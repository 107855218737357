/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Box from 'core/ui/Box';
import TextAreaFieldRounded from 'core/ui/TextAreaFieldRounded';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import Radio from 'core/ui/Radio';
import Paper from 'core/ui/Paper';
import Typography, { fontStyle } from 'core/ui/Typography';
import { secondary } from 'core/ui/Colors';
import { useTranslation } from 'react-i18next';
import DatePickerRounded from 'core/ui/DatePickers/DatePickerRounded';
import { Dialog } from '@material-ui/core';
import StoreProducts from 'components/Store/Products';
import { automatedMessageTypes } from 'core/utils/consts';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import TablePlans from 'components/Channel/Manage/Tabs/Memberships/TablePlans';
import Checkbox from 'core/ui/Checkbox';
import Divider from 'core/ui/Divider';
import TimePickerRounded from 'core/ui/DatePickers/TimePickerRounded';
import Grid from 'core/ui/Grid';
import { useSelector } from 'react-redux';
import { channelsSelectors } from 'modules/channels';
import Toggle from 'core/ui/Toggle';
import useChannelStyles from 'core/useChannelStyles';
import S3UploadAttachments from './S3UploadAttachments';
import { RichEditor } from './FormSend/RichEditor';

const FormSend = ({ ownerableType, ownerableId, textService }) => {
  const { t } = useTranslation();
  const { buttonColor } = useChannelStyles();
  const [openModalStore, setOpenModalStore] = useState(false);
  const [openModalMembership, setOpenModalMembership] = useState(false);
  const boxStyle = {
    padding: 3,
    border: `0.2px solid ${secondary}`,
  };

  const ch = useSelector((state) => channelsSelectors.getChannel(state));

  const isChannel = ownerableType === 'Channel';
  const channel = isChannel ? ch : null;

  const openMembershipChoose = () => {
    setOpenModalMembership(true);
  };

  return (
    <Box px={3}>
      {textService.automated && (
        <Box mb={3}>
          <Typography mb={1} variant="h5">
            {t('channel.message.selectRecurringType')}
          </Typography>

          <Box>
            <SelectBoxObject
              options={textService?.messagesTypes || []}
              // label={t('channel.template.type.recurring.type')}
              value={textService.watch('messagesTypeId')}
              setValue={(v) => textService.setValue('messagesTypeId', v)}
            />

            {!!textService?.currentMessageType?.identify && (
              <Typography style={fontStyle.small} p={1}>
                {t(
                  `channel.message.automated.type.info.${textService?.currentMessageType?.identify}`,
                )}
              </Typography>
            )}
          </Box>
        </Box>
      )}

      <Box mb={3} display="flex">
        {!textService.automated && (
          <>
            <Box>
              <Toggle
                label={t('label.messageType')}
                options={[
                  { value: 'sms', label: 'SMS', width: 120 },
                  { value: 'email', label: 'EMAIL', width: 120 },
                  { value: 'push_notification', label: 'App Messages', width: 120 },
                ]}
                color="white"
                activeColor={buttonColor}
                value={textService.watch('method')}
                onChange={(v) => {
                  textService.setValue('method', v);
                  if (v === 'sms') {
                    textService.setValue('text', '');
                    textService.setValue('textRaw', null);
                  }
                }}
              />
            </Box>
            <Divider orientation="vertical" flexItem mx={5} />
            <Box>
              <Typography mb={2} style={fontStyle.label}>
                {t('label.draft')}
              </Typography>
              <Checkbox
                checked={textService?.watch('status') === 'draft'}
                onClick={() => {
                  textService.setValue(
                    'status',
                    textService?.watch('status') === 'draft' ? 'queued' : 'draft',
                  );
                }}
              />
            </Box>
            {textService?.watch('status') !== 'draft' && (
              <>
                <Divider orientation="vertical" flexItem mx={5} />
                <Box display="flex" alignItems="center" flexWrap="wrap">
                  <Box>
                    <Toggle
                      label={t('label.sendWhen')}
                      options={[
                        { value: false, label: t('message.sendNow'), width: 120 },
                        { value: true, label: t('message.sendLater'), width: 120 },
                      ]}
                      color="white"
                      activeColor={buttonColor}
                      value={!!textService.watch('scheduleSend')}
                      onChange={(v) => {
                        if (!v) {
                          textService.setValue('scheduleSend', false);
                          textService.setValue('scheduleSendAt', null);
                          textService.setValue('scheduleSendAtHour', null);
                          textService.setValue('scheduleSendAtDate', null);
                        } else {
                          textService.setValue('scheduleSend', true);
                        }
                      }}
                    />
                  </Box>

                  {textService.watch('scheduleSend') && (
                    <Box display="flex" className="gap-5" ml={3}>
                      <DatePickerRounded
                        name="scheduledAt"
                        type="datetime-local"
                        label={t('workout.label.scheduledAtDate')}
                        onChange={(v) => textService.setValue('scheduleSendAtDate', v)}
                        value={textService.watch('scheduleSendAtDate')}
                        usFormat={channel?.useUsDateFormat}
                        required
                        disablePast={false}
                        bordered
                      />

                      <TimePickerRounded
                        name="scheduledAt"
                        type="datetime-local"
                        label={t('workout.label.scheduledAtTime')}
                        onChange={(v) => textService.setValue('scheduleSendAtHour', v)}
                        value={textService.watch('scheduleSendAtHour')}
                        required
                        bordered
                      />
                    </Box>
                  )}
                </Box>
              </>
            )}
          </>
        )}
      </Box>
      {textService.watch('method') !== 'sms' && (
        <Box mb={3} display="flex" alignItems="flex-end">
          <Box flexGrow={1}>
            <TextFieldRounded
              label="Subject"
              error={textService.errors && textService.errors.subject}
              value={textService.watch('subject') || ''}
              onChange={(v) => textService.setValue('subject', v)}
              bordered
            />
          </Box>

          {textService.watch('method') === 'email' && (
            <Box ml={3} pb={2}>
              <Checkbox
                checked={textService.watch('copyMe')}
                onClick={() => textService.setValue('copyMe', !textService.watch('copyMe'))}
                label="Copy Me"
              />
            </Box>
          )}
        </Box>
      )}
      <Box mb={3}>
        {textService.watch('method') === 'sms' ? (
          <TextAreaFieldRounded
            label="Message"
            error={textService.errors && textService.errors.text}
            value={textService.watch('text') || ''}
            onChange={(v) => textService.setValue('text', v)}
            maxLength={150}
            bordered
            resize="vertical"
          />
        ) : (
          <Box>
            <RichEditor
              form={textService}
              isChannel={isChannel}
              ownerableType={ownerableType}
              ownerableId={ownerableId}
            />
          </Box>
        )}

        <Box mx={1}>
          <Typography mt={3} style={fontStyle.label}>
            StreamFit Links
          </Typography>
          <Box display="flex" flexWrap="wrap">
            {ownerableType === 'Channel' && (
              <>
                <Paper
                  m={1}
                  style={boxStyle}
                  className="paper-rounded link-hover"
                  onClick={() => openMembershipChoose()}
                >
                  <Typography color="secondary" variant="caption">
                    {t('channel.membershipLink')}
                  </Typography>

                  {openModalMembership && (
                    <Dialog
                      open={openModalMembership}
                      maxWidth="md"
                      fullWidth
                      onClose={() => setOpenModalMembership(false)}
                    >
                      <Box p={3}>
                        <TablePlans
                          channel={channel}
                          membershipPlans={channel?.membershipAllPlans}
                        />
                      </Box>
                    </Dialog>
                  )}
                </Paper>
                <Paper
                  m={1}
                  style={boxStyle}
                  className="paper-rounded link-hover"
                  onClick={() => setOpenModalStore(true)}
                >
                  <Typography color="secondary" variant="caption">
                    {t('channel.storeProductLink')}
                  </Typography>

                  {openModalStore && (
                    <Dialog
                      open={openModalStore}
                      maxWidth="lg"
                      fullWidth
                      onClose={() => setOpenModalStore(false)}
                    >
                      <Box p={3}>
                        {!!channel?.id && (
                          <StoreProducts
                            channelId={channel?.id}
                            ownerableId={channel?.id}
                            ownerableType="Channel"
                            currency={channel?.currency}
                            channelsTaxes={channel?.channelsTaxes}
                            copyLink
                          />
                        )}
                      </Box>
                    </Dialog>
                  )}
                </Paper>
              </>
            )}
          </Box>
        </Box>
      </Box>
      {textService.automated && (
        <Box mx={1}>
          {textService?.currentMessageType?.identify ===
            automatedMessageTypes.registration_lapse && (
            <Box mb={3}>
              <TextFieldRounded
                label={t('channel.template.label.days.registration_lapse')}
                error={textService.errors && textService.errors.days}
                value={textService.watch('days') || ''}
                onChange={(v) => textService.setValue('days', v)}
                bordered
              />
            </Box>
          )}

          {[
            automatedMessageTypes.total_classes_range,
            automatedMessageTypes.total_classes_overall,
          ].find((x) => x === textService?.currentMessageType?.identify) && (
            <Box display="flex" alignItems="flex-end" mx={3} mb={3}>
              <TextFieldRounded
                label={t('channel.template.label.classes')}
                placeholder="Number of classes"
                error={textService.errors && textService.errors.days}
                value={textService.watch('classes') || ''}
                onChange={(v) => textService.setValue('classes', v)}
                bordered
              />
              {textService?.currentMessageType?.identify ===
                automatedMessageTypes.total_classes_range && (
                <>
                  <Typography mx={3}>x</Typography>
                  <TextFieldRounded
                    label={t('channel.template.label.days')}
                    placeholder="Number of days"
                    error={textService.errors && textService.errors.days}
                    value={textService.watch('days') || ''}
                    onChange={(v) => textService.setValue('days', v)}
                    bordered
                  />
                </>
              )}
            </Box>
          )}

          {textService?.currentMessageType?.identify === automatedMessageTypes.recurring && (
            <Box display="flex" flexWrap="wrap" mb={3}>
              <Box ml={3}>
                <Typography mb={2} style={fontStyle.label} variant="body1">
                  {t('channel.template.frequency')}
                </Typography>
                <Box display="flex">
                  <Box>
                    <Radio
                      label={t('channel.memberships.custom.week')}
                      checked={textService.watch('frequency') === 'week'}
                      onClick={() => textService.setValue('frequency', 'week')}
                    />
                  </Box>
                  <Box mx={1}>
                    <Radio
                      label={t('channel.memberships.custom.month')}
                      checked={textService.watch('frequency') === 'month'}
                      onClick={() => textService.setValue('frequency', 'month')}
                    />
                  </Box>
                  <Radio
                    label={t('channel.memberships.custom.year')}
                    checked={textService.watch('frequency') === 'year'}
                    onClick={() => textService.setValue('frequency', 'year')}
                  />
                </Box>
              </Box>

              <Box ml={3}>
                <DatePickerRounded
                  label={t('channel.template.startDate')}
                  placeholder={t('channel.template.startDate')}
                  stylePaper={{ width: 160 }}
                  value={textService.watch('recurringStartDate') || ''}
                  error={textService.errors.recurringStartDate}
                  usFormat={channel?.useUsDateFormat}
                  onChange={(v) => textService.setValue('recurringStartDate', v)}
                  disablePast
                  bordered
                />
              </Box>
            </Box>
          )}
          {textService?.currentMessageType?.identify === automatedMessageTypes.first_visit && (
            <Box display="flex" flexWrap="wrap" mb={3}>
              <Box ml={3}>
                <Typography mb={2} style={fontStyle.label} variant="body1">
                  {t('channel.template.firstVisit.afterFirstVisit')}
                </Typography>
                <Box display="flex">
                  <Box mr={1}>
                    <Radio
                      label={t('channel.memberships.custom.minute')}
                      checked={textService.watch('firstVisitFrequency') === 'minute'}
                      onClick={() => textService.setValue('firstVisitFrequency', 'minute')}
                    />
                  </Box>
                  <Box mx={1}>
                    <Radio
                      label={t('channel.memberships.custom.hour')}
                      checked={textService.watch('firstVisitFrequency') === 'hour'}
                      onClick={() => textService.setValue('firstVisitFrequency', 'hour')}
                    />
                  </Box>
                  <Box mx={1}>
                    <Radio
                      label={t('channel.memberships.custom.day')}
                      checked={textService.watch('firstVisitFrequency') === 'day'}
                      onClick={() => textService.setValue('firstVisitFrequency', 'day')}
                    />
                  </Box>
                  <Box>
                    <Radio
                      label={t('channel.memberships.custom.week')}
                      checked={textService.watch('firstVisitFrequency') === 'week'}
                      onClick={() => textService.setValue('firstVisitFrequency', 'week')}
                    />
                  </Box>
                  <Box mx={1}>
                    <Radio
                      label={t('channel.memberships.custom.month')}
                      checked={textService.watch('firstVisitFrequency') === 'month'}
                      onClick={() => textService.setValue('firstVisitFrequency', 'month')}
                    />
                  </Box>
                </Box>
              </Box>

              <Box ml={3}>
                <TextFieldRounded
                  label={`${textService.watch('firstVisitFrequency')?.toUpperCase()}`}
                  placeholder={`${textService.watch('firstVisitFrequency')}`}
                  error={textService.errors && textService.errors.days}
                  value={textService.watch('firstVisitNumber') || ''}
                  onChange={(v) => textService.setValue('firstVisitNumber', v)}
                  bordered
                />
              </Box>
            </Box>
          )}
        </Box>
      )}
      {textService.watch('method') === 'email' && (
        <Box mb={3}>
          <TextFieldRounded
            label="Video Link"
            error={textService.errors && textService.errors.videoLink}
            value={textService.watch('videoLink') || ''}
            onChange={(v) => textService.setValue('videoLink', v)}
            bordered
          />
        </Box>
      )}
      {(textService.watch('method') === 'email' ||
        textService.watch('method') === 'push_notification') && (
        <Box mb={3} display="flex">
          <Box mr={5}>
            <TextFieldRounded
              label="Button Text"
              error={textService.errors && textService.errors.actionText}
              value={textService.watch('actionText') || ''}
              onChange={(v) => textService.setValue('actionText', v)}
              bordered
            />
          </Box>
          <TextFieldRounded
            label="Button Link"
            error={textService.errors && textService.errors.actionLink}
            value={textService.watch('actionLink') || ''}
            onChange={(v) => textService.setValue('actionLink', v)}
            bordered
          />
        </Box>
      )}

      {!textService.automated && (
        <Grid container spacing={5} mb={3}>
          {textService.watch('method') === 'email' && (
            <Grid item xs={12} md={6}>
              <S3UploadAttachments
                setFiles={textService.setAttachments}
                files={textService.attachments}
                objectId={channel?.id}
                objectType="Channel"
              />
            </Grid>
          )}
        </Grid>
      )}
    </Box>
  );
};

export default FormSend;
