import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import Typography, { fontStyle } from 'core/ui/Typography';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import Checkbox from 'core/ui/Checkbox';
import Radio from 'core/ui/Radio';
import TimePickerRounded from 'core/ui/DatePickers/TimePickerRounded';

const HideUntilDate = ({ watch, setValue, bordered, isCompetition }) => {
  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="column">
      <Box>
        <Checkbox
          checked={!!watch('hideSectionsConfig.active')}
          onClick={() => setValue('hideSectionsConfig.active', !watch('hideSectionsConfig.active'))}
          label={t(
            isCompetition
              ? 'workout.section.hideSectionsBefore.comp'
              : 'workout.section.hideSectionsBefore',
          )}
        />
      </Box>
      {watch('hideSectionsConfig.active') && (
        <Box m={1} mt={2}>
          <Box display="flex" mb={1}>
            <Radio
              checked={!!watch('hideSectionsConfig.setTime')}
              onClick={() =>
                setValue('hideSectionsConfig.setTime', !watch('hideSectionsConfig.setTime'))
              }
              label={t('workout.section.hideSections.setTime')}
            />
            &nbsp;
            <Radio
              checked={!watch('hideSectionsConfig.setTime')}
              onClick={() =>
                setValue('hideSectionsConfig.setTime', !watch('hideSectionsConfig.setTime'))
              }
              label={t('workout.section.hideSections.custom')}
            />
          </Box>

          {watch('hideSectionsConfig.setTime') ? (
            <>
              <Typography style={fontStyle.label} mb={1}>
                {t('workout.hide.sections.beforeStart').toUpperCase()}
              </Typography>
              <Box display="flex" alignItems="center">
                <Box>
                  <TextFieldRounded
                    label={t('workout.hideSectionsConfig.daysBefore').toUpperCase()}
                    onChange={(v) => setValue('hideSectionsConfig.number', v > 0 ? v : 0)}
                    value={watch('hideSectionsConfig.number')}
                    type="number"
                    pattern="[0-9]*"
                    bordered={bordered}
                  />
                </Box>

                <Box ml={1}>
                  <TimePickerRounded
                    type="datetime-local"
                    label={t('hour').toUpperCase()}
                    onChange={(v) => setValue('hideSectionsConfig.setTimeHour', v)}
                    value={watch('hideSectionsConfig.setTimeHour')}
                    required
                    bordered
                  />
                </Box>
              </Box>
            </>
          ) : (
            <>
              <Typography style={fontStyle.label} mb={1}>
                {t(
                  isCompetition
                    ? 'workout.hide.sections.beforeStart.comp'
                    : 'workout.hide.sections.beforeStart',
                ).toUpperCase()}
              </Typography>
              <Box display="flex" alignItems="center">
                <Box>
                  <TextFieldRounded
                    onChange={(v) => setValue('hideSectionsConfig.number', v > 0 ? v : 0)}
                    value={watch('hideSectionsConfig.number')}
                    type="number"
                    pattern="[0-9]*"
                    bordered={bordered}
                  />
                </Box>

                <Box ml={1}>
                  <SelectBoxObject
                    emptyItem={false}
                    propValue="id"
                    propLabel="name"
                    options={['minutes', 'hours', 'days'].map((x) => ({
                      id: x,
                      name: t(`workout.limit.type.${x}`),
                    }))}
                    setValue={(v) => setValue('hideSectionsConfig.timeType', v)}
                    value={watch('hideSectionsConfig.timeType')}
                    bordered={bordered}
                  />
                </Box>
              </Box>
            </>
          )}
        </Box>
      )}
    </Box>
  );
};

export default HideUntilDate;

HideUntilDate.propTypes = {};

HideUntilDate.defaultProps = {};
