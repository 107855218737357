import api from 'core/api';
import { call, put } from 'redux-saga/effects';

export const channelsStagesModule = 'channelsStages';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const actions = {
  postChannelsStages: {
    module: channelsStagesModule,
    name: 'postChannelsStages',
    api: (data) => api.post(`/channels/${data?.channelId}/channels_stages`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  putChannelsStages: {
    module: channelsStagesModule,
    name: 'putChannelsStages',
    api: (data) => api.put(`/channels/${data?.channelId}/channels_stages/${data?.id}`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  destroy: {
    module: channelsStagesModule,
    name: 'destroy',
    api: (data) => api.delete(`/channels/${data?.channelId}/channels_stages/${data?.id}`),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  getChannelsStages: {
    module: channelsStagesModule,
    name: 'getChannelsStages',
    api: (params) => api.get(`/channels/${params?.channelId}/channels_stages`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: ['data'],
    },
    state: loadingStates,
  },
  getChannelsStagesUsers: {
    module: channelsStagesModule,
    name: 'getChannelsStagesUsers',
    api: (params) =>
      api.post(
        `/channels/${params?.channelId}/channels_stages/${params?.channelsStageId}/channels_stages_users/users`,

        params,
      ),
    *sagas(Creators, { params, options }) {
      try {
        const resp = yield call(actions.getChannelsStagesUsers.api, params);
        yield put(Creators.getChannelsStagesUsersSuccess());
        if (options.onSuccess) {
          options.onSuccess(resp.data, resp.pagination);
        }
      } catch (error) {
        if (options.onError) {
          options.onError(error);
        }
        yield put(Creators.getChannelsStagesUsersError());
      }
    },
    isTakeEvery: true,
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  saveStagesOrder: {
    module: channelsStagesModule,
    name: 'saveStagesOrder',
    api: (params) => api.post(`/channels/${params?.channelId}/channels_stages/save_order`, params),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  saveStagesUserOrder: {
    module: channelsStagesModule,
    name: 'saveStagesUserOrder',
    api: (params) =>
      api.post(
        `/channels/${params?.channelId}/channels_stages/${params?.stageOrigId}/channels_stages_users/save_user_order`,
        params,
      ),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  setStagesData: {
    module: channelsStagesModule,
    name: 'setStagesData',
    params: {
      start: ['params'],
      error: [''],
      success: ['data'],
    },
    state: loadingStates,
  },
};

const channelsStageActionsAndStates = {
  actions,
  state: {
    data: [],
    error: null,
    loading: false,
  },
};

export default channelsStageActionsAndStates;
