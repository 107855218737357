/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Pagination from '@material-ui/lab/Pagination';

import {} from '@material-ui/core';
import Box from '../Box';
import './style.css';

const Paginator = ({ pagination, changePage }) => {
  const [page, setPage] = useState(pagination?.currentPage || 1);
  const handlePageClick = (p) => {
    setPage(p);
    changePage(p);
  };

  useEffect(() => {
    setPage(pagination?.currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination?.currentPage]);

  return (
    <Box className="pagination primary">
      <Pagination
        onChange={(e, p) => handlePageClick(p)}
        count={pagination?.totalPages}
        page={page}
      />
    </Box>
  );
};

export default Paginator;
