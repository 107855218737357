import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import Texture from 'core/ui/Texture';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import PersonalRecordList from 'components/Profile/Dashboard/PersonalRecord/List';
import { repMaxTypes } from 'core/utils/consts';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import Grid from 'core/ui/Grid';
import Divider from 'core/ui/Divider';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import Typography from 'core/ui/Typography';
import Paper from 'core/ui/Paper';
import Radio from 'core/ui/Radio';
import { secondary } from 'core/ui/Colors';
import { ButtonPrimary, ButtonPrimarySm } from 'core/ui/Button';
import FormatSectionPrRow from './FormatSectionPrRow';

const ModalPersonalRecord = ({ close, prScore, setSection, setPr, liftComplext }) => {
  const { t } = useTranslation();
  const [selectedPr, setSelectedPr] = useState(null);
  const [dataRow, setDataRow] = useState({
    scoreType: 'repMax1',
    percStart: '100',
    percEnd: '100',
  });
  const [data, setData] = useState({
    repScheme: '',
    layoutType: 'single',

    rows: [],
  });

  const create = () => {
    if (!selectedPr) {
      return;
    }
    const newPrData = {
      ...data,
      rows: data.layoutType === 'custom' ? data.rows : [dataRow],
      prId: selectedPr.id,
      movementName: selectedPr.movement.name,
    };

    setSection((prev) => ({
      ...prev,
      personalRecords: prev.personalRecords ? [...prev.personalRecords, newPrData] : [newPrData],
    }));

    close();
  };

  const insertRow = () => {
    const newItem = {
      ...dataRow,
    };
    setData((prev) => ({ ...prev, rows: prev.rows ? [...prev.rows, newItem] : [newItem] }));
  };

  const removeRow = (row) => {
    setData((prev) => ({ ...prev, rows: prev.rows.filter((x) => x !== row) }));
  };

  const multiple = data.layoutType === 'custom';

  return (
    <Dialog open onClose={close} maxWidth="sm" fullWidth scroll="body">
      <Texture>
        <Box p={5}>
          {!prScore ? (
            <>
              <Grid mb={3} container spacing={5}>
                <Grid item xs={8}>
                  <Box display="flex" justifyContent="center" mb={3}>
                    {['single', 'range', 'custom'].map((op, index) => (
                      <Box mx={1} key={index.toString()}>
                        <Radio
                          color={secondary}
                          label={t(`workout.section.pr.type.${op}`)}
                          onClick={() => setData((prev) => ({ ...prev, layoutType: op }))}
                          checked={data.layoutType === op}
                        />
                      </Box>
                    ))}
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <TextFieldRounded
                    value={data.repScheme}
                    onChange={(value) => setData((prev) => ({ ...prev, repScheme: value }))}
                    placeholder="Rep Scheme"
                  />
                </Grid>
              </Grid>
              <Grid mb={3} container spacing={5}>
                <Grid item xs={4}>
                  <SelectBoxObject
                    propValue="id"
                    propLabel="name"
                    options={[
                      ...repMaxTypes.map((x) => ({
                        id: x,
                        name: t(x),
                      })),
                      { id: 'noPR', name: t('form.personalRecord.noRecordPR') },
                    ]}
                    value={dataRow.scoreType}
                    setValue={(value) => setDataRow((prev) => ({ ...prev, scoreType: value }))}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Box display="flex" alignItems="center">
                    <TextFieldRounded
                      type="number"
                      pattern="[0-9]*"
                      value={dataRow.percStart}
                      onChange={(value) => setDataRow((prev) => ({ ...prev, percStart: value }))}
                      placeholder="%"
                    />
                    {data.layoutType === 'range' && (
                      <>
                        &nbsp;-&nbsp;
                        <TextFieldRounded
                          type="number"
                          pattern="[0-9]*"
                          value={dataRow.percEnd}
                          onChange={(value) => setDataRow((prev) => ({ ...prev, percEnd: value }))}
                          placeholder="%"
                        />
                      </>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={2}>
                  {multiple && (
                    <Box display="flex" justifyContent="center" alignItems="flex-start">
                      <ButtonPrimarySm onClick={() => insertRow()}>
                        {t('button.add')}
                      </ButtonPrimarySm>
                    </Box>
                  )}
                </Grid>
              </Grid>

              <Paper p={3}>
                <Typography
                  style={{ color: 'gray' }}
                  variant="caption"
                  component="div"
                  align="center"
                >
                  {multiple ? (
                    <FormatSectionPrRow
                      removeRow={removeRow}
                      data={data}
                      movement={selectedPr ? selectedPr.movement.name : '{movement}'}
                      form
                    />
                  ) : (
                    <FormatSectionPrRow
                      data={{ ...data, rows: [dataRow] }}
                      movement={selectedPr ? selectedPr.movement.name : '{movement}'}
                      form
                    />
                  )}
                </Typography>
              </Paper>

              <Divider my={3} />
            </>
          ) : (
            <>
              <Typography textCenter variant="h5" mb={3}>
                {selectedPr?.movement?.name}
              </Typography>
              {selectedPr?.scoreType === 'rep_max' && (
                <SelectBoxObject
                  propValue="id"
                  propLabel="name"
                  options={[
                    ...repMaxTypes.map((x) => ({
                      id: x,
                      name: t(x),
                    })),
                    { id: 'noRepMax', name: t('form.personalRecord.noRepMax') },
                  ]}
                  value={dataRow.scoreType}
                  setValue={(value) => setDataRow((prev) => ({ ...prev, scoreType: value }))}
                />
              )}
              {selectedPr?.scoreType === 'min_max' && (
                <SelectBoxObject
                  propValue="id"
                  propLabel="name"
                  options={['reps', 'calories'].map((x) => ({
                    id: x,
                    name: t(`workout.section.label.score.${x}`),
                  }))}
                  value={dataRow.scoreType}
                  setValue={(value) => setDataRow((prev) => ({ ...prev, scoreType: value }))}
                />
              )}
            </>
          )}

          {(!prScore || !selectedPr?.id) && (
            <PersonalRecordList
              selectedPr={selectedPr}
              setSelectedPr={(p) =>
                prScore && p?.scoreType !== 'rep_max' && p.scoreType !== 'min_max'
                  ? setPr(p)
                  : setSelectedPr(p)
              }
              scoreType={
                !liftComplext && prScore
                  ? ['max_distance', 'rep_max', 'min_max', 'time', 'weight']
                  : ['rep_max']
              }
              isSelector
              rowsPerPage={5}
              showInsertButton
            />
          )}

          {(selectedPr?.scoreType === 'rep_max' ||
            selectedPr?.scoreType === 'min_max' ||
            !prScore) && (
            <Box display="flex" justifyContent="center" mt={5}>
              <ButtonPrimary
                style={{ minWidth: 200 }}
                onClick={() =>
                  prScore ? setPr({ ...selectedPr, selectedScore: dataRow.scoreType }) : create()
                }
              >
                {prScore ? t('button.assignPr') : t('button.create')}
              </ButtonPrimary>
            </Box>
          )}
        </Box>
      </Texture>
    </Dialog>
  );
};

ModalPersonalRecord.propTypes = {
  close: PropTypes.func,
};

ModalPersonalRecord.defaultProps = {
  close: () => {},
};

export default ModalPersonalRecord;
