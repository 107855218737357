import React, { useContext, useState } from 'react';
import Typography from 'core/ui/Typography';
import { Collapse } from '@material-ui/core';
import Box from 'core/ui/Box';
import { KeyboardArrowDown, KeyboardArrowRight } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { CompetitionContext } from '../index';
import DivisionWorkoutCard from './DivisionWorkoutCard';

const DivisionsCards = ({ division, perPage, withoutLogo }) => {
  const formContext = useContext(CompetitionContext);
  const { competition } = formContext;
  const { t } = useTranslation();
  const [opened, setOpened] = useState(!division?.id);

  const showCards = (!!competition?.id && !competition?.hasDivisions) || !!division?.id;

  const cWorkouts =
    competition?.workouts?.filter(
      (x) => !division || !!x.divisions.find((y) => y.id === division?.id),
    ) || [];

  return (
    <div>
      {competition?.hasDivisions && (
        <Box
          className="link tw-flex tw-flex-row tw-items-center"
          onClick={() => setOpened(!opened)}
        >
          <Typography className="tw-text-lg tw-font-bold">{division?.name}</Typography>

          {opened === true ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
        </Box>
      )}

      <Collapse in={opened} unmountOnExit>
        <div>
          <Typography className="tw-mb-2 tw-text-lg tw-font-bold">
            {t('competition.materialDesign.instagramPost')}
          </Typography>
          {showCards && (
            <div className="tw-grid tw-grid-cols-1 tw-gap-2 md:tw-grid-cols-4">
              <DivisionWorkoutCard withoutLogo={withoutLogo} divisionCard division={division} />
              <DivisionWorkoutCard withoutLogo={withoutLogo} division={division} />
              {cWorkouts?.map((x) => (
                <div>
                  <DivisionWorkoutCard
                    perPage={perPage}
                    withoutLogo={withoutLogo}
                    division={division}
                    workout={x}
                  />
                </div>
              ))}
            </div>
          )}

          <Typography className="tw-mb-2 tw-mt-4 tw-text-lg tw-font-bold">
            {t('competition.materialDesign.instagramStory')}
          </Typography>
          {showCards && (
            <div className="tw-grid tw-grid-cols-1 tw-gap-2 md:tw-grid-cols-4">
              <DivisionWorkoutCard
                withoutLogo={withoutLogo}
                story
                divisionCard
                division={division}
              />
              <DivisionWorkoutCard withoutLogo={withoutLogo} story division={division} />
              {cWorkouts.map((x) => (
                <div>
                  <DivisionWorkoutCard
                    withoutLogo={withoutLogo}
                    story
                    perPage={perPage}
                    division={division}
                    workout={x}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </Collapse>
    </div>
  );
};

export default DivisionsCards;
