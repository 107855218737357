/* eslint-disable react/prop-types */
import React from 'react';
import Paper from 'core/ui/Paper';
import { useTranslation } from 'react-i18next';
import Typography from 'core/ui/Typography';
import SFDataTable from 'core/ui/DataTable';
import LabelPhone from 'core/ui/InputPhone/LabelPhone';
import { date } from 'core/utils/formatters';
import Grid from 'core/ui/Grid';
import { Link, Tooltip } from '@material-ui/core';

export default function ResultUsers({ channel, data }) {
  const { t } = useTranslation();
  const columns = [
    {
      name: 'User Name',
      selector: 'userName',
      sortable: true,
      cell: (row) => (
        <Tooltip title={`ID: ${row?.id}`} placement="left-start">
          <div>
            <Link
              target="_blank"
              className="hover"
              href={`/channel/manage/${channel?.id}/members/${row?.id}`}
            >
              <Typography>{row.name}</Typography>
            </Link>
          </div>
        </Tooltip>
      ),
    },
    {
      name: t('channel.memberships.column.phone'),
      selector: 'phone',
      sortable: true,
      width: '230px',
      cell: (member) => <>{member.phone && <LabelPhone value={member.phone} />}</>,
    },
    {
      name: t('channel.memberships.column.email'),
      selector: 'phone',
      sortable: true,
      width: '230px',
      cell: (member) => <Typography>{member.email}</Typography>,
    },
    {
      selector: 'joinedDate',
      name: 'Joined',
      sortable: true,
      cell: (row) => <Typography>{row.joinedDate && date(row.joinedDate, 'MM/DD/YY')}</Typography>,
    },
  ];

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <Paper p={3}>
            <Typography variant="h5" mb={3}>
              Total Members Begin Month
            </Typography>
            <Typography variant="h1">{data?.resume?.totalActiveBeginMonth || 0}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={3}>
          <Paper p={3}>
            <Typography variant="h5" mb={3}>
              New Members
            </Typography>
            <Typography variant="h1">{data?.resume?.totalNewMembers || 0}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={3}>
          <Paper p={3}>
            <Typography variant="h5" mb={3}>
              Lost Members
            </Typography>
            <Typography variant="h1">{data?.resume?.totalLostMembers || '0'}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={3}>
          <Paper p={3}>
            <Typography variant="h5" mb={3}>
              Churn Rate
            </Typography>
            <Typography variant="h1">{data?.resume?.rate || '0'}</Typography>
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper style={{ width: '100%' }} my={3}>
            <SFDataTable
              title="New Members"
              columns={columns}
              data={data?.newMembers}
              pagination
              showExport={false}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper style={{ width: '100%' }} my={3}>
            <SFDataTable
              title="Lost Members"
              columns={columns}
              data={data?.lostMembers}
              pagination
              showExport={false}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
