import React, { useCallback, useEffect, useState } from 'react';

import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import Typography from 'core/ui/Typography';
import { dayWeekFilters } from 'core/utils/consts';
import Paper from 'core/ui/Paper';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import { formatWeekDaysToObj } from 'core/utils/helpers';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import ViewWeekDay from './ViewWeekDay';

const ViewCalendarsAvailable = ({ userId, channelId }) => {
  const { t } = useTranslation();
  const { request } = useActions();

  const [calendars, setCalendars] = useState([]);
  const [currentCalendarId, setCurrentCalendarId] = useState(null);
  const [currentCalendar, setCurrentCalendar] = useState([]);

  const getCalendars = useCallback(() => {
    if (!channelId || !userId) {
      return;
    }
    request({
      action: Modules.usersCalendars.actions.getCalendars,
      data: {
        channelId,
        userId,
        showOnGymCalendar: 1,
      },
      options: {
        onSuccess: (data) => {
          setCalendars(data);
          if (data?.length > 0) {
            setCurrentCalendarId(data[0].id);
          }
        },
      },
    });
  }, [request, channelId, userId]);

  const getCalendar = useCallback(
    (id) => {
      request({
        action: Modules.usersCalendars.actions.getCalendar,
        data: {
          channelId,
          id,
        },
        options: {
          onSuccess: (data) => {
            setCurrentCalendar({
              ...data,
              weeklyHours: formatWeekDaysToObj(data?.usersCalendarsWeeklyHours || []),
            });
          },
        },
      });
    },
    [request],
  );

  useEffect(() => {
    getCalendars();
  }, [getCalendars]);

  useEffect(() => {
    if (currentCalendarId) {
      getCalendar(currentCalendarId);
    }
  }, [currentCalendarId, getCalendar]);

  return (
    <Box>
      <Typography mb={3} variant="h5">
        {t('button.times')}
      </Typography>
      {calendars?.length <= 0 && (
        <Typography mb={3} variant="h5">
          {t('message.no.calendar')}
        </Typography>
      )}

      {calendars?.length > 1 && (
        <SelectBoxObject
          label={t('users.calendars')}
          options={calendars}
          value={currentCalendarId}
          setValue={setCurrentCalendarId}
          bordered
        />
      )}

      {currentCalendarId && (
        <div className="tw-mt-2 tw-flex tw-flex-col tw-gap-px">
          {dayWeekFilters.map((weekDay) => (
            <Paper square p={1} key={weekDay?.id}>
              <ViewWeekDay weekDay={weekDay} weeklyHours={currentCalendar?.weeklyHours || []} />
            </Paper>
          ))}
        </div>
      )}
    </Box>
  );
};

export default ViewCalendarsAvailable;
