import api from 'core/api';
import { retrieveAuthHeaders } from 'core/api/auth-headers';
import { retrieveData } from 'core/utils/session-storage';
import { call, put } from 'redux-saga/effects';
import { saveAs } from 'file-saver';
import humps from 'humps';
import { BASE_URL } from 'core/env';

export const newChannelMembersModule = 'newChannelMembers';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const loadingUpdateMemberStates = {
  start: { loadingUpdateMember: true },
  error: { loadingUpdateMember: false },
  success: { loadingUpdateMember: false },
};

const loadingJoinedDateStates = {
  start: { loadingJoinedDate: true },
  error: { loadingJoinedDate: false },
  success: { loadingJoinedDate: false },
};

const loadingMembershipsStates = {
  start: { loadingMemberships: true },
  error: { loadingMemberships: false },
  success: { loadingMemberships: false },
};

const loadingExportStates = {
  start: { loadingExport: true },
  error: { loadingExport: false },
  success: { loadingExport: false },
};

const loadingRenewMembershipStates = {
  start: { loadingRenewMembership: true },
  error: { loadingRenewMembership: false },
  success: { loadingRenewMembership: false },
};

const loadingCancelMembershipStates = {
  start: { loadingCancelMembership: true },
  error: { loadingCancelMembership: false },
  success: { loadingCancelMembership: false },
};

const loadingUploadStates = {
  start: { loadingUpload: true },
  error: { loadingUpload: false },
  success: { loadingUpload: false },
};

const loadingCheckinStates = {
  start: { loadingCheckIn: true },
  error: { loadingCheckIn: false },
  success: { loadingCheckIn: false },
};

const actions = {
  createMembership: {
    module: newChannelMembersModule,
    name: 'createMembership',
    api: (data) => api.post(`/channel_members/${data.channelId}/add_key`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingMembershipsStates,
  },
  updateMembership: {
    module: newChannelMembersModule,
    name: 'updateMembership',
    api: (data) => api.put(`/channel_members/${data.channelId}/update_key`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingMembershipsStates,
  },
  deleteChannelKey: {
    module: newChannelMembersModule,
    name: 'deleteChannelKey',
    api: (data) => api.delete(`/channel_members/${data.channelId}/remove_key`, { params: data }),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingMembershipsStates,
  },
  removeUserFromChannel: {
    module: newChannelMembersModule,
    name: 'removeUserFromChannel',
    api: (data) =>
      api.delete(
        `/channel_members/${data.channelId}/member/${data?.userId}/remove_user_from_channel`,
      ),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingMembershipsStates,
  },
  changeLeadToInactive: {
    module: newChannelMembersModule,
    name: 'changeLeadToInactive',
    api: (data) =>
      api.put(`/channel_members/${data.channelId}/member/${data?.userId}/change_lead_to_inactive`),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  changeMemberToInactive: {
    module: newChannelMembersModule,
    name: 'changeMemberToInactive',
    api: (data) =>
      api.put(
        `/channel_members/${data.channelId}/member/${data?.userId}/change_member_to_inactive`,
      ),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  getNextMembershipCharge: {
    module: newChannelMembersModule,
    name: 'getNextMembershipCharge',
    api: (data) => api.post(`/channel_members/${data.channelId}/get_next_membership_cost`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },

  getMemberPayments: {
    module: newChannelMembersModule,
    name: 'getMemberPayments',
    api: (params) =>
      api.get(`/channel_members/${params.channelId}/member/${params?.userId}/payments`, { params }),
    *sagas(Creators, { params, options }) {
      try {
        const resp = yield call(actions.getMemberPayments.api, params);

        yield put(Creators.getMemberPaymentsSuccess(resp?.data, resp?.pagination));
        if (options.onSuccess) {
          options.onSuccess(resp?.data, resp?.pagination);
        }
      } catch (error) {
        if (options.onError) {
          options.onError(error);
        }
        yield put(Creators.getMemberPaymentsError());
      }
    },
    params: {
      start: ['params'],
      error: [''],
      success: ['memberPayments', 'paginationPayments'],
    },
    state: loadingStates,
  },

  uploadMembers: {
    module: newChannelMembersModule,
    name: 'uploadMembers',
    api: ({ channelId, formData, keys }) =>
      api.post(`/channel_members/${channelId}/upload_members?keys=${keys ? 1 : 0}`, formData),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingUploadStates,
  },

  memberCheckin: {
    module: newChannelMembersModule,
    name: 'memberCheckin',
    api: (params) =>
      api.put(`/channel_members/${params.channelId}/check_in/${params?.userId}`, params),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingCheckinStates,
  },

  renewMembership: {
    module: newChannelMembersModule,
    name: 'renewMembership',
    api: (params) =>
      api.get(`/channel_members/${params.channelId}/renew/${params.channelKeyId}`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingRenewMembershipStates,
  },

  getMemberships: {
    module: newChannelMembersModule,
    name: 'getMemberships',
    api: (params) =>
      api.get(`/channel_memberships/${params.channelId}/member_memberships`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: ['memberships'],
    },
    state: loadingMembershipsStates,
  },

  cancelMembership: {
    module: newChannelMembersModule,
    name: 'cancelMembership',
    api: (params) =>
      api.put(`/channel_members/${params.channelId}/cancel/${params.channelKeyId}`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingCancelMembershipStates,
  },

  updateJoinedDate: {
    module: newChannelMembersModule,
    name: 'updateJoinedDate',
    api: (params) =>
      api.put(`/channel_members/${params.channelId}/update_joined_date/${params?.userId}`, params),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingJoinedDateStates,
  },

  updateMember: {
    module: newChannelMembersModule,
    name: 'updateMember',
    api: (params) =>
      api.put(`/channel_members/${params.channelId}/member/${params.userId}/update`, params),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingUpdateMemberStates,
  },

  exportChannelMembers: {
    module: newChannelMembersModule,
    name: 'exportChannelMembers',
    api: (params) => {
      const authHeaders = retrieveAuthHeaders(retrieveData)();
      return fetch(`${BASE_URL}/api/v1/channel_members/${params?.channelId}/export`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          ...authHeaders,
        },
        body: JSON.stringify(humps.decamelizeKeys(params)),
      });
    },
    *sagas(Creators, { params, options }) {
      try {
        const resp = yield call(actions.exportChannelMembers.api, params);

        resp.blob().then((f) => saveAs(f, params?.exportFileName || `${params?.channelId}.csv`));

        yield put(Creators.exportChannelMembersSuccess());
        if (options.onSuccess) {
          options.onSuccess(resp);
        }
      } catch (error) {
        if (options.onError) {
          options.onError(error);
        }
        yield put(Creators.exportChannelMembersError());
      }
    },

    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingExportStates,
  },
};

const channelMembersObj = {
  actions,
  state: {
    loadingExport: false,
    loading: false,
    loadingUpload: false,
    loadingCheckIn: false,
    loadingRenewMembership: false,
    loadingCancelMembership: false,
    loadingMemberships: false,

    memberPayments: [],
    members: [],

    memberships: {},

    pagination: null,
    paginationPayments: null,
  },
};

export default channelMembersObj;
