import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from 'core/ui/Box';
import Texture from 'core/ui/Texture';
import Typography from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import Form from './Form';

const ModalAddChild = ({ user, close, child, callback }) => {
  const { t } = useTranslation();
  return (
    <Dialog open onClose={close} maxWidth="xs" fullWidth scroll="body">
      <Texture>
        <Box p={5}>
          <Typography mb={3} variant="h5" color="primary" align="center">
            {t(child && child.id ? 'form.child.edit' : 'form.child.new')}
          </Typography>
          <Form close={close} child={child} user={user} callback={callback} />
        </Box>
      </Texture>
    </Dialog>
  );
};

ModalAddChild.defaultProps = {
  close: () => {},
  userId: null,
};

export default ModalAddChild;
