import React, { useEffect } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import { TableCell, TablePagination, withStyles } from '@material-ui/core';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useTranslation } from 'react-i18next';
import { paymentsActions, paymentsSelectors } from 'modules/payments';
import { useDispatch, useSelector } from 'react-redux';
import { secondary, secondaryLight } from 'core/ui/Colors';
import LoaderSm from 'core/ui/LoaderSm';
import Box from 'core/ui/Box';
import { ButtonPrimary } from 'core/ui/Button';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import notifications from 'modules/notifications';
import TypographyIcon from 'core/ui/TypographyIcon';
import { Email } from '@material-ui/icons';
import useSelectors from 'modules/map/useSelectors';
import { newPaymentsModule } from 'modules/payments/new';
import PaymentItem from './TableRow';
import ModalRetryPayment from './ModalRetryPayment';
import ModalRequiresAction from './ModalRequiresAction';

export const CellPayment = withStyles(() => ({
  head: {
    backgroundColor: secondaryLight,
    color: secondary,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

export default function TablePayments() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { request } = useActions();
  const loadingExport = useSelectors(newPaymentsModule, 'loading');
  const payments = useSelector((state) => paymentsSelectors.getPayments(state));
  const loading = useSelector((state) => paymentsSelectors.getLoading(state));

  const [page, setPage] = React.useState(0);
  const [openModalRetry, setOpenModalRetry] = React.useState({ open: false });
  const [openModalAction, setOpenModalAction] = React.useState({ open: false });
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const exportPayment = () => {
    request({
      action: Modules.newPayments.actions.exportPayments,
      options: {
        onSuccess: () => {
          dispatch(notifications.success(t('payments.export.success')));
        },
      },
    });
  };

  const getPayments = () => {
    dispatch(paymentsActions.getPaymentsStart());
  };

  useEffect(() => {
    getPayments();
  }, []); // eslint-disable-line

  return (
    <>
      {openModalRetry.open && (
        <ModalRetryPayment
          payment={openModalRetry.payment}
          open
          close={() => setOpenModalRetry({ open: false })}
        />
      )}
      {openModalAction.open && (
        <ModalRequiresAction
          open
          close={() => setOpenModalAction({ open: false })}
          callback={() => getPayments()}
          clientSecret={openModalAction?.payment?.nextActionSecret}
        />
      )}
      <Box display="flex" justifyContent="flex-end" mb={3}>
        <ButtonPrimary onClick={() => exportPayment()}>
          <LoaderSm loading={loadingExport} />
          <TypographyIcon text={t('button.export')} icon={<Email fontSize="small" />} />
        </ButtonPrimary>
      </Box>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <CellPayment />
              <CellPayment>{t('payment.column.paymentMethod')}</CellPayment>
              <CellPayment>{t('payment.column.type')}</CellPayment>
              <CellPayment align="center">{t('payment.column.date')}</CellPayment>
              <CellPayment align="center">{t('payment.column.status')}</CellPayment>
              <CellPayment align="right">{t('payment.column.amount')}</CellPayment>
              <CellPayment align="center" />
            </TableRow>
          </TableHead>
          <TableBody>
            {payments.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
              <PaymentItem
                key={row.id}
                payment={row}
                openModalRetry={() => setOpenModalRetry({ open: true, payment: row })}
                openAction={() => setOpenModalAction({ open: true, payment: row })}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box mt={3} className="hide-pagination-controls">
        <TablePagination
          component="div"
          count={payments.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Box>
      <Box display="flex" justifyContent="center">
        <LoaderSm loading={loading} />
      </Box>
    </>
  );
}
