import React from 'react';
import Box from 'core/ui/Box';
import { Skeleton } from '@material-ui/lab';

const SkeletonMemberInfo = () => (
  <Box>
    <Box display="flex">
      <Box mr={3}>
        <Skeleton variant="rect" animation="wave" style={{ height: 200, width: 180 }} />
      </Box>
      <Box mt={3}>
        <Skeleton variant="rect" animation="wave" style={{ height: 50, width: '100%' }} />
        <Skeleton
          variant="rect"
          animation="wave"
          style={{ height: 50, marginTop: 5, width: '100%' }}
        />
      </Box>
    </Box>
    <Box mt={3} display="flex">
      <Skeleton variant="rect" animation="wave" style={{ height: 200, width: '100%' }} />
    </Box>
    <Box mt={3} display="flex">
      <Skeleton variant="rect" animation="wave" style={{ height: 200, width: '100%' }} />
    </Box>
    <Box mt={3} display="flex">
      <Skeleton variant="rect" animation="wave" style={{ height: 200, width: '100%' }} />
    </Box>
  </Box>
);

export default SkeletonMemberInfo;
