import React, { useEffect, useState } from 'react';
import { ButtonPrimary } from 'core/ui/Button';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import Typography from 'core/ui/Typography';

import {
  TableContainer,
  TableHead,
  Table,
  TableCell,
  TableRow,
  TableBody,
  Paper,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { channelsSelectors } from 'modules/channels';
import { Add } from '@material-ui/icons';
import LoaderSm from 'core/ui/LoaderSm';
import useSelectors from 'modules/map/useSelectors';
import { channelProgramsModule } from 'modules/channelPrograms';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import ItemProgram from './ItemProgram';
import ModalForm from './Form/Modal';

const Programs = () => {
  const { t } = useTranslation();
  const channel = useSelector((state) => channelsSelectors.getChannel(state));
  const data = useSelectors(channelProgramsModule, 'data');
  const loading = useSelectors(channelProgramsModule, 'loading');
  const { request } = useActions();

  const [modal, setModal] = useState({
    open: false,
  });

  useEffect(() => {
    if (channel && channel.id) {
      request({
        action: Modules.channelPrograms.actions.getChannelPrograms,
        data: { channelId: channel.id },
      });
    }
  }, [request, channel]);

  const openModal = () => {
    setModal((prev) => ({ ...prev, mode: 'new', open: true }));
  };

  const onEditClick = (item) => {
    setModal((prev) => ({ ...prev, item, mode: 'edit', open: true }));
  };

  return (
    <Box p={5}>
      {modal.open && (
        <ModalForm
          open={modal.open}
          programId={modal.item?.id}
          mode={modal.mode}
          channel={channel}
          close={() => setModal(() => ({ open: false }))}
          callback={() => setModal(() => ({ open: false }))}
        />
      )}

      <Box display="flex" alignItems="center">
        <Box flexGrow="1" display="flex" alignItems="center">
          <LoaderSm loading={loading} />
        </Box>
        <Box>
          <ButtonPrimary onClick={() => openModal()}>
            <Box display="flex" alignItems="flex-end">
              <Add size="small" />
              <Box alignItems="center">
                <Typography component="span" mr={1}>
                  {t('button.addProgram')}
                </Typography>
              </Box>
            </Box>
          </ButtonPrimary>
        </Box>
      </Box>
      <Box mt={2}>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>{t('form.program.label.name')}</TableCell>
                <TableCell align="center">{t('form.program.label.active')}</TableCell>
                <TableCell align="center">{t('form.program.label.default')}</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data.map((row) => (
                  <ItemProgram
                    onEditClick={onEditClick}
                    key={row.name}
                    item={row}
                    expanded={modal.nameListExpand && modal.nameListExpand === row.name}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default Programs;
