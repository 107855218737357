import React from 'react';
import Box from 'core/ui/Box';
import Typography, { fontStyle } from 'core/ui/Typography';
import Grid from 'core/ui/Grid';
import Paper from 'core/ui/Paper';
import { secondaryXLight } from 'core/ui/Colors';
import { date } from 'core/utils/formatters';
import Divider from 'core/ui/Divider';
import { useTranslation } from 'react-i18next';
import ScheduleItemClass from './ScheduleItemClass';

const ScheduleList = ({ loading, calendarData }) => {
  const { t } = useTranslation();
  const styleDay = {
    backgroundColor: secondaryXLight,
    width: 100,
    borderRadius: 0,
  };

  return (
    <Paper p={5} className="paper-rounded">
      <Box>
        {!loading && calendarData && calendarData?.length === 0 && (
          <Typography align="center" m={5} variant="h1">
            {t('message.no.class.forDay')}
          </Typography>
        )}
        {calendarData &&
          calendarData.map((group, index) => (
            <Box key={index.toString()}>
              <Box mb={3} display="flex">
                <Box>
                  <Box
                    display="flex"
                    justifyContent="center"
                    flexDirection="column"
                    alignItems="center"
                    style={styleDay}
                    p={1}
                  >
                    <Typography style={fontStyle.xxLarge} align="center">
                      {date(group.title, 'D')}
                    </Typography>
                    <Typography style={fontStyle.large} align="center">
                      {date(group.title, 'ddd')}
                    </Typography>
                  </Box>
                </Box>
                <Box flexGrow={1} ml={3}>
                  <Grid container spacing={5} className="search-item">
                    {group.data &&
                      group.data.map((itemCalendar) => (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={4}
                          lg={4}
                          key={itemCalendar.workoutData.id}
                          className="item"
                        >
                          <ScheduleItemClass
                            workout={itemCalendar.workoutData}
                            datetime={itemCalendar.datetime}
                          />
                        </Grid>
                      ))}
                  </Grid>
                </Box>
              </Box>
              <Divider my={3} />
            </Box>
          ))}
      </Box>
    </Paper>
  );
};

export default ScheduleList;
