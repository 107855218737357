import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from '@material-ui/core';
import { LayoutContext } from 'pages/shared/Layout';
import { useParams } from 'react-router';
import { workoutsActions, workoutsSelectors } from 'modules/workouts';
import { competitionsActions, competitionsSelectors } from 'modules/competitions';
import Background from 'core/ui/Background';
import Paper from 'core/ui/Paper';
import useDeepLinks, { AppScreens } from 'core/useDeepLinks';
import { isMobile } from 'react-device-detect';
import DownloadApp from 'core/ui/ModalDownloadApp/DownloadApp';
import Typography from 'core/ui/Typography';
import Gallery from 'components/Competition/Workout/Watch/GalleryView/index';
import BannerCompetition from 'components/Competition/Show/BannerCompetition';

const CompetitionWorkoutWatch = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const layoutContext = useContext(LayoutContext);
  const workout = useSelector((state) => workoutsSelectors.getWorkout(state));
  const loadingWorkout = useSelector((state) => workoutsSelectors.getLoading(state));

  const loadingCompetition = useSelector((state) => competitionsSelectors.getLoading(state));
  const competition = useSelector((state) => competitionsSelectors.getCompetition(state));
  const { workoutId, competitionId } = useParams();

  const deep = useDeepLinks();

  useEffect(() => {
    deep.openWatchCompScreen({ screen: AppScreens.Competition.Watch, workoutId, competitionId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(workoutsActions.getWorkoutStart(workoutId));
    return () => {
      dispatch(workoutsActions.clearWorkout());
    };
  }, [dispatch, workoutId]);

  useEffect(() => {
    dispatch(competitionsActions.getCompetitionStart(competitionId));
    return () => {
      dispatch(competitionsActions.clearCompetition());
    };
  }, [dispatch, competitionId]);

  useEffect(() => {
    layoutContext.setTitleBar(t('title.watch').toUpperCase());
    layoutContext.setActiveMenu('');
  }, [layoutContext, t]);

  return (
    <Background
      hexBgColor={competition?.backgroundColor}
      imageUrl={competition?.backgroundImage?.image?.url}
      heightBanner={0}
      heightHeader={70}
    >
      <Container maxWidth="xl">
        {workout.allowWatch ? (
          <Box pt={5}>
            {isMobile ? (
              <Paper className="paper-rounded opacity-80" p={5}>
                <Typography variant="h5" color="primary" align="center" m={10}>
                  {t('competition.watch.message.mobile')}
                </Typography>
                <DownloadApp />
              </Paper>
            ) : (
              <>
                <Gallery
                  competition={competition}
                  workout={workout}
                  loading={loadingWorkout || loadingCompetition}
                />

                <Paper className="paper-rounded opacity-80" p={3}>
                  <Box style={{ position: 'relative' }}>
                    <BannerCompetition competition={competition} />
                  </Box>
                </Paper>
              </>
            )}
          </Box>
        ) : (
          <Typography m={5} variant="h5">
            {t('competition.watch.disabled')}
          </Typography>
        )}
      </Container>
    </Background>
  );
};

export default CompetitionWorkoutWatch;
