import React from 'react';
import ReactPlayer from 'react-player/vimeo';

import Box from 'core/ui/Box';
import Typography from 'core/ui/Typography';
import { useStyles } from './styles';

export const HelpVideoItem = ({ helpVideo, ...props }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.videoWrapper}>
        <ReactPlayer
          url={helpVideo.vimeoUrl}
          controls
          width="100%"
          height="100%"
          style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}
          {...props}
        />
      </Box>

      <Typography variant="h5" className={classes.title}>
        {helpVideo.title}
      </Typography>
    </Box>
  );
};
