/* eslint-disable eqeqeq */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import { IconButton, Tooltip } from '@material-ui/core';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import Typography, { fontStyle } from 'core/ui/Typography';
import { ButtonPrimary } from 'core/ui/Button';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import TextAreaFieldRounded from 'core/ui/TextAreaFieldRounded';
import Grid from 'core/ui/Grid';
import LoaderSm from 'core/ui/LoaderSm';
import CountryChosen from 'core/ui/CountryChosen';
import Checkbox from 'core/ui/Checkbox';
import { US_COUNTRY_ID } from 'core/utils/consts';
import { Delete, Info } from '@material-ui/icons';
import { secondary } from 'core/ui/Colors';
import SelectBoxSearch from 'core/ui/SelectBox/SelectBoxSearch';
import AdminChannelSelector from 'components/Admin/ChannelSelector';
import Paper from 'core/ui/Paper';
import Radio from 'core/ui/Radio';
import { useSelector } from 'react-redux';
import { sessionSelectors } from 'modules/session';
import useForm from './useForm';
import SelImages from './SelImages';
import Inventory from './Inventory';
import StoreCouponsSelector from './CouponsSelector';

const ModalFormProduct = ({
  currency,
  item,
  callback,
  mode,
  ownerableType,
  ownerableId,
  taxPercent,
  channelsTaxes,
  hasShopifyIntegration,
  customerAmounts,
  allowChangeAmounts,
}) => {
  const { t } = useTranslation();
  const isAdmin = useSelector((state) => sessionSelectors.getIsAdmin(state));

  const {
    setValue,
    watch,
    handleSubmit,
    onSubmit,
    errors,
    loading,
    categories,
    taxJatCategories,
    loadingTaxJarCategories,
    setImages,
    images,
    inventories,
    setInventories,
    taxByCounty,
    privateChannels,
    deleteChannel,
    addChannel,
    product,
  } = useForm({
    mode,
    productId: item?.id,
    callback,
    ownerableType,
    ownerableId,
    hasShopifyIntegration,
  });

  const deliveryWorldWide = watch('deliveryWorldWide');

  const isPartner = ownerableType === 'Partner';

  useEffect(() => {
    if (deliveryWorldWide) {
      setValue('deliveryCountryId', null);
    } else {
      setValue('deliveryCountryId', US_COUNTRY_ID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryWorldWide]);

  const addSalesTax = !!watch('addSalesTax');

  // eslint-disable-next-line no-nested-ternary
  const salesTax = addSalesTax
    ? ownerableType == 'Channel'
      ? channelsTaxes?.find((x) => x.id == watch('channelsTaxId')) || null
      : { amountType: 'percentage', amount: taxPercent }
    : null;

  useEffect(() => {
    if (!addSalesTax) {
      setInventories((prev) => prev.map((x) => ({ ...x, salesTax: 0 })));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addSalesTax]);

  const selectedCouponCodes = watch('storeDiscountCodeIds') || [];

  const toggleValue = (code) => {
    if (selectedCouponCodes?.find((x) => x === code?.id)) {
      setValue(
        'storeDiscountCodeIds',
        selectedCouponCodes?.filter((x) => x !== code?.id),
      );
    } else {
      setValue('storeDiscountCodeIds', [...selectedCouponCodes, code?.id]);
    }
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4} lg={4}>
          <SelImages setImages={setImages} images={images} />
        </Grid>
        <Grid item xs={12} md={8} lg={8}>
          <Grid container spacing={5}>
            <Grid item xs={6} md={6}>
              <Box mb={3}>
                <TextFieldRounded
                  value={watch('name') || ''}
                  onChange={(v) => setValue('name', v)}
                  placeholder={t('form.product.label.name')}
                  error={errors.name}
                  label={t('form.product.label.name').toUpperCase()}
                  required
                />
              </Box>
              <Box mb={3}>
                <SelectBoxObject
                  value={watch('storeCategoryId') || 0}
                  options={categories.filter((x) => !!x.active)}
                  propLabel="name"
                  propValue="id"
                  emptyItem
                  emptyValue={0}
                  setValue={(v) => setValue('storeCategoryId', v)}
                  placeholder={t('form.product.placeholder.category')}
                  error={errors.storeCategoryId}
                  label={t('form.product.label.category').toUpperCase()}
                  required
                />
              </Box>
            </Grid>

            <Grid item xs={6} md={6}>
              <TextAreaFieldRounded
                value={watch('description') || ''}
                onChange={(v) => setValue('description', v)}
                placeholder={t('form.product.label.description')}
                error={errors.description}
                label={t('form.product.label.description').toUpperCase()}
                required
              />
            </Grid>
          </Grid>
          <Box flexGrow={1}>
            <Box mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <Box display="flex" flexWrap="wrap">
                    <Box pl={5} pt={1}>
                      <Typography style={fontStyle.label} variant="body1">
                        {t('form.product.label.active').toUpperCase()}
                      </Typography>
                      <Box display="flex">
                        <Radio
                          checked={!!watch('active')}
                          label="Yes"
                          onClick={() => setValue('active', true)}
                        />
                        <Radio
                          ml={2}
                          checked={!watch('active')}
                          label="No"
                          onClick={() => setValue('active', false)}
                        />
                      </Box>
                    </Box>
                    {ownerableType === 'Partner' && (
                      <>
                        <Box pl={5} pt={1}>
                          <Typography style={fontStyle.label} variant="body1">
                            {t('form.product.label.partnerPrivate').toUpperCase()}
                          </Typography>

                          <Box display="flex">
                            <Radio
                              checked={!!watch('partnerPrivate')}
                              label="Yes"
                              onClick={() => setValue('partnerPrivate', true)}
                            />
                            <Radio
                              ml={2}
                              checked={!watch('partnerPrivate')}
                              label="No"
                              onClick={() => setValue('partnerPrivate', false)}
                            />
                          </Box>
                        </Box>
                        <Box pl={5} pt={1}>
                          <Typography style={fontStyle.label} variant="body1">
                            {t('form.product.label.allowPreSale').toUpperCase()}
                          </Typography>

                          <Box display="flex">
                            <Radio
                              checked={!!watch('allowPreSale')}
                              label="Yes"
                              onClick={() => setValue('allowPreSale', true)}
                            />
                            <Radio
                              ml={2}
                              checked={!watch('allowPreSale')}
                              label="No"
                              onClick={() => setValue('allowPreSale', false)}
                            />
                          </Box>
                        </Box>
                      </>
                    )}

                    {!isPartner && (
                      <Box pl={5} pt={1}>
                        <Box display="flex">
                          <Typography style={fontStyle.label} variant="body1">
                            {t('form.product.label.holdTransactions').toUpperCase()}
                          </Typography>

                          <Tooltip
                            style={{ zIndex: 99 }}
                            title={t('channel.product.info.holdTransaction')}
                          >
                            <Info style={{ fontSize: 24, color: secondary }} />
                          </Tooltip>
                        </Box>

                        <Box display="flex">
                          <Radio
                            checked={!!watch('holdTransactions')}
                            label="Yes"
                            onClick={() => setValue('holdTransactions', true)}
                          />
                          <Radio
                            ml={2}
                            checked={!watch('holdTransactions')}
                            label="No"
                            onClick={() => setValue('holdTransactions', false)}
                          />
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Grid>

                {!isPartner && (
                  <Grid item xs={12} md={4}>
                    <Box pl={5} pt={1}>
                      <Typography style={fontStyle.label} variant="body1">
                        {t('form.product.label.allowMemberPurchase').toUpperCase()}
                      </Typography>

                      <Box display="flex">
                        <Radio
                          checked={!watch('noMemberPurchase')}
                          label="Yes"
                          onClick={() => setValue('noMemberPurchase', false)}
                        />
                        <Radio
                          ml={2}
                          checked={!!watch('noMemberPurchase')}
                          label="No"
                          onClick={() => setValue('noMemberPurchase', true)}
                        />
                      </Box>
                    </Box>
                  </Grid>
                )}

                {!isPartner && (
                  <Grid item xs={12} md={4}>
                    <Box pl={5} pt={1}>
                      <Typography style={fontStyle.label} variant="body1">
                        {t('form.product.label.showInCompetitions').toUpperCase()}
                      </Typography>

                      <Box display="flex">
                        <Radio
                          checked={!!watch('showInCompetitions')}
                          label="Yes"
                          onClick={() => setValue('showInCompetitions', true)}
                        />
                        <Radio
                          ml={2}
                          checked={!watch('showInCompetitions')}
                          label="No"
                          onClick={() => setValue('showInCompetitions', false)}
                        />
                      </Box>
                    </Box>
                  </Grid>
                )}
                {hasShopifyIntegration && (
                  <Grid item xs={12} md={4}>
                    <Box display="flex">
                      <Box pl={5} pt={1}>
                        <Typography style={fontStyle.label} variant="body1">
                          {t('form.product.label.shopifyInventory').toUpperCase()}
                        </Typography>
                        <Box display="flex">
                          <Radio
                            checked={!!watch('shopifyInventory')}
                            label="Yes"
                            onClick={() => setValue('shopifyInventory', true)}
                          />
                          <Radio
                            ml={2}
                            checked={!watch('shopifyInventory')}
                            label="No"
                            onClick={() => setValue('shopifyInventory', false)}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Box>
            <Box mb={3}>
              <Box display="flex" flexWrap="wrap">
                <Box pl={5} pt={1}>
                  <Typography style={fontStyle.label} variant="body1">
                    {t('form.product.label.offerDelivery').toUpperCase()}
                  </Typography>

                  <Box display="flex">
                    <Radio
                      checked={!!watch('offerDelivery')}
                      label="Yes"
                      onClick={() => setValue('offerDelivery', true)}
                    />
                    <Radio
                      ml={2}
                      checked={!watch('offerDelivery')}
                      label="No"
                      onClick={() => setValue('offerDelivery', false)}
                    />
                  </Box>
                </Box>
                <Box ml={3}>
                  <Box pt={1} display="flex">
                    <Box display="flex" flexDirection="column" alignItems="center">
                      <Typography mb={2} align="center" style={fontStyle.label} variant="body1">
                        {t('form.product.label.deliveryWorldWide').toUpperCase()}
                      </Typography>

                      <Checkbox
                        checked={!!watch('deliveryWorldWide')}
                        onClick={() => setValue('deliveryWorldWide', !watch('deliveryWorldWide'))}
                      />
                    </Box>

                    {!watch('deliveryWorldWide') && (
                      <Box display="flex" ml={2}>
                        <CountryChosen
                          label={t('form.product.label.deliveryOnly').toUpperCase()}
                          setValue={(v) => setValue('deliveryCountryId', v)}
                          defaultValue={
                            watch('deliveryCountryId') ? `${watch('deliveryCountryId')}` : 16
                          }
                        />
                      </Box>
                    )}
                  </Box>
                </Box>

                <Box pl={5} pt={1}>
                  <Typography style={fontStyle.label} variant="body1">
                    {t('channel.product.label.addSalesTax').toUpperCase()}
                  </Typography>

                  <Box display="flex">
                    <Radio
                      checked={!!watch('addSalesTax')}
                      label="Yes"
                      onClick={() => setValue('addSalesTax', true)}
                    />
                    <Radio
                      ml={2}
                      checked={!watch('addSalesTax')}
                      label="No"
                      onClick={() => setValue('addSalesTax', false)}
                    />
                  </Box>
                </Box>
                {addSalesTax && ownerableType === 'Channel' && (
                  <Box ml={3} flexGrow={1} pt={1}>
                    <SelectBoxObject
                      options={channelsTaxes}
                      value={watch('channelsTaxId')}
                      setValue={(v) => setValue('channelsTaxId', v)}
                      label={t('channel.label.selectTheTax').toUpperCase()}
                      bordered
                    />
                  </Box>
                )}

                {watch('offerDelivery') && (
                  <Box ml={3} pt={1}>
                    <TextFieldRounded
                      value={watch('deliveryPrice') || ''}
                      onChange={(v) => setValue('deliveryPrice', v)}
                      label={t('form.product.label.deliveryPrice').toUpperCase()}
                      placeholder={t('form.product.label.deliveryPrice')}
                      type="number"
                      pattern="[0-9]*"
                      currency
                      currencySymbol={currency?.symbol}
                      style={{ textAlign: 'right' }}
                    />
                  </Box>
                )}
              </Box>
              {taxByCounty && watch('addSalesTax') && (
                <Box>
                  <SelectBoxSearch
                    value={taxJatCategories?.find(
                      (x) => x.productTaxCode === watch('taxJarProductTaxCode'),
                    )}
                    options={taxJatCategories}
                    getOptionLabel={(option) => option.name}
                    renderOption={(option) => (
                      <Typography style={fontStyle.medium}>{option.name}</Typography>
                    )}
                    getOptionSelected={(option, value) => option?.productTaxCode === value}
                    setValue={(v) => setValue('taxJarProductTaxCode', v?.productTaxCode)}
                    placeholder={t('form.product.placeholder.category')}
                    error={errors.taxJarProductTaxCode}
                    label={
                      <Typography>
                        <LoaderSm loading={loadingTaxJarCategories} />
                        {t('form.product.label.taxJarProductTaxCode').toUpperCase()}
                      </Typography>
                    }
                    required
                  />
                </Box>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Grid container my={3} spacing={3}>
        {watch('partnerPrivate') && (
          <Grid item xs={12} md={6}>
            <Box>
              <Box>
                <AdminChannelSelector
                  selectedChannel={null}
                  setSelectedChannel={(v) => addChannel(v)}
                  label={t('form.product.privateChannels')}
                />
              </Box>

              {privateChannels
                ?.filter((x) => !x?._destroy)
                ?.map((channel) => (
                  <Paper p={1} px={3}>
                    <Box display="flex" alignItems="center">
                      <Box flexGrow={1}>
                        <Typography>{channel?.channelName}</Typography>
                      </Box>
                      <IconButton onClick={() => deleteChannel(channel)}>
                        <Delete />
                      </IconButton>
                    </Box>
                  </Paper>
                ))}
            </Box>
          </Grid>
        )}
        {(isAdmin || allowChangeAmounts) && isPartner && (
          <Grid item xs={12} md={6}>
            <Paper p={3}>
              <Typography variant="h5">{t('product.partner.taxAmounts')}</Typography>
              <Typography mb={2} style={fontStyle.xSmall}>
                {t('product.partner.taxAmounts.default')}
              </Typography>
              <Grid container spacing={5}>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <Box display="flex">
                    <Radio
                      label={t('form.partner.perc')}
                      checked={watch('amounts.streamAmountType') === '%'}
                      onClick={() => setValue('amounts.streamAmountType', '%')}
                    />
                    <Box ml={2}>
                      <Radio
                        label={t('form.partner.money')}
                        checked={watch('amounts.streamAmountType') === '$'}
                        onClick={() => setValue('amounts.streamAmountType', '$')}
                      />
                    </Box>
                  </Box>

                  <TextFieldRounded
                    placeholder={t('form.partner.streamAmount')}
                    label={t('form.partner.streamAmount').toUpperCase()}
                    value={watch('amounts.streamAmount')}
                    onChange={(v) => setValue('amounts.streamAmount', v)}
                    error={errors?.amounts?.streamAmount}
                    type="number"
                    pattern="[1-9]*"
                    bordered
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <Box display="flex">
                    <Radio
                      label={t('form.partner.perc')}
                      checked={watch('amounts.gymAmountType') === '%'}
                      onClick={() => setValue('amounts.gymAmountType', '%')}
                    />
                    <Box ml={2}>
                      <Radio
                        label={t('form.partner.money')}
                        checked={watch('amounts.gymAmountType') === '$'}
                        onClick={() => setValue('amounts.gymAmountType', '$')}
                      />
                    </Box>
                  </Box>

                  <TextFieldRounded
                    placeholder={t('form.partner.gymAmount')}
                    label={t('form.partner.gymAmount').toUpperCase()}
                    value={watch('amounts.gymAmount')}
                    onChange={(v) => setValue('amounts.gymAmount', v)}
                    error={errors?.amounts?.gymAmount}
                    type="number"
                    pattern="[1-9]*"
                    bordered
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <Box display="flex">
                    <Radio
                      label={t('form.partner.perc')}
                      checked={watch('amounts.customerAmountType') === '%'}
                      onClick={() => setValue('amounts.customerAmountType', '%')}
                    />
                    <Box ml={2}>
                      <Radio
                        label={t('form.partner.money')}
                        checked={watch('amounts.customerAmountType') === '$'}
                        onClick={() => setValue('amounts.customerAmountType', '$')}
                      />
                    </Box>
                  </Box>

                  <TextFieldRounded
                    placeholder={t('form.partner.customerAmount')}
                    label={t('form.partner.customerAmount').toUpperCase()}
                    value={watch('amounts.customerAmount')}
                    onChange={(v) => setValue('amounts.customerAmount', v)}
                    error={errors?.amounts?.customerAmount}
                    type="number"
                    pattern="[1-9]*"
                    bordered
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <Box>
            <StoreCouponsSelector
              ownerableId={ownerableId}
              ownerableType={ownerableType}
              selected={watch('storeDiscountCodeIds')}
              setSelected={(coupon) => toggleValue(coupon)}
            />
          </Box>
        </Grid>
      </Grid>

      <Box>
        <Inventory
          addSalesTax={addSalesTax}
          salesTax={salesTax}
          product={product}
          currency={currency}
          inventories={[...inventories]}
          setInventories={setInventories}
          isPartner={ownerableType === 'Partner'}
          hasShopifyIntegration={hasShopifyIntegration}
          shopifyInventory={watch('shopifyInventory')}
          customerAmounts={
            watch('amounts.customerAmount')
              ? {
                  type: watch('amounts.customerAmountType'),
                  amount: watch('amounts.customerAmount'),
                }
              : customerAmounts
          }
        />
      </Box>

      <Box mt={3} display="flex" justifyContent="flex-end">
        <ButtonPrimary disabled={loading} onClick={handleSubmit(onSubmit)}>
          <LoaderSm loading={loading} />
          {t('button.save')}
        </ButtonPrimary>
      </Box>
    </>
  );
};

ModalFormProduct.defaultProps = {
  callback: () => {},
  item: null,
  mode: 'new',
};

ModalFormProduct.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  callback: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.object,
  mode: PropTypes.string,
};

export default ModalFormProduct;
