/* eslint-disable react/prop-types */
import React from 'react';
import { primary, secondary } from 'core/ui/Colors';
import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import { parse } from 'query-string';
import Box from 'core/ui/Box';
import FormCardPointe from 'components/Account/CardPointe/Form';

export default () => {
  const location = useLocation();
  const { userId } = useParams();

  const parsed = parse(location.search);

  const formBgColor = parsed.bgColor || secondary;
  const formButtonColor = parsed.buttonColor || primary;
  const showCanadaDebit = parsed.showCanadaDebit || '';

  const handleSubmit = async (pmData) => {
    try {
      window.ReactNativeWebView.postMessage(JSON.stringify(pmData));
    } catch {
      alert('Error to create payment method');
    }
  };

  return (
    <Box style={{ height: '100vh', backgroundColor: formBgColor, padding: 10 }}>
      <FormCardPointe
        userId={userId}
        setView={() => {}}
        setSelectedPaymentMethod={(method) => handleSubmit(method)}
        closeModal={() => {}}
        // eslint-disable-next-line eqeqeq
        showCanadaDebit={showCanadaDebit == 1}
        onlyMethod={
          parsed.onlyMethod === 'card ' || parsed.onlyMethod === 'ach' ? parsed.onlyMethod : null
        }
        buttonColor={formButtonColor}
        embedView
      />
    </Box>
  );
};
