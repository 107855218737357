import React from 'react';
import { useTranslation } from 'react-i18next';
import { channelCurrency, gymDate, time } from 'core/utils/formatters';
import Box from 'core/ui/Box';
import Typography from 'core/ui/Typography';
import EventTabs from './Tabs';
import ChannelsEventRegistrations from './Registration/List';

const EventInfo = ({ event, channel, tab, setTab, refreshEvent }) => {
  const { t } = useTranslation();

  return (
    <div className="tw-mb-2 tw-flex tw-flex-col tw-gap-2">
      <div className="tw-grid tw-grid-cols-1 tw-gap-4 md:tw-grid-cols-6">
        <div className="tw-col-span-2">
          <Box>
            <div className="tw-aspect-video tw-overflow-hidden tw-rounded-md tw-bg-white">
              <img
                draggable="false"
                src={event?.image?.image?.url || channel?.image?.image?.url}
                alt=""
                className="tw-w-full"
              />
            </div>
          </Box>
        </div>

        <div className="h-full tw-col-span-4">
          <div className="flex-1 tw-flex tw-flex-col tw-gap-2 tw-rounded-md tw-bg-white tw-p-3">
            <Typography className="tw-text-lg tw-font-bold">{event?.name}</Typography>
            <div className="tw-flex tw-flex-row tw-gap-2">
              <Typography className="tw-font-semibold">{t('label.startAt')}</Typography>
              <Typography>
                {gymDate(event?.startAt)}&nbsp;&bull;&nbsp;{time(event?.startAt)}
              </Typography>
            </div>
            <div className="tw-flex tw-flex-row tw-gap-2">
              <Typography className="tw-font-semibold">{t('label.endAt')}</Typography>
              <Typography>
                {gymDate(event?.endAt)}&nbsp;&bull;&nbsp;{time(event?.endAt)}
              </Typography>
            </div>
            <div className="tw-flex tw-flex-row tw-items-center tw-gap-2">
              <Typography className="tw-font-semibold">{t('label.location')}</Typography>
              <Typography>{event?.location?.name}</Typography>
            </div>
            {event?.price && (
              <div className="tw-flex tw-flex-row tw-gap-2">
                <Typography className="tw-font-semibold">{t('label.price')}</Typography>
                <Typography>{channelCurrency(event?.price, channel?.currency)}</Typography>
              </div>
            )}
            {event?.tags && (
              <div className="tw-flex tw-flex-row tw-items-center tw-gap-2">
                {event?.tags?.map((tag) => (
                  <div key={tag.id} className="tw-rounded-md tw-bg-gray-300 tw-p-1">
                    <Typography className="tw-text-xs tw-font-semibold">#{tag?.name}</Typography>
                  </div>
                ))}
              </div>
            )}
          </div>
          {setTab && (
            <EventTabs
              refreshEvent={refreshEvent}
              setTab={setTab}
              tab={tab}
              channel={channel}
              event={event}
            />
          )}
        </div>
      </div>

      {tab === 'info' && (
        <div className="tw-rounded-md tw-bg-white tw-p-2">
          <div dangerouslySetInnerHTML={{ __html: event?.text }} className="roboto-regular" />
        </div>
      )}
      {tab === 'registrations' && <ChannelsEventRegistrations event={event} />}
    </div>
  );
};

export default EventInfo;
