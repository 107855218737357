import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Delete, Edit } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { currency, date } from 'core/utils/formatters';

export default function ItemList({ channel, item, onEditClick, onRemoveClick }) {
  const { t } = useTranslation();
  return (
    <TableRow>
      <TableCell>{item?.ratesTemplate?.name}</TableCell>
      <TableCell>{t(`channel.rateTemplate.kind.${item?.ratesTemplate?.kind}`)}</TableCell>
      <TableCell>{currency(item?.ratesTemplate?.amount)}</TableCell>
      <TableCell>{date(item?.ratesTemplate?.startDate, channel?.dateFormatReact)}</TableCell>
      <TableCell>
        {item?.ratesTemplate?.endDate && date(item?.endDate, channel?.dateFormatReact)}
      </TableCell>
      <TableCell align="right">
        <IconButton onClick={() => onEditClick(item)}>
          <Edit />
        </IconButton>
      </TableCell>
      <TableCell align="right">
        <IconButton onClick={() => onRemoveClick(item)}>
          <Delete />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

ItemList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.object.isRequired,
  onEditClick: PropTypes.func.isRequired,
};
