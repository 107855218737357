import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import TimePickerRounded from 'core/ui/DatePickers/TimePickerRounded';
import { FileCopy, RemoveCircleOutline } from '@material-ui/icons';
import Divider from 'core/ui/Divider';
import WeekDaysCheck from 'components/Workout/Form/Schedule/WeekDaysCheck';
import SelectBoxObject from 'core/ui/SelectBoxObject';

const ScheduleOption = ({ index, option, manage, channel }) => {
  const { t } = useTranslation();

  const setScheduleOption = (key, value) => {
    const arr = [...manage?.scheduleOptions];

    arr[index][key] = value;
    manage.setScheduleOptions(arr);
  };

  const cloneOption = () => {
    const arr = [...manage.scheduleOptions, { ...option }];

    manage.setScheduleOptions(arr);
  };

  const removeScheduleOption = () => {
    const arr = [...manage.scheduleOptions];
    const newArray = [];
    arr.forEach((e, i) => {
      if (i !== index) {
        newArray.push(e);
      }
    });
    manage.setScheduleOptions(newArray);
  };

  return (
    <Box>
      <Box display="flex" flexWrap="wrap" alignItems="flex-end">
        {channel.workoutsTypes?.length > 0 && (
          <Box m={1}>
            <SelectBoxObject
              options={channel.workoutsTypes}
              label={t('channel.label.workoutsType').toUpperCase()}
              value={option?.channelsWorkoutsTypeId}
              setValue={(v) => setScheduleOption('channelsWorkoutsTypeId', v)}
              bordered
            />
          </Box>
        )}
        <Box m={1}>
          <TimePickerRounded
            name="scheduledAt"
            type="datetime-local"
            label={t('workout.label.scheduledAtTime').toUpperCase()}
            onChange={(v) => setScheduleOption('startTime', v)}
            value={option.startTime}
            required
            bordered
          />
        </Box>

        <Box m={1}>
          <TimePickerRounded
            name="scheduledAt"
            type="datetime-local"
            label={t('workout.label.endAtHour').toUpperCase()}
            onChange={(v) => setScheduleOption('endTime', v)}
            value={option.endTime}
            required
            bordered
          />
        </Box>

        <Box m={1} mx={5} pb={1.3}>
          <WeekDaysCheck
            setWeekDays={(v) => setScheduleOption('weekDays', v)}
            weekDays={option.weekDays || []}
            label={t('coaches.schedules.days').toUpperCase()}
          />
        </Box>

        <Box style={{ width: 40 }}>
          <Box onClick={() => removeScheduleOption(index)} ml={2} mt={8} className="link">
            <RemoveCircleOutline color="primary" />
          </Box>
        </Box>

        <Box ml={1} onClick={() => cloneOption(option)}>
          <FileCopy />
        </Box>
      </Box>
      <Divider my={2} mx={5} />
    </Box>
  );
};

export default ScheduleOption;
