import React, { useContext, useEffect, useState } from 'react';
import Box from 'core/ui/Box';
import { ChannelContext } from 'components/Channel/Manage/index';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import useSelectors from 'modules/map/useSelectors';
import { channelReportsModule } from 'modules/channelReports';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { sessionSelectors } from 'modules/session';
import MembershipReport from './Membership';
import PaymentsReport from './Payments';
import ClassPerformanceReport from './ClassPerformance';
import AtRiskMembersReport from './AtRiskMembers';
import CoachesReport from './Coaches';
import StoreOrders from './StoreOrders';
import WaiversReport from './Waivers';
import HybridTracks from './HybridTracks';
import Birthday from './Birthday';
import Attendance from './Attendance';
import menuItems from '../../menuItems';
import ScheduleMembership from './ScheduleMembership';
import PausedMembers from './PausedMembers';
import Payouts from './Payouts';
import ForeCastReport from './Forecast';
import Anniversaries from './Anniversaries';
import MembersConversions from './MembersConversions';
import MembersChurn from './MembersChurn';
import MembersGrowth from './MembersGrowth';
import ActionReport from './ActionReport';
import SifelyLogs from './SifelyLogs';
import ProductsInventories from './ProductsInventories';
import MembershipsDetails from './MembershipsDetails';

export default function Reports() {
  const { param1, defaultTag } = useParams();
  const channelContext = useContext(ChannelContext);
  const currentUser = useSelector((state) => sessionSelectors.getUser(state));

  const { request } = useActions();
  const loadingReport = useSelectors(channelReportsModule, 'loading');
  const [currentReport, setCurrentReport] = useState(null);

  const findReport = (p) => {
    let item = null;
    // eslint-disable-next-line consistent-return
    menuItems.reportsMenus(channelContext?.channel, currentUser).forEach((x) => {
      if (!item) {
        item = x.items.find((y) => y.value.toLowerCase() === p?.toLowerCase());
        return item;
      }
    });

    return item;
  };

  useEffect(() => {
    if (param1 && defaultTag === 'reports') {
      setCurrentReport(findReport(param1)?.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param1, channelContext?.channel]);

  useEffect(() => {
    if (currentReport) {
      window.history.replaceState(
        null,
        'StreamFit',
        `/channel/manage/${channelContext?.channel?.id}/reports/${currentReport?.toLowerCase()}`,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentReport]);

  const getReport = (params = {}) => {
    if (!currentReport) {
      return;
    }
    if (params?.exportReport === true) {
      request({
        action: Modules.channelReports.actions.exportChannelReport,
        data: {
          channelId: channelContext.channel.id,
          report: currentReport,
          export: true,
          ...params,
        },
      });
      return;
    }
    request({
      action: Modules.channelReports.actions.channelReport,
      data: {
        channelId: channelContext.channel.id,
        report: currentReport,
        export: false,
        ...params,
      },
    });
  };

  if (!channelContext.channel.id) {
    return <></>;
  }
  return (
    <Box p={5}>
      <>
        {currentReport === 'Memberships' && (
          <MembershipReport loading={loadingReport} getReport={getReport} />
        )}
        {currentReport === 'MembersConversions' && (
          <MembersConversions
            channel={channelContext?.channel}
            loading={loadingReport}
            getReport={getReport}
          />
        )}
        {currentReport === 'MembersChurn' && (
          <MembersChurn
            channel={channelContext?.channel}
            loading={loadingReport}
            getReport={getReport}
          />
        )}
        {currentReport === 'MembersGrowth' && (
          <MembersGrowth
            channel={channelContext?.channel}
            loading={loadingReport}
            getReport={getReport}
          />
        )}
        {currentReport === 'Forecast' && (
          <ForeCastReport
            loading={loadingReport}
            getReport={getReport}
            channel={channelContext.channel}
          />
        )}
        {currentReport === 'Payments' && (
          <PaymentsReport
            channel={channelContext.channel}
            loading={loadingReport}
            getReport={getReport}
          />
        )}
        {currentReport === 'Payouts' && (
          <Payouts channel={channelContext.channel} loading={loadingReport} getReport={getReport} />
        )}
        {currentReport === 'ScheduledMemberships' && (
          <ScheduleMembership
            channel={channelContext.channel}
            loading={loadingReport}
            getReport={getReport}
          />
        )}
        {currentReport === 'PausedMembers' && (
          <PausedMembers
            loading={loadingReport}
            getReport={getReport}
            channel={channelContext?.channel}
          />
        )}
        {currentReport === 'ProductsInventories' && (
          <ProductsInventories
            loading={loadingReport}
            getReport={getReport}
            channel={channelContext?.channel}
          />
        )}

        {currentReport === 'ActionReport' && (
          <ActionReport
            channel={channelContext.channel}
            loading={loadingReport}
            getReport={getReport}
          />
        )}
        {currentReport === 'ClassPerformance' && (
          <ClassPerformanceReport
            channel={channelContext?.channel}
            loading={loadingReport}
            getReport={getReport}
          />
        )}
        {currentReport === 'AtRiskMembers' && (
          <AtRiskMembersReport
            channel={channelContext.channel}
            loading={loadingReport}
            getReport={getReport}
          />
        )}
        {currentReport === 'MembershipsDetails' && (
          <MembershipsDetails
            channel={channelContext.channel}
            loading={loadingReport}
            getReport={getReport}
          />
        )}
        {currentReport === 'Coaches' && (
          <CoachesReport
            channel={channelContext.channel}
            loading={loadingReport}
            getReport={getReport}
          />
        )}
        {currentReport === 'StoreOrders' && (
          <StoreOrders loading={loadingReport} getReport={getReport} />
        )}
        {currentReport === 'Waivers' && (
          <WaiversReport
            channel={channelContext?.channel}
            loading={loadingReport}
            getReport={getReport}
          />
        )}
        {currentReport === 'Birthday' && (
          <Birthday
            channel={channelContext.channel}
            loading={loadingReport}
            getReport={getReport}
          />
        )}
        {currentReport === 'Anniversaries' && (
          <Anniversaries
            channel={channelContext.channel}
            loading={loadingReport}
            getReport={getReport}
          />
        )}
        {currentReport === 'AttendanceReport' && (
          <Attendance
            genericCheckIn={channelContext?.channel?.genericCheckIn}
            loading={loadingReport}
            getReport={getReport}
            channel={channelContext?.channel}
          />
        )}
        {channelContext?.channel?.hasHybrid && currentReport === 'HybridTracks' && (
          <HybridTracks
            channel={channelContext?.channel}
            loading={loadingReport}
            getReport={getReport}
          />
        )}

        {channelContext?.channel?.activePlans?.sifely.active && currentReport === 'SifelyLogs' && (
          <SifelyLogs
            channel={channelContext?.channel}
            loading={loadingReport}
            getReport={getReport}
          />
        )}
      </>
    </Box>
  );
}
