/* eslint-disable import/prefer-default-export */
import { all, call, put } from 'redux-saga/effects';

import { getErrorMessage } from 'core/api/api-error';

import notifications from 'modules/notifications';

import * as api from './api';
import { competitionsSearchActions as actions } from './index';

export function* getSearch({ data }) {
  try {
    const response = yield call(api.search, data);
    yield put(actions.getSearchSuccess(response.data, response.pagination));
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.getSearchError(getErrorMessage(error))),
    ]);
  }
}

export function* getRecents({ data }) {
  try {
    const response = yield call(api.recents, data);
    yield put(actions.getRecentsSuccess(response.data, response.pagination));
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.getRecentsError(getErrorMessage(error))),
    ]);
  }
}

export function* getUpcoming({ data }) {
  try {
    const response = yield call(api.upcoming, data);
    yield put(actions.getUpcomingSuccess(response.data, response.pagination));
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.getUpcomingError(getErrorMessage(error))),
    ]);
  }
}

export function* getCurrent({ data }) {
  try {
    const response = yield call(api.current, data);
    yield put(actions.getCurrentSuccess(response.data, response.pagination));
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.getCurrentError(getErrorMessage(error))),
    ]);
  }
}
