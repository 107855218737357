import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import Typography, { fontStyle } from 'core/ui/Typography';
import Radio from 'core/ui/Radio';

export default ({ setValue, watch }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography style={fontStyle.label} variant="body1">
        {t('workout.label.visibility').toUpperCase()}
      </Typography>

      <Box display="flex" mt={2}>
        <Radio
          label={t('workout.label.type.public')}
          checked={watch('isPrivate') === false || watch('isPrivate') === undefined}
          onClick={() => setValue('isPrivate', false)}
          mr={2}
        />

        <Radio
          label={t('workout.label.type.private')}
          checked={watch('isPrivate') === true}
          onClick={() => setValue('isPrivate', true)}
        />
      </Box>
    </Box>
  );
};
