import { all, call, put } from 'redux-saga/effects';

import { getErrorMessage } from 'core/api/api-error';

import notifications from 'modules/notifications';

import * as api from './api';
import { messageListsActions as actions } from './index';

export function* getMessageLists() {
  try {
    const response = yield call(api.getMessageLists);
    const messageLists = response.data;
    yield put(actions.getMessageListsSuccess(messageLists));
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.getMessageListsError(getErrorMessage(error))),
    ]);
  }
}

export function* getMessageListContacts({ listId, query, page, perPage }) {
  try {
    const response = yield call(api.getMessageListContacts, listId, query, page, perPage);
    yield put(actions.getMessageListContactsSuccess(response.data, response.pagination));
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.getMessageListContactsError(getErrorMessage(error))),
    ]);
  }
}

export function* getMessageList({ messageListId }) {
  try {
    const response = yield call(api.getMessageList, messageListId);
    const messageLists = response.data;
    yield put(actions.getMessageListSuccess(messageLists));
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.getMessageListError(getErrorMessage(error))),
    ]);
  }
}

export function* postMessageLists({ data, callback }) {
  try {
    const response = yield call(api.postMessageLists, data);
    const messageLists = response.data;
    yield put(actions.postMessageListsSuccess(messageLists));
    if (data.messageSuccess) {
      yield put(notifications.success(data.messageSuccess));
    }
    if (callback) {
      callback();
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.postMessageListsError(getErrorMessage(error))),
    ]);
  }
}

export function* putMessageLists({ data, callback }) {
  try {
    const response = yield call(api.putMessageLists, data);
    const messageLists = response.data;
    yield put(actions.putMessageListsSuccess(messageLists));
    if (data.messageSuccess) {
      yield put(notifications.success(data.messageSuccess));
    }
    if (callback) {
      callback();
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.putMessageListsError(getErrorMessage(error))),
    ]);
  }
}

export function* deleteContactList({ messageListId, contactId, callback }) {
  try {
    const response = yield call(api.deleteContactList, messageListId, contactId);
    const messageLists = response.data;
    yield put(actions.deleteContactListSuccess(messageLists));
    if (callback) {
      callback();
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.deleteContactListError(getErrorMessage(error))),
    ]);
  }
}
