import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import {
  TableContainer,
  TableHead,
  Table,
  TableCell,
  TableRow,
  TableBody,
  Paper,
  IconButton,
} from '@material-ui/core';

import Typography, { fontStyle } from 'core/ui/Typography';
import { onlineColor, redColor } from 'core/ui/Colors';

import { Edit } from '@material-ui/icons';
import { ChannelContext } from '..';

const ListLocations = ({ onEditClick }) => {
  const { t } = useTranslation();
  const formContext = useContext(ChannelContext);

  return (
    <Box mt={2}>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>{t('form.locations.label.location')}</TableCell>
              <TableCell align="center">{t('form.locations.label.active')}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {formContext.locations &&
              formContext.locations.map((row, index) => (
                <TableRow key={index.toString()}>
                  <TableCell>
                    <Box>
                      <Typography variant="body1" style={fontStyle.small} ml={1} color="secondary">
                        {row.name}
                      </Typography>
                      <Box display="flex" flexDirection=" column">
                        <Typography style={fontStyle.xSmall} ml={1} color="textSecondary">
                          {row.street1},{row.street2}
                        </Typography>
                        <Typography style={fontStyle.xSmall} ml={1} color="textSecondary">
                          {row.state}/{row.city}&nbsp;-&nbsp;{row.zip}
                        </Typography>
                      </Box>
                    </Box>
                  </TableCell>

                  <TableCell align="center">
                    {!row.active ? (
                      <RadioButtonUncheckedIcon style={{ ...fontStyle.medium, color: redColor }} />
                    ) : (
                      <CheckCircleOutlineIcon style={{ ...fontStyle.medium, color: onlineColor }} />
                    )}
                  </TableCell>
                  <TableCell align="right">
                    <IconButton onClick={() => onEditClick(row)}>
                      <Edit />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

ListLocations.propTypes = {
  onEditClick: PropTypes.func.isRequired,
};

export default ListLocations;
