import React, { useEffect, useRef, useState } from 'react';
import Box from 'core/ui/Box';
import useWindowSize from 'core/utils/hook/useWindowSize';
import ItemGallery from './ItemGallery';

export default ({ player, recordings, workout, buttonColor }) => {
  const [activeRecordings, setActiveRecordings] = useState([]);
  const boxVideo = useRef();
  const boxVideoLarge = useRef();

  const setVideoConfig = (duration = false) => {
    let total = 0;
    let mainId = 0;
    if (workout && recordings) {
      recordings.forEach((rec) => {
        const totalSeconds = Number(rec.durationSeconds) - Number(rec.startCountdownSeconds);

        if (total < totalSeconds) {
          total = totalSeconds;
          mainId = rec.id;
        }
      });
    }

    if (duration) {
      player.setTime((prev) => ({ ...prev, total }));
    } else {
      player.setTime((prev) => ({ ...prev, mainId, totalMain: total }));
    }
  };

  useEffect(() => {
    if (workout && recordings) {
      setVideoConfig(true);
      setVideoConfig(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workout]);

  const isFullScreen = (userId) => !!activeRecordings.find((x) => x === userId);

  const setRecordingFullScreen = (userId) => {
    let actives = [];
    if (isFullScreen(userId)) {
      actives = activeRecordings.filter((x) => x !== userId);
    } else {
      actives = [...activeRecordings, userId];
    }
    setActiveRecordings(actives);
  };

  const size = useWindowSize();
  const getSize = (rec, newSize) => {
    let width = newSize;

    const resWidth = rec?.resWidth || 230;
    const resHeight = rec?.resHeight || 180;

    let height = Math.round((width / resWidth) * resHeight, 0);
    if (height > size.height) {
      height = size.height;
      width = (height / resHeight) * resWidth;
    }

    return {
      width,
      height,
    };
  };

  return (
    <Box>
      <div className="wrapper-rec" style={{ maxHeight: 0 }}>
        <div className="box-rec" ref={boxVideo} />
        <div className="box-rec active-rec" ref={boxVideoLarge} />
      </div>
      <Box mt={3} className="wrapper-rec">
        {recordings &&
          recordings.map((rec, index) => (
            <ItemGallery
              key={index.toString()}
              recording={rec}
              index={index}
              player={player}
              boxVideo={boxVideo.current}
              buttonColor={buttonColor}
              boxVideoLarge={boxVideoLarge.current}
              setRecordingFullScreen={setRecordingFullScreen}
              getSize={getSize}
              isFullScreen={isFullScreen}
            />
          ))}
      </Box>
    </Box>
  );
};
