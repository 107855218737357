import api from 'core/api';
import { call, put } from 'redux-saga/effects';

export const competitionsWaitlistsModule = 'competitionsWaitlists';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const actions = {
  registerWaitlist: {
    module: competitionsWaitlistsModule,
    name: 'registerWaitlist',
    api: (data) => api.post(`/competitions/${data?.competitionId}/competitions_waitlists`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  deleteWaitlist: {
    module: competitionsWaitlistsModule,
    name: 'deleteWaitlist',
    api: (params) =>
      api.delete(`/competitions/${params?.competitionId}/competitions_waitlists/${params?.id}`),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  getCompetitionsWaitlists: {
    module: competitionsWaitlistsModule,
    name: 'getCompetitionsWaitlists',
    api: (params) =>
      api.get(`/competitions/${params?.competitionId}/competitions_waitlists`, { params }),
    *sagas(Creators, { params, options }) {
      try {
        const resp = yield call(actions.getCompetitionsWaitlists.api, params);
        yield put(Creators.getCompetitionsWaitlistsSuccess(resp.data, resp.pagination));
        if (options.onSuccess) {
          options.onSuccess(resp.data, resp.pagination);
        }
      } catch (error) {
        if (options.onError) {
          options.onError(error);
        }
        yield put(Creators.getCompetitionsWaitlistsError());
      }
    },
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
};

const competitionsWaitlistsModuleObj = {
  actions,
  state: {
    error: null,
    loading: false,
  },
};

export default competitionsWaitlistsModuleObj;
