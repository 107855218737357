import React, { useCallback, useEffect } from 'react';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import Modules from 'modules';
import useActions from 'modules/map/useActions';
import Paper from 'core/ui/Paper';
import Typography from 'core/ui/Typography';
import LinearProgress from 'core/ui/LinearProgress';
import Grid from 'core/ui/Grid';
import { date } from 'core/utils/formatters';
import Divider from 'core/ui/Divider';
import { secondary, successColor } from 'core/ui/Colors';
import useSelectors from 'modules/map/useSelectors';
import { ownerableMessagesModule } from 'modules/ownerableMessages';

export default ({ channel }) => {
  const { t } = useTranslation();
  const resume = useSelectors(ownerableMessagesModule, 'resume');
  const { request } = useActions();

  const getResume = useCallback(() => {
    request({
      action: Modules.ownerableMessages.actions.getMonthResume,
      data: { channelId: channel?.id },
    });
  }, [channel, request]);

  useEffect(() => {
    getResume();
  }, [getResume]);

  return (
    <Box alignItems="flex-start">
      <Grid container spacing={5}>
        <Grid item xs={12} md={4}>
          <Paper p={3}>
            <Box display="flex" alignItems="flex-top">
              <Box flexGrow={1}>
                <Typography variant="h3" mb={2}>
                  {t('channel.textMessages')}
                </Typography>
              </Box>
              <Typography style={{ color: secondary, fontSize: 10 }}>
                {t('label.currentMonth')}
              </Typography>
            </Box>
            {resume?.textMessages?.unlimitedMonth ? (
              <Typography variant="h6">
                {resume?.textMessages?.totalSent}&nbsp;/&nbsp;
                <span style={{ color: successColor }}>
                  {t('label.unlimited')}&nbsp;
                  {date(resume?.endDate)}
                </span>
              </Typography>
            ) : (
              <>
                <Typography variant="h6">
                  {resume?.textMessages?.totalSent}&nbsp;/&nbsp;
                  <span style={{ color: successColor }}>{resume?.textMessages?.totalPlan}</span>
                </Typography>
                <LinearProgress variant="determinate" value={resume?.textMessages?.percent || 0} />
              </>
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper p={3}>
            <Box display="flex" alignItems="flex-top">
              <Box flexGrow={1}>
                <Typography variant="h3" mb={2}>
                  {t('channel.emails')}
                </Typography>
              </Box>
              <Typography style={{ color: secondary, fontSize: 10 }}>
                {t('label.currentMonth')}
              </Typography>
            </Box>
            <Typography variant="h6">
              {resume?.emails?.totalSent}&nbsp;/&nbsp;
              <span style={{ color: successColor }}>{resume?.pushNotifications?.totalPlan}</span>
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper p={3}>
            <Box display="flex" alignItems="flex-top">
              <Box flexGrow={1}>
                <Typography variant="h3" mb={2}>
                  {t('channel.pushNotifications')}
                </Typography>
              </Box>
              <Typography style={{ color: secondary, fontSize: 10 }}>
                {t('label.currentMonth')}
              </Typography>
            </Box>

            <Typography variant="h6">
              {resume?.pushNotifications?.totalSent}&nbsp;/&nbsp;
              <span style={{ color: successColor }}>{resume?.pushNotifications?.totalPlan}</span>
            </Typography>
          </Paper>
        </Grid>
      </Grid>

      <Divider my={3} />
    </Box>
  );
};
