import React, { useContext } from 'react';
import Box from 'core/ui/Box';
import Typography from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import { ButtonPrimary } from 'core/ui/Button';
import { useHistory } from 'react-router';
import Paper from 'core/ui/Paper';
import { timeWithoutTz } from 'core/utils/formatters';
import Divider from 'core/ui/Divider';
import { CalendarToday } from '@material-ui/icons';
import { secondary, secondaryXLight } from 'core/ui/Colors';
import { ShowContext } from '../../index';

export default ({ item, onViewClick }) => {
  const showContext = useContext(ShowContext);
  const { t } = useTranslation();
  const history = useHistory();

  const { buttonColor, fontColor } = showContext;
  const iconFontStyle = { color: secondary, fontSize: 12 };
  const fontStyle = { color: fontColor, fontSize: 13 };
  const stageColor =
    item.competitionsStageLocation?.color ||
    (showContext?.competition.darkBackground ? secondaryXLight : secondary);

  const borderStyleColor = {
    borderLeft: `5px solid ${stageColor}`,
  };

  return (
    <Paper className="opacity-80" style={{ width: 200, overflow: 'hidden' }}>
      <Box display="flex" flexDirection="column" style={{ height: '100%', flex: 1 }}>
        <Box display="flex" p={2}>
          <Typography
            variant="h6"
            color="secondary"
            align="center"
            style={{ flex: 1, textOverflow: 'ellipsis' }}
          >
            {item.name}
          </Typography>
        </Box>
        <Box flexGrow={1} mb={2}>
          <Box py={1} pl={1} style={borderStyleColor} className="opacity-80">
            <Box display="flex" alignItems="center">
              <CalendarToday style={iconFontStyle} />
              <Typography ml={1} style={fontStyle}>
                {timeWithoutTz(item.startAt)}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <CalendarToday style={iconFontStyle} />
              <Typography ml={1} style={fontStyle}>
                {timeWithoutTz(item.endAt)}
              </Typography>
            </Box>
          </Box>

          {showContext.competition.hasDivisions && (
            <Box py={1} pl={1} style={borderStyleColor} className="opacity-80" mt={1}>
              <Typography color="secondary" mt={1}>
                {item.divisions && item.divisions.map((x) => x.name).join(', ')}
              </Typography>
            </Box>
          )}

          {item?.competitionsStageLocation && (
            <Box py={1} pl={1} style={borderStyleColor} className="opacity-80" mt={1}>
              <Typography color="secondary" mt={1}>
                {item?.competitionsStageLocation?.name}
              </Typography>
            </Box>
          )}
        </Box>

        <Box p={2} className="opacity-50">
          <Box display="flex" justifyContent="center">
            {showContext.competition.isCoach && (
              <>
                <ButtonPrimary
                  buttonColor={buttonColor}
                  fullWidth
                  onClick={() => history.push(`/workout/edit/${item.id}`)}
                >
                  {t('button.edit')}
                </ButtonPrimary>
                <Divider orientation="vertical" flexItem m={2} />
              </>
            )}

            <ButtonPrimary fullWidth buttonColor={buttonColor} onClick={() => onViewClick(item)}>
              {t('button.details')}
            </ButtonPrimary>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};
