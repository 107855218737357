import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import Box from 'core/ui/Box';
import Texture from 'core/ui/Texture';
import Form from './Form';

const ModalFormPartner = ({ open, partner, close, callbackSave }) => (
  <Dialog open={open} onClose={close} maxWidth="md" fullWidth scroll="body">
    <Texture>
      <Box p={5}>
        <Box>
          <Form partner={partner} close={close} callbackSave={callbackSave} />
        </Box>
      </Box>
    </Texture>
  </Dialog>
);

ModalFormPartner.propTypes = {
  close: PropTypes.func,
};

ModalFormPartner.defaultProps = {
  close: () => {},
};

export default ModalFormPartner;
