import React, { useState } from 'react';
import { Box, Tab, Tabs } from '@material-ui/core';
import Paper from 'core/ui/Paper';
import MemberWorkoutsList from './MemberWorkoutsList';

const MemberWorkouts = ({ user, channel }) => {
  const [tab, setTab] = useState(0);

  return (
    <Paper square>
      <Tabs value={tab} indicatorColor="primary" textColor="primary" onChange={(e, v) => setTab(v)}>
        <Tab label="Recents Classes" value={0} />
        <Tab label="Upcoming Classes" value={1} />
      </Tabs>

      <Box pb={3}>
        <MemberWorkoutsList channel={channel} user={user} limit={8} upcoming={tab === 1} />
      </Box>
    </Paper>
  );
};

export default MemberWorkouts;
