import api from 'core/api';

export const channelsDenylistsModule = 'channelsDenylists';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const actions = {
  create: {
    module: channelsDenylistsModule,
    name: 'create',
    api: (params) => api.post(`/channels/${params.channelId}/channels_denylists`, params),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  getDenylists: {
    module: channelsDenylistsModule,
    name: 'getDenylists',
    api: (params) => api.get(`/channels/${params?.channelId}/channels_denylists`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: ['data'],
    },
    state: loadingStates,
  },
  destroy: {
    module: channelsDenylistsModule,
    name: 'destroy',
    api: (params) => api.delete(`/channels/${params.channelId}/channels_denylists/${params.id}`),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
};

export default {
  actions,
  state: {
    loading: false,
    data: [],
  },
};
