import api from 'core/api';

export const automationTypesModule = 'automationTypes';

const loadingTriggersStates = {
  start: { loadingTriggers: true },
  error: { loadingTriggers: false },
  success: { loadingTriggers: false },
};

const loadingActionsStates = {
  start: { loadingActions: true },
  error: { loadingActions: false },
  success: { loadingActions: false },
};

const actions = {
  getActions: {
    module: automationTypesModule,
    name: 'getActions',
    api: (params) => api.get(`/automation/types?kind=action`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: ['actions'],
    },
    state: loadingActionsStates,
  },
  getTriggers: {
    module: automationTypesModule,
    name: 'getTriggers',
    api: (params) => api.get(`/automation/types?kind=trigger`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: ['triggers'],
    },
    state: loadingTriggersStates,
  },
};

export default {
  actions,
  state: {
    loadingTriggers: false,
    loadingActions: false,
    triggers: [],
    actions: [],
  },
};
