import React, { useState } from 'react';
import propTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import Box from 'core/ui/Box';
import { useSelector } from 'react-redux';
import { competitionsSelectors } from 'modules/competitions';
import { sessionSelectors } from 'modules/session';
import Form from 'components/Login/Form';
import QuickForm from 'components/Profile/QuickForm';
import ChannelContracts from 'components/Channel/ChannelContracts';
import Paper from 'core/ui/Paper';
import { secondaryBgModal } from 'core/ui/Colors';
import useIsXs from 'core/utils/hook/useIsXs';
import Registration from './New/Registration';

export default function ModalRegistration({ open, setOpen, buttonColor }) {
  const competition = useSelector((state) => competitionsSelectors.getCompetition(state));
  const currentUser = useSelector((state) => sessionSelectors.getUser(state));
  const isAuthenticated = useSelector((state) => sessionSelectors.getIsAuthenticated(state));
  const isXs = useIsXs();
  const [waiverAccepted, setWaiverAccepted] = useState();

  return (
    <Dialog
      PaperProps={{ style: { borderRadius: 5, backgroundColor: competition?.backgroundColor } }}
      maxWidth="md"
      fullWidth
      scroll={isXs ? 'paper' : 'body'}
      fullScreen={isXs}
      onClose={() => setOpen(false)}
      open={open}
    >
      <Box p={1}>
        {!isAuthenticated ? (
          <Form fromModal channelId={competition?.channel?.id} dark={competition?.darkBackground} />
        ) : (
          <div>
            {!currentUser.name ? (
              <Paper px={2} pb={2} style={{ backgroundColor: secondaryBgModal }}>
                <QuickForm
                  buttonColor={buttonColor}
                  channelId={competition?.channel?.id}
                  dark={competition?.darkBackground}
                />
              </Paper>
            ) : (
              <Box>
                {!competition.isCoach &&
                !waiverAccepted &&
                competition &&
                competition.documentWaiverId ? (
                  <Box p={3}>
                    <ChannelContracts
                      channel={competition?.channel}
                      userId={currentUser.id}
                      ownerableType="Competition"
                      ownerableId={competition?.id}
                      kind="waivers"
                      buttonColor={buttonColor}
                      setAccepted={setWaiverAccepted}
                      documentId={competition.documentWaiverId}
                      alwaysOpen
                    />
                  </Box>
                ) : (
                  <Box py={2}>
                    <Registration close={() => setOpen(false)} />
                  </Box>
                )}
              </Box>
            )}
          </div>
        )}
      </Box>
    </Dialog>
  );
}

ModalRegistration.propTypes = {
  open: propTypes.bool,
  setOpen: propTypes.func,
};

ModalRegistration.defaultProps = {
  open: false,
  setOpen: null,
};
