import api from 'core/api';

export const getMessageList = (messageId) => api.get(`/admin/message_lists/${messageId}`);
export const getMessageLists = () => api.get(`/admin/message_lists`);
export const getMessageListContacts = (listId, query, page, perPage) =>
  api.get(
    `/admin/message_lists/${listId}/contacts?query=${query}&page=${page}&per_page=${perPage}`,
  );
export const postMessageLists = (data) => api.post('/admin/message_lists', data);
export const putMessageLists = (data) => api.put(`/admin/message_lists/${data.id}`, data);
export const deleteContactList = (messageListId, contactId) =>
  api.delete(`/admin/message_lists/${messageListId}/remove_contact/${contactId}`);
