import api from 'core/api';
import { call, put } from 'redux-saga/effects';
import { saveAs } from 'file-saver';
import { retrieveAuthHeaders } from 'core/api/auth-headers';
import { retrieveData } from 'core/utils/session-storage';
import { BASE_URL } from 'core/env';
import { getErrorMessage } from 'core/api/api-error';

export const newPaymentsModule = 'newPayments';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const loadingChargeHoldStates = {
  start: { loadingChargeHold: true },
  error: { loadingChargeHold: false },
  success: { loadingChargeHold: false },
};

const loadingStorePurchaseStates = {
  start: { loadingStorePurchase: true },
  error: { loadingStorePurchase: false },
  success: { loadingStorePurchase: false },
};

const loadingAddPaymentStates = {
  start: { loadingAddPayment: true },
  error: { loadingAddPayment: false },
  success: { loadingAddPayment: false },
};

const actions = {
  show: {
    module: newPaymentsModule,
    name: 'show',
    api: (paymentId) => api.get(`/payments/${paymentId}`),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  update: {
    module: newPaymentsModule,
    name: 'update',
    api: (params) => api.put(`/payments/${params?.id}`, params),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  refundPayment: {
    module: newPaymentsModule,
    name: 'refundPayment',
    api: (params) => api.post('/payments/refund', params),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  removePayment: {
    module: newPaymentsModule,
    name: 'removePayment',
    api: (params) => api.delete(`/payments/${params?.paymentId}`),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  sendReceipt: {
    module: newPaymentsModule,
    name: 'sendReceipt',
    api: (params) => api.get(`/payments/${params?.paymentId}/send_receipt`),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  downloadReceipt: {
    module: newPaymentsModule,
    name: 'downloadReceipt',
    api: (params) => {
      const authHeaders = retrieveAuthHeaders(retrieveData)();
      return fetch(`${BASE_URL}/api/v1/payments/${params?.paymentId}/receipt`, {
        headers: { ...authHeaders },
      });
    },
    *sagas(Creators, { params, options }) {
      try {
        const resp = yield call(actions.downloadReceipt.api, params);

        if (!resp.ok) throw getErrorMessage({ body: yield resp.json() });

        resp.blob().then((f) => saveAs(f, `Receipt ${params.paymentId}.pdf`));

        yield put(Creators.downloadReceiptSuccess());
        if (options.onSuccess) {
          options.onSuccess(resp);
        }
      } catch (error) {
        if (options.onError) {
          options.onError(error);
        }
        yield put(Creators.downloadReceiptError());
      }
    },
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  resendVerification: {
    module: newPaymentsModule,
    name: 'resendVerification',
    api: (params) => api.get(`/payments/${params?.paymentId}/resend_verification`),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  checkAsPaid: {
    module: newPaymentsModule,
    name: 'checkAsPaid',
    api: (params) => api.get(`/payments/${params?.paymentId}/check_as_paid`),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  partnersPrograms: {
    module: newPaymentsModule,
    name: 'partnersPrograms',
    api: (params) => api.post('/payments/partners_programs', params),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  cancellationChannelKey: {
    module: newPaymentsModule,
    name: 'cancellationChannelKey',
    api: (params) => api.post('/payments/cancellation_membership', params),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  chargeHoldPending: {
    module: newPaymentsModule,
    name: 'chargeHoldPending',
    api: (params) => api.post('/payments/charge_hold_pending', params),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingChargeHoldStates,
  },
  storePurchase: {
    module: newPaymentsModule,
    name: 'storePurchase',
    api: (params) => api.post(`payments/channel/store_product`, params),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStorePurchaseStates,
  },
  getPaymentBySecret: {
    module: newPaymentsModule,
    name: 'getPaymentBySecret',
    api: ({ secret }) => api.get(`payments/by_secret/${secret}`),
    params: {
      start: ['params'],
      error: [''],
      success: ['payment'],
    },
    state: loadingStates,
  },

  addGenericPayment: {
    module: newPaymentsModule,
    name: 'addGenericPayment',
    api: (params) => api.post('payments/channel/add_generic_payment', params),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingAddPaymentStates,
  },

  exportPayments: {
    module: newPaymentsModule,
    name: 'exportPayments',
    api: (params) => api.post('payments/export_to_email', params),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
};

export default {
  actions,
  state: {
    loading: false,
    loadingChargeHold: false,
    loadingStorePurchase: false,
    data: [],
    pr: null,
    payment: null,
    loadingAddPayment: false,
  },
};
