/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { BarChart, Bar, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import Typography from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import Paper from 'core/ui/Paper';
import { timeWithoutTz } from 'core/utils/formatters';
import { primary } from 'core/ui/Colors';
import Toggle from 'core/ui/Toggle';
import Box from 'core/ui/Box';

const daysWeeks = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const BarHours = ({ hours }) => {
  const { t } = useTranslation();
  const [currentDay, setCurrentDay] = useState('Monday');

  const data = hours
    ?.filter((x) => Number(x.total) > 0)
    ?.filter((x) => x?.day?.indexOf(currentDay) > -1)
    ?.map((item) => ({
      time: timeWithoutTz(item.time),
      unlocks: item.total,
    }));

  return (
    <Paper my={5} p={3}>
      <Typography variant="h5" mb={3} align="center">
        {t('channel.reports.summary.topTimeCheckinGraph')}
      </Typography>

      <Box display="flex" justifyContent="center" alignItems="cneter" m={2}>
        <Toggle
          options={daysWeeks.map((x) => ({ value: x, label: x }))}
          value={currentDay}
          onChange={(v) => setCurrentDay(v)}
        />
      </Box>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <XAxis dataKey="time" />
          <YAxis />
          <Bar label dataKey="unlocks" fontSize={8} fill={primary} />
        </BarChart>
      </ResponsiveContainer>
    </Paper>
  );
};

export default BarHours;
