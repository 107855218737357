import React, { useContext, useEffect, useState } from 'react';
import Box from 'core/ui/Box';
import { sessionSelectors } from 'modules/session';
import { useSelector } from 'react-redux';
import { Dialog, Slide } from '@material-ui/core';
import useChannelPermissions from 'components/Channel/useChannelPermissions';
import { primary } from 'core/ui/Colors';
import { workoutChannelPrograms } from 'core/utils/helpers';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import { useTranslation } from 'react-i18next';
import { LayoutContext } from 'pages/shared/Layout';
import { useParams } from 'react-router';
import ItemSection from './ItemSection';
import ModalAddScores from '../../Attendance/ModalAddScores';
import ExpandedSection from './Expanded';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default ({ openLeaderboard, workout }) => {
  const { t } = useTranslation();
  const { sectionId } = useParams();
  const layoutContext = useContext(LayoutContext);
  const currentUser = useSelector((state) => sessionSelectors.getUser(state));
  const [currentProgramId, setCurrentProgramId] = useState(null);
  const [sections, setSections] = useState([]);
  const [modalScores, setModalScores] = useState({ open: false });
  const [modelSection, setModalSection] = useState({ open: false });
  const { commentsAccess } = useChannelPermissions({ channel: workout.channel });
  const buttonColor = workout?.channel?.customColors?.buttonColor || primary;

  useEffect(() => {
    if (workout.id) {
      setSections(workout.sections.map((s) => ({ ...s, open: true })));
    }
  }, [setSections, workout]);

  const selectTab = (index, open) => {
    const ar = [...sections];
    const section = { ...ar[index], open: !open };
    ar[index] = section;
    setSections(ar);
  };

  const onLeaderboardClick = (section) => {
    openLeaderboard(section);

    window.history.replaceState(
      null,
      'StreamFit',
      `/workout/view/${workout?.id}/overview/leaderboard/${section?.id}`,
    );
  };

  const expandSection = (sec) => {
    setModalSection({ open: !!sec, section: sec });
  };

  const hasKey =
    workout.hasValidKeyInPersonChannel || workout.hasValidKeyChannel || workout.hasListChannel;

  const showLeaderboard =
    hasKey || commentsAccess || workout.registered || workout.allowPublicPostScores;

  const isToHideSections = () => {
    if (workout.isCoach) {
      return false;
    }
    return (
      !!workout.hideSectionsEveryone ||
      (!workout.competition
        ? !hasKey && workout.hideSections && !workout.registered
        : workout.hideSections && !workout.started)
    );
  };

  const hideSections = isToHideSections();

  const programs = workoutChannelPrograms(workout.sections);

  useEffect(() => {
    if (sectionId && workout?.sections?.length > 0 && showLeaderboard) {
      setTimeout(() => {
        openLeaderboard(workout?.sections?.find((x) => Number(x.id) === Number(sectionId)));
      }, 300);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionId, workout]);

  useEffect(() => {
    if (programs && programs?.length > 0) {
      const def = programs.find((x) => !!x.default);
      if (def) {
        setCurrentProgramId(def.id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workout]);

  return (
    <>
      {modelSection.section && (
        <Dialog
          fullScreen
          open={modelSection.open}
          onClose={() => setModalSection({ open: false })}
          TransitionComponent={Transition}
          PaperProps={{ style: { borderTop: `3px solid ${primary}` } }}
        >
          <ExpandedSection
            sections={sections}
            hideSections={false}
            onLeaderboardClick={() => onLeaderboardClick(modelSection.section)}
            selectTab={selectTab}
            expandSection={expandSection}
            buttonColor={buttonColor}
            section={modelSection.section}
            showLeaderboard={showLeaderboard}
          />
        </Dialog>
      )}
      {modalScores.open && (
        <ModalAddScores
          open={modalScores.open}
          close={() => setModalScores({ open: false })}
          user={currentUser}
          sectionId={modalScores.sectionId}
          buttonColor={buttonColor}
        />
      )}
      {programs && programs.length > 1 && (
        <Box mb={3}>
          <SelectBoxObject
            label={t('workout.section.label.program').toUpperCase()}
            options={programs}
            value={currentProgramId || null}
            setValue={(v) => setCurrentProgramId(v)}
          />
        </Box>
      )}
      {sections
        .filter(
          (x) =>
            !x?.channelProgram ||
            !currentProgramId ||
            Number(x?.channelProgram?.id) === Number(currentProgramId),
        )
        .map((section, index) => (
          <Box key={index.toString()} mb={3}>
            <ItemSection
              workout={workout}
              hideSections={hideSections}
              onAddScoresClick={() => setModalScores({ open: true, sectionId: section.id })}
              onLeaderboardClick={() => onLeaderboardClick(section)}
              selectTab={selectTab}
              addPrScore={() => layoutContext.addPrScore()}
              expandSection={expandSection}
              section={section}
              buttonColor={buttonColor}
              showLeaderboard={
                hasKey || commentsAccess || workout.registered || workout.allowPublicPostScores
              }
              index={index}
              isCoach={workout.isCoach}
            />
          </Box>
        ))}
    </>
  );
};
