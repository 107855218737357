import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import { Dialog, makeStyles, Slide, IconButton } from '@material-ui/core';
import Typography from 'core/ui/Typography';
import Texture from 'core/ui/Texture';
import CloseIcon from '@material-ui/icons/Close';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Scrollbars from 'react-custom-scrollbars';
import Form from './Form';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const ModalFormProduct = ({
  currency,
  open,
  item,
  close,
  callback,
  mode,
  ownerableType,
  ownerableId,
  taxPercent,
  channelsTaxes,
  hasShopifyIntegration,
  customerAmounts,
  allowChangeAmounts,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Dialog
      style={{ marginTop: 100 }}
      fullScreen
      open={open}
      onClose={close}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar} color="secondary">
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            {t('channel.tab.store.product')}
          </Typography>
          <IconButton edge="start" color="inherit" onClick={close} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Scrollbars allowFullScreen>
        <Texture style={{ minHeight: '100%' }}>
          <Box p={5}>
            <Form
              mode={mode}
              currency={currency}
              callback={callback}
              item={item}
              ownerableType={ownerableType}
              ownerableId={ownerableId}
              taxPercent={taxPercent}
              channelsTaxes={channelsTaxes}
              hasShopifyIntegration={hasShopifyIntegration}
              customerAmounts={customerAmounts}
              allowChangeAmounts={allowChangeAmounts}
            />
          </Box>
        </Texture>
      </Scrollbars>
    </Dialog>
  );
};

ModalFormProduct.defaultProps = {
  close: () => {},
  callback: () => {},
  item: null,
  open: false,
  mode: 'new',
};

ModalFormProduct.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  close: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  callback: PropTypes.func,
  open: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.object,
  mode: PropTypes.string,
};

export default ModalFormProduct;
