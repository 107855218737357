import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Helmet } from 'react-helmet';
import { primary, secondary } from 'core/ui/Colors';
import Box from 'core/ui/Box';
import TimeSelector from 'core/ui/TimeSelector';
import Typography from 'core/ui/Typography';
import EmbedUploadClass from 'pages/shared/embed/UploadMobile';
import { workoutsActions, workoutsSelectors } from 'modules/workouts';
import { sessionActions, sessionSelectors } from 'modules/session';
import { useDispatch, useSelector } from 'react-redux';
import { convertToSeconds } from 'core/utils/helpers';
import notifications from 'modules/notifications';
import { sessionWorkoutActions } from 'modules/sessionWorkout';
import { Check } from '@material-ui/icons';
import { persistData } from 'core/utils/session-storage';

const AndroidUpload = () => {
  const { t } = useTranslation();
  const [uploadStatus, setUploadStatus] = useState(null);
  const workout = useSelector((state) => workoutsSelectors.getWorkout(state));
  const user = useSelector((state) => sessionSelectors.getUser(state));
  const { workoutId, token } = useParams();
  const dispatch = useDispatch();

  const [startWorkoutTimer, setStartWorkoutTimer] = useState({});

  const decodeToken = () => {
    const data = atob(token);
    const decoded = JSON.parse(data);

    Object.keys(decoded).forEach((item) => {
      persistData(item, decoded[item]);
    });

    dispatch(sessionActions.authenticateStart());
  };

  useEffect(() => {
    if (token) {
      decodeToken();
    }
  }, [token]);

  useEffect(() => {
    if (workoutId && user?.id) {
      dispatch(workoutsActions.getWorkoutStart(workoutId));
    }
  }, [user, dispatch, workoutId]);

  const onCompleteUpload = (data) => {
    dispatch(
      sessionWorkoutActions.processLocalRecordingStart({
        workoutId: workout.id,
        userId: user.id,
        s3Url: data.s3Url,
        startCountdownSeconds:
          startWorkoutTimer && startWorkoutTimer.value
            ? convertToSeconds(startWorkoutTimer.value)
            : 0,
        callback: () => {
          dispatch(notifications.success('Video uploaded'));
        },
      }),
    );
  };

  useEffect(() => {
    if (uploadStatus && uploadStatus.complete) {
      onCompleteUpload(uploadStatus);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadStatus]);

  return (
    <div className="flex-1 bg-zinc-600">
      <Helmet>
        <style>{`body { background-color: ${secondary} }`}</style>
      </Helmet>

      <div className="tw=font-bold tw-m-3 tw-text-center tw-text-lg tw-text-white">
        Workout Video Upload
      </div>

      {user?.id && (
        <div>
          {uploadStatus?.complete ? (
            <div className="tw-flex tw-flex-col tw-items-center tw-gap-2">
              <Check className="tw-text-[40px] tw-text-green-600" />
              <div className="tw-mb-1 tw-text-xs tw-text-white">
                {t('competition.event.video.recordedUploaded')}
              </div>
            </div>
          ) : (
            <div>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                mb={3}
              >
                <Box my={5}>
                  <div className="tw-mb-1 tw-text-xs tw-text-white">
                    {t('workout.competition.upload.startWorkoutCoutndownTime')}
                  </div>
                  <TimeSelector
                    time={startWorkoutTimer}
                    setTime={(time) => setStartWorkoutTimer(time)}
                  />
                </Box>
                <Typography className="tw-text-white" align="center">
                  {t('workout.competition.upload.startWorkoutCoutndownTimeHelp')}
                </Typography>
                <Typography className="tw-text-white" align="center">
                  {t('workout.competition.upload.note')}
                </Typography>
              </Box>
              <EmbedUploadClass
                bgColor={secondary}
                setUploadModal={setUploadStatus}
                compButtonColor={primary}
                web
                showPreview
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

AndroidUpload.propTypes = {};

export default AndroidUpload;
