/* eslint-disable no-nested-ternary */
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import notifications from 'modules/notifications';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';

const useFormStageUsers = ({ stageUser, stageId, close, callbackSave, channelId }) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
    control,
  } = useForm();
  const { request } = useActions();
  const dispatch = useDispatch();

  useEffect(() => {
    if (stageUser?.id) {
      const userData = {
        ...stageUser.user,
        tags: stageUser?.tags?.map((x) => x.name),
        image: {
          url: stageUser.user.image ? stageUser.user.image.image.url : '',
          edit: false,
          ...(stageUser?.user?.image || {}),
        },
      };
      reset({
        ...stageUser,
        channelsStageId: stageId,
        user: userData,
      });
    } else if (stageId) {
      setValue('channelsStageId', stageId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stageUser, stageId]);

  const onSubmit = (data) => {
    const stargeUserData = {
      id: stageUser?.id,
      channelId,
      ...data,
      tags:
        data?.tags &&
        data?.tags.map((x) => ({
          name: x.name ? x.name : x,
        })),
      staffIds: data?.staffs?.map((x) => x.id),
      user: {
        ...(data?.user || {}),

        imageAttributes: data?.user?.image,
      },
    };

    request({
      action: stageUser?.id
        ? Modules.channelsStagesUsers.actions.updateStageUser
        : Modules.channelsStagesUsers.actions.createStageUser,
      data: {
        ...stargeUserData,
      },
      options: {
        onSuccess: () => {
          dispatch(notifications.success(t('channel.member.stages.success')));
          if (callbackSave) {
            callbackSave(stageUser?.channelsStageId, data?.channelsStageId);
            close();
            return;
          }
          close();
        },
        onError: (error) => {
          dispatch(notifications.error(error));
        },
      },
    });
  };

  const setUser = (user) => {
    const userData = {
      ...user,
      image: {
        url: user.image ? user.image.image.url : '',
        edit: false,
      },
    };

    reset({
      user: userData,
    });
  };

  return {
    stageUser,
    watch,
    setValue,
    submit: handleSubmit(onSubmit),
    errors,
    register,
    setUser,
    control,
  };
};

useFormStageUsers.propTypes = {
  close: PropTypes.func.isRequired,
};

useFormStageUsers.defaultProps = {};

export default useFormStageUsers;
