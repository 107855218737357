export const primary = '#4fb94a';

export const secondary = '#404041';
export const secondaryBg = '#707074';
export const secondaryChat = '#5A5858';
export const secondaryBgModal = '#f3f3f3';
export const secondaryLight = '#e3e3e3';
export const secondaryXLight = '#F5F5F5';
export const secondaryXXLight = '#F9FAFB';
export const secondaryBgLight = '#F4F4F4';
export const secondaryTableLight = '#FBFAFB';

export const chatColors = {
  background: '#0E0E0E',
  container: '#1F2225',
  input: '#272A2D',
  message: '#272A2D',
};

export const bgModal = '#ececec';
export const warningColor = '#FFCC00';

export const labelLight = '#75787b';

export const rowXLight = '#FBFBFB';
export const rowLight = '#F5F6F6';

export const redColor = '#ec4141';
export const onlineColor = '#00e410';
export const greenLightColor = '#98FB98';
export const successColor = '#09b815';

export const colorLightGrey = '#fafafa';
export const colorCode = '#f1f1f1';
export const colorPdfBackground = '#5C6164';
export const bgDrawerColor = '#616162';

export const twColors = {
  cyan: {
    500: '#06b6d4',
    600: '#0891b2',
    700: '#0e7490',
  },
  emerald: {
    400: '#34d399',
    500: '#10b981',
    600: '#059669',
  },
};
