/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
// import Button from './button';
// import RecordButton from './record-button';
// import StopButton from './stop-button';
// import Timer from './timer';
// import Countdown from './countdown';

import Timer from 'react-video-recorder/lib/defaults/timer';
import Countdown from 'react-video-recorder/lib/defaults/countdown';
import { useTranslation } from 'react-i18next';
import Typography from 'core/ui/Typography';
import { ButtonWhiteSm } from 'core/ui/Button';
import Box from 'core/ui/Box';
import { Check, Refresh, FiberManualRecord, Videocam, Stop } from '@material-ui/icons';

const ActionsWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  z-index: 0;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 80px;
`;

const Actions = ({
  isVideoInputSupported,
  isInlineRecordingSupported,
  thereWasAnError,
  isRecording,
  isCameraOn,
  streamIsReady,
  isConnecting,
  isRunningCountdown,
  isReplayingVideo,
  countdownTime,
  timeLimit,
  useVideoInput,
  onTurnOnCamera,
  onOpenVideoInput,
  onStartRecording,
  onStopRecording,
  onStopReplaying,
  setOpen,
}) => {
  const Label = ({ label }) => {
    const { t } = useTranslation();
    return <Typography color="primary">{t(label)}</Typography>;
  };

  function ConfirmRecord() {
    return (
      <ButtonWhiteSm onClick={() => setOpen(false)}>
        <Check size="small" />
        <Label label="workout.confirmVideo" />
      </ButtonWhiteSm>
    );
  }

  const renderContent = () => {
    const shouldUseVideoInput = !isInlineRecordingSupported && isVideoInputSupported;

    if (
      (!isInlineRecordingSupported && !isVideoInputSupported) ||
      thereWasAnError ||
      isConnecting ||
      isRunningCountdown
    ) {
      return null;
    }

    if (isReplayingVideo) {
      return (
        <Box display="flex">
          <Box m={1}>
            <ConfirmRecord />
          </Box>
          <Box m={1}>
            <ButtonWhiteSm onClick={onStopReplaying} data-qa="start-replaying">
              <Refresh size="small" />
              <Label label="workout.restartRecord" />
            </ButtonWhiteSm>
          </Box>
        </Box>
      );
    }

    if (isRecording) {
      return (
        <ButtonWhiteSm variant="contained" onClick={onStopRecording} data-qa="stop-recording">
          <Stop size="small" />
          <Label label="workout.stopRecord" />
        </ButtonWhiteSm>
      );
    }

    if (isCameraOn && streamIsReady) {
      return (
        <ButtonWhiteSm variant="contained" onClick={onStartRecording} data-qa="start-recording">
          <FiberManualRecord size="small" />
          <Label label="workout.startRecord" />
        </ButtonWhiteSm>
      );
    }

    if (useVideoInput) {
      return (
        <ButtonWhiteSm variant="contained" onClick={onOpenVideoInput} data-qa="open-input">
          <Label label="workout.uploadAVideo" />
        </ButtonWhiteSm>
      );
    }

    return shouldUseVideoInput ? (
      <ButtonWhiteSm variant="contained" onClick={onOpenVideoInput} data-qa="open-input">
        <Label label="workout.recordAVideo" />
      </ButtonWhiteSm>
    ) : (
      <ButtonWhiteSm variant="contained" onClick={onTurnOnCamera} data-qa="turn-on-camera">
        <Videocam size="small" />
        <Label label="workout.turnOnMyCamera" />
      </ButtonWhiteSm>
    );
  };

  return (
    <div>
      {isRecording && (
        <Box style={{ position: 'absolute', top: 0, right: 0 }}>
          <Timer timeLimit={timeLimit} />
        </Box>
      )}
      {isRunningCountdown && <Countdown countdownTime={countdownTime} />}
      <ActionsWrapper>{renderContent()}</ActionsWrapper>
    </div>
  );
};

Actions.propTypes = {
  isVideoInputSupported: PropTypes.bool,
  isInlineRecordingSupported: PropTypes.bool,
  thereWasAnError: PropTypes.bool,
  isRecording: PropTypes.bool,
  isCameraOn: PropTypes.bool,
  streamIsReady: PropTypes.bool,
  isConnecting: PropTypes.bool,
  isRunningCountdown: PropTypes.bool,
  countdownTime: PropTypes.number,
  timeLimit: PropTypes.number,
  isReplayingVideo: PropTypes.bool,
  useVideoInput: PropTypes.bool,

  onTurnOnCamera: PropTypes.func,
  onOpenVideoInput: PropTypes.func,
  onStartRecording: PropTypes.func,
  onStopRecording: PropTypes.func,
  setOpen: PropTypes.func,
  onStopReplaying: PropTypes.func,
};

export default Actions;
