import NewChannel from 'pages/Channel/New';
import ManageChannel from 'pages/Channel/Manage';

export default [
  {
    path: '/channel/new',
    exact: true,
    name: 'New Channel',
    component: NewChannel,
  },
  {
    path: '/channel/manage/:id', // 🚩 src/hocs/withHealedCurrentPath.js, src/pages/shared/Layout/components/Helper/HelpCollapse/index.js
    exact: true,
    name: 'Edit Channel',
    component: ManageChannel,
  },
  {
    path: '/channel/manage/:id/:defaultTag', // 🚩 src/hocs/withHealedCurrentPath.js, src/pages/shared/Layout/components/Helper/HelpCollapse/index.js
    exact: true,
    name: 'Edit Channel',
    component: ManageChannel,
  },
  {
    path: '/channel/manage/:id/:defaultTag/:param1', // 🚩 src/hocs/withHealedCurrentPath.js, src/pages/shared/Layout/components/Helper/HelpCollapse/index.js
    exact: true,
    name: 'Edit Channel',
    component: ManageChannel,
  },
];
