import React from 'react';
import PropTypes from 'prop-types';
import Box from 'core/ui/Box';
import { competitionsSelectors } from 'modules/competitions';
import Video from 'core/ui/Video';
import { useSelector } from 'react-redux';
import noImage from 'assets/img/no-image.jpg';

const HeaderCompetition = ({ competition }) => {
  const loading = useSelector((state) => competitionsSelectors.getLoading(state));

  const show = () => {
    if (competition.previewVideo) {
      return 'preview';
    }
    if (!competition.recordingPreview && !competition.previewVideo) {
      return 'image';
    }
    return 'image';
  };

  return (
    <>
      {show() === 'image' && (
        <Box style={{ position: 'relative', paddingTop: '56.25%', overflow: 'hidden' }}>
          <Box style={{ position: 'absolute', top: 0 }}>
            <img
              src={
                competition?.cardImage?.image?.size350?.url ||
                competition?.backgroundImage?.image?.size350?.url ||
                noImage
              }
              alt={competition?.name}
              style={{ aspectRatio: 16 / 9, width: '100%' }}
            />
          </Box>
        </Box>
      )}

      {show() === 'preview' && !loading && (
        <Box className="container-aspect container-aspect-37">
          <Box className="item-aspect">
            <Video src={competition.previewVideo && competition.previewVideo.url} controls full />
          </Box>
        </Box>
      )}
    </>
  );
};

export default HeaderCompetition;

HeaderCompetition.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  competition: PropTypes.object,
};

HeaderCompetition.defaultProps = {
  competition: {},
};
