import api from 'core/api';
import { retrieveAuthHeaders } from 'core/api/auth-headers';
import { BASE_URL } from 'core/env';
import { retrieveData } from 'core/utils/session-storage';
import { call, put } from 'redux-saga/effects';
import { saveAs } from 'file-saver';

export const newChannelsModule = 'newChannels';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const loadingPullStates = {
  start: { loadingPull: true },
  error: { loadingPull: false },
  success: { loadingPull: false },
};

const planPaymentsLoadingStates = {
  start: { planPaymentsLoading: true },
  error: { planPaymentsLoading: false },
  success: { planPaymentsLoading: false },
};

const loadingPlanStates = {
  start: { loadingPlan: true },
  error: { loadingPlan: false },
  success: { loadingPlan: false },
};

const loadingCoachesStates = {
  start: { loadingCoaches: true },
  error: { loadingCoaches: false },
  success: { loadingCoaches: false },
};

const loadingCompetitionsStates = {
  start: { loadingCompetitions: true },
  error: { loadingCompetitions: false },
  success: { loadingCompetitions: false },
};

const loadingCalendarWorkoutsStates = {
  start: { loadingCalendarWorkouts: true },
  error: { loadingCalendarWorkouts: false },
  success: { loadingCalendarWorkouts: false },
};

const actions = {
  getChannelCalendarWorkoutsStart: {
    module: newChannelsModule,
    name: 'getChannelCalendarWorkoutsStart',
    api: (params) => api.get(`/channels/${params.channelId}/calendar_workouts`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingCalendarWorkoutsStates,
  },
  manualUpdateMemberTypes: {
    module: newChannelsModule,
    name: 'manualUpdateMemberTypes',
    api: (params) =>
      api.get(`/channels/${params.channelId}/members/manual_update_types`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingPullStates,
  },
  memberHasActiveMembership: {
    module: newChannelsModule,
    name: 'memberHasActiveMembership',
    api: (params) =>
      api.get(`/channels/${params.channelId}/members/member_has_active_membership`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  getMyChannels: {
    module: newChannelsModule,
    name: 'getMyChannels',
    api: (params) => api.get(`/channels/my_channels`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: ['myChannels'],
    },
    state: loadingStates,
  },
  getChannelCoaches: {
    module: newChannelsModule,
    name: 'getChannelCoaches',
    api: (params) => api.get(`/channels/${params?.channelId}/coaches`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingCoachesStates,
  },
  getChannelCompetitions: {
    module: newChannelsModule,
    name: 'getChannelCompetitions',
    api: (params) => api.get(`/competitions/by_channel/${params?.channelId}`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: ['channelCompetitions'],
    },
    state: loadingCompetitionsStates,
  },
  getMembershipPlan: {
    module: newChannelsModule,
    name: 'getMembershipPlan',
    api: (id) => api.get(`/channel_membership_plans/${id}`),
    params: {
      start: ['params'],
      error: [''],
      success: ['membershipPlan'],
    },
    state: loadingPlanStates,
  },
  getChannelPlanPayments: {
    module: newChannelsModule,
    name: 'getChannelPlanPayments',
    api: (params) => api.get(`/channels/${params?.channelId}/payments/plan`, { params }),
    *sagas(Creators, { params, options }) {
      try {
        const resp = yield call(actions.getChannelPlanPayments.api, params);
        yield put(Creators.getChannelPlanPaymentsSuccess(resp.data, resp.pagination));
        if (options.onSuccess) {
          options.onSuccess(resp.data, resp.pagination);
        }
      } catch (error) {
        if (options.onError) {
          options.onError(error);
        }
        yield put(Creators.getChannelPlanPaymentsError());
      }
    },
    params: {
      start: ['params'],
      error: [''],
      success: ['planPayments', 'planPaymentsPagination'],
    },
    state: planPaymentsLoadingStates,
  },

  exportChannelPlanPayments: {
    module: newChannelsModule,
    name: 'exportChannelPlanPayments',
    api: (data) => {
      // api.post(`/channel_reports/${data.channelId}/${data.report}`, data)
      const authHeaders = retrieveAuthHeaders(retrieveData)();
      return fetch(`${BASE_URL}/api/v1/channels/${data?.channelId}/payments/plan?export=true`, {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          ...authHeaders,
        },
      });
    },
    *sagas(Creators, { params, options }) {
      try {
        const resp = yield call(actions.exportChannelPlanPayments.api, params);

        resp.blob().then((f) => saveAs(f, params?.exportFileName || `${params?.channelId}.csv`));

        yield put(Creators.exportChannelPlanPaymentsSuccess());
        if (options.onSuccess) {
          options.onSuccess(resp);
        }
      } catch (error) {
        if (options.onError) {
          options.onError(error);
        }
        yield put(Creators.exportChannelPlanPaymentsError());
      }
    },
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
};

const newChannelsModuleObj = {
  actions,
  state: {
    loading: false,
    loadingPull: false,
    loadingPlanKeys: false,
    loadingCompetitions: false,

    planKeys: [],
    myChannels: [],
    channelCompetitions: [],

    planPayments: [],
    planPaymentsLoadingStates: false,
    planPaymentsPagination: null,

    loadingPlan: false,
    membershipPlan: null,
  },
};

export default newChannelsModuleObj;
