import { redirectUri, authBasic } from './config';

export const refreshToken = async (refreshTk) => {
  const data = {
    refresh_token: refreshTk,
    grant_type: 'refresh_token',
  };

  const body = Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');

  const request = {
    headers: {
      Authorization: authBasic,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    method: 'POST',
    body,
  };

  const resp = await fetch('https://accounts.spotify.com/api/token', request);
  const reader = resp.body.getReader();

  const stream = new ReadableStream({
    start(controller) {
      function push() {
        reader.read().then(({ done, value }) => {
          if (done) {
            controller.close();
            return;
          }
          controller.enqueue(value);
          push();
        });
      }
      push();
    },
  });

  return new Response(stream, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .json()
    .then((res) => res)
    .catch(() => false);
};

export const getAccessToken = async (code) => {
  const data = {
    code,
    redirect_uri: redirectUri,
    grant_type: 'authorization_code',
  };

  const body = Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');

  const request = {
    headers: {
      Authorization: authBasic,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    method: 'POST',
    body,
  };

  const resp = await fetch('https://accounts.spotify.com/api/token', request);
  const reader = resp.body.getReader();

  const stream = new ReadableStream({
    start(controller) {
      function push() {
        reader.read().then(({ done, value }) => {
          if (done) {
            controller.close();
            return;
          }
          controller.enqueue(value);
          push();
        });
      }
      push();
    },
  });

  return new Response(stream, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .json()
    .then((res) => res)
    .catch(() => false);
};

// eslint-disable-next-line import/prefer-default-export
// eslint-disable-next-line consistent-return
export const playerRequest = async (token, type, additional, deviceID = null) => {
  const types = {
    setPosition: {
      uri: `https://api.spotify.com/v1/me/player/seek?position_ms=${additional && additional.ms}`,
      type: 'PUT',
    },
    nextTrack: {
      uri: 'https://api.spotify.com/v1/me/player/next',
      type: 'POST',
    },
    previousTrack: {
      uri: 'https://api.spotify.com/v1/me/player/previous',
      type: 'POST',
    },
    setVolume: {
      uri: `https://api.spotify.com/v1/me/player/volume?volume_percent=${
        additional && additional.vol
      }`,
      type: 'PUT',
    },
    playPlayback: {
      uri: deviceID
        ? `https://api.spotify.com/v1/me/player/play?device_id=${deviceID}`
        : 'https://api.spotify.com/v1/me/player/play',
      type: 'PUT',
    },
    playSpecificPlayback: {
      uri: deviceID
        ? `https://api.spotify.com/v1/me/player/play?=device_id=${deviceID}`
        : 'https://api.spotify.com/v1/me/player/play',
      type: 'PUT',
      body: {
        context_uri: additional && additional.cx,
        offset: { position: additional && (additional.cx_pos || 0) },
      },
    },
    playRecentTracks: {
      uri: deviceID
        ? `https://api.spotify.com/v1/me/player/play?device_id=${deviceID}`
        : 'https://api.spotify.com/v1/me/player/play',
      type: 'PUT',
      body: {
        uris: Array.isArray(additional && additional.cx)
          ? additional && additional.cx
          : additional && [additional.cx],
        offset: { position: (additional && additional.cx_pos) || 0 },
      },
    },
    playArtist: {
      uri: deviceID
        ? `https://api.spotify.com/v1/me/player/play?device_id=${deviceID}`
        : 'https://api.spotify.com/v1/me/player/play',
      type: 'PUT',
      body: { context_uri: additional && additional.cx },
    },
    pausePlayback: {
      uri: deviceID
        ? `https://api.spotify.com/v1/me/player/pause?device_id=${deviceID}`
        : 'https://api.spotify.com/v1/me/player/pause',
      type: 'PUT',
    },
    getDevices: {
      uri: 'https://api.spotify.com/v1/me/player/devices',
      type: 'GET',
    },
  };

  const chosen = types[type];
  let request;
  if (chosen) {
    request = {
      GET: {
        headers: { Authorization: `Bearer ${token}` },
        method: 'GET',
      },
      PUT: {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        'Content-Type': 'application/x-www-form-urlencoded',
        method: 'PUT',
        body: JSON.stringify(chosen.body),
      },
      POST: {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        'Content-Type': 'application/x-www-form-urlencoded',
        method: 'POST',
        body: JSON.stringify(chosen.body),
      },
    };
    request = request[chosen.type];
    if (token) {
      return fetch(chosen.uri, request)
        .then((res) => {
          if (res.body) {
            return true;
          }
          console.error('No response body found!');
          return false;
        })
        .catch(() => false);
    }
  }
};

export const seek = async (position, token) =>
  fetch(`https://api.spotify.com/v1/me/player/seek?position_ms=${position}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    method: 'PUT',
  });

export const getPlaybackState = async (token) =>
  fetch(`https://api.spotify.com/v1/me/player`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    method: 'GET',
  }).then((d) => {
    if (d.status === 204) {
      return;
    }
    // eslint-disable-next-line consistent-return
    return d.json();
  });

export const setDevice = async (deviceId, token) =>
  fetch(`https://api.spotify.com/v1/me/player`, {
    body: JSON.stringify({ device_ids: [deviceId], play: true }),
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    method: 'PUT',
  });
