import { workoutsActions, workoutsSelectors } from 'modules/workouts';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form-v5';
import { useDispatch, useSelector } from 'react-redux';
import { isAfterNow, formatDateToApi, formatDateApi } from 'core/utils/formatters';
import { useTranslation } from 'react-i18next';
import { cutDate, cutTime, date, nextDayOfWeek, timeZoneName } from 'core/utils/formatters/date';
import { appActions } from 'modules/app';
import { sessionSelectors } from 'modules/session';

const useFormWorkout = ({
  workoutId,
  mode,
  callbackSave,
  workoutRecurringId,
  coachScheduleSessionId,
  type,
}) => {
  const isTemplate = type === 'template';

  const currentUser = useSelector((state) => sessionSelectors.getUser(state));
  const workout = useSelector((state) => workoutsSelectors.getWorkout(state));
  const { register, handleSubmit, errors, reset, control, setValue, getValues, watch } = useForm();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [formOpenSection, setFormOpenSection] = useState({});
  const [modeClass, setModeClass] = useState(mode);
  const [selectedCompetition, setSelectedCompetition] = useState(null);
  const [sections, setSections] = useState([]);
  const [modalChannel, setModalChannel] = useState(false);
  const [channels, setChannels] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [membershipPlanBlocks, setMembershipPlanBlocks] = useState([]);
  const [channelLists, setChannelLists] = useState([]);
  const [scheduleOptions, setScheduleOptions] = useState([{}]);
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [video, setVideo] = useState(null);
  const [competition, setCompetition] = useState(false);
  const [isScheduleSession, setIsScheduleSession] = useState(false);
  const [submiting, setSubmiting] = useState(false);
  const [errorsPopup, setErrorsPopup] = useState([]);
  const [popUpValidation, setPopUpValidation] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [locations, setLocations] = useState([]);
  const [heats, setHeats] = useState([]);
  const [modalConfirmSave, setModalConfirmSave] = useState({ open: false });
  const [modalSection, setModalSection] = useState({ open: false });
  const [pictureDay, setPictureDay] = useState(null);

  const [upload, setUpload] = useState({
    type: 'file',
    open: false,
  });

  const sectionsRef = useRef(sections);

  useEffect(() => {
    sectionsRef.current = sections;
  }, [sections]);

  const copyClass = type === 'classFromHistory' || type === 'classFromTemplate';

  useEffect(() => {
    if ((copyClass || modeClass === 'edit') && (workoutRecurringId || workoutId)) {
      dispatch(workoutsActions.getWorkoutStart(workoutRecurringId || workoutId));
    } else {
      dispatch(workoutsActions.clearWorkout());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modeClass, dispatch, workoutId, type, workoutRecurringId]);

  useEffect(() => {
    setCompetition(false);
  }, []);

  useEffect(() => {
    register({ name: 'ownerId' }, { required: false });
    register({ name: 'showScores' }, { required: false });
    register({ name: 'method' }, { required: false });
    register({ name: 'isPrivate' }, { required: false });
    register({ name: 'movements' }, { required: false });
    register({ name: 'coaches' }, { required: false });
    register({ name: 'invites' }, { required: false });
    register({ name: 'users' }, { required: false });
    register({ name: 'hasEquipment' }, { required: false });
    register({ name: 'equipment' }, { required: false });
    register({ name: 'videoLink' }, { required: false });
    register({ name: 'durationVideoLink' }, { required: false });
    register({ name: 'hybridAutoCheckin' }, { required: false });
    register({ name: 'payCoach' }, { required: false });
    register({ name: 'payCoachAmount' }, { required: false });

    register({ name: 'startAtDate' }, { required: false });
    register({ name: 'startAtTime' }, { required: false });
    register({ name: 'endAtDate' }, { required: false });
    register({ name: 'endAtTime' }, { required: false });
    register({ name: 'timezone' }, { required: false });

    register({ name: 'publishAtDate' }, { required: false });
    register({ name: 'publishAtTime' }, { required: false });
    register({ name: 'publishTimezone' }, { required: false });

    register({ name: 'freeOption' }, { required: false });
    register({ name: 'freeInPersonOption' }, { required: false });

    register({ name: 'hideSections' }, { required: false });
    register({ name: 'hideOnCalendarNonMembers' }, { required: false });
    register({ name: 'channelsWorkoutsTypeId' }, { required: false });

    register({ name: 'online' }, { required: false });
    register({ name: 'inPerson' }, { required: false });
    register({ name: 'maxUsers' }, { required: false });
    register({ name: 'useHeats' }, { required: false });
    register({ name: 'useCalendarColor' }, { required: false });
    register({ name: 'calendarColor' }, { required: false });

    register({ name: 'waitlistPolicy' }, { required: false });
    register({ name: 'waitlistPolicyPercent' }, { required: false });

    register({ name: 'hideSectionsConfig.active' }, { required: false });
    register({ name: 'hideSectionsConfig.number' }, { required: false });
    register({ name: 'hideSectionsConfig.timeType' }, { required: false });
    register({ name: 'hideSectionsConfig.setTime' }, { required: false });
    register({ name: 'hideSectionsConfig.setTimeHour' }, { required: false });

    register({ name: 'competitionDivisions' }, { required: false });
    register({ name: 'isTemplate' }, { required: false });
    register({ name: 'relatedWorkouts' }, { required: false });
    register({ name: 'allowPublicPostScores' }, { required: false });
    register({ name: 'previewVideoLink' }, { required: false });
    register({ name: 'competitionUseRxScaledScores' }, { required: false });
    register({ name: 'multiplyPoints' }, { required: false });
    register({ name: 'multiplyPointsNumber' }, { required: false });

    register({ name: 'limitRegistration.hasLimit' }, { required: false });
    register({ name: 'limitRegistration.hasInPersonLimit' }, { required: false });

    register({ name: 'limitRegistration.number' }, { required: false });
    register({ name: 'limitRegistration.numberInPerson' }, { required: false });

    register({ name: 'limitRegistration.numberAfter' }, { required: false });
    register({ name: 'limitRegistration.numberInPersonAfter' }, { required: false });

    register({ name: 'limitRegistration.type' }, { required: false });
    register({ name: 'limitRegistration.typeInPerson' }, { required: false });

    register({ name: 'limitRegistration.typeAfter' }, { required: false });
    register({ name: 'limitRegistration.typeInPersonAfter' }, { required: false });

    register({ name: 'cancellationPolicies.active' }, { required: false });
    register({ name: 'cancellationPolicies.cancelClassNumber' }, { required: false });
    register({ name: 'cancellationPolicies.cancelClassNumberType' }, { required: false });
    register({ name: 'minRegisteredPeoplePolicy' }, { required: false });
    register({ name: 'cancellationPolicies.cancelRegistrationLimitNumber' }, { required: false });
    register(
      { name: 'cancellationPolicies.cancelRegistrationLimitNumberType' },
      { required: false },
    );
    register({ name: 'cancellationPolicies.cancelRegistrationLimitLate' }, { required: false });
    register(
      { name: 'cancellationPolicies.cancelRegistrationLimitLateAmount' },
      { required: false },
    );
    register({ name: 'competitionsStageLocationId' }, { required: false });
    register({ name: 'competitionsUsersCategoryIds' }, { required: false });

    if (mode === 'new' || mode === 'quick-add') {
      setTimeout(() => {
        setValue('showScores', true);
        setValue('waitlistPolicy', 'all');
        setValue('maxUsers', 20);
        setValue('isTemplate', isTemplate);
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register]);

  const populateMembershipPlanBlocks = useCallback(() => {
    setMembershipPlanBlocks([...workout.membershipPlanBlocks.map((x) => x.channelMembershipPlan)]);
  }, [workout]);

  const populateChannelLists = useCallback(() => {
    setChannelLists([...workout.channelLists.map((x) => x.channelList)]);
  }, [workout]);

  const populateCoaches = useCallback(() => {
    setValue('coaches', [...workout.coaches.map((coach) => coach.id)]);
  }, [workout, setValue]);

  const populateLocations = useCallback(() => {
    setLocations(workout.channelLocations.map((loc) => loc.id));
  }, [workout]);

  const populateChannels = useCallback(() => {
    setChannels(workout.channels.map((c) => c.id));
  }, [workout]);

  const populateInvites = useCallback(() => {
    setValue(
      'invites',
      workout.athletesInvites
        ? [
            ...workout.athletesInvites.map((item) => ({
              id: item.id,
              userId: item?.user?.id,
              user: item?.user,
              invited: item?.invited,
            })),
          ]
        : [],
    );
  }, [workout, setValue]);

  const populateUsers = useCallback(() => {
    setValue(
      'users',
      workout.users
        ? [
            ...workout.users
              .filter((x) => !!x.autoAdded)
              .map((item) => ({
                ...item,
                id: item.id,
                userId: item?.user?.id,
                user: item?.user,
              })),
          ]
        : [],
    );
  }, [workout, setValue]);

  const populateMovements = useCallback(() => {
    if (workout) {
      const movs = workout.movements.map((item) => ({
        id: item?.movement?.id,
        name: item.movement.name ? item.movement.name : item,
        movement: item.movementAlt && item.movementAlt.name,
        embedVideo: item.embedVideo || item?.movement?.mediaFile?.url,
      }));
      setValue('movements', movs);
    }
  }, [setValue, workout]);

  const populateEquipments = useCallback(() => {
    if (workout) {
      const movs = workout.equipment.map((item) => ({
        name: item.equipment.name ? item.equipment.name : item,
        equipment: item.equipmentAlt && item.equipmentAlt.name,
      }));
      setValue('equipment', movs);
    }
  }, [setValue, workout]);

  const populateAttchments = useCallback(() => {
    if (workout) {
      const attchs = workout?.attachments?.map((item) => ({
        id: item.id,
        name: item.name,
      }));
      setAttachments(attchs || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValue, workout]);

  const populateHeats = useCallback(() => {
    if (workout) {
      setHeats(
        workout.workoutHeats?.map((x) => ({
          ...x,
          startTime: formatDateApi(
            `${cutDate(workout.scheduledAt)} ${x.startTime}`,
            'YYYY-MM-DD HH:mm:ss a Z',
            workout.timezone,
          ),
          endTime: formatDateApi(
            `${cutDate(workout.scheduledAt)} ${x.endTime}`,
            'YYYY-MM-DD HH:mm:ss a Z',
            workout.timezone,
          ),
        })) || [],
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValue, workout]);

  useEffect(() => {
    if (workout.id && (modeClass === 'edit' || copyClass)) {
      dispatch(appActions.setLoading(true));
      const wk = { ...workout };
      delete wk.movements;
      delete wk.equipment;
      delete wk.sections;
      reset(wk);
      // setSelectedChannel(workout.channel);
      setVideo(workout.previewVideo);
      if (workout.id) {
        setTimeout(() => {
          if (wk.coachScheduleSessionId) {
            setIsScheduleSession(true);
          }
          setValue('ownerId', wk.user.id);
          if (wk.isCompetition) {
            setValue(
              'startAtDate',
              formatDateApi(wk.startAt, 'YYYY-MM-DD HH:mm:ss Z', wk.timezone),
            );
            setValue(
              'startAtTime',
              formatDateApi(wk.startAt, 'YYYY-MM-DD HH:mm:ss Z', wk.timezone),
            );
            setValue('endAtDate', formatDateApi(wk.endAt, 'YYYY-MM-DD HH:mm:ss Z', wk.timezone));
            setValue('endAtTime', formatDateApi(wk.endAt, 'YYYY-MM-DD HH:mm:ss Z', wk.timezone));

            populateAttchments();
            if (wk.competitionsUsersCategories) {
              setValue(
                'competitionsUsersCategoryIds',
                wk.competitionsUsersCategories?.map((x) => x.id),
              );
            }
          } else {
            if (!copyClass && wk.publishAt) {
              setValue(
                'publishAtDate',
                formatDateApi(wk.publishAt, 'YYYY-MM-DD HH:mm:ss Z', wk.publishTimezone),
              );
              setValue(
                'publishAtTime',
                formatDateApi(wk.publishAt, 'YYYY-MM-DD HH:mm:ss Z', wk.publishTimezone),
              );
            }

            if (!copyClass) {
              setScheduleOptions([
                {
                  durationMinutes: wk.durationMinutes,
                  timezone: wk.timezone,
                  scheduledAtDate: formatDateApi(
                    wk.scheduledAt,
                    'YYYY-MM-DD HH:mm:ss Z',
                    wk.timezone,
                  ),
                  scheduledAtHour: formatDateApi(
                    wk.scheduledAt,
                    'YYYY-MM-DD HH:mm:ss Z',
                    wk.timezone,
                  ),
                  endAtDate: formatDateApi(wk.endAt, 'YYYY-MM-DD HH:mm:ss Z', wk.timezone),
                  endAtHour: formatDateApi(wk.endAt, 'YYYY-MM-DD HH:mm:ss Z', wk.timezone),
                },
              ]);
            }
            populateCoaches();
            populateMembershipPlanBlocks();
            populateChannelLists();
            populateLocations();
            populateChannels();
            populateHeats();

            if (wk.pictureDay) {
              setPictureDay({ url: wk.pictureDay?.image?.url, id: wk.pictureDay?.id });
            }
          }
          populateInvites();
          populateUsers();
          populateMovements();
          populateEquipments();
          setTimeout(() => {
            dispatch(appActions.setLoading(false));
          }, 1000);
        }, 100);
      }
    }
  }, [
    copyClass,
    type,
    workout,
    reset,
    populateCoaches,
    populateInvites,
    populateUsers,
    populateMovements,
    populateEquipments,
    populateMembershipPlanBlocks,
    populateChannelLists,
    populateLocations,
    populateChannels,
    populateAttchments,
    populateHeats,
    setValue,
    modeClass,
    dispatch,
  ]);

  useEffect(() => {
    if (workoutId && modeClass === 'new') {
      setSections([]);
    }
  }, [workoutId, modeClass]);

  const validateWorkout = (data) => {
    const errorsValidation = [];

    if (!workoutRecurringId && modeClass === 'edit' && workout.editable === false) {
      errorsValidation.push(t('workout.validate.schedule'));
      return false;
    }

    if (!competition && !data.online && !data.inPerson) {
      errorsValidation.push(t('workout.validation.inPersonOrOnline'));
    }

    if (!competition && data.inPerson && locations.length === 0) {
      errorsValidation.push(t('workout.validation.inPersonLocations'));
    }

    if (competition && sectionsRef.current && sectionsRef.current.length === 0) {
      errorsValidation.push(t('workout.validate.sections'));
    }

    if (!isScheduleSession && !competition && !selectedChannel) {
      errorsValidation.push(t('modalChannels.selectChannel'));
      setModalChannel(true);
    }

    if (competition && !selectedCompetition) {
      errorsValidation.push(t('workout.validate.competition'));
    }

    if (!competition && scheduleOptions.length === 0) {
      errorsValidation.push(t('workout.validate.invalidSchedule'));
    }

    if (
      !competition &&
      data?.hideSectionsConfig?.active &&
      (!data?.hideSectionsConfig?.number || data?.hideSectionsConfig?.type)
    ) {
      errorsValidation.push(t('workout.validate.checkHideSectionsConfig'));
    }

    if (!isTemplate) {
      if (!competition) {
        if (!workoutRecurringId) {
          scheduleOptions.forEach((option, index) => {
            if (!option.endAtHour && !option.durationMinutes) {
              errorsValidation.push(`${index + 1} - ${t('workout.validate.duration')}`);
            }

            if (watch('method') !== 'video' && modeClass !== 'edit') {
              if (!option.scheduledAtDate || !option.scheduledAtHour) {
                errorsValidation.push(`${index + 1} - ${t('workout.validate.date')}`);
              }
            }
          });
        }
      } else if (!data.startAtDate || !data.endAtDate || !data.startAtTime || !data.endAtTime) {
        errorsValidation.push(`${t('workout.competition.validate.date')}`);
      } else if (mode !== 'edit') {
        const startAt = `${data.startAtDate?.substr(0, 10)} ${data.startAtTime?.substr(11, 8)}`;
        if (!isAfterNow(startAt)) {
          errorsValidation.push(`${t('workout.validate.date')}`);
        }
      }
    }

    if (
      competition &&
      selectedCompetition.hasDivisions &&
      (!data.competitionDivisions || data.competitionDivisions.length === 0)
    ) {
      errorsValidation.push(`${t('workout.validate.compDivisions')}`);
    }

    if (data.method === 'video' && !data.videoLink && upload.type === 'link') {
      errorsValidation.push(t('workout.validate.videoLink'));
    }

    if (data.method === 'video' && upload.type === 'file' && !upload.file && !data.videoLink) {
      errorsValidation.push(t('workout.validate.uploadFile'));
    }

    if (data.method && data.method !== 'live' && !data.publishAtDate) {
      errorsValidation.push(t('workout.validation.publishAtDate'));
    }

    if (data.method && data.method !== 'live' && !data.publishAtTime) {
      errorsValidation.push(t('workout.validation.publishAtTime'));
    }

    if (errorsValidation.length > 0) {
      setErrorsPopup((prev) => [...prev, ...errorsValidation]);
      return false;
    }

    return true;
  };

  const callback = () => {
    setSubmiting(false);
    dispatch(appActions.setLoading(false));
    if (callbackSave) {
      callbackSave();
    }
  };

  const saveWorkout = (workoutData, index, dateOptions) => {
    if (modeClass === 'new' || modeClass === 'quick-add') {
      dispatch(
        workoutsActions.postWorkoutStart({
          data: workoutData,
          messageSuccess: t(
            competition ? 'message.success.save.workout' : 'message.success.save.class',
          ),
          redirect: competition || (index === dateOptions?.length && !callbackSave),
          callback,
        }),
      );
    } else {
      const save = (newData = {}) => {
        dispatch(
          workoutsActions.putWorkoutStart({
            data: { id: workout.id, ...workoutData, ...newData },
            messageSuccess: t(
              competition ? 'message.success.save.workout' : 'message.success.save.class',
            ),
            redirect: !callbackSave,
            callback,
          }),
        );
      };

      if (workout.recurringTemplate) {
        setModalConfirmSave({
          open: true,
          save,
          cancel: () => {
            setSubmiting(false);
            dispatch(appActions.setLoading(false));
          },
        });
        return;
      }
      save({});
    }
  };

  const buildScheduledTimes = (method) => {
    const newOptions = [];
    if (method === 'video') {
      return scheduleOptions;
    }
    scheduleOptions.forEach((option) => {
      const timezone = option.timezone || timeZoneName();
      if (option.repeat && option.weekDays && option.scheduledAtDate) {
        option.weekDays.forEach((weekDay) => {
          const nextScheduledAt = date(
            nextDayOfWeek(option.scheduledAtDate?.substr(0, 10), weekDay.day),
            'MM-DD-YYYY HH:mm a',
          );

          const dateStr = `${nextScheduledAt?.substr(0, 10)} ${option?.scheduledAtHour?.substr(
            11,
            8,
          )} ${timezone}`;

          const scheduledAt = formatDateToApi(dateStr, timezone);
          const endAt = formatDateToApi(
            `${nextScheduledAt?.substr(0, 10)} ${option?.endAtHour?.substr(11, 8)} ${timezone}`,
            timezone,
          );

          newOptions.push({
            ...option,
            scheduledAt,
            endAt,
            autoRenew: true,
            every: weekDay.every,
          });
        });
      } else {
        const dateStr = `${option.scheduledAtDate?.substr(0, 10)} ${option?.scheduledAtHour?.substr(
          11,
          8,
        )} ${timezone}`;

        const scheduledAt = formatDateToApi(dateStr, timezone);

        const endAt = formatDateToApi(
          `${option.scheduledAtDate?.substr(0, 10)} ${option.endAtHour?.substr(11, 8)} ${timezone}`,
          timezone,
        );
        newOptions.push({
          ...option,
          scheduledAt,
          endAt,
          autoRenew: true,
          every: 1,
        });
      }
    });

    return newOptions;
  };

  const onSubmit = (data) => {
    if (submiting) {
      return;
    }
    setSubmiting(true);

    setTimeout(() => {
      if (!validateWorkout(data)) {
        setSubmiting(false);
        return;
      }

      if (data.method === 'video' && upload.type === 'file' && !data.videoLink) {
        setSubmiting(false);
        setUpload((prev) => ({ ...prev, open: true }));
        return;
      }

      let videoData = video && video.data ? video.data.split('base64,')[1] : null;
      if (video && video.data) {
        if (video.data.indexOf('webm') > -1) {
          videoData = `data:video/webm;base64,${videoData}`;
        } else {
          videoData = `data:video/mov;base64,${videoData}`;
        }
      }

      if (!competition) {
        const dateOptions = buildScheduledTimes(data?.method);
        dateOptions.forEach((option, index) => {
          const timezone = option.timezone || timeZoneName();
          const publishTimezone = data.publishTimezone || timeZoneName();

          let publishAt = null;
          let stopRecurring = null;
          let autoRenew = false;
          let every = 1;
          if (!isTemplate) {
            if (data.publishAtDate) {
              publishAt = formatDateToApi(
                `${data.publishAtDate?.substr(0, 10)} ${data.publishAtTime?.substr(11, 8)}`,
                publishTimezone,
              );
            }

            if (option.stopRecurring) {
              stopRecurring = formatDateToApi(option.stopRecurring?.substr(0, 10));
            }
            autoRenew = option.autoRenew;
            every = option.every;
          }

          const { relatedWorkouts } = data;

          // eslint-disable-next-line no-param-reassign
          delete data.relatedWorkouts;

          const workoutData = {
            ...data,
            ownerId: data.ownerId || currentUser.id,
            scheduledAt: option?.scheduledAt,
            endAt: option?.endAt,
            publishAt,
            publishTimezone,
            durationMinutes: option.durationMinutes,
            frequency: option.repeat,
            stopRecurring,
            autoRenew,
            every,
            timezone,
            channelId: selectedChannel && selectedChannel.id,
            workoutRecurringId,
            previewVideoAttributes: {
              video: videoData,
              _destroy: video?._destroy,
            },
            movementsAttributes:
              data.movements &&
              data.movements.map((x) => ({
                name: x.name ? x.name : x,
                movement: x.movement ? x.movement.name || x.movement : null,
                embedVideo: x.embedVideo,
              })),
            equipmentAttributes:
              data.equipment &&
              data.equipment.map((x) => ({
                name: x.name ? x.name : x,
                equipment: x.equipment ? x.equipment.name || x.equipment : null,
              })),
            sectionsAttributes: sectionsRef.current.map((s) => ({
              ...s,
              id: s.new === true || modeClass === 'new' ? null : s.id,
            })),
            channelIds: channels.filter((c) => c !== selectedChannel.id),
            membershipPlanBlocksAttributes:
              membershipPlanBlocks && membershipPlanBlocks.map((x) => x.id),
            channelListsAttributes: channelLists && channelLists.map((x) => x.id),
            coachIds: data.coaches,
            invitesAttributes: data.invites,
            usersAttributes: data?.users?.map((x) => ({ ...x, autoAdded: true, inPerson: true })),
            channelLocationIds: data.inPerson ? locations : [],
            coachScheduleSessionId,
            templateId: copyClass ? workoutId : null,
            relatedWorkouts:
              relatedWorkouts &&
              relatedWorkouts.filter((x) => Number(x) !== workout.id).map((x) => x),
            pictureDayAttributes:
              pictureDay?.new || pictureDay?._destroy === true ? pictureDay : null,
            workoutHeatsAttributes: heats.map((x) => ({
              ...x,
              startTime: cutTime(x.startTime),
              endTime: cutTime(x.endTime),
            })),
          };

          saveWorkout(workoutData, index + 1, dateOptions);
        });
      } else {
        const timezone = data.timezone || timeZoneName();
        const startAt = formatDateToApi(
          `${data.startAtDate?.substr(0, 10)} ${data.startAtTime?.substr(11, 8)}`,
          timezone,
        );
        const endAt = formatDateToApi(
          `${data.endAtDate?.substr(0, 10)} ${data.endAtTime?.substr(11, 8)}`,
          timezone,
        );

        const workoutData = {
          ...data,
          relatedWorkouts: [],
          startAt,
          endAt,
          timezone,
          competitionId: selectedCompetition.id,
          previewVideoAttributes: {
            video: videoData,
            _destroy: video?._destroy,
          },
          movementsAttributes:
            data.movements &&
            data.movements.map((x) => ({
              name: x.name ? x.name : x,
              movement: x.movement ? x.movement.name || x.movement : null,
              embedVideo: x.embedVideo,
            })),
          equipmentAttributes:
            data.equipment &&
            data.equipment.map((x) => ({
              name: x.name ? x.name : x,
              equipment: x.equipment ? x.equipment.name || x.equipment : null,
            })),
          sectionsAttributes: [sectionsRef.current[0]].map((s) => ({
            ...s,
            id: s.new === true || modeClass === 'new' ? null : s.id,
          })),
          attachmentsAttributes: attachments.map((x) => ({
            id: x.id,
            name: x.name,
            attachment: x.data,
            _destroy: x.destroy || null,
          })),
        };
        saveWorkout(workoutData, 1);
      }
    }, 2000);
  };

  useEffect(() => {
    if (upload.complete) {
      handleSubmit(onSubmit)();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upload.complete]);

  const duplicateClass = () => {
    setModeClass('new');
    setVideo(null);
    setScheduleOptions([
      {
        durationMinutes: null,
        timezone: timeZoneName(),
        scheduledAtDate: null,
        scheduledAtHour: null,
      },
    ]);
    window.history.pushState({}, '', '/workout/new');
    setConfirmOpen(false);
  };

  useEffect(() => {
    if (!workoutRecurringId && modeClass === 'edit' && workout.editable === false) {
      setConfirmOpen(true);
    }
  }, [workout, modeClass, setConfirmOpen, workoutRecurringId]);

  useEffect(() => {
    if (workout.id) {
      setCompetition(!!workout.isCompetition);
      if (workout.isCompetition) {
        setSelectedCompetition(workout.competition);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workout]);

  useEffect(() => {
    if (errorsPopup.length > 0) {
      setPopUpValidation(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorsPopup]);

  useEffect(() => {
    if (errors) {
      const er = Object.keys(errors).map((key) => errors[key].message);
      setErrorsPopup(() => [...er]);
    }
  }, [errors]); //eslint-disable-line

  const populateSections = useCallback(() => {
    if (!copyClass && modeClass !== 'edit') {
      return;
    }
    if (workout && workout.sections) {
      setSections([
        ...workout.sections.map((x) => ({
          ...x,
          partnersProgram: workout?.partnersProgram,
          partnersProgramId: workout?.partnersProgram?.id,
          mediaFilesAssociations: x?.mediaFilesAssociations?.map((ass) => ({
            id: copyClass ? null : ass.id,
            mediaFileId: ass.mediaFile?.id,
            mediaFile: ass.mediaFile,
            kind: ass.kind,
          })),
          mediaFilesAssociationsAttributes: x?.mediaFilesAssociations?.map((ass) => ({
            id: copyClass ? null : ass.id,
            mediaFileId: ass.mediaFile?.id,
            mediaFile: ass.mediaFile,
            kind: ass.kind,
          })),
        })),
      ]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workout, modeClass]);

  useEffect(() => {
    populateSections();
  }, [populateSections]);

  return {
    workoutType:
      watch('channelsWorkoutsTypeId') &&
      selectedChannel?.workoutsTypes?.find((x) => x.id === Number(watch('channelsWorkoutsTypeId'))),
    workout,
    register,
    errors,
    reset,
    handleSubmit,
    onSubmit,
    control,
    setValue,
    watch,
    getValues,
    modeClass,
    setModeClass,
    sections,
    setSections,
    setSelectedCompetition,
    selectedCompetition,
    modalChannel,
    setModalChannel,
    video,
    setVideo,
    setCompetition,
    competition,
    setSubmiting,
    submiting,
    errorsPopup,
    setErrorsPopup,
    popUpValidation,
    setPopUpValidation,
    setScheduleOptions,
    scheduleOptions,
    duplicateClass,
    confirmOpen,
    setConfirmOpen,
    selectedChannel,
    setSelectedChannel,
    membershipPlanBlocks,
    setMembershipPlanBlocks,
    channelLists,
    setChannelLists,
    upload,
    setUpload,
    locations,
    setLocations,
    channels,
    setChannels,
    isScheduleSession,
    setIsScheduleSession,
    attachments,
    setAttachments,

    modalConfirmSave,
    setModalConfirmSave,
    formOpenSection,
    setFormOpenSection,
    pictureDay,
    setPictureDay,

    heats,
    setHeats,

    modalSection,
    setModalSection,

    type,

    showMembershipAccess:
      watch('freeInPersonOption') !== 'everyone' &&
      selectedChannel &&
      selectedChannel?.planValid &&
      selectedChannel?.membershipAllPlans &&
      selectedChannel?.membershipAllPlans
        .filter((x) => (watch('inPerson') && x.inPerson) || x.online)
        .filter((x) => (watch('online') && x.online) || x.inPerson).length > 0,
  };
};

export default useFormWorkout;
