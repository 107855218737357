import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

import * as selectors from './selectors';
import * as sagas from './sagas';

/* ---------- Action Creators and Types ---------- */
const { Types, Creators } = createActions(
  {
    getMovementsAutocompleteError: ['error'],
    getMovementsAutocompleteStart: ['params'],
    getMovementsAutocompleteSuccess: ['movements'],

    clearMovementsAutocomplete: [],
  },
  { prefix: 'movements/' },
);

/* ---------- Initial State ---------- */
const INITIAL_STATE = Immutable({
  dataAutocomplete: [],
  error: null,
  loading: false,
});

/* ---------- Reducers ---------- */
const loadStartReducer = (state) => state.set('loading', true);

const getMovementsAutocompleteSuccessReducer = (state, action) =>
  state.merge({
    dataAutocomplete: action.movements,
    loading: false,
    error: null,
  });

const loadErrorReducer = (state, action) =>
  state.merge({
    loading: false,
    error: action.error,
  });

const clearMovementsAutocompleteReducer = (state) =>
  state.merge({
    dataAutocomplete: [],
  });

/* ---------- Main Reducer ---------- */
const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_MOVEMENTS_AUTOCOMPLETE_START]: loadStartReducer,
  [Types.GET_MOVEMENTS_AUTOCOMPLETE_SUCCESS]: getMovementsAutocompleteSuccessReducer,
  [Types.GET_MOVEMENTS_AUTOCOMPLETE_ERROR]: loadErrorReducer,

  [Types.CLEAR_MOVEMENTS_AUTOCOMPLETE]: clearMovementsAutocompleteReducer,
});

/* ---------- Exporting ---------- */
export const movementsActions = Creators;
export const movementsTypes = Types;
export const movementsSelectors = selectors;
export const movementsSagas = sagas;

export default reducer;
