import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form-v5';
import { useDispatch, useSelector } from 'react-redux';
import { channelsSelectors } from 'modules/channels';
import notifications from 'modules/notifications';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import useSelectors from 'modules/map/useSelectors';
import { channelsStagesModule } from 'modules/channelsStages';

const useStagesForm = ({ mode, item, callback }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const channel = useSelector((state) => channelsSelectors.getChannel(state));
  const loading = useSelectors(channelsStagesModule, 'loading');
  const { request } = useActions();
  const { register, handleSubmit, setValue, watch, reset, errors } = useForm();

  const onSubmit = (values) => {
    request({
      action:
        mode === 'new'
          ? Modules.channelsStages.actions.postChannelsStages
          : Modules.channelsStages.actions.putChannelsStages,
      data: {
        ...values,
        id: item?.id || null,
        channelId: channel.id,
      },
      options: {
        onSuccess: () => {
          if (callback) {
            callback(values.name);
          }
          dispatch(notifications.success(t('message.success.save.stages')));
          request({
            action: Modules.channelsStages.actions.getChannelsStages,
            data: { channelId: channel.id },
          });
        },
      },
    });
  };

  useEffect(() => {
    register('name', { required: true });
    register('color', { required: false });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register]);

  useEffect(() => {
    if (item && item?.id) {
      reset({
        ...item,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, item?.id]);

  return {
    register,
    setValue,
    watch,
    reset,
    errors,
    onSubmit,
    handleSubmit,
    loading,
  };
};

export default useStagesForm;
