import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import { ButtonPrimary } from 'core/ui/Button';
import LoaderSm from 'core/ui/LoaderSm';
import { useForm } from 'react-hook-form-v5';
import { useDispatch, useSelector } from 'react-redux';
import { workoutHeatsActions, workoutHeatsSelectors } from 'modules/workoutHeats';

const FormConfig = ({ workoutId, callback, buttonColor }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loading = useSelector((state) => workoutHeatsSelectors.getLoading(state));

  const { setValue, register, watch, handleSubmit, errors } = useForm();

  useEffect(() => {
    register('heats', { required: true });
    register('lanes', { required: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (data) => {
    dispatch(
      workoutHeatsActions.postWorkoutHeatStart(
        {
          ...data,
          workoutId,
        },
        () => {
          if (callback) callback();
        },
      ),
    );
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box mb={3}>
        <TextFieldRounded
          type="number"
          pattern="[0-9]*"
          value={watch('heats') || ''}
          onChange={(v) => setValue('heats', v)}
          placeholder={t('workout.form.heats.label.heats')}
          error={errors.heats}
          label={t('workout.form.heats.label.heats').toUpperCase()}
          required
        />
      </Box>
      <Box mb={3}>
        <TextFieldRounded
          type="number"
          pattern="[0-9]*"
          value={watch('lanes') || ''}
          onChange={(v) => setValue('lanes', v)}
          placeholder={t('workout.form.heats.label.lanes')}
          error={errors.lanes}
          label={t('workout.form.heats.label.lanes').toUpperCase()}
          required
        />
      </Box>

      <Box mt={3} display="flex" justifyContent="flex-end">
        <ButtonPrimary
          style={{ backgroundColor: buttonColor }}
          disabled={loading}
          onClick={handleSubmit(onSubmit)}
        >
          <LoaderSm loading={loading} />
          {t('button.save')}
        </ButtonPrimary>
      </Box>
    </form>
  );
};

FormConfig.defaultProps = {
  callback: () => {},
};

FormConfig.propTypes = {};

export default FormConfig;
