import Modules from 'modules';
import useActions from 'modules/map/useActions';
import { useEffect, useState } from 'react';

const useCompAthletes = ({ competition, divisionId, hasDivision, load = true }) => {
  const { request } = useActions();
  const [compUsers, setCompUsers] = useState([]);

  const getCompUsers = () => {
    request({
      action: Modules.competitionsUsers.actions.getCompetitionsUser,
      data: {
        competitionId: competition?.id,
        divisionId,
        regType: 'competitor',
        noPagination: 1,
      },
      options: {
        onSuccess: (resp) => {
          setCompUsers(resp);
        },
      },
    });
  };

  useEffect(() => {
    if (competition?.id && load && (!hasDivision || !!divisionId)) {
      getCompUsers();
    }
  }, [competition, divisionId, hasDivision, load]);

  return {
    compUsers,
  };
};

export default useCompAthletes;
