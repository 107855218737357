import React, { useState } from 'react';
import Box from 'core/ui/Box';
import Typography from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import { Collapse } from '@material-ui/core';
import { listScores } from 'core/utils/helpers';
import ViewVideo from 'components/LibraryMedias/MediaFilesGallery/ViewVideo';
import LabelBreakLine from 'core/ui/LabelBreakLine';
import SectionTimerInfo from 'components/Workout/Form/Section/SectionTimerInfo';
import ViewSectionScoresTypes from 'components/Workout/Form/Section/ViewSectionScoresTypes';
import { secondary } from 'core/ui/Colors';
import SectionPersonalRecords from 'components/Workout/Show/Tabs/Overview/SectionPersonalRecords';
import MIcon from 'core/ui/MIcon';
import { ButtonPrimary } from 'core/ui/Button';
import { Lock } from '@material-ui/icons';

const SectionProgrammingWorkout = ({ workout, section, buttonColor, setModalProgram }) => {
  const { t } = useTranslation();
  const [opened] = useState(true);

  const sectionVideos = section?.mediaFilesAssociations
    ?.filter((x) => x.kind === 'section' && !!x.mediaFile)
    .map((x) => x.mediaFile);

  return (
    <Box>
      <Box>
        <Box
          style={{ backgroundColor: secondary }}
          display="flex"
          className="link"
          alignItems="center"
          p={1}
        >
          <Box flexGrow={1}>
            <Typography color="secondary" variant="body2" style={{ color: 'white' }}>
              {section?.title?.toUpperCase()}
            </Typography>
            {workout?.partnersProgram && (
              <Typography style={{ fontSize: 10, color: 'white' }}>
                <MIcon icon="fitness_center" color="white" size={9} />
                &nbsp;{workout?.partnersProgram?.name}
              </Typography>
            )}
          </Box>

          <Box>
            <ButtonPrimary
              onClick={() => setModalProgram({ open: true, program: workout?.partnersProgram })}
              px={2}
              py={2}
              style={{ minWidth: 20 }}
              buttonColor={buttonColor}
            >
              <Lock style={{ fontSize: 10 }} />
            </ButtonPrimary>
          </Box>
        </Box>

        <Box mt={3}>
          <Collapse in={opened}>
            <Box px={2} pb={5}>
              {sectionVideos?.length > 0 && (
                <Box my={3}>
                  {sectionVideos.map((x) => (
                    <Box key={x.id} mb={1}>
                      <ViewVideo url={x?.url} />
                    </Box>
                  ))}
                </Box>
              )}
              <Box px={2} pb={5}>
                <Typography mb={3} className="roboto-regular">
                  <LabelBreakLine>{section?.description}</LabelBreakLine>
                </Typography>

                <SectionPersonalRecords section={section} />

                {section?.timerData?.type !== 'none' && (
                  <Box display="flex" alignItems="center">
                    <SectionTimerInfo section={section} fontSize={12} showTimer={false} />
                  </Box>
                )}

                {listScores(section, t) && (
                  <Box my={2}>
                    <ViewSectionScoresTypes scores={section?.scores} />
                  </Box>
                )}
              </Box>
            </Box>
          </Collapse>
        </Box>
      </Box>
    </Box>
  );
};

export default SectionProgrammingWorkout;

SectionProgrammingWorkout.defaultProps = {
  section: {},
  index: 0,
  selectTab: null,
  hideSections: true,
  isRegistered: false,
};
