import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { roomSelectors } from 'modules/room';
import Box from 'core/ui/Box';
import useWindowSize from 'core/utils/hook/useWindowSize';
import { drawerStreamWidth, StreamContext } from 'pages/Stream';
import useLayoutGrid from './Layout/useLayoutGrid';
import './Layout/index.css';
import LiveUsersGallery from './LiveUsersGallery';

const GalleryView = () => {
  const streamContext = useContext(StreamContext);
  const participants = useSelector((state) => roomSelectors.getParticipants(state));
  const size = useWindowSize();
  const { heightBox, colsSize, widthBox } = useLayoutGrid({
    maxHeight: size.height - 150,
    maxWidth: size.width - (streamContext.openDrawer ? drawerStreamWidth + 100 : 150),
    videoCount: participants ? participants.length + 1 : 1,
  });

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Box
        my={5}
        mx={3}
        display="flex"
        alignItems="center"
        justifyContent="center"
        className="gallery"
        style={{ maxWidth: `calc(${widthBox} * ${colsSize})` }}
      >
        <LiveUsersGallery style={{ width: widthBox, height: heightBox }} />
      </Box>
    </Box>
  );
};

GalleryView.propTypes = {};

GalleryView.defaultProps = {};

export default GalleryView;
