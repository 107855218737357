import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import Grid from 'core/ui/Grid';
import Typography, { fontStyle } from 'core/ui/Typography';
import { redColor, successColor } from 'core/ui/Colors';
import { channelCurrency } from 'core/utils/formatters';
import Paper from 'core/ui/Paper';

const CardTotals = ({ amount, label, positive, style = fontStyle.large, channel }) => {
  if (amount === '0.00') {
    return <></>;
  }
  return (
    <Box display="flex" flexDirection="column">
      <Typography style={style}>{label}</Typography>
      <Typography style={{ ...style, color: positive ? successColor : 'red' }} color="secondary">
        {channelCurrency(amount, channel?.currency)}
      </Typography>
    </Box>
  );
};

const CardExpenses = ({ channel, data }) => {
  const { t } = useTranslation();

  return (
    <Paper className="paper-rounded" style={{ overflow: 'hidden' }}>
      <Typography variant="h5" p={3}>
        {t('label.expenses')}
      </Typography>

      <Grid container spacing={3} p={3} style={{ height: 200 }}>
        {data.resumePaymentsAmounts?.channelPlan && (
          <Grid item xs={6}>
            <CardTotals
              channel={channel}
              label={t('channel.reports.summary.channelPlan')}
              amount={data.resumePaymentsAmounts?.channelPlan}
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <CardTotals
            channel={channel}
            label={t('channel.reports.summary.taxAmount')}
            amount={data.resumePaymentsAmounts?.taxAmount}
          />
        </Grid>
        <Grid item xs={6}>
          <CardTotals
            channel={channel}
            label={t('channel.reports.summary.refunds')}
            amount={data.resumePaymentsAmounts?.refunds}
          />
        </Grid>
        <Grid item xs={6}>
          <CardTotals
            channel={channel}
            label={t('channel.reports.summary.stripeFees')}
            amount={data.resumePaymentsAmounts?.applicationFee}
          />
        </Grid>
        <Grid item xs={6}>
          <CardTotals
            channel={channel}
            label="Credits"
            amount={data.resumePaymentsAmounts?.creditAmount}
          />
        </Grid>
      </Grid>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        style={{ backgroundColor: '#FFC2C4' }}
        p={5}
      >
        <Typography variant="h2" style={{ color: redColor }}>
          {channelCurrency(data.resumePaymentsAmounts?.expenses, channel?.currency)}
        </Typography>
      </Box>
    </Paper>
  );
};

export default CardExpenses;
