/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Calendar from 'core/ui/Calendar';
import { parseDate, time, timeWithoutTz } from 'core/utils/formatters';
import { isMobile } from 'react-device-detect';
import { ButtonPrimary } from 'core/ui/Button';
import { ButtonGroup, makeStyles } from '@material-ui/core';
import Paper from 'core/ui/Paper';
import Box from 'core/ui/Box';
import { secondaryChat } from 'core/ui/Colors';
import { EventNote, NavigateBefore, NavigateNext } from '@material-ui/icons';
import Checkbox from 'core/ui/Checkbox';
import { useTranslation } from 'react-i18next';
import { sessionSelectors } from 'modules/session';
import { useSelector } from 'react-redux';
import Typography, { fontStyle } from 'core/ui/Typography';

function renderEventContent(eventInfo) {
  return (
    <Box style={{ overflowY: 'scroll' }}>
      <Typography variant="caption" style={fontStyle.xSmall}>
        {timeWithoutTz(eventInfo.event.start)}&nbsp;-&nbsp;{time(eventInfo.event.end)}
      </Typography>
      <Typography variant="h6">{eventInfo?.event?.title}</Typography>
      <Typography variant="caption" style={fontStyle.small} component="p">
        {eventInfo.event?.extendedProps.divisions}
      </Typography>
      <Typography variant="caption" style={fontStyle.small} component="p">
        {eventInfo.event?.extendedProps?.location}
      </Typography>
    </Box>
  );
}

const CompetitionCalendarEvents = ({
  setModalFormCustom,
  customEvents,
  competition,
  isCoachView,
  onViewClick,
}) => {
  const currentUser = useSelector((state) => sessionSelectors.getUser(state));
  const [currentDate, setCurrentDate] = useState(false);

  const [calendarRef, setCalendarRef] = useState(null);
  const { t } = useTranslation();
  const [myEvents, setMyEvents] = useState(false);
  const [events, setEvents] = useState([]);

  const setEventsByFilters = () => {
    const evs = competition?.workouts
      ? competition.workouts
          .filter(
            (x) =>
              !myEvents ||
              x.competitionsUsersCategories?.find((y) => y.userId === currentUser?.id) ||
              x.heatsVolunteers?.find((y) => y === currentUser?.id),
          )
          .map((item) => ({
            id: item.id,
            backgroundColor: item?.competitionsStageLocation?.color || secondaryChat,
            borderColor: item?.competitionsStageLocation?.color || secondaryChat,
            display: 'block',
            title: item.name,
            start: parseDate(item.startAt).format('YYYY-MM-DD HH:mm:ss'),
            end: parseDate(item.endAt).format('YYYY-MM-DD HH:mm:ss'),
            textColor: 'white',
            divisions: item?.divisions?.map((x) => x.name).join(','),
            location: item?.competitionsStageLocation?.name,
          }))
      : [];

    const cEvents =
      (customEvents || [])?.map((item) => ({
        id: item.id,
        custom: true,
        backgroundColor: competition?.buttonColor,
        borderColor: competition?.buttonColor,
        display: 'block',
        title: item.name,
        description: item.description,
        start: parseDate(item.startAt).format('YYYY-MM-DD HH:mm:ss'),
        end: parseDate(item.endAt).format('YYYY-MM-DD HH:mm:ss'),
        textColor: 'white',

        startAt: item.startAt,
        endAt: item.endAt,
      })) || [];

    setEvents([...evs, ...cEvents]);
  };

  useEffect(() => {
    setEventsByFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [competition, isCoachView, customEvents, myEvents]);

  const calendarProps = isMobile ? { height: 400 } : {};

  const handleEventClick = ({ event }) => {
    onViewClick(event);
  };

  useEffect(() => {
    if (competition?.id && events?.length > 0 && events[0]?.start && calendarRef.current) {
      // setCurrentDate(calendarRef?.current?.getApi()?.view?.title);
      calendarRef.current?.getApi()?.gotoDate(parseDate(events[0]?.start).format('YYYY-MM-DD'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [competition, events]);

  return (
    <Paper className="opacity-80" style={{ overflow: 'hidden' }}>
      <Box display="flex" p={2} alignItems="center" justifyContent="flex-end" className="gap-5">
        <EventNote color="secondary" fontSize="large" />
        <div className="tw-flex tw-flex-1 tw-flex-row tw-items-center tw-justify-center">
          <Typography variant="h3">{currentDate}</Typography>
        </div>
        {competition?.isCoach && (
          <ButtonPrimary
            buttonColor={competition.buttonColor}
            onClick={() => setModalFormCustom({ open: true })}
          >
            {t('button.customEvent')}
          </ButtonPrimary>
        )}
        {competition?.registeredAsVolunteer && (
          <Box>
            <Checkbox
              color={competition?.buttonColor}
              checked={myEvents}
              onClick={() => setMyEvents(!myEvents)}
              label={t('label.myAssignments')}
            />
          </Box>
        )}
        <GridView calendarRef={calendarRef} buttonColor={competition?.buttonColor} />
      </Box>
      <Calendar
        calendarRef={setCalendarRef}
        headerToolbar={1}
        eventClick={handleEventClick}
        events={events}
        style={calendarProps}
        backgroundColor="rgba(255,255,255,0.7)"
        initialView="timeGridDay"
        eventContent={renderEventContent}
        datesSet={() => {
          if (calendarRef?.current) {
            setCurrentDate(calendarRef?.current?.getApi()?.view?.title);
          }
        }}
        dayHeaderFormat={{ weekday: 'short', month: 'numeric', day: 'numeric', omitCommas: true }}
      />
    </Paper>
  );
};

const useStyles = makeStyles(() => ({
  btnTypeDisabled: {
    backgroundColor: 'rgba(64, 64, 65, 1)!important',
    color: 'white !important',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgba(64, 64, 65, 0.8)!important',
    },
  },
}));

const GridView = ({ calendarRef, buttonColor }) => {
  const classes = useStyles();
  const [currentView, setCurrentView] = useState('timeGridDay');

  const gridView = (newType) => {
    if (!calendarRef?.current?.getApi()) {
      return;
    }
    setCurrentView(newType);
    if (newType === 'timeGridProgram') {
      calendarRef.current?.getApi()?.changeView('timeGridWeek');
    } else {
      calendarRef.current?.getApi()?.changeView(newType);
    }
  };

  const prev = () => {
    calendarRef.current.getApi().prev();
  };

  const next = () => {
    calendarRef.current.getApi().next();
  };
  return (
    <Box display="flex" className="gap-10">
      <ButtonGroup variant="contained" color="secondary">
        <ButtonPrimary
          buttonColor={buttonColor}
          onClick={() => prev()}
          style={{ backgroundColor: buttonColor }}
        >
          <NavigateBefore />
        </ButtonPrimary>
        <ButtonPrimary
          buttonColor={buttonColor}
          onClick={() => next()}
          style={{ backgroundColor: buttonColor }}
        >
          <NavigateNext />
        </ButtonPrimary>
      </ButtonGroup>

      <ButtonGroup variant="contained" color="secondary">
        <ButtonPrimary
          className={currentView !== 'dayGridMonth' && classes.btnTypeDisabled}
          buttonColor={buttonColor}
          onClick={() => gridView('dayGridMonth')}
        >
          month
        </ButtonPrimary>
        <ButtonPrimary
          className={currentView !== 'timeGridWeek' && classes.btnTypeDisabled}
          onClick={() => gridView('timeGridWeek')}
          buttonColor={buttonColor}
        >
          week
        </ButtonPrimary>
        <ButtonPrimary
          className={currentView !== 'timeGridDay' && classes.btnTypeDisabled}
          onClick={() => gridView('timeGridDay')}
          buttonColor={buttonColor}
        >
          day
        </ButtonPrimary>
      </ButtonGroup>
    </Box>
  );
};

export default CompetitionCalendarEvents;
