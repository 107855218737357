/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import Modules from 'modules';
import useActions from 'modules/map/useActions';
import useSelectors from 'modules/map/useSelectors';
import Paper from 'core/ui/Paper';
import { usersAddressesModule } from 'modules/usersAddresses';
import { Collapse } from '@material-ui/core';
import Typography from 'core/ui/Typography';
import { Add, KeyboardArrowDown, KeyboardArrowRight } from '@material-ui/icons';
import { ButtonPrimarySm } from 'core/ui/Button';
import Divider from 'core/ui/Divider';
import ModalFormAddress from 'components/Profile/Edit/Addresses/Form/Modal';

export default ({ userId, buttonColor, channelId, setAddress }) => {
  const { t } = useTranslation();
  const { request } = useActions();
  const [open, setOpen] = useState(false);
  const [modal, setModal] = useState({
    open: false,
  });
  const data = useSelectors(usersAddressesModule, 'data');

  const getAddresses = () => {
    request({
      action: Modules.usersAddresses.actions.getAdrresses,
      data: { userId, channelId },
    });
  };

  useEffect(() => {
    getAddresses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request, userId, channelId]);

  const select = (add) => {
    setAddress((prev) => ({
      ...prev,
      street: add.street,
      countryId: add.countryId,
      city: add.city,
      state: add.state,
      zip: add.zip,
    }));
    setOpen(false);
  };

  return (
    <Paper p={5}>
      <Box flexGrow="1" display="flex" alignItems="center">
        <Box flexGrow="1" display="flex" alignItems="center" onClick={() => setOpen(!open)}>
          <Typography color="secondary" className="link" variant="body2">
            {t('user.profileAddresses').toUpperCase()}
          </Typography>
        </Box>
        <Box
          className="link-hover"
          display="flex"
          alignItems="center"
          onClick={() => setModal((prev) => ({ ...prev, mode: 'new', open: true }))}
        >
          <Add style={{ fontSize: 20 }} />
        </Box>
        <Box display="flex" alignItems="center" onClick={() => setOpen(!open)}>
          {open ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
        </Box>
      </Box>
      {modal.open && (
        <ModalFormAddress
          open={modal.open}
          item={modal.item}
          mode={modal.mode}
          userId={userId}
          channelId={channelId}
          close={() => setModal(() => ({ open: false }))}
          callback={() => {
            setModal(() => ({ open: false }));
            getAddresses();
            setOpen(true);
          }}
        />
      )}
      <Collapse in={open}>
        <Box mt={3}>
          {data?.map((add) => (
            <Box key={add?.id}>
              <Box display="flex" alignItems="center">
                <Box flexGrow={1}>
                  <Typography>{add?.street}</Typography>
                  <Typography>
                    {add?.country?.name}, {add?.city}, {add?.state}, {add?.zip}
                  </Typography>
                </Box>
                <Box>
                  <ButtonPrimarySm buttonColor={buttonColor} onClick={() => select(add)}>
                    {t('button.select')}
                  </ButtonPrimarySm>
                </Box>
              </Box>
              <Divider m={1} />
            </Box>
          ))}
        </Box>
      </Collapse>
    </Paper>
  );
};
