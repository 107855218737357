import React, { useContext } from 'react';
import FiberSmartRecordIcon from '@material-ui/icons/FiberSmartRecord';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import { ButtonPrimarySm } from 'core/ui/Button';
import Video from 'core/ui/Video';
import Typography from 'core/ui/Typography';
import { primary } from 'core/ui/Colors';
import { InputLabel } from '@material-ui/core';
import { Delete, Publish } from '@material-ui/icons';
import { getBase64 } from 'core/utils/helpers';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import Paper from 'core/ui/Paper';
import ReactPlayer from 'react-player';
import { WorkoutContext } from '.';

const SelectFile = ({ video, setVideo }) => {
  const { t } = useTranslation();

  const handleCapture = ({ target }) => {
    if (target.files.length) {
      for (let i = 0; i < target.files.length; i += 1) {
        const f = target.files[i];
        // eslint-disable-next-line no-loop-func
        getBase64(f, (result) => {
          setVideo({
            data: result,
            name: f.name,
            url: URL.createObjectURL(f),
          });
        });
      }
    }
  };

  const randomId = Math.floor(Math.random() * 101);

  return (
    <Box style={{ position: 'relative', zIndex: 2 }}>
      <>
        <ButtonPrimarySm variant="contained" p={1}>
          <InputLabel style={{ color: primary }} htmlFor={`icon-button-file-${randomId}`}>
            <Box display="flex" alignItems="center">
              <Publish style={{ color: 'white' }} size="small" />
              <Typography style={{ color: 'white' }} ml={3} variant="subtitle2">
                {(video && video.name) || t('button.selectAVideoFile')}
              </Typography>
            </Box>
          </InputLabel>
        </ButtonPrimarySm>
        <input
          color="primary"
          type="file"
          onChange={handleCapture}
          id={`icon-button-file-${randomId}`}
          style={{ display: 'none' }}
          accept=".mpeg,.mp4,.avi,.mov,.webm,.flv"
        />
      </>
    </Box>
  );
};

const InfoRecord = () => {
  const formContext = useContext(WorkoutContext);
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="h5" color="primary" pb={3}>
        {t('workout.previewVideo')}
      </Typography>

      {!formContext.watch('previewVideoLink') && (
        <>
          {formContext.video && !formContext.video?._destroy ? (
            <Box className="container-aspect container-aspect-47">
              <Box className="item-aspect">
                <Video full src={formContext.video && formContext.video.url} />
              </Box>
            </Box>
          ) : (
            <Typography align="center">{t('workout.video.record')}</Typography>
          )}

          <Box m={5} display="flex" justifyContent="center">
            {formContext.video && !formContext.video?._destroy && (
              <ButtonPrimarySm
                onClick={() => formContext.setVideo((prev) => ({ ...prev, _destroy: true }))}
              >
                <Delete />
                &nbsp;Remove Video
              </ButtonPrimarySm>
            )}
            &nbsp;&nbsp;
            <ButtonPrimarySm onClick={() => formContext.setModalPreviewVideo(true)}>
              <FiberSmartRecordIcon />
              &nbsp;Record
            </ButtonPrimarySm>
            &nbsp;&nbsp;
            <Box>
              <SelectFile video={formContext.video} setVideo={formContext.setVideo} />
            </Box>
          </Box>
        </>
      )}

      <Box p={3}>
        <TextFieldRounded
          value={formContext.watch('previewVideoLink') || ''}
          onChange={(v) => formContext.setValue('previewVideoLink', v)}
          placeholder={t('workout.placeholder.video').toUpperCase()}
          bordered
        />
        {formContext.watch('previewVideoLink') && (
          <Paper p={3} mt={3}>
            <Box display="flex" justifyContent="center">
              {formContext.watch('previewVideoLink').indexOf('streamfitness-workout-video') > -1 ? (
                <Video
                  src={formContext.watch('previewVideoLink')}
                  controls
                  showPlayButton={false}
                />
              ) : (
                <Box className="container-aspect container-aspect-37">
                  <Box className="item-aspect">
                    <ReactPlayer
                      playing={false}
                      url={formContext.watch('previewVideoLink')}
                      width="100%"
                      height="100%"
                    />
                  </Box>
                </Box>
              )}
            </Box>
          </Paper>
        )}
      </Box>
    </>
  );
};

export default InfoRecord;
