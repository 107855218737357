import React, { useState } from 'react';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import DatePickerRounded from 'core/ui/DatePickers/DatePickerRounded';
import { timeZoneName, formatDateToApi, formatDate } from 'core/utils/formatters/date';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import useSelectors from 'modules/map/useSelectors';
import { newSearchModule } from 'modules/search/new';
import { InputWrapper } from 'core/ui/ChosenComponents';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import { ButtonPrimary } from 'core/ui/Button';
import LoaderSm from 'core/ui/LoaderSm';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import Grid from 'core/ui/Grid';
import Typography from 'core/ui/Typography';
import Toggle from 'core/ui/Toggle';
import ClassHistoryTable from './Table';
import ModalCloneWorkoutsToWorkout from '../../Schedule/ModalCloneWorkoutsToWorkout';

export default ({ channel, setWorkout, fromClassForm }) => {
  const workouts = useSelectors(newSearchModule, 'workouts');
  const paginationWorkouts = useSelectors(newSearchModule, 'paginationWorkouts');
  const loading = useSelectors(newSearchModule, 'loading');
  const { t } = useTranslation();
  const [query, setQuery] = useState([]);

  const dateNow = new Date();
  const month = dateNow.getMonth() + 1;

  const [filters, setFilters] = useState({
    workoutsType: null,
    status: null,
    startAt: formatDate(
      `${month > 12 ? 12 : month}/1/${dateNow.getFullYear()}`,
      channel?.dateFormatReact,
    ),
    endAt: formatDate(new Date(), channel?.dateFormatReact),
  });
  const [perPage, setPerPage] = useState(20);

  const { request } = useActions();
  const [modalClass, setModalClass] = useState({ open: false });

  const search = (page = 1) => {
    const startAt = filters.startAt ? formatDateToApi(filters.startAt) : null;
    const endAt = filters.endAt ? formatDateToApi(filters.endAt) : null;

    request({
      action: Modules.newSearch.actions.getWorkouts,
      data: {
        query,
        timezone: timeZoneName(),
        startAt,
        endAt,
        channel_id: channel.id,
        workoutsType: filters?.workoutsType,
        status: filters?.status,
        perPage,
        page,
      },
    });
  };

  const openCloneClass = (data) => {
    request({
      action: Modules.newWorkouts.actions.getWorkout,
      data: { id: data?.workoutId },
      options: {
        onSuccess: (w) => {
          setModalClass({ ...data, open: true, workout: w });
        },
      },
    });
  };

  return (
    <Box p={5}>
      {modalClass?.open && modalClass?.workout && (
        <ModalCloneWorkoutsToWorkout
          workouts={[modalClass?.workout]}
          close={() => setModalClass({ open: false })}
          open={modalClass?.open}
          channel={channel}
          showCompareModal
        />
      )}

      <Box mb={3}>
        <Box display="flex" alignItems="center">
          <Grid container spacing={5}>
            <Grid item xs={12} md={6}>
              <InputWrapper>
                <Box display="flex" style={{ width: '100%' }}>
                  <TextFieldRounded
                    value={query}
                    onChange={(value) => setQuery(value)}
                    style={{ minWidth: 30, width: '100%', margin: '4px' }}
                    placeholder={t('search.workout.placeholder')}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        search(1);
                      }
                    }}
                    bordered
                  />
                </Box>
              </InputWrapper>
            </Grid>
            <Grid item xs={12} md={6}>
              <SelectBoxObject
                value={filters?.workoutsType}
                setValue={(v) => setFilters((prev) => ({ ...prev, workoutsType: v }))}
                options={channel.workoutsTypes}
                height={40}
                emptyItem
                emptyValue={null}
                placeholder="Class Type"
                bordered
              />
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box display="flex" my={3} flexWrap="wrap" alignItems="center">
        <Box>
          <DatePickerRounded
            label={t('workout.history.start').toUpperCase()}
            onChange={(v) => setFilters((prev) => ({ ...prev, startAt: v }))}
            value={filters.startAt}
            disablePast={false}
            usFormat={channel?.useUsDateFormat}
            autoOk
          />
        </Box>
        <Box ml={1}>
          <DatePickerRounded
            label={t('workout.history.end').toUpperCase()}
            onChange={(v) => setFilters((prev) => ({ ...prev, endAt: v }))}
            value={filters.endAt}
            disablePast={false}
            usFormat={channel?.useUsDateFormat}
            autoOk
          />
        </Box>
        <Box ml={1}>
          <Typography>&nbsp;</Typography>
          <Toggle
            onChange={(v) => setFilters((prev) => ({ ...prev, status: v }))}
            value={filters.status}
            options={[
              { value: null, width: 100, label: 'All classes' },
              { value: 'active', width: 100, label: 'Active' },
              { value: 'canceled', width: 100, label: 'Canceled' },
            ]}
            color="white"
          />
        </Box>
        <Box ml={1}>
          <Typography>&nbsp;</Typography>
          <ButtonPrimary onClick={() => search(1)}>
            <LoaderSm loading={loading} />
            {t('button.search')}
          </ButtonPrimary>
        </Box>
      </Box>

      <ClassHistoryTable
        channel={channel}
        workouts={workouts}
        setWorkout={setWorkout}
        setModalClass={setWorkout ? null : openCloneClass}
        pagination={paginationWorkouts}
        changePage={(p) => search(p)}
        perPage={perPage}
        setPerPage={setPerPage}
        fromClassForm={fromClassForm}
      />
    </Box>
  );
};
