import { all, call, put } from 'redux-saga/effects';
import { getErrorMessage } from 'core/api/api-error';
import notifications from 'modules/notifications';
import { push } from 'connected-react-router';
import * as api from './api';
import { workoutsActions as actions } from './index';

export function* getWorkouts() {
  try {
    const response = yield call(api.workouts);
    const workouts = response.data;
    yield put(actions.getWorkoutsSuccess(workouts));
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.getWorkoutsError(getErrorMessage(error))),
    ]);
  }
}

export function* getWorkoutUsers({ id, callback }) {
  try {
    const response = yield call(api.workoutUsers, id);
    const users = response.data;
    yield put(actions.getWorkoutUsersSuccess(users));
    if (callback) {
      callback();
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.getWorkoutUsersError(getErrorMessage(error))),
    ]);
  }
}

export function* getWorkoutCoaches({ id }) {
  try {
    const response = yield call(api.workoutCoaches, id);
    const users = response.data;
    yield put(actions.getWorkoutCoachesSuccess(users));
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.getWorkoutCoachesError(getErrorMessage(error))),
    ]);
  }
}

export function* getWorkoutSections({ id, callback }) {
  try {
    const response = yield call(api.workoutSections, id);
    const users = response.data;
    yield put(actions.getWorkoutSectionsSuccess(users));
    if (callback) {
      callback();
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.getWorkoutSectionsError(getErrorMessage(error))),
    ]);
  }
}

export function* getWorkoutSectionLeaderboard({ workoutId, sectionId, params, callback }) {
  try {
    const response = yield call(api.workoutSectionLeaderboard, workoutId, sectionId, params);
    const users = response.data;
    yield put(actions.getWorkoutSectionLeaderboardSuccess(users));
    if (callback) {
      callback();
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.getWorkoutSectionLeaderboardError(getErrorMessage(error))),
    ]);
  }
}

export function* postWorkout({ data }) {
  try {
    const response = yield call(api.create, data.data);
    const workout = response.data;
    yield put(actions.postWorkoutSuccess(workout));

    if (data.callback) {
      data.callback();
    }

    if (data.redirect) {
      if (response.data.isCompetition) {
        yield put(push(`/competition/view/${response.data.competition.slug}`));
      } else if (response?.data?.channel?.id) {
        yield put(push(`/channel/manage/${response?.data?.channel?.id}/schedules`));
      } else {
        yield put(push(`/workout/view/${response.data.slug}`));
      }
      if (data.messageSuccess) {
        yield put(notifications.success(data.messageSuccess));
      }
    }
  } catch (error) {
    yield all([
      // put(notifications.error(getErrorMessage(error))),
      put(actions.postWorkoutError(getErrorMessage(error))),
    ]);
  }
}

export function* putWorkout({ data }) {
  try {
    const response = yield call(api.update, data.data);
    const workout = response.data;
    yield put(actions.putWorkoutSuccess(workout));

    if (data.callback) {
      data.callback();
    }

    if (data.redirect) {
      if (response.data.isCompetition) {
        yield put(push(`/competition/view/${response.data.competition.slug}`));
      } else if (response?.data?.channel?.id) {
        yield put(push(`/channel/manage/${response?.data?.channel?.id}/schedules`));
      } else {
        yield put(push(`/workout/view/${response.data.slug}`));
      }
      if (data.messageSuccess) {
        yield put(notifications.success(data.messageSuccess));
      }
    }
  } catch (error) {
    yield all([
      // put(notifications.error(getErrorMessage(error))),
      put(actions.putWorkoutError(getErrorMessage(error))),
    ]);
  }
}

export function* putWorkoutSection({ data }) {
  try {
    yield call(api.updateWorkoutSection, {
      workoutId: data.workoutId,
      workoutSectionId: data.workoutSectionId,
      sectionData: data.sectionData,
    });

    yield put(actions.putWorkoutSectionSuccess());
    if (data.messageSuccess) {
      yield put(notifications.success(data.messageSuccess));
    }
    if (data.callback) {
      data.callback();
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.putWorkoutSectionError(getErrorMessage(error))),
    ]);
  }
}

export function* postWorkoutRecordingInvites({ data, callback }) {
  try {
    yield call(api.postWorkoutRecordingInvites, data);

    yield put(actions.postWorkoutRecordingInvitesSuccess());
    if (callback) {
      callback();
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.postWorkoutRecordingInvitesError(getErrorMessage(error))),
    ]);
  }
}

export function* getWorkout({ id, callback, workoutsInviteId }) {
  try {
    const response = yield call(api.workout, id, workoutsInviteId);
    const workout = response.data;
    if (callback) {
      yield put(actions.getWorkoutStateLocalSuccess());
      callback(workout);
    } else {
      yield put(actions.getWorkoutSuccess(workout));
    }
  } catch (error) {
    if (error.statusCode === 404) {
      yield put(push('/dashboard'));
    }
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.getWorkoutsError(getErrorMessage(error))),
    ]);
  }
}

export function* getWorkoutDetail({ id, callback }) {
  try {
    const response = yield call(api.workout, id);
    const workout = response.data;
    if (callback) {
      callback(workout);
    }
    yield put(actions.getWorkoutDetailSuccess());
  } catch (error) {
    if (error.statusCode === 404) {
      yield put(push('/dashboard'));
    }
    console.log('error', error);
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.getWorkoutsError(getErrorMessage(error))),
    ]);
  }
}

export function* getWorkoutSectionUser({ data }) {
  try {
    const response = yield call(api.getWorkoutSectionUser, {
      workoutId: data.workoutId,
      userId: data.userId,
    });
    const workout = response.data;
    yield put(actions.getWorkoutSectionUserSuccess(workout));
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.getWorkoutSectionUserError(getErrorMessage(error))),
    ]);
  }
}

export function* deleteWorkoutSection({ data }) {
  try {
    yield call(api.deleteWorkoutSection, data);
    yield put(actions.deleteWorkoutSectionSuccess());
    if (data.success) {
      data.success();
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.deleteWorkoutSectionError(getErrorMessage(error))),
    ]);
  }
}

export function* setWorkoutSectionUser({ data, callback }) {
  try {
    const response = yield call(api.setWorkoutSectionUser, {
      workoutId: data.workoutId,
      data: data.data,
    });
    const workout = response.data;
    yield put(actions.setWorkoutSectionUserSuccess(workout));
    if (callback) {
      callback(response?.data);
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.setWorkoutSectionUserError(getErrorMessage(error))),
    ]);
  }
}

export function* setWorkoutSectionUserPenalties({ data }) {
  try {
    const response = yield call(api.setWorkoutSectionUserPenalties, {
      workoutId: data.workoutId,
      data: data.data,
    });
    const workout = response.data;
    yield put(actions.setWorkoutSectionUserPenaltiesSuccess(workout));
    if (data.messageSuccess) {
      yield put(notifications.success(data.messageSuccess));
    }
    if (data.callback) {
      data.callback();
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.setWorkoutSectionUserPenaltiesError(getErrorMessage(error))),
    ]);
  }
}

export function* setWorkoutCountdownSeconds({ data, callback }) {
  try {
    const response = yield call(api.setWorkoutCountdownSeconds, data);
    const workout = response.data;
    yield put(actions.setWorkoutCountdownSecondsSuccess(workout));
    if (callback) {
      callback();
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.setWorkoutCountdownSecondsError(getErrorMessage(error))),
    ]);
  }
}

export function* addWorkoutSectionUserFlag({ data }) {
  try {
    const response = yield call(api.addWorkoutSectionUserFlag, {
      workoutId: data.workoutId,
      data: data.data,
    });
    const workout = response.data;
    yield put(actions.addWorkoutSectionUserFlagSuccess(workout));
    if (data.messageSuccess) {
      yield put(notifications.success(data.messageSuccess));
    }
    if (data.callback) {
      data.callback();
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.addWorkoutSectionUserFlagError(getErrorMessage(error))),
    ]);
  }
}

export function* setWorkoutSectionUserVerified({ data }) {
  try {
    const response = yield call(api.setWorkoutSectionUserVerified, {
      workoutId: data.workoutId,
      data: data.data,
    });
    const workout = response.data;
    yield put(actions.setWorkoutSectionUserVerifiedSuccess(workout));
    if (data.messageSuccess) {
      yield put(notifications.success(data.messageSuccess));
    }
    if (data.callback) {
      data.callback();
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.setWorkoutSectionUserVerifiedError(getErrorMessage(error))),
    ]);
  }
}

export function* getUpcoming({ userId, pagination }) {
  try {
    const response = yield call(api.upcoming, { userId, pagination });
    const workouts = response.data;
    yield put(actions.getUpcomingSuccess(workouts, response.pagination));
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.getUpcomingError(getErrorMessage(error))),
    ]);
  }
}

export function* getCoachAccessed({ workoutId }) {
  try {
    const response = yield call(api.coachAccessed, workoutId);
    yield put(actions.getCoachAccessedSuccess(response.data));
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.getCoachAccessedError(getErrorMessage(error))),
    ]);
  }
}

export function* getUpcomingAttendings({ userId, pagination }) {
  try {
    const response = yield call(api.upcomingAttendings, { userId, pagination });
    const workouts = response.data;
    yield put(actions.getUpcomingAttendingsSuccess(workouts, response.pagination));
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.getUpcomingError(getErrorMessage(error))),
    ]);
  }
}

export function* getRecents({ userId, pagination }) {
  try {
    const response = yield call(api.recents, { userId, pagination });
    const workouts = response.data;
    yield put(actions.getRecentsSuccess(workouts, response.pagination));
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.getRecentsError(getErrorMessage(error))),
    ]);
  }
}

export function* getRecentsAttendings({ userId, pagination }) {
  try {
    const response = yield call(api.recentsAttendings, { userId, pagination });
    const workouts = response.data;
    yield put(actions.getRecentsAttendingsSuccess(workouts, response.pagination));
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.getRecentsAttendingsError(getErrorMessage(error))),
    ]);
  }
}

export function* getFavorites({ userId, pagination }) {
  try {
    const response = yield call(api.favorites, { userId, pagination });
    const workouts = response.data;
    yield put(actions.getFavoritesSuccess(workouts, response.pagination));
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.getFavoritesError(getErrorMessage(error))),
    ]);
  }
}

export function* purchase({ data, callback, onError }) {
  try {
    yield call(api.purchase, data);
    yield put(actions.purchaseSuccess());
    if (data.messageSuccess) {
      yield put(notifications.success(data.messageSuccess));
    }
    if (callback) {
      callback();
    }
  } catch (error) {
    if (onError) {
      onError(getErrorMessage(error));
    }
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.purchaseError(getErrorMessage(error))),
    ]);
  }
}

export function* refund({ data }) {
  try {
    yield call(api.refund, data);
    yield put(actions.refundSuccess());
    if (data.messageSuccess) {
      yield put(notifications.success(data.messageSuccess));
    }
    if (data.callback) {
      data.callback();
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.refundError(getErrorMessage(error))),
    ]);
  }
}

export function* putInactivateUser({ data }) {
  try {
    yield call(api.inactivateUser, data);
    yield put(actions.putInactivateUserSuccess());
    if (data.messageSuccess) {
      yield put(notifications.success(data.messageSuccess));
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.putInactivateUserError(getErrorMessage(error))),
    ]);
  }
}

export function* putTrackUser({ data }) {
  try {
    const response = yield call(api.trackUser, {
      workoutId: data.workoutId,
      data: data.data,
    });
    const section = response.data;
    yield put(actions.putTrackUserSuccess(section));
    if (data.messageSuccess) {
      yield put(notifications.success(data.messageSuccess));
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.putTrackUserError(getErrorMessage(error))),
    ]);
  }
}
