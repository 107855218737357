import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from 'core/ui/Typography';
import { Box } from '@material-ui/core';
import { useHistory } from 'react-router';
import { time } from 'core/utils/formatters';
import { useTranslation } from 'react-i18next';
import { primary, secondary } from 'core/ui/Colors';
import { dateExt } from 'core/utils/formatters/date';
import Header from '../Show/Header';

const useStyles = makeStyles({
  difficulty: {
    fontFamily: 'Roboto',
    fontSize: 14,
  },
  timeFont: {
    fontFamily: 'Roboto',
  },
  boxTag: {
    background: primary,
    position: 'absolute',
    bottom: 0,
    left: 0,
    zIndex: 1,
  },
  boxStreaming: {
    background: primary,
    width: 70,
    borderRadius: 3,
    zIndex: 1,
  },
  boxTagRight: {
    background: primary,
    position: 'absolute',
    bottom: 0,
    right: 0,
    zIndex: 1,
  },
  boxContent: {
    backgroundColor: 'white',
    color: secondary,
    borderBottomLeftRadius: '5px',
    borderBottomRightRadius: '5px',
    padding: 5,
    marginTop: -1,
    zIndex: 2,
    minHeight: 80,
  },
  detailBoxButton: {
    backgroundColor: 'white',
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 5,
    marginTop: -3,
  },
  listAdded: {
    background: '#1ed760',
    position: 'absolute',
    borderTopRightRadius: '5px',
    borderBottomLeftRadius: '5px',
    top: 0,
    padding: 3,
    right: 0,
    zIndex: 1,
  },
  boxRating: {
    background: primary,
    position: 'absolute',
    borderBottomRightRadius: '5px',
    borderBottomLeftRadius: '5px',
    top: 0,
    paddingLeft: 3,
    paddingRight: 3,
    right: 10,
    zIndex: 1,
  },
});

const CardCompetition = ({ competition }) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <Box style={{ minHeight: 220 }}>
      <div className="item item-shadow">
        <Box
          display="flex"
          onClick={() => history.push(`/competition/view/${competition.slug}`)}
          flexDirection="column"
        >
          <Box style={{ position: 'relative' }}>
            <Header competition={competition} />
          </Box>

          <Box className={classes.boxContent}>
            <Typography
              noWrap
              color="primary"
              variant="body2"
              style={{ textOverflow: 'ellipsis' }}
              component="h5"
            >
              {competition.name}
            </Typography>

            {!competition.ended ? (
              <>
                <Box flexGrow="1">
                  <Typography className={classes.dayFont} variant="caption" component="span">
                    {dateExt(competition.startAt, t, competition?.channel?.dateFormatExtReact)}{' '}
                    &bull;
                  </Typography>
                  <Typography
                    className={classes.timeFont}
                    variant="caption"
                    component="span"
                    ml={1}
                  >
                    {time(competition.startAt)}
                  </Typography>
                </Box>
                <Box flexGrow="1">
                  <Typography className={classes.dayFont} variant="caption" component="span">
                    {dateExt(competition.endAt, t, competition?.channel?.dateFormatExtReact)} &bull;
                  </Typography>
                  <Typography
                    className={classes.timeFont}
                    variant="caption"
                    component="span"
                    ml={1}
                  >
                    {time(competition.endAt)}
                  </Typography>
                </Box>
              </>
            ) : (
              <Typography className={classes.dayFont} variant="caption" component="span">
                {t('competition.ended')}
              </Typography>
            )}
          </Box>
        </Box>
      </div>
    </Box>
  );
};

export default CardCompetition;

CardCompetition.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  competition: PropTypes.object,
};

CardCompetition.defaultProps = {
  competition: {},
};
