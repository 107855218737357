import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Delete, Edit } from '@material-ui/icons';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { onlineColor, primary, successColor } from 'core/ui/Colors';
import Typography, { fontStyle } from 'core/ui/Typography';
import { ButtonPrimary } from 'core/ui/Button/index';
import { useTranslation } from 'react-i18next';
import { Chip, Dialog, Table, TableBody, withStyles } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import { channelCurrency, currency as currencyConvert, date } from 'core/utils/formatters';
import { linkStoreItem } from 'core/utils/helpers';
import Divider from 'core/ui/Divider';
import ExampleQR from 'assets/img/qr_code_example.png';
import ImageProduct from './Image';
import ProductOption from './Form/ProductOption';

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default function ItemProduct({
  currency,
  item,
  onEditClick,
  onRemoveClick,
  setSelectProduct,
  removeItemChannel,
  isSelector,
  isPartner,
  hideActions,
  marketPlaceView,
  onQrCodeClick,
  channelId,
}) {
  const { t } = useTranslation();
  const [openedDetails, setOpenedDetails] = useState(false);

  const Inventories = () => (
    <>
      {item.inventories &&
        item.inventories.map((stock, idx) => (
          <StyledTableRow key={idx.toString()} display="flex">
            <TableCell>
              <ProductOption option={stock} />
            </TableCell>
            {isPartner && (
              <TableCell>
                <Typography>{stock.sku}</Typography>
              </TableCell>
            )}
            <TableCell align="right">
              <Typography mx={3}>
                {stock.qtyUnlimited ? (
                  <>{t('channel.product.inventory.qtyUnlimited')}</>
                ) : (
                  <>
                    {Number(stock.qty) <= 0 && (
                      <>
                        <WarningIcon style={{ fontSize: 12 }} />
                        &nbsp;
                      </>
                    )}
                    {stock.qty}
                  </>
                )}
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography>{channelCurrency(stock.price, currency)}</Typography>
            </TableCell>
          </StyledTableRow>
        ))}
    </>
  );

  return (
    <>
      {openedDetails && (
        <Dialog
          maxWidth="md"
          fullWidth
          open={openedDetails}
          onClose={() => setOpenedDetails(false)}
        >
          <Box display="flex" alignItems="center" flexDirection="column" justifyContent="center">
            <Box mt={2} display="flex" alignItems="center">
              <Typography>{t('form.product.label.offerDelivery')}</Typography>
              <Typography ml={1} color="primary">
                {item.offerDelivery ? 'Yes' : 'No'}
              </Typography>
            </Box>
            {item.offerDelivery && (
              <>
                <Box mt={2} display="flex" alignItems="center">
                  <Typography>{t('form.product.label.deliveryPrice')}</Typography>
                  <Typography ml={1} color="primary">
                    {currencyConvert(item?.deliveryPrice)}
                  </Typography>
                </Box>
                {item?.deliveryCountry ? (
                  <Box mt={2} display="flex" alignItems="center">
                    <Typography>{t('form.product.label.deliveryOnly')}</Typography>
                    <Typography ml={1} color="primary">
                      {item?.deliveryCountry?.name}
                    </Typography>
                  </Box>
                ) : (
                  <Typography mt={2}>
                    {t('form.product.label.deliveryWorldWide').toUpperCase()}
                  </Typography>
                )}
              </>
            )}
            <Table size="small">
              <TableBody>
                <Inventories />
              </TableBody>
            </Table>
          </Box>
        </Dialog>
      )}
      <TableRow className="hover">
        <TableCell style={{ width: isSelector ? 100 : 50 }}>
          <ImageProduct first images={item.images || []} size={isSelector ? 96 : 32} />
        </TableCell>
        <TableCell>
          <Typography mr={1}>{item.name}</Typography>
        </TableCell>
        <TableCell>
          {!!channelId && (
            <Box display="flex" alignItems="center">
              {onQrCodeClick && (
                <Box
                  ml={2}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  className="hover"
                  onClick={() =>
                    onQrCodeClick({
                      open: true,
                      text: linkStoreItem(channelId, item?.id),
                      title: item.name,
                    })
                  }
                >
                  <Box>
                    <img src={ExampleQR} alt="Qr Code" style={{ width: 24 }} />
                  </Box>
                </Box>
              )}
            </Box>
          )}
        </TableCell>
        <TableCell>{item.category.name}</TableCell>

        <TableCell
          align="center"
          onClick={() => {
            setOpenedDetails((prev) => !prev);
          }}
        >
          {item?.preSaleData?.preSale && (
            <Box>
              <Chip size="small" color="primary" label="Pre-Sale" />
              <Typography>
                {date(item?.preSaleData?.preSaleStartDate)}
                &nbsp;&bull;&nbsp;
                {date(item?.preSaleData?.preSaleEndDate)}
              </Typography>

              <Divider m={1} />
            </Box>
          )}

          <Typography color="primary">Options</Typography>
        </TableCell>

        {!marketPlaceView && isPartner && (
          <TableCell align="center">
            <Typography wrap>
              {item?.partnersPrivateProductsChannels?.map((x) => x.channelName).join(', ')}
            </Typography>
          </TableCell>
        )}

        {!marketPlaceView && (
          <TableCell align="center">
            {!item.active ? (
              <RadioButtonUncheckedIcon style={{ ...fontStyle.medium, color: primary }} />
            ) : (
              <CheckCircleOutlineIcon style={{ ...fontStyle.medium, color: onlineColor }} />
            )}
          </TableCell>
        )}
        <TableCell align="right">
          {!hideActions && (
            <>
              {setSelectProduct ? (
                <>
                  {item.isAddedInChannel ? (
                    <ButtonPrimary onClick={() => removeItemChannel(item)}>
                      {t('button.remove')}
                    </ButtonPrimary>
                  ) : (
                    <ButtonPrimary
                      style={{ backgroundColor: successColor }}
                      onClick={() => setSelectProduct(item)}
                    >
                      {t('button.addToStore')}
                    </ButtonPrimary>
                  )}
                </>
              ) : (
                <>
                  {onEditClick && (
                    <IconButton onClick={() => onEditClick(item)}>
                      <Edit />
                    </IconButton>
                  )}
                  {onRemoveClick && (
                    <IconButton onClick={() => onRemoveClick(item)}>
                      <Delete />
                    </IconButton>
                  )}
                  {!isPartner && removeItemChannel && (
                    <IconButton onClick={() => removeItemChannel(item)}>
                      <Delete />
                    </IconButton>
                  )}
                </>
              )}
            </>
          )}
        </TableCell>
      </TableRow>
    </>
  );
}

ItemProduct.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.object.isRequired,
};
