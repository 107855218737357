import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ImgLive from 'assets/img/app-icon-small.png';
import imgLoadingIntro from 'assets/img/loading-intro-2.gif';
import Box from '../Box';

const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    zIndex: 2000,
    backgroundColor: '#2B2D35',
    display: 'flex',
    height: '100vh',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  img: {
    maxWidth: 600,
  },
}));

export default function StreamFitnessSplash() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Box>
        <img src={ImgLive} alt="live" className="classes.img" />
      </Box>
      <Box>
        <img src={imgLoadingIntro} alt="live" style={{ width: 50 }} />
      </Box>
    </div>
  );
}
