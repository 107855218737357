import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import { useDispatch } from 'react-redux';
import { Container } from '@material-ui/core';
import { LayoutContext } from 'pages/shared/Layout';
import Texture from 'core/ui/Texture';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import { useParams } from 'react-router';
import notifications from 'modules/notifications';
import ChannelsEventsShowComponent from 'components/ChannelsEvents/Show';
import { channelsActions } from 'modules/channels';

const ChannelsEventsShow = () => {
  const { t } = useTranslation();
  const { channelId, eventId } = useParams();
  const [event, setEvent] = useState(null);
  const { request } = useActions();
  const dispatch = useDispatch();
  const layoutContext = useContext(LayoutContext);

  const getEvent = () => {
    request({
      action: Modules.channelsEvents.actions.show,
      data: {
        channelId,
        id: eventId,
      },
      options: {
        onSuccess: (data) => {
          setEvent(data);
        },
        onError: (error) => {
          dispatch(notifications.error(error));
        },
      },
    });
  };

  useEffect(() => {
    if (channelId) {
      dispatch(channelsActions.getChannelStart(channelId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelId]);

  useEffect(() => {
    if (eventId) {
      getEvent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId]);

  useEffect(() => {
    layoutContext.setTitleBar(t('title.channelsEvents').toUpperCase());
    layoutContext.setActiveMenu('');
  }, [layoutContext, t]);

  return (
    <Texture
      fullHeight
      heightBanner={layoutContext.heightBanner}
      heightHeader={layoutContext.heightHeader}
    >
      <Container maxWidth="xl">
        <Box pb={20} pt={5}>
          <ChannelsEventsShowComponent event={event} getEvent={getEvent} />
        </Box>
      </Container>
    </Texture>
  );
};

export default ChannelsEventsShow;
