import { all, call, put } from 'redux-saga/effects';
import { getErrorMessage } from 'core/api/api-error';
import { push } from 'connected-react-router';
import notifications from 'modules/notifications';
import { appActions } from 'modules/app';
import * as api from './api';
import { channelsActions as actions } from './index';

export function* getChannels({ data }) {
  try {
    const response = yield call(api.channels, { userId: data ? data.userId : null });
    const channels = response.data;
    yield put(actions.getChannelsSuccess(channels));
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.getChannelsError(getErrorMessage(error))),
    ]);
  }
}

export function* getChannel({ id, view = null }) {
  try {
    const response = yield call(api.channel, { id, view });
    const channel = response.data;
    yield put(actions.getChannelSuccess(channel));
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.getChannelError(getErrorMessage(error))),
    ]);
  }
}

export function* getChannelCalendarWorkouts({ id, params }) {
  try {
    const response = yield call(api.channelCalendarWorkouts, { id, params });
    const channel = response.data;
    yield put(actions.getChannelCalendarWorkoutsSuccess(channel));
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.getChannelCalendarWorkoutsError(getErrorMessage(error))),
    ]);
  }
}

export function* connectStripeAccount({ channelId, code, callback }) {
  try {
    const response = yield call(api.connectAccount, channelId, code);
    yield put(actions.connectStripeAccountSuccess());
    if (callback) {
      callback(response.data);
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.connectStripeAccountError(getErrorMessage(error))),
    ]);
  }
}

export function* removeStripeAccount({ channelId, callback }) {
  try {
    const response = yield call(api.removeAccount, channelId);
    yield put(actions.removeStripeAccountSuccess());
    if (callback) {
      callback(response.data);
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.removeStripeAccountError(getErrorMessage(error))),
    ]);
  }
}

export function* postChannel({ data }) {
  try {
    const response = yield call(api.postChannel, data.data);

    yield all([
      put(actions.postChannelSuccess(response.data)),
      put(push(`/channel/manage/${response.data.id}/plans`)),
      put(appActions.setLoading(false)),
      put(notifications.success(data.messageSuccess)),
    ]);
    if (data.callback) {
      data.callback();
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(appActions.setLoading(false)),
      put(actions.postChannelError(getErrorMessage(error))),
    ]);
  }
}

export function* putChannel({ data }) {
  try {
    const response = yield call(api.putChannel, { id: data.id, params: data.data });
    yield all([put(actions.putChannelSuccess(response.data)), put(appActions.setLoading(false))]);

    yield all([data.messageSuccess ? put(notifications.success(data.messageSuccess)) : null]);
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(appActions.setLoading(false)),
      put(actions.putChannelError(getErrorMessage(error))),
    ]);
  }
}

export function* cancelChannel({ data }) {
  try {
    yield call(api.cancelChannel, data.channelId);
    yield all([
      put(actions.cancelChannelSuccess()),
      put(push(`/dashboard`)),
      data.messageSuccess ? put(notifications.success(data.messageSuccess)) : null,
    ]);
    if (data.callback) {
      data.callback();
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(appActions.setLoading(false)),
      put(actions.cancelChannelError(getErrorMessage(error))),
    ]);
  }
}

export function* assignKeysToUser({ data }) {
  try {
    yield call(api.assignKeysToUser, { data });

    yield all([put(actions.assignKeysToUserSuccess())]);
    if (data.callback) {
      data.callback();
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.assignKeysToUserError(getErrorMessage(error))),
    ]);
  }
}

export function* removeUserFromKeys({ data }) {
  try {
    yield call(api.removeUserFromKeys, { data });

    yield all([put(actions.removeUserFromKeysSuccess())]);
    if (data.callback) {
      data.callback();
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.removeUserFromKeysError(getErrorMessage(error))),
    ]);
  }
}

export function* toggleKeysToRenew({ data }) {
  try {
    yield call(api.toggleKeysToRenew, { data });
    yield all([put(actions.toggleKeysToRenewSuccess())]);
    if (data.callback) {
      data.callback();
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.toggleKeysToRenew(getErrorMessage(error))),
    ]);
  }
}

export function* activeKey({ data }) {
  try {
    yield call(api.activeKeyUser, { key: data.key });
    yield all([put(actions.activeKeySuccess())]);
    if (data.callback) {
      data.callback();
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.activeKeyError(getErrorMessage(error))),
    ]);
  }
}

export function* validateCardOwnerChannel({ data }) {
  try {
    const response = yield call(api.validateCardOwnerChannel, data.channelId);
    yield all([put(actions.validateCardOwnerChannelSuccess())]);
    if (data.callback) {
      data.callback(response.data.data);
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.validateCardOwnerChannelError(getErrorMessage(error))),
    ]);
  }
}

export function* applyMembershipDiscountCode({ data }) {
  try {
    const response = yield call(api.applyMembershipDiscountCode, data);
    yield all([put(actions.applyMembershipDiscountCodeSuccess())]);
    if (data.callback) {
      data.callback(response.data);
    }
  } catch (error) {
    if (data.callback) {
      data.callback(false);
    }
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.applyMembershipDiscountCodeError(getErrorMessage(error))),
    ]);
  }
}
