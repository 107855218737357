import { createSelector } from 'reselect';

const getState = (state) => state.messageLists;

export const getLoading = createSelector(getState, (state) => !!state.loading);
export const getMessageLists = createSelector(getState, (state) => state.data);

export const getMessageListContacts = createSelector(getState, (state) => state.contacts);
export const getMessageListContactsPagination = createSelector(
  getState,
  (state) => state.paginationContacts,
);
export const getLoadingContacts = createSelector(getState, (state) => state.loadingContacts);
