import React from 'react';
import Box from 'core/ui/Box';
import Typography from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import { PlayArrow, RotateLeft } from '@material-ui/icons';
import LoaderSm from 'core/ui/LoaderSm';

const TimerActions = ({
  workout,
  colorLabel,
  timeValue,
  start,
  reset,
  isCoach,
  timerStarted,
  requireVerification,
  recordingActive,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {workout.isCompetition ? (
        <>
          {!workout.competitionAccessed && workout.isCompetition && (
            <>
              {!timeValue.active && timeValue.ended !== true && (
                <Box mt={2} className="hover buttonStartTimerComp" onClick={start}>
                  <Box
                    style={{ color: colorLabel }}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    {timerStarted ? <LoaderSm loading width={25} /> : <PlayArrow />}
                    <Typography>
                      {requireVerification && !recordingActive
                        ? t('button.startRecording')
                        : t('button.startTimer')}
                    </Typography>
                  </Box>
                </Box>
              )}
            </>
          )}
        </>
      ) : (
        <>
          {isCoach && (
            <Box display="flex" mt={2} justifyContent="center">
              {!timeValue.active && timeValue.ended !== true && (
                <Box className="hover buttonStartTimer" onClick={start}>
                  <Box
                    style={{ color: colorLabel }}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    {timerStarted ? <LoaderSm loading width={25} /> : <PlayArrow />}
                    <Typography>{t('start')}</Typography>
                  </Box>
                </Box>
              )}

              <Box
                className={`hover ${
                  !timeValue.active && timeValue.ended !== true
                    ? 'buttonResetTimer'
                    : 'buttonResetTimerActive'
                }`}
                onClick={reset}
              >
                <Box
                  style={{ color: colorLabel }}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <RotateLeft />
                  <Typography>{t('reset')}</Typography>
                </Box>
              </Box>
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default TimerActions;

TimerActions.defaultProps = {};
