import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import SelImages from 'components/Competition/Form/SelImages';
import Typography from 'core/ui/Typography';
import { ButtonPrimarySm } from 'core/ui/Button';

const PictureDay = ({ pictureDay, setPictureDay }) => {
  const { t } = useTranslation();

  const confirm = (img) => {
    setPictureDay(() => ({
      ...img,
      new: true,
      edit: false,
    }));
  };

  return (
    <Box>
      <Box display="flex" justifyContent="center" mb={1}>
        <Box flexGrow={1}>
          <Typography pb={3} variant="h5" color="primary">
            {t('workout.label.PictureDay')}
          </Typography>
        </Box>
        {(pictureDay?.new || (pictureDay?.id && !pictureDay?._destroy)) && (
          <ButtonPrimarySm
            onClick={() => setPictureDay((prev) => (prev?.new ? {} : { ...prev, _destroy: true }))}
          >
            {t('button.remove')}
          </ButtonPrimarySm>
        )}
      </Box>
      <Box>
        {!pictureDay?._destroy && !!pictureDay?.url && (
          <img
            className="boxImg"
            draggable="false"
            src={pictureDay?.url}
            style={{ width: '100%', maxHeight: 300 }}
            alt=""
          />
        )}
      </Box>
      <Box display="flex" justifyContent="center">
        <SelImages setImage={confirm} />
      </Box>
    </Box>
  );
};

export default PictureDay;
