/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import {
  BarChart,
  Bar,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from 'recharts';
import Typography from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import useSelectors from 'modules/map/useSelectors';
import { channelReportsModule } from 'modules/channelReports';
import Paper from 'core/ui/Paper';
import LoaderSm from 'core/ui/LoaderSm';
import { primary } from 'core/ui/Colors';
import { channelCurrency } from 'core/utils/formatters';

const BarMonthsLtv = ({ channel }) => {
  const { t } = useTranslation();
  const chart = useSelectors(channelReportsModule, 'chart');
  const loadingChart = useSelectors(channelReportsModule, 'loadingChart');
  const { request } = useActions();

  const getReportChart = (params = {}) => {
    request({
      action: Modules.channelReports.actions.membersGrowthLtvChart,
      data: {
        channelId: channel?.id,
        ...params,
      },
    });
  };

  useEffect(() => {
    if (channel) {
      getReportChart();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel]);

  return (
    <Paper p={3}>
      <Typography variant="h5" mb={3} align="center">
        {t('label.last6Months')} <LoaderSm loading={loadingChart} />
      </Typography>

      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={chart}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" style={{ fontSize: 10 }} />

          <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />

          <YAxis
            tickFormatter={(value) => channelCurrency(value, channel?.currency)}
            yAxisId="left"
            orientation="left"
            stroke="#8884d8"
          />

          <Tooltip formatter={(value) => channelCurrency(value, channel?.currency)} />

          <Legend />

          <Bar yAxisId="left" dataKey="ltv" fill={primary} />
        </BarChart>
      </ResponsiveContainer>
    </Paper>
  );
};

export default BarMonthsLtv;
