/* eslint-disable react/prop-types */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from 'core/ui/Typography';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import Grid from 'core/ui/Grid';
import Paper from 'core/ui/Paper';
import FontIcon from 'core/ui/FontIcon';
import Skeleton from '@material-ui/lab/Skeleton';
import { ButtonPrimary } from 'core/ui/Button';
import { useHistory } from 'react-router';
import CollapseText from 'core/ui/CollapseText';
import ClassInfoDetail from './ClassInfoDetail';

const useStyles = makeStyles({
  iconAlignment: {
    marginLeft: 10,
    marginTop: 8,
  },
});

export default ({ workout, loading, buttonColor }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();

  return (
    <Paper elevation={2} className="paper-rounded" p={3}>
      <Box display="flex" flexGrow="1">
        <Typography color="secondary" variant="h4">
          {workout.name}
        </Typography>
        <FontIcon iconName={`icon-live ${classes.iconAlignment}`} size={12} color={buttonColor} />
      </Box>

      <Grid item xs={12} sm={12} md={12} lg={12} mt={2}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={12} md={6}>
            {!loading ? (
              <>
                <Typography color="secondary" variant="subtitle2" component="h1">
                  {t('description')}
                </Typography>
                <Typography
                  className="roboto-regular"
                  color="secondary"
                  variant="caption"
                  component="div"
                  align="justify"
                >
                  <CollapseText text={workout.description} maxChar={120} />
                </Typography>
              </>
            ) : (
              <Skeleton
                variant="rect"
                animation="wave"
                style={{ minWidth: 100 }}
                height={40}
                width="90%"
              />
            )}
          </Grid>
        </Grid>
      </Grid>

      <>{!workout.isCompetition && <ClassInfoDetail workout={workout} />}</>

      {workout.isCompetition && workout.recordingVideos && workout.recordingVideos.length > 0 && (
        <Paper elevation={2} mt={2} className="paper-rounded" p={3}>
          <Box display="flex" justifyContent="center">
            <ButtonPrimary
              onClick={() =>
                history.push(`/competition/${workout.competitionId}/view/workout/${workout.id}`)
              }
            >
              <FontIcon iconName="icon-video-box" color="white" size={24} />
              &nbsp;{t('button.competitionVideos')}
            </ButtonPrimary>
          </Box>
        </Paper>
      )}
    </Paper>
  );
};
