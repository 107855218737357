import React, { useState } from 'react';
import iconSpotify from 'assets/img/icon-spotify-logo-cinza.png';
import { ButtonGreen, ButtonPrimarySm } from 'core/ui/Button';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import Typography, { fontStyle } from 'core/ui/Typography';
import {
  TableContainer,
  TableHead,
  Table,
  TableCell,
  TableRow,
  TableBody,
  Paper,
} from '@material-ui/core';

import { useSelector, useDispatch } from 'react-redux';
import { usersActions } from 'modules/users';
import { authEndpoint, clientId, redirectUri, scopes } from 'core/spotify/config';
import { getAccessToken } from 'core/spotify/requests';
import notifications from 'modules/notifications';
import ConfirmDialog from 'core/ui/ConfirmDialog';
import { sessionSelectors } from 'modules/session';

const MusicAccounts = () => {
  const { t } = useTranslation();
  const user = useSelector((state) => sessionSelectors.getUser(state));
  const dispatch = useDispatch();
  const [openConfirm, setOpenConfirm] = useState(false);
  const openPopUp = () => {
    const popup = window.open(
      `${authEndpoint}?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scopes.join(
        '%20',
      )}&response_type=code&show_dialog=true`,
      'Login with Spotify',
      'width=800,height=600',
    );

    window.spotifyCallback = async (payload) => {
      popup.close();
      const resp = await getAccessToken(payload);
      if (resp.access_token) {
        fetch('https://api.spotify.com/v1/me', {
          headers: {
            Authorization: `Bearer ${resp.access_token}`,
          },
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.product !== 'premium') {
              dispatch(notifications.warning(t('profile.edit.validate.spotifyPremium')));
              return;
            }
            dispatch(
              usersActions.postSpotifyAccountStart({
                accessToken: resp.access_token,
                tokenType: resp.token_type,
                expiry: resp.expires_in,
                refreshToken: resp.refresh_token,
                redirectUri,
                name: data.display_name,
              }),
            );
          });
      }
    };
  };

  const removeSpAccount = (id) => {
    dispatch(usersActions.delSpotifyAccountStart(id));
  };

  return (
    <Box>
      <ConfirmDialog
        open={openConfirm}
        setOpen={setOpenConfirm}
        description={t('profile.musicAccounts.confirm.spotify.alert')}
        labelConfirm={t('connect')}
        labelCancel={t('cancel')}
        confirm={openPopUp}
      />
      <Box display="flex">
        <Box flexGrow="1" mb={3} alignItems="center">
          <Typography color="primary" component="span" variant="h5">
            {t('profile.musicAccounts').toUpperCase()}
          </Typography>
        </Box>

        <ButtonGreen onClick={() => setOpenConfirm(true)} p={3}>
          <img src={iconSpotify} style={{ width: 25 }} alt="Spotify" />
          <Typography ml={1} style={{ color: 'white' }}>
            {t('profile.button.spotify')}
          </Typography>
        </ButtonGreen>
      </Box>
      <Typography ml={2} component="h6" variant="caption" style={fontStyle.medium}>
        {t('profile.spotify.alert')}
      </Typography>
      <Box mt={2} px={2}>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Account</TableCell>
                <TableCell>Type</TableCell>
                <TableCell align="right" />
              </TableRow>
            </TableHead>
            <TableBody>
              {user.spotifyAccounts &&
                user.spotifyAccounts.map((row) => (
                  <TableRow key={row.name}>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      Spotify
                    </TableCell>
                    <TableCell align="right">
                      <ButtonPrimarySm onClick={() => removeSpAccount(row.id)}>
                        {t('remove')}
                      </ButtonPrimarySm>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default MusicAccounts;
