/* eslint-disable no-use-before-define */
import useGA from 'core/utils/hook/useGA';
import useWindowSize from 'core/utils/hook/useWindowSize';
import { persistData, removeData, retrieveData } from 'core/utils/session-storage';
import { bannersActions, bannersSelectors } from 'modules/banners';
import { useEffect, useRef, useState } from 'react';
import { useBeforeunload } from 'react-beforeunload';
import { useDispatch, useSelector } from 'react-redux';
import { usePageVisibility } from 'react-page-visibility';

const HEADER_IMG_SIZE = { width: 3000, height: 500 };

export default ({ page, widthBox = null }) => {
  const allBannersHeaders = useSelector((state) => bannersSelectors.getBannersHeader(state));
  const dispatch = useDispatch();
  const intervalBannerRef = useRef();
  const [current, setCurrent] = useState({});
  const [lastBanner, setLastBanner] = useState({});
  const currentRef = useRef(current);
  const ga = useGA();
  const [heightHeaderWrapper, setHeightHeaderWrapper] = useState(100);
  const size = useWindowSize();
  const isVisible = usePageVisibility();
  const isVisibleRef = useRef(isVisible);

  const pageBanner = page || '';
  const bannersHeader = allBannersHeaders.filter((x) =>
    pageBanner ? x.banner.page === pageBanner : !x.banner.page,
  );

  useEffect(() => {
    currentRef.current = current;
    isVisibleRef.current = isVisible;
  });

  useEffect(() => {
    setLastBanner({});
    setCurrent({});
  }, [pageBanner]);

  useEffect(() => {
    const height = (HEADER_IMG_SIZE.height / HEADER_IMG_SIZE.width) * (widthBox || size.width);
    setHeightHeaderWrapper(height);
  }, [size.width, widthBox]); //eslint-disable-line

  useEffect(() => {
    dispatch(bannersActions.getBannersHeaderStart());
  }, [dispatch]);

  useEffect(() => {
    const currentLocalStorage = retrieveData(`CURRENT_BANNER_HEADER-${pageBanner}`);
    if (currentLocalStorage) {
      const currentBanner = JSON.parse(currentLocalStorage);
      setCurrent({
        ...currentBanner,
        timeStoped: currentBanner.exit - currentBanner.start,
      });
    }
  }, [pageBanner]); //eslint-disable-line

  const startInterval = () => {
    clearInterval(intervalBannerRef.current);
    intervalBannerRef.current = setInterval(() => {
      checkBanner();
    }, 100);
  };

  const checkBanner = () => {
    try {
      if (bannersHeader.length <= 0) {
        return;
      }

      if (!currentRef.current.start) {
        setCurrent({
          start: Date.now(),
          index: 0,
          timeStoped: 0,
          ...bannersHeader[0],
        });
        addEventToGA(0);
      } else {
        let time = 0;
        if (currentRef.current.timeStoped > 0) {
          time = currentRef.current.timeStoped;
          setCurrent((prev) => ({
            ...prev,
            timeStoped: 0,
            start: Date.now() - time,
          }));
        } else {
          time = Date.now() - currentRef.current.start;
        }

        if (time / 1000 > Number(currentRef.current.banner.durationSeconds)) {
          let newIndex = currentRef.current.index + 1;

          if (bannersHeader.length <= newIndex) {
            newIndex = 0;
          }
          setLastBanner(currentRef.current);
          addEventToGA(newIndex);
          setCurrent({
            start: Date.now(),
            index: newIndex,
            timeStoped: 0,
            ...bannersHeader[newIndex],
          });
        }
      }
    } catch {
      removeData(`CURRENT_BANNER_HEADER-${pageBanner}`);
    }
  };

  const addEventToGA = (index) => {
    if (!isVisibleRef.current) {
      return;
    }
    ga.addEvent({
      category: 'Banner Header',
      action: 'Displayed Banner Header',
      label: `${bannersHeader[index].banner.company} - ${bannersHeader[index].order} - ${bannersHeader[index].alt}`,
      nonInteraction: true,
    });
  };

  useEffect(() => {
    if (bannersHeader.length > 0) {
      startInterval();
    }

    return () => {
      clearInterval(intervalBannerRef.current);
    };
  }, [bannersHeader]); //eslint-disable-line

  useBeforeunload(() =>
    currentRef.current.start
      ? persistData(
          `CURRENT_BANNER_HEADER-${pageBanner}`,
          JSON.stringify({ exit: Date.now(), ...currentRef.current }),
        )
      : null,
  );

  useEffect(
    () => () => {
      if (currentRef.current.start) {
        persistData(
          `CURRENT_BANNER_HEADER-${pageBanner}`,
          JSON.stringify({ exit: Date.now(), ...currentRef.current }),
        );
      }
    }, [pageBanner]); //eslint-disable-line

  return {
    bannersHeader,
    current,
    heightHeaderWrapper,
    lastBanner,
  };
};
