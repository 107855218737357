import api from 'core/api';
import { call, put } from 'redux-saga/effects';

export const channelsCoachesClocksModule = 'channelsCoachesClocks';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const actions = {
  postChannelsCoachesClocks: {
    module: channelsCoachesClocksModule,
    name: 'postChannelsCoachesClocks',
    api: (data) => api.post(`/channels/${data?.channelId}/channels_coaches_clocks`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  putChannelsCoachesClocks: {
    module: channelsCoachesClocksModule,
    name: 'putChannelsCoachesClocks',
    api: (data) =>
      api.put(`/channels/${data?.channelId}/channels_coaches_clocks/${data?.id}`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  destroy: {
    module: channelsCoachesClocksModule,
    name: 'destroy',
    api: (data) => api.delete(`/channels/${data?.channelId}/channels_coaches_clocks/${data?.id}`),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  getChannelsCoachesClocks: {
    module: channelsCoachesClocksModule,
    name: 'getChannelsCoachesClocks',
    api: (params) => api.get(`/channels/${params?.channelId}/channels_coaches_clocks`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: ['data', 'pagination'],
    },
    *sagas(Creators, { params, options }) {
      try {
        const resp = yield call(actions.getChannelsCoachesClocks.api, params);
        yield put(Creators.getChannelsCoachesClocksSuccess(resp.data, resp.pagination));
        if (options.onSuccess) {
          options.onSuccess(resp.data, resp.pagination);
        }
      } catch (error) {
        if (options.onError) {
          options.onError(error);
        }
        yield put(Creators.getChannelsCoachesClocksError());
      }
    },
    state: loadingStates,
  },
};

export default {
  actions,
  state: {
    data: [],
    pagination: null,
    error: null,
    loading: false,
  },
};
