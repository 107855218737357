import { all, call, put } from 'redux-saga/effects';

import { getErrorMessage } from 'core/api/api-error';

import notifications from 'modules/notifications';

import * as api from './api';
import { ratingsActions as actions } from './index';

export function* getRatings({ klass, klassId, pagination }) {
  try {
    const response = yield call(api.getRatings, { klass, klassId, pagination });
    const ratings = response.data;
    yield put(actions.getRatingsSuccess(ratings, response.pagination));
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.getRatingsError(getErrorMessage(error))),
    ]);
  }
}

export function* postRating({ data }) {
  try {
    const response = yield call(api.postRating, data);
    const tags = response.data;
    yield put(actions.postRatingSuccess(tags));
    if (data.messageSuccess) {
      yield put(notifications.success(data.messageSuccess));
    }
    if (data.callback) {
      data.callback();
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.postRatingError(getErrorMessage(error))),
    ]);
  }
}
