import React from 'react';
import ReactCountryFlag from 'react-country-flag';

function FlagCountry({ flag, size }) {
  return (
    <ReactCountryFlag
      className="emojiFlag"
      countryCode={flag}
      style={{
        fontSize: size,
      }}
      aria-label="United States"
      svg
    />
  );
}

FlagCountry.defaultProps = {
  size: '3em',
};

export default FlagCountry;
