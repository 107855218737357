import { useState } from 'react';
import useActions from 'modules/map/useActions';
import Modules from 'modules';

import useFavorite from 'components/Favorite/useFavorite';
import { useDispatch } from 'react-redux';
import { workoutsActions } from 'modules/workouts';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

const useCustomActions = ({ callbackForm, workout }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [modalForm, setModalForm] = useState({ open: false });
  const [modalScore, setModalScore] = useState({ open: false });
  const [modalInvite, setModalInvite] = useState({ open: false });
  const [modalCalculator, setModalCalculator] = useState(false);
  const [modalShare, setModalShare] = useState(false);
  const { request } = useActions();
  const dispatch = useDispatch();

  const openForm = (workoutId = null) => {
    if (workoutId) {
      setModalForm({ open: true, workoutId });
    } else {
      setModalForm({ open: true });
    }
  };

  const invite = (w) => {
    setModalInvite({ open: true, workout: w });
  };

  const removeScore = (scoreId, sectionId) => {
    request({
      action: Modules.newWorkouts.actions.deleteUserScore,
      data: { workoutId: workout?.id, workoutSectionUserId: scoreId, sectionId },
      options: {
        onSuccess: () => {
          callbackForm({ id: workout?.id });
        },
      },
    });
  };

  const { addFavorite, removeFavorite } = useFavorite();

  const toggleFavorite = async (w) => {
    if (w.favorited) {
      await removeFavorite('Workout', w.id);
    } else {
      await addFavorite('Workout', w.id);
    }
    callbackForm(w);
  };

  const register = (w) => {
    dispatch(
      workoutsActions.purchaseStart(
        {
          workoutId: w.id,
          messageSuccess: t('purchase.workout.success'),
        },
        () => {
          callbackForm({ id: workout?.id });
        },
      ),
    );
  };

  return {
    openForm,
    toggleFavorite,
    removeScore,
    setModalCalculator,
    modalCalculator,
    modalScore,
    setModalScore,
    modalForm,
    setModalForm,
    register,
    calculator: () => setModalCalculator(true),
    modalShare,
    setModalShare,
    share: () => setModalShare(true),
    invite,
    modalInvite,
    setModalInvite,
    viewWorkout: (w) => history.push(`/workout/view/${w?.id}`),
  };
};

export default useCustomActions;
