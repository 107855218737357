import Reports from 'pages/Admin/Reports';
import TextService from 'pages/Admin/TextService';
import Benchmarks from 'pages/Admin/Benchmarks';
import PersonalRecords from 'pages/Admin/PersonalRecords';
import AdminPlans from 'pages/Admin/Plans/index';
import AdminImport from 'pages/Admin/Import/Stripe';
import ChannelsOverview from 'pages/Admin/ChannelsOverview';

export default [
  {
    path: '/admin/reports',
    exact: true,
    name: 'adminReports',
    component: Reports,
  },
  {
    path: '/admin/channels',
    exact: true,
    name: 'adminReports',
    component: ChannelsOverview,
  },
  {
    path: '/admin/import/tool',
    exact: true,
    name: 'adminImport',
    component: AdminImport,
  },
  {
    path: '/admin/textService',
    exact: true,
    name: 'adminTextService',
    component: TextService,
  },
  {
    path: '/admin/benchmarks',
    exact: true,
    name: 'adminBenchmarks',
    component: Benchmarks,
  },
  {
    path: '/admin/personalRecords',
    exact: true,
    name: 'adminPersonalRecords',
    component: PersonalRecords,
  },
  {
    path: '/admin/plans',
    exact: true,
    name: 'adminPersonalRecords',
    component: AdminPlans,
  },
];
