import useConfirmation from 'core/useConfirmation';
import Modules from 'modules';
import { ownerableMessagesModule } from 'modules/ownerableMessages';
import useActions from 'modules/map/useActions';
import useSelectors from 'modules/map/useSelectors';
import { sessionSelectors } from 'modules/session';
import { usersMessagesModule } from 'modules/usersMessages';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usersActions } from 'modules/users';
import notifications from 'modules/notifications';
import { useTranslation } from 'react-i18next';

export default (load = false) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { request } = useActions();
  const [modalView, setModalView] = useState({ open: false });
  const data = useSelectors(usersMessagesModule, 'data');
  const pagination = useSelectors(usersMessagesModule, 'pagination');
  const unreadCount = useSelectors(usersMessagesModule, 'unreadCount');
  const loading = useSelectors(usersMessagesModule, 'loading');
  const userSession = useSelector((state) => sessionSelectors.getUser(state));
  const { confirmation } = useConfirmation();
  const { messagesTypes } = useSelectors(ownerableMessagesModule);

  const [localSettings, setLocalSettings] = useState({});

  useEffect(() => {
    const newData = {};
    messagesTypes.forEach((item) => {
      newData[item.id] = !!item?.userData?.block;
    });
    setLocalSettings(newData);
  }, [messagesTypes]);

  const getMessageTypes = () => {
    request({
      action: Modules.ownerableMessages.actions.getMessagesTypes,
      data: {
        channelId: 2,
      },
    });
  };

  useEffect(() => {
    if (userSession && load) {
      getMessageTypes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSession, load]);

  const getMessages = (page = 1) => {
    request({
      action: Modules.usersMessages.actions.getMessages,
      data: {
        page,
        perPage: 10,
      },
    });
  };

  const getUnreadCount = () => {
    request({
      action: Modules.usersMessages.actions.getMessageUnredCount,
    });
  };

  const markAllAsRead = () => {
    request({
      action: Modules.usersMessages.actions.markAllAsRead,
      options: {
        onSuccess: () => {
          getMessages();
          getUnreadCount();
        },
      },
    });
  };

  const deleteMessage = (id) => {
    confirmation({
      description: 'Are you sure?',
      yesClick: () => {
        request({
          action: Modules.usersMessages.actions.destroy,
          data: {
            id,
          },
          options: {
            onSuccess: () => {
              getMessages();
              getUnreadCount();
            },
          },
        });
      },
    });
  };

  const viewMessage = (message) => {
    request({
      action: Modules.usersMessages.actions.update,
      data: {
        id: message?.id,
        visualized: true,
      },
      options: {
        onSuccess: () => {
          getUnreadCount();
          request({
            action: Modules.usersMessages.actions.setMessages,
            data: data?.map((m) => {
              if (m === message) {
                return { ...m, visualized: true };
              }
              return m;
            }),
          });
        },
      },
    });

    setModalView({ open: true, message });
  };

  useEffect(() => {
    if (userSession.id && load) {
      getMessages(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, userSession, load]);

  const toggleMessageType = (type, opt) => {
    let changeTypes = [];
    if (type.groupType) {
      changeTypes = messagesTypes
        .filter((x) => x.groupType === type?.groupType)
        .map((item) => ({ id: item?.userData?.id, messagesTypeId: item?.id, block: opt }));
    } else {
      changeTypes = [{ id: type?.userData?.id, messagesTypeId: type?.id, block: opt }];
    }

    dispatch(
      usersActions.putUpdateUserStart(
        {
          id: userSession.id,
          user: {
            usersMessagesTypesAttributes: changeTypes,
          },
        },
        () => {
          getMessageTypes();
          dispatch(notifications.success(t('message.success.save.profile')));
        },
      ),
    );
  };

  const formatMessagesTypes = () => {
    const types = { email: [], push: [], sms: [] };
    messagesTypes
      .filter((x) => x.role !== 'channel')
      .forEach((item) => {
        if (!item.groupType) {
          types[item?.kind].push(item);
        } else if (!types[item?.kind].find((x) => x.groupType === item?.groupType)) {
          types[item?.kind].push(item);
        }
      });
    return types;
  };

  return {
    getMessageTypes,
    deleteMessage,
    viewMessage,
    data,
    pagination,
    modalView,
    setModalView,
    getMessages,
    getUnreadCount,
    unreadCount: unreadCount?.total,
    messagesTypes: formatMessagesTypes(),
    toggleMessageType,
    loading,
    markAllAsRead,
    localSettings,
    setLocalSettings,
  };
};
