import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';

import Typography from 'core/ui/Typography';
import Box from 'core/ui/Box';

import TextFieldRounded from 'core/ui/TextFieldRounded';
import { ButtonPrimary } from 'core/ui/Button';
import { Link, colors } from '@material-ui/core';
import { Context } from 'components/Login/Form';
import { useSelector } from 'react-redux';
import { sessionSelectors } from 'modules/session';
import InputPhone from 'core/ui/InputPhone';
import CaptchaForm from 'core/ui/Recaptcha';

const FormLogin = () => {
  const { t } = useTranslation();
  const formContext = useContext(Context);

  const loading = useSelector((state) => sessionSelectors.getLoading(state));

  return (
    <>
      {formContext?.step === 'newUser' ? (
        <Typography
          variant="h6"
          mt={5}
          mb={5}
          align="center"
          className="roboto-regular"
          style={{ color: colors.grey[600] }}
        >
          {t(`onboarding.new.user.${formContext.input}`)}
        </Typography>
      ) : (
        <Typography
          variant="h6"
          mt={5}
          mb={5}
          align="center"
          className="roboto-regular"
          style={{ color: colors.grey[600] }}
        >
          {t(formContext.input === 'phone' ? 'login.enterMobileNumber' : 'login.enterYourEmail')}
        </Typography>
      )}

      {formContext.step === 'newUser' && (
        <Box mb={2}>
          <TextFieldRounded
            style={{ fontSize: 16, fontFamily: 'Roboto' }}
            name="name"
            onChange={(data) => formContext.setValue('name', data)}
            placeholder={t('placeholder.name')}
            value={formContext.watch('name')}
            error={formContext?.errors?.name}
          />
        </Box>
      )}

      {(formContext.input === 'phone' || formContext.step === 'newUser') && (
        <Box mb={4}>
          <InputPhone
            name="phone"
            setValue={(data) => formContext.setValue('phone', data)}
            placeholder="(55) 555-5555"
            value={formContext?.phone}
            error={formContext?.errors?.phone}
          />
        </Box>
      )}

      {(formContext.input === 'email' || formContext.step === 'newUser') && (
        <Box mb={4}>
          <TextFieldRounded
            style={{ fontSize: 16, fontFamily: 'Roboto' }}
            name="email"
            onChange={(data) => formContext.setValue('email', data)}
            placeholder={t('profile.placeholder.email')}
            value={formContext.watch('email')}
            error={formContext?.errors?.email}
          />
        </Box>
      )}

      {formContext.step !== 'newUser' && (
        <>
          <Typography
            m={3}
            className="roboto-regular"
            color="primary"
            align="center"
            size={6}
            variant="caption"
            component="h6"
          >
            {formContext.errors.phone && formContext.input === 'phone' && t('login.phoneInvalid')}
            {formContext.errors.email && formContext.input === 'email' && t('login.emailInvalid')}
          </Typography>

          <Link
            className="link"
            onClick={() => formContext.setInput(formContext.input === 'phone' ? 'email' : 'phone')}
          >
            <Typography
              className="roboto-regular"
              align="center"
              color="primary"
              component="h6"
              variant="caption"
            >
              <b>
                {t(
                  formContext.input === 'email'
                    ? 'login.optionInputEmail'
                    : 'login.optionInputPhone',
                )}
              </b>
            </Typography>
          </Link>
        </>
      )}

      {formContext.step === 'newUser' && (
        <Box display="flex" alignItems="center" justifyContent="center" my={2}>
          <CaptchaForm captchaRef={formContext?.recaptchaRef} />
        </Box>
      )}

      <Box p={5} mt={5}>
        <ButtonPrimary disabled={loading} type="submit" fullWidth variant="contained">
          {t('login.signin')}
        </ButtonPrimary>
      </Box>
    </>
  );
};

export default FormLogin;
