import api from 'core/api';
import { call, put } from 'redux-saga/effects';

export const benchmarkSectionsModule = 'benchmarkSections';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const loadingUsersStates = {
  start: { loadingUsers: true },
  error: { loadingUsers: false },
  success: { loadingUsers: false },
};

const loadingAddStates = {
  start: { loadingAdd: true },
  error: { loadingAdd: false },
  success: { loadingAdd: false },
};

const loadingHistStates = {
  start: { loadingHistory: true },
  error: { loadingHistory: false },
  success: { loadingHistory: false },
};

const loadingCategories = {
  start: { loadingCategories: true },
  error: { loadingCategories: false },
  success: { loadingCategories: false },
};

const actions = {
  getBenchmarkSections: {
    module: benchmarkSectionsModule,
    name: 'getBenchmarkSections',
    api: (params) =>
      api.get(`/benchmark_sections?is_selector=${params?.isSelector ? 1 : 0}`, { params }),
    *sagas(Creators, { params, options }) {
      try {
        const resp = yield call(actions.getBenchmarkSections.api, params);
        yield put(Creators.getBenchmarkSectionsSuccess(resp.data, resp.pagination));
        if (options.onSuccess) {
          options.onSuccess(resp.data, resp.pagination);
        }
      } catch (error) {
        if (options.onError) {
          options.onError(error);
        }
        yield put(Creators.getBenchmarkSectionsError());
      }
    },
    params: {
      start: ['params'],
      error: [''],
      success: ['data', 'pagination'],
    },
    state: loadingStates,
  },
  postBenchmarkSection: {
    module: benchmarkSectionsModule,
    name: 'postBenchmarkSection',
    api: (data) => api.post(`/benchmark_sections`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  putBenchmarkSection: {
    module: benchmarkSectionsModule,
    name: 'putBenchmarkSection',
    api: (data) => api.put(`/benchmark_sections/${data.id}`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  getBenchmarkSectionsCategories: {
    module: benchmarkSectionsModule,
    name: 'getBenchmarkSectionsCategories',
    api: () => api.get(`/benchmark_sections/categories`),
    params: {
      start: ['params'],
      error: [''],
      success: ['categories'],
    },
    state: loadingCategories,
  },
  getBenchmarkUsers: {
    module: benchmarkSectionsModule,
    name: 'getBenchmarkUsers',
    api: ({ benchmarkSectionId }) => api.get(`/benchmark_sections/${benchmarkSectionId}/users`),
    params: {
      start: ['params'],
      error: [''],
      success: ['users'],
    },
    state: loadingUsersStates,
  },
  deleteBenchmarkSectionUser: {
    module: benchmarkSectionsModule,
    name: 'deleteBenchmarkSectionUser',
    api: ({ benchmarkSectionUserId }) =>
      api.delete(`/benchmark_section_users/${benchmarkSectionUserId}`),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingHistStates,
  },
  postBenchmarkSectionUser: {
    module: benchmarkSectionsModule,
    name: 'postBenchmarkSectionUser',
    api: (data) => api.post('/benchmark_section_users', data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingAddStates,
  },
};

export default {
  actions,
  state: {
    loading: false,
    loadingAdd: false,
    loadingHistory: false,
    loadingusers: false,

    pagination: null,
    data: [],
    users: [],
    categories: [],
    loadingCategories: false,
  },
};
