import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { useSelector } from 'react-redux';
import { sessionSelectors } from 'modules/session';
import QuickForm from 'components/Profile/QuickForm';
import useChannelStyles from 'core/useChannelStyles';
import Texture from 'core/ui/Texture';
import Box from 'core/ui/Box';
import FormLogin from 'components/Login/Form';
import DialogTitle from 'core/ui/Dialog/DialogTitle';
import Typography from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import useIsXs from 'core/utils/hook/useIsXs';
import Form from './Form';

const ModalRegistration = ({ open, event, close, callback, callbackError, channel }) => {
  const currentUser = useSelector((state) => sessionSelectors.getUser(state));
  const isAuthenticated = useSelector((state) => sessionSelectors.getIsAuthenticated(state));
  const { buttonColor } = useChannelStyles();

  const isXs = useIsXs();

  const { t } = useTranslation();

  if (!open) {
    return <div />;
  }

  if (!isAuthenticated) {
    return (
      <Dialog
        open={!!open}
        maxWidth="sm"
        fullWidth
        scroll={isXs ? 'paper' : 'body'}
        fullScreen={isXs}
        onClose={() => close()}
      >
        <Texture>
          <Box p={2}>
            <FormLogin fromModal channelId={channel?.id} />
          </Box>
        </Texture>
      </Dialog>
    );
  }

  if (isAuthenticated && !currentUser?.name) {
    return (
      <Dialog open maxWidth="sm" fullWidth scroll={isXs ? 'paper' : 'body'} fullScreen={isXs}>
        <Texture>
          <Box px={4}>
            <QuickForm buttonColor={buttonColor} fastForm />
          </Box>
        </Texture>
      </Dialog>
    );
  }

  return (
    <Dialog
      open={!!open}
      onClose={close}
      maxWidth="sm"
      fullWidth
      scroll={isXs ? 'paper' : 'body'}
      fullScreen={isXs}
    >
      <DialogTitle onClose={close}>
        <Typography className="tw-font-semibold">
          {t('event.registration', { eventName: event?.name })}
        </Typography>
      </DialogTitle>
      <Form callback={callback} callbackError={callbackError} event={event} channel={channel} />
    </Dialog>
  );
};

ModalRegistration.defaultProps = {
  close: () => {},
  userId: null,
};

export default ModalRegistration;
