import { call, put } from 'redux-saga/effects';

import { getErrorMessage } from 'core/api/api-error';

import notifications from 'modules/notifications';

import * as api from './api';
import { sessionWorkoutActions as actions } from './index';

export function* create({ workoutId, workoutsInviteId }) {
  try {
    yield call(api.create, workoutId, workoutsInviteId);
    yield put(actions.createSuccess());
    yield put(actions.getTokenStart(workoutId, workoutsInviteId));
  } catch (error) {
    yield put(notifications.warning(getErrorMessage(error)));
    yield put(actions.createError(getErrorMessage(error)));
  }
}

export function* postMessage({ data }) {
  try {
    const response = yield call(api.addMessage, {
      workoutId: data.workoutId,
      message: data.message,
    });

    if (data.callback) {
      data.callback(response.data.audio.url);
    }
    yield put(actions.postChatMessageSuccess(response.data));
  } catch (error) {
    yield put(actions.postChatMessageError(getErrorMessage(error)));
  }
}

export function* postEvent({ data }) {
  try {
    const response = yield call(api.addEvent, data);
    yield put(actions.postEventSuccess(response.data));
  } catch (error) {
    yield put(actions.postEventError(getErrorMessage(error)));
  }
}

export function* getEvents({ workoutId }) {
  try {
    const response = yield call(api.events, workoutId);
    yield put(actions.getEventsSuccess(response.data));
  } catch (error) {
    yield put(actions.getEventsError(getErrorMessage(error)));
  }
}

export function* startRecording({ data }) {
  try {
    const response = yield call(api.startRecording, data.workoutId, data.workoutsInviteId);
    yield put(actions.startRecordingSuccess(response.data));
    if (data.callback) {
      data.callback(response.data);
    }
  } catch (error) {
    console.log('error', error);
    if (data.callback) {
      data.callback({});
    }
    yield put(actions.startRecordingError(getErrorMessage(error)));
  }
}

export function* getRecordingStatus({ data }) {
  try {
    const response = yield call(api.recordingStatus, data.workoutId, data.workoutsInviteId);
    yield put(actions.getRecordingStatusSuccess(response.data));
    if (data.callback) {
      data.callback(response.data);
    }
  } catch (error) {
    yield put(actions.getRecordingStatusError(getErrorMessage(error)));
  }
}

export function* stopRecording({ data }) {
  try {
    const response = yield call(api.stopRecording, data.workoutId, data.workoutsInviteId);
    yield put(actions.stopRecordingSuccess(response.data));
    if (data.callback) {
      data.callback(response.data);
    }
  } catch (error) {
    yield put(actions.stopRecordingError(getErrorMessage(error)));
  }
}

export function* postAccess({ data }) {
  try {
    const response = yield call(api.access, data);
    yield put(actions.postAccessSuccess(response.data));
  } catch (error) {
    yield put(actions.postAccessError(getErrorMessage(error)));
  }
}

export function* postAccessDisconnect({ data }) {
  try {
    const response = yield call(api.accessDisconnect, data);
    yield put(actions.postAccessDisconnectSuccess(response.data));
  } catch (error) {
    yield put(actions.postAccessDisconnectError(getErrorMessage(error)));
  }
}

export function* startWorkoutCompetition({ data }) {
  try {
    yield call(api.startWorkoutCompetition, data);
    yield put(actions.startWorkoutCompetitionSuccess());
    if (data.callback) {
      data.callback();
    }
  } catch (error) {
    yield put(actions.startWorkoutCompetitionError(getErrorMessage(error)));
  }
}

export function* resetWorkoutCompetition({ data }) {
  try {
    yield call(api.resetWorkoutCompetition, data);
    yield put(actions.resetWorkoutCompetitionSuccess());
    if (data.callback) {
      data.callback();
    }
  } catch (error) {
    yield put(actions.resetWorkoutCompetitionError(getErrorMessage(error)));
  }
}

export function* getToken({ workoutId, workoutsInviteId }) {
  try {
    const response = yield call(api.token, workoutId, workoutsInviteId);
    const token = response.data;
    yield put(actions.getTokenSuccess(token));
  } catch (error) {
    yield put(actions.getTokenError(getErrorMessage(error)));
  }
}

export function* processLocalRecording({ data }) {
  try {
    yield call(api.processLocalRecording, data);
    yield put(actions.processLocalRecordingSuccess());
    if (data.callback) {
      data.callback();
    }
  } catch (error) {
    yield put(actions.processLocalRecordingError(getErrorMessage(error)));
  }
}
