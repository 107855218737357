import api from 'core/api';

export const documentsModule = 'documents';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const actions = {
  create: {
    module: documentsModule,
    name: 'create',
    api: (params) => api.post(`/documents`, params),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  update: {
    module: documentsModule,
    name: 'update',
    api: (params) => api.put(`/documents/${params?.id}`, params),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  getDocuments: {
    module: documentsModule,
    name: 'getDocuments',
    api: (params) => api.get(`/documents`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
    isTakeEvery: true,
  },
  getDocument: {
    module: documentsModule,
    name: 'getDocument',
    api: (params) => api.get(`/documents/${params?.id}`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: ['document'],
    },
    state: loadingStates,
  },
  destroy: {
    module: documentsModule,
    name: 'destroy',
    api: (params) => api.delete(`/documents/${params.id}`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
};

export default {
  actions,
  state: {
    loading: false,
    document: null,
  },
};
