import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import { Dialog } from '@material-ui/core';
import Typography from 'core/ui/Typography';
import { ButtonPrimary } from 'core/ui/Button';
import Texture from 'core/ui/Texture';
import SelectBox from 'core/ui/SelectBox';
import { getAllTimezones, timeZoneName } from 'core/utils/formatters/date';
import DatePickerRounded from 'core/ui/DatePickers/DatePickerRounded';
import TimePickerRounded from 'core/ui/DatePickers/TimePickerRounded';
import { channelsSelectors } from 'modules/channels';
import { useSelector } from 'react-redux';
import CKEditor, { defaultCkEditorText } from 'core/ui/CKEditor';
import { ContentState, convertFromHTML, convertToRaw } from 'draft-js';
import useFormAnnouncement from './form';

const ModalFormAnnouncement = ({ open, item, close, callback, mode }) => {
  const { t } = useTranslation();
  const channel = useSelector((state) => channelsSelectors.getChannel(state));

  const { setValue, watch, handleSubmit, onSubmit, errors, loading } = useFormAnnouncement({
    mode,
    item,
    callback,
  });

  const defaultCkText = () => {
    if (!item?.text) {
      return defaultCkEditorText('');
    }
    if (item?.method !== 'sms' && !item?.textRaw && !!item?.text) {
      const blocksFromHTML = convertFromHTML(item.text);

      return convertToRaw(
        ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap),
      );
    }

    const newRaw = typeof item?.textRaw === 'string' ? JSON.parse(item?.textRaw) : item?.textRaw;

    return newRaw;
  };

  return (
    <Dialog
      PaperProps={{ style: { borderRadius: 5, minWidth: 300 } }}
      maxWidth="md"
      scroll="body"
      fullWidth
      onClose={() => close()}
      open={open}
    >
      <Texture>
        <Box p={5}>
          <Box>
            <Typography align="center" mb={2}>
              {t('channel.announcement')}
            </Typography>
            <Box mb={3}>
              <CKEditor
                error={errors.text}
                value={watch('text')}
                onChange={(v) => {
                  setValue('text', v?.html);
                  setValue('textRaw', v?.raw);
                }}
                defaultValue={defaultCkText()}
                bordered
                allowUploads
                // keywordOptions={[]}
                pathUpload={`Channel/${channel?.id}/Announcements`}
              />
            </Box>

            <Box mb={3} display="flex" alignItems="center">
              <Box m={1}>
                <DatePickerRounded
                  type="datetime-local"
                  error={errors.startAtDate}
                  label={t('announcement.label.startAtDate').toUpperCase()}
                  onChange={(v) => setValue('startAtDate', v)}
                  value={watch('startAtDate')}
                  usFormat={channel?.useUsDateFormat}
                  required
                />
              </Box>

              <Box m={1}>
                <TimePickerRounded
                  type="datetime-local"
                  error={errors.startAtHour}
                  label={t('announcement.label.startAtTime').toUpperCase()}
                  onChange={(v) => setValue('startAtHour', v)}
                  value={watch('startAtHour')}
                  required
                />
              </Box>
            </Box>
            <Box mb={3} display="flex" alignItems="center">
              <Box m={1}>
                <DatePickerRounded
                  error={errors.endAtDate}
                  type="datetime-local"
                  label={t('announcement.label.endAtDate').toUpperCase()}
                  onChange={(v) => setValue('endAtDate', v)}
                  value={watch('endAtDate')}
                  usFormat={channel?.useUsDateFormat}
                  required
                />
              </Box>

              <Box m={1}>
                <TimePickerRounded
                  error={errors.endAtHour}
                  type="datetime-local"
                  label={t('announcement.label.endAtTime').toUpperCase()}
                  onChange={(v) => setValue('endAtHour', v)}
                  value={watch('endAtHour')}
                  required
                />
              </Box>
            </Box>

            <Box mb={3}>
              <Box m={1}>
                <SelectBox
                  propValue="id"
                  propLabel="name"
                  label={t('workout.label.timezone').toUpperCase()}
                  options={getAllTimezones()}
                  setValue={(v) => setValue('timezone', v)}
                  value={watch('timezone') || timeZoneName()}
                  error={errors.timezone}
                  required
                />
              </Box>
            </Box>

            <Box mt={3} display="flex" justifyContent="flex-end">
              <ButtonPrimary disabled={loading} onClick={handleSubmit(onSubmit)}>
                {t('button.save')}
              </ButtonPrimary>
            </Box>
          </Box>
        </Box>
      </Texture>
    </Dialog>
  );
};

ModalFormAnnouncement.defaultProps = {
  close: () => {},
  callback: () => {},
  item: null,
  open: false,
  mode: 'new',
};

ModalFormAnnouncement.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  close: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  callback: PropTypes.func,
  open: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.object,
  mode: PropTypes.string,
};

export default ModalFormAnnouncement;
