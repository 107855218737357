import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import * as selectors from './selectors';
/* ---------- Action Creators and Types ---------- */
const { Types, Creators } = createActions(
  {
    setLoading: ['value'],
    setSession: ['session'],
    addParticipant: ['participant'],
    leaveParticipant: ['userId'],
    setParticipants: ['participants'],
    setParticipantDeviceStatus: ['connectionId', 'prop', 'value'],
    setUser: ['user'],
    setSeekVideo: ['seek'],
    setFocusedUserId: ['userId'],
    setError: ['error'],
    clear: [],
    addMessage: ['message'],
    setMessages: ['messages'],
    setReadMessage: ['index'],
    clearMessages: [],
    setConfig: ['config'],
    setParticipantFullScreen: ['id', 'screen'],
    removeParticipantScreen: ['id'],

    setVolumeStatus: ['volume'],

    setRecordingFullScreen: ['recording'],
    setPlayRecordings: ['play'],
    setMutedRecordings: ['muted'],

    setConnectionQuality: ['event'],
    sendSignalStream: ['signal'],
  },
  { prefix: 'room/' },
);

/* ---------- Initial State ---------- */
const INITIAL_STATE = Immutable({
  loading: false,
  session: null,
  participants: [],
  participantFullScreen: {},
  messages: [],
  user: {},
  seekVideo: null,
  focusedUserId: null,
  config: {
    musicVolumeControl: true,
  },
  error: {},
  playingRecordings: false,
  mutedRecordings: true,
  connectionQuality: {},
  signalStream: {},
});

/* ---------- Reducers ---------- */
const setLoadingReducer = (state, { value }) => ({ ...state, loading: value });

const setSessionReducer = (state, action) => ({ ...state, session: action.session });

const addParticipantReducer = (state, action) => {
  const newState = [...state.participants].filter((x) => x.id !== action.participant.id);
  newState.push(action.participant);
  return { ...state, participants: [...newState] };
};

const addMessageReducer = (state, action) => {
  const newState = [...state.messages];
  newState.push(action.message);
  return { ...state, messages: [...newState] };
};

const setMessagesReducer = (state, action) => {
  const newState = [...action.messages];
  return { ...state, messages: [...newState] };
};

const setReadMessageReducer = (state, action) => {
  const newState = [...state.messages];
  newState[action.index].unread = false;
  return { ...state, messages: [...newState] };
};

const clearMessagesReducer = (state) => ({ ...state, messages: [] });

const leaveParticipantReducer = (state, action) => {
  const newState = state.participants.filter((x) => x.id !== action.userId);

  return { ...state, participants: [...newState] };
};

const setParticipantsReducer = (state, action) => ({ ...state, participants: action.participants });

const setParticipantDeviceStatusReducer = (state, action) => ({
  ...state,
  participants: state.participants.map((u) => {
    if (u.connectionId === action.connectionId) {
      // eslint-disable-next-line no-param-reassign
      u[action.prop] = action.value;
    }
    return u;
  }),
});

const setConfigReducer = (state, action) => ({ ...state, config: { ...action.config } });

const setUserReducer = (state, action) => ({ ...state, user: action.user });

const setParticipantFullScreenReducer = (state, action) => {
  let participant = state.participants.find((x) => x.id === action.id);
  if (!participant) {
    if (state.user.id === action.id) {
      participant = state.user;
    }
  }
  return { ...state, participantFullScreen: { ...participant, screen: !!action.screen } };
};

const setErrorReducer = (state, action) => ({ ...state, error: action.error });

const clearReducer = () => INITIAL_STATE;

const removeParticipantScreenReducer = (state, action) => {
  const newState = state.participantsScreens.filter((x) => x.id !== action.id);
  return { ...state, participantsScreens: [...newState] };
};

const setPlayRecordingsReducer = (state, action) => ({ ...state, playingRecordings: action.play });
const setMutedRecordingsReducer = (state, action) => ({ ...state, mutedRecordings: action.muted });

const setConnectionQualityReducer = (state, action) => ({
  ...state,
  connectionQuality: action.event,
});

const sendSignalStreamReducer = (state, action) => ({ ...state, signalStream: action.signal });

const setFocusedUserIdReducer = (state, action) => ({ ...state, focusedUserId: action.userId });

const setSeekVideoReducer = (state, action) => ({ ...state, seekVideo: action.seek });

/* ---------- Main Reducer ---------- */
const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_LOADING]: setLoadingReducer,
  [Types.SET_SESSION]: setSessionReducer,
  [Types.ADD_PARTICIPANT]: addParticipantReducer,
  [Types.LEAVE_PARTICIPANT]: leaveParticipantReducer,
  [Types.SET_PARTICIPANTS]: setParticipantsReducer,
  [Types.SET_PARTICIPANT_DEVICE_STATUS]: setParticipantDeviceStatusReducer,
  [Types.SET_USER]: setUserReducer,
  [Types.SET_ERROR]: setErrorReducer,
  [Types.CLEAR]: clearReducer,
  [Types.ADD_MESSAGE]: addMessageReducer,
  [Types.SET_MESSAGES]: setMessagesReducer,
  [Types.SET_READ_MESSAGE]: setReadMessageReducer,
  [Types.CLEAR_MESSAGES]: clearMessagesReducer,
  [Types.SET_CONFIG]: setConfigReducer,
  [Types.SET_PARTICIPANT_FULL_SCREEN]: setParticipantFullScreenReducer,
  [Types.REMOVE_PARTICIPANT_SCREEN]: removeParticipantScreenReducer,
  [Types.SET_PLAY_RECORDINGS]: setPlayRecordingsReducer,
  [Types.SET_SEEK_VIDEO]: setSeekVideoReducer,
  [Types.SET_FOCUSED_USER_ID]: setFocusedUserIdReducer,
  [Types.SET_MUTED_RECORDINGS]: setMutedRecordingsReducer,
  [Types.SET_CONNECTION_QUALITY]: setConnectionQualityReducer,
  [Types.SEND_SIGNAL_STREAM]: sendSignalStreamReducer,
});

/* ---------- Exporting ---------- */
export const roomActions = Creators;
export const roomTypes = Types;
export const roomSelectors = selectors;

export default reducer;
