import React, { useContext } from 'react';

import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import Typography from 'core/ui/Typography';
import { dayWeekFilters } from 'core/utils/consts';
import Paper from 'core/ui/Paper';
import { CalendarsContext } from 'contexts';
import WeekDay from './WeekDay';

const WeeklyHours = () => {
  const { t } = useTranslation();
  const calendarContext = useContext(CalendarsContext);

  return (
    <Box>
      <Typography mb={3} variant="h5">
        {t('users.calendars.weeklyHours')}
      </Typography>

      <div>
        {dayWeekFilters.map((weekDay) => (
          <Paper p={1} key={weekDay?.id}>
            <WeekDay weekDay={weekDay} weeklyHours={calendarContext?.weeklyHours} />
          </Paper>
        ))}
      </div>
    </Box>
  );
};

export default WeeklyHours;
