import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Edit } from '@material-ui/icons';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { fontStyle } from 'core/ui/Typography';
import { onlineColor, primary } from 'core/ui/Colors';

export default function ItemList({ item, onEditClick }) {
  return (
    <TableRow>
      <TableCell>{item.name}</TableCell>
      <TableCell align="center">
        {!item.active ? (
          <RadioButtonUncheckedIcon style={{ ...fontStyle.medium, color: primary }} />
        ) : (
          <CheckCircleOutlineIcon style={{ ...fontStyle.medium, color: onlineColor }} />
        )}
      </TableCell>
      <TableCell align="center">
        {!item.default ? (
          <RadioButtonUncheckedIcon style={{ ...fontStyle.medium, color: primary }} />
        ) : (
          <CheckCircleOutlineIcon style={{ ...fontStyle.medium, color: onlineColor }} />
        )}
      </TableCell>
      <TableCell align="right">
        <IconButton onClick={() => onEditClick(item)}>
          <Edit />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

ItemList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.object.isRequired,
  onEditClick: PropTypes.func.isRequired,
};
