import React, { useState } from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import Paper from 'core/ui/Paper';
import Typography from 'core/ui/Typography';
import { ButtonPrimary } from 'core/ui/Button';
import { useDispatch, useSelector } from 'react-redux';
import DialogTitle from 'core/ui/Dialog/DialogTitle';
import LoaderSm from 'core/ui/LoaderSm';
import Texture from 'core/ui/Texture';
import SelectorPaymentMethod, { PaymentMethodViewType } from 'components/Account/Selector';
import { NavigateBefore } from '@material-ui/icons';
import { IconButton, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import { sessionSelectors } from 'modules/session';
import useSelectors from 'modules/map/useSelectors';
import { newPaymentsModule } from 'modules/payments/new';
import Modules from 'modules';
import useActions from 'modules/map/useActions';
import { currency } from 'core/utils/formatters';
import notifications from 'modules/notifications';
import { channelsSelectors } from 'modules/channels';

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function ModalPaymentKeyCancel({ open, close, channelKey, callback }) {
  const { t } = useTranslation();
  const { request } = useActions();
  const dispatch = useDispatch();
  const loading = useSelectors(newPaymentsModule, 'loading');
  const currentUser = useSelector((state) => sessionSelectors.getUser(state));
  const channel = useSelector((state) => channelsSelectors.getChannel(state));

  const [viewMethodPayment, setViewMethodPayment] = useState(PaymentMethodViewType.list);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);

  const purchase = () => {
    request({
      action: Modules.newPayments.actions.cancellationChannelKey,
      data: {
        channelId: channelKey?.channelId,
        channelKeyId: channelKey?.id,
        userPaymentMethodId: selectedPaymentMethod?.id,
      },
      options: {
        onSuccess: () => {
          if (callback) {
            callback();
          }
        },
        onError: (msg) => {
          dispatch(notifications.error(msg));
        },
      },
    });
  };

  return (
    <div style={{ minWidth: 300 }}>
      <Dialog scroll="body" fullWidth maxWidth="sm" onClose={() => close()} open={open}>
        <Texture>
          <DialogTitle onClose={() => close()}>
            {t('channel.modalPayment.paymentInformation')}
          </DialogTitle>
          <DialogContent dividers>
            <Box mx={5}>
              <Paper className="paper-rounded" mx={2} p={3}>
                <Table size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Typography color="primary" variant="subtitle1" component="span">
                          This membership is still under contract. Pay{' '}
                          {`$${currency(channelKey?.cancellationAmount)}`} to get out of the
                          contract
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Paper>
            </Box>

            <Box m={5}>
              {viewMethodPayment === PaymentMethodViewType.form && (
                <Box display="flex" alignItems="center">
                  <Box>
                    <IconButton onClick={() => setViewMethodPayment(PaymentMethodViewType.list)}>
                      <NavigateBefore fontSize="large" />
                    </IconButton>
                  </Box>
                  <Box display="flex" flexGrow={1} justifyContent="center">
                    <Typography mt={3} mb={3} align="center" color="primary" variant="h4">
                      {t('payment.method.save')}
                    </Typography>
                  </Box>
                </Box>
              )}
              <SelectorPaymentMethod
                selectedPaymentMethod={selectedPaymentMethod}
                setSelectedPaymentMethod={setSelectedPaymentMethod}
                view={viewMethodPayment}
                setView={setViewMethodPayment}
                userId={currentUser.id}
                userInfo={{
                  name: currentUser?.name,
                  phone: currentUser?.phone,
                  email: currentUser?.email,
                }}
                cardpointe={currentUser?.gymConfig?.useCardpointe}
                onlyMethod={channel?.allowAchPayments ? null : 'card'}
              />
            </Box>

            {viewMethodPayment !== PaymentMethodViewType.form && (
              <Box display="flex" px={5} justifyContent="flex-end">
                <ButtonPrimary
                  disabled={loading}
                  onClick={() => purchase()}
                  type="submit"
                  fullWidth
                >
                  <Box display="flex" alignItems="center">
                    <LoaderSm loading={loading} width={20} />
                    <Typography ml={1} component="span">
                      {t('purchase')}
                    </Typography>
                  </Box>
                </ButtonPrimary>
              </Box>
            )}
          </DialogContent>
        </Texture>
      </Dialog>
    </div>
  );
}

ModalPaymentKeyCancel.propTypes = {
  open: propTypes.bool,
  close: propTypes.func,
  callback: propTypes.func,
};

ModalPaymentKeyCancel.defaultProps = {
  open: false,
  close: null,
  callback: () => {},
};
