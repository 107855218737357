/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Typography from 'core/ui/Typography';
import Box from 'core/ui/Box';
import SFDataTable from 'core/ui/DataTable';
import { date } from 'core/utils/formatters';
import { Dialog, Link } from '@material-ui/core';
import { colorLightGrey } from 'core/ui/Colors';
import { ButtonPrimarySm } from 'core/ui/Button';
import { useTranslation } from 'react-i18next';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import { ownerableMessagesModule } from 'modules/ownerableMessages';
import useSelectors from 'modules/map/useSelectors';
import Paginator from 'core/ui/Paginator';
import Paper from 'core/ui/Paper';
import toUpper from 'ramda/src/toUpper';
import Card from 'core/ui/Card';
import { automatedMessageTypes } from 'core/utils/consts';
import { useSelector } from 'react-redux';
import { channelsSelectors } from 'modules/channels';
import TableMessages from './Table';
import { renderTypeCell } from './renderTypeCell';

const ExpanableComponent = ({ data }) => (
  <Box display="flex" alignItems="center" justifyContent="center" p={5}>
    <div dangerouslySetInnerHTML={{ __html: data?.text }} />
  </Box>
);

const TableMessageTemplates = ({ messages }) => {
  const [modalContacts, setModalContacts] = useState({ open: false });
  const { request } = useActions();
  const channel = useSelector((state) => channelsSelectors.getChannel(state));
  const { t } = useTranslation();

  const inactiveMessage = (messageId) => {
    request({
      action: Modules.ownerableMessages.actions.inactiveMessage,
      data: { channelId: channel?.id, messageId },
      options: {
        onSuccess: () => {
          request({
            action: Modules.ownerableMessages.actions.getMessages,
            data: { channelId: channel?.id, template: true },
          });
        },
      },
    });
  };

  const columns = [
    {
      name: 'Type',
      selector: 'kind',
      sortable: true,
      cell: renderTypeCell,
    },
    {
      name: 'Start Date',
      selector: 'date',
      sortable: true,
      cell: (message) => (
        <Typography>
          {message.recurringStartDate &&
            date(message.recurringStartDate, channel?.dateFormatExtReact)}
        </Typography>
      ),
    },
    {
      name: 'Last',
      selector: 'date',
      sortable: true,
      cell: (message) => (
        <Typography>
          {message.lastMessageDate && date(message.lastMessageDate, channel?.dateFormatExtReact)}
        </Typography>
      ),
    },
    {
      name: 'Method',
      selector: 'method',
      sortable: true,
      cell: (message) => <Typography>{t(`message.method.${message.method}`)}</Typography>,
    },
    {
      name: 'Frequency',
      selector: 'frequency',
      sortable: true,
      center: true,
      cell: (message) => (
        <Typography>{message.kind === 'recurring' && toUpper(message.frequency)}</Typography>
      ),
    },

    {
      name: '',
      selector: 'contactsCount',
      sortable: true,
      center: true,
      cell: (message) => (
        <Box display="flex">
          <ButtonPrimarySm onClick={() => inactiveMessage(message.id)}>
            {t('button.remove')}
          </ButtonPrimarySm>
          <Box ml={2}>
            <ButtonPrimarySm
              onClick={() => setModalContacts((prev) => ({ ...prev, open: true, message }))}
            >
              {t('button.view')}
            </ButtonPrimarySm>
          </Box>
        </Box>
      ),
    },
  ];

  const exportRows =
    messages &&
    messages.map((list) => ({
      id: list.id,
      text: `${list.text.substr(0, 15)}${list.text && list.text.length > 15 ? '...' : ''}`,
      fullText: list.text,
      subject: list.subject,
      method: list.method,
      startDate:
        list.recurringStartDate && date(list.recurringStartDate, channel?.dateFormatExtReact),
      frequency: list.frequency,
      kind: list.kind,
      videoLink: list.videoLink,
      lastMessageDate:
        list.lastMessageDate && date(list.lastMessageDate, channel?.dateFormatExtReact),
      recurringPeopleGroups: list.recurringPeopleGroups,
      messagesTypeName: list.messagesType?.name,
      recurringParams: list.recurringParams,
      customContactsNames: list.customContactsNames?.map((x) => x.name).join(', '),
      image: list.image,
    }));

  return (
    <Box>
      <SFDataTable
        columns={columns}
        data={messages}
        pagination
        showPaper={false}
        noTableHead={false}
        noHeader
        showExport
        exportData={exportRows}
        expandableRows
        expandableRowsComponent={<ExpanableComponent />}
      />

      {modalContacts.open && (
        <ModalTemplateMessages
          channel={channel}
          close={() => setModalContacts({ open: false })}
          open={modalContacts.open}
          message={modalContacts.message}
        />
      )}
    </Box>
  );
};

const ModalTemplateMessages = ({ channel, message, close }) => {
  const { t } = useTranslation();
  const { request } = useActions();
  const pagination = useSelectors(ownerableMessagesModule, 'pagination');
  const messages = useSelectors(ownerableMessagesModule, 'messages');
  const loading = useSelectors(ownerableMessagesModule, 'loading');

  const [perPage] = useState(10);

  const getMessages = (p) => {
    request({
      action: Modules.ownerableMessages.actions.getTemplateMessages,
      data: {
        channelId: channel?.id,
        messageId: message.id,
        query: null,
        page: p,
        perPage,
      },
    });
  };

  useEffect(() => {
    if (message && message.id) {
      getMessages(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changePage = (p) => {
    if (p <= pagination.totalPages) {
      getMessages(p);
    }
  };

  return (
    <Dialog maxWidth="md" fullWidth open onClose={close} scroll="body">
      <Box p={5} style={{ backgroundColor: colorLightGrey }}>
        <Paper p={5}>
          <Box m={5}>
            <div dangerouslySetInnerHTML={{ __html: message?.text }} />
          </Box>

          {message.image && (
            <Box m={5}>
              <Link href={message.videoLink} target="_blank">
                {message.image.image.url}
              </Link>
            </Box>
          )}
          {message.videoLink && (
            <Box m={5}>
              <Link href={message.videoLink} target="_blank">
                {message.videoLink}
              </Link>
            </Box>
          )}
        </Paper>

        {message.recurringParams && (
          <>
            <Paper p={5} mt={3}>
              {message.recurringParams?.membersTypes && (
                <Box>
                  <Card title={<Typography color="primary">Members</Typography>}>
                    <Box px={3} mt={2}>
                      {message?.recurringParams?.membersTypes?.map((x) => (
                        <Typography>{t(`channel.memberships.column.type.${x}`)}</Typography>
                      ))}
                    </Box>
                  </Card>
                </Box>
              )}

              {message.customContactsNames && (
                <Box mt={2}>
                  <Card title={<Typography color="primary">Custom Users:</Typography>}>
                    <Box px={3} mt={2}>
                      <Typography>{toUpper(message.customContactsNames)}</Typography>
                    </Box>
                  </Card>
                </Box>
              )}
              {message.recurringParams.membershipsTags && (
                <Box mt={2}>
                  <Card
                    title={
                      <Typography color="primary">{t('channel.label.membershipsTags')}:</Typography>
                    }
                  >
                    <Box px={3} mt={2}>
                      <Typography>{message.recurringParams.membershipsTags?.join(', ')}</Typography>
                    </Box>
                  </Card>
                </Box>
              )}
              {message.recurringParams.membersTags && (
                <Box mt={2}>
                  <Card
                    title={
                      <Typography color="primary">{t('channel.label.memberTags')}:</Typography>
                    }
                  >
                    <Box px={3} mt={2}>
                      <Typography>{message.recurringParams.membersTags?.join(', ')}</Typography>
                    </Box>
                  </Card>
                </Box>
              )}
            </Paper>

            {message.messagesType?.identify === automatedMessageTypes.recurring && (
              <Paper mt={3} p={5}>
                <Typography variant="h5">Start Date {message.recurringStartDate}</Typography>
                <Typography variant="h5">Frequency: {message.frequency}</Typography>
              </Paper>
            )}
            {message.messagesType?.identify === automatedMessageTypes.total_classes_range && (
              <Paper mt={3} p={5}>
                <Typography variant="h5">
                  Classes {message.recurringParams?.classes}
                  {' x '}
                  {message.recurringParams?.days} Days
                </Typography>
              </Paper>
            )}
            {message.messagesType?.identify === automatedMessageTypes.registration_lapse && (
              <Paper mt={3} p={5}>
                <Typography variant="h5">
                  {t('channel.template.label.days.registration_lapse')}{' '}
                  {message.recurringParams?.days}
                </Typography>
              </Paper>
            )}
            {message.messagesType?.identify === automatedMessageTypes.total_classes_overall && (
              <Paper mt={3} p={5}>
                <Typography variant="h5">Classes {message.recurringParams?.classes}</Typography>
              </Paper>
            )}
            {message.messagesType?.identify === automatedMessageTypes.first_visit && (
              <Paper mt={3} p={5}>
                <Typography variant="h5">
                  Sends {message.recurringParams?.firstVisitNumber}{' '}
                  {message.recurringParams?.firstVisitFrequency}
                  {message.recurringParams?.firstVisitNumber > 1 && 's'} after first visit
                </Typography>
              </Paper>
            )}
          </>
        )}

        <Typography mt={5} align="center">
          Logs
        </Typography>
        <TableMessages loading={loading} channel={channel} messages={messages} />

        <Box flexGrow={1} m={3} display="flex" justifyContent="center">
          <Paginator pagination={pagination} changePage={(p) => changePage(p)} />
        </Box>
      </Box>
    </Dialog>
  );
};

TableMessageTemplates.propTypes = {};

TableMessageTemplates.defaultProps = {};

export default TableMessageTemplates;
