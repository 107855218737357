import React, { useEffect, useState } from 'react';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import ConfirmDialog from 'core/ui/ConfirmDialog';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import SectionScoresDetails from '../Workouts/SectionScoresDetails';

const BenchmarkSectionsUser = ({ selectedBenchmark }) => {
  const { t } = useTranslation();
  const { request } = useActions();
  const [data, setData] = useState({ open: false });
  const [confirmDelete, setConfirmDelete] = useState({ open: false });

  const getHistoryScore = () => {
    request({
      action: Modules.usersHistoryScores.actions.getCompareHistory,
      data: {
        benchmarkSectionId: selectedBenchmark?.id,
      },
      options: {
        onSuccess: (resp) => {
          setData(resp);
        },
        onError: () => {
          setData(null);
        },
      },
    });
  };

  useEffect(() => {
    if (selectedBenchmark && selectedBenchmark.id) {
      getHistoryScore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBenchmark]);

  const remove = (histId) => {
    setConfirmDelete({
      open: true,
      confirm: () => {
        request({
          action: Modules.benchmarkSections.actions.deleteBenchmarkSectionUser,
          data: { benchmarkSectionUserId: histId },
          options: {
            onSuccess: () => {
              getHistoryScore();
              request({
                action: Modules.benchmarkSections.actions.getBenchmarkSections,
                data: { benchmarkSectionId: selectedBenchmark.id },
              });
            },
          },
        });

        setConfirmDelete({ open: false });
      },
    });
  };

  return (
    <Box mt={3}>
      <SectionScoresDetails
        section={{ scores: [selectedBenchmark?.scores] }}
        data={data}
        removeScore={remove}
      />

      <ConfirmDialog
        open={confirmDelete?.open}
        confirm={() => confirmDelete?.confirm && confirmDelete?.confirm()}
        cancel={() => setConfirmDelete({ open: false })}
        description={t('pr.user.confirmDelete')}
        labelConfirm={t('button.confirm')}
        labelCancel={t('button.cancel')}
      />
    </Box>
  );
};

export default BenchmarkSectionsUser;
