import React from 'react';
import Paper from 'core/ui/Paper';
import Typography from 'core/ui/Typography';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import MIcon from 'core/ui/MIcon';
import ViewVideo from './MediaFilesGallery/ViewVideo';

export default function ItemMovement({ movement, addMedia, removeAssociation }) {
  const { t } = useTranslation();
  const hasUrl = !!movement?.mediaFile?.url;
  return (
    <Paper className="paper-raounded" p={3}>
      <Box>
        <Typography mb={2}>{movement.name}</Typography>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          style={{ minHeight: 180 }}
        >
          <ViewVideo url={movement?.mediaFile?.url} />

          <Box mt={3} display="flex" justifyContent="center" alignItems="center">
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              className="hover"
              onClick={() => addMedia(movement)}
            >
              <MIcon icon={hasUrl ? 'edit' : 'add'} size={hasUrl ? 14 : 40} />
              <Typography color="primary" style={{ fontSize: hasUrl ? 14 : 24 }}>
                {t(hasUrl ? 'movement.media.change' : 'movement.media.addMedia')}
              </Typography>
            </Box>
            {hasUrl && (
              <Box
                ml={3}
                display="flex"
                justifyContent="center"
                alignItems="center"
                className="hover"
                onClick={() => removeAssociation(movement)}
              >
                <MIcon icon="delete" size={14} />
                <Typography color="primary" style={{ fontSize: hasUrl ? 14 : 24 }}>
                  {t('movement.media.remove')}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Paper>
  );
}
