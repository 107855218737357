import useActions from 'modules/map/useActions';
import Modules from 'modules';
import useSelectors from 'modules/map/useSelectors';
import { workoutsUsersModule } from 'modules/workoutsUsers';

export default function useRegisterStatus({ workout }) {
  const loading = useSelectors(workoutsUsersModule, 'loading');
  const registerStatus = useSelectors(workoutsUsersModule, 'registerStatus');
  const { request } = useActions();

  const getRegisterStatus = (user) => {
    if (user && user.id) {
      request({
        action: Modules.workoutsUsers.actions.getRegisterStatus,
        data: { workoutId: workout.id, userId: user.id },
      });
    } else {
      request({
        action: Modules.workoutsUsers.actions.clearRegisterStatus,
      });
    }
  };

  const clearRegisterStatus = () => {
    request({
      action: Modules.workoutsUsers.actions.clearRegisterStatus,
    });
  };

  return {
    getRegisterStatus,
    clearRegisterStatus,
    registerStatus,
    loading,
  };
}
