/* eslint-disable no-use-before-define */
import React from 'react';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import NoSsr from '@material-ui/core/NoSsr';
import CloseIcon from '@material-ui/icons/Close';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import styled from 'styled-components';
import { InputBase, makeStyles, Popper } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { tagsSelectors, tagsActions } from 'modules/tags';
import Box from '../Box';
import { secondaryLight } from '../Colors';
import Typography, { fontStyle } from '../Typography';

const InputWrapper = styled('div')`
  width: 100%;
  background-color: #fff;
  padding: 4px;
  display: flex;
  flex-wrap: wrap;
  border-radius: 5px;
  min-height: 45px;
  & input {
    font-size: 12px;
    height: 30px;
    box-sizing: border-box;
    padding: 4px 6px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
`;

export const Tag = styled(
  ({ label, onDelete, iconTag, iconTagHover, bgColor, lightColor, ...props }) => {
    const useStyles = makeStyles((theme) => ({
      labelColor: {
        color: theme.palette.getContrastText(lightColor || '#bbbdbf'),
      },
    }));

    const classes = useStyles();

    return (
      <div {...props} style={{ backgroundColor: lightColor || '#bbbdbf' }}>
        <span className={classes.labelColor} style={fontStyle.label}>
          {label}
        </span>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          className="change-icon"
          style={{ background: bgColor || '#404041', height: 30, width: 25 }}
        >
          {iconTag ? (
            <div className="MuiSvgIcon-root">{iconTag}</div>
          ) : (
            <LocalOfferIcon style={{ color: 'white' }} onClick={onDelete} />
          )}
          {iconTagHover ? (
            <div className="MuiSvgIcon-root">{iconTagHover}</div>
          ) : (
            <CloseIcon style={{ color: 'white', fontSize: 14 }} onClick={onDelete} />
          )}
        </Box>
      </div>
    );
  },
)`
  display: flex;
  align-items: center;
  margin: 4px;

  border-radius: 5px;
  box-sizing: content-box;
  outline: 0;
  overflow: hidden;
  padding-left: 5px;
  font-family: Roboto;
  font-weight: 500;
  font-size: 12px;

  &:focus {
    border-color: #40a9ff;
    background-color: #e6f7ff;
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 10px;
  }

  .change-icon > .MuiSvgIcon-root + .MuiSvgIcon-root,
  .change-icon:hover > .MuiSvgIcon-root {
    display: none;
  }
  .change-icon:hover > .MuiSvgIcon-root + .MuiSvgIcon-root {
    display: inherit;
  }

  & svg {
    font-size: 12px;
    cursor: pointer;
    padding: 4px;
  }
`;

const Listbox = styled('ul')`
  padding: 0;
  list-style: none;
  overflow: auto;
  max-height: 200px;
  max-width: 200px;
  background: #fff;
  z-index: 1000;

  & div {
    padding: 5px 12px;
    display: flex;
    font-size: 12px;
    font-family: Roboto;
    font-weight: 500;
    cursor: pointer;
    & span {
      flex-grow: 1;
    }
  }

  & div[aria-selected='true'] {
    background-color: #fafafa;
    color: #ec4242;
    & svg {
      color: #414042;
    }
  }

  & div[data-focus='true'] {
    color: #ec4242;
    cursor: pointer;

    & svg {
      color: #ec4242;
    }
  }
`;

const styleBoxList = {
  maxHeight: 250,
  backgroundColor: '#fff',
  padding: 10,
  borderRadius: '20px 20px 20px 20px',
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
  zIndex: 9999,
};

export default function TagsChosen({
  setValue,
  name,
  kind,
  val,
  limit,
  lightColor,
  placeholder,
  defaultValue,
  iconTag,
  style,
  bordered,
  label,
  required,
}) {
  const change = (evt, data) => {
    setValue(data);
  };
  const wRef = React.createRef();
  const dispatch = useDispatch();

  const interests = useSelector((state) => tagsSelectors.getTagsAutocomplete(state));
  const searchInterest = (event) => {
    if (event && kind) {
      dispatch(tagsActions.getTagsAutocompleteStart({ query: event.target.value, kind }));
    }
  };

  const {
    getRootProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    value,
    focused,
    setAnchorEl,
    inputValue,
  } = useAutocomplete({
    defaultValue: [...defaultValue],
    value: val,
    multiple: true,
    // groupBy: (option) => option,
    freeSolo: true,
    options: [...interests],
    // value: defaultValue,
    onChange: change,
    getOptionLabel: (option) => option.name,
    onInputChange: searchInterest,
  });

  const input = getInputProps();

  const set = (option) => {
    setValue([...val, option]);
  };

  const handleBlur = () => {
    dispatch(tagsActions.clearTagsAutocomplete());
    input.onBlur();
    input.onChange({ target: { value: '' } });
  };

  const styleInput = { ...style, border: bordered ? `1px solid${secondaryLight}` : 'none' };

  return (
    <NoSsr>
      <div>
        {label !== '' && (
          <Box ml={2} mb={2} display="flex">
            <Typography style={fontStyle.label} variant="body1">
              {label}
            </Typography>
            {required && (
              <Typography ml={1} color="error">
                *
              </Typography>
            )}
          </Box>
        )}
        <div {...getRootProps()}>
          <InputWrapper ref={wRef} className={focused ? 'focused' : ''} style={styleInput}>
            {value.map((option, index) => (
              <Tag
                lightColor={lightColor}
                label={option.name || option}
                iconTag={iconTag}
                {...getTagProps({ index })}
              />
            ))}
            <Box display="flex" flexGrow="1" ref={setAnchorEl} alignItems="center">
              <InputBase
                style={{ minWidth: 30, width: '100%' }}
                name={name}
                className={`${limit && limit > 0 && value.length >= limit ? 'hide' : ''} fontInput`}
                placeholder={value.length <= 0 ? placeholder : ''}
                {...getInputProps()}
                onBlur={handleBlur}
              />
            </Box>
          </InputWrapper>
        </div>
        {inputValue && interests.length > 0 ? (
          <Popper
            open
            anchorEl={input.ref.current}
            placement="bottom-start"
            role={undefined}
            transition
            disablePortal
            style={{ zIndex: 9999 }}
          >
            <Box
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
              style={{ ...styleBoxList }}
            >
              <Listbox {...getListboxProps()}>
                {interests.map((option, index) => (
                  <Box key={index.toString()} onClick={() => set(option)}>
                    <span>{option.name}</span>
                  </Box>
                ))}
              </Listbox>
            </Box>
          </Popper>
        ) : null}
      </div>
    </NoSsr>
  );
}

TagsChosen.defaultProps = {
  setValue: null,
  placeholder: '',
  error: {},
  defaultValue: [],
  limit: null,
  val: [],
  iconTag: null,
  style: null,
  tag: '',
  kind: '',
};
