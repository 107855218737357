import React from 'react';
import { Dialog, Slide } from '@material-ui/core';
import ContactConversation from './ContactConversation';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function ModalConversations({ close, open, channel, userId, contactForm }) {
  return (
    <Dialog
      open={open}
      onClose={() => close()}
      style={{ marginTop: 90 }}
      fullScreen
      scroll="paper"
      PaperProps={{
        style: { borderTopRightRadius: 5, borderTopLeftRadius: 5 },
      }}
      TransitionComponent={Transition}
    >
      <ContactConversation
        close={close}
        contactForm={contactForm}
        channel={channel}
        userId={userId}
      />
    </Dialog>
  );
}

ModalConversations.propTypes = {};

ModalConversations.defaultProps = {};
