import React, { createContext, useState } from 'react';

export const HelpVideoManagerContext = createContext({
  visible: false,
  setVisible: () => {},
  toggleVisible: () => {},
});

export const HelpVideoManagerProvider = ({ children }) => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    setVisible((_visible) => !_visible);
  };

  return (
    <HelpVideoManagerContext.Provider value={{ visible, setVisible, toggleVisible }}>
      {children}
    </HelpVideoManagerContext.Provider>
  );
};
