import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Paper from '../Paper';

const useStyles = makeStyles(() => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: 5,
  },
}));

export default function WrapperInput({ children, style, error, px }) {
  const classes = useStyles();
  return (
    <Paper
      className={`${error ? 'input-paper-error' : ''} ${classes.root}`}
      style={{ ...style }}
      px={px}
    >
      {children}
    </Paper>
  );
}

WrapperInput.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  error: PropTypes.object,
};

WrapperInput.defaultProps = {
  style: {},
  error: null,
};
