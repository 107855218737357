import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { APP_BASE_URL } from 'core/env.js';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';
import { FileCopy } from '@material-ui/icons';
import { Dialog, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { copyToClipBoard } from 'core/utils/helpers';
import Typography from '../Typography';
import Box from '../Box';
import { primary } from '../Colors';
import TextFieldRounded from '../TextFieldRounded';
import Texture from '../Texture';

export default function ModalShare({ title, opened, close, url, quote, hashtag }) {
  const [text, setText] = useState('');

  const { t } = useTranslation();

  return (
    <Dialog open={opened} maxWidth="xs" fullWidth onClose={close}>
      <Texture>
        <Box p={5}>
          {title && (
            <Typography mb={3} variant="h5" align="center" color="primary">
              {title}
            </Typography>
          )}

          <Box display="flex" justifyContent="center">
            <Box
              className="open-social-media"
              display="flex"
              alignItems="center"
              justifyContent="center"
              style={{
                background: '#fff',
                borderRadius: 5,
                height: 33,
                zIndex: 0,
                width: 0,
                overflow: 'hidden',
              }}
            >
              <Box display="flex" alignItems="center" pt={1}>
                <Box display="flex" pt={1}>
                  <Tooltip title={t('social.share.tooltip.facebook')}>
                    <FacebookShareButton url={url || APP_BASE_URL} quote={quote} hashtag={hashtag}>
                      <FacebookIcon
                        round
                        size={38}
                        bgStyle={{ fill: '#fff' }}
                        iconFillColor="#82aae9"
                      />
                    </FacebookShareButton>
                  </Tooltip>
                  <Tooltip title={t('social.share.tooltip.twitter')}>
                    <TwitterShareButton url={url || APP_BASE_URL} quote={quote} hashtag={hashtag}>
                      <TwitterIcon
                        round
                        size={38}
                        bgStyle={{ fill: '#fff' }}
                        iconFillColor="#86cafa"
                      />
                    </TwitterShareButton>
                  </Tooltip>
                  <Tooltip title={t('social.share.tooltip.whatsapp')}>
                    <WhatsappShareButton url={url || APP_BASE_URL} quote={quote} hashtag={hashtag}>
                      <WhatsappIcon
                        round
                        size={30}
                        bgStyle={{ fill: '#fff' }}
                        iconFillColor="#0dbf42"
                      />
                    </WhatsappShareButton>
                  </Tooltip>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box mt={3}>
            <Box display="flex" alignItems="flex-end">
              <Box
                flexGrow="1"
                style={{
                  borderRadius: 5,
                }}
                p={1}
              >
                <TextFieldRounded value={url} locked="true" />
              </Box>
              <Box ml={1} pb={1} onClick={() => copyToClipBoard(url, setText)}>
                <FileCopy style={{ color: primary, fontSize: 18 }} />
              </Box>
            </Box>
            <Typography color="primary" style={{ fontSize: 12 }} align="center" component="p">
              {text}
            </Typography>
          </Box>
        </Box>
      </Texture>
    </Dialog>
  );
}

ModalShare.defaultProps = {
  url: '',
  hashtag: '',
  quote: '',
};

ModalShare.propTypes = {
  url: PropTypes.string,
  hashtag: PropTypes.string,
  quote: PropTypes.string,
};
