import React from 'react';
import Box from 'core/ui/Box';
import Invites from '../Tabs/Overview/Invites/index';

const CustomInvites = ({ workout }) => (
  <Box>
    {workout?.invites?.length > 0 && (
      <Box mt={3}>
        <Invites workout={workout} showStartJoinButton={() => true} />
      </Box>
    )}
  </Box>
);

export default CustomInvites;
