/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import Typography from 'core/ui/Typography';
import CalculatorIcon from 'core/ui/MIcon/CalculatorIcon';
import ModalPrCalculator from './ModalPrCalculator';

const PrCalculator = ({ selectedPr }) => {
  const [opened, setOpened] = useState(false);
  const { t } = useTranslation();

  return (
    <Box my={5}>
      <Box
        display="flex"
        className="hover"
        onClick={() => setOpened(!opened)}
        justifyContent="center"
        alignItems="center"
      >
        <CalculatorIcon color="black" size={18} />
        <Typography color="secondary" variant="h5" mx={1}>
          {t('personalRecord.calculator')}
        </Typography>
      </Box>
      {opened && (
        <ModalPrCalculator close={() => setOpened(false)} scores={selectedPr.bestPr.scores} />
      )}
    </Box>
  );
};

export default PrCalculator;
