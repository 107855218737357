import api from 'core/api';

export const channelManageModule = 'channelManage';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const actions = {
  cloneCalendarWorkouts: {
    module: channelManageModule,
    name: 'cloneCalendarWorkouts',
    api: (data) => api.post(`/channels/${data.channelId}/clone_calendar_workouts`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
};

export default {
  actions,
  state: {
    loading: false,
  },
};
