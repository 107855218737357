/* eslint-disable react/prop-types */
import React, { createContext } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Box from 'core/ui/Box';
import Typography from 'core/ui/Typography';
import { ButtonPrimarySm } from 'core/ui/Button';
import { Hidden } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import LoaderSm from 'core/ui/LoaderSm';
import Image from './Image';
import UserAddresses from './Addresses';
import EmergencyContactFields from './EmergencyContactFields';

export const Context = createContext();

const useStyles = makeStyles((theme) => ({
  boxRoot: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  spacingBox: {
    marginBottom: 4,
  },
  boxAvatar: {
    height: 170,
    position: 'relative',
    top: 33,
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      marginRight: 15,
      justifyContent: 'flex-start',
    },
  },
  avatar: {
    height: 170,
    width: 170,
    borderRadius: 10,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    display: 'block',
    backgroundColor: '#bbbdbf',
  },
  labelHeader: {
    width: 100,
    fontSize: 12,
  },
  btnSubmitXs: {
    position: 'absolute',
    right: 10,
    zIndex: 1,
    top: 170,
  },
}));

export default ({
  errors,
  loading,
  setValue,
  watch,
  memberEdit,
  memberAdd,
  quickForm,
  image,
  setImage,
  channelId,
  fastForm,
  user,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const defaultStyles = {
    fontWeight: '600',
    color: '#404041',
    fontSize: 12,
  };

  return (
    <div>
      {!quickForm && !memberEdit && !memberAdd && (
        <Hidden only={['md', 'lg', 'xl']}>
          <div className={classes.btnSubmitXs}>
            <ButtonPrimarySm
              type="submit"
              size="small"
              disabled={loading}
              style={{ height: 30, width: 80, borderRadius: 5 }}
            >
              <LoaderSm loading={loading} width={20} />
              {t('button.save')}
            </ButtonPrimarySm>
          </div>
        </Hidden>
      )}

      {!quickForm && (
        <Box display="flex" flexWrap="wrap" className={classes.boxRoot}>
          <Box flexGrow="1">
            <Box className={classes.boxAvatar} display="flex">
              <Box className={classes.avatar}>
                <Image image={image} setImage={setImage} />
              </Box>
            </Box>
          </Box>

          {!memberEdit && !memberAdd && (
            <Hidden only={['xs', 'sm']}>
              <Box display="flex">
                <ButtonPrimarySm
                  type="submit"
                  size="small"
                  mt={10}
                  ml={1}
                  disabled={loading}
                  style={{ height: 30, width: 100, borderRadius: 5 }}
                >
                  <LoaderSm loading={loading} width={20} />
                  {t('button.save')}
                </ButtonPrimarySm>
              </Box>
            </Hidden>
          )}
        </Box>
      )}
      <Box mt={10} flexGrow="1">
        <Typography color="primary" mt={5} mb={3} variant="h5">
          {t('profile.title.personalInformation').toUpperCase()}
        </Typography>

        <Box px={5} display="flex" className={classes.spacingBox} flexGrow="1">
          <TextFieldHeader
            classe="ph-bold"
            error={errors.name}
            value={watch('name') || ''}
            name="name"
            label={t('profile.label.name').toUpperCase()}
            onChange={(v) => setValue('name', v)}
            style={{
              ...defaultStyles,
              fontSize: 18,
            }}
            placeholder={t('profile.enterYourName')}
            required
          />
        </Box>

        {!fastForm && (
          <>
            <Box px={5} display="flex" className={classes.spacingBox} alignItems="center">
              <TextFieldHeader
                classe="ph-bold"
                name="birthday"
                label={t('profile.birthday').toUpperCase()}
                type="date"
                value={watch('birthday') || ''}
                error={errors.birthday}
                onChange={(v) => setValue('birthday', v)}
                style={{
                  ...defaultStyles,
                  maxWidth: 120,
                }}
                placeholder={t('profile.placeholder.birthday')}
                required={!memberAdd && !memberEdit}
              />
            </Box>

            <Box
              px={5}
              display="flex"
              className={classes.spacingBox}
              flexWrap="wrap"
              flexDirection="column"
            >
              <Box flexGrow="1" ml={2} display="flex">
                <Typography className={classes.labelHeader} variant="body1" mr={5}>
                  {t('profile.gender').toUpperCase()}
                </Typography>
              </Box>
              <Box ml={2}>
                <RadioGroup row aria-label="position" name="gender" value={watch('gender') || null}>
                  <FormControlLabel
                    value="male"
                    onClick={() => setValue('gender', 'male')}
                    control={<Radio size="small" color="primary" />}
                    label={t('profile.label.gender.male')}
                  />
                  <FormControlLabel
                    value="female"
                    onClick={() => setValue('gender', 'female')}
                    control={<Radio size="small" color="primary" />}
                    label={t('profile.label.gender.female')}
                  />
                  <FormControlLabel
                    value="n"
                    onClick={() => setValue('gender', 'n')}
                    control={<Radio size="small" color="primary" />}
                    label={t('profile.label.gender.do_not_define_me')}
                  />
                </RadioGroup>
              </Box>
            </Box>

            {!quickForm && (
              <Box px={5} display="flex" className={classes.spacingBox} alignItems="center">
                <TextFieldHeader
                  classe="ph-bold"
                  name="instagram"
                  label={t('profile.instagram').toUpperCase()}
                  value={watch('instagram') || ''}
                  error={errors.instagram}
                  onChange={(v) => setValue('instagram', v)}
                  style={{
                    ...defaultStyles,
                  }}
                  placeholder={t('profile.placeholder.instagram')}
                />
              </Box>
            )}

            <Box px={5} display="flex" className={classes.spacingBox} alignItems="center">
              <TextFieldHeader
                classe="ph-bold"
                name="referredBy"
                label={t('profile.referredBy').toUpperCase()}
                value={watch('referredBy') || ''}
                error={errors.referredBy}
                onChange={(v) => setValue('referredBy', v)}
                style={{
                  ...defaultStyles,
                }}
                placeholder={t('profile.placeholder.referredBy')}
              />
            </Box>

            {!quickForm && (
              <Box px={5} display="flex" className={classes.spacingBox} alignItems="center">
                <TextFieldHeader
                  classe="ph-bold"
                  name="localGym"
                  label={t('profile.localGym').toUpperCase()}
                  value={watch('localGym') || ''}
                  onChange={(v) => setValue('localGym', v)}
                  style={{
                    ...defaultStyles,
                  }}
                  placeholder={t('profile.placeholder.localGym')}
                />
              </Box>
            )}
          </>
        )}
      </Box>

      {!fastForm && (
        <>
          {user?.id && (
            <Box pb={3} mt={5}>
              <UserAddresses user={user} channelId={channelId} />
            </Box>
          )}

          <EmergencyContactFields setValue={setValue} watch={watch} errors={errors} />
        </>
      )}
    </div>
  );
};

const TextFieldHeader = (props) => (
  <Box mb={1} style={{ width: '100%' }}>
    <TextFieldRounded {...props} style={{ fontSize: 12 }} />
  </Box>
);
