import React, { useEffect, useState } from 'react';
import Typography from 'core/ui/Typography';
import AWS from 'aws-sdk';
import LinearProgress from 'core/ui/LinearProgress';
import { ENV_APP } from 'core/env';
import Box from 'core/ui/Box';
import { B_ID } from 'core/utils/consts';
import { mKY } from 'core/utils/helpers';
import { colors } from '@material-ui/core';
import Paper from 'core/ui/Paper';
import { chatColors } from 'core/ui/Colors';
import { Close } from '@material-ui/icons';

const B_K = mKY();

const bucket = new AWS.S3({
  accessKeyId: B_ID,
  secretAccessKey: B_K,
  region: 'us-east-2',
});

const PATH_KEY = `uploads/${ENV_APP}/groups_chats/media`;

export default function BucketUpload({ groupId, itemFile, setFile, removeFile }) {
  const [uploading, setUploading] = useState(0);
  const [progressUpload, setProgressUpload] = useState(0);

  const file = itemFile?.file;

  const uploadfile = (fileName) => {
    const params = {
      Bucket: 'stream-fitness-app-uploads',
      Key: `${PATH_KEY}/${groupId}/${fileName}`,
      Body: file,
      ContentType: file.type,
    };

    return bucket.upload(params, (err, data) => {
      setUploading(false);
      if (err) {
        console.log('There was an error uploading your file: ', err);
        return false;
      }

      setFile({ uuid: itemFile?.uuid, url: data.Location, name: fileName, complete: true });
      return true;
    });
  };

  const uploadSampleFile = () => {
    const uniqueFileName = `streamfit-group-mediafile-${file.uuid}-${
      file?.name ? file?.name : file.type
    }`;
    setUploading(true);
    uploadfile(uniqueFileName).on('httpUploadProgress', (progress) => {
      const progressPercentage = Math.round((progress.loaded / progress.total) * 100);
      setProgressUpload(progressPercentage);
    });
  };

  useEffect(() => {
    if (itemFile?.file?.name) {
      uploadSampleFile();
    }
  }, [itemFile]);

  return (
    <>
      {!!itemFile?.uuid && (
        <Paper style={{ backgroundColor: chatColors.container, minWidth: 200 }} p={1}>
          <Box display="flex">
            <Box flexGrow={1}>
              <Typography style={{ color: colors.grey[400] }}>{file?.name}</Typography>
            </Box>
            <Box onClick={() => removeFile(itemFile)}>
              <Close fontSize="small" style={{ color: colors.grey[300] }} />
            </Box>
          </Box>
          <LinearProgress
            style={{ height: 10, width: '100%', borderRadius: 5 }}
            value={progressUpload}
            textColor={colors.grey[300]}
          />
          {/* {!uploading && (
            <Box mt={3} display="flex" justifyContent="center">
              <ButtonPrimary onClick={() => uploadSampleFile()}>
                {t('button.startUpload')}
              </ButtonPrimary>
            </Box>
          )} */}
        </Paper>
      )}
    </>
  );
}
