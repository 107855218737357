import React from 'react';
import Paper from 'core/ui/Paper';
import { GoogleMap, OverlayView } from '@react-google-maps/api';
import { mapsStyles } from 'core/utils/consts';
import { Avatar } from '@material-ui/core';
import Typography from 'core/ui/Typography';

const bounds = (event) => {
  if (!window.google.maps || !window.google.maps.LatLng) {
    return null;
  }

  return new window.google.maps.LatLngBounds(
    new window.google.maps.LatLng(event?.location?.lat, event?.location?.lng),
    new window.google.maps.LatLng(event?.location?.lat, event?.location?.lng),
  );
};

const LocationMap = ({ event }) => (
  <Paper p={3}>
    <Typography mb={3} className="tw-text-lg tw-font-bold">
      {event?.location?.name}
    </Typography>
    <GoogleMap
      clickableIcons={false}
      zoom={12}
      center={event?.location}
      options={{
        styles: mapsStyles.light,
      }}
      mapContainerStyle={{ width: '100%', height: 350, flex: 1 }}
    >
      <OverlayView bounds={bounds(event)} mapPaneName={OverlayView.FLOAT_PANE}>
        <Avatar src={event?.image?.image?.url} />
      </OverlayView>
    </GoogleMap>
  </Paper>
);

LocationMap.defaultProps = {};

LocationMap.propTypes = {};

export default LocationMap;
