import React, { createContext, useContext, useEffect, useRef } from 'react';
import Box from 'core/ui/Box';
import { makeStyles } from '@material-ui/core';
import Video from 'core/ui/Video';
import { StreamContext } from 'pages/Stream';
import Grid from 'core/ui/Grid';
import TimerSection from 'components/Workout/Timer/index';
import { sessionWorkoutActions } from 'modules/sessionWorkout';
import { workoutEvent } from 'core/utils/consts';
import { useDispatch } from 'react-redux';
import { isSafari } from 'react-device-detect';
import Typography from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import ClassSlider from './Timeline/ClassSlider';
import ModalChooseView from './ModalChooseView';
import SkeletonRecording from './Skeleton';
import GalleryView from './GalleryView';
import SpeakerView from './SpeakerView';
import useRecordingView from './useRecordingView';

export const RecordingContext = createContext();
export default function Recording() {
  const { t } = useTranslation();
  const streamContext = useContext(StreamContext);
  const dispatch = useDispatch();
  const recording = useRecordingView();

  const useStyles = makeStyles((theme) => ({
    boxSpeaker: {
      marginBottom: 30,
      height: '100%',
      overflow: 'hidden',
      [theme.breakpoints.down('sm')]: {
        marginBottom: 85,
        marginTop: 10,
      },
    },
    boxTimer: {
      position: 'absolute',
      top: recording.size.height - 130,
      zIndex: 11,
      [theme.breakpoints.down('sm')]: {
        top: recording.size.height - 180,
      },
      padding: 10,
      borderRadius: 10,
    },
    boxName: {
      position: 'relative',
      marginTop: -23,
      marginLeft: 0,
      background: 'rgba(24,24,24,0.54)',
      borderBottomRightRadius: 10,
      borderBottomLeftRadius: 10,
    },
  }));

  useEffect(() => {
    dispatch(
      sessionWorkoutActions.postEventStart({
        workoutId: recording.workout.id,
        kind: workoutEvent.join,
      }),
    );
  }, [dispatch, recording.workout]);

  const classes = useStyles();

  const boxCamRef = useRef();
  const boxCamActiveRef = useRef();

  return (
    <Box style={{ height: '100%', width: '100%' }} display="flex" flexDirection="column" px={3}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8} md={8} lg={8} />
      </Grid>

      {recording.workout.method !== 'video' && (
        <div className="wrapper-rec" style={{ maxHeight: 0 }}>
          <div className="box-rec" ref={boxCamRef} />
          <div
            className={
              recording.workout.method === 'video' || recording.workout.recordingVideos.length === 1
                ? 'box-rec active-rec-3'
                : 'box-rec active-rec'
            }
            ref={boxCamActiveRef}
          />
        </div>
      )}

      <RecordingContext.Provider
        value={{
          ...recording,
        }}
      >
        {(!recording.recordingChooseView.open || recording.workout.method === 'video') && (
          <Box display="flex" justifyContent="center" m={1}>
            <Box flexGrow="1" mr={3} my={1}>
              <ClassSlider
                showButtonView={
                  !recording.workout.live && recording.workout.recordingVideos.length > 1
                }
              />
            </Box>
          </Box>
        )}

        {isSafari && (
          <Box p={3}>
            <Typography align="center" style={{ color: 'white', fontSize: 40 }}>
              {t('workout.recording.watch.safari.alert')}
            </Typography>
          </Box>
        )}

        {!recording.workout.live && recording.workout.method === 'video' ? (
          <Box className={classes.boxSpeaker}>
            <Box
              className="box-rec active-rec-3"
              style={{
                height: '100%',
                position: 'relative',
                background: '#000',
                borderRadius: 10,
              }}
            >
              <Video
                playing={recording.playingRecordings}
                muted={recording.mutedRecordings}
                src={recording.workout.videoLink}
                upload
                full
                showPlayButton={false}
                seek={recording.time.seek}
                onSeek={(v) => recording.setTime((prev) => ({ ...prev, complete: v }))}
                borderRadius={10}
              />
            </Box>

            <Box className={classes.boxTimer}>
              {streamContext.eventSideBar.currentSection && (
                <TimerSection
                  playingRecordings={recording.playingRecordings}
                  live
                  isCoach={false}
                  section={streamContext.eventSideBar.currentSection}
                  colorLabel="#fff"
                  zoomView={streamContext && streamContext.zoomView}
                />
              )}
            </Box>
          </Box>
        ) : (
          <>
            {!recording.workout.live && (
              <ModalChooseView
                open={recording.recordingChooseView.open}
                close={() => recording.setRecordingChooseView({ open: false })}
                setGalleryView={(v) => {
                  streamContext.eventSideBar.setGalleryView(v);
                  recording.setRecordingChooseView({ open: false });
                }}
              />
            )}
            {!recording.recordingChooseView.open ? (
              <Box className={classes.boxSpeaker}>
                {!recording.workout.live && recording.workout.recordingVideos.length === 1 && (
                  <Box className="wrapper-rec" m={1}>
                    <Box
                      className="box-rec active-rec-3"
                      style={{
                        height: `${
                          recording.getSize(
                            recording.workout.recordingVideos[0],
                            boxCamActiveRef.current && boxCamActiveRef.current.clientWidth,
                          ).height
                        }px`,
                        position: 'relative',
                        background: '#000',
                        borderRadius: 10,
                      }}
                    >
                      <Video
                        playing={recording.playingRecordings}
                        muted={recording.mutedRecordings}
                        src={recording.workout.recordingVideos[0].vimeoUrl}
                        vimeo
                        full
                        showPlayButton={false}
                        seek={recording.time.seek}
                        onReady={() =>
                          recording.setVideoReady({
                            url: recording.workout.recordingVideos[0].vimeoUrl,
                          })
                        }
                        onSeek={(v) =>
                          recording.workout.recordingVideos[0].isCoach
                            ? recording.setTime((prev) => ({ ...prev, complete: v }))
                            : null
                        }
                        borderRadius={10}
                      />
                    </Box>
                  </Box>
                )}

                {(recording.workout.live || recording.workout.recordingVideos.length > 1) && (
                  <>
                    {streamContext.eventSideBar.galleryView ? (
                      <GalleryView
                        openDrawer={streamContext.openDrawer}
                        workout={recording.workout}
                        getSize={recording.getSize}
                        videos={recording.videos}
                        playingRecordings={recording.playingRecordings}
                        setTime={recording.setTime}
                        time={recording.time}
                        mutedRecordings={recording.mutedRecordings}
                        setVideoReady={recording.setVideoReady}
                      />
                    ) : (
                      <SpeakerView
                        workout={recording.workout}
                        setRecordingFullScreen={recording.setRecordingFullScreen}
                        playingRecordings={recording.playingRecordings}
                        setTime={recording.setTime}
                        videos={recording.videos}
                        time={recording.time}
                        mutedRecordings={recording.mutedRecordings}
                        setVideoReady={recording.setVideoReady}
                      />
                    )}
                  </>
                )}
              </Box>
            ) : (
              <SkeletonRecording />
            )}
          </>
        )}

        <Box className={classes.boxTimer}>
          {streamContext.eventSideBar.currentSection && (
            <TimerSection
              playingRecordings={recording.playingRecordings}
              live
              isCoach={false}
              section={streamContext.eventSideBar.currentSection}
              colorLabel="#fff"
              zoomView={streamContext && streamContext.zoomView}
            />
          )}
        </Box>
      </RecordingContext.Provider>
    </Box>
  );
}

Recording.propTypes = {};

Recording.defaultProps = {};
