/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Typography from 'core/ui/Typography';
import Box from 'core/ui/Box';
import SFDataTable from 'core/ui/DataTable';
import { useDispatch, useSelector } from 'react-redux';
import { messageListsActions, messageListsSelectors } from 'modules/messageLists';
import { Skeleton } from '@material-ui/lab';
import { Check, RemoveCircle } from '@material-ui/icons';
import { Dialog, IconButton } from '@material-ui/core';
import Texture from 'core/ui/Texture';
import Paper from 'core/ui/Paper';
import { useTranslation } from 'react-i18next';
import { primary, successColor } from 'core/ui/Colors';
import Paginator from 'core/ui/Paginator';

const TableMessageListContacts = ({ open, close, remove, list }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const data = useSelector((state) => messageListsSelectors.getMessageListContacts(state));
  const pagination = useSelector((state) =>
    messageListsSelectors.getMessageListContactsPagination(state),
  );
  const loading = useSelector((state) => messageListsSelectors.getLoading(state));
  const [perPage] = useState(10);

  const getContacts = (p) => {
    dispatch(messageListsActions.getMessageListContactsStart(list.id, null, p, perPage));
  };

  useEffect(() => {
    if (list && list.id) {
      getContacts(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  const changePage = (p) => {
    if (p <= pagination.totalPages) {
      getContacts(p);
    }
  };

  const columnsUsers = [
    {
      name: 'Name',
      selector: 'name',
      sortable: true,
      cell: (contact) => <Typography ml={1}>{contact.name}</Typography>,
    },
    {
      name: 'Phone',
      selector: 'phone',
      sortable: true,
      cell: (contact) => (
        <Box display="flex" alignItems="center">
          <Typography ml={1}>
            {contact.allowSend ? (
              <Check style={{ fontSize: 15, color: successColor }} />
            ) : (
              <Check style={{ fontSize: 15, color: primary }} />
            )}
            &nbsp;{contact.phone}
          </Typography>
        </Box>
      ),
    },
    {
      name: '',
      center: true,
      cell: (contact) => (
        <IconButton onClick={() => remove(contact)}>
          <Typography>
            <RemoveCircle style={{ fontSize: 20 }} />
          </Typography>
        </IconButton>
      ),
    },
  ];

  const rows =
    data &&
    data.map((contact) => ({
      id: contact.id,
      name: contact.name,
      phone: contact.formattedPhone,
      messageListId: contact.messageListId,
      allowSend: contact.allowSend,
    }));

  return (
    <Dialog scroll="body" maxWidth="sm" fullWidth open={open} onClose={close}>
      <Texture>
        <Paper m={3} p={3}>
          {loading && (
            <Skeleton variant="rect" animation="wave" style={{ height: 200, width: '100%' }} />
          )}

          <SFDataTable
            title={t('admin.messageList.contacts')}
            columns={columnsUsers}
            data={rows}
            showExport={false}
            noHeader
            pagination={false}
          />

          <Box flexGrow={1} m={3} display="flex" justifyContent="center">
            <Paginator pagination={pagination} changePage={(p) => changePage(p)} />
          </Box>
        </Paper>
      </Texture>
    </Dialog>
  );
};

TableMessageListContacts.propTypes = {};

TableMessageListContacts.defaultProps = {};

export default TableMessageListContacts;
