import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from 'core/ui/Box';
import Button, { ButtonPrimary } from 'core/ui/Button';
import LoaderSm from 'core/ui/LoaderSm';
import { useTranslation } from 'react-i18next';
import Grid from 'core/ui/Grid';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import InputPhone from 'core/ui/InputPhone';
import { Controller } from 'react-hook-form';
import Image from 'components/Profile/Edit/Image';
import TextAreaFieldRounded from 'core/ui/TextAreaFieldRounded';
import Divider from 'core/ui/Divider';
import Typography from 'core/ui/Typography';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import { channelsStagesModule } from 'modules/channelsStages';
import useSelectors from 'modules/map/useSelectors';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import { redColor } from 'core/ui/Colors';
import TagsChosen from 'core/ui/TagsChosen';
import SelectorChannelUser from 'components/Channel/SelectorChannelUser';
import { Delete, Search } from '@material-ui/icons';
import Paper from 'core/ui/Paper';
import { channelsStagesUsersModule } from 'modules/channelsStagesUsers';
import useFormStageUser from './useForm';

const FormStageUsers = ({ close, stageId, callbackSave, channel, stageUser }) => {
  const [modalUser, setModalUser] = useState(false);
  const { t } = useTranslation();
  const loadingForm = useSelectors(channelsStagesUsersModule, 'loadingForm');
  const { submit, control, setUser } = useFormStageUser({
    stageUser,
    callbackSave,
    close,
    channelId: channel?.id,
    stageId,
  });

  const channelStages = useSelectors(channelsStagesModule, 'data');

  const staffs = [...(channel.coaches || []), ...(channel.admins || [])];

  return (
    <>
      <DialogTitle>
        <Typography variant="h5" mb={2}>
          {t('label.contactDetails')}
        </Typography>
      </DialogTitle>
      <Divider mb={5} />
      <DialogContent>
        <Dialog maxWidth="xs" fullWidth open={modalUser} onClose={() => setModalUser(false)}>
          <DialogContent>
            {!stageUser && (
              <div>
                <SelectorChannelUser
                  onSelectedUser={(u) => {
                    setUser(u);
                    setModalUser(false);
                  }}
                  channelId={channel.id}
                  showCreateButton={false}
                />
              </div>
            )}
          </DialogContent>
        </Dialog>

        <Grid container spacing={5}>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Box className="tw-h-40 tw-w-40 tw-rounded-full">
                <Controller
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Image image={value || {}} setImage={onChange} />
                  )}
                  name="user.image"
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <div className="tw-relative tw-flex tw-flex-col tw-gap-4">
              <div className="tw-flex tw-flex-row tw-items-end tw-gap-2">
                {!stageUser && (
                  <div>
                    <Button className="mt-1 tw-bg-gray-200" onClick={() => setModalUser(true)}>
                      <Search />
                    </Button>
                  </div>
                )}
                <div className="tw-grow">
                  <Controller
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <div>
                        <TextFieldRounded
                          error={error}
                          placeholder={t('profile.placeholder.name')}
                          label={t('profile.label.name').toUpperCase()}
                          required
                          onChange={onChange}
                          value={value}
                          bordered
                        />
                      </div>
                    )}
                    name="user.name"
                  />
                </div>
              </div>

              <Box>
                <Controller
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <InputPhone
                      name="phone"
                      error={error}
                      label={t('profile.label.mobilePhone').toUpperCase()}
                      setValue={(data) => {
                        onChange(data.number);
                      }}
                      value={value}
                      bordered
                    />
                  )}
                  name="user.phone"
                />
              </Box>
              <Box>
                <Controller
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextFieldRounded
                      name="email"
                      error={error}
                      placeholder={t('profile.placeholder.email')}
                      label={t('profile.label.email').toUpperCase()}
                      onChange={onChange}
                      value={value}
                      required
                      bordered
                    />
                  )}
                  name="user.email"
                />
              </Box>
            </div>
          </Grid>
        </Grid>

        <Divider my={5} />

        <Grid container spacing={5}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <div className="tw-flex tw-flex-col tw-gap-4">
              <Controller
                control={control}
                defaultValue={stageId}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <div>
                    <SelectBoxObject
                      options={channelStages}
                      placeholder={t('label.channel.stages')}
                      error={error}
                      label={t('label.channel.stages').toUpperCase()}
                      setValue={onChange}
                      value={value}
                      required
                      bordered
                    />
                  </div>
                )}
                name="channelsStageId"
              />

              <div>
                <Controller
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TagsChosen
                      label={t('competition.registration.label.tags').toUpperCase()}
                      placeholder={t('competition.registration.label.tags')}
                      setValue={(values) => onChange(values)}
                      limit={6}
                      kind="channels_stages_user"
                      val={value || []}
                      iconTag={
                        <Typography ml={1.5} variant="h6" style={{ color: 'white' }}>
                          #
                        </Typography>
                      }
                      bordered
                    />
                  )}
                  name="tags"
                />
              </div>

              <div>
                <Controller
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <div>
                      <SelectBoxObject
                        label={t('label.assignTo').toUpperCase()}
                        options={staffs?.map((x) => ({ id: x.userId, name: x?.user?.name }))}
                        setValue={(v) =>
                          onChange([
                            ...(value || []),
                            staffs?.find((x) => x.userId === Number(v))?.user,
                          ])
                        }
                        bordered
                      />

                      <Paper p={2} mt={2}>
                        <Typography variant="h6" mb={2}>
                          {t('label.assignedTo').toUpperCase()}
                        </Typography>
                        {value?.map((item) => (
                          <div key={item?.id} className="tw-flex tw-flex-row">
                            <div className="tw-grow">
                              <Typography>{item?.name}</Typography>
                            </div>

                            <IconButton
                              size="small"
                              onClick={() => {
                                onChange((value || []).filter((x) => x.id !== item?.id));
                              }}
                            >
                              <Delete />
                            </IconButton>
                          </div>
                        ))}
                      </Paper>
                    </div>
                  )}
                  name="staffs"
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Box mb={3}>
              <Controller
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextAreaFieldRounded
                    error={error}
                    placeholder={t('userStage.placeholder.description')}
                    label={t('label.description').toUpperCase()}
                    onChange={onChange}
                    value={value}
                    rows={10}
                    bordered
                  />
                )}
                name="description"
              />
            </Box>
          </Grid>
        </Grid>
      </DialogContent>

      <Divider mb={3} />
      <DialogActions>
        <ButtonPrimary
          type="button"
          buttonColor={redColor}
          onClick={() => close()}
          size="small"
          disabled={loadingForm}
          style={{ width: 100 }}
        >
          {t('button.close')}
        </ButtonPrimary>
        <ButtonPrimary
          type="button"
          onClick={() => submit()}
          size="small"
          disabled={loadingForm}
          style={{ width: 100 }}
        >
          <LoaderSm loading={loadingForm} width={20} />
          {t('button.save')}
        </ButtonPrimary>
      </DialogActions>
    </>
  );
};

FormStageUsers.propTypes = {
  close: PropTypes.func.isRequired,
};

FormStageUsers.defaultProps = {};

export default FormStageUsers;
