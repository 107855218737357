import React from 'react';
import PropTypes from 'prop-types';
import Box from 'core/ui/Box';
import { makeStyles, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import CardUser from 'components/Channel/SelectorChannelUser/Card';
import { ButtonPrimarySm } from 'core/ui/Button';
import { useTranslation } from 'react-i18next';

const ListUsers = ({ users, onSelectedUser, buttonColor }) => {
  const { t } = useTranslation();
  const useStyles = makeStyles((theme) => ({
    boxCoaches: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
  }));

  const classes = useStyles();

  return (
    <Box>
      {users && (
        <Box className={classes.boxCoaches} flexWrap="wrap" mb={5}>
          <Table aria-label="collapsible table" size="small">
            <TableHead>
              <TableRow>
                <TableCell>User</TableCell>
                <TableCell>ID</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user, index) => (
                <TableRow key={index.toString()}>
                  <TableCell>
                    <CardUser user={user} onClick={() => onSelectedUser(user)} />
                  </TableCell>
                  <TableCell>{user?.id}</TableCell>
                  <TableCell>
                    <ButtonPrimarySm onClick={() => onSelectedUser(user)} buttonColor={buttonColor}>
                      {t('button.select')}
                    </ButtonPrimarySm>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      )}
    </Box>
  );
};

ListUsers.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  users: PropTypes.array,
  onSelectedUser: PropTypes.func,
};

ListUsers.defaultProps = {
  users: [],
  onSelectedUser: () => {},
};

export default ListUsers;
