import { all, call, put } from 'redux-saga/effects';

import { getErrorMessage } from 'core/api/api-error';

import notifications from 'modules/notifications';

import * as api from './api';
import { paymentsActions as actions } from './index';

// eslint-disable-next-line import/prefer-default-export
export function* getPayments() {
  try {
    const response = yield call(api.payments);
    const payments = response.data;
    yield put(actions.getPaymentsSuccess(payments));
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.getPaymentsError(getErrorMessage(error))),
    ]);
  }
}

export function* purchaseChannelPlan({ data, callback }) {
  try {
    yield call(api.purchaseChannelPlan, data);
    yield all([put(actions.purchaseChannelPlanSuccess())]);
    if (callback) {
      callback();
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.purchaseChannelPlanError(getErrorMessage(error))),
    ]);
  }
}

export function* retryPayment({ data, callback }) {
  try {
    yield call(api.retryPayment, data);
    yield all([put(actions.retryPaymentSuccess())]);
    if (callback) {
      callback();
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.retryPaymentError(getErrorMessage(error))),
    ]);
  }
}

export function* purchaseChannelKey({ data }) {
  try {
    const resp = yield call(api.purchaseChannelKey, data);
    yield all([put(actions.purchaseChannelKeySuccess())]);
    if (data.callback) {
      data.callback(resp?.data);
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.purchaseChannelKeyError(getErrorMessage(error))),
    ]);
  }
}

export function* purchaseChannelList({ data }) {
  try {
    yield call(api.purchaseChannelList, data);
    yield all([put(actions.purchaseChannelListSuccess())]);
    if (data.callback) {
      data.callback();
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.purchaseChannelListError(getErrorMessage(error))),
    ]);
  }
}

export function* purchaseCoachScheduleSession({ data, callback }) {
  try {
    yield call(api.purchaseCoachScheduleSession, data);
    yield all([put(actions.purchaseCoachScheduleSessionSuccess())]);
    if (callback) {
      callback();
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.purchaseCoachScheduleSessionError(getErrorMessage(error))),
    ]);
  }
}

export function* purchaseChannelStoreProduct({ data, callback }) {
  try {
    const response = yield call(api.purchaseChannelStoreProduct, data);
    yield all([put(actions.purchaseChannelStoreProductSuccess())]);
    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.purchaseChannelStoreProductError(getErrorMessage(error))),
    ]);
  }
}

export function* purchaseWorkoutToMember({ data, callback, onError }) {
  try {
    yield call(api.purchaseWorkoutToMember, data);
    yield all([put(actions.purchaseWorkoutToMemberSuccess())]);
    if (callback) {
      callback();
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.purchaseWorkoutToMemberError(getErrorMessage(error))),
    ]);
    if (onError) {
      onError(getErrorMessage(error));
    }
  }
}
