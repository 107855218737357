import React, { useCallback, useEffect, useState } from 'react';
import { ButtonPrimary } from 'core/ui/Button';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import Typography from 'core/ui/Typography';

import {
  TableContainer,
  TableHead,
  Table,
  TableCell,
  TableRow,
  TableBody,
  Paper,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { channelsSelectors } from 'modules/channels';
import { Add } from '@material-ui/icons';
import LoaderSm from 'core/ui/LoaderSm';
import useSelectors from 'modules/map/useSelectors';
import { channelAnnouncementsModule } from 'modules/channelAnnouncements';
import useConfirmation from 'core/useConfirmation';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import ModalFormAnnouncements from './Form/Modal';
import ItemRow from './ItemRow';

const Announcements = () => {
  const { t } = useTranslation();
  const channel = useSelector((state) => channelsSelectors.getChannel(state));
  const loading = useSelectors(channelAnnouncementsModule, 'loading');
  const data = useSelectors(channelAnnouncementsModule, 'data');
  const { request } = useActions();
  const [modal, setModal] = useState({
    open: false,
  });
  const [modalWorkouts, setModalWorkouts] = useState({
    open: false,
  });

  const { confirmation } = useConfirmation();

  const getAnnouncements = useCallback(
    (channelId) => {
      request({
        action: Modules.channelAnnouncements.actions.getAnnouncements,
        data: { channelId },
      });
    },
    [request],
  );

  useEffect(() => {
    if (channel?.id) getAnnouncements(channel.id);
  }, [request, getAnnouncements, channel?.id]);

  useEffect(() => {
    if (modalWorkouts.list) {
      setModalWorkouts((prev) => ({
        ...prev,
        list: data.find((x) => x.id === modalWorkouts.list.id),
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const openModal = () => {
    setModal((prev) => ({ ...prev, mode: 'new', open: true }));
  };

  const onEditClick = (item) => {
    setModal((prev) => ({ ...prev, item, mode: item.id ? 'edit' : 'new', open: true }));
  };

  const onDestroyItem = (announcementId) => {
    confirmation({
      title: t('announcement.delete.title'),
      description: t('announcement.delete.description'),
      yesClick: () => {
        request({
          action: Modules.channelAnnouncements.actions.destroy,
          data: {
            channelId: channel.id,
            id: announcementId,
          },
          options: {
            onSuccess: () => getAnnouncements(channel.id),
          },
        });
      },
    });
  };

  return (
    <Box p={5}>
      {modal.open && (
        <ModalFormAnnouncements
          open={modal.open}
          item={modal.item}
          mode={modal.mode}
          close={() => setModal((prev) => ({ ...prev, open: false, item: {} }))}
          callback={(nameList) =>
            setModal((prev) => ({ ...prev, open: false, item: {}, nameListExpand: nameList }))
          }
        />
      )}

      <Box display="flex" alignItems="center">
        <Box flexGrow="1" display="flex" alignItems="center">
          <LoaderSm loading={loading} />
        </Box>
        <Box>
          <ButtonPrimary onClick={() => openModal()}>
            <Box display="flex" alignItems="flex-end">
              <Add size="small" />
              <Box alignItems="center">
                <Typography component="span" mr={1}>
                  {t('button.addAnnouncement')}
                </Typography>
              </Box>
            </Box>
          </ButtonPrimary>
        </Box>
      </Box>
      <Box mt={2}>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell />

                <TableCell>{t('announcement.label.text')}</TableCell>

                <TableCell align="center">{t('announcement.label.startAtDate')}</TableCell>

                <TableCell align="center">{t('announcement.label.endAtDate')}</TableCell>

                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data.map((row) => (
                  <ItemRow
                    channel={channel}
                    onEditClick={onEditClick}
                    onDestroyItem={() => onDestroyItem(row.id)}
                    key={row.id}
                    item={row}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default Announcements;
