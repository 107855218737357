/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Typography from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import { InputLabel } from '@material-ui/core';
import AWS from 'aws-sdk';
import uuid from 'react-uuid';
import LinearProgress from 'core/ui/LinearProgress';
import { ENV_APP } from 'core/env';
import Button, { ButtonPrimarySm } from 'core/ui/Button';
import Box from 'core/ui/Box';
import { Attachment } from '@material-ui/icons';
import { B_ID } from 'core/utils/consts';
import { mKY } from 'core/utils/helpers';
import Card from 'core/ui/Card';
import { primary } from 'core/ui/Colors';

const B_K = mKY();

const bucket = new AWS.S3({
  accessKeyId: B_ID,
  secretAccessKey: B_K,
  region: 'us-east-2',
});

const S3UploadAttachments = ({ objectId, objectType, setFiles, files }) => {
  const { t } = useTranslation();
  const [fileUpload, setFileUpload] = useState(null);
  const PATH_KEY = `uploads/${ENV_APP}/${objectType}/${objectId}/MessageAttachments/`;

  const [progressUpload, setProgressUpload] = useState(0);

  const uploadfile = (fileName, file) => {
    const params = {
      Bucket: 'stream-fitness-app-uploads',
      Key: PATH_KEY + fileName,
      Body: file,
      ContentType: file.type,
    };

    return bucket.upload(params, (err, data) => {
      if (err) {
        console.log('There was an error uploading your file: ', err);
        return false;
      }

      setProgressUpload(0);
      setFiles((prev) => [...prev, { name: file?.name, s3Url: data.Location }]);
      setFileUpload(null);
      return true;
    });
  };

  const startUpload = (file) => {
    const uniqueFileName = `streamfitness-channel-user-document-${uuid()}-${file.name}`;

    uploadfile(uniqueFileName, file).on('httpUploadProgress', (progress) => {
      const progressPercentage = Math.round((progress.loaded / progress.total) * 100);
      setProgressUpload(progressPercentage);
    });
  };

  const handleCapture = ({ target }) => {
    if (target.files.length) {
      for (let i = 0; i < target.files.length; i += 1) {
        const f = target.files[i];
        setFileUpload(f);
        startUpload(f);
      }
    }
  };

  const randomId = Math.floor(Math.random() * 101);

  return (
    <Card
      title="Attachments"
      action={
        <Box display="flex" justifyContent="center" p={1}>
          <ButtonPrimarySm variant="contained" p={1}>
            <InputLabel htmlFor={`icon-button-file-${randomId}`}>
              <Box display="flex" alignItems="center">
                <Attachment color="secondary" style={{ fontSize: 14 }} />
                <Typography ml={2} color="secondary" style={{ fontSize: 14 }}>
                  {(fileUpload && fileUpload.name) || t('button.add')}
                </Typography>
              </Box>
            </InputLabel>
          </ButtonPrimarySm>
          <input
            color="primary"
            type="file"
            onChange={handleCapture}
            id={`icon-button-file-${randomId}`}
            style={{ display: 'none' }}
            accept=".pdf"
            multiple
          />
        </Box>
      }
    >
      {fileUpload && (
        <>
          <Typography align="center" m={3} color="primary" variant="h4">
            {fileUpload.name}
          </Typography>

          <LinearProgress
            style={{ height: 15, width: '100%', borderRadius: 5 }}
            value={progressUpload}
          />
        </>
      )}
      <Box my={3}>
        {files && files.length > 0 && (
          <Box my={3}>
            <Box p={3}>
              {files &&
                files.map((f, index) => (
                  <Box key={index.toString()} display="flex" alignItems="center">
                    <Attachment style={{ fontSize: 16, color: primary }} />
                    <Typography ml={3}>{f.name}</Typography>
                  </Box>
                ))}
            </Box>

            <Box p={2} display="flex" justifyContent="center">
              <Button onClick={() => setFiles([])}>Clear Attachments</Button>
            </Box>
          </Box>
        )}
      </Box>
    </Card>
  );
};

export default S3UploadAttachments;
