import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import Radio from 'core/ui/Radio';
import Typography, { fontStyle } from 'core/ui/Typography';
import { Tooltip } from '@material-ui/core';
import { Info } from '@material-ui/icons';

export default ({ setValue, watch }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Box my={3} px={2}>
        <Typography style={fontStyle.label} mb={1}>
          {t('workout.label.freeClass').toUpperCase()}
        </Typography>
        <Box display="flex" alignItems="center">
          <Radio
            checked={watch('freeInPersonOption') === 'no'}
            label={t('button.no')}
            onClick={() => setValue('freeInPersonOption', 'no')}
          />
          <Box ml={3}>
            <Radio
              checked={watch('freeInPersonOption') === 'everyone'}
              label={
                <Tooltip title={t('workout.free.everyone.help')} placement="right-start">
                  <Box display="flex" alignItems="center">
                    <Typography>{t('button.everyone')}</Typography>
                    <Info />
                  </Box>
                </Tooltip>
              }
              onClick={() => setValue('freeInPersonOption', 'everyone')}
            />
          </Box>
          <Box ml={3}>
            <Radio
              checked={watch('freeInPersonOption') === 'non_members'}
              label={
                <Tooltip title={t('workout.free.non_members.help')} placement="right-start">
                  <Box display="flex" alignItems="center">
                    <Typography>{t('button.non_members')}</Typography>
                    <Info />
                  </Box>
                </Tooltip>
              }
              onClick={() => setValue('freeInPersonOption', 'non_members')}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
