import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Typography, { fontStyle } from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import { DialogContent, Dialog } from '@material-ui/core';
import { QRCodeCanvas } from 'qrcode.react';
import notifications from 'modules/notifications';
import { FileCopy } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import Texture from '../Texture';
import Box from '../Box';
import { ButtonPrimary } from '../Button';
import Paper from '../Paper';

export default function ModalQrCodeDownload({ open, close, text, title }) {
  const { t } = useTranslation();
  const canvasRef = useRef();
  const dispatch = useDispatch();
  const download = () => {
    const canvas = canvasRef.current.children[0]?.children[0];
    const pngFile = canvas.toDataURL('image/png');

    const downloadLink = document.createElement('a');
    downloadLink.download = 'QrCode';
    downloadLink.href = `${pngFile}`;
    downloadLink.click();
  };

  return (
    <Dialog open={!!open} maxWidth="xs" fullWidth onClose={close}>
      <Texture>
        <DialogContent>
          <Typography mb={3} align="center" color="primary" variant="h6">
            {title || 'QR CODE'}
          </Typography>

          <div ref={canvasRef} style={{ display: 'flex', justifyContent: 'center' }}>
            <div>{!!text && <QRCodeCanvas value={text} />}</div>
          </div>

          <Paper p={2}>
            <Box display="flex" justifyContent="center">
              <Typography style={fontStyle.small}>{text}</Typography>
            </Box>
            <Box display="flex" justifyContent="center" mt={2}>
              <Typography
                className="link-hover"
                component="span"
                align="center"
                style={{ fontSize: 11 }}
                noWrap
                onClick={() => {
                  navigator.clipboard.writeText(text);
                  dispatch(notifications.success(t('channel.store.link.copy')));
                }}
              >
                <FileCopy color="primary" size="small" style={{ fontSize: 10 }} />
                &nbsp; Copy Link
              </Typography>
            </Box>
          </Paper>

          <Box mt={3} display="flex" justifyContent="center">
            <ButtonPrimary onClick={() => download()}>{t('button.download')}</ButtonPrimary>
          </Box>
        </DialogContent>
      </Texture>
    </Dialog>
  );
}

ModalQrCodeDownload.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};
