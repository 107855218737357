import React, { useCallback, useContext, useMemo } from 'react';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import Box from 'core/ui/Box';
import { date, dateAdd } from 'core/utils/formatters';
import { secondary, secondaryXLight, secondaryXXLight } from 'core/ui/Colors';
import Button, { ButtonPrimary } from 'core/ui/Button';
import { PartersProgramsWorkoutsContext } from 'contexts';
import usePagination from 'core/useArrayPagination';
import SectionProgrammingWorkout from './SectionProgrammingWorkout';

const useStyles = makeStyles((theme) => ({
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  cardDay: {
    border: `0.5px solid ${secondaryXLight}`,
  },
  titleCardDay: {
    backgroundColor: secondaryXXLight,
    color: secondary,
    width: '100%',
    padding: 2,
  },
}));

const mountSections = (filteredWorkouts) => {
  let sections = [];
  filteredWorkouts?.forEach((x) => {
    sections = [...sections, ...(x.sections.map((y) => ({ ...y, workout: x })) || [])];
  });

  return sections;
};

const ProgramViewIndex = ({
  channel,
  workouts,
  calendarInfo,
  buttonColor,
  setModalProgram,
  indexDay,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { setSelectedWorkouts } = useContext(PartersProgramsWorkoutsContext);
  const filterDate = useCallback(
    (w) =>
      date(w.scheduledAt, channel?.dateFormatReact) ===
      date(dateAdd(calendarInfo?.start, indexDay, 'days'), channel?.dateFormatReact),
    [calendarInfo?.start, channel?.dateFormatReact, indexDay],
  );

  const filteredWorkouts = useMemo(
    () => workouts?.filter((x) => filterDate(x)),
    [filterDate, workouts],
  );

  const allSections = useMemo(() => mountSections(filteredWorkouts || []), [filteredWorkouts]);

  const { items, nextPage, totalPages, totalItems } = usePagination(allSections, 1, 4);

  const stylePaper = {
    backgroundColor: secondaryXXLight,
    border: `0.5px solid ${secondaryXLight}`,
  };

  return (
    <Box flexGrow={1} className={classes.cardDay}>
      <Typography align="center" className={classes.titleCardDay}>
        {date(
          dateAdd(calendarInfo?.start, indexDay, 'days'),
          channel?.useUsDateFormat ? 'ddd M/DD' : 'ddd DD/M',
        )}
      </Typography>

      {filteredWorkouts?.length > 0 && (
        <Box display="flex" mt={1} justifyContent="center" className="hover">
          <ButtonPrimary
            buttonColor={buttonColor}
            onClick={() => setSelectedWorkouts(filteredWorkouts)}
          >
            {t('button.select')}
          </ButtonPrimary>
        </Box>
      )}

      <Box p={1}>
        <Box style={stylePaper}>
          {items?.map((section) => (
            <Box key={section.id}>
              <SectionProgrammingWorkout
                buttonColor={buttonColor}
                workout={section?.workout}
                section={section}
                setModalProgram={setModalProgram}
              />
            </Box>
          ))}

          {totalPages > 0 && totalItems > items.length && (
            <div className="tw-flex tw-items-center tw-justify-center">
              <Button onClick={() => nextPage()}>
                {t('button.loadMore')}&nbsp;-&nbsp;
                {items.length}/{totalItems}
              </Button>
            </div>
          )}

          {/* {filteredWorkouts.length - 1 > idx && <Divider m={2} />} */}
        </Box>
      </Box>
    </Box>
  );
};

ProgramViewIndex.propTypes = {};

ProgramViewIndex.defaultProps = {
  close: () => {},
};

export default ProgramViewIndex;
