import React from 'react';
import MuiRating from '@material-ui/lab/Rating';
import { withStyles } from '@material-ui/core';
import { Star } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { primary } from '../Colors';

function Rating({
  name,
  value,
  size,
  precision,
  style,
  colorEmpty,
  setValue,
  readOnly,
  buttonColor,
}) {
  const StyledRating = withStyles({
    iconFilled: {
      color: buttonColor || primary,
    },
    iconHover: {
      color: buttonColor || primary,
    },
  })(MuiRating);
  return (
    <StyledRating
      emptyIcon={<Star style={{ color: colorEmpty || '#fff' }} fontSize="inherit" />}
      value={value}
      name={name || 'rating'}
      size={size}
      style={{ ...style }}
      precision={precision}
      readOnly={readOnly}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
    />
  );
}

Rating.propTypes = {
  value: PropTypes.number,
  size: PropTypes.string,
  name: PropTypes.string,
  colorEmpty: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  setValue: PropTypes.func,
  precision: PropTypes.number,
  readOnly: PropTypes.bool,
};

Rating.defaultProps = {
  value: 0,
  size: 'medium',
  name: '',
  style: {},
  colorEmpty: '#DCDCDC',
  setValue: () => {},
  precision: 0.1,
  readOnly: false,
};

export default Rating;
