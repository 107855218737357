import React, { useState } from 'react';
import propTypes from 'prop-types';
import Box from 'core/ui/Box';
import { SketchPicker } from 'react-color';
import { Dialog } from '@material-ui/core';
import { ButtonPrimary } from '../Button';
import Typography, { fontStyle } from '../Typography';
import useStylesColorPicker from './style';
import TextFieldRounded from '../TextFieldRounded';
import { secondary } from '../Colors';

export default function ColorPicker({ setColor, color, label, bordered }) {
  const classes = useStylesColorPicker();
  const [modal, setModal] = useState({
    open: false,
    fill: color,
  });

  const toggleModal = (value) => {
    setModal((prev) => ({ ...prev, fill: color, open: value }));
  };

  const confirmColor = () => {
    setColor(modal.fill);
    toggleModal(false);
  };

  return (
    <>
      <Box>
        <Typography style={fontStyle.label} mb={2} color="secondary">
          {label}
        </Typography>
        <Box display="flex">
          <Box className={classes.inputColor}>
            <TextFieldRounded
              value={color || ''}
              disabled
              onClick={() => toggleModal(true)}
              bordered={bordered}
            />
          </Box>
          <Box
            onClick={() => toggleModal(true)}
            className={classes.boxPreviewColor}
            style={{ backgroundColor: color, border: '2px solid black' }}
            mx={1}
          >
            &nbsp;
          </Box>
        </Box>
      </Box>
      <Dialog maxWidth="xs" open={modal.open} onClose={() => toggleModal(false)}>
        <Box display="flex" flexDirection="column" style={{ backgroundColor: secondary }} p={5}>
          <SketchPicker
            styles={{ backgroundColor: 'red' }}
            color={modal.fill || ''}
            onChangeComplete={({ hex }) => setModal((prev) => ({ ...prev, fill: hex }))}
          />
          <Box display="flex" mt={5}>
            <Box flexGrow={1} mr={3}>
              <ButtonPrimary
                fullWidth
                variant="contained"
                onClick={() => {
                  toggleModal(false);
                }}
              >
                Cancel
              </ButtonPrimary>
            </Box>
            <Box flexGrow={1} ml={3}>
              <ButtonPrimary fullWidth variant="contained" onClick={() => confirmColor()}>
                Save
              </ButtonPrimary>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </>
  );
}

ColorPicker.propTypes = {
  color: propTypes.string,
  setColor: propTypes.func.isRequired,
  label: propTypes.string,
};

ColorPicker.defaultProps = {
  label: '',
  color: '',
};
