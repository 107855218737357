import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import Typography, { fontStyle } from 'core/ui/Typography';
import Radio from 'core/ui/Radio';
import Checkbox from 'core/ui/Checkbox';
import Texture from 'core/ui/Texture';
import { ButtonPrimary } from 'core/ui/Button';
import DatePickerRounded from 'core/ui/DatePickers/DatePickerRounded';
import { channelCurrency, formatDateToApi, date, currency } from 'core/utils/formatters';
import { dateAdd, timeZoneName } from 'core/utils/formatters/date';
import Paper from 'core/ui/Paper';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import { Edit } from '@material-ui/icons';
import LoaderSm from 'core/ui/LoaderSm';
import { Chip } from '@material-ui/core';
import { warningColor } from 'core/ui/Colors';
import { PaymentOfflineTypes } from 'core/utils/consts';
import useFormKey from './useFormKey';
import FormNewChannelKey from './FormNew';

const ModalFormKey = ({ channelKey, open, user, close, changeMemberKey, mode, modeEdit }) => {
  const { t } = useTranslation();

  const {
    channel,
    setCurrentKey,
    currentSelected,
    currentKey,
    currentAmounts,
    loading,
    loadingMemberships,
    saveChannelKey,
  } = useFormKey({
    channelKey,
    user,
    changeMemberKey,
    mode,
  });

  return (
    <Dialog open={open} onClose={close} maxWidth="sm" fullWidth scroll="body">
      <Texture>
        <Typography pt={3} mb={3} align="center" variant="h3">
          {t(mode === 'edit' ? 'channel.label.editPlan.title' : 'channel.label.newMember.title')}
        </Typography>
        <Box px={5} py={3} flexGrow={1}>
          {mode === 'new' && (
            <FormNewChannelKey
              channel={channel}
              setCurrentKey={setCurrentKey}
              currentKey={currentKey}
              currentSelected={currentSelected}
            />
          )}
          <Box display="flex" justifyContent="space-between" mb={3}>
            {mode === 'new' && (
              <Box ml={1} display="flex">
                <Box>
                  <Paper p={3}>
                    <Box>
                      <Checkbox
                        label={t('channel.label.addKey')}
                        checked={!!currentKey.addInPersonKey}
                        onClick={() =>
                          setCurrentKey((prev) => ({
                            ...prev,
                            addInPersonKey: !prev?.addInPersonKey,
                          }))
                        }
                      />
                    </Box>
                    {channel?.hasHybrid && !!currentKey.addInPersonKey && (
                      <Box mt={2}>
                        <Checkbox
                          label={t('channel.addHybridToKey')}
                          checked={!!currentKey.inPersonHybridAccess}
                          onClick={() =>
                            setCurrentKey((prev) => ({
                              ...prev,
                              inPersonHybridAccess: !prev?.inPersonHybridAccess,
                            }))
                          }
                        />
                      </Box>
                    )}
                    {channel?.activePlans?.sifely?.active && !!currentKey.addInPersonKey && (
                      <Box mt={2}>
                        <Checkbox
                          label={t('channel.addSifelyToKey')}
                          checked={!!currentKey.inPersonSifelyAccess}
                          onClick={() =>
                            setCurrentKey((prev) => ({
                              ...prev,
                              inPersonSifelyAccess: !prev?.inPersonSifelyAccess,
                            }))
                          }
                        />
                      </Box>
                    )}
                  </Paper>
                </Box>
              </Box>
            )}
          </Box>

          {mode === 'edit' && (
            <>
              {modeEdit === 'editStatus' &&
                !currentSelected?.renewable &&
                currentSelected?.limitUse > 0 && (
                  <TextFieldRounded
                    type="number"
                    value={currentKey.limitClass}
                    pattern="[0-9]*"
                    placeholder={t('channel.placeholder.limitClass')}
                    label={`${t('channel.label.limitClass')}`}
                    onChange={(v) =>
                      setCurrentKey((prev) => ({ ...prev, limitClass: v >= 0 ? v : 0 }))
                    }
                  />
                )}
              {modeEdit === 'editAmount' && (
                <>
                  {(channelKey.isPostPayment || channelKey.membershipPlan.allowPostPayment) && (
                    <Paper className="tw-mb-2 tw-p-1">
                      <Checkbox
                        disabled={!channelKey.isPostPayment}
                        checked={currentKey.isPostPayment}
                        onClick={() =>
                          setCurrentKey((prev) => ({ ...prev, isPostPayment: !prev.isPostPayment }))
                        }
                        label="Pay later"
                      />
                    </Paper>
                  )}

                  {currentSelected &&
                    currentSelected?.coupons &&
                    currentSelected.coupons.length > 0 && (
                      <Box mb={3}>
                        <SelectBoxObject
                          label={t('channel.label.coupon')}
                          placeholder={t('channel.key.placeholder.coupon')}
                          emptyItem
                          emptyValue={null}
                          options={
                            currentSelected &&
                            currentSelected.coupons.map((x) => ({ id: x.id, code: x.code }))
                          }
                          propLabel="code"
                          propValue="id"
                          setValue={(v) =>
                            setCurrentKey((prev) => ({
                              ...prev,
                              channelMembershipCouponId: v,
                              chargeGymAmount: null,
                            }))
                          }
                          value={currentKey.channelMembershipCouponId || null}
                        />
                      </Box>
                    )}
                </>
              )}

              {channelKey.membership ? (
                <div>
                  {modeEdit === 'editRenewal' && (
                    <Box mb={3} className="tw-flex tw-flex-row tw-items-center tw-gap-2">
                      <Paper p={3}>
                        <Typography style={fontStyle.label} variant="body1">
                          {t('channel.label.renewable')}
                        </Typography>
                        <Box display="flex">
                          <Box mr={3}>
                            <Radio
                              label={t('button.yes')}
                              checked={!!currentKey.renewable}
                              onClick={() =>
                                setCurrentKey((prev) => ({ ...prev, renewable: true }))
                              }
                            />
                          </Box>
                          <Radio
                            label={t('button.no')}
                            checked={!currentKey.renewable}
                            onClick={() =>
                              setCurrentKey((prev) => ({
                                ...prev,
                                renewable: false,
                              }))
                            }
                          />
                        </Box>

                        {(currentKey.removeRenewableAt ||
                          (channelKey.id && channelKey.renewable && !currentKey.renewable)) && (
                          <Box>
                            <DatePickerRounded
                              placeholder="Today"
                              label={t('channel.label.executeOn')}
                              minDate={dateAdd(new Date(), 1, 'day')}
                              value={currentKey.removeRenewableAt}
                              usFormat={channel?.useUsDateFormat}
                              onChange={(v) =>
                                setCurrentKey((prev) => ({ ...prev, removeRenewableAt: v }))
                              }
                              bordered
                            />
                          </Box>
                        )}

                        {currentKey.removeRenewableAt && (
                          <Typography style={fontStyle.xSmall} color="primary">
                            This membership will be changed to Renewable=
                            {channelKey.renewable ? 'No' : 'Yes'}&nbsp;on&nbsp;
                            {date(currentKey.removeRenewableAt, channel?.dateFormatReact)}
                          </Typography>
                        )}
                      </Paper>
                      {!currentSelected?.online && (
                        <Paper p={3}>
                          <DatePickerRounded
                            label={t(
                              currentKey?.renewable
                                ? 'channel.label.renewAt'
                                : 'channel.label.expiryAt',
                            )}
                            minDate={dateAdd(new Date(), 1, 'day')}
                            value={currentKey.expiryAt}
                            usFormat={channel?.useUsDateFormat}
                            onChange={(v) => setCurrentKey((prev) => ({ ...prev, expiryAt: v }))}
                            bordered
                          />
                        </Paper>
                      )}

                      {currentKey.planExpiryAt && (
                        <Paper p={3}>
                          <DatePickerRounded
                            placeholder="Today"
                            label={t('channel.memberships.planExpiresIn')}
                            minDate={dateAdd(new Date(), 1, 'day')}
                            value={currentKey.planExpiryAt}
                            usFormat={channel?.useUsDateFormat}
                            onChange={(v) =>
                              setCurrentKey((prev) => ({ ...prev, planExpiryAt: v }))
                            }
                            bordered
                          />
                        </Paper>
                      )}
                    </Box>
                  )}
                  {modeEdit === 'editPause' && (
                    <Box display="flex" alignItems="center">
                      {!currentKey?.unpauseNow && currentKey?.paused && (
                        <Paper p={3} mx={3}>
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            flexDirection="column"
                          >
                            <Chip
                              style={{ background: warningColor }}
                              label={
                                <Typography style={{ color: 'white' }}>
                                  {channelKey?.pauseAt}
                                </Typography>
                              }
                            />
                            <Typography my={2} style={fontStyle.label} variant="body1">
                              {t('channel.label.paused')}
                            </Typography>
                            <ButtonPrimary
                              onClick={() =>
                                setCurrentKey((prev) => ({
                                  ...prev,
                                  pauseAt: prev?.pauseAt ? null : channelKey?.pauseAt,
                                  pauseStartAt: prev?.pauseStartAt
                                    ? null
                                    : channelKey?.pauseStartAt,
                                  pauseEndAt: prev?.pauseEndAt ? null : channelKey?.pauseEndAt,
                                  unpauseNow: !!prev?.pauseAt,
                                }))
                              }
                            >
                              {t(
                                currentKey?.pauseAt
                                  ? 'button.unpauseNow'
                                  : 'help.unpause.membership',
                              )}
                            </ButtonPrimary>
                          </Box>
                        </Paper>
                      )}

                      {!channelKey?.pauseAt &&
                        !currentKey?.unpauseNow &&
                        !currentKey?.pauseSchedule && (
                          <Paper p={3} mx={3}>
                            <Box display="flex">
                              <Typography style={fontStyle.label} variant="body1">
                                {t('channel.label.pauseNow')}
                              </Typography>
                              <Typography
                                ml={1}
                                style={fontStyle.label}
                                color="primary"
                                variant="body1"
                              >
                                {channelCurrency(
                                  channel?.membershipPauseFeeAmount,
                                  channel?.currency,
                                )}
                              </Typography>
                            </Box>
                            <Box display="flex">
                              <Box mr={3}>
                                <Radio
                                  label={t('button.yes')}
                                  checked={!!currentKey.pauseNow}
                                  onClick={() =>
                                    setCurrentKey((prev) => ({
                                      ...prev,
                                      pauseNow: true,
                                      pauseAt: formatDateToApi(
                                        channelKey.pauseAt || new Date(),
                                        timeZoneName(),
                                      ),
                                    }))
                                  }
                                />
                              </Box>
                              <Radio
                                label={t('button.no')}
                                checked={!currentKey.pauseNow}
                                onClick={() =>
                                  setCurrentKey((prev) => ({
                                    ...prev,
                                    pauseNow: false,
                                    pauseAt: null,
                                  }))
                                }
                              />
                            </Box>
                          </Paper>
                        )}

                      {!currentKey?.unpauseNow &&
                        !currentKey?.pauseSchedule &&
                        !currentKey?.unpauseNow &&
                        !currentKey?.pauseNow && <Typography>OR</Typography>}

                      {!currentKey?.unpauseNow && !currentKey?.pauseNow && (
                        <Paper p={3} mx={3}>
                          <Box display="flex">
                            <Typography style={fontStyle.label} variant="body1">
                              {t('channel.label.pauseSchedule')}
                            </Typography>
                            <Typography
                              ml={1}
                              style={fontStyle.label}
                              color="primary"
                              variant="body1"
                            >
                              {channelCurrency(
                                channel?.membershipPauseFeeAmount,
                                channel?.currency,
                              )}
                            </Typography>
                          </Box>
                          <Box display="flex">
                            <Box mr={3}>
                              <Radio
                                label={t('button.yes')}
                                checked={!!currentKey.pauseSchedule}
                                disabled={currentKey?.paused}
                                onClick={() =>
                                  setCurrentKey((prev) => ({ ...prev, pauseSchedule: true }))
                                }
                              />
                            </Box>
                            <Radio
                              label={t('button.no')}
                              disabled={currentKey?.paused}
                              checked={!currentKey.pauseSchedule}
                              onClick={() =>
                                setCurrentKey((prev) => ({
                                  ...prev,
                                  pauseSchedule: false,
                                  pauseStartAt: null,
                                  pauseEndAt: null,
                                }))
                              }
                            />
                          </Box>

                          {currentKey?.pauseSchedule && (
                            <Box mt={1} display="flex" alignItems="center">
                              {!currentKey.paused && (
                                <DatePickerRounded
                                  label={t('channel.label.pause.startAt')}
                                  value={currentKey.pauseStartAt}
                                  usFormat={channel?.useUsDateFormat}
                                  minDate={dateAdd(new Date(), 1, 'day')}
                                  onChange={(v) =>
                                    setCurrentKey((prev) => ({
                                      ...prev,
                                      pauseStartAt: v,
                                    }))
                                  }
                                  bordered
                                />
                              )}
                              <Box ml={1}>
                                <DatePickerRounded
                                  label={t('channel.label.pause.endAt')}
                                  value={currentKey.pauseEndAt}
                                  minDate={dateAdd(new Date(), 1, 'day')}
                                  usFormat={channel?.useUsDateFormat}
                                  onChange={(v) =>
                                    setCurrentKey((prev) => ({
                                      ...prev,
                                      pauseEndAt: v,
                                    }))
                                  }
                                  bordered
                                />
                              </Box>
                            </Box>
                          )}
                        </Paper>
                      )}

                      {currentKey?.unpauseNow && (
                        <Paper p={3}>
                          <Typography variant="h5" align="center">
                            {t('help.unpause.membership')}
                          </Typography>
                        </Paper>
                      )}
                    </Box>
                  )}

                  {modeEdit === 'editStatus' && (
                    <Box>
                      {!channelKey.pauseAt && (
                        <Paper p={3}>
                          <Typography style={fontStyle.label} variant="body1">
                            {t('channel.label.active')}
                          </Typography>
                          <Box display="flex">
                            <Box mr={3}>
                              <Radio
                                label={t('button.yes')}
                                checked={!!currentKey.active}
                                onClick={() =>
                                  setCurrentKey((prev) => ({
                                    ...prev,
                                    active: true,
                                    removeActiveAt: null,
                                  }))
                                }
                              />
                            </Box>
                            <Radio
                              label={t('button.no')}
                              checked={!currentKey.active}
                              onClick={() =>
                                setCurrentKey((prev) => ({
                                  ...prev,
                                  active: false,
                                  removeActiveAt: null,
                                }))
                              }
                            />
                          </Box>

                          {(currentKey.removeActiveAt ||
                            (channelKey.id && channelKey.active && !currentKey.active)) && (
                            <Box>
                              <DatePickerRounded
                                placeholder="Today"
                                label={t('channel.label.executeOn')}
                                value={currentKey.removeActiveAt}
                                usFormat={channel?.useUsDateFormat}
                                minDate={dateAdd(new Date(), 1, 'day')}
                                onChange={(v) =>
                                  setCurrentKey((prev) => ({ ...prev, removeActiveAt: v }))
                                }
                                bordered
                              />
                            </Box>
                          )}

                          {currentKey.removeActiveAt && (
                            <Typography style={fontStyle.xSmall} color="primary">
                              This membership will be changed to Active=
                              {channelKey.active ? 'No' : 'Yes'}&nbsp;on&nbsp;
                              {date(currentKey.removeActiveAt, channel?.dateFormatReact)}
                            </Typography>
                          )}
                        </Paper>
                      )}
                    </Box>
                  )}
                </div>
              ) : (
                <Box my={5} display="flex" justifyContent="center" flexWrap="wrap">
                  <DatePickerRounded
                    label={t('channel.label.plan.removeUserDate')}
                    placeholder="Today"
                    minDate={dateAdd(new Date(), 1, 'day')}
                    value={currentKey.removeUserAt}
                    usFormat={channel?.useUsDateFormat}
                    stylePaper={{ width: 200 }}
                    onChange={(v) => setCurrentKey((prev) => ({ ...prev, removeUserAt: v }))}
                    bordered
                  />

                  {channel?.hasHybrid && (
                    <Box m={5}>
                      <Checkbox
                        label={t('channel.hybridAccess')}
                        checked={!!currentKey.inPersonHybridAccess}
                        onClick={() =>
                          setCurrentKey((prev) => ({
                            ...prev,
                            inPersonHybridAccess: !prev?.inPersonHybridAccess,
                          }))
                        }
                      />
                    </Box>
                  )}
                  {channel?.activePlans?.sifely?.active && (
                    <Box mt={2}>
                      <Checkbox
                        label={t('channel.addSifelyToKey')}
                        checked={!!currentKey.inPersonSifelyAccess}
                        onClick={() =>
                          setCurrentKey((prev) => ({
                            ...prev,
                            inPersonSifelyAccess: !prev?.inPersonSifelyAccess,
                          }))
                        }
                      />
                    </Box>
                  )}
                </Box>
              )}
            </>
          )}

          {currentSelected?.hasContract && (mode === 'new' || modeEdit === 'editContract') && (
            <Box>
              <Paper p={3}>
                <Box display="flex" alignItems="center">
                  <Box>
                    <DatePickerRounded
                      placeholder="Date"
                      label={t('channel.membership.label.contractStartDate')}
                      value={currentKey.contractStartDate}
                      onChange={(v) => setCurrentKey((prev) => ({ ...prev, contractStartDate: v }))}
                      bordered
                      usFormat={channel?.useUsDateFormat}
                      disablePast={null}
                    />
                  </Box>
                  <Box ml={2}>
                    <DatePickerRounded
                      placeholder="Date"
                      label={t('channel.membership.label.contractEndDate')}
                      value={currentKey.contractEndDate}
                      onChange={(v) => setCurrentKey((prev) => ({ ...prev, contractEndDate: v }))}
                      bordered
                      usFormat={channel?.useUsDateFormat}
                      disablePast={null}
                    />
                  </Box>
                </Box>
              </Paper>
            </Box>
          )}

          {(mode === 'new' || modeEdit === 'editAmount') && (
            <>
              <Paper p={3} mb={3}>
                <Box>
                  <Checkbox
                    label={t('channel.membership.offline')}
                    checked={!!currentKey.offline}
                    onClick={() =>
                      setCurrentKey((prev) => ({
                        ...prev,
                        offline: !prev?.offline,
                      }))
                    }
                  />
                </Box>

                {currentKey?.offline && (
                  <Box m={3}>
                    <Typography mb={2} style={fontStyle.label}>
                      {t('channel.placeholder.offlinePayments.offlineType').toUpperCase()}
                    </Typography>
                    <Box display="flex">
                      {Object.keys(PaymentOfflineTypes).map((item) => (
                        <Radio
                          key={item}
                          checked={currentKey?.offlineType === item}
                          label={t(`channel.placeholder.offlinePayments.${item}`)}
                          onClick={() =>
                            setCurrentKey((prev) => ({
                              ...prev,
                              offlineType: item,
                            }))
                          }
                          ml={2}
                        />
                      ))}
                    </Box>
                  </Box>
                )}
              </Paper>

              {currentKey.showGymAmount && (
                <Box display="flex" alignItems="flex-end">
                  <TextFieldRounded
                    type="number"
                    value={currentKey.chargeGymAmount}
                    pattern="[0-9]*"
                    placeholder={t('channel.placeholder.membershipschargeGymAmount')}
                    label={t('channel.label.membershipschargeGymAmount')}
                    onChange={(v) =>
                      setCurrentKey((prev) => ({ ...prev, chargeGymAmount: v >= 0 ? v : 0 }))
                    }
                    required
                    currency
                    currencySymbol={channel?.currency?.symbol}
                    bordered
                  />
                  <Box ml={2}>
                    <ButtonPrimary
                      onClick={() =>
                        setCurrentKey((prev) => ({
                          ...prev,
                          showGymAmount: false,
                          chargeGymAmount: null,
                        }))
                      }
                    >
                      {t('button.default')}
                    </ButtonPrimary>
                  </Box>
                </Box>
              )}

              <Paper p={3} mb={3} style={{ minHeight: 110 }}>
                <Box display="flex" alignItems="center" flexDirection="column">
                  {loading ? (
                    <LoaderSm loading />
                  ) : (
                    <>
                      <Typography color="secondary" mb={1} style={{ ...fontStyle.large }}>
                        {t('channel.membership.chargeDate')}:&nbsp;
                        {date(currentAmounts.startAt, channel?.dateFormatReact)}
                      </Typography>

                      <Typography mb={1} style={fontStyle.label}>
                        {t('channel.membership.cost')}:{' '}
                        {channelCurrency(currentAmounts?.nextCostWithoutTax, channel?.currency)}
                      </Typography>

                      <Typography mb={1} color="secondary" style={fontStyle.label}>
                        {t('channel.membership.taxAmount')}:{' '}
                        {channelCurrency(currentAmounts?.taxCost, channel?.currency)}
                      </Typography>

                      <Typography mb={1} color="primary" style={fontStyle.label}>
                        {t(
                          currentAmounts?.items?.length > 1
                            ? 'channel.membership.costNextTransactions'
                            : 'channel.membership.costNextRenew',
                        )}
                        : {channelCurrency(currentAmounts?.nextCost, channel?.currency)}
                      </Typography>

                      {currentAmounts?.items?.length > 1 && (
                        <>
                          <Typography>Charges: {currentAmounts?.items?.length}</Typography>
                          {currentAmounts?.items?.map((charge, index) => (
                            <Box key={index} display="flex">
                              <Typography mb={1} color="primary" style={fontStyle.label}>
                                {date(charge.startAt, channel?.dateFormatReact)}:{' '}
                                {channelCurrency(charge?.nextCost, channel?.currency)}
                              </Typography>
                            </Box>
                          ))}
                        </>
                      )}

                      {!currentKey.showGymAmount && (
                        <ButtonPrimary
                          onClick={() =>
                            setCurrentKey((prev) => ({
                              ...prev,
                              showGymAmount: true,
                              chargeGymAmount: currency(currentAmounts?.nextCostWithoutTax),
                            }))
                          }
                        >
                          <Box display="flex" alignItems="center">
                            <Edit fontSize="small" style={{ fontSize: 12 }} />
                            <Typography style={{ ...fontStyle.small }}>
                              {t('button.changeAmount')}
                            </Typography>
                          </Box>
                        </ButtonPrimary>
                      )}
                    </>
                  )}
                </Box>
              </Paper>
            </>
          )}

          <Box mt={3}>
            <ButtonPrimary disabled={loadingMemberships} fullWidth onClick={() => saveChannelKey()}>
              {t('button.save')}
            </ButtonPrimary>
          </Box>
        </Box>
      </Texture>
    </Dialog>
  );
};

ModalFormKey.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  channelKey: PropTypes.object,
  mode: PropTypes.string,
  open: PropTypes.bool,
  close: PropTypes.func,
  changeMemberKey: PropTypes.func,
};

ModalFormKey.defaultProps = {
  channelKey: {},
  user: {},
  open: false,
  close: () => {},
  changeMemberKey: () => {},
  mode: 'edit',
};

export default ModalFormKey;
