import React, { useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';

import MuiDialogContent from '@material-ui/core/DialogContent';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import { ButtonPrimary } from 'core/ui/Button';
import { useDispatch, useSelector } from 'react-redux';
import propTypes from 'prop-types';
import notifications from 'modules/notifications';
import DialogTitle from 'core/ui/Dialog/DialogTitle';
import Typography from 'core/ui/Typography';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import Paper from 'core/ui/Paper';
import { workoutsActions, workoutsSelectors } from 'modules/workouts';
import DatePickerRounded from 'core/ui/DatePickers/DatePickerRounded';
import TimePickerRounded from 'core/ui/DatePickers/TimePickerRounded';
import { timeZoneName, formatDateToApi } from 'core/utils/formatters/date';
import LoaderSm from 'core/ui/LoaderSm';
import { getVideoTypeByLink } from 'core/utils/helpers';
import SelectorUser from 'components/Profile/SelectorUser';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#fff3f3f3fff',
  },
}));

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(5),
  },
}))(MuiDialogContent);

export default function ModalRecordingInvite({ workout, open, close }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const loadingInvite = useSelector((state) =>
    workoutsSelectors.getLoadingWorkoutRecordingInvites(state),
  );
  const dispatch = useDispatch();
  const [invites, setInvites] = useState([]);
  const [data, setData] = useState({});

  const addUser = (user) => {
    setInvites((prev) => [
      ...prev,
      {
        userId: user?.id,
        user,
      },
    ]);
  };

  const validate = () => {
    if (!data.scheduledAtDate || !data.scheduledAtHour) {
      dispatch(notifications.warning(t('workout.validation.scheduleAt')));
      return false;
    }

    return true;
  };

  const saveInvites = () => {
    if (!validate()) {
      return;
    }
    const timezone = timeZoneName();
    const scheduledAt = formatDateToApi(
      `${data.scheduledAtDate.substr(0, 10)} ${data.scheduledAtHour.substr(11, 8)}`,
      timezone,
    );

    dispatch(
      workoutsActions.postWorkoutRecordingInvitesStart(
        {
          workoutId: workout.id,
          scheduledAt,
          inviteType: 'recording_watch',
          invites: invites.map((x) => ({ userId: x.userId })),
        },
        () => {
          dispatch(notifications.success(t('workout.invite.recording.success')));
          dispatch(workoutsActions.getWorkoutStart(workout.id));
          close();
        },
      ),
    );
  };

  const videoLink = workout.videoLink ? getVideoTypeByLink(workout.videoLink) : null;
  const showMessageApp = videoLink && ['youtube', 'vimeo'].find((x) => x === videoLink.type);
  const removeNew = (row) => {
    setInvites((prev) => prev.filter((x) => x !== row));
  };

  return (
    <div>
      <Dialog fullWidth maxWidth="sm" scroll="body" onClose={() => close()} open={open}>
        <div className={classes.root}>
          <DialogTitle onClose={() => close()}>{t('workout.invite.recording')}</DialogTitle>
          <DialogContent dividers>
            {showMessageApp && (
              <Typography mb={5} mx={3} variant="h5" color="primary">
                {t('workout.videoLink.alertApp').toUpperCase()}
              </Typography>
            )}
            <Box display="flex" alignItems="center">
              <Box m={1}>
                <DatePickerRounded
                  name="scheduledAt"
                  type="datetime-local"
                  label={t('workout.label.scheduledAtDate').toUpperCase()}
                  onChange={(v) => setData((prev) => ({ ...prev, scheduledAtDate: v }))}
                  value={data.scheduledAtDate}
                  required
                />
              </Box>

              <Box m={1}>
                <TimePickerRounded
                  name="scheduledAt"
                  type="datetime-local"
                  label={t('workout.label.scheduledAtTime').toUpperCase()}
                  onChange={(v) => setData((prev) => ({ ...prev, scheduledAtHour: v }))}
                  value={data.scheduledAtHour}
                  required
                />
              </Box>
            </Box>

            <Box>
              <SelectorUser onSelectedUser={(u) => addUser(u)} />
            </Box>

            <Box my={2}>
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>User</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {invites &&
                      invites.map((row, index) => (
                        <TableRow key={index.toString()}>
                          <TableCell>{row?.user?.name}</TableCell>
                          <TableCell>
                            {!row.id && (
                              <IconButton onClick={() => removeNew(row)}>
                                <HighlightOffIcon />
                              </IconButton>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            {invites && invites.length > 0 && (
              <Box display="flex" px={5} mt={5} justifyContent="flex-end">
                <ButtonPrimary disabled={loadingInvite} onClick={() => saveInvites()} fullWidth>
                  <LoaderSm loading={loadingInvite} />
                  {t('button.save')}
                </ButtonPrimary>
              </Box>
            )}
          </DialogContent>
        </div>
      </Dialog>
    </div>
  );
}

ModalRecordingInvite.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  open: propTypes.bool,
  close: propTypes.func,
};

ModalRecordingInvite.defaultProps = {
  open: false,
  close: null,
};
