/* eslint-disable react/prop-types */
import Box from 'core/ui/Box';
import React from 'react';

import { useTranslation } from 'react-i18next';
import ByMembershipData from './ByMembershipData';

export default function ByPercentMembership({ channel, byPercentMembership }) {
  const { t } = useTranslation();
  return (
    <Box>
      <ByMembershipData
        title={t('channel.rateTemplate.kind.percent_by_membership')}
        channel={channel}
        byPercentMembership={byPercentMembership}
      />
    </Box>
  );
}
