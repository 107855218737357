/* eslint-disable react/prop-types */
import React, { useState } from 'react';

import Typography, { fontStyle } from 'core/ui/Typography';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import Paper from 'core/ui/Paper';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  withStyles,
  TableCell,
  Collapse,
} from '@material-ui/core';
import { primary, secondary, secondaryLight } from 'core/ui/Colors';
import { KeyboardArrowDown, KeyboardArrowRight } from '@material-ui/icons';
import FontIcon from 'core/ui/FontIcon';
import { gymDate, dateAdd, diffFromNow, time } from 'core/utils/formatters';
import LiveIcon from 'core/ui/LiveIcon';
import TimerToStart from 'components/Workout/Grid/TimerToStart';
import { workoutInviteTypes } from 'core/utils/consts';
import ButtonActions from './ButtonActions';

const styleIcon = {
  color: secondary,
  fontSize: 20,
};

export default ({ workout, showStartJoinButton, buttonColor }) => {
  const { t } = useTranslation();

  const isShareScores = !!workout?.invites?.find(
    (x) => x.inviteType === workoutInviteTypes.share_scores,
  );
  const groupInvites = () => {
    const grouped = {};
    if (workout.invites) {
      if (isShareScores) {
        grouped[workout?.user?.id] = {
          id: workout?.user?.id,
          mainUser: workout?.user,
          items: [...workout?.invites],
        };

        return grouped;
      }

      workout.invites.forEach((x) => {
        const key = `${x?.mainUser?.id}-${x.scheduledAt}-${x.inviteType}`;
        if (!grouped[key]) {
          grouped[key] = {
            id: x.id,
            scheduledAt: x.scheduledAt,
            mainUser: x.mainUser,
            inviteType: x.inviteType,
            live: x.live,
            items: [...workout?.invites.filter((y) => y?.mainUser?.id === x?.mainUser?.id)],
          };
        }
        grouped[key].items.push(x);
      });
    }

    return grouped;
  };

  const groupedInvites = groupInvites();
  const [open, setOpen] = useState(true);

  const showTimeToStart = (invite) => {
    const timeToStart = workout ? diffFromNow(invite.scheduledAt) : 0;

    return timeToStart > 0 && timeToStart < 3600;
  };

  const recordCompare = workout.invites?.find(
    (x) => x.inviteType === workoutInviteTypes.record_compare,
  );

  return (
    <Box mb={3}>
      <Paper elevation={2} className="paper-rounded" p={3}>
        <Box display="flex" alignItems="center">
          <Box
            flexGrow="1"
            display="flex"
            alignItems="center"
            className="link"
            onClick={() => setOpen(!open)}
          >
            <FontIcon
              iconName="icon-classes"
              style={{ color: buttonColor || primary, fontSize: 16 }}
            />
            <Typography ml={2} style={{ color: buttonColor || primary }} variant="h5">
              {t('workout.invites').toUpperCase()}
            </Typography>
          </Box>

          <Box className="link" onClick={() => setOpen(!open)}>
            {open === true ? (
              <KeyboardArrowDown style={{ ...styleIcon }} />
            ) : (
              <KeyboardArrowRight style={{ ...styleIcon }} />
            )}
          </Box>
        </Box>
        <Collapse in={open}>
          <Box mt={5}>
            {groupedInvites &&
              Object.keys(groupedInvites).map((key) => {
                const invite = groupedInvites[key];

                return (
                  <Box
                    mb={5}
                    p={1}
                    key={invite.id}
                    style={{ border: `0.5px solid ${secondaryLight}`, borderRadius: 5 }}
                  >
                    <Box py={2} display="flex" alignItems="center">
                      <Box flexGrow={1}>
                        <Typography style={fontStyle.small} ml={1} color="secondary">
                          {invite.mainUser.name}
                        </Typography>
                      </Box>

                      <Box display="flex" alignItems="center" flexGrow={1}>
                        <Box display="flex" flexDirection="row">
                          <Typography style={fontStyle.medium} ml={1} color="textSecondary">
                            <span>{gymDate(invite.scheduledAt, 'MMM DD, YYYY')}</span>
                            <span>&nbsp;&bull;&nbsp;</span>
                            <span className="roboto-regular">{time(invite.scheduledAt)}</span>
                          </Typography>
                        </Box>
                        <Box ml={5}>{!recordCompare && invite.live && <LiveIcon />}</Box>
                      </Box>
                      <Box display="flex" alignItems="center">
                        {!recordCompare && (
                          <ButtonActions
                            invite={invite}
                            showStartJoinButton={showStartJoinButton}
                            workout={workout}
                          />
                        )}

                        {!recordCompare &&
                          !invite.live &&
                          invite.scheduledAt &&
                          !!showTimeToStart(invite) && (
                            <Box mx={1}>
                              <TimerToStart
                                label={t('workout.invite.startIn')}
                                workout={{
                                  method: 'custom',
                                  publishAt: dateAdd(invite.scheduledAt, -15, 'minutes'),
                                }}
                              />
                            </Box>
                          )}
                      </Box>
                    </Box>

                    <Box mt={3}>
                      <TableContainer component={Paper}>
                        <Table size="small" aria-label="customized table">
                          <TableHead>
                            <TableRow>
                              <StyledTableCell />
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {invite.items.map((item, index) => (
                              <StyledTableRow key={index.toString()}>
                                <StyledTableCell component="th" scope="row">
                                  <Typography style={fontStyle.small} ml={1} color="secondary">
                                    {item.user && <>{item.user.name}</>}

                                    {!item.user && <>{item.email || item.phone}</>}
                                  </Typography>
                                </StyledTableCell>
                              </StyledTableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  </Box>
                );
              })}
          </Box>
        </Collapse>
      </Paper>
    </Box>
  );
};

const StyledTableCell = withStyles(() => ({
  head: {
    // backgroundColor: theme.palette.common.black,
    color: '#4fb94a',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);
