import React, { useState } from 'react';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import Checkbox from 'core/ui/Checkbox';
import { useSelector } from 'react-redux';
import { competitionsSelectors } from 'modules/competitions';
import Typography from 'core/ui/Typography';
import Paper from 'core/ui/Paper';
import { InputWrapper } from 'core/ui/ChosenComponents';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import LoaderSm from 'core/ui/LoaderSm';
import useActions from 'modules/map/useActions';
import { competitionsUsersModule } from 'modules/competitionsUsers';
import useSelectors from 'modules/map/useSelectors';
import Modules from 'modules';
import { ButtonPrimary } from 'core/ui/Button';
import Paginator from 'core/ui/Paginator';
import Divider from 'core/ui/Divider';

const CompetitionsUsersSelector = ({ textService }) => {
  const { t } = useTranslation();
  const competition = useSelector((state) => competitionsSelectors.getCompetition(state));
  const { request } = useActions();
  const [users, setUsers] = useState(null);
  const [pagination, setPagination] = useState(null);
  const loading = useSelectors(competitionsUsersModule, 'loadingUsers');

  const [query, setQuery] = useState('');

  const isTypeChecked = (type, field) => {
    const arr = textService.watch(field) ? [...textService.watch(field)] : [];

    if (field === 'competitionsUsers') {
      if (arr.find((x) => x?.id === type?.id)) {
        return true;
      }

      return false;
    }

    if (arr.find((x) => x === type)) {
      return true;
    }

    return false;
  };

  const changeMemberType = (type, field) => {
    let arr = textService.watch(field) ? [...textService.watch(field)] : [];

    if (isTypeChecked(type, field)) {
      if (field === 'competitionsUsers') {
        arr = arr.filter((x) => x?.id !== type?.id);
      } else {
        arr = arr.filter((x) => x !== type);
      }
    } else {
      if (type === 'All') {
        textService.setValue(field, [type]);
        return;
      }
      arr.push(type);
    }
    textService.setValue(field, arr);
  };

  const search = (page = 1) => {
    request({
      action: Modules.competitionsUsers.actions.getCompetitionsUser,
      data: {
        competitionId: competition?.id,
        regType: 'competitor',
        page,
        perPage: 30,
        groupByUser: true,
        query,
      },
      options: {
        onSuccess: (resp, pag) => {
          setUsers(resp);
          setPagination(pag);
        },
      },
    });
  };

  return (
    <>
      <Paper p={3} mb={2}>
        <Typography mb={2}>{t('categories')}</Typography>
        <Box>
          <Typography variant="body2" mb={2}>
            {t('competition.label.vendorsCategories')}
          </Typography>
          {competition.vendorsCategories.map((item) => (
            <Box key={item.id} m={1}>
              <Checkbox
                checked={isTypeChecked(item?.id, 'competitionsCategories')}
                onClick={() => changeMemberType(item?.id, 'competitionsCategories')}
                label={item.name}
                color={competition?.buttonColor}
              />
            </Box>
          ))}

          <Typography variant="body2" mb={2} mt={2}>
            {t('competition.label.volunteersCategories')}
          </Typography>
          {competition.volunteersCategories.map((item) => (
            <Box key={item.id} m={1}>
              <Checkbox
                checked={isTypeChecked(item?.id, 'competitionsCategories')}
                onClick={() => changeMemberType(item?.id, 'competitionsCategories')}
                label={item.name}
                color={competition?.buttonColor}
              />
            </Box>
          ))}
        </Box>
      </Paper>

      <Paper p={3} mb={2}>
        <Typography mb={2}>{t('competition.divisions')}</Typography>
        {competition.divisions.map((item) => (
          <Box key={item.id} m={1}>
            <Checkbox
              checked={isTypeChecked(item?.id, 'competitionsDivisions')}
              onClick={() => changeMemberType(item?.id, 'competitionsDivisions')}
              label={item.name}
              color={competition?.buttonColor}
            />
          </Box>
        ))}
      </Paper>

      {competition?.allowWaitlist && (
        <Paper p={3} mb={2}>
          <Box m={1}>
            <Checkbox
              checked={isTypeChecked('All', 'competitionsWaitlist')}
              onClick={() => changeMemberType('All', 'competitionsWaitlist')}
              label={t('competition.waitlists')}
              color={competition?.buttonColor}
            />
          </Box>
        </Paper>
      )}

      <Paper p={3} mb={2}>
        <Typography mb={2}>{t('competition.athletes')}</Typography>

        <Box m={1}>
          <Checkbox
            checked={isTypeChecked('All', 'competitionsUsers')}
            onClick={() => changeMemberType('All', 'competitionsUsers')}
            label="All Athletes"
            color={competition?.buttonColor}
          />
        </Box>

        {!isTypeChecked('All', 'competitionsUsers') && (
          <>
            <Divider m={1} />

            <Box mb={3}>
              <Box display="flex" alignItems="center">
                <InputWrapper>
                  <Box display="flex" style={{ width: '100%' }}>
                    <TextFieldRounded
                      value={query}
                      onChange={(value) => setQuery(value)}
                      style={{ minWidth: 30, width: '100%', margin: '4px' }}
                      placeholder={t('channel.members.search')}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          search();
                        }
                      }}
                    />
                  </Box>
                </InputWrapper>
                <Box display="flex" justifyContent="center">
                  <LoaderSm loading={loading} />
                </Box>
                <Box ml={1}>
                  <ButtonPrimary disabled={loading} onClick={() => search()}>
                    {t('button.search')}
                  </ButtonPrimary>
                </Box>
              </Box>
            </Box>
            <Box className="gap-5">
              {users?.map((item) => (
                <Paper
                  key={item?.user?.id}
                  p={2}
                  onClick={() => changeMemberType(item?.user, 'competitionsUsers')}
                  className="hover"
                >
                  <Box display="flex" className="gap-10">
                    <Box>
                      <Checkbox
                        checked={isTypeChecked(item?.user, 'competitionsUsers')}
                        label={item.name}
                        color={competition?.buttonColor}
                      />
                    </Box>
                    <Box>
                      <Typography>{item?.user?.name}</Typography>
                      <Typography>{item?.team}</Typography>
                    </Box>
                  </Box>
                </Paper>
              ))}
            </Box>

            {pagination && (
              <Box flexGrow={1} m={3} display="flex" justifyContent="center">
                <Paginator pagination={pagination} changePage={(p) => search(p)} />
              </Box>
            )}
          </>
        )}
      </Paper>

      <Paper p={2}>
        {(textService.watch('competitionsUsers') || []).map((item) => (
          <Box key={item?.id} m={1} display="flex" className="gap-10">
            <Checkbox
              checked={isTypeChecked(item, 'competitionsUsers')}
              label={item.name}
              color={competition?.buttonColor}
            />
          </Box>
        ))}
      </Paper>
    </>
  );
};

export default CompetitionsUsersSelector;
