/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import Button, { ButtonPrimary } from 'core/ui/Button';
import SFDataTable from 'core/ui/DataTable';
import { Delete, Edit } from '@material-ui/icons';
import useConfirmation from 'core/useConfirmation';
import Typography from 'core/ui/Typography';
import ModalFormMessage from './Form/Modal';
import { objectableTypeTypes } from './Form/FormSend';

export default ({ competition, watch, setValue }) => {
  const { t } = useTranslation();
  const [modalMessage, setModalMessage] = useState({ open: false });
  const { confirmation } = useConfirmation();

  const emailTemplates = watch('messages') || [];

  const saveMessage = (data) => {
    setValue('messages', [
      ...emailTemplates.filter((x) => (x.id ? x.id !== data?.id : x.uuid !== data?.uuid)),
      data,
    ]);

    setModalMessage({ open: false });
  };

  const removeMessage = (data) => {
    confirmation({
      description: 'Are you sure?',
      yesClick: () => {
        if (data?.id) {
          saveMessage({ ...data, _destroy: true });
        } else {
          setValue('messages', [
            ...emailTemplates.filter((x) => (x.id ? x.id !== data?.id : x.uuid !== data?.uuid)),
          ]);
        }
      },
    });
  };

  const columns = [
    {
      name: 'Subject',
      selector: 'subject',
      sortable: false,
      cell: (row) => <Box>{row.subject}</Box>,
    },
    {
      name: 'Category',
      selector: 'objectId',
      sortable: false,
      cell: (row) => (
        <Box>
          <Typography color="primary">
            {objectableTypeTypes.find((x) => x.value === row?.objectableType)?.label}
          </Typography>
          <Typography color="textSecondary">{row.objectable?.name}</Typography>
        </Box>
      ),
    },
    {
      name: '',
      cell: (row) => (
        <Box display="flex" alignItems="center" className="gap-10">
          <Button onClick={() => setModalMessage({ open: true, item: row })}>
            <Edit />
          </Button>
          <Button onClick={() => removeMessage(row)}>
            <Delete />
          </Button>
        </Box>
      ),
    },
  ];

  return (
    <Box p={3}>
      <Box display="flex" alignItems="center" pb={3} pt={2}>
        <Box flexGrow="1">
          <Typography variant="h5" mb={3} color="primary">
            {t('label.emailTemplates')}
          </Typography>
        </Box>
        <Box p={1}>
          <ButtonPrimary onClick={() => setModalMessage({ open: true })}>
            {t('button.createEmailTemplate')}
          </ButtonPrimary>
        </Box>
      </Box>

      {modalMessage?.open && (
        <ModalFormMessage
          open={modalMessage?.open}
          close={() => setModalMessage({ open: false })}
          competition={competition}
          item={modalMessage?.item}
          callback={(data) => saveMessage(data)}
          automated
        />
      )}

      <Box my={3}>
        {/* <Messages channel={channel} template /> */}

        {emailTemplates?.filter((x) => !x._destroy)?.length > 0 && (
          <SFDataTable
            noDataComponent={null}
            columns={columns}
            data={emailTemplates?.filter((x) => !x._destroy)}
            noHeader
            pagination={false}
            showExport
          />
        )}
      </Box>
    </Box>
  );
};
