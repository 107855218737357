import React, { useEffect, useState } from 'react';
import Box from 'core/ui/Box';
import PropTypes from 'prop-types';
import { colors, makeStyles, Tooltip } from '@material-ui/core';
import Typography from 'core/ui/Typography';
// import ImageMedia from './ImageMedia';

const AvatarCoach = ({ user, size, buttonColor }) => {
  const [imageError, setImageError] = useState(false);

  useEffect(() => {
    setImageError(false);
  }, []);
  const useStyles = makeStyles({
    boxAvatarCoach: {
      overflow: 'hidden',
      borderRadius: 50,
      backgroundColor: colors.grey[500],
      width: size,
      height: size,
      minHeight: size,
      minWidth: size,
      transition: 'transform .2s',
      border: `2px solid ${buttonColor}`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '&:hover': {
        cursor: 'pointer',
        zIndex: '999 !important',
        transform: 'scale(1.15)',
      },
    },
  });
  const classes = useStyles();
  return (
    <Tooltip title={user.name || ''} placement="bottom">
      <Box className={classes.boxAvatarCoach}>
        {!imageError && user.image?.image?.url ? (
          <img
            style={{ width: size, height: size }}
            alt={user?.name}
            src={user.image?.image?.url}
            onError={() => setImageError(true)}
          />
        ) : (
          <Typography align="center" style={{ fontSize: size * 0.5, color: 'white' }}>
            {user.initials}
          </Typography>
        )}

        {/* <ImageMedia
          showCountry={false}
          user={user}
          image={user && user.image ? user.image : {}}
          size={size}
        /> */}
      </Box>
    </Tooltip>
  );
};

AvatarCoach.propTypes = {
  size: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.object,
};

AvatarCoach.defaultProps = {
  size: 50,
  user: {},
};

export default AvatarCoach;
