import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import Button from 'core/ui/Button';
import { ButtonGroup, Popover, useTheme } from '@material-ui/core';
import { dayWeekFilters } from 'core/utils/consts';
import { primary, secondary } from 'core/ui/Colors';
import Typography, { fontStyle } from 'core/ui/Typography';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import Paper from 'core/ui/Paper';

const WeekDaysCheck = ({ weekDays, setWeekDays, label, hideEvery }) => {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const [element, setElement] = useState(null);

  const isChecked = (day) => !!weekDays.find((x) => x?.day === day);

  const checkedStyle = {
    backgroundColor: palette.primary.main,
    color: secondary,
  };

  const style = {
    backgroundColor: secondary,
    color: primary,
  };

  const handleClick = (event, x, checked) => {
    if (!hideEvery && !checked) {
      setElement({ target: event.currentTarget, day: { ...x, every: 1 } });
    }
    setWeekDays(
      checked ? weekDays?.filter((y) => y?.day !== x.id) : [...weekDays, { day: x.id, every: 1 }],
    );
  };

  const changeEvery = (v) => {
    setWeekDays(
      weekDays?.map((x) => {
        if (x.day === element?.day?.id) {
          return {
            ...x,
            every: v,
          };
        }

        return x;
      }),
    );

    setElement((prev) => ({ ...prev, day: { ...element?.day, every: v } }));
  };

  const handleClose = () => {
    setElement(null);
  };

  const every = (day) => weekDays?.find((x) => x.day === day);

  return (
    <Box>
      <Typography mb={2} style={fontStyle.label}>
        {label || t('recurring.label.selectDays').toUpperCase()}
      </Typography>
      <ButtonGroup size="small" aria-label="small button group">
        {dayWeekFilters.map((x) => {
          const checked = isChecked(x.id);
          return (
            <Button
              style={checked ? checkedStyle : style}
              onClick={(e) => handleClick(e, x, checked)}
              key={x.id}
            >
              {t(`abbrev.${x.name}`)}
              {!hideEvery && every(x.id)?.every && (
                <span style={{ paddingTop: 1, ...fontStyle.xSmall }}>
                  &nbsp;({every(x.id)?.every})
                </span>
              )}
            </Button>
          );
        })}
      </ButtonGroup>

      <Popover
        open={!!element}
        anchorEl={element?.target}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Paper p={3}>
          <Box display="flex" flexDirection="column" justifyContent="center">
            <Box m={1}>
              <TextFieldRounded
                bordered
                value={element?.day?.every}
                onChange={(v) => changeEvery(v)}
                placeholder="Every"
                label="Every"
              />
            </Box>
          </Box>
        </Paper>
      </Popover>
    </Box>
  );
};

export default WeekDaysCheck;

WeekDaysCheck.propTypes = {};

WeekDaysCheck.defaultProps = {};
