/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import Box from 'core/ui/Box';
import Typography from 'core/ui/Typography';
import { KeyboardArrowDown, KeyboardArrowRight } from '@material-ui/icons';
import { Collapse } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { WorkoutContext } from '.';
import SectionsSettings from './Section/SectionsSettings';

export default ({ mbSections, paddingSections, classes, hideCompare }) => {
  const { t } = useTranslation();
  const formContext = useContext(WorkoutContext);

  return (
    <>
      {!formContext?.custom && !formContext?.isScheduleSession && (
        <Box
          mb={mbSections}
          px={paddingSections}
          py={2}
          className={classes.content}
          style={hideCompare}
        >
          <Box className="hover" display="flex" alignItems="center">
            <Box
              onClick={() =>
                formContext?.setFormOpenSection((prev) => ({
                  ...prev,
                  sectionsSettings: !prev.sectionsSettings,
                }))
              }
              flexGrow="1"
              display="flex"
            >
              <Box flexGrow={1}>
                <Typography color="primary" variant="h5">
                  {t('workout.settings.sections')}
                </Typography>
              </Box>
              {formContext?.mode !== 'new' && (
                <>
                  {formContext?.formOpenSection?.sectionsSettings === true ? (
                    <KeyboardArrowDown />
                  ) : (
                    <KeyboardArrowRight />
                  )}
                </>
              )}
            </Box>
          </Box>
          <Collapse
            in={formContext?.mode === 'new' || formContext?.formOpenSection.sectionsSettings}
          >
            <Box p={3}>
              <SectionsSettings setValue={formContext.setValue} watch={formContext.watch} />
            </Box>
          </Collapse>
        </Box>
      )}
    </>
  );
};
