export default ({ buttonColor, isCoachView }) => {
  const calendarBgColor = (upcomingWorkout) => {
    if (isCoachView) {
      if (!upcomingWorkout?.mainCoach?.id) {
        return 'white';
      }
      return upcomingWorkout?.mainCoach?.calendarColor || buttonColor;
    }
    if (upcomingWorkout?.useCalendarColor === 'channel') {
      return buttonColor;
    }
    if (upcomingWorkout?.useCalendarColor === 'coach') {
      return upcomingWorkout?.mainCoach?.calendarColor || buttonColor;
    }
    return upcomingWorkout?.calendarColor || buttonColor;
  };

  const fontColor = (upcomingWorkout) => {
    if (isCoachView && !upcomingWorkout?.mainCoach?.id) {
      return '#000';
    }

    return 'white';
  };

  const borderColor = (upcomingWorkout) => {
    if (isCoachView && !upcomingWorkout?.mainCoach?.id) {
      return '#000';
    }

    return 'transparent';
  };

  return {
    calendarBgColor,
    fontColor,
    borderColor,
  };
};
