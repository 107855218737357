import { Fade, TableCell } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { convertTimerToForm } from 'core/utils/helpers';
import { useDispatch } from 'react-redux';
import { typeDistance, typeWeight } from 'core/utils/consts';
import { workoutsActions } from 'modules/workouts';
import uuid from 'react-uuid';
import { useDebounce } from 'use-debounce';
import Typography from 'core/ui/Typography';
import { Check } from '@material-ui/icons';
import TeamScoreItem from './TeamScoreItem';

const TeamWorkoutItem = ({ item, workoutId, divisionId, competition }) => {
  const leaderboardWorkout = item?.workouts[workoutId];
  const workout = competition.workouts?.find((x) => x.id === Number(workoutId));
  const [sectionUserScores, setSectionUserScores] = useState([]);
  const dispatch = useDispatch();

  const [showAlert, setShowAlert] = useState(false);
  const [itemsChanged, setItemsChanged] = useState(null);
  const [changeState] = useDebounce(itemsChanged, 1000);

  useEffect(() => {
    setSectionUserScores([]);
    setShowAlert(false);
  }, [workoutId, divisionId]);

  useEffect(() => {
    if (leaderboardWorkout?.leaderboard) {
      setSectionUserScores(
        leaderboardWorkout?.leaderboard?.scores.map((itemScore) => ({
          ...itemScore,
          time: convertTimerToForm(itemScore.time ? itemScore.time : null),
          tieBreaktime: convertTimerToForm(itemScore.tieBreaktime ? itemScore.tieBreaktime : null),
        })),
      );
    }
  }, [leaderboardWorkout?.leaderboard]);

  useEffect(() => {
    if (changeState) {
      saveSectionUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeState]);

  if (!leaderboardWorkout || !workout?.id) {
    return <div />;
  }

  const sectionData = leaderboardWorkout?.section;

  const changeCurrentScores = (values, index) => {
    const currentItems = [...sectionUserScores];
    currentItems[index] = { ...sectionUserScores[index], ...values };
    setSectionUserScores(currentItems);

    setItemsChanged(uuid());
  };

  const totalTimeSection = () => {
    if (sectionUserScores.timeCapped) {
      return (sectionData.timerData && sectionData.timerData.work) || '00:00:00';
    }
    return null;
  };

  const saveSectionUser = () => {
    const scoresData = sectionUserScores.map((itemScore) => {
      const newItemScore = { ...itemScore };
      if (itemScore.distance && !itemScore.typeDistance) {
        newItemScore.typeDistance = typeDistance[0].id;
      }
      if (itemScore.weight && !itemScore.typeWeight) {
        newItemScore.typeWeight = typeWeight[0].id;
      }
      const time =
        newItemScore.time && itemScore.time.value && !itemScore.timeCapped
          ? itemScore.time.value
          : totalTimeSection();

      const tieBreaktime = newItemScore?.tieBreaktime?.value
        ? newItemScore?.tieBreaktime?.value
        : null;

      return {
        ...newItemScore,
        time,
        tieBreaktime,
      };
    });

    dispatch(
      workoutsActions.setWorkoutSectionUserStart(
        {
          workoutId: workout.id,
          data: {
            scores: scoresData,
            sectionId: sectionData.id,
            userId: item?.team.id,
          },
        },
        () => {
          setShowAlert(uuid());
          setTimeout(() => {
            setShowAlert(false);
          }, 2000);
        },
      ),
    );
  };

  return (
    <TableCell key={leaderboardWorkout?.id} className="tw-p-0">
      {sectionData.scores && (
        <div className="tw-flex tw-flex-row tw-items-center tw-justify-end tw-gap-2">
          {showAlert && (
            <Fade in>
              <Typography className="tw-flex tw-flex-row tw-items-center tw-gap-x-2 tw-rounded-md tw-bg-green-400 tw-p-1 tw-text-xs tw-font-semibold tw-text-white">
                <Check className="tw-text-base tw-text-white" />
                Saved
              </Typography>
            </Fade>
          )}
          {sectionData?.scores?.map((itemScore, idx) => (
            <TeamScoreItem
              sectionData={sectionData}
              workout={workout}
              sectionUserScores={sectionUserScores}
              key={`${idx.toString()}-${workout?.id}`}
              currentIndexScore={idx}
              changeCurrentScores={(data) => changeCurrentScores(data, idx)}
              workoutId={workoutId}
              item={item}
            />
          ))}
        </div>
      )}
    </TableCell>
  );
};

TeamWorkoutItem.defaultProps = {};

export default TeamWorkoutItem;
