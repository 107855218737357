import React, { useContext } from 'react';
import Box from 'core/ui/Box';
import useActions from 'modules/map/useActions';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import Typography from 'core/ui/Typography';
import { ButtonPrimary } from 'core/ui/Button';
import Modules from 'modules';
import { showAPIError } from 'core/utils/showAPIError';
import useSelectors from 'modules/map/useSelectors';
import { competitionsCustomScoringsModule } from 'modules/competitionsCustomScorings';
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import Divider from 'core/ui/Divider';
import { CompetitionContext } from '..';
import FormTemplateScores from './FormTemplateScores';

const ModalFormTemplate = ({ open, close, item, callback }) => {
  const { t } = useTranslation();
  const formContext = useContext(CompetitionContext);
  const { control, handleSubmit, watch } = useForm({
    defaultValues:
      item?.id || item?.clone
        ? {
            ...item,
            workouts: item?.workouts?.map((x) => String(x.id)) || [],
            divisions: item?.divisions?.map((x) => String(x.id)) || [],
          }
        : { positions: 5 },
  });
  const loadingForm = useSelectors(competitionsCustomScoringsModule, 'loadingForm');
  const { request } = useActions();

  const divisions =
    (formContext?.competition.hasDivisions && formContext?.competition?.divisions) || [];
  const workouts = formContext?.competition?.workouts || [];

  const positions = watch('positions');

  const saveTemplate = (data) => {
    request({
      action: !item?.id
        ? Modules.competitionsCustomScorings.actions.create
        : Modules.competitionsCustomScorings.actions.update,
      data: {
        competitionId: formContext?.competition?.id,
        ...data,
        scorings: data?.scorings.slice(0, Number(data?.positions)),
        id: item?.id,
      },
      options: {
        onSuccess: () => {
          if (callback) {
            callback();
          }
        },
        onError: showAPIError,
      },
    });
  };

  return (
    <Dialog open={open} onClose={close} maxWidth="sm" fullWidth scroll="paper">
      <DialogContent className="tw-bg-gray-100">
        <Typography className="tw-m-2 tw-text-xs tw-text-zinc-600">
          {t('competition.customScorings.helpMessage')}
        </Typography>
        <Box className="tw-flex tw-flex-col tw-gap-2">
          {divisions && divisions.length > 0 && (
            <Box flexGrow={1}>
              <Controller
                control={control}
                rules={{ required: false }}
                defaultValue={[]}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <div>
                    <SelectBoxObject
                      label={t('competition.divisions')}
                      options={divisions || []}
                      setValue={(v) => {
                        onChange(v);
                      }}
                      value={value || []}
                      elevation={0}
                      emptyItem
                      error={error}
                      bordered
                      multiple
                      placeholder={t('competition.placeholder.division')}
                    />
                    <div className="tw-ml-2 tw-text-xs tw-text-gray-400">
                      {t('competition.customScoring.help.divisions')}
                    </div>
                  </div>
                )}
                name="divisions"
              />
            </Box>
          )}
          <Box flexGrow={1}>
            <Controller
              control={control}
              rules={{ required: false }}
              defaultValue={[]}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <div>
                  <SelectBoxObject
                    label={t('competition.workouts')}
                    options={workouts}
                    setValue={onChange}
                    value={value || []}
                    elevation={0}
                    emptyItem
                    bordered
                    error={error}
                    multiple
                    placeholder={t('competition.placeholder.workout')}
                  />
                  <div className="tw-ml-2 tw-text-xs tw-text-gray-400">
                    {t('competition.customScoring.help.events')}
                  </div>
                </div>
              )}
              name="workouts"
            />
          </Box>

          <div className="tw-flex tw-flex-row">
            <Controller
              control={control}
              rules={{ required: true }}
              defaultValue=""
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <div>
                  <TextFieldRounded
                    label={t(`competition.label.howManyPositions`)}
                    placeholder={t(`competition.label.positions`)}
                    value={value}
                    onChange={onChange}
                    bordered
                    error={error}
                    type="number"
                    pattern="[0-9]*"
                  />
                </div>
              )}
              name="positions"
            />
          </div>

          <div>
            <Controller
              control={control}
              rules={{ required: true }}
              defaultValue={[]}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <div>
                  {error && (
                    <Typography className="tw-text-xs tw-text-red-500">
                      {t('competition.customScoring.error')}
                    </Typography>
                  )}
                  <FormTemplateScores
                    positions={positions || 0}
                    scores={value || []}
                    setScores={onChange}
                  />
                </div>
              )}
              name="scorings"
            />
          </div>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <ButtonPrimary disabled={loadingForm} onClick={handleSubmit(saveTemplate)}>
          {t('button.save')}
        </ButtonPrimary>
      </DialogActions>
    </Dialog>
  );
};

export default ModalFormTemplate;
