import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table, TableCell, TableRow, TableBody, IconButton } from '@material-ui/core';
import Box from 'core/ui/Box';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import { ButtonPrimarySm } from 'core/ui/Button';
import { Add } from '@material-ui/icons';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Typography from 'core/ui/Typography';
import Radio from 'core/ui/Radio';

const ServicesFees = ({ fees, setFees, channel }) => {
  const { t } = useTranslation();

  const addOption = () => {
    setFees([...fees, { name: '', amountType: 'percent' }]);
  };

  const changeFee = (prop, value, item) => {
    setFees(
      fees.map((x) => {
        const newItem = { ...x };
        if (x === item) {
          newItem[prop] = value;
        }

        return newItem;
      }),
    );
  };

  const removeNew = (row) => {
    if (row?.id) {
      setFees(
        fees.map((x) => {
          if (x === row) {
            return { ...x, _destroy: true };
          }

          return x;
        }),
      );
    } else {
      setFees(fees.filter((x) => x !== row));
    }
  };

  return (
    <Box pb={2}>
      <Box display="flex" alignItems="center">
        <Box flexGrow="1">
          <Typography variant="h5" mb={3} color="primary">
            {t('label.service.fees')}
          </Typography>
        </Box>
        <Box p={1}>
          <ButtonPrimarySm onClick={() => addOption()}>
            <Add />
          </ButtonPrimarySm>
        </Box>
      </Box>

      <Table size="small">
        <TableBody>
          {fees &&
            fees
              // eslint-disable-next-line no-underscore-dangle
              .filter((x) => !x._destroy)
              .map((row, index) => (
                <TableRow key={index.toString()}>
                  <TableCell>
                    <TextFieldRounded
                      value={row.name || ''}
                      onChange={(v) => changeFee('name', v, row)}
                      placeholder={t('label.name')}
                      bordered
                    />
                  </TableCell>
                  <TableCell>
                    <Box display="flex" flexDirection="row">
                      <Radio
                        label={
                          <Typography variant="caption" color="textSecondary">
                            %
                          </Typography>
                        }
                        onClick={() => changeFee('amountType', 'percent', row)}
                        checked={row?.amountType === 'percent'}
                      />
                      <Box ml={2}>
                        <Radio
                          label={
                            <Typography variant="caption" color="textSecondary">
                              {channel?.currency?.symbol}
                            </Typography>
                          }
                          onClick={() => changeFee('amountType', 'money', row)}
                          checked={row?.amountType === 'money'}
                        />
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <TextFieldRounded
                      type="number"
                      pattern="[0-9]*"
                      placeholder={t('channel.placeholder.membershipsAmount')}
                      value={row.amount || ''}
                      onChange={(v) => changeFee('amount', v, row)}
                      required
                      currency
                      currencySymbol={row?.amountType === 'money' ? channel?.currency?.symbol : '%'}
                      bordered
                    />
                  </TableCell>

                  <TableCell>
                    <IconButton onClick={() => removeNew(row)}>
                      <HighlightOffIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
        </TableBody>
      </Table>
    </Box>
  );
};

ServicesFees.defaultProps = {};

ServicesFees.propTypes = {};

export default ServicesFees;
