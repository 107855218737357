import React, { useCallback, useContext, useEffect, useState } from 'react';
import Box from 'core/ui/Box';
import { Draggable } from 'react-beautiful-dnd';
import Modules from 'modules';
import useActions from 'modules/map/useActions';
import { useTranslation } from 'react-i18next';
import { StagesContext } from 'contexts';
import Paper from 'core/ui/Paper';
import Typography from 'core/ui/Typography';
import Button from 'core/ui/Button';
import { kanbanUpdateColumnEmitter } from 'emitters';
import { gymDate, time } from 'core/utils/formatters';
import Divider from 'core/ui/Divider';
import { IconButton, Tooltip } from '@material-ui/core';
import { Chat, Delete, LocalOffer, SupervisorAccount, Undo } from '@material-ui/icons';
import useConfirmation from 'core/useConfirmation';
import useChannelStyles from 'core/useChannelStyles';
import notifications from 'modules/notifications';
import { useDispatch } from 'react-redux';
import LoaderSm from 'core/ui/LoaderSm';

const KanbanColumn = ({ stage }) => {
  const { buttonColor } = useChannelStyles();
  const [pagination, setPagination] = useState(null);
  const [loading, setLoading] = useState(false);
  const stagesContext = useContext(StagesContext);
  const { t } = useTranslation();
  const { request } = useActions();
  const { confirmation } = useConfirmation();
  const dispatch = useDispatch();
  const { setColumns } = stagesContext;

  const getStageUsers = useCallback(
    (page = 1, filters = {}) => {
      setLoading(true);
      request({
        action: Modules.channelsStages.actions.getChannelsStagesUsers,
        data: {
          channelId: stage.channelId,
          channelsStageId: stage?.id,
          page,
          perPage: 20,
          ...(filters || {}),
        },
        options: {
          onSuccess: (data, pag) => {
            setPagination(pag);
            setLoading(false);

            if (page === 1) {
              setColumns((prev) => ({ ...prev, [stage.id]: data }));
            } else {
              setColumns((prev) => ({
                ...prev,
                [stage.id]: [...prev[stage.id], ...data],
              }));
            }
          },
          onError: () => {
            setLoading(false);
          },
        },
      });
    },
    [request, stage, setColumns],
  );

  useEffect(() => {
    if (stage && stage.id) {
      getStageUsers(1);
    }
  }, [request, stage, getStageUsers]);

  useEffect(() => {
    if (stage && stage.id) {
      kanbanUpdateColumnEmitter.on(`CHANNEL_STAGE_UPDATE_${stage?.id}`, (filters) => {
        getStageUsers(1, filters);
      });
    }

    return () => {
      kanbanUpdateColumnEmitter.off(`CHANNEL_STAGE_UPDATE_${stage?.id}`);
    };
  }, [stage, getStageUsers]);

  const openModalEdit = (stageUser) => {
    stagesContext.setModalForm({ open: true, stageUser, stageId: stage?.id });
  };

  const deleteStageUser = (stageUser) => {
    confirmation({
      description: t('areYouSure'),
      yesClick: () => {
        setLoading(true);
        request({
          action: Modules.channelsStagesUsers.actions.deleteStageUser,
          data: { channelId: stage.channelId, channelsStageId: stage?.id, id: stageUser.id },
          options: {
            onSuccess: () => {
              setLoading(false);
              setColumns((prev) => ({
                ...prev,
                [stage.id]: prev[stage.id].filter((x) => x.id !== stageUser.id),
              }));
            },
            onError: (error) => {
              dispatch(notifications.error(error));
              setLoading(false);
            },
          },
        });
      },
    });
  };

  const openMessages = (stageUser) => {
    stagesContext?.setModalConversations({
      open: true,
      userId: stageUser?.user?.id,
    });
    setColumns((prev) => ({
      ...prev,
      [stage.id]: prev[stage.id].map((item) => {
        if (item.id === stageUser?.id) {
          return { ...item, countMessages: 0 };
        }

        return item;
      }),
    }));
  };

  return (
    <div className="tw-mb-20 tw-flex tw-flex-col md:tw-mb-4">
      <LoaderSm center loading={loading} />
      {(stagesContext.columns[stage?.id] || [])?.map((stageUser, idx) => (
        <Draggable
          key={`stageUser-${stageUser?.id}`}
          draggableId={`stageUser-${stageUser?.id}`}
          index={idx}
        >
          {(dragProv) => (
            <Box
              mt={2}
              ref={dragProv.innerRef}
              {...dragProv.draggableProps}
              {...dragProv.dragHandleProps}
            >
              <Paper p={2}>
                <Typography
                  onClick={() => openModalEdit(stageUser)}
                  className="tw-cursor-pointer tw-text-gray-800"
                >
                  {stageUser?.user?.name}
                </Typography>
                <div className="tw-text-xs tw-font-normal tw-text-gray-400">
                  {gymDate(stageUser?.createdAt)}&nbsp;-&nbsp;{time(stageUser?.createdAt)}
                </div>
                <div className="tw-text-xs tw-font-normal tw-text-gray-500">
                  {stageUser?.description}
                </div>

                <Divider my={2} />
                <div className="tw-flex tw-flex-row tw-items-center tw-gap-x-2">
                  <div className="tw-flex tw-grow tw-flex-row tw-items-center tw-gap-x-2">
                    <IconButton
                      className="tw-relative tw-p-1"
                      size="small"
                      onClick={() => openMessages(stageUser)}
                    >
                      {stageUser?.countMessages > 0 && (
                        <div className="tw-absolute -tw-right-1 -tw-top-1 tw-rounded-full tw-bg-green-500 tw-p-1 tw-text-[10px] tw-text-white">
                          {stageUser?.countMessages || 0}
                        </div>
                      )}
                      <Chat className="tw-text-lg" />
                    </IconButton>
                    <Tooltip
                      disableHoverListener={stageUser?.tags?.length <= 0}
                      title={stageUser?.tags
                        ?.map((x) => x.name)
                        .map((x) => (
                          <p>{x}</p>
                        ))}
                    >
                      <IconButton
                        onClick={() => openModalEdit(stageUser)}
                        className="tw-relative tw-p-1"
                        size="small"
                      >
                        {stageUser?.tags?.length > 0 && (
                          <div className="tw-absolute -tw-right-1 -tw-top-1 tw-rounded-full tw-bg-blue-400 tw-p-1 tw-text-[10px] tw-text-white">
                            {stageUser?.tags?.length || 0}
                          </div>
                        )}
                        <LocalOffer className="tw-text-lg" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      disableHoverListener={stageUser?.staffs?.length <= 0}
                      title={stageUser?.staffs
                        ?.map((x) => x.name)
                        .map((x) => (
                          <p>{x}</p>
                        ))}
                    >
                      <IconButton
                        onClick={() => openModalEdit(stageUser)}
                        className="tw-relative tw-p-1"
                        size="small"
                      >
                        {stageUser?.staffs?.length > 0 && (
                          <div
                            className="tw-absolute -tw-right-1 -tw-top-1 tw-rounded-full tw-p-1 tw-text-[10px] tw-text-white"
                            style={{ backgroundColor: buttonColor }}
                          >
                            {stageUser?.staffs?.length || 0}
                          </div>
                        )}
                        <SupervisorAccount className="tw-text-lg" />
                      </IconButton>
                    </Tooltip>
                  </div>
                  <div>
                    <Tooltip title={stageUser?.archived ? t('label.undo') : t('label.archived')}>
                      <IconButton
                        onClick={() => deleteStageUser(stageUser)}
                        className="tw-relative tw-p-1"
                        size="small"
                      >
                        {stageUser?.archived ? <Undo /> : <Delete className="tw-text-lg" />}
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              </Paper>
            </Box>
          )}
        </Draggable>
      ))}

      {pagination?.currentPage < pagination?.totalPages && (
        <div className="tw-flex tw-justify-center">
          <Button onClick={() => getStageUsers(pagination.currentPage + 1)}>
            {t('button.viewMore')}
          </Button>
        </div>
      )}
    </div>
  );
};

KanbanColumn.propTypes = {};

export default KanbanColumn;
