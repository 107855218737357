import React from 'react';
import Box from 'core/ui/Box';
import useSelectors from 'modules/map/useSelectors';
import SFDataTable from 'core/ui/DataTable';
import Typography, { fontStyle } from 'core/ui/Typography';
import { currency, date } from 'core/utils/formatters';
import { redColor, warningColor } from 'core/ui/Colors';
import LoaderSm from 'core/ui/LoaderSm';
import { Autorenew } from '@material-ui/icons';
import useConfirmation from 'core/useConfirmation';
import { useDispatch } from 'react-redux';
import { ButtonPrimary, ButtonPrimarySm } from 'core/ui/Button';
import { newChannelMembersModule } from 'modules/channelMembers/new';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import notifications from 'modules/notifications';
import Paper from 'core/ui/Paper';
import Divider from 'core/ui/Divider';
import { useTranslation } from 'react-i18next';

const TablePausedMembers = ({ channel, getPendingMemberships, data, loading }) => {
  const loadingRenewMembership = useSelectors(newChannelMembersModule, 'loadingRenewMembership');
  const { request } = useActions();
  const { confirmation } = useConfirmation();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const retryAll = () => {
    confirmation({
      description: 'Do you want retry all scheuled memberhsips?',
      yesClick: () => {
        getPendingMemberships({ retryAll: true });
      },
    });
  };

  const runMembership = (channelKey) => {
    confirmation({
      description: 'Are you sure?',
      yesClick: () => {
        request({
          action: Modules.newChannelMembers.actions.renewMembership,
          data: {
            channelId: channelKey?.channel?.id,
            channelKeyId: channelKey?.id,
          },
          options: {
            onSuccess: () => {
              getPendingMemberships();
            },
            onError: (ex) => {
              dispatch(notifications.error(ex));
            },
          },
        });
      },
    });
  };

  const columns = [
    {
      name: 'KEY ID',
      selector: 'keyId',
      sortable: true,
      cell: (row) => <Box>{row.keyId}</Box>,
    },
    {
      name: 'User Name',
      selector: 'userName',
      sortable: true,
      cell: (row) => <Box>{row.userName}</Box>,
    },
    {
      name: 'Expiry/Start Date',
      selector: 'expiryAt',
      sortable: true,
      cell: (row) => (
        <Box>
          {row.expiryAt}&nbsp;{row?.renew && <Autorenew style={{ fontSize: 10 }} />}
        </Box>
      ),
    },
    {
      name: 'Cost',
      selector: 'costNextRenew',
      sortable: true,
      cell: (row) => <Box>{currency(row.costNextRenew)}</Box>,
    },
    {
      name: 'Job',
      selector: 'jobTimezoneStatus',
      sortable: true,
      cell: (row) => (
        <Box>
          <Typography style={fontStyle.xSmall}>
            {row?.jobTimezoneStatus === 'OK' ? (
              <span style={{ color: 'green' }}>Job has already run for this timezone</span>
            ) : (
              <span style={{ color: warningColor }}>Job has not yet run for this timezone</span>
            )}
          </Typography>
        </Box>
      ),
    },
    {
      name: 'Issue',
      selector: 'issueCheck',
      sortable: true,
      cell: (row) => (
        <Box>
          {row?.jobTimezoneStatus === 'OK' && !row?.error ? (
            <span style={{ color: redColor }}>Check this key</span>
          ) : (
            row?.error
          )}
        </Box>
      ),
    },
    {
      name: 'Actions',
      selector: 'issueCheck',
      sortable: true,
      cell: (row) => (
        <Box>
          {row?.jobTimezoneStatus === 'OK' && (
            <ButtonPrimarySm
              disabled={loadingRenewMembership}
              onClick={() => runMembership(row?.channelKey)}
            >
              {t('button.retry')}
            </ButtonPrimarySm>
          )}
        </Box>
      ),
    },
  ];

  const formatElement = (element, renew = true) => ({
    channelKey: element?.channelKey,

    keyId: element?.channelKey?.id,
    timezone: element?.channelKey?.channel?.timezone,
    jobTimezoneStatus: element?.jobTimezoneStatus,
    userName: `${element?.channelKey?.user?.id} - ${element?.channelKey?.user?.name}`,
    channelName: `${element?.channelKey?.channel?.id} - ${element?.channelKey?.channel?.name}`,
    expiryAt: date(
      element?.channelKey?.expiryAt || element?.channelKey?.startAt,
      channel?.dateFormatReact,
    ),
    costNextRenew: element?.channelKey?.nextRenewAmounts?.nextCost,
    error: element?.error,
    renew,
    issueCheck: element?.jobTimezoneStatus === 'OK' && !element?.error ? 1 : 0,
  });

  const dataFormat = () => {
    const arr = [];
    if (data?.renew) {
      data.renew.forEach((element) => {
        arr.push(formatElement(element, true));
      });
    }
    if (data?.start) {
      data.start.forEach((element) => {
        arr.push(formatElement(element, false));
      });
    }
    return arr;
  };

  return (
    <Box>
      <LoaderSm center loading={loading || loadingRenewMembership} />
      <Box display="flex" justifyContent="flex-end" mb={2}>
        <ButtonPrimary onClick={() => retryAll()}>{t('button.retryAll')}</ButtonPrimary>
      </Box>
      <SFDataTable
        noDataComponent={loading ? null : <Typography m={5}>No Keys</Typography>}
        columns={columns}
        data={dataFormat()}
        noHeader
        pagination={false}
      />

      <Box>
        <Paper p={5} mt={3}>
          <Typography variant="h5" color="textSecondary">
            Starting: {data?.start?.length}
          </Typography>
          <Divider my={1} />
          <Typography variant="h5" color="textSecondary">
            Renewing: {data?.renew?.length}
          </Typography>
          <Divider my={1} />
          <Typography variant="h4">Total: {data?.renew?.length + data?.start?.length}</Typography>
        </Paper>
      </Box>
    </Box>
  );
};

export default TablePausedMembers;
