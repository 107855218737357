/* eslint-disable react/prop-types */
import React, { useContext, useEffect } from 'react';
import Box from 'core/ui/Box';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import { useSelector } from 'react-redux';
import { messagesSelectors } from 'modules/messages';
import Typography from 'core/ui/Typography';
import { colorLightGrey } from 'core/ui/Colors';
import Paper from 'core/ui/Paper';
import PhoneIcon from '@material-ui/icons/Phone';
import MessageIcon from '@material-ui/icons/Message';
import { IconButton } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { TextServiceContext } from 'contexts';
import FormSend from 'components/Commmunication/Form/FormSend';

export default () => {
  const textServiceContext = useContext(TextServiceContext);
  const twilioNumbers = useSelector((state) => messagesSelectors.getTwilioNumbers(state));
  const checkedLists = textServiceContext.watch('checkedLists');
  const replyTo = textServiceContext.watch('replyTo');

  useEffect(() => {
    if (checkedLists && checkedLists.length > 0) {
      textServiceContext.setValue('replyTo', null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedLists]);

  useEffect(() => {
    if (replyTo) {
      textServiceContext.setValue('checkedLists', []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [replyTo]);

  return (
    <Box px={3}>
      {textServiceContext.watch('method') === 'sms' && (
        <Box mb={3}>
          <SelectBoxObject
            label="From"
            value={textServiceContext.watch('twilioNumberId')}
            setValue={(v) => textServiceContext.setValue('twilioNumberId', v)}
            options={twilioNumbers}
            error={textServiceContext.errors && textServiceContext.errors.twilioNumberId}
            propLabel="description"
            propValue="id"
          />
        </Box>
      )}
      {textServiceContext.watch('replyTo') && (
        <Paper
          className="paper-rounded"
          my={5}
          p={3}
          style={{ position: 'relative', backgroundColor: colorLightGrey }}
        >
          <IconButton
            onClick={() => textServiceContext.setValue('replyTo', null)}
            style={{ position: 'absolute', right: 2, top: 2 }}
          >
            <HighlightOffIcon />
          </IconButton>
          <Typography color="primary">Reply To</Typography>
          <Typography color="secondary">
            {textServiceContext.watch('replyTo').contactName}
          </Typography>
          <Typography color="secondary">
            <PhoneIcon style={{ fontSize: 12 }} />
            &nbsp;
            {textServiceContext.watch('replyTo').phone}
          </Typography>
          <Typography>
            <MessageIcon style={{ fontSize: 12 }} />
            &nbsp;
            {textServiceContext.watch('replyTo').fullText}
          </Typography>
        </Paper>
      )}
      <Box />

      <FormSend textService={textServiceContext} />
    </Box>
  );
};
