/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Typography from 'core/ui/Typography';
import Box from 'core/ui/Box';
import SFDataTable from 'core/ui/DataTable';
import { Dialog, IconButton, Link } from '@material-ui/core';
import { successColor, colorLightGrey, redColor } from 'core/ui/Colors';
import { Delete, Edit, People } from '@material-ui/icons';
import Paginator from 'core/ui/Paginator';
import { Skeleton } from '@material-ui/lab';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { useTranslation } from 'react-i18next';
import { ownerableMessagesModule } from 'modules/ownerableMessages';
import useActions from 'modules/map/useActions';
import useSelectors from 'modules/map/useSelectors';
import Modules from 'modules';
import LoaderSm from 'core/ui/LoaderSm';
import LabelBreakLine from 'core/ui/LabelBreakLine';
import { date } from 'core/utils/formatters';
import { useSelector } from 'react-redux';
import { channelsSelectors } from 'modules/channels';
import Divider from 'core/ui/Divider';
import StatusMessage from './StatusMessage';

const ExpanableComponent = ({ data }) => (
  <Box display="flex" alignItems="center" justifyContent="center" p={5}>
    <div dangerouslySetInnerHTML={{ __html: data?.text }} />
  </Box>
);
const TableMessageLists = ({
  loading,
  ownerableId,
  ownerableType,
  messages,
  copyMessage,
  removeMessage,
  editMessage,
}) => {
  const channel = useSelector((state) => channelsSelectors.getChannel(state));
  const [modalContacts, setModalContacts] = useState({ open: false });
  const { t } = useTranslation();
  const columns = [
    {
      name: 'Date',
      selector: 'createdAt',
      sortable: true,
      cell: (message) => (
        <Typography>{date(message?.createdAt, channel?.dateFormatReact)}</Typography>
      ),
    },
    {
      name: 'Method',
      selector: 'method',
      sortable: true,
      cell: (message) => <Typography>{t(`message.method.${message.method}`)}</Typography>,
    },
    {
      name: 'Status',
      selector: 'status',
      sortable: true,
      center: true,
      cell: (message) => <StatusMessage channel={channel} contact={message} />,
    },

    {
      name: <People style={{ color: successColor }} />,
      selector: 'contactsSentCount',
      sortable: true,
      center: true,
      cell: (message) => <Typography>{message.contactsSentCount}</Typography>,
    },
    {
      name: <People style={{ color: redColor }} />,
      selector: 'contactsUndeliveredCount',
      sortable: true,
      center: true,
      cell: (message) => <Typography>{message.contactsUndeliveredCount}</Typography>,
    },
    {
      name: '',
      selector: 'contactsCount',
      sortable: true,
      right: true,
      cell: (message) => (
        <Box className="tw-flex tw-flex-row tw-items-center tw-justify-end tw-gap-2">
          {message?.allowEdit && (
            <>
              <IconButton size="small" onClick={() => editMessage(message)}>
                <Edit className="tw-text-gray-700" />
              </IconButton>
              <Divider orientation="vertical" flexItem />
            </>
          )}
          {!message?.scheduled && (
            <>
              <IconButton
                size="small"
                onClick={() => setModalContacts((prev) => ({ ...prev, open: true, message }))}
              >
                <People className="tw-text-gray-700" /> ({message.contactsCount})
              </IconButton>
              <Divider orientation="vertical" flexItem />
            </>
          )}
          {copyMessage && (
            <>
              <IconButton size="small" onClick={() => copyMessage(message)}>
                <FileCopyIcon className="tw-text-gray-700" />
              </IconButton>
              {message?.allowRemove && <Divider orientation="vertical" flexItem />}
            </>
          )}
          {message?.allowRemove && (
            <IconButton size="small" onClick={() => removeMessage(message)}>
              <Delete className="tw-text-gray-700" />
            </IconButton>
          )}
        </Box>
      ),
    },
  ];

  return (
    <Box>
      <LoaderSm loading={loading} />
      <SFDataTable
        columns={columns}
        data={messages || []}
        pagination
        showPaper={false}
        noTableHead={false}
        noHeader
        showExport
        expandableRows
        expandableRowsComponent={<ExpanableComponent />}
      />

      {modalContacts.open && (
        <ModalMessageContacts
          ownerableId={ownerableId}
          ownerableType={ownerableType}
          close={() => setModalContacts({ open: false })}
          open={modalContacts.open}
          message={modalContacts.message}
        />
      )}
    </Box>
  );
};

const ModalMessageContacts = ({ message, close }) => {
  const { request } = useActions();
  const loading = useSelectors(ownerableMessagesModule, 'loading');
  const contacts = useSelectors(ownerableMessagesModule, 'contacts');
  const pagination = useSelectors(ownerableMessagesModule, 'pagination');

  const [perPage] = useState(10);

  const getContacts = (p) => {
    request({
      action: Modules.ownerableMessages.actions.getMessageContacts,
      data: {
        ownerableId: message?.ownerableId,
        ownerableType: message?.ownerableType,
        messageId: message.id,
        query: null,
        page: p,
        perPage,
      },
    });
  };

  useEffect(() => {
    if (message && message.id) {
      getContacts(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changePage = (p) => {
    if (p <= pagination.totalPages) {
      getContacts(p);
    }
  };

  const columns = [
    {
      name: 'Name',
      selector: 'name',
      sortable: true,
      cell: (contact) => <Typography>{contact.name}</Typography>,
    },
    {
      name: 'Email',
      selector: 'email',
      sortable: true,
      cell: (contact) => <Typography>{contact.email}</Typography>,
    },
    {
      name: 'Phone',
      selector: 'phone',
      sortable: true,
      cell: (contact) => <Typography>{contact.phone}</Typography>,
    },
    {
      name: 'Status',
      selector: 'status',
      sortable: true,
      center: true,
      cell: (contact) => <StatusMessage contact={contact} />,
    },
  ];

  const rows =
    message &&
    contacts &&
    contacts.map((list) => ({
      id: list.id,
      name: list.name,
      phone: list.formattedPhone,
      status: list.status,
      email: list.email,
    }));

  return (
    <Dialog maxWidth="md" fullWidth open onClose={close} scroll="body">
      <Box p={5} style={{ backgroundColor: colorLightGrey }}>
        <Box m={5}>
          <Typography>
            <LabelBreakLine>{message.fullText}</LabelBreakLine>
          </Typography>
        </Box>
        {message.image && (
          <Box m={5}>
            <Link href={message.videoLink} target="_blank">
              {message.image.image.url}
            </Link>
          </Box>
        )}
        {message.videoLink && (
          <Box m={5}>
            <Link href={message.videoLink} target="_blank">
              {message.videoLink}
            </Link>
          </Box>
        )}

        {loading ? (
          <Skeleton variant="rect" animation="wave" style={{ height: 200, width: '100%' }} />
        ) : (
          <SFDataTable
            columns={columns}
            data={rows}
            pagination={false}
            showPaper={false}
            showExport
            noTableHead={false}
            noHeader
          />
        )}

        <Box flexGrow={1} m={3} display="flex" justifyContent="center">
          <Paginator pagination={pagination} changePage={(p) => changePage(p)} />
        </Box>
      </Box>
    </Dialog>
  );
};

TableMessageLists.propTypes = {};

TableMessageLists.defaultProps = {};

export default TableMessageLists;
