import React, { useEffect, useRef, useState } from 'react';
import Box from 'core/ui/Box';
import useWindowSize from 'core/utils/hook/useWindowSize';
import DriverTutorial from 'core/ui/DriverTutorial';
import { useTranslation } from 'react-i18next';
import { ClickAwayListener } from '@material-ui/core';
import ItemGallery from './ItemGallery';
import useTutorialWatch from '../useTutorialWatch';

export default ({ player, checkedTeams, leaderboard, workout, buttonColor }) => {
  const [activeRecordings, setActiveRecordings] = useState([]);
  const boxVideo = useRef();
  const { t } = useTranslation();
  const boxVideoLarge = useRef();
  const tutorial = useTutorialWatch();

  const setVideoConfig = (duration = false) => {
    let total = 0;
    let mainId = 0;
    if (workout && workout.recordingVideos) {
      workout.recordingVideos
        .filter((x) => !!duration || !!checkedTeams.find((y) => y === x.user.id))
        .forEach((rec) => {
          const totalSeconds = Number(rec.durationSeconds) - Number(rec.startCountdownSeconds);

          if (total < totalSeconds) {
            total = totalSeconds;
            mainId = rec.id;
          }
        });
    }

    if (duration) {
      player.setTime((prev) => ({ ...prev, total }));
    } else {
      player.setTime((prev) => ({ ...prev, mainId, totalMain: total }));
    }
  };

  useEffect(() => {
    if (workout && workout.recordingVideos && leaderboard) {
      setActiveRecordings(
        leaderboard
          .filter((x) => !!workout.recordingVideos.find((y) => y.user.id === x.user.id))
          .slice(0, 4)
          .map((x) => x.user.id),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workout, leaderboard]);

  useEffect(() => {
    if (checkedTeams && checkedTeams.length > 0) {
      setVideoConfig(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedTeams]);

  useEffect(() => {
    player.setPlaying(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedTeams]);

  useEffect(() => {
    if (workout && workout.recordingVideos) {
      setVideoConfig(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workout]);

  const isFullScreen = (userId) => !!activeRecordings.find((x) => x === userId);

  const setRecordingFullScreen = (userId) => {
    let actives = [];
    if (isFullScreen(userId)) {
      actives = activeRecordings.filter((x) => x !== userId);
    } else {
      actives = [...activeRecordings, userId];
    }
    setActiveRecordings(actives);
  };

  const size = useWindowSize();
  const getSize = (rec, newSize) => {
    let width = newSize;
    let height = Math.round((width / rec.resWidth) * rec.resHeight, 0);
    if (height > size.height) {
      height = size.height;
      width = (height / rec.resHeight) * rec.resWidth;
    }

    return {
      width,
      height,
    };
  };

  return (
    <Box>
      <ClickAwayListener onClickAway={() => tutorial.setOpened(false)}>
        <Box>
          {tutorial.opened && (
            <DriverTutorial
              targetId="#box-video-tutorial"
              title={t('tutorial.video.expand.title')}
              description={t('tutorial.video.expand.message')}
              onDeselected={() => tutorial.setLog()}
            />
          )}
        </Box>
      </ClickAwayListener>
      <div className="wrapper-rec" style={{ maxHeight: 0 }}>
        <div className="box-rec" ref={boxVideo} />
        <div className="box-rec active-rec" ref={boxVideoLarge} />
      </div>
      <Box mt={3} display="flex" flexWrap="wrap" className="wrapper-rec">
        {checkedTeams &&
          checkedTeams.map((userId, index) => {
            const rec =
              (workout.recordingVideos &&
                workout.recordingVideos.find((x) => x.user.id === userId)) ||
              {};
            if (rec.durationSeconds < player.time.complete) {
              return <React.Fragment key={userId} />;
            }
            return (
              <ItemGallery
                leaderboardUser={
                  (leaderboard && leaderboard.find((x) => x.user.id === userId)) || {}
                }
                recording={rec}
                userId={userId}
                key={userId.toString()}
                index={index}
                workoutId={workout.id}
                sectionId={workout.sections[0].id}
                player={player}
                boxVideo={boxVideo.current}
                buttonColor={buttonColor}
                boxVideoLarge={boxVideoLarge.current}
                setRecordingFullScreen={setRecordingFullScreen}
                getSize={getSize}
                isFullScreen={isFullScreen}
              />
            );
          })}
      </Box>
    </Box>
  );
};
