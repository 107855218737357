/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';

import { primary, redColor, warningColor } from 'core/ui/Colors';

const colors = { create: primary, update: warningColor, destroy: redColor };

const removeColumns = ['tokens', 'uid'];

export const AuditModal = ({ audit, ...props }) => {
  if (!audit) return null;

  return (
    <Dialog open {...props} maxWidth="xl" fullWidth>
      <DialogTitle>Audit</DialogTitle>

      <DialogContent>
        <strong className="tw-uppercase" style={{ color: colors[audit.action] }}>
          {audit.action}
        </strong>

        {(audit.action === 'create' || audit.action === 'destroy') && (
          <table className="tw-w-full tw-border-spacing-0">
            <thead className="tw-bg-gray-800 tw-text-white">
              <tr>
                <th className="tw-px-4 tw-py-3 tw-text-left tw-text-sm tw-font-semibold tw-uppercase">
                  Key
                </th>

                <th className="tw-px-4 tw-py-3 tw-text-left tw-text-sm tw-font-semibold tw-uppercase">
                  Value
                </th>
              </tr>
            </thead>

            <tbody>
              {Object.entries(audit.auditedChanges)
                .filter(([k, v]) => !!v && !removeColumns.find((column) => column === k))
                .map(([key, value]) => (
                  <tr key={key} className="odd:tw-bg-neutral-100">
                    <td className="tw-px-4 tw-py-3 tw-text-left tw-uppercase">{key}</td>

                    <td className="tw-px-4 tw-py-3 tw-text-left tw-text-green-500">
                      {JSON.stringify(value)}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}

        {audit.action === 'update' && (
          <table className="tw-w-full tw-border-spacing-0">
            <thead className="tw-bg-gray-800 tw-text-white">
              <tr>
                <th className="tw-w-1/5 tw-px-4 tw-py-3 tw-text-left tw-text-sm tw-font-semibold tw-uppercase">
                  Key
                </th>

                <th className="tw-w-2/5 tw-px-4 tw-py-3 tw-text-left tw-text-sm tw-font-semibold tw-uppercase">
                  Old
                </th>

                <th className="tw-w-2/5 tw-px-4 tw-py-3 tw-text-left tw-text-sm tw-font-semibold tw-uppercase">
                  New
                </th>
              </tr>
            </thead>

            <tbody>
              {Object.entries(audit.auditedChanges)
                .filter(([k]) => !removeColumns.find((column) => column === k))
                .map(([key, value]) => (
                  <tr key={key} className="odd:tw-bg-neutral-100">
                    <td className="tw-px-4 tw-py-3 tw-text-left tw-uppercase">{key}:&nbsp;</td>

                    <td className="tw-px-4 tw-py-3 tw-text-left tw-text-red-500">
                      {JSON.stringify(value[0]) || 'null'}
                    </td>

                    <td className="tw-px-4 tw-py-3 tw-text-left tw-text-green-500">
                      {JSON.stringify(value[1]) || 'null'}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
      </DialogContent>
    </Dialog>
  );
};
