import React, { useContext, useEffect, useState } from 'react';
import { Collapse, makeStyles } from '@material-ui/core';
import { durationWorkout } from 'core/utils/consts';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { searchActions, searchSelectors } from 'modules/search';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { LayoutContext } from 'pages/shared/Layout';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { filtersActions } from 'modules/filters';
import Scrollbars from 'react-custom-scrollbars';
import Filter from './Filter';
import Box from '../Box';
import FontIcon from '../FontIcon';
import { primary } from '../Colors';
import Typography, { fontStyle } from '../Typography';
import Divider from '../Divider';
import { ButtonPrimary } from '../Button';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    background: '#fff',
    zIndex: 9999,
  },
  buttonApply: {
    marginBottom: 5,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 80,
    },
  },
}));

export default () => {
  const filters = useSelector((state) => searchSelectors.getFilters(state));
  const [tab, setTab] = useState('');
  const layoutContext = useContext(LayoutContext);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();

  useEffect(() => {
    dispatch(filtersActions.getFiltersStart());
  }, [dispatch]);

  const checked = (id, kind = null) => {
    const arrayFilters = filters ? [...filters] : [];
    return !!arrayFilters.find((x) => kind === x.kind && x.value === id);
  };

  const change = ({ value, label, kind }) => {
    let arrayFilters = filters ? [...filters] : [];

    if (kind === 'range_date' || value === 'all') {
      arrayFilters = arrayFilters.filter((x) => x.kind !== kind);
    }

    if (value !== 'all') {
      arrayFilters = arrayFilters.filter((x) => `${x.kind}${x.value}` !== `${kind}all`);
    }

    if (checked(value, kind) && value !== 'all') {
      const item = arrayFilters.find((x) => x.value === value && x.kind === kind);
      arrayFilters = arrayFilters.filter((x) => x !== item);
    } else {
      arrayFilters.push({ kind, value, label });
    }

    dispatch(searchActions.setFilters(arrayFilters));
  };

  const setTabFilter = (selectedTab) => {
    if (selectedTab === tab) {
      setTab('');
      return;
    }
    setTab(selectedTab);
  };

  useEffect(() => {
    dispatch(
      searchActions.setFilters([
        { value: 'all', label: 'all', kind: 'range_date' },
        { value: 'all', label: 'all', kind: 'filter_language' },
        { value: 'all', label: 'all', kind: 'duration_minutes' },
        { value: 'all', label: 'all', kind: 'filter_type_ids' },
        { value: 'all', label: 'all', kind: 'filter_age_ids' },
        { value: 'all', label: 'all', kind: 'filter_intensity_ids' },
        { value: 'all', label: 'all', kind: 'filter_equipment_ids' },
        { value: 'all', label: 'all', kind: 'filter_difficulty_ids' },
      ]),
    );
  }, []); // eslint-disable-line

  // eslint-disable-next-line react/prop-types
  const ItemMenu = ({ kind, label }) => (
    <Box onClick={() => setTabFilter(kind)} display="flex" py={1} px={1} className="link">
      <Box flexGrow="1" display="flex" alignItems="center">
        <Box flexGrow="1">
          <Typography ml={1} style={{ ...fontStyle.xLarge }}>
            {label}
          </Typography>
        </Box>
        {filters.filter((x) => x.kind === kind).length === 0 ? (
          <Typography ml={1} style={{ ...fontStyle.label }}>
            {t('search.filters.all')}
          </Typography>
        ) : (
          <Box>
            {filters
              .filter((x) => x.kind === kind)
              .map((filter, index) => (
                <Typography key={index.toString()} ml={1} style={{ ...fontStyle.label }}>
                  {t(filter.label)}
                </Typography>
              ))}
          </Box>
        )}
      </Box>
      <Box display="flex" alignItems="center">
        {tab === kind ? (
          <KeyboardArrowUpIcon fontSize="large" />
        ) : (
          <KeyboardArrowDownIcon fontSize="large" />
        )}
      </Box>
    </Box>
  );

  const DividerFilter = () => <Divider />;
  return (
    <Box className={classes.root} display="flex" flexDirection="column">
      <Box display="flex" px={1} className="link">
        <Box
          display="flex"
          alignItems="center"
          flexGrow="1"
          my={3}
          onClick={() => layoutContext.setDrawerFilters((prev) => ({ ...prev, open: false }))}
        >
          <ArrowBackIcon style={{ fontSize: 18 }} />
          <Typography ml={1} style={{ ...fontStyle.large }} color="primary">
            {t('button.close')}
          </Typography>
        </Box>
        <Box my={3} display="flex" alignItems="center">
          <FontIcon iconName="icon-filters" color={primary} />
          <Typography ml={1} style={{ ...fontStyle.large }}>
            {t('filters')}
          </Typography>
        </Box>
      </Box>
      <DividerFilter my={1} />

      <Scrollbars
        renderTrackHorizontal={(props) => (
          <div {...props} style={{ display: 'none' }} className="track-horizontal" />
        )}
        autoHide
        style={{ height: '100%', maxHeight: '100%' }}
      >
        <Box style={{ height: '100%' }}>
          {/* WORKOUTS FILTERS */}
          <ItemMenu
            label={t('search.filters.durationMinutes').toUpperCase()}
            kind="duration_minutes"
          />
          <Box style={{ background: '#F5F4F4' }}>
            <Collapse in={tab === 'duration_minutes'}>
              <Filter
                change={change}
                checked={checked}
                filters={[{ id: 'all', name: 'All' }, ...durationWorkout]}
                kind="duration_minutes"
              />
            </Collapse>
          </Box>
          <DividerFilter my={1} />
        </Box>
      </Scrollbars>

      <Box alignItems="flex-end" px={5} className={classes.buttonApply}>
        <ButtonPrimary
          style={{ width: '100%' }}
          onClick={() =>
            layoutContext.setDrawerFilters((prev) => ({ ...prev, open: false, execSearch: true }))
          }
        >
          {t('button.applyChanges')}
        </ButtonPrimary>
      </Box>
    </Box>
  );
};
