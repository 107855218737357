import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form-v5';
import { useDispatch } from 'react-redux';
import notifications from 'modules/notifications';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import useSelectors from 'modules/map/useSelectors';
import { usersAddressesModule } from 'modules/usersAddresses';

const useAddressForm = ({ userId, item, callback, channelId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loading = useSelectors(usersAddressesModule, 'loading');
  const { request } = useActions();
  const { register, handleSubmit, setValue, watch, reset, errors } = useForm();

  const onSubmit = (values) => {
    request({
      action: !item?.id
        ? Modules.usersAddresses.actions.create
        : Modules.usersAddresses.actions.update,
      data: {
        ...values,
        channelId,
        id: item?.id,
        userId,
      },
      options: {
        onSuccess: () => {
          if (callback) {
            callback(values?.name);
          }
          dispatch(notifications.success(t('message.success.save.addresses')));
          request({
            action: Modules.usersAddresses.actions.getAddress,
            data: { channelId: values?.channelId, userId },
          });
        },
        onError: (error) => {
          dispatch(notifications.error(error));
        },
      },
    });
  };

  useEffect(() => {
    register('description', { required: false });
    register('countryId', { required: 'Country is required.' });
    register('street');
    register('city');
    register('state');
    register('zip', { required: 'Zip is required' });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register]);

  useEffect(() => {
    if (item && item?.id) {
      reset({
        ...item,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, item?.id]);

  useEffect(() => {
    const er = Object.keys(errors)[0];
    if (er) {
      dispatch(notifications.warning(errors[er]?.message));
    }
  }, [errors, dispatch]);

  return {
    register,
    setValue,
    watch,
    reset,
    errors,
    onSubmit,
    handleSubmit,
    loading,
  };
};

export default useAddressForm;
