import React from 'react';

import Texture from 'core/ui/Texture';
import { WorkflowContext } from 'contexts';
import useSelectors from 'modules/map/useSelectors';
import { automationWorkflowsModule } from 'modules/automationWorkflows';

import Backdrop from 'core/ui/Backdrop';
import { AppBar, Toolbar, colors, makeStyles } from '@material-ui/core';
import Box from 'core/ui/Box';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import { useTranslation } from 'react-i18next';
import Checkbox from 'core/ui/Checkbox';
import { secondary, secondaryLight } from 'core/ui/Colors';
import { ButtonPrimary } from 'core/ui/Button';
import Divider from 'core/ui/Divider';
import Workflow from './Workflow';
import useWorkflowForm from './useWorkflowForm';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    backgroundColor: 'white',
    borderBottom: `1px solid ${colors.grey[300]}`,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

export default function WorkflowFormContext({
  channelId,
  callback,
  workflowId,
  showTemplate,
  isTemplate,
  close,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const loadingWorkflow = useSelectors(automationWorkflowsModule, 'loadingWorkflow');
  const context = useWorkflowForm({ channelId, workflowId, callback, isTemplate });

  const allowEdit = !isTemplate || showTemplate;

  return (
    <>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Box flexGrow={1}>
            <TextFieldRounded
              placeholder={t('label.workflowNane')}
              value={context.workflowData?.name}
              onChange={(v) => context.setWorkflowData((prev) => ({ ...prev, name: v }))}
              bordered
              disabled={!allowEdit}
            />
          </Box>

          {showTemplate && (
            <Box mx={2}>
              <Checkbox
                label="Template"
                labelColor={secondary}
                checked={!!context.workflowData?.template}
                onClick={() =>
                  context.setWorkflowData((prev) => ({
                    ...prev,
                    template: !context.workflowData?.template,
                  }))
                }
              />
            </Box>
          )}

          <Box display="flex" px={3} className="gap-10">
            <ButtonPrimary buttonColor={secondaryLight} onClick={close} color="primary">
              Cancel
            </ButtonPrimary>
            {!!allowEdit && (
              <>
                <Divider orientation="vertical" mx={2} flexItem />
                <ButtonPrimary disabled={loadingWorkflow} onClick={context.saveWorkflow}>
                  {t('button.save')}
                </ButtonPrimary>
              </>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <Texture style={{ minHeight: '100%' }}>
        <WorkflowContext.Provider value={{ ...context, channelId }}>
          {loadingWorkflow && <Backdrop loading={loadingWorkflow} />}
          <Workflow allowEdit={allowEdit} />
        </WorkflowContext.Provider>
      </Texture>
    </>
  );
}
