import React from 'react';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import { ButtonPrimary } from 'core/ui/Button';
import Checkbox from 'core/ui/Checkbox';
import DatePickerRounded from 'core/ui/DatePickers/DatePickerRounded';
import useForm from './useForm';

const FormChannelEditInfo = ({ callback, channel }) => {
  const { t } = useTranslation();
  const { watch, setValue, submitForm, loading } = useForm({
    callback,
    channel,
  });

  return (
    <Box>
      <Box mb={4}>
        <DatePickerRounded
          label="Renew Date"
          onChange={(v) => setValue('renewAt', v)}
          value={watch('renewAt')}
          disablePast={false}
          autoOk
        />
      </Box>
      <Box mb={4}>
        <Checkbox
          label="Allow Cardpointe"
          onClick={() => setValue('allowCardpointe', !watch('allowCardpointe'))}
          checked={watch('allowCardpointe')}
        />
      </Box>

      <Box flexGrow={1} display="flex" justifyContent="flex-end" my={5}>
        <ButtonPrimary disabled={loading} style={{ width: 150 }} onClick={() => submitForm()}>
          {t('button.save')}
        </ButtonPrimary>
      </Box>
    </Box>
  );
};

FormChannelEditInfo.defaultProps = {
  callback: () => {},
  benchmark: null,
};

export default FormChannelEditInfo;
