import { useCallback, useMemo, useState } from 'react';
import { getFavorite, postFavorite, deleteFavorite } from 'modules/favorites/api';

function useFavorite() {
  const [favorite, setFavorite] = useState({});

  const klassToFavorite = useMemo(
    () => ({
      channel: 'Channel',
      workout: 'Workout',
      coach: 'User',
    }),
    [],
  );

  const isFavorite = useCallback(async (klass, klassId) => {
    const resp = await getFavorite({ klass, klassId });
    setFavorite(resp.data ? resp.data : {});
  }, []);

  const addFavorite = useCallback(async (klass, klassId, messageSuccess = '') => {
    const resp = await postFavorite({
      klass,
      klassId,
      messageSuccess,
    });
    setFavorite(resp.data ? resp.data : {});
  }, []);

  const removeFavorite = useCallback(async (klass, klassId, messageSuccess = '') => {
    await deleteFavorite({
      klass,
      klassId,
      messageSuccess,
    });
    setFavorite({});
  }, []);

  return { klassToFavorite, favorite, isFavorite, addFavorite, removeFavorite };
}

export default useFavorite;
