import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker } from '@material-ui/pickers';
import clsx from 'clsx';
import Paper from '@material-ui/core/Paper';

import Typography, { fontStyle } from 'core/ui/Typography';
import { secondaryLight } from '../Colors';

export default function NeoDatePickerRounded({
  /** Custom */
  bordered,
  stylePaper,
  usFormat,
  paperClassName,
  labelClassName,
  /** Custom */

  label = '',
  error = false,
  required = false,
  format,
  disabled,
  className,
  InputProps,
  ...props
}) {
  const [open, setOpen] = useState(false);

  const classes = useStyles();

  return (
    <div>
      {label && (
        <Typography
          style={fontStyle.label}
          variant="body1"
          className={clsx('tw-mb-2 tw-ml-2', labelClassName)}
        >
          {label} {required && <span className="tw-text-red-500"> *</span>}
        </Typography>
      )}

      <Paper
        className={clsx(
          classes.root,
          error && 'input-paper-error',
          !error && bordered && classes.inputBordered,
          paperClassName,
        )}
      >
        <KeyboardDatePicker
          variant="inline"
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          onClick={() => (disabled ? null : setOpen(true))}
          inputVariant="standard"
          format={format ?? (usFormat ? 'M-d-yy' : 'd-M-yy')}
          className={clsx('inputDatePicker tw-px-1 tw-py-0.5', className)}
          InputProps={{ ...InputProps, className: clsx(classes.input, InputProps?.className) }}
          disabled={disabled}
          required={required}
          {...props}
        />
      </Paper>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 5,
    width: '150px',
  },
  rootBordered: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: secondaryLight,
  },
  input: {
    flex: 1,
    marginLeft: theme.spacing(1),
    fontSize: 12,
    paddingLeft: 5,
    borderBottom: 'none',
  },
}));
