import { useEffect } from 'react';
import ReactGA from 'react-ga';

import { sessionSelectors } from 'modules/session';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { GA_TRACKING_ID, ENV_APP } from 'core/env';

export default () => {
  const userSession = useSelector((state) => sessionSelectors.getUser(state));
  const location = useLocation();
  useEffect(() => {
    ReactGA.initialize(GA_TRACKING_ID, {
      gaOptions: {
        name: userSession ? userSession.name : '',
        userId: userSession ? userSession.id : null,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addEvent = (params) => {
    if (ENV_APP !== 'production') {
      return;
    }
    ReactGA.event(params);
  };

  const pageView = (page) => {
    if (ENV_APP !== 'production') {
      return;
    }
    ReactGA.pageview(page);
  };

  useEffect(() => {
    pageView(location.pathname);
  }, [location.pathname]);

  return {
    addEvent,
    pageView,
  };
};
