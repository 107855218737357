import React, { useState } from 'react';
import Grid from 'core/ui/Grid';
import BenchmarkList from './List';
import BenchmarkInfo from './Info';
import BenchmarkHistory from './History';
import ModalFormHistory from './Form/Modal';
import BenchmarkVideos from './Videos';

const Benchmark = () => {
  const [selectedBenchmark, setSelectedBenchmark] = useState(null);
  const [modalFormHistory, setModalFormHistory] = useState(false);

  const openModalFormBenchmark = () => {
    setModalFormHistory(true);
  };

  return (
    <>
      <ModalFormHistory
        selectedBenchmark={selectedBenchmark}
        open={modalFormHistory}
        close={() => setModalFormHistory(false)}
        callback={() => setModalFormHistory(false)}
      />
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <BenchmarkList
            setSelectedBenchmark={setSelectedBenchmark}
            selectedBenchmark={selectedBenchmark}
            filter={null}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          {selectedBenchmark && (
            <BenchmarkInfo
              addNewBenchmark={openModalFormBenchmark}
              selectedBenchmark={selectedBenchmark}
            />
          )}

          {selectedBenchmark && <BenchmarkHistory selectedBenchmark={selectedBenchmark} />}
          {selectedBenchmark && <BenchmarkVideos selectedBenchmark={selectedBenchmark} />}
        </Grid>
      </Grid>
    </>
  );
};

export default Benchmark;
