import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography, { fontStyle } from 'core/ui/Typography';
import { time } from 'core/utils/formatters';
import { useTranslation } from 'react-i18next';
import { primary, redColor } from 'core/ui/Colors';
import { timeWithoutTz, dateAdd } from 'core/utils/formatters/date';
import AvatarCoach from 'components/Profile/AvatarCoach';
import AvatarCoaches from 'components/Profile/AvatarCoaches';
import LiveIcon from 'core/ui/LiveIcon';
import Box from 'core/ui/Box';
import Paper from 'core/ui/Paper';
import { ButtonPrimarySm } from 'core/ui/Button';
import { Link } from '@material-ui/core';
import MIcon from 'core/ui/MIcon';
import FontIcon from 'core/ui/FontIcon';
import { channelsSelectors } from 'modules/channels';
import { useSelector } from 'react-redux';

const useStyles = makeStyles({
  difficulty: {
    fontFamily: 'Roboto',
    fontSize: 14,
  },
  timeFont: {
    fontFamily: 'Roboto',
  },
  boxTag: {
    background: primary,
    position: 'absolute',
    top: 8,
    right: 8,
    zIndex: 10,
  },
});

const ItemScheduleClass = ({ workout, workoutRegister, buttonColor }) => {
  const classes = useStyles();
  const channel = useSelector((state) => channelsSelectors.getChannel(state));

  const { t } = useTranslation();
  const workoutDate =
    workout?.datetime || (workout.method !== 'live' ? workout.publishAt : workout.scheduledAt);

  return (
    <Box
      style={{
        height: 100,
        position: 'relative',
      }}
    >
      <Paper elevation={1} p={3} style={{ border: '0.4px solid #e9e9e9' }}>
        <Box display="flex" flexDirection="column">
          <Box className={classes.boxContent}>
            <Box display="flex">
              {workout?.coaches?.length > 0 && (
                <div className="tw-flex tw-flex-row">
                  {workout.coaches && workout.coaches.length > 1 ? (
                    <AvatarCoaches coaches={workout.coaches} />
                  ) : (
                    <AvatarCoach user={workout.coaches[0]} />
                  )}
                </div>
              )}
              <Box flexGrow={1} pl={2} pt={1}>
                <Box display="flex">
                  <Box flexGrow={1} style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {!!workoutDate && (
                      <Box display="flex">
                        <Typography style={{ ...fontStyle.xSmall, color: 'gray' }}>
                          {timeWithoutTz(
                            workoutDate,
                            channel?.useUserTimezone ? null : workout?.timezone,
                          )}
                          &nbsp;-&nbsp;
                        </Typography>

                        {workout.online ? (
                          <Typography style={{ ...fontStyle.xSmall, color: 'gray' }}>
                            {time(
                              dateAdd(workoutDate, workout.durationMinutes, 'minutes'),
                              channel?.useUserTimezone ? null : workout?.timezone,
                            )}
                          </Typography>
                        ) : (
                          <Typography style={{ ...fontStyle.xSmall, color: 'gray' }}>
                            {time(
                              workout.endAt,
                              channel?.useUserTimezone ? null : workout?.timezone,
                            )}
                          </Typography>
                        )}
                      </Box>
                    )}
                    {workout?.canceled && (
                      <Typography variant="caption" style={{ color: redColor }}>
                        {t('workout.canceled')}
                      </Typography>
                    )}
                    <Typography
                      noWrap
                      variant="body2"
                      style={{ textOverflow: 'ellipsis' }}
                      component="h5"
                    >
                      {(
                        workout.name ||
                        (workout.firstType && workout.firstType.name) ||
                        ''
                      ).substring(0, 30)}
                    </Typography>
                    <Box>
                      {workout.online && (
                        <Box display="flex" alignItems="center">
                          <FontIcon iconName="icon-live-tv" size={12} />
                          <Typography mx={1} style={fontStyle.small} mr={1}>
                            {t('label.registered')}
                          </Typography>
                          <Typography style={fontStyle.small}>
                            {workout?.maxUsers > 0
                              ? `${workout?.countOnlineUsers || 0}/${workout?.maxUsers}`
                              : workout?.countOnlineUsers || 0}
                          </Typography>
                        </Box>
                      )}

                      <Box display="flex" alignItems="center">
                        <MIcon icon="people" size={12} />
                        <Typography mx={1} style={fontStyle.small} mr={1}>
                          {t('label.registered')}
                        </Typography>
                        <Typography style={fontStyle.small}>
                          {workout?.inPersonMaxUsers > 0
                            ? `${workout?.countInPersonUsers || 0}/${workout?.inPersonMaxUsers}`
                            : workout?.countInPersonUser || 0}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  {workout?.live && workout?.online && workout?.started && (
                    <Box style={{ position: 'absolute', right: 5, top: 3 }}>
                      <LiveIcon />
                    </Box>
                  )}
                  <div className="tw-flex tw-flex-col tw-gap-2">
                    <Link href={`/workout/view/${workout?.slug}`} target="_blank">
                      <ButtonPrimarySm buttonColor={buttonColor}>
                        <Typography style={fontStyle.medium}>{t('button.view')}</Typography>
                      </ButtonPrimarySm>
                    </Link>

                    {!workout?.registered && (
                      <ButtonPrimarySm
                        buttonColor={buttonColor}
                        onClick={() => workoutRegister(workout)}
                      >
                        <Typography style={fontStyle.medium}>{t('button.register')}</Typography>
                      </ButtonPrimarySm>
                    )}
                  </div>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default ItemScheduleClass;

ItemScheduleClass.defaultProps = {
  workout: {},
};
