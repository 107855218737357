import React, { useContext, useEffect } from 'react';
import Box from 'core/ui/Box';
import { ShowContext } from 'components/Workout/Show/index';
import Paper from 'core/ui/Paper';
import useActions from 'modules/map/useActions';
import useSelectors from 'modules/map/useSelectors';
import { workoutWaitlistsModule } from 'modules/workoutWaitlists';
import Modules from 'modules';
import Typography from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import Divider from 'core/ui/Divider';
import useConfirmation from 'core/useConfirmation';
import CardWaitList from './Card';

export default ({ setSelectedUser }) => {
  const showContext = useContext(ShowContext);
  const { request } = useActions();
  const { t } = useTranslation();
  const data = useSelectors(workoutWaitlistsModule, 'data');
  const { confirmation } = useConfirmation();
  const getUsers = () => {
    request({
      action: Modules.workoutWaitlists.actions.getWorkoutWaitlists,
      data: { workoutId: showContext.workout.id },
    });
  };
  useEffect(() => {
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request, showContext.workout]);

  const removeFromWaitList = (userId) => {
    confirmation({
      title: t('workout.waitlist.remove'),
      description: t('workout.waitlist.remove.confirm'),
      yesClick: () => {
        request({
          action: Modules.workoutWaitlists.actions.removeFromWaitList,
          data: { workoutId: showContext.workout.id, userId },
          options: {
            onSuccess: () => {
              getUsers();
            },
          },
        });
      },
    });
  };

  if (data && data.length <= 0) {
    return <></>;
  }

  return (
    <Paper elevation={2} className="paper-rounded" p={3} mt={3}>
      <Box>
        <Box flexGrow={1} mb={3} display="flex" alignItems="flex-end">
          <Typography variant="h5" component="h4">
            {t('workout.waitlist')}
          </Typography>
        </Box>
        <Box>
          {data &&
            data.map((athlete, index) => (
              <Box key={index.toString()}>
                <CardWaitList
                  athlete={athlete}
                  isCoach={showContext.workout.isCoach}
                  onClick={(user) => setSelectedUser(user)}
                  removeFromWaitList={removeFromWaitList}
                />
                {data.length - 1 > index && <Divider my={2} />}
              </Box>
            ))}
        </Box>
      </Box>
    </Paper>
  );
};
