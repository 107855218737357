import api from 'core/api';

export const helpVideoModule = 'helpVideo';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const actions = {
  index: {
    module: helpVideoModule,
    name: 'index',
    api: () => api.get('/help_videos'),
    params: {
      start: ['params'],
      error: [''],
      success: ['helpVideos'],
    },
    state: loadingStates,
  },
  create: {
    module: helpVideoModule,
    name: 'create',
    api: (body) => api.post('/help_videos', body),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  update: {
    module: helpVideoModule,
    name: 'update',
    api: ({ id, body }) => api.patch(`/help_videos/${id}`, body),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  destroy: {
    module: helpVideoModule,
    name: 'destroy',
    api: (id) => api.delete(`/help_videos/${id}`),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  indexByPath: {
    module: helpVideoModule,
    name: 'indexByPath',
    api: (params) => api.get('/help_videos/by_path', { params }),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  destroyByPath: {
    module: helpVideoModule,
    name: 'destroyByPath',
    api: ({ helpVideoId, params }) => api.delete(`/help_videos/${helpVideoId}/by_path`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  reorderByPath: {
    module: helpVideoModule,
    name: 'reorderByPath',
    api: (body) => api.patch('/help_videos/reorder_by_path', body),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
};

export default {
  actions,
  state: {
    helpVideos: [],
    loading: false,
  },
};
