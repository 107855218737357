import React from 'react';
import Box from 'core/ui/Box';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import Grid from 'core/ui/Grid';
import Paper from 'core/ui/Paper';
import Typography, { fontStyle } from 'core/ui/Typography';
import { usStatesTypes } from 'core/utils/consts';
import SelectBox from 'core/ui/SelectBox';
import TextFieldMaskedRounded from 'core/ui/TextFieldRounded/MaskedField';
import FormAddress from './FormAddress';

export default ({ watch, setValue, errors }) => (
  <Grid container mb={4} spacing={4}>
    <Grid item xs={12} md={6}>
      <Paper p={3}>
        <Typography mb={3}>Owner Information</Typography>
        <Box mb={4}>
          <TextFieldRounded
            label="Owner Name"
            placeholder="Owner Name"
            value={watch('payload.merchant.ownership.owner.ownerName')}
            onChange={(v) => setValue('payload.merchant.ownership.owner.ownerName', v)}
            bordered
            error={errors?.payload?.merchant?.ownership?.owner?.ownerName}
            required
          />
        </Box>
        <Box mb={4}>
          <TextFieldRounded
            label="Owner Email"
            placeholder="Owner Email"
            value={watch('payload.merchant.ownership.owner.ownerEmail')}
            onChange={(v) => setValue('payload.merchant.ownership.owner.ownerEmail', v)}
            bordered
            error={errors?.payload?.merchant?.ownership?.owner?.ownerEmail}
            required
          />
        </Box>
        <Box mb={4}>
          <TextFieldMaskedRounded
            label="Owner Phone"
            placeholder="Owner Phone"
            bordered
            error={errors?.payload?.merchant?.ownership?.owner?.ownerPhone}
            value={watch('payload.merchant.ownership.owner.ownerPhone')}
            onChange={(v) => setValue('payload.merchant.ownership.owner.ownerPhone', v)}
            required
          />
          <Typography mx={2} style={fontStyle.xSmall} color="textSecondary">
            Please use XXX-XXX-XXXX format. Phone cannot start with a zero or a one and must not be
            all the same numbers.
          </Typography>
        </Box>
        <Box mb={4}>
          <SelectBox
            options={[
              'PARTNER',
              'OWNER',
              'PRESIDENT',
              'VICE_PRESIDENT',
              'MEMBER_LLC',
              'SECRETARY',
              'TREASURER',
              'CEO',
              'CFO',
              'COO',
            ]}
            value={watch('payload.merchant.ownership.owner.ownerTitle')}
            setValue={(v) => setValue('payload.merchant.ownership.owner.ownerTitle', v)}
            label="Owner Title"
            placeholder="Owner Title"
            bordered
            required
          />
        </Box>
        <Grid container spacing={3}>
          <Grid item={12} md={6}>
            <SelectBox
              options={usStatesTypes}
              placeholder="Drivers License State"
              error={errors?.payload?.merchant?.ownership?.driversLicenseStateCd}
              label="Drivers License State"
              setValue={(v) => setValue(`payload.merchant.ownership.driversLicenseStateCd`, v)}
              value={watch(`payload.merchant.ownership.driversLicenseStateCd`)}
              required
              bordered
            />
          </Grid>
          <Grid item={12} md={6}>
            <TextFieldRounded
              label="Drivers License Number"
              placeholder="Drivers License Number"
              value={watch('payload.merchant.ownership.driversLicenseNumber')}
              onChange={(v) => setValue('payload.merchant.ownership.driversLicenseNumber', v)}
              bordered
              error={errors?.payload?.merchant?.ownership?.driversLicenseNumber}
              required
            />
          </Grid>
        </Grid>
      </Paper>
    </Grid>
    <Grid item xs={12} md={6}>
      <Paper p={3}>
        <Typography mb={3}>Owner Address</Typography>
        <FormAddress
          watch={watch}
          setValue={setValue}
          rootKey="payload.merchant.ownership.owner.ownerAddress"
          errors={errors}
          showCopy
        />
      </Paper>
    </Grid>
  </Grid>
);
