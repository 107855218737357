import React from 'react';
import { makeStyles } from '@material-ui/core';

import FontIcon from 'core/ui/FontIcon';
import Box from 'core/ui/Box';
import Typography from 'core/ui/Typography';

export const sizeIcon = 30;

export const ItemMenu = ({
  mx,
  ml,
  label,
  size = sizeIcon,
  icon,
  color,
  active,
  onClick,
  CustomIcon,
}) => {
  const classes = useStyles();

  return (
    <Box
      mx={mx}
      ml={ml || mx}
      className={`${classes.itemMenu} ${active ? classes.itemMenuActive : ''}`}
    >
      <Box className="link" onClick={onClick}>
        <Box style={{ height: 35 }} display="flex" justifyContent="center" alignItems="flex-start">
          {CustomIcon ?? <FontIcon size={size} iconName={icon} color={color} />}
        </Box>

        {label && (
          <Typography
            mt={1}
            component="h6"
            align="center"
            className="roboto-regular"
            style={{ fontSize: 12, color: 'white' }}
          >
            {label}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

const useStyles = makeStyles({
  itemMenu: {
    transition: 'opacity .1s ease-in-out',
    opacity: 0.6,
    '&:hover': {
      opacity: 1,
    },
  },
  itemMenuActive: {
    opacity: 1,
  },
});
