/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography, { fontStyle } from 'core/ui/Typography';
import PropTypes from 'prop-types';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import { DialogContent, Dialog } from '@material-ui/core';
import DatePickerRounded from 'core/ui/DatePickers/DatePickerRounded';
import { date } from 'core/utils/formatters';
import { ButtonPrimary } from 'core/ui/Button';
import { dateNowAdd } from 'core/utils/formatters/date';
import { useDispatch } from 'react-redux';
import notifications from 'modules/notifications';
import TimePickerRounded from 'core/ui/DatePickers/TimePickerRounded';

const useStyles = makeStyles({
  root: {
    backgroundColor: '#f3f3f3',
  },
  btnClose: {
    position: 'absolute',
    right: 5,
    top: 0,
  },
});

export default function ModalExtendRecurring({
  channel,
  open,
  close,
  recurring,
  saveEditRecurring,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [current, setCurrent] = useState({});

  const save = (obj) => {
    if (obj.countLiveWorkouts <= 0 && !obj.startAt) {
      dispatch(notifications.warning(t('recurring.message.validate.startDate')));
      return;
    }
    if (!obj.stopRecurring) {
      dispatch(notifications.warning(t('recurring.message.validate.stopDate')));
      return;
    }
    saveEditRecurring(obj);
  };

  useEffect(() => {
    if (recurring) {
      // const startAt = formatDateApi(recurring.startAt, 'YYYY-MM-DD HH:mm:ss Z');
      setCurrent((prev) => ({ ...prev, ...recurring, startAt: null, stopRecurring: null }));
    }
  }, [recurring]);

  return (
    <Dialog open={open} maxWidth="xs" fullWidth onClose={() => close()}>
      <DialogContent className={classes.root}>
        <Typography align="center" color="primary" variant="h5">
          {t('channel.recurring.extend')}
        </Typography>

        <Box>
          {recurring.countLiveWorkouts <= 0 && (
            <Box ml={2} mt={2} display="flex">
              <DatePickerRounded
                type="datetime-local"
                label={t('workout.label.startAt').toUpperCase()}
                onChange={(v) => setCurrent((prev) => ({ ...prev, startAt: v }))}
                value={current.startAt}
                usFormat={channel?.useUsDateFormat}
              />

              <TimePickerRounded
                type="datetime-local"
                label="TIME"
                onChange={(v) => setCurrent((prev) => ({ ...prev, startTimeAt: v }))}
                value={current.startTimeAt}
              />
            </Box>
          )}
          <Box m={2} mx={4} mt={2}>
            <Typography style={fontStyle.label} variant="body1">
              {t('workout.repeat').toUpperCase()}
            </Typography>
            <Typography style={fontStyle.xSmall} variant="body1">
              {t('workout.repeat.max')}
            </Typography>
            <Box m={2}>
              <Typography align="center">
                {current?.every > 1 ? `Every ${current?.every}` : ''}&nbsp;
              </Typography>
              <Typography align="center">{current.frequency?.toUpperCase()}</Typography>
            </Box>
          </Box>
          <Box display="flex">
            <Box flexGrow={1}>
              <Typography my={1} align="center" style={fontStyle.label} variant="h6">
                {t('workout.repeat.limit').toUpperCase()}
              </Typography>
              <Typography align="center" color="primary" variant="h6">
                {date(dateNowAdd(6, 'months'), channel?.dateFormatReact)}
              </Typography>
            </Box>
          </Box>
          <Box ml={2} mt={2} display="flex" justifyContent="center">
            <DatePickerRounded
              type="datetime-local"
              label={t('workout.label.repeatLimitDate').toUpperCase()}
              onChange={(v) => setCurrent((prev) => ({ ...prev, stopRecurring: v }))}
              value={current.stopRecurring}
              usFormat={channel?.useUsDateFormat}
            />
          </Box>
          <Box m={3}>
            <ButtonPrimary fullWidth onClick={() => save(current)}>
              {t('button.confirm')}
            </ButtonPrimary>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

ModalExtendRecurring.propTypes = {
  saveEditRecurring: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  recurring: PropTypes.object.isRequired,
};

ModalExtendRecurring.defaultProps = {};
