import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

import * as selectors from './selectors';
import * as sagas from './sagas';

/* ---------- Action Creators and Types ---------- */
const { Types, Creators } = createActions(
  {
    getChannelMembersError: ['error'],
    getChannelMembersStart: ['params'],
    getChannelMembersSuccess: ['members', 'pagination'],

    getChannelMemberError: ['error'],
    getChannelMemberStart: ['channelId', 'userId', 'options'],
    getChannelMemberSuccess: ['member'],

    getAvailableClassesError: ['error'],
    getAvailableClassesStart: ['channelId', 'userId', 'workoutId'],
    getAvailableClassesSuccess: ['workouts'],

    memberUnregisterClassError: ['error'],
    memberUnregisterClassStart: ['channelId', 'userId', 'workoutId', 'callback'],
    memberUnregisterClassSuccess: ['member'],

    memberRegisterClassError: ['error'],
    memberRegisterClassStart: ['data', 'callback', 'onError'],
    memberRegisterClassSuccess: ['member'],

    putUpdateMemberError: ['error'],
    putUpdateMemberStart: ['data', 'callback'],
    putUpdateMemberSuccess: [],

    postAddMemberError: ['error'],
    postAddMemberStart: ['data', 'callback'],
    postAddMemberSuccess: [],

    clearMember: [],
  },
  { prefix: 'channelMembers/' },
);

/* ---------- Initial State ---------- */
const INITIAL_STATE = Immutable({
  data: [],
  dataWorkouts: [],
  error: null,
  membersPagination: null,
  loading: false,
  loadingWorkouts: false,
  loadingMember: false,
  loadingUploadMembers: false,
});

/* ---------- Reducers ---------- */
const loadStartReducer = (state) => state.set('loading', true);
const loadMemberStartReducer = (state) => state.set('loadingMember', true);

const loadMemberWorkoutsStartReducer = (state) =>
  state.merge({
    loadingWorkouts: true,
  });
const getChannelMembersSuccessReducer = (state, action) =>
  state.merge({
    data: action.members,
    membersPagination: action.pagination,
    loading: false,
    error: null,
  });

const getAvailableClassesSuccessReducer = (state, action) =>
  state.merge({
    dataWorkouts: action.workouts,
    loadingWorkouts: false,
    error: null,
  });

const loadErrorReducer = (state, action) =>
  state.merge({
    loading: false,
    error: action.error,
  });

const getChannelMemberSuccessReducer = (state, action) =>
  state.merge({
    loadingMember: false,
    member: action.member,
  });

const clearMemberReducer = (state) =>
  state.merge({
    loadingMember: false,
    member: null,
  });

const putUpdateMemberSuccessReducer = (state) =>
  state.merge({
    loadingMember: false,
  });

const memberUnregisterClassSuccessReducer = (state) =>
  state.merge({
    loadingMember: false,
  });

const memberRegisterClassSuccessReducer = (state) =>
  state.merge({
    loadingMember: false,
  });

const loadMemberErrorReducer = (state, action) =>
  state.merge({
    loadingMember: false,
    error: action.error,
  });

const postAddMemberSuccessReducer = (state) =>
  state.merge({
    loadingMember: false,
  });

const loadMemberWorkoutsErrorReducer = (state) =>
  state.merge({
    loadingWorkouts: false,
  });

/* ---------- Main Reducer ---------- */
const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_CHANNEL_MEMBERS_START]: loadStartReducer,
  [Types.GET_CHANNEL_MEMBERS_SUCCESS]: getChannelMembersSuccessReducer,
  [Types.GET_CHANNEL_MEMBERS_ERROR]: loadErrorReducer,

  [Types.GET_CHANNEL_MEMBER_START]: loadMemberStartReducer,
  [Types.GET_CHANNEL_MEMBER_SUCCESS]: getChannelMemberSuccessReducer,
  [Types.GET_CHANNEL_MEMBER_ERROR]: loadMemberErrorReducer,

  [Types.GET_AVAILABLE_CLASSES_START]: loadMemberWorkoutsStartReducer,
  [Types.GET_AVAILABLE_CLASSES_SUCCESS]: getAvailableClassesSuccessReducer,
  [Types.GET_AVAILABLE_CLASSES_ERROR]: loadMemberWorkoutsErrorReducer,

  [Types.MEMBER_UNREGISTER_CLASS_START]: loadMemberStartReducer,
  [Types.MEMBER_UNREGISTER_CLASS_SUCCESS]: memberUnregisterClassSuccessReducer,
  [Types.MEMBER_UNREGISTER_CLASS_ERROR]: loadMemberErrorReducer,

  [Types.MEMBER_REGISTER_CLASS_START]: loadMemberStartReducer,
  [Types.MEMBER_REGISTER_CLASS_SUCCESS]: memberRegisterClassSuccessReducer,
  [Types.MEMBER_REGISTER_CLASS_ERROR]: loadMemberErrorReducer,

  [Types.PUT_UPDATE_MEMBER_START]: loadMemberStartReducer,
  [Types.PUT_UPDATE_MEMBER_SUCCESS]: putUpdateMemberSuccessReducer,
  [Types.PUT_UPDATE_MEMBER_ERROR]: loadMemberErrorReducer,

  [Types.POST_ADD_MEMBER_START]: loadMemberStartReducer,
  [Types.POST_ADD_MEMBER_SUCCESS]: postAddMemberSuccessReducer,
  [Types.POST_ADD_MEMBER_ERROR]: loadMemberErrorReducer,

  [Types.CLEAR_MEMBER]: clearMemberReducer,
});

/* ---------- Exporting ---------- */
export const channelMembersActions = Creators;
export const channelMembersTypes = Types;
export const channelMembersSelectors = selectors;
export const channelMembersSagas = sagas;

export default reducer;
