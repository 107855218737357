import React from 'react';

import Typography from 'core/ui/Typography';

export const renderTypeCell = (message) => {
  const additionalInfo = getAddicionalInfo(message);

  return (
    <Typography>
      {message?.messagesType?.name}
      {additionalInfo}
    </Typography>
  );
};

const getAddicionalInfo = (message) => {
  if (!message.recurringParams) return null;

  const { recurringParams } = message;
  const divider = ' - ';

  if (recurringParams.classes && recurringParams.days) {
    return `${divider}${recurringParams.classes} x ${recurringParams.days}`;
  }
  if (recurringParams.classes) {
    return `${divider}${recurringParams.classes}`;
  }

  return null;
};
