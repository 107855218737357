import React, { useEffect, useState } from 'react';
import Typography from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import { InputLabel } from '@material-ui/core';
import AWS from 'aws-sdk';
import uuid from 'react-uuid';
import LinearProgress from 'core/ui/LinearProgress';
import { ENV_APP } from 'core/env';
import Box from 'core/ui/Box';
import Button, { ButtonPrimary } from 'core/ui/Button';
import { Movie } from '@material-ui/icons';
import { B_ID } from 'core/utils/consts';
import { mKY } from 'core/utils/helpers';

const B_K = mKY();

const bucket = new AWS.S3({
  accessKeyId: B_ID,
  secretAccessKey: B_K,
  region: 'us-east-2',
});

const PATH_KEY = `uploads/${ENV_APP}/ChannelMovementVideos/`;

export default function BucketUpload({ file, setFile, autoUpload }) {
  const { t } = useTranslation();
  const [uploading, setUploading] = useState(0);
  const [progressUpload, setProgressUpload] = useState(0);

  const uploadfile = (fileName) => {
    const params = {
      Bucket: 'stream-fitness-app-uploads',
      Key: PATH_KEY + fileName,
      Body: file?.fileData,
      ContentType: file?.fileData?.type,
    };

    return bucket.upload(params, (err, data) => {
      setUploading(false);
      if (err) {
        console.log('There was an error uploading your file: ', err);
        return false;
      }

      setFile({ ...file, url: data.Location });
      return true;
    });
  };

  const handleCapture = ({ target }) => {
    if (target.files.length) {
      for (let i = 0; i < target.files.length; i += 1) {
        const f = target.files[i];
        setFile({ ...file, name: file?.name, fileData: f });
      }
    }
  };

  const uploadSampleFile = () => {
    const uniqueFileName = `streamfitness-workout-media-video-${uuid()}-${file?.fileData?.name}`;
    setUploading(true);
    uploadfile(uniqueFileName).on('httpUploadProgress', (progress) => {
      const progressPercentage = Math.round((progress.loaded / progress.total) * 100);
      setProgressUpload(progressPercentage);
    });
  };
  const random = uuid();

  useEffect(() => {
    if (file?.fileData?.name && autoUpload) {
      uploadSampleFile();
    }
  }, [file?.fileData?.name, autoUpload]);

  return (
    <Box>
      <Typography mb={1} align="center">
        {t('workout.upload.video.formats')}
      </Typography>
      <Box display="flex" justifyContent="center" className="link-hover">
        <Button style={{ borderRadius: 5 }} color="primary" variant="contained">
          <InputLabel htmlFor={`icon-button-file-${random}`}>
            <Box display="flex" alignItems="center" justifyContent="center">
              <Movie size="small" style={{ color: 'white' }} />
              <Typography ml={3} style={{ color: 'white' }} variant="subtitle2">
                {file?.fileData?.name || t('workout.upload.selectVideo')}
              </Typography>
            </Box>
          </InputLabel>
        </Button>
      </Box>

      <input
        color="primary"
        type="file"
        onChange={handleCapture}
        id={`icon-button-file-${random}`}
        style={{ display: 'none' }}
        accept=".mpeg,.mp4,.avi,.mov,.webm,.flv"
        multiple
      />

      {!!file?.fileData?.name && (
        <>
          <Typography>{file?.name}</Typography>
          <LinearProgress
            style={{ height: 15, width: '100%', borderRadius: 5 }}
            value={progressUpload}
          />
          {!autoUpload && !uploading && (
            <Box mt={3} display="flex" justifyContent="center">
              <ButtonPrimary onClick={() => uploadSampleFile()}>
                {t('button.startUpload')}
              </ButtonPrimary>
            </Box>
          )}
        </>
      )}
    </Box>
  );
}
