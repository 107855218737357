import React, { useState } from 'react';
import Typography from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import { Dialog } from '@material-ui/core';
import Box from 'core/ui/Box';
import Texture from 'core/ui/Texture';
import { ButtonPrimary } from 'core/ui/Button';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import { channelAccessTypes } from 'core/utils/consts';
import LoaderSm from 'core/ui/LoaderSm';
import SelectorChannelUser from 'components/Channel/SelectorChannelUser';
import ModalAddMembers from '../Members/AddMembers/Modal';

export default function ModalAddUser({
  close,
  open,
  loading,
  channelId,
  setSelectedUser,
  buttonColor,
}) {
  const { t } = useTranslation();
  const [modalAddMember, setModalAddMember] = useState();
  const [type, setType] = useState('coach');

  const onSelect = (u) => {
    setSelectedUser(u, type);
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth onClose={() => close()}>
      <Texture>
        <Box p={3}>
          {loading && (
            <Box p={5} display="flex" alignItems="center" justifyContent="center">
              <LoaderSm loading={loading} />
            </Box>
          )}
          <Box mx={4}>
            <SelectBoxObject
              propLabel="label"
              propValue="value"
              value={type}
              setValue={(v) => setType(v)}
              label={t('channel.coach.invite.label.accessType')}
              options={channelAccessTypes}
            />
          </Box>

          <Typography mt={3} align="center" color="secondary" variant="h6">
            {t('workout.modal.searchUser')}
          </Typography>

          {modalAddMember && (
            <ModalAddMembers
              callbackSave={onSelect}
              channelId={channelId}
              close={() => setModalAddMember(false)}
              form
            />
          )}

          <SelectorChannelUser
            buttonColor={buttonColor}
            onSelectedUser={(u) => onSelect(u)}
            channelId={channelId}
            showCreateButton={false}
          />

          <Box p={5} display="flex" alignItems="center" justifyContent="center">
            <ButtonPrimary buttonColor={buttonColor} onClick={() => setModalAddMember(true)}>
              {t('button.createNewUser')}
            </ButtonPrimary>
          </Box>
        </Box>
      </Texture>
    </Dialog>
  );
}

ModalAddUser.propTypes = {};

ModalAddUser.defaultProps = {};
