import React, { useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ArrowForwardIos } from '@material-ui/icons';

import Modules from 'modules';
import useActions from 'modules/map/useActions';

import Paper from 'core/ui/Paper';
import Typography from 'core/ui/Typography';
import { channelCurrency, gymDate } from 'core/utils/formatters';
import Divider from 'core/ui/Divider';
import { showAPIError } from 'core/utils/showAPIError';

export const Resume = ({ control, channelKey, channel }) => {
  const { t } = useTranslation();
  const { request } = useActions();

  const [membershipPlanId, membershipCouponId] = useWatch({
    control,
    name: ['membershipPlanId', 'membershipCoupon.id'],
  });

  const [resume, setResume] = useState(null);

  const channelKeyChannelId = channelKey?.channel?.id;
  const channelKeyId = channelKey?.id;

  useEffect(() => {
    if (!channelKeyChannelId || !channelKeyId || !membershipPlanId) return;

    request({
      action: Modules.channelKeys.actions.checkSwitchresume,
      data: {
        channelId: channelKeyChannelId,
        channelKeyId,
        channelMembershipPlanId: membershipPlanId,
        channelMembershipCouponId: membershipCouponId,
      },
      options: {
        onSuccess: (data) => {
          setResume(data);
        },
        onError: showAPIError,
      },
    });
  }, [request, membershipPlanId, membershipCouponId, channelKeyChannelId, channelKeyId]);

  if (!resume || !resume.nextMembershipPlan) return null;

  return (
    <Paper p={2} className="tw-flex tw-flex-col tw-gap-2">
      <div className="tw-flex tw-flex-row tw-items-center tw-justify-center tw-gap-2 tw-p-4">
        <Typography>{resume?.currentMembershipPlan?.name}</Typography>
        <ArrowForwardIos style={{ fontSize: 12 }} />
        <Typography>{resume?.nextMembershipPlan?.name}</Typography>
      </div>
      <div>
        <Typography>{t('label.membership.paymentType')}</Typography>
        <Typography className="tw-text-lg">
          {channelKey?.offline ? channelKey?.offlineType : 'ONLINE'}
        </Typography>
      </div>
      <Divider />
      <div>
        <Typography>{t('label.membership.remainingAmount')}</Typography>
        <Typography className="tw-text-lg">
          {channelCurrency(resume?.currentCreditAmount, channel?.currency)}
        </Typography>
      </div>
      <Divider />
      <div>
        <Typography>
          {t(resume?.due ? 'label.membership.totalToPay' : 'label.membership.totalDiffToPay')}
        </Typography>
        <Typography className={resume?.due ? 'tw-text-lg' : 'tw-text-lg tw-text-green-500'}>
          {channelCurrency(resume?.totalToPay, channel?.currency)}
        </Typography>
        {resume?.due && (
          <div>
            <Typography className="tw-text-xs tw-text-blue-500">
              {t('userPaymentMethod.defaultPayment')}
            </Typography>
          </div>
        )}
      </div>
      {resume?.nextExpiryDate && (
        <>
          <Divider />
          <div className="tw-flex tw-flex-row tw-gap-2">
            <div className="flex-1">
              <Typography>{t('label.membership.nextMembershipPrice')}</Typography>
              <Typography className="tw-text-lg">
                {channelCurrency(resume?.nextMembershipAmount, channel?.currency)}
              </Typography>
            </div>
            <div className="tw-flex-1">
              <Typography>
                {t(
                  resume?.nextMembershipPlan?.renewable
                    ? 'label.membership.newPlanExpiryDate'
                    : 'label.membership.newPlanRenewalDate',
                )}
              </Typography>
              <Typography className="tw-text-lg">{gymDate(resume?.nextExpiryDate)}</Typography>
            </div>
          </div>
        </>
      )}
    </Paper>
  );
};
