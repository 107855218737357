export default ({ channel }) => {
  const cancelAccess = () => {
    if (!channel) return false;

    return !!['owner'].find((x) => x === channel.accessType);
  };

  const formAccess = () => {
    if (!channel) return false;

    return !!['owner', 'admin', 'admin_coach'].find((x) => x === channel.accessType);
  };

  const dashboard = () => {
    if (!channel) return false;

    return !!['owner', 'admin', 'admin_coach'].find((x) => x === channel.accessType);
  };

  const planAccess = () => {
    if (!channel) return false;

    return !!['owner', 'admin', 'admin_coach'].find((x) => x === channel.accessType);
  };

  const membershipsAccess = () => {
    if (!channel) return false;

    return !!['owner', 'admin', 'admin_coach', 'coach_head', 'coach_owner'].find(
      (x) => x === channel.accessType,
    );
  };

  const usersAccess = () => {
    if (!channel) return false;

    return !!['owner', 'admin', 'admin_coach', 'coach_owner'].find((x) => x === channel.accessType);
  };

  const notesAccess = () => {
    if (!channel) return false;

    return !!['owner', 'admin', 'admin_coach', 'coach_head', 'coach_owner', 'coach'].find(
      (x) => x === channel.accessType,
    );
  };

  const reportsAccess = () => {
    if (!channel) return false;

    return !!['owner', 'admin', 'admin_coach', 'coach_owner'].find((x) => x === channel.accessType);
  };

  const storeAccess = () => {
    if (!channel) return false;

    return !!['owner', 'admin', 'admin_coach', 'coach_head', 'coach_owner'].find(
      (x) => x === channel.accessType,
    );
  };

  const scheduleAccess = () => {
    if (!channel) return false;

    return !!['owner', 'admin', 'admin_coach', 'coach_head', 'coach_owner', 'coach'].find(
      (x) => x === channel.accessType,
    );
  };

  const allowComments = () => {
    if (channel && channel.accessType) {
      return !!['owner', 'admin', 'admin_coach', 'coach_head', 'coach_owner', 'coach'].find(
        (x) => x === channel.accessType,
      );
    }
    return false;
  };

  const announcementsAccess = () => {
    if (channel && channel.accessType) {
      return !!['owner', 'admin', 'admin_coach', 'coach_head', 'coach_owner', 'coach'].find(
        (x) => x === channel.accessType,
      );
    }
    return false;
  };

  const membersAccess = () => {
    if (channel && channel.accessType) {
      return !!['owner', 'admin', 'admin_coach', 'coach_head', 'coach_owner', 'coach'].find(
        (x) => x === channel.accessType,
      );
    }
    return false;
  };

  const documentsAccess = () => {
    if (channel && channel.accessType) {
      return !!['owner', 'admin', 'admin_coach', 'coach_head', 'coach_owner', 'coach'].find(
        (x) => x === channel.accessType,
      );
    }
    return false;
  };

  const viewManage = () => !!channel?.accessType;

  return {
    cancelAccess: cancelAccess(),
    formAccess: formAccess(),
    planAccess: planAccess(),
    usersAccess: usersAccess(),
    membershipsAccess: membershipsAccess(),
    membersAccess: membersAccess(),
    notesAccess: notesAccess(),
    reportsAccess: reportsAccess(),
    storeAccess: storeAccess(),
    commentsAccess: allowComments(),
    scheduleAccess: scheduleAccess(),
    documentsAccess: documentsAccess(),
    announcementsAccess: announcementsAccess(),
    viewManage: viewManage(),
    dashboard: dashboard(),
  };
};
