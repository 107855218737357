/* eslint-disable react/prop-types */
import React, { useRef, useState } from 'react';
import Paper from 'core/ui/Paper';
import Box from 'core/ui/Box';
import {
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Popper,
} from '@material-ui/core';
import { ButtonPrimary } from 'core/ui/Button';
import { ArrowDropDown } from '@material-ui/icons';
import LoaderSm from 'core/ui/LoaderSm';
import { useTranslation } from 'react-i18next';
import Typography from 'core/ui/Typography';

const PaymentsMenuActions = ({ row, loading, actions }) => {
  const { t } = useTranslation();
  const refElement = useRef();
  const [open, setOpen] = useState(false);

  const menuItems = [];

  if (!row.confirmedAt && row?.offline && row?.offlineType !== 'post_payment') {
    menuItems.push(
      <MenuItem onClick={() => actions?.checkAsPaid(row?.id)}>{t('button.checkAsPaid')}</MenuItem>,
    );
  }

  if (row?.status !== 'succeeded') {
    menuItems.push(
      <MenuItem onClick={() => actions?.checkAsResolved(row?.id, !!row.failedResolvedAt)}>
        {t(!row.failedResolvedAt ? 'button.checkAsResolved' : 'button.checkAsUnresolved')}
      </MenuItem>,
    );
  }

  if (row?.offline && row?.offlineType !== 'post_payment') {
    menuItems.push(
      <MenuItem onClick={() => actions?.changeType()}>{t('button.editType')}</MenuItem>,
    );
  }

  if (row?.allowReceipt) {
    menuItems.push(
      <MenuItem onClick={() => actions?.sendReceipt(row?.id)}>{t('button.sendReceipt')}</MenuItem>,
    );

    menuItems.push(
      <MenuItem onClick={() => actions.downloadReceipt(row.id)}>
        {t('button.downloadReceipt')}
      </MenuItem>,
    );
  }

  if (row?.pendingStripeAction) {
    menuItems.push(
      <MenuItem onClick={() => actions?.sendStepVerification(row?.id)}>
        {t('button.resendVerification')}
      </MenuItem>,
    );
  }

  if (row?.allowDestroy) {
    menuItems.push(
      <MenuItem onClick={() => actions?.removePayment(row?.id)}>{t('button.delete')}</MenuItem>,
    );
  }

  if (!row.holdTransaction && !row.fromPartner && row.allowRefund) {
    menuItems.push(
      <MenuItem onClick={() => actions?.refundPayment(row)}>{t('button.refund')}</MenuItem>,
    );
  }

  if (menuItems.length === 0) {
    return <></>;
  }

  return (
    <Box m={3} display="flex">
      <Box>
        <ButtonGroup ref={refElement} variant="contained" color="primary" aria-label="split button">
          <ButtonPrimary onClick={() => setOpen(true)}>
            <LoaderSm width={20} loading={loading} />
            <Typography noWrap>Actions</Typography> <ArrowDropDown />
          </ButtonPrimary>
        </ButtonGroup>
        <Popper
          open={open}
          anchorEl={refElement?.current}
          role={undefined}
          transition
          style={{ zIndex: 10 }}
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={() => setOpen(false)}>
                  <MenuList id="split-button-menu">
                    {menuItems?.map((x, index) => (
                      <React.Fragment key={index}>{x}</React.Fragment>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Box>
    </Box>
  );
};
export default PaymentsMenuActions;
