import { Skeleton } from '@material-ui/lab';
import Box from 'core/ui/Box';
import Grid from 'core/ui/Grid';
import React from 'react';

const SkeletonWorkout = () => {
  const styleBox = {
    borderRadius: 10,
    overflow: 'hidden',
  };
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Box style={styleBox} className="container-aspect container-aspect-37">
            <Box className="item-aspect">
              <Skeleton variant="rect" style={{ height: '100%', width: '100%' }} />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box style={styleBox} className="container-aspect container-aspect-37">
            <Box className="item-aspect">
              <Skeleton variant="rect" style={{ height: '100%', width: '100%' }} />
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Box mt={3} style={{ ...styleBox, borderRadius: 5 }}>
        <Skeleton variant="rect" style={{ height: 60, width: '100%' }} />
      </Box>

      <Grid container spacing={3} mt={3}>
        <Grid item xs={12} md={6}>
          <Box style={styleBox} className="container-aspect container-aspect-57">
            <Box className="item-aspect">
              <Skeleton variant="rect" style={{ height: '100%', width: '100%' }} />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box style={styleBox} className="container-aspect container-aspect-57">
            <Box className="item-aspect">
              <Skeleton variant="rect" style={{ height: '100%', width: '100%' }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default SkeletonWorkout;
