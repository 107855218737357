import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import propTypes from 'prop-types';
import Texture from 'core/ui/Texture';
import Box from 'core/ui/Box';
import Payment from './index';

export default function ModalPayment({ open, setOpen }) {
  return (
    <div>
      <Dialog
        PaperProps={{ style: { borderRadius: 5 } }}
        maxWidth="sm"
        fullWidth
        scroll="body"
        onClose={() => setOpen(false)}
        open={open}
      >
        <Texture>
          <Box p={3}>
            <Payment open={open} setOpen={setOpen} />
          </Box>
        </Texture>
      </Dialog>
    </div>
  );
}

ModalPayment.propTypes = {
  open: propTypes.bool,
  setOpen: propTypes.func,
};

ModalPayment.defaultProps = {
  open: false,
  setOpen: null,
};
