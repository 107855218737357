import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Typography from 'core/ui/Typography';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import Grid from 'core/ui/Grid';

import ImageMedia from 'components/Profile/ImageMedia';
import Rating from 'core/ui/Rating';
import LabelBreakLine from 'core/ui/LabelBreakLine';
import ClassCard from 'components/Workout/Grid/Card';
import { useDispatch, useSelector } from 'react-redux';
import { workoutsSelectors } from 'modules/workouts';
import LoaderSm from 'core/ui/LoaderSm';
import { recents } from 'modules/workouts/api';
import InstagramLink from 'components/Profile/InstagramLink';

const CoachInfo = ({ user }) => {
  const { t } = useTranslation();
  const loading = useSelector((state) => workoutsSelectors.getLoading(state));
  const loadingRecents = useSelector((state) => workoutsSelectors.getLoadingRecents(state));
  const dispatch = useDispatch();
  const [workouts, setWorkouts] = useState([]);

  const loadWorkouts = useCallback(async () => {
    const response = await recents({ userId: user.id, pagination: { page: 1, per_page: 4 } });
    setWorkouts(response.data);
  }, [user]);

  useEffect(() => {
    if (user && user.id) {
      loadWorkouts();
    }
  }, [dispatch, user, loadWorkouts]);

  return (
    <Box>
      <Grid container spacing={10}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Box display="flex">
            <Box>
              {!loading && (
                <ImageMedia user={user} image={user.image ? user.image : {}} size={200} />
              )}
              <Box>
                <Typography color="primary" variant="subtitle2">
                  {user.name || user.phone || user.email}
                </Typography>
                <InstagramLink instagram={user.instagram} />
                <Rating readOnly value={user.rating ? user.rating.avg : 0} size="small" />
              </Box>
            </Box>
            <Box pl={5} pt={1}>
              <Typography color="secondary" variant="subtitle2" component="h1">
                {t('description')}
              </Typography>
              <Typography
                className="roboto-regular"
                color="secondary"
                variant="caption"
                component="p"
                align="justify"
              >
                <LabelBreakLine>{user.description}</LabelBreakLine>
              </Typography>
              <Typography color="secondary" variant="subtitle2" component="h1">
                {t('profile.title.achievements')}
              </Typography>
              <Typography
                className="roboto-regular"
                color="secondary"
                variant="caption"
                component="p"
                align="justify"
              >
                {user.certifications && user.certifications.map((e) => e.name).join(', ')}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Typography color="primary" variant="h5" component="h1">
            <Box display="flex" alignItems="center">
              {t('profile.recentTitle')}
              <LoaderSm loading={loadingRecents} width={20} />
            </Box>
          </Typography>
          <Grid mt={2} container spacing={3} className="search-item">
            {workouts &&
              workouts.map((w) => (
                <Grid item xs={12} sm={6} md={6} lg={6} key={w.id} className="item">
                  <ClassCard
                    key={w.id}
                    workout={w}
                    image={w.channel && w.channel.image ? w.channel.image : {}}
                  />
                </Grid>
              ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

CoachInfo.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.object,
};

CoachInfo.defaultProps = {
  user: {},
};

export default CoachInfo;
