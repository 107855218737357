import React from 'react';
import Box from 'core/ui/Box';
import ListWorkout from './ListWorkout';

const ItemProgram = ({ partner, program, setModalFormWorkout, setModalCloneWeek }) => (
  <Box>
    <ListWorkout
      partner={partner}
      partnersProgram={program}
      setModalFormWorkout={setModalFormWorkout}
      setModalCloneWeek={setModalCloneWeek}
    />
  </Box>
);

export default ItemProgram;
