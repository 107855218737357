import api from 'core/api';

export const usersCalendarsWeeklyHoursModule = 'usersCalendarsWeeklyHours';

const loadingFormStates = {
  start: { loadingForm: true },
  error: { loadingForm: false },
  success: { loadingForm: false },
};

const actions = {
  getTimes: {
    module: usersCalendarsWeeklyHoursModule,
    name: 'getTimes',
    api: (params) =>
      api.get(
        `channels/${params?.channelId}/users_calendars/${params?.usersCalendarId}/users_calendars_weekly_hours`,
        { params },
      ),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingFormStates,
  },
  save: {
    module: usersCalendarsWeeklyHoursModule,
    name: 'save',
    api: (data) =>
      api.post(
        `channels/${data?.channelId}/users_calendars/${data?.usersCalendarId}/users_calendars_weekly_hours/save`,
        data,
      ),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingFormStates,
  },
};

const usersCalendarsWeeklyHoursActionsAndStates = {
  actions,
  state: {
    data: [],
    error: null,
    loadingForm: false,
  },
};

export default usersCalendarsWeeklyHoursActionsAndStates;
