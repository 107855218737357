import React from 'react';
import {} from '@material-ui/core';
import gif from 'assets/img/loading-green.gif';
import Box from '../Box';

const LoaderSm = ({ center, ...props }) =>
  props.loading ? (
    <>
      {center ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <img
            src={gif}
            alt="Carregando"
            style={{
              width: props.width ? props.width : 30,
              height: props.width ? props.width : 30,
            }}
          />
        </Box>
      ) : (
        <img
          src={gif}
          alt="Carregando"
          style={{ width: props.width ? props.width : 30, height: props.width ? props.width : 30 }}
        />
      )}
    </>
  ) : null;

export default LoaderSm;
