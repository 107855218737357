import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import styled from 'styled-components';
import Box from '../Box';

export const Tag = styled(({ label, onDelete, ...props }) => (
  <div {...props}>
    <span>{label?.name || label}</span>
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      className="change-icon"
      style={{ background: '#404041', height: 35, width: 25 }}
    >
      <LocalOfferIcon style={{ color: 'white' }} onClick={onDelete} />
      <CloseIcon style={{ color: 'white', fontSize: 14 }} onClick={onDelete} />
    </Box>
  </div>
))`
  display: flex;
  align-items: center;
  margin: 4px;
  background-color: #bbbdbf;
  border-radius: 5px;
  box-sizing: content-box;
  outline: 0;
  overflow: hidden;
  padding-left: 5px;
  font-family: Roboto;
  font-weight: 500;
  font-size: 12px;
  max-height: 35px;
  min-height: 35px;
  align-items: center;
  &:focus {
    border-color: #40a9ff;
    background-color: #e6f7ff;
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 10px;
  }

  .change-icon > .MuiSvgIcon-root + .MuiSvgIcon-root,
  .change-icon:hover > .MuiSvgIcon-root {
    display: none;
  }
  .change-icon:hover > .MuiSvgIcon-root + .MuiSvgIcon-root {
    display: inherit;
  }

  & svg {
    font-size: 12px;
    cursor: pointer;
    padding: 4px;
  }
`;

export const InputWrapper = styled('div')`
  width: 100%;
  background-color: #fff;
  -webkit-box-shadow: 2px 1px 16px -12px rgba(41, 41, 41, 0.63);
  -moz-box-shadow: 2px 1px 16px -12px rgba(41, 41, 41, 0.63);
  box-shadow: 2px 1px 16px -12px rgba(41, 41, 41, 0.63);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-radius: 5px;
  min-height: 45px;
  & input {
    font-size: 12px;
    height: 30px;
    box-sizing: border-box;
    padding: 4px 6px;
    border-radius: 5px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
`;

export const Listbox = styled('ul')`
  padding: 0;
  list-style: none;
  max-height: 300px;
  max-width: 300px;
  background: #fff;
  z-index: 1000;

  & div {
    display: flex;
    font-size: 12px;
    font-family: Roboto;
    font-weight: 500;
    cursor: pointer;
    & span {
      flex-grow: 1;
    }
  }

  & div[aria-selected='true'] {
    background-color: #fafafa;
    color: #ec4242;
    & svg {
      color: #414042;
    }
  }

  & div[data-focus='true'] {
    color: #ec4242;
    cursor: pointer;

    & svg {
      color: #ec4242;
    }
  }
`;
