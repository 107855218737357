import React, { useEffect } from 'react';
import Schedules from 'components/Channel/Show/Tabs/Schedules/index';
import { useDispatch, useSelector } from 'react-redux';
import { channelsActions, channelsSelectors } from 'modules/channels';
import { useParams } from 'react-router';
import Grid from 'core/ui/Grid';
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core';
import Box from 'core/ui/Box';

const CalendarScheduleChannel = () => {
  const { channelId } = useParams();
  const dispatch = useDispatch();
  const channel = useSelector((state) => channelsSelectors.getChannel(state));
  const loading = useSelector((state) => channelsSelectors.getLoadingChannel(state));

  const useStyles = makeStyles(() => ({
    root: {
      backgroundColor: '#f3f3f3',
      overflow: 'scroll',
      minHeight: '100vh',
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    if (channelId) {
      dispatch(channelsActions.getChannelStart(channelId));
    }
  }, [dispatch, channelId]);

  return (
    <Box p={5} className={classes.root}>
      {!loading && channel && channel.id && (
        <Schedules channel={channel} isCoach={false} hidePrograms embed />
      )}

      {loading && (
        <Grid container spacing={3} style={{ width: '100%' }}>
          {[...Array(9)].map((v, index) => (
            <Grid key={index.toString()} item xs={12} sm={6} md={4} lg={4}>
              <Skeleton variant="rect" width="100%" height="200px" />
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

CalendarScheduleChannel.propTypes = {};

export default CalendarScheduleChannel;
