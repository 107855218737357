import api from 'core/api';

export const newWorkoutsModule = 'newWorkouts';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const loadingCheckinStates = {
  start: { loadingCheckin: true },
  error: { loadingCheckin: false },
  success: { loadingCheckin: false },
};

const loadingAddCoachStates = {
  start: { loadingAddCoach: true },
  error: { loadingAddCoach: false },
  success: { loadingAddCoach: false },
};

const actions = {
  createWorkout: {
    module: newWorkoutsModule,
    name: 'createWorkout',
    api: (data) => api.post(`/workouts`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  cloneFromModal: {
    module: newWorkoutsModule,
    name: 'cloneFromModal',
    api: (data) => api.post(`/workouts/clone`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  updateWorkout: {
    module: newWorkoutsModule,
    name: 'updateWorkout',
    api: (data) => api.put(`/workouts/${data.id}`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  getWorkout: {
    module: newWorkoutsModule,
    name: 'getWorkout',
    api: ({ id }) => api.get(`/workouts/${id}`),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  registerWaitList: {
    module: newWorkoutsModule,
    name: 'registerWaitList',
    api: (params) => api.post(`/workouts/${params?.workoutId}/waitlist/register`, params),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  cancelClass: {
    module: newWorkoutsModule,
    name: 'cancelClass',
    api: ({ id }) => api.delete(`/workouts/${id}`),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  checkInUsers: {
    module: newWorkoutsModule,
    name: 'checkInUsers',
    api: (params) => api.put(`/workouts_users/${params.workoutId}/check_in_user`, params),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingCheckinStates,
  },
  deleteUserScore: {
    module: newWorkoutsModule,
    name: 'deleteUserScore',
    api: (data) =>
      api.delete(`/workouts/${data.workoutId}/destroy_section_score`, { params: data }),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  getLeaderboardUser: {
    module: newWorkoutsModule,
    name: 'getLeaderboardUser',
    api: (params) =>
      api.get(`/workouts/${params?.workoutId}/section/${params?.sectionId}/leaderboard`, {
        params,
      }),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  addCoachWorkout: {
    module: newWorkoutsModule,
    name: 'addCoachWorkout',
    api: (params) => api.put(`/workouts/${params.workoutId}/add_coach/${params.userId}`, params),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingAddCoachStates,
  },
};

const newWorkoutsModuleObj = {
  actions,
  state: {
    loading: false,
    loadingCheckin: false,
  },
};

export default newWorkoutsModuleObj;
