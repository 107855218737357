import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from 'core/ui/Box';
import FormPenaltyScores from './FormPenaltyScores';

export default function ModalPenalty({
  open,
  workout,
  close,
  currentScores,
  section,
  user,
  saveCallback,
}) {
  return (
    <div>
      <Dialog
        PaperProps={{ style: { borderRadius: 5 } }}
        maxWidth="xs"
        scroll="body"
        onClose={() => close()}
        open={open}
      >
        <Box p={8} style={{ backgroundColor: '#ececec' }}>
          <FormPenaltyScores
            sectionData={section}
            workout={workout}
            currentScores={currentScores}
            showSaveButton
            saveCallback={saveCallback}
            user={user}
          />
        </Box>
      </Dialog>
    </div>
  );
}
