import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import Typography from 'core/ui/Typography';

import Checkbox from 'core/ui/Checkbox';
import { WorkoutContext } from '../index';

export default () => {
  const { t } = useTranslation();
  const formContext = useContext(WorkoutContext);

  const checkMembershipPlanBlock = (plan) =>
    !!formContext.membershipPlanBlocks.find((x) => x.id === plan.id);

  const changeMembershipPlanBlock = (plan) => {
    const arr = [...formContext.membershipPlanBlocks];
    if (!checkMembershipPlanBlock(plan)) {
      arr.push(plan);
      formContext.setMembershipPlanBlocks(arr);
    } else {
      formContext.setMembershipPlanBlocks(arr.filter((x) => x.id !== plan.id));
    }
  };

  const memberships = !formContext.watch('channelsWorkoutsTypeId')
    ? formContext?.selectedChannel?.membershipAllPlans
    : formContext?.workoutType?.channelMembershipPlans || [];

  return (
    <Box pt={3} pb={3}>
      <Typography color="primary" variant="h5">
        {t('workout.channel.membershipsAccess')}
      </Typography>

      <Box mt={2} display="flex" flexWrap="wrap">
        {memberships
          .filter((x) => x.active)
          .filter((x) => (formContext.watch('inPerson') && x.inPerson) || x.online)
          .filter((x) => (formContext.watch('online') && x.online) || x.inPerson)
          .map((plan, index) => (
            <Box
              mx={1}
              onClick={() => changeMembershipPlanBlock(plan)}
              display="flex"
              className="link-hover"
              key={index.toString()}
            >
              <Checkbox checked={!checkMembershipPlanBlock(plan)} onClick={() => {}} />
              <Typography variant="caption" ml={1} color="secondary">
                {plan.name || 'MEMBERSHIPS'}
              </Typography>
            </Box>
          ))}
      </Box>
    </Box>
  );
};
