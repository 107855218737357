import api from 'core/api';

export const channelsCoachesRatesModule = 'channelsCoachesRates';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const actions = {
  postChannelsCoachesRates: {
    module: channelsCoachesRatesModule,
    name: 'postChannelsCoachesRates',
    api: (data) => api.post(`/channels/${data?.channelId}/channels_coaches_rates`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  putChannelsCoachesRates: {
    module: channelsCoachesRatesModule,
    name: 'putChannelsCoachesRates',
    api: (data) => api.put(`/channels/${data?.channelId}/channels_coaches_rates/${data?.id}`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  destroy: {
    module: channelsCoachesRatesModule,
    name: 'destroy',
    api: (data) => api.delete(`/channels/${data?.channelId}/channels_coaches_rates/${data?.id}`),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  getChannelsCoachesRates: {
    module: channelsCoachesRatesModule,
    name: 'getChannelsCoachesRates',
    api: (params) => api.get(`/channels/${params?.channelId}/channels_coaches_rates`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: ['data'],
    },
    state: loadingStates,
  },
};

export default {
  actions,
  state: {
    data: [],
    error: null,
    loading: false,
  },
};
