import React from 'react';
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import Box from 'core/ui/Box';
import Texture from 'core/ui/Texture';
import Button, { ButtonPrimary } from 'core/ui/Button';
import { Send } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { secondaryBgModal } from 'core/ui/Colors';
import Divider from 'core/ui/Divider';
import FormSend from './FormSend';
import useForm from './useForm';

export default function ModalFormMessage({ item, open, close, callback, competition }) {
  const textService = useForm({ item, competition, callback });
  const { t } = useTranslation();
  return (
    <Dialog open={open} maxWidth="xl" fullWidth onClose={() => close()} scroll="body">
      <Texture>
        <DialogContent>
          <Box p={3}>
            <FormSend textService={textService} competition={competition} />
          </Box>
        </DialogContent>
        <Divider m={3} />
      </Texture>

      <DialogActions style={{ backgroundColor: secondaryBgModal }}>
        <Box display="flex" px={3}>
          <Button style={{ marginRight: 5 }} onClick={close} color="primary">
            Cancel
          </Button>
          <ButtonPrimary
            disabled={textService.loadingSend}
            onClick={() => textService.handleSubmit(textService.onSubmit)()}
          >
            <Send style={{ fontSize: 15 }} />
            &nbsp;{t('button.save')}
          </ButtonPrimary>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
