import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import Texture from 'core/ui/Texture';

import AdminChannelSelector from 'components/Admin/ChannelSelector';
import { useDispatch, useSelector } from 'react-redux';
import { sessionActions, sessionSelectors } from 'modules/session';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import { useHistory } from 'react-router';
import { ButtonPrimary } from 'core/ui/Button';

export default function ModalChannelProfileSelector({ noRedirect, close }) {
  const { t } = useTranslation();
  const { request } = useActions();
  const dispatch = useDispatch();
  const history = useHistory();
  const isCoach = useSelector((state) => sessionSelectors.getIsCoach(state));

  const saveChannel = (channel) => {
    request({
      action: Modules.channelUsers.actions.setChannelMemberApp,
      data: { channelId: channel?.id },
      options: {
        onSuccess: () => {
          dispatch(sessionActions.authenticateStart());
          if (!noRedirect) {
            history.push(`/channel/view/${channel.slug}`);
          }
        },
      },
    });
  };

  return (
    <Dialog
      open
      scroll="body"
      PaperProps={{ style: { verticalAlign: 'top' } }}
      onClose={close && close}
      maxWidth="sm"
      fullWidth
    >
      <Texture>
        <Box p={4}>
          {isCoach && (
            <Box display="flex" mb={2} justifyContent="center">
              <ButtonPrimary onClick={() => history.push('/channel/new')}>
                {t('button.createGym')}
              </ButtonPrimary>
            </Box>
          )}
          <AdminChannelSelector
            setSelectedChannel={(c) => saveChannel(c)}
            withoutCollapse
            label={t('profile.label.selectGym')}
          />
        </Box>
      </Texture>
    </Dialog>
  );
}
