import React from 'react';
import Typography from 'core/ui/Typography';
import Box from 'core/ui/Box';
import { secondary } from 'core/ui/Colors';
import SectionItemScores from './SectionItemScores';

const ViewSectionScoresTypes = ({
  scores,
  fontSize,
  userScores,
  scoresFontColor,
  buttonColor,
  fontColor,
}) => {
  if (!scores) {
    return <></>;
  }

  return (
    <>
      {scores.map((itemSectionScore, index) => (
        <Box key={index.toString()} display="flex" alignItems="center">
          <Typography mr={2} style={{ fontSize, color: buttonColor }}>
            {itemSectionScore?.name || `SCORE ${index + 1}`}
          </Typography>
          <Box>
            <SectionItemScores
              sectionScore={itemSectionScore}
              userScore={userScores?.scores ? userScores?.scores[index] : null}
              fontSize={fontSize}
              scoresFontColor={scoresFontColor}
              fontColor={fontColor}
            />
          </Box>
        </Box>
      ))}
    </>
  );
};

ViewSectionScoresTypes.defaultProps = {
  fontSize: 12,
  scoresFontColor: secondary,
};

export default ViewSectionScoresTypes;
