import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import {
  Table,
  TableCell,
  TableContainer,
  TableRow,
  TableBody,
  TableHead,
  Link,
} from '@material-ui/core';
import Box from 'core/ui/Box';
import Texture from 'core/ui/Texture';
import { useTranslation } from 'react-i18next';
import Paper from 'core/ui/Paper';
import Typography from 'core/ui/Typography';
import { date } from 'core/utils/formatters';

const ModalChannels = ({ open, month, close, channels }) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={close} maxWidth="md" fullWidth scroll="body">
      <Texture>
        <Box p={5}>
          <Typography m={4} align="center">
            {month}
          </Typography>
          <TableContainer component={Paper}>
            <Table aria-label="customized table" size="small">
              <TableHead>
                <TableCell>{t('programs.channels.table.name')}</TableCell>
                <TableCell>{t('programs.channels.table.renewAt')}</TableCell>
                <TableCell align="center" />
              </TableHead>
              <TableBody>
                {channels.map((channel, index) => (
                  <TableRow key={index.toString()}>
                    <TableCell>
                      {channel?.id}&nbsp;{channel.name && channel.name.toUpperCase()}
                    </TableCell>
                    <TableCell>{channel?.renewAt && date(channel?.renewAt)}</TableCell>

                    <TableCell>
                      <Link color="primary" target="_blank" href={`/channel/view/${channel.id}`}>
                        {t('button.view')}
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Texture>
    </Dialog>
  );
};

ModalChannels.propTypes = {
  close: PropTypes.func,
};

ModalChannels.defaultProps = {
  close: () => {},
};

export default ModalChannels;
