import React, { useEffect, useState } from 'react';
import Box from 'core/ui/Box';
import { Skeleton } from '@material-ui/lab';
import { ButtonCompetition } from 'core/ui/Button';
import { workoutsActions, workoutsSelectors } from 'modules/workouts';
import { useDispatch, useSelector } from 'react-redux';
import Paper from 'core/ui/Paper';
import Typography from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import ListTeams from './ListTeams';
import JudgeTeam from './JudgeTeam';

const WorkoutJudgeComponent = ({ loading, workout, competition }) => {
  const [currentDivision, setCurrentDivision] = useState(null);
  const leaderboard = useSelector((state) => workoutsSelectors.getWorkoutSectionLeaderboard(state));
  const [view, setView] = useState({ type: 'list' });
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const allowJudge = competition.isCoach || competition.isJudge;
  const hasDivisions = competition.divisions && competition.divisions.length > 0;

  useEffect(() => {
    if (workout && workout.id && competition.id && !allowJudge) {
      history.push(`/competition/view/${competition.id}`);
    }
  }, [history, dispatch, workout, competition, allowJudge]);

  const refreshLeaderboard = () => {
    dispatch(
      workoutsActions.getWorkoutSectionLeaderboardStart(workout.id, 'first', {
        division_id: currentDivision,
      }),
    );
  };

  useEffect(() => {
    if (workout && workout.id && allowJudge && (!hasDivisions || !!currentDivision)) {
      refreshLeaderboard();
    }
    return () => {
      dispatch(workoutsActions.clearWorkoutLeaderboard());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, workout, allowJudge, currentDivision]);

  const goToTeamJudge = (userLeaderboard) => {
    setView({ type: 'TeamWatch', leaderboard: userLeaderboard });
  };

  useEffect(() => {
    if (view.type === 'TeamWatch' && view.leaderboard) {
      const board = leaderboard.find((x) => x.user.id === view.leaderboard.user.id);
      if (board) {
        setView((prev) => ({
          ...prev,
          leaderboard: board,
        }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leaderboard]);

  const onBackClick = () => {
    if (view.type === 'list') {
      history.push(`/competition/view/${competition.id}`);
    } else {
      setView({ type: 'list' });
    }
  };

  if (loading) {
    return (
      <Box my={3} display="flex">
        <Skeleton variant="rect" animation="wave" style={{ height: 200, width: '100%' }} />
      </Box>
    );
  }
  return (
    <Box>
      <Paper className="paper-rounded opacity-80" p={3}>
        <Box display="flex" alignItems="center">
          <Box pl={5} flexGrow={1}>
            <Typography variant="h5" color="secondary">
              {competition.name}
            </Typography>
          </Box>
          <Box>
            <ButtonCompetition
              style={{ backgroundColor: competition.buttonColor }}
              onClick={() => onBackClick()}
            >
              {t(view.type === 'list' ? 'button.backToCompetition' : 'button.back')}
            </ButtonCompetition>
          </Box>
        </Box>
      </Paper>
      <Box my={1}>
        {view.type === 'list' && (
          <ListTeams
            currentDivision={currentDivision}
            setCurrentDivision={setCurrentDivision}
            workout={workout}
            competition={competition}
            goToTeamJudge={goToTeamJudge}
          />
        )}
        {view.type === 'TeamWatch' && (
          <JudgeTeam
            refreshLeaderboard={refreshLeaderboard}
            workout={workout}
            competition={competition}
            leaderboard={view.leaderboard}
          />
        )}
      </Box>
    </Box>
  );
};

export default WorkoutJudgeComponent;
