import React from 'react';

import { HelpVideoItem } from '../../HelpVideoItem';
import { useStyles } from './styles';

export const ListUser = ({ helpVideos }) => {
  const classes = useStyles();

  return (
    <div className={classes.contentContainerStyle}>
      {helpVideos.map((helpVideo) => (
        <div key={helpVideo.id} className={classes.itemWrapper}>
          <HelpVideoItem helpVideo={helpVideo} light />
        </div>
      ))}
    </div>
  );
};
