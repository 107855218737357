import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import Typography from 'core/ui/Typography';
import Paper from 'core/ui/Paper';
import Grid from 'core/ui/Grid';
import CardCompetition from 'components/Competition/Card';
import { competitionsSearchActions, competitionsSearchSelectors } from 'modules/competitionsSearch';
import { useDispatch, useSelector } from 'react-redux';
import Paginator from 'core/ui/Paginator';
import FontIcon from 'core/ui/FontIcon';
import { primary } from 'core/ui/Colors';
import GridSkeleton from '../../core/ui/GridSkeleton';

import './Item.css';

const Recents = () => {
  const { t } = useTranslation();
  const recents = useSelector((state) => competitionsSearchSelectors.getRecents(state));
  const loading = useSelector((state) => competitionsSearchSelectors.getLoadingRecents(state));
  const pagination = useSelector((state) =>
    competitionsSearchSelectors.getRecentsPagination(state),
  );
  const perPage = 8;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(competitionsSearchActions.getRecentsStart({ page: 1, perPage }));
  }, [dispatch]);

  const changePage = (page) => {
    dispatch(competitionsSearchActions.getRecentsStart({ page, perPage }));
  };

  return (
    <>
      {(loading || (recents && recents.length > 0)) && (
        <Paper className="paper-rounded" p={5} mb={5}>
          <Box display="flex" mb={2} alignItems="center">
            <Box flexGrow="1" display="flex" alignItems="center">
              <FontIcon iconName="icon-clock-ok" color={primary} size={14} />
              <Typography ml={1} color="secondary" className="link" variant="h5">
                {t('title.competitions.recents').toUpperCase()}
              </Typography>
            </Box>
          </Box>

          <Box px={2}>
            <Box mt={5}>
              {loading ? (
                <GridSkeleton />
              ) : (
                <Grid container spacing={3}>
                  {recents &&
                    recents.map((object, index) => (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={3}
                        key={index.toString()}
                        className="search-item"
                      >
                        <Box>
                          <CardCompetition key={object.id} competition={object} />
                        </Box>
                      </Grid>
                    ))}
                </Grid>
              )}
            </Box>
            <Box
              mt={5}
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              {!loading && <Paginator pagination={pagination} changePage={(p) => changePage(p)} />}
            </Box>
          </Box>
        </Paper>
      )}
    </>
  );
};

export default Recents;

Recents.propTypes = {};

Recents.defaultProps = {};
