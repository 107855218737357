import React, { useEffect, useState } from 'react';
import Grid from 'core/ui/Grid';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import PersonalRecordList from './List';
import PersonalRecordInfo from './Info';
import PersonalRecordHistory from './History';
import ModalFormHistory from './Form/Modal';

const PersonalRecord = () => {
  const [selectedPr, setSelectedPr] = useState(null);
  const [modalFormHistory, setModalFormHistory] = useState(false);
  const { request } = useActions();
  const openModalFormPr = () => {
    setModalFormHistory(true);
  };

  const [data, setData] = useState(null);

  const getHistoryScore = () => {
    request({
      action: Modules.usersHistoryScores.actions.getCompareHistory,
      data: {
        personalRecordId: selectedPr?.id,
      },
      options: {
        onSuccess: (resp) => {
          setData(resp);
        },
        onError: () => {
          setData(null);
        },
      },
    });
  };

  useEffect(() => {
    if (selectedPr && selectedPr.id) {
      getHistoryScore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPr]);

  return (
    <>
      <ModalFormHistory
        selectedPr={selectedPr}
        open={modalFormHistory}
        close={() => setModalFormHistory(false)}
        callback={() => {
          setModalFormHistory(false);
          getHistoryScore();
        }}
      />
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <PersonalRecordList setSelectedPr={setSelectedPr} selectedPr={selectedPr} />
        </Grid>
        <Grid item xs={12} md={6}>
          {selectedPr && <PersonalRecordInfo addNewPr={openModalFormPr} selectedPr={selectedPr} />}
          {selectedPr && <PersonalRecordHistory data={data} getHistoryScore={getHistoryScore} />}
        </Grid>
      </Grid>
    </>
  );
};

export default PersonalRecord;
