import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import TimeSelector from 'core/ui/TimeSelector';
import Typography from 'core/ui/Typography';
import Texture from 'core/ui/Texture';

export default function DialogConfirmTime({ open, setOpen, confirm, cancel, time }) {
  const { t } = useTranslation();
  const [editTime, setEditTime] = useState({});

  const setTimeSelector = (value) => {
    if (!value) {
      return {};
    }
    const workTimer = value.split(':');
    return {
      hours: workTimer && workTimer[0],
      minutes: workTimer && workTimer[1],
      seconds: workTimer && workTimer[2],
      value,
    };
  };

  useEffect(() => {
    setEditTime(setTimeSelector(time));
  }, [time]); //eslint-disable-line

  const handleConfirm = () => {
    if (confirm) {
      confirm(editTime.value);
    }
    if (setOpen) {
      setOpen(false);
    }
  };

  const handleCancel = () => {
    if (cancel) {
      cancel();
    }
    if (setOpen) {
      setOpen(false);
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Texture>
          <DialogContent style={{ minHeight: 130 }}>
            <Typography mb={3}>{t('workout.score.confirm.done')}</Typography>
            <TimeSelector time={editTime} setTime={setEditTime} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel} color="primary">
              {t('button.no')}
            </Button>
            <Button onClick={handleConfirm} color="primary" autoFocus>
              {t('button.yes')}
            </Button>
          </DialogActions>
        </Texture>
      </Dialog>
    </div>
  );
}

DialogConfirmTime.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  confirm: PropTypes.func,
  cancel: PropTypes.func,
  time: PropTypes.string,
};

DialogConfirmTime.defaultProps = {
  open: false,
  setOpen: null,
  confirm: null,
  cancel: null,
  time: 0,
};
