import React, { useState } from 'react';
import Box from 'core/ui/Box';
import { Dialog } from '@material-ui/core';
import Button, { ButtonPrimarySm } from 'core/ui/Button';
import Texture from 'core/ui/Texture';
import FontIcon from 'core/ui/FontIcon';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import { useDispatch } from 'react-redux';
import { workoutsActions } from 'modules/workouts';
import Typography from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import notifications from 'modules/notifications';
import ListHeats from './ListHeats';
import CardUser from './CardUser';

const Heats = ({ open, setOpen, workout, buttonColor }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { request } = useActions();
  const [modalSelectUser, setModalSelectUser] = useState(false);

  const registerInHeat = (heat, userId = null) => {
    request({
      action: Modules.newWorkoutHeats.actions.addUser,
      data: {
        workoutId: workout?.id,
        heatId: heat?.id,
        userId,
      },
      options: {
        onSuccess: () => {
          dispatch(workoutsActions.getWorkoutStart(workout?.id));
        },
        onError: (resp) => {
          dispatch(notifications.warning(resp || t('workout.heat.validation.user')));
        },
      },
    });
  };

  const removeMember = (userId, heatId) => {
    request({
      action: Modules.newWorkoutHeats.actions.removeUser,
      data: {
        workoutId: workout?.id,
        heatId,
        userId,
      },
      options: {
        onSuccess: () => {
          dispatch(workoutsActions.getWorkoutStart(workout?.id));
        },
        onError: (resp) => {
          dispatch(notifications.warning(resp));
        },
      },
    });
  };

  const onSelect = (user) => {
    if (modalSelectUser?.heat) {
      registerInHeat(modalSelectUser?.heat, user?.id);
    }
  };

  const checkUserHeat = (user) =>
    !workout?.workoutHeats?.find((heat) => !!heat.users.find((x) => x.id === user?.id));

  const allAthletes = workout?.users?.filter((x) => checkUserHeat(x?.user));
  const allCoaches = workout?.coaches?.filter((x) => checkUserHeat(x));

  return (
    <Box>
      <Button onClick={() => setOpen(true)}>
        <FontIcon iconName="icon-fire" color="white" size={28} />
      </Button>
      <Dialog
        PaperProps={{ style: { borderRadius: 10 } }}
        style={{ borderRadius: 15 }}
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="md"
        fullWidth
        scroll="body"
      >
        <Texture>
          <Box p={3}>
            <Typography m={1} mb={3} align="center" variant="h6">
              {t(!workout?.isCoach ? 'workout.heat.select' : 'workout.heat.select.coach')}
            </Typography>

            <ListHeats
              registeredHeat={workout?.registeredHeat}
              buttonColor={buttonColor}
              heats={workout?.workoutHeats || []}
              registerInHeat={workout.registered ? registerInHeat : null}
              addMember={
                workout.isCoach ? (heat) => setModalSelectUser({ open: true, heat }) : null
              }
              removeMember={removeMember}
              isCoach={workout.isCoach}
            />
          </Box>
        </Texture>
      </Dialog>
      {workout?.isCoach && (
        <Dialog
          PaperProps={{ style: { borderRadius: 10 } }}
          style={{ borderRadius: 15 }}
          open={modalSelectUser}
          onClose={() => setModalSelectUser(false)}
          maxWidth="sm"
          fullWidth
          scroll="body"
        >
          <Texture>
            <Box p={3}>
              {allAthletes?.length === 0 && allCoaches?.length === 0 && (
                <Typography m={5}>{t('workout.heat.allRegistered')}</Typography>
              )}
              {allAthletes?.length > 0 && (
                <>
                  <Typography mb={2} variant="h5" component="h4">
                    {t('workout.number.athletes')}
                  </Typography>

                  <Box mb={5} display="flex" flexWrap="wrap">
                    {allAthletes.map((athlete) => (
                      <Box key={athlete?.user?.id} mx={1}>
                        <CardUser user={athlete?.user} />
                        <ButtonPrimarySm
                          buttonColor={buttonColor}
                          onClick={() => onSelect(athlete?.user)}
                        >
                          {t('button.select')}
                        </ButtonPrimarySm>
                      </Box>
                    ))}
                  </Box>
                </>
              )}
              {allCoaches?.length > 0 && (
                <>
                  <Typography mb={2} variant="h5" component="h4">
                    {t('workout.coaches')}
                  </Typography>
                  <Box mb={5} display="flex" flexWrap="wrap">
                    {allCoaches?.map((coach) => (
                      <Box key={coach?.id} mx={1}>
                        <CardUser user={coach} />
                        <ButtonPrimarySm buttonColor={buttonColor} onClick={() => onSelect(coach)}>
                          {t('button.select')}
                        </ButtonPrimarySm>
                      </Box>
                    ))}
                  </Box>
                </>
              )}
            </Box>
          </Texture>
        </Dialog>
      )}
    </Box>
  );
};

export default Heats;

Heats.propTypes = {};
