import api from 'core/api';

export const channelUserCreditsModule = 'channelUserCredits';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const actions = {
  getUserCredit: {
    module: channelUserCreditsModule,
    name: 'getUserCredit',
    api: (params) => api.get(`/channels/${params.channelId}/credits/${params.userId}`),
    params: {
      start: ['params'],
      error: [''],
      success: ['userCredit'],
    },
    state: loadingStates,
  },
  createTransaction: {
    module: channelUserCreditsModule,
    name: 'createTransaction',
    api: (params) => api.post(`/channels/${params.channelId}/credits/create_transaction`, params),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
};

export default {
  actions,
  state: {
    loading: false,
    data: [],
    userCredit: {},
  },
};
