import React from 'react';

import Box from 'core/ui/Box';
import Paper from 'core/ui/Paper';
import ProgramViewIndex from './ProgramViewIndex';

const arrayData = [...new Array(7)];

const ProgramView = ({ channel, workouts, calendarInfo, buttonColor, setModalProgram }) => (
  <Paper>
    <Box p={5} className="grid-calendar-week">
      {arrayData.map((indexDay, index) => (
        <ProgramViewIndex
          channel={channel}
          calendarInfo={calendarInfo}
          workouts={workouts}
          buttonColor={buttonColor}
          setModalProgram={setModalProgram}
          indexDay={index}
        />
      ))}
    </Box>
  </Paper>
);

ProgramView.propTypes = {};

ProgramView.defaultProps = {
  close: () => {},
};

export default ProgramView;
