import { all, call, put } from 'redux-saga/effects';

import { getErrorMessage } from 'core/api/api-error';

import notifications from 'modules/notifications';

import * as api from './api';
import { channelListsActions as actions } from './index';

export function* getChannelLists({ channelId, channelEdit = null }) {
  try {
    const response = yield call(api.getChannelLists, channelId, channelEdit);
    const channelLists = response.data;
    yield put(actions.getChannelListsSuccess(channelLists));
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.getChannelListsError(getErrorMessage(error))),
    ]);
  }
}

export function* getUserChannelLists({ userId }) {
  try {
    const response = yield call(api.getUserChannelLists, userId);
    const channelLists = response.data;
    yield put(actions.getUserChannelListsSuccess(channelLists));
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.getUserChannelListsError(getErrorMessage(error))),
    ]);
  }
}

export function* postChannelLists({ data }) {
  try {
    const response = yield call(api.postChannelLists, data.data);
    const channelLists = response.data;
    yield put(actions.postChannelListsSuccess(channelLists));
    if (data.messageSuccess) {
      yield put(notifications.success(data.messageSuccess));
    }
    if (data.callback) {
      data.callback();
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.postChannelListsError(getErrorMessage(error))),
    ]);
  }
}

export function* postWorkoutChannelList({ channelId, listId, workoutId, callback }) {
  try {
    const response = yield call(api.postWorkoutChannelList, { channelId, listId, workoutId });
    const channelLists = response.data;
    yield put(actions.postWorkoutChannelListSuccess(channelLists));
    if (callback) {
      callback();
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.postWorkoutChannelListError(getErrorMessage(error))),
    ]);
  }
}

export function* deleteWorkoutChannelList({ channelId, listId, workoutId, callback }) {
  try {
    const response = yield call(api.deleteWorkoutChannelList, { channelId, listId, workoutId });
    const channelLists = response.data;
    yield put(actions.deleteWorkoutChannelListSuccess(channelLists));
    if (callback) {
      callback();
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.deleteWorkoutChannelListError(getErrorMessage(error))),
    ]);
  }
}

export function* putChannelLists({ data }) {
  try {
    const response = yield call(api.putChannelLists, data.data);
    const channelLists = response.data;
    yield put(actions.putChannelListsSuccess(channelLists));
    if (data.messageSuccess) {
      yield put(notifications.success(data.messageSuccess));
    }
    if (data.callback) {
      data.callback();
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.putChannelListsError(getErrorMessage(error))),
    ]);
  }
}
