import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';

import './style.css';
import clsx from 'clsx';

const Calendar = ({
  calendarRef,
  events,
  eventClick,
  embed,
  headerToolbar,
  backgroundColor,
  rootClasses,
  ...props
}) => {
  const useStyles = makeStyles({
    root: embed
      ? {
          position: 'relative',
          backgroundColor: '#fff',
          top: 10,
          bottom: 10,
          right: 0,
          left: 0,
        }
      : {
          flexGrow: 1,
          width: '100%',
          backgroundColor: backgroundColor || '#fff',
        },
  });

  const classes = useStyles();
  const fullCalendarRef = useRef();

  return (
    <div className={clsx(classes.root, rootClasses)}>
      <FullCalendar
        contentHeight="auto"
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, momentTimezonePlugin]}
        headerToolbar={headerToolbar}
        editable={false}
        selectable={false}
        selectMirror
        weekends
        expandRows
        ref={(ref) => {
          fullCalendarRef.current = ref;
          calendarRef(fullCalendarRef);
        }}
        allDaySlot={false}
        events={events}
        eventClick={eventClick}
        {...props}
      />
    </div>
  );
};

Calendar.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  calendarRef: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  events: PropTypes.array,
  eventClick: PropTypes.func.isRequired,
  embed: PropTypes.bool,
  headerToolbar: PropTypes.bool,
};

Calendar.defaultProps = {
  events: [],
  embed: false,
  headerToolbar: false,
  calendarRef: null,
};

export default Calendar;
