import React, { forwardRef, useEffect, useState } from 'react';
import { Chip, CircularProgress, Tooltip } from '@material-ui/core';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import clsx from 'clsx';
import moment from 'moment';

export const ReportChip = ({ value, onClick }) => {
  const [loading, setLoading] = useState(false);

  const newOnClick = () => {
    setLoading(true);
    onClick();
  };

  useEffect(() => {
    setLoading(false);
  }, [value]);

  if (value.denial)
    return (
      <Tooltip title="Denied">
        <LoadingChip
          loading={loading}
          onClick={newOnClick}
          label={<CheckCircleOutlineIcon className="tw-my-auto tw-block" />}
          className="tw-bg-green-500 tw-text-white hover:tw-bg-green-600 focus:tw-bg-green-600"
        />
      </Tooltip>
    );

  if (value.willExecute)
    return (
      <Tooltip title={`Email planned to: ${moment(value.willExecute.plannedTo).toString()}`}>
        <Chip
          loading={loading}
          onClick={newOnClick}
          label={<CalendarTodayOutlinedIcon className="tw-my-auto tw-block" />}
          className="tw-bg-neutral-500 tw-text-white hover:tw-bg-neutral-600 focus:tw-bg-neutral-600"
        />
      </Tooltip>
    );

  if (value.shouldRunByRules && value.execution)
    return (
      <Tooltip title={`Email sent at: ${moment(value.execution.createdAt).toString()}`}>
        <Chip
          label={<MailOutlineIcon className="tw-my-auto tw-block" />}
          className="tw-bg-red-500 tw-text-white"
        />
      </Tooltip>
    );

  if (value.shouldRunByRules) return '-';

  if (value.execution)
    return (
      <Tooltip title={`Email sent at: ${moment(value.execution.createdAt).toString()}`}>
        <Chip
          label={<CheckCircleOutlineIcon className="tw-my-auto tw-block" />}
          className="tw-bg-green-500 tw-text-white"
        />
      </Tooltip>
    );

  return (
    <Chip
      label={<CheckCircleOutlineIcon className="tw-my-auto tw-block" />}
      className="tw-bg-green-500 tw-text-white"
    />
  );
};

const LoadingChip = forwardRef(({ loading, label, ...props }, ref) => (
  <Chip
    ref={ref}
    disabled={loading}
    label={
      <div className="tw-relative">
        <span className={clsx(loading && 'tw-opacity-0')}>{label}</span>

        {loading && (
          <div className="tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center">
            <CircularProgress color="inherit" size={14} />
          </div>
        )}
      </div>
    }
    {...props}
  />
));
