import api from 'core/api';

export const channelProgramsModule = 'channelPrograms';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const actions = {
  getChannelPrograms: {
    module: channelProgramsModule,
    name: 'getChannelPrograms',
    api: ({ channelId }) => api.get(`/channels/${channelId}/channel_programs`),
    params: {
      start: ['params'],
      error: [''],
      success: ['data'],
    },
    state: loadingStates,
  },
  getChannelProgram: {
    module: channelProgramsModule,
    name: 'getChannelProgram',
    api: ({ channelId, channelProgramId }) =>
      api.get(`/channels/${channelId}/channel_programs/${channelProgramId}`),
    params: {
      start: ['params'],
      error: [''],
      success: ['program'],
    },
    state: loadingStates,
  },
  clearChannelProgram: {
    module: channelProgramsModule,
    name: 'clearChannelProgram',
    params: {
      start: ['params'],
      error: [''],
      success: ['program'],
    },
    state: loadingStates,
  },
  postChannelPrograms: {
    module: channelProgramsModule,
    name: 'postChannelPrograms',
    api: (data) => api.post(`/channels/${data.channelId}/channel_programs`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  putChannelPrograms: {
    module: channelProgramsModule,
    name: 'putChannelPrograms',
    api: (data) => api.put(`/channels/${data.channelId}/channel_programs/${data.listId}`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
};

export default {
  actions,
  state: {
    loading: false,
    data: [],
    program: null,
  },
};
