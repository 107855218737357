import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from 'core/ui/Box';
import Texture from 'core/ui/Texture';

import Form from './Form';

const ModalFormCalendar = ({ open, item, close, callback, channel }) => (
  <Dialog open={open} onClose={close} maxWidth="md" fullWidth scroll="body">
    <Texture>
      <Box p={5}>
        <Form callback={callback} item={item} channel={channel} />
      </Box>
    </Texture>
  </Dialog>
);

ModalFormCalendar.defaultProps = {
  close: () => {},
  userId: null,
};

export default ModalFormCalendar;
