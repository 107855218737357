import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import { Container } from '@material-ui/core';
import { LayoutContext } from 'pages/shared/Layout';
import Reports from 'components/Admin/Reports';
import Texture from 'core/ui/Texture';

export default () => {
  const { t } = useTranslation();

  const layoutContext = useContext(LayoutContext);
  useEffect(() => {
    layoutContext.setTitleBar(t('title.admin.reports').toUpperCase());
    layoutContext.setActiveMenu('adminReports');
  }, [layoutContext, t]);

  return (
    <Reports />
  );
};
