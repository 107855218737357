import React, { useContext, useState } from 'react';
import Box from 'core/ui/Box';
import Grid from 'core/ui/Grid';
import Paper from 'core/ui/Paper';
import CollapseText from 'core/ui/CollapseText';
import LoaderSm from 'core/ui/LoaderSm';
import Typography from 'core/ui/Typography';
import { MapSharp } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import ModalViewMap from 'core/ui/ModalViewMap';
import { Avatar } from '@material-ui/core';
import GeneralInfo from './GeneralInfo';
import { ShowContext } from '../..';

const CompetitionOverview = ({ competition }) => {
  const { t } = useTranslation();
  const showContext = useContext(ShowContext);
  const [modalMap, setModalMap] = useState(false);
  const { fontColor, buttonColor } = showContext;

  const hasLocation = competition?.location?.lat;
  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12} md={hasLocation ? 4 : 6}>
          <GeneralInfo />
        </Grid>
        <Grid item xs={12} md={hasLocation ? 4 : 6}>
          <Paper
            elevation={2}
            className="paper-rounded opacity-80"
            p={3}
            style={{ height: '100%' }}
          >
            {showContext.loading && (
              <Box display="flex" justifyContent="center" py={1.4}>
                <LoaderSm loading={showContext.loading} />
              </Box>
            )}
            <CollapseText text={competition.description} maxChar={380} linkColor={fontColor} />
          </Paper>
        </Grid>
        {hasLocation && !!competition?.location?.name && (
          <Grid item xs={12} md={4}>
            <Paper
              elevation={2}
              p={2}
              className="paper-rounded opacity-80"
              style={{ overflow: 'hidden', height: '100%' }}
            >
              <Box
                className="hover"
                onClick={() => setModalMap(true)}
                display="flex"
                alignItems="center"
              >
                <MapSharp />
                <Typography ml={2} variant="h5">
                  {t('competition.label.location')}
                </Typography>
              </Box>
              <Typography align="center" variant="h6" m={2}>
                {competition?.location?.name}
              </Typography>
              <Box>
                <Typography
                  className="hover"
                  onClick={() => setModalMap(true)}
                  align="center"
                  variant="h6"
                  style={{ color: buttonColor }}
                >
                  {t('button.viewMap')}
                </Typography>
              </Box>
              <ModalViewMap
                location={competition?.location}
                open={modalMap}
                close={() => setModalMap(false)}
                avatar={<Avatar src={competition?.channel?.image?.image?.url} />}
              />
            </Paper>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default CompetitionOverview;
