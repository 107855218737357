import React from 'react';
import CastIcon from '@material-ui/icons/Cast';
import { useTranslation } from 'react-i18next';
import Typography, { fontStyle } from '../Typography';
import Box from '../Box';
import { primary } from '../Colors';

export default ({ buttonColor }) => {
  const { t } = useTranslation();
  const boxStreaming = {
    background: buttonColor || primary,
    width: 70,
    borderRadius: 5,
    zIndex: 1,
  };
  return (
    <Box my={1} display="flex" alignItems="center" style={boxStreaming} px={1} py={0.5}>
      <CastIcon className="recording-effect" style={{ ...fontStyle.small, color: 'white' }} />
      <Typography ml={1} style={{ color: 'white', ...fontStyle.small }}>
        {t('workout.liveNow')}
      </Typography>
    </Box>
  );
};
