import React from 'react';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import Paper from 'core/ui/Paper';
import Typography, { fontStyle } from 'core/ui/Typography';
import { dateExt } from 'core/utils/formatters';
import MIcon from 'core/ui/MIcon';
import { IconButton } from '@material-ui/core';
import { secondary } from 'core/ui/Colors';
import Divider from 'core/ui/Divider';
import { sessionSelectors } from 'modules/session';
import { useSelector } from 'react-redux';
import BenchmarkUserScores from '../Benchmark/BenchmarkUserScores';
import PrScore from '../PersonalRecord/PrScore';

const SectionHistoryScores = ({ scores, removeScore }) => {
  const { t } = useTranslation();
  const currentUser = useSelector((state) => sessionSelectors.getUser(state));

  return (
    <Box mt={3}>
      <Paper className="paper-rounded" p={3}>
        <Box display="flex" alignItems="center">
          <Box display="flex" alignItems="center" flexGrow={1}>
            <Typography variant="h4" color="primary">
              {t('dashboard.personalRecord.history')}
            </Typography>
          </Box>
        </Box>
        {scores && scores.length > 0 && (
          <Box my={3}>
            {scores &&
              scores.map((itemScore, index) => (
                <Box key={index.toString()}>
                  <Box display="flex" alignItems="center">
                    <Box flexGrow={1}>
                      {itemScore?.objectType === 'WorkoutSectionUser' ||
                      itemScore?.objectType === 'BenchmarkSectionUser' ? (
                        <BenchmarkUserScores
                          sectionScores={itemScore?.sectionScores}
                          scores={itemScore?.scores}
                        />
                      ) : (
                        <PrScore
                          scores={itemScore?.scores && itemScore?.scores[0]}
                          scoreType={itemScore?.scoreType}
                        />
                      )}
                    </Box>
                    <Box>
                      <Typography style={fontStyle.medium} align="center">
                        {dateExt(itemScore.updatedAt)}
                      </Typography>
                    </Box>
                    <Box ml={1}>
                      {currentUser?.id === itemScore.userId && removeScore && (
                        <IconButton onClick={() => removeScore(itemScore)}>
                          <MIcon icon="remove_circle" color={secondary} />
                        </IconButton>
                      )}
                    </Box>
                  </Box>
                  {scores?.length > index && <Divider my={3} />}
                </Box>
              ))}
          </Box>
        )}
      </Paper>
    </Box>
  );
};

export default SectionHistoryScores;
