import Partner from 'pages/Partner/index';

export default [
  {
    path: '/partner',
    exact: true,
    name: 'Parnter',
    component: Partner,
  },
  {
    path: '/partner/:partnerId',
    exact: true,
    name: 'Partner',
    component: Partner,
  },
  {
    path: '/partner/:partnerId/:screen',
    exact: true,
    name: 'Partner',
    component: Partner,
  },
  {
    path: '/partner/store',
    exact: true,
    name: 'adminReports',
    component: Partner,
  },
];
