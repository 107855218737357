import api from 'core/api';

export const usersCalendarsModule = 'usersCalendars';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const loadingFormStates = {
  start: { loadingForm: true },
  error: { loadingForm: false },
  success: { loadingForm: false },
};

const loadingEventsStates = {
  start: { loadingEvents: true },
  error: { loadingEvents: false },
  success: { loadingEvents: false },
};

const loadingExternalCalendarStates = {
  start: { loadingExternalCalendar: true },
  error: { loadingExternalCalendar: false },
  success: { loadingExternalCalendar: false },
};

const actions = {
  getCalendars: {
    module: usersCalendarsModule,
    name: 'getCalendars',
    api: (params) => api.get(`channels/${params?.channelId}/users_calendars`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
    isTakeEvery: true,
  },
  getCalendar: {
    module: usersCalendarsModule,
    name: 'getCalendar',
    api: (params) =>
      api.get(`channels/${params?.channelId}/users_calendars/${params?.id}`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingFormStates,
  },
  getCalendarsEvents: {
    module: usersCalendarsModule,
    name: 'getCalendarsEvents',
    api: (params) =>
      api.get(`channels/${params?.channelId}/users_calendars/calendar_events`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingEventsStates,
  },
  create: {
    module: usersCalendarsModule,
    name: 'create',
    api: (data) => api.post(`channels/${data?.channelId}/users_calendars`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingFormStates,
  },
  update: {
    module: usersCalendarsModule,
    name: 'update',
    api: (data) => api.put(`channels/${data?.channelId}/users_calendars/${data?.id}`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingFormStates,
  },
  delete: {
    module: usersCalendarsModule,
    name: 'delete',
    api: (data) => api.delete(`channels/${data?.channelId}/users_calendars/${data?.id}`),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingFormStates,
  },
  connectExternal: {
    module: usersCalendarsModule,
    name: 'connectExternal',
    api: (params) => api.get(`/external_calendars/connect`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingExternalCalendarStates,
  },
  confirmExternal: {
    module: usersCalendarsModule,
    name: 'confirmExternal',
    api: (data) => api.post(`/external_calendars/confirm`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingExternalCalendarStates,
  },
  updateExternalCalendar: {
    module: usersCalendarsModule,
    name: 'updateExternalCalendar',
    api: (data) => api.put(`/external_calendars/select_calendar`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingExternalCalendarStates,
  },
  removeExternalCalendar: {
    module: usersCalendarsModule,
    name: 'removeExternalCalendar',
    api: (params) => api.delete(`/external_calendars/remove`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingExternalCalendarStates,
  },
};

const usersCalendarsActionsAndStates = {
  actions,
  state: {
    data: [],
    error: null,
    loading: false,
    loadingEvents: false,
    loadingForm: false,
  },
};

export default usersCalendarsActionsAndStates;
