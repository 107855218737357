import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import Box from 'core/ui/Box';
import { hhmmss } from 'core/utils/formatters/date';

const Chronometer = ({ timeValue, fontSize, spaceWidth, zoomView }) => {
  const [split, setSplit] = useState({});
  const splitTime = useCallback(() => {
    if (timeValue) {
      const time = hhmmss(timeValue.timeDisplay);
      setSplit({
        h1: time[0],
        h2: time[1],
        m1: time[3],
        m2: time[4],
        s1: time[6],
        s2: time[7],
      });
    }
  }, [timeValue]);

  useEffect(() => {
    splitTime();
  }, [timeValue, splitTime]);

  const classes = useStyles({ spaceWidth, zoomView });

  return (
    <Box
      style={{
        position: 'relative',
        height: fontSize * (zoomView || 1),
      }}
    >
      <Box
        align="center"
        style={{
          fontSize: fontSize * (zoomView || 1),
          lineHeight: 1,
          letterSpacing: 3 * (zoomView || 1),
        }}
        className="digital-font"
      >
        <Box display="flex">
          <Box display="flex" className={classes.boxLetter}>
            <Box className={classes.boxPlaceHolder}>88</Box>
            <Box className={classes.boxValue}>{split.h1 || 0}</Box>
            <Box className={classes.boxValue}>{split.h2 || 0}</Box>
          </Box>
          <Box>:</Box>
          <Box display="flex" className={classes.boxLetter}>
            <Box className={classes.boxPlaceHolder}>88</Box>
            <Box className={classes.boxValue}>{split.m1 || 0}</Box>
            <Box className={classes.boxValue}>{split.m2 || 0}</Box>
          </Box>
          <Box>:</Box>
          <Box display="flex" className={classes.boxLetter}>
            <Box className={classes.boxPlaceHolder}>88</Box>
            <Box className={classes.boxValue}>{split.s1 || 0}</Box>
            <Box className={classes.boxValue}>{split.s2 || 0}</Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles({
  boxPlaceHolder: {
    position: 'absolute',
    top: 0,
    zIndex: 50,
    color: '#f2f2f2',
  },
  boxValue: ({ spaceWidth, zoomView }) => ({
    position: 'relative',
    top: 0,
    zIndex: 50,
    color: '#000',
    width: spaceWidth * (zoomView || 1),
    display: 'block',
    textAlign: 'right',
  }),
  boxLetter: {
    position: 'relative',
  },
});

export default Chronometer;

Chronometer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  timeValue: PropTypes.object,
  fontSize: PropTypes.number,
  spaceWidth: PropTypes.number,
};

Chronometer.defaultProps = {
  timeValue: {},
  fontSize: 50,
  spaceWidth: 27,
};
