/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import Grid from 'core/ui/Grid';
import { useDispatch, useSelector } from 'react-redux';
import ImageMediaUser from 'components/Profile/ImageMedia';
import { workoutsActions, workoutsSelectors } from 'modules/workouts';
import Typography, { fontStyle } from 'core/ui/Typography';
import Paper from 'core/ui/Paper';
import { primary } from 'core/ui/Colors';
import Divider from 'core/ui/Divider';
import LoaderSm from 'core/ui/LoaderSm';
import { Grow, IconButton } from '@material-ui/core';
import { sessionSelectors } from 'modules/session';
import WorkoutScoresPoints from 'components/Competition/Show/Tabs/Results/Tables/WorkoutScoresPoints';
import { ArrowBackIos } from '@material-ui/icons';
import ButtonLikeInfo from 'core/ui/LikeInfo';
import Checkbox from 'core/ui/Checkbox';
import { channelsSelectors } from 'modules/channels';
import { defaultScoresVariations } from 'core/utils/consts';
import { variationScoreName } from 'core/utils/helpers';
import useStylesLeaderboard from './styles';
import LeaderboardInfo from './Info';

const LeaderboardUserItem = ({
  board,
  classes,
  setSelectedBoard,
  refresh,
  buttonColor,
  currentUser,
}) => {
  const channel = useSelector((state) => channelsSelectors.getChannel(state));

  return (
    <Box display="flex" p={3} className={classes.boxUser} onClick={() => setSelectedBoard(board)}>
      <Box>
        <ImageMediaUser
          user={board.user}
          image={board.user.image ? board.user.image : {}}
          size={45}
          sizeCountry="1em"
          heightCountry={18}
        />
      </Box>

      <Box display="flex" flexGrow={1} pl={2}>
        <Typography>{board.user.name}</Typography>
        <Box display="flex" alignItems="center" flexGrow={1} pl={3}>
          <ButtonLikeInfo
            callbackLike={refresh}
            likeableType="WorkoutSectionUser"
            likeableId={board.sectionUserId}
            countLikes={board.countLikes}
            buttonColor={buttonColor}
            liked={board.liked}
          />
        </Box>
      </Box>
      <Box>
        {(!board.isPrivate || board.user.id === currentUser.id) && (
          <Box display="flex" flexWrap="wrap">
            {board?.scores.length > 1 ? (
              <Typography ml={1} style={{ ...fontStyle.medium, color: buttonColor }}>
                Scores
              </Typography>
            ) : (
              <WorkoutScoresPoints
                scores={board.scores}
                sectionScores={board?.sectionScores}
                positions={channel?.showLeaderboardRank ? board?.positions : null}
              />
            )}
          </Box>
        )}
        {board.isPrivate && board.user.id !== currentUser.id && (
          <Typography ml={1} style={{ ...fontStyle.small, color: buttonColor }}>
            Private
          </Typography>
        )}
      </Box>
    </Box>
  );
};

const LeaderboardListType = ({
  channel,
  leaderboard,
  loading,
  classes,
  section,
  refresh,
  buttonColor,
  currentUser,
  setSelectedBoard,
  variation,
}) => {
  const { t } = useTranslation();
  return (
    <Paper elevation={2} mb={3} className="paper-rounded" p={3}>
      <Box display="flex" alignItems="center" mb={1}>
        <Box flexGrow="1" display="flex" alignItems="center">
          <Typography mx={2} color="secondary" variant="h5">
            {section.title}&nbsp;{t('workout.section.leaderboard')}{' '}
            {variationScoreName(channel, variation, t)}
          </Typography>
          <LoaderSm loading={loading} width={24} />
        </Box>
      </Box>
      <Box>
        {leaderboard &&
          leaderboard
            .filter((x) => !!x.scores)
            // eslint-disable-next-line eqeqeq
            .filter((x) => !!x.scores.find((y) => y.variation == variation))
            .map((board, index) => (
              <Box key={index.toString()}>
                <LeaderboardUserItem
                  board={board}
                  classes={classes}
                  setSelectedBoard={setSelectedBoard}
                  refresh={refresh}
                  buttonColor={buttonColor}
                  currentUser={currentUser}
                />
                <Divider />
              </Box>
            ))}
      </Box>
    </Paper>
  );
};

export default ({ workout, section, hideComments, singleView }) => {
  const intervalRef = useRef();
  const dispatch = useDispatch();
  const classes = useStylesLeaderboard();
  const currentUser = useSelector((state) => sessionSelectors.getUser(state));
  const loading = useSelector((state) => workoutsSelectors.getLoadingSectionLeaderboard(state));
  const channel = useSelector((state) => channelsSelectors.getChannel(state));
  const leaderboard = useSelector((state) => workoutsSelectors.getWorkoutSectionLeaderboard(state));
  const [autoRefresh, setAutoRefresh] = useState();
  const [selectedBoard, setSelectedBoard] = useState(null);

  const buttonColor = workout?.channel?.customColors?.buttonColor || primary;
  const isCustomClass = workout.method === 'custom';

  const refresh = () => {
    if (workout && section) {
      dispatch(workoutsActions.getWorkoutSectionLeaderboardStart(workout.id, section.id));
    }
  };

  const variationsOptions =
    channel?.scoresVariations?.length > 0
      ? [...channel?.scoresVariations, ...defaultScoresVariations]
      : defaultScoresVariations;

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, workout, section]);

  useEffect(() => {
    if (autoRefresh) {
      intervalRef.current = setInterval(() => {
        refresh();
      }, 5000);
    } else {
      clearInterval(intervalRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoRefresh]);

  const showList = !selectedBoard || !singleView;

  return (
    <Box flex={1}>
      {selectedBoard && singleView && (
        <Box className="link" onClick={() => setSelectedBoard(null)}>
          <IconButton>
            <ArrowBackIos />
          </IconButton>
        </Box>
      )}
      {showList && (
        <Box display="flex" justifyContent="flex-end" mb={2}>
          <Paper p={1}>
            <Checkbox
              label="Auto Refresh"
              checked={autoRefresh}
              onClick={() => setAutoRefresh(!autoRefresh)}
            />
          </Paper>
        </Box>
      )}
      <Grid container spacing={5}>
        {showList && (
          <>
            {variationsOptions?.map((variation) => (
              <Grid item xs={12} sm={12} md={singleView ? 12 : 6}>
                <LeaderboardListType
                  channel={channel}
                  variation={variation?.id}
                  leaderboard={leaderboard}
                  loading={loading}
                  classes={classes}
                  section={section}
                  refresh={refresh}
                  buttonColor={buttonColor}
                  currentUser={currentUser}
                  setSelectedBoard={setSelectedBoard}
                />
              </Grid>
            ))}
          </>
        )}
      </Grid>

      <Grid container>
        <Grid item xs={12} md={3} />
        <Grid item xs={12} md={singleView ? 12 : 6}>
          <Grow direction="up" in={!!selectedBoard} mountOnEnter unmountOnExit>
            <Box>
              {selectedBoard && (
                <Box mb={10}>
                  <LeaderboardInfo
                    onRemoveScore={() => {
                      setSelectedBoard(null);
                      refresh();
                    }}
                    leaderboard={selectedBoard}
                    isCustomClass={isCustomClass}
                    hideComments={hideComments}
                    buttonColor={buttonColor}
                    showScores={
                      !selectedBoard.isPrivate || selectedBoard.user.id === currentUser.id
                    }
                    workoutId={workout?.id}
                    sectionId={section?.id}
                    allowRemove={selectedBoard.user.id === currentUser.id}
                  />
                </Box>
              )}
            </Box>
          </Grow>
        </Grid>
        <Grid item xs={12} md={3} />
      </Grid>
    </Box>
  );
};
