import React, { useContext, useRef, useState } from 'react';
import Box from 'core/ui/Box';
import Typography from 'core/ui/Typography';
import { Button, Popover } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { primary, secondary } from 'core/ui/Colors';
import Paper from 'core/ui/Paper';
import { useTranslation } from 'react-i18next';
import TimeSelector from 'core/ui/TimeSelector';
import { ButtonCompetition } from 'core/ui/Button';
import { useDispatch, useSelector } from 'react-redux';
import { workoutsActions } from 'modules/workouts';
import { sessionSelectors } from 'modules/session';
import notifications from 'modules/notifications';
import Radio from 'core/ui/Radio';
import { LayoutContext } from 'pages/shared/Layout';
import TextAreaFieldRounded from 'core/ui/TextAreaFieldRounded';

const DEFAULT_FLAG = {
  incorrectTime: false,
  movementViolation: false,
  incorrectRepCount: false,
  description: '',
};
export default ({ workoutId, sectionId, userId, buttonColor, callback }) => {
  const [openPopOver, setOpenPopOver] = useState(false);
  const [flag, setFlag] = useState(DEFAULT_FLAG);
  const { setModalLogin } = useContext(LayoutContext);

  const isAuthenticated = useSelector((state) => sessionSelectors.getIsAuthenticated(state));
  const userSession = useSelector((state) => sessionSelectors.getUser(state));
  const btnRef = useRef();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const styleBox = {
    backgroundColor: primary,
    color: 'white',
  };

  const confirm = () => {
    if (!flag.type) {
      return;
    }

    const timeValue = flag.time && flag.time.value;
    setOpenPopOver(false);
    dispatch(
      workoutsActions.addWorkoutSectionUserFlagStart({
        workoutId,
        data: {
          flagData: {
            ...flag,
            time: timeValue,
            userId: userSession.id,
            userName: userSession.name,
          },
          sectionId,
          userId,
        },
        callback: () => {
          dispatch(notifications.success(t('message.success.flag')));
          setFlag(DEFAULT_FLAG);
          if (callback) {
            callback();
          }
        },
      }),
    );
  };

  return (
    <Box>
      <Button
        ref={btnRef}
        onClick={() => setOpenPopOver(true)}
        style={{
          backgroundColor: primary,
          padding: 0,
          minWidth: 30,
          borderRadius: 10,
        }}
      >
        <MoreHorizIcon style={{ fontSize: 38, color: 'white' }} />
      </Button>
      <Popover
        open={openPopOver}
        anchorEl={btnRef.current}
        onClose={() => setOpenPopOver(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Paper p={5} style={styleBox}>
          <Typography mb={2}>{t('workout.watch.flag.flagForReview').toUpperCase()}</Typography>
          {isAuthenticated ? (
            <>
              <Box mb={2}>
                <Radio
                  style={{ color: 'white' }}
                  checked={flag.type === 'incorrectTime'}
                  onClick={() => setFlag((prev) => ({ ...prev, type: 'incorrectTime' }))}
                  size={28}
                  label={t('workout.watch.flag.incorrectTime')}
                />
              </Box>
              <Box mb={2}>
                <Radio
                  style={{ color: 'white' }}
                  checked={flag.type === 'movementViolation'}
                  onClick={() => setFlag((prev) => ({ ...prev, type: 'movementViolation' }))}
                  size={28}
                  label={t('workout.watch.flag.movementViolation')}
                />
              </Box>
              <Box mb={2}>
                <Radio
                  style={{ color: 'white' }}
                  checked={flag.type === 'incorrectRepCount'}
                  onClick={() => setFlag((prev) => ({ ...prev, type: 'incorrectRepCount' }))}
                  size={28}
                  label={t('workout.watch.flag.incorrectRepCount')}
                />
              </Box>
              <Box mb={3}>
                <Typography align="center" mb={1}>
                  {t('workout.watch.flag.timeViolation').toUpperCase()}
                </Typography>
                <TimeSelector
                  time={flag.time}
                  setTime={(tm) => setFlag((prev) => ({ ...prev, time: tm }))}
                />
              </Box>
              <Box mb={3}>
                <Typography align="center" mb={1}>
                  {t('workout.watch.flag.description').toUpperCase()}
                </Typography>
                <TextAreaFieldRounded
                  value={flag.description}
                  placeholder={t('workout.watch.flag.placeholder.description')}
                  onChange={(v) => setFlag((prev) => ({ ...prev, description: v }))}
                  maxLength={144}
                />
              </Box>
              <Box>
                <ButtonCompetition
                  onClick={() => confirm()}
                  fullWidth
                  style={{ backgroundColor: secondary }}
                >
                  {t('button.confirm')}
                </ButtonCompetition>
              </Box>
            </>
          ) : (
            <>
              <Typography mb={2} variant="h6">
                {t('message.flag.user.login')}
              </Typography>

              <Box display="flex">
                <Box mx={1} flexGrow={1}>
                  <ButtonCompetition
                    onClick={() => {
                      setOpenPopOver(false);
                      setModalLogin({ open: true });
                    }}
                    fullWidth
                    style={{ backgroundColor: buttonColor }}
                  >
                    {t('button.signIn')}
                  </ButtonCompetition>
                </Box>
                <Box mx={1} flexGrow={1}>
                  <ButtonCompetition
                    onClick={() => setOpenPopOver(false)}
                    fullWidth
                    style={{ backgroundColor: secondary }}
                  >
                    {t('button.noThanks')}
                  </ButtonCompetition>
                </Box>
              </Box>
            </>
          )}
        </Paper>
      </Popover>
    </Box>
  );
};
