import { Chip } from '@material-ui/core';
import Box from 'core/ui/Box';
import Grid from 'core/ui/Grid';
import LoaderSm from 'core/ui/LoaderSm';
import Video from 'core/ui/Video';
import useWindowSize from 'core/utils/hook/useWindowSize';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ videoTeam, playing, setSeek, seek, muted, setReady }) => {
  const boxVideo = useRef();
  const { t } = useTranslation();
  const size = useWindowSize();

  const getSize = (rec, newSize) => {
    let width = newSize;
    let height = Math.round((width / rec.resWidth) * rec.resHeight, 0);
    if (height > size.height) {
      height = size.height;
      width = (height / rec.resHeight) * rec.resWidth;
    }
    return {
      width,
      height,
    };
  };

  const onSeek = (newSeek) => {
    setSeek(newSeek);
  };

  const onReady = () => {
    setReady(videoTeam.vimeoUrl);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid ref={boxVideo} item xs={12} sm={12} md={12} lg={12} />
      </Grid>
      {videoTeam.vimeoUrl ? (
        <Box
          style={{
            height: `${
              getSize(videoTeam, boxVideo.current && boxVideo.current.clientWidth).height
            }px`,
            width: `${
              getSize(videoTeam, boxVideo.current && boxVideo.current.clientWidth).width
            }px`,
            position: 'relative',
            borderRadius: 10,
            backgroundColor: 'black',
          }}
        >
          <Video
            controls={false}
            src={videoTeam.vimeoUrl}
            onSeek={(s) => onSeek(s)}
            seek={seek}
            muted={muted}
            onReady={() => onReady()}
            vimeo
            playing={playing && seek <= videoTeam.durationSeconds}
            full
            showPlayButton={false}
            borderRadius={10}
          />
        </Box>
      ) : (
        <Box my={5} display="flex" justifyContent="center">
          <Chip
            icon={<LoaderSm loading />}
            color="primary"
            label={t('workout.competition.processing')}
          />
        </Box>
      )}
    </>
  );
};
