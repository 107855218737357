/* eslint-disable react/prop-types */
import Grid from 'core/ui/Grid';
import React from 'react';

import { useTranslation } from 'react-i18next';
import ByClassData from './ByClassData';

export default function ByClass({ channel, byClass, isCoachView }) {
  const { t } = useTranslation();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12} lg={6}>
        <ByClassData
          title={t('channel.rateTemplate.kind.price_by_class')}
          channel={channel}
          isCoachView={isCoachView}
          byClassData={byClass?.byMoney}
        />
      </Grid>
      <Grid item xs={12} md={12} lg={6}>
        <ByClassData
          title={t('channel.rateTemplate.kind.percent_by_class')}
          channel={channel}
          isCoachView={isCoachView}
          byClassData={byClass?.byPercent}
        />
      </Grid>
    </Grid>
  );
}
