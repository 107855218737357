import React, { useEffect } from 'react';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import Typography, { fontStyle } from 'core/ui/Typography';
import { useSelector } from 'react-redux';
import { channelsSelectors } from 'modules/channels';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { successColor } from 'core/ui/Colors';
import { currency } from 'core/utils/formatters';
import { plansModule } from 'modules/plans';
import useSelectors from 'modules/map/useSelectors';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import Grid from 'core/ui/Grid';
import { ButtonPrimary } from 'core/ui/Button';
import Paper from 'core/ui/Paper';
import useChannelPlan from 'components/Channel/Manage/Tabs/Plans/New/useChannelPlan';
import ModalPayment from 'components/Channel/Manage/Tabs/Plans/ModalPayment';
import { useHistory } from 'react-router';
import EngageImage from 'assets/img/engage-icon.png';

const EngageBanner = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const channel = useSelector((state) => channelsSelectors.getChannel(state));
  const { request } = useActions();
  const plan = useSelectors(plansModule, 'plan');

  const channelPlan = useChannelPlan({ plan: channel?.plan || plan, channel });

  useEffect(() => {
    request({
      action: Modules.plans.actions.getActivePlan,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request]);

  const totalPlanOption = () => {
    const planAmount = channel?.annualRenew ? plan?.annualAmountEngage : Number(plan?.amountEngage);

    return currency(Number(channel?.activePlans?.free?.active ? 0 : planAmount));
  };

  const ItemPlan = ({ label }) => (
    <Box display="flex" alignItems="center">
      <CheckCircleOutlineIcon style={{ color: successColor, fontSize: 14 }} />
      <Typography ml={2} style={fontStyle.large} className="roboto-regular">
        {label}
      </Typography>
    </Box>
  );

  if (channel?.activePlans?.engage?.active) {
    return <></>;
  }

  return (
    <Paper p={3}>
      {channel && channelPlan.openPayment?.open && (
        <ModalPayment
          channel={channel}
          channelPlan={channelPlan}
          setChannelPlan={channelPlan.setChannelPlan}
          open={channelPlan.openPayment?.open}
          allPlans={channelPlan.openPayment?.allPlans}
          close={() => channelPlan.setOpenPayment({ oepn: false })}
          createPaymentMethod={() => history.push(`/channel/manage/${channel.id}/plans`)}
          plan={channel?.plan || plan}
        />
      )}
      <Grid container>
        <Grid item xs={12} md={6}>
          <Box display="flex">
            <Box display="flex" justifyContent="center">
              <img src={EngageImage} alt="Profile" style={{ width: 100 }} />
            </Box>
            <Box ml={2}>
              <Typography my={2} align="center" variant="h4">
                {t('channel.plan.introducingEngage')}
              </Typography>

              <ItemPlan label={t('channel.plan.engage.buildAutomationWorkflows')} />
              <ItemPlan label={t('channel.plan.engage.captureLeadsFromYourWebsite')} />
              <ItemPlan
                label={t('channel.plan.engage.automateConversationsWithTriggersAndActions')}
              />
              <ItemPlan label={t('channel.plan.engage.replyToMessagesOnYourStreamFitApp')} />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box display="flex">
            <Box flexGrow={1}>
              <Box display="flex" alignItems="flex-end">
                <Typography component="span" variant="h2" color="secondary">
                  {t('prefix')}
                  {totalPlanOption()}
                </Typography>
                <Typography
                  component="span"
                  variant="h2"
                  pb={1}
                  color="secondary"
                  style={{ ...fontStyle.medium }}
                >
                  /{t('channel.plan.new.month').toLowerCase()}
                </Typography>
              </Box>
              {/* <Typography>$35 starting in July</Typography> */}
              <ItemPlan label={t('channel.plan.engage.unlimitedWorkflows')} />
              <ItemPlan label={t('channel.plan.engage.unlimitedTextMessages')} />
              <ItemPlan label={t('channel.plan.engage.unlimitedEmails')} />
            </Box>
            <Box mt={4} mr={3}>
              <ButtonPrimary
                onClick={() => channelPlan.buyPlan('engage')}
                style={{ minWidth: 150 }}
              >
                {t('button.getItNow')}
              </ButtonPrimary>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default EngageBanner;
