/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import Typography, { fontStyle } from 'core/ui/Typography';
import Radio from 'core/ui/Radio';
import ColorPicker from 'core/ui/ColorPicker';

const CustomColors = ({ watch, setValue, bordered }) => {
  const { t } = useTranslation();

  return (
    <Box mx={1}>
      <Typography style={fontStyle.label} variant="body1">
        {t('workout.label.calendarColor').toUpperCase()}
      </Typography>
      <Box display="flex" mt={2}>
        <Radio
          label={<>{t('workout.customColors.default')}</>}
          checked={watch('useCalendarColor') === 'channel'}
          onClick={() => setValue('useCalendarColor', 'channel')}
        />
        <Box mx={2}>
          <Radio
            label={<>{t('workout.customColors.coach')}</>}
            checked={watch('useCalendarColor') === 'coach'}
            onClick={() => setValue('useCalendarColor', 'coach')}
          />
        </Box>
        <Box>
          <Radio
            label={<>{t('workout.customColors.custom')}</>}
            checked={watch('useCalendarColor') === 'custom'}
            onClick={() => setValue('useCalendarColor', 'custom')}
          />
        </Box>
      </Box>
      {watch('useCalendarColor') === 'custom' && (
        <Box ml={1} mt={1}>
          <ColorPicker
            label={
              <Typography style={fontStyle.label} variant="body1">
                {t('workout.label.color').toUpperCase()}
              </Typography>
            }
            color={watch('calendarColor')}
            setColor={(v) => setValue('calendarColor', v)}
            bordered={bordered}
          />
        </Box>
      )}
    </Box>
  );
};

export default CustomColors;

CustomColors.propTypes = {};

CustomColors.defaultProps = {};
