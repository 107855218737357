import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import Paper from 'core/ui/Paper';
import Typography from 'core/ui/Typography';
import { ButtonPrimary } from 'core/ui/Button';
import { useSelector, useDispatch } from 'react-redux';
import DialogTitle from 'core/ui/Dialog/DialogTitle';
import LoaderSm from 'core/ui/LoaderSm';
import SelectorPaymentMethod, { PaymentMethodViewType } from 'components/Account/Selector';
import { paymentsActions, paymentsSelectors } from 'modules/payments';
import { timeZoneName } from 'core/utils/formatters/date';
import { NavigateBefore, Warning } from '@material-ui/icons';
import { IconButton, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import notifications from 'modules/notifications';
import { primary, secondary } from 'core/ui/Colors';
import Checkbox from 'core/ui/Checkbox';
import Radio from 'core/ui/Radio';
import FontIcon from 'core/ui/FontIcon';
import { channelsSelectors } from 'modules/channels';
import ModalWaitlist from 'components/Purchase/ModalWaitlist';

export default function PaymentAddMember({ workout, member, close, callback }) {
  const { t } = useTranslation();
  const channel = useSelector((state) => channelsSelectors.getChannel(state));
  const loadingPurchase = useSelector((state) =>
    paymentsSelectors.getLoadingPurchaseWorkoutToMember(state),
  );
  const dispatch = useDispatch();
  const [offlinePayment, setOfflinePayment] = useState({ isOffline: false, type: 'cash' });
  const [price, setPrice] = useState(0);
  const [registerType, setRegisterType] = useState('');
  const [errors, setErrors] = useState([]);
  const [freeDrop, setFreeDrop] = useState(false);
  const [viewMethodPayment, setViewMethodPayment] = useState(PaymentMethodViewType.list);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [limitClass, setLimitClass] = React.useState({ open: false });

  const validate = () => {
    const vErrors = [];
    if (!freeDrop && !offlinePayment.isOffline && !selectedPaymentMethod) {
      vErrors.push(t('channel.modalPayment.validation.selectAccount'));
    }
    if (!freeDrop && (!price || Number(price) <= 0)) {
      vErrors.push(t('workout.modalPayment.validation.price'));
    }
    if (!registerType) {
      vErrors.push('Select register type');
    }
    if (vErrors.length > 0) {
      setErrors(vErrors);
      return false;
    }
    return true;
  };

  const purchase = () => {
    if (!validate()) {
      return;
    }

    dispatch(
      paymentsActions.purchaseWorkoutToMemberStart(
        {
          userPaymentMethodId: selectedPaymentMethod && selectedPaymentMethod.id,
          workoutId: workout.id,
          registerType,
          price: freeDrop && registerType === 'inPerson' ? 0 : price,
          memberId: member && member.id,
          isOffline: offlinePayment.isOffline,
          offlineType: offlinePayment.type,
          timezone: timeZoneName(),
        },
        () => {
          if (callback) {
            callback();
          }
          dispatch(notifications.success(t('channel.store.product.success')));
          close();
        },
        (error) => {
          if (error && error.indexOf('The class limit has been reached') > -1) {
            setLimitClass({ open: true });
          }
        },
      ),
    );
  };

  useEffect(() => {
    if (offlinePayment.isOffline) {
      setViewMethodPayment(PaymentMethodViewType.list);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!offlinePayment.isOffline]);

  useEffect(() => {
    if (!offlinePayment.isOffline && member && member.guest) {
      setViewMethodPayment(PaymentMethodViewType.form);
    }
  }, [member, offlinePayment.isOffline]);

  useEffect(() => {
    if (workout.inPerson && !workout.online) {
      setRegisterType('inPerson');
      return;
    }
    if (!workout.inPerson && workout.online) {
      setRegisterType('online');
      return;
    }
    setRegisterType('inPerson');
  }, [workout]);

  useEffect(() => {
    if (registerType === 'online') {
      setFreeDrop(false);
    }
  }, [registerType]);

  useEffect(() => {
    if (freeDrop) {
      setOfflinePayment({ offline: false, type: 'cash' });
    }
  }, [freeDrop]);

  return (
    <Box>
      <>
        <DialogTitle onClose={() => close()}>
          {t('workout.modalPayment.workoutToMember')}
        </DialogTitle>

        {limitClass.open && (
          <ModalWaitlist
            userId={member?.id}
            open
            close={() => {
              setLimitClass({ open: false });
              close();
            }}
            closePurchase={() => {
              if (callback) {
                callback();
              }
            }}
          />
        )}

        <Box>
          {workout.inPerson === workout.online && (
            <Box
              m={5}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Typography mb={2}>{t('workout.register.location').toUpperCase()}</Typography>
              <Box display="flex">
                <Radio
                  checked={registerType === 'inPerson'}
                  label={t('in-person')}
                  onClick={() => setRegisterType('inPerson')}
                />
                <Box ml={3}>
                  <Radio
                    checked={registerType === 'online'}
                    label={t('online')}
                    onClick={() => setRegisterType('online')}
                  />
                </Box>
              </Box>
            </Box>
          )}
          {registerType === 'inPerson' && (
            <Box
              m={5}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Box display="flex">
                <Radio
                  checked={freeDrop}
                  label={t('workout.register.freeDrop')}
                  onClick={() => setFreeDrop(true)}
                />
                <Box ml={3}>
                  <Radio
                    checked={!freeDrop}
                    label={t('workout.register.customPrice')}
                    onClick={() => setFreeDrop(false)}
                  />
                </Box>
              </Box>
            </Box>
          )}
        </Box>

        <Box mx={5}>
          <Paper className="paper-rounded" mx={2} p={3}>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      <FontIcon size={20} iconName="icon-classes" color={secondary} />
                      <Typography ml={2} color="primary" variant="subtitle1" component="p">
                        {workout.name}:
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell align="center">
                    <Box display="flex" alignItems="center" justifyContent="flex-end">
                      {!freeDrop ? (
                        <Box flexGrow>
                          <TextFieldRounded
                            type="number"
                            value={price || ''}
                            pattern="[0-9]*"
                            placeholder={t('workout.placeholder.price')}
                            label={t('workout.label.price').toUpperCase()}
                            onChange={(v) => setPrice(v > 0 ? v : 0)}
                            required
                            currency
                            bordered
                          />
                        </Box>
                      ) : (
                        <Box>Free</Box>
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
        </Box>

        <>
          {!freeDrop && registerType === 'inPerson' && (
            <Box m={5} display="flex" justifyContent="center" alignItems="center" className="hover">
              <Checkbox
                color="secondary"
                checked={offlinePayment.isOffline}
                onClick={() =>
                  setOfflinePayment((prev) => ({ ...prev, isOffline: !prev.isOffline }))
                }
              />
              <Typography
                ml={2}
                align="center"
                color="primary"
                variant="h4"
                onClick={() =>
                  setOfflinePayment((prev) => ({ ...prev, isOffline: !prev.isOffline }))
                }
              >
                {t('store.offlinePurchase')}
              </Typography>
            </Box>
          )}
          {offlinePayment.isOffline && (
            <Box
              m={5}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Typography mb={2}>
                {t('channel.placeholder.offlinePayments.offlineType').toUpperCase()}
              </Typography>
              <Box display="flex">
                <Radio
                  checked={offlinePayment.type === 'cash'}
                  label={t('channel.placeholder.offlinePayments.cash')}
                  onClick={() => setOfflinePayment((prev) => ({ ...prev, type: 'cash' }))}
                />
                <Box ml={3}>
                  <Radio
                    checked={offlinePayment.type === 'check'}
                    label={t('channel.placeholder.offlinePayments.check')}
                    onClick={() => setOfflinePayment((prev) => ({ ...prev, type: 'check' }))}
                  />
                </Box>
              </Box>
            </Box>
          )}
        </>

        {!freeDrop && !offlinePayment.isOffline && (
          <Box m={5}>
            <>
              {viewMethodPayment === PaymentMethodViewType.form && (
                <Box display="flex" alignItems="center">
                  {(!member || !member.guest) && (
                    <Box>
                      <IconButton onClick={() => setViewMethodPayment(PaymentMethodViewType.list)}>
                        <NavigateBefore fontSize="large" />
                      </IconButton>
                    </Box>
                  )}
                  <Box display="flex" flexGrow={1} justifyContent="center">
                    <Typography mt={3} mb={3} align="center" color="primary" variant="h4">
                      {t(
                        member && member.guest ? 'payment.method.guest.new' : 'payment.method.save',
                      )}
                    </Typography>
                  </Box>
                </Box>
              )}

              <SelectorPaymentMethod
                selectedPaymentMethod={selectedPaymentMethod}
                setSelectedPaymentMethod={setSelectedPaymentMethod}
                view={viewMethodPayment}
                setView={setViewMethodPayment}
                userId={member.id}
                userInfo={{ name: member?.name, phone: member?.phone, email: member?.email }}
                guest={member && member.guest}
                cardpointe={member?.gymConfig?.useCardpointe}
                onlyMethod={channel?.allowAchPayments ? null : 'card'}
              />
            </>
          </Box>
        )}

        {errors.length > 0 && (
          <Box m={5} display="flex" flexDirection="column" justifyContent="center">
            {errors.map((error, index) => (
              <Typography key={index.toString()} color="primary" variant="body2">
                <Warning style={{ fontSize: 16, color: primary }} /> {error}
              </Typography>
            ))}
          </Box>
        )}

        {viewMethodPayment !== PaymentMethodViewType.form && (
          <Box px={5}>
            <ButtonPrimary
              disabled={loadingPurchase}
              onClick={() => purchase()}
              type="submit"
              fullWidth
            >
              <Box display="flex" alignItems="center">
                <LoaderSm loading={loadingPurchase} width={20} />
                <Typography ml={1} component="span">
                  {t('purchase')}
                </Typography>
              </Box>
            </ButtonPrimary>
          </Box>
        )}
      </>
    </Box>
  );
}

PaymentAddMember.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  workout: propTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  member: propTypes.object.isRequired,
  close: propTypes.func,
  callback: propTypes.func,
};

PaymentAddMember.defaultProps = {
  close: null,
  callback: () => {},
};
