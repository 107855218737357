import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import Paper from 'core/ui/Paper';
import Typography from 'core/ui/Typography';
import { ButtonPrimary } from 'core/ui/Button';
import { useDispatch } from 'react-redux';
import DialogTitle from 'core/ui/Dialog/DialogTitle';
import LoaderSm from 'core/ui/LoaderSm';
import Texture from 'core/ui/Texture';
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import Modules from 'modules';
import useActions from 'modules/map/useActions';
import { currency } from 'core/utils/formatters';
import notifications from 'modules/notifications';
import useSelectors from 'modules/map/useSelectors';
import { newPaymentsModule } from 'modules/payments/new';
import Toggle from 'core/ui/Toggle';

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function ModalPaymentPartnerProgram({
  partnersProgram,
  open,
  close,
  callback,
  channel,
}) {
  const { t } = useTranslation();
  const { request } = useActions();
  const dispatch = useDispatch();
  const loading = useSelectors(newPaymentsModule, 'loading');

  const [annualRenewal, setAnnualRenew] = useState(false);

  useEffect(() => {
    setAnnualRenew(false);
  }, [partnersProgram]);

  const purchase = () => {
    if (!channel.defaultPaymentMethod) {
      dispatch(notifications.warning('Add a payment method for the channel'));
      return;
    }
    request({
      action: Modules.newPayments.actions.partnersPrograms,
      data: {
        partnersProgramId: partnersProgram?.id,
        channelId: channel?.id,
        annualRenewal,
      },
      options: {
        onSuccess: () => {
          if (callback) {
            callback();
          }
          close();
        },
        onError: (error) => {
          dispatch(notifications.error(error));
        },
      },
    });
  };

  return (
    <div style={{ minWidth: 300 }}>
      <Dialog scroll="body" fullWidth maxWidth="sm" onClose={() => close()} open={open}>
        <Texture>
          <DialogTitle onClose={() => close()}>
            {t('channel.modalPayment.paymentInformation')}
          </DialogTitle>
          <DialogContent dividers>
            <Box mx={5}>
              {partnersProgram?.allowAnnualCost && (
                <Box display="flex" justifyContent="center" p={2}>
                  <Toggle
                    options={[
                      {
                        label: (
                          <Box>
                            <Typography>Monthly&nbsp;</Typography>
                            <Typography>
                              {t('prefix')}
                              {currency(partnersProgram?.cost)}
                            </Typography>
                          </Box>
                        ),
                        value: false,
                        width: 120,
                      },
                      {
                        label: (
                          <Box>
                            <Typography>Annual&nbsp;</Typography>
                            <Typography>
                              {t('prefix')}
                              {currency(partnersProgram?.annualCost)}
                            </Typography>
                          </Box>
                        ),
                        value: true,
                        width: 120,
                      },
                    ]}
                    value={annualRenewal}
                    onChange={(value) => setAnnualRenew(value)}
                  />
                </Box>
              )}

              <Paper className="paper-rounded" mx={2} p={3}>
                <Table size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Typography color="primary" variant="subtitle1" component="span">
                          {partnersProgram?.name}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography ml={1} variant="subtitle2" component="span">
                          {t('prefix')}
                          {currency(
                            annualRenewal ? partnersProgram?.annualCost : partnersProgram?.cost,
                          )}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Paper>
            </Box>

            {channel.defaultPaymentMethod && (
              <Box my={3} display="flex" justifyContent="center" alignItems="center">
                <Typography variant="h6" align="center" className="roboto-regular">
                  <span style={{ textTransform: 'capitalize' }}>
                    <b>{channel.defaultPaymentMethod.cardFlag}</b>
                  </span>{' '}
                  <Typography
                    className="roboto-regular"
                    variant="h6"
                    component="span"
                    color="textSecondary"
                  >
                    {t('userPaymentMethod.endingIn')} {channel.defaultPaymentMethod.lastFour}
                  </Typography>
                </Typography>
              </Box>
            )}

            {channel.defaultPaymentMethod && (
              <Box display="flex" px={5} justifyContent="flex-end">
                <ButtonPrimary
                  disabled={loading}
                  onClick={() => purchase()}
                  type="submit"
                  fullWidth
                >
                  <Box display="flex" alignItems="center">
                    <LoaderSm loading={loading} width={20} />
                    <Typography ml={1} component="span">
                      {t('purchase')}
                    </Typography>
                  </Box>
                </ButtonPrimary>
              </Box>
            )}
          </DialogContent>
        </Texture>
      </Dialog>
    </div>
  );
}

ModalPaymentPartnerProgram.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  partnersProgram: propTypes.object.isRequired,
  open: propTypes.bool,
  close: propTypes.func,
  callback: propTypes.func,
};

ModalPaymentPartnerProgram.defaultProps = {
  open: false,
  close: null,
  callback: () => {},
};
