/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import { ButtonPrimary } from 'core/ui/Button';
import Texture from 'core/ui/Texture';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import { Dialog } from '@material-ui/core';
import DatePickerRounded from 'core/ui/DatePickers/DatePickerRounded';
import TimePickerRounded from 'core/ui/DatePickers/TimePickerRounded';
import SelectBoxSearch from 'core/ui/SelectBox/SelectBoxSearch';
import { getAllTimezones } from 'core/utils/formatters/date';
import Grid from 'core/ui/Grid';
import TextAreaFieldRounded from 'core/ui/TextAreaFieldRounded';
import { currency } from 'core/utils/formatters';
import useForm from './form';

const ModalFormClock = ({ open, item, close, callback, channel }) => {
  const { t } = useTranslation();
  const { setValue, watch, handleSubmit, onSubmit, errors, loading, coachRates } = useForm({
    item,
    callback,
    channel,
  });

  return (
    <Dialog maxWidth="md" scroll="body" fullWidth onClose={() => close()} open={open}>
      <Texture>
        <Box p={5}>
          <Box mb={3}>
            <SelectBoxObject
              label={t('label.coach').toUpperCase()}
              options={channel.coaches?.map((x) => ({ id: x?.user?.id, name: x?.user?.name }))}
              value={watch('userId')}
              setValue={(v) => setValue('userId', v)}
              error={errors.userId}
              required
            />
          </Box>
          <Box mb={3}>
            <SelectBoxObject
              label={t('label.coach.rate').toUpperCase()}
              options={coachRates
                ?.filter((x) => x?.ratesTemplate?.kind === 'hourly')
                .map((x) => ({
                  id: x?.id,
                  name: `${x?.ratesTemplate?.name} - ${currency(x?.ratesTemplate?.amount)}`,
                }))}
              value={watch('channelsCoachesRateId')}
              setValue={(v) => setValue('channelsCoachesRateId', v)}
              error={errors.channelsCoachesRateId}
              required
            />
          </Box>

          <Grid container spacing={5} my={5} px={3}>
            <Grid item xs={12} md={6}>
              <Box display="flex" alignItems="center" justifyContent="center">
                <Box>
                  <DatePickerRounded
                    label={t('label.startAt').toUpperCase()}
                    onChange={(v) => setValue('startAtDate', v)}
                    value={watch('startAtDate')}
                    disablePast={false}
                    usFormat={channel?.useUsDateFormat}
                    autoOk
                    error={errors?.startAtDate}
                    bordered
                    required
                  />
                </Box>
                &nbsp;
                <TimePickerRounded
                  label="TIME"
                  type="datetime-local"
                  onChange={(v) => setValue('startAtHour', v)}
                  value={watch('startAtHour')}
                  error={errors?.startAtHour}
                  bordered
                  required
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box display="flex" alignItems="center" justifyContent="center">
                <Box>
                  <DatePickerRounded
                    label={t('label.endAt').toUpperCase()}
                    onChange={(v) => setValue('endAtDate', v)}
                    value={watch('endAtDate')}
                    usFormat={channel?.useUsDateFormat}
                    disablePast={false}
                    autoOk
                    error={errors?.endAtDate}
                    bordered
                    required
                  />
                </Box>
                <TimePickerRounded
                  label="TIME"
                  type="datetime-local"
                  onChange={(v) => setValue('endAtHour', v)}
                  value={watch('endAtHour')}
                  error={errors?.endAtHour}
                  bordered
                  required
                />
              </Box>
            </Grid>
          </Grid>

          <Box mb={3}>
            <SelectBoxSearch
              label={t('workout.label.timezone').toUpperCase()}
              options={getAllTimezones()}
              setValue={(v) => setValue('timezone', v)}
              value={watch('timezone')}
              error={errors?.timezone}
              required
            />
          </Box>

          <Box mb={3}>
            <TextAreaFieldRounded
              value={watch('description')}
              onChange={(v) => setValue('description', v)}
              placeholder={t('label.description')}
              error={errors.description}
              label={t('label.description').toUpperCase()}
            />
          </Box>

          <Box mt={3} display="flex" justifyContent="flex-end">
            <ButtonPrimary disabled={loading} onClick={handleSubmit(onSubmit)}>
              {t('button.save')}
            </ButtonPrimary>
          </Box>
        </Box>
      </Texture>
    </Dialog>
  );
};

ModalFormClock.defaultProps = {
  close: () => {},
  callback: () => {},
  open: false,
};

ModalFormClock.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  close: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  callback: PropTypes.func,
  open: PropTypes.bool,
};

export default ModalFormClock;
