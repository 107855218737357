import React, { createContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from 'core/ui/Box';
import { competitionsSelectors } from 'modules/competitions';
import { workoutsSelectors } from 'modules/workouts';
import HelmetMetaData from 'core/ui/SocialMediaButtons/HelmetMetaData';
import { primary, secondary } from 'core/ui/Colors';
import Store from 'components/Channel/Show/Tabs/Store';
import { channelsActions } from 'modules/channels';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import Tabs from './Tabs';
import Workouts from './Tabs/Workouts/index';
import Results from './Tabs/Results';
import ModalRegistration from '../Registration/Modal';
import Overview from './Tabs/Overview/index';
import CompetitionUsers from './Tabs/Athletes';
import VolunteersAndVendors from './Tabs/VolunteersAndVendors';
import ModalEditTeam from '../Registration/New/Teams/ModalEditTeam';
import CompetitionsScoresManage from './Tabs/ScoresManage';

export const ShowContext = createContext();

const CompetitionShow = ({ workoutId, embedTab, tab: paramTab }) => {
  const [tab, setTab] = useState('workouts');
  const loading = useSelector((state) => competitionsSelectors.getLoading(state));
  const loadingEvent = useSelector((state) => workoutsSelectors.getLoading(state));
  const competition = useSelector((state) => competitionsSelectors.getCompetition(state));
  const [openTeamEdit, setOpenTeamEdit] = useState({
    open: false,
  });
  const [openModalRegistration, setOpenModalRegistration] = useState({
    open: false,
    pay: true,
  });
  const dispatch = useDispatch();
  const [selectedWorkout, setSelectedWorkout] = useState(null);
  const [eventTimer, setEventTimer] = useState({ visible: false });

  useEffect(() => {
    if (workoutId) {
      setTab('viewWorkout');
      setSelectedWorkout(workoutId);
    }
  }, [workoutId, competition]);

  useEffect(() => {
    if (embedTab) {
      setTab(embedTab);
    }
  }, [embedTab, competition]);

  useEffect(() => {
    if (paramTab) {
      setTab(paramTab);
    }
  }, [paramTab, competition]);

  useEffect(() => {
    if (competition?.showStore && competition?.channel?.id) {
      dispatch(channelsActions.getChannelStart(competition?.channel?.id));
    }
  }, [dispatch, competition]);

  useEffect(() => {
    if (tab !== 'viewWorkout') {
      setSelectedWorkout(null);
    }
  }, [tab]);

  useEffect(() => {
    if (tab && competition?.id && tab !== 'viewWorkout') {
      window.history.replaceState(
        null,
        'StreamFit',
        `/competition/view/${competition?.slug || competition?.id}/${tab}`,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  const fontColor = competition.fontColor || secondary;
  const buttonColor = competition.buttonColor || primary;

  return (
    <ShowContext.Provider
      value={{
        tab,
        setTab,
        loading: loading || loadingEvent,
        competition,
        setOpenModalRegistration,
        selectedWorkout,
        setSelectedWorkout,
        fontColor,
        buttonColor,
        eventTimer,
        setEventTimer,
        openTeamEdit,
        setOpenTeamEdit,
      }}
    >
      {openTeamEdit.open && (
        <ModalEditTeam
          competition={competition}
          userId={openTeamEdit.userId}
          open={openTeamEdit.open}
          regType={openTeamEdit.regType}
          close={() => setOpenTeamEdit({ open: false })}
          buttonColor={buttonColor}
        />
      )}
      <HelmetMetaData
        title={competition.name}
        description={competition.description}
        image={competition.backgroundImage && competition?.backgroundImage?.image?.size350}
      />
      {openModalRegistration.open && (
        <ModalRegistration
          open
          setTab={setTab}
          pay={openModalRegistration.pay}
          addTeamManually={openModalRegistration.addTeamManually}
          coachTeam={openModalRegistration.coachTeam}
          setOpen={(b) => setOpenModalRegistration((prev) => ({ ...prev, open: b }))}
          buttonColor={buttonColor}
        />
      )}

      {!embedTab && (
        <Box style={{ position: 'relative' }}>
          <Tabs actionsVisible={!eventTimer.visible} />
        </Box>
      )}

      {selectedWorkout && (
        <Box mt={2}>
          <SelectBoxObject
            value={selectedWorkout}
            setValue={(v) => setSelectedWorkout(v)}
            options={competition.workouts}
            height={45}
          />
        </Box>
      )}

      {!embedTab && tab === 'workouts' && (
        <Box mt={2}>
          <Overview competition={competition} />
        </Box>
      )}

      <Box mt={2} flex={1}>
        {(tab === 'workouts' || tab === 'viewWorkout') && !loading && <Workouts />}
        {tab === 'results' && <Results />}
        {tab === 'store' && <Store isCompetition />}
        {tab === 'scores' && <CompetitionsScoresManage competition={competition} />}
        {tab === 'athletes' && <CompetitionUsers competition={competition} />}
        {tab === 'vendor' && <VolunteersAndVendors competition={competition} regType="vendor" />}
        {tab === 'volunteer' && (
          <VolunteersAndVendors competition={competition} regType="volunteer" />
        )}
      </Box>
    </ShowContext.Provider>
  );
};

export default CompetitionShow;
