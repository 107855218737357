import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import { useSelector, useDispatch } from 'react-redux';
import { workoutsActions, workoutsSelectors } from 'modules/workouts';
import { useLocation } from 'react-router';
import ModalChannels from 'components/Profile/Dashboard/Channels/Modal';
import { appActions } from 'modules/app';
import ConfirmDialog from 'core/ui/ConfirmDialog';
import { channelsActions, channelsSelectors } from 'modules/channels';
import Record from 'core/ui/RecordVideo/Record';
import { parse } from 'query-string';
import { competitionsActions, competitionsSelectors } from 'modules/competitions';
import {
  coachScheduleSessionsActions,
  coachScheduleSessionsSelectors,
} from 'modules/coachScheduleSessions';
import FormData from './FormData';
import PopUpValidation from './PopUpValidation';
import useFormWorkout from './form';
import FormQuickData from './FormQuickData';
import ModalUpload from './VideoUpload/ModalUpload';
import ModalConfirmSave from './ModalConfirmSave';

export const WorkoutContext = createContext();

const Form = ({
  workoutId,
  mode,
  channelId,
  callbackSave,
  coachScheduleSessionId,
  workoutRecurringId,
  type,
  compare,
  benchmark,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const formWorkout = useFormWorkout({
    workoutId,
    mode,
    callbackSave,
    workoutRecurringId,
    coachScheduleSessionId,
    type,
    compare,
    benchmark,
  });

  const loading = useSelector((state) => workoutsSelectors.getLoading(state));
  const error = useSelector((state) => workoutsSelectors.getError(state));
  const channels = useSelector((state) => channelsSelectors.getChannels(state));
  const channel = useSelector((state) => channelsSelectors.getChannel(state));
  const competition = useSelector((state) => competitionsSelectors.getCompetition(state));
  const scheduleSession = useSelector((state) => coachScheduleSessionsSelectors.getSession(state));

  const [modalPreviewVideo, setModalPreviewVideo] = useState(false);
  const [invites, setInvites] = useState([]);

  const location = useLocation();

  useEffect(() => {
    if (loading) {
      dispatch(appActions.setLoading(loading));
    }
  }, [loading, dispatch]);

  useEffect(() => {
    formWorkout.setSubmiting(false);

    if (error) {
      dispatch(appActions.setLoading(false));
      formWorkout.setErrorsPopup((prev) => [...prev, error]);
      formWorkout.setPopUpValidation(true);
      dispatch(workoutsActions.clearError());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    const params = parse(location.search);
    if (!params.competitionId) {
      dispatch(channelsActions.getChannelsStart());
      if (channelId) {
        dispatch(channelsActions.getChannelStart(channelId));
      }
    } else {
      dispatch(competitionsActions.getCompetitionStart(params.competitionId));
    }
  }, [dispatch]); //eslint-disable-line

  const setDataToScheduledSession = () => {
    formWorkout.setIsScheduleSession(true);
    if (mode === 'new') {
      dispatch(coachScheduleSessionsActions.getScheduleSessionStart(coachScheduleSessionId));
    } else if (formWorkout.workout.coachScheduleSessionId) {
      dispatch(
        coachScheduleSessionsActions.getScheduleSessionStart(
          formWorkout.workout.coachScheduleSessionId,
        ),
      );
    }

    formWorkout.setSelectedChannel(null);
    formWorkout.setModalChannel(false);

    setTimeout(() => {
      formWorkout.setValue('online', true);
      formWorkout.setValue('isPrivate', true);
    }, 300);
  };

  useEffect(() => {
    if (!formWorkout.isScheduleSession) {
      formWorkout.setIsScheduleSession(false);
      if (channel?.id) {
        formWorkout.setSelectedChannel(channels[0]);
      }
    } else {
      setDataToScheduledSession();
    }

    return () => {
      dispatch(coachScheduleSessionsActions.clearSession());
    };
  }, [channel, formWorkout.isScheduleSession]); //eslint-disable-line

  useEffect(() => {
    if (
      (type === 'classFromTemplate' || mode === 'edit') &&
      formWorkout.workout &&
      formWorkout.workout.channel
    ) {
      dispatch(channelsActions.getChannelStart(formWorkout.workout.channel.id));
    }
  }, [channels, formWorkout.workout, mode]); //eslint-disable-line

  useEffect(() => {
    if (channel?.id) {
      formWorkout.setSelectedChannel(channel);
    }
  }, [channel]); //eslint-disable-line

  useEffect(() => {
    const params = parse(location.search);
    if (params.competitionId && competition) {
      formWorkout.setCompetition(true);
      formWorkout.setSelectedCompetition(competition);
    }
  }, [competition]); //eslint-disable-line

  useEffect(() => {
    if (coachScheduleSessionId) {
      formWorkout.setIsScheduleSession(true);
    } else {
      formWorkout.setIsScheduleSession(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coachScheduleSessionId]);

  const selectChannel = (ch) => {
    if (ch) {
      formWorkout.setSelectedChannel(ch);
      formWorkout.setModalChannel(false);
    }
  };

  return (
    <WorkoutContext.Provider
      value={{
        ...formWorkout,
        invites,
        setInvites,
        mode,
        modalPreviewVideo,
        setModalPreviewVideo,
        workoutRecurringId,
        scheduleSession,

        customClass: formWorkout.type === 'customClass',
        isTemplate: formWorkout.type === 'template',
      }}
    >
      {!formWorkout.competition && (
        <ConfirmDialog
          open={formWorkout.confirmOpen}
          title={t('workout.title.no.editable')}
          description={t('workout.duplicate.message')}
          labelConfirm={t('button.yes')}
          labelCancel={t('button.no')}
          confirm={formWorkout.duplicateClass}
          cancel={() => formWorkout.setConfirmOpen(false)}
        />
      )}
      <Box>
        <Record
          setOpen={setModalPreviewVideo}
          open={modalPreviewVideo}
          setVideo={formWorkout.setVideo}
          duration={formWorkout.competition ? 5 * 60000 : 1 * 60000}
        />
      </Box>
      <PopUpValidation
        errors={formWorkout.errorsPopup}
        setOpen={formWorkout.setPopUpValidation}
        open={formWorkout.popUpValidation}
      />
      <ModalConfirmSave
        modalConfirmSave={formWorkout.modalConfirmSave}
        setModalConfirmSave={formWorkout.setModalConfirmSave}
        channel={formWorkout.selectedChannel}
      />
      <ModalUpload />

      {mode !== 'quick-add' && (
        <Box>
          <Box mb={5}>
            <form onSubmit={formWorkout.handleSubmit(formWorkout.onSubmit)} autoComplete="off">
              <ModalChannels
                select={(ch) => selectChannel(ch)}
                open={formWorkout.modalChannel}
                setOpen={formWorkout.setModalChannel}
              />
              <FormData />
            </form>
          </Box>
        </Box>
      )}

      {mode === 'quick-add' && (
        <form onSubmit={formWorkout.handleSubmit(formWorkout.onSubmit)} autoComplete="off">
          <ModalChannels
            select={(ch) => selectChannel(ch)}
            open={formWorkout.modalChannel}
            setOpen={formWorkout.setModalChannel}
          />
          <FormQuickData />
        </form>
      )}
    </WorkoutContext.Provider>
  );
};

Form.propTypes = {
  mode: PropTypes.string,
  workoutId: PropTypes.oneOfType(PropTypes.string, PropTypes.number),
  channelId: PropTypes.number,
  workoutRecurringId: PropTypes.number,
  callbackSave: PropTypes.func,
  type: PropTypes.string,
  compare: PropTypes.bool,
};

Form.defaultProps = {
  mode: 'new',
  workoutId: null,
  channelId: null,
  compare: false,
  callbackSave: null,
  workoutRecurringId: null,
  type: 'custom',
};

export default Form;
