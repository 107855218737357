/* eslint-disable react/prop-types */
import React from 'react';
import Paper from 'core/ui/Paper';
import Typography from 'core/ui/Typography';
import SFDataTable from 'core/ui/DataTable';
import { channelCurrency, dateTime } from 'core/utils/formatters';
import Box from 'core/ui/Box';
import { secondaryBgLight } from 'core/ui/Colors';

export default function ByHourlyData({ title, channel, byHourly, isCoachView }) {
  const columns = [
    {
      name: 'Coach',
      selector: 'name',
      sortable: true,
      cell: (row) => <Typography>{row?.user?.name}</Typography>,
    },
    {
      name: 'Total Hours',
      cell: (row) => <Typography>{row.totalHours}</Typography>,
    },
    {
      name: 'Total Amount',
      selector: 'total',
      sortable: true,
      cell: (row) => (
        <Typography noWrap>{channelCurrency(row?.total, channel?.currency)}</Typography>
      ),
    },
  ];
  const columnsClocks = [
    {
      name: 'Description',
      selector: 'description',
      sortable: true,
      cell: (row) => <Typography>{row?.clock?.description}</Typography>,
    },
    {
      name: 'Clock in',
      selector: 'startAt',
      sortable: true,
      cell: (row) => <Typography>{dateTime(row?.clock?.startAt)}</Typography>,
    },
    {
      name: 'Clock out',
      selector: 'endAt',
      sortable: true,
      cell: (row) => <Typography>{row?.clock?.endA && dateTime(row?.clock?.endAt)}</Typography>,
    },
    {
      name: 'Total Hours',
      selector: 'hours',
      sortable: true,
      cell: (row) => <Typography>{row?.clock?.hours && row?.clock?.hours}</Typography>,
    },
    {
      name: 'Total Revenue',
      selector: 'hours',
      sortable: true,
      cell: (row) => (
        <Typography noWrap>{channelCurrency(row?.coachRevenue, channel?.currency)}</Typography>
      ),
    },
  ];

  const ExpanableComponent = ({ data: dataExpanded }) => (
    <Box p={5}>
      <SFDataTable noHeader columns={columnsClocks} data={dataExpanded?.clocks} />
    </Box>
  );

  return (
    <Paper
      style={{ width: '100%', border: isCoachView ? `solid 1px ${secondaryBgLight}` : 'none' }}
      py={2}
    >
      <SFDataTable
        title={
          <Box>
            <Typography variant="h5">{title}</Typography>
          </Box>
        }
        columns={columns}
        data={byHourly?.coaches}
        expandableRows
        expandableRowsComponent={<ExpanableComponent />}
        showPaper={false}
      />
      <Box display="flex" justifyContent="flex-end">
        <Paper p={3} mt={3}>
          <Typography variant="h4">
            Total:&nbsp;{channelCurrency(byHourly?.total, channel?.currency)}
          </Typography>
        </Paper>
      </Box>
    </Paper>
  );
}
