import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { IconButton, Link } from '@material-ui/core';
import Typography, { fontStyle } from 'core/ui/Typography';
import Box from 'core/ui/Box';
import SFDataTable from 'core/ui/DataTable';
import ImageMedia from 'components/Profile/ImageMedia';
import LabelPhone from 'core/ui/InputPhone/LabelPhone';
import { Delete } from '@material-ui/icons';
import { ButtonPrimary } from 'core/ui/Button';

const TableCoaches = ({ channelId, coaches, setModalManage, remove, buttonColor }) => {
  const { t } = useTranslation();

  const openManage = (channelCoach) => {
    setModalManage((prev) => ({
      ...prev,
      channelCoach,
      open: true,
    }));
  };

  const columns = [
    {
      selector: 'name',
      cell: (channelCoach) => (
        <Link className="hover" onClick={() => openManage(channelCoach)}>
          <Box my={1}>
            <ImageMedia
              user={channelCoach}
              image={channelCoach?.user?.image ? channelCoach?.user.image : {}}
              size={50}
              heightCountry={15}
              sizeCountry="1em"
            />
          </Box>
        </Link>
      ),
      sortable: true,
      width: '60px',
    },
    {
      name: t('user'),
      selector: 'name',
      cell: (channelCoach) => (
        <Box>
          <Link className="hover" onClick={() => openManage(channelCoach)}>
            <Typography mr={1}>{channelCoach?.user?.name}</Typography>
          </Link>
          <Typography style={fontStyle.small}>{channelCoach?.user?.email}</Typography>
        </Box>
      ),
      sortable: true,
    },
    {
      name: t('phone'),
      selector: 'phone',
      cell: (channelCoach) => (
        <Box>{channelCoach?.user?.phone && <LabelPhone value={channelCoach?.user?.phone} />}</Box>
      ),
      sortable: true,
      width: '220px',
    },
    {
      name: t('accessType'),
      selector: 'accessType',
      sortable: true,
      cell: (channelCoach) => (
        <Typography> {t(`channel.access.type.${channelCoach?.accessType}`)}</Typography>
      ),
    },
    {
      sortable: false,
      align: 'center',
      cell: (channelCoach) => (
        <Link target="_blank" href={`/calendars/manage/${channelId}/${channelCoach?.user?.id}`}>
          {t('button.coachCalendar')}
        </Link>
      ),
    },
    {
      sortable: false,
      align: 'right',
      width: '150px',
      cell: (channelCoach) => (
        <Box display="flex" alignItems="center" justifyContent="end">
          <ButtonPrimary
            buttonColor={buttonColor}
            color="secondary"
            onClick={() => openManage(channelCoach)}
            size="small"
          >
            {t('button.manage')}
          </ButtonPrimary>

          {remove && (
            <Box ml={1}>
              <IconButton color="secondary" p={0} m={0} onClick={() => remove(channelCoach?.id)}>
                <Delete />
              </IconButton>
            </Box>
          )}
        </Box>
      ),
    },
  ];

  return (
    <SFDataTable
      title=""
      showPaper={false}
      columns={columns}
      data={coaches}
      exportData={coaches.map((channelCoach) => ({
        userName: channelCoach?.user?.name,
        userPhone: channelCoach?.user?.phone,
        userEmail: channelCoach?.user?.email,
        accessType: channelCoach.accessType,
      }))}
      showExport
      noDataComponent=""
    />
  );
};

TableCoaches.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  coaches: PropTypes.array,
  setModalManage: PropTypes.func,
};

TableCoaches.defaultProps = {
  coaches: [],
  setModalManage: () => {},
};

export default TableCoaches;
