import api from 'core/api';
import { call, put } from 'redux-saga/effects';

export const mediaFilesAssociationsModule = 'mediaFilesAssociations';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const actions = {
  getMovements: {
    module: mediaFilesAssociationsModule,
    name: 'getMovements',
    api: (params) => api.get(`/media_files/movements`, { params }),
    *sagas(Creators, { params, options }) {
      try {
        const resp = yield call(actions.getMovements.api, params);
        yield put(Creators.getMovementsSuccess(resp.data, resp.pagination));
        if (options.onSuccess) {
          options.onSuccess(resp.data, resp.pagination);
        }
      } catch (error) {
        if (options.onError) {
          options.onError(error);
        }
        yield put(Creators.getMovementsError());
      }
    },
    params: {
      start: ['params'],
      error: [''],
      success: ['movements', 'pagination'],
    },
    state: loadingStates,
  },
  getMediaFiles: {
    module: mediaFilesAssociationsModule,
    name: 'getMediaFiles',
    api: (params) => api.get(`/media_files`, { params }),
    *sagas(Creators, { params, options }) {
      try {
        const resp = yield call(actions.getMediaFiles.api, params);
        yield put(Creators.getMediaFilesSuccess(resp.data, resp.pagination));
        if (options.onSuccess) {
          options.onSuccess(resp.data, resp.pagination);
        }
      } catch (error) {
        if (options.onError) {
          options.onError(error);
        }
        yield put(Creators.getMediaFilesError());
      }
    },
    params: {
      start: ['params'],
      error: [''],
      success: ['mediaFiles', 'paginationMediaFiles'],
    },
    state: loadingStates,
  },
  create: {
    module: mediaFilesAssociationsModule,
    name: 'create',
    api: (data) => api.post(`/media_files`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  removeAssociation: {
    module: mediaFilesAssociationsModule,
    name: 'removeAssociation',
    api: (params) => api.delete(`/media_files/remove_association`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  createAssociation: {
    module: mediaFilesAssociationsModule,
    name: 'createAssociation',
    api: (data) => api.delete(`/media_files/create_association`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  copyStreamMedia: {
    module: mediaFilesAssociationsModule,
    name: 'copyStreamMedia',
    api: (data) => api.get(`/media_files/copy_from_stream`, { params: data }),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
};

export default {
  actions,
  state: {
    loading: false,
    movements: [],
    mediaFiles: [],
    paginationMediaFiles: null,
    pagination: null,
  },
};
