import api from 'core/api';

export const channelsStagesUsersModule = 'channelsStagesUsers';

const loadingFormStates = {
  start: { loadingForm: true },
  error: { loadingForm: false },
  success: { loadingForm: false },
};

const actions = {
  createStageUser: {
    module: channelsStagesUsersModule,
    name: 'createStageUser',
    api: (data) => api.post(`/channels/${data?.channelId}/channels_stages_users`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingFormStates,
  },
  updateStageUser: {
    module: channelsStagesUsersModule,
    name: 'updateStageUser',
    api: (data) => api.put(`/channels/${data?.channelId}/channels_stages_users/${data?.id}`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingFormStates,
  },
  deleteStageUser: {
    module: channelsStagesUsersModule,
    name: 'deleteStageUser',
    api: (data) =>
      api.delete(
        `/channels/${data?.channelId}/channels_stages/${data?.channelsStageId}/channels_stages_users/${data?.id}`,
      ),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingFormStates,
  },
};

const channelsStageActionsAndStates = {
  actions,
  state: {
    data: [],
    error: null,
    loadingForm: false,
  },
};

export default channelsStageActionsAndStates;
