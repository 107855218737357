/* eslint-disable react/prop-types */
import React from 'react';
import Box from 'core/ui/Box';
import Typography, { fontStyle } from 'core/ui/Typography';
import { Tooltip } from '@material-ui/core';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import { successColor } from 'core/ui/Colors';
import { dateTime } from 'core/utils/formatters';

const { useTranslation } = require('react-i18next');

export default ({ payment }) => {
  const { t } = useTranslation();

  return (
    <Box display="flex" alignItems="center">
      <Tooltip title={`ID: ${payment?.id}`} placement="left-start">
        <Box>
          {payment.kind === 'additional' && (
            <Typography style={fontStyle.small}>
              {t('channel.payment.kind.cancelWorkout')}
            </Typography>
          )}
          {payment.kind === 'users_calendars_registration' && (
            <Typography style={fontStyle.small}>
              {t('channel.payment.kind.users_calendars_registration')}
            </Typography>
          )}
          {payment.kind === 'channels_events_registration' && (
            <Typography style={fontStyle.small}>
              {t('channel.payment.kind.channels_events_registration')}
            </Typography>
          )}
          {payment.kind === 'workout_to_member' && (
            <Typography style={fontStyle.small}>
              {t('channel.payment.kind.workout_to_member')}
            </Typography>
          )}
          {payment.kind === 'cancel_workout' && (
            <Typography style={fontStyle.small}>{t('channel.cancel')}</Typography>
          )}
          {payment.kind === 'membership' && (
            <Box display="flex" flexDirection="column">
              <Typography style={fontStyle.small}>
                {t('channel.payment.kind.membership')}
              </Typography>
              {payment.ownerable && payment.ownerable.membershipPlan && (
                <Typography style={fontStyle.small} ml={2} color="primary">
                  {payment.ownerable.membershipPlan.name}
                </Typography>
              )}
            </Box>
          )}
          {payment.kind === 'membership_pause' && (
            <Box display="flex" flexDirection="column">
              <Typography style={fontStyle.small}>
                {t('channel.payment.kind.membershipPause')}
              </Typography>
              {payment.ownerable && payment.ownerable.membershipPlan && (
                <Typography style={fontStyle.small} ml={2} color="primary">
                  {payment.ownerable.membershipPlan.name}
                </Typography>
              )}
            </Box>
          )}
          {payment.kind === 'list' && (
            <Box display="flex" flexDirection="column">
              <Typography style={fontStyle.small}>{t('channel.list')}</Typography>
              <Box display="flex" alignItems="center">
                <Typography ml={2} style={fontStyle.small} color="secondary">
                  {payment.ownerable &&
                    payment.ownerable.channelList &&
                    payment.ownerable.channelList.name}
                </Typography>
              </Box>
            </Box>
          )}
          {payment.kind === 'free_workout' && (
            <Box display="flex" alignItems="center">
              <Typography style={fontStyle.small}>{t('channel.freeWorkout')}</Typography>

              <Typography ml={2} style={fontStyle.small} color="secondary">
                {payment.ownerable.name}
              </Typography>
            </Box>
          )}

          {(payment.kind === 'competition' || payment.kind === 'competition_registration') && (
            <Box display="flex" alignItems="center">
              <Typography style={fontStyle.small}>{t('payment.competition')}</Typography>
            </Box>
          )}

          {payment.kind === 'plan' && (
            <Box display="flex" alignItems="center">
              <Typography style={fontStyle.small}>{t('channel.plan')}</Typography>
            </Box>
          )}
          {payment.kind === 'renew_plan' && (
            <Box display="flex" alignItems="center">
              <Typography style={fontStyle.small}>{t('channel.payment.kind.plan')}</Typography>
            </Box>
          )}
          {payment.kind === 'upgrade_plan_to_online' && (
            <Box display="flex" alignItems="center">
              <Typography style={fontStyle.small}>
                {t('channel.payment.kind.upgrade_plan_to_online')}
              </Typography>
            </Box>
          )}
          {payment.kind === 'upgrade_plan_to_in_person' && (
            <Box display="flex" alignItems="center">
              <Typography style={fontStyle.small}>
                {t('channel.payment.kind.upgrade_plan_to_in_person')}
              </Typography>
            </Box>
          )}
          {payment.kind === 'payout_renew_channel' && (
            <Box display="flex" alignItems="center">
              <Typography style={fontStyle.small}>
                {t('channel.payment.kind.payoutRenewChannel')}
              </Typography>
            </Box>
          )}
          {payment.kind === 'channel_store_product' && (
            <Box display="flex" alignItems="center">
              <Typography style={fontStyle.small}>
                {t('channel.payment.kind.channelStoreProduct')}
              </Typography>
            </Box>
          )}
          {payment.kind === 'pending_refunds' && (
            <Box display="flex" alignItems="center">
              <Typography style={fontStyle.small}>
                {t('channel.payment.kind.pending_refunds')}
              </Typography>
            </Box>
          )}
          {payment.kind === 'partner_program' && (
            <Box display="flex" alignItems="center">
              <Typography style={fontStyle.small}>
                {t('channel.payment.kind.partner_program')}
              </Typography>
            </Box>
          )}
          {payment.kind === 'hold_pending' && (
            <Box display="flex" alignItems="center">
              <Typography style={fontStyle.small}>
                {t('channel.payment.kind.hold_pending')}
              </Typography>
            </Box>
          )}
          {payment.kind === 'other' && (
            <Box display="flex" alignItems="center">
              <Typography style={fontStyle.small}>
                {t(payment.offline ? 'channel.payment.kind.offline' : 'channel.payment.kind.other')}{' '}
                -{' '}
                {payment.offline && payment.offlineType
                  ? payment.offlineType.toUpperCase()
                  : 'Generic'}
              </Typography>
            </Box>
          )}
        </Box>
      </Tooltip>
      <Box ml={2}>
        {payment?.payoutData?.id && (
          <Tooltip
            title={`${payment?.payoutData?.id} - Payout to your bank: ${dateTime(
              payment?.payoutData?.date,
            )}`}
            placement="left-start"
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              style={{ backgroundColor: successColor, width: 24, height: 24, borderRadius: 10 }}
            >
              <AccountBalanceIcon style={{ fontSize: 14, color: 'white' }} />
            </Box>
          </Tooltip>
        )}
      </Box>
    </Box>
  );
};
