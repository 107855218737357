import React, { useEffect, useState } from 'react';
import Box from 'core/ui/Box';
import Paper from 'core/ui/Paper';
import { Delete, Edit, FileCopy } from '@material-ui/icons';
import { dateDayOfWeek, dateExt, formatDateToApi } from 'core/utils/formatters';
import {
  IconButton,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  TableBody,
  withStyles,
} from '@material-ui/core';
import Modules from 'modules';
import useActions from 'modules/map/useActions';
import { secondaryXXLight } from 'core/ui/Colors';
import Typography, { fontStyle } from 'core/ui/Typography';
import useConfirmation from 'core/useConfirmation';
import { useDispatch } from 'react-redux';
import notifications from 'modules/notifications';
import { useTranslation } from 'react-i18next';
import WorkoutsDateSwipe from './WorkoutsDateSwipe';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(() => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: secondaryXXLight,
    },
  },
}))(TableRow);

const ListWorkout = ({ partner, partnersProgram, setModalFormWorkout, setModalCloneWeek }) => {
  const { request } = useActions();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { confirmation } = useConfirmation();
  const [currentDate, setCurrentDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [workouts, setWorkouts] = useState([]);

  const getWorkouts = () => {
    setLoading(true);
    request({
      action: Modules.partnersPrograms.actions.getWorkouts,
      data: {
        date: formatDateToApi(currentDate),
        partnerId: partnersProgram?.partnerId,
        id: partnersProgram?.id,
      },
      options: {
        onSuccess: (data) => {
          setWorkouts(data);
          setLoading(false);
        },
        onError: () => {
          setLoading(false);
        },
      },
    });
  };

  const cancelWorkoutProgram = (id) => {
    confirmation({
      title: 'Cancel Day',
      description: 'Are you sure?',
      yesClick: () => {
        request({
          action: Modules.newWorkouts.actions.cancelClass,
          data: { id },
          options: {
            onSuccess: () => {
              getWorkouts();
              dispatch(notifications.success(t('workout.message.cancel')));
            },
          },
        });
      },
    });
  };

  useEffect(() => {
    getWorkouts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDate, partnersProgram]);

  return (
    <Box mt={3}>
      <Paper p={3}>
        <Box>
          <Box>
            <WorkoutsDateSwipe
              partner={partner}
              setModalCloneWeek={() =>
                setModalCloneWeek({
                  open: true,
                  partnersProgram,
                  dateClone: formatDateToApi(currentDate),
                })
              }
              setCurrentDate={setCurrentDate}
              currentDate={currentDate}
              loading={loading}
            />
          </Box>
          <Box my={2}>
            <TableContainer component={Box}>
              <Table aria-label="customized table" size="small">
                <TableBody>
                  {workouts.map((w) => (
                    <StyledTableRow key={w.id}>
                      <StyledTableCell component="th" scope="row">
                        <Typography>
                          {w.id}&nbsp;-&nbsp;{dateDayOfWeek(w.scheduledAt)} -{' '}
                          {dateExt(w.scheduledAt)}
                        </Typography>
                        <Typography style={fontStyle.small} color="textSecondary">
                          {w?.sections?.map((x) => x.title).join(', ')}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <IconButton
                          onClick={() =>
                            setModalFormWorkout({
                              open: true,
                              partnersProgram,
                              workout: w,
                            })
                          }
                        >
                          <Edit />
                        </IconButton>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <IconButton
                          onClick={() =>
                            setModalFormWorkout({
                              open: true,
                              partnersProgram,
                              clone: true,
                              workout: w,
                            })
                          }
                        >
                          <FileCopy />
                        </IconButton>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <IconButton onClick={() => cancelWorkoutProgram(w?.id)}>
                          <Delete />
                        </IconButton>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default ListWorkout;
