import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, makeStyles } from '@material-ui/core';
import { LayoutContext } from 'pages/shared/Layout';
import { useParams } from 'react-router';
import ManageCalendars from 'components/Calendars/Manage';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#f3f3f3',
    minHeight: '100vh',
  },
}));

const Calendars = () => {
  const { t } = useTranslation();
  const layoutContext = useContext(LayoutContext);
  const classes = useStyles();
  const { coachId, channelId, calendarId } = useParams();

  useEffect(() => {
    layoutContext.setTitleBar(t('title.calendars').toUpperCase());
    layoutContext.setActiveMenu('calendars');
  }, [layoutContext, t]);

  return (
    <div className={classes.root}>
      <Container maxWidth="xl">
        <ManageCalendars calendarId={calendarId} channelId={channelId} coachId={coachId} />
      </Container>
    </div>
  );
};

export default Calendars;
