import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form-v5';
import { useTranslation } from 'react-i18next';
import notifications from 'modules/notifications';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import { useDispatch } from 'react-redux';
import { currency } from 'core/utils/formatters';

const useFormPartner = ({ partnerId, program, close, callbackSave }) => {
  const { t } = useTranslation();
  const { register, handleSubmit, errors, setValue, watch, reset } = useForm();

  const { request } = useActions();
  const dispatch = useDispatch();

  useEffect(() => {
    register({ name: 'name' }, { required: true });
    register({ name: 'parentProgramId' });
    register({ name: 'active' });
    register({ name: 'cost' });
    register({ name: 'annualCost' });
    register({ name: 'allowAnnualCost' });
  }, [register]);

  useEffect(() => {
    if (program?.id) {
      reset({ ...program, cost: currency(program?.cost) });
    } else {
      setTimeout(() => {
        setValue('active', true);
      }, 30);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [program]);

  const onSubmit = (data) => {
    const partnerData = {
      id: program?.id,
      partnerId,
      ...data,
    };

    if (!data.parentProgramId && !data.cost) {
      dispatch(notifications.warning('Enter the cost.'));
      return;
    }

    request({
      action: program?.id
        ? Modules.partnersPrograms.actions.update
        : Modules.partnersPrograms.actions.create,
      data: partnerData,
      options: {
        onSuccess: (resp) => {
          dispatch(notifications.success(t('partner.program.added.success')));
          if (callbackSave) {
            callbackSave(resp);
            close();
            return;
          }
          close();
        },
      },
    });
  };

  return {
    program,
    watch,
    setValue,
    submit: handleSubmit(onSubmit),
    errors,
  };
};

useFormPartner.propTypes = {
  close: PropTypes.func.isRequired,
};

useFormPartner.defaultProps = {};

export default useFormPartner;
