import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';

import { ButtonPrimary } from 'core/ui/Button';
import Typography from 'core/ui/Typography';
import notifications from 'modules/notifications';
import Modules from 'modules';
import useActions from 'modules/map/useActions';
import { showAPIError } from 'core/utils/showAPIError';

export const MenuActions = ({ row }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { request } = useActions();

  const refElement = useRef();
  const [open, setOpen] = useState(false);

  const sendReceipt = (paymentId) => {
    request({
      action: Modules.newPayments.actions.sendReceipt,
      data: {
        paymentId,
      },
      options: {
        onSuccess: () => {
          dispatch(notifications.success(t('payment.sendReceipt.success')));
        },
        onError: showAPIError,
      },
    });
  };

  const menuItems = [];

  if (row?.allowReceipt) {
    menuItems.push(
      <MenuItem onClick={() => sendReceipt(row.id)}>{t('button.resendReceipt')}</MenuItem>,
    );
  }

  if (menuItems.length === 0) return null;

  return (
    <>
      <ButtonGroup ref={refElement} variant="contained" color="primary" aria-label="split button">
        <ButtonPrimary onClick={() => setOpen(true)}>
          <Typography noWrap>Actions</Typography> <ArrowDropDown />
        </ButtonPrimary>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={refElement?.current}
        role={undefined}
        transition
        style={{ zIndex: 10 }}
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={() => setOpen(false)}>
                <MenuList id="split-button-menu">
                  {menuItems.map((x, index) => (
                    <React.Fragment key={index}>{x}</React.Fragment>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
