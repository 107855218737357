import api from 'core/api';

export const plansModule = 'plans';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const loadingChannelsStates = {
  start: { loadingChannels: true },
  error: { loadingChannels: false },
  success: { loadingChannels: false },
};

const actions = {
  create: {
    module: plansModule,
    name: 'create',
    api: (data) => api.post(`/plans`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  update: {
    module: plansModule,
    name: 'update',
    api: (data) => api.put(`/plans/${data?.id}`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  getActivePlan: {
    module: plansModule,
    name: 'getActivePlan',
    api: (params) => api.get(`/plans/active`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: ['plan'],
    },
    state: loadingStates,
  },
  getPlansChannel: {
    module: plansModule,
    name: 'getPlansChannel',
    api: (params) => api.get(`/plans`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: ['data'],
    },
    state: loadingStates,
  },
  getChannels: {
    module: plansModule,
    name: 'getChannels',
    api: (params) => api.get(`/plans/${params?.planId}/channels`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: ['overviewPlan'],
    },
    state: loadingChannelsStates,
  },
};

export default {
  actions,
  state: {
    data: [],
    plan: null,
    error: null,
    loading: false,

    overviewPlan: null,
    loadingChannels: false,
  },
};
