import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Typography, { fontStyle } from 'core/ui/Typography';
import Box from 'core/ui/Box';
import LoaderSm from 'core/ui/LoaderSm';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import { secondary } from 'core/ui/Colors';
import { AccountBalance, Payment, Warning } from '@material-ui/icons';
import { Link } from '@material-ui/core';
import { integrationTypes } from 'core/utils/consts';

const PaymentMethodList = ({
  methods,
  loading,
  setSelectedPaymentMethod,
  selectedPaymentMethod,
  buttonColor,
}) => {
  const { t } = useTranslation();

  const change = (v) => {
    if (v.stepVerificationUrl) {
      alert(t('userPaymentMethod.verifyToUse'));
      return;
    }

    if (setSelectedPaymentMethod) {
      setSelectedPaymentMethod(v);
    }
  };

  return (
    <Box px={2} pb={1} display="flex" flexDirection="column" justifyContent="center">
      <Box display="flex" justifyContent="center">
        <LoaderSm loading={loading} />
        <Typography variant="h6" style={{ color: buttonColor }}>
          {t('user.paymentMethod')}
        </Typography>
      </Box>
      <Box>
        {!loading &&
          methods.map((ac) => (
            <Box className="link" key={ac.id} onClick={() => change(ac)} p={1}>
              <Box display="flex" alignItems="center">
                <Box pt={1}>
                  {selectedPaymentMethod && selectedPaymentMethod.id === ac.id ? (
                    <RadioButtonCheckedIcon style={{ fontSize: 18, color: buttonColor }} />
                  ) : (
                    <RadioButtonUncheckedIcon style={{ fontSize: 18 }} />
                  )}
                </Box>
                <Box display="flex" alignItems="center" ml={1}>
                  <img
                    alt=""
                    src={integrationTypes.find((x) => x.id === ac.gateway)?.img}
                    style={{ width: 14, height: 14, borderRadius: 10, marginRight: 5 }}
                  />
                  {ac.method === 'card' ? (
                    <Payment style={{ color: secondary }} />
                  ) : (
                    <AccountBalance style={{ color: secondary }} />
                  )}
                  <Typography
                    ml={1}
                    variant="body2"
                    className="roboto-regular"
                    color="textSecondary"
                  >
                    <span style={{ textTransform: 'capitalize' }}>
                      <b>{ac.cardFlag}</b>
                    </span>{' '}
                    {t('userPaymentMethod.endingIn')} {ac.lastFour}
                  </Typography>
                </Box>
              </Box>
              {ac.stepVerificationUrl && (
                <Box ml={10} display="flex" alignItems="center">
                  <Warning style={{ fontSize: 13 }} />
                  <Link style={fontStyle.small} href={ac.stepVerificationUrl} target="_blank">
                    {t('payment.method.verificationStep')}
                  </Link>
                </Box>
              )}
            </Box>
          ))}
      </Box>
    </Box>
  );
};

PaymentMethodList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  methods: PropTypes.array,
  loading: PropTypes.bool,
  setSelectedPaymentMethod: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  selectedPaymentMethod: PropTypes.object,
};

PaymentMethodList.defaultProps = {
  methods: [],
  loading: false,
  setSelectedPaymentMethod: () => {},
  selectedPaymentMethod: null,
};

export default PaymentMethodList;
