import React, { useContext, Suspense } from 'react';

import Box from 'core/ui/Box';
import { WorkflowContext } from 'contexts';
import { getWorkflowTypeKey } from '../../utils/getWorkflowTypeKey';
import { getFormComponent } from './getFormComponent';

const WorkflowTypeForms = ({ automationType }) => {
  const workFlowContext = useContext(WorkflowContext);

  if (!automationType?.function) return 'undefined automationType.function';

  const Component = getFormComponent(automationType.function);

  const workflowType = workFlowContext.currentType?.workflowType;
  const key = automationType.function + (getWorkflowTypeKey(workflowType) ?? '');

  return (
    <Box mb={2}>
      <Suspense fallback={<div>Loading...</div>}>
        <Component
          key={key}
          automationType={automationType}
          values={workFlowContext?.formType?.watch('form') || {}}
          onChangeFormValues={workFlowContext.onChangeFormValues}
          channelId={workFlowContext.channelId}
        />
      </Suspense>
    </Box>
  );
};

export default WorkflowTypeForms;
