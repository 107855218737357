import React from 'react';
import { secondary } from '../Colors';

const EngageIcon = ({ size, color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    width={size}
    height={size}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 100 97.5"
  >
    <path
      d="M69.8,17.8c-1.9-2.9-1.6-6.8,1-9.4,2.9-2.9,7.6-2.9,10.6,0,2.9,2.9,2.9,7.6,0,10.6-1.4,1.4-3.3,2.2-5.3,2.2s-2.9-.4-4.1-1.2l-11.9,11.8c-.7-.7-1.5-1.4-2.2-2l11.9-12ZM81.3,81c-1.4-1.4-3.3-2.2-5.3-2.2s-2.9.4-4.1,1.2l-11.8-11.8c-.6.6-1.2,1.2-1.8,1.7,0,0-.3.2-.4.3l12,12c-1.9,2.9-1.6,6.8,1,9.4s3.4,2.2,5.3,2.2,3.8-.7,5.3-2.2c2.7-2.9,2.7-7.7-.2-10.6ZM41.7,39c-3.3,0-6,2.7-6,6s2.7,6,6,6,6-2.7,6-6-2.7-6-6-6ZM25,64.1c0,0,0-.2.1-.2.1-.3.2-.7.3-1,0,0,.1-.2.1-.3.1-.3.2-.6.4-.9,0,0,.1-.2.1-.3.1-.3.3-.6.4-.9,0,0,.1-.2.2-.3.2-.3.3-.6.5-.8.1,0,.1-.2.2-.3.2-.3.3-.5.5-.8.1,0,.1-.2.2-.3.2-.3.4-.5.6-.7.1,0,.2-.2.2-.3.2-.2.4-.5.6-.7.1,0,.2-.2.3-.3.2-.2.4-.4.7-.6.1,0,.2-.2.3-.2.2-.2.5-.4.7-.6s.2-.2.3-.2c.2-.2.5-.3.7-.5,0,0,.2,0,.4-.2.2-.2.5-.3.7-.4,0,0,.3,0,.4-.2.2,0,.5-.3.8-.4,0,0,.3,0,.4-.2.2,0,.5-.2.8-.3,0,0,.2,0,.2,0-2-1.7-3.3-4.2-3.3-7,0-5,4-9,9-9s9,4,9,9-1.3,5.3-3.3,7c0,0,.2,0,.2,0,.3,0,.5.2.8.3,0,0,.3,0,.4.2.3,0,.5.2.8.4,0,0,.3,0,.4.2.3,0,.5.3.7.4,0,0,.2,0,.4.2.2.2.5.3.7.5s.2.2.3.2c.2.2.5.4.7.6,0,0,.2.2.3.2.2.2.5.4.7.6l.3.3c.2.2.4.5.6.7,0,0,.2.2.2.3.2.2.4.5.6.7,0,0,0,.2.2.3.2.3.4.5.5.8,0,0,0,.2.2.3.2.3.3.6.5.8s0,.2.2.3c0,.3.3.6.4.9,0,0,0,.2,0,.3,0,.3.2.6.4.9,0,0,0,.2,0,.3,0,.3.2.7.3,1,0,0,0,.2,0,.2,0,.4.2.8.2,1.2h0c3.8-4.1,6-9.6,6-15.4,0-12.7-10.3-22.9-22.9-22.9s-23.2,10.3-23.2,23,2.2,11.3,6,15.5h0c0-.6.2-1,.3-1.4ZM27.5,68c4,3.2,9,4.9,14.2,4.9s10.1-1.7,14.2-4.9c0-7.8-6.4-14-14.2-14s-14.1,6.3-14.2,14Z"
      fill={color}
    />
  </svg>
);

export default EngageIcon;

EngageIcon.defaultProps = {
  icon: null,
  color: secondary,
  size: 20,
  styles: {},
};
