/* eslint-disable react/prop-types */
import React from 'react';
import Paper from 'core/ui/Paper';
import { useTranslation } from 'react-i18next';
import { channelCurrency, date, timeWithoutTz } from 'core/utils/formatters';
import Typography, { fontStyle } from 'core/ui/Typography';
import SFDataTable from 'core/ui/DataTable';
import Box from 'core/ui/Box';
import Grid from 'core/ui/Grid';
import StoreOrderItems from './StoreOrderItems';

export default function ResultOrders({ channel, orders }) {
  const { t } = useTranslation();
  const columns = [
    {
      name: 'User Name',
      selector: 'user.name',
      sortable: true,
      cell: (row) => <Typography>{row?.user?.name}</Typography>,
    },
    {
      name: 'Date',
      selector: 'createdAt',
      cell: (row) => (
        <Typography>
          {date(row?.createdAt, channel?.dateFormatReact)}&nbsp;&bull;&nbsp;
          {timeWithoutTz(row.createdAt)}
        </Typography>
      ),
      sortable: true,
      center: true,
    },
    {
      name: 'Processed',
      selector: 'confirmedAt',
      cell: (row) => (
        <Typography style={fontStyle.medium}>
          {row.confirmedAt && `${date(row.confirmedAt)}`}
        </Typography>
      ),
      sortable: true,
      center: true,
    },
    {
      name: 'Delivery',
      selector: 'delivery',
      cell: (row) => <Typography> {row?.items?.find((x) => x.delivery) ? 'YES' : 'NO'}</Typography>,
      sortable: true,
      center: true,
    },
    {
      name: 'Offline',
      selector: 'offline',
      cell: (row) => <Typography> {row.isOfflinePayment ? 'YES' : 'NO'}</Typography>,
      sortable: true,
      center: true,
    },
    {
      name: 'Total',
      selector: 'total',
      sortable: true,
      right: true,
      cell: (row) => <Typography>{channelCurrency(row.totalAmount, row?.currency)}</Typography>,
    },
  ];

  const rows = orders || [];

  const ExpanableComponent = ({ data }) => (
    <Box p={5} display="flex" alignItems="center" flexDirection="column" justifyContent="center">
      <StoreOrderItems currency={data?.currency} items={data?.items || []} />

      {data.discountCode && (
        <>
          <Box display="flex">
            <Typography mb={3}>Discount Code:</Typography>
            <Typography ml={1} color="primary">
              {data.discountCode}
            </Typography>
          </Box>
          <Box display="flex">
            <Typography mb={3}>Original Price:</Typography>
            <Typography ml={1} color="primary">
              {channelCurrency(data.originalPrice, data?.currency)}
            </Typography>
          </Box>
        </>
      )}
      <Box display="flex">
        <Typography mb={3}>Purchased Price:</Typography>
        <Typography ml={1} color="primary">
          {channelCurrency(data.total, data?.currency)}
        </Typography>
      </Box>
      {data.userCard && (
        <Box display="flex">
          <Typography mb={3}>Card:</Typography>
          <Typography ml={1} color="primary">
            {data.userCard}
          </Typography>
        </Box>
      )}
      {data.offline === true && (
        <Box display="flex">
          <Typography mb={3}>Offline Type:</Typography>
          <Typography ml={1} color="primary">
            {data.offlinePaymentType && data.offlinePaymentType.toUpperCase()}
          </Typography>
        </Box>
      )}
      {data.delivery === true && (
        <Box>
          <Typography color="primary" variant="h6" align="center">
            Delivery
          </Typography>
          <Grid container spacing={5}>
            <Grid item xs={12} md={6}>
              <Box display="flex">
                <Typography mb={3}>Delivery Price:</Typography>
                <Typography ml={1} color="primary">
                  {data?.currencySymbol}
                  {data.deliveryPrice}&nbsp;
                  {data?.currencyCode}
                </Typography>
              </Box>

              <Box display="flex">
                <Typography mb={3}>Country:</Typography>
                <Typography ml={1} color="primary">
                  {data.country}
                </Typography>
              </Box>

              <Box display="flex">
                <Typography mb={3}>City:</Typography>
                <Typography ml={1} color="primary">
                  {data.city}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box display="flex">
                <Typography mb={3}>State/Providence:</Typography>
                <Typography ml={1} color="primary">
                  {data.state}
                </Typography>
              </Box>

              <Box display="flex">
                <Typography mb={3}>Street:</Typography>
                <Typography ml={1} color="primary">
                  {data.street}
                </Typography>
              </Box>

              <Box display="flex">
                <Typography mb={3}>Postal Code:</Typography>
                <Typography ml={1} color="primary">
                  {data.zip}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
      <Box display="flex">
        <Typography mb={3}>Payment Summart:</Typography>
        <Typography ml={1} color="primary">
          {data.paymentSummary}
        </Typography>
      </Box>
    </Box>
  );

  return (
    <Paper style={{ width: '100%' }}>
      <SFDataTable
        title={t('channel.reports.storeOrders')}
        columns={columns}
        data={rows}
        pagination
        showExport={false}
        expandableRows
        expandableRowsComponent={<ExpanableComponent />}
      />
    </Paper>
  );
}
