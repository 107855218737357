import React, { useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from 'core/ui/Box';
import Texture from 'core/ui/Texture';
import { ButtonPrimary } from 'core/ui/Button';
import LoaderSm from 'core/ui/LoaderSm';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form-v5';
import { useTranslation } from 'react-i18next';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import notifications from 'modules/notifications';
import DatePickerRounded from 'core/ui/DatePickers/DatePickerRounded';
import { formatDateApi, formatDateToApi } from 'core/utils/formatters';
import { channelMembersActions } from 'modules/channelMembers';
import useSelectors from 'modules/map/useSelectors';
import { newChannelMembersModule } from 'modules/channelMembers/new';

const ModalEditJoinedDate = ({ user, channel, open, close }) => {
  const { t } = useTranslation();
  const { request } = useActions();

  const { register, handleSubmit, errors, setValue, watch } = useForm();
  const dispatch = useDispatch();

  const loadingJoinedDate = useSelectors(newChannelMembersModule, 'loadingJoinedDate');

  useEffect(() => {
    register({ name: 'joinedDate' }, { required: true });
  }, [register]);

  useEffect(() => {
    if (user?.memberAnniversaryDate) {
      setValue('joinedDate', formatDateApi(user?.memberAnniversaryDate));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.memberAnniversaryDate]);

  const updateJoinedDate = (data) => {
    request({
      action: Modules.newChannelMembers.actions.updateJoinedDate,
      data: {
        channelId: channel?.id,
        userId: user?.id,
        joinedDate: formatDateToApi(data?.joinedDate),
      },
      options: {
        onSuccess: () => {
          dispatch(notifications.success('Joined date has been updated.'));
          dispatch(channelMembersActions.getChannelMemberStart(channel.id, user?.id));
          close();
        },
      },
    });
  };
  const onSubmit = (data) => {
    updateJoinedDate(data);
  };

  return (
    <Dialog open={open} onClose={close} maxWidth="xs" fullWidth>
      <Texture>
        <Box p={3}>
          <Box>
            <DatePickerRounded
              label={t('channel.member.joinedDate').toUpperCase()}
              onChange={(v) => setValue('joinedDate', v)}
              value={watch('joinedDate')}
              disablePast={false}
              usFormat={channel?.useUsDateFormat}
              autoOk
              error={errors?.joinedDate}
              bordered
              required
            />
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <ButtonPrimary
              type="button"
              onClick={() => handleSubmit(onSubmit)()}
              size="small"
              disabled={loadingJoinedDate}
            >
              <LoaderSm loading={loadingJoinedDate} width={20} />
              {t('button.save')}
            </ButtonPrimary>
          </Box>
        </Box>
      </Texture>
    </Dialog>
  );
};

ModalEditJoinedDate.defaultProps = {
  user: {},
  open: false,
  close: () => {},
};

export default ModalEditJoinedDate;
