import React, { useState, useContext } from 'react';
import Typography from 'core/ui/Typography';
import Box from 'core/ui/Box';
import { useDispatch } from 'react-redux';
import { channelsActions } from 'modules/channels';
import { useTranslation } from 'react-i18next';
import { ButtonPrimary } from 'core/ui/Button';
import FontIcon from 'core/ui/FontIcon';
import notifications from 'modules/notifications';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import useSelectors from 'modules/map/useSelectors';
import { channelUsersModule } from 'modules/channelUsers';
import LoaderSm from 'core/ui/LoaderSm';
import Paper from 'core/ui/Paper';
import useConfirmation from 'core/useConfirmation';
import { ChannelContext } from '../..';
import ModalCoachManage from './Manage/Modal';
import TableCoaches from './Manage/TableCoaches';
import ModalAddUser from './ModalAddUser';

const ChannelStaff = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loading = useSelectors(channelUsersModule, 'loading');
  const { request } = useActions();
  const { confirmation } = useConfirmation();
  const [modalUser, setModalUser] = useState({ open: false });
  const [modalManage, setModalManage] = useState(false);
  const channelContext = useContext(ChannelContext);

  const remove = (coachId) => {
    confirmation({
      description: 'Are you sure?',
      yesClick: () => {
        request({
          action: Modules.channelUsers.actions.removeUser,
          data: {
            channelId: channelContext.channel.id,
            userId: coachId,
          },
          options: {
            onSuccess: () => {
              dispatch(notifications.success(t('invite.coach.remove.success')));
              dispatch(channelsActions.getChannelStart(channelContext.channel.id, 'edit'));
            },
            onError: (msg) => {
              dispatch(notifications.error(msg));
            },
          },
        });
      },
    });
  };

  const changeUserType = (userId, data = {}) => {
    request({
      action: Modules.channelUsers.actions.changeCoachInfo,
      data: {
        channelId: channelContext.channel.id,
        userId,
        ...data,
      },
      options: {
        onSuccess: () => {
          dispatch(channelsActions.getChannelStart(channelContext.channel.id, 'edit'));
          dispatch(notifications.success(t('invite.coach.update.success')));
        },
        onError: (msg) => {
          dispatch(notifications.error(msg));
        },
      },
    });
  };

  const onUserSelected = (user, accessType) => {
    request({
      action: Modules.channelUsers.actions.addUser,
      data: {
        channelId: channelContext.channel.id,
        accessType,
        userId: user?.id,
      },
      options: {
        onSuccess: () => {
          dispatch(notifications.success(t('invite.coach.success')));
          dispatch(channelsActions.getChannelStart(channelContext.channel.id, 'edit'));
        },
        onError: (msg) => {
          dispatch(notifications.error(msg));
        },
      },
    });
  };

  const buttonColor = channelContext?.channel?.customColors?.buttonColor;

  return (
    <Box p={5}>
      <Box display="flex" mb={5} alignItems="center">
        <Box flexGrow="1" alignItems="center">
          {channelContext.channel && channelContext.channel.user && (
            <Box justifyContent="flex-end">
              <Typography color="secondary" component="span" variant="h6">
                Channel Owner:&nbsp;
              </Typography>
              <Typography color="primary" component="span" variant="h6">
                {channelContext.channel.user.name}
              </Typography>
            </Box>
          )}
        </Box>
        <Box>
          <ButtonPrimary buttonColor={buttonColor} onClick={() => setModalUser({ open: true })}>
            <LoaderSm loading={loading} />
            <FontIcon iconName="icon-user" color="secondary" size={14} />
            <Typography ml={1} component="span">
              {t('button.addStaff')}
            </Typography>
          </ButtonPrimary>
        </Box>
      </Box>

      <Box display="flex" mt={5} mb={5} alignItems="center">
        <Box flexGrow="1" alignItems="center">
          <Typography color="secondary" component="span" variant="h5">
            {t('channel.staff').toUpperCase()}
          </Typography>
        </Box>
      </Box>

      <Paper p={2} className="paper-rounded">
        <TableCoaches
          coaches={[
            ...(channelContext.channel.coaches || []),
            ...(channelContext.channel.admins || []),
          ]}
          setModalManage={setModalManage}
          remove={remove}
          channelId={channelContext.channel?.id}
          buttonColor={buttonColor}
        />
      </Paper>

      <ModalCoachManage
        open={modalManage?.open}
        channelCoach={modalManage?.channelCoach}
        close={() => setModalManage({ open: false })}
        changeUserType={changeUserType}
        channel={channelContext.channel}
      />

      {modalUser && (
        <ModalAddUser
          loading={loading}
          channelId={channelContext.channel.id}
          open={modalUser?.open}
          setOpen={() => setModalUser({ open: true })}
          setSelectedUser={onUserSelected}
          close={() => setModalUser({ open: false })}
        />
      )}
    </Box>
  );
};

export default ChannelStaff;
