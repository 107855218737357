/* eslint-disable no-param-reassign */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/prop-types */
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import DataTable from 'react-data-table-component';

import Paper from 'core/ui/Paper';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { ExportToCsv } from 'export-to-csv';
import { useTranslation } from 'react-i18next';
import Box from '../Box';
import Typography from '../Typography';
import Button from '../Button';
import TextFieldRounded from '../TextFieldRounded';

export default function SFDataTable({
  data = [],
  exportData,
  columns,
  title,
  showExport,
  showPaper,
  showSearch,
  searchField,
  ...props
}) {
  const { t } = useTranslation();
  const [text, setText] = useState('');

  const exportCsv = () => {
    try {
      const options = {
        filename: 'StreamFitness - CSV FILE',
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: false,
        title: 'Export CSV',
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
      };

      const csvExporter = new ExportToCsv(options);

      csvExporter.generateCsv(exportData || data);
    } catch (ex) {
      console.log(' ERROR ', ex);
    }
  };

  const filteredData = useMemo(() => {
    if (!text || !searchField) return data;

    const healedText = text.toUpperCase();

    return data.filter((item) => {
      const toSearchField = searchField(item);

      if (!toSearchField) return false;

      return toSearchField.toUpperCase().includes(healedText);
    });
  }, [data, searchField, text]);

  return (
    <>
      {showPaper ? (
        <Paper style={{ width: '100%', position: 'relative' }}>
          {showSearch && (
            <Box p={3} m={1}>
              <TextFieldRounded
                bordered
                value={text}
                onChange={(v) => setText(v)}
                label={t('label.search')}
              />
            </Box>
          )}
          {showExport && (
            <Box style={{ position: 'absolute', zIndex: 10, right: 10, top: 8 }}>
              <Button onClick={() => exportCsv()}>
                <CloudDownloadIcon />
                <Typography ml={1}>CSV</Typography>
              </Button>
            </Box>
          )}

          <DataTable
            title={title}
            columns={columns}
            data={filteredData}
            pagination
            sortFunction={sortFunction}
            {...props}
          />
        </Paper>
      ) : (
        <Box style={{ position: 'relative' }}>
          <div className="tw-flex tw-flex-row">
            <Box className="tw-grow">
              {showSearch && (
                <TextFieldRounded
                  bordered
                  value={text}
                  onChange={(v) => setText(v)}
                  label={t('label.search')}
                />
              )}
            </Box>
            {showExport && (
              <Box style={{ position: 'absolute', zIndex: 10, right: 10, top: 10 }}>
                <Button onClick={() => exportCsv()}>
                  <CloudDownloadIcon />
                  <Typography ml={1}>CSV</Typography>
                </Button>
              </Box>
            )}
          </div>

          <DataTable
            title={title}
            columns={columns}
            data={filteredData}
            sortFunction={sortFunction}
            {...props}
          />
        </Box>
      )}
    </>
  );
}

const sortFunction = (rows, field, direction) =>
  [...rows].sort((rowA, rowB) => {
    const aField = rowA[field];
    const bField = rowB[field];

    if (!Number.isNaN(Number(aField)) && !Number.isNaN(Number(bField))) {
      return direction === 'desc'
        ? Number(aField) - Number(bField)
        : Number(bField) - Number(aField);
    }

    if (Date.parse(aField) && Date.parse(bField)) {
      return direction === 'desc'
        ? Date.parse(aField) - Date.parse(bField)
        : Date.parse(bField) - Date.parse(aField);
    }

    let comparison = 0;

    if (aField > bField) {
      comparison = 1;
    } else if (aField < bField) {
      comparison = -1;
    }

    return direction === 'desc' ? comparison * -1 : comparison;
  });

SFDataTable.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
  title: PropTypes.string,
  showExport: PropTypes.bool,
  showPaper: PropTypes.bool,
};

SFDataTable.defaultProps = {
  data: [],
  columns: [],
  title: null,
  showExport: false,
  showPaper: true,
};
