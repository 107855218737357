import Modules from 'modules';
import useActions from 'modules/map/useActions';
import useSelectors from 'modules/map/useSelectors';
import { sessionActions } from 'modules/session';
import { usersContractsModule } from 'modules/usersContracts';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

const useUsersContracts = ({
  userId,
  ownerableId,
  ownerableType,
  objectId,
  objectType,
  documentId,
}) => {
  const [contract, setContract] = useState(null);
  const loading = useSelectors(usersContractsModule, 'loading');
  const { request } = useActions();
  const dispatch = useDispatch();

  const check = (kind, setLoaded = null, callback = null) => {
    if (setLoaded) {
      setLoaded(false);
    }

    request({
      action: Modules.usersContracts.actions.checkUsersContract,
      data: {
        userId,
        ownerableId,
        ownerableType,
        objectId,
        objectType,
        documentId,
        kind,
      },
      options: {
        onSuccess: (data) => {
          if (callback) {
            callback(data);
          }
          setContract(data);
          if (setLoaded) {
            setLoaded(true);
          }
        },
        onError: () => {
          setContract(null);
          if (setLoaded) {
            setLoaded(true);
          }
        },
      },
    });
  };

  const toggle = (kind, accept, callback) => {
    request({
      action: Modules.usersContracts.actions.acceptUsersContract,
      data: {
        userId,
        ownerableId,
        ownerableType,
        objectId,
        objectType,
        documentId,
        accept,
        kind,
      },
      options: {
        onSuccess: () => {
          dispatch(sessionActions.authenticateStart());

          check();
          if (callback) {
            callback();
          }
        },
      },
    });
  };

  return {
    check,
    toggle,
    accepted: contract?.accepted,
    setContract,
    contract,
    loadingContract: loading,
  };
};

export default useUsersContracts;
