/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import Typography from 'core/ui/Typography';
import Box from 'core/ui/Box';
import Scrollbars from 'react-custom-scrollbars';
import { primary, secondary } from 'core/ui/Colors';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { sessionSelectors } from 'modules/session';
import useUserMessages from './Messages/useUserMessages';

export default ({ tab, setTab }) => {
  const user = useSelector((state) => sessionSelectors.getUser(state));
  const { t } = useTranslation();
  const history = useHistory();
  const { getUnreadCount, unreadCount } = useUserMessages(false);

  const useStyles = makeStyles(() => ({
    boxTabs: {
      display: 'flex',
      height: 55,
      borderRadius: 5,
      alignItems: 'center',
    },
    wraperTabs: {
      background: secondary,
      height: 55,
      borderRadius: 5,
    },
    activeTab: {
      borderBottom: '0.5px dotted #fff',
    },
    styleTab: {
      padding: 3,
      marginLeft: 5,
    },
  }));
  const classes = useStyles();

  const ItemTab = ({ label, tabName, onClick }) => (
    <Box className={classes.styleTab}>
      <Button size="small" onClick={() => (onClick ? onClick() : setTab(tabName))}>
        <Typography
          className={tabName === tab && classes.activeTab}
          style={{ color: 'white' }}
          variant="subtitle2"
        >
          {label}
        </Typography>
      </Button>
    </Box>
  );

  useEffect(() => {
    getUnreadCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    history.push(`/dashboard/${tab}`);
  }, [history, tab]);

  return (
    <Box className={classes.wraperTabs} px={2}>
      <Scrollbars
        autoHide
        renderTrackVertical={(props) => (
          <div {...props} style={{ display: 'none' }} className="track-horizontal" />
        )}
        style={{ width: '100%', height: 55 }}
      >
        <Box className={classes.boxTabs}>
          <Box display="flex" flexGrow={1} alignItems="center">
            <ItemTab label={t('dashboard.tab.myCalendar')} tabName="myCalendar" />
            <ItemTab label={t('dashboard.tab.invites')} tabName="invites" />
            <ItemTab label={t('dashboard.tab.personalRecord')} tabName="personalRecord" />
            <ItemTab label={t('dashboard.tab.benchmarks')} tabName="benchmark" />
            <ItemTab label={t('dashboard.tab.workouts')} tabName="workouts" />
            {(user?.role === 'coach' || user?.role === 'admin') && (
              <ItemTab label={t('dashboard.tab.coach')} tabName="coaches" />
            )}
            <ItemTab
              label={
                <Typography>
                  {t('dashboard.tab.manageAccount')}

                  {user?.hasPaymentPendingActionCount > 0 && (
                    <Typography style={{ color: primary }} ml={2} component="span">
                      ({user?.hasPaymentPendingActionCount})
                    </Typography>
                  )}
                </Typography>
              }
              tabName="manageAccount"
            />
            <ItemTab
              label={
                <Typography>
                  {t('dashboard.tab.notifications')}

                  {unreadCount > 0 && (
                    <Typography style={{ color: primary }} ml={2} component="span">
                      ({unreadCount})
                    </Typography>
                  )}
                </Typography>
              }
              tabName="notifications"
            />
          </Box>

          <Box className={classes.styleTab}>
            <Button size="small" onClick={() => history.push('/profile/edit')}>
              <Typography color="primary" variant="subtitle2">
                {t('button.editProfile')}
              </Typography>
            </Button>
          </Box>
        </Box>
      </Scrollbars>
    </Box>
  );
};
