import { all, call, put } from 'redux-saga/effects';

import { getErrorMessage } from 'core/api/api-error';

import notifications from 'modules/notifications';

import { push } from 'connected-react-router';
import * as api from './api';
import { competitionsActions as actions } from './index';

export function* getCompetitions() {
  try {
    const response = yield call(api.competitions);
    const competitions = response.data;
    yield put(actions.getCompetitionsSuccess(competitions));
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.getCompetitionsError(getErrorMessage(error))),
    ]);
  }
}

export function* cancelCompetition({ data }) {
  try {
    const response = yield call(api.cancel, data.id);
    const competition = response.data;
    yield put(actions.cancelCompetitionSuccess(competition));
    if (data.messageSuccess) {
      yield put(notifications.success(data.messageSuccess));
    }
    yield put(push(`/dashboard`));
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.cancelCompetitionError(getErrorMessage(error))),
    ]);
  }
}

export function* paymentCompetition({ data }) {
  try {
    const response = yield call(api.payment, data);
    const competition = response.data;
    yield put(actions.paymentCompetitionSuccess(competition));
    if (data.messageSuccess) {
      yield put(notifications.success(data.messageSuccess));
    }
    if (data.callback) {
      data.callback(competition);
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.paymentCompetitionError(getErrorMessage(error))),
    ]);
  }
}

export function* postCompetition({ data }) {
  try {
    const response = yield call(api.create, data.data);
    const competition = response.data;
    yield put(actions.postCompetitionSuccess(competition));
    if (data.messageSuccess) {
      yield put(notifications.success(data.messageSuccess));
    }
    if (data.callback) {
      data.callback(competition);
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.postCompetitionError(getErrorMessage(error))),
    ]);
  }
}

export function* putCompetition({ data }) {
  try {
    const response = yield call(api.update, data.data);
    const competition = response.data;
    yield put(actions.putCompetitionSuccess(competition));
    if (data.messageSuccess) {
      yield put(notifications.success(data.messageSuccess));
    }
    if (data.callback) {
      data.callback(competition);
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.putCompetitionError(getErrorMessage(error))),
    ]);
  }
}

export function* getCompetition({ id }) {
  try {
    const response = yield call(api.competition, id);
    const competition = response.data;
    yield put(actions.getCompetitionSuccess(competition));
  } catch (error) {
    if (error.statusCode === 404) {
      yield put(push('/dashboard'));
    }
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.getCompetitionsError(getErrorMessage(error))),
    ]);
  }
}
