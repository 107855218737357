import React from 'react';
import Box from 'core/ui/Box';
import Typography, { fontStyle } from 'core/ui/Typography';
import { secondary } from 'core/ui/Colors';
import { useTranslation } from 'react-i18next';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import Button from 'core/ui/Button';
import WorkoutScoresPoints from 'components/Competition/Show/Tabs/Results/Tables/WorkoutScoresPoints';

const WorkoutPenalty = ({ penalty, fontColor, fontValue, onRemoveClick }) => {
  const { t } = useTranslation();

  return (
    <Box display="flex" alignItems="center" flexWrap="wrap">
      <Typography
        align="center"
        style={{ ...fontValue, color: fontColor || secondary }}
        color="secondary"
        className="roboto-regular"
      >
        {t(`penalty.type.${penalty.type}`)}&nbsp;
      </Typography>

      <WorkoutScoresPoints scores={penalty?.scores || []} showNotes />

      {penalty.judgeName && (
        <Typography
          align="center"
          style={{ ...fontValue, color: fontColor || secondary }}
          color="secondary"
          className="roboto-regular"
        >
          -&nbsp;&nbsp;{penalty.judgeName}
        </Typography>
      )}

      {onRemoveClick && (
        <Button onClick={() => onRemoveClick(penalty)}>
          <RemoveCircleOutlineIcon color="primary" />
        </Button>
      )}
    </Box>
  );
};

WorkoutPenalty.defaultProps = {
  fontValue: fontStyle.medium,
  fontScore: fontStyle.medium,
};

export default WorkoutPenalty;
