/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import Paper from 'core/ui/Paper';
import { IconButton } from '@material-ui/core';
import Divider from 'core/ui/Divider';
import { colorPdfBackground } from 'core/ui/Colors';
import Grid from 'core/ui/Grid';
import LabelTextField from 'core/ui/LabelTextField';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Button from 'core/ui/Button';
import Checkbox from 'core/ui/Checkbox';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import { Add, ArrowUpward, Close } from '@material-ui/icons';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import TypographyIcon from 'core/ui/TypographyIcon';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Typography from 'core/ui/Typography';
import MenuDocSection from './MenuDocSection';

const DocSection = ({ form, sec, index, actions }) => {
  const { t } = useTranslation();
  const [temp, setTemp] = useState('');

  return (
    <Paper p={3} mb={2}>
      <Grid container spacing={5}>
        <Grid item xs={12} md={9}>
          <Box px={5}>
            {sec?.kind === 'pdf' && (
              <Box style={{ backgroundColor: colorPdfBackground }}>
                <iframe
                  title=""
                  style={{ height: 400, width: '100%', border: 'none' }}
                  src={sec.s3Url}
                />
              </Box>
            )}
            {sec?.kind === 'text' && (
              <Box>
                <LabelTextField
                  value={sec?.text}
                  onChange={(v) => form.changeSection(sec, 'text', v)}
                  placeholder={t('placeholder.document.question')}
                  multiline
                  style={{ fontSize: 12 }}
                />
              </Box>
            )}
            {sec?.kind === 'paragraph' && (
              <Box>
                <LabelTextField
                  value={sec?.text}
                  onChange={(v) => form.changeSection(sec, 'text', v)}
                  placeholder={t('placeholder.document.paragraph')}
                  multiline
                  style={{ fontSize: 12 }}
                />
              </Box>
            )}
            {(sec?.kind === 'radio' || sec?.kind === 'checkbox' || sec?.kind === 'dropdown') && (
              <Box>
                <Box>
                  <LabelTextField
                    value={sec?.text}
                    onChange={(v) => form.changeSection(sec, 'text', v)}
                    placeholder="Question"
                    multiline
                    style={{ fontSize: 12 }}
                  />
                </Box>
                <Divider my={2} />
                <Box display="flex" alignItems="center" mb={2}>
                  <TextFieldRounded
                    placeholder="Options"
                    bordered
                    value={temp}
                    onChange={(v) => setTemp(v)}
                  />
                  <IconButton
                    onClick={() => {
                      actions.addOption(sec, temp);
                      setTemp('');
                    }}
                  >
                    <Add />
                  </IconButton>
                </Box>

                <Box>
                  {sec?.options && <Typography mb={1}>{t('label.options')}</Typography>}
                  {sec?.options?.map &&
                    sec?.options?.map((x, idx) => (
                      <Box key={x}>
                        <Box display="flex" alignItems="center">
                          <TypographyIcon
                            mr={1}
                            icon={
                              sec?.kind === 'radio' ? (
                                <RadioButtonUncheckedIcon />
                              ) : (
                                <CheckBoxOutlineBlankIcon />
                              )
                            }
                            text={x}
                            key={x}
                          />
                          <Box pb={1}>
                            <IconButton onClick={() => actions.removeOption(sec, x)}>
                              <Close fontSize="small" />
                            </IconButton>
                          </Box>
                        </Box>
                        {sec?.options?.length - 1 > idx && <Divider m={1} />}
                      </Box>
                    ))}
                </Box>
              </Box>
            )}
            {sec?.kind === 'signature' && (
              <Box>
                <Paper style={{ height: 150 }}>
                  <Typography />
                </Paper>
              </Box>
            )}
          </Box>
        </Grid>

        {form.canEditSections && (
          <Grid item xs={12} md={3}>
            <MenuDocSection section={sec} form={form} />
          </Grid>
        )}
      </Grid>

      {form.canEditSections && (
        <>
          <Divider m={3} />
          <Box display="flex" px={5}>
            <Box flexGrow={1} />
            <Box display="flex" justifyContent="space-between">
              <Box mr={3}>
                <IconButton onClick={() => actions.reorderSection(sec, index)}>
                  <ArrowUpward />
                </IconButton>
              </Box>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => form.addSection({ ...sec, id: null })}
                aria-label="close"
              >
                <FileCopyIcon />
              </IconButton>
              <Box ml={3}>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => form.removeSection(sec)}
                  aria-label="close"
                >
                  <DeleteOutlineIcon />
                </IconButton>
              </Box>
              {['text', 'radio', 'checkbox', 'dropdown', 'signature'].find(
                (x) => x === sec.kind,
              ) && (
                <>
                  <Divider orientation="vertical" mx={3} />
                  <Button onClick={() => form.changeSection(sec, 'required', !sec?.required)}>
                    <Checkbox color="secondary" checked={sec?.required} />
                    &nbsp;{t('button.required')}
                  </Button>
                </>
              )}
            </Box>
          </Box>
        </>
      )}
    </Paper>
  );
};

export default DocSection;
