import React from 'react';
import ScoreFields from 'components/Stream/SideBar/components/Sections/ScoreFields';
import Box from 'core/ui/Box';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import Typography from 'core/ui/Typography';
import { defaultScoresVariations } from 'core/utils/consts';
import { hasScore } from 'core/utils/helpers';
import { useTranslation } from 'react-i18next';

const TeamScoreItem = ({
  sectionData,
  workout,
  currentIndexScore,
  sectionUserScores,
  changeCurrentScores,
}) => {
  const { t } = useTranslation();

  const currentEditScores = sectionUserScores[currentIndexScore] || {};

  return (
    <div className="tw-flex tw-flex-row tw-justify-end tw-gap-2">
      <ScoreFields
        workout={workout}
        sectionData={sectionData}
        scoresTypes={sectionData.scores[currentIndexScore]}
        currentEditScores={currentEditScores}
        timeCapped={currentEditScores.timeCapped}
        changeCurrentScores={changeCurrentScores}
      />

      {hasScore(sectionData.scores[currentIndexScore], 'tieBreak') && (
        <Box mt={3}>
          <Typography>Tie Break</Typography>
          <Typography className="roboto-regular">
            {sectionData.scores[currentIndexScore]?.tieBreakDescription}
          </Typography>

          <ScoreFields
            workout={workout}
            sectionData={sectionData}
            scoresTypes={{ [sectionData.scores[currentIndexScore].tieBreakScore]: true }}
            currentEditScores={currentEditScores}
            changeCurrentScores={changeCurrentScores}
            prefix="tieBreak"
          />
        </Box>
      )}

      {(!workout.isCompetition || !!workout.competitionUseRxScaledScores) && (
        <Box my={3} className="gap-5">
          <SelectBoxObject
            propValue="id"
            propLabel="name"
            label={t('channel.scoreDivision')}
            options={defaultScoresVariations}
            value={currentEditScores.variation}
            setValue={(v) =>
              changeCurrentScores({
                variation: v,
                variationName: defaultScoresVariations?.find((x) => x.id == v)?.name,
              })
            }
          />
        </Box>
      )}
    </div>
  );
};

TeamScoreItem.defaultProps = {};

export default TeamScoreItem;
