import React, { useContext, useRef } from 'react';
import Box from 'core/ui/Box';
import { ButtonPrimary } from 'core/ui/Button';
import { useTranslation } from 'react-i18next';
import { ButtonGroup, Paper, MenuList, MenuItem, Popper } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import notifications from 'modules/notifications';
import { primary, secondary } from 'core/ui/Colors';
import { ShowContext } from 'components/Competition/Show';

const MenuActions = ({ member, competition, refresh }) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const showContext = useContext(ShowContext);
  const { request } = useActions();
  const dispatch = useDispatch();
  const anchorRef = useRef();

  const reSendWaiverRequest = () => {
    request({
      action: Modules.usersContracts.actions.sendWaiverToUser,
      data: {
        ownerableId: competition?.id,
        ownerableType: 'Competition',
        documentId: competition?.documentWaiverId,
        userId: member?.user?.id,
        kind: 'waivers',
      },
      options: {
        onSuccess: () => {
          dispatch(notifications.success('Waiver has been sent.'));
        },
      },
    });
  };

  const toggleCheckIn = () => {
    request({
      action: Modules.competitionsUsers.actions.update,
      data: {
        competitionId: competition?.id,
        userId: member?.user?.id,
        checkIn: !member?.checkIn,
        regType: 'competitor',
      },
      options: {
        onSuccess: () => {
          refresh();
          dispatch(notifications.success('Waiver has been sent.'));
        },
      },
    });
  };

  const openTeamEdit = () => {
    showContext?.setOpenTeamEdit({
      open: true,
      userId: member?.user?.id,
      regType: member?.regType,
    });
  };

  return (
    <Box>
      <Box>
        <ButtonGroup variant="contained" color="primary" ref={anchorRef} aria-label="split button">
          <ButtonPrimary
            buttonColor={member?.checkIn ? primary : secondary}
            ref={anchorRef}
            onClick={() => setOpen(!open)}
          >
            Actions <ArrowDropDown />
          </ButtonPrimary>
        </ButtonGroup>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          style={{ zIndex: 9999 }}
          disablePortal
        >
          <Box>
            <Paper>
              <MenuList id="split-button-menu">
                {competition?.requireWaiver && member?.user?.waiver && (
                  <>
                    <MenuItem
                      onClick={() => {
                        reSendWaiverRequest();
                        setOpen(false);
                      }}
                    >
                      {t('button.resendWaiver')}
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        window.open(member?.user?.waiver?.documentUrl);
                        setOpen(false);
                      }}
                    >
                      {t('button.downloadWaiver')}
                    </MenuItem>
                  </>
                )}

                <MenuItem
                  onClick={() => {
                    openTeamEdit();
                    setOpen(false);
                  }}
                >
                  {t('button.editTeam')}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    toggleCheckIn();
                    setOpen(false);
                  }}
                >
                  {t(member?.checkIn ? 'button.checkOut' : 'button.checkIn')}
                </MenuItem>
              </MenuList>
            </Paper>
          </Box>
        </Popper>
      </Box>
    </Box>
  );
};

export default MenuActions;
