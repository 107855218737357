import { lazy } from 'react';

const FilterNotFound = lazy(() => import('./FilterNotFound'));

const FiltersAddWorkflow = lazy(() => import('./FiltersAddWorkflow'));
const FiltersAnniversary = lazy(() => import('./FiltersAnniversary'));
const FiltersBirthday = lazy(() => import('./FiltersBirthday'));
const FiltersFirstVisitCheckin = lazy(() => import('./FiltersFirstVisitCheckin'));
const FiltersMembershipPurchase = lazy(() => import('./FiltersMembershipPurchase'));
const FiltersRegistrationLapse = lazy(() => import('./FiltersRegistrationLapse'));
const FiltersSendEmail = lazy(() => import('./FiltersSendEmail'));
const FiltersTotalClasses = lazy(() => import('./FiltersTotalClasses'));
const FiltersMembershipLimit = lazy(() => import('./FiltersMembershipLimit'));
const FiltersMembershipCancelled = lazy(() => import('./FiltersMembershipCancelled'));
const FiltersStagesChanges = lazy(() => import('./FiltersStagesChanges'));
const FiltersNewGroupMessage = lazy(() => import('./FiltersNewGroupMessage'));
const FiltersNewChannelEventRegistration = lazy(
  () => import('./FiltersNewChannelEventRegistration'),
);
const FiltersNoShowClasses = lazy(() => import('./FiltersNoShowClasses'));
const FiltersEngageFlow = lazy(() => import('./FiltersEngageFlow'));
const FiltersCalendarBooking = lazy(() => import('./FiltersCalendarBooking'));
const FiltersMembershipExpiring = lazy(() => import('./FiltersMembershipExpiring'));

export const getFilterComponent = (automationTypeFunction) => {
  switch (automationTypeFunction) {
    case 'AddWorkflow':
      return FiltersAddWorkflow;
    case 'Anniversary':
      return FiltersAnniversary;
    case 'Birthday':
      return FiltersBirthday;
    case 'FirstVisitCheckin':
      return FiltersFirstVisitCheckin;
    case 'MembershipPurchase':
      return FiltersMembershipPurchase;
    case 'RegistrationLapse':
      return FiltersRegistrationLapse;
    case 'SendEmail':
      return FiltersSendEmail;
    case 'TotalClasses':
      return FiltersTotalClasses;
    case 'MembershipLimit':
      return FiltersMembershipLimit;
    case 'MembershipCancelled':
      return FiltersMembershipCancelled;
    case 'StagesChanges':
      return FiltersStagesChanges;
    case 'NewGroupMessage':
      return FiltersNewGroupMessage;
    case 'NewChannelEventRegistration':
      return FiltersNewChannelEventRegistration;
    case 'NoShowClasses':
      return FiltersNoShowClasses;
    case 'EngageFlow':
      return FiltersEngageFlow;
    case 'CalendarBooking':
      return FiltersCalendarBooking;
    case 'MembershipExpiring':
      return FiltersMembershipExpiring;
    default:
      return FilterNotFound;
  }
};

export const getExistsFilterComponent = (automationTypeFunction) =>
  getFilterComponent(automationTypeFunction) !== FilterNotFound;
