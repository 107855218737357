import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import { ButtonPrimary } from 'core/ui/Button';
import { Search } from '@material-ui/icons';
import { sessionSelectors } from 'modules/session';
import { useSelector } from 'react-redux';
import useSelectors from 'modules/map/useSelectors';
import { channelReportsModule } from 'modules/channelReports';
import Grid from 'core/ui/Grid';
import ChoosenChannelTags from 'core/ui/ChoosenChannelTags';
import TablePausedMembers from './Table';

const PausedMembers = ({ getReport, loading, channel }) => {
  const { t } = useTranslation();
  const isAdmin = useSelector((state) => sessionSelectors.getIsAdmin(state));
  const [filters, setFilters] = useState({ membersTags: [], membershipsTags: [] });

  const data = useSelectors(channelReportsModule, 'data');

  useEffect(() => {
    if (isAdmin) {
      getReport(filters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdmin, filters]);

  return (
    <Box>
      <Box display="flex" mb={3} justifyContent="flex-end" flexWrap="wrap">
        <Box flexGrow={1}>
          <Grid container spacing={5}>
            <Grid item xs={12} md={4}>
              <ChoosenChannelTags
                label={t('channel.label.memberTags')}
                placeholder={t('channel.tags')}
                val={filters?.membersTags ? [...filters?.membersTags] : []}
                setValue={(v) =>
                  setFilters((prev) => ({ ...prev, membersTags: v?.map((x) => x?.name || x) }))
                }
                channelId={channel?.id}
                bordered
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <ChoosenChannelTags
                label={t('channel.label.membershipsTags')}
                placeholder={t('channel.tags')}
                val={filters?.membershipsTags ? [...filters?.membershipsTags] : []}
                setValue={(v) =>
                  setFilters((prev) => ({ ...prev, membershipsTags: v?.map((x) => x?.name || x) }))
                }
                channelId={channel?.id}
                bordered
              />
            </Grid>
          </Grid>
        </Box>
        <Box>
          <ButtonPrimary disabled={loading} onClick={() => getReport()}>
            <Search style={{ color: 'white' }} fontSize="small" />
            &nbsp;{t('button.view')}&nbsp;
          </ButtonPrimary>
        </Box>
      </Box>

      <TablePausedMembers channel={channel} data={data} refresh={getReport} loading={loading} />
    </Box>
  );
};

export default PausedMembers;
