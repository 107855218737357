import React from 'react';
import Box from 'core/ui/Box';
import ReactPlayer from 'react-player';
import Paper from 'core/ui/Paper';
import Grid from 'core/ui/Grid';

export default function BenchmarkVideos({ selectedBenchmark }) {
  const videos = (selectedBenchmark && selectedBenchmark.videos) || [];
  if (videos && videos.length === 0) {
    return <></>;
  }

  return (
    <Paper className="paper-rounded" mt={3}>
      <Box display="flex" alignItems="center" style={{ width: '100%' }} p={5}>
        <Grid container spacing={3}>
          {videos &&
            videos.map((video, index) => (
              <Grid item xs={12} md={6} key={index.toString()}>
                <Box style={{ borderRadius: 10, overflow: 'hidden' }}>
                  <Box className="container-aspect container-aspect-57">
                    <Box className="item-aspect">
                      <ReactPlayer controls url={video} width="100%" height="100%" />
                    </Box>
                  </Box>
                </Box>
              </Grid>
            ))}
        </Grid>
      </Box>
    </Paper>
  );
}
