import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form-v5';
import { useDispatch } from 'react-redux';
import notifications from 'modules/notifications';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import useSelectors from 'modules/map/useSelectors';
import { channelsRatesTemplatesModule } from 'modules/channelsRatesTemplates';
import { channelsCoachesRatesModule } from 'modules/channelsCoachesRates';

export default ({ channel, coach, mode, item, callback }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loading = useSelectors(channelsCoachesRatesModule, 'loading');
  const loadingTemplates = useSelectors(channelsRatesTemplatesModule, 'loading');
  const templates = useSelectors(channelsRatesTemplatesModule, 'data');
  const { request } = useActions();
  const { register, handleSubmit, setValue, watch, reset, errors } = useForm();

  const [member, setMember] = useState(null);
  const [members, setMembers] = useState([]);

  useEffect(() => {
    if (channel && channel.id) {
      request({
        action: Modules.channelsRatesTemplates.actions.getChannelsRatesTemplates,
        data: { channelId: channel.id },
      });
    }
  }, [request, channel]);

  const onSubmit = (values) => {
    request({
      action: !item?.id
        ? Modules.channelsCoachesRates.actions.postChannelsCoachesRates
        : Modules.channelsCoachesRates.actions.putChannelsCoachesRates,
      data: {
        ...values,
        channelsWorkoutsTypeIds: values?.channelsWorkoutsTypeIds,
        membersAttributes: members.map((x) => ({
          id: x?.id,
          userId: x?.user?.id,
          channelMembershipPlanIds: x?.membershipPlanIds,
          _destroy: x?._destroy,
        })),
        id: item?.id || null,
        channelId: channel.id,
        userId: coach?.id,
      },
      options: {
        onSuccess: () => {
          if (callback) {
            callback(values.name);
          }
          dispatch(notifications.success(t('message.success.save.rateTemplate')));
          request({
            action: Modules.channelsCoachesRates.actions.getChannelsCoachesRates,
            data: { channelId: channel.id, userId: coach?.id },
          });
        },
      },
    });
  };

  useEffect(() => {
    register('channelsRatesTemplateId', { required: true });
    register('active', { required: false });
    register('channelsWorkoutsTypeIds', { required: false });

    if (mode === 'new') {
      setValue('active', true);
      setValue('channelsRatesTemplateId', 4);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register]);

  useEffect(() => {
    if (item && item?.id) {
      reset({
        ...item,
        channelsWorkoutsTypeIds: item?.channelsWorkoutsTypes?.map((x) => x.id),
      });

      setTimeout(() => {
        setMembers(
          item?.members?.map((p) => ({
            id: p?.id,
            user: p?.user,
            membershipPlanIds: p?.channelMembershipPlans?.map((x) => x.id),
          })),
        );
      }, 200);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, item?.id]);

  const addUser = () => {
    if (!member?.id) {
      dispatch(notifications(t('channel.selectAMember')));
      return;
    }
    if (!members?.find((x) => x?.user?.id === member?.id)) {
      setMembers((prev) => [...prev, { user: member, membershipPlanIds: [] }]);
      setMember(null);
    }
  };

  const changeMembershipPlans = (itemChild, v) => {
    setMembers((prev) =>
      prev.map((itemPrev) => {
        if (itemPrev?.user?.id === itemChild?.user?.id) {
          return { ...itemPrev, membershipPlanIds: v };
        }

        return itemPrev;
      }),
    );
  };

  const deleteMember = (itemChild) => {
    setMembers((prev) =>
      prev.map((itemPrev) => {
        if (itemPrev?.user?.id === itemChild?.user?.id) {
          return { ...itemPrev, _destroy: true };
        }

        return itemPrev;
      }),
    );
  };

  return {
    register,
    setValue,
    watch,
    reset,
    errors,
    onSubmit,
    handleSubmit,
    loading,
    templates,
    addUser,
    member,
    setMember,
    members,
    loadingTemplates,
    setMembers,
    changeMembershipPlans,
    deleteMember,
  };
};
