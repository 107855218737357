import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form-v5';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import useSelectors from 'modules/map/useSelectors';
import { personalRecordMovementsModule } from 'modules/personalRecordMovements';
import notifications from 'modules/notifications';
import { useDispatch } from 'react-redux';
import { currency } from 'core/utils/formatters';

export default ({ callback, channel }) => {
  const { request } = useActions();
  const loadingStatus = useSelectors(personalRecordMovementsModule, 'loadingStatus');
  const loading = useSelectors(personalRecordMovementsModule, 'loading');
  const { register, handleSubmit, setValue, watch, reset, errors } = useForm();
  const dispatch = useDispatch();
  const [status, setStatus] = useState();

  const onSubmit = (values) => {
    request({
      action: Modules.ownersPaymentsFees.actions.save,
      data: { ...values, ownerableType: 'Channel', ownerableId: channel?.id },
      options: {
        onSuccess: () => {
          if (callback) {
            callback();
          }
        },
        onError: (errr) => {
          dispatch(notifications.error(errr));
        },
      },
    });
  };

  useEffect(() => {
    register('cardFee', { required: true });
    register('cardAmount', { required: true });
    register('achFee', { required: true });
    register('achAmount', { required: true });
    register('cardpointeTemplateId', { required: true });

    register('cardpointeMerchantUser', { required: false });
    register('cardpointeMerchantPwd', { required: false });

    register('cardpointeProcessingAchMid', { required: false });
    register('cardpointeMerchantAchPwd', { required: false });
    register('cardpointeMerchantAchUser', { required: false });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register]);

  useEffect(() => {
    if (channel && channel.id) {
      const bk = { ...channel?.ownersPaymentsFee };

      reset({
        cardpointeMerchantUser: bk?.cardpointeMerchantUser,
        cardpointeProcessingAchMid: bk?.cardpointeProcessingAchMid,
        cardpointeMerchantAchUser: bk?.cardpointeMerchantAchUser,
        cardpointeTemplateId: bk.cardpointeTemplateId,

        cardFee: currency(bk.cardFee),
        cardAmount: currency(bk.cardAmount),
        achFee: currency(bk.achFee),
        achAmount: currency(bk.achAmount),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel]);

  const checkStatus = () => {
    request({
      action: Modules.ownersPaymentsFees.actions.checkCardpointeStatus,
      data: { ownerableType: 'Channel', ownerableId: channel?.id },
      options: {
        onSuccess: (data) => {
          setStatus(data);
        },
        onError: (errr) => {
          dispatch(notifications.error(errr));
        },
      },
    });
  };

  return {
    register,
    setValue,
    watch,
    reset,
    errors,
    onSubmit,
    handleSubmit,
    submitForm: handleSubmit(onSubmit),

    loading,
    checkStatus,
    status,
    loadingStatus,
  };
};
