import { isMobile } from 'react-device-detect';

export const AppScreens = {
  Workout: {
    View: 'WorkoutView',
  },
  Dashboard: 'Dashboard',
  Channel: {
    View: 'ChannelView',
  },
  Coach: {
    View: 'CoachView',
  },
  Competition: {
    View: 'CompetitionView',
    Watch: 'WatchWorkout',
  },
  Stream: {
    View: 'Stream',
  },
};
export default () => {
  const openInApp = ({ screen, param }) => {
    // if (isMobile) {
    //   const exportUrl = `streamfitness://${screen}/${param}`;
    //   window.location = exportUrl;
    // }
  };
  const openWatchCompScreen = ({ screen, workoutId, competitionId }) => {
    // if (isMobile) {
    //   window.location = `streamfitness://${screen}/${competitionId}/${workoutId}`;
    // }
  };
  return {
    openInApp,
    openWatchCompScreen,
  };
};
