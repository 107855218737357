import api from 'core/api';
import { getErrorMessage } from 'core/api/api-error';
import { call, put } from 'redux-saga/effects';

export const ownerableMessagesModule = 'ownerableMessages';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const loadingCountStates = {
  start: { loadingCount: true },
  error: { loadingCount: false },
  success: { loadingCount: false },
};

const loadingSendStates = {
  start: { loadingSend: true },
  error: { loadingSend: false },
  success: { loadingSend: false },
};

const loadingContactsStates = {
  start: { loadingContacts: true },
  error: { loadingContacts: false },
  success: { loadingContacts: false },
};

const actions = {
  getMessages: {
    module: ownerableMessagesModule,
    name: 'getMessages',
    api: (params) => api.get(`/messages`, { params }),
    params: {
      start: ['params'],
      error: [],
      success: ['data'],
    },
    state: loadingStates,
  },
  postMessage: {
    module: ownerableMessagesModule,
    name: 'postMessage',
    api: (data) => api.post(`/messages`, data),
    params: {
      start: ['params'],
      error: [],
      success: [],
    },
    state: loadingSendStates,
  },
  updateMessage: {
    module: ownerableMessagesModule,
    name: 'updateMessage',
    api: (data) => api.put(`/messages/${data?.id}`, data),
    params: {
      start: ['params'],
      error: [],
      success: [],
    },
    state: loadingSendStates,
  },
  getMonthResume: {
    module: ownerableMessagesModule,
    name: 'getMonthResume',
    api: (data) =>
      api.get(`/messages/resume`, {
        params: { channelId: data?.channelId },
      }),
    params: {
      start: ['params'],
      error: [],
      success: ['resume'],
    },
    state: loadingSendStates,
  },
  inactiveMessage: {
    module: ownerableMessagesModule,
    name: 'inactiveMessage',
    api: (params) => api.delete(`/messages/${params.messageId}`, { params }),
    params: {
      start: ['params'],
      error: [],
      success: [],
    },
    state: loadingStates,
  },
  getChannelMembersTypesCount: {
    module: ownerableMessagesModule,
    name: 'getChannelMembersTypesCount',
    api: (params) => api.get(`/messages/members_types_count`, { params }),
    params: {
      start: ['params'],
      error: [],
      success: ['types'],
    },
    state: loadingCountStates,
  },
  getMessagesTypes: {
    module: ownerableMessagesModule,
    name: 'getMessagesTypes',
    api: (params) => api.get(`/messages/messages_types`, { params }),
    params: {
      start: ['params'],
      error: [],
      success: ['messagesTypes'],
    },
    state: loadingCountStates,
  },
  getMessageContacts: {
    module: ownerableMessagesModule,
    name: 'getMessageContacts',
    api: (params) => api.get(`/messages/${params?.messageId}/contacts`, { params }),
    *sagas(Creators, { params }) {
      try {
        const resp = yield call(actions.getMessageContacts.api, params);
        yield put(Creators.getMessageContactsSuccess(resp.data, resp.pagination));
      } catch (error) {
        yield put(Creators.getMessageContactsError(getErrorMessage(error)));
      }
    },
    params: {
      start: ['params'],
      error: [],
      success: ['contacts', 'pagination'],
    },
    state: loadingContactsStates,
  },
  getTemplateMessages: {
    module: ownerableMessagesModule,
    name: 'getTemplateMessages',
    api: (params) => api.get(`/messages/${params.messageId}/messages`, { params }),
    *sagas(Creators, { params }) {
      try {
        const resp = yield call(actions.getTemplateMessages.api, params);
        yield put(Creators.getTemplateMessagesSuccess(resp.data, resp.pagination));
      } catch (error) {
        yield put(Creators.getTemplateMessagesError(getErrorMessage(error)));
      }
    },
    params: {
      start: ['params'],
      error: [],
      success: ['messages', 'pagination'],
    },
    state: loadingContactsStates,
  },
};

export default {
  actions,
  state: {
    loading: false,
    loadingCount: false,
    loadingSend: false,
    loadingContacts: false,

    data: [],
    messages: [],
    messagesTypes: [],

    types: {},
    resume: null,
    pagination: {},
  },
};
