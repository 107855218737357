/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from 'react';
import { ButtonPrimarySm } from 'core/ui/Button';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import Typography from 'core/ui/Typography';
import Paper from 'core/ui/Paper';

import useActions from 'modules/map/useActions';
import Modules from 'modules';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import ModalDocumentViewer from '../../../../DocumentsBuilder/ModalDocumentViewer';

const QuestionnaireSelector = ({ currentPlan, setCurrentPlan, channel }) => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [modalViewPdf, setModalViewPdf] = useState({ open: false });
  const { request } = useActions();

  const getData = () => {
    request({
      action: Modules.documents.actions.getDocuments,
      data: { ownerableId: channel.id, ownerableType: 'Channel', kind: 'questionnaire' },
      options: {
        onSuccess: (resp) => {
          setData(resp);
        },
      },
    });
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Paper p={3} mt={2} className="paper-rounded">
        <Box display="flex">
          <Box flexGrow={1}>
            <Typography>{t('document.kind.questionnaire')}</Typography>
          </Box>
        </Box>

        <Box p={3}>
          <Box mt={2}>
            <SelectBoxObject
              emptyItem
              placeholder={t('channel.membership.contract.select')}
              emptyValue={null}
              value={currentPlan?.documentQuestionnaireId}
              setValue={(v) => setCurrentPlan((prev) => ({ ...prev, documentQuestionnaireId: v }))}
              options={data}
            />
          </Box>
          {currentPlan?.documentQuestionnaireId && modalViewPdf.open && (
            <ModalDocumentViewer
              close={() => setModalViewPdf({ open: false })}
              // eslint-disable-next-line eqeqeq
              document={data?.find((x) => x.id == currentPlan?.documentQuestionnaireId)}
              open={modalViewPdf.open}
            />
          )}
          {currentPlan?.documentQuestionnaireId && (
            <Box display="flex" justifyContent="center" my={3} mb={1}>
              <ButtonPrimarySm color="primary" onClick={() => setModalViewPdf({ open: true })}>
                {t('button.viewQuestionnaire')}
              </ButtonPrimarySm>
            </Box>
          )}
        </Box>
      </Paper>
    </Box>
  );
};

export default QuestionnaireSelector;
