/* eslint-disable react/prop-types */
import React from 'react';
import Typography from 'core/ui/Typography';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import { redColor } from 'core/ui/Colors';
import ClassScheduleInfo from './ClassScheduleInfo';

export default ({ workout, compare }) => {
  const { t } = useTranslation();
  return (
    <Box mt={2}>
      {!compare && (
        <>
          <ClassScheduleInfo workout={workout} />
          {workout?.canceled && (
            <Typography variant="h5" style={{ color: redColor }}>
              {t('workout.canceled')}
            </Typography>
          )}
        </>
      )}
      <Box display="flex" mt={1}>
        <Typography>{t('workout.difficulty')}</Typography>
        <Typography color="textSecondary" ml={3} style={{ textTransform: 'capitalize' }}>
          {workout?.filterDifficulty && workout?.filterDifficulty?.name}
        </Typography>
      </Box>
      <Box display="flex" mt={1}>
        <Typography>{t('workout.equipment')}</Typography>
        <Typography color="textSecondary" ml={3} style={{ textTransform: 'capitalize' }}>
          {workout?.filterEquipment && workout?.filterEquipment.name}
        </Typography>
      </Box>
      <Box display="flex" mt={1}>
        <Typography>{t('workout.label.access')}</Typography>
        {workout?.online && workout?.inPerson && (
          <Typography color="textSecondary" ml={3} style={{ textTransform: 'capitalize' }}>
            {t('workout.setting.online')},&nbsp;{t('workout.setting.inPerson')}
          </Typography>
        )}
        {!workout?.online && workout?.inPerson && (
          <Typography color="textSecondary" ml={3} style={{ textTransform: 'capitalize' }}>
            {t('workout.setting.inPerson')}
          </Typography>
        )}
        {workout?.online && !workout?.inPerson && (
          <Typography color="textSecondary" ml={3} style={{ textTransform: 'capitalize' }}>
            {t('workout.setting.online')}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
