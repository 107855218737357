import React, { useState } from 'react';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import { ButtonPrimary } from 'core/ui/Button';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import Sections from 'components/Workout/Form/Section/Sections';
import Paper from 'core/ui/Paper';
import LoaderSm from 'core/ui/LoaderSm';
import CardUser from 'components/Channel/SelectorChannelUser/Card';
import { workoutInviteTypes } from 'core/utils/consts';
import Divider from 'core/ui/Divider';
import ModalFormSection from 'components/Workout/Form/Section/Modal';
import useForm from './useForm';

const FormCustomWorkout = ({ callback, workoutId, duplicate, dataInvite }) => {
  const { t } = useTranslation();
  const [modalSection, setModalSection] = useState();

  const { watch, setValue, errors, submitForm, loading, sections, setSections } = useForm({
    callback,
    workoutId,
    duplicate,
    dataInvite,
  });

  return (
    <Box>
      <Paper p={3} mb={3}>
        <TextFieldRounded
          error={errors.name}
          placeholder={t('workout.label.className')}
          label={t('workout.label.className').toUpperCase()}
          value={watch('name')}
          onChange={(v) => setValue('name', v)}
          bordered
        />
      </Paper>
      {dataInvite && (
        <Box my={3}>
          <Paper p={3} elevation={4} className="paper-rounded" style={{ position: 'relative' }}>
            {dataInvite.inviteType === workoutInviteTypes.live_one_to_one && (
              <Box display="flex" flexWrap="wrap">
                <CardUser user={dataInvite?.user} />
              </Box>
            )}
            {dataInvite.inviteType !== workoutInviteTypes.live_one_to_one && dataInvite?.users && (
              <Box>
                {dataInvite.users.map((user, index) => (
                  <Box key={user?.id}>
                    <CardUser user={user} />

                    {dataInvite.users.length - 1 > index && <Divider my={3} />}
                  </Box>
                ))}
              </Box>
            )}
          </Paper>
        </Box>
      )}
      <ModalFormSection
        modalSection={modalSection}
        setModalSection={setModalSection}
        sections={sections}
        setSections={setSections}
        custom
      />
      <Paper p={3} mb={3}>
        <Sections
          addNewSection={() => setModalSection({ open: true })}
          openEditSection={(sec) => setModalSection({ open: true, currentSection: sec })}
          setValue={setValue}
          sections={sections}
          setSections={setSections}
          watch={watch}
          workout={{ id: workoutId }}
          custom
          mode={workoutId ? 'edit' : 'new'}
        />
      </Paper>

      <Box flexGrow={1} display="flex" justifyContent="flex-end" my={5}>
        <ButtonPrimary disabled={loading} style={{ width: 150 }} onClick={() => submitForm()}>
          <LoaderSm loading={loading} />
          {t('button.save')}
        </ButtonPrimary>
      </Box>
    </Box>
  );
};

FormCustomWorkout.defaultProps = {
  callback: () => {},
  benchmark: null,
};

export default FormCustomWorkout;
