import { makeStyles } from '@material-ui/core';
import { secondaryXLight } from 'core/ui/Colors';

const useStylesLeaderboard = makeStyles(() => ({
  boxWrapper: {},
  boxUser: {
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: secondaryXLight,
    },
  },
  btnLike: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: secondaryXLight,
    },
  },
  buttonLeft: {
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20,
    padding: 5,
    width: 80,
    display: 'flex',
    justifyContent: 'center',
    color: 'white',
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.9,
    },
  },
  buttonRight: {
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
    padding: 5,
    width: 80,
    display: 'flex',
    justifyContent: 'center',
    color: 'white',
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.9,
    },
  },
}));

export default useStylesLeaderboard;
