/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import Typography from 'core/ui/Typography';
import Box from 'core/ui/Box';
import SFDataTable from 'core/ui/DataTable';
import { date, timeWithoutTz } from 'core/utils/formatters';
import { colorLightGrey, colorCode, successColor, primary } from 'core/ui/Colors';
import Paper from 'core/ui/Paper';
import { ButtonPrimarySm } from 'core/ui/Button';
import { Check } from '@material-ui/icons';
import { TextServiceContext } from 'contexts';

const TableMessagesReplies = ({ messages, stopContact }) => {
  const textServiceContext = useContext(TextServiceContext);

  const columns = [
    {
      name: 'Body',
      selector: 'body',
      sortable: true,
      cell: (contact) => <Typography>{contact.body}</Typography>,
    },
    {
      name: 'Date',
      selector: 'date',
      sortable: true,
      cell: (contact) => <Typography>{contact.date}</Typography>,
    },
    {
      name: 'Name',
      selector: 'contactName',
      sortable: true,
      cell: (contact) => <Typography>{contact.contactName}</Typography>,
    },
    {
      name: 'Phone',
      selector: 'phone',
      sortable: true,
      cell: (contact) => (
        <Typography>
          {contact.allowSend ? (
            <Check style={{ fontSize: 15, color: successColor }} />
          ) : (
            <Check style={{ fontSize: 15, color: primary }} />
          )}
          {contact.phone}
        </Typography>
      ),
    },
    {
      name: '',
      selector: '',
      sortable: false,
      cell: (contact) => (
        <Box>
          <ButtonPrimarySm
            onClick={() => {
              textServiceContext.setValue('replyTo', contact);
              window.scrollTo({
                top: 0,
                behavior: 'smooth',
              });
            }}
          >
            Reply
          </ButtonPrimarySm>
          {contact.allowSend && (
            <ButtonPrimarySm style={{ marginLeft: 3 }} onClick={() => stopContact(contact)}>
              Stop
            </ButtonPrimarySm>
          )}
        </Box>
      ),
    },
  ];

  const rows =
    messages &&
    messages.map((reply) => ({
      id: reply.id,
      contactName: reply.contactName,
      phone: reply.formattedPhone,
      body: `${reply.body.substr(0, 15)}${reply.body && reply.body.length > 15 ? '...' : ''}`,
      fullText: reply.body,
      date: `${date(reply.createdAt)} - ${timeWithoutTz(reply.createdAt)}`,
      data: reply.data,
      allowSend: reply.allowSend,
    }));

  return (
    <Box>
      <SFDataTable
        columns={columns}
        data={rows}
        pagination
        showPaper={false}
        noTableHead={false}
        noHeader
        showExport
        expandableRows
        expandableRowsComponent={<ExpanableComponent />}
      />
    </Box>
  );
};

const ExpanableComponent = ({ data }) => (
  <Box p={5} style={{ backgroundColor: colorLightGrey }}>
    <Box m={5}>
      <Typography align="center">{data.fullText}</Typography>
    </Box>
    <Paper p={1} px={5} className="paper-rounded">
      <Box display="flex" mb={1}>
        <Typography mr={1} variant="body2">
          Reply data
        </Typography>
      </Box>
      <Box
        style={{
          backgroundColor: colorCode,
          padding: 5,
          margin: 2,
          borderRadius: 5,
        }}
      >
        <code>{JSON.stringify(data.data, null, 2)}</code>
      </Box>
    </Paper>
  </Box>
);

TableMessagesReplies.propTypes = {};

TableMessagesReplies.defaultProps = {};

export default TableMessagesReplies;
