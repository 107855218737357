import api from 'core/api';
import { call, put } from 'redux-saga/effects';

export const adminChannelsModule = 'adminChannels';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const loadingPullStates = {
  start: { loadingPull: true },
  error: { loadingPull: false },
  success: { loadingPull: false },
};

const loadingCardpointeStates = {
  start: { loadingCardpointe: true },
  error: { loadingCardpointe: false },
  success: { loadingCardpointe: false },
};

const loadingCloneAllStates = {
  start: { loadingCloneAll: true },
  error: { loadingCloneAll: false },
  success: { loadingCloneAll: false },
};

const loadingPendingMembershipsStates = {
  start: { loadingPendingMemberships: true },
  error: { loadingPendingMemberships: false },
  success: { loadingPendingMemberships: false },
};

const loadingLogsStates = {
  start: { loadingLogs: true },
  error: { loadingLogs: false },
  success: { loadingLogs: false },
};

const actions = {
  getChannels: {
    module: adminChannelsModule,
    name: 'getChannels',
    api: (params) => api.get(`/admin/channels`, { params }),
    *sagas(Creators, { params, options }) {
      try {
        const resp = yield call(actions.getChannels.api, params);
        yield put(Creators.getChannelsSuccess(resp.data, resp.pagination));
        if (options.onSuccess) {
          options.onSuccess(resp.data, resp.pagination);
        }
      } catch (error) {
        if (options.onError) {
          options.onError(error);
        }
        yield put(Creators.getChannelsError());
      }
    },
    params: {
      start: ['params'],
      error: [''],
      success: ['channels', 'pagination'],
    },
    state: loadingStates,
  },
  pullStripeData: {
    module: adminChannelsModule,
    name: 'pullStripeData',
    api: (params) => api.post(`/admin/channels/${params?.channelId}/pull_stripe`, params),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingPullStates,
  },
  cloneAllCustumersAndPaymentMethods: {
    module: adminChannelsModule,
    name: 'cloneAllCustumersAndPaymentMethods',
    api: (params) =>
      api.post(
        `/admin/channels/${params?.channelId}/clone_all_stripe_custumers_and_payment_methods`,
        params,
      ),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingCloneAllStates,
  },
  importCardpointe: {
    module: adminChannelsModule,
    name: 'importCardpointe',
    api: (params) =>
      api.post(`/admin/channels/${params?.channelId}/import_cardpointe`, params.formData),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingCardpointeStates,
  },

  getAuditLogs: {
    module: adminChannelsModule,
    name: 'getAuditLogs',
    api: (params) => api.get(`/admin/reports/audited_logs`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingLogsStates,
  },
  addAsAdminChannel: {
    module: adminChannelsModule,
    name: 'addAsAdminChannel',
    api: (params) => api.put(`/admin/channels/${params?.channelId}/add_as_admin`),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingPullStates,
  },
  updateChannelInfo: {
    module: adminChannelsModule,
    name: 'updateChannelInfo',
    api: (params) => api.put(`/admin/channels/${params?.channelId}`, params),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  getPendingMemberships: {
    module: adminChannelsModule,
    name: 'getPendingMemberships',
    api: (params) => api.get(`/admin/check_pending_memberships`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: ['pendingMemberships'],
    },
    state: loadingPendingMembershipsStates,
  },
};

export default {
  actions,
  state: {
    loading: false,
    loadingPull: false,
    loadingCardpointe: false,
    loadingCloneAll: false,
    channels: [],
    logs: [],
    pagination: null,
    pendingMemberships: null,
    loadingPendingMemberships: false,
  },
};
