import React from 'react';
import { ButtonPrimary } from 'core/ui/Button';
import Box from 'core/ui/Box';
import Typography, { fontStyle } from 'core/ui/Typography';
import { Chip, Link } from '@material-ui/core';
import { Edit, FileCopy } from '@material-ui/icons';
import Paper from 'core/ui/Paper';
import useSelectors from 'modules/map/useSelectors';
import { integrationsModule } from 'modules/integrations';
import { copyToClipBoard } from 'core/utils/helpers';
import LoaderSm from 'core/ui/LoaderSm';
import { primary, redColor, secondary } from 'core/ui/Colors';

const ChipKey = ({ title }) => (
  <Chip
    size="small"
    plac
    color="secondary"
    label={title}
    onDelete={() => copyToClipBoard(title)}
    deleteIcon={<FileCopy />}
  />
);

const signatureStatus = ['NOT_SENT', 'PENDING', 'SIGNED'];

export default ({ integration, cardpointeStep, editItem }) => {
  const loadingSignature = useSelectors(integrationsModule, 'loadingSignature');

  return (
    <Paper p={3}>
      {!!integration?.cardpointeStatusMessage && (
        <Box m={1}>
          <Typography>Integration Message Status</Typography>
          <Typography style={{ color: redColor }}>
            {integration?.cardpointeStatusMessage}
          </Typography>
        </Box>
      )}

      <Box>
        <>
          {!!integration?.cardpointeStatusGateway && (
            <Box m={1} display="flex" alignItems="center">
              <Typography style={fontStyle.large}>Gateway Status:</Typography>&nbsp;
              <Typography style={fontStyle.large} color="textSecondary">
                {integration?.cardpointeStatusGateway}
              </Typography>
            </Box>
          )}
          <Box m={1} display="flex" alignItems="center">
            <Typography style={fontStyle.large}>Onboarding Status:</Typography>&nbsp;
            <Typography style={fontStyle.large} color="textSecondary">
              {integration?.cardpointeStatusOnbarding ||
                'Please click sync data to submit to processor'}
            </Typography>
          </Box>

          {integration?.cardpointeSignatureStatus && (
            <Box mt={2}>
              <Box m={1} display="flex" alignItems="center">
                <Typography>Signature Status:</Typography>&nbsp;
                <Typography
                  style={{
                    color: signatureStatus.find((x) => x === integration?.cardpointeSignatureStatus)
                      ? primary
                      : 'red',
                  }}
                >
                  {integration?.cardpointeSignatureStatus}
                </Typography>
              </Box>
            </Box>
          )}

          <Box display="flex" justifyContent="flex-end">
            {integration?.cardpointeStep === 'draft' && (
              <>
                <ButtonPrimary
                  disabled={loadingSignature}
                  buttonColor={primary}
                  onClick={() => editItem(integration)}
                >
                  <Edit />
                </ButtonPrimary>
                <ButtonPrimary
                  disabled={loadingSignature}
                  buttonColor={primary}
                  style={{ marginLeft: 10 }}
                  onClick={() => cardpointeStep(integration.id, 'ready')}
                >
                  <LoaderSm loading={loadingSignature} />
                  Sync Data
                </ButtonPrimary>
              </>
            )}
            {integration?.cardpointeStep === 'ready' && (
              <ButtonPrimary
                disabled={loadingSignature}
                buttonColor={primary}
                onClick={() => cardpointeStep(integration.id, 'pending_sign')}
              >
                <LoaderSm loading={loadingSignature} />
                Add Signature
              </ButtonPrimary>
            )}
            {(integration?.cardpointeStep === 'pending_sign' ||
              integration?.cardpointeStatusOnbarding === 'LIVE') && (
              <ButtonPrimary
                disabled={loadingSignature}
                buttonColor={redColor}
                onClick={() => cardpointeStep(integration.id, 'draft')}
              >
                <LoaderSm loading={loadingSignature} />
                Cancel Signature
              </ButtonPrimary>
            )}
            {!!integration?.cardpointeStatusOnbarding && (
              <ButtonPrimary
                disabled={loadingSignature}
                buttonColor={secondary}
                style={{ marginLeft: 10 }}
                onClick={() => cardpointeStep(integration.id, 'update')}
              >
                <LoaderSm loading={loadingSignature} />
                Update Status
              </ButtonPrimary>
            )}
          </Box>

          {integration?.cardpointeStep === 'pending_sign' && (
            <Box display="flex" alignItems="center" justifyContent="center" m={4}>
              <Link
                style={{
                  marginLeft: 10,
                  fontSize: 24,
                  backgroundColor: primary,
                  padding: 10,
                  borderRadius: 5,
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
                className="hover"
                href={integration?.cardpointeSignatureUrl}
                target="_blank"
              >
                <LoaderSm loading={loadingSignature} />
                <Typography style={{ color: 'white' }}>Click to Sign</Typography>

                <Typography textCenter>
                  Please click add signature button to complete your application
                </Typography>
              </Link>
            </Box>
          )}
        </>
      </Box>

      <Box display="flex" flexDirection="column">
        {integration?.kind === 'zapier' && (
          <Typography align="center" mx={1} mb={1} variant="h5">
            YOUR API KEY:&nbsp;
            <ChipKey title={integration?.token} />
          </Typography>
        )}

        {integration?.kind === 'hybrid' && (
          <>
            <Typography mb={1}>
              GYM ID: <ChipKey title={integration?.externalId} />
            </Typography>
            <Typography variant="caption">
              TOKEN: <ChipKey title={integration?.token} />
            </Typography>
          </>
        )}
        {integration?.kind === 'shopify' && (
          <>
            <Typography mb={1}>
              SHOP: <ChipKey title={integration?.payload?.shop} />
            </Typography>
            <Typography variant="caption">
              ACCESS TOKEN: <ChipKey title={integration?.payload?.accessToken} />
            </Typography>
          </>
        )}
      </Box>
    </Paper>
  );
};
