/* eslint-disable react/prop-types */
import React from 'react';
import {} from 'react-i18next';
import Typography, { fontStyle } from 'core/ui/Typography';
import Box from 'core/ui/Box';
import Radio from 'core/ui/Radio';
import { Tooltip } from '@material-ui/core';
import { Info } from '@material-ui/icons';

const FormOptionYesNo = ({ t, currentPlan, setCurrentPlan, prop, label, helpText }) => (
  <Box>
    {helpText ? (
      <div className="tw-flex tw-flex-row tw-items-start tw-gap-x-1">
        <Typography style={fontStyle.label} mb={2} variant="body1">
          {label}
        </Typography>
        <Tooltip title={helpText}>
          <Info fontSize="small" />
        </Tooltip>
      </div>
    ) : (
      <Typography style={fontStyle.label} mb={2} variant="body1">
        {label}
      </Typography>
    )}

    <Box display="flex">
      <Box mr={3}>
        <Radio
          label={t('button.yes')}
          checked={!!currentPlan[prop]}
          onClick={() => setCurrentPlan((prev) => ({ ...prev, [prop]: true }))}
        />
      </Box>
      <Radio
        label={t('button.no')}
        checked={!currentPlan[prop]}
        onClick={() =>
          setCurrentPlan((prev) => ({
            ...prev,
            [prop]: false,
          }))
        }
      />
    </Box>
  </Box>
);

FormOptionYesNo.defaultProps = {
  currentPlan: {},
  setCurrentPlan: () => {},
};

export default FormOptionYesNo;
