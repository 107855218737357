import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from 'core/ui/Box';

import { useHistory } from 'react-router';
import Typography from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { sessionSelectors, sessionActions } from 'modules/session';
import { removeData } from 'core/utils/session-storage';

import { clearAuthHeaders } from 'core/api/auth-headers';
import FontIcon from 'core/ui/FontIcon';
import { AssignmentTurnedIn, BarChart, EventAvailable, List, Storage } from '@material-ui/icons';
import { primary } from 'core/ui/Colors';
import { Collapse, Hidden } from '@material-ui/core';
import ModalChannels from 'components/Profile/Dashboard/Channels/Modal';

import GroupWorkIcon from '@material-ui/icons/GroupWork';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { channelsSelectors } from 'modules/channels';
import { LayoutContext } from '..';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    background: '#5A5A5C',
    zIndex: 9999,
  },
  content: {
    flexGrow: 1,
  },
  logo: {
    width: 30,
    height: 30,
  },
  icon: {
    width: 30,
  },
  colorGrey: {
    backgroundColor: 'rgba(84,84,84)',
  },
  btnMenu: {
    backgroundColor: 'rgba(84,84,84,0.4)',
    padding: 15,
    color: 'white',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgba(84,84,84,1)',
    },
  },
  btnMenuActive: {
    backgroundColor: 'rgba(84,84,84,1)',
    padding: 15,
    color: 'white',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgba(84,84,84,1)',
    },
  },
  itemMenu: {
    cursor: 'pointer',
    transition: 'opacity .1s ease-in-out',
    opacity: 0.6,
    '&:hover': {
      opacity: 1,
    },
  },
  itemMenuActive: {
    opacity: 1,
  },
}));

const SideBar = () => {
  const layoutContext = useContext(LayoutContext);
  const history = useHistory();
  const isCoach = useSelector((state) => sessionSelectors.getIsCoach(state));
  const isAdminOrManager = useSelector((state) => sessionSelectors.getIsAdminOrManager(state));
  const isAdmin = useSelector((state) => sessionSelectors.getIsAdmin(state));
  const channel = useSelector((state) => channelsSelectors.getChannel(state));
  const isSupport = useSelector((state) => sessionSelectors.getIsSupport(state));
  const user = useSelector((state) => sessionSelectors.getUser(state));

  const isAuthenticated = useSelector((state) => sessionSelectors.getIsAuthenticated(state));
  const [adminCollapse, setAdminCollapse] = useState(false);
  const [modalChannels, setModalChannels] = useState(false);
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const logout = () => {
    clearAuthHeaders(removeData);
    removeData('loggedInRedirectPath');
    removeData('activeCoachChannel');
    dispatch(sessionActions.authLogOutStart());
    history.push('/');
  };

  const login = () => {
    layoutContext.setModalLogin({ open: true });
  };

  // eslint-disable-next-line react/prop-types
  const ItemMenu = ({ label, color, icon, iconMUI, onClick, active, divider, my = 3 }) => (
    <Box
      className={`${classes.itemMenu} ${active ? classes.itemMenuActive : ''}`}
      display="flex"
      onClick={onClick}
      my={my}
      mx={3}
    >
      <Box mr={5}>{icon ? <FontIcon iconName={icon} color={color || 'white'} /> : iconMUI}</Box>
      <Box
        display="flex"
        pb={2}
        style={{ borderBottom: divider ? '0.1px solid #A9A9A9' : '', width: '100%' }}
      >
        <Box flexGrow="1">
          <Typography className="roboto-regular" style={{ fontSize: 18, color: 'white' }}>
            {label}
          </Typography>
        </Box>
      </Box>
    </Box>
  );

  return (
    <div className={classes.root}>
      <ModalChannels open={modalChannels} setOpen={setModalChannels} sidebar />
      <Hidden only={['xs', 'sm']}>
        <Box style={{ height: '72px', background: '#404041' }} />
      </Hidden>
      <Hidden only={['md', 'lg', 'xl']}>
        <Box
          style={{ height: '45px', background: '#404041' }}
          justifyContent="flex-end"
          alignItems="center"
          display="flex"
        >
          <Box
            className={`${classes.itemMenu}`}
            display="flex"
            onClick={() => layoutContext.setDrawer((prev) => !prev)}
            mx={3}
            alignItems="center"
          >
            <FontIcon iconName="icon-action-nav" size={36} color={primary} />
          </Box>
        </Box>
      </Hidden>
      <Box display="flex" flexDirection="column" className={classes.content}>
        <Box display="flex" flexDirection="column" flexGrow="1">
          {isAuthenticated && isCoach && (
            <>
              {/* {user.hasChannel && (
                      <ItemMenu
                        onClick={() => history.push('/workout/new')}
                        active={layoutContext.activeMenu === 'newWorkout'}
                        label={t('sidebar.createClass')}
                        icon="icon-classes"
                        divider
                      />
                    )} */}
              <ItemMenu
                onClick={() =>
                  // eslint-disable-next-line no-nested-ternary
                  user.hasChannel
                    ? user.manageChannelId && !isAdminOrManager
                      ? history.push(`/channel/manage/${user.manageChannelId}`)
                      : setModalChannels(true)
                    : history.push('/channel/new')
                }
                active={layoutContext.activeMenu === 'newChannel'}
                label={t(user.hasChannel ? 'sidebar.manageChannels' : 'sidebar.createChannel')}
                icon="icon-live-tv"
                divider
              />
              <ItemMenu
                onClick={() => history.push(`/calendars/manage/${channel?.id}/${user?.id}`)}
                active={layoutContext.activeMenu === 'manageCalendar'}
                label={t('sidebar.manageCalendar')}
                iconMUI={<EventAvailable style={{ color: 'white', fontSize: 26 }} />}
                divider
              />
              <ItemMenu
                onClick={() => history.push('/competition/new')}
                active={layoutContext.activeMenu === 'newCompetition'}
                label={t('sidebar.createCompetition')}
                icon="icon-kettlebell"
                divider
              />
            </>
          )}

          {isAuthenticated && (
            <>
              {(isSupport || isAdmin || user?.partner?.id) && (
                <ItemMenu
                  onClick={() => history.push('/partner')}
                  label={t('sidebar.partner')}
                  iconMUI={<GroupWorkIcon style={{ color: 'white', fontSize: 26 }} />}
                  divider
                />
              )}
              {user?.manager && (
                <>
                  <ItemMenu
                    onClick={() => history.push('/gymMetrics')}
                    label={t('sidebar.gymMetrics')}
                    iconMUI={<BarChart style={{ color: 'white', fontSize: 26 }} />}
                    divider
                  />
                  <ItemMenu
                    onClick={() => history.push('/gymsOnboarding')}
                    label={t('sidebar.gymOnboarding')}
                    iconMUI={<AssignmentTurnedIn style={{ color: 'white', fontSize: 26 }} />}
                    divider
                  />
                </>
              )}
              <ItemMenu
                onClick={() => history.push('/profile/edit')}
                label={t('sidebar.editProfile')}
                icon="icon-user"
                divider
              />
            </>
          )}

          {isAdmin && (
            <Box my={3}>
              <>
                <ItemMenu
                  onClick={() => setAdminCollapse(!adminCollapse)}
                  label={t('sidebar.admin')}
                  iconMUI={<List style={{ color: 'white', fontSize: 26 }} />}
                  divider
                  my={0}
                />

                <Box px={5}>
                  <Collapse in={adminCollapse}>
                    <ItemMenu
                      onClick={() => history.push('/admin/channels')}
                      active={layoutContext.activeMenu === 'adminChannels'}
                      label={t('sidebar.adminChannels')}
                      divider
                    />
                    <ItemMenu
                      onClick={() => history.push('/admin/textService')}
                      active={layoutContext.activeMenu === 'adminTextService'}
                      label={t('sidebar.adminTextService')}
                      divider
                    />
                    <ItemMenu
                      onClick={() => history.push('/admin/benchmarks')}
                      active={layoutContext.activeMenu === 'adminBenchmarks'}
                      label={t('sidebar.adminBenchmarks')}
                      divider
                    />
                    <ItemMenu
                      onClick={() => history.push('/admin/personalRecords')}
                      active={layoutContext.activeMenu === 'adminPersonalRecords'}
                      label={t('sidebar.adminPersonalRecords')}
                      divider
                    />
                    <ItemMenu
                      onClick={() => history.push('/admin/plans')}
                      active={layoutContext.activeMenu === 'adminPlans'}
                      label={t('sidebar.adminPlans')}
                      divider
                    />
                    <ItemMenu
                      onClick={() => history.push('/admin/reports')}
                      active={layoutContext.activeMenu === 'adminReports'}
                      label={t('sidebar.adminReports')}
                      divider
                    />
                    <ItemMenu
                      onClick={() => history.push('/admin/import/tool')}
                      active={layoutContext.activeMenu === 'adminImport'}
                      label={t('sidebar.adminImport')}
                      divider
                    />
                  </Collapse>
                </Box>
              </>
            </Box>
          )}

          {isSupport && (
            <ItemMenu
              iconMUI={<Storage style={{ color: 'white', fontSize: 26 }} />}
              onClick={() => history.push('/admin/reports')}
              active={layoutContext.activeMenu === 'adminReports'}
              label={t('sidebar.logReports')}
              divider
            />
          )}

          {isAuthenticated ? (
            <ItemMenu
              onClick={() => logout()}
              label={t('appbar.logout')}
              iconMUI={<ExitToAppIcon style={{ color: 'white', fontSize: 26 }} />}
            />
          ) : (
            <ItemMenu onClick={() => login()} label={t('appbar.signInRegister')} icon="icon-user" />
          )}
        </Box>
      </Box>
    </div>
  );
};

export default SideBar;
