import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Typography, { fontStyle } from 'core/ui/Typography';
import Box from 'core/ui/Box';
import { useSelector } from 'react-redux';
import { Link, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import LoaderSm from 'core/ui/LoaderSm';
import { Payment, Warning } from '@material-ui/icons';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import { secondary } from 'core/ui/Colors';
import Scrollbars from 'react-custom-scrollbars';
import { date, dateExt } from 'core/utils/formatters';
import { sessionSelectors } from 'modules/session';
import { integrationTypes } from 'core/utils/consts';
import useSelectors from 'modules/map/useSelectors';
import useActions from 'modules/map/useActions';
import { channelsSelectors } from 'modules/channels';
import Modules from 'modules';
import { userPaymentMethodsModule } from 'modules/userPaymentMethods';

const ManageList = ({ userId, channelId }) => {
  const { t } = useTranslation();
  const { request } = useActions();
  const currentUser = useSelector((state) => sessionSelectors.getUser(state));
  const loading = useSelectors(userPaymentMethodsModule, 'loading');
  const methods = useSelectors(userPaymentMethodsModule, 'data');

  const channel = useSelector((state) => channelsSelectors.getChannel(state));

  const getPaymentMethods = () => {
    request({
      action: Modules.userPaymentMethods.actions.getUserPaymentMethods,
      data: {
        userId,
        channelId: channelId || channel?.id,
      },
    });
  };

  useEffect(() => {
    if (userId) {
      getPaymentMethods();
    }
  }, [userId, channelId, channel]); // eslint-disable-line

  const remove = (id) => {
    request({
      action: Modules.userPaymentMethods.actions.inactive,
      data: {
        id,
        userId,
        channelId: channel?.id,
      },
    });
  };

  const setDefault = (id) => {
    request({
      action: Modules.userPaymentMethods.actions.setDefault,
      data: {
        id,
        userId,
        channelId: channel?.id,
      },
    });
  };

  const isOwner = (item) => userId === item?.userId;

  return (
    <Box pb={1} display="flex" flexDirection="column" justifyContent="center">
      <Scrollbars autoHeight autoHeightMax={150}>
        <Table size="small">
          <TableBody>
            {methods.map((ac) => (
              <TableRow key={ac.id}>
                <TableCell colSpan={ac.method === 'card' ? 1 : 2}>
                  <Box display="flex" alignItems="center">
                    <img
                      alt=""
                      src={integrationTypes.find((x) => x.id === ac.gateway)?.img}
                      style={{ width: 14, height: 14, borderRadius: 10, marginRight: 5 }}
                    />
                    {ac.method === 'card' ? (
                      <Payment style={{ color: secondary }} />
                    ) : (
                      <AccountBalanceIcon style={{ color: secondary }} />
                    )}
                    <Typography
                      ml={1}
                      variant="body2"
                      className="roboto-regular"
                      color="textSecondary"
                    >
                      {ac.method === 'card' && (
                        <span style={{ textTransform: 'capitalize' }}>
                          <b>{ac.cardFlag}</b>&nbsp;
                        </span>
                      )}
                      {t('userPaymentMethod.endingIn')} {ac.lastFour}
                    </Typography>
                    {ac.stepVerificationUrl && (
                      <Box ml={3} display="flex" alignItems="center">
                        <Warning style={{ fontSize: 16 }} />
                        <Link style={fontStyle.small} href={ac.stepVerificationUrl} target="_blank">
                          {t('payment.method.verificationStep')}
                        </Link>
                      </Box>
                    )}
                  </Box>
                </TableCell>
                {ac.method === 'card' && (
                  <TableCell>
                    <Typography variant="body2" className="roboto-regular" color="textSecondary">
                      {date(ac.expiryAt, 'yyyy/MM')}
                    </Typography>
                  </TableCell>
                )}
                <TableCell>
                  {!currentUser?.hasUserHousehold && isOwner(ac) && !ac.default && (
                    <Typography
                      onClick={() => setDefault(ac.id)}
                      color="primary"
                      className="link-hover"
                      style={fontStyle.small}
                    >
                      {t('button.setDefault')}
                    </Typography>
                  )}
                </TableCell>
                <TableCell align="center">
                  {isOwner(ac) && (
                    <Typography
                      style={fontStyle.small}
                      onClick={() => remove(ac.id)}
                      color="primary"
                      className="link-hover"
                    >
                      {t('button.remove')}
                    </Typography>
                  )}

                  <Typography style={fontStyle.xSmall} mt={2} className="roboto-regular">
                    <b>{t('label.created')}</b>:&nbsp;
                    {dateExt(ac.createdAt)}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Scrollbars>
      <Box display="flex" justifyContent="center">
        <LoaderSm loading={loading} />
      </Box>
    </Box>
  );
};

export default ManageList;
