import React from 'react';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import Typography from 'core/ui/Typography';
import { ordinalNumber } from 'core/utils/formatters/date';
import Divider from 'core/ui/Divider';
import LabelTextField from 'core/ui/LabelTextField';

const FormTemplateScores = ({ positions, scores, setScores }) => {
  const { t } = useTranslation();

  const changeScore = (index, v) => {
    const arr = [...scores];

    arr[index] = v;

    setScores(arr);
  };

  return (
    <Box className="tw-flex tw-flex-col tw-gap-2 tw-rounded-sm tw-border tw-border-solid tw-border-gray-200 tw-bg-white tw-p-2">
      {[...new Array(Number(positions))].map((item, index) => (
        <div key={index}>
          <div className="tw-flex tw-flex-row tw-items-center tw-gap-2">
            <div className="tw-flex tw-w-10 tw-items-center tw-justify-center">
              <Typography className="tw-font-bold">{ordinalNumber(index + 1)}</Typography>
            </div>
            <Divider orientation="vertical" mx={2} flexItem />
            <div className="tw-flex tw-grow tw-flex-row">
              <LabelTextField
                label={t(`label.score`)}
                placeholder={t(`label.score`)}
                value={scores[index] || ''}
                onChange={(v) => changeScore(index, v)}
                type="number"
                pattern="[0-9]*"
              />
            </div>
          </div>

          {Number(positions) - 1 > index && <Divider my={2} />}
        </div>
      ))}
    </Box>
  );
};

export default FormTemplateScores;
