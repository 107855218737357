export default [
  {
    flag: 'US',
    label: ' English',
    language: 'en',
    defaultCountry: 'US',
  },
  {
    flag: 'BR',
    label: ' Portuguese',
    language: 'pt-br',
    defaultCountry: 'BR',
  },
  {
    flag: 'ES',
    label: ' Spanish',
    language: 'es',
    defaultCountry: 'ES',
  },
];
