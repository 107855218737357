import { put, call, all } from 'redux-saga/effects';

import { persistData } from 'core/utils/session-storage';
import { getErrorMessage } from 'core/api/api-error';
import notifications from 'modules/notifications';
import * as api from './api';
import { languageActions as actions } from './index';

export function* setLanguage({ language }) {
  try {
    persistData('currentLanguage', JSON.stringify(language));
    yield put(actions.setLanguageSuccess(language));
  } catch (error) {
    yield put(actions.setLanguageError());
  }
}

export function* getLanguages() {
  try {
    const response = yield call(api.languages);
    const languages = response.data;
    yield put(actions.getLanguagesSuccess(languages));
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.getLanguagesError(getErrorMessage(error))),
    ]);
  }
}
