import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

import * as selectors from './selectors';
import * as sagas from './sagas';

/* ---------- Action Creators and Types ---------- */
const { Types, Creators } = createActions(
  {
    getSearchError: ['error'],
    getSearchStart: ['data'],
    getSearchSuccess: ['data'],
    getSearchWorkoutSuccess: ['data'],

    getSearchUsersError: ['error'],
    getSearchUsersStart: ['data', 'callback'],
    getSearchUsersSuccess: ['data'],

    getSearchChannelMembersError: ['error'],
    getSearchChannelMembersStart: ['data'],
    getSearchChannelMembersSuccess: ['data'],

    getSearchAutocompleteError: ['error'],
    getSearchAutocompleteStart: ['data'],
    getSearchAutocompleteSuccess: ['data'],

    getFeaturedError: ['error'],
    getFeaturedStart: ['data'],
    getFeaturedSuccess: ['data'],

    getFeaturedUpcomingError: ['error'],
    getFeaturedUpcomingStart: ['data'],
    getFeaturedUpcomingSuccess: ['data'],

    getFeaturedRegisteredError: ['error'],
    getFeaturedRegisteredStart: ['data'],
    getFeaturedRegisteredSuccess: ['data'],

    getViewMoreError: ['error'],
    getViewMoreStart: ['data'],
    getViewMoreSuccess: ['data'],

    setQueryData: ['data'],
    setFilters: ['data'],

    clearViewMoreResults: [],
    clearSearchResults: [],
    clearSearchUsers: [],
    clearSearchAutocomplete: [],
  },
  { prefix: 'search/' },
);

/* ---------- Initial State ---------- */
const INITIAL_STATE = Immutable({
  data: null,
  users: {},
  channelMembers: [],
  dataAutocomplete: [],
  dataInterests: [],
  dataFeatured: [],
  queryData: '',
  filters: [],
  error: null,
  loading: false,
  loadingUsers: false,
  loadingAutoComplete: false,
  loadingInterests: false,
  loadingFeatured: false,
  loadingFeaturedUpcoming: false,
  loadingFeaturedRegistered: false,
  workoutResults: null,
  loadingViewMore: false,
  viewMoreData: [],
  viewMorePagination: {},
});

/* ---------- Reducers ---------- */
const loadStartReducer = (state) => state.set('loading', true);
const loadUsersStartReducer = (state) => state.set('loadingUsers', true);
const loadFeaturedStartReducer = (state) => state.set('loadingFeatured', true);
const loadFeaturedUpcomingStartReducer = (state) => state.set('loadingFeaturedUpcoming', true);
const loadFeaturedRegisteredStartReducer = (state) => state.set('loadingFeaturedRegistered', true);
const loadAutocompleteStartReducer = (state) => state.set('loadingAutoComplete', true);

const loadViewMoreStartReducer = (state) => state.set('loadingViewMore', true);

const getSearchAutocompleteSuccessReducer = (state, action) =>
  state.merge({
    dataAutocomplete: action.data,
    loading: false,
    error: null,
  });

const getFeaturedSuccessReducer = (state) =>
  state.merge({
    loadingFeatured: false,
    error: null,
  });

const getFeaturedUpcomingSuccessReducer = (state) =>
  state.merge({
    loadingFeaturedUpcoming: false,
    error: null,
  });

const getFeaturedRegisteredSuccessReducer = (state) =>
  state.merge({
    loadingFeaturedRegistered: false,
    error: null,
  });

const getViewMoreSuccessReducer = (state, action) => {
  const keys = Object.keys(action.data);
  let data = [];
  let pagination = {};
  keys.forEach((key) => {
    data = [...data, ...action.data[key].data];
    pagination = action.data[key].pagination;
  });
  return state.merge({
    viewMoreData: data,
    viewMorePagination: pagination,
    loadingViewMore: false,
    error: null,
  });
};

const getSearchSuccessReducer = (state, action) => {
  const keys = Object.keys(action.data);
  let data = [];
  keys.forEach((key) => {
    data = [...data, ...action.data[key].data];
  });
  return state.merge({
    data,
    loading: false,
    error: null,
  });
};

const getSearchUsersSuccessReducer = (state, action) =>
  state.merge({
    users: action.data.user,
    loadingUsers: false,
    error: null,
  });

const getSearchChannelUsersSuccessReducer = (state, action) =>
  state.merge({
    channelMembers: action.data,
    loadingUsers: false,
    error: null,
  });

const getSearchWorkoutSuccessReducer = (state, action) => {
  const data = {
    itemsRecents: action?.data?.workoutRecents?.data || [],
    paginationRecents: action?.data?.workoutRecents?.pagination || {},
    itemsUpcoming: action?.data?.workoutUpcoming?.data || [],
    paginationUpcoming: action?.data?.workoutUpcoming?.pagination || {},
  };
  return state.merge({
    workoutResults: data,
    loading: false,
    error: null,
  });
};

const loadErrorReducer = (state, action) =>
  state.merge({
    loading: false,
    error: action.error,
  });

const loadUsersErrorReducer = (state, action) =>
  state.merge({
    loadingUsers: false,
    error: action.error,
  });

const loadAutocompleteErrorReducer = (state, action) =>
  state.merge({
    loadingAutocomplete: false,
    error: action.error,
  });

const loadFeaturedErrorReducer = (state, action) =>
  state.merge({
    loadingFeatured: false,
    loadingFeaturedUpcoming: false,
    error: action.error,
  });

const loadViewMoreErrorReducer = (state, action) =>
  state.merge({
    loadingViewMore: false,
    error: action.error,
  });

const clearSearchResultsReducer = (state) =>
  state.merge({
    data: null,
    workoutResults: null,
  });

const clearAutocompleteReducer = (state) =>
  state.merge({
    dataAutocomplete: [],
  });

const clearSearchUsersReducer = (state) =>
  state.merge({
    users: {},
    channelMembers: [],
  });

const clearViewMoreResultsReducer = (state) =>
  state.merge({
    viewMoreData: [],
    viewMorePagination: {},
  });

const setFiltersReducer = (state, action) =>
  state.merge({
    filters: [...action.data],
  });

const setQueryDataReducer = (state, action) =>
  state.merge({
    queryData: action.data,
  });

/* ---------- Main Reducer ---------- */
const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_SEARCH_AUTOCOMPLETE_START]: loadAutocompleteStartReducer,
  [Types.GET_SEARCH_AUTOCOMPLETE_SUCCESS]: getSearchAutocompleteSuccessReducer,
  [Types.GET_SEARCH_AUTOCOMPLETE_ERROR]: loadAutocompleteErrorReducer,

  [Types.GET_FEATURED_START]: loadFeaturedStartReducer,
  [Types.GET_FEATURED_SUCCESS]: getFeaturedSuccessReducer,
  [Types.GET_FEATURED_ERROR]: loadFeaturedErrorReducer,

  [Types.GET_FEATURED_UPCOMING_START]: loadFeaturedUpcomingStartReducer,
  [Types.GET_FEATURED_UPCOMING_SUCCESS]: getFeaturedUpcomingSuccessReducer,
  [Types.GET_FEATURED_UPCOMING_ERROR]: loadFeaturedErrorReducer,

  [Types.GET_FEATURED_REGISTERED_START]: loadFeaturedRegisteredStartReducer,
  [Types.GET_FEATURED_REGISTERED_SUCCESS]: getFeaturedRegisteredSuccessReducer,
  [Types.GET_FEATURED_REGISTERED_ERROR]: loadFeaturedErrorReducer,

  [Types.GET_VIEW_MORE_START]: loadViewMoreStartReducer,
  [Types.GET_VIEW_MORE_SUCCESS]: getViewMoreSuccessReducer,
  [Types.GET_VIEW_MORE_ERROR]: loadViewMoreErrorReducer,

  [Types.GET_SEARCH_START]: loadStartReducer,
  [Types.GET_SEARCH_SUCCESS]: getSearchSuccessReducer,
  [Types.GET_SEARCH_ERROR]: loadErrorReducer,

  [Types.GET_SEARCH_USERS_START]: loadUsersStartReducer,
  [Types.GET_SEARCH_USERS_SUCCESS]: getSearchUsersSuccessReducer,
  [Types.GET_SEARCH_USERS_ERROR]: loadUsersErrorReducer,

  [Types.GET_SEARCH_CHANNEL_MEMBERS_START]: loadUsersStartReducer,
  [Types.GET_SEARCH_CHANNEL_MEMBERS_SUCCESS]: getSearchChannelUsersSuccessReducer,
  [Types.GET_SEARCH_CHANNEL_MEMBERS_ERROR]: loadUsersErrorReducer,

  [Types.GET_SEARCH_WORKOUT_SUCCESS]: getSearchWorkoutSuccessReducer,

  [Types.SET_QUERY_DATA]: setQueryDataReducer,
  [Types.SET_FILTERS]: setFiltersReducer,

  [Types.CLEAR_SEARCH_RESULTS]: clearSearchResultsReducer,
  [Types.CLEAR_SEARCH_USERS]: clearSearchUsersReducer,
  [Types.CLEAR_VIEW_MORE_RESULTS]: clearViewMoreResultsReducer,
  [Types.CLEAR_SEARCH_AUTOCOMPLETE]: clearAutocompleteReducer,
});

/* ---------- Exporting ---------- */
export const searchActions = Creators;
export const searchTypes = Types;
export const searchSelectors = selectors;
export const searchSagas = sagas;

export default reducer;
