/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import './SpeakerVideoStyle.css';
import Box from 'core/ui/Box';
import { useSelector } from 'react-redux';
import { roomSelectors } from 'modules/room';
import ImageMedia from 'components/Profile/ImageMedia';

const SpeakerVideo = ({ user, style, width, height, resolution, full }) => {
  const videoRef = useRef();
  const config = useSelector((state) => roomSelectors.getConfig(state));

  const boxImage = {
    position: 'absolute',
    zIndex: 1000,
    top: 0,
    height: '100%',
    width: '100%',
  };

  useEffect(() => {
    if (user.screen && user.shareScreen) {
      if (user.streamManagerScreen && videoRef.current) {
        user.streamManagerScreen.addVideoElement(videoRef.current);
      }
      return;
    }

    if (user.streamManager && videoRef.current) {
      user.streamManager.addVideoElement(videoRef.current);
    }
  }, [user]);

  useEffect(() => {
    if (config.deviceSpeaker && videoRef.current && videoRef.current.setSinkId) {
      videoRef.current.setSinkId(config.deviceSpeaker.deviceId);
    }
  }, [config.deviceSpeaker]);

  return (
    <Box
      className="container"
      style={{
        backgroundColor: '#000',
        borderRadius: 5,
        width: full === true ? '100%' : width || resolution.width,
        height: full === true ? '100%' : height || resolution.height,
      }}
    >
      {!user.videoActive && (
        <>
          <Box mb={1} style={{ ...style, position: 'relative', backgroundColor: '#000' }} />
          <Box style={boxImage} display="flex" justifyContent="center" alignItems="center">
            <Box
              style={{
                position: 'absolute',
                border: '10px solid rgba(0, 0, 0, 0.5)',
                borderRadius: 5,
                height: 50,
                width: 50,
                zIndex: 1000,
              }}
            />
            <ImageMedia showCountry={false} user={user} image={user.image || {}} size={60} />
          </Box>
        </>
      )}
      <video
        style={{
          ...style,
          boxShadow: '0 2px 2px rgba(0,0,0,0.10), 0 1px 1px rgba(0,0,0,0.10)',
          display: user.videoActive ? 'block' : 'none',
        }}
        ref={videoRef}
        className="speaker-video"
        autoPlay
        width="480"
      />
    </Box>
  );
};

SpeakerVideo.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.object,
  width: PropTypes.number,
  height: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  resolution: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  full: PropTypes.bool,
};

SpeakerVideo.defaultProps = {
  user: {},
  width: 340,
  height: 200,
  resolution: {},
  style: {},
  full: false,
};

export default SpeakerVideo;
