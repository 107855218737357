import React from 'react';
import Typography from 'core/ui/Typography';
import PropTypes from 'prop-types';
import Box from 'core/ui/Box';
import ImageMediaUser from 'components/Profile/ImageMedia';
import InstagramLink from 'components/Profile/InstagramLink';
import { secondary } from 'core/ui/Colors';

import { useTranslation } from 'react-i18next';
import { ButtonPrimary } from 'core/ui/Button';
import { useSelector } from 'react-redux';
import { sessionSelectors } from 'modules/session';

const CardWaitList = ({ isCoach, athlete, onClick, removeFromWaitList }) => {
  const currentUser = useSelector((state) => sessionSelectors.getUser(state));
  const { t } = useTranslation();
  return (
    <Box display="flex" flex={1} alignItems="center">
      <Box flexGrow="1">
        <Box display="flex" alignItems="flex-center" mt={1}>
          <ImageMediaUser
            user={athlete}
            image={athlete.image ? athlete.image : {}}
            size={45}
            sizeCountry="1em"
            heightCountry={18}
          />
          <Box px={2} flexGrow={1}>
            <Typography color="primary" variant="caption" component="p">
              {athlete.name || athlete.phone || athlete.email}
            </Typography>
            <InstagramLink instagram={athlete.instagram} clickable={false} />
          </Box>
        </Box>
      </Box>
      <Box display="flex" alignItems="flex-center">
        {isCoach && (
          <ButtonPrimary
            style={{ backgroundColor: secondary, padding: 3, color: 'white' }}
            aria-label="settings"
            onClick={() => onClick(athlete)}
          >
            {t('button.register')}
          </ButtonPrimary>
        )}
        {(isCoach || athlete?.id === currentUser?.id) && (
          <ButtonPrimary
            style={{ marginLeft: 2, padding: 3, color: 'white' }}
            aria-label="settings"
            onClick={() => removeFromWaitList(athlete?.id)}
          >
            {t('button.remove')}
          </ButtonPrimary>
        )}
      </Box>
    </Box>
  );
};

CardWaitList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  isCoach: PropTypes.bool,
};

CardWaitList.defaultProps = {
  isCoach: false,
};

export default CardWaitList;
