import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';
import Typography from 'core/ui/Typography';
import Box from 'core/ui/Box';
import InstagramLink from 'components/Profile/InstagramLink';
import Paper from 'core/ui/Paper';
import CollapseText from 'core/ui/CollapseText';
import { ShowContext } from './index';

export default () => {
  const { t } = useTranslation();

  const showContext = useContext(ShowContext);

  const buttonColor = showContext?.channel?.customColors?.buttonColor;

  return (
    <Paper elevation={2} className="paper-rounded" p={3}>
      <Box>
        <Box display="flex">
          <Box flexGrow="1">
            <Typography color="secondary" variant="h4" component="h4">
              {showContext.channel.name}
            </Typography>
            <InstagramLink instagram={showContext.channel.instagram} />
          </Box>
        </Box>

        <Box display="flex" flexWrap="wrap" flexDirection="column">
          <Typography color="secondary" variant="subtitle2" component="h1">
            {t('description')}
          </Typography>

          <CollapseText
            text={showContext.channel.description}
            maxChar={300}
            linkColor={buttonColor}
          />
        </Box>

        {showContext.channel.categories && showContext.channel.categories.length > 0 && (
          <Box display="flex" flexDirection="column">
            <Typography color="secondary" variant="subtitle2" component="h1" display="block">
              {t('categories')}
            </Typography>

            <Typography
              className="roboto-regular"
              color="secondary"
              variant="caption"
              component="p"
              align="justify"
            >
              {showContext.channel.categories.map((tag) => tag.name).join(', ')}
            </Typography>
          </Box>
        )}

        {showContext.channel.languages && showContext.channel.languages.length > 0 && (
          <Box display="flex" flexDirection="column">
            <Typography color="secondary" variant="subtitle2" component="h1">
              {t('channel.label.languages')}
            </Typography>

            <Typography
              className="roboto-regular"
              color="secondary"
              variant="caption"
              component="p"
              align="justify"
            >
              {showContext.channel.languages &&
                showContext.channel.languages.map((tag) => tag.name).join(', ')}
            </Typography>
          </Box>
        )}
      </Box>
    </Paper>
  );
};
