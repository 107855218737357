import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import DatePickerRounded from 'core/ui/DatePickers/DatePickerRounded';
import { ButtonPrimarySm } from 'core/ui/Button';
import { Search } from '@material-ui/icons';
import { formatDateToApi } from 'core/utils/formatters';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import { sessionSelectors } from 'modules/session';
import useSelectors from 'modules/map/useSelectors';
import { adminChannelsModule } from 'modules/admin/channels';
import { useSelector } from 'react-redux';
import TablePendingMemberships from './Table';

const AdminPaymentsReport = () => {
  const { t } = useTranslation();
  const { request } = useActions();
  const isAdmin = useSelector((state) => sessionSelectors.getIsAdmin(state));
  const loadingPendingMemberships = useSelectors(adminChannelsModule, 'loadingPendingMemberships');

  const dateNow = new Date();
  const [filters, setFilters] = useState({
    expiryDate: dateNow,
  });

  const getPendingMemberships = (expiryDate) => {
    request({
      action: Modules.adminChannels.actions.getPendingMemberships,
      data: {
        expiryDate,
      },
    });
  };

  const search = () => {
    const expiryDate = filters.expiryDate ? formatDateToApi(filters.expiryDate) : null;
    if (expiryDate) getPendingMemberships({ expiryDate });
  };

  useEffect(() => {
    if (isAdmin) {
      search();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdmin]);

  return (
    <Box>
      <Box display="flex" mb={3} alignItems="flex-end" justifyContent="center" flexWrap="wrap">
        <Box ml={1}>
          <DatePickerRounded
            label="Expiry Date"
            onChange={(v) => setFilters((prev) => ({ ...prev, expiryDate: v }))}
            value={filters.expiryDate}
            disablePast={false}
            autoOk
          />
        </Box>
        <Box ml={3}>
          <ButtonPrimarySm disabled={loadingPendingMemberships} onClick={() => search()}>
            <Search style={{ color: 'white' }} fontSize="small" />
            {t('button.view')}&nbsp;
          </ButtonPrimarySm>
        </Box>
      </Box>

      <TablePendingMemberships refresh={search} loading={loadingPendingMemberships} />
    </Box>
  );
};

export default AdminPaymentsReport;
