/* eslint-disable react/prop-types */
import React from 'react';
import { Legend, PieChart, Pie, Cell } from 'recharts';

const PieCoachesChart = ({ data }) => {
  function randomColor() {
    const hex = Math.floor(Math.random() * 0xffffff);
    const color = `#${hex.toString(16)}`;

    return color;
  }
  return (
    <PieChart width={400} height={300}>
      <Pie
        data={data?.map((p) => ({
          name: p.coachName,
          value: p?.amount,
          color: 'red',
        }))}
        dataKey="value"
        nameKey="name"
      >
        {data?.map((entry, index) => (
          <Cell key={`cell-${index.toString()}`} fill={entry?.coachColor || randomColor()} />
        ))}
      </Pie>
      <Legend />
    </PieChart>
  );
};

export default PieCoachesChart;
