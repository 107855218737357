import api from 'core/api';

export const competitionsCalendarsModule = 'competitionsCalendars';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const actions = {
  postCompetitionsCalendars: {
    module: competitionsCalendarsModule,
    name: 'postCompetitionsCalendars',
    api: (data) => api.post(`/competitions/${data?.competitionId}/competitions_calendars`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  putCompetitionsCalendars: {
    module: competitionsCalendarsModule,
    name: 'putCompetitionsCalendars',
    api: (data) =>
      api.put(`/competitions/${data?.competitionId}/competitions_calendars/${data?.id}`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  destroy: {
    module: competitionsCalendarsModule,
    name: 'destroy',
    api: (data) =>
      api.delete(`/competitions/${data?.competitionId}/competitions_calendars/${data?.id}`),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  getCompetitionsCalendars: {
    module: competitionsCalendarsModule,
    name: 'getCompetitionsCalendars',
    api: (params) =>
      api.get(`/competitions/${params?.competitionId}/competitions_calendars`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: ['data'],
    },
    state: loadingStates,
  },
};

export default {
  actions,
  state: {
    data: [],
    error: null,
    loading: false,
  },
};
