import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography, { fontStyle } from 'core/ui/Typography';
import PropTypes from 'prop-types';
import Box from 'core/ui/Box';
import { IconButton, Menu, withStyles, MenuItem, ListItemText } from '@material-ui/core';
import FontIcon from 'core/ui/FontIcon';
import ImageMediaUser from 'components/Profile/ImageMedia';
import InstagramLink from 'components/Profile/InstagramLink';
import { useTranslation } from 'react-i18next';
import { primary } from 'core/ui/Colors';
import { channelAccessTypes } from 'core/utils/consts';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';

const StyledMenu = withStyles({
  paper: {
    // border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

export default function CardCoach({ coach, remove, accessType, onChangeType, buttonColor }) {
  const useStyles = makeStyles({
    root: {
      backgroundColor: 'transparent',
      maxWidth: 170,
    },
    boxFlag: {
      position: 'absolute',
      marginTop: -42,
      width: 55,
      height: 42,
      overflow: 'hidden',
      borderBottomLeftRadius: 10,
    },
    boxRemove: {
      position: 'absolute',
      backgroundColor: '#fff',
      padding: 0.5,
      overflow: 'hidden',
      borderRadius: 50,
      zIndex: 10,
    },
    boxType: {
      position: 'absolute',
      backgroundColor: buttonColor || primary,
      padding: 2,
      top: 148,
      right: 0,
      overflow: 'hidden',
      borderBottomRightRadius: 10,
      zIndex: 10,
    },
  });
  const classes = useStyles();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!coach) {
    return <></>;
  }

  return (
    <>
      <Box className={`${classes.root} link`}>
        <Box style={{ position: 'relative' }}>
          {remove && (
            <Box style={{ zIndex: 9999 }} display="flex" justifyContent="flex-end">
              <Box className={classes.boxRemove}>
                <IconButton color="secondary" p={0} m={0} onClick={() => remove()}>
                  <FontIcon iconName="icon-close" size={10} />
                </IconButton>
              </Box>
            </Box>
          )}

          <Box
            display="flex"
            alignItems="center"
            className={classes.boxType}
            onClick={onChangeType ? handleClickMenu : null}
          >
            {accessType && (
              <Typography style={{ color: 'white', ...fontStyle.medium }}>
                {t(`channel.access.type.${accessType}`)}
              </Typography>
            )}
            {onChangeType && (
              <>
                {!anchorEl ? (
                  <KeyboardArrowDown style={{ fontSize: 15, color: 'white' }} />
                ) : (
                  <KeyboardArrowUp style={{ fontSize: 15, color: 'white' }} />
                )}
              </>
            )}
          </Box>

          <Box>
            <ImageMediaUser user={coach} image={coach?.image ? coach?.image : {}} />
            <Box>
              <Typography color="secondary" variant="subtitle2">
                {coach.name}
              </Typography>
              <InstagramLink instagram={coach.instagram} />
            </Box>
          </Box>
        </Box>
      </Box>

      <StyledMenu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {channelAccessTypes.map((type, index) => (
          <MenuItem key={index.toString()} selected={type.value === accessType}>
            <ListItemText
              onClick={() => {
                handleClose();
                onChangeType(coach.id, type.value);
              }}
              primary={type.label}
              // secondary={type.value === accessType ? 'selected' : ''}
            />
          </MenuItem>
        ))}
      </StyledMenu>
    </>
  );
}

CardCoach.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  coach: PropTypes.object,
  remove: PropTypes.func,
  onChangeType: PropTypes.func,
  accessType: PropTypes.string.isRequired,
};

CardCoach.defaultProps = {
  coach: {},
  remove: null,
  onChangeType: null,
};
