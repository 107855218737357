import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Texture from 'core/ui/Texture';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import Dashboard from 'components/Profile/Dashboard/index';
import { sessionSelectors } from 'modules/session';
import { retrieveData } from 'core/utils/session-storage';
import ModalNotifyPlanChannel from 'components/Profile/Coach/ModalNotifyPlanChannel';
import { Container } from '@material-ui/core';
import Box from 'core/ui/Box';
import useDeepLinks, { AppScreens } from 'core/useDeepLinks';
import { channelsActions } from 'modules/channels';
import { LayoutContext } from '../Layout';
import SkeletonDashboard from './Skeleton';

export default ({ defaultTab }) => {
  const { t } = useTranslation();

  const history = useHistory();
  const [openAlert, setOpenAlert] = useState(false);
  const user = useSelector((state) => sessionSelectors.getUser(state));
  const { tab } = useParams();
  const layoutContext = useContext(LayoutContext);
  const dispatch = useDispatch();
  const deep = useDeepLinks();

  useEffect(() => {
    deep.openInApp({ screen: AppScreens.Dashboard, param: tab });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    layoutContext.setTitleBar(t('title.dashboard').toUpperCase());
    layoutContext.setActiveMenu('dashboard');
  }, [layoutContext, t]);

  useEffect(() => {
    const key = retrieveData('activeCoachKey');
    const channelId = retrieveData('activeCoachChannel');
    if (key !== null) {
      history.push(`/channel/${channelId}/coach/active/${key}`);
      return;
    }
    if (user.id && !user.name) {
      history.push('/profile/edit');
    }
  }, [user, history]);

  useEffect(() => {
    if (user.notifyChannelPlan) {
      setOpenAlert(true);
    }
  }, [user.notifyChannelPlan]);

  useEffect(() => {
    if (user?.channel?.id) {
      dispatch(channelsActions.getChannelStart(user?.channel?.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.channel?.id]);

  if (!(user && user.id)) {
    return (
      <Container maxWidth="xl">
        <Box mt={3}>
          <SkeletonDashboard />
        </Box>
      </Container>
    );
  }

  return (
    <Texture fullHeight>
      {openAlert && (
        <ModalNotifyPlanChannel setOpen={setOpenAlert} channel={user.notifyChannelPlan} />
      )}
      <Dashboard defaultTab={defaultTab || tab} />
    </Texture>
  );
};
