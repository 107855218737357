import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form-v5';

import { useDispatch, useSelector } from 'react-redux';
import { channelsSelectors } from 'modules/channels';
import notifications from 'modules/notifications';
import Modules from 'modules';
import useActions from 'modules/map/useActions';
import useSelectors from 'modules/map/useSelectors';
import { storeDiscountCodesModule } from 'modules/storeDiscountCodes';

export default ({ mode, item, callback, ownerableId, ownerableType }) => {
  const { t } = useTranslation();
  const { request } = useActions();
  const dispatch = useDispatch();
  const channel = useSelector((state) => channelsSelectors.getChannel(state));
  const loading = useSelectors(storeDiscountCodesModule, 'loading');
  const { register, handleSubmit, setValue, watch, reset, errors } = useForm();

  const onSubmit = (values) => {
    const data = {
      ...values,
      // eslint-disable-next-line no-nested-ternary
      channelId: channel.id,
    };

    if (mode === 'new') {
      request({
        action: Modules.storeDiscountCodes.actions.postStoreDiscountCodes,
        data: {
          ownerableId,
          ownerableType,
          ...data,
        },
        options: {
          onSuccess: () => {
            if (callback) {
              callback(values.name);
            }
            request({
              action: Modules.storeDiscountCodes.actions.getStoreDiscountCodes,
              data: {
                ownerableId,
                ownerableType,
              },
            });
            dispatch(notifications.success(t('message.success.save.storeDiscountCodes')));
          },
        },
      });
    } else {
      request({
        action: Modules.storeDiscountCodes.actions.putStoreDiscountCodes,
        data: {
          id: item.id,
          ownerableId,
          ownerableType,
          ...data,
        },
        options: {
          onSuccess: () => {
            if (callback) {
              callback(values.name);
            }
            request({
              action: Modules.storeDiscountCodes.actions.getStoreDiscountCodes,
              data: {
                ownerableId,
                ownerableType,
              },
            });
            dispatch(notifications.success(t('message.success.save.storeDiscountCodes')));
          },
        },
      });
    }
  };

  useEffect(() => {
    register('code', { required: 'Code is required' });
    register('amount', { required: 'Amount is required' });
    register('kind', { required: 'Type is required' });
    register('limitUse', { required: false });
    register('active', { required: false });
    register('applyAllProducts', { required: false });

    if (mode === 'new') {
      setValue('kind', 'money');
      setValue('active', true);
      setValue('applyAllProducts', false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register]);

  useEffect(() => {
    setTimeout(() => {
      reset(item);
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    register,
    setValue,
    watch,
    reset,
    errors,
    onSubmit,
    handleSubmit,
    loading,
  };
};
