import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import { ButtonPrimary } from 'core/ui/Button';
import LoaderSm from 'core/ui/LoaderSm';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import { typeDistance, typeWeight } from 'core/utils/consts';
import TimeSelector from 'core/ui/TimeSelector';
import Typography from 'core/ui/Typography';
import DatePickerRounded from 'core/ui/DatePickers/DatePickerRounded';
import { hasBenchmarkScore } from 'core/utils/helpers';
import Radio from 'core/ui/Radio';
import { secondaryLight } from 'core/ui/Colors';
import { makeStyles } from '@material-ui/core';
import Checkbox from 'core/ui/Checkbox';
import { sessionSelectors } from 'modules/session';
import { useSelector } from 'react-redux';
import useForm from './useForm';

const useStyles = makeStyles(() => ({
  boxScore: {
    border: `0.5px solid ${secondaryLight}`,
    borderRadius: 10,
    padding: 10,
    margin: 1,
  },
}));

const FormHistory = ({ selectedBenchmark, callback, mode }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const currentUser = useSelector((state) => sessionSelectors.getUser(state));

  const defaultWeightType = typeWeight.find(
    (x) => x.id === (currentUser?.defaultWeightImperial ? 'pounds' : 'kilograms'),
  );
  const defaultDistanceType = typeDistance.find(
    (x) => x.id === (currentUser?.defaultDistanceMiles ? 'miles' : 'kilometers'),
  );

  const { setValue, watch, handleSubmit, onSubmit, loading } = useForm({
    mode,
    selectedBenchmark,
    callback,
  });

  return (
    <>
      <Typography color="primary" variant="h4" align="center" my={5}>
        {selectedBenchmark.name}
      </Typography>

      <Box mb={3}>
        <DatePickerRounded
          disablePast={false}
          label={t('form.personalRecord.date')}
          placeholder={t('form.personalRecord.date')}
          value={watch('date')}
          onChange={(v) => setValue('date', v)}
        />
      </Box>

      <>
        <Box display="flex" justifyContent="center" my={3}>
          <Radio
            label={t('workout.section.label.score.prescribed')}
            onClick={() => setValue('variation', 'prescribed')}
            checked={watch('variation') === 'prescribed'}
          />
          <Box ml={3}>
            <Radio
              label={t('workout.section.label.score.scaled')}
              onClick={() => setValue('variation', 'scaled')}
              checked={watch('variation') === 'scaled'}
            />
          </Box>
        </Box>

        {hasBenchmarkScore(selectedBenchmark.scores, 'time') && (
          <>
            {selectedBenchmark.timeCapped && (
              <Box mb={3}>
                <Checkbox
                  label={t('workout.section.label.score.timeCapped')}
                  onClick={() => setValue('timeCapped', !watch('timeCapped'))}
                  checked={!!watch('timeCapped')}
                />
              </Box>
            )}
            {!watch('timeCapped') && (
              <Box className={classes.boxScore}>
                <Box display="flex">
                  <Box flexGrow={1}>
                    <TimeSelector
                      time={watch('time')}
                      setTime={(time) => setValue('time', time)}
                      label={t('workout.section.label.score.time')}
                    />
                  </Box>
                </Box>

                <Box mt={1}>
                  <TextFieldRounded
                    onChange={(v) => setValue('timeNotes', v)}
                    value={watch('timeNotes') || ''}
                    label={t('workout.section.label.notes')}
                  />
                </Box>
              </Box>
            )}
          </>
        )}
        {(!selectedBenchmark.timeCapped || !!watch('timeCapped')) && (
          <>
            {hasBenchmarkScore(selectedBenchmark.scores, 'rounds') && (
              <Box className={classes.boxScore}>
                <TextFieldRounded
                  onChange={(v) => setValue('rounds', v)}
                  value={watch('rounds') || ''}
                  label={t('workout.section.label.score.rounds')}
                />
                <Box mt={1}>
                  <TextFieldRounded
                    onChange={(v) => setValue('roundsNotes', v)}
                    value={watch('roundsNotes') || ''}
                    label={t('workout.section.label.notes')}
                  />
                </Box>
              </Box>
            )}
            {hasBenchmarkScore(selectedBenchmark.scores, 'roundReps') && (
              <Box className={classes.boxScore}>
                <Box display="flex" alignItems="flex-end">
                  <TextFieldRounded
                    onChange={(v) => setValue('roundRepsRounds', v)}
                    value={watch('roundRepsRounds') || ''}
                    label={t('workout.section.label.score.rounds')}
                  />
                  <Typography mx={1} mb={2}>
                    +
                  </Typography>
                  <TextFieldRounded
                    onChange={(v) => setValue('roundRepsReps', v)}
                    value={watch('roundRepsReps') || ''}
                    label={t('workout.section.label.score.reps')}
                  />
                </Box>
                <Box mt={1}>
                  <TextFieldRounded
                    onChange={(v) => setValue('roundRepsNotes', v)}
                    value={watch('roundRepsNotes') || ''}
                    label={t('workout.section.label.notes')}
                  />
                </Box>
              </Box>
            )}
            {hasBenchmarkScore(selectedBenchmark.scores, 'reps') && (
              <Box className={classes.boxScore}>
                <TextFieldRounded
                  onChange={(v) => setValue('reps', v)}
                  value={watch('reps') || ''}
                  label={t('workout.section.label.score.reps')}
                />

                <Box mt={1}>
                  <TextFieldRounded
                    onChange={(v) => setValue('repsNotes', v)}
                    value={watch('repsNotes') || ''}
                    label={t('workout.section.label.notes')}
                  />
                </Box>
              </Box>
            )}
            {hasBenchmarkScore(selectedBenchmark.scores, 'weight') && (
              <Box className={classes.boxScore}>
                <Box display="flex">
                  <TextFieldRounded
                    onChange={(v) => {
                      setValue('weight', v);
                      if (!watch('typeWeight')) {
                        setValue('typeWeight', defaultWeightType?.id);
                      }
                    }}
                    value={watch('weight') || ''}
                    label={t('workout.section.label.score.weight')}
                  />
                  <Box ml={1}>
                    <SelectBoxObject
                      propValue="id"
                      propLabel="id"
                      label="&nbsp;"
                      options={typeWeight}
                      value={watch('typeWeight') || defaultWeightType?.id}
                      setValue={(v) => setValue('typeWeight', v)}
                    />
                  </Box>
                </Box>
                <Box mt={1}>
                  <TextFieldRounded
                    onChange={(v) => setValue('weightNotes', v)}
                    value={watch('weightNotes') || ''}
                    label={t('workout.section.label.notes')}
                  />
                </Box>
              </Box>
            )}
            {hasBenchmarkScore(selectedBenchmark.scores, 'calories') && (
              <Box className={classes.boxScore}>
                <TextFieldRounded
                  onChange={(v) => setValue('calories', v)}
                  value={watch('calories') || ''}
                  label={t('workout.section.label.score.calories')}
                />
                <Box mt={1}>
                  <TextFieldRounded
                    onChange={(v) => setValue('caloriesNotes', v)}
                    value={watch('caloriesNotes') || ''}
                    label={t('workout.section.label.notes')}
                  />
                </Box>
              </Box>
            )}
            {hasBenchmarkScore(selectedBenchmark.scores, 'distance') && (
              <Box className={classes.boxScore}>
                <Box display="flex">
                  <TextFieldRounded
                    onChange={(v) => {
                      setValue('distance', v);
                      if (!watch('typeDistance')) {
                        setValue('typeDistance', defaultDistanceType?.id);
                      }
                    }}
                    value={watch('distance') || ''}
                    label={t('workout.section.label.score.distance')}
                  />
                  <Box ml={1}>
                    <SelectBoxObject
                      propValue="id"
                      propLabel="id"
                      label="&nbsp;"
                      options={typeDistance}
                      value={watch('typeDistance') || defaultDistanceType?.id}
                      setValue={(v) => setValue('typeDistance', v)}
                    />
                  </Box>
                </Box>
                <Box mt={1}>
                  <TextFieldRounded
                    onChange={(v) => setValue('distanceNotes', v)}
                    value={watch('distanceNotes') || ''}
                    label={t('workout.section.label.notes')}
                  />
                </Box>
              </Box>
            )}
            {hasBenchmarkScore(selectedBenchmark.scores, 'tieBreak') && (
              <Box className={classes.boxScore}>
                <TextFieldRounded
                  onChange={(v) => setValue('tieBreak', v)}
                  value={watch('tieBreak') || ''}
                  label={t('workout.section.label.score.tieBreak')}
                />
                <Box mt={1}>
                  <TextFieldRounded
                    onChange={(v) => setValue('tieBreakNotes', v)}
                    value={watch('tieBreakNotes') || ''}
                    label={t('workout.section.label.notes')}
                  />
                </Box>
              </Box>
            )}
          </>
        )}
      </>

      <Box mt={3}>
        <ButtonPrimary fullWidth disabled={loading} onClick={handleSubmit(onSubmit)}>
          <LoaderSm loading={loading} />
          {t('button.save')}
        </ButtonPrimary>
      </Box>
    </>
  );
};

FormHistory.defaultProps = {
  callback: () => {},
  selectedBenchmark: null,
};

FormHistory.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  callback: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  selectedBenchmark: PropTypes.object,
};

export default FormHistory;
