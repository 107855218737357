import { lazy } from 'react';

import InfoNotFound from './InfoNotFound';

const InfoChannelSignup = lazy(() => import('./InfoChannelSignup'));
const InfoDocumentSigned = lazy(() => import('./InfoDocumentSigned'));
const InfoFirstVisitCheckin = lazy(() => import('./InfoFirstVisitCheckin'));
const InfoSendEmail = lazy(() => import('./InfoSendEmail'));
const InfoSendPushNotification = lazy(() => import('./InfoSendPushNotification'));
const InfoSendSms = lazy(() => import('./InfoSendSms'));
const InfoWait = lazy(() => import('./InfoWait'));

export const getInfoComponent = (automationTypeFunction) => {
  switch (automationTypeFunction) {
    case 'ChannelSignup':
      return InfoChannelSignup;
    case 'DocumentSigned':
      return InfoDocumentSigned;
    case 'FirstVisitCheckin':
      return InfoFirstVisitCheckin;
    case 'SendEmail':
      return InfoSendEmail;
    case 'SendPushNotification':
      return InfoSendPushNotification;
    case 'SendSms':
      return InfoSendSms;
    case 'Wait':
      return InfoWait;
    default:
      return InfoNotFound;
  }
};
