/* eslint-disable react/prop-types */
import React from 'react';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import Typography from 'core/ui/Typography';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import { usersImportToolTypes } from 'core/utils/consts';

export default ({ toolType, setToolType }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <SelectBoxObject
        label={t('import.tool')}
        options={Object.keys(usersImportToolTypes).map((x) => ({
          id: x,
          name: t(`import.tool.${x}`),
        }))}
        value={toolType}
        setValue={(v) => setToolType(v)}
        bordered
        emptyItem={false}
      />
      <Typography className="roboto-regular" m={2}>
        {t(`import.tool.info.${toolType}`)}
      </Typography>
    </Box>
  );
};
