import React, { createContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Dialog } from '@material-ui/core';
import DialogTitle from 'core/ui/Dialog/DialogTitle';
import Box from 'core/ui/Box';
import Texture from 'core/ui/Texture';
import { useDispatch } from 'react-redux';
import { searchActions } from 'modules/search';
import Classes from 'components/Search/Classes';
import { channelListsActions } from 'modules/channelLists';

export const ListContext = createContext();

const ModalSearchWorkouts = ({ channel, list, close }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(
    () => () => {
      dispatch(searchActions.clearSearchResults());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const addWorkoutInList = (workout) => {
    const callback = () => {
      dispatch(channelListsActions.getChannelListsStart(channel.id, true));
    };
    dispatch(
      channelListsActions.postWorkoutChannelListStart(channel.id, list.id, workout.id, callback),
    );
  };

  return (
    <Dialog open maxWidth="md" fullWidth onClose={() => close()}>
      <DialogTitle background="true" onClose={() => close()}>
        {t('modal.channel.searchClasses')}
      </DialogTitle>
      <Texture>
        <Box px={5} pb={5}>
          <Box>
            <ListContext.Provider value={{ list }}>
              <Classes
                onClickCard={(workout) => addWorkoutInList(workout)}
                channelId={channel.id}
                model="Workout"
                hideFilters
                hideUpcoming
              />
            </ListContext.Provider>
          </Box>
        </Box>
      </Texture>
    </Dialog>
  );
};

ModalSearchWorkouts.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  channel: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  list: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
};

export default ModalSearchWorkouts;
