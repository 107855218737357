import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form-v5';
import { useDispatch } from 'react-redux';
import notifications from 'modules/notifications';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import useSelectors from 'modules/map/useSelectors';
import { usersDocumentsModule } from 'modules/usersDocuments';
import { formatDateApi, formatDateToApi } from 'core/utils/formatters';

export default ({ channel, user, item, callback }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loading = useSelectors(usersDocumentsModule, 'loading');
  const { request } = useActions();
  const { register, handleSubmit, setValue, watch, reset, errors } = useForm();

  const onSubmit = (values) => {
    request({
      action: !item?.id
        ? Modules.usersDocuments.actions.postUsersDocuments
        : Modules.usersDocuments.actions.putUsersDocuments,
      data: {
        ...values,
        id: item?.id || null,
        ownerableId: channel.id,
        ownerableType: 'Channel',
        userId: user?.id,
        date: formatDateToApi(values?.date || new Date()),
      },
      options: {
        onSuccess: () => {
          if (callback) {
            callback(values.name);
          }
          dispatch(notifications.success(t('message.success.save.coachClock')));
          request({
            action: Modules.usersDocuments.actions.getUsersDocuments,
            data: { ownerableId: channel.id, ownerableType: 'Channel', userId: user?.id },
          });
        },
      },
    });
  };

  useEffect(() => {
    register('name', { required: true });
    register('fileType', { required: true });
    register('s3Url', { required: true });
    register('date', { required: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register]);

  useEffect(() => {
    if (item && item?.id) {
      reset({
        ...item,
        date: formatDateApi(item?.date),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, item?.id]);

  return {
    register,
    setValue,
    watch,
    reset,
    errors,
    onSubmit,
    handleSubmit,
    loading,
  };
};
