import { useEffect, useState } from 'react';

export default ({ judge = false, workout }) => {
  const [ready, setReady] = useState([]);
  const [playing, setPlaying] = useState(false);
  const [muted, setMuted] = useState(true);
  const [seek, setSeek] = useState(0);
  const [checkedTeams, setCheckedTeams] = useState([]);
  const [time, setTime] = useState({
    value: 0,
    complete: 0,
    jumped: 0,
    seekJump: 0,
    total: 0,
    mainId: 0,
  });

  const setVideoReady = (userId) => {
    setReady((prev) => [...prev.filter((x) => x !== userId), userId]);
  };

  const removeVideoReady = (userId) => {
    setReady((prev) => prev.filter((x) => x !== userId));
  };

  useEffect(() => {
    if (!judge) {
      setReady([]);
    }
  }, [judge]);

  const isReady = () => {
    if (judge) {
      return ready.length === 1;
    }

    let ret = true;
    checkedTeams.forEach((item) => {
      const recording =
        workout &&
        workout.recordingVideos &&
        workout.recordingVideos.find((x) => x.user.id === item);
      if (recording) {
        if (time.complete <= recording.durationSeconds && !ready.find((y) => y === item)) {
          ret = false;
        }
      }
    });

    return checkedTeams.length > 0 && ret;
  };

  return {
    playing,
    setPlaying,
    muted,
    setMuted,
    time,
    setTime,
    ready,
    setReady,
    durationSeconds: time.total,
    setVideoReady,
    removeVideoReady,
    isReady: isReady(),
    checkedTeams,
    setCheckedTeams,
    setSeek,
    seek,
  };
};
