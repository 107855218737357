/* eslint-disable jsx-a11y/media-has-caption */
import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import { RecordingContext } from 'components/Stream/Views/Recording/index';
import { useTranslation } from 'react-i18next';
import { Chip } from '@material-ui/core';
import Box from '../Box';
import './style.css';
import Typography from '../Typography';
import FontIcon from '../FontIcon';
import LoaderSm from '../LoaderSm';

export const videoResolutions = {
  240_160: {
    width: 240,
    height: 160,
  },
  384_288: {
    width: 384,
    height: 288,
  },
  480_360: {
    width: 480,
    height: 360,
  },
  640_480: {
    width: 640,
    height: 480,
  },
  800_480: {
    width: 800,
    height: 480,
  },
  800_600: {
    width: 800,
    height: 600,
  },
  1024_768: {
    width: 1024,
    height: 768,
  },
};
const Video = ({
  idVideo,
  src,
  playing,
  width,
  onSeek,
  seek,
  vimeo,
  upload,
  controls,
  showPlayButton,
  style,
  height,
  muted,
  resolution,
  full,
  borderRadius,
  onReady,
  onWaiting,
  durationSeconds,
  togglePlayRecordings,
  setDuration,
}) => {
  const { t } = useTranslation();
  const videoRef = useRef();
  const [state, setState] = useState({
    play: false,
    ready: false,
  });

  const clickVideo = () => {
    setState((prev) => ({
      ...prev,
      play: !prev.play,
    }));
    if (vimeo || upload) {
      return;
    }

    if (state.play) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
  };

  const stop = () => {
    const videoElem = document.getElementById(`react-video-id-${idVideo}`);
    if (videoElem && videoElem.pause) {
      videoElem.pause();
    }
  };

  useEffect(() => {
    if (!playing) {
      stop();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playing]);

  useEffect(() => {
    if (videoRef.current && seek && videoRef.current.seekTo && state.ready) {
      if (durationSeconds > 0 && seek > durationSeconds) {
        videoRef.current.seekTo(Math.round(durationSeconds - 0.5), 0);
      } else {
        videoRef.current.seekTo(Math.round(seek - 0.5), 0);
      }
      // const p = videoRef.current.getInternalPlayer();
      // if (p && p.play) {
      //   p.play();
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seek, durationSeconds, videoRef.current, state.ready]);

  const recordingContext = useContext(RecordingContext);

  const onReadyLocal = () => {
    setState((prev) => ({ ...prev, ready: true }));
    onReady();
    if (recordingContext && videoRef.current) {
      setTimeout(() => {
        if (videoRef.current) {
          if (setDuration) {
            setDuration(videoRef.current.getDuration());
          }
          recordingContext.setDurationVideo(videoRef.current.getDuration());
        }
      }, 2000);
      if (setDuration) {
        setDuration(videoRef.current.getDuration());
      }
      recordingContext.setDurationVideo(videoRef.current.getDuration());
    }
  };

  const onPlay = () => {
    if (!playing && recordingContext) {
      recordingContext.togglePlayRecordings(true);
      togglePlayRecordings(true);
    }
  };

  const onEnded = () => {
    if (recordingContext) {
      recordingContext.showModalEnded();
      recordingContext.togglePlayRecordings(false);
    }
  };

  return (
    <Box
      className="container"
      style={{
        width: full === true ? '100%' : width || resolution.width,
        height: full === true ? '100%' : height || resolution.height,
        borderRadius,
      }}
      onClick={() => !!src && clickVideo()}
    >
      {!vimeo && !upload && (
        <video
          style={style}
          ref={videoRef}
          autoPlay=""
          className="video"
          controls={controls}
          onEnded={() => setState((prev) => ({ ...prev, play: false }))}
          loop=""
          muted={muted}
          width="480"
          src={src}
          playsInline
        >
          <source src={src} type="video/mp4" />
          <source src={src} type="video/ogg" />
        </video>
      )}

      {upload && <Box className={playing ? 'method-video-playing' : 'method-video-paused'} />}

      {(vimeo || upload) && (
        <ReactPlayer
          playing={showPlayButton ? state.play : playing}
          ref={videoRef}
          className="video"
          style={{ background: upload ? '#000' : 'transparent' }}
          config={{
            vimeo: { playerOptions: { playsinline: 1, preload: true } },
            youtube: {
              playerVars: { showinfo: 1, playsinline: 1, rel: 0 },
            },
            file: {
              forceVideo: true,
              forceAudio: true,
              attributes: {
                id: `react-video-id-${idVideo}`,
                muted: 'muted',
                attributes: { preload: 'auto' },
                onWaiting: () => {
                  stop();
                  onWaiting(idVideo);
                },
              },
            },
          }}
          width="100%"
          onProgress={(e) => (playing ? onSeek(e.playedSeconds) : null)}
          onReady={() => onReadyLocal()}
          onPlay={() => onPlay()}
          onEnded={() => onEnded()}
          height="100%"
          volume={muted ? 0 : 1}
          muted={muted}
          loop={false}
          controls={controls}
          url={src}
        />
      )}

      {showPlayButton && (
        <Box className={`boxControls ${!state.play ? 'boxShadow' : ''}`}>
          {state.play ? (
            <Typography className="button-opc">
              <FontIcon iconName="icon-pause" color="white" size={50} />{' '}
            </Typography>
          ) : (
            <Typography className="button" component="div">
              {!src ? (
                <Chip icon={<LoaderSm loading />} color="primary" label={t('video.processing')} />
              ) : (
                <FontIcon iconName="icon-play" color="white" size={50} />
              )}
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
};

Video.propTypes = {
  src: PropTypes.string,
  idVideo: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  resolution: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  full: PropTypes.bool,
  vimeo: PropTypes.bool,
  upload: PropTypes.bool,
  controls: PropTypes.bool,
  muted: PropTypes.bool,
  showPlayButton: PropTypes.bool,
  playing: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  onSeek: PropTypes.func,
  seek: PropTypes.number,
  borderRadius: PropTypes.number,
  onReady: PropTypes.func,
  onWaiting: PropTypes.func,
  durationSeconds: PropTypes.number,
  togglePlayRecordings: PropTypes.func,
};

Video.defaultProps = {
  src: '',
  width: 340,
  height: 200,
  idVideo: `${Math.random() * 1000}`,
  resolution: {},
  style: {},
  full: false,
  muted: false,
  vimeo: false,
  upload: false,
  controls: false,
  showPlayButton: true,
  playing: false,
  onSeek: () => {},
  seek: 0,
  borderRadius: 0,
  onReady: () => {},
  onWaiting: () => {},
  durationSeconds: 0,
  togglePlayRecordings: () => {},
};

export default Video;
