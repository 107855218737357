import React from 'react';

import Grid from 'core/ui/Grid';
import Box from 'core/ui/Box';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import { useTranslation } from 'react-i18next';

import SelectBox from 'core/ui/SelectBox';
import { usStatesTypes } from 'core/utils/consts';
import Checkbox from 'core/ui/Checkbox';

export default ({ watch, setValue, errors, rootKey, showCopy }) => {
  const error = (field) => {
    const keys = rootKey.split('.');
    let er = errors;

    keys.forEach((item) => {
      er = er && er[item];
    });

    return er && er[field];
  };

  const { t } = useTranslation();
  return (
    <>
      {showCopy && (
        <Box mb={2}>
          <Checkbox
            checked={watch(`${rootKey}.sameBusinessAddress`)}
            onClick={() =>
              setValue(`${rootKey}.sameBusinessAddress`, !watch(`${rootKey}.sameBusinessAddress`))
            }
            label={t('form.address.label.sameBusinessAddress')}
          />
        </Box>
      )}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <TextFieldRounded
            value={`${watch(`${rootKey}.address1`) || ''}`}
            onChange={(v) => setValue(`${rootKey}.address1`, v)}
            error={error('address1')}
            placeholder={t('form.address.label.address')}
            label={t('form.address.label.address').toUpperCase()}
            required
            bordered
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <TextFieldRounded
            value={`${watch(`${rootKey}.address2`) || ''}`}
            onChange={(v) => setValue(`${rootKey}.address2`, v)}
            placeholder={t('form.address.label.address')}
            label="Address 2"
            error={error('address2')}
            bordered
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Box>
            <TextFieldRounded
              value={`${watch(`${rootKey}.city`) || ''}`}
              onChange={(v) => setValue(`${rootKey}.city`, v)}
              error={error('city')}
              placeholder={t('form.address.label.city')}
              label={t('form.address.label.city').toUpperCase()}
              required
              bordered
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <SelectBox
            options={usStatesTypes}
            placeholder={t('channel.placeholder.state')}
            error={error('stateCd')}
            label={t('channel.label.state').toUpperCase()}
            setValue={(v) => setValue(`${rootKey}.stateCd`, v)}
            value={watch(`${rootKey}.stateCd`)}
            bordered
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Box mb={3}>
            <TextFieldRounded
              value={`${watch(`${rootKey}.zip`) || ''}`}
              onChange={(v) => setValue(`${rootKey}.zip`, v)}
              error={error('zip')}
              placeholder={t('form.address.label.zip')}
              label={t('form.address.label.zip').toUpperCase()}
              required
              bordered
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
