import React, { useEffect, useState } from 'react';
import { ButtonPrimary } from 'core/ui/Button';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import Typography from 'core/ui/Typography';

import {
  TableContainer,
  TableHead,
  Table,
  TableCell,
  TableRow,
  TableBody,
  Paper,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { channelsSelectors } from 'modules/channels';
import { Add } from '@material-ui/icons';
import { channelListsActions, channelListsSelectors } from 'modules/channelLists';
import LoaderSm from 'core/ui/LoaderSm';
import MIcon from 'core/ui/MIcon';
import { secondary } from 'core/ui/Colors';
import ItemList from './ItemList';
import ModalFormList from './Form/Modal';
import ModalSearchWorkouts from './Workouts/ModalSearch';

const Lists = () => {
  const { t } = useTranslation();
  const channel = useSelector((state) => channelsSelectors.getChannel(state));
  const channelLists = useSelector((state) => channelListsSelectors.getChannelLists(state));
  const loading = useSelector((state) => channelListsSelectors.getLoading(state));
  const [modal, setModal] = useState({
    open: false,
  });
  const [modalWorkouts, setModalWorkouts] = useState({
    open: false,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (channel && channel.id) {
      dispatch(channelListsActions.getChannelListsStart(channel.id, true));
    }
  }, [dispatch, channel]);

  useEffect(() => {
    if (modalWorkouts.list) {
      setModalWorkouts((prev) => ({
        ...prev,
        list: channelLists.find((x) => x.id === modalWorkouts.list.id),
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelLists]);

  const openModal = () => {
    setModal((prev) => ({ ...prev, mode: 'new', open: true }));
  };

  const onEditClick = (item) => {
    setModal((prev) => ({ ...prev, item, mode: 'edit', open: true }));
  };

  const onRemoveWorkoutClick = (workout, listId) => {
    const callback = () => {
      dispatch(channelListsActions.getChannelListsStart(channel.id, true));
    };
    dispatch(
      channelListsActions.deleteWorkoutChannelListStart(channel.id, listId, workout.id, callback),
    );
  };

  return (
    <Box p={5}>
      {modal.open && (
        <ModalFormList
          open={modal.open}
          item={modal.item}
          mode={modal.mode}
          close={() => setModal((prev) => ({ ...prev, open: false, item: {} }))}
          callback={(nameList) =>
            setModal((prev) => ({ ...prev, open: false, item: {}, nameListExpand: nameList }))
          }
        />
      )}

      {modalWorkouts.open && (
        <ModalSearchWorkouts
          channel={channel}
          list={modalWorkouts.list}
          close={() => setModalWorkouts((prev) => ({ ...prev, open: false }))}
        />
      )}
      <Box display="flex" alignItems="center">
        <Box flexGrow="1" display="flex" alignItems="center">
          <LoaderSm loading={loading} />
        </Box>
        <Box>
          <ButtonPrimary onClick={() => openModal()}>
            <Box display="flex" alignItems="flex-end">
              <Add size="small" />
              <Box alignItems="center">
                <Typography component="span" mr={1}>
                  {t('channel.button.addList')}
                </Typography>
              </Box>
            </Box>
          </ButtonPrimary>
        </Box>
      </Box>
      <Box mt={2}>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell style={{ width: 50 }} />
                <TableCell>{t('form.list.label.name')}</TableCell>
                <TableCell align="right">{t('form.list.label.price')}</TableCell>
                <TableCell align="center">{t('form.list.label.active')}</TableCell>
                <TableCell align="center">
                  <MIcon icon="people" color={secondary} size={20} />
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {channelLists &&
                channelLists.map((row) => (
                  <ItemList
                    onAddWorkoutClick={(list) =>
                      setModalWorkouts((prev) => ({ ...prev, open: true, list }))
                    }
                    onRemoveWorkoutClick={(workout) => onRemoveWorkoutClick(workout, row.id)}
                    onEditClick={onEditClick}
                    key={row.name}
                    item={row}
                    expanded={modal.nameListExpand && modal.nameListExpand === row.name}
                    channel={channel}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default Lists;
