import api from 'core/api';

export const payments = () => api.get('/payments');
export const retryPayment = (data) => api.post(`/payments/retry`, data);
export const purchaseChannelPlan = (params) => api.post(`payments/channel_plan`, params);

export const purchaseChannelKey = (params) => api.post(`payments/channel/memberships`, params);
export const purchaseChannelList = (params) => api.post(`payments/channel/list`, params);
export const purchaseCoachScheduleSession = (params) =>
  api.post(`payments/coach/schedule_session`, params);
export const purchaseChannelStoreProduct = (params) =>
  api.post(`payments/channel/store_product`, params);
export const purchaseWorkoutToMember = (params) => api.post(`payments/workout/member`, params);
