import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import { Select, Paper } from '@material-ui/core';
import { countriesModule } from 'modules/countries';
import useSelectors from 'modules/map/useSelectors';
import Typography, { fontStyle } from '../Typography';
import Box from '../Box';
import { secondaryLight } from '../Colors';

const BootstrapInput = withStyles(() => ({
  input: {
    borderRadius: 5,
    position: 'relative',
    backgroundColor: '#fff',
    width: '100%',
    fontSize: 12,
    paddingLeft: 5,
    '&:focus': {
      backgroundColor: '#fff',
      borderRadius: 5,
      borderColor: '#80bdff',
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: 5,
    width: '100%',
  },
  select: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontSize: 12,
    paddingLeft: 5,
  },
}));

export default function CountryChosen({
  placeholder,
  defaultValue,
  label,
  error,
  setValue,
  required,
  disabled,
  bordered,
  paperStyle,
  labelColor,
}) {
  const classes = useStyles();
  const countries = useSelectors(countriesModule, 'countries');

  const styleInput = error
    ? paperStyle
    : { ...paperStyle, border: bordered ? `1px solid${secondaryLight}` : 'none' };

  const countryUS = countries.find((x) => x.flag === 'US');

  return (
    <div>
      {label !== '' && (
        <Box ml={2} mb={2} display="flex">
          <Typography style={{ color: labelColor, ...fontStyle.label }} variant="body1">
            {label}
          </Typography>
          {required && (
            <Typography ml={1} color="error">
              *
            </Typography>
          )}
        </Box>
      )}
      <Paper style={styleInput} className={`${error ? 'input-paper-error' : ''} ${classes.root}`}>
        <Select
          native
          disabled={disabled}
          className={classes.select}
          value={defaultValue}
          onChange={(e) => (!disabled && e.target.value ? setValue(e.target.value) : null)}
          input={<BootstrapInput />}
        >
          {placeholder && <option value="">{placeholder}</option>}
          {[countryUS, ...countries.filter((x) => x !== countryUS)]
            .filter((x) => !!x)
            .map((x) => (
              <option key={x.id} value={x.id}>
                {x.name}
              </option>
            ))}
        </Select>
      </Paper>
    </div>
  );
}

CountryChosen.defaultProps = {
  label: '',
  defaultValue: '',
  placeholder: '',
  error: null,
  setValue: null,
  required: false,
  disabled: false,
};
