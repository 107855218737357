import api from 'core/api';

export const likesModule = 'likes';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const actions = {
  getLikes: {
    module: likesModule,
    name: 'getLikes',
    api: (params) => api.get(`/likes`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: ['data'],
    },
    state: loadingStates,
  },
  toggleLike: {
    module: likesModule,
    name: 'toggleLike',
    api: (data) => api.post(`/likes`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
};

export default {
  actions,
  state: {
    data: [],
  },
};
