import api from 'core/api';
import { call, put } from 'redux-saga/effects';

export const personalRecordMovementsModule = 'personalRecordMovements';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const loadingUsersStates = {
  start: { loadingUsers: true },
  error: { loadingUsers: false },
  success: { loadingUsers: false },
};

const actions = {
  getPrMovements: {
    module: personalRecordMovementsModule,
    name: 'getPrMovements',
    api: (params) => api.get('/personal_record_movements', { params }),
    *sagas(Creators, { params, options }) {
      try {
        const resp = yield call(actions.getPrMovements.api, params);
        yield put(Creators.getPrMovementsSuccess(resp.data, resp.pagination));
        if (options.onSuccess) {
          options.onSuccess(resp.data, resp.pagination);
        }
      } catch (error) {
        if (options.onError) {
          options.onError(error);
        }
        yield put(Creators.getPrMovementsError());
      }
    },
    params: {
      start: ['params'],
      error: [''],
      success: ['data', 'pagination'],
    },
    state: loadingStates,
  },
  postPersonalRecord: {
    module: personalRecordMovementsModule,
    name: 'postPersonalRecord',
    api: (data) => api.post(`/personal_record_movements`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  putPersonalRecord: {
    module: personalRecordMovementsModule,
    name: 'putPersonalRecord',
    api: (data) => api.put(`/personal_record_movements/${data.id}`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  getPrMovementUsers: {
    module: personalRecordMovementsModule,
    name: 'getPrMovementUsers',
    api: ({ id }) => api.get(`/personal_record_movements/${id}/users`),
    params: {
      start: ['params'],
      error: [''],
      success: ['users'],
    },
    state: loadingUsersStates,
  },
  getPrMovement: {
    module: personalRecordMovementsModule,
    name: 'getPrMovement',
    api: ({ id }) => api.get(`/personal_record_movements/${id}`),
    params: {
      start: ['params'],
      error: [''],
      success: ['pr'],
    },
    state: loadingStates,
  },
  deletePrHistory: {
    module: personalRecordMovementsModule,
    api: ({ personalRecordUserId }) => api.delete(`/personal_record_users/${personalRecordUserId}`),
    name: 'deletePrHistory',
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: {},
  },
  postUserPr: {
    module: personalRecordMovementsModule,
    api: (params) => api.post('/personal_record_users', params),
    name: 'postUserPr',
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: {},
  },
  importScores: {
    module: personalRecordMovementsModule,
    api: (params) => api.post('/personal_record_users/import_scores', params),
    name: 'importScores',
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: {},
  },
};

export default {
  actions,
  state: {
    loading: false,
    loadingAdd: false,
    loadingHistory: false,
    loadingUsers: false,
    users: [],
    data: [],
    pagination: null,
    pr: null,
  },
};
