import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Delete, Edit } from '@material-ui/icons';
import Button, { ButtonPrimary } from 'core/ui/Button';
import { useTranslation } from 'react-i18next';
import Typography from 'core/ui/Typography';
import Box from 'core/ui/Box';

export default function ItemDocument({
  defaultDocumentWaiverId,
  preview,
  item,
  onEditClick,
  updatePrimaryWaiver,
  onRemoveClick,
}) {
  const { t } = useTranslation();
  return (
    <TableRow>
      <TableCell>{item.name}</TableCell>
      <TableCell>{item.pdf ? 'PDF' : 'CUSTOM'}</TableCell>
      <TableCell align="center">
        <Box>
          <Typography>{item.kind?.toUpperCase()}</Typography>
        </Box>

        {item.kind === 'waiver' && (
          <>
            {item?.id === defaultDocumentWaiverId ? (
              <Typography color="primary">{t('label.primary')}</Typography>
            ) : (
              <ButtonPrimary onClick={() => updatePrimaryWaiver(item?.id)}>
                {t('button.makePrimary')}
              </ButtonPrimary>
            )}
          </>
        )}
      </TableCell>
      <TableCell align="right">
        <Button onClick={() => preview(item)}>View</Button>
        <IconButton onClick={() => onEditClick(item)}>
          <Edit />
        </IconButton>
        <IconButton onClick={() => onRemoveClick(item?.id)}>
          <Delete />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

ItemDocument.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.object.isRequired,
  onEditClick: PropTypes.func.isRequired,
};
