import React from 'react';

import { secondary, secondaryLight } from 'core/ui/Colors';
import { useTheme } from '@material-ui/core';

export default () => {
  const { palette } = useTheme();
  const [filterTypes, setFilterTypes] = React.useState([]);

  const toogleFilter = (item) => {
    if (!item) {
      setFilterTypes([]);
      return;
    }
    if (filterTypes.find((x) => x === item)) {
      setFilterTypes((prev) => prev.filter((x) => x !== item));
    } else {
      setFilterTypes((prev) => [...prev, item]);
    }
  };

  const optionFilter = (item) => {
    const style = {
      padding: 8,
      borderRadius: 10,
    };
    if ((!item && filterTypes.length === 0) || (item && filterTypes.find((x) => x === item))) {
      return {
        ...style,
        backgroundColor: palette.primary.main,
        color: 'white',
      };
    }
    return {
      ...style,
      backgroundColor: secondaryLight,
      color: secondary,
    };
  };

  return {
    filterTypes,
    setFilterTypes,
    toogleFilter,
    optionFilter,
  };
};
