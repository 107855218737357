import React, { useContext, useEffect } from 'react';
import Box from 'core/ui/Box';
import Grid from 'core/ui/Grid';
import { ratingsActions, ratingsSelectors } from 'modules/ratings';
import { useDispatch, useSelector } from 'react-redux';
import { ShowContext } from 'components/Workout/Show/index';
import Paper from 'core/ui/Paper';
import CardRating from './Card';

export default () => {
  const showContext = useContext(ShowContext);
  const dispatch = useDispatch();
  const ratings = useSelector((state) => ratingsSelectors.getRatings(state));

  useEffect(() => {
    dispatch(
      ratingsActions.getRatingsStart('Workout', showContext.workout.id, {
        page: 1,
        per_page: 10,
      }),
    );
  }, [dispatch, showContext.workout]);

  return (
    <Paper elevation={2} className="paper-rounded" p={3}>
      <Box>
        <Grid container spacing={5} mt={3}>
          {ratings &&
            ratings.map((rate, index) => (
              <Grid item xs={12} sm={6} md={3} lg={3} key={index.toString()}>
                <CardRating rate={rate} />
              </Grid>
            ))}
        </Grid>
      </Box>
    </Paper>
  );
};
