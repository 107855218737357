import React from 'react';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import Typography, { fontStyle } from 'core/ui/Typography';
import FormSectionScores from 'components/Workout/Form/Section/FormSectionScores';
import Paper from 'core/ui/Paper';
import { ButtonPrimary } from 'core/ui/Button';
import MovementChosen from 'core/ui/MovimentChosen';
import FormSectionTimer from 'components/Workout/Form/Section/FormSectionTimer';
import useForm from './useForm';
import FormInfo from './FormInfo';

const FormBenchmark = ({ callback, benchmark, channel }) => {
  const { t } = useTranslation();
  const {
    watch,
    setValue,
    errors,
    setScoreData,
    scoreData,
    timerData,
    setTimerData,
    submitForm,
    loading,
  } = useForm({
    callback,
    benchmark,
    channel,
  });

  const allowEdit = !benchmark?.id || benchmark?.allowEdit;

  return (
    <Box>
      <FormInfo watch={watch} setValue={setValue} errors={errors} />

      <Paper className="paper-rounded" p={3} my={2}>
        <FormSectionScores
          setScoreData={(values) =>
            setScoreData((prev) => ({
              ...prev,
              ...values,
            }))
          }
          scoreData={scoreData}
          isVideoClass={false}
          timerData={timerData}
          setTimerData={setTimerData}
          disabled={false}
          bordered
        />

        <FormSectionTimer
          isVideoClass={false}
          timerData={timerData}
          setTimerData={setTimerData}
          disabled={false}
          bordered
        />
      </Paper>

      <Box mt={3}>
        <Typography mb={2} style={fontStyle.label} variant="body1">
          {t('movements').toUpperCase()}
        </Typography>

        <MovementChosen
          val={watch('movements') ? [...watch('movements')] : []}
          setValue={(values) => setValue('movements', values)}
          kind={null}
          limit={100}
          channelId={2}
        />
      </Box>

      <Box flexGrow={1} display="flex" justifyContent="flex-end" my={5}>
        {allowEdit && (
          <ButtonPrimary disabled={loading} style={{ width: 150 }} onClick={() => submitForm()}>
            {t('button.save')}
          </ButtonPrimary>
        )}

        {!allowEdit && <Typography>{t('system.benchmarks.notEditable')}</Typography>}
      </Box>
    </Box>
  );
};

FormBenchmark.defaultProps = {
  callback: () => {},
  benchmark: null,
};

export default FormBenchmark;
