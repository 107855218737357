import React, { useContext, useEffect, useCallback, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from 'core/ui/Box';
import { useBeforeunload } from 'react-beforeunload';
import { StreamContext } from 'pages/Stream';
import useOpenVidu, { TypesSignal } from 'core/openVidu/useOpenVidu';
import { useDispatch, useSelector } from 'react-redux';
import { workoutsSelectors } from 'modules/workouts';
import TimerSection from 'components/Workout/Timer/index';
import { sessionWorkoutActions } from 'modules/sessionWorkout';
import { workoutEvent, workoutInviteTypes } from 'core/utils/consts';
import ModalConfig from './Config';
import GalleryView from './Views/GalleryView';
import TimerRemaining from './TimerRemaining';
import SpeakerView from './Views/SpeakerView';
import Recording from './Views/Recording/index';
import ModalEnded from './Views/Recording/ModalEnded';
import CountDownCompetition from './CountDownCompetition';
import ConnectionQuality from './ConnectionQuality';
import InfoEntry from './InfoEntry';

const useStyles = makeStyles((theme) => ({
  boxMain: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    position: 'relative',
    flexGrow: 1,
    flexWrap: 'wrap',
  },
  boxBottom: {
    position: 'absolute',
    bottom: 10,
    [theme.breakpoints.down('sm')]: {
      bottom: 80,
    },
    padding: 10,
    borderRadius: 10,
  },
  boxTimer: {
    background: '#fff',
    borderRadius: 5,
  },
}));

export default () => {
  const streamContext = useContext(StreamContext);
  const { leaveSession, sendSignalUserChanged, OV, changeDevice, isCoach, screenShare } =
    useOpenVidu();
  const classes = useStyles();
  const [openConfig, setOpenConfig] = useState({
    open: false,
  });
  const workout = useSelector((state) => workoutsSelectors.getWorkout(state));
  const loadingWorkout = useSelector((state) => workoutsSelectors.getLoading(state));
  const workoutRef = useRef();
  const [currentWorkoutSection, setCurrentWorkoutSection] = useState(null);
  const currentWorkoutSectionRef = useRef(currentWorkoutSection);
  const [galleryView, setGalleryView] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    workoutRef.current = workout;
    currentWorkoutSectionRef.current = currentWorkoutSection;
  });

  const camStatusChanged = useCallback(
    (userParam, value) => {
      userParam.streamManager.publishVideo(value);
      sendSignalUserChanged({ isVideoActive: value });
    },
    [sendSignalUserChanged],
  );

  const micStatusChanged = useCallback(
    (userParam, value) => {
      streamContext.setControlPermission((prev) => ({ ...prev, changeAudio: value }));
      userParam.streamManager.publishAudio(value);
      sendSignalUserChanged({ isAudioActive: value });
    },
    [sendSignalUserChanged, streamContext],
  );

  const exit = (rating = true) => {
    window.location = `/workout/view/${workoutRef.current.slug}${rating ? '?rating=true' : ''}`;
    if (streamContext.eventSideBar.resetTimer) {
      streamContext.eventSideBar.resetTimer(false);
    }
  };

  const setCurrentSection = (section, send = true) => {
    setCurrentWorkoutSection(section);
    streamContext.setEventSideBar((prev) => ({ ...prev, currentSection: section }));
    if (send && workoutRef.current.live) {
      if (workoutRef.current.live) {
        dispatch(
          sessionWorkoutActions.postEventStart({
            workoutId: workoutRef.current.id,
            kind: workoutEvent.change_section,
            data: section?.id,
          }),
        );
      }
      sendSignalUserChanged({
        type: TypesSignal.setTimer,
        sectionId: section ? section.id : null,
      });
    }
  };

  const setGalleryLayout = (b) => {
    setGalleryView(b);
    streamContext.setEventSideBar((prev) => ({ ...prev, galleryView: b }));
  };

  // SET EVENTS IN CONTEXT
  useEffect(() => {
    streamContext.setEventSideBar({
      camStatusChanged,
      micStatusChanged,
      sendSignalUserChanged,
      exit,
      changeDevice,
      setOpenConfig,
      setCurrentSection,
      galleryView,
      screenShare,
      setGalleryView: setGalleryLayout,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useBeforeunload(() => leaveSession());

  useEffect(() => {
    if (
      workout &&
      workout.registered &&
      workout.isCompetition &&
      !workout.competitionAccessed &&
      workout.sections.length > 0
    ) {
      const firstSection = workout.sections[0];
      setCurrentSection(firstSection, false);
    }

    if (workout.isCompetition && workout.competitionAccessed) {
      streamContext.setOpenModalEnded((prev) => ({ ...prev, edit: false, open: true }));
    }
  }, [workout]); //eslint-disable-line

  useEffect(
    () => () => {
      leaveSession();
    }, []); //eslint-disable-line

  useEffect(() => {
    if (workout && workout.id && !workout.registered) {
      exit(false);
    }
  }, [workout]); //eslint-disable-line

  return (
    <>
      {!loadingWorkout &&
        (!workout.isCompetition || !workout.competitionAccessed) &&
        workout.registered && (
          <>
            <InfoEntry exit={exit} setOpenConfig={setOpenConfig} />
            {workout?.inviteSession?.inviteType !== workoutInviteTypes.record_compare &&
              !workout.isCompetition &&
              !workout.recorded && <TimerRemaining exit={exit} />}
            {workout.live && <ModalConfig modal={openConfig} OV={OV} setModal={setOpenConfig} />}
          </>
        )}

      {workout.isCompetition && streamContext.countDownCompetition.open && (
        <CountDownCompetition exit={exit} />
      )}

      {streamContext.openModalEnded.open && (
        <ModalEnded
          edit={streamContext.openModalEnded.edit}
          open={streamContext.openModalEnded.open}
          setOpen={(v) => streamContext.setOpenModalEnded((prev) => ({ ...prev, open: v }))}
        />
      )}

      {workout.id && (
        <Box style={{ width: '100%', height: '100%' }}>
          <div className={classes.boxMain}>
            {workout.isCompetition || workout.recordingStatus !== 'done' ? (
              <>
                {!workout.isCompetition && (
                  <ConnectionQuality camStatusChanged={camStatusChanged} />
                )}
                {streamContext.eventSideBar.galleryView ? <GalleryView /> : <SpeakerView />}
              </>
            ) : (
              <Recording currentWorkoutSection={currentWorkoutSectionRef.current} />
            )}
          </div>

          <Box className={classes.boxBottom} display="flex">
            {(workout.isCompetition || (!workout.recorded && workout.live)) &&
              currentWorkoutSectionRef.current && (
                <TimerSection
                  live
                  isCoach={isCoach}
                  section={currentWorkoutSectionRef.current}
                  colorLabel="#fff"
                  zoomView={streamContext && streamContext.zoomView}
                />
              )}
          </Box>
        </Box>
      )}
    </>
  );
};
