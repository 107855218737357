import { createSelector } from 'reselect';

const getState = (state) => state.users;

export const getLoading = createSelector(getState, (state) => !!state.loading);
export const getLoadingConnectAccount = createSelector(
  getState,
  (state) => !!state.loadingConnectAccount,
);

export const getError = createSelector(getState, (state) => state.error);

export const getSpotifyData = createSelector(getState, (state) => state.spotifyData);
export const getSpotifyPlayer = createSelector(getState, (state) => state.spotifyPlayer);
export const getWorkoutsInvites = createSelector(getState, (state) => state.workoutsInvites);
export const getLoadingWorkoutsInvites = createSelector(
  getState,
  (state) => state.loadingWorkoutsInvites,
);
