/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';
import { Box } from '@material-ui/core';
import humps from 'humps';
import { useTranslation } from 'react-i18next';
import { primary, secondary, secondaryLight } from 'core/ui/Colors';
import { ButtonPrimary } from 'core/ui/Button';
import Typography from 'core/ui/Typography';
import { BASE_API_URL_SOCKET, STRIPE_PK_KEY } from 'core/env';
import LoaderSm from 'core/ui/LoaderSm';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import Modules from 'modules';
import useActions from 'modules/map/useActions';
import { retrieveAuthHeaders } from 'core/api/auth-headers';
import { retrieveData } from 'core/utils/session-storage';
import { PaymentMethodViewType } from '../Selector';

const actioncable = require('actioncable');

const StripeCardForm = ({
  setView,
  setSelectedPaymentMethod,
  paymentMethod,
  closeModal,
  bordered,
}) => {
  const stripePromise = useRef(loadStripe(STRIPE_PK_KEY));

  return (
    <Elements stripe={stripePromise.current}>
      <FormPaymentMethod
        setSelectedPaymentMethod={setSelectedPaymentMethod}
        setView={setView}
        closeModal={closeModal}
        paymentMethod={paymentMethod}
        bordered={bordered}
      />
    </Elements>
  );
};

export default StripeCardForm;

function FormPaymentMethod({
  setView,
  setSelectedPaymentMethod,
  paymentMethod,
  closeModal,
  bordered,
}) {
  const stripe = useStripe();
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const elements = useElements();
  const { t } = useTranslation();
  const { request } = useActions();
  const [zip, setZip] = useState('');

  const setupIntentClientSecret = paymentMethod?.clientSecret;

  const intervalRefresh = useRef();
  const intervalRefreshCount = useRef(0);

  const checkInterval = (setupIntentId) => {
    intervalRefreshCount.current = 0;

    intervalRefresh.current = setInterval(() => {
      intervalRefreshCount.current += 1;

      if (intervalRefreshCount.current < 8) return;

      request({
        action: Modules.userPaymentMethods.actions.getPaymentMethodBySetupIntent,
        data: { setupIntentId },
        options: {
          onSuccess: (method) => {
            if (setSelectedPaymentMethod) {
              setSelectedPaymentMethod(method);
            }
            if (setView) {
              setView(PaymentMethodViewType.list);
            }
            request({
              action: Modules.userPaymentMethods.actions.addPaymentMethod,
              data: method,
            });
            if (closeModal) {
              closeModal();
            }
            // Must be there, the user can close the modal before the stripe request completes
            clearInterval(intervalRefresh.current);
          },
        },
      });
    }, 1000);
  };

  useEffect(
    () => () => {
      clearInterval(intervalRefresh.current);
    },
    [],
  );

  useEffect(() => {
    let channelCable = null;
    let cable = null;

    if (paymentMethod?.userId) {
      const logAuth = retrieveAuthHeaders(retrieveData)();
      const { client, uid } = logAuth;

      const authHeaders = {
        'access-token': logAuth['access-token'],
        client,
        uid,
      };
      const queryString = new URLSearchParams(authHeaders).toString();

      cable = actioncable.createConsumer(`${BASE_API_URL_SOCKET}?${queryString}`);
      channelCable = cable.subscriptions.create(
        {
          channel: 'PaymentMethodsChannel',
          user_id: paymentMethod?.userId,
        },
        {
          connected: () => {
            console.log('connected!');
          },
          disconnected: () => {},
          received: (data) => {
            setLoading(false);
            const method = humps.camelizeKeys(data.data.method);
            if (setSelectedPaymentMethod) {
              setSelectedPaymentMethod(method);
            }
            if (setView) {
              setView(PaymentMethodViewType.list);
            }
            request({
              action: Modules.userPaymentMethods.actions.addPaymentMethod,
              data: method,
            });
            if (closeModal) {
              closeModal();
            }
          },
        },
      );
    }
    return () => {
      if (channelCable) {
        channelCable.unsubscribe();
      }
      if (cable) {
        cable.disconnect();
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethod]);

  const handleSubmit = async (e) => {
    setLoading(true);
    try {
      e.preventDefault();
      const cardEl = elements.getElement(CardNumberElement);
      const paymentResult = await stripe.confirmCardSetup(setupIntentClientSecret, {
        payment_method: {
          card: cardEl,
          billing_details: {
            address: {
              postal_code: zip,
            },
          },
        },
      });
      if (paymentResult.error) {
        setLoading(false);
        setErrorMessage(paymentResult.error.message);
      }

      if (paymentResult?.setupIntent?.id) checkInterval(paymentResult.setupIntent.id);
    } catch {
      setLoading(false);
    }
  };

  const ELEMENT_OPTIONS = {
    style: {
      base: {
        fontSize: 12,
        fontWeight: 600,
        color: secondary,
        letterSpacing: '0.025em',
        '::placeholder': {
          fontWeight: 'bold',
          color: '#C0C0C0',
        },
      },
      invalid: {
        color: primary,
      },
    },
  };

  const styleInput = {
    backgroundColor: '#fff',
    padding: 10,
    borderRadius: 5,
    border: bordered ? `1px solid${secondaryLight}` : 'none',
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box>
        <Box m={1} px={1} style={styleInput}>
          <CardNumberElement options={{ showIcon: true, ...ELEMENT_OPTIONS }} />
        </Box>
        <Box m={1} px={1} style={styleInput}>
          <CardCvcElement options={ELEMENT_OPTIONS} />
        </Box>
        <Box m={1} px={1} style={styleInput}>
          <CardExpiryElement options={ELEMENT_OPTIONS} />
        </Box>
        <Box px={1}>
          <TextFieldRounded
            className="ph-stripe"
            style={{ height: 20 }}
            paperStyle={{ paddingLeft: 10 }}
            placeholder={t('postalCode')}
            value={zip}
            onChange={(value) => setZip(value)}
            bordered={bordered}
          />
        </Box>
        <Box>
          <Typography color="primary" variant="caption">
            {errorMessage}
          </Typography>
        </Box>

        <ButtonPrimary
          onClick={handleSubmit}
          style={{ width: '100%', marginTop: 10 }}
          component="p"
          disabled={!stripe || loading}
        >
          <LoaderSm loading={loading} />
          {t('button.confirm')}
        </ButtonPrimary>
      </Box>
    </form>
  );
}
