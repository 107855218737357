import React, { useContext, useState } from 'react';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import Typography from 'core/ui/Typography';
import { dropdownWrapper } from 'components/Competition/Show/Tabs/Results/style';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import HeatsManage from 'components/Competition/Show/Tabs/Workouts/Heats/HeatsManage';
import { CompetitionContext } from '../index';

const CompetitionHeats = ({ classes }) => {
  const { t } = useTranslation();
  const formContext = useContext(CompetitionContext);

  const [workoutId, setWorkoutId] = useState();
  const [divisionId, setDivisionId] = useState();

  const divisions =
    (formContext?.competition.hasDivisions && formContext?.competition?.divisions) || [];
  const workouts = formContext?.competition?.workouts || [];
  const workout = workouts.find((x) => x.id === Number(workoutId));

  return (
    <Box className="tw-flex tw-flex-1 tw-flex-col tw-gap-2">
      <Box p={4} className={classes.content} mb={2}>
        <div className="tw-flex tw-flex-row tw-gap-2">
          {divisions && divisions.length > 0 && (
            <Box flexGrow={1}>
              <Typography variant="body2" mb={1}>
                {t('competition.divisions')}
              </Typography>
              <Box style={dropdownWrapper}>
                <SelectBoxObject
                  options={divisions}
                  value={divisionId}
                  setValue={(v) => setDivisionId(v)}
                  elevation={0}
                />
              </Box>
            </Box>
          )}

          <Box flexGrow={1}>
            <Typography variant="body2" mb={1}>
              {t('competition.workouts')}
            </Typography>
            <Box style={dropdownWrapper}>
              <SelectBoxObject
                options={workouts}
                value={workoutId}
                setValue={(v) => setWorkoutId(v)}
                elevation={0}
              />
            </Box>
          </Box>
        </div>
      </Box>
      {workout?.id && (
        <HeatsManage
          divisionId={divisionId}
          hasDivisions={formContext?.competition.hasDivisions}
          workout={{ ...workout, competition: formContext.competition }}
          isCoach
        />
      )}
    </Box>
  );
};

export default CompetitionHeats;
