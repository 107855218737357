/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Paper from 'core/ui/Paper';
import { useTranslation } from 'react-i18next';
import Typography, { fontStyle } from 'core/ui/Typography';
import SFDataTable from 'core/ui/DataTable';
import Box from 'core/ui/Box';
import { useDispatch } from 'react-redux';
import ModalRetryPayment from 'components/Profile/Dashboard/Account/Table/ModalRetryPayment';
import useActions from 'modules/map/useActions';
import notifications from 'modules/notifications';
import ConfirmDialog from 'core/ui/ConfirmDialog';
import Modules from 'modules';
import useSelectors from 'modules/map/useSelectors';
import { channelReportsModule } from 'modules/channelReports';
import { newPaymentsModule } from 'modules/payments/new';
import { date, formatDateToApi } from 'core/utils/formatters';
import { showAPIError } from 'core/utils/showAPIError';
import Divider from 'core/ui/Divider';
import { Link } from '@material-ui/core';
import { useHistory } from 'react-router';
import PaymentTypes from './PaymentTypes';
import StoreOrderItems from '../StoreOrders/StoreOrderItems';
import MenuActions from './MenuActions';
import ModalChangeType from './ModalChangeType';
import ModalRefundPayment from './ModalRefundPayment';
import PaymentStatusColumn from './PaymentStatusColumn';

export default function ResultPayments({
  channel,
  title,
  pagination,
  rows,
  getReport,
  noHeader,
  hideActions,
  perPage,
  setPerPage,
  failedTransactions,
}) {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loadingRefund = useSelectors(newPaymentsModule, 'loading');
  const { request } = useActions();
  const [openModalRetry, setOpenModalRetry] = React.useState({ open: false });
  const [openModalChange, setOpenModalChange] = React.useState({ open: false });
  const [modalRefund, setModalRefund] = React.useState({ open: false });
  const [confirmDialog, setConfirmDialog] = useState({ open: false });
  const loading = useSelectors(channelReportsModule, 'loading');
  const hasSalesTax = rows?.find((x) => Number(x.taxAmount) > 0);

  const refundPayment = (payment) => {
    setModalRefund({
      open: true,
      payment,
    });
  };

  const removePayment = (paymentId) => {
    setConfirmDialog({
      title: 'Remove Payment',
      description: '',
      open: true,
      confirm: () => {
        setConfirmDialog({ open: false });
        request({
          action: Modules.newPayments.actions.removePayment,
          data: {
            paymentId,
          },
          options: {
            onSuccess: () => {
              dispatch(notifications.success(t('payment.remove.success')));
              getReport();
            },
          },
        });
      },
    });
  };

  const sendReceipt = (paymentId) => {
    setConfirmDialog({
      title: 'Send Receipt',
      description: 'Are you sure?',
      open: true,
      confirm: () => {
        setConfirmDialog({ open: false });
        request({
          action: Modules.newPayments.actions.sendReceipt,
          data: {
            paymentId,
          },
          options: {
            onSuccess: () => {
              dispatch(notifications.success(t('payment.sendReceipt.success')));
            },
            onError: showAPIError,
          },
        });
      },
    });
  };

  const downloadReceipt = (paymentId) => {
    setConfirmDialog({
      title: t('button.downloadReceipt'),
      description: t('areYouSure'),
      open: true,
      confirm: () => {
        setConfirmDialog({ open: false });
        request({
          action: Modules.newPayments.actions.downloadReceipt,
          data: {
            paymentId,
          },
          options: {
            onError: showAPIError,
          },
        });
      },
    });
  };

  const sendStepVerification = (paymentId) => {
    setConfirmDialog({
      title: 'Resend Verfication',
      description: 'Are you sure?',
      open: true,
      confirm: () => {
        setConfirmDialog({ open: false });
        request({
          action: Modules.newPayments.actions.resendVerification,
          data: {
            paymentId,
          },
          options: {
            onSuccess: () => {
              dispatch(notifications.success(t('payment.resendVerirication.success')));
            },
          },
        });
      },
    });
  };

  const checkAsPaid = (paymentId) => {
    setConfirmDialog({
      title: 'Check as Paid',
      description: 'Are you sure?',
      open: true,
      confirm: () => {
        setConfirmDialog({ open: false });
        request({
          action: Modules.newPayments.actions.checkAsPaid,
          data: {
            paymentId,
          },
          options: {
            onSuccess: () => {
              dispatch(notifications.success(t('payment.paid.success')));
              getReport();
            },
          },
        });
      },
    });
  };

  const checkAsResolved = (paymentId, resolved = false) => {
    setConfirmDialog({
      title: 'Check as Paid',
      description: 'Are you sure?',
      open: true,
      confirm: () => {
        setConfirmDialog({ open: false });
        request({
          action: Modules.newPayments.actions.update,
          data: {
            id: paymentId,
            failed_resolved_at: resolved ? null : formatDateToApi(new Date()),
          },
          options: {
            onSuccess: () => {
              dispatch(notifications.success(t('payment.paid.success')));
              getReport();
            },
          },
        });
      },
    });
  };

  const columns = [
    {
      name: 'ID',
      selector: 'id',
      sortable: true,
      width: '80px',
      cell: (row) => <Typography style={fontStyle.small}>{row?.id}</Typography>,
    },
    {
      name: 'User Name',
      selector: (row) => row?.user?.name,
      sortable: true,
      cell: (row) => (
        <Box>
          <Link
            className="hover"
            onClick={() => history.push(`/channel/manage/${channel?.id}/members/${row?.user?.id}`)}
          >
            <Typography style={fontStyle.small}>{row.user?.name}</Typography>
          </Link>

          {row.ownerable && row.ownerable.user && row?.user?.id !== row.ownerable.user?.id && (
            <Typography style={fontStyle.small} ml={2} color="textSecondary">
              <Link
                className="link-hover"
                onClick={() =>
                  history.push(`/channel/manage/${channel?.id}/members/${row?.user?.id}`)
                }
              >
                <Typography style={fontStyle.small}>{row.ownerable.user.name}</Typography>
              </Link>
            </Typography>
          )}
        </Box>
      ),
    },
    {
      name: 'Type',
      selector: 'kind',
      sortable: true,
      cell: (row) => <PaymentTypes payment={row} />,
    },
    {
      name: 'Date',
      selector: 'createdAt',
      cell: (row) => (
        <Typography style={fontStyle.medium}>{`${date(
          row.createdAt,
          channel?.dateFormatReact,
        )}`}</Typography>
      ),
      sortable: true,
      center: true,
    },
    {
      name: 'Processed',
      selector: 'confirmedAt',
      cell: (row) => (
        <Typography style={fontStyle.medium}>
          {row.confirmedAt && `${date(row.confirmedAt, channel?.dateFormatReact)}`}
        </Typography>
      ),
      sortable: true,
      center: true,
    },
    {
      name: 'Due',
      selector: 'dueAt',
      cell: (row) => (
        <Typography style={fontStyle.medium}>
          {row.dueAt && date(row.dueAt, channel?.dateFormatReact)}
        </Typography>
      ),
      sortable: true,
      center: true,
    },
    {
      name: 'Method',
      selector: 'methodType',
      sortable: true,
      cell: (row) => (
        <Typography style={fontStyle.medium}>{row.methodType?.toUpperCase()}</Typography>
      ),
    },
    {
      name: 'Status',
      selector: 'status',
      sortable: true,
      cell: (row) => <PaymentStatusColumn row={row} />,
    },
    {
      name: 'Belongs',
      selector: 'mainPaymentId',
      sortable: true,
      width: '50px',
      cell: (row) => <Typography style={fontStyle.small}>{row?.mainPaymentId}</Typography>,
    },
    {
      name: 'Charge',
      selector: 'charge',
      sortable: true,
      right: true,
      cell: (row) => (
        <Typography style={fontStyle.medium}>
          {row.currencySymbol}
          {row.amount}&nbsp;
          {row.currencyCode}
        </Typography>
      ),
    },
    {
      name: 'Fees',
      selector: 'applicationFee',
      sortable: true,
      right: true,
      cell: (row) => (
        <Typography style={fontStyle.medium}>
          {row.currencySymbol}
          {row.applicationFee}&nbsp;
          {row.currencyCode}
        </Typography>
      ),
    },
  ];

  if (hasSalesTax) {
    columns.push({
      name: 'SalesTax',
      selector: 'SalesTax',
      sortable: true,
      right: true,
      cell: (row) => (
        <Typography style={fontStyle.medium}>
          {row.currencySymbol}
          {row.taxAmount}&nbsp;
          {row.currencyCode}
        </Typography>
      ),
    });
  }
  columns.push({
    name: 'Revenue',
    selector: 'revenue',
    sortable: true,
    right: true,
    cell: (row) => (
      <Typography style={fontStyle.medium}>
        {row.currencySymbol}
        {row.revenue}&nbsp;
        {row.currencyCode}
      </Typography>
    ),
  });

  if (failedTransactions) {
    columns.push({
      name: 'Resolved',
      selector: 'failedResolvedAt',
      sortable: true,
      center: true,
      cell: (row) => (
        <Typography style={fontStyle.medium}>{row.failedResolvedAt ? 'Yes' : 'No'}</Typography>
      ),
    });
  }

  if (!hideActions) {
    columns.push({
      name: '',
      center: true,
      cell: (row) => (
        <Box mr={2}>
          <MenuActions
            loading={loadingRefund}
            row={row}
            actions={{
              refundPayment,
              removePayment,
              sendReceipt,
              downloadReceipt,
              checkAsResolved,
              sendStepVerification,
              checkAsPaid,
              changeType: () => setOpenModalChange({ open: true, payment: row }),
            }}
          />
        </Box>
      ),
    });
  }

  const ExpanableComponent = ({ data: dataExpanded }) => (
    <>
      <Box p={3}>
        <Typography component="h1" align="center" m={5}>
          {dataExpanded.summary}
        </Typography>

        {dataExpanded?.kind === 'channel_store_product' && (
          <StoreOrderItems
            currency={dataExpanded?.currency}
            items={dataExpanded?.storeOrder?.items || []}
          />
        )}

        {dataExpanded?.holdPayments && (
          <Box p={3}>
            <ResultPayments title="Hold Payments" rows={dataExpanded?.holdPayments} />
          </Box>
        )}

        {dataExpanded?.uncapturedRefunds && (
          <Box p={3}>
            <ResultPayments title="Uncaptured Refunds" rows={dataExpanded?.uncapturedRefunds} />
          </Box>
        )}

        {dataExpanded.userCard && (
          <Box display="flex" justifyContent="center">
            <Typography mb={3}>Card:</Typography>
            <Typography ml={1} color="primary">
              {dataExpanded.userCard.toUpperCase()}
            </Typography>
          </Box>
        )}
        {dataExpanded.offline && (
          <Box display="flex" justifyContent="center">
            <Typography mb={3}>Offline Type:</Typography>
            <Typography ml={1} color="primary">
              {dataExpanded.offlineType && dataExpanded.offlineType.toUpperCase()}
            </Typography>
          </Box>
        )}
      </Box>
      <Divider mt={2} />
    </>
  );

  return (
    <Paper style={{ width: '100%' }}>
      <ConfirmDialog
        open={confirmDialog.open}
        confirm={() => confirmDialog.confirm()}
        cancel={() => setConfirmDialog({ open: false })}
        description={confirmDialog?.description || t('payment.refund.desc')}
        title={confirmDialog?.title || t('payment.refund.title')}
        labelConfirm={t('button.confirm')}
        labelCancel={t('button.cancel')}
        loading={loading}
      />

      {openModalRetry.open && (
        <ModalRetryPayment
          payment={openModalRetry.payment}
          userId={openModalRetry.userId}
          callback={() => {
            getReport();
            setOpenModalRetry({ open: false });
          }}
          open
          close={() => setOpenModalRetry({ open: false })}
        />
      )}
      {modalRefund.open && (
        <ModalRefundPayment
          payment={modalRefund.payment}
          callback={() => {
            getReport();
            setModalRefund({ open: false });
          }}
          open
          close={() => setModalRefund({ open: false })}
        />
      )}
      {openModalChange.open && (
        <ModalChangeType
          payment={openModalChange.payment}
          callback={() => {
            getReport();
            setOpenModalChange({ open: false });
          }}
          open
          close={() => setOpenModalChange({ open: false })}
        />
      )}

      <SFDataTable
        title={title || t('channel.reports.payments')}
        columns={columns}
        data={rows}
        pagination={!!pagination}
        showExport={false}
        noHeader={noHeader}
        expandableRows
        expandableRowsComponent={<ExpanableComponent />}
        paginationPerPage={perPage}
        onChangePage={(p) => getReport(p)}
        paginationServer
        paginationDefaultPage={pagination?.currentPage}
        paginationTotalRows={pagination?.totalCount}
        onChangeRowsPerPage={(newValue) => {
          if (setPerPage) {
            setPerPage(newValue);
          }
        }}
        paginationRowsPerPageOptions={setPerPage ? [5, 10, 15, 20, 30, 50, 100] : [10]}
      />
    </Paper>
  );
}
