import React from 'react';
import Box from 'core/ui/Box';
import { Skeleton } from '@material-ui/lab';

const SkeletonAtRiskMembersReport = () => (
  <Box mt={5}>
    <Box mt={3} display="flex">
      <Skeleton variant="rect" animation="wave" style={{ height: 200, width: '100%' }} />
    </Box>
    <Box mt={3} display="flex">
      <Skeleton variant="rect" animation="wave" style={{ height: 200, width: '100%' }} />
    </Box>
  </Box>
);

export default SkeletonAtRiskMembersReport;
