import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from '@material-ui/core';
import { LayoutContext } from 'pages/shared/Layout';
import { useParams } from 'react-router';
import WatchTeam from 'components/Competition/Workout/Watch/Team';
import { workoutsActions, workoutsSelectors } from 'modules/workouts';
import { competitionsActions, competitionsSelectors } from 'modules/competitions';
import Background from 'core/ui/Background';
import Paper from 'core/ui/Paper';
import { Skeleton } from '@material-ui/lab';
import useDeepLinks, { AppScreens } from 'core/useDeepLinks';
import BannerCompetition from 'components/Competition/Show/BannerCompetition';

const CompetitionWatchTeam = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const layoutContext = useContext(LayoutContext);
  const workout = useSelector((state) => workoutsSelectors.getWorkout(state));
  const loadingWorkout = useSelector((state) => workoutsSelectors.getLoading(state));

  const loadingCompetition = useSelector((state) => competitionsSelectors.getLoading(state));
  const competition = useSelector((state) => competitionsSelectors.getCompetition(state));
  const { workoutId, competitionId, userId } = useParams();

  const deep = useDeepLinks();

  useEffect(() => {
    deep.openWatchCompScreen({ screen: AppScreens.Competition.Watch, workoutId, competitionId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(workoutsActions.getWorkoutStart(workoutId));
    return () => {
      dispatch(workoutsActions.clearWorkout());
    };
  }, [dispatch, workoutId]);

  useEffect(() => {
    dispatch(competitionsActions.getCompetitionStart(competitionId));
    return () => {
      dispatch(competitionsActions.clearCompetition());
    };
  }, [dispatch, competitionId]);

  useEffect(() => {
    layoutContext.setTitleBar(
      t(competition.isTeam ? 'title.watchTeam' : 'title.watchCompetitor').toUpperCase(),
    );
    layoutContext.setActiveMenu('');
  }, [layoutContext, t, competition]);

  return (
    <Background
      hexBgColor={competition?.backgroundColor}
      imageUrl={competition?.backgroundImage?.image?.url}
      heightBanner={0}
      heightHeader={70}
    >
      <Container maxWidth="xl">
        <Box pt={5} mb={1}>
          {workout && workout.id ? (
            <WatchTeam
              competition={competition}
              workout={workout}
              loading={loadingWorkout || loadingCompetition}
              userId={userId}
            />
          ) : (
            <>
              <Skeleton variant="rect" style={{ width: '100%', height: 200 }} />
              <Skeleton variant="rect" style={{ width: '100%', height: 250, marginTop: 3 }} />
            </>
          )}
        </Box>
        <Paper className="paper-rounded opacity-80" p={3}>
          <Box style={{ position: 'relative' }}>
            <BannerCompetition competition={competition} />
          </Box>
        </Paper>
      </Container>
    </Background>
  );
};

export default CompetitionWatchTeam;
