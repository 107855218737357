import React, { useEffect, useState } from 'react';
import Box from 'core/ui/Box';
import Typography, { fontStyle } from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import Paper from 'core/ui/Paper';
import Grid from 'core/ui/Grid';
import { VerifiedUser } from '@material-ui/icons';
import { onlineColor, secondary, successColor } from 'core/ui/Colors';
import { workoutsActions, workoutsSelectors } from 'modules/workouts';
import { useDispatch, useSelector } from 'react-redux';
import LoaderSm from 'core/ui/LoaderSm';
import { useHistory } from 'react-router';
import Button, { ButtonCompetition } from 'core/ui/Button';
import { Skeleton } from '@material-ui/lab';
import WorkoutScoresPoints from 'components/Competition/Show/Tabs/Results/Tables/WorkoutScoresPoints';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import { sessionSelectors } from 'modules/session';
import useWorkoutWatch from './useWorkoutWatch';
import HeaderPlayer from './HeaderPlayer';
import VideoTeam from './VideoTeam';
import WorkoutPenalty from '../Judge/WorkoutPenalty';
import Popover from './Flags/Popover';

const CompetitionWatchTeam = ({ workout, competition, userId }) => {
  const { t } = useTranslation();
  const leaderboard = useSelector((state) => workoutsSelectors.getWorkoutSectionLeaderboard(state));
  const dispatch = useDispatch();
  const { request } = useActions();
  const history = useHistory();
  const currentUser = useSelector((state) => sessionSelectors.getUser(state));

  const [leaderboardUser, setLeaderboardUser] = useState(null);

  const mySelf = !!currentUser?.id && currentUser?.id === leaderboardUser?.user?.id;

  const showPointsRank = workout?.showScores || competition?.isCoach || competition?.isJudge;

  const allowWatch = mySelf || (workout?.allowWatch && workout.hasRecordings);

  useEffect(() => {
    if (workout && workout.id && competition.id) {
      request({
        action: Modules.newWorkouts.actions.getLeaderboardUser,
        data: {
          workoutId: workout?.id,
          sectionId: 'first',
          userId,
        },
        options: {
          onSuccess: (data) => {
            if (data?.length > 0) {
              setLeaderboardUser(data[0]);
            }
          },
        },
      });
    }
  }, [history, dispatch, workout, competition, userId, request]);

  useEffect(() => {
    if (workout && workout.id && competition.id && !allowWatch) {
      // history.push(`/competition/view/${competition.id}`);
    }
  }, [history, dispatch, workout, competition, allowWatch]);

  const workoutSectionUserData = useSelector((state) =>
    workoutsSelectors.getWorkoutSectionUserData(state),
  );
  const loadingWorkoutSection = useSelector((state) =>
    workoutsSelectors.getLoadingWorkoutSection(state),
  );
  const section = workout.id ? workout.sections[0] : {};
  const userSection = workoutSectionUserData[section.id];
  const isVerified = userSection && userSection.verified;

  const player = useWorkoutWatch({ judge: true, workout });

  const videoTeam = workout.recordingVideos.find((x) => x.user.id === Number(userId));
  const penalties = (userSection && userSection.penalties) || [];

  const refreshSectionUser = () => {
    if (!leaderboard) {
      return;
    }
    dispatch(
      workoutsActions.getWorkoutSectionUserStart({
        workoutId: workout.id,
        userId,
      }),
    );
  };

  useEffect(() => {
    if (videoTeam && videoTeam.durationSeconds) {
      player.setTime((prev) => ({ ...prev, total: videoTeam.durationSeconds }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoTeam]);

  useEffect(() => {
    refreshSectionUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onBackClick = () => {
    history.push(`/competition/view/${competition.id}`);
  };

  if (!leaderboardUser) {
    return (
      <>
        <Skeleton variant="rect" style={{ width: '100%', height: 200 }} />
        <Skeleton variant="rect" style={{ width: '100%', height: 250, marginTop: 3 }} />
      </>
    );
  }

  return (
    <>
      <Paper className="paper-rounded opacity-80" p={3} mb={1}>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1} pl={5}>
            <Typography variant="h5" color="secondary">
              {competition.name}
            </Typography>
          </Box>
          <ButtonCompetition
            style={{ backgroundColor: competition.buttonColor }}
            onClick={() => onBackClick()}
          >
            {t('button.backToCompetition')}
          </ButtonCompetition>
        </Box>
      </Paper>
      {!allowWatch ? (
        <Typography m={5} variant="h5">
          {t('competition.watch.disabled')}
        </Typography>
      ) : (
        <>
          <HeaderPlayer
            player={player}
            buttonColor={competition?.buttonColor}
            competition={competition}
            workout={workout}
            marks={[{ value: videoTeam?.startCountdownSeconds, label: 'Workout' }]}
            startCountdownSeconds={videoTeam?.startCountdownSeconds}
          />

          <Paper mt={1} className="paper-rounded opacity-80" p={3}>
            <Grid container>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Box style={{ position: 'relative' }}>
                  {showPointsRank && (
                    <Box style={{ position: 'absolute', right: 2, top: 10, zIndex: 999 }}>
                      {leaderboardUser.verified ? (
                        <Box>
                          <Button
                            style={{
                              backgroundColor: onlineColor,
                              padding: 5,
                              minWidth: 30,
                              borderRadius: 10,
                            }}
                          >
                            <Typography mr={1} color="secondary" variant="h6">
                              {t('button.verified').toUpperCase()}
                            </Typography>
                            <VerifiedUser style={{ color: 'white', fontSize: 20 }} />
                          </Button>
                        </Box>
                      ) : (
                        <Popover
                          buttonColor={competition.buttonColor}
                          workoutId={workout.id}
                          userId={userId}
                          sectionId={section.id}
                        />
                      )}
                    </Box>
                  )}
                  <VideoTeam
                    seek={player.seek}
                    videoTeam={videoTeam || {}}
                    startCountdownSeconds={videoTeam?.startCountdownSeconds}
                    playing={player.playing}
                    setReady={() => player.setVideoReady(videoTeam.user.id)}
                    setSeek={(newSeek) =>
                      player.setTime((prev) => ({ ...prev, complete: newSeek }))
                    }
                    muted={player.muted}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} pl={3}>
                {showPointsRank && (
                  <Paper style={{ height: '100%' }} className="paper-rounded opacity-80" p={3}>
                    <Box display="flex" style={{ height: '100%' }} flexDirection="column">
                      <LoaderSm loading={loadingWorkoutSection} />
                      <Box flexGrow={1}>
                        <Box display="flex" mb={3} alignItems="center">
                          <VerifiedUser style={{ color: isVerified ? successColor : secondary }} />
                          {leaderboardUser.user &&
                            leaderboardUser.user.team &&
                            leaderboardUser.user.team.name && (
                              <Typography ml={1} variant="h5">
                                {leaderboardUser.user.team.name.toUpperCase()}
                              </Typography>
                            )}
                        </Box>

                        <Box mb={3} display="flex" alignItems="center">
                          <Typography variant="h6">
                            {t('workout.judge.currentScore').toUpperCase()}
                          </Typography>

                          <WorkoutScoresPoints
                            sectionScores={leaderboardUser?.sectionScores}
                            scores={leaderboardUser?.scores}
                            positions={leaderboardUser?.positions || {}}
                            showVariation={workout.competitionUseRxScaledScores}
                            buttonColor={competition?.buttonColor}
                          />
                        </Box>
                        {leaderboardUser.revisedScores && (
                          <Box mb={3} display="flex" alignItems="center">
                            <Typography variant="h6">
                              {t('workout.judge.revisedScore').toUpperCase()}
                            </Typography>

                            <WorkoutScoresPoints
                              sectionScores={leaderboardUser?.sectionScores}
                              scores={leaderboardUser?.revisedScores}
                              positions={leaderboardUser?.positions || {}}
                              showVariation={workout.competitionUseRxScaledScores}
                              buttonColor={competition?.buttonColor}
                            />
                          </Box>
                        )}
                        <Box mb={3}>
                          <Typography variant="h6">
                            {t('workout.judge.penalties').toUpperCase()}
                          </Typography>
                          <Box mb={3}>
                            {userSection && penalties && (
                              <>
                                {penalties.map((p, index) => (
                                  <Box key={index.toString()}>
                                    <WorkoutPenalty fontValue={fontStyle.large} penalty={p} />
                                  </Box>
                                ))}
                              </>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Paper>
                )}
              </Grid>
            </Grid>
          </Paper>
        </>
      )}
    </>
  );
};

export default CompetitionWatchTeam;
