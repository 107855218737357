import { makeStyles } from '@material-ui/core';
import { primary } from 'core/ui/Colors';

const VIDEO_WIDTH = 340;
const VIDEO_MOBILE_WIDTH = 270;

export const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: VIDEO_MOBILE_WIDTH,

    [theme.breakpoints.up('md')]: {
      maxWidth: VIDEO_WIDTH,
    },
  },
  videoWrapper: {
    position: 'relative',
    width: VIDEO_MOBILE_WIDTH,
    aspectRatio: 16 / 9,
    background: '#f3f4f6',

    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: primary,

    [theme.breakpoints.up('md')]: {
      width: VIDEO_WIDTH,
    },
  },
  title: {
    textAlign: 'center',
    color: primary,
    marginTop: 8,
    wordBreak: 'break-word',
  },
}));
