import api from 'core/api';

export const channelsCoachesSchedulesModule = 'channelsCoachesSchedules';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const loadingSchedulesStates = {
  start: { loadingSchedules: true },
  error: { loadingSchedules: false },
  success: { loadingSchedules: false },
};

const actions = {
  getCoachesSchedules: {
    module: channelsCoachesSchedulesModule,
    name: 'getCoachesSchedules',
    api: (params) =>
      api.get(`/channels/${params?.channelId}/channels_coaches_schedules`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: ['data'],
    },
    state: loadingStates,
  },
  save: {
    module: channelsCoachesSchedulesModule,
    name: 'save',
    api: (data) => api.post(`/channels/${data.channelId}/channels_coaches_schedules`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingSchedulesStates,
  },
  update: {
    module: channelsCoachesSchedulesModule,
    name: 'update',
    api: (data) =>
      api.put(`/channels/${data.channelId}/channels_coaches_schedules/${data.scheduleId}`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingSchedulesStates,
  },
};

export default {
  actions,
  state: {
    loading: false,
    loadingSchedules: false,
    data: [],
  },
};
