/* eslint-disable import/prefer-default-export */
import { secondaryLight } from 'core/ui/Colors';

export const dropdownWrapper = {
  minHeight: 50,
  backgroundColor: 'white',
  border: `0.5px solid ${secondaryLight}`,
  borderRadius: 5,
  display: 'flex',
  alignItems: 'center',
  flex: 1,
};
