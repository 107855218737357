/* eslint-disable react/prop-types */
import React from 'react';
import Paper from 'core/ui/Paper';
import { useTranslation } from 'react-i18next';
import Typography from 'core/ui/Typography';
import SFDataTable from 'core/ui/DataTable';
import LabelPhone from 'core/ui/InputPhone/LabelPhone';
import { date } from 'core/utils/formatters';
import Grid from 'core/ui/Grid';
import Box from 'core/ui/Box';
import BarMonths from './BarMonths';

export default function ResultUsers({ channel, data }) {
  const { t } = useTranslation();
  const columns = [
    {
      name: 'User Name',
      selector: 'userName',
      sortable: true,
      cell: (row) => <Typography>{row.name}</Typography>,
    },
    {
      name: t('channel.memberships.column.phone'),
      selector: 'phone',
      sortable: true,
      width: '230px',
      cell: (member) => <>{member.phone && <LabelPhone value={member.phone} />}</>,
    },
    {
      name: t('channel.memberships.column.email'),
      selector: 'phone',
      sortable: true,
      width: '230px',
      cell: (member) => <Typography>{member.email}</Typography>,
    },
    {
      selector: 'leadDate',
      name: 'Lead Date',
      sortable: true,
      cell: (row) => (
        <Typography>{row.leadDate && date(row.leadDate, channel?.dateFormatReact)}</Typography>
      ),
    },
    {
      selector: 'membershipDate',
      name: 'Membership Date',
      sortable: true,
      cell: (row) => (
        <Typography>
          {row.membershipDate && date(row.membershipDate, channel?.dateFormatReact)}
        </Typography>
      ),
    },
    {
      selector: 'registerDate',
      name: 'Register Date',
      sortable: true,
      cell: (row) => (
        <Typography>
          {row.registerDate && date(row.registerDate, channel?.dateFormatReact)}
        </Typography>
      ),
    },
  ];
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Paper p={3}>
            <Typography variant="h5" mb={3}>
              New Leads
            </Typography>
            <Typography variant="h1">{data?.totalLeads}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper p={3}>
            <Typography variant="h5" mb={3}>
              New Members
            </Typography>
            <Typography variant="h1">{data?.totalMemberships}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper p={3}>
            <Typography variant="h5" mb={3}>
              New First Visits
            </Typography>
            <Typography variant="h1">{data?.totalVisits || '0'}</Typography>
          </Paper>
        </Grid>
      </Grid>
      <Paper style={{ width: '100%' }} my={3}>
        <SFDataTable
          title={t('channel.reports.leads')}
          columns={columns}
          data={data?.users}
          pagination
          showExport
        />
      </Paper>

      <Box>
        <BarMonths channelId={channel?.id} />
      </Box>
    </>
  );
}
