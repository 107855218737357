import React from 'react';

import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import Grid from 'core/ui/Grid';
import Paper from 'core/ui/Paper';
import Typography from 'core/ui/Typography';
import useWindowSize from 'core/utils/hook/useWindowSize';
import FormConfig from './FormConfig';
import ManageHeats from './Manage';
import ModalEditHeat from './ModalEditHeat';
import ModalCloneHeat from './ModalCloneHeat';

const HeatsManageContent = ({
  workout,
  buttonColor,
  fontColor,
  compUsers,

  heatsHook,
}) => {
  const { t } = useTranslation();

  const { height } = useWindowSize();

  const {
    competition,
    loadHeats,
    resetHeats,
    heats,

    leaderboard,
    heatsUsers,
    setHeatsUsers,
    changeHeat,
    modalHeatAction,
    setModalHeatAction,
    createNewHeat,
  } = heatsHook;

  const hasHeats = heats && heats.length > 0;

  return (
    <Box style={{ height: height - 200 }}>
      {hasHeats && (
        <>
          <ManageHeats
            heats={heats}
            leaderboard={leaderboard}
            competition={competition}
            workout={workout}
            buttonColor={buttonColor}
            heatsUsers={heatsUsers}
            loadHeats={loadHeats}
            resetHeats={resetHeats}
            setHeatsUsers={setHeatsUsers}
            createHeat={() => setModalHeatAction({ open: true, action: 'new' })}
            cloneHeat={() => setModalHeatAction({ open: true, action: 'clone' })}
            editHeat={(heat) => setModalHeatAction({ heat, open: true, action: 'edit' })}
            compUsers={compUsers}
          />

          {(modalHeatAction?.action === 'edit' || modalHeatAction?.action === 'new') && (
            <ModalEditHeat
              {...modalHeatAction}
              close={() => setModalHeatAction({})}
              changeHeat={changeHeat}
              createNewHeat={createNewHeat}
            />
          )}
          {modalHeatAction?.action === 'clone' && (
            <ModalCloneHeat
              {...modalHeatAction}
              workout={workout}
              close={() => setModalHeatAction({})}
            />
          )}
        </>
      )}

      {!hasHeats && (
        <Grid container spacing={5}>
          <Grid item xs={12} md={6} />
          <Grid item xs={12} md={6}>
            <Paper elevation={2} mb={3} className="paper-rounded opacity-80" p={3}>
              <Typography mb={3} variant="h5" color="secondary">
                {t('workout.heat.information').toUpperCase()}
              </Typography>
              <FormConfig
                workoutId={workout.id}
                buttonColor={buttonColor}
                fontColor={fontColor}
                callback={loadHeats}
              />
            </Paper>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default HeatsManageContent;
