import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form-v5';
import { useTranslation } from 'react-i18next';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import { convertTimerToApi, convertTimerToForm, validateTime } from 'core/utils/helpers';
import notifications from 'modules/notifications';
import { useDispatch } from 'react-redux';
import { typeTimer } from 'core/utils/consts';
import { benchmarkSectionsModule } from 'modules/benchmarkSections';
import useSelectors from 'modules/map/useSelectors';

const defaultTimerData = {
  rounds: 1,
  type: 'none',
  unlimied: false,
};

export default ({ callback, benchmark, channel }) => {
  const { request } = useActions();
  const dispatch = useDispatch();
  const [scoreData, setScoreData] = useState({});
  const [timerData, setTimerData] = useState(defaultTimerData);
  const { t } = useTranslation();
  const loading = useSelectors(benchmarkSectionsModule, 'loading');
  const { register, handleSubmit, setValue, watch, reset, errors } = useForm();

  const validate = () => {
    const errorsValidation = [];

    if (timerData.type !== 'none') {
      if (!timerData.type) {
        errorsValidation.push(t('workout.section.validate.timerType'));
      }

      if (timerData.rounds > 1 && timerData.customInterval) {
        timerData.customIntervalTimers.forEach((cT, index) => {
          if (!validateTime('work', cT)) {
            errorsValidation.push(`${index} - ${t('workout.section.validate.workTime')}`);
          }
        });
      } else if (!timerData.unlimited && !validateTime('work', timerData)) {
        errorsValidation.push(t('workout.section.validate.workTime'));
      }

      if (
        !timerData.rounds &&
        timerData.type !== typeTimer.amrap &&
        timerData.type !== typeTimer.forTime
      ) {
        errorsValidation.push(t('workout.section.validate.rounds'));
      }
    }

    if (errorsValidation.length > 0) {
      dispatch(notifications.warning(errorsValidation.join(' ')));
      return false;
    }
    return true;
  };

  const onSubmit = (values) => {
    if (!validate()) {
      return;
    }

    const { work, rest, timeStart, customIntervalTimers } = convertTimerToApi({
      timerData,
      isVideoClass: false,
    });

    const data = {
      ...values,
      movements: values?.movements?.map((x) => x.name || x),
      timerCapped: timerData.timerCapped,
      timer: {
        ...timerData,
        customIntervalTimers,
        work,
        rest,
        timeStart,
        count: timerData.count || 'UP',
      },
      scores: { ...scoreData },
      id: benchmark && benchmark.id,
      channelId: channel?.id,
    };

    request({
      action: data.id
        ? Modules.benchmarkSections.actions.putBenchmarkSection
        : Modules.benchmarkSections.actions.postBenchmarkSection,
      data,
      options: {
        onSuccess: () => {
          if (callback) {
            callback();
          } else {
            request({
              action: Modules.benchmarkSections.actions.getBenchmarkSections,
              data: { isSelector: true },
            });
          }
        },
      },
    });
  };

  useEffect(() => {
    register('name', { required: true });
    register('description', { required: true });
    register('benchmarkSectionCategoryId', { required: true });
    register('movements');
    register('active');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register]);

  useEffect(() => {
    if (benchmark && benchmark.id) {
      const bk = { ...benchmark };
      delete bk.movements;

      reset(bk);
      setTimeout(() => {
        if (benchmark.scores) {
          setScoreData({ ...benchmark.scores });
        }
        setValue('movements', benchmark.movements);
        if (benchmark.timer) {
          const newTimer = convertTimerToForm(benchmark.timer);
          setTimerData({ ...newTimer, timeCapped: benchmark.timeCapped });
        }
      }, 500);
    } else {
      setValue('active', true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [benchmark]);

  return {
    register,
    setValue,
    watch,
    reset,
    errors,
    onSubmit,
    handleSubmit,
    submitForm: handleSubmit(onSubmit),

    setScoreData,
    scoreData,
    timerData,
    setTimerData,
    loading,
  };
};
