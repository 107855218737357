import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Box from 'core/ui/Box';

import Header from 'components/Profile/Edit/Header';

import FormAthlete from 'components/Profile/Edit/Athlete/Form';
import { ButtonPrimary } from 'core/ui/Button';
import LoaderSm from 'core/ui/LoaderSm';
import { channelMembersActions, channelMembersSelectors } from 'modules/channelMembers';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form-v5';
import { useTranslation } from 'react-i18next';
import notifications from 'modules/notifications';
import Typography from 'core/ui/Typography';
import { replaceAll } from 'core/utils/helpers';

const FormMembers = ({ channelId, close, callbackSave }) => {
  const { t } = useTranslation();
  const { register, handleSubmit, errors, setValue, watch } = useForm({
    defaultValues: { defaultWeightImperial: true, defaultDistanceMiles: true },
  });
  const dispatch = useDispatch();
  const loading = useSelector((state) => channelMembersSelectors.getLoadingMember(state));
  const [image, setImage] = useState('');

  useEffect(() => {
    register({ name: 'interests' });
    register({ name: 'certifications' });
    register({ name: 'name' }, { required: true });
    register({ name: 'phone' }, { required: true });
    register({ name: 'localGym' }, { required: false });
    register({ name: 'instagram' }, { required: false });
    register({ name: 'referredBy' }, { required: false });
    register({ name: 'gender' }, { required: false });
    register({ name: 'birthday' });

    register({ name: 'emergencyName' });
    register({ name: 'emergencyEmail' });
    register({ name: 'emergencyPhone' });

    register({ name: 'weightDefault' });
    register({ name: 'defaultWeightImperial' });
    register({ name: 'defaultDistanceMiles' });

    register(
      { name: 'countryId' },
      {
        required: true,
        validate: (value) => Number(value) > 0,
      },
    );
  }, [register]);

  const onSubmit = (data) => {
    let instagram = '';
    if (data.instagram && data.instagram.indexOf('@') === -1) {
      instagram = `@${data.instagram}`;
    } else if (data.instagram) {
      instagram = data.instagram;
    }
    if (!channelId) {
      alert('Error to verify the channel');
      return;
    }
    const userData = {
      channelId,
      ...data,
      instagram,

      // eslint-disable-next-line no-nested-ternary
      photo: image ? (image.new === true ? image : null) : null,
    };
    dispatch(
      channelMembersActions.postAddMemberStart(userData, (respUserData) => {
        if (respUserData?.channelId === channelId) {
          dispatch(notifications.success(t('channel.member.added.success')));
        } else {
          dispatch(notifications.success(t('channel.member.request.success')));
        }
        if (callbackSave) {
          callbackSave(respUserData);
          close();
          return;
        }
        close();
      }),
    );
  };

  return (
    <>
      <Typography align="center" variant="h4" mb={2} mt={2}>
        {replaceAll(t('modal.add.member.title'), '#{user}', watch('name'))}
      </Typography>
      <Box flexGrow={1}>
        <Header
          errors={errors}
          loading={loading}
          setValue={setValue}
          setIsPhoneValid={() => {}}
          register={register}
          watch={watch}
          setImage={setImage}
          image={image}
          memberAdd
        />
        <Box mt={3}>
          <FormAthlete
            errors={errors}
            loading={loading}
            setValue={setValue}
            setIsPhoneValid={() => {}}
            register={register}
            watch={watch}
            memberAdd
          />
        </Box>

        <Box mb={5} display="flex" justifyContent="flex-end">
          <ButtonPrimary
            type="button"
            onClick={() => handleSubmit(onSubmit)()}
            size="small"
            disabled={loading}
            style={{ height: 30, width: 80, borderRadius: 5 }}
          >
            <LoaderSm loading={loading} width={20} />
            {t('button.save')}
          </ButtonPrimary>
        </Box>
      </Box>
    </>
  );
};

FormMembers.propTypes = {
  close: PropTypes.func.isRequired,
};

FormMembers.defaultProps = {};

export default FormMembers;
