import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import Box from 'core/ui/Box';
import Texture from 'core/ui/Texture';
import DatePickerRounded from 'core/ui/DatePickers/DatePickerRounded';
import { useTranslation } from 'react-i18next';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import { ButtonPrimary } from 'core/ui/Button';
import Typography from 'core/ui/Typography';
import { formatDateToApi } from 'core/utils/formatters';
import useSelectors from 'modules/map/useSelectors';
import { partnersProgramsModule } from 'modules/partnersPrograms';
import LoaderSm from 'core/ui/LoaderSm';
import notifications from 'modules/notifications';
import { useDispatch } from 'react-redux';

const ModalPartnerProgramCloneWeek = ({ open, close, partnersProgram, dateClone, callback }) => {
  const [date, setDate] = useState(null);
  const dispatch = useDispatch();
  const { request } = useActions();
  const { t } = useTranslation();
  const loading = useSelectors(partnersProgramsModule, 'loading');

  const confirmClone = () => {
    if (!date || !dateClone) {
      return;
    }
    request({
      action: Modules.partnersPrograms.actions.cloneWeekWorkouts,
      data: {
        partnersProgramId: partnersProgram?.id,
        partnerId: partnersProgram?.partnerId,
        dateClone,
        date: formatDateToApi(date),
      },
      options: {
        onSuccess: () => {
          dispatch(notifications.success(t('partnersProgram.week.cloned')));
          if (callback) {
            callback();
          }
        },
      },
    });
  };

  return (
    <Dialog open={open} onClose={close} maxWidth="xs" fullWidth scroll="body">
      <Texture>
        <Box p={5}>
          <Typography m={3} align="center">
            {t('button.cloneWeek').toUpperCase()}
          </Typography>
          <Box display="flex">
            <Box m={1} mb={3}>
              <DatePickerRounded
                name="scheduledAt"
                disablePast={false}
                type="datetime-local"
                label="Week"
                onChange={(v) => setDate(v)}
                value={date}
                required
                error={!date}
              />
            </Box>
          </Box>

          <ButtonPrimary disabled={loading} fullWidth onClick={() => confirmClone()}>
            {loading && <LoaderSm loading />}
            {t('button.confirm')}
          </ButtonPrimary>
        </Box>
      </Texture>
    </Dialog>
  );
};

ModalPartnerProgramCloneWeek.propTypes = {
  close: PropTypes.func,
};

ModalPartnerProgramCloneWeek.defaultProps = {
  close: () => {},
};

export default ModalPartnerProgramCloneWeek;
