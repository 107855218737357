import React, { useContext, useEffect, useState } from 'react';
import { BASE_URL } from 'core/env';
import { Skeleton } from '@material-ui/lab';
import Typography from 'core/ui/Typography';
import { IconButton, Link, Tooltip } from '@material-ui/core';
import { CloudDownload, Refresh } from '@material-ui/icons';
import CopyText from 'core/ui/CopyText';
import Divider from 'core/ui/Divider';
import LoaderSm from 'core/ui/LoaderSm';
import { CompetitionContext } from '../index';

const DivisionWorkoutCard = ({ story, withoutLogo, divisionCard, division, workout, perPage }) => {
  const formContext = useContext(CompetitionContext);
  const [link, setLink] = useState('');
  const [loading, setLoading] = useState(true);
  const { competition } = formContext;

  let urlImage = `${BASE_URL}/competition/${competition?.id}/leaderboard_image?per_page=${perPage || 10}`;
  // main_card=1&division=175&story=1
  if (story) {
    urlImage += `&story=1`;
  }

  if (division?.id) {
    urlImage += `&division=${division?.id}`;
  }

  if (workout?.id) {
    urlImage += `&workout=${workout?.id}`;
  }

  if (withoutLogo) {
    urlImage += `&logo_off=1`;
  }

  if (divisionCard) {
    urlImage += `&main_card=1`;
  }

  const renewLink = () => {
    const ts = new Date().getTime();
    setLoading(true);
    setLink(`${urlImage}&t=?${ts}`);
  };

  useEffect(() => {
    if (competition?.id) {
      renewLink();
    }
  }, [competition?.id]);

  return (
    <div>
      <div className="tw-mt-2 tw-flex tw-flex-row tw-items-center tw-justify-center tw-px-2">
        <div className="tw-ml-1 tw-flex-1">
          <Typography className="tw-font-semibold">{division?.name}</Typography>
          <Typography className="tw-font-semibold">
            {workout?.name || 'Overall Stadings'}
          </Typography>
        </div>

        <div className="tw-flex tw-flex-row tw-items-center tw-justify-center tw-gap-2">
          <Tooltip title="Download">
            <Link href={link} target="_blank" className="link">
              <IconButton size="small" onClick={() => renewLink()}>
                <CloudDownload />
              </IconButton>
            </Link>
          </Tooltip>
          <Divider orientation="vertical" mx={2} flexItem />

          <Tooltip title="Copy image link">
            <div>
              <CopyText fontSize={22} text={link} />
            </div>
          </Tooltip>
          <Divider orientation="vertical" mx={2} flexItem />
          <Tooltip title="Refresh">
            <IconButton size="small" onClick={() => renewLink()}>
              {loading ? <LoaderSm loading /> : <Refresh />}
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <div className="tw-relative tw-w-full tw-overflow-hidden tw-border-2 tw-border-gray-600 tw-p-2">
        {loading && (
          <div
            className={`tw-absolute tw-flex-1 ${story ? 'tw-aspect-[1080/1920]' : 'tw-aspect-square'} tw-w-full`}
          >
            <Skeleton variant="rect" animation="wave" style={{ height: '95%', width: '95%' }} />
          </div>
        )}
        {!!link && (
          <img
            className={`${story ? 'tw-aspect-[1080/1920]' : 'tw-aspect-square'} tw-w-full`}
            draggable="false"
            src={link}
            onLoad={() => setLoading(false)}
            alt=""
          />
        )}
      </div>
    </div>
  );
};

export default DivisionWorkoutCard;
