import React, { useEffect, useRef, useState } from 'react';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import { ButtonGroup, MenuList, MenuItem, ClickAwayListener, Popper } from '@material-ui/core';
import { Delete, Edit, FileCopy, Menu, OpenWith } from '@material-ui/icons';
import Paper from 'core/ui/Paper';
import { date, dateAdd } from 'core/utils/formatters';
import Typography from 'core/ui/Typography';
import { ButtonSecondary } from 'core/ui/Button';

const SectionMenu = ({ channel, actions, section, calendarInfo }) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const anchorRef = useRef();

  const [action, setAction] = useState();

  useEffect(() => {
    if (open) {
      setAction(null);
    }
  }, [open]);

  return (
    <Box>
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <Box>
          <ButtonGroup
            variant="contained"
            color="primary"
            ref={anchorRef}
            aria-label="split button"
          >
            <ButtonSecondary ref={anchorRef} onClick={() => setOpen(!open)}>
              <Menu style={{ color: 'white' }} />
            </ButtonSecondary>
          </ButtonGroup>

          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            style={{ zIndex: 9999 }}
            disablePortal
          >
            <Box>
              <Paper p={3}>
                {action ? (
                  <Box>
                    <Typography m={2} align="center">
                      {t(`label.${action}.section`)}
                    </Typography>
                    <MenuList id="split-button-menu">
                      {[...new Array(7)].map((indexDay, index) => (
                        <MenuItem
                          key={index.toString()}
                          style={{ fontSize: 18 }}
                          onClick={() => {
                            if (action === 'move') {
                              actions.moveSection(
                                date(dateAdd(calendarInfo?.start, index, 'days'), 'YYYY/MM/DD'),
                              );
                            } else {
                              actions.cloneSection(
                                date(dateAdd(calendarInfo?.start, index, 'days'), 'YYYY/MM/DD'),
                              );
                            }
                            setOpen(false);
                          }}
                        >
                          {date(
                            dateAdd(calendarInfo?.start, index, 'days'),
                            channel?.useUsDateFormat ? 'ddd M/DD' : 'ddd DD/M',
                          )}
                        </MenuItem>
                      ))}

                      <MenuItem
                        style={{ fontSize: 18 }}
                        onClick={() =>
                          actions?.setModalCloneDay({
                            open: true,
                            section,
                          })
                        }
                      >
                        Other
                      </MenuItem>
                    </MenuList>
                  </Box>
                ) : (
                  <MenuList id="split-button-menu">
                    <MenuItem
                      style={{ fontSize: 18 }}
                      onClick={() => {
                        actions.editSection();
                        setOpen(false);
                      }}
                    >
                      <Edit style={{ fontSize: 20 }} />
                      &nbsp;&nbsp;
                      {t('button.edit')}
                    </MenuItem>
                    <MenuItem
                      style={{ fontSize: 18 }}
                      onClick={() => {
                        setAction('clone');
                      }}
                    >
                      <FileCopy style={{ fontSize: 20 }} />
                      &nbsp;&nbsp;
                      {t('button.clone')}
                    </MenuItem>
                    <MenuItem
                      style={{ fontSize: 18 }}
                      onClick={() => {
                        setAction('move');
                      }}
                    >
                      <OpenWith style={{ fontSize: 20 }} />
                      &nbsp;&nbsp;
                      {t('button.move')}
                    </MenuItem>
                    <MenuItem
                      style={{ fontSize: 18 }}
                      onClick={() => {
                        actions.removeSection();
                        setOpen(false);
                      }}
                    >
                      <Delete style={{ fontSize: 20 }} />
                      &nbsp;&nbsp;
                      {t('button.remove')}
                    </MenuItem>
                  </MenuList>
                )}
              </Paper>
            </Box>
          </Popper>
        </Box>
      </ClickAwayListener>
    </Box>
  );
};

SectionMenu.propTypes = {};

SectionMenu.defaultProps = {};

export default SectionMenu;
