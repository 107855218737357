import { all, call, put } from 'redux-saga/effects';

import { getErrorMessage } from 'core/api/api-error';

import notifications from 'modules/notifications';

import * as api from './api';
import { advertisingsActions as actions } from './index';

// eslint-disable-next-line import/prefer-default-export
export function* getAdvertisings({ data }) {
  try {
    const response = yield call(api.advertisings);
    const advertisings = response.data;
    yield put(actions.getAdvertisingsSuccess(advertisings));
    if (data.callback) {
      data.callback(advertisings);
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.getAdvertisingsError(getErrorMessage(error))),
    ]);
  }
}

export function* postRegistration({ options }) {
  try {
    const response = yield call(api.registration, options.data);
    yield put(actions.postRegistrationSuccess());
    if (options.callback) {
      options.callback(response.data);
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.postRegistrationError(getErrorMessage(error))),
    ]);
  }
}

export function* postClick({ options }) {
  try {
    const response = yield call(api.click, options.data);
    yield put(actions.postClickSuccess());
    if (options.callback) {
      options.callback(response.data);
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.postClickError(getErrorMessage(error))),
    ]);
  }
}
