import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import DatePickerRounded from 'core/ui/DatePickers/DatePickerRounded';
import { ButtonPrimary } from 'core/ui/Button';
import { GetApp, Search } from '@material-ui/icons';
import { channelCurrency, formatDate, formatDateToApi } from 'core/utils/formatters';
import { timeZoneName } from 'core/utils/formatters/date';
import Paper from 'core/ui/Paper';
import Modules from 'modules';
import useActions from 'modules/map/useActions';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import useSelectors from 'modules/map/useSelectors';
import { newCompetitionsModule } from 'modules/competitions/new';
import ResultPayments from 'components/Channel/Manage/Tabs/Reports/Payments/ResultPayments';
import Grid from 'core/ui/Grid';
import StatisticCard from 'components/Channel/Manage/Tabs/ChannelDashboard/StatisticCard';

const SearchPayments = ({ competition }) => {
  const { t } = useTranslation();
  const dateNow = new Date();
  const month = dateNow.getMonth() + 1;
  const { request } = useActions();
  const loading = useSelectors(newCompetitionsModule, 'loadingPayments');
  const [perPage, setPerPage] = useState(10);
  const [pagination, setPagination] = useState(null);
  const [payments, setPayments] = useState([]);
  const [filters, setFilters] = useState({
    startAt: formatDate(`${month > 12 ? 12 : month}/1/${dateNow.getFullYear()}`, 'MM/DD/YYYY'),
    endAt: formatDate(new Date(), 'MM/DD/YYYY'),
  });

  const search = ({ page = 1, exportReport = false } = {}) => {
    const startAt = filters.startAt ? formatDateToApi(filters.startAt, timeZoneName()) : null;

    const endAt = filters.endAt ? formatDateToApi(filters.endAt, timeZoneName()) : null;
    if (startAt && endAt) {
      request({
        action: Modules.newCompetitions.actions.getCompetitionPayments,
        data: {
          ...filters,
          startAt,
          endAt,
          page,
          perPage,
          exportReport,
          competitionId: competition?.id,
        },
        options: {
          onSuccess: (data, pag) => {
            setPayments(data);
            setPagination(pag);
          },
        },
      });
    }
  };

  useEffect(() => {
    if (competition?.id) {
      search();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [competition, perPage]);

  return (
    <Box mt={2}>
      <Box>
        <Paper p={5} mb={3}>
          <Box display="flex" alignItems="center" flexWrap="wrap">
            <Box display="flex" mr={3}>
              <Box>
                <DatePickerRounded
                  label={t('channel.reports.filter.start').toUpperCase()}
                  onChange={(v) => setFilters((prev) => ({ ...prev, startAt: v }))}
                  value={filters.startAt}
                  disablePast={false}
                  autoOk
                  bordered
                />
              </Box>
              <Box ml={1}>
                <DatePickerRounded
                  label={t('channel.reports.filter.end').toUpperCase()}
                  onChange={(v) => setFilters((prev) => ({ ...prev, endAt: v }))}
                  value={filters.endAt}
                  disablePast={false}
                  autoOk
                  bordered
                />
              </Box>
              <Box ml={1}>
                <TextFieldRounded
                  label={t('label.userName').toUpperCase()}
                  value={filters?.userName}
                  onChange={(v) => setFilters((prev) => ({ ...prev, userName: v }))}
                  bordered
                />
              </Box>
              {filters.kind === 'store' && (
                <Box ml={1}>
                  <TextFieldRounded
                    label={t('label.productName').toUpperCase()}
                    value={filters?.productName}
                    onChange={(v) => setFilters((prev) => ({ ...prev, productName: v }))}
                    bordered
                  />
                </Box>
              )}
            </Box>

            <Box ml={3} pt={5}>
              <ButtonPrimary disabled={loading} onClick={() => search()}>
                <Search style={{ color: 'white' }} fontSize="small" />
                {t('button.search')}&nbsp;
              </ButtonPrimary>
            </Box>
            <Box ml={3} pt={5}>
              <ButtonPrimary
                disabled={loading}
                onClick={() => search({ page: 1, exportReport: true })}
              >
                <GetApp style={{ color: 'white' }} fontSize="small" />
                {t('button.exportCsv')}&nbsp;
              </ButtonPrimary>
            </Box>
          </Box>
        </Paper>
      </Box>

      <Grid container spacing={5} my={5}>
        <Grid item xs={12} lg={4} xl={3}>
          <StatisticCard
            icon="money"
            label="Total Revenue"
            loading={loading}
            amount={channelCurrency(payments?.totalRevenue || 0, competition?.currency)}
            iconColor="white"
            colorBgIcon={competition?.buttonColor}
          />
        </Grid>
      </Grid>

      <ResultPayments
        pagination={pagination}
        rows={payments?.payments || []}
        getReport={(p) => search({ page: p || 1 })}
        channel={competition?.channel || competition}
        perPage={perPage}
        setPerPage={setPerPage}
      />
    </Box>
  );
};

export default SearchPayments;
