/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Box from 'core/ui/Box';
import {
  BarChart,
  Bar,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from 'recharts';
import Checkbox from 'core/ui/Checkbox';
import Typography from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import useSelectors from 'modules/map/useSelectors';
import { channelReportsModule } from 'modules/channelReports';
import Paper from 'core/ui/Paper';
import LoaderSm from 'core/ui/LoaderSm';

const optionsInfo = ['memberships', 'store', 'offline', 'competitions', 'playLists'];

const BarMonths = ({ channelId }) => {
  const { t } = useTranslation();
  const chartData = useSelectors(channelReportsModule, 'chartData');
  const loadingChart = useSelectors(channelReportsModule, 'loadingChart');
  const { request } = useActions();

  const getReportChart = (params = {}) => {
    request({
      action: Modules.channelReports.actions.paymentsChart,
      data: {
        channelId,
        report: 'PaymentsChart',
        ...params,
      },
    });
  };

  useEffect(() => {
    if (channelId) {
      getReportChart();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelId]);

  const [info, setInfo] = useState(optionsInfo.slice(0, 4));

  const checkOption = (item) => {
    let newArray = [...info];
    if (item !== 'total') {
      newArray = info.filter((x) => x !== 'total');
    }
    if (info.find((x) => x === item)) {
      newArray = info.filter((x) => x !== item);
    } else {
      if (item === 'total') {
        setInfo([item]);
        return;
      }
      newArray = [...newArray, item];
    }
    setInfo(newArray);
  };

  const colorsChart = {
    memberships: '#269FFC',
    store: '#04BC84',
    offline: '#D28704',
    competitions: '#FD8080',
    playLists: '#94DAFB',
    revenue: '#FECC6D',
  };
  return (
    <>
      <Paper p={3}>
        <Typography variant="h5" mb={3} align="center">
          {t('channel.reports.summary.chartIncome12Months')} <LoaderSm loading={loadingChart} />
        </Typography>
        <Box display="flex" justifyContent="center">
          {optionsInfo.map((item) => (
            <Box mr={2} key={item}>
              <Checkbox
                label={item.toUpperCase()}
                onClick={() => checkOption(item)}
                checked={!!info.find((x) => x === item)}
              />
            </Box>
          ))}
        </Box>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart
            data={chartData}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" style={{ fontSize: 10 }} />
            <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
            <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
            <Tooltip />
            <Legend />
            {info.map((item) => (
              <Bar yAxisId="left" key={item} dataKey={item} fill={colorsChart[item]} />
            ))}
          </BarChart>
        </ResponsiveContainer>
      </Paper>
      <Paper my={3} p={3}>
        <Typography variant="h5" mb={3} align="center">
          {t('channel.reports.summary.chartRevenue12Months')} <LoaderSm loading={loadingChart} />
        </Typography>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart
            data={chartData}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" style={{ fontSize: 10 }} />
            <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
            <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
            <Tooltip />
            <Legend />

            <Bar yAxisId="left" dataKey="revenue" fill={colorsChart.revenue} />
          </BarChart>
        </ResponsiveContainer>
      </Paper>
    </>
  );
};

export default BarMonths;
