import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogContent, makeStyles } from '@material-ui/core';
import { useLocation } from 'react-router';
import { persistData, retrieveData } from 'core/utils/session-storage';
import { parse } from 'query-string';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import { ButtonPrimary } from 'core/ui/Button';
import { useSelector } from 'react-redux';
import { sessionSelectors } from 'modules/session';
import Typography from 'core/ui/Typography';
import { EventAvailable, EventBusy, Today } from '@material-ui/icons';
import Divider from 'core/ui/Divider';
import useSelectors from 'modules/map/useSelectors';
import { usersCalendarsModule } from 'modules/usersCalendars';
import LoaderSm from 'core/ui/LoaderSm';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#f3f3f3',
    minHeight: '100vh',
  },
}));

const CalendarCallback = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const location = useLocation();
  const [calendars, setCalendars] = useState([]);
  const loading = useSelectors(usersCalendarsModule, 'loadingExternalCalendar');
  const isAuthenticated = useSelector((state) => sessionSelectors.getIsAuthenticated(state));
  const [error, setError] = useState(null);
  const { request } = useActions();

  const getData = () => {
    const parsed = parse(location.search);
    const data = JSON.parse(retrieveData('EXTERNAL_CALENDAR_INTEGRATION') || '');
    request({
      action: Modules.usersCalendars.actions.confirmExternal,
      data: {
        ...data,
        code: parsed?.code,
      },
      options: {
        onSuccess: (resp) => {
          if (resp?.success) {
            setCalendars(resp?.calendars);
          }
        },
        onError: () => {
          setError('Error to confirm calandar code');
        },
      },
    });
  };

  useEffect(() => {
    if (isAuthenticated) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const saveCalendarId = (calendarId) => {
    const data = JSON.parse(retrieveData('EXTERNAL_CALENDAR_INTEGRATION') || '');

    if (!data?.ownerableId) {
      alert('Error, Calendar ID is blank.');
      return;
    }

    request({
      action: Modules.usersCalendars.actions.updateExternalCalendar,
      data: {
        ...data,
        calendarId,
      },
      options: {
        onSuccess: (resp) => {
          persistData('EXTERNAL_CALENDAR_INTEGRATION', null);

          window.location = resp?.calendarManageUrl;
        },
        onError: () => {
          setError('Error to confirm calandar code.');
        },
      },
    });
  };

  return (
    <div className={classes.root}>
      <Dialog open maxWidth="sm" fullWidth>
        <DialogContent>
          <LoaderSm loading={loading} center />
          <div className="tw-p-2">
            {error ? (
              <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-2">
                <EventBusy style={{ fontSize: 30 }} />
                <div className="tw-text-lg tw-text-red-500">{error}</div>
                <div>
                  <ButtonPrimary onClick={() => (window.location = '/')}>
                    {t('button.close')}
                  </ButtonPrimary>
                </div>
              </div>
            ) : (
              <div className="tw-flex tw-flex-col tw-gap-2">
                <div className="tw-mb-2 tw-flex tw-items-center tw-justify-center">
                  <EventAvailable style={{ fontSize: 30 }} />
                  <Typography align="center" variant="h5">
                    {t('label.chooseACalendar')}
                  </Typography>
                </div>
                <div className="tw-flex tw-w-full tw-flex-col tw-items-start tw-justify-start tw-gap-2">
                  {calendars?.map((item, idx) => (
                    <div key={item?.id} className="tw-w-full">
                      <div className="link-hover tw-mb-1" onClick={() => saveCalendarId(item?.id)}>
                        <div className="tw-flex tw-flex-row tw-items-start tw-justify-start tw-gap-2">
                          <Today />
                          <Typography>{item?.name}</Typography>
                        </div>
                      </div>
                      {calendars.length - 1 > idx && <Divider />}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CalendarCallback;
