import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form-v5';
import { useTranslation } from 'react-i18next';
import notifications from 'modules/notifications';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import { useDispatch } from 'react-redux';
import useSelectors from 'modules/map/useSelectors';
import { partnersModule } from 'modules/partners';

const useFormPartner = ({ partner, close, callbackSave }) => {
  const { t } = useTranslation();
  const { register, handleSubmit, errors, setValue, watch, reset } = useForm();
  const [image, setImage] = useState();
  const [taxByCountyStates, setTaxByCountyStates] = useState([]);
  const { request } = useActions();
  const loading = useSelectors(partnersModule, 'loading');
  const dispatch = useDispatch();

  useEffect(() => {
    register({ name: 'companyName' }, { required: true });
    register({ name: 'companyEmail' }, { required: true });
    register({ name: 'contactName' }, { required: true });
    register({ name: 'contactEmail' }, { required: false });
    register({ name: 'contactPhone' }, { required: false });
    register({ name: 'shippingEmail' }, { required: false });
    register({ name: 'details' });
    register({ name: 'active' });
    register({ name: 'useSalesTax' });
    register({ name: 'useUsDateFormat' });
    register({ name: 'taxByCounty' });
    register({ name: 'taxPercent' });
    register({ name: 'amounts.gymAmountType' });
    register({ name: 'amounts.gymAmount' });
    register({ name: 'amounts.streamAmountType' });
    register({ name: 'amounts.streamAmount' });
    register({ name: 'amounts.customerAmountType' });
    register({ name: 'amounts.customerAmount' });
    register({ name: 'allowChangeAmounts' });

    register({ name: 'countryId' }, { required: true });
    register({ name: 'city' }, { required: true });
    register({ name: 'state' }, { required: true });
    register({ name: 'street' }, { required: true });
    register({ name: 'zip' }, { required: true });
  }, [register]);

  useEffect(() => {
    if (partner?.id) {
      reset({ ...partner });

      setTaxByCountyStates(partner?.taxByCountyStates || []);
      setTimeout(() => {
        if (Number(partner?.taxPercent) > 0) {
          setValue('showTaxPercent', true);
        }
      }, 100);
    } else {
      setTimeout(() => {
        setValue('amounts.gymAmountType', '%');
        setValue('amounts.streamAmountType', '%');
        setValue('amounts.customerAmountType', '%');
      }, 30);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partner]);

  const onSubmit = (data) => {
    const partnerData = {
      id: partner?.id,
      ...data,
      taxByCountyStates,
      imageAttributes: image?.image
        ? {
            image: image?.image,
          }
        : null,
    };

    request({
      action: partner?.id ? Modules.partners.actions.update : Modules.partners.actions.create,
      data: partnerData,
      options: {
        onSuccess: (resp) => {
          dispatch(notifications.success(t('channel.member.added.success')));
          if (callbackSave) {
            callbackSave(resp);
            close();
            return;
          }
          close();
        },
      },
    });
  };

  return {
    partner,
    watch,
    setValue,
    submit: handleSubmit(onSubmit),
    errors,
    image,
    setImage,
    loading,
    taxByCountyStates,
    setTaxByCountyStates,
  };
};

useFormPartner.propTypes = {
  close: PropTypes.func.isRequired,
};

useFormPartner.defaultProps = {};

export default useFormPartner;
