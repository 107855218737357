import React, { useContext } from 'react';

import Box from 'core/ui/Box';
import Divider from 'core/ui/Divider';
import Paper from 'core/ui/Paper';
import { ShowContext } from '../..';
import CoachInfo from './CoachInfo';

export default () => {
  const showContext = useContext(ShowContext);

  return (
    <Paper elevation={2} className="paper-rounded" p={3}>
      <Box>
        <Box>
          {showContext.workout &&
            showContext.workout.coaches &&
            showContext.workout.coaches.map((coach, index) => (
              <Box key={index.toString()}>
                <Divider m={5} />
                <CoachInfo user={coach} />
              </Box>
            ))}
        </Box>
      </Box>
    </Paper>
  );
};
