import React, { useContext, useEffect, useState } from 'react';
import { benchmarkSectionsModule } from 'modules/benchmarkSections';
import { InputBase, makeStyles } from '@material-ui/core';
import Box from 'core/ui/Box';
import { InputWrapper } from 'core/ui/ChosenComponents';
import { useTranslation } from 'react-i18next';
import Paper from 'core/ui/Paper';
import Typography, { fontStyle } from 'core/ui/Typography';
import SFDataTable from 'core/ui/DataTable';
import { secondaryXLight } from 'core/ui/Colors';
import LoaderSm from 'core/ui/LoaderSm';
import { ButtonPrimary } from 'core/ui/Button';
import { LayoutContext } from 'pages/shared/Layout';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import useSelectors from 'modules/map/useSelectors';
import BenchmarkUserScores from './BenchmarkUserScores';
import ModalImportBenchmarksWorkouts from '../Imports/BenckmarksWorkouts/Modal';

const useStyles = makeStyles(() => ({
  rowHover: {
    width: '100%',
    paddingTop: 8,
    paddingBottom: 8,
    paddingRight: 5,
    paddingLeft: 5,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: secondaryXLight,
    },
  },
}));

const BenchmarkList = ({
  selectedBenchmark,
  setSelectedBenchmark,
  rowsPerPage,
  hideTitle,
  hideBestScore,
  onUsersButtonClick,
  onEditButtonClick,
  selector,
  channel,
  filter,
  refresh,
}) => {
  const { t } = useTranslation();
  const { request } = useActions();
  const [text, setText] = useState('');
  const layoutContext = useContext(LayoutContext);
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(rowsPerPage || 10);
  const data = useSelectors(benchmarkSectionsModule, 'data');
  const loading = useSelectors(benchmarkSectionsModule, 'loading');
  const pagination = useSelectors(benchmarkSectionsModule, 'pagination');

  const getBenchmarkSections = (p) => {
    request({
      action: Modules.benchmarkSections.actions.getBenchmarkSections,
      data: { isSelector: selector, page: p, perPage, text, channelId: channel?.id, filter },
    });
  };

  useEffect(() => {
    getBenchmarkSections(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage, channel, filter, refresh]);

  useEffect(() => {
    setPage(1);
    getBenchmarkSections(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text]);

  useEffect(() => {
    if (selectedBenchmark) {
      setSelectedBenchmark(data.find((x) => x.id === selectedBenchmark.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const columns = [
    {
      cell: (row) => (
        <Box display="flex" className={classes.rowHover} onClick={() => setSelectedBenchmark(row)}>
          <Box flexGrow={1}>
            <Typography style={fontStyle.medium}>{row.name}</Typography>
          </Box>
          <Box>
            {!hideBestScore && row.bestBenchmark && (
              <BenchmarkUserScores
                sectionScores={[row?.scores]}
                scores={row.bestBenchmark?.scores}
              />
            )}
          </Box>
        </Box>
      ),
    },
  ];

  if (onEditButtonClick) {
    columns.push({
      width: '100px',
      cell: (row) => (
        <>
          {row?.allowEdit && (
            <ButtonPrimary onClick={() => onEditButtonClick(row)}>{t('button.edit')}</ButtonPrimary>
          )}
        </>
      ),
    });
  }
  if (onUsersButtonClick) {
    columns.push({
      width: '100px',
      cell: (row) => (
        <ButtonPrimary onClick={() => onUsersButtonClick(row.id)}>
          {t('button.users')}
        </ButtonPrimary>
      ),
    });
  }

  const rows = data;

  return (
    <Box>
      <Box>
        <Box display="flex" alignItems="center">
          <InputWrapper>
            <Box display="flex" style={{ width: '100%' }}>
              <InputBase
                value={text}
                onChange={(v) => setText(v.target.value)}
                style={{ minWidth: 30, width: '100%', margin: '4px' }}
                placeholder={t('dashboard.benchmark.search')}
              />
            </Box>
          </InputWrapper>

          {!selector && (
            <Box ml={1}>
              <ModalImportBenchmarksWorkouts refresh={getBenchmarkSections} />
            </Box>
          )}
        </Box>
      </Box>
      <Paper className="paper-rounded" mt={1} py={3}>
        <Box display="flex" alignItems="center" mb={2}>
          {!hideTitle && (
            <Typography variant="h4" color="primary" mx={3}>
              {t('dashboard.tab.benchmarks')}
            </Typography>
          )}
        </Box>
        {loading && (
          <Box display="flex" justifyContent="center">
            <LoaderSm loading={loading} />
          </Box>
        )}

        <Box>
          <SFDataTable
            noHeader
            noTableHead
            paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
            title=""
            showPaper={false}
            columns={columns}
            data={rows}
            pagination
            paginationServer
            paginationPerPage={perPage || 10}
            paginationTotalRows={pagination?.totalCount}
            onChangePage={(p) => setPage(p)}
            onChangeRowsPerPage={(newValue) => {
              setPage(1);
              setPerPage(newValue);
            }}
            noDataComponent=""
          />
        </Box>

        {!!text && rows?.length === 0 && (
          <Box display="flex" alignItems="center" justifyContent="center" m={5}>
            <Typography>{t('benchmark.notFound.request')}</Typography>
            <Typography
              ml={1}
              color="primary"
              className="hover"
              onClick={() =>
                layoutContext.setModalSupport((prev) => ({
                  ...prev,
                  open: true,
                  requestBenchmark: true,
                }))
              }
            >
              {t('button.requestBenchmark')}
            </Typography>
          </Box>
        )}
      </Paper>
    </Box>
  );
};

export default BenchmarkList;
