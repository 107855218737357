import React, { useContext } from 'react';
import Box from 'core/ui/Box';
import { ButtonPrimary } from 'core/ui/Button';
import { useTranslation } from 'react-i18next';
import TablePlans from './TablePlans';
import ModalCoupons from './MembershipCoupons';
import { ChannelContext } from '../..';
import ModalForm from './ModalForm';
import useMembershipsForm from './useMembershipsForm';

export default () => {
  const { t } = useTranslation();
  const formContext = useContext(ChannelContext);
  const {
    modalForm,
    setModalForm,
    currentPlan,
    setCurrentPlan,
    setEditIndex,
    editIndex,
    setModalCoupons,
    changeActive,
    changeVisible,
    editPlan,
    changeOrderShow,
    modalCoupons,
    saveCoupon,
    changeCoupon,
    removeCoupon,
    createPlan,
  } = useMembershipsForm();

  return (
    <Box p={5}>
      <Box mb={4}>
        <>
          <ModalForm
            open={modalForm?.open}
            close={() => setModalForm({ open: false })}
            currentPlan={currentPlan}
            setCurrentPlan={setCurrentPlan}
            setEditIndex={setEditIndex}
            editIndex={editIndex}
            channel={formContext?.channel}
          />
          <Box display="flex" justifyContent="flex-end" m={1} mb={2}>
            <ButtonPrimary
              onClick={() => {
                createPlan();
              }}
            >
              {t('button.createMembershipPlan')}
            </ButtonPrimary>
          </Box>
          <Box>
            <TablePlans
              channel={formContext?.channel}
              setModalCoupons={setModalCoupons}
              membershipPlans={formContext.membershipPlans}
              changeActive={changeActive}
              changeVisible={changeVisible}
              editPlan={editPlan}
              changeOrderShow={changeOrderShow}
            />
          </Box>
        </>
      </Box>

      {modalCoupons.open && (
        <ModalCoupons
          open={modalCoupons.open}
          close={() => setModalCoupons((prev) => ({ ...prev, open: false }))}
          currentPlan={modalCoupons.currentPlan}
          saveCoupon={saveCoupon}
          channel={formContext.channel}
          changeCoupon={changeCoupon}
          removeCoupon={removeCoupon}
        />
      )}
    </Box>
  );
};
