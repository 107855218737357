import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from 'core/ui/Box';
import { primary, redColor, secondary } from 'core/ui/Colors';
import { Fade, Tab, Tabs, colors } from '@material-ui/core';
import { ButtonPrimary } from 'core/ui/Button';
import { useTranslation } from 'react-i18next';
import Divider from 'core/ui/Divider';
import { WorkflowContext } from 'contexts';
import Scrollbars from 'react-custom-scrollbars';
import Toggle from 'core/ui/Toggle';
import TypeSelector from './TypeSelector';
import TypeForm from './TypeForm';
import TypeHeader from './TypeHeader';
import WorkflowOverview from './WorkflowOverview';
import WorkflowExecutions from './WorkflowExecutions';
import ExecutionInfo from './Info';
import ModalAddUsers from './ModalAddUsers';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '6px 16px',
  },
  header: {
    backgroundColor: 'white',
    height: 54,
    flexGrow: 1,
    paddingTop: 3,
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  rightContent: {
    height: '100%',
    backgroundColor: 'white',
    borderLeft: `1px solid ${colors.grey[200]}`,

    [theme.breakpoints.down('md')]: {
      position: 'fixed',
      right: 0,
      zIndex: 9999,
      width: '100%',
    },
  },
  rightContentBottom: {
    [theme.breakpoints.down('md')]: {
      marginBottom: 90,
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: 155,
    },
  },
  cardManage: {
    zIndex: 9999,
    position: 'absolute',
    right: 2,
  },
}));

export default function Workflow({ allowEdit }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const workFlowContext = useContext(WorkflowContext);

  const [tab, setTab] = useState(0);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <Box display="flex" style={{ height: '100%', flex: 1, position: 'relative' }}>
      <Box flex={1} pb={30}>
        <Box display="flex" alignItems="center" style={{ position: 'relative' }}>
          <Tabs
            value={tab}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            className={classes.header}
            aria-label="scrollable auto tabs example"
          >
            <Tab label="Overview" />
            {allowEdit && workFlowContext?.workflow?.id && <Tab label="Executions" />}
          </Tabs>

          {allowEdit && (
            <Box className={classes.cardManage} mr={4}>
              <Toggle
                label=""
                options={[
                  { value: 'draft', label: 'Draft' },
                  { value: 'active', label: 'Active' },
                  { value: 'inactive', label: 'Inactive' },
                ]}
                buttonColor={primary}
                value={workFlowContext?.workflowData?.status}
                onChange={(v) =>
                  workFlowContext?.setWorkflowData((prev) => ({ ...prev, status: v }))
                }
              />
            </Box>
          )}
        </Box>
        <Divider />

        {workFlowContext?.workflow?.id && workFlowContext?.modalAddUsers && (
          <ModalAddUsers
            workflowId={workFlowContext?.workflow?.id}
            open={workFlowContext?.modalAddUsers}
            close={() => workFlowContext?.setModalAddUsers(false)}
            refreshWorkflow={() => {
              workFlowContext?.refreshWorkflow();
            }}
          />
        )}

        <Scrollbars
          allowFullScreen
          hidden={false}
          autoHide={false}
          hideTracksWhenNotNeeded={false}
          universal
        >
          {tab === 0 && <WorkflowOverview setTab={setTab} allowEdit={allowEdit} />}
          {tab === 1 && <WorkflowExecutions />}
        </Scrollbars>
      </Box>

      <Fade direction="left" in={workFlowContext.currentType?.open} mountOnEnter unmountOnExit>
        <Box
          flex={1}
          display="flex"
          flexDirection="column"
          className={classes.rightContent}
          pb={16}
        >
          <Box display="flex">
            <Box flexGrow={1} p={3}>
              <TypeHeader
                title={
                  // eslint-disable-next-line no-nested-ternary
                  workFlowContext.currentType?.executionsTypeUser
                    ? t('label.executionDetails')
                    : workFlowContext.currentType?.type === 'trigger'
                      ? t('label.triggers')
                      : t('label.actions')
                }
                cancel={() => workFlowContext.setCurrentType(() => ({ open: false }))}
              />
            </Box>
          </Box>
          <Divider mx={4} />
          <Box flexGrow={1} flex={1}>
            <Scrollbars
              allowFullScreen
              hidden={false}
              autoHide={false}
              hideTracksWhenNotNeeded={false}
              universal
            >
              {workFlowContext.currentType?.executionsTypeUser ? (
                <ExecutionInfo
                  channelId={workFlowContext?.channelId}
                  executionsTypesUser={workFlowContext.currentType?.executionsTypeUser}
                />
              ) : (
                <div>
                  {workFlowContext.currentType?.automationType ? (
                    <TypeForm
                      automationType={workFlowContext.currentType?.automationType}
                      changeType={(automationType) =>
                        workFlowContext.setCurrentType((prev) => ({ ...prev, automationType }))
                      }
                      cancel={() =>
                        workFlowContext.setCurrentType((prev) => ({
                          ...prev,
                          automationType: null,
                        }))
                      }
                    />
                  ) : (
                    <TypeSelector
                      onSelect={(automationType) =>
                        workFlowContext.setCurrentType((prev) => ({ ...prev, automationType }))
                      }
                      type={workFlowContext.currentType?.type}
                      cancel={() => workFlowContext.setCurrentType({ open: false })}
                    />
                  )}
                </div>
              )}
            </Scrollbars>
          </Box>
          {allowEdit && (
            <>
              <Divider mx={4} />
              <Box
                display="flex"
                justifyContent="flex-end"
                p={3}
                className={`gap-5 ${classes.rightContentBottom}`}
              >
                {workFlowContext?.currentType?.workflowType && (
                  <Box flexGrow={1}>
                    <ButtonPrimary
                      buttonColor={redColor}
                      onClick={() =>
                        workFlowContext.deleteWorkflowType(
                          workFlowContext?.currentType?.workflowType,
                        )
                      }
                    >
                      {t('button.delete')}
                    </ButtonPrimary>
                  </Box>
                )}
                <ButtonPrimary
                  buttonColor={secondary}
                  onClick={() => workFlowContext.setCurrentType(() => ({ open: false }))}
                >
                  {t('button.cancel')}
                </ButtonPrimary>
                {!workFlowContext.currentType?.executionsTypeUser && (
                  <ButtonPrimary onClick={() => workFlowContext.formType.submit()}>
                    {t('button.save')}
                  </ButtonPrimary>
                )}
              </Box>
            </>
          )}
        </Box>
      </Fade>
    </Box>
  );
}
