import api from 'core/api';

export const usersHistoryScoresModule = 'usersHistoryScores';

const loadingStates = {
  start: { loading: true, registerStatus: null },
  error: { loading: false },
  success: { loading: false },
};

const actions = {
  getCompareHistory: {
    module: usersHistoryScoresModule,
    name: 'getCompareHistory',
    api: (params) => api.get(`/users_history_scores/compare_history`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
    isTakeEvery: true,
  },
};

export default {
  actions,
  state: {
    loading: false,
  },
};
