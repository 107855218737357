import React, { useContext } from 'react';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import Typography, { fontStyle } from 'core/ui/Typography';
import { useSelector } from 'react-redux';
import { channelsSelectors } from 'modules/channels';

import MemberAppImg from 'assets/img/plan_member_app.png';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { successColor } from 'core/ui/Colors';
import { PlanManageContext } from 'contexts';
import { currency } from 'core/utils/formatters';
import Checkbox from 'core/ui/Checkbox';
import { styleBoxPlanImage, styleBoxPlanInfo } from './styles';

const MemberApp = ({ plan }) => {
  const { t } = useTranslation();
  const channel = useSelector((state) => channelsSelectors.getChannel(state));
  const planContext = useContext(PlanManageContext);

  const totalPlanOption = () => {
    const planAmount = planContext?.annualRenew ? plan.annualAmountMemberApp : plan.amountMemberApp;
    const planAmout = channel?.activePlans?.free?.active ? 0 : planAmount;
    return currency(planAmout);
  };

  const ItemPlan = ({ label }) => (
    <Box display="flex" alignItems="center">
      <CheckCircleOutlineIcon style={{ color: successColor, fontSize: 14 }} />
      <Typography ml={2} style={fontStyle.large} className="roboto-regular">
        {label}
      </Typography>
    </Box>
  );

  return (
    <Box p={5}>
      <Box display="flex" style={styleBoxPlanImage} justifyContent="center">
        <img src={MemberAppImg} alt="Profile" />
      </Box>

      <Typography my={2} align="center" variant="h5">
        {t('channel.plan.member_app')}
      </Typography>

      <Box style={styleBoxPlanInfo}>
        <Box style={{ width: 200 }}>
          <ItemPlan label={t('channel.plan.new.noBannerAds')} />
          <ItemPlan label={t('channel.plan.new.appStoreMemberApp')} />
          <ItemPlan label={t('channel.plan.new.customBackgrounds')} />
          <ItemPlan label={t('channel.plan.new.customColors')} />
          <ItemPlan label={t('channel.plan.new.streamlinedLook')} />
          <ItemPlan label={t('channel.plan.new.yourClassesOnly')} />
        </Box>
      </Box>

      <Box my={1} display="flex" alignItems="center" justifyContent="center">
        <Box my={1} display="flex" justifyContent="center">
          <Checkbox
            checked={planContext?.plans.find((x) => x.plan === 'member_app')}
            onClick={() =>
              planContext?.togglePlan('member_app', !channel?.activePlans?.memberApp?.active)
            }
            size={40}
            disabled={
              channel.showRenewButton ||
              (channel?.activePlans?.memberApp?.active && !channel?.activePlans?.memberApp?.renew)
            }
          />
        </Box>

        <Box display="flex" justifyContent="center" alignItems="flex-end">
          <Typography component="span" variant="h2" color="secondary">
            {t('prefix')}
            {totalPlanOption()}
          </Typography>
          <Typography
            component="span"
            variant="h2"
            pb={1}
            color="secondary"
            style={{ ...fontStyle.medium }}
          >
            /{t('channel.plan.new.month').toLowerCase()}
          </Typography>
        </Box>
      </Box>
      {channel?.activePlans?.memberApp?.active && !channel?.activePlans?.memberApp?.renew && (
        <Typography align="center" style={fontStyle.xSmall} color="textSecondary">
          {t('channel.plan.message.cancel')}
        </Typography>
      )}
    </Box>
  );
};

export default MemberApp;
