/* eslint-disable no-nested-ternary */
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from 'core/ui/Typography';
import Box from 'core/ui/Box';
import { ButtonPrimary } from 'core/ui/Button';
import { Link } from '@material-ui/core';
import { Context } from 'components/Login/Form';
import { useSelector } from 'react-redux';
import { sessionSelectors } from 'modules/session';
import TextConfirm from './TextConfirm';

const FormConfirmCode = () => {
  const { t } = useTranslation();
  const formContext = useContext(Context);
  const loading = useSelector((state) => sessionSelectors.getLoading(state));

  return (
    <div>
      <Typography
        className="roboto-regular"
        variant="h4"
        color="primary"
        mt={5}
        mb={10}
        align="center"
      >
        {t('login.verifyYourAccount')}
      </Typography>

      <Typography className="roboto-regular tw-text-gray-400" variant="h5" align="center" mb={2}>
        {formContext?.dataForm?.pin ? (
          <>{t('login.enterPinCode')}</>
        ) : (
          <>
            {t(
              formContext?.dataForm?.existsEmail
                ? 'login.enterCodeSendToEmail'
                : 'login.enterCodeSendToPhone',
            )}
          </>
        )}
      </Typography>

      <TextConfirm pin={formContext?.dataForm?.pin} />
      <Typography
        className="roboto-regular"
        m={3}
        color="primary"
        align="center"
        size={6}
        variant="caption"
        component="h6"
      >
        {formContext.errors.code && t('login.codeRequired')}
      </Typography>

      <Box p={5} mt={5}>
        <ButtonPrimary disabled={loading} type="submit" fullWidth variant="contained">
          {t('login.submit')}
        </ButtonPrimary>
      </Box>

      <Box mb={5}>
        {!formContext?.dataForm?.pin ? (
          <Typography className="roboto-regular" align="center">
            <Typography className="roboto-regular" variant="caption" component="span">
              {t('login.didNotGetACode')}
            </Typography>
            <Link className="link" onClick={() => formContext.reSendCode(true)}>
              <Typography
                className="roboto-regular"
                ml={1}
                color="primary"
                component="span"
                variant="caption"
              >
                <b>{t('login.resend')}</b>
              </Typography>
            </Link>
          </Typography>
        ) : (
          <Typography className="roboto-regular" align="center">
            <Link
              className="link"
              onClick={() => {
                formContext.reSendCode(true);
              }}
            >
              <Typography
                className="roboto-regular"
                ml={1}
                color="primary"
                component="span"
                variant="caption"
              >
                <b>
                  {t(
                    formContext.input === 'phone' && !formContext?.dataForm?.existsEmail
                      ? 'login.resendWithPhone'
                      : 'login.resendWithEmail',
                  )}
                </b>
              </Typography>
            </Link>
          </Typography>
        )}
      </Box>
    </div>
  );
};

export default FormConfirmCode;
