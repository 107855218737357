/* eslint-disable react/prop-types */
import React, { useEffect, useState, useContext } from 'react';
import Typography from 'core/ui/Typography';
import Box from 'core/ui/Box';
import SFDataTable from 'core/ui/DataTable';
import { useDispatch, useSelector } from 'react-redux';
import { messageListsActions, messageListsSelectors } from 'modules/messageLists';
import { Skeleton } from '@material-ui/lab';
import { Edit, People } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { ButtonPrimarySm } from 'core/ui/Button';
import Checkbox from 'core/ui/Checkbox';
import { TextServiceContext } from 'contexts';
import TableMessageListContacts from './TableContacts';

const TableMessageLists = ({ edit, remove }) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => messageListsSelectors.getMessageLists(state));
  const loading = useSelector((state) => messageListsSelectors.getLoading(state));
  const [modalUsers, setModalUsers] = useState({ open: false });
  const textServiceContext = useContext(TextServiceContext);

  useEffect(() => {
    dispatch(messageListsActions.getMessageListsStart());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data && modalUsers && modalUsers.list) {
      setModalUsers((prev) => ({ ...prev, list: data.find((x) => x.id === prev.list.id) }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const isChecked = (item) => {
    const lists = textServiceContext.watch('checkedLists') || [];
    return !!lists.find((x) => Number(x) === Number(item.id));
  };

  const onRowClicked = (item) => {
    const lists = textServiceContext.watch('checkedLists') || [];
    if (isChecked(item)) {
      textServiceContext.setValue(
        'checkedLists',
        lists.filter((x) => x !== item.id),
      );
      return;
    }

    textServiceContext.setValue('checkedLists', [...lists, item.id]);
  };

  const columns = [
    {
      width: '30px',
      sortable: false,
      cell: (list) => <Checkbox checked={isChecked(list)} onClick={() => onRowClicked(list)} />,
    },
    {
      name: 'Name',
      selector: 'name',
      sortable: true,
      cell: (list) => <Typography ml={1}>{list.name}</Typography>,
    },
    {
      name: 'Contacts',
      selector: 'contacts',
      sortable: true,
      cell: (list) => (
        <ButtonPrimarySm onClick={() => setModalUsers((prev) => ({ ...prev, open: true, list }))}>
          <People style={{ fontSize: 20 }} /> ({list.contactsCount})
        </ButtonPrimarySm>
      ),
    },
    {
      name: '',
      selector: 'contacts',
      sortable: true,
      cell: (list) => (
        <IconButton onClick={() => edit(list)}>
          <Typography>
            <Edit style={{ fontSize: 20 }} />
          </Typography>
        </IconButton>
      ),
    },
  ];

  const rows =
    data &&
    data.map((list) => ({
      id: list.id,
      name: list.name,
      contactsCount: list.contactsCount,
    }));

  return (
    <Box>
      {loading && (
        <Skeleton variant="rect" animation="wave" style={{ height: 200, width: '100%' }} />
      )}
      <SFDataTable
        columns={columns}
        data={rows}
        pagination
        showPaper={false}
        noTableHead
        noHeader
      />

      {modalUsers.open && (
        <TableMessageListContacts
          close={() => setModalUsers({ open: false })}
          remove={remove}
          open={modalUsers.open}
          list={modalUsers.list}
        />
      )}
    </Box>
  );
};

TableMessageLists.propTypes = {};

TableMessageLists.defaultProps = {};

export default TableMessageLists;
