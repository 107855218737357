/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { channelsSelectors } from 'modules/channels';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Typography, { fontStyle } from 'core/ui/Typography';
import { primary } from 'core/ui/Colors';
import { Collapse } from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { WorkoutContext } from '.';

const InfoChannel = () => {
  const formContext = useContext(WorkoutContext);
  const { t } = useTranslation();
  const channels = useSelector((state) => channelsSelectors.getChannels(state));

  const checkedChannel = (channel) => !!formContext.channels.find((x) => x === channel.id);

  const changeChannelOption = (channel) => {
    let arr = [...formContext.channels];
    if (checkedChannel(channel)) {
      arr = arr.filter((x) => x !== channel.id);
      if (arr.length === 0) {
        formContext.setSelectedChannel(null);
      }
    } else {
      if (arr.length === 0) {
        formContext.setSelectedChannel(channel);
      }
      arr.push(channel.id);
    }

    formContext.setChannels(arr);
  };

  const OptionClass = ({ label, checked, onClick }) => (
    <Box onClick={() => onClick()} display="flex" className="link-hover" alignItems="center">
      {checked ? <CheckBoxIcon style={{ color: primary }} /> : <CheckBoxOutlineBlankIcon />}
      <Typography ml={1} mr={2} variant="caption" color="textSecondary">
        {label}
      </Typography>
    </Box>
  );

  if (channels && channels.length === 1) {
    return <></>;
  }

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        className="hover"
        onClick={() =>
          formContext?.setFormOpenSection((prev) => ({ ...prev, infoChannels: !prev.infoChannels }))
        }
      >
        <Box flexGrow={1} display="flex" alignItems="center">
          <Typography variant="h5" color="primary">
            {t('workout.mainChannel').toUpperCase()}:
          </Typography>
          <Typography ml={1} variant="h5">
            {formContext.selectedChannel
              ? formContext.selectedChannel.name
              : t('workout.selectAChannel')}
          </Typography>
        </Box>

        <>
          {!formContext?.formOpenSection.infoChannels ? (
            <KeyboardArrowDown style={{ fontSize: 30 }} />
          ) : (
            <KeyboardArrowUp style={{ fontSize: 30 }} />
          )}
        </>
      </Box>
      <Collapse in={formContext?.formOpenSection.infoChannels}>
        <Box p={3}>
          {channels && channels.length > 0 && (
            <Box>
              {!!formContext.selectedChannel && (
                <Typography style={fontStyle.label} variant="body1">
                  {t('workout.label.selectChannelsToShowThisClass').toUpperCase()}
                </Typography>
              )}
              <Box display="flex" flexWrap="wrap" m={1}>
                {channels &&
                  channels.map((channel, index) => (
                    <OptionClass
                      key={index.toString()}
                      label={channel.name}
                      checked={checkedChannel(channel)}
                      onClick={() => changeChannelOption(channel)}
                    />
                  ))}
              </Box>
            </Box>
          )}
        </Box>
      </Collapse>
    </>
  );
};

export default InfoChannel;
