/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Typography, { fontStyle } from 'core/ui/Typography';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import Box from 'core/ui/Box';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Paper from 'core/ui/Paper';
import DatePickerRounded from 'core/ui/DatePickers/DatePickerRounded';
import Checkbox from 'core/ui/Checkbox';

const Option = ({ label, onClick, checked }) => (
  <Box className="link-hover" mr={2} onClick={() => onClick()} display="flex" alignItems="center">
    {!checked ? (
      <RadioButtonUncheckedIcon color="primary" />
    ) : (
      <CheckCircleOutlineIcon color="primary" />
    )}
    <Typography ml={2} variant="body2">
      {label}
    </Typography>
  </Box>
);

const FormRenewal = ({ channel, currentPlan, setCurrentPlan }) => {
  const { t } = useTranslation();

  return (
    <Paper className="paper-rounded" p={3}>
      <Box display="flex" alignItems="center">
        <Box flexGrow={1}>
          <Typography mb={2} style={fontStyle.label} variant="body1">
            {t(
              currentPlan.renewable
                ? 'channel.memberships.customIncrementType'
                : 'channel.memberships.customIncrementTypeExpiry',
            ).toUpperCase()}
          </Typography>
          <Box display="flex">
            <Option
              label={t('channel.memberships.custom.day')}
              onClick={() => setCurrentPlan((prev) => ({ ...prev, customIncrement: 'day' }))}
              checked={currentPlan.customIncrement === 'day'}
            />
            <Option
              label={t('channel.memberships.custom.week')}
              onClick={() => setCurrentPlan((prev) => ({ ...prev, customIncrement: 'week' }))}
              checked={currentPlan.customIncrement === 'week'}
            />
            <Option
              label={t('channel.memberships.custom.month')}
              onClick={() => setCurrentPlan((prev) => ({ ...prev, customIncrement: 'month' }))}
              checked={currentPlan.customIncrement === 'month'}
            />
            <Option
              label={t('channel.memberships.custom.year')}
              onClick={() => setCurrentPlan((prev) => ({ ...prev, customIncrement: 'year' }))}
              checked={currentPlan.customIncrement === 'year'}
            />
            {!currentPlan?.renewable && (
              <Option
                label={t('channel.memberships.custom')}
                onClick={() => setCurrentPlan((prev) => ({ ...prev, customIncrement: 'custom' }))}
                checked={currentPlan.customIncrement === 'custom'}
              />
            )}
            {currentPlan?.renewable && (
              <Option
                label={t('channel.memberships.limit0')}
                onClick={() => setCurrentPlan((prev) => ({ ...prev, customIncrement: 'limit0' }))}
                checked={currentPlan.customIncrement === 'limit0'}
              />
            )}
          </Box>
        </Box>

        {currentPlan?.customIncrement !== 'custom' && currentPlan?.customIncrement !== 'limit0' && (
          <Box>
            <TextFieldRounded
              type="number"
              pattern="[1-9]*"
              value={currentPlan.customIncrementNumber || ''}
              placeholder={t(
                currentPlan.renewable
                  ? `channel.memberships.custom.renewEvery`
                  : 'channel.memberships.custom.renewEveryExpiry',
              ).replaceAll('#{increment}', currentPlan.customIncrement)}
              label={t(
                currentPlan.renewable
                  ? `channel.memberships.custom.renewEvery`
                  : 'channel.memberships.custom.renewEveryExpiry',
              )
                .replaceAll('#{increment}', currentPlan.customIncrement)
                .toUpperCase()}
              onChange={(v) =>
                setCurrentPlan((prev) => ({ ...prev, customIncrementNumber: v > 0 ? v : 0 }))
              }
              required
              bordered
            />
          </Box>
        )}
      </Box>

      {currentPlan.renewable && currentPlan.customIncrement === 'month' && (
        <Box>
          <Box flexGrow={1}>
            <Typography mb={2} style={fontStyle.label} variant="body1">
              {t('channel.memberships.renewConfigDate').toUpperCase()}
            </Typography>
            <Box display="flex">
              <Option
                label={t('channel.memberships.renewConfigDate.purchaseDate')}
                onClick={() =>
                  setCurrentPlan((prev) => ({ ...prev, renewConfigDate: 'purchase_date' }))
                }
                checked={currentPlan.renewConfigDate === 'purchase_date'}
              />
              <Option
                label={t('channel.memberships.renewConfigDate.renew1st')}
                onClick={() => setCurrentPlan((prev) => ({ ...prev, renewConfigDate: 'renew1st' }))}
                checked={currentPlan.renewConfigDate === 'renew1st'}
              />
              <Option
                label={t('channel.memberships.renewConfigDate.renew15th')}
                onClick={() =>
                  setCurrentPlan((prev) => ({ ...prev, renewConfigDate: 'renew15th' }))
                }
                checked={currentPlan.renewConfigDate === 'renew15th'}
              />
            </Box>
          </Box>
        </Box>
      )}

      {currentPlan?.customIncrement === 'custom' && (
        <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
          <Box ml={1}>
            <DatePickerRounded
              label={t('label.startDate').toUpperCase()}
              onChange={(v) => setCurrentPlan((prev) => ({ ...prev, startAt: v }))}
              value={currentPlan?.startAt}
              disablePast={false}
              usFormat={channel?.useUsDateFormat}
              autoOk
              bordered
            />
          </Box>
          <Box ml={1}>
            <DatePickerRounded
              label={t('label.expiryDate').toUpperCase()}
              onChange={(v) => setCurrentPlan((prev) => ({ ...prev, endAt: v }))}
              value={currentPlan?.endAt}
              disablePast={false}
              usFormat={channel?.useUsDateFormat}
              autoOk
              bordered
            />
          </Box>
        </Box>
      )}

      {!currentPlan?.renewable && currentPlan?.customIncrement !== 'custom' && (
        <Box>
          <Checkbox
            label={t('channel.membership.addExpiryInRegisterDate')}
            checked={currentPlan?.addExpiryInRegisterDate}
            onClick={() =>
              setCurrentPlan((prev) => ({
                ...prev,
                addExpiryInRegisterDate: !prev?.addExpiryInRegisterDate,
              }))
            }
          />
        </Box>
      )}
    </Paper>
  );
};

FormRenewal.defaultProps = {
  currentPlan: {},
  setCurrentPlan: () => {},
};
FormRenewal.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  currentPlan: PropTypes.object,
  setCurrentPlan: PropTypes.func,
};

export default FormRenewal;
