import React from 'react';
import Box from 'core/ui/Box';
import CalendarsCalendar from 'components/Calendars/Calendar';

const ChannelCoachesCalendar = ({ channel }) => (
  <Box p={5}>
    <CalendarsCalendar gymArea isCoach channel={channel} />
  </Box>
);

export default ChannelCoachesCalendar;
