import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from 'core/ui/Typography';
import PropTypes from 'prop-types';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { workoutsSelectors } from 'modules/workouts';
import { DialogContent, Dialog } from '@material-ui/core';
import { ButtonPrimary } from 'core/ui/Button';
import Grid from 'core/ui/Grid';
import { ratingsActions } from 'modules/ratings';
import Rating from 'core/ui/Rating';
import TextAreaFieldRounded from 'core/ui/TextAreaFieldRounded';
import { useHistory } from 'react-router';
import notifications from 'modules/notifications';

const useStyles = makeStyles({
  root: {
    backgroundColor: '#f3f3f3',
  },
  btnClose: {
    position: 'absolute',
    right: 5,
    top: 0,
  },
});

export default function ModalRating({ callback, setOpenRating }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const workout = useSelector((state) => workoutsSelectors.getWorkout(state));
  const dispatch = useDispatch();
  const history = useHistory();

  const [rate, setRate] = useState({ score: 5 });

  const exit = () => {
    history.push(`/workout/view/${workout.slug}`);
    if (callback) {
      callback();
    }
  };

  const createRating = () => {
    if (!rate.comment) {
      dispatch(notifications.warning(t('rating.validate.comment')));
      return;
    }
    if (rate.score <= 0) {
      dispatch(notifications.warning(t('rating.validate.score')));
      return;
    }

    dispatch(
      ratingsActions.postRatingStart({
        workoutId: workout.id,
        score: rate.score,
        comment: rate.comment,
        messageSuccess: t('message.success.save.rating'),
        callback: exit(),
      }),
    );
  };

  return (
    <Dialog open maxWidth="xs" fullWidth onClose={() => setOpenRating(false)}>
      <DialogContent className={classes.root}>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography align="center" color="primary" variant="h2">
              {t('workout.rating')}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box display="flex" justifyContent="center" flexDirection="column">
              <Box mx={5} mt={2}>
                <Typography align="center" variant="body2">
                  {t('workout.rating.message')}
                </Typography>
              </Box>

              <Box display="flex" mx={5} mt={2} justifyContent="center">
                <Rating
                  precision={0.5}
                  size="large"
                  style={{ fontSize: 34 }}
                  value={rate.score}
                  setValue={(v) => setRate((prev) => ({ ...prev, score: v }))}
                />
              </Box>

              <Box mb={4} mt={5}>
                <TextAreaFieldRounded
                  placeholder={t('comments')}
                  value={rate.comment}
                  onChange={(v) => setRate((prev) => ({ ...prev, comment: v }))}
                />
              </Box>

              <Box mt={5} mx={5} display="flex">
                <ButtonPrimary onClick={() => createRating()} style={{ width: '100%' }}>
                  {t('confirm')}
                </ButtonPrimary>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

ModalRating.propTypes = {
  callback: PropTypes.func,
  setOpenRating: PropTypes.func,
};

ModalRating.defaultProps = {
  callback: () => {},
  setOpenRating: () => {},
};
