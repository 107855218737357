import React, { useEffect, useState } from 'react';
import { ButtonPrimary } from 'core/ui/Button';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import Typography from 'core/ui/Typography';
import { Add, Delete, Edit, Search } from '@material-ui/icons';
import useSelectors from 'modules/map/useSelectors';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import { channelsCoachesClocksModule } from 'modules/channelsCoachesClocks';
import SFDataTable from 'core/ui/DataTable';
import { currency, dateTime, gymDate, time } from 'core/utils/formatters';
import { IconButton } from '@material-ui/core';
import LoaderSm from 'core/ui/LoaderSm';
import { channelsSelectors } from 'modules/channels';
import { useSelector } from 'react-redux';
import ModalForm from './Form/Modal';
import TimerClock from './TimerClock';

const CoachClocks = ({ user }) => {
  const { t } = useTranslation();
  const channel = useSelector((state) => channelsSelectors.getChannel(state));

  const data = useSelectors(channelsCoachesClocksModule, 'data');
  const loading = useSelectors(channelsCoachesClocksModule, 'loading');
  const pagination = useSelectors(channelsCoachesClocksModule, 'pagination');
  const { request } = useActions();
  const [perPage, setPerPage] = useState(10);
  const [modal, setModal] = useState({
    open: false,
  });

  const getTimesClocks = (page = 1) => {
    if (!channel?.id) {
      return;
    }
    request({
      action: Modules.channelsCoachesClocks.actions.getChannelsCoachesClocks,
      data: { userId: user.id, page, perPage, channelId: channel?.id },
    });
  };

  useEffect(() => {
    if (user && user.id) {
      getTimesClocks();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, channel]);

  const openModal = () => {
    setModal((prev) => ({ ...prev, mode: 'new', open: true }));
  };

  const onRemoveClick = (item) => {
    request({
      action: Modules.channelsCoachesClocks.actions.destroy,
      data: { channelId: item.channelId, id: item?.id },
      options: {
        onSuccess: () => {
          request({
            action: Modules.channelsCoachesClocks.actions.getChannelsCoachesClocks,
            data: { channelId: item?.channelId, userId: user?.id },
          });
        },
      },
    });
  };

  const stopClock = (item) => {
    const endAt = new Date();

    setModal((prev) => ({ ...prev, mode: 'new', item: { ...item, endAt }, open: true }));
  };

  const onEditClick = (item) => {
    setModal((prev) => ({ ...prev, mode: 'edit', item, open: true }));
  };

  const columns = [
    {
      name: 'Description',
      selector: 'description',
      sortable: true,
      cell: (row) => <Typography>{row?.description}</Typography>,
    },
    {
      name: 'Rate',
      cell: (row) => (
        <Typography>{`${row?.channelsCoachesRate?.ratesTemplate?.name || ''} - ${currency(
          row?.channelsCoachesRate?.ratesTemplate?.amount,
        )}`}</Typography>
      ),
    },
    {
      name: t('label.startAt'),
      selector: 'startAt',
      sortable: true,
      cell: (row) => (
        <Typography>
          {gymDate(row?.startAt)}&nbsp;&nbull;&nbsp;{time(row?.endAt)}
        </Typography>
      ),
    },
    {
      name: t('label.endAt'),
      selector: 'endAt',
      sortable: true,
      cell: (row) => (
        <Typography>
          {row?.endAt ? (
            dateTime(row?.endAt)
          ) : (
            <Box>
              <ButtonPrimary onClick={() => stopClock(row)}>{t('button.stop')}</ButtonPrimary>
            </Box>
          )}
        </Typography>
      ),
    },
    {
      name: 'Total Hours',
      selector: 'hours',
      sortable: true,
      cell: (row) => (
        <Typography>
          {row?.endAt ? <Typography>{row?.hours} hrs</Typography> : <TimerClock clock={row} />}
        </Typography>
      ),
    },
    {
      cell: (row) => (
        <Box display="flex">
          {row?.endAt && (
            <IconButton onClick={() => onEditClick(row)}>
              <Edit />
            </IconButton>
          )}
          <IconButton onClick={() => onRemoveClick(row)}>
            <Delete />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <Box p={5}>
      {modal.open && (
        <ModalForm
          open={modal.open}
          item={modal.item}
          mode={modal.mode}
          user={user}
          close={() => setModal(() => ({ open: false }))}
          callback={() => setModal(() => ({ open: false }))}
        />
      )}

      <Box display="flex" alignItems="flex-end" mb={3}>
        <Box flexGrow={1}>
          <LoaderSm loading={loading} />
        </Box>
        <Box display="flex" alignItems="center">
          <Box mx={2}>
            <ButtonPrimary onClick={() => getTimesClocks()}>
              <Box display="flex" alignItems="flex-end">
                <Search size="small" />
                <Box alignItems="center">
                  <Typography component="span" mr={1}>
                    {t('button.view')}
                  </Typography>
                </Box>
              </Box>
            </ButtonPrimary>
          </Box>
          <ButtonPrimary onClick={() => openModal()}>
            <Box display="flex" alignItems="flex-end">
              <Add size="small" />
              <Box alignItems="center">
                <Typography component="span" mr={1}>
                  {t('button.add')}
                </Typography>
              </Box>
            </Box>
          </ButtonPrimary>
        </Box>
      </Box>

      <SFDataTable
        columns={columns}
        data={data}
        pagination
        showExport={false}
        noHeader
        paginationPerPage={perPage}
        onChangePage={(p) => getTimesClocks(p)}
        paginationServer
        paginationDefaultPage={pagination?.currentPage}
        paginationTotalRows={pagination?.totalCount}
        onChangeRowsPerPage={(newValue) => {
          setPerPage(newValue);
        }}
      />
    </Box>
  );
};

export default CoachClocks;
