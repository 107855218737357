import React, { useState } from 'react';
import { KeyboardArrowDown, KeyboardArrowRight } from '@material-ui/icons';
import BenchmarkList from 'components/Profile/Dashboard/Benchmark/List';
import Box from 'core/ui/Box';
import Typography from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import { Collapse } from '@material-ui/core';
import uuid from 'react-uuid';

const BenchmarkOptions = ({
  isCompetition,
  mode,
  setSections,
  setValue,
  sections,
  saveSection,
}) => {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);

  const selectCompareOption = (benchmark, compare) => {
    const sectionData = {
      title: benchmark?.name,
      description: benchmark?.description,
      scores: [
        {
          ...benchmark?.scores,
          timeCapped: benchmark?.scores.timeCapped || benchmark?.timeCapped,
        },
      ],
      benchmarkSectionId: compare ? benchmark?.id : null,
      timerData: benchmark?.timer,
    };

    if (saveSection) {
      saveSection(sectionData);
      setOpened(false);
      return;
    }

    if (isCompetition) {
      const isNew = !(mode === 'edit' && sections && sections.length > 0);
      setSections([{ ...sectionData, id: !isNew ? sections[0].id : uuid(), new: isNew }]);
    } else {
      setSections((prev) => [...prev, sectionData]);
    }

    if (benchmark?.movements && benchmark?.movements.length > 0) {
      const movs = benchmark?.movements.map((x) => ({ name: x }));
      setValue('movements', movs);
    } else {
      setValue('movements', []);
    }
    if (benchmark?.equipment && benchmark?.equipment.length > 0) {
      const movs = benchmark?.equipment.map((x) => ({ name: x }));
      setValue('equipment', movs);
    } else {
      setValue('equipment', []);
    }

    setOpened(false);
  };

  const onSelectedBenchmark = (benchmark) => {
    selectCompareOption(benchmark, true);
  };

  return (
    <>
      <Box className="hover" display="flex" alignItems="center">
        <Box onClick={() => setOpened(!opened)} flexGrow="1" display="flex">
          <Box flexGrow={1}>
            <Typography color="primary" variant="h5">
              {t('workout.section.useBenchmark')}
            </Typography>
          </Box>
          {opened === true ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
        </Box>
      </Box>
      <Collapse in={opened}>
        <Box py={2}>
          {opened && (
            <BenchmarkList
              setSelectedBenchmark={(b) => onSelectedBenchmark(b)}
              rowsPerPage={5}
              hideTitle
              selector
              hideBestScore
            />
          )}
        </Box>
      </Collapse>
    </>
  );
};

export default BenchmarkOptions;
