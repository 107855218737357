import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import { ButtonPrimarySm } from 'core/ui/Button';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import { adminReportsModule } from 'modules/adminReports';
import useSelectors from 'modules/map/useSelectors';
import Typography, { fontStyle } from 'core/ui/Typography';
import { currency, formatDateToApi } from 'core/utils/formatters';
import DatePickerRounded from 'core/ui/DatePickers/DatePickerRounded';
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import Paper from 'core/ui/Paper';
import Grid from 'core/ui/Grid';
import Card from 'core/ui/Card';
import { primary, secondary } from 'core/ui/Colors';
import BarMonths from './BarMonths';

const GymsChart = () => {
  const { t } = useTranslation();
  const { request } = useActions();
  const loading = useSelectors(adminReportsModule, 'loading');
  const gymMetrics = useSelectors(adminReportsModule, 'gymMetrics');

  const dateNow = new Date();

  const [filters, setFilters] = useState({
    startAt: `${dateNow.getMonth() + 1}/1/${dateNow.getFullYear()}`,
    endAt: `${dateNow.getMonth() + 1}/${dateNow.getDate()}/${dateNow.getFullYear()}`,
    workoutId: '',
  });

  const getReport = (params = {}) => {
    const startAt = filters.startAt ? formatDateToApi(filters.startAt) : null;
    const endAt = filters.endAt ? formatDateToApi(filters.endAt) : null;
    request({
      action: Modules.adminReports.actions.getGymsMetrics,
      data: { ...params, startAt, endAt },
    });
  };

  const search = () => {
    getReport();
  };

  useEffect(() => {
    search();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const CardSummary = ({ title, total, size, resumeComponent }) => (
    <Grid item xs={6} sm={6} md={size} lg={size}>
      <Card btopcolor={secondary} btopwidth={2} hidedivider="true" title={title}>
        <Box p={3} display="flex" flexDirection="column" alignItems="center">
          <Typography variant="h1">{total}</Typography>
        </Box>

        {resumeComponent}
      </Card>
    </Grid>
  );

  return (
    <Box pb={5} pt={2} px={5}>
      <Box>
        <Grid container spacing={5} mb={3}>
          <CardSummary
            size={4}
            total={gymMetrics?.resumeGyms.totalActiveGyms}
            title="Total Active"
          />

          <CardSummary size={4} total={gymMetrics?.resumeGyms.totalPaidGyms} title="Total Paid" />

          <CardSummary size={4} total={gymMetrics?.resumeGyms.totalFreeGyms} title="Total Free" />
        </Grid>

        <Box display="flex" mb={3} alignItems="flex-end" justifyContent="center" flexWrap="wrap">
          <Box ml={1}>
            <DatePickerRounded
              label={t('channel.reports.filter.start').toUpperCase()}
              onChange={(v) => setFilters((prev) => ({ ...prev, startAt: v }))}
              value={filters.startAt}
              disablePast={false}
              autoOk
            />
          </Box>
          <Box ml={1}>
            <DatePickerRounded
              label={t('channel.reports.filter.end').toUpperCase()}
              onChange={(v) => setFilters((prev) => ({ ...prev, endAt: v }))}
              value={filters.endAt}
              disablePast={false}
              autoOk
            />
          </Box>
          <Box ml={3}>
            <ButtonPrimarySm disabled={loading} onClick={() => search()}>
              {t('button.update')}
            </ButtonPrimarySm>
          </Box>
        </Box>

        <Box>
          {gymMetrics?.gymsChart && <BarMonths title="Gyms" chartData={gymMetrics?.gymsChart} />}

          {/* {gymMetrics?.lostGymsChart && (
            <>
              <BarMonths
                title="Lost Gyms"
                chartData={gymMetrics?.lostGymsChart}
                barColor={primary}
              />
            </>
          )} */}
        </Box>

        <Typography variant="h5" align="center" m={3}>
          StreamFitness Revenue
        </Typography>

        <Paper>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Plans and Competitions</TableCell>
                <TableCell>
                  {currency(gymMetrics?.streamResume?.current?.streamPlansTotal)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Programs</TableCell>
                <TableCell>
                  {currency(gymMetrics?.streamResume?.current?.pProgramsFeesTotal)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Store</TableCell>
                <TableCell>{currency(gymMetrics?.streamResume?.current?.storeFeesTotal)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Others</TableCell>
                <TableCell>
                  {currency(gymMetrics?.streamResume?.current?.othersFeesTotal)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="h5" style={{ ...fontStyle.xxLarge }}>
                    Total
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h5" style={{ ...fontStyle.xxLarge, color: primary }}>
                    {currency(gymMetrics?.streamResume?.current?.total)}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
      </Box>
    </Box>
  );
};

GymsChart.propTypes = {};

GymsChart.defaultProps = {};

export default GymsChart;
