import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import Typography, { fontStyle } from 'core/ui/Typography';
import { ButtonPrimary } from 'core/ui/Button';
import { useHistory } from 'react-router';
import Checkbox from 'core/ui/Checkbox';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import InPersonFreeClass from './InPersonFreeClass';
import LimitRegistration from './LimitRegistration';

export default ({ locations, setLocations, channel, mode, errors, watch, setValue, register }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const checkedLocation = (location) => !!locations.find((x) => x === location.id);

  const checkLocation = (location) => {
    if (checkedLocation(location)) {
      const newArr = locations.filter((x) => x !== location.id);
      setLocations(newArr);
      return;
    }

    const newArr = locations ? [...locations] : [];
    newArr.push(location.id);
    setLocations(newArr);
  };

  const localtions = channel?.locations?.filter((x) => !!x.active) || [];

  useEffect(() => {
    if (mode === 'new') {
      if (channel && localtions) {
        setLocations(localtions.map((x) => x.id));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, channel]);

  return (
    <Box py={3}>
      <Typography mb={3} variant="h5" color="primary">
        {t('workout.setting.inPerson')}
      </Typography>
      {channel && localtions && localtions.length > 0 && (
        <Box display="flex">
          <Box ml={2}>
            <Typography style={fontStyle.label} variant="body1">
              {t('workout.channel.locations').toUpperCase()}
            </Typography>

            <Box my={3} display="flex" flexWrap="wrap">
              {localtions.map((location, index) => (
                <Box
                  mx={1}
                  mb={1}
                  onClick={() => checkLocation(location)}
                  display="flex"
                  className="link-hover"
                  key={index.toString()}
                >
                  <Box display="flex">
                    <Box>
                      <Checkbox checked={checkedLocation(location)} onClick={() => {}} />
                    </Box>
                    <Box>
                      <Typography variant="body1" style={fontStyle.small} ml={1} color="secondary">
                        {location.name}
                      </Typography>
                      <Box display="flex" flexDirection=" column">
                        <Typography style={fontStyle.xSmall} ml={1} color="textSecondary">
                          {location.street1},{location.street2}
                        </Typography>
                        <Typography style={fontStyle.xSmall} ml={1} color="textSecondary">
                          {location.state}/{location.city}&nbsp;-&nbsp;{location.zip}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      )}

      {channel && localtions && localtions.length === 0 && (
        <Box
          m={5}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Typography style={fontStyle.large} variant="h3">
            {t('workout.channel.no.locations').toUpperCase()}
          </Typography>
          <Box mt={3}>
            <ButtonPrimary onClick={() => history.push(`/channel/manage/${channel.id}`)}>
              {t('button,editChannel')}
            </ButtonPrimary>
          </Box>
        </Box>
      )}

      <Box mr={1} my={1} flexGrow="1">
        <TextFieldRounded
          name="inPersonMaxUsers"
          type="number"
          pattern="[0-9]*"
          error={errors.inPersonMaxUsers}
          placeholder={t('workout.label.maxUsers')}
          label={t('workout.label.maxUsers').toUpperCase()}
          inputRef={register({})}
        />
      </Box>
      <Box>
        <InPersonFreeClass watch={watch} setValue={setValue} />
        <LimitRegistration
          fieldNumber="limitRegistration.numberInPerson"
          fieldRadio="limitRegistration.hasInPersonLimit"
          fieldType="limitRegistration.typeInPerson"
          watch={watch}
          setValue={setValue}
        />
      </Box>

      {channel?.hasHybrid && (
        <Box m={1} mx={2}>
          <Checkbox
            label={t('workout.label.hybridAutoCheckin')}
            checked={watch('hybridAutoCheckin')}
            onClick={() => setValue('hybridAutoCheckin', !watch('hybridAutoCheckin'))}
          />
        </Box>
      )}
    </Box>
  );
};
