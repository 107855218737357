import React from 'react';
import styled from 'styled-components';
import clsx from 'clsx';
// eslint-disable-next-line import/no-extraneous-dependencies
import { spacing } from '@material-ui/system';
import { withStyles } from '@material-ui/core/styles';
import { Button as MuiButton, makeStyles } from '@material-ui/core';
import { secondary } from '../Colors';

const Button = styled(MuiButton)(spacing);

export const ButtonPrimary = ({ buttonColor, fontSize, className, ...props }) => {
  const classes = useButtonPrimaryStyles({ buttonColor, fontSize });

  return <Button className={clsx(classes.button, className)} {...props} />;
};

const useButtonPrimaryStyles = makeStyles((theme) => ({
  button: ({ buttonColor, fontSize }) => {
    const backgroundColor = buttonColor || theme.palette.primary.main;

    return {
      borderRadius: 5,
      color: theme.palette.getContrastText(backgroundColor),
      backgroundColor,
      fontSize: fontSize || 14,

      '&:hover': {
        backgroundColor,
        opacity: 0.8,
        textDecoration: 'none',
        cursor: 'pointer',
      },
    };
  },
}));

export const ButtonPrimarySm = ({ buttonColor, fontSize, className, ...props }) => {
  const classes = useButtonPrimarySmStyles({ buttonColor, fontSize });

  return <Button className={clsx(classes.button, className)} {...props} />;
};

const useButtonPrimarySmStyles = makeStyles((theme) => ({
  button: ({ buttonColor, fontSize }) => {
    const backgroundColor = buttonColor || theme.palette.primary.main;

    return {
      padding: 2,
      borderRadius: 5,
      fontSize: fontSize || 12,
      backgroundColor,
      color: theme.palette.getContrastText(backgroundColor),

      '&:hover': {
        backgroundColor,
        opacity: 0.8,
        textDecoration: 'none',
      },
    };
  },
}));

export const ButtonSecondary = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(secondary),
    backgroundColor: secondary,
    '&:hover': {
      backgroundColor: '#2d2d2e',
    },
    borderRadius: 5,
  },
}))(Button);

export const ButtonCancel = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText('#000'),
    backgroundColor: '#000',
    '&:hover': {
      backgroundColor: '#000',
    },
    borderRadius: 5,
  },
}))(Button);

export const ButtonGreen = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText('#1ed760'),
    backgroundColor: '#1ed760',
    '&:hover': {
      backgroundColor: '#1cc357',
    },
    borderRadius: 5,
  },
}))(Button);

export const ButtonAdvSubmit = withStyles(() => ({
  root: {
    border: `0.5px solid ${secondary}`,
    color: 'white',
    backgroundColor: '#3bae49',
    '&:hover': {
      backgroundColor: '#3bae49',
    },
    borderRadius: 5,
  },
}))(Button);

export const ButtonAdvCancel = withStyles(() => ({
  root: {
    border: `0.5px solid ${secondary}`,
    color: secondary,
    backgroundColor: '#E3E2D8',
    '&:hover': {
      backgroundColor: '#dcdbd2',
    },
    borderRadius: 5,
  },
}))(Button);

export const ButtonSideBar = withStyles(() => ({
  root: {
    color: '#fff',
    backgroundColor: '#38383a',
    '&:hover': {
      backgroundColor: '#333335',
    },
    borderRadius: 5,
    minWidth: 50,
    minHeight: 40,
    padding: 0,
    margin: 0,
    display: 'flex',
    alignItems: 'center',
  },
}))(Button);

export const ButtonPaginator = withStyles(() => ({
  root: {
    color: '#fff',
    backgroundColor: '#38383a',
    '&:hover': {
      backgroundColor: '#333335',
    },
    borderRadius: 5,
    padding: 3,
    minWidth: 50,
  },
}))(Button);

export const ButtonCompetition = withStyles(() => ({
  root: {
    padding: 5,
    color: 'white',
    minWidth: 80,
    '&:hover': {
      opacity: 0.9,
    },
    borderRadius: 5,
  },
}))(Button);

export const ButtonGreenSm = withStyles(() => ({
  root: {
    padding: 2,
    color: 'white',
    backgroundColor: '#17C012',
    '&:hover': {
      backgroundColor: '#17C012',
      opacity: 0.9,
    },
    borderRadius: 5,
  },
}))(Button);

export const ButtonYellowSm = withStyles(() => ({
  root: {
    padding: 2,
    color: 'white',
    backgroundColor: '#B1BC04',
    '&:hover': {
      backgroundColor: '#B1BC04',
      opacity: 0.9,
    },
    borderRadius: 5,
  },
}))(Button);

export const ButtonWhite = withStyles(() => ({
  root: {
    padding: 2,
    color: '#EC4242',
    backgroundColor: '#FFF',
    '&:hover': {
      backgroundColor: '#f9f9f9',
    },
    borderRadius: 5,
  },
}))(Button);

export const ButtonWhiteSm = withStyles(() => ({
  root: {
    color: '#EC4242',
    backgroundColor: '#FFF',
    '&:hover': {
      backgroundColor: '#f9f9f9',
    },
    borderRadius: 5,
  },
}))(Button);

export default Button;
