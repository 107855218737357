import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

import * as selectors from './selectors';
import * as sagas from './sagas';

/* ---------- Action Creators and Types ---------- */
const { Types, Creators } = createActions(
  {
    putUpdateUserError: ['error'],
    putUpdateUserStart: ['data', 'callback'],
    putUpdateUserSuccess: ['user'],

    postSpotifyAccountError: ['error'],
    postSpotifyAccountStart: ['data'],
    postSpotifyAccountSuccess: ['account'],

    delSpotifyAccountError: ['error'],
    delSpotifyAccountStart: ['id'],
    delSpotifyAccountSuccess: ['accounts'],

    getWorkoutsInvitesError: ['error'],
    getWorkoutsInvitesStart: ['userId'],
    getWorkoutsInvitesSuccess: ['data'],

    connectStripeAccountError: ['error'],
    connectStripeAccountStart: ['userId', 'code', 'callback'],
    connectStripeAccountSuccess: ['link'],

    removeStripeAccountError: ['error'],
    removeStripeAccountStart: ['userId', 'callback'],
    removeStripeAccountSuccess: ['link'],

    clearUser: [],

    setSpotifyData: ['account'],
    setSpotifyPlayer: ['player'],
  },
  { prefix: 'users/' },
);

/* ---------- Initial State ---------- */
const INITIAL_STATE = Immutable({
  workoutsInvites: [],
  loadingWorkoutsInvites: false,
  coach: {},
  error: null,
  loading: false,
  loadingCoach: false,
  loadingConnectAccount: false,
  spotifyData: null,
  spotifyPlayer: {},
});

/* ---------- Reducers ---------- */
const loadStartReducer = (state) => state.set('loading', true);
const loadWorkoutsInvitesStartReducer = (state) => state.set('loadingWorkoutsInvites', true);
const connectStripeAccountStartReducer = (state) => state.set('loadingConnectAccount', true);

const putUpdateUserSuccessReducer = (state, action) =>
  state.merge({
    user: action.user,
    loading: false,
    error: null,
  });

const postSpotifyAccountSuccessReducer = (state, action) => {
  const prevState = { ...state };
  const accounts = prevState.user.spotifyAccounts;
  return state.merge({
    user: { ...state.user, spotifyAccounts: [...accounts, action.account] },
    loading: false,
    error: null,
  });
};

const loadErrorReducer = (state, action) =>
  state.merge({
    loading: false,
    loadingCoach: false,
    error: action.error,
  });

const loadCodeErrorReducer = (state, action) =>
  state.merge({
    loadingCode: false,
    error: action.error,
  });

const setSpotifyDataReducer = (state, action) =>
  state.merge({
    spotifyData: action.account,
  });

const setSpotifyPlayerReducer = (state, action) => {
  const actualPlayer = state.spotifyPlayer ? { ...state.spotifyPlayer } : {};
  return state.merge({
    spotifyPlayer: { ...actualPlayer, ...action.player },
  });
};

const delSpotifyAccountSuccessReducer = (state, action) =>
  state.merge({
    user: { ...state.user, spotifyAccounts: [...action.accounts] },
    loading: false,
    error: null,
  });

const connectStripeAccountSuccessReducer = (state) =>
  state.merge({
    loadingConnectAccount: false,
  });

const connectStripeAccountErrorReducer = (state) =>
  state.merge({
    loadingConnectAccount: false,
  });

const getWorkoutsInvitesSuccessReducer = (state, action) =>
  state.merge({
    workoutsInvites: action.data,
    loadingWorkoutsInvites: false,
    error: null,
  });

const loadWorkoutsInvitesErrorReducer = (state, action) =>
  state.merge({
    loadingWorkoutsInvites: false,
    error: action.error,
  });

const clearUserReducer = (state) =>
  state.merge({
    user: {},
  });

/* ---------- Main Reducer ---------- */
const reducer = createReducer(INITIAL_STATE, {
  [Types.PUT_UPDATE_USER_START]: loadStartReducer,
  [Types.PUT_UPDATE_USER_SUCCESS]: putUpdateUserSuccessReducer,
  [Types.PUT_UPDATE_USER_ERROR]: loadErrorReducer,

  [Types.GET_WORKOUTS_INVITES_START]: loadWorkoutsInvitesStartReducer,
  [Types.GET_WORKOUTS_INVITES_SUCCESS]: getWorkoutsInvitesSuccessReducer,
  [Types.GET_WORKOUTS_INVITES_ERROR]: loadWorkoutsInvitesErrorReducer,

  [Types.POST_SPOTIFY_ACCOUNT_START]: loadStartReducer,
  [Types.POST_SPOTIFY_ACCOUNT_SUCCESS]: postSpotifyAccountSuccessReducer,
  [Types.POST_SPOTIFY_ACCOUNT_ERROR]: loadCodeErrorReducer,

  [Types.DEL_SPOTIFY_ACCOUNT_START]: loadStartReducer,
  [Types.DEL_SPOTIFY_ACCOUNT_SUCCESS]: delSpotifyAccountSuccessReducer,
  [Types.DEL_SPOTIFY_ACCOUNT_ERROR]: loadCodeErrorReducer,

  [Types.CONNECT_STRIPE_ACCOUNT_START]: connectStripeAccountStartReducer,
  [Types.CONNECT_STRIPE_ACCOUNT_SUCCESS]: connectStripeAccountSuccessReducer,
  [Types.CONNECT_STRIPE_ACCOUNT_ERROR]: connectStripeAccountErrorReducer,

  [Types.REMOVE_STRIPE_ACCOUNT_START]: connectStripeAccountStartReducer,
  [Types.REMOVE_STRIPE_ACCOUNT_SUCCESS]: connectStripeAccountSuccessReducer,
  [Types.REMOVE_STRIPE_ACCOUNT_ERROR]: connectStripeAccountErrorReducer,

  [Types.SET_SPOTIFY_DATA]: setSpotifyDataReducer,
  [Types.SET_SPOTIFY_PLAYER]: setSpotifyPlayerReducer,

  [Types.CLEAR_USER]: clearUserReducer,
});

/* ---------- Exporting ---------- */
export const usersActions = Creators;
export const usersTypes = Types;
export const usersSelectors = selectors;
export const usersSagas = sagas;

export default reducer;
