import React, { useEffect, useState, createRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import propTypes from 'prop-types';
import { workoutsSelectors, workoutsActions } from 'modules/workouts';
import Box from 'core/ui/Box';
import { ButtonPrimary } from 'core/ui/Button';
import Typography from 'core/ui/Typography';
import Divider from 'core/ui/Divider';
import FormScore from 'components/Stream/SideBar/components/Sections/FormScore';
import { listScores } from 'core/utils/helpers';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#ececec',
  },
}));

export default function ModalAddScores({ open, close, user, sectionId, buttonColor }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const workout = useSelector((state) => workoutsSelectors.getWorkout(state));
  const dispatch = useDispatch();

  useEffect(() => {
    if (workout.id) {
      dispatch(
        workoutsActions.getWorkoutSectionUserStart({
          workoutId: workout.id,
          userId: user.id,
        }),
      );
    }
  }, [workout, user, dispatch]);

  const [refsSections, setRefsSections] = useState({});

  const allSections =
    workout &&
    workout.sections
      .filter((s) => !sectionId || s.id === sectionId)
      .filter((s) => !!listScores(s, t));

  useEffect(() => {
    const refs = { ...refsSections };
    workout.sections.forEach((e) => {
      refs[e.id] = createRef();
    });
    setRefsSections(refs);
  }, [workout.sections]); //eslint-disable-line

  const saveAllSections = () => {
    allSections.forEach((s) => {
      refsSections[s.id].current.saveSection();
    });
  };

  return (
    <Dialog
      PaperProps={{ style: { borderRadius: 5, minWidth: 300 } }}
      maxWidth="sm"
      fullWidth
      scroll="body"
      onClose={() => close()}
      open={open}
    >
      <Box p={5} className={classes.root}>
        <Box display="flex" justifyContent="center" flexDirection="column">
          {workout &&
            allSections.map((section) => (
              <Box key={section.id}>
                <Box mt={2}>
                  <Typography align="center" color="primary" style={{ fontSize: 14 }}>
                    {section?.title?.toUpperCase()}
                  </Typography>
                  <Box>
                    <FormScore
                      ref={refsSections[section.id]}
                      sectionData={section}
                      user={user}
                      showSaveButton={false}
                      workout={workout}
                      buttonColor={buttonColor}
                      saveCallback={() => close()}
                    />
                  </Box>
                </Box>
                <Divider mx={5} my={1} />
              </Box>
            ))}

          <Box display="flex" mt={5} justifyContent="center">
            <ButtonPrimary buttonColor={buttonColor} fullWidth onClick={() => saveAllSections()}>
              {t('button.saveScores')}
            </ButtonPrimary>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
}

ModalAddScores.propTypes = {
  open: propTypes.bool,
  close: propTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  user: propTypes.object.isRequired,
  sectionId: propTypes.number,
};

ModalAddScores.defaultProps = {
  open: false,
  close: null,
  sectionId: null,
};
