import React, { useCallback, useContext, useEffect, useState } from 'react';
import Box from 'core/ui/Box';
import { ShowContext } from 'components/Competition/Show';
import Typography from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { competitionsActions } from 'modules/competitions/index.js';
import LoaderSm from 'core/ui/LoaderSm/index.js';
import { Hidden } from '@material-ui/core';
import Paper from 'core/ui/Paper/index.js';
import Paginator from 'core/ui/Paginator/index.js';
import { labelLight } from 'core/ui/Colors.js';
import ConfirmDialog from 'core/ui/ConfirmDialog/index.js';
import TagsChosen from 'core/ui/TagsChosen';
import { sessionSelectors } from 'modules/session';
import Modules from 'modules';
import useActions from 'modules/map/useActions';
import useConfirmation from 'core/useConfirmation';
import notifications from 'modules/notifications/index.js';
import useSelectors from 'modules/map/useSelectors.js';
import { newCompetitionsModule } from 'modules/competitions/new.js';
import { ButtonPrimary } from 'core/ui/Button/index.js';
import ModalEditScores from './ModalEditScores.js';
import PoweredLogo from '../../PoweredLogo.js';
import TableResults from './Tables/TableResults';
import MobileTableResults from './Tables/Mobile/MobileTableResults';
import { dropdownWrapper } from './style';
import TableResultsAffiliates from './Tables/TableResultsAffiliates';
import MobileTableResultsAffiliates from './Tables/Mobile/MobileTableResultsAffiliates';
import YourTags from './YourTags';
import LeaderboardFilters from './LeaderboardFilters.js';

const CompetitionResults = () => {
  const { t } = useTranslation();
  const { request } = useActions();
  const showContext = useContext(ShowContext);
  const { confirmation } = useConfirmation();
  const currentUser = useSelector((state) => sessionSelectors.getUser(state));

  const loadingResults = useSelectors(newCompetitionsModule, 'loadingResults');
  const loadingExportResults = useSelectors(newCompetitionsModule, 'loadingExportResults');

  const [resultsData, setResultsData] = useState({ data: [], pagination: null });

  const dispatch = useDispatch();
  const [modalEditScore, setModalEditScore] = useState({ open: false });
  const [currentDivision, setCurrentDivision] = useState();
  const [workoutId, setWorkoutId] = useState();
  const [currentView, setCurrentView] = useState('Teams');
  const [tags, setTags] = useState(null);
  const [withdraw, setWithdraw] = useState(null);
  const [regType, setRegType] = useState('individual');
  const [lastOrder, setLastOrder] = useState(null);

  const [modalConfirm, setModalConfirm] = useState(true);
  const { isCoach, registration, buttonColor } = showContext.competition;
  const userTags = registration && registration.tags && registration.tags.map((x) => x.name);

  const confirmRemoveUser = (userId, refund = true) => {
    if (!isCoach) {
      return;
    }

    request({
      action: Modules.competitionsUsers.actions.delete,
      data: {
        regType: 'competitor',
        competitionId: showContext.competition.id,
        userId,
        refund,
      },
      options: {
        onSuccess: () => {
          dispatch(competitionsActions.getCompetitionStart(showContext.competition.id));
        },
        onError: (error) => {
          dispatch(notifications.error(error));
        },
      },
    });
  };

  const withdrawUser = (userId) => {
    if (!isCoach) {
      return;
    }

    confirmation({
      description: t('user.withdraw.confirm'),
      yesClick: () => {
        request({
          action: Modules.competitionsUsers.actions.withdraw,
          data: {
            regType: 'competitor',
            competitionId: showContext.competition.id,
            userId,
          },
          options: {
            onSuccess: () => {
              dispatch(competitionsActions.getCompetitionStart(showContext.competition.id));
            },
            onError: (error) => {
              dispatch(notifications.error(error));
            },
          },
        });
      },
    });
  };

  const removeUserVideo = (wId, userId) => {
    confirmation({
      description: 'Are you sure want to remove the video?',
      yesClick: () => {
        request({
          action: Modules.workoutRecordings.actions.destroyUserVideo,
          data: {
            workoutId: wId,
            userId,
          },
          options: {
            onSuccess: () => {
              dispatch(competitionsActions.getCompetitionStart(showContext.competition.id));
            },
          },
        });
      },
    });
  };

  const removeUser = (userId) => {
    if (!isCoach) {
      return;
    }

    confirmation({
      description: t('Are you sure?'),
      yesClick: () => {
        confirmRemoveUser(userId);
      },

      showConfirm2: true,
      labelConfirm2: t('button.confirmNoRefund'),
      confirm2: () => {
        confirmRemoveUser(userId, false);
      },
    });
  };

  const [page, setPage] = useState(1);
  const rowsPerPage = 30;

  useEffect(() => {
    if (showContext.competition && showContext.competition.registration) {
      setTags((prev) => (prev ? [...prev] : [...userTags]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showContext.competition]);

  useEffect(() => {
    if (showContext.competition?.id) {
      setPage(1);

      setRegType(
        showContext?.competition?.eventType !== 'both'
          ? showContext?.competition?.eventType
          : 'individual',
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showContext.competition?.id]);

  useEffect(() => {
    if (registration && showContext.competition.hasDivisions && registration.division) {
      setCurrentDivision(registration.division.id);
    } else if (
      !registration &&
      !currentDivision &&
      showContext.competition.hasDivisions &&
      showContext.competition.divisions &&
      showContext.competition.divisions.length > 0
    ) {
      setCurrentDivision(showContext.competition.divisions[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showContext.competition?.id]);

  const getResults = () => {
    if (!showContext?.competition?.id) {
      return;
    }
    if (
      showContext.competition.hasDivisions &&
      (!currentDivision || currentDivision === undefined)
    ) {
      return;
    }

    request({
      action: Modules.newCompetitions.actions.getCompetitionResults,
      data: {
        competitionId: showContext.competition.id,
        page,
        per_page: rowsPerPage,
        division_id: showContext.competition.hasDivisions ? currentDivision : null,
        current_view: currentView,
        tags: tags && currentView === 'Custom' ? tags.map((x) => x.name || x) : null,
        withdraw: isCoach && !!withdraw ? '1' : null,
        workoutId,
        regType,
      },
      options: {
        onSuccess: (data, pag) => {
          setResultsData({ data, pagination: pag });
        },
      },
    });
  };

  const exportResults = () => {
    if (showContext.competition && showContext.competition.hasDivisions && !currentDivision) {
      return;
    }

    request({
      action: Modules.newCompetitions.actions.exportCompetitionLeaderboard,
      data: {
        competitionId: showContext.competition.id,
        division_id: showContext.competition.hasDivisions ? currentDivision : null,
        current_view: currentView,
        workoutId,
        tags: tags && currentView === 'Custom' ? tags.map((x) => x.name || x) : null,
        exportFileName: `${showContext.competition?.name}-LeaderboardResults.csv`,
        export: true,
        withdraw: isCoach && !!withdraw ? '1' : null,
        regType,
      },
    });
  };

  useEffect(() => {
    setTimeout(() => {
      getResults();
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    showContext.competition,
    currentDivision,
    workoutId,
    currentView,
    page,
    tags,
    regType,
    withdraw,
  ]);

  const removeUserScore = (wId, sectionId, scoreId) => {
    confirmation({
      description: t('user.remove.score'),
      yesClick: () => {
        request({
          action: Modules.newWorkouts.actions.deleteUserScore,
          data: { workoutId: wId, workoutSectionUserId: scoreId, sectionId },
          options: {
            onSuccess: () => {
              getResults();
            },
          },
        });
      },
    });
  };

  useEffect(() => {
    if (resultsData?.data && showContext?.openTeamEdit?.team) {
      showContext.setOpenTeamEdit((prev) => ({
        ...prev,
        team: resultsData?.data?.find((x) => x?.team?.id === showContext?.openTeamEdit?.team?.id)
          ?.team,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultsData?.data]);

  const orderResult = (orderBy, wId) => {
    setLastOrder(workoutId);

    request({
      action: Modules.newCompetitions.actions.getCompetitionResults,
      data: {
        competitionId: showContext.competition.id,
        page: 1,
        per_page: rowsPerPage,
        divisionId: currentDivision,
        current_view: currentView,
        currentView,
        orderBy,
        tags: tags && currentView === 'Custom' ? tags.map((x) => x.name || x) : null,
        workoutId,
        orderWorkoutId: wId,
        withdraw: isCoach && !!withdraw ? '1' : null,
        regType,
      },
      options: {
        onSuccess: (data, pag) => {
          setResultsData({ data, pagination: pag });
        },
      },
    });
  };

  const openModalScores = ({ workout, section, user }) => {
    setModalEditScore({
      open: true,
      workout,
      user,
      section,
    });
  };

  const getCompWorkouts = useCallback(() => {
    let { workouts } = showContext.competition;

    if (currentDivision) {
      workouts = workouts?.filter(
        (x) => x.divisions && !!x.divisions.find((y) => Number(y.id) === Number(currentDivision)),
      );
    }
    if (workoutId) {
      workouts = workouts?.filter((x) => x.id === Number(workoutId));
    }

    return workouts;
  }, [showContext.competition, currentDivision, workoutId]);

  const compWorkouts = getCompWorkouts();

  const viewOptions = [
    { id: 'Teams', name: showContext.competition.isTeam ? 'Teams' : 'Competitors' },
  ];

  if (showContext.competition && showContext.competition.hasAffiliate) {
    viewOptions.push({ id: 'Affiliate Gyms', name: 'Affiliate Gyms' });
  }
  viewOptions.push({ id: 'Custom', name: 'Custom' });

  return (
    <Paper className="paper-rounded opacity-80" p={3}>
      <Box display="flex" mb={2}>
        <Box flexGrow={1} display="flex" alignItems="center">
          <Typography variant="h5" mb={3}>
            {t('competition.tab.leaderboard')}
          </Typography>
          <Box>
            <LoaderSm loading={loadingResults} />
          </Box>
        </Box>
        <Box flexGrow={1} mx={2} justifyContent="center">
          <Box style={{ width: 100 }}>
            <PoweredLogo size={100} leaderboard competition={showContext.competition} />
          </Box>
        </Box>
      </Box>

      <ConfirmDialog
        open={modalConfirm.open}
        setOpen={(v) => setModalConfirm((prev) => ({ ...prev, open: v }))}
        description={t('areYouSure')}
        labelConfirm={t('confirm')}
        labelCancel={t('cancel')}
        showConfirm2
        labelConfirm2={t('button.confirmNoRefund')}
        confirm={modalConfirm.callback}
        confirm2={modalConfirm.callbackNoRefund}
      />

      {modalEditScore.open && modalEditScore.section && (
        <ModalEditScores
          {...modalEditScore}
          close={() => openModalScores({ open: false })}
          refresh={getResults}
          buttonColor={showContext.buttonColor}
        />
      )}

      <Box className="hide-pagination-controls tw-mb-2 tw-flex tw-flex-row tw-flex-wrap tw-items-end tw-gap-2">
        <LeaderboardFilters
          divisionId={currentDivision}
          setDivisionId={setCurrentDivision}
          currentView={currentView}
          setCurrentView={setCurrentView}
          withdraw={withdraw}
          setWithdraw={setWithdraw}
          workoutId={workoutId}
          setWorkoutId={setWorkoutId}
          regType={regType}
          setRegType={setRegType}
        />

        <Box flexGrow={1} display="flex" alignItems="center" justifyContent="flex-end">
          {resultsData?.pagination && resultsData?.pagination.currentPage && (
            <Paginator
              pagination={{
                currentPage: resultsData?.pagination.currentPage,
                totalPages: resultsData?.pagination.totalPages,
              }}
              changePage={(p) => setPage(p)}
            />
          )}
        </Box>

        {isCoach && (
          <div>
            <ButtonPrimary onClick={() => exportResults()} disabled={loadingExportResults}>
              <LoaderSm loading={loadingExportResults} />
              {t('button.exportResults')}
            </ButtonPrimary>
          </div>
        )}
      </Box>

      {currentView === 'Custom' && (
        <Box mx={0.5} mb={2}>
          {currentUser &&
            currentUser.id &&
            showContext.competition &&
            showContext.competition.registered && (
              <Box mb={3}>
                <YourTags
                  competitionId={showContext.competition.id}
                  userId={currentUser.id}
                  userTags={userTags}
                  setTags={setTags}
                  tags={tags}
                  buttonColor={buttonColor}
                />
              </Box>
            )}

          <Typography variant="body2" style={{ color: labelLight }}>
            {t('competition.leaderboard.tags')}
          </Typography>
          <Typography ml={1} variant="caption" color="secondary" mb={1}>
            {t('competition.leaderboard.tags.help')}
          </Typography>
          <TagsChosen
            placeholder={t('competition.registration.label.tags')}
            setValue={(values) => setTags(values)}
            limit={303}
            kind="competition"
            lightColor={buttonColor}
            val={tags ? [...tags] : []}
            style={dropdownWrapper}
            radius={5}
            iconTag={
              <Typography ml={1.5} variant="h6" style={{ color: 'white' }}>
                #
              </Typography>
            }
          />
        </Box>
      )}

      {currentView !== 'Affiliate Gyms' ? (
        <>
          <Hidden only={['xs', 'sm']}>
            <TableResults
              competition={showContext.competition}
              competitionResults={resultsData?.data?.filter((x) => !!x.team)}
              openModalScores={openModalScores}
              setOpenTeamEdit={showContext.setOpenTeamEdit}
              removeUser={removeUser}
              removeUserVideo={removeUserVideo}
              compWorkouts={compWorkouts}
              orderResult={orderResult}
              removeUserScore={removeUserScore}
              lastOrder={lastOrder}
              withdrawUser={withdrawUser}
            />
          </Hidden>
          <Hidden only={['md', 'lg', 'xl']}>
            <MobileTableResults
              competition={showContext.competition}
              competitionResults={resultsData?.data?.filter((x) => !!x.team)}
              page={page}
              rowsPerPage={rowsPerPage}
              openModalScores={openModalScores}
              setOpenTeamEdit={showContext.setOpenTeamEdit}
              removeUser={removeUser}
              removeUserVideo={removeUserVideo}
              compWorkouts={compWorkouts}
              withdrawUser={withdrawUser}
            />
          </Hidden>
        </>
      ) : (
        <>
          <Hidden only={['xs', 'sm']}>
            <TableResultsAffiliates
              competition={showContext.competition}
              competitionResults={resultsData?.data?.filter((x) => !!x.affiliate)}
              openModalScores={openModalScores}
              setOpenTeamEdit={showContext.setOpenTeamEdit}
              removeUser={removeUser}
              compWorkouts={compWorkouts}
              orderResult={orderResult}
              lastOrder={lastOrder}
            />
          </Hidden>
          <Hidden only={['md', 'lg', 'xl']}>
            <MobileTableResultsAffiliates
              competition={showContext.competition}
              competitionResults={resultsData?.data?.filter((x) => !!x.affiliate)}
              page={page}
              rowsPerPage={rowsPerPage}
              openModalScores={openModalScores}
              setOpenTeamEdit={showContext.setOpenTeamEdit}
              removeUser={removeUser}
              compWorkouts={compWorkouts}
            />
          </Hidden>
        </>
      )}
    </Paper>
  );
};

export default CompetitionResults;
