import React from 'react';
import Box from 'core/ui/Box';
import FormatSectionPrRow from 'components/Workout/Form/Section/FormatSectionPrRow';

const PrItem = ({ section, sectionPr, textColor, fontSize, expandView }) => {
  const bestScore =
    section && section.userPrs && section.userPrs.find((x) => x.id === sectionPr.prId);

  return (
    <Box>
      <Box mt={1} display="flex" alignItems="center">
        <Box flexGrow={1}>
          <FormatSectionPrRow
            data={sectionPr}
            movement={sectionPr.movementName}
            bestPr={bestScore && bestScore.bestPr}
            textColor={textColor}
            fontSize={fontSize}
            expandView={expandView}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ({ section, textColor, fontSize, expandView }) => {
  if (!section.personalRecords) {
    return <></>;
  }
  return (
    <Box mb={3}>
      {section.personalRecords &&
        section.personalRecords.map((pr, index) => (
          <PrItem
            fontSize={fontSize}
            section={section}
            sectionPr={pr}
            key={index.toString()}
            textColor={textColor}
            expandView={expandView}
          />
        ))}
    </Box>
  );
};
