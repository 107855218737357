import React from 'react';
import { IconButton, InputLabel } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { getBase64 } from 'core/utils/helpers';
import Typography, { fontStyle } from 'core/ui/Typography';
import Box from 'core/ui/Box';
import { Delete, Image } from '@material-ui/icons';
import Paper from 'core/ui/Paper';
import { redColor } from 'core/ui/Colors';
import Grid from 'core/ui/Grid';

const SelImages = ({ setImages, images }) => {
  const { t } = useTranslation();

  const handleCapture = ({ target }) => {
    if (target.files.length) {
      for (let i = 0; i < target.files.length; i += 1) {
        const file = target.files[i];
        getBase64(file, (result) => {
          setImages((prev) => [
            ...prev,
            {
              url: URL.createObjectURL(file),
              image: result,
              name: file.name,
              new: true,
            },
          ]);
        });
      }
    }
  };

  const deleteImage = (img) => {
    setImages((prev) => prev.map((i) => (img === i ? { ...i, _destroy: true } : i)));
  };

  const styleIcon = {
    color: '#404041',
  };

  const randomId = Math.floor(Math.random() * 101);

  return (
    <Paper p={5} mx={1} mb={3}>
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        <Box className="link-hover">
          <InputLabel htmlFor={`icon-button-file-${randomId}`}>
            <Box display="flex" alignItems="center">
              <Image style={{ ...styleIcon }} size="small" />
              <Typography ml={3} color="secondary" variant="subtitle2">
                {t('uploadImages')}
              </Typography>
            </Box>
          </InputLabel>
          <input
            color="primary"
            type="file"
            onChange={handleCapture}
            id={`icon-button-file-${randomId}`}
            style={{ display: 'none' }}
            accept=".png,.jpg,.jpeg"
            multiple
          />
        </Box>
        <Grid container spacing={3}>
          {images
            // eslint-disable-next-line no-underscore-dangle
            ?.filter((x) => !x._destroy)
            .map((image, index) => (
              <Grid item xs={12} md={6} key={index}>
                <Box m={2} style={{ position: 'relative' }}>
                  {image && image.url && (
                    <img alt="Preview" src={image.url} style={{ width: '100%' }} />
                  )}
                  <IconButton
                    style={{
                      position: 'absolute',
                      right: 3,
                      top: 3,
                      backgroundColor: redColor,
                      padding: 2,
                    }}
                    onClick={() => deleteImage(image)}
                  >
                    <Delete style={{ color: 'white', fontSize: 16 }} />
                  </IconButton>
                </Box>
              </Grid>
            ))}
        </Grid>
        <Typography align="center" mt={2} style={fontStyle.medium}>
          Suggested image size is 600px X 600px
        </Typography>
      </Box>
    </Paper>
  );
};

export default SelImages;
