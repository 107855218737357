import React from 'react';
import PropTypes from 'prop-types';
import Box from 'core/ui/Box';
import { ButtonPrimary } from 'core/ui/Button';
import LoaderSm from 'core/ui/LoaderSm';
import { useTranslation } from 'react-i18next';
import TextAreaFieldRounded from 'core/ui/TextAreaFieldRounded';
import Grid from 'core/ui/Grid';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import Checkbox from 'core/ui/Checkbox';
import Radio from 'core/ui/Radio';
import Paper from 'core/ui/Paper';
import Typography, { fontStyle } from 'core/ui/Typography';
import CountryChosen from 'core/ui/CountryChosen';
import StatesChosen from 'core/ui/StatesChosen';
import { countriesModule } from 'modules/countries';
import useSelectors from 'modules/map/useSelectors';
import SelectBoxSearch from 'core/ui/SelectBox/SelectBoxSearch';
import useFormPartner from './useForm';
import SelImage from './SelImage';

const FormPartner = ({ partner, close, callbackSave }) => {
  const { t } = useTranslation();
  const {
    loading,
    errors,
    setValue,
    watch,
    submit,
    setImage,
    image,
    taxByCountyStates,
    setTaxByCountyStates,
  } = useFormPartner({
    partner,
    callbackSave,
    close,
  });
  const states = useSelectors(countriesModule, 'states');
  const imageUrl = image?.url || partner?.image?.image?.url;

  return (
    <>
      <Paper p={3} mb={3}>
        <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
          {imageUrl && <img src={imageUrl} alt="Partner" style={{ width: 150 }} />}
          <SelImage setImage={setImage} />
        </Box>
      </Paper>
      <Box flexGrow={1}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Box mb={4}>
              <TextFieldRounded
                placeholder={t('form.partner.name')}
                label={t('form.partner.name').toUpperCase()}
                value={watch('companyName')}
                onChange={(v) => setValue('companyName', v)}
                error={errors?.companyName}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Box>
              <CountryChosen
                name="countryId"
                defaultValue={watch('countryId')}
                placeholder={t('form.address.label.country')}
                label={t('form.address.label.country').toUpperCase()}
                setValue={(v) => setValue('countryId', v)}
                required
                error={errors?.countryId}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Box>
              <StatesChosen
                name="state"
                defaultValue={`${watch('state')}`}
                countryId={watch('countryId') || 0}
                placeholder={t('channel.placeholder.state')}
                error={errors.state}
                label={t('channel.label.state').toUpperCase()}
                setValue={(v) => setValue('state', v)}
                required
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Box>
              <TextFieldRounded
                value={watch('street')}
                onChange={(v) => setValue('street', v)}
                placeholder={t('form.address.label.address')}
                label={t('form.address.label.address').toUpperCase()}
                required
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Box>
              <TextFieldRounded
                value={watch('city')}
                onChange={(v) => setValue('city', v)}
                placeholder={t('form.address.label.city')}
                label={t('form.address.label.city').toUpperCase()}
                required
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Box mb={3}>
              <TextFieldRounded
                value={watch('zip')}
                onChange={(v) => setValue('zip', v)}
                placeholder={t('form.address.label.zip')}
                label={t('form.address.label.zip').toUpperCase()}
                required
              />
            </Box>
          </Grid>
        </Grid>
        <Box mb={4}>
          <TextFieldRounded
            placeholder={t('form.partner.companyEmail')}
            label={t('form.partner.companyEmail').toUpperCase()}
            value={watch('companyEmail')}
            onChange={(v) => setValue('companyEmail', v)}
            error={errors?.companyEmail}
          />
        </Box>

        <Grid container spacing={5}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Box mb={4}>
              <TextFieldRounded
                placeholder={t('form.partner.contactName')}
                label={t('form.partner.contactName').toUpperCase()}
                value={watch('contactName')}
                onChange={(v) => setValue('contactName', v)}
                error={errors?.contactName}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Box mb={4}>
              <TextFieldRounded
                placeholder={t('form.partner.contactEmail')}
                label={t('form.partner.contactEmail').toUpperCase()}
                value={watch('contactEmail')}
                onChange={(v) => setValue('contactEmail', v)}
                error={errors?.contactEmail}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Box mb={4}>
              <TextFieldRounded
                placeholder={t('form.partner.contactPhone')}
                label={t('form.partner.contactPhone').toUpperCase()}
                value={watch('contactPhone')}
                onChange={(v) => setValue('contactPhone', v)}
                error={errors?.contactName}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Box mb={4}>
              <TextFieldRounded
                placeholder={t('form.partner.shippingEmail')}
                label={t('form.partner.shippingEmail').toUpperCase()}
                value={watch('shippingEmail')}
                onChange={(v) => setValue('shippingEmail', v)}
                error={errors?.shippingEmail}
              />
            </Box>
          </Grid>
        </Grid>

        <Box flexGrow="1" mb={4} px={1}>
          <Box display="flex" flexWrap="wrap" alignItems="top">
            <Box m={1}>
              <Typography mb={2} style={fontStyle.label} variant="body1">
                {t('channel.allowChangeAmounts').toUpperCase()}
              </Typography>
              <Box display="flex">
                <Radio
                  label={t('button.yes')}
                  checked={!!watch('allowChangeAmounts')}
                  onClick={() => setValue('allowChangeAmounts', true)}
                />
                <Box ml={1}>
                  <Radio
                    label={t('button.no')}
                    checked={!watch('allowChangeAmounts')}
                    onClick={() => setValue('allowChangeAmounts', false)}
                  />
                </Box>
              </Box>
            </Box>
            <Box m={1}>
              <Typography mb={2} style={fontStyle.label} variant="body1">
                {t('channel.dateFormat').toUpperCase()}
              </Typography>
              <Box display="flex">
                <Radio
                  label={t('date.format.us')}
                  checked={!!watch('useUsDateFormat')}
                  onClick={() => setValue('useUsDateFormat', true)}
                />
                <Box ml={1}>
                  <Radio
                    label={t('date.format.int')}
                    checked={!watch('useUsDateFormat')}
                    onClick={() => setValue('useUsDateFormat', false)}
                  />
                </Box>
              </Box>
            </Box>
            <Box m={1}>
              <Typography mb={2} style={fontStyle.label} variant="body1">
                {t('channel.label.addSalesTax').toUpperCase()}
              </Typography>
              <Box display="flex">
                <Radio
                  label={t('button.yes')}
                  checked={!!watch('useSalesTax')}
                  onClick={() => setValue('useSalesTax', true)}
                />
                <Box ml={1}>
                  <Radio
                    label={t('button.no')}
                    checked={!watch('useSalesTax')}
                    onClick={() => {
                      setValue('useSalesTax', false);
                      setValue('taxPercent', 0);
                    }}
                  />
                </Box>
              </Box>
            </Box>
            {!!watch('useSalesTax') && (
              <Box m={1}>
                <Typography mb={2} style={fontStyle.label} variant="body1">
                  {t('channel.label.taxByCounty').toUpperCase()}
                </Typography>
                <Box display="flex">
                  <Radio
                    label={t('button.yes')}
                    checked={!!watch('taxByCounty')}
                    onClick={() => setValue('taxByCounty', true)}
                  />
                  <Box ml={1}>
                    <Radio
                      label={t('button.no')}
                      checked={!watch('taxByCounty')}
                      onClick={() => {
                        setValue('taxByCounty', false);
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            )}

            {watch('useSalesTax') && !watch('taxByCounty') && (
              <Box m={1}>
                <TextFieldRounded
                  type="number"
                  value={watch('taxPercent') || 0}
                  pattern="[1-9]*"
                  placeholder={t('channel.placeholder.taxPercent')}
                  label={t('channel.label.taxPercent').toUpperCase()}
                  onChange={(v) => setValue('taxPercent', v)}
                />
              </Box>
            )}
          </Box>
          {watch('taxByCounty') && (
            <Box mt={3}>
              <SelectBoxSearch
                label={t('label.tax.statesChargeTax').toUpperCase()}
                options={states}
                setValue={(v) => setTaxByCountyStates(v)}
                value={[...(taxByCountyStates || [])]}
                required
                multiple
              />
            </Box>
          )}
        </Box>
        <Grid container spacing={5} mb={3}>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <Box mb={4}>
              <Checkbox
                checked={watch('active')}
                label={t('form.partner.active')}
                onClick={() => setValue('active', !watch('active'))}
              />
            </Box>
          </Grid>
        </Grid>

        <Paper p={3}>
          <Typography variant="h5" mb={2}>
            {t('product.partner.taxAmounts')}
          </Typography>
          <Grid container spacing={5}>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Box display="flex">
                <Radio
                  label={t('form.partner.perc')}
                  checked={watch('amounts.streamAmountType') === '%'}
                  onClick={() => setValue('amounts.streamAmountType', '%')}
                />
                <Box ml={2}>
                  <Radio
                    label={t('form.partner.money')}
                    checked={watch('amounts.streamAmountType') === '$'}
                    onClick={() => setValue('amounts.streamAmountType', '$')}
                  />
                </Box>
              </Box>

              <TextFieldRounded
                placeholder={t('form.partner.streamAmount')}
                label={t('form.partner.streamAmount').toUpperCase()}
                value={watch('amounts.streamAmount')}
                onChange={(v) => setValue('amounts.streamAmount', v)}
                error={errors?.amounts?.streamAmount}
                type="number"
                pattern="[1-9]*"
                bordered
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Box display="flex">
                <Radio
                  label={t('form.partner.perc')}
                  checked={watch('amounts.gymAmountType') === '%'}
                  onClick={() => setValue('amounts.gymAmountType', '%')}
                />
                <Box ml={2}>
                  <Radio
                    label={t('form.partner.money')}
                    checked={watch('amounts.gymAmountType') === '$'}
                    onClick={() => setValue('amounts.gymAmountType', '$')}
                  />
                </Box>
              </Box>

              <TextFieldRounded
                placeholder={t('form.partner.gymAmount')}
                label={t('form.partner.gymAmount').toUpperCase()}
                value={watch('amounts.gymAmount')}
                onChange={(v) => setValue('amounts.gymAmount', v)}
                error={errors?.amounts?.gymAmount}
                type="number"
                pattern="[1-9]*"
                bordered
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Box display="flex">
                <Radio
                  label={t('form.partner.perc')}
                  checked={watch('amounts.customerAmountType') === '%'}
                  onClick={() => setValue('amounts.customerAmountType', '%')}
                />
                <Box ml={2}>
                  <Radio
                    label={t('form.partner.money')}
                    checked={watch('amounts.customerAmountType') === '$'}
                    onClick={() => setValue('amounts.customerAmountType', '$')}
                  />
                </Box>
              </Box>

              <TextFieldRounded
                placeholder={t('form.partner.customerAmount')}
                label={t('form.partner.customerAmount').toUpperCase()}
                value={watch('amounts.customerAmount')}
                onChange={(v) => setValue('amounts.customerAmount', v)}
                error={errors?.amounts?.customerAmount}
                type="number"
                pattern="[1-9]*"
                bordered
              />
            </Grid>
          </Grid>
        </Paper>

        <Box mb={4}>
          <TextAreaFieldRounded
            placeholder={t('form.partner.details')}
            label={t('form.partner.details').toUpperCase()}
            value={watch('details')}
            onChange={(v) => setValue('details', v)}
            error={errors?.details}
          />
        </Box>

        <Box mb={5} display="flex" justifyContent="flex-end">
          <ButtonPrimary
            type="button"
            onClick={() => submit()}
            size="small"
            disabled={loading}
            style={{ height: 30, width: 80, borderRadius: 5 }}
          >
            <LoaderSm loading={loading} width={20} />
            {t('button.save')}
          </ButtonPrimary>
        </Box>
      </Box>
    </>
  );
};

FormPartner.propTypes = {
  close: PropTypes.func.isRequired,
};

FormPartner.defaultProps = {};

export default FormPartner;
