import { createSelector } from 'reselect';

const getState = (state) => state.payments;

export const getLoading = createSelector(getState, (state) => !!state.loading);
export const getPayments = createSelector(getState, (state) => state.data);
export const getPayment = createSelector(getState, (state) => state.payment);

export const getLoadingPurchaseChannelPlan = createSelector(
  getState,
  (state) => state.loadingPurchaseChannelPlan,
);
export const getPurchaseChannelPlanSuccess = createSelector(
  getState,
  (state) => state.purchaseChannelPlanSuccess,
);
export const getLoadingPurchaseAdditional = createSelector(
  getState,
  (state) => state.loadingPurchaseAdditional,
);
export const getLoadingPurchaseChannelKey = createSelector(
  getState,
  (state) => state.loadingPurchaseChannelKey,
);
export const getLoadingPurchaseChannelList = createSelector(
  getState,
  (state) => state.loadingPurchaseChannelList,
);
export const getLoadingPurchaseCoachScheduleSession = createSelector(
  getState,
  (state) => state.loadingPurchaseCoachScheduleSession,
);
export const getLoadingPurchaseChannelStoreProduct = createSelector(
  getState,
  (state) => state.loadingPurchaseChannelStoreProduct,
);
export const getLoadingPurchaseWorkoutToMember = createSelector(
  getState,
  (state) => state.loadingPurchaseWorkoutToMember,
);
