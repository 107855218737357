import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import Typography from 'core/ui/Typography';
import Paper from 'core/ui/Paper';
import Grid from 'core/ui/Grid';
import CardCompetition from 'components/Competition/Card';
import { useDispatch, useSelector } from 'react-redux';
import FontIcon from 'core/ui/FontIcon';
import { primary } from 'core/ui/Colors';
import { sessionSelectors } from 'modules/session';

import './Item.css';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import { newCompetitionsModule } from 'modules/competitions/new';
import GridSkeleton from 'core/ui/GridSkeleton';
import useSelectors from 'modules/map/useSelectors';

const MyCompetitions = () => {
  const { t } = useTranslation();
  const [comps, setComps] = useState([]);
  const loading = useSelectors(newCompetitionsModule, 'loading');
  const isAuthenticated = useSelector((state) => sessionSelectors.getIsAuthenticated(state));

  const { request } = useActions();

  const dispatch = useDispatch();

  const refresh = () => {
    request({
      action: Modules.newCompetitions.actions.getMyCompetitions,
      options: {
        onSuccess: (data) => {
          setComps(data);
        },
      },
    });
  };

  useEffect(() => {
    if (isAuthenticated) {
      refresh();
    }
  }, [dispatch, isAuthenticated]);

  return (
    <>
      {(loading || (comps && comps.length > 0)) && (
        <Paper className="paper-rounded" p={5} mb={3}>
          <Box display="flex" mb={2} alignItems="center">
            <Box flexGrow="1" display="flex" alignItems="center">
              <FontIcon iconName="icon-force" color={primary} size={14} />
              <Typography ml={1} color="secondary" className="link" variant="h5">
                {t('title.myCompetitions').toUpperCase()}
              </Typography>
            </Box>
          </Box>

          <Box px={2}>
            <Box mt={5}>
              {loading ? (
                <GridSkeleton />
              ) : (
                <Grid container spacing={3}>
                  {comps &&
                    comps.map((object, index) => (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={3}
                        key={index.toString()}
                        className="search-item"
                      >
                        <Box>
                          <CardCompetition key={object.id} competition={object} />
                        </Box>
                      </Grid>
                    ))}
                </Grid>
              )}
            </Box>
          </Box>
        </Paper>
      )}
    </>
  );
};

export default MyCompetitions;

MyCompetitions.propTypes = {};

MyCompetitions.defaultProps = {};
