import React, { useContext } from 'react';

import Box from 'core/ui/Box';
import GroupsChats from 'components/GroupsChats';
import { CompetitionContext } from '..';
import CompetitionRegistrationEmail from './EmailTemplates';
import CompetitionMessages from './CompetitionMessages';

const CompetitionCommunication = ({ classes }) => {
  const formContext = useContext(CompetitionContext);

  return (
    <Box pb={5}>
      <Box px={4} className={classes.content} mb={4}>
        <CompetitionRegistrationEmail
          watch={formContext.watch}
          setValue={formContext.setValue}
          competition={formContext.competition}
        />
      </Box>

      <Box px={4} mb={4} className={classes.content}>
        <GroupsChats
          showTitle
          ownerableId={formContext.competition?.id}
          ownerableType="Competition"
        />
      </Box>

      <Box px={4} className={classes.content}>
        <CompetitionMessages competition={formContext.competition} />
      </Box>
    </Box>
  );
};

CompetitionCommunication.defaultProps = {};

CompetitionCommunication.propTypes = {};

export default CompetitionCommunication;
