import React, { useContext } from 'react';
import { Toolbar, AppBar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Box from 'core/ui/Box';

import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { sessionSelectors } from 'modules/session';
import { useTranslation } from 'react-i18next';
import { ItemMenu } from 'pages/shared/Layout/components/ItemMenu';
import { HelperToggleMenuItem } from 'pages/shared/Layout/components/Helper/HelperToggleMenuItem';
import { LayoutContext } from '..';

const sizeIcon = 25;

export default () => {
  const layoutContext = useContext(LayoutContext);
  const history = useHistory();
  const classes = useStyles();
  const isAuthenticated = useSelector((state) => sessionSelectors.getIsAuthenticated(state));
  const { t } = useTranslation();
  const currentUser = useSelector((state) => sessionSelectors.getUser(state));

  return (
    <AppBar className={classes.appBar} position="fixed">
      <Toolbar className={classes.toolbar}>
        <Box my={2} alignItems="center" display="flex" justifyContent="center" flexWrap="noWrap">
          {isAuthenticated && (
            <ItemMenu
              onClick={() => history.push('/dashboard')}
              size={sizeIcon + 2}
              mx={3}
              icon="icon-dashboard"
              active={layoutContext.activeMenu === 'dashboard'}
              color="white"
              label={t('appbar.menu.dashboard')}
            />
          )}
          {/* <ItemMenu
            onClick={() => history.push('/classes')}
            size={sizeIcon + 3}
            mx={3}
            icon="icon-classes"
            active={layoutContext.activeMenu === 'classes'}
            color="white"
            label={t('appbar.menu.classes')}
          /> */}
          <ItemMenu
            onClick={() =>
              currentUser.channel?.id
                ? history.push(`/channel/view/${currentUser.channel?.id}`)
                : history.push('/channels/selector')
            }
            size={sizeIcon + 2}
            mx={3}
            icon="icon-live-tv"
            active={layoutContext.activeMenu === 'channel'}
            color="white"
            label={t('appbar.menu.gym')}
          />
          {/* <ItemMenu
            onClick={() => history.push('/coaches')}
            size={sizeIcon + 3}
            mx={3}
            icon="icon-coaches"
            active={layoutContext.activeMenu === 'coaches'}
            color="white"
            label={t('appbar.menu.coaches')}
          /> */}
          <ItemMenu
            onClick={() => history.push('/competitions')}
            size={sizeIcon + 1}
            mx={3}
            icon="icon-kettlebell"
            active={layoutContext.activeMenu === 'competitions'}
            color="white"
            label={t('appbar.menu.competitions')}
          />

          <HelperToggleMenuItem size={sizeIcon} mx={3} />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

const useStyles = makeStyles(() => ({
  appBar: {
    background: '#404041',
    height: '72px',
    display: 'flex',
    justifyContent: 'center',
    top: 'auto',
    bottom: 0,
    zIndex: 1500,
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'center',
    minHeight: 10,
  },
  content: {
    flexGrow: 1,
  },
  logo: {
    width: 35,
  },
}));
