import React from 'react';
import Typography from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import { Dialog } from '@material-ui/core';
import Box from 'core/ui/Box';
import SelectorChannelUser from 'components/Channel/SelectorChannelUser';
import Texture from 'core/ui/Texture';

export default function ModalAddMember({ close, channelId, setSelectedUser, buttonColor }) {
  const { t } = useTranslation();
  const onSelect = (u) => {
    setSelectedUser(u);
  };

  return (
    <Dialog open maxWidth="sm" fullWidth onClose={() => close()}>
      <Texture>
        <Box p={3}>
          <Typography mb={3} align="center" color="secondary" variant="h2">
            {t('workout.modal.searchMembers')}
          </Typography>

          <SelectorChannelUser
            buttonColor={buttonColor}
            onSelectedUser={(u) => onSelect(u)}
            channelId={channelId}
          />
        </Box>
      </Texture>
    </Dialog>
  );
}

ModalAddMember.propTypes = {};

ModalAddMember.defaultProps = {};
