/* eslint-disable react/prop-types */
import React from 'react';
import { Publish } from '@material-ui/icons';
import Box from 'core/ui/Box';
import Paper from 'core/ui/Paper';
import { ButtonPrimary } from 'core/ui/Button';
import { InputLabel } from '@material-ui/core';
import Typography from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';

const SelectFile = ({ file, setFile }) => {
  const { t } = useTranslation();

  const handleCapture = ({ target }) => {
    if (target.files.length) {
      for (let i = 0; i < target.files.length; i += 1) {
        const f = target.files[i];
        setFile(f);
      }
    }
  };

  const styleIcon = {
    color: '#404041',
  };

  const randomId = Math.floor(Math.random() * 101);

  return (
    <Box style={{ position: 'relative', zIndex: 2 }}>
      <>
        <Paper className="paper-rounded" p={1}>
          <Box display="flex" alignItems="center">
            <Box display="flex" justifyContent="center">
              <ButtonPrimary variant="contained" p={1}>
                <InputLabel
                  style={{ color: 'white', cursor: 'pointer' }}
                  htmlFor={`icon-button-file-${randomId}`}
                >
                  <Box display="flex" alignItems="center">
                    <Publish style={{ ...styleIcon, color: 'white' }} size="small" />
                    <Typography ml={3} variant="subtitle2">
                      {(file && file.name) || t('button.selectCsvFile')}
                    </Typography>
                  </Box>
                </InputLabel>
              </ButtonPrimary>
              <input
                color="primary"
                type="file"
                onChange={handleCapture}
                id={`icon-button-file-${randomId}`}
                style={{ display: 'none' }}
                accept=".csv"
                multiple
              />
            </Box>
          </Box>
        </Paper>
      </>
    </Box>
  );
};

export default SelectFile;
