import React, { useContext, useEffect, useState } from 'react';
import { Toolbar, AppBar, useScrollTrigger, Hidden, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Box from 'core/ui/Box';
import Typography from 'core/ui/Typography';
import { useHistory, useLocation } from 'react-router';
import CssBaseline from '@material-ui/core/CssBaseline';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { sessionActions, sessionSelectors } from 'modules/session';
import { useTranslation } from 'react-i18next';
import { primary } from 'core/ui/Colors';
import logoLive from 'assets/img/app-icon-small.png';
import LoaderLine from 'core/ui/LoaderSm/LoaderLine';
import { appSelectors } from 'modules/app';
import { persistData, removeData, retrieveData } from 'core/utils/session-storage';
import Button from 'core/ui/Button';
import useChannelStyles from 'core/useChannelStyles';
import { channelsSelectors } from 'modules/channels';
import { LayoutContext } from '..';

import { ItemMenu, sizeIcon } from './ItemMenu';
import { HelperToggleMenuItem } from './Helper/HelperToggleMenuItem';

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

const StreamAppBar = (props) => {
  const layoutContext = useContext(LayoutContext);
  const history = useHistory();
  const { appLogo } = useChannelStyles();
  const channel = useSelector((state) => channelsSelectors.getChannel(state));
  const location = useLocation();
  const [showAdminBack, setShowAdminBack] = useState(false);
  const appLoading = useSelector((state) => appSelectors.getLoading(state));
  const currentUser = useSelector((state) => sessionSelectors.getUser(state));
  const isAuthenticated = useSelector((state) => sessionSelectors.getIsAuthenticated(state));
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const classes = useStyles();

  const canBackToAdmin = () => {
    const r = retrieveData('ADMIN_LOGIN');
    if (r) {
      setShowAdminBack(true);
    }
  };

  useEffect(() => {
    canBackToAdmin();
  }, [currentUser]);

  const backAdminLogin = () => {
    const data = retrieveData('ADMIN_LOGIN');
    if (data) {
      const obj = JSON.parse(data);
      Object.keys(obj).forEach((x) => {
        persistData(x, obj[x]);
      });
      dispatch(sessionActions.authenticateStart());
      history.push('/');
      setShowAdminBack(false);
      removeData('ADMIN_LOGIN');
    }
  };

  return (
    <div className="appBar">
      <CssBaseline />
      <ElevationScroll {...props}>
        <AppBar className={classes.appBar} position="fixed">
          <Toolbar
            className={classes.toolbar}
            style={{
              marginRight: layoutContext.drawer && !isMobile ? layoutContext.drawerWidth : 0,
            }}
          >
            <Box flexGrow={1} display="flex" alignItems="center">
              {location?.pathname?.indexOf('/channel/new') === -1 ? (
                <>
                  <Box
                    display="flex"
                    alignItems="center"
                    className="hover"
                    onClick={() => history.push(`/channel/view/${channel?.slug}`)}
                  >
                    <img src={appLogo || logoLive} alt="logo" className={classes.logo} />

                    <Typography
                      className={`${classes.title} roboto-medium`}
                      mx={4}
                      color="inherit"
                      noWrap
                    >
                      {channel?.name}
                      {!!layoutContext.titleBar && <>&nbsp;/&nbsp;{layoutContext.titleBar}</>}
                    </Typography>
                  </Box>
                  <Link
                    className="hover"
                    style={{ color: primary }}
                    onClick={() => history.push('/channels/selector')}
                  >
                    {t('button.switchGym')}
                  </Link>
                </>
              ) : (
                <Box
                  display="flex"
                  alignItems="center"
                  className="hover"
                  onClick={() => history.push(`/channel/view/${channel?.slug}`)}
                >
                  <img src={logoLive} alt="logo" className={classes.logo} />

                  <Typography
                    className={`${classes.title} roboto-medium`}
                    mx={4}
                    color="inherit"
                    noWrap
                  >
                    {layoutContext.titleBar}
                  </Typography>
                </Box>
              )}
            </Box>

            <Box
              my={2}
              alignItems="center"
              display="flex"
              justifyContent="center"
              flexWrap="noWrap"
            >
              {showAdminBack && (
                <Button onClick={() => backAdminLogin()}>
                  <Typography style={{ color: 'white' }}>BACK TO ADMIN</Typography>
                </Button>
              )}

              <Hidden only={['xs', 'sm']}>
                {isAuthenticated && (
                  <ItemMenu
                    onClick={() => history.push('/dashboard')}
                    size={sizeIcon + 2}
                    mx={5}
                    icon="icon-dashboard"
                    active={layoutContext.activeMenu === 'dashboard'}
                    color="white"
                    label={t('appbar.menu.dashboard')}
                  />
                )}
                {/* <ItemMenu
                  onClick={() => history.push('/classes')}
                  size={sizeIcon + 3.5}
                  mx={5}
                  icon="icon-classes"
                  active={layoutContext.activeMenu === 'classes'}
                  color="white"
                  label={t('appbar.menu.classes')}
                /> */}
                <ItemMenu
                  onClick={() =>
                    currentUser.channel?.id
                      ? history.push(`/channel/view/${currentUser.channel?.id}`)
                      : history.push('/channels/selector')
                  }
                  size={sizeIcon + 2}
                  mx={5}
                  icon="icon-live-tv"
                  active={layoutContext.activeMenu === 'channel'}
                  color="white"
                  label={t('appbar.menu.gym')}
                />
                {/* <ItemMenu
                  onClick={() => history.push('/coaches')}
                  size={sizeIcon + 3.5}
                  mx={5}
                  icon="icon-coaches"
                  active={layoutContext.activeMenu === 'coaches'}
                  color="white"
                  label={t('appbar.menu.coaches')}
                /> */}
                <ItemMenu
                  onClick={() => history.push('/competitions')}
                  size={sizeIcon + 1}
                  mx={5}
                  icon="icon-kettlebell"
                  active={layoutContext.activeMenu === 'competitions'}
                  color="white"
                  label={t('appbar.menu.competitions')}
                />

                <HelperToggleMenuItem mx={5} />
              </Hidden>

              <Hidden only={layoutContext.drawer ? ['xs', 'sm'] : []}>
                <>
                  <Hidden only={['xs', 'sm']}>
                    <ItemMenu
                      onClick={() => layoutContext.setDrawer((prev) => !prev)}
                      size={sizeIcon + 5}
                      ml={5}
                      mx={1}
                      icon="icon-action-nav"
                      active={layoutContext.drawer}
                      color={primary}
                      label={t('appbar.menu.more')}
                    />
                  </Hidden>

                  <Hidden only={['md', 'lg', 'xl']}>
                    <ItemMenu
                      onClick={() => layoutContext.setDrawer((prev) => !prev)}
                      size={sizeIcon + 5}
                      ml={5}
                      mx={1}
                      icon="icon-action-nav"
                      active={layoutContext.drawer}
                      color={primary}
                    />
                  </Hidden>
                </>
              </Hidden>
            </Box>
          </Toolbar>
          {appLoading && <LoaderLine loading />}
        </AppBar>
      </ElevationScroll>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  appBar: {
    background: '#404041',
    height: '72px',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      height: '45px',
    },
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'center',
    minHeight: 10,
  },
  content: {
    flexGrow: 1,
  },
  logo: {
    height: 44,
    width: 48,
    [theme.breakpoints.down('sm')]: {
      height: 30,
      width: 30,
    },
  },
  title: {
    fontSize: 20,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
}));

export default StreamAppBar;
