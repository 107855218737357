import React, { useEffect, useState } from 'react';
import Box from 'core/ui/Box';
import { useSelector } from 'react-redux';
import { sessionSelectors } from 'modules/session';
import Typography from 'core/ui/Typography';
import Divider from 'core/ui/Divider';
import Paper from 'core/ui/Paper';
import { plansModule } from 'modules/plans';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import useSelectors from 'modules/map/useSelectors';
import { ButtonPrimary } from 'core/ui/Button';
import { Add, Edit } from '@material-ui/icons';
import { currency } from 'core/utils/formatters';
import { useTranslation } from 'react-i18next';
import Check from '@material-ui/icons/Check';
import { Chip, Dialog } from '@material-ui/core';
import { successColor } from 'core/ui/Colors';
import LoaderSm from 'core/ui/LoaderSm';
import Grid from 'core/ui/Grid';
import ModalFormPlans from './Form/Modal';
import ResultChannels from '../Overview/ResultChannels';

const Plans = () => {
  const { t } = useTranslation();
  const [modalPlan, setModalPlan] = useState({ open: false });
  const currentUser = useSelector((state) => sessionSelectors.getUser(state));
  const isAdmin = useSelector((state) => sessionSelectors.getIsAdmin(state));
  const { request } = useActions();
  const [modalChannels, setModalChannels] = useState({ open: false });
  const plans = useSelectors(plansModule, 'data');
  const loading = useSelectors(plansModule, 'loading');
  const overviewPlan = useSelectors(plansModule, 'overviewPlan');
  const loadingChannels = useSelectors(plansModule, 'loadingChannels');

  const getPlans = () => {
    request({
      action: Modules.plans.actions.getPlansChannel,
    });
  };

  const getChannels = (planId) => {
    request({
      action: Modules.plans.actions.getChannels,
      data: {
        planId,
      },
    });
  };

  useEffect(() => {
    if (isAdmin) {
      getPlans();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  return (
    <Box>
      <Dialog
        maxWidth="lg"
        fullWidth
        open={modalChannels?.open}
        onClose={() => setModalChannels({ open: false })}
      >
        <Box>
          {loadingChannels && (
            <Box display="flex" alignItems="center" m={3} justifyContent="center">
              <LoaderSm loading />
            </Box>
          )}
          {overviewPlan && <ResultChannels channels={overviewPlan?.channels || []} />}
        </Box>
      </Dialog>
      <Box m={1}>
        <ButtonPrimary onClick={() => setModalPlan({ open: true })}>
          <Add />
        </ButtonPrimary>
      </Box>
      <Paper p={3}>
        {loading && (
          <Box display="flex" alignItems="center" m={3} justifyContent="center">
            <LoaderSm loading />
          </Box>
        )}
        {plans?.map((p, index) => (
          <Box key={p?.id}>
            <Box>
              <Box display="flex" alignItems="center" flexWrap="wrap">
                <Box flexGrow={1} display="flex" alignItems="center">
                  <Typography color="secondary" variant="h5">
                    {p.name}
                  </Typography>
                  <Box mx={2}>
                    {p.active && (
                      <Chip
                        style={{ background: successColor }}
                        icon={<Check style={{ color: 'white' }} />}
                        label={<Typography style={{ color: 'white' }}>{t('active')}</Typography>}
                      />
                    )}
                  </Box>
                </Box>
                <Box mx={2}>
                  <ButtonPrimary
                    onClick={() => {
                      getChannels(p?.id);
                      setModalChannels({ open: true });
                    }}
                  >
                    {t('button.channels')}
                  </ButtonPrimary>
                </Box>

                <Box mx={1}>
                  <ButtonPrimary onClick={() => setModalPlan({ open: true, plan: p })}>
                    <Edit />
                  </ButtonPrimary>
                </Box>
              </Box>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <Typography color="textSecondary">{t('channel.plan.online')}</Typography>
                  <Typography color="primary">{currency(p.amount)}</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography color="textSecondary">{t('channel.plan.in_person')}</Typography>
                  <Typography color="primary">{currency(p.amountInPerson)}</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography color="textSecondary">{t('channel.plan.member_app')}</Typography>
                  <Typography color="primary">{currency(p.amountMemberApp)}</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography color="textSecondary">{t('channel.plan.competition')}</Typography>
                  <Typography color="primary">{currency(p.amountCompetition)}</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography color="textSecondary">{t('channel.plan.sifely')}</Typography>
                  <Typography color="primary">{currency(p.amountSifely)}</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography color="textSecondary">{t('channel.plan.engage')}</Typography>
                  <Typography color="primary">{currency(p.amountEngage)}</Typography>
                </Grid>
              </Grid>
            </Box>
            {plans?.length - 1 > index && <Divider my={2} />}
          </Box>
        ))}
      </Paper>
      <ModalFormPlans
        plan={modalPlan?.plan}
        open={modalPlan?.open}
        close={() => setModalPlan({ open: false })}
        callbackSave={() => {
          setModalPlan({ open: false });
          getPlans();
        }}
      />
    </Box>
  );
};

export default Plans;
