import React, { useState } from 'react';
import Box from 'core/ui/Box';
import { secondary } from 'core/ui/Colors';
import Paper from 'core/ui/Paper';
import Typography from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Add, KeyboardArrowDown, KeyboardArrowRight } from '@material-ui/icons';
import { Collapse } from '@material-ui/core';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import { sessionSelectors } from 'modules/session';
import ModalAddPaymentMethod from './ModalAddPaymentMethod';
import ManageChannelPaymentMethods from './ManageList';

const OwnerablePaymentMethods = ({ ownerableType, ownerableId, onlyMethod, callbackSave }) => {
  const { t } = useTranslation();
  const { request } = useActions();
  const [openPaymentMethods, setOpenPaymentMethods] = useState(false);
  const user = useSelector((state) => sessionSelectors.getUser(state));
  const [openModalAddPaymentMethod, setOpenModalAddPaymentMethod] = useState(false);
  const styleIcon = {
    color: secondary,
    fontSize: 20,
  };

  return (
    <Box>
      {user && user.id && (
        <ModalAddPaymentMethod
          open={openModalAddPaymentMethod}
          setOpen={setOpenModalAddPaymentMethod}
          onClose={() => setOpenModalAddPaymentMethod(false)}
          userId={user.id}
          userInfo={{
            name: user?.name,
            email: user?.email,
            phone: user?.phone,
          }}
          ownerableId={ownerableId}
          ownerableType={ownerableType}
          onlyMethod={onlyMethod}
          onRefresh={() => {
            request({
              action: Modules.ownerablePaymentMethods.actions.getOwnerablePaymentMethods,
              data: { ownerableId, ownerableType },
            });

            if (callbackSave) {
              callbackSave();
            }
          }}
        />
      )}

      <Paper className="paper-rounded" p={5}>
        <Box flexGrow="1" display="flex" alignItems="center">
          <Box
            display="flex"
            alignItems="center"
            onClick={() => setOpenPaymentMethods(!openPaymentMethods)}
          >
            {openPaymentMethods ? (
              <KeyboardArrowDown style={{ ...styleIcon }} />
            ) : (
              <KeyboardArrowRight style={{ ...styleIcon }} />
            )}
          </Box>
          <Box
            flexGrow="1"
            display="flex"
            alignItems="center"
            onClick={() => setOpenPaymentMethods(!openPaymentMethods)}
          >
            <Typography ml={1} color="secondary" className="link" variant="h5">
              {t('user.paymentMethods').toUpperCase()}
            </Typography>
          </Box>
          <Box
            className="link-hover"
            display="flex"
            alignItems="center"
            onClick={() => setOpenModalAddPaymentMethod(true)}
          >
            <Add style={{ fontSize: 20 }} />
          </Box>
        </Box>
        <Collapse in={openPaymentMethods}>
          {user && user.id && (
            <ManageChannelPaymentMethods
              userId={user.id}
              ownerableType={ownerableType}
              ownerableId={ownerableId}
              setOpenCollapse={setOpenPaymentMethods}
            />
          )}
        </Collapse>
      </Paper>
    </Box>
  );
};

export default OwnerablePaymentMethods;
