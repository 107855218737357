import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

import * as selectors from './selectors';
import * as sagas from './sagas';

/* ---------- Action Creators and Types ---------- */
const { Types, Creators } = createActions(
  {
    createError: ['error'],
    createStart: ['workoutId', 'workoutsInviteId'],
    createSuccess: [],

    getTokenError: ['error'],
    getTokenStart: ['workoutId', 'workoutsInviteId'],
    getTokenSuccess: ['token'],

    startWorkoutCompetitionError: ['error'],
    startWorkoutCompetitionStart: ['data'],
    startWorkoutCompetitionSuccess: [''],

    resetWorkoutCompetitionError: ['error'],
    resetWorkoutCompetitionStart: ['data'],
    resetWorkoutCompetitionSuccess: [''],

    postChatMessageError: ['error'],
    postChatMessageStart: ['data'],
    postChatMessageSuccess: ['message'],

    postEventError: ['error'],
    postEventStart: ['data'],
    postEventSuccess: ['event'],

    getEventsError: ['error'],
    getEventsStart: ['workoutId'],
    getEventsSuccess: ['events'],

    postAccessError: ['error'],
    postAccessStart: ['data'],
    postAccessSuccess: ['data'],

    startRecordingError: ['error'],
    startRecordingStart: ['data'],
    startRecordingSuccess: ['data'],

    getRecordingStatusError: ['error'],
    getRecordingStatusStart: ['data'],
    getRecordingStatusSuccess: ['data'],

    stopRecordingError: ['error'],
    stopRecordingStart: ['data'],
    stopRecordingSuccess: ['data'],

    postAccessDisconnectError: ['error'],
    postAccessDisconnectStart: ['data'],
    postAccessDisconnectSuccess: ['data'],

    processLocalRecordingError: ['error'],
    processLocalRecordingStart: ['data'],
    processLocalRecordingSuccess: ['data'],

    clearToken: [],
  },
  { prefix: 'sessionWorkout/' },
);

/* ---------- Initial State ---------- */
const INITIAL_STATE = Immutable({
  error: null,
  loading: false,
  loadingRecordings: false,
  recording: {},
  token: null,
  message: {},
  access: {},
  events: [],
});

/* ---------- Reducers ---------- */
const loadStartReducer = (state) => state.set('loading', true);
const loadRecordingsStartReducer = (state) => state.set('loadingRecordings', true);
const clearTokenReducer = (state) => state.set('token', null);

const loadErrorReducer = (state, action) =>
  state.merge({
    token: '',
    loadingRecordings: false,
    loading: false,
    error: action.error,
  });

const loadRecordingStatusErrorReducer = (state, action) =>
  state.merge({
    recording: {},
    loadingRecordings: false,
    error: action.error,
  });

const createSuccessReducer = (state) =>
  state.merge({
    loading: false,
    error: null,
  });

const getTokenSuccessReducer = (state, action) =>
  state.merge({
    token: action.token,
    loading: false,
    error: null,
  });

const postChatMessageSuccessReducer = (state, action) =>
  state.merge({
    message: action.message,
    loading: false,
    error: null,
  });

const postAccessSuccessReducer = (state, action) =>
  state.merge({
    access: { ...action.data },
    loading: false,
    error: null,
  });

const startRecordingSuccessReducer = (state, action) =>
  state.merge({
    recording: { ...action.data },
    loadingRecordings: false,
    error: null,
  });

const stopRecordingSuccessReducer = (state, action) =>
  state.merge({
    recording: { ...action.data },
    loadingRecordings: false,
    error: null,
  });

const postAccessDisconnectSuccessReducer = (state, action) =>
  state.merge({
    access: { ...action.data },
    loading: false,
    error: null,
  });

const postEventSuccessReducer = (state) =>
  state.merge({
    loading: false,
    error: null,
  });

const getRecordingStatusSuccessReducer = (state, action) =>
  state.merge({
    recording: { ...action.data },
    loadingRecordings: false,
    error: null,
  });

const getEventsSuccessReducer = (state, action) =>
  state.merge({
    events: action.events,
    loading: false,
    error: null,
  });

const startWorkoutCompetitionSuccessReducer = (state) =>
  state.merge({
    loading: false,
    error: null,
  });

const resetWorkoutCompetitionSuccessReducer = (state) =>
  state.merge({
    loading: false,
    error: null,
  });

const processLocalRecordingSuccessReducer = (state) =>
  state.merge({
    loading: false,
    error: null,
  });

/* ---------- Main Reducer ---------- */
const reducer = createReducer(INITIAL_STATE, {
  [Types.CREATE_START]: loadStartReducer,
  [Types.CREATE_SUCCESS]: createSuccessReducer,
  [Types.CREATE_ERROR]: loadErrorReducer,

  [Types.GET_TOKEN_START]: loadStartReducer,
  [Types.GET_TOKEN_SUCCESS]: getTokenSuccessReducer,
  [Types.GET_TOKEN_ERROR]: loadErrorReducer,

  [Types.START_WORKOUT_COMPETITION_START]: loadStartReducer,
  [Types.START_WORKOUT_COMPETITION_SUCCESS]: startWorkoutCompetitionSuccessReducer,
  [Types.START_WORKOUT_COMPETITION_ERROR]: loadErrorReducer,

  [Types.RESET_WORKOUT_COMPETITION_START]: loadStartReducer,
  [Types.RESET_WORKOUT_COMPETITION_SUCCESS]: resetWorkoutCompetitionSuccessReducer,
  [Types.RESET_WORKOUT_COMPETITION_ERROR]: loadErrorReducer,

  [Types.POST_CHAT_MESSAGE_START]: loadStartReducer,
  [Types.POST_CHAT_MESSAGE_SUCCESS]: postChatMessageSuccessReducer,
  [Types.POST_CHAT_MESSAGE_ERROR]: loadErrorReducer,

  [Types.GET_EVENTS_START]: loadStartReducer,
  [Types.GET_EVENTS_SUCCESS]: getEventsSuccessReducer,
  [Types.GET_EVENTS_ERROR]: loadErrorReducer,

  [Types.POST_EVENT_START]: loadStartReducer,
  [Types.POST_EVENT_SUCCESS]: postEventSuccessReducer,
  [Types.POST_EVENT_ERROR]: loadErrorReducer,

  [Types.POST_ACCESS_START]: loadStartReducer,
  [Types.POST_ACCESS_SUCCESS]: postAccessSuccessReducer,
  [Types.POST_ACCESS_ERROR]: loadErrorReducer,

  [Types.START_RECORDING_START]: loadRecordingsStartReducer,
  [Types.START_RECORDING_SUCCESS]: startRecordingSuccessReducer,
  [Types.START_RECORDING_ERROR]: loadRecordingStatusErrorReducer,

  [Types.GET_RECORDING_STATUS_START]: loadRecordingsStartReducer,
  [Types.GET_RECORDING_STATUS_SUCCESS]: getRecordingStatusSuccessReducer,
  [Types.GET_RECORDING_STATUS_ERROR]: loadRecordingStatusErrorReducer,

  [Types.STOP_RECORDING_START]: loadRecordingsStartReducer,
  [Types.STOP_RECORDING_SUCCESS]: stopRecordingSuccessReducer,
  [Types.STOP_RECORDING_ERROR]: loadRecordingStatusErrorReducer,

  [Types.POST_ACCESS_DISCONNECT_START]: loadStartReducer,
  [Types.POST_ACCESS_DISCONNECT_SUCCESS]: postAccessDisconnectSuccessReducer,
  [Types.POST_ACCESS_DISCONNECT_ERROR]: loadErrorReducer,

  [Types.PROCESS_LOCAL_RECORDING_START]: loadStartReducer,
  [Types.PROCESS_LOCAL_RECORDING_SUCCESS]: processLocalRecordingSuccessReducer,
  [Types.PROCESS_LOCAL_RECORDING_ERROR]: loadErrorReducer,

  [Types.CLEAR_TOKEN]: clearTokenReducer,
});

/* ---------- Exporting ---------- */
export const sessionWorkoutActions = Creators;
export const sessionWorkoutTypes = Types;
export const sessionWorkoutSelectors = selectors;
export const sessionWorkoutSagas = sagas;

export default reducer;
