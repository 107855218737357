import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import DatePickerRounded from 'core/ui/DatePickers/DatePickerRounded';
import { ButtonPrimary } from 'core/ui/Button';
import { Search } from '@material-ui/icons';
import { formatDateToApi } from 'core/utils/formatters';
import { channelReportsModule } from 'modules/channelReports';
import useSelectors from 'modules/map/useSelectors';
import { timeZoneName } from 'core/utils/formatters/date';
import Grid from 'core/ui/Grid';
import ChoosenChannelTags from 'core/ui/ChoosenChannelTags';
import Paper from 'core/ui/Paper';
import Toggle from 'core/ui/Toggle';
import SkeletonPayamentsReport from './Skeleton';
import ResultPayments from '../Payments/ResultPayments';

const ActionReport = ({ loading, getReport, channel }) => {
  const { t } = useTranslation();
  const [perPage, setPerPage] = useState(10);
  const data = useSelectors(channelReportsModule, 'data');
  const dateNow = new Date();
  const [filters, setFilters] = useState({
    startAt: `${dateNow.getMonth() + 1}/1/${dateNow.getFullYear()}`,
    endAt: `${dateNow.getMonth() + 1}/${dateNow.getDate()}/${dateNow.getFullYear()}`,
    type: 'offline',
  });

  const search = (page = 1) => {
    const startAt = filters.startAt ? formatDateToApi(filters.startAt, timeZoneName()) : null;
    const endAt = filters.endAt ? formatDateToApi(filters.endAt, timeZoneName()) : null;
    if (startAt && endAt) getReport({ startAt, endAt, page, perPage, type: filters?.type });
  };

  useEffect(() => {
    if (channel?.id) {
      search();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [perPage, channel]);

  return (
    <Box mt={2}>
      <Box>
        <Paper p={3} mb={3}>
          <Box
            display="flex"
            mb={3}
            alignItems="flex-end"
            justifyContent="flex-start"
            flexWrap="wrap"
          >
            <Box>
              <DatePickerRounded
                label={t('channel.reports.filter.start').toUpperCase()}
                onChange={(v) => setFilters((prev) => ({ ...prev, startAt: v }))}
                value={filters.startAt}
                disablePast={false}
                usFormat={channel?.useUsDateFormat}
                autoOk
                bordered
              />
            </Box>
            <Box ml={1}>
              <DatePickerRounded
                label={t('channel.reports.filter.end').toUpperCase()}
                onChange={(v) => setFilters((prev) => ({ ...prev, endAt: v }))}
                value={filters.endAt}
                disablePast={false}
                usFormat={channel?.useUsDateFormat}
                autoOk
                bordered
              />
            </Box>
            <Box ml={1}>
              <Toggle
                onChange={(v) => setFilters((prev) => ({ ...prev, type: v }))}
                options={[
                  { label: 'Offline', value: 'offline', width: 120 },
                  { label: 'Failed', value: 'error', width: 120 },
                  { label: 'Requires Action', value: 'pending', width: 120 },
                ]}
                value={filters?.type}
                color="white"
              />
            </Box>
            <Box ml={3}>
              <ButtonPrimary disabled={loading} onClick={() => search()}>
                <Search style={{ color: 'white' }} fontSize="small" />
                {t('button.search')}&nbsp;
              </ButtonPrimary>
            </Box>
          </Box>
          <Grid container spacing={5}>
            <Grid item xs={12} md={4}>
              <ChoosenChannelTags
                label={t('channel.label.memberTags')}
                placeholder={t('channel.tags')}
                val={filters?.membersTags ? [...filters?.membersTags] : []}
                setValue={(v) =>
                  setFilters((prev) => ({ ...prev, membersTags: v?.map((x) => x?.name || x) }))
                }
                channelId={channel?.id}
                bordered
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <ChoosenChannelTags
                label={t('channel.label.membershipsTags')}
                placeholder={t('channel.tags')}
                val={filters?.membershipsTags ? [...filters?.membershipsTags] : []}
                setValue={(v) =>
                  setFilters((prev) => ({ ...prev, membershipsTags: v?.map((x) => x?.name || x) }))
                }
                channelId={channel?.id}
                bordered
              />
            </Grid>
          </Grid>
        </Paper>

        {!data?.payments || loading ? (
          <SkeletonPayamentsReport />
        ) : (
          <Box>
            <ResultPayments
              channel={channel}
              pagination={data?.pagination}
              rows={data?.payments || []}
              getReport={search}
              perPage={perPage}
              setPerPage={setPerPage}
              failedTransactions
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

ActionReport.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  loading: PropTypes.bool,
  getReport: PropTypes.func,
};

ActionReport.defaultProps = {
  loading: false,
  getReport: () => {},
};

export default ActionReport;
