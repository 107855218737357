import React, { useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import { useDispatch } from 'react-redux';

import Modules from 'modules';
import useActions from 'modules/map/useActions';
import notifications from 'modules/notifications';
import { ButtonPrimary } from 'core/ui/Button';
import { primary } from 'core/ui/Colors';
import { useStyles } from './styles';

export const AddMapToHelpVideoButton = ({
  helpVideo,
  currentPath,
  alreadyAdded = false,
  getMappedHelpVideos,
}) => {
  const dispatch = useDispatch();
  const { request } = useActions();
  const classes = useStyles();

  const [loading, setLoading] = useState();
  const [added, setAdded] = useState(alreadyAdded);

  const addVideoToPath = () => {
    setLoading(true);

    request({
      action: Modules.helpVideoMap.actions.create,
      data: {
        helpVideoId: helpVideo.id,
        body: {
          path: currentPath,
        },
      },
      options: {
        onSuccess: () => {
          setLoading(false);
          setAdded(true);

          // Ensure that if the component unmounts, we have the current alreadyAdded
          getMappedHelpVideos();
        },
        onError: (err) => {
          setLoading(false);
          dispatch(notifications.error(err));
        },
      },
    });
  };

  return (
    <ButtonPrimary
      buttonColor={primary}
      disabled={alreadyAdded || loading || added}
      onClick={addVideoToPath}
    >
      {/* eslint-disable-next-line no-nested-ternary */}
      {loading ? (
        <CircularProgress size="1rem" className={classes.loader} />
      ) : added ? (
        <DoneIcon htmlColor="white" />
      ) : (
        'Add'
      )}
    </ButtonPrimary>
  );
};
