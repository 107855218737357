/* eslint-disable prefer-destructuring */
import { APP_BASE_URL } from 'core/env';
import humps from 'humps';
import moment from 'moment';
import { K1, typeTimer, Z2 } from './consts';
import { date, formatDateToApi } from './formatters';

export const getBase64 = (file, cb) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    cb(reader.result);
  };
  reader.onerror = (error) => {
    console.log('Error: ', error);
  };
};

export const mKY = () => `${K1}${Z2}`;

// const params = { Name: 'Mayander', LastName: 'Martins', testings: [1, 2, 3] };

export const serializeRansak = (params, prefix) => {
  const query = Object.keys(params).map((key) => {
    let keyParam = key;
    const value = params[key];

    if (params.constructor === Array) keyParam = `${prefix}[]`;
    else if (params.constructor === Object) keyParam = prefix ? `${prefix}[${keyParam}]` : keyParam;

    if (typeof value === 'object') return serializeRansak(value, keyParam);
    return `${keyParam}=${encodeURIComponent(value)}`;
  });

  return query.join('&');
};

export const exportCsv = (data, name = 'export_csv_file') => {
  const csvContent = `data:application/octet-stream;charset=utf-8,${data}`;
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', `${name}_${new Date().getTime()}.csv`);
  link.click();
};

export const textStoreOptions = (inventory) => {
  if (inventory.kind) {
    return inventory.kind;
  }

  if (!inventory?.optionSecond) {
    return `${inventory?.optionFirst?.name || ''}`;
  }
  if (!inventory?.optionThird) {
    return `${inventory?.optionFirst?.name || ''} - ${inventory?.optionSecond?.name || ''}`;
  }
  return `${inventory?.optionFirst?.name} - ${inventory?.optionSecond?.name} - ${inventory?.optionThird?.name}`;
};

export const formatNumberTime = (v) => {
  if (!v) {
    return '00';
  }
  if (v.length === 1) {
    return `0${v}`;
  }
  return v;
};

export const formatNumberMilhar = (n) => Number(n).toLocaleString('en');

export const convertToSeconds = (formatedTimer) => {
  if (!formatedTimer) {
    return 0;
  }
  const arrayTimer = formatedTimer.split(':');

  let seconds =
    Number(arrayTimer[0]) * 60 * 60 + Number(arrayTimer[1]) * 60 + Number(arrayTimer[2]);

  if (arrayTimer[3] && !isNaN(arrayTimer[3])) {
    seconds = `${seconds}.${arrayTimer[3]}`;
  }

  return seconds;
};

export const convertSecondsToObject = (time) => {
  if (!time) {
    return {};
  }
  const workTimer = time.split(':');
  return {
    hours: workTimer && workTimer[0],
    minutes: workTimer && workTimer[1],
    seconds: workTimer && workTimer[2],
    milliseconds: workTimer && workTimer[3],
    value: time,
  };
};

export function convertSecondsToTimeString(durationSeconds) {
  const durSec = durationSeconds * 1000;

  const milliseconds = Math.floor((durSec % 1000) / 100);

  let seconds = Math.floor((durSec / 1000) % 60);
  let minutes = Math.floor((durSec / (1000 * 60)) % 60);
  let hours = Math.floor((durSec / (1000 * 60 * 60)) % 24);

  hours = hours < 10 ? `0${hours}` : hours;
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  seconds = seconds < 10 ? `0${seconds}` : seconds;

  return `${hours}:${minutes}:${seconds}:${milliseconds}`;
}

export const paginateArray = (array, pageSize, pageNumber) =>
  array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);

export const loadScript = (attributes) => {
  if (!attributes || !attributes.source) {
    throw new Error('Invalid attributes');
  }

  return new Promise((resolve, reject) => {
    const { async, defer, id, source } = {
      async: false,
      defer: false,
      source: '',
      ...attributes,
    };

    const scriptTag = document.getElementById('spotify-player');

    if (!scriptTag) {
      const script = document.createElement('script');

      script.id = id || '';
      script.type = 'text/javascript';
      script.async = async;
      script.defer = defer;
      script.src = source;
      script.onload = () => resolve(undefined);
      // eslint-disable-next-line prefer-promise-reject-errors
      script.onerror = (error) => reject(`createScript: ${error.message}`);

      document.head.appendChild(script);
    } else {
      resolve();
    }
  });
};

export const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

export const copyToClipBoard = async (copyMe, setText = () => {}) => {
  try {
    await navigator.clipboard.writeText(copyMe);
    setText('Link copied');
  } catch (err) {
    setText('Failed to copy!');
  }
};

export const hasScore = (scores, score) => {
  if (!scores) {
    return false;
  }
  return (
    scores[score] !== null &&
    (scores[score] === '1' || scores[score] === true || typeof scores[score] === 'object')
  );
};

export const hasBenchmarkScore = (scores, score) => scores[score] === '1' || scores[score] === true;

export const listScores = (section, t) => {
  const scores = {};
  if (section.scores) {
    section.scores.forEach((itemScore) => {
      if (hasScore(itemScore, 'timeCapped') === true) {
        scores[t('workout.section.label.score.time')] = 1;
      } else {
        if (hasScore(itemScore, 'time') === true) {
          scores[t('workout.section.label.score.time')] = 1;
        }
        if (hasScore(itemScore, 'rounds') === true) {
          scores[t('workout.section.label.score.rounds')] = 1;
        }
        if (hasScore(itemScore, 'roundReps') === true) {
          scores[t('workout.section.label.score.round_reps')] = 1;
        }
        if (hasScore(itemScore, 'reps') === true) {
          scores[t('workout.section.label.score.reps')] = 1;
        }
        if (hasScore(itemScore, 'weight') === true) {
          scores[t('workout.section.label.score.weight')] = 1;
        }
        if (hasScore(itemScore, 'calories') === true) {
          scores[t('workout.section.label.score.calories')] = 1;
        }
        if (hasScore(itemScore, 'distance') === true) {
          scores[t('workout.section.label.score.distance')] = 1;
        }
        if (hasScore(itemScore, 'completion') === true) {
          scores[t('workout.section.label.score.completion')] = 1;
        }
        if (hasScore(itemScore, 'tieBreak') === true) {
          scores[t('workout.section.label.score.tieBreak')] = 1;
        }
        if (hasScore(itemScore, 'liftComplex') === true) {
          scores[t('workout.section.label.score.liftComplex')] = 1;
        }
      }
    });
  }

  if (Object.keys(scores).length > 0) {
    return Object.keys(scores).join(', ');
  }
  return null;
};

export const variationScoreName = (channel, id, t) => {
  if (id === 'prescribed' || id === 'scaled') {
    return t(`workout.section.label.score.${id}`);
  }

  // eslint-disable-next-line eqeqeq
  return channel?.scoresVariations?.find((x) => x.id == id)?.name;
};

export const showScore = (score, type) => {
  if (!score) {
    return '';
  }

  if (type === 'time') {
    const dataScore = score.split(':');

    if (Number(dataScore[0]) > 0) {
      return `${Number(dataScore[0])}:${dataScore[1] || '00'}:${dataScore[2] || '00'}`;
    }
    if (Number(dataScore[1]) === 0) {
      return `${Number(dataScore[1])}:${dataScore[2]}`;
    }
    if (Number(dataScore[1]) < 9) {
      return `${Number(dataScore[1])}:${dataScore[2]}`;
    }

    return `${Number(dataScore[1])}:${dataScore[2]}`;
  }

  if (type === 'completion') {
    return score === '1' ? 'YES' : 'NO';
  }

  return score;
};

export const getPrScore = (history) => {
  if (history.scoreType === 'rep_max') {
    return (
      history.scores.repMax1 ||
      history.scores.repMax2 ||
      history.scores.repMax3 ||
      history.scores.repMax4
    );
  }
  return history.scores[humps.camelize(history.scoreType)];
};

export const snakeToCamel = (str) =>
  str
    .toLowerCase()
    .replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('-', '').replace('_', ''));

export const getHistoryScore = (sectionUser, scoreType, index) => {
  if (!sectionUser.scores || !sectionUser.scores[index]) {
    return '';
  }
  if (scoreType === 'roundReps') {
    return sectionUser.scores[index].roundRepsRounds;
  }
  if (scoreType === 'completion') {
    return sectionUser.scores[index]?.completion === '1' ? 'YES' : 'NO';
  }
  return sectionUser.scores[index][humps.camelize(scoreType)];
};

export const workoutUrlSlug = (workout) => workout.shareUrl;

export const competitionUrlSlug = (competition) => competition.shareUrl;

export const channelUrlSlug = (channel) => channel.shareUrl;

export const coachUrlSlug = (coach) => coach.shareUrl;

export const validateTime = (time, timerData) => {
  try {
    if (!timerData[time]) {
      return false;
    }
    if (
      Number(timerData[time].hours) <= 0 &&
      Number(timerData[time].minutes) <= 0 &&
      Number(timerData[time].seconds) <= 0
    ) {
      return false;
    }
  } catch {
    return false;
  }
  return true;
};

export const validateEmail = (email) => {
  if (email) {
    return email.indexOf('@') > -1;
  }
  return false;
};

export const valueTaxAmount = (amount, salesTax) => {
  if (!salesTax) {
    return 0;
  }

  if (salesTax?.amountType === 'money') {
    return Number(salesTax?.amount);
  }

  return (Number(amount) / 100) * Number(salesTax?.amount);
};

export const calculateServiceFees = (plan, amount) => {
  let retFee = 0;
  const mAmount = Number(amount);

  if (!plan.fees || mAmount <= 0) {
    return mAmount;
  }

  plan.fees.forEach((fee) => {
    if (fee.amountType === 'percent') {
      retFee += (Number(mAmount) / 100) * Number(fee.amount);
    } else {
      retFee += Number(fee.amount);
    }
  });

  return mAmount + retFee;
};

export const replaceAll = (string, search, replace) => {
  if (!string) {
    return '';
  }
  return string.split(search).join(replace);
};

export const uCase = (string) => {
  if (string && string !== undefined) {
    return string.toUpperCase();
  }
  return '';
};

export function getVideoTypeByLink(videolink) {
  // eslint-disable-next-line no-useless-escape
  let regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
  let match = videolink.match(regExp);
  const videoType = {};
  if (match && match[7].length === 11) {
    videoType.type = 'youtube';
    videoType.id = match[7];
    return videoType;
  }
  regExp = 'vimeo\\.com/(?:.*#|.*/videos/)?([0-9]+)';
  match = videolink.match(regExp);
  if (match) {
    const videoid = videolink.split('/')[videolink.split('/').length - 1];
    videoType.type = 'vimeo';
    videoType.id = videoid;
    return videoType;
  }
  return { type: 'direct', id: null };
}

export const toSnackCase = (value) =>
  value.replace(/[A-Z]/g, (val) => `_${val.toLowerCase()}`).replace(/^_/, '');

export const camalize = function camalize(str) {
  return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
};

export const convertTimerToForm = (timerData) => {
  if (!timerData) {
    return { type: 'none' };
  }

  if (timerData?.type !== 'none') {
    let { customIntervalTimers } = timerData;
    let work = null;
    let rest = null;
    let timeStart = null;

    if (timerData.timeStart) {
      const start = timerData.timeStart.split(':');
      timeStart = {
        hours: start && start[0],
        minutes: start && start[1],
        seconds: start && start[2],
      };
    }

    if (timerData.customInterval) {
      customIntervalTimers = timerData.customIntervalTimers.map((ct) => {
        const customTimer = { ...ct };

        const workTimer = ct.work.split(':');
        customTimer.work = {
          hours: workTimer[0],
          minutes: workTimer[1],
          seconds: workTimer[2],
        };

        if (ct.rest) {
          const restTimer = ct.rest.split(':');
          customTimer.rest = {
            hours: restTimer && restTimer[0],
            minutes: restTimer && restTimer[1],
            seconds: restTimer && restTimer[2],
          };
        }
        return customTimer;
      });
    } else {
      if (!timerData.unlimited && timerData.work) {
        const workTimer = timerData.work.split(':');
        work = {
          hours: workTimer[0],
          minutes: workTimer[1],
          seconds: workTimer[2],
        };
      }

      let restTimer = null;
      if (timerData.rest) {
        restTimer = timerData.rest.split(':');
        rest = {
          hours: restTimer && restTimer[0],
          minutes: restTimer && restTimer[1],
          seconds: restTimer && restTimer[2],
        };
      }
    }

    return {
      ...timerData,
      customIntervalTimers,
      work,
      rest,
      timeStart,
    };
  }

  return { type: 'none' };
};

export const convertTimerToApi = ({ timerData, isVideoClass }) => {
  let work = null;
  let rest = null;
  let timeStart = null;
  let customIntervalTimers = null;
  if (timerData.type !== 'none') {
    if (isVideoClass) {
      timeStart = `${formatNumberTime(timerData.timeStart.hours)}:${formatNumberTime(
        timerData.timeStart.minutes,
      )}:${formatNumberTime(timerData.timeStart.seconds)}`;
    }

    if (!timerData.customInterval) {
      if (!timerData.unlimited) {
        work = `${formatNumberTime(timerData.work.hours)}:${formatNumberTime(
          timerData.work.minutes,
        )}:${formatNumberTime(timerData.work.seconds)}`;
      }

      if (
        timerData.rest &&
        (timerData.type === typeTimer.tabata || timerData.type === typeTimer.interval)
      ) {
        rest = `${formatNumberTime(timerData.rest.hours)}:${formatNumberTime(
          timerData.rest.minutes,
        )}:${formatNumberTime(timerData.rest.seconds)}`;
      }
    } else {
      customIntervalTimers = timerData.customIntervalTimers.map((x) => {
        const ct = { ...x };
        // eslint-disable-next-line no-param-reassign
        ct.work = `${formatNumberTime(x.work.hours)}:${formatNumberTime(
          x.work.minutes,
        )}:${formatNumberTime(x.work.seconds)}`;

        if (x.rest) {
          // eslint-disable-next-line no-param-reassign
          ct.rest = `${formatNumberTime(x.rest.hours)}:${formatNumberTime(
            x.rest.minutes,
          )}:${formatNumberTime(x.rest.seconds)}`;
        }
        return ct;
      });
    }
  }

  return {
    work,
    rest,
    timeStart,
    customIntervalTimers,
  };
};

export const workoutChannelPrograms = (wSections) => {
  try {
    if (!wSections) {
      return [];
    }
    const programs = {};
    wSections.forEach((x) => {
      programs[x.channelProgram?.id] = x.channelProgram;
    });

    return Object.keys(programs)
      .filter((x) => !!programs[x])
      .map((x) => programs[x]);
  } catch {
    return [];
  }
};

export const abbrevName = (name, max) => {
  if (!name) {
    return '';
  }

  if (name?.length > max) {
    return `${name?.substring(0, max)}...`;
  }

  return name;
};

export const linkStoreItem = (channelId, productId) =>
  `${APP_BASE_URL}/channel/view/${channelId}/store/${productId}`;

export const dynamicSort = (property) => {
  let sortOrder = 1;
  if (property[0] === '-') {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a, b) {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     */
    // eslint-disable-next-line no-nested-ternary
    const result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
};

export const moveOrder = (from, to, arr) => {
  if (from === to || from < 0 || to < 0) {
    return arr;
  }
  const moveNumber = arr[from];
  if (from < to) {
    for (var i = from; i < to; i++) {
      arr[i] = arr[i + 1];
    }
  } else {
    for (var i = from; i > to; i--) {
      arr[i] = arr[i - 1];
    }
  }
  arr[to] = moveNumber;
  return arr;
};

export const populateVideos = (sec) => {
  const mediaFilesAssociationsAttributes = [];

  const medias = sec?.mediaFilesAssociationsAttributes
    ? sec?.mediaFilesAssociationsAttributes
    : sec.mediaFilesAssociations;

  if (medias) {
    medias.forEach((item) => {
      mediaFilesAssociationsAttributes.push({
        id: item?.id,
        mediaFile: item?.mediaFile,
        mediaFileId: item?.mediaFile?.id,
        kind: item.kind,
        _destroy: item?._destroy,
      });
    });
  }

  return mediaFilesAssociationsAttributes;
};

export const clearSectionIds = (section) => {
  const data = {
    ...section,
    mediaFilesAssociations: section?.mediaFilesAssociations?.map((ass) => ({
      mediaFileId: ass.mediaFile?.id,
      mediaFile: ass.mediaFile,
      kind: ass.kind,
    })),
    mediaFilesAssociationsAttributes: section?.mediaFilesAssociations?.map((ass) => ({
      mediaFileId: ass.mediaFile?.id,
      mediaFile: ass.mediaFile,
      kind: ass.kind,
    })),
  };

  return data;
};

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const formatWeekDays = (data) => {
  let array = [];

  Object.keys(data).forEach((itemDay) => {
    const newItems = data[itemDay]?.map((x) => ({
      id: x?.id,
      _destroy: x?._destroy,

      startTime: formatDateToApi(x.startTime, null, true),
      endTime: formatDateToApi(x.endTime, null, true),
      dayOfWeek: itemDay,
    }));

    array = [...array, ...newItems];
  });

  return array;
};

export const formatWeekDaysToObj = (weeklyHours, toForm) => {
  const obj = {};

  weeklyHours?.forEach((x) => {
    if (!obj[x?.dayOfWeek]) {
      obj[x.dayOfWeek] = [];
    }

    obj[x.dayOfWeek].push({
      ...x,

      startTime: toForm ? `${date(moment(), 'MM-01-yyyy')} ${x.startTime}` : x.startTime,
      endTime: toForm ? `${date(moment(), 'MM-01-yyyy')} ${x.endTime}` : x.endTime,
    });
  });

  return obj;
};

export const validateFullname = (value) => {
  const regexp = /[a-zA-Z]+\s+[a-zA-Z]+/;

  if (regexp.test(value)) {
    return true;
  }

  return false;
};
