import React, { useRef } from 'react';
import Box from 'core/ui/Box';
import Button from 'core/ui/Button';
import { useTranslation } from 'react-i18next';
import {
  ButtonGroup,
  Paper,
  MenuList,
  MenuItem,
  Popper,
  ClickAwayListener,
} from '@material-ui/core';
import { ArrowDropDown, BorderColor, Notes, ShortText } from '@material-ui/icons';
import Divider from 'core/ui/Divider';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

const MenuDocSection = ({ section, form }) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const anchorRef = useRef();

  const menusIcons = {
    pdf: <PictureAsPdfIcon style={{ fontSize: 20 }} />,
    text: <ShortText style={{ fontSize: 20 }} />,
    paragraph: <Notes style={{ fontSize: 20 }} />,
    radio: <RadioButtonCheckedIcon style={{ fontSize: 20 }} />,
    checkbox: <CheckBoxIcon style={{ fontSize: 20 }} />,
    dropdown: <ArrowDropDownCircleIcon style={{ fontSize: 20 }} />,
    signature: <BorderColor style={{ fontSize: 20 }} />,
  };

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Box flex={1}>
        <ButtonGroup fullWidth variant="outlined" ref={anchorRef} aria-label="split button">
          <Button ref={anchorRef} onClick={() => setOpen(!open)}>
            {menusIcons[section?.kind || 'text']}
            {t(`document.section.kind.${section?.kind || 'text'}`)} <ArrowDropDown />
          </Button>
        </ButtonGroup>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          style={{ zIndex: 9999 }}
          disablePortal
        >
          <Box>
            <Paper>
              <MenuList id="split-button-menu">
                <MenuItem
                  style={{ height: 50 }}
                  onClick={() => {
                    setOpen(false);
                    form.changeSection(section, 'kind', 'text');
                  }}
                >
                  <ShortText style={{ fontSize: 20 }} />
                  &nbsp;
                  {t('document.section.kind.text')}
                </MenuItem>
                <MenuItem
                  style={{ height: 50 }}
                  onClick={() => {
                    setOpen(false);
                    form.changeSection(section, 'kind', 'paragraph');
                  }}
                >
                  <Notes style={{ fontSize: 20 }} />
                  &nbsp;
                  {t('document.section.kind.paragraph')}
                </MenuItem>
                <Divider />
                <MenuItem
                  style={{ height: 50 }}
                  onClick={() => {
                    setOpen(false);
                    form.changeSection(section, 'kind', 'radio');
                  }}
                >
                  <RadioButtonCheckedIcon style={{ fontSize: 20 }} />
                  &nbsp;
                  {t('document.section.kind.radio')}
                </MenuItem>
                <MenuItem
                  style={{ height: 50 }}
                  onClick={() => {
                    setOpen(false);
                    form.changeSection(section, 'kind', 'checkbox');
                  }}
                >
                  <CheckBoxIcon style={{ fontSize: 20 }} />
                  &nbsp;
                  {t('document.section.kind.checkbox')}
                </MenuItem>
                <MenuItem
                  style={{ height: 50 }}
                  onClick={() => {
                    setOpen(false);
                    form.changeSection(section, 'kind', 'dropdown');
                  }}
                >
                  <ArrowDropDownCircleIcon style={{ fontSize: 20 }} />
                  &nbsp;
                  {t('document.section.kind.dropdown')}
                </MenuItem>
                <Divider />
                <MenuItem
                  style={{ height: 50 }}
                  onClick={() => {
                    setOpen(false);
                    form.changeSection(section, 'kind', 'signature');
                  }}
                >
                  <BorderColor style={{ fontSize: 20 }} />
                  &nbsp;
                  {t('document.section.kind.signature')}
                </MenuItem>
              </MenuList>
            </Paper>
          </Box>
        </Popper>
      </Box>
    </ClickAwayListener>
  );
};

MenuDocSection.propTypes = {};

MenuDocSection.defaultProps = {};

export default MenuDocSection;
