import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from 'core/ui/Grid';
import Typography from 'core/ui/Typography';
import noImage from 'assets/img/no-image.jpg';
import Box from 'core/ui/Box';
import Card from 'core/ui/Card';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import { ButtonPrimary } from 'core/ui/Button';
import { Delete } from '@material-ui/icons';
import { CompetitionContext } from '.';
import SelImages from './SelImages';

const BannerAndLogo = () => {
  const formContext = useContext(CompetitionContext);
  const { t } = useTranslation();

  const timerLogo = formContext?.watch('timerLogo')?._destroy
    ? null
    : formContext?.watch('timerLogo');
  const leaderboardLogo = formContext?.watch('leaderboardLogo')?._destroy
    ? null
    : formContext?.watch('leaderboardLogo');

  return (
    <Box py={3}>
      <Box flexGrow="1">
        <Typography variant="h5" mb={3} color="primary">
          {t('competition.label.bannerAndLogo')}
        </Typography>
      </Box>
      <Grid container spacing={5}>
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <Card
            title={t('competition.label.timerLogo')}
            action={
              <Box display="flex" alignItems="center" p={1} mt={2}>
                {timerLogo && (
                  <ButtonPrimary onClick={() => formContext?.deleteImage('timerLogo')}>
                    <Delete fontSize="small" />
                  </ButtonPrimary>
                )}
              </Box>
            }
          >
            <Box>
              <img
                className="boxImg"
                draggable="false"
                src={timerLogo?.url || noImage}
                style={{ width: '100%' }}
                alt=""
              />
            </Box>

            <Box display="flex" justifyContent="center">
              <SelImages setImage={(img) => formContext?.setImage('timerLogo', img)} />
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <Card
            title={t('competition.label.leaderboardLogo')}
            action={
              <Box display="flex" alignItems="center" p={1} mt={2}>
                {leaderboardLogo && (
                  <ButtonPrimary onClick={() => formContext?.deleteImage('leaderboardLogo')}>
                    <Delete fontSize="small" />
                  </ButtonPrimary>
                )}
              </Box>
            }
          >
            <Box>
              <img
                className="boxImg"
                draggable="false"
                src={leaderboardLogo?.url || noImage}
                style={{ width: '100%' }}
                alt=""
              />
            </Box>

            <Box display="flex" justifyContent="center">
              <SelImages setImage={(img) => formContext?.setImage('leaderboardLogo', img)} />
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <Card title={t('competition.label.banner')}>
            <Box>
              <img
                className="boxImg"
                draggable="false"
                src={formContext?.banner?.image?.url || noImage}
                style={{ width: '100%' }}
                alt=""
              />
            </Box>

            <Box display="flex" justifyContent="center" mb={1}>
              <SelImages
                setImage={(img) => formContext?.setBanner((prev) => ({ ...prev, image: img }))}
              />
            </Box>
            <Box p={3}>
              <TextFieldRounded
                value={formContext?.banner?.url}
                onChange={(v) => formContext?.setBanner((prev) => ({ ...prev, url: v }))}
                pattern="[0-9]*"
                placeholder={t('competition.label.banner.url')}
                label={t('competition.label.banner.url').toUpperCase()}
                required
                bordered
              />
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BannerAndLogo;
