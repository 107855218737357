/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import Box from 'core/ui/Box';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import { useTranslation } from 'react-i18next';
import CountryChosen from 'core/ui/CountryChosen';
import Modules from 'modules';
import useActions from 'modules/map/useActions';
import useSelectors from 'modules/map/useSelectors';
import { storeOrdersModule } from 'modules/storeOrders';
import Paper from 'core/ui/Paper';
import StatesChosen from 'core/ui/StatesChosen';
import AddressSelector from './AddressSelector';

export default ({ userId, address, channelId, setAddress, buttonColor }) => {
  const { t } = useTranslation();
  const { request } = useActions();

  const lastOrder = useSelectors(storeOrdersModule, 'order');

  useEffect(() => {
    request({
      action: Modules.storeOrders.actions.getLastOrderWithAddress,
      data: {
        userId,
      },
    });
  }, [request, userId]);

  useEffect(() => {
    if (lastOrder) {
      setAddress((prev) => ({
        ...prev,
        street: lastOrder.street,
        countryId: lastOrder.countryId,
        city: lastOrder.city,
        state: lastOrder.state,
        zip: lastOrder.zip,
      }));
    }
  }, [lastOrder, setAddress]);

  return (
    <Paper p={3} className="paper-rounded">
      <Box pb={3}>
        <AddressSelector
          buttonColor={buttonColor}
          setAddress={setAddress}
          userId={userId}
          channelId={channelId}
        />
      </Box>

      {address.delivery && (
        <>
          <Box mb={3}>
            <CountryChosen
              name="countryId"
              defaultValue={address.countryId}
              placeholder={t('form.address.label.country')}
              label={t('form.address.label.country').toUpperCase()}
              setValue={(v) => setAddress((prev) => ({ ...prev, countryId: v }))}
              required
              bordered
            />
          </Box>
          <Box mb={3}>
            <TextFieldRounded
              value={address.street}
              onChange={(v) => setAddress((prev) => ({ ...prev, street: v }))}
              placeholder={t('form.address.label.address')}
              label={t('form.address.label.address').toUpperCase()}
              bordered
              required
            />
          </Box>
          <Box mb={3}>
            <TextFieldRounded
              value={address.city}
              onChange={(v) => setAddress((prev) => ({ ...prev, city: v }))}
              placeholder={t('form.address.label.city')}
              label={t('form.address.label.city').toUpperCase()}
              bordered
            />
          </Box>
          <Box mb={3}>
            <StatesChosen
              name="state"
              defaultValue={`${address.state}`}
              setValue={(v) => setAddress((prev) => ({ ...prev, state: v }))}
              countryId={address.countryId}
              placeholder={t('form.address.label.state')}
              label={t('form.address.label.state').toUpperCase()}
              required
              bordered
            />
          </Box>
          <Box mb={3}>
            <TextFieldRounded
              value={address.zip}
              onChange={(v) => setAddress((prev) => ({ ...prev, zip: v }))}
              placeholder={t('form.address.label.zip')}
              label={t('form.address.label.zip').toUpperCase()}
              bordered
            />
          </Box>
        </>
      )}
    </Paper>
  );
};
