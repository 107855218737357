/* eslint-disable react/prop-types */
import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Box from 'core/ui/Box';
import { ButtonPrimary } from 'core/ui/Button';
import notifications from 'modules/notifications';
import Grid from 'core/ui/Grid';
import { formatDateToApi } from 'core/utils/formatters';
import ChoosenChannelTags from 'core/ui/ChoosenChannelTags';
import Paper from 'core/ui/Paper';
import { ChannelContext } from '../..';
import FormLimituse from './FormLimitUse';
import FormRenewal from './FormRenewal';
import FormSetting from './FormSetting';
import FormInfo from './FormInfo';
import FormExpiryConfig from './FormExpiryConfig';
import ContractInfo from './ContractInfo';
import FormNotify from './FormNotify';
import TableWorkoutsTypes from './TableWorkoutsTypes';
import QuestionnaireSelector from './QuestionnaireSelector';
import FormLimitPurchases from './FormLimitPurchases';
import FormCancelationPolicyAutomation from './FormCancelationPolicyAutomation';
import FormOptionYesNo from './FormOptionYesNo';
import FormPrices from './FormPrices';
import FormMembershipBonus from './FormMembershipBonus';
import { Enroll } from './Enroll';

const FormMembershipPlan = ({
  currentPlan,
  setCurrentPlan,
  editIndex,
  setEditIndex,
  closeModal,
  channel,
}) => {
  const { t } = useTranslation();
  const formContext = useContext(ChannelContext);
  const dispatch = useDispatch();

  const savePlan = () => {
    if (!currentPlan.amount) {
      dispatch(notifications.warning(t('channel.membershipsPlan.validate.amount')));
      return;
    }
    if (currentPlan.useIntroductoryAmount && !currentPlan.introductoryAmount) {
      dispatch(notifications.warning(t('channel.membershipsPlan.validate.introductoryAmount')));
      return;
    }
    if (currentPlan.showTaxAmount && !currentPlan.taxAmount) {
      dispatch(notifications.warning(t('channel.membershipsPlan.validate.taxAmount')));
      return;
    }
    if (!!currentPlan.contractTimeType && !currentPlan.contractTimeNumber) {
      dispatch(notifications.warning(t('channel.membershipsPlan.validate.contractTimeNumber')));
      return;
    }
    if (
      (!currentPlan.limitUseIncrement || currentPlan.limitUseIncrement !== 'renew_frequency') &&
      currentPlan.limitUseIncrementNumber <= 0 &&
      currentPlan.limitUse > 0
    ) {
      dispatch(notifications.warning(t('channel.membershipsPlan.validate.limitUseIncrement')));
      return;
    }

    const startAt = currentPlan.startAt ? formatDateToApi(currentPlan.startAt) : null;
    const endAt = currentPlan.endAt ? formatDateToApi(currentPlan.endAt) : null;

    let orderShow = 0;
    if (currentPlan?.orderShow !== 0) {
      orderShow =
        currentPlan?.orderShow ||
        formContext.membershipPlans[formContext.membershipPlans?.length - 1]?.orderShow + 1 ||
        0;
    }

    let arr = [];
    if (editIndex > -1) {
      const elem = formContext.membershipPlans[editIndex];
      arr = [...formContext.membershipPlans.filter((x) => x !== elem)];
      arr.push({ ...currentPlan, orderShow, startAt, endAt, feesAttributes: currentPlan?.fees });
    } else {
      arr = [...formContext.membershipPlans];
      arr.push({
        ...currentPlan,
        orderShow,
        active: true,
        coupons: [],
        startAt,
        endAt,
        feesAttributes: currentPlan?.fees,
      });
    }

    formContext.setMembershipPlans(arr);
    setCurrentPlan({
      visible: true,
      online: formContext.channel?.activePlans?.online?.active,
      inPerson: formContext.channel?.activePlans?.inPerson?.active,
      renewable: true,
      renewConfigDate: 'purchase_date',
      cancelable: true,
      customIncrement: 'month',
      customIncrementNumber: 1,
      limitUseIncrement: 'renew_frequency',
      expiryConfig: {
        config: 'none',
      },
      coupons: [],
    });
    setEditIndex(-1);
    closeModal();

    setTimeout(() => {
      formContext.submitForm();
    }, 500);
  };

  useEffect(() => {
    if (currentPlan.taxAmount > 0) {
      setCurrentPlan((prev) => ({ ...prev, showTaxAmount: true }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPlan.taxAmount]);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <FormInfo setCurrentPlan={setCurrentPlan} currentPlan={currentPlan} />
          <FormPrices
            channel={formContext.channel}
            setCurrentPlan={setCurrentPlan}
            currentPlan={currentPlan}
          />

          <FormLimitPurchases
            channel={formContext.channel}
            setCurrentPlan={setCurrentPlan}
            currentPlan={currentPlan}
          />

          <ContractInfo
            channel={formContext.channel}
            currentPlan={currentPlan}
            setCurrentPlan={setCurrentPlan}
          />

          {currentPlan && !currentPlan.renewable && (
            <Enroll
              channel={formContext.channel}
              currentPlan={currentPlan}
              setCurrentPlan={setCurrentPlan}
            />
          )}

          <Box p={2}>
            <TableWorkoutsTypes
              checkedItems={currentPlan?.channelsWorkoutsTypeIds || []}
              setCheckedTypes={(v) =>
                setCurrentPlan((prev) => ({ ...prev, channelsWorkoutsTypeIds: v }))
              }
              channel={channel}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box mb={1}>
            <FormSetting setCurrentPlan={setCurrentPlan} currentPlan={currentPlan} />
          </Box>
          {currentPlan?.cancelable && currentPlan?.renewable && (
            <Box mb={1}>
              <FormCancelationPolicyAutomation
                setCurrentPlan={setCurrentPlan}
                currentPlan={currentPlan}
              />
            </Box>
          )}
          <Box mb={1}>
            <FormRenewal
              channel={channel}
              setCurrentPlan={setCurrentPlan}
              currentPlan={currentPlan}
            />
          </Box>

          {currentPlan.renewable && (
            <Box mb={1}>
              <FormExpiryConfig setCurrentPlan={setCurrentPlan} currentPlan={currentPlan} />
            </Box>
          )}
          <Box mb={1}>
            <FormLimituse setCurrentPlan={setCurrentPlan} currentPlan={currentPlan} />
          </Box>
          <Box mb={1}>
            <FormNotify setCurrentPlan={setCurrentPlan} currentPlan={currentPlan} />
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Paper className="paper-rounded" p={3} mb={3}>
                <FormOptionYesNo
                  t={t}
                  currentPlan={currentPlan}
                  setCurrentPlan={setCurrentPlan}
                  label={t('channel.memberships.attendanceAddCredits').toUpperCase()}
                  prop="attendanceAddCredits"
                />
              </Paper>

              <FormMembershipBonus
                channel={channel}
                setCurrentPlan={setCurrentPlan}
                currentPlan={currentPlan}
              />
            </Grid>
          </Grid>

          <Box>
            <QuestionnaireSelector
              channel={channel}
              setCurrentPlan={setCurrentPlan}
              currentPlan={currentPlan}
            />
          </Box>
          <Box mt={3}>
            <ChoosenChannelTags
              label={t('channel.tags')}
              placeholder={t('channel.tags')}
              val={
                currentPlan?.channelsTagsAttributes ? [...currentPlan?.channelsTagsAttributes] : []
              }
              setValue={(v) =>
                setCurrentPlan((prev) => ({
                  ...prev,
                  updatedAt: formatDateToApi(new Date()),
                  channelsTagsAttributes: v?.map((x) => x.name || x),
                }))
              }
              channelId={channel?.id}
            />
          </Box>
        </Grid>
      </Grid>

      <Box display="flex" my={3} justifyContent="flex-end">
        <ButtonPrimary onClick={() => savePlan()}>{t('button.save')}</ButtonPrimary>
      </Box>
    </Box>
  );
};

FormMembershipPlan.defaultProps = {
  currentPlan: {},
  setCurrentPlan: () => {},
  setEditIndex: () => {},
  editIndex: -1,
};
FormMembershipPlan.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  currentPlan: PropTypes.object,
  setCurrentPlan: PropTypes.func,
  setEditIndex: PropTypes.func,
  editIndex: PropTypes.number,
};

export default FormMembershipPlan;
