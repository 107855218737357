/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect } from 'react';
import { Box } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { channelsSelectors } from 'modules/channels';
import { CONTACT_FORM_CSS, CONTACT_FORM_JS } from 'core/env';

const ContactWidget = () => {
  const channel = useSelector((state) => channelsSelectors.getChannel(state));
  useEffect(
    () => () => {
      const metaTag = document.querySelector(`link[name="contact_form_css"]`);
      if (metaTag) {
        metaTag.remove();
      }
      const cssMeta = document.querySelector(`script[name="contact_form_js"]`);
      if (cssMeta) {
        cssMeta.remove();
      }
    },
    [],
  );

  if (!channel?.contactFormPk || !channel?.activePlans?.engage?.active) {
    return <></>;
  }

  return (
    <Box>
      <Helmet>
        <link name="contact_form_css" rel="stylesheet" href={CONTACT_FORM_CSS} />
        <script name="contact_form_js" src={CONTACT_FORM_JS} type="text/javascript" />
      </Helmet>
      <div id="contact-form" data-channel-pk={channel?.contactFormPk} />
    </Box>
  );
};

export default ContactWidget;
