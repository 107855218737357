import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import Paper from 'core/ui/Paper';
import Typography from 'core/ui/Typography';
import { ButtonPrimary } from 'core/ui/Button';
import { CompetitionRegistrationContext } from 'contexts';
import Checkbox from 'core/ui/Checkbox';
import Divider from 'core/ui/Divider';
import { channelCurrency } from 'core/utils/formatters';
import Modules from 'modules';
import useActions from 'modules/map/useActions';
import Radio from 'core/ui/Radio';
import { secondary } from 'core/ui/Colors';
import { registrationSteps } from './useCompRegistration';

export default function RegistrationTypeSelector() {
  const { t } = useTranslation();
  const context = useContext(CompetitionRegistrationContext);
  const { request } = useActions();
  const [divisions, setDivisions] = useState([]);
  const hasVendors = context?.competition?.vendorsCategories?.length > 0;
  const hasVolunteers = context?.competition?.volunteersCategories?.length > 0;

  const getDivisions = () => {
    request({
      action: Modules.newCompetitions.actions.getCompetitionDivisions,
      data: {
        competitionId: context?.competition?.id,
      },
      options: {
        onSuccess: (data) => {
          setDivisions(data);
        },
      },
    });
  };

  useEffect(() => {
    if (context?.competition) {
      getDivisions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.competition]);

  const checkRegisterType = (field, value) => {
    context.setRegisterTypes((prev) => ({
      ...prev,
      [field]: prev[field] === value ? null : value,
    }));
  };

  const checkRegisterDivision = (division) => {
    context.setRegisterTypes((prev) => {
      let newV = [];

      if ((prev.divisions || []).find((x) => x.id === division.id)) {
        return {
          ...prev,
          regType: division?.eventType,
          divisions: (prev.divisions || []).filter((x) => x.id !== division?.id),
        };
      }

      if (context?.competition?.multiDivisionRegistrations) {
        newV = [
          ...(prev.divisions || []).filter((x) => x.eventType === division?.eventType),
          division,
        ];
      } else {
        newV = [division];
      }

      return { ...prev, divisions: newV, regType: division?.eventType };
    });
  };

  const checkRegisterVolunteers = (category) => {
    context.setRegisterTypes((prev) => {
      let newV = [];

      if ((prev.volunteers || []).find((x) => x.id === category.id)) {
        return {
          ...prev,
          volunteers: (prev.volunteers || []).filter((x) => x.id !== category?.id),
        };
      }

      newV = [...(prev.volunteers || []), category];

      return { ...prev, volunteers: newV };
    });
  };

  const buttonEnabled =
    context?.registerTypes?.affiliate ||
    context?.registerTypes?.volunteers?.length > 0 ||
    context?.registerTypes?.vendor ||
    (context?.competition?.hasDivisions
      ? context?.registerTypes?.divisions?.length > 0
      : !!context?.registerTypes?.regType);

  return (
    <Box mx={5} mt={2}>
      <Typography
        align="center"
        variant="h5"
        mb={2}
        style={{ color: context?.competition?.darkBackground ? 'white' : secondary }}
      >
        {t('competition.label.registrationTypes')}
      </Typography>

      {context?.competition.hasAffiliate && (
        <Box mb={2}>
          <ItemOption
            label={t('label.registerAsAffiliate')}
            multiple
            checked={context?.registerTypes?.affiliate}
            onClick={() => checkRegisterType('affiliate', !context?.registerTypes?.affiliate)}
            bgColor={context?.cardBgColor}
            buttonColor={context?.competition?.buttonColor}
            dark={context?.competition?.darkBackground}
            t={t}
          />
        </Box>
      )}

      {!context?.registerTypes?.affiliate && (
        <>
          <Box mb={2}>
            <Typography
              variant="h6"
              mb={1}
              style={{ color: context?.competition?.darkBackground ? 'white' : secondary }}
            >
              {t('label.registerAsCompetitor')}
            </Typography>

            {context?.competition?.hasDivisions ? (
              <>
                {['individual', 'team'].map((itemReg) => (
                  <Box key={itemReg}>
                    {divisions
                      ?.filter((x) => x.eventType === itemReg)
                      .map((division) => (
                        <ItemOption
                          key={division.id}
                          label={division?.name}
                          subLabel={
                            <span>
                              {t(`competition.label.eventType.${itemReg}`)}

                              {!division?.allowRegistration && (
                                <Typography color="error">{t('divisions.limit.full')}</Typography>
                              )}
                            </span>
                          }
                          checked={
                            !!context?.registerTypes?.divisions?.find((x) => x?.id === division.id)
                          }
                          disabled={!division?.allowRegistration}
                          onWaitlistClick={() => context?.confirmWaitlistRegistration(division?.id)}
                          showWaitlist={context?.competition.allowWaitlist}
                          waitlisted={context?.competition?.waitlisted}
                          onClick={() => checkRegisterDivision(division)}
                          buttonColor={context?.competition?.buttonColor}
                          cost={channelCurrency(
                            division?.registrationPrice,
                            context?.competition?.currency,
                          )}
                          multiple={context?.competition?.multiDivisionRegistrations}
                          bgColor={context?.cardBgColor}
                          dark={context?.competition?.darkBackground}
                          t={t}
                        />
                      ))}
                  </Box>
                ))}
              </>
            ) : (
              <>
                {['both', 'individual'].find((x) => x === context?.competition?.eventType) && (
                  <ItemOption
                    label={t('competition.label.eventType.individual')}
                    checked={context?.registerTypes?.regType === 'individual'}
                    onClick={() => checkRegisterType('regType', 'individual')}
                    onWaitlistClick={() => context?.confirmWaitlistRegistration()}
                    showWaitlist={context?.competition.allowWaitlist}
                    waitlisted={context?.competition?.waitlisted}
                    disabled={!context?.competition?.allowRegistration}
                    subLabel={
                      <span>
                        {!context?.competition?.allowRegistration && (
                          <Typography color="error">{t('divisions.limit.full')}</Typography>
                        )}
                      </span>
                    }
                    cost={channelCurrency(
                      context.competition?.cost,
                      context?.competition?.currency,
                    )}
                    bgColor={context?.cardBgColor}
                    buttonColor={context?.competition?.buttonColor}
                    dark={context?.competition?.darkBackground}
                    t={t}
                  />
                )}
                {['both', 'team'].find((x) => x === context?.competition?.eventType) && (
                  <ItemOption
                    label={t('competition.label.eventType.team')}
                    checked={context?.registerTypes?.regType === 'team'}
                    onWaitlistClick={() => context?.confirmWaitlistRegistration()}
                    showWaitlist={context?.competition.allowWaitlist}
                    subLabel={
                      <span>
                        {!context?.competition?.allowRegistration && (
                          <Typography color="error">{t('divisions.limit.full')}</Typography>
                        )}
                      </span>
                    }
                    waitlisted={context?.competition?.waitlisted}
                    onClick={() => checkRegisterType('regType', 'team')}
                    buttonColor={context?.competition?.buttonColor}
                    cost={channelCurrency(
                      context.competition?.costTeam,
                      context?.competition?.currency,
                    )}
                    bgColor={context?.cardBgColor}
                    dark={context?.competition?.darkBackground}
                    t={t}
                  />
                )}
              </>
            )}
          </Box>
          {hasVendors && (
            <Box mb={2}>
              <Typography
                variant="h6"
                mb={1}
                style={{ color: context?.competition?.darkBackground ? 'white' : secondary }}
              >
                {t('label.registerAsVendor')}
              </Typography>
              {context?.competition?.vendorsCategories.map((vendor) => (
                <ItemOption
                  key={vendor?.id}
                  label={vendor?.name}
                  checked={context?.registerTypes?.vendor === vendor}
                  onClick={() => checkRegisterType('vendor', vendor)}
                  buttonColor={context?.competition?.buttonColor}
                  bgColor={context?.cardBgColor}
                  dark={context?.competition?.darkBackground}
                  cost={channelCurrency(vendor?.registrationPrice, context?.competition?.currency)}
                />
              ))}
            </Box>
          )}

          {hasVolunteers && (
            <Box mb={2}>
              <Typography
                variant="h6"
                mb={1}
                style={{ color: context?.competition?.darkBackground ? 'white' : secondary }}
              >
                {t('label.registerAsVolunteers')}
              </Typography>
              {context?.competition?.volunteersCategories.map((volunteer) => (
                <ItemOption
                  key={volunteer?.id}
                  label={volunteer?.name}
                  // eslint-disable-next-line eqeqeq
                  checked={!!context?.registerTypes?.volunteers?.find((x) => x?.id == volunteer.id)}
                  onClick={() => checkRegisterVolunteers(volunteer)}
                  bgColor={context?.cardBgColor}
                  buttonColor={context?.competition?.buttonColor}
                  dark={context?.competition?.darkBackground}
                  multiple
                />
              ))}
            </Box>
          )}
        </>
      )}

      <Divider />
      <Box mt={2} display="flex" justifyContent="flex-end">
        <ButtonPrimary
          onClick={() => context?.setStep(registrationSteps.finish)}
          disabled={!buttonEnabled}
          buttonColor={context?.competition?.buttonColor}
        >
          {t('channel.next')}
        </ButtonPrimary>
      </Box>
    </Box>
  );
}

const ItemOption = ({
  label,
  checked,
  onClick,
  onWaitlistClick,
  showWaitlist,
  cost,
  subLabel,
  disabled,
  multiple,
  bgColor,
  buttonColor,
  dark,
  t,
}) => (
  <Paper
    className="hover"
    onClick={disabled ? null : onClick}
    p={2}
    mb={1}
    style={{ backgroundColor: bgColor }}
  >
    <Box display="flex" className="gap-5">
      <Box flexGrow={1}>
        <Typography variant="body2" style={{ color: dark ? 'white' : secondary }}>
          {label}
        </Typography>
        <Typography variant="body2" style={{ color: dark ? 'white' : secondary }}>
          {subLabel}
        </Typography>
        <Typography variant="h6" style={{ color: dark ? 'white' : secondary }}>
          {cost}
        </Typography>
      </Box>
      {!disabled && (
        <div>
          {multiple ? (
            <Checkbox checked={checked} disabled={disabled} color={buttonColor} />
          ) : (
            <Radio checked={checked} disabled={disabled} color={buttonColor} />
          )}
        </div>
      )}

      {showWaitlist && disabled && onWaitlistClick && (
        <div className="tw-flex tw-flex-col tw-items-center">
          <Typography className="tw-mb-2">{t('button.regigerForWaitlist')}</Typography>
          <ButtonPrimary onClick={onWaitlistClick}>{t('button.waitlist')}</ButtonPrimary>
        </div>
      )}
    </Box>
  </Paper>
);

RegistrationTypeSelector.defaultProps = {
  open: false,
  close: null,
  callback: () => {},
};
