import React from 'react';
import Grid from 'core/ui/Grid';
import CardGrossIncome from './CardGrossIncome';
import CardExpenses from './CardExpenses';
import CardNetIncome from './CardNetIncome';
import CardRevenueByTypes from './CardRevenueByTypes';

const CardResumes = ({ channel, data }) => (
  <Grid container spacing={3}>
    <Grid item xs={12} sm={6} md={3}>
      <CardRevenueByTypes channel={channel} data={data} />
    </Grid>
    <Grid item xs={12} sm={6} md={3}>
      <CardGrossIncome channel={channel} data={data} />
    </Grid>
    <Grid item xs={12} sm={6} md={3}>
      <CardExpenses channel={channel} data={data} />
    </Grid>
    <Grid item xs={12} sm={6} md={3}>
      <CardNetIncome channel={channel} data={data} />
    </Grid>
  </Grid>
);

export default CardResumes;
