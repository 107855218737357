import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

import * as selectors from './selectors';
import * as sagas from './sagas';

/* ---------- Action Creators and Types ---------- */
const { Types, Creators } = createActions(
  {
    getTagsAutocompleteError: ['error'],
    getTagsAutocompleteStart: ['data'],
    getTagsAutocompleteSuccess: ['tags'],

    clearTagsAutocomplete: [],
  },
  { prefix: 'tags/' },
);

/* ---------- Initial State ---------- */
const INITIAL_STATE = Immutable({
  data: [],
  error: null,
  loading: false,
});

/* ---------- Reducers ---------- */
const loadStartReducer = (state) => state.set('loading', true);

const getTagsAutocompleteSuccessReducer = (state, action) =>
  state.merge({
    data: action.tags,
    loading: false,
    error: null,
  });

const loadErrorReducer = (state, action) =>
  state.merge({
    loading: false,
    error: action.error,
  });

const clearAutocompleteReducer = (state) =>
  state.merge({
    loading: false,
    data: [],
  });

/* ---------- Main Reducer ---------- */
const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_TAGS_AUTOCOMPLETE_START]: loadStartReducer,
  [Types.GET_TAGS_AUTOCOMPLETE_SUCCESS]: getTagsAutocompleteSuccessReducer,
  [Types.GET_TAGS_AUTOCOMPLETE_ERROR]: loadErrorReducer,

  [Types.CLEAR_TAGS_AUTOCOMPLETE]: clearAutocompleteReducer,
});

/* ---------- Exporting ---------- */
export const tagsActions = Creators;
export const tagsTypes = Types;
export const tagsSelectors = selectors;
export const tagsSagas = sagas;

export default reducer;
