/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Paper from 'core/ui/Paper';
import { useTranslation } from 'react-i18next';
import Typography from 'core/ui/Typography';
import SFDataTable from 'core/ui/DataTable';
import Box from 'core/ui/Box';
import { PaymentStatus } from 'core/utils/consts';
import ModalRetryPayment from 'components/Profile/Dashboard/Account/Table/ModalRetryPayment';
import ConfirmDialog from 'core/ui/ConfirmDialog';
import useSelectors from 'modules/map/useSelectors';
import { channelReportsModule } from 'modules/channelReports';
import PaymentTypes from 'components/Channel/Manage/Tabs/Reports/Payments/PaymentTypes';
import { ButtonPrimary } from 'core/ui/Button';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import { useDispatch } from 'react-redux';
import notifications from 'modules/notifications';
import LoaderSm from 'core/ui/LoaderSm';
import { newPaymentsModule } from 'modules/payments/new';
import { date } from 'core/utils/formatters';
import Divider from 'core/ui/Divider';
import StoreOrderItems from 'components/Channel/Manage/Tabs/Reports/StoreOrders/StoreOrderItems';

export default function ResultPayments({ partner, rows, getReport }) {
  const { t } = useTranslation();
  const { request } = useActions();
  const dispatch = useDispatch();
  const [openModalRetry, setOpenModalRetry] = React.useState({ open: false });
  const [confirmDialog, setConfirmDialog] = useState({ open: false });
  const loading = useSelectors(channelReportsModule, 'loading');
  const hasSalesTax = rows?.find((x) => Number(x.taxAmount) > 0);
  const loadingRefund = useSelectors(newPaymentsModule, 'loading');

  const refundPayment = (paymentId) => {
    setConfirmDialog({
      open: true,
      confirm: () => {
        setConfirmDialog({ open: false });
        request({
          action: Modules.newPayments.actions.refundPayment,
          data: {
            paymentId,
          },
          options: {
            onSuccess: () => {
              dispatch(notifications.success(t('payment.refund.success')));
              getReport();
            },
          },
        });
      },
    });
  };

  const columns = [
    {
      name: 'Gym',
      selector: (row) => row?.channel?.name,
      sortable: true,
      cell: (row) => (
        <Box>
          <Typography>{row?.channel?.name}</Typography>
          <Typography>{row?.user?.name}</Typography>
        </Box>
      ),
    },
    {
      name: 'Type',
      selector: 'kind',
      sortable: true,
      cell: (row) => <PaymentTypes payment={row} />,
    },
    {
      name: 'Date',
      selector: 'confirmedAt',
      cell: (row) => <Typography>{date(row.createdAt, partner?.dateFormatReact)}</Typography>,
      sortable: true,
      center: true,
    },
    {
      name: 'Status',
      selector: 'status',
      sortable: true,
      right: true,
      cell: (row) => {
        const falied = [PaymentStatus.failed, PaymentStatus.requires_payment_method].find(
          (x) => x === row.status,
        );
        return (
          <>
            {falied ? (
              <Box display="flex" justifyContent="center" alignItems="center">
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Typography color="primary" variant="h6">
                    {t(`payment.stripe.status.failed`)}
                  </Typography>
                  <Typography variant="caption">{row.error}</Typography>
                </Box>
              </Box>
            ) : (
              <Typography color="secondary" variant="h6">
                {t(`payment.stripe.status.${row.status}`)}
              </Typography>
            )}
          </>
        );
      },
    },
    {
      name: 'Charge',
      selector: 'amount',
      sortable: true,
      right: true,
      cell: (row) => (
        <Typography>
          {row.currencySymbol}
          {row.amount}
        </Typography>
      ),
    },
    {
      name: 'Pre-Sale End Date',
      sortable: true,
      right: true,
      cell: (row) => (
        <Typography>
          {row?.storeOrder?.preSaleEndDate && date(row?.storeOrder?.preSaleEndDate)}
        </Typography>
      ),
    },
    {
      name: 'Stream',
      selector: 'streamFee',
      sortable: true,
      right: true,
      cell: (row) => (
        <Typography>
          {row.currencySymbol}
          {row.streamFee}&nbsp;
          {row.currencyCode}
        </Typography>
      ),
    },
    {
      name: 'Gym',
      selector: 'revenue',
      sortable: true,
      right: true,
      cell: (row) => (
        <Typography>
          {row.currencySymbol}
          {row.revenue}&nbsp;
          {row.currencyCode}
        </Typography>
      ),
    },
  ];

  if (hasSalesTax) {
    columns.push({
      name: 'SalesTax',
      selector: 'taxAmount',
      sortable: true,
      right: true,
      cell: (row) => (
        <Typography>
          {row.currencySymbol}
          {row.taxAmount}&nbsp;
          {row.currencyCode}
        </Typography>
      ),
    });
  }
  columns.push({
    name: 'Revenue',
    selector: 'partnerRevenue',
    sortable: true,
    right: true,
    cell: (row) => (
      <Typography>
        {row.currencySymbol}
        {row.partnerRevenue}&nbsp;
        {row.currencyCode}
      </Typography>
    ),
  });
  columns.push({
    name: '',
    center: true,
    cell: (row) => (
      <Box display="flex">
        {row.allowRefund && (
          <Box display="flex" justifyContent="center">
            <ButtonPrimary
              disabled={loadingRefund}
              style={{ width: 100 }}
              onClick={() => refundPayment(row.id)}
            >
              {loadingRefund && <LoaderSm loading />}
              {t('button.refund')}
            </ButtonPrimary>
          </Box>
        )}
      </Box>
    ),
  });

  const ExpanableComponent = ({ data: dataExpanded }) => (
    <>
      <Box p={5}>
        <Typography component="h1" align="center" m={5}>
          {dataExpanded.summary}
        </Typography>
        <StoreOrderItems
          currency={dataExpanded?.currency}
          items={dataExpanded?.storeOrder?.items || []}
        />

        {dataExpanded.userCard && (
          <Box display="flex" justifyContent="center">
            <Typography mb={3}>Card:</Typography>
            <Typography ml={1} color="primary">
              {dataExpanded.userCard.toUpperCase()}
            </Typography>
          </Box>
        )}
      </Box>
      <Divider mt={2} />
    </>
  );

  return (
    <Paper style={{ width: '100%' }}>
      <ConfirmDialog
        open={confirmDialog.open}
        confirm={() => confirmDialog.confirm()}
        cancel={() => setConfirmDialog({ open: false })}
        description={t('payment.refund.desc')}
        title={t('payment.refund.title')}
        labelConfirm={t('button.confirm')}
        labelCancel={t('button.cancel')}
        loading={loading}
      />

      {openModalRetry.open && (
        <ModalRetryPayment
          payment={openModalRetry.payment}
          userId={openModalRetry.userId}
          callback={() => {
            getReport();
            setOpenModalRetry({ open: false });
          }}
          open
          close={() => setOpenModalRetry({ open: false })}
        />
      )}

      <SFDataTable
        title={t('channel.reports.payments')}
        columns={columns}
        data={rows}
        pagination
        showExport
        expandableRows
        expandableRowsComponent={<ExpanableComponent />}
      />
    </Paper>
  );
}
