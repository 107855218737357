import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

import { persistData } from 'core/utils/session-storage';
import * as selectors from './selectors';
import * as sagas from './sagas';

/* ---------- Action Creators and Types ---------- */
const { Types, Creators } = createActions(
  {
    getChannelsError: ['error'],
    getChannelsStart: ['data'],
    getChannelsSuccess: ['channels'],

    getChannelError: ['error'],
    getChannelStart: ['id', 'view'],
    getChannelSuccess: ['channel'],

    postChannelError: ['error'],
    postChannelStart: ['data'],
    postChannelSuccess: ['channel'],

    putChannelError: ['error'],
    putChannelStart: ['data'],
    putChannelSuccess: ['data'],

    cancelChannelError: ['error'],
    cancelChannelStart: ['data'],
    cancelChannelSuccess: [],

    assignKeysToUserError: ['error'],
    assignKeysToUserStart: ['data'],
    assignKeysToUserSuccess: [],

    removeUserFromKeysError: ['error'],
    removeUserFromKeysStart: ['data'],
    removeUserFromKeysSuccess: [],

    activeKeyError: ['error'],
    activeKeyStart: ['data'],
    activeKeySuccess: [''],

    toggleKeysToRenewError: ['error'],
    toggleKeysToRenewStart: ['data'],
    toggleKeysToRenewSuccess: [''],

    validateCardOwnerChannelError: ['error'],
    validateCardOwnerChannelStart: ['data'],
    validateCardOwnerChannelSuccess: [''],

    applyMembershipDiscountCodeError: ['error'],
    applyMembershipDiscountCodeStart: ['data'],
    applyMembershipDiscountCodeSuccess: [''],

    getChannelCalendarWorkoutsError: ['error'],
    getChannelCalendarWorkoutsStart: ['id', 'params'],
    getChannelCalendarWorkoutsSuccess: ['calendarWorkouts'],

    connectStripeAccountError: ['error'],
    connectStripeAccountStart: ['channelId', 'code', 'callback'],
    connectStripeAccountSuccess: ['link'],

    removeStripeAccountError: ['error'],
    removeStripeAccountStart: ['channelId', 'callback'],
    removeStripeAccountSuccess: ['link'],

    clearChannel: [],
    setChannel: ['channel'],
  },
  { prefix: 'channels/' },
);

/* ---------- Initial State ---------- */
const INITIAL_STATE = Immutable({
  data: [],
  calendarWorkouts: [],
  channel: {},
  channelCoaches: [],
  favoriteChannels: {},
  error: null,
  loading: false,
  loadingChannel: false,
  loadingActiveKey: false,
  loadingChannelUpcomingWorkouts: false,
  loadingKeys: false,
  loadingCoach: false,
  loadingValidationCard: false,
  loadingDiscountCode: false,
  loadingConnectAccount: false,
});

/* ---------- Reducers ---------- */
const loadStartReducer = (state) => state.set('loading', true);
const loadChannelStartReducer = (state) => state.set('loadingChannel', true);
const getChannelCalendarWorkoutsStartReducer = (state) =>
  state.set('loadingChannelUpcomingWorkouts', true);
const loadKeysStartReducer = (state) => state.set('loadingKeys', true);
const activeKeyStartReducer = (state) => state.set('loadingActiveKey', true);
const loadValidateCardOwnerChannelStartReducer = (state) =>
  state.set('loadingValidationCard', true);
const applyMembershipDiscountCodeStartReducer = (state) => state.set('loadingDiscountCode', true);
const connectStripeAccountStartReducer = (state) => state.set('loadingConnectAccount', true);

const getChannelsSuccessReducer = (state, action) =>
  state.merge({
    data: action.channels,
    loading: false,
    error: null,
  });

const getChannelSuccessReducer = (state, action) => {
  const data = action?.channel || {};

  persistData('MEMBER_CHANNEL', JSON.stringify(data));

  return state.merge({
    channel: action.channel,
    loadingChannel: false,
    error: null,
  });
};

const setChannelReducer = (state, action) =>
  state.merge({
    channel: action.channel,
  });

const postChannelSuccessReducer = (state, action) => {
  const data = action?.channel || {};

  persistData('MEMBER_CHANNEL', JSON.stringify(data));

  return state.merge({
    channel: data,
    loading: false,
    error: null,
  });
};

const putChannelSuccessReducer = (state, action) =>
  state.merge({
    channel: action.data || {},
    loading: false,
    error: null,
  });

const getChannelCalendarWorkoutsSuccessReducer = (state, action) =>
  state.merge({
    loadingChannelUpcomingWorkouts: false,
    calendarWorkouts: action.calendarWorkouts,
    error: null,
  });

const getChannelCalendarWorkoutsErrorReducer = (state) =>
  state.merge({
    loadingChannelUpcomingWorkouts: false,
    calendarWorkouts: [],
    error: null,
  });

const clearChannelReducer = (state) =>
  state.merge({
    channel: {},
    loading: false,
    error: null,
  });

const loadErrorReducer = (state, action) =>
  state.merge({
    loading: false,
    loadingPurchase: false,
    error: action.error,
  });

const loadValidateCardOwnerChannelErrorReducer = (state, action) =>
  state.merge({
    loadingValidationCard: false,
    error: action.error,
  });

const loadKeysErrorReducer = (state, action) =>
  state.merge({
    loadingKeys: false,
    error: action.error,
  });

const activeKeyErrorReducer = (state, action) =>
  state.merge({
    loadingActiveKey: false,
    error: action.error,
  });

const applyMembershipDiscountCodeErrorReducer = (state, action) =>
  state.merge({
    loadingDiscountCode: false,
    error: action.error,
  });

const loadKeysSuccessReducer = (state) =>
  state.merge({
    loadingKeys: false,
    error: null,
  });

const activeKeySuccessReducer = (state) =>
  state.merge({
    loadingActiveKey: false,
    error: null,
  });

const cancelChannelSuccessReducer = (state) =>
  state.merge({
    loading: false,
    error: null,
  });

const loadValidateCardOwnerChannelSuccessReducer = (state) =>
  state.merge({
    loadingValidationCard: false,
    error: null,
  });

const applyMembershipDiscountCodeSuccessReducer = (state) =>
  state.merge({
    loadingDiscountCode: false,
    error: null,
  });

const connectStripeAccountSuccessReducer = (state) =>
  state.merge({
    loadingConnectAccount: false,
  });

const connectStripeAccountErrorReducer = (state) =>
  state.merge({
    loadingConnectAccount: false,
  });

/* ---------- Main Reducer ---------- */
const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_CHANNELS_START]: loadStartReducer,
  [Types.GET_CHANNELS_SUCCESS]: getChannelsSuccessReducer,
  [Types.GET_CHANNELS_ERROR]: loadErrorReducer,

  [Types.GET_CHANNEL_START]: loadChannelStartReducer,
  [Types.GET_CHANNEL_SUCCESS]: getChannelSuccessReducer,
  [Types.GET_CHANNEL_ERROR]: loadErrorReducer,

  [Types.POST_CHANNEL_START]: loadStartReducer,
  [Types.POST_CHANNEL_SUCCESS]: postChannelSuccessReducer,
  [Types.POST_CHANNEL_ERROR]: loadErrorReducer,

  [Types.PUT_CHANNEL_START]: loadStartReducer,
  [Types.PUT_CHANNEL_SUCCESS]: putChannelSuccessReducer,
  [Types.PUT_CHANNEL_ERROR]: loadErrorReducer,

  [Types.CANCEL_CHANNEL_START]: loadStartReducer,
  [Types.CANCEL_CHANNEL_SUCCESS]: cancelChannelSuccessReducer,
  [Types.CANCEL_CHANNEL_ERROR]: loadErrorReducer,

  [Types.ASSIGN_KEYS_TO_USER_START]: loadKeysStartReducer,
  [Types.ASSIGN_KEYS_TO_USER_SUCCESS]: loadKeysSuccessReducer,
  [Types.ASSIGN_KEYS_TO_USER_ERROR]: loadKeysErrorReducer,

  [Types.REMOVE_USER_FROM_KEYS_START]: loadKeysStartReducer,
  [Types.REMOVE_USER_FROM_KEYS_SUCCESS]: loadKeysSuccessReducer,
  [Types.REMOVE_USER_FROM_KEYS_ERROR]: loadKeysErrorReducer,

  [Types.ACTIVE_KEY_START]: activeKeyStartReducer,
  [Types.ACTIVE_KEY_SUCCESS]: activeKeySuccessReducer,
  [Types.ACTIVE_KEY_ERROR]: activeKeyErrorReducer,

  [Types.TOGGLE_KEYS_TO_RENEW_START]: loadKeysStartReducer,
  [Types.TOGGLE_KEYS_TO_RENEW_SUCCESS]: loadKeysSuccessReducer,
  [Types.TOGGLE_KEYS_TO_RENEW_ERROR]: loadKeysErrorReducer,

  [Types.VALIDATE_CARD_OWNER_CHANNEL_START]: loadValidateCardOwnerChannelStartReducer,
  [Types.VALIDATE_CARD_OWNER_CHANNEL_SUCCESS]: loadValidateCardOwnerChannelSuccessReducer,
  [Types.VALIDATE_CARD_OWNER_CHANNEL_ERROR]: loadValidateCardOwnerChannelErrorReducer,

  [Types.APPLY_MEMBERSHIP_DISCOUNT_CODE_START]: applyMembershipDiscountCodeStartReducer,
  [Types.APPLY_MEMBERSHIP_DISCOUNT_CODE_SUCCESS]: applyMembershipDiscountCodeSuccessReducer,
  [Types.APPLY_MEMBERSHIP_DISCOUNT_CODE_ERROR]: applyMembershipDiscountCodeErrorReducer,

  [Types.GET_CHANNEL_CALENDAR_WORKOUTS_START]: getChannelCalendarWorkoutsStartReducer,
  [Types.GET_CHANNEL_CALENDAR_WORKOUTS_SUCCESS]: getChannelCalendarWorkoutsSuccessReducer,
  [Types.GET_CHANNEL_CALENDAR_WORKOUTS_ERROR]: getChannelCalendarWorkoutsErrorReducer,

  [Types.CONNECT_STRIPE_ACCOUNT_START]: connectStripeAccountStartReducer,
  [Types.CONNECT_STRIPE_ACCOUNT_SUCCESS]: connectStripeAccountSuccessReducer,
  [Types.CONNECT_STRIPE_ACCOUNT_ERROR]: connectStripeAccountErrorReducer,

  [Types.REMOVE_STRIPE_ACCOUNT_START]: connectStripeAccountStartReducer,
  [Types.REMOVE_STRIPE_ACCOUNT_SUCCESS]: connectStripeAccountSuccessReducer,
  [Types.REMOVE_STRIPE_ACCOUNT_ERROR]: connectStripeAccountErrorReducer,

  [Types.SET_CHANNEL]: setChannelReducer,
  [Types.CLEAR_CHANNEL]: clearChannelReducer,
});

/* ---------- Exporting ---------- */
export const channelsActions = Creators;
export const channelsTypes = Types;
export const channelsSelectors = selectors;
export const channelsSagas = sagas;

export default reducer;
