import api from 'core/api';

export const channelDashboardModule = 'channelDashboard';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const loadingNewMembersStates = {
  start: { loadingNewMembers: true },
  error: { loadingNewMembers: false },
  success: { loadingNewMembers: false },
};

const loadingAnniversariesStates = {
  start: { loadingAnniversaries: true },
  error: { loadingAnniversaries: false },
  success: { loadingAnniversaries: false },
};

const loadingMonthBirthdayStates = {
  start: { loadingMonthBirthday: true },
  error: { loadingMonthBirthday: false },
  success: { loadingMonthBirthday: false },
};

const actions = {
  dashboardResume: {
    module: channelDashboardModule,
    name: 'dashboardResume',
    api: (data) => api.post(`/channel_dashboard/resume`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  newMembersMonth: {
    module: channelDashboardModule,
    name: 'newMembersMonth',
    api: (data) => api.post(`/channel_dashboard/new_members`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingNewMembersStates,
  },
  monthBirthday: {
    module: channelDashboardModule,
    name: 'monthBirthday',
    api: (data) => api.post(`/channel_dashboard/month_birthday`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingMonthBirthdayStates,
  },
  monthAnniversaries: {
    module: channelDashboardModule,
    name: 'monthAnniversaries',
    api: (data) => api.post(`/channel_dashboard/month_joined`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingAnniversariesStates,
  },
};

export default {
  actions,
  state: {
    loading: false,
    loadingNewMembers: false,
    loadingAnniversaries: false,
    loadingMonthBirthday: false,
  },
};
