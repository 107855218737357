import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import { Dialog } from '@material-ui/core';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import Typography from 'core/ui/Typography';
import { ButtonPrimary } from 'core/ui/Button';
import Texture from 'core/ui/Texture';
import InputPhone from 'core/ui/InputPhone';
import useFormLists from './form';

const ModalFormDenylist = ({ open, item, close, callback, mode }) => {
  const { t } = useTranslation();
  const { setValue, watch, handleSubmit, onSubmit, loading } = useFormLists({
    mode,
    item,
    callback,
  });
  return (
    <Dialog
      PaperProps={{ style: { borderRadius: 5, minWidth: 300 } }}
      maxWidth="sm"
      scroll="body"
      fullWidth
      onClose={() => close()}
      open={open}
    >
      <Texture>
        <Box p={5}>
          <Box>
            <Typography align="center" mb={2}>
              {t('channel.lists')}
            </Typography>
            <Box>
              <Box mb={3}>
                <TextFieldRounded
                  style={{ fontSize: 14, minWidth: 200, fontFamily: 'Roboto' }}
                  name="email"
                  value={watch('email') || ''}
                  onChange={(n) => setValue('email', n)}
                  placeholder={t('placeholder.email').toUpperCase()}
                />
              </Box>
              <Box>
                <InputPhone
                  value={watch('phone')}
                  name="phone"
                  setValue={(n) => setValue('phone', n?.number)}
                  placeholder="(55) 555-5555"
                />
              </Box>
            </Box>

            <Box mt={3} display="flex" justifyContent="flex-end">
              <ButtonPrimary disabled={loading} onClick={handleSubmit(onSubmit)}>
                {t('button.save')}
              </ButtonPrimary>
            </Box>
          </Box>
        </Box>
      </Texture>
    </Dialog>
  );
};

ModalFormDenylist.defaultProps = {
  close: () => {},
  callback: () => {},
  item: null,
  open: false,
  mode: 'new',
};

ModalFormDenylist.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  close: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  callback: PropTypes.func,
  open: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.object,
  mode: PropTypes.string,
};

export default ModalFormDenylist;
