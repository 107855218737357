import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import { Dialog } from '@material-ui/core';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import { ButtonPrimary } from 'core/ui/Button';
import Texture from 'core/ui/Texture';
import Radio from 'core/ui/Radio';
import Typography from 'core/ui/Typography';
import useFormGroupsChat from './form';

const ModalFormGroup = ({ ownerableType, ownerableId, open, item, close, callback, mode }) => {
  const { t } = useTranslation();
  const { setValue, watch, handleSubmit, onSubmit, loading } = useFormGroupsChat({
    mode,
    item,
    callback,
    ownerableType,
    ownerableId,
  });
  return (
    <Dialog
      PaperProps={{ style: { borderRadius: 5, minWidth: 300 } }}
      maxWidth="sm"
      scroll="body"
      fullWidth
      onClose={() => close()}
      open={open}
    >
      <Texture>
        <Box p={5}>
          <Box mb={3}>
            <TextFieldRounded
              style={{ fontSize: 14, minWidth: 200, fontFamily: 'Roboto' }}
              value={watch('name') || ''}
              onChange={(n) => setValue('name', n)}
              placeholder={t('placeholder.group.name').toUpperCase()}
            />
          </Box>

          <Box>
            <Typography>{t('label.accessType')}</Typography>
            {['everyone', 'active_members', 'private_users'].map((pItem) => (
              <Radio
                label={t(`label.group.${pItem}`)}
                checked={watch('accessType') === pItem}
                onClick={() => setValue('accessType', pItem)}
              />
            ))}
          </Box>

          <Box mt={3} display="flex" justifyContent="flex-end">
            <ButtonPrimary disabled={loading} onClick={handleSubmit(onSubmit)}>
              {t('button.save')}
            </ButtonPrimary>
          </Box>
        </Box>
      </Texture>
    </Dialog>
  );
};

ModalFormGroup.defaultProps = {
  close: () => {},
  callback: () => {},
  item: null,
  open: false,
  mode: 'new',
};

ModalFormGroup.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  close: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  callback: PropTypes.func,
  open: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.object,
  mode: PropTypes.string,
};

export default ModalFormGroup;
