import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form-v5';
import { useDispatch, useSelector } from 'react-redux';
import { channelsSelectors } from 'modules/channels';
import notifications from 'modules/notifications';
import { channelsDenylistsModule } from 'modules/channelsDenylists';
import useSelectors from 'modules/map/useSelectors';
import useActions from 'modules/map/useActions';
import Modules from 'modules';

export default ({ item, callback }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { request } = useActions();
  const channel = useSelector((state) => channelsSelectors.getChannel(state));
  const loading = useSelectors(channelsDenylistsModule, 'loading');
  const { register, handleSubmit, setValue, watch, reset, errors } = useForm();

  const onSubmit = (values) => {
    if (!values.email && !values.phone) {
      dispatch(notifications.warning('Please add a phone or email.'));
      return;
    }
    request({
      action: Modules.channelsDenylists.actions.create,
      data: {
        ...values,
        channelId: channel.id,
      },
      options: {
        onSuccess: () => {
          if (callback) {
            callback(values.name);
          }
          request({
            action: Modules.channelsDenylists.actions.getDenylists,
            data: {
              channelId: channel?.id,
            },
          });
          dispatch(notifications.success(t('message.success.save.channelsDenyLists')));
        },
      },
    });
  };

  useEffect(() => {
    register('phone', { required: false });
    register('email', { required: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register]);

  useEffect(() => {
    setTimeout(() => {
      reset(item);
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    register,
    setValue,
    watch,
    reset,
    errors,
    onSubmit,
    handleSubmit,
    loading,
  };
};
