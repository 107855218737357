import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import FontIcon from 'core/ui/FontIcon';
import { secondary } from 'core/ui/Colors';
import Typography from 'core/ui/Typography';
import Button from 'core/ui/Button';

export default function ModalChooseView({ open, close, setGalleryView }) {
  const { t } = useTranslation();

  return (
    <Dialog
      PaperProps={{ style: { borderRadius: 5, backgroundColor: secondary } }}
      maxWidth="sm"
      fullWidth
      scroll="body"
      onClose={() => close()}
      open={open}
    >
      <Box p={5}>
        <Typography mb={8} align="center" variant="h3" style={{ color: 'white' }}>
          {t('stream.recording.view.choose')}
        </Typography>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Box mr={5}>
            <Button onClick={() => setGalleryView(false)}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <FontIcon iconName="icon-speaker-view" size={100} color="white" />
                <Typography mt={3} style={{ color: 'white' }}>
                  {t('stream.recording.view.speaker')}
                </Typography>
              </Box>
            </Button>
          </Box>
          <Box ml={5}>
            <Button onClick={() => setGalleryView(true)}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <FontIcon iconName="icon-menu" size={100} color="white" />
                <Typography mt={3} style={{ color: 'white' }}>
                  {t('stream.recording.view.gallery')}
                </Typography>
              </Box>
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
}

ModalChooseView.defaultProps = {
  close: null,
};
