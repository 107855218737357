import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form-v5';
import { useDispatch, useSelector } from 'react-redux';
import { channelsSelectors } from 'modules/channels';
import notifications from 'modules/notifications';
import useSelectors from 'modules/map/useSelectors';
import { channelProgramsModule } from 'modules/channelPrograms';
import useActions from 'modules/map/useActions';
import Modules from 'modules';

export default ({ mode, programId, callback }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const channel = useSelector((state) => channelsSelectors.getChannel(state));
  const loading = useSelectors(channelProgramsModule, 'loading');
  const program = useSelectors(channelProgramsModule, 'program');
  const { request } = useActions();
  const { register, handleSubmit, setValue, watch, reset, errors } = useForm();

  useEffect(() => {
    if (programId) {
      request({
        action: Modules.channelPrograms.actions.getChannelProgram,
        data: {
          channelId: channel?.id,
          channelProgramId: programId,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programId]);

  const onSubmit = (values) => {
    request({
      action:
        mode === 'new'
          ? Modules.channelPrograms.actions.postChannelPrograms
          : Modules.channelPrograms.actions.putChannelPrograms,
      data: { ...values, listId: program?.id, channelId: channel.id },
      options: {
        onSuccess: () => {
          if (callback) {
            callback(values.name);
          }
          dispatch(notifications.success(t('message.success.save.program')));
          request({
            action: Modules.channelPrograms.actions.getChannelPrograms,
            data: { channelId: channel.id },
          });
        },
      },
    });
  };

  useEffect(() => {
    register('name', { required: t('form.list.validate.name') });
    register('active', { required: false });
    register('default', { required: false });
    register('channelMembershipPlanIds', { required: false });

    if (mode === 'new') {
      setValue('active', true);
      setValue('default', false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register]);

  useEffect(() => {
    if (programId && program?.id) {
      reset(program);
      setTimeout(() => {
        if (program?.id && program?.channelMembershipPlans) {
          setValue(
            'channelMembershipPlanIds',
            program?.channelMembershipPlans.map((x) => x.id),
          );
        }
      }, 300);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programId, program?.id]);

  return {
    register,
    setValue,
    watch,
    reset,
    errors,
    onSubmit,
    handleSubmit,
    loading,
  };
};
