import React from 'react';
import Box from 'core/ui/Box';
import Typography, { fontStyle } from 'core/ui/Typography';
import { Hidden, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import { redColor, secondary } from 'core/ui/Colors';
import ImageProduct from 'components/Store/Products/Image';
import { channelCurrency } from 'core/utils/formatters';
import { textStoreOptions } from 'core/utils/helpers';
import { useTranslation } from 'react-i18next';

const PurchaseResumeItem = ({ cartItem, shoppingCart, previewOrder, buttonColor }) => {
  const { product, option } = cartItem;
  const { t } = useTranslation();

  const resumeItemStore =
    previewOrder?.items?.find((x) => Number(x.storeProductInventoryId) === Number(option?.id)) ||
    {};

  const ProductInfo = () => (
    <Box>
      <Typography style={{ color: buttonColor, ...fontStyle.medium }} variant="subtitle1">
        {product.name}
      </Typography>
      {option && (
        <Typography style={{ color: secondary, fontSize: 10 }} variant="subtitle1">
          {textStoreOptions(option)}
        </Typography>
      )}
    </Box>
  );

  const TableCellAmounts = () => (
    <>
      <TableCell align="center">
        <Typography style={fontStyle.xSmall}>{t('payment.resume.salesTax')}</Typography>
        <Typography ml={1} style={fontStyle.medium} component="span">
          {channelCurrency(resumeItemStore?.salesTaxAmount, shoppingCart?.channel?.currency)}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Box>
          <Typography style={fontStyle.xSmall}>{t('payment.resume.total')}</Typography>
          <Typography ml={1} style={fontStyle.medium} component="span">
            {channelCurrency(resumeItemStore?.totalOriginal, shoppingCart?.channel?.currency)}
          </Typography>
          {resumeItemStore?.discountAmount > 0 && (
            <span>
              <Typography style={{ ...fontStyle.xSmall, color: secondary }}>Discount</Typography>
              <Typography style={{ ...fontStyle.xSmall, color: redColor }}>
                -{channelCurrency(resumeItemStore?.discountAmount, shoppingCart?.channel?.currency)}
              </Typography>
            </span>
          )}
        </Box>
      </TableCell>
    </>
  );

  return (
    <TableRow>
      <Hidden only={['xs', 'sm']}>
        <TableCell>
          <ImageProduct indicators={false} images={product.images || []} size={44} />
        </TableCell>
        <TableCell>
          <ProductInfo />
        </TableCell>
        <TableCell align="center" style={{ width: 100 }}>
          <Typography>{cartItem.qty}</Typography>
        </TableCell>

        <TableCellAmounts />
      </Hidden>
      <Hidden only={['md', 'lg', 'xl']}>
        <TableCell>
          <Box display="flex">
            <ImageProduct indicators={false} images={product.images || []} size={44} />
            <Box flexGrow={1} ml={3}>
              <ProductInfo />
            </Box>
          </Box>

          <Box m={2}>
            <Typography>{cartItem.qty}</Typography>
          </Box>

          <Box m={2}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCellAmounts />
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </TableCell>
      </Hidden>
    </TableRow>
  );
};

PurchaseResumeItem.defaultProps = {};

export default PurchaseResumeItem;
