import React from 'react';

export const renderEventContent = ({ view, event, timeText }) => {
  if (view.type !== 'timeGridDay' && view.type !== 'timeGridWeek')
    return (
      <div className="tw-flex tw-flex-row tw-flex-nowrap">
        <div className="fc-event-time tw-w-12">{timeText}</div>
        {event?.extendedProps?.type === 'unavailable_time' && (
          <div className="fc-event-title fc-sticky tw-font-normal">
            Unavailable&nbsp;
            {event.extendedProps?.description ? event.extendedProps?.description : ''}
          </div>
        )}
        {event?.extendedProps?.type === 'slot' && (
          <div className="fc-event-title fc-sticky tw-font-normal">
            Available - {event.extendedProps?.usersCalendar?.name}
          </div>
        )}
        {event?.extendedProps?.type === 'registration' && (
          <div className="fc-event-title fc-sticky tw-font-normal">
            {event.extendedProps?.usersCalendar?.channelsWorkoutsType?.name}&nbsp;-&nbsp;
            {event.extendedProps?.user?.name || event.extendedProps?.usersCalendar?.user?.firstName}
          </div>
        )}
      </div>
    );

  return (
    <div>
      <div className="fc-event-main-frame tw-overflow-scroll tw-pl-1">
        <div className="fc-event-time">{timeText}</div>

        <div className="fc-event-title-container tw-flex tw-flex-row">
          {event?.extendedProps?.type === 'unavailable_time' && (
            <div className="fc-event-title fc-sticky tw-font-normal">
              Unavailable&nbsp;
              {event.extendedProps?.description ? event.extendedProps?.description : ''}
            </div>
          )}
          {event?.extendedProps?.type === 'slot' && (
            <div className="fc-event-title fc-sticky">
              Available - {event.extendedProps?.usersCalendar?.name}
            </div>
          )}
          <div className="fc-event-title fc-sticky">
            {event?.extendedProps?.type === 'registration' && (
              <div>
                {event.extendedProps?.usersCalendar?.user?.firstName}&nbsp;-&nbsp;
                {event.extendedProps?.usersCalendar?.channelsWorkoutsType?.name}
              </div>
            )}
          </div>
          {event?.extendedProps?.type === 'slot' && (
            <div className="fc-event-title fc-sticky">{event.title}</div>
          )}
        </div>
      </div>
    </div>
  );
};
