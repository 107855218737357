import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

import * as selectors from './selectors';
import * as sagas from './sagas';

/* ---------- Action Creators and Types ---------- */
const { Types, Creators } = createActions(
  {
    postSupportError: ['error'],
    postSupportStart: ['data'],
    postSupportSuccess: ['support'],

    getSupportError: ['error'],
    getSupportStart: ['pagination'],
    getSupportSuccess: ['support', 'pagination'],
  },
  { prefix: 'support/' },
);

/* ---------- Initial State ---------- */
const INITIAL_STATE = Immutable({
  data: [],
  error: null,
  loading: false,
});

/* ---------- Reducers ---------- */
const loadStartReducer = (state) => state.set('loading', true);

const postSupportSuccessReducer = (state, action) =>
  state.merge({
    support: action.support,
    loading: false,
    error: null,
  });

const getSupportSuccessReducer = (state, action) =>
  state.merge({
    data: action.support,
    pagination: action.pagination,
    loading: false,
    error: null,
  });

const loadErrorReducer = (state, action) =>
  state.merge({
    loading: false,
    error: action.error,
  });

/* ---------- Main Reducer ---------- */
const reducer = createReducer(INITIAL_STATE, {
  [Types.POST_SUPPORT_START]: loadStartReducer,
  [Types.POST_SUPPORT_SUCCESS]: postSupportSuccessReducer,
  [Types.POST_SUPPORT_ERROR]: loadErrorReducer,

  [Types.GET_SUPPORT_START]: loadStartReducer,
  [Types.GET_SUPPORT_SUCCESS]: getSupportSuccessReducer,
  [Types.GET_SUPPORT_ERROR]: loadErrorReducer,
});

/* ---------- Exporting ---------- */
export const supportActions = Creators;
export const supportTypes = Types;
export const supportSelectors = selectors;
export const supportSagas = sagas;

export default reducer;
