import React, { useState } from 'react';
import Modules from 'modules';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import { ButtonPrimary } from 'core/ui/Button';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import useActions from 'modules/map/useActions';
import { redColor } from 'core/ui/Colors';

export const DestroyHelpVideoButton = ({ helpVideo, getHelpVideosToSelect }) => {
  const { request } = useActions();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClickOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const destroyHelpVideo = () => {
    setLoading(true);

    request({
      action: Modules.helpVideo.actions.destroy,
      data: helpVideo.id,
      options: {
        onSuccess: () => {
          getHelpVideosToSelect();
          setLoading(false);
          handleClose();
        },
        onError: () => {
          setLoading(false);
        },
      },
    });
  };

  return (
    <>
      <ButtonPrimary buttonColor={redColor} onClick={handleClickOpen}>
        <DeleteForeverIcon />
      </ButtonPrimary>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete Help Video?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This will remove all references to this video on all pages in the system.
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={destroyHelpVideo} autoFocus>
            {loading ? <CircularProgress /> : 'Delete'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
