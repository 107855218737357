import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { IconButton } from '@material-ui/core';
import Typography, { fontStyle } from 'core/ui/Typography';
import { Edit } from '@material-ui/icons';
import { defaultScoresVariations } from 'core/utils/consts';
import { reorder } from 'core/utils/helpers';
import Paper from 'core/ui/Paper';
import Divider from 'core/ui/Divider';
import { ChannelContext } from '..';

const ListTaxes = ({ onEditClick }) => {
  const { t } = useTranslation();
  const formContext = useContext(ChannelContext);
  const scoresVariations = formContext.watch('scoresVariations') || [];

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newItems = reorder([...scoresVariations], result.source.index, result.destination.index);

    formContext.setValue(
      'scoresVariations',
      newItems?.map((x, idx) => ({ ...x, showOrder: idx })),
    );
  };

  return (
    <Box mt={2}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Paper>
          <Box px={4} pt={2}>
            <Typography>{t('label.name')}</Typography>
          </Box>
          <Divider mt={2} />
          {defaultScoresVariations.map((row, index) => (
            <Box key={index.toString()}>
              <Box p={4}>
                <Typography variant="body1" style={fontStyle.small} color="secondary">
                  {row.name}
                </Typography>
              </Box>
              {scoresVariations?.length - 1 > index && <Divider />}
            </Box>
          ))}
          <Divider />

          <Box>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {scoresVariations.map((row, index) => (
                    <Draggable key={row.id} draggableId={`${row.id}`} index={index}>
                      {(provided, snapshot) => (
                        <Box
                          key={index.toString()}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <Box display="flex" px={4} alignItems="center">
                            <Box flexGrow={1}>
                              <Box>
                                <Typography
                                  variant="body1"
                                  style={fontStyle.small}
                                  ml={1}
                                  color="secondary"
                                >
                                  {row.name}
                                </Typography>
                              </Box>
                            </Box>

                            <Box align="right">
                              <IconButton onClick={() => onEditClick(row)}>
                                <Edit />
                              </IconButton>
                            </Box>
                          </Box>
                          {scoresVariations?.length - 1 > index && <Divider />}
                        </Box>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </Box>
        </Paper>
      </DragDropContext>
    </Box>
  );
};

ListTaxes.propTypes = {
  onEditClick: PropTypes.func.isRequired,
};

export default ListTaxes;
