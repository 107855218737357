import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { channelsActions, channelsSelectors } from 'modules/channels';
import Box from 'core/ui/Box';
import Paper from 'core/ui/Paper';
import Typography from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import { primary, secondary } from 'core/ui/Colors';
import MIcon from 'core/ui/MIcon';
import { channelListsActions, channelListsSelectors } from 'modules/channelLists';
import { sessionSelectors } from 'modules/session';
import GridSkeleton from 'core/ui/GridSkeleton';
import notifications from 'modules/notifications';
import { LayoutContext } from 'pages/shared/Layout';
import ListWorkouts from './ListWorkouts';
import ModalPaymentList from './ModalPaymentList';

export default () => {
  const layoutContext = useContext(LayoutContext);

  const channel = useSelector((state) => channelsSelectors.getChannel(state));
  const isAuthenticated = useSelector((state) => sessionSelectors.getIsAuthenticated(state));
  const currentUser = useSelector((state) => sessionSelectors.getUser(state));
  const loading = useSelector((state) => channelListsSelectors.getLoading(state));
  const channelLists = useSelector((state) => channelListsSelectors.getChannelLists(state));
  const [modalPayment, setModalPayment] = useState({ open: false, list: null });
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const buttonColor = channel?.customColors?.buttonColor || primary;

  useEffect(() => {
    if (channel && channel.id) {
      dispatch(channelListsActions.getChannelListsStart(channel.id));
    }
  }, [dispatch, channel]);

  const onBuyClick = (list) => {
    setModalPayment({
      open: true,
      list,
    });
  };

  const login = () => {
    layoutContext.setModalLogin({ open: true });
  };

  const showBuyButton = (row) => {
    if (channel) {
      const userExists = channel.userLists && channel.userLists.find((x) => x.id === row.id);

      return !userExists && currentUser && !channel.accessType && row.price > 0;
    }

    return false;
  };

  return (
    <>
      {modalPayment.open && (
        <ModalPaymentList
          channel={channel}
          list={modalPayment.list}
          open={modalPayment.open}
          close={() => setModalPayment(() => ({ open: false, list: null }))}
          callback={() => {
            dispatch(notifications.success(t('purchase.list.success')));
            dispatch(channelsActions.getChannelStart(channel.id));
            dispatch(channelListsActions.getChannelListsStart(channel.id));
          }}
        />
      )}
      <Paper className="paper-rounded" p={5}>
        <Box mb={3} display="flex" alignItems="center">
          <Box flexGrow="1" display="flex" alignItems="center">
            <MIcon icon="list" color={secondary} size={24} />
            <Typography ml={1} color="secondary" className="link" variant="h5">
              {t('channel.lists').toUpperCase()}
            </Typography>
          </Box>
        </Box>

        <Box>
          {loading ? (
            <Box>
              <GridSkeleton />
            </Box>
          ) : (
            <>
              {channelLists &&
                channelLists
                  .filter((x) => x.workouts && x.workouts.length > 0)
                  .map((row) => (
                    <ListWorkouts
                      channel={channel}
                      showBuyButton={showBuyButton(row)}
                      onBuyClick={!isAuthenticated ? login : onBuyClick}
                      key={row.name}
                      item={row}
                      buttonColor={buttonColor}
                    />
                  ))}
            </>
          )}
        </Box>
      </Paper>
    </>
  );
};
