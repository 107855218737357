import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import Typography, { fontStyle } from 'core/ui/Typography';
import { Checkbox, FormControlLabel, Radio } from '@material-ui/core';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import ReactPlayer from 'react-player';
import Paper from 'core/ui/Paper';
import notifications from 'modules/notifications';
import { useDispatch } from 'react-redux';
import Video from 'core/ui/Video';
import { WorkoutContext } from '../index';
import VideoUpload from '../VideoUpload';

export default () => {
  const { t } = useTranslation();
  const formContext = useContext(WorkoutContext);
  const [accept, setAccept] = useState(false);
  const videoUrl = formContext.watch('videoLink');
  const dispatch = useDispatch();

  useEffect(() => {
    if (videoUrl) {
      setAccept(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setAccept, formContext]);

  const setAttest = () => {
    setAccept(!accept);
    formContext.setValue('videoLink', '');
  };

  const errorVideo = () => {
    formContext.setValue('videoLink', '');
    dispatch(notifications.warning(t('workout.video.validate.link')));
  };
  const videoRef = useRef();

  return (
    <Box>
      <Box display="flex" alignItems="center">
        <Box flexGrow="1">
          <Typography variant="h6" color="primary">
            {t('workout.label.method.video')}
          </Typography>
        </Box>
      </Box>

      <FormControlLabel
        control={
          <Checkbox
            checked={accept}
            name="filterType"
            size="small"
            color="primary"
            onChange={() => setAttest()}
          />
        }
        label={
          <Typography noWrap variant="body2" color="secondary">
            {t('workout.method.video.accept')}
          </Typography>
        }
      />

      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        <Box display="flex" alignItems="center" justifyContent="center">
          <Box display="flex" justifyContent="center" alignItems="center">
            <Radio
              color="primary"
              checked={formContext.upload.type === 'link'}
              onChange={() => formContext.setUpload((prev) => ({ ...prev, type: 'link' }))}
            />
            <Typography style={fontStyle.label} variant="body1">
              {t('workout.upload.link').toUpperCase()}
            </Typography>
          </Box>
          <Box ml={3} display="flex" justifyContent="center" alignItems="center">
            <Radio
              color="primary"
              checked={formContext.upload.type === 'file'}
              onChange={() => formContext.setUpload((prev) => ({ ...prev, type: 'file' }))}
            />
            <Typography style={fontStyle.label} variant="body1">
              {t('workout.upload.file').toUpperCase()}
            </Typography>
          </Box>
        </Box>
      </Box>

      {formContext.upload.type === 'file' && <VideoUpload accept={accept} />}
      {formContext.upload.type === 'link' && (
        <Box>
          <TextFieldRounded
            value={formContext.watch('videoLink') || ''}
            onChange={(v) => formContext.setValue('videoLink', v)}
            placeholder={t('workout.placeholder.video').toUpperCase()}
            disabled={!accept}
          />
          {videoUrl && (
            <Paper p={3} mt={3}>
              <Box display="flex" justifyContent="center">
                {videoUrl.indexOf('streamfitness-workout-video') > -1 ? (
                  <Video src={videoUrl} controls showPlayButton={false} />
                ) : (
                  <Box className="container-aspect container-aspect-37">
                    <Box className="item-aspect">
                      <ReactPlayer
                        playing={false}
                        ref={videoRef}
                        onReady={() => {
                          if (videoRef.current) {
                            formContext.setValue(
                              'durationVideoLink',
                              videoRef.current ? videoRef.current.getDuration() : 60,
                            );
                            setTimeout(() => {
                              formContext.setValue(
                                'durationVideoLink',
                                videoRef.current ? videoRef.current.getDuration() : 60,
                              );
                            }, 2000);
                          }
                        }}
                        onError={(e) => errorVideo(e)}
                        url={videoUrl}
                        width="100%"
                        height="100%"
                      />
                    </Box>
                  </Box>
                )}
              </Box>
            </Paper>
          )}
        </Box>
      )}
    </Box>
  );
};
