import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Box from 'core/ui/Box';
import { workoutsSelectors } from 'modules/workouts';
import { ButtonPrimary } from 'core/ui/Button';
import { useHistory } from 'react-router';
import { sessionWorkoutActions } from 'modules/sessionWorkout';
import { isMobile } from 'react-device-detect';
import ModalDownloadApp from 'core/ui/ModalDownloadApp';
import FontIcon from 'core/ui/FontIcon';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import { Publish } from '@material-ui/icons';
import { sessionSelectors } from 'modules/session';
import { ShowContext } from '../../index';
import ModalUpload from './ModalUpload';

const ButtonActions = ({ showTimer }) => {
  const { t } = useTranslation();
  const [openModalDownloadApp, setOpenModalDownloadApp] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const currentUser = useSelector((state) => sessionSelectors.getUser(state));
  const workout = useSelector((state) => workoutsSelectors.getWorkout(state));
  const history = useHistory();
  const showContext = useContext(ShowContext);
  const { buttonColor, competition } = showContext;
  const { isCoach, isJudge } = competition;
  const dispatch = useDispatch();

  const startWorkout = () => {
    if (isMobile) {
      setOpenModalDownloadApp(true);
      return;
    }
    dispatch(
      sessionWorkoutActions.startWorkoutCompetitionStart({
        workoutId: workout.id,
        callback: () => {
          history.push(`/workout/${workout.id}/stream`);
        },
      }),
    );
  };

  const goToWatch = () => {
    history.push(`/competition/${workout.competitionId}/workout/${workout.id}/watch`);
  };

  const goToTeamWatch = () => {
    history.push(
      `/competition/${workout.competitionId}/workout/${workout.id}/watch/team/${currentUser?.id}`,
    );
  };

  const goToJudge = () => {
    history.push(`/competition/${workout.competitionId}/workout/${workout.id}/judge`);
  };

  const styleIcon = {
    fontSize: 16,
  };
  const styleButton = {
    minWidth: 100,
  };

  return (
    <div className="tw-flex tw-flex-row tw-flex-wrap tw-items-center tw-gap-2">
      <Box>
        {(isJudge || isCoach) && !competition?.hideJudge && (
          <ButtonPrimary buttonColor={buttonColor} style={styleButton} onClick={() => goToJudge()}>
            <VerifiedUserIcon style={styleIcon} />
            &nbsp;{t('button.judge').toUpperCase()}
          </ButtonPrimary>
        )}
      </Box>
      <Box>
        {isCoach && (
          <ButtonPrimary buttonColor={buttonColor} style={styleButton} onClick={() => showTimer()}>
            <VerifiedUserIcon style={styleIcon} />
            &nbsp;{t('button.timer').toUpperCase()}
          </ButtonPrimary>
        )}
      </Box>
      <Box>
        {workout?.allowWatch && workout.hasRecordings ? (
          <ButtonPrimary buttonColor={buttonColor} style={styleButton} onClick={() => goToWatch()}>
            <FontIcon iconName="icon-classes" size={styleIcon.fontSize} />
            &nbsp;{t('button.watch').toUpperCase()}
          </ButtonPrimary>
        ) : (
          <div>
            {workout?.competitionEventInfo?.recorded && (
              <ButtonPrimary
                buttonColor={buttonColor}
                style={styleButton}
                onClick={() => goToTeamWatch()}
              >
                <FontIcon iconName="icon-classes" size={styleIcon.fontSize} />
                &nbsp;{t('button.myWorkout').toUpperCase()}
              </ButtonPrimary>
            )}
          </div>
        )}
      </Box>
      {/* <Box>
        {workout.allowJoinWorkout && (
          <ButtonPrimary
            buttonColor={buttonColor}
            style={styleButton}
            onClick={() => startWorkout()}
          >
            <FiberSmartRecord style={styleIcon} />
            &nbsp;{t('button.recordWorkout').toUpperCase()}
          </ButtonPrimary>
        )}
      </Box> */}
      <Box>
        {workout.allowJoinWorkout && (
          <ButtonPrimary
            buttonColor={buttonColor}
            style={styleButton}
            onClick={() => setOpenUpload(true)}
          >
            <Publish style={styleIcon} />
            &nbsp;{t('button.scores').toUpperCase()}&nbsp;/&nbsp;
            {t('button.upload').toUpperCase()}
          </ButtonPrimary>
        )}
      </Box>

      {isMobile && (
        <ModalDownloadApp
          open={openModalDownloadApp}
          close={() => setOpenModalDownloadApp(false)}
        />
      )}

      {openUpload && (
        <ModalUpload
          open={openUpload}
          buttonColor={buttonColor}
          close={() => setOpenUpload(false)}
        />
      )}
    </div>
  );
};

export default ButtonActions;
