import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form-v5';
import Modules from 'modules';
import useActions from 'modules/map/useActions';
import { useDispatch } from 'react-redux';
import notifications from 'modules/notifications';
import useSelectors from 'modules/map/useSelectors';
import { newMovementsModule } from 'modules/movements/new';

export default ({ callback }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { request } = useActions();
  const loading = useSelectors(newMovementsModule, 'loading');

  const { register, handleSubmit, setValue, watch, reset, errors } = useForm();

  const onSubmit = (values) => {
    request({
      action: Modules.newMovements.actions.create,
      data: {
        ...values,
      },
      options: {
        onSuccess: () => {
          dispatch(notifications.success(t('message.success.save.movement')));

          if (callback) {
            callback();
          }
        },
        onError: (err) => {
          dispatch(notifications.error(err));
        },
      },
    });
  };

  useEffect(() => {
    register('name', { required: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register]);

  return {
    register,
    setValue,
    watch,
    reset,
    errors,
    onSubmit,
    handleSubmit,
    loading,
  };
};
