/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import Box from 'core/ui/Box';
import TextAreaFieldRounded from 'core/ui/TextAreaFieldRounded';

import Paper from 'core/ui/Paper';

const FormInfo = ({ currentPlan, setCurrentPlan }) => {
  const { t } = useTranslation();

  return (
    <Paper className="paper-rounded" p={3} mb={1}>
      <Box mb={3}>
        <TextFieldRounded
          value={currentPlan.name || ''}
          placeholder={t('channel.placeholder.membershipsName')}
          label={t('channel.label.membershipsName').toUpperCase()}
          onChange={(v) => setCurrentPlan((prev) => ({ ...prev, name: v }))}
          bordered
        />
      </Box>

      <Box mb={3}>
        <TextAreaFieldRounded
          value={currentPlan.description || ''}
          placeholder={t('channel.placeholder.membershipsDescription')}
          label={t('channel.label.membershipsDescription').toUpperCase()}
          onChange={(v) => setCurrentPlan((prev) => ({ ...prev, description: v }))}
          bordered
        />
      </Box>
    </Paper>
  );
};

FormInfo.defaultProps = {
  currentPlan: {},
  setCurrentPlan: () => {},
};

export default FormInfo;
