/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Box from 'core/ui/Box';
import { channelReportsModule } from 'modules/channelReports';
import useSelectors from 'modules/map/useSelectors';
import Modules from 'modules';
import useActions from 'modules/map/useActions';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import { ButtonPrimary } from 'core/ui/Button';
import { Search } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { InputWrapper } from 'core/ui/ChosenComponents';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import ResultsWaivers from './ResultsWaivers';
import SkeletonWaiversReport from './Skeleton';

const WaiversReport = ({ channel, loading, getReport }) => {
  const data = useSelectors(channelReportsModule, 'data');
  const [documents, setDocuments] = useState([]);
  const [filters, setFilters] = useState({});
  const { t } = useTranslation();
  const { request } = useActions();

  const getData = () => {
    request({
      action: Modules.documents.actions.getDocuments,
      data: { ownerableId: channel.id, ownerableType: 'Channel', kind: 'waiver' },
      options: {
        onSuccess: setDocuments,
      },
    });
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const search = () => {
    if (!filters?.documentId) {
      return;
    }
    getReport({
      ...filters,
    });
  };

  useEffect(() => {
    search();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    if (!filters?.documentId && documents[0]?.id) {
      setFilters((prev) => ({ ...prev, documentId: documents[0]?.id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documents]);

  return (
    <Box mt={2}>
      <Box display="flex" mb={3} alignItems="flex-end" justifyContent="center" flexWrap="wrap">
        <Box mr={1}>
          <SelectBoxObject
            value={filters.documentId || 0}
            options={documents}
            propLabel="name"
            propValue="id"
            emptyItem
            emptyValue={0}
            setValue={(v) => setFilters((prev) => ({ ...prev, documentId: v }))}
            placeholder={t('label.document')}
            label={t('label.document').toUpperCase()}
          />
        </Box>
        <Box ml={1} flexGrow={1} display="flex" alignItems="center">
          <InputWrapper>
            <Box display="flex" style={{ width: '100%' }}>
              <TextFieldRounded
                value={filters?.query}
                onChange={(value) => setFilters((prev) => ({ ...prev, query: value }))}
                style={{ minWidth: 30, width: '100%', margin: '4px' }}
                placeholder={t('channel.waivers.search')}
              />
            </Box>
          </InputWrapper>
        </Box>

        <Box ml={3} pt={5}>
          <ButtonPrimary disabled={loading} onClick={() => search()}>
            <Search style={{ color: 'white' }} fontSize="small" />
            {t('button.search')}&nbsp;
          </ButtonPrimary>
        </Box>
      </Box>

      <Box>
        {!data.members || loading ? (
          <SkeletonWaiversReport />
        ) : (
          <Box>
            <ResultsWaivers channel={channel} members={data.members} getReport={getReport} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

WaiversReport.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  loading: PropTypes.bool,
  getReport: PropTypes.func,
};

WaiversReport.defaultProps = {
  loading: false,
  getReport: () => {},
};

export default WaiversReport;
