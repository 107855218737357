import React, { useState } from 'react';
import Box from 'core/ui/Box';

import Typography from 'core/ui/Typography';
import LeaderboardIcon from 'core/ui/MIcon/LeaderboardIcon';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { workoutsSelectors } from 'modules/workouts';
import Leaderboard from 'components/Workout/Show/Tabs/Overview/Leaderboard';
import { Collapse } from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowRight } from '@material-ui/icons';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import { workoutChannelPrograms } from 'core/utils/helpers';

export default ({ openDrawer }) => {
  const workout = useSelector((state) => workoutsSelectors.getWorkout(state));
  const { t } = useTranslation();
  const [currentOpen, setCurrentOpen] = useState(null);
  const [currentProgramId, setCurrentProgramId] = useState(null);
  const programs = workoutChannelPrograms(workout.sections);
  return (
    <Box display="flex" flexDirection="column" style={{ height: '100%' }}>
      <Box p={5} className="hover" display="flex" alignItems="center">
        <LeaderboardIcon size={30} color="white" />
        <Typography ml={2} style={{ color: 'white' }} component="h3" variant="h4">
          {t('leaderboard').toUpperCase()}
        </Typography>
      </Box>

      {programs && programs.length > 1 && (
        <Box
          p={3}
          mb={0.5}
          className="hover"
          style={{ background: '#545454' }}
          display="flex"
          alignItems="center"
        >
          <SelectBoxObject
            options={programs}
            value={currentProgramId || null}
            setValue={(v) => setCurrentProgramId(v)}
          />
        </Box>
      )}
      <Box>
        {workout?.sections
          ?.filter(
            (x) =>
              !x?.channelProgram ||
              !currentProgramId ||
              Number(x?.channelProgram?.id) === Number(currentProgramId),
          )
          ?.map((section) => (
            <Box key={section?.id}>
              <Box
                p={3}
                mb={0.5}
                className="hover"
                style={{ background: '#545454' }}
                onClick={() => setCurrentOpen(section?.id)}
                display="flex"
                alignItems="center"
              >
                <Box flexGrow={1}>
                  <Typography style={{ color: 'white' }}>{section?.title}</Typography>
                </Box>
                {currentOpen === section?.id ? (
                  <KeyboardArrowDown style={{ fontSize: 24, color: 'white' }} />
                ) : (
                  <KeyboardArrowRight style={{ fontSize: 24, color: 'white' }} />
                )}
              </Box>
              <Collapse in={currentOpen === section?.id}>
                <Box p={3}>
                  {openDrawer && currentOpen === section?.id && (
                    <Leaderboard workout={workout} section={section} singleView hideComments />
                  )}
                </Box>
              </Collapse>
            </Box>
          ))}
      </Box>
    </Box>
  );
};
