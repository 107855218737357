import { all, call, put } from 'redux-saga/effects';

import { getErrorMessage } from 'core/api/api-error';

import notifications from 'modules/notifications';

import * as api from './api';
import { channelMembersActions as actions } from './index';

// eslint-disable-next-line import/prefer-default-export
export function* getChannelMembers({ params }) {
  try {
    const response = yield call(api.getChannelMembers, params);
    const { pagination, data } = response;
    yield put(actions.getChannelMembersSuccess(data?.data, pagination));
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.getChannelMembersError(getErrorMessage(error))),
    ]);
  }
}

export function* getChannelMember({ channelId, userId, options }) {
  try {
    const response = yield call(api.getChannelMember, channelId, userId);
    const channelMembers = response.data;
    yield put(actions.getChannelMemberSuccess(channelMembers));
    if (options?.onSuccess) {
      options.onSuccess(channelMembers);
    }
  } catch (error) {
    if (options?.onError) {
      options.onError(error);
    } else {
      yield all([put(notifications.error(getErrorMessage(error)))]);
    }
    yield all([put(actions.getChannelMemberError(getErrorMessage(error)))]);
  }
}

export function* getAvailableClasses({ channelId, userId, workoutId }) {
  try {
    const response = yield call(api.getAvailableClasses, channelId, userId, workoutId || null);
    const classes = response.data;
    yield put(actions.getAvailableClassesSuccess(classes));
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.getAvailableClassesError(getErrorMessage(error))),
    ]);
  }
}

export function* putUpdateMember({ data, callback }) {
  try {
    const response = yield call(api.putUpdateMember, data);
    yield put(actions.putUpdateMemberSuccess());
    if (callback) {
      callback(response.data);
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.putUpdateMemberError(getErrorMessage(error))),
    ]);
  }
}

export function* postAddMember({ data, callback }) {
  try {
    const response = yield call(api.postAddMember, data);
    yield put(actions.postAddMemberSuccess());
    if (callback) {
      callback(response.data);
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.postAddMemberError(getErrorMessage(error))),
    ]);
  }
}

export function* uploadFileMembers({ data, callback }) {
  try {
    yield call(api.uploadFileMembers, data);
    yield put(actions.uploadFileMembersSuccess());
    if (callback) {
      callback();
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.uploadFileMembersError(getErrorMessage(error))),
    ]);
  }
}

export function* memberUnregisterClass({ channelId, userId, workoutId, callback }) {
  try {
    const response = yield call(api.memberUnregisterClass, channelId, userId, workoutId);
    const channelMembers = response.data;
    yield put(actions.memberUnregisterClassSuccess(channelMembers));
    if (callback) {
      callback();
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.memberUnregisterClassError(getErrorMessage(error))),
    ]);
  }
}

export function* memberRegisterClass({ data, callback, onError }) {
  try {
    const response = yield call(
      api.memberRegisterClass,
      data.channelId,
      data.userId,
      data.workoutId,
      data.type,
    );
    const channelMembers = response.data;
    yield put(actions.memberRegisterClassSuccess(channelMembers));
    if (callback) {
      callback();
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.memberRegisterClassError(getErrorMessage(error))),
    ]);
    if (onError) {
      onError(getErrorMessage(error));
    }
  }
}
