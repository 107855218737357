import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import { makeStyles, Slide } from '@material-ui/core';
import { Scrollbars } from 'react-custom-scrollbars';
import Form from 'components/Workout/Form';
import Box from 'core/ui/Box';
import useActions from 'modules/map/useActions';
import useSelectors from 'modules/map/useSelectors';
import { channelTemplatesModule } from 'modules/channelTemplates';
import Modules from 'modules';
import LoaderSm from 'core/ui/LoaderSm';
import Texture from 'core/ui/Texture';
import TemplatesTable from '../Library/ClassTemplates/Table';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const ModalClass = ({ channelId, modalClass, close, callback }) => {
  const { t } = useTranslation();
  const { request } = useActions();
  const [showForm, setShowForm] = useState(false);
  const [compareOptions, setCompareOptions] = useState({ open: false });
  const templates = useSelectors(channelTemplatesModule, 'templates');
  const loading = useSelectors(channelTemplatesModule, 'loading');
  const [selectedBenchmark, setSelectedBenchmark] = useState(null);

  const classes = useStyles();
  const [templateId, setTemplateId] = useState(null);

  const getTemplates = () => {
    request({
      action: Modules.channelTemplates.actions.getTemplates,
      data: { channelId },
    });
  };

  useEffect(() => {
    if (modalClass.type === 'classFromTemplate') {
      getTemplates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalClass.type]);

  const callbackSave = () => {
    callback();
  };

  useEffect(() => {
    if (!modalClass.open) {
      setTemplateId(null);
      setShowForm(false);
      setSelectedBenchmark(null);
      setCompareOptions({ open: false });
    }
  }, [modalClass.open]);

  const checkShowForm = () => {
    if (modalClass.type === 'classFromTemplate' && templateId) {
      setShowForm(true);
      return;
    }
    if (modalClass.type === 'customClass') {
      setShowForm(true);
      return;
    }
    setShowForm(false);
  };

  useEffect(() => {
    checkShowForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalClass.type, templateId]);

  const choseWorkoutHistory = (workoutId) => {
    setCompareOptions((prev) => ({ ...prev, open: true, workoutId }));
  };

  useEffect(() => {
    if (modalClass.type === 'classFromHistory' && modalClass.workoutId) {
      choseWorkoutHistory(modalClass.workoutId);
    }
  }, [modalClass.type, modalClass.workoutId]);

  const closeModal = () => {
    setCompareOptions({ open: false });
    setShowForm(false);
    close();
  };

  return (
    <Dialog
      style={{ marginTop: 100 }}
      fullScreen
      open={modalClass.open}
      onClose={closeModal}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar} color="secondary">
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            {t('channel.class')}
          </Typography>
          <IconButton edge="start" color="inherit" onClick={closeModal} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Scrollbars allowFullScreen>
        <Box p={3}>
          {!templateId && modalClass.type === 'classFromTemplate' && (
            <Texture>
              <Box p={5} py={10}>
                <Box display="flex" justifyContent="center">
                  <LoaderSm loading={loading} />
                </Box>
                <TemplatesTable
                  templates={templates}
                  setTemplate={(template) => {
                    setTemplateId(template.id);
                    setShowForm(true);
                  }}
                />
              </Box>
            </Texture>
          )}

          {showForm && (
            <Form
              callbackSave={callbackSave}
              channelId={channelId}
              mode="new"
              workoutId={modalClass.type === 'classFromTemplate' ? templateId : null}
              type={modalClass.type}
              compare={compareOptions.option === 'compare'}
              benchmark={selectedBenchmark}
            />
          )}
        </Box>
      </Scrollbars>
    </Dialog>
  );
};

ModalClass.propTypes = {};

ModalClass.defaultProps = {
  close: () => {},
};

export default ModalClass;
