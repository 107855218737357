import { Checkbox, FormControl, FormControlLabel } from '@material-ui/core';
import Box from 'core/ui/Box';
import Typography, { fontStyle } from 'core/ui/Typography';
import React from 'react';

const OptionScore = ({ label, value, onChange, disabled }) => (
  <Box ml={2} flexGrow="1">
    <FormControl component="fieldset">
      <FormControlLabel
        disabled={disabled}
        control={
          <Checkbox
            disabled={disabled}
            checked={value || false}
            onChange={() => onChange(!value)}
          />
        }
        label={
          <Typography
            style={fontStyle.label}
            className="roboto-regular"
            variant="body1"
            component="div"
          >
            {label}
          </Typography>
        }
      />
    </FormControl>
  </Box>
);

export default OptionScore;
