/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Grid from 'core/ui/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { workoutsSelectors } from 'modules/workouts';
import Calendar from 'core/ui/Calendar';
import { parseDate, dateAdd, date } from 'core/utils/formatters';
import Box from 'core/ui/Box';
import { appActions } from 'modules/app';
import { timeZoneName } from 'core/utils/formatters/date';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import notifications from 'modules/notifications';
import { Dialog, Slide, Typography, colors } from '@material-ui/core';
import useChannelStyles from 'core/useChannelStyles';
import WeekProgramCalendarView from 'components/Channel/Manage/Tabs/Schedule/WeekCalendarView/WeekProgramCalendarView';
import LoaderSm from 'core/ui/LoaderSm';
import { isMobile } from 'react-device-detect';
import { showAPIError } from 'core/utils/showAPIError';
import useSelectors from 'modules/map/useSelectors';
import { newChannelsModule } from 'modules/channels/new';
import Header from './Header';
import { WorkoutShow } from './Modals';
import useScheduleColors from './useScheduleColors';
import { renderEventContent } from './renderEventContent';
import ModalEventInfo from './Modals/ModalEventInfo';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const Schedules = ({
  channel,
  isCoach,
  embed,
  showQuickAdd,
  setRefreshCalendar,
  refreshCalendar,
  setCalendarInfo,
  hidePrograms,
  isCoachView,
  setModalEventForm,
}) => {
  const { t } = useTranslation();
  const { request } = useActions();
  const { buttonColor } = useChannelStyles();
  const dispatch = useDispatch();
  const [calendarType, setCalendarType] = useState('workouts');
  const [calendarRef, setCalendarRef] = useState(null);
  const [workoutShowModal, setWorkoutShowModal] = useState({ open: false, workoutId: null });
  const [modalEventDetails, setModalEventDetails] = useState({ open: false, eventId: null });
  const [calendarDates, setCalendarDates] = useState({ current: true });
  const loading = useSelector((state) => workoutsSelectors.getLoading(state));
  const loadingCalendarWorkouts = useSelectors(newChannelsModule, 'loadingCalendarWorkouts');

  const [events, setEvents] = useState([]);

  const { calendarBgColor, fontColor, borderColor } = useScheduleColors({
    buttonColor,
    isCoachView,
  });

  const formatWorkoutsEvents = (data) =>
    data.map((upcomingWorkout) => {
      let name = !isCoachView
        ? upcomingWorkout.name
        : upcomingWorkout?.mainCoach?.name || 'NO COACH';
      if (upcomingWorkout?.canceled) {
        name += ` ${t('workout.canceled')}`;
      }

      const start = parseDate(
        upcomingWorkout.scheduledAt,
        null,
        channel?.useUserTimezone ? null : upcomingWorkout?.timezone,
      ).format('YYYY-MM-DD HH:mm:ss');

      return {
        id: upcomingWorkout.id,
        backgroundColor: calendarBgColor(upcomingWorkout),
        borderColor: borderColor(upcomingWorkout),
        display: 'block',
        title: name,
        start,
        end: upcomingWorkout.online
          ? parseDate(
              dateAdd(upcomingWorkout.scheduledAt, upcomingWorkout.durationMinutes, 'minutes'),
              null,
              channel?.useUserTimezone ? null : upcomingWorkout?.timezone,
            ).format('YYYY-MM-DD HH:mm:ss')
          : parseDate(
              upcomingWorkout.endAt,
              null,
              channel?.useUserTimezone ? null : upcomingWorkout?.timezone,
            ).format('YYYY-MM-DD HH:mm:ss'),
        textColor: fontColor(upcomingWorkout),
        extendedProps: {
          method: upcomingWorkout?.method,
          // Live
          maxUsers: upcomingWorkout?.maxUsers,
          countOnlineUsers: upcomingWorkout?.countOnlineUsers,
          // In Person
          inPersonMaxUsers: upcomingWorkout?.inPersonMaxUsers,
          countInPersonUsers: upcomingWorkout?.countInPersonUsers,
          // Waitlist
          waitlistCount: upcomingWorkout?.waitlistCount,
        },
      };
    }) || [];

  const getWorkouts = () => {
    if (channel && channel.id && calendarDates.start) {
      request({
        action: Modules.newChannels.actions.getChannelCalendarWorkoutsStart,
        data: {
          channelId: channel?.id,
          startAt: calendarDates.start,
          endAt:
            calendarDates?.currentView === 'timeGridDay' ? calendarDates?.start : calendarDates.end,
          timezone: timeZoneName(),
        },
        options: {
          onSuccess: (data) => {
            setEvents(formatWorkoutsEvents(data));
          },
        },
      });
    }
  };

  const formatChannelsEvents = (data) =>
    data.map((event) => {
      const start = parseDate(
        event.startAt,
        null,
        channel?.useUserTimezone ? null : event?.timezone,
      ).format('YYYY-MM-DD HH:mm:ss');

      return {
        id: event.id,
        backgroundColor: colors.green[300],
        borderColor: colors.green[300],
        display: 'block',
        title: event?.name,
        start,
        end: parseDate(event.endAt, null, channel?.useUserTimezone ? null : event?.timezone).format(
          'YYYY-MM-DD HH:mm:ss',
        ),
        textColor: fontColor(event),
        extendedProps: {
          type: 'events',
          ...event,
        },
      };
    }) || [];

  const getChannelsEvents = () => {
    if (channel && channel.id && calendarDates.start) {
      const filterParams = {
        q: {
          start_at_gteq: calendarDates?.start,
          start_at_lteq: calendarDates?.end,
        },
      };

      request({
        action: Modules.channelsEvents.actions.getEvents,
        data: {
          channelId: channel?.id,
          ...filterParams,
        },
        options: {
          onSuccess: (data) => setEvents(formatChannelsEvents(data)),
        },
      });
    }
  };

  const confirmCancelClass = () => {
    request({
      action: Modules.newWorkouts.actions.cancelClass,
      data: { id: workoutShowModal.workoutId },
      options: {
        onSuccess: () => {
          dispatch(notifications.success(t('workout.message.cancel')));
          setWorkoutShowModal({ open: false, workoutId: null });
          getWorkouts();
        },
        onError: showAPIError,
      },
    });
  };

  useEffect(() => {
    if (setCalendarInfo) {
      setCalendarInfo(calendarDates);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarDates]);

  const getCalendarData = () => {
    if (calendarType === 'events') {
      getChannelsEvents();
    } else {
      getWorkouts();
    }
  };

  useEffect(() => {
    if (calendarDates.start) {
      getCalendarData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarDates?.start, calendarType, isCoachView]);

  useEffect(() => {
    if (refreshCalendar) {
      getCalendarData();
      setRefreshCalendar(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshCalendar]);

  const handleEventClick = (info) => {
    const { event } = info;

    if (info.el.focus) {
      info.el.focus();
    }
    if (event?.extendedProps?.type === 'events') {
      setModalEventDetails({ open: true, eventId: event.id });
    } else {
      setWorkoutShowModal({ open: true, workoutId: Number(event.id) });
    }
  };

  useEffect(() => {
    dispatch(appActions.setLoading(loadingCalendarWorkouts));
  }, [loadingCalendarWorkouts, dispatch]);

  if (!channel.planValid) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" p={10}>
        <Typography color="primary">{t('channel.active.yourPlan')}</Typography>
      </Box>
    );
  }

  const calendarProps = isMobile ? { height: 400 } : {};

  return (
    <>
      <Box>
        <Header
          setCalendarDates={setCalendarDates}
          calendarDates={calendarDates}
          isCoach={isCoach}
          calendarRef={calendarRef}
          showQuickAdd={showQuickAdd}
          buttonColor={buttonColor}
          hidePrograms={hidePrograms}
          setCalendarType={setCalendarType}
          calendarType={calendarType}
        />
      </Box>
      {calendarDates?.currentView === 'timeGridProgram' && (
        <Dialog
          style={{ marginTop: 70 }}
          fullScreen
          open
          onClose={() =>
            setCalendarDates((prev) => ({
              ...prev,
              currentView: 'timeGridWeek',
            }))
          }
          TransitionComponent={Transition}
        >
          <Box display="flex" justifyContent="center">
            <LoaderSm loading={loading} />
          </Box>
          <WeekProgramCalendarView
            channel={channel}
            calendarInfo={calendarDates}
            buttonColor={buttonColor}
            close={() =>
              setCalendarDates((prev) => ({
                ...prev,
                currentView: 'timeGridWeek',
              }))
            }
          />
        </Dialog>
      )}
      <Grid item xs={12} sm={12} md={12} lg={12} mb={5}>
        <Calendar
          calendarRef={setCalendarRef}
          eventClick={handleEventClick}
          events={events}
          embed={embed}
          initialView={!isMobile ? 'timeGridWeek' : 'timeGridDay'}
          dayHeaderFormat={(args) => {
            if (calendarRef?.current?.getApi()?.view?.type === 'dayGridMonth') {
              return date(args.date, channel?.useUsDateFormat ? 'ddd' : 'ddd');
            }
            return date(args.date, channel?.useUsDateFormat ? 'ddd M/DD' : 'ddd DD/M');
          }}
          datesSet={(dateInfo) => {
            let current = false;
            if (setCalendarDates) {
              const currentView = calendarRef
                ? calendarRef?.current?.getApi()?.view?.type
                : 'timeGridWeek';
              const currentDate = parseDate(date(), 'MM/DD/YYYY');

              let calendarStart = dateInfo.start;
              if (currentView === 'dayGridMonth') {
                calendarStart = dateAdd(calendarStart, 15, 'days');
              }

              const startAt = parseDate(calendarStart, 'DD/MM/YYYY');

              if (currentView === 'timeGridDay') {
                if (currentDate <= startAt) {
                  current = true;
                }
              }
              if (currentView === 'timeGridWeek') {
                if (currentDate.week() <= startAt.week()) {
                  current = true;
                }
              }
              if (currentView === 'dayGridMonth') {
                const currentMonth = Number(currentDate.format('YYYYMM'));
                const currentStartMonth = Number(startAt.format('YYYYMM'));
                if (currentMonth <= currentStartMonth) {
                  current = true;
                }
              }
              setCalendarDates((prev) => ({
                ...prev,
                start: dateInfo.start,
                end: dateInfo.end,
                current,
              }));
            }
          }}
          eventContent={renderEventContent}
          {...calendarProps}
        />
      </Grid>

      {modalEventDetails?.open && (
        <ModalEventInfo
          channel={channel}
          open={modalEventDetails?.open}
          eventId={modalEventDetails?.eventId}
          close={() => setModalEventDetails({})}
          setModalEventForm={setModalEventForm}
        />
      )}

      <WorkoutShow
        open={workoutShowModal.open}
        onClose={() => {
          setWorkoutShowModal({ open: false, workoutId: null });
          getWorkouts();
        }}
        workoutId={workoutShowModal.workoutId}
        embed={embed}
        loading={loading}
        confirmCancelClass={confirmCancelClass}
        buttonColor={buttonColor}
      />
    </>
  );
};

export default Schedules;
