import api from 'core/api';
import { call, put } from 'redux-saga/effects';

export const userHistoryModule = 'userHistory';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const actions = {
  getWorkoutHistories: {
    module: userHistoryModule,
    name: 'getWorkoutHistories',
    api: (data) => api.get(`/workouts_users/${data.userId}/history`, { params: data }),
    *sagas(Creators, { params, options }) {
      try {
        const resp = yield call(actions.getWorkoutHistories.api, params);
        if (!params.exportData) {
          yield put(Creators.getWorkoutHistoriesSuccess(resp.data, resp.pagination));
        }
        if (options.onSuccess) {
          options.onSuccess(resp.data, resp.pagination);
        }
      } catch (error) {
        if (options.onError) {
          options.onError(error);
        }
        yield put(Creators.getWorkoutHistoriesError());
      }
    },
    params: {
      start: ['params'],
      error: [''],
      success: ['data', 'pagination'],
    },
    state: loadingStates,
  },
  exportWorkoutsHistory: {
    module: userHistoryModule,
    name: 'exportWorkoutsHistory',
    api: (data) => api.get(`/workouts_users/${data.userId}/history`, { params: data }),
    params: {
      start: ['params'],
      error: [''],
      success: [],
    },
    state: loadingStates,
  },
};

export default {
  actions,
  state: {
    loading: false,
    data: [],
    pagination: null,
  },
};
