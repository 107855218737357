import React from 'react';
import Box from 'core/ui/Box';
import Typography from 'core/ui/Typography';
import Card from 'core/ui/Card';
import { primary } from 'core/ui/Colors';

const PartnerDetail = ({ partner, modal }) => (
  <Box>
    {modal ? (
      <Box display="flex" flexDirection="column" alignItems="center" px={3} pb={3}>
        <Box>
          <img src={partner?.image?.image?.url} alt="Partner" style={{ width: 80 }} />
        </Box>
        <Box my={3}>
          <Typography>{partner.companyName}</Typography>
        </Box>
        <Box>
          <Typography>{partner.details}</Typography>
        </Box>
      </Box>
    ) : (
      <Card btopcolor={primary} btopwidth={2} hidedivider="true" title={partner.companyName}>
        <Box display="flex" px={3} pb={3}>
          <Box>
            <img src={partner?.image?.image?.url} alt="Partner" style={{ width: 80 }} />
          </Box>
          <Box pl={3}>
            <Typography>{partner.companyAddress}</Typography>
            <Typography>{partner.companyEmail}</Typography>
            <Typography>{partner.contactName}</Typography>
            <Typography>{partner.contactEmail}</Typography>
            <Typography>{partner.contactPhone}</Typography>
            <Typography>{partner.shippingEmail}</Typography>
          </Box>
        </Box>
      </Card>
    )}
  </Box>
);

PartnerDetail.defaultProps = {
  modal: false,
};

export default PartnerDetail;
