import React from 'react';
import { Table, TableCell, TableRow, TableBody, IconButton } from '@material-ui/core';
import Box from 'core/ui/Box';
import { ButtonPrimary } from 'core/ui/Button';
import { Add } from '@material-ui/icons';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Typography from 'core/ui/Typography';
import Paper from 'core/ui/Paper';
import TextFieldRounded from 'core/ui/TextFieldRounded';

const Categories = ({
  label,
  categories,
  setCategories,
  helpText,
  vendors,
  currency,
  component,
}) => {
  const addOption = () => {
    setCategories([...categories, { required: true }]);
  };

  const change = (prop, value, item) => {
    setCategories(
      categories.map((x) => {
        const newItem = { ...x };
        if (x === item) {
          newItem[prop] = value;
        }

        return newItem;
      }),
    );
  };

  const removeNew = (row) => {
    setCategories(categories.filter((x) => x !== row));
  };

  const showCategories = categories?.filter((x) => !x?._destroy) || [];

  return (
    <Box pb={3} pt={2}>
      <Box display="flex" alignItems="center">
        <Box flexGrow="1">
          <Typography variant="h5" mb={3} color="primary">
            {label}
          </Typography>
        </Box>
        <Box p={1}>
          <ButtonPrimary onClick={() => addOption()}>
            <Add />
          </ButtonPrimary>
        </Box>
      </Box>

      {component}

      {showCategories?.length > 0 && (
        <Paper p={3}>
          <Table size="small">
            <TableBody>
              {showCategories
                ?.filter((x) => !x?._destroy)
                .map((row, index) => (
                  <TableRow key={index.toString()}>
                    <TableCell>
                      <TextFieldRounded
                        value={row.name || ''}
                        onChange={(v) => change('name', v, row)}
                        placeholder="Name"
                        bordered
                      />
                    </TableCell>
                    {vendors && (
                      <TableCell>
                        <TextFieldRounded
                          value={row.price || ''}
                          onChange={(v) => change('price', v, row)}
                          placeholder="Cost"
                          type="number"
                          pattern="[0-9]*"
                          currency
                          bordered
                          currencySymbol={currency?.symbol}
                        />
                      </TableCell>
                    )}
                    <TableCell style={{ width: 20 }}>
                      <IconButton
                        onClick={() => (row?.id ? change('_destroy', true, row) : removeNew(row))}
                      >
                        <HighlightOffIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Paper>
      )}
      {showCategories?.length === 0 && (
        <Typography align="center" m={3}>
          {helpText}
        </Typography>
      )}
    </Box>
  );
};

Categories.defaultProps = {};

Categories.propTypes = {};

export default Categories;
