import React, { memo, useContext } from 'react';
import { colors, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

import { WorkflowContext } from 'contexts';
import Box from 'core/ui/Box';
import MIcon from 'core/ui/MIcon';
import Typography, { fontStyle } from 'core/ui/Typography';
import { ButtonPrimary } from 'core/ui/Button';
import { useTranslation } from 'react-i18next';
import { ActionItem, ActionItemLeft } from './ActionItem';

export const WorkflowTypeCard = memo(({ workflowType, setTab, allowEdit }) => {
  const workFlowContext = useContext(WorkflowContext);
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box style={{ position: 'relative' }}>
      {workflowType.membersCount > 0 && (
        <Box display="flex" justifyContent="center">
          <Box
            className={clsx(classes.cardMembers, 'hover')}
            display="flex"
            justifyContent="center"
            alignItems="center"
            onClick={() => {
              workFlowContext.setExecutionFilters((prev) => ({
                ...prev,
                workflowsTypeId: workflowType.id,
                status: 'waiting',
              }));
              setTab(1);
            }}
          >
            <MIcon icon="people" size={16} />

            <Typography className="roboto-medium" style={fontStyle.label}>
              {workflowType.membersCount}
            </Typography>
          </Box>
        </Box>
      )}

      <Box style={{ position: 'relative' }}>
        <ActionItem
          bgIconColor={
            workflowType.automationType?.kind === 'trigger' ? colors.green[100] : colors.blue[100]
          }
          iconColor={
            workflowType.automationType?.kind === 'trigger' ? colors.green[700] : colors.blue[700]
          }
          icon={workflowType?.automationType?.icon}
          label={workflowType?.name}
          onClick={() => workFlowContext.editWorkflowType(workflowType)}
          active={workflowType === workFlowContext.currentType?.workflowType}
          helpText={typeHelpText(workflowType)}
        />

        {allowEdit && workflowType.id && (
          <ActionItemLeft
            item={workflowType}
            onExecutionsClick={() => {
              workFlowContext.setExecutionFilters((prev) => ({
                ...prev,
                workflowsTypeId: workflowType.id,
                status: null,
                date: null,
              }));
              setTab(1);
            }}
          />
        )}
      </Box>

      {workflowType?.automationType?.function === 'IncomingFlow' && (
        <div className="tw-mt-2 tw-flex tw-items-center tw-justify-center">
          <ButtonPrimary
            disabled={!workFlowContext?.workflow?.id}
            onClick={() => workFlowContext?.workflow?.id && workFlowContext?.setModalAddUsers(true)}
          >
            <div className="tw-flex tw-flex-col">
              <div className="tw-flex tw-flex-row tw-items-center tw-gap-2 tw-text-sm">
                <MIcon icon="group_add" size={16} color="white" />
                {t('button.addUsers')}
              </div>
              {!workFlowContext?.workflow?.id && (
                <div className="tw-text-[9px] tw-text-gray-300">
                  {t('workflow.addusers.message')}
                </div>
              )}
            </div>
          </ButtonPrimary>
        </div>
      )}
    </Box>
  );
});

const typeHelpText = (workflowType) => {
  const texts = [];

  if (workflowType.automationType?.description) {
    texts.push(workflowType.automationType?.description);
  }

  if (workflowType.automationType?.frequencyDescription) {
    texts.push(workflowType.automationType?.frequencyDescription);
  }

  return texts.join(', ');
};

const useStyles = makeStyles({
  cardMembers: {
    backgroundColor: colors.grey[300],
    width: 44,
    height: 24,
    zIndex: 10,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    gap: 5,
  },
});
