import React from 'react';
import { TableCell, withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { secondary, secondaryLight } from 'core/ui/Colors';
import { ButtonPrimary } from 'core/ui/Button';
import Box from 'core/ui/Box';
import Paper from 'core/ui/Paper';
import LoaderSm from 'core/ui/LoaderSm';
import Typography from 'core/ui/Typography';
import ViewCalendarsAvailable from 'components/Calendars/Availability/View';
import Divider from 'core/ui/Divider';
import ScheduleOption from './ScheduleOption';
import ModalConfirmSave from './ModalConfirmSave';

export const Cell = withStyles(() => ({
  head: {
    backgroundColor: secondaryLight,
    color: secondary,
    fontSize: 12,
  },
  body: {
    fontSize: 11,
  },
}))(TableCell);

export default function CoachesSchedules({ channel, manage, coach }) {
  const { t } = useTranslation();

  return (
    <div>
      <ViewCalendarsAvailable channelId={channel?.id} userId={coach?.id} />
      <Divider m={3} />
      <Paper my={3} p={3}>
        <Box
          my={3}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          {manage?.scheduleOptions.map((option, index) => (
            <ScheduleOption channel={channel} option={option} manage={manage} index={index} />
          ))}

          {manage?.scheduleOptions?.length === 0 && (
            <Box
              p={3}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Typography align="center" m={3} variant="h6">
                {t('channel.coach.add.schedules')}
              </Typography>
              <ButtonPrimary onClick={() => manage.setScheduleOptions([{}])}>
                {t('button.addSchedule')}
              </ButtonPrimary>
            </Box>
          )}
        </Box>

        <ModalConfirmSave
          modalConfirmSave={manage.modalConfirmSave}
          setModalConfirmSave={manage.setModalConfirmSave}
          channel={channel}
        />

        <Box my={3} display="flex" justifyContent="flex-end">
          <ButtonPrimary disabled={manage.loadingSchedules} onClick={() => manage.saveSchedules()}>
            <LoaderSm center loading={manage.loadingSchedules} />
            {t('button.saveSchedule')}
          </ButtonPrimary>
        </Box>
      </Paper>
    </div>
  );
}

CoachesSchedules.defaultProps = {
  membershipPlans: [],
  setModalCoupons: null,
  editPlan: null,
  changeActive: null,
  changeVisible: null,
};
