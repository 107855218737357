/* eslint-disable react/prop-types */
import React from 'react';

import Paper from 'core/ui/Paper';
import { useTranslation } from 'react-i18next';
import { date } from 'core/utils/formatters';
import Typography, { fontStyle } from 'core/ui/Typography';
import SFDataTable from 'core/ui/DataTable';
import { Chip } from '@material-ui/core';

import { ButtonPrimary } from 'core/ui/Button';
import Box from 'core/ui/Box';
import { primary, secondary, successColor } from 'core/ui/Colors';
import PaymentTypes from 'components/Channel/Manage/Tabs/Reports/Payments/PaymentTypes';

export default function ResultPayouts({ transfers, setPayoutTransfer }) {
  const { t } = useTranslation();
  const columns = [
    {
      name: 'Channel',
      selector: 'channelName',
      sortable: true,
      cell: (row) => <Typography style={fontStyle.small}>{row.channelName}</Typography>,
    },
    {
      name: 'Type',
      selector: 'kind',
      sortable: true,
      cell: (row) => <PaymentTypes payment={row} />,
    },
    {
      name: 'Date',
      selector: 'confirmedAt',
      cell: (row) => <Typography style={fontStyle.small}>{row.createdAt}</Typography>,
      sortable: true,
      center: true,
    },
    {
      name: 'Status',
      selector: 'status',
      sortable: true,
      center: true,
      cell: (row) => (
        <Box display="flex">
          <Chip
            color="primary"
            style={{ backgroundColor: row.paid ? successColor : secondary }}
            size="small"
            label={
              <Typography style={fontStyle.small}>{!row.paid ? 'PENDING' : 'PAID'}</Typography>
            }
          />
          {row.paid && row.manual && (
            <Chip
              color="primary"
              style={{ backgroundColor: primary }}
              size="small"
              label={<Typography style={fontStyle.small}>MANUAL</Typography>}
            />
          )}
          {!row.paid && (
            <Box ml={3}>
              <ButtonPrimary style={{ padding: 0 }} onClick={() => setPayoutTransfer(row.id)}>
                Confirm
              </ButtonPrimary>
            </Box>
          )}
        </Box>
      ),
    },
    {
      name: 'Amount',
      selector: 'amount',
      sortable: true,
      right: true,
      cell: (row) => <Typography style={fontStyle.small}>{row.amount}</Typography>,
    },
  ];

  const getRows = () => {
    const rowsTransfers = transfers.map((transfer) => ({
      id: transfer.id,
      kind: transfer.kind,
      channelName: transfer.channel.name,
      manual: transfer.manual,
      paid: transfer.paid,
      summary: transfer.summary,
      createdAt: date(transfer.createdAt),
      amount: transfer.amount,
    }));

    return rowsTransfers;
  };

  const rows = getRows();

  const ExpanableComponent = ({ data }) => (
    <Typography component="h1" align="center" m={5}>
      {data.summary}
    </Typography>
  );

  return (
    <Paper style={{ width: '100%' }}>
      <SFDataTable
        title={t('channel.reports.payouts')}
        columns={columns}
        data={rows}
        pagination
        showExport
        expandableRows
        expandableRowsComponent={<ExpanableComponent />}
      />
    </Paper>
  );
}
