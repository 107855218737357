import api from 'core/api';

export const partnersProgramsModule = 'partnersPrograms';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const loadingWorkoutsStates = {
  start: { loadingWorkouts: true },
  error: { loadingWorkouts: false },
  success: { loadingWorkouts: false },
};

const actions = {
  create: {
    module: partnersProgramsModule,
    name: 'create',
    api: (data) => api.post(`/partners/${data?.partnerId}/partners_programs`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  update: {
    module: partnersProgramsModule,
    name: 'update',
    api: (data) => api.put(`/partners/${data?.partnerId}/partners_programs/${data?.id}`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  getWorkouts: {
    module: partnersProgramsModule,
    name: 'getWorkouts',
    api: (data) =>
      api.get(`/partners/${data?.partnerId}/partners_programs/${data?.id}/workouts`, {
        params: data,
      }),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingWorkoutsStates,
    isTakeEvery: true,
  },
  cloneWeekWorkouts: {
    module: partnersProgramsModule,
    name: 'cloneWeekWorkouts',
    api: (data) =>
      api.post(
        `/partners/${data?.partnerId}/partners_programs/${data?.partnersProgramId}/clone_week_workouts`,
        data,
      ),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  getPartnersPrograms: {
    module: partnersProgramsModule,
    name: 'getPartnersPrograms',
    api: (params) => api.get(`/partners/${params?.partnerId}/partners_programs`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: ['data'],
    },
    state: loadingStates,
  },
  getAvailablePartnersPrograms: {
    module: partnersProgramsModule,
    name: 'getAvailablePartnersPrograms',
    api: (params) => api.get(`/partners/partners_programs/available`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: ['available'],
    },
    state: loadingStates,
  },
};

export default {
  actions,
  state: {
    data: [],
    available: [],
    error: null,
    loading: false,
    loadingWorkouts: false,
  },
};
