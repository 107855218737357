import { createContext } from 'react';

export const AppContext = createContext();
export const ReducersProvider = createContext();
export const PlanManageContext = createContext();
export const TextServiceContext = createContext();
export const DashboardContext = createContext();
export const StoreContext = createContext();
export const ProgramViewContext = createContext();
export const CompetitionRegistrationContext = createContext();
export const ChatContext = createContext();
export const WorkflowContext = createContext();
export const StagesContext = createContext();
export const CalendarsContext = createContext();
export const PartersProgramsWorkoutsContext = createContext();
