import React, { useEffect, useState } from 'react';
import Box from 'core/ui/Box';
import { date } from 'core/utils/formatters';
import { dateAdd, usDateFormat } from 'core/utils/formatters/date';
import Paper from 'core/ui/Paper';
import Button from 'core/ui/Button';
import { primary, secondary } from 'core/ui/Colors';
import Typography from 'core/ui/Typography';
import MIcon from 'core/ui/MIcon';

import { DatePicker } from '@material-ui/pickers';
import { Badge, Dialog } from '@material-ui/core';
import { replaceAll } from 'core/utils/helpers';
import useActions from 'modules/map/useActions';
import useSelectors from 'modules/map/useSelectors';
import { usersWorkoutsModule } from 'modules/usersWorkouts';
import Modules from 'modules';

export default function ScheduleSwipe({ currentDate, setCurrentDate, onNext, onPrev }) {
  const { request } = useActions();
  const dots = useSelectors(usersWorkoutsModule, 'dots');
  const [openDate, setOpenDate] = useState(false);

  const getCalendarDots = (month) => {
    request({
      action: Modules.usersWorkouts.actions.getCalendarDots,
      data: { startAt: month, onlyUserClass: true },
    });
  };

  useEffect(() => {
    if (openDate && currentDate) {
      getCalendarDots(currentDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDate, openDate]);

  const nextDate = () =>
    date(dateAdd(currentDate, +1, 'days'), usDateFormat() ? 'MMM DD' : 'DD MMM');

  const prevDate = () =>
    date(dateAdd(currentDate, -1, 'days'), usDateFormat() ? 'MMM DD' : 'DD MMM');

  const ButtomSwipe = ({ right, label, onClick }) => {
    const swipeStyle = { backgroundColor: secondary, color: 'white' };
    if (right === true) {
      swipeStyle.borderTopRightRadius = 10;
      swipeStyle.borderBottomRightRadius = 10;
    } else {
      swipeStyle.borderTopLeftRadius = 10;
      swipeStyle.borderBottomLeftRadius = 10;
    }
    return (
      <Box onClick={onClick} p={3} style={swipeStyle} className="hover">
        <Typography>{label}</Typography>
      </Box>
    );
  };

  const hasDot = (dateParam) => {
    const newDate = date(dateParam, 'YYYY-MM-DD');
    const dayKey = replaceAll(newDate, '-', '/');
    if (dots && dots[dayKey]) {
      return true;
    }
    return false;
  };

  return (
    <Box>
      <Paper>
        <Dialog open={openDate} onClose={() => setOpenDate(false)}>
          <Box>
            <DatePicker
              autoOk
              onMonthChange={(params) => {
                getCalendarDots(date(params, 'YYYY-MM-DD'));
              }}
              // ToolbarComponent={(params) => <Box>{gymDate(params?.date)}</Box>}
              format="yyyy-MM-dd"
              variant="static"
              renderDay={(day, selectedDate, isInCurrentMonth, dayComponent) => {
                if (hasDot(day)) {
                  return (
                    <Badge
                      badgeContent={<MIcon icon="fiber_manual_record" color={primary} size={8} />}
                    >
                      {dayComponent}
                    </Badge>
                  );
                }
                return <Box className="day-inative">{dayComponent}</Box>;
              }}
              openTo="date"
              value={date(currentDate)}
              onChange={(params) => {
                if (hasDot(params)) {
                  setCurrentDate(date(params, 'YYYY-MM-DD'));
                  setOpenDate(false);
                }
              }}
            />
          </Box>
        </Dialog>
        <Box display="flex">
          <Box>
            <ButtomSwipe
              right={false}
              label={prevDate()}
              onClick={() => onPrev(dateAdd(currentDate, -1, 'days'))}
            />
          </Box>
          <Box
            className="hover"
            flexGrow={1}
            display="flex"
            justifyContent="center"
            alignItems="center"
            onClick={() => setOpenDate(true)}
          >
            <Typography mr={1} color="secondary" size="medium">
              {date(currentDate, 'dddd')}
            </Typography>
            <Button style={{ minWidth: 10, backgroundColor: secondary, borderRadius: 5 }}>
              <MIcon icon="date_range" color="white" size={16} />
            </Button>
            <Typography ml={1} color="secondary" size="medium">
              {date(currentDate, usDateFormat() ? 'MMM DD' : 'DD MMM')}
            </Typography>
          </Box>
          <Box>
            <ButtomSwipe
              right
              label={nextDate()}
              onClick={() => onNext(dateAdd(currentDate, 1, 'days'))}
            />
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}

ScheduleSwipe.propTypes = {};

ScheduleSwipe.defaultProps = {};
