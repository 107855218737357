import api from 'core/api';

export const usersContractsModule = 'usersContracts';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const actions = {
  getChannelUserContracts: {
    module: usersContractsModule,
    name: 'getChannelUserContracts',
    api: (params) => api.get(`/users/${params?.userId}/users_contracts/by_channel`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: ['channelContracts'],
    },
    state: loadingStates,
  },
  getOwnerableUsers: {
    module: usersContractsModule,
    name: 'getOwnerableUsers',
    api: (params) => api.get(`/users_contracts/users`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  getContractByKey: {
    module: usersContractsModule,
    name: 'getContractByKey',
    api: (params) => api.get(`/users/${params?.userId}/users_contracts/by_key`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: ['contract'],
    },
    state: loadingStates,
  },
  acceptUsersContract: {
    module: usersContractsModule,
    name: 'acceptUsersContract',
    api: (params) => api.put(`/users/${params?.userId}/users_contracts/accept`, params),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  acceptByKey: {
    module: usersContractsModule,
    name: 'acceptByKey',
    api: (params) =>
      api.post(`/users/${params?.userId}/users_contracts/accept_by_key/${params?.key}`, params),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  checkUsersContract: {
    module: usersContractsModule,
    name: 'checkUsersContract',
    api: (params) => api.get(`/users/${params?.userId}/users_contracts`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
    isTakeEvery: true,
  },
  resendContractEmail: {
    module: usersContractsModule,
    name: 'resendContractEmail',
    api: (params) =>
      api.get(`/users/${params?.userId}/users_contracts/resend_contract_email`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  sendWaiverToUser: {
    module: usersContractsModule,
    name: 'sendWaiverToUser',
    api: (params) =>
      api.get(`/users/${params?.userId}/users_contracts/send_waiver_to_user`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
};

export default {
  actions,
  state: {
    loading: false,
    channelContracts: [],
    contract: null,
  },
};
