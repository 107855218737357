import React, { useEffect, useState } from 'react';
import { ButtonPrimary } from 'core/ui/Button';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import Typography from 'core/ui/Typography';

import {
  TableContainer,
  TableHead,
  Table,
  TableCell,
  TableRow,
  TableBody,
  Paper,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import LoaderSm from 'core/ui/LoaderSm';
import useSelectors from 'modules/map/useSelectors';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import Toggle from 'core/ui/Toggle';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import { channelsTagsModule } from 'modules/channelsTags';
import ItemTag from './ItemTag';
import ModalForm from './Form/Modal';

const ChannelTags = ({ channel }) => {
  const { t } = useTranslation();

  const [filter, setFilter] = useState({ text: '', active: null });
  const loading = useSelectors(channelsTagsModule, 'loading');
  const data = useSelectors(channelsTagsModule, 'data');
  const { request } = useActions();

  const [modal, setModal] = useState({
    open: false,
  });

  const getData = () => {
    request({
      action: Modules.channelsTags.actions.getTags,
      data: { channelId: channel.id, ...filter },
    });
  };

  useEffect(() => {
    if (channel && channel.id) {
      getData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request, channel, filter]);

  const openModal = () => {
    setModal((prev) => ({ ...prev, mode: 'new', open: true }));
  };

  const onEditClick = (item) => {
    setModal((prev) => ({ ...prev, item, mode: 'edit', open: true }));
  };

  return (
    <Box p={5}>
      {modal.open && (
        <ModalForm
          open={modal.open}
          tagId={modal.item?.id}
          mode={modal.mode}
          channel={channel}
          close={() => setModal(() => ({ open: false }))}
          callback={() => {
            setModal(() => ({ open: false }));
            getData();
          }}
        />
      )}

      <Box display="flex" alignItems="center">
        <Box>
          <Toggle
            options={[
              {
                label: 'All',
                value: null,
                width: 160,
              },
              {
                label: t(`label.active`),
                value: 1,
                width: 160,
              },
              {
                label: t(`label.inactive`),
                value: 0,
                width: 160,
              },
            ]}
            value={filter?.active}
            color="white"
            onChange={(value) => setFilter((prev) => ({ ...prev, active: value }))}
            fontSize="large"
          />
        </Box>
        <Box flexGrow={1} mx={3}>
          <TextFieldRounded
            style={{ height: 32 }}
            placeholder={t('search.tags')}
            value={filter?.text}
            onChange={(v) => setFilter((prev) => ({ ...prev, text: v }))}
          />
        </Box>
        <LoaderSm loading={loading} />
        <Box>
          <ButtonPrimary onClick={() => openModal()}>
            <Box display="flex" alignItems="flex-end">
              <Add size="small" />
              <Box alignItems="center">
                <Typography component="span" mr={1}>
                  {t('button.addTag')}
                </Typography>
              </Box>
            </Box>
          </ButtonPrimary>
        </Box>
      </Box>
      <Box mt={2}>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>{t('label.name')}</TableCell>
                <TableCell align="center">{t('label.active')}</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data.map((row) => (
                  <ItemTag onEditClick={onEditClick} key={row.name} item={row} channel={channel} />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default ChannelTags;
