import React, { useEffect } from 'react';
import { hexToRgb, makeStyles } from '@material-ui/core/styles';
import Box from 'core/ui/Box';
import useWindowSize from 'core/utils/hook/useWindowSize';

const lightBg = '233,233,233';

export default ({ imageUrl, hexBgColor, heightBanner, heightHeader, children, ...props }) => {
  const bgColor = !hexBgColor ? lightBg : hexToRgb(hexBgColor).replace('rgb(', '').replace(')', '');

  const useStyles = makeStyles(() => ({
    background: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,

      background: ` linear-gradient(to bottom, rgba(${bgColor},0) 5%, rgba(${bgColor},0.3) 25%, rgba(${bgColor},0.5) 60%, rgba(${bgColor},0.7) 80%,  rgba(${bgColor},0.8) 90%, rgba(${bgColor},1) 100%)`,
    },
    backgroundWithoutImg: {
      minHeight: `calc(100vh)`,
      flex: 1,
      flexGrow: 1,
      backgroundColor: bgColor,
      backgroundSize: '100%',
    },
    bg: {
      minHeight: `calc(100vh)`,
      flex: 1,
      flexGrow: 1,
      backgroundColor: `rgb(${bgColor})`,
      // backgroundColor: `red`,
      backgroundSize: '100%',
      zIndex: 999,
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    document.body.style.backgroundColor = `rgb(${bgColor})`;
    return () => {
      document.body.style.backgroundColor = null;
    };
  }, [bgColor]);

  const { width } = useWindowSize();

  const gradientStyle = {
    width,
    position: 'absolute',
    bottom: 0,
    height: 120,
    marginTop: -30,
    background: `linear-gradient(to bottom , rgba(${bgColor}, 0.05) 10%, rgba(${bgColor}, 0.4) 20%, rgba(${bgColor}, 0.8) 40%, rgba(${bgColor}, 0.98) 60%, rgba(${bgColor}, 1) 70%, rgb(${bgColor}) 100%)`,
  };

  return (
    <Box className={`${classes.bg}`} {...props}>
      <div style={{ position: 'relative', zIndex: 10, flex: 1 }}>{children}</div>

      <Box style={{ position: 'absolute', top: heightHeader, width: '100%' }}>
        <img src={imageUrl} style={{ width: '100%', flex: 1 }} alt="" />
        <Box style={gradientStyle} />
        <Box className={classes.background} />
      </Box>
    </Box>
  );
};
