import React, { useContext, useState } from 'react';
import Box from 'core/ui/Box';
import { ButtonPrimary } from 'core/ui/Button';
import { Add } from '@material-ui/icons';
import Typography from 'core/ui/Typography';
import MIcon from 'core/ui/MIcon';
import { primary, secondary } from 'core/ui/Colors';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { ProgramViewContext } from 'contexts';
import { date, dateAdd } from 'core/utils/formatters';
import SectionView from './SectionView';
import ModalClassTypeWorkouts from './ModalClassTypeWorkouts';

const WeekDayView = ({ index, sectionOpened }) => {
  const {
    channel,
    weekView,
    buttonColor,
    calendarInfo,
    removeSection,
    cloneSection,
    moveSection,
    setModalSection,
    setModalCloneDay,
  } = useContext(ProgramViewContext);
  const [modalClasses, setModalClasses] = useState({ open: false });
  const day = date(dateAdd(calendarInfo?.start, index, 'days'), 'YYYY/MM/DD');
  const dayData = weekView ? weekView[day] : {};

  const classesTypes = Object.keys(dayData?.classesTypes || {});

  return (
    <>
      {classesTypes?.map((cType, idx) => {
        const classTypeData = dayData?.classesTypes[cType];

        return (
          <Box key={idx}>
            <Droppable droppableId={`drop-${cType}-${day}`}>
              {(provided) => (
                <div {...provided.droppableProps} style={{ minHeight: 10 }} ref={provided.innerRef}>
                  {Object.keys(classTypeData?.sections || {}).map(
                    (sectionKeySlug, indexSection) => {
                      const sectionData = classTypeData?.sections[sectionKeySlug];

                      return (
                        <Draggable
                          key={`drag-${cType}-${day}-${sectionKeySlug}`}
                          draggableId={`drag-${cType}-${day}-${sectionKeySlug}`}
                          index={indexSection}
                        >
                          {(dragProv) => (
                            <div
                              ref={dragProv.innerRef}
                              {...dragProv.draggableProps}
                              {...dragProv.dragHandleProps}
                            >
                              <SectionView
                                channel={channel}
                                setModalSection={setModalSection}
                                buttonColor={buttonColor}
                                workout={{}}
                                removeSection={removeSection}
                                cloneSection={cloneSection}
                                setModalCloneDay={setModalCloneDay}
                                moveSection={moveSection}
                                calendarInfo={calendarInfo}
                                section={sectionData}
                                workouts={classTypeData?.workouts}
                                sectionOpened={sectionOpened[index]}
                              />
                            </div>
                          )}
                        </Draggable>
                      );
                    },
                  )}
                </div>
              )}
            </Droppable>

            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              p={3}
            >
              {classTypeData?.type && classTypeData?.type?.name && (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  className="hover"
                  onClick={() => setModalClasses({ open: true, workouts: classTypeData?.workouts })}
                >
                  <MIcon icon="fitness_center" size={14} />
                  <Typography style={{ fontSize: 14, color: secondary }}>
                    &nbsp;{classTypeData?.type?.name}&nbsp;
                  </Typography>

                  <MIcon icon="timer" size={14} color={primary} />
                </Box>
              )}

              <ButtonPrimary
                onClick={() => setModalSection({ open: true, workouts: classTypeData?.workouts })}
              >
                <Add style={{ color: 'white' }} />
              </ButtonPrimary>
            </Box>
          </Box>
        );
      })}

      <ModalClassTypeWorkouts
        open={modalClasses?.open}
        workouts={modalClasses?.workouts}
        close={() => setModalClasses({ open: false })}
      />
    </>
  );
};

WeekDayView.propTypes = {};

WeekDayView.defaultProps = {
  close: () => {},
};

export default WeekDayView;
