/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import { DialogContent, Dialog, InputLabel } from '@material-ui/core';
import AWS from 'aws-sdk';
import uuid from 'react-uuid';
import LinearProgress from 'core/ui/LinearProgress';
import DialogTitle from 'core/ui/Dialog/DialogTitle';
import { ENV_APP } from 'core/env';
import { ButtonWhite } from 'core/ui/Button';
import Box from 'core/ui/Box';
import { Publish } from '@material-ui/icons';
import { B_ID } from 'core/utils/consts';
import { mKY } from 'core/utils/helpers';
import { primary } from 'core/ui/Colors';

const B_K = mKY();

const bucket = new AWS.S3({
  accessKeyId: B_ID,
  secretAccessKey: B_K,
  region: 'us-east-2',
});

const useStyles = makeStyles({
  root: {
    backgroundColor: '#f3f3f3',
  },
  btnClose: {
    position: 'absolute',
    right: 5,
    top: 0,
  },
});

export default function ModalS3Updload({ ownerableType, ownerableId, setS3Url, open, close }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [fileUpload, setFileUpload] = useState(null);
  const PATH_KEY = `uploads/${ENV_APP}/${ownerableType}/${ownerableId}/Documents`;

  const [progressUpload, setProgressUpload] = useState(0);

  const uploadfile = (fileName, file) => {
    const params = {
      Bucket: 'stream-fitness-app-uploads',
      Key: PATH_KEY + fileName,
      Body: file,
      ContentType: file.type,
    };

    return bucket.upload(params, (err, data) => {
      if (err) {
        console.log('There was an error uploading your file: ', err);
        return false;
      }

      setS3Url(data.Location);
      close();
      return true;
    });
  };

  const uploadSampleFile = (file) => {
    const uniqueFileName = `streamfitness-${ownerableType}-waivers-${uuid()}-${file.name}`;

    uploadfile(uniqueFileName, file).on('httpUploadProgress', (progress) => {
      const progressPercentage = Math.round((progress.loaded / progress.total) * 100);
      setProgressUpload(progressPercentage);
    });
  };

  const handleCapture = ({ target }) => {
    if (target.files.length) {
      for (let i = 0; i < target.files.length; i += 1) {
        const f = target.files[i];
        setFileUpload(f);
        uploadSampleFile(f);
      }
    }
  };

  const randomId = Math.floor(Math.random() * 101);

  return (
    <Dialog open={open} maxWidth="xs" fullWidth onClose={() => close()}>
      <DialogTitle background="true">{t('channel.modal.upload')}</DialogTitle>
      <DialogContent className={classes.root}>
        <Box display="flex" justifyContent="center">
          <ButtonWhite variant="contained" p={1}>
            <InputLabel style={{ color: primary }} htmlFor={`icon-button-file-${randomId}`}>
              <Box display="flex" alignItems="center">
                <Publish style={{ color: primary }} size="small" />
                <Typography ml={3} variant="subtitle2">
                  {(fileUpload && fileUpload.name) || t('button.selectPdfFile')}
                </Typography>
              </Box>
            </InputLabel>
          </ButtonWhite>
          <input
            color="primary"
            type="file"
            onChange={handleCapture}
            id={`icon-button-file-${randomId}`}
            style={{ display: 'none' }}
            accept=".pdf"
            multiple
          />
        </Box>

        {fileUpload && (
          <Typography align="center" ml={3} color="primary" variant="h4">
            {fileUpload.name}
          </Typography>
        )}
        <Box my={3}>
          <LinearProgress
            style={{ height: 15, width: '100%', borderRadius: 5 }}
            value={progressUpload}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
}
