/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import TextAreaFieldRounded from 'core/ui/TextAreaFieldRounded';
import Box from 'core/ui/Box';
import Grid from 'core/ui/Grid';
import Typography, { fontStyle } from 'core/ui/Typography';
import { makeStyles, IconButton } from '@material-ui/core';
import { ButtonPrimarySm } from 'core/ui/Button';
import TagsChosen from 'core/ui/TagsChosen';
import CountryChosen from 'core/ui/CountryChosen';
import StatesChosen from 'core/ui/StatesChosen';
import LanguagesChosen from 'core/ui/LanguagesChosen';
import Skeleton from '@material-ui/lab/Skeleton';
import { primary } from 'core/ui/Colors';
import Paper from 'core/ui/Paper';
import { APP_BASE_URL } from 'core/env';
import { FileCopy } from '@material-ui/icons';
import Radio from 'core/ui/Radio';
import CurrencyChosen from 'core/ui/CurrencyChosen';
import { getAllTimezones } from 'core/utils/formatters/date';
import SelectBoxSearch from 'core/ui/SelectBox/SelectBoxSearch';
import ContactFormGenerateCode from 'components/ContactForms/ContactFormGenerateCode';
import { ChannelContext } from './index';
import Locations from './Locations';
import RequiredVideos from './RequiredVideos';
import ChannelCustomization from './Customization';
import WorkoutsTypes from './WorkoutsTypes';
import ChannelsTaxes from './ChannelsTaxes';
import ChannelsScoresVariations from './ChannelsScoresVariations';

const useStyles = makeStyles({
  media: {
    paddingTop: '46.25%',
    backgroundColor: '#bbbdbf',
    maxHeight: 100,
    backgroundSize: '100% 100%',
  },
  boxSelImage: {
    position: 'absolute',
    marginTop: -30,
  },
  code: {
    backgroundColor: '#f1f1f1',
    padding: 5,
    margin: 2,
    borderRadius: 5,
  },
});

const copy = (v) => {
  navigator.clipboard.writeText(v);
};

const FormData = () => {
  const { t } = useTranslation();
  const formContext = useContext(ChannelContext);
  const classes = useStyles();

  const embedCalendar = `<iframe width="100%" height="800px" src="${APP_BASE_URL}/embed/schedule/${formContext.channel.slug}" frameBorder="0" noresize="noresize"></iframe>`;
  const embedMPlans = `<iframe width="100%" height="800px" src="${APP_BASE_URL}/embed/memberships/${formContext.channel.slug}" frameBorder="0" noresize="noresize"></iframe>`;
  const embedStore = `<iframe width="100%" height="800px" src="${APP_BASE_URL}/embed/store/${formContext.channel.slug}" frameBorder="0" noresize="noresize"></iframe>`;

  return (
    <Box mb={5}>
      <Box className={classes.content} pb={5}>
        <Grid container>
          <Grid item xs={12} sm={12} md={6} lg={6} px={5}>
            <Box mb={4} mt={5}>
              <TextFieldRounded
                name="name"
                error={formContext.errors.name}
                placeholder={t('channel.label.name')}
                label={t('channel.label.name').toUpperCase()}
                inputRef={formContext.register({ required: true })}
                required
              />
            </Box>

            <Box mb={4} mt={5}>
              <TextFieldRounded
                name="officialBusinessName"
                error={formContext.errors.officialBusinessName}
                placeholder={t('channel.label.officialBusinessName')}
                label={t('channel.label.officialBusinessName').toUpperCase()}
                inputRef={formContext.register({ required: false })}
              />
            </Box>

            <Box mb={4} mt={5}>
              <TextFieldRounded
                name="registeredAddress"
                error={formContext.errors.registeredAddress}
                placeholder={t('channel.label.registeredAddress')}
                label={t('channel.label.registeredAddress').toUpperCase()}
                inputRef={formContext.register({ required: false })}
              />
            </Box>

            <Box mb={4} mt={5}>
              <TextFieldRounded
                name="companyIdNumber"
                error={formContext.errors.companyIdNumber}
                placeholder={t('channel.label.companyIdNumber')}
                label={t('channel.label.companyIdNumber').toUpperCase()}
                inputRef={formContext.register({ required: false })}
              />
            </Box>

            <Box mb={4} mt={5}>
              <TextFieldRounded
                name="companyTaxNumber"
                error={formContext.errors.companyTaxNumber}
                placeholder={t('channel.label.companyTaxNumber')}
                label={t('channel.label.companyTaxNumber').toUpperCase()}
                inputRef={formContext.register({ required: false })}
              />
            </Box>

            <Box mb={4} mt={5}>
              <TextAreaFieldRounded
                name="description"
                error={formContext.errors.description}
                placeholder={t('description')}
                label={t('description').toUpperCase()}
                inputRef={formContext.register({ required: false })}
                required
              />
            </Box>
            <Box flexGrow="1" mt={4} mb={4}>
              <TextFieldRounded
                name="instagram"
                error={formContext.errors.instagram}
                placeholder={t('channel.placeholder.instagram')}
                label={t('channel.label.instagram').toUpperCase()}
                inputRef={formContext.register({})}
              />
            </Box>
            <Box flexGrow="1">
              <TextFieldRounded
                name="contactEmail"
                error={formContext.errors.contactEmail}
                placeholder={t('channel.label.contactEmail')}
                label={t('channel.label.contactEmail').toUpperCase()}
                inputRef={formContext.register({ required: true })}
                required
              />
            </Box>
            <Box flexGrow="1" mt={4}>
              <CountryChosen
                name="countryId"
                defaultValue={`${formContext.watch('countryId')}`}
                placeholder={t('channel.placeholder.country')}
                error={formContext.errors.countryId}
                label={t('channel.label.country').toUpperCase()}
                setValue={(v) => formContext.setValue('countryId', v)}
              />
            </Box>
            <Box flexGrow="1" mt={4}>
              <CurrencyChosen
                name="currencyId"
                defaultValue={`${formContext.watch('currencyId')}`}
                placeholder={t('channel.placeholder.currency')}
                error={formContext.errors.currencyId}
                label={t('channel.label.currency').toUpperCase()}
                setValue={(v) => formContext.setValue('currencyId', v)}
              />
            </Box>
            <Box flexGrow="1" mt={4}>
              <StatesChosen
                name="state"
                defaultValue={`${formContext.watch('state')}`}
                countryId={formContext.watch('countryId') || 0}
                placeholder={t('channel.placeholder.state')}
                error={formContext.errors.state}
                label={t('channel.label.state').toUpperCase()}
                setValue={(v) => formContext.setValue('state', v)}
              />
            </Box>
            <Box flexGrow="1" mt={4}>
              <TextFieldRounded
                name="city"
                error={formContext.errors.city}
                placeholder={t('channel.label.city')}
                label={t('channel.label.city').toUpperCase()}
                inputRef={formContext.register({ required: false })}
              />
            </Box>
            <Box flexGrow="1" mt={4}>
              <SelectBoxSearch
                label={t('workout.label.timezone').toUpperCase()}
                options={getAllTimezones()}
                setValue={(v) => formContext.setValue('timezone', v)}
                value={formContext.watch('timezone')}
                error={formContext.errors.timezone}
                required
              />
            </Box>

            <Box flexGrow="1" mt={4} px={1}>
              <Box>
                <Typography mb={2} style={fontStyle.label} variant="body1">
                  {t('channel.label.useUserTimezone').toUpperCase()}
                </Typography>
                <Box display="flex">
                  <Radio
                    label={
                      <Box>
                        <Typography style={fontStyle.label}>By timezone</Typography>
                        <Typography style={{ fontSize: 10 }}>User timezone</Typography>
                      </Box>
                    }
                    checked={!!formContext.watch('useUserTimezone')}
                    onClick={() => formContext.setValue('useUserTimezone', true)}
                  />
                  <Box ml={2}>
                    <Radio
                      label={
                        <Box>
                          <Typography style={fontStyle.label}>Standard time</Typography>
                          <Typography style={{ fontSize: 10 }}>Class timezone</Typography>
                        </Box>
                      }
                      checked={!formContext.watch('useUserTimezone')}
                      onClick={() => {
                        formContext.setValue('useUserTimezone', false);
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box flexGrow="1" mt={4} px={1}>
              <Box>
                <Typography mb={2} style={fontStyle.label} variant="body1">
                  {t('channel.label.addSalesTax').toUpperCase()}
                </Typography>
                <Box display="flex">
                  <Radio
                    label={t('button.yes')}
                    checked={!!formContext.watch('useTaxes')}
                    onClick={() => formContext.setValue('useTaxes', true)}
                  />
                  <Box ml={1}>
                    <Radio
                      label={t('button.no')}
                      checked={!formContext.watch('useTaxes')}
                      onClick={() => {
                        formContext.setValue('useTaxes', false);
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
            {formContext.watch('useTaxes') && (
              <Box flexGrow="1" mt={4}>
                <ChannelsTaxes channel={formContext?.channel} />
              </Box>
            )}

            <Box>
              <ChannelsScoresVariations channel={formContext?.channel} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} px={5}>
            <Locations />
            <WorkoutsTypes channel={formContext?.channel} />

            <Box mt={4}>
              <RequiredVideos />
            </Box>

            <Box mt={4}>
              <ChannelCustomization
                channel={formContext?.channel}
                bgImage={formContext.bgImage}
                setBgImage={formContext.setBgImage}
                setValue={formContext.setValue}
                watch={formContext.watch}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container pt={5} px={5}>
          {formContext.channel.categories || formContext.mode === 'new' ? (
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Box display="flex">
                <Typography ml={2} mb={1} style={fontStyle.label} variant="body1">
                  {t('channel.label.categories').toUpperCase()}
                </Typography>
                <Typography ml={2} style={fontStyle.medium}>
                  {t('input.tag.helper')}
                </Typography>
              </Box>
              <TagsChosen
                placeholder={t('profile.placeholder.categories')}
                setValue={(values) => formContext.setValue('categories', values)}
                error={formContext.errors}
                limit={6}
                kind="category"
                val={formContext.watch('categories') ? [...formContext.watch('categories')] : []}
              />
              <Typography px={5} pt={1} style={fontStyle.medium} variant="body2">
                {t('channel.label.categories.help')}
              </Typography>
            </Grid>
          ) : (
            <Skeleton animation="wave" height={50} width="100%" />
          )}
        </Grid>
        <Grid container pt={5} px={5}>
          {formContext.channel.languages || formContext.mode === 'new' ? (
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography ml={2} mb={1} style={fontStyle.label} variant="body1">
                {t('channel.label.languages').toUpperCase()}&nbsp;
                <span style={{ color: primary }}>*</span>
              </Typography>
              <LanguagesChosen
                placeholder={t('channel.placeholder.language')}
                defaultValue={formContext.mode === 'edit' ? formContext.channel.languages : []}
                error={formContext.errors.languages}
                setValue={(values) => formContext.setValue('languages', values)}
              />
            </Grid>
          ) : (
            <Skeleton animation="wave" height={50} width="100%" />
          )}
        </Grid>

        <Box px={5}>
          <Typography
            m={3}
            className="roboto-regular"
            color="primary"
            align="center"
            size={6}
            variant="h6"
            component="h6"
          >
            {formContext.errors.plan && t('channel.selectPlan')}
          </Typography>
        </Box>

        <Box px={6} display="flex" alignItems="center" flexWrap="wrap">
          <Grid container spacing={5}>
            <Grid item xs={6} md={3}>
              <Box>
                <Typography mb={2} style={fontStyle.label} variant="body1">
                  {t('channel.hideAttendance').toUpperCase()}
                </Typography>
                <Box display="flex">
                  <Radio
                    label={t('button.yes')}
                    checked={!!formContext.watch('hideAttendance')}
                    onClick={() => formContext.setValue('hideAttendance', true)}
                  />
                  <Box ml={1}>
                    <Radio
                      label={t('button.no')}
                      checked={!formContext.watch('hideAttendance')}
                      onClick={() => formContext.setValue('hideAttendance', false)}
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={6} md={3}>
              <Box>
                <Typography mb={2} style={fontStyle.label} variant="body1">
                  {t('channel.hideToPublic').toUpperCase()}
                </Typography>
                <Box display="flex">
                  <Radio
                    label={t('button.yes')}
                    checked={!!formContext.watch('hideToPublic')}
                    onClick={() => formContext.setValue('hideToPublic', true)}
                  />
                  <Box ml={1}>
                    <Radio
                      label={t('button.no')}
                      checked={!formContext.watch('hideToPublic')}
                      onClick={() => formContext.setValue('hideToPublic', false)}
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={6} md={3}>
              <Typography mb={2} style={fontStyle.label} variant="body1">
                {t('channel.hideCompetitions').toUpperCase()}
              </Typography>
              <Box display="flex">
                <Radio
                  label={t('button.yes')}
                  checked={!!formContext.watch('hideDashboardCompetitions')}
                  onClick={() => formContext.setValue('hideDashboardCompetitions', true)}
                />
                <Box ml={1}>
                  <Radio
                    label={t('button.no')}
                    checked={!formContext.watch('hideDashboardCompetitions')}
                    onClick={() => formContext.setValue('hideDashboardCompetitions', false)}
                  />
                </Box>
              </Box>
            </Grid>

            <Grid item xs={6} md={3}>
              <Box>
                <Typography mb={2} style={fontStyle.label} variant="body1">
                  {t('channel.hideLeaderboardButton').toUpperCase()}
                </Typography>
                <Box display="flex">
                  <Radio
                    label={t('button.yes')}
                    checked={!!formContext.watch('hideLeaderboardButton')}
                    onClick={() => formContext.setValue('hideLeaderboardButton', true)}
                  />
                  <Box ml={1}>
                    <Radio
                      label={t('button.no')}
                      checked={!formContext.watch('hideLeaderboardButton')}
                      onClick={() => formContext.setValue('hideLeaderboardButton', false)}
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6} md={3}>
              <Box>
                <Typography mb={2} style={fontStyle.label} variant="body1">
                  {t('channel.showLeaderboardRank').toUpperCase()}
                </Typography>
                <Box display="flex">
                  <Radio
                    label={t('button.yes')}
                    checked={!!formContext.watch('showLeaderboardRank')}
                    onClick={() => formContext.setValue('showLeaderboardRank', true)}
                  />
                  <Box ml={1}>
                    <Radio
                      label={t('button.no')}
                      checked={!formContext.watch('showLeaderboardRank')}
                      onClick={() => formContext.setValue('showLeaderboardRank', false)}
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6} md={3}>
              <Box>
                <Typography mb={2} style={fontStyle.label} variant="body1">
                  {t('channel.payCoachesRatesByClassType').toUpperCase()}
                </Typography>
                <Box display="flex">
                  <Radio
                    label={t('button.byClass')}
                    checked={!!formContext.watch('payCoachesRatesByClassType')}
                    onClick={() => formContext.setValue('payCoachesRatesByClassType', true)}
                  />
                  <Box ml={1}>
                    <Radio
                      label={t('button.byMembership')}
                      checked={!formContext.watch('payCoachesRatesByClassType')}
                      onClick={() => formContext.setValue('payCoachesRatesByClassType', false)}
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={6} md={3}>
              <Box>
                <Typography style={fontStyle.label} variant="body1">
                  {t('channel.allowAchPayments').toUpperCase()}
                </Typography>
                <Typography mb={2} style={fontStyle.xSmall} variant="body2">
                  {t('channel.allowAchPayments.help').toUpperCase()}
                </Typography>
                <Box display="flex">
                  <Radio
                    label={t('button.yes')}
                    checked={!!formContext.watch('allowAchPayments')}
                    onClick={() => formContext.setValue('allowAchPayments', true)}
                  />
                  <Box ml={1}>
                    <Radio
                      label={t('button.no')}
                      checked={!formContext.watch('allowAchPayments')}
                      onClick={() => formContext.setValue('allowAchPayments', false)}
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={6} md={3}>
              <Box>
                <Typography mb={2} style={fontStyle.label} variant="body1">
                  {t('label.checkInRule').toUpperCase()}
                </Typography>
                <Box display="flex">
                  <Radio
                    label={t('label.genericCheckIn')}
                    checked={!!formContext.watch('genericCheckIn')}
                    onClick={() => formContext.setValue('genericCheckIn', true)}
                  />
                  <Box ml={1}>
                    <Radio
                      label={t('label.classSpecific')}
                      checked={!formContext.watch('genericCheckIn')}
                      onClick={() => formContext.setValue('genericCheckIn', false)}
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6} md={3}>
              <Box>
                <Typography mb={2} style={fontStyle.label} variant="body1">
                  {t('channel.dateFormat').toUpperCase()}
                </Typography>
                <Box display="flex">
                  <Radio
                    label={t('date.format.us')}
                    checked={!!formContext.watch('useUsDateFormat')}
                    onClick={() => formContext.setValue('useUsDateFormat', true)}
                  />
                  <Box ml={1}>
                    <Radio
                      label={t('date.format.int')}
                      checked={!formContext.watch('useUsDateFormat')}
                      onClick={() => formContext.setValue('useUsDateFormat', false)}
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6} md={3}>
              <Box>
                <Typography mb={2} style={fontStyle.label} variant="body1">
                  {t('label.showPaymentMethodAlert').toUpperCase()}
                </Typography>
                <Box display="flex">
                  <Radio
                    label={t('button.yes')}
                    checked={!!formContext.watch('showPaymentMethodAlert')}
                    onClick={() => formContext.setValue('showPaymentMethodAlert', true)}
                  />
                  <Box ml={1}>
                    <Radio
                      label={t('button.no')}
                      checked={!formContext.watch('showPaymentMethodAlert')}
                      onClick={() => formContext.setValue('showPaymentMethodAlert', false)}
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6} md={3}>
              <Box>
                <Typography mb={2} style={fontStyle.label} variant="body1">
                  {t('label.paymentDiscounts').toUpperCase()}
                </Typography>
                <Box display="flex">
                  <Radio
                    label={t('button.no')}
                    checked={!formContext.watch('paymentDiscountSettings.active')}
                    onClick={() => {
                      formContext.setValue('paymentDiscountSettings.active', false);
                      formContext.setValue('paymentDiscountSettings.achMembershipPerc', null);
                      formContext.setValue('paymentDiscountSettings.achStorePerc', null);
                      formContext.setValue('paymentDiscountSettings.cardMembershipPerc', null);
                      formContext.setValue('paymentDiscountSettings.cardStorePerc', null);
                    }}
                  />
                  <Box ml={1}>
                    <Radio
                      label={t('button.yes')}
                      checked={formContext.watch('paymentDiscountSettings.active')}
                      onClick={() => formContext.setValue('paymentDiscountSettings.active', true)}
                    />
                  </Box>
                </Box>
                {!!formContext.watch('paymentDiscountSettings.active') && (
                  <>
                    {['card', 'ach', 'offline'].map((item) => (
                      <Box display="flex" mt={2} key={item}>
                        <Box mr={1}>
                          <TextFieldRounded
                            type="number"
                            value={
                              formContext.watch(`paymentDiscountSettings.${item}MembershipPerc`) ||
                              0
                            }
                            pattern="[1-9]*"
                            placeholder={t('discount.placeholder.percent')}
                            label={`${item.toUpperCase()} ${t(
                              'discount.membership.percent',
                            ).toUpperCase()}`}
                            onChange={(v) =>
                              formContext.setValue(
                                `paymentDiscountSettings.${item}MembershipPerc`,
                                v >= 0 ? v : 0,
                              )
                            }
                          />
                        </Box>
                        <Box ml={1}>
                          <TextFieldRounded
                            type="number"
                            value={
                              formContext.watch(`paymentDiscountSettings.${item}StorePerc`) || 0
                            }
                            pattern="[1-9]*"
                            placeholder={t('discount.placeholder.percent')}
                            label={`${item.toUpperCase()} ${t(
                              'discount.store.percent',
                            ).toUpperCase()}`}
                            onChange={(v) =>
                              formContext.setValue(
                                `paymentDiscountSettings.${item}StorePerc`,
                                v >= 0 ? v : 0,
                              )
                            }
                          />
                        </Box>
                      </Box>
                    ))}
                  </>
                )}
              </Box>
            </Grid>

            {['athlete', 'coach'].map((item) => (
              <Grid item xs={6} md={3} key={item}>
                <Box>
                  <Typography mb={2} style={fontStyle.label} variant="body1">
                    {t(`label.attendanceAmounts.${item}`).toUpperCase()}
                  </Typography>
                  <Box display="flex">
                    <Radio
                      label={t('button.no')}
                      checked={!formContext.watch(`attendanceCreditsAmounts.${item}Active`)}
                      onClick={() => {
                        formContext.setValue(`attendanceCreditsAmounts.${item}Active`, false);
                      }}
                    />
                    <Box ml={1}>
                      <Radio
                        label={t('button.yes')}
                        checked={formContext.watch(`attendanceCreditsAmounts.${item}Active`)}
                        onClick={() => {
                          formContext.setValue(`attendanceCreditsAmounts.${item}Active`, true);
                          if (!formContext.watch(`attendanceCreditsAmounts.${item}AmountType`)) {
                            formContext.setValue(
                              `attendanceCreditsAmounts.${item}AmountType`,
                              'money',
                            );
                          }
                        }}
                      />
                    </Box>
                  </Box>
                  {!!formContext.watch(`attendanceCreditsAmounts.${item}Active`) && (
                    <>
                      {item === 'athlete' && (
                        <>
                          <Typography my={2} style={fontStyle.label} variant="body1">
                            {t(`label.attendanceAmounts.amountType`).toUpperCase()}
                          </Typography>
                          <Box display="flex">
                            <Radio
                              label={t('label.money')}
                              checked={
                                formContext.watch(`attendanceCreditsAmounts.${item}AmountType`) ===
                                'money'
                              }
                              onClick={() => {
                                formContext.setValue(
                                  `attendanceCreditsAmounts.${item}AmountType`,
                                  'money',
                                );
                              }}
                            />
                            <Box ml={1}>
                              <Radio
                                label={t('label.percentMemberships')}
                                checked={
                                  formContext.watch(
                                    `attendanceCreditsAmounts.${item}AmountType`,
                                  ) === 'percent'
                                }
                                onClick={() =>
                                  formContext.setValue(
                                    `attendanceCreditsAmounts.${item}AmountType`,
                                    'percent',
                                  )
                                }
                              />
                            </Box>
                          </Box>
                        </>
                      )}
                      <Box display="flex" mt={2}>
                        <Box mr={1}>
                          <TextFieldRounded
                            type="number"
                            value={
                              formContext.watch(`attendanceCreditsAmounts.${item}Attendance`) || 0
                            }
                            pattern="[1-9]*"
                            placeholder={t('channel.placeholder.attendance')}
                            label={t('channel.label.creditsAttendance').toUpperCase()}
                            onChange={(v) =>
                              formContext.setValue(
                                `attendanceCreditsAmounts.${item}Attendance`,
                                v >= 0 ? v : 0,
                              )
                            }
                          />
                        </Box>
                        <Box ml={1}>
                          <TextFieldRounded
                            type="number"
                            value={formContext.watch(`attendanceCreditsAmounts.${item}Amount`) || 0}
                            pattern="[1-9]*"
                            placeholder={t('channel.placeholder.amount')}
                            label={t('channel.label.creditsAmount').toUpperCase()}
                            onChange={(v) =>
                              formContext.setValue(
                                `attendanceCreditsAmounts.${item}Amount`,
                                v >= 0 ? v : 0,
                              )
                            }
                            currency
                            currencySymbol={
                              item === 'coach' ||
                              formContext.watch(`attendanceCreditsAmounts.${item}AmountType`) ===
                                'money'
                                ? formContext?.channel?.currency?.symbol
                                : '%'
                            }
                          />
                        </Box>
                      </Box>
                    </>
                  )}
                </Box>
              </Grid>
            ))}

            <Grid item xs={6} md={3}>
              <Typography mb={2} style={fontStyle.label} variant="body1">
                {t('channel.label.membershipPauseFeeAmount').toUpperCase()}
              </Typography>
              <Box display="flex">
                <Radio
                  label={t('button.no')}
                  checked={!formContext.watch('membershipPauseFeeAmountActive')}
                  onClick={() => {
                    formContext.setValue('membershipPauseFeeAmountActive', false);
                    formContext.setValue('membershipPauseFeeAmount', null);
                  }}
                />
                <Box ml={1}>
                  <Radio
                    label={t('button.yes')}
                    checked={formContext.watch('membershipPauseFeeAmountActive')}
                    onClick={() => formContext.setValue('membershipPauseFeeAmountActive', true)}
                  />
                </Box>
              </Box>

              {formContext.watch('membershipPauseFeeAmountActive') && (
                <Box mt={2}>
                  <TextFieldRounded
                    type="number"
                    value={formContext.watch('membershipPauseFeeAmount') || 0}
                    pattern="[1-9]*"
                    placeholder={t('channel.label.membershipPauseFeeAmount')}
                    onChange={(v) => formContext.setValue('membershipPauseFeeAmount', v)}
                  />
                </Box>
              )}
            </Grid>

            <Grid item xs={6} md={3}>
              <Box>
                <Typography mb={2} style={fontStyle.label} variant="body1">
                  {t('channel.useMembershipServiceFee').toUpperCase()}
                </Typography>
                <Box display="flex">
                  <Radio
                    label={t('button.yes')}
                    checked={!!formContext.watch('useMembershipServiceFee')}
                    onClick={() => formContext.setValue('useMembershipServiceFee', true)}
                  />
                  <Box ml={1}>
                    <Radio
                      label={t('button.no')}
                      checked={!formContext.watch('useMembershipServiceFee')}
                      onClick={() => formContext.setValue('useMembershipServiceFee', false)}
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={6} md={3}>
              <Box>
                <Typography mb={2} style={fontStyle.label} variant="body1">
                  {t('channel.allowSelfCheckInWorkouts').toUpperCase()}
                </Typography>
                <Box display="flex">
                  <Radio
                    label={t('button.yes')}
                    checked={!!formContext.watch('allowSelfCheckInWorkouts')}
                    onClick={() => formContext.setValue('allowSelfCheckInWorkouts', true)}
                  />
                  <Box ml={1}>
                    <Radio
                      label={t('button.no')}
                      checked={!formContext.watch('allowSelfCheckInWorkouts')}
                      onClick={() => formContext.setValue('allowSelfCheckInWorkouts', false)}
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>

        {formContext.channel && formContext.channel.id && (
          <Box m={5}>
            <Typography m={3} variant="h6" style={fontStyle.label}>
              {t('channel.embed.codes').toUpperCase()}
            </Typography>
            <Grid container spacing={5}>
              <Grid item xs={12} md={12}>
                <Paper p={1} px={5} className="paper-rounded">
                  <Box display="flex" mb={1}>
                    <Typography mr={1} variant="h6" style={fontStyle.label}>
                      {t('channel.embed.calendarSchedule')}
                    </Typography>
                    <IconButton size="small" onClick={() => copy(embedCalendar)}>
                      <FileCopy size="small" style={{ fontSize: 12 }} />
                    </IconButton>
                  </Box>
                  <Box className={classes.code}>
                    <code>{embedCalendar}</code>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12} md={12}>
                <Paper p={1} px={5} className="paper-rounded">
                  <Box display="flex" mb={1}>
                    <Typography mr={1} variant="h6" style={fontStyle.label}>
                      {t('channel.embed.membershipsPlans')}
                    </Typography>
                    <IconButton size="small" onClick={() => copy(embedMPlans)}>
                      <FileCopy size="small" style={{ fontSize: 12 }} />
                    </IconButton>
                  </Box>
                  <Box className={classes.code}>
                    <code>{embedMPlans}</code>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12} md={12}>
                <Paper p={1} px={5} className="paper-rounded">
                  <Box display="flex" mb={1}>
                    <Typography mr={1} variant="h6" style={fontStyle.label}>
                      {t('channel.embed.store')}
                    </Typography>
                    <IconButton size="small" onClick={() => copy(embedStore)}>
                      <FileCopy size="small" style={{ fontSize: 12 }} />
                    </IconButton>
                  </Box>
                  <Box className={classes.code}>
                    <code>{embedStore}</code>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12} md={12}>
                <ContactFormGenerateCode channel={formContext?.channel} />
              </Grid>
            </Grid>
          </Box>
        )}

        {(formContext.channel.active || formContext.mode === 'new') && (
          <Box px={5} display="flex" justifyContent="flex-end">
            <ButtonPrimarySm
              onClick={() => {
                formContext.submitForm();
              }}
              size="small"
              mt={10}
              mb={5}
              disabled={formContext.loading}
              style={{ height: 30, width: 100, borderRadius: 5 }}
            >
              {t('button.save')}
            </ButtonPrimarySm>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default FormData;
