/* eslint-disable react/prop-types */
import React from 'react';
import Box from 'core/ui/Box';
import Typography, { fontStyle } from 'core/ui/Typography';

import ImageMedia from 'components/Profile/ImageMedia';
import { fromNow } from 'core/utils/formatters';
import Divider from 'core/ui/Divider';
import { primary } from 'core/ui/Colors';
import ButtonLikeInfo from 'core/ui/LikeInfo';
import LabelBreakLine from 'core/ui/LabelBreakLine';
import useStylesComments from './styles';

export default ({ comment, fontColor, buttonColor, hideLikes }) => {
  const classes = useStylesComments();

  return (
    <Box my={1} mx={1}>
      <Box display="flex">
        <Box>
          {comment.user && (
            <ImageMedia
              user={comment.user}
              image={comment.user.image ? comment.user.image : {}}
              size={45}
              sizeCountry="1em"
              heightCountry={15}
              // showCountry={false}
            />
          )}
        </Box>
        <Box flexGrow={1}>
          <Box flexGrow={1} pl={3}>
            <Box display="flex" alignItems="center">
              {comment.user && <Typography>{comment.user.name}</Typography>}
              {comment.isCoach && (
                <Typography
                  ml={1}
                  className={classes.boxCoach}
                  style={{ backgroundColor: buttonColor || primary }}
                >
                  COACH
                </Typography>
              )}
            </Box>
            <Typography
              variant="caption"
              className="roboto-regular"
              style={{ ...fontStyle.medium, overflowWrap: 'anywhere' }}
            >
              <LabelBreakLine>{comment.text || ''}</LabelBreakLine>
            </Typography>
          </Box>

          <Box display="flex">
            <Box display="flex" alignItems="center" flexGrow={1} pl={3}>
              {!hideLikes && (
                <ButtonLikeInfo
                  // callbackLike={refresh}
                  likeableType="Comment"
                  likeableId={comment.id}
                  countLikes={comment.count_likes || comment.countLikes}
                  buttonColor={buttonColor}
                  liked={!!comment.liked}
                />
              )}
            </Box>
            <Box pr={3}>
              <Typography style={{ ...fontStyle.small, color: fontColor || primary }}>
                {fromNow(comment.createdAt)}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <Divider my={1} />
    </Box>
  );
};
