import React, { useState } from 'react';
import useHeats from './useHeats';
import useCompAthletes from '../../Athletes/useCompAthletes';
import HeatsManageContent from './HeatsManageContent';

const HeatsManage = ({ workout, buttonColor, fontColor, divisionId, hasDivisions }) => {
  const [open, setOpen] = useState(false);
  const { compUsers } = useCompAthletes({
    competition: workout?.competition,
    divisionId,
    load: true,
  });

  const {
    competition,
    loadHeats,
    resetHeats,
    heats,
    loadingHeats,
    loadingLeaderboard,
    leaderboard,
    heatsUsers,
    setHeatsUsers,
    changeHeat,
    modalHeatAction,
    setModalHeatAction,
    createNewHeat,
  } = useHeats({
    workout,
    divisionId,
    hasDivisions,
  });

  return (
    <HeatsManageContent
      workout={workout}
      open={open}
      close={() => setOpen(false)}
      buttonColor={buttonColor}
      fontColor={fontColor}
      compUsers={compUsers}
      heatsHook={{
        competition,
        loadHeats,
        resetHeats,
        heats,
        loadingHeats,
        loadingLeaderboard,
        leaderboard,
        heatsUsers,
        setHeatsUsers,
        changeHeat,
        modalHeatAction,
        setModalHeatAction,
        createNewHeat,
      }}
    />
  );
};

export default HeatsManage;
