import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { CircularProgress } from '@material-ui/core';
import { parse } from 'query-string';

import { sessionActions } from 'modules/session';
import { getLoading, getUser } from 'modules/session/selectors';
import { RetryPayment } from './RetryPayment';

export const LoginManager = ({ token }) => {
  const { search } = useLocation();
  const { replace } = useHistory();
  const dispatch = useDispatch();

  const currentUser = useSelector(getUser);
  const loading = useSelector(getLoading);

  useEffect(() => {
    dispatch(
      sessionActions.authenticateByJwtStart(
        token,
        () => {},
        () => replace('/auth/login'),
      ),
    );
  }, [dispatch, token, replace]);

  if (loading || !currentUser)
    return (
      <div className="tw-flex tw-h-screen tw-w-screen tw-flex-col tw-items-center tw-justify-center">
        <h1 className="tw-text-center tw-font-bold">Authenticating...</h1>

        <CircularProgress />
      </div>
    );

  const { id } = parse(search);

  // Currently we just use this route to retry payments by the email, but we can use the "sub" query param to
  // choose the right screen or something like this
  return <RetryPayment paymentId={id} />;
};
