import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import propTypes from 'prop-types';
import { workoutsActions, workoutsSelectors } from 'modules/workouts';
import Box from 'core/ui/Box';
import Typography from 'core/ui/Typography';
import Radio from 'core/ui/Radio';
import { Tooltip } from '@material-ui/core';
import { secondary } from 'core/ui/Colors';
import LoaderSm from 'core/ui/LoaderSm';
import { Info } from '@material-ui/icons';
import notifications from 'modules/notifications';
import { sessionSelectors } from 'modules/session';
import FormScore from 'components/Stream/SideBar/components/Sections/FormScore';

const FormPenaltyScores = ({ workout, sectionData, currentScores, user, saveCallback }) => {
  const { t } = useTranslation();
  const [penalties, setPenaltiesData] = useState({});
  const dispatch = useDispatch();

  const currentUser = useSelector((state) => sessionSelectors.getUser(state));

  const workoutSectionUserData = useSelector((state) =>
    workoutsSelectors.getWorkoutSectionUserData(state),
  );
  const loadingWorkoutSection = useSelector((state) =>
    workoutsSelectors.getLoadingWorkoutSection(state),
  );

  const setForTime = (time) => {
    if (!time) {
      return null;
    }
    const workTimer = time.split(':');
    return {
      hours: workTimer && workTimer[0],
      minutes: workTimer && workTimer[1],
      seconds: workTimer && workTimer[2],
      value: time,
    };
  };

  useEffect(() => {
    if (currentScores) {
      setPenaltiesData({ ...currentScores, time: setForTime(currentScores.time) });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentScores]);

  const saveSectionUser = (scoresData) => {
    if (!penalties.type) {
      dispatch(notifications.warning('Penalty type is required.'));
      return;
    }

    const callback = () => {
      if (saveCallback) saveCallback();
    };

    const currentPenalties = workoutSectionUserData[sectionData.id].penalties || [];

    dispatch(
      workoutsActions.setWorkoutSectionUserPenaltiesStart({
        workoutId: workout.id,
        data: {
          penalties: [
            ...currentPenalties,
            {
              ...penalties,
              judgeId: currentUser.id,
              judgeName: currentUser.name,
              scores: scoresData,
            },
          ],
          sectionId: sectionData.id,
          userId: user.id,
        },
        messageSuccess: t('message.success.save.score'),
        callback: () => callback(),
      }),
    );
  };

  if (loadingWorkoutSection) {
    return (
      <Box p={50} display="flex" justifyContent="center" alignItems="center">
        <LoaderSm width={50} />
      </Box>
    );
  }

  return (
    <Box display="flex" justifyContent="center" flexDirection="column">
      {sectionData.scores && (
        <>
          <Box display="flex" justifyContent="center" my={3}>
            <Radio
              color={secondary}
              label={
                <Box display="flex">
                  <Tooltip
                    title={t('penalty.type.minor.help')}
                    PopperProps={{ style: { zIndex: 9999 } }}
                  >
                    <Info />
                  </Tooltip>
                  <Typography ml={1}>{t('penalty.type.minor')}</Typography>
                </Box>
              }
              onClick={() => setPenaltiesData((prev) => ({ ...prev, type: 'minor' }))}
              checked={penalties && penalties.type === 'minor'}
            />
            <Box ml={3}>
              <Radio
                color={secondary}
                label={
                  <Box display="flex">
                    <Tooltip
                      title={t('penalty.type.major.help')}
                      PopperProps={{ style: { zIndex: 9999 } }}
                    >
                      <Info />
                    </Tooltip>
                    <Typography ml={1}>{t('penalty.type.major')}</Typography>
                  </Box>
                }
                onClick={() => setPenaltiesData((prev) => ({ ...prev, type: 'major' }))}
                checked={penalties && penalties.type === 'major'}
              />
            </Box>
            <Box ml={3}>
              <Radio
                color={secondary}
                label={
                  <Box display="flex">
                    <Tooltip
                      title={t('penalty.type.invalid.help')}
                      PopperProps={{ style: { zIndex: 9999 } }}
                    >
                      <Info />
                    </Tooltip>
                    <Typography ml={1}>{t('penalty.type.invalid')}</Typography>
                  </Box>
                }
                onClick={() => setPenaltiesData((prev) => ({ ...prev, type: 'invalid' }))}
                checked={penalties && penalties.type === 'invalid'}
              />
            </Box>
          </Box>

          <FormScore
            sectionData={workout && workout.sections && workout.sections[0]}
            user={user}
            showSaveButton
            workout={workout}
            saveFormPenalty={(scoreData) => saveSectionUser(scoreData)}
          />
        </>
      )}

      {/* {showSaveButton && (
        <Box display="flex" mt={5} justifyContent="center">
          <ButtonPrimary
            disabled={loadingWorkoutSection}
            style={{ width: '100%' }}
            onClick={() => saveSectionUser()}
          >
            {t('confirm')}
          </ButtonPrimary>
        </Box>
      )} */}
    </Box>
  );
};

FormPenaltyScores.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  sectionData: propTypes.object,
  saveCallback: propTypes.func,
};

FormPenaltyScores.defaultProps = {
  sectionData: {},
  saveCallback: () => {},
};

export default FormPenaltyScores;
