import React, { useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from 'core/ui/Typography';
import PropTypes from 'prop-types';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import ImageMediaUser from 'components/Profile/ImageMedia';
import InstagramLink from 'components/Profile/InstagramLink';
import useChannelPermissions from 'components/Channel/useChannelPermissions';
import { secondary, successColor } from 'core/ui/Colors';
import { IconButton, Menu, MenuItem, Dialog } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Checkbox from 'core/ui/Checkbox';
import Check from '@material-ui/icons/Check';
import MemberNotes from 'components/Channel/Manage/Tabs/Members/Memberships/MemberInformation/MemberNotes';
import ModalAddPayment from 'components/Channel/Manage/Tabs/Members/Memberships/MemberInformation/FormPayment/Modal';

const useStyles = makeStyles({
  root: {
    backgroundColor: 'transparent',
    display: 'flex',
    position: 'relative',
    width: '100%',
  },
  boxNotes: {
    position: 'absolute',
    top: 3,
    padding: 3,
    right: 40,
    zIndex: 1,
    '&:hover': {
      cursor: 'pointer',
    },
  },
});

export default function CardAthlete({
  workout,
  athlete,
  checkInUser,
  onUnRegisterClick,
  addScoresUser,
  isCoach,
  checked,
  coaches,
  selected,
  setSelected,
  pendingPostPayment,
  channelKeyId,
  refreshWorkout,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const refBox = useRef();
  const anchorMenuRef = useRef();

  const [openMenu, setOpenMenu] = useState(false);
  const [openPostPayment, setOpenPostPayment] = useState(false);
  const [openEmergContact, setOpenEmergContact] = useState(false);
  const [openInjury, setOpenInjury] = useState(false);
  const [openNotes, setOpenNotes] = useState();

  const { notesAccess } = useChannelPermissions({ channel: workout.channel });

  return (
    <Box className={classes.root} ref={refBox}>
      {openNotes && (
        <Dialog maxWidth="sm" fullWidth open={openNotes} onClose={() => setOpenNotes(false)}>
          <Box p={3}>
            <MemberNotes memberId={athlete?.id} channelId={workout?.channel?.id} />
          </Box>
        </Dialog>
      )}
      {openEmergContact && (
        <Dialog
          maxWidth="sm"
          fullWidth
          open={openEmergContact}
          onClose={() => setOpenEmergContact(false)}
        >
          <Box p={3}>
            <Typography variant="h6">{t('profile.title.emergencyContact')}</Typography>

            <Box>
              <Typography className="roboto-regular">{athlete?.emergencyName}</Typography>
              <Typography className="roboto-regular">{athlete?.emergencyEmail}</Typography>
              <Typography className="roboto-regular">{athlete?.emergencyPhone}</Typography>
            </Box>
          </Box>
        </Dialog>
      )}
      {openInjury && (
        <Dialog maxWidth="sm" fullWidth open={openInjury} onClose={() => setOpenInjury(false)}>
          <Box p={3}>
            <Box>
              <Typography className="roboto-regular">{athlete?.injuryReport}</Typography>
            </Box>
          </Box>
        </Dialog>
      )}

      {openPostPayment?.open && (
        <ModalAddPayment
          member={athlete}
          open={openPostPayment?.open}
          channel={workout?.channel}
          channelKey={{ id: channelKeyId }}
          close={() => setOpenPostPayment({ open: false })}
          callback={refreshWorkout}
        />
      )}

      <Menu
        anchorEl={anchorMenuRef.current}
        keepMounted
        open={openMenu}
        onClose={() => setOpenMenu(false)}
      >
        {!!pendingPostPayment && (
          <MenuItem
            onClick={() => {
              setOpenMenu(false);
              setOpenPostPayment({ open: true });
            }}
          >
            {t('label.postPayment')}
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            setOpenMenu(false);
            checkInUser(athlete.id);
          }}
        >
          {t(checked ? 'button.checkedIn' : 'button.checkIn')}
        </MenuItem>
        {!!athlete?.emergencyName && (
          <MenuItem
            onClick={() => {
              setOpenMenu(false);
              setOpenEmergContact(true);
            }}
          >
            {t('button.emergencyContact')}
          </MenuItem>
        )}
        {!!athlete?.injuryReport && (
          <MenuItem
            onClick={() => {
              setOpenMenu(false);
              setOpenInjury(true);
            }}
          >
            {t('button.injuryReport')}
          </MenuItem>
        )}
        {notesAccess && (
          <MenuItem
            onClick={() => {
              setOpenMenu(false);
              setOpenNotes(true);
            }}
          >
            {t('button.memberNotes')}
          </MenuItem>
        )}
        {addScoresUser && !coaches && (
          <MenuItem
            onClick={() => {
              setOpenMenu(false);
              addScoresUser(athlete);
            }}
          >
            {t('button.scores')}
          </MenuItem>
        )}

        <MenuItem
          onClick={() => {
            setOpenMenu(false);
            onUnRegisterClick(athlete.id);
          }}
        >
          {t('button.remove')}
        </MenuItem>
      </Menu>

      <Box display="flex" flex={1} alignItems="center">
        {isCoach && setSelected && (
          <Box mx={1}>
            <Checkbox checked={selected} onClick={() => setSelected(selected, athlete.id)} />
          </Box>
        )}
        <Box flexGrow="1">
          <Box
            display="flex"
            alignItems="flex-center"
            mt={1}
            className="hover"
            onClick={() => isCoach && setSelected && setSelected(selected, athlete.id)}
          >
            <ImageMediaUser
              user={athlete}
              image={athlete.image ? athlete.image : {}}
              size={45}
              sizeCountry="1em"
              heightCountry={18}
            />
            <Box px={2} flexGrow={1}>
              <Typography color="secondary" variant="caption" component="p">
                {athlete.name || athlete.phone || athlete.email}
              </Typography>
              <InstagramLink instagram={athlete.instagram} clickable={false} />

              {isCoach && pendingPostPayment && (
                <div className="tw-mt-1 tw-font-bold tw-text-red-500">{t('label.postDue')}</div>
              )}
            </Box>
          </Box>
        </Box>
        {isCoach && (
          <Box>
            <IconButton
              ref={anchorMenuRef}
              style={{ backgroundColor: checked ? successColor : secondary, padding: 1 }}
              aria-label="settings"
              onClick={() => setOpenMenu(true)}
            >
              {checked ? (
                <Check style={{ color: 'white', fontSize: 30 }} />
              ) : (
                <MoreHorizIcon style={{ color: 'white', fontSize: 30 }} />
              )}
            </IconButton>
          </Box>
        )}
      </Box>
    </Box>
  );
}

CardAthlete.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  athlete: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  workout: PropTypes.object,
};

CardAthlete.defaultProps = {
  athlete: {},
  workout: {},
};
