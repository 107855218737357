import React, { useContext, useEffect, useState } from 'react';
import Box from 'core/ui/Box';
import Paper from 'core/ui/Paper';
import Typography from 'core/ui/Typography';
import CollapseText from 'core/ui/CollapseText';
import { useTranslation } from 'react-i18next';
import { secondaryLight } from 'core/ui/Colors';
import { workoutChannelPrograms } from 'core/utils/helpers';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import useCustomActions from 'components/Profile/Dashboard/Workouts/useCustomActions';
import ModalScore from 'components/Stream/SideBar/components/Sections/ModalScore';
import { workoutsActions } from 'modules/workouts';
import { useDispatch, useSelector } from 'react-redux';
import { sessionSelectors } from 'modules/session';
import ModalCustomClass from 'components/Profile/Dashboard/Workouts/Custom/Modal';
import Grid from 'core/ui/Grid';
import HistoryWorkoutScoresDetails from 'components/Profile/Dashboard/Workouts/HistoryWorkoutScoresDetails';
import { LayoutContext } from 'pages/shared/Layout';
import WorkoutDetailSections from './WorkoutDetailSections';

const CustomOverview = ({ workout, isCompareVideos }) => {
  const { t } = useTranslation();
  const layoutContext = useContext(LayoutContext);
  const dispatch = useDispatch();
  const programs = workoutChannelPrograms(workout?.sections?.map((x) => x.detail));
  const [currentProgramId, setCurrentProgramId] = useState(null);
  const currentUser = useSelector((state) => sessionSelectors.getUser(state));
  const [currentOpened, setCurrentOpened] = useState(null);

  const callbackForm = () => {
    dispatch(workoutsActions.getWorkoutStart(workout.id));
  };

  const customWorkoutActions = useCustomActions({
    callbackForm,
    workout,
  });

  useEffect(() => {
    if (programs && programs?.length > 0) {
      const def = programs.find((x) => !!x.default);
      if (def) {
        setCurrentProgramId(def.id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workout]);

  return (
    <Box>
      {customWorkoutActions.modalScore.open && (
        <ModalScore
          user={currentUser}
          sectionData={customWorkoutActions.modalScore.sectionData}
          open={customWorkoutActions.modalScore.open}
          workout={customWorkoutActions.modalScore.workout}
          setOpen={() => customWorkoutActions.setModalScore({ open: false })}
          callbackSave={() => {
            callbackForm({ id: workout?.id });
          }}
        />
      )}
      <ModalCustomClass
        callbackForm={callbackForm}
        modalForm={customWorkoutActions.modalForm}
        close={() => customWorkoutActions.setModalForm({ open: false })}
      />

      <Paper className="paper-rounded" p={3}>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <Typography color="primary" variant="h5">
              {workout.name}
            </Typography>
          </Box>
        </Box>

        {!!workout.description && (
          <Box display="flex" flexWrap="wrap" flexDirection="column">
            <Typography color="secondary" variant="subtitle2" component="h1">
              {t('description')}
            </Typography>

            <CollapseText text={workout.description} maxChar={300} />
          </Box>
        )}
      </Paper>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          {programs && programs.length > 1 && (
            <Box my={3}>
              <SelectBoxObject
                label={t('workout.section.label.program').toUpperCase()}
                options={programs}
                value={currentProgramId || null}
                setValue={(v) => setCurrentProgramId(v)}
              />
            </Box>
          )}
          {workout &&
            workout.sections &&
            workout.sections
              .filter(
                (x) =>
                  !x?.channelProgram ||
                  !currentProgramId ||
                  Number(x?.channelProgram?.id) === Number(currentProgramId),
              )
              .map((section) => (
                <Box mt={2} key={section.id}>
                  <Paper
                    className="paper-rounded"
                    style={{
                      backgroundColor: 'transparent',
                      border: `0.5px solid ${secondaryLight}`,
                    }}
                  >
                    <WorkoutDetailSections
                      workout={workout}
                      sectionId={section.id}
                      setModalScore={customWorkoutActions.setModalScore}
                      removeScore={customWorkoutActions.removeScore}
                      addPrScore={() => layoutContext.addPrScore()}
                      opened={currentOpened === section.id}
                      setCurrentOpened={setCurrentOpened}
                      hideTimer={isCompareVideos}
                    />
                  </Paper>
                </Box>
              ))}
        </Grid>
        <Grid item xs={12} md={6}>
          {workout?.sections?.map((section) => (
            <Box key={section?.id} mt={2}>
              <HistoryWorkoutScoresDetails
                section={section}
                workout={workout}
                actions={customWorkoutActions}
                currentUser={currentUser}
              />
            </Box>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default CustomOverview;
