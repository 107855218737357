import api from 'core/api';

export const userPaymentMethodsModule = 'userPaymentMethods';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const actions = {
  getUserPaymentMethods: {
    module: userPaymentMethodsModule,
    name: 'getUserPaymentMethods',
    api: (params) => api.get(`/user_payment_methods`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: ['data'],
    },
    state: loadingStates,
  },
  getPaymentMethodBySetupIntent: {
    module: userPaymentMethodsModule,
    name: 'getPaymentMethodBySetupIntent',
    api: (params) => api.get(`/user_payment_methods/by_setup_intent`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  setDefault: {
    module: userPaymentMethodsModule,
    name: 'setDefault',
    api: (params) => api.get(`/user_payment_methods/${params?.id}/set_default`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: ['data'],
    },
    state: loadingStates,
  },
  inactive: {
    module: userPaymentMethodsModule,
    name: 'inactive',
    api: (params) => api.get(`/user_payment_methods/${params?.id}/inactive`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: ['data'],
    },
    state: loadingStates,
  },
  createSetupIntent: {
    module: userPaymentMethodsModule,
    name: 'createSetupIntent',
    api: (params) =>
      api.get(`/user_payment_methods/create_setup_intent/${params?.userId}`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: ['setupIntent'],
    },
    state: loadingStates,
  },
  addPaymentMethod: {
    module: userPaymentMethodsModule,
    name: 'addPaymentMethod',
    params: {
      start: ['params'],
      error: [''],
      success: [['data', ({ state, params }) => [...state.data, params]]],
    },
  },
};

export default {
  actions,
  state: {
    loading: false,
    data: [],
    setupIntent: null,
  },
};
