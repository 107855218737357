import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import { Dialog, FormControlLabel, Radio } from '@material-ui/core';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import Typography, { fontStyle } from 'core/ui/Typography';
import { ButtonPrimary } from 'core/ui/Button';
import Texture from 'core/ui/Texture';
import useFormLists from './form';

const ModalFormList = ({ open, item, close, callback, mode }) => {
  const { t } = useTranslation();
  const { setValue, watch, handleSubmit, onSubmit, errors, loading } = useFormLists({
    mode,
    item,
    callback,
  });
  return (
    <Dialog
      PaperProps={{ style: { borderRadius: 5, minWidth: 300 } }}
      maxWidth="md"
      scroll="body"
      onClose={() => close()}
      open={open}
    >
      <Texture>
        <Box p={5}>
          <Box>
            <Typography align="center" mb={2}>
              {t('channel.lists')}
            </Typography>
            <Box>
              <Box mb={3}>
                <TextFieldRounded
                  value={watch('name')}
                  onChange={(v) => setValue('name', v)}
                  placeholder={t('form.list.label.name')}
                  error={errors.name}
                  label={t('form.list.label.name').toUpperCase()}
                  required
                />
              </Box>
              <Box mb={1} ml={2}>
                <Typography style={fontStyle.label} variant="body1">
                  {t('form.list.label.active').toUpperCase()}
                </Typography>
                <Box display="flex">
                  <FormControlLabel
                    onClick={() => setValue('active', true)}
                    control={<Radio color="primary" size="small" checked={!!watch('active')} />}
                    label="Yes"
                  />
                  <FormControlLabel
                    onClick={() => setValue('active', false)}
                    control={<Radio color="primary" size="small" checked={!watch('active')} />}
                    label="No"
                  />
                </Box>
              </Box>
              <Box>
                <Typography ml={2} style={fontStyle.label} variant="body1">
                  {t('form.list.label.price').toUpperCase()}
                </Typography>
                <Typography ml={2} mb={1} style={fontStyle.small} variant="body1">
                  {t('form.list.help.price')}
                </Typography>
                <TextFieldRounded
                  value={watch('price')}
                  onChange={(v) => setValue('price', v)}
                  type="number"
                  pattern="[0-9]*"
                  placeholder={t('form.list.label.price')}
                  currency
                />
              </Box>
              <Typography
                ml={2}
                component="h5"
                variant="caption"
                color="primary"
                style={fontStyle.small}
              >
                {t('channel.list.packagePriceDescription')}
              </Typography>
              <Typography
                ml={2}
                component="h5"
                variant="caption"
                color="primary"
                style={fontStyle.small}
              >
                {t('channel.list.packagePriceClassesRule')}
              </Typography>
            </Box>

            <Box mt={3} display="flex" justifyContent="flex-end">
              <ButtonPrimary disabled={loading} onClick={handleSubmit(onSubmit)}>
                {t('button.save')}
              </ButtonPrimary>
            </Box>
          </Box>
        </Box>
      </Texture>
    </Dialog>
  );
};

ModalFormList.defaultProps = {
  close: () => {},
  callback: () => {},
  item: null,
  open: false,
  mode: 'new',
};

ModalFormList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  close: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  callback: PropTypes.func,
  open: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.object,
  mode: PropTypes.string,
};

export default ModalFormList;
