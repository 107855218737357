import React, { useEffect, useState } from 'react';
import AWS from 'aws-sdk';
import uuid from 'react-uuid';
import LinearProgress from 'core/ui/LinearProgress';
import { ENV_APP } from 'core/env';
import { B_ID } from 'core/utils/consts';
import { primary, redColor, secondary } from 'core/ui/Colors';
import Box from 'core/ui/Box';
import { mKY } from 'core/utils/helpers';
import { Error } from '@material-ui/icons';
import Typography from 'core/ui/Typography';
import { ButtonPrimary } from 'core/ui/Button';

const B_K = mKY();

const bucket = new AWS.S3({
  accessKeyId: B_ID,
  secretAccessKey: B_K,
  region: 'us-east-2',
});

const PATH_KEY = `uploads/${ENV_APP}/WebViewMobileUpload/`;

export default function ModalUpload({ buttonColor, upload, setUpload, web }) {
  const [error, setError] = useState('');
  const [progressUpload, setProgressUpload] = useState(0);

  const uploadfile = (fileName, file) => {
    const params = {
      Bucket: 'stream-fitness-app-uploads',
      Key: PATH_KEY + fileName,
      Body: file,
      ContentType: file.type,
    };

    return bucket.upload(params, (err, data) => {
      if (err) {
        setProgressUpload(-1);
        setError(err?.message);
        return false;
      }

      if (!web) {
        window.sendMessage(JSON.stringify({ s3Url: data.Location }));
      }
      // window.ReactNativeWebView.postMessage(JSON.stringify({ s3Url: data.Location }));
      // setValue('videoLink', data.Location);
      setUpload((prev) => ({ ...prev, complete: true, s3Url: data.Location }));
      return true;
    });
  };

  const startUploadFile = () => {
    const { file } = upload;
    const uniqueFileName = `streamfitness-file-${uuid()}-${file.name}`;

    setError(null);
    setProgressUpload(0);

    uploadfile(uniqueFileName, file).on('httpUploadProgress', (progress) => {
      const progressPercentage = Math.round((progress.loaded / progress.total) * 100);
      setProgressUpload(progressPercentage);
    });
  };

  useEffect(() => {
    if (upload.open) {
      startUploadFile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upload.open]);

  return (
    <Box>
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" px={5}>
        {progressUpload === -1 ? (
          <div className="tw-m-2 tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-2">
            <Error style={{ color: redColor }} fontSize="large" />
            <Typography className="tw-text-xl tw-text-white">Upload Error: {error}</Typography>

            <ButtonPrimary buttonColor={primary} onClick={() => startUploadFile()}>
              Try Again
            </ButtonPrimary>
          </div>
        ) : (
          <Box p={3} style={{ backgroundColor: 'white', width: '100%', borderRadius: 10 }}>
            <LinearProgress
              style={{
                height: 15,
                width: '100%',
                borderRadius: 5,
                backgroundColor: buttonColor || secondary,
              }}
              value={progressUpload}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}
