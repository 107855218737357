import React, { useContext, useRef, useState } from 'react';
import Box from 'core/ui/Box';
import { ButtonPrimary } from 'core/ui/Button';
import { useTranslation } from 'react-i18next';
import {
  ButtonGroup,
  Paper,
  MenuList,
  MenuItem,
  Popper,
  ClickAwayListener,
} from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import useActions from 'modules/map/useActions';
import notifications from 'modules/notifications';
import { useDispatch } from 'react-redux';
import Modules from 'modules';
import { competitionsActions } from 'modules/competitions';
import { useHistory } from 'react-router';
import ModalPayment from 'components/Competition/Payment/Modal';
import ConfirmDialog from 'core/ui/ConfirmDialog';
import ModalPayAndCreate from 'components/Competition/Payment/ModalPayAndCreate';
import useSelectors from 'modules/map/useSelectors';
import { newCompetitionsModule } from 'modules/competitions/new';
import { ShowContext } from '../..';

const CoachMenuActions = () => {
  const { request } = useActions();
  const dispatch = useDispatch();
  const [dialogCancel, setDialogCancel] = useState({ open: false });
  const history = useHistory();
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const showContext = useContext(ShowContext);
  const [openModalPayment, setOpenModalPayment] = useState(false);
  const [openModalClone, setOpenModalClone] = useState(false);
  const anchorRef = useRef();

  const loadingClone = useSelectors(newCompetitionsModule, 'loadingClone');

  const confirmCancelClass = () => {
    if (showContext.selectedWorkout) {
      request({
        action: Modules.newWorkouts.actions.cancelClass,
        data: { id: showContext.selectedWorkout },
        options: {
          onSuccess: () => {
            dispatch(notifications.success(t('workout.message.cancel')));
          },
        },
      });
    } else {
      dispatch(
        competitionsActions.cancelCompetitionStart({
          id: showContext.competition.id,
          messageSuccess: t('competition.message.cancel'),
        }),
      );
    }
  };

  const confirmClone = (userPaymentMethodId) => {
    request({
      action: Modules.newCompetitions.actions.cloneCompetition,
      data: { competitionId: showContext.competition.id, userPaymentMethodId },
      options: {
        onSuccess: (newComp) => {
          history.push(`/competition/view/${newComp?.id}`);
        },
        onError: (error) => {
          dispatch(notifications.error(error));
        },
      },
    });
  };

  const cancelClass = () => {
    setDialogCancel({
      open: true,
      callback: () => {
        confirmCancelClass();
      },
    });
  };

  return (
    <Box>
      <ConfirmDialog
        open={dialogCancel.open}
        setOpen={(v) => setDialogCancel((prev) => ({ ...prev, open: v }))}
        description={t(
          showContext.selectedWorkout ? 'event.confirm.delete' : 'competition.confirm.delete',
        )}
        labelConfirm={t('confirm')}
        labelCancel={t('cancel')}
        confirm={dialogCancel.callback}
      />
      <Box>
        <ButtonGroup variant="contained" color="primary" ref={anchorRef} aria-label="split button">
          <ButtonPrimary
            buttonColor={showContext.competition.buttonColor}
            ref={anchorRef}
            onClick={() => setOpen(!open)}
          >
            Actions <ArrowDropDown />
          </ButtonPrimary>
        </ButtonGroup>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="left-start"
          transition
          style={{ zIndex: 9999 }}
          disablePortal
        >
          <Box>
            <Paper>
              <ClickAwayListener onClickAway={() => setOpen(false)}>
                <MenuList id="split-button-menu">
                  <MenuItem
                    onClick={() => {
                      showContext.setOpenModalRegistration((prev) => ({
                        ...prev,
                        open: true,
                        pay: false,
                        coachTeam: false,
                        addTeamManually: true,
                      }));
                      setOpen(false);
                    }}
                  >
                    {t(
                      showContext?.isTeam
                        ? 'competition.teams.addTeam'
                        : 'competition.teams.addCompetitor',
                    )}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      history.push(
                        showContext.selectedWorkout
                          ? `/workout/edit/${showContext.selectedWorkout}`
                          : `/competition/edit/${showContext.competition.id}`,
                      );
                      setOpen(false);
                    }}
                  >
                    {t(showContext.selectedWorkout ? 'button.editEvent' : 'button.editCompetition')}
                  </MenuItem>
                  {!showContext.selectedWorkout && (
                    <MenuItem
                      onClick={() => {
                        setOpenModalClone(true);
                        setOpen(false);
                      }}
                    >
                      {t('button.cloneCompetition')}
                    </MenuItem>
                  )}
                  <MenuItem
                    onClick={() => {
                      cancelClass();
                      setOpen(false);
                    }}
                  >
                    {t(
                      showContext.selectedWorkout
                        ? 'button.deleteEvent'
                        : 'button.deleteCompetition',
                    )}
                  </MenuItem>
                  {!showContext.selectedWorkout && !showContext.competition.paidAt && (
                    <MenuItem
                      onClick={() => {
                        setOpenModalPayment(true);
                        setTimeout(() => {
                          setOpen(false);
                        }, 300);
                      }}
                    >
                      {t('button.payCompetition')}
                    </MenuItem>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Box>
        </Popper>
      </Box>
      {openModalPayment && <ModalPayment open={openModalPayment} setOpen={setOpenModalPayment} />}
      {openModalClone && (
        <ModalPayAndCreate
          channel={showContext?.competition?.channel}
          open={openModalClone}
          close={() => setOpenModalClone(false)}
          confirm={(userPaymentMethodId) => confirmClone(userPaymentMethodId)}
          isClone
          loading={loadingClone}
        />
      )}
    </Box>
  );
};

export default CoachMenuActions;
