import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form-v5';
import Modules from 'modules';
import useActions from 'modules/map/useActions';
import { useDispatch } from 'react-redux';
import notifications from 'modules/notifications';
import useSelectors from 'modules/map/useSelectors';
import { storeCategoriesModule } from 'modules/storeCategories';

export default ({ mode, item, callback, ownerableId, ownerableType }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { request } = useActions();
  const loading = useSelectors(storeCategoriesModule, 'loading');

  const { register, handleSubmit, setValue, watch, reset, errors } = useForm();

  const getCategories = () => {
    request({
      action: Modules.storeCategories.actions.getStoreCategories,
      data: {
        ownerableId,
        ownerableType,
        showInactive: 0,
      },
    });
  };

  const onSubmit = (values) => {
    const data = {
      ...values,
      // eslint-disable-next-line no-nested-ternary
      ownerableId,
      ownerableType,
    };

    if (mode === 'new') {
      request({
        action: Modules.storeCategories.actions.postStoreCategories,
        data: {
          ownerableId,
          ownerableType,
          ...data,
        },
        options: {
          onSuccess: () => {
            if (callback) {
              callback(values.name);
            }
            getCategories();
            dispatch(notifications.success(t('message.success.save.storeCategories')));
          },
        },
      });
    } else {
      request({
        action: Modules.storeCategories.actions.putStoreCategories,
        data: {
          id: item.id,
          ...data,
        },
        options: {
          onSuccess: () => {
            if (callback) {
              callback(values.name);
            }
            getCategories();
            dispatch(notifications.success(t('message.success.save.storeCategories')));
          },
        },
      });
    }
  };

  useEffect(() => {
    register('name', { required: t('form.product.validate.name') });
    register('active', { required: false });

    if (mode === 'new') {
      setValue('active', true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register]);

  useEffect(() => {
    setTimeout(() => {
      reset(item);
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item?.id]);

  return {
    register,
    setValue,
    watch,
    reset,
    errors,
    onSubmit,
    handleSubmit,
    loading,
  };
};
