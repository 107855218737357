import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import Typography from 'core/ui/Typography';

import {
  Dialog,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { ButtonPrimary, ButtonPrimarySm } from 'core/ui/Button';
import notifications from 'modules/notifications';
import { useDispatch } from 'react-redux';
import SelectorUser from 'components/Profile/SelectorUser';
import Texture from 'core/ui/Texture';
import { WorkoutContext } from '../index';

export default () => {
  const { t } = useTranslation();
  const formContext = useContext(WorkoutContext);
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useDispatch();

  const confirmAdd = (user, showMessage = true) => {
    const ar = formContext.watch('users') ? [...formContext.watch('users')] : [];

    const valid = !ar.find((x) => x.userId === user?.id);

    if (valid) {
      ar.push({ userId: user?.id, user, invited: false, inviteType: 'normal', autoAdded: true });
      formContext.setValue('users', ar);
    } else if (showMessage) {
      dispatch(notifications.warning(t('workout.invite.validate')));
    }
    setOpenModal(false);
  };

  useEffect(() => {
    if (formContext.scheduleSession && formContext.scheduleSession.id) {
      confirmAdd(formContext.scheduleSession.user, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formContext.scheduleSession]);

  const remove = (invite) => {
    const arr = formContext?.watch('users') || [];

    formContext.setValue(
      'users',
      arr.map((x) => {
        if (x === invite) {
          return {
            ...x,
            _destroy: true,
          };
        }
        return x;
      }),
    );
  };

  return (
    <Box>
      <Dialog open={openModal} maxWidth="sm" fullWidth onClose={() => setOpenModal(false)}>
        <Texture>
          <Box>{openModal && <SelectorUser onSelectedUser={(u) => confirmAdd(u)} />}</Box>
        </Texture>
      </Dialog>
      <Box>
        <Box display="flex" alignItems="center">
          <Box flexGrow="1">
            <Typography variant="h5" mb={3} color="primary">
              {t('workout.sssignments')}
            </Typography>
          </Box>
          <ButtonPrimary onClick={() => setOpenModal(true)}>
            {t('button.invite.athlete')}
          </ButtonPrimary>
        </Box>

        <Box>
          <Box mt={2}>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>User</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {formContext.watch('users') &&
                    formContext
                      .watch('users')
                      .filter((x) => !x?._destroy)
                      .filter((x) => !!x?.autoAdded)
                      .map((row, index) => (
                        <TableRow key={index.toString()}>
                          <TableCell>{row?.user?.name}</TableCell>
                          <TableCell>
                            <ButtonPrimarySm onClick={() => remove(row)}>
                              {t('button.remove')}
                            </ButtonPrimarySm>
                          </TableCell>
                        </TableRow>
                      ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
