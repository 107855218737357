import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form-v5';
import { useDispatch } from 'react-redux';
import notifications from 'modules/notifications';
import { storeCategoriesModule } from 'modules/storeCategories';
import Modules from 'modules';
import useActions from 'modules/map/useActions';
import useSelectors from 'modules/map/useSelectors';
import { storeProductsModule } from 'modules/storeProducts';
import { storeTaxJarModule } from 'modules/storeTaxJar';
import { StoreContext } from 'contexts';

export default ({ mode, productId, callback, ownerableId, ownerableType }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { request } = useActions();
  const storeContext = useContext(StoreContext);
  const categories = useSelectors(storeCategoriesModule, 'data');
  const loading = useSelectors(storeProductsModule, 'loading');
  const { register, handleSubmit, setValue, watch, reset, errors } = useForm();
  const [inventories, setInventories] = useState([]);

  const [product, setProduct] = useState(null);
  const [images, setImages] = useState([]);

  const [privateChannels, setPrivateChannels] = useState([]);

  const taxJatCategories = useSelectors(storeTaxJarModule, 'data');
  const loadingTaxJarCategories = useSelectors(storeTaxJarModule, 'loading');

  useEffect(() => {
    if (productId) {
      request({
        action: Modules.storeProducts.actions.getStoreProduct,
        data: { id: productId, isForm: true },
        options: {
          onSuccess: (data) => setProduct(data),
        },
      });
    }

    return () => {
      setProduct(null);
    };
  }, [productId, request]);

  useEffect(() => {
    if (storeContext?.taxByCounty) {
      request({
        action: Modules.storeTaxJar.actions.getStoreTaxJarCategories,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeContext?.taxByCounty]);

  useEffect(() => {
    request({
      action: Modules.storeCategories.actions.getStoreCategories,
      data: {
        ownerableType,
        ownerableId,
        showInactive: 0,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, ownerableId]);

  const onSubmit = (values) => {
    const data = {
      ...values,
      // eslint-disable-next-line no-nested-ternary
      imagesAttributes: images,
      inventoriesAttributes: inventories,
      partnersPrivateProductsChannelsAttributes: privateChannels,
      ownerableType,
      ownerableId,
    };

    if (mode === 'new') {
      request({
        action: Modules.storeProducts.actions.postStoreProducts,
        data: {
          ...data,
        },
        options: {
          onSuccess: () => {
            dispatch(notifications.success(t('message.success.save.storeProducts')));
            if (callback) {
              callback(values.name);
            }
          },
          onError: (err) => {
            dispatch(notifications.error(err));
          },
        },
      });
    } else {
      request({
        action: Modules.storeProducts.actions.putStoreProducts,
        data: {
          id: productId,
          ...data,
        },
        options: {
          onSuccess: () => {
            dispatch(notifications.success(t('message.success.save.storeProducts')));
            if (callback) {
              callback(values.name);
            }
          },
          onError: (error) => {
            dispatch(notifications.error(error));
          },
        },
      });
    }
  };

  useEffect(() => {
    register('name', { required: t('form.product.validate.name') });
    register('description', { required: t('form.product.validate.description') });
    register('storeCategoryId', { required: true });
    register('active', { required: false });
    register('noMemberPurchase', { required: false });
    register('addSalesTax', { required: false });
    register('channelsTaxId', { required: false });
    register('offerDelivery', { required: false });
    register('deliveryPrice', { required: false });
    register('deliveryWorldWide', { required: false });
    register('deliveryCountryId', { required: false });
    register('showInCompetitions', { required: false });
    register('holdTransactions', { required: false });
    register('shopifyInventory', { required: false });
    register('taxJarProductTaxCode', { required: false });
    register('partnerPrivate', { required: false });
    register('allowPreSale', { required: false });
    register('storeDiscountCodeIds', { required: false });

    register({ name: 'amounts.gymAmountType' });
    register({ name: 'amounts.gymAmount' });
    register({ name: 'amounts.streamAmountType' });
    register({ name: 'amounts.streamAmount' });
    register({ name: 'amounts.customerAmountType' });
    register({ name: 'amounts.customerAmount' });

    if (mode === 'new') {
      setValue('active', true);
      setValue('noMemberPurchase', false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register]);

  useEffect(() => {
    if (mode === 'new' && ownerableType === 'Partner') {
      setTimeout(() => {
        setValue('offerDelivery', true);
      }, 300);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register, ownerableType]);

  useEffect(() => {
    setTimeout(() => {
      reset(product);
      if (product && product.images && product.images?.length > 0) {
        setImages(product?.images?.map((img) => ({ id: img?.id, url: img.image.url })));
      }

      setInventories(product && product.inventories ? product.inventories : []);
      setPrivateChannels(
        product && product.partnersPrivateProductsChannels
          ? product.partnersPrivateProductsChannels
          : [],
      );
      if (product && !product.deliveryCountryId) {
        setValue('deliveryWorldWide', 1);
      }
      setValue(
        'storeDiscountCodeIds',
        product?.storeDiscountCodes?.map((x) => x.id),
      );
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  const deleteChannel = (ch) => {
    if (!ch.id) {
      setPrivateChannels((prev) => [...prev.filter((x) => x !== ch)]);
    } else {
      setPrivateChannels((prev) => [
        ...prev.map((x) => (x.id === ch.id ? { ...x, _destroy: true } : x)),
      ]);
    }
  };

  const addChannel = (ch) => {
    setPrivateChannels((prev) => [
      ...prev.filter((x) => x?.channelId !== ch?.id),
      { channelName: ch?.name, channelId: ch?.id },
    ]);
  };

  return {
    register,
    setValue,
    watch,
    reset,
    errors,
    onSubmit,
    handleSubmit,
    loading,
    categories,
    product,

    inventories,
    setInventories,

    images,
    setImages,
    taxJatCategories,
    loadingTaxJarCategories,
    taxByCounty: storeContext?.taxByCounty,
    privateChannels,
    setPrivateChannels,
    deleteChannel,
    addChannel,
  };
};
