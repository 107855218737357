import React, { createContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import Box from 'core/ui/Box';
import Paper from 'core/ui/Paper';
import { useDispatch, useSelector } from 'react-redux';
import { channelMembersActions, channelMembersSelectors } from 'modules/channelMembers';
import notifications from 'modules/notifications';
import { useTranslation } from 'react-i18next';
import LoaderSm from 'core/ui/LoaderSm';
import Typography from 'core/ui/Typography';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import { useParams } from 'react-router';
import useConfirmation from 'core/useConfirmation';
import { appActions } from 'modules/app';
import Toggle from 'core/ui/Toggle';
import Divider from 'core/ui/Divider';
import { membersTypes } from 'core/utils/consts';
import MIcon from 'core/ui/MIcon';
import TableMembers from './TableMembers';
import ModalMembershipInformation from './MemberInformation/Modal';
import ModalFormKey from './MemberInformation/FormKey/Modal';
import useMemberTypesFilter from './useMemberTypesFilter';
import { Form } from './Form';

export const MembersContext = createContext();

const Memberships = ({ channel }) => {
  const dispatch = useDispatch();
  const { request } = useActions();
  const { t } = useTranslation();
  const { confirmation } = useConfirmation();
  const channelMembers = useSelector((state) => channelMembersSelectors.getChannelMembers(state));
  const pagination = useSelector((state) =>
    channelMembersSelectors.getChannelMembersPagination(state),
  );
  const [modalMemberInformation, setModalMemberInformation] = useState({
    open: false,
  });
  const [modalEditKey, setModalFormKey] = useState({
    open: false,
  });
  const { filterTypes, toogleFilter, optionFilter } = useMemberTypesFilter();
  const [perPage, setPerPage] = React.useState(25);

  const { control, getValues } = useForm({
    defaultValues: {
      query: '',
      membershipId: null,
      sortBy: 'name',
      sortType: 'ASC',
      pausedMembers: '0',
    },
  });

  const search = (page, mode) => {
    const newFilters = getValues();

    const data = {
      ...newFilters,
      channelId: channel.id,
      page,
      perPage,
      membersTypes: filterTypes,
      exportFileName: `${channel?.id}-members`,
      mode,
    };
    if (mode === 'export') {
      request({
        action: Modules.newChannelMembers.actions.exportChannelMembers,
        data,
      });
      return;
    }
    dispatch(channelMembersActions.getChannelMembersStart(data));
  };

  const updateMemberMembershipsAndPayments = (userId) => {
    request({
      action: Modules.newChannelMembers.actions.getMemberships,
      data: {
        channelId: channel?.id,
        userId,
      },
      options: {
        onError: (ex) => {
          dispatch(notifications.error(ex));
        },
      },
    });

    request({
      action: Modules.newChannelMembers.actions.getMemberPayments,
      data: {
        userId,
        channelId: channel?.id,
        page: 1,
        perPage: 10,
      },
      options: {
        onError: (ex) => {
          dispatch(notifications.error(ex));
        },
      },
    });
  };

  useEffect(() => {
    if (channel && channel.id) {
      search(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel, perPage, filterTypes]);

  const { param1, defaultTag } = useParams();

  useEffect(() => {
    if (param1 && defaultTag === 'members') {
      setModalMemberInformation({
        userId: param1,
        open: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param1]);

  const changeMemberKey = (channelKey) => {
    if (channelKey.membershp && !channelKey.channelMembershipPlanId) {
      dispatch(notifications.warning(t('channel.members.add.validation.plan')));
      return false;
    }
    if (modalEditKey.mode === 'edit') {
      request({
        action: Modules.newChannelMembers.actions.updateMembership,
        data: { ...channelKey, channelId: channel.id },
        options: {
          onSuccess: () => {
            updateMemberMembershipsAndPayments(channelKey?.userId);
            setModalFormKey((prev) => ({ ...prev, open: false }));
            dispatch(notifications.success(t('channel.members.message.plan.saved')));
          },
          onError: (error) => {
            dispatch(notifications.error(error));
          },
        },
      });
    } else {
      request({
        action: Modules.newChannelMembers.actions.createMembership,
        data: { ...channelKey, channelId: channel.id },
        options: {
          onSuccess: () => {
            updateMemberMembershipsAndPayments(channelKey?.userId);
            setModalFormKey((prev) => ({ ...prev, open: false }));
            dispatch(notifications.success(t('channel.members.message.plan.saved')));
          },
          onError: (error) => {
            dispatch(notifications.error(error));
          },
        },
      });
    }
    return true;
  };

  const arrayMembers = channelMembers || [];

  const openMemberModal = (data) => {
    window.history.replaceState(
      null,
      'StreamFit',
      `/channel/manage/${channel?.id}/members/${data?.userId}`,
    );
    setModalMemberInformation(data);
  };

  const closeModalMember = () => {
    window.history.replaceState(null, 'StreamFit', `/channel/manage/${channel?.id}/members`);

    setModalMemberInformation({ open: false });
  };

  const removeUserFromChannel = (member) => {
    confirmation({
      description: 'Are you sure you want to delete this user from members?',
      yesClick: () => {
        dispatch(appActions.setLoading(true));
        request({
          action: Modules.newChannelMembers.actions.removeUserFromChannel,
          data: {
            userId: member?.id,
            channelId: channel?.id,
          },
          options: {
            onSuccess: () => {
              dispatch(notifications.success(t('channel.member.changed.success')));
              search(pagination?.currentPage || 1);
              dispatch(appActions.setLoading(false));
            },
            onError: () => {
              dispatch(appActions.setLoading(false));
            },
          },
        });
      },
    });
  };

  const changeLead = (member) => {
    confirmation({
      description: t('channel.member.confirmRemove'),
      yesClick: () => {
        dispatch(appActions.setLoading(true));
        request({
          action: Modules.newChannelMembers.actions.changeLeadToInactive,
          data: {
            userId: member?.id,
            channelId: channel?.id,
          },
          options: {
            onSuccess: () => {
              dispatch(notifications.success(t('channel.member.changed.success')));
              search(pagination?.currentPage || 1);
              dispatch(appActions.setLoading(false));
            },
            onError: () => {
              dispatch(appActions.setLoading(false));
            },
          },
        });
      },
    });
  };

  const inactiveMember = (member) => {
    confirmation({
      description: t('channel.member.confirmRemove'),
      yesClick: () => {
        dispatch(appActions.setLoading(true));
        request({
          action: Modules.newChannelMembers.actions.changeMemberToInactive,
          data: {
            userId: member?.id,
            channelId: channel?.id,
          },
          options: {
            onSuccess: () => {
              dispatch(notifications.success(t('channel.member.changed.success')));
              search(pagination?.currentPage || 1);
              dispatch(appActions.setLoading(false));
            },
            onError: (error) => {
              dispatch(notifications.error(error));
              dispatch(appActions.setLoading(false));
            },
          },
        });
      },
    });
  };

  return (
    <Box mt={2}>
      <Form control={control} channel={channel} search={search} />

      <Box display="flex" flexWrap="wrap" alignItems="center" className="tw-gap-2" mb={3}>
        <div className="tw-flex tw-flex-row tw-items-center tw-gap-2">
          <Box style={optionFilter(null)} onClick={() => toogleFilter(null)} className="hover">
            <Typography>{t(`all`)}</Typography>
          </Box>
          {membersTypes.map((x) => (
            <Box key={x} style={optionFilter(x)} onClick={() => toogleFilter(x)} className="hover">
              <Typography>{t(`channel.memberships.column.type.${x}`)}</Typography>
            </Box>
          ))}
        </div>
        <Divider orientation="vertical" flexItem my={1} mx={2} />
        <Box>
          <Controller
            control={control}
            name="pausedMembers"
            render={({ field }) => (
              <Toggle
                className="tw-rounded-full"
                value={field.value}
                onChange={() => field.onChange(field.value === '1' ? '0' : '1')}
                fontSize="medium"
                options={[{ value: '1', label: 'Paused' }]}
              />
            )}
          />
        </Box>
      </Box>

      <MembersContext.Provider value={{ setModalFormKey, modalEditKey }}>
        {modalMemberInformation.userId && (
          <ModalMembershipInformation
            open={modalMemberInformation.open}
            userId={modalMemberInformation.userId}
            close={() => closeModalMember()}
          />
        )}

        <Paper className="paper-rounded" px={3}>
          <Box>
            <LoadingIndicator />

            <TableMembers
              channel={channel}
              members={arrayMembers}
              setModalMemberInformation={openMemberModal}
              search={search}
              pagination={pagination}
              setPerPage={setPerPage}
              perPage={perPage}
              removeUserFromChannel={removeUserFromChannel}
              changeLead={changeLead}
              inactiveMember={inactiveMember}
            />

            <EmptyResults arrayMembers={arrayMembers} />

            {modalEditKey.open && (
              <ModalFormKey
                changeMemberKey={changeMemberKey}
                open={modalEditKey.open}
                mode={modalEditKey.mode}
                modeEdit={modalEditKey.modeEdit}
                channelKey={modalEditKey.channelKey}
                user={modalEditKey.user}
                close={() => setModalFormKey((prev) => ({ ...prev, open: false }))}
              />
            )}
          </Box>
        </Paper>
      </MembersContext.Provider>
    </Box>
  );
};

const LoadingIndicator = () => {
  const loadingMembers = useSelector(channelMembersSelectors.getLoading);
  if (!loadingMembers) {
    return <div />;
  }
  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <LoaderSm loading={loadingMembers} />
    </Box>
  );
};

const EmptyResults = ({ arrayMembers }) => {
  const { t } = useTranslation();
  const loadingMembers = useSelector(channelMembersSelectors.getLoading);
  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      {arrayMembers.length === 0 && !loadingMembers && (
        <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-bg-white tw-p-10">
          <MIcon icon="group" size={30} />
          <Typography className="tw-mt-2 tw-font-bold">{t('members.noResults')}</Typography>
        </div>
      )}
    </Box>
  );
};

Memberships.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  channel: PropTypes.object.isRequired,
};

Memberships.defaultProps = {};

export default Memberships;
