import React, { useState } from 'react';
import Box from 'core/ui/Box';
import BenchmarkList from 'components/Profile/Dashboard/Benchmark/List';
import { ButtonPrimary } from 'core/ui/Button';
import { useTranslation } from 'react-i18next';
import ModalFormBenchmark from 'components/Admin/Benchmarks/Form/Modal';
import Toggle from 'core/ui/Toggle';

export default ({ channel }) => {
  const [modal, setModal] = useState({ opened: false });
  const { t } = useTranslation();
  const onSelectedBenchmark = (item) => {
    setModal({ opened: true, benchmark: item });
  };
  const [currentFilter, setCurrentFilter] = useState(null);
  const [refresh, setRefresh] = useState(null);

  return (
    <Box p={5}>
      <ModalFormBenchmark
        channel={channel}
        modal={modal}
        close={() => setModal({ opened: false })}
        callback={() => {
          setModal({ opened: false });
          setRefresh((prev) => !prev);
        }}
      />

      <Box display="flex" alignItems="center" mb={2}>
        <Box flexGrow={1}>
          <Toggle
            options={[
              {
                label: 'All Benchmarks',
                value: null,
                width: 160,
              },
              {
                label: 'Stream Benchmarks',
                value: 'stream',
                width: 160,
              },
              {
                label: 'Gym Benchmarks',
                value: 'gym',
                width: 160,
              },
            ]}
            value={currentFilter}
            color="white"
            onChange={(value) => setCurrentFilter(value)}
            fontSize="large"
          />
        </Box>
        <ButtonPrimary style={{ width: 150 }} onClick={() => setModal({ opened: true })}>
          {t('button.create')}
        </ButtonPrimary>
      </Box>

      <BenchmarkList
        selectedBenchmark={modal.benchmark}
        setSelectedBenchmark={onSelectedBenchmark}
        rowsPerPage={20}
        hideTitle
        onEditButtonClick={(benchmark) => setModal({ opened: true, benchmark })}
        selector
        filter={currentFilter}
        channel={channel}
        refresh={refresh}
      />
    </Box>
  );
};
