import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
} from '@material-ui/core';

import Box from 'core/ui/Box';

import Typography from 'core/ui/Typography';
import RowTableRecurring from './RowTableRecurring';

const TableRecurrings = ({
  channel,
  recurrings,
  extendRecurring,
  editRecurring,
  setRefreshCalendar,
  checkedAll,
  toggleSelectAll,
  checkedItems,
  toggleItem,
}) => {
  const { t } = useTranslation();

  return (
    <Box>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography className="hover" color="primary" onClick={() => toggleSelectAll()}>
                  {t(checkedAll ? 'search.button.deselectAll' : 'search.button.selectAll')}
                </Typography>
              </TableCell>
              <TableCell>{t('recurring.table.recurring')}</TableCell>
              <TableCell />
              <TableCell align="center">{t('recurring.table.frequency')}</TableCell>
              <TableCell align="center">{t('recurring.table.autoRenew')}</TableCell>
              <TableCell align="center">{t('recurring.table.liveClasses')}</TableCell>
              <TableCell align="center">{t('recurring.table.status')}</TableCell>
              <TableCell />
              <TableCell />
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {recurrings.map((rec) => (
              <RowTableRecurring
                channel={channel}
                key={rec.id}
                rec={rec}
                extendRecurring={extendRecurring}
                editRecurring={editRecurring}
                setRefreshCalendar={setRefreshCalendar}
                checkedItems={checkedItems}
                toggleItem={toggleItem}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

TableRecurrings.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  recurrings: PropTypes.array,
  editRecurring: PropTypes.func.isRequired,
  extendRecurring: PropTypes.func.isRequired,
};

TableRecurrings.defaultProps = {
  recurrings: [],
};

export default TableRecurrings;
