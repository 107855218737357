import React, { useContext, useState, useEffect } from 'react';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import { ButtonPrimary } from 'core/ui/Button';
import Card from 'core/ui/Card';
import Video from 'core/ui/Video';
import Typography from 'core/ui/Typography';
import Record from 'core/ui/RecordVideo/Record';
import { ProfileContext } from 'pages/shared/Profile/Edit';
import ModalUploadProfileVideo from './ModalUploadProfileVideo';

const ProfileVideo = () => {
  const { t } = useTranslation();
  const formContext = useContext(ProfileContext);
  const [openModal, setOpenModal] = useState(false);
  const [openModalUpload, setOpenModalUpload] = useState(false);

  const video = formContext.watch('video');
  const profileVideoLink = formContext.watch('profileVideoLink');

  useEffect(() => {
    if (video) {
      formContext.setValue('profileVideoLink', null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [video]);

  useEffect(() => {
    if (profileVideoLink) {
      formContext.setValue('video', null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileVideoLink]);

  return (
    <Card btopcolor="#4fb94a" btopwidth={2} hidedivider="true" title={t('profile.video')}>
      {video || profileVideoLink ? (
        <Box className="container-aspect container-aspect-47">
          <Box className="item-aspect">
            <Video controls full src={profileVideoLink || (video && video.url)} />
          </Box>
        </Box>
      ) : (
        <Typography align="center">{t('workout.video.record')}</Typography>
      )}

      <Box display="flex" justifyContent="center" p={5}>
        <Box mr={3}>
          <ButtonPrimary fullWidth onClick={() => setOpenModalUpload(true)}>
            {t('button.uploadVideo')}
          </ButtonPrimary>
        </Box>
        <Box ml={3}>
          <ButtonPrimary fullWidth onClick={() => setOpenModal(true)}>
            {' '}
            {t('button.recordVideo')}
          </ButtonPrimary>
        </Box>
      </Box>
      <ModalUploadProfileVideo open={openModalUpload} close={() => setOpenModalUpload(false)} />

      <Record
        setOpen={setOpenModal}
        open={openModal}
        setVideo={(v) => formContext.setValue('video', v)}
      />
    </Card>
  );
};

export default ProfileVideo;
