/* eslint-disable react/prop-types */
import React from 'react';
import Paper from 'core/ui/Paper';
import { useTranslation } from 'react-i18next';
import { date, timeWithoutTz } from 'core/utils/formatters';
import Typography from 'core/ui/Typography';
import SFDataTable from 'core/ui/DataTable';
import { LockOpen } from '@material-ui/icons';
import { primary } from 'core/ui/Colors';
import Box from 'core/ui/Box';

export default function ResultTracks({ channel, logs }) {
  const { t } = useTranslation();
  const columns = [
    {
      name: 'User Name',
      selector: 'userName',
      sortable: true,
      cell: (row) => <Typography>{row.userName}</Typography>,
    },
    {
      name: 'Lock ID',
      selector: 'lockId',
      sortable: true,
      cell: (row) => <Typography>{row?.lockId}</Typography>,
    },

    {
      name: 'Date',
      selector: 'datetime',
      cell: (row) => (
        <Box display="flex" alignItems="center">
          <LockOpen style={{ color: primary }} />
          <Typography>
            {' '}
            {date(row?.datetime, channel?.dateFormatReact)}&nbsp;&bull;&nbsp;
            {timeWithoutTz(row.datetime)}
          </Typography>
        </Box>
      ),
      sortable: true,
      center: true,
    },
  ];

  const rows =
    logs &&
    logs.map((key) => ({
      id: key.id,
      userName: key.user && key.user.name,
      checkin: key.checkin,
      checkinNote: key.checkinNote,
      datetime: key.createdAt,
      lockId: key?.body?.lockId,
      path: key?.path,
    }));

  return (
    <Paper style={{ width: '100%' }}>
      <SFDataTable
        title={t('channel.reports.hybridTracks')}
        columns={columns}
        data={rows || []}
        pagination
        showExport
      />
    </Paper>
  );
}
