import api from 'core/api';

export const partnersProgramsChannelsModule = 'partnersProgramsChannels';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const actions = {
  create: {
    module: partnersProgramsChannelsModule,
    name: 'create',
    api: (data) => api.post(`/channels/${data?.channelId}/partners_programs_channels`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  update: {
    module: partnersProgramsChannelsModule,
    name: 'update',
    api: (data) =>
      api.put(`/channels/${data?.channelId}/partners_programs_channels/${data?.id}`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  getPartnersProgramsChannels: {
    module: partnersProgramsChannelsModule,
    name: 'getPartnersProgramsChannels',
    api: (params) =>
      api.get(`/channels/${params?.channelId}/partners_programs_channels`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  getPlansPartnersProgramsChannels: {
    module: partnersProgramsChannelsModule,
    name: 'getPlansPartnersProgramsChannels',
    api: (params) =>
      api.get(`/channels/${params?.channelId}/partners_programs_channels/plans`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  renewPartnersProgramsChannel: {
    module: partnersProgramsChannelsModule,
    name: 'renewPartnersProgramsChannel',
    api: (params) =>
      api.put(`/channels/${params?.channelId}/partners_programs_channels/renew`, params),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  getPartnersProgramsChannelWorkouts: {
    module: partnersProgramsChannelsModule,
    name: 'getPartnersProgramsChannelWorkouts',
    api: (params) =>
      api.get(`/channels/${params?.channelId}/partners_programs_channels/workouts`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: ['workouts'],
    },
    state: loadingStates,
  },
  cancel: {
    module: partnersProgramsChannelsModule,
    name: 'cancel',
    api: (params) =>
      api.delete(
        `/channels/${params?.channelId}/partners_programs_channels/${params?.partnersProgramId}`,
        { params },
      ),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },

  getMembershipPlansBlocks: {
    module: partnersProgramsChannelsModule,
    name: 'getMembershipPlansBlocks',
    api: (params) =>
      api.get(`/channels/${params?.channelId}/partners_programs_channels/membership_blocks`, {
        params,
      }),
    params: {
      start: ['params'],
      error: [''],
      success: ['membershipBlocks'],
    },
    state: loadingStates,
  },
  saveMembershipPlansBlocks: {
    module: partnersProgramsChannelsModule,
    name: 'saveMembershipPlansBlocks',
    api: (data) =>
      api.post(
        `/channels/${data?.channelId}/partners_programs_channels/save_membership_blocks`,
        data,
      ),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  clearWorkouts: {
    module: partnersProgramsChannelsModule,
    name: 'clearWorkouts',
    params: {
      success: ['workouts'],
    },
    state: loadingStates,
  },
};

export default {
  actions,
  state: {
    workouts: [],
    membershipBlocks: [],
    error: null,
    loading: false,
  },
};
