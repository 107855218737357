import React from 'react';
import Box from 'core/ui/Box';

import Calendar from './Calendar';

export default () => (
  <Box>
    <Calendar />
  </Box>
);
