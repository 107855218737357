import React, { useEffect, useState } from 'react';
import { ButtonPrimary } from 'core/ui/Button';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import Typography from 'core/ui/Typography';

import {
  TableContainer,
  TableHead,
  Table,
  TableCell,
  TableRow,
  TableBody,
  Paper,
  IconButton,
} from '@material-ui/core';
import { Add, Delete, Edit, People, PeopleAlt } from '@material-ui/icons';
import LoaderSm from 'core/ui/LoaderSm';
import useSelectors from 'modules/map/useSelectors';
import { groupsChatsModule } from 'modules/groupsChats';
import Modules from 'modules';
import useActions from 'modules/map/useActions';
import ModalFormGroupsChat from './Form/Modal';
import ModalUsers from './Form/ModalUsers';

const GroupsChats = ({ ownerableId, ownerableType, showTitle }) => {
  const { t } = useTranslation();
  const { request } = useActions();
  const loading = useSelectors(groupsChatsModule, 'loading');
  const groupsChats = useSelectors(groupsChatsModule, 'data');
  const [modalForm, setModalForm] = useState({
    open: false,
  });
  const [modalUsers, setModalUsers] = useState({
    open: false,
  });

  const getGroups = () => {
    request({
      action: Modules.groupsChats.actions.getGroupsChats,
      data: {
        ownerableId,
        ownerableType,
      },
    });
  };

  useEffect(() => {
    if (ownerableId) {
      getGroups();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request, ownerableId]);

  const openModal = () => {
    setModalForm((prev) => ({ ...prev, mode: 'new', open: true }));
  };

  useEffect(() => {
    if (groupsChats && modalUsers?.open) {
      setModalUsers((prev) => ({
        ...prev,
        item: groupsChats?.find((x) => x.id === modalUsers?.item?.id),
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupsChats]);

  const remove = (id) => {
    request({
      action: Modules.groupsChats.actions.destroy,
      data: {
        ownerableType,
        ownerableId,
        id,
      },
      options: {
        onSuccess: () => {
          getGroups();
        },
      },
    });
  };

  const addUserGroup = (user) => {
    request({
      action: Modules.groupsChatsUsers.actions.create,
      data: {
        ownerableType,
        ownerableId,
        userId: user?.id,
        groupsChatId: modalUsers?.item?.id,
      },
      options: {
        onSuccess: () => {
          getGroups();
        },
      },
    });
  };

  const updateUserGroup = (user, block = true) => {
    request({
      action: Modules.groupsChatsUsers.actions.update,
      data: {
        ownerableType,
        ownerableId,
        userId: user?.id,
        groupsChatId: modalUsers?.item?.id,
        blocked: block,
      },
      options: {
        onSuccess: () => {
          getGroups();
        },
      },
    });
  };

  const removeUserGroup = (user) => {
    if (!user?.id) {
      return;
    }
    request({
      action: Modules.groupsChatsUsers.actions.destroy,
      data: {
        ownerableType,
        ownerableId,
        userId: user?.id,
        groupsChatId: modalUsers?.item?.id,
      },
      options: {
        onSuccess: () => {
          getGroups();
        },
      },
    });
  };

  return (
    <Box p={3}>
      {modalForm.open && (
        <ModalFormGroupsChat
          ownerableId={ownerableId}
          ownerableType={ownerableType}
          open={modalForm.open}
          item={modalForm.item}
          mode={modalForm.mode}
          close={() => setModalForm((prev) => ({ ...prev, open: false, item: {} }))}
          callback={(nameList) =>
            setModalForm((prev) => ({
              ...prev,
              open: false,
              item: {},
              nameListExpand: nameList,
            }))
          }
        />
      )}
      {modalUsers.open && (
        <ModalUsers
          addUserGroup={addUserGroup}
          removeUserGroup={removeUserGroup}
          updateUserGroup={updateUserGroup}
          open={modalUsers.open}
          item={modalUsers.item}
          close={() => setModalUsers((prev) => ({ ...prev, open: false, item: {} }))}
        />
      )}

      <Box display="flex" alignItems="center">
        <Box flexGrow="1" display="flex" alignItems="center">
          {showTitle && (
            <Typography variant="h5" color="primary">
              {t('label.groupsChats')}
            </Typography>
          )}
          <LoaderSm loading={loading} />
        </Box>
        <Box>
          <ButtonPrimary onClick={() => openModal()}>
            <Box display="flex" alignItems="flex-end">
              <Add size="small" />
              <Box alignItems="center">
                <Typography component="span" mr={1}>
                  {t('button.add')}
                </Typography>
              </Box>
            </Box>
          </ButtonPrimary>
        </Box>
      </Box>
      <Box mt={2}>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>{t('label.name')}</TableCell>
                <TableCell>{t('label.private')}</TableCell>
                <TableCell align="center">
                  <People />
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {groupsChats &&
                groupsChats.map((item) => (
                  <TableRow key={item?.id} className="hover">
                    <TableCell>
                      <Typography component="p">{item.name}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography component="p">{t(`label.group.${item.accessType}`)}</Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography component="p">{item.usersCount}</Typography>
                    </TableCell>
                    <TableCell align="right">
                      <IconButton onClick={() => setModalUsers({ open: true, item })}>
                        <PeopleAlt />
                      </IconButton>
                      <IconButton onClick={() => setModalForm({ open: true, item })}>
                        <Edit />
                      </IconButton>
                      <IconButton onClick={() => remove(item?.id)}>
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default GroupsChats;
