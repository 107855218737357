import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import Typography from 'core/ui/Typography';
import ConfirmDialog from 'core/ui/ConfirmDialog';
import { Info } from '@material-ui/icons';
import { channelsSelectors } from 'modules/channels';
import { useSelector } from 'react-redux';
import Automated from 'components/Commmunication/Automated';
import menuItems from 'components/Channel/Manage/menuItems';
import Button from 'core/ui/Button';
import { useHistory, useParams } from 'react-router';
import { sessionSelectors } from 'modules/session';
import GroupsChats from 'components/GroupsChats';
import { AutomatedWorkflow } from 'components/AutomatedWorkflow';
import ContactForms from 'components/ContactForms';
import Store from './Tabs/Store/index';
import FormData from './FormData';
import Plans from './Tabs/Plans/New/index';
import Image from './Image';
import Coaches from './Tabs/Coaches/index';
import Lists from './Tabs/Lists';
import Reports from './Tabs/Reports';
import Memberships from './Tabs/Memberships/index';
import Members from './Tabs/Members';
import { ImportMembers } from './Tabs/Members/ImportMembers';
import Communication from './Tabs/Communication';
import useForm from './useForm';
import ClassTemplates from './Tabs/Library/ClassTemplates';
import ClassSchedule from './Tabs/Schedule';
import ClassHistory from './Tabs/Library/ClassHistory';
import ClassImport from './Tabs/Schedule/ClassImport';
import Announcements from './Tabs/Announcements';
import LibraryMedias from '../../LibraryMedias';
import CustomPrograms from './Tabs/Programming/Programs/index';
import PartnersPrograms from './Tabs/Programming/PartnersPrograms/index';
import RatesTemplates from './Tabs/Coaches/RatesTemplates';
import CoachClocks from './Tabs/Coaches/Clocks';
import MessageSettings from './Tabs/Communication/MessageSettings';
import Benchmarks from './Tabs/Benchmarks';
import DocumentsBuilder from '../../DocumentsBuilder';
import ChannelTags from './Tabs/Tags';
import CustomPayments from './Tabs/Coaches/CustomPayments';
import ChannelIntegrations from './Tabs/Integrations';
import ChannelDashboard from './Tabs/ChannelDashboard';
import ChannelStages from './Tabs/Stages';
import ChannelCoachesCalendar from './Tabs/Coaches/Calendars';

export const ChannelContext = createContext();

const Form = ({ mode, tab, setTab }) => {
  const history = useHistory();
  const { param1 } = useParams();
  const currentUser = useSelector((state) => sessionSelectors.getUser(state));

  const channel = useSelector((state) => channelsSelectors.getChannel(state));
  const { t } = useTranslation();
  const [confirmCancel, setConfirmCancel] = useState({ open: false });
  const form = useForm({ mode, tab, setTab });

  const findReport = (p) => {
    let item = null;
    // eslint-disable-next-line consistent-return
    menuItems.reportsMenus(channel, currentUser).forEach((x) => {
      if (!item) {
        item = x.items.find((y) => y.value.toLowerCase() === p?.toLowerCase());
        return item?.label;
      }
    });

    return item?.label;
  };

  useEffect(() => {
    if (
      mode === 'edit' &&
      channel &&
      channel.accessType &&
      tab === 'info' &&
      !['admin', 'admin_coach', 'owner'].find((x) => x === channel.accessType)
    ) {
      setTab('members');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel]);

  return (
    <ChannelContext.Provider
      value={{
        ...form,
        tab,
        setTab,
        mode,
        buttonColor: channel?.customColors?.buttonColor,
      }}
    >
      <ConfirmDialog
        open={confirmCancel.open}
        confirm={() => form.cancelChannel()}
        cancel={() => setConfirmCancel({ open: false })}
        description={t('button.cancel.channel.message')}
        labelConfirm={t('button.confirm')}
        labelCancel={t('button.cancel')}
      />
      <Box mb={10} className="tw-flex-1">
        {mode === 'edit' ? (
          <Box display="flex" alignItems="center">
            <Box flexGrow={1} pl={2} py={5}>
              <Typography variant="h3" color="secondary">
                {t(`channel.tab.${tab}`).toUpperCase()}
                {tab === 'reports' && (
                  <>
                    &nbsp;/&nbsp;
                    {findReport(param1)}
                  </>
                )}
              </Typography>
            </Box>
            {tab === 'info' && (
              <Box>
                <Button onClick={() => history.push(`/channel/view/${channel?.slug}`)}>View</Button>
              </Box>
            )}
          </Box>
        ) : (
          <Typography m={2} variant="h3" color="secondary">
            {t(`channel.newChannel`).toUpperCase()}
          </Typography>
        )}
        {(mode === 'new' || (form.permissions.formAccess && tab === 'info')) && <Image />}
        <>
          <Box
            className={
              (mode === 'edit' && !form.permissions.formAccess) || tab !== 'info' ? 'hide' : ''
            }
          >
            <form onSubmit={form.submit} autoComplete="off">
              <FormData />
            </form>
          </Box>
          {mode !== 'new' && (
            <>
              {form.permissions.dashboard && tab === 'dashboard' && (
                <ChannelDashboard setTab={setTab} channel={channel} />
              )}
              {form.permissions.planAccess && tab === 'plans' && <Plans setTab={setTab} />}
              {form.permissions.planAccess && tab === 'integrations' && (
                <ChannelIntegrations channel={channel} />
              )}
              {form.permissions.membershipsAccess && tab === 'memberships' && <Memberships />}
              {form.permissions.documentsAccess && tab === 'documents' && (
                <DocumentsBuilder
                  ownerableId={channel?.id}
                  ownerableType="Channel"
                  defaultDocumentWaiverId={channel.documentWaiverId}
                />
              )}
              {form.permissions.usersAccess && tab === 'users' && <Coaches />}
              {form.permissions.usersAccess && tab === 'coachesCalendar' && (
                <ChannelCoachesCalendar channel={channel} />
              )}
              {form.permissions.usersAccess && tab === 'payRates' && <RatesTemplates />}
              {form.permissions.usersAccess && tab === 'customPayments' && <CustomPayments />}
              {form.permissions.usersAccess && tab === 'timeClock' && (
                <CoachClocks channel={channel} />
              )}

              {form.permissions.membersAccess && tab === 'members' && <Members />}
              {form.permissions.membersAccess && tab === 'importMembers' && <ImportMembers />}

              {tab === 'playlists' && <Lists />}
              {form.permissions.reportsAccess && tab === 'reports' && <Reports />}
              {form.permissions.storeAccess && tab === 'store' && <Store channel={channel} />}

              {channel.planValid && form.permissions.scheduleAccess && tab === 'schedules' && (
                <ClassSchedule />
              )}
              {channel.planValid &&
                form.permissions.scheduleAccess &&
                !!menuItems.libraryMenus.find((x) => x === tab) && (
                  <>
                    {tab === 'templates' && <ClassTemplates />}
                    {tab === 'history' && <ClassHistory channel={channel} />}
                    {tab === 'importClasses' && <ClassImport />}
                    {tab === 'benchmarks' && <Benchmarks channel={channel} />}
                    {tab === 'tags' && <ChannelTags channel={channel} />}
                    {/* {tab === 'libraryMedia' && <LibraryVideos channel={channel} />} */}
                    {tab === 'movementLibrary' && (
                      <LibraryMedias ownerableType="Channel" ownerableId={channel?.id} />
                    )}
                  </>
                )}

              {tab === 'programmingPartners' && <PartnersPrograms />}
              {tab === 'programmingCustoms' && <CustomPrograms />}
              {tab === 'messages' && <Communication />}
              {tab === 'contactForms' && <ContactForms channel={channel} />}
              {tab === 'automatedWorkflow' && <AutomatedWorkflow channel={channel} />}
              {tab === 'stages' && <ChannelStages channel={channel} />}
              {tab === 'groupsChats' && channel.planValid && (
                <GroupsChats ownerableId={channel?.id} ownerableType="Channel" />
              )}
              {form.permissions.announcementsAccess && tab === 'announcements' && <Announcements />}
              {tab === 'automated' && <Automated channel={channel} />}
              {tab === 'messageSettings' && <MessageSettings channel={channel} />}
            </>
          )}
        </>

        {tab === 'info' && form.permissions.cancelAccess && channel.active === true && (
          <Box
            py={5}
            pb={8}
            className="link-hover"
            onClick={() => setConfirmCancel({ open: true })}
            display="flex"
            alignItems="center"
          >
            <Info color="primary" fontSize="small" />
            <Typography ml={2} color="primary">
              {t('button.cancel.channel')}{' '}
            </Typography>
          </Box>
        )}
      </Box>
    </ChannelContext.Provider>
  );
};

Form.propTypes = {
  mode: PropTypes.string,
};

Form.defaultProps = {
  mode: 'new',
};

export default Form;
