/* eslint-disable react/prop-types */
import Box from 'core/ui/Box';
import React from 'react';

import { useTranslation } from 'react-i18next';
import ByHourlyData from './ByHourlyData';

export default function ByHourly({ channel, byHourly }) {
  const { t } = useTranslation();
  return (
    <Box>
      <ByHourlyData
        title={t('channel.rateTemplate.kind.hourly')}
        channel={channel}
        byHourly={byHourly}
      />
    </Box>
  );
}
