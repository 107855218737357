import { channelsSelectors } from 'modules/channels';
import { useSelector } from 'react-redux';
import { primary, secondary } from './ui/Colors';

export default () => {
  const channel = useSelector((state) => channelsSelectors.getChannel(state));

  const customColors = (channel && channel.customColors) || {};
  const borderStyles = {};

  borderStyles.borderWidth = 0.8;
  borderStyles.borderColor = secondary;

  const buttonColor = (customColors && customColors.buttonColor) || primary;

  const buttonPrimaryStyle = {
    backgroundColor: buttonColor,
  };
  return {
    buttonColor,
    fontColor: (customColors && customColors.fontColor) || secondary,
    buttonPrimaryStyle,
    appLogo: channel?.appLogo?.image?.url,
  };
};
