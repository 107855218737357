import React, { createContext, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from 'core/ui/Typography';
import Checkbox from 'core/ui/Checkbox';
import { IconButton } from '@material-ui/core';
import { Add, Close } from '@material-ui/icons';
import TimePickerRounded from 'core/ui/DatePickers/TimePickerRounded';
import { CalendarsContext } from 'contexts';

export const SearchCompetitionContext = createContext();

const WeekDay = ({ weekDay, weeklyHours }) => {
  const calendarContext = useContext(CalendarsContext);

  const { t } = useTranslation();

  const dayTimes = (weeklyHours && weeklyHours[weekDay?.id]) || [];

  const checked = calendarContext?.currentCalendar?.weekDays?.find((x) => x === weekDay?.id);

  const editTimeIdx = ({ value, prop, idx }) => {
    calendarContext.setWeeklyHours((prev) => {
      const obj = prev;
      obj[weekDay?.id] = obj[weekDay?.id].map((x, indexObj) => {
        if (indexObj === idx) {
          return { ...x, [prop]: value };
        }

        return x;
      });
      return obj;
    });
  };

  const changeCheck = useCallback(
    (isChecked) => {
      if (isChecked) {
        calendarContext.setCurrentCalendar((prev) => ({
          ...prev,
          weekDays: prev.weekDays.filter((x) => x !== weekDay?.id),
        }));
      } else {
        calendarContext.setCurrentCalendar((prev) => ({
          ...prev,
          weekDays: [...(prev?.weekDays || []), weekDay?.id],
        }));
      }
    },
    [calendarContext, weekDay],
  );

  const addTime = useCallback(() => {
    if (!checked) {
      changeCheck(false);
    }

    calendarContext.setWeeklyHours((prev) => {
      const obj = { ...prev };
      obj[weekDay?.id] = [...(obj[weekDay?.id] || []), {}];
      return obj;
    });
  }, [calendarContext, weekDay, checked, changeCheck]);

  const removeTime = (timeRemove) => {
    calendarContext.setWeeklyHours((prev) => {
      const obj = { ...prev };
      if (!timeRemove?.id) {
        obj[weekDay?.id] = obj[weekDay?.id].filter((x) => x !== timeRemove);
      } else {
        obj[weekDay?.id] = obj[weekDay?.id].map((x) =>
          x === timeRemove ? { ...x, _destroy: true } : x,
        );
      }
      return obj;
    });
  };

  const validDayTimes = dayTimes?.filter((x) => !x._destroy);

  return (
    <div className="tw-flex tw-flex-row tw-items-center tw-gap-4 tw-p-2 tw-py-4">
      <div>
        <Checkbox checked={checked} onClick={() => changeCheck(checked)} />
      </div>
      <div className="tw-w-16">
        <Typography className="tw-text-base">
          {t(`abbrev2.${weekDay?.name}`).toUpperCase()}
        </Typography>
      </div>
      <div className="tw-flex tw-grow tw-flex-col tw-gap-2">
        {validDayTimes?.length <= 0 && (
          <div className="tw-text-base tw-text-gray-400">{t('label.unavailable')}</div>
        )}
        {validDayTimes.map((x, idx) => (
          <div key={x.startTime} className="tw-flex tw-flex-row tw-items-center tw-gap-2">
            <div>
              <TimePickerRounded
                bordered
                onChange={(v) => editTimeIdx({ prop: 'startTime', value: v, idx })}
                value={x?.startTime}
              />
            </div>
            <div>-</div>
            <div>
              <TimePickerRounded
                bordered
                onChange={(v) => editTimeIdx({ prop: 'endTime', value: v, idx })}
                value={x?.endTime}
              />
            </div>
            <div>
              <IconButton size="small" onClick={() => removeTime(x)}>
                <Close className="tw-text-base" />
              </IconButton>
            </div>
          </div>
        ))}
      </div>
      <div className="tw-flex tw-flex-row tw-gap-2">
        <IconButton size="small" onClick={addTime}>
          <Add className="tw-text-lg" />
        </IconButton>
        {/* <IconButton size="small">
          <FileCopy className="tw-text-lg" />
        </IconButton> */}
      </div>
    </div>
  );
};

export default WeekDay;
