import React, { useEffect, useState } from 'react';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import Typography from '@material-ui/core/Typography';
import { useSelector } from 'react-redux';
import { sessionWorkoutSelectors } from 'modules/sessionWorkout';
import { workoutsSelectors } from 'modules/workouts';
import { shortDateTime } from 'core/utils/formatters';
import { useTranslation } from 'react-i18next';
import { workoutEvent } from 'core/utils/consts';
import Scrollbars from 'react-custom-scrollbars';
import Join from './Events/Join';
import Recording from './Events/Recording';
import Event from './Events/Event';

export default function ClassTimeline() {
  const { t } = useTranslation();
  const [timeline, setTimeline] = useState([]);
  const workout = useSelector((state) => workoutsSelectors.getWorkout(state));
  const workoutEvents = useSelector((state) => sessionWorkoutSelectors.getEvents(state));

  const mountTimeline = () => {
    const events = workoutEvents.map((e) => ({
      date: e.createdAt,
      content: '',
      kind: e.kind,
      user: e.user,
      data: e.data,
    }));
    setTimeline(events);
  };

  useEffect(() => {
    mountTimeline();
  }, [workoutEvents]);

  return (
    <Scrollbars autoHeight>
      <Timeline align="alternate">
        <TimelineItem>
          <TimelineOppositeContent>
            <Typography color="textSecondary">{shortDateTime(workout.scheduledAt)}</Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Typography>{t('workout.event.startClass')}</Typography>
          </TimelineContent>
        </TimelineItem>
        {timeline.map((event, index) => (
          <React.Fragment key={index.toString()}>
            {event.kind === workoutEvent.join && <Join event={event} />}
            {event.kind === workoutEvent.change_section && <Event event={event} />}
            {(event.kind === workoutEvent.start_timer ||
              event.kind === workoutEvent.reset_timer) && <Event event={event} />}
            {(event.kind === workoutEvent.start_recording ||
              event.kind === workoutEvent.stop_recording) && <Recording event={event} />}
          </React.Fragment>
        ))}
      </Timeline>
    </Scrollbars>
  );
}
