import React from 'react';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import Grid from 'core/ui/Grid';
import { ButtonPrimary } from 'core/ui/Button';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import Paper from 'core/ui/Paper';
import LoaderSm from 'core/ui/LoaderSm';
import Typography from 'core/ui/Typography';
import { primary, redColor } from 'core/ui/Colors';
import MIcon from 'core/ui/MIcon';
import useForm from './useForm';

const FormChannelPaymentFess = ({ callback, channel }) => {
  const { t } = useTranslation();
  const { watch, setValue, errors, submitForm, loading, checkStatus, status, loadingStatus } =
    useForm({
      callback,
      channel,
    });

  return (
    <Box>
      <Grid container spacing={5}>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Box mb={4}>
            <TextFieldRounded
              placeholder="Template"
              label="CARDPOINTE TEMPLATE ID"
              value={watch('cardpointeTemplateId')}
              onChange={(v) => setValue('cardpointeTemplateId', v)}
              error={errors?.cardFee}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Box mb={4}>
            <TextFieldRounded
              placeholder="Cardpointe username"
              label="CARDPOINTE MID USERNAME"
              value={watch('cardpointeMerchantUser')}
              onChange={(v) => setValue('cardpointeMerchantUser', v)}
              error={errors?.cardFee}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Box mb={4}>
            <TextFieldRounded
              placeholder="****************"
              label="CARDPOINTE MID PWD"
              value={watch('cardpointeMerchantPwd')}
              onChange={(v) => setValue('cardpointeMerchantPwd', v)}
              error={errors?.cardFee}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={5}>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Box mb={4}>
            <TextFieldRounded
              placeholder="Ach merchant id"
              label="CARDPOINTE ACH MID"
              value={watch('cardpointeProcessingAchMid')}
              onChange={(v) => setValue('cardpointeProcessingAchMid', v)}
              error={errors?.cardFee}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Box mb={4}>
            <TextFieldRounded
              placeholder="Cardpointe ACH Username"
              label="CARDPOINTE ACH MID USERNAME"
              value={watch('cardpointeMerchantAchUser')}
              onChange={(v) => setValue('cardpointeMerchantAchUser', v)}
              error={errors?.cardFee}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Box mb={4}>
            <TextFieldRounded
              placeholder="****************"
              label="CARDPOINTE ACH MID PWD"
              value={watch('cardpointeMerchantAchPwd')}
              onChange={(v) => setValue('cardpointeMerchantAchPwd', v)}
              error={errors?.cardFee}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={5}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Box mb={4}>
            <TextFieldRounded
              placeholder="0.00"
              label={t('channel.fees.cardFeePercent').toUpperCase()}
              value={watch('cardFee')}
              onChange={(v) => setValue('cardFee', v)}
              error={errors?.cardFee}
              pattern="[1-9]*"
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Box mb={4}>
            <TextFieldRounded
              placeholder="0.00"
              label={t('channel.fees.cardFeeAmount').toUpperCase()}
              value={watch('cardAmount')}
              onChange={(v) => setValue('cardAmount', v)}
              error={errors?.cardAmount}
              pattern="[1-9]*"
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={5}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Box mb={4}>
            <TextFieldRounded
              placeholder="0.00"
              label={t('channel.fees.achFeePercent').toUpperCase()}
              value={watch('achFee')}
              onChange={(v) => setValue('achFee', v)}
              error={errors?.achFee}
              pattern="[1-9]*"
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Box mb={4}>
            <TextFieldRounded
              placeholder="0.00"
              label={t('channel.fees.achFeeAmount').toUpperCase()}
              value={watch('achAmount')}
              onChange={(v) => setValue('achAmount', v)}
              error={errors?.achAmount}
              pattern="[1-9]*"
            />
          </Box>
        </Grid>
      </Grid>

      <Box display="flex" alignItems="flex-end">
        <Paper p={3}>
          {status && (
            <>
              <Box mb={3} display="flex" alignItems="center" className="gap-10">
                <MIcon
                  icon={status?.card ? 'check' : 'close'}
                  color={status?.card ? primary : redColor}
                />
                <Typography>CARD CREDENTIALS</Typography>
              </Box>
              <Box mb={3} display="flex" alignItems="center" className="gap-10">
                <MIcon
                  icon={status?.ach ? 'check' : 'close'}
                  color={status?.ach ? primary : redColor}
                />
                <Typography>ACH CREDENTIALS</Typography>
              </Box>
            </>
          )}

          <ButtonPrimary onClick={checkStatus} disabled={loadingStatus}>
            <LoaderSm loading={loadingStatus} />
            CHECK CREDENTIALS
          </ButtonPrimary>
        </Paper>
      </Box>

      <Box flexGrow={1} display="flex" justifyContent="flex-end" my={5}>
        <ButtonPrimary disabled={loading} style={{ width: 150 }} onClick={() => submitForm()}>
          {t('button.save')}
        </ButtonPrimary>
      </Box>
    </Box>
  );
};

FormChannelPaymentFess.defaultProps = {
  callback: () => {},
  benchmark: null,
};

export default FormChannelPaymentFess;
