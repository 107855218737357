import React from 'react';
import Typography from 'core/ui/Typography';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import { primary } from 'core/ui/Colors';
import { ItemScore } from 'components/Workout/Show/Tabs/Overview/Leaderboard/WorkoutScores';

const SectionItemScores = ({ sectionScore, userScore, fontSize, scoresFontColor }) => {
  const { t } = useTranslation();
  const hasTimeCapped = !!Object.keys(sectionScore || {}).find(
    (x) => x === 'timeCapped' && !!sectionScore.timeCapped,
  );
  const scoresTypes = Object.keys(sectionScore || {})
    .filter((x) => !!sectionScore[x])
    .filter(
      (x) =>
        x !== 'tieBreakScore' &&
        x !== 'tieBreakDescription' &&
        x !== 'personalRecord' &&
        x !== 'timeCapped' &&
        x !== 'variation' &&
        x !== 'judgeId' &&
        x !== 'isPrivate' &&
        x !== 'name' &&
        x !== 'judgeName' &&
        x.indexOf('Notes') === -1 &&
        x.indexOf('type') === -1,
    );

  const mapScores = scoresTypes
    .filter((x) => !hasTimeCapped || x !== 'time')
    .map((scoreType) => ({
      label: t(`workout.section.label.score.${scoreType}`),
      userScores: (
        <ItemScore
          sectionScore={sectionScore}
          workoutScores={userScore}
          score={scoreType}
          fontColor={scoresFontColor}
          fontValue={{ fontSize: 16, fontWeight: 'bold', color: primary }}
          showNotes={false}
          showFullNotes={false}
          hideName
        />
      ),
    }));

  return (
    <Box display="flex" alignItems="center" flexWrap="wrap">
      {hasTimeCapped ? (
        <Typography style={{ fontSize, color: scoresFontColor }}>
          <Box display="flex" alignItems="center">
            For Time{' '}
            <ItemScore
              workoutScores={userScore}
              score="time"
              fontColor={scoresFontColor}
              fontValue={{ fontSize: 16, fontWeight: 'bold', color: primary }}
              showNotes={false}
              showFullNotes={false}
              hideName
            />{' '}
            (Time Cap&nbsp;
            {mapScores?.map((item, index) => (
              <Typography component="span" key={index}>
                <Box display="flex" alignItems="center">
                  {item?.label}&nbsp;
                  {item?.userScores}
                </Box>
              </Typography>
            ))}
            )
          </Box>
        </Typography>
      ) : (
        <Typography style={{ fontSize, color: scoresFontColor }}>
          {mapScores?.map((item, index) => (
            <Typography component="span" key={index}>
              <Box display="flex" alignItems="center">
                {item?.label}&nbsp;
                {item?.userScores}
              </Box>
            </Typography>
          ))}
        </Typography>
      )}
    </Box>
  );
};

export default SectionItemScores;
