import React, { useState } from 'react';
import Box from 'core/ui/Box';
import Typography, { fontStyle } from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import Paper from 'core/ui/Paper';
import Grid from 'core/ui/Grid';
import { ButtonPrimary } from 'core/ui/Button';
import { useDispatch } from 'react-redux';
import { Check } from '@material-ui/icons';
import { primary } from 'core/ui/Colors';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import notifications from 'modules/notifications';
import useSelectors from 'modules/map/useSelectors';
import LoaderSm from 'core/ui/LoaderSm';
import { adminChannelsModule } from 'modules/admin/channels';
import useConfirmation from 'core/useConfirmation';
import SelectFile from 'core/ui/SelectFile';
import SFDataTable from 'core/ui/DataTable';
import AdminChannelSelector from '../ChannelSelector';

export default function AdminImportCardpointe() {
  const { t } = useTranslation();
  const [selectedChannel, setSelectedChannel] = useState(null);
  const { request } = useActions();
  const loadingCardpointe = useSelectors(adminChannelsModule, 'loadingCardpointe');
  const dispatch = useDispatch();
  const { confirmation } = useConfirmation();

  const [callback, setCallback] = useState();
  const [data, setData] = useState();

  const confirmImport = () => {
    if (!selectedChannel?.id) {
      dispatch(notifications.warning('Select a channel.'));
      return;
    }

    const formData = new FormData();
    formData.append('file', data);
    confirmation({
      description: 'Are you sure?',
      yesClick: () => {
        request({
          action: Modules.adminChannels.actions.importCardpointe,
          data: { channelId: selectedChannel?.id, formData },
          options: {
            onSuccess: (resp) => {
              setCallback(resp);
            },
            onError: (error) => {
              dispatch(notifications.error(error));
            },
          },
        });
      },
    });
  };

  const checkImport = () => {
    const formData = new FormData();
    formData.append('file', data);
    formData.append('check_only', true);

    request({
      action: Modules.adminChannels.actions.importCardpointe,
      data: { channelId: selectedChannel?.id, formData },
      options: {
        onSuccess: (resp) => {
          setCallback(resp);
        },
        onError: (error) => {
          dispatch(notifications.error(error));
        },
      },
    });
  };

  const columns = [
    {
      name: 'ID',
      selector: 'userId',
      sortable: false,
      width: '80px',
      cell: (row) => <Typography style={fontStyle.small}>{row?.userId}</Typography>,
    },
    {
      name: 'Name',
      selector: 'userName',
      sortable: false,
      cell: (row) => <Typography style={fontStyle.small}>{row?.userName}</Typography>,
    },
    {
      name: 'Name',
      selector: 'userEmail',
      sortable: false,
      cell: (row) => <Typography style={fontStyle.small}>{row?.userEmail}</Typography>,
    },
    {
      name: 'Stripe CusId',
      selector: 'userName',
      sortable: false,
      cell: (row) => <Typography style={fontStyle.small}>{row?.stripeCustomerId}</Typography>,
    },
    {
      name: 'IN CSV',
      selector: 'exists',
      sortable: false,
      cell: (row) => <Typography style={fontStyle.small}>{row?.exists ? 'Yes' : 'No'}</Typography>,
    },
    {
      name: 'CSV Name',
      selector: 'rowName',
      sortable: false,
      cell: (row) => <Typography style={fontStyle.small}>{row?.rowName}</Typography>,
    },
    {
      name: 'CSV Email',
      selector: 'rowName',
      sortable: false,
      cell: (row) => <Typography style={fontStyle.small}>{row?.rowEmail}</Typography>,
    },
  ];

  return (
    <Box p={5}>
      <Grid container>
        <Grid item xs={12} md={12}>
          <Paper p={3}>
            <Typography variant="h5" align="center" mb={3}>
              Cardpointe Import
            </Typography>
            <AdminChannelSelector
              selectedChannel={selectedChannel}
              setSelectedChannel={setSelectedChannel}
            />

            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              p={5}
            >
              <SelectFile setFile={setData} file={data} />
              <Box>
                <Typography mt={1} style={fontStyle.small} align="center">
                  <b>Columns:</b> Email,Token,ProfileID,MerchID,AcctType,Expiry,DefaultAcct
                </Typography>
              </Box>
            </Box>

            {callback?.createdAccounts && (
              <Box display="flex" alignItems="center" mb={2}>
                <Check style={{ color: primary }} />
                <Typography variant="h6" mx={2}>
                  Imported Accounts: {callback?.createdAccounts}
                </Typography>
              </Box>
            )}
            <Box mt={3} display="flex" justifyContent="flex-end" alignItems="flex-end">
              <ButtonPrimary disabled={loadingCardpointe} onClick={() => checkImport()}>
                <LoaderSm loading={loadingCardpointe} />
                {t('button.checkImport')}
              </ButtonPrimary>
              <Box ml={2}>
                <ButtonPrimary disabled={loadingCardpointe} onClick={() => confirmImport()}>
                  <LoaderSm loading={loadingCardpointe} />
                  {t('button.import')}
                </ButtonPrimary>
              </Box>
            </Box>

            {callback?.membersStripeWithoutCp?.length > 0 && (
              <Box mt={2}>
                <SFDataTable
                  title={null}
                  columns={columns}
                  data={callback?.membersStripeWithoutCp}
                  pagination
                  showExport
                  noHeader
                  paginationRowsPerPageOptions={[5, 10, 15, 20, 30, 50, 100]}
                />
              </Box>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}
