/* eslint-disable no-use-before-define */
import React, { createContext } from 'react';
import NoSsr from '@material-ui/core/NoSsr';
import { InputBase } from '@material-ui/core';
import useChannelStyles from 'core/useChannelStyles';
import { useDispatch, useSelector } from 'react-redux';
import { searchActions, searchSelectors } from 'modules/search';
import { timeZoneName } from 'core/utils/formatters/date';
import Box from '../Box';
import FontIcon from '../FontIcon';
import { ButtonPrimary } from '../Button';
import { InputWrapper } from '../ChosenComponents';
import LoaderSm from '../LoaderSm';

export const SearchChosenContext = createContext();

export default function SearchChosen({
  model,
  val,
  setValue,
  handleSearch,
  placeholder,
  channelId,
}) {
  const { buttonColor } = useChannelStyles();
  const dispatch = useDispatch();
  const loading = useSelector((state) => searchSelectors.getLoading(state));

  const search = (values = null) => {
    if (model !== 'Competition' && !handleSearch) {
      dispatch(
        searchActions.getSearchStart({
          query: val,

          models: [model],
          timezone: timeZoneName(),
          upcoming: true,
          recents: true,
          role: 'coach',
          channel_id: channelId,
        }),
      );

      return;
    }

    handleSearch(values);
  };

  return (
    <NoSsr>
      <Box display="flex" alignItems="center">
        <InputWrapper>
          <Box display="flex" style={{ width: '100%' }}>
            <Box display="flex" flexGrow="1" flexWrap="wrap">
              <InputBase
                style={{ minWidth: 30, width: '100%', margin: '4px' }}
                className="fontInput"
                placeholder={placeholder}
                value={val}
                onChange={(e) => setValue(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    search();
                  }
                }}
              />
            </Box>

            <Box>
              <ButtonPrimary
                buttonColor={buttonColor}
                onClick={() => search()}
                style={{
                  height: '100%',
                  borderRadius: '5px',
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  padding: 10,
                }}
              >
                {!loading && <FontIcon iconName="icon-search" color="white" size={24} />}
                <LoaderSm width={24} loading={loading} />
              </ButtonPrimary>
            </Box>
          </Box>
        </InputWrapper>
      </Box>
    </NoSsr>
  );
}
