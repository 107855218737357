import { Link } from '@material-ui/core';
import Box from 'core/ui/Box';
import { secondaryBg } from 'core/ui/Colors';
import LoaderSm from 'core/ui/LoaderSm';
import MIcon from 'core/ui/MIcon';
import Paper from 'core/ui/Paper';
import Typography from 'core/ui/Typography';
import React from 'react';

function StatisticCard({
  colorBgIcon,
  label,
  loading,
  componentIcon,
  icon,
  iconColor,
  amount,
  bottomText,
  onClick,
  labelButton,
  component,
}) {
  return (
    <Paper>
      <Box p={3}>
        <Box
          px={2}
          style={{
            position: 'absolute',
            background: colorBgIcon || secondaryBg,
            borderRadius: 5,
            width: 40,
            height: 40,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          mt={-6}
        >
          {loading ? (
            <LoaderSm loading />
          ) : (
            <>{componentIcon || <MIcon icon={icon} color={iconColor || 'white'} />}</>
          )}
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <Typography component="h2" variant="h5" color="primary" gutterBottom>
            {label}
          </Typography>
        </Box>
        <Typography component="p" variant="h4">
          {amount}
        </Typography>
        <Typography align="center" color="textSecondary" style={{ flex: 1 }} component="div">
          {bottomText}
        </Typography>
        {onClick && (
          <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
            <Link color="primary" href="#" onClick={onClick}>
              {labelButton || 'View'}
            </Link>
          </Box>
        )}
      </Box>

      <Box>{component}</Box>
    </Paper>
  );
}

export default StatisticCard;
