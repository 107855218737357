import { colors } from '@material-ui/core';

export const eventName = (event) => {
  if (event.type === 'registration') {
    return event?.user?.name;
  }
  if (event.type === 'unavailable_time') {
    return 'Unavailable';
  }

  return 'Available';
};

export const eventColor = (event) => {
  if (event.type === 'registration') {
    if (event?.confirmed) {
      return colors.blue[50];
    }
    return colors.yellow[50];
  }
  if (event.type === 'unavailable_time') {
    return colors.red[50];
  }

  return colors.green[50];
};

export const eventTextColor = (event) => {
  if (event.type === 'registration') {
    return colors.blue[900];
  }
  if (event.type === 'unavailable_time') {
    return colors.red[900];
  }

  return colors.green[900];
};
