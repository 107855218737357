import React, { useContext, useEffect } from 'react';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { channelsSelectors } from 'modules/channels';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import Typography, { fontStyle } from 'core/ui/Typography';
import Checkbox from 'core/ui/Checkbox';
import { CompetitionContext } from '.';

const ChannelSelect = () => {
  const competitionContext = useContext(CompetitionContext);
  const { t } = useTranslation();
  const channels = useSelector((state) => channelsSelectors.getChannels(state));

  useEffect(() => {
    if (
      !competitionContext?.selectedChannel?.id &&
      channels?.length === 1 &&
      !competitionContext.competition?.id
    ) {
      competitionContext.setSelectedChannel(channels[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channels]);

  if (channels?.length === 0) {
    return <></>;
  }

  return (
    <Box item xs={12} sm={6} md={6} lg={6}>
      <Typography ml={1} mb={3} style={fontStyle.label}>
        {t('channel').toUpperCase()}
      </Typography>

      <SelectBoxObject
        emptyItem
        disabled={!!competitionContext.competition?.id}
        placeholder="Select a gym if this competition belong to a gym"
        value={competitionContext.selectedChannel && competitionContext.selectedChannel.id}
        setValue={(value) => {
          const ch = channels.find((x) => x.id === Number(value));
          competitionContext.setSelectedChannel(ch);
          if (ch) {
            competitionContext.setCurrency(ch?.currency);
          }
        }}
        options={channels}
      />

      <Box mt={2}>
        <Checkbox
          disabled={!!competitionContext.competition?.id}
          label={t('competiton.withoutGym')}
          checked={!competitionContext?.selectedChannel?.id}
          onClick={() =>
            competitionContext?.selectedChannel?.id
              ? competitionContext.setSelectedChannel(null)
              : competitionContext.setSelectedChannel(channels[0])
          }
        />
      </Box>
    </Box>
  );
};

export default ChannelSelect;
