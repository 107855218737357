import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import Box from 'core/ui/Box';
import Texture from 'core/ui/Texture';
import Form from './Form';

const ModalPartnerProgramChannel = ({
  open,
  partnersProgram,
  partnersProgramsChannel,
  close,
  callbackSave,
}) => (
  <Dialog open={open} onClose={close} maxWidth="sm" fullWidth scroll="body">
    <Texture>
      <Box p={5}>
        <Form
          open={open}
          partnersProgram={partnersProgram}
          partnersProgramsChannel={partnersProgramsChannel}
          close={close}
          callbackSave={callbackSave}
        />
      </Box>
    </Texture>
  </Dialog>
);

ModalPartnerProgramChannel.propTypes = {
  close: PropTypes.func,
};

ModalPartnerProgramChannel.defaultProps = {
  close: () => {},
};

export default ModalPartnerProgramChannel;
