import React, { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { Grid } from '@material-ui/core';
import { favoritesActions, favoritesSelectors } from 'modules/favorites';
import CardChannel from 'components/Search/Sections/Cards/CardChannel';
import Typography from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import { sessionSelectors } from 'modules/session';
import GridSkeleton from 'core/ui/GridSkeleton';
import Box from 'core/ui/Box';
import ScheduleItemClass from '../MyCalendar/Calendar/ScheduleItemClass';

const Favorite = () => {
  const dispatch = useDispatch();
  const favorites = useSelector((state) => favoritesSelectors.getFavorites(state));
  const loadingFavorites = useSelector((state) => favoritesSelectors.getLoading(state));
  const isAuthenticated = useSelector((state) => sessionSelectors.getIsAuthenticated(state));

  const { t } = useTranslation();
  useEffect(() => {
    if (isAuthenticated) {
      dispatch(favoritesActions.getFavoritesStart({ page: 1, perPage: 10 }));
    }
  }, [dispatch, isAuthenticated]);

  if (favorites?.length === 0) {
    return <></>;
  }

  return (
    <>
      {!loadingFavorites && favorites?.length === 0 && (
        <Typography align="center" m={5} variant="h5">
          {t('user.favorites.notExists')}
        </Typography>
      )}

      {loadingFavorites && <GridSkeleton />}
      {!loadingFavorites && (
        <Box mt={1}>
          {favorites.find((x) => !!x.workout) && (
            <Typography mb={1} color="primary" variant="h6">
              {t('title.workouts')}
            </Typography>
          )}
          <Grid container spacing={5} className="search-item">
            {favorites
              .filter((x) => !!x.workout)
              .map((favorite) => (
                <Grid item xs={12} sm={6} md={3} lg={3} key={favorite.workout.id} className="item">
                  <ScheduleItemClass
                    workout={favorite.workout}
                    datetime={favorite?.workout?.scheduledAt}
                  />
                </Grid>
              ))}
          </Grid>
        </Box>
      )}
      {!loadingFavorites && (
        <Box mt={1}>
          {favorites.find((x) => !!x.channel) && (
            <Typography mb={1} color="primary" variant="h6">
              {t('title.gyms')}
            </Typography>
          )}
          <Grid container spacing={5} className="search-item">
            {favorites
              .filter((x) => !!x.channel)
              .map((favorite) => (
                <Grid item xs={12} sm={6} md={3} lg={3} key={favorite.channel.id} className="item">
                  <CardChannel
                    key={favorite.channel.id}
                    channel={favorite.channel}
                    image={favorite.channel.image ? favorite.channel.image : {}}
                  />
                </Grid>
              ))}
          </Grid>
        </Box>
      )}
    </>
  );
};

export default Favorite;
