import { S3 } from 'aws-sdk';

import { ENV_APP } from 'core/env';
import { B_ID } from './consts';
import { mKY } from './helpers';

const B_K = mKY();

export const S3Client = new S3({
  accessKeyId: B_ID,
  secretAccessKey: B_K,
  region: 'us-east-2',
});

export const Bucket = 'stream-fitness-app-uploads';

export const makeKey = (path) => `uploads/${ENV_APP}/${path}`;
