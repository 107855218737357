import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from 'core/ui/Dialog/DialogTitle';
import Texture from 'core/ui/Texture';
import Modules from 'modules';
import Box from 'core/ui/Box';
import Paper from 'core/ui/Paper';
import Typography, { fontStyle } from 'core/ui/Typography';
import { ButtonPrimary } from 'core/ui/Button';
import LoaderSm from 'core/ui/LoaderSm';
import useActions from 'modules/map/useActions';
import Radio from 'core/ui/Radio';
import { PaymentOfflineTypes } from 'core/utils/consts';
import { newPaymentsModule } from 'modules/payments/new';
import useSelectors from 'modules/map/useSelectors';

export default function ModalChangeType({ open, close, payment, callback }) {
  const { t } = useTranslation();
  const { request } = useActions();
  const loading = useSelectors(newPaymentsModule, 'loading');
  const [type, setType] = useState();

  useEffect(() => {
    setType(payment?.offlineType);
  }, [payment]);

  const confirm = () => {
    request({
      action: Modules.newPayments.actions.update,
      data: {
        id: payment?.id,
        offlineType: type,
      },
      options: {
        onSuccess: () => {
          if (callback) {
            callback();
          }
        },
      },
    });
  };

  return (
    <div>
      <Dialog maxWidth="sm" scroll="body" onClose={() => close()} open={open} fullWidth>
        <Texture>
          <DialogTitle onClose={() => close()}>{t('payment.retry')}</DialogTitle>
          <Box p={5}>
            <Paper p={5} className="paper-rounded">
              <Box m={3}>
                <Typography mb={2} style={fontStyle.label}>
                  {t('channel.placeholder.offlinePayments.offlineType').toUpperCase()}
                </Typography>
                <Box display="flex">
                  {Object.keys(PaymentOfflineTypes).map((item) => (
                    <Radio
                      key={item}
                      checked={type === item}
                      label={t(`channel.placeholder.offlinePayments.${item}`)}
                      onClick={() => setType(item)}
                      ml={2}
                    />
                  ))}
                </Box>
              </Box>
            </Paper>

            <Box flexGrow={1} px={5}>
              <ButtonPrimary disabled={loading} fullWidth onClick={() => confirm()}>
                <LoaderSm loading={loading} />
                {t('button.confirm')}
              </ButtonPrimary>
            </Box>
          </Box>
        </Texture>
      </Dialog>
    </div>
  );
}

ModalChangeType.defaultProps = {
  open: false,
  close: null,
  userId: null,
};
