import React, { useContext, useEffect, useState } from 'react';
import { InputBase, makeStyles } from '@material-ui/core';
import Box from 'core/ui/Box';
import { InputWrapper } from 'core/ui/ChosenComponents';
import { useTranslation } from 'react-i18next';
import Paper from 'core/ui/Paper';
import Typography, { fontStyle } from 'core/ui/Typography';
import SFDataTable from 'core/ui/DataTable';
import { primary, secondaryXLight, successColor } from 'core/ui/Colors';
import LoaderSm from 'core/ui/LoaderSm';
import { ButtonPrimary, ButtonPrimarySm } from 'core/ui/Button';
import { LayoutContext } from 'pages/shared/Layout';
import Modules from 'modules';
import useActions from 'modules/map/useActions';
import useSelectors from 'modules/map/useSelectors';
import { personalRecordMovementsModule } from 'modules/personalRecordMovements';
import ModalImportPr from '../Imports/Pr/Modal';
import PrScore from './PrScore';

const useStyles = makeStyles(() => ({
  rowHover: {
    width: '100%',
    paddingTop: 8,
    paddingBottom: 8,
    paddingRight: 5,
    paddingLeft: 5,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: secondaryXLight,
    },
  },
}));

const PersonalRecordList = ({
  selectedPr,
  setSelectedPr,
  scoreType,
  isSelector,
  rowsPerPage,
  showInsertButton,
  onUsersButtonClick,
  onEditButtonClick,
}) => {
  const { t } = useTranslation();
  const { request } = useActions();
  const [text, setText] = useState('');
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const layoutContext = useContext(LayoutContext);
  const pagination = useSelectors(personalRecordMovementsModule, 'pagination');
  const loading = useSelectors(personalRecordMovementsModule, 'loading');
  const data = useSelectors(personalRecordMovementsModule, 'data');
  const classes = useStyles();

  const getMovements = (p) => {
    request({
      action: Modules.personalRecordMovements.actions.getPrMovements,
      data: { isSelector, scoreType, page: p, perPage, text },
    });
  };

  useEffect(() => {
    getMovements(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage]);

  useEffect(() => {
    setPage(1);
    getMovements(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text]);

  useEffect(() => {
    if (selectedPr) {
      setSelectedPr(data.find((x) => x.id === selectedPr.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const columns = [
    {
      cell: (row) => (
        <Box
          display="flex"
          className={classes.rowHover}
          onClick={() => (showInsertButton ? null : setSelectedPr(row))}
        >
          <Box flexGrow={1}>
            <Typography style={fontStyle.medium}>{row.movement && row.movement.name}</Typography>
          </Box>
          <Box>
            {showInsertButton && (
              <ButtonPrimarySm
                style={{ backgroundColor: selectedPr === row ? successColor : primary }}
                onClick={() => setSelectedPr(row)}
              >
                {t(selectedPr === row ? 'button.selected' : 'button.select')}
              </ButtonPrimarySm>
            )}
            {row.bestPr && <PrScore scores={row?.bestPr?.scores} scoreType={row.scoreType} />}
          </Box>
        </Box>
      ),
    },
  ];

  if (onEditButtonClick) {
    columns.push({
      width: '100px',
      cell: (row) => (
        <ButtonPrimary onClick={() => onEditButtonClick(row)}>{t('button.edit')}</ButtonPrimary>
      ),
    });
  }

  if (onUsersButtonClick) {
    columns.push({
      width: '100px',
      cell: (row) => (
        <ButtonPrimary onClick={() => onUsersButtonClick(row.id)}>
          {t('button.users')}
        </ButtonPrimary>
      ),
    });
  }

  const rows = data;

  return (
    <Box>
      <Box>
        <Box display="flex" alignItems="center">
          <InputWrapper>
            <Box display="flex" style={{ width: '100%' }}>
              <InputBase
                value={text}
                onChange={(v) => setText(v.target.value)}
                style={{ minWidth: 30, width: '100%', margin: '4px' }}
                placeholder={t('dashboard.prs.searchMovements')}
              />
            </Box>
          </InputWrapper>

          {!isSelector && (
            <Box ml={1}>
              <ModalImportPr refresh={getMovements} />
            </Box>
          )}
        </Box>
      </Box>
      <Paper className="paper-rounded" mt={1} py={3}>
        <Box display="flex" alignItems="center" mb={2}>
          <Typography variant="h4" color="secondary" mx={3}>
            {t('dashboard.prs.movements')}
          </Typography>
          <LoaderSm loading={loading} />
        </Box>

        <Box>
          <SFDataTable
            noHeader
            noTableHead
            title=""
            showPaper={false}
            columns={columns}
            data={rows}
            pagination
            paginationServer
            paginationPerPage={rowsPerPage || 10}
            paginationTotalRows={pagination?.totalCount}
            onChangePage={(p) => setPage(p)}
            onChangeRowsPerPage={(newValue) => {
              setPage(1);
              setPerPage(newValue);
            }}
            noDataComponent=""
          />
        </Box>

        {!!text && rows?.length === 0 && (
          <Box display="flex" alignItems="center" justifyContent="center" m={5}>
            <Typography>{t('personalRecord.notFound.request')}</Typography>
            <Typography
              ml={1}
              color="primary"
              className="hover"
              onClick={() =>
                layoutContext.setModalSupport((prev) => ({
                  ...prev,
                  open: true,
                  requestPR: true,
                }))
              }
            >
              {t('button.requestPR')}
            </Typography>
          </Box>
        )}
      </Paper>
    </Box>
  );
};

export default PersonalRecordList;
