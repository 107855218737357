import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import Texture from 'core/ui/Texture';
import {
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TableFooter,
  TablePagination,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { channelMembersActions, channelMembersSelectors } from 'modules/channelMembers';
import TablePaginationActions from '@material-ui/core/TablePagination/TablePaginationActions';
import Paper from 'core/ui/Paper';
import LoaderSm from 'core/ui/LoaderSm';
import Typography from 'core/ui/Typography';
import { secondaryXLight } from 'core/ui/Colors';
import ModalWaitlist from 'components/Purchase/ModalWaitlist';
import WorkoutRow from './WorkoutRow';
import PaymentAddMember from './PaymentAddMember';

const ModalClassesToRegister = ({
  channelId,
  workoutToAdd,
  user,
  open,
  close,
  callback,
  buttonColor,
}) => {
  const channelMemberWorkouts = useSelector((state) =>
    channelMembersSelectors.getChannelMemberWorkouts(state),
  );
  const loadingWorkouts = useSelector((state) => channelMembersSelectors.getLoadingWorkouts(state));
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [limitClass, setLimitClass] = React.useState({ open: false });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const currentWorkoutId = workoutToAdd ? workoutToAdd.id : null;

  useEffect(() => {
    dispatch(channelMembersActions.getAvailableClassesStart(channelId, user.id, currentWorkoutId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workoutToAdd?.id]);

  const onRegisterClick = (type, workoutId) => {
    dispatch(
      channelMembersActions.memberRegisterClassStart(
        { channelId, userId: user.id, workoutId, type },
        () => {
          if (callback) {
            callback();
          }
        },
        (error) => {
          if (error && error.indexOf('The class limit has been reached') > -1) {
            setLimitClass({ open: true });
          }
        },
      ),
    );
  };

  const isAddMemberToWorkout = !!currentWorkoutId;

  const currentWorkout = isAddMemberToWorkout
    ? channelMemberWorkouts.find((x) => !!currentWorkoutId && x.id === Number(currentWorkoutId))
    : null;

  const showPayment = !loadingWorkouts && isAddMemberToWorkout && !currentWorkout;

  return (
    <Dialog open={open} onClose={close} maxWidth={showPayment ? 'sm' : 'md'} fullWidth>
      <Texture>
        {loadingWorkouts ? (
          <Box display="flex" justifyContent="center" p={50}>
            <LoaderSm loading={loadingWorkouts} />
          </Box>
        ) : (
          <Box p={5}>
            {limitClass.open ? (
              <ModalWaitlist
                userId={user?.id}
                open
                close={() => {
                  setLimitClass({ open: false });
                  close();
                }}
                closePurchase={() => {
                  if (callback) {
                    callback();
                  }
                }}
              />
            ) : (
              <>
                {showPayment && (
                  <PaymentAddMember
                    close={() => close()}
                    workout={workoutToAdd}
                    member={user}
                    callback={callback}
                  />
                )}

                {!showPayment && (
                  <Paper m={3} p={3}>
                    {isAddMemberToWorkout && currentWorkout && (
                      <Table size="small">
                        <TableBody>
                          <>
                            <WorkoutRow
                              style={{
                                backgroundColor: secondaryXLight,
                                border: '1px solod #4fb94a',
                              }}
                              workout={currentWorkout}
                              purchase={(type, workoutId) => onRegisterClick(type, workoutId)}
                              buttonColor={buttonColor}
                            />
                          </>
                        </TableBody>
                      </Table>
                    )}

                    {!isAddMemberToWorkout && channelMemberWorkouts && (
                      <>
                        {!loadingWorkouts &&
                          channelMemberWorkouts &&
                          channelMemberWorkouts.length === 0 && (
                            <Typography m={5} variant="h4">
                              {t('user.no.upcomingAvailableClasses')}
                            </Typography>
                          )}

                        <TableContainer>
                          <Table size="small">
                            <TableBody>
                              {(rowsPerPage > 0
                                ? channelMemberWorkouts.slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage,
                                  )
                                : channelMemberWorkouts
                              )
                                .filter(
                                  (x) => !currentWorkoutId || x.id !== Number(currentWorkoutId),
                                )
                                .map((w, index) => (
                                  <WorkoutRow
                                    workout={w}
                                    key={index.toString()}
                                    purchase={(type, workoutId) => onRegisterClick(type, workoutId)}
                                    buttonColor={buttonColor}
                                  />
                                ))}
                            </TableBody>
                            <TableFooter>
                              <TableRow>
                                <TablePagination
                                  rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                  colSpan={3}
                                  count={channelMemberWorkouts.length}
                                  rowsPerPage={rowsPerPage}
                                  page={page}
                                  labelRowsPerPage={t('rowsPerPage')}
                                  SelectProps={{
                                    inputProps: { 'aria-label': 'rows per page' },
                                    native: true,
                                  }}
                                  onChangePage={handleChangePage}
                                  onChangeRowsPerPage={handleChangeRowsPerPage}
                                  ActionsComponent={TablePaginationActions}
                                />
                              </TableRow>
                            </TableFooter>
                          </Table>
                        </TableContainer>
                      </>
                    )}
                  </Paper>
                )}
              </>
            )}
          </Box>
        )}
      </Texture>
    </Dialog>
  );
};

ModalClassesToRegister.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  workoutToAdd: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.object,
  open: PropTypes.bool,
  close: PropTypes.func,
};

ModalClassesToRegister.defaultProps = {
  user: {},
  workoutToAdd: null,
  open: false,
  close: () => {},
};

export default ModalClassesToRegister;
