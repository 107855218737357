/* eslint-disable camelcase */
import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import Typography from 'core/ui/Typography';
import { Avatar } from '@material-ui/core';
import LocationMapView from 'core/ui/LocationMapView';

const MapsLocation = ({ formContext }) => {
  const { t } = useTranslation();

  const { location, setLocation } = formContext;

  return (
    <Box py={3}>
      <Box display="flex" alignItems="center">
        <Box flexGrow="1">
          <Typography variant="h5" mb={3} color="primary">
            {t('competition.location')}
          </Typography>
        </Box>
      </Box>

      <LocationMapView
        setLocation={setLocation}
        location={location}
        avatar={<Avatar src={formContext?.selectedChannel?.image?.image?.url} />}
      />
    </Box>
  );
};

MapsLocation.defaultProps = {};

MapsLocation.propTypes = {};

export default MapsLocation;
