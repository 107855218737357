import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import { KeyboardArrowRight, FirstPage, LastPage, KeyboardArrowLeft } from '@material-ui/icons';
import { channelMembersSelectors } from 'modules/channelMembers';
import { useSelector } from 'react-redux';
import LoaderSm from '../LoaderSm';

const useStyles = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

export default function TablePaginationActions(props) {
  const classes = useStyles();
  const loading = useSelector((state) => channelMembersSelectors.getLoading(state));

  const { count, page, rowsPerPage, onPageChange } = props;
  const [loader, setLoader] = useState('');
  const handleFirstPageButtonClick = (event) => {
    setLoader('first');
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    setLoader('back');
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    setLoader('next');
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    setLoader('last');
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {loading && loader === 'first' ? <LoaderSm loading /> : <FirstPage />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {loading && loader === 'back' ? <LoaderSm loading /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {loading && loader === 'next' ? <LoaderSm loading /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {loading && loader === 'last' ? <LoaderSm loading /> : <LastPage />}
      </IconButton>
    </div>
  );
}
