import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography, { fontStyle } from 'core/ui/Typography';
import { time } from 'core/utils/formatters';
import { useTranslation } from 'react-i18next';
import { primary } from 'core/ui/Colors';
import Box from 'core/ui/Box';
import { dateExt } from 'core/utils/formatters/date';
import { TableRow, TableCell } from '@material-ui/core';
import ButtonsRegister from './ButtonsRegister';

const WorkoutRow = ({ style, workout, purchase, buttonColor }) => {
  const useStyles = makeStyles({
    root: {
      maxWidth: 250,
      minWidth: 200,
      backgroundColor: 'transparent',
    },
    difficulty: {
      fontFamily: 'Roboto',
      fontSize: 14,
    },
    dayFont: {
      fontFamily: 'Roboto',
      fontWeight: 700,
    },
    timeFont: {
      fontFamily: 'Roboto',
    },
    boxTagRight: {
      background: buttonColor || primary,
      position: 'relative',
      bottom: 0,
      right: 0,
      zIndex: 1,
      width: 90,
    },
    boxContent: {
      backgroundColor: '#404041',
      color: 'white',
      borderBottomLeftRadius: '5px',
      borderBottomRightRadius: '5px',
      padding: 5,
      marginTop: -1,
      zIndex: 2,
      minHeight: 80,
    },
    listAdded: {
      background: '#1ed760',
      position: 'absolute',
      borderTopRightRadius: '5px',
      borderBottomLeftRadius: '5px',
      top: 0,
      padding: 3,
      right: 0,
      zIndex: 1,
    },
  });
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <TableRow style={style}>
      <TableCell>
        <Typography noWrap variant="body2" style={{ textOverflow: 'ellipsis' }} component="h5">
          {workout.name || (workout.firstType && workout.firstType.name)}
        </Typography>
      </TableCell>

      <TableCell>
        <Box>
          {!workout.isCompetition &&
            !workout.recorded &&
            workout.online &&
            workout.method === 'live' && (
              <Box className={classes.boxTagRight} m={1}>
                <Typography align="center" mx={1} style={{ color: 'white', ...fontStyle.small }}>
                  {t('online').toUpperCase()}
                </Typography>
              </Box>
            )}

          {!workout.isCompetition &&
            !workout.recorded &&
            workout.inPerson &&
            workout.method === 'live' && (
              <Box className={classes.boxTagRight} m={1}>
                <Typography align="center" mx={1} style={{ color: 'white', ...fontStyle.small }}>
                  {t('in-person').toUpperCase()}
                </Typography>
              </Box>
            )}

          {!workout.isCompetition && workout.recorded && (
            <Box className={classes.boxTagRight}>
              <Typography align="center" mx={1} style={{ color: 'white', ...fontStyle.small }}>
                {t('on-demand').toUpperCase()}
              </Typography>
            </Box>
          )}

          {!workout.isCompetition && !workout.recorded && workout.method === 'video' && (
            <Box className={classes.boxTagRight}>
              <Typography align="center" mx={1} style={{ color: 'white', ...fontStyle.small }}>
                {t('on-demand').toUpperCase()}
              </Typography>
            </Box>
          )}
        </Box>

        {!workout.recorded && (
          <>
            <Typography className={classes.dayFont} variant="caption" component="span">
              {dateExt(
                workout.scheduledAt,
                t,
                workout?.channel?.useUserTimezone ? null : workout?.timezone,
              )}{' '}
              &bull;
            </Typography>
            <Typography className={classes.timeFont} variant="caption" component="span" ml={1}>
              {time(
                workout.scheduledAt,
                workout?.channel?.useUserTimezone ? workout?.timezone : null,
              )}
            </Typography>
          </>
        )}
        {workout.recorded && workout.filterDifficulty && workout.filterDifficulty.name && (
          <Typography className={classes.dayFont} variant="caption" component="span">
            {t('workout.difficulty')}:&nbsp;
            {workout.filterDifficulty && workout.filterDifficulty.name}
          </Typography>
        )}

        <Typography>
          {Math.round(workout.durationMinutes)}&nbsp;{t('workout.duration.mins')}
        </Typography>
      </TableCell>
      <TableCell>
        <ButtonsRegister
          workout={workout}
          purchase={(type, workoutId) => purchase(type, workoutId)}
          buttonColor={buttonColor}
        />
      </TableCell>
    </TableRow>
  );
};

export default WorkoutRow;

WorkoutRow.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  workout: PropTypes.object,
  purchase: PropTypes.func,
};

WorkoutRow.defaultProps = {
  workout: {},
  purchase: () => {},
};
