/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Paper from 'core/ui/Paper';
import FormOptionYesNo from './FormOptionYesNo';

const FormNotify = ({ currentPlan, setCurrentPlan }) => {
  const { t } = useTranslation();

  return (
    <Paper className="paper-rounded" p={3}>
      <FormOptionYesNo
        t={t}
        currentPlan={currentPlan}
        setCurrentPlan={setCurrentPlan}
        label={t('channel.memberships.notifyUse').toUpperCase()}
        prop="notifyUse"
      />
    </Paper>
  );
};

FormNotify.defaultProps = {
  currentPlan: {},
  setCurrentPlan: () => {},
};
FormNotify.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  currentPlan: PropTypes.object,
  setCurrentPlan: PropTypes.func,
};

export default FormNotify;
