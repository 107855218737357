import React from 'react';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import Typography, { fontStyle } from 'core/ui/Typography';
import Grid from 'core/ui/Grid';

import { ButtonPrimary } from 'core/ui/Button';
import MovementChosen from 'core/ui/MovimentChosen';
import Checkbox from 'core/ui/Checkbox';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import { prScoreTypes } from 'core/utils/consts';
import Radio from 'core/ui/Radio';
import useForm from './useForm';

const FormBenchmark = ({ callback, personalRecord }) => {
  const { t } = useTranslation();
  const { watch, setValue, errors, submitForm, loading } = useForm({
    callback,
    personalRecord,
  });

  return (
    <Box>
      <Box mb={3}>
        <Typography mb={2} ml={2} style={fontStyle.label} variant="body1">
          {t('personalRecord.label.movement').toUpperCase()}
        </Typography>

        <MovementChosen
          val={watch('movement') ? [...watch('movement')] : []}
          setValue={(values) => setValue('movement', values)}
          kind={null}
          error={errors.movement}
          limit={1}
        />
      </Box>

      <Box mb={3}>
        <TextFieldRounded
          placeholder={t('personalRecord.label.videoLink')}
          label={t('personalRecord.label.videoLink').toUpperCase()}
          value={watch('videoLink') || ''}
          onChange={(v) => setValue('videoLink', v)}
          error={errors.videoLink}
        />
      </Box>

      <Grid container spacing={3} m={1}>
        <Grid item xs={12} md={6}>
          <Box>
            <Typography mb={3} style={fontStyle.label} variant="body1">
              {t('personalRecord.label.scoreType').toUpperCase()}
            </Typography>

            {prScoreTypes.map((type, index) => (
              <Box key={index.toString()}>
                <Radio
                  checked={type.id === watch('scoreType')}
                  onClick={() => setValue('scoreType', type.id)}
                  label={type.name}
                />
              </Box>
            ))}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>
            <Typography style={fontStyle.label} mb={1} variant="body1">
              {t('benchmark.label.active').toUpperCase()}
            </Typography>
            <Box>
              <Checkbox
                checked={watch('active')}
                onClick={() => setValue('active', !watch('active'))}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Box flexGrow={1} display="flex" justifyContent="flex-end" my={5}>
        <ButtonPrimary disabled={loading} style={{ width: 150 }} onClick={() => submitForm()}>
          {t('button.save')}
        </ButtonPrimary>
      </Box>
    </Box>
  );
};

FormBenchmark.defaultProps = {
  callback: () => {},
  benchmark: null,
};

export default FormBenchmark;
