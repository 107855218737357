import React from 'react';
import PropTypes from 'prop-types';
import Box from '../Box';
import Typography from '../Typography';
import { primary, secondary } from '../Colors';

export default function LabelInfo({ label, labelBackgroud, value, width, fontSize, orientation }) {
  const style = {
    borderRadius: 30,
    padding: 3,
  };

  const styleV = {
    padding: 3,
    width,
  };

  return (
    <>
      {orientation === 'Horizontal' ? (
        <Box display="flex">
          <Box style={{ background: labelBackgroud, minWidth: 40, zIndex: 2, ...style }}>
            <Typography style={{ color: 'white', fontSize }}>{label}</Typography>
          </Box>
          <Box
            style={{
              background: secondary,
              zIndex: 1,
              position: 'relative',
              marginLeft: -30,
              ...style,
            }}
          >
            <Typography pl={8} pr={3} style={{ color: 'white', fontSize }}>
              {value}
            </Typography>
          </Box>
        </Box>
      ) : (
        <Box>
          <Box
            style={{
              background: labelBackgroud,
              ...styleV,
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
            }}
          >
            <Typography noWrap align="center" style={{ color: 'white', fontSize }}>
              {label}
            </Typography>
          </Box>
          <Box
            style={{
              background: '#fff',
              ...styleV,
              borderBottomLeftRadius: 10,
              borderBottomRightRadius: 10,
            }}
          >
            <Typography noWrap align="center" style={{ fontSize }} color="secondary">
              {value}
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
}

LabelInfo.propTypes = {
  label: PropTypes.string,
  labelBackgroud: PropTypes.string,
  value: PropTypes.string,
  fontSize: PropTypes.number,
  width: PropTypes.number,
  orientation: PropTypes.string,
};

LabelInfo.defaultProps = {
  label: '',
  labelBackgroud: primary,
  value: '',
  fontSize: 10,
  width: 60,
  orientation: 'Horizontal',
};
