/* eslint-disable no-underscore-dangle */
import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import { ButtonPrimary, ButtonSecondary } from 'core/ui/Button';
import Texture from 'core/ui/Texture';
import { Dialog, DialogActions } from '@material-ui/core';
import DatePickerRounded from 'core/ui/DatePickers/DatePickerRounded';
import TimePickerRounded from 'core/ui/DatePickers/TimePickerRounded';
import Grid from 'core/ui/Grid';
import TextAreaFieldRounded from 'core/ui/TextAreaFieldRounded';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import Paper from 'core/ui/Paper';
import Typography from 'core/ui/Typography';
import { time } from 'core/utils/formatters';
import useForm from './form';

const ModalCalendarsEvent = ({ open, item, close, callback, competition, isCoach }) => {
  const { t } = useTranslation();
  const { setValue, watch, handleSubmit, onSubmit, errors, loading, removeEvent } = useForm({
    item,
    callback,
    competition,
  });

  return (
    <Dialog maxWidth="md" scroll="body" fullWidth onClose={() => close()} open={open}>
      <Texture>
        <Box p={5}>
          {!isCoach ? (
            <Paper p={3}>
              <Typography variant="h5">{item?.name}</Typography>
              <Typography component="div" variant="body2">
                {time(item?.startAt)}
              </Typography>
              <Typography mt={1} className="roboto-regular">
                {item?.description}
              </Typography>
            </Paper>
          ) : (
            <>
              <Box mb={3}>
                <TextFieldRounded
                  label={t('label.name').toUpperCase()}
                  value={watch('name')}
                  onChange={(v) => setValue('name', v)}
                  error={errors.name}
                  required
                />
              </Box>
              <Grid container spacing={5} my={5} px={3}>
                <Grid item xs={12} md={6}>
                  <Box display="flex" alignItems="center" justifyContent="center">
                    <Box>
                      <DatePickerRounded
                        label={t('label.startAt').toUpperCase()}
                        onChange={(v) => setValue('startAtDate', v)}
                        value={watch('startAtDate')}
                        disablePast={false}
                        autoOk
                        error={errors?.startAtDate}
                        bordered
                        required
                      />
                    </Box>
                    &nbsp;
                    <TimePickerRounded
                      label="TIME"
                      type="datetime-local"
                      onChange={(v) => setValue('startAtHour', v)}
                      value={watch('startAtHour')}
                      error={errors?.startAtHour}
                      bordered
                      required
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box display="flex" alignItems="center" justifyContent="center">
                    <Box>
                      <DatePickerRounded
                        label={t('label.endAt').toUpperCase()}
                        onChange={(v) => setValue('endAtDate', v)}
                        value={watch('endAtDate')}
                        disablePast={false}
                        autoOk
                        error={errors?.endAtDate}
                        bordered
                        required
                      />
                    </Box>
                    <TimePickerRounded
                      label="TIME"
                      type="datetime-local"
                      onChange={(v) => setValue('endAtHour', v)}
                      value={watch('endAtHour')}
                      error={errors?.endAtHour}
                      bordered
                      required
                    />
                  </Box>
                </Grid>
              </Grid>

              <Box mb={3}>
                <TextAreaFieldRounded
                  value={watch('description')}
                  onChange={(v) => setValue('description', v)}
                  placeholder={t('label.description')}
                  error={errors.description}
                  label={t('label.description').toUpperCase()}
                />
              </Box>
            </>
          )}
        </Box>
      </Texture>
      <DialogActions>
        {item?.id && (
          <ButtonSecondary disabled={loading} onClick={() => removeEvent()}>
            {t('button.delete')}
          </ButtonSecondary>
        )}
        <Box display="flex" justifyContent="flex-end">
          <ButtonPrimary disabled={loading} onClick={handleSubmit(onSubmit)}>
            {t('button.save')}
          </ButtonPrimary>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

ModalCalendarsEvent.defaultProps = {
  close: () => {},
  callback: () => {},
  open: false,
};

export default ModalCalendarsEvent;
