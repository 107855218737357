/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
import React from 'react';
import Typography from 'core/ui/Typography';
import Box from 'core/ui/Box';
import SFDataTable from 'core/ui/DataTable';
import { ButtonPrimarySm } from 'core/ui/Button';
import { useTranslation } from 'react-i18next';
import MIcon from 'core/ui/MIcon';

const TableWorkflows = ({
  workflows,
  viewClick,
  removeClick,
  templates,
  isStreamChannel,
  cloneTemplate,
  clone,
}) => {
  const { t } = useTranslation();

  const columns = [
    {
      selector: 'name',
      sortable: true,
      width: '40px',
      cell: (workflow) => (
        <div>
          <MIcon icon={workflow?.automationType?.icon} />
        </div>
      ),
    },
    {
      name: 'Name',
      selector: 'name',
      sortable: true,
      cell: (workflow) => (
        <Typography className="tw-font-semibold" noWrap>
          {workflow?.name}
        </Typography>
      ),
    },
    {
      name: 'Status',
      selector: 'status',
      sortable: true,
      cell: (workflow) => <Typography noWrap>{workflow.status?.toUpperCase()}</Typography>,
    },
    {
      name: '',
      selector: 'contactsCount',
      sortable: true,
      center: true,
      cell: (workflow) => (
        <Box display="flex" className="gap-5" flexWrap="wrap" p={1}>
          <Box>
            <ButtonPrimarySm onClick={() => viewClick(workflow)}>
              {t('button.view')}
            </ButtonPrimarySm>
          </Box>
          {!templates && (
            <Box>
              <ButtonPrimarySm onClick={() => clone(workflow)}>{t('button.clone')}</ButtonPrimarySm>
            </Box>
          )}

          {!!templates && (
            <Box>
              <ButtonPrimarySm onClick={() => cloneTemplate(workflow)}>
                {t('button.cloneWorkflow')}
              </ButtonPrimarySm>
            </Box>
          )}
          {(!templates || isStreamChannel) && (
            <Box>
              <ButtonPrimarySm onClick={() => removeClick(workflow)}>
                {t('button.remove')}
              </ButtonPrimarySm>
            </Box>
          )}
        </Box>
      ),
    },
  ].filter((x) => x.name !== 'Status' || isStreamChannel || !templates);

  return (
    <Box>
      <SFDataTable
        columns={columns}
        data={workflows}
        searchField={(row) => row?.name}
        pagination
        noTableHead={false}
        noHeader
        showSearch
        showPaper
        paginationPerPage={30}
      />
    </Box>
  );
};

TableWorkflows.propTypes = {};

TableWorkflows.defaultProps = {};

export default TableWorkflows;
