import React from 'react';

import { InputBase, makeStyles } from '@material-ui/core';
import Box from 'core/ui/Box';
import { InputWrapper } from 'core/ui/ChosenComponents';
import { useTranslation } from 'react-i18next';
import Paper from 'core/ui/Paper';
import Typography, { fontStyle } from 'core/ui/Typography';
import SFDataTable from 'core/ui/DataTable';
import { secondaryXLight } from 'core/ui/Colors';
import LoaderSm from 'core/ui/LoaderSm';
import useSelectors from 'modules/map/useSelectors';
import { userHistoryModule } from 'modules/userHistory';
import { dateExt } from 'core/utils/formatters';
import { ButtonPrimary } from 'core/ui/Button';
import MenuCustomWorkout from './MenuCustom';
import ModalImportBenchmarksWorkouts from '../Imports/BenckmarksWorkouts/Modal';

const useStyles = makeStyles(() => ({
  rowHover: {
    width: '100%',
    paddingTop: 8,
    paddingBottom: 8,
    paddingRight: 5,
    paddingLeft: 5,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: secondaryXLight,
    },
  },
}));

const WorkoutsList = ({ text, setText, actions, setPage, setPerPage, perPage, search }) => {
  const { t } = useTranslation();

  const pagination = useSelectors(userHistoryModule, 'pagination');
  const loading = useSelectors(userHistoryModule, 'loading');
  const data = useSelectors(userHistoryModule, 'data');

  const classes = useStyles();

  const getLastScoreAt = (workout) => {
    const lastDate = null;
    if (workout.lastInviteAt) {
      return workout.lastInviteAt;
    }

    return lastDate || workout?.datetime;
  };

  const columns = [
    {
      cell: (row) => (
        <Box display="flex" alignItems="center" className={classes.rowHover}>
          <Box flexGrow={1}>
            <Typography style={fontStyle.medium}>{row.workoutName}</Typography>
          </Box>
          <Box>{dateExt(getLastScoreAt(row), t)}</Box>
          <Box ml={3}>
            <MenuCustomWorkout actions={actions} workout={row} />
          </Box>
        </Box>
      ),
    },
  ];

  return (
    <Box>
      <Box>
        <Box display="flex" alignItems="center">
          <InputWrapper>
            <Box display="flex" style={{ width: '100%' }}>
              <InputBase
                value={text}
                onChange={(v) => setText(v.target.value)}
                style={{ minWidth: 30, width: '100%', margin: '4px' }}
                placeholder={t('dashboard.history.search')}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    search();
                  }
                }}
              />
            </Box>
          </InputWrapper>
          <Box ml={1}>
            <ModalImportBenchmarksWorkouts refresh={search} type="Workout" />
          </Box>
          <Box ml={1}>
            <ButtonPrimary onClick={() => search({ exportData: true })}>
              {t('button.export')}
            </ButtonPrimary>
          </Box>
          <Box ml={1}>
            <ButtonPrimary onClick={() => search()}>{t('button.search')}</ButtonPrimary>
          </Box>
          <Box ml={1}>
            <ButtonPrimary onClick={() => actions.openForm()}>{t('button.create')}</ButtonPrimary>
          </Box>
        </Box>
      </Box>
      <Paper className="paper-rounded" mt={1} py={3}>
        <Box display="flex" alignItems="center" justifyContent="center">
          <LoaderSm loading={loading} />
        </Box>
        <Box>
          <SFDataTable
            noHeader
            noTableHead
            title=""
            showPaper={false}
            columns={columns}
            paginationPerPage={perPage}
            data={data}
            pagination
            noDataComponent=""
            onChangePage={(p) => setPage(p)}
            paginationServer
            paginationTotalRows={pagination?.totalCount}
            onChangeRowsPerPage={(newValue) => {
              setPage(1);
              setPerPage(newValue);
            }}
          />
        </Box>
      </Paper>
    </Box>
  );
};

export default WorkoutsList;
