import { createSelector } from 'reselect';

const getState = (state) => state.recurrings;

export const getLoading = createSelector(getState, (state) => !!state.loading);
export const getError = createSelector(getState, (state) => state.error);
export const getRecurring = createSelector(getState, (state) => state.recurring);
export const getChannelRecurrings = createSelector(getState, (state) => state.data);
export const getLoadingChannelRecurrings = createSelector(
  getState,
  (state) => state.loadingChannelRecurrings,
);
export const getPagination = createSelector(getState, (state) => state.pagination);
