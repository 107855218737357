import React, { useContext } from 'react';

import { useSelector } from 'react-redux';
import { workoutsSelectors } from 'modules/workouts';
import { Alert } from '@material-ui/lab';

import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import Overview from './Tabs/Overview/index';
import Reviews from './Tabs/Reviews/index';
import CoachInfo from './Tabs/CoachesInfo/index';
import Athletes from './Tabs/Attendance';
import { ShowContext } from '.';

export default () => {
  const { t } = useTranslation();
  const loading = useSelector((state) => workoutsSelectors.getLoading(state));
  const workout = useSelector((state) => workoutsSelectors.getWorkout(state));
  const showContext = useContext(ShowContext);

  return (
    <>
      {workout.registered && !workout.recorded && workout.channel && !workout.channel.planValid && (
        <Box mb={3}>
          <Alert variant="filled" severity="warning">
            {t('workout.channel.inactive')}
          </Alert>
        </Box>
      )}

      {(showContext.tab === 'overview' || showContext.tab === 'leaderboard') && (
        <Overview workout={workout} loading={loading} />
      )}
      {showContext.tab === 'coaches' && !loading && <CoachInfo />}
      {showContext.tab === 'reviews' && !loading && <Reviews />}
      {showContext.tab === 'attendance' && <Athletes />}
    </>
  );
};
