/* eslint-disable react/prop-types */
import * as React from 'react';
import Paper from 'core/ui/Paper';
import Typography, { fontStyle } from 'core/ui/Typography';
import { channelCurrency } from 'core/utils/formatters';
import SFDataTable from 'core/ui/DataTable';
import Grid from 'core/ui/Grid';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer } from 'recharts';
import randomColor from 'randomcolor';
import Box from 'core/ui/Box';
import Divider from 'core/ui/Divider';

export default function TotalByMembership({ memberships, channel }) {
  const columns = [
    {
      name: 'Membership Plan',
      cell: (row) => (
        <Typography style={fontStyle.medium} noWrap>
          {row.plan?.name}
        </Typography>
      ),
    },
    {
      name: 'Months',
      selector: 'total',
      sortable: true,
      right: true,
      cell: (row) => (
        <Box>
          {Object.keys(row?.months).map((itemMonth, idx) => (
            <Box>
              <Typography style={fontStyle.medium}>
                {itemMonth}:&nbsp;
                {channelCurrency(row?.months[itemMonth], channel?.currency)}
              </Typography>

              {Object.keys(row?.months).length - 1 > idx && <Divider my={1} />}
            </Box>
          ))}
        </Box>
      ),
    },
    {
      name: 'Total',
      selector: 'total',
      sortable: true,
      right: true,
      cell: (row) => (
        <Typography style={fontStyle.medium}>
          {channelCurrency(row.total, channel?.currency)}
        </Typography>
      ),
    },
  ];

  return (
    <Grid container spacing={5}>
      <Grid item xs={12} md={6}>
        <Paper pb={3}>
          <SFDataTable
            columns={columns}
            data={memberships}
            pagination={false}
            showExport={false}
            noHeader
          />
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper pb={3} style={{ height: '100%', width: '100%' }}>
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Pie
                data={memberships?.map((p) => ({
                  name: p.plan?.name,
                  value: p.total * 100,
                }))}
                dataKey="value"
                nameKey="name"
              >
                {memberships?.map((entry, index) => (
                  <Cell key={`cell-${index.toString()}`} fill={randomColor()} />
                ))}
              </Pie>
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </Paper>
      </Grid>
    </Grid>
  );
}
