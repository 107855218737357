import React, { Suspense, useEffect, useState } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { ThemeProvider } from 'styled-components';
import { MuiThemeProvider } from '@material-ui/core';
import useSelectors from 'modules/map/useSelectors';
import { newAppModule } from 'modules/app/new';
import { useSelector } from 'react-redux';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import { primary } from 'core/ui/Colors';
import useChannelSettings from 'core/useChannelSettings';
import { channelsSelectors } from 'modules/channels';
import { AppContext } from 'contexts';
import ModalUserPin from 'core/ui/ModalUserPin';
import ModalPurchase from 'components/Purchase/Modal';
import AppStart from 'AppStart';
import history from './history';

import maTheme from './theme';
import './i18n';
import 'react-phone-input-2/lib/material.css';
import 'react-toastify/dist/ReactToastify.css';
import 'assets/css/App.css';
import 'assets/css/react-resizable.css';
import 'assets/css/animate.css';

const App = () => {
  const { request } = useActions();
  const channel = useSelector((state) => channelsSelectors.getChannel(state));
  const primaryColor = useSelectors(newAppModule, 'primaryColor');
  const [modalPurchase, setModalPurchase] = useState({ open: false });

  useChannelSettings();

  useEffect(() => {
    request({
      action: Modules.newApp.actions.setPrimaryColor,
      data: channel?.customColors?.buttonColor || primary,
    });
  }, [request, channel]);

  return (
    <MuiThemeProvider theme={maTheme(primaryColor)}>
      <ThemeProvider theme={maTheme(primaryColor)}>
        <Suspense fallback={null}>
          <AppContext.Provider value={{ modalPurchase, setModalPurchase }}>
            <ConnectedRouter history={history}>
              <>
                <ModalUserPin />
                <AppStart />
                <ModalPurchase
                  modalPurchase={modalPurchase}
                  close={() => setModalPurchase({ open: false })}
                />
              </>
            </ConnectedRouter>
          </AppContext.Provider>
        </Suspense>
      </ThemeProvider>
    </MuiThemeProvider>
  );
};

export default App;
