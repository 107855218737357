import React from 'react';
import Box from 'core/ui/Box';
import { useSelector } from 'react-redux';
import { channelsSelectors } from 'modules/channels';
import CardCoach from 'components/Channel/Manage/Tabs/Coaches/Card';
import { makeStyles } from '@material-ui/core';
import Paper from 'core/ui/Paper';
import useChannelStyles from 'core/useChannelStyles';

export default () => {
  const channel = useSelector((state) => channelsSelectors.getChannel(state));

  const { buttonColor } = useChannelStyles();

  const useStyles = makeStyles((theme) => ({
    boxCoaches: {
      display: 'flex',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
  }));

  const classes = useStyles();

  return (
    <Paper elevation={2} className="paper-rounded" p={3} mt={3}>
      {channel.coaches && (
        <Box mb={4} className={classes.boxCoaches} flexWrap="wrap">
          {channel.coaches
            .filter((x) => x.active === true)
            .map((coach) => (
              <Box p={2} key={coach.id}>
                <CardCoach
                  coach={coach.user}
                  accessType={coach.accessType}
                  buttonColor={buttonColor}
                />
              </Box>
            ))}
        </Box>
      )}
    </Paper>
  );
};
