import React from 'react';
import Box from 'core/ui/Box';
import Paper from 'core/ui/Paper';
import { useTranslation } from 'react-i18next';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import TimePickerRounded from 'core/ui/DatePickers/TimePickerRounded';
import { IconButton } from '@material-ui/core';
import { Delete, FileCopy } from '@material-ui/icons';

const ListHeats = ({ heats, changeHeat, removeHeat, duplicateHeat }) => (
  <Box>
    {heats &&
      heats
        .filter((x) => !x?._destroy)
        .map((heat) => (
          <React.Fragment key={heat?.id}>
            <ItemHeat
              heat={heat}
              changeHeat={changeHeat}
              removeHeat={removeHeat}
              duplicateHeat={duplicateHeat}
            />
          </React.Fragment>
        ))}
  </Box>
);

const ItemHeat = ({ heat, changeHeat, removeHeat, duplicateHeat }) => {
  const { t } = useTranslation();

  return (
    <Paper elevation={2} mb={3} className="paper-rounded opacity-80" p={3}>
      <Box display="flex" flexWrap="wrap" className="link" alignItems="flex-end">
        <Box>
          <TextFieldRounded
            placeholder="Add a name for this heat"
            value={heat?.name}
            style={{ width: 250 }}
            onChange={(v) => changeHeat(heat, 'name', v)}
            bordered
          />
        </Box>
        <Box mx={1} display="flex" alignItems="center">
          <TimePickerRounded
            name="scheduledAt"
            type="datetime-local"
            label={t('workout.label.scheduledAtTime').toUpperCase()}
            onChange={(v) => changeHeat(heat, 'startTime', v)}
            value={heat.startTime}
            required
            bordered
          />
          <Box ml={1}>
            <TimePickerRounded
              name="scheduledAt"
              type="datetime-local"
              label={t('workout.label.endAtHour').toUpperCase()}
              onChange={(v) => changeHeat(heat, 'endTime', v)}
              value={heat.endTime}
              required
              bordered
            />
          </Box>
        </Box>
        <Box flexGrow={1} mx={1}>
          <TextFieldRounded
            type="number"
            pattern="[0-9]*"
            value={heat?.spots || ''}
            onChange={(v) => changeHeat(heat, 'spots', v)}
            placeholder={t('workout.form.heats.label.spots')}
            label={t('workout.form.heats.label.spots').toUpperCase()}
            required
            bordered
          />
        </Box>
        <Box>
          <IconButton onClick={() => duplicateHeat(heat)}>
            <FileCopy />
          </IconButton>
        </Box>
        {(heat?.new || heat?.allowRemove) && (
          <Box>
            <IconButton onClick={() => removeHeat(heat)}>
              <Delete />
            </IconButton>
          </Box>
        )}
      </Box>
    </Paper>
  );
};

export default ListHeats;
