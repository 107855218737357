import api from 'core/api';

export const channelsEventsModule = 'channelsEvents';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const loadingFormStates = {
  start: { loadingForm: true },
  error: { loadingForm: false },
  success: { loadingForm: false },
};

const actions = {
  getEvents: {
    module: channelsEventsModule,
    name: 'getEvents',
    api: (params) => api.post(`/channels/${params?.channelId}/channels_events/index`, params),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  create: {
    module: channelsEventsModule,
    name: 'create',
    api: (data) => api.post(`/channels/${data?.channelId}/channels_events`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingFormStates,
  },
  show: {
    module: channelsEventsModule,
    name: 'show',
    api: (params) => api.get(`/channels/${params?.channelId}/channels_events/${params?.id}`),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingFormStates,
  },
  update: {
    module: channelsEventsModule,
    name: 'update',
    api: (data) => api.put(`/channels/${data?.channelId}/channels_events/${data?.id}`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingFormStates,
  },
  delete: {
    module: channelsEventsModule,
    name: 'delete',
    api: (data) => api.delete(`/channels/${data?.channelId}/channels_events/${data?.id}`),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingFormStates,
  },
};

const channelsStageActionsAndStates = {
  actions,
  state: {
    data: [],
    error: null,
    loadingForm: false,
  },
};

export default channelsStageActionsAndStates;
