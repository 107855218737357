import React, { createContext, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Box from 'core/ui/Box';
import { useDispatch, useSelector } from 'react-redux';
import { searchSelectors, searchActions } from 'modules/search';
import Grid from 'core/ui/Grid';
import LoaderSm from 'core/ui/LoaderSm';
import Paginator from 'core/ui/Paginator';
import GridSkeleton from 'core/ui/GridSkeleton';
import Item from '../Sections/Item';
import CardCoach from '../Sections/Cards/CardCoach';

export const ShowContext = createContext();

const Content = ({ viewMore, channelId, onClickCard }) => {
  const dispatch = useDispatch();
  const viewMoreData = useSelector((state) => searchSelectors.getViewMoreData(state));
  const viewMorePagination = useSelector((state) => searchSelectors.getViewMorePagination(state));
  const loadingViewMore = useSelector((state) => searchSelectors.getLoadingViewMore(state));

  const filters = useSelector((state) => searchSelectors.getFilters(state));
  const queryData = useSelector((state) => searchSelectors.getQueryData(state));
  const configPagination = {
    Workout: {
      perPage: 12,
    },
    User: {
      perPage: 36,
    },
    Channel: {
      perPage: 24,
    },
  };

  const getFiltersSearch = useCallback(
    (page = 1) => ({
      page,
      perPage: configPagination[viewMore.model].perPage,
      type: viewMore.type,
      query: queryData,
      models: [viewMore.model],
      recents: viewMore.recents,
      upcoming: viewMore.upcoming,
      role: 'coach',
      channel_id: channelId,
    }),
    [filters, queryData, viewMore], //eslint-disable-line
  );

  useEffect(() => {
    let data = {};
    if (viewMore.type === 'results') {
      data = getFiltersSearch();
    } else {
      data = {
        upcoming: viewMore.upcoming,
        recents: viewMore.recents,
        registered: viewMore.registered,
        type: viewMore.type,
        model: viewMore.model,
        page: 1,
        perPage: configPagination[viewMore.model].perPage,
        channel_id: channelId,
      };
    }

    dispatch(searchActions.getViewMoreStart(data));

    return () => {
      dispatch(searchActions.clearViewMoreResults());
    };
  }, [dispatch, viewMore, getFiltersSearch]); //eslint-disable-line

  const changePage = (page) => {
    let data = {};
    if (viewMore.type === 'results') {
      data = getFiltersSearch(page);
    } else {
      data = {
        upcoming: viewMore.upcoming,
        recents: viewMore.recents,
        registered: viewMore.registered,
        type: viewMore.type,
        model: viewMore.model,
        page,
        perPage: configPagination[viewMore.model].perPage,
        channel_id: channelId,
      };
    }
    dispatch(searchActions.getViewMoreStart(data));
  };

  return (
    <>
      <Grid container className="search-item">
        {loadingViewMore ? (
          <GridSkeleton />
        ) : (
          <>
            {viewMoreData.map((object, index) => (
              <React.Fragment key={index.toString()}>
                {object.model === 'User' ? (
                  <Grid item xs={6} sm={4} md={2} lg={2} key={index.toString()}>
                    <Box m={2} className="item link">
                      <CardCoach coach={object.item} fullWidth />
                    </Box>
                  </Grid>
                ) : (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={object.model === 'Workout' ? 3 : 4}
                    key={index.toString()}
                    className="search-item"
                  >
                    <Box
                      m={2}
                      onClick={() => (channelId && onClickCard ? onClickCard(object.item) : null)}
                    >
                      <Item object={object} key={index.toString()} clickable={!onClickCard} />
                    </Box>
                  </Grid>
                )}
              </React.Fragment>
            ))}
          </>
        )}
      </Grid>

      <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
        <LoaderSm loading={loadingViewMore} size={12} />
        {!loadingViewMore && (
          <Paginator pagination={viewMorePagination} changePage={(p) => changePage(p)} />
        )}
      </Box>
    </>
  );
};

export default Content;

Content.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  viewMore: PropTypes.object,
  channelId: PropTypes.number,
  onClickCard: PropTypes.func,
};

Content.defaultProps = {
  viewMore: {},
  channelId: null,
  onClickCard: null,
};
