import React, { useEffect, useState } from 'react';
import { Box, Tab, Tabs } from '@material-ui/core';
import Paper from 'core/ui/Paper';
import useActions from 'modules/map/useActions';
import { useDispatch } from 'react-redux';
import { channelsActions } from 'modules/channels';
import useConfirmation from 'core/useConfirmation';
import notifications from 'modules/notifications';
import Modules from 'modules';
import { useTranslation } from 'react-i18next';
import useSelectors from 'modules/map/useSelectors';
import { newChannelMembersModule } from 'modules/channelMembers/new';
import { channelMembersActions } from 'modules/channelMembers';
import UserPlans from './UserPlans';
import ModalSwitchMembership from './ModalSwitchMembership';

const MemberMemberships = ({ member, channel, setModalFormKey, setModalAddPayment }) => {
  const { t } = useTranslation();
  const [tab, setTab] = useState(0);
  const { request } = useActions();
  const dispatch = useDispatch();
  const { confirmation } = useConfirmation();

  const [modalSwitch, setModalSwitch] = useState({});

  const memberships = useSelectors(newChannelMembersModule, 'memberships');

  const getMemberships = () => {
    request({
      action: Modules.newChannelMembers.actions.getMemberships,
      data: {
        channelId: channel?.id,
        userId: member?.id,
      },
      options: {
        onError: (ex) => {
          dispatch(notifications.error(ex));
        },
      },
    });
  };

  useEffect(() => {
    if (channel?.id && member?.id) {
      getMemberships();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel, member]);

  const removeChannelKeyFromUser = (keyId) => {
    dispatch(
      channelsActions.removeUserFromKeysStart({
        channelId: channel?.id,
        keys: [keyId],
        callback: () => {
          getMemberships();
        },
      }),
    );
  };

  const removeKey = (channelKey) => {
    confirmation({
      description: !channelKey.allowRemove
        ? t('confirm.remove.activeKey')
        : t('confirm.remove.key'),
      yesClick: () => {
        if (channelKey.allowRemove) {
          request({
            action: Modules.newChannelMembers.actions.deleteChannelKey,
            data: {
              channelId: channel?.id,
              id: channelKey?.id,
            },
            options: {
              onSuccess: () => {
                getMemberships();
                setModalFormKey((p) => ({ ...p, open: false }));
                dispatch(notifications.success(t('channel.members.message.plan.saved')));
              },
            },
          });
        } else {
          request({
            action: Modules.newChannelMembers.actions.updateMembership,
            data: {
              id: channelKey.id,
              active: false,
              channelId: channel?.id,
            },
            options: {
              onSuccess: () => {
                getMemberships();
                setModalFormKey((p) => ({ ...p, open: false }));
                dispatch(notifications.success(t('channel.members.message.plan.saved')));
              },
            },
          });
        }
      },
    });
  };

  return (
    <Paper square>
      <ModalSwitchMembership
        channel={channel}
        channelKey={modalSwitch?.channelKey}
        open={modalSwitch?.open}
        close={() => setModalSwitch({})}
        callbackSave={() => {
          // getMemberships();
          dispatch(channelMembersActions.getChannelMemberStart(channel.id, member.id));
        }}
      />
      <Tabs value={tab} indicatorColor="primary" textColor="primary" onChange={(e, v) => setTab(v)}>
        <Tab
          label={`${t('channel.memberships.activePlans')} (${memberships?.activeKeys?.length || '0'})`}
          value={0}
        />
        <Tab
          label={`${t('channel.memberships.inactivePlans')} (${memberships?.inactiveKeys?.length || '0'})`}
          value={1}
        />
        <Tab
          label={`${t('channel.memberships.pausedPlans')} (${memberships?.pausedKeys?.length || '0'})`}
          value={2}
        />
      </Tabs>

      <Box pb={3}>
        {tab === 0 && memberships?.activeKeys && memberships?.activeKeys?.length > 0 && (
          <Box>
            <UserPlans
              channel={channel}
              user={member}
              removeChannelKeyFromUser={removeChannelKeyFromUser}
              keys={memberships?.activeKeys}
              removeKey={removeKey}
              getMemberships={getMemberships}
              setModalAddPayment={setModalAddPayment}
              setModalSwitch={setModalSwitch}
            />
          </Box>
        )}

        {tab === 1 && memberships?.inactiveKeys && memberships?.inactiveKeys?.length > 0 && (
          <Box>
            <UserPlans
              channel={channel}
              user={member}
              keys={memberships?.inactiveKeys}
              inactiveKeys
              removeKey={removeKey}
              getMemberships={getMemberships}
              setModalAddPayment={setModalAddPayment}
            />
          </Box>
        )}
        {tab === 2 && memberships?.pausedKeys && memberships?.pausedKeys?.length > 0 && (
          <Box>
            <UserPlans
              channel={channel}
              user={member}
              keys={memberships?.pausedKeys}
              removeKey={removeKey}
              getMemberships={getMemberships}
              setModalAddPayment={setModalAddPayment}
            />
          </Box>
        )}
      </Box>
    </Paper>
  );
};

export default MemberMemberships;
