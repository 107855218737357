import React, { useState } from 'react';
import { ButtonSecondary } from 'core/ui/Button';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import { useForm } from 'react-hook-form-v5';

import Modules from 'modules';
import useActions from 'modules/map/useActions';
import useSelectors from 'modules/map/useSelectors';
import { helpVideoModule } from 'modules/helpVideo';

export const EditHelpVideoButton = ({ helpVideo, getHelpVideosToSelect }) => {
  const { request } = useActions();

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = (data) => {
    request({
      action: Modules.helpVideo.actions.update,
      data: { id: helpVideo.id, body: data },
      options: {
        onSuccess: () => {
          getHelpVideosToSelect();
          handleClose();
        },
      },
    });
  };

  return (
    <>
      <ButtonSecondary onClick={() => setOpen(true)}>Edit</ButtonSecondary>

      <Dialog open={open} onClose={handleClose}>
        <Form helpVideo={helpVideo} handleClose={handleClose} onSubmit={onSubmit} />
      </Dialog>
    </>
  );
};

const Form = ({ helpVideo, handleClose, onSubmit }) => {
  const { register, handleSubmit } = useForm({ defaultValues: helpVideo });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogTitle>Edit Help Video</DialogTitle>

      <DialogContent>
        <TextField
          inputRef={register}
          autoFocus
          required
          margin="dense"
          id="title"
          name="title"
          label="Title"
          type="text"
          fullWidth
          variant="standard"
        />

        <TextField
          inputRef={register}
          required
          margin="dense"
          id="vimeoUrl"
          name="vimeoUrl"
          label="Vimeo URL"
          type="text"
          fullWidth
          variant="standard"
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <CreateButton />
      </DialogActions>
    </form>
  );
};

const CreateButton = () => {
  const loading = useSelectors(helpVideoModule, 'loading');

  return <Button type="submit">{loading ? <CircularProgress /> : 'Update'}</Button>;
};
