import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import Box from 'core/ui/Box';
import Texture from 'core/ui/Texture';
import Form from './Form';

const ModalPartnerProgram = ({ open, partner, program, close, callbackSave, programs }) => (
  <Dialog open={open} onClose={close} maxWidth="xs" fullWidth scroll="body">
    <Texture>
      <Box p={5}>
        <Form
          partnerId={partner?.id}
          close={close}
          programs={programs}
          callbackSave={callbackSave}
          program={program}
        />
      </Box>
    </Texture>
  </Dialog>
);

ModalPartnerProgram.propTypes = {
  close: PropTypes.func,
};

ModalPartnerProgram.defaultProps = {
  close: () => {},
};

export default ModalPartnerProgram;
