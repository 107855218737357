import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import Grid from 'core/ui/Grid';
import Typography, { fontStyle } from 'core/ui/Typography';
import { successColor, warningColor } from 'core/ui/Colors';
import { channelCurrency } from 'core/utils/formatters';
import Paper from 'core/ui/Paper';
import { Tooltip } from '@material-ui/core';
import { Info } from '@material-ui/icons';

const CardGrossIncome = ({ channel, data }) => {
  const { t } = useTranslation();

  const CardTotals = ({
    amount,
    groupByTypes,
    label,
    positive,
    style = fontStyle.large,
    fontColor,
  }) => {
    if (amount === '0.00') {
      return <></>;
    }
    return (
      <Box display="flex" flexDirection="column">
        <Typography style={style}>{label}</Typography>
        <Box display="flex" alignItems="center">
          <Typography
            style={{ ...style, color: fontColor || (positive ? successColor : 'red') }}
            color="secondary"
          >
            {positive ? '' : '-'}

            {channelCurrency(amount, channel?.currency)}
          </Typography>
          {groupByTypes && groupByTypes?.length > 0 && (
            <Tooltip
              title={
                <div style={{ whiteSpace: 'pre-line' }}>
                  {groupByTypes
                    ?.map((x) => `${x.type}: ${channelCurrency(x.total, channel?.currency)}`)
                    .join('\n')}
                </div>
              }
            >
              <Info fontSize="small" />
            </Tooltip>
          )}
        </Box>
      </Box>
    );
  };

  return (
    <Paper className="paper-rounded" style={{ overflow: 'hidden' }}>
      <Typography variant="h5" p={3}>
        {t('label.grossIncome')}
      </Typography>

      <Grid container spacing={3} p={3} style={{ height: 200 }}>
        <Grid item xs={6}>
          <CardTotals
            label={t('channel.reports.summary.memberships')}
            positive
            amount={data.memberships?.total}
            groupByTypes={data.memberships?.groupByTypes}
          />
        </Grid>
        <Grid item xs={6}>
          <CardTotals
            label={t('channel.reports.summary.competitions')}
            positive
            amount={data.competitionRegistrations?.total}
            groupByTypes={data.competitionRegistrations?.groupByTypes}
          />
        </Grid>
        <Grid item xs={6}>
          <CardTotals
            positive
            label={t('channel.reports.summary.store')}
            amount={data.store?.total}
            groupByTypes={data.store?.groupByTypes}
          />
        </Grid>
        <Grid item xs={6}>
          {!!data.lists?.total && (
            <CardTotals
              positive
              label={t('channel.reports.summary.playLists')}
              amount={data.lists?.total}
              groupByTypes={data.lists?.groupByTypes}
            />
          )}
        </Grid>
        <Grid item xs={6}>
          <CardTotals
            positive
            label={t('channel.reports.summary.others')}
            amount={data.others?.total}
            groupByTypes={data.others?.groupByTypes}
          />
        </Grid>
        <Grid item xs={6}>
          <CardTotals
            positive
            label={t('channel.reports.summary.requiresAction')}
            amount={data.resumePaymentsAmounts?.pendingAction}
            fontColor={warningColor}
          />
        </Grid>
      </Grid>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        style={{ backgroundColor: '#D4EDD2' }}
        p={5}
      >
        <Typography variant="h2" style={{ color: successColor }}>
          {channelCurrency(data.resumePaymentsAmounts?.income, channel?.currency)}
        </Typography>
      </Box>
    </Paper>
  );
};

export default CardGrossIncome;
