import { persistData, retrieveData } from 'core/utils/session-storage';
import Modules from 'modules';
import useActions from 'modules/map/useActions';
import useSelectors from 'modules/map/useSelectors';
import notifications from 'modules/notifications';
import { storeOrdersModule } from 'modules/storeOrders';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

export default ({ channel }) => {
  const { request } = useActions();
  const loadingPreview = useSelectors(storeOrdersModule, 'loadingPreview');
  const [purchaseResume, setPurchaseResume] = useState(null);
  const [previewOrder, setPreviewOrder] = useState({
    total: 0,
    deliveryTotal: 0,
    salesTaxTotal: 0,
    items: [],
  });
  const [cartItems, setCartItems] = useState([]);
  const [opened, setOpened] = useState(false);
  const [address, setAddress] = useState({});
  const STORE_KEY_NAME = `SHPPOING_CART_${channel?.id}`;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [coupon, setCoupon] = useState(null);
  const [offlinePayment, setOfflinePayment] = useState({ isOffline: false, type: 'cash' });
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);

  const getItems = () => {
    const dataString = retrieveData(STORE_KEY_NAME);
    return dataString ? JSON.parse(dataString) : [];
  };

  const addItem = (product, option) => {
    setCartItems((prev) => [
      ...prev,
      {
        productId: product?.id,
        productInventoryId: option?.id,
        product,
        option,
        qty: 1,
        delivery: product?.ownerableType === 'Partner',
      },
    ]);
  };

  const checkItem = (x, product, option) =>
    x?.product?.id === product?.id && x?.option?.id === option?.id;

  const existsInCart = (product, option) => cartItems.find((x) => checkItem(x, product, option));

  const item = (product, option) => cartItems.find((x) => checkItem(x, product, option)) || {};

  const resumeItem = useCallback(
    (option) =>
      previewOrder?.items?.find((x) => Number(x.storeProductInventoryId) === Number(option?.id)) ||
      {},
    [previewOrder],
  );

  const removeItem = (product, option) => {
    setCartItems((prev) => prev.filter((x) => !checkItem(x, product, option)));
  };

  const addQtyItem = (product, option, shopItem, value) => {
    if (value > 0) {
      const newQty = shopItem?.qty + value;

      if (!option?.qtyUnlimited && newQty > option?.qty) {
        dispatch(
          notifications.warning(
            t('store.payment.validate.stock').replace('#{inventory_total}', option?.qty),
          ),
        );
        return;
      }
    }

    setCartItems((prev) =>
      prev
        .filter((x) => (checkItem(x, product, option) ? x.qty + value > 0 : true))
        .map((x) => {
          if (checkItem(x, product, option)) {
            return { ...x, qty: x.qty + value };
          }
          return x;
        }),
    );
  };

  const setShoppingItem = (product, option, prop, value) => {
    if (prop === 'qty' && value > 0) {
      if (!product?.qtyUnlimited && value > option?.qty) {
        dispatch(
          notifications.warning(
            t('store.payment.validate.stock').replace('#{inventory_total}', option?.qty),
          ),
        );
        return;
      }
    }

    setCartItems((prev) =>
      prev
        .filter((x) => (prop === 'qty' && checkItem(x, product, option) ? value > 0 : true))
        .map((x) => {
          if (checkItem(x, product, option)) {
            return { ...x, [prop]: value };
          }
          return x;
        }),
    );
  };

  const buildOrderParams = () => ({
    userPaymentMethodId: selectedPaymentMethod?.id,
    channelId: channel?.id,
    ownerableType: 'Channel',
    ownerableId: channel?.id,
    isOffline: channel?.isCoach && offlinePayment.isOffline,
    offlineType: channel?.isCoach && offlinePayment.type,
    cartItems: cartItems.map((x) => ({
      productInventoryId: x.productInventoryId,
      productId: x.productId,
      delivery: x.delivery,
      qty: x.qty,
    })),
    couponCode: coupon?.code,
    address,
  });

  const getResumeTotal = () => {
    request({
      action: Modules.storeOrders.actions.getPreviewOrder,
      data: buildOrderParams(),
      options: {
        onSuccess: (data) => {
          setPreviewOrder(data);
        },
      },
    });
  };

  useEffect(() => {
    setCartItems(getItems());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (cartItems) {
      persistData(STORE_KEY_NAME, JSON.stringify(cartItems));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartItems]);

  useEffect(() => {
    if (opened) {
      getResumeTotal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    cartItems,
    coupon,
    opened,
    address?.countryId,
    address?.state,
    address?.zip,
    selectedPaymentMethod?.id,
    offlinePayment,
  ]);

  useEffect(() => {
    if (!opened) {
      setPurchaseResume(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opened]);

  const countTotalItems = () => {
    let total = 0;
    cartItems.forEach((x) => {
      total += x.qty;
    });
    return total;
  };

  const successPurchase = (resp) => {
    setPurchaseResume({
      showSuccess: true,
      cartItems,
      previewOrder,
      success: !!resp.payments.find((x) => x.success),
      payments: resp.payments,
    });

    setCoupon(null);
    const items = [];
    cartItems.forEach((x) => {
      const exists = resp?.payments
        ?.filter((y) => !y?.error)
        .find((payment) => payment?.items?.find((y) => Number(y) === Number(x.productInventoryId)));

      if (!exists) {
        items.push(x);
      }
    });

    setCartItems(items);
  };

  const validItems = useCallback(() => {
    let valid = true;
    cartItems.forEach((cartItem) => {
      const { option } = cartItem;

      const resumeItemStore = resumeItem(option);
      const allowPurchase = !resumeItemStore?.error;

      if (!allowPurchase) {
        valid = false;
      }
    });

    return valid;
  }, [cartItems, resumeItem]);

  return {
    cartItems,
    addItem,
    removeItem,
    addQtyItem,
    opened,
    open: () => setOpened(true),
    close: () => setOpened(false),
    existsInCart,
    item,
    countTotalItems: countTotalItems(),
    setShoppingItem,
    address,
    setAddress,
    previewOrder,
    coupon,
    setCoupon,
    loadingPreview,
    resumeItem,
    offlinePayment,
    setOfflinePayment,
    buildOrderParams,
    purchaseResume,
    setPurchaseResume,
    successPurchase,
    channel,
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    validItems: validItems(),
  };
};
