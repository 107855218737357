import React, { useContext } from 'react';
import Typography from 'core/ui/Typography';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import Paper from 'core/ui/Paper';
import { time, date } from 'core/utils/formatters';
import LoaderSm from 'core/ui/LoaderSm';
import { ShowContext } from '../../index';

export default () => {
  const { t } = useTranslation();
  const showContext = useContext(ShowContext);
  const { fontColor } = showContext;
  return (
    <Paper elevation={2} className="paper-rounded opacity-80" p={2} style={{ height: '100%' }}>
      <Typography color="secondary" mb={3} variant="h5">
        {showContext.competition.name}
      </Typography>

      {showContext.loading && (
        <Box display="flex" justifyContent="center">
          <LoaderSm loading={showContext.loading} />
        </Box>
      )}

      {!showContext.loading && (
        <>
          <Box display="flex">
            <Typography component="span" variant="body1">
              {t('start').toUpperCase()}&nbsp;
            </Typography>
            <Typography style={{ color: fontColor }} component="span" variant="body1">
              <span>
                {date(
                  showContext.competition.startAt,
                  showContext?.competition?.channel?.dateFormatExtReact,
                )}
              </span>
              <span>&nbsp;&bull;&nbsp;</span>
              <span className="roboto-regular">{time(showContext.competition.startAt)}</span>
            </Typography>
          </Box>
          <Box display="flex" mt={1}>
            <Typography component="span" variant="body1">
              {t('end').toUpperCase()}&nbsp;
            </Typography>
            <Typography style={{ color: fontColor }} component="span" variant="body1">
              <span>
                {date(
                  showContext.competition.endAt,
                  showContext?.competition?.channel?.dateFormatExtReact,
                )}
              </span>
              <span>&nbsp;&bull;&nbsp;</span>
              <span className="roboto-regular">{time(showContext.competition.endAt)}</span>
            </Typography>
          </Box>

          {showContext?.competition?.registrationCloseDate && (
            <Box display="flex" flexWrap="wrap" mt={1}>
              <Typography>{t('competition.label.registrationCloseDate')}</Typography>
              <Typography ml={1} style={{ color: fontColor }}>
                {date(
                  showContext.competition.registrationCloseDate,
                  showContext?.competition?.channel?.dateFormatExtReact,
                )}
              </Typography>
            </Box>
          )}
        </>
      )}

      {showContext.competition.eventType === 'team' && (
        <Box display="flex" mt={1}>
          <Typography>{t('competition.label.teamSize').toUpperCase()}</Typography>
          <Typography style={{ color: fontColor }} ml={1}>
            {showContext.competition?.teamSize}
          </Typography>
        </Box>
      )}
    </Paper>
  );
};
