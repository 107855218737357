/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Box from 'core/ui/Box';
import Typography from 'core/ui/Typography';
import TextFieldRounded from 'core/ui/TextFieldRounded';

const useStyles = makeStyles(() => ({
  spacingBox: {
    marginBottom: 4,
  },
}));

export default ({ watch, setValue, errors, required }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const defaultStyles = {
    fontWeight: '600',
    color: '#404041',
    fontSize: 12,
  };

  return (
    <Box mt={5} flexGrow="1">
      <Typography color="primary" mt={5} mb={3} variant="h5">
        {t('profile.title.emergencyContact').toUpperCase()}
      </Typography>

      <Box px={5} display="flex" className={classes.spacingBox} flexGrow="1">
        <TextFieldRounded
          classe="ph-bold"
          error={errors.emergencyName}
          value={watch('emergencyName') || ''}
          name="emergencyName"
          label={t('profile.label.name').toUpperCase()}
          onChange={(v) => setValue('emergencyName', v)}
          style={{
            ...defaultStyles,
          }}
          placeholder={t('profile.label.name')}
          required={required}
        />
      </Box>
      <Box px={5} display="flex" className={classes.spacingBox} flexGrow="1">
        <TextFieldRounded
          classe="ph-bold"
          error={errors.emergencyEmail}
          value={watch('emergencyEmail') || ''}
          name="emergencyEmail"
          label={t('profile.label.email').toUpperCase()}
          onChange={(v) => setValue('emergencyEmail', v)}
          style={{
            ...defaultStyles,
          }}
          placeholder={t('profile.label.email')}
          required={required}
        />
      </Box>
      <Box px={5} display="flex" className={classes.spacingBox} flexGrow="1">
        <TextFieldRounded
          classe="ph-bold"
          error={errors.emergencyPhone}
          value={watch('emergencyPhone') || ''}
          name="emergencyPhone"
          label={t('profile.label.phone').toUpperCase()}
          onChange={(v) => setValue('emergencyPhone', v)}
          style={{
            ...defaultStyles,
          }}
          placeholder={t('profile.label.phone')}
          required={required}
        />
      </Box>
    </Box>
  );
};
