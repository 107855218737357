import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

import * as selectors from './selectors';
import * as sagas from './sagas';

/* ---------- Action Creators and Types ---------- */
const { Types, Creators } = createActions(
  {
    getWorkoutError: ['error'],
    getWorkoutStart: ['id', 'callback', 'workoutsInviteId'],
    getWorkoutSuccess: ['workout'],

    getWorkoutDetailError: ['error'],
    getWorkoutDetailStart: ['id', 'callback'],
    getWorkoutDetailSuccess: ['workout'],

    getWorkoutsError: ['error'],
    getWorkoutsStart: ['userId'],
    getWorkoutsSuccess: ['workouts'],

    getWorkoutUsersError: ['error'],
    getWorkoutUsersStart: ['id', 'callback'],
    getWorkoutUsersSuccess: ['users'],

    setWorkoutUsers: ['users'],

    getWorkoutCoachesError: ['error'],
    getWorkoutCoachesStart: ['id'],
    getWorkoutCoachesSuccess: ['coaches'],

    getWorkoutSectionsError: ['error'],
    getWorkoutSectionsStart: ['id', 'callback'],
    getWorkoutSectionsSuccess: ['sections'],

    getWorkoutSectionLeaderboardError: ['error'],
    getWorkoutSectionLeaderboardStart: ['workoutId', 'sectionId', 'params', 'callback'],
    getWorkoutSectionLeaderboardSuccess: ['leaderboard'],

    postWorkoutError: ['error'],
    postWorkoutStart: ['data'],
    postWorkoutSuccess: ['workout'],

    putWorkoutError: ['error'],
    putWorkoutStart: ['data'],
    putWorkoutSuccess: ['workout'],

    putWorkoutSectionError: ['error'],
    putWorkoutSectionStart: ['data'],
    putWorkoutSectionSuccess: ['section'],

    putInactivateUserError: ['error'],
    putInactivateUserStart: ['data'],
    putInactivateUserSuccess: [''],

    putTrackUserError: ['error'],
    putTrackUserStart: ['data'],
    putTrackUserSuccess: ['section'],

    getWorkoutSectionUserError: ['error'],
    getWorkoutSectionUserStart: ['data'],
    getWorkoutSectionUserSuccess: ['sections'],

    deleteWorkoutSectionError: ['error'],
    deleteWorkoutSectionStart: ['data'],
    deleteWorkoutSectionSuccess: [],

    setWorkoutSectionUserError: ['error'],
    setWorkoutSectionUserStart: ['data', 'callback'],
    setWorkoutSectionUserSuccess: ['section'],

    setWorkoutSectionUserPenaltiesError: ['error'],
    setWorkoutSectionUserPenaltiesStart: ['data'],
    setWorkoutSectionUserPenaltiesSuccess: ['section'],

    setWorkoutCountdownSecondsError: ['error'],
    setWorkoutCountdownSecondsStart: ['data', 'callback'],
    setWorkoutCountdownSecondsSuccess: [''],

    addWorkoutSectionUserFlagError: ['error'],
    addWorkoutSectionUserFlagStart: ['data'],
    addWorkoutSectionUserFlagSuccess: [''],

    setWorkoutSectionUserVerifiedError: ['error'],
    setWorkoutSectionUserVerifiedStart: ['data'],
    setWorkoutSectionUserVerifiedSuccess: ['section'],

    postWorkoutRecordingInvitesError: ['error'],
    postWorkoutRecordingInvitesStart: ['data', 'callback'],
    postWorkoutRecordingInvitesSuccess: [''],

    getUpcomingError: ['error'],
    getUpcomingStart: ['userId', 'pagination'],
    getUpcomingSuccess: ['workouts', 'pagination'],

    getUpcomingAttendingsError: ['error'],
    getUpcomingAttendingsStart: ['userId', 'pagination'],
    getUpcomingAttendingsSuccess: ['workouts', 'pagination'],

    getRecentsError: ['error'],
    getRecentsStart: ['userId', 'pagination'],
    getRecentsSuccess: ['workouts', 'pagination'],

    getRecentsAttendingsError: ['error'],
    getRecentsAttendingsStart: ['userId', 'pagination'],
    getRecentsAttendingsSuccess: ['workouts', 'pagination'],

    getFavoritesError: ['error'],
    getFavoritesStart: ['userId', 'pagination'],
    getFavoritesSuccess: ['workouts', 'pagination'],

    getCoachAccessedError: ['error'],
    getCoachAccessedStart: ['workoutId'],
    getCoachAccessedSuccess: ['data'],

    purchaseError: ['error'],
    purchaseStart: ['data', 'callback', 'onError'],
    purchaseSuccess: [],

    refundError: ['error'],
    refundStart: ['data'],
    refundSuccess: [],

    setPurchaseSuccess: ['value'],

    clearWorkoutLeaderboard: [],
    clearWorkout: [],
    clearError: [],
  },
  { prefix: 'workouts/' },
);

/* ---------- Initial State ---------- */
const INITIAL_STATE = Immutable({
  data: [],
  leaderboard: [],
  workout: {},

  sectionUserData: {},
  sections: [],
  workoutUsers: [],
  workoutCoaches: [],

  upcomingData: [],
  upcomingPagination: {},

  recentsData: [],
  recentsPagination: {},

  recentsAttendingsData: [],
  recentsAttendingsPagination: {},

  upcomingAttendingsData: [],
  upcomingAttendingsPagination: {},

  favoritesData: [],
  purchaseSuccess: false,
  coachAccessed: false,
  dataTrackUser: [],

  upcomingRecordingInvites: [],
  upcomingRecordingInvitesPagination: {},

  loading: false,
  loadingDetail: false,
  loadingAthlete: false,
  loadSections: false,
  loadingWorkoutUsers: false,
  loadingWorkoutCoaches: false,
  loadingUpcoming: false,
  loadingUpcomingAttendings: false,
  loadingRecents: false,
  loadingRecentsAttendings: false,
  loadingFavorites: false,
  loadingPurchase: false,
  loadingRefund: false,
  loadingSectionUser: false,
  loadingWorkoutSection: false,
  loadingSectionLeaderboard: false,
  loadingWorkoutRecordingInvites: false,
  loadingUpcomingRecordingInvites: false,
  loadingAddWorkoutCoach: false,
  error: null,
});

/* ---------- Reducers ---------- */
const loadStartReducer = (state) => state.set('loading', true);
const loadDetailStartReducer = (state) => state.set('loadingDetail', true);
const loadWorkoutSectionStartReducer = (state) => state.set('loadingWorkoutSection', true);
const loadRefundReducer = (state) => state.set('loadingRefund', true);
const loadCoachAccessedStartReducer = (state) => state.set('loadingCoachAccessed', true);
const loadWorkoutUsersStartReducer = (state) => state.set('loadingWorkoutUsers', true);
const loadWorkoutCoachesStartReducer = (state) => state.set('loadingWorkoutCoaches', true);
const loadSectionsStartReducer = (state) => state.set('loadSections', true);
const loadUpcomingStartReducer = (state) => state.set('loadingUpcoming', true);
const loadUpcomingAttendingsStartReducer = (state) => state.set('loadingUpcomingAttendings', true);
const loadRecentsStartReducer = (state) => state.set('loadingRecents', true);
const loadRecentsAttendingsStartReducer = (state) => state.set('loadingRecentsAttendings', true);
const loadFavoritesStartReducer = (state) => state.set('loadingFavorites', true);
const loadWorkoutSectionUserStartReducer = (state) => state.set('loadingSectionUser', true);
const loadSectionLeaderboardStartReducer = (state) => state.set('loadingSectionLeaderboard', true);
const loadWorkoutRecordingInvitesStartReducer = (state) =>
  state.set('loadingWorkoutRecordingInvites', true);

const purchaseStartReducer = (state) =>
  state.merge({
    loadingPurchase: true,
    purchaseSuccess: false,
  });

const getWorkoutsSuccessReducer = (state, action) =>
  state.merge({
    data: action.workouts,
    loading: false,
    error: null,
  });

const getWorkoutSuccessReducer = (state, action) =>
  state.merge({
    workout: action.workout,
    workoutUsers: action.workout.users,
    loading: false,
    error: null,
  });

const getWorkoutDetailSuccessReducer = (state) =>
  state.merge({
    loadingDetail: false,
    error: null,
  });

const getUpcomingSuccessReducer = (state, action) =>
  state.merge({
    upcomingData: action.workouts,
    upcomingPagination: action.pagination,
    loadingUpcoming: false,
    error: null,
  });

const getUpcomingAttendingsSuccessReducer = (state, action) =>
  state.merge({
    upcomingAttendingsData: action.workouts,
    upcomingAttendingsPagination: action.pagination,
    loadingUpcomingAttendings: false,
    error: null,
  });

const getRecentsSuccessReducer = (state, action) =>
  state.merge({
    recentsData: action.workouts,
    recentsPagination: action.pagination,
    loadingRecents: false,
    error: null,
  });

const getWorkoutSectionLeaderboardSuccessReducer = (state, action) =>
  state.merge({
    leaderboard: action.leaderboard,
    loadingSectionLeaderboard: false,
    error: null,
  });

const loadSectionLeaderboardErrorReducer = (state, action) =>
  state.merge({
    error: action.error,
    loadingSectionLeaderboard: false,
  });

const getRecentsAttendingsSuccessReducer = (state, action) =>
  state.merge({
    recentsAttendingsData: action.workouts,
    recentsAttendingsPagination: action.pagination,
    loadingRecentsAttendings: false,
    error: null,
  });

const getFavoritesSuccessReducer = (state, action) =>
  state.merge({
    favoritesData: action.workouts,
    loadingFavorites: false,
    error: null,
  });

const loadErrorReducer = (state, action) =>
  state.merge({
    loading: false,
    loadingPurchase: false,
    error: action.error,
  });
const loadWorkoutErrorReducer = (state, action) =>
  state.merge({
    loading: false,
    workout: {},
    error: action.error,
  });

const loadSectionsErrorReducer = (state, action) =>
  state.merge({
    loadingSections: false,
    error: action.error,
  });

const loadWorkoutUsersErrorReducer = (state) =>
  state.merge({
    loadingWorkoutUsers: false,
  });

const loadWorkoutDetailErrorReducer = (state) =>
  state.merge({
    loadingDetail: false,
  });

const loadWorkoutCoachesErrorReducer = (state) =>
  state.merge({
    loadingWorkoutUsers: false,
  });

const loadUpcomingErrorReducer = (state, action) =>
  state.merge({
    loading: false,
    error: action.error,
  });

const loadRecentsErrorReducer = (state, action) =>
  state.merge({
    loadingRecents: false,
    error: action.error,
  });

const loadRecentsAttendingsErrorReducer = (state, action) =>
  state.merge({
    loadingRecentsAttendings: false,
    error: action.error,
  });

const loadWorkoutSectionErrorReducer = (state, action) =>
  state.merge({
    loadingWorkoutSection: false,
    error: action.error,
  });

const loadFavoritesErrorReducer = (state, action) =>
  state.merge({
    loading: false,
    error: action.error,
  });

const workoutSectionUserErrorReducer = (state, action) =>
  state.merge({
    loadingSectionUser: false,
    error: action.error,
  });

const loadCoachAccessedErrorReducer = (state, action) =>
  state.merge({
    loadingCoachAccessed: false,
    error: action.error,
  });

const loadRefundErrorReducer = (state, action) =>
  state.merge({
    loadingRefund: false,
    error: action.error,
  });

const postWorkoutSuccessReducer = (state, action) =>
  state.merge({
    loading: false,
    workout: action.workout,
  });

const putWorkoutSuccessReducer = (state, action) =>
  state.merge({
    loading: false,
    workout: action.workout,
  });

const deleteWorkoutSectionSuccessReducer = (state) =>
  state.merge({
    loading: false,
    deleteSectionSuccess: true,
  });

const purchaseSuccessReducer = (state) =>
  state.merge({
    loading: false,
    error: null,
    purchaseSuccess: true,
    loadingPurchase: false,
  });

const clearWorkoutReducer = (state) =>
  state.merge({
    workout: {},
    loading: false,
    error: null,
  });

const clearWorkoutLeaderboardReducer = (state) =>
  state.merge({
    leaderboard: [],
    loading: false,
    error: null,
  });

const clearErrorReducer = (state) =>
  state.merge({
    error: null,
  });

const loadWorkoutRecordingInvitesErrorReducer = (state) =>
  state.merge({
    loadingWorkoutRecordingInvites: false,
  });

const loadWorkoutRecordingInvitesSuccessReducer = (state) =>
  state.merge({
    loadingWorkoutRecordingInvites: false,
  });

const setPurchaseSuccessReducer = (state, action) =>
  state.merge({
    purchaseSuccess: action.value,
  });

const getCoachAccessedSuccessReducer = (state, action) =>
  state.merge({
    loadingCoachAccessed: false,
    coachAccessed: action.data,
  });

const getWorkoutSectionUserSuccessReducer = (state, action) => {
  const data = {};
  action.sections.forEach((section) => {
    data[section.workoutSectionId] = { ...section };
  });

  return state.merge({
    sectionUserData: data,
    loadingSectionUser: false,
  });
};

const getWorkoutUsersSuccessReducer = (state, action) =>
  state.merge({
    workoutUsers: action.users,
    loadingWorkoutUsers: false,
  });

const setWorkoutUsersReducer = (state, action) =>
  state.merge({
    workoutUsers: action.users,
  });

const getWorkoutCoachesSuccessReducer = (state, action) =>
  state.merge({
    workoutCoaches: action.coaches,
    loadingWorkoutCoaches: false,
  });

const putWorkoutSectionSuccessReducer = (state) =>
  state.merge({
    loadingWorkoutSection: false,
  });

const setWorkoutSectionUserPenaltiesSuccessReducer = (state) =>
  state.merge({
    loadingSectionUser: false,
  });

const addWorkoutSectionUserFlagSuccessReducer = (state) =>
  state.merge({
    loadingSectionUser: false,
  });

const setWorkoutSectionUserVerifiedSuccessReducer = (state) =>
  state.merge({
    loadingSectionUser: false,
  });

const setWorkoutSectionUserSuccessReducer = (state, action) => {
  const prevSectionUserData = { ...state.sectionUserData };
  prevSectionUserData[action.section.workoutSectionId] = action.section;

  return state.merge({
    sectionUserData: prevSectionUserData,
    loadingSectionUser: false,
  });
};

const putInactivateUserSuccessReducer = (state) =>
  state.merge({
    loading: false,
  });

const refundSuccessReducer = (state) =>
  state.merge({
    loadingRefund: false,
  });

const putTrackUserSuccessReducer = (state, action) => {
  const prevSectionUserData = { ...state.sectionUserData };
  prevSectionUserData[action.section.workoutSectionId] = action.section;

  return state.merge({
    sectionUserData: prevSectionUserData,
    loadingSectionUser: false,
  });
};

const getWorkoutSectionsSuccessReducer = (state, action) =>
  state.merge({
    loading: false,
    sections: action.sections,
  });

/* ---------- Main Reducer ---------- */
const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_WORKOUT_START]: loadStartReducer,
  [Types.GET_WORKOUT_SUCCESS]: getWorkoutSuccessReducer,
  [Types.GET_WORKOUT_ERROR]: loadWorkoutErrorReducer,

  [Types.GET_WORKOUT_DETAIL_START]: loadDetailStartReducer,
  [Types.GET_WORKOUT_DETAIL_SUCCESS]: getWorkoutDetailSuccessReducer,
  [Types.GET_WORKOUT_DETAIL_ERROR]: loadWorkoutDetailErrorReducer,

  [Types.GET_WORKOUTS_START]: loadStartReducer,
  [Types.GET_WORKOUTS_SUCCESS]: getWorkoutsSuccessReducer,
  [Types.GET_WORKOUTS_ERROR]: loadErrorReducer,

  [Types.GET_WORKOUT_SECTIONS_START]: loadSectionsStartReducer,
  [Types.GET_WORKOUT_SECTIONS_SUCCESS]: getWorkoutSectionsSuccessReducer,
  [Types.GET_WORKOUT_SECTIONS_ERROR]: loadSectionsErrorReducer,

  [Types.GET_WORKOUT_SECTION_LEADERBOARD_START]: loadSectionLeaderboardStartReducer,
  [Types.GET_WORKOUT_SECTION_LEADERBOARD_SUCCESS]: getWorkoutSectionLeaderboardSuccessReducer,
  [Types.GET_WORKOUT_SECTION_LEADERBOARD_ERROR]: loadSectionLeaderboardErrorReducer,

  [Types.GET_WORKOUT_USERS_START]: loadWorkoutUsersStartReducer,
  [Types.GET_WORKOUT_USERS_SUCCESS]: getWorkoutUsersSuccessReducer,
  [Types.GET_WORKOUT_USERS_ERROR]: loadWorkoutUsersErrorReducer,

  [Types.SET_WORKOUT_USERS]: setWorkoutUsersReducer,

  [Types.GET_WORKOUT_COACHES_START]: loadWorkoutCoachesStartReducer,
  [Types.GET_WORKOUT_COACHES_SUCCESS]: getWorkoutCoachesSuccessReducer,
  [Types.GET_WORKOUT_COACHES_ERROR]: loadWorkoutCoachesErrorReducer,

  [Types.POST_WORKOUT_START]: loadStartReducer,
  [Types.POST_WORKOUT_SUCCESS]: postWorkoutSuccessReducer,
  [Types.POST_WORKOUT_ERROR]: loadErrorReducer,

  [Types.DELETE_WORKOUT_SECTION_START]: loadStartReducer,
  [Types.DELETE_WORKOUT_SECTION_SUCCESS]: deleteWorkoutSectionSuccessReducer,
  [Types.DELETE_WORKOUT_SECTION_ERROR]: loadErrorReducer,

  [Types.PUT_WORKOUT_START]: loadStartReducer,
  [Types.PUT_WORKOUT_SUCCESS]: putWorkoutSuccessReducer,
  [Types.PUT_WORKOUT_ERROR]: loadErrorReducer,

  [Types.PUT_WORKOUT_SECTION_START]: loadWorkoutSectionStartReducer,
  [Types.PUT_WORKOUT_SECTION_SUCCESS]: putWorkoutSectionSuccessReducer,
  [Types.PUT_WORKOUT_SECTION_ERROR]: loadWorkoutSectionErrorReducer,

  [Types.PUT_INACTIVATE_USER_START]: loadWorkoutUsersStartReducer,
  [Types.PUT_INACTIVATE_USER_SUCCESS]: putInactivateUserSuccessReducer,
  [Types.PUT_INACTIVATE_USER_ERROR]: loadWorkoutUsersErrorReducer,

  [Types.PUT_TRACK_USER_START]: loadWorkoutSectionUserStartReducer,
  [Types.PUT_TRACK_USER_SUCCESS]: putTrackUserSuccessReducer,
  [Types.PUT_TRACK_USER_ERROR]: workoutSectionUserErrorReducer,

  [Types.GET_UPCOMING_START]: loadUpcomingStartReducer,
  [Types.GET_UPCOMING_SUCCESS]: getUpcomingSuccessReducer,
  [Types.GET_UPCOMING_ERROR]: loadUpcomingErrorReducer,

  [Types.POST_WORKOUT_RECORDING_INVITES_START]: loadWorkoutRecordingInvitesStartReducer,
  [Types.POST_WORKOUT_RECORDING_INVITES_SUCCESS]: loadWorkoutRecordingInvitesSuccessReducer,
  [Types.POST_WORKOUT_RECORDING_INVITES_ERROR]: loadWorkoutRecordingInvitesErrorReducer,

  [Types.GET_UPCOMING_ATTENDINGS_START]: loadUpcomingAttendingsStartReducer,
  [Types.GET_UPCOMING_ATTENDINGS_SUCCESS]: getUpcomingAttendingsSuccessReducer,
  [Types.GET_UPCOMING_ATTENDINGS_ERROR]: loadUpcomingErrorReducer,

  [Types.GET_RECENTS_START]: loadRecentsStartReducer,
  [Types.GET_RECENTS_SUCCESS]: getRecentsSuccessReducer,
  [Types.GET_RECENTS_ERROR]: loadRecentsErrorReducer,

  [Types.GET_RECENTS_ATTENDINGS_START]: loadRecentsAttendingsStartReducer,
  [Types.GET_RECENTS_ATTENDINGS_SUCCESS]: getRecentsAttendingsSuccessReducer,
  [Types.GET_RECENTS_ATTENDINGS_ERROR]: loadRecentsAttendingsErrorReducer,

  [Types.GET_FAVORITES_START]: loadFavoritesStartReducer,
  [Types.GET_FAVORITES_SUCCESS]: getFavoritesSuccessReducer,
  [Types.GET_FAVORITES_ERROR]: loadFavoritesErrorReducer,

  [Types.PURCHASE_START]: purchaseStartReducer,
  [Types.PURCHASE_SUCCESS]: purchaseSuccessReducer,
  [Types.PURCHASE_ERROR]: loadErrorReducer,

  [Types.REFUND_START]: loadRefundReducer,
  [Types.REFUND_SUCCESS]: refundSuccessReducer,
  [Types.REFUND_ERROR]: loadRefundErrorReducer,

  [Types.GET_WORKOUT_SECTION_USER_START]: loadWorkoutSectionUserStartReducer,
  [Types.GET_WORKOUT_SECTION_USER_SUCCESS]: getWorkoutSectionUserSuccessReducer,
  [Types.GET_WORKOUT_SECTION_USER_ERROR]: workoutSectionUserErrorReducer,

  [Types.GET_COACH_ACCESSED_START]: loadCoachAccessedStartReducer,
  [Types.GET_COACH_ACCESSED_SUCCESS]: getCoachAccessedSuccessReducer,
  [Types.GET_COACH_ACCESSED_ERROR]: loadCoachAccessedErrorReducer,

  [Types.SET_WORKOUT_SECTION_USER_START]: loadWorkoutSectionUserStartReducer,
  [Types.SET_WORKOUT_SECTION_USER_SUCCESS]: setWorkoutSectionUserSuccessReducer,
  [Types.SET_WORKOUT_SECTION_USER_ERROR]: workoutSectionUserErrorReducer,

  [Types.SET_WORKOUT_SECTION_USER_PENALTIES_START]: loadWorkoutSectionUserStartReducer,
  [Types.SET_WORKOUT_SECTION_USER_PENALTIES_SUCCESS]: setWorkoutSectionUserPenaltiesSuccessReducer,
  [Types.SET_WORKOUT_SECTION_USER_PENALTIES_ERROR]: workoutSectionUserErrorReducer,

  [Types.SET_WORKOUT_COUNTDOWN_SECONDS_START]: loadWorkoutSectionUserStartReducer,
  [Types.SET_WORKOUT_COUNTDOWN_SECONDS_SUCCESS]: setWorkoutSectionUserPenaltiesSuccessReducer,
  [Types.SET_WORKOUT_COUNTDOWN_SECONDS_ERROR]: workoutSectionUserErrorReducer,

  [Types.ADD_WORKOUT_SECTION_USER_FLAG_START]: loadWorkoutSectionUserStartReducer,
  [Types.ADD_WORKOUT_SECTION_USER_FLAG_SUCCESS]: addWorkoutSectionUserFlagSuccessReducer,
  [Types.ADD_WORKOUT_SECTION_USER_FLAG_ERROR]: workoutSectionUserErrorReducer,

  [Types.SET_WORKOUT_SECTION_USER_VERIFIED_START]: loadWorkoutSectionUserStartReducer,
  [Types.SET_WORKOUT_SECTION_USER_VERIFIED_SUCCESS]: setWorkoutSectionUserVerifiedSuccessReducer,
  [Types.SET_WORKOUT_SECTION_USER_VERIFIED_ERROR]: workoutSectionUserErrorReducer,

  [Types.SET_PURCHASE_SUCCESS]: setPurchaseSuccessReducer,

  [Types.CLEAR_WORKOUT]: clearWorkoutReducer,
  [Types.CLEAR_WORKOUT_LEADERBOARD]: clearWorkoutLeaderboardReducer,
  [Types.CLEAR_ERROR]: clearErrorReducer,
});

/* ---------- Exporting ---------- */
export const workoutsActions = Creators;
export const workoutsTypes = Types;
export const workoutsSelectors = selectors;
export const workoutsSagas = sagas;

export default reducer;
