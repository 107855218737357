/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from 'react';
import { ButtonPrimarySm } from 'core/ui/Button';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import Typography from 'core/ui/Typography';
import Paper from 'core/ui/Paper';
import Radio from 'core/ui/Radio';
import Checkbox from 'core/ui/Checkbox';
import { currency } from 'core/utils/formatters';
import { calculateServiceFees, valueTaxAmount } from 'core/utils/helpers';

import useActions from 'modules/map/useActions';
import Modules from 'modules';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import ModalDocumentViewer from '../../../../DocumentsBuilder/ModalDocumentViewer';

const ContractInfo = ({ currentPlan, setCurrentPlan, channel }) => {
  const { t } = useTranslation();
  const [modalViewPdf, setModalViewPdf] = useState({ open: false });
  const { request } = useActions();
  const [data, setData] = useState([]);

  const salesTax =
    // eslint-disable-next-line eqeqeq
    channel?.channelsTaxes?.find((x) => x.id == currentPlan?.channelsTaxId) || null;

  const getData = () => {
    request({
      action: Modules.documents.actions.getDocuments,
      data: { ownerableId: channel.id, ownerableType: 'Channel', kind: 'contract' },
      options: {
        onSuccess: (resp) => {
          setData(resp);
        },
      },
    });
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Paper p={3} mt={2} className="paper-rounded">
        <Box display="flex">
          <Box flexGrow={1}>
            <Typography>{t('channel.membership.contract')}</Typography>
          </Box>
        </Box>

        <Box p={3}>
          <Box mt={2}>
            <SelectBoxObject
              label="From"
              emptyItem
              placeholder={t('channel.membership.contract.select')}
              emptyValue={null}
              value={currentPlan?.documentContractId}
              setValue={(v) => setCurrentPlan((prev) => ({ ...prev, documentContractId: v }))}
              options={data}
            />
          </Box>
          {currentPlan?.documentContractId && modalViewPdf.open && (
            <ModalDocumentViewer
              close={() => setModalViewPdf({ open: false })}
              document={data?.find((x) => x.id === currentPlan?.documentContractId)}
              open={modalViewPdf.open}
            />
          )}
          {currentPlan?.documentContractId && (
            <Box display="flex" justifyContent="center" my={3} mb={1}>
              <ButtonPrimarySm color="primary" onClick={() => setModalViewPdf({ open: true })}>
                {t('button.viewContract')}
              </ButtonPrimarySm>
            </Box>
          )}
        </Box>

        <Box display="flex" alignItems="center" mx={3} mb={2}>
          <Typography>{t('channel.membership.label.contractTime')}</Typography>

          <Box display="flex" mx={2}>
            <Radio
              label={t('channel.memberships.custom.none')}
              onClick={() => setCurrentPlan((prev) => ({ ...prev, contractTimeType: null }))}
              checked={currentPlan.contractTimeType === null}
              mr={3}
            />
            <Radio
              label={t('channel.memberships.custom.day')}
              onClick={() => setCurrentPlan((prev) => ({ ...prev, contractTimeType: 'day' }))}
              checked={currentPlan.contractTimeType === 'day'}
              mr={3}
            />
            <Radio
              label={t('channel.memberships.custom.week')}
              onClick={() => setCurrentPlan((prev) => ({ ...prev, contractTimeType: 'week' }))}
              checked={currentPlan.contractTimeType === 'week'}
              mr={3}
            />
            <Radio
              label={t('channel.memberships.custom.month')}
              onClick={() => setCurrentPlan((prev) => ({ ...prev, contractTimeType: 'month' }))}
              checked={currentPlan.contractTimeType === 'month'}
              mr={3}
            />
            <Radio
              label={t('channel.memberships.custom.year')}
              onClick={() => setCurrentPlan((prev) => ({ ...prev, contractTimeType: 'year' }))}
              checked={currentPlan.contractTimeType === 'year'}
            />
          </Box>
        </Box>
        {currentPlan.contractTimeType && (
          <Box px={5} mb={2}>
            <TextFieldRounded
              type="number"
              placeholder={`How many ${currentPlan.contractTimeType}s`}
              pattern="[1-9]*"
              value={currentPlan.contractTimeNumber || ''}
              onChange={(v) =>
                setCurrentPlan((prev) => ({ ...prev, contractTimeNumber: v > 0 ? v : 0 }))
              }
              required
              bordered
            />
          </Box>
        )}

        <Box px={5}>
          {!currentPlan?.contractRenew && (
            <>
              <Box mb={2}>
                <Checkbox
                  label={t('channel.memberships.changeAmountAfterEnd')}
                  onClick={() =>
                    setCurrentPlan((prev) => ({
                      ...prev,
                      contractChangeAmountAfterEnd: !prev?.contractChangeAmountAfterEnd,
                    }))
                  }
                  checked={currentPlan?.contractChangeAmountAfterEnd}
                />
              </Box>
              {currentPlan.contractChangeAmountAfterEnd && (
                <Box my={3}>
                  <TextFieldRounded
                    type="number"
                    value={currentPlan.contractNewPrice}
                    pattern="[0-9]*"
                    placeholder={t('channel.placeholder.contractNewPrice')}
                    label={t('channel.memberships.contractNewPrice')}
                    onChange={(v) =>
                      setCurrentPlan((prev) => ({ ...prev, contractNewPrice: v >= 0 ? v : 0 }))
                    }
                    currency
                    currencySymbol={channel?.currency?.symbol}
                    bordered
                  />
                  <Typography mx={2} variant="caption">
                    {t('channel.memberships.contractNewPrice')}&nbsp;{channel?.currency?.symbol}
                    {currency(
                      Number(calculateServiceFees(currentPlan, currentPlan.contractNewPrice)) +
                        valueTaxAmount(
                          calculateServiceFees(currentPlan, currentPlan.contractNewPrice || 0),
                          currentPlan?.addSalesTax ? salesTax : null,
                        ),
                    )}
                    /Key
                  </Typography>
                </Box>
              )}
            </>
          )}

          <Box>
            <Checkbox
              label={t('channel.memberships.contractRenew')}
              onClick={() =>
                setCurrentPlan((prev) => ({ ...prev, contractRenew: !prev?.contractRenew }))
              }
              checked={currentPlan?.contractRenew}
            />
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default ContractInfo;
