import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import { Dialog, Link } from '@material-ui/core';
import StoreProducts from 'components/Store/Products';
import useActions from 'modules/map/useActions';
import useSelectors from 'modules/map/useSelectors';
import { partnersModule } from 'modules/partners';
import Modules from 'modules';
import Paper from 'core/ui/Paper';
import Typography from 'core/ui/Typography';
import { ButtonPrimary } from 'core/ui/Button';
import ConfirmDialog from 'core/ui/ConfirmDialog';
import Scrollbars from 'react-custom-scrollbars';
import Texture from 'core/ui/Texture';
import notifications from 'modules/notifications';
import { useDispatch } from 'react-redux';
import ModalPartnerProduct from './ModalPartnerProduct';

const PartnersMarketPlace = ({ channelId, setRefreshProducts, hasStripe }) => {
  const { t } = useTranslation();
  const [terms, setTerms] = useState(false);
  const [viewDetail, setViewDetail] = useState(null);
  const [selectedItem, setSelectedItem] = useState({});
  const dispatch = useDispatch();
  const { request } = useActions();
  const [confirmCancel, setConfirmCancel] = useState({ open: false });
  const [refreshPartnerProducts, setRefreshPartnerProducts] = useState(false);
  const partners = useSelectors(partnersModule, 'data');

  useEffect(() => {
    request({
      action: Modules.channelsContracts.actions.check,
      data: {
        channelId,
        kind: 'marketplace_terms',
      },
      options: {
        onSuccess: (data) => {
          setTerms(data?.accepted);
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewDetail, selectedItem]);

  const accept = () => {
    request({
      action: Modules.channelsContracts.actions.accept,
      data: {
        channelId,
        kind: 'marketplace_terms',
      },
      options: {
        onSuccess: () => {
          setTerms(true);
        },
      },
    });
  };

  const getPartners = () => {
    request({
      action: Modules.partners.actions.getPartners,
      data: {
        channelId,
      },
    });
  };

  useEffect(() => {
    getPartners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelId]);

  return (
    <>
      <Typography variant="h5" color="secondary" mb={2}>
        {t('partner.marketplace')}
      </Typography>
      <Scrollbars autoHeight>
        <Box display="flex" style={{ height: 140, width: '100%' }} className="list-hover">
          {partners.map((item) => (
            <Paper
              key={item?.id}
              p={3}
              className="paper-rounded item"
              onClick={() =>
                hasStripe
                  ? setViewDetail(item)
                  : dispatch(
                      notifications.warning(
                        'You will need a stripe account connected to use the partner marketplace',
                      ),
                    )
              }
              mr={2}
              style={{ height: 135, width: 230, minWidth: 230 }}
            >
              <Box display="flex" flexDirection="column" alignItems="center">
                <Box p={3} style={{ maxHeight: 80, overflow: 'hidden' }}>
                  <img src={item?.image?.image?.url} alt="Partner" style={{ width: 100 }} />
                </Box>
                <Box mt={2}>
                  <Typography>{item.companyName}</Typography>
                </Box>
              </Box>
            </Paper>
          ))}
        </Box>
      </Scrollbars>

      <ConfirmDialog
        open={confirmCancel.open}
        confirm={() => confirmCancel.confirm()}
        cancel={() => setConfirmCancel({ open: false })}
        description={t('confirm.active.partner')}
        labelConfirm={t('message.contract.i_agree_to')}
        labelCancel={t('button.cancel')}
      >
        <Box mt={1} display="flex" justifyContent="center">
          <Link target="_blank" href="https://streamfit.com/terms-of-use">
            {t('message.contract.terms_of_use')}
          </Link>
        </Box>
      </ConfirmDialog>

      {!!viewDetail && (
        <Dialog
          maxWidth="xs"
          fullWidth
          scroll="body"
          onClose={() => setViewDetail(null)}
          open={!!viewDetail}
        >
          <Box p={3}>
            <Typography variant="h5" align="center" m={1} mb={3}>
              {t('partner.title')}&nbsp;{viewDetail?.companyName}
            </Typography>
            <Box display="flex" flexDirection="column" alignItems="center" px={3} pb={3}>
              <Box>
                <img src={viewDetail?.image?.image?.url} alt="Partner" style={{ width: 80 }} />
              </Box>
              <Box my={3}>
                <Typography>{viewDetail?.companyName}</Typography>
              </Box>
              <Box mb={3}>
                <Typography>{viewDetail?.details}</Typography>
              </Box>
              <Box>
                <ButtonPrimary
                  style={{ marginLeft: 2 }}
                  onClick={() => setSelectedItem((prev) => ({ ...prev, partner: viewDetail }))}
                >
                  {t('button.chooseProducts')}
                </ButtonPrimary>
              </Box>
            </Box>
          </Box>
          <Dialog
            maxWidth="lg"
            fullWidth
            scroll="body"
            onClose={() => setSelectedItem({})}
            open={!!selectedItem?.partner}
          >
            <Texture>
              {!!selectedItem?.partner && (
                <Box p={3}>
                  {!terms && (
                    <Box display="flex" alignItems="center" justifyContent="flex-end" mb={3}>
                      <Typography mr={3}>
                        Accept the{' '}
                        <Link target="_blank" href="https://streamfit.com/terms-of-use">
                          {t('message.contract.terms_of_use')}
                        </Link>{' '}
                        before adding products to your store.
                      </Typography>
                      <ButtonPrimary onClick={() => accept()}>
                        {t('button.acceptTerms')}
                      </ButtonPrimary>
                    </Box>
                  )}
                  {selectedItem?.partner?.id && (
                    <StoreProducts
                      setSelectProduct={(product, callback) =>
                        terms &&
                        setSelectedItem((prev) => ({
                          ...prev,
                          storeProduct: product,
                          callback,
                        }))
                      }
                      ownerableType="Partner"
                      ownerableId={selectedItem?.partner?.id}
                      channelId={channelId}
                      taxPercent={selectedItem?.partner?.taxPercent}
                      refreshProducts={refreshPartnerProducts}
                      hideActions={!terms}
                      marketPlaceView
                    />
                  )}
                </Box>
              )}
            </Texture>
          </Dialog>
        </Dialog>
      )}

      <ModalPartnerProduct
        open={selectedItem?.partner && selectedItem?.storeProduct}
        storeProduct={selectedItem?.storeProduct}
        partner={selectedItem?.partner}
        callback={() => {
          if (selectedItem?.callback) {
            selectedItem.callback();
          }
          setRefreshProducts((prev) => !prev);
          setRefreshPartnerProducts((prev) => !prev);
          getPartners();
        }}
        channelId={channelId}
        close={() => setSelectedItem((prev) => ({ ...prev, storeProduct: null }))}
      />
    </>
  );
};

PartnersMarketPlace.defaultProps = {
  close: () => {},
  open: false,
};

export default PartnersMarketPlace;
