import React, { useState } from 'react';
import Box from 'core/ui/Box';

import Typography, { fontStyle } from 'core/ui/Typography';

import { useTranslation } from 'react-i18next';
import { Collapse, IconButton, Link } from '@material-ui/core';
import {
  KeyboardArrowDown,
  KeyboardArrowRight,
  Close,
  Edit,
  FileCopy,
  ArrowUpward,
} from '@material-ui/icons';
import { secondary, secondaryLight } from 'core/ui/Colors';
import Divider from 'core/ui/Divider';
import LabelBreakLine from 'core/ui/LabelBreakLine';
import ConfirmDialog from 'core/ui/ConfirmDialog';
import { workoutsActions } from 'modules/workouts';
import { useDispatch } from 'react-redux';
import { appActions } from 'modules/app';
import {
  convertTimerToForm,
  listScores,
  workoutChannelPrograms,
  dynamicSort,
} from 'core/utils/helpers';
import SectionPersonalRecords from 'components/Workout/Show/Tabs/Overview/SectionPersonalRecords';
import Paper from 'core/ui/Paper';
import ViewVideo from 'components/LibraryMedias/MediaFilesGallery/ViewVideo';
import MIcon from 'core/ui/MIcon';
import Button, { ButtonPrimary } from 'core/ui/Button';
import ModalOpenAI from 'core/ui/ModalOpenAI';
import OpenAIIcon from 'core/ui/FontIcon/OpenAIIcon';
import SectionTimerInfo from './SectionTimerInfo';
import ViewSectionScoresTypes from './ViewSectionScoresTypes';

const styleIcon = {
  color: secondary,
  fontSize: 20,
};

const styleBox = {
  background: '#fff',
};

// eslint-disable-next-line react/prop-types
export default ({
  openEditSection,
  addNewSection,
  sections,
  setSections,
  competition,
  workout,
  mode,
  setMovements,
}) => {
  const { t } = useTranslation();
  const [openConfirm, setOpenConfirm] = useState(false);
  const [selSection, setSelSection] = useState(null);
  const [modalAI, setModalAI] = useState(null);
  const dispatch = useDispatch();

  const removeSection = (section) => {
    setSelSection(section);
    setOpenConfirm(true);
  };

  const callBackSuccessDelete = () => {
    const ar = [...sections].filter((sec) => sec !== selSection);
    setSections([...ar]);
    dispatch(appActions.setLoading(false));
  };

  const confirmRemove = () => {
    if (selSection.new !== true && selSection.id && mode === 'edit') {
      dispatch(
        workoutsActions.deleteWorkoutSectionStart({
          workoutId: workout.id,
          sectionId: selSection.id,
          success: callBackSuccessDelete,
        }),
      );
    } else {
      callBackSuccessDelete();
    }
  };

  const editSection = (section, index) => {
    const data = {};

    const mediaFiles = section?.mediaFilesAssociationsAttributes || section?.mediaFilesAssociations;

    data.section = {
      ...section,
      mediaFilesAssociations: mediaFiles?.map((ass) => ({
        id: !section?.id ? null : ass.id,
        mediaFileId: ass.mediaFile?.id,
        mediaFile: ass.mediaFile,
        kind: ass.kind,
      })),
      mediaFilesAssociationsAttributes: mediaFiles?.map((ass) => ({
        id: !section?.id ? null : ass.id,
        mediaFileId: ass.mediaFile?.id,
        mediaFile: ass.mediaFile,
        kind: ass.kind,
      })),
    };

    if (index !== undefined && index !== null) {
      data.section.index = index;
    } else {
      delete data.section.index;
    }
    data.timerData = convertTimerToForm(section.timerData);

    openEditSection(data);
  };

  const reorderSection = (sec, index) => {
    const ar = [];

    const newIndex = index > 0 ? index - 1 : 0;

    sections.forEach((section, idx) => {
      if (section.id === sec.id) {
        ar.push({ ...section, order: newIndex });
      } else if (idx >= newIndex) {
        ar.push({ ...section, order: idx + 1 });
      } else {
        ar.push({ ...section, order: idx });
      }
    });

    setSections(ar);
  };

  const allSections = sections ? sections.sort(dynamicSort('order')) : [];
  const hasPrograms = sections && sections.find((x) => !!x?.channelProgram);

  return (
    <Box display="flex" flexDirection="column" style={{ height: '100%' }}>
      <ConfirmDialog
        open={openConfirm}
        setOpen={setOpenConfirm}
        confirm={confirmRemove}
        cancel={() => setOpenConfirm(false)}
        labelCancel={t('cancel')}
        labelConfirm={t('confirm')}
        title={t('workout.section.modal.remove.title')}
        description={t('workout.section.modal.remove.description')}
      />
      <Box display="flex" mb={2} alignItems="center">
        <Box flexGrow={1}>
          <Typography variant="h5" color="primary">
            {t(competition ? 'workout.section.title.competition' : 'workout.sections')}
          </Typography>
        </Box>

        {/* <ModalOpenAI
          setMovements={setMovements}
          open={modalAI}
          close={() => setModalAI(false)}
          setSections={setSections}
        />
        <Button onClick={() => setModalAI(true)}>
          <OpenAIIcon />
          &nbsp; AI Generate
        </Button> */}

        {(!competition || allSections?.length === 0) && addNewSection && (
          <ButtonPrimary style={{ minWidth: 100 }} onClick={() => addNewSection()}>
            {t('button.addSection')}
          </ButtonPrimary>
        )}
      </Box>

      {!hasPrograms && (
        <>
          {allSections.map((itemSection, idx) => (
            <ItemSection
              section={itemSection}
              index={idx}
              key={itemSection.id}
              editSection={(sec) => editSection(sec)}
              removeSection={removeSection}
              reorderSection={reorderSection}
            />
          ))}
        </>
      )}

      {sections?.length === 0 && (
        <Box display="flex" flexDirection="column" alignItems="center" m={5}>
          <Typography style={{ ...fontStyle.medium }} mb={2}>
            {t(competition ? 'workout.section.subtitle.competition' : 'workout.section.subtitle')}
          </Typography>

          <Link color="primary" className="hover" onClick={() => addNewSection()}>
            {t('button.createNewSection')}
          </Link>
        </Box>
      )}

      {!!hasPrograms && !!sections.find((x) => !x?.channelProgram) && (
        <Paper p={2} mb={1}>
          <Typography color="primary" mb={1}>
            All
          </Typography>
          {allSections
            .filter((x) => !x.channelProgram)
            .map((itemSection, idx) => (
              <ItemSection
                section={itemSection}
                index={idx}
                key={itemSection.id}
                editSection={(sec) => editSection(sec)}
                removeSection={removeSection}
                reorderSection={reorderSection}
              />
            ))}
        </Paper>
      )}

      {!!hasPrograms &&
        workoutChannelPrograms(sections).map((item) => (
          <Paper key={item.id} p={2} mb={1}>
            <Typography color="primary" mb={1}>
              {item?.name || 'All'}
            </Typography>

            {allSections
              .filter((x) => x.channelProgram?.id === item?.id)
              .map((itemSection, idx) => (
                <ItemSection
                  section={itemSection}
                  index={idx}
                  editSection={editSection}
                  removeSection={removeSection}
                  competition={competition}
                  reorderSection={reorderSection}
                />
              ))}
          </Paper>
        ))}
    </Box>
  );
};

const ProgrammingTag = ({ section }) => (
  <Box>
    <Box className="tag">
      <MIcon icon="fitness_center" color="black" size={12} />
      &nbsp;{section?.partnersProgram?.name}
    </Box>
  </Box>
);

const CompareTag = ({ t }) => (
  <Box>
    <Box className="tag" style={{ width: 70, background: secondaryLight }}>
      <MIcon icon="timeline" color="black" size={12} />
      &nbsp;{t('tag.compareSection')}
    </Box>
  </Box>
);

const ItemSection = ({
  competition,
  section,
  index,
  editSection,
  removeSection,
  reorderSection,
}) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const mediaFiles = section?.mediaFilesAssociationsAttributes || section?.mediaFilesAssociations;
  const sectionVideos = mediaFiles
    ?.filter((x) => x.kind === 'section' && !!x.mediaFile && !x?._destroy)
    .map((x) => x.mediaFile);
  const coachNotesVideos = mediaFiles
    ?.filter((x) => x.kind === 'coach_notes' && !!x.mediaFile && !x?._destroy)
    .map((x) => x.mediaFile);
  const athleteNotesVideos = mediaFiles
    ?.filter((x) => x.kind === 'ahtlete_notes' && !!x.mediaFile && !x?._destroy)
    .map((x) => x.mediaFile);

  return (
    <Box style={styleBox}>
      <Box px={5} pt={1} className="hover" display="flex" alignItems="center">
        <Box onClick={() => setOpen(!open)} flexGrow="1" display="flex">
          <Box>
            <Typography style={{ color: secondary }}>{section?.title?.toUpperCase()}</Typography>
            {section?.partnersProgram?.id && <ProgrammingTag section={section} />}
            {(!!section?.parentId || !!section?.benchmarkSectionId) && (
              <CompareTag section={section} t={t} />
            )}
          </Box>
          {open === true ? (
            <KeyboardArrowDown style={{ ...styleIcon }} />
          ) : (
            <KeyboardArrowRight style={{ ...styleIcon }} />
          )}
        </Box>
        <Box>
          <IconButton onClick={() => reorderSection(section, index)}>
            <ArrowUpward />
          </IconButton>
          <IconButton onClick={() => editSection({ ...section, id: null, new: true }, null)}>
            <FileCopy />
          </IconButton>
          {!section?.parentId && !section.benchmarkSectionId && (
            <IconButton onClick={() => editSection(section, index)}>
              <Edit />
            </IconButton>
          )}
          {!competition && (
            <IconButton onClick={() => removeSection(section)}>
              <Close />
            </IconButton>
          )}
        </Box>
      </Box>

      <Box>
        <Collapse in={competition || open}>
          <Box px={8} pb={5}>
            <SectionPersonalRecords section={section} />
            <Box style={{ overflowWrap: 'break-word' }}>
              <Typography mb={3} className="roboto-regular" variant="body2">
                <LabelBreakLine>{section.description}</LabelBreakLine>
              </Typography>
            </Box>
            {section?.channelProgram && (
              <Box display="flex" alignItems="center" mb={3}>
                <Typography mr={1} variant="body2">
                  {t('channel.program')}
                </Typography>
                <Typography className="roboto-regular" variant="body2">
                  {section?.channelProgram?.name}
                </Typography>
              </Box>
            )}
            {sectionVideos?.length > 0 && (
              <Box my={3}>
                {sectionVideos.map((x) => (
                  <Box key={x.id} mb={1}>
                    <ViewVideo url={x?.url} />
                  </Box>
                ))}
              </Box>
            )}

            {(section.coachNotes || coachNotesVideos?.length > 0) && (
              <>
                <Typography mb={1} className="roboto-regular" variant="body2">
                  <b>{t('workout.coachNotes').toUpperCase()}</b>
                </Typography>
                <Typography className="roboto-regular" variant="body2">
                  <LabelBreakLine>{section.coachNotes}</LabelBreakLine>
                </Typography>
                {coachNotesVideos?.length > 0 && (
                  <Box my={3}>
                    {coachNotesVideos.map((x) => (
                      <Box key={x.id} mb={1}>
                        <ViewVideo url={x?.url} />
                      </Box>
                    ))}
                  </Box>
                )}
              </>
            )}
            {(section.athleteNotes || athleteNotesVideos?.length > 0) && (
              <>
                <Typography mb={1} className="roboto-regular" variant="body2">
                  <b>{t('workout.section.label.athleteNotes').toUpperCase()}</b>
                </Typography>
                <Typography className="roboto-regular" variant="body2">
                  <LabelBreakLine>{section.athleteNotes}</LabelBreakLine>
                </Typography>
                {athleteNotesVideos?.length > 0 && (
                  <Box my={3}>
                    {athleteNotesVideos.map((x) => (
                      <Box key={x.id} mb={1}>
                        <ViewVideo url={x?.url} />
                      </Box>
                    ))}
                  </Box>
                )}
              </>
            )}

            <Box my={1}>
              {listScores(section, t) && (
                <Box>
                  <ViewSectionScoresTypes scores={section?.scores} />
                </Box>
              )}
            </Box>
            <SectionTimerInfo section={section} showTimer />
          </Box>
        </Collapse>
      </Box>
      <Divider mx={4} />
    </Box>
  );
};
