import React from 'react';
import { Helmet } from 'react-helmet';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import Typography, { fontStyle } from 'core/ui/Typography';
import { colorPdfBackground, primary } from 'core/ui/Colors';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import Radio from 'core/ui/Radio';
import Checkbox from 'core/ui/Checkbox';
import SelectBox from 'core/ui/SelectBox';
import LabelBreakLine from 'core/ui/LabelBreakLine';
import { ButtonPrimary } from 'core/ui/Button';
import LoaderSm from 'core/ui/LoaderSm';
import { DialogActions, DialogContent } from '@material-ui/core';
import useDocumentViewer from './useDocumentViewer';
import { PDFSection } from './Sections/PDFSection';
import { SignatureSection } from './Sections/SignatureSection';

const Content = ({ document, contract, watch, setValue, errors, itemsEls, signaturesErrors }) => {
  const p = { marginBottom: 1 };
  const { t } = useTranslation();

  return (
    <div>
      {contract?.id ? (
        <Typography align="center" variant="h4" color="primary" m={5}>
          {t('contract.accepted.message')}
        </Typography>
      ) : (
        <Box>
          {document?.sections?.map((section, index) => (
            <Box key={section?.id || index} mb={2}>
              {section?.kind === 'paragraph' && (
                <Typography style={p} className="roboto-regular">
                  <LabelBreakLine>{section.text}</LabelBreakLine>
                </Typography>
              )}
              {section?.kind === 'text' && (
                <>
                  <Typography mb={1} style={p}>
                    <LabelBreakLine>{section.text}</LabelBreakLine>
                  </Typography>
                  <Box>
                    <TextFieldRounded
                      bordered
                      value={watch(`section-${section?.id}`)}
                      onChange={(v) => setValue(`section-${section?.id}`, v)}
                      error={errors[`section-${section?.id}`]}
                    />
                  </Box>
                </>
              )}
              {section?.kind === 'pdf' && (
                <Box style={{ backgroundColor: colorPdfBackground, padding: 3 }}>
                  <Helmet>
                    <meta
                      name="viewport"
                      content="width=device-width, initial-scale=1,maximum-scale=3"
                    />
                  </Helmet>

                  <PDFSection file={section.s3Url} />
                </Box>
              )}

              {(section?.kind === 'radio' || section?.kind === 'checkbox') && (
                <Box>
                  <Box>
                    <Typography style={p}>
                      <LabelBreakLine>{section.text}</LabelBreakLine>
                    </Typography>
                  </Box>

                  <Box>
                    {section?.options?.map &&
                      section?.options?.map((x) => (
                        <Box key={x}>
                          {section?.kind === 'radio' ? (
                            <Radio
                              color="secondary"
                              checked={watch(`section-${section?.id}`) === x}
                              onClick={() => setValue(`section-${section?.id}`, x)}
                              label={x}
                            />
                          ) : (
                            <Checkbox
                              color="secondary"
                              checked={
                                watch(`section-${section?.id}`)?.find &&
                                watch(`section-${section?.id}`)?.find((y) => y === x)
                              }
                              onClick={() => {
                                let arr = watch(`section-${section?.id}`) || [];

                                if (arr.find((y) => y === x)) {
                                  arr = arr.filter((y) => y !== x);
                                } else {
                                  arr.push(x);
                                }

                                setValue(`section-${section?.id}`, arr);
                              }}
                              label={x}
                            />
                          )}
                        </Box>
                      ))}
                    {errors[`section-${section?.id}`] && (
                      <Typography m={1} style={fontStyle.xSmall} color="error">
                        {section?.text} is required
                      </Typography>
                    )}
                  </Box>
                </Box>
              )}
              {section?.kind === 'dropdown' && (
                <Box>
                  <Box>
                    <Typography style={p}>
                      <LabelBreakLine>{section.text}</LabelBreakLine>
                    </Typography>
                  </Box>

                  <Box>
                    <SelectBox
                      bordered
                      options={section?.options}
                      error={errors[`section-${section?.id}`]}
                      value={watch(`section-${section?.id}`)}
                      setValue={(v) => setValue(`section-${section?.id}`, v)}
                    />
                  </Box>
                </Box>
              )}
              {section?.kind === 'signature' && (
                <SignatureSection
                  itemsEls={itemsEls}
                  section={section}
                  signaturesErrors={signaturesErrors}
                  index={index}
                />
              )}
            </Box>
          ))}
        </Box>
      )}
    </div>
  );
};

const ViewerContent = ({ contractKey, callback, document, userId, buttonColor, embed }) => {
  const { t } = useTranslation();

  const { watch, setValue, errors, submit, contract, loading, itemsEls, signaturesErrors } =
    useDocumentViewer({
      document,
      userId,
      contractKey,
      callback,
    });

  return (
    <div>
      {embed ? (
        <>
          <div className="tw-p-2.5">
            <Content
              document={document}
              contract={contract}
              watch={watch}
              setValue={setValue}
              errors={errors}
              itemsEls={itemsEls}
              signaturesErrors={signaturesErrors}
            />
          </div>

          {document && !contract && (
            <Box display="flex" justifyContent="center" pt={2} pb={10}>
              {!!userId && (
                <ButtonPrimary
                  buttonColor={buttonColor || primary}
                  disabled={loading}
                  style={{ height: 40 }}
                  onClick={() => submit()}
                >
                  <LoaderSm loading={loading} />
                  {document?.kind === 'questionnaire' ? t('button.save') : t('button.acceptTerms')}
                </ButtonPrimary>
              )}
            </Box>
          )}
        </>
      ) : (
        <>
          <DialogContent>
            <Content
              document={document}
              contract={contract}
              watch={watch}
              setValue={setValue}
              errors={errors}
              itemsEls={itemsEls}
              signaturesErrors={signaturesErrors}
            />
          </DialogContent>
          <DialogActions>
            {document && !contract && (
              <Box display="flex" justifyContent="center" p={2}>
                {!!userId && (
                  <ButtonPrimary
                    buttonColor={buttonColor || primary}
                    disabled={loading}
                    style={{ height: 40 }}
                    onClick={() => submit()}
                  >
                    <LoaderSm loading={loading} />
                    {document?.kind === 'questionnaire'
                      ? t('button.save')
                      : t('button.acceptTerms')}
                  </ButtonPrimary>
                )}
              </Box>
            )}
          </DialogActions>
        </>
      )}
    </div>
  );
};

export default ViewerContent;
