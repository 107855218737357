import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

import * as selectors from './selectors';
import * as sagas from './sagas';

/* ---------- Action Creators and Types ---------- */
const { Types, Creators } = createActions(
  {
    getRecurringsByChannelError: ['error'],
    getRecurringsByChannelStart: ['channelId'],
    getRecurringsByChannelSuccess: ['data'],

    stopRecurringError: ['error'],
    stopRecurringStart: ['recurringsIds', 'disable', 'startAtRemoveWorkouts', 'callback'],
    stopRecurringSuccess: [],

    extendRecurringError: ['error'],
    extendRecurringStart: ['data', 'callback'],
    extendRecurringSuccess: [],

    clearRecurring: [],
  },
  { prefix: 'recurrings/' },
);

/* ---------- Initial State ---------- */
const INITIAL_STATE = Immutable({
  data: [],
  recurring: {},
  pagination: {},
  error: null,
  loading: false,
  loadingChannelRecurrings: false,
});

/* ---------- Reducers ---------- */
const loadStartReducer = (state) => state.set('loading', true);
const loadChannelRecurringsStartReducer = (state) => state.set('loadingChannelRecurrings', true);

const getRecurringsByChannelSuccessReducer = (state, action) =>
  state.merge({
    data: action.data,
    loadingChannelRecurrings: false,
    error: null,
  });

const loadErrorReducer = (state, action) =>
  state.merge({
    recurring: {},
    pagination: {},
    loading: false,
    error: action.error,
  });

const clearRecurringReducer = (state) =>
  state.merge({
    recurring: {},
    loading: false,
  });

const loadRecurringsByChannelErrorReducer = (state) =>
  state.merge({
    data: [],
    loadingChannelRecurrings: false,
  });

const stopRecurringSuccessReducer = (state) =>
  state.merge({
    loading: false,
  });

const extendRecurringSuccessReducer = (state) =>
  state.merge({
    loading: false,
  });

/* ---------- Main Reducer ---------- */
const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_RECURRINGS_BY_CHANNEL_START]: loadChannelRecurringsStartReducer,
  [Types.GET_RECURRINGS_BY_CHANNEL_SUCCESS]: getRecurringsByChannelSuccessReducer,
  [Types.GET_RECURRINGS_BY_CHANNEL_ERROR]: loadRecurringsByChannelErrorReducer,

  [Types.STOP_RECURRING_START]: loadStartReducer,
  [Types.STOP_RECURRING_SUCCESS]: stopRecurringSuccessReducer,
  [Types.STOP_RECURRING_ERROR]: loadErrorReducer,

  [Types.EXTEND_RECURRING_START]: loadStartReducer,
  [Types.EXTEND_RECURRING_SUCCESS]: extendRecurringSuccessReducer,
  [Types.EXTEND_RECURRING_ERROR]: loadErrorReducer,

  [Types.CLEAR_RECURRING]: clearRecurringReducer,
});

/* ---------- Exporting ---------- */
export const recurringsActions = Creators;
export const recurringsTypes = Types;
export const recurringsSelectors = selectors;
export const recurringsSagas = sagas;

export default reducer;
