/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { gymDate, timeWithoutTz } from 'core/utils/formatters';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import { showAPIError } from 'core/utils/showAPIError';
import useSelectors from 'modules/map/useSelectors';
import { usersCalendarsModule } from 'modules/usersCalendars';
import Backdrop from 'core/ui/Backdrop';
import SFDataTable from 'core/ui/DataTable';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core';
import { Delete, Visibility } from '@material-ui/icons';
import useConfirmation from 'core/useConfirmation';
import ModalEventInfo from '../ModalEventInfo';

const CalendarsRegistration = ({
  events,
  perPage,
  setPerPage,
  pagination,
  userId,
  channel,
  getCalendarsEvents,
}) => {
  const { request } = useActions();
  const { t } = useTranslation();
  const loadingEvents = useSelectors(usersCalendarsModule, 'loadingEvents');
  const [modalEvent, setModalEvent] = useState({ open: false });

  const { confirmation } = useConfirmation();

  const handleEventClick = (event) => {
    if (event?.type === 'registration' && event?.allowDestroy) {
      setModalEvent({ open: true, event });
    }
  };

  const deleteRegistration = (reg) => {
    confirmation({
      description: t('areYouSure'),
      yesClick: () => {
        request({
          action: Modules.usersCalendarsRegistrations.actions.deleteRegistration,
          data: {
            userId,
            usersCalendarId: reg?.usersCalendar?.id,
            channelId: channel.id,
            id: reg?.id,
          },
          options: {
            onSuccess: () => {
              getCalendarsEvents();
            },
            onError: showAPIError,
          },
        });
      },
    });
  };

  const confirmRegistration = (reg, callback = null) => {
    confirmation({
      description: t('areYouSure'),
      yesClick: () => {
        request({
          action: Modules.usersCalendarsRegistrations.actions.updateRegistration,
          data: {
            status: 'confirmed',
            usersCalendarId: reg?.usersCalendar?.id,
            channelId: channel.id,
            id: reg?.id,
          },
          options: {
            onSuccess: () => {
              getCalendarsEvents();
              if (callback) {
                callback();
              }
            },
            onError: showAPIError,
          },
        });
      },
    });
  };

  const columns = [
    {
      name: <div className="tw-font-normal">{t('label.coach')}</div>,
      selector: '',
      sortable: false,
      cell: (row) => <div>{row?.usersCalendar?.user?.name}</div>,
    },
    {
      name: <div className="tw-font-normal">{t('label.member')}</div>,
      selector: '',
      sortable: false,
      cell: (row) => <div>{row?.user?.name}</div>,
    },
    {
      name: <div className="tw-font-normal">{t('channel.workoutsTypes')}</div>,
      selector: '',
      sortable: false,
      cell: (row) => <div>{row?.usersCalendar?.channelsWorkoutsType?.name}</div>,
    },
    {
      name: <div className="tw-font-normal">{t('label.confirmed')}</div>,
      selector: '',
      sortable: false,
      cell: (row) => <div>{row?.confirmed ? 'YES' : 'NO'}</div>,
    },
    {
      name: <div className="tw-font-normal">{t('label.startAt')}</div>,
      selector: '',
      sortable: false,
      cell: (row) => (
        <div className="tw-font-normal">
          {gymDate(row?.startAt)}&nbsp;-&nbsp;{timeWithoutTz(row?.startAt)}
        </div>
      ),
    },
    {
      name: <div className="tw-font-normal">{t('label.duration')}</div>,
      selector: '',
      sortable: false,
      cell: (row) => <div>{t(`duration.minutes.${row?.duration}`)}</div>,
    },
    {
      name: <div className="tw-font-normal">{t('label.endAt')}</div>,
      selector: '',
      sortable: false,
      cell: (row) => (
        <div className="tw-font-normal">
          {gymDate(row?.endAt)}&nbsp;-&nbsp;{timeWithoutTz(row?.endAt)}
        </div>
      ),
    },
    {
      name: '',
      width: 100,
      selector: '',
      sortable: true,
      cell: (row) => (
        <div>
          <IconButton size="small" onClick={() => handleEventClick(row)}>
            <Visibility />
          </IconButton>
          <IconButton size="small" onClick={() => deleteRegistration(row)}>
            <Delete />
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <div className="tw-mb-20 md:tw-mb-2">
      <ModalEventInfo
        {...modalEvent}
        onConfirm={confirmRegistration}
        close={() => setModalEvent({})}
        channel={channel}
      />

      <SFDataTable
        columns={columns}
        data={events}
        pagination
        showExport={false}
        noHeader
        paginationPerPage={perPage}
        onChangePage={(p) => getCalendarsEvents(p)}
        paginationServer
        paginationDefaultPage={pagination?.currentPage}
        paginationTotalRows={pagination?.totalCount}
        onChangeRowsPerPage={(newValue) => {
          setPerPage(newValue);
        }}
      />

      {loadingEvents && <Backdrop />}
    </div>
  );
};

export default CalendarsRegistration;
