import { Skeleton } from '@material-ui/lab';
import Box from 'core/ui/Box';
import Grid from 'core/ui/Grid';
import React from 'react';

const SkeletonDashboard = () => {
  const styleBox = {
    borderRadius: 10,
    overflow: 'hidden',
  };
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Skeleton variant="rect" style={{ height: 200, width: '100%' }} />
        </Grid>
      </Grid>

      <Box mt={3} style={{ ...styleBox, borderRadius: 5 }}>
        <Skeleton variant="rect" style={{ height: 60, width: '100%' }} />
      </Box>

      <Box mt={3} style={{ ...styleBox, borderRadius: 5 }}>
        <Skeleton variant="rect" style={{ height: 60, width: '100%' }} />
      </Box>

      <Box mt={5} style={styleBox} className="container-aspect container-aspect-37">
        <Box className="item-aspect">
          <Skeleton variant="rect" style={{ height: '100%', width: '100%' }} />
        </Box>
      </Box>
    </>
  );
};

export default SkeletonDashboard;
