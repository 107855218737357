import React, { useContext, useEffect, useState } from 'react';
import Box from 'core/ui/Box';
import Grid from 'core/ui/Grid';
import Typography, { fontStyle } from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import Paper from 'core/ui/Paper';
import { useDispatch } from 'react-redux';
import { workoutsActions } from 'modules/workouts';
import { ButtonPrimary } from 'core/ui/Button';
import ModalClassesToRegister from 'components/Channel/Manage/Tabs/Members/Memberships/MemberInformation/ModalRegisterClass';
import ConfirmDialog from 'core/ui/ConfirmDialog';
import { channelMembersActions } from 'modules/channelMembers';
import { listScores } from 'core/utils/helpers';
import Divider from 'core/ui/Divider';
import { primary, secondary } from 'core/ui/Colors';
import Modules from 'modules';
import useActions from 'modules/map/useActions';
import notifications from 'modules/notifications';
import CardAthlete from './Card';
import { ShowContext } from '../../index';
import ModalAddMember from './ModalAddMember';
import ModalAddScores from './ModalAddScores';
import useStylesAthletes from './styles';
import ModalAddCoach from './ModalAddCoach';
import Waitlists from './Waitlists';

const WorkoutAttendance = () => {
  const showContext = useContext(ShowContext);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { request } = useActions();
  const classes = useStylesAthletes();
  const [selectedUser, setSelectedUser] = useState(null);
  const [view, setView] = useState('online');
  const [modalAddMember, setModalAddMember] = useState({ open: false });
  const [modalAddCoach, setModalAddCoach] = useState({ open: false });
  const [modalAddScores, setModalAddScores] = useState({ open: false });
  const [confirmCancel, setConfirmCancel] = useState({ open: false });

  const [selectedUsers, setSelectedUsers] = useState([]);

  const buttonColor = showContext?.workout?.channel?.customColors?.buttonColor || primary;

  const refreshWorkout = () => {
    if (showContext.refreshWorkout) {
      showContext.refreshWorkout();
    } else {
      dispatch(workoutsActions.getWorkoutStart(showContext.workout.id));
    }

    setTimeout(() => {
      if (showContext.setTab) showContext.setTab('attendance');
    }, 1000);
  };

  const checkInUser = (id) => {
    request({
      action: Modules.newWorkouts.actions.checkInUsers,
      data: {
        workoutId: showContext?.workout?.id,
        userId: id,
      },
      options: {
        onSuccess: () => {
          refreshWorkout();
        },
        onError: (error) => {
          dispatch(notifications.error(error));
        },
      },
    });
  };

  const checkInUsers = () => {
    request({
      action: Modules.newWorkouts.actions.checkInUsers,
      data: {
        workoutId: showContext.workout.id,
        usersIds: selectedUsers,
      },
      options: {
        onSuccess: () => {
          setSelectedUsers([]);
          refreshWorkout();
        },
        onError: (error) => {
          refreshWorkout();

          dispatch(notifications.error(error));
        },
      },
    });
  };

  const openModalAddMembers = () => {
    setModalAddMember({ open: true });
  };

  const openModalAddCoaches = () => {
    setModalAddCoach({ open: true });
  };

  const toggleSelectedUser = (selected, userId) => {
    if (selected) {
      setSelectedUsers(selectedUsers.filter((x) => x !== userId));
    } else {
      setSelectedUsers((prev) => [...prev, userId]);
    }
  };

  const onUnRegisterClick = (userId) => {
    setConfirmCancel((prev) => ({
      ...prev,
      open: true,
      confirm: () => {
        setConfirmCancel({ open: false });
        dispatch(
          channelMembersActions.memberUnregisterClassStart(
            showContext.workout.channel.id,
            userId,
            showContext.workout.id,
            () => {
              refreshWorkout();
            },
          ),
        );
      },
    }));
  };

  const isCoach =
    showContext.workout.isCoach ||
    (showContext.workout.channel &&
      ['admin', 'admin_coach', 'owner', 'coach_head'].find(
        (x) => x === showContext.workout.channel.accessType,
      ));
  const hasScores =
    showContext.workout && !!showContext.workout?.sections.find((s) => !!listScores(s, t));
  const isOnline = showContext.workout.online;
  const isInPerson = showContext.workout.inPerson;

  useEffect(() => {
    if (isInPerson) {
      setView('inPerson');
      return;
    }
    if (isOnline) {
      setView('online');
    }
  }, [isOnline, isInPerson]);

  const selectAll = () => {
    if (selectedUsers.length > 0) {
      setSelectedUsers([]);
      return;
    }
    const allUsers =
      showContext.workout.users &&
      showContext.workout.users
        .filter((x) => !!x.user)
        .filter((x) => !x.checkIn)
        .filter((x) => (view === 'online' ? !x.inPerson : !!x.inPerson));

    setSelectedUsers(allUsers.map((x) => x.user.id));
  };

  const classCountInfo = (online = true) => {
    if (online) {
      return `(${showContext.workout?.countOnlineUsers}/${showContext.workout?.maxUsers})`;
    }
    if (showContext.workout?.inPersonMaxUsers <= 0) {
      return '';
    }
    return `(${showContext.workout?.countInPersonUsers}/${showContext.workout?.inPersonMaxUsers})`;
  };

  return (
    <>
      {modalAddMember.open && (
        <ModalAddMember
          channelId={showContext.workout.channel.id}
          close={() => setModalAddMember({ open: false })}
          setSelectedUser={setSelectedUser}
          buttonColor={buttonColor}
        />
      )}
      {modalAddCoach.open && (
        <ModalAddCoach
          workout={showContext.workout}
          close={() => setModalAddCoach({ open: false })}
          onAddCoach={refreshWorkout}
          setSelectedUser={setSelectedUser}
          buttonColor={buttonColor}
        />
      )}

      {modalAddScores.open && (
        <ModalAddScores
          open={modalAddScores.open}
          close={() => setModalAddScores({ open: false })}
          user={modalAddScores.user}
        />
      )}

      <ConfirmDialog
        open={confirmCancel.open}
        confirm={() => confirmCancel.confirm()}
        cancel={() => setConfirmCancel({ open: false })}
        description={t('button.unregister.confirm')}
        labelConfirm={t('button.confirm')}
        labelCancel={t('button.cancel')}
      />

      {selectedUser && (
        <ModalClassesToRegister
          channelId={showContext.workout.channel.id}
          workoutToAdd={showContext.workout}
          isCoach={showContext.workout.isCoach}
          open
          user={selectedUser}
          close={() => setSelectedUser(null)}
          callback={() => {
            setModalAddMember({ open: false });
            setSelectedUser(null);
            refreshWorkout();
          }}
          buttonColor={buttonColor}
        />
      )}

      <Paper elevation={2} className="paper-rounded" p={3}>
        <Box display="flex" flexWrap="wrap" alignItems="center">
          <Box flexGrow={1}>
            <Box display="flex" alignItems="flex-end">
              <Typography variant="h5" component="h4">
                {t('workout.number.athletes')}
              </Typography>
              {isCoach && (
                <Typography
                  ml={2}
                  style={{ ...fontStyle.medium, color: buttonColor }}
                  className="hover"
                  onClick={() => selectAll()}
                >
                  {t(
                    selectedUsers.length > 0
                      ? 'workout.users.deselectAll'
                      : 'workout.users.selectAll',
                  )}
                </Typography>
              )}
            </Box>
            <Box>
              <Typography style={fontStyle.medium}>{classCountInfo(view === 'online')}</Typography>
            </Box>
          </Box>

          <Box>
            {isInPerson && isOnline && (
              <>
                <Typography
                  className={classes.buttonLeft}
                  component="span"
                  style={{ backgroundColor: view === 'inPerson' ? buttonColor : secondary }}
                  onClick={() => setView('inPerson')}
                >
                  {t('participants.inPerson')}
                </Typography>
                <Typography
                  className={classes.buttonRight}
                  style={{ backgroundColor: view === 'online' ? buttonColor : secondary }}
                  onClick={() => setView('online')}
                  component="span"
                >
                  {t('participants.online')}
                </Typography>
              </>
            )}
          </Box>

          {isCoach && (
            <Box display="flex" flexGrow={1} justifyContent="flex-end">
              {selectedUsers && selectedUsers.length > 0 ? (
                <ButtonPrimary
                  buttonColor={secondary}
                  style={{ width: 130 }}
                  onClick={() => checkInUsers()}
                >
                  {t('button.groupCheckIn')}
                </ButtonPrimary>
              ) : (
                <ButtonPrimary
                  buttonColor={buttonColor}
                  style={{ width: 130 }}
                  onClick={() => openModalAddMembers()}
                >
                  {t('button.addMember')}
                </ButtonPrimary>
              )}
            </Box>
          )}
        </Box>

        <Divider my={2} />
        <Grid container>
          {view === 'online' && isOnline && (
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Box>
                {showContext.workout.users
                  ?.filter((wu) => wu.user && !wu.inPerson)
                  ?.map((item, index, arr) => (
                    <Box key={item.id}>
                      <CardAthlete
                        workout={showContext.workout}
                        athlete={item.user}
                        fullWidth
                        clickable={false}
                        checked={item.checkIn}
                        channelKeyId={item.channelKeyId}
                        pendingPostPayment={item.pendingPostPayment}
                        refreshWorkout={refreshWorkout}
                        checkInUser={checkInUser}
                        onUnRegisterClick={onUnRegisterClick}
                        isCoach={isCoach}
                        buttonColor={buttonColor}
                        addScoresUser={
                          hasScores ? (u) => setModalAddScores({ open: true, user: u }) : null
                        }
                        selected={selectedUsers.find((x) => x === item.user.id)}
                        setSelected={(selected) => toggleSelectedUser(selected, item.user.id)}
                      />

                      {arr.length - 1 > index && <Divider my={2} />}
                    </Box>
                  ))}
              </Box>
            </Grid>
          )}
          {view === 'inPerson' && isInPerson && (
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Box>
                {showContext.workout.users
                  ?.filter((wu) => wu.user && wu.inPerson)
                  ?.map((item, index, arr) => (
                    <Box key={item.id}>
                      <CardAthlete
                        workout={showContext.workout}
                        athlete={item.user}
                        fullWidth
                        clickable={false}
                        channelKeyId={item.channelKeyId}
                        pendingPostPayment={item.pendingPostPayment}
                        refreshWorkout={refreshWorkout}
                        checked={item.checkIn}
                        checkInUser={checkInUser}
                        onUnRegisterClick={onUnRegisterClick}
                        addScoresUser={
                          hasScores ? (u) => setModalAddScores({ open: true, user: u }) : null
                        }
                        isCoach={isCoach}
                        selected={selectedUsers.find((x) => x === item.user.id)}
                        setSelected={(selected) => toggleSelectedUser(selected, item.user.id)}
                      />

                      {arr.length - 1 > index && <Divider my={2} />}
                    </Box>
                  ))}
              </Box>
            </Grid>
          )}
        </Grid>
      </Paper>

      {(isCoach || showContext.workout?.registered || showContext.workout?.isInWaitlist) && (
        <Waitlists setSelectedUser={setSelectedUser} />
      )}

      <Paper mt={3} elevation={2} className="paper-rounded" p={3}>
        <Box display="flex" mb={5} flexWrap="wrap" alignItems="center">
          <Box flexGrow={1} display="flex" alignItems="flex-end">
            <Typography variant="h5" component="h4">
              {t('workout.coaches')}
            </Typography>
          </Box>

          {isCoach && (
            <Box display="flex" flexGrow={1} justifyContent="flex-end">
              <ButtonPrimary
                buttonColor={buttonColor}
                style={{ width: 130 }}
                onClick={() => openModalAddCoaches()}
              >
                {t('button.addCoach')}
              </ButtonPrimary>
            </Box>
          )}
        </Box>

        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {showContext.workout.coaches &&
              showContext.workout.coaches.map((item, index) => (
                <Box key={item.id}>
                  <CardAthlete
                    workout={showContext.workout}
                    athlete={item}
                    fullWidth
                    clickable={false}
                    checked={item.checkIn}
                    checkInUser={checkInUser}
                    onUnRegisterClick={onUnRegisterClick}
                    isCoach={isCoach}
                    coaches
                  />
                  {showContext.workout.coaches.length - 1 > index && <Divider my={2} />}
                </Box>
              ))}
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default WorkoutAttendance;
