/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from 'react';
import { ButtonGreen, ButtonPrimary } from 'core/ui/Button';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import Typography, { fontStyle } from 'core/ui/Typography';
import { useDispatch } from 'react-redux';
import { STRIPE_AUTH_URL } from 'core/env';
import { Link } from '@material-ui/core';
import ConfirmDialog from 'core/ui/ConfirmDialog';
import Card from 'core/ui/Card';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import { sessionActions } from 'modules/session';

const StripeConnectedAccount = ({ partner, updatePartner }) => {
  const { t } = useTranslation();
  const [confirmCancel, setConfirmCancel] = useState({ open: false });
  const dispatch = useDispatch();
  const { request } = useActions();

  const removeAccount = () => {
    const confirm = () => {
      setConfirmCancel({ open: false });
      if (partner?.id) {
        request({
          action: Modules.partners.actions.removeStripeAccount,
          data: {
            partnerId: partner?.id,
          },
          options: {
            onSuccess: () => {
              dispatch(sessionActions.authenticateStart());
              if (updatePartner) {
                updatePartner();
              }
            },
          },
        });
      }
    };

    setConfirmCancel((prev) => ({
      ...prev,
      open: true,
      confirm,
    }));
  };

  const jsonStr = { ownerableType: 'Partner', ownerableId: partner?.id };
  const partnerString = btoa(JSON.stringify(jsonStr));

  return (
    <Box>
      <Card
        btopcolor="#4fb94a"
        btopwidth={2}
        hidedivider="true"
        title={t('channel.stripe.connect.title')}
      >
        <ConfirmDialog
          open={confirmCancel.open}
          description={t('channel.stripe.removeAccount.confirm')}
          labelConfirm={t('button.yes')}
          labelCancel={t('button.no')}
          confirm={() => confirmCancel.confirm()}
          cancel={() => setConfirmCancel((prev) => ({ ...prev, open: false }))}
        />
        <Box p={3}>
          {partner.hasStripe ? (
            <Box>
              <ButtonPrimary fullWidth onClick={() => removeAccount()}>
                {t('channel.stripe.removeAccount')}
              </ButtonPrimary>
            </Box>
          ) : (
            <>
              <Link
                href={`${STRIPE_AUTH_URL}&state=${partnerString}`}
                className="hover"
                style={{ textDecoration: 'none' }}
              >
                <ButtonGreen fullWidth>
                  <Typography ml={1} variant="h5" style={{ color: 'white' }}>
                    {t('button.stripe.connect')}
                  </Typography>
                </ButtonGreen>
              </Link>
              <Typography mt={1} variant="body2" style={fontStyle.medium} color="secondary">
                {t('channel.stripe.connect.desc')}
                <Link href="https://stripe.com" target="_blank">
                  here
                </Link>
              </Typography>
            </>
          )}
        </Box>
      </Card>
    </Box>
  );
};

export default StripeConnectedAccount;
