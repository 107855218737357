import React from 'react';
import InputBase from '@material-ui/core/InputBase';
import PropTypes from 'prop-types';

export default function LabelTextField({
  name,
  inputRef,
  placeholder,
  error,
  value,
  onChange,
  style,
  classe,
  type,
  ...props
}) {
  return (
    <div style={{ width: '100%' }} className={error ? 'input-error' : ''}>
      {onChange === null && (
        <InputBase
          type="text"
          name={name}
          value={value}
          inputRef={inputRef}
          placeholder={placeholder}
          className={classe}
          fullWidth
          autoComplete="off"
          inputProps={{ style: { width: '100%', ...style }, type }}
          {...props}
        />
      )}
      {onChange !== null && (
        <InputBase
          name={name}
          onChange={(e) => onChange(e.target.value)}
          placeholder={placeholder}
          className={classe}
          value={value}
          fullWidth
          autoComplete="off"
          inputProps={{ style, type }}
          {...props}
        />
      )}
    </div>
  );
}

LabelTextField.propTypes = {
  name: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  error: PropTypes.object,
  inputRef: PropTypes.func,
  placeholder: PropTypes.string,
  classe: PropTypes.string,
  onChange: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  type: PropTypes.string,
};

LabelTextField.defaultProps = {
  name: null,
  error: null,
  classe: null,
  inputRef: null,
  placeholder: '',
  onChange: null,
  style: {
    fontSize: 16,
  },
  type: 'text',
};
