/* eslint-disable react/prop-types */
import React, { useState } from 'react';

import Typography from 'core/ui/Typography';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import Paper from 'core/ui/Paper';

import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  withStyles,
  TableCell,
  Collapse,
} from '@material-ui/core';
import FontIcon from 'core/ui/FontIcon';
import { primary, secondary } from 'core/ui/Colors';
import { KeyboardArrowDown, KeyboardArrowRight } from '@material-ui/icons';
import ModalVideoMovement from 'components/Stream/SideBar/components/Sections/VideoMov';

export default ({ workout, fontColor, className, competition }) => {
  const { t } = useTranslation();
  const [viewVideo, setViewVideo] = useState({ open: false });

  const [openTab, setOpenTab] = useState({
    movement: true,
    equipment: true,
  });

  const styleIcon = {
    color: secondary,
    fontSize: 20,
  };

  const hasMovementsOrEquipments = () => {
    if (workout?.equipment && workout?.equipment.length > 0) {
      return true;
    }
    if (workout?.movements && workout?.movements.length > 0) {
      return true;
    }
    return false;
  };

  return (
    <>
      {hasMovementsOrEquipments() && (
        <>
          {!competition && (
            <Paper elevation={2} className={`paper-rounded ${className}`} p={3}>
              <Box
                display="flex"
                alignItems="center"
                className="link"
                onClick={() => setOpenTab((prev) => ({ ...prev, equipment: !prev.equipment }))}
              >
                <Box flexGrow="1" display="flex" alignItems="center">
                  <FontIcon iconName="icon-dumbbell" color={fontColor || primary} size={20} />
                  <Typography ml={2} style={{ color: fontColor || primary }} variant="h5">
                    {t('workout.section.equipment').toUpperCase()}
                  </Typography>
                </Box>

                <Box>
                  {openTab.equipment === true ? (
                    <KeyboardArrowDown style={{ ...styleIcon }} />
                  ) : (
                    <KeyboardArrowRight style={{ ...styleIcon }} />
                  )}
                </Box>
              </Box>
              <Collapse in={openTab.equipment}>
                <Box mt={5}>
                  <TableContainer component={Paper}>
                    <Table size="small" aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell />
                          <StyledTableCell
                            align="left"
                            style={{ fontStyle: 'italic', color: fontColor || primary }}
                          >
                            {t('workout.section.equipmentAlt')}
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {workout?.equipment &&
                          workout?.equipment.map((eqp) => (
                            <StyledTableRow key={eqp.id}>
                              <StyledTableCell component="th" scope="row">
                                <span>&bull;&nbsp;</span> {eqp.equipment.name}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                <span>&bull;&nbsp;</span>{' '}
                                {eqp.equipmentAlt ? eqp.equipmentAlt.name : 'N/A'}
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Collapse>
            </Paper>
          )}

          <Paper mt={3} mb={3} elevation={2} className={`paper-rounded ${className}`} p={3}>
            <Box
              display="flex"
              alignItems="center"
              className="link"
              onClick={() => setOpenTab((prev) => ({ ...prev, movement: !prev.movement }))}
            >
              <Box flexGrow="1" display="flex" alignItems="center">
                <FontIcon iconName="icon-stretch" color={fontColor || primary} size={20} />
                <Typography ml={2} style={{ color: fontColor || primary }} variant="h5">
                  {t('workout.section.movements').toUpperCase()}
                </Typography>
              </Box>
              <Box>
                {openTab.equipment === true ? (
                  <KeyboardArrowDown style={{ ...styleIcon }} />
                ) : (
                  <KeyboardArrowRight style={{ ...styleIcon }} />
                )}
              </Box>
            </Box>
            <Collapse in={openTab.movement}>
              <Box mt={5}>
                <TableContainer component={Paper}>
                  <Table size="small" aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell />
                        <StyledTableCell
                          align="left"
                          style={{ fontStyle: 'italic', color: fontColor || primary }}
                        >
                          {t('workout.section.movementAlt')}
                        </StyledTableCell>
                        <StyledTableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {workout?.movements &&
                        workout?.movements.map((eqp) => (
                          <StyledTableRow key={eqp.id}>
                            <StyledTableCell component="th" scope="row">
                              <span>&bull;&nbsp;</span> {eqp.movement.name}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <span>&bull;&nbsp;</span>{' '}
                              {eqp.movementAlt ? eqp.movementAlt.name : 'N/A'}
                            </StyledTableCell>
                            <StyledTableCell>
                              {eqp.embedVideo && (
                                <Typography
                                  style={{ color: fontColor || primary }}
                                  className="link"
                                  onClick={() =>
                                    setViewVideo({
                                      idEqp: eqp.movement.id,
                                      open: true,
                                      url: eqp.embedVideo,
                                    })
                                  }
                                >
                                  {t('video').toUpperCase()}
                                </Typography>
                              )}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Collapse>
          </Paper>
        </>
      )}
      <ModalVideoMovement
        url={viewVideo.url}
        open={viewVideo.open}
        setOpen={(b) => setViewVideo((prev) => ({ ...prev, open: b }))}
      />
    </>
  );
};

const StyledTableCell = withStyles(() => ({
  head: {
    // backgroundColor: theme.palette.common.black,
    color: '#4fb94a',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);
