/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from 'react';
import { ButtonGreen, ButtonPrimary } from 'core/ui/Button';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import Typography, { fontStyle } from 'core/ui/Typography';

import { useDispatch, useSelector } from 'react-redux';
import { channelsActions, channelsSelectors } from 'modules/channels';
import { STRIPE_AUTH_URL } from 'core/env';
import { Chip, Link } from '@material-ui/core';
import ConfirmDialog from 'core/ui/ConfirmDialog';

const StripeConnectedAccount = () => {
  const channel = useSelector((state) => channelsSelectors.getChannel(state));
  const { t } = useTranslation();
  const [confirmCancel, setConfirmCancel] = useState({ open: false });

  const dispatch = useDispatch();

  const removeAccount = () => {
    const confirm = () => {
      setConfirmCancel({ open: false });
      if (channel.id) {
        dispatch(
          channelsActions.removeStripeAccountStart(channel.id, () => {
            dispatch(channelsActions.getChannelStart(channel.id, 'edit'));
          }),
        );
      }
    };

    setConfirmCancel((prev) => ({
      ...prev,
      open: true,
      confirm,
    }));
  };

  const jsonStr = { ownerableType: 'Channel', ownerableId: channel?.id };
  const channelString = btoa(JSON.stringify(jsonStr));

  return (
    <Box>
      <ConfirmDialog
        open={confirmCancel.open}
        description={t('channel.stripe.removeAccount.confirm')}
        labelConfirm={t('button.yes')}
        labelCancel={t('button.no')}
        confirm={() => confirmCancel.confirm()}
        cancel={() => setConfirmCancel((prev) => ({ ...prev, open: false }))}
      />
      <Box p={3}>
        {channel.hasStripe ? (
          <Box>
            <Box display="flex" mb={3} alignItems="center">
              {channel?.directCharges && (
                <Chip size="small" color="primary" label="Direct Charges" />
              )}
            </Box>
            <ButtonPrimary fullWidth onClick={() => removeAccount()}>
              {t('channel.stripe.removeAccount')}
            </ButtonPrimary>
          </Box>
        ) : (
          <>
            <Link
              href={`${STRIPE_AUTH_URL}&state=${channelString}`}
              className="hover"
              style={{ textDecoration: 'none' }}
            >
              <ButtonGreen fullWidth>
                <Typography ml={1} variant="h5" style={{ color: 'white' }}>
                  {t('button.stripe.connect')}
                </Typography>
              </ButtonGreen>
            </Link>
            <Typography mt={1} variant="body2" style={fontStyle.medium} color="secondary">
              {t('channel.stripe.connect.desc')}
              <Link href="https://stripe.com" target="_blank">
                here
              </Link>
            </Typography>
          </>
        )}
      </Box>
    </Box>
  );
};

export default StripeConnectedAccount;
