import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import { Dialog } from '@material-ui/core';
import Texture from 'core/ui/Texture';
import Typography, { fontStyle } from 'core/ui/Typography';
import Grid from 'core/ui/Grid';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import Radio from 'core/ui/Radio';
import { ButtonPrimary } from 'core/ui/Button';
import ItemProduct from 'components/Store/Products/ItemProduct';
import Paper from 'core/ui/Paper';
import DatePickerRounded from 'core/ui/DatePickers/DatePickerRounded';
import useForm from './useForm';

const ModalPartnerProduct = ({ open, close, channelId, partner, storeProduct, callback }) => {
  const { t } = useTranslation();

  const { setValue, watch, handleSubmit, onSubmit, errors, categories } = useForm({
    channelId,
    partnerId: partner?.id,
    storeProductId: storeProduct?.id,
    callback: () => {
      if (callback) {
        callback();
      }
      close();
    },
  });

  return (
    <Dialog maxWidth="sm" fullWidth scroll="body" onClose={() => close()} open={open}>
      <Texture>
        <Box p={3}>
          {storeProduct?.id && (
            <Paper p={2}>
              <ItemProduct item={storeProduct} />
            </Paper>
          )}
          <Paper p={3} mb={3} mt={2}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Box>
                  <SelectBoxObject
                    value={watch('storeCategoryId') || 0}
                    options={categories.filter((x) => !!x.active)}
                    propLabel="name"
                    propValue="id"
                    emptyItem
                    emptyValue={0}
                    setValue={(v) => setValue('storeCategoryId', v)}
                    placeholder={t('form.product.placeholder.category')}
                    error={errors.storeCategoryId}
                    label={t('form.product.label.category').toUpperCase()}
                    required
                    bordered
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box>
                  <Typography style={fontStyle.label} mb={2} variant="body1">
                    {t('form.product.label.showInCompetitions').toUpperCase()}
                  </Typography>
                  <Box display="flex">
                    <Radio
                      checked={!!watch('showInCompetitions')}
                      onClick={() => setValue('showInCompetitions', true)}
                      label={t('button.yes')}
                    />
                    <Box ml={2}>
                      <Radio
                        checked={!watch('showInCompetitions')}
                        onClick={() => setValue('showInCompetitions', false)}
                        label={t('button.no')}
                      />
                    </Box>
                  </Box>
                </Box>
              </Grid>
              {storeProduct?.allowPreSale && (
                <>
                  <Grid item xs={12} md={4}>
                    <Box>
                      <Typography style={fontStyle.label} mb={2} variant="body1">
                        {t('form.product.label.preSale').toUpperCase()}
                      </Typography>
                      <Box display="flex">
                        <Radio
                          checked={!!watch('preSale')}
                          onClick={() => setValue('preSale', true)}
                          label={t('button.yes')}
                        />
                        <Box ml={2}>
                          <Radio
                            checked={!watch('preSale')}
                            onClick={() => setValue('preSale', false)}
                            label={t('button.no')}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  {watch('preSale') && (
                    <>
                      <Grid item xs={12} md={4}>
                        <Box>
                          <Typography style={fontStyle.label} mb={2} variant="body1">
                            {t('form.product.label.preSaleStartDate').toUpperCase()}
                          </Typography>
                          <Box display="flex">
                            <DatePickerRounded
                              onChange={(v) => setValue('preSaleStartDate', v)}
                              value={watch('preSaleStartDate')}
                              disablePast={false}
                              autoOk
                              bordered
                            />
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Box>
                          <Typography style={fontStyle.label} mb={2} variant="body1">
                            {t('form.product.label.preSaleEndDate').toUpperCase()}
                          </Typography>
                          <Box display="flex">
                            <DatePickerRounded
                              onChange={(v) => setValue('preSaleEndDate', v)}
                              value={watch('preSaleEndDate')}
                              disablePast={false}
                              autoOk
                              bordered
                            />
                          </Box>
                        </Box>
                      </Grid>
                    </>
                  )}
                </>
              )}
            </Grid>
          </Paper>

          <Box display="flex" justifyContent="flex-end">
            <ButtonPrimary style={{ minWidth: 150 }} onClick={handleSubmit(onSubmit)}>
              {t('button.save')}
            </ButtonPrimary>
          </Box>
        </Box>
      </Texture>
    </Dialog>
  );
};

ModalPartnerProduct.defaultProps = {
  close: () => {},
  open: false,
};

export default ModalPartnerProduct;
