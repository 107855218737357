import React from 'react';
import { Dialog, Slide } from '@material-ui/core';

import WorkflowFormContext from './Context';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function ModalForm({
  open,
  close,
  channelId,
  callback,
  workflowId,
  showTemplate,
  isTemplate,
}) {
  return (
    <Dialog
      open={open}
      onClose={() => close()}
      style={{ marginTop: 90 }}
      fullScreen
      scroll="paper"
      PaperProps={{
        style: { overflow: 'hidden', borderTopRightRadius: 5, borderTopLeftRadius: 5 },
      }}
      TransitionComponent={Transition}
    >
      {open && (
        <WorkflowFormContext
          callback={callback}
          channelId={channelId}
          isTemplate={isTemplate}
          showTemplate={showTemplate}
          workflowId={workflowId}
          close={close}
        />
      )}
    </Dialog>
  );
}
