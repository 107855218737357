import React, { useContext, useState } from 'react';

import { useSelector } from 'react-redux';
import { channelsSelectors } from 'modules/channels';
import { LayoutContext } from 'pages/shared/Layout';
import EventInfo from './EventInfo';
import LocationMap from './LocationMap';

const ChannelsEventsShowComponent = ({ event, getEvent }) => {
  const [tab, setTab] = useState('info');
  const channel = useSelector((state) => channelsSelectors.getChannel(state));
  const { isMapsLoaded } = useContext(LayoutContext);
  return (
    <div>
      <EventInfo
        refreshEvent={getEvent}
        event={event}
        setTab={setTab}
        tab={tab}
        channel={channel}
      />

      {tab === 'info' && isMapsLoaded && event?.location?.lat && (
        <div>
          <LocationMap event={event} />
        </div>
      )}
    </div>
  );
};

export default ChannelsEventsShowComponent;
