import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import { ButtonPrimary } from 'core/ui/Button';
import useActions from 'modules/map/useActions';
import { newCompetitionsModule } from 'modules/competitions/new';
import useSelectors from 'modules/map/useSelectors';
import Modules from 'modules';
import Divider from 'core/ui/Divider';
import Typography from 'core/ui/Typography';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import LoaderSm from 'core/ui/LoaderSm';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import Checkbox from 'core/ui/Checkbox';
import Paper from 'core/ui/Paper';
import Grid from 'core/ui/Grid';
import Scrollbars from 'react-custom-scrollbars';
import notifications from 'modules/notifications';
import { useDispatch } from 'react-redux';
import SelectorUser from 'components/Profile/SelectorUser';
import { Collapse } from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowRight } from '@material-ui/icons';
import { competitionsInvitesModule } from 'modules/competitionsInvites';
import { CompetitionContext } from '../index';
import SelectLeaderboardResult from './SelectLeaderboardResult';

const CompetitionsSelect = ({ classes, refreshInvites }) => {
  const { t } = useTranslation();
  const formContext = useContext(CompetitionContext);
  const { request } = useActions();
  const dispatch = useDispatch();
  const loadingInvites = useSelectors(competitionsInvitesModule, 'loading');
  const loadingResults = useSelectors(newCompetitionsModule, 'loadingResults');
  const loadingDivisions = useSelectors(newCompetitionsModule, 'loadingDivisions');
  const loadingMyCompetitions = useSelectors(newCompetitionsModule, 'loadingMyCompetitions');
  const [filters, setFilters] = useState({ competitionId: null });
  const [, setPagination] = useState(null);
  const [competitions, setCompetitions] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const [results, setResults] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [checkedAll, setCheckedAll] = useState(false);
  const [collapse, setCollapse] = useState({ chooseFromComp: true });
  const [invite, setInvite] = useState({ templateId: null, divisionId: null });

  const getCompetitions = () => {
    request({
      action: Modules.newCompetitions.actions.getMyCompetitions,
      options: {
        onSuccess: (data) => {
          setCompetitions(data);
        },
      },
    });
  };

  const getCompetitionsDivisions = () => {
    request({
      action: Modules.newCompetitions.actions.getCompetitionDivisions,
      data: {
        competitionId: filters?.competitionId,
      },
      options: {
        onSuccess: (data) => {
          setDivisions(data);
        },
      },
    });
  };

  useEffect(() => {
    if (filters?.competitionId) {
      getCompetitionsDivisions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters?.competitionId]);

  useEffect(() => {
    getCompetitions();
  }, []);

  const getResults = () => {
    request({
      action: Modules.newCompetitions.actions.getCompetitionResults,
      data: {
        division_id: null,
        current_view: 'Teams',
        page: 1,
        perPage: 100000,
        ...filters,
      },
      options: {
        onSuccess: (data, pag) => {
          setResults(data);
          setPagination(pag);
        },
      },
    });
  };

  const isChecked = (userId) => selectedUsers?.find((x) => x?.id === userId);

  const onSelectUser = (checked, user, isCustom = false) => {
    if (checked) {
      setSelectedUsers((prev) => prev.filter((x) => x?.id !== user?.id));
    } else {
      setSelectedUsers((prev) => [
        ...prev,
        { id: user?.id, name: user?.name || user?.firstName, isCustom },
      ]);
      if (isCustom) {
        dispatch(notifications.success(t('user.added.sucess')));
      }
    }
  };

  const checkAll = () => {
    if (checkedAll) {
      setSelectedUsers([]);
    } else {
      setSelectedUsers(results?.map((x) => ({ id: x.team?.id, name: x.team?.firstName })));
    }
    setCheckedAll(!checkedAll);
  };

  const saveInvites = () => {
    request({
      action: Modules.competitionsInvites.actions.saveInvites,
      data: {
        competitionId: formContext?.competition?.id,
        selectedUsers: selectedUsers?.map((x) => x.id),
        ...invite,
      },
      options: {
        onSuccess: () => {
          setInvite({});
          refreshInvites();
          dispatch(notifications.success(t('competition.usersHasBeenInvited')));
        },
      },
    });
  };

  return (
    <Box>
      <Grid container spacing={5} mb={2}>
        <Grid item xs={12} md={6}>
          <Box className={classes.content} p={3}>
            <Box
              display="flex"
              alignItems="center"
              className="hover"
              onClick={() =>
                setCollapse((prev) => ({ ...prev, chooseFromComp: !prev?.chooseFromComp }))
              }
            >
              <Box flexGrow={1}>
                <Typography variant="h5">
                  {t('label.chooseACompetitionToInviteAthletes')}
                </Typography>
              </Box>

              {collapse?.chooseFromComp ? (
                <KeyboardArrowDown style={{}} />
              ) : (
                <KeyboardArrowRight style={{}} />
              )}
            </Box>

            <Collapse in={collapse?.chooseFromComp}>
              <Box>
                <Box mb={3} mt={1} display="flex" alignItems="center">
                  <Box flexGrow={1}>
                    <SelectBoxObject
                      label={<>{t('label.competitions')}</>}
                      options={competitions}
                      setValue={(v) => setFilters((prev) => ({ ...prev, competitionId: v }))}
                      value={filters?.competitionId}
                    />
                  </Box>
                  <LoaderSm loading={loadingMyCompetitions} />
                </Box>

                <Box mb={3} display="flex" alignItems="flex-end" className="gap-10">
                  <Box display="flex" alignItems="center" flexGrow={1}>
                    <Box flexGrow={1}>
                      <SelectBoxObject
                        label={t('competition.divisions')}
                        options={divisions}
                        setValue={(v) => setFilters((prev) => ({ ...prev, divisionId: v }))}
                        value={filters?.divisionId}
                      />
                    </Box>
                    <LoaderSm loading={loadingDivisions} />
                  </Box>
                  <Box flexGrow={1}>
                    <TextFieldRounded
                      label={t('competition.label.positions')}
                      value={filters?.maxPosition}
                      onChange={(v) => setFilters((prev) => ({ ...prev, maxPosition: v }))}
                    />
                  </Box>
                  <Box display="flex" justifyContent="flex-end">
                    <ButtonPrimary onClick={getResults}>{t('button.seeAthletes')}</ButtonPrimary>
                  </Box>
                </Box>

                <Paper p={3} mt={2}>
                  {results?.length > 0 ? (
                    <Box px={2}>
                      <Checkbox
                        checked={checkedAll}
                        onClick={() => checkAll()}
                        label={t('label.selectAll')}
                      />
                    </Box>
                  ) : (
                    <Box>
                      {!loadingResults && (
                        <Typography m={3} align="center">
                          {t('label.selectACompetitionAndInviteUsers')}
                        </Typography>
                      )}
                    </Box>
                  )}

                  <Scrollbars autoHeight autoHeightMax={500}>
                    {results?.map((item, index) => (
                      <Box key={`${item?.team?.id}-${item?.divisionId}`}>
                        <SelectLeaderboardResult
                          competition={formContext?.competition}
                          item={item}
                          checked={isChecked(item?.team?.id)}
                          onClick={onSelectUser}
                        />
                        {results?.length - 1 > index && <Divider />}
                      </Box>
                    ))}
                  </Scrollbars>
                  <LoaderSm loading={loadingResults} />
                  {results?.length > 0 && (
                    <Box display="flex" mt={2} alignItems="center">
                      <Typography variant="h5" mr={2}>
                        {t('label.total')}
                      </Typography>
                      <Typography variant="h5" color="textSecondary">
                        {results?.length || 0}
                      </Typography>
                    </Box>
                  )}
                </Paper>
              </Box>
            </Collapse>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box className={classes.content}>
            <Typography variant="h5" px={3} pt={3}>
              {t('competition.inviteCustomUsers')}
            </Typography>
            <SelectorUser
              onSelectedUser={(user) => onSelectUser(false, user, true)}
              autoFocus={false}
              hideTexture
            />
          </Box>
        </Grid>
      </Grid>

      <Box className={classes.content} p={3}>
        <Box display="flex" alignItems="center">
          <Typography variant="h5" mr={2}>
            {t('label.selectedUsers')}
          </Typography>
          <Typography variant="h5" color="textSecondary">
            {selectedUsers?.length || 0}
          </Typography>
        </Box>
        <Box display="flex" className="gap-5" flexWrap="wrap">
          {selectedUsers?.map((itemInvite) => (
            <Paper p={1}>{itemInvite?.name}</Paper>
          ))}
        </Box>

        <Divider my={2} />

        <Box mb={3}>
          <SelectBoxObject
            label={t('competition.divisions')}
            options={formContext?.watch('divisions')}
            setValue={(v) => setInvite((prev) => ({ ...prev, divisionId: v }))}
            value={invite?.divisionId}
            bordered
          />
        </Box>
        <Box mb={3}>
          <SelectBoxObject
            label={t('label.emailTemplates')}
            options={formContext
              ?.watch('messages')
              ?.filter((x) => x.objectableType === 'CompetitionsInvite')
              ?.map((x) => ({ id: x.id, name: x.subject }))}
            setValue={(v) => setInvite((prev) => ({ ...prev, templateId: v }))}
            value={invite?.templateId}
            bordered
          />
        </Box>

        <Box display="flex" justifyContent="flex-end">
          <LoaderSm loading={loadingInvites} />
          <ButtonPrimary
            disabled={loadingInvites || selectedUsers?.length === 0}
            onClick={saveInvites}
          >
            {t('button.confirmInvite')}
          </ButtonPrimary>
        </Box>
      </Box>
    </Box>
  );
};

export default CompetitionsSelect;
