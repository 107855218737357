/* eslint-disable react/prop-types */
import React from 'react';
import Paper from 'core/ui/Paper';
import Typography from 'core/ui/Typography';
import SFDataTable from 'core/ui/DataTable';
import { channelCurrency, dateExt, time } from 'core/utils/formatters';
import Box from 'core/ui/Box';
import { secondaryBgLight } from 'core/ui/Colors';
import { Link } from '@material-ui/core';
import { OpenInNew, Schedule } from '@material-ui/icons';

export default function ByMembershipBonus({ title, channel, byMembershipBonus, isCoachView }) {
  const columns = [
    {
      name: 'Coach',
      selector: 'name',
      sortable: true,
      cell: (row) => <Typography>{row?.coachName}</Typography>,
    },
    {
      name: 'Registrations',
      cell: (row) => <Typography>{row.workoutsUsers?.length}</Typography>,
    },
    {
      name: 'Total Amount',
      selector: 'total',
      sortable: true,
      cell: (row) => <Typography>{channelCurrency(row?.amount, channel?.currency)}</Typography>,
    },
  ];
  const columnsPayments = [
    {
      name: 'Workout',
      selector: 'id',
      sortable: true,
      cell: (row) => (
        <Box display="flex" alignItems="center">
          <Typography mr={1}>{row.workoutName}</Typography>
          <Link target="_blank" href={`/workout/view/${row.workoutId}`}>
            <OpenInNew style={{ fontSize: 15 }} />
          </Link>
        </Box>
      ),
    },
    {
      name: 'Date',
      selector: 'workoutScheduledAt',
      sortable: true,
      cell: (row) => (
        <Box display="flex" alignItems="center">
          <Schedule style={{ fontSize: 18 }} />
          <Typography color="primary" ml={1} component="span" variant="body1">
            <span>{dateExt(row.workoutScheduledAt, null, channel?.dateFormatExtReact)}</span>
            <span>&nbsp;&bull;&nbsp;</span>
            <span className="roboto-regular">{time(row.workoutScheduledAt)}</span>
          </Typography>
        </Box>
      ),
    },
    {
      name: 'Plan',
      selector: 'membership',
      sortable: true,
      cell: (row) => <Typography>{row?.membershipPlan}</Typography>,
    },
    {
      name: 'Revenue Amount',
      selector: 'coachRevenue',
      sortable: true,
      width: '150px',
      cell: (row) => <Typography>{channelCurrency(row?.amount, channel?.currency)}</Typography>,
    },
  ];

  const ExpanableComponent = ({ data: dataExpanded }) => (
    <Box p={5}>
      <SFDataTable noHeader columns={columnsPayments} data={dataExpanded?.workoutsUsers} />
    </Box>
  );

  return (
    <Paper
      style={{ width: '100%', border: isCoachView ? `solid 1px ${secondaryBgLight}` : 'none' }}
      py={2}
    >
      <SFDataTable
        title={
          <Box>
            <Typography variant="h5">{title}</Typography>
          </Box>
        }
        columns={columns}
        data={byMembershipBonus?.coaches}
        expandableRows
        expandableRowsComponent={<ExpanableComponent />}
        showPaper={false}
      />
      <Box display="flex" justifyContent="flex-end">
        <Paper p={3} mt={3}>
          <Typography variant="h4">
            Total:&nbsp;{channelCurrency(byMembershipBonus?.total, channel?.currency)}
          </Typography>
        </Paper>
      </Box>
    </Paper>
  );
}
