import React, { useContext, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Container } from '@material-ui/core';
import { LayoutContext } from 'pages/shared/Layout';
import { channelsActions, channelsSelectors } from 'modules/channels';
import { useParams } from 'react-router';
import SelfCheckIn from 'components/Channel/Show/Tabs/SelfCheckIn';
import Texture from 'core/ui/Texture';
import useDeepLinks, { AppScreens } from 'core/useDeepLinks';
import { sessionSelectors } from 'modules/session';
import Background from 'core/ui/Background';

export default () => {
  const { t } = useTranslation();
  const channel = useSelector((state) => channelsSelectors.getChannel(state));
  const dispatch = useDispatch();
  const layoutContext = useContext(LayoutContext);
  const isAuthenticated = useSelector((state) => sessionSelectors.getIsAuthenticated(state));

  const { id } = useParams();

  const deep = useDeepLinks();

  useEffect(() => {
    deep.openInApp({ screen: AppScreens.Channel.View, params: id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(channelsActions.getChannelStart(id));
    }
  }, [dispatch, id, isAuthenticated]);

  useEffect(() => {
    layoutContext.setTitleBar(t('title.gym').toUpperCase());
    layoutContext.setActiveMenu('');
  }, [layoutContext, t]);

  return (
    <Texture
      fullHeight
      heightBanner={layoutContext.heightBanner}
      heightHeader={layoutContext.heightHeader}
    >
      <Background
        imageUrl={channel?.bgImage?.image?.url || channel?.image?.image?.url}
        heightBanner={layoutContext.heightHeader}
        heightHeader={layoutContext.heightHeader}
      >
        <Container maxWidth="xl">
          <Box p={5} style={{ minHeight: 'calc(100vh - 75px)' }}>
            <SelfCheckIn />
          </Box>
        </Container>
      </Background>
    </Texture>
  );
};
