import React from 'react';
import { secondary } from '../Colors';

const PlateWeightIcon = ({ size, color }) => (
  <svg width={size} height={size} viewBox="0, 0, 400,400">
    <g id="svgg">
      <path
        id="path0"
        d="M129.139 9.381 C -75.882 87.117,-28.162 389.436,190.625 398.913 C 341.630 405.454,441.423 266.066,387.718 123.619 C 351.838 28.451,225.754 -27.252,129.139 9.381 M159.599 85.492 C 175.857 101.750,169.659 163.416,149.607 184.898 C 107.896 229.584,62.332 229.943,62.637 185.583 C 63.049 125.736,130.360 56.252,159.599 85.492 M292.278 91.462 C 341.120 128.716,353.682 212.500,310.425 212.500 C 272.044 212.500,237.500 168.831,237.500 120.310 C 237.500 72.483,255.184 63.169,292.278 91.462 M212.434 190.545 C 221.606 201.597,216.529 218.750,204.086 218.750 C 188.680 218.750,178.494 201.477,187.500 190.625 C 197.282 178.838,202.704 178.821,212.434 190.545 M240.026 264.165 C 283.673 289.136,285.765 311.372,246.298 330.839 C 195.426 355.932,106.726 324.407,128.756 289.063 C 138.351 273.668,181.370 250.206,200.000 250.206 C 208.594 250.206,226.605 256.487,240.026 264.165 "
        stroke="none"
        fill={color}
        fillRule="evenodd"
      />
    </g>
  </svg>
);

export default PlateWeightIcon;

PlateWeightIcon.defaultProps = {
  icon: null,
  color: secondary,
  size: 20,
  styles: {},
};
