import React, { useCallback, useEffect, useState } from 'react';

import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import Typography from 'core/ui/Typography';
import Button from 'core/ui/Button';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import { IconButton } from '@material-ui/core';
import { Add, Close } from '@material-ui/icons';

import Paper from 'core/ui/Paper';
import ModalFormUnavailableDate from './Form/ModalForm';

const UnavailableDates = ({ channelId }) => {
  const { t } = useTranslation();
  const [modalForm, setModalForm] = useState({ open: false });

  const [times, setTimes] = useState([]);
  const { request } = useActions();

  const getUnavailableDates = useCallback(() => {
    request({
      action: Modules.usersUnavailableTimes.actions.getTimes,
      data: { channelId },
      options: {
        onSuccess: (data) => {
          setTimes(data);
        },
      },
    });
  }, [request]);

  const removeUnavailableDate = useCallback(
    (item) => {
      request({
        action: Modules.usersUnavailableTimes.actions.delete,
        data: {
          id: item?.id,
          channelId,
        },
        options: {
          onSuccess: () => {
            getUnavailableDates();
          },
        },
      });
    },
    [request],
  );

  useEffect(() => {
    getUnavailableDates();
  }, [getUnavailableDates]);

  return (
    <Box>
      <Typography mb={3} variant="h5">
        {t('label.unavailableDates')}
      </Typography>
      <div className="tw-flex tw-flex-col tw-gap-2">
        {times?.map((time) => (
          <Paper p={2} key={time?.id}>
            <div className="tw-flex tw-flex-row tw-items-center tw-gap-2">
              <div className="tw-grow">
                <div className="tw-text-xs tw-font-bold">{time?.date}</div>
                <div className="tw-text-xs">
                  {time?.allDay ? t('label.allDay') : `${time?.startTime} - ${time?.endTime}`}
                </div>

                <div>{time?.description}</div>
              </div>

              <IconButton onClick={() => removeUnavailableDate(time)}>
                <Close className="tw-text-xs" />
              </IconButton>
            </div>
          </Paper>
        ))}

        <div className="tw-flex tw-items-center tw-justify-center">
          <Button onClick={() => setModalForm({ open: true })}>
            <Add className="mr-2 tw-text-base" />
            {t('button.create')}
          </Button>
        </div>
      </div>

      <ModalFormUnavailableDate
        open={modalForm?.open}
        close={() => setModalForm({ open: false })}
        callback={() => {
          getUnavailableDates();
          setModalForm({ open: false });
        }}
        channelId={channelId}
      />
    </Box>
  );
};

export default UnavailableDates;
