import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Chip } from '@material-ui/core';
import Box from 'core/ui/Box';
import { workoutsActions, workoutsSelectors } from 'modules/workouts';
import { ButtonPrimary } from 'core/ui/Button';
import { useHistory } from 'react-router';
import LoaderSm from 'core/ui/LoaderSm';
import { sessionSelectors } from 'modules/session';
import { LayoutContext } from 'pages/shared/Layout';
import { sessionWorkoutActions } from 'modules/sessionWorkout';
import { isMobile } from 'react-device-detect';
import Modules from 'modules';
import useActions from 'modules/map/useActions';
import notifications from 'modules/notifications';
import useConfirmation from 'core/useConfirmation';
import { AppContext } from 'contexts';
import { ShowContext } from '.';

const useStyles = makeStyles((theme) => ({
  boxButtons: {
    position: 'absolute',
    top: 1,
    right: -5,
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      right: 0,
      display: 'flex',
      justifyContent: 'center',
    },
  },
  colorTitle: {
    color: '#404041',
  },
  boxRating: {
    position: 'absolute',
    marginTop: '-50px',
    marginLeft: 40,
  },
  boxRegister: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  buttons: {
    color: '#fff',
    minWidth: 130,
    marginLeft: 1,
  },
  boxButtonsHeader: {
    position: 'absolute',
    marginTop: '-60px',
    right: 30,
    cursor: 'pointer',
  },
}));

export default (workout) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [openModalDownloadApp, setOpenModalDownloadApp] = useState(false);
  const loading = useSelector((state) => workoutsSelectors.getLoading(state));
  const history = useHistory();
  const dispatch = useDispatch();
  const { request } = useActions();
  const { confirmation } = useConfirmation();
  const buttonColor = workout?.channel?.customColors?.buttonColor;
  const currentUser = useSelector((state) => sessionSelectors.getUser(state));
  const isAuthenticated = useSelector((state) => sessionSelectors.getIsAuthenticated(state));
  const layoutContext = useContext(LayoutContext);
  const appContext = useContext(AppContext);

  const showContext = useContext(ShowContext);
  const [dialogConfirm, setDialogCancel] = useState({
    open: false,
    callback: () => {},
  });
  const [dialogConfirmReset, setDialogConfirmReset] = useState({
    open: false,
    callback: () => {},
  });

  const refund = () => {
    setDialogCancel({
      open: true,
    });
  };

  const cancelInvite = () => {
    request({
      action: Modules.workoutsInvites.actions.declineInvite,
      data: {
        workoutId: workout?.id,
      },
      options: {
        onSuccess: () => {
          dispatch(workoutsActions.getWorkoutStart(workout?.id));
        },
      },
    });
  };

  const uncancelClass = (w) => {
    confirmation({
      description: t('confirm.uncancelClass'),
      yesClick: () => {
        request({
          action: Modules.newWorkouts.actions.updateWorkout,
          data: {
            id: w?.id,
            cancelAt: null,
          },
          options: {
            onSuccess: () => {
              dispatch(workoutsActions.getWorkoutStart(workout?.id));
            },
          },
        });
      },
    });
  };

  const confirmCancel = (childId = null) => {
    dispatch(
      workoutsActions.refundStart({
        workoutId: workout?.id,
        childId,
        callback: () => {
          dispatch(workoutsActions.getWorkoutStart(workout?.id));
        },
      }),
    );
  };

  const inviteFriends = () => {
    showContext.setModalInvites((prev) => ({ ...prev, open: true }));
  };

  const openHeats = () => {
    showContext.setModalHeats(true);
  };

  const purchase = () => {
    dispatch(
      workoutsActions.purchaseStart({
        workoutId: workout?.id,
        messageSuccess: t('purchase.workout?.success'),
      }),
    );
  };

  const startClass = () => {
    if (isMobile) {
      setOpenModalDownloadApp(true);
      return;
    }
    history.push(`/workout/${workout?.id}/stream`);
  };

  const startWorkout = () => {
    if (isMobile) {
      setOpenModalDownloadApp(true);
      return;
    }
    dispatch(
      sessionWorkoutActions.startWorkoutCompetitionStart({
        workoutId: workout?.id,
        callback: () => {
          history.push(`/workout/${workout?.id}/stream`);
        },
      }),
    );
  };

  const registerClick = () => {
    if (workout?.free) {
      purchase();
      return;
    }

    appContext.setModalPurchase({
      open: true,
      workoutId: workout?.id,
      confirmCallback: workout?.useHeats ? openHeats : null,
    });
  };

  // eslint-disable-next-line react/prop-types
  const ButtonLogin = ({ style = {} }) => (
    <ButtonPrimary
      buttonColor={buttonColor}
      onClick={() => layoutContext.setModalLogin({ open: true })}
      pl={3}
      pr={3}
      spacing={2}
      style={{ ...style }}
    >
      {t('appbar.signInRegister').toUpperCase()}
    </ButtonPrimary>
  );

  const showProcessingRecording = () => {
    if (workout?.recorded && workout?.hasRecordings && workout?.recordingStatus !== 'done') {
      return true;
    }
    return false;
  };

  const resetConfirm = () => {
    dispatch(
      sessionWorkoutActions.resetWorkoutCompetitionStart({
        workoutId: workout?.id,
        callback: () => {
          startClass();
        },
      }),
    );
  };

  const checkInWorkout = () => {
    request({
      action: Modules.newWorkouts.actions.checkInUsers,
      data: {
        workoutId: workout.id,
        userId: currentUser?.id,
      },
      options: {
        onSuccess: () => {
          dispatch(workoutsActions.getWorkoutStart(workout?.id));
        },
        onError: (error) => {
          dispatch(notifications.error(error));
        },
      },
    });
  };

  const removeFromWaitList = (userId) => {
    confirmation({
      description: t('workout.waitlist.yourself.remove'),
      yesClick: () => {
        request({
          action: Modules.workoutWaitlists.actions.removeFromWaitList,
          data: { workoutId: workout.id, userId },
          options: {
            onSuccess: () => {
              dispatch(workoutsActions.getWorkoutStart(workout?.id));
            },
          },
        });
      },
    });
  };

  const showRegisterButton = () => {
    if (currentUser && currentUser.hasChildren) {
      return true;
    }
    return !!(
      !workout?.isCompetition &&
      !workout?.registered &&
      workout?.allowRegister &&
      workout?.channel &&
      workout?.channel.planValid &&
      !loading
    );
  };

  // eslint-disable-next-line react/prop-types
  const ButtonsJoinRefund = () => (
    <Box display="flex">
      {showContext.showStartJoinButton() && (
        <Box mx={1} display="flex" justifyContent="center">
          <ButtonPrimary className={classes.buttons} onClick={() => startClass()}>
            {t(
              // eslint-disable-next-line no-nested-ternary
              workout?.isCoach === true
                ? !workout?.recorded
                  ? 'button.startClass'
                  : 'button.watch'
                : 'workout.joinClass',
            ).toUpperCase()}
          </ButtonPrimary>
        </Box>
      )}
      {isAuthenticated &&
        workout?.recorded &&
        workout?.recordingStatus === 'done' &&
        workout?.started &&
        workout?.registered && (
          <ButtonPrimary
            style={{ marginLeft: 5, marginRight: 5 }}
            className={classes.buttons}
            onClick={() => inviteFriends()}
          >
            {t('button.inviteFriends').toUpperCase()}
          </ButtonPrimary>
        )}
      {workout?.isCoach &&
        workout?.recorded &&
        !workout?.started &&
        workout?.method === 'pre_recorded' && (
          <Box ml={1} display="flex" justifyContent="center">
            <ButtonPrimary
              className={classes.buttons}
              onClick={() => setDialogConfirmReset({ open: true })}
            >
              {t('button.reRecord').toUpperCase()}
            </ButtonPrimary>
          </Box>
        )}
      {!workout?.coachScheduleSessionId &&
        workout?.isCoach === false &&
        !workout?.started &&
        (workout?.allowRefund === true || workout?.chargeLateCancellation) && (
          <Box>
            <ButtonPrimary
              buttonColor={buttonColor}
              onClick={() => refund()}
              className={classes.buttons}
            >
              {t('button.cancel').toUpperCase()}
            </ButtonPrimary>
          </Box>
        )}
    </Box>
  );

  // eslint-disable-next-line react/prop-types
  const ButtonJoinWorkout = () => (
    <Box display="flex">
      <Box mx={2} display="flex" justifyContent="center">
        <ButtonPrimary className={classes.buttons} onClick={() => startWorkout()}>
          {t('workout.startWorkout').toUpperCase()}
        </ButtonPrimary>
      </Box>
    </Box>
  );

  const ButtonCheckin = () => (
    <Box display="flex">
      <Box mx={2} display="flex" justifyContent="center">
        <ButtonPrimary className={classes.buttons} onClick={() => checkInWorkout()}>
          {t('button.checkIn').toUpperCase()}
        </ButtonPrimary>
      </Box>
    </Box>
  );

  const ProcessingRecordings = () => (
    <Box display="flex" justifyContent="center">
      {showProcessingRecording() && (
        <Box ml={2} display="flex" justifyContent="center">
          <Chip
            icon={<LoaderSm loading />}
            color="primary"
            label={t('workout.recording.processing')}
          />
        </Box>
      )}
    </Box>
  );

  return {
    openModalDownloadApp,
    setOpenModalDownloadApp,
    dialogConfirm,
    setDialogCancel,
    confirmCancel,
    dialogConfirmReset,
    setDialogConfirmReset,
    resetConfirm,
    showRegisterButton,
    registerClick,
    cancelInvite,
    loading,
    uncancelClass,

    ButtonLogin,
    ButtonsJoinRefund,
    ProcessingRecordings,
    ButtonCheckin,
    ButtonJoinWorkout,
    removeFromWaitList,
  };
};
