import React from 'react';
import Typography, { fontStyle } from 'core/ui/Typography';
import PropTypes from 'prop-types';
import Box from 'core/ui/Box';
import ImageMediaUser from 'components/Profile/ImageMedia';

export default function CardUser({ user }) {
  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <ImageMediaUser
        user={user}
        image={user.image ? user.image : {}}
        size={60}
        heightCountry={15}
        sizeCountry="1em"
      />

      <Typography style={{ ...fontStyle.small }} variant="caption" component="p" align="center">
        {user.firstName}
      </Typography>
    </Box>
  );
}

CardUser.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.object,
};

CardUser.defaultProps = {
  user: {},
};
