/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import { ButtonPrimary, ButtonPrimarySm } from 'core/ui/Button';
import Typography, { fontStyle } from 'core/ui/Typography';
import LoaderSm from 'core/ui/LoaderSm';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import useSelectors from 'modules/map/useSelectors';
import { usersImportsModule } from 'modules/usersImports';
import { usersImportToolTypes } from 'core/utils/consts';
import Paper from 'core/ui/Paper';
import { useDispatch } from 'react-redux';
import notifications from 'modules/notifications';
import Check from '@material-ui/icons/Check';
import { Link } from '@material-ui/core';
import SelectFile from '../SelectFile';
import PreviewList from './PreviewList';
import ToolsTypes from '../ToolsTypes';

export default ({ close, type }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [toolType, setToolType] = useState(usersImportToolTypes.wodify);
  const { request } = useActions();
  const loading = useSelectors(usersImportsModule, 'loading');
  const previewData = useSelectors(usersImportsModule, 'previewData');
  const [data, setData] = useState(null);

  const previewImport = () => {
    const formData = new FormData();
    formData.append('file', data);
    formData.append('tool', toolType);
    request({
      action: Modules.usersImports.actions.importBenchmarksWorkouts,
      data: formData,
      options: {
        onError: (error) => {
          dispatch(notifications.error(error));
        },
      },
    });
  };

  const confirmImport = () => {
    const benchmarks = previewData?.benchmarks
      .filter((x) => !!x?.benchmarkSection?.id)
      .map((x) => ({ ...x, benchmarkSectionId: x?.benchmarkSection?.id }));

    request({
      action: Modules.usersImports.actions.confirmBenchmarksWorkouts,
      data: {
        workouts: previewData?.workouts.map((x) => ({ ...x, sectionsAttributes: x.sections })),
        benchmarks,
      },
      options: {
        onSuccess: () => {
          dispatch(notifications.success(t('message.success.import')));
          request({
            action: Modules.usersImports.actions.setPreviewData,
            data: [],
          });
          close();
        },
      },
    });
  };

  return (
    <Box>
      <Paper p={3} m={3}>
        <ToolsTypes toolType={toolType} setToolType={setToolType} />

        <Box display="flex" alignItems="center" flexDirection="column" p={5}>
          <Box>
            <SelectFile setFile={setData} file={data} />
          </Box>
          <Typography mt={1} style={fontStyle.small} align="center">
            <Link
              href={toolType === 'wodify' ? '/example_wodify_prs.csv' : '/example_sugarwod.csv'}
            >
              Download Template
            </Link>
          </Typography>

          <Typography className="roboto-regular" mt={1}>
            {t(`select.file.import.${toolType}.help`)}{' '}
            <b>{t(`select.file.import.${toolType}.benchmark.file`)}</b>
          </Typography>
          <Box display="flex" alignItems="flex-end" mb={3} mt={5}>
            <Box ml={3}>
              <ButtonPrimarySm disabled={loading} p={1} onClick={() => previewImport()}>
                <LoaderSm loading={loading} width={25} />
                {t('button.previewResults')}
              </ButtonPrimarySm>
            </Box>
          </Box>

          {type !== 'Workout' && (
            <Typography className="roboto-regular">
              We need to match our benchmark names exactly. If your benchmark is just CrossFit Games
              &nbsp;<b>OPEN 17.1</b> for example, please choose&nbsp;<b>OPEN 17.1</b> to save the
              benchmark properly.
            </Typography>
          )}
        </Box>
      </Paper>

      {previewData &&
        (previewData?.workouts?.length > 0 || previewData?.benchmarks?.length > 0) && (
          <Box mx={3} display="flex">
            <Box flexGrow={1}>
              <Typography variant="h3">{t('import.previewResults')}</Typography>
            </Box>
            <ButtonPrimary disabled={loading} p={1} onClick={() => confirmImport()}>
              <LoaderSm loading={loading} width={25} />
              <Check />
              {t('button.confirm')}
            </ButtonPrimary>
          </Box>
        )}

      <Box>
        <PreviewList previewData={previewData} loading={loading} />
      </Box>
    </Box>
  );
};
