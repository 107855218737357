import React, { useMemo, useState } from 'react';
import Box from 'core/ui/Box';
import { StagesContext } from 'contexts';
import { DragDropContext } from 'react-beautiful-dnd';
import Typography from 'core/ui/Typography';
import { primary } from 'core/ui/Colors';
import Scrollbars from 'react-custom-scrollbars';
import { channelsStagesModule } from 'modules/channelsStages';
import useSelectors from 'modules/map/useSelectors';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import notifications from 'modules/notifications';
import { useDispatch } from 'react-redux';
import { IconButton } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { kanbanUpdateColumnEmitter } from 'emitters';
import ModalConversations from 'components/ContactForms/ModalConversations';
import KanbanColumns from './KanbanColumns';
import ModalStageUsersForm from './StageUsersForm/Modal';
import StagesKanbanFilters from './StagesKanbanFilters';

const StagesKanban = ({ channel }) => {
  const [columns, setColumns] = useState({});
  const dispatch = useDispatch();
  const { request } = useActions();

  const channelStages = useSelectors(channelsStagesModule, 'data');
  const [modalForm, setModalForm] = useState({});
  const [modalConversations, setModalConversations] = useState({});

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const { source, destination } = result;

    const stageUserId = result.draggableId.split('-')[1];
    const stageOrigId = source?.droppableId?.split('-')[1];
    const stageDestId = destination?.droppableId?.split('-')[1];

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[stageOrigId];
      const destColumn = columns[stageDestId];
      const sourceItems = [...sourceColumn];
      const destItems = [...destColumn];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [stageOrigId]: sourceItems,
        [stageDestId]: destItems,
      });
    } else {
      const column = columns[stageOrigId];
      const copiedItems = [...column];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [stageOrigId]: copiedItems,
      });
    }

    request({
      action: Modules.channelsStages.actions.saveStagesUserOrder,
      data: {
        channelId: channel?.id,
        stageOrigId,
        stageDestId,
        stageUserId,
        stageInsertIndex: destination.index + 1,
      },
      options: {
        onError: () => {
          dispatch(notifications.error('erro to order'));
        },
      },
    });
  };

  const updateKanbanColumns = (oldStageId, newStageId) => {
    if (oldStageId && oldStageId !== newStageId) {
      kanbanUpdateColumnEmitter.emit(`CHANNEL_STAGE_UPDATE_${oldStageId}`);
    }
    kanbanUpdateColumnEmitter.emit(`CHANNEL_STAGE_UPDATE_${newStageId}`);
  };

  return (
    <>
      <StagesKanbanFilters channelStages={channelStages} channel={channel} />

      <Scrollbars allowFullScreen className="tw-rounded-t-md " renderThumbVertical={() => <div />}>
        <Box display="flex" className="tw-gap-3">
          {channelStages.map((stage) => (
            <Box
              className="tw-min-w-60 tw-rounded-t-lg tw-bg-white tw-p-2"
              style={{ borderTop: `2px solid ${stage.color || primary}` }}
              key={stage.id.toString()}
            >
              <div className="tw-flex tw-flex-row tw-gap-2">
                <Box className="tw-flex tw-grow tw-flex-col tw-gap-2">
                  <Typography color="secondary">{stage.name}</Typography>
                  <div className="tw-text-xs tw-font-normal tw-text-gray-500">
                    {stage.usersCount || 0} Users
                  </div>
                </Box>
                <div>
                  <IconButton
                    size="small"
                    onClick={() => setModalForm({ open: true, stageId: stage?.id })}
                  >
                    <Add />
                  </IconButton>
                </div>
              </div>
            </Box>
          ))}
        </Box>

        {modalForm?.open && (
          <ModalStageUsersForm
            open={modalForm?.open}
            close={() => setModalForm({ open: false })}
            stageUser={modalForm?.stageUser}
            stageId={modalForm?.stageId}
            callbackSave={(oldStage, newStage) => updateKanbanColumns(oldStage, newStage)}
            channel={channel}
          />
        )}

        <ModalConversations
          open={modalConversations?.open}
          close={() => setModalConversations({ open: false })}
          channel={channel}
          userId={modalConversations?.userId}
        />

        <StagesContext.Provider
          value={useMemo(
            () => ({
              columns,
              setColumns,
              modalForm,
              setModalForm,
              setModalConversations,
            }),
            [columns, modalForm],
          )}
        >
          <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
            <KanbanColumns channelStages={channelStages} />
          </DragDropContext>
        </StagesContext.Provider>
      </Scrollbars>
    </>
  );
};

StagesKanban.propTypes = {};

StagesKanban.defaultProps = {
  close: () => {},
};

export default StagesKanban;
