import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import { Dialog, Slide } from '@material-ui/core';
import Typography from 'core/ui/Typography';
import Texture from 'core/ui/Texture';
import Form from './Form';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const ModalFormCategory = ({ open, item, close, callback, mode, ownerableType, ownerableId }) => {
  const { t } = useTranslation();
  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={close} TransitionComponent={Transition}>
      <Texture style={{ minHeight: '100%' }}>
        <Typography variant="h4" align="center" m={3}>
          {t('channel.store.category')}
        </Typography>
        <Box p={3}>
          <Form
            mode={mode}
            callback={callback}
            item={item}
            ownerableId={ownerableId}
            ownerableType={ownerableType}
          />
        </Box>
      </Texture>
    </Dialog>
  );
};

ModalFormCategory.defaultProps = {
  close: () => {},
  callback: () => {},
  item: null,
  open: false,
  mode: 'new',
};

ModalFormCategory.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  close: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  callback: PropTypes.func,
  open: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.object,
  mode: PropTypes.string,
};

export default ModalFormCategory;
