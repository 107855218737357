import React, { useContext, useEffect, useState } from 'react';
import Box from 'core/ui/Box';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import { useSelector } from 'react-redux';
import { sessionSelectors } from 'modules/session';
import LoaderSm from 'core/ui/LoaderSm';
import useSelectors from 'modules/map/useSelectors';
import { usersWorkoutsModule } from 'modules/usersWorkouts';
import usePullRefresh from 'core/usePullRefresh';
import { DashboardContext } from 'contexts';
import { Link } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ScheduleSwipe from './ScheduleSwipe';
import ScheduleList from './ScheduleList';

const CalendarWorkouts = () => {
  const { request } = useActions();
  const { refresh } = usePullRefresh();
  const loadingWorkouts = useSelectors(usersWorkoutsModule, 'loadingWorkouts');
  const user = useSelector((state) => sessionSelectors.getUser(state));
  const { t } = useTranslation();
  const [calendarData, setCalendarData] = useState([]);
  const [pagination, setPagination] = useState([]);

  const dashboardContext = useContext(DashboardContext);

  const mergeGroups = (prevW, newW) => {
    const newArr = [...prevW];

    newW.forEach((item) => {
      const idxItem = newArr?.findIndex((x) => x.title === item?.title);
      if (idxItem > -1) {
        newArr[idxItem].data = [...newArr[idxItem]?.data, ...item?.data];
      } else {
        newArr.push(item);
      }
    });

    return newArr;
  };

  const getWorkoutsGroup = ({ page = 1, newDate }) => {
    request({
      action: Modules.usersWorkouts.actions.getCalendarGroupWorkouts,
      data: {
        startAt: newDate,
        endAt: newDate,

        onlyUserClass: true,
        page,
        perPage: 25,
      },
      options: {
        onSuccess: (resp) => {
          setPagination(resp?.pagination);
          setCalendarData((prev) =>
            resp?.pagination?.currentPage === 1
              ? resp?.workouts
              : mergeGroups(prev || [], resp?.workouts),
          );
        },
      },
    });
  };

  useEffect(() => {
    if (user && user.id) {
      getWorkoutsGroup({ newDate: dashboardContext?.currentDate });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, dashboardContext?.currentDate, request]);

  useEffect(() => {
    if (refresh) {
      getWorkoutsGroup({ newDate: dashboardContext?.currentDate });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  return (
    <Box>
      <ScheduleSwipe
        currentDate={dashboardContext?.currentDate}
        setCurrentDate={dashboardContext?.setCurrentDate}
        onNext={(nextDate) => {
          dashboardContext.setCurrentDate(nextDate);
          getWorkoutsGroup({ next: true, newDate: nextDate, recursive: true });
        }}
        onPrev={(nextDate) => {
          dashboardContext.setCurrentDate(nextDate);
          getWorkoutsGroup({ next: false, newDate: nextDate, recursive: true });
        }}
      />

      <Box display="flex" flexGrow="1" mb={3} justifyContent="center">
        <LoaderSm loading={loadingWorkouts} />
      </Box>

      <Box mb={3}>
        <ScheduleList calendarData={calendarData} loading={loadingWorkouts} />
      </Box>

      {pagination?.currentPage < pagination?.totalPages && (
        <Box display="flex" justifyContent="center">
          <Link
            onClick={() =>
              getWorkoutsGroup({
                page: (pagination?.currentPage || 0) + 1,
                newDate: dashboardContext?.currentDate,
              })
            }
            className="hover"
          >
            {t('button.loadMore')}
          </Link>
        </Box>
      )}
    </Box>
  );
};

export default CalendarWorkouts;
