import React, { createContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { sessionSelectors } from 'modules/session';
import Box from 'core/ui/Box';
import ImageMedia from 'components/Channel/ImageMedia';
import { ButtonPrimarySm } from 'core/ui/Button';
import { useTranslation } from 'react-i18next';
import Add from '@material-ui/icons/Add';
import LoaderSm from 'core/ui/LoaderSm';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import { newChannelsModule } from 'modules/channels/new';
import useSelectors from 'modules/map/useSelectors';
import SFDataTable from 'core/ui/DataTable';
import Typography from 'core/ui/Typography';

export const Context = createContext();

const MyChannels = () => {
  const { t } = useTranslation();
  const isCoach = useSelector((state) => sessionSelectors.getIsCoach(state));
  const getIsAdminOrManager = useSelector((state) => sessionSelectors.getIsAdmin(state));
  const [name, setName] = useState();
  const loading = useSelectors(newChannelsModule, 'loading');
  const myChannels = useSelectors(newChannelsModule, 'myChannels');
  const { request } = useActions();

  const history = useHistory();
  useEffect(() => {
    request({
      action: Modules.newChannels.actions.getMyChannels,
    });
  }, [request]);

  const goToChannel = (channel) => {
    // channel.allowEdit
    // ? history.push(`/channel/manage/${channel.slug}/${defaultTab || ''}`)
    // : history.push(`/channel/view/${channel.slug}`)

    if (channel?.allowEdit) {
      history.push(`/channel/manage/${channel.id}`);
    } else if (getIsAdminOrManager) {
      request({
        action: Modules.adminChannels.actions.addAsAdminChannel,
        data: {
          channelId: channel?.id,
        },
        options: {
          onSuccess: () => {
            history.push(`/channel/manage/${channel.id}`);
          },
        },
      });
    } else {
      history.push(`/channel/view/${channel.slug}`);
    }
  };

  const columns = [
    {
      name: '',
      selector: 'image',
      sortable: true,
      width: 100,
      cell: (channel) => (
        <Box style={{ width: 60 }}>
          <ImageMedia image={channel.image ? channel.image : {}} />
        </Box>
      ),
    },
    {
      name: 'Gym',
      selector: 'name',
      sortable: true,
      cell: (channel) => <Typography>{channel.name && channel.name.toUpperCase()}</Typography>,
    },
    {
      name: '',
      cell: (channel) => (
        <Box>
          <ButtonPrimarySm onClick={() => goToChannel(channel)}>
            {t('button.manage')}
          </ButtonPrimarySm>
        </Box>
      ),
    },
  ];

  return (
    <>
      {isCoach && (
        <Box m={1} display="flex" justifyContent="flex-end">
          {loading && (
            <Box display="flex" justifyContent="center" alignItems="center" mx={1}>
              <LoaderSm loading={loading} />
            </Box>
          )}
          <TextFieldRounded
            value={name}
            onChange={(v) => setName(v)}
            placeholder={t('search.channel.placeholder.mobile')}
          />
          <Box ml={3} style={{ minWidth: 150 }}>
            <ButtonPrimarySm
              onClick={() => history.push('/channel/new')}
              align="center"
              fullWidth
              component="h4"
            >
              <Add fontSize="medium" color="white" />
              {t('button.createChannel')}
            </ButtonPrimarySm>
          </Box>
        </Box>
      )}

      <Box>
        <SFDataTable
          columns={columns}
          data={myChannels.filter(
            (x) => !name || x?.name?.toLowerCase().indexOf(name.toLowerCase()) > -1,
          )}
          pagination
          showExport
          noHeader
        />
      </Box>
    </>
  );
};

export default MyChannels;

MyChannels.propTypes = {};

MyChannels.defaultProps = {};
