import React, { useState, useEffect, useRef } from 'react';
import noImage from 'assets/img/no-image.jpg';
import Box from 'core/ui/Box';
import useWindowSize from 'core/utils/hook/useWindowSize';
import Draggable from 'react-draggable';
import PropTypes from 'prop-types';
import { CardMedia } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

const ImageMedia = ({ image, style, loading }) => {
  const size = useWindowSize();
  const [sizeMedia, setSizeMedia] = useState({ width: 0 });
  const [position, setPosition] = useState(image ? image.position : {});

  useEffect(() => {
    if (image) {
      setPosition(image.position);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image && image.position]);

  const refMedia = useRef(null);

  useEffect(() => {
    if (refMedia.current) {
      setSizeMedia({
        height: refMedia.current.clientHeight,
        width: refMedia.current.clientWidth,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [size, setSizeMedia, refMedia.current && refMedia.current.clientHeight]);

  useEffect(() => {
    if (image && image.position && sizeMedia.width > 0) {
      setPosition((prev) => ({
        width: sizeMedia.width,
        x: 0,
        y: ((prev.y !== 0 ? prev.y : 1) / prev.width) * sizeMedia.width,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sizeMedia.width, image && image.position]);

  const styleMedia = {
    paddingTop: '37.25%',
    backgroundColor: '#bbbdbf',
  };

  return (
    <Box>
      {image && image.image && !loading && (
        <Box style={{ ...style, position: 'relative' }}>
          <Box className="boxGridMedia" ref={refMedia}>
            <Draggable
              axis="y"
              disabled
              position={{
                x: 0,
                y: position ? position.y : 0,
              }}
            >
              <Box>
                <img
                  className="boxImg"
                  draggable="false"
                  style={{
                    transform: `scale(${image.position.zoom || 1})`,
                  }}
                  src={image.image ? image?.image?.size350?.url || image.image.url : noImage}
                  alt=""
                />
              </Box>
            </Draggable>
          </Box>
        </Box>
      )}

      {(!image || !image.image) && !loading && (
        <CardMedia style={{ ...styleMedia }} image={noImage} title="" />
      )}
      {loading && <Skeleton variant="rect" style={{ ...styleMedia }} />}
    </Box>
  );
};

ImageMedia.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  image: PropTypes.object,
  loading: PropTypes.bool,
};

ImageMedia.defaultProps = {
  style: { paddingTop: '37.25%' },
  image: {},
  loading: false,
};

export default ImageMedia;
