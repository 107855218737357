import React, { useState } from 'react';
import Box from 'core/ui/Box';
import Typography from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import Paper from 'core/ui/Paper';
import Toggle from 'core/ui/Toggle';
import Grid from 'core/ui/Grid';
import { ButtonPrimary } from 'core/ui/Button';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import notifications from 'modules/notifications';
import { useDispatch } from 'react-redux';
import useSelectors from 'modules/map/useSelectors';
import LoaderSm from 'core/ui/LoaderSm';
import { adminChannelsModule } from 'modules/admin/channels';
import useConfirmation from 'core/useConfirmation';
import AdminChannelSelector from '../ChannelSelector';

export default function AdminImport() {
  const { t } = useTranslation();
  const [selectedChannel, setSelectedChannel] = useState(null);
  const { request } = useActions();
  const loadingPull = useSelectors(adminChannelsModule, 'loadingPull');
  const loadingCloneAll = useSelectors(adminChannelsModule, 'loadingCloneAll');
  const dispatch = useDispatch();
  const { confirmation } = useConfirmation();

  const [responseClonse, setResponseClone] = useState();
  const [data, setData] = useState({ base: 'members', override: false });

  const confirmPull = () => {
    if (!selectedChannel?.id) {
      dispatch(notifications.warning('Select a channel.'));
      return;
    }

    confirmation({
      description: 'Are you sure?',
      yesClick: () => {
        request({
          action: Modules.adminChannels.actions.pullStripeData,
          data: {
            channelId: selectedChannel?.id,
            override: data?.override,
          },
          options: {
            onError: (error) => {
              dispatch(notifications.error(error));
            },
          },
        });
      },
    });
  };

  const cloneAll = () => {
    request({
      action: Modules.adminChannels.actions.cloneAllCustumersAndPaymentMethods,
      data: {
        channelId: selectedChannel?.id,
        override: data?.override,
      },
      options: {
        onSuccess: (resp) => {
          setResponseClone(resp);
        },
        onError: (error) => {
          dispatch(notifications.error(error));
        },
      },
    });
  };

  return (
    <Box p={5}>
      <Grid container>
        <Grid item xs={12} md={12}>
          <Paper p={3}>
            <Typography variant="h5" align="center" mb={3}>
              Stripe Import
            </Typography>
            <AdminChannelSelector
              selectedChannel={selectedChannel}
              setSelectedChannel={setSelectedChannel}
            />

            <Box display="flex" mt={3} style={{ width: '100%' }}>
              <Box flexGrow={1} display="flex" justifyContent="flex-start">
                <Box>
                  <Toggle
                    value={data?.override}
                    onChange={(v) => setData((prev) => ({ ...prev, override: v }))}
                    options={[
                      { label: 'Full Overwrite', value: true, width: 100 },
                      { label: 'Add New Only', value: false, width: 100 },
                    ]}
                  />
                </Box>
                <Box>
                  <ButtonPrimary disabled={loadingPull} onClick={() => confirmPull()}>
                    <LoaderSm loading={loadingPull} width={20} />
                    {t('button.confirm')}
                  </ButtonPrimary>
                </Box>
              </Box>
              <Box>
                {responseClonse && (
                  <Box>
                    <Typography>PM Errors: {responseClonse?.errors}</Typography>
                    <Typography>PM Success: {responseClonse?.success}</Typography>
                  </Box>
                )}
                <Box display="flex" justifyContent="flex-end">
                  <ButtonPrimary disabled={loadingCloneAll} onClick={() => cloneAll()}>
                    <LoaderSm loading={loadingCloneAll} width={20} />
                    {t('button.cloneAllCustumersAndPaymentMethods')}
                  </ButtonPrimary>
                </Box>
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}
