import api from 'core/api';

export const partnersModule = 'partners';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const actions = {
  create: {
    module: partnersModule,
    name: 'create',
    api: (data) => api.post(`/partners`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  update: {
    module: partnersModule,
    name: 'update',
    api: (data) => api.put(`/partners/${data?.id}`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  getPartners: {
    module: partnersModule,
    name: 'getPartners',
    api: (params) => api.get(`/partners`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: ['data'],
    },
    state: loadingStates,
  },
  getPartner: {
    module: partnersModule,
    name: 'getPartner',
    api: (params) => api.get(`/partners/${params?.id}`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: ['partner'],
    },
    state: loadingStates,
  },
  getPayments: {
    module: partnersModule,
    name: 'getPayments',
    api: (params) => api.get(`/partners/${params?.partnerId}/report`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: ['payments'],
    },
    state: loadingStates,
  },
  assignProductChannel: {
    module: partnersModule,
    name: 'assignProductChannel',
    api: (data) => api.post(`/partners/${data?.partnerId}/assign_product_channel`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  removeAssignProductChannel: {
    module: partnersModule,
    name: 'removeAssignProductChannel',
    api: (params) =>
      api.delete(`/partners/${params?.partnerId}//remove_product_channel`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  connectStripeAccount: {
    module: partnersModule,
    name: 'connectStripeAccount',
    api: ({ partnerId, code }) => api.get(`/partners/${partnerId}/connect_stripe_account/${code}`),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  removeStripeAccount: {
    module: partnersModule,
    name: 'removeStripeAccount',
    api: ({ partnerId }) => api.delete(`/partners/${partnerId}/remove_stripe_account`),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  updateInventoryByFile: {
    module: partnersModule,
    name: 'updateInventoryByFile',
    api: ({ partnerId, formData }) =>
      api.post(`/partners/${partnerId}/update_inventory_by_file`, formData),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
};

export default {
  actions,
  state: {
    data: [],
    payments: [],
    error: null,
    loading: false,
    partner: null,
  },
};
