import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import Typography, { fontStyle } from 'core/ui/Typography';
import noImage from 'assets/img/no-image.jpg';
import './device.css';
import ColorPicker from 'core/ui/ColorPicker';
import Grid from 'core/ui/Grid';
import FontIcon from 'core/ui/FontIcon';
import { primary, secondaryBgLight } from 'core/ui/Colors';
import SelImage from './SelImage';

const ChannelCustomization = ({ channel, bgImage, setBgImage, watch, setValue }) => {
  const { t } = useTranslation();

  const colors = watch('customColors') || {};

  const changeData = (key, value) => {
    setValue('customColors', { ...colors, [key]: value });
  };

  return (
    <Box pt={3}>
      <Box flexGrow="1">
        <Typography variant="h5" mb={3} color="primary">
          {t('competition.label.colors')}
        </Typography>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Box p={1} display="flex">
            <ColorPicker
              color={colors.buttonColor}
              setColor={(v) => changeData('buttonColor', v)}
              label={t('competition.label.buttonColor')}
            />
            <Box>
              <ColorPicker
                color={colors.fontColor}
                setColor={(v) => changeData('fontColor', v)}
                label={t('competition.label.fontAccentColor')}
              />
            </Box>
          </Box>
        </Grid>
        {channel?.activePlans?.memberApp?.active && (
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <div className="phone">
              <Box px={3} className="top" alignItems="flex">
                <Box mb={1} px={5}>
                  <Typography style={fontStyle.small}>22:45</Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <img
                    className="boxImg"
                    draggable="false"
                    src={watch('appLogoPhoto')?.url || noImage}
                    alt=""
                    style={{ width: 36, height: 34, borderRadius: 5 }}
                  />

                  <Box flexGrow={1}>
                    <Typography ml={3} style={{ color: 'white' }}>
                      {watch('name')}
                    </Typography>
                  </Box>
                  <Box style={{ position: 'relative', right: 0, top: 4 }}>
                    <Box
                      style={{
                        borderRadius: '50%',
                        width: 20,
                        height: 20,
                        backgroundColor: secondaryBgLight,
                      }}
                    >
                      &nbsp;
                    </Box>
                    <FontIcon iconName="icon-action-nav" size={20} color={primary} />
                  </Box>
                </Box>
              </Box>
              <Box
                className="bg-screen"
                style={{
                  backgroundImage:
                    'linear-gradient(to bottom, rgba(233,233,233,0) 5%, rgba(233,233,233,0.4) 25%, rgba(233,233,233,0.7) 60%, rgba(233,233,233,0.9) 80%, rgba(233,233,233,1)),' +
                    `url(${bgImage?.url})`,
                }}
              >
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                  flex={1}
                  style={{ height: '100%' }}
                >
                  <Box b={3}>
                    <SelImage
                      label={t('channel.app.logo')}
                      setImage={(img) => setValue('appLogoPhoto', img)}
                    />
                  </Box>
                  <Box>
                    <SelImage
                      label={t('competition.label.backgroundImage')}
                      setImage={(img) => setBgImage(img)}
                    />
                  </Box>
                </Box>
              </Box>
            </div>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

ChannelCustomization.defaultProps = {};

ChannelCustomization.propTypes = {};

export default ChannelCustomization;
