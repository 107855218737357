import React from 'react';
import PropTypes from 'prop-types';

import CardClass from 'components/Workout/Grid/Card';
import { primary } from 'core/ui/Colors';
import CardChannel from './Cards/CardChannel';

const Item = ({ object, clickable, buttonColor }) => (
  <>
    {object.model === 'Workout' && (
      <CardClass
        key={object.item.id}
        workout={object.item}
        clickable={clickable}
        image={object.item?.channel?.image ? object.item.channel.image : {}}
        buttonColor={buttonColor || primary}
      />
    )}
    {object.model === 'Channel' && (
      <CardChannel
        key={object.item.id}
        channel={object.item}
        clickable={clickable}
        image={object.item.image ? object.item.image : {}}
      />
    )}
  </>
);

Item.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  object: PropTypes.object,
  clickable: PropTypes.bool,
};

Item.defaultProps = {
  object: {},
  clickable: true,
};

export default Item;
