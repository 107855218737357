import { all, call, put } from 'redux-saga/effects';

import { getErrorMessage } from 'core/api/api-error';

import notifications from 'modules/notifications';

import * as api from './api';
import { contractActions as actions } from './index';

export function* getContract({ name }) {
  try {
    const response = yield call(api.getContract, name);
    const contract = response.data;
    yield put(actions.getContractSuccess(contract));
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.getContractError(getErrorMessage(error))),
    ]);
  }
}

export function* getContractUserSign({ name }) {
  try {
    const response = yield call(api.getUserSign, name);
    const contract = response.data;
    yield put(actions.getContractUserSignSuccess(contract));
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.getContractUserSignError(getErrorMessage(error))),
    ]);
  }
}

export function* acceptContract({ data }) {
  try {
    const response = yield call(api.acceptContract, data);
    const contract = response.data;
    yield put(actions.acceptContractSuccess(contract));
    if (data.messageSuccess) {
      yield put(notifications.success(data.messageSuccess));
    }
    if (data.callback) {
      data.callback();
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.acceptContractError(getErrorMessage(error))),
    ]);
  }
}
export function* rejectContract({ data }) {
  try {
    const response = yield call(api.rejectContract, data);
    const contract = response.data;
    yield put(actions.rejectContractSuccess(contract));
    if (data.messageSuccess) {
      yield put(notifications.success(data.messageSuccess));
    }
    if (data.callback) {
      data.callback();
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.rejectContractError(getErrorMessage(error))),
    ]);
  }
}
