import React, { useEffect, useState } from 'react';
import Box from 'core/ui/Box';
import { useSelector } from 'react-redux';
import { channelsSelectors } from 'modules/channels';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import ModalCalendar from './ModalCalendar';

const PartnersPrograms = ({ modal, setModal, refreshSchedule, calendarInfo }) => {
  const channel = useSelector((state) => channelsSelectors.getChannel(state));
  const [data, setData] = useState([]);
  const { request } = useActions();

  useEffect(() => {
    if (channel && channel.id && modal.open) {
      request({
        action: Modules.partnersProgramsChannels.actions.getPartnersProgramsChannels,
        data: { channelId: channel.id, onlyValid: true },
        options: {
          onSuccess: setData,
        },
      });
    }
  }, [request, channel, modal.open]);

  return (
    <Box>
      {modal.open && (
        <ModalCalendar
          open={modal.open}
          programs={data}
          channel={channel}
          calendarInfo={calendarInfo}
          close={() => setModal((prev) => ({ ...prev, open: false }))}
          callback={() => {
            setModal((prev) => ({ ...prev, open: false }));
            refreshSchedule();
          }}
        />
      )}
    </Box>
  );
};

export default PartnersPrograms;
