import api from 'core/api';

export const automationWorkflowsModule = 'automationWorkflows';

const loadingWorkflowStates = {
  start: { loadingWorkflow: true },
  error: { loadingWorkflow: false },
  success: { loadingWorkflow: false },
};

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const loadingTemplatesStates = {
  start: { loadingTemplates: true },
  error: { loadingTemplates: false },
  success: { loadingTemplates: false },
};

const loadingWorkflowAddUserStates = {
  start: { loadingAddUser: true },
  error: { loadingAddUser: false },
  success: { loadingAddUser: false },
};

const actions = {
  getWorkflows: {
    module: automationWorkflowsModule,
    name: 'getWorkflows',
    api: (params) => api.get(`/automation/workflows`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  getWorkflowsTemplates: {
    module: automationWorkflowsModule,
    name: 'getWorkflowsTemplates',
    api: (params) => api.get(`/automation/workflows/templates`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingTemplatesStates,
  },
  getWorkflow: {
    module: automationWorkflowsModule,
    name: 'getWorkflow',
    api: (params) => api.get(`/automation/workflows/${params?.id}`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingWorkflowStates,
  },
  postWorkflow: {
    module: automationWorkflowsModule,
    name: 'postWorkflow',
    api: (params) => api.post(`/automation/workflows`, params),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingWorkflowStates,
  },
  putWorkflow: {
    module: automationWorkflowsModule,
    name: 'putWorkflow',
    api: (params) => api.put(`/automation/workflows/${params?.id}`, params),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingWorkflowStates,
  },
  addUserInWorkflow: {
    module: automationWorkflowsModule,
    name: 'addUserInWorkflow',
    api: (params) => api.put(`/automation/workflows/${params?.id}/add_user`, params),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingWorkflowAddUserStates,
  },
  deleteWorkflow: {
    module: automationWorkflowsModule,
    name: 'deleteWorkflow',
    api: (params) => api.delete(`/automation/workflows/${params?.id}`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingWorkflowStates,
  },
  cloneWorkflowTemplate: {
    module: automationWorkflowsModule,
    name: 'cloneWorkflowTemplate',
    api: (params) => api.post(`/automation/workflows/clone_template`, params),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingWorkflowStates,
  },
  cloneWorkflow: {
    module: automationWorkflowsModule,
    name: 'cloneWorkflow',
    api: (params) => api.post(`/automation/workflows/clone`, params),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingWorkflowStates,
  },
};

const automationWorkflowsModuleObj = {
  actions,
  state: {
    loadingWorkflow: false,
    loadingActions: false,
    triggers: [],
    actions: [],
  },
};

export default automationWorkflowsModuleObj;
