/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Typography from 'core/ui/Typography';
import Box from 'core/ui/Box';
import SFDataTable from 'core/ui/DataTable';
import { date, timeWithoutTz } from 'core/utils/formatters';
import { Chip, Dialog } from '@material-ui/core';
import { successColor, secondary, primary, colorLightGrey } from 'core/ui/Colors';
import LoaderSm from 'core/ui/LoaderSm';
import { People } from '@material-ui/icons';
import Paginator from 'core/ui/Paginator';
import { Skeleton } from '@material-ui/lab';
import { ButtonPrimarySm } from 'core/ui/Button';
import LabelBreakLine from 'core/ui/LabelBreakLine';
import Modules from 'modules';
import { ownerableMessagesModule } from 'modules/ownerableMessages';
import useSelectors from 'modules/map/useSelectors';
import useActions from 'modules/map/useActions';

const StatusMessage = ({ contact }) => {
  const colorStatus = {
    accepted: successColor,
    sending: successColor,
    sent: successColor,
    queued: secondary,
    delivered: secondary,
    undelivered: secondary,
    delivery_unknown: secondary,
    declined: primary,
    failed: primary,
  };

  return (
    <Chip
      style={{
        backgroundColor: colorStatus[contact.status] ? colorStatus[contact.status] : secondary,
        color: 'white',
      }}
      size="small"
      label={
        <Box display="flex" alignItems="center">
          <LoaderSm
            loading={!!['accepted', 'sending', 'queued'].find((x) => x === contact.status)}
            width={20}
          />
          <Typography
            style={{ textTransform: 'capitalize' }}
            ml={contact.status !== 'sent' ? 1 : 0}
          >
            {contact.status}
          </Typography>
        </Box>
      }
    />
  );
};

const TableMessageLists = ({ messages }) => {
  const [modalContacts, setModalContacts] = useState({ open: false });

  const columns = [
    {
      name: 'Text',
      selector: 'text',
      sortable: true,
      cell: (message) => <Typography>{message.text}</Typography>,
    },
    {
      name: 'Date',
      selector: 'date',
      sortable: true,
      cell: (message) => <Typography>{message.date}</Typography>,
    },
    {
      name: 'Status',
      selector: 'status',
      sortable: true,
      center: true,
      cell: (message) => <StatusMessage contact={message} />,
    },

    {
      name: <People style={{ color: successColor }} />,
      selector: 'contactsSentCount',
      sortable: true,
      center: true,
      cell: (message) => <Typography>{message.contactsSentCount}</Typography>,
    },
    {
      name: <People style={{ color: primary }} />,
      selector: 'contactsUndeliveredCount',
      sortable: true,
      center: true,
      cell: (message) => <Typography>{message.contactsUndeliveredCount}</Typography>,
    },
    {
      name: '',
      selector: 'contactsCount',
      sortable: true,
      center: true,
      cell: (message) => (
        <ButtonPrimarySm
          onClick={() => setModalContacts((prev) => ({ ...prev, open: true, message }))}
        >
          <People style={{ fontSize: 20 }} /> ({message.contactsCount})
        </ButtonPrimarySm>
      ),
    },
  ];

  const rows =
    messages &&
    messages.map((list) => ({
      id: list.id,
      text: `${list.text.substr(0, 15)}${list.text && list.text.length > 15 ? '...' : ''}`,
      fullText: list.text,
      status: list.status,
      date: `${date(list.createdAt)} - ${timeWithoutTz(list.createdAt)}`,
      contactsCount: list.contactsCount,
      contactsUndeliveredCount: list.contactsUndeliveredCount,
      contactsSentCount: list.contactsSentCount,
      contactsPendingCount: list.contactsPendingCount,
    }));

  return (
    <Box>
      <SFDataTable
        columns={columns}
        data={rows}
        pagination
        showPaper={false}
        noTableHead={false}
        noHeader
        showExport
      />

      {modalContacts.open && (
        <ModalMessageContacts
          close={() => setModalContacts({ open: false })}
          open={modalContacts.open}
          message={modalContacts.message}
        />
      )}
    </Box>
  );
};

const ModalMessageContacts = ({ message, close }) => {
  const { request } = useActions();

  const loadingContacts = useSelectors(ownerableMessagesModule, 'loadingContacts');
  const contacts = useSelectors(ownerableMessagesModule, 'contacts');
  const pagination = useSelectors(ownerableMessagesModule, 'pagination');

  const [perPage] = useState(10);

  const getContacts = (p) => {
    request({
      action: Modules.ownerableMessages.actions.getMessageContacts,
      data: {
        ownerableId: message?.ownerableId,
        ownerableType: message?.ownerableType,
        messageId: message.id,
        query: null,
        page: p,
        perPage,
      },
    });
  };

  useEffect(() => {
    if (message && message.id) {
      getContacts(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changePage = (p) => {
    if (p <= pagination.totalPages) {
      getContacts(p);
    }
  };

  const columns = [
    {
      name: 'Name',
      selector: 'name',
      sortable: true,
      cell: (contact) => <Typography>{contact.name}</Typography>,
    },
    {
      name: 'List',
      selector: 'messageListName',
      sortable: true,
      cell: (contact) => <Typography>{contact.messageListName}</Typography>,
    },
    {
      name: 'Phone',
      selector: 'phone',
      sortable: true,
      cell: (contact) => <Typography>{contact.phone}</Typography>,
    },
    {
      name: 'Status',
      selector: 'status',
      sortable: true,
      center: true,
      cell: (contact) => <StatusMessage contact={contact} />,
    },
  ];

  const rows =
    message &&
    contacts &&
    contacts.map((list) => ({
      id: list.id,
      name: list.name,
      phone: list.formattedPhone,
      status: list.status,
      messageListName: list.messageList && list.messageList.name,
    }));

  return (
    <Dialog maxWidth="md" fullWidth open onClose={close} scroll="body">
      <Box p={5} style={{ backgroundColor: colorLightGrey }}>
        <Box m={5}>
          <Typography>
            <LabelBreakLine>{message.fullText}</LabelBreakLine>
          </Typography>
        </Box>

        {loadingContacts ? (
          <Skeleton variant="rect" animation="wave" style={{ height: 200, width: '100%' }} />
        ) : (
          <SFDataTable
            columns={columns}
            data={rows}
            pagination={false}
            showPaper={false}
            showExport
            noTableHead={false}
            noHeader
          />
        )}

        <Box flexGrow={1} m={3} display="flex" justifyContent="center">
          <Paginator pagination={pagination} changePage={(p) => changePage(p)} />
        </Box>
      </Box>
    </Dialog>
  );
};

TableMessageLists.propTypes = {};

TableMessageLists.defaultProps = {};

export default TableMessageLists;
