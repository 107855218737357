import React, { useContext, useState } from 'react';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import { ButtonPrimary } from 'core/ui/Button';
import Modules from 'modules';
import useActions from 'modules/map/useActions';
import useSelectors from 'modules/map/useSelectors';
import { newChannelsModule } from 'modules/channels/new';
import Typography from 'core/ui/Typography';
import LoaderSm from 'core/ui/LoaderSm';
import { ChannelContext } from '../..';
import Memberships from './Memberships';
import ModalAddMembers from './AddMembers/Modal';
import Denylists from '../Denylists';

const Members = () => {
  const { t } = useTranslation();
  const loadingPull = useSelectors(newChannelsModule, 'loadingPull');
  const { request } = useActions();
  const channelContext = useContext(ChannelContext);
  const [modalAddMembers, openModalAddMembers] = useState(false);

  const manualPullMembers = () => {
    request({
      action: Modules.newChannels.actions.manualUpdateMemberTypes,
      data: {
        channelId: channelContext.channel?.id,
      },
    });
  };

  return (
    <Box p={5}>
      {modalAddMembers && (
        <ModalAddMembers
          channelId={channelContext.channel.id}
          close={() => openModalAddMembers(false)}
        />
      )}
      <Box display="flex" mb={5} alignItems="center">
        <Box flexGrow="1" alignItems="center" />
        <Box>
          <Denylists />
          <ButtonPrimary style={{ marginLeft: 3 }} onClick={() => openModalAddMembers(true)}>
            {t('button.addMembers')}
          </ButtonPrimary>
          <ButtonPrimary
            loading={loadingPull}
            style={{ marginLeft: 3 }}
            onClick={() => manualPullMembers(true)}
          >
            {t('button.manualPull')}
          </ButtonPrimary>
        </Box>
      </Box>
      {loadingPull && (
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <LoaderSm loading />
          <Typography ml={1}>{t('channel.update.members.loading')}</Typography>
        </Box>
      )}

      <Memberships channel={channelContext.channel} />
    </Box>
  );
};

export default Members;
