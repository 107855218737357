import NewWorkout from 'pages/Workout/New';
import EditWorkout from 'pages/Workout/Edit';

import NewCompetition from 'pages/Competition/New';
import EditCompetition from 'pages/Competition/Edit';
import channel from './channel';

export default [
  ...channel,
  {
    path: '/workout/new',
    exact: true,
    name: 'New Workout',
    component: NewWorkout,
  },
  {
    path: '/workout/edit/:id',
    exact: true,
    name: 'Edit Workout',
    component: EditWorkout,
  },
  {
    path: '/competition/new',
    exact: true,
    name: 'New Competition',
    component: NewCompetition,
  },
  {
    path: '/competition/edit/:id',
    exact: true,
    name: 'Edit Competition',
    component: EditCompetition,
  },
  {
    path: '/competition/edit/:id/:tab',
    exact: true,
    name: 'Edit Competition',
    component: EditCompetition,
  },
];
