import React from 'react';
import Typography, { fontStyle } from 'core/ui/Typography';
import Box from 'core/ui/Box';
import { Chip } from '@material-ui/core';
import { successColor, secondary, redColor } from 'core/ui/Colors';
import LoaderSm from 'core/ui/LoaderSm';
import { date, time } from 'core/utils/formatters';
import { useTranslation } from 'react-i18next';

const StatusMessage = ({ contact, channel }) => {
  const { t } = useTranslation();
  const colorStatus = {
    accepted: successColor,
    sending: successColor,
    sent: successColor,
    queued: secondary,
    delivered: secondary,
    undelivered: secondary,
    delivery_unknown: secondary,
    failed: redColor,
  };

  if (contact.status === 'queued' && contact.scheduleSendAt) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" flexWrap="wrap">
        <Typography style={fontStyle.medium} noWrap mr={1}>
          {t('label.scheduled')}
        </Typography>
        <Typography noWrap style={fontStyle.medium}>
          {date(contact?.scheduleSendAt, channel?.dateFormatExtReact)}&nbsp;&bull;&nbsp;
          {time(contact?.scheduleSendAt)}
        </Typography>
      </Box>
    );
  }

  return (
    <Chip
      style={{
        backgroundColor: colorStatus[contact.status] ? colorStatus[contact.status] : secondary,
        color: 'white',
      }}
      size="small"
      label={
        <Box display="flex" alignItems="center">
          <LoaderSm
            loading={!!['accepted', 'sending', 'queued'].find((x) => x === contact.status)}
            width={20}
          />
          <Typography
            style={{ textTransform: 'capitalize' }}
            ml={contact.status !== 'sent' ? 1 : 0}
          >
            {contact.status}
          </Typography>
        </Box>
      }
    />
  );
};

export default StatusMessage;
