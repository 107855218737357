/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import Typography from 'core/ui/Typography';
import FontIcon from 'core/ui/FontIcon';
import { primary } from 'core/ui/Colors';
import Paper from 'core/ui/Paper';
import Grid from 'core/ui/Grid';
import LoaderSm from 'core/ui/LoaderSm';
import GridSkeleton from 'core/ui/GridSkeleton';
import { searchActions, searchSelectors } from 'modules/search';
import { useDispatch, useSelector } from 'react-redux';
import { sessionSelectors } from 'modules/session';
import Item from './Item';
import CardCoach from './Cards/CardCoach';
import SectionTitle from './SectionTitle';

export default ({ model, channelId, onClickCard, hideUpcoming, buttonColor }) => {
  const { t } = useTranslation();
  const mounted = useRef();
  const isAuthenticated = useSelector((state) => sessionSelectors.getIsAuthenticated(state));
  const loadingFeatured = useSelector((state) => searchSelectors.getLoadingFeatured(state));
  const loadingFeaturedUpcoming = useSelector((state) =>
    searchSelectors.getLoadingFeaturedUpcoming(state),
  );

  const configPagination = {
    Workout: {
      perPage: 8,
    },
    User: {
      perPage: 18,
    },
    Channel: {
      perPage: 12,
    },
  };
  const dispatch = useDispatch();
  const [featuredItems, setFeatureditems] = useState([]);
  const [recents, setRecents] = useState([]);
  const [upcoming, setUpcoming] = useState([]);
  const [registered, setRegistered] = useState([]);

  const loadFeatureds = useCallback(async () => {
    try {
      if (model === 'Workout') {
        if (mounted.current === true) {
          dispatch(
            searchActions.getFeaturedStart({
              page: 1,
              perPage: configPagination[model].perPage,
              model,
              recents: true,
              channel_id: channelId,
              callback: (data) => {
                if (mounted.current === true) {
                  setRecents(data.workout.data);
                }
              },
            }),
          );
        }

        if (mounted.current === true && isAuthenticated) {
          dispatch(
            searchActions.getFeaturedRegisteredStart({
              page: 1,
              perPage: configPagination[model].perPage,
              model,
              registered: true,
              channel_id: channelId,
              callback: (data) => {
                if (mounted.current === true) {
                  setRegistered(data.workout.data);
                }
              },
            }),
          );
        }

        if (mounted.current === true && !hideUpcoming) {
          dispatch(
            searchActions.getFeaturedUpcomingStart({
              page: 1,
              perPage: configPagination[model].perPage,
              model,
              upcoming: true,
              channel_id: channelId,
              callback: (data) => {
                if (mounted.current === true) {
                  setUpcoming(data.workout.data);
                }
              },
            }),
          );
        }
      } else {
        dispatch(
          searchActions.getFeaturedStart({
            page: 1,
            perPage: configPagination[model].perPage,
            model,
            channel_id: channelId,
            callback: (data) => {
              if (mounted.current === true) {
                setFeatureditems(data[model.toLowerCase()].data);
              }
            },
          }),
        );
      }
      // eslint-disable-next-line no-empty
    } catch {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    mounted.current = true;
    loadFeatureds();

    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return (
    <Paper className="paper-rounded" p={5}>
      {model === 'Workout' ? (
        <>
          {(loadingFeaturedUpcoming || upcoming.filter((x) => x.model === model).length > 0) && (
            <Box mb={3} display="flex" alignItems="center">
              <Box flexGrow="1" display="flex" alignItems="center">
                {/* <FontIcon iconName="icon-live" color={primary} size={14} /> */}
                <Typography ml={1} color="secondary" className="link" variant="h5">
                  {t('search.featured.upcoming').toUpperCase()}
                </Typography>
              </Box>
              <Box>
                <SectionTitle
                  titleViewMore={t('search.featured.upcoming')}
                  model={model}
                  icon="icon-fire"
                  type="featured"
                  upcoming
                />
              </Box>
            </Box>
          )}
          {loadingFeaturedUpcoming && (
            <Box mb={3}>
              <GridSkeleton />
            </Box>
          )}
          {upcoming.filter((x) => x.model === model).length > 0 && (
            <Grid container mb={3}>
              {upcoming
                .filter((x) => x.model === model)
                .map((object, index) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    key={index.toString()}
                    className="search-item"
                  >
                    <Box
                      m={2}
                      key={index.toString()}
                      className="hover"
                      onClick={() => (channelId && onClickCard ? onClickCard(object.item) : null)}
                    >
                      <Item
                        object={object}
                        key={index.toString()}
                        clickable={!onClickCard}
                        buttonColor={buttonColor}
                      />
                    </Box>
                  </Grid>
                ))}
            </Grid>
          )}

          {(loadingFeatured || recents.filter((x) => x.model === model).length > 0) && (
            <Box mb={3} display="flex" alignItems="center">
              <Box flexGrow="1" display="flex" alignItems="center">
                {/* <FontIcon iconName="icon-clock-ok" color={primary} size={14} /> */}
                <Typography ml={1} color="secondary" className="link" variant="h5">
                  {t('search.featured.recents').toUpperCase()}
                </Typography>
              </Box>
              <Box>
                <SectionTitle
                  titleViewMore={t('search.featured.recents')}
                  model={model}
                  icon="icon-clock-ok"
                  type="featured"
                  recents
                />
              </Box>
            </Box>
          )}
          {loadingFeatured && (
            <Box mb={3}>
              <GridSkeleton />
            </Box>
          )}
          <Grid container>
            {recents
              .filter((x) => x.model === model)
              .map((object, index) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  key={index.toString()}
                  className="search-item"
                >
                  <Box
                    m={2}
                    key={index.toString()}
                    onClick={() => (channelId && onClickCard ? onClickCard(object.item) : null)}
                  >
                    <Item
                      object={object}
                      key={index.toString()}
                      clickable={!onClickCard}
                      buttonColor={buttonColor}
                    />
                  </Box>
                </Grid>
              ))}
          </Grid>

          {(loadingFeatured || registered.filter((x) => x.model === model).length > 0) && (
            <Box mb={3} display="flex" alignItems="center">
              <Box flexGrow="1" display="flex" alignItems="center">
                <Typography ml={1} color="secondary" className="link" variant="h5">
                  {t('search.featured.registered').toUpperCase()}
                </Typography>
              </Box>
              <Box>
                <SectionTitle
                  titleViewMore={t('search.featured.registered')}
                  model={model}
                  icon="icon-clock-ok"
                  type="featured"
                  registered
                />
              </Box>
            </Box>
          )}
          {loadingFeatured && (
            <Box mb={3}>
              <GridSkeleton />
            </Box>
          )}
          <Grid container>
            {registered
              .filter((x) => x.model === model)
              .map((object, index) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  key={index.toString()}
                  className="search-item"
                >
                  <Box
                    m={2}
                    key={index.toString()}
                    onClick={() => (channelId && onClickCard ? onClickCard(object.item) : null)}
                  >
                    <Item
                      object={object}
                      key={index.toString()}
                      clickable={!onClickCard}
                      buttonColor={buttonColor}
                    />
                  </Box>
                </Grid>
              ))}
          </Grid>
        </>
      ) : (
        <Box mb={3} display="flex" alignItems="center">
          <Box flexGrow="1" display="flex" alignItems="center">
            <FontIcon iconName="icon-fire" color={primary} size={14} />
            <Typography ml={1} color="secondary" className="link" variant="h5">
              {t('search.featured.title').toUpperCase()}
            </Typography>
            <LoaderSm loading={loadingFeatured} size={10} />
          </Box>
          {featuredItems.filter((x) => x.model === model).length > 0 && (
            <Box>
              <SectionTitle
                titleViewMore={t('search.featured.title')}
                model={model}
                icon="icon-fire"
                type="featured"
              />
            </Box>
          )}
        </Box>
      )}

      {loadingFeatured && (
        <Box mb={3}>
          <GridSkeleton />
        </Box>
      )}
      {!loadingFeatured && (
        <>
          {model === 'User' && (
            <Grid container className="search-item">
              {featuredItems
                .filter((x) => x.model === 'User')
                .map((object, index) => (
                  <Grid item xs={6} sm={4} md={2} lg={2} key={index.toString()} className="item">
                    <Box m={2}>
                      <CardCoach coach={object.item} fullWidth />
                    </Box>
                  </Grid>
                ))}
            </Grid>
          )}

          {model === 'Channel' && (
            <Grid container>
              {featuredItems
                .filter((x) => x.model === model)
                .map((object, index) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    key={index.toString()}
                    className="search-item"
                  >
                    <Box m={2} key={index.toString()}>
                      <Item object={object} key={index.toString()} buttonColor={buttonColor} />
                    </Box>
                  </Grid>
                ))}
            </Grid>
          )}
        </>
      )}
    </Paper>
  );
};
