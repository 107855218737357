import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import propTypes from 'prop-types';
import Typography, { fontStyle } from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { competitionsActions } from 'modules/competitions';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import { ButtonPrimary } from 'core/ui/Button';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmDialog from 'core/ui/ConfirmDialog';
import notifications from 'modules/notifications';
import CountryChosen from 'core/ui/CountryChosen';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import TagsChosen from 'core/ui/TagsChosen';
import Modules from 'modules';
import useActions from 'modules/map/useActions';
import { competitionsUsersModule } from 'modules/competitionsUsers';
import useSelectors from 'modules/map/useSelectors';
import SelectorPaymentMethod from 'components/Account/Selector';
import { sessionSelectors } from 'modules/session';
import { channelCurrency } from 'core/utils/formatters';
import { secondary } from 'core/ui/Colors';
import useCompRegistration, { registrationSteps } from '../useCompRegistration';
import AddAthletes from './AddAthletes';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#f3f3f3',
  },
  form: {
    backgroundColor: '#ffffff',
    backgroundRepeat: 'repeat',
    padding: 10,
  },
}));

export default function ModalEditTeam({ competition, open, close, userId, buttonColor, regType }) {
  const currentUser = useSelector((state) => sessionSelectors.getUser(state));
  const classes = useStyles();
  const { t } = useTranslation();
  const { request } = useActions();
  const {
    setStep,
    resume,
    invites,
    setInvites,
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    viewMethodPayment,
    setViewMethodPayment,
  } = useCompRegistration({ close: () => close(false) });

  const dispatch = useDispatch();
  const loadingRegistration = useSelectors(competitionsUsersModule, 'loading');

  const [modalConfirm, setModalConfirm] = useState(true);

  const [teamData, setTeamData] = useState({});

  const getRegistration = () => {
    request({
      action: Modules.competitionsUsers.actions.show,
      data: {
        competitionId: competition?.id,
        userId,
        regType,
      },
      options: {
        onSuccess: (team) => {
          setTeamData({
            ...team,
            name: team.team,
            allowEditDivision: team.allowEditDivision,
            countryId: team.country && team.country.id,
            captainId: team.captainId,
            location: team.location,
            userName: team.userName,
            regType: team.regType,
            competitionsDivisionId: team.division && team.division.id,
            teamNumber: team.teamNumber,
            tags: team.tags && team.tags.map((x) => x.name),
            competitionsAffiliateId: team.affiliate && team.affiliate.id,
          });
          setInvites(team.teamUsers?.map((x) => ({ ...x, exists: true })));
          setStep(registrationSteps.finish);
        },
      },
    });
  };

  useEffect(() => {
    if (userId) {
      getRegistration();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, regType]);

  const confirmRemoveUser = (uId) => {
    request({
      action: Modules.competitionsUsers.actions.delete,
      data: {
        regType: 'competitor',
        competitionId: competition.id,
        userId: uId,
      },
      options: {
        onSuccess: () => {
          dispatch(competitionsActions.getCompetitionStart(competition.id));
        },
        onError: (error) => {
          dispatch(notifications.error(error));
        },
      },
    });
  };

  const editTeam = () => {
    request({
      action: Modules.competitionsUsers.actions.update,
      data: {
        kind: 'competitor',
        regType: teamData.regType,
        competitionId: competition.id,
        userId,
        team: teamData.name,
        userName: teamData.userName,
        location: teamData.location,
        countryId: teamData.countryId,
        teamNumber: teamData.teamNumber,
        competitionDivisionId: teamData.competitionsDivisionId,
        competitionAffiliateId: teamData.affiliateId,
        invites: invites.filter((x) => !x.exists),
        userPaymentMethodId: selectedPaymentMethod?.id,
        tags:
          teamData.tags &&
          teamData.tags.map((x) => ({
            name: x.name ? x.name : x,
          })),
      },
      options: {
        onSuccess: () => {
          dispatch(competitionsActions.getCompetitionStart(competition.id));
          dispatch(notifications.success(t('competition.team.edit.success')));
          close();
        },
        onError: (error) => {
          dispatch(notifications.error(error));
        },
      },
    });
  };

  const divisions = competition.hasDivisions ? competition.divisions : [];

  const removeUser = (item) => {
    setModalConfirm((prev) => ({
      ...prev,
      open: true,
      callback: () => {
        confirmRemoveUser(item?.id);
      },
    }));
  };

  const setAsCaptain = (uId) => {
    request({
      action: Modules.competitionsUsers.actions.updateCaptain,
      data: {
        competitionId: competition.id,
        userId: uId,
      },
      options: {
        onSuccess: () => {
          dispatch(competitionsActions.getCompetitionStart(competition.id));
          dispatch(notifications.success(t('competition.team.edit.success')));
          close();
        },
        onError: (error) => {
          dispatch(notifications.error(error));
        },
      },
    });
  };

  return (
    <div>
      <ConfirmDialog
        open={modalConfirm.open}
        setOpen={(v) => setModalConfirm((prev) => ({ ...prev, open: v }))}
        description={t('competition.delete.user')}
        labelConfirm={t('confirm')}
        labelCancel={t('cancel')}
        confirm={modalConfirm.callback}
      />
      <Dialog
        PaperProps={{ style: { borderRadius: 5 } }}
        maxWidth="md"
        fullWidth
        scroll="body"
        onClose={() => close()}
        open={open}
      >
        <div className={classes.root}>
          <Box px={5} py={2}>
            <Box display="flex">
              <Box flexGrow={1}>
                <Typography mt={3} mb={3} align="center" color="primary" variant="h4">
                  {teamData.name}
                </Typography>
              </Box>
              <IconButton onClick={() => close()}>
                <Close />
              </IconButton>
            </Box>
            {teamData?.regType === 'team' && (
              <>
                <Box mb={3}>
                  <TextFieldRounded
                    value={teamData.name}
                    onChange={(v) => setTeamData((prev) => ({ ...prev, name: v }))}
                  />
                </Box>

                <Box mb={3}>
                  <CountryChosen
                    setValue={(v) => setTeamData((prev) => ({ ...prev, countryId: v }))}
                    defaultValue={teamData.countryId}
                    label={t('competition.country')}
                  />
                </Box>

                <Box mb={3}>
                  <TextFieldRounded
                    name="name"
                    onChange={(v) =>
                      setTeamData((prev) => ({
                        ...prev,
                        location: v,
                      }))
                    }
                    value={teamData.location || ''}
                    placeholder={t('competition.registration.label.location')}
                    label={t('competition.registration.label.location')}
                  />
                </Box>
              </>
            )}
            {teamData?.regType === 'individual' && (
              <>
                <Box mb={3}>
                  <TextFieldRounded
                    name="userName"
                    onChange={(v) =>
                      setTeamData((prev) => ({
                        ...prev,
                        userName: v,
                      }))
                    }
                    value={teamData.userName || ''}
                    placeholder={t('label.userName')}
                    label={t('label.userName')}
                  />
                </Box>

                <Box mb={3}>
                  <CountryChosen
                    setValue={(v) => setTeamData((prev) => ({ ...prev, countryId: v }))}
                    defaultValue={teamData.countryId}
                    label={t('competition.country')}
                  />
                </Box>
              </>
            )}

            {competition.affiliates && competition.affiliates.length > 0 && (
              <Box mb={3}>
                <SelectBoxObject
                  value={teamData.affiliateId || -1}
                  setValue={(v) =>
                    setTeamData((prev) => ({
                      ...prev,
                      affiliateId: v,
                    }))
                  }
                  options={[{ id: -1, gymName: 'No Affiliate' }, ...competition.affiliates]}
                  propLabel="gymName"
                  propValue="id"
                  label={t('competition.affiliateGym')}
                />
              </Box>
            )}

            {teamData?.allowEditDivision && divisions && divisions.length > 0 && (
              <Box mb={3}>
                <SelectBoxObject
                  value={teamData.competitionsDivisionId}
                  options={divisions}
                  setValue={(v) => setTeamData((prev) => ({ ...prev, competitionsDivisionId: v }))}
                  propLabel="name"
                  propValue="id"
                  label={t('competition.division')}
                />
              </Box>
            )}

            {competition?.isCoach && (
              <Box mb={3}>
                <TextFieldRounded
                  label="Number"
                  value={teamData.teamNumber}
                  onChange={(v) => setTeamData((prev) => ({ ...prev, teamNumber: v }))}
                />
              </Box>
            )}

            <Box mb={3}>
              <Typography ml={2} style={fontStyle.label}>
                {t('competition.registration.label.tags')}
              </Typography>
              <Typography style={fontStyle.xSmall} mb={1} ml={3}>
                {t('competition.registration.help.tags')}
              </Typography>
              <TagsChosen
                placeholder={t('competition.registration.label.tags')}
                setValue={(values) => setTeamData((prev) => ({ ...prev, tags: values }))}
                limit={50}
                kind="competition"
                val={teamData.tags ? [...teamData.tags] : []}
                iconTag={
                  <Typography ml={1.5} variant="h6" style={{ color: 'white' }}>
                    #
                  </Typography>
                }
              />
            </Box>
            {!teamData?.allowEditDivision && divisions && divisions.length > 0 && (
              <Typography my={5} color="primary" variant="h4">
                {t('message.divisions.cannot.edit')}
              </Typography>
            )}

            {teamData?.regType === 'team' && (
              <AddAthletes
                competition={competition}
                invites={invites}
                setInvites={setInvites}
                removeUser={removeUser}
                eventType="team"
                setAsCaptain={setAsCaptain}
                captainId={teamData?.captainId}
                edit
              />
            )}

            {resume?.total > 0 && (
              <>
                {teamData?.regType === 'team' && (
                  <SelectorPaymentMethod
                    selectedPaymentMethod={selectedPaymentMethod}
                    setSelectedPaymentMethod={setSelectedPaymentMethod}
                    view={viewMethodPayment}
                    setView={setViewMethodPayment}
                    userId={currentUser.id}
                    userInfo={{
                      name: currentUser?.name,
                      phone: currentUser?.phone,
                      email: currentUser?.email,
                    }}
                    cardpointe={!!competition?.channel?.useCardpointe}
                    onlyMethod={competition?.channel?.allowAchPayments ? null : 'card'}
                  />
                )}
                <Box mx={2} mt={3}>
                  <Typography align="center" variant="h6" style={{ color: secondary }}>
                    {t('competition.registration.cost')}
                  </Typography>
                  <Typography align="center" color="primary" variant="h2">
                    {channelCurrency(resume?.total, competition?.currency)}
                  </Typography>
                </Box>
              </>
            )}

            <Box my={3}>
              <ButtonPrimary
                style={{ backgroundColor: buttonColor }}
                disabled={loadingRegistration}
                fullWidth
                onClick={() => editTeam()}
              >
                {t('button.save')}
              </ButtonPrimary>
            </Box>
          </Box>
        </div>
      </Dialog>
    </div>
  );
}

ModalEditTeam.propTypes = {
  open: propTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  competition: propTypes.object,
  close: propTypes.func,
};

ModalEditTeam.defaultProps = {
  competition: {},
  open: false,
  close: null,
};
