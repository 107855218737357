import React from 'react';
import WorkoutScoresPoints from 'components/Competition/Show/Tabs/Results/Tables/WorkoutScoresPoints';
import { fontStyle } from 'core/ui/Typography';

const BenchmarkUserScores = ({ sectionScores, scores }) => {
  let newScores = scores;
  if (!Array.isArray(newScores)) {
    newScores = [scores];
  }

  if (!scores || scores.length === 0) {
    return <></>;
  }

  return (
    <WorkoutScoresPoints
      sectionScores={sectionScores}
      scores={newScores}
      showVariation
      showFullNotes
    />
  );
};

BenchmarkUserScores.defaultProps = {
  fontValue: fontStyle.small,
  fontScore: fontStyle.xSmall,
};

export default BenchmarkUserScores;
