import { useEffect, useState } from 'react';

// Hook
export default function useWindowSize() {
  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
}

const getSize = () => ({
  width: window ? window.innerWidth : undefined,
  height: window ? window.innerHeight : undefined,
});
