import React from 'react';
import { Skeleton } from '@material-ui/lab';
import Box from 'core/ui/Box';
import Grid from 'core/ui/Grid';
import Typography, { fontStyle } from 'core/ui/Typography';
import { gymDate, timeWithoutTz } from 'core/utils/formatters';
import { useTranslation } from 'react-i18next';
import { ButtonPrimarySm } from 'core/ui/Button';
import { useHistory } from 'react-router';
import { Link } from '@material-ui/core';
import SFDataTable from 'core/ui/DataTable';
import ImageMedia from 'components/Profile/ImageMedia';

const TableSessions = ({ data, coaching }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const goToWorkoutNew = (row) => {
    history.push(`/workout/new?coachScheduleSessionId=${row.id}`);
  };

  const columns = [
    {
      cell: (row) => (
        <Box p={1}>
          <Box>
            {coaching ? (
              <ImageMedia
                user={row.user}
                image={row.user.image ? row.user.image : {}}
                size={40}
                showCountry={false}
              />
            ) : (
              <ImageMedia
                user={row.coach}
                image={row.coach.image ? row.coach.image : {}}
                size={40}
                showCountry={false}
              />
            )}
          </Box>
        </Box>
      ),
    },
    {
      cell: (row) => (
        <Box>
          <Box>
            {coaching ? (
              <Typography style={{ ...fontStyle.medium }}>{row.user.name}</Typography>
            ) : (
              <Typography style={{ ...fontStyle.medium }}>{row.coach && row.coach.name}</Typography>
            )}
          </Box>
        </Box>
      ),
    },
    {
      cell: (row) => (
        <Box>
          <Box>
            <Typography style={{ ...fontStyle.small }}>
              {row.workout
                ? `${gymDate(row.workout.scheduledAt)} - ${timeWithoutTz(row.workout.scheduledAt)}`
                : 'Date/Time: TBD'}
            </Typography>
          </Box>
          <Box>
            {row.workout && (
              <Typography style={{ ...fontStyle.small }}>
                <Link href={`/workout/view/${row.workout.id}`} target="_blank">
                  {row.workout && row.workout.name}
                </Link>
              </Typography>
            )}
          </Box>
        </Box>
      ),
    },
    {
      cell: (row) => (
        <Box>
          {coaching && !row.workout && (
            <ButtonPrimarySm onClick={() => goToWorkoutNew(row)}>
              <Typography style={{ ...fontStyle.small }}>{t('button.createWorkout')}</Typography>
            </ButtonPrimarySm>
          )}
        </Box>
      ),
    },
  ];

  return (
    <Grid container spacing={3}>
      <SFDataTable
        noHeader
        noTableHead
        title=""
        showPaper={false}
        columns={columns}
        data={data}
        pagination
        noDataComponent=""
      />
    </Grid>
  );
};

export default ({ loading, coachSessions, userSessions, isCoach }) => {
  const { t } = useTranslation();

  return (
    <>
      {(loading || coachSessions.length > 0 || userSessions.length > 0) && (
        <>
          <Box display="flex" alignItems="center">
            <Typography color="secondary" className="link" variant="h6">
              {t('section.title.upcomingScheduledSessions').toUpperCase()}
            </Typography>
          </Box>

          <Grid container spacing={3} mx={3}>
            {isCoach && (
              <Grid item xs={12} sm={12} md={6} lg={6}>
                {!loading ? (
                  <>
                    <Typography color="primary" mb={3}>
                      {t('section.title.coaching')}
                    </Typography>
                    <TableSessions data={coachSessions} coaching />
                  </>
                ) : (
                  <Skeleton variant="rect" style={{ height: 200, width: '100%' }} />
                )}
              </Grid>
            )}
            <Grid item xs={12} sm={12} md={isCoach ? 6 : 12} lg={isCoach ? 6 : 12}>
              {!loading ? (
                <>
                  <Typography color="primary" mb={3}>
                    {t('section.title.attending')}
                  </Typography>
                  <TableSessions data={userSessions} coaching={false} />
                </>
              ) : (
                <Skeleton variant="rect" style={{ height: 200, width: '100%' }} />
              )}
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};
