import { useState, useEffect, useContext, useRef } from 'react';
/* eslint-disable react-hooks/exhaustive-deps */
import { TypesSignal } from 'core/openVidu/useOpenVidu';
import { getTimeNow } from 'core/utils/formatters/date';
import { sessionSelectors } from 'modules/session';
import { StreamContext } from 'pages/Stream';
import { useSelector } from 'react-redux';
import { roomSelectors } from 'modules/room';
import Beep from 'assets/songs/timer-short-beep.wav';
import BeepEnd from 'assets/songs/timer-long-beep.wav';
import { convertToSeconds } from 'core/utils/helpers';

const audioBeep = new Audio(Beep);
const audioBeepEnd = new Audio(BeepEnd);

audioBeep.load();
audioBeepEnd.load();

export const countDownTime = 10;

const unlimitedTimer = 5 * 60 * 60;

const useNewTimer = ({ section, isCoach, live, playingRecordings, playingCompetition }) => {
  const userSession = useSelector((state) => sessionSelectors.getUser(state));
  const streamContext = useContext(StreamContext);
  const signalStream = useSelector((state) => roomSelectors.getSignalStream(state));
  const timer = useRef();
  const [currentInfo, setCurrentInfo] = useState({ time: 0, active: '', round: 1, ended: false });

  const [configTimer, setConfigTimer] = useState({});

  const setConfigTimerRound = (nextRound) => {
    if (!section?.timerData) {
      return {};
    }
    let config = {};
    if (!section?.timerData?.customInterval) {
      config = {
        countdownTime: countDownTime,
        workTime: section.timerData.unlimited
          ? unlimitedTimer
          : convertToSeconds(section.timerData.work),
        restTime: section.timerData.rest ? convertToSeconds(section.timerData.rest) : 0,
        rounds: Number(section.timerData.rounds || 1),
        count: section.timerData.count.toUpperCase(),
        muted: false,
      };
    } else {
      const timerCurrentRound = section.timerData.customIntervalTimers[nextRound - 1];

      config = {
        countdownTime: countDownTime,
        workTime: convertToSeconds(timerCurrentRound.work),
        restTime: timerCurrentRound.rest ? convertToSeconds(timerCurrentRound.rest) : 0,
        rounds: Number(section.timerData.rounds || 1),
        count: section.timerData.count.toUpperCase(),
        muted: false,
      };
    }

    setConfigTimer(config);

    return config;
  };

  useEffect(() => {
    setConfigTimerRound(1);
  }, [section]);

  const [state, setState] = useState({
    time: 0,
    start: 0,
    isOn: false,
  });

  const currentValues = () => {
    if (!state.isOn) {
      return { start: state.start, round: 1, active: '', time: 0 };
    }
    if (state.time < configTimer.countdownTime * 1000) {
      return {
        start: state.start,
        round: 1,
        active: 'countdown',
        time: Math.round(state.time / 1000),
      };
    }

    let timeValue = Math.round((state.time - configTimer.countdownTime * 1000) / 1000);
    if (timeValue < 0) {
      timeValue = 0;
    }

    if (configTimer.rounds === 1) {
      return { start: state.start, round: 1, active: 'work', time: timeValue };
    }

    let config = configTimer;

    let round = 1;
    let sRound = true;
    let totalRounds = 0;
    while (sRound) {
      let workTime = 0;
      let restTime = 0;
      totalRounds = 0;
      if (!section.timerData.customInterval) {
        workTime = config.workTime;
        restTime = config.restTime;
        totalRounds = (workTime + restTime) * round;
      } else {
        for (let j = 0; j < round; j += 1) {
          const timerCurrentRound = section.timerData.customIntervalTimers[j];
          if (timerCurrentRound) {
            workTime = convertToSeconds(timerCurrentRound.work);
            restTime = timerCurrentRound.rest ? convertToSeconds(timerCurrentRound.rest) : 0;
            totalRounds += workTime + restTime;
          }
        }
      }

      if (timeValue <= totalRounds || round >= config.rounds) {
        sRound = false;
        break;
      }
      round += 1;
    }

    config = setConfigTimerRound(round);

    if (round > config.rounds) {
      round = config.rounds;
    }
    // const current = round * (config.workTime + config.restTime);

    let value = totalRounds - timeValue;

    let active = '';

    if (value < config.restTime) {
      active = 'rest';
      value = config.restTime - value;
    } else {
      active = 'work';
      value -= config.restTime;
      value = config.workTime - value;
    }

    return { start: state.start, round, active, time: value };
  };

  const playAudio = (long = false) => {
    setTimeout(() => {
      if (long) {
        audioBeepEnd.currentTime = 0;
        audioBeepEnd.play();
      } else {
        audioBeep.currentTime = 0;
        audioBeep.play();
      }
    }, 1);
  };

  /// BEEP ///
  useEffect(() => {
    if (
      currentInfo.active === '' ||
      configTimer.muted === true ||
      !!section.timerData.silenceTimer
    ) {
      return;
    }
    let activeTime = currentInfo.active === 'work' ? configTimer.workTime : configTimer.restTime;
    if (currentInfo.active === 'countdown') {
      activeTime = configTimer.countdownTime;
    }
    try {
      const currentTime = activeTime - currentInfo.time;
      if (currentTime <= 3 && currentTime > 0) {
        playAudio(false);
      }

      if (currentTime <= 0) {
        playAudio(true);
      }
    } catch (ex) {
      console.log('error to play', ex);
    }
  }, [currentInfo.time]);

  useEffect(() => {
    setCurrentInfo((prev) => ({ ...prev, ...currentValues() }));
  }, [state.time]);

  const stopTimer = () => {
    clearInterval(timer.current);
    timer.current = null;
    setState((prev) => ({ ...prev, isOn: false }));
  };

  const startTimer = () => {
    setState((prev) => ({
      ...prev,
      time: prev.time,
      start: Date.now() - prev.time,
      isOn: true,
    }));

    timer.current = setInterval(
      () =>
        setState((prev) => ({
          ...prev,
          time: Date.now() - prev.start,
        })),
      200,
    );
  };

  const resetTimer = (send = false, ended = false) => {
    stopTimer();
    if (live && send) {
      streamContext.eventSideBar.sendSignalUserChanged({
        type: TypesSignal.resetTimer,
      });
    }
    setConfigTimerRound(1);
    setCurrentInfo((prev) => ({ ...prev, ended }));
    setState((prev) => ({ ...prev, isOn: false, time: 0, round: 1, active: '' }));
  };

  /// VALIDATE WORK TIMER ////
  useEffect(() => {
    if (currentInfo.active !== 'work') {
      return;
    }
    const config = setConfigTimerRound(currentInfo.round);
    let total = 0;
    const seconds = state.time / 1000;

    if (!section.timerData.customInterval) {
      total =
        config.countdownTime +
        config.workTime * config.rounds +
        config.restTime * (config.rounds > 1 ? config.rounds - 1 : 1);
    } else {
      total = config.countdownTime;
      for (let j = 0; j < config.rounds; j += 1) {
        const timerCurrentRound = section.timerData.customIntervalTimers[j];
        if (timerCurrentRound) {
          const workTime = convertToSeconds(timerCurrentRound.work);
          const restTime = timerCurrentRound.rest ? convertToSeconds(timerCurrentRound.rest) : 0;
          total += workTime + restTime;
        }
      }
    }

    if (seconds >= total) {
      resetTimer(false, true);
    }
  }, [state.time, currentInfo.active]);

  const startCountdown = () => {
    stopTimer();
    startTimer();
  };

  const doneSection = (value) => {
    setState((prev) => ({
      ...prev,
      doneAt: getTimeNow(),
    }));
    if (!isCoach) {
      streamContext.eventSideBar.sendSignalUserChanged({
        type: TypesSignal.doneSection,
        section: { ...section },
        value,
        userName: userSession.name,
      });
    }
  };

  useEffect(() => {
    if (!playingRecordings) {
      clearInterval(timer.current);
    } else if (playingRecordings && currentInfo.active) {
      startTimer();
    }
  }, [playingRecordings]); //eslint-disable-line

  useEffect(() => {
    if (!playingCompetition) {
      clearInterval(timer.current);
    } else if (playingCompetition) {
      startTimer();
    }
  }, [playingCompetition]); //eslint-disable-line

  const setCurrentTimer = (totalTime, dateNow) => {
    const dif = Date.now() - dateNow;
    setState((prev) => ({
      ...prev,
      start: Date.now() - (totalTime + 500 + dif),
      time: totalTime + 500 + dif,
    }));
    if (Number(totalTime) > 0) {
      startTimer();
    }
  };

  useEffect(() => {
    resetTimer();
    if (live && streamContext) {
      streamContext.setEventSideBar((prev) => ({
        ...prev,
        startTimer: startCountdown,
        resetTimer,
        setCurrentTimer,
      }));
    }
  }, [section]); //eslint-disable-line

  useEffect(() => {
    if (signalStream.type === TypesSignal.setTimeValue) {
      streamContext.eventSideBar.sendSignalUserChanged({
        ...signalStream,
        sectionId: section.id,
        totalTime: state.time,
      });
    }
  }, [signalStream]);

  const getDisplayValue = () => {
    if (
      configTimer.count === 'UP' &&
      currentInfo.active !== 'rest' &&
      currentInfo.active !== 'countdown'
    ) {
      return Math.round(currentInfo.time);
    }
    if (currentInfo.active === 'work') {
      return Math.round(configTimer.workTime - currentInfo.time);
    }
    if (currentInfo.active === 'rest') {
      return Math.round(configTimer.restTime - currentInfo.time);
    }
    if (currentInfo.active === 'countdown') {
      return Math.round(configTimer.countdownTime - currentInfo.time);
    }
    return 0;
  };

  return {
    timeValue: { ...currentInfo, timeDisplay: getDisplayValue(), start: state.start },
    startTimer: startCountdown,
    stopTimer,
    resetTimer,
    doneSection,
    workToSeconds: configTimer.workTime,
    restToSeconds: configTimer.restTime,
    showTimer: true,
  };
};

export default useNewTimer;
