import React, { useEffect, useContext } from 'react';
import { OpenVidu } from 'openvidu-browser';
import { useSelector, useDispatch } from 'react-redux';
import { roomSelectors, roomActions } from 'modules/room';
import { sessionWorkoutSelectors, sessionWorkoutActions } from 'modules/sessionWorkout';
import notifications from 'modules/notifications';
import { sessionSelectors } from 'modules/session';
import { time } from 'core/utils/formatters';
import { workoutsActions, workoutsSelectors } from 'modules/workouts';
import { playerRequest } from 'core/spotify/requests';
import { usersSelectors, usersActions } from 'modules/users';
import { StreamContext } from 'pages/Stream';
import { ComponentsSideBar } from 'components/Stream/SideBar/components';
import { useTranslation } from 'react-i18next';
import { ENV } from 'core/env';
import { workoutEvent } from 'core/utils/consts';

export const TypesSignal = {
  join: 'join',
  setTimer: 'SetTimer',
  startTimer: 'StartTimer',
  resetTimer: 'ResetTimer',
  setTimeValue: 'SetTimeValue',
  setMicrophoneStatus: 'setMicrophoneStatus',
  muteUser: 'muteUser',
  hideCamUser: 'hideCamUser',
  doneSection: 'doneSection',
  scoreSaved: 'scoreSaved',
  trackRound: 'trackRound',
  refreshStatusRecording: 'refreshStatusRecording',
  refreshSections: 'refreshSections',
  stopShareScreen: 'stopShareScreen',
  setFocusedUser: 'setFocusedUser',

  setPlayingRecording: 'setPlayingRecording',
  setSeekRecordingVideo: 'setSeekRecordingVideo',
};

let OV = new OpenVidu();
OV.setAdvancedConfiguration({
  publisherSpeakingEventsOptions: {
    interval: 100,
    threshold: -50,
  },
});

function useOpenVidu() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const streamContext = useContext(StreamContext);
  const token = useSelector((state) => sessionWorkoutSelectors.getToken(state));
  const access = useSelector((state) => sessionWorkoutSelectors.getAccess(state));
  const workout = useSelector((state) => workoutsSelectors.getWorkout(state));
  const workoutUsers = useSelector((state) => workoutsSelectors.getWorkoutUsers(state));
  const workoutCoaches = useSelector((state) => workoutsSelectors.getWorkoutCoaches(state));
  const userSession = useSelector((state) => sessionSelectors.getUser(state));
  const user = useSelector((state) => roomSelectors.getUser(state));
  const session = useSelector((state) => roomSelectors.getSession(state));
  const participants = useSelector((state) => roomSelectors.getParticipants(state));
  const workoutSections = useSelector((state) => workoutsSelectors.getWorkoutSections(state));

  const messages = useSelector((state) => roomSelectors.getMessages(state));
  const config = useSelector((state) => roomSelectors.getConfig(state));
  const error = useSelector((state) => roomSelectors.getError(state));
  const spotifyData = useSelector((state) => usersSelectors.getSpotifyData(state));
  const spotifyPlayer = useSelector((state) => usersSelectors.getSpotifyPlayer(state));

  const accessRef = React.useRef(access);
  const participantsRef = React.useRef(participants);
  const userRef = React.useRef(user);
  const sessionRef = React.useRef(session);
  const messagesRef = React.useRef(messages);
  const workoutRef = React.useRef(workout);
  const workoutUsersRef = React.useRef(workoutUsers);
  const workoutCoachesRef = React.useRef(workoutCoaches);
  const configRef = React.useRef(config);
  const spotifyDataRef = React.useRef(spotifyData);
  const workoutSectionsRef = React.useRef(workoutSections);
  const spotifyPlayerRef = React.useRef(spotifyPlayer);
  const streamContextRef = React.useRef(streamContext);

  React.useEffect(() => {
    accessRef.current = access;
    participantsRef.current = participants;
    sessionRef.current = session;
    messagesRef.current = messages;
    userRef.current = user;
    workoutRef.current = workout;
    workoutUsersRef.current = workoutUsers;
    workoutCoachesRef.current = workoutCoaches;
    configRef.current = config;
    spotifyDataRef.current = spotifyData;
    spotifyPlayerRef.current = spotifyPlayer;
    streamContextRef.current = streamContext;
    workoutSectionsRef.current = workoutSections;
  });

  useEffect(() => {
    if (workout.id) {
      dispatch(roomActions.setMessages(workout.messages));
    }
  }, [workout, dispatch]);

  const sendSignalUserChanged = (data) => {
    if (!workoutRef.current.live) {
      return;
    }
    try {
      if (sessionRef.current) {
        const signalOptions = {
          data: JSON.stringify(data),
          type: 'userChanged',
        };
        sessionRef.current.signal(signalOptions);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getRecordingStatus = () => {
    if (
      (workoutRef.current.recorded && workoutRef.current.live) ||
      workoutRef.current.method === 'video'
    ) {
      return;
    }
    dispatch(
      sessionWorkoutActions.getRecordingStatusStart({
        workoutId: workoutRef.current.id,
        workoutsInviteId: streamContext.inviteId,
      }),
    );
  };

  const getUserInfo = (id) => {
    let usrInfo = workoutUsersRef.current.find((x) => x.user.id === id);
    usrInfo = usrInfo ? usrInfo.user : null;

    if (!usrInfo) {
      usrInfo = workoutCoachesRef.current.find((x) => x.id === id);
    }
    if (id === workoutRef.current.user.id) {
      return workoutRef.current.user;
    }

    return usrInfo || {};
  };

  const sendDevicesStatus = () => {
    sendSignalUserChanged({
      isAudioActive: userRef.current.audioActive,
      isVideoActive: userRef.current.videoActive,
      userId: userRef.current.id,
      userName: userRef.current.name,
      isScreenShareActive: false,
    });
  };

  const newUserConnection = ({ userId, userName, connectionId, streamManager }) => {
    const newUser = {};

    newUser.id = userId;
    newUser.name = userName;
    newUser.connectionId = connectionId;
    newUser.streamManager = streamManager;
    newUser.videoActive = true;
    const usrInfo = getUserInfo(newUser.id);

    dispatch(roomActions.addParticipant({ ...newUser, ...usrInfo }));

    if (userRef.current) {
      setTimeout(() => {
        sendDevicesStatus();

        if (streamContextRef.current.eventSideBar.currentSection) {
          dispatch(
            roomActions.sendSignalStream({
              type: TypesSignal.setTimeValue,
              userId: newUser.id,
            }),
          );
        }

        dispatch(
          notifications.success(`${newUser.name} ${t('workout.stream.hasJoinedTheWorkout')}`),
        );
      }, 1000);

      setTimeout(() => {
        sendDevicesStatus();
      }, 3000);
    }
  };

  const subscribeToStreamCreated = () => {
    if (!sessionRef.current) {
      return;
    }

    sessionRef.current.on('streamCreated', (event) => {
      const subscriber = sessionRef.current.subscribe(event.stream, undefined);
      const data = event.stream.connection.data.split('%')[0];
      const { userId, userName } = JSON.parse(data);

      if (participantsRef.current.find((x) => Number(x.id) === Number(userId))) {
        const remoteUsers = [...participantsRef.current].map((param) => {
          const item = { ...param };
          if (item && item.id === userId) {
            item.streamManager = subscriber;
            item.connectionId = event.stream.connection.connectionId;
            item.type = 'remote';
          }
          return item;
        });
        dispatch(roomActions.setParticipants(remoteUsers));
      } else {
        newUserConnection({
          userId,
          userName,
          connectionId: event.stream.connection.connectionId,
          streamManager: subscriber,
        });
      }

      if (workoutRef.current.isCoach && configRef.current.muteUsersOnEntry) {
        sendSignalUserChanged({ type: TypesSignal.muteUser, id: userId });
      }
    });
  };

  const deleteSubscriber = (userId) => {
    dispatch(roomActions.leaveParticipant(userId));
  };

  const subscribeToStreamDestroyed = () => {
    sessionRef.current.on('streamDestroyed', (event) => {
      const u = JSON.parse(event.stream.connection.data);
      deleteSubscriber(u.userId);
      event.preventDefault();
    });
  };

  const setTimerSection = (sectionId = null, callback) => {
    if (sectionId) {
      dispatch(
        workoutsActions.getWorkoutSectionsStart(workoutRef.current.id, () => {
          const sec = workoutSectionsRef.current.find((x) => x.id === sectionId);
          streamContextRef.current.eventSideBar.setCurrentSection(sec, false);
          if (callback) {
            callback();
          }
        }),
      );
    } else {
      streamContextRef.current.eventSideBar.setCurrentSection(null, false);
    }
  };

  const startTimerSection = () => {
    streamContextRef.current.eventSideBar.startTimer();
  };

  const signalStream = (signal, event) => {
    if (signal.type === TypesSignal.join && signal.userId !== userRef.current.id) {
      newUserConnection(signal);
      return;
    }

    if (signal.type === TypesSignal.setFocusedUser && signal.userId !== userRef.current.id) {
      dispatch(roomActions.setFocusedUserId(signal.focusedUserId));
      return;
    }

    if (signal.type === TypesSignal.setPlayingRecording && signal.userId !== userRef.current.id) {
      dispatch(roomActions.setPlayRecordings(signal.value));
      return;
    }

    if (signal.type === TypesSignal.setSeekRecordingVideo && signal.userId !== userRef.current.id) {
      dispatch(roomActions.setSeekVideo(signal.value));
      return;
    }

    if (signal.muteAll === true && !workoutRef.current.isCoach) {
      userRef.current.streamManager.publishAudio(false);
      dispatch(
        roomActions.setUser({
          ...userRef.current,
          audioActive: false,
        }),
      );
      sendSignalUserChanged({ isAudioActive: false });
      return;
    }

    if (signal.type === TypesSignal.setMicrophoneStatus) {
      if (signal.value === true && !streamContextRef.current.controlPermission.changeAudio) {
        return;
      }

      userRef.current.streamManager.publishAudio(signal.value);
      dispatch(
        roomActions.setUser({
          ...userRef.current,
          audioActive: signal.value,
        }),
      );
      sendSignalUserChanged({ isAudioActive: signal.value });
      return;
    }

    if (signal.type === TypesSignal.setTimer && signal.userId !== userRef.current.id) {
      setTimerSection(signal.sectionId);
      return;
    }

    if (signal.type === TypesSignal.startTimer && signal.userId !== userRef.current.id) {
      startTimerSection();
      return;
    }

    if (signal.type === TypesSignal.resetTimer && signal.userId !== userRef.current.id) {
      streamContextRef.current.eventSideBar.resetTimer();
      return;
    }

    if (signal.type === TypesSignal.refreshStatusRecording && workoutRef.current.isCoach) {
      getRecordingStatus();
      return;
    }

    if (signal.type === TypesSignal.refreshSections) {
      dispatch(workoutsActions.getWorkoutSectionsStart(workoutRef.current.id));
      return;
    }

    if (signal.type === TypesSignal.setTimeValue && userRef.current.id === signal.userId) {
      if (streamContextRef.current.eventSideBar.currentSection) {
        if (streamContextRef.current.eventSideBar.currentSection.id === signal.sectionId) {
          return;
        }
      }
      const dateNow = Date.now();
      setTimerSection(signal.sectionId, () => {
        setTimeout(() => {
          streamContextRef.current.eventSideBar.setCurrentTimer(signal.totalTime, dateNow);
        }, 100);
      });
      return;
    }

    if (signal.type === TypesSignal.muteUser) {
      if (userRef.current.id === signal.id) {
        userRef.current.streamManager.publishAudio(false);
        dispatch(
          roomActions.setUser({
            ...userRef.current,
            audioActive: false,
          }),
        );
        sendSignalUserChanged({ isAudioActive: false });
      }
      return;
    }

    if (signal.type === TypesSignal.hideCamUser) {
      if (userRef.current.id === signal.id) {
        userRef.current.streamManager.publishVideo(false);
        dispatch(
          roomActions.setUser({
            ...userRef.current,
            videoActive: false,
          }),
        );
        sendSignalUserChanged({ isVideoActive: false });
      }
      return;
    }

    if (signal.type === TypesSignal.doneSection && workoutRef.current.isCoach === true) {
      const msg = `${signal.userName} ${t('workout.stream.hasCompletedThe')} ${
        signal.section.title
      } ${t('workout.stream.section')} ${t('workout.stream.withATimeOf')} ${signal.value}`;
      dispatch(notifications.success(msg));
      return;
    }

    if (
      (signal.type === TypesSignal.scoreSaved || signal.type === TypesSignal.trackRound) &&
      workoutRef.current.isCoach === true
    ) {
      setTimeout(() => {
        dispatch(workoutsActions.getWorkoutSectionsStart(workoutRef.current.id));
      }, 1000);
    }

    if (signal.type === TypesSignal.scoreSaved && workoutRef.current.isCoach === true) {
      let msg = `${signal.userName} ${t('workout.stream.postedScoreFor')} ${signal.section.title}.`;
      if (signal.data.time) {
        msg += ` ${t('workout.section.label.score.time')} ${signal.data.time} `;
      }
      if (signal.data.rounds) {
        msg += ` ${t('workout.section.label.score.rounds')} ${signal.data.rounds} `;
      }
      if (signal.data.roundRepsRounds) {
        msg += ` ${t('workout.section.label.score.round_reps')} ${signal.data.roundRepsRounds}+${
          signal.data.roundRepsReps
        } `;
      }
      if (signal.data.reps) {
        msg += ` ${t('workout.section.label.score.reps')} ${signal.data.reps} `;
      }
      if (signal.data.weight) {
        msg += ` ${t('workout.section.label.score.weight')} ${signal.data.weight} ${
          signal.data.typeWeight
        }`;
      }
      if (signal.data.calories) {
        msg += ` ${t('workout.section.label.score.calories')} ${signal.data.calories} `;
      }
      if (signal.data.distance) {
        msg += ` ${t('workout.section.label.score.distance')} ${signal.data.distance} ${
          signal.data.typeDistance
        }`;
      }

      dispatch(notifications.success(msg));
      return;
    }

    [...participantsRef.current].forEach((param) => {
      const item = param;
      if (item.connectionId === event.from.connectionId) {
        const data = JSON.parse(event.data);
        if (data.isAudioActive !== undefined) {
          dispatch(
            roomActions.setParticipantDeviceStatus(
              item.connectionId,
              'audioActive',
              !!data.isAudioActive,
            ),
          );
        }
        if (data.isVideoActive !== undefined) {
          dispatch(
            roomActions.setParticipantDeviceStatus(
              item.connectionId,
              'videoActive',
              !!data.isVideoActive,
            ),
          );
        }
      }
    });
  };

  const subscribeToUserChanged = () => {
    sessionRef.current.on('signal:userChanged', (event) => {
      const signal = JSON.parse(event.data);
      signalStream(signal, event);
    });
  };

  const subscribeNetworkQuality = () => {
    sessionRef.current.on('networkQualityLevelChanged', (event) => {
      if (event.connection.connectionId === sessionRef.current.connection.connectionId) {
        dispatch(roomActions.setConnectionQuality(event));
      }
    });
  };

  const getParticipant = (id) => {
    let u = workoutUsersRef.current.find((x) => x.user.id === id);
    if (!u) {
      u = workoutCoachesRef.current.find((x) => x.id === id);
      return u;
    }
    return u.user;
  };

  const changeDevice = async (deviceId = undefined, kind) => {
    if (!userRef.current.streamManager) {
      return;
    }
    if (kind === 'audio') {
      const constraints = { audio: { deviceId: { exact: deviceId } } };
      navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
        userRef.current.streamManager
          .replaceTrack(stream.getAudioTracks()[0])
          .then(() => {
            sessionRef.current.unpublish(userRef.current.streamManager);
            sessionRef.current.publish(userRef.current.streamManager);
          })
          .catch((ex) => console.error('Error replacing track', ex));
      });
    } else {
      const constraints = { video: { deviceId: { exact: deviceId } } };
      navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
        userRef.current.streamManager
          .replaceTrack(stream.getVideoTracks()[0])
          .then()
          .catch((ex) => console.error('Error replacing track', ex));
      });
    }
  };

  const muteUnmuteUser = (flag) => {
    userRef.current.streamManager.publishAudio(flag);
    dispatch(
      roomActions.setUser({
        ...userRef.current,
        audioActive: flag,
      }),
    );
  };

  const subscribeToMessage = () => {
    sessionRef.current.on('signal:chat', (event) => {
      const data = JSON.parse(event.data);
      const usr = getParticipant(data.userId) || userRef.current;

      const msg = {
        connectionId: event.from.connectionId,
        from: usr,
        audio: data.audio,
        hour: time(new Date()),
        createdAt: new Date(),
        message: data.message,
        to: { id: data.toId },
        unread: data.toId === userRef.current.id,
      };

      if (data.toId === userRef.current.id && msg.audio && msg.audio.url) {
        const isActive = userRef.current.audioActive;
        muteUnmuteUser(false);
        const p = new Audio(`${msg.audio.url}`);
        p.play();
        p.onended = () => {
          if (isActive) {
            muteUnmuteUser(true);
          }
        };
      }
      dispatch(roomActions.addMessage(msg));

      if (
        streamContextRef.current.openDrawer &&
        streamContextRef.current.componentSideBar === ComponentsSideBar.Chat
      ) {
        streamContextRef.current.setUnreadMessages(0);
      } else if (usr.id !== userRef.current.id) {
        streamContextRef.current.setUnreadMessages((prev) => prev + 1);
      }
    });
  };

  const subscribeToSpeech = () => {
    try {
      sessionRef.current.on('publisherStartSpeaking', (e) => {
        const data = JSON.parse(e.connection.data);
        if (
          spotifyDataRef.current &&
          data.isCoach === true &&
          configRef.current.musicVolumeControl
        ) {
          dispatch(usersActions.setSpotifyPlayer({ volume: 3 }));
          playerRequest(
            spotifyDataRef.current.accessToken,
            'setVolume',
            { vol: 3 },
            spotifyPlayerRef.current.id,
          );
        }
      });

      sessionRef.current.on('publisherStopSpeaking', (e) => {
        const data = JSON.parse(e.connection.data);
        if (
          spotifyDataRef.current &&
          data.isCoach === true &&
          configRef.current.musicVolumeControl
        ) {
          dispatch(
            usersActions.setSpotifyPlayer({ volume: spotifyPlayerRef.current.volumeUser || 100 }),
          );
          playerRequest(
            spotifyDataRef.current.accessToken,
            'setVolume',
            { vol: spotifyPlayerRef.current.volumeUser || 100 },
            spotifyPlayerRef.current.id,
          );
        }
      });
    } catch (ex) {
      setTimeout(() => {
        subscribeToSpeech();
      }, 2000);
    }
  };

  const initPublisher = (publisher) => {
    const usrInfo = getUserInfo(userSession.id);
    const newUser = {
      ...usrInfo,
      connectionId: sessionRef.current.connection.connectionId,
      streamManager: publisher,
      audioActive: configRef.current.audioActive,
      videoActive: configRef.current.videoActive,
    };

    if (sessionRef.current.capabilities.publish) {
      sendSignalUserChanged({ type: TypesSignal.join, userId: newUser.id, userName: newUser.name });
      sessionRef.current.publish(publisher);
    }

    dispatch(
      sessionWorkoutActions.postAccessStart({
        workoutId: workoutRef.current.id,
        connectionId: session.connection.connectionId,
      }),
    );

    dispatch(roomActions.setUser(newUser));
    streamContext.setControlPermission((prev) => ({
      ...prev,
      changeAudio: configRef.current.audioActive,
      changeVideo: configRef.current.videoActive,
    }));

    dispatch(
      sessionWorkoutActions.postEventStart({
        workoutId: workoutRef.current.id,
        kind: workoutEvent.join,
      }),
    );

    subscribeToMessage();
    subscribeToSpeech();
    if (workoutRef.current.isCoach) {
      getRecordingStatus();
    }

    subscribeNetworkQuality();
    userRef.current.streamManager.publishAudio(configRef.current.audioActive);
    userRef.current.streamManager.publishVideo(configRef.current.videoActive);
  };

  const connectWebCam = () => {
    const publisher = OV.initPublisher(
      undefined,
      {
        audioSource:
          configRef.current.deviceAudio && configRef.current.deviceAudio.deviceId
            ? configRef.current.deviceAudio.deviceId
            : undefined,
        videoSource:
          configRef.current.deviceVideo && configRef.current.deviceVideo.deviceId
            ? configRef.current.deviceVideo.deviceId
            : undefined,
        publishAudio: configRef.current.audioActive,
        publishVideo: configRef.current.videoActive,
        resolution: '1280x720',
        // resolution: '1280x720',
        frameRate: 30,
        insertMode: 'APPEND',
        mirror: true,
      },
      (err) => {
        if (err) {
          dispatch(notifications.warning(err.message));
          // const loc = window.location;
          // window.location = loc;
        } else {
          initPublisher(publisher);
        }
      },
    );

    if (ENV !== 'development') {
      OV.enableProdMode();
    }
  };

  const connect = () => {
    if (!sessionRef.current) {
      return;
    }

    subscribeToStreamCreated();
    subscribeToStreamDestroyed();
    subscribeToUserChanged();

    sessionRef.current
      .connect(token.token, {
        userId: userSession.id,
        userName: userSession.name,
        isCoach: workoutRef.current.isCoach,
      })
      .then(() => {
        connectWebCam();
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          roomActions.setError({
            error: err.error,
            messgae: err.message,
            code: err.code,
            status: err.status,
          }),
        );
      });
  };

  const screenShare = () => {
    const stopShare = () => {
      if (userRef.current.streamManager) {
        sessionRef.current.unpublish(userRef.current.streamManager);
      }
      dispatch(
        roomActions.setUser({
          ...userRef.current,
          shareScreen: false,
          oldStreamManager: null,
          streamManager: userRef.current.oldStreamManager,
        }),
      );
      sendSignalUserChanged({
        type: TypesSignal.stopShareScreen,
        userId: userRef.current.id,
        userName: userRef.current.name,
      });
      sessionRef.current.publish(userRef.current.oldStreamManager);
    };

    if (userRef.current && userRef.current.shareScreen) {
      stopShare();
      return;
    }
    const videoSource = navigator.userAgent.indexOf('Firefox') !== -1 ? 'window' : 'screen';

    const publisher = OV.initPublisher(
      undefined,
      {
        audioSource:
          configRef.current.deviceAudio && configRef.current.deviceAudio.deviceId
            ? configRef.current.deviceAudio.deviceId
            : undefined,
        videoSource,
        publishAudio: userRef.current.audioActive,
        publishVideo: userRef.current.videoActive,
        mirror: false,
      },
      (errorShare) => {
        if (errorShare && errorShare.name === 'SCREEN_EXTENSION_NOT_INSTALLED') {
          alert('Extension not installed');
        } else if (errorShare && errorShare.name === 'SCREEN_SHARING_NOT_SUPPORTED') {
          alert('Your browser does not support screen sharing');
        } else if (errorShare && errorShare.name === 'SCREEN_EXTENSION_DISABLED') {
          alert('You need to enable screen sharing extension');
        } else if (errorShare && errorShare.name === 'SCREEN_CAPTURE_DENIED') {
          alert('You need to choose a window or application to share');
        }
      },
    );

    publisher.once('accessAllowed', () => {
      sessionRef.current.unpublish(userRef.current.streamManager);
      sessionRef.current.publish(publisher).then(() => {
        dispatch(
          roomActions.setUser({
            ...userRef.current,
            shareScreen: true,
            oldStreamManager: userRef.current.streamManager,
            streamManager: publisher,
          }),
        );

        publisher.stream
          .getMediaStream()
          .getVideoTracks()[0]
          .addEventListener('ended', () => {
            stopShare();
          });
      });
    });
  };

  const leaveSession = (redirect = false) => {
    if (sessionRef.current) {
      if (accessRef.current.id && sessionRef.current.connection) {
        dispatch(
          sessionWorkoutActions.postAccessDisconnectStart({
            accessId: accessRef.current.id,
            workoutId: workoutRef.current.id,
            connectionId: sessionRef.current.connection.connectionId,
          }),
        );
      }

      sessionRef.current.disconnect();
      dispatch(sessionWorkoutActions.clearToken());
      if (streamContextRef.current.eventSideBar.resetTimer) {
        streamContextRef.current.eventSideBar.resetTimer();
      }
    }
    dispatch(roomActions.clear());
    OV = null;
    if (redirect === true) {
      window.location = `/workout/view/${workoutRef.current.slug}?rating=true`;
    }
  };

  useEffect(() => {
    OV = new OpenVidu();
    dispatch(roomActions.setSession(OV.initSession()));
  }, [dispatch]); //eslint-disable-line

  useEffect(() => {
    if (token) {
      connect();
    }
  }, [token]); //eslint-disable-line

  useEffect(
    () => () => {
      leaveSession();
    }, []); //eslint-disable-line

  useEffect(() => {
    if (error) {
      notifications.error(error.message);
    }
  }, [error]);

  return {
    leaveSession,
    sendSignalUserChanged,
    OV,
    screenShare,
    changeDevice,
    isCoach: workoutRef.current.isCoach,
    workoutId: workoutRef.current.id,
  };
}

export default useOpenVidu;
