import React, { useContext, useEffect, useRef } from 'react';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import useSelectors from 'modules/map/useSelectors';
import { automationTypesModule } from 'modules/automationTypes';
import { makeStyles } from '@material-ui/core';
import { secondaryXLight } from 'core/ui/Colors';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import { WorkflowContext } from 'contexts';
import WorkflowTypeFilters from './Filters';
import WorkflowTypeForms from './Forms';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    backgroundColor: 'white',
    padding: 10,
  },
  cardType: {
    border: `1px solid ${secondaryXLight}`,
  },
  cardTypeIcon: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    width: 40,
  },
}));

export default ({ automationType, changeType }) => {
  const shouldFillName = useRef(true);

  const classes = useStyles();
  const { t } = useTranslation();
  const triggers = useSelectors(automationTypesModule, 'triggers');
  const actions = useSelectors(automationTypesModule, 'actions');
  const workFlowContext = useContext(WorkflowContext);

  const data = automationType?.kind === 'trigger' ? triggers : actions;

  const name = workFlowContext.formType?.watch('name');

  useEffect(() => {
    if (!name && shouldFillName.current) {
      workFlowContext.formType.setValue('name', automationType?.name);
    }

    shouldFillName.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [automationType?.name, name, workFlowContext.formType.setValue]);

  return (
    <Box className={classes.root}>
      <Box display="flex" flexDirection="column" className="gap-10">
        <Box>
          <SelectBoxObject
            label={t(`label.${automationType?.kind}.type`).toUpperCase()}
            options={data}
            value={automationType?.id}
            // eslint-disable-next-line eqeqeq
            setValue={(v) => changeType(data?.find((x) => x.id == v))}
            bordered
            style={{ height: 40 }}
          />
        </Box>
        <Box>
          <TextFieldRounded
            label={t(`label.${automationType?.kind}.name`).toUpperCase()}
            value={workFlowContext.formType.watch('name')}
            error={workFlowContext.errors?.name}
            onChange={(v) => workFlowContext.formType.setValue('name', v)}
            bordered
          />
        </Box>

        <div key={automationType?.id}>
          <WorkflowTypeFilters automationType={automationType} />

          <WorkflowTypeForms automationType={automationType} />
        </div>
      </Box>
    </Box>
  );
};
