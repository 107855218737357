/* eslint-disable react/prop-types */
import React from 'react';
import Box from 'core/ui/Box';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import Checkbox from 'core/ui/Checkbox';
import Grid from 'core/ui/Grid';
import S3UploadAttachments from 'components/Commmunication/Form/S3UploadAttachments';
import Toggle from 'core/ui/Toggle';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import { useTranslation } from 'react-i18next';
import CKEditor, { defaultCkEditorText } from 'core/ui/CKEditor';

export const objectableTypeTypes = [
  { label: 'All', value: null },
  { label: 'Competitors Registration', value: 'CompetitionsUser' },
  { label: 'Volunteers Registration', value: 'CompetitionsVolunteersCategory' },
  { label: 'Vendors Registration', value: 'CompetitionsVendorsCategory' },
  { label: 'Invites', value: 'CompetitionsInvite' },
];

export default ({ competition, textService }) => {
  const { t } = useTranslation();

  const keys = ['{first_name}', '{competition_name}', '{competition_view_link}'];

  const selectBoxCategoryOptions =
    textService.watch('objectableType') === 'CompetitionsVolunteersCategory'
      ? competition?.volunteersCategories
      : competition?.vendorsCategories;

  const defaultCkText = () => {
    if (!textService?.watch('text')) {
      return defaultCkEditorText('');
    }

    const newRaw =
      typeof textService?.watch('textRaw') === 'string'
        ? JSON.parse(textService?.watch('textRaw'))
        : textService?.watch('textRaw');

    return newRaw;
  };

  return (
    <Box px={3}>
      <>
        <Box mb={3} display="flex" alignItems="flex-end">
          <Box flexGrow={1}>
            <TextFieldRounded
              label="Subject"
              error={textService.errors && textService.errors.subject}
              value={textService.watch('subject') || ''}
              onChange={(v) => textService.setValue('subject', v)}
              bordered
            />
          </Box>

          {textService.watch('method') === 'email' && (
            <Box ml={3} pb={2}>
              <Checkbox
                checked={textService.watch('copyMe')}
                onClick={() => textService.setValue('copyMe', !textService.watch('copyMe'))}
                label="Copy Me"
              />
            </Box>
          )}
        </Box>
      </>

      <Box mb={3}>
        {/* <TextAreaFieldRounded
          label="Message"
          error={textService.errors && textService.errors.text}
          value={textService.watch('text') || ''}
          onChange={(v) => textService.setValue('text', v)}
          maxLength={textService.watch('method') === 'sms' ? 150 : 10000}
          bordered
          resize="vertical"
        /> */}

        <CKEditor
          label={t('label.message')}
          error={textService.errors && textService.errors.text}
          value={textService.watch('text')}
          onChange={(v) => {
            textService.setValue('text', v?.html);
            textService.setValue('textRaw', v?.raw);
          }}
          defaultValue={defaultCkText()}
          bordered
          keywordOptions={keys}
          allowUploads
          pathUpload={`Competition/${competition?.id}/Messages`}
        />
      </Box>

      <>
        <Box mb={3}>
          <TextFieldRounded
            label="Video Link"
            error={textService.errors && textService.errors.videoLink}
            value={textService.watch('videoLink') || ''}
            onChange={(v) => textService.setValue('videoLink', v)}
            bordered
          />
        </Box>
      </>

      <>
        <Box mb={3} display="flex">
          <Box mr={5}>
            <TextFieldRounded
              label="Button Text"
              error={textService.errors && textService.errors.actionText}
              value={textService.watch('actionText') || ''}
              onChange={(v) => textService.setValue('actionText', v)}
              bordered
            />
          </Box>
          <TextFieldRounded
            label="Button Link"
            error={textService.errors && textService.errors.actionLink}
            value={textService.watch('actionLink') || ''}
            onChange={(v) => textService.setValue('actionLink', v)}
            bordered
          />
        </Box>
      </>

      <Grid container>
        <Grid item xs={12} md={6}>
          <Box>
            <Toggle
              options={objectableTypeTypes}
              color="white"
              value={textService.watch('objectableType')}
              onChange={(v) => textService.setValue('objectableType', v)}
            />

            {(textService.watch('objectableType') === 'CompetitionsVolunteersCategory' ||
              textService.watch('objectableType') === 'CompetitionsVendorsCategory') && (
              <Box mt={3}>
                <SelectBoxObject
                  label={
                    textService.watch('objectableType') === 'CompetitionsVolunteersCategory'
                      ? t('competition.label.volunteersCategories')
                      : t('competition.label.vendorsCategories')
                  }
                  options={selectBoxCategoryOptions}
                  value={textService.watch('objectableId')}
                  setValue={(v) => {
                    textService.setValue('objectableId', v);
                    textService.setValue(
                      'objectable',
                      // eslint-disable-next-line eqeqeq
                      selectBoxCategoryOptions.find((x) => x.id == v),
                    );
                  }}
                  emptyItem
                  emptyValue={null}
                  placeholder="All"
                />
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>
            <S3UploadAttachments
              setFiles={textService.setAttachments}
              files={textService.attachments}
              objectId={competition?.id}
              objectType="Competition"
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
