import React from 'react';
import { CircularProgress } from '@material-ui/core';
import Box from '../Box';

const Backdrop = ({ loading, onClick }) => (
  <Box
    style={{
      zIndex: 9999,
      position: 'fixed',
      right: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'rgba(235, 238, 242, 0.5)',
    }}
    open={loading}
    color="primary"
    onClick={onClick}
  >
    <CircularProgress color="primary" />
  </Box>
);

export default Backdrop;
