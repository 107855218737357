import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, makeStyles } from '@material-ui/core';
import { LayoutContext } from 'pages/shared/Layout';
import CompetitionsSearch from 'components/CompetitionsSearch';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#f3f3f3',
    minHeight: '100vh',
  },
}));

export default () => {
  const { t } = useTranslation();
  const layoutContext = useContext(LayoutContext);
  const classes = useStyles();

  useEffect(() => {
    layoutContext.setTitleBar(t('title.competitions').toUpperCase());
    layoutContext.setActiveMenu('competitions');
  }, [layoutContext, t]);

  return (
    <div className={classes.root}>
      <Container maxWidth="xl">
        <CompetitionsSearch />
      </Container>
    </div>
  );
};
