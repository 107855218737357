import { useEffect } from 'react';
import { useForm } from 'react-hook-form-v5';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import notifications from 'modules/notifications';
import { useDispatch } from 'react-redux';
import useSelectors from 'modules/map/useSelectors';
import { personalRecordMovementsModule } from 'modules/personalRecordMovements';

export default ({ callback, personalRecord }) => {
  const { request } = useActions();
  const dispatch = useDispatch();
  const loading = useSelectors(personalRecordMovementsModule, 'loading');
  const { register, handleSubmit, setValue, watch, reset, errors } = useForm();

  const validate = () => {
    const errorsValidation = [];

    if (errorsValidation.length > 0) {
      dispatch(notifications.warning(errorsValidation.join(' ')));
      return false;
    }
    return true;
  };

  const onSubmit = (values) => {
    if (!validate()) {
      return;
    }

    const mov = values.movement[0];
    const data = {
      ...values,
      movement: mov.name || mov,
      id: personalRecord && personalRecord.id,
    };

    request({
      action: data.id
        ? Modules.personalRecordMovements.actions.putPersonalRecord
        : Modules.personalRecordMovements.actions.postPersonalRecord,
      data,
      options: {
        onSuccess: () => {
          request({
            action: Modules.personalRecordMovements.actions.getPrMovements,
            data: { isSelector: true },
          });
          if (callback) {
            callback();
          }
        },
      },
    });
  };

  useEffect(() => {
    register('movement', { required: true });
    register('videoLink');
    register('scoreType', { required: true });
    register('active');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register]);

  useEffect(() => {
    if (personalRecord && personalRecord.id) {
      const bk = { ...personalRecord };
      delete bk.movement;

      reset(bk);
      setTimeout(() => {
        if (personalRecord.movement) {
          setValue('movement', [personalRecord.movement.name]);
        }
      }, 500);
    } else {
      setValue('active', true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personalRecord]);

  return {
    register,
    setValue,
    watch,
    reset,
    errors,
    onSubmit,
    handleSubmit,
    submitForm: handleSubmit(onSubmit),

    loading,
  };
};
