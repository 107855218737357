/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import Box from 'core/ui/Box';
import { useSelector } from 'react-redux';
import useActions from 'modules/map/useActions';
import useSelectors from 'modules/map/useSelectors';
import { newChannelMembersModule } from 'modules/channelMembers/new';
import Modules from 'modules';
import { sessionSelectors } from 'modules/session';
import Plans from './Plans';

const ChannelMembershipPlans = ({
  channel,
  hideCurrent,
  embed,
  plansBlock,
  purchasedPlanCallback,
}) => {
  const currentUser = useSelector((state) => sessionSelectors.getUser(state));
  const { request } = useActions();

  const memberships = useSelectors(newChannelMembersModule, 'memberships');

  const getMemberships = () => {
    request({
      action: Modules.newChannelMembers.actions.getMemberships,
      data: {
        channelId: channel?.id,
        userId: currentUser?.id,
      },
    });
  };

  useEffect(() => {
    if (channel?.id && currentUser?.id) {
      getMemberships();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel?.id, currentUser?.id]);

  return (
    <Box>
      <Plans
        channel={channel}
        embed={embed}
        hideCurrent={hideCurrent}
        plansBlock={plansBlock}
        purchasedPlanCallback={purchasedPlanCallback}
        membershipsActiveKeys={memberships?.activeKeys}
        getMemberships={getMemberships}
      />
    </Box>
  );
};

export default ChannelMembershipPlans;
