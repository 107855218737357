import { channelsActions } from 'modules/channels';
import notifications from 'modules/notifications';
import { paymentsActions, paymentsSelectors } from 'modules/payments';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

export default ({ channel }) => {
  const loadingPurchaseChannelPlan = useSelector((state) =>
    paymentsSelectors.getLoadingPurchaseChannelPlan(state),
  );
  const dispatch = useDispatch();
  const [openPayment, setOpenPayment] = useState({ open: false });
  const { t } = useTranslation();
  const [annualRenew, setAnnualRenew] = useState(false);

  const initialPlanStateRef = useRef([]);
  const [plans, setPlans] = useState([]);

  const checkPlanState = () => {
    if (initialPlanStateRef.current.length > 0 && plans.length === 0) {
      return 'cancel';
    }
    if (
      initialPlanStateRef.current.length === 0 &&
      plans.length > 0 &&
      !plans.find((x) => !x.new)
    ) {
      return 'purchase';
    }
    if (
      plans.find((x) => !initialPlanStateRef.current.find((y) => y === x.plan)) ||
      initialPlanStateRef.current.find((x) => !plans.find((y) => x === y.plan))
    ) {
      return 'change';
    }

    if (channel.annualRenewal !== annualRenew) {
      return 'change';
    }

    return null;
  };

  useEffect(() => {
    if (channel) {
      const options = [];

      if (channel?.activePlans?.online?.active && channel?.activePlans?.online?.renew) {
        options.push('online');
      }
      if (channel?.activePlans?.inPerson?.active && channel?.activePlans?.inPerson?.renew) {
        options.push('in_person');
      }
      if (channel?.activePlans?.memberApp?.active && channel?.activePlans?.memberApp?.renew) {
        options.push('member_app');
      }
      if (channel?.activePlans?.sifely?.active && channel?.activePlans?.sifely?.renew) {
        options.push('sifely');
      }
      if (channel?.activePlans?.competition?.active && channel?.activePlans?.competition?.renew) {
        options.push('competition');
      }
      if (channel?.activePlans?.engage?.active && channel?.activePlans?.engage?.renew) {
        options.push('engage');
      }

      initialPlanStateRef.current = options;
      setPlans(options.map((x) => ({ plan: x, new: false })));
      setAnnualRenew(channel.annualRenewal);
    }
  }, [channel]);

  const togglePlan = (item) => {
    if (plans.find((x) => x.plan === item)) {
      setPlans((prev) => prev.filter((x) => x.plan !== item));
    } else {
      setPlans((prev) => [
        ...prev,
        { plan: item, new: !initialPlanStateRef.current.find((x) => x === item) },
      ]);
    }
  };

  const checkPlanNextState = (item) => {
    if (
      initialPlanStateRef.current.find((x) => x === item) &&
      !plans.find((x) => x.plan === item)
    ) {
      return 'cancel';
    }
    if (
      !initialPlanStateRef.current.find((x) => x === item) &&
      plans.find((x) => x.plan === item)
    ) {
      return 'add';
    }

    return null;
  };

  const buyPlan = (engagePlan = null) => {
    if (!channel.defaultPaymentMethod && checkPlanState() !== 'cancel') {
      dispatch(notifications.warning(t('channel.message.addAPaymentMethod')));
      return;
    }
    const allPlans = [
      ...initialPlanStateRef.current.filter((x) => !plans.find((y) => y.plan === x)),
      ...plans?.map((x) => x.plan),
    ].map((item) => ({ plan: item, action: checkPlanNextState(item) }));

    setOpenPayment({
      open: true,
      allPlans: engagePlan ? [{ plan: 'engage', action: 'add' }] : allPlans,
    });
  };

  const renewPlan = () => {
    dispatch(
      paymentsActions.purchaseChannelPlanStart(
        {
          channelId: channel.id,
          renew: true,
        },
        () => {
          dispatch(channelsActions.getChannelStart(channel.id, 'edit'));
        },
      ),
    );
  };

  return {
    openPayment,
    setOpenPayment,

    plans,
    setPlans,

    buyPlan,
    renewPlan,

    loadingPurchaseChannelPlan,

    togglePlan,

    planState: checkPlanState(),
    annualRenew,
    setAnnualRenew,
  };
};
