import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import Typography from 'core/ui/Typography';
import { ButtonPrimary } from 'core/ui/Button';
import { useSelector, useDispatch } from 'react-redux';
import notifications from 'modules/notifications';
import LoaderSm from 'core/ui/LoaderSm';
import { competitionsActions, competitionsSelectors } from 'modules/competitions';
import { useHistory } from 'react-router';
import SelectorPaymentMethod, { PaymentMethodViewType } from 'components/Account/Selector';
import { sessionSelectors } from 'modules/session';
import { channelCurrency } from 'core/utils/formatters';
import Success from './Success';

export default function Payment({ setOpen }) {
  const { t } = useTranslation();
  const history = useHistory();
  const currentUser = useSelector((state) => sessionSelectors.getUser(state));

  const competition = useSelector((state) => competitionsSelectors.getCompetition(state));
  const loadingPayment = useSelector((state) => competitionsSelectors.getLoadingPayment(state));

  const [viewMethodPayment, setViewMethodPayment] = useState(PaymentMethodViewType.list);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);

  const [showSuccess, setShowSuccess] = useState(false);
  const dispatch = useDispatch();

  const validate = () => {
    if (!competition?.channel?.defaultPaymentMethod && !selectedPaymentMethod) {
      dispatch(notifications.warning(t('modalpurchase.validation.selectAccount')));
      return false;
    }
    return true;
  };

  const confirmPayment = () => {
    if (!validate()) {
      return;
    }

    dispatch(
      competitionsActions.paymentCompetitionStart({
        competitionId: competition.id,
        userPaymentMethodId: selectedPaymentMethod ? selectedPaymentMethod.id : null,
        callback: () => {
          setShowSuccess(true);
          dispatch(competitionsActions.getCompetitionStart(competition.id));
        },
      }),
    );
  };

  return (
    <Box>
      <Typography mt={3} mb={3} align="center" color="primary" variant="h4">
        {t(showSuccess ? 'competition.payment.success' : 'competition.modal.payment')}
      </Typography>
      <Box>
        {!showSuccess && !competition.paid && (
          <>
            <Box mx={5}>
              <Box display="flex" mt={2} justifyContent="center">
                <Typography style={{ fontSize: 40 }}>
                  {channelCurrency(competition.price, competition?.channel?.currency)}
                </Typography>
              </Box>
            </Box>
            <Box m={5}>
              {competition?.channel?.defaultPaymentMethod ? (
                <Box my={3} display="flex" justifyContent="center" alignItems="center">
                  <Typography variant="h6" align="center" className="roboto-regular">
                    <span style={{ textTransform: 'capitalize' }}>
                      <b>{competition?.channel?.defaultPaymentMethod?.cardFlag}</b>
                    </span>{' '}
                    <Typography
                      className="roboto-regular"
                      variant="h6"
                      component="span"
                      color="textSecondary"
                    >
                      {t('userPaymentMethod.endingIn')}{' '}
                      {competition?.channel?.defaultPaymentMethod?.lastFour}
                    </Typography>
                  </Typography>
                </Box>
              ) : (
                <SelectorPaymentMethod
                  selectedPaymentMethod={selectedPaymentMethod}
                  setSelectedPaymentMethod={setSelectedPaymentMethod}
                  view={viewMethodPayment}
                  setView={setViewMethodPayment}
                  userId={currentUser.id}
                  userInfo={{
                    name: currentUser?.name,
                    phone: currentUser?.phone,
                    email: currentUser?.email,
                  }}
                />
              )}
            </Box>

            {viewMethodPayment !== PaymentMethodViewType.form && (
              <Box display="flex" px={5} justifyContent="flex-end">
                <ButtonPrimary
                  disabled={loadingPayment}
                  onClick={() => {
                    setOpen(false);
                  }}
                  type="submit"
                  fullWidth
                  m={1}
                >
                  <Box display="flex" alignItems="center">
                    <LoaderSm loading={loadingPayment} width={20} />
                    <Typography ml={1} component="span">
                      {t('button.cancel')}
                    </Typography>
                  </Box>
                </ButtonPrimary>
                <ButtonPrimary
                  disabled={loadingPayment}
                  onClick={() => confirmPayment()}
                  type="submit"
                  fullWidth
                  m={1}
                >
                  <Box display="flex" alignItems="center">
                    <LoaderSm loading={loadingPayment} width={20} />
                    <Typography ml={1} component="span">
                      {t('button.pay')}
                    </Typography>
                  </Box>
                </ButtonPrimary>
              </Box>
            )}
          </>
        )}

        {showSuccess && (
          <>
            <Success />
            <ButtonPrimary
              disabled={loadingPayment}
              onClick={() => {
                setOpen(false);
                history.push(`/competition/view/${competition.slug}`);
              }}
              type="submit"
              fullWidth
              m={1}
              mt={5}
            >
              <Box display="flex" alignItems="center">
                <LoaderSm loading={loadingPayment} width={20} />
                <Typography ml={1} component="span">
                  {t('button.ok')}
                </Typography>
              </Box>
            </ButtonPrimary>
          </>
        )}
      </Box>
    </Box>
  );
}

Payment.propTypes = {
  setOpen: PropTypes.func,
};

Payment.defaultProps = {
  setOpen: () => {},
};
