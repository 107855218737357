import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from 'core/ui/Grid';

import { workoutsActions, workoutsSelectors } from 'modules/workouts';
import { useDispatch, useSelector } from 'react-redux';
import Typography from 'core/ui/Typography';
import Paper from 'core/ui/Paper';
import Box from 'core/ui/Box';
import { date, timeWithoutTz } from 'core/utils/formatters';
import LoaderSm from 'core/ui/LoaderSm';
import { ButtonPrimary } from 'core/ui/Button';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import Divider from 'core/ui/Divider';
import { Link } from '@material-ui/core';
import EqpMovList from 'components/Workout/Show/Tabs/Overview/EqpMovList';

import Video from 'core/ui/Video';
import ItemSection from 'components/Workout/Show/Tabs/Overview/Sections/ItemSection';
import { ShowContext } from '../../index';
import ButtonActions from './ButtonActions';
import WorkoutHeats from './Heats';
import TimerView from './TimerView';

export default ({ heats, workoutId, competition }) => {
  const showContext = useContext(ShowContext);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { fontColor, buttonColor } = showContext;

  const loading = useSelector((state) => workoutsSelectors.getLoading(state));
  const workout = useSelector((state) => workoutsSelectors.getWorkout(state));

  useEffect(() => {
    dispatch(workoutsActions.getWorkoutStart(workoutId));
    return () => {
      showContext.setEventTimer({ open: false });
      dispatch(workoutsActions.clearWorkout());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, workoutId]);

  return (
    <>
      {showContext.eventTimer.visible && workout && workout.id && workout.sections ? (
        <Box mb={3}>
          <TimerView
            start={showContext.eventTimer.start}
            reset={showContext.eventTimer.reset}
            competition={showContext.competition}
            setEventTimer={showContext.setEventTimer}
            workout={workout}
            closeTimer={() => showContext.setEventTimer(false)}
          />
        </Box>
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Paper elevation={2} mb={3} className="paper-rounded opacity-80" p={3}>
              <Typography color="secondary" mb={1} variant="h5">
                {workout.name}
              </Typography>

              {loading && (
                <Box display="flex" justifyContent="center">
                  <LoaderSm loading={loading} />
                </Box>
              )}
              {!loading && (
                <>
                  <Box display="flex">
                    <Typography component="span" variant="body1">
                      {t('start').toUpperCase()}&nbsp;
                    </Typography>
                    <Typography style={{ color: fontColor }} component="span" variant="body1">
                      <span>
                        {date(
                          workout.startAt,
                          showContext?.competition?.channel?.dateFormatExtReact,
                        )}
                      </span>
                      <span>&nbsp;&bull;&nbsp;</span>
                      <span className="roboto-regular">{timeWithoutTz(workout.startAt)}</span>
                    </Typography>
                  </Box>
                  <Box display="flex" mt={1}>
                    <Typography component="span" variant="body1">
                      {t('end').toUpperCase()}&nbsp;
                    </Typography>
                    <Typography style={{ color: fontColor }} component="span" variant="body1">
                      <span>
                        {date(workout.endAt, showContext?.competition?.channel?.dateFormatExtReact)}
                      </span>
                      <span>&nbsp;&bull;&nbsp;</span>
                      <span className="roboto-regular">{timeWithoutTz(workout.endAt)}</span>
                    </Typography>
                  </Box>
                </>
              )}
              {workout.divisions && workout.divisions.length > 0 && (
                <Box display="flex" mt={1}>
                  <Typography component="span" variant="body1">
                    {t(
                      workout.divisions.length > 1
                        ? 'competition.divisions'
                        : 'competition.division',
                    ).toUpperCase()}
                    &nbsp;
                  </Typography>
                  <Typography style={{ color: fontColor }} component="span" variant="body1">
                    {workout.divisions && workout.divisions.map((x) => x.name).join(', ')}
                  </Typography>
                </Box>
              )}

              {!loading && (
                <Box mt={3}>
                  <ButtonActions showTimer={() => showContext.setEventTimer({ visible: true })} />
                </Box>
              )}
            </Paper>

            {competition?.isCoach && (
              <Paper p={3} elevation={2} mb={3} className="paper-rounded opacity-80">
                <Typography variant="h5">{t('competition.tab.volunteers')}</Typography>
                {workout.competitionsUsersCategories?.map((item, index) => (
                  <Box key={item?.id}>
                    <Box display="flex" p={2}>
                      <Typography>{item?.userName}</Typography>
                      &nbsp;
                      <Typography color="textSecondary">
                        {item?.competitionsCategory?.name}
                      </Typography>
                    </Box>

                    {workout.competitionsUsersCategorie?.length - 1 > index && <Divider m={2} />}
                  </Box>
                ))}
              </Paper>
            )}

            {workout.sections && workout.sections[0] && (
              <Box my={3}>
                <ItemSection
                  hideSections={!competition.isCoach && workout?.hideSectionsEveryone}
                  section={{ open: true, ...workout.sections[0] }}
                  buttonColor={buttonColor}
                  showLeaderboard={false}
                  isCoach={competition.isCoach || competition?.isJudge}
                  isCompetition
                  isRegistered={workout?.registered}
                />
              </Box>
            )}

            <EqpMovList
              workout={workout}
              fontColor={fontColor}
              className="opacity-80"
              competition
            />

            {workout.attachments && workout.attachments.length > 0 && (
              <Paper elevation={2} mb={3} className="paper-rounded opacity-80" p={3}>
                <Box display="flex" mb={3}>
                  <PictureAsPdfIcon />
                  <Typography ml={2} color="secondary" variant="h5">
                    {t('workout.attachments').toUpperCase()}
                  </Typography>
                </Box>
                {workout.attachments &&
                  workout.attachments.map((file) => (
                    <Box display="flex" alignItems="center" key={file.id}>
                      <Box key={file.id}>
                        <Box flexGrow={1}>
                          <Typography color="secondary" variant="h6">
                            {file.name}
                          </Typography>
                        </Box>

                        <Link href={file.attachment.url} target="_blank" className="link">
                          <ButtonPrimary buttonColor={buttonColor}>
                            {t('button.download').toUpperCase()}
                          </ButtonPrimary>
                        </Link>
                      </Box>
                      <Divider />
                    </Box>
                  ))}
              </Paper>
            )}
          </Grid>
          <Grid item xs={12} md={6} mb={5}>
            {(workout?.previewVideoLink || (workout.previewVideo && workout.previewVideo.url)) && (
              <Paper elevation={2} mb={3} className="paper-rounded opacity-80" p={3}>
                <Box className="container-aspect container-aspect-57">
                  <Box className="item-aspect">
                    <Video
                      src={
                        workout?.previewVideoLink ||
                        (workout.previewVideo && workout.previewVideo.url)
                      }
                      full
                      vimeo
                    />
                  </Box>
                </Box>
              </Paper>
            )}
            {competition?.heats && heats && (
              <WorkoutHeats
                loading={loading}
                workout={workout}
                isCoach={competition.isCoach}
                fontColor={fontColor}
                buttonColor={buttonColor}
              />
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};
