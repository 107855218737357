import React from 'react';
import Box from 'core/ui/Box';
import Texture from 'core/ui/Texture';
import Paper from 'core/ui/Paper';
import Typography from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import { dateExt, time } from 'core/utils/formatters';
import { Dialog } from '@material-ui/core';

const ModalClassTypeWorkouts = ({ open, workouts, channel, close }) => {
  const { t } = useTranslation();
  return (
    <Dialog open={open} maxWidth="xs" fullWidth scroll="body" onClose={close}>
      <Texture>
        <Paper p={3}>
          <Typography variant="h5" mb={3}>
            {t('label.classes')}
          </Typography>
          {workouts?.map((w) => (
            <Box key={w.id} display="flex" alignItems="center">
              <Typography>
                {w?.id}&nbsp;-&nbsp;{w?.name}&nbsp;-&nbsp;
              </Typography>
              <Typography>
                <Typography color="primary" component="span" variant="body1">
                  <span>
                    {dateExt(w.datetime, t, channel?.useUserTimezone ? null : w?.timezone)}
                  </span>
                  <span>&nbsp;&bull;&nbsp;</span>
                  <span className="roboto-regular">
                    {time(w.datetime, channel?.useUserTimezone ? null : w?.timezone)}
                  </span>
                </Typography>
              </Typography>
            </Box>
          ))}
        </Paper>
      </Texture>
    </Dialog>
  );
};

export default ModalClassTypeWorkouts;

ModalClassTypeWorkouts.defaultProps = {};
