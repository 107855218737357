/* eslint-disable react/prop-types */
import React from 'react';
import Box from 'core/ui/Box';
import Typography from 'core/ui/Typography';
import Card from 'core/ui/Card';
import LoaderSm from 'core/ui/LoaderSm';
import Grid from 'core/ui/Grid';
import useChannelStyles from 'core/useChannelStyles';

export default ({ member, loading }) => {
  const { buttonColor } = useChannelStyles();
  const CardResume = ({ title, value }) => (
    <Box m={1}>
      <Card title={title} color={buttonColor}>
        <Typography color="primary" align="center" variant="h3">
          {value || 0}
        </Typography>
        <LoaderSm center loading={loading} />
      </Card>
    </Box>
  );

  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <CardResume title="Upcoming Classes" value={member?.channelResume?.totalClasses} />
      </Grid>
      <Grid item xs={12} md={6}>
        <CardResume
          title="Current Month Checkins"
          value={member?.channelResume?.countCheckinMonth}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <CardResume title="Active Memberships" value={member?.channelResume?.totalMemberships} />
      </Grid>
      <Grid item xs={12} md={6}>
        <CardResume title="Payments" value={member?.channelResume?.totalPayments} />
      </Grid>
    </Grid>
  );
};
