import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { MembersContext } from 'components/Channel/Manage/Tabs/Members/Memberships/index';
import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  TablePagination,
  TableFooter,
} from '@material-ui/core';
import Typography, { fontStyle } from 'core/ui/Typography';
import Box from 'core/ui/Box';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { onlineColor, redColor, successColor } from 'core/ui/Colors';
import { date } from 'core/utils/formatters';
import TablePaginationActions from 'core/ui/TablePaginationActions';
import UserMembershipPlanResume from 'components/Channel/Manage/Tabs/Reports/Membership/UserMembershipPlanResume';
import { Warning } from '@material-ui/icons';
import TypographyIcon from 'core/ui/TypographyIcon';
import ModalRenewMembership from './ModalRenewMembership';
import MenuMembership from './MenuMembership';

const UserPlans = ({
  channel,
  user,
  keys,
  removeChannelKeyFromUser,
  getMemberships,
  removeKey,
  inactiveKeys,
  setModalAddPayment,
  setModalSwitch,
}) => {
  const { setModalFormKey } = useContext(MembersContext);
  const { t } = useTranslation();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box mt={2}>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">{t('channel.memberships.column.plan')}</TableCell>
              <TableCell>{t('channel.memberships.planExpiresIn')}</TableCell>
              <TableCell>{t('channel.memberships.contractDates')}</TableCell>
              <TableCell>{t('channel.memberships.column.expires')}</TableCell>
              <TableCell>{t('channel.memberships.column.renew')}</TableCell>
              <TableCell>{t('channel.memberships.column.removeAt')}</TableCell>
              {!inactiveKeys && (
                <>
                  <TableCell align="center">{t('channel.memberships.column.renewable')}</TableCell>
                  <TableCell align="center">{t('channel.memberships.column.active')}</TableCell>
                </>
              )}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? keys.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : keys
            ).map((key) => (
              <TableRow key={key?.id} style={{ backgroundColor: key.pauseAt ? '#FFFF66' : '' }}>
                <TableCell align="center" className="tw-relative">
                  {key.isPostPayment && (
                    <div className="tw-absolute tw-left-0 tw-top-0 tw-rounded-br tw-bg-red-600 tw-px-0.5 tw-text-white">
                      <span className="tw-text-[10px] tw-font-semibold tw-leading-none">
                        Pay later
                      </span>
                    </div>
                  )}

                  {key.membership && key.membershipPlan && (
                    <UserMembershipPlanResume channelKey={key} />
                  )}
                  {!key.membershipPlan && (
                    <>
                      <Box display="flex" justifyContent="center">
                        <Typography mr={3} color="secondary" ml={3} style={{ ...fontStyle.small }}>
                          Channel Key
                        </Typography>
                      </Box>
                      <Typography color="primary" ml={3} style={{ ...fontStyle.xSmall }}>
                        {key.kind === 'online'
                          ? t('workout.setting.online')
                          : t('workout.setting.inPerson')}
                      </Typography>

                      {key?.inPersonHybridAccess && (
                        <Typography>{t('channel.hybridAccess')}</Typography>
                      )}
                      {key?.inPersonSifelyAccess && (
                        <Typography>{t('channel.sifelyAccess')}</Typography>
                      )}
                    </>
                  )}
                  {key?.offline && key?.offlineType !== 'post_payment' && (
                    <Box>
                      <Typography variant="body2" style={{ color: redColor }}>
                        {t('channel.membership.offline')}
                      </Typography>
                    </Box>
                  )}
                  {key?.offline && key?.offlineType === 'post_payment' && (
                    <Box>
                      <Typography variant="body2" style={{ color: redColor }}>
                        {t('channel.membership.pendingPostPayment')}
                      </Typography>
                      {!key?.postPaymentExpired ? (
                        <Typography color="secondary" style={{ ...fontStyle.medium }}>
                          Expires: {date(key?.postPaymentExpiryDate, channel?.dateFormatReact)}
                        </Typography>
                      ) : (
                        <Typography color="secondary" style={{ ...fontStyle.medium }}>
                          Member Debt
                        </Typography>
                      )}
                    </Box>
                  )}
                </TableCell>

                <TableCell>
                  <Typography color="primary" style={{ ...fontStyle.medium }}>
                    {key.planExpiryAt ? (
                      <>{date(key.planExpiryAt, channel?.dateFormatReact)}</>
                    ) : (
                      <>{t('channel.memberships.noExpiry')}</>
                    )}
                  </Typography>
                </TableCell>

                <TableCell>
                  {key?.contractStartDate && (
                    <Typography color="secondary" style={{ ...fontStyle.medium }}>
                      {date(key?.contractStartDate, channel?.dateFormatReact)}&nbsp;-&nbsp;
                      {date(key?.contractEndDate, channel?.dateFormatReact)}
                    </Typography>
                  )}

                  {!key?.contractAccepted && (
                    <TypographyIcon
                      mt={3}
                      icon={<Warning fontSize="small" />}
                      text={
                        <Typography style={fontStyle.medium}>
                          {t('memberhship.contract.pendingAccept')}
                        </Typography>
                      }
                    />
                  )}
                </TableCell>

                <TableCell>
                  {key?.kind === 'membership' && !key?.renewable && (
                    <KeyExpiryRenew
                      channel={channel}
                      getMemberships={getMemberships}
                      t={t}
                      channelKey={key}
                    />
                  )}
                </TableCell>
                <TableCell>
                  {key?.kind === 'membership' && key?.renewable && (
                    <KeyExpiryRenew
                      channel={channel}
                      getMemberships={getMemberships}
                      t={t}
                      channelKey={key}
                    />
                  )}
                </TableCell>

                <TableCell>
                  {!!key.removeUserAt && (
                    <Typography color="secondary">
                      {key.removeUserAt ? date(key.removeUserAt, channel?.dateFormatReact) : ''}
                    </Typography>
                  )}
                </TableCell>
                {!inactiveKeys && (
                  <>
                    <TableCell align="center">
                      {!key.notStarted && key.membership && (
                        <Typography className="link-hover" variant="caption">
                          {!key.renewable ? (
                            <RadioButtonUncheckedIcon
                              style={{ ...fontStyle.medium, color: redColor }}
                            />
                          ) : (
                            <CheckCircleOutlineIcon
                              style={{ ...fontStyle.medium, color: onlineColor }}
                            />
                          )}
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {!key.notStarted && key.membership && (
                        <Typography className="link-hover" variant="caption">
                          {!key.active ? (
                            <RadioButtonUncheckedIcon
                              style={{ ...fontStyle.large, color: redColor }}
                            />
                          ) : (
                            <CheckCircleOutlineIcon
                              style={{ ...fontStyle.large, color: onlineColor }}
                            />
                          )}
                        </Typography>
                      )}
                    </TableCell>
                  </>
                )}
                <TableCell align="center">
                  {/* {key.kind === 'membership' && (!!key.pauseAt || !key.expired || !key.membership) && (
                    <Typography
                      className="link-hover"
                      variant="caption"
                      onClick={() =>
                        setModalFormKey((prev) => ({
                          ...prev,
                          open: true,
                          mode: 'edit',
                          channelKey: key,
                          user,
                        }))
                      }
                    >
                      <EditIcon style={{ ...fontStyle.large }} />
                    </Typography>
                  )} */}

                  <MenuMembership
                    setModalFormKey={setModalFormKey}
                    channelKey={key}
                    inactiveKeys={inactiveKeys}
                    user={user}
                    actions={{
                      removeChannelKeyFromUser,
                      removeKey,
                      setModalAddPayment,
                      setModalSwitch,
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                colSpan={!inactiveKeys ? 9 : 7}
                count={keys.length}
                rowsPerPage={rowsPerPage}
                page={page}
                labelRowsPerPage={t('rowsPerPage')}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Box>
  );
};

const KeyExpiryRenew = ({ t, getMemberships, channelKey, channel }) => (
  <>
    {!channelKey?.pauseAt && !channelKey?.notStarted && channelKey?.membership && (
      <Typography color={channelKey?.expired ? 'primary' : 'secondary'}>
        {channelKey?.expiryAt ? date(channelKey?.expiryAt, channel?.dateFormatReact) : ''}
      </Typography>
    )}
    {channelKey?.pauseStartAt && (
      <Typography color={channelKey?.expired ? 'primary' : 'secondary'}>
        {t('channel.label.pause.startAt')}&nbsp;
        {date(channelKey?.pauseStartAt, channel?.dateFormatReact)}
      </Typography>
    )}
    {channelKey?.removeActiveAt && (
      <Typography color={channelKey?.expired ? 'primary' : 'secondary'}>
        {t(channelKey?.active ? 'channel.label.pause.inactiveAt' : 'channel.label.pause.activeAt')}
        &nbsp;
        {date(channelKey?.removeActiveAt, channel?.dateFormatReact)}
      </Typography>
    )}
    {channelKey?.removeRenewableAt && (
      <Typography color={channelKey?.expired ? 'primary' : 'secondary'}>
        {t(
          channelKey?.renewable
            ? 'channel.label.pause.renewableRemoveAtNo'
            : 'channel.label.pause.renewableRemoveAtYes',
        )}
        &nbsp;
        {date(channelKey?.removeRenewableAt, channel?.dateFormatReact)}
      </Typography>
    )}
    {channelKey?.pauseAt && (
      <>
        <Typography>
          {t('channel.membership.pause.remainingDays')}:&nbsp;
          {channelKey?.pauseRemainingDays}
        </Typography>

        {!!channelKey?.pauseAt && (
          <Typography style={{ ...fontStyle.medium, color: successColor }}>
            {t('channel.label.pause.startAt')}&nbsp;
            {date(channelKey?.pauseAt, channel?.dateFormatReact)}
          </Typography>
        )}
        {!!channelKey?.pauseEndAt && (
          <Typography style={{ ...fontStyle.medium, color: successColor }}>
            {t('channel.label.pause.endAt')}&nbsp;
            {date(channelKey?.pauseEndAt, channel?.dateFormatReact)}
          </Typography>
        )}
      </>
    )}

    {channelKey?.showRetry && (
      <ModalRenewMembership
        channel={channel}
        channelKey={channelKey}
        callbackSave={() => getMemberships()}
      />
    )}
    {!channelKey?.pauseAt && !channelKey?.membership && channelKey?.expiryAt && (
      <Typography color="secondary">
        {channelKey?.expiryAt ? date(channelKey?.expiryAt, channel?.dateFormatReact) : ''}
      </Typography>
    )}
    {channelKey?.limitUse && (
      <Typography color="secondary" style={{ ...fontStyle.small, color: 'grey' }}>
        {t('table.column.userKeys.limitUse')}:&nbsp;{channelKey?.limitUse}
      </Typography>
    )}
  </>
);

UserPlans.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  keys: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.object.isRequired,
  removeChannelKeyFromUser: PropTypes.func,
};

UserPlans.defaultProps = {
  keys: [],
  removeChannelKeyFromUser: () => {},
};

export default UserPlans;
