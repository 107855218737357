import api from 'core/api';

export const create = (workoutId, workoutsInviteId = null) =>
  workoutsInviteId
    ? api.get(`/sessions/${workoutId}/create?workouts_invite_id=${workoutsInviteId}`)
    : api.get(`/sessions/${workoutId}/create`);
export const addMessage = ({ workoutId, message }) =>
  api.post(`/sessions/${workoutId}/add_message`, message);
export const token = (workoutId, workoutsInviteId = null) =>
  workoutsInviteId
    ? api.get(`/sessions/${workoutId}/token?workouts_invite_id=${workoutsInviteId}`)
    : api.get(`/sessions/${workoutId}/token`);
export const addEvent = (data) => api.post(`/workout_events/${data.workoutId}`, data);
export const events = (workoutId) => api.get(`/workout_events/${workoutId}`);

export const startRecording = (workoutId, workoutsInviteId) =>
  api.get(`/sessions/${workoutId}/start_recording`, { params: { workoutsInviteId } });
export const stopRecording = (workoutId, workoutsInviteId) =>
  api.get(`/sessions/${workoutId}/stop_recording`, { params: { workoutsInviteId } });
export const recordingStatus = (workoutId, workoutsInviteId) =>
  api.get(`/sessions/${workoutId}/recording_status`, { params: { workoutsInviteId } });

export const startWorkoutCompetition = (data) => api.get(`/sessions/${data.workoutId}/start`);
export const resetWorkoutCompetition = (data) =>
  api.get(`/sessions/${data.workoutId}/reset_workout_competition`);
export const access = (data) => api.post(`/sessions/${data.workoutId}/accesses`, data);
export const accessDisconnect = (data) =>
  api.post(`/sessions/${data.workoutId}/access_disconnect`, data);
export const processLocalRecording = (data) =>
  api.post(`/sessions/${data.workoutId}/process_local_recordings`, data);
