import React from 'react';
import { InputLabel } from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import { getBase64 } from 'core/utils/helpers';
import Typography from 'core/ui/Typography';
import Box from 'core/ui/Box';
import { Image } from '@material-ui/icons';

const SelImage = ({ setImage }) => {
  const { t } = useTranslation();

  const handleCapture = ({ target }) => {
    if (target.files.length) {
      for (let i = 0; i < target.files.length; i += 1) {
        const file = target.files[i];
        getBase64(file, (result) => {
          setImage({
            url: URL.createObjectURL(file),
            image: result,
            name: file.name,
          });
        });
      }
    }
  };

  const styleIcon = {
    color: '#404041',
  };
  const randomId = Math.floor(Math.random() * 101);

  return (
    <Box className="link-hover" p={3}>
      <InputLabel htmlFor={`icon-button-file-${randomId}`}>
        <Box display="flex" alignItems="center">
          <Image style={{ ...styleIcon }} size="small" />
          <Typography ml={3} color="secondary" variant="subtitle2">
            {t('button.selectImage')}
          </Typography>
        </Box>
      </InputLabel>
      <input
        color="primary"
        type="file"
        onChange={handleCapture}
        id={`icon-button-file-${randomId}`}
        style={{ display: 'none' }}
        accept=".png,.jpg,.jpeg"
        multiple
      />
    </Box>
  );
};

export default SelImage;
