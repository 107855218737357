import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import Typography from 'core/ui/Typography';
import DatePickerRounded from 'core/ui/DatePickers/DatePickerRounded';
import TimePickerRounded from 'core/ui/DatePickers/TimePickerRounded';
import SelectBox from 'core/ui/SelectBox';
import { getAllTimezones, timeZoneName } from 'core/utils/formatters/date';
import PublishDate from './PublishDate';
import ScheduleOption from './ScheduleOption';
import { WorkoutContext } from '../index';

export default () => {
  const { t } = useTranslation();
  const formContext = useContext(WorkoutContext);
  const removeScheduleOption = (index) => {
    const arr = [...formContext.scheduleOptions];
    const newArray = [];
    arr.forEach((e, i) => {
      if (i !== index) {
        newArray.push(e);
      }
    });
    formContext.setScheduleOptions(newArray);
  };

  return (
    <Box pb={3} pt={3}>
      <Typography variant="h5" mb={2} color="primary">
        {t('workout.title.schedule')}
      </Typography>

      {!formContext.competition && (
        <>
          {formContext.watch('method') && formContext.watch('method') !== 'live' && <PublishDate />}

          <Box display="flex" flexDirection="column" alignItems="flex-start" flexWrap="wrap">
            {formContext.scheduleOptions.map((s, index) => (
              <Box key={index.toString()} mt={formContext.watch('method') === 'video' ? 7 : 0}>
                <ScheduleOption
                  removeScheduleOption={(i) => removeScheduleOption(i)}
                  index={index}
                />
              </Box>
            ))}
          </Box>
        </>
      )}

      {formContext.competition && (
        <Box>
          <Typography>{t('workout.competition.date')}</Typography>
          <Box display="flex" mt={1} flexWrap="wrap" alignItems="center">
            <Box m={1}>
              <DatePickerRounded
                name="startAtDate"
                type="datetime-local"
                error={formContext.errors.startAtDate}
                usFormat={formContext?.selectedChannel?.useUsDateFormat}
                label={t('competition.label.startAtDate').toUpperCase()}
                onChange={(v) => formContext.setValue('startAtDate', v)}
                value={formContext.watch('startAtDate')}
                required
              />
            </Box>
            <Box m={1}>
              <TimePickerRounded
                name="startAtTime"
                type="datetime-local"
                error={formContext.errors.startAtTime}
                label={t('competition.label.startAtTime').toUpperCase()}
                onChange={(v) => formContext.setValue('startAtTime', v)}
                value={formContext.watch('startAtTime')}
                required
              />
            </Box>
            <Box m={1}>
              <DatePickerRounded
                name="endAtDate"
                type="datetime-local"
                usFormat={formContext?.selectedChannel?.useUsDateFormat}
                error={formContext.errors.endAtDate}
                label={t('competition.label.endAtDate').toUpperCase()}
                onChange={(v) => formContext.setValue('endAtDate', v)}
                value={formContext.watch('endAtDate')}
                required
              />
            </Box>
            <Box m={1}>
              <TimePickerRounded
                name="endAtTime"
                type="datetime-local"
                error={formContext.errors.endAtTime}
                label={t('competition.label.endAtTime').toUpperCase()}
                onChange={(v) => formContext.setValue('endAtTime', v)}
                value={formContext.watch('endAtTime')}
                required
              />
            </Box>
            <Box m={1}>
              <SelectBox
                propValue="id"
                propLabel="name"
                error={formContext.errors.timezone}
                label={t('competition.label.timezone').toUpperCase()}
                options={getAllTimezones()}
                setValue={(v) => formContext.setValue('timezone', v)}
                value={formContext.watch('timezone') || timeZoneName()}
                required
              />
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};
