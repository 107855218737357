import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import Box from 'core/ui/Box';
import Texture from 'core/ui/Texture';
import Form from './Form';

const ModalPartnerProgramDates = ({
  partner,
  open,
  close,
  clone,
  partnersProgram,
  workoutId,
  callbackSave,
}) => (
  <Dialog open={open} onClose={close} maxWidth="md" fullWidth scroll="body">
    <Texture>
      <Box p={5}>
        <Form
          partner={partner}
          callback={callbackSave}
          partnersProgram={partnersProgram}
          clone={clone}
          workoutId={workoutId}
        />
      </Box>
    </Texture>
  </Dialog>
);

ModalPartnerProgramDates.propTypes = {
  close: PropTypes.func,
};

ModalPartnerProgramDates.defaultProps = {
  close: () => {},
};

export default ModalPartnerProgramDates;
