import React, { useContext } from 'react';
import { IconButton, colors, makeStyles } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import clsx from 'clsx';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import { WorkflowContext } from 'contexts';
import { getWorkflowTypeKey } from 'components/AutomatedWorkflow/utils/getWorkflowTypeKey';
import { reorder } from 'core/utils/helpers';
import { WorkflowTypeCard } from './WorkflowTypeCard';

export const GAP = 10;

export const WorkflowTypesList = ({ setTab, allowEdit }) => {
  const classes = useStyles();

  const workFlowContext = useContext(WorkflowContext);

  const isRightSideBarOpen = workFlowContext.currentType?.open ?? false;

  const onDragEnd = (result) => {
    if (!result.destination || result.source.index === 0 || result.destination.index === 0) return;

    workFlowContext.setWorkflowTypes((prevWorkflowTypes) => {
      const newWorkflowTypes = reorder(
        prevWorkflowTypes,
        result.source.index,
        result.destination.index,
      ).map((wt, index) => ({ ...wt, execOrder: index }));

      return newWorkflowTypes;
    });
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="workflowDroppable" direction="vertical">
        {(provided, snapshot) => (
          <div className={classes.container} ref={provided.innerRef} {...provided.droppableProps}>
            {workFlowContext.workflowTypesWithoutDestroyed.map((workflowType, index, arr) => (
              <Draggable
                key={String(getWorkflowTypeKey(workflowType))}
                draggableId={String(getWorkflowTypeKey(workflowType))}
                index={index}
                isDragDisabled={index === 0 || isRightSideBarOpen}
              >
                {(draggableProvided, draggableSnapshot) => (
                  <div
                    className={classes.workflowTypeCardWrapper}
                    ref={draggableProvided.innerRef}
                    {...draggableProvided.draggableProps}
                    {...draggableProvided.dragHandleProps}
                  >
                    <WorkflowTypeCard
                      workflowType={workflowType}
                      setTab={setTab}
                      allowEdit={allowEdit}
                    />

                    <IconButton
                      size="small"
                      onClick={() => workFlowContext.addWorkflowType('action', index)}
                      className={clsx(
                        classes.iconButton,
                        (snapshot.isDraggingOver ||
                          draggableSnapshot.isDragging ||
                          index + 1 >= arr.length) &&
                          classes.iconButtonHidden,
                      )}
                      style={{
                        backgroundColor:
                          workFlowContext?.currentType?.open &&
                          workFlowContext?.currentType?.index === index
                            ? colors.green[100]
                            : colors.grey[200],
                      }}
                    >
                      <Add />
                    </IconButton>
                  </div>
                )}
              </Draggable>
            ))}

            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  workflowTypeCardWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: GAP,

    '& + div': {
      marginTop: GAP,
    },
  },
  iconButton: {
    transitionProperty: 'opacity',
    transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
    transitionDuration: '150ms',
  },
  iconButtonHidden: {
    opacity: 0,
    pointerEvents: 'none',
  },
});
