import React from 'react';
import Box from 'core/ui/Box';
import Typography, { fontStyle } from 'core/ui/Typography';
import { Hidden, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import { redColor, secondary } from 'core/ui/Colors';
import ImageProduct from 'components/Store/Products/Image';
import { channelCurrency } from 'core/utils/formatters';
import { textStoreOptions } from 'core/utils/helpers';
import Checkbox from 'core/ui/Checkbox';
import ItemQty from './ItemQty';

const CartItem = ({ cartItem, shoppingCart, channel, buttonColor }) => {
  const { product, option } = cartItem;

  const shoppingCartItem = shoppingCart?.item(product, option);
  const resumeItemStore = shoppingCart?.resumeItem(option);

  const allowPurchase = !resumeItemStore?.error;

  const ProductInfo = () => (
    <Box>
      <Typography style={{ color: buttonColor, ...fontStyle.medium }} variant="subtitle1">
        {product.name}
      </Typography>
      {option && (
        <Typography style={{ color: secondary, fontSize: 10 }} variant="subtitle1">
          {textStoreOptions(option)}
        </Typography>
      )}

      {product.offerDelivery && !shoppingCart?.previewOrder?.preSale && (
        <Box>
          {product?.ownerableType !== 'Partner' ? (
            <Checkbox
              onClick={() =>
                shoppingCart?.setShoppingItem(
                  product,
                  option,
                  'delivery',
                  !shoppingCartItem?.delivery,
                )
              }
              size={20}
              checked={shoppingCartItem?.delivery}
              label={`Delivery - ${channelCurrency(product.fDeliveryPrice, product?.currency)}`}
              color={buttonColor}
            />
          ) : (
            <Typography>{`Delivery - ${channelCurrency(
              product.fDeliveryPrice,
              product?.currency,
            )}`}</Typography>
          )}
        </Box>
      )}
    </Box>
  );

  const TableCellAmounts = () => (
    <>
      <TableCell align="center">
        <Typography ml={1} style={fontStyle.medium} component="span">
          {channelCurrency(resumeItemStore?.salesTaxAmount, channel?.currency)}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Box>
          <Typography ml={1} style={fontStyle.medium} component="span">
            {channelCurrency(resumeItemStore?.totalOriginal, channel?.currency)}
          </Typography>
          {resumeItemStore?.discountAmount > 0 && (
            <span>
              <Typography style={{ ...fontStyle.xSmall, color: secondary }}>Discount</Typography>
              <Typography style={{ ...fontStyle.xSmall, color: redColor }}>
                -{channelCurrency(resumeItemStore?.discountAmount, channel?.currency)}
              </Typography>
            </span>
          )}
        </Box>
      </TableCell>
    </>
  );

  return (
    <TableRow style={{ backgroundColor: !allowPurchase ? 'rgba(255, 50, 0, 0.2)' : 'white' }}>
      <Hidden only={['xs', 'sm']}>
        <TableCell>
          <ImageProduct indicators={false} images={product.images || []} size={44} />
        </TableCell>
        <TableCell>
          <ProductInfo />
        </TableCell>
        <TableCell align="center" style={{ width: 100 }}>
          <ItemQty
            changeQty={(v) => shoppingCart?.addQtyItem(product, option, shoppingCartItem, v)}
            qty={shoppingCartItem?.qty}
          />
        </TableCell>

        {allowPurchase ? (
          <TableCellAmounts />
        ) : (
          <TableCell align="center" colSpan={2}>
            <Typography style={{ color: redColor }}>{resumeItemStore?.error}</Typography>
          </TableCell>
        )}
      </Hidden>
      <Hidden only={['md', 'lg', 'xl']}>
        <TableCell>
          <Box display="flex">
            <ImageProduct indicators={false} images={product.images || []} size={44} />
            <Box flexGrow={1} ml={3}>
              <ProductInfo />
            </Box>
          </Box>

          <Box m={2}>
            <ItemQty
              changeQty={(v) => shoppingCart?.addQtyItem(product, option, shoppingCartItem, v)}
              qty={shoppingCartItem?.qty}
            />
          </Box>

          <Box m={2}>
            {allowPurchase ? (
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCellAmounts />
                  </TableRow>
                </TableBody>
              </Table>
            ) : (
              <Typography style={{ color: 'white' }}>{resumeItemStore?.error}</Typography>
            )}
          </Box>
        </TableCell>
      </Hidden>
    </TableRow>
  );
};

CartItem.defaultProps = {};

export default CartItem;
