import React from 'react';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import Grid from 'core/ui/Grid';
import Paper from 'core/ui/Paper';
import Typography from 'core/ui/Typography';
import SelectBox from 'core/ui/SelectBox';
import FormAddress from './FormAddress';
import FormOwner from './FormOwner';
import FormBank from './FormBank';

export default ({ watch, setValue, errors }) => (
  <>
    <Paper p={3} mb={4}>
      <Typography mb={3}>Business Information</Typography>

      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <TextFieldRounded
            label="DBA Name"
            placeholder="Dba Name"
            value={watch('payload.merchant.dbaName')}
            onChange={(v) => setValue('payload.merchant.dbaName', v)}
            bordered
            error={errors?.payload?.merchant?.dbaName}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextFieldRounded
            label="Legal Business Name"
            placeholder="Legal Business Name"
            value={watch('payload.merchant.legalBusinessName')}
            onChange={(v) => setValue('payload.merchant.legalBusinessName', v)}
            bordered
            error={errors?.payload?.merchant?.legalBusinessName}
          />
        </Grid>
      </Grid>
      <Grid container mb={4} spacing={4}>
        <Grid item xs={12} md={6}>
          <TextFieldRounded
            label="Tax Filing Name"
            placeholder="The name you file taxes under"
            value={watch('payload.merchant.taxFilingName')}
            onChange={(v) => setValue('payload.merchant.taxFilingName', v)}
            bordered
            error={errors?.payload?.merchant?.taxFilingName}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectBox
            options={['SSN', 'EIN']}
            label="Tax Filing Method"
            placeholder="Tax Filing Method"
            value={watch('payload.merchant.taxFilingMethod')}
            setValue={(v) => setValue('payload.merchant.taxFilingMethod', v)}
            bordered
            error={errors?.payload?.merchant?.taxFilingMethod}
          />
        </Grid>
      </Grid>
    </Paper>
    <Grid container mb={4} spacing={4}>
      <Grid item xs={12} md={6}>
        <Paper p={3}>
          <Typography mb={3}>Business Address</Typography>
          <FormAddress
            watch={watch}
            setValue={setValue}
            rootKey="payload.merchant.demographic.businessAddress"
            errors={errors}
          />
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper p={3}>
          <Typography mb={3}>Mailing Address</Typography>
          <FormAddress
            watch={watch}
            setValue={setValue}
            rootKey="payload.merchant.demographic.mailingAddress"
            errors={errors}
            showCopy
          />
        </Paper>
      </Grid>
    </Grid>

    <FormOwner watch={watch} setValue={setValue} errors={errors} />
    <Grid container mb={4} spacing={4}>
      <Grid item xs={12} md={6}>
        <Paper p={3}>
          <Typography mb={3}>Deposit Bank</Typography>
          <FormBank
            watch={watch}
            setValue={setValue}
            rootKey="payload.merchant.bankDetail.depositBank"
            errors={errors}
          />
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper p={3}>
          <Typography mb={3}>Withdrawal Bank</Typography>
          <FormBank
            watch={watch}
            setValue={setValue}
            rootKey="payload.merchant.bankDetail.withdrawalBank"
            errors={errors}
            showCopy
          />
        </Paper>
      </Grid>
    </Grid>
  </>
);
