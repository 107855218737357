import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, FormControl, FormControlLabel, Checkbox, Radio } from '@material-ui/core';
import Typography, { fontStyle } from 'core/ui/Typography';
import Box from '../Box';

const Filter = ({ title, filters, change, checked, kind, radio }) => (
  <Box mb={3} display="flex" pl={5} justifyContent="center">
    <FormControl component="fieldset" style={{ width: '100%' }}>
      <FormGroup>
        {filters.map((filter) => (
          <Box
            className="link"
            onClick={() => change({ value: filter.id, kind, label: filter.name })}
            display="flex"
            key={filter.id}
            alignItems="center"
          >
            <Box flexGrow="1">
              <Typography variant="body2" style={{ ...fontStyle.large }}>
                {filter.name}
              </Typography>
            </Box>
            <FormControlLabel
              key={filter.id}
              control={
                !radio ? (
                  <Checkbox
                    checked={checked(filter.id, kind)}
                    name="filterType"
                    size="medium"
                    color="primary"
                    onChange={() => change({ value: filter.id, kind, label: filter.name })}
                  />
                ) : (
                  <Radio
                    checked={checked(filter.id, kind)}
                    name="filterType"
                    size="medium"
                    color="primary"
                    onChange={() => change({ value: filter.id, kind, label: filter.name })}
                  />
                )
              }
            />
          </Box>
        ))}
      </FormGroup>
    </FormControl>
  </Box>
);

Filter.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  filters: PropTypes.array,
  checked: PropTypes.func,
  change: PropTypes.func,
  kind: PropTypes.string,
  title: PropTypes.string,
  radio: PropTypes.bool,
};

Filter.defaultProps = {
  filters: [],
  checked: () => {},
  change: () => {},
  kind: '',
  title: '',
  radio: false,
};

export default Filter;
