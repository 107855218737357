import React from 'react';
import Layout from 'pages/shared/Layout/index';
import Login from 'pages/shared/Login';
import { ByToken } from 'pages/shared/Login/ByToken';
import Test from 'pages/shared/Test';
import Show from 'pages/shared/Profile';
import EditProfile from 'pages/shared/Profile/Edit';
import TypeAccount from 'pages/shared/TypeAccount';
import Stream from 'pages/Stream';
import ShowWorkout from 'pages/Workout/Show';
import ShowCompetition from 'pages/Competition/Show';
import ShowChannel from 'pages/Channel/Show';
import SelfCheckChannel from 'pages/Channel/SelfCheckIn';
import SearchCompetitions from 'pages/CompetitionsSearch';
import TermsOfUse from 'pages/shared/Contract/TermsOfUse';
import IframeBanners from 'pages/shared/embed/HeaderBanners';
import EmbedSchedule from 'pages/shared/embed/CalendarScheduleChannel';
import EmbedUploadClass from 'pages/shared/embed/UploadMobile';
import EmbedChannelMemberships from 'pages/shared/embed/ChannelMemberships';
import EmbedChannelStore from 'pages/shared/embed/ChannelStore';
import FormSetupIntent from 'pages/Stripe/Mobile/FormSetupIntent';
import ConnectAccountStripe from 'pages/Stripe/Connect';
import Judge from 'pages/Competition/Workout/Judge';
import Watch from 'pages/Competition/Workout/Watch';
import WatchTeam from 'pages/Competition/Workout/WatchTeam';
import CurrentGymRedirect from 'pages/Channel/CurrentGymRedirect';
import RequiresAction from 'pages/Stripe/RequiresAction';
import GymContract from 'pages/shared/Contract/GymContract';
import FormPaymentMethod from 'pages/Cardpointe/Mobile/FormPaymentMethod';
import ModalChannelProfileSelector from 'components/Channel/ModalChannelProfileSelector';
import Dashboard from 'components/Profile/Dashboard';
import GroupsChat from 'components/GroupsChat';
import AndroidUpload from 'pages/shared/embed/AndroidUpload';
import Calendars from 'pages/Calendars';
import CoachCalendar from 'pages/Calendars/CoachCalendar';
import CalendarCallback from 'pages/Calendars/Callback';
import CalendarConnect from 'pages/Calendars/Connect';
import ChannelsEventsShow from 'pages/ChannelsEvents/Show';
import Main from '../Main';

import coach from './coach';
import adminRoutes from './admin';
import supportRoutes from './support';
import partner from './partner';
import managerRoutes from './manager';

const routes = (role, manager = false) => {
  let userRoutes = [...coach, ...partner];

  if (role === 'admin') {
    userRoutes = [...userRoutes, ...adminRoutes];
  }
  if (role === 'support') {
    userRoutes = [...userRoutes, ...supportRoutes];
  }
  if (manager) {
    userRoutes = [...userRoutes, ...managerRoutes];
  }

  const userNavRoutes = [];
  return [
    {
      component: Main,
      routes: [
        {
          path: '/auth/login',
          exact: true,
          name: 'Login',
          component: Login,
          public: true,
        },
        {
          path: '/auth/by_token',
          exact: true,
          name: 'ByTokenLogin',
          component: ByToken,
        },
        {
          path: '/newpage',
          exact: true,
          name: 'Login',
          component: Test,
          public: true,
        },
        {
          path: '/stripe/connect/account',
          exact: true,
          name: 'ConnectAccountStripe',
          component: ConnectAccountStripe,
          public: true,
        },
        {
          path: '/stripe/payment/action/:secret',
          exact: true,
          name: 'Stripe Action',
          component: RequiresAction,
          public: true,
        },
        {
          path: '/cardpointe/payment_method_form/:userId',
          exact: true,
          name: 'Payment Method',
          component: FormPaymentMethod,
          public: true,
        },
        {
          path: '/embed/schedule/:channelId',
          exact: true,
          name: 'EmbedSchedule',
          component: EmbedSchedule,
          public: true,
        },
        {
          path: '/embed/EmbedUploadClass',
          exact: true,
          name: 'EmbedUploadClass',
          component: EmbedUploadClass,
          public: true,
        },
        {
          path: '/embed/memberships/:channelId',
          exact: true,
          name: 'ChannelMemberships',
          component: EmbedChannelMemberships,
          public: true,
        },
        {
          path: '/embed/memberships/:channelId/:paramId',
          exact: true,
          name: 'ChannelMemberships',
          component: EmbedChannelMemberships,
          public: true,
        },
        {
          path: '/workout/upload/:workoutId/:token',
          exact: true,
          name: 'AndroidUpload',
          component: AndroidUpload,
          public: true,
        },
        {
          path: '/embed/store/:channelId',
          exact: true,
          name: 'ChannelStore',
          component: EmbedChannelStore,
          public: true,
        },
        {
          path: '/embed/competition/:id/:embedTab',
          exact: true,
          name: 'Show Competition',
          component: ShowCompetition,
          public: true,
          showBannerHeader: true,
          pageBanner: 'competitions',
        },

        {
          path: '/integrations/calendars/connect',
          exact: true,
          name: 'Calendars',
          public: true,
          component: CalendarConnect,
          showBannerHeader: true,
        },
        {
          path: '/integrations/calendars/connect/:auth',
          exact: true,
          name: 'Calendars',
          public: true,
          component: CalendarConnect,
          showBannerHeader: true,
        },
        {
          path: '/integrations/calendars/callback',
          exact: true,
          name: 'Calendars',
          public: true,
          component: CalendarCallback,
          showBannerHeader: true,
        },

        {
          path: '/banners/header',
          exact: true,
          name: 'IframeBanners',
          component: IframeBanners,
          public: true,
        },
        {
          path: '/workout/:id/stream',
          exact: true,
          name: 'Stream',
          component: Stream,
          public: false,
        },
        {
          path: '/signup/type-account',
          exact: true,
          name: 'Type Account',
          component: TypeAccount,
          public: false,
        },
        {
          path: '/gym/:tab',
          exact: true,
          name: 'Current Gym Redirect',
          component: CurrentGymRedirect,
          public: true,
        },

        {
          path: '/mobile/setup_intent/:clientSecret',
          exact: true,
          name: 'Add Setup Intent in Mobile',
          component: FormSetupIntent,
          public: true,
        },
        {
          path: '/pm/setup_intent/:setupIntentId',
          exact: true,
          name: 'Add Setup Intent in Mobile',
          component: FormSetupIntent,
          public: true,
        },
        {
          path: '/contracts/:userId/:key',
          exact: true,
          name: 'Contract',
          component: GymContract,
          public: true,
        },
        {
          path: '/contracts/:userId/:key/:iframeHeight/:buttonColor',
          exact: true,
          name: 'Contract',
          component: GymContract,
          public: true,
        },
        {
          component: Layout,
          routes: [
            {
              path: '/',
              exact: true,
              component: Dashboard,
              public: false,
              showBannerHeader: true,
            },
            ...userNavRoutes,
            ...userRoutes,
            {
              path: '/group/messages/:groupId',
              exact: true,
              name: 'Classes',
              component: GroupsChat,
              public: false,
              showBannerHeader: true,
            },
            {
              path: '/channels/selector',
              exact: true,
              name: 'Classes',
              component: ModalChannelProfileSelector,
              public: true,
              showBannerHeader: true,
            },
            {
              path: '/competitions',
              exact: true,
              name: 'Competitions',
              component: SearchCompetitions,
              public: true,
              showBannerHeader: true,
              pageBanner: 'competitions',
            },
            {
              path: '/profile/edit',
              exact: true,
              name: 'Edit Profile',
              component: EditProfile,
            },
            {
              path: '/dashboard',
              exact: true,
              name: 'Dashboard',
              component: Show,
              showBannerHeader: true,
            },
            {
              path: '/calendars/manage/:channelId/:coachId',
              exact: true,
              name: 'Calendars',
              public: true,
              component: Calendars,
              showBannerHeader: true,
            },
            {
              path: '/calendars/manage/:channelId/:coachId/:calendarId',
              exact: true,
              name: 'Calendars',
              public: true,
              component: Calendars,
              showBannerHeader: true,
            },
            {
              path: '/calendar/:calendarId',
              exact: true,
              name: 'Calendars',
              public: true,
              component: CoachCalendar,
              showBannerHeader: true,
            },
            {
              path: '/dashboard/:tab',
              exact: true,
              name: 'Dashboard',
              component: Show,
              showBannerHeader: true,
            },

            {
              path: '/channel/view/:id',
              exact: true,
              name: 'Show Channel',
              component: ShowChannel,
              public: true,
              showBannerHeader: true,
            },
            {
              path: '/channel/view/:id/:defaultTab/:paramId',
              exact: true,
              name: 'Show Channel',
              component: ShowChannel,
              public: true,
              showBannerHeader: true,
            },
            {
              path: '/channel/view/:id/:defaultTab',
              exact: true,
              name: 'Show Channel',
              component: ShowChannel,
              public: true,
              showBannerHeader: true,
            },
            {
              path: '/channel/selfCheckIn/:id',
              exact: true,
              name: 'Show Channel',
              component: SelfCheckChannel,
              public: true,
              showBannerHeader: true,
            },
            {
              path: '/workout/view/:id',
              exact: true,
              name: 'Show Workout',
              component: ShowWorkout,
              public: true,
              showBannerHeader: true,
            },
            {
              path: '/channel/events/:channelId/view/:eventId',
              exact: true,
              name: 'Show Channels Events',
              component: ChannelsEventsShow,
              public: true,
              showBannerHeader: true,
            },
            {
              path: '/workout/view/:id/:defaultTab',
              exact: true,
              name: 'Show Workout',
              component: ShowWorkout,
              public: true,
              showBannerHeader: true,
            },
            {
              path: '/workout/view/:id/overview/leaderboard/:sectionId',
              exact: true,
              name: 'Show Workout',
              component: ShowWorkout,
              public: true,
              showBannerHeader: true,
            },
            {
              path: '/competition/view/:id',
              exact: true,
              name: 'Show Competition',
              component: ShowCompetition,
              public: true,
              showBannerHeader: true,
              pageBanner: 'competitions',
            },
            {
              path: '/competition/view/:id/:tab',
              exact: true,
              name: 'Show Competition',
              component: ShowCompetition,
              public: true,
              showBannerHeader: true,
              pageBanner: 'competitions',
            },
            {
              path: '/competition/:competitionId/workout/:workoutId/judge',
              exact: true,
              name: 'Workout Judge',
              component: Judge,
              public: true,
            },
            {
              path: '/competition/:competitionId/workout/:workoutId/watch',
              exact: true,
              name: 'Workout Watch',
              component: Watch,
              public: true,
            },
            {
              path: '/competition/:competitionId/workout/:workoutId/watch/team/:userId',
              exact: true,
              name: 'Workout Watch',
              component: WatchTeam,
              public: true,
            },
            {
              path: '/terms-of-use',
              exact: true,
              name: 'TermsOfUse',
              component: TermsOfUse,
              public: true,
            },
            {
              path: '/payments',
              exact: true,
              name: 'Manage Account',
              component: () => <Show defaultTab="manageAccount" />,
              public: false,
            },
          ],
        },
      ],
    },
  ];
};

export default routes;
