import React, { useEffect, useState } from 'react';
import { ButtonPrimary } from 'core/ui/Button';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import Typography, { fontStyle } from 'core/ui/Typography';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { onlineColor, primary, redColor } from 'core/ui/Colors';
import { Chip, IconButton, Link } from '@material-ui/core';
import { Add, Delete, Edit, FileCopy } from '@material-ui/icons';
import SFDataTable from 'core/ui/DataTable';
import Paper from 'core/ui/Paper';
import useActions from 'modules/map/useActions';
import useSelectors from 'modules/map/useSelectors';
import Modules from 'modules';
import { integrationsModule } from 'modules/integrations';
import { date } from 'core/utils/formatters';
import { integrationTypes } from 'core/utils/consts';
import { copyToClipBoard } from 'core/utils/helpers';
import useConfirmation from 'core/useConfirmation';
import LoaderSm from 'core/ui/LoaderSm';
import Grid from 'core/ui/Grid';
import StripeConnectedAccount from 'components/Channel/Manage/Tabs/Plans/StripeConnectedAccount';
import ModalFormIntegrations from './Modal';
import CardPointeDetails from './CardPointeDetails';

const ChipKey = ({ title }) => (
  <Chip
    size="small"
    plac
    color="secondary"
    label={title}
    onDelete={() => copyToClipBoard(title)}
    deleteIcon={<FileCopy />}
  />
);

const signatureStatus = ['NOT_SENT', 'PENDING', 'SIGNED'];

export const IntegrationPayload = ({ integration, cardpointeStep }) => {
  const loadingSignature = useSelectors(integrationsModule, 'loadingSignature');

  return (
    <Paper p={3}>
      {!!integration?.cardpointeStatusMessage && (
        <Box m={1}>
          <Typography>Integration Message Status</Typography>
          <Typography style={{ color: redColor }}>
            {integration?.cardpointeStatusMessage}
          </Typography>
        </Box>
      )}

      <Box>
        {integration?.kind === 'cardpointe' && (
          <>
            {!!integration?.cardpointeStatusGateway && (
              <Box m={1} display="flex" alignItems="center">
                <Typography style={fontStyle.medium}>Gateway Status:</Typography>&nbsp;
                <Typography style={fontStyle.medium} color="textSecondary">
                  {integration?.cardpointeStatusGateway}
                </Typography>
              </Box>
            )}
            <Box m={1} display="flex" alignItems="center">
              <Typography style={fontStyle.medium}>Onboarding Status:</Typography>&nbsp;
              <Typography style={fontStyle.medium} color="textSecondary">
                {integration?.cardpointeStatusOnbarding}
              </Typography>
            </Box>

            {integration?.cardpointeStep === 'draft' && (
              <ButtonPrimary
                disabled={loadingSignature}
                buttonColor={primary}
                onClick={() => cardpointeStep(integration.id, 'ready')}
              >
                <LoaderSm loading={loadingSignature} />
                Sync Merchant
              </ButtonPrimary>
            )}
            {integration?.cardpointeStep === 'ready' && (
              <ButtonPrimary
                disabled={loadingSignature}
                buttonColor={primary}
                onClick={() => cardpointeStep(integration.id, 'pending_sign')}
              >
                <LoaderSm loading={loadingSignature} />
                Signature
              </ButtonPrimary>
            )}
            {integration?.cardpointeStep === 'pending_sign' && (
              <>
                <ButtonPrimary
                  disabled={loadingSignature}
                  buttonColor={redColor}
                  onClick={() => cardpointeStep(integration.id, 'draft')}
                >
                  <LoaderSm loading={loadingSignature} />
                  Cancel Signature
                </ButtonPrimary>

                <Link
                  href={integration?.cardpointeSignatureUrl}
                  target="_blank"
                  onClick={() => cardpointeStep(integration.id, 'draft')}
                >
                  <LoaderSm loading={loadingSignature} />
                  Sign
                </Link>
              </>
            )}
            {integration?.cardpointeSignatureStatus && (
              <Box mt={2}>
                <Box m={1} display="flex" alignItems="center">
                  <Typography>Signature Status:</Typography>&nbsp;
                  <Typography
                    style={{
                      color: signatureStatus.find(
                        (x) => x === integration?.cardpointeSignatureStatus,
                      )
                        ? primary
                        : 'red',
                    }}
                  >
                    {integration?.cardpointeSignatureStatus}
                  </Typography>
                </Box>
              </Box>
            )}
          </>
        )}
      </Box>

      <Box display="flex" flexDirection="column">
        {integration?.kind === 'zapier' && (
          <Typography align="center" mx={1} mb={1} variant="h5">
            YOUR API KEY:&nbsp;
            <ChipKey title={integration?.token} />
          </Typography>
        )}

        {integration?.kind === 'hybrid' && (
          <>
            <Typography mb={1}>
              GYM ID: <ChipKey title={integration?.externalId} />
            </Typography>
            <Typography variant="caption">
              TOKEN: <ChipKey title={integration?.token} />
            </Typography>
          </>
        )}
        {integration?.kind === 'shopify' && (
          <>
            <Typography mb={1}>
              SHOP: <ChipKey title={integration?.payload?.shop} />
            </Typography>
            <Typography variant="caption">
              ACCESS TOKEN: <ChipKey title={integration?.payload?.accessToken} />
            </Typography>
          </>
        )}
      </Box>
    </Paper>
  );
};

const Integrations = ({
  ownerableType,
  ownerableId,
  dateFormatExtReact,
  hasSifelyPlan,
  showCardpointe,
  hasStripe,
}) => {
  const { t } = useTranslation();
  const { request } = useActions();
  const { confirmation } = useConfirmation();
  const integrations = useSelectors(integrationsModule, 'data');

  const [modal, setModal] = useState({
    open: false,
  });

  const getIntegrations = () => {
    request({
      action: Modules.integrations.actions.getIntegrations,
      data: {
        ownerableType,
        ownerableId,
        showInactive: true,
      },
    });
  };

  const cardpointeStep = (integrationId, step) => {
    const messages = {
      ready: {
        msg: 'Are you sure you are ready to submit your application to the processor?',
        yes: 'Yes',
        no: 'Not yet',
      },
      draft: {
        msg: 'Are you sure you want cancel signature request?',
        yes: 'Yes',
        no: 'No',
      },
      pending_sign: {
        msg: 'Are you ready to sign your application?',
        yes: 'Yes',
        no: 'Not yet',
      },
    };

    const yesClick = () => {
      request({
        action: Modules.integrations.actions.cardpointeStep,
        data: {
          ownerableType,
          ownerableId,
          id: integrationId,
          step,
        },
        options: {
          onSuccess: () => {
            getIntegrations();
          },
        },
      });
    };

    if (step === 'update') {
      yesClick();
      return;
    }

    confirmation({
      title: step.toUpperCase(),
      description: messages[step]?.msg,
      labelConfirm: messages[step]?.yes || 'Yes',
      labelCancel: messages[step]?.no || 'No',
      yesClick: () => {
        yesClick();
      },
    });
  };

  const onRemoveClick = (integrationId) => {
    confirmation({
      description: 'Are you sure?',
      yesClick: () => {
        request({
          action: Modules.integrations.actions.destroy,
          data: {
            ownerableType,
            ownerableId,
            id: integrationId,
          },
          options: {
            onSuccess: () => {
              getIntegrations();
            },
          },
        });
      },
    });
  };

  useEffect(() => {
    if (ownerableId) {
      getIntegrations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request, ownerableId, ownerableType]);

  const openModal = () => {
    setModal((prev) => ({ ...prev, mode: 'new', open: true }));
  };

  const editItem = (item) => {
    setModal((prev) => ({ ...prev, item, mode: 'edit', open: true }));
  };

  const columns = [
    {
      name: 'Integration',
      selector: 'kind',
      cell: (row) => (
        <Box>
          <Box display="flex" alignItems="center" py={1}>
            <img
              src={integrationTypes.find((x) => x.id === row.kind)?.img}
              alt={row.kind}
              style={{ width: 26 }}
            />
            <Typography ml={1}>{t(`integration.kind.${row.kind}`)}</Typography>
          </Box>
        </Box>
      ),
      sortable: true,
    },
    {
      name: 'Created',
      selector: 'createdAt',
      cell: (row) => <Typography> {date(row.createdAt, dateFormatExtReact)}</Typography>,
      sortable: true,
      center: true,
    },
    {
      name: 'Active',
      selector: 'active',
      sortable: true,
      right: true,
      cell: () => <CheckCircleOutlineIcon style={{ ...fontStyle.medium, color: onlineColor }} />,
    },
    {
      sortable: false,
      center: true,
      cell: (row) => (
        <>
          {row?.allowEdit && row?.kind === 'cardpointe' && (
            <IconButton onClick={() => editItem(row)}>
              <Edit />
            </IconButton>
          )}
          <IconButton onClick={() => onRemoveClick(row?.id)}>
            <Delete />
          </IconButton>
        </>
      ),
    },
  ];

  const cardpointe = integrations?.find((x) => x.kind === 'cardpointe');

  const ExpanableComponent = ({ data: dataExpanded }) => (
    <Box p={5}>
      <IntegrationPayload integration={dataExpanded} cardpointeStep={cardpointeStep} />
    </Box>
  );

  return (
    <Box>
      <Box display="flex" alignItems="center" mb={3}>
        <Box flexGrow={1}>
          <Typography variant="h4" color="primary" mr={1}>
            {t('integrations')}
          </Typography>
        </Box>
        <Box>
          <ButtonPrimary onClick={() => openModal()}>
            <Box display="flex" alignItems="flex-end">
              <Add size="small" />
              <Box alignItems="center">
                <Typography component="span" mr={1}>
                  {t('button.addIntegration')}
                </Typography>
              </Box>
            </Box>
          </ButtonPrimary>
        </Box>
      </Box>
      {modal.open && (
        <ModalFormIntegrations
          hasSifelyPlan={hasSifelyPlan}
          open={modal.open}
          item={modal.item}
          mode={modal.mode}
          ownerableType={ownerableType}
          ownerableId={ownerableId}
          close={() => setModal((prev) => ({ ...prev, open: false, item: {} }))}
          callback={(nameList) =>
            setModal((prev) => ({ ...prev, open: false, item: {}, nameListExpand: nameList }))
          }
          showCardpointe={showCardpointe}
        />
      )}

      <Grid container spacing={5}>
        <Grid item xs={12} md={6}>
          <Paper className="paper-rounded" p={3}>
            <Box mt={2}>
              <SFDataTable
                noHeader
                columns={columns}
                data={integrations.filter((x) => x !== cardpointe)}
                expandableRows
                expandableRowsComponent={<ExpanableComponent />}
                pagination
                showExport={false}
              />
            </Box>
          </Paper>
        </Grid>
        {ownerableType === 'Channel' && (
          <Grid item xs={12} md={6}>
            {hasStripe && (
              <Paper p={3} mb={3}>
                <Box display="flex" alignItems="center" py={1}>
                  <img
                    src={integrationTypes.find((x) => x.id === 'stripe')?.img}
                    alt="Stripe"
                    style={{ width: 26, borderRadius: 50 }}
                  />
                  <Typography ml={1} variant="h5">
                    {t(`integration.kind.stripe`)}
                  </Typography>
                </Box>
                <Box>
                  <StripeConnectedAccount />
                </Box>
              </Paper>
            )}
            {showCardpointe && cardpointe && (
              <Paper p={3}>
                <Box display="flex" alignItems="center" py={1}>
                  <img
                    src={integrationTypes.find((x) => x.id === 'cardpointe')?.img}
                    alt="Cardpointe"
                    style={{ width: 26, borderRadius: 50 }}
                  />
                  <Typography variant="h5" ml={1}>
                    {t(`integration.kind.cardpointe`)}
                  </Typography>
                </Box>
                <CardPointeDetails
                  editItem={editItem}
                  cardpointeStep={cardpointeStep}
                  integration={cardpointe}
                />
              </Paper>
            )}
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default Integrations;
