import { useEffect } from 'react';
import {
  coachScheduleSessionsActions,
  coachScheduleSessionsSelectors,
} from 'modules/coachScheduleSessions';
import { useDispatch, useSelector } from 'react-redux';
import usePullRefresh from 'core/usePullRefresh';
import { sessionSelectors } from 'modules/session';
import { usersActions, usersSelectors } from 'modules/users';

export default () => {
  const dispatch = useDispatch();
  const { refresh } = usePullRefresh();

  const currentUser = useSelector((state) => sessionSelectors.getUser(state));
  const isCoach = useSelector((state) => sessionSelectors.getIsCoach(state));
  const loading = useSelector((state) => coachScheduleSessionsSelectors.getLoading(state));
  const coachSessions = useSelector((state) =>
    coachScheduleSessionsSelectors.getCoachSessions(state),
  );
  const userSessions = useSelector((state) =>
    coachScheduleSessionsSelectors.getUserSessions(state),
  );
  const loadingWorkouts = useSelector((state) => usersSelectors.getLoadingWorkoutsInvites(state));
  const workoutsInvites = useSelector((state) => usersSelectors.getWorkoutsInvites(state));

  const getCoachSessions = () => {
    dispatch(coachScheduleSessionsActions.getCoachScheduleSessionsStart(currentUser.id));
  };

  const getUserSessions = () => {
    dispatch(coachScheduleSessionsActions.getUserScheduleSessionsStart(currentUser.id));
  };

  const getInvites = () => {
    dispatch(usersActions.getWorkoutsInvitesStart(currentUser.id));
  };

  useEffect(() => {
    if (currentUser && currentUser.id) {
      getCoachSessions();
      getUserSessions();
      getInvites();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  useEffect(() => {
    if (refresh) {
      getCoachSessions();
      getUserSessions();
      getInvites();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  return {
    isCoach,
    loadingSchedule: loading,
    coachSessions,
    userSessions,

    getCoachSessions,
    getUserSessions,
    getInvites,

    loadingInvites: loadingWorkouts,
    workoutsInvites,
  };
};
