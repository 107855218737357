/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Typography, { fontStyle } from 'core/ui/Typography';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import Box from 'core/ui/Box';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Paper from 'core/ui/Paper';
import { Tooltip } from '@material-ui/core';
import { Info } from '@material-ui/icons';

const FormCancelationPolicyAutomation = ({ currentPlan, setCurrentPlan }) => {
  const { t } = useTranslation();

  const setConfig = (field, value) => {
    setCurrentPlan((prev) => ({
      ...prev,
      cancellationPolicyAutomation: prev.cancellationPolicyAutomation
        ? { ...prev.cancellationPolicyAutomation, [field]: value }
        : { [field]: value },
    }));
  };

  const Option = ({ label, onClick, checked }) => (
    <Box className="link-hover" mr={2} onClick={() => onClick()} display="flex" alignItems="center">
      {!checked ? (
        <RadioButtonUncheckedIcon color="primary" />
      ) : (
        <CheckCircleOutlineIcon color="primary" />
      )}
      <Typography ml={2} variant="body2">
        {label}
      </Typography>
    </Box>
  );

  return (
    <Paper className="paper-rounded" p={3}>
      <Typography mb={2} style={fontStyle.label} variant="body1">
        {t('channel.memberships.cancellationPolicyAutomation').toUpperCase()}
      </Typography>
      <Box>
        <Box display="flex" alignItems="center">
          <Box>
            <Typography mb={2} style={fontStyle.label} variant="body1">
              {t('label.active').toUpperCase()}
            </Typography>
            <Box display="flex">
              <Option
                label={t('button.yes')}
                onClick={() => setConfig('active', true)}
                checked={currentPlan?.cancellationPolicyAutomation?.active}
              />
              <Option
                label={t('button.no')}
                onClick={() => setConfig('active', false)}
                checked={!currentPlan?.cancellationPolicyAutomation?.active}
              />
            </Box>
          </Box>
          {currentPlan?.cancellationPolicyAutomation?.active && (
            <Box flexGrow={1} ml={3} display="flex" alignItems="center">
              <Box flexGrow={1}>
                <Typography mb={2} style={fontStyle.label} variant="body1">
                  {t('channel.memberships.cancellationPolicyAutomation.config').toUpperCase()}
                </Typography>
                <Box display="flex">
                  <Option
                    label={t('channel.memberships.custom.day')}
                    onClick={() => setConfig('frequency', 'day')}
                    checked={currentPlan?.cancellationPolicyAutomation?.frequency === 'day'}
                  />
                  <Option
                    label={t('channel.memberships.custom.week')}
                    onClick={() => setConfig('frequency', 'week')}
                    checked={currentPlan?.cancellationPolicyAutomation?.frequency === 'week'}
                  />
                  <Option
                    label={t('channel.memberships.custom.month')}
                    onClick={() => setConfig('frequency', 'month')}
                    checked={currentPlan?.cancellationPolicyAutomation?.frequency === 'month'}
                  />
                </Box>
                {currentPlan?.cancellationPolicyAutomation?.frequency && (
                  <Box mt={1}>
                    <TextFieldRounded
                      type="number"
                      value={currentPlan?.cancellationPolicyAutomation?.value || ''}
                      pattern="[1-9]*"
                      placeholder={`${currentPlan?.cancellationPolicyAutomation?.frequency}s`.toUpperCase()}
                      label={`${currentPlan?.cancellationPolicyAutomation?.frequency}s`.toUpperCase()}
                      onChange={(v) => setConfig('value', v)}
                      required
                      bordered
                    />
                  </Box>
                )}
              </Box>
            </Box>
          )}
        </Box>
        {currentPlan?.cancellationPolicyAutomation?.active && (
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>
              <Typography mb={2} style={fontStyle.label} variant="body1">
                {t('label.proRate').toUpperCase()}
                <Tooltip title={t('channel.memberships.cancellationPolicyAutomation.proRate')}>
                  <Info />
                </Tooltip>
              </Typography>
              <Box display="flex">
                <Option
                  label={t('button.yes')}
                  onClick={() => setConfig('proRate', true)}
                  checked={currentPlan?.cancellationPolicyAutomation?.proRate}
                />
                <Option
                  label={t('button.no')}
                  onClick={() => setConfig('proRate', false)}
                  checked={!currentPlan?.cancellationPolicyAutomation?.proRate}
                />
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Paper>
  );
};

FormCancelationPolicyAutomation.defaultProps = {
  currentPlan: {},
  setCurrentPlan: () => {},
};
FormCancelationPolicyAutomation.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  currentPlan: PropTypes.object,
  setCurrentPlan: PropTypes.func,
};

export default FormCancelationPolicyAutomation;
