import moment from 'moment';

const momentTz = require('moment-timezone');

export const date = (value, format = 'MM/DD/YYYY') => moment(value).format(format);

export const timeZoneName = () => new window.Intl.DateTimeFormat().resolvedOptions().timeZone;
export const gymDate = (value) => {
  const defaultFormat = 'MM/DD/YYYY';

  const storageFormat = localStorage.getItem('GYM_DATE_FORMAT');

  const newFormat =
    storageFormat && storageFormat !== undefined && storageFormat !== 'undefined'
      ? storageFormat
      : defaultFormat;

  return moment(value).format(newFormat);
};

export const usDateFormat = () => localStorage.getItem('GYM_USE_USE_FORMAT') === '1';

export const dateNowAdd = (number, params) => moment().add(number, params).toDate();
export const dateAdd = (value, number, params) => moment(value).add(number, params).toDate();

export const getTimeNow = () => moment().toDate();

export const cutTime = (datetime) => datetime?.substr(11, 8);

export const cutDate = (datetime) => datetime?.substr(0, 10);

export const parseDate = (value, format = null, tz = null) => {
  if (format) {
    return moment(value, format).tz(tz || timeZoneName());
  }

  return moment(value).tz(tz || timeZoneName());
};

export const nextDayOfWeek = (dateStart, dayOfWeek) => {
  const dateStartReplaced = dateStart.replace(/-/g, '/');
  const weekDayToFind = moment().day(dayOfWeek).weekday();

  let nextDate = parseDate(dateStartReplaced);

  if (!moment(nextDate).isValid()) {
    return null;
  }

  let count = 0;
  // eslint-disable-next-line no-constant-condition
  while (true) {
    if (moment(nextDate).weekday() === weekDayToFind || count > 1000) {
      break;
    }
    nextDate = dateAdd(nextDate, 1, 'days');
    count += 1;
  }

  return nextDate || dateStartReplaced;
};

export const ordinalNumber = (n) => moment.localeData().ordinal(n);

export const diffFromNow = (value, configDiff = 'seconds') => {
  const day = moment(value);
  const today = moment();
  const diff = day.diff(today, configDiff);
  return diff;
};

export const dateExt = (value, t = null, tz = null) => {
  let day = null;
  if (tz) {
    day = moment(value).tz(tz || timeZoneName());
  } else {
    day = moment(value);
  }

  const today = moment();
  if (day.format('MM-DD-YYYY') === today.format('MM-DD-YYYY') && t) {
    return t('workout.date.today');
  }
  const tomorrow = moment().add(1, 'days').format('MM-DD-YYYY');
  if (day.format('MM-DD-YYYY') === tomorrow && t) {
    return t('workout.date.tomorrow');
  }
  const dif = day.diff(today, 'days');

  if (dif > 0 && dif < 5 && t) {
    return t(`dayOfWeek.${day.format('dddd').toLowerCase()}`);
  }

  const defaultFormat = 'MMM DD, yy';
  const storageFormat = localStorage.getItem('GYM_DATE_FORMAT_EXT');

  const newFormat =
    storageFormat && storageFormat !== undefined && storageFormat !== 'undefined'
      ? storageFormat
      : defaultFormat;
  return day.format(newFormat);
};

export const dateDayOfWeek = (value) => moment(value).format('dddd');

export const timeZoneNameAbbr = (tz = null) =>
  moment.tz(tz || new window.Intl.DateTimeFormat().resolvedOptions().timeZone).zoneName();

export const getAllTimezones = () => momentTz.tz.names();

export const formatDateApi = (value, format, tz, withoutSeconds = true) => {
  if (!value) {
    return null;
  }
  return moment(value, format)
    .tz(tz || timeZoneName())
    .format(withoutSeconds ? 'MM-DD-YYYY HH:mm a Z' : 'MM-DD-YYYY HH:mm:ss a Z');
};

export const formatDateToApi = (value, tz, onlyTime = false) => {
  let string = value;
  if (typeof value !== 'string') {
    string = moment(value, 'MM-DD-YYYY hh:mm:ss a Z').format('YYYY-MM-DD HH:mm:ss');
  }
  const dateString = string?.substr(0, 10);
  const timeString = string?.substr(11, 8);

  const formattedDate = moment(
    dateString,
    typeof value === 'string' ? 'MM-DD-YYYY' : 'YYYY-MM-DD',
  ).format('YYYY-MM-DD');

  const dateTimezone = moment(value, 'MM-DD-YYYY hh:mm a').format('YYYY-MM-DD HH:mm');

  const tzAbbr2 = moment.tz(dateTimezone, tz).format('Z');

  if (dateTimezone.toString() === 'Invalid date') {
    return value;
  }

  if (onlyTime) {
    return timeString;
  }

  return `${formattedDate} ${timeString} ${tzAbbr2}`;
};

export const formatDate = (value, format) => {
  if (!value) {
    return null;
  }
  return moment(value, format).toDate();
};

export const formatDateToCurrentTimeZone = (value) => {
  if (!value) {
    return null;
  }
  return moment(value, 'MM-DD-YYYY hh:mm a').toDate();
};

export const isAfterNow = (value) => {
  const m = moment(value, 'MM-DD-YYYY hh:mm a');
  return moment(m).isAfter(moment());
};

export const dateTime = (value) => moment(value).format('MM-DD-YYYY hh:mm a');
export const dateTimeZone = (value) => {
  if (!value) {
    return null;
  }
  return moment(value).format('MM-DD-YYYY hh:mm a Z');
};

export const time = (value, tz) =>
  `${moment(value)
    .tz(tz || timeZoneName())
    .format('h:mma z')}`;
export const timeWithoutTz = (value, tz) =>
  `${moment(value)
    .tz(tz || timeZoneName())
    .format('h:mma')}`;
export const timeSeconds = (value) => moment(value).format('h:mm:ss');

export const shortDateTime = (value) => moment(value).format('MMM Do YYYY, h:mm:ss a');
export const fromNow = (value) => moment(value).fromNow();
const pad = (num) => `0${num}`.slice(-2);

export const hhmmss = (seconds) => {
  let secs = seconds;
  let minutes = Math.floor(secs / 60);
  secs %= 60;
  const hours = Math.floor(minutes / 60);
  minutes %= 60;
  return `${pad(hours)}:${pad(minutes)}:${pad(secs)}`;
  // return pad(hours)+":"+pad(minutes)+":"+pad(secs); for old browsers
};

export const mmss = (seconds) => {
  let secs = seconds;
  let minutes = Math.floor(secs / 60);
  secs %= 60;
  minutes %= 60;
  return `${pad(minutes)}:${pad(secs)}`;
};
