/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import { ButtonPrimary, ButtonPrimarySm } from 'core/ui/Button';
import Typography, { fontStyle } from 'core/ui/Typography';
import LoaderSm from 'core/ui/LoaderSm';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import useSelectors from 'modules/map/useSelectors';
import { usersImportsModule } from 'modules/usersImports';
import { usersImportToolTypes } from 'core/utils/consts';
import Paper from 'core/ui/Paper';
import { useDispatch } from 'react-redux';
import notifications from 'modules/notifications';
import Check from '@material-ui/icons/Check';
import { Link } from '@material-ui/core';
import PreviewList from './PreviewList';
import ToolsTypes from '../ToolsTypes';
import SelectFile from '../SelectFile';

export default ({ close }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [toolType, setToolType] = useState(usersImportToolTypes.wodify);
  const { request } = useActions();
  const loading = useSelectors(usersImportsModule, 'loading');
  const previewData = useSelectors(usersImportsModule, 'previewData');
  const [data, setData] = useState(null);

  const previewImport = () => {
    const formData = new FormData();
    formData.append('file', data);
    formData.append('tool', toolType);

    request({
      action: Modules.usersImports.actions.importPersonalRecords,
      data: formData,
      options: {
        onError: (error) => {
          dispatch(notifications.error(error));
        },
      },
    });
  };

  const confirmImport = () => {
    const records = previewData
      .filter((x) => !!x?.personalRecordMovement?.id)
      .map((x) => ({
        personalRecordMovementId: x?.personalRecordMovement?.id,
        scores: x.scores,
        date: x.date,
      }));

    request({
      action: Modules.usersImports.actions.confirmPersonalRecords,
      data: {
        records,
      },
      options: {
        onSuccess: () => {
          dispatch(notifications.success(t('message.success.import.prs')));
          request({
            action: Modules.usersImports.actions.setPreviewData,
            data: [],
          });
          close();
        },
      },
    });
  };

  return (
    <Box>
      <Paper p={3} m={3}>
        <ToolsTypes toolType={toolType} setToolType={setToolType} />

        <Box display="flex" alignItems="center" flexDirection="column" p={5}>
          <Box>
            <SelectFile setFile={setData} file={data} />
            <Typography mt={1} style={fontStyle.small} align="center">
              <Link
                href={toolType === 'wodify' ? '/example_wodify_prs.csv' : '/example_sugarwod.csv'}
              >
                Download Template
              </Link>
            </Typography>
          </Box>

          <Typography className="roboto-regular" mt={1}>
            {t(`select.file.import.${toolType}.help`)}{' '}
            <b>{t(`select.file.import.${toolType}.file`)}</b>
          </Typography>
          <Box display="flex" alignItems="flex-end" mb={3} mt={5}>
            <Box>
              <ButtonPrimarySm disabled={loading} p={1} onClick={() => previewImport()}>
                <LoaderSm loading={loading} width={25} />
                {t('button.previewResults')}
              </ButtonPrimarySm>
            </Box>
          </Box>

          <Typography className="roboto-regular">
            We need to match our movement names exactly. If your movement is just <b>Snatch</b> for
            example, please choose <b>Power Snatch</b> or <b>Squat Snatch</b> to save the movement
            properly.
          </Typography>
        </Box>
      </Paper>

      {previewData && previewData?.length > 0 && (
        <Box mx={3} display="flex">
          <Box flexGrow={1}>
            <Typography variant="h3">{t('import.previewResults')}</Typography>
          </Box>
          <ButtonPrimary disabled={loading} p={1} onClick={() => confirmImport()}>
            <LoaderSm loading={loading} width={25} />
            <Check />
            {t('button.confirm')}
          </ButtonPrimary>
        </Box>
      )}

      <Box>
        <PreviewList previewData={previewData} loading={loading} />
      </Box>
    </Box>
  );
};
