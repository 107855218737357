import { ChatContext } from 'contexts';
import useConfirmation from 'core/useConfirmation';
import Modules from 'modules';
import useActions from 'modules/map/useActions';
import { useContext } from 'react';

export default () => {
  const chatContext = useContext(ChatContext);
  const { request } = useActions();
  const { confirmation } = useConfirmation();
  const getGroupsChats = () => {
    if (!chatContext?.ownerable?.id) {
      return;
    }
    request({
      action: Modules.conversations.actions.getGroupsChats,
      data: {
        ownerableId: chatContext?.ownerableId,
        ownerableType: chatContext?.ownerableType,
      },
    });
  };

  const getGroupsChat = ({ groupsChatId, options }) => {
    if (!chatContext?.ownerable?.id) {
      return;
    }
    request({
      action: Modules.conversations.actions.getGroupsChat,
      data: {
        groupsChatId,
        ownerableId: chatContext?.ownerableId,
        ownerableType: chatContext?.ownerableType,
      },
      options,
    });
  };

  const create = ({
    groupsChatId,
    text,
    kind,
    mediaFilesAttributes,
    objectId,
    objectType,
    parentId,
    callback,
  }) => {
    if (!text && mediaFilesAttributes?.length === 0 && !objectId) {
      return;
    }

    request({
      action: Modules.conversations.actions.createMessage,
      data: {
        ownerableId: chatContext?.ownerableId,
        ownerableType: chatContext?.ownerableType,
        groupsChatId,
        parentId,
        text,
        kind,
        mediaFilesAttributes,

        objectId,
        objectType,
      },
      options: {
        onSuccess: (data) => {
          if (callback) {
            callback(data);
          }
        },
      },
    });
  };

  const updateMessage = ({ groupsChatId, id, text, callback }) => {
    request({
      action: Modules.conversations.actions.updateMessage,
      data: {
        ownerableId: chatContext?.ownerableId,
        ownerableType: chatContext?.ownerableType,
        groupsChatId,
        id,
        text,
      },
      options: {
        onSuccess: (data) => {
          if (callback) {
            callback(data);
          }
        },
      },
    });
  };

  const deleteMessage = ({ groupsChatId, id, callback }) => {
    confirmation({
      description: 'Are you sure?',
      yesClick: () => {
        request({
          action: Modules.conversations.actions.deleteMessage,
          data: {
            ownerableId: chatContext?.ownerableId,
            ownerableType: chatContext?.ownerableType,
            groupsChatId,
            id,
          },
          options: {
            onSuccess: () => {
              if (callback) {
                callback();
              }
            },
          },
        });
      },
    });
  };

  const setReaded = ({ groupsChatId, id, callback }) => {
    request({
      action: Modules.conversations.actions.setReaded,
      data: {
        ownerableId: chatContext?.ownerableId,
        ownerableType: chatContext?.ownerableType,
        groupsChatId,
        id,
      },
      options: {
        onSuccess: () => {
          if (callback) {
            callback();
          }
        },
      },
    });
  };

  const joinGroup = ({ groupsChatId, callback }) => {
    request({
      action: Modules.conversations.actions.joinGroup,
      data: {
        ownerableId: chatContext?.ownerableId,
        ownerableType: chatContext?.ownerableType,
        groupsChatId,
      },
      options: {
        onSuccess: () => {
          if (callback) {
            callback();
          }
        },
      },
    });
  };

  const unjoinGroup = ({ groupsChatId, callback }) => {
    request({
      action: Modules.conversations.actions.unjoinGroup,
      data: {
        ownerableId: chatContext?.ownerableId,
        ownerableType: chatContext?.ownerableType,
        groupsChatId,
      },
      options: {
        onSuccess: () => {
          if (callback) {
            callback();
          }
        },
      },
    });
  };

  return {
    create,
    updateMessage,
    deleteMessage,
    getGroupsChat,
    getGroupsChats,
    joinGroup,
    setReaded,
    unjoinGroup,
  };
};
