/* eslint-disable react/prop-types */
import Box from 'core/ui/Box';
import { redColor, secondary, successColor } from 'core/ui/Colors';
import Typography, { fontStyle } from 'core/ui/Typography';
import { PaymentStatus } from 'core/utils/consts';
import { currency, date } from 'core/utils/formatters';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function PaymentStatusColumn({ row }) {
  const { t } = useTranslation();
  const falied = [PaymentStatus.failed, PaymentStatus.requires_payment_method].find(
    (x) => x === row.status,
  );
  return (
    <Box>
      <Box>
        {falied ? (
          <Box>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Box display="flex" flexDirection="column" alignItems="center">
                <Typography color="primary" variant="h6" style={fontStyle.medium}>
                  {t(`payment.stripe.status.failed`)}
                </Typography>
                <Typography variant="caption" style={fontStyle.medium}>
                  {row.error}
                </Typography>
              </Box>
            </Box>

            <Typography
              color={row?.failedResolvedAt ? 'primary' : 'error'}
              style={fontStyle.small}
              align="center"
            >
              {row?.failedResolvedAt
                ? `Payment checked as resolved ${date(row?.failedResolvedAt)}`
                : 'Not Resolved'}
            </Typography>
          </Box>
        ) : (
          <>
            {row?.offline && !row?.holdTransaction ? (
              <Typography
                color="secondary"
                variant="h6"
                style={{
                  ...fontStyle.medium,
                  color: row?.confirmedAt ? successColor : redColor,
                }}
              >
                {t(`payment.offline.status.${row?.confirmedAt ? 'paid' : 'notPaid'}`)}
              </Typography>
            ) : (
              <>
                {row.pendingHold ? (
                  <Typography color="secondary" variant="h6" style={fontStyle.medium}>
                    {t(`payment.stripe.status.holdPending`)}
                  </Typography>
                ) : (
                  <Typography
                    style={{
                      ...fontStyle.medium,
                      color: row.status === PaymentStatus.succeeded ? successColor : secondary,
                    }}
                    color="secondary"
                    variant="h6"
                  >
                    {t(`payment.stripe.status.${row.status}`)}
                  </Typography>
                )}
              </>
            )}
          </>
        )}
      </Box>

      {row?.partialRefunded > 0 && (
        <Typography
          mt={1}
          p={0.5}
          variant="caption"
          style={{
            ...fontStyle.small,
            backgroundColor: redColor,
            color: 'white',
            borderRadius: 5,
          }}
          noWrap
        >
          {t('payment.status.partialRefund')}:&nbsp;{currency(row?.refundedAmount)}
        </Typography>
      )}
    </Box>
  );
}
