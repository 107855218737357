import { useContext, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form-v5';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import { useTranslation } from 'react-i18next';
import notifications from 'modules/notifications';
import { useDispatch, useSelector } from 'react-redux';
import useSelectors from 'modules/map/useSelectors';
import { newWorkoutsModule } from 'modules/workouts/new';
import { formatDateToApi, date, parseDate } from 'core/utils/formatters';
import moment from 'moment';
import { sessionActions, sessionSelectors } from 'modules/session';
import { workoutInviteTypes } from 'core/utils/consts';
import { DashboardContext } from 'contexts';

export default ({ callback, workoutId, duplicate, dataInvite }) => {
  const { request } = useActions();
  const { t } = useTranslation();
  const currentUser = useSelector((state) => sessionSelectors.getUser(state));
  const dispatch = useDispatch();
  const loading = useSelectors(newWorkoutsModule, 'loading');
  const { register, handleSubmit, setValue, watch, reset, errors } = useForm();
  const dashboardContext = useContext(DashboardContext);
  const [submiting, setSubmiting] = useState(false);
  const [sections, setSections] = useState([]);

  const sectionsRef = useRef(sections);

  useEffect(() => {
    sectionsRef.current = sections;
  });

  const validate = () => {
    const errorsValidation = [];

    if (dataInvite?.inviteType === workoutInviteTypes.record_compare && sections?.length > 1) {
      errorsValidation.push(t('recordCompare.choose.sections'));
    }

    if (errorsValidation.length > 0) {
      dispatch(notifications.warning(errorsValidation.join(' ')));
      return false;
    }
    return true;
  };

  const onSubmit = (values) => {
    if (!validate()) {
      return;
    }

    setSubmiting(true);

    const invites = [];
    const isNew = !workoutId || duplicate;

    if (dataInvite && dataInvite.inviteType === workoutInviteTypes.live_one_to_one) {
      invites.push({
        userId: dataInvite?.user?.id,
        scheduledAt: dataInvite?.scheduledAt,
        mainUserId: dataInvite?.mainUserId,
        invited: false,
        inviteType: dataInvite.inviteType,
      });
    }

    if (
      dataInvite &&
      dataInvite.users &&
      dataInvite.inviteType !== workoutInviteTypes.live_one_to_one
    ) {
      dataInvite.users.forEach((user) => {
        invites.push({
          userId: user?.id,
          invited: false,
          scheduledAt: dataInvite?.scheduledAt,
          mainUserId: dataInvite?.mainUserId,
          inviteType: dataInvite.inviteType,
        });
      });
    }

    setTimeout(() => {
      const data = {
        ...values,
        id: !isNew ? workoutId : null,
        method: 'custom',
        durationMinutes: 60,
        coachesAttributes: [currentUser?.id],
        invitesAttributes: invites,
        sectionsAttributes: sectionsRef.current.map((s) => ({
          ...s,
          id: isNew || s.new === true || !workoutId ? null : s.id,
        })),
      };

      if (isNew) {
        data.scheduledAt = dataInvite?.scheduledAt || formatDateToApi(moment());
      }

      request({
        action: !isNew
          ? Modules.newWorkouts.actions.updateWorkout
          : Modules.newWorkouts.actions.createWorkout,
        data,
        options: {
          onSuccess: (workoutResp) => {
            if (callback) {
              dispatch(sessionActions.authenticateStart());
              callback(workoutResp);
              if (dashboardContext && dataInvite?.scheduledAt) {
                dashboardContext.setCurrentDate(
                  date(parseDate(dataInvite?.scheduledAt, 'DD-MM-YYYY'), 'YYYY-MM-DD'),
                );
                dashboardContext.setTab('myCalendar');
              }
            }
          },
        },
      });
    }, 500);
  };

  useEffect(() => {
    register('name', { required: true });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register]);

  useEffect(() => {
    if (workoutId) {
      request({
        action: Modules.newWorkouts.actions.getWorkout,
        data: { id: workoutId },
        options: {
          onSuccess: (workoutResp) => {
            setValue('name', workoutResp.name);
            if (!dataInvite?.section) {
              setSections(workoutResp?.sections.map((x) => (x.detail ? x.detail : x)));
            } else {
              setSections([dataInvite?.section]);
            }
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workoutId]);

  return {
    register,
    setValue,
    watch,
    reset,
    errors,
    onSubmit,
    handleSubmit,
    submitForm: handleSubmit(onSubmit),
    loading,
    setSections,
    sections,
    submiting,
  };
};
