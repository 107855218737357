import React, { useRef } from 'react';
import Box from 'core/ui/Box';
import { ButtonPrimary } from 'core/ui/Button';
import { useTranslation } from 'react-i18next';

import {
  ButtonGroup,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Popper,
} from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';

const MenuMembership = ({ setModalFormKey, user, channelKey, actions }) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const anchorRef = useRef();

  return (
    <Box>
      <Box>
        <ButtonGroup variant="contained" color="primary" ref={anchorRef} aria-label="split button">
          <ButtonPrimary onClick={() => setOpen(true)}>
            Actions <ArrowDropDown />
          </ButtonPrimary>
        </ButtonGroup>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          style={{ zIndex: 9999 }}
          // disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={() => setOpen(false)}>
                  <MenuList id="split-button-menu">
                    {channelKey.kind === 'membership' && (
                      <>
                        {channelKey?.offline && channelKey?.offlineType === 'post_payment' && (
                          <MenuItem
                            onClick={() => {
                              actions?.setModalAddPayment((prev) => ({
                                ...prev,
                                open: true,
                                channelKey,
                                user,
                              }));
                              setOpen(false);
                            }}
                          >
                            {t('button.membership.postPayment')}
                          </MenuItem>
                        )}
                        {channelKey?.allowSwitchPlan && actions?.setModalSwitch && (
                          <MenuItem
                            onClick={() => {
                              actions?.setModalSwitch((prev) => ({
                                ...prev,
                                open: true,
                                channelKey,
                                user,
                              }));
                              setOpen(false);
                            }}
                          >
                            {t('button.membership.switchMembership')}
                          </MenuItem>
                        )}
                        <MenuItem
                          onClick={() => {
                            setModalFormKey((prev) => ({
                              ...prev,
                              open: true,
                              mode: 'edit',
                              modeEdit: 'editAmount',
                              channelKey,
                              user,
                            }));
                            setOpen(false);
                          }}
                        >
                          {t('button.membership.editAmount')}
                        </MenuItem>

                        <MenuItem
                          onClick={() => {
                            setModalFormKey((prev) => ({
                              ...prev,
                              open: true,
                              mode: 'edit',
                              modeEdit: 'editRenewal',
                              channelKey,
                              user,
                            }));
                            setOpen(false);
                          }}
                        >
                          {t('button.membership.editRenewal')}
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            setModalFormKey((prev) => ({
                              ...prev,
                              open: true,
                              mode: 'edit',
                              modeEdit: 'editPause',
                              channelKey,
                              user,
                            }));
                            setOpen(false);
                          }}
                        >
                          {t('button.membership.editPause')}
                        </MenuItem>
                        {channelKey?.contractStartDate && (
                          <MenuItem
                            onClick={() => {
                              setModalFormKey((prev) => ({
                                ...prev,
                                open: true,
                                mode: 'edit',
                                modeEdit: 'editContract',
                                channelKey,
                                user,
                              }));
                              setOpen(false);
                            }}
                          >
                            {t('button.membership.editContract')}
                          </MenuItem>
                        )}
                        {!channelKey?.pauseAt && (
                          <MenuItem
                            onClick={() => {
                              setModalFormKey((prev) => ({
                                ...prev,
                                open: true,
                                mode: 'edit',
                                modeEdit: 'editStatus',
                                channelKey,
                                user,
                              }));
                              setOpen(false);
                            }}
                          >
                            {t('button.membership.editStatus')}
                          </MenuItem>
                        )}
                      </>
                    )}

                    {channelKey.kind !== 'membership' && (
                      <MenuItem
                        onClick={() => {
                          setModalFormKey((prev) => ({
                            ...prev,
                            open: true,
                            mode: 'edit',
                            modeEdit: 'editInPerson',
                            channelKey,
                            user,
                          }));
                          setOpen(false);
                        }}
                      >
                        {t('button.membership.editInPersonKey')}
                      </MenuItem>
                    )}

                    <MenuItem
                      onClick={() => {
                        if (!channelKey.membership) {
                          actions.removeChannelKeyFromUser(channelKey?.id);
                        } else {
                          actions.removeKey(channelKey);
                        }
                        setOpen(false);
                      }}
                    >
                      {t('button.membership.remove')}
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Box>
    </Box>
  );
};

MenuMembership.propTypes = {};

MenuMembership.defaultProps = {};

export default MenuMembership;
