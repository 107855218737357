import React, { createContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { Hidden } from '@material-ui/core';
import Grid from 'core/ui/Grid';
import { channelsSelectors } from 'modules/channels';
import Box from 'core/ui/Box';
import { ButtonPrimary } from 'core/ui/Button';
import { useHistory, useLocation, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { sessionSelectors } from 'modules/session';
import { channelUrlSlug } from 'core/utils/helpers';
import HelmetMetaData from 'core/ui/SocialMediaButtons/HelmetMetaData';
import { parse } from 'query-string';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import ContactForm from 'components/ContactForms/Widget';
import Tabs from './Tabs';
import SkeletonChannel from './Skeleton';
import Classes from './Tabs/Classes';
import Coaches from './Tabs/Coaches';
import Schedules from './Tabs/Schedules/index';
import UpcomingPrivateClasses from './Tabs/UpcomingPrivateClasses';
import Memberships from './Tabs/Memberships/index';
import ShowHeader from './ShowHeader';
import General from './General';
import Store from './Tabs/Store';
import ValidAnnouncements from '../Manage/Tabs/Announcements/ShowValid';
import SelfCheckIn from './Tabs/SelfCheckIn';
import ChannelContracts from '../ChannelContracts';

export const ShowContext = createContext();

const useStyles = makeStyles((theme) => ({
  boxButtons: {
    position: 'absolute',
    top: 10,
    right: 5,
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
    },
  },
}));

export default () => {
  const classes = useStyles();
  const { defaultTab } = useParams();
  const { request } = useActions();
  const [tab, setTab] = useState('schedules');
  const userSession = useSelector((state) => sessionSelectors.getUser(state));
  const loading = useSelector((state) => channelsSelectors.getLoadingChannel(state));
  const channel = useSelector((state) => channelsSelectors.getChannel(state));
  const history = useHistory();
  const { t } = useTranslation();
  const [refreshCalendar, setRefreshCalendar] = useState(false);
  const [accepted, setAccepted] = useState(null);

  const location = useLocation();

  const buttonColor = channel?.customColors?.buttonColor;

  useEffect(() => {
    if (defaultTab) {
      return;
    }
    const parsed = parse(location.search);
    if (parsed && parsed.tab) {
      setTab(parsed.tab);
    } else if (channel?.hideToPublic) {
      setTab('schedules');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel, userSession, setTab, location, defaultTab]);

  useEffect(() => {
    if (defaultTab) {
      setTab(defaultTab);
    }
  }, [defaultTab]);

  const adminManager = () =>
    userSession?.role === 'admin' ||
    userSession?.role === 'support' ||
    userSession?.role === 'manager';

  const goToChannel = () => {
    if (channel?.allowEdit) {
      history.push(`/channel/manage/${channel.id}`);
    } else if (adminManager()) {
      request({
        action: Modules.adminChannels.actions.addAsAdminChannel,
        data: {
          channelId: channel?.id,
        },
        options: {
          onSuccess: () => {
            history.push(`/channel/manage/${channel.id}`);
          },
        },
      });
    }
  };

  const ButtonManageChannel = () => (
    <ButtonPrimary
      buttonColor={buttonColor}
      onClick={() => goToChannel()}
      pl={3}
      pr={3}
      spacing={2}
      style={{ letterSpacing: 2 }}
    >
      {t('button.manageChannel').toUpperCase()}
    </ButtonPrimary>
  );

  const isCoach = () => {
    if (!channel.id) {
      return false;
    }
    if (channel?.user?.id === userSession.id) {
      return true;
    }
    if (channel?.coaches.find((x) => x.user && x?.user?.id === userSession.id)) {
      return true;
    }
    return false;
  };

  if (loading) {
    return <SkeletonChannel />;
  }

  return (
    <ShowContext.Provider value={{ tab, setTab, channel, loading, isCoach }}>
      {!accepted && !!channel?.documentWaiverId && (
        <Box p={3}>
          <ChannelContracts
            channel={channel}
            userId={userSession.id}
            ownerableType="Channel"
            ownerableId={channel?.id}
            kind="waivers"
            buttonColor={buttonColor}
            documentId={channel.documentWaiverId}
            setAccepted={setAccepted}
          />
        </Box>
      )}

      <HelmetMetaData
        url={channelUrlSlug(channel)}
        title={channel.name}
        description={channel.description}
        image={channel.image && channel.image.image.url}
      />

      {channel && <ShowHeader buttonColor={buttonColor} />}

      <Box flexGrow="1" mt={3} style={{ position: 'relative' }}>
        <Tabs />
        {(!!channel.allowEdit || adminManager()) && (
          <Box className={classes.boxButtons}>
            <Hidden only={['xs', 'sm']}>
              <ButtonManageChannel />
            </Hidden>
          </Box>
        )}
      </Box>

      <Hidden only={['md', 'lg', 'xl']}>
        <Box mt={3}>
          <General />
        </Box>
      </Hidden>

      <Box>
        {!!channel.allowEdit && (
          <Hidden only={['md', 'lg', 'xl']}>
            <Box pt={3} mr={1} display="flex" justifyContent="flex-end">
              <ButtonManageChannel />
            </Box>
          </Hidden>
        )}
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {channel && <ValidAnnouncements mt={3} channelId={channel.id} />}

            {tab === 'classes' && <Classes />}
            {tab === 'privateClasses' && <UpcomingPrivateClasses />}
            {tab === 'coaches' && <Coaches />}

            {channel && <>{tab === 'memberships' && <Memberships channel={channel} />}</>}
            {channel && <>{tab === 'store' && <Store />}</>}
            {channel && channel?.allowEdit && <>{tab === 'selfCheckIn' && <SelfCheckIn />}</>}

            {tab === 'schedules' && (
              <Schedules
                channel={channel}
                isCoach={isCoach()}
                showQuickAdd
                refreshCalendar={refreshCalendar}
                setRefreshCalendar={setRefreshCalendar}
              />
            )}
          </Grid>
        </Grid>
      </Box>
      <ContactForm />
    </ShowContext.Provider>
  );
};
