import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Box from 'core/ui/Box';
import gifLoading from 'assets/img/loading-green.gif';
import Typography from 'core/ui/Typography';
import { sessionWorkoutActions, sessionWorkoutSelectors } from 'modules/sessionWorkout';
import { workoutsSelectors } from 'modules/workouts';
import { ButtonPrimary, ButtonPrimarySm } from 'core/ui/Button';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import { roomActions, roomSelectors } from 'modules/room';
import FontIcon from 'core/ui/FontIcon';
import {
  DialogContent,
  FormControlLabel,
  Checkbox,
  IconButton,
  Popper,
  ClickAwayListener,
} from '@material-ui/core';
import useEvents from 'core/openVidu/useEvents';
import { Close, MicRounded, VolumeUp, Warning } from '@material-ui/icons';
import LoaderSm from 'core/ui/LoaderSm';
import useValidationBrowsers from 'core/utils/hook/useValidationBrowsers';
import notifications from 'modules/notifications';
import { isSafari } from 'react-device-detect';
import Paper from 'core/ui/Paper';
import useAudioMeter from './useAudioMeter';

export const Context = React.createContext();

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#f3f3f3',
  },
  btnClose: {
    position: 'absolute',
    right: 0,
  },
}));

export default function ModalConfg({ modal, setModal, OV }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { changeDevice, exit } = useEvents();
  const workout = useSelector((state) => workoutsSelectors.getWorkout(state));
  const configRoom = useSelector((state) => roomSelectors.getConfig(state));
  const workoutLoading = useSelector((state) => workoutsSelectors.getLoading(state));
  const token = useSelector((state) => sessionWorkoutSelectors.getToken(state));
  const loadingSessionWorkout = useSelector((state) => sessionWorkoutSelectors.getLoading(state));
  const { validateBrowserStream } = useValidationBrowsers();
  const micRef = useRef();
  const spkRef = useRef();
  const [config, setConfig] = useState({
    status: '',
    deviceAudio: {
      deviceId: '',
    },
    deviceVideo: {
      deviceId: '',
    },
    deviceSpeaker: {
      deviceId: '',
    },
    audioActive: true,
    videoActive: true,
    showMyCam: true,
  });
  const {
    setOpenMicStatus,
    openMicStatus,
    micTest,
    volume,
    testSpeaker,
    openSpeakerStatus,
    setOpenSpeakerStatus,
  } = useAudioMeter(config);

  const [devices, setDevices] = useState([]);
  const [permision, setPermision] = useState({
    allow: false,
    loading: true,
  });

  const getPermisions = () => {
    const successCallback = () => {
      setPermision({
        allow: true,
        loading: false,
      });
    };

    const errorCallback = () => {
      setPermision({
        allow: false,
        loading: false,
      });
    };
    const constraints = { audio: {}, video: {} };
    navigator.mediaDevices.getUserMedia(constraints).then(successCallback, errorCallback);
  };

  const getConnectedDevices = (callback) => {
    if (navigator.mediaDevices) {
      navigator.mediaDevices.enumerateDevices().then((dvs) => {
        callback(dvs);
      });
    }
  };

  useEffect(() => {
    const validation = validateBrowserStream();
    if (validation === true) {
      getPermisions();
    }
  }, [validateBrowserStream]);

  useEffect(() => {
    let isSubscribed = true;
    if (!OV || !permision) {
      return;
    }

    getConnectedDevices((dvs) => {
      if (isSubscribed) {
        setDevices(dvs);
        if (dvs.filter((d) => d.label !== '').length === 0) {
          setConfig((prev) => ({
            ...prev,
            deviceAudio: {
              deviceId: undefined,
            },
            deviceVideo: {
              deviceId: undefined,
            },
            deviceSpeaker: {
              deviceId: undefined,
            },
          }));
          return;
        }
        setConfig((prev) => ({
          ...prev,
          deviceSpeaker: dvs ? dvs.find((d) => d.kind === 'audiooutput') || {} : undefined,
          deviceAudio: dvs ? dvs.find((d) => d.kind === 'audioinput') : undefined,
          deviceVideo: dvs ? dvs.find((d) => d.kind === 'videoinput') : undefined,
        }));
      }
    });
    return () => (isSubscribed = false); //eslint-disable-line
  }, [OV, permision, modal.open]);

  const refresh = () => {
    if (!config.deviceAudio.deviceId || !config.deviceVideo.deviceId) {
      dispatch(notifications.warning(t('workout.config.selectYourMicrophoneAndCamera')));
      // return;
    }

    if (workout.id) {
      dispatch(sessionWorkoutActions.createStart(workout.id, workout?.inviteSession?.id));
    }
  };

  useEffect(() => {
    if (token) {
      if (token.token) {
        setModal((prevState) => ({ ...prevState, open: false }));
        setConfig((prev) => ({
          ...prev,
          status: '',
        }));
      } else {
        setConfig((prev) => ({
          ...prev,
          status: 'offline',
        }));
      }
    }
  }, [token, setModal]);

  const confirm = () => {
    setOpenMicStatus(false);
    dispatch(roomActions.setConfig({ ...configRoom, ...config }));
    if (modal.edit === true) {
      setModal((prevState) => ({ ...prevState, open: false }));
    } else {
      setTimeout(() => {
        refresh();
      }, 300);
    }
  };

  const setDevice = (field, devId) => {
    const device = devices.find((d) => d.deviceId === devId);
    setConfig({ ...config, [field]: device });
    if (modal.edit === true) {
      changeDevice(devId, field === 'deviceAudio' ? 'audio' : 'video');
    }
  };

  return (
    <div>
      <Dialog open={modal.open}>
        {modal.showClose === true ? (
          <Box
            className={classes.btnClose}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
          >
            <IconButton
              aria-label="close"
              onClick={() => setModal((prevState) => ({ ...prevState, open: false }))}
            >
              <Close />
            </IconButton>
          </Box>
        ) : (
          <Box
            className={classes.btnClose}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
          >
            {!loadingSessionWorkout && (
              <IconButton aria-label="close" onClick={() => exit()}>
                <Close />
              </IconButton>
            )}
          </Box>
        )}
        <DialogContent className={classes.root}>
          <Box style={{ minWidth: 250 }} display="flex" flexDirection="column">
            {validateBrowserStream() === true &&
              config.status === '' &&
              !loadingSessionWorkout &&
              permision.allow === true && (
                <Box mt={1} flexGrow="1">
                  {(modal.edit !== true || modal.device === 'microphone') && (
                    <Box py={2} display="flex" alignItems="flex-end">
                      <Box flexGrow="1">
                        <SelectBoxObject
                          options={devices.filter((x) => x.kind === 'audioinput')}
                          propValue="deviceId"
                          propLabel="label"
                          label={t('stream.config.label.deviceAudio')}
                          setValue={(value) => setDevice('deviceAudio', value)}
                          value={config.deviceAudio.deviceId}
                        />
                      </Box>
                      <Box ml={1} pb={0.5}>
                        <ClickAwayListener onClickAway={() => setOpenMicStatus(false)}>
                          <Box>
                            <ButtonPrimarySm ref={micRef} onClick={() => micTest()}>
                              <MicRounded />
                            </ButtonPrimarySm>
                            <Popper
                              open={openMicStatus}
                              anchorEl={micRef.current}
                              role={undefined}
                              transition
                              disablePortal
                              style={{ zIndex: 10, width: '100%' }}
                            >
                              <Paper p={5}>
                                <Box display="flex" flexDirection="column" alignItems="center">
                                  <Typography my={3} variant="body2">
                                    {t('workout.mic.test')}
                                  </Typography>

                                  <Box mx={5} mb={2} display="flex" alignItems="center">
                                    <FontIcon
                                      iconName="icon-microphone-on"
                                      size={15}
                                      color="#4fb94a"
                                    />
                                    <Box
                                      ml={1}
                                      flexGrow="1"
                                      style={{
                                        height: 15,
                                        width: '150px',
                                        background: 'white',
                                        borderRadius: 5,
                                      }}
                                    >
                                      <Box
                                        style={{
                                          background: 'green',
                                          height: 15,
                                          width: `${volume || 0}%`,
                                          borderRadius: 5,
                                          display: 'block',
                                        }}
                                      >
                                        &nbsp;
                                      </Box>
                                    </Box>
                                  </Box>

                                  <Box display="fex" pb={0.5}>
                                    <ButtonPrimarySm m={1} onClick={() => setOpenMicStatus(false)}>
                                      {t('button.close')}
                                    </ButtonPrimarySm>
                                  </Box>
                                </Box>
                              </Paper>
                            </Popper>
                          </Box>
                        </ClickAwayListener>
                      </Box>
                    </Box>
                  )}
                  {(modal.edit !== true || modal.device === 'speaker') &&
                    !isSafari &&
                    devices.find((x) => x.kind === 'audiooutput') && (
                      <Box py={2} display="flex" alignItems="flex-end">
                        <Box flexGrow="1">
                          <SelectBoxObject
                            options={devices.filter((x) => x.kind === 'audiooutput')}
                            propValue="deviceId"
                            propLabel="label"
                            label={t('stream.config.label.deviceSpeaker')}
                            setValue={(value) => setDevice('deviceSpeaker', value)}
                            value={config.deviceSpeaker.deviceId || ''}
                          />
                        </Box>
                        <Box ml={1} pb={0.5}>
                          <ClickAwayListener onClickAway={() => setOpenSpeakerStatus(false)}>
                            <Box>
                              <ButtonPrimarySm ref={spkRef} onClick={() => testSpeaker()}>
                                <VolumeUp />
                              </ButtonPrimarySm>
                              <Popper
                                open={openSpeakerStatus}
                                anchorEl={spkRef.current}
                                role={undefined}
                                transition
                                disablePortal
                                style={{ zIndex: 10 }}
                              >
                                <Paper p={5}>
                                  <Box display="flex" flexDirection="column" alignItems="center">
                                    <Typography my={3} variant="body2">
                                      {t('workout.speaker.test')}
                                    </Typography>
                                    <Box display="fex" pb={0.5}>
                                      <ButtonPrimarySm
                                        m={1}
                                        onClick={() => setOpenSpeakerStatus(false)}
                                      >
                                        {t('button.close')}
                                      </ButtonPrimarySm>
                                    </Box>
                                  </Box>
                                </Paper>
                              </Popper>
                            </Box>
                          </ClickAwayListener>
                        </Box>
                      </Box>
                    )}
                  {(modal.edit !== true || modal.device === 'cam') && (
                    <Box py={2}>
                      <SelectBoxObject
                        options={devices.filter((x) => x.kind === 'videoinput')}
                        propValue="deviceId"
                        propLabel="label"
                        label={t('stream.config.label.deviceVideo')}
                        setValue={(value) => setDevice('deviceVideo', value)}
                        value={config.deviceVideo.deviceId}
                      />
                    </Box>
                  )}
                  {modal.edit !== true && (
                    <Box py={2} display="flex" justifyContent="center">
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={config.audioActive}
                            onChange={() =>
                              setConfig({ ...config, audioActive: !config.audioActive })
                            }
                            name="audioActive"
                          />
                        }
                        label={t('stream.config.label.enableAudio')}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={config.videoActive}
                            onChange={() =>
                              setConfig({ ...config, videoActive: !config.videoActive })
                            }
                            name="videoActive"
                          />
                        }
                        label={t('stream.config.label.enableVideo')}
                      />
                    </Box>
                  )}
                  <Box py={2}>
                    <ButtonPrimary
                      disabled={workoutLoading}
                      style={{ width: '100%' }}
                      onClick={() => confirm()}
                    >
                      <Box display="flex" alignItems="center">
                        <LoaderSm loading={workoutLoading} width={20} />
                        {!workoutLoading && (
                          <FontIcon iconName="icon-play" size={14} color="#fff" />
                        )}
                        <Typography ml={1} component="span">
                          {t(workoutLoading ? 'loading' : 'stream.config.confirm')}
                        </Typography>
                      </Box>
                    </ButtonPrimary>
                  </Box>
                </Box>
              )}

            {validateBrowserStream() === true && permision.allow === false && permision.loading && (
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <LoaderSm loading width={20} />
                <Box display="flex" mt={3} flexDirection="column" justifyContent="center">
                  <Typography>{t('grantAccessDevices')}</Typography>
                </Box>
              </Box>
            )}

            {validateBrowserStream() === true &&
              permision.allow === false &&
              !permision.loading && (
                <Box display="flex" justifyContent="center" alignItems="center" p={5}>
                  <Box display="flex" flexDirection="column" justifyContent="center">
                    <Typography ml={3}>{t('grantNotPermitedAccessDevices')}</Typography>
                  </Box>
                </Box>
              )}

            {validateBrowserStream() !== true && (
              <Box display="flex" alignItems="center">
                <Warning color="primary" />
                <Typography ml={2} component="span">
                  {validateBrowserStream()}
                </Typography>
              </Box>
            )}

            <Box
              style={{ height: '100%' }}
              display="flex"
              justifyContent="center"
              alignItems="center"
              my={2}
            >
              {loadingSessionWorkout && (
                <>
                  <img src={gifLoading} alt="loading" style={{ width: 30 }} />
                  <Typography ml={3}>
                    {t(
                      workout.isCoach
                        ? 'stream.config.creatingSession'
                        : 'stream.config.connectingToSession',
                    )}
                  </Typography>
                </>
              )}
              {config.status === 'offline' && !loadingSessionWorkout && (
                <Box display="flex" flexDirection="column" justifyContent="center">
                  <Box mb={5}>
                    <Typography ml={3} variant="h4" align="center">
                      {t('stream.config.sessionOffline')}
                    </Typography>
                  </Box>
                  <Box>
                    <ButtonPrimary onClick={() => refresh()} style={{ width: '100%' }}>
                      {t('stream.config.refresh')}
                    </ButtonPrimary>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

ModalConfg.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  modal: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  setModal: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  OV: PropTypes.object,
};

ModalConfg.defaultProps = {
  modal: {},
  setModal: null,
  OV: null,
};
