import shopifyImg from 'assets/img/integrations/shopify.png';
import hybridafImg from 'assets/img/integrations/hybridaf.png';
import zapierImg from 'assets/img/integrations/zapier.png';
import sifelyImg from 'assets/img/sifely.png';
import cardpointeImg from 'assets/img/cardpointe.png';
import stripeImg from 'assets/img/stripeLogo.png';

export const US_COUNTRY_ID = 16;
export const acceptImageTypes = '.png,.jpg,.jpeg';

export const B_ID = 'AKIAR2TQZ4WDYGHQYWXN';

export const membersTypes = ['user', 'membership_active', 'membership_inactive', 'child', 'lead'];

export const typeTimer = {
  tabata: 'tabata',
  emom: 'emom',
  interval: 'interval',
  forTime: 'forTime',
  amrap: 'amrap',
};

export const usersImportToolTypes = {
  wodify: 'wodify',
  sugarwod: 'sugarwod',
};

export const channelsRatesTemplatesTypes = {
  hourly: 'hourly',
  percent_by_class: 'percent_by_class',
  price_by_class: 'price_by_class',
  percent_by_membership: 'percent_by_membership',
  percent_by_product: 'percent_by_product',
};

export const documentSectionsTypes = [
  'pdf',
  'paragraph',
  'text',
  'radio',
  'checkbox',
  'dropdown',
  'signature',
];

export const integrationTypes = [
  {
    id: 'shopify',
    name: 'Shopify',
    img: shopifyImg,
  },
  {
    id: 'hybrid',
    name: 'HybridAF',
    img: hybridafImg,
  },
  {
    id: 'zapier',
    name: 'Zapier',
    img: zapierImg,
  },
  {
    id: 'sifely',
    name: 'Sifely',
    img: sifelyImg,
  },

  {
    id: 'cardpointe',
    name: 'CardPointe',
    img: cardpointeImg,
    type: 'gateway',
  },
  {
    id: 'stripe',
    name: 'Stripe',
    img: stripeImg,
    type: 'gateway',
  },
];

export const usStatesTypes = [
  'AB',
  'AE',
  'AK',
  'AL',
  'AR',
  'AS',
  'AZ',
  'BC',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'FL',
  'FM',
  'GA',
  'GU',
  'HI',
  'IA',
  'ID',
  'IL',
  'IN',
  'KS',
  'KY',
  'LA',
  'MA',
  'MB',
  'MD',
  'ME',
  'MH',
  'MI',
  'MN',
  'MO',
  'MP',
  'MS',
  'MT',
  'NB',
  'NC',
  'ND',
  'NE',
  'NH',
  'NJ',
  'NL',
  'NM',
  'NS',
  'NT',
  'NU',
  'NV',
  'NY',
  'OH',
  'OK',
  'ON',
  'OR',
  'PA',
  'PE',
  'PR',
  'PW',
  'QC',
  'RI',
  'SC',
  'SD',
  'SK',
  'TN',
  'TX',
  'UT',
  'VA',
  'VI',
  'VT',
  'WA',
  'WI',
  'WV',
  'WY',
  'Y',
];

export const cardpointeBabkTypes = [
  {
    code: 'BIZ',
    name: 'Business Checking',
  },
  {
    code: 'GL',
    name: 'General Ledger',
  },
  {
    code: 'SAVINGS',
    name: 'Savings',
  },
];

export const Z2 = '+pXRGFNZcIR2jvfGM';
export const workoutInviteTypes = {
  live_one_to_one: 'live_one_to_one',
  share_scores: 'share_scores',
  recording_watch: 'recording_watch',
  normal: 'normal',
  record_compare: 'record_compare',
};

export const automatedMessageTypes = {
  normal: 'email_custom_onetime',
  recurring: 'email_custom_recurring',
  member_anniversary: 'email_member_anniversary',
  first_membership: 'email_first_membership',
  registration_lapse: 'email_registration_lapse',
  total_classes_range: 'email_total_classes_range',
  total_classes_overall: 'email_total_classes_overall',
  first_visit: 'email_member_first_visit',
};

export const automatedRecurringMessageTypes = {
  custom: 'custom',
};

export const timers = [
  { id: 'none', name: 'NONE' },
  { id: 'tabata', name: 'TABATA' },
  { id: 'emom', name: 'EMOM' },
  { id: 'interval', name: 'INTERVAL' },
  { id: 'forTime', name: 'FOR TIME' },
  { id: 'amrap', name: 'AMRAP' },
];

export const scoresTypes = [
  'time',
  'rounds',
  'reps',
  'round_reps',
  'weight',
  'calories',
  'distance',
  'tie_break',
];

export const defaultScoresVariations = [
  {
    id: 'prescribed',
    name: 'RX',
  },
  {
    id: 'scaled',
    name: 'Scaled',
  },
];

export const channelAccessTypes = [
  {
    value: 'admin',
    label: 'Admin',
  },
  {
    value: 'admin_coach',
    label: 'Admin/Coach',
  },
  {
    value: 'coach_head',
    label: 'Head Coach',
  },
  {
    value: 'coach',
    label: 'Coach',
  },
];

export const streamCosts = {
  PERC_STREAM_COMPETITION: 0.9,
  PERC_STREAM_LIST: 0.8,

  FREE_CLASS_COST_FIRST_20: 1,
  FREE_CLASS_COST_20_50: 0.5,
  FREE_CLASS_COST_50: 0.4,
};

export const workoutEvent = {
  join: 'join',
  leave: 'leave',
  start_timer: 'start_timer',
  reset_timer: 'reset_timer',
  stop_timer: 'stop_timer',
  change_section: 'change_section',
  start_recording: 'start_recording',
  stop_recording: 'stop_recording',
  done_section: 'done_section',
};

export const typeDistance = [
  {
    id: 'kilometers',
  },
  {
    id: 'miles',
  },
  {
    id: 'yards',
  },
  {
    id: 'meters',
  },
  {
    id: 'centimeters',
  },
  {
    id: 'inches',
  },
  {
    id: 'feet',
  },
];

export const typeWeight = [
  {
    id: 'pounds',
  },
  {
    id: 'kilograms',
  },
  {
    id: 'grams',
  },
];

export const typeMinMax = [
  {
    id: 'calories',
  },
  {
    id: 'reps',
  },
];

export const boxTimerColor = {
  work: {
    bg: 'rgba(255, 255, 255, 1)',
    font: '#000',
    fontPlaceHolder: '#f2f2f2',
  },
  rest: {
    bg: 'rgba(255, 255, 255, 1)',
    font: '#000',
    fontPlaceHolder: '#f2f2f2',
  },
  countdown: {
    bg: 'rgba(255, 255, 255, 1)',
    font: '#000',
    fontPlaceHolder: '#ff2f2f2ff',
  },
  stop: {
    bg: 'rgba(255, 255, 255, 1)',
    font: '#000',
    fontPlaceHolder: '#f2f2f2',
  },
};

export const typesFilters = [
  {
    id: 'Workout',
    name: 'class',
  },
  {
    id: 'User',
    name: 'coach',
  },
  {
    id: 'Channel',
    name: 'channel',
  },
];

export const dayWeekFilters = [
  {
    id: 'sunday',
    name: 'dayOfWeek.sunday',
  },
  {
    id: 'monday',
    name: 'dayOfWeek.monday',
  },
  {
    id: 'tuesday',
    name: 'dayOfWeek.tuesday',
  },
  {
    id: 'wednesday',
    name: 'dayOfWeek.wednesday',
  },
  {
    id: 'thursday',
    name: 'dayOfWeek.thursday',
  },
  {
    id: 'friday',
    name: 'dayOfWeek.friday',
  },
  {
    id: 'saturday',
    name: 'dayOfWeek.saturday',
  },
];

export const rangeDateFilters = [
  {
    id: 'all',
    name: 'All',
  },
  {
    id: 'week',
    name: 'This week',
  },
  {
    id: 'next_week',
    name: 'Next week',
  },
  {
    id: 'month',
    name: 'This Month',
  },
];

export const durationWorkout = [
  {
    name: '15 Mins',
    id: '15',
  },
  {
    name: '20 Mins',
    id: '20',
  },
  {
    name: '30 Mins',
    id: '30',
  },
  {
    name: '45 Mins',
    id: '45',
  },
  {
    name: '1 Hr',
    id: '60',
  },
  {
    name: '1hr 15min',
    id: '75',
  },
  {
    name: '1 1/2 Hrs',
    id: '90',
  },
  {
    name: '2 Hrs',
    id: '120',
  },
];

export const competitionEventType = {
  individual: 'individual',
  team: 'team',
};

export const repMaxTypes = [
  'repMax1',
  'repMax2',
  'repMax3',
  'repMax4',
  'repMax5',
  'repMax6',
  'repMax7',
  'repMax8',
  'repMax9',
  'repMax10',
];
export const prScoreTypes = [
  {
    id: 'min_max',
    name: 'Min.Max',
  },
  {
    id: 'unbroken',
    name: 'Unbroken',
  },
  {
    id: 'max_distance',
    name: 'Max Distance',
  },
  {
    id: 'rep_max',
    name: 'Rep Max',
  },
  {
    id: 'weight',
    name: 'Weight',
  },
  {
    id: 'time',
    name: 'Time',
  },
];

export const PRPercents = [105, 100, 95, 90, 85, 80, 75, 70, 60, 65, 55, 50, 45, 40, 35, 30];

export const PaymentStatus = {
  processing: 'processing',
  succeeded: 'succeeded',
  canceled: 'canceled',
  requires_payment_method: 'requires_payment_method',
  requires_confirmation: 'requires_confirmation',
  requires_action: 'requires_action',
  refunded: 'refunded',
  failed: 'failed',
};

export const PaymentOfflineTypes = {
  cash: 'cash',
  bank_draft: 'bank_draft',
  check: 'check',
  other: 'other',
};

export const K1 = 'N65WliaW8KFMyVDSsJND9PF';

export const mapsStyles = {
  light: [
    {
      featureType: 'administrative',
      elementType: 'labels.text',
      stylers: [
        {
          visibility: 'on',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'transit.station.rail',
      elementType: 'all',
      stylers: [
        {
          visibility: 'simplified',
        },
        {
          saturation: '-100',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'all',
      stylers: [
        {
          visibility: 'on',
        },
      ],
    },
  ],

  dark: [
    {
      featureType: 'all',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'on',
        },
      ],
    },
    {
      featureType: 'all',
      elementType: 'labels.text.fill',
      stylers: [
        {
          saturation: 36,
        },
        {
          color: '#000000',
        },
        {
          lightness: 40,
        },
      ],
    },
    {
      featureType: 'all',
      elementType: 'labels.text.stroke',
      stylers: [
        {
          visibility: 'on',
        },
        {
          color: '#000000',
        },
        {
          lightness: 16,
        },
      ],
    },
    {
      featureType: 'all',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'administrative',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#000000',
        },
        {
          lightness: 20,
        },
      ],
    },
    {
      featureType: 'administrative',
      elementType: 'geometry.stroke',
      stylers: [
        {
          color: '#000000',
        },
        {
          lightness: 17,
        },
        {
          weight: 1.2,
        },
      ],
    },
    {
      featureType: 'administrative.country',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#838383',
        },
      ],
    },
    {
      featureType: 'administrative.locality',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#c4c4c4',
        },
      ],
    },
    {
      featureType: 'administrative.neighborhood',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#aaaaaa',
        },
      ],
    },
    {
      featureType: 'landscape',
      elementType: 'geometry',
      stylers: [
        {
          color: '#000000',
        },
        {
          lightness: 20,
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [
        {
          color: '#000000',
        },
        {
          lightness: 21,
        },
        {
          visibility: 'on',
        },
      ],
    },
    {
      featureType: 'poi.business',
      elementType: 'geometry',
      stylers: [
        {
          visibility: 'on',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#6e6e6e',
        },
        {
          lightness: '0',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.stroke',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#ffffff',
        },
      ],
    },
    {
      featureType: 'road.arterial',
      elementType: 'geometry',
      stylers: [
        {
          color: '#000000',
        },
        {
          lightness: 18,
        },
      ],
    },
    {
      featureType: 'road.arterial',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#575757',
        },
      ],
    },
    {
      featureType: 'road.arterial',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#ffffff',
        },
      ],
    },
    {
      featureType: 'road.arterial',
      elementType: 'labels.text.stroke',
      stylers: [
        {
          color: '#2c2c2c',
        },
      ],
    },
    {
      featureType: 'road.local',
      elementType: 'geometry',
      stylers: [
        {
          color: '#000000',
        },
        {
          lightness: 16,
        },
      ],
    },
    {
      featureType: 'road.local',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#999999',
        },
      ],
    },
    {
      featureType: 'transit',
      elementType: 'geometry',
      stylers: [
        {
          color: '#000000',
        },
        {
          lightness: 19,
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [
        {
          color: '#000000',
        },
        {
          lightness: 17,
        },
      ],
    },
  ],
};
