import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import { Container } from '@material-ui/core';
import { LayoutContext } from 'pages/shared/Layout';
import Texture from 'core/ui/Texture';
import AdminImport from 'components/Admin/Import';
import AdminImportCardpointe from 'components/Admin/Import/ImportCardpointe';

export default () => {
  const { t } = useTranslation();

  const layoutContext = useContext(LayoutContext);
  useEffect(() => {
    layoutContext.setTitleBar(t('title.admin.import.tool').toUpperCase());
    layoutContext.setActiveMenu('adminImport');
  }, [layoutContext, t]);

  return (
    <Texture fullHeight>
      <Container maxWidth="xl">
        <Box py={5}>
          <AdminImport />
        </Box>
        <Box py={5}>
          <AdminImportCardpointe />
        </Box>
      </Container>
    </Texture>
  );
};
