import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import Paper from 'core/ui/Paper';
import Typography from 'core/ui/Typography';
import { ButtonPrimary } from 'core/ui/Button';
import { useDispatch } from 'react-redux';
import LoaderSm from 'core/ui/LoaderSm';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import notifications from 'modules/notifications';
import Modules from 'modules';
import useActions from 'modules/map/useActions';
import useSelectors from 'modules/map/useSelectors';
import { newCompetitionsModule } from 'modules/competitions/new';

export default function RegistrationCoupon({
  competition,
  eventType,
  coupon,
  setCoupon,
  buttonColor,
}) {
  const { t } = useTranslation();
  const { request } = useActions();
  const loadingCoupon = useSelectors(newCompetitionsModule, 'loadingCoupon');
  const [textCoupon, setTextCoupon] = useState('');

  const dispatch = useDispatch();

  const applyCode = () => {
    if (!textCoupon) {
      return;
    }

    request({
      action: Modules.newCompetitions.actions.getDiscount,
      data: {
        competitionId: competition?.id,
        code: textCoupon,
        eventType,
      },
      options: {
        onSuccess: (data) => {
          if (data) {
            setCoupon({
              code: textCoupon,
              newPrice: data.newPrice,
            });
          } else {
            setCoupon(null);
          }
        },
        onError: (error) => {
          dispatch(notifications.error(error));
        },
      },
    });
  };

  const clearCoupon = () => {
    setTextCoupon('');
    setCoupon(null);
  };

  return (
    <Paper className="paper-rounded" p={3}>
      <Typography align="center">{t('channel.membership.discountCode')}</Typography>
      <Box display="flex" mt={2}>
        <TextFieldRounded
          placeholder={t('channel.membership.code.placeholder')}
          value={textCoupon}
          onChange={(v) => setTextCoupon(v)}
          disabled={!!coupon}
        />
        <ButtonPrimary
          onClick={() => (!coupon ? applyCode() : clearCoupon())}
          style={{ width: 100, height: 30 }}
          buttonColor={buttonColor}
        >
          {loadingCoupon ? (
            <LoaderSm loading width={25} />
          ) : (
            <>{t(!coupon ? 'button.apply' : 'button.clear')}</>
          )}
        </ButtonPrimary>
      </Box>
    </Paper>
  );
}

RegistrationCoupon.defaultProps = {
  open: false,
  close: null,
  callback: () => {},
};
