import api from 'core/api';

export const channelsCustomPaymentsUsersModule = 'channelsCustomPaymentsUsers';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const actions = {
  postChannelsCustomPaymentsUsers: {
    module: channelsCustomPaymentsUsersModule,
    name: 'postChannelsCustomPaymentsUsers',
    api: (data) => api.post(`/channels/${data?.channelId}/channels_custom_payments_users`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  destroy: {
    module: channelsCustomPaymentsUsersModule,
    name: 'destroy',
    api: (data) =>
      api.delete(`/channels/${data?.channelId}/channels_custom_payments_users/${data?.id}`),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  getChannelsCustomPaymentsUsers: {
    module: channelsCustomPaymentsUsersModule,
    name: 'getChannelsCustomPaymentsUsers',
    api: (params) =>
      api.get(`/channels/${params?.channelId}/channels_custom_payments_users`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: ['data'],
    },
    state: loadingStates,
  },
};

export default {
  actions,
  state: {
    data: [],
    error: null,
    loading: false,
  },
};
