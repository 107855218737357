import React, { useEffect, useMemo } from 'react';
import { Search } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Modules from 'modules';
import { adminReportsModule } from 'modules/adminReports';
import useActions from 'modules/map/useActions';
import useSelectors from 'modules/map/useSelectors';
import { ButtonPrimarySm } from 'core/ui/Button';
import NeoDatePickerRounded from 'core/ui/DatePickers/NeoDatePickerRounded';

import { Table } from './Table';
import { HintTable } from './HintTable';

const actualDate = new Date();
actualDate.setTime(actualDate.getTime() - 90 * 24 * 60 * 60 * 1000 /* 90 days */);

const schema = yup.object({
  startDate: yup.date().required(),
});

const OnboardingReport = () => {
  const { control, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { startDate: actualDate },
  });
  const { t } = useTranslation();

  const onboarding = useSelectors(adminReportsModule, 'onboarding');

  const { request } = useActions();

  const handleSubmitReport = useMemo(
    () =>
      handleSubmit(({ startDate }) => {
        request({ action: Modules.adminReports.actions.onboarding, data: { startDate } });
      }),
    [handleSubmit, request],
  );

  useEffect(() => {
    handleSubmitReport();
  }, [handleSubmitReport]);

  return (
    <div>
      <div className="tw-my-2 tw-flex tw-items-end tw-justify-between tw-gap-2">
        <HintTable />

        <div className="tw-flex tw-items-end tw-gap-2">
          <Controller
            control={control}
            name="startDate"
            render={({ field, fieldState }) => (
              <NeoDatePickerRounded
                label={t('label.startDate')}
                required
                usFormat
                disableFuture
                value={field.value}
                onChange={field.onChange}
                error={fieldState.error}
              />
            )}
          />

          <ButtonPrimarySm onClick={handleSubmitReport}>
            <Search style={{ color: 'white' }} fontSize="small" />
            {t('button.search')}
          </ButtonPrimarySm>
        </div>
      </div>

      {onboarding && <Table onboarding={onboarding} handleSubmitReport={handleSubmitReport} />}
    </div>
  );
};

export default OnboardingReport;
