import { useCallback } from 'react';
import { isMobile, isIOS, isSafari, isFirefox, isEdge, isIE, isChrome } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

// Hook
export default function useValidationBrowsers() {
  const { t } = useTranslation();

  const validateBrowserStream = useCallback(() => {
    if (isMobile && isIOS && !isSafari) {
      return t('workout.stream.validation.ios');
    }
    return true;
  }, [t]);

  const validateSpotify = useCallback(() => {
    if (isMobile) {
      return false;
    }

    if (isSafari) {
      return t('workout.stream.validation.spotify.mobile');
    }

    if (isFirefox || isEdge || isIE || isChrome) {
      return true;
    }

    return false;
  }, [t]);

  return { validateBrowserStream, validateSpotify };
}
