import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

import * as selectors from './selectors';
import * as sagas from './sagas';

/* ---------- Action Creators and Types ---------- */
const { Types, Creators } = createActions(
  {
    getPaymentsError: ['error'],
    getPaymentsStart: [],
    getPaymentsSuccess: ['payments'],

    purchaseChannelPlanError: ['error'],
    purchaseChannelPlanStart: ['data', 'callback'],
    purchaseChannelPlanSuccess: [],

    purchaseChannelKeyError: ['error'],
    purchaseChannelKeyStart: ['data'],
    purchaseChannelKeySuccess: [],

    purchaseChannelListError: ['error'],
    purchaseChannelListStart: ['data'],
    purchaseChannelListSuccess: [],

    purchaseCoachScheduleSessionError: ['error'],
    purchaseCoachScheduleSessionStart: ['data', 'callback'],
    purchaseCoachScheduleSessionSuccess: [],

    purchaseChannelStoreProductError: ['error'],
    purchaseChannelStoreProductStart: ['data', 'callback'],
    purchaseChannelStoreProductSuccess: [],

    purchaseWorkoutToMemberError: ['error'],
    purchaseWorkoutToMemberStart: ['data', 'callback', 'onError'],
    purchaseWorkoutToMemberSuccess: [],

    retryPaymentError: ['error'],
    retryPaymentStart: ['data', 'callback'],
    retryPaymentSuccess: [],
  },
  { prefix: 'payments/' },
);

/* ---------- Initial State ---------- */
const INITIAL_STATE = Immutable({
  data: [],
  error: null,
  loading: false,

  loadingPurchaseChannelKey: false,
  loadingPurchaseChannelList: false,
  loadingPurchaseAdditional: false,
  loadingPurchaseChannelPlan: false,
  loadingPurchaseCoachScheduleSession: false,
  loadingAddOfflinePaymentSession: false,
  loadingPurchaseWorkoutToMember: false,
  purchaseChannelPlanSuccess: false,
  loadingPurchaseChannelStoreProduct: false,
  payment: null,
});

/* ---------- Reducers ---------- */
const loadStartReducer = (state) => state.set('loading', true);

const purchaseChannelKeyStartReducer = (state) => state.set('loadingPurchaseChannelKey', true);
const purchaseChannelListStartReducer = (state) => state.set('loadingPurchaseChannelList', true);
const purchaseCoachScheduleSessionStartReducer = (state) =>
  state.set('loadingPurchaseCoachScheduleSession', true);
const purchaseChannelStoreProductStartReducer = (state) =>
  state.set('loadingPurchaseChannelStoreProduct', true);
const purchaseWorkoutToMemberStartReducer = (state) =>
  state.set('loadingPurchaseWorkoutToMember', true);

const getPaymentsSuccessReducer = (state, action) =>
  state.merge({
    data: action.payments,
    loading: false,
    error: null,
  });
const retryPaymentSuccessReducer = (state) => state.set('loading', false);

const purchaseChannelPlanStartReducer = (state) =>
  state.merge({
    loadingPurchaseChannelPlan: true,
    purchaseChannelPlanSuccess: false,
  });

const loadErrorReducer = (state, action) =>
  state.merge({
    loading: false,
    error: action.error,
  });

const purchaseChannelPlanErrorReducer = (state, action) =>
  state.merge({
    loading: false,
    loadingPurchaseChannelPlan: false,
    purchaseChannelPlanSuccess: false,
    error: action.error,
  });

const purchaseChannelKeyErrorReducer = (state, action) =>
  state.merge({
    loadingPurchaseChannelKey: false,
    error: action.error,
  });

const purchaseChannelListErrorReducer = (state, action) =>
  state.merge({
    loadingPurchaseChannelList: false,
    error: action.error,
  });

const purchaseChannelPlanSuccessReducer = (state) =>
  state.merge({
    loadingPurchaseChannelPlan: false,
    purchaseChannelPlanSuccess: true,
    error: null,
  });

function purchaseChannelKeySuccessReducer(state) {
  return state.merge({
    loadingPurchaseChannelKey: false,
    error: null,
  });
}

function purchaseWorkoutToMemberErrorReducer(state) {
  return state.merge({
    loadingPurchaseWorkoutToMember: false,
    error: null,
  });
}

function purchaseWorkoutToMemberSuccessReducer(state) {
  return state.merge({
    loadingPurchaseWorkoutToMember: false,
    error: null,
  });
}

function purchaseChannelListSuccessReducer(state) {
  return state.merge({
    loadingPurchaseChannelList: false,
    error: null,
  });
}

function purchaseCoachScheduleSessionSuccessReducer(state) {
  return state.merge({
    loadingPurchaseCoachScheduleSession: false,
    error: null,
  });
}

function purchaseCoachScheduleSessionErrorReducer(state) {
  return state.merge({
    loadingPurchaseCoachScheduleSession: false,
  });
}

function purchaseChannelStoreProductErrorReducer(state) {
  return state.merge({
    loadingPurchaseChannelStoreProduct: false,
  });
}

function purchaseChannelStoreProductSuccessReducer(state) {
  return state.merge({
    loadingPurchaseChannelStoreProduct: false,
  });
}

/* ---------- Main Reducer ---------- */
const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_PAYMENTS_START]: loadStartReducer,
  [Types.GET_PAYMENTS_SUCCESS]: getPaymentsSuccessReducer,
  [Types.GET_PAYMENTS_ERROR]: loadErrorReducer,

  [Types.RETRY_PAYMENT_START]: loadStartReducer,
  [Types.RETRY_PAYMENT_SUCCESS]: retryPaymentSuccessReducer,
  [Types.RETRY_PAYMENT_ERROR]: loadErrorReducer,

  [Types.PURCHASE_CHANNEL_KEY_START]: purchaseChannelKeyStartReducer,
  [Types.PURCHASE_CHANNEL_KEY_SUCCESS]: purchaseChannelKeySuccessReducer,
  [Types.PURCHASE_CHANNEL_KEY_ERROR]: purchaseChannelKeyErrorReducer,

  [Types.PURCHASE_CHANNEL_LIST_START]: purchaseChannelListStartReducer,
  [Types.PURCHASE_CHANNEL_LIST_SUCCESS]: purchaseChannelListSuccessReducer,
  [Types.PURCHASE_CHANNEL_LIST_ERROR]: purchaseChannelListErrorReducer,

  [Types.PURCHASE_CHANNEL_PLAN_START]: purchaseChannelPlanStartReducer,
  [Types.PURCHASE_CHANNEL_PLAN_SUCCESS]: purchaseChannelPlanSuccessReducer,
  [Types.PURCHASE_CHANNEL_PLAN_ERROR]: purchaseChannelPlanErrorReducer,

  [Types.PURCHASE_COACH_SCHEDULE_SESSION_START]: purchaseCoachScheduleSessionStartReducer,
  [Types.PURCHASE_COACH_SCHEDULE_SESSION_SUCCESS]: purchaseCoachScheduleSessionSuccessReducer,
  [Types.PURCHASE_COACH_SCHEDULE_SESSION_ERROR]: purchaseCoachScheduleSessionErrorReducer,

  [Types.PURCHASE_CHANNEL_STORE_PRODUCT_START]: purchaseChannelStoreProductStartReducer,
  [Types.PURCHASE_CHANNEL_STORE_PRODUCT_SUCCESS]: purchaseChannelStoreProductSuccessReducer,
  [Types.PURCHASE_CHANNEL_STORE_PRODUCT_ERROR]: purchaseChannelStoreProductErrorReducer,

  [Types.PURCHASE_WORKOUT_TO_MEMBER_START]: purchaseWorkoutToMemberStartReducer,
  [Types.PURCHASE_WORKOUT_TO_MEMBER_SUCCESS]: purchaseWorkoutToMemberSuccessReducer,
  [Types.PURCHASE_WORKOUT_TO_MEMBER_ERROR]: purchaseWorkoutToMemberErrorReducer,
});

/* ---------- Exporting ---------- */
export const paymentsActions = Creators;
export const paymentsTypes = Types;
export const paymentsSelectors = selectors;
export const paymentsSagas = sagas;

export default reducer;
