import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { GOOGLE_RECAPTCHA_SITE_KEY } from 'core/env';

const CaptchaForm = ({ captchaRef }) => (
  <div>
    <ReCAPTCHA sitekey={GOOGLE_RECAPTCHA_SITE_KEY} ref={captchaRef} />
  </div>
);

export default CaptchaForm;
