import React from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import { TableCell, withStyles } from '@material-ui/core';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useTranslation } from 'react-i18next';
import { onlineColor, primary, secondary, secondaryLight } from 'core/ui/Colors';
import Typography, { fontStyle } from 'core/ui/Typography';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { Close, Edit, FileCopy } from '@material-ui/icons';
import { calculateServiceFees } from 'core/utils/helpers';
import { channelCurrency } from 'core/utils/formatters';
import { APP_BASE_URL } from 'core/env';

export const Cell = withStyles(() => ({
  head: {
    backgroundColor: secondaryLight,
    color: secondary,
    fontSize: 11,
  },
  body: {
    fontSize: 11,
  },
}))(TableCell);

export default function TableCoupons({ channel, coupons, removeNew, change, edit, currentPlan }) {
  const { t } = useTranslation();
  const copy = (v) => {
    navigator.clipboard.writeText(v);
  };

  const calculateNewCost = (coupon, amount) => {
    let val = 0;

    if (coupon.kind === 'percentage') {
      val = amount - (amount / 100) * Number(coupon.amount);
    } else {
      val = amount - Number(coupon.amount);
    }
    if (val < 0) {
      val = 0;
    }

    return calculateServiceFees(currentPlan, val);
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table" size="small">
        <TableHead>
          <TableRow>
            <Cell>{t('channel.membership.placeholder.code')}</Cell>
            <Cell align="right">{t('channel.membership.col.limitUse')}</Cell>
            <Cell align="right">{t('channel.membership.col.discount')}</Cell>
            <Cell align="right">{t('channel.membership.col.cost')}</Cell>
            <Cell align="center">{t('channel.membership.col.renewable')}</Cell>
            <Cell align="center">{t('channel.membership.label.active')}</Cell>
            <Cell align="center" />
            <Cell align="center" />
            <Cell align="center" />
          </TableRow>
        </TableHead>
        <TableBody>
          {coupons.map((coupon, index) => (
            <TableRow key={index.toString()}>
              <Cell>
                <Typography
                  className="link-hover"
                  component="span"
                  style={{ fontSize: 11 }}
                  noWrap
                  onClick={() => copy(coupon.code)}
                >
                  <FileCopy color="primary" size="small" style={{ fontSize: 10 }} />
                  &nbsp;{coupon.code}
                </Typography>
              </Cell>
              <Cell align="right">
                {coupon.limitUse && coupon.limitUse > 0 ? coupon.limitUse : ''}
              </Cell>
              <Cell align="right">
                {coupon.kind === 'percentage' ? `${coupon.amount}%` : `$${coupon.amount}`}
              </Cell>
              <Cell align="right">
                {currentPlan.membershipsIntroductoryAmount > 0 && (
                  <Typography style={fontStyle.xSmall}>
                    {t('channel.membership.plan.introductoryCost')}&nbsp; \
                    {channelCurrency(
                      calculateNewCost(coupon, currentPlan.introductoryAmount),
                      channel?.currency,
                    )}
                  </Typography>
                )}
                <Typography style={fontStyle.xSmall}>
                  {t('channel.membership.plan.cost')}&nbsp;
                  {channelCurrency(calculateNewCost(coupon, currentPlan.amount), channel?.currency)}
                </Typography>
              </Cell>
              <Cell align="center">
                <Typography
                  onClick={() => change('renewable', coupon)}
                  className="link-hover"
                  variant="caption"
                >
                  {!coupon.renewable ? (
                    <RadioButtonUncheckedIcon style={{ ...fontStyle.medium, color: primary }} />
                  ) : (
                    <CheckCircleOutlineIcon style={{ ...fontStyle.medium, color: onlineColor }} />
                  )}
                </Typography>
              </Cell>
              <Cell align="center">
                <Typography
                  onClick={() => change('active', coupon)}
                  className="link-hover"
                  variant="caption"
                >
                  {!coupon.active ? (
                    <RadioButtonUncheckedIcon style={{ ...fontStyle.medium, color: primary }} />
                  ) : (
                    <CheckCircleOutlineIcon style={{ ...fontStyle.medium, color: onlineColor }} />
                  )}
                </Typography>
              </Cell>
              <Cell align="center">
                <Typography
                  onClick={() => removeNew(coupon)}
                  className="link-hover"
                  variant="caption"
                >
                  {!coupon.id && <Close style={{ ...fontStyle.medium, color: primary }} />}
                </Typography>
              </Cell>
              <Cell align="center">
                <Typography onClick={() => edit(coupon)} className="link-hover" variant="caption">
                  <Edit style={{ ...fontStyle.medium, color: primary }} />
                </Typography>
              </Cell>
              <Cell>
                <Typography
                  className="link-hover"
                  component="span"
                  style={{ fontSize: 11 }}
                  noWrap
                  onClick={() =>
                    copy(
                      `${APP_BASE_URL}/embed/memberships/${channel?.id}/${currentPlan?.id}&coupon=${coupon.code}`,
                    )
                  }
                >
                  <FileCopy color="primary" size="small" style={{ fontSize: 10 }} />
                  &nbsp; Copy Link
                </Typography>
              </Cell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

TableCoupons.defaultProps = {
  coupons: [],
  currentPlan: {},
  removeNew: () => {},
  change: () => {},
};
TableCoupons.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  coupons: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  removeNew: PropTypes.func,
  change: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  currentPlan: PropTypes.object,
};
