import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import DatePickerRounded from 'core/ui/DatePickers/DatePickerRounded';
import { ButtonPrimary } from 'core/ui/Button';
import { GetApp, Search } from '@material-ui/icons';
import { formatDateToApi } from 'core/utils/formatters';
import { channelReportsModule } from 'modules/channelReports';
import useSelectors from 'modules/map/useSelectors';
import { dateAdd, timeZoneName } from 'core/utils/formatters/date';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import LoaderSm from 'core/ui/LoaderSm';
import ChoosenChannelTags from 'core/ui/ChoosenChannelTags';
import Grid from 'core/ui/Grid';
import Toggle from 'core/ui/Toggle';
import MembershipsTransactions from './MembershipsTransactions';
import BarMonths from './BarMonths';
import TotalByMembership from './TotalByMembership';

const ForeCastReport = ({ loading, getReport, channel }) => {
  const { t } = useTranslation();

  const data = useSelectors(channelReportsModule, 'data');
  const dateNow = new Date();
  const start = `${dateNow.getMonth() + 1}/1/${dateNow.getFullYear()}`;
  const endDate = dateAdd(dateAdd(start, 2, 'month'), -1, 'day');

  const [filters, setFilters] = useState({
    startAt: start,
    endAt: endDate,
    membersTags: [],
    membershipsTags: [],
  });

  const search = ({ exportReport = false } = {}) => {
    const startAt = filters.startAt ? formatDateToApi(filters.startAt, timeZoneName()) : null;
    const endAt = filters.endAt ? formatDateToApi(filters.endAt, timeZoneName()) : null;
    if (startAt && endAt)
      getReport({ ...filters, startAt, endAt, exportReport, exportFileName: 'forecast.xls' });
  };

  useEffect(() => {
    search();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box mt={2}>
      <Box>
        <Box
          display="flex"
          mb={3}
          alignItems="flex-end"
          justifyContent="flex-start"
          flexWrap="wrap"
        >
          <Box ml={1}>
            <SelectBoxObject
              label="MEMBERSHIPS"
              placeholder="All"
              emptyItem
              height={24}
              emptyValue=""
              options={channel.membershipAllPlans || []}
              value={filters?.channelMembershipPlanId}
              setValue={(v) => setFilters((prev) => ({ ...prev, channelMembershipPlanId: v }))}
              bordered
            />
          </Box>
          <Box ml={1}>
            <DatePickerRounded
              label={t('channel.reports.filter.start').toUpperCase()}
              onChange={(v) => setFilters((prev) => ({ ...prev, startAt: v }))}
              value={filters.startAt}
              disablePast={false}
              usFormat={channel?.useUsDateFormat}
              autoOk
              disabled
            />
          </Box>
          <Box ml={1}>
            <DatePickerRounded
              label={t('channel.reports.filter.end').toUpperCase()}
              onChange={(v) => setFilters((prev) => ({ ...prev, endAt: v }))}
              value={filters.endAt}
              disablePast={false}
              usFormat={channel?.useUsDateFormat}
              autoOk
            />
          </Box>
          <Box ml={3} display="flex" justifyContent="center">
            <Toggle
              options={[
                { value: false, label: t('button.membershipsOnly'), width: 200 },
                { value: true, label: t('button.includeHoldPayments'), width: 200 },
              ]}
              color="white"
              value={!!filters?.addHold}
              onChange={(v) => setFilters((prev) => ({ ...prev, addHold: v }))}
            />
          </Box>
          <Box ml={3}>
            <ButtonPrimary disabled={loading} onClick={() => search()}>
              <Search style={{ color: 'white' }} fontSize="small" />
              {t('button.search')}&nbsp;
            </ButtonPrimary>
          </Box>
          <Box ml={3} pt={5}>
            <ButtonPrimary
              disabled={loading}
              onClick={() => search({ page: 1, exportReport: true })}
            >
              <GetApp style={{ color: 'white' }} fontSize="small" />
              {t('button.exportCsv')}&nbsp;
            </ButtonPrimary>
          </Box>
        </Box>
        <Box mb={3}>
          <Grid container spacing={5}>
            <Grid item xs={12} md={4}>
              <ChoosenChannelTags
                label={t('channel.label.memberTags')}
                placeholder={t('channel.tags')}
                val={filters?.membersTags ? [...filters?.membersTags] : []}
                setValue={(v) =>
                  setFilters((prev) => ({ ...prev, membersTags: v?.map((x) => x?.name || x) }))
                }
                channelId={channel?.id}
                bordered
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <ChoosenChannelTags
                label={t('channel.label.membershipsTags')}
                placeholder={t('channel.tags')}
                val={filters?.membershipsTags ? [...filters?.membershipsTags] : []}
                setValue={(v) =>
                  setFilters((prev) => ({ ...prev, membershipsTags: v?.map((x) => x?.name || x) }))
                }
                channelId={channel?.id}
                bordered
              />
            </Grid>
          </Grid>
        </Box>
        <LoaderSm loading={loading} center />
        <Box>
          <MembershipsTransactions
            rows={data?.payments || []}
            channel={channel}
            getReport={getReport}
          />
        </Box>

        <Box mt={5}>
          <TotalByMembership memberships={data?.totalByMemberships} channel={channel} />
        </Box>

        <Box>
          <BarMonths data={data?.chart} channel={channel} />
        </Box>
      </Box>
    </Box>
  );
};

ForeCastReport.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  loading: PropTypes.bool,
  getReport: PropTypes.func,
};

ForeCastReport.defaultProps = {
  loading: false,
  getReport: () => {},
};

export default ForeCastReport;
