import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

import * as selectors from './selectors';
import * as sagas from './sagas';

/* ---------- Action Creators and Types ---------- */
const { Types, Creators } = createActions(
  {
    postMessagesError: ['error'],
    postMessagesStart: ['data', 'callback'],
    postMessagesSuccess: ['message'],

    putMessagesError: ['error'],
    putMessagesStart: ['data', 'callback'],
    putMessagesSuccess: ['message'],

    getMessagesError: ['error'],
    getMessagesStart: [],
    getMessagesSuccess: ['messages', 'pagination'],

    stopContactError: ['error'],
    stopContactStart: ['phone', 'callback'],
    stopContactSuccess: [],

    getMessagesRepliesError: ['error'],
    getMessagesRepliesStart: [],
    getMessagesRepliesSuccess: ['replies'],

    getMessageError: ['error'],
    getMessageStart: [],
    getMessageSuccess: ['message'],

    getStreamUserCountError: ['error'],
    getStreamUserCountStart: [],
    getStreamUserCountSuccess: ['countUsers'],

    getTwilioNumbersError: ['error'],
    getTwilioNumbersStart: [],
    getTwilioNumbersSuccess: ['numbers'],
  },
  { prefix: 'messages/' },
);

/* ---------- Initial State ---------- */
const INITIAL_STATE = Immutable({
  data: [],
  numbers: [],
  replies: [],
  countUsers: {},
  error: null,
  loading: false,
  loadingStop: false,
  loadingSend: false,
  loadingNumbers: false,

  contacts: [],
  paginationContacts: {},
  loadingContacts: false,
});

/* ---------- Reducers ---------- */
const loadStartReducer = (state) => state.set('loading', true);
const loadStopStartReducer = (state) => state.set('loadingStop', true);
const loadRepliesStartReducer = (state) => state.set('loadingReplies', true);
const loadPostStartReducer = (state) => state.set('loadingSend', true);
const loadTwilioNumbersStartReducer = (state) => state.set('loadingNumbers', true);

const postMessagesSuccessReducer = (state) =>
  state.merge({
    loadingSend: false,
    error: null,
  });

const stopContactSuccessReducer = (state) =>
  state.merge({
    loadingStop: false,
    error: null,
  });

const getMessagesSuccessReducer = (state, action) =>
  state.merge({
    data: action.messages,
    loading: false,
    error: null,
  });

const getTwilioNumbersSuccessReducer = (state, action) =>
  state.merge({
    numbers: action.numbers,
    loadingNumbers: false,
    error: null,
  });

const getMessagesRepliesSuccessReducer = (state, action) =>
  state.merge({
    replies: action.replies,
    loadingReplies: false,
    error: null,
  });

const getMessageSuccessReducer = (state, action) =>
  state.merge({
    data: action.message,
    loading: false,
    error: null,
  });

const getStreamUserCountSuccessReducer = (state, action) =>
  state.merge({
    countUsers: action.countUsers,
    loading: false,
    error: null,
  });

const loadErrorReducer = (state, action) =>
  state.merge({
    loading: false,
    error: action.error,
  });

const loadPostErrorReducer = (state, action) =>
  state.merge({
    loadingSend: false,
    error: action.error,
  });

const loadTwilioNumbersErrorReducer = (state, action) =>
  state.merge({
    loadingNumbers: false,
    error: action.error,
  });

const loadRepliesErrorReducer = (state, action) =>
  state.merge({
    loadingReplies: false,
    error: action.error,
  });

const loadStopErrorReducer = (state, action) =>
  state.merge({
    loadingStop: false,
    error: action.error,
  });

/* ---------- Main Reducer ---------- */
const reducer = createReducer(INITIAL_STATE, {
  [Types.POST_MESSAGES_START]: loadPostStartReducer,
  [Types.POST_MESSAGES_SUCCESS]: postMessagesSuccessReducer,
  [Types.POST_MESSAGES_ERROR]: loadPostErrorReducer,

  [Types.PUT_MESSAGES_START]: loadStartReducer,
  [Types.PUT_MESSAGES_SUCCESS]: postMessagesSuccessReducer,
  [Types.PUT_MESSAGES_ERROR]: loadErrorReducer,

  [Types.GET_MESSAGES_START]: loadStartReducer,
  [Types.GET_MESSAGES_SUCCESS]: getMessagesSuccessReducer,
  [Types.GET_MESSAGES_ERROR]: loadErrorReducer,

  [Types.GET_MESSAGE_START]: loadStartReducer,
  [Types.GET_MESSAGE_SUCCESS]: getMessageSuccessReducer,
  [Types.GET_MESSAGE_ERROR]: loadErrorReducer,

  [Types.GET_STREAM_USER_COUNT_START]: loadStartReducer,
  [Types.GET_STREAM_USER_COUNT_SUCCESS]: getStreamUserCountSuccessReducer,
  [Types.GET_STREAM_USER_COUNT_ERROR]: loadErrorReducer,

  [Types.STOP_CONTACT_START]: loadStopStartReducer,
  [Types.STOP_CONTACT_SUCCESS]: stopContactSuccessReducer,
  [Types.STOP_CONTACT_ERROR]: loadStopErrorReducer,

  [Types.GET_MESSAGES_REPLIES_START]: loadRepliesStartReducer,
  [Types.GET_MESSAGES_REPLIES_SUCCESS]: getMessagesRepliesSuccessReducer,
  [Types.GET_MESSAGES_REPLIES_ERROR]: loadRepliesErrorReducer,

  [Types.GET_TWILIO_NUMBERS_START]: loadTwilioNumbersStartReducer,
  [Types.GET_TWILIO_NUMBERS_SUCCESS]: getTwilioNumbersSuccessReducer,
  [Types.GET_TWILIO_NUMBERS_ERROR]: loadTwilioNumbersErrorReducer,
});

/* ---------- Exporting ---------- */
export const messagesActions = Creators;
export const messagesTypes = Types;
export const messagesSelectors = selectors;
export const messagesSagas = sagas;

export default reducer;
