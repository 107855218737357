import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form-v5';
import { useTranslation } from 'react-i18next';
import notifications from 'modules/notifications';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import { useDispatch } from 'react-redux';
import { formatDateApi, formatDateToApi } from 'core/utils/formatters';

const useFormParterProgramChannel = ({
  partnersProgram,
  partnersProgramsChannel,
  close,
  callbackSave,
}) => {
  const { t } = useTranslation();
  const { register, handleSubmit, errors, setValue, watch, reset } = useForm();

  const { request } = useActions();
  const dispatch = useDispatch();

  useEffect(() => {
    register({ name: 'channel' }, { required: true });
    register({ name: 'expiryAt' }, { required: true });
    register({ name: 'active' });
    register({ name: 'renewPrice' });
    register({ name: 'renewable' });
    if (partnersProgram?.allowAnnualCost) {
      register({ name: 'annualRenewal' });
    }
  }, [register, partnersProgramsChannel, partnersProgram]);

  useEffect(() => {
    if (partnersProgramsChannel?.id) {
      const newObj = { ...partnersProgramsChannel };
      delete newObj.expiryAt;
      reset({ ...partnersProgramsChannel });

      setTimeout(() => {
        setValue('expiryAt', formatDateApi(partnersProgramsChannel.expiryAt, 'YYYY-MM-DD'));
      }, 100);
    } else {
      setTimeout(() => {
        setValue('active', true);
      }, 30);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnersProgramsChannel]);

  const onSubmit = (data) => {
    const partnerData = {
      id: partnersProgramsChannel?.id,
      partnersProgramId: partnersProgram?.id,
      channelId: partnersProgramsChannel?.id
        ? partnersProgramsChannel?.channelId
        : data?.channel?.id,
      renewable: data?.renewable,
      renewPrice: data?.renewPrice,
      annualRenewal: data?.annualRenewal,
      expiryAt: formatDateToApi(data?.expiryAt),
    };

    request({
      action: partnersProgramsChannel?.id
        ? Modules.partnersProgramsChannels.actions.update
        : Modules.partnersProgramsChannels.actions.create,
      data: partnerData,
      options: {
        onSuccess: (resp) => {
          dispatch(notifications.success(t('partner.program.added.success')));
          if (callbackSave) {
            callbackSave(resp);
            close();
            return;
          }
          close();
        },
      },
    });
  };

  return {
    watch,
    setValue,
    submit: handleSubmit(onSubmit),
    errors,
  };
};

useFormParterProgramChannel.propTypes = {
  close: PropTypes.func.isRequired,
};

useFormParterProgramChannel.defaultProps = {};

export default useFormParterProgramChannel;
