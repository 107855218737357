import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import ChannelContracts from 'components/Channel/ChannelContracts';
import { useSelector } from 'react-redux';
import { sessionSelectors } from 'modules/session';
import QuickForm from 'components/Profile/QuickForm';
import useChannelStyles from 'core/useChannelStyles';
import Texture from 'core/ui/Texture';
import Box from 'core/ui/Box';
import FormLogin from 'components/Login/Form';
import Form from './Form';

const ModalFormRegistration = ({
  isCoach,
  open,
  event,
  close,
  callback,
  callbackError,
  channel,
}) => {
  const currentUser = useSelector((state) => sessionSelectors.getUser(state));
  const isAuthenticated = useSelector((state) => sessionSelectors.getIsAuthenticated(state));
  const [waiverOk, setWaiverOk] = useState(false);
  const { buttonColor } = useChannelStyles();

  if (!open) {
    return <div />;
  }

  if (!isAuthenticated) {
    return (
      <Dialog open={!!open} maxWidth="sm" fullWidth onClose={() => close()}>
        <Texture>
          <Box p={2}>
            <FormLogin fromModal channelId={channel?.id} />
          </Box>
        </Texture>
      </Dialog>
    );
  }

  if (isAuthenticated && !currentUser?.name) {
    return (
      <Dialog open maxWidth="sm" fullWidth>
        <Texture>
          <Box px={4}>
            <QuickForm buttonColor={buttonColor} fastForm />
          </Box>
        </Texture>
      </Dialog>
    );
  }

  if (
    open &&
    !isCoach &&
    (event?.usersCalendar?.documentWaiverId || channel?.documentWaiverId) &&
    !waiverOk
  ) {
    return (
      <ChannelContracts
        channel={channel}
        userId={currentUser.id}
        ownerableType="Channel"
        ownerableId={channel?.id}
        objectId={event?.usersCalendar?.id}
        objectType="UsersCalendar"
        kind="waivers"
        setAccepted={setWaiverOk}
        documentId={event?.usersCalendar?.documentWaiverId || channel?.documentWaiverId}
        onClose={close}
      />
    );
  }

  return (
    <Dialog open={!!open} onClose={close} maxWidth="xs" fullWidth scroll="body">
      <Form callback={callback} callbackError={callbackError} event={event} channel={channel} />
    </Dialog>
  );
};

ModalFormRegistration.defaultProps = {
  close: () => {},
  userId: null,
};

export default ModalFormRegistration;
