import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import Texture from 'core/ui/Texture';
import Typography from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import Form from './Form';

const ModalClone = ({ close, modalClone, calendarInfo, channel }) => {
  const { t } = useTranslation();
  return (
    <Dialog open={modalClone.open} onClose={close} maxWidth="sm" fullWidth scroll="body">
      <Texture>
        <Box py={3}>
          <Typography mb={3} align="center" variant="h4" color="primary">
            {t('schedule.calendar.clone')}
          </Typography>
          <Form calendarInfo={calendarInfo} channel={channel} close={close} />
        </Box>
      </Texture>
    </Dialog>
  );
};

ModalClone.propTypes = {
  close: PropTypes.func,
};

ModalClone.defaultProps = {
  close: () => {},
};

export default ModalClone;
