import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import { useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';
import Typography from 'core/ui/Typography';
import LoaderSm from 'core/ui/LoaderSm';
import { channelsSelectors } from 'modules/channels';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import useSelectors from 'modules/map/useSelectors';
import { userPaymentMethodsModule } from 'modules/userPaymentMethods';
import ModalAddPaymentMethod from 'components/Profile/Dashboard/Account/ModalAddPaymentMethod';
import PaymentMethodList from './PaymentMethodList';

export const PaymentMethodViewType = {
  form: 0,
  list: 1,
};

const SelectorPaymentMethod = ({
  view,
  setView,
  selectedPaymentMethod,
  setSelectedPaymentMethod,
  userId,
  userInfo,
  guest,
  onlyMethod,
  buttonColor,
  bordered,
  showCanadaDebit,
  cardpointe,
  showMessageToAddStripePaymentMethod,
}) => {
  const { t } = useTranslation();
  const channel = useSelector((state) => channelsSelectors.getChannel(state));
  const { request } = useActions();
  const loadingPaymentMethods = useSelectors(userPaymentMethodsModule, 'loading');
  const userPaymentMethods = useSelectors(userPaymentMethodsModule, 'data');
  const [showDefault, setShowDefault] = useState(false);

  const getPaymentMethods = () => {
    request({
      action: Modules.userPaymentMethods.actions.getUserPaymentMethods,
      data: {
        userId,
        onlyMethod,
        cardpointe,
        channelId: channel?.id,
      },
    });
  };

  useEffect(() => {
    if (userId && !guest) {
      getPaymentMethods();
    }
  }, [userId, guest, cardpointe, channel]); // eslint-disable-line

  useEffect(() => {
    setSelectedPaymentMethod(null);
  }, [userId]); // eslint-disable-line

  useEffect(() => {
    if (userPaymentMethods.length > 0) {
      const def = [...userPaymentMethods].reverse().find((x) => x.default === true);
      setSelectedPaymentMethod(def);
      if (def) {
        setShowDefault(true);
      } else {
        setShowDefault(false);
      }
      setView(PaymentMethodViewType.list);
    }
  }, [userPaymentMethods]); //eslint-disable-line

  const closePaymentMethodModal = () => {
    setView(PaymentMethodViewType.list);
  };

  return (
    <Box pb={1} justifyContent="center">
      <Box display="flex" justifyContent="center">
        <LoaderSm loading={loadingPaymentMethods} />
      </Box>
      {!guest && !loadingPaymentMethods && view !== PaymentMethodViewType.form && showDefault && (
        <Box mb={1} mt={3} display="flex" alignItems="center">
          <Box className="link" onClick={() => setShowDefault(false)}>
            <Box display="flex" alignItems="center">
              <Box pt={1}>
                <KeyboardArrowDown size="small" />
              </Box>
              <Typography style={{ color: buttonColor }} variant="body2">
                {t('purchase.defaultPaymentMethod')}
              </Typography>
            </Box>
          </Box>
          {selectedPaymentMethod && (
            <Typography ml={2} variant="body2" className="roboto-regular">
              <span style={{ textTransform: 'capitalize' }}>
                <b>{selectedPaymentMethod.cardFlag}</b>
              </span>{' '}
              <Typography className="roboto-regular" component="span" color="textSecondary">
                {t('userPaymentMethod.endingIn')} {selectedPaymentMethod.lastFour}
              </Typography>
            </Typography>
          )}
        </Box>
      )}

      {guest && !loadingPaymentMethods && view !== PaymentMethodViewType.form && showDefault && (
        <Box mb={1} mt={3} display="flex" justifyContent="center" alignItems="center">
          {selectedPaymentMethod && (
            <Typography ml={2} variant="h3" align="center" className="roboto-regular">
              <span style={{ textTransform: 'capitalize' }}>
                <b>{selectedPaymentMethod.cardFlag}</b>
              </span>{' '}
              <Typography
                className="roboto-regular"
                variant="h3"
                component="span"
                color="textSecondary"
              >
                {t('userPaymentMethod.endingIn')} {selectedPaymentMethod.lastFour}
              </Typography>
            </Typography>
          )}
        </Box>
      )}

      {!loadingPaymentMethods && view === PaymentMethodViewType.list && !showDefault && (
        <Box my={3}>
          <PaymentMethodList
            setSelectedPaymentMethod={setSelectedPaymentMethod}
            selectedPaymentMethod={selectedPaymentMethod}
            methods={userPaymentMethods}
            loading={loadingPaymentMethods}
            showExpiry
            buttonColor={buttonColor}
          />
        </Box>
      )}

      {view === PaymentMethodViewType.form && (
        <ModalAddPaymentMethod
          cardpointe={cardpointe}
          showCanadaDebit={showCanadaDebit}
          userId={userId}
          userInfo={userInfo}
          allowClose
          onClose={closePaymentMethodModal}
          open
          showMessageToAddStripePaymentMethod={
            showMessageToAddStripePaymentMethod && userPaymentMethods.length === 0
          }
          bordered={bordered}
          guest={guest}
          onlyMethod={onlyMethod}
          setSelectedPaymentMethod={setSelectedPaymentMethod}
          setView={setView}
        />
      )}

      {!guest && view === PaymentMethodViewType.list && !loadingPaymentMethods && (
        <Button
          type="button"
          variant="outlined"
          style={{ width: '100%', marginTop: 3, borderRadius: 5 }}
          onClick={() => setView(PaymentMethodViewType.form)}
        >
          {t('userPaymentMethod.addNewMethod')}
        </Button>
      )}
    </Box>
  );
};

SelectorPaymentMethod.defaultProps = {
  selectedPaymentMethod: null,
  guest: false,
  onlyMethod: 'card',
};

export default SelectorPaymentMethod;
