import { useState } from 'react';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import uuid from 'react-uuid';
import notifications from 'modules/notifications';
import { useDispatch } from 'react-redux';

export default ({ callback, associableId, associableType, ownerableId, ownerableType }) => {
  const { request } = useActions();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [uploads, setUploads] = useState([{}]);

  const create = (file, index) => {
    const data = {
      ownerableId,
      ownerableType,
      name: file?.name,
      url: file?.url,
      media_type: 'video',
      uuid: uuid(),
      mediaFilesAssociationsAttributes: [
        {
          associableType,
          associableId,
        },
      ],
    };

    if (!associableType) {
      delete data.mediaFilesAssociationsAttributes;
    }

    setLoading(true);
    request({
      action: Modules.mediaFilesAssociations.actions.create,
      data,
      options: {
        onSuccess: () => {
          if (uploads.length - 1 > index) {
            create(uploads[index + 1], index + 1);
            return;
          }

          setLoading(false);
          if (callback) {
            callback({ url: file?.url });
          }
        },
        onError: (error) => {
          dispatch(notifications.error(error));
          setLoading(false);
        },
      },
    });
  };

  const confirmUploads = () => {
    create(uploads[0], 0);
  };

  return {
    confirmUploads,
    loading,

    uploads,
    setUploads,
  };
};
