import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core';
import Box from 'core/ui/Box';
import { secondary, secondaryXLight, secondaryXXLight, secondaryBgLight } from 'core/ui/Colors';
import { ProgramViewContext } from 'contexts';
import Scrollbars from 'react-custom-scrollbars';
import Button from 'core/ui/Button';
import useChannelStyles from 'core/useChannelStyles';
import { date, dateAdd } from 'core/utils/formatters';
import WeekDayView from './WeekDayView';

const useStyles = makeStyles((theme) => ({
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  cardDay: {
    border: `0.5px solid ${secondaryXLight}`,
    flexBasis: 0,
    flexGrow: 1,
  },
  titleCardDay: {
    backgroundColor: secondaryXXLight,
    color: secondary,
    width: '100%',
    padding: 2,
  },
  boxDay: {
    backgroundColor: secondaryXXLight,
    color: secondary,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 40,
  },
  boxWorkout: {
    backgroundColor: secondaryBgLight,
    width: '100%',
    padding: 2,
  },
}));

const WeekView = () => {
  const classes = useStyles();
  const { buttonColor } = useChannelStyles();
  const { sectionOpened, calendarInfo, setModalCloneDay } = useContext(ProgramViewContext);

  return (
    <Box className="grid-calendar-week" style={{ flex: 1 }}>
      {[...new Array(7)].map((indexDay, index) => (
        <div>
          <Scrollbars className={classes.cardDay} allowFullScreen key={index.toString()}>
            <WeekDayView index={index} sectionOpened={sectionOpened} />
          </Scrollbars>

          <Box style={{ height: 30 }} pt={1} display="flex" justifyContent="center">
            <Button
              buttonColor={buttonColor}
              onClick={() =>
                setModalCloneDay({
                  open: true,
                  day: date(dateAdd(calendarInfo?.start, index, 'days'), 'YYYY/MM/DD'),
                })
              }
              variant="outlined"
            >
              Clone Day
            </Button>
          </Box>
        </div>
      ))}
    </Box>
  );
};

WeekView.propTypes = {};

WeekView.defaultProps = {
  close: () => {},
};

export default WeekView;
