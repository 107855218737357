import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form-v5';
import { useTranslation } from 'react-i18next';
import notifications from 'modules/notifications';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import { useDispatch } from 'react-redux';
import { currency } from 'core/utils/formatters';

const useFormPlans = ({ plan, close, callbackSave }) => {
  const { t } = useTranslation();
  const { register, handleSubmit, errors, setValue, watch, reset } = useForm();
  const { request } = useActions();
  const dispatch = useDispatch();

  useEffect(() => {
    register({ name: 'name' }, { required: true });
    register({ name: 'amount' }, { required: true });
    register({ name: 'active' });
    register({ name: 'amountInPerson' }, { required: true });
    register({ name: 'amountMemberApp' }, { required: true });
    register({ name: 'amountCompetition' }, { required: true });
    register({ name: 'amountSifely' }, { required: true });
    register({ name: 'amountEngage' }, { required: true });
  }, [register]);

  useEffect(() => {
    if (plan?.id) {
      reset({
        ...plan,
        amount: currency(plan?.amount),
        amountInPerson: currency(plan?.amountInPerson),
        amountMemberApp: currency(plan?.amountMemberApp),
        amountCompetition: currency(plan?.amountCompetition),
        amountSifely: currency(plan?.amountSifely),
        amountEngage: currency(plan?.amountEngage),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plan]);
  const onSubmit = (data) => {
    const partnerData = {
      id: plan?.id,
      ...data,
    };

    request({
      action: plan?.id ? Modules.plans.actions.update : Modules.plans.actions.create,
      data: partnerData,
      options: {
        onSuccess: (resp) => {
          dispatch(notifications.success(t('channel.member.added.success')));
          if (callbackSave) {
            callbackSave(resp);
            close();
            return;
          }
          close();
        },
      },
    });
  };

  return {
    plan,
    watch,
    setValue,
    submit: handleSubmit(onSubmit),
    errors,
  };
};

useFormPlans.propTypes = {
  close: PropTypes.func.isRequired,
};

useFormPlans.defaultProps = {};

export default useFormPlans;
