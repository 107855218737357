import React, { useEffect, useState } from 'react';
import Box from 'core/ui/Box';
import { secondary, redColor, successColor } from 'core/ui/Colors';
import Paper from 'core/ui/Paper';
import Typography from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import { GetApp, KeyboardArrowDown, KeyboardArrowRight, Check, Close } from '@material-ui/icons';
import { Collapse } from '@material-ui/core';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import useSelectors from 'modules/map/useSelectors';
import { newChannelsModule } from 'modules/channels/new';
import SFDataTable from 'core/ui/DataTable';
import { currency, date } from 'core/utils/formatters';
import LoaderSm from 'core/ui/LoaderSm';
import { ButtonPrimary, ButtonPrimarySm } from 'core/ui/Button';
import { partnersProgramsChannelsModule } from 'modules/partnersProgramsChannels';
import useConfirmation from 'core/useConfirmation';
import notifications from 'modules/notifications';
import { useDispatch } from 'react-redux';
import PaymentStatusColumn from '../../../Reports/Payments/PaymentStatusColumn';
import { MenuActions } from './MenuActions';

export default ({ channel }) => {
  const { t } = useTranslation();
  const { request } = useActions();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openPrograms, setOpenPrograms] = useState(false);
  const [partnersPrograms, setPartnersProgram] = useState([]);
  const planPayments = useSelectors(newChannelsModule, 'planPayments');
  const planPaymentsPagination = useSelectors(newChannelsModule, 'planPaymentsPagination');
  const planPaymentsLoading = useSelectors(newChannelsModule, 'planPaymentsLoading');
  const loadingPrograms = useSelectors(partnersProgramsChannelsModule, 'loading');

  const { confirmation } = useConfirmation();

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const styleIcon = {
    color: secondary,
    fontSize: 20,
  };

  const getPayments = (exportReport = null) => {
    request({
      action: exportReport
        ? Modules.newChannels.actions.exportChannelPlanPayments
        : Modules.newChannels.actions.getChannelPlanPayments,
      data: {
        channelId: channel?.id,
        page,
        perPage,
        exportFileName: 'PaymentsPlan.csv',
      },
    });
  };

  const getPartnerPrograms = () => {
    request({
      action: Modules.partnersProgramsChannels.actions.getPlansPartnersProgramsChannels,
      data: { channelId: channel.id },
      options: {
        onSuccess: setPartnersProgram,
      },
    });
  };

  const renew = (item) => {
    confirmation({
      description: t('areYouSure'),
      yesClick: () => {
        request({
          action: Modules.partnersProgramsChannels.actions.renewPartnersProgramsChannel,
          data: { channelId: channel.id, partnersProgramId: item?.partnersProgramId, id: item?.id },
          options: {
            onSuccess: () => {
              getPartnerPrograms();
            },
            onError: (error) => {
              dispatch(notifications.error(error));
            },
          },
        });
      },
    });
  };

  const cancel = (item) => {
    confirmation({
      description: t('areYouSure'),
      yesClick: () => {
        request({
          action: Modules.partnersProgramsChannels.actions.cancel,
          data: {
            channelId: channel?.id,
            partnersProgramId: item?.partnersProgramId,
          },
          options: {
            onSuccess: () => {
              getPartnerPrograms();
            },
          },
        });
      },
    });
  };

  useEffect(() => {
    if (open) {
      getPayments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, open]);

  useEffect(() => {
    if (openPrograms) {
      getPartnerPrograms();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, openPrograms]);

  const columns = [
    {
      name: 'Type',
      cell: (row) => (
        <Box display="flex">
          <Box flexGrow={1}>
            <Typography>{t(`payment.kind.${row?.kind}`)}</Typography>
          </Box>
        </Box>
      ),
    },
    {
      name: 'Status',
      cell: (row) => <PaymentStatusColumn row={row} />,
    },
    {
      name: 'Last Four',
      cell: (row) => (
        <Box display="flex">
          <Box flexGrow={1}>
            <Typography>{row?.userCard}</Typography>
          </Box>
        </Box>
      ),
    },
    {
      name: 'Date',
      cell: (row) => (
        <Box display="flex">
          <Box flexGrow={1}>
            <Typography>{date(row.createdAt, channel?.dateFormatReact)}</Typography>
          </Box>
        </Box>
      ),
    },
    {
      name: 'Amount',
      cell: (row) => (
        <Box display="flex">
          <Box flexGrow={1}>
            <Typography>
              {t('prefix')}
              {row?.amount}
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      sortable: false,
      cell: (row) => (
        <Box ml="auto">
          <MenuActions row={row} />
        </Box>
      ),
    },
  ];

  const columnsPartnersPrograms = [
    {
      name: 'Program',
      selector: (row) => row?.partnersProgram?.name,
      sortable: true,
      cell: (row) => (
        <Box>
          {row?.id}
          {row?.partnersProgram?.name}
        </Box>
      ),
    },
    {
      name: 'Renewable',
      selector: 'renewable',
      sortable: true,
      cell: (row) => (
        <Typography>
          {row?.renewable ? (
            <Check style={{ color: successColor }} />
          ) : (
            <Close style={{ color: redColor }} />
          )}
        </Typography>
      ),
    },
    {
      name: 'Renewal',
      selector: 'annualRenwal',
      sortable: true,
      align: 'center',
      cell: (row) => <Typography>{row?.annualRenewal ? 'Annual' : 'Monthly'}</Typography>,
    },
    {
      name: 'Renew Date',
      selector: 'expiryAt',
      sortable: true,
      cell: (row) => (
        <Typography style={{ color: row?.expired ? redColor : successColor }}>
          {row?.expiryAt && date(row?.expiryAt, channel?.dateFormatReact)}
        </Typography>
      ),
    },
    {
      name: 'Price',
      selector: 'renewPrice',
      sortable: true,
      cell: (row) => (
        <Typography style={{ color: row?.expired ? redColor : successColor }}>
          {currency(row?.renewPrice || row?.partnersProgram?.cost)}
        </Typography>
      ),
    },
    {
      sortable: false,
      cell: (row) => (
        <>
          {row?.expired && (
            <ButtonPrimarySm disabled={loadingPrograms} onClick={() => renew(row)}>
              {t('button.renew')}
            </ButtonPrimarySm>
          )}
          &nbsp;
          {!row?.expired && row?.renewable && (
            <ButtonPrimarySm
              buttonColor={redColor}
              disabled={loadingPrograms}
              onClick={() => cancel(row)}
            >
              {t('button.cancel')}
            </ButtonPrimarySm>
          )}
        </>
      ),
    },
  ];

  return (
    <Box>
      <Paper className="paper-rounded" p={5}>
        <Box flexGrow="1" display="flex" alignItems="center">
          <Box display="flex" alignItems="center" onClick={() => setOpen(!open)}>
            {open ? (
              <KeyboardArrowDown style={{ ...styleIcon }} />
            ) : (
              <KeyboardArrowRight style={{ ...styleIcon }} />
            )}
          </Box>
          <Box flexGrow="1" display="flex" alignItems="center" onClick={() => setOpen(!open)}>
            <Typography ml={1} color="secondary" className="link" variant="h5">
              {t('plan.payment.history').toUpperCase()}
            </Typography>
          </Box>
          <ButtonPrimary disabled={planPaymentsLoading} onClick={() => getPayments(true)}>
            <GetApp style={{ color: 'white' }} fontSize="small" />
            {t('button.exportCsv')}&nbsp;
          </ButtonPrimary>
        </Box>
        <Collapse in={open}>
          <Box>
            <LoaderSm loading={planPaymentsLoading} center />
            <SFDataTable
              noHeader
              paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
              title=""
              showPaper={false}
              columns={columns}
              data={planPayments}
              pagination
              paginationServer
              paginationPerPage={perPage || 10}
              paginationTotalRows={planPaymentsPagination?.totalCount}
              onChangePage={(p) => setPage(p)}
              onChangeRowsPerPage={(newValue) => {
                setPage(1);
                setPerPage(newValue);
              }}
              noDataComponent=""
            />
          </Box>
        </Collapse>
      </Paper>
      <Paper className="paper-rounded" p={5} mt={3}>
        <Box flexGrow="1" display="flex" alignItems="center">
          <Box display="flex" alignItems="center" onClick={() => setOpenPrograms(!openPrograms)}>
            {openPrograms ? (
              <KeyboardArrowDown style={{ ...styleIcon }} />
            ) : (
              <KeyboardArrowRight style={{ ...styleIcon }} />
            )}
          </Box>
          <Box
            flexGrow="1"
            display="flex"
            alignItems="center"
            onClick={() => setOpenPrograms(!openPrograms)}
          >
            <Typography ml={1} color="secondary" className="link" variant="h5">
              {t('plan.payment.partnersPrograms').toUpperCase()}
            </Typography>
          </Box>
        </Box>
        <Collapse in={openPrograms}>
          <Box>
            <LoaderSm loading={loadingPrograms} center />
            <SFDataTable
              noHeader
              title=""
              showPaper={false}
              columns={columnsPartnersPrograms}
              data={partnersPrograms}
              pagination={false}
              noDataComponent=""
            />
          </Box>
        </Collapse>
      </Paper>
    </Box>
  );
};
