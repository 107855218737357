import React from 'react';
import Box from 'core/ui/Box';
import SectionItemScores from 'components/Workout/Form/Section/SectionItemScores';

export default function WorkoutUserLabelMultipleScores({ userScores, orientation }) {
  return (
    <Box>
      {Array.isArray(userScores) ? (
        <>
          {userScores &&
            userScores.map((itemScore, index) => (
              <React.Fragment key={index.toString()}>
                <SectionItemScores userScore={itemScore} index={index} orientation={orientation} />
              </React.Fragment>
            ))}
        </>
      ) : (
        <SectionItemScores userScore={userScores} index={0} orientation={orientation} />
      )}
    </Box>
  );
}

WorkoutUserLabelMultipleScores.defaultProps = {
  userScores: [],
  orientation: 'Vertical',
};
