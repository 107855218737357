/* eslint-disable eqeqeq */
/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from 'react';
import Box from 'core/ui/Box';
import {
  Bar,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Line,
  ComposedChart,
} from 'recharts';
import { primary, secondary, secondaryBgLight } from 'core/ui/Colors';
import { gymDate, hhmmss } from 'core/utils/formatters';
import { convertToSeconds } from 'core/utils/helpers';
import Paper from 'core/ui/Paper';
import Typography from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import Grid from 'core/ui/Grid';

const SectionScoresChart = ({ scoreType, data, historyChart }) => {
  const { t } = useTranslation();
  const [reps, setReps] = useState(null);

  const matchScore = useCallback(
    (item) => {
      if (scoreType === 'rep_max') {
        return (
          item.reps === reps &&
          (item?.scoreType === 'lift_complex' || item?.scoreType === 'rep_max')
        );
      }

      return item?.scoreType === scoreType;
    },
    [scoreType, reps],
  );

  useEffect(() => {
    if (data?.repMaxScores) {
      setReps(data?.repMaxScores[0]?.reps);
    }
  }, [data?.repMaxScores]);

  const filteredScores = historyChart?.filter((x) => matchScore(x));

  const dataChart = () => {
    const chartData = [...filteredScores].reverse().map((x) => {
      let value = x.score;

      if (scoreType === 'time') {
        value = value && value !== undefined ? convertToSeconds(value) : 0;
      }

      return {
        month: gymDate(x.datetime),
        [scoreType]: value || 0,
      };
    });

    return chartData;
  };

  const formatYAxis = (v) => {
    if (scoreType === 'time') {
      return hhmmss(v);
    }

    return v;
  };

  const CustomTooltip = ({ active, payload }) => {
    if (!active) return null;
    const { value } = payload ? payload[0] : {};
    const { month } = payload ? payload[0].payload : {};
    return (
      <Paper p={5}>
        <Typography color="secondary" variant="caption">
          {month}
        </Typography>
        <Box display="flex">
          <Typography variant="h6">{t(`workout.section.label.score.${scoreType}`)}:</Typography>
          <Typography variant="h6" color="primary">
            &nbsp;<b>{formatYAxis(value)}</b>
          </Typography>
        </Box>
      </Paper>
    );
  };

  return (
    <Box my={5} flex={1}>
      {scoreType === 'rep_max' && (
        <Paper p={3} mb={3}>
          <Box aligmItems="center" justifyContent="center">
            <Grid container spacing={1}>
              <Grid item xs={1} />
              {['1', '2', '3', '4', '5'].map((x) => {
                const max = data?.repMaxScores?.find((y) => y.reps == x)?.max;
                return (
                  <Grid item xs={2} key={x}>
                    <Box
                      display="flex"
                      aligmItems="center"
                      justifyContent="center"
                      className="hover"
                      onClick={() => setReps(`${x}`)}
                      style={{
                        border: `0.5px solid ${secondaryBgLight}`,
                        backgroundColor: reps === x ? secondaryBgLight : null,
                        borderRadius: 10,
                      }}
                    >
                      <Box aligmItems="center" justifyContent="center">
                        <Typography align="center" variant="h2">
                          {max || '-'}
                        </Typography>
                        <Typography align="center">{t(`repMax${x}`, x)}</Typography>
                      </Box>
                    </Box>
                  </Grid>
                );
              })}
              <Grid item xs={1} />
            </Grid>
          </Box>
        </Paper>
      )}

      <Paper p={3}>
        <ResponsiveContainer width="100%" height={250}>
          <ComposedChart data={dataChart()}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" style={{ fontSize: 10 }} />
            <YAxis
              domain={[0, 'auto']}
              yAxisId="left"
              orientation="left"
              stroke={secondary}
              dataKey={(v) => Number(v[scoreType])}
              allowDataOverflow={false}
              tickFormatter={(v) => formatYAxis(v)}
            />
            <Tooltip content={<CustomTooltip />} formatter={(v) => formatYAxis(v)} />

            <Bar yAxisId="left" dataKey={scoreType} fill={primary} barSize={10} />
            <Line yAxisId="left" type="monotone" dataKey={scoreType} stroke="#ff7300" />
          </ComposedChart>
        </ResponsiveContainer>
      </Paper>
    </Box>
  );
};

export default SectionScoresChart;
