import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Select, MenuItem, Hidden } from '@material-ui/core';
import Typography from 'core/ui/Typography';

import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';

import languages from 'core/locale/languages';

import { languageActions, languageSelectors } from 'modules/language';
import FlagCountry from 'core/ui/FlagCountry';
import Box from '../../core/ui/Box';

const useStyles = makeStyles(() => ({
  menuItem: {
    display: 'flex',
  },
  iconWhite: {
    color: 'white',
    marginLeft: -10,
  },
  iconBlack: {
    color: '#000',
  },
}));

export default function SelectLanguage({ style, colorIcon, showLabel }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const language = useSelector((state) => languageSelectors.getLanguage(state));

  const changeLanguage = (event) => {
    i18n.changeLanguage(event.target.value);
    const { value } = event.target;
    dispatch(languageActions.setLanguageStart(languages.find((x) => x.language === value)));
  };

  return (
    <Select
      disableUnderline
      name="country"
      value={language.language}
      onChange={(e) => changeLanguage(e)}
      classes={{
        icon: colorIcon === 'white' ? classes.iconWhite : classes.iconBlack,
      }}
      style={style}
    >
      {languages.map((option) => (
        <MenuItem className={classes.menuItem} value={option.language} key={option.language}>
          <Box display="flex" justifyContent="flex-start" alignItems="center">
            <Box px={1}>
              <FlagCountry size="2em" flag={option.flag.toLowerCase()} />
            </Box>
            {showLabel === true && (
              <Hidden only={['xs']}>
                <Box justifyContent="flex-start" alignItems="center">
                  <Typography className="roboto-regular" variant="subtitle1">
                    {option.label}
                  </Typography>
                </Box>
              </Hidden>
            )}
          </Box>
        </MenuItem>
      ))}
    </Select>
  );
}

SelectLanguage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  colorIcon: PropTypes.string,
  showLabel: PropTypes.bool,
};

SelectLanguage.defaultProps = {
  style: {},
  colorIcon: '',
  showLabel: true,
};
