/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { competitionsActions, competitionsSelectors } from 'modules/competitions';
import { useParams, useLocation } from 'react-router';

import { appActions } from 'modules/app';
import { validateTime } from 'core/utils/helpers';

import { formatDateToApi, formatDateApi, currencyEdit } from 'core/utils/formatters';
import { channelsActions, channelsSelectors } from 'modules/channels';
import { parse } from 'query-string';
import { isAfterNow, timeZoneName } from 'core/utils/formatters/date';
import { useForm } from 'react-hook-form-v5';
import { useTranslation } from 'react-i18next';
import { primary } from 'core/ui/Colors';

const useCompetitionForm = ({ mode }) => {
  const competition = useSelector((state) => competitionsSelectors.getCompetition(state));
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { register, handleSubmit, errors, reset, control, setValue, getValues, watch } = useForm();
  const loading = useSelector((state) => competitionsSelectors.getLoading(state));
  const error = useSelector((state) => competitionsSelectors.getError(state));
  const channels = useSelector((state) => channelsSelectors.getChannels(state));

  const [location, setLocation] = useState({ lat: 59.938043, lng: 30.337157 });
  const [currency, setCurrency] = useState();

  const [banner, setBanner] = useState({});

  const [modeComp, setModeComp] = useState(mode);
  const [modalPreviewVideo, setModalPreviewVideo] = useState(false);

  const [invites, setInvites] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const [coupons, setCoupons] = useState([]);
  const [scheduleOptions, setScheduleOptions] = useState([{}]);

  const [selectedChannel, setSelectedChannel] = useState(null);
  const { id } = useParams();
  const browserLocation = useLocation();
  const [video, setVideo] = useState(null);
  const [sections, setSections] = useState([]);
  const [filters, setFilters] = useState([]);
  const [openModalPayment, setOpenModalPayment] = useState({ open: false, new: true });
  const [submiting, setSubmiting] = useState(false);

  const [errorsPopup, setErrorsPopup] = useState([]);
  const [popUpValidation, setPopUpValidation] = useState(false);

  useEffect(() => {
    setModeComp(mode);
  }, [mode]);

  useEffect(() => {
    dispatch(appActions.setLoading(loading));
    setSubmiting(loading);
  }, [loading, dispatch]);

  useEffect(() => {
    setSubmiting(false);
  }, [error]);

  useEffect(() => {
    dispatch(channelsActions.getChannelsStart());
  }, [dispatch]);

  useEffect(() => {
    const params = parse(browserLocation.search);
    if (params.channelId) {
      setSelectedChannel(channels.find((x) => x.id === Number(params.channelId)));
    }
  }, [channels, browserLocation]);

  useEffect(() => {
    register({ name: 'leaderboardOrder' }, { required: false });
    register({ name: 'usersJudges' }, { required: false });
    register({ name: 'usersEarlyAccess' }, { required: false });
    register({ name: 'watchOption' }, { required: false });
    register({ name: 'heats' }, { required: false });
    register({ name: 'hasDivisions' }, { required: false });
    register({ name: 'hasAffiliate' }, { required: false });
    register({ name: 'buttonColor' }, { required: false });
    register({ name: 'fontColor' }, { required: false });
    register({ name: 'materialDesignFontColor' }, { required: false });
    register({ name: 'eventType' }, { required: false });
    register({ name: 'isPrivate' }, { required: false });
    register({ name: 'requireVerification' }, { required: false });
    register({ name: 'invites' }, { required: false });
    register({ name: 'teamSize' }, { required: false });
    register({ name: 'usersEarlyAccess' }, { required: false });
    register({ name: 'useTax' }, { required: false });
    register({ name: 'rankByVideo' }, { required: false });

    register({ name: 'online' }, { required: false });
    register({ name: 'inPerson' }, { required: false });
    register({ name: 'limitRegistrations' }, { required: false });
    register({ name: 'documentWaiverId' }, { required: false });
    register({ name: 'taxPercent' }, { required: false });
    register({ name: 'useVendorsCustomFields' }, { required: false });

    register({ name: 'startAtDate' }, { required: t('competition.validation.startAtDate') });
    register({ name: 'startAtTime' }, { required: t('competition.validation.startAtTime') });
    register({ name: 'endAtDate' }, { required: t('competition.validation.endAtDate') });
    register({ name: 'endAtTime' }, { required: t('competition.validation.endAtTime') });
    register({ name: 'timezone' }, { required: false });
    register({ name: 'donations' }, { required: false });
    register({ name: 'donationsDescription' }, { required: false });
    register({ name: 'hideLeaderboard' }, { required: false });
    register({ name: 'hidePoints' }, { required: false });
    register({ name: 'fullTeamRegistration' }, { required: false });

    register({ name: 'channelsTaxId' }, { required: false });
    register({ name: 'customFields' }, { required: false });

    register({ name: 'invitesOnly' }, { required: false });
    register({ name: 'hideWatch' }, { required: false });
    register({ name: 'hideJudge' }, { required: false });
    register({ name: 'multiDivisionRegistrations' }, { required: false });
    register({ name: 'payPerDivision' }, { required: false });
    register({ name: 'allowWaitlist' }, { required: false });

    register({ name: 'messages' }, { required: false });
    register({ name: 'vendorsCategories' }, { required: false });
    register({ name: 'volunteersCategories' }, { required: false });
    register({ name: 'stageLocations' }, { required: false });
    register({ name: 'darkBackground' }, { required: false });
    register({ name: 'backgroundColor' }, { required: false });
    register({ name: 'registrationCloseDate' }, { required: false });
    register({ name: 'currencyId' }, { required: false });

    register({ name: 'backgroundImage' }, { required: false });
    register({ name: 'designBackgroundImage' }, { required: false });
    register({ name: 'designStoryBackgroundImage' }, { required: false });
    register({ name: 'designLogoImage' }, { required: false });
    register({ name: 'mobileBackgroundImage' }, { required: false });
    register({ name: 'timerLogo' }, { required: false });
    register({ name: 'cardImage' }, { required: false });
    register({ name: 'leaderboardLogo' }, { required: false });
  }, [register, t]);

  useEffect(() => {
    if (mode === 'new') {
      setTimeout(() => {
        setValue('leaderboardOrder', 'highest');
        setValue('buttonColor', primary);
        setValue('buttonColor', primary);
        setValue('eventType', 'individual');
        setValue('donations', false);
        setValue('online', false);
        setValue('inPerson', true);
        setValue('currencyId', 1);
      }, 300);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [competition, mode]);

  useEffect(() => {
    if (mode === 'edit') {
      dispatch(competitionsActions.getCompetitionStart(id));
    } else {
      dispatch(competitionsActions.clearCompetition());
    }
  }, [dispatch, mode, id]);

  const populateInvites = useCallback(() => {
    setValue('invites', competition.invites ? [...competition.invites] : []);
  }, [competition, setValue]);

  useEffect(() => {
    if (competition && modeComp === 'edit') {
      dispatch(appActions.setLoading(true));
      const wk = {
        ...competition,
        vendorsCategories: competition?.vendorsCategories?.map((item) => ({
          ...item,
          price: item?.price && currencyEdit(item?.price),
        })),
      };

      reset(wk);
      setLocation(wk?.location);
      setSelectedChannel(competition.channel);
      setVideo(competition.previewVideo);
      if (competition.id) {
        setTimeout(() => {
          setValue('startAtDate', formatDateApi(wk.startAt, 'YYYY-MM-DD HH:mm:ss Z', wk.timezone));
          setValue('startAtTime', formatDateApi(wk.startAt, 'YYYY-MM-DD HH:mm:ss Z', wk.timezone));
          setValue('endAtDate', formatDateApi(wk.endAt, 'YYYY-MM-DD HH:mm:ss Z', wk.timezone));
          setValue('endAtTime', formatDateApi(wk.endAt, 'YYYY-MM-DD HH:mm:ss Z', wk.timezone));
          setValue(
            'registrationCloseDate',
            wk.registrationCloseDate &&
              formatDateApi(wk.registrationCloseDate, 'YYYY-MM-DD', wk.timezone),
          );
          populateInvites();

          if (competition?.timerLogo) {
            setValue('timerLogo', {
              id: competition?.timerLogo?.id,
              url: competition?.timerLogo?.image?.url,
            });
          }
          if (competition?.leaderboardLogo) {
            setValue('leaderboardLogo', {
              id: competition?.leaderboardLogo?.id,
              url: competition?.leaderboardLogo?.image?.url,
            });
          }
          if (competition?.cardImage) {
            setValue('cardImage', {
              id: competition?.cardImage?.id,
              url: competition?.cardImage?.image?.url,
            });
          }
          if (competition?.backgroundImage) {
            setValue('backgroundImage', {
              id: competition?.backgroundImage?.id,
              url: competition?.backgroundImage?.image?.url,
            });
          }
          if (competition?.designBackgroundImage) {
            setValue('designBackgroundImage', {
              id: competition?.designBackgroundImage?.id,
              url: competition?.designBackgroundImage?.image?.url,
            });
          }
          if (competition?.designStoryBackgroundImage) {
            setValue('designStoryBackgroundImage', {
              id: competition?.designStoryBackgroundImage?.id,
              url: competition?.designStoryBackgroundImage?.image?.url,
            });
          }
          if (competition?.designLogoImage) {
            setValue('designLogoImage', {
              id: competition?.designLogoImage?.id,
              url: competition?.designLogoImage?.image?.url,
            });
          }
          if (competition?.mobileBackgroundImage) {
            setValue('mobileBackgroundImage', {
              id: competition?.mobileBackgroundImage?.id,
              url: competition?.mobileBackgroundImage?.image?.url,
            });
          }
          if (competition?.banner?.images?.length > 0) {
            const currentImg = competition?.banner?.images[0];

            setBanner({
              id: competition?.banner?.id,
              url: currentImg?.url,
              image: {
                url: currentImg?.image?.image?.url,
              },
            });
          }

          if (wk.channel?.currency) {
            setCurrency(wk.channel?.currency);
          } else if (wk.currency) {
            setCurrency(wk.currency);
          }

          setDivisions(competition.divisions ? [...competition.divisions] : []);
          setCoupons(competition.coupons ? [...competition.coupons] : []);
          dispatch(appActions.setLoading(false));
        }, 100);
      }
    }
  }, [competition, reset, populateInvites, setValue, modeComp, dispatch]);

  const validateCompetition = (data) => {
    const errorsValidation = [];

    if (errorsValidation.length > 0) {
      setErrorsPopup((prev) => [...prev, ...errorsValidation]);
      return false;
    }

    const startAtDate = `${data.startAtDate.substr(0, 10)} ${data.startAtTime.substr(11, 15)}`;
    if (!isAfterNow(startAtDate)) {
      errorsValidation.push(`${t('competition.validate.startAtDate')}`);
    }

    const endAtDate = `${data.endAtDate.substr(0, 10)} ${data.endAtTime.substr(11, 15)}`;
    if (!isAfterNow(endAtDate)) {
      errorsValidation.push(`${t('competition.validate.endAtDate')}`);
    }

    if (data.eventType === 'team' && !data.teamSize) {
      errorsValidation.push(`${t('competition.validate.teamSize')}`);
    }
    return true;
  };

  const confirmCreate = (competitionData, callback) => {
    dispatch(
      competitionsActions.postCompetitionStart({
        data: competitionData,
        messageSuccess: t('message.success.save.competition'),
        callback,
      }),
    );
  };

  const onSubmit = (data) => {
    if (submiting) {
      return;
    }
    setSubmiting(true);

    setTimeout(() => {
      if (!validateCompetition(data)) {
        setSubmiting(false);
        return;
      }

      let videoData = video && video.data ? video.data.split('base64,')[1] : null;
      if (video && video.data) {
        if (video.data.indexOf('webm') > -1) {
          videoData = `data:video/webm;base64,${videoData}`;
        } else {
          videoData = `data:video/mov;base64,${videoData}`;
        }
      }

      const timezone = data.timezone || timeZoneName();
      let startAt = '';
      let endAt = '';
      let registrationCloseDate = '';

      startAt = formatDateToApi(
        `${data.startAtDate.substr(0, 10)} ${data.startAtTime.substr(11, 8)}`,
        timezone,
      );
      endAt = formatDateToApi(
        `${data.endAtDate.substr(0, 10)} ${data.endAtTime.substr(11, 8)}`,
        timezone,
      );

      if (data?.registrationCloseDate) {
        registrationCloseDate = formatDateToApi(`${data.registrationCloseDate.substr(0, 10)}`);
      }

      const bannerImagesAttributes = [];

      const currentImg = competition?.banner?.images[0];
      if (banner?.image?.new) {
        if (currentImg) {
          bannerImagesAttributes.push({ id: currentImg?.id, _destroy: true });
        }
        bannerImagesAttributes.push({
          url: banner?.url,
          order: 1,
          imageAttributes: { ...banner?.image },
        });
      } else {
        bannerImagesAttributes.push({
          id: currentImg?.id,
          url: banner?.url,
        });
      }

      let bannerAttributes = null;
      if (banner?.image?.url) {
        bannerAttributes = {
          durationSeconds: 8,
          imagesAttributes: bannerImagesAttributes,
        };
        if (banner?.id) {
          bannerAttributes.id = banner?.id;
        }
      }

      const formData = { ...data };

      delete formData.eventType;
      delete formData.isPrivate;
      delete formData.teamSize;
      delete formData.invites;
      delete formData.volunteersCategories;
      delete formData.vendorsCategories;
      delete formData.stageLocations;
      delete formData.customFields;
      delete formData.backgroundImage;
      delete formData.designBackgroundImage;
      delete formData.designStoryBackgroundImage;
      delete formData.designLogodImage;
      delete formData.mobileBackgroundImage;
      delete formData.timerLogo;
      delete formData.cardImage;
      delete formData.leaderboardLogo;

      const competitionData = {
        ...formData,
        eventType: data.eventType || 'individual',
        isPrivate: data.isPrivate || false,
        teamSize: data.teamSize || 2,
        startAt,
        endAt,
        timezone,
        registrationCloseDate,
        channelId: selectedChannel?.id,
        previewVideoAttributes: {
          video: videoData,
        },
        invitesAttributes: data.invites,
        divisionsAttributes: divisions,
        couponsAttributes: coupons,
        messagesAttributes: data?.messages,
        volunteersCategoriesAttributes: data?.volunteersCategories,
        vendorsCategoriesAttributes: data?.vendorsCategories,
        stageLocationsAttributes: data?.stageLocations,
        customFieldsAttributes: data?.customFields,

        backgroundImageAttributes: data?.backgroundImage,
        designBackgroundImageAttributes: data?.designBackgroundImage,
        designStoryBackgroundImageAttributes: data?.designStoryBackgroundImage,
        designLogoImageAttributes: data?.designLogoImage,
        mobileBackgroundImageAttributes: data?.mobileBackgroundImage,
        timerLogoAttributes: data?.timerLogo,
        cardImageAttributes: data?.cardImage,
        leaderboardLogoAttributes: data?.leaderboardLogo,

        bannerAttributes,
        location,
      };

      const callback = (comp) => {
        setModeComp('edit');
        if (modeComp === 'new') {
          window.location = `/competition/edit/${comp?.id}`;
        }

        if (!comp.paidAt) {
          setOpenModalPayment({
            open: true,
            new: false,
          });
        }
      };

      if (modeComp === 'new') {
        if (selectedChannel?.competitionActivePlan) {
          confirmCreate(competitionData, callback);
        } else {
          setOpenModalPayment({
            open: true,
            new: true,
            confirm: (userPaymentMethodId) =>
              confirmCreate({ ...competitionData, userPaymentMethodId }, callback),
          });
        }
      } else {
        dispatch(
          competitionsActions.putCompetitionStart({
            data: { id: competition.id, ...competitionData },
            messageSuccess: t('message.success.save.competition'),
            callback,
          }),
        );
      }
    }, 3000);
  };

  const selectChannel = (channel) => {
    if (channel) {
      setSelectedChannel(channel);
    }
  };

  useEffect(() => {
    if (errorsPopup.length > 0) {
      setPopUpValidation(true);
    }
  }, [errorsPopup]);

  useEffect(() => {
    if (errors) {
      const er = Object.keys(errors).map((key) => errors[key].message);
      setErrorsPopup(() => [...er]);
      if (Object.keys(errors).length > 0) {
        validateTime();
      }
    }
  }, [errors]); //eslint-disable-line

  const setImage = (prop, img) => {
    setValue(prop, img);
  };

  const deleteImage = (prop) => {
    if (watch(prop)?.id) {
      setImage(prop, { id: watch(prop)?.id, _destroy: true });
    } else {
      setImage(prop, null);
    }
  };

  return {
    register,
    errors,
    control,
    setValue,
    getValues,
    watch,
    loading,
    competition,
    mode: modeComp,
    selectedChannel,
    setModalPreviewVideo,
    modalPreviewVideo,
    video,
    setVideo,
    setSections,
    sections,
    setFilters,
    filters,
    invites,
    setInvites,
    submiting,
    scheduleOptions,
    setScheduleOptions,
    setOpenModalPayment,
    openModalPayment,
    popUpValidation,
    selectChannel,
    submitForm: handleSubmit(onSubmit),
    divisions,
    setDivisions,
    coupons,
    setCoupons,
    errorsPopup,
    setPopUpValidation,
    setSelectedChannel,

    setSubmiting,
    banner,
    setBanner,
    location,
    setLocation,
    currency,
    setCurrency,
    setImage,
    deleteImage,
  };
};

export default useCompetitionForm;
