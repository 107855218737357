import React, { useContext, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from '@material-ui/core';
import { LayoutContext } from 'pages/shared/Layout';
import { channelsActions } from 'modules/channels';
import { useParams } from 'react-router';
import Show from 'components/Channel/Show';
import Texture from 'core/ui/Texture';
import useDeepLinks, { AppScreens } from 'core/useDeepLinks';
import { sessionSelectors } from 'modules/session';

const ShowChannel = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const layoutContext = useContext(LayoutContext);
  const isAuthenticated = useSelector((state) => sessionSelectors.getIsAuthenticated(state));

  const { id } = useParams();

  const deep = useDeepLinks();

  useEffect(() => {
    deep.openInApp({ screen: AppScreens.Channel.View, params: id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(channelsActions.getChannelStart(id));
    }
  }, [dispatch, id, isAuthenticated]);

  useEffect(() => {
    layoutContext.setTitleBar(t('title.gym').toUpperCase());
    layoutContext.setActiveMenu('channel');
  }, [layoutContext, t]);

  return (
    <Texture
      fullHeight
      heightBanner={layoutContext.heightBanner}
      heightHeader={layoutContext.heightHeader}
    >
      <Container maxWidth="xl">
        <Box pt={5} pb={5}>
          <Show />
        </Box>
      </Container>
    </Texture>
  );
};

export default ShowChannel;
