import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

import * as selectors from './selectors';
import * as sagas from './sagas';

/* ---------- Action Creators and Types ---------- */
const { Types, Creators } = createActions(
  {
    postFavoriteError: ['error'],
    postFavoriteStart: ['data'],
    postFavoriteSuccess: ['favorite'],

    deleteFavoriteError: ['error'],
    deleteFavoriteStart: ['data'],
    deleteFavoriteSuccess: [],

    getFavoriteError: ['error'],
    getFavoriteStart: ['klass', 'klassId'],
    getFavoriteSuccess: ['favorite'],

    getFavoritesError: ['error'],
    getFavoritesStart: ['pagination'],
    getFavoritesSuccess: ['favorites', 'pagination'],
  },
  { prefix: 'favorites/' },
);

/* ---------- Initial State ---------- */
const INITIAL_STATE = Immutable({
  data: [],
  favorite: {},
  pagination: {},
  error: null,
  loading: false,
});

/* ---------- Reducers ---------- */
const loadStartReducer = (state) => state.set('loading', true);

const postFavoriteSuccessReducer = (state, action) =>
  state.merge({
    favorite: action.favorite,
    loading: false,
    error: null,
  });

const getFavoritesSuccessReducer = (state, action) =>
  state.merge({
    data: action.favorites,
    pagination: action.pagination,
    loading: false,
    error: null,
  });

const getFavoriteSuccessReducer = (state, action) =>
  state.merge({
    favorite: action.favorite,
    loading: false,
    error: null,
  });

const deleteFavoriteSuccessReducer = (state) =>
  state.merge({
    favorite: {},
    loading: false,
    error: null,
  });

const loadErrorReducer = (state, action) =>
  state.merge({
    loading: false,
    error: action.error,
  });

/* ---------- Main Reducer ---------- */
const reducer = createReducer(INITIAL_STATE, {
  [Types.POST_FAVORITE_START]: loadStartReducer,
  [Types.POST_FAVORITE_SUCCESS]: postFavoriteSuccessReducer,
  [Types.POST_FAVORITE_ERROR]: loadErrorReducer,

  [Types.DELETE_FAVORITE_START]: loadStartReducer,
  [Types.DELETE_FAVORITE_SUCCESS]: deleteFavoriteSuccessReducer,
  [Types.DELETE_FAVORITE_ERROR]: loadErrorReducer,

  [Types.GET_FAVORITE_START]: loadStartReducer,
  [Types.GET_FAVORITE_SUCCESS]: getFavoriteSuccessReducer,
  [Types.GET_FAVORITE_ERROR]: loadErrorReducer,

  [Types.GET_FAVORITES_START]: loadStartReducer,
  [Types.GET_FAVORITES_SUCCESS]: getFavoritesSuccessReducer,
  [Types.GET_FAVORITES_ERROR]: loadErrorReducer,
});

/* ---------- Exporting ---------- */
export const favoritesActions = Creators;
export const favoritesTypes = Types;
export const favoritesSelectors = selectors;
export const favoritesSagas = sagas;

export default reducer;
