import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Box, Paper } from '@material-ui/core';
import { useSelector } from 'react-redux';

import Modules from 'modules';
import useActions from 'modules/map/useActions';
import { showAPIError } from 'core/utils/showAPIError';
import { getChannel } from 'modules/channels/sagas';
import Typography, { fontStyle } from 'core/ui/Typography';

import { channelCurrency, date } from 'core/utils/formatters';

export const ChannelKeyNextCostViewer = ({
  userId,
  channelMembershipPlanId,
  channelMembershipCouponId,
  startAt,
  chargeGymAmount,
  className,
}) => {
  const { t } = useTranslation();
  const { request } = useActions();
  const channel = useSelector(getChannel);

  const [currentAmounts, setCurrentAmounts] = useState(null);

  useEffect(() => {
    request({
      action: Modules.newChannelMembers.actions.getNextMembershipCharge,
      data: {
        userId,
        channelId: null,
        channelMembershipPlanId,
        channelMembershipCouponId,
        startAt,
        chargeGymAmount,
      },
      options: {
        onSuccess: setCurrentAmounts,
        onError: showAPIError,
      },
    });
  }, [
    request,
    userId,
    channelMembershipPlanId,
    channelMembershipCouponId,
    startAt,
    chargeGymAmount,
  ]);

  if (!currentAmounts) return null;

  return (
    <Paper className={clsx('tw-p-1.5', className)}>
      <Typography color="secondary" mb={1} style={{ ...fontStyle.large }}>
        {t('channel.membership.chargeDate')}:{' '}
        {date(currentAmounts.startAt, channel?.dateFormatReact)}
      </Typography>

      <Typography mb={1} style={fontStyle.label}>
        {t('channel.membership.cost')}:{' '}
        {channelCurrency(currentAmounts?.nextCostWithoutTax, channel?.currency)}
      </Typography>

      <Typography mb={1} color="secondary" style={fontStyle.label}>
        {t('channel.membership.taxAmount')}:{' '}
        {channelCurrency(currentAmounts?.taxCost, channel?.currency)}
      </Typography>

      <Typography mb={1} color="primary" style={fontStyle.label}>
        {t(
          currentAmounts?.items?.length > 1
            ? 'channel.membership.costNextTransactions'
            : 'channel.membership.costNextRenew',
        )}
        : {channelCurrency(currentAmounts?.nextCost, channel?.currency)}
      </Typography>

      {currentAmounts?.items?.length > 1 && (
        <>
          <Typography>Charges: {currentAmounts?.items?.length}</Typography>
          {currentAmounts?.items?.map((charge, index) => (
            <Box key={index} display="flex">
              <Typography mb={1} color="primary" style={fontStyle.label}>
                {date(charge.startAt, channel?.dateFormatReact)}:{' '}
                {channelCurrency(charge?.nextCost, channel?.currency)}
              </Typography>
            </Box>
          ))}
        </>
      )}
    </Paper>
  );
};
