import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import DatePickerRounded from 'core/ui/DatePickers/DatePickerRounded';
import { ButtonPrimarySm } from 'core/ui/Button';
import { Search } from '@material-ui/icons';
import { formatDateToApi } from 'core/utils/formatters';
import { channelReportsModule } from 'modules/channelReports';
import useSelectors from 'modules/map/useSelectors';
import PaymentsReportContent from 'components/Channel/Manage/Tabs/Reports/Payments/ReportContent';

import useActions from 'modules/map/useActions';
import Modules from 'modules';
import BarMonths from 'components/Channel/Manage/Tabs/Reports/Payments/BarMonths';
import AdminChannelSelector from 'components/Admin/ChannelSelector';

const AdminPaymentsReport = () => {
  const { t } = useTranslation();
  const { request } = useActions();
  const [channel, setChannel] = useState([]);
  const loading = useSelectors(channelReportsModule, 'loading');
  const data = useSelectors(channelReportsModule, 'data');
  const dateNow = new Date();
  const [filters, setFilters] = useState({
    startAt: `${dateNow.getMonth() + 1}/1/${dateNow.getFullYear()}`,
    endAt: `${dateNow.getMonth() + 1}/${dateNow.getDate()}/${dateNow.getFullYear()}`,
    workoutId: '',
  });

  const getReport = (params = {}) => {
    if (channel && channel?.id > 0) {
      request({
        action: Modules.channelReports.actions.channelReport,
        data: {
          channelId: channel?.id,
          report: 'Payments',
          ...params,
        },
      });
    }
  };

  const search = () => {
    const startAt = filters.startAt ? formatDateToApi(filters.startAt) : null;
    const endAt = filters.endAt ? formatDateToApi(filters.endAt) : null;
    if (startAt && endAt) getReport({ startAt, endAt });
  };

  useEffect(() => {
    if (channel) {
      search();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel]);

  return (
    <Box mt={2}>
      <Box>
        <Box display="flex" mb={3} alignItems="flex-end" justifyContent="center" flexWrap="wrap">
          <Box ml={1}>
            <DatePickerRounded
              label={t('channel.reports.filter.start').toUpperCase()}
              onChange={(v) => setFilters((prev) => ({ ...prev, startAt: v }))}
              value={filters.startAt}
              disablePast={false}
              autoOk
            />
          </Box>
          <Box ml={1}>
            <DatePickerRounded
              label={t('channel.reports.filter.end').toUpperCase()}
              onChange={(v) => setFilters((prev) => ({ ...prev, endAt: v }))}
              value={filters.endAt}
              disablePast={false}
              autoOk
            />
          </Box>
          <Box ml={3}>
            <ButtonPrimarySm disabled={loading} onClick={() => search()}>
              <Search style={{ color: 'white' }} fontSize="small" />
              {t('button.search')}&nbsp;
            </ButtonPrimarySm>
          </Box>
        </Box>
        <Box>
          <AdminChannelSelector selectedChannel={channel} setSelectedChannel={setChannel} />
        </Box>

        {!!channel?.id && (
          <>
            <PaymentsReportContent
              channelId={channel?.id}
              getReport={getReport}
              data={data}
              loading={loading}
            />

            <Box mt={3}>
              <BarMonths channelId={channel?.id} />
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default AdminPaymentsReport;
