import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from 'core/ui/Dialog/DialogTitle';
import Texture from 'core/ui/Texture';
import Box from 'core/ui/Box';
import { useDispatch, useSelector } from 'react-redux';
import Paper from 'core/ui/Paper';
import Typography from 'core/ui/Typography';
import { ButtonPrimary } from 'core/ui/Button';
import { paymentsActions, paymentsSelectors } from 'modules/payments';
import LoaderSm from 'core/ui/LoaderSm';
import SelectorPaymentMethod, { PaymentMethodViewType } from 'components/Account/Selector';

export default function ModalRetryPayment({
  open,
  close,
  payment,
  showPaymentMethodsSelector = false,
  showPlanName = false,
  userId = null,
  callback,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loading = useSelector((state) => paymentsSelectors.getLoading(state));

  // Selector
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [viewMethodPayment, setViewMethodPayment] = useState(PaymentMethodViewType.list);

  const confirm = () => {
    dispatch(
      paymentsActions.retryPaymentStart(
        { paymentId: payment.id, paymentMethodId: selectedPaymentMethod?.id },
        () => {
          if (!callback) {
            close?.();
            window.location = '/payments';
          } else {
            callback();
          }
        },
      ),
    );
  };

  return (
    <Dialog maxWidth="sm" scroll="body" onClose={close} open={open} fullWidth>
      <Texture>
        <DialogTitle onClose={close}>{t('payment.retry')}</DialogTitle>
        <Box p={5}>
          <Paper p={5} className="paper-rounded">
            <Typography color="secondary" align="center" variant="h2">
              {t(`payment.type.${payment.kind}`)}
            </Typography>

            <Typography align="center">
              {showPlanName ? payment.ownerable.membershipPlan.name : payment.summary}
            </Typography>

            <Typography mt={3} align="center" color="primary" variant="h1">
              {payment.amount}
            </Typography>
          </Paper>

          {showPaymentMethodsSelector && (
            <SelectorPaymentMethod
              selectedPaymentMethod={selectedPaymentMethod}
              setSelectedPaymentMethod={setSelectedPaymentMethod}
              view={viewMethodPayment}
              setView={setViewMethodPayment}
              userId={userId}
              buttonColor="#333333"
              cardpointe={false}
            />
          )}

          <Box flexGrow={1} px={5} mt={4}>
            <ButtonPrimary disabled={loading} fullWidth onClick={confirm}>
              <LoaderSm loading={loading} />
              {t('button.confirm')}
            </ButtonPrimary>
          </Box>
        </Box>
      </Texture>
    </Dialog>
  );
}

ModalRetryPayment.defaultProps = {
  open: false,
  close: null,
  userId: null,
};
