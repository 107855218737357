import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from 'core/ui/Box';
import { secondaryLight } from 'core/ui/Colors';
import { IconButton, colors } from '@material-ui/core';
import { Add, Refresh } from '@material-ui/icons';
import Button from 'core/ui/Button';
import { WorkflowContext } from 'contexts';
import useSelectors from 'modules/map/useSelectors';
import { automationWorkflowsModule } from 'modules/automationWorkflows';
import LoaderSm from 'core/ui/LoaderSm';
import { ActionItem } from './ActionItem';
import { WorkflowTypesList, GAP } from './WorkflowTypesList';

export default function WorkflowOverview({ setTab, allowEdit }) {
  const classes = useStyles();
  const loadingWorkflow = useSelectors(automationWorkflowsModule, 'loadingWorkflow');

  const workFlowContext = useContext(WorkflowContext);

  const haveWorkflowTypes = workFlowContext.workflowTypesWithoutDestroyed.length > 0;

  return (
    <>
      <Box className={classes.boxActionsMenu}>
        {workFlowContext?.workflow?.id && (
          <IconButton onClick={() => workFlowContext?.refreshWorkflow()}>
            {loadingWorkflow ? <LoaderSm loading={loadingWorkflow} /> : <Refresh />}
          </IconButton>
        )}
      </Box>

      <Box className={classes.root}>
        <Box className={classes.lineCenter} />

        <WorkflowTypesList allowEdit={allowEdit} setTab={setTab} />

        <div className={classes.footer}>
          {haveWorkflowTypes ? (
            <ActionItem
              bgIconColor={colors.blue[100]}
              iconColor={colors.blue[700]}
              icon="add"
              label="Add a action"
              onClick={() => workFlowContext.addWorkflowType('action')}
              active={
                workFlowContext?.currentType?.open &&
                !workFlowContext?.currentType?.workflowType &&
                !workFlowContext?.currentType?.index &&
                workFlowContext?.currentType?.index !== 0
              }
            />
          ) : (
            <ActionItem
              bgIconColor={colors.green[100]}
              iconColor={colors.green[700]}
              icon="add"
              label="Create a trigger"
              onClick={() => workFlowContext.addWorkflowType('trigger')}
              active={
                workFlowContext?.currentType?.open && !workFlowContext?.currentType?.workflowType
              }
            />
          )}

          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() =>
              workFlowContext.setCurrentType({
                open: true,
                type: haveWorkflowTypes ? 'action' : 'trigger',
              })
            }
          >
            <Add />
          </Button>
        </div>
      </Box>
    </>
  );
}

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: 12,
  },
  lineCenter: {
    top: 0,
    bottom: 0,
    position: 'absolute',
    backgroundColor: secondaryLight,
    width: 2,
    zIndex: 0,
  },
  boxActionsMenu: {
    position: 'absolute',
    right: 5,
    top: 5,
    zIndex: 20,
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: GAP,
    gap: GAP,
  },
}));
