import React, { createContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormLogin from 'components/Login/FormLogin';

import FormConfirmCode from 'components/Login/FormConfirmCode';
import Box from 'core/ui/Box';
import useFormLogin from './useFormLogin';

export const Context = createContext();

const Form = ({ callback, channelId, fromModal }) => {
  const useStyles = makeStyles(() => ({
    paper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    form: {
      borderRadius: 10,
      minWidth: '100%',
      maxWidth: 400,

      backgroundColor: !fromModal ? 'rgba(64, 64, 65, 0.70)' : 'transparent',
      marginTop: 3,
    },
  }));

  const classes = useStyles();

  const {
    tryWithEmail,
    reSendCode,
    onSubmit,
    register,
    handleSubmit,
    errors,
    setValue,
    watch,
    step,
    setDataForm,
    input,
    dataForm,
    recaptchaRef,
  } = useFormLogin({ callback, fromModal, channelId });

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.form} autoComplete="one-time-code">
      <Context.Provider
        value={{
          register,
          errors,
          dataForm,
          reSendCode,
          setValue,
          watch,
          input,
          setInput: (v) => setValue('input', v),
          tryWithEmail,
          recaptchaRef,
          step,
          setDataForm,
        }}
      >
        <Box p={2} px={4}>
          {(step === 'submit' || step === 'newUser') && <FormLogin />}
          {step === 'confirm' && <FormConfirmCode />}
        </Box>
      </Context.Provider>
    </form>
  );
};
export default Form;
