import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Typography, { fontStyle } from 'core/ui/Typography';
import Box from 'core/ui/Box';
import { Link, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import LoaderSm from 'core/ui/LoaderSm';
import { AccountBalance, Payment, Warning } from '@material-ui/icons';
import { secondary } from 'core/ui/Colors';
import Scrollbars from 'react-custom-scrollbars';
import { date } from 'core/utils/formatters';
import useSelectors from 'modules/map/useSelectors';
import { ownerablePaymentMethodsModule } from 'modules/ownerablePaymentMethods';
import useActions from 'modules/map/useActions';
import Modules from 'modules';

const ManageChannelPaymentMethods = ({ ownerableId, ownerableType, setOpenCollapse }) => {
  const { t } = useTranslation();
  const { request } = useActions();
  const data = useSelectors(ownerablePaymentMethodsModule, 'data');
  const loading = useSelectors(ownerablePaymentMethodsModule, 'loading');

  useEffect(() => {
    if (ownerableId && ownerableType) {
      request({
        action: Modules.ownerablePaymentMethods.actions.getOwnerablePaymentMethods,
        data: { ownerableId, ownerableType },
      });
    }
  }, [request, ownerableId, ownerableType]);

  const remove = (id) => {
    request({
      action: Modules.ownerablePaymentMethods.actions.setInactive,
      data: { ownerableId, ownerableType, id },
    });
  };
  const setDefault = (id) => {
    request({
      action: Modules.ownerablePaymentMethods.actions.setDefault,
      data: { ownerableId, ownerableType, id },
    });
  };

  useEffect(() => {
    if (setOpenCollapse && data?.length === 0) {
      setOpenCollapse(true);
    }
  }, [setOpenCollapse, data]);

  return (
    <Box pb={1} display="flex" flexDirection="column" justifyContent="center">
      {data && data.length === 0 && (
        <Typography m={5} color="primary">
          {t('user.paymentMethods.noExists')}
        </Typography>
      )}

      <Scrollbars autoHeight autoHeightMax={150}>
        <Table size="small">
          <TableBody>
            {data.map((ac) => (
              <TableRow key={ac.id}>
                <TableCell colSpan={ac.method === 'card' ? 1 : 2}>
                  <Box display="flex" alignItems="center">
                    {ac.method === 'card' ? (
                      <Payment style={{ color: secondary }} />
                    ) : (
                      <AccountBalance style={{ color: secondary }} />
                    )}
                    <Typography
                      ml={1}
                      variant="body2"
                      className="roboto-regular"
                      color="textSecondary"
                    >
                      {ac.method === 'card' && (
                        <span style={{ textTransform: 'capitalize' }}>
                          <b>{ac.cardFlag}</b>&nbsp;
                        </span>
                      )}
                      {t('userPaymentMethod.endingIn')} {ac.lastFour}
                    </Typography>
                    {ac.stepVerificationUrl && (
                      <Box ml={3} display="flex" alignItems="center">
                        <Warning style={{ fontSize: 16 }} />
                        <Link style={fontStyle.small} href={ac.stepVerificationUrl} target="_blank">
                          {t('payment.method.verificationStep')}
                        </Link>
                      </Box>
                    )}
                  </Box>
                </TableCell>
                {ac.method === 'card' && (
                  <TableCell>
                    <Typography variant="body2" className="roboto-regular" color="textSecondary">
                      {date(ac.expiryAt, 'yyyy/MM')}
                    </Typography>
                  </TableCell>
                )}
                <TableCell>
                  {!ac.default && (
                    <Typography
                      onClick={() => setDefault(ac.id)}
                      color="primary"
                      className="link-hover"
                    >
                      {t('button.setDefault')}
                    </Typography>
                  )}
                </TableCell>
                <TableCell>
                  <Typography onClick={() => remove(ac.id)} color="primary" className="link-hover">
                    {t('button.remove')}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Scrollbars>
      <Box display="flex" justifyContent="center">
        <LoaderSm loading={loading} />
      </Box>
    </Box>
  );
};

export default ManageChannelPaymentMethods;
