import React from 'react';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import useSelectors from 'modules/map/useSelectors';
import { automationTypesModule } from 'modules/automationTypes';
import Typography, { fontStyle } from 'core/ui/Typography';
import { Tooltip, colors, makeStyles } from '@material-ui/core';
import MIcon from 'core/ui/MIcon';
import { secondaryXLight } from 'core/ui/Colors';
import { Info } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    backgroundColor: 'white',
    padding: 10,
  },
  cardType: {
    border: `1px solid ${secondaryXLight}`,
  },
  cardTypeIcon: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    width: 40,
  },
}));

export default ({ type, onSelect }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const triggers = useSelectors(automationTypesModule, 'triggers');
  const actions = useSelectors(automationTypesModule, 'actions');

  const data = type === 'trigger' ? triggers : actions;

  const groups = () => {
    const retGroups = {};
    data.forEach((item) => {
      if (!retGroups[item?.category]) {
        retGroups[item.category] = { category: item?.category, data: [] };
      }
      retGroups[item.category].data.push(item);
    });
    return retGroups;
  };

  const typesGroups = groups();

  return (
    <Box className={classes.root}>
      {Object.keys(typesGroups).map((itemG) => (
        <Box key={itemG} mb={2}>
          <Typography color="textSecondary" variant="h6" mb={2}>
            {t(`workflow.types.group.${itemG}`)}
          </Typography>
          {typesGroups[itemG].data.map((item) => (
            <Box
              className={`${classes.cardType} link-hover`}
              key={item?.id}
              onClick={() => onSelect(item)}
            >
              <Box display="flex" className="gap-10">
                <Box
                  className={classes.cardTypeIcon}
                  style={{
                    backgroundColor: type === 'trigger' ? colors.green[200] : colors.blue[200],
                  }}
                >
                  <MIcon
                    icon={item?.icon || 'refresh'}
                    color={type === 'trigger' ? colors.green[700] : colors.blue[700]}
                  />
                </Box>
                <Box
                  flexGrow={1}
                  py={1}
                  style={{ height: 40 }}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                >
                  <Typography color="secondary" className="roboto-medium" style={fontStyle.large}>
                    {item?.name}
                  </Typography>
                  {item?.description && (
                    <Typography
                      className="roboto-regular"
                      variant="caption"
                      color="textSecondary"
                      mt={-1}
                    >
                      {item?.description}
                    </Typography>
                  )}
                </Box>
                {item?.frequencyDescription && (
                  <Box display="flex" alignItems="center" justifyContent="center">
                    <Tooltip title={item?.frequencyDescription}>
                      <Info color="secondary" />
                    </Tooltip>
                  </Box>
                )}
              </Box>
            </Box>
          ))}
        </Box>
      ))}
    </Box>
  );
};
