import React, { useState } from 'react';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import { ButtonPrimary } from 'core/ui/Button';
import Modules from 'modules';
import useActions from 'modules/map/useActions';
import Messages from './Messages';
import ModalFormMessage from './Form/Modal';
import Resume from './Resume';

const messagePersistKey = 'message-persist-communitation-index';

const CommunitationMessages = ({ channel }) => {
  const { t } = useTranslation();
  const { request } = useActions();
  const [modalMessage, setModalMessage] = useState({ open: false });
  const editMessage = (message) => {
    setModalMessage({ open: true, message });
  };

  const copyMessage = (message) => {
    setModalMessage({ open: true, message: { ...message, id: null } });
  };

  return (
    <Box p={3}>
      <Resume channel={channel} />
      <Box display="flex" justifyContent="flex-end" m={3}>
        <ButtonPrimary onClick={() => setModalMessage({ open: true })}>
          {t('button.sendNewMessage')}
        </ButtonPrimary>
      </Box>

      <Box my={3}>
        {modalMessage?.open && (
          <ModalFormMessage
            open={modalMessage?.open}
            close={() => setModalMessage({ open: false })}
            ownerableId={channel?.id}
            ownerableType="Channel"
            message={modalMessage?.message}
            callback={() => {
              setModalMessage({ open: false });
              request({
                action: Modules.ownerableMessages.actions.getMonthResume,
                data: { channelId: channel?.id },
              });
            }}
            persistKey={messagePersistKey}
          />
        )}
        <Messages
          ownerableId={channel?.id}
          ownerableType="Channel"
          copyMessage={copyMessage}
          editMessage={editMessage}
        />
      </Box>
    </Box>
  );
};

export default CommunitationMessages;
