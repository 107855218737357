import api from 'core/api';

export const userChildrenModule = 'userChildren';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const actions = {
  getChildren: {
    module: userChildrenModule,
    name: 'getChildren',
    api: (params) => api.get(`/user_children`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: ['data'],
    },
    state: loadingStates,
  },
  postChild: {
    module: userChildrenModule,
    name: 'postChild',
    api: (data) => api.post(`/user_children`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  putChild: {
    module: userChildrenModule,
    name: 'putChild',
    api: (data) => api.put(`/user_children/${data.childId}`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  removeChild: {
    module: userChildrenModule,
    name: 'removeChild',
    api: ({ userId, childId }) => api.delete(`/user_children/${childId}`, { params: { userId } }),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
};

export default {
  actions,
  state: {
    loading: false,
    data: [],
    child: null,
  },
};
