import React, { useEffect, useState } from 'react';
import Box from 'core/ui/Box';
import { useSelector } from 'react-redux';
import { sessionSelectors } from 'modules/session';
import Grid from 'core/ui/Grid';
import LibraryMedias from 'components/LibraryMedias';
import Paper from 'core/ui/Paper';
import PaymentMethods from 'components/OwnerablePaymentMethods';
import Typography from 'core/ui/Typography';
import { useHistory, useParams } from 'react-router';
import useSelectors from 'modules/map/useSelectors';
import { partnersModule } from 'modules/partners';
import Modules from 'modules';
import useActions from 'modules/map/useActions';
import { Collapse } from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowRight } from '@material-ui/icons';
import PartnerDetail from './Detail';
import Store from './Store/index';
import StripeConnectedAccount from './Store/StripeConnectedAccount';
import Report from './Report/index';
import PartnerList from './List';
import PartnerPrograms from './Programs';
import MenuPartner from './MenuPartner';

const Partner = () => {
  const history = useHistory();
  const { partnerId, screen } = useParams();
  const [adminOpened, setAdminOpened] = useState(false);
  const { request } = useActions();
  const user = useSelector((state) => sessionSelectors.getUser(state));
  const isAdminOrSupport = useSelector((state) => sessionSelectors.getIsAdminOrManager(state));

  const partner = useSelectors(partnersModule, 'partner');

  const getPartner = (id) => {
    request({
      action: Modules.partners.actions.getPartner,
      data: { id },
    });
  };

  useEffect(() => {
    if (user?.partner && !partnerId) {
      getPartner(user?.partner?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (partnerId) {
      getPartner(partnerId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnerId]);

  return (
    <Box px={5}>
      {isAdminOrSupport && (
        <Paper p={3} mb={3}>
          <Box flexGrow="1" display="flex" alignItems="center" className="hover">
            <Box
              flexGrow="1"
              display="flex"
              alignItems="center"
              onClick={() => setAdminOpened((prev) => !prev)}
            >
              <Typography ml={1} color="secondary" className="link" variant="h5">
                Partners
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" onClick={() => setAdminOpened((prev) => !prev)}>
              {adminOpened ? (
                <KeyboardArrowDown style={{ fontSize: 20 }} />
              ) : (
                <KeyboardArrowRight style={{ fontSize: 20 }} />
              )}
            </Box>
          </Box>

          <Collapse in={adminOpened}>
            <PartnerList
              setPartner={(p) => {
                history.push(`/partner/${p?.id}/${screen || 'information'}`);
                setAdminOpened(false);
              }}
            />
          </Collapse>
        </Paper>
      )}

      {partner?.id && (
        <>
          <Paper p={3} mb={3}>
            <Box display="flex">
              <Box flexGrow={1}>
                <Typography variant="h2">{partner?.companyName}</Typography>
              </Box>

              <MenuPartner partner={partner} screen={screen} />
            </Box>
          </Paper>
          {(!screen || screen === 'information') && (
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <PartnerDetail partner={partner} />
              </Grid>
              <Grid item xs={12} md={6}>
                <StripeConnectedAccount
                  currentUser={user}
                  partner={partner}
                  updatePartner={() => getPartner(partnerId)}
                />

                <Box mt={3}>
                  <PaymentMethods ownerableType="Partner" ownerableId={partner?.id} />
                </Box>
              </Grid>
            </Grid>
          )}
          {screen === 'store' && (
            <Box>
              <Store currentUser={user} partner={partner} />
            </Box>
          )}
          {screen === 'library' && (
            <Box>
              <LibraryMedias ownerableId={partner?.id} ownerableType="Partner" />
            </Box>
          )}
          {screen === 'programs' && (
            <Box>
              <PartnerPrograms partner={partner} />
            </Box>
          )}
          {screen === 'reports' && (
            <Box>
              <Report partner={partner} />
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default Partner;
