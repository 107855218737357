import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, TableCell, TableRow, TableBody, TableHead } from '@material-ui/core';
import Box from 'core/ui/Box';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import Button, { ButtonPrimary } from 'core/ui/Button';
import { Add } from '@material-ui/icons';

import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Typography, { fontStyle } from 'core/ui/Typography';
import Paper from 'core/ui/Paper';
import Checkbox from 'core/ui/Checkbox';
import Radio from 'core/ui/Radio';

const Divisions = ({
  divisions,
  setDivisions,
  competition,
  eventType,
  payPerDivision,
  currency,
  invitesOnly,
}) => {
  const { t } = useTranslation();
  const addOption = () => {
    setDivisions((prev) => [
      ...prev,
      { eventType: eventType === 'both' ? 'individual' : competition?.eventType },
    ]);
  };

  useEffect(() => {
    if (divisions.length === 0) {
      addOption();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [divisions]);

  const changeDivision = (prop, value, item) => {
    setDivisions((prev) =>
      prev.map((x) => {
        const newItem = { ...x };
        if (x === item) {
          newItem[prop] = value;
        }

        return newItem;
      }),
    );
  };

  const removeNew = (row) => {
    if (!row?.id) {
      setDivisions(divisions.filter((x) => x !== row));
    } else {
      setDivisions(divisions.map((x) => (x.id === row.id ? { ...x, _destroy: true } : x)));
    }
  };

  return (
    <Box pb={2}>
      <Box display="flex" alignItems="center" pb={3} pt={2}>
        <Box flexGrow="1">
          <Typography variant="h5" mb={3} color="primary">
            {t('competition.divisions')}
          </Typography>
        </Box>
        <Box p={1}>
          <ButtonPrimary onClick={() => addOption()}>
            <Add />
          </ButtonPrimary>
        </Box>
      </Box>

      <Paper p={3}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell />
              {eventType === 'both' && <TableCell />}

              <TableCell>{t('label.limitRegistrations')}</TableCell>
              <TableCell>{t('label.cutLine')}</TableCell>
              {payPerDivision && <TableCell>{t('label.registrationCost')}</TableCell>}
              <TableCell />
              <TableCell />
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {divisions &&
              divisions
                ?.filter((x) => !x._destroy)
                .map((row, index) => (
                  <TableRow key={index.toString()}>
                    <TableCell>
                      <TextFieldRounded
                        value={row.name || ''}
                        onChange={(v) => changeDivision('name', v, row)}
                        placeholder={t('competition.division.label.name')}
                        bordered
                      />
                    </TableCell>
                    {eventType === 'both' && (
                      <TableCell>
                        <Box display="flex" flexDirection="column">
                          <Radio
                            label={
                              <Typography
                                style={fontStyle.xSmall}
                                variant="caption"
                                color="textSecondary"
                              >
                                {t(`competition.label.eventType.individual`).toUpperCase()}
                              </Typography>
                            }
                            onClick={() => changeDivision('eventType', 'individual', row)}
                            checked={row?.eventType === 'individual'}
                          />
                          <Box>
                            <Radio
                              label={
                                <Typography
                                  style={fontStyle.small}
                                  variant="caption"
                                  color="textSecondary"
                                >
                                  {t(`competition.label.eventType.team`).toUpperCase()}
                                </Typography>
                              }
                              onClick={() => changeDivision('eventType', 'team', row)}
                              checked={row?.eventType === 'team'}
                            />
                          </Box>
                        </Box>
                      </TableCell>
                    )}
                    <TableCell>
                      <TextFieldRounded
                        value={row.limitUsers || ''}
                        onChange={(v) => changeDivision('limitUsers', v, row)}
                        placeholder={t('competition.division.label.limitUsers')}
                        bordered
                      />
                    </TableCell>
                    <TableCell>
                      <TextFieldRounded
                        value={row.cutLine || ''}
                        onChange={(v) => changeDivision('cutLine', v, row)}
                        placeholder={t('competition.division.label.cutLine')}
                        bordered
                      />
                    </TableCell>
                    {payPerDivision && (
                      <TableCell>
                        <TextFieldRounded
                          value={row.cost || ''}
                          onChange={(v) => changeDivision('cost', v, row)}
                          placeholder={t('competition.label.cost')}
                          type="number"
                          pattern="[0-9]*"
                          currency
                          bordered
                          currencySymbol={currency?.symbol}
                        />
                      </TableCell>
                    )}
                    {invitesOnly && (
                      <TableCell>
                        <Checkbox
                          label="Skip invites"
                          checked={row?.skipInvites}
                          onClick={() => changeDivision('skipInvites', !row?.skipInvites, row)}
                        />
                      </TableCell>
                    )}
                    <TableCell>
                      <Checkbox
                        label={`Use ${competition?.isTeam ? 'teams' : 'competitors'} numbers`}
                        checked={row?.useTeamNumber}
                        onClick={() => changeDivision('useTeamNumber', !row?.useTeamNumber, row)}
                      />
                      {row?.useTeamNumber && (
                        <Box display="flex" mt={1}>
                          <Box mr={1}>
                            <TextFieldRounded
                              value={row.teamNumberStart || ''}
                              onChange={(v) => changeDivision('teamNumberStart', v, row)}
                              placeholder={t('competition.division.label.teamNumberStart')}
                              bordered
                            />
                          </Box>

                          <Box ml={1}>
                            <TextFieldRounded
                              value={row.teamNumberEnd || ''}
                              onChange={(v) => changeDivision('teamNumberEnd', v, row)}
                              placeholder={t('competition.division.label.teamNumberEnd')}
                              bordered
                            />
                          </Box>
                        </Box>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {!!row?.id && !row?.allowDestroy ? (
                        <div className="tw-text-[8px] tw-text-red-300">
                          {t('label.usersRegistered')}
                        </div>
                      ) : (
                        <Button
                          disabled={!row?.id && !row?.allowDestroy}
                          onClick={() => removeNew(row)}
                        >
                          <HighlightOffIcon />
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </Paper>
    </Box>
  );
};

Divisions.defaultProps = {};

Divisions.propTypes = {};

export default Divisions;
