import React from 'react';
import Box from 'core/ui/Box';
import Typography from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import { secondary } from 'core/ui/Colors';
import LabelBreakLine from 'core/ui/LabelBreakLine';
import { listScores } from 'core/utils/helpers';
import ViewSectionScoresTypes from 'components/Workout/Form/Section/ViewSectionScoresTypes';
import SectionTimerInfo from 'components/Workout/Form/Section/SectionTimerInfo';
import Toggle from 'core/ui/Toggle';
import SectionPersonalRecords from './SectionPersonalRecords';

const ItemDetailSection = ({
  section,
  buttonColor,
  expandView,
  activeTimer,
  setActiveTimer,
  fontSize,
}) => {
  const { t } = useTranslation();
  return (
    <div style={{ flex: 1 }}>
      <SectionPersonalRecords
        fontSize={fontSize}
        section={section}
        textColor={expandView ? 'white' : secondary}
        expandView={expandView}
      />
      <Typography
        mb={3}
        className="roboto-regular"
        style={{ fontSize, color: expandView ? 'white' : secondary, wordBreak: 'break-word' }}
      >
        <LabelBreakLine>{section?.description}</LabelBreakLine>
      </Typography>

      {section?.timerData?.type !== 'none' && (
        <Box display="flex" alignItems="center">
          <SectionTimerInfo
            section={section}
            buttonColor={buttonColor}
            fontSize={fontSize}
            showTimerIcon={!expandView}
            expandView={expandView}
            fontColor={expandView ? 'white' : secondary}
            showTimer={false}
          />
          {expandView && (
            <Box className="hover" ml={3} mt={2} onClick={() => setActiveTimer(!activeTimer)}>
              <Toggle
                onChange={() => setActiveTimer(!activeTimer)}
                options={[
                  { label: 'OFF', value: 'OFF' },
                  { label: 'ON', value: 'ON' },
                ]}
                value={activeTimer ? 'ON' : 'OFF'}
                color="white"
              />
            </Box>
          )}
        </Box>
      )}
      {listScores(section, t) && (
        <Box my={2}>
          <ViewSectionScoresTypes
            scores={section?.scores}
            userScores={section?.userScores}
            fontSize={fontSize}
            buttonColor={buttonColor}
            scoresFontColor={expandView ? 'white' : secondary}
          />
        </Box>
      )}
    </div>
  );
};

export default ItemDetailSection;

ItemDetailSection.defaultProps = {
  section: {},
  index: 0,
  selectTab: null,
  hideSections: true,
  isRegistered: false,
  fontSize: 14,
};
