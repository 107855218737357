import React, { useState } from 'react';
import Typography from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import { Dialog } from '@material-ui/core';
import Box from 'core/ui/Box';
import Texture from 'core/ui/Texture';
import { ButtonPrimary } from 'core/ui/Button';
import LoaderSm from 'core/ui/LoaderSm';
import SelectorChannelUser from 'components/Channel/SelectorChannelUser';
import ModalAddMembers from 'components/Channel/Manage/Tabs/Members/AddMembers/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { channelsSelectors } from 'modules/channels';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import useSelectors from 'modules/map/useSelectors';
import { automationWorkflowsModule } from 'modules/automationWorkflows';
import useChannelStyles from 'core/useChannelStyles';
import notifications from 'modules/notifications';
import DialogTitle from 'core/ui/Dialog/DialogTitle';

export default function ModalAddUsers({ close, open, workflowId, refreshWorkflow }) {
  const { t } = useTranslation();
  const { buttonColor } = useChannelStyles();
  const channel = useSelector((state) => channelsSelectors.getChannel(state));
  const { request } = useActions();
  const loading = useSelectors(automationWorkflowsModule, 'loadingAddUser');
  const [modalAddMember, setModalAddMember] = useState();

  const dispatch = useDispatch();

  const onSelect = (u) => {
    request({
      action: Modules.automationWorkflows.actions.addUserInWorkflow,
      data: {
        ownerableId: channel?.id,
        ownerableType: 'Channel',
        id: workflowId,
        userId: u?.id,
      },
      options: {
        onSuccess: () => {
          refreshWorkflow();
          dispatch(notifications.success(t('user.added.workflow.message')));
        },
      },
    });
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth onClose={() => close()}>
      <DialogTitle onClose={close}>
        <Typography mt={3} align="center" color="secondary" variant="h6">
          {t('workout.modal.searchUser')}
        </Typography>
      </DialogTitle>
      <Texture>
        <Box p={3}>
          {loading && (
            <Box p={5} display="flex" alignItems="center" justifyContent="center">
              <LoaderSm loading={loading} />
            </Box>
          )}

          {modalAddMember && (
            <ModalAddMembers
              callbackSave={onSelect}
              channelId={channel?.id}
              close={() => setModalAddMember(false)}
              form
            />
          )}

          <SelectorChannelUser
            buttonColor={buttonColor}
            onSelectedUser={(u) => onSelect(u)}
            channelId={channel?.id}
            showCreateButton={false}
          />

          <Box p={5} display="flex" alignItems="center" justifyContent="center">
            <ButtonPrimary buttonColor={buttonColor} onClick={() => setModalAddMember(true)}>
              {t('button.createNewUser')}
            </ButtonPrimary>
          </Box>
        </Box>
      </Texture>
    </Dialog>
  );
}

ModalAddUsers.propTypes = {};

ModalAddUsers.defaultProps = {};
