/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React from 'react';
import Paper from 'core/ui/Paper';
import { useTranslation } from 'react-i18next';
import Typography from 'core/ui/Typography';
import SFDataTable from 'core/ui/DataTable';
import { date } from 'core/utils/formatters';
import { Link } from '@material-ui/core';
import Button from 'core/ui/Button';

export default function ResultMembers({ channel, members }) {
  const { t } = useTranslation();
  const columns = [
    {
      name: 'ID',
      selector: 'id',
      cell: (row) => <Typography> {row.id}</Typography>,
      sortable: true,
      width: '100px',
    },
    {
      name: 'Name',
      selector: 'name',
      sortable: true,
      cell: (row) => <Typography>{row.name}</Typography>,
    },
    {
      name: 'Has Waiver',
      selector: 'accepted',
      sortable: true,
      cell: (row) => <Typography> {row.accepted}</Typography>,
    },
    {
      name: 'Type',
      selector: 'waiverDocType',
      sortable: true,
      cell: (row) => <Typography> {row.waiverDocType}</Typography>,
    },
    {
      name: 'Date',
      selector: 'accepted',
      sortable: true,
      cell: (row) => (
        <Typography>
          {' '}
          {row.acceptedDate && date(row.acceptedDate, channel?.dateFormatReact)}
        </Typography>
      ),
    },
    {
      name: '',
      selector: '',
      sortable: true,
      cell: (row) => (
        <>
          {row?.documentUrl && (
            <Link target="_blank" href={row?.documentUrl} className="link-hover">
              <Button>{t('button.view')}</Button>
            </Link>
          )}
        </>
      ),
    },
  ];

  const getRows = () => {
    const rowsMembers =
      members &&
      members.map((member) => ({
        id: member.id,
        name: member.name,
        accepted: member.waiver?.accepted === true ? 'Yes' : 'No',
        documentUrl: member.waiver?.accepted === true ? member.waiver?.documentUrl : null,
        acceptedDate: member.waiver?.accepted === true ? member.waiver?.date : null,
        waiverDocType:
          member.waiver?.accepted === true ? (member.waiver?.pdf ? 'PDF' : 'Custom') : null,
      }));

    return rowsMembers;
  };

  const rows = getRows();

  return (
    <Paper style={{ width: '100%' }}>
      <SFDataTable
        title={t('channel.reports.waivers')}
        columns={columns}
        data={rows}
        pagination
        showExport
        paginationPerPage={20}
      />
    </Paper>
  );
}
