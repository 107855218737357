import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form-v5';
import { useDispatch, useSelector } from 'react-redux';
import { channelsSelectors } from 'modules/channels';
import notifications from 'modules/notifications';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import useSelectors from 'modules/map/useSelectors';
import { channelsCoachesPayrollDeductionsModule } from 'modules/channelsCoachesPayrollDeductions';
import { formatDateApi, formatDateToApi } from 'core/utils/formatters';

export default ({ item, callback, userId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const channel = useSelector((state) => channelsSelectors.getChannel(state));
  const loading = useSelectors(channelsCoachesPayrollDeductionsModule, 'loading');
  const { request } = useActions();
  const { register, handleSubmit, setValue, watch, reset, errors } = useForm();

  const onSubmit = (values) => {
    request({
      action: !item?.id
        ? Modules.channelsCoachesPayrollDeductions.actions.postPayrollDeductions
        : Modules.channelsCoachesPayrollDeductions.actions.putPayrollDeductions,
      data: {
        ...values,
        date: formatDateToApi(values?.date),
        id: item?.id || null,
        channelId: channel.id,
        userId,
      },
      options: {
        onSuccess: () => {
          if (callback) {
            callback(values.name);
          }
          dispatch(notifications.success(t('message.success.save.payrollDeductions')));
        },
      },
    });
  };

  useEffect(() => {
    register('description', { required: true });
    register('date', { required: true });
    register('amount', { required: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register]);

  useEffect(() => {
    if (item && item?.id) {
      reset({
        ...item,
        date: formatDateApi(item?.date),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, item?.id]);

  return {
    register,
    setValue,
    watch,
    reset,
    errors,
    onSubmit,
    handleSubmit,
    loading,
  };
};
