import React, { useEffect } from 'react';
import Box from 'core/ui/Box';
import Typography from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import { competitionsSelectors } from 'modules/competitions/index.js';
import SelectBoxObject from 'core/ui/SelectBoxObject/index.js';
import { labelLight } from 'core/ui/Colors.js';
import Checkbox from 'core/ui/Checkbox/index.js';
import { useSelector } from 'react-redux';
import { dropdownWrapper } from './style.js';

const regTypes = [
  {
    id: 'individual',
    name: 'Individual',
  },
  { id: 'team', name: 'Team' },
];

const LeaderboardFilters = ({
  divisionId,
  setDivisionId,
  currentView,
  setCurrentView,
  withdraw,
  setWithdraw,
  workoutId,
  setWorkoutId,
  regType,
  setRegType,
}) => {
  const { t } = useTranslation();
  const competition = useSelector((state) => competitionsSelectors.getCompetition(state));
  const divisions = competition?.hasDivisions ? competition?.divisions : [];
  const workouts = competition?.workouts || [];

  const viewOptions = [
    {
      id: 'Teams',
      name: competition.isTeam ? 'Teams' : 'Competitors',
    },
    { id: 'Custom', name: 'Custom' },
  ];

  if (competition.hasAffiliate) {
    viewOptions.push({ id: 'Affiliate Gyms', name: 'Affiliate Gyms' });
  }

  useEffect(() => {
    if (divisions?.length > 0 && !divisionId) {
      setDivisionId(divisions[0]?.id);
    }
  }, [divisions, divisionId]);

  return (
    <div className="tw-flex tw-grow tw-flex-row tw-items-end tw-gap-2">
      {divisions && divisions.length > 0 && (
        <Box flexGrow={1}>
          <Box>
            <Typography variant="body2" style={{ color: labelLight }} mb={1}>
              {t('competition.divisions')}
            </Typography>
            <Box style={dropdownWrapper}>
              <SelectBoxObject
                options={divisions}
                value={divisionId}
                setValue={(v) => setDivisionId(v)}
                elevation={0}
              />
            </Box>
          </Box>
        </Box>
      )}
      {!competition?.hasDivisions && competition?.eventType === 'both' && (
        <Box>
          <Typography variant="body2" style={{ color: labelLight }}>
            {t('competition.regType')}
          </Typography>
          <Box style={dropdownWrapper}>
            <SelectBoxObject
              options={regTypes}
              value={regType}
              setValue={(v) => {
                setRegType(v);
              }}
              elevation={0}
            />
          </Box>
        </Box>
      )}
      {setWorkoutId && workouts && workouts.length > 0 && (
        <Box flexGrow={1}>
          <Box>
            <Typography variant="body2" style={{ color: labelLight }} mb={1}>
              {t('competition.workouts')}
            </Typography>
            <Box style={dropdownWrapper}>
              <SelectBoxObject
                options={workouts}
                value={workoutId}
                setValue={(v) => setWorkoutId(v)}
                elevation={0}
                emptyItem
                placeholder={t('competition.placeholder.workout')}
              />
            </Box>
          </Box>
        </Box>
      )}

      {setCurrentView && viewOptions && (
        <Box flexGrow={1}>
          <Box>
            <Typography variant="body2" mb={1} style={{ color: labelLight }}>
              {t('competition.views')}
            </Typography>
            <Box style={dropdownWrapper}>
              <SelectBoxObject
                options={viewOptions}
                value={currentView}
                setValue={(v) => {
                  setCurrentView(v);
                }}
                elevation={0}
              />
            </Box>
          </Box>
        </Box>
      )}
      {setWithdraw && competition?.isCoach && (
        <div className="tw-mx-1 tw-flex tw-flex-col tw-items-center tw-justify-center tw-pb-4">
          <Typography variant="body2" style={{ color: labelLight }} mb={2}>
            {t('label.athletesWithdraw')}
          </Typography>
          <Checkbox
            // label={t('label.athletesWithdraw')}
            checked={withdraw}
            onClick={() => setWithdraw(!withdraw)}
          />
        </div>
      )}
    </div>
  );
};

export default LeaderboardFilters;
