/* eslint-disable react/prop-types */
import Box from 'core/ui/Box';
import React from 'react';

import { useTranslation } from 'react-i18next';
import ByMembershipData from './ByMembershipBonusData';

export default function ByMembershipBonus({ channel, byMembershipBonus }) {
  const { t } = useTranslation();
  return (
    <Box>
      <ByMembershipData
        title={t('channel.payroll.kind.byMembershipBonus')}
        channel={channel}
        byMembershipBonus={byMembershipBonus}
      />
    </Box>
  );
}
