/* eslint-disable react/prop-types */
import React from 'react';
import { BarChart, Bar, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import Typography from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import Paper from 'core/ui/Paper';
import { time } from 'core/utils/formatters';
import { primary } from 'core/ui/Colors';

const BarHours = ({ hours }) => {
  const { t } = useTranslation();

  const data = hours
    .filter((x) => Number(x.total) > 0)
    .map((item) => ({
      time: time(item.time),
      unlocks: item.total,
    }));

  return (
    <Paper my={5} p={3}>
      <Typography variant="h5" mb={3} align="center">
        {t('channel.reports.summary.topTimeGraph')}
      </Typography>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <XAxis dataKey="time" />
          <YAxis />
          <Bar label dataKey="unlocks" fill={primary} />
        </BarChart>
      </ResponsiveContainer>
    </Paper>
  );
};

export default BarHours;
