import React from 'react';
import Box from 'core/ui/Box';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import Checkbox from 'core/ui/Checkbox';

export default function ChildFields({ child, setChild, childName, setChildName }) {
  return (
    <>
      <Box mb={3}>
        <Checkbox label="Child" checked={child} onClick={() => setChild(!child)} />
      </Box>
      {child && (
        <Box mb={3}>
          <TextFieldRounded
            label="Child Name"
            value={childName}
            onChange={(v) => setChildName(v)}
            bordered
          />
        </Box>
      )}
    </>
  );
}

ChildFields.defaultProps = {
  competition: {},
};
