import React, { useState, useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { ClickAwayListener, makeStyles, Popper } from '@material-ui/core';
import Box from 'core/ui/Box';
import { hhmmss } from 'core/utils/formatters/date';
import Typography from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import { primary } from 'core/ui/Colors';
import { Add, Remove, Timer } from '@material-ui/icons';
import Paper from 'core/ui/Paper';
import Divider from 'core/ui/Divider';
import { workoutsActions, workoutsSelectors } from 'modules/workouts';
import { useDispatch, useSelector } from 'react-redux';
import { StreamContext } from 'pages/Stream';
import { TypesSignal } from 'core/openVidu/useOpenVidu';
import { sessionSelectors } from 'modules/session';
import { countDownTime } from 'components/Workout/Timer/useNewTimer';

const TrackRounds = ({ section, timeValue, tracking, setTracking }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userSession = useSelector((state) => sessionSelectors.getUser(state));
  const workout = useSelector((state) => workoutsSelectors.getWorkout(state));
  const streamContext = useContext(StreamContext);

  const useStyles = makeStyles(() => ({
    boxTrackRounds: {
      background: '#373739',
      borderRadius: 5,
      zIndex: 5,
    },
    boxButton: {
      display: 'flex',
      alignItems: 'center',
      background: primary,
      color: 'white',
      borderRadius: '50%',
    },
    boxRounds: {
      width: 110 * streamContext.zoomView,
      background: '#fff',
      padding: 2,
      paddingBottom: 6,
      zIndex: 4,
      marginBottom: -6,
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
      display: 'flex',
      alignItems: 'center',
    },
  }));

  const clearTracking = () => {
    setTracking((prev) => ({ ...prev, active: false, rounds: [], startTime: null, value: 0 }));
  };

  const saveTrack = () => {
    setTimeout(() => {
      dispatch(
        workoutsActions.putTrackUserStart({
          workoutId: workout.id,
          data: {
            trackData: {
              ...tracking,
            },
            sectionId: section.id,
          },
          messageSuccess: t('message.success.save.round.track'),
        }),
      );

      streamContext.eventSideBar.sendSignalUserChanged({
        type: TypesSignal.trackRound,
        userId: userSession.id,
      });

      dispatch(workoutsActions.getWorkoutSectionsStart(workout.id));
    }, 1000);
  };

  const prevRound = () => {
    const { rounds } = tracking;
    rounds.pop();
    setTracking((prev) => ({ ...prev, rounds }));
    saveTrack();
  };

  const nextRound = ({ value = null, clear = false } = {}) => {
    if (!tracking.active) {
      return;
    }
    const rounds = tracking.rounds || [];
    let lastRound = {
      time: 0,
    };
    if (rounds.length > 0) {
      lastRound = rounds[rounds.length - 1];
    }
    const currentValue = value || tracking.currentValue;
    // MIN TO SAVE A ROUND
    if (currentValue > 3) {
      const round = {
        index: rounds.length + 1,
        time: currentValue,
        workTime: lastRound.time > 0 ? (lastRound.time - currentValue) * -1 : currentValue,
      };

      if (round.workTime > 0) {
        rounds.push(round);
        setTracking((prev) => ({ ...prev, rounds }));
        saveTrack();
      }
    }

    if (clear) {
      clearTracking();
    }
  };

  const startTracking = () => {
    setTracking((prev) => ({ ...prev, active: true }));
  };

  useEffect(() => {
    if ((timeValue.active === 'work' || timeValue.active === 'rest') && tracking.active) {
      setTracking((prev) => ({
        ...prev,
        currentValue: Math.round((Date.now() - timeValue.start) / 1000 - countDownTime),
      }));
    }
  }, [timeValue.time]); //eslint-disable-line

  useEffect(() => {
    if (timeValue.ended === true && tracking.active) {
      setTimeout(() => {
        nextRound({ clear: true });
      }, 500);
    }
  }, [timeValue.ended]); //eslint-disable-line

  const boxRoundsRef = useRef();
  const [openDetail, setOpenDetail] = useState(false);

  const classes = useStyles();
  return (
    <>
      <Popper
        open={openDetail}
        anchorEl={boxRoundsRef.current}
        placement="bottom-start"
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: 9999 }}
      >
        <Paper p={5}>
          <ClickAwayListener onClickAway={() => setOpenDetail(false)}>
            <Box>
              {tracking.rounds &&
                tracking.rounds.map((round) => (
                  <>
                    <Box display="flex" alignItems="center">
                      <Typography style={{ fontSize: 12 * streamContext.zoomView }}>
                        {t('round')}:&nbsp;
                      </Typography>
                      <Typography
                        style={{ fontSize: 12 * streamContext.zoomView }}
                        color="primary"
                        mr={2}
                      >
                        {round.index}
                      </Typography>
                      <Timer style={{ fontSize: 12 * streamContext.zoomView }} />
                      <Typography style={{ fontSize: 12 * streamContext.zoomView }}>
                        {t('time')}:&nbsp;
                      </Typography>
                      <Typography style={{ fontSize: 12 * streamContext.zoomView }} color="primary">
                        {hhmmss(round.workTime)}
                      </Typography>
                    </Box>
                    <Divider m={3} />
                  </>
                ))}
            </Box>
          </ClickAwayListener>
        </Paper>
      </Popper>

      {tracking.rounds && tracking.rounds.length > 0 && (
        <Box
          mx={1}
          ref={boxRoundsRef}
          onClick={() => setOpenDetail(true)}
          className={classes.boxRounds}
        >
          <Typography ml={2} mb={0.1} style={{ fontSize: 10 * streamContext.zoomView }}>
            {t('round').toUpperCase()}&nbsp;{tracking.rounds.length}
          </Typography>
          <Typography
            className="digital-font"
            ml={2}
            color="primary"
            style={{ fontSize: 12 * streamContext.zoomView, fontWeight: 'normal' }}
          >
            {hhmmss(tracking.rounds[tracking.rounds.length - 1].workTime || 0)}
          </Typography>
        </Box>
      )}
      <Box
        display="flex"
        p={2}
        mb={1}
        mx={1}
        justifyContent="center"
        alignItems="center"
        className={classes.boxTrackRounds}
      >
        {tracking.active === true ? (
          <>
            {timeValue.active !== 'rest' && timeValue.active === 'work' && (
              <Box className={classes.boxButton} onClick={() => prevRound()}>
                <Remove />
              </Box>
            )}
            <Box mx={3}>
              <Typography
                className="digital-font"
                variant="h2"
                style={{
                  color: 'white',
                  fontSize: 24 * streamContext.zoomView,
                  fontWeight: 'normal',
                }}
              >
                {hhmmss(tracking.currentValue || 0)}
              </Typography>
            </Box>
            {timeValue.active !== 'rest' && timeValue.active === 'work' && (
              <Box className={classes.boxButton} onClick={() => nextRound()}>
                <Add />
              </Box>
            )}
          </>
        ) : (
          <Box onClick={() => startTracking()}>
            <Typography variant="h4" color="primary">
              {t('workout.stream.timer.track.rounds').toUpperCase()}
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
};

export default TrackRounds;

TrackRounds.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  section: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  timeValue: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  tracking: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  setTracking: PropTypes.func,
};

TrackRounds.defaultProps = {
  section: {},
  timeValue: {},
  tracking: {},
  setTracking: () => {},
};
