export function storage() {
  let lStorage = null;
  try {
    lStorage = window.localStorage;
  } catch (e) {
    lStorage = undefined;
  }

  return lStorage;
}

export function persistData(key, value) {
  if (value === 'batch-request') {
    return;
  }

  const localStorage = storage();
  if (!localStorage) {
    return;
  }

  if (localStorage === 'undefined') {
    console.warn('"window.localStorage" not found. Data will not be persisted.');
    return;
  }

  localStorage.setItem(key, value);
}

export function retrieveData(key) {
  const localStorage = storage();
  if (!localStorage) {
    return null;
  }
  return localStorage && localStorage.getItem(key);
}

export function removeData(key) {
  if (!localStorage) {
    return null;
  }
  return localStorage && localStorage.removeItem(key);
}
