import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '0 16px',
    color: 'white',
    gap: 16,
  },
  modal: {
    display: 'flex',
    justifyContent: 'center',
    padding: 32,
  },
  paper: {
    maxWidth: theme.breakpoints.values.xl,
    width: '100%',
    overflow: 'auto',
  },
  header: {
    display: 'flex',
    padding: 16,
    flexDirection: 'row',
    gap: 8,
    justifyContent: 'flex-end',
  },
  tableVideoContainer: {
    position: 'relative',
    width: 340,
    height: 190,
  },
  tableOptionsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 8,
  },
}));
