import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import Typography from 'core/ui/Typography';
import FontIcon from 'core/ui/FontIcon';
import { primary } from 'core/ui/Colors';
import { SearchContext } from '..';

const SectionTitle = ({ titleViewMore, type, model, icon, upcoming, recents, registered }) => {
  const { t } = useTranslation();
  const searchContext = useContext(SearchContext);

  return (
    <Box display="flex" alignItems="center">
      <Box
        display="flex"
        className="link"
        onClick={() =>
          searchContext.setViewMore({
            open: true,
            type,
            title: titleViewMore,
            model,
            icon,
            upcoming,
            recents,
            registered,
          })
        }
      >
        <Typography variant="body1">
          <>
            <Typography mr={1} component="span" variant="body1">
              {t('profile.seeAll').toUpperCase()}
            </Typography>
            <FontIcon iconName="icon-arrow-right" color={primary} size={10} />
          </>
        </Typography>
      </Box>
    </Box>
  );
};

export default SectionTitle;

SectionTitle.propTypes = {
  titleViewMore: PropTypes.string,
  type: PropTypes.string,
  model: PropTypes.string,
  icon: PropTypes.string,
  upcoming: PropTypes.bool,
  recents: PropTypes.bool,
  registered: PropTypes.bool,
};

SectionTitle.defaultProps = {
  titleViewMore: '',
  type: '',
  model: '',
  icon: '',
  upcoming: false,
  recents: false,
  registered: false,
};
