import React from 'react';
import PropTypes from 'prop-types';
import {} from 'react-i18next';
import Box from 'core/ui/Box';
import SkeletonPayamentsReport from './Skeleton';
import ResultPayments from './ResultPayments';
import CardResumes from './CardResumes';
import TotalByMembership from './TotalByMembership';

const PaymentsReportContent = ({
  channel,
  data,
  rows,
  loading,
  getReport,
  perPage,
  setPerPage,
}) => (
  <Box>
    {loading ? (
      <SkeletonPayamentsReport />
    ) : (
      <Box>
        <Box mb={3}>
          <CardResumes data={data} channel={channel} />
        </Box>

        <ResultPayments
          pagination={data?.paymentsPagination}
          rows={rows}
          chartData={data?.chartData || []}
          getReport={getReport}
          channel={channel}
          perPage={perPage}
          setPerPage={setPerPage}
        />

        {data.memberships?.byPlan?.length > 0 && (
          <Box my={3}>
            <TotalByMembership channel={channel} memberships={data.memberships?.byPlan} />
          </Box>
        )}
      </Box>
    )}
  </Box>
);

PaymentsReportContent.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  loading: PropTypes.bool,
};

PaymentsReportContent.defaultProps = {
  loading: false,
};

export default PaymentsReportContent;
