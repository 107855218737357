import React, { useContext, useState } from 'react';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import usePullRefresh from 'core/usePullRefresh';
import Grid from 'core/ui/Grid';
import Typography from 'core/ui/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { workoutsActions, workoutsSelectors } from 'modules/workouts';
import { useHistory } from 'react-router';
import ConfirmDialog from 'core/ui/ConfirmDialog';
import Modules from 'modules';
import useActions from 'modules/map/useActions';
import { AppContext } from 'contexts';
import PrivateItem from './PrivateItem';

const PrivateInvites = ({ workoutsInvites }) => {
  const appContext = useContext(AppContext);
  const [confirmDialog, setConfirmDialog] = useState({ open: false });
  const loading = useSelector((state) => workoutsSelectors.getLoadingPurchase(state));
  const history = useHistory();
  const { request } = useActions();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { setRefresh } = usePullRefresh();

  const cancelInvite = (workoutId) => {
    request({
      action: Modules.workoutsInvites.actions.declineInvite,
      data: {
        workoutId,
      },
      options: {
        onSuccess: () => {
          setRefresh(true);
        },
      },
    });
  };

  const acceptInvite = (w) => {
    if (w.method === 'custom') {
      dispatch(
        workoutsActions.purchaseStart(
          {
            workoutId: w.id,
            messageSuccess: t('purchase.workout.success'),
          },
          () => {
            setConfirmDialog({
              description:
                'You can access this workout anytime from the My Calendar or Workouts screen.',
              open: true,
              confirm: () => {
                setConfirmDialog({ open: false });
                history.push(`/workout/view/${w.id}`);
              },
            });
          },
        ),
      );
    } else {
      appContext.setModalPurchase({
        open: true,
        workoutId: w.id,
        confirmCallback: () => setRefresh(true),
      });
    }
  };

  return (
    <>
      <ConfirmDialog
        open={confirmDialog.open}
        confirm={() => confirmDialog.confirm()}
        cancel={() => {
          setConfirmDialog({ open: false });
          setRefresh(true);
        }}
        description={confirmDialog.description}
        labelConfirm={t('button.viewWorkout')}
        labelCancel={t('button.ok')}
      />
      <Box mb={5}>
        <Box display="flex" alignItems="center">
          <Typography color="secondary" className="link" variant="h6">
            {t('section.title.invites').toUpperCase()}
          </Typography>
        </Box>

        <Grid container spacing={5} className="search-item" mx={3}>
          {workoutsInvites.map((workout, index) => (
            <Grid item xs={12} sm={12} md={6} lg={4} key={index.toString()} className="item">
              <PrivateItem
                key={workout.id}
                workout={workout}
                openModalRegistration={acceptInvite}
                declineInvite={cancelInvite}
                onViewClick={() => history.push(`/workout/view/${workout.id}`)}
                loading={loading}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default PrivateInvites;
