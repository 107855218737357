/* eslint-disable react/prop-types */
import React from 'react';
import {
  BarChart,
  Bar,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from 'recharts';
import Paper from 'core/ui/Paper';
import { successColor } from 'core/ui/Colors';

const BarMonths = ({ chartData }) => (
  <Paper p={3} style={{ width: '100%' }}>
    <ResponsiveContainer width="100%" height={300}>
      <BarChart
        data={chartData}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" style={{ fontSize: 10 }} />
        <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
        <Tooltip />
        <Legend />
        <Bar yAxisId="left" dataKey="checkins" fill={successColor} />
      </BarChart>
    </ResponsiveContainer>
  </Paper>
);

export default BarMonths;
