import React, { useContext, Suspense } from 'react';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import Typography from 'core/ui/Typography';
import { IconButton } from '@material-ui/core';
import { Add, Close } from '@material-ui/icons';
import { WorkflowContext } from 'contexts';
import Divider from 'core/ui/Divider';
import { getExistsFilterComponent, getFilterComponent } from './getFilterComponent';

const WorkflowTypeFilters = ({ automationType }) => {
  const { t } = useTranslation();

  const workFlowContext = useContext(WorkflowContext);
  const filters = workFlowContext.formType.watch('filters') || [];

  if (!automationType?.function) return 'undefined automationType.function';

  const existsFilterComponent = getExistsFilterComponent(automationType.function);

  if (!existsFilterComponent) return null;

  const Component = getFilterComponent(automationType.function);

  return (
    <Box>
      <Typography variant="h6" color="secondary" my={3}>
        {t('label.membersFilters').toUpperCase()}
      </Typography>
      <Box
        mb={2}
        display="flex"
        alignItems="center"
        className="gap-5 link-hover"
        onClick={() => workFlowContext.formType.setValue('filters', [...filters, {}])}
      >
        <IconButton size="small">
          <Add />
        </IconButton>
        <Typography color="textSecondary">{t('button.addFilters')}</Typography>
      </Box>

      <Box>
        <Suspense fallback={<div>Loading...</div>}>
          {filters?.map((itemFilter, index) => (
            <Box key={index}>
              <Box display="flex" alignItems="flex-start" className="gap-5">
                <Box flexGrow={1}>
                  <Component
                    key={automationType.function}
                    automationType={automationType}
                    values={itemFilter}
                    onChangeFiltersValues={workFlowContext.onChangeFiltersValues}
                    index={index}
                    channelId={workFlowContext.channelId}
                    excludeTypes={filters?.map((x) => x.filterType)}
                  />
                </Box>
                <IconButton size="small" onClick={() => workFlowContext.removeFilter(itemFilter)}>
                  <Close />
                </IconButton>
              </Box>
              <Divider my={4} />
            </Box>
          ))}
        </Suspense>
      </Box>
    </Box>
  );
};

export default WorkflowTypeFilters;
