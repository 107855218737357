/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import Typography from 'core/ui/Typography';
import Box from 'core/ui/Box';
import { useHistory } from 'react-router';
import Scrollbars from 'react-custom-scrollbars';
import { secondary } from 'core/ui/Colors';
import { LayoutContext } from 'pages/shared/Layout';
import { ShowContext } from './index';

export default () => {
  const { t } = useTranslation();
  const showContext = useContext(ShowContext);
  const layoutContext = useContext(LayoutContext);

  const history = useHistory();
  const useStyles = makeStyles((theme) => ({
    boxTabs: {
      display: 'flex',
      height: 55,
      borderRadius: 5,
      alignItems: 'center',
    },
    wraperTabs: {
      background: secondary,
      height: 55,
      borderRadius: 5,
    },
    activeTab: {
      borderBottom: '0.5px dotted #fff',
    },
    styleTab: {
      padding: 3,
      marginLeft: 5,
      [theme.breakpoints.up('sm')]: {
        padding: 5,
        marginLeft: 11,
      },
    },
  }));
  const classes = useStyles();
  const showPrivateClassTab = () => {
    if (showContext.channel.upcomingPrivateClasses) {
      return showContext.channel.upcomingPrivateClasses.length > 0;
    }
    return false;
  };
  const ItemTab = ({ label, tab, onClick }) => (
    <Box className={classes.styleTab}>
      <Button
        size="small"
        onClick={
          onClick
            ? () => onClick()
            : () => {
                showContext.setTab(tab);
                history.push(`/channel/view/${showContext.channel.id}/${tab}`);
              }
        }
      >
        <Typography
          className={showContext.tab === tab && classes.activeTab}
          style={{ color: 'white' }}
          variant="subtitle2"
        >
          {label}
        </Typography>
      </Button>
    </Box>
  );

  return (
    <Box className={classes.wraperTabs} px={2}>
      <Scrollbars
        autoHide
        renderTrackVertical={(props) => (
          <div {...props} style={{ display: 'none' }} className="track-horizontal" />
        )}
        style={{ width: '100%', height: 55 }}
      >
        <Box className={classes.boxTabs}>
          {/* {!showContext?.channel?.hideToPublic && (
            <ItemTab label={t('channel.tab.classes')} tab="classes" />
          )} */}
          <ItemTab label={t('channel.schedules')} tab="schedules" />
          {showPrivateClassTab() && (
            <ItemTab label={t('channel.tab.privateClasses')} tab="privateClasses" />
          )}
          <ItemTab label={t('channel.tab.coaches')} tab="coaches" />

          {showContext.channel && showContext.channel.membershipsSell && (
            <ItemTab label={t('channel.tab.memberships')} tab="memberships" />
          )}
          {showContext.channel && showContext.channel.hasStore && (
            <ItemTab label={t('channel.tab.store')} tab="store" />
          )}
          {showContext.channel && (
            <ItemTab
              label={t('channel.tab.chat')}
              tab="chat"
              onClick={() =>
                layoutContext?.setModalChat({
                  open: true,
                  ownerableType: 'Channel',
                  ownerableId: showContext?.channel?.id,
                  ownerable: showContext?.channel,
                })
              }
            />
          )}
          {showContext.channel && showContext.channel.allowEdit && (
            <ItemTab
              label={t('channel.tab.selfCheckIn')}
              tab="selfCheckIn"
              onClick={() => history.push(`/channel/selfCheckIn/${showContext?.channel?.slug}`)}
            />
          )}
        </Box>
      </Scrollbars>
    </Box>
  );
};
