import api from 'core/api';
import { call, put } from 'redux-saga/effects';

export const adminReportsModule = 'adminReports';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const actions = {
  getPayoutReport: {
    module: adminReportsModule,
    name: 'getPayoutReport',
    api: (params) => api.post(`/admin/reports/payout`, params),
    params: {
      start: ['params'],
      error: [''],
      success: ['data'],
    },
    state: loadingStates,
  },
  getOverviewReport: {
    module: adminReportsModule,
    name: 'getOverviewReport',
    api: (params) => api.get(`/admin/reports/overview`, { params }),
    *sagas(Creators, { params, options }) {
      try {
        const resp = yield call(actions.getOverviewReport.api, params);
        yield put(Creators.getOverviewReportSuccess());
        if (options.onSuccess) {
          options.onSuccess(resp.data, resp.pagination);
        }
      } catch (error) {
        if (options.onError) {
          options.onError(error);
        }
        yield put(Creators.getOverviewReportError());
      }
    },
    params: {
      start: ['params'],
      error: [''],
      success: [],
    },
    state: loadingStates,
  },
  getGymsMetrics: {
    module: adminReportsModule,
    name: 'getGymsMetrics',
    api: (params) => api.get(`/admin/reports/gyms_metrics`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: ['gymMetrics'],
    },
    state: loadingStates,
  },
  getForecast: {
    module: adminReportsModule,
    name: 'getForecast',
    api: (params) => api.get(`/admin/reports/forecast`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: ['forecast'],
    },
    state: loadingStates,
  },
  setPayoutTransfer: {
    module: adminReportsModule,
    name: 'getOverviewReport',
    api: ({ transferId }) => api.put(`/admin/reports/set_payout_transfer/${transferId}`),
    params: {
      start: ['params'],
      error: [''],
      success: ['data'],
    },
    state: loadingStates,
  },
  onboarding: {
    module: adminReportsModule,
    name: 'onboarding',
    api: (params) => api.get('/admin/reports/onboarding/', { params }),
    params: {
      start: ['params'],
      error: [''],
      success: ['onboarding'],
    },
    state: loadingStates,
  },
};

export default {
  actions,
  state: {
    loading: false,
    data: {},
    pagination: null,
    gymMetrics: null,
    forecast: null,
    onboarding: null,
  },
};
