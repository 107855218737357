import React from 'react';
import PropTypes from 'prop-types';

import './font-icon.css';

const FontIcon = ({ iconName, size, color }) => (
  <span style={{ fontSize: size, color }} className={iconName} />
);

FontIcon.propTypes = {
  iconName: PropTypes.string.isRequired,
  size: PropTypes.number,
  color: PropTypes.string,
};

FontIcon.defaultProps = {
  size: 24,
  color: 'black',
};

export default FontIcon;
