/* eslint-disable react/prop-types */
import React from 'react';
import { primary, secondary } from 'core/ui/Colors';
import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import { parse } from 'query-string';
import Form from 'components/Account/Form';
import Box from 'core/ui/Box';

export default () => {
  const location = useLocation();
  const { setupIntentId } = useParams();

  const parsed = parse(location.search);

  const formBgColor = parsed.bgColor || secondary;
  const formButtonColor = parsed.buttonColor || primary;
  const showCanadaDebit = parsed.showCanadaDebit || '';

  const handleSubmit = async (method) => {
    try {
      const paymentMethod = {
        stripeSetupIntentId: method?.stripeSetupIntentId,
        userPaymentMethodId: method?.id,
      };
      window.ReactNativeWebView.postMessage(JSON.stringify(paymentMethod));
    } catch {
      alert('Error to create payment method');
    }
  };

  return (
    <Box style={{ height: '100vh', backgroundColor: formBgColor, padding: 10 }}>
      {setupIntentId && (
        <Form
          setView={() => {}}
          setSelectedPaymentMethod={(method) => handleSubmit(method)}
          setupIntentId={setupIntentId}
          closeModal={() => {}}
          // eslint-disable-next-line eqeqeq
          showCanadaDebit={showCanadaDebit == 1}
          onlyMethod={
            parsed.onlyMethod === 'card ' || parsed.onlyMethod === 'ach' ? parsed.onlyMethod : null
          }
          buttonColor={formButtonColor}
        />
      )}
    </Box>
  );
};
