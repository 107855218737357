import { colors } from '@material-ui/core';
import AvatarCoach from 'components/Profile/AvatarCoach';
import Box from 'core/ui/Box';
import { chatColors } from 'core/ui/Colors';
import Typography from 'core/ui/Typography';
import { fromNow } from 'core/utils/formatters';
import React, { memo, useState } from 'react';
import AutoLinkText from 'react-autolink-text2';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ImageViewer from 'react-simple-image-viewer';
import { Delete, Edit, Reply } from '@material-ui/icons';
import Button from 'core/ui/Button';
import Paper from 'core/ui/Paper';
import { abbrevName } from 'core/utils/helpers';

import IconReply from 'assets/img/icon-reply.png';

export default memo(({ item, isOwner, actions }) => {
  const [isBoxerOpen, setIsBoxerOpen] = useState(false);

  return (
    <>
      <Box activeOpacity={0.7} className="element" style={{ position: 'relative' }}>
        {item?.parent && (
          <div className="parent parent-flex-fixed">
            <Box mx={2} mt={1}>
              <img src={IconReply} alt="Arrow" style={{ height: 10 }} />
            </Box>
            <Box>
              <AvatarCoach user={item?.parent?.user} size={16} />
            </Box>
            <Box
              ml={4}
              style={{
                position: 'relative',
                backgroundColor: chatColors.message,
                borderRadius: 10,
                flex: 1,
              }}
              flexGrow={1}
              p={3}
            >
              <Box style={{ position: 'absolute', left: -22, top: -6 }}>
                <ArrowLeftIcon style={{ color: chatColors.message, fontSize: 40 }} />
              </Box>
              <Box>
                <Box display="flex" mb={1}>
                  <Box flexGrow={1}>
                    <Typography style={{ color: colors.grey[100] }}>
                      {item?.parent?.user?.name}
                    </Typography>
                  </Box>
                  <Typography style={{ color: colors.grey[500], fontSize: 10 }}>
                    {fromNow(item?.parent?.createdAt)}
                  </Typography>
                </Box>

                {item?.parent?.kind === 'gif' ? (
                  <Box>
                    <img
                      style={{ width: 40 }}
                      alt={item?.parent?.user?.name}
                      src={item?.parent?.text}
                    />
                  </Box>
                ) : (
                  <div
                    style={{
                      wordWrap: 'break-word',
                      whiteSpace: 'pre-line',
                    }}
                  >
                    <Typography
                      pl={2}
                      style={{
                        color: colors.grey[400],
                      }}
                      className="roboto-regular chat-link"
                    >
                      <AutoLinkText text={abbrevName(item?.parent?.text, 200)} />
                    </Typography>
                  </div>
                )}
              </Box>
            </Box>
          </div>
        )}
        <div className="parent parent-flex-fixed" style={{ position: 'relative' }}>
          <Paper
            p={1}
            mr={2}
            style={{ backgroundColor: colors.grey[800] }}
            className="element-menu"
          >
            <Button onClick={() => actions?.replyMessage(item)}>
              <Reply style={{ color: colors.grey[300], fontSize: 12 }} />
            </Button>
            {isOwner && (
              <Button onClick={() => actions?.editMessage(item)}>
                <Edit style={{ color: colors.grey[300], fontSize: 12 }} />
              </Button>
            )}
            {isOwner && (
              <Button onClick={() => actions?.removeMessage(item)}>
                <Delete style={{ color: colors.grey[300], fontSize: 12 }} />
              </Button>
            )}
          </Paper>
          <Box>
            <AvatarCoach user={item?.user} size={30} />
          </Box>
          <Box
            ml={4}
            style={{
              position: 'relative',
              backgroundColor: chatColors.message,
              borderRadius: 10,
              flex: 1,
            }}
            flexGrow={1}
            p={3}
          >
            <Box style={{ position: 'absolute', left: -22, top: -6 }}>
              <ArrowLeftIcon style={{ color: chatColors.message, fontSize: 40 }} />
            </Box>
            <Box>
              <Box display="flex" mb={1}>
                <Box flexGrow={1}>
                  <Typography style={{ color: colors.grey[100] }}>{item?.user?.name}</Typography>
                </Box>
                <Typography style={{ color: colors.grey[500], fontSize: 10 }}>
                  {fromNow(item?.createdAt)}
                </Typography>
              </Box>

              {item?.kind === 'gif' ? (
                <Box>
                  <img style={{ width: 150 }} alt={item?.user?.name} src={item?.text} />
                </Box>
              ) : (
                <>
                  {/* {item?.kind === 'object' && item?.objectType === 'Workout' && (
                    <Box>
                      <WorkoutItem item={item?.object} buttonColor={buttonColor} />
                    </Box>
                  )} */}

                  {/* {item?.kind === 'object' && item?.objectType === 'PersonalRecordMovement' && (
                    <Pressable
                      onClick={() =>
                        navigation.navigate('Dashboard', {
                          screen: 'Dashboard',
                          params: {
                            tab: 'prDetail',
                            id: item?.object?.id,
                          },
                        })
                      }
                    >
                      <ShadowBox classes="bg-white p-3 flex flex-col rounded-md">
                        <Typography classes="text-gray-500 text-xs mb-2 font-bold">PR</Typography>
                        <Box classes="flex flex-row">
                          <FontIcon name="force" size={15} />
                          <Typography classes="ml-2 font-semibold text-base">
                            {item?.object?.movement?.name}
                          </Typography>
                        </Box>

                        {item?.object?.bestPr && (
                          <Box classes="mt-2">
                            <Typography classes="text-gray-500 text-xs mb-2 font-bold">
                              SCORE
                            </Typography>
                            <BestPrScores item={item?.object} />
                          </Box>
                        )}
                      </ShadowBox>
                    </Pressable>
                  )}
                  {item?.kind === 'object' && item?.objectType === 'BenchmarkSection' && (
                    <Pressable
                      onClick={() =>
                        navigation.navigate('Dashboard', {
                          screen: 'Dashboard',
                          params: {
                            tab: 'benchmarkDetail',
                            id: item?.object?.id,
                          },
                        })
                      }
                    >
                      <ShadowBox classes="bg-white p-3 flex flex-col rounded-md">
                        <Typography classes="text-gray-500 text-xs mb-2 font-bold">
                          BENCHMARK
                        </Typography>
                        <Box classes="flex flex-row">
                          <MuiIcon name="directions-run" size={15} />
                          <Typography classes="ml-2 font-semibold text-base">
                            {item?.object?.name}
                          </Typography>
                        </Box>

                        {item?.object?.bestBenchmark && (
                          <Box classes="mt-2">
                            <Typography classes="text-gray-500 text-xs mb-2 font-bold">
                              SCORE
                            </Typography>
                            <BenchmarkUserScores
                              scores={item?.object?.bestBenchmark?.scores}
                              buttonColor={buttonColor}
                            />
                          </Box>
                        )}
                      </ShadowBox>
                    </Pressable>
                  )} */}

                  <div
                    style={{
                      wordWrap: 'break-word',
                      whiteSpace: 'pre-line',
                    }}
                  >
                    <Typography
                      style={{
                        color: colors.grey[400],
                      }}
                      className="roboto-regular chat-link"
                    >
                      <AutoLinkText text={item?.text} />
                    </Typography>
                  </div>
                </>
              )}

              {item?.mediaFiles?.map((media, idx) => (
                <Box key={media?.id} classes={item?.mediaFiles?.length - 1 > idx ? 'mb-2' : ''}>
                  <Box onClick={() => setIsBoxerOpen({ open: true, currentIndex: idx })}>
                    <img style={{ width: 100 }} src={media?.url} alt={item?.text} />
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </div>
      </Box>

      {isBoxerOpen?.open && (
        <ImageViewer
          src={item?.mediaFiles?.map((x) => x?.url)}
          currentIndex={isBoxerOpen?.currentIndex}
          onClose={() => setIsBoxerOpen({ open: false })}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: 'rgba(0,0,0,0.9)',
          }}
          closeOnClickOutside
        />
      )}
    </>
  );
});
