import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Typography from 'core/ui/Typography';
import Box from 'core/ui/Box';
import { Warning } from '@material-ui/icons';
import { primary } from 'core/ui/Colors';
import { ButtonPrimary } from 'core/ui/Button';

export default function PopUpValidation({ open, setOpen, errors }) {
  const { t } = useTranslation();
  const handleCancel = () => {
    if (setOpen) {
      setOpen(false);
    }
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title">{t('workout.validation.title')}</DialogTitle>
        <DialogContent>
          <Box flexDirection="column">
            {errors &&
              errors.map((err, index) => (
                <Box alignItems="center" key={index.toString()} display="flex">
                  {err && (
                    <>
                      <Warning size="small" style={{ fontSize: 16, color: primary }} />
                      <Typography ml={1} component="h1" color="primary" variant="body2">
                        {err}
                      </Typography>
                    </>
                  )}
                </Box>
              ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <ButtonPrimary onClick={handleCancel} color="primary">
            {t('button.close')}
          </ButtonPrimary>
        </DialogActions>
      </Dialog>
    </div>
  );
}

PopUpValidation.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  errors: PropTypes.array,
};

PopUpValidation.defaultProps = {
  open: false,
  setOpen: null,
  errors: [],
};
