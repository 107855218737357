import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Delete } from '@material-ui/icons';

import { currency, date } from 'core/utils/formatters';

export default function ItemCustomPaymentUser({ channel, item, onRemoveClick }) {
  return (
    <TableRow>
      <TableCell>{item?.channelsCustomPayment?.name}</TableCell>
      <TableCell>{item?.description}</TableCell>
      <TableCell>{currency(item?.amount)}</TableCell>
      <TableCell>{item?.date && date(item?.date, channel?.dateFormatReact)}</TableCell>
      <TableCell align="right">
        <IconButton onClick={() => onRemoveClick(item)}>
          <Delete />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}
