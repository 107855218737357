import { Skeleton } from '@material-ui/lab';
import Box from 'core/ui/Box';
import Grid from 'core/ui/Grid';
import React from 'react';

const SkeletonStoreProducts = () => {
  const styleBox = {
    borderRadius: 10,
    overflow: 'hidden',
  };
  return (
    <>
      {/* <Box mb={3} style={{ ...styleBox, borderRadius: 5 }}>
        <Skeleton variant="rect" style={{ height: 60, width: '100%' }} />
      </Box> */}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Box style={styleBox} className="container-aspect container-aspect-50">
            <Box className="item-aspect">
              <Skeleton variant="rect" style={{ height: '100%', width: '100%' }} />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Box style={styleBox} className="container-aspect container-aspect-50">
            <Box className="item-aspect">
              <Skeleton variant="rect" style={{ height: '100%', width: '100%' }} />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Box style={styleBox} className="container-aspect container-aspect-50">
            <Box className="item-aspect">
              <Skeleton variant="rect" style={{ height: '100%', width: '100%' }} />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Box style={styleBox} className="container-aspect container-aspect-50">
            <Box className="item-aspect">
              <Skeleton variant="rect" style={{ height: '100%', width: '100%' }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default SkeletonStoreProducts;
