import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import propTypes from 'prop-types';
import Box from 'core/ui/Box';
import ReactPlayer from 'react-player';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#ececec',
  },
}));

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    minWidth: 200,
  },
}))(MuiDialogContent);

export default function ModalVideoMovement({ url, open, setOpen }) {
  const classes = useStyles();

  return (
    <div>
      <Dialog
        PaperProps={{ style: { borderRadius: 5 } }}
        maxWidth="xl"
        scroll="body"
        fullWidth
        onClose={() => setOpen(false)}
        open={open}
      >
        <Box className={classes.root}>
          <DialogContent>
            <ReactPlayer controls height="90vh" width="100%" url={url} />
          </DialogContent>
        </Box>
      </Dialog>
    </div>
  );
}

ModalVideoMovement.propTypes = {
  open: propTypes.bool,
  setOpen: propTypes.func,
  url: propTypes.string,
};

ModalVideoMovement.defaultProps = {
  open: false,
  setOpen: null,
  url: '',
};
