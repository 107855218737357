import React from 'react';
import PropTypes from 'prop-types';
import { LinearProgress as Progress } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '../Typography';
import { secondary } from '../Colors';

const LinearProgress = ({ textColor, ...props }) => (
  <Box display="flex" alignItems="center" flexGrow={1}>
    <Box width="100%" mr={1}>
      <Progress variant="determinate" {...props} />
    </Box>
    <Box minWidth={35}>
      <Typography variant="body2" style={{ color: textColor || secondary }}>{`${Math.round(
        props.value,
      )}%`}</Typography>
    </Box>
  </Box>
);

LinearProgress.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

export default LinearProgress;
