import React, { useEffect, useState } from 'react';
import { ButtonPrimary } from 'core/ui/Button';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import Typography from 'core/ui/Typography';

import {
  TableContainer,
  TableHead,
  Table,
  TableCell,
  TableRow,
  TableBody,
  Paper,
  IconButton,
  Dialog,
} from '@material-ui/core';
import { Add, Delete, Edit } from '@material-ui/icons';
import LoaderSm from 'core/ui/LoaderSm';
import useSelectors from 'modules/map/useSelectors';
import { channelsCustomPaymentsModule } from 'modules/channelsCustomPayments';
import useActions from 'modules/map/useActions';
import Modules from 'modules';

import { channelCurrency } from 'core/utils/formatters';
import Texture from 'core/ui/Texture';
import ModalFormDeduction from './Form/Modal';

const ModalListDeductions = ({ open, close, userId, channel, refreshReport }) => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [modalDeductions, setModalDeduction] = useState({ open: false });

  const loading = useSelectors(channelsCustomPaymentsModule, 'loading');
  const { request } = useActions();

  const getLists = () => {
    request({
      action: Modules.channelsCoachesPayrollDeductions.actions.getPayrollDeductions,
      data: { channelId: channel?.id, userId },
      options: {
        onSuccess: setData,
      },
    });
  };

  const saveSuccess = () => {
    getLists();
    setModalDeduction(false);
    refreshReport();
  };

  useEffect(() => {
    if (channel?.id && userId) {
      getLists();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request, userId, channel?.id]);

  const removeDeduction = (itemId) => {
    request({
      action: Modules.channelsCoachesPayrollDeductions.actions.destroy,
      data: { channelId: channel?.id, userId, id: itemId },
      options: {
        onSuccess: saveSuccess,
      },
    });
  };

  return (
    <Dialog maxWidth="md" scroll="body" fullWidth onClose={() => close()} open={open}>
      <Texture>
        <Box p={5}>
          <ModalFormDeduction
            channel={channel}
            callback={() => {
              saveSuccess();
            }}
            open={modalDeductions?.open}
            item={modalDeductions?.item}
            userId={userId}
            close={() => setModalDeduction({ open: false })}
          />

          <Box display="flex" alignItems="center">
            <Box flexGrow="1" display="flex" alignItems="center">
              <LoaderSm loading={loading} />
            </Box>
            <Box>
              <ButtonPrimary onClick={() => setModalDeduction({ open: true })}>
                <Box display="flex" alignItems="flex-end">
                  <Add size="small" />
                  <Box alignItems="center">
                    <Typography component="span" mr={1}>
                      {t('button.createDeductions')}
                    </Typography>
                  </Box>
                </Box>
              </ButtonPrimary>
            </Box>
          </Box>
          <Box mt={2}>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>{t('label.description')}</TableCell>
                    <TableCell>{t('label.amount')}</TableCell>
                    <TableCell />
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data &&
                    data.map((row) => (
                      <TableRow key={row?.id}>
                        <TableCell>{row.description}</TableCell>
                        <TableCell>{channelCurrency(row?.amount, channel?.currency)}</TableCell>
                        <TableCell align="right">
                          <IconButton onClick={() => setModalDeduction({ open: true, item: row })}>
                            <Edit />
                          </IconButton>
                        </TableCell>
                        <TableCell align="right">
                          <IconButton onClick={() => removeDeduction(row?.id)}>
                            <Delete />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Texture>
    </Dialog>
  );
};

export default ModalListDeductions;
