import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from 'core/ui/Dialog/DialogTitle';
import Texture from 'core/ui/Texture';
import Modules from 'modules';
import Box from 'core/ui/Box';
import Paper from 'core/ui/Paper';
import { ButtonPrimary } from 'core/ui/Button';
import LoaderSm from 'core/ui/LoaderSm';
import useActions from 'modules/map/useActions';
import { newPaymentsModule } from 'modules/payments/new';
import useSelectors from 'modules/map/useSelectors';
import { useDispatch } from 'react-redux';
import notifications from 'modules/notifications';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import Typography, { fontStyle } from 'core/ui/Typography';
import Toggle from 'core/ui/Toggle';

export default function ModalRefundPayment({ open, close, payment, callback }) {
  const { t } = useTranslation();
  const { request } = useActions();
  const dispatch = useDispatch();
  const loading = useSelectors(newPaymentsModule, 'loading');
  const [amount, setAmount] = useState(null);
  const [keepActive, setKeepActive] = useState(false);
  const [keepHoldPaid, setKeepHoldPaid] = useState(false);

  const confirm = () => {
    request({
      action: Modules.newPayments.actions.refundPayment,
      data: {
        paymentId: payment?.id,
        refundAmount: amount,

        refundKeepActive: keepActive,
        refundKeepHoldPaid: keepHoldPaid,
      },
      options: {
        onSuccess: () => {
          dispatch(notifications.success(t('payment.refund.success')));
          if (callback) {
            callback();
          }
        },
        onError: (error) => {
          dispatch(notifications.error(error));
        },
      },
    });
  };
  return (
    <div>
      <Dialog maxWidth="sm" scroll="body" onClose={() => close()} open={open} fullWidth>
        <Texture>
          <DialogTitle onClose={() => close()}>{t('payment.refund.title')}</DialogTitle>
          <Box p={5}>
            <Paper p={5} className="paper-rounded">
              <Box m={3}>
                <TextFieldRounded
                  type="number"
                  value={amount}
                  pattern="[0-9]*"
                  placeholder={t('label.amountRefund')}
                  label={t('label.amountRefund').toUpperCase()}
                  onChange={(v) => setAmount(v)}
                  required
                  currency
                  currencySymbol={payment?.currency?.symbol}
                  bordered
                />
                <Typography style={fontStyle.small} mt={1}>
                  {t('help.amountRefund')}
                </Typography>
              </Box>
            </Paper>

            <Paper p={3} my={3}>
              <Typography style={fontStyle.xLarge} align="center">
                Refund Amount: {amount || payment?.refundRemain}
              </Typography>
            </Paper>

            {payment?.kind === 'membership' && (
              <div>
                <Paper p={3} my={3}>
                  <Box display="flex" justifyContent="center">
                    <Toggle
                      options={[
                        { value: false, label: t('button.refund.cancelMembership'), width: 200 },
                        {
                          value: true,
                          label: t('button.refund.leaveMembershipActive'),
                          width: 200,
                        },
                      ]}
                      color="white"
                      fontSize="medium"
                      value={!!keepActive}
                      onChange={(v) => setKeepActive(v)}
                    />
                  </Box>
                </Paper>
              </div>
            )}
            {payment?.kind === 'competition_registration' && (
              <div>
                <Paper p={3} my={3}>
                  <Box display="flex" justifyContent="center">
                    <Toggle
                      options={[
                        { value: false, label: t('button.refund.cancelRegistration'), width: 200 },
                        {
                          value: true,
                          label: t('button.refund.keepRegistration'),
                          width: 200,
                        },
                      ]}
                      color="white"
                      fontSize="medium"
                      value={!!keepActive}
                      onChange={(v) => setKeepActive(v)}
                    />
                  </Box>
                </Paper>
              </div>
            )}
            {payment?.holdPayments?.length > 0 && (
              <Paper p={3} my={3}>
                <Box display="flex" justifyContent="center">
                  <Toggle
                    options={[
                      { value: false, label: t('button.refund.cancelHoldPayments'), width: 200 },
                      {
                        value: true,
                        label: t('button.refund.leaveHoldPaymentsPaid'),
                        width: 200,
                      },
                    ]}
                    color="white"
                    fontSize="medium"
                    value={!!keepHoldPaid}
                    onChange={(v) => setKeepHoldPaid(v)}
                  />
                </Box>
              </Paper>
            )}

            <Box flexGrow={1} px={5}>
              <ButtonPrimary disabled={loading} fullWidth onClick={() => confirm()}>
                <LoaderSm loading={loading} />
                {t('button.confirm')}
              </ButtonPrimary>
            </Box>
          </Box>
        </Texture>
      </Dialog>
    </div>
  );
}

ModalRefundPayment.defaultProps = {
  open: false,
  close: null,
  userId: null,
};
