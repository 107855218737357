import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import Grid from 'core/ui/Grid';
import Typography, { fontStyle } from 'core/ui/Typography';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { primary } from 'core/ui/Colors';
import { useDispatch, useSelector } from 'react-redux';
import { channelListsActions, channelListsSelectors } from 'modules/channelLists';
import { ButtonPrimarySm } from 'core/ui/Button';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import { WorkoutContext } from '../index';
import Location from './Location';
import Visibility from './Visibility';
import UseHeats from './UseHeats';
import CustomColors from './CustomColors';
import CancellationPolicy from './CancellationPolicy';
import PayCoachCost from './PayCoachCost';
import WaitlistPolicy from './WaitlistPolicy';

export default () => {
  const { t } = useTranslation();
  const formContext = useContext(WorkoutContext);
  const channelLists = useSelector((state) => channelListsSelectors.getChannelLists(state));
  const dispatch = useDispatch();

  useEffect(() => {
    if (formContext.selectedChannel && formContext.selectedChannel.id) {
      dispatch(channelListsActions.getChannelListsStart(formContext.selectedChannel.id));
    }
  }, [dispatch, formContext.selectedChannel]);

  const checkChannelList = (list) => !!formContext.channelLists.find((x) => x.id === list.id);

  const changeChannelLists = (list) => {
    const arr = [...formContext.channelLists];
    if (!checkChannelList(list)) {
      arr.push(list);
      formContext.setChannelLists(arr);
    } else {
      formContext.setChannelLists(arr.filter((x) => x.id !== list.id));
    }
  };

  return (
    <Grid container pb={5}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Box mt={3} display="flex" alignItems="center">
          <Box flexGrow={1}>
            <Typography variant="h5" mb={2} color="primary">
              {t('workout.title.settings')}
            </Typography>
          </Box>

          {formContext.mode === 'edit' && !formContext.workoutRecurringId && (
            <Box display="flex" alignItems="center">
              <ButtonPrimarySm m={1} onClick={() => formContext.duplicateClass()}>
                {t('workout.classSettings.duplicateClassEdit')}
              </ButtonPrimarySm>
            </Box>
          )}
        </Box>

        {(!formContext.selectedChannel || !formContext.selectedChannel?.planValid) && (
          <Typography variant="h2" color="primary" m={6} align="center">
            {t('workout.select.channelSettings')}
          </Typography>
        )}

        {formContext.selectedChannel && formContext.selectedChannel?.planValid && (
          <>
            <Box>
              {formContext?.selectedChannel?.workoutsTypes?.length > 0 && (
                <SelectBoxObject
                  options={formContext?.selectedChannel?.workoutsTypes?.filter((x) => !!x.active)}
                  label={t('channel.label.workoutsType').toUpperCase()}
                  value={formContext?.watch('channelsWorkoutsTypeId')}
                  setValue={(v) => formContext?.setValue('channelsWorkoutsTypeId', v)}
                />
              )}

              <Box display="flex" mt={5} flexWrap="wrap">
                <Box mr={1} my={1}>
                  <Location />
                </Box>
                <Box m={1}>
                  <Visibility watch={formContext?.watch} setValue={formContext?.setValue} />
                </Box>
                {!formContext.isTemplate && !formContext?.workout?.recurringTemplate && (
                  <Box m={1}>
                    <UseHeats />
                  </Box>
                )}
                <Box m={1}>
                  <CustomColors watch={formContext?.watch} setValue={formContext?.setValue} />
                </Box>
              </Box>

              {formContext.watch('method') === 'video' &&
                formContext.selectedChannel &&
                channelLists &&
                channelLists.length > 0 && (
                  <Box display="flex" mt={2} px={1}>
                    <Box>
                      <Typography style={fontStyle.label} variant="body1">
                        {t('workout.channel.lists').toUpperCase()}
                      </Typography>

                      <Box mt={2} display="flex">
                        {channelLists.map((list, index) => (
                          <Box
                            mx={1}
                            onClick={() => changeChannelLists(list)}
                            display="flex"
                            className="link-hover"
                            key={index.toString()}
                          >
                            {checkChannelList(list) ? (
                              <CheckBoxIcon style={{ color: primary }} />
                            ) : (
                              <CheckBoxOutlineBlankIcon />
                            )}
                            <Typography variant="h6" ml={1} color="secondary">
                              {list.name}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  </Box>
                )}
            </Box>
            <Box m={1} my={2}>
              <CancellationPolicy watch={formContext.watch} setValue={formContext.setValue} />
            </Box>
            <Box m={1} my={2}>
              <WaitlistPolicy watch={formContext.watch} setValue={formContext.setValue} />
            </Box>
            <PayCoachCost watch={formContext.watch} setValue={formContext.setValue} />
          </>
        )}
      </Grid>
    </Grid>
  );
};
