import { useEffect, useRef, useState } from 'react';
import BeepEnd from 'assets/songs/timer-long-beep.wav';

const useAudioMeter = (config) => {
  const [audioStream, setAudioStream] = useState(null);
  const [openMicStatus, setOpenMicStatus] = useState(false);
  const [openSpeakerStatus, setOpenSpeakerStatus] = useState(false);
  const [volume, setVolume] = useState(0);

  let mediaStreamSource = null;
  const processor = useRef();

  function volumeAudioProcess(e) {
    const inputData = e.inputBuffer.getChannelData(0);
    const inputDataLength = inputData.length;
    let total = 0;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < inputDataLength; i++) {
      // eslint-disable-next-line no-plusplus
      total += Math.abs(inputData[i++]);
    }
    const rms = Math.sqrt(total / inputDataLength) * 100;
    setVolume(Math.round(rms, 0));
  }

  function createAudioMeter(audioContext, clipLevel, averaging, clipLag) {
    processor.current = audioContext.createScriptProcessor(512);
    processor.current.onaudioprocess = volumeAudioProcess;
    processor.current.clipping = false;
    processor.current.lastClip = 0;
    processor.current.volume = 0;
    processor.current.clipLevel = clipLevel || 0.98;
    processor.current.averaging = averaging || 0.95;
    processor.current.clipLag = clipLag || 750;

    // this will have no effect, since we don't copy the input to the output,
    // but works around a current Chrome bug.
    processor.current.connect(audioContext.destination);

    processor.current.checkClipping = function () {
      if (!this.clipping) {
        return false;
      }
      if (this.lastClip + this.clipLag < window.performance.now()) {
        this.clipping = false;
      }
      return this.clipping;
    };

    processor.current.shutdown = function () {
      this.disconnect();
      this.onaudioprocess = null;
    };
  }

  function beginDetect(stream) {
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    mediaStreamSource = audioContext.createMediaStreamSource(stream);
    createAudioMeter(audioContext);
    mediaStreamSource.connect(processor.current);
  }

  const micTest = async () => {
    setOpenMicStatus(true);
    if (audioStream) {
      audioStream.getTracks()[0].stop();
    }
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: { deviceId: config.deviceAudio.deviceId },
    });
    setAudioStream(stream);
    beginDetect(stream);
  };

  const testSpeaker = () => {
    setOpenSpeakerStatus(true);
    const audio = new Audio(BeepEnd);
    audio.setSinkId(config.deviceSpeaker.deviceId);
    audio.play();
  };

  useEffect(() => {
    if (audioStream) {
      audioStream.getTracks()[0].stop();
      processor.current.onaudioprocess = null;
      processor.current.disconnect();
    }
  }, [openMicStatus]); //eslint-disable-line

  return {
    volume,
    micTest,
    setOpenMicStatus,
    openMicStatus,
    testSpeaker,
    openSpeakerStatus,
    setOpenSpeakerStatus,
  };
};

export default useAudioMeter;
