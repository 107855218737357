import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Typography, { fontStyle } from 'core/ui/Typography';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { sessionSelectors } from 'modules/session';
import { workoutsActions, workoutsSelectors } from 'modules/workouts';
import { sessionWorkoutActions } from 'modules/sessionWorkout';
import ConfirmDialog from 'core/ui/ConfirmDialog';
import FormScore from 'components/Stream/SideBar/components/Sections/FormScore';
import { primary, secondaryLight } from 'core/ui/Colors';
import EmbedUploadClass from 'pages/shared/embed/UploadMobile';
import notifications from 'modules/notifications';
import { ButtonPrimary } from 'core/ui/Button';
import { convertToSeconds } from 'core/utils/helpers';
import TimeSelector from 'core/ui/TimeSelector';
import useConfirmation from 'core/useConfirmation';
import StartAgain from './StartAgain';

export default function ModalUpload({ open, close, buttonColor }) {
  const { t } = useTranslation();
  const workout = useSelector((state) => workoutsSelectors.getWorkout(state));
  const user = useSelector((state) => sessionSelectors.getUser(state));
  const dispatch = useDispatch();
  const [confirmCancel, setConfirmCancel] = useState({ open: false });
  const [uploadStatus, setUploadStatus] = useState(null);
  const [startWorkoutTimer, setStartWorkoutTimer] = useState({});
  const { confirmation } = useConfirmation();
  const hasVideo =
    workout &&
    workout.recordingVideos &&
    workout.recordingVideos.find((x) => x.user.id === user.id);

  const [step, setStep] = useState(hasVideo ? 'start' : 'formScore');

  useEffect(() => {
    if (workout.id) {
      dispatch(
        workoutsActions.getWorkoutSectionUserStart({
          workoutId: workout.id,
          userId: user.id,
        }),
      );
    }
  }, [workout, user, dispatch]);

  const onCompleteUpload = (data) => {
    dispatch(
      sessionWorkoutActions.processLocalRecordingStart({
        workoutId: workout.id,
        userId: user.id,
        s3Url: data.s3Url,
        startCountdownSeconds:
          startWorkoutTimer && startWorkoutTimer.value
            ? convertToSeconds(startWorkoutTimer.value)
            : 0,
        callback: () => {
          dispatch(workoutsActions.getWorkoutStart(workout.id));
          dispatch(notifications.success('Video uploaded'));
          close();
        },
      }),
    );
  };

  useEffect(() => {
    if (uploadStatus && uploadStatus.complete) {
      onCompleteUpload(uploadStatus);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadStatus]);

  const confirmStartAgain = () => {
    setConfirmCancel(() => ({
      open: true,
      description: t('competition.workout.replace'),
      confirm: () => {
        dispatch(
          sessionWorkoutActions.resetWorkoutCompetitionStart({
            workoutId: workout.id,
            callback: () => {
              dispatch(workoutsActions.getWorkoutStart(workout.id));
              setConfirmCancel({ open: false });
              close();
            },
          }),
        );
      },
    }));
  };

  const confirmClose = () => {
    setConfirmCancel(() => ({
      open: true,
      description: t('competition.workout.close.modal.upload'),
      confirm: () => {
        setConfirmCancel({ open: false });
        close();
      },
    }));
  };

  const checkNextStep = () => {
    if (hasVideo) {
      close();
      return;
    }

    confirmation({
      description: t('competition.scoreWithVideo'),
      labelCancel: t('button.no'),
      labelConfirm: t('button.yes'),
      noClick: (closeModal) => {
        closeModal();
        close();
      },
      yesClick: () => {
        setStep('uploadVideo');
      },
    });
  };

  return (
    <div>
      <Dialog
        PaperProps={{ style: { borderRadius: 5, backgroundColor: secondaryLight } }}
        maxWidth="sm"
        fullWidth
        scroll="body"
        onClose={() => confirmClose()}
        open={open}
      >
        <Typography variant="h5" align="center" mt={5}>
          {workout.name}
        </Typography>
        <Box p={5}>
          <ConfirmDialog
            open={confirmCancel.open}
            confirm={() => confirmCancel.confirm()}
            cancel={() => setConfirmCancel({ open: false })}
            description={confirmCancel.description}
            labelConfirm={t('button.confirm')}
            labelCancel={t('button.cancel')}
          />
          <Box>
            {step === 'start' && (
              <Box display="flex" mt={5} justifyContent="center">
                <ButtonPrimary
                  buttonColor={buttonColor || primary}
                  style={{
                    width: '100%',
                    marginRight: 2,
                  }}
                  onClick={() => confirmStartAgain()}
                >
                  {t('button.startAgain')}
                </ButtonPrimary>
                <ButtonPrimary
                  buttonColor={buttonColor || primary}
                  style={{
                    width: '100%',
                    marginLeft: 2,
                  }}
                  onClick={() => setStep('formScore')}
                >
                  {t('button.editScores')}
                </ButtonPrimary>
              </Box>
            )}

            {step === 'startAgain' && (
              <StartAgain
                buttonColor={buttonColor}
                workout={workout}
                confirmStartAgain={confirmStartAgain}
              />
            )}
            {step === 'formScore' && (
              <Box pb={5}>
                <Box px={5} mb={3}>
                  <Typography component="div">
                    <Typography color="secondary">STEP1:&nbsp;</Typography>
                    <Typography color="secondary">
                      {t('workout.competition.addScoreTitle')}
                    </Typography>
                  </Typography>
                </Box>
                <FormScore
                  sectionData={workout && workout.sections && workout.sections[0]}
                  user={user}
                  showSaveButton
                  workout={workout}
                  buttonColor={buttonColor}
                  saveCallback={() => checkNextStep()}
                />
              </Box>
            )}
            {step === 'uploadVideo' && (
              <>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  mb={3}
                >
                  <Box mb={3}>
                    <TimeSelector
                      time={startWorkoutTimer}
                      setTime={(time) => setStartWorkoutTimer(time)}
                      label={t('workout.competition.upload.startWorkoutCoutndownTime')}
                      milliseconds
                    />
                  </Box>
                  <Typography color="secondary" style={fontStyle.medium}>
                    {t('workout.competition.upload.startWorkoutCoutndownTimeHelp')}
                  </Typography>
                </Box>
                <EmbedUploadClass
                  bgColor={secondaryLight}
                  setUploadModal={setUploadStatus}
                  compButtonColor={buttonColor}
                  close={close}
                  web
                />
                <Typography color="secondary" style={fontStyle.medium}>
                  {t('workout.competition.upload.note')}
                </Typography>
              </>
            )}
          </Box>
        </Box>
      </Dialog>
    </div>
  );
}
