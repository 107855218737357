import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Box from 'core/ui/Box';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonPrimary } from 'core/ui/Button';
import LoaderSm from 'core/ui/LoaderSm';
import { useTranslation } from 'react-i18next';
import { searchActions, searchSelectors } from 'modules/search';
import Scrollbars from 'react-custom-scrollbars';
import Paper from 'core/ui/Paper';
import Typography from 'core/ui/Typography';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import { primary } from 'core/ui/Colors';
import ListUsers from './ListUsers';

const SearchMembers = ({ onSelectedUser, query, setQuery, channelId, buttonColor }) => {
  const dispatch = useDispatch();
  const dataUsers = useSelector((state) => searchSelectors.getChannelMembers(state));
  const loadingUsers = useSelector((state) => searchSelectors.getLoadingUsers(state));
  const { t } = useTranslation();

  const [info, setInfo] = useState(false);

  const refInput = React.createRef();
  const setFocus = () => {
    // eslint-disable-next-line no-unused-expressions
    refInput.current && refInput.current.focus();
  };

  const search = (p) => {
    if (!Number.isNaN(Number(query))) {
      onSelectedUser({ id: Number(query) });
      return;
    }

    dispatch(
      searchActions.getSearchChannelMembersStart({
        query,
        channelId,
        page: p,
      }),
    );
    setTimeout(() => {
      setInfo(true);
    }, 300);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      search(1);
    }
  };

  useEffect(() => {
    if (query === '') {
      setFocus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(
    () => () => {
      dispatch(searchActions.clearSearchUsers());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    if (!query) {
      setInfo(false);
      dispatch(searchActions.clearSearchUsers());
    }
  }, [dispatch, query]);

  return (
    <Box className="paper-rounded" style={{ width: '100%', borderRadius: 30 }} pb={5}>
      <Box>
        <Box display="flex" alignItems="center">
          <TextFieldRounded
            value={query}
            onChange={(v) => setQuery(v)}
            style={{ minWidth: 30, width: '100%', margin: '4px' }}
            placeholder={t('checkIn.searchUsers')}
            onKeyDown={(e) => handleKeyDown(e)}
            bordered
            autoFocus
            inputProps={{ ref: refInput }}
          />

          <Box ml={1}>
            <ButtonPrimary
              disabled={loadingUsers}
              onClick={() => search()}
              style={{ height: 40 }}
              buttonColor={buttonColor || primary}
            >
              {t('button.search')}
            </ButtonPrimary>
          </Box>
        </Box>
      </Box>

      <Paper p={3} className="paper-rounded" mt={3}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <LoaderSm loading={loadingUsers} />
        </Box>

        {!loadingUsers && query && info && dataUsers && dataUsers.length === 0 && (
          <Box display="flex" justifyContent="center" alignItems="center">
            <Typography>
              {t('channel.members.search.notFound').replace('#{text}', query)}
            </Typography>
          </Box>
        )}

        <Scrollbars autoHeight autoHeightMax={500}>
          <ListUsers onSelectedUser={onSelectedUser} users={dataUsers} buttonColor={buttonColor} />
        </Scrollbars>
      </Paper>
    </Box>
  );
};

SearchMembers.propTypes = {
  onSelectedUser: PropTypes.func,
};

SearchMembers.defaultProps = {
  onSelectedUser: () => {},
};

export default SearchMembers;
