import React, { useEffect, useState } from 'react';
import Box from 'core/ui/Box';

import { useTranslation } from 'react-i18next';
import Grid from 'core/ui/Grid';
import { ButtonPrimarySm } from 'core/ui/Button';
import Card from 'core/ui/Card';
import Checkbox from 'core/ui/Checkbox';
import { primary } from 'core/ui/Colors';
import { useDispatch, useSelector } from 'react-redux';
import { messageListsSelectors } from 'modules/messageLists';
import { messagesActions, messagesSelectors } from 'modules/messages';
import LoaderSm from 'core/ui/LoaderSm';
import SendIcon from '@material-ui/icons/Send';
import { TextServiceContext } from 'contexts';
import useTextService from 'components/Commmunication/useTextService';
import ModalCreateList from './MessageLists/Modal';
import TableMessageLists from './MessageLists/Table';
import FormSend from './Form/Form';
import Messages from './Messages';
import MessagesReplies from './MessagesReplies';

export default () => {
  const textService = useTextService({ channelId: null });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [modalList, openModalList] = useState(false);
  const countUsers = useSelector((state) => messagesSelectors.getStreamCountUsers(state));
  const loadingSend = useSelector((state) => messagesSelectors.getLoadingSend(state));
  const data = useSelector((state) => messageListsSelectors.getMessageLists(state));

  useEffect(() => {
    dispatch(messagesActions.getStreamUserCountStart());
    dispatch(messagesActions.getTwilioNumbersStart());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data && openModalList && openModalList.list) {
      openModalList((prev) => ({ ...prev, list: data.find((x) => x.id === prev.list.id) }));
    }
  }, [data]);

  return (
    <TextServiceContext.Provider value={{ ...textService }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8} p={3}>
          <Card
            btopcolor={primary}
            btopwidth={2}
            hidedivider="true"
            title={t('admin.sendMessage')}
            action={
              <Box pt={1} m={1}>
                <ButtonPrimarySm
                  ref={textService.formElementRef}
                  disabled={loadingSend}
                  onClick={() => textService.handleSubmit(textService.onSubmit)()}
                >
                  {loadingSend ? (
                    <LoaderSm loading width={20} />
                  ) : (
                    <SendIcon style={{ fontSize: 15 }} />
                  )}
                  &nbsp;{t('button.send')}
                </ButtonPrimarySm>
              </Box>
            }
          >
            <FormSend />
          </Card>
        </Grid>
        <Grid item xs={12} md={4} p={3}>
          {modalList && (
            <ModalCreateList list={modalList.list} close={() => openModalList(false)} />
          )}

          <Box mb={3}>
            <Card
              btopcolor={primary}
              btopwidth={2}
              hidedivider="true"
              title={t('admin.streamUsers')}
            >
              <Box px={3} py={1} pb={3}>
                <Checkbox
                  label={`${t('admin.textService.streamUSUsers')} (${countUsers.countUs})`}
                  checked={textService.watch('streamUSUsers')}
                  onClick={() =>
                    textService.setValue('streamUSUsers', !textService.watch('streamUSUsers'))
                  }
                />
                <Checkbox
                  label={`${t('admin.textService.streamWorldUsers')} (${countUsers.countOthers})`}
                  checked={textService.watch('streamWorldUsers')}
                  onClick={() =>
                    textService.setValue('streamWorldUsers', !textService.watch('streamWorldUsers'))
                  }
                />
              </Box>
            </Card>
          </Box>
          <Card
            btopcolor={primary}
            btopwidth={2}
            hidedivider="true"
            title={t('admin.messageLists')}
            action={
              <Box pt={1} m={1}>
                <ButtonPrimarySm onClick={() => openModalList(true)}>
                  {t('button.createList')}
                </ButtonPrimarySm>
              </Box>
            }
          >
            <TableMessageLists
              remove={(item) => textService.removeContactList(item)}
              edit={(list) => openModalList((prev) => ({ ...prev, list }))}
            />
          </Card>
        </Grid>
      </Grid>

      <Box my={3}>
        <Messages />
      </Box>

      <Box my={3}>
        <MessagesReplies />
      </Box>
    </TextServiceContext.Provider>
  );
};
