import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Form from './Form';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#f3f3f3',
  },
}));

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function ModalForm({
  open,
  close,
  currentPlan,
  setCurrentPlan,
  setEditIndex,
  editIndex,
  channel,
}) {
  const classes = useStyles();

  return (
    <div>
      <Dialog maxWidth="lg" scroll="body" onClose={() => close()} open={open} fullWidth>
        <div className={classes.root}>
          <DialogContent dividers>
            <Form
              currentPlan={currentPlan}
              setCurrentPlan={setCurrentPlan}
              setEditIndex={setEditIndex}
              editIndex={editIndex}
              closeModal={close}
              channel={channel}
            />
          </DialogContent>
        </div>
      </Dialog>
    </div>
  );
}
