import React from 'react';
import PropTypes from 'prop-types';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import Typography from '@material-ui/core/Typography';
import { shortDateTime } from 'core/utils/formatters';
import { useTranslation } from 'react-i18next';

const Join = ({ event }) => {
  const { t } = useTranslation();

  return (
    <TimelineItem>
      <TimelineOppositeContent>
        <Typography color="textSecondary">{shortDateTime(event.date)}</Typography>
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot />
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <Typography>
          {t(`workout.event.${event.kind}`)} - {event.user.name}
        </Typography>
      </TimelineContent>
    </TimelineItem>
  );
};

export default Join;

Join.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  event: PropTypes.object,
};

Join.defaultProps = {
  event: {},
};
