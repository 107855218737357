import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

import * as selectors from './selectors';
import * as sagas from './sagas';

/* ---------- Action Creators and Types ---------- */
const { Types, Creators } = createActions(
  {
    getAdvertisingsError: ['error'],
    getAdvertisingsStart: ['data'],
    getAdvertisingsSuccess: ['advertising'],

    postRegistrationError: ['error'],
    postRegistrationStart: ['options'],
    postRegistrationSuccess: [],

    postClickError: ['error'],
    postClickStart: ['options'],
    postClickSuccess: [],
  },
  { prefix: 'advertisings/' },
);

/* ---------- Initial State ---------- */
const INITIAL_STATE = Immutable({
  advertising: [],
  error: null,
  loading: false,
});

/* ---------- Reducers ---------- */
const loadStartReducer = (state) => state.set('loading', true);

const getAdvertisingsSuccessReducer = (state, action) =>
  state.merge({
    advertising: action.advertising,
    loading: false,
    error: null,
  });

const postRegistrationSuccessReducer = (state) =>
  state.merge({
    loading: false,
    error: null,
  });

const postClickSuccessReducer = (state) =>
  state.merge({
    loading: false,
    error: null,
  });

const loadErrorReducer = (state, action) =>
  state.merge({
    loading: false,
    error: action.error,
  });

/* ---------- Main Reducer ---------- */
const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_ADVERTISINGS_START]: loadStartReducer,
  [Types.GET_ADVERTISINGS_SUCCESS]: getAdvertisingsSuccessReducer,
  [Types.GET_ADVERTISINGS_ERROR]: loadErrorReducer,

  [Types.POST_REGISTRATION_START]: loadStartReducer,
  [Types.POST_REGISTRATION_SUCCESS]: postRegistrationSuccessReducer,
  [Types.POST_REGISTRATION_ERROR]: loadErrorReducer,

  [Types.POST_CLICK_START]: loadStartReducer,
  [Types.POST_CLICK_SUCCESS]: postClickSuccessReducer,
  [Types.POST_CLICK_ERROR]: loadErrorReducer,
});

/* ---------- Exporting ---------- */
export const advertisingsActions = Creators;
export const advertisingsTypes = Types;
export const advertisingsSelectors = selectors;
export const advertisingsSagas = sagas;

export default reducer;
