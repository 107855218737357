import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import Typography from 'core/ui/Typography';

import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import { formatDateToApi, timeWithoutTz } from 'core/utils/formatters';
import Checkbox from 'core/ui/Checkbox';
import { timeZoneName } from 'core/utils/formatters/date';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import LoaderSm from 'core/ui/LoaderSm';
import { secondaryXXLight } from 'core/ui/Colors';
import Paper from 'core/ui/Paper';

export default ({ watch, setValue, workout, scheduleOptions, channelId, hideText }) => {
  const { t } = useTranslation();
  const { request } = useActions();
  const [channelUpcomingWorkouts, setChannelUpcomingWorkouts] = useState([]);
  const [loading, setLoading] = useState(false);
  const relatedWorkouts = watch('relatedWorkouts') || [];

  const getWorkouts = () => {
    if (channelId) {
      setLoading(true);
      const { scheduledAtDate } = scheduleOptions[0];
      if (scheduledAtDate) {
        const scheduledAt = formatDateToApi(scheduledAtDate, timeZoneName());
        request({
          action: Modules.newChannels.actions.getChannelCalendarWorkoutsStart,
          data: {
            channelId,
            startAt: scheduledAt,
            endAt: scheduledAt,
            timezone: timeZoneName(),
            cloneContent: true,
          },
          options: {
            onSuccess: (data) => {
              setLoading(false);
              setChannelUpcomingWorkouts(data);
            },
            onError: () => {
              setLoading(false);
            },
          },
        });
      }
    }
  };

  useEffect(() => {
    if (scheduleOptions && scheduleOptions.length > 0) {
      getWorkouts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workout, scheduleOptions]);

  const checkWorkout = (w, isChecked) => {
    if (isChecked) {
      setValue(
        'relatedWorkouts',
        relatedWorkouts.filter((x) => x !== w.id),
      );
    } else {
      setValue('relatedWorkouts', [...relatedWorkouts, w.id]);
    }
  };

  const allWorkouts =
    channelUpcomingWorkouts &&
    channelUpcomingWorkouts.filter((x) => Number(x.id) !== Number(workout?.id));

  const groupbyTypes = () => {
    const types = {};
    allWorkouts.forEach((x) => {
      const key = x?.channelsWorkoutsType?.id || 'all';
      if (!types[key]) {
        types[key] = [];
      }

      types[key].push(x);
    });

    return types;
  };

  const workouts = groupbyTypes();

  return (
    <Box>
      <Box>
        <Box display="flex" alignItems="center">
          <Box flexGrow="1">
            <Typography variant="h5" mb={3} color="primary">
              {t('workout.clone.content')}
            </Typography>
          </Box>
        </Box>
        {!hideText && (
          <Typography variant="caption" mb={3} color="secondary">
            {t('workout.clone.content.description')}
          </Typography>
        )}

        <Box>
          {loading && <LoaderSm loading={loading} />}
          <Paper mt={2} className="paper-rounded">
            <Box p={3}>
              <Checkbox
                label={t('label.selectAll')}
                checked={
                  relatedWorkouts?.filter((x) => Number(x) !== Number(workout?.id))?.length > 0 &&
                  relatedWorkouts?.length === allWorkouts?.length
                }
                onClick={() =>
                  allWorkouts?.length > 0 && relatedWorkouts?.length === allWorkouts?.length
                    ? setValue('relatedWorkouts', [])
                    : setValue(
                        'relatedWorkouts',
                        allWorkouts?.map((x) => x.id),
                      )
                }
              />
            </Box>

            <Table size="small">
              <TableBody>
                {Object.keys(workouts).map((group) => (
                  <React.Fragment key={group}>
                    <TableRow>
                      <TableCell colSpan={3} style={{ backgroundColor: secondaryXXLight }}>
                        <Box display="flex" alignItems="center">
                          <Checkbox
                            checked={
                              !workouts[group].find(
                                (x) => !relatedWorkouts.find((y) => Number(y) === x.id),
                              )
                            }
                            onClick={() =>
                              workouts[group]?.length > 0 &&
                              relatedWorkouts?.length === workouts[group]?.length
                                ? setValue('relatedWorkouts', [])
                                : setValue(
                                    'relatedWorkouts',
                                    workouts[group]?.map((x) => x.id),
                                  )
                            }
                          />
                          <Typography ml={2}>
                            {workouts[group][0]?.channelsWorkoutsType?.name || 'No Type'}
                          </Typography>
                        </Box>
                      </TableCell>
                    </TableRow>
                    {workouts[group].map((row, index) => {
                      const checked =
                        relatedWorkouts &&
                        !!relatedWorkouts.find((x) => Number(x) === Number(row.id));
                      return (
                        <TableRow key={index.toString()}>
                          <TableCell>
                            <Checkbox
                              onClick={() => checkWorkout(row, checked)}
                              checked={checked}
                            />
                          </TableCell>
                          <TableCell>
                            {row?.id} - {row.name && row.name.toUpperCase()}
                          </TableCell>
                          <TableCell>{timeWithoutTz(row.scheduledAt)}</TableCell>
                        </TableRow>
                      );
                    })}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </Box>
      </Box>
    </Box>
  );
};
