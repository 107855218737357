import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import Typography, { fontStyle } from 'core/ui/Typography';
import { useDispatch } from 'react-redux';
import { Dialog, IconButton } from '@material-ui/core';
import Texture from 'core/ui/Texture';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import { useForm } from 'react-hook-form-v5';
import { ButtonPrimary } from 'core/ui/Button';
import TextAreaFieldRounded from 'core/ui/TextAreaFieldRounded';
import Radio from 'core/ui/Radio';
import notifications from 'modules/notifications';
import DatePickerRounded from 'core/ui/DatePickers/DatePickerRounded';
import { formatDateApi, formatDateToApi } from 'core/utils/formatters';
import Paper from 'core/ui/Paper';
import SelectorPaymentMethod, { PaymentMethodViewType } from 'components/Account/Selector';
import { NavigateBefore, Warning } from '@material-ui/icons';
import Toggle from 'core/ui/Toggle';
import { primary, redColor } from 'core/ui/Colors';
import Grid from 'core/ui/Grid';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import useSelectors from 'modules/map/useSelectors';
import { newPaymentsModule } from 'modules/payments/new';
import LoaderSm from 'core/ui/LoaderSm';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import { ChannelKeyNextCostViewer } from './ChannelKeyNextCostViewer';

export default function ModalAddPayment({ member, open, channel, channelKey, close, callback }) {
  const { t } = useTranslation();
  const { request } = useActions();
  const loading = useSelectors(newPaymentsModule, 'loadingAddPayment');
  const [viewMethodPayment, setViewMethodPayment] = useState(PaymentMethodViewType.list);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [channelMembershipCouponId, setChannelMembershipCouponId] = useState(null);
  const [errorsState, setErrorsState] = useState([]);
  const dispatch = useDispatch();
  const { setValue, watch, errors, register, handleSubmit } = useForm();

  useEffect(() => {
    register('summary', { required: !channelKey?.id });
    register('offlineType', { required: false });
    register('amount', { required: !channelKey?.id });
    register('date', { required: false });
    register('paid', { required: false });
    register('offline', { required: false });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    setTimeout(() => {
      setValue('offline', true);
      setValue('paid', true);
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isPostPaymentCreate = !!channelKey?.id;

  const validate = (data) => {
    const vErrors = [];
    if (!data?.offline && !selectedPaymentMethod) {
      vErrors.push(t('channel.modalPayment.validation.selectAccount'));
    }
    if (data?.offline && !data?.offlineType) {
      vErrors.push(t('channel.payment.validation.offlineType'));
    }

    setErrorsState(vErrors);
    if (vErrors.length > 0) {
      return false;
    }
    return true;
  };

  const savePayment = (data) => {
    if (!validate(data)) {
      return;
    }

    const dateParam = data?.date || formatDateApi(new Date());

    const date = formatDateToApi(`${dateParam.substr(0, 10)} ${dateParam.substr(11, 8)}`);

    request({
      action: Modules.newPayments.actions.addGenericPayment,
      data: {
        ...data,
        date,
        channelId: channel?.id,
        channelKeyId: channelKey?.id,
        postPayment: !!channelKey?.id,
        memberId: member?.id,
        userPaymentMethodId: selectedPaymentMethod && selectedPaymentMethod.id,
        channelMembershipCouponId: isPostPaymentCreate ? channelMembershipCouponId : undefined,
      },
      options: {
        onSuccess: () => {
          dispatch(notifications.success(t('channel.generic.payment.saved')));
          if (callback) {
            callback();
          }
          close();
        },
        onError: (err) => {
          dispatch(notifications.error(err));
        },
      },
    });
  };

  useEffect(() => {
    if (channelKey?.channelMembershipCouponId) {
      setChannelMembershipCouponId(channelKey?.channelMembershipCouponId);
    }
  }, [channelKey?.channelMembershipCouponId]);

  return (
    <Dialog open={open} fullWidth maxWidth="xs" scroll="body" onClose={() => close()}>
      <Texture>
        <Box p={5}>
          <Typography mb={5} variant="h4" align="center">
            {t(
              isPostPaymentCreate
                ? 'channel.member.postPaymentConfirmation'
                : 'channel.member.addPayment',
            )}
          </Typography>
          {!isPostPaymentCreate && (
            <Box mb={3}>
              <TextAreaFieldRounded
                value={watch('summary') || ''}
                placeholder={t('channel.placeholder.offlinePayments.summary')}
                label={t('channel.placeholder.offlinePayments.summary').toUpperCase()}
                onChange={(v) => setValue('summary', v)}
                error={errors.summary}
                required
              />
            </Box>
          )}

          {!isPostPaymentCreate && (
            <Grid container mb={3}>
              <Grid item xs={12} md={6}>
                <Box>
                  <DatePickerRounded
                    type="datetime-local"
                    disablePast={false}
                    error={errors.date}
                    label={t('channel.label.date').toUpperCase()}
                    onChange={(v) => setValue('date', v)}
                    value={watch('date')}
                    usFormat={channel?.useUsDateFormat}
                    required
                  />
                </Box>
              </Grid>

              <Grid item xs={12} md={6}>
                <Box>
                  <TextFieldRounded
                    type="number"
                    value={watch('amount') || ''}
                    pattern="[1-9]*"
                    placeholder={t('channel.placeholder.offlinePayments.amount')}
                    label={t('channel.placeholder.offlinePayments.amount').toUpperCase()}
                    onChange={(v) => setValue('amount', v)}
                    error={errors.amount}
                    required
                    currency
                    currencySymbol={channel?.currency?.symbol}
                  />
                </Box>
              </Grid>
            </Grid>
          )}

          <Box display="flex" m={3} justifyContent="center">
            <Toggle
              options={[
                { label: 'Offline', value: true, width: 100 },
                {
                  label: 'Online',
                  value: false,
                  width: 100,
                },
              ]}
              fontSize="xSmall"
              value={watch('offline')}
              color="white"
              onChange={(value) => setValue('offline', value)}
            />
          </Box>

          {watch('offline') === false ? (
            <Paper p={3} mb={2}>
              {viewMethodPayment === PaymentMethodViewType.form && (
                <Box display="flex" alignItems="center">
                  <Box>
                    <IconButton onClick={() => setViewMethodPayment(PaymentMethodViewType.list)}>
                      <NavigateBefore fontSize="large" />
                    </IconButton>
                  </Box>

                  <Box display="flex" flexGrow={1} justifyContent="center">
                    <Typography mt={3} mb={3} align="center" variant="h4">
                      {t('payment.method.save')}
                    </Typography>
                  </Box>
                </Box>
              )}

              <SelectorPaymentMethod
                selectedPaymentMethod={selectedPaymentMethod}
                setSelectedPaymentMethod={setSelectedPaymentMethod}
                view={viewMethodPayment}
                setView={setViewMethodPayment}
                onlyMethod={channel?.allowAchPayments ? null : 'card'}
                userId={member?.id}
                guest={member && member.guest}
                userInfo={{
                  name: member?.name,
                  phone: member?.phone,
                  email: member?.email,
                }}
                bordered
                cardpointe={member?.gymConfig?.useCardpointe}
              />
            </Paper>
          ) : (
            <>
              <Box m={3}>
                <Typography mb={2} style={fontStyle.label}>
                  {t('channel.placeholder.offlinePayments.offlineType').toUpperCase()}
                </Typography>
                <Box display="flex">
                  <Radio
                    checked={watch('offlineType') === 'cash'}
                    label={t('channel.placeholder.offlinePayments.cash')}
                    onClick={() => setValue('offlineType', 'cash')}
                  />
                  <Box ml={3}>
                    <Radio
                      checked={watch('offlineType') === 'check'}
                      label={t('channel.placeholder.offlinePayments.check')}
                      onClick={() => setValue('offlineType', 'check')}
                    />
                  </Box>
                  <Box ml={3}>
                    <Radio
                      checked={watch('offlineType') === 'bank_draft'}
                      label={t('channel.placeholder.offlinePayments.bankDraft')}
                      onClick={() => setValue('offlineType', 'bank_draft')}
                    />
                  </Box>
                  <Box ml={3}>
                    <Radio
                      checked={watch('offlineType') === 'other'}
                      label={t('channel.placeholder.offlinePayments.other')}
                      onClick={() => setValue('offlineType', 'other')}
                    />
                  </Box>
                </Box>
              </Box>
              <Box mx={3} mb={3}>
                <Typography mb={2} style={fontStyle.label}>
                  {t('channel.label.offlinePaid').toUpperCase()}
                </Typography>
                <Box display="flex">
                  <Radio
                    checked={watch('paid')}
                    label={t('button.yes')}
                    onClick={() => setValue('paid', true)}
                  />
                  <Box ml={3}>
                    <Radio
                      checked={!watch('paid')}
                      label={t('button.no')}
                      onClick={() => setValue('paid', false)}
                    />
                  </Box>
                </Box>
              </Box>
            </>
          )}

          {channelKey && channelKey.membershipPlan.coupons?.length > 0 && (
            <>
              <Box mb={3}>
                <SelectBoxObject
                  label={t('channel.label.coupon')}
                  placeholder={t('channel.key.placeholder.coupon')}
                  emptyItem
                  emptyValue={null}
                  options={channelKey.membershipPlan.coupons.map((x) => ({
                    id: x.id,
                    code: x.code,
                  }))}
                  propLabel="code"
                  propValue="id"
                  setValue={(v) => setChannelMembershipCouponId(v)}
                  value={channelMembershipCouponId}
                />
              </Box>

              <ChannelKeyNextCostViewer
                userId={channelKey.userId}
                channelMembershipPlanId={channelKey.membershipPlan.id}
                channelMembershipCouponId={channelMembershipCouponId}
                startAt={channelKey.startAt}
                chargeGymAmount={channelKey.chargeGymAmount}
                className="tw-mb-3"
              />
            </>
          )}

          {errorsState.length > 0 && (
            <Paper className="paper-rounded" p={3} m={2}>
              <Box display="flex" flexDirection="column" justifyContent="center">
                {errorsState.map((error) => (
                  <Typography key={error} style={{ color: redColor }} variant="body2" mb={1}>
                    <Warning style={{ fontSize: 16, color: primary }} /> {error}
                  </Typography>
                ))}
              </Box>
            </Paper>
          )}

          <LoaderSm loading={loading} center />
          <ButtonPrimary
            fullWidth
            disabled={loading}
            onClick={() => (loading ? null : handleSubmit(savePayment)())}
          >
            {t('button.save')}
          </ButtonPrimary>
        </Box>
      </Texture>
    </Dialog>
  );
}

ModalAddPayment.defaultProps = {};
