import api from 'core/api';

export const countriesModule = 'countries';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const actions = {
  getCountries: {
    module: countriesModule,
    name: 'getCountries',
    api: () => api.get(`/countries`),
    params: {
      start: ['params'],
      error: [''],
      success: ['countries'],
    },
    state: loadingStates,
  },
  getStates: {
    module: countriesModule,
    name: 'getStates',
    api: ({ countryId }) => api.get(`/countries/states/${countryId}`),
    params: {
      start: ['params'],
      error: [''],
      success: ['states'],
    },
    state: loadingStates,
  },
  getCurrencies: {
    module: countriesModule,
    name: 'getCurrencies',
    api: (data) => api.get(`/currencies`, data),
    params: {
      start: ['params'],
      error: [''],
      success: ['currencies'],
    },
    state: loadingStates,
  },
};

export default {
  actions,
  state: {
    countries: [],
    currencies: [],
    states: [],
    error: null,
    loading: false,
  },
};
