import React, { useContext, useState } from 'react';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import Typography, { fontStyle } from 'core/ui/Typography';
import ColorPicker from 'core/ui/ColorPicker';
import { Delete } from '@material-ui/icons';
import { ButtonSecondary } from 'core/ui/Button';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import Checkbox from 'core/ui/Checkbox';
import clsx from 'clsx';
import { CompetitionContext } from '../index';
import SelImages from '../SelImages';
import DivisionsCards from './DivisionsCards';

const ImageCard = ({ t, label, story, image, deleteImage, setImage }) => (
  <div>
    <Box>
      <Typography style={fontStyle.label} mb={1}>
        {label}
      </Typography>
      <div className="tw-flex tw-flex-row tw-gap-4">
        <Box className={`${story ? 'tw-h-24 tw-w-14' : 'tw-h-20 tw-w-20'} tw-bg-gray-300`}>
          {!!image?.url && (
            <img
              className={`${story ? 'tw-h-24 tw-w-14' : 'tw-h-20 tw-w-20'}`}
              draggable="false"
              src={image?.url}
              alt=""
            />
          )}
        </Box>

        <div className="tw-flex tw-flex-col tw-gap-2">
          <Box display="flex" justifyContent="center">
            <SelImages setImage={setImage} />
          </Box>
          {image && (
            <div>
              <ButtonSecondary size="small" onClick={deleteImage}>
                <Delete fontSize="small" />
                <Typography className="tw-pl-4 tw-font-bold">{t('button.remove')}</Typography>
              </ButtonSecondary>
            </div>
          )}
        </div>
      </div>
    </Box>
  </div>
);

const CompetitionMaterialDesign = ({ classes }) => {
  const { t } = useTranslation();
  const formContext = useContext(CompetitionContext);

  const [perPage, setPerPage] = useState(10);
  const [withoutLogo, setWithoutLogo] = useState(false);

  const divisions =
    (formContext?.competition.hasDivisions && formContext?.competition?.divisions) || [];

  const backgroundImage = formContext?.watch('designBackgroundImage')?._destroy
    ? null
    : formContext?.watch('designBackgroundImage');
  const backgroundStoryImage = formContext?.watch('designStoryBackgroundImage')?._destroy
    ? null
    : formContext?.watch('designStoryBackgroundImage');
  const logoImage = formContext?.watch('designLogoImage')?._destroy
    ? null
    : formContext?.watch('designLogoImage');

  return (
    <div>
      <Box mb={2} className={classes.content}>
        <div className="tw-grid tw-grid-cols-1 tw-gap-2 tw-p-4 sm:tw-grid-cols-2 md:tw-grid-cols-3 lg:tw-grid-cols-3 xl:tw-grid-cols-5">
          <div className="tw-flex tw-flex-col tw-gap-2">
            <div>
              <TextFieldRounded
                value={perPage}
                onChange={(v) => setPerPage(v)}
                label={t('competition.label.perPageResults')}
                type="number"
                pattern="[0-9]*"
              />
            </div>
            <Checkbox
              label={t('competition.materialDesign.useWithoutLogo')}
              checked={withoutLogo}
              onClick={() => setWithoutLogo(!withoutLogo)}
            />
          </div>

          <div className="tw-flex tw-items-start tw-justify-center">
            <ColorPicker
              color={formContext.watch('materialDesignFontColor')}
              setColor={(v) => formContext.setValue('materialDesignFontColor', v)}
              label={t('label.fontColor')}
            />
          </div>

          <ImageCard
            t={t}
            label={t('competition.label.designBackground')}
            image={backgroundImage}
            setImage={(img) => formContext?.setImage('designBackgroundImage', img)}
            deleteImage={() => formContext?.deleteImage('designBackgroundImage')}
          />
          <ImageCard
            t={t}
            label={t('competition.label.designStoryBackground')}
            image={backgroundStoryImage}
            setImage={(img) => formContext?.setImage('designStoryBackgroundImage', img)}
            deleteImage={() => formContext?.deleteImage('designStoryBackgroundImage')}
            story
          />

          <div>
            <ImageCard
              t={t}
              label={t('competition.label.designLogo')}
              image={logoImage}
              setImage={(img) => formContext?.setImage('designLogoImage', img)}
              deleteImage={() => formContext?.deleteImage('designLogoImage')}
            />
          </div>
        </div>
      </Box>

      <div>
        <div className="tw-flex tw-flex-row tw-items-center tw-gap-2">
          <Typography className="tw-mb-2 tw-text-lg tw-font-bold">
            {t(
              formContext.competition?.hasDivisions
                ? 'competition.card.topDivisions'
                : 'competition.card.topEvents',
              {
                perPage,
              },
            )}
          </Typography>
        </div>

        {formContext?.competition?.hasDivisions ? (
          <div className="tw-flex tw-flex-col tw-gap-2">
            {divisions?.map((division) => (
              <div key={division?.id} className={clsx([classes.content, 'tw-p-4'])}>
                <DivisionsCards division={division} perPage={perPage} withoutLogo={withoutLogo} />
              </div>
            ))}
          </div>
        ) : (
          <div className={classes.content}>
            <div className="tw-p-4">
              <DivisionsCards perPage={perPage} withoutLogo={withoutLogo} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CompetitionMaterialDesign;
