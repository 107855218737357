/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import Typography from 'core/ui/Typography';
import LoaderSm from 'core/ui/LoaderSm';
import Modules from 'modules';
import useActions from 'modules/map/useActions';
import { useDispatch } from 'react-redux';
import notifications from 'modules/notifications';
import { Chip } from '@material-ui/core';
import ClassDetail from 'components/Workout/Grid/Details/ClassDetail';
import { onlineColor, primary } from 'core/ui/Colors';

export default ({ selectedUser, channelId, buttonColor, closeModal }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { request } = useActions();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const memberCheckin = () => {
    setTimeout(() => {
      request({
        action: Modules.newChannelMembers.actions.memberCheckin,
        data: {
          channelId,
          userId: selectedUser?.id,
        },
        options: {
          onSuccess: (resp) => {
            setSuccess(resp);

            setTimeout(() => {
              closeModal();
            }, 4000);
          },
          onError: (err) => {
            setError(err);
            dispatch(notifications.warning(t(err)));
          },
        },
      });
    }, 1000);
  };

  useEffect(() => {
    if (selectedUser?.id) {
      memberCheckin();
    }
  }, [selectedUser]);

  return (
    <Box>
      {!error && !success && (
        <Box display="flex" alignItems="center" justifyContent="center">
          <LoaderSm loading />
          <Typography variant="h1" mx={1}>
            {t('label.checking')}
          </Typography>
        </Box>
      )}
      {!!error && (
        <Box m={3} display="flex" alignItems="center" justifyContent="center">
          <Chip
            style={{ backgroundColor: 'yellow' }}
            label={
              <Typography m={3} align="center">
                {t(error)}
              </Typography>
            }
          />
        </Box>
      )}

      {success && (
        <Box
          m={3}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          {success?.workoutId && (
            <ClassDetail
              setOpenDetail={() => {}}
              workoutId={success?.workoutId}
              embed={false}
              confirmCancelClass={() => {}}
              buttonColor={buttonColor || primary}
              resume
            />
          )}
          <Box mt={1}>
            <Chip
              style={{ backgroundColor: onlineColor }}
              label={<Typography align="center">CHECKED IN</Typography>}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};
