import api from 'core/api';

export const storeShopifyModule = 'storeShopify';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const loadingPullStates = {
  start: { loadingPull: true },
  error: { loadingPull: false },
  success: { loadingPull: false },
};

const actions = {
  getStoreShopifyLocations: {
    module: storeShopifyModule,
    name: 'getStoreShopifyLocations',
    api: (params) => api.get(`/store/shopify/locations`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: ['data'],
    },
    state: loadingStates,
  },
  pullInventory: {
    module: storeShopifyModule,
    name: 'pullInventory',
    api: (params) => api.get(`/store/shopify/pull_inventory`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingPullStates,
  },
};

export default {
  actions,
  state: {
    loading: false,
    loadingPull: false,
    data: [],
  },
};
