import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import propTypes from 'prop-types';
import Box from 'core/ui/Box';
import Typography from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import Divider from 'core/ui/Divider';
import { ButtonPrimary } from 'core/ui/Button';
import InstagramLink from 'components/Profile/InstagramLink';
import ImageMediaUser from 'components/Profile/ImageMedia';
import { secondary } from 'core/ui/Colors';
import Texture from 'core/ui/Texture';
import DatePickerRounded from 'core/ui/DatePickers/DatePickerRounded';
import useSelectors from 'modules/map/useSelectors';
import { newChannelsModule } from 'modules/channels/new';
import LoaderSm from 'core/ui/LoaderSm';
import Checkbox from 'core/ui/Checkbox';
import { channelsSelectors } from 'modules/channels';
import { useSelector } from 'react-redux';

export default function ModalUserInfo({ itemKey, user, open, cancel, confirm }) {
  const channel = useSelector((state) => channelsSelectors.getChannel(state));
  const loadingKeys = useSelector((state) => channelsSelectors.getLoadingKeys(state));

  const { t } = useTranslation();
  const [removeUserAt, setRemoveUserAt] = useState(null);
  const [options, setOptions] = useState({ inPerson: true, online: false });
  const loading = useSelectors(newChannelsModule, 'loading');

  useEffect(() => {
    setOptions((prev) => ({
      ...prev,
      online: channel?.activePlans?.online?.active,
      inPerson: channel?.activePlans?.inPerson?.active,
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel]);

  return (
    <div>
      <Dialog
        PaperProps={{ style: { borderRadius: 5, zIndex: 2000 } }}
        maxWidth="xs"
        fullWidth
        scroll="body"
        open={open}
      >
        <Texture>
          <Box display="flex" flexDirection="column" px={8} py={5}>
            <Typography variant="h2" color="primary" align="center">
              {user.name || user.phone || user.email}
            </Typography>
            <Divider style={{ width: '100%', backgroundColor: secondary }} my={3} />

            <Box display="flex" justifyContent="center">
              <ImageMediaUser
                size={180}
                sizeCountry="2em"
                heightCountry={30}
                user={user}
                image={user.image ? user.image : {}}
              />
            </Box>
            <Box display="flex" justifyContent="center">
              <InstagramLink instagram={user.instagram} clickable={false} />
            </Box>

            {user.description && (
              <Typography variant="caption" mb={3} color="secondary" className="roboto-regular">
                {user.description}
              </Typography>
            )}
            {user.country && (
              <Typography
                align="center"
                variant="body2"
                mb={3}
                color="secondary"
                className="roboto-regular"
              >
                {user.country && user.country.name}
              </Typography>
            )}
            {user.state && (
              <Typography
                align="center"
                variant="body2"
                mb={3}
                color="secondary"
                className="roboto-regular"
              >
                {user.state}
              </Typography>
            )}
            {user.parentName && (
              <Typography align="center" variant="h5" mb={3} color="secondary">
                Parent: {user.parentName}
              </Typography>
            )}

            {loading && <LoaderSm center loading />}

            {!loading && !itemKey?.id && (
              <Box>
                {loading && <LoaderSm />}

                <Typography>{t('channellKeys.assign.types')}</Typography>

                {channel?.activePlans?.online?.active && (
                  <Checkbox
                    label={t('channel.key.kind.online')}
                    onClick={() => setOptions((prev) => ({ ...prev, online: !prev.online }))}
                    checked={options?.online}
                  />
                )}
                {channel?.activePlans?.inPerson?.active && (
                  <Checkbox
                    label={t('channel.key.kind.in_person')}
                    onClick={() => setOptions((prev) => ({ ...prev, inPerson: !prev.inPerson }))}
                    checked={options?.inPerson}
                  />
                )}
                {channel?.hasHybrid && (
                  <Checkbox
                    label={t('channel.hybridAccess')}
                    onClick={() =>
                      setOptions((prev) => ({
                        ...prev,
                        inPersonHybridAccess: !prev.inPersonHybridAccess,
                      }))
                    }
                    checked={options?.inPersonHybridAccess}
                  />
                )}
                {channel?.activePlans?.sifely?.active && (
                  <Checkbox
                    label={t('channel.sifelyAccess')}
                    onClick={() =>
                      setOptions((prev) => ({
                        ...prev,
                        inPersonSifelyAccess: !prev.inPersonSifelyAccess,
                      }))
                    }
                    checked={options?.inPersonSifelyAccess}
                  />
                )}
              </Box>
            )}

            <Box display="flex" mt={4} alignItems="center">
              <Box flexGrow={1}>
                <ButtonPrimary onClick={() => cancel()} style={{ width: '100%' }}>
                  {t('button.cancel')}
                </ButtonPrimary>
              </Box>
              <Box mx={2} mt={-5}>
                <DatePickerRounded
                  label={t('channel.label.plan.removeUserDate')}
                  placeholder={t('channel.placeholder.plan.removeUserDate')}
                  value={removeUserAt}
                  usFormat={channel?.useUsDateFormat}
                  stylePaper={{ width: 160 }}
                  onChange={(v) => setRemoveUserAt(v)}
                />
              </Box>
              <Box flexGrow={1}>
                <ButtonPrimary
                  disabled={loadingKeys}
                  onClick={() => confirm(user, removeUserAt, options)}
                  style={{ width: '100%' }}
                >
                  {t('button.assign')}
                </ButtonPrimary>
              </Box>
            </Box>
          </Box>
        </Texture>
      </Dialog>
    </div>
  );
}

ModalUserInfo.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  user: propTypes.object,
  open: propTypes.bool,
  cancel: propTypes.func,
  confirm: propTypes.func,
};

ModalUserInfo.defaultProps = {
  user: {},
  open: false,
  cancel: () => {},
  confirm: () => {},
};
