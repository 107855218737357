import React, { useCallback, useMemo } from 'react';
import { Link } from '@material-ui/core';

import Modules from 'modules';
import useActions from 'modules/map/useActions';
import SFDataTable from 'core/ui/DataTable';
import { date } from 'core/utils/formatters/date';
import { showAPIError } from 'core/utils/showAPIError';
import { ReportChip } from './ReportChip';

export const Table = ({ onboarding, handleSubmitReport }) => {
  const { request } = useActions();

  const handleDeny = useCallback(
    (workflowInfos, channelId, denialId) => {
      request({
        action: Modules.automationWorkflows.actions.putWorkflow,
        data: {
          ownerableId: workflowInfos.workflowOwnerableId,
          ownerableType: workflowInfos.workflowOwnerableType,
          id: workflowInfos.workflowId,
          automationWorkflowsTypesAttributes: [
            {
              id: workflowInfos.workflowsTypeId,
              denialsAttributes: [
                {
                  deniableType: 'Channel',
                  deniableId: channelId,

                  // Remove denial
                  id: denialId,
                  _destroy: !!denialId,
                },
              ],

              // FIXME: See the respective field in backend for more informations
              automationTypeId: workflowInfos.workflowsTypeAutomationId,
            },
          ],
        },
        options: {
          onSuccess: () => handleSubmitReport(),
          onError: showAPIError,
        },
      });
    },
    [request, handleSubmitReport],
  );

  const columns = useMemo(() => {
    const newOnboardingColumns = onboarding.columns.map((column) => ({
      id: column.id,
      name: column.name,
      selector: column.selector,
      cell: (row) => (
        <ReportChip
          value={row[column.selector]}
          onClick={() => handleDeny(column.workflowInfos, row.id, row[column.selector].denial?.id)}
        />
      ),
    }));

    return fixedColumns.concat(newOnboardingColumns);
  }, [onboarding.columns, handleDeny]);

  return <SFDataTable noHeader columns={columns} data={onboarding.rows} paginationPerPage={25} />;
};

const fixedColumns = [
  {
    id: 'id',
    name: '#',
    selector: 'id',
    sortable: true,
    grow: 0,
  },
  {
    id: 'channelName',
    name: 'Channel',
    selector: 'name',
    sortable: true,
    cell: (channel) => (
      <Link href={`/channel/view/${channel.id}`} target="_blank" rel="noopener noreferrer">
        {channel.name}
      </Link>
    ),
  },
  {
    id: 'createdAt',
    name: 'Created At',
    selector: 'createdAt',
    sortable: true,
    cell: (channel) => date(channel.createdAt, 'YYYY-MM-DD Z'),
  },
];
