import { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form-v5';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import notifications from 'modules/notifications';
import { useDispatch } from 'react-redux';
import useSelectors from 'modules/map/useSelectors';
import { newWorkoutsModule } from 'modules/workouts/new';
import { formatDateApi, formatDateToApi } from 'core/utils/formatters';
import { timeZoneName } from 'core/utils/formatters/date';

export default ({ callback, workoutId, clone, partnersProgram }) => {
  const { request } = useActions();
  const dispatch = useDispatch();
  const loading = useSelectors(newWorkoutsModule, 'loading');
  const { register, handleSubmit, setValue, watch, reset, errors } = useForm();
  const [workout, setWorkout] = useState(null);
  const [submiting, setSubmiting] = useState(false);
  const [sections, setSections] = useState([]);

  const sectionsRef = useRef(sections);
  const isNew = clone || !workoutId;

  useEffect(() => {
    sectionsRef.current = sections;
  });

  const validate = () => {
    const errorsValidation = [];

    if (errorsValidation.length > 0) {
      dispatch(notifications.warning(errorsValidation.join(' ')));
      return false;
    }
    return true;
  };

  const onSubmit = (values) => {
    if (!validate()) {
      return;
    }

    setSubmiting(true);

    setTimeout(() => {
      const timezone = values.timezone || timeZoneName();
      const scheduledAt = formatDateToApi(`${values.scheduledAtDate.substr(0, 10)} 12:00:00 UTC`);

      const data = {
        ...values,
        id: !isNew ? workoutId : null,
        scheduledAt,
        publishAt: scheduledAt,
        timezone,
        method: 'custom',
        durationMinutes: 60,
        partnersProgramId: partnersProgram?.id,
        sectionsAttributes: sectionsRef.current.map((s) => ({
          ...s,
          channelProgramId: null,
          id: isNew || s.new === true ? null : s.id,
        })),
        movementsAttributes:
          values.movements &&
          values.movements.map((x) => ({
            name: x.name ? x.name : x,
            movement: x.movement ? x.movement.name || x.movement : null,
            embedVideo: x.embedVideo,
          })),
        equipmentAttributes:
          values.equipment &&
          values.equipment.map((x) => ({
            name: x.name ? x.name : x,
            equipment: x.equipment ? x.equipment.name || x.equipment : null,
          })),
      };

      request({
        action: !isNew
          ? Modules.newWorkouts.actions.updateWorkout
          : Modules.newWorkouts.actions.createWorkout,
        data,
        options: {
          onSuccess: (workoutResp) => {
            if (callback) {
              callback(workoutResp);
            }
          },
        },
      });
    }, 500);
  };

  useEffect(() => {
    register('scheduledAtDate', { required: true });
    register('movements');
    register('equipment');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register]);

  const populateMovements = useCallback(() => {
    if (workout) {
      const movs = workout?.movements?.map((item) => ({
        id: item?.movement?.id,
        name: item.movement.name ? item.movement.name : item,
        movement: item.movementAlt && item.movementAlt.name,
        embedVideo: item.embedVideo || item?.movement?.mediaFile?.url,
      }));
      setValue('movements', movs);
    }
  }, [setValue, workout]);

  const populateEquipments = useCallback(() => {
    if (workout) {
      const movs = workout?.equipment?.map((item) => ({
        name: item.equipment.name ? item.equipment.name : item,
        equipment: item.equipmentAlt && item.equipmentAlt.name,
      }));
      setValue('equipment', movs);
    }
  }, [setValue, workout]);

  useEffect(() => {
    if (workout) {
      const wk = { ...workout };
      delete wk.movements;
      delete wk.equipment;
      delete wk.sections;
      reset(wk);
      // setSelectedChannel(workout.channel);

      if (workout) {
        setTimeout(() => {
          if (!clone) {
            setValue(
              'scheduledAtDate',
              formatDateApi(wk.scheduledAt, 'YYYY-MM-DD HH:mm:ss Z', wk.timezone),
            );
            setValue(
              'scheduledAtHour',
              formatDateApi(wk.scheduledAt, 'YYYY-MM-DD HH:mm:ss Z', wk.timezone),
            );
          }

          populateMovements();
          populateEquipments();
          setSections(
            workout?.sections?.map((s) => ({
              ...s,
              id: isNew ? null : s?.id,
              mediaFilesAssociations: s?.mediaFilesAssociations?.map((ass) => ({
                id: isNew ? null : ass.id,
                mediaFileId: ass.mediaFile?.id,
                mediaFile: ass.mediaFile,
                kind: ass.kind,
              })),
              mediaFilesAssociationsAttributes: s?.mediaFilesAssociations?.map((ass) => ({
                id: isNew ? null : ass.id,
                mediaFileId: ass.mediaFile?.id,
                mediaFile: ass.mediaFile,
                kind: ass.kind,
              })),
            })),
          );
        }, 100);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workout, reset, populateMovements, populateEquipments, setValue, clone]);

  useEffect(() => {
    if (workoutId) {
      request({
        action: Modules.newWorkouts.actions.getWorkout,
        data: { id: workoutId },
        options: {
          onSuccess: (workoutResp) => {
            setWorkout(workoutResp);
            setValue('name', workoutResp.name);
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workoutId]);

  return {
    register,
    setValue,
    watch,
    reset,
    errors,
    onSubmit,
    handleSubmit,
    submitForm: handleSubmit(onSubmit),
    loading,
    setSections,
    sections,
    submiting,
  };
};
