/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import Box from 'core/ui/Box';
import DatePickerRounded from 'core/ui/DatePickers/DatePickerRounded';
import { useForm } from 'react-hook-form-v5';
import { useTranslation } from 'react-i18next';
import { formatDateToApi } from 'core/utils/formatters/date';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import { ButtonPrimary } from 'core/ui/Button';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import useSelectors from 'modules/map/useSelectors';
import { channelManageModule } from 'modules/channelManage';
import LoaderSm from 'core/ui/LoaderSm';
import notifications from 'modules/notifications';
import { useDispatch } from 'react-redux';

export default ({ calendarInfo, channel, close }) => {
  const { t } = useTranslation();
  const actions = useActions();
  const loading = useSelectors(channelManageModule, 'loading');
  const dispatch = useDispatch();
  const { setValue, register, watch, handleSubmit, errors } = useForm();

  useEffect(() => {
    register('startAtDate', { required: true });
    register('repeat', { required: true });

    setTimeout(() => {
      setValue('repeat', 1);
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const calendarViewApi = { timeGridWeek: 'week', dayGridMonth: 'month', timeGridDay: 'day' };

  const submit = (data) => {
    const startAt = formatDateToApi(data.startAtDate);
    const calendarStartAt = formatDateToApi(calendarInfo.start);
    const calendarEndAt = formatDateToApi(calendarInfo.end);

    actions.request({
      action: Modules.channelManage.actions.cloneCalendarWorkouts,
      data: {
        channelId: channel?.id,
        startAt,
        calendarStartAt,
        calendarEndAt,
        calendarView: calendarViewApi[calendarInfo.currentView]?.toLowerCase(),
        repeat: data.repeat,
      },
      options: {
        onSuccess: () => {
          dispatch(notifications.success(t('channel.workouts.clone.success')));
          close();
        },
        onError: () => {
          dispatch(notifications.warning(t('channel.workouts.clone.error')));
        },
      },
    });
  };

  return (
    <Box px={3}>
      <Box display="flex">
        <Box m={1}>
          <DatePickerRounded
            name="scheduledAt"
            type="datetime-local"
            label={t('workout.label.scheduledAtDate').toUpperCase()}
            onChange={(v) => setValue('startAtDate', v)}
            value={watch('startAtDate')}
            usFormat={channel?.useUsDateFormat}
            error={errors.startAtDate}
            required
          />
        </Box>
        <Box m={1}>
          <TextFieldRounded
            label={t('workout.clone.repeat').toUpperCase()}
            onChange={(v) => setValue('repeat', v)}
            value={watch('repeat') || 1}
            type="number"
            error={errors.repeat}
          />
        </Box>
      </Box>

      <Box m={5}>
        <ButtonPrimary disabled={loading} onClick={() => handleSubmit(submit)()} fullWidth>
          <LoaderSm loading={loading} />
          {t('button.confirm')}
        </ButtonPrimary>
      </Box>
    </Box>
  );
};
