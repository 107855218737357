import { createSelector } from 'reselect';

const getState = (state) => state.search;

export const getLoading = createSelector(getState, (state) => !!state.loading);
export const getLoadingAutocomplete = createSelector(
  getState,
  (state) => !!state.loadingAutocomplete,
);
export const getLoadingFeatured = createSelector(getState, (state) => !!state.loadingFeatured);
export const getLoadingFeaturedUpcoming = createSelector(
  getState,
  (state) => !!state.loadingFeaturedUpcoming,
);

export const getQueryData = createSelector(getState, (state) => state.queryData);
export const getFilters = createSelector(getState, (state) => state.filters);

export const getLoadingUsers = createSelector(getState, (state) => !!state.loadingUsers);
export const getUsers = createSelector(getState, (state) => state.users);
export const getChannelMembers = createSelector(getState, (state) => state.channelMembers);

export const getSearch = createSelector(getState, (state) => state.data);
export const getSearchAutocomplete = createSelector(getState, (state) => state.dataAutocomplete);
export const getFeatured = createSelector(getState, (state) => state.dataFeatured);

export const getLoadingViewMore = createSelector(getState, (state) => !!state.loadingViewMore);
export const getViewMorePagination = createSelector(getState, (state) => state.viewMorePagination);
export const getViewMoreData = createSelector(getState, (state) => state.viewMoreData);
export const getWorkoutResults = createSelector(getState, (state) => state.workoutResults);
