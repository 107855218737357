import React, { useEffect } from 'react';
import Image from 'assets/img/bg-site.jpeg';
import { makeStyles } from '@material-ui/core/styles';
import LanguageSelector from 'components/LanguageSelector';
import Box from 'core/ui/Box';
import Form from 'components/Login/Form';
import { clearAuthHeaders } from 'core/api/auth-headers';
import { removeData } from 'core/utils/session-storage';
import logoHeader from '../../../assets/img/logo-transparent.png';

const useStyles = makeStyles(() => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    width: 260,
  },
  paperContainer: {
    backgroundImage: `url(${Image})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    height: '100vh',
    backgroundAttachment: 'fixed',
    backgroundSize: 'cover',
    backgroundColor: '#d9d9d9',
    display: 'flex',
    justifyContent: 'center',
  },
}));

export default () => {
  const classes = useStyles();
  useEffect(() => {
    clearAuthHeaders(removeData);
  }, []);

  return (
    <div className={classes.paperContainer}>
      <LanguageSelector
        style={{
          position: 'absolute',
          top: 5,
          right: 50,
          border: 'none',
        }}
      />
      <div className={classes.paper}>
        <Box mb={5}>
          <img src={logoHeader} className={classes.logo} alt="Stream Fitness Logo" />
        </Box>
        <Form />
      </div>
    </div>
  );
};
