import React, { useState } from 'react';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import { ButtonPrimary } from 'core/ui/Button';
import Grid from 'core/ui/Grid';
import Sections from 'components/Workout/Form/Section/Sections';
import Paper from 'core/ui/Paper';
import LoaderSm from 'core/ui/LoaderSm';
import BenchmarkOptions from 'components/Workout/Form/Section/BenchmarkOptions';
import DatePickerRounded from 'core/ui/DatePickers/DatePickerRounded';
import Movements from 'components/Workout/Form/Movements';
import Equipments from 'components/Workout/Form/Equipments';
import Divider from 'core/ui/Divider';
import ModalFormSection from 'components/Workout/Form/Section/Modal';
import useForm from './useForm';

const FormCustomWorkout = ({ partner, callback, workoutId, clone, partnersProgram }) => {
  const { t } = useTranslation();
  const [modalSection, setModalSection] = useState({ open: false });
  const { watch, setValue, errors, submitForm, loading, sections, setSections } = useForm({
    callback,
    workoutId,
    clone,
    partnersProgram,
  });
  return (
    <Box>
      <ModalFormSection
        modalSection={modalSection}
        setModalSection={setModalSection}
        sections={sections}
        partnerId={partnersProgram?.partnerId}
        setSections={setSections}
        partnerProgramming
      />

      <Box m={1} mb={3}>
        <DatePickerRounded
          name="scheduledAt"
          disablePast={false}
          type="datetime-local"
          label={t('workout.label.scheduledAtDate').toUpperCase()}
          onChange={(v) => setValue('scheduledAtDate', v)}
          value={watch('scheduledAtDate')}
          usFormat={partner?.useUsDateFormat}
          required
          error={errors?.scheduledAtDate}
        />
      </Box>

      <Grid container spacing={3} mt={3}>
        <Grid item xs={12} md={6}>
          <Paper p={3} mb={3}>
            <BenchmarkOptions
              isCompetition={false}
              mode={workoutId ? 'edit' : 'new'}
              setSections={setSections}
              setValue={setValue}
              sections={sections}
            />
          </Paper>
          <Paper p={3} mb={3}>
            <Sections
              openEditSection={(section) =>
                setModalSection({ open: true, currentSection: section })
              }
              addNewSection={() => setModalSection({ open: true })}
              setValue={setValue}
              sections={sections}
              setSections={setSections}
              watch={watch}
              workout={{ id: workoutId }}
              custom
              mode={workoutId ? 'edit' : 'new'}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box mb={5}>
            <Box>
              <Movements
                setMovements={(values) => setValue('movements', values)}
                movements={watch('movements') || []}
                ownerableId={partnersProgram?.partnerId}
                ownerableType="Partner"
              />
            </Box>

            <Divider my={3} mx={5} />
            <Box mt={3}>
              <Equipments
                setEquipment={(values) => setValue('equipment', values)}
                equipment={watch('equipment') || []}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Box flexGrow={1} display="flex" justifyContent="flex-end" my={5}>
        <ButtonPrimary disabled={loading} style={{ width: 150 }} onClick={() => submitForm()}>
          <LoaderSm loading={loading} />
          {t('button.save')}
        </ButtonPrimary>
      </Box>
    </Box>
  );
};

FormCustomWorkout.defaultProps = {
  callback: () => {},
  benchmark: null,
};

export default FormCustomWorkout;
