import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

import * as selectors from './selectors';
import * as sagas from './sagas';

/* ---------- Action Creators and Types ---------- */
const { Types, Creators } = createActions(
  {
    postRatingError: ['error'],
    postRatingStart: ['data'],
    postRatingSuccess: [],

    getRatingsError: ['error'],
    getRatingsStart: ['klass', 'klassId', 'pagination'],
    getRatingsSuccess: ['ratings', 'pagination'],
  },
  { prefix: 'ratings/' },
);

/* ---------- Initial State ---------- */
const INITIAL_STATE = Immutable({
  data: [],
  pagination: {},
  error: null,
  loading: false,
});

/* ---------- Reducers ---------- */
const loadStartReducer = (state) => state.set('loading', true);

const postRatingSuccessReducer = (state) =>
  state.merge({
    loading: false,
    error: null,
  });

const getRatingsSuccessReducer = (state, action) =>
  state.merge({
    data: action.ratings,
    pagination: action.pagination,
    loading: false,
    error: null,
  });

const loadErrorReducer = (state, action) =>
  state.merge({
    loading: false,
    error: action.error,
  });

/* ---------- Main Reducer ---------- */
const reducer = createReducer(INITIAL_STATE, {
  [Types.POST_RATING_START]: loadStartReducer,
  [Types.POST_RATING_SUCCESS]: postRatingSuccessReducer,
  [Types.POST_RATING_ERROR]: loadErrorReducer,

  [Types.GET_RATINGS_START]: loadStartReducer,
  [Types.GET_RATINGS_SUCCESS]: getRatingsSuccessReducer,
  [Types.GET_RATINGS_ERROR]: loadErrorReducer,
});

/* ---------- Exporting ---------- */
export const ratingsActions = Creators;
export const ratingsTypes = Types;
export const ratingsSelectors = selectors;
export const ratingsSagas = sagas;

export default reducer;
