import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form-v5';
import useCloneWorkout from 'components/Profile/Dashboard/Workouts/Invite/useCloneWorkout';
import { useDispatch } from 'react-redux';
import notifications from 'modules/notifications';
import { useTranslation } from 'react-i18next';

export default ({ workouts, channel, close }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { register, handleSubmit, setValue, watch, reset, errors } = useForm();
  const { cloneFromModal } = useCloneWorkout();
  const [sections, setSections] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentSection, setCurrentSection] = useState(null);

  useEffect(() => {
    register('relatedWorkouts');
    register('movements');
    register('equipment');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register]);

  const populateMovements = useCallback(() => {
    let movs = [];
    workouts.forEach((workout) => {
      if (workout) {
        movs = [
          ...movs,
          ...workout?.movements?.map((item) => ({
            id: item?.movement?.id,
            name: item.movement.name ? item.movement.name : item,
            movement: item.movementAlt && item.movementAlt.name,
            embedVideo: item.embedVideo || item?.movement?.mediaFile?.url,
          })),
        ];
      }
    });
    setValue('movements', movs);
  }, [setValue, workouts]);

  const populateEquipments = useCallback(() => {
    let movs = [];
    workouts.forEach((workout) => {
      if (workout) {
        movs = [
          ...movs,
          ...workout?.equipment?.map((item) => ({
            name: item.equipment.name ? item.equipment.name : item,
            equipment: item.equipmentAlt && item.equipmentAlt.name,
          })),
        ];
      }
    });
    setValue('equipment', movs);
  }, [setValue, workouts]);

  const populateSections = useCallback(() => {
    let arrSections = [];
    workouts.forEach((workout) => {
      if (workout) {
        arrSections = [
          ...arrSections,
          ...workout?.sections.map((x, idx) => ({
            ...(x.detail ? x.detail : x),
            partnersProgram: workout?.partnersProgram,
            partnersProgramId: workout?.partnersProgram?.id,
            order: arrSections?.length + idx,
            mediaFilesAssociations: x?.mediaFilesAssociations?.map((ass) => ({
              mediaFileId: ass.mediaFile?.id,
              mediaFile: ass.mediaFile,
              kind: ass.kind,
            })),
            mediaFilesAssociationsAttributes: x?.mediaFilesAssociations?.map((ass) => ({
              mediaFileId: ass.mediaFile?.id,
              mediaFile: ass.mediaFile,
              kind: ass.kind,
            })),
          })),
        ];
      }
    });
    setSections(arrSections);
  }, [workouts]);

  useEffect(() => {
    if (workouts) {
      setTimeout(() => {
        populateMovements();
        populateEquipments();
        populateSections();

        if (channel?.id) {
          setValue('online', channel?.activePlans?.online?.active);
          setValue('inPerson', channel?.activePlans?.inPerson?.active);
        }
      }, 100);
    }
  }, [workouts, channel, reset, populateMovements, populateEquipments, setValue, populateSections]);

  const onSubmit = (data) => {
    setLoading(true);

    cloneFromModal({
      workout: workouts[0],
      newParams: {
        ...data,
        partnersProgramId: null,
        channelId: channel?.id,
        sectionsAttributes: sections.map((s) => ({
          ...s,
          id: null,
        })),
        movementsAttributes:
          data.movements &&
          data.movements.map((x) => ({
            name: x.name ? x.name : x,
            movement: x.movement ? x.movement.name || x.movement : null,
            embedVideo: x.embedVideo,
          })),
        equipmentAttributes:
          data.equipment &&
          data.equipment.map((x) => ({
            name: x.name ? x.name : x,
            equipment: x.equipment ? x.equipment.name || x.equipment : null,
          })),
        method: 'live',
        templateId: workouts[0]?.id,
        isTemplate: false,
        coachesAttributes: [],
        relatedWorkouts:
          data?.relatedWorkouts &&
          data?.relatedWorkouts
            .filter((x) => !workouts.find((y) => y.id === Number(x)))
            .map((x) => x),
      },
      callback: () => {
        dispatch(notifications.success(t('workout.partner.clone.message.success')));
        close();
        setLoading(false);
      },
    });
  };
  return {
    register,
    setValue,
    watch,
    reset,
    errors,
    submitForm: handleSubmit(onSubmit),
    loading,

    setCurrentSection,
    currentSection,
    sections,
    setSections,
  };
};
