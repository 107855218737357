import { useEffect } from 'react';
import { useForm } from 'react-hook-form-v5';
import { useSelector } from 'react-redux';
import { channelsSelectors } from 'modules/channels';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import { timeZoneName, formatDateToApi, formatDateApi } from 'core/utils/formatters/date';
import useSelectors from 'modules/map/useSelectors';
import { channelAnnouncementsModule } from 'modules/channelAnnouncements';

const useFormAnnouncements = ({ mode, item, callback }) => {
  const { request } = useActions();
  const channel = useSelector((state) => channelsSelectors.getChannel(state));
  const loading = useSelectors(channelAnnouncementsModule, 'loading');
  const { register, handleSubmit, setValue, watch, reset, errors } = useForm();

  const load = () => {
    request({
      action: Modules.channelAnnouncements.actions.getAnnouncements,
      data: { channelId: channel.id },
    });
  };

  const onSubmit = (values) => {
    const timezone = values.timezone || timeZoneName();
    const startAt = formatDateToApi(
      `${values.startAtDate.substr(0, 10)} ${values.startAtHour.substr(11, 8)}`,
      timezone,
    );
    const endAt = formatDateToApi(
      `${values.endAtDate.substr(0, 10)} ${values.endAtHour.substr(11, 8)}`,
      timezone,
    );

    if (mode === 'new') {
      request({
        action: Modules.channelAnnouncements.actions.postAnnouncement,
        data: { ...values, startAt, endAt, timezone, channelId: channel.id },
        options: {
          onSuccess: () => {
            load();
            if (callback) {
              callback();
            }
          },
        },
      });
    } else {
      request({
        action: Modules.channelAnnouncements.actions.putAnnouncement,
        data: { ...values, id: item.id, startAt, endAt, timezone, channelId: channel.id },
        options: {
          onSuccess: () => {
            load();
            if (callback) {
              callback();
            }
          },
        },
      });
    }
  };

  useEffect(() => {
    register('text', { required: true });
    register('textRaw', { required: true });
    register('startAtDate', { required: true });
    register('startAtHour', { required: true });
    register('endAtDate', { required: true });
    register('endAtHour', { required: true });
    register('timezone');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register]);

  useEffect(() => {
    if (item) {
      reset(item);
      setTimeout(() => {
        const startAt = formatDateApi(item.startAt, 'YYYY-MM-DD HH:mm:ss Z', item.timezone);
        setValue('startAtHour', startAt);
        setValue('startAtDate', startAt);
        setValue('endAtDate', formatDateApi(item.endAt, 'YYYY-MM-DD HH:mm:ss Z', item.timezone));
        setValue('endAtHour', formatDateApi(item.endAt, 'YYYY-MM-DD HH:mm:ss Z', item.timezone));
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  return {
    register,
    setValue,
    watch,
    reset,
    errors,
    onSubmit,
    handleSubmit,
    loading,
  };
};

export default useFormAnnouncements;
