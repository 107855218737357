import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import Grid from 'core/ui/Grid';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import { ButtonPrimary } from 'core/ui/Button';
import Typography, { fontStyle } from 'core/ui/Typography';
import { FormControlLabel, Radio } from '@material-ui/core';

import useFormLocations from './form';

const FormDataLocations = ({ itemEdit, callback, mode }) => {
  const { t } = useTranslation();
  const { setValue, watch, handleSubmit, onSubmit, errors, loading } = useFormLocations({
    mode,
    itemEdit,
    callback,
  });

  return (
    <>
      <Box>
        <Grid mb={3} container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextFieldRounded
              value={watch('name') || ''}
              onChange={(v) => setValue('name', v)}
              placeholder={t('form.locations.label.name')}
              error={errors.name}
              label={t('form.locations.label.name').toUpperCase()}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextFieldRounded
              value={watch('street1') || ''}
              onChange={(v) => setValue('street1', v)}
              placeholder={t('form.locations.label.street1')}
              error={errors.street1}
              label={t('form.locations.label.street1').toUpperCase()}
              required
            />
          </Grid>
        </Grid>
        <Box mb={3}>
          <TextFieldRounded
            value={watch('street2') || ''}
            onChange={(v) => setValue('street2', v)}
            placeholder={t('form.locations.label.street2')}
            error={errors.street2}
            label={t('form.locations.label.street2').toUpperCase()}
          />
        </Box>
        <Grid container spacing={3} mb={3}>
          <Grid item xs={12} md={5}>
            <TextFieldRounded
              value={watch('city') || ''}
              onChange={(v) => setValue('city', v)}
              placeholder={t('form.locations.label.city')}
              error={errors.city}
              label={t('form.locations.label.city').toUpperCase()}
              required
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextFieldRounded
              value={watch('state') || ''}
              onChange={(v) => setValue('state', v)}
              placeholder={t('form.locations.label.state')}
              error={errors.state}
              label={t('form.locations.label.state').toUpperCase()}
              required
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextFieldRounded
              value={watch('zip') || ''}
              onChange={(v) => setValue('zip', v)}
              placeholder={t('form.locations.label.zip')}
              error={errors.zip}
              label={t('form.locations.label.zip').toUpperCase()}
              required
            />
          </Grid>
        </Grid>

        <Box mb={1} ml={2}>
          <Typography style={fontStyle.label} variant="body1">
            {t('form.locations.label.active').toUpperCase()}
          </Typography>
          <Box display="flex">
            <FormControlLabel
              onClick={() => setValue('active', true)}
              control={<Radio color="primary" size="small" checked={!!watch('active')} />}
              label="Yes"
            />
            <FormControlLabel
              onClick={() => setValue('active', false)}
              control={<Radio color="primary" size="small" checked={!watch('active')} />}
              label="No"
            />
          </Box>
        </Box>
      </Box>

      <Box mt={3} display="flex" justifyContent="flex-end">
        <ButtonPrimary disabled={loading} onClick={handleSubmit(onSubmit)}>
          {t(itemEdit && itemEdit.id ? 'button.editLocation' : 'button.addLocation')}
        </ButtonPrimary>
      </Box>
    </>
  );
};

FormDataLocations.defaultProps = {
  callback: () => {},
  itemEdit: null,
  mode: 'new',
};

FormDataLocations.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  callback: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  itemEdit: PropTypes.object,
  mode: PropTypes.string,
};

export default FormDataLocations;
