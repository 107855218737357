import { PaymentMethodViewType } from 'components/Account/Selector';
import { validateEmail } from 'core/utils/helpers';
import { competitionRegistrationEmitter } from 'emitters';
import Modules from 'modules';
import { competitionsActions, competitionsSelectors } from 'modules/competitions';

import useActions from 'modules/map/useActions';
import notifications from 'modules/notifications';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useSelectors from 'modules/map/useSelectors';
import { competitionsUsersModule } from 'modules/competitionsUsers';
import { sessionSelectors } from 'modules/session';

export const registrationSteps = { chooseType: 'chooseType', finish: 'finish', success: 'success' };

const useCompRegistration = ({ close } = {}) => {
  const [step, setStep] = useState('chooseType');
  const [registerTypes, setRegisterTypes] = useState({});
  const currentUser = useSelector((state) => sessionSelectors.getUser(state));
  const loadingRegistration = useSelectors(competitionsUsersModule, 'loading');
  const [registrationData, setRegistrationData] = useState({});
  const [invites, setInvites] = useState([]);
  const [coupon, setCoupon] = useState(null);
  const [skipPayment, setSkipPayment] = useState(false);
  const [resume, setResume] = useState(null);
  const [viewMethodPayment, setViewMethodPayment] = useState(PaymentMethodViewType.list);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const competition = useSelector((state) => competitionsSelectors.getCompetition(state));
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { request } = useActions();

  const isFree = resume?.total === 0;

  const registerAsCompetitor = competition?.hasDivisions
    ? registerTypes?.divisions?.length > 0
    : !!registerTypes?.regType;

  const isTeam =
    registerAsCompetitor &&
    (registerTypes?.regType === 'team' ||
      !!registerTypes?.divisions?.find((x) => x.eventType === 'team'));

  const validate = () => {
    if (!skipPayment && !selectedPaymentMethod && !isFree) {
      dispatch(notifications.warning(t('modalpurchase.validation.selectAccount')));
      return false;
    }
    if (isTeam && !registrationData.teamName) {
      dispatch(notifications.warning(t('competition.registration.validation.teamName')));
      return false;
    }
    if (isTeam && !registrationData.countryId) {
      dispatch(notifications.warning(t('competition.registration.validation.country')));
      return false;
    }

    let errorChild = false;
    invites.forEach((invite) => {
      if (invite.child && !invite.childName) {
        errorChild = false;
      }
    });

    if (errorChild) {
      notifications.warning('Child name is required.');

      return false;
    }

    let emailInvalid = '';
    invites.forEach((invite) => {
      if (invite.email && !validateEmail(invite.email)) {
        emailInvalid = invite.email;
      }
    });

    if (emailInvalid !== '') {
      dispatch(
        notifications.warning(`${emailInvalid} ${t('competition.registration.validation.email')}`),
      );
      return false;
    }

    if (registerAsCompetitor && !competition?.isCoach && invites.length === 0) {
      dispatch(notifications.warning(t('competition.registration.validation.captain')));
      return false;
    }
    return true;
  };

  const confirmRegistration = () => {
    if (!validate()) {
      return;
    }

    // eslint-disable-next-line prefer-const
    let { teamName, location, countryId, tags } = registrationData;
    let affiliateId = null;
    if (competition.isCoach) {
      if (!affiliateId && Number(registrationData.affiliateId) > 0) {
        affiliateId = registrationData.affiliateId;
      }
    } else {
      affiliateId =
        competition.registeredAsAffiliate && competition.affiliateGym
          ? competition.affiliateGym.id
          : null;
    }

    request({
      action: Modules.competitionsUsers.actions.create,
      data: {
        competitionId: competition.id,
        invites,
        skipPayment,
        couponCode: coupon?.code,
        competitionAffiliateId: affiliateId,
        team: teamName,
        company: registrationData.company,
        donationAmount: registrationData.donationAmount || 0,
        customFields: registrationData?.customFields || [],
        child: registrationData?.child,
        childName: registrationData?.childName,
        eventType: isTeam ? 'team' : 'individual',
        registerTypes,
        tags:
          tags &&
          tags.map((x) => ({
            name: x.name ? x.name : x,
          })),
        countryId: countryId || 16,
        location,
        userPaymentMethodId: selectedPaymentMethod ? selectedPaymentMethod.id : null,
      },
      options: {
        onSuccess: () => {
          // close();
          setStep(registrationSteps.success);
          dispatch(competitionsActions.getCompetitionStart(competition.id));
          if (affiliateId) {
            dispatch(notifications.success(t('competition.registration.affiliate.teams.success')));
          } else {
            dispatch(notifications.success(t('competition.registration.success')));
          }
        },
        onError: (error) => {
          dispatch(notifications.error(error));
        },
      },
    });
  };

  const confirmWaitlistRegistration = (competitionDivisionId = null) => {
    if (competition?.waitlisted) {
      dispatch(notifications.warning('You already are in waitlist'));
      return;
    }
    request({
      action: Modules.competitionsWaitlists.actions.registerWaitlist,
      data: {
        competitionId: competition?.id,
        userId: currentUser?.id,
        competitionDivisionId,
      },
      options: {
        onSuccess: () => {
          dispatch(notifications.success('You have been registered for a waitlist.'));
          dispatch(competitionsActions.getCompetitionStart(competition.id));
          if (close) {
            close();
          }
        },
      },
    });
  };

  const calcCompetitionRegistrationPrice = () => {
    request({
      action: Modules.newCompetitions.actions.getRegistrationPrice,
      data: {
        competitionId: competition?.id,
        donationAmount: registrationData.donationAmount || 0,
        registerTypes,
        couponCode: coupon?.code,
        invites,
      },
      options: {
        onSuccess: (data) => {
          setResume(data);
        },
      },
    });
  };

  useEffect(() => {
    competitionRegistrationEmitter.on('updatePrice', () => {
      calcCompetitionRegistrationPrice();
    });

    return () => {
      competitionRegistrationEmitter.off('updatePrice');
    };
  }, []);

  useEffect(() => {
    if (step === registrationSteps.finish) {
      calcCompetitionRegistrationPrice();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step, registerTypes, registrationData?.donationAmount, coupon, invites]);

  return {
    registerTypes,
    setRegisterTypes,
    step,
    setStep,
    registrationData,
    setRegistrationData,
    coupon,
    setCoupon,
    invites,
    setInvites,
    viewMethodPayment,
    setViewMethodPayment,
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    isFree,
    competition,
    confirmRegistration,
    confirmWaitlistRegistration,
    resume,
    isTeam,
    registerAsCompetitor,

    loadingRegistration,
    skipPayment,
    setSkipPayment,
  };
};

export default useCompRegistration;
