/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Paper from 'core/ui/Paper';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import Box from 'core/ui/Box';
import FormOptionYesNo from './FormOptionYesNo';

const FormLimitPurchases = ({ currentPlan, setCurrentPlan }) => {
  const { t } = useTranslation();

  return (
    <Paper className="paper-rounded" p={3} mt={2}>
      <FormOptionYesNo
        t={t}
        currentPlan={currentPlan}
        setCurrentPlan={setCurrentPlan}
        label={t('channel.memberships.limitPurchases').toUpperCase()}
        prop="limitPurchases"
      />

      {currentPlan?.limitPurchases && (
        <Box>
          <TextFieldRounded
            type="number"
            value={currentPlan.limitPurchasesMax || ''}
            pattern="[0-9]*"
            placeholder={t('channel.memberships.limitPurchases.max')}
            label={t('channel.memberships.limitPurchases.max').toUpperCase()}
            onChange={(v) =>
              setCurrentPlan((prev) => ({ ...prev, limitPurchasesMax: v > 0 ? v : 0 }))
            }
            required={!currentPlan.renewable}
            bordered
          />
        </Box>
      )}
    </Paper>
  );
};

FormLimitPurchases.defaultProps = {
  currentPlan: {},
  setCurrentPlan: () => {},
};
FormLimitPurchases.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  currentPlan: PropTypes.object,
  setCurrentPlan: PropTypes.func,
};

export default FormLimitPurchases;
