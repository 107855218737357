import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

import { omit } from 'core/utils/fp';

import * as sagas from './sagas';
import * as selectors from './selectors';

const AUTO_DISMISS = 5;

/* ---------- Action Creators and Types ---------- */
const { Types, Creators } = createActions(
  {
    add: ['message', 'level', 'autoDismiss'],
    dismiss: [],
  },
  { prefix: 'NOTIFICATIONS/' },
);

/* ---------- Initial State ---------- */
const INITIAL_STATE = Immutable([]);

/* ---------- Reducers ---------- */
const addReducer = (state, action) => state.concat([omit(['type'], action)]);
const dismissReducer = () => INITIAL_STATE;

/* ---------- Main Reducer ---------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.ADD]: addReducer,
  [Types.DISMISS]: dismissReducer,
});

/* ---------- Action Helpers ---------- */
const error = (message) => Creators.add(message, 'error', AUTO_DISMISS);
const notice = (message) => Creators.add(message, 'info', AUTO_DISMISS);
const warning = (message) => Creators.add(message, 'warning', AUTO_DISMISS);
const success = (message) => Creators.add(message, 'success', AUTO_DISMISS);

export const notificationsActions = Creators;
export const notificationsTypes = Types;
export const notificationsSelectors = selectors;
export const notificationsSagas = sagas;

export default { error, notice, warning, success };
