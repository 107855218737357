import React, { useState } from 'react';
import Box from 'core/ui/Box';
import Paper from 'core/ui/Paper';
import Typography from 'core/ui/Typography';
import Toggle from 'core/ui/Toggle';
import { Collapse } from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowRight } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

export default ({ messagesTypes, toggleType, localSettings, setLocalSettings }) => {
  const { t } = useTranslation();
  const [viewTypes, setViewTypes] = useState({ email: true });
  return (
    <Box px={3}>
      <Box className="paper-rounded" mb={3}>
        {['email', 'sms', 'push']
          ?.filter((x) => messagesTypes[x]?.length > 0)
          ?.map((msgType) => (
            <Box key={msgType}>
              <Paper p={3} className="hover" mb={3}>
                <Box
                  display="flex"
                  alignItems="center"
                  onClick={() =>
                    setViewTypes((prev) => ({ ...prev, [msgType]: !viewTypes[msgType] }))
                  }
                >
                  <Box flexGrow={1}>
                    <Typography variant="h4">{t(`message.settings.${msgType}`)}</Typography>
                  </Box>
                  {viewTypes[msgType] ? (
                    <KeyboardArrowDown style={{}} />
                  ) : (
                    <KeyboardArrowRight style={{}} />
                  )}
                </Box>

                <Collapse in={viewTypes[msgType]}>
                  <Box mt={3}>
                    {messagesTypes[msgType]?.map((message) => (
                      <Paper key={message?.id} m={1} p={3} className="hover">
                        <Box display="flex" alignItems="center">
                          <Box flexGrow={1}>
                            <Typography>{message?.groupType || message?.name}</Typography>
                          </Box>
                          <Toggle
                            options={[
                              { value: true, width: 50, label: 'Off' },
                              { value: false, width: 50, label: 'On' },
                            ]}
                            value={!!localSettings[message.id]}
                            color="white"
                            onChange={() => {
                              toggleType(message, !localSettings[message.id]);
                              setLocalSettings((prev) => ({
                                ...prev,
                                [message.id]: !localSettings[message.id],
                              }));
                            }}
                          />
                        </Box>
                      </Paper>
                    ))}
                  </Box>
                </Collapse>
              </Paper>
            </Box>
          ))}
      </Box>
    </Box>
  );
};
