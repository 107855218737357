import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import Box from 'core/ui/Box';
import Texture from 'core/ui/Texture';
import Typography from 'core/ui/Typography';
import { ButtonPrimarySm } from 'core/ui/Button';
import { useTranslation } from 'react-i18next';
import { Check, Close } from '@material-ui/icons';
import { redColor, successColor } from 'core/ui/Colors';
import { currency, date } from 'core/utils/formatters';
import SFDataTable from 'core/ui/DataTable';
import ModalPartnerProgramChannel from './Modal';

const ModalChannels = ({ partner, open, close, partnersProgram, refreshPrograms }) => {
  const { t } = useTranslation();
  const [modalForm, setModalForm] = useState({ open: false });

  const columns = [
    {
      name: 'Gym',
      selector: (row) => row?.channel?.name,
      sortable: true,
      cell: (row) => <Box>{row?.channel?.name}</Box>,
    },
    {
      name: 'Contact',
      selector: (row) => row?.channel?.contactEmail,
      sortable: true,
      cell: (row) => <Box>{row?.channel?.contactEmail}</Box>,
    },
    {
      name: 'Renewable',
      selector: 'renewable',
      sortable: true,
      cell: (row) => (
        <Typography>
          {row?.renewable ? (
            <Check style={{ color: successColor }} />
          ) : (
            <Close style={{ color: redColor }} />
          )}
        </Typography>
      ),
    },
    {
      name: 'Renewal',
      selector: 'annualRenwal',
      sortable: true,
      cell: (row) => <Typography>{row?.annualRenewal ? 'Annual' : 'Monthly'}</Typography>,
    },
    {
      name: 'Renew Date',
      selector: 'expiryAt',
      sortable: true,
      cell: (row) => (
        <Typography style={{ color: row?.expired ? redColor : successColor }}>
          {date(row?.expiryAt, partner?.dateFormatReact)}
        </Typography>
      ),
    },
    {
      name: 'Price',
      selector: 'renewPrice',
      sortable: true,
      cell: (row) => (
        <Typography style={{ color: row?.expired ? redColor : successColor }}>
          {currency(row?.renewPrice || partnersProgram?.cost)}
        </Typography>
      ),
    },
    {
      sortable: false,
      cell: (row) => (
        <ButtonPrimarySm onClick={() => setModalForm({ open: true, partnersProgramsChannel: row })}>
          {t('button.change')}
        </ButtonPrimarySm>
      ),
    },
  ];

  return (
    <Dialog open={open} onClose={close} maxWidth="lg" fullWidth scroll="body">
      <Texture>
        <ModalPartnerProgramChannel
          open={modalForm?.open}
          close={() => setModalForm({ open: false })}
          partnersProgramsChannel={modalForm?.partnersProgramsChannel}
          partnersProgram={partnersProgram}
          callbackSave={() => refreshPrograms()}
        />
        <Box p={5}>
          <Box mb={3} display="flex" justifyContent="flex-end">
            <ButtonPrimarySm onClick={() => setModalForm({ open: true })}>
              {t('button.create')}
            </ButtonPrimarySm>
          </Box>

          <SFDataTable
            noDataComponent={null}
            columns={columns}
            data={partnersProgram?.partnersProgramsChannels}
            noHeader
            pagination
            showExport
            exportData={partnersProgram?.partnersProgramsChannels?.map((item) => ({
              gym: item?.channel?.name,
              contact: item?.channel?.contactEmail,
              renewable: item?.renewable ? 'YES' : 'NO',
              renewal: item?.annualRenewal ? 'Annual' : 'Monthly',
              renewDate: date(item?.expiryAt, partner?.dateFormatReact),
              renewPrice: currency(item?.renewPrice || partnersProgram?.cost),
            }))}
          />
        </Box>
      </Texture>
    </Dialog>
  );
};

ModalChannels.propTypes = {
  close: PropTypes.func,
};

ModalChannels.defaultProps = {
  close: () => {},
};

export default ModalChannels;
