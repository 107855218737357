/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import { primary, redColor, warningColor } from 'core/ui/Colors';

const colors = { create: primary, update: warningColor, destroy: redColor };

const removeColumns = ['tokens', 'uid'];

const AuditedChanges = ({ audit }) => (
  <div>
    <strong className="tw-uppercase" style={{ color: colors[audit.action] }}>
      {audit.action}
    </strong>

    {(audit.action === 'create' || audit.action === 'destroy') &&
      Object.entries(audit.auditedChanges)
        .filter(([k, v]) => !!v && !removeColumns.find((column) => column === k))
        .map(([key, value]) => (
          <p key={key} className="tw-text-xs">
            <strong className="tw-uppercase">{key}: </strong>

            <span className="tw-text-green-500">{JSON.stringify(value)}</span>
          </p>
        ))}

    {audit.action === 'update' &&
      Object.entries(audit.auditedChanges)
        .filter(([k]) => !removeColumns.find((column) => column === k))
        .map(([key, value]) => (
          <p key={key} className="tw-text-xs">
            <strong className="tw-uppercase">{key}: </strong>
            <span className="tw-text-red-500">{JSON.stringify(value[0]) || 'null'}</span>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <span className="tw-text-green-500">{JSON.stringify(value[1]) || 'null'}</span>
          </p>
        ))}
  </div>
);

export default AuditedChanges;
