import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import Typography, { fontStyle } from 'core/ui/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { channelMemberNotesActions, channelMemberNotesSelectors } from 'modules/channelMemberNotes';
import Paper from 'core/ui/Paper';
import Grid from 'core/ui/Grid';
import { Dialog, IconButton } from '@material-ui/core';
import Texture from 'core/ui/Texture';
import TextFieldRounded from 'core/ui/TextAreaFieldRounded';
import { useForm } from 'react-hook-form-v5';
import { Edit, RemoveCircle } from '@material-ui/icons';
import { ButtonPrimary } from 'core/ui/Button';
import ConfirmDialog from 'core/ui/ConfirmDialog';
import { dateExt } from 'core/utils/formatters';

export default function MemberNotes({ memberId, channelId, modalNote, setModalNote }) {
  const { t } = useTranslation();
  const loading = useSelector((state) => channelMemberNotesSelectors.getLoading(state));
  const memberNotes = useSelector((state) =>
    channelMemberNotesSelectors.getChannelMemberNotes(state),
  );
  const dispatch = useDispatch();
  const { setValue, watch, errors, register, handleSubmit } = useForm();
  const [confirmDelete, setConfirmDelete] = useState({ open: false });

  useEffect(() => {
    register('note', { required: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    dispatch(channelMemberNotesActions.getChannelMemberNotesStart(channelId, memberId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const deleteNote = (id) => {
    setConfirmDelete({
      open: true,
      confirm: () => {
        setConfirmDelete({ open: false });
        dispatch(
          channelMemberNotesActions.deleteChannelMemberNotesStart(channelId, id, () => {
            setModalNote((prev) => ({ ...prev, noteId: null, open: false }));
            dispatch(channelMemberNotesActions.getChannelMemberNotesStart(channelId, memberId));
          }),
        );
      },
    });
  };

  const saveNote = (data) => {
    if (modalNote?.noteId) {
      dispatch(
        channelMemberNotesActions.putChannelMemberNotesStart(
          { id: modalNote?.noteId, channelId, note: data.note },
          () => {
            setValue('note', '');
            setModalNote((prev) => ({ ...prev, noteId: null, open: false }));
            dispatch(channelMemberNotesActions.getChannelMemberNotesStart(channelId, memberId));
          },
        ),
      );
    } else {
      dispatch(
        channelMemberNotesActions.postChannelMemberNotesStart(
          { note: data.note, channelId, memberId },
          () => {
            setValue('note', '');
            setModalNote((prev) => ({ ...prev, noteId: null, open: false }));
            dispatch(channelMemberNotesActions.getChannelMemberNotesStart(channelId, memberId));
          },
        ),
      );
    }
  };

  const isViewMode = !setModalNote;

  return (
    <Box>
      <ConfirmDialog
        open={confirmDelete.open}
        confirm={() => confirmDelete.confirm()}
        cancel={() => setConfirmDelete({ open: false })}
        description={t('confirm.delete.memberNote')}
        labelConfirm={t('button.confirm')}
        labelCancel={t('button.cancel')}
      />
      {memberNotes?.length > 0 && (
        <Box display="flex" alignItems="center">
          <Box mr={5}>
            <Typography variant="h6" mb={2}>
              {t('channel.member.notes')}
            </Typography>
          </Box>
        </Box>
      )}
      <Grid container>
        {memberNotes &&
          memberNotes.map((note, index) => (
            <Grid xs={12} md={3} lg={2} item key={index.toString()}>
              <Paper className="paper-rounded" p={3} m={1}>
                <Box display="flex">
                  <Box flexGrow={1}>
                    <Typography color="textSecondary" style={fontStyle.xSmall} variant="caption">
                      {dateExt(note.createdAt)}
                    </Typography>
                    <Typography color="primary" mt={-1} variant="caption" component="p">
                      {note.coach.name}
                    </Typography>
                  </Box>

                  <Box>
                    {!isViewMode && (
                      <Box display="flex" className="gap-5">
                        <IconButton
                          onClick={() => {
                            setValue('note', note.note);
                            setModalNote((prev) => ({ ...prev, noteId: note.id, open: true }));
                          }}
                        >
                          <Edit style={{ fontSize: 15 }} />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            deleteNote(note.id);
                          }}
                        >
                          <RemoveCircle style={{ fontSize: 15 }} />
                        </IconButton>
                      </Box>
                    )}
                  </Box>
                </Box>
                <Typography style={{ ...fontStyle.small }}>{note.note}</Typography>
              </Paper>
            </Grid>
          ))}
      </Grid>
      {!isViewMode && (
        <Dialog
          open={modalNote?.open}
          fullWidth
          maxWidth="xs"
          onClose={() => setModalNote(() => ({ open: false }))}
        >
          <Texture>
            <Box p={5}>
              <Typography mb={5} variant="h4" align="center">
                {t('channel.member.note')}
              </Typography>
              <Box mb={3}>
                <TextFieldRounded
                  value={watch('note') || ''}
                  onChange={(v) => setValue('note', v)}
                  error={errors.note}
                />
              </Box>
              <ButtonPrimary fullWidth disabled={loading} onClick={() => handleSubmit(saveNote)()}>
                {t('button.save')}
              </ButtonPrimary>
            </Box>
          </Texture>
        </Dialog>
      )}
    </Box>
  );
}

MemberNotes.propTypes = {
  channelId: propTypes.number.isRequired,
  memberId: propTypes.number.isRequired,
};

MemberNotes.defaultProps = {};
