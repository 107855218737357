import React from 'react';
import PropTypes from 'prop-types';
import Box from 'core/ui/Box';
import { ButtonPrimary } from 'core/ui/Button';
import LoaderSm from 'core/ui/LoaderSm';
import { useTranslation } from 'react-i18next';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import Checkbox from 'core/ui/Checkbox';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import Typography from 'core/ui/Typography';
import useFormPartnerProgram from './useForm';

const FormPartnerProgram = ({ programs, partnerId, program, close, callbackSave }) => {
  const { t } = useTranslation();
  const { loading, errors, setValue, watch, submit } = useFormPartnerProgram({
    partnerId,
    callbackSave,
    close,
    program,
  });

  return (
    <Box flexGrow={1}>
      <Box mb={4}>
        <TextFieldRounded
          placeholder={t('form.partner.program.name')}
          label={t('form.partner.program.name').toUpperCase()}
          value={watch('name')}
          onChange={(v) => setValue('name', v)}
          error={errors?.name}
        />
      </Box>
      {(!program?.id || (!!program?.parentProgram?.id && !program?.hasWorkouts)) &&
        programs?.length > 0 && (
          <Box mb={4}>
            <SelectBoxObject
              options={programs}
              label={t('form.partner.program.parent').toUpperCase()}
              propLabel="name"
              propValue="id"
              value={watch('parentProgramId')}
              setValue={(v) => setValue('parentProgramId', v)}
            />

            {watch('parentProgramId') &&
              programs.find(
                (x) => x.id === Number(watch('parentProgramId')) && !!x.hasWorkouts,
              ) && (
                <Typography color="primary" variant="caption">
                  {t('form.partner.program.transferWorkouts')}
                </Typography>
              )}
          </Box>
        )}
      {!watch('parentProgramId') && (
        <>
          <Box mb={4}>
            <TextFieldRounded
              placeholder={t('form.partner.program.cost')}
              label={t('form.partner.program.cost').toUpperCase()}
              value={watch('cost')}
              onChange={(v) => setValue('cost', v)}
              error={errors?.cost}
              type="number"
              pattern="[1-9]*"
            />
          </Box>
          <Checkbox
            checked={watch('allowAnnualCost')}
            label={t('form.partner.allowAnnualCost')}
            onClick={() => setValue('allowAnnualCost', !watch('allowAnnualCost'))}
          />
          {watch('allowAnnualCost') && (
            <Box my={4}>
              <TextFieldRounded
                placeholder={t('form.partner.program.annualCost')}
                label={t('form.partner.program.annualCost').toUpperCase()}
                value={watch('annualCost')}
                onChange={(v) => setValue('annualCost', v)}
                error={errors?.cost}
                type="number"
                pattern="[1-9]*"
              />
            </Box>
          )}
        </>
      )}
      <Checkbox
        checked={watch('active')}
        label={t('form.partner.active')}
        onClick={() => setValue('active', !watch('active'))}
      />

      <Box mb={5} display="flex" justifyContent="flex-end">
        <ButtonPrimary type="button" onClick={() => submit()} size="small" disabled={loading}>
          <LoaderSm loading={loading} width={20} />
          {t('button.save')}
        </ButtonPrimary>
      </Box>
    </Box>
  );
};

FormPartnerProgram.propTypes = {
  close: PropTypes.func.isRequired,
};

FormPartnerProgram.defaultProps = {};

export default FormPartnerProgram;
