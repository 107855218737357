/* eslint-disable react/prop-types */
import React from 'react';

import { Fade } from '@material-ui/core';
import ReactGA from 'react-ga';

const BannerCompetition = ({ competition }) => {
  const currentImageBanner = competition?.banner?.images[0];

  return (
    <Fade in key={currentImageBanner?.image?.image?.url} timeout={1500}>
      <ReactGA.OutboundLink
        eventLabel={`${competition?.name}`}
        to={currentImageBanner?.url}
        target="_blank"
      >
        {currentImageBanner?.image?.image?.url && (
          <img
            src={currentImageBanner?.image?.image?.url}
            alt="Banner"
            style={{
              width: '100%',
              borderRadius: 10,
            }}
            className="shadow-bg-header"
          />
        )}
      </ReactGA.OutboundLink>
    </Fade>
  );
};

export default BannerCompetition;
