import React, { useEffect, useState } from 'react';
import { ButtonPrimary } from 'core/ui/Button';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import Typography from 'core/ui/Typography';

import {
  TableContainer,
  TableHead,
  Table,
  TableCell,
  TableRow,
  TableBody,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { Add, GetApp, Reorder, Search } from '@material-ui/icons';
import LoaderSm from 'core/ui/LoaderSm';
import Paper from 'core/ui/Paper';
import { Pagination } from '@material-ui/lab';
import Modules from 'modules';
import useActions from 'modules/map/useActions';
import notifications from 'modules/notifications';
import ConfirmDialog from 'core/ui/ConfirmDialog';
import Toggle from 'core/ui/Toggle';
import useSelectors from 'modules/map/useSelectors';
import { storeShopifyModule } from 'modules/storeShopify';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import { storeProductsModule } from 'modules/storeProducts';
import ModalQrCodeDownload from 'core/ui/ModalQrCodeDownload';
import ModalFormProduct from './Form/Modal';
import ItemProduct from './ItemProduct';
import ModalReorder from './ModalReorder.js';

const StoreProducts = ({
  currency,
  ownerableId,
  ownerableType,
  taxPercent,
  channelsTaxes,
  customerAmounts,
  setSelectProduct,
  channelId,
  copyLink,
  hasShopifyIntegration,
  refreshProducts,
  inventoryActions,
  hideActions,
  marketPlaceView,
  allowChangeAmounts,
}) => {
  const { t } = useTranslation();
  const { request } = useActions();
  const loading = useSelectors(storeProductsModule, 'loading');

  const loadingPull = useSelectors(storeShopifyModule, 'loadingPull');
  const [modalConfirm, setModalConfirm] = useState({ open: false });
  const [modalReorder, setModalReorder] = useState({ open: false });
  const [data, setData] = useState({
    storeProducts: [],
    pagination: null,
    loading: false,
  });
  const [filter, setFilter] = useState('channel');
  const [query, setQuery] = useState('');
  const [modalQr, setModalQr] = useState();

  const isSelector = !!setSelectProduct;

  const [modal, setModal] = useState({
    open: false,
  });

  const dispatch = useDispatch();
  const perPage = 30;

  const getProducts = (page = 1) => {
    setData((prev) => ({ ...prev, loading: true }));
    request({
      action: Modules.storeProducts.actions.getStoreProducts,
      data: {
        ownerableId,
        ownerableType,
        page,
        perPage,
        showInactive: true,
        categories: [],
        channelId,
        query,
        onlyChannelProducts: ownerableType === 'Channel' && filter === 'channel',
        onlyChannelPartnersProducts: ownerableType === 'Channel' && filter === 'partner',
      },
      options: {
        onSuccess: (respData, pagination) => {
          setData({ loading: false, storeProducts: respData, pagination });
        },
      },
    });
  };

  useEffect(() => {
    if (ownerableId) {
      getProducts(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, ownerableId, refreshProducts, filter]);

  const openModal = () => {
    setModal((prev) => ({ ...prev, mode: 'new', open: true }));
  };

  const onEditClick = (item) => {
    setModal((prev) => ({ ...prev, item, mode: 'edit', open: true }));
  };

  const pullInventory = () => {
    request({
      action: Modules.storeShopify.actions.pullInventory,
      data: {
        ownerableId,
        ownerableType,
      },
      options: {
        onSuccess: () => {
          dispatch(notifications.success(t('message.success.inventories.storeProducts')));
          getProducts(data?.pagination.currentPage);
        },
      },
    });
  };

  const removeItemChannel = (product) => {
    request({
      action: Modules.partners.actions.removeAssignProductChannel,
      data: {
        channelId,
        partnerId: product?.ownerableId,
        storeProductId: product?.id,
      },
      options: {
        onSuccess: () => {
          dispatch(notifications.success(t('message.success.remove.storeProducts')));
          getProducts(data?.pagination.currentPage);
        },
      },
    });
  };

  const onRemoveClick = (product) => {
    setModalConfirm({
      open: true,
      confirm: () => {
        request({
          action: Modules.storeProducts.actions.destroy,
          data: {
            ownerableId,
            ownerableType,
            storeProductId: product?.id,
          },
          options: {
            onSuccess: () => {
              getProducts(data?.pagination.currentPage);
            },
          },
        });
        setModalConfirm({ open: false });
      },
    });
  };

  const allowEdit = (product) => {
    if (channelId) {
      return Number(product?.ownerableId) === Number(channelId);
    }

    return true;
  };

  return (
    <Box>
      <ConfirmDialog
        open={modalConfirm.open}
        confirm={() => modalConfirm.confirm()}
        cancel={() => setModalConfirm({ open: false })}
        description={t('message.remove.product')}
        labelConfirm={t('button.confirm')}
        labelCancel={t('button.cancel')}
      />
      {modal.open && (
        <ModalFormProduct
          open={modal.open}
          item={modal.item}
          mode={modal.mode}
          currency={currency}
          ownerableId={ownerableId}
          ownerableType={ownerableType}
          close={() => setModal((prev) => ({ ...prev, open: false, item: {} }))}
          callback={(nameList) => {
            getProducts(data?.pagination?.currentPage);
            setModal((prev) => ({ ...prev, open: false, item: {}, nameListExpand: nameList }));
          }}
          taxPercent={taxPercent}
          channelsTaxes={channelsTaxes}
          customerAmounts={customerAmounts}
          hasShopifyIntegration={hasShopifyIntegration}
          allowChangeAmounts={allowChangeAmounts}
        />
      )}
      {modalReorder?.open && (
        <ModalReorder
          ownerableId={ownerableId}
          ownerableType={ownerableType}
          close={() => setModalReorder({ open: false })}
          open={modalReorder.open}
          refresh={() => getProducts(data?.pagination?.currentPage)}
        />
      )}
      <Paper p={3} className="paper-rounded">
        <Box display="flex" alignItems="center" mb={2}>
          <Box display="flex" alignItems="center">
            <Typography variant="h4" color="primary" mr={1}>
              {t('channel.store.products')}
            </Typography>
            {ownerableType !== 'Partner' && !copyLink && !setSelectProduct && (
              <Box m={1}>
                <Toggle
                  options={[
                    { value: 'channel', label: 'Gym' },
                    { value: 'partner', label: 'Partners' },
                  ]}
                  value={filter}
                  onChange={(v) => setFilter(v)}
                />
              </Box>
            )}
          </Box>
          <Box flexGrow={1} display="flex" alignItems="center" mx={2}>
            <TextFieldRounded
              placeholder={
                ownerableType !== 'Partner'
                  ? t('search.placeholder.products')
                  : t('search.placeholder.products.partner')
              }
              bordered
              value={query}
              onChange={(v) => setQuery(v)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  getProducts(1);
                }
              }}
            />
            <ButtonPrimary style={{ marginLeft: 10 }} onClick={() => getProducts(1)}>
              <Box display="flex" alignItems="center">
                {loading ? (
                  <LoaderSm loading={data?.loading} width={24} />
                ) : (
                  <Search size="small" />
                )}

                <Box alignItems="center">
                  <Typography component="span" mr={1}>
                    {t('button.search')}
                  </Typography>
                </Box>
              </Box>
            </ButtonPrimary>
          </Box>
          {inventoryActions}
          {ownerableType !== 'Partner' && (
            <Box mx={1}>
              <ButtonPrimary onClick={() => setModalReorder({ open: true })}>
                <Box display="flex" alignItems="flex-end">
                  <Reorder size="small" />
                  <Box alignItems="center">
                    <Typography component="span" mr={1}>
                      {t('button.reorder')}
                    </Typography>
                  </Box>
                </Box>
              </ButtonPrimary>
            </Box>
          )}
          {hasShopifyIntegration && (
            <Box mx={1}>
              <ButtonPrimary onClick={() => pullInventory()}>
                <Box display="flex" alignItems="flex-end">
                  <LoaderSm loading={loadingPull} />
                  <GetApp size="small" />
                  <Box alignItems="center">
                    <Typography component="span" mr={1}>
                      {t('button.pullInventory')}
                    </Typography>
                  </Box>
                </Box>
              </ButtonPrimary>
            </Box>
          )}
          {!copyLink && !setSelectProduct && (
            <Box>
              <ButtonPrimary onClick={() => openModal()}>
                <Box display="flex" alignItems="flex-end">
                  <Add size="small" />
                  <Box alignItems="center">
                    <Typography component="span" mr={1}>
                      {t('channel.button.addProduct')}
                    </Typography>
                  </Box>
                </Box>
              </ButtonPrimary>
            </Box>
          )}
        </Box>

        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell style={{ width: 50 }} />
                <TableCell>
                  <Typography>{t('channel.product.column.name')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{t('channel.product.column.copyLink')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{t('channel.product.column.category')}</Typography>
                </TableCell>

                <TableCell align="center">
                  <Typography>{t('channel.product.column.options')}</Typography>
                </TableCell>
                {!marketPlaceView && ownerableType === 'Partner' && (
                  <TableCell align="center">
                    <Typography>{t('label.gym')}</Typography>
                  </TableCell>
                )}

                {!marketPlaceView && (
                  <TableCell align="center">
                    <Typography>{t('channel.product.column.active')}</Typography>
                  </TableCell>
                )}
                <TableCell />
              </TableRow>
            </TableHead>

            <TableBody>
              {data?.storeProducts &&
                data?.storeProducts.map((row) => (
                  <ItemProduct
                    currency={currency}
                    onEditClick={!copyLink && allowEdit(row) ? onEditClick : null}
                    onRemoveClick={!copyLink && allowEdit(row) ? onRemoveClick : null}
                    removeItemChannel={row?.ownerableType === 'Partner' ? removeItemChannel : null}
                    setSelectProduct={
                      isSelector
                        ? () =>
                            setSelectProduct(row, () => {
                              getProducts(data?.pagination?.currentPage);
                            })
                        : null
                    }
                    isSelector={isSelector}
                    hideActions={hideActions}
                    key={row.id}
                    item={row}
                    expanded={modal.nameListExpand && modal.nameListExpand === row.name}
                    isPartner={ownerableType === 'Partner'}
                    marketPlaceView={marketPlaceView}
                    onQrCodeClick={(item) => setModalQr(item)}
                    channelId={channelId}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <ModalQrCodeDownload
          open={modalQr?.open}
          close={() => setModalQr({ opem: false })}
          text={modalQr?.text}
          title={modalQr?.title}
        />

        {data?.pagination && data?.pagination.totalPages > 1 && (
          <Box p={3} mt={3} display="flex" justifyContent="center">
            <Pagination
              onChange={(e, page) => getProducts(page)}
              count={data?.pagination.totalPages}
              page={data?.pagination.currentPage}
            />
            <LoaderSm loading={data?.loading} center />
          </Box>
        )}
      </Paper>
    </Box>
  );
};

export default StoreProducts;
