import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import { ButtonPrimary } from 'core/ui/Button';
import { useDispatch, useSelector } from 'react-redux';
import notifications from 'modules/notifications';
import Typography from 'core/ui/Typography';
import { workoutsActions, workoutsSelectors } from 'modules/workouts';
import LoaderSm from 'core/ui/LoaderSm';
import SelectorUser from 'components/Profile/SelectorUser';
import CardUser from 'components/Channel/SelectorChannelUser/Card';
import Paper from 'core/ui/Paper';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import Divider from 'core/ui/Divider';
import { workoutInviteTypes } from 'core/utils/consts';
import { sessionActions, sessionSelectors } from 'modules/session';
import { formatDateToApi } from 'core/utils/formatters';
import Checkbox from 'core/ui/Checkbox';
import useCloneWorkout from './useCloneWorkout';

export default function RecordCompare({ workout, callback, close, isOwner, goToWorkout }) {
  const { t } = useTranslation();
  const { clone: cloneWorkout } = useCloneWorkout();

  const currentUser = useSelector((state) => sessionSelectors.getUser(state));
  const loadingInvite = useSelector((state) =>
    workoutsSelectors.getLoadingWorkoutRecordingInvites(state),
  );
  const dispatch = useDispatch();
  const [section, setSection] = useState(null);
  const [users, setUsers] = useState([]);

  const [clone, setClone] = useState(false);

  const countInvitedRecord = workout?.invites?.filter(
    (x) => x.inviteType === workoutInviteTypes.record_compare,
  ).length;
  const hasOtherInvite = workout?.invites?.filter(
    (x) => x.inviteType !== workoutInviteTypes.record_compare,
  ).length;

  useEffect(() => {
    if (
      !hasOtherInvite &&
      isOwner &&
      workout.method === 'custom' &&
      countInvitedRecord < 3 &&
      workout?.sections?.length === 1
    ) {
      setClone(false);
    } else {
      setClone(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOwner]);

  const validate = () => {
    if (users?.length === 0) {
      dispatch(notifications.warning(t('workout.custom.invite.selectUser')));
      return false;
    }
    if (workout?.sections?.length > 1 && !section) {
      dispatch(notifications.warning(t('workout.custom.invite.selectOneSection')));
      return false;
    }

    if (workout?.sections?.length === 0) {
      dispatch(notifications.warning(t('workout.custom.invite.validate.section')));
      return false;
    }

    return true;
  };

  const createInvite = () => {
    if (!validate()) {
      return;
    }
    if (!clone) {
      const dataInvite = {
        workoutId: workout?.id,
        scheduledAt: formatDateToApi(new Date()),
        invites: users.map((x) => ({ userId: x.id })),
        inviteType: workoutInviteTypes.record_compare,
        mainUserId: currentUser?.id,
        section,
      };
      dispatch(
        workoutsActions.postWorkoutRecordingInvitesStart(dataInvite, () => {
          if (callback) {
            callback();
          }
          goToWorkout(workout);
          dispatch(notifications.success(t('workout.invite.recording.success')));
        }),
      );
    } else {
      const dataInvite = {
        workoutId: workout?.id,
        users,
        inviteType: workoutInviteTypes.record_compare,
        scheduledAt: formatDateToApi(new Date()),
        mainUserId: currentUser?.id,
        section,
      };
      cloneWorkout({
        workout,
        dataInvite,
        callback: (workoutResp) => {
          dispatch(sessionActions.authenticateStart());
          callback(workoutResp);
          goToWorkout(workoutResp);
          close();
        },
      });
    }
  };

  const allUsers = () => {
    let invitedUsers = [];
    invitedUsers = [currentUser, ...users];
    if (!clone && countInvitedRecord > 0) {
      invitedUsers = [
        ...invitedUsers,
        ...workout?.invites.map((x) => ({ ...x.user, invited: true })),
      ];
    }
    return invitedUsers;
  };

  const inviteAndNewUsers = allUsers();

  return (
    <div>
      <Typography mb={3} variant="h5" align="center" color="primary">
        {t('button.recordAndCompare')}
      </Typography>

      {workout.sections?.length > 1 && (
        <Paper p={3}>
          <Typography mb={3} variant="body2" align="center" color="secondary">
            {t('recordCompare.choose.sections')}
          </Typography>
          {workout.sections
            .map((x) => x.detail || x)
            .map((sec, index) => (
              <Box key={sec.id}>
                <Box display="flex" alignItems="center" p={2}>
                  <Box>
                    <Checkbox checked={section?.id === sec.id} onClick={() => setSection(sec)} />
                  </Box>

                  <Typography ml={2} align="center" variant="h6" color="secondary">
                    {sec.title}
                  </Typography>
                </Box>
                {workout.sections?.length - 1 > index && <Divider my={1} />}
              </Box>
            ))}
        </Paper>
      )}

      <Box mt={3}>
        <Typography align="center" variant="h6" mb={3} color="secondary">
          {t('workout.custom.invite.users').toUpperCase()}&nbsp;{inviteAndNewUsers.length}/4
        </Typography>

        <Paper p={3} elevation={4} className="paper-rounded">
          {users &&
            inviteAndNewUsers.map((user, index) => (
              <Box key={user?.id} style={{ position: 'relative' }}>
                <Box display="flex" flexWrap="wrap">
                  {user?.id !== currentUser?.id && !user.invited && (
                    <IconButton
                      style={{ position: 'absolute', right: 0, top: 0 }}
                      onClick={() => setUsers((prev) => prev.filter((y) => y !== user))}
                    >
                      <Close />
                    </IconButton>
                  )}
                  <CardUser user={user} />
                </Box>
                {inviteAndNewUsers.length - 1 > index && <Divider my={3} />}
              </Box>
            ))}
        </Paper>
      </Box>

      {inviteAndNewUsers.length < 4 && (
        <Paper p={3} mt={3}>
          <Typography align="center" variant="h6" color="secondary">
            {t('select.user.message').toUpperCase()}
          </Typography>
          <SelectorUser onSelectedUser={(u) => setUsers((prev) => [...prev, u])} hideTexture />
        </Paper>
      )}

      {!clone && countInvitedRecord >= 3 ? (
        <Paper p={3} mt={3}>
          <Typography align="center" mb={3} variant="h6" color="secondary">
            {t('workout.recordCompare.full').toUpperCase()}
          </Typography>
          <ButtonPrimary disabled={loadingInvite} onClick={() => setClone(true)} fullWidth>
            <LoaderSm loading={loadingInvite} />
            {t('button.cloneWorkout')}
          </ButtonPrimary>
        </Paper>
      ) : (
        <Box display="flex" mt={3} justifyContent="flex-end">
          <ButtonPrimary disabled={loadingInvite} onClick={() => createInvite()} fullWidth>
            <LoaderSm loading={loadingInvite} />
            {t('button.sendInvite')}
          </ButtonPrimary>
        </Box>
      )}
    </div>
  );
}

RecordCompare.propTypes = {};
