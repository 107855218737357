import React, { useEffect, useRef, useState } from 'react';
import Typography from 'core/ui/Typography';
import { mmss } from 'core/utils/formatters';

const TimerClock = ({ clock }) => {
  const [time, setTime] = useState(0);
  const intervalRef = useRef();
  const dateBase = clock?.startAt;

  const calculeDiff = () => {
    const startAt = new Date(dateBase).getTime();
    const seconds = Math.round((new Date().getTime() - startAt) / 1000);
    if (seconds <= 0) {
      setTime(0);
      clearInterval(intervalRef.current);
      intervalRef.current = null;
      return;
    }

    setTime(seconds);
  };

  useEffect(() => {
    calculeDiff();
    intervalRef.current = setInterval(() => {
      calculeDiff();
    }, 800);
    return () => {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {time > 0 && (
        <Typography variant="body" color="primary">
          {mmss(time)}
        </Typography>
      )}
    </>
  );
};

export default TimerClock;
