/* eslint-disable react/prop-types */
import React, { useCallback, useState } from 'react';
import Typography, { fontStyle } from 'core/ui/Typography';
import Box from 'core/ui/Box';
import SFDataTable from 'core/ui/DataTable';
import { Skeleton } from '@material-ui/lab';
import { Dialog, Tab, Tabs } from '@material-ui/core';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import { CalendarToday, Warning } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import Paper from 'core/ui/Paper';
import WorkoutScoresPoints from 'components/Competition/Show/Tabs/Results/Tables/WorkoutScoresPoints';
import { dateTime, gymDate } from 'core/utils/formatters';
import Checkbox from 'core/ui/Checkbox';
import { ButtonPrimary } from 'core/ui/Button';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import BenchmarkUserScores from '../../Benchmark/BenchmarkUserScores';
import BenchmarkList from '../../Benchmark/List';

const PreviewList = ({ previewData, loading }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState();
  const { request } = useActions();

  const [value, setValue] = React.useState(0);
  const [checkedItems, setCheckedItems] = useState([]);
  const [query, setQuery] = useState();

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  const benchmarks = previewData?.benchmarks || [];

  const setSelectedBenchmark = (benchmarkSection) => {
    const newArray = benchmarks?.map((x) => {
      const obj = { ...x };
      if (x === open.benchmark || checkedItems.find((y) => y === x)) {
        obj.benchmarkSection = benchmarkSection;
      }

      return obj;
    });

    request({
      action: Modules.usersImports.actions.setPreviewData,
      data: {
        workouts: previewData?.workouts,
        benchmarks: newArray,
      },
    });

    setOpen({ open: false });
    setQuery('');
    setCheckedItems([]);
  };

  const toggleItem = (item) => {
    if (checkedItems.find((x) => x === item)) {
      setCheckedItems((prev) => prev.filter((x) => x !== item));
    } else {
      setCheckedItems((prev) => [...prev, item]);
    }
  };

  const filter = useCallback(
    (benchmark) => {
      if (
        !query ||
        benchmark.benchmarkSection?.name?.toUpperCase().indexOf(query.toUpperCase()) > -1
      ) {
        return true;
      }

      return false;
    },
    [query],
  );

  const columnsWorkouts = [
    {
      name: 'Name',
      selector: 'name',
      sortable: true,
      cell: (workout) => <Typography ml={1}>{workout.name}</Typography>,
    },
    {
      name: 'Date',
      selector: 'scheduledAt',
      sortable: true,
      cell: (workout) => (
        <Typography ml={1}>
          <CalendarToday style={{ fontSize: 12 }} /> {dateTime(workout?.scheduledAt)}
        </Typography>
      ),
    },
    {
      sortable: true,
      cell: (workout) => (
        <Box>
          <Box display="flex" flexWrap="wrap" justifyContent="center">
            {workout?.sections && workout?.sections[0] && (
              <WorkoutScoresPoints
                sectionScores={
                  workout?.sections && workout?.sections[0] && workout?.sections[0]?.scores
                }
                scores={
                  workout?.sections &&
                  workout?.sections[0] &&
                  workout?.sections[0]?.users &&
                  workout?.sections[0]?.users[0] &&
                  workout?.sections[0]?.users[0]?.scores
                }
                showVariation
                showNotes
                fontValue={fontStyle.medium}
                fontScore={fontStyle.medium}
                showFullNotes
              />
            )}
            {/* <WorkoutUserLabelMultipleScores userScores={workout?.sections[0]?.users[0]?.scores} /> */}
          </Box>
        </Box>
      ),
    },
  ];

  const columnsBenchmarks = (showCheckBox) => [
    {
      name: 'Benchmark Section',
      selector: 'benchmarkSectionName',
      sortable: true,
      cell: (benchmark) => (
        <>
          {showCheckBox && (
            <Checkbox
              checked={checkedItems.find((x) => x === benchmark)}
              onClick={() => toggleItem(benchmark)}
            />
          )}
          {benchmark.benchmarkSection?.id ? (
            <Box>
              <Typography>{benchmark.benchmarkSection?.name}</Typography>
            </Box>
          ) : (
            <Box display="flex" alignItems="center">
              <Warning color="primary" style={{ fontSize: 14 }} />
              <Typography variant="caption" mr={3}>
                {t('import.preview.cantFind')} {benchmark.benchmarkSection?.name}
              </Typography>
              <Typography
                className="link-hover"
                ml={2}
                color="primary"
                onClick={() => setOpen({ open: true, benchmark })}
              >
                Select Benchmark
              </Typography>
            </Box>
          )}
        </>
      ),
    },
    {
      name: 'Date',
      selector: 'date',
      sortable: true,
      cell: (benchmark) => (
        <Typography ml={1}>
          <CalendarToday style={{ fontSize: 12 }} /> {gymDate(benchmark.date)}
        </Typography>
      ),
    },
    {
      sortable: true,
      cell: (benchmark) => (
        <Box>
          <Box display="flex" flexWrap="wrap" justifyContent="center">
            <BenchmarkUserScores scores={benchmark?.scores} />
          </Box>
        </Box>
      ),
    },
  ];

  const fixItems = benchmarks?.filter((x) => !x?.benchmarkSection?.id).filter((x) => filter(x));

  const toggleAll = () => {
    if (checkedItems.length > 0) {
      setCheckedItems([]);
    } else {
      setCheckedItems(fixItems);
    }
  };

  const numberBenchmarksToFix = fixItems?.length || 0;

  return (
    <Box p={5}>
      <Dialog maxWidth="sm" fullWidth open={open?.open} onClose={() => setOpen({ open: false })}>
        <Box p={3}>
          <BenchmarkList selector setSelectedBenchmark={(bh) => setSelectedBenchmark(bh)} />
        </Box>
      </Dialog>

      <Paper m={5} mx={20}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label={t('import.benchmarks')} />
          <Tab label={t('import.workouts')} />
        </Tabs>
      </Paper>

      {value === 0 && (
        <>
          {numberBenchmarksToFix > 0 && (
            <Box mb={3}>
              <Typography mb={1} variant="h6" color="primary">
                {t('import.pr.fix').replace('#{number}', numberBenchmarksToFix)}
              </Typography>
              <Box mb={1} display="flex" alignItems="center">
                <Box>
                  <ButtonPrimary onClick={() => toggleAll()}>{t('button.toggle')}</ButtonPrimary>
                </Box>
                <Box mx={1}>
                  <ButtonPrimary
                    disabled={checkedItems.length === 0}
                    onClick={() => setOpen({ open: true })}
                  >
                    <Typography>{t('button.fixSelected')}</Typography>&nbsp;
                    <Typography>
                      {checkedItems?.length > 0 ? `(${checkedItems?.length})` : ''}
                    </Typography>
                  </ButtonPrimary>
                </Box>
                <Box flexGrow={1}>
                  <TextFieldRounded
                    placeholder="Search"
                    bordered
                    value={query}
                    onChange={(v) => {
                      setQuery(v);
                      setCheckedItems([]);
                    }}
                  />
                </Box>
              </Box>
              <SFDataTable
                columns={columnsBenchmarks(true)}
                data={fixItems}
                pagination
                showPaper={false}
                noTableHead
                noHeader
              />
            </Box>
          )}
          <SFDataTable
            columns={columnsBenchmarks(false)}
            data={benchmarks?.filter((x) => !!x?.benchmarkSection?.id)}
            pagination
            showPaper={false}
            // noTableHead
            noHeader
          />
        </>
      )}

      {loading && (
        <Skeleton variant="rect" animation="wave" style={{ height: 200, width: '100%' }} />
      )}

      {value === 1 && (
        <SFDataTable
          columns={columnsWorkouts}
          data={previewData?.workouts}
          pagination
          showPaper={false}
          noTableHead
          noHeader
        />
      )}
    </Box>
  );
};

PreviewList.propTypes = {};

PreviewList.defaultProps = {};

export default PreviewList;
