import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from 'core/ui/Box';

import { StreamContext } from 'pages/Stream';

import { roomActions, roomSelectors } from 'modules/room';
import { useDispatch, useSelector } from 'react-redux';
import FontIcon from 'core/ui/FontIcon';
import Button, { ButtonSideBar } from 'core/ui/Button';
import useEvents from 'core/openVidu/useEvents';
import { Settings, VolumeOff, VolumeUp } from '@material-ui/icons';
import Typography from 'core/ui/Typography';
import { onlineColor, primary } from 'core/ui/Colors';
import { sessionWorkoutSelectors } from 'modules/sessionWorkout';
import LoaderSm from 'core/ui/LoaderSm';
import FiberSmartRecordIcon from '@material-ui/icons/FiberSmartRecord';
import ScreenShareIcon from '@material-ui/icons/ScreenShare';
import StopScreenShareIcon from '@material-ui/icons/StopScreenShare';
import { workoutsSelectors } from 'modules/workouts';
import ModalCalculator from 'components/Profile/Dashboard/PersonalRecord/ModalCalculator';
import CalculatorIcon from 'core/ui/MIcon/CalculatorIcon';
import LeaderboardIcon from 'core/ui/MIcon/LeaderboardIcon';
import Components, { ComponentsSideBar } from './components';
import Exit from './Exit';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    height: '100%',
  },
  sideBar: {
    background: '#404041',
    height: '100%',
    overflow: 'hide',
    display: 'flex',
    flexDirection: 'column',
    width: 70,
    maxWidth: 70,
    minWidth: 70,
  },
  content: {
    flexGrow: 1,
  },
  logo: {
    width: 30,
    height: 30,
  },
  icon: {
    width: 30,
  },
}));

export default () => {
  const streamContext = useContext(StreamContext);
  const [modalCalculator, setModalCalculator] = useState(false);

  const classes = useStyles();
  const user = useSelector((state) => roomSelectors.getUser(state));
  const mutedRecordings = useSelector((state) => roomSelectors.getMutedRecordings(state));
  const recording = useSelector((state) => sessionWorkoutSelectors.getRecording(state));
  const dispatch = useDispatch();
  const { camStatusChange, micStatusChanged, isCoach, exit } = useEvents();
  const loadingRecording = useSelector((state) =>
    sessionWorkoutSelectors.getLoadingRecording(state),
  );
  const workout = useSelector((state) => workoutsSelectors.getWorkout(state));
  const openMessages = () => {
    streamContext.openSideBar({ component: ComponentsSideBar.Chat });
    streamContext.setUnreadMessages(0);
  };

  return (
    <div className={classes.root}>
      <Box className={`${streamContext.openDrawer === true ? '' : 'hide'} ${classes.content}`}>
        <Components />
      </Box>
      {modalCalculator && <ModalCalculator close={() => setModalCalculator(false)} />}

      <Box className={classes.sideBar}>
        <Box mt={3} display="flex" justifyContent="center">
          <Button
            onClick={() => streamContext.openSideBar({ component: ComponentsSideBar.Settings })}
          >
            <Settings
              style={{
                color:
                  streamContext.componentSideBar === ComponentsSideBar.Settings &&
                  streamContext.openDrawer === true
                    ? '#4fb94a'
                    : 'white',
                fontSize: 30,
              }}
            />
          </Button>
        </Box>
        {!workout.isCompetition && (
          <Box mt={5} display="flex" justifyContent="center">
            <Button
              onClick={() =>
                streamContext.eventSideBar.setGalleryView(!streamContext.eventSideBar.galleryView)
              }
            >
              <FontIcon
                iconName={
                  streamContext.eventSideBar.galleryView ? 'icon-speaker-view' : 'icon-menu'
                }
                size={25}
                color="white"
              />
            </Button>
          </Box>
        )}

        <Button mt={5} onClick={() => setModalCalculator(true)}>
          <CalculatorIcon color="white" size={30} />
        </Button>
        {!workout.isCompetition && workout.live && (
          <Box mt={5} display="flex" justifyContent="center">
            <Button onClick={() => streamContext.eventSideBar.screenShare()}>
              {!user.shareScreen ? (
                <ScreenShareIcon style={{ color: 'white', fontSize: 25 }} />
              ) : (
                <StopScreenShareIcon style={{ color: onlineColor, fontSize: 25 }} />
              )}
            </Button>
          </Box>
        )}

        <Box
          flexGrow="1"
          flexWrap="wrap"
          alignItems="flex-end"
          display="flex"
          justifyContent="center"
        >
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="flex-end">
            {!workout.recorded && !workout.isCompetition && isCoach() && workout.live && (
              <ButtonSideBar mb={1} onClick={() => streamContext.setModalRecording({ open: true })}>
                {loadingRecording ? (
                  <LoaderSm loading />
                ) : (
                  <FiberSmartRecordIcon
                    className={recording.active ? 'recording-effect' : ''}
                    style={{ fontSize: 24, color: recording.active ? primary : 'white' }}
                  />
                )}
              </ButtonSideBar>
            )}
            {!workout.isCompetition && (
              <ButtonSideBar
                mb={1}
                onClick={() =>
                  streamContext.openSideBar({ component: ComponentsSideBar.Participants })
                }
              >
                {streamContext.componentSideBar === ComponentsSideBar.Participants &&
                streamContext.openDrawer === true ? (
                  <FontIcon iconName="icon-users" color={primary} size={20} />
                ) : (
                  <FontIcon iconName="icon-users" color="white" size={20} />
                )}
              </ButtonSideBar>
            )}
            <ButtonSideBar
              mb={1}
              onClick={() => streamContext.openSideBar({ component: ComponentsSideBar.Sections })}
            >
              {streamContext.componentSideBar === ComponentsSideBar.Sections &&
              streamContext.openDrawer === true ? (
                <FontIcon iconName="icon-dumbbell" color={primary} size={30} />
              ) : (
                <FontIcon iconName="icon-dumbbell" color="white" size={30} />
              )}
            </ButtonSideBar>

            {!workout.isCompetition && workout.live && (
              <ButtonSideBar onClick={() => openMessages()} mb={1}>
                {streamContext.componentSideBar === ComponentsSideBar.Chat &&
                streamContext.openDrawer === true ? (
                  <FontIcon iconName="icon-chat-dialog" color={primary} size={20} />
                ) : (
                  <>
                    {streamContext.unreadMessages !== 0 && (
                      <Box className="badge-notification-message">
                        <Typography style={{ color: 'white' }}>
                          {streamContext.unreadMessages}
                        </Typography>
                      </Box>
                    )}
                    <FontIcon iconName="icon-chat-dialog" color="white" size={20} />
                  </>
                )}
              </ButtonSideBar>
            )}
            {!workout.isCompetition && (
              <ButtonSideBar
                onClick={() =>
                  streamContext.openSideBar({ component: ComponentsSideBar.Leaderboard })
                }
              >
                <LeaderboardIcon
                  color={
                    streamContext.componentSideBar === ComponentsSideBar.Leaderboard &&
                    streamContext.openDrawer === true
                      ? primary
                      : 'white'
                  }
                  size={30}
                />
              </ButtonSideBar>
            )}
          </Box>

          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="flex-end">
            {workout.live && (
              <>
                {workout.recorded && (
                  <Button
                    onClick={() => dispatch(roomActions.setMutedRecordings(!mutedRecordings))}
                    mb={3}
                  >
                    {mutedRecordings ? (
                      <VolumeOff style={{ color: primary, fontSize: 34 }} />
                    ) : (
                      <VolumeUp style={{ color: onlineColor, fontSize: 34 }} />
                    )}
                  </Button>
                )}

                <Button onClick={() => camStatusChange(true)} mb={3}>
                  {user !== undefined && user.videoActive === true ? (
                    <FontIcon iconName="icon-cam-on" size={30} color={onlineColor} />
                  ) : (
                    <FontIcon iconName="icon-cam-off" color={primary} size={30} />
                  )}
                </Button>
                <Button onClick={() => micStatusChanged(true)} mb={3}>
                  {user !== undefined && user.audioActive ? (
                    <FontIcon iconName="icon-microphone-on" color={onlineColor} size={30} />
                  ) : (
                    <FontIcon iconName="icon-microphone-off" color={primary} size={30} />
                  )}
                </Button>
              </>
            )}

            <Exit exit={exit} />
          </Box>
        </Box>
      </Box>
    </div>
  );
};
