import React, { useEffect, useState } from 'react';
import { ButtonPrimary } from 'core/ui/Button';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import Typography from 'core/ui/Typography';

import {
  TableContainer,
  TableHead,
  Table,
  TableCell,
  TableRow,
  TableBody,
  Paper,
  IconButton,
  Dialog,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { channelsSelectors } from 'modules/channels';
import { Add, Delete } from '@material-ui/icons';
import LoaderSm from 'core/ui/LoaderSm';
import useSelectors from 'modules/map/useSelectors';
import { channelsDenylistsModule } from 'modules/channelsDenylists';
import Modules from 'modules';
import useActions from 'modules/map/useActions';
import Texture from 'core/ui/Texture';
import ModalFormDenylist from './Form/Modal';

const Denylists = () => {
  const { t } = useTranslation();
  const { request } = useActions();
  const channel = useSelector((state) => channelsSelectors.getChannel(state));
  const loading = useSelectors(channelsDenylistsModule, 'loading');
  const channelLists = useSelectors(channelsDenylistsModule, 'data');
  const [modalForm, setModalForm] = useState({
    open: false,
  });
  const [modal, setModal] = useState(false);

  useEffect(() => {
    if (channel && channel.id) {
      request({
        action: Modules.channelsDenylists.actions.getDenylists,
        data: {
          channelId: channel?.id,
        },
      });
    }
  }, [request, channel]);

  const openModal = () => {
    setModalForm((prev) => ({ ...prev, mode: 'new', open: true }));
  };

  const remove = (id) => {
    request({
      action: Modules.channelsDenylists.actions.destroy,
      data: {
        channelId: channel?.id,
        id,
      },
      options: {
        onSuccess: () => {
          request({
            action: Modules.channelsDenylists.actions.getDenylists,
            data: {
              channelId: channel?.id,
            },
          });
        },
      },
    });
  };

  return (
    <>
      <ButtonPrimary onClick={() => setModal(true)}>{t('button.denyList')}</ButtonPrimary>

      <Dialog
        PaperProps={{ style: { borderRadius: 5, minWidth: 300 } }}
        maxWidth="md"
        scroll="body"
        fullWidth
        onClose={() => setModal(false)}
        open={modal}
      >
        <Texture>
          <Box p={3}>
            {modalForm.open && (
              <ModalFormDenylist
                open={modalForm.open}
                item={modalForm.item}
                mode={modalForm.mode}
                close={() => setModalForm((prev) => ({ ...prev, open: false, item: {} }))}
                callback={(nameList) =>
                  setModalForm((prev) => ({
                    ...prev,
                    open: false,
                    item: {},
                    nameListExpand: nameList,
                  }))
                }
              />
            )}

            <Box display="flex" alignItems="center">
              <Box flexGrow="1" display="flex" alignItems="center">
                <Typography variant="h5">{t('channel.denyLists')}</Typography>
                <LoaderSm loading={loading} />
              </Box>
              <Box>
                <ButtonPrimary onClick={() => openModal()}>
                  <Box display="flex" alignItems="flex-end">
                    <Add size="small" />
                    <Box alignItems="center">
                      <Typography component="span" mr={1}>
                        {t('button.add')}
                      </Typography>
                    </Box>
                  </Box>
                </ButtonPrimary>
              </Box>
            </Box>
            <Box mt={2}>
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        {t('label.phone')} / {t('label.email')}
                      </TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {channelLists &&
                      channelLists.map((item) => (
                        <TableRow key={item?.id} className="hover">
                          <TableCell>
                            <Typography component="p">{item.email}</Typography>
                            <Typography component="p">{item.phone}</Typography>
                          </TableCell>
                          <TableCell align="right">
                            <IconButton onClick={() => remove(item?.id)}>
                              <Delete />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Texture>
      </Dialog>
    </>
  );
};

export default Denylists;
