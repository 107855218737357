import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from 'core/ui/Typography';

import { Add } from '@material-ui/icons';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

const useStyles = makeStyles({
  root: {
    backgroundColor: 'transparent',
  },
  box: {
    backgroundColor: '#fff',
    position: 'relative',
    paddingTop: '37.25%',
  },
});

export default function CardButtonAdd() {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Card className={classes.root} onClick={() => history.push('/channel/new')}>
      <Box className={classes.box}>
        <Box
          display="flex"
          justifyContent="center"
          style={{ position: 'absolute', top: 0, width: '100%' }}
        >
          <Box justifyContent="center">
            <Typography align="center" component="h4">
              <Add style={{ fontSize: 120 }} fontSize="large" color="primary" />
            </Typography>
          </Box>
        </Box>
      </Box>
      <CardContent>
        <Typography gutterBottom color="primary" variant="h5" component="h2">
          {t('profile.addChannel')}
        </Typography>
      </CardContent>
    </Card>
  );
}
