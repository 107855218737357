import api from 'core/api';

export const channelsTagsModule = 'channelsTags';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const actions = {
  create: {
    module: channelsTagsModule,
    name: 'create',
    api: (params) => api.post(`/channels/${params.channelId}/channels_tags`, params),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  update: {
    module: channelsTagsModule,
    name: 'update',
    api: (params) => api.put(`/channels/${params.channelId}/channels_tags/${params?.id}`, params),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  getTags: {
    module: channelsTagsModule,
    name: 'getTags',
    api: (params) => api.get(`/channels/${params?.channelId}/channels_tags`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: ['data'],
    },
    state: loadingStates,
  },
  clearTags: {
    module: channelsTagsModule,
    name: 'clearTags',
    params: {
      start: ['params'],
      success: ['data'],
    },
  },
  getTag: {
    module: channelsTagsModule,
    name: 'getTag',
    api: (params) => api.get(`/channels/${params.channelId}/channels_tags/${params?.id}`, params),
    params: {
      start: ['params'],
      error: [''],
      success: ['tag'],
    },
    state: loadingStates,
  },
  saveMemberTags: {
    module: channelsTagsModule,
    name: 'saveMemberTags',
    api: (data) => api.post(`/channels/${data.channelId}/channels_tags/save_member_tags`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
};

export default {
  actions,
  state: {
    loading: false,
    tag: null,
    data: [],
  },
};
