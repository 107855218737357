/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  makeStyles,
  TableCell,
  TableRow,
  withStyles,
  Collapse,
  Dialog,
  Tooltip,
} from '@material-ui/core';
import {
  labelLight,
  onlineColor,
  primary,
  redColor,
  rowLight,
  rowXLight,
  secondary,
  secondaryBgModal,
  secondaryLight,
} from 'core/ui/Colors';
import Box from 'core/ui/Box';
import FlagCountry from 'core/ui/FlagCountry';
import Typography, { fontStyle } from 'core/ui/Typography';
import { ButtonPrimary } from 'core/ui/Button';
import { Close, Info, VerifiedUser } from '@material-ui/icons';
import ImageMediaUser from 'components/Profile/ImageMedia';
import InstagramLink from 'components/Profile/InstagramLink';
import { useTranslation } from 'react-i18next';
import FontIcon from 'core/ui/FontIcon';
import { ordinalNumber } from 'core/utils/formatters/date';
import { useHistory } from 'react-router';
import Divider from 'core/ui/Divider';
import AvatarCoach from 'components/Profile/AvatarCoach';
import { sessionSelectors } from 'modules/session';
import { useSelector } from 'react-redux';
import ModalJudgeTeam from 'pages/Competition/Workout/ModalJudgeTeam';
import LabelPhone from 'core/ui/InputPhone/LabelPhone';
import Paper from 'core/ui/Paper';
import DialogTitle from 'core/ui/Dialog/DialogTitle';
import clsx from 'clsx';
import WorkoutScoresPoints from './WorkoutScoresPoints';
import ModalTableTeamHeats from './ModalTableTeamHeats';
import ActionsTeam from './ActionsTeam';
import ActionsTeamWorkout from './ActionsTeamWorkout';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },

  tableContainer: {},
  cell: {
    minWidth: '100px',
  },
  boxCelMin: {
    width: 60,
    display: 'flex',
    justifyContent: 'center',
  },
  buttonXs: {
    width: 27,
    height: 27,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
  },
}));

const StickyTableCell = withStyles(() => ({
  body: {
    minWidth: '50px',
    left: 0,
    position: 'sticky',
  },
}))(TableCell);

const TableResultsRow = ({
  indexRow,
  item,
  competition,
  openModalScores,
  position,
  setOpenTeamEdit,
  removeUser,
  withdrawUser,
  removeUserVideo,
  removeUserScore,
  compWorkouts,
  showing,
  affiliate,
}) => {
  const currentUser = useSelector((state) => sessionSelectors.getUser(state));
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const [judge, setJudge] = useState({});
  const [detail, setDetail] = useState(false);
  const [teamDetails, setTeamDetails] = useState(false);
  const [modalHeats, setModalHeats] = useState({ open: false });

  const openDetail = () => {
    setDetail(!detail);
  };

  const affiliateGym = (itemTeam) =>
    competition.affiliateGym && competition.affiliateGym.id === itemTeam.team.affiliateId;

  const borderLeft = {
    borderLeft: `0.5px solid ${secondaryLight}`,
  };

  const borderRight = {
    borderRight: `0.5px solid ${secondaryLight}`,
  };

  const lastWorkout = (compWorkouts && compWorkouts.length - 1) || 0;
  const { isCoach, isJudge } = competition;

  const cellStyle = {
    backgroundColor: indexRow % 2 === 0 ? rowXLight : rowLight,
    cursor: 'pointer',
  };

  if (!showing) {
    cellStyle.display = 'none';
  }

  const cutLineStyle = (footer) => {
    if (detail && footer) {
      return { borderBottom: item?.showCutline ? `3px solid ${competition?.buttonColor}` : 'none' };
    }
    if (!detail && !footer) {
      return { borderBottom: item?.showCutline ? `3px solid ${competition?.buttonColor}` : 'none' };
    }

    return {};
  };

  const mySelf = currentUser?.id === item?.team?.id;

  const showPointsRank = !competition?.hidePoints || isCoach || isJudge;

  const showVideo = (w) =>
    item?.workouts[w.id]?.leaderboard?.hasVideo &&
    (isCoach || isJudge || currentUser?.id === item?.team?.id);

  return (
    <>
      <ModalTableTeamHeats
        competition={competition}
        userId={modalHeats?.userId}
        open={modalHeats?.open}
        close={() => setModalHeats({ open: false })}
      />
      {judge.open && (
        <ModalJudgeTeam
          open
          workoutId={judge?.workoutId}
          competitionId={competition?.id}
          userId={judge?.userId}
          close={() => setJudge({})}
        />
      )}
      <Dialog
        fullWidth
        maxWidth="sm"
        PaperProps={{ backgroundColor: secondaryBgModal }}
        open={teamDetails}
        onClose={() => setTeamDetails(false)}
      >
        <DialogTitle onClose={() => setTeamDetails(false)}>
          {item?.team?.teamName || item?.team?.firstName}
        </DialogTitle>
        <div className="tw-flex tw-flex-col tw-gap-2 tw-p-4">
          {item?.team?.teamUsers?.map((user) => (
            <Paper key={user?.id} className="tw-flex-gap-2 tw-flex tw-flex-row tw-p-3">
              <AvatarCoach user={user} size={30} />
              <div className="tw-flex-gap-2 tw-flex tw-flex-col">
                <Typography pl={2}>{user?.name}</Typography>
                <Typography pl={2}>{user?.email}</Typography>
                <LabelPhone value={user?.phone} />
              </div>
            </Paper>
          ))}
        </div>
      </Dialog>
      <TableRow>
        <StickyTableCell
          style={{
            borderBottomColor: 'transparent',
            zIndex: 50,
            ...borderLeft,
            ...cellStyle,
            ...cutLineStyle(),
          }}
        >
          <Box display="flex" alignItems="center">
            {showPointsRank && (
              <Box onClick={() => openDetail()} className={classes.boxCelMin}>
                {!affiliate ? position : ''}
              </Box>
            )}
            <Box
              onClick={() => openDetail()}
              className={classes.boxCelMin}
              style={{ color: labelLight }}
            >
              {item?.team?.teamNumber?.toString() !== '0' && item?.team?.teamNumber}
            </Box>
            {item.team && item.team.country && item.team.country.flag && (
              <Box className={classes.boxCelMin}>
                <FlagCountry flag={item.team.country.flag} size={affiliate ? 32 : 40} />
              </Box>
            )}

            <ActionsTeam
              item={item}
              competition={competition}
              setOpenTeamEdit={setOpenTeamEdit}
              removeUser={removeUser}
              withdrawUser={withdrawUser}
              detail={detail}
              openDetail={openDetail}
              isCoach={isCoach}
              currentUser={currentUser}
              affiliateGym={affiliateGym}
              setModalHeats={setModalHeats}
              setTeamDetails={setTeamDetails}
            />
            {/* <Divider orientation="vertical" mx={2} flexItem /> */}

            <Box className={clsx([classes.boxCelMin, 'tw-w-[85px]'])} onClick={() => openDetail()}>
              {showPointsRank && (
                <Typography className="roboto-regular">
                  {item?.totalPoints > 0 ? item?.totalPoints : '--'}
                </Typography>
              )}
            </Box>
          </Box>
        </StickyTableCell>
        {(compWorkouts || []).map((w, index) => (
          <TableCell
            onClick={() => openDetail()}
            key={w.id}
            style={
              lastWorkout === index
                ? {
                    borderBottomColor: 'transparent',
                    ...borderLeft,
                    ...borderRight,
                    ...cellStyle,
                    ...cutLineStyle(),
                  }
                : {
                    borderBottomColor: 'transparent',
                    ...borderLeft,
                    ...cellStyle,
                    ...cutLineStyle(),
                  }
            }
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={{ minWidth: 100 }}
            >
              {showPointsRank && (
                <>
                  {!!item.workouts[w.id]?.leaderboard &&
                    !item.workouts[w.id]?.invalidCustomScores && (
                      <Typography
                        className="roboto-regular"
                        color="secondary"
                        mr={2}
                        align="center"
                        style={fontStyle.large}
                      >
                        {item.workouts[w.id]?.leaderboard?.position && (
                          <div>
                            <b>{ordinalNumber(item.workouts[w.id]?.leaderboard?.position)}</b>
                            &nbsp;&nbsp;
                            {item.workouts[w.id]?.leaderboard?.points} points
                          </div>
                        )}
                      </Typography>
                    )}
                  {!!item.workouts[w.id]?.invalidCustomScores && (
                    <div className="tw-flex tw-flex-row">
                      <Tooltip title={t('competition.leaderboard.invalidCustomScorings')}>
                        <Info />
                      </Tooltip>
                    </div>
                  )}

                  {item.workouts[w.id]?.leaderboard?.position &&
                    item?.workouts[w.id]?.leaderboard?.verified && (
                      <ButtonPrimary p={1} style={{ minWidth: 22 }} buttonColor={onlineColor}>
                        <VerifiedUser style={{ fontSize: 15 }} />
                      </ButtonPrimary>
                    )}
                  {(isJudge || isCoach) && item.workouts[w.id]?.leaderboard?.isInvalid && (
                    <ButtonPrimary p={1} style={{ minWidth: 22 }} buttonColor={redColor}>
                      <Close style={{ fontSize: 15 }} />
                      {t('label.invalidScores')}
                    </ButtonPrimary>
                  )}
                </>
              )}
              {showVideo(w) && (
                <ButtonPrimary
                  p={1}
                  style={{ minWidth: 22 }}
                  onClick={() =>
                    history.push(
                      `/competition/${competition.id}/workout/${w.id}/watch/team/${item.team.id}`,
                    )
                  }
                  buttonColor={competition?.buttonColor || primary}
                >
                  <FontIcon iconName="icon-classes" color="inherit" size={15} />
                </ButtonPrimary>
              )}
            </Box>
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        <StickyTableCell
          style={{
            padding: 0,
            zIndex: 1090,
            ...borderLeft,
            ...cellStyle,
            ...cutLineStyle(true),
          }}
        >
          <Collapse in={detail}>
            <Box>
              {!affiliate && item.team.affiliate && item.team.affiliate.gymName && (
                <Box pl={10} mb={2}>
                  <Typography noWrap style={{ color: secondary }}>
                    {t('competition.affiliateGym')}{' '}
                    {item.team.affiliate && item.team.affiliate.gymName}
                  </Typography>
                </Box>
              )}
              <Box display="flex">
                <Box pl={10} p={3} display="flex">
                  {item?.regType === 'team' ? (
                    <Box>
                      <Box display="flex" alignItems="center" flexWrap="wrap">
                        <Typography color="secondary" className="tw-font-bold">
                          {item.team.country.name}
                        </Typography>
                        {item.team.location && (
                          <Typography color="secondary" className="roboto-regular">
                            &nbsp;&bull;&nbsp;{item.team.location}
                          </Typography>
                        )}
                      </Box>
                      <Box display="flex" flexWrap="wrap" flexDirection="column" mt={1}>
                        {item.team.teamUsers &&
                          item.team.teamUsers.map((user, index) => (
                            <Box key={`team-${user?.id}`}>
                              <Typography
                                color="secondary"
                                className="roboto-regular"
                                style={fontStyle.medium}
                              >
                                {user.name}
                              </Typography>

                              {item.team.teamUsers.length - 1 > index && <Divider my={1} />}
                            </Box>
                          ))}
                      </Box>
                    </Box>
                  ) : (
                    <>
                      <ImageMediaUser
                        user={item.team}
                        image={item.team.image ? item.team.image : {}}
                        size={70}
                        heightCountry={15}
                        sizeCountry="1em"
                      />
                      <Box ml={3}>
                        <Typography>{item.team?.displayName}</Typography>
                        <Typography className="roboto-regular">{item.team.country.name}</Typography>
                        <InstagramLink instagram={item.team.instagram} />
                        <Typography className="roboto-regular">{item.team.localGym}</Typography>
                      </Box>
                    </>
                  )}
                </Box>
              </Box>
            </Box>
          </Collapse>
        </StickyTableCell>

        {(compWorkouts || []).map((w, index) => {
          const showScores =
            (w.showScores || isJudge || isCoach || mySelf) &&
            ((!item.workouts[w.id]?.invalidCustomScores &&
              item.workouts[w.id]?.leaderboard?.points &&
              item.workouts[w.id]?.leaderboard?.points > 0) ||
              item.workouts[w.id]?.leaderboard?.isInvalid);

          return (
            <TableCell
              key={w.id}
              style={
                lastWorkout === index
                  ? {
                      padding: 0,
                      ...borderLeft,
                      ...borderRight,
                      ...cellStyle,
                      ...cutLineStyle(true),
                    }
                  : { padding: 0, ...borderLeft, ...cellStyle, ...cutLineStyle(true) }
              }
            >
              <Collapse in={detail}>
                <Box
                  p={2}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  {item.workouts[w.id] && (
                    <>
                      {showScores && (
                        <Box display="flex" flexDirection="column" alignItems="center">
                          <Box display="flex" flexWrap="wrap" className="tw-gap-2">
                            <WorkoutScoresPoints
                              sectionScores={item.workouts[w.id]?.leaderboard?.sectionScores}
                              scores={
                                item.workouts[w.id]?.leaderboard?.revisedScores ||
                                item.workouts[w.id]?.leaderboard?.scores
                              }
                              positions={item.workouts[w.id]?.leaderboard?.positions || {}}
                              showVariation={w.competitionUseRxScaledScores}
                              buttonColor={competition?.buttonColor}
                            />
                          </Box>

                          <Typography color="secondary" mr={2} className="roboto-regular hover">
                            Points {item.workouts[w.id]?.leaderboard?.points}
                          </Typography>
                        </Box>
                      )}
                      {(isJudge || isCoach) && (
                        <ActionsTeamWorkout
                          item={item}
                          openModalScores={openModalScores}
                          w={w}
                          removeUserScore={removeUserScore}
                          removeUserVideo={removeUserVideo}
                          setJudge={setJudge}
                        />
                      )}
                    </>
                  )}
                </Box>
              </Collapse>
            </TableCell>
          );
        })}
      </TableRow>
    </>
  );
};

export default TableResultsRow;
