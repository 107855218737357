import api from 'core/api';
import { call, put } from 'redux-saga/effects';

export const storeProductsModule = 'storeProducts';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const loadingProductStates = {
  start: { loadingProduct: true },
  error: { loadingProduct: false },
  success: { loadingProduct: false },
};

const loadingReorderStates = {
  start: { loadingReorder: true },
  error: { loadingReorder: false },
  success: { loadingReorder: false },
};

const actions = {
  getStoreProducts: {
    module: storeProductsModule,
    name: 'getStoreProducts',
    api: (params) => api.get(`/store/products`, { params }),
    *sagas(Creators, { params, options }) {
      try {
        const resp = yield call(actions.getStoreProducts.api, params);
        yield put(Creators.getStoreProductsSuccess(resp.data, resp.pagination));
        if (options.onSuccess) {
          options.onSuccess(resp.data, resp.pagination);
        }
      } catch (error) {
        if (options.onError) {
          options.onError(error);
        }
        yield put(Creators.getStoreProductsError());
      }
    },
    params: {
      start: ['params'],
      error: [''],
      success: ['data', 'pagination'],
    },
    state: loadingStates,
    isTakeEvery: true,
  },
  getStoreProduct: {
    module: storeProductsModule,
    name: 'getStoreProduct',
    api: (params) => api.get(`/store/products/${params?.id}`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: ['product'],
    },
    state: loadingProductStates,
  },
  postStoreProducts: {
    module: storeProductsModule,
    name: 'postStoreProducts',
    api: (params) => api.post(`/store/products`, params),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  putStoreProducts: {
    module: storeProductsModule,
    name: 'putStoreProducts',
    api: (data) => api.put(`/store/products/${data.id}`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [],
    },
    state: loadingStates,
  },
  reorderProducts: {
    module: storeProductsModule,
    name: 'reorderProducts',
    api: (data) => api.put(`/store/products/reorder`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [],
    },
    state: loadingReorderStates,
  },
  putOrderProduct: {
    module: storeProductsModule,
    name: 'putOrderProduct',
    api: (data) => api.put(`/store/products/${data.storeProductId}/change_order`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [],
    },
    state: loadingStates,
  },
  destroy: {
    module: storeProductsModule,
    name: 'destroy',
    api: (data) => api.delete(`/store/products/${data.storeProductId}`, { params: data }),
    params: {
      start: ['params'],
      error: [''],
      success: [],
    },
    state: loadingStates,
  },
};

export default {
  actions,
  state: {
    data: [],
    pagination: {},
    error: null,
    product: null,
    loading: false,
    loadingReorder: false,
    loadingProduct: false,
  },
};
