import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import { secondaryBgLight } from 'core/ui/Colors';
import { DialogActions, DialogContent } from '@material-ui/core';
import { ButtonPrimary } from 'core/ui/Button';
import Modules from 'modules';
import useActions from 'modules/map/useActions';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import Typography from 'core/ui/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { competitionsSelectors } from 'modules/competitions';
import notifications from 'modules/notifications';
import useConfirmation from 'core/useConfirmation';

const ModalCloneHeat = ({ open, workout, close }) => {
  const { t } = useTranslation();
  const { request } = useActions();
  const [workoutDest, setWorkoutDest] = useState(null);
  const { confirmation } = useConfirmation();
  const competition = useSelector((state) => competitionsSelectors.getCompetition(state));

  const dispatch = useDispatch();

  const confirmClone = () => {
    confirmation({
      description: `Do you want to overwrite all heats for ${workoutDest?.name}`,
      yesClick: () => {
        request({
          action: Modules.newWorkoutHeats.actions.cloneHeat,
          data: {
            workoutOrigId: workout?.id,
            workoutId: workoutDest?.id,
          },
          options: {
            onSuccess: () => {
              dispatch(notifications.success(t('workout.heats.save')));
              close();
            },
          },
        });
      },
    });
  };

  return (
    <Dialog fullWidth maxWidth="sm" style={{ marginTop: 100 }} open={!!open} onClose={close}>
      <DialogContent style={{ backgroundColor: secondaryBgLight }}>
        <Box mb={4}>
          <Typography>{t('label.cloneHeat')}</Typography>
        </Box>
        <Box mb={4}>
          <SelectBoxObject
            options={competition?.workouts}
            label={t('competition.tab.events')}
            value={workoutDest?.id}
            // eslint-disable-next-line eqeqeq
            setValue={(v) => setWorkoutDest(competition?.workouts.find((x) => x.id == v))}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <ButtonPrimary buttonColor={competition?.buttonColor} onClick={() => confirmClone()}>
          {t('button.save')}
        </ButtonPrimary>
      </DialogActions>
    </Dialog>
  );
};

export default ModalCloneHeat;
