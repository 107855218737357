import React, { useEffect, useState } from 'react';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import { sessionSelectors } from 'modules/session';
import { useDispatch, useSelector } from 'react-redux';
import ModalScore from 'components/Stream/SideBar/components/Sections/ModalScore';
import ModalShare from 'core/ui/SocialMediaButtons/ModalShare';
import { workoutUrlSlug } from 'core/utils/helpers';
import { useTranslation } from 'react-i18next';
import notifications from 'modules/notifications';
import WorkoutsList from './List';
import ModalCustomClass from './Custom/Modal';
import ModalCalculator from '../PersonalRecord/ModalCalculator';
import useCustomActions from './useCustomActions';
import ModalInvite from './Invite/ModalInvite';

const Workouts = () => {
  const { t } = useTranslation();
  const [selectClass, setSelectClass] = useState(null);
  const { request } = useActions();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => sessionSelectors.getUser(state));
  const [text, setText] = useState('');
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [closeModal, setCloseModal] = useState(false);

  const getWorkouts = ({ exportData = false } = {}) => {
    request({
      action: exportData
        ? Modules.userHistory.actions.exportWorkoutsHistory
        : Modules.userHistory.actions.getWorkoutHistories,
      data: { userId: currentUser.id, page, perPage, query: text, exportData },
      options: {
        onSuccess: () => {
          if (exportData) {
            dispatch(notifications.success(t('workouts.history.export')));
          }
        },
      },
    });
  };

  useEffect(() => {
    getWorkouts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage]);

  const callbackForm = (dataWorkout) => {
    request({
      action: Modules.userHistory.actions.getWorkoutHistories,
      data: { userId: currentUser.id, page, perPage, query: text },
      options: {
        onSuccess: (data) => {
          const newItem = data && data.find((x) => x?.workout?.id === dataWorkout?.id);
          if (newItem) {
            setSelectClass(newItem);
          }
          setCloseModal(true);
        },
      },
    });
  };

  const customWorkoutActions = useCustomActions({
    callbackForm,
    workout: selectClass?.workout,
  });

  useEffect(() => {
    if (closeModal) {
      customWorkoutActions.setModalForm({ open: false });
      setCloseModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeModal]);

  return (
    <>
      {customWorkoutActions.modalCalculator && (
        <ModalCalculator close={() => customWorkoutActions.setModalCalculator(false)} />
      )}
      {customWorkoutActions.modalInvite?.open && (
        <ModalInvite
          open={customWorkoutActions.modalInvite?.open}
          workout={customWorkoutActions.modalInvite?.workout}
          close={() => customWorkoutActions.setModalInvite({ open: false })}
          setModalForm={customWorkoutActions.setModalForm}
          isOwner={currentUser?.id === customWorkoutActions.modalInvite?.workout?.ownerId}
          callback={() => callbackForm()}
        />
      )}
      {customWorkoutActions.modalShare && (
        <ModalShare
          title={t('workout.custom.share')}
          url={workoutUrlSlug(selectClass?.workout)}
          close={() => customWorkoutActions.setModalShare(false)}
          opened={customWorkoutActions.modalShare}
        />
      )}
      {customWorkoutActions.modalScore.open && (
        <ModalScore
          user={currentUser}
          sectionData={customWorkoutActions.modalScore.sectionData}
          open={customWorkoutActions.modalScore.open}
          workout={customWorkoutActions.modalScore.workout}
          setOpen={() => customWorkoutActions.setModalScore({ open: false })}
          callbackSave={() => {
            callbackForm({ id: selectClass?.workout?.id });
          }}
        />
      )}
      <ModalCustomClass
        callbackForm={callbackForm}
        modalForm={customWorkoutActions.modalForm}
        close={() => customWorkoutActions.setModalForm({ open: false })}
      />

      <WorkoutsList
        actions={customWorkoutActions}
        setPerPage={setPerPage}
        setPage={setPage}
        perPage={perPage}
        text={text}
        setText={setText}
        search={getWorkouts}
      />
    </>
  );
};

export default Workouts;
