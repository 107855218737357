import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import { CompetitionRegistrationContext } from 'contexts';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import { secondary } from 'core/ui/Colors';

export default function VendorRegistration() {
  const { t } = useTranslation();
  const context = useContext(CompetitionRegistrationContext);

  return (
    <Box>
      <TextFieldRounded
        onChange={(v) =>
          context.setRegistrationData((prev) => ({
            ...prev,
            company: v,
          }))
        }
        value={context?.registrationData?.company || ''}
        placeholder={t('competition.registration.label.company')}
        label={t('competition.registration.label.company')}
        required
        labelColor={context?.competition?.darkBackground ? 'white' : secondary}
      />
    </Box>
  );
}
