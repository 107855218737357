import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppBar, Container, Dialog, IconButton, Slide, Toolbar } from '@material-ui/core';
import { LayoutContext } from 'pages/shared/Layout';
import { workoutsActions, workoutsSelectors } from 'modules/workouts';
import { competitionsSelectors } from 'modules/competitions';
import Modules from 'modules';
import useActions from 'modules/map/useActions';
import JudgeTeam from 'components/Competition/Workout/Judge/JudgeTeam';
import useSelectors from 'modules/map/useSelectors';
import { newWorkoutsModule } from 'modules/workouts/new';
import LoaderSm from 'core/ui/LoaderSm';
import { secondaryBgModal } from 'core/ui/Colors';
import Typography from 'core/ui/Typography';
import { Close } from '@material-ui/icons';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const ModalJudgeTeam = ({ open, close, workoutId, userId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const layoutContext = useContext(LayoutContext);
  const workout = useSelector((state) => workoutsSelectors.getWorkout(state));
  const { request } = useActions();
  const loading = useSelectors(newWorkoutsModule, 'loading');
  const competition = useSelector((state) => competitionsSelectors.getCompetition(state));

  const [leaderboard, setLeaderboard] = useState(null);

  useEffect(() => {
    dispatch(workoutsActions.getWorkoutStart(workoutId));
    return () => {
      dispatch(workoutsActions.clearWorkout());
    };
  }, [dispatch, workoutId]);

  const getLeaderboard = () => {
    request({
      action: Modules.newWorkouts.actions.getLeaderboardUser,
      data: {
        workoutId,
        sectionId: 'first',
        userId,
      },
      options: {
        onSuccess: (data) => {
          if (data?.length > 0) {
            setLeaderboard(data[0]);
          }
        },
      },
    });
  };

  useEffect(() => {
    layoutContext.setTitleBar(t('title.judging').toUpperCase());
    layoutContext.setActiveMenu('');
  }, [layoutContext, t]);

  useEffect(() => {
    if (workoutId && userId) {
      getLeaderboard();
    }
  }, [workoutId, userId]);

  return (
    <Dialog
      style={{ marginTop: 100 }}
      fullScreen
      TransitionComponent={Transition}
      open={open}
      PaperProps={{ style: { backgroundColor: secondaryBgModal } }}
      onClose={close}
    >
      <AppBar color="secondary" className="tw-relative">
        <Toolbar>
          <Typography variant="h6" className="tw-flex-1">
            {t('button.judge')}
          </Typography>

          <IconButton edge="start" color="inherit" onClick={close} aria-label="close">
            <Close />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Container maxWidth="xl" className="tw-pt-2">
        <LoaderSm loading={loading} />
        {workout?.id && leaderboard && (
          <JudgeTeam
            refreshLeaderboard={getLeaderboard}
            workout={workout}
            competition={competition}
            leaderboard={leaderboard}
          />
        )}
      </Container>
    </Dialog>
  );
};

export default ModalJudgeTeam;
