import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import AccountShow from 'components/Profile/Dashboard/Account/Show';
import { sessionActions } from 'modules/session';
import Box from 'core/ui/Box';

export default () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(sessionActions.authenticateStart());
  }, [dispatch]);

  return (
    <Box>
      <AccountShow />
    </Box>
  );
};
