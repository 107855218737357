import React from 'react';
import Box from 'core/ui/Box';
import Typography from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import { primary } from 'core/ui/Colors';
import { ButtonPrimary } from 'core/ui/Button';

export default ({ confirmStartAgain, buttonColor }) => {
  const { t } = useTranslation();
  return (
    <Box>
      <Box px={10} mt={5} mb={10}>
        <Typography variant="h5" color="secondary">
          {t('workout.competition.startAgainMessege')}
        </Typography>
        <Box my={10}>
          <Typography variant="h5" color="secondary">
            {t('workout.competition.startAgainConfirm')}
          </Typography>
        </Box>

        <ButtonPrimary buttonColor={buttonColor || primary} onClick={confirmStartAgain}>
          {t('button.continue').toUpperCase()}
        </ButtonPrimary>
      </Box>
    </Box>
  );
};
