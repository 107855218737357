import React, { createContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from 'core/ui/Box';
import { competitionsSearchActions, competitionsSearchSelectors } from 'modules/competitionsSearch';
import { timeZoneName } from 'core/utils/formatters/date';
import Results from './Results';
import Header from './Header';
import Recents from './Recents';
import Upcoming from './Upcoming';
import Current from './Current';
import MyCompetitions from './MyCompetitions';

export const SearchCompetitionContext = createContext();

export default () => {
  const results = useSelector((state) => competitionsSearchSelectors.getSearchData(state));
  const queryData = useSelector((state) => competitionsSearchSelectors.getQueryData(state));
  const loading = useSelector((state) => competitionsSearchSelectors.getLoading(state));
  const dispatch = useDispatch();

  const [isSearch, setIsSearch] = useState(false);
  const perPage = 8;

  const search = (page) => {
    if (queryData) {
      setIsSearch(true);
      dispatch(
        competitionsSearchActions.getSearchStart({
          query: queryData,
          timezone: timeZoneName(),
          page,
          perPage,
        }),
      );
    } else {
      setIsSearch(false);
      dispatch(competitionsSearchActions.clearResults());
    }
  };

  return (
    <Box>
      <Header search={search} />

      {isSearch ? (
        <Results changePage={(p) => search(p)} results={results} loading={loading} />
      ) : (
        <>
          <MyCompetitions />
          <Current />
          <Upcoming />
          <Recents />
        </>
      )}
    </Box>
  );
};
