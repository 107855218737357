import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Typography from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import GridSkeleton from 'core/ui/GridSkeleton';
import Box from 'core/ui/Box';
import Paginator from 'core/ui/Paginator';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import useSelectors from 'modules/map/useSelectors';
import { workoutsUsersModule } from 'modules/workoutsUsers';
import SFDataTable from 'core/ui/DataTable';
import { date, dateExt, timeWithoutTz } from 'core/utils/formatters';
import { Check, OpenInNew } from '@material-ui/icons';
import { primary } from 'core/ui/Colors';
import Close from '@material-ui/icons/Close';
import useConfirmation from 'core/useConfirmation';
import { channelMembersActions } from 'modules/channelMembers';
import { useDispatch } from 'react-redux';
import Button from 'core/ui/Button';
import notifications from 'modules/notifications';
import { Link, Tooltip } from '@material-ui/core';

const MemberWorkoutsList = ({ user, channel, limit, showTitle, upcoming }) => {
  const { request } = useActions();
  const { t } = useTranslation();

  const loading = useSelectors(workoutsUsersModule, 'loading');
  const workouts = useSelectors(workoutsUsersModule, 'workouts');
  const paginationWorkouts = useSelectors(workoutsUsersModule, 'paginationWorkouts');

  const dispatch = useDispatch();
  const { confirmation } = useConfirmation();

  const getData = (p) => {
    if (user.id) {
      request({
        action: Modules.workoutsUsers.actions.getUserWorkouts,
        data: {
          userId: user?.id,
          page: p,
          perPage: limit,
          channelId: channel?.id,
          upcoming,
        },
      });
    }
  };

  const checkInUser = (workoutId) => {
    request({
      action: Modules.newWorkouts.actions.checkInUsers,
      data: {
        workoutId,
        userId: user?.id,
      },
      options: {
        onSuccess: () => {
          getData(1);
        },
        onError: (error) => {
          dispatch(notifications.error(error));
        },
      },
    });
  };

  const onUnRegisterClick = (workoutId) => {
    confirmation({
      description: t('button.unregister.confirm'),
      yesClick: () => {
        dispatch(
          channelMembersActions.memberUnregisterClassStart(channel?.id, user?.id, workoutId, () => {
            getData(1);
          }),
        );
      },
    });
  };

  useEffect(() => {
    if (user.id) {
      getData(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request, user, limit, upcoming]);

  const changePage = (p) => {
    if (p <= paginationWorkouts.totalPages) {
      getData(p);
    }
  };

  const columns = [
    {
      name: 'Workout',
      selector: 'name',
      cell: (workout) => (
        <Typography variant="caption">
          <Link target="_blank" href={`/workout/view/${workout.slug}`}>
            <OpenInNew style={{ fontSize: 15 }} />
            {workout.name || ''}
          </Link>
        </Typography>
      ),
    },
    {
      name: 'Membership',
      align: 'center',
      selector: (row) => row?.channelKey?.membershipName,
      cell: (workout) => (
        <Tooltip title={`Key: ${workout?.channelKey?.id}`}>
          <div>
            <Typography variant="caption">{workout?.channelKey?.membershipName}</Typography>
          </div>
        </Tooltip>
      ),
    },
    {
      name: 'Checkin',
      selector: 'checkIn',
      cell: (workout) => (
        <Typography>
          {workout?.checkIn ? (
            <Check style={{ color: primary, fontSize: 14 }} />
          ) : (
            <Close style={{ fontSize: 14 }} />
          )}
        </Typography>
      ),
    },
    {
      name: 'Day',
      selector: 'scheduledAt',
      sortable: true,
      cell: (workout) => (
        <Typography variant="caption" component="span">
          {date(workout?.scheduledAt, 'dddd')}
        </Typography>
      ),
    },
    {
      name: 'Date/Time',
      selector: 'scheduledAt',
      sortable: true,
      cell: (workout) => (
        <Box display="flex" alignItems="center">
          <Typography variant="caption" component="span">
            {dateExt(workout?.scheduledAt, t, channel?.dateFormatExtReact)} &bull;
          </Typography>
          <Typography variant="caption" component="span" ml={1}>
            {timeWithoutTz(workout?.scheduledAt)}
          </Typography>
        </Box>
      ),
    },
    {
      name: 'Actions',
      selector: '',
      cell: (workout) => (
        <Box display="flex">
          <Box mr={2}>
            <Button onClick={() => checkInUser(workout?.id)}>
              {t(workout?.checkIn ? 'button.checkedIn' : 'button.checkIn')}
            </Button>
          </Box>

          <Button onClick={() => onUnRegisterClick(workout?.id)}>{t('button.remove')}</Button>
        </Box>
      ),
    },
  ];

  return (
    <>
      {loading && <GridSkeleton />}
      {!loading && (
        <>
          {showTitle && workouts && workouts.length > 0 && (
            <Typography mb={3} color="primary" variant="h6">
              {t('section.title.attending')}
            </Typography>
          )}

          <SFDataTable
            noHeader
            columns={columns}
            data={workouts}
            showExport={false}
            showPaper={false}
            paginationPerPage={10}
            onChangePage={(p) => changePage(p)}
            paginationServer
            paginationDefaultPage={paginationWorkouts?.currentPage}
            paginationTotalRows={paginationWorkouts?.totalCount}
            onChangeRowsPerPage={(newValue) => {
              if (changePage) {
                changePage(newValue);
              }
            }}
            paginationRowsPerPageOptions={[10]}
          />

          {paginationWorkouts && paginationWorkouts.totalPages > 1 && (
            <Box flexGrow={1} m={3} display="flex" justifyContent="center">
              <Paginator pagination={paginationWorkouts} changePage={(p) => changePage(p)} />
            </Box>
          )}
        </>
      )}
    </>
  );
};

MemberWorkoutsList.propTypes = {
  limit: PropTypes.number,
  showTitle: PropTypes.bool,
};

MemberWorkoutsList.defaultProps = {
  limit: isMobile ? 4 : 10,
  showTitle: false,
};

export default MemberWorkoutsList;
