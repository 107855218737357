import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  container: {
    position: 'relative',
    backgroundColor: '#404041',
    paddingBottom: 16,
  },
  listEmptyComponent: {
    display: 'flex',
    flex: 1,
    padding: 16,
    alignItems: 'center',
    justifyContent: 'center',
  },
  listEmptyComponentLoader: {
    color: 'white',
  },
  listEmptyComponentText: {
    color: 'white',
    textAlign: 'center',
  },
});
