import React, { useState } from 'react';
import Box from 'core/ui/Box';
import Grid from 'core/ui/Grid';
import StoreCategories from 'components/Store/Categories';
import StoreProducts from 'components/Store/Products';
import Integrations from 'components/Integrations';
import StoreOptions from 'components/Store/Options';

import { StoreContext } from 'contexts';
import StoreDiscountCodes from 'components/Store/DiscountCodes';
import ModalImportInventory from './ModalImportInventory/Modal';

const PartnerStore = ({ partner }) => {
  const [refresh, setRefresh] = useState(false);
  return (
    <StoreContext.Provider
      value={{
        taxByCounty: partner?.taxByCounty,
        taxPercent: partner?.taxPercent,
        currency: partner?.currency,
        hasShopify: partner?.hasShopify,
        refreshProducts: refresh,
      }}
    >
      <>
        <Integrations ownerableId={partner?.id} ownerableType="Partner" />
        <Grid container spacing={3} mt={3}>
          <Grid item xs={12} md={6}>
            <Box>
              <StoreCategories ownerableType="Partner" ownerableId={partner?.id} />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box mb={3}>
              <StoreOptions
                currency={partner?.currency}
                ownerableType="Partner"
                ownerableId={partner?.id}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box mb={3}>
              <StoreDiscountCodes
                currency={partner?.currency}
                ownerableType="Partner"
                ownerableId={partner?.id}
              />
            </Box>
          </Grid>
        </Grid>

        <Box mt={3}>
          {!!partner?.id && (
            <StoreProducts
              currency={partner?.currency}
              ownerableType="Partner"
              ownerableId={partner?.id}
              taxPercent={partner?.taxPercent}
              hasShopifyIntegration={partner?.hasShopify}
              customerAmounts={{
                type: partner.amounts?.customerAmountType,
                amount: partner.amounts?.customerAmount,
              }}
              refreshProducts={refresh}
              inventoryActions={
                <>
                  {/* {partner.companyName === 'inov-8' && ( */}
                  <ModalImportInventory partner={partner} callback={() => setRefresh(!refresh)} />
                  {/* )} */}
                </>
              }
              allowChangeAmounts={partner?.allowChangeAmounts}
            />
          )}
        </Box>
      </>
    </StoreContext.Provider>
  );
};

export default PartnerStore;
