import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import Typography from 'core/ui/Typography';
import Paper from 'core/ui/Paper';
import Children from 'components/Profile/Dashboard/Account/Children';
import LoaderSm from 'core/ui/LoaderSm';
import { workoutsActions } from 'modules/workouts';
import { useDispatch } from 'react-redux';
import { timeZoneName } from 'core/utils/formatters/date';
import ButtonsRegister from './ButtonsRegister';
import useRegisterStatus from './useRegisterStatus';
import UserChannelKeys from './UserChannelKeys';

export default function ChildrenRegister({ workout, close }) {
  const { t } = useTranslation();
  const [child, setChild] = useState(null);
  const dispatch = useDispatch();
  const [pendingConfirm, setPendingConfirm] = useState(null);

  const { getRegisterStatus, clearRegisterStatus, registerStatus, loading } = useRegisterStatus({
    workout,
  });

  const hasListOrKey =
    registerStatus &&
    (!!registerStatus?.hasOnlineKey ||
      !!registerStatus?.hasInPersonKey ||
      !!registerStatus?.hasList);

  useEffect(() => {
    if (child && child.id) {
      getRegisterStatus(child);
    } else {
      clearRegisterStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workout, child]);

  const purchase = (type, workoutId) => {
    const confirm = () => {
      dispatch(
        workoutsActions.purchaseStart(
          {
            workoutId,
            childId: child.id,
            timezone: timeZoneName(),
            type,
            messageSuccess: t('purchase.workout.success'),
          },
          () => {
            setPendingConfirm(null);
            setChild(null);
            dispatch(workoutsActions.getWorkoutStart(workout.id));
            close();
          },
        ),
      );
    };

    if (hasListOrKey) {
      setPendingConfirm(null);
      confirm();
    } else {
      setPendingConfirm({ type, workoutId });
    }
  };

  return (
    <div>
      <Box>
        <Typography mb={3} color="primary" align="center" variant="h5">
          {t('user.children').toUpperCase()}
        </Typography>
        <Paper className="paper-rounded" p={3}>
          <Children setChildren={(c) => setChild(c)} />
        </Paper>
      </Box>

      {loading && !registerStatus && (
        <Box display="flex" justifyContent="center" p={5}>
          <LoaderSm loading />
        </Box>
      )}

      {!!child &&
        !loading &&
        !registerStatus?.registered &&
        !registerStatus?.hasInPersonKey &&
        !registerStatus?.hasOnlineKey &&
        !registerStatus?.hasList && (
          <Typography align="center" variant="h3" m={8}>
            {t('workout.register.msg')}
          </Typography>
        )}

      {!!child && !loading && !pendingConfirm && registerStatus && (
        <ButtonsRegister purchase={purchase} workout={workout} registerStatus={registerStatus} />
      )}

      <Box>
        {!loading && !!child && (
          <UserChannelKeys keys={registerStatus?.channelKeys || []} workout={workout} />
        )}
      </Box>
    </div>
  );
}
