import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import UserIcon from '@material-ui/icons/Person';
import Slide from '@material-ui/core/Slide';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import Texture from 'core/ui/Texture';
import Scrollbars from 'react-custom-scrollbars';
import { ButtonPrimary } from 'core/ui/Button';
import { useSelector } from 'react-redux';
import { sessionSelectors } from 'modules/session';
import Import from './Import';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const ModalImportBenchmarksWorkouts = ({ refresh, type }) => {
  const user = useSelector((state) => sessionSelectors.getUser(state));
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const close = () => {
    refresh();
    setOpen(false);
  };

  return (
    <>
      <ButtonPrimary onClick={() => setOpen(true)}>{t('button.import')}</ButtonPrimary>
      <Dialog
        style={{ marginTop: 100 }}
        fullScreen
        open={open}
        onClose={() => close()}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar} color="secondary">
          <Toolbar>
            <UserIcon />
            <Typography variant="h6" className={classes.title}>
              {t('title.importBenchmarksAndWorkouts')}&nbsp;
              {user.name}
            </Typography>
            <IconButton edge="start" color="inherit" onClick={() => close()} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <Scrollbars allowFullScreen>
          <Texture style={{ minHeight: '100%' }}>
            <Box>
              <Import close={close} type={type} />
            </Box>
          </Texture>
        </Scrollbars>
      </Dialog>
    </>
  );
};

ModalImportBenchmarksWorkouts.propTypes = {};

ModalImportBenchmarksWorkouts.defaultProps = {};

export default ModalImportBenchmarksWorkouts;
