import React, { Suspense } from 'react';
import Box from 'core/ui/Box';
import Divider from 'core/ui/Divider';
import Header from './Components/Header';
import { getInfoComponent } from './getInfoComponent';

const ExecutionInfo = ({ channelId, executionsTypesUser }) => {
  const automationTypeFunction =
    executionsTypesUser?.execution?.workflowsType?.automationType?.function;

  const Component = getInfoComponent(automationTypeFunction);

  return (
    <Box mb={2} className="gap-10" display="flex" flexDirection="column" px={3} pt={3}>
      <Header channelId={channelId} executionsTypesUser={executionsTypesUser} />

      <Divider />

      <Suspense fallback={<div>Loading...</div>}>
        <Component
          key={automationTypeFunction}
          channelId={channelId}
          executionsTypesUser={executionsTypesUser}
        />
      </Suspense>
    </Box>
  );
};

export default ExecutionInfo;
