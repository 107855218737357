/* eslint-disable react/prop-types */
import React from 'react';

import moment from 'moment';
import Paper from 'core/ui/Paper';
import { useTranslation } from 'react-i18next';
import Typography, { fontStyle } from 'core/ui/Typography';
import SFDataTable from 'core/ui/DataTable';
import {
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
} from '@material-ui/core';
import { channelCurrency, dateExt, time } from 'core/utils/formatters';
import Box from 'core/ui/Box';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { secondaryBgLight } from 'core/ui/Colors';
import PieCoachesChart from './PieCoachesChart';

export default function ByClassData({ title, channel, byClassData, isCoachView }) {
  const { t } = useTranslation();
  const columns = [
    {
      name: 'Coach',
      selector: 'coachName',
      sortable: true,
      cell: (row) => <Typography style={fontStyle.small}>{row?.coachName}</Typography>,
    },
    {
      name: 'Classes',
      sortable: true,
      selector: 'countWorkouts',
      cell: (row) => <Typography style={fontStyle.small}>{row.countWorkouts}</Typography>,
    },
    {
      name: 'In-Person',
      sortable: true,
      selector: 'countInPersonUsers',
      cell: (row) => <Typography style={fontStyle.small}>{row.inPersonUsers}</Typography>,
    },
    {
      name: 'Online',
      sortable: true,
      selector: 'countOnlineUsers',
      cell: (row) => <Typography style={fontStyle.small}>{row.onlineUsers}</Typography>,
    },
    {
      name: 'Total Users',
      selector: 'totalUsers',
      sortable: true,
      cell: (row) => (
        <Typography style={fontStyle.small}>
          {Number(row.onlineUsers) + Number(row.inPersonUsers)}
        </Typography>
      ),
    },
    {
      name: 'Total Amount',
      selector: 'amount',
      sortable: true,
      cell: (row) => (
        <Typography noWrap style={fontStyle.small}>
          {channelCurrency(row?.amount, channel?.currency)}
        </Typography>
      ),
    },
  ];

  const ExpanableComponent = ({ data: dataExpanded }) => (
    <Box p={5}>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>{t('workout.column.name')}</TableCell>
              <TableCell>{t('workout.column.scheduled')}</TableCell>
              <TableCell align="right">{t('channel.coach.rate')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataExpanded.rates &&
              dataExpanded.rates.map((rate, index) => (
                <TableRow key={index.toString()}>
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      <Typography mr={1}>{rate.workoutName}</Typography>
                      <Link target="_blank" href={`/workout/view/${rate.workoutId}`}>
                        <OpenInNewIcon style={{ fontSize: 15 }} />
                      </Link>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      <ScheduleIcon style={{ fontSize: 18 }} />
                      <Typography color="primary" ml={1} component="span" variant="body1">
                        <span>{moment(rate.workoutScheduledAt).format('dddd')} </span>
                        <span>
                          {dateExt(rate.workoutScheduledAt, null)}
                        </span>
                        <span>&nbsp;&bull;&nbsp;</span>
                        <span className="roboto-regular">{time(rate.workoutScheduledAt)}</span>
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell align="right">
                    <Typography noWrap>
                      {channelCurrency(rate?.amount, channel?.currency)}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );

  return (
    <Paper
      style={{ width: '100%', border: isCoachView ? `solid 1px ${secondaryBgLight}` : 'none' }}
      py={2}
    >
      <Box px={3}>
        <Typography variant="h5">{title}</Typography>
      </Box>
      {!isCoachView && (
        <Box mb={3} display="flex" justifyContent="center">
          <PieCoachesChart data={byClassData?.data} />
        </Box>
      )}
      <SFDataTable
        columns={columns}
        data={byClassData?.data}
        expandableRows
        expandableRowsComponent={<ExpanableComponent />}
        exportData={byClassData?.data?.map((coach) => ({
          id: coach.coachId,
          name: coach.coachName,
          countWorkouts: coach.countWorkouts,
          totalUsers: Number(coach.onlineUsers) + Number(coach.inPersonUsers),
          totalAmount: channelCurrency(coach?.amount, channel?.currency),
          onlineUsers: coach.onlineUsers,
          inPersonUsers: coach.inPersonUsers,
        }))}
        showExport
        showPaper={false}
      />
      <Box display="flex" justifyContent="flex-end">
        <Paper p={3} mt={3}>
          <Typography variant="h4">
            Total:&nbsp;{channelCurrency(byClassData?.total, channel?.currency)}
          </Typography>
        </Paper>
      </Box>
    </Paper>
  );
}
