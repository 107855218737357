import React, { useEffect, useState } from 'react';
import Box from 'core/ui/Box';
import Paper from 'core/ui/Paper';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import LoaderSm from 'core/ui/LoaderSm';
import Typography from 'core/ui/Typography';
import { Collapse } from '@material-ui/core';
import Texture from 'core/ui/Texture';
import Divider from 'core/ui/Divider';
import { KeyboardArrowDown, KeyboardArrowRight, Search } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import useConfirmation from 'core/useConfirmation';
import SectionScoresDetails from './SectionScoresDetails';
import PersonalRecordList from '../PersonalRecord/List';
import BenchmarkList from '../Benchmark/List';

const HistoryWorkoutScoresDetails = ({ workout, section, actions }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(false);
  const { confirmation } = useConfirmation();
  const [searchMovements, setSearchMovements] = useState(true);
  const { request } = useActions();
  const [personalRecord, setPersonalRecord] = useState(null);
  const [benchmarkSection, setBenchmarkSection] = useState(null);

  const benchmark = section?.benchmarkSection;

  const personalRecords = section.scores
    .filter((x) => x.personalRecord?.id)
    .map((item) => item.personalRecord);

  const getHistoryScore = () => {
    setLoading(true);
    request({
      action: Modules.usersHistoryScores.actions.getCompareHistory,
      data: {
        workoutId: workout?.id,
        sectionId: section?.id,
        personalRecordId: personalRecord?.id,
        benchmarkSectionId: benchmarkSection?.id,
      },
      options: {
        onSuccess: (resp) => {
          setData(resp);
          setLoading(false);
        },
        onError: () => {
          setData(null);
          setLoading(false);
        },
      },
    });
  };

  useEffect(() => {
    if (!section?.id || !workout?.id) {
      setData(null);
      return;
    }
    if (personalRecord || benchmarkSection) {
      getHistoryScore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [section, personalRecord, benchmarkSection]);

  const removeScore = (item) => {
    confirmation({
      description: t('areYouSure'),
      yesClick: () => {
        if (item.objectType === 'WorkoutSectionUser') {
          request({
            action: Modules.newWorkouts.actions.deleteUserScore,
            data: {
              workoutId: workout?.id,
              sectionId: section?.id,
              workoutSectionUserId: item?.id,
            },
            options: {
              onSuccess: () => {
                getHistoryScore();
              },
            },
          });
        }
        if (item.objectType === 'BenchmarkSectionUser') {
          request({
            action: Modules.benchmarkSections.actions.deleteBenchmarkSectionUser,
            data: { benchmarkSectionUserId: item?.id },
            options: {
              onSuccess: () => {
                getHistoryScore();
              },
            },
          });
        }
        if (item.objectType === 'PersonalRecordUser') {
          request({
            action: Modules.personalRecordMovements.actions.deletePrHistory,
            data: { personalRecordUserId: item?.id },
            options: {
              onSuccess: () => {
                request({ action: Modules.personalRecordMovements.actions.getPrMovements });
                getHistoryScore();
              },
            },
          });
        }
      },
    });
  };

  return (
    <Texture>
      <Box p={4}>
        <Paper className="paper-rounded" p={3} mb={3}>
          <Box display="flex">
            <Box flexGrow={1}>
              <Typography variant="h5">{section.title} Scores</Typography>
            </Box>
          </Box>
        </Paper>

        <Paper p={3}>
          <Box>
            {benchmark?.id ? (
              <Box
                p={3}
                className="hover"
                onClick={() => {
                  setBenchmarkSection(benchmark);
                  setSearchMovements(false);
                }}
              >
                <Typography>{benchmark?.name}</Typography>
              </Box>
            ) : (
              <>
                {personalRecords?.map((pr, idx) => (
                  <Box key={pr?.id}>
                    <Box
                      p={3}
                      className="hover"
                      onClick={() => {
                        setPersonalRecord(pr);
                        setSearchMovements(false);
                      }}
                    >
                      <Typography>{pr?.movementName}</Typography>
                    </Box>
                    {personalRecords?.length - 1 > idx && <Divider />}
                  </Box>
                ))}
              </>
            )}
            <Divider m={2} />
            <Box
              p={3}
              display="flex"
              alignItems="center"
              className="hover"
              onClick={() => setSearchMovements(!searchMovements)}
            >
              <Box flexGrow={1}>
                <Box display="flex" alignItems="center" className="gap-10">
                  <Search />
                  <Typography>{t('label.search')}</Typography>
                </Box>
                <Box ml={8}>
                  {benchmark?.id ? (
                    <Typography style={{ fontSize: 12 }} color="textSecondary">
                      {benchmarkSection?.name}
                    </Typography>
                  ) : (
                    <Typography style={{ fontSize: 12 }} color="textSecondary">
                      {personalRecord?.movement?.name || personalRecord?.movementName}
                    </Typography>
                  )}
                </Box>
              </Box>

              <>
                {searchMovements ? (
                  <KeyboardArrowDown style={{}} />
                ) : (
                  <KeyboardArrowRight style={{}} />
                )}
              </>
            </Box>
          </Box>
          <Collapse in={searchMovements}>
            {benchmark?.id ? (
              <BenchmarkList
                selector
                setSelectedBenchmark={(bh) => {
                  setBenchmarkSection(bh);
                  setSearchMovements(false);
                }}
              />
            ) : (
              <PersonalRecordList
                isSelector
                setSelectedPr={(pr) => {
                  setPersonalRecord(pr);
                  setSearchMovements(false);
                }}
              />
            )}
          </Collapse>
        </Paper>

        <LoaderSm center loading={loading} />

        {benchmark?.id ? (
          <Typography variant="h3" m={3} align="center">
            {benchmarkSection?.name}
          </Typography>
        ) : (
          <Typography variant="h3" m={3} align="center">
            {personalRecord?.movement?.name || personalRecord?.movementName}
          </Typography>
        )}
        <SectionScoresDetails
          section={section}
          workout={workout}
          data={data}
          setModalScore={actions.setModalScore}
          removeScore={removeScore}
        />
      </Box>
    </Texture>
  );
};

export default HistoryWorkoutScoresDetails;
