import { Dialog } from '@material-ui/core';
import Box from 'core/ui/Box';
import { ButtonPrimary } from 'core/ui/Button';
import Texture from 'core/ui/Texture';
import Typography from 'core/ui/Typography';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormDataLocations from './FormData';
import ListLocations from './List';

export default () => {
  const { t } = useTranslation();
  const [openModalForm, setOpenForm] = useState(null);
  const [itemEdit, setItemEdit] = useState(null);

  const onEditClick = (item) => {
    setOpenForm(true);
    setItemEdit(item);
  };

  return (
    <Box py={5}>
      <Box display="flex" alignItems="flex-start">
        <Box flexGrow={1}>
          <Typography>{t('channel.locations.title')}</Typography>
          <Typography className="roboto-regular">{t('channel.locations.description')}</Typography>
        </Box>
        <Box style={{ minWidth: 110 }}>
          <ButtonPrimary onClick={() => setOpenForm(true)}>{t('button.addLocation')}</ButtonPrimary>
        </Box>
      </Box>

      <Dialog maxWidth="sm" fullWidth open={!!openModalForm} onClose={() => setOpenForm(false)}>
        <Texture>
          <Box p={5}>
            <FormDataLocations
              itemEdit={itemEdit}
              callback={() => {
                setItemEdit(null);
                setOpenForm(false);
              }}
            />
          </Box>
        </Texture>
      </Dialog>

      <Box mt={3}>
        <ListLocations onEditClick={onEditClick} />
      </Box>
    </Box>
  );
};
