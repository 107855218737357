import React from 'react';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';

import Checkbox from 'core/ui/Checkbox';
import HideUntilDate from './HideUntilDate';

// eslint-disable-next-line react/prop-types
export default ({ setValue, watch, bordered }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Checkbox
        checked={!!watch('allowPublicPostScores')}
        onClick={() => setValue('allowPublicPostScores', !watch('allowPublicPostScores'))}
        label={t('workout.label.allow_public_post_scores')}
      />

      <Box my={3}>
        <HideUntilDate watch={watch} setValue={setValue} bordered={bordered} />
      </Box>
      <Box mb={3}>
        <Checkbox
          checked={!!watch('hideSections')}
          onClick={() => setValue('hideSections', !watch('hideSections'))}
          label={t('workout.section.hideToUnregistered')}
        />
      </Box>
      <Checkbox
        checked={!!watch('hideOnCalendarNonMembers')}
        onClick={() => setValue('hideOnCalendarNonMembers', !watch('hideOnCalendarNonMembers'))}
        label={t('workout.section.hideOnCalendarNonMembers')}
      />
    </Box>
  );
};
