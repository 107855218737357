/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Typography from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import { InputLabel } from '@material-ui/core';
import AWS from 'aws-sdk';
import uuid from 'react-uuid';
import LinearProgress from 'core/ui/LinearProgress';
import { ENV_APP } from 'core/env';
import { ButtonWhite } from 'core/ui/Button';
import Box from 'core/ui/Box';
import { Publish } from '@material-ui/icons';
import { B_ID } from 'core/utils/consts';
import { mKY } from 'core/utils/helpers';

const B_K = mKY();

const bucket = new AWS.S3({
  accessKeyId: B_ID,
  secretAccessKey: B_K,
  region: 'us-east-2',
});

export default function S3Upload({ channelId, userId, setUrl, buttonColor }) {
  const { t } = useTranslation();
  const [fileUpload, setFileUpload] = useState(null);
  const PATH_KEY = `uploads/${ENV_APP}/Channel/${channelId}/UsersDocuments/${userId}/`;

  const [progressUpload, setProgressUpload] = useState(0);

  const uploadfile = (fileName, file) => {
    const params = {
      Bucket: 'stream-fitness-app-uploads',
      Key: PATH_KEY + fileName,
      Body: file,
      ContentType: file.type,
    };

    return bucket.upload(params, (err, data) => {
      if (err) {
        console.log('There was an error uploading your file: ', err);
        return false;
      }

      setUrl(data.Location);
      setFileUpload(null);
      return true;
    });
  };

  const startUpload = (file) => {
    const uniqueFileName = `streamfitness-channel-user-document-${uuid()}-${file.name}`;

    uploadfile(uniqueFileName, file).on('httpUploadProgress', (progress) => {
      const progressPercentage = Math.round((progress.loaded / progress.total) * 100);
      setProgressUpload(progressPercentage);
    });
  };

  const handleCapture = ({ target }) => {
    if (target.files.length) {
      for (let i = 0; i < target.files.length; i += 1) {
        const f = target.files[i];
        setFileUpload(f);
        startUpload(f);
      }
    }
  };

  const randomId = Math.floor(Math.random() * 101);

  return (
    <>
      <Box display="flex" justifyContent="center">
        <ButtonWhite variant="contained" p={1}>
          <InputLabel style={{ color: buttonColor }} htmlFor={`icon-button-file-${randomId}`}>
            <Box display="flex" alignItems="center">
              <Publish style={{ color: buttonColor }} size="small" />
              <Typography ml={3} variant="subtitle2">
                {(fileUpload && fileUpload.name) || t('button.selectFile')}
              </Typography>
            </Box>
          </InputLabel>
        </ButtonWhite>
        <input
          color="primary"
          type="file"
          onChange={handleCapture}
          id={`icon-button-file-${randomId}`}
          style={{ display: 'none' }}
          accept=".pdf"
          multiple
        />
      </Box>

      {fileUpload && (
        <Typography align="center" ml={3} color="primary" variant="h4">
          {fileUpload.name}
        </Typography>
      )}
      <Box my={3}>
        <LinearProgress
          style={{ height: 15, width: '100%', borderRadius: 5 }}
          value={progressUpload || 0}
        />
      </Box>
    </>
  );
}
