/* eslint-disable no-use-before-define */
import React from 'react';
import PropTypes from 'prop-types';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import NoSsr from '@material-ui/core/NoSsr';
import { InputBase, Popper } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { equipmentSelectors, equipmentActions } from 'modules/equipment';

import Box from '../Box';
import { InputWrapper, Tag, Listbox } from '../ChosenComponents';

const styleBoxList = {
  maxHeight: 250,
  backgroundColor: '#fff',
  padding: 10,
  borderRadius: '20px 20px 20px 20px',
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
  zIndex: 9999,
};

export default function EquipmentChosen({
  setValue,
  error,
  tag,
  name,
  val,
  limit,
  placeholder,
  defaultValue,
  placement,
}) {
  const change = (evt, data) => {
    setValue(data);
  };
  const wRef = React.createRef();
  const dispatch = useDispatch();

  const equipment = useSelector((state) => equipmentSelectors.getEquipmentAutocomplete(state));
  const searchEquipment = (event) => {
    if (event) {
      dispatch(equipmentActions.getEquipmentAutocompleteStart({ query: event.target.value }));
    }
  };

  const {
    getRootProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    value,
    focused,
    setAnchorEl,
    inputValue,
  } = useAutocomplete({
    defaultValue: [...defaultValue],
    value: val,
    multiple: true,
    // groupBy: (option) => option,
    freeSolo: true,
    options: [...equipment],
    // value: defaultValue,
    onChange: change,
    getOptionLabel: (option) => option.name,
    onInputChange: searchEquipment,
  });

  const input = getInputProps();

  const set = (option) => {
    setValue([...val, option]);
  };

  const handleBlur = () => {
    dispatch(equipmentActions.clearEquipmentAutocomplete());
    input.onBlur();
    input.onChange({ target: { value: '' } });
  };

  return (
    <NoSsr>
      <div>
        <div {...getRootProps()}>
          <InputWrapper ref={wRef} className={focused ? 'focused' : ''}>
            {value.map((option, index) => (
              <Tag label={option.name || option} {...getTagProps({ index })} />
            ))}
            <Box display="flex" flexGrow="1" ref={setAnchorEl} alignItems="center">
              <InputBase
                style={{ minWidth: 30, width: '100%' }}
                name={name}
                className={`${limit > 0 && value.length < limit ? '' : 'hide'} fontInput`}
                placeholder={value.length <= 0 ? placeholder : ''}
                {...getInputProps()}
                onBlur={handleBlur}
              />
            </Box>
          </InputWrapper>
        </div>
        {inputValue && equipment.length > 0 ? (
          <Popper
            open
            anchorEl={input.ref.current}
            placement={placement}
            role={undefined}
            transition
            disablePortal
            style={{ zIndex: 9999 }}
          >
            <Box
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
              style={{ ...styleBoxList }}
            >
              <Listbox {...getListboxProps()}>
                {equipment.map((option, index) => (
                  <Box key={index.toString()} onClick={() => set(option)}>
                    <span>{option.name}</span>
                  </Box>
                ))}
              </Listbox>
            </Box>
          </Popper>
        ) : null}
      </div>
    </NoSsr>
  );
}

EquipmentChosen.propTypes = {
  setValue: PropTypes.func,
  limit: PropTypes.number,
  placeholder: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  error: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  defaultValue: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  val: PropTypes.array,
  tag: PropTypes.string,
  placement: PropTypes.string,
};

EquipmentChosen.defaultProps = {
  setValue: null,
  placeholder: '',
  error: {},
  defaultValue: [],
  limit: 0,
  val: [],
  tag: '',
  placement: 'bottom-start',
};
