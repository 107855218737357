import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import Grid from 'core/ui/Grid';
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { ButtonPrimary } from 'core/ui/Button';
import Typography, { fontStyle } from 'core/ui/Typography';
import LoaderSm from 'core/ui/LoaderSm';
import ConfirmDialog from 'core/ui/ConfirmDialog';
import Divider from 'core/ui/Divider';
import ViewWorkoutDetail from 'components/Channel/Manage/Tabs/Library/ClassHistory/ViewWorkoutDetail';
import Checkbox from 'core/ui/Checkbox';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import { WorkoutContext } from './index';
import Movements from './Movements';
import Equipments from './Equipments';
import Coaches from './Coaches';
import InfoChannel from './InfoChannel';
import InfoRecord from './InfoRecord';
import Sections from './Section/Sections';
import ClassSettings from './ClassSettings/index';
import InstructionsComp from './InstructionsComp';
import ClassOverview from './ClassOverview';
import Schedule from './Schedule';
import CompDivisions from './ClassSettings/CompDivisions';
import CloneContent from './CloneContent';
import BenchmarkOptions from './Section/BenchmarkOptions';
import Invites from './Invites';
import PictureDay from './PictureDay';
import FormHeats from './Heats';
import MembershipAccess from './ClassSettings/MembershipAccess';
import OnlineSetting from './ClassSettings/OnlineSetting';
import InPersonSetting from './ClassSettings/InPersonSetting';
import Users from './Users';
import HistoryOptions from './Section/HistoryOptions';
import SectionsSettings from './SectionsSettings';
import ModalFormSection from './Section/Modal';
import HideUntilDate from './Section/HideUntilDate';
import CompWorkoutVolunteers from './CompVolunteers';

const useStyles = makeStyles((theme) => ({
  media: {
    paddingTop: '46.25%',
    backgroundColor: '#bbbdbf',
    maxHeight: 100,
    backgroundSize: '100% 100%',
  },
  boxSelImage: {
    position: 'absolute',
    marginTop: -30,
  },
  content: {
    position: 'relative',
    backgroundColor: '#fafafa',
    borderRadius: 10,
  },
  boxButtons: {
    margin: 0,
    top: 'auto',
    right: 30,
    bottom: 15,
    left: 'auto',
    position: 'fixed',
  },
  button: {
    boxShadow: '0px 3px 5px 0px rgba(0,0,0,0.4)',
    borderRadius: 5,
    height: 38,
    width: 110,
    margin: 2,
    [theme.breakpoints.down('sm')]: {
      bottom: '50px',
    },
  },
}));

export default () => {
  const { t } = useTranslation();
  const formContext = useContext(WorkoutContext);
  const classes = useStyles();
  const [confirmCancel, setConfirmCancel] = useState({ open: false });
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const mbSections = 3;
  const paddingSections = 3;

  useEffect(() => {
    if (!formContext.loading && !formContext.selectedChannel) {
      // formContext.setModalChannel(true);
    } else {
      if (formContext.mode !== 'edit' && formContext.type === 'customClass') {
        formContext.setValue('online', formContext.selectedChannel?.activePlans?.online?.active);
        formContext.setValue(
          'inPerson',
          formContext.selectedChannel?.activePlans?.inPerson?.active,
        );
      }
      formContext.setModalChannel(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formContext.selectedChannel]);

  const hideCompare = {
    display: formContext.type === 'classFromHistory' ? 'none' : 'normal',
  };

  const showCloneContent =
    !formContext.workout.recurringTemplate &&
    !formContext.competition &&
    formContext.modeClass === 'edit' &&
    formContext.scheduleOptions?.length > 0;

  const showHeats = formContext.watch('useHeats');

  const colsRow1 = formContext.watch('inPerson') && formContext.watch('online') ? 4 : 6;
  const colsOverview = showCloneContent ? 4 : 6;

  const showUsers = !formContext.competition && formContext?.watch('inPerson');

  return (
    <Box>
      <ConfirmDialog
        open={confirmCancel.open}
        description={t('workout.confirm.cancel')}
        labelConfirm={t('button.yes')}
        labelCancel={t('button.no')}
        confirm={() => confirmCancel.callback()}
        cancel={() => setConfirmCancel((prev) => ({ ...prev, open: false }))}
      />

      {!formContext.isScheduleSession && !formContext.competition && (
        <Box
          mb={mbSections}
          px={paddingSections}
          py={2}
          className={classes.content}
          style={hideCompare}
        >
          <InfoChannel />
        </Box>
      )}

      <>
        {!formContext.isScheduleSession && !formContext.competition && (
          <Grid container spacing={isSmall ? 0 : 3}>
            <Grid item xs={12} md={colsRow1}>
              <Box
                mb={mbSections}
                px={paddingSections}
                className={classes.content}
                style={hideCompare}
              >
                <ClassSettings />
              </Box>
            </Grid>
            {formContext.watch('online') && (
              <Grid item xs={12} md={colsRow1}>
                <Box
                  mb={mbSections}
                  px={paddingSections}
                  className={classes.content}
                  style={hideCompare}
                >
                  <OnlineSetting />
                </Box>
              </Grid>
            )}
            {formContext.watch('inPerson') && (
              <Grid item xs={12} md={colsRow1}>
                <Box
                  mb={mbSections}
                  px={paddingSections}
                  className={classes.content}
                  style={hideCompare}
                >
                  <InPersonSetting
                    watch={formContext.watch}
                    setValue={formContext.setValue}
                    locations={formContext.locations}
                    errors={formContext.errors}
                    register={formContext.register}
                    mode={formContext.mode}
                    channel={formContext?.selectedChannel}
                    setLocations={formContext.setLocations}
                  />
                </Box>
              </Grid>
            )}
          </Grid>
        )}

        {!formContext.isTemplate && !formContext.competition && formContext.selectedChannel && (
          <Grid container spacing={3}>
            {formContext.showMembershipAccess && (
              <Grid item xs={12} md={6}>
                <Box
                  mb={mbSections}
                  px={paddingSections}
                  className={classes.content}
                  style={hideCompare}
                >
                  <MembershipAccess />
                </Box>
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <Box
                mb={mbSections}
                px={paddingSections}
                className={classes.content}
                style={hideCompare}
              >
                <Grid container pb={3}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Box mt={3}>
                      <Typography variant="h5" mb={2} color="primary">
                        {t('workout.title.coaches')}
                      </Typography>
                    </Box>

                    <Coaches />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        )}

        {!formContext.isTemplate && (
          <Box mb={mbSections} px={paddingSections} className={classes.content}>
            <Schedule />
          </Box>
        )}

        {!formContext.isTemplate && showHeats && (
          <Box mb={mbSections} p={paddingSections} className={classes.content}>
            <FormHeats heats={formContext.heats} setHeats={formContext.setHeats} />
          </Box>
        )}

        {!formContext?.competition && (
          <Grid container spacing={3}>
            <Grid item xs={12} md={showUsers ? 6 : 12}>
              <Box
                mb={mbSections}
                px={paddingSections}
                className={classes.content}
                py={3}
                style={hideCompare}
              >
                <Invites />
              </Box>
            </Grid>
            {showUsers && (
              <Grid item xs={12} md={6}>
                <Box
                  mb={mbSections}
                  px={paddingSections}
                  className={classes.content}
                  py={3}
                  style={hideCompare}
                >
                  <Users />
                </Box>
              </Grid>
            )}
          </Grid>
        )}

        {formContext.type === 'classFromHistory' && (
          <Box mb={mbSections} px={paddingSections} className={classes.content}>
            <ViewWorkoutDetail compare />
          </Box>
        )}

        <Grid container mb={mbSections} spacing={isSmall ? 0 : 3}>
          {showCloneContent && (
            <Grid item xs={12} sm={12} md={colsOverview}>
              <Box mb={mbSections} p={paddingSections} className={classes.content}>
                <CloneContent
                  watch={formContext.watch}
                  setValue={formContext.setValue}
                  workout={formContext.workout}
                  scheduleOptions={formContext.scheduleOptions}
                  channelId={formContext?.workout?.channel?.id}
                />
              </Box>
            </Grid>
          )}

          <Grid item xs={12} sm={12} md={colsOverview}>
            <Box p={paddingSections} className={classes.content} style={hideCompare}>
              <Typography variant="h5" mb={2} color="primary">
                {t(
                  formContext.competition
                    ? 'workout.competition.title.overview'
                    : 'workout.title.overview',
                )}
              </Typography>

              <ClassOverview />
            </Box>

            {!formContext?.competition && formContext?.mode === 'new' && (
              <Box mt={3}>
                <SectionsSettings
                  classes={classes}
                  hideCompare={hideCompare}
                  mbSections={mbSections}
                  paddingSections={paddingSections}
                  opened
                />
              </Box>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={colsOverview}>
            {!formContext?.competition && !formContext.workout.recurringTemplate && (
              <Box mb={mbSections} p={paddingSections} className={classes.content}>
                <PictureDay
                  pictureDay={formContext.pictureDay}
                  setPictureDay={formContext.setPictureDay}
                />
              </Box>
            )}
            <Box mb={mbSections} p={paddingSections} className={classes.content}>
              <InfoRecord />
            </Box>
          </Grid>
        </Grid>

        {formContext.competition &&
          formContext?.selectedCompetition?.hasDivisions &&
          formContext?.selectedCompetition?.divisions?.length > 0 && (
            <>
              <Box
                mb={mbSections}
                px={paddingSections}
                className={classes.content}
                style={hideCompare}
              >
                <InstructionsComp />
              </Box>
              <Box
                mb={mbSections}
                px={paddingSections}
                className={classes.content}
                style={hideCompare}
              >
                <CompDivisions />
              </Box>
              <Box
                mb={mbSections}
                px={paddingSections}
                className={classes.content}
                style={hideCompare}
              >
                <CompWorkoutVolunteers />
              </Box>
            </>
          )}

        <ModalFormSection
          modalSection={formContext?.modalSection}
          setModalSection={formContext?.setModalSection}
          sections={formContext?.sections}
          channelId={formContext?.selectedChannel?.id || formContext?.workout?.channel?.id}
          setSections={formContext?.setSections}
          isVideoClass={formContext.watch('method') === 'video'}
          isCompetition={formContext.competition}
          editingRecurring={!!formContext.workoutRecurringId}
        />

        <Box mb={mbSections} style={formContext.type === 'classFromHistory' ? hideCompare : {}}>
          <Grid container spacing={3}>
            {formContext.competition && (
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Box className={classes.content} p={3} mb={3}>
                  <Box>
                    <Checkbox
                      label={t('competition.label.showScores')}
                      checked={formContext.watch('showScores')}
                      onClick={() =>
                        formContext.setValue('showScores', !formContext.watch('showScores'))
                      }
                    />
                    <Box>
                      <Checkbox
                        label={t('competition.label.multiplyPoints')}
                        checked={formContext.watch('multiplyPoints')}
                        onClick={() =>
                          formContext.setValue(
                            'multiplyPoints',
                            !formContext.watch('multiplyPoints'),
                          )
                        }
                      />
                      {formContext.watch('multiplyPoints') && (
                        <Box mb={1}>
                          <TextFieldRounded
                            type="number"
                            value={formContext.watch('multiplyPointsNumber') || ''}
                            pattern="[0-9]*"
                            placeholder={t('competition.label.multiplyPoints')}
                            // label={t('channel.memberships.label.cancellationAmount').toUpperCase()}
                            onChange={(v) => formContext.setValue('multiplyPointsNumber', v)}
                            bordered
                          />
                        </Box>
                      )}
                    </Box>
                    <Checkbox
                      label={t('competition.label.useRxScaledScores')}
                      checked={formContext.watch('competitionUseRxScaledScores')}
                      onClick={() =>
                        formContext.setValue(
                          'competitionUseRxScaledScores',
                          !formContext.watch('competitionUseRxScaledScores'),
                        )
                      }
                    />

                    <HideUntilDate
                      isCompetition
                      setValue={formContext.setValue}
                      watch={formContext.watch}
                    />
                  </Box>
                </Box>

                <Box
                  mb={mbSections}
                  px={paddingSections}
                  className={classes.content}
                  py={2}
                  style={hideCompare}
                >
                  <Sections
                    openEditSection={(sec) =>
                      formContext?.setModalSection({ open: true, currentSection: sec })
                    }
                    addNewSection={() => formContext?.setModalSection({ open: true })}
                    setValue={formContext.setValue}
                    sections={formContext.sections}
                    setSections={formContext.setSections}
                    competition={formContext.competition}
                    isScheduleSession={formContext.isScheduleSession}
                    watch={formContext.watch}
                    workout={formContext.workout}
                    mode={formContext.mode}
                    setMovements={(values) =>
                      formContext.setValue('movements', values(formContext.watch('movements')))
                    }
                  />
                </Box>
              </Grid>
            )}
            {!formContext.competition && (
              <Grid item xs={12} sm={12} md={6}>
                {formContext?.mode === 'edit' && (
                  <SectionsSettings
                    classes={classes}
                    hideCompare={hideCompare}
                    mbSections={mbSections}
                    paddingSections={paddingSections}
                  />
                )}

                <Box
                  mb={mbSections}
                  px={paddingSections}
                  py={2}
                  className={classes.content}
                  style={hideCompare}
                >
                  <BenchmarkOptions
                    setCurrentSection={(sec) =>
                      formContext?.setModalSection({ open: true, currentSection: sec })
                    }
                    isCompetition={formContext.competition}
                    mode={formContext.mode}
                    setSections={formContext.setSections}
                    setValue={formContext.setValue}
                    setClassOption={formContext.setClassOption}
                    sections={formContext.sections}
                  />
                </Box>
                {formContext?.selectedChannel && (
                  <Box
                    mb={mbSections}
                    px={paddingSections}
                    py={2}
                    className={classes.content}
                    style={hideCompare}
                  >
                    <HistoryOptions
                      setCurrentSection={(sec) =>
                        formContext?.setModalSection({ open: true, currentSection: sec })
                      }
                      isCompetition={formContext.competition}
                      mode={formContext.mode}
                      setSections={formContext.setSections}
                      setValue={formContext.setValue}
                      watch={formContext.watch}
                      sections={formContext.sections}
                      channel={formContext?.selectedChannel}
                    />
                  </Box>
                )}

                <Box
                  mb={mbSections}
                  px={paddingSections}
                  className={classes.content}
                  py={2}
                  style={hideCompare}
                >
                  <Sections
                    openEditSection={(sec) =>
                      formContext?.setModalSection({ open: true, currentSection: sec })
                    }
                    addNewSection={() => formContext?.setModalSection({ open: true })}
                    setValue={formContext.setValue}
                    sections={formContext.sections}
                    setSections={formContext.setSections}
                    competition={formContext.competition}
                    isScheduleSession={formContext.isScheduleSession}
                    watch={formContext.watch}
                    workout={formContext.workout}
                    mode={formContext.mode}
                    setMovements={(values) =>
                      formContext.setValue(
                        'movements',
                        values(formContext.watch('movements') || []),
                      )
                    }
                  />
                </Box>
              </Grid>
            )}

            <Grid item xs={12} sm={12} md={6}>
              <Box className={classes.content} p={3}>
                <Box flexGrow="1" display="flex" mb={2} flexDirection="column">
                  <Typography variant="h5" color="primary">
                    {t(
                      formContext.competition
                        ? 'workout.stream.section.movements'
                        : 'workout.stream.section.eqpMov',
                    )}
                  </Typography>
                  <Typography style={fontStyle.medium}>{t('input.tag.helper')}</Typography>
                </Box>

                <Box pb={5} mt={3}>
                  <Box>
                    <Movements
                      setMovements={(values) => formContext.setValue('movements', values)}
                      movements={formContext.watch('movements') || []}
                      ownerableId={
                        formContext?.selectedChannel?.id || formContext?.selectedCompetition?.id
                      }
                      ownerableType="Channel"
                    />
                  </Box>
                  {!formContext.competition && (
                    <>
                      <Divider my={3} mx={5} />
                      <Box mt={3}>
                        <Equipments
                          setEquipment={(values) => formContext.setValue('equipment', values)}
                          equipment={formContext.watch('equipment') || []}
                        />
                      </Box>
                    </>
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box px={5} display="flex" justifyContent="flex-end">
          <Box className={formContext.workoutRecurringId ? '' : classes.boxButtons}>
            {(!!formContext.workoutRecurringId ||
              formContext.workout.editable === true ||
              formContext.modeClass === 'new') && (
              <ButtonPrimary
                type="submit"
                size="small"
                mt={10}
                mb={5}
                className={classes.button}
                disabled={formContext.submiting}
              >
                <Box display="flex" alignItems="center">
                  <LoaderSm loading={formContext.submiting} width={20} />
                  <Typography ml={1} component="span">
                    {t(formContext.competition ? 'button.saveEvent' : 'button.saveClass')}
                  </Typography>
                </Box>
              </ButtonPrimary>
            )}
          </Box>
        </Box>
      </>
    </Box>
  );
};
