/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Paper from 'core/ui/Paper';
import Typography from 'core/ui/Typography';
import SFDataTable from 'core/ui/DataTable';
import { channelCurrency, date, time } from 'core/utils/formatters';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import { Dialog, Link } from '@material-ui/core';
import Texture from 'core/ui/Texture';
import { Add, OpenInNew, ViewHeadline } from '@material-ui/icons';
import Button, { ButtonPrimary, ButtonPrimarySm } from 'core/ui/Button';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import notifications from 'modules/notifications';
import { useDispatch } from 'react-redux';
import { newWorkoutsModule } from 'modules/workouts/new';
import useSelectors from 'modules/map/useSelectors';
import ModalListDeductions from '../../../Coaches/PayrollDeductions/ModalListDeductions';

export default function Total({ channel, refreshReport, data }) {
  const { t } = useTranslation();
  const { request } = useActions();
  const dispatch = useDispatch();
  const loadingCheckin = useSelectors(newWorkoutsModule, 'loadingCheckin');

  const [modalDeductions, setModalDeduction] = useState({ open: false });
  const [viewClasses, setViewClasses] = useState({ open: false, workouts: [] });
  const columns = [
    {
      name: 'Coach',
      selector: 'name',
      sortable: true,
      cell: (row) => <Typography>{row?.coach?.name}</Typography>,
    },
    {
      name: t('channel.rateTemplate.kind.noShow'),
      selector: 'totalUsers',
      sortable: true,
      cell: (row) => (
        <Box display="flex" alignItems="center">
          <Typography>{row?.totalNoShow?.total}</Typography>

          {row?.totalNoShow?.total > 0 && (
            <Button
              onClick={() =>
                setViewClasses({
                  open: true,
                  workouts: row?.totalNoShow?.workouts || [],
                  coachId: row?.coach?.id,
                  noShow: true,
                })
              }
            >
              <ViewHeadline />
              <Typography>&nbsp;{row.checkins}</Typography>
            </Button>
          )}
        </Box>
      ),
    },
    {
      name: t('channel.rateTemplate.kind.price_by_class'),
      selector: 'totalUsers',
      sortable: true,
      cell: (row) => (
        <Typography>{channelCurrency(row?.totalByClassMoney, channel?.currency)}</Typography>
      ),
    },
    {
      name: t('channel.rateTemplate.kind.percent_by_class'),
      selector: 'totalByClassPercent',
      sortable: true,
      cell: (row) => (
        <Typography>{channelCurrency(row?.totalByClassPercent, channel?.currency)}</Typography>
      ),
    },
    {
      name: t('channel.rateTemplate.kind.percent_by_membership'),
      selector: 'totalByMembership',
      sortable: true,
      cell: (row) => (
        <Typography>{channelCurrency(row?.totalByMembership, channel?.currency)}</Typography>
      ),
    },
    {
      name: t('channel.rateTemplate.kind.hourly'),
      selector: 'totalByHourly',
      sortable: true,
      cell: (row) => (
        <Typography>{channelCurrency(row?.totalByHourly, channel?.currency)}</Typography>
      ),
    },
    {
      name: t('channel.coaches.customPayments'),
      selector: 'totalByCustomPayments',
      sortable: true,
      cell: (row) => (
        <Typography>{channelCurrency(row?.totalByCustomPayments, channel?.currency)}</Typography>
      ),
    },
    {
      name: t('payroll.calendarsRegistrations'),
      selector: 'totalByCustomPayments',
      sortable: true,
      cell: (row) => (
        <Typography>
          {channelCurrency(row?.totalCalendarsRegistrations, channel?.currency)}
        </Typography>
      ),
    },
    {
      name: t('channel.payroll.kind.byMembershipBonus'),
      selector: 'totalByMembershipBonus',
      sortable: true,
      cell: (row) => (
        <Typography>{channelCurrency(row?.totalByMembershipBonus, channel?.currency)}</Typography>
      ),
    },
    {
      name: t('channel.rateTemplate.kind.percent_by_product'),
      selector: 'totalByProduct',
      sortable: true,
      cell: (row) => (
        <Typography>{channelCurrency(row?.totalByProduct, channel?.currency)}</Typography>
      ),
    },
    {
      name: 'Deductions',
      selector: 'totalDeductions',
      sortable: true,
      cell: (row) => (
        <Box display="flex" className="gap-5" alignItems="center" flexWrap="wrap">
          <Typography noWrap color="error">
            {channelCurrency(row?.totalDeductions, channel?.currency)}
          </Typography>
          <ButtonPrimarySm
            onClick={() => setModalDeduction({ open: true, userId: row?.coach?.id })}
          >
            <Add fontSize="small" />
          </ButtonPrimarySm>
          &nbsp;
        </Box>
      ),
    },
    {
      name: 'Total',
      selector: 'totalUsers',
      sortable: true,
      cell: (row) => <Typography>{channelCurrency(row?.total, channel?.currency)}</Typography>,
    },
  ];

  const checkInUser = (workoutId) => {
    request({
      action: Modules.newWorkouts.actions.checkInUsers,
      data: {
        workoutId,
        userId: viewClasses?.coachId,
      },
      options: {
        onSuccess: () => {
          setViewClasses((prev) => {
            const checked = prev?.checkedClasses || {};
            checked[workoutId] = true;
            return {
              ...prev,
              checkedClasses: checked,
            };
          });
          if (refreshReport) {
            refreshReport();
          }
        },
        onError: (error) => {
          dispatch(notifications.error(error));
        },
      },
    });
  };

  return (
    <>
      <Dialog
        open={viewClasses?.open}
        fullWidth
        maxWidth="sm"
        onClose={() => setViewClasses({ open: false })}
      >
        <Texture>
          <Box p={3}>
            {viewClasses?.workouts?.map((x) => (
              <Paper key={x?.id} p={3} mb={1}>
                <Box display="flex" alignItems="center">
                  <Box flexGrow={1}>
                    <Typography>
                      {x?.id} - {x?.name}
                    </Typography>
                    <Typography>{x?.classTypeName}</Typography>

                    <Typography>
                      {date(x?.datetime, channel?.dateFormatReact)}&nbsp;&bull;&nbsp;
                      {time(x.datetime)}
                    </Typography>

                    <Typography color="textSecondary">
                      {t('label.totalAthletes')}&nbsp;:&nbsp;
                      {x.totalUsers}
                    </Typography>

                    {x?.cancelAt && (
                      <Typography color="error" variant="h5">
                        {t('label.cancelled')}
                      </Typography>
                    )}
                  </Box>
                  {viewClasses?.noShow &&
                    (!viewClasses?.checkedClasses || !viewClasses?.checkedClasses[x?.id]) && (
                      <ButtonPrimary
                        disabled={loadingCheckin}
                        style={{ marginRight: 5 }}
                        onClick={() => checkInUser(x.id)}
                      >
                        {t('button.checkIn')}
                      </ButtonPrimary>
                    )}
                  <Link target="_blank" href={`/workout/view/${x.id}`}>
                    <OpenInNew style={{ fontSize: 30 }} />
                  </Link>
                </Box>
              </Paper>
            ))}
          </Box>
        </Texture>
      </Dialog>
      <ModalListDeductions
        channel={channel}
        refreshReport={refreshReport}
        open={modalDeductions?.open}
        userId={modalDeductions?.userId}
        close={() => setModalDeduction({ open: false })}
      />
      <Paper style={{ width: '100%' }} py={2}>
        <SFDataTable
          title={
            <Box>
              <Typography variant="h5">Show All</Typography>
            </Box>
          }
          columns={columns}
          data={data?.total?.coaches}
          showPaper={false}
        />
        <Box display="flex" justifyContent="flex-end">
          <Paper p={3} mt={3}>
            <Typography variant="h4">
              Total:&nbsp;{channelCurrency(data?.total?.total, channel?.currency)}
            </Typography>
          </Paper>
        </Box>
      </Paper>
    </>
  );
}
