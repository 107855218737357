/* eslint-disable react/no-danger */
import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import useSelectors from 'modules/map/useSelectors';
import { usersContractsModule } from 'modules/usersContracts';
import { useDispatch } from 'react-redux';
import notifications from 'modules/notifications';
import ViewerContent from 'components/DocumentsBuilder/ViewerContent';
import { primary } from 'core/ui/Colors';
import LoaderSm from 'core/ui/LoaderSm';

const GymContract = () => {
  const { key, userId, buttonColor } = useParams();
  const { request } = useActions();
  const dispatch = useDispatch();
  const contract = useSelectors(usersContractsModule, 'contract');
  const loading = useSelectors(usersContractsModule, 'loading');

  const getContract = () => {
    request({
      action: Modules.usersContracts.actions.getContractByKey,
      data: {
        userId,
        key,
      },
      options: {
        onError: () => {
          dispatch(notifications.error('Error to find contract.'));
        },
      },
    });
  };

  useEffect(() => {
    if (key && userId) {
      getContract();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key, userId]);

  return (
    <div>
      <LoaderSm center loading={loading} />
      <ViewerContent
        contractKey={key}
        document={contract?.document}
        userId={userId}
        embed
        callback={() => {
          window.history.replaceState(null, 'StreamFit', `/channels`);
        }}
        buttonColor={buttonColor ? `#${buttonColor}` : primary}
      />
    </div>
  );
};

export default GymContract;
