import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import Typography from 'core/ui/Typography';
import noImage from 'assets/img/no-image.jpg';
import Box from 'core/ui/Box';
import imgGrid from 'assets/img/bg-grid.png';
import { Check, OpenWith, ZoomOut, ZoomIn } from '@material-ui/icons';
import Draggable from 'react-draggable';
import { ButtonWhite } from 'core/ui/Button';
import SelImage from './SelImage';

const Image = ({ image, setImage }) => {
  const { t } = useTranslation();
  const [sizeMedia] = useState({ width: 170, height: 170 });

  const refMedia = useRef(null);

  const useStyles = makeStyles((theme) => ({
    boxAvatar: {
      borderRadius: 10,
      [theme.breakpoints.up('sm')]: {
        marginRight: 15,
      },
    },
    boxSelImage: {
      position: 'absolute',
      marginTop: -30,
      height: 100,
    },
    boxInfoDrag: {
      backgroundColor: 'rgba(0,0,0,0.4)',
      color: 'white',
      position: 'absolute',
      marginTop: -70,
      padding: 5,
      borderRadius: 10,
    },
    gridEdit: {
      backgroundImage: `url(${imgGrid})`,
      margin: 0,
      padding: 0,
      cursor: 'move',
    },
    btnZoom: {
      backgroundColor: 'rgba(0,0,0,0.4)',
      borderRadius: 10,
      padding: 1,
    },
    colorTitle: {
      color: '#404041',
    },
  }));
  const classes = useStyles();

  const handleDrag = (e, ui) => {
    const prev = image || {};
    setImage({
      ...(prev || {}),
      position: {
        ...prev.position,
        width: sizeMedia.width,
        x: 0,
        y: ui.y,
      },
    });
  };

  const confirmPosition = () => {
    const prev = image || {};
    setImage({
      ...prev,
      new: true,
      edit: false,
    });
  };

  const zoom = (n) => {
    const prev = image || {};
    setImage({
      ...prev,
      position: {
        ...(prev?.position || {}),
        zoom: (prev?.position?.zoom || 1) + n,
      },
    });
  };

  return (
    <>
      {image && image.edit && (
        <Box className={`${classes.gridEdit}`} style={{ borderRadius: 10 }}>
          <Box style={{ height: sizeMedia.height, width: sizeMedia.width, position: 'relative' }}>
            <Box className="boxGridMedia" ref={refMedia} style={{ borderRadius: 10 }}>
              <Draggable
                axis="y"
                onDrag={handleDrag}
                position={{
                  x: 0,
                  y: image.position ? image.position.y : 0,
                }}
              >
                <Box>
                  <img
                    className="boxImg"
                    draggable="false"
                    style={{
                      borderRadius: 10,
                      transform: `scale(${(image.position && image.position.zoom) || 1})`,
                    }}
                    src={image ? image.url : noImage}
                    alt="Profile"
                  />
                </Box>
              </Draggable>
            </Box>
          </Box>
        </Box>
      )}

      {image && image.edit && (
        <Box display="flex" justifyContent="center">
          <Box
            className={classes.boxInfoDrag}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Box>
              <OpenWith size="small" style={{ fontSize: 10 }} />
              <Typography ml={1} variant="caption" style={{ fontSize: 10 }}>
                {t('channel.info.dragToReposition')}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}

      {image && !image.edit && (
        <Box style={{ borderRadius: 10 }} className={`${classes.grid}`}>
          <Box style={{ height: sizeMedia.height, width: sizeMedia.width, position: 'relative' }}>
            <Box className="boxGridMedia" ref={refMedia} style={{ borderRadius: 10 }}>
              <Draggable
                axis="y"
                disabled
                position={{
                  x: 0,
                  y: image.position ? image.position.y : 0,
                }}
              >
                <Box>
                  <img
                    className="boxImg"
                    draggable="false"
                    style={{
                      borderRadius: 10,
                      transform: `scale(${(image.position && image.position.zoom) || 1})`,
                    }}
                    src={image ? image.url : noImage}
                    alt=""
                  />
                </Box>
              </Draggable>
            </Box>
          </Box>
        </Box>
      )}

      <Box display="flex" justifyContent="center" style={{ position: 'relative' }}>
        <Box className={classes.boxSelImage}>
          {!image?.edit && (
            <Box display="flex" justifyContent="center">
              <SelImage empty={!image?.url} setImage={setImage} />
            </Box>
          )}
          {image && image.edit && (
            <Box display="flex">
              <Box display="flex" mr={1} justifyContent="flex-start">
                <Box
                  className={classes.btnZoom}
                  display="flex"
                  mr={1}
                  alignItems="center"
                  onClick={() => zoom(0.1)}
                >
                  <ZoomIn style={{ color: 'white', fontSize: 22 }} />
                </Box>
                <Box
                  className={classes.btnZoom}
                  display="flex"
                  mr={1}
                  alignItems="center"
                  onClick={() => zoom(-0.1)}
                >
                  <ZoomOut style={{ color: 'white', fontSize: 22 }} />
                </Box>
              </Box>
              <Box flexGrow="1" justifyContent="flex-end">
                <ButtonWhite variant="contained" onClick={() => confirmPosition()}>
                  <Box display="flex" alignItems="center">
                    <Check style={{ fontSize: 12 }} />
                    <Typography style={{ fontSize: 12 }} component="span" ml={1} mr={1}>
                      {t('confirmCrop').toUpperCase()}
                    </Typography>
                  </Box>
                </ButtonWhite>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default Image;
