import React, { useEffect, useState } from 'react';
import { ButtonPrimary } from 'core/ui/Button';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import Typography, { fontStyle } from 'core/ui/Typography';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { onlineColor, primary } from 'core/ui/Colors';
import { Dialog, IconButton } from '@material-ui/core';
import { Add, Edit, RemoveRedEye } from '@material-ui/icons';
import { storeOptionsModule } from 'modules/storeOptions';
import SFDataTable from 'core/ui/DataTable';
import Paper from 'core/ui/Paper';
import useActions from 'modules/map/useActions';
import useSelectors from 'modules/map/useSelectors';
import Modules from 'modules';
import ModalFormOption from './Modal';

const StoreOptions = ({ ownerableType, ownerableId }) => {
  const { t } = useTranslation();
  const { request } = useActions();
  const storeOptions = useSelectors(storeOptionsModule, 'data');

  const [modalViewOptions, setModalViewOptions] = useState({ open: false, anchorEl: null });

  const [modal, setModal] = useState({
    open: false,
  });

  const getStoreOptions = () => {
    request({
      action: Modules.storeOptions.actions.getStoreOptions,
      data: {
        ownerableType,
        ownerableId,
        showInactive: true,
      },
    });
  };

  useEffect(() => {
    if (ownerableId) {
      getStoreOptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request, ownerableId, ownerableType]);

  useEffect(() => {
    if (modalViewOptions?.option?.id) {
      setModalViewOptions((prev) => ({
        ...prev,
        option: storeOptions?.find((x) => x.id === modalViewOptions?.option?.id),
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeOptions]);

  const openModal = (parent) => {
    setModal((prev) => ({ ...prev, mode: 'new', open: true, parent }));
  };

  const onEditClick = (item) => {
    setModal((prev) => ({ ...prev, item, mode: 'edit', open: true }));
  };

  const handleClick = (parent) => {
    setModalViewOptions({ option: parent, open: true });
  };

  const columns = [
    {
      name: 'Name',
      selector: 'name',
      cell: (row) => <Typography> {row.name}</Typography>,
      sortable: true,
    },
    {
      name: 'Active',
      selector: 'active',
      sortable: true,
      center: true,
      width: '40px',
      cell: (row) => (
        <>
          {!row.active ? (
            <RadioButtonUncheckedIcon style={{ ...fontStyle.medium, color: primary }} />
          ) : (
            <CheckCircleOutlineIcon style={{ ...fontStyle.medium, color: onlineColor }} />
          )}
        </>
      ),
    },
    {
      sortable: false,
      center: true,
      width: '300px',
      cell: (row) => (
        <>
          {!row?.parentId && (
            <>
              <ButtonPrimary p={0.5} mx={1} onClick={() => handleClick(row)}>
                <Box display="flex" alignItems="center">
                  <RemoveRedEye size="small" style={{ color: 'white', fontSize: 14 }} />
                  <Box alignItems="center">
                    <Typography component="span" ml={1} style={{ color: 'white' }}>
                      {t('button.options')}({row?.parents?.length || 0})
                    </Typography>
                  </Box>
                </Box>
              </ButtonPrimary>
              <ButtonPrimary p={0.5} mx={1} onClick={() => openModal(row)}>
                <Box display="flex" alignItems="center">
                  <Add size="small" style={{ color: 'white', fontSize: 14 }} />
                  <Box alignItems="center">
                    <Typography component="span" ml={1} style={{ color: 'white' }}>
                      {t('button.addOption')}
                    </Typography>
                  </Box>
                </Box>
              </ButtonPrimary>
            </>
          )}
          <IconButton onClick={() => onEditClick(row)}>
            <Edit />
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <Box>
      {modal.open && (
        <ModalFormOption
          storeOptions={storeOptions}
          open={modal.open}
          item={modal.item}
          parent={modal.parent}
          mode={modal.mode}
          ownerableType={ownerableType}
          ownerableId={ownerableId}
          close={() => setModal((prev) => ({ ...prev, open: false, item: {} }))}
          callback={(nameList) =>
            setModal((prev) => ({ ...prev, open: false, item: {}, nameListExpand: nameList }))
          }
        />
      )}

      <Dialog
        fullWidth
        maxWidth="sm"
        open={modalViewOptions?.open}
        onClose={() => setModalViewOptions({ open: false })}
      >
        <Paper p={3}>
          <SFDataTable
            noHeader
            columns={columns}
            data={modalViewOptions?.option?.parents}
            pagination
            showExport={false}
          />
        </Paper>
      </Dialog>

      <Paper className="paper-rounded" p={3}>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <Typography variant="h4" color="primary" mr={1}>
              {t('channel.store.options')}
            </Typography>
          </Box>
          <Box>
            <ButtonPrimary onClick={() => openModal()}>
              <Box display="flex" alignItems="flex-end">
                <Add size="small" />
                <Box alignItems="center">
                  <Typography component="span" mr={1}>
                    {t('button.addOption')}
                  </Typography>
                </Box>
              </Box>
            </ButtonPrimary>
          </Box>
        </Box>
        <Box mt={2}>
          <SFDataTable
            noHeader
            columns={columns}
            data={storeOptions.filter((x) => !x.parentId)}
            pagination
            showExport={false}
          />
        </Box>
      </Paper>
    </Box>
  );
};

export default StoreOptions;
