/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import Typography from 'core/ui/Typography';
import FontIcon from 'core/ui/FontIcon';
import { primary } from 'core/ui/Colors';
import Paper from 'core/ui/Paper';
import Grid from 'core/ui/Grid';
import Divider from 'core/ui/Divider';
import GridSkeleton from 'core/ui/GridSkeleton';
import CardCoach from './Cards/CardCoach';
import Item from './Item';
import SectionTitle from './SectionTitle';

const Results = ({ results, loading, model, hideUpcoming, channelId, onClickCard }) => {
  const { t } = useTranslation();

  return (
    <Paper className="paper-rounded" p={5}>
      {model === 'Workout' ? (
        <>
          {!hideUpcoming &&
            results &&
            results.itemsUpcoming &&
            results.itemsUpcoming.length > 0 && (
              <>
                <Box display="flex" mb={2} alignItems="center">
                  <Box flexGrow="1" display="flex" alignItems="center">
                    <FontIcon iconName="icon-search" color={primary} size={14} />
                    <Typography ml={1} color="secondary" className="link" variant="h5">
                      {t('search.results.upcoming').toUpperCase()}
                    </Typography>
                  </Box>
                  <>
                    {results.paginationUpcoming.totalPages > 1 && (
                      <SectionTitle
                        titleViewMore={t('search.results.upcoming')}
                        model={model}
                        icon="icon-search"
                        type="results"
                        upcoming
                      />
                    )}
                    <Divider />
                  </>
                </Box>
                <Box px={2}>
                  <Box mt={5} display="flex">
                    <Grid container spacing={3}>
                      {results.itemsUpcoming.map((object, index) => (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={4}
                          lg={3}
                          key={index.toString()}
                          className="search-item"
                        >
                          <Box
                            className="hover"
                            onClick={() =>
                              channelId && onClickCard ? onClickCard(object.item) : null
                            }
                          >
                            <Item object={object} key={index.toString()} clickable={!onClickCard} />
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </Box>
              </>
            )}

          {results && results.itemsRecents && results.itemsRecents.length > 0 && (
            <>
              <Box display="flex" mb={2} alignItems="center">
                <Box flexGrow="1" display="flex" alignItems="center">
                  <FontIcon iconName="icon-search" color={primary} size={14} />
                  <Typography ml={1} color="secondary" className="link" variant="h5">
                    {t('search.results.recents').toUpperCase()}
                  </Typography>
                </Box>
                <>
                  {results.paginationRecents.totalPages > 1 && (
                    <SectionTitle
                      titleViewMore={t('search.results.recents')}
                      model={model}
                      icon="icon-search"
                      type="results"
                      recents
                    />
                  )}
                  <Divider />
                </>
              </Box>
              <Box px={2}>
                <Box mt={5} display="flex">
                  <Grid container spacing={3}>
                    {results.itemsRecents.map((object, index) => (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={3}
                        key={index.toString()}
                        className="search-item"
                      >
                        <Box
                          className="hover"
                          onClick={() =>
                            channelId && onClickCard ? onClickCard(object.item) : null
                          }
                        >
                          <Item object={object} key={index.toString()} clickable={!onClickCard} />
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Box>
            </>
          )}

          {!loading &&
            results &&
            results.itemsUpcoming.length <= 0 &&
            results.itemsRecents.length <= 0 && (
              <Box display="flex" justifyContent="center">
                <Typography variant="h5">{t('search.messageNotResults')}</Typography>
              </Box>
            )}
        </>
      ) : (
        <>
          <Box display="flex" mb={2} alignItems="center">
            <Box flexGrow="1" display="flex" alignItems="center">
              <FontIcon iconName="icon-search" color={primary} size={14} />
              <Typography ml={1} color="secondary" className="link" variant="h5">
                {t('search.results.title').toUpperCase()}
              </Typography>
            </Box>
            {results.filter((x) => x.model === model).length > 0 && (
              <>
                <SectionTitle
                  titleViewMore={t('search.results.title')}
                  model={model}
                  icon="icon-search"
                  type="results"
                />
                <Divider />
              </>
            )}
          </Box>
          <Box px={2}>
            <Grid container className="search-item">
              {results
                .filter((x) => x.model === 'User')
                .map((object, index) => (
                  <Grid item xs={6} sm={4} md={2} lg={2} key={index.toString()}>
                    <Box m={2} className="item">
                      <CardCoach coach={object.item} fullWidth />
                    </Box>
                  </Grid>
                ))}
            </Grid>
          </Box>

          <Box px={2}>
            <Box mt={5} display="flex">
              <Grid container spacing={3}>
                {results
                  .filter((x) => x.model === 'Channel')
                  .map((object, index) => (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      key={index.toString()}
                      className="search-item"
                    >
                      <Box>
                        <Item object={object} key={index.toString()} />
                      </Box>
                    </Grid>
                  ))}
              </Grid>
            </Box>
          </Box>
          {!loading && results.length <= 0 && (
            <Box display="flex" justifyContent="center">
              <Typography variant="h5">{t('search.messageNotResults')}</Typography>
            </Box>
          )}
        </>
      )}

      {loading && <GridSkeleton />}
    </Paper>
  );
};

export default Results;

Results.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  results: PropTypes.object,
  loading: PropTypes.bool,
  model: PropTypes.string,
  channelId: PropTypes.number,
  onClickCard: PropTypes.func,
  hideUpcoming: PropTypes.bool,
};

Results.defaultProps = {
  results: {},
  loading: false,
  model: '',
  onClickCard: null,
  channelId: null,
  hideUpcoming: false,
};
