import api from 'core/api';

export const channelsCoachesPayrollDeductionsModule = 'channelsCoachesPayrollDeductions';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const actions = {
  postPayrollDeductions: {
    module: channelsCoachesPayrollDeductionsModule,
    name: 'postPayrollDeductions',
    api: (data) =>
      api.post(`/channels/${data?.channelId}/channels_coaches_payroll_deductions`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  putPayrollDeductions: {
    module: channelsCoachesPayrollDeductionsModule,
    name: 'putPayrollDeductions',
    api: (data) =>
      api.put(`/channels/${data?.channelId}/channels_coaches_payroll_deductions/${data?.id}`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  destroy: {
    module: channelsCoachesPayrollDeductionsModule,
    name: 'destroy',
    api: (data) =>
      api.delete(`/channels/${data?.channelId}/channels_coaches_payroll_deductions/${data?.id}`),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  getPayrollDeductions: {
    module: channelsCoachesPayrollDeductionsModule,
    name: 'getPayrollDeductions',
    api: (params) =>
      api.get(`/channels/${params?.channelId}/channels_coaches_payroll_deductions`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
};

export default {
  actions,
  state: {
    error: null,
    loading: false,
  },
};
