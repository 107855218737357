/* eslint-disable react/prop-types */
import * as React from 'react';

import Paper from 'core/ui/Paper';
import { useTranslation } from 'react-i18next';
import { channelCurrency, date } from 'core/utils/formatters';
import Typography from 'core/ui/Typography';
import SFDataTable from 'core/ui/DataTable';
import Box from 'core/ui/Box';
import UserMembershipPlanResume from './UserMembershipPlanResume';

export default function ActiveMemberships({ members, channel, membershipPlanId }) {
  const { t } = useTranslation();

  const columns = [
    {
      name: 'Member',
      selector: 'userName',
      sortable: true,
      cell: (row) => <Typography>{row?.userName}</Typography>,
    },
    {
      name: 'Joined',
      selector: 'userJoinedAt',
      sortable: true,
      cell: (row) => <Typography>{row?.userJoinedAt}</Typography>,
    },
    {
      name: 'Resume',
      selector: 'coupon',
      sortable: false,
      cell: (row) => (
        <Box>
          <UserMembershipPlanResume channelKey={row} />
        </Box>
      ),
    },
    {
      name: 'Start',
      selector: 'startAt',
      cell: (row) => (
        <Typography>{row?.startAt && date(row?.startAt, channel?.dateFormatReact)}</Typography>
      ),
      sortable: true,
      center: true,
    },
    {
      name: 'Expires/Renew',
      selector: 'expiryAt',
      cell: (row) => (
        <Typography>{row?.expiryAt && date(row?.expiryAt, channel?.dateFormatReact)}</Typography>
      ),
      sortable: true,
      center: true,
    },
    {
      name: 'Plan Expires',
      selector: 'expires',
      cell: (row) => (
        <Typography>
          {row.planExpiresAt ? date(row.planExpiresAt, channel?.dateFormatReact) : 'No Expiry'}
        </Typography>
      ),
      sortable: true,
      center: true,
    },
    {
      name: 'Revenue',
      selector: 'revenue',
      sortable: true,
      right: true,
      cell: (row) => (
        <Typography>
          {row.plan !== 'Channel Key'
            ? channelCurrency(row.membershipChannelAmount, channel?.currency)
            : ''}
        </Typography>
      ),
    },
  ];

  const rows = members
    .filter((x) => x?.membershipPlan?.id === Number(membershipPlanId))
    .map((item) => ({
      userName: item?.user?.name,
      userJoinedAt: item.userJoinedAt && date(item?.userJoinedAt),
      ...item,
    }));

  return (
    <Paper style={{ width: '100%' }} p={3}>
      <Typography m={3} align="center">
        {t('channel.reports.activeMembers')}
      </Typography>
      <SFDataTable noHeader columns={columns} data={rows} pagination showExport={false} />
    </Paper>
  );
}
