import React, { createContext, useEffect, useState } from 'react';

import Box from 'core/ui/Box';
import ImageMedia from 'components/Channel/ImageMedia';
import { ButtonPrimarySm } from 'core/ui/Button';
import { useTranslation } from 'react-i18next';
import useActions from 'modules/map/useActions';
import SFDataTable from 'core/ui/DataTable';
import Typography from 'core/ui/Typography';
import useSelectors from 'modules/map/useSelectors';
import { adminChannelsModule } from 'modules/admin/channels';
import Modules from 'modules';
import LoaderSm from 'core/ui/LoaderSm';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import { KeyboardArrowDown, KeyboardArrowRight, Search } from '@material-ui/icons';
import Paper from 'core/ui/Paper';
import { Collapse } from '@material-ui/core';
import { secondaryXLight } from 'core/ui/Colors';

export const Context = createContext();

const AdminChannelSelector = ({ label, setSelectedChannel, selectedChannel, withoutCollapse }) => {
  const { t } = useTranslation();
  const { request } = useActions();
  const [name, setName] = useState('');
  const [perPage, setPerPage] = useState(10);
  const [opened, setOpened] = useState(false);
  const loading = useSelectors(adminChannelsModule, 'loading');
  const channels = useSelectors(adminChannelsModule, 'channels');
  const pagination = useSelectors(adminChannelsModule, 'pagination');

  const getChannels = (page = 1) => {
    request({
      action: Modules.adminChannels.actions.getChannels,
      data: {
        name,
        page,
        perPage,
      },
    });
  };

  useEffect(() => {
    getChannels(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request]);

  const chooseChannel = (ch) => {
    setSelectedChannel(ch);
    setOpened(false);
  };

  const columns = [
    {
      name: '',
      selector: 'image',
      sortable: true,
      width: '80px',
      cell: (channel) => (
        <Box style={{ width: '60px' }}>
          <ImageMedia image={channel.image ? channel.image : {}} />
        </Box>
      ),
    },
    {
      name: 'Gym',
      selector: 'name',
      sortable: true,
      cell: (channel) => <Typography>{channel.name && channel.name.toUpperCase()}</Typography>,
    },
    {
      name: '',
      width: '100px',
      cell: (channel) => (
        <Box>
          <ButtonPrimarySm onClick={() => chooseChannel(channel)}>
            {t('button.select')}
          </ButtonPrimarySm>
        </Box>
      ),
    },
  ];

  return (
    <>
      <Box>
        <Paper
          style={{ border: `0.5px solid ${secondaryXLight}` }}
          p={3}
          className="hover"
          onClick={() => setOpened(!opened)}
        >
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>
              <Typography variant="h4">
                {!selectedChannel?.id
                  ? label || t('admin.channel.select')
                  : `${selectedChannel?.id} - ${selectedChannel?.name}`}
              </Typography>
            </Box>
            {!withoutCollapse && (
              <>{opened ? <KeyboardArrowDown style={{}} /> : <KeyboardArrowRight style={{}} />}</>
            )}
          </Box>
        </Paper>
      </Box>

      {withoutCollapse ? (
        <Content
          name={name}
          setName={setName}
          getChannels={getChannels}
          loading={loading}
          columns={columns}
          channels={channels}
          pagination={pagination}
          setPerPage={setPerPage}
          t={t}
        />
      ) : (
        <Collapse in={opened}>
          <Content
            name={name}
            setName={setName}
            getChannels={getChannels}
            loading={loading}
            columns={columns}
            channels={channels}
            pagination={pagination}
            setPerPage={setPerPage}
            t={t}
          />
        </Collapse>
      )}
    </>
  );
};

const Content = ({
  name,
  setName,
  getChannels,
  loading,
  columns,
  channels,
  pagination,
  setPerPage,
  t,
}) => (
  <Box>
    <Box m={1} display="flex" justifyContent="flex-end">
      <TextFieldRounded
        value={name}
        onChange={(v) => setName(v)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            getChannels(1);
          }
        }}
        placeholder={t('search.channel.placeholder.mobile')}
      />
      <Box ml={3} style={{ minWidth: 150 }}>
        <ButtonPrimarySm onClick={() => getChannels(1)} align="center" fullWidth component="h4">
          <LoaderSm loading={loading} />
          <Search fontSize="medium" color="white" />
        </ButtonPrimarySm>
      </Box>
    </Box>

    <SFDataTable
      columns={columns}
      data={channels}
      pagination
      showExport={false}
      noHeader
      onChangePage={(p) => getChannels(p)}
      paginationServer
      paginationDefaultPage={pagination?.currentPage}
      paginationTotalRows={pagination?.totalCount}
      onChangeRowsPerPage={(newValue) => {
        setPerPage(newValue);
      }}
    />
  </Box>
);

export default AdminChannelSelector;

AdminChannelSelector.propTypes = {};

AdminChannelSelector.defaultProps = {};
