import React, { useEffect, useState } from 'react';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import { ButtonPrimary } from 'core/ui/Button';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import useConfirmation from 'core/useConfirmation';
import Divider from 'core/ui/Divider';
import Typography, { fontStyle } from 'core/ui/Typography';
import useSelectors from 'modules/map/useSelectors';
import { automationWorkflowsModule } from 'modules/automationWorkflows';
import LoaderSm from 'core/ui/LoaderSm';
import ModalForm from './Form/ModalForm';
import TableWorkflows from './TableWorkflows';

export const AutomatedWorkflow = ({ channel }) => {
  const { t } = useTranslation();
  const [modalWorkflow, setModalWorkflow] = useState({ open: false });
  const [workflows, setWorkflows] = useState([]);
  const [templates, setWorkflowsTemplates] = useState([]);
  const { confirmation } = useConfirmation();
  const loading = useSelectors(automationWorkflowsModule, 'loading');
  const { request } = useActions();

  const getWorkflows = () => {
    request({
      action: Modules.automationWorkflows.actions.getWorkflows,
      data: {
        ownerableId: channel?.id,
        ownerableType: 'Channel',
      },
      options: {
        onSuccess: setWorkflows,
      },
    });
  };

  const getWorkflowsTemplates = () => {
    request({
      action: Modules.automationWorkflows.actions.getWorkflowsTemplates,
      data: {
        ownerableId: channel?.id,
        ownerableType: 'Channel',
      },
      options: {
        onSuccess: setWorkflowsTemplates,
      },
    });
  };

  useEffect(() => {
    getWorkflows();
    getWorkflowsTemplates();
    request({
      action: Modules.automationTypes.actions.getActions,
      data: {
        ownerableId: channel?.id,
        ownerableType: 'Channel',
      },
    });
    request({
      action: Modules.automationTypes.actions.getTriggers,
      data: {
        ownerableId: channel?.id,
        ownerableType: 'Channel',
      },
    });
  }, []);

  const removeClick = (item) => {
    confirmation({
      description: t('areYouSure'),
      yesClick: () => {
        request({
          action: Modules.automationWorkflows.actions.deleteWorkflow,
          data: {
            ownerableId: channel?.id,
            ownerableType: 'Channel',
            id: item?.id,
          },
          options: {
            onSuccess: () => {
              getWorkflowsTemplates();
              getWorkflows();
            },
          },
        });
      },
    });
  };

  const cloneWorkflowTemplate = (item) => {
    request({
      action: Modules.automationWorkflows.actions.cloneWorkflowTemplate,
      data: {
        ownerableId: channel?.id,
        ownerableType: 'Channel',
        workflowId: item?.id,
      },
      options: {
        onSuccess: () => {
          getWorkflows();
        },
      },
    });
  };

  const cloneWorkflow = (item) => {
    confirmation({
      description: t('areYouSure'),
      yesClick: () => {
        request({
          action: Modules.automationWorkflows.actions.cloneWorkflow,
          data: {
            ownerableId: channel?.id,
            ownerableType: 'Channel',
            workflowId: item?.id,
          },
          options: {
            onSuccess: () => {
              getWorkflows();
            },
          },
        });
      },
    });
  };

  const showTemplate = channel?.id === 2;

  return (
    <Box p={3}>
      <Box display="flex" justifyContent="flex-end" m={3}>
        <ButtonPrimary onClick={() => setModalWorkflow({ open: true })}>
          {t('button.createWorkflow')}
        </ButtonPrimary>
      </Box>

      <ModalForm
        open={modalWorkflow?.open}
        close={() => setModalWorkflow({ open: false })}
        channelId={channel?.id}
        showTemplate={showTemplate}
        isTemplate={modalWorkflow?.workflow?.template}
        workflowId={modalWorkflow?.workflow?.id}
        callback={() => {
          getWorkflows();
          if (showTemplate) {
            getWorkflowsTemplates();
          }
          setModalWorkflow({ open: false });
        }}
        automated
      />

      <LoaderSm loading={loading} center />

      <Box my={3}>
        <TableWorkflows
          workflows={workflows}
          viewClick={(workflow) => setModalWorkflow({ open: true, workflow })}
          removeClick={removeClick}
          clone={cloneWorkflow}
        />
      </Box>
      {templates?.length > 0 && (
        <>
          <Divider my={4} />
          <Typography mb={2} style={fontStyle.label}>
            {t('label.workflowsTemplates')}
          </Typography>
          <Box my={3}>
            <TableWorkflows
              templates
              isStreamChannel={showTemplate}
              workflows={templates}
              cloneTemplate={cloneWorkflowTemplate}
              viewClick={(workflow) => setModalWorkflow({ open: true, workflow })}
              removeClick={removeClick}
            />
          </Box>
        </>
      )}
    </Box>
  );
};
