import api from 'core/api';
import { call, put } from 'redux-saga/effects';

export const newMovementsModule = 'newMovements';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const actions = {
  create: {
    module: newMovementsModule,
    name: 'create',
    api: (data) => api.post(`/movements`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  getMovementsVideos: {
    module: newMovementsModule,
    name: 'getMovementsVideos',
    api: (params) => api.get(`/movements/videos`, { params }),
    *sagas(Creators, { params, options }) {
      try {
        const resp = yield call(actions.getMovementsVideos.api, params);
        yield put(Creators.getMovementsVideosSuccess(resp.data, resp.pagination));
        if (options.onSuccess) {
          options.onSuccess(resp.data, resp.pagination);
        }
      } catch (error) {
        if (options.onError) {
          options.onError(error);
        }
        yield put(Creators.getMovementsVideosError());
      }
    },
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
};

export default {
  actions,
  state: {
    data: [],
    loading: false,
  },
};
