import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ItemPartnerProgram from './ItemPartnerProgram';

export default function ItemPartner({
  itemPartner,
  channel,
  programs,
  onPurchaseClick,
  cancel,
  setModalProgram,
  loadingCancel,
}) {
  return (
    <Box py={8}>
      <Box mb={3}>
        <Box display="flex">
          {itemPartner?.image?.image?.url && (
            <img
              src={itemPartner?.image?.image?.url}
              alt="Partner"
              style={{ width: 60, objectFit: 'contain' }}
            />
          )}
          <Box flexGrow={1} pl={5}>
            <Typography variant="h3" component="span" color="primary">
              {itemPartner?.companyName}{' '}
            </Typography>

            <Typography variant="subtitle1" color="textSecondary" paragraph>
              {itemPartner.details}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Grid container spacing={3}>
        {programs?.map((program) => (
          <Grid item xs={12} md={4} lg={3} key={program?.id}>
            <Box textAlign="center">
              <ItemPartnerProgram
                channel={channel}
                onPurchaseClick={onPurchaseClick}
                onCancelClick={() => cancel(program)}
                setModalProgram={setModalProgram}
                item={program}
                loadingCancel={loadingCancel}
              />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
