import React, { useCallback, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from 'core/ui/Box';
import { useDispatch, useSelector } from 'react-redux';
import ClassDetail from 'components/Workout/Grid/Details/ClassDetail';
import { DialogContent } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { primary } from 'core/ui/Colors';
import Divider from 'core/ui/Divider';

import Attendance from 'components/Workout/Show/Tabs/Attendance';
import { ShowContext } from 'components/Workout/Show';
import { workoutsActions, workoutsSelectors } from 'modules/workouts';
import Texture from 'core/ui/Texture';
import DialogTitle from 'core/ui/Dialog/DialogTitle';

const WorkoutShow = ({
  open = false,
  onClose,
  workoutId,
  embed = false,
  confirmCancelClass,
  buttonColor,
}) => {
  const dispatch = useDispatch();

  const workout = useSelector((state) => workoutsSelectors.getWorkout(state));

  const loadWorkout = useCallback(() => {
    if (!workoutId) return;

    // Compability issues... Some <Attendance /> components rely on redux, others on ShowContext, others on props
    dispatch(workoutsActions.getWorkoutStart(workoutId));
  }, [dispatch, workoutId]);

  useEffect(loadWorkout, [loadWorkout]);

  return (
    <Dialog scroll="body" fullWidth maxWidth="sm" onClose={onClose} open={open}>
      <DialogTitle onClose={onClose}>{workout?.name}</DialogTitle>
      <Texture>
        <DialogContent>
          <Box pb={3}>
            {workout && workout.id === workoutId ? (
              <>
                <ClassDetail
                  setOpenDetail={(toOpen) => {
                    if (!toOpen) onClose();
                  }}
                  workoutId={workoutId}
                  embed={embed}
                  confirmCancelClass={confirmCancelClass}
                  buttonColor={buttonColor || primary}
                />

                {workout.isCoach && (
                  <>
                    <Divider my={2} />

                    <ShowContext.Provider value={{ workout, refreshWorkout: loadWorkout }}>
                      <Attendance />
                    </ShowContext.Provider>
                  </>
                )}
              </>
            ) : (
              <>
                <Skeleton variant="rect" style={{ width: '100%', height: 150 }} />
                <Skeleton variant="rect" style={{ marginTop: 5, width: '100%', height: 100 }} />
              </>
            )}
          </Box>
        </DialogContent>
      </Texture>
    </Dialog>
  );
};

export default WorkoutShow;
