import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, TableCell, TableRow, TableBody, IconButton, TableHead } from '@material-ui/core';
import Box from 'core/ui/Box';

import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Typography from 'core/ui/Typography';
import SelectorUser from 'components/Profile/SelectorUser';
import Paper from 'core/ui/Paper';
import { secondaryXLight } from 'core/ui/Colors';

const Judges = ({ watch, setValue }) => {
  const { t } = useTranslation();
  const usersJudges = watch('usersJudges') || [];
  const elementRef = useRef();
  const addUser = (user) => {
    setValue('usersJudges', [
      ...usersJudges.filter((x) => x.id !== user.id),
      { id: user.id, name: user.name },
    ]);

    setTimeout(() => {
      elementRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, 500);
  };

  const removeNew = (row) => {
    setValue(
      'usersJudges',
      usersJudges.filter((x) => x.id !== row.id),
    );
  };

  return (
    <Box pb={5}>
      <Box display="flex" alignItems="center" pt={3} px={3}>
        <Box flexGrow="1">
          <Typography variant="h5" mb={3} color="primary">
            {t('competition.userJudges')}
          </Typography>
        </Box>
      </Box>

      <Box>
        <SelectorUser onSelectedUser={(user) => addUser(user)} autoFocus={false} />
        <Paper p={3} className="paper-rounded">
          <Box mt={3} display="flex">
            <Table
              ref={elementRef}
              size="small"
              style={{ border: `0.5px solid ${secondaryXLight}` }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography color="primary" py={3}>
                      Judge
                    </Typography>
                  </TableCell>
                  <TableCell align="right" />
                </TableRow>
              </TableHead>
              <TableBody>
                {usersJudges &&
                  usersJudges.map((row, index) => (
                    <TableRow key={index.toString()}>
                      <TableCell>
                        <Typography>{row.name}</Typography>
                      </TableCell>
                      <TableCell align="center">
                        <IconButton onClick={() => removeNew(row)}>
                          <HighlightOffIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

Judges.defaultProps = {};

Judges.propTypes = {};

export default Judges;
