import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import Typography from 'core/ui/Typography';
import ColorPicker from 'core/ui/ColorPicker';
import Grid from 'core/ui/Grid';
import Card from 'core/ui/Card';
import Checkbox from 'core/ui/Checkbox';
import noImage from 'assets/img/no-image.jpg';
import { ButtonPrimary } from 'core/ui/Button';
import { Delete } from '@material-ui/icons';
import { CompetitionContext } from '..';
import SelImages from '../SelImages';

const CompetitionCustomization = () => {
  const { t } = useTranslation();
  const formContext = useContext(CompetitionContext);

  const darkBg = '#1e1e1f';
  const lightBg = '#E9E9E9';

  const cardImage = formContext?.watch('cardImage')?._destroy
    ? null
    : formContext?.watch('cardImage');

  const backgroundImage = formContext?.watch('backgroundImage')?._destroy
    ? null
    : formContext?.watch('backgroundImage');
  const mobileBackgroundImage = formContext?.watch('mobileBackgroundImage')?._destroy
    ? null
    : formContext?.watch('mobileBackgroundImage');

  return (
    <Box pt={3}>
      <Box flexGrow="1">
        <Typography variant="h5" mb={3} color="primary">
          {t('competition.label.colors')}
        </Typography>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={3} lg={3} className="gap-10">
          <Box p={1} display="flex" mb={5}>
            <ColorPicker
              color={formContext.watch('buttonColor')}
              setColor={(v) => formContext.setValue('buttonColor', v)}
              label={t('competition.label.buttonColor')}
            />
            <Box>
              <ColorPicker
                color={formContext.watch('fontColor')}
                setColor={(v) => formContext.setValue('fontColor', v)}
                label={t('competition.label.fontAccentColor')}
              />
            </Box>
          </Box>

          <Box>
            <Checkbox
              label={t('competition.label.darkBackground')}
              checked={formContext.watch('darkBackground')}
              onClick={() => {
                formContext.setValue('darkBackground', !formContext.watch('darkBackground'));
                if (formContext.watch('darkBackground')) {
                  formContext.setValue('backgroundColor', darkBg);
                } else {
                  formContext.setValue('backgroundColor', lightBg);
                }
              }}
            />
          </Box>

          <Box>
            <ColorPicker
              color={formContext.watch('backgroundColor')}
              setColor={(v) => formContext.setValue('backgroundColor', v)}
              label={t('competition.label.backgroundColor')}
            />
            <Typography color="textSecondary" style={{ fontSize: 10 }}>
              Dark: #1e1e1f
            </Typography>
            <Typography color="textSecondary" style={{ fontSize: 10 }}>
              Light: #E9E9E9
            </Typography>
          </Box>

          <Box mt={2}>
            <Card
              title={t('competition.label.cardImage')}
              action={
                <Box display="flex" alignItems="center" p={1} mt={2}>
                  {cardImage && (
                    <ButtonPrimary onClick={() => formContext?.deleteImage('cardImage')}>
                      <Delete fontSize="small" />
                    </ButtonPrimary>
                  )}
                </Box>
              }
            >
              <Box>
                <img
                  className="boxImg"
                  draggable="false"
                  src={cardImage?.url || noImage}
                  alt=""
                  style={{
                    width: '100%',
                    // Without height undefined it won't work
                    height: undefined,
                    // figure out your image aspect ratio
                    aspectRatio: 16 / 9,
                  }}
                />
              </Box>

              <Box display="flex" justifyContent="center">
                <SelImages setImage={(img) => formContext?.setImage('cardImage', img)} />
              </Box>
            </Card>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Box mb={3}>
            <Card
              title={t('competition.label.backgroundImage')}
              action={
                <Box display="flex" alignItems="center" p={1} mt={2}>
                  {backgroundImage && (
                    <ButtonPrimary onClick={() => formContext?.deleteImage('backgroundImage')}>
                      <Delete fontSize="small" />
                    </ButtonPrimary>
                  )}
                </Box>
              }
            >
              <Box>
                <img
                  className="boxImg"
                  draggable="false"
                  src={backgroundImage?.url || noImage}
                  alt=""
                  style={{
                    width: '100%',
                    // Without height undefined it won't work
                    height: undefined,
                    // figure out your image aspect ratio
                    aspectRatio: 16 / 9,
                  }}
                />
              </Box>

              <Box display="flex" justifyContent="center">
                <SelImages setImage={(img) => formContext?.setImage('backgroundImage', img)} />
              </Box>
            </Card>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <Card
            style={{ height: '100%', flex: 1 }}
            title={t('competition.label.mobileBackgroundImage')}
            action={
              <Box display="flex" alignItems="center" p={1} mt={2}>
                {mobileBackgroundImage && (
                  <ButtonPrimary onClick={() => formContext?.deleteImage('mobileBackgroundImage')}>
                    <Delete fontSize="small" />
                  </ButtonPrimary>
                )}
              </Box>
            }
          >
            <Box>
              <img
                className="boxImg"
                draggable="false"
                src={mobileBackgroundImage?.url || noImage}
                alt=""
                style={{
                  width: '100%',
                  // Without height undefined it won't work
                  height: undefined,
                  // figure out your image aspect ratio
                  aspectRatio: 9 / 16,
                }}
              />
            </Box>

            <Box display="flex" justifyContent="center">
              <SelImages setImage={(img) => formContext?.setImage('mobileBackgroundImage', img)} />
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

CompetitionCustomization.defaultProps = {};

CompetitionCustomization.propTypes = {};

export default CompetitionCustomization;
