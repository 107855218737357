import { useContext, useState } from 'react';
import { currencyEdit, formatDateApi } from 'core/utils/formatters';
import useConfirmation from 'core/useConfirmation';
import { ChannelContext } from '../..';

export default () => {
  const formContext = useContext(ChannelContext);
  const [modalForm, setModalForm] = useState({ open: false });
  const { confirmation } = useConfirmation();
  const [modalCoupons, setModalCoupons] = useState({});

  const defaultPlan = {
    visible: true,
    online: formContext.channel?.activePlans?.online?.active,
    inPerson: formContext.channel?.activePlans?.inPerson?.active,
    renewable: true,
    cancelable: true,
    sifelyAccess: false,
    limitOneByUser: false,
    customIncrement: 'month',
    customIncrementNumber: 1,
    renewConfigDate: 'purchase_date',
    limitUseIncrement: 'renew_frequency',
    expiryConfig: {
      config: 'none',
    },
    channelsWorkoutsTypeIds: [],
    defaultValueForPostPayment: true,
  };

  const [currentPlan, setCurrentPlan] = useState(defaultPlan);
  const [editIndex, setEditIndex] = useState(-1);

  const changeActive = (plan) => {
    const planCoupon = { ...formContext.membershipPlans.find((x) => x === plan) };
    const callback = () => {
      planCoupon.active = !planCoupon.active;
      formContext.setMembershipPlans([
        ...formContext.membershipPlans.filter((x) => x !== plan),
        planCoupon,
      ]);

      setTimeout(() => {
        formContext.submitForm();
      }, 500);
    };

    if (planCoupon.active) {
      confirmation({
        description: 'Are you sure?',
        yesClick: () => {
          callback();
        },
      });
    }
  };

  const changeVisible = (plan) => {
    const planCoupon = { ...formContext.membershipPlans.find((x) => x === plan) };
    planCoupon.visible = !planCoupon.visible;
    formContext.setMembershipPlans([
      ...formContext.membershipPlans.filter((x) => x !== plan),
      planCoupon,
    ]);

    setTimeout(() => {
      formContext.submitForm();
    }, 500);
  };

  const editPlan = (plan, index) => {
    const startAt = plan.startAt ? formatDateApi(plan.startAt) : null;
    const endAt = plan.endAt ? formatDateApi(plan.endAt) : null;

    setModalForm({ open: true });
    setCurrentPlan({
      ...plan,
      channelsWorkoutsTypeIds: plan?.channelsWorkoutsTypes?.map((x) => x.id),
      amount: currencyEdit(plan.amount),
      introductoryAmount: currencyEdit(plan.introductoryAmount),
      contractNewPrice: plan.contractNewPrice && currencyEdit(plan.contractNewPrice),
      channelsTagsAttributes: plan?.channelsTags?.map((x) => x?.name),

      startAt,
      endAt,
    });
    setEditIndex(index);
  };

  const createPlan = () => {
    setCurrentPlan(defaultPlan);
    setModalForm({ open: true });
  };

  const saveCoupon = (plan, coupon) => {
    const planCoupon = { ...formContext.membershipPlans.find((x) => x.id === plan.id) };
    const ar = planCoupon.coupons
      ? [...planCoupon.coupons?.filter((x) => x.id !== coupon?.id)]
      : [];

    ar.push(coupon);
    planCoupon.coupons = ar;
    formContext.setMembershipPlans([
      ...formContext.membershipPlans.filter((x) => x !== plan),
      planCoupon,
    ]);

    setModalCoupons((prev) => ({ ...prev, currentPlan: planCoupon }));

    setTimeout(() => {
      formContext.submitForm();
    }, 500);
  };

  const removeCoupon = (plan, coupon) => {
    const planCoupon = { ...formContext.membershipPlans.find((x) => x === plan) };
    const ar = planCoupon.coupons ? [...planCoupon.coupons] : [];
    planCoupon.coupons = ar.filter((x) => x !== coupon);

    formContext.setMembershipPlans([
      ...formContext.membershipPlans.filter((x) => x !== plan),
      planCoupon,
    ]);

    setModalCoupons((prev) => ({ ...prev, currentPlan: planCoupon }));

    setTimeout(() => {
      formContext.submitForm();
    }, 500);
  };

  const changeCoupon = (plan, prop, value) => {
    const planCoupon = { ...formContext.membershipPlans.find((x) => x === plan) };
    const ar = planCoupon.coupons ? [...planCoupon.coupons] : [];

    planCoupon.coupons = ar.map((x) => {
      const v = { ...x };
      if (x === value) {
        v[prop] = !v[prop];
      }
      return v;
    });

    formContext.setMembershipPlans([
      ...formContext.membershipPlans.filter((x) => x !== plan),
      planCoupon,
    ]);

    setModalCoupons((prev) => ({ ...prev, currentPlan: planCoupon }));

    setTimeout(() => {
      formContext.submitForm();
    }, 500);
  };

  const changeOrderShow = (plan, currentIndex) => {
    const newIndex = currentIndex - 1;
    const newPlans = formContext.membershipPlans.map((p, index) => {
      const newPlan = { ...p };
      if (newIndex === index) {
        newPlan.orderShow = currentIndex + 1;
      } else if (plan.id === p.id) {
        newPlan.orderShow = newIndex;
      } else {
        newPlan.orderShow = index;
      }

      return newPlan;
    });

    formContext.setMembershipPlans(newPlans);

    setTimeout(() => {
      formContext.submitForm();
    }, 100);
  };

  return {
    modalForm,
    setModalForm,
    currentPlan,
    setCurrentPlan,
    setEditIndex,
    editIndex,
    setModalCoupons,
    changeActive,
    changeVisible,
    editPlan,
    changeOrderShow,
    modalCoupons,
    saveCoupon,
    changeCoupon,
    removeCoupon,
    createPlan,
  };
};
