import React from 'react';
import Box from 'core/ui/Box';
import Typography from 'core/ui/Typography';
import { secondaryLight, secondaryTableLight, secondaryBgLight, secondary } from 'core/ui/Colors';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { IconButton } from '@material-ui/core';
import { HighlightOff } from '@material-ui/icons';
import FlagCountry from 'core/ui/FlagCountry';
import { ordinalNumber } from 'core/utils/formatters/date';

const Item = ({ styleBox, heatId, laneNumber, item, leaderboard, dropable, removeItem }) => (
  <Box p={3} display="flex" style={styleBox} className="link" alignItems="center">
    {heatId && (
      <Typography align="center" style={{ color: secondary, width: 40 }} mr={2} variant="h6">
        {laneNumber}
      </Typography>
    )}

    <Box flexGrow="1" className="tw-flex tw-flex-row tw-items-center tw-gap-2">
      <FlagCountry flag={item.country && item.country.flag} size={40} />
      <div>
        <Typography style={{ color: secondary }} variant="body2" className="roboto-regular">
          {item?.displayName?.toUpperCase()}
        </Typography>
        <div className="tw-flex tw-flex-row tw-gap-2">
          {item?.division && (
            <Typography className="tw-text-xs tw-font-bold">{item?.division?.name}</Typography>
          )}
          {leaderboard?.position && (
            <div className="tw-text-xs">
              <b>{ordinalNumber(leaderboard?.position)}</b>
              &nbsp;&nbsp;
              {leaderboard?.points} points
            </div>
          )}
        </div>
      </div>
    </Box>
    <Box display="flex" alignItems="center">
      {dropable && heatId && (
        <IconButton onClick={() => removeItem({ userId: item.userId, heatId })}>
          <HighlightOff />
        </IconButton>
      )}
    </Box>
  </Box>
);

const ItemTeam = ({
  item,
  leaderboard,
  index,
  totalLen,
  isDragDisabled,
  heatId,
  removeItem,
  dropable,
  laneNumber,
  allTeams,
}) => {
  const styleBox = {
    backgroundColor: index % 2 === 0 ? secondaryTableLight : secondaryBgLight,
    height: 60,
    borderBottom: totalLen - 1 > index ? `0.5px solid ${secondaryLight}` : 'none',
  };
  const competitionType = item?.regType;

  if (allTeams) {
    return (
      <Draggable
        isDragDisabled={isDragDisabled}
        draggableId={`TEAM-${item.userId}`}
        key={`TEAM-${item.userId}`}
        index={index}
      >
        {(provided) => (
          <Box ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
            <Item
              styleBox={styleBox}
              competitionType={competitionType}
              heatId={heatId}
              laneNumber={laneNumber}
              item={item || {}}
              leaderboard={leaderboard}
              dropable={dropable}
              removeItem={removeItem}
            />
            {provided.placeholder}
          </Box>
        )}
      </Draggable>
    );
  }

  return (
    <div>
      {dropable ? (
        <DropableItemHeat laneNumber={laneNumber || 0} heat={{ id: heatId || 0 }}>
          <div>
            {item ? (
              <Draggable
                isDragDisabled={isDragDisabled}
                draggableId={`TEAM-${item.userId}`}
                key={`TEAM-${item.userId}`}
                index={index}
              >
                {(provided) => (
                  <Box
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <Item
                      styleBox={styleBox}
                      competitionType={competitionType}
                      heatId={heatId}
                      laneNumber={laneNumber}
                      item={item}
                      leaderboard={leaderboard}
                      dropable={dropable}
                      removeItem={removeItem}
                    />
                    {provided.placeholder}
                  </Box>
                )}
              </Draggable>
            ) : (
              <div
                className="tw-flex tw-w-16 tw-flex-row tw-items-center tw-justify-center tw-p-2 tw-font-bold"
                style={styleBox}
              >
                {laneNumber}
              </div>
            )}
          </div>
        </DropableItemHeat>
      ) : (
        <Item
          styleBox={styleBox}
          competitionType={competitionType}
          heatId={heatId}
          laneNumber={laneNumber}
          item={item || {}}
          leaderboard={leaderboard}
          dropable={dropable}
          removeItem={removeItem}
        />
      )}
    </div>
  );
};

export default ItemTeam;

const DropableItemHeat = ({ heat, laneNumber, children }) => (
  <Droppable droppableId={`HEAT-${heat.id}-${laneNumber}`}>
    {(provided) => (
      <Box ref={provided.innerRef}>
        {children}

        {provided.placeholder}
      </Box>
    )}
  </Droppable>
);
