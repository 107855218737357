import { all, call, put } from 'redux-saga/effects';

import { getErrorMessage } from 'core/api/api-error';

import notifications from 'modules/notifications';

import * as api from './api';
import { messagesActions as actions } from './index';

export function* getMessagesReplies() {
  try {
    const response = yield call(api.getMessagesReplies);
    const messages = response.data;
    yield put(actions.getMessagesRepliesSuccess(messages));
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.getMessagesRepliesError(getErrorMessage(error))),
    ]);
  }
}

export function* getMessages() {
  try {
    const response = yield call(api.getMessages);
    const messages = response.data;
    yield put(actions.getMessagesSuccess(messages));
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.getMessagesError(getErrorMessage(error))),
    ]);
  }
}

export function* getStreamUserCount() {
  try {
    const response = yield call(api.getStreamUserCount);
    const messages = response.data;
    yield put(actions.getStreamUserCountSuccess(messages));
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.getStreamUserCountError(getErrorMessage(error))),
    ]);
  }
}

export function* stopContact({ phone, callback }) {
  try {
    const response = yield call(api.stopContact, phone);
    const messages = response.data;
    yield put(actions.stopContactSuccess(messages));
    if (callback) {
      callback();
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.stopContactError(getErrorMessage(error))),
    ]);
  }
}

export function* getMessageContacts({ messageId, query, page, perPage }) {
  try {
    const response = yield call(api.getMessageContacts, messageId, query, page, perPage);
    yield put(actions.getMessageContactsSuccess(response.data, response.pagination));
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.getMessageContactsError(getErrorMessage(error))),
    ]);
  }
}

export function* getTwilioNumbers() {
  try {
    const response = yield call(api.getTwilioNumbers);
    const messages = response.data;
    yield put(actions.getTwilioNumbersSuccess(messages));
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.getTwilioNumbersError(getErrorMessage(error))),
    ]);
  }
}

export function* getMessage({ messageId }) {
  try {
    const response = yield call(api.getMessage, messageId);
    const messages = response.data;
    yield put(actions.getMessageSuccess(messages));
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.getMessageError(getErrorMessage(error))),
    ]);
  }
}

export function* postMessages({ data, callback }) {
  try {
    const response = yield call(api.postMessages, data);
    const messages = response.data;
    yield put(actions.postMessagesSuccess(messages));
    if (callback) {
      callback();
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.postMessagesError(getErrorMessage(error))),
    ]);
  }
}

export function* putMessages({ data, callback }) {
  try {
    const response = yield call(api.putMessages, data);
    const messages = response.data;
    yield put(actions.putMessagesSuccess(messages));
    if (callback) {
      callback();
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.putMessagesError(getErrorMessage(error))),
    ]);
  }
}
