import React from 'react';
import { IconButton } from '@material-ui/core';
import { Cancel, DragIndicator } from '@material-ui/icons';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Modules from 'modules';
import useActions from 'modules/map/useActions';
import notifications from 'modules/notifications';
import { reorder } from 'core/utils/helpers';
import { HelpVideoItem } from '../../HelpVideoItem';
import { useStyles } from './styles';

export const ListAdmin = ({
  helpVideos,
  setHelpVideos,
  healedCurrentPath,
  getMappedHelpVideos,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { request } = useActions();

  const classes = useStyles();

  const handleDragEnd = (e) => {
    if (!e.destination) return;

    const items = reorder(helpVideos, e.source.index, e.destination.index);

    setHelpVideos(items);

    request({
      action: Modules.helpVideo.actions.reorderByPath,
      data: {
        path: healedCurrentPath,
        helpVideoIds: items.map((item) => item.id),
      },
      options: {
        onSuccess: () => dispatch(notifications.success(t('helparea.admin.reordered'))),
        onError: (err) => dispatch(notifications.error(err)),
      },
    });
  };

  const removeHelpVideoMap = (helpVideoId) => {
    request({
      action: Modules.helpVideo.actions.destroyByPath,
      data: { helpVideoId, params: { path: healedCurrentPath } },
      options: {
        onSuccess: getMappedHelpVideos,
        onError: (err) => dispatch(notifications.error(err)),
      },
    });
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="helpvideoitems" direction="horizontal">
        {(droppableProvided) => (
          <div
            ref={droppableProvided.innerRef}
            className={classes.contentContainerStyle}
            {...droppableProvided.droppableProps}
          >
            {helpVideos.map((helpVideo, index) => (
              <Draggable
                key={String(helpVideo.id)}
                draggableId={String(helpVideo.id)}
                index={index}
              >
                {(draggableProvided) => (
                  <div
                    ref={draggableProvided.innerRef}
                    className={classes.itemWrapper}
                    {...draggableProvided.draggableProps}
                  >
                    <HelpVideoItem helpVideo={helpVideo} light />

                    <div className={classes.absoluteOptions}>
                      <IconButton
                        onClick={() => removeHelpVideoMap(helpVideo.id)}
                        className={classes.removeButton}
                      >
                        <Cancel />
                      </IconButton>

                      <div {...draggableProvided.dragHandleProps}>
                        <DragIndicator />
                      </div>
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
