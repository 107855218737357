import api from 'core/api';

export const competitionsCustomScoringsModule = 'competitionsCustomScorings';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const loadingFormStates = {
  start: { loadingForm: true },
  error: { loadingForm: false },
  success: { loadingForm: false },
};

const actions = {
  create: {
    module: competitionsCustomScoringsModule,
    name: 'create',
    api: (data) =>
      api.post(`/competitions/${data?.competitionId}/competitions_custom_scorings`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingFormStates,
  },
  update: {
    module: competitionsCustomScoringsModule,
    name: 'update',
    api: (data) =>
      api.put(
        `/competitions/${data?.competitionId}/competitions_custom_scorings/${data?.id}`,
        data,
      ),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingFormStates,
  },
  delete: {
    module: competitionsCustomScoringsModule,
    name: 'delete',
    api: (data) =>
      api.delete(`/competitions/${data?.competitionId}/competitions_custom_scorings/${data?.id}`),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingFormStates,
  },
  getCustomScorings: {
    module: competitionsCustomScoringsModule,
    name: 'getCustomScorings',
    api: (params) =>
      api.get(`/competitions/${params?.competitionId}/competitions_custom_scorings`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
};

const competitionsCustomScoringsReducer = {
  actions,
  state: {
    error: null,
    loading: false,
  },
};

export default competitionsCustomScoringsReducer;
