import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import Typography from 'core/ui/Typography';
import FontIcon from 'core/ui/FontIcon';
import { primary } from 'core/ui/Colors';
import Paper from 'core/ui/Paper';
import Grid from 'core/ui/Grid';
import GridSkeleton from 'core/ui/GridSkeleton';
import CardCompetition from 'components/Competition/Card';

import './Item.css';
import Paginator from 'core/ui/Paginator';
import { competitionsSearchSelectors } from 'modules/competitionsSearch';
import { useSelector } from 'react-redux';

const Results = ({ results, loading, changePage }) => {
  const { t } = useTranslation();
  const pagination = useSelector((state) => competitionsSearchSelectors.getSearchPagination(state));

  return (
    <Paper className="paper-rounded" p={5}>
      <Box display="flex" mb={2} alignItems="center">
        <Box flexGrow="1" display="flex" alignItems="center">
          <FontIcon iconName="icon-search" color={primary} size={14} />
          <Typography ml={1} color="secondary" className="link" variant="h5">
            {t('title.competitions').toUpperCase()}
          </Typography>
        </Box>
      </Box>

      {!loading && results.length <= 0 && (
        <Box display="flex" justifyContent="center">
          <Typography variant="h5">{t('search.competitions.messageNotResults')}</Typography>
        </Box>
      )}

      <Box px={2}>
        <Box mt={5}>
          {loading && <GridSkeleton />}
          <Grid container spacing={3}>
            {results.map((object, index) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
                key={index.toString()}
                className="search-item"
              >
                <Box>
                  <CardCompetition key={object.item.id} competition={object.item} />
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box
          mt={5}
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          {!loading && <Paginator pagination={pagination} changePage={(p) => changePage(p)} />}
        </Box>
      </Box>
    </Paper>
  );
};

export default Results;

Results.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  results: PropTypes.array,
  loading: PropTypes.bool,
  changePage: PropTypes.func,
};

Results.defaultProps = {
  results: [],
  loading: false,
  changePage: () => {},
};
