import Skeleton from '@material-ui/lab/Skeleton';
import Grid from 'core/ui/Grid';
import React from 'react';

export default () => (
  <Grid container spacing={3} style={{ width: '100%' }}>
    <Grid item xs={12} sm={6} md={4} lg={4}>
      <Skeleton variant="rect" width="100%" height="200px" />
    </Grid>
    <Grid item xs={12} sm={6} md={4} lg={4}>
      <Skeleton variant="rect" width="100%" height="200px" />
    </Grid>
    <Grid item xs={12} sm={6} md={4} lg={4}>
      <Skeleton variant="rect" width="100%" height="200px" />
    </Grid>
  </Grid>
);
