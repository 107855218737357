import { all, call, put } from 'redux-saga/effects';

import { getErrorMessage } from 'core/api/api-error';

import notifications from 'modules/notifications';

import * as api from './api';
import { channelMemberNotesActions as actions } from './index';

export function* getChannelMemberNotes({ channelId, memberId, callback }) {
  try {
    const response = yield call(api.getChannelMemberNotes, channelId, memberId);
    const channelMemberNotes = response.data;
    if (callback) {
      callback(channelMemberNotes);
    }
    yield put(actions.getChannelMemberNotesSuccess(channelMemberNotes));
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.getChannelMemberNotesError(getErrorMessage(error))),
    ]);
  }
}

export function* deleteChannelMemberNotes({ channelId, id, callback }) {
  try {
    const response = yield call(api.deleteChannelMemberNotes, channelId, id);
    const channelMemberNotes = response.data;
    yield put(actions.deleteChannelMemberNotesSuccess(channelMemberNotes));
    if (callback) {
      callback();
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.deleteChannelMemberNotesError(getErrorMessage(error))),
    ]);
  }
}

export function* postChannelMemberNotes({ data, callback }) {
  try {
    const response = yield call(api.postChannelMemberNotes, data);
    const channelMemberNotes = response.data;
    yield put(actions.postChannelMemberNotesSuccess(channelMemberNotes));
    if (callback) {
      callback();
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.postChannelMemberNotesError(getErrorMessage(error))),
    ]);
  }
}

export function* putChannelMemberNotes({ data, callback }) {
  try {
    const response = yield call(api.putChannelMemberNotes, data);
    const channelMemberNotes = response.data;
    yield put(actions.putChannelMemberNotesSuccess(channelMemberNotes));
    if (callback) {
      callback();
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.putChannelMemberNotesError(getErrorMessage(error))),
    ]);
  }
}
