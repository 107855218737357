/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { messageListsActions, messageListsSelectors } from 'modules/messageLists';
import { ButtonPrimarySm } from 'core/ui/Button';
import { Link } from '@material-ui/core';
import Typography, { fontStyle } from 'core/ui/Typography';
import LoaderSm from 'core/ui/LoaderSm';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import InputPhone from 'core/ui/InputPhone';
import AddIcon from '@material-ui/icons/Add';
import Divider from 'core/ui/Divider';
import Paper from 'core/ui/Paper';
import SelectFile from './SelectFile';

export default ({ close, list }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [currentContact, setCurrentContact] = useState('');
  const loading = useSelector((state) => messageListsSelectors.getLoading(state));
  const [clearPhone, setClearPhone] = useState(false);

  useEffect(() => {
    setData((prev) => ({ ...prev, ...list }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveList = () => {
    if (!data.name) {
      return;
    }
    if (list.id) {
      dispatch(
        messageListsActions.putMessageListsStart({ ...data, file: data.file }, () => {
          setData({});
          dispatch(messageListsActions.getMessageListsStart());
          close();
        }),
      );
      return;
    }
    dispatch(
      messageListsActions.postMessageListsStart(
        { name: data.name, newContacts: data.newContacts, file: data.file },
        () => {
          setData({});
          dispatch(messageListsActions.getMessageListsStart());
          close();
        },
      ),
    );
  };

  const addNewContact = () => {
    if (!currentContact.phone) {
      return;
    }
    setClearPhone(true);
    const array = data.newContacts || [];
    array.push(currentContact);
    setData((prev) => ({ ...prev, newContacts: array }));
    setCurrentContact({});
    setTimeout(() => {
      setClearPhone(false);
    }, 300);
  };

  return (
    <Box p={5}>
      <Box mb={3}>
        <TextFieldRounded
          label={t('admin.messageList.name')}
          value={data.name || ''}
          onChange={(v) => setData((prev) => ({ ...prev, name: v }))}
        />
      </Box>

      <Box mb={3} display="flex" alignItems="center" flexDirection="column">
        <Typography mb={3}>{t('admin.messageList.contacts')}</Typography>
        <SelectFile setFile={(f) => setData((prev) => ({ ...prev, file: f }))} file={data.file} />
        <Typography mt={1} style={fontStyle.small} align="center">
          <Link href="/example_message_list_contacts.csv">Download Template</Link>
        </Typography>
      </Box>

      <Box mb={3} display="flex" alignItems="center" flexDirection="column">
        <Typography mb={3}>{t('admin.messageList.addContactsManually')}</Typography>

        <Box display="flex" alignItems="center" flexDirection="column">
          <TextFieldRounded
            placeholder="Name"
            value={currentContact.name || ''}
            onChange={(v) => setCurrentContact((prev) => ({ ...prev, name: v }))}
          />
          <Box ml={-1} mt={3} display="flex" alignItems="center" flexGrow={1}>
            {!clearPhone && (
              <InputPhone
                name="phone"
                setValue={(n) => setCurrentContact((prev) => ({ ...prev, phone: n.number }))}
                placeholder="(55) 555-5555"
              />
            )}
            <Box ml={1}>
              <ButtonPrimarySm fullWidth onClick={() => addNewContact()}>
                <AddIcon />
              </ButtonPrimarySm>
            </Box>
          </Box>
        </Box>
      </Box>

      {data.newContacts && data.newContacts.length > 0 && (
        <Paper my={3} p={3}>
          {data.newContacts.map((contact, index) => (
            <Box key={index.toString()}>
              <Typography>
                {contact.name} - {contact.phone}
              </Typography>
              <Divider />
            </Box>
          ))}
        </Paper>
      )}

      <Box m={1} pt={5}>
        <ButtonPrimarySm fullWidth disabled={loading} p={1} onClick={() => saveList()}>
          <LoaderSm loading={loading} width={25} />
          {t('button.saveList')}
        </ButtonPrimarySm>
      </Box>
    </Box>
  );
};
