/* eslint-disable react/jsx-no-target-blank */
import React, { useContext } from 'react';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import Card from 'core/ui/Card';
import { primary } from 'core/ui/Colors';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import { IconButton } from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import Typography from 'core/ui/Typography';
import { ChannelContext } from '.';

const RequiredVideos = () => {
  const formContext = useContext(ChannelContext);
  const { t } = useTranslation();

  return (
    <Box>
      <Card
        btopcolor={primary}
        btopwidth={2}
        hidedivider="true"
        title={t('channel.required.videos')}
      >
        <Box p={3}>
          <Typography mb={3} variant="caption">
            {t('channel.required.videos.desc')}
          </Typography>
          <Box display="flex" alignItems="center" flexGrow="1" mt={2}>
            <TextFieldRounded
              name="requiredVideoCoach"
              error={formContext.errors.requiredVideoCoach}
              placeholder={t('channel.required.coach')}
              inputRef={formContext.register({ required: false })}
            />
            <Box ml={2}>
              <IconButton onClick={() => formContext.setValue('requiredVideoCoach', '')}>
                <Clear />
              </IconButton>
            </Box>
          </Box>
          <Box display="flex" alignItems="center" flexGrow="1" mt={2}>
            <TextFieldRounded
              name="requiredVideoAthlete"
              error={formContext.errors.requiredVideoAthlete}
              placeholder={t('channel.required.athlete')}
              inputRef={formContext.register({ required: false })}
            />
            <Box ml={2}>
              <IconButton onClick={() => formContext.setValue('requiredVideoAthlete', '')}>
                <Clear />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default RequiredVideos;
