import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import DatePickerRounded from 'core/ui/DatePickers/DatePickerRounded';
import { ButtonPrimarySm } from 'core/ui/Button';
import { Search } from '@material-ui/icons';
import { formatDateToApi } from 'core/utils/formatters';
import Grid from 'core/ui/Grid';
import Card from 'core/ui/Card';
import Typography from 'core/ui/Typography';
import { primary } from 'core/ui/Colors';
import { channelReportsModule } from 'modules/channelReports';
import useSelectors from 'modules/map/useSelectors';
import SkeletonPayamentsReport from './Skeleton';
import ResultTracks from './ResultTracks';
import BarHours from './BarHours';

const SifelyLogs = ({ channel, loading, getReport }) => {
  const { t } = useTranslation();

  const data = useSelectors(channelReportsModule, 'data');
  const dateNow = new Date();
  const [filters, setFilters] = useState({
    startAt: `${dateNow.getMonth() + 1}/1/${dateNow.getFullYear()}`,
    endAt: `${dateNow.getMonth() + 1}/${dateNow.getDate()}/${dateNow.getFullYear()}`,
    workoutId: '',
  });

  const search = () => {
    const startAt = filters.startAt ? formatDateToApi(filters.startAt) : null;
    const endAt = filters.endAt ? formatDateToApi(filters.endAt) : null;
    if (startAt && endAt) getReport({ startAt, endAt, categoryId: filters.categoryId || null });
  };

  useEffect(() => {
    search();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react/prop-types
  const CardSummary = ({ title, total, size }) => (
    <Grid item xs={6} sm={6} md={size} lg={size}>
      <Card btopcolor={primary} btopwidth={2} hidedivider="true" title={title}>
        <Box p={3} display="flex" flexDirection="column" alignItems="center">
          <Typography variant="h2" color="primary">
            {total}
          </Typography>
        </Box>
      </Card>
    </Grid>
  );

  return (
    <Box mt={2}>
      <Box>
        <Box display="flex" mb={3} alignItems="flex-end" justifyContent="center" flexWrap="wrap">
          <Box>
            <DatePickerRounded
              label={t('channel.reports.filter.start').toUpperCase()}
              onChange={(v) => setFilters((prev) => ({ ...prev, startAt: v }))}
              value={filters.startAt}
              disablePast={false}
              usFormat={channel?.useUsDateFormat}
              autoOk
            />
          </Box>
          <Box ml={1}>
            <DatePickerRounded
              label={t('channel.reports.filter.end').toUpperCase()}
              onChange={(v) => setFilters((prev) => ({ ...prev, endAt: v }))}
              value={filters.endAt}
              disablePast={false}
              usFormat={channel?.useUsDateFormat}
              autoOk
            />
          </Box>
          <Box ml={3}>
            <ButtonPrimarySm disabled={loading} onClick={() => search()}>
              <Search style={{ color: 'white' }} fontSize="small" />
              {t('button.search')}&nbsp;
            </ButtonPrimarySm>
          </Box>
        </Box>

        {!data.logs || loading ? (
          <SkeletonPayamentsReport />
        ) : (
          <Box>
            <Grid container spacing={3} my={3}>
              <CardSummary
                size={3}
                total={data?.resume?.totalUsers}
                title={t('channel.reports.hybrid.summary.totalUsers')}
              />
              <CardSummary
                size={3}
                total={data?.resume?.avgUsers}
                title={t('channel.reports.hybrid.summary.avgUsers')}
              />
              <CardSummary
                size={3}
                total={data?.resume?.totalUses}
                title={t('channel.reports.hybrid.summary.totalUses')}
              />
            </Grid>
            <ResultTracks channel={channel} logs={data ? data.logs : []} />
            <Box>
              <BarHours hours={data?.chartHours} />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

SifelyLogs.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  loading: PropTypes.bool,
  getReport: PropTypes.func,
};

SifelyLogs.defaultProps = {
  loading: false,
  getReport: () => {},
};

export default SifelyLogs;
