import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import Box from 'core/ui/Box';
import Typography, { fontStyle } from 'core/ui/Typography';
import { InputWrapper } from 'core/ui/ChosenComponents';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import { ButtonPrimary } from 'core/ui/Button';
import { contactFormsModule } from 'modules/contactForms';
import useSelectors from 'modules/map/useSelectors';
import { Dialog, DialogContent } from '@material-ui/core';
import SelectorChannelUser from 'components/Channel/SelectorChannelUser';

export const FiltersFormContacts = ({ control, setModalConversations, search }) => {
  const { t } = useTranslation();
  const loading = useSelectors(contactFormsModule, 'loading');
  const [modalUsers, setModalUsers] = useState(false);
  return (
    <Box mb={3}>
      <Box display="flex" alignItems="flex-end" className="tw-gap-2">
        <Box flexGrow={1}>
          <Typography mb={1.5} style={fontStyle.label}>
            {t('label.search')}
          </Typography>

          <InputWrapper>
            <Box style={{ width: '100%' }}>
              <Controller
                control={control}
                name="query"
                render={({ field }) => (
                  <TextFieldRounded
                    value={field.value}
                    onChange={field.onChange}
                    style={{ minWidth: 30, width: '100%', margin: '4px' }}
                    placeholder={t('channel.members.search')}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        search(1);
                      }
                    }}
                  />
                )}
              />
            </Box>
          </InputWrapper>
        </Box>

        <Box>
          <ButtonPrimary disabled={loading} style={{ height: 44 }} onClick={() => search(1)}>
            {t('button.search')}
          </ButtonPrimary>
        </Box>
        <Box>
          <Dialog open={modalUsers?.open} fullWidth maxWidth="sm" onClose={() => setModalUsers({})}>
            <DialogContent className="tw-bg-gray-100">
              <SelectorChannelUser
                onSelectedUser={(u) => {
                  setModalConversations({ open: true, userId: u?.id });
                  setModalUsers({});
                }}
              />
            </DialogContent>
          </Dialog>
          <ButtonPrimary
            disabled={loading}
            style={{ height: 44 }}
            onClick={() => setModalUsers({ open: true })}
          >
            {t('button.newContact')}
          </ButtonPrimary>
        </Box>
      </Box>
    </Box>
  );
};
