import React, { createContext, useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import SideBarVertical from 'components/Stream/SideBar/Vertical';
import SideBarHorizontal from 'components/Stream/SideBar/Horizontal';
import { useSelector, useDispatch } from 'react-redux';
import { sessionSelectors } from 'modules/session';
import { Hidden } from '@material-ui/core';
import Stream from 'components/Stream';
import clsx from 'clsx';
import { useLocation, useParams } from 'react-router';
import { workoutsActions, workoutsSelectors } from 'modules/workouts';
import { sessionWorkoutActions } from 'modules/sessionWorkout';
import Box from 'core/ui/Box';
import ModalScore from 'components/Stream/SideBar/components/Sections/ModalScore';
import useDeepLinks, { AppScreens } from 'core/useDeepLinks';
import useWorkoutUsersSocket from 'components/Stream/workoutUsersSocket';
import { parse } from 'query-string';

export const StreamContext = createContext();

export const drawerStreamSize = 70;
export const drawerStreamWidth = 500;

export default () => {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      overflow: 'hidden',
    },
    appBar: {
      width: `calc(100% - ${drawerStreamSize}px)`,
      marginRight: drawerStreamSize,
    },
    drawer: {
      justifyContent: 'center',
      flexShrink: 0,
      background: 'transparent',
      border: 'none',
    },
    drawerOpen: {
      width: drawerStreamWidth,
      background: 'transparent',
      border: 'none',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: drawerStreamSize,
      background: 'transparent',
      border: 'none',
    },
    drawerBottomOpen: {
      height: '100%',
      border: 'none',
      backgroundColor: '#373738',
      transition: theme.transitions.create('height', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerBottomClose: {
      transition: theme.transitions.create('height', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      border: 'none',
      overflowX: 'hidden',
      backgroundColor: '#373738',
    },
    content: {
      flexGrow: 1,
    },
  }));

  const [sectionModal, setSectionModal] = useState({
    open: false,
  });
  const [inviteId, setInviteId] = useState(null);
  const currentUser = useSelector((state) => sessionSelectors.getUser(state));
  const workout = useSelector((state) => workoutsSelectors.getWorkout(state));

  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(false);
  const dispatch = useDispatch();
  const { id } = useParams();

  const location = useLocation();

  const deep = useDeepLinks();

  useEffect(() => {
    deep.openInApp({ screen: AppScreens.Stream.View, param: id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [controlPermission, setControlPermission] = useState({
    changeAudio: false,
    changeVideo: false,
  });
  const [zoomView, setZoomView] = useState(1);
  const [openModalEnded, setOpenModalEnded] = useState({ open: false });
  const [countDownCompetition, setCountDownCompetition] = useState({ open: false });

  useWorkoutUsersSocket({ workoutId: id });

  useEffect(() => {
    dispatch(workoutsActions.clearWorkout());
    dispatch(sessionWorkoutActions.clearToken());

    const parsed = parse(location.search);
    dispatch(workoutsActions.getWorkoutStart(id, null, parsed.inviteId));
    setInviteId(parsed.inviteId);
    dispatch(workoutsActions.getWorkoutUsersStart(id));
    dispatch(workoutsActions.getWorkoutCoachesStart(id));
    dispatch(workoutsActions.getWorkoutSectionsStart(id));
  }, [dispatch, id, location]);

  useEffect(() => {
    if (workout && workout?.id) {
      if (!workout.isCompetition && workout.method === 'live' && !workout.online) {
        window.location = `/workout/view/${workout?.id}`;
      }
    }
  }, [workout]);

  const [eventSideBar, setEventSideBar] = useState({});
  const [componentSideBar, setComponentSideBar] = useState('');
  const loading = useSelector((state) => sessionSelectors.getLoading(state));
  const [unreadMessages, setUnreadMessages] = useState(0);
  const [modalRecording, setModalRecording] = useState({ open: false });

  const openSideBar = useCallback(
    async ({ component, open = false }) => {
      setTimeout(() => {
        if (component !== componentSideBar) {
          setOpenDrawer(true);
          setComponentSideBar(component);
        } else if (!open) {
          setOpenDrawer(!openDrawer);
        } else {
          setOpenDrawer(true);
        }
      }, 10);
    },
    [openDrawer, componentSideBar],
  );

  return (
    <StreamContext.Provider
      value={{
        openDrawer,
        openSideBar,
        componentSideBar,
        loading,
        setEventSideBar,
        eventSideBar,
        unreadMessages,
        setUnreadMessages,
        controlPermission,
        setControlPermission,
        modalRecording,
        setModalRecording,
        zoomView,
        setZoomView,
        openModalEnded,
        setOpenModalEnded,
        countDownCompetition,
        setCountDownCompetition,
        sectionModal,
        setSectionModal,
        inviteId,
      }}
    >
      <div className={`background-workout ${classes.root}`}>
        {!loading && (
          <main className={classes.content}>
            <Stream />
          </main>
        )}

        {/* <ClickAwayListener onClickAway={() => setOpenDrawer(false)}> */}
        <Box>
          <Hidden only={['xs', 'sm']}>
            <Drawer
              variant="permanent"
              className={clsx(classes.drawer, {
                [classes.drawerOpen]: openDrawer,
                [classes.drawerClose]: !openDrawer,
              })}
              classes={{
                paper: clsx({
                  [classes.drawerOpen]: openDrawer,
                  [classes.drawerClose]: !openDrawer,
                }),
              }}
              anchor="right"
            >
              <SideBarVertical />
            </Drawer>
          </Hidden>
          <Hidden only={['md', 'lg', 'xl']}>
            <Drawer
              variant="permanent"
              className={clsx('', {
                [classes.drawerBottomOpen]: openDrawer,
                [classes.drawerBottomClose]: !openDrawer,
              })}
              classes={{
                paper: clsx({
                  [classes.drawerBottomOpen]: openDrawer,
                  [classes.drawerBottomClose]: !openDrawer,
                }),
              }}
              anchor="bottom"
            >
              <SideBarHorizontal />
            </Drawer>
          </Hidden>
        </Box>
        {/* </ClickAwayListener> */}

        {sectionModal.open && (
          <>
            {!workout.isCoach ? (
              <ModalScore
                workout={workout}
                open={sectionModal.open}
                setOpen={(v) => setSectionModal((prev) => ({ ...prev, open: v }))}
                sectionData={sectionModal.section}
                user={currentUser}
              />
            ) : (
              <ModalScore
                workout={workout}
                open={sectionModal.open}
                sectionData={{
                  ...sectionModal.section,
                  userData: sectionModal.userData,
                }}
                setOpen={(v) => setSectionModal((prev) => ({ ...prev, open: v }))}
                user={sectionModal.user && sectionModal.user}
              />
            )}
          </>
        )}
      </div>
    </StreamContext.Provider>
  );
};
