import React, { useEffect, useContext, useState, createRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import { useTranslation } from 'react-i18next';

import { useSelector, useDispatch } from 'react-redux';
import propTypes from 'prop-types';
import { workoutsSelectors, workoutsActions } from 'modules/workouts';

import Box from 'core/ui/Box';
import { ButtonPrimary } from 'core/ui/Button';
import Typography from 'core/ui/Typography';

import { StreamContext } from 'pages/Stream';
import { sessionSelectors } from 'modules/session';
import Divider from 'core/ui/Divider';
import FormScore from 'components/Stream/SideBar/components/Sections/FormScore';
import ViewScores from 'components/Stream/SideBar/components/Sections/ViewScores';
import { listScores } from 'core/utils/helpers';
import { Link } from 'react-router-dom';
import { sessionWorkoutActions } from 'modules/sessionWorkout';
import ConfirmDialog from 'core/ui/ConfirmDialog';
import LoaderSm from 'core/ui/LoaderSm';
import { primary } from 'core/ui/Colors';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#ececec',
  },
}));

export default function ModalEnded({ open, setOpen, edit }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const loading = useSelector((state) => workoutsSelectors.getLoading(state));
  const workout = useSelector((state) => workoutsSelectors.getWorkout(state));
  const user = useSelector((state) => sessionSelectors.getUser(state));
  const streamContext = useContext(StreamContext);
  const dispatch = useDispatch();
  const [viewScore, setViewScore] = useState(false);
  const [dialogConfirmRestart, setDialogConfirmRestart] = useState({ open: false });

  const sections = workout?.sections?.map((x) => x.detail || x);

  useEffect(() => {
    if (workout.id) {
      dispatch(
        workoutsActions.getWorkoutSectionUserStart({
          workoutId: workout.id,
          userId: user.id,
        }),
      );
    }
  }, [workout, user, dispatch]);

  useEffect(() => {
    setViewScore((prev) => (workout.isCompetition ? !edit : prev));
  }, [workout, edit]);

  const [refsSections, setRefsSections] = useState({});

  useEffect(() => {
    const refs = { ...refsSections };
    if (sections) {
      sections.forEach((e) => {
        refs[e.id] = createRef();
      });
    }
    setRefsSections(refs);
  }, [workout]); //eslint-disable-line

  const saveAllSections = () => {
    sections.forEach((s) => {
      refsSections[s.id].current.saveSection();
    });
    setTimeout(() => {
      setViewScore(true);
    }, 1000);
  };

  const hasScoresInSections = () => sections && !!sections.find((s) => !!listScores(s, t));

  const resetWorkout = () => {
    setDialogConfirmRestart({
      open: true,
      callback: () => {
        dispatch(
          sessionWorkoutActions.resetWorkoutCompetitionStart({
            workoutId: workout.id,
            callback: () => {
              const loc = window.location;
              window.location = loc;
            },
          }),
        );
      },
    });
  };

  return (
    <div>
      <ConfirmDialog
        open={dialogConfirmRestart.open || dialogConfirmRestart.confirmAgain}
        setOpen={(b) => setDialogConfirmRestart((prev) => ({ ...prev, open: b }))}
        description={t(
          !dialogConfirmRestart.confirmAgain
            ? 'competition.workout.replace'
            : 'competition.areYouSure',
        )}
        labelConfirm={t('button.yes')}
        labelCancel={t('button.no')}
        styleBody={{ fontSize: 24, color: '#000' }}
        cancel={() =>
          setDialogConfirmRestart((prev) => ({ ...prev, open: false, confirmAgain: false }))
        }
        confirm={() =>
          !dialogConfirmRestart.confirmAgain
            ? setDialogConfirmRestart((prev) => ({ ...prev, open: true, confirmAgain: true }))
            : dialogConfirmRestart.callback()
        }
      />
      <Dialog
        PaperProps={{ style: { borderRadius: 5, minWidth: 300 } }}
        maxWidth="sm"
        fullWidth
        scroll="body"
        onClose={() =>
          workout.isCompetition && !streamContext.openModalEnded.close ? null : setOpen(false)
        }
        open={open}
      >
        <Box p={5} className={classes.root}>
          {loading && (
            <Box display="flex" justifyContent="center">
              <LoaderSm loading />
            </Box>
          )}
          {!loading && !workout.isCompetition && (
            <Typography mb={5} align="center" variant="h4">
              {t('workout.recording.ended')}
            </Typography>
          )}
          {!loading && !edit && workout.isCompetition && (
            <Typography align="center" variant="h4">
              {t('workout.competition.startAgainMessege')}
            </Typography>
          )}
          {(workout.isCompetition || workout.method === 'video') && hasScoresInSections() ? (
            <Box display="flex" justifyContent="center" flexDirection="column">
              {workout &&
                sections
                  .filter((s) => !!listScores(s, t))
                  .map((section) => (
                    <Box key={section.id}>
                      <Box mt={2}>
                        {!workout.isCompetition && (
                          <Typography
                            align="center"
                            color="primary"
                            style={{ fontSize: 14 * streamContext.zoomView }}
                          >
                            {section?.title?.toUpperCase()}
                          </Typography>
                        )}
                        <Box>
                          {!viewScore && (
                            <FormScore
                              ref={refsSections[section.id]}
                              sectionData={section}
                              user={user}
                              showSaveButton={false}
                              workout={workout}
                              buttonColor={workout?.channel?.customColors?.buttonColor || primary}
                            />
                          )}
                          {viewScore && <ViewScores section={section} />}
                        </Box>
                      </Box>
                      <Divider mx={5} my={1} />
                    </Box>
                  ))}

              {!viewScore ? (
                <Box display="flex" mt={5} justifyContent="center">
                  <ButtonPrimary style={{ width: '100%' }} onClick={() => saveAllSections()}>
                    {t('button.saveScores')}
                  </ButtonPrimary>
                </Box>
              ) : (
                <Box display="flex" mt={5} justifyContent="center">
                  <ButtonPrimary
                    mx={1}
                    style={{ width: '100%' }}
                    onClick={() => streamContext.eventSideBar.exit()}
                  >
                    {t('button.exit')}
                  </ButtonPrimary>
                  {!edit && (
                    <ButtonPrimary mx={1} style={{ width: '100%' }} onClick={() => resetWorkout()}>
                      {t('button.continue')}
                    </ButtonPrimary>
                  )}
                </Box>
              )}
            </Box>
          ) : (
            <Box display="flex" mt={5} justifyContent="center">
              {workout.isCompetition && (
                <ButtonPrimary
                  style={{ width: '100%' }}
                  onClick={() =>
                    streamContext.setOpenModalEnded((prev) => ({ ...prev, open: false }))
                  }
                >
                  {t(workout.isCompetition ? 'button.ok' : 'button.exit')}
                </ButtonPrimary>
              )}
              {!loading && !workout.isCompetition && (
                <Link className="link" to={`/workout/view/${workout.slug}`}>
                  <ButtonPrimary style={{ width: '100%' }}>{t('button.exit')}</ButtonPrimary>
                </Link>
              )}
            </Box>
          )}
        </Box>
      </Dialog>
    </div>
  );
}

ModalEnded.propTypes = {
  edit: propTypes.bool,
  open: propTypes.bool,
  setOpen: propTypes.func,
};

ModalEnded.defaultProps = {
  edit: false,
  open: false,
  setOpen: null,
};
