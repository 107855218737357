import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from 'core/ui/Box';

import { Scrollbars } from 'react-custom-scrollbars';

import { StreamContext } from 'pages/Stream';
import FiberSmartRecordIcon from '@material-ui/icons/FiberSmartRecord';
import { useDispatch, useSelector } from 'react-redux';
import { roomActions, roomSelectors } from 'modules/room';
import FontIcon from 'core/ui/FontIcon';
import { ButtonSideBar } from 'core/ui/Button';
import useEvents from 'core/openVidu/useEvents';
import { Settings, VolumeOff, VolumeUp } from '@material-ui/icons';
import Typography from 'core/ui/Typography';
import ConfirmDialog from 'core/ui/ConfirmDialog';
import { useTranslation } from 'react-i18next';
import { primary, onlineColor } from 'core/ui/Colors';
import { sessionWorkoutSelectors } from 'modules/sessionWorkout';
import LoaderSm from 'core/ui/LoaderSm';
import ScreenShareIcon from '@material-ui/icons/ScreenShare';
import StopScreenShareIcon from '@material-ui/icons/StopScreenShare';
import { workoutsSelectors } from 'modules/workouts';
import { useHistory } from 'react-router';
import ModalCalculator from 'components/Profile/Dashboard/PersonalRecord/ModalCalculator';
import CalculatorIcon from 'core/ui/MIcon/CalculatorIcon';
import LeaderboardIcon from 'core/ui/MIcon/LeaderboardIcon';
import Components, { ComponentsSideBar } from './components';
import Exit from './Exit';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    overflow: 'hidden',
  },
  sideBar: {
    background: '#404041',
    width: '100%',
    height: 60,
    minHeight: 60,
    overflow: 'hide',
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    width: 30,
    height: 30,
  },
  content: {
    flexGrow: 1,
    height: '100%',
    overflow: 'auto',
  },
  icon: {
    width: 30,
  },
}));

export default () => {
  const streamContext = useContext(StreamContext);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [modalCalculator, setModalCalculator] = useState(false);
  const user = useSelector((state) => roomSelectors.getUser(state));
  const { camStatusChange, micStatusChanged, exit, isCoach } = useEvents();
  const loadingRecording = useSelector((state) =>
    sessionWorkoutSelectors.getLoadingRecording(state),
  );
  const workout = useSelector((state) => workoutsSelectors.getWorkout(state));
  const recording = useSelector((state) => sessionWorkoutSelectors.getRecording(state));
  const mutedRecordings = useSelector((state) => roomSelectors.getMutedRecordings(state));

  const { t } = useTranslation();
  const [dialogCancel, setDialogCancel] = useState(false);
  const openMessages = () => {
    streamContext.openSideBar({ component: ComponentsSideBar.Chat });
    streamContext.setUnreadMessages(0);
  };
  const history = useHistory();

  return (
    <div className={classes.root}>
      <ConfirmDialog
        open={dialogCancel}
        setOpen={setDialogCancel}
        title={t('workout.stream.exit.title')}
        description={t('workout.stream.exit.description')}
        labelConfirm={t('confirm')}
        labelCancel={t('cancel')}
        confirm={() => (workout.live ? exit() : history.push(`/workout/view/${workout.slug}`))}
      />
      {modalCalculator && <ModalCalculator close={() => setModalCalculator(false)} />}
      <Box className={`${streamContext.openDrawer === true ? '' : 'hide'} ${classes.content}`}>
        <Scrollbars>
          <Components />
        </Scrollbars>
      </Box>
      <div className={classes.sideBar}>
        <Scrollbars
          autoHide
          renderTrackVertical={(props) => (
            <div {...props} style={{ display: 'none' }} className="track-horizontal" />
          )}
          style={{ width: '100%', display: 'flex', alignItems: 'center' }}
        >
          <Box display="flex" pt={3} flexWrap="nowrap">
            <Box display="flex" mx={1} alignItems="center">
              <ButtonSideBar
                mr={1}
                onClick={() => streamContext.openSideBar({ component: ComponentsSideBar.Settings })}
              >
                <Settings
                  style={{
                    color:
                      streamContext.componentSideBar === ComponentsSideBar.Settings &&
                      streamContext.openDrawer === true
                        ? primary
                        : 'white',
                    fontSize: 15,
                  }}
                />
              </ButtonSideBar>

              {!workout.isCompetition && (
                <ButtonSideBar
                  mr={1}
                  onClick={() =>
                    streamContext.eventSideBar.setGalleryView(
                      !streamContext.eventSideBar.galleryView,
                    )
                  }
                >
                  <FontIcon
                    iconName={
                      streamContext.eventSideBar.galleryView ? 'icon-speaker-view' : 'icon-menu'
                    }
                    size={15}
                    color="white"
                  />
                </ButtonSideBar>
              )}

              <ButtonSideBar mr={1} onClick={() => setModalCalculator(true)}>
                <CalculatorIcon color="white" />
              </ButtonSideBar>

              {!workout.isCompetition && workout.live && (
                <ButtonSideBar
                  mr={1}
                  onClick={() =>
                    !user.shareScreen ? streamContext.eventSideBar.screenShare() : null
                  }
                >
                  {!user.shareScreen ? (
                    <ScreenShareIcon style={{ color: 'white', fontSize: 20 }} />
                  ) : (
                    <StopScreenShareIcon style={{ color: onlineColor, fontSize: 20 }} />
                  )}
                </ButtonSideBar>
              )}

              {workout.live && (
                <>
                  {workout.recorded && (
                    <ButtonSideBar
                      mr={1}
                      onClick={() => dispatch(roomActions.setMutedRecordings(!mutedRecordings))}
                    >
                      {mutedRecordings ? (
                        <VolumeOff style={{ color: primary, fontSize: 18 }} />
                      ) : (
                        <VolumeUp style={{ color: onlineColor, fontSize: 18 }} />
                      )}
                    </ButtonSideBar>
                  )}
                  <ButtonSideBar mr={1} onClick={() => camStatusChange(true)}>
                    {user !== undefined && user.videoActive === true ? (
                      <FontIcon iconName="icon-cam-on" size={14} color={onlineColor} />
                    ) : (
                      <FontIcon iconName="icon-cam-off" color={primary} size={15} />
                    )}
                  </ButtonSideBar>

                  <ButtonSideBar onClick={() => micStatusChanged(true)}>
                    {user !== undefined && user.audioActive ? (
                      <FontIcon iconName="icon-microphone-on" color={onlineColor} size={15} />
                    ) : (
                      <FontIcon iconName="icon-microphone-off" color={primary} size={15} />
                    )}
                  </ButtonSideBar>
                </>
              )}
            </Box>
            <Box flexGrow="1" display="flex" alignItems="center" justifyContent="center">
              <Box display="flex">
                {!workout.recorded && !workout.isCompetition && isCoach() && workout.live && (
                  <ButtonSideBar
                    mr={1}
                    onClick={() => streamContext.setModalRecording({ open: true })}
                  >
                    {loadingRecording ? (
                      <LoaderSm loading />
                    ) : (
                      <FiberSmartRecordIcon
                        className={recording.active ? 'recording-effect' : ''}
                        style={{ fontSize: 16, color: recording.active ? primary : 'white' }}
                      />
                    )}
                  </ButtonSideBar>
                )}
                {!workout.isCompetition && (
                  <ButtonSideBar
                    mr={1}
                    onClick={() =>
                      streamContext.openSideBar({ component: ComponentsSideBar.Participants })
                    }
                  >
                    {streamContext.componentSideBar === ComponentsSideBar.Participants &&
                    streamContext.openDrawer === true ? (
                      <FontIcon iconName="icon-users" color={primary} size={10} />
                    ) : (
                      <FontIcon iconName="icon-users" color="white" size={10} />
                    )}
                  </ButtonSideBar>
                )}
                <ButtonSideBar
                  mr={1}
                  pt={3}
                  pb={3}
                  onClick={() =>
                    streamContext.openSideBar({ component: ComponentsSideBar.Sections })
                  }
                >
                  {streamContext.componentSideBar === ComponentsSideBar.Sections &&
                  streamContext.openDrawer === true ? (
                    <FontIcon iconName="icon-dumbbell" color={primary} size={20} />
                  ) : (
                    <FontIcon iconName="icon-dumbbell" color="white" size={20} />
                  )}
                </ButtonSideBar>
                {!workout.isCompetition && workout.live && (
                  <ButtonSideBar onClick={() => openMessages()}>
                    {streamContext.componentSideBar === ComponentsSideBar.Chat &&
                    streamContext.openDrawer === true ? (
                      <FontIcon iconName="icon-chat-dialog" color={primary} size={12} />
                    ) : (
                      <>
                        {streamContext.unreadMessages !== 0 && (
                          <Box className="badge-notification-message">
                            <Typography style={{ color: 'white' }}>
                              {streamContext.unreadMessages}
                            </Typography>
                          </Box>
                        )}
                        <FontIcon iconName="icon-chat-dialog" color="white" size={12} />
                      </>
                    )}
                  </ButtonSideBar>
                )}
                <ButtonSideBar
                  ml={1}
                  onClick={() =>
                    streamContext.openSideBar({ component: ComponentsSideBar.Leaderboard })
                  }
                >
                  <LeaderboardIcon
                    color={
                      streamContext.componentSideBar === ComponentsSideBar.Leaderboard &&
                      streamContext.openDrawer === true
                        ? primary
                        : 'white'
                    }
                    size={22}
                  />
                </ButtonSideBar>
              </Box>
            </Box>
            <Box display="flex" alignItems="center">
              <Exit exit={exit} />
            </Box>
          </Box>
        </Scrollbars>
      </div>
    </div>
  );
};
