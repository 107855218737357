import api from 'core/api';

export const channelKeysModule = 'channelKeys';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const actions = {
  updatePostPayment: {
    module: channelKeysModule,
    name: 'updatePostPayment',
    api: (channelKeyId) =>
      api.put(`/channel_keys/${channelKeyId}/update_post_payment?is_post_payment=false`),
    params: {
      start: ['params'],
      error: [''],
      success: ['data'],
    },
    state: loadingStates,
  },
  checkSwitchresume: {
    module: channelKeysModule,
    name: 'checkSwitchresume',
    api: (params) =>
      api.get(`/channel_keys/${params?.channelKeyId}/switch_membership_resume`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },

  confirmSwitchMembership: {
    module: channelKeysModule,
    name: 'confirmSwitchMembership',
    api: (params) =>
      api.post(`/channel_keys/${params?.channelKeyId}/switch_membership_confirm`, params),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
};

const channelKeysModuleObj = {
  actions,
  state: {
    data: [],
    error: null,
    loading: false,
  },
};

export default channelKeysModuleObj;
