import React, { useContext, useEffect, useState } from 'react';
import Box from 'core/ui/Box';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import SFDataTable from 'core/ui/DataTable';
import Typography from 'core/ui/Typography';
import FlagCountry from 'core/ui/FlagCountry';
import { labelLight } from 'core/ui/Colors';
import useSelectors from 'modules/map/useSelectors';
import { competitionsInvitesModule } from 'modules/competitionsInvites';
import { useTranslation } from 'react-i18next';
import LoaderSm from 'core/ui/LoaderSm';
import { ButtonPrimarySm } from 'core/ui/Button';
import { Check, Close } from '@material-ui/icons';
import { CompetitionContext } from '../index';
import CompetitionsSelect from './CompetitionsSelect';

const CompetitionInvites = ({ classes }) => {
  const { t } = useTranslation();
  const formContext = useContext(CompetitionContext);
  const [invites, setInvites] = useState([]);
  const [pagination, setPagination] = useState([]);
  const [perPage, setPerPage] = useState(50);
  const loadingInvites = useSelectors(competitionsInvitesModule, 'loading');

  const { request } = useActions();

  const refreshInvites = (newPage = 1) => {
    request({
      action: Modules.competitionsInvites.actions.getCompetitionsInvites,
      data: {
        competitionId: formContext?.competition?.id,
        page: newPage,
        perPage,
      },
      options: {
        onSuccess: (data, pag) => {
          setInvites(data);
          setPagination(pag);
        },
      },
    });
  };

  const removeInvite = (inviteId) => {
    request({
      action: Modules.competitionsInvites.actions.deleteInvite,
      data: {
        competitionId: formContext?.competition?.id,
        id: inviteId,
      },
      options: {
        onSuccess: () => {
          refreshInvites(pagination?.currentPage || 1);
        },
      },
    });
  };

  useEffect(() => {
    if (formContext?.competition?.id) {
      refreshInvites(1);
    }
  }, [formContext?.competition?.id]);

  const columns = [
    {
      name: 'User',
      selector: (row) => row?.user?.name,
      sortable: true,
      cell: (row) => (
        <Box flexGrow="1" display="flex" alignItems="center">
          <FlagCountry flag={row?.user?.country?.flag} size={40} />

          <Box ml={2}>
            <Typography noWrap style={{ color: labelLight }}>
              {row?.user?.name}
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      name: 'Division',
      selector: (row) => row?.competitionDivision?.name,
      sortable: true,
      cell: (row) => <Typography>{row?.competitionDivision?.name}</Typography>,
    },
    {
      name: 'Registered',
      selector: (row) => row?.registered,
      sortable: true,
      cell: (row) => (
        <Typography>
          {row?.registered ? <Check color="primary" /> : <Close color="error" />}
        </Typography>
      ),
    },
    {
      name: '',
      selector: 'name',
      sortable: false,
      cell: (row) => (
        <Box>
          <ButtonPrimarySm onClick={() => removeInvite(row?.id)}>
            {t('button.remove')}
          </ButtonPrimarySm>
        </Box>
      ),
    },
  ];

  return (
    <Box>
      <CompetitionsSelect classes={classes} refreshInvites={refreshInvites} />

      <Box p={3} className={classes.content} mt={3}>
        <Box display="flex" className="gap-10">
          <Typography variant="h5" mb={2}>
            {t('competition.invites')}
          </Typography>
          <LoaderSm loading={loadingInvites} />
        </Box>
        <SFDataTable
          columns={columns}
          data={invites}
          pagination
          showExport={false}
          noHeader
          paginationPerPage={perPage}
          onChangePage={(p) => refreshInvites(p)}
          paginationServer
          paginationDefaultPage={pagination?.currentPage}
          paginationTotalRows={pagination?.totalCount}
          onChangeRowsPerPage={(newValue) => {
            setPerPage(newValue);
          }}
          paginationRowsPerPageOptions={[10, 15, 20, 30, 50, 100]}
        />
      </Box>
    </Box>
  );
};

export default CompetitionInvites;
