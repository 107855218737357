import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import TextAreaFieldRounded from 'core/ui/TextAreaFieldRounded';
import Box from 'core/ui/Box';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import { WorkoutContext } from './index';
import UploadAttachments from './UploadAttachments';

export default () => {
  const { t } = useTranslation();
  const formContext = useContext(WorkoutContext);

  return (
    <>
      <Box mb={4}>
        <TextFieldRounded
          name="name"
          error={formContext.errors.name}
          placeholder={t(
            formContext.competition
              ? 'workout.competition.label.className'
              : 'workout.label.className',
          )}
          label={t(
            formContext.competition
              ? 'workout.competition.label.className'
              : 'workout.label.className',
          ).toUpperCase()}
          inputRef={formContext.register({ required: false })}
        />
      </Box>
      {!formContext.competition && (
        <Box mb={4} mt={5}>
          <TextAreaFieldRounded
            name="description"
            error={formContext.errors.description}
            placeholder={t(
              formContext.competition
                ? 'workout.competition.placeholder.description'
                : 'workout.placeholder.description',
            )}
            label={t('description').toUpperCase()}
            inputRef={formContext.register({
              required: false,
            })}
          />
        </Box>
      )}

      {!!formContext.competition && (
        <Box>
          {formContext?.selectedCompetition && formContext.selectedCompetition?.stageLocations && (
            <SelectBoxObject
              label={t('competition.label.stageLocation').toUpperCase()}
              value={formContext?.watch('competitionsStageLocationId')}
              setValue={(v) => formContext?.setValue('competitionsStageLocationId', v)}
              options={formContext.selectedCompetition?.stageLocations}
            />
          )}

          <UploadAttachments />
        </Box>
      )}
    </>
  );
};
