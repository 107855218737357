import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Box from 'core/ui/Box';
import { Dialog, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import Typography from 'core/ui/Typography';
import { secondary } from 'core/ui/Colors';
import Form from 'components/Account/Form';
import FormCardPointe from 'components/Account/CardPointe/Form';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import { channelsSelectors } from 'modules/channels';
import { useSelector } from 'react-redux';
import { usersAddressesModule } from 'modules/usersAddresses';
import useSelectors from 'modules/map/useSelectors';
import { ButtonPrimary } from 'core/ui/Button';
import { useTranslation } from 'react-i18next';
import ModalFormAddress from 'components/Profile/Edit/Addresses/Form/Modal';

const ModalAddPaymentMethod = ({
  textHeader,
  open,
  allowClose,
  userId,
  userInfo,
  onClose,
  showCanadaDebit,
  cardpointe,

  setView,
  setSelectedPaymentMethod,
  onlyMethod,
  bordered,
  showMessageToAddStripePaymentMethod,
  guest,
}) => {
  const [modalAddress, setModalAddress] = useState(false);
  const { t } = useTranslation();
  const { request } = useActions();
  const loadingAddress = useSelectors(usersAddressesModule, 'loadingAddress');

  const channel = useSelector((state) => channelsSelectors.getChannel(state));
  const [address, setAddress] = useState(null);

  const getAddress = () => {
    request({
      action: Modules.usersAddresses.actions.getAddress,
      data: { userId, channelId: channel?.id },
      options: {
        onSuccess: (data) => {
          setAddress(data);
        },
        onError: () => {},
      },
    });
  };

  useEffect(() => {
    if (userId && channel?.id && open) {
      getAddress();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, channel?.id, modalAddress.open, open]);

  return (
    <Dialog open={!!open} maxWidth="xs" fullWidth onClose={() => (allowClose ? onClose() : null)}>
      <Box style={{ backgroundColor: secondary }}>
        <Box style={{ position: 'relative' }}>
          {onClose && (
            <Box
              style={{ position: 'absolute', right: 0, top: 0 }}
              display="flex"
              justifyContent="flex-end"
            >
              <IconButton onClick={() => onClose()}>
                <Close style={{ color: 'white' }} />
              </IconButton>
            </Box>
          )}
          <Box m={5}>
            {textHeader && <Typography mb={2}>{textHeader}</Typography>}

            {!loadingAddress && !address?.id && (
              <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-p-4">
                <Typography className="tw-mb-4 tw-text-lg tw-text-white">
                  {t('paymentMethod.addAddress')}
                </Typography>
                <ButtonPrimary onClick={() => setModalAddress(() => ({ open: true }))}>
                  {t('button.addAddress')}
                </ButtonPrimary>
                {modalAddress.open && (
                  <ModalFormAddress
                    open={modalAddress.open}
                    mode={modalAddress.mode}
                    userId={userId}
                    hideLocation
                    channelId={channel.id}
                    close={() => {
                      getAddress();
                      setModalAddress(() => ({ open: false }));
                    }}
                    callback={() => {
                      getAddress();
                      setModalAddress(() => ({ open: false }));
                    }}
                  />
                )}
              </div>
            )}

            {/* {cardpointe ? (
              <FormCardPointe
                userId={userId}
                closeModal={() => onClose()}
                setSelectedPaymentMethod={setSelectedPaymentMethod}
                setView={() => {}}
              />
            ) : (
              <Form
                setView={() => {}}
                setSelectedPaymentMethod={setSelectedPaymentMethod}
                userId={userId}
                userInfo={userInfo}
                closeModal={() => onClose()}
                showCanadaDebit={showCanadaDebit}
              />
            )} */}

            {!loadingAddress && !!address?.id && (
              <div>
                {cardpointe ? (
                  <FormCardPointe
                    userId={userId}
                    closeModal={onClose}
                    setSelectedPaymentMethod={setSelectedPaymentMethod}
                    setView={setView}
                  />
                ) : (
                  <Form
                    setView={setView}
                    setSelectedPaymentMethod={setSelectedPaymentMethod}
                    userId={userId}
                    userInfo={userInfo}
                    closeModal={onClose}
                    onlyMethod={guest ? 'card' : onlyMethod}
                    bordered={bordered}
                    showCanadaDebit={showCanadaDebit}
                    showMessageToAddStripePaymentMethod={showMessageToAddStripePaymentMethod}
                  />
                )}
              </div>
            )}
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

ModalAddPaymentMethod.propTypes = {
  onClose: PropTypes.func,
  allowClose: PropTypes.bool,
  open: PropTypes.bool,
  textHeader: PropTypes.string,
};

ModalAddPaymentMethod.defaultProps = {
  onClose: () => {},
  open: true,
  allowClose: true,
  textHeader: null,
};

export default ModalAddPaymentMethod;
