import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table, TableCell, TableRow, TableBody, IconButton } from '@material-ui/core';
import Box from 'core/ui/Box';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import { ButtonPrimary } from 'core/ui/Button';
import { Add } from '@material-ui/icons';

import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Typography from 'core/ui/Typography';
import Paper from 'core/ui/Paper';
import Radio from 'core/ui/Radio';

const Coupons = ({ coupons, setCoupons }) => {
  const { t } = useTranslation();
  const addOption = () => {
    setCoupons((prev) => [...prev, {}]);
  };

  const changeDivision = (prop, value, item) => {
    setCoupons((prev) =>
      prev.map((x) => {
        const newItem = { ...x };
        if (x === item) {
          newItem[prop] = value;
        }

        return newItem;
      }),
    );
  };

  const remove = (row) => {
    if (row.id) {
      changeDivision('_destroy', true, row);
    } else {
      setCoupons((prev) => prev.filter((x) => x !== row));
    }
  };

  return (
    <Box py={3}>
      <Box display="flex" alignItems="center">
        <Box flexGrow="1">
          <Typography variant="h5" mb={3} color="primary">
            {t('competition.coupons')}
          </Typography>
        </Box>
        <Box p={1}>
          <ButtonPrimary onClick={() => addOption()}>
            <Add />
          </ButtonPrimary>
        </Box>
      </Box>

      {coupons && coupons?.length > 0 && (
        <Paper p={3}>
          <Table size="small">
            <TableBody>
              {coupons
                ?.filter((x) => !x?._destroy)
                .map((row, index) => (
                  <TableRow key={index.toString()}>
                    <TableCell>
                      <TextFieldRounded
                        value={row.code || ''}
                        onChange={(v) => changeDivision('code', v, row)}
                        placeholder={t('label.code')}
                        bordered
                      />
                    </TableCell>
                    <TableCell>
                      <Box mx={1} ml={4}>
                        <Box display="flex">
                          <Radio
                            label="%"
                            onClick={() => changeDivision('kind', 'percentage', row)}
                            color="primary"
                            checked={row?.kind === 'percentage'}
                          />
                          <Radio
                            label="$"
                            onClick={() => changeDivision('kind', 'money', row)}
                            color="primary"
                            checked={row?.kind === 'money'}
                            mx={2}
                          />
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <TextFieldRounded
                        value={row.amount || ''}
                        onChange={(v) => changeDivision('amount', v, row)}
                        type="number"
                        pattern="[0-9]*"
                        placeholder={t('channel.membership.placeholder.amount')}
                        required
                        currency={row.kind === 'money'}
                        bordered
                      />
                    </TableCell>
                    <TableCell>
                      <TextFieldRounded
                        type="number"
                        pattern="[1-9]*"
                        value={row.limitUse || ''}
                        onChange={(v) => changeDivision('limitUse', v, row)}
                        placeholder={t('competition.coupon.placeholder.limitUse')}
                        bordered
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => remove(row)}>
                        <HighlightOffIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Paper>
      )}
    </Box>
  );
};

Coupons.defaultProps = {};

Coupons.propTypes = {};

export default Coupons;
