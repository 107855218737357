/* eslint-disable react/prop-types */
import * as React from 'react';
import Paper from 'core/ui/Paper';
import { channelCurrency, date } from 'core/utils/formatters';
import Typography from 'core/ui/Typography';
import SFDataTable from 'core/ui/DataTable';
import { Check } from '@material-ui/icons';
import TypographyIcon from 'core/ui/TypographyIcon';

export default function MembershipsTransactions({ rows, channel }) {
  const columns = [
    {
      name: 'Member',
      selector: (row) => row?.user?.name,
      sortable: true,
      cell: (row) => <Typography>{row?.user?.name}</Typography>,
    },
    {
      name: 'Membership Plan',
      selector: (row) => row?.membershipPlan?.name,
      sortable: true,
      cell: (row) => <Typography>{row?.membershipPlan?.name}</Typography>,
    },
    {
      name: 'Date',
      selector: 'date',
      cell: (row) => (
        <Typography>{row?.date && date(row?.date, channel.dateFormatReact)}</Typography>
      ),
      sortable: true,
      center: true,
    },
    {
      name: 'Paid',
      selector: 'success',
      cell: (row) => (
        <Typography>{row?.success && <Check color="primary" fontSize="small" />}</Typography>
      ),
      sortable: true,
      center: true,
    },
    {
      name: 'Amount',
      selector: 'amount',
      sortable: true,
      right: true,
      cell: (row) => <TypographyIcon text={channelCurrency(row.amount, channel?.currency)} />,
    },
  ];

  return (
    <Paper style={{ width: '100%' }}>
      <SFDataTable
        noHeader
        showPaper={false}
        columns={columns}
        data={rows || []}
        pagination
        noDataComponent={null}
        showExport={false}
      />
    </Paper>
  );
}
