/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sessionWorkoutSelectors } from 'modules/sessionWorkout';
import { workoutsSelectors } from 'modules/workouts';
import { hhmmss, timeSeconds, dateAdd } from 'core/utils/formatters/date';
import { Box, ClickAwayListener, Hidden, Popper, Slider, withStyles } from '@material-ui/core';
import { ButtonSideBar } from 'core/ui/Button';
import Typography from 'core/ui/Typography';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { SkipNext, SkipPrevious, VolumeOff, VolumeUp } from '@material-ui/icons';
import PauseIcon from '@material-ui/icons/Pause';
import { workoutEvent } from 'core/utils/consts';
import Paper from 'core/ui/Paper';
import { primary } from 'core/ui/Colors';
import { roomActions, roomSelectors } from 'modules/room';
import { StreamContext } from 'pages/Stream';
import LoaderSm from 'core/ui/LoaderSm';
import { TypesSignal } from 'core/openVidu/useOpenVidu';
import { isSafari } from 'react-device-detect';
import ClassTimeline from './index';
import { RecordingContext } from '../index';

import './style.css';

export default function ClassSlider() {
  const workout = useSelector((state) => workoutsSelectors.getWorkout(state));
  const workoutEvents = useSelector((state) => sessionWorkoutSelectors.getEvents(state));
  const userRoom = useSelector((state) => roomSelectors.getUser(state));
  const seekVideo = useSelector((state) => roomSelectors.getSeekVideo(state));
  const dispatch = useDispatch();
  const streamContext = useContext(StreamContext);
  const recordingContext = useContext(RecordingContext);
  const [openTimeLine, setOpenTimeLine] = useState(false);
  const [startTimer, setStartTimer] = useState(false);
  const timelineRef = useRef();

  const workoutEventsRef = useRef();
  const playingRecordingsRef = useRef();

  useEffect(() => {
    workoutEventsRef.current = workoutEvents;
    playingRecordingsRef.current = recordingContext.playingRecordings;
  });

  const getTotalTimeClass = () => {
    if (workout.method === 'video') {
      return recordingContext.durationVideo;
    }
    return workout.durationMinutes * 60;
  };

  const executeEvent = (seconds) => {
    const newDate = dateAdd(workout.classStartAt, seconds, 'seconds');
    const events = workoutEventsRef.current.filter(
      (e) => timeSeconds(e.createdAt) === timeSeconds(newDate),
    );
    if (
      getTotalTimeClass() > 0 &&
      Math.round(seconds) > 0 &&
      Math.round(seconds) >= getTotalTimeClass()
    ) {
      recordingContext.showModalEnded();
      recordingContext.togglePlayRecordings(false);
    }
    recordingContext.executeEvents(events);
  };

  const convertToSeconds = (formatedTimer) => {
    const arrayTimer = formatedTimer.split(':');

    return Number(arrayTimer[0]) * 60 * 60 + Number(arrayTimer[1]) * 60 + Number(arrayTimer[2]);
  };

  const executeEventVideo = (currentSeconds) => {
    if (!recordingContext.playingRecordings) {
      return;
    }
    if (workout.sections && workout.sections.length > 0) {
      workout.sections.forEach((section) => {
        if (section.timerData && section.timerData.timeStart) {
          const seconds = convertToSeconds(section.timerData.timeStart);

          if (Math.round(seconds - 10) === Math.round(currentSeconds)) {
            if (streamContext.eventSideBar.resetTimer) {
              streamContext.eventSideBar.resetTimer();
            }
            setStartTimer(true);
            streamContext.eventSideBar.setCurrentSection(section, false);
          }
        }
      });
    }
    if (
      getTotalTimeClass() > 0 &&
      Math.round(currentSeconds) > 0 &&
      Math.round(currentSeconds) >= getTotalTimeClass()
    ) {
      recordingContext.showModalEnded();
      recordingContext.togglePlayRecordings(false);
    }
  };

  useEffect(() => {
    if (startTimer && streamContext.eventSideBar.startTimer) {
      streamContext.eventSideBar.startTimer();
      setStartTimer(false);
    }
  }, [streamContext.eventSideBar.startTimer, startTimer]); //eslint-disable-line

  useEffect(() => {
    if (workout.method !== 'video') {
      executeEvent(recordingContext.time.complete);
    } else {
      executeEventVideo(recordingContext.time.complete);
    }
  }, [recordingContext.time.complete]); //eslint-disable-line

  const [sliderMarks, setSliderMarks] = useState([]);

  const changeTime = (newValue) => {
    recordingContext.resetEvents();
    // execEventsToTime(newValue);
    if (
      userRoom &&
      userRoom.streamManager &&
      workout.live &&
      workout.coachWorkoutInvite &&
      streamContext.eventSideBar.sendSignalUserChanged
    ) {
      streamContext.eventSideBar.sendSignalUserChanged({
        type: TypesSignal.setSeekRecordingVideo,
        userId: userRoom.id,
        value: newValue,
      });
    }
    recordingContext.setTime((prev) => ({
      ...prev,
      seek: newValue,
      complete: newValue,
    }));
  };

  const createMarkers = () => {
    const marks = [];
    const startAt = new Date(workout.classStartAt).getTime();
    workoutEvents.forEach((event) => {
      if (event.kind === workoutEvent.start_timer) {
        const timeEvent = new Date(event.createdAt).getTime();
        const value = (timeEvent - startAt) / 1000;
        if (event.data && value > 0) {
          marks.push({
            value,
            label: event.data.title,
          });
        }
      }
    });
    setSliderMarks(marks);
  };

  const toggleClass = () => {
    if (workout.live && streamContext.eventSideBar.sendSignalUserChanged) {
      streamContext.eventSideBar.sendSignalUserChanged({
        type: TypesSignal.setPlayingRecording,
        userId: userRoom.id,
        value: !playingRecordingsRef.current,
      });
    }
    recordingContext.togglePlayRecordings(!playingRecordingsRef.current);
  };

  const nextSection = () => {
    if (playingRecordingsRef.current) {
      toggleClass();
    }
    const mark = sliderMarks.find((x) => x.value > recordingContext.time.complete);
    if (mark) {
      changeTime(mark.value);
    }
  };

  useEffect(() => {
    if (playingRecordingsRef.current) {
      if (workout.method !== 'video') {
        toggleClass();
      }
      recordingContext.setTime((prev) => ({ ...prev, seek: prev.complete }));
    }
    recordingContext.setVideosReady([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [streamContext.eventSideBar.galleryView]);

  const previousSection = () => {
    if (playingRecordingsRef.current) {
      toggleClass();
    }
    const mark = sliderMarks.find((x) => x.value < recordingContext.time.complete);
    if (mark) {
      changeTime(mark.value);
    }
  };

  const checkTimeRecordingLive = () => {
    if (!workout.live) {
      return;
    }
    const publishAt = new Date(workout.publishAt).getTime();
    const endAt = new Date().getTime();
    const currentSeek = Math.round((endAt - publishAt) / 1000);

    changeTime(currentSeek);
    setTimeout(() => {
      recordingContext.togglePlayRecordings(true);
    }, 300);
  };

  useEffect(() => {
    checkTimeRecordingLive();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workout]);

  useEffect(() => {
    createMarkers();
  }, [workoutEvents]); //eslint-disable-line

  useEffect(() => {
    if (seekVideo) {
      changeTime(seekVideo);
    }
  }, [seekVideo]); //eslint-disable-line

  const PrettoSlider = withStyles({
    root: {
      color: primary,
      height: 8,
    },
    thumb: {
      height: 15,
      width: 15,
      backgroundColor: '#fff',
      marginTop: -5,
      marginLeft: -5,
      '&:focus, &:hover, &$active': {
        boxShadow: 'inherit',
      },
    },
    active: {},
    valueLabel: {
      left: 'calc(-50% + 4px)',
    },
    track: {
      height: 5,
      borderRadius: 4,
    },
    rail: {
      height: 5,
      borderRadius: 4,
      backgroundColor: '#bfbfbf',
    },
    mark: {
      backgroundColor: '#bfbfbf',
      height: 5,
      width: 2,
    },
    markLabel: {
      fontSize: 8,
      color: 'white',
      marginTop: 5,
    },
    markActive: {
      opacity: 1,
      backgroundColor: '#bfbfbf',
    },
  })(Slider);

  const loadingVideos = !isSafari && recordingContext.loadingVideos();

  if (workout.live && !workout.coachWorkoutInvite) {
    return <></>;
  }

  return (
    <Box display="flex" flexDirection="column">
      <Box className="player" px={2}>
        <Box ml={2} mt={2} display="flex" alignItems="center">
          <Box flexGrow={1} display="flex">
            {sliderMarks.length > 0 && (
              <ButtonSideBar
                style={{ padding: 1, margin: 0.5 }}
                onClick={() => !loadingVideos && previousSection()}
              >
                {loadingVideos ? (
                  <LoaderSm width={24} loading />
                ) : (
                  <SkipPrevious style={{ color: 'white' }} />
                )}
              </ButtonSideBar>
            )}
            <ButtonSideBar
              style={{ padding: 1, margin: 0.5 }}
              onClick={() => !loadingVideos && toggleClass()}
            >
              {loadingVideos ? (
                <LoaderSm width={24} loading />
              ) : (
                <>
                  {recordingContext.playingRecordings && <PauseIcon style={{ color: 'white' }} />}
                  {!recordingContext.playingRecordings && (
                    <PlayArrowIcon style={{ color: 'white' }} />
                  )}
                </>
              )}
            </ButtonSideBar>
            {sliderMarks.length > 0 && (
              <ButtonSideBar
                style={{ padding: 1, margin: 0.5 }}
                onClick={() => !loadingVideos && nextSection()}
              >
                {loadingVideos ? (
                  <LoaderSm width={24} loading />
                ) : (
                  <SkipNext style={{ color: 'white' }} />
                )}
              </ButtonSideBar>
            )}
            <ButtonSideBar
              style={{ padding: 1, margin: 0.5 }}
              onClick={() =>
                !loadingVideos &&
                dispatch(roomActions.setMutedRecordings(!recordingContext.mutedRecordings))
              }
            >
              {loadingVideos ? (
                <LoaderSm width={24} loading />
              ) : (
                <>
                  {recordingContext.mutedRecordings ? (
                    <VolumeOff style={{ color: 'white' }} />
                  ) : (
                    <VolumeUp />
                  )}
                </>
              )}
            </ButtonSideBar>
          </Box>
          <Box>
            <Typography style={{ color: 'white' }} component="h1" variant="body2">
              {hhmmss(Math.round(recordingContext.time.complete))}
            </Typography>
          </Box>
        </Box>

        <Box className="player-slider">
          <Hidden only={['xs', 'sm']}>
            <PrettoSlider
              min={0}
              style={{ width: '100%' }}
              max={getTotalTimeClass()}
              value={recordingContext.time.complete}
              onChangeCommitted={(e, newValue) => changeTime(newValue)}
              aria-labelledby="continuous-slider"
              marks={sliderMarks}
              step={1}
            />
          </Hidden>
          <Hidden only={['md', 'lg', 'xl']}>
            <PrettoSlider
              min={0}
              style={{ width: '100%' }}
              max={getTotalTimeClass()}
              value={recordingContext.time.complete}
              onChangeCommitted={(e, newValue) => changeTime(newValue)}
              aria-labelledby="continuous-slider"
              step={1}
            />
          </Hidden>
        </Box>
      </Box>

      {openTimeLine && (
        <Popper
          open
          anchorEl={timelineRef.current}
          placement="bottom-start"
          role={undefined}
          transition
          disablePortal
          style={{ zIndex: 9999 }}
        >
          <Paper p={5}>
            <ClickAwayListener onClickAway={() => setOpenTimeLine(false)}>
              <ClassTimeline />
            </ClickAwayListener>
          </Paper>
        </Popper>
      )}
    </Box>
  );
}
