import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
} from '@material-ui/core';
import Typography, { fontStyle } from 'core/ui/Typography';
import Box from 'core/ui/Box';
import { primary } from 'core/ui/Colors';
import { gymDate } from 'core/utils/formatters';
import Divider from 'core/ui/Divider';
import DescPlanLimitUse from 'components/Channel/Manage/Tabs/Memberships/DescPlanLimitUse';
import Paper from 'core/ui/Paper';
import ChannelContracts from 'components/Channel/ChannelContracts';
import { ButtonPrimary } from 'core/ui/Button';

const UserChannelKeys = ({ keys, registerStatus, label, workout }) => {
  const { t } = useTranslation();
  const [modalContract, setModalContract] = useState(null);

  if (keys?.length === 0) {
    return <></>;
  }

  return (
    <Paper m={2} p={3}>
      {label}

      {registerStatus && !registerStatus?.hasOnlineKey && !registerStatus?.hasInPersonKey && (
        <Typography m={3}>
          You have reached your class limit for this membership. Please wait or purchase another
          membership to register for this class.
        </Typography>
      )}

      <Divider />
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">{t('table.column.userKeys.limit')}</TableCell>
              <TableCell>{t('table.column.userKeys.plan')}</TableCell>
              <TableCell align="center" />
              <TableCell>{t('table.column.userKeys.status')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {keys.map((itemKey) => (
              <TableRow key={itemKey.id}>
                <TableCell align="center">
                  <Typography>{itemKey.limitUse}</Typography>
                </TableCell>
                <TableCell>
                  {itemKey.channel && (
                    <Box>
                      <Typography ml={1} color="secondary">
                        {itemKey.membershipPlan ? (
                          <span style={{ color: primary }}>{itemKey.membershipPlan.name}</span>
                        ) : (
                          <>Channel Key</>
                        )}
                      </Typography>
                      {itemKey.membershipPlan && <DescPlanLimitUse plan={itemKey.membershipPlan} />}
                    </Box>
                  )}
                </TableCell>
                <TableCell>
                  {itemKey?.membershipPlan && (
                    <>
                      {itemKey.membershipPlan.online && itemKey.membershipPlan.inPerson && (
                        <Typography style={{ color: primary }} ml={3}>
                          {t('workout.setting.online')},&nbsp;{t('workout.setting.inPerson')}
                        </Typography>
                      )}
                      {!itemKey.membershipPlan.online && itemKey.membershipPlan.inPerson && (
                        <Typography style={{ color: primary }} ml={3}>
                          {t('workout.setting.inPerson')}
                        </Typography>
                      )}
                      {itemKey.membershipPlan.online && !itemKey.membershipPlan.inPerson && (
                        <Typography style={{ color: primary }} ml={3}>
                          {t('workout.setting.online')}
                        </Typography>
                      )}
                    </>
                  )}
                </TableCell>
                <TableCell>
                  {itemKey.expiryAt && (
                    <Typography color="secondary" style={{ ...fontStyle.small }}>
                      {t(
                        itemKey.renewable
                          ? 'channel.memberships.renewIn'
                          : 'channel.memberships.expiryIn',
                      )}
                      &nbsp;
                      {gymDate(itemKey.expiryAt)}
                    </Typography>
                  )}
                  {itemKey.notStarted && (
                    <Typography color="secondary" style={{ ...fontStyle.small }}>
                      {t('channel.label.plan.startDate')}
                      &nbsp;
                      {gymDate(itemKey.startAt)}
                    </Typography>
                  )}
                  {itemKey.planExpiryAt && (
                    <>
                      <Divider my={1} />
                      <Typography color="primary" style={{ ...fontStyle.small }}>
                        {t('channel.memberships.planExpiresIn')}
                        &nbsp;
                        {gymDate(itemKey.planExpiryAt)}
                      </Typography>
                    </>
                  )}
                  {!itemKey?.contractAccepted && modalContract !== itemKey.membershipPlan?.id && (
                    <>
                      <Typography color="primary" style={fontStyle.small}>
                        {t('memberhship.contract.acceptToUse')}
                      </Typography>

                      {!modalContract && (
                        <ButtonPrimary onClick={() => setModalContract(itemKey.membershipPlan?.id)}>
                          {t('button.view')}
                        </ButtonPrimary>
                      )}
                    </>
                  )}

                  {modalContract === itemKey.membershipPlan?.id && (
                    <ChannelContracts
                      channel={workout?.channel}
                      userId={itemKey.userId}
                      ownerableType="Channel"
                      ownerableId={workout?.channel?.id}
                      kind="membership"
                      objectId={itemKey.membershipPlan?.id}
                      objectType="ChannelMembershipPlan"
                      setAccepted={() => setModalContract(null)}
                      documentId={itemKey.membershipPlan.documentContract?.id}
                      onClose={() => setModalContract(null)}
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

UserChannelKeys.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  keys: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  label: PropTypes.any,
};

UserChannelKeys.defaultProps = {
  keys: [],
  label: <Typography>Memberships</Typography>,
};

export default UserChannelKeys;
