import React, { useEffect, useRef } from 'react';
import Box from 'core/ui/Box';
import { useDispatch } from 'react-redux';
import { channelsActions } from 'modules/channels';
import { usersActions } from 'modules/users';
import { useLocation } from 'react-router';
import StreamFitnessSplash from 'core/ui/StreamFitnessSplash';
import { parse } from 'query-string';
import useActions from 'modules/map/useActions';
import Modules from 'modules';

const StripeConnect = () => {
  const location = useLocation();
  const params = parse(location.search);
  const { request } = useActions();
  const dispatch = useDispatch();

  const connectedRef = useRef(null);

  const assignByState = () => {
    const ownerable = JSON.parse(atob(params?.state));

    if (ownerable?.ownerableType === 'Partner') {
      connectPartner(ownerable?.ownerableId);
      return;
    }

    if (ownerable?.ownerableType === 'Channel') {
      connectChannel(ownerable?.ownerableId);
      return;
    }

    if (ownerable?.ownerableType === 'Competition') {
      connectCompetition(ownerable?.ownerableId);
      return;
    }

    if (ownerable?.ownerableType === 'User') {
      connectUser(ownerable?.ownerableId);
    }
  };

  const connectPartner = (id) => {
    request({
      action: Modules.partners.actions.connectStripeAccount,
      data: {
        partnerId: id,
        code: params.code,
      },
      options: {
        onSuccess: () => {
          window.location = `/partner/${id}`;
        },
      },
    });
  };

  const connectChannel = (id) => {
    dispatch(
      channelsActions.connectStripeAccountStart(id, params.code, () => {
        window.location = `/channel/manage/${id}/integrations`;
      }),
    );
  };

  const connectCompetition = (id) => {
    request({
      action: Modules.newCompetitions.actions.connectStripeAccount,
      data: {
        competitionId: id,
        code: params.code,
      },
      options: {
        onSuccess: () => {
          window.location = `/competition/edit/${id}/payments`;
        },
      },
    });
  };

  const connectUser = (id) => {
    dispatch(
      usersActions.connectStripeAccountStart(id, params.code, () => {
        window.location = `/profile/edit`;
      }),
    );
  };

  const assignAccount = () => {
    if (params?.state) {
      assignByState();
      return;
    }

    alert('Error to parse state.');
  };

  useEffect(() => {
    if (connectedRef.current === null) {
      connectedRef.current = 1;
      assignAccount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <StreamFitnessSplash />
    </Box>
  );
};

export default StripeConnect;
