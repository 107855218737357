import React, { useEffect, useState } from 'react';
import Box from 'core/ui/Box';
import { useSelector } from 'react-redux';
import { channelsSelectors } from 'modules/channels';
import LoaderSm from 'core/ui/LoaderSm';
import useSelectors from 'modules/map/useSelectors';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import { partnersProgramsModule } from 'modules/partnersPrograms';
import Divider from 'core/ui/Divider';
import { partnersProgramsChannelsModule } from 'modules/partnersProgramsChannels';
import ConfirmDialog from 'core/ui/ConfirmDialog';
import { useTranslation } from 'react-i18next';
import Typography from 'core/ui/Typography';
import ModalPaymentPartnerProgram from './ModalPayment';
import ModalMembershipsBlocks from './ModalMembershipsBlocks';
import ItemPartner from './ItemPartner';

const PartnersPrograms = () => {
  const channel = useSelector((state) => channelsSelectors.getChannel(state));
  const loading = useSelectors(partnersProgramsModule, 'loading');
  const available = useSelectors(partnersProgramsModule, 'available');
  const loadingCancel = useSelectors(partnersProgramsChannelsModule, 'loading');
  const { request } = useActions();
  const [confirmCancel, setConfirmCancel] = useState({ open: false });
  const { t } = useTranslation();
  const [modalProgram, setModalProgram] = useState({ open: false });

  const [modalPurchase, setModalPurchase] = useState({
    open: false,
  });

  const getAvailablePartnersPrograms = () => {
    request({
      action: Modules.partnersPrograms.actions.getAvailablePartnersPrograms,
      data: {
        channelId: channel?.id,
      },
    });
  };
  const cancel = (partnersProgram) => {
    setConfirmCancel({
      open: true,
      confirm: () => {
        request({
          action: Modules.partnersProgramsChannels.actions.cancel,
          data: {
            channelId: channel?.id,
            partnersProgramId: partnersProgram?.id,
          },
          options: {
            onSuccess: () => {
              request({
                action: Modules.partnersPrograms.actions.getAvailablePartnersPrograms,
                data: {
                  channelId: channel?.id,
                },
              });
            },
          },
        });
        setConfirmCancel({ open: false });
      },
    });
  };

  useEffect(() => {
    if (channel && channel.id) {
      request({
        action: Modules.partnersPrograms.actions.getAvailablePartnersPrograms,
        data: {
          channelId: channel?.id,
        },
      });
    }
  }, [request, channel]);

  const onPurchaseClick = (item) => {
    setModalPurchase((prev) => ({ ...prev, partnersProgram: item, open: true }));
  };

  const groupPartners = {};
  available.forEach((item) => {
    groupPartners[item?.partner?.id] = item?.partner;
  });

  return (
    <Box px={5} py={3}>
      <ConfirmDialog
        open={confirmCancel.open}
        confirm={() => confirmCancel.confirm()}
        cancel={() => setConfirmCancel({ open: false })}
        description={t('button.cancel.channel.program')}
        labelConfirm={t('button.yes')}
        labelCancel={t('button.no')}
      />
      <ModalPaymentPartnerProgram
        open={modalPurchase?.open}
        channel={channel}
        partnersProgram={modalPurchase?.partnersProgram}
        close={() => setModalPurchase({ open: false })}
        callback={() => {
          getAvailablePartnersPrograms();
        }}
      />
      <ModalMembershipsBlocks
        open={modalProgram?.open}
        channel={channel}
        program={modalProgram.program}
        close={() => setModalProgram({ open: false })}
      />

      <Typography variant="h5">{t('partner.channel.title')}</Typography>

      {Object.keys(groupPartners).map((itemG, index) => (
        <React.Fragment key={index.toString()}>
          <ItemPartner
            channel={channel}
            itemPartner={groupPartners[itemG]}
            programs={available && available.filter((x) => x.partnerId === Number(itemG))}
            onPurchaseClick={onPurchaseClick}
            cancel={cancel}
            setModalProgram={setModalProgram}
            loadingCancel={loadingCancel}
          />
          {Object.keys(groupPartners).length - 1 > index && <Divider m={3} />}
        </React.Fragment>
      ))}

      <Box display="flex" alignItems="center" justifyContent="center">
        <LoaderSm loading={loading} center />
      </Box>
    </Box>
  );
};

export default PartnersPrograms;
