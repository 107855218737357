import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import Form from './Form';

const ModalStageUsersForm = ({ open, stageId, stageUser, channel, close, callbackSave }) => (
  <Dialog open={open} onClose={close} maxWidth="md" fullWidth>
    <Form
      stageUser={stageUser}
      stageId={stageId}
      close={close}
      channel={channel}
      callbackSave={callbackSave}
    />
  </Dialog>
);

ModalStageUsersForm.propTypes = {
  close: PropTypes.func,
};

ModalStageUsersForm.defaultProps = {
  close: () => {},
};

export default ModalStageUsersForm;
