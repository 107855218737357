import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import { ButtonPrimary } from 'core/ui/Button';
import Grid from 'core/ui/Grid';
import LoaderSm from 'core/ui/LoaderSm';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import { repMaxTypes, typeDistance, typeMinMax, typeWeight } from 'core/utils/consts';
import TimeSelector from 'core/ui/TimeSelector';
import Typography from 'core/ui/Typography';
import DatePickerRounded from 'core/ui/DatePickers/DatePickerRounded';
import TextAreaFieldRounded from 'core/ui/TextAreaFieldRounded';
import { sessionSelectors } from 'modules/session';
import { useSelector } from 'react-redux';
import useForm from './useForm';

const FormPersonalRecord = ({ selectedPr, itemEdit, callback, mode }) => {
  const { t } = useTranslation();
  const currentUser = useSelector((state) => sessionSelectors.getUser(state));

  const defaultWeightType = typeWeight.find(
    (x) => x.id === (currentUser?.defaultWeightImperial ? 'pounds' : 'kilograms'),
  );
  const defaultDistanceType = typeDistance.find(
    (x) => x.id === (currentUser?.defaultDistanceMiles ? 'miles' : 'kilometers'),
  );

  const { setValue, watch, handleSubmit, onSubmit, loading } = useForm({
    mode,
    selectedPr,
    callback,
    itemEdit,
  });

  return (
    <>
      <Typography color="primary" variant="h4" align="center" my={5}>
        {selectedPr?.movement?.name}
      </Typography>

      <Box mb={3}>
        <DatePickerRounded
          disablePast={false}
          label={t('form.personalRecord.date')}
          placeholder={t('form.personalRecord.date')}
          value={watch('date')}
          onChange={(v) => setValue('date', v)}
        />
      </Box>
      {selectedPr?.scoreType === 'rep_max' && (
        <>
          <SelectBoxObject
            label={t('form.personalRecord.repMax')}
            options={[
              ...repMaxTypes.map((item) => ({
                id: item,
                name: t(item).toUpperCase(),
              })),
              { id: 'Custom', name: 'Custom' },
            ]}
            value={watch('repMaxNumber')}
            setValue={(v) => setValue('repMaxNumber', v)}
          />

          {watch('repMaxNumber') !== 'Custom' ? (
            <Grid container spacing={3} mt={2}>
              <Grid item xs={12} md={6} lg={6}>
                <TextFieldRounded
                  type="number"
                  pattern="[0-9]*"
                  value={watch(watch('repMaxNumber')) || ''}
                  onChange={(v) => {
                    setValue(watch('repMaxNumber'), v);
                    if (!watch('repMaxType')) {
                      setValue('repMaxType', defaultWeightType?.id);
                    }
                  }}
                  placeholder={t(`form.personalRecord.repMaxResult`)}
                  label={t(`form.personalRecord.repMaxResult`).toUpperCase()}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <Box>
                  <SelectBoxObject
                    propValue="id"
                    propLabel="id"
                    label="&nbsp;"
                    options={typeWeight}
                    value={watch(`repMaxType`) || defaultWeightType?.id}
                    setValue={(v) => setValue(`repMaxType`, v)}
                  />
                </Box>
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={3} mt={2}>
              <Grid item xs={12} md={6} lg={6}>
                <TextFieldRounded
                  value={watch('repCustomScheme') || ''}
                  onChange={(v) => setValue('repCustomScheme', v)}
                  placeholder={t(`form.personalRecord.repCustomScheme`)}
                  label={t(`form.personalRecord.repCustomScheme`).toUpperCase()}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <Box>
                  <TextFieldRounded
                    value={watch('repCustomResults') || ''}
                    onChange={(v) => setValue('repCustomResults', v)}
                    placeholder={t(`form.personalRecord.repCustomResults`)}
                    label={t(`form.personalRecord.repCustomResults`).toUpperCase()}
                  />
                </Box>
              </Grid>
            </Grid>
          )}
        </>
      )}
      {selectedPr?.scoreType === 'min_max' && (
        <Box display="flex">
          <TextFieldRounded
            type="number"
            pattern="[0-9]*"
            value={watch(`minMax`) || ''}
            onChange={(v) => setValue(`minMax`, v)}
            placeholder={t(`form.personalRecord.minMax`)}
            label={t(`form.personalRecord.minMax`).toUpperCase()}
          />
          <Box ml={1}>
            <SelectBoxObject
              propValue="id"
              propLabel="id"
              label="&nbsp;"
              options={typeMinMax}
              value={watch(`minMaxType`) || ''}
              setValue={(v) => setValue(`minMaxType`, v)}
            />
          </Box>
        </Box>
      )}
      {selectedPr?.scoreType === 'weight' && (
        <Box display="flex">
          <TextFieldRounded
            type="number"
            pattern="[0-9]*"
            value={watch(`weight`) || ''}
            onChange={(v) => {
              setValue('weight', v);
              if (!watch('weightType')) {
                setValue('weightType', defaultWeightType?.id);
              }
            }}
            placeholder={t(`form.personalRecord.weight`)}
            label={t(`form.personalRecord.weight`).toUpperCase()}
          />
          <Box ml={1}>
            <SelectBoxObject
              propValue="id"
              propLabel="id"
              label="&nbsp;"
              options={typeWeight}
              value={watch(`weightType`) || defaultWeightType?.id}
              setValue={(v) => setValue(`weightType`, v)}
            />
          </Box>
        </Box>
      )}

      {selectedPr?.scoreType === 'max_distance' && (
        <Box display="flex">
          <TextFieldRounded
            type="number"
            pattern="[0-9]*"
            value={watch(`maxDistance`) || ''}
            onChange={(v) => {
              setValue('maxDistance', v);
              if (!watch('maxDistanceType')) {
                setValue('maxDistanceType', defaultDistanceType?.id);
              }
            }}
            placeholder={t(`form.personalRecord.maxDistance`)}
            label={t(`form.personalRecord.maxDistance`).toUpperCase()}
          />
          <Box ml={1}>
            <SelectBoxObject
              propValue="id"
              propLabel="id"
              label="&nbsp;"
              options={typeDistance}
              value={watch(`maxDistanceType`) || defaultDistanceType?.id}
              setValue={(v) => setValue(`maxDistanceType`, v)}
            />
          </Box>
        </Box>
      )}
      {selectedPr?.scoreType === 'time' && (
        <Box py={5} flex={1}>
          <TimeSelector
            time={watch(`time`)}
            setTime={(time) => setValue(`time`, time)}
            label={t('form.personalRecord.time')}
          />
        </Box>
      )}

      {selectedPr?.scoreType === 'unbroken' && (
        <Box display="flex">
          <TextFieldRounded
            type="number"
            pattern="[0-9]*"
            value={watch(`unbroken`) || ''}
            onChange={(v) => setValue(`unbroken`, v)}
            placeholder={t(`form.personalRecord.unbroken`)}
            label={t(`form.personalRecord.unbroken`).toUpperCase()}
          />
        </Box>
      )}

      <Box mt={1}>
        <TextAreaFieldRounded
          value={watch(`notes`) || ''}
          onChange={(v) => setValue(`notes`, v)}
          label={t('workout.section.label.notes')}
          placeholder={t('workout.section.label.notes')}
        />
      </Box>

      <Box mt={3}>
        <ButtonPrimary fullWidth disabled={loading} onClick={handleSubmit(onSubmit)}>
          <LoaderSm loading={loading} />
          {t('button.save')}
        </ButtonPrimary>
      </Box>
    </>
  );
};

FormPersonalRecord.defaultProps = {
  callback: () => {},
  selectedPr: null,
};

FormPersonalRecord.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  callback: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  selectedPr: PropTypes.object,
};

export default FormPersonalRecord;
