import React from 'react';
import PropTypes from 'prop-types';
import Typography, { fontStyle } from 'core/ui/Typography';
import Box from 'core/ui/Box';
import Paper from 'core/ui/Paper';
import Rating from 'core/ui/Rating';
import CollapseText from 'core/ui/CollapseText';

const CardRating = ({ rate }) => (
  <Paper elevation={1} className="paper-rounded" p={3}>
    <Box display="flex" flexDirection="column">
      <Typography color="primary" variant="caption">
        {rate.rater && rate.rater.name}
      </Typography>
      <Rating readOnly size="small" value={rate.stars} colorEmpty="#eee" />
      <Typography mx={1} component="div" className="roboto-regular" style={fontStyle.medium}>
        <CollapseText text={rate.comment} maxChar={20} />
      </Typography>
    </Box>
  </Paper>
);

CardRating.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  rate: PropTypes.object,
};

CardRating.defaultProps = {
  rate: {},
};

export default CardRating;
