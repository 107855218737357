import React, { useEffect, useState } from 'react';
import { ButtonPrimary } from 'core/ui/Button';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import Typography from 'core/ui/Typography';
import {
  TableContainer,
  TableHead,
  Table,
  TableCell,
  TableRow,
  TableBody,
  Paper,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import LoaderSm from 'core/ui/LoaderSm';
import useSelectors from 'modules/map/useSelectors';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import { channelsCoachesRatesModule } from 'modules/channelsCoachesRates';
import ItemRate from './ItemRate';
import ModalForm from './Form/Modal';

const CoachRates = ({ channel, coach }) => {
  const { t } = useTranslation();
  const loading = useSelectors(channelsCoachesRatesModule, 'loading');
  const data = useSelectors(channelsCoachesRatesModule, 'data');
  const { request } = useActions();

  const [modal, setModal] = useState({
    open: false,
  });

  useEffect(() => {
    if (channel && channel.id && coach?.id) {
      request({
        action: Modules.channelsCoachesRates.actions.getChannelsCoachesRates,
        data: { channelId: channel.id, userId: coach?.id },
      });
    }
  }, [request, channel, coach]);

  const openModal = () => {
    setModal((prev) => ({ ...prev, mode: 'new', open: true }));
  };

  const onEditClick = (item) => {
    setModal((prev) => ({ ...prev, item, mode: 'edit', open: true }));
  };

  const onRemoveClick = (item) => {
    request({
      action: Modules.channelsCoachesRates.actions.destroy,
      data: { channelId: channel.id, id: item?.id },
      options: {
        onSuccess: () => {
          request({
            action: Modules.channelsCoachesRates.actions.getChannelsCoachesRates,
            data: { channelId: channel.id, userId: coach?.id },
          });
        },
      },
    });
  };

  return (
    <Box p={5}>
      {modal.open && (
        <ModalForm
          open={modal.open}
          item={modal.item}
          mode={modal.mode}
          coach={coach}
          channel={channel}
          close={() => setModal(() => ({ open: false }))}
          callback={() => setModal(() => ({ open: false }))}
        />
      )}

      <Box display="flex" alignItems="center">
        <Box flexGrow="1" display="flex" alignItems="center">
          <LoaderSm loading={loading} />
        </Box>
        <Box>
          <ButtonPrimary onClick={() => openModal()}>
            <Box display="flex" alignItems="flex-end">
              <Add size="small" />
              <Box alignItems="center">
                <Typography component="span" mr={1}>
                  {t('button.asignRate')}
                </Typography>
              </Box>
            </Box>
          </ButtonPrimary>
        </Box>
      </Box>
      <Box mt={2}>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>{t('label.name')}</TableCell>
                <TableCell>{t('channel.rateTemplate.label.kind')}</TableCell>
                <TableCell>{t('channel.rateTemplate.label.amount')}</TableCell>
                <TableCell>{t('channel.rateTemplate.label.startDate')}</TableCell>
                <TableCell>{t('channel.rateTemplate.label.endDate')}</TableCell>
                <TableCell />
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data.map((row, idx) => (
                  <ItemRate
                    channel={channel}
                    onEditClick={onEditClick}
                    onRemoveClick={onRemoveClick}
                    key={idx.toString()}
                    item={row}
                    expanded={modal.nameListExpand && modal.nameListExpand === row.name}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default CoachRates;
