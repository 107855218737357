import React from 'react';
import Box from 'core/ui/Box';
import Paper from 'core/ui/Paper';
import { useTranslation } from 'react-i18next';
import Typography from 'core/ui/Typography';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import Grid from 'core/ui/Grid';
import { ButtonPrimarySm } from 'core/ui/Button';
import Divider from 'core/ui/Divider';
import { useSelector } from 'react-redux';
import { sessionSelectors } from 'modules/session';
import CardUser from './CardUser';

const ListHeats = ({
  heats,
  registerInHeat,
  buttonColor,
  registeredHeat,
  addMember,
  removeMember,
  isCoach,
}) => {
  const currentUser = useSelector((state) => sessionSelectors.getUser(state));
  return (
    <Box>
      {heats &&
        heats
          .filter((x) => !x?._destroy)
          .map((heat) => (
            <React.Fragment key={heat?.id}>
              <ItemHeat
                heat={heat}
                registerInHeat={registerInHeat}
                buttonColor={buttonColor}
                registeredHeat={registeredHeat}
                addMember={addMember}
                removeMember={removeMember}
                currentUser={currentUser}
                isCoach={isCoach}
              />
            </React.Fragment>
          ))}
    </Box>
  );
};

const ItemHeat = ({
  heat,
  registerInHeat,
  buttonColor,
  registeredHeat,
  addMember,
  removeMember,
  currentUser,
  isCoach,
}) => {
  const { t } = useTranslation();

  return (
    <Paper elevation={2} my={1} className="paper-rounded opacity-80" p={3}>
      <Grid container spacing={5}>
        <Grid item xs={6} md={4}>
          <Typography>{heat?.name}</Typography>
        </Grid>
        <Grid item xs={6} md={4}>
          <Box flexGrow={1} display="flex" alignItems="center">
            <Typography>{heat?.startTime}</Typography>
            <Typography>&nbsp;&bull;&nbsp;</Typography>
            <Typography>{heat?.endTime}</Typography>
          </Box>
        </Grid>
        <Grid item xs={6} md={2}>
          <Box display="flex" alignItems="center">
            <PeopleAltIcon />
            <Typography ml={1}>{heat?.countRegistered}&nbsp;/&nbsp;</Typography>
            <Typography>{heat?.spots}</Typography>
          </Box>
        </Grid>
        <Grid item xs={6} md={2}>
          <Box display="flex" justifyContent="center">
            {heat?.allowRegister && registerInHeat && !registeredHeat && (
              <ButtonPrimarySm
                fontSize={12}
                buttonColor={buttonColor}
                onClick={() => registerInHeat(heat)}
              >
                {t('button.select')}
              </ButtonPrimarySm>
            )}
            {heat?.allowRegister && addMember && (
              <Box ml={1}>
                <ButtonPrimarySm
                  fontSize={12}
                  buttonColor={buttonColor}
                  onClick={() => addMember(heat)}
                >
                  {t('button.addMember')}
                </ButtonPrimarySm>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
      <Divider my={1} />
      <Box display="flex" flexWrap="wrap" p={2}>
        {heat?.users?.length <= 0 && (
          <Typography align="center" m={3}>
            {t('workout.heat.noUsersAdded')}
          </Typography>
        )}
        {heat?.users?.map((item) => (
          <Box key={item?.id} m={1}>
            <CardUser user={item} />
            {(isCoach || currentUser?.id === item?.id) && (
              <ButtonPrimarySm
                buttonColor={buttonColor}
                variant="contained"
                fontSize={10}
                onClick={() => removeMember(item?.id, heat?.id)}
              >
                {t('button.remove')}
              </ButtonPrimarySm>
            )}
          </Box>
        ))}
      </Box>
    </Paper>
  );
};

export default ListHeats;
