/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { channelsSelectors } from 'modules/channels';
import Box from 'core/ui/Box';
import Grid from 'core/ui/Grid';
import { storeCategoriesModule } from 'modules/storeCategories';
import Typography from 'core/ui/Typography';
import { primary } from 'core/ui/Colors';
import { Pagination } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { ShoppingCart } from '@material-ui/icons';
import { sessionSelectors } from 'modules/session';
import { LayoutContext } from 'pages/shared/Layout';
import Modules from 'modules';
import useActions from 'modules/map/useActions';
import CardProduct from 'components/Store/Products/Card';
import useSelectors from 'modules/map/useSelectors';
import { storeProductsModule } from 'modules/storeProducts';
import useChannelStyles from 'core/useChannelStyles';
import { useLocation, useParams } from 'react-router';
import { parse } from 'query-string';
import notifications from 'modules/notifications';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import { dropdownWrapper } from 'components/Competition/Show/Tabs/Results/style';
import { ButtonPrimary } from 'core/ui/Button';
import { Dialog } from '@material-ui/core';
import ModalPaymentStore from './ShoppingCart/ModalStoreCheckout';
import SkeletonStoreProducts from './Skeleton';
import useShoppingCart from './ShoppingCart/useShoppingCart';

export default ({ isCompetition }) => {
  const { t } = useTranslation();
  const { request } = useActions();
  const { paramId } = useParams();
  const layoutContext = useContext(LayoutContext);
  const isAuthenticated = useSelector((state) => sessionSelectors.getIsAuthenticated(state));
  const channel = useSelector((state) => channelsSelectors.getChannel(state));
  const location = useLocation();
  const { buttonColor } = useChannelStyles();
  const shoppingCart = useShoppingCart({ channel });

  const storeProducts = useSelectors(storeProductsModule, 'data');
  const pagination = useSelectors(storeProductsModule, 'pagination');
  const loading = useSelectors(storeProductsModule, 'loading');
  const product = useSelectors(storeProductsModule, 'product');
  const categories = useSelectors(storeCategoriesModule, 'data');

  const [modalProduct, setModalProduct] = useState(false);

  const [selectCategory, setSelectCategory] = useState('all');
  const dispatch = useDispatch();
  const perPage = 12;

  const getProduct = (id) => {
    request({
      action: Modules.storeProducts.actions.getStoreProduct,
      data: { id },
      options: {
        onSuccess: () => {
          setModalProduct(true);
        },
      },
    });
  };

  const getProducts = (page) => {
    request({
      action: Modules.storeProducts.actions.getStoreProducts,
      data: {
        ownerableId: channel?.id,
        ownerableType: 'Channel',
        page,
        perPage,
        showInactive: false,
        categories: selectCategory !== 'all' ? [selectCategory] : [],
        showInCompetitions: isCompetition ? 1 : null,
      },
    });
  };

  useEffect(() => {
    if (paramId) {
      getProduct(paramId);
      return;
    }
    const parsed = parse(location.search);
    if (parsed && parsed.productId) {
      /// CHECK EMBED PRODUCT LINK
      getProduct(parsed.productId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel, location]);

  useEffect(() => {
    if (channel && channel.id) {
      getProducts(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, channel, selectCategory]);

  useEffect(() => {
    if (channel && channel.id) {
      request({
        action: Modules.storeCategories.actions.getStoreCategories,
        data: {
          ownerableType: 'Channel',
          ownerableId: channel?.id,
          showInactive: 0,
        },
      });
    }
  }, [request, channel]);

  const isCoach = !!['owner', 'admin', 'admin_coach', 'coach_head', 'coach_owner', 'coach'].find(
    (x) => x === channel.accessType,
  );

  const buyClick = (prod, option) => {
    shoppingCart.addItem(prod, option);
    dispatch(notifications.success(t('channel.store.shoppingCart.addedItem')));
  };

  const openLogin = () => {
    if (layoutContext) {
      layoutContext.setModalLogin({ open: true });
    } else {
      window.location = `/auth/login`;
    }
  };

  return (
    <Box mt={3} mb={3}>
      <Dialog maxWidth="sm" fullWidth open={modalProduct} onClose={() => setModalProduct(false)}>
        <Box>
          <CardProduct
            shoppingCart={shoppingCart}
            product={product}
            currency={channel?.currency}
            channelId={channel?.id}
            buyClick={isAuthenticated ? buyClick : openLogin}
            isCoach={isCoach}
            buttonColor={buttonColor || primary}
          />
        </Box>
      </Dialog>
      {shoppingCart.opened && (
        <ModalPaymentStore
          open={shoppingCart.opened}
          isCoach={isCoach}
          channel={channel}
          callback={() => getProducts(pagination.currentPage)}
          close={() => shoppingCart.close()}
          buttonColor={buttonColor}
          shoppingCart={shoppingCart}
        />
      )}

      <Box mb={3} display="flex" alignItems="center">
        <Box style={dropdownWrapper} flexGrow={1}>
          <SelectBoxObject
            options={[{ id: 'all', name: 'All' }, ...categories]}
            value={selectCategory}
            setValue={(v) => setSelectCategory(v)}
            elevation={0}
          />
        </Box>

        <Box ml={3}>
          <ButtonPrimary
            style={{ height: 45 }}
            buttonColor={buttonColor}
            onClick={() => shoppingCart.open()}
          >
            <ShoppingCart /> {shoppingCart.countTotalItems}&nbsp; {t('button.checkout')}
          </ButtonPrimary>
        </Box>
      </Box>
      {loading ? (
        <SkeletonStoreProducts />
      ) : (
        <>
          <Grid container spacing={3} className="search-item">
            {storeProducts &&
              storeProducts
                .filter((x) => !!x.active)
                .map((prod) => (
                  <Grid className="item" item key={prod.id} xs={12} sm={6} md={4} lg={3}>
                    <CardProduct
                      shoppingCart={shoppingCart}
                      product={prod}
                      currency={channel?.currency}
                      buyClick={isAuthenticated ? buyClick : openLogin}
                      isCoach={isCoach}
                      channelId={channel?.id}
                      buttonColor={buttonColor || primary}
                    />
                  </Grid>
                ))}
          </Grid>

          {storeProducts && storeProducts.length === 0 && (
            <Typography align="center" variant="h3" color="primary" m={10}>
              {t('channel.store.noProducts')}
            </Typography>
          )}

          {pagination && pagination.totalPages > 1 && (
            <Box p={3} display="flex" justifyContent="center">
              <Pagination
                onChange={(e, page) => getProducts(page)}
                count={pagination.totalPages}
                page={pagination.currentPage}
              />
            </Box>
          )}
        </>
      )}
    </Box>
  );
};
