import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import Divider from 'core/ui/Divider';
import Typography from 'core/ui/Typography';
import ListHeats from './ListHeats';

const FormHeats = ({ heats, setHeats }) => {
  const { t } = useTranslation();

  const addHeat = () => {
    setHeats((prev) => [...prev, { new: true, spots: 0 }]);
  };

  useEffect(() => {
    if (heats.filter((x) => !x?._destroy)?.length === 0) {
      addHeat();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [heats]);

  const changeHeat = (heat, prop, value) => {
    setHeats((prev) =>
      prev.map((item) => {
        if (item === heat) {
          return {
            ...item,
            [prop]: value,
          };
        }

        return item;
      }),
    );
  };

  const removeHeat = (heat) => {
    if (!heat.id) {
      setHeats((prev) => prev.filter((x) => x !== heat));
    } else {
      changeHeat(heat, '_destroy', true);
    }
  };

  const duplicateHeat = (heat) => {
    setHeats((prev) => [...prev, { ...heat, id: null, new: true }]);
  };

  return (
    <>
      <Typography mb={3} variant="h5" color="primary">
        {t('workout.label.heats')}
      </Typography>

      <Divider m={3} />

      <Box>
        <ListHeats
          heats={heats}
          setHeats={setHeats}
          changeHeat={changeHeat}
          removeHeat={removeHeat}
          duplicateHeat={duplicateHeat}
        />
      </Box>
    </>
  );
};

FormHeats.defaultProps = {
  callback: () => {},
};

FormHeats.propTypes = {};

export default FormHeats;
