/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import { ButtonPrimary } from 'core/ui/Button';
import Texture from 'core/ui/Texture';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import { Dialog } from '@material-ui/core';
import TextAreaFieldRounded from 'core/ui/TextAreaFieldRounded';
import { currency, dateAdd, dateTime } from 'core/utils/formatters';
import Typography, { fontStyle } from 'core/ui/Typography';
import useForm from './form';
import ChangeTime from './ChangeTime';

const ModalFormList = ({ open, item, close, callback, user, mode }) => {
  const { t } = useTranslation();
  const { setValue, watch, handleSubmit, onSubmit, errors, loading, coachRates } = useForm({
    item,
    callback,
    user,
  });

  return (
    <Dialog maxWidth="xs" scroll="body" fullWidth onClose={() => close()} open={open}>
      <Texture>
        <Box p={5}>
          <Box mb={3}>
            <SelectBoxObject
              label={t('label.coach.rate').toUpperCase()}
              options={coachRates
                ?.filter((x) => x?.ratesTemplate?.kind === 'hourly')
                .map((x) => ({
                  id: x?.id,
                  name: `${x?.ratesTemplate?.name} - ${currency(x?.ratesTemplate?.amount)}`,
                }))}
              value={watch('channelsCoachesRateId')}
              setValue={(v) => setValue('channelsCoachesRateId', v)}
              error={errors.channelsCoachesRateId}
              required
            />
          </Box>

          <Box mb={3}>
            <TextAreaFieldRounded
              value={watch('description')}
              onChange={(v) => setValue('description', v)}
              placeholder={t('label.description')}
              error={errors.description}
              label={t('label.description').toUpperCase()}
            />
          </Box>

          {item?.id && (
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
              <Typography style={fontStyle.label} mb={2}>
                {t('label.endAt').toUpperCase()}
              </Typography>
              <ChangeTime
                qty={dateTime(watch('endAt'))}
                changeQty={(v, config) => setValue('endAt', dateAdd(watch('endAt'), v, config))}
              />
            </Box>
          )}

          <Box mt={3} display="flex" justifyContent="flex-end">
            <ButtonPrimary disabled={loading} onClick={handleSubmit(onSubmit)}>
              {mode === 'edit'
                ? t('button.save')
                : item?.id
                  ? t('button.stop')
                  : t('button.startClock')}
            </ButtonPrimary>
          </Box>
        </Box>
      </Texture>
    </Dialog>
  );
};

ModalFormList.defaultProps = {
  close: () => {},
  callback: () => {},
  open: false,
};

ModalFormList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  close: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  callback: PropTypes.func,
  open: PropTypes.bool,
};

export default ModalFormList;
