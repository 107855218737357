import React, { useState } from 'react';
import { Dialog } from '@material-ui/core';
import Box from 'core/ui/Box';
import PersonalRecordList from './List';
import ModalFormHistory from './Form/Modal';

const ModalAddPr = ({ close, open }) => {
  const [selectedPr, setSelectedPr] = useState(null);
  const [modalFormHistory, setModalFormHistory] = useState(false);

  const openModalFormPr = () => {
    setModalFormHistory(true);
  };

  return (
    <Dialog
      PaperProps={{ style: { borderRadius: 5, minWidth: 300 } }}
      maxWidth="sm"
      fullWidth
      scroll="body"
      onClose={() => close()}
      open={open}
    >
      {selectedPr && (
        <ModalFormHistory
          selectedPr={selectedPr}
          open={modalFormHistory}
          close={() => setModalFormHistory(false)}
          callback={() => {
            setModalFormHistory(false);
            setSelectedPr(null);
          }}
        />
      )}

      <Box p={3}>
        <PersonalRecordList
          setSelectedPr={(pr) => {
            setSelectedPr(pr);
            openModalFormPr();
          }}
          selectedPr={selectedPr}
        />
      </Box>
    </Dialog>
  );
};

export default ModalAddPr;
