/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import Calendar from 'core/ui/Calendar';
import { date } from 'core/utils/formatters';
import useChannelStyles from 'core/useChannelStyles';
import { isMobile } from 'react-device-detect';
import Header from 'components/Channel/Show/Tabs/Schedules/Header';
import useSelectors from 'modules/map/useSelectors';
import { usersCalendarsModule } from 'modules/usersCalendars';
import Backdrop from 'core/ui/Backdrop';
import Paper from 'core/ui/Paper';
import { renderEventContent } from './renderEventContent';
import ModalFormRegistration from './Form/ModalFormRegistration';
import ModalEventInfo from './ModalEventInfo';
import CalendarsRegistration from './Registrations';
import CalendarsFilters from './Filters';
import useCalendarEvents from './useCalendarEvents';

const CalendarsCalendar = ({ gymArea, isCoach, usersCalendarId, coachId, channel }) => {
  const { buttonColor } = useChannelStyles();
  const loadingEvents = useSelectors(usersCalendarsModule, 'loadingEvents');

  const {
    handleSubmit,
    submit,
    setValue,
    control,
    watch,

    registrationEvents,
    perPage,
    setPerPage,
    pagination,
    getCalendarsRegistrationsEvents,
    modalRegistration,
    setModalRegistration,
    getCalendarsEvents,
    modalEvent,
    setModalEvent,
    confirmRegistration,
    setCalendarDates,
    calendarDates,

    calendarRef,
    setCalendarRef,

    events,

    handleEventClick,
    calendarProps,
  } = useCalendarEvents({ usersCalendarId, coachId, channel, gymArea });

  useEffect(() => {
    if (usersCalendarId) {
      setValue('usersCalendarId', usersCalendarId);
    }
  }, [usersCalendarId, setValue]);

  return (
    <div className="tw-mb-20 md:tw-mb-2">
      {isCoach && (
        <CalendarsFilters
          control={control}
          submit={handleSubmit(submit)}
          channel={channel}
          watch={watch}
        />
      )}

      {watch('viewType') === 'registrations' ? (
        <div>
          <Paper p={2}>
            <CalendarsRegistration
              events={registrationEvents}
              perPage={perPage}
              setPerPage={setPerPage}
              pagination={pagination}
              userId={coachId}
              channel={channel}
              getCalendarsEvents={getCalendarsRegistrationsEvents}
            />
          </Paper>
        </div>
      ) : (
        <>
          <ModalFormRegistration
            {...modalRegistration}
            close={() => setModalRegistration({})}
            channel={channel}
            callback={() => {
              getCalendarsEvents();
              setModalRegistration({});
            }}
            callbackError={() => {
              getCalendarsEvents();
            }}
            isCoach={isCoach}
          />
          <ModalEventInfo
            {...modalEvent}
            onConfirm={confirmRegistration}
            close={() => setModalEvent({})}
            channel={channel}
          />

          <Header
            setCalendarDates={setCalendarDates}
            calendarDates={calendarDates}
            isCoach={false}
            calendarRef={calendarRef}
            buttonColor={buttonColor}
            hidePrograms
          />

          <div className="tw-flex-1 tw-font-normal tw-text-zinc-400">
            <Calendar
              calendarRef={setCalendarRef}
              eventClick={handleEventClick}
              events={events}
              initialView={!isMobile ? 'timeGridWeek' : 'timeGridDay'}
              dayHeaderFormat={(args) => {
                if (calendarRef?.current?.getApi()?.view?.type === 'dayGridMonth') {
                  return date(args.date, channel?.useUsDateFormat ? 'ddd' : 'ddd');
                }
                return date(args.date, channel?.useUsDateFormat ? 'ddd M/DD' : 'ddd DD/M');
              }}
              datesSet={(dateInfo) => {
                if (setCalendarDates) {
                  setCalendarDates((prev) => ({
                    ...prev,
                    start: dateInfo.start,
                    end: dateInfo.end,
                  }));
                }
              }}
              eventContent={renderEventContent}
              {...calendarProps}
            />
          </div>
        </>
      )}

      {loadingEvents && <Backdrop />}
    </div>
  );
};

export default CalendarsCalendar;
