import React, { useState } from 'react';
import Box from 'core/ui/Box';
import PersonalRecordList from 'components/Profile/Dashboard/PersonalRecord/List';
import { ButtonPrimary } from 'core/ui/Button';
import { useTranslation } from 'react-i18next';
import ModalFormPersonalRecord from './Form/Modal';
import ModalUsers from './ModalUsers';

export default () => {
  const [modal, setModal] = useState({ opened: false });
  const [modalUsers, setModalUsers] = useState({ opened: false });
  const { t } = useTranslation();
  const onSelectedPersonalRecord = (item) => {
    setModal({ opened: true, personalRecord: item });
  };

  return (
    <Box py={3}>
      <ModalFormPersonalRecord modal={modal} close={() => setModal({ opened: false })} />
      {modalUsers.opened && modalUsers.personalRecordId && (
        <ModalUsers
          personalRecordId={modalUsers.personalRecordId}
          close={() => setModalUsers({ opened: false })}
        />
      )}

      <Box flexGrow={1} display="flex" justifyContent="flex-end" mb={2}>
        <ButtonPrimary style={{ width: 150 }} onClick={() => setModal({ opened: true })}>
          {t('button.create')}
        </ButtonPrimary>
      </Box>
      <PersonalRecordList
        selectedPr={modal.personalRecord}
        setSelectedPr={onSelectedPersonalRecord}
        rowsPerPage={20}
        hideTitle
        onUsersButtonClick={(bId) => setModalUsers({ opened: true, personalRecordId: bId })}
        onEditButtonClick={(personalRecord) => setModal({ opened: true, personalRecord })}
        isSelector
      />
    </Box>
  );
};
