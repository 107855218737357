import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import Button, { ButtonPrimary } from 'core/ui/Button';
import Typography from 'core/ui/Typography';
import Grid from 'core/ui/Grid';
import { Add } from '@material-ui/icons';
import ModalS3Updload from './ModalS3Updload';
import DocSections from './DocSections';
import ModalDocumentViewer from '../ModalDocumentViewer';

const FormDocument = ({ completeForm, form, ownerableType, ownerableId }) => {
  const { t } = useTranslation();
  const [modalPreview, setModalPreview] = useState(false);
  const [modalUpload, setModalUpload] = useState(false);
  const { setValue, watch, handleSubmit, onSubmit, errors, loading } = form;

  const handleUploadedPdf = (url) => {
    setValue('pdf', true);
    form.addSection({ kind: 'pdf', s3Url: url });
  };

  const handleCreateYourOwn = () => {
    setValue('pdf', false);
    form.addSection({ kind: 'text' });
  };

  return (
    <>
      <ModalDocumentViewer
        document={
          form.document
            ? { ...form.document, sections: form?.sections }
            : { name: form?.watch('name'), sections: form?.sections }
        }
        open={modalPreview}
        close={() => setModalPreview(false)}
      />
      <Box>
        <Typography variant="h5" align="center" mb={3}>
          {t('document.builder')}
        </Typography>

        <Grid container spacing={5}>
          <Grid item xs={12} md={10}>
            <Box mb={3}>
              <TextFieldRounded
                value={watch('name')}
                onChange={(v) => setValue('name', v)}
                placeholder={t('label.document.name')}
                error={errors.name}
                label={t('label.document.name').toUpperCase()}
                required
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={2}>
            {!!completeForm && form.canEditSections && (
              <Box>
                <Typography mb={1}>&nbsp;</Typography>
                <Button
                  variant="contained"
                  style={{ backgroundColor: 'white' }}
                  onClick={() => form.addSection({ kind: 'text' })}
                >
                  <Add />
                  {t('button.addSection')}
                </Button>
              </Box>
            )}
          </Grid>
        </Grid>

        {completeForm ? (
          <>
            <DocSections form={form} />

            <Box mt={3} display="flex" justifyContent="flex-end">
              <ButtonPrimary onClick={() => setModalPreview(true)}>
                {t('button.preview')}
              </ButtonPrimary>
              <Box ml={3}>
                <ButtonPrimary disabled={loading} onClick={handleSubmit(onSubmit)}>
                  {t('button.save')}
                </ButtonPrimary>
              </Box>
            </Box>
          </>
        ) : (
          <Box my={5}>
            <Typography variant="h5" mb={2}>
              {t('document.waiver.desc')}
            </Typography>
            <Typography mb={2}>{t('document.waiver.desc1')}</Typography>
            <Typography>{t('document.waiver.desc2')}</Typography>

            <Box display="flex" justifyContent="center" mt={5}>
              <Box mr={1}>
                <ButtonPrimary onClick={() => setModalUpload(true)}>
                  {t('button.uploadPdf')}
                </ButtonPrimary>
              </Box>
              <Box ml={1}>
                <ButtonPrimary onClick={handleCreateYourOwn}>
                  {t('button.createYourOwn')}
                </ButtonPrimary>
              </Box>
            </Box>
          </Box>
        )}

        <ModalS3Updload
          setS3Url={handleUploadedPdf}
          open={modalUpload}
          close={() => setModalUpload(false)}
          ownerableId={ownerableId}
          ownerableType={ownerableType}
        />
      </Box>
    </>
  );
};

export default FormDocument;
