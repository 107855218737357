import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';

import Paper from 'core/ui/Paper';
import Typography from 'core/ui/Typography';
import LoaderSm from 'core/ui/LoaderSm';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import { ButtonPrimary } from 'core/ui/Button';

import { channelsActions, channelsSelectors } from 'modules/channels';
import clsx from 'clsx';
import useChannelStyles from 'core/useChannelStyles';

export const MembershipDiscountCode = ({
  channelId,
  membershipPlanId,

  arbitraryCouponCode,

  coupon,
  onCoupon,

  className,
}) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const loadingDiscountCode = useSelector(channelsSelectors.getLoadingDiscountCode);

  const { control, reset, setValue, handleSubmit } = useForm({
    defaultValues: { code: arbitraryCouponCode },
  });

  const { buttonColor } = useChannelStyles();

  const onSubmitCode = useCallback(
    ({ code }) => {
      if (!code) return;

      setValue('code', code);

      dispatch(
        channelsActions.applyMembershipDiscountCodeStart({
          channelId,
          membershipPlanId,
          code,
          callback: (data) => onCoupon(data || null),
        }),
      );
    },
    [dispatch, channelId, membershipPlanId, onCoupon, setValue],
  );

  const clearCoupon = () => {
    onCoupon(null);
    reset();
  };

  return (
    <Paper className={clsx('paper-rounded tw-flex tw-flex-col tw-gap-2 tw-p-2', className)}>
      <Typography align="center" className="tw-font-semibold">
        {t('channel.membership.discountCode')}
      </Typography>

      <form onSubmit={handleSubmit(onSubmitCode)} className="tw-flex tw-items-center tw-gap-2">
        <Controller
          control={control}
          name="code"
          rules={{ required: 'Code is required.' }}
          render={({ field }) => (
            <TextFieldRounded
              placeholder={t('channel.membership.code.placeholder')}
              value={field.value}
              onChange={field.onChange}
              disabled={!!coupon}
            />
          )}
        />

        <ButtonPrimary
          type={coupon ? 'button' : 'submit'}
          onClick={coupon ? clearCoupon : undefined}
          buttonColor={buttonColor}
          className="tw-h-7 tw-w-24"
        >
          {loadingDiscountCode ? (
            <LoaderSm loading width={25} />
          ) : (
            t(coupon ? 'button.clear' : 'button.apply')
          )}
        </ButtonPrimary>
      </form>

      {coupon && (
        <Typography variant="caption" align="center" color="textSecondary">
          {t(
            coupon.renewable
              ? 'channel.membership.code.monthly'
              : 'channel.membership.code.onetime',
          )}
        </Typography>
      )}
    </Paper>
  );
};
