import React, { useEffect, useRef, useState } from 'react';
import { Document, Page } from 'react-pdf';
import { makeStyles } from '@material-ui/core';
import throttle from 'lodash.throttle';

import LoaderSm from 'core/ui/LoaderSm';

const MAX_PDF_WIDTH = 820;

export const PDFSection = ({ file }) => {
  const classes = useStyles();
  const wrapperRef = useRef();

  const [numberOfPages, setNumberOfPages] = useState(0);
  const [width, setWidth] = useState();

  useEffect(() => {
    const setNewWidth = throttle((entrie) => {
      setWidth(Math.min(MAX_PDF_WIDTH, entrie.contentRect.width));
    }, 250);

    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach(setNewWidth);
    });

    resizeObserver.observe(wrapperRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <div ref={wrapperRef} className={classes.container}>
      <Document
        file={file}
        onLoadSuccess={({ numPages }) => setNumberOfPages(numPages)}
        options={{
          // https://github.com/advisories/GHSA-wgrm-67xf-hhpq
          isEvalSupported: false,
        }}
        loading={<LoaderSm loading center />}
        className={classes.pdfDocument}
      >
        {Array.from(new Array(numberOfPages), (_, index) => (
          <Page key={index} pageNumber={index + 1} width={width} />
        ))}
      </Document>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    maxWidth: MAX_PDF_WIDTH,
    width: '100%',
    margin: '0 auto',
  },
  pdfDocument: {
    display: 'flex',
    flexDirection: 'column',
    gap: 3,
    overflowX: 'auto',
  },
});
