/* eslint-disable react/prop-types */
import React from 'react';
import Paper from 'core/ui/Paper';
import { useTranslation } from 'react-i18next';
import Typography from 'core/ui/Typography';
import SFDataTable from 'core/ui/DataTable';
import ResultTracks from './ResultTracks';

export default function ResultUsers({ channelsKeys }) {
  const { t } = useTranslation();
  const columns = [
    {
      name: 'User Name',
      selector: 'userName',
      sortable: true,
      cell: (row) => <Typography>{row.userName}</Typography>,
    },
    {
      name: 'Membership',
      selector: 'membershipPlan',
      sortable: true,
      cell: (row) => <Typography>{row.membershipPlan}</Typography>,
    },
    {
      name: 'Uses',
      sortable: true,
      cell: (row) => <Typography>{row.productKind}</Typography>,
    },
  ];

  const rows =
    channelsKeys &&
    channelsKeys.map((ck) => ({
      id: ck.id,
      userName: ck.user && ck.user.name,
      membershipPlan: ck.membershipPlan && ck.membershipPlan.name,
      hybridTracks: ck.hybridTracks,
    }));

  const ExpanableComponent = ({ data }) => <ResultTracks tracks={data?.hybridTracks} />;

  return (
    <Paper style={{ width: '100%' }}>
      <SFDataTable
        title={t('channel.reports.hybridChannelKeys')}
        columns={columns}
        data={rows}
        pagination
        showExport
        expandableRows
        expandableRowsComponent={<ExpanableComponent />}
      />
    </Paper>
  );
}
