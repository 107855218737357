import React, { useEffect } from 'react';
import Box from 'core/ui/Box';
import Header from 'components/Profile/Edit/Header';
import FormAthlete from 'components/Profile/Edit/Athlete/Form';
import { ButtonPrimary } from 'core/ui/Button';
import LoaderSm from 'core/ui/LoaderSm';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form-v5';
import { useTranslation } from 'react-i18next';
import { usersActions, usersSelectors } from 'modules/users';
import notifications from 'modules/notifications';
import { primary } from 'core/ui/Colors';
import { sessionActions, sessionSelectors } from 'modules/session';
import { channelsActions } from 'modules/channels';

const QuickForm = ({ buttonColor, channelId, fastForm }) => {
  const { t } = useTranslation();
  const { register, handleSubmit, errors, setValue, watch, reset } = useForm();
  const dispatch = useDispatch();
  const user = useSelector((state) => sessionSelectors.getUser(state));
  const loading = useSelector((state) => usersSelectors.getLoading(state));

  useEffect(() => {
    dispatch(sessionActions.authenticateStart());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    register({ name: 'name' }, { required: true });
    register({ name: 'phone' }, { required: true });
    register({ name: 'instagram' }, { required: false });
    register({ name: 'referredBy' }, { required: false });
    register({ name: 'state' }, { required: false });
    register({ name: 'gender' }, { required: false });
    register({ name: 'birthday' });

    register({ name: 'emergencyName' });
    register({ name: 'emergencyEmail' });
    register({ name: 'emergencyPhone' });

    register(
      { name: 'countryId' },
      {
        required: !fastForm,
        validate: (value) => Number(value) > 0,
      },
    );

    setTimeout(() => {
      setValue('countryId', 16);
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register]);

  useEffect(() => {
    if (user) {
      reset(user);
    }
  }, [reset, setValue, user]);

  const onSubmit = (data) => {
    let instagram = '';
    if (data.instagram && data.instagram.indexOf('@') === -1) {
      instagram = `@${data.instagram}`;
    } else if (data.instagram) {
      instagram = data.instagram;
    }
    const userData = {
      ...data,
      instagram,
    };

    dispatch(
      usersActions.putUpdateUserStart(
        {
          id: user.id,
          user: { role: 'athlete', ...userData },
          channelId,
        },
        () => {
          if (channelId) {
            dispatch(channelsActions.getChannelStart(channelId));
          }
          dispatch(notifications.success(t('message.success.save.profile')));
        },
      ),
    );
  };

  return (
    <Box flexGrow={1}>
      <Header
        errors={errors}
        loading={loading}
        setValue={setValue}
        setIsPhoneValid={() => {}}
        register={register}
        watch={watch}
        quickForm
        fastForm={fastForm}
        user={user}
      />
      <Box mt={3}>
        <FormAthlete
          errors={errors}
          loading={loading}
          setValue={setValue}
          setIsPhoneValid={() => {}}
          register={register}
          watch={watch}
          quickForm
          fastForm={fastForm}
        />
      </Box>

      <Box mb={5} display="flex" justifyContent="flex-end">
        <ButtonPrimary
          type="button"
          onClick={() => handleSubmit(onSubmit)()}
          size="small"
          disabled={loading}
          buttonColor={buttonColor || primary}
          style={{
            height: 30,
            width: 80,
            borderRadius: 5,
          }}
        >
          <LoaderSm loading={loading} width={20} />
          {t('button.save')}
        </ButtonPrimary>
      </Box>
    </Box>
  );
};

QuickForm.propTypes = {};

QuickForm.defaultProps = {};

export default QuickForm;
