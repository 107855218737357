import api from 'core/api';

// eslint-disable-next-line import/prefer-default-export
export const getChannelMembers = (params) =>
  api.get(`/channel_members/${params?.channelId}`, { params });
export const getChannelMember = (channelId, userId) =>
  api.get(`/channel_members/${channelId}/member/${userId}`);
export const getAvailableClasses = (channelId, userId, workoutId) =>
  api.get(
    `/channel_members/${channelId}/member/${userId}/available_classes?workout_id=${workoutId}`,
  );
export const memberUnregisterClass = (channelId, userId, workoutId) =>
  api.get(`/channel_members/${channelId}/member/${userId}/unregister/${workoutId}`);
export const memberRegisterClass = (channelId, userId, workoutId, type) =>
  api.get(`/channel_members/${channelId}/member/${userId}/register/${workoutId}/${type}`);

export const renewMembership = (data) =>
  api.get(`/channel_members/${data.channelId}/renew/${data.channelKeyId}`, data);
export const putUpdateMember = (data) =>
  api.put(`/channel_members/${data.channelId}/member/${data.userId}/update`, data);
export const postAddMember = (data) =>
  api.put(`/channel_members/${data.channelId}/member/add`, data);

export const uploadFileMembers = (data) =>
  api.post(`/channel_members/${data.channelId}/upload_members`, data);
