import React from 'react';
import PropTypes from 'prop-types';

const LabelBreakLine = ({ children, style }) =>
  children ? (
    children.split('\n').map((line, index) => (
      <span key={index.toString()} style={style}>
        {line}
        <br />
      </span>
    ))
  ) : (
    <></>
  );

export default LabelBreakLine;

LabelBreakLine.propTypes = {
  children: PropTypes.string,
};

LabelBreakLine.defaultProps = {
  children: '',
};
