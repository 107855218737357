import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import styled from 'styled-components';
// eslint-disable-next-line import/no-extraneous-dependencies
import { spacing } from '@material-ui/system';
import { Box, Card as MuiCard, CardHeader, Divider as MuiDivider } from '@material-ui/core';

const CardMui = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);

const useStyles = makeStyles((theme) => ({
  card: {
    borderTopWidth: 1,
    borderColor: theme.palette.mq.card.colorRed,
  },
}));

const Card = ({ style, ...props }) => {
  const classes = useStyles();
  const { palette } = useTheme();

  const bTopColor =
    !props.btopcolor || props.btopcolor === '#4fb94a' ? palette.primary.main : props.btopcolor;

  return (
    <Box borderRadius={5} borderTop={props.btopwidth ? props.btopwidth : 0} borderColor={bTopColor}>
      <CardMui {...props} display="flex" className={classes.card}>
        {props.title && (
          <CardHeader
            title={props.title}
            style={{ color: props.color ? props.color : palette.primary.main, padding: 8 }}
            avatar={props.avatar}
            titleTypographyProps={{ variant: 'subtitle2' }}
            action={props.action}
          />
        )}

        {!props.hidedivider && <Divider ml={2} mr={2} />}

        {props.children && <Box p={0.5}>{props.children}</Box>}
      </CardMui>
    </Box>
  );
};

export default Card;
