/* eslint-disable react/prop-types */
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import Typography from 'core/ui/Typography';
import Box from 'core/ui/Box';
import Scrollbars from 'react-custom-scrollbars';
import { secondary } from 'core/ui/Colors';
import CalculatorIcon from 'core/ui/MIcon/CalculatorIcon';
import ModalCalculator from 'components/Profile/Dashboard/PersonalRecord/ModalCalculator';
import { workoutInviteTypes } from 'core/utils/consts';
import { ShowContext } from '..';
import ButtonActions from '../Tabs/Overview/Invites/ButtonActions';

export default ({ workout, showRecordings }) => {
  const { t } = useTranslation();
  const showContext = useContext(ShowContext);

  const useStyles = makeStyles((theme) => ({
    boxTabs: {
      display: 'flex',
      height: 55,
      width: '100%',
      borderRadius: 5,
      alignItems: 'center',
    },
    wraperTabs: {
      background: secondary,
      height: 55,
      borderRadius: 5,
    },
    activeTab: {
      borderBottom: '0.5px dotted #fff',
    },
    styleTab: {
      padding: 3,
      marginLeft: 5,
      [theme.breakpoints.up('sm')]: {
        padding: 5,
        marginLeft: 11,
      },
    },
    styleTabFirst: {
      padding: 3,
      [theme.breakpoints.up('sm')]: {
        padding: 5,
        marginLeft: 3,
      },
    },
  }));
  const classes = useStyles();

  const [modalCalculator, setModalCalculator] = useState(false);

  const ItemTab = ({ label, tab, onClick }) => (
    <Box className={classes.styleTab}>
      <Button size="small" onClick={() => (onClick ? onClick() : showContext.setTab(tab))}>
        <Typography
          className={showContext.tab === tab && classes.activeTab}
          style={{ color: 'white' }}
          variant="subtitle2"
        >
          {label}
        </Typography>
      </Button>
    </Box>
  );

  const recordCompare = workout.invites?.find(
    (x) => x.inviteType === workoutInviteTypes.record_compare,
  );

  const liveInvite = workout.invites?.find(
    (x) => x.inviteType === workoutInviteTypes.live_one_to_one && !!x.live,
  );

  return (
    <Box display="flex" alignItems="center" className={classes.wraperTabs} px={2}>
      {modalCalculator && <ModalCalculator close={() => setModalCalculator(false)} />}
      <Scrollbars
        autoHide
        renderTrackVertical={(props) => (
          <div {...props} style={{ display: 'none' }} className="track-horizontal" />
        )}
        style={{ width: '100%', height: 55 }}
      >
        <Box className={classes.boxTabs}>
          <Box
            className={showContext.tab === 'leaderboard' ? classes.styleTabFirst : classes.styleTab}
          >
            <Button size="small" onClick={() => showContext.setTab('overview')}>
              <Typography
                className={
                  (showContext.tab === 'overview' || showContext.tab === 'leaderboard') &&
                  classes.activeTab
                }
                style={{ color: 'white' }}
                variant="subtitle2"
              >
                {t('workout.tab.overview')}
              </Typography>
            </Button>
          </Box>

          <ItemTab label={t('workout.tab.invites')} tab="invites" />
          {showRecordings && <ItemTab label={t('workout.tab.recordings')} tab="recordings" />}

          <Box>
            <Button size="small" onClick={() => setModalCalculator(true)}>
              <CalculatorIcon color="white" size={24} />
            </Button>
          </Box>
        </Box>
      </Scrollbars>
      <Box>
        {(recordCompare || liveInvite) && (
          <Box mx={1}>
            <ButtonActions
              invite={recordCompare || liveInvite}
              showStartJoinButton
              workout={workout}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};
