import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import TextAreaFieldRounded from 'core/ui/TextAreaFieldRounded';
import Typography, { fontStyle } from 'core/ui/Typography';
import { ButtonPrimary, ButtonPrimarySm } from 'core/ui/Button';
import { typeTimer } from 'core/utils/consts';
import { convertTimerToApi, validateTime, populateVideos } from 'core/utils/helpers';
import { useDispatch } from 'react-redux';
import notifications from 'modules/notifications';
import Paper from 'core/ui/Paper';
import Divider from 'core/ui/Divider';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import MIcon from 'core/ui/MIcon';
import Modules from 'modules';
import { channelProgramsModule } from 'modules/channelPrograms';
import useActions from 'modules/map/useActions';
import useSelectors from 'modules/map/useSelectors';
import ModalMediaFilesGallery from 'components/LibraryMedias/MediaFilesGallery/Modal';
import ItemMedia from 'components/LibraryMedias/MediaFilesGallery/ItemMedia';
import Grid from 'core/ui/Grid';
import ModalPersonalRecord from './ModalPersonalRecord';
import FormatSectionPrRow from './FormatSectionPrRow';
import FormSectionScores from './FormSectionScores';
import FormSectionTimer from './FormSectionTimer';

const FormSection = ({
  sections,
  setSections,
  showSaveButton,
  isVideoClass,
  isCompetition,
  submiting,
  currentSection,
  closeModalSection,
  saveLiveSection,
  channelId,
  partnerId,
  custom,
  bordered,
  partnerProgramming,
  partnerPrograms,
  programmingClassesContent,
  callbackSave,
}) => {
  const { t } = useTranslation();
  const { request } = useActions();

  const defaultTimerData = {
    rounds: 1,
    type: 'none',
  };
  const [openPR, setOpenPR] = useState(false);
  const [mediaFileGallery, setMediaFileGallery] = useState({ open: false });
  const [timerData, setTimerData] = useState(defaultTimerData);
  const [section, setSection] = useState({
    scores: [{}],
    timerData: { rounds: 1 },
  });
  const channelPrograms = useSelectors(channelProgramsModule, 'data');

  const dispatch = useDispatch();

  useEffect(() => {
    if (channelId) {
      request({
        action: Modules.channelPrograms.actions.getChannelPrograms,
        data: { channelId },
      });
    }
  }, [request, channelId]);

  useEffect(() => {
    if (currentSection && currentSection.section) {
      const newSection = { ...currentSection?.section };
      newSection.mediaFilesAssociationsAttributes = populateVideos(newSection);

      setSection(newSection);
      if (currentSection.timerData) {
        setTimerData(currentSection.timerData);
      }
    }
  }, [currentSection]);

  const validate = () => {
    const errorsValidation = [];
    if (!section.title) {
      errorsValidation.push(t('workout.section.validate.title'));
    }

    if (timerData.type !== 'none') {
      if (!timerData.type) {
        errorsValidation.push(t('workout.section.validate.timerType'));
      }

      if (isVideoClass && !validateTime('timeStart', timerData)) {
        errorsValidation.push(t('workout.section.validate.timeStart'));
      }

      if (timerData.rounds > 1 && timerData.customInterval) {
        timerData.customIntervalTimers.forEach((cT, index) => {
          if (!validateTime('work', cT)) {
            errorsValidation.push(`${index} - ${t('workout.section.validate.workTime')}`);
          }
        });
      } else if (!timerData.unlimited && !validateTime('work', timerData)) {
        errorsValidation.push(t('workout.section.validate.workTime'));
      }

      if (
        !timerData.rounds &&
        timerData.type !== typeTimer.amrap &&
        timerData.type !== typeTimer.forTime
      ) {
        errorsValidation.push(t('workout.section.validate.rounds'));
      }
    }

    if (isCompetition) {
      if (timerData.type === 'none' || !timerData.type) {
        errorsValidation.push(t('workout.section.validate.timerType'));
      }

      if (section && section?.scores.length === 0) {
        errorsValidation.push(t('workout.section.validate.scores'));
      }
    }

    if (errorsValidation.length > 0) {
      dispatch(notifications.warning(errorsValidation.join(' ')));
      return false;
    }
    return true;
  };

  const saveSection = (fromButton = false) => {
    if (!fromButton && !section?.title) {
      return true;
    }

    if (!validate()) {
      return false;
    }

    if (saveLiveSection) {
      saveLiveSection(section, timerData);
      return true;
    }

    const { work, rest, timeStart, customIntervalTimers } = convertTimerToApi({
      timerData,
      isVideoClass,
    });

    let order = 0;
    if (section?.order !== 0) {
      order = section?.order || sections[sections?.length - 1]?.order + 1 || 0;
    }

    const sectionData = {
      ...section,
      order,
      id: section.id ? section.id : Math.random() * 100,
      new: section.new || !section?.id,
      timerData: {
        ...timerData,
        customIntervalTimers,
        work,
        rest,
        timeStart,
        count: timerData.count || 'UP',
      },
    };
    if (isCompetition) {
      setSections([{ ...sectionData }]);
    } else {
      setSections((prev) => [...prev.filter((x) => x.id !== sectionData.id), sectionData]);
    }

    setSection({
      timerData: {},
      scores: [{}],
      editing: false,
    });
    setTimerData(defaultTimerData);
    if (closeModalSection) {
      closeModalSection(null);
    }

    if (callbackSave) {
      callbackSave(sectionData);
    }

    return true;
  };

  useEffect(() => {
    if (submiting) {
      saveSection();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submiting]);

  const checkCustomTimer = (v) => {
    const arr = [];
    if (v) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < timerData.rounds; i++) {
        const tm = timerData.customIntervalTimers ? timerData.customIntervalTimers[i] : null;
        arr.push(tm || { work: null, rest: null });
      }
      setTimerData((prev) => ({ ...prev, customInterval: v, customIntervalTimers: arr }));
    } else {
      setTimerData((prev) => ({ ...prev, customInterval: v, customIntervalTimers: [] }));
    }
  };

  useEffect(() => {
    if (timerData.customInterval) {
      checkCustomTimer(timerData.customInterval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timerData.rounds, timerData.customInterval]);

  const removePr = (pr) => {
    setSection((prev) => ({
      ...prev,
      personalRecords: prev.personalRecords.filter((x) => x !== pr),
    }));
  };

  const disabled = !!section?.parentId || !!section.benchmarkSectionId;

  const removeItemMediaFile = (item) => {
    setSection((prev) => ({
      ...prev,

      mediaFilesAssociationsAttributes: prev?.mediaFilesAssociationsAttributes.map((x) => {
        if (x === item) {
          return { ...x, _destroy: true };
        }
        return x;
      }),
    }));
  };

  const setPrInScore = (selectedPr) => {
    setSection((prev) => ({
      ...prev,
      scores: prev.scores.map((score, index) => {
        let newScore = { ...score };
        if (index === openPR.scoreIndex) {
          newScore = score?.liftComplex ? { ...score } : { name: score?.name };
          newScore.personalRecord = {
            id: selectedPr?.id,
            movementName: selectedPr?.movement?.name,
          };
          if (!score?.liftComplex) {
            if (selectedPr?.scoreType === 'rep_max') {
              newScore.weight = true;
              newScore.personalRecord.repMaxType = selectedPr?.selectedScore;
            }
            if (selectedPr?.scoreType === 'max_distance') {
              newScore.distance = true;
            }
            if (selectedPr?.scoreType === 'min_max') {
              newScore[selectedPr?.selectedScore] = true;
              newScore.personalRecord.repMaxType = selectedPr?.selectedScore;
            }
            if (selectedPr?.scoreType === 'weight' || selectedPr?.scoreType === 'time') {
              newScore[selectedPr?.scoreType] = true;
            }
          } else {
            newScore.personalRecord.repMaxType = selectedPr?.selectedScore;
          }
        }

        return newScore;
      }),
    }));

    setOpenPR({ open: false });
  };

  return (
    <>
      {openPR?.open && (
        <ModalPersonalRecord
          prScore={openPR?.prScore}
          liftComplext={openPR?.liftComplext}
          setPr={(pr) => setPrInScore(pr)}
          setSection={setSection}
          close={() => setOpenPR({ open: false })}
        />
      )}

      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Box flexGrow="1" mb={4}>
            <TextFieldRounded
              disabled={disabled}
              placeholder={t(
                isCompetition
                  ? 'workout.competition.section.placeholder.title'
                  : 'workout.section.placeholder.title',
              )}
              label={t('workout.section.label.title').toUpperCase()}
              value={section.title || ''}
              onChange={(v) => setSection((prev) => ({ ...prev, title: v }))}
              bordered={bordered}
              inputProps={{ maxLength: 200 }}
            />
          </Box>
          {!custom && channelPrograms?.filter((x) => !!x.active)?.length > 0 && (
            <Box mb={4}>
              <SelectBoxObject
                label={t('workout.section.label.program').toUpperCase()}
                options={channelPrograms?.filter((x) => !!x.active) || []}
                value={section.channelProgram ? Number(section.channelProgram?.id) : null}
                emptyValue={null}
                placeholder="No Selection"
                emptyItem
                setValue={(v) =>
                  setSection((prev) => ({
                    ...prev,
                    channelProgramId: v,
                    channelProgram: {
                      id: Number(v),
                      name: channelPrograms.find((x) => Number(x.id) === Number(v))?.name,
                    },
                  }))
                }
                bordered={bordered}
              />
            </Box>
          )}
          {!isCompetition && !custom && (
            <Paper p={5} mb={4} mt={6} className="paper-rounded">
              <Box display="flex" alignItems="center">
                <Box flexGrow={1} mr={1}>
                  <Typography component="span" style={fontStyle.medium}>
                    {t('section.customized.liftPercentages')}
                  </Typography>
                </Box>
                <ButtonPrimarySm style={{ width: 150 }} onClick={() => setOpenPR({ open: true })}>
                  {t('button.liftPercentages')}
                </ButtonPrimarySm>
              </Box>
              <Divider mt={2} />
              <Box>
                {section.personalRecords &&
                  section.personalRecords.map((pr, index) => (
                    <Box mt={1} display="flex" alignItems="center" key={index.toString()}>
                      <Box flexGrow={1}>
                        <FormatSectionPrRow form data={pr} movement={pr.movementName} />
                      </Box>
                      <Box className="hover">
                        <MIcon icon="remove_circle" onClick={() => removePr(pr)} />
                      </Box>
                    </Box>
                  ))}
              </Box>
            </Paper>
          )}

          {partnerProgramming &&
            !custom &&
            partnerPrograms?.filter((x) => !!x.active)?.length > 1 && (
              <Box mb={4}>
                <SelectBoxObject
                  label={t('workout.section.label.partnerProgram').toUpperCase()}
                  options={partnerPrograms?.filter((x) => !!x.active)}
                  value={section.partnersProgram ? Number(section.partnersProgram?.id) : null}
                  setValue={(v) =>
                    setSection((prev) => ({
                      ...prev,
                      partnersProgramId: v,
                      partnersProgram: {
                        id: Number(v),
                        name: partnerPrograms?.find((x) => Number(x.id) === Number(v))?.name,
                      },
                    }))
                  }
                  bordered={bordered}
                />
              </Box>
            )}

          <Box flexGrow="1" mb={4} style={{ position: 'relative' }}>
            {(partnerId || channelId) && (
              <Box
                display="flex"
                justifyContent="center"
                style={{ position: 'absolute', right: 0, top: -2, zIndex: 10 }}
              >
                <ButtonPrimarySm
                  onClick={() => setMediaFileGallery({ open: true, type: 'section' })}
                >
                  {t('button.addVideo')}
                </ButtonPrimarySm>
              </Box>
            )}
            <TextAreaFieldRounded
              disabled={disabled}
              placeholder={t('workout.section.placeholder.description')}
              label={t('workout.section.label.description').toUpperCase()}
              value={section.description || ''}
              onChange={(v) => setSection((prev) => ({ ...prev, description: v }))}
              rows={8}
              bordered={bordered}
              resize="vertical"
            />
          </Box>

          <Box>
            {(partnerId || channelId) && (
              <>
                <ModalMediaFilesGallery
                  section={section}
                  ownerableId={channelId || partnerId}
                  ownerableType={channelId ? 'Channel' : 'Partner'}
                  open={mediaFileGallery?.open}
                  selectedFiles={section?.mediaFilesAssociationsAttributes
                    ?.filter((x) => x.kind === mediaFileGallery.type && !x?._destroy)
                    ?.map((x) => x.mediaFile)}
                  close={() => setMediaFileGallery({ open: false })}
                  associableId={section?.id}
                  associableType={section?.id ? 'WorkoutSection' : ''}
                  onSelected={(mediaFile) => {
                    setSection((prev) => ({
                      ...prev,

                      mediaFilesAssociationsAttributes: [
                        ...(prev?.mediaFilesAssociationsAttributes || []),
                        {
                          mediaFile,
                          mediaFileId: mediaFile?.id,
                          kind: mediaFileGallery?.type,
                        },
                      ],
                    }));
                  }}
                />
                {section?.mediaFilesAssociationsAttributes?.find(
                  (x) => x.kind === 'section' && !x?._destroy,
                ) && (
                  <Box mb={4}>
                    <Typography mb={2} style={fontStyle.label}>
                      {t('workout.label.generalVideos').toUpperCase()}
                    </Typography>
                    <Paper p={4} mb={4} mt={2}>
                      {section?.mediaFilesAssociationsAttributes
                        ?.filter((x) => x.kind === 'section' && !x?._destroy)
                        .map((item, index) => (
                          <Box key={index} style={{ position: 'relative' }}>
                            <ItemMedia mediaFile={item?.mediaFile} />
                            <Box style={{ position: 'absolute', top: 14, right: 14, zIndex: 20 }}>
                              <ButtonPrimarySm
                                onClick={() => {
                                  removeItemMediaFile(item);
                                }}
                              >
                                {t('button.remove')}
                              </ButtonPrimarySm>
                            </Box>
                          </Box>
                        ))}
                    </Paper>
                  </Box>
                )}
              </>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box mb={4} style={{ position: 'relative' }}>
            {(partnerId || channelId) && (
              <Box
                display="flex"
                justifyContent="center"
                mb={1}
                style={{ position: 'absolute', right: 0, top: -2, zIndex: 10 }}
              >
                <ButtonPrimarySm
                  onClick={() => setMediaFileGallery({ open: true, type: 'athlete_notes' })}
                >
                  {t('button.addVideo')}
                </ButtonPrimarySm>
              </Box>
            )}
            <TextAreaFieldRounded
              name="athleteNotes"
              placeholder={t('workout.section.placeholder.athleteNotes')}
              label={t('workout.section.label.athleteNotes').toUpperCase()}
              value={section.athleteNotes || ''}
              onChange={(v) => setSection((prev) => ({ ...prev, athleteNotes: v }))}
              resize="vertical"
              rows={10}
            />
            {(partnerId || channelId) &&
              section?.mediaFilesAssociationsAttributes?.find(
                (x) => x.kind === 'athlete_notes' && !x?._destroy,
              ) && (
                <Box mb={4} mt={2}>
                  <Typography mb={2} style={fontStyle.label}>
                    {t('workout.label.athleteVideos').toUpperCase()}
                  </Typography>
                  <Paper p={4} mb={4} mt={2}>
                    {section?.mediaFilesAssociationsAttributes
                      ?.filter((x) => x.kind === 'athlete_notes' && !x?._destroy)
                      .map((item, index) => (
                        <Box key={index} style={{ position: 'relative' }}>
                          <ItemMedia mediaFile={item?.mediaFile} />
                          <Box style={{ position: 'absolute', top: 14, right: 14, zIndex: 20 }}>
                            <ButtonPrimarySm
                              onClick={() => {
                                removeItemMediaFile(item);
                              }}
                            >
                              {t('button.remove')}
                            </ButtonPrimarySm>
                          </Box>
                        </Box>
                      ))}
                  </Paper>
                </Box>
              )}
          </Box>
          <Box mb={4} style={{ position: 'relative' }}>
            {(partnerId || channelId) && (
              <Box
                display="flex"
                justifyContent="center"
                mb={1}
                style={{ position: 'absolute', right: 0, top: -2, zIndex: 10 }}
              >
                <ButtonPrimarySm
                  onClick={() => setMediaFileGallery({ open: true, type: 'coach_notes' })}
                >
                  {t('button.addVideo')}
                </ButtonPrimarySm>
              </Box>
            )}
            <TextAreaFieldRounded
              name="coachNotes"
              placeholder={t('workout.placeholder.coachNotes')}
              label={t(
                isCompetition ? 'workout.label.judgeNotes' : 'workout.label.coachNotes',
              ).toUpperCase()}
              value={section.coachNotes || ''}
              onChange={(v) => setSection((prev) => ({ ...prev, coachNotes: v }))}
              resize="vertical"
              rows={10}
            />
          </Box>

          {(partnerId || channelId) &&
            section?.mediaFilesAssociationsAttributes?.find(
              (x) => x.kind === 'coach_notes' && !x?._destroy,
            ) && (
              <Box mb={4}>
                <Typography mb={2} style={fontStyle.label}>
                  {t('workout.label.coachVideos').toUpperCase()}
                </Typography>
                <Paper p={4} mb={4} mt={2}>
                  {section?.mediaFilesAssociationsAttributes
                    ?.filter((x) => x.kind === 'coach_notes' && !x?._destroy)
                    .map((item, index) => (
                      <Box key={index} style={{ position: 'relative' }}>
                        <ItemMedia mediaFile={item?.mediaFile} />
                        <Box style={{ position: 'absolute', top: 14, right: 14, zIndex: 20 }}>
                          <ButtonPrimarySm
                            onClick={() => {
                              removeItemMediaFile(item);
                            }}
                          >
                            {t('button.remove')}
                          </ButtonPrimarySm>
                        </Box>
                      </Box>
                    ))}
                </Paper>
              </Box>
            )}
        </Grid>
      </Grid>

      <Grid container spacing={5} mb={3}>
        <Grid item xs={12} md={programmingClassesContent ? 6 : 12}>
          <Paper p={5} mb={4} className="paper-rounded">
            <Box display="flex" alignItems="center">
              <Box flexGrow={1} mr={1}>
                <Typography component="span" color="primary" style={fontStyle.medium}>
                  {t('section.scores').toUpperCase()}
                </Typography>
              </Box>
              <ButtonPrimarySm
                style={{ width: 120 }}
                onClick={() =>
                  setSection((prev) => ({
                    ...prev,
                    scores: prev.scores ? [...prev.scores, {}] : [{}],
                  }))
                }
              >
                <Typography style={fontStyle.medium}>{t('button.addScores')}</Typography>
              </ButtonPrimarySm>
            </Box>
            <Divider my={2} />

            {section?.scores?.map((data, index) => (
              <Box key={index.toString()} mb={1} style={{ position: 'relative' }}>
                <FormSectionScores
                  setOpenPR={setOpenPR}
                  setSection={setSection}
                  index={index}
                  data={data}
                  setScoreData={(values) => {
                    const scores = { ...data, ...values };
                    const sectionScores = [...section.scores];

                    sectionScores[index] = scores;

                    setSection((prev) => ({
                      ...prev,
                      scores: sectionScores,
                    }));
                  }}
                  scoreData={data}
                  disabled={disabled}
                  bordered={bordered}
                />

                {section?.scores.length - 1 > index && <Divider m={2} />}
              </Box>
            ))}
          </Paper>
        </Grid>

        <Grid item xs={12} md={programmingClassesContent ? 6 : 12}>
          {programmingClassesContent}
        </Grid>
      </Grid>

      <Paper p={5} mb={4} className="paper-rounded">
        <FormSectionTimer
          isVideoClass={isVideoClass}
          timerData={timerData}
          setTimerData={setTimerData}
          disabled={disabled}
          bordered={bordered}
          isCompetition={isCompetition}
        />
      </Paper>

      {showSaveButton && (
        <Box mt={4} display="flex" justifyContent="flex-end">
          <ButtonPrimary onClick={() => saveSection(true)}>{t('button.saveSection')}</ButtonPrimary>
        </Box>
      )}
    </>
  );
};

export default FormSection;

FormSection.defaultProps = {
  custom: false,
  sections: [],
  setSections: () => {},
  register: () => {},
  currentSection: {},
  workout: {},
  mode: 'new',
  showSaveButton: false,
  isVideoClass: false,
  isCompetition: false,
  submiting: false,
  editingRecurring: false,
  saveLiveSection: null,
  channelId: null,
};
