/* eslint-disable react/prop-types */
import * as React from 'react';
import Paper from 'core/ui/Paper';
import { useTranslation } from 'react-i18next';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer } from 'recharts';

export default function CardRevenueByTypes({ data }) {
  const { t } = useTranslation();

  const categories = [
    {
      name: t('channel.reports.summary.memberships'),
      value: data.memberships?.total,
      color: '#269FFC',
    },
    {
      name: t('channel.reports.summary.competitions'),
      value: data.competitionRegistrations?.total,
      color: '#FD8080',
    },
    { name: t('channel.reports.summary.store'), value: data?.store?.total, color: '#04BC84' },
    { name: t('channel.reports.summary.playLists'), value: data?.lists?.total, color: '#94DAFB' },
  ];

  return (
    <Paper style={{ height: '100%', width: '100%' }} p={3}>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart>
          <Pie
            data={categories?.map((x) => ({ ...x, value: x.value * 100 }))}
            dataKey="value"
            nameKey="name"
          >
            {categories?.map((entry, index) => (
              <Cell key={`cell-${index.toString()}`} fill={entry?.color} />
            ))}
          </Pie>
          <Legend />
        </PieChart>
      </ResponsiveContainer>
    </Paper>
  );
}
