import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import UserIcon from '@material-ui/icons/Person';
import Slide from '@material-ui/core/Slide';
import { useTranslation } from 'react-i18next';
import Texture from 'core/ui/Texture';
import Scrollbars from 'react-custom-scrollbars';
import { Tab, Tabs, Tooltip } from '@material-ui/core';
import Box from 'core/ui/Box';
import Paper from 'core/ui/Paper';
import CoachMenuTypes from './CoachMenuTypes';
import useCoachManage from './useCoachManage';
import CoachRates from './Rates';
import CoachesSchedules from './Schedules/index';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const ModalCoachManage = ({ channel, channelCoach, open, close, changeUserType }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const manage = useCoachManage({ channel, coach: channelCoach?.user });
  const coach = channelCoach?.user;

  return (
    <Dialog
      style={{ marginTop: 100 }}
      fullScreen
      open={open}
      onClose={close}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar} color="secondary">
        <Toolbar>
          <UserIcon />
          <Tooltip
            placement="right-end"
            title={
              <p>
                {coach?.id} &nbsp;-&nbsp;
                {coach?.name}
              </p>
            }
          >
            <Typography variant="h6" className={classes.title}>
              {t('channel.tab.members.manage')}&nbsp;
              {coach?.name}
            </Typography>
          </Tooltip>
          <IconButton edge="start" color="inherit" onClick={close} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Scrollbars allowFullScreen>
        <Texture style={{ minHeight: '100%' }}>
          <Box p={5}>
            <CoachMenuTypes
              coach={coach}
              accessType={channelCoach?.accessType}
              calendarColor={channelCoach?.calendarColor}
              manageContactForm={channelCoach?.manageContactForm}
              showContactForm={!!channel?.activePlans?.engage.active}
              onChangeType={changeUserType}
            />

            <Paper m={5} mx={20}>
              <Tabs
                value={manage?.tab}
                onChange={(_curTab, newTab) => manage.setTab(newTab)}
                indicatorColor="primary"
                textColor="primary"
                centered
              >
                <Tab label={t('channel.coaches.payRates')} />
                <Tab label={t('channel.coaches.schedules')} />
              </Tabs>
            </Paper>

            {manage?.tab === 0 && (
              <Box>
                <CoachRates channel={channel} coach={coach} />
              </Box>
            )}
            {manage?.tab === 1 && (
              <Box>
                <CoachesSchedules channel={channel} manage={manage} coach={coach} />
              </Box>
            )}
          </Box>
        </Texture>
      </Scrollbars>
    </Dialog>
  );
};

ModalCoachManage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  channelCoach: PropTypes.object,
  open: PropTypes.bool,
  close: PropTypes.func,
};

ModalCoachManage.defaultProps = {
  channelCoach: {},
  open: false,
  close: () => {},
};

export default ModalCoachManage;
