import React from 'react';
import { LiveTv, People, SupervisedUserCircle } from '@material-ui/icons';
import { useStyles } from './styles';

export const renderEventContent = ({ view, event, timeText }) => {
  if (view.type !== 'timeGridDay' && view.type !== 'timeGridWeek') return undefined;

  return (
    <div className="fc-event-main-frame">
      <div className="fc-event-time">{timeText}</div>

      <div className="fc-event-title-container">
        <div className="fc-event-title fc-sticky">{event.title}</div>
      </div>

      {event.extendedProps.method !== 'custom' && <Attendance workout={event.extendedProps} />}
    </div>
  );
};

const Attendance = ({
  workout: {
    // Live
    maxUsers,
    countOnlineUsers,
    // In Person
    inPersonMaxUsers,
    countInPersonUsers,
    // Waitlist
    waitlistCount,
  },
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {maxUsers > 0 && (
        <div className={classes.row}>
          <LiveTv fontSize="inherit" />

          <span>
            {countOnlineUsers}/{maxUsers}
          </span>
        </div>
      )}

      {inPersonMaxUsers > 0 && (
        <div className={classes.row}>
          <People fontSize="inherit" />

          <span>
            {countInPersonUsers}/{inPersonMaxUsers}
          </span>
        </div>
      )}

      {waitlistCount > 0 && (
        <div className={classes.row}>
          <SupervisedUserCircle fontSize="inherit" />

          <span>{waitlistCount}</span>
        </div>
      )}
    </div>
  );
};
