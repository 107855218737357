/* eslint-disable no-underscore-dangle */
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Paper from 'core/ui/Paper';
import {
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Popper,
} from '@material-ui/core';
import { ButtonPrimary } from 'core/ui/Button';
import { ArrowDropDown } from '@material-ui/icons';

const ButtonActions = ({ buttonColor, addCustomOption, addOption }) => {
  const { t } = useTranslation();
  const anchorRef = useRef();
  const [open, setOpen] = useState(false);

  return (
    <>
      <ButtonGroup
        variant="contained"
        style={{ backgroundColor: buttonColor }}
        ref={anchorRef}
        aria-label="split button"
      >
        <ButtonPrimary
          style={{ width: 150 }}
          buttonColor={buttonColor}
          onClick={() => setOpen(true)}
        >
          &nbsp;{t('button.addOptions')} <ArrowDropDown />
        </ButtonPrimary>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        style={{ zIndex: 10 }}
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={() => setOpen(false)}>
                <MenuList>
                  <MenuItem onClick={() => addOption()}>{t('button.combineOptions')}</MenuItem>
                  <MenuItem onClick={() => addCustomOption()}>
                    {t('button.addCustomOption')}
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

ButtonActions.defaultProps = {};

export default ButtonActions;
