/* eslint-disable no-underscore-dangle */
import React from 'react';
import Box from 'core/ui/Box';
import { reorder } from 'core/utils/helpers';
import DocSection from './DocSection';

const DocSections = ({ form }) => {
  const addOption = (sec, text) => {
    if (!text) {
      return;
    }
    const currentOptions = sec?.options || [];
    form.changeSection(sec, 'options', [...currentOptions, text]);
  };

  const removeOption = (sec, item) => {
    form.changeSection(
      sec,
      'options',
      sec?.options.filter((x) => x !== item),
    );
  };

  const reorderSection = (sec, index) => {
    if (index <= 0) return;

    form.setSections((_sections) => {
      for (let i = index - 1; i >= 0; i -= 1) {
        if (!_sections[i]._destroy) {
          return reorder(_sections, index, i);
        }
      }

      return _sections;
    });
  };

  return (
    <Box>
      {form?.sections?.map((sec, index) =>
        sec._destroy ? null : (
          <Box key={sec?.id || index}>
            <DocSection
              sec={sec}
              index={index}
              form={form}
              actions={{ removeOption, addOption, reorderSection }}
            />
          </Box>
        ),
      )}
    </Box>
  );
};

export default DocSections;
