import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import { ButtonPrimarySm } from 'core/ui/Button';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import { adminReportsModule } from 'modules/adminReports';
import useSelectors from 'modules/map/useSelectors';
import Typography, { fontStyle } from 'core/ui/Typography';
import { currency } from 'core/utils/formatters';
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import Paper from 'core/ui/Paper';
import { primary } from 'core/ui/Colors';
import Grid from 'core/ui/Grid';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import BarMonths from './BarMonths';

const Forecast = () => {
  const { t } = useTranslation();
  const { request } = useActions();
  const loading = useSelectors(adminReportsModule, 'loading');
  const forecast = useSelectors(adminReportsModule, 'forecast');

  const [filters, setFilters] = useState({});

  const getReport = () => {
    request({
      action: Modules.adminReports.actions.getForecast,
      data: {
        ...filters,
      },
    });
  };

  const search = () => {
    getReport();
  };

  useEffect(() => {
    search();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box pb={5} pt={2} px={5}>
      <Box>
        <Box display="flex" mb={3} alignItems="flex-end" justifyContent="center" flexWrap="wrap">
          <Grid container spacing={5}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box mb={4}>
                <TextFieldRounded
                  placeholder="0"
                  label="Gyms"
                  value={filters?.qtdGyms}
                  onChange={(v) => setFilters((prev) => ({ ...prev, qtdGyms: v }))}
                  type="number"
                  pattern="[1-9]*"
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box mb={4}>
                <TextFieldRounded
                  placeholder="0,00"
                  label="Avg/Gym"
                  value={filters?.avgByGym}
                  onChange={(v) => setFilters((prev) => ({ ...prev, avgByGym: v }))}
                  type="number"
                  pattern="[1-9]*"
                />
              </Box>
            </Grid>
          </Grid>
          <ButtonPrimarySm disabled={loading} onClick={() => search()}>
            {t('button.update')}
          </ButtonPrimarySm>
        </Box>

        <Box>
          {forecast && <BarMonths title="Monthly Revenue" chartData={forecast} />}

          <MonthData monthData={forecast?.currentMonth} current />

          <Typography variant="h5" align="center" m={3}>
            Next Months
          </Typography>
          <Grid container spacing={5}>
            {forecast?.nextMonths?.map((item) => (
              <Grid key={item.month} item xs={6} sm={4} md={3} lg={3}>
                <Paper p={2} mt={1} className="paper-rounded">
                  <Box display="flex" alignItems="center">
                    <Box flexGrow={1}>
                      <Typography variant="h5" style={{ ...fontStyle.xLarge }}>
                        {item?.month}
                      </Typography>
                    </Box>
                    <Box>
                      <Box display="flex">
                        <Typography variant="h5" style={{ ...fontStyle.large }}>
                          Anticipated:&nbsp;
                        </Typography>
                        <Typography variant="h5" style={{ ...fontStyle.large, color: primary }}>
                          {t('prefix')}
                          {currency(item?.anticipated || 0)}
                        </Typography>
                      </Box>
                      <Box display="flex">
                        <Typography variant="h5" style={{ ...fontStyle.large }}>
                          Gyms:&nbsp;
                        </Typography>
                        <Typography variant="h5" style={{ ...fontStyle.large, color: primary }}>
                          {item?.gyms}
                        </Typography>
                      </Box>

                      <Typography
                        align="center"
                        mt={2}
                        variant="h5"
                        style={{ ...fontStyle.xxLarge, color: primary }}
                      >
                        {t('prefix')}
                        {currency(item?.total)}
                      </Typography>
                    </Box>
                  </Box>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

const MonthData = ({ monthData }) => (
  <Paper p={2}>
    <Typography variant="h5" align="center" mt={3}>
      Current Month
    </Typography>
    <Table>
      <TableBody>
        <>
          <TableRow>
            <TableCell>Plans and Competitions</TableCell>
            <TableCell>{currency(monthData?.streamPlansTotal)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Next Month Plans Payments</TableCell>
            <TableCell>{currency(monthData?.nextPaymentMonth)}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Programs</TableCell>
            <TableCell>{currency(monthData?.pProgramsFeesTotal)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Store</TableCell>
            <TableCell>{currency(monthData?.storeFeesTotal)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Others</TableCell>
            <TableCell>{currency(monthData?.othersFeesTotal)}</TableCell>
          </TableRow>
        </>

        <TableRow>
          <TableCell>
            <Typography variant="h5" style={{ ...fontStyle.xxLarge }}>
              Total
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="h5" style={{ ...fontStyle.xxLarge, color: primary }}>
              {currency(monthData?.total)}
            </Typography>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </Paper>
);

Forecast.propTypes = {};

Forecast.defaultProps = {};

export default Forecast;
