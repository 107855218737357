/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import SearchChosen from 'core/ui/SearchChosen';
import { useSelector, useDispatch } from 'react-redux';
import { searchSelectors, searchActions } from 'modules/search';
import { Hidden } from '@material-ui/core';

export default ({ channelId, model, hideFilters }) => {
  const { t } = useTranslation();
  const queryData = useSelector((state) => searchSelectors.getQueryData(state));
  const dispatch = useDispatch();

  return (
    <Box>
      <Hidden only={['md', 'lg', 'xl']}>
        <SearchChosen
          limit={10}
          placeholder={t(`search.${model.toLowerCase()}.placeholder.mobile`)}
          value={queryData}
          setValue={(v) => dispatch(searchActions.setQueryData(v))}
          model={model}
          hideFilters={hideFilters}
          channelId={channelId}
        />
      </Hidden>
      <Hidden only={['xs', 'sm']}>
        <SearchChosen
          limit={10}
          placeholder={t(`search.${model.toLowerCase()}.placeholder`)}
          val={queryData}
          setValue={(v) => dispatch(searchActions.setQueryData(v))}
          model={model}
          hideFilters={hideFilters}
          channelId={channelId}
        />
      </Hidden>
    </Box>
  );
};
