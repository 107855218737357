import React from 'react';
import WorkoutScores from 'components/Workout/Show/Tabs/Overview/Leaderboard/WorkoutScores';
import Box from 'core/ui/Box';
import { primary } from 'core/ui/Colors';
import Typography, { fontStyle } from 'core/ui/Typography';
import { ordinalNumber } from 'core/utils/formatters/date';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Divider from 'core/ui/Divider';

const WorkoutScoresPoints = ({
  sectionScores,
  scores,
  positions,
  showVariation,
  showNotes,
  fontValue,
  fontScore,
  showFullNotes,
  buttonColor,
}) => {
  const { t } = useTranslation();
  const useStyles = makeStyles(() => ({
    boxVariation: {
      backgroundColor: buttonColor || primary,
      padding: 2,
      borderRadius: 5,
      minWidth: 60,
      display: 'flex',
      justifyContent: 'center',
      height: 20,
      fontSize: 10,
      maxWidth: 50,
      color: 'white',
    },
  }));
  const classes = useStyles();
  const formatPosititons = (scorePositions, index, kind) => {
    if (scorePositions && scorePositions[index]) {
      if (kind === 'position') {
        return `${ordinalNumber(scorePositions[index].position)}`;
      }
      return `${scorePositions[index].points} ${
        scorePositions[index].points > 1 ? 'points' : 'point'
      }`;
    }
    return '';
  };

  return (
    <>
      {sectionScores &&
        sectionScores.map((sectionScore, idx) => {
          const itemScore = scores[idx];

          return (
            <Box key={idx.toString()}>
              {positions && scores && scores.length > 1 && (
                <Typography className="roboto-regular" color="secondary" style={fontStyle.medium}>
                  <>
                    <b>
                      Score {idx + 1}
                      {' - '}
                      {formatPosititons(positions, idx, 'position')}
                    </b>
                    &nbsp;&nbsp;
                  </>
                  {formatPosititons(positions, idx, 'points')}
                </Typography>
              )}
              <Box display="flex" className="gap-5">
                <Box flexGrow={1}>
                  <WorkoutScores
                    sectionScore={sectionScore}
                    workoutScores={itemScore}
                    fontValue={fontValue}
                    fontScore={fontScore}
                    showNotes={showNotes}
                    showFullNotes={showFullNotes}
                  />
                </Box>
                <Box mx={2} display="flex" justifyContent="center">
                  {showVariation && (
                    <Typography mb={1} className={classes.boxVariation}>
                      {itemScore?.variationName ||
                        t(`workout.section.label.score.${itemScore?.variation || 'prescribed'}`)}
                    </Typography>
                  )}
                </Box>
              </Box>
              {sectionScores.length - 1 > idx && <Divider mb={2} mr={3} />}
            </Box>
          );
        })}
    </>
  );
};

WorkoutScoresPoints.defaultProps = {
  fontValue: fontStyle.medium,
  fontScore: fontStyle.medium,
};

export default WorkoutScoresPoints;
