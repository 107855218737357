import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import * as selectors from './selectors';

/* ---------- Action Creators and Types ---------- */
const { Types, Creators } = createActions(
  {
    setLoading: ['value'],
    setCurrentRoute: ['route'],
  },
  { prefix: 'app/' },
);

/* ---------- Initial State ---------- */
const INITIAL_STATE = Immutable({
  loading: false,
  currentRoute: {},
});

/* ---------- Reducers ---------- */
const setLoadingReducer = (state, { value }) =>
  state.merge({
    loading: value,
  });
const setCurrentRouteReducer = (state, { route }) =>
  state.merge({
    currentRoute: route,
  });

/* ---------- Main Reducer ---------- */
const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_LOADING]: setLoadingReducer,
  [Types.SET_CURRENT_ROUTE]: setCurrentRouteReducer,
});

/* ---------- Exporting ---------- */
export const appActions = Creators;
export const appTypes = Types;
export const appSelectors = selectors;

export default reducer;
