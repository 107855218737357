import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import { Popper, ClickAwayListener, InputBase } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { formatNumberTime } from 'core/utils/helpers';
import Typography, { fontStyle } from '../Typography';
import Box from '../Box';
import TextFieldRounded from '../TextFieldRounded';
import Grid from '../Grid';
import { secondaryLight } from '../Colors';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    overflowY: 'auto',
    borderRadius: 5,
  },
  boxPopper: {
    background: '#fff',
    backgroundRepeat: 'repeat',
    borderRadius: 5,
    zIndex: 1000,
    padding: 5,
    minWidth: 100,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontSize: 12,
  },
}));

export default function TimeSelector({
  label,
  error,
  setTime,
  time,
  hideHour,
  disabled,
  bordered,
  milliseconds,
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const paperRef = useRef();
  const { t } = useTranslation();
  const styleInput = error ? {} : { border: bordered ? `1px solid${secondaryLight}` : 'none' };

  useEffect(() => {
    if (!time) {
      return;
    }
    if (time.hours > 23 || time.hours < 0) {
      setTime((prev) => ({ ...prev, hours: 0 }));
    }
    if (time.minutes > 59 || time.minutes < 0) {
      setTime((prev) => ({ ...prev, minutes: 0 }));
    }
    if (time.seconds > 59 || time.seconds < 0) {
      setTime((prev) => ({ ...prev, seconds: 0 }));
    }
    if (milliseconds) {
      if (time.milliseconds > 1000 || time.milliseconds < 0) {
        setTime((prev) => ({ ...prev, milliseconds: 0 }));
      }
    }
  }, [time, setTime, milliseconds]);

  const formatValue = (tm) => {
    if (!tm) {
      return '';
    }
    let value = `${hideHour ? '' : `${formatNumberTime(tm.hours)}:`}${formatNumberTime(
      tm.minutes,
    )}:${formatNumberTime(tm.seconds)}`;
    if (milliseconds) {
      value = `${value}:${tm.milliseconds || 0}`;
    }
    return value;
  };

  const setTimeValue = (input, v) => {
    const tm = { ...time };
    setTime({ ...tm, [input]: v, value: formatValue({ ...tm, [input]: v }) });
  };

  return (
    <>
      {label !== '' && (
        <Box ml={2} mb={2}>
          <Typography style={fontStyle.label} variant="body1">
            {label}
          </Typography>
        </Box>
      )}
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <Box style={{ zIndex: 1000 }}>
          <Paper
            ref={paperRef}
            onClick={() => (!disabled ? setOpen(true) : null)}
            className={`${error ? 'input-paper-error' : ''} ${classes.root}`}
            style={styleInput}
          >
            <InputBase
              value={formatValue(time)}
              onFocus={() => (!disabled ? setOpen(true) : null)}
              className={classes.input}
              style={{ color: !disabled ? '#000' : '#eee' }}
            />
          </Paper>

          <Popper
            open={open}
            anchorEl={paperRef.current}
            placement="bottom-start"
            role={undefined}
            transition
            onClose={() => setOpen(false)}
            disablePortal
            style={{ zIndex: 9999 }}
          >
            <Box className={classes.boxPopper}>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  {!hideHour && (
                    <Box mb={3}>
                      <Typography ml={3} mb={1} style={fontStyle.xSmall}>
                        {t('hours').toUpperCase()}
                      </Typography>
                      <TextFieldRounded
                        type="number"
                        pattern="[0-9]*"
                        placeholder={t('hours')}
                        bordered
                        value={time?.hours || ''}
                        inputProps={{ min: 0, max: 23 }}
                        onChange={(v) => setTimeValue('hours', v)}
                      />
                    </Box>
                  )}
                  <Box mb={3}>
                    <Typography ml={3} mb={1} style={fontStyle.xSmall}>
                      {t('minutes').toUpperCase()}
                    </Typography>
                    <TextFieldRounded
                      type="number"
                      pattern="[0-9]*"
                      placeholder={t('minutes')}
                      bordered
                      value={time?.minutes || ''}
                      inputProps={{ min: 0, max: 59 }}
                      onChange={(v) => setTimeValue('minutes', v)}
                    />
                  </Box>
                  <Box mb={3}>
                    <Typography ml={3} mb={1} style={fontStyle.xSmall}>
                      {t('seconds').toUpperCase()}
                    </Typography>
                    <TextFieldRounded
                      type="number"
                      pattern="[0-9]*"
                      placeholder={t('seconds')}
                      bordered
                      value={time?.seconds || ''}
                      inputProps={{ min: 0, max: 59 }}
                      onChange={(v) => setTimeValue('seconds', v)}
                    />
                  </Box>
                  {milliseconds && (
                    <Box mb={3}>
                      <Typography ml={3} mb={1} style={fontStyle.xSmall}>
                        {t('milliseconds').toUpperCase()}
                      </Typography>
                      <TextFieldRounded
                        type="number"
                        pattern="[0-9]*"
                        placeholder={t('milliseconds')}
                        bordered
                        value={time?.milliseconds || ''}
                        inputProps={{ min: 0, max: 1000 }}
                        onChange={(v) => setTimeValue('milliseconds', v)}
                      />
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Popper>
        </Box>
      </ClickAwayListener>
    </>
  );
}

TimeSelector.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  error: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  time: PropTypes.object,
  setTime: PropTypes.func,
  label: PropTypes.string,
  disabled: PropTypes.bool,
};

TimeSelector.defaultProps = {
  error: null,
  label: '',
  time: {},
  setTime: () => {},
  disabled: false,
};
