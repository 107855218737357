import React, { useEffect, useState } from 'react';
import { sessionSelectors } from 'modules/session';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import StreamFitnessSplash from 'core/ui/StreamFitnessSplash';
import ModalChannels from 'components/Profile/Dashboard/Channels/Modal';

const CurrentGymRedirect = () => {
  const user = useSelector((state) => sessionSelectors.getUser(state));
  const { tab } = useParams();
  const [modalChannels, setModalChannels] = useState(false);

  useEffect(() => {
    if (user.hasChannel) {
      window.location = `/channel/manage/${user.manageChannelId}/${tab}`;
    } else {
      setModalChannels(false);
    }

    if (user?.id && !user.manageChannelId) {
      setModalChannels(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <>
      <ModalChannels defaultTab={tab} open={modalChannels} setOpen={setModalChannels} sidebar />
      <StreamFitnessSplash />
    </>
  );
};

export default CurrentGymRedirect;
