/* eslint-disable react/prop-types */
import Box from 'core/ui/Box';
import React from 'react';

import { useTranslation } from 'react-i18next';
import ByCustomPaymentsData from './ByCustomPaymentsData';

export default function ByCustomPayments({ channel, byCustomPayments }) {
  const { t } = useTranslation();
  return (
    <Box>
      <ByCustomPaymentsData
        title={t('channel.coaches.customPayments')}
        channel={channel}
        byCustomPayments={byCustomPayments}
      />
    </Box>
  );
}
