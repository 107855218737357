import React, { useState } from 'react';
import propTypes from 'prop-types';
import { Link } from '@material-ui/core';
import Typography from '../Typography';
import LabelBreakLine from '../LabelBreakLine';
import { primary } from '../Colors';

export default function CollapseText({ text, maxChar, linkColor, variant }) {
  const [showAlltext, setShowAllText] = useState();

  const workoutDescription = () => {
    if (text && text.length > maxChar) {
      return `${text.substring(0, maxChar)}...`;
    }

    return text;
  };

  return (
    <Typography className="roboto-regular" color="secondary" variant={variant} component="div">
      {!showAlltext && <LabelBreakLine>{workoutDescription()}</LabelBreakLine>}

      {showAlltext && <LabelBreakLine>{text}</LabelBreakLine>}

      {text && text.length > maxChar && (
        <Link
          style={{ color: linkColor }}
          className="link-hover"
          onClick={() => setShowAllText(!showAlltext)}
        >
          {!showAlltext ? 'more' : 'less'}
        </Link>
      )}
    </Typography>
  );
}

CollapseText.propTypes = {
  text: propTypes.string,
  linkColor: propTypes.string,
  variant: propTypes.string,
  maxChar: propTypes.number,
};

CollapseText.defaultProps = {
  text: '',
  variant: 'caption',
  maxChar: 100,
  linkColor: primary,
};
