import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { ButtonPrimary } from 'core/ui/Button';
import Typography, { fontStyle } from 'core/ui/Typography';
import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Dialog,
  Checkbox,
} from '@material-ui/core';
import ModalAddPaymentMethod from 'components/Profile/Dashboard/Account/ModalAddPaymentMethod';
import LoaderSm from 'core/ui/LoaderSm';
import { streamCosts } from 'core/utils/consts';
import { sessionSelectors } from 'modules/session';
import { useDispatch, useSelector } from 'react-redux';
import { channelsActions, channelsSelectors } from 'modules/channels';
import { primary } from 'core/ui/Colors';
import { WorkoutContext } from '../index';
import OnlineCost from './OnlineCost';

import VideoLink from './VideoLink';

export default () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const formContext = useContext(WorkoutContext);
  const userSession = useSelector((state) => sessionSelectors.getUser(state));
  const [openModalAddPaymentMethod, setOpenModalAddPaymentMethod] = useState(false);
  const loadingValidateCard = useSelector((state) =>
    channelsSelectors.getLoadingValidationCard(state),
  );
  const [modalFree, setModalFree] = useState(false);
  const openModalFree = () => {
    setModalFree(true);
  };

  const [checked, setChecked] = useState(false);

  const validateCardOwnerChannel = () => {
    if (userSession.id === formContext.selectedChannel.user.id) {
      dispatch(
        channelsActions.validateCardOwnerChannelStart({
          channelId: formContext.selectedChannel.id,
          callback: (ret) => {
            if (ret === false) {
              setOpenModalAddPaymentMethod(true);
            }
          },
        }),
      );
    }
  };

  const changeAccept = (ch) => {
    if (ch) {
      validateCardOwnerChannel();
    }
  };

  const ModalConfirm = () => (
    <Dialog open={modalFree} maxWidth="sm" onClose={() => setModalFree(false)}>
      <Box p={5} display="flex" flexDirection="column" alignItems="center">
        {userSession && userSession.id && (
          <ModalAddPaymentMethod
            open={openModalAddPaymentMethod}
            setOpen={setOpenModalAddPaymentMethod}
            allowClose={false}
            userId={userSession.id}
            onClose={() => {
              setOpenModalAddPaymentMethod(false);
              setChecked(false);
            }}
            textHeader={t('workout.addPaymentMethodMessage')}
            userInfo={{
              name: userSession?.name,
              email: userSession?.email,
              phone: userSession?.phone,
            }}
            cardpointe={userSession?.gymConfig?.useCardpointe}
          />
        )}
        <Typography>
          {t('workout.free.costs')
            .replace(
              '{FREE_CLASS_COST_FIRST_20}',
              t('prefix') + streamCosts.FREE_CLASS_COST_FIRST_20.toFixed(2),
            )
            .replace(
              '{FREE_CLASS_COST_20_50}',
              t('prefix') + streamCosts.FREE_CLASS_COST_20_50.toFixed(2),
            )
            .replace(
              '{FREE_CLASS_COST_50}',
              t('prefix') + streamCosts.FREE_CLASS_COST_50.toFixed(2),
            )}
        </Typography>
        <Box
          my={3}
          display="flex"
          onClick={() => {
            changeAccept(!checked);
            setChecked(!checked);
          }}
          className="link-hover"
          alignItems="center"
        >
          <Checkbox checked={checked} />
          <Typography>{t('workout.free.accept')}</Typography>
        </Box>
        <LoaderSm loading={loadingValidateCard} />
        <Box display="flex">
          <Box mx={1}>
            <ButtonPrimary
              style={{ width: '100%' }}
              onClick={() => {
                setChecked(false);
                setModalFree(false);
              }}
            >
              {t('button.noThanks')}
            </ButtonPrimary>
          </Box>
          <Box mx={1}>
            <ButtonPrimary
              type="button"
              style={{ width: '100%' }}
              onClick={() => {
                setModalFree(false);
                formContext.setValue('freeOption', 'everyone');
              }}
              disabled={!checked || loadingValidateCard}
            >
              {t('button.Iaccept')}
            </ButtonPrimary>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );

  return (
    <>
      <Box py={3}>
        <Typography mb={3} variant="h5" color="primary">
          {t('workout.setting.online')}
        </Typography>
        <Box display="flex">
          <Box>
            <FormControl component="fieldset">
              <Typography style={fontStyle.label} variant="body1">
                {t('workout.label.method').toUpperCase()}
              </Typography>
              <RadioGroup row>
                <FormControlLabel
                  onChange={() => formContext.setValue('method', 'live')}
                  checked={formContext.watch('method') === 'live' || !formContext.watch('method')}
                  control={<Radio color="primary" />}
                  label={
                    <Typography style={fontStyle.small} variant="caption" color="textSecondary">
                      {t(`workout.label.method.live`).toUpperCase()}
                    </Typography>
                  }
                />
                <FormControlLabel
                  onChange={() => formContext.setValue('method', 'pre_recorded')}
                  checked={formContext.watch('method') === 'pre_recorded'}
                  control={<Radio color="primary" />}
                  label={
                    <Typography style={fontStyle.small} variant="caption" color="textSecondary">
                      {t(`workout.label.method.pre_recorded`).toUpperCase()}
                    </Typography>
                  }
                />
                <FormControlLabel
                  onChange={() => formContext.setValue('method', 'video')}
                  checked={formContext.watch('method') === 'video'}
                  control={<Radio color="primary" />}
                  label={
                    <Typography style={fontStyle.small} variant="caption" color="textSecondary">
                      {t(`workout.label.method.video`).toUpperCase()}
                    </Typography>
                  }
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
        {formContext.selectedChannel && formContext.selectedChannel.planValid && (
          <Box
            my={3}
            ml={-1}
            display="flex"
            alignItems="center"
            className="link"
            onClick={() =>
              formContext.watch('freeOption') === 'no'
                ? openModalFree()
                : formContext.setValue('freeOption', 'no')
            }
          >
            {formContext.watch('freeOption') === 'everyone' ? (
              <CheckBoxIcon style={{ color: primary }} />
            ) : (
              <CheckBoxOutlineBlankIcon style={{}} />
            )}
            <Typography ml={1} color="secondary">
              {t('workout.label.makeFree')}
            </Typography>
          </Box>
        )}
        <Box display="flex" flexWrap="wrap">
          <OnlineCost watch={formContext.watch} setValue={formContext.setValue} />
        </Box>

        <Box display="flex" alignItems="flex-start" flexWrap="wrap">
          {formContext.watch('method') === 'video' && (
            <Box>
              <VideoLink />
            </Box>
          )}
        </Box>
      </Box>

      <ModalConfirm />
    </>
  );
};
