import React, { useState } from 'react';
import Box from 'core/ui/Box';
import { copyToClipBoard } from 'core/utils/helpers';
import { Check, FileCopy } from '@material-ui/icons';

const CopyText = ({ text, fontSize }) => {
  const [copied, setCopied] = useState();

  const copy = () => {
    copyToClipBoard(text);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <Box onClick={() => copy()} className="link-hover">
      {copied ? (
        <Check color="secondary" size="small" style={{ fontSize: fontSize || 10 }} />
      ) : (
        <FileCopy color="secondary" size="small" style={{ fontSize: fontSize || 10 }} />
      )}
    </Box>
  );
};

export default CopyText;
