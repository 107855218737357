import React, { useContext } from 'react';
import Box from 'core/ui/Box';
import { workoutInviteTypes } from 'core/utils/consts';
import CustomRecordinds from './Recordings';
import { ShowContext } from '..';
import Tabs from './Tabs';
import CustomOverview from './Overview';
import CustomInvites from './Invites';

const CustomWorkoutView = ({ workout }) => {
  const showContext = useContext(ShowContext);
  const isCompareVideos = !!workout?.invites.find(
    (x) => x.inviteType === workoutInviteTypes.record_compare,
  );
  const showRecordings = workout && (workout.recordingVideos?.length > 0 || isCompareVideos);

  return (
    <Box>
      <Box mb={3}>
        <Tabs showRecordings={showRecordings} workout={workout} />
      </Box>
      <>
        {showContext.tab === 'overview' && (
          <CustomOverview workout={workout} isCompareVideos={isCompareVideos} />
        )}
      </>
      <>{showContext.tab === 'invites' && <CustomInvites workout={workout} />}</>
      <>
        {showContext.tab === 'recordings' && (
          <CustomRecordinds workout={workout} competition={showContext.competition} />
        )}
      </>
    </Box>
  );
};

export default CustomWorkoutView;
