import React, { useState } from 'react';

import { Dialog } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Texture from 'core/ui/Texture';
import Box from 'core/ui/Box';
import Typography from 'core/ui/Typography';
import { ButtonPrimary } from 'core/ui/Button';
import { useHistory } from 'react-router';
import CustomLiveInvite from './LiveInvite';
import ShareWorkoutScores from './ShareWorkoutScores';
import RecordCompare from './RecordCompare';

export default function ModalInvite({ open, close, callback, workout, isOwner }) {
  const [option, setOption] = useState();
  const { t } = useTranslation();
  const history = useHistory();
  const goToWorkout = (w) => {
    history.push(`/workout/view/${w?.id}`);
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth onClose={close} scroll="body">
      <Texture>
        <Box p={5}>
          {option === 'liveInvite' && (
            <Box>
              <CustomLiveInvite
                workout={workout}
                close={close}
                callback={() => {
                  if (callback) {
                    callback();
                  }
                  close();
                }}
                isOwner={isOwner}
                goToWorkout={goToWorkout}
              />
            </Box>
          )}
          {option === 'shareWorkoutScores' && (
            <Box>
              <ShareWorkoutScores
                workout={workout}
                close={close}
                callback={() => {
                  if (callback) {
                    callback();
                  }
                  close();
                }}
                isOwner={isOwner}
                goToWorkout={goToWorkout}
              />
            </Box>
          )}
          {option === 'recordCompare' && (
            <Box>
              <RecordCompare
                workout={workout}
                close={close}
                callback={() => {
                  if (callback) {
                    callback();
                  }
                  close();
                }}
                isOwner={isOwner}
                goToWorkout={goToWorkout}
              />
            </Box>
          )}

          {!option && (
            <>
              <Typography mb={3} variant="h5" align="center" color="primary">
                {t('modal.title.invite')}
              </Typography>
              <Box mb={1}>
                <ButtonPrimary fullWidth onClick={() => setOption('liveInvite')}>
                  {t('button.workoutInviteLive')}
                </ButtonPrimary>
              </Box>

              <Box mb={1}>
                <ButtonPrimary fullWidth onClick={() => setOption('recordCompare')}>
                  {t('button.recordAndCompare')}
                </ButtonPrimary>
              </Box>

              <Box mb={1}>
                <ButtonPrimary fullWidth onClick={() => setOption('shareWorkoutScores')}>
                  {t('button.shareWorkoutScores')}
                </ButtonPrimary>
              </Box>
            </>
          )}
        </Box>
      </Texture>
    </Dialog>
  );
}
