import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Link,
} from '@material-ui/core';
import ScheduleIcon from '@material-ui/icons/Schedule';
import Box from 'core/ui/Box';
import { useDispatch, useSelector } from 'react-redux';
import { channelsSelectors } from 'modules/channels';
import Typography from 'core/ui/Typography';
import { dateExt, time } from 'core/utils/formatters';
import { ButtonPrimarySm } from 'core/ui/Button';
import ConfirmDialog from 'core/ui/ConfirmDialog';
import { recurringsActions } from 'modules/recurrings';
import FontIcon from 'core/ui/FontIcon';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import notifications from 'modules/notifications';
import useSelectors from 'modules/map/useSelectors';
import { newRecurringsModule } from 'modules/recurrings/new';
import LoaderSm from 'core/ui/LoaderSm';
import { redColor } from 'core/ui/Colors';

const WorkoutsRecurring = ({ recurring, setRefreshCalendar }) => {
  const { t } = useTranslation();
  const { request } = useActions();
  const loading = useSelectors(newRecurringsModule, 'loading');
  const [workouts, setWorkouts] = useState([]);
  const [pagination, setPagination] = useState({ totalPages: 0 });
  const dispatch = useDispatch();
  const channel = useSelector((state) => channelsSelectors.getChannel(state));
  const [dialogCancel, setDialogCancel] = useState({
    open: false,
  });

  const getRecurringWorkouts = (page = 1) => {
    request({
      action: Modules.newRecurrings.actions.getRecurringWorkouts,
      data: {
        recurringId: recurring?.id,
        page,
        perPage: 5,
      },
      options: {
        onSuccess: (data, pag) => {
          setPagination(pag);
          if (pag?.currentPage === 1) {
            setWorkouts(data);
          } else {
            setWorkouts((prev) => [...prev, ...data]);
          }
        },
      },
    });
  };

  useEffect(() => {
    getRecurringWorkouts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recurring]);

  const confirmCancelClass = (workoutId) => {
    request({
      action: Modules.newWorkouts.actions.cancelClass,
      data: { id: workoutId },
      options: {
        onSuccess: () => {
          dispatch(notifications.success(t('workout.message.cancel')));
          if (setRefreshCalendar) {
            setRefreshCalendar(true);
          }
          dispatch(recurringsActions.getRecurringsByChannelStart(channel.id));
        },
      },
    });
  };

  const cancelClass = (workoutId) => {
    setDialogCancel({
      open: true,
      callback: () => {
        confirmCancelClass(workoutId);
      },
    });
  };

  return (
    <Box mt={2}>
      <ConfirmDialog
        open={dialogCancel.open}
        setOpen={(v) => setDialogCancel((prev) => ({ ...prev, open: v }))}
        description={t('workout.confirm.cancel')}
        labelConfirm={t('confirm')}
        labelCancel={t('cancel')}
        confirm={dialogCancel.callback}
      />
      <Box display="flex" alignItems="center" justifyContent="center">
        <FontIcon iconName="icon-live" size={15} />
        <Typography ml={1} variant="h6" my={1}>
          {t('profile.upcomingTitle')}
        </Typography>
      </Box>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>{t('workout.column.name')}</TableCell>
              <TableCell>{t('workout.column.scheduled')}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {workouts &&
              workouts.map((w, index) => (
                <TableRow key={index.toString()}>
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      <Typography mr={1}>{w.name}</Typography>
                      <Link target="_blank" href={`/workout/view/${w.slug}`}>
                        <OpenInNewIcon style={{ fontSize: 15 }} />
                      </Link>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      <ScheduleIcon style={{ fontSize: 18 }} />
                      <Typography color="primary" ml={1} component="span" variant="body1">
                        <span>
                          {dateExt(
                            w.method === 'live' ? w.scheduledAt : w.publishAt,
                            t,
                            channel?.dateFormatExtReact,
                          )}
                        </span>
                        <span>&nbsp;&bull;&nbsp;</span>
                        <span className="roboto-regular">
                          {time(w.method === 'live' ? w.scheduledAt : w.publishAt, w.timezone)}
                        </span>
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell align="center">
                    {!w.started && (
                      <ButtonPrimarySm buttonColor={redColor} onClick={() => cancelClass(w.id)}>
                        {t('button.cancelClass')}
                      </ButtonPrimarySm>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box flexGrow={1} m={3} display="flex" justifyContent="center">
        <LoaderSm loading={loading} />

        {pagination && pagination?.currentPage < pagination?.totalPages > 0 && (
          <Box>
            <Typography
              color="primary"
              className="hover"
              onClick={() => getRecurringWorkouts(pagination?.currentPage + 1)}
            >
              {t('button.seeMore')}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

WorkoutsRecurring.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  recurring: PropTypes.object.isRequired,
};

WorkoutsRecurring.defaultProps = {};

export default WorkoutsRecurring;
