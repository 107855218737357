import Reports from 'pages/Admin/Reports';

export default [
  {
    path: '/admin/reports',
    exact: true,
    name: 'adminReports',
    component: Reports,
  },
];
