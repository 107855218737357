import { createSelector } from 'reselect';

const getState = (state) => state.session;

export const getLoadingStart = createSelector(getState, (state) => !!state.loadingStart);
export const getLoading = createSelector(getState, (state) => !!state.loading);

export const getError = createSelector(getState, (state) => state.error);

export const getIsAuthenticated = createSelector(getState, (state) => !!state.authenticated);
export const getToken = createSelector(getState, (state) => state.token);

export const getUser = createSelector(getState, (state) => state.currentUser || {});

export const getIsAdmin = createSelector(getUser, (user) => user.role === 'admin');
export const getIsSupport = createSelector(getUser, (user) => user.role === 'support');

export const getIsCoach = createSelector(
  getUser,
  (user) => user.role === 'coach' || user.role === 'admin' || user.role === 'support',
);

export const getIsAdminOrManager = createSelector(
  getUser,
  (user) => user.role === 'manager' || user.role === 'admin' || user.role === 'support',
);
export const getIsManager = createSelector(getUser, (user) => user.role === 'manager');

export const getUserEmail = createSelector(getUser, (user) => user && user.email);
