import React, { createContext, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Box from 'core/ui/Box';
import { useSelector, useDispatch } from 'react-redux';
import { Container } from '@material-ui/core';
import { sessionSelectors } from 'modules/session';
import { usersSelectors } from 'modules/users';
import Header from 'components/Profile/Edit/Header';
import FormAthlete from 'components/Profile/Edit/Athlete/Form';
import FormCoach from 'components/Profile/Edit/Coach/Form';
import Grid from 'core/ui/Grid';
import MessageDialog from 'components/Profile/Edit/MessageDialog';
import MusicAccounts from 'components/Profile/Edit/MusicAccounts';
import { parse } from 'query-string';
import PopUpValidation from 'components/Profile/Edit/PopUpValidation';
import { LayoutContext } from '../Layout';
import useForm from './useForm';

// SPOTIFY CALLBACK AUTORIZATION
const hash = parse(window.location.search);
if (hash.code && window.opener) {
  window.opener.spotifyCallback(hash.code);
}

export const ProfileContext = createContext();

const useStyles = makeStyles(() => ({
  page: {
    backgroundColor: '#f3f3f3',
    minHeight: '100vh',
  },
  pageContent: {
    minHeight: '100vh',
    width: '100%',
    botton: 0,
    left: 0,
    right: 0,
  },
  dataHeader: {
    zIndex: 1000,
  },
  header: {
    minHeight: 200,
  },
}));

export default () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [openMessage, setOpenMessage] = useState(false);
  const user = useSelector((state) => sessionSelectors.getUser(state));
  const loadingUser = useSelector((state) => usersSelectors.getLoading(state));
  const profile = useSelector((state) => sessionSelectors.getUser(state));
  const isRoleCoach = useSelector((state) => sessionSelectors.getIsCoach(state));

  const form = useForm();

  useEffect(() => {
    if (profile.firstAccess === true) {
      setOpenMessage(true);
    }
  }, [dispatch, profile, t]);

  const layoutContext = useContext(LayoutContext);
  useEffect(() => {
    layoutContext.setTitleBar(t('title.account').toUpperCase());
    layoutContext.setActiveMenu('profile');
  }, [layoutContext, t]);

  return (
    <div className="gradient-background">
      <div className={classes.page}>
        <PopUpValidation
          setOpen={(v) => form.setValidation((prev) => ({ ...prev, open: v }))}
          open={form.validation.open}
          errors={form.validation.errors}
        />
        <Container maxWidth="xl">
          <form
            onSubmit={form.handleSubmit(form.onSubmit)}
            className={classes.form}
            autoComplete="off"
          >
            <ProfileContext.Provider
              value={{
                profile,
                ...form,
              }}
            >
              <Box className={classes.header}>
                <Grid container>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Header
                      errors={form.errors}
                      loading={loadingUser}
                      setValue={form.setValue}
                      watch={form.watch}
                      image={form.image}
                      setImage={form.setImage}
                      user={user}
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box mt={3}>
                <FormAthlete
                  errors={form.errors}
                  loading={form.loadingSk}
                  setValue={form.setValue}
                  setIsPhoneValid={form.setIsPhoneValid}
                  register={form.register}
                  watch={form.watch}
                  profile={profile}
                />
              </Box>

              <Box mt={3} pb={5}>
                <MusicAccounts />
              </Box>

              {isRoleCoach && (
                <Box my={3}>
                  <FormCoach />
                </Box>
              )}

              <MessageDialog
                setOpen={setOpenMessage}
                open={openMessage}
                message={t('profile.message.newCoach')}
              />
            </ProfileContext.Provider>
          </form>
        </Container>
      </div>
    </div>
  );
};
