import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  TableContainer,
  TableHead,
  Table,
  TableCell,
  TableRow,
  TableBody,
  Paper,
  IconButton,
} from '@material-ui/core';
import MIcon from 'core/ui/MIcon';
import Box from 'core/ui/Box';
import Typography from 'core/ui/Typography';
import { toUpper } from 'ramda';
import { ButtonPrimarySm } from 'core/ui/Button';

const TemplatesTable = ({ templates, setModalTemplate, setTemplate }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>{t('channel.template.name')}</TableCell>
              <TableCell align="center">{t('channel.template.type')}</TableCell>
              <TableCell align="center">{t('channel.template.inPerson')}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {templates &&
              templates.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>
                    <Typography color="secondary">{row.name}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography color="primary">
                      {row.online && <>{toUpper(row.method)}</>}
                      {row.inPerson && <>IN-PERSON</>}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography color="primary">{row.inPerson ? 'Yes' : 'No'}</Typography>
                  </TableCell>
                  <TableCell>
                    {setModalTemplate && (
                      <IconButton
                        onClick={() =>
                          setModalTemplate({ open: true, method: 'edit', workoutId: row.id })
                        }
                      >
                        <MIcon icon="edit" />
                      </IconButton>
                    )}
                    {setTemplate && (
                      <ButtonPrimarySm>
                        <Typography onClick={() => setTemplate(row)}>
                          {t('button.select')}
                        </Typography>
                      </ButtonPrimarySm>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default TemplatesTable;
