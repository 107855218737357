import Modules from 'modules';
import useActions from 'modules/map/useActions';
import { useCallback, useState } from 'react';

function useLikes() {
  const { request } = useActions();
  const [likes, setLikes] = useState([]);

  const postLike = useCallback(
    ({ likeableId, likeableType }, callback) => {
      request({
        action: Modules.likes.actions.toggleLike,
        data: {
          likeableId,
          likeableType,
        },
        options: {
          onSuccess: () => {
            if (callback) {
              callback();
            }
          },
        },
      });
    },
    [request],
  );

  const like = useCallback(
    ({ likeableId, likeableType }, callback) => {
      request({
        action: Modules.likes.actions.toggleLike,
        data: {
          likeableId,
          likeableType,
        },
        options: {
          onSuccess: () => {
            if (callback) {
              callback();
            }
          },
        },
      });
    },
    [request],
  );

  const getViewLikes = ({ likeableId, likeableType }) => {
    request({
      action: Modules.likes.actions.getLikes,
      data: {
        likeableId,
        likeableType,
      },
      options: {
        onSuccess: (data) => {
          setLikes(data);
        },
      },
    });
  };

  return {
    postLike,
    getViewLikes,
    setLikes,
    likes,
    like,
  };
}

export default useLikes;
