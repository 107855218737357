import { createSelector } from 'reselect';

const getState = (state) => state.messages;

export const getLoading = createSelector(getState, (state) => !!state.loading);
export const getLoadingStop = createSelector(getState, (state) => !!state.loadingStop);
export const getLoadingSend = createSelector(getState, (state) => !!state.loadingSend);
export const getLoadingReplies = createSelector(getState, (state) => !!state.loadingReplies);
export const getLoadingNumbers = createSelector(getState, (state) => !!state.loadingNumbers);
export const getMessages = createSelector(getState, (state) => state.data);
export const getTwilioNumbers = createSelector(getState, (state) => state.numbers);
export const getMessagesReplies = createSelector(getState, (state) => state.replies);

export const getMessageContacts = createSelector(getState, (state) => state.contacts);
export const getMessageContactsPagination = createSelector(
  getState,
  (state) => state.paginationContacts,
);
export const getLoadingContacts = createSelector(getState, (state) => state.loadingContacts);
export const getStreamCountUsers = createSelector(getState, (state) => state.countUsers);
