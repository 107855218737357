/* eslint-disable no-underscore-dangle */
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form-v5';
import { useDispatch } from 'react-redux';
import notifications from 'modules/notifications';
import useSelectors from 'modules/map/useSelectors';
import { channelsTagsModule } from 'modules/channelsTags';
import useActions from 'modules/map/useActions';
import Modules from 'modules';

export default ({ mode, tagId, callback, channel }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loading = useSelectors(channelsTagsModule, 'loading');
  const tag = useSelectors(channelsTagsModule, 'tag');
  const { request } = useActions();
  const { register, handleSubmit, setValue, watch, reset, errors } = useForm();

  useEffect(() => {
    if (tagId) {
      request({
        action: Modules.channelsTags.actions.getTag,
        data: {
          channelId: channel?.id,
          id: tagId,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagId]);

  const onSubmit = (values) => {
    const data = {
      ...values,

      id: tag?.id,
      channelId: channel.id,
    };

    request({
      action:
        mode === 'new' ? Modules.channelsTags.actions.create : Modules.channelsTags.actions.update,
      data,
      options: {
        onSuccess: () => {
          if (callback) {
            callback(values.name);
          }
          dispatch(notifications.success(t('message.success.save.document')));
        },
        onError: (ex) => {
          dispatch(notifications.error(ex));
        },
      },
    });
  };

  useEffect(() => {
    register('name', { required: t('form.validate.name') });
    register('active');

    if (mode === 'new') {
      setValue('active', true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register]);

  useEffect(() => {
    if (tagId && tag) {
      reset(tag);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagId, tag]);

  return {
    register,
    setValue,
    watch,
    reset,
    errors,
    onSubmit,
    handleSubmit,
    loading,
    tag,
  };
};
