import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

import * as selectors from './selectors';
import * as sagas from './sagas';

/* ---------- Action Creators and Types ---------- */
const { Types, Creators } = createActions(
  {
    authenticateError: ['error'],
    authenticateStart: ['callbackSucess', 'callbackError'],
    authenticateSuccess: ['user'],

    authenticateByJwtError: ['error'],
    authenticateByJwtStart: ['token', 'callbackSuccess', 'callbackError'],
    authenticateByJwtSuccess: ['user'],

    signInError: ['error'],
    signInStart: ['user', 'callback'],
    signInSuccess: [],

    validatePinError: ['error'],
    validatePinStart: ['credentials'],
    validatePinSuccess: ['user'],

    authLogOutError: ['error'],
    authLogOutStart: ['callback'],
    authLogOutSuccess: [],

    changeUserStart: ['userId', 'callback'],
    changeUserSuccess: ['currentUser'],
    changeUserError: ['error'],

    setLoadingStart: ['value'],
  },
  { prefix: 'SESSION/' },
);

/* ---------- Initial State ---------- */
const INITIAL_STATE = Immutable({
  authenticated: false,
  error: null,
  loadingStart: true,
  loading: false,
  loggingOut: false,
  token: null,
  currentUser: {
    email: null,
    error: null,
    isDisabled: false,
    loading: false,
  },
});

/* ---------- Reducers ---------- */
const loadStartReducer = (state) => state.set('loading', true);
const loadLogOutErrorReducer = (state) =>
  state.merge({
    authenticated: false,
    loading: true,
    currentUser: {},
  });

const loadSuccessReducer = (state, action) =>
  state
    .merge({
      authenticated: true,
      loadingStart: false,
      loading: false,
      error: null,
    })
    .setIn(['currentUser'], action.user || INITIAL_STATE.currentUser);

const loadErrorReducer = (state, action) =>
  state.merge({
    authenticated: false,
    loadingStart: false,
    loading: false,
    error: action.error,
  });

const logInSuccessReducer = (state, action) =>
  state.merge({
    authenticated: true,
    loadingStart: false,
    currentUser: state.currentUser.merge(action.user),
    loading: false,
    error: null,
  });

const signInSuccessReducer = (state) =>
  state.merge({
    authenticated: false,
    loading: false,
    error: null,
  });

const setLoadingStartReducer = (state, action) =>
  state.merge({
    loadingStart: action.value,
  });

const changeUserSuccessReducer = (state, { currentUser }) =>
  state.merge({
    authenticated: true,
    currentUser,
    error: null,
    loading: false,
  });

const logOutSuccessReducer = () => INITIAL_STATE.merge({ loadingStart: false });

/* ---------- Main Reducer ---------- */
const reducer = createReducer(INITIAL_STATE, {
  [Types.AUTHENTICATE_START]: loadStartReducer,
  [Types.AUTHENTICATE_SUCCESS]: loadSuccessReducer,
  [Types.AUTHENTICATE_ERROR]: loadErrorReducer,

  [Types.AUTHENTICATE_BY_JWT_START]: loadStartReducer,
  [Types.AUTHENTICATE_BY_JWT_SUCCESS]: loadSuccessReducer,
  [Types.AUTHENTICATE_BY_JWT_ERROR]: loadErrorReducer,

  [Types.SIGN_IN_ERROR]: loadErrorReducer,
  [Types.SIGN_IN_START]: loadStartReducer,
  [Types.SIGN_IN_SUCCESS]: signInSuccessReducer,

  [Types.VALIDATE_PIN_ERROR]: loadErrorReducer,
  [Types.VALIDATE_PIN_START]: loadStartReducer,
  [Types.VALIDATE_PIN_SUCCESS]: logInSuccessReducer,

  [Types.SET_LOADING_START]: setLoadingStartReducer,

  [Types.AUTH_LOG_OUT_START]: loadLogOutErrorReducer,
  [Types.AUTH_LOG_OUT_SUCCESS]: logOutSuccessReducer,
  [Types.AUTH_LOG_OUT_ERROR]: loadErrorReducer,

  [Types.CHANGE_USER_ERROR]: loadErrorReducer,
  [Types.CHANGE_USER_START]: loadStartReducer,
  [Types.CHANGE_USER_SUCCESS]: changeUserSuccessReducer,
});

/* ---------- Exporting ---------- */
export const sessionActions = Creators;
export const sessionTypes = Types;
export const sessionSelectors = selectors;
export const sessionSagas = sagas;

export default reducer;
