import { confirmDialogEmitter } from 'emitters';

const useConfirmation = () => {
  const confirmation = ({
    noClick,
    yesClick,
    title,
    description,
    labelConfirm,
    labelCancel,
    hideNoButton = false,
    ...props
  }) => {
    confirmDialogEmitter.emit('UPDATE_DIALOG_STATUS', {
      title,
      description,
      open: true,
      confirm: yesClick,
      cancel: noClick,
      labelConfirm,
      labelCancel,
      hideNoButton,
      ...props,
    });
  };

  return { confirmation };
};

export default useConfirmation;
