import React from 'react';
import clsx from 'clsx';
import { Tooltip, colors, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Box from 'core/ui/Box';
import MIcon from 'core/ui/MIcon';
import Paper from 'core/ui/Paper';
import Typography, { fontStyle } from 'core/ui/Typography';

export const ActionItem = ({ bgIconColor, icon, iconColor, onClick, label, active, helpText }) => {
  const classes = useStyles();

  return (
    <Paper
      className={clsx(classes.card, active && classes.activeCard, 'hover')}
      elevation={4}
      onClick={onClick}
    >
      {!!helpText && (
        <Box className={classes.boxInfo}>
          <Tooltip title={helpText}>
            <Box>
              <MIcon icon="info" size={20} />
            </Box>
          </Tooltip>
        </Box>
      )}
      <Box display="flex" alignItems="center" className="gap-10">
        <Box className={classes.cardIcon} style={{ backgroundColor: bgIconColor }}>
          <MIcon icon={icon} color={iconColor} />
        </Box>
        <Box>
          <Typography color="secondary" style={fontStyle.label}>
            {label}
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};

export const ActionItemLeft = ({ item, onExecutionsClick }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box className={classes.cardLeftItems}>
      {/* <Box className={classes.relative}>
        <Box
          className={clsx(classes.cardLeftExecCount, 'link-hover')}
          p={1}
          onClick={onExecutionsClick}
          mb={2}
        >
          <Tooltip title={t('tooltip.executions')}>
            <Box display="flex" className="gap-5" alignItems="center">
              <MIcon icon="refresh" />

              <Typography className="roboto-medium" style={fontStyle.label}>
                {item?.executionsCount}
              </Typography>
            </Box>
          </Tooltip>
        </Box>
        <Box className={classes.leftConnectCard} />
      </Box> */}

      <Box className={classes.relative} mt={5}>
        <Box
          className={clsx(classes.cardLeftExecCount, 'link-hover')}
          p={1}
          onClick={onExecutionsClick}
        >
          <Tooltip title={t('tooltip.usersExecutions')}>
            <Box display="flex" className="gap-5" alignItems="center">
              <MIcon icon="people" />

              <Typography className="roboto-medium" style={fontStyle.label}>
                {item?.executionsUsersCount}
              </Typography>
            </Box>
          </Tooltip>
        </Box>
        <Box className={classes.leftConnectCard} />
      </Box>
    </Box>
  );
};

const useStyles = makeStyles({
  relative: {
    position: 'relative',
  },
  card: {
    position: 'relative',
    zIndex: 100,
    padding: 15,
    paddingRight: 20,
    paddingLeft: 20,
    width: 250,
    boxShadow: '0 1px 3px #1018281a',
    borderRadius: 10,
    border: '1px solid white',
  },
  cardIcon: {
    width: 36,
    height: 36,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'red',
    borderRadius: 5,
  },
  cardLeftItems: {
    position: 'absolute',
    top: 0,
    left: -60,
    zIndex: 30,
  },
  cardLeftExecCount: {
    backgroundColor: 'white',
    boxShadow: `0 1px 3px ${colors.grey[200]}`,
    borderRadius: 5,
    width: 55,
    zIndex: 10,
  },
  activeCard: {
    borderColor: colors.grey[400],
  },
  leftConnectCard: {
    width: 30,
    height: 2,
    backgroundColor: colors.grey[300],
    position: 'absolute',
    zIndex: -1,
    right: -20,
    top: 15,
  },
  boxInfo: {
    position: 'absolute',

    right: 5,
    top: 5,
  },
});
