/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  Bar,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
  ComposedChart,
} from 'recharts';
import Paper from 'core/ui/Paper';
import Typography from 'core/ui/Typography';
import { currency } from 'core/utils/formatters';
import ModalChannels from './ModalChannels';

const BarMonths = ({ title, chartData, barColor }) => {
  const [modalChannels, setModalChannels] = useState({ open: false });

  return (
    <>
      <ModalChannels
        channels={modalChannels?.channels || []}
        open={modalChannels?.open}
        month={modalChannels?.month}
        close={() => setModalChannels({ open: false })}
      />
      <Paper my={5} p={3}>
        {title && (
          <Typography align="center" m={3}>
            {title}
          </Typography>
        )}
        <ResponsiveContainer width="100%" height={300}>
          <ComposedChart
            data={chartData?.nextMonths}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" style={{ fontSize: 10 }} />
            <YAxis yAxisId="left" tickFormatter={(value) => currency(value)} orientation="left" />
            <YAxis yAxisId="right" orientation="right" allowDecimals={false} />

            <Tooltip formatter={(value, name) => (name !== 'gyms' ? currency(value) : value)} />
            <Legend />

            <Bar yAxisId="left" dataKey="total" label="Total US" fill={barColor || '#90EE90'} />
            <Line
              type="monotone"
              dataKey="gyms"
              label="Gyms"
              yAxisId="right"
              stroke="#ADD8E6"
              strokeWidth={2}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </Paper>
    </>
  );
};

export default BarMonths;
