import api from 'core/api';

export const workouts = () => api.get('/workouts');
export const workout = (id, workoutsInviteId = null) =>
  workoutsInviteId
    ? api.get(`/workouts/${id}?workouts_invite_id=${workoutsInviteId}`)
    : api.get(`/workouts/${id}`);

export const workoutUsers = (id) => api.get(`/workouts_users/${id}`);

export const workoutCoaches = (id) => api.get(`/workouts/${id}/coaches`);
export const workoutSections = (id) => api.get(`/workouts/${id}/sections`);

export const create = (data) => api.post(`/workouts/`, data);
export const update = (data) => api.put(`/workouts/${data.id}`, data);
export const getWorkoutSectionUser = ({ workoutId, userId }) =>
  api.get(`/workouts/${workoutId}/sections_user/${userId}`);

export const workoutSectionLeaderboard = (workoutId, sectionId, params) =>
  api.get(`/workouts/${workoutId}/section/${sectionId}/leaderboard`, { params });

export const setWorkoutSectionUser = ({ workoutId, data }) =>
  api.post(`/workouts/${workoutId}/set_section_scores`, data);

export const setWorkoutCountdownSeconds = (data) =>
  api.post(`/workouts/${data.workoutId}/set_workout_countdown_seconds`, data);

export const setWorkoutSectionUserPenalties = ({ workoutId, data }) =>
  api.put(`/workouts/${workoutId}/set_penalties`, data);
export const addWorkoutSectionUserFlag = ({ workoutId, data }) =>
  api.put(`/workouts/${workoutId}/add_flag`, data);
export const setWorkoutSectionUserVerified = ({ workoutId, data }) =>
  api.put(`/workouts/${workoutId}/set_verified`, data);
export const favorites = ({ userId, pagination }) =>
  api.get(`/workouts_users/${userId}/favorites`, { params: pagination });
export const upcoming = ({ userId, pagination }) =>
  api.get(`/workouts_users/${userId}/upcoming`, { params: pagination });
export const upcomingAttendings = ({ userId, pagination }) =>
  api.get(`/workouts_users/${userId}/upcoming_attendings`, { params: pagination });
export const recents = ({ userId, pagination }) =>
  api.get(`/workouts_users/${userId}/recents`, { params: pagination });
export const recentsAttendings = ({ userId, pagination }) =>
  api.get(`/workouts_users/${userId}/recents_attendings`, { params: pagination });
export const inactivateUser = (data) =>
  api.put(`/workouts_users/${data.workoutId}/inactivate_user`, data);
export const postWorkoutRecordingInvites = (data) =>
  api.post(`/workouts/${data.workoutId}/workouts_invites`, data);
export const coachAccessed = (workoutId) => api.get(`/workouts_users/${workoutId}/coach_accessed`);
export const trackUser = ({ workoutId, data }) => api.put(`/workouts/${workoutId}/track`, data);
export const purchase = (data) => api.post(`/workouts/${data.workoutId}/purchase`, data);
export const refund = (data) =>
  api.get(`/workouts/${data.workoutId}/refund`, { params: { childId: data?.childId } });
export const updateWorkoutSection = ({ workoutId, workoutSectionId, sectionData }) =>
  api.put(`/workouts_sections/${workoutId}/${workoutSectionId}`, sectionData);
export const deleteWorkoutSection = (data) =>
  api.delete(`/workouts/${data.workoutId}/delete_section/${data.sectionId}`);
export const addWorkoutCoach = (workoutId, userId) =>
  api.put(`/workouts/${workoutId}/add_coach/${userId}`);
