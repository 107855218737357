/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { makeStyles, TableCell, TableRow, withStyles } from '@material-ui/core';
import { labelLight, rowLight, rowXLight, secondaryLight } from 'core/ui/Colors';
import Box from 'core/ui/Box';
import FlagCountry from 'core/ui/FlagCountry';
import Typography from 'core/ui/Typography';
import TableResultsRow from './TableResultsRow';

const useStyles = makeStyles(() => ({
  boxCelMin: {
    width: 60,
    display: 'flex',
    justifyContent: 'center',
  },
}));

const StickyTableCell = withStyles(() => ({
  body: {
    minWidth: '50px',
    left: 0,
    position: 'sticky',
  },
}))(TableCell);

const TableResultsAffiliatesRow = ({
  indexRow,
  item,
  competition,
  openModalScores,
  position,
  setOpenTeamEdit,
  removeUser,
  compWorkouts,
}) => {
  const classes = useStyles();
  const [detail, setDetail] = useState(false);
  const openDetail = () => {
    setDetail(!detail);
  };
  const borderLeft = {
    borderLeft: `0.5px solid ${secondaryLight}`,
  };
  const borderRight = {
    borderRight: `0.5px solid ${secondaryLight}`,
  };
  const lastWorkout = (compWorkouts && compWorkouts.length - 1) || 0;
  const cellStyle = {
    backgroundColor: indexRow % 2 === 0 ? rowXLight : rowLight,
    cursor: 'pointer',
  };

  const sumWorkoutPoints = (w) => {
    let points = 0;
    item.teams.forEach((team) => {
      if (team.workouts[w.id]) {
        points += team.workouts[w.id].points;
      }
    });
    return points;
  };

  return (
    <>
      <TableRow style={cellStyle} onClick={() => openDetail()}>
        <StickyTableCell style={{ borderBottomColor: 'transparent', ...borderLeft, ...cellStyle }}>
          <Box className={classes.boxCelMin} />
          <Box display="flex" alignItems="center">
            <Box className={classes.boxCelMin}>{position}</Box>
            {item.affiliate.country && item.affiliate.country.flag && (
              <Box className={classes.boxCelMin}>
                <FlagCountry flag={item.affiliate.country.flag} size={40} />
              </Box>
            )}
            <Box flexGrow="1" display="flex" alignItems="center">
              <Typography noWrap style={{ color: labelLight }}>
                {item.affiliate.gymName}
              </Typography>
            </Box>

            <Box className={classes.boxCelMin}>
              <Typography className="roboto-regular">
                {item.totalPoints > 0 ? item.totalPoints : '--'}
              </Typography>
            </Box>
          </Box>
        </StickyTableCell>
        {compWorkouts.map((w, index) => (
          <TableCell
            key={w.id}
            style={
              lastWorkout === index
                ? { borderBottomColor: 'transparent', ...borderLeft, ...borderRight, ...cellStyle }
                : { borderBottomColor: 'transparent', ...borderLeft, ...cellStyle }
            }
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              style={{ minWidth: 100 }}
            >
              {sumWorkoutPoints(w)}
            </Box>
          </TableCell>
        ))}
      </TableRow>

      {item.teams.map((teamData, index) => (
        <TableResultsRow
          item={teamData}
          indexRow={index}
          competition={competition}
          openModalScores={openModalScores}
          position={item.position}
          setOpenTeamEdit={setOpenTeamEdit}
          removeUser={removeUser}
          compWorkouts={compWorkouts}
          showing={detail}
          affiliate
        />
      ))}
    </>
  );
};

export default TableResultsAffiliatesRow;
