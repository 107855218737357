/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { labelLight, secondary, secondaryLight } from 'core/ui/Colors';
import Box from 'core/ui/Box';
import FlagCountry from 'core/ui/FlagCountry';
import Typography from 'core/ui/Typography';
import { Collapse } from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowRight } from '@material-ui/icons';
import MobileTableResultsRow from './MobileTableResultsRow';

export default ({
  competition,
  competitionResults,
  openModalScores,
  setOpenTeamEdit,
  removeUser,
  compWorkouts,
}) => {
  const [currentOpen, setCurrentOpen] = useState(0);
  const styleIcon = {
    color: secondary,
    fontSize: 20,
  };
  const style = {
    borderLeft: `0.5px solid ${secondaryLight}`,
    borderRight: `0.5px solid ${secondaryLight}`,
    borderTop: `0.5px solid ${secondaryLight}`,
  };

  return (
    <Box mt={3} style={style}>
      {competitionResults.map((item, index) => (
        <Box key={index.toString()}>
          <Box
            p={2}
            display="flex"
            alignItems="center"
            className="hover"
            style={style}
            onClick={() =>
              setCurrentOpen((prev) => (prev === item.affiliate.id ? 0 : item.affiliate.id))
            }
          >
            <Typography mr={3} variant="h5">
              {item.position}
            </Typography>

            {item.affiliate.country && item.affiliate.country.flag && (
              <Box mr={3}>
                <FlagCountry flag={item.affiliate.country.flag} size={40} />
              </Box>
            )}
            <Box flexGrow="1" display="flex" alignItems="center">
              <Typography noWrap style={{ color: labelLight }}>
                {item.affiliate.gymName}
              </Typography>
            </Box>

            <Box display="flex" alignItems="center">
              <Box>
                <Typography mr={2} color="secondary" variant="h5">
                  {item.totalPoints}
                </Typography>
              </Box>
              {currentOpen === item.affiliate.id ? (
                <KeyboardArrowDown style={{ ...styleIcon }} />
              ) : (
                <KeyboardArrowRight style={{ ...styleIcon }} />
              )}
            </Box>
          </Box>

          <Collapse in={currentOpen === item.affiliate.id}>
            {item.teams.map((teamData, i) => (
              <Box key={i.toString()}>
                <MobileTableResultsRow
                  competition={competition}
                  item={teamData}
                  openModalScores={openModalScores}
                  setOpenTeamEdit={setOpenTeamEdit}
                  removeUser={removeUser}
                  compWorkouts={compWorkouts}
                  affiliate
                />
              </Box>
            ))}
          </Collapse>
        </Box>
      ))}
    </Box>
  );
};
