import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import { DialogContent, Dialog } from '@material-ui/core';
import AWS from 'aws-sdk';

import uuid from 'react-uuid';
import LinearProgress from 'core/ui/LinearProgress';
import DialogTitle from 'core/ui/Dialog/DialogTitle';
import { ENV_APP } from 'core/env';
import { B_ID } from 'core/utils/consts';
import { mKY } from 'core/utils/helpers';
import { WorkoutContext } from '..';

const B_K = mKY();

const bucket = new AWS.S3({
  accessKeyId: B_ID,
  secretAccessKey: B_K,
  region: 'us-east-2',
});

const PATH_KEY = `uploads/${ENV_APP}/WorkoutUploadVideo/`;

const useStyles = makeStyles({
  root: {
    backgroundColor: '#f3f3f3',
  },
  btnClose: {
    position: 'absolute',
    right: 5,
    top: 0,
  },
});

export default function ModalUpload() {
  const classes = useStyles();
  const { t } = useTranslation();
  const formContext = useContext(WorkoutContext);

  const [progressUpload, setProgressUpload] = useState(0);

  const uploadfile = (fileName, file) => {
    const params = {
      Bucket: 'stream-fitness-app-uploads',
      Key: PATH_KEY + fileName,
      Body: file,
      ContentType: file.type,
    };

    return bucket.upload(params, (err, data) => {
      if (err) {
        console.log('There was an error uploading your file: ', err);
        return false;
      }

      formContext.setValue('videoLink', data.Location);
      formContext.setUpload((prev) => ({ ...prev, complete: true, type: 'link' }));
      return true;
    });
  };

  const uploadSampleFile = () => {
    const { file } = formContext.upload;
    const uniqueFileName = `streamfitness-workout-video-${uuid()}-${file.name}`;

    uploadfile(uniqueFileName, file).on('httpUploadProgress', (progress) => {
      const progressPercentage = Math.round((progress.loaded / progress.total) * 100);
      setProgressUpload(progressPercentage);
    });
  };

  useEffect(() => {
    if (formContext.upload.open) {
      uploadSampleFile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formContext.upload.open]);

  return (
    <Dialog open={formContext.upload.open} maxWidth="xs" fullWidth>
      <DialogTitle background="true">{t('workout.modal.upload')}</DialogTitle>
      <DialogContent className={classes.root}>
        {formContext.upload.file && (
          <Typography align="center" ml={3} color="primary" variant="h4">
            {formContext.upload.file.name}
          </Typography>
        )}
        <LinearProgress
          style={{ height: 15, width: '100%', borderRadius: 5 }}
          value={progressUpload}
        />
      </DialogContent>
    </Dialog>
  );
}
