import React from 'react';
import { Tooltip } from '@material-ui/core';
import Info from '@material-ui/icons/Info';

/**
 * @param {import('@material-ui/core').TooltipProps} props
 */

export const HintIcon = (props) => (
  <Tooltip {...props}>
    <Info fontSize="small" />
  </Tooltip>
);
