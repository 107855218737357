import React, { useEffect } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import Typography, { fontStyle } from 'core/ui/Typography';
import { useSelector } from 'react-redux';
import { workoutsSelectors } from 'modules/workouts';
import LoaderSm from 'core/ui/LoaderSm';
import { ButtonPrimary } from 'core/ui/Button';
import CheckIcon from '@material-ui/icons/Check';

export default function ButtonsRegister({ purchase, workout, registerStatus }) {
  const { t } = useTranslation();
  const loadingPurchase = useSelector((state) => workoutsSelectors.getLoadingPurchase(state));
  const purchaseSuccess = useSelector((state) => workoutsSelectors.getPurchaseSuccess(state));
  const buttonColor = workout?.channel?.customColors?.buttonColor;

  const hasKeyOrList =
    !!registerStatus?.hasOnlineKey || !!registerStatus?.hasInPersonKey || !!registerStatus?.hasList;

  useEffect(() => {
    if (hasKeyOrList) {
      if (workout.online !== workout.inPerson) {
        purchase(workout.online ? 'online' : 'inPerson', workout?.id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasKeyOrList, workout]);

  const showOnline = !!registerStatus?.hasOnlineKey || !!registerStatus?.hasList;
  const showInPerson = !!registerStatus?.hasInPersonKey || !!registerStatus?.hasList;

  return (
    <Box>
      {registerStatus?.registered && (
        <Typography variant="h5" align="center" m={5}>
          {t('workout.alreadyRegistered')}
        </Typography>
      )}
      <Box mt={5} display="flex" justifyContent="flex-end">
        {showInPerson && !registerStatus?.registered && workout.inPerson && (
          <Box flexGrow={1} mr={1}>
            <ButtonPrimary
              disabled={loadingPurchase && !purchaseSuccess}
              onClick={() => purchase('inPerson', workout.id)}
              type="submit"
              buttonColor={buttonColor}
              fullWidth
            >
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <Box display="flex" alignItems="center">
                  <LoaderSm loading={loadingPurchase} width={20} />
                  <Typography component="span" variant="h6">
                    {t('button.inPerson')}
                  </Typography>
                </Box>
                {(registerStatus?.hasList || registerStatus?.hasInPersonKey) && (
                  <Box display="flex" mt={-1} alignItems="center">
                    <CheckIcon style={{ color: 'white', fontSize: 14 }} />
                    <Typography style={{ ...fontStyle.xSmall, color: 'white' }}>
                      {t(registerStatus?.hasList ? 'channelList' : 'membership')}
                    </Typography>
                  </Box>
                )}
              </Box>
            </ButtonPrimary>
          </Box>
        )}

        {showOnline && !registerStatus.registered && workout.online && (
          <Box flexGrow={1} ml={1}>
            <ButtonPrimary
              disabled={loadingPurchase && !purchaseSuccess}
              onClick={() => purchase('online', workout.id)}
              type="submit"
              buttonColor={buttonColor}
              fullWidth
            >
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <Box display="flex" alignItems="center">
                  <LoaderSm loading={loadingPurchase} width={20} />
                  <Typography component="span" variant="h6">
                    {t('button.online')}
                  </Typography>
                </Box>
                {(registerStatus?.hasList || registerStatus?.hasOnlineKey) && (
                  <Box display="flex" mt={-1} alignItems="center">
                    <CheckIcon style={{ color: 'white', fontSize: 14 }} />
                    <Typography style={{ ...fontStyle.xSmall, color: 'white' }}>
                      {t(registerStatus?.hasList ? 'channelList' : 'membership')}
                    </Typography>
                  </Box>
                )}
              </Box>
            </ButtonPrimary>
          </Box>
        )}
      </Box>
    </Box>
  );
}

ButtonsRegister.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  workout: propTypes.object.isRequired,
  purchase: propTypes.func.isRequired,
};

ButtonsRegister.defaultProps = {};
