import api from 'core/api';
import { call, put } from 'redux-saga/effects';

export const automationWorkflowsTypesModule = 'automationWorkflowsTypes';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const actions = {
  getExecutionsUsers: {
    module: automationWorkflowsTypesModule,
    name: 'getExecutionsUsers',
    api: (params) =>
      api.get(`automation/workflows/${params?.workflowId}/workflows_types_executions_users`, {
        params,
      }),
    *sagas(Creators, { params, options }) {
      try {
        const resp = yield call(actions.getExecutionsUsers.api, params);
        yield put(Creators.getExecutionsUsersSuccess(resp.data, resp.pagination));
        if (options.onSuccess) {
          options.onSuccess(resp.data, resp.pagination);
        }
      } catch (error) {
        if (options.onError) {
          options.onError(error);
        }
        yield put(Creators.getExecutionsUsersError());
      }
    },
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  deleteExecutionsUser: {
    module: automationWorkflowsTypesModule,
    name: 'deleteExecutionsUser',
    api: (params) =>
      api.delete(
        `automation/workflows/${params?.workflowId}/workflows_types_executions_users/${params?.id}`,
        {
          params,
        },
      ),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
};

export default {
  actions,
  state: {
    loading: false,
  },
};
