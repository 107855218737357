/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Box from 'core/ui/Box';
import { secondary, secondaryXLight } from 'core/ui/Colors';
import Typography, { fontStyle } from 'core/ui/Typography';
import Paper from 'core/ui/Paper';
import Radio from 'core/ui/Radio';
import { useTranslation } from 'react-i18next';
import PlateWeightIcon from 'core/ui/MIcon/PlateWeightIcon';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

const BarbellPlatesCalculator = () => {
  const { t } = useTranslation();
  const [data, setData] = useState({ option: 'pounds', weight: '100', barbellWeight: 45 });
  const [weights, setWeights] = useState({});

  const kgOptions = [25, 20, 15, 10, 5, 2.5, 1, 0.5];
  const poundsOptions = [55, 45, 35, 25, 15, 10, 5, 2.5];

  const barbellWeightPounds = [15, 35, 45];
  const barbellWeightKgs = [15, 20];

  const options = {
    set: data.option === 'pounds' ? poundsOptions : kgOptions,
    barbellWeight: data.barbellWeight,
    weightLimits: {},
    addedPlates: [],
  };

  const getTotal = () => {
    let total = data.barbellWeight;
    Object.keys(weights).forEach((w) => {
      total += w * weights[w];
    });

    return total;
  };

  return (
    <Box>
      <Box display="flex" justifyContent="center" my={3}>
        {['kilograms', 'pounds'].map((w) => (
          <Box mx={1} key={w}>
            <Radio
              color={secondary}
              label={w.toUpperCase()}
              onClick={() =>
                setData((prev) => ({ ...prev, option: w, barbellWeight: w === 'pounds' ? 45 : 20 }))
              }
              checked={data.option === w}
            />
          </Box>
        ))}
      </Box>
      <Box my={3} display="flex" flexDirection="column" alignItems="center">
        <Typography mb={1} style={fontStyle.label}>
          {t('calculator.barbellWeight')}
        </Typography>
        <Box display="flex">
          {(data.option === 'pounds' ? barbellWeightPounds : barbellWeightKgs).map((w) => (
            <Box mx={1} key={w}>
              <Radio
                color={secondary}
                label={`${w}`}
                onClick={() => setData((prev) => ({ ...prev, barbellWeight: w }))}
                checked={data.barbellWeight === w}
              />
            </Box>
          ))}
        </Box>
      </Box>
      <Paper className="paper-rounded" p={3} m={3}>
        <Box className="grid grid-template-columns-4">
          {options.set.map((op) => (
            <Box key={op}>
              <Box
                style={{ border: `0.2px solid ${secondaryXLight}` }}
                display="flex"
                p={3}
                flexDirection="column"
                alignItems="center"
              >
                <Box display="flex">
                  <Box
                    className="hover"
                    onClick={() =>
                      setWeights((prev) => ({
                        ...prev,
                        [op]: prev[op] ? prev[op] + 2 : 2,
                      }))
                    }
                  >
                    <AddIcon />
                  </Box>
                  <Box flexGrow={1}>
                    <Box display="flex" alignItems="flex-end">
                      <Typography color="secondary" variant="h5">
                        {op}
                      </Typography>
                      <Typography ml={0.5} color="secondary" style={fontStyle.medium}>
                        {t(`weight.type.abbrev.${data.option}`)}
                      </Typography>
                    </Box>
                    <Box display="flex" alignItems="center" style={{ height: 25 }}>
                      <PlateWeightIcon
                        size={15}
                        color={weights[op] && weights[op] > 0 ? secondary : secondaryXLight}
                      />
                      <Typography
                        ml={2}
                        style={{
                          color: weights[op] && weights[op] > 0 ? secondary : secondaryXLight,
                        }}
                        variant="h4"
                      >
                        {weights[op] ? weights[op] : 0}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    className="hover"
                    onClick={() =>
                      setWeights((prev) => ({
                        ...prev,
                        [op]: prev[op] ? (prev[op] === 2 ? 0 : prev[op] - 2) : 0,
                      }))
                    }
                  >
                    <RemoveIcon />
                  </Box>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
        <Box mt={3} display="flex" justifyContent="center" alignItems="flex-end">
          <Typography align="center" color="textSecondary" variant="h1">
            {getTotal()}
          </Typography>
          <Typography ml={0.5} color="secondary" style={fontStyle.medium}>
            {t(`weight.type.abbrev.${data.option}`)}
          </Typography>
        </Box>
      </Paper>
    </Box>
  );
};

export default BarbellPlatesCalculator;
