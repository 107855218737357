import React, { createContext, useEffect, useState } from 'react';

import Box from 'core/ui/Box';
import useActions from 'modules/map/useActions';
import Typography from 'core/ui/Typography';
import Modules from 'modules';
import { KeyboardArrowDown, KeyboardArrowRight } from '@material-ui/icons';
import Paper from 'core/ui/Paper';
import { Collapse } from '@material-ui/core';
import { secondaryXLight } from 'core/ui/Colors';
import Checkbox from 'core/ui/Checkbox';

export const Context = createContext();

const StoreCouponsSelector = ({ ownerableId, ownerableType, selected, setSelected }) => {
  const { request } = useActions();
  const [coupons, setCoupons] = useState([]);
  const [opened, setOpened] = useState(false);

  const getCoupons = () => {
    request({
      action: Modules.storeDiscountCodes.actions.getStoreDiscountCodes,
      data: {
        ownerableId,
        ownerableType,
      },
      options: {
        onSuccess: (data) => {
          setCoupons(data);
        },
      },
    });
  };

  useEffect(() => {
    getCoupons(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request]);

  const choose = (item) => {
    setSelected(item);
  };

  return (
    <Box>
      <Paper style={{ border: `0.5px solid ${secondaryXLight}` }} p={3} className="hover">
        <Box display="flex" alignItems="center" onClick={() => setOpened(!opened)}>
          <Box flexGrow={1}>
            <Typography variant="h4">Coupons Codes</Typography>
          </Box>
          {opened ? <KeyboardArrowDown style={{}} /> : <KeyboardArrowRight style={{}} />}
        </Box>
        <Collapse in={opened}>
          <Box>
            {coupons
              ?.filter((x) => !x.applyAllProducts)
              ?.map((item) => (
                <Paper m={1} p={3} key={item.id} onClick={() => choose(item)} className="hover">
                  <Box display="flex" alignItems="center">
                    <Checkbox
                      checked={selected?.find((x) => x === item?.id)}
                      onClick={() => choose(item)}
                    />
                    <Typography ml={2} variant="h5">
                      {item?.code}
                    </Typography>
                  </Box>
                </Paper>
              ))}
          </Box>
        </Collapse>
      </Paper>
    </Box>
  );
};

export default StoreCouponsSelector;

StoreCouponsSelector.propTypes = {};

StoreCouponsSelector.defaultProps = {};
