import React, { useEffect, useState } from 'react';
import { ButtonPrimary } from 'core/ui/Button';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import Typography from 'core/ui/Typography';
import { Add, Delete, Edit } from '@material-ui/icons';
import useSelectors from 'modules/map/useSelectors';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import { usersAddressesModule } from 'modules/usersAddresses';
import SFDataTable from 'core/ui/DataTable';
import { IconButton } from '@material-ui/core';
import useConfirmation from 'core/useConfirmation';
import ModalForm from './Form/Modal';

const UserAddresses = ({ user, channelId }) => {
  const { t } = useTranslation();
  const data = useSelectors(usersAddressesModule, 'data');
  const { request } = useActions();
  const { confirmation } = useConfirmation();
  const [modal, setModal] = useState({
    open: false,
  });

  const getAddresses = () => {
    request({
      action: Modules.usersAddresses.actions.getAdrresses,
      data: { userId: user.id, channelId },
    });
  };

  useEffect(() => {
    if (user && user.id) {
      getAddresses();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const openModal = () => {
    setModal((prev) => ({ ...prev, mode: 'new', open: true }));
  };

  const onRemoveClick = (item) => {
    confirmation({
      description: 'Are you sure?',
      yesClick: () => {
        request({
          action: Modules.usersAddresses.actions.destroy,
          data: { userId: user.id, channelId, id: item?.id },
          options: {
            onSuccess: () => {
              getAddresses();
            },
          },
        });
      },
    });
  };

  const onEditClick = (item) => {
    setModal((prev) => ({ ...prev, mode: 'edit', item, open: true }));
  };

  const columns = [
    {
      name: 'Description',
      selector: 'description',
      sortable: true,
      cell: (row) => <Typography>{row?.description}</Typography>,
    },
    {
      name: 'Country',
      selector: 'countryId',
      sortable: true,
      cell: (row) => <Typography>{row?.country?.name}</Typography>,
    },
    {
      name: 'Address',
      selector: 'street',
      sortable: true,
      cell: (row) => <Typography>{row?.street}</Typography>,
    },
    {
      name: 'State',
      selector: 'street',
      sortable: true,
      cell: (row) => <Typography>{row?.state}</Typography>,
    },
    {
      name: 'City',
      selector: 'city',
      sortable: true,
      cell: (row) => <Typography>{row?.city}</Typography>,
    },
    {
      name: 'Zip',
      selector: 'zip',
      sortable: true,
      cell: (row) => <Typography>{row?.zip}</Typography>,
    },
    {
      cell: (row) => (
        <Box display="flex">
          <IconButton onClick={() => onEditClick(row)}>
            <Edit />
          </IconButton>

          <IconButton onClick={() => onRemoveClick(row)}>
            <Delete />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <Box>
      {modal.open && (
        <ModalForm
          open={modal.open}
          item={modal.item}
          mode={modal.mode}
          userId={user?.id}
          channelId={channelId}
          close={() => setModal(() => ({ open: false }))}
          callback={() => {
            setModal(() => ({ open: false }));
            getAddresses();
          }}
        />
      )}

      <Box display="flex" mb={3}>
        <Box flexGrow="1" mb={3} alignItems="center">
          <Typography color="primary" component="span" variant="h5">
            {t('user.addresses').toUpperCase()}
          </Typography>
        </Box>

        <ButtonPrimary onClick={() => openModal()}>
          <Box display="flex" alignItems="flex-end">
            <Add size="small" />
            <Box alignItems="center">
              <Typography component="span" mr={1}>
                {t('button.add')}
              </Typography>
            </Box>
          </Box>
        </ButtonPrimary>
      </Box>

      <Box px={3}>
        <SFDataTable pagination={false} columns={columns} data={data} showExport={false} noHeader />
      </Box>
    </Box>
  );
};

export default UserAddresses;
