import api from 'core/api';
import { retrieveAuthHeaders } from 'core/api/auth-headers';
import { retrieveData } from 'core/utils/session-storage';
import { call, put } from 'redux-saga/effects';
import { saveAs } from 'file-saver';
import humps from 'humps';
import { BASE_URL } from 'core/env';

export const channelReportsModule = 'channelReports';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const loadingExportStates = {
  start: { loadingExport: true },
  error: { loadingExport: false },
  success: { loadingExport: false },
};

const loadingChartStates = {
  start: { loadingChart: true },
  error: { loadingChart: false },
  success: { loadingChart: false },
};

const loadingAttendanceStates = {
  start: { loadingAttendance: true },
  error: { loadingAttendance: false },
  success: { loadingAttendance: false },
};

const actions = {
  channelReport: {
    module: channelReportsModule,
    name: 'channelReport',
    api: (data) => api.post(`/channel_reports/${data.channelId}/${data.report}`, data),
    params: {
      start: ['params'],
      error: [''],
      success: ['data'],
    },
    state: loadingStates,
  },
  exportChannelReport: {
    module: channelReportsModule,
    name: 'exportChannelReport',
    api: (data) => {
      // api.post(`/channel_reports/${data.channelId}/${data.report}`, data)
      const authHeaders = retrieveAuthHeaders(retrieveData)();
      return fetch(
        `${BASE_URL}/api/v1/channel_reports/${data.channelId}/${data.report}?export=true`,
        {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
            ...authHeaders,
          },
          body: JSON.stringify(humps.decamelizeKeys(data)),
        },
      );
    },
    *sagas(Creators, { params, options }) {
      try {
        const resp = yield call(actions.exportChannelReport.api, params);

        resp.blob().then((f) => saveAs(f, params?.exportFileName || `${params?.channelId}.csv`));

        yield put(Creators.exportChannelReportSuccess());
        if (options.onSuccess) {
          options.onSuccess(resp);
        }
      } catch (error) {
        if (options.onError) {
          options.onError(error);
        }
        yield put(Creators.exportChannelReportError());
      }
    },
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  coachPayrollReport: {
    module: channelReportsModule,
    name: 'coachPayrollReport',
    api: (data) => api.post(`/channel_reports/coach_payroll`, data),
    params: {
      start: ['params'],
      error: [''],
      success: ['data'],
    },
    state: loadingStates,
  },
  attendanceResume: {
    module: channelReportsModule,
    name: 'attendanceResume',
    api: (data) => api.post(`/channel_reports/attendance_resume`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingAttendanceStates,
  },
  paymentsChart: {
    module: channelReportsModule,
    name: 'paymentsChart',
    api: (data) => api.post(`/channel_reports/${data.channelId}/${data.report}`, data),
    params: {
      start: ['params'],
      error: [''],
      success: ['chartData'],
    },
    state: loadingChartStates,
  },
  membersConversionsChart: {
    module: channelReportsModule,
    name: 'membersConversionsChart',
    api: (data) => api.post(`/channel_reports/members_conversions_chart`, data),
    params: {
      start: ['params'],
      error: [''],
      success: ['membersConversionsChart'],
    },
    state: loadingChartStates,
  },
  membersGrowthLtvChart: {
    module: channelReportsModule,
    name: 'membersGrowthLtvChart',
    api: (data) => api.post(`/channel_reports/members_growth_ltv_chart`, data),
    params: {
      start: ['params'],
      error: [''],
      success: ['chart'],
    },
    state: loadingChartStates,
  },
  membersChurnChart: {
    module: channelReportsModule,
    name: 'membersChurnChart',
    api: (data) => api.post(`/channel_reports/members_churn_chart`, data),
    params: {
      start: ['params'],
      error: [''],
      success: ['membersChurnChart'],
    },
    state: loadingChartStates,
  },
  exportChannelMemberships: {
    module: channelReportsModule,
    name: 'exportChannelMemberships',
    api: (data) => api.post(`/channel_reports/${data.channelId}/Memberships`, data),
    *sagas(Creators, { params, options }) {
      try {
        const resp = yield call(actions.exportChannelMemberships.api, params);

        yield put(Creators.exportChannelMembershipsSuccess());
        if (options.onSuccess) {
          options.onSuccess(resp);
        }
      } catch (error) {
        if (options.onError) {
          options.onError(error);
        }
        yield put(Creators.exportChannelMembershipsError());
      }
    },
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingExportStates,
  },
};

export default {
  actions,
  state: {
    loading: false,
    loadingChart: false,
    loadingExport: false,
    loadingAttendance: false,

    data: {},
    membersConversionsChart: [],
    membersChurnChart: [],
    chart: [],
    chartData: {},
    loadingProjection: false,
  },
};
