/* eslint-disable react/prop-types */
import React from 'react';
import Paper from 'core/ui/Paper';
import Typography from 'core/ui/Typography';
import Grid from 'core/ui/Grid';
import { channelCurrency } from 'core/utils/formatters';
import BarMonthsLtv from './BarMonthsLtv';

export default function ResultLtv({ channel, data }) {
  return (
    <Paper p={3} px={5}>
      <Typography variant="h5" mb={3}>
        LTV
      </Typography>
      <Grid container spacing={3} mb={3}>
        <Grid item xs={12} md={12}>
          <Paper p={3}>
            <Typography variant="h5" mb={3}>
              {data?.currentMonth}
            </Typography>
            <Typography variant="h1">
              {channelCurrency(data?.currentLtv, channel?.currency)}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={12}>
          <BarMonthsLtv channel={channel} />
        </Grid>
      </Grid>
    </Paper>
  );
}
