import api from 'core/api';
import { call, put } from 'redux-saga/effects';

export const conversationsModule = 'conversations';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const loadingMessagesStates = {
  start: { loadingMessages: true },
  error: { loadingMessages: false },
  success: { loadingMessages: false },
};

const loadingShowStates = {
  start: { loadingShow: true },
  error: { loadingShow: false },
  success: { loadingShow: false },
};

const loadingCreateStates = {
  start: { loadingCreate: true },
  error: { loadingCreate: false },
  success: { loadingCreate: false },
};

const loadingJoinStates = {
  start: { loadingJoin: true },
  error: { loadingJoin: false },
  success: { loadingJoin: false },
};

const actions = {
  getGroupsChats: {
    module: conversationsModule,
    name: 'getGroupsChats',
    api: (params) => api.get(`/chat/groups_chats/my_groups`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: ['groupsChats'],
    },
    state: loadingStates,
  },
  getGroupsChat: {
    module: conversationsModule,
    name: 'getGroupsChat',
    api: (params) => api.get(`/chat/groups_chats/${params.groupsChatId}`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: ['groupsChat'],
    },
    state: loadingShowStates,
  },
  createMessage: {
    module: conversationsModule,
    name: 'createMessage',
    api: (params) =>
      api.post(`/chat/groups_chats/${params.groupsChatId}/groups_chats_messages`, params),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingCreateStates,
  },
  updateMessage: {
    module: conversationsModule,
    name: 'updateMessage',
    api: (params) =>
      api.put(
        `/chat/groups_chats/${params.groupsChatId}/groups_chats_messages/${params?.id}`,
        params,
      ),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingCreateStates,
  },
  deleteMessage: {
    module: conversationsModule,
    name: 'deleteMessage',
    api: (params) =>
      api.delete(
        `/chat/groups_chats/${params.groupsChatId}/groups_chats_messages/${params?.id}`,
        params,
      ),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingCreateStates,
  },
  setReaded: {
    module: conversationsModule,
    name: 'setReaded',
    api: (params) =>
      api.put(
        `/chat/groups_chats/${params.groupsChatId}/groups_chats_messages/${params?.id}/set_readed`,
        params,
      ),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingCreateStates,
  },
  joinGroup: {
    module: conversationsModule,
    name: 'joinGroup',
    api: (params) =>
      api.post(`/chat/groups_chats/${params.groupsChatId}/groups_chats_users`, params),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingJoinStates,
  },
  unjoinGroup: {
    module: conversationsModule,
    name: 'unjoinGroup',
    api: (params) =>
      api.delete(`/chat/groups_chats/${params.groupsChatId}/groups_chats_users`, params),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingCreateStates,
  },

  getGroupMessages: {
    module: conversationsModule,
    name: 'getGroupMessages',
    api: (params) =>
      api.get(`/chat/groups_chats/${params.groupsChatId}/groups_chats_messages`, { params }),
    *sagas(Creators, { params, options }) {
      try {
        const resp = yield call(actions.getGroupMessages.api, params);
        yield put(Creators.getGroupMessagesSuccess(resp.pagination));
        if (options.onSuccess) {
          options.onSuccess(resp.data, resp.pagination);
        }
      } catch (error) {
        if (options.onError) {
          options.onError(error);
        }
        yield put(Creators.getGroupMessagesError());
      }
    },
    params: {
      start: ['params'],
      error: [''],
      success: ['pagination'],
    },
    state: loadingMessagesStates,
  },

  clearGroupsChat: {
    module: conversationsModule,
    name: 'clearGroupsChat',
    params: {
      start: ['params'],
      error: [''],
      success: ['groupsChat'],
    },
    state: loadingCreateStates,
  },

  setGroupsChats: {
    module: conversationsModule,
    name: 'setGroupsChats',
    params: {
      start: ['params'],
      error: [''],
      success: ['groupsChats'],
    },
  },

  clearPagination: {
    module: conversationsModule,
    name: 'clearPagination',
    params: {
      start: ['params'],
      error: [''],
      success: ['pagination'],
    },
  },

  updateGroupChat: {
    module: conversationsModule,
    api: (params) => api.get(`/chat/groups_chats/${params.groupsChatId}`, { params }),
    name: 'updateGroupChat',
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingCreateStates,
  },
};

export default {
  actions,
  state: {
    groupsChats: [],
    groupsChat: null,
    loading: false,
    loadingCreate: false,
    loadingCoupon: false,
    loadingShow: false,
    loadingMessages: false,
    loadingJoin: false,
    users: [],
    pagination: null,
  },
};
