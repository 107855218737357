import { sessionSelectors } from 'modules/session';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const useVerifyUserProfile = () => {
  const history = useHistory();
  const location = useLocation();
  const loading = useSelector((state) => sessionSelectors.getLoading(state));
  const isAuthenticated = useSelector((state) => sessionSelectors.getIsAuthenticated(state));
  const currentUser = useSelector((state) => sessionSelectors.getUser(state));
  const pathName = location?.pathname;

  const isToRedirect =
    pathName.indexOf('/embed/memberships/') === -1 &&
    pathName.indexOf('/channel/view/') === -1 &&
    pathName.indexOf('/competition/view') === -1 &&
    pathName.indexOf('/channel/events/') === -1 &&
    pathName.indexOf('/calendar') === -1 &&
    pathName.indexOf('workout/upload');

  useEffect(() => {
    if (!isToRedirect) {
      return;
    }
    if (!loading && isAuthenticated && currentUser && !currentUser.role) {
      history.push('/signup/type-account');
      return;
    }

    if (!loading && isAuthenticated && currentUser && !currentUser.name) {
      history.push('/profile/edit');
    }
  }, [loading, history, isAuthenticated, currentUser, pathName, isToRedirect]);

  return {};
};
export default useVerifyUserProfile;
