import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { IntlProvider } from 'react-intl';
import { pdfjs } from 'react-pdf';
// eslint-disable-next-line import/no-extraneous-dependencies
import { StylesProvider } from '@material-ui/styles';
// eslint-disable-next-line import/no-extraneous-dependencies

import { ReducersProvider } from 'contexts';
import store from './store';
import App from './App';
import actions from './modules/map/actions';
import selectors from './modules/map/selectors';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

import './index.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const honeybadger = {};

if (process.env.NODE_ENV !== 'development') {
  const config = {
    api_key: 'eaa2b96f',
    environment: process.env.NODE_ENV,
    revision: 'git SHA/project version',
  };

  // honeybadger = Honeybadger.configure(config);
  // Honeybadger.beforeNotify((notice) => {
  //   if (notice.message.indexOf('UnhandledPromiseRejectionWarning') > -1) {
  //     return false;
  //   }
  //   return true;
  // });
}

const jsx = (
  <StylesProvider injectFirst>
    <IntlProvider locale="en">
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        {/* <ErrorBoundary honeybadger={honeybadger}> */}
        <Provider store={store}>
          <ReducersProvider.Provider value={{ actions, selectors }}>
            <App />
          </ReducersProvider.Provider>
        </Provider>
        {/* </ErrorBoundary> */}
      </MuiPickersUtilsProvider>
    </IntlProvider>
  </StylesProvider>
);

ReactDOM.render(jsx, document.getElementById('root'));
