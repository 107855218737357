import React, { useState } from 'react';
import Box from 'core/ui/Box';
import { secondary } from 'core/ui/Colors';
import Paper from 'core/ui/Paper';
import Typography from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { KeyboardArrowDown, KeyboardArrowRight } from '@material-ui/icons';
import { Collapse } from '@material-ui/core';
import { sessionSelectors } from 'modules/session';
import ManageCalendars from 'components/Calendars/Manage';
import { channelsSelectors } from 'modules/channels';
import CoachRatePayroll from './CoachRatePayroll';
import CoachClocks from './Clocks';
import CoachProfileCustomPayments from './CustomPayments';

const DashboardCoaches = () => {
  const { t } = useTranslation();
  const channel = useSelector((state) => channelsSelectors.getChannel(state));
  const user = useSelector((state) => sessionSelectors.getUser(state));
  const [openCustomPayments, setOpenCustomPayments] = useState(false);
  const [openPayroll, setOpenPayroll] = useState(false);
  const [openClock, setOpenClock] = useState(false);
  const [calendar, setCalendar] = useState(false);

  const styleIcon = {
    color: secondary,
    fontSize: 20,
  };

  return (
    <Box>
      <Paper p={5} className="paper-rounded" mb={3}>
        <Box mb={3} flexGrow="1" display="flex" alignItems="center">
          <Box display="flex" alignItems="center" onClick={() => setCalendar(!calendar)}>
            {calendar ? (
              <KeyboardArrowDown style={{ ...styleIcon }} />
            ) : (
              <KeyboardArrowRight style={{ ...styleIcon }} />
            )}
          </Box>
          <Box
            flexGrow="1"
            display="flex"
            alignItems="center"
            onClick={() => setCalendar(!calendar)}
          >
            <Typography ml={1} color="secondary" className="link" variant="h5">
              {t('channel.coach.calendars').toUpperCase()}
            </Typography>
          </Box>
        </Box>
        <Collapse in={calendar}>
          <ManageCalendars coachId={user?.id} channelId={channel?.id} />
        </Collapse>
      </Paper>

      <Paper p={5} className="paper-rounded" mb={3}>
        <Box mb={3} flexGrow="1" display="flex" alignItems="center">
          <Box display="flex" alignItems="center" onClick={() => setOpenClock(!openClock)}>
            {openClock ? (
              <KeyboardArrowDown style={{ ...styleIcon }} />
            ) : (
              <KeyboardArrowRight style={{ ...styleIcon }} />
            )}
          </Box>
          <Box
            flexGrow="1"
            display="flex"
            alignItems="center"
            onClick={() => setOpenClock(!openClock)}
          >
            <Typography ml={1} color="secondary" className="link" variant="h5">
              {t('channel.coach.clocks').toUpperCase()}
            </Typography>
          </Box>
        </Box>
        <Collapse in={openClock}>
          <CoachClocks user={user} />
        </Collapse>
      </Paper>

      <Paper p={5} className="paper-rounded" mb={3}>
        <Box mb={3} flexGrow="1" display="flex" alignItems="center">
          <Box display="flex" alignItems="center" onClick={() => setOpenPayroll(!openPayroll)}>
            {openPayroll ? (
              <KeyboardArrowDown style={{ ...styleIcon }} />
            ) : (
              <KeyboardArrowRight style={{ ...styleIcon }} />
            )}
          </Box>
          <Box
            flexGrow="1"
            display="flex"
            alignItems="center"
            onClick={() => setOpenPayroll(!openPayroll)}
          >
            <Typography ml={1} color="secondary" className="link" variant="h5">
              {t('channel.coach.payroll').toUpperCase()}
            </Typography>
          </Box>
        </Box>
        <Collapse in={openPayroll}>
          <CoachRatePayroll />
        </Collapse>
      </Paper>

      <Paper p={5} className="paper-rounded" mb={3}>
        <Box mb={3} flexGrow="1" display="flex" alignItems="center">
          <Box
            display="flex"
            alignItems="center"
            onClick={() => setOpenCustomPayments(!openCustomPayments)}
          >
            {openCustomPayments ? (
              <KeyboardArrowDown style={{ ...styleIcon }} />
            ) : (
              <KeyboardArrowRight style={{ ...styleIcon }} />
            )}
          </Box>
          <Box
            flexGrow="1"
            display="flex"
            alignItems="center"
            onClick={() => setOpenCustomPayments(!openCustomPayments)}
          >
            <Typography ml={1} color="secondary" className="link" variant="h5">
              {t('channel.coaches.customPayments').toUpperCase()}
            </Typography>
          </Box>
        </Box>
        <Collapse in={openCustomPayments}>
          <CoachProfileCustomPayments user={user} />
        </Collapse>
      </Paper>
    </Box>
  );
};

export default DashboardCoaches;
