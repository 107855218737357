import React, { useEffect, useState } from 'react';
import { Box, Tab, Tabs } from '@material-ui/core';
import Paper from 'core/ui/Paper';
import ResultPayments from 'components/Channel/Manage/Tabs/Reports/Payments/ResultPayments';
import Modules from 'modules';
import useSelectors from 'modules/map/useSelectors';
import useActions from 'modules/map/useActions';
import LoaderSm from 'core/ui/LoaderSm';
import { newChannelMembersModule } from 'modules/channelMembers/new';
import { ButtonPrimary } from 'core/ui/Button';
import { useTranslation } from 'react-i18next';
import useConfirmation from 'core/useConfirmation';
import { newPaymentsModule } from 'modules/payments/new';
import { useDispatch } from 'react-redux';
import notifications from 'modules/notifications';
import Checkbox from 'core/ui/Checkbox';

const MemberPayments = ({ user, channel }) => {
  const { t } = useTranslation();
  const [tab, setTab] = useState(0);
  const [monthlyRunPendingHold, setMonthlyRunPendingHold] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const { confirmation } = useConfirmation();
  const dispatch = useDispatch();
  const { request } = useActions();
  const loadingChargeHold = useSelectors(newPaymentsModule, 'loadingChargeHold');
  const loading = useSelectors(newChannelMembersModule, 'loading');
  const loadingUpdateMember = useSelectors(newChannelMembersModule, 'loadingUpdateMember');
  const memberPayments = useSelectors(newChannelMembersModule, 'memberPayments');
  const paginationPayments = useSelectors(newChannelMembersModule, 'paginationPayments');

  const getData = (page = 1) => {
    if (user.id) {
      request({
        action: Modules.newChannelMembers.actions.getMemberPayments,
        data: {
          userId: user?.id,
          channelId: channel?.id,
          page,
          perPage,
          pendingHold: tab,
        },
      });
    }
  };

  const holdCount = memberPayments?.filter((x) => x?.pendingHold)?.length;

  const chargeNow = () => {
    if (user.id) {
      const total = memberPayments
        .filter((x) => x.pendingHold)
        .reduce((acum, current) => acum + Number(current?.amount), 0);

      confirmation({
        yesClick: () => {
          request({
            action: Modules.newPayments.actions.chargeHoldPending,
            data: {
              memberId: user?.id,
              channelId: channel?.id,
            },
            options: {
              onSuccess: () => {
                getData();
              },
            },
          });
        },
        description: t('payments.hold.pending.run.message')
          .replace('#{total_transaction_count}', holdCount)
          .replace('#{total_amount)', total.toFixed(2)),
      });
    }
  };

  useEffect(() => {
    getData();
    setMonthlyRunPendingHold(user?.monthlyRunPendingHold);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, tab, perPage]);

  const updatePaymentUser = () => {
    setMonthlyRunPendingHold(!monthlyRunPendingHold);
    request({
      action: Modules.newChannelMembers.actions.updateMember,
      data: {
        channelId: channel?.id,
        userId: user.id,
        monthlyRunPendingHold: !monthlyRunPendingHold,
      },
      options: {
        onSuccess: () => {
          dispatch(notifications.success(t('channel.member.changed.success')));
        },
      },
    });
  };

  return (
    <Paper square style={{ position: 'relative' }}>
      <Tabs value={tab} indicatorColor="primary" textColor="primary" onChange={(e, v) => setTab(v)}>
        <Tab label="Payments" value={0} />
        <Tab label="Pending Hold Payments" value={1} />
      </Tabs>
      {tab === 1 && (
        <Box display="flex" alignItems="center" style={{ position: 'absolute', right: 10, top: 5 }}>
          <LoaderSm loading={loadingUpdateMember} />
          <Box mr={5}>
            <Checkbox
              label={t('channel.pendingHold.runMonthly')}
              checked={monthlyRunPendingHold}
              onClick={() => updatePaymentUser()}
              disabled={loadingUpdateMember}
            />
          </Box>
          {holdCount > 0 && (
            <ButtonPrimary loading={loadingChargeHold} onClick={() => chargeNow()}>
              <LoaderSm loading={loadingChargeHold} />
              {t('button.chargeHoldTransactions')}
            </ButtonPrimary>
          )}
        </Box>
      )}
      <Box>
        {loadingChargeHold || (loading && <LoaderSm center loading />)}
        <ResultPayments
          channel={channel}
          rows={memberPayments}
          chartData={null}
          getReport={(p) => getData(p)}
          noHeader
          pagination={paginationPayments}
          perPage={perPage}
          setPerPage={setPerPage}
        />
      </Box>
    </Paper>
  );
};

export default MemberPayments;
