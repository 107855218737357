/* eslint-disable react/prop-types */
import React from 'react';
import { labelLight, secondary } from 'core/ui/Colors';
import Box from 'core/ui/Box';
import FlagCountry from 'core/ui/FlagCountry';
import Typography from 'core/ui/Typography';
import Checkbox from 'core/ui/Checkbox';

export default ({ checked, onClick, item }) => (
  <Box onClick={() => onClick(checked, item?.team)} className="link">
    <Box px={2} display="flex" alignItems="center" className="gap-5">
      <Box>
        <Checkbox checked={checked} />
      </Box>
      <Box py={2} flexGrow="1" display="flex" alignItems="center">
        <Typography mr={3} variant="h5">
          {item.position}
        </Typography>
        <FlagCountry flag={item.team.country.flag} size={40} />
        {item.regType === 'team' ? (
          <Typography ml={2} noWrap style={{ color: labelLight }}>
            {item.team.teamName}
          </Typography>
        ) : (
          <Box ml={2}>
            <Typography noWrap style={{ color: labelLight }}>
              {item.team.firstName}
            </Typography>
            <Typography noWrap style={{ color: secondary }}>
              {item.team.lastName}
            </Typography>
          </Box>
        )}
      </Box>
      <Box>
        <Typography>{item?.team?.division?.name}</Typography>
      </Box>
    </Box>
  </Box>
);
