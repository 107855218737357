import React, { useContext, useEffect, useState } from 'react';
import Box from 'core/ui/Box';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import SFDataTable from 'core/ui/DataTable';
import Typography from 'core/ui/Typography';
import FlagCountry from 'core/ui/FlagCountry';
import { labelLight } from 'core/ui/Colors';
import useSelectors from 'modules/map/useSelectors';
import { useTranslation } from 'react-i18next';
import LoaderSm from 'core/ui/LoaderSm';
import { usersContractsModule } from 'modules/usersContracts';
import { CompetitionContext } from '../../index';

const WaiversUsers = () => {
  const { t } = useTranslation();
  const formContext = useContext(CompetitionContext);
  const [users, setUsers] = useState([]);
  const loadingInvites = useSelectors(usersContractsModule, 'loading');

  const { request } = useActions();

  const refreshWaiversUsers = () => {
    request({
      action: Modules.usersContracts.actions.getOwnerableUsers,
      data: {
        ownerableId: formContext?.competition?.id,
        ownerableType: 'Competition',
      },
      options: {
        onSuccess: (data) => {
          setUsers(data);
        },
      },
    });
  };

  useEffect(() => {
    if (formContext?.competition?.id) {
      refreshWaiversUsers(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formContext?.competition?.id]);

  const columns = [
    {
      name: 'User',
      selector: (row) => row?.user?.name,
      sortable: true,
      cell: (row) => (
        <Box flexGrow="1" display="flex" alignItems="center">
          <FlagCountry flag={row?.user?.country?.flag} size={40} />

          <Box ml={2}>
            <Typography noWrap style={{ color: labelLight }}>
              {row?.user?.name}
            </Typography>
            <Typography noWrap style={{ color: labelLight }}>
              {row?.user?.contactEmail}
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      name: 'Signed',
      selector: (row) => (row?.accepted ? 'YES' : 'NO'),
      sortable: true,
      cell: (row) => <Typography>{row?.accepted ? 'YES' : 'NO'}</Typography>,
    },
  ];

  return (
    <Box>
      <Box p={3} mt={3}>
        <Box display="flex" className="gap-10">
          <Typography variant="h5" mb={2}>
            {t('label.waiversUsers')}
          </Typography>
          <LoaderSm loading={loadingInvites} />
        </Box>
        <SFDataTable
          columns={columns}
          data={users}
          showExport
          exportData={users?.map((x) => ({
            userName: x.user?.name,
            userEmail: x.user?.contactEmail,
            userPhone: x.user?.phone,
            division: x.competitionDivision?.name,
          }))}
          noHeader
          // pagination
          // onChangePage={(p) => refreshWaiversUsers(p)}
          // paginationServer
          // paginationDefaultPage={pagination?.currentPage}
          // paginationTotalRows={pagination?.totalCount}
          // onChangeRowsPerPage={(newValue) => {
          //   setPerPage(newValue);
          // }}
        />
      </Box>
    </Box>
  );
};

export default WaiversUsers;
