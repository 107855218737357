import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { channelsActions, channelsSelectors } from 'modules/channels';
import Box from 'core/ui/Box';
import Memberships from 'components/Channel/Show/Tabs/Memberships';
import LoaderSm from 'core/ui/LoaderSm';

const LiveInvitePlans = ({ purchasedPlanCallback }) => {
  const dispatch = useDispatch();
  const channel = useSelector((state) => channelsSelectors.getChannel(state));
  const loading = useSelector((state) => channelsSelectors.getLoadingChannel(state));

  useEffect(() => {
    dispatch(channelsActions.getChannelStart(2));
  }, [dispatch]);

  return (
    <Box my={1}>
      {!loading && channel && channel.id ? (
        <Memberships
          hideCurrent
          channel={{
            ...channel,
            membershipPlans: channel.membershipPlans.filter((x) => !!x.liveAccess),
          }}
          isCoach={false}
          purchasedPlanCallback={purchasedPlanCallback}
        />
      ) : (
        <Box display="flex" justifyContent="center" itemsCenter="center" p={5}>
          <LoaderSm loading />
        </Box>
      )}
    </Box>
  );
};

LiveInvitePlans.propTypes = {};

export default LiveInvitePlans;
