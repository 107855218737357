/* eslint-disable no-return-assign */
import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';

import propTypes from 'prop-types';
import Box from 'core/ui/Box';
import Typography from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import { ButtonPrimary } from 'core/ui/Button';
import ConfirmDialog from 'core/ui/ConfirmDialog';
import { channelsActions } from 'modules/channels';
import { useDispatch } from 'react-redux';
import Texture from 'core/ui/Texture';
import WarningIcon from '@material-ui/icons/Warning';

export default function ModalNotifyPlanChannel({ setOpen, channel }) {
  const { t } = useTranslation();
  const [confirmCancel, setConfirmCancel] = useState({ open: false });
  const dispatch = useDispatch();

  const cancelChannel = () => {
    dispatch(
      channelsActions.cancelChannelStart({
        channelId: channel.id,
        messageSuccess: t('message.success.cancel.channel'),
        callback: () => {
          setOpen(false);
        },
      }),
    );
  };

  return (
    <div>
      {channel && (
        <Dialog
          PaperProps={{ style: { borderRadius: 5 } }}
          maxWidth="xs"
          scroll="body"
          open
          onClose={() => setOpen(false)}
        >
          <ConfirmDialog
            open={confirmCancel.open}
            confirm={() => cancelChannel()}
            cancel={() => setConfirmCancel({ open: false })}
            description={t('modal.notify.cancel.channel')}
            labelConfirm={t('button.confirm')}
            labelCancel={t('button.cancel')}
          />
          <Texture>
            <Box display="flex" flexDirection="column" alignItems="center" p={8}>
              <Box display="flex" justifyContent="center">
                <WarningIcon color="primary" style={{ fontSize: 35 }} />
              </Box>
              <Typography mt={1} variant="h6" mb={3} color="primary">
                {t('channel.message.activePlan').replace('{channel_name}', channel.name)}
              </Typography>

              <Box display="flex" justifyContent="center" flexWrap="wrap">
                <ButtonPrimary
                  m={1}
                  onClick={() => setConfirmCancel({ open: true })}
                  // style={{ width: 190 }}
                >
                  {t('button.deleteChannel')}
                </ButtonPrimary>
                <ButtonPrimary
                  m={1}
                  onClick={() => (window.location = `/channel/manage/${channel.id}/plans`)}
                  // style={{ width: 190 }}
                >
                  <Typography noWrap>{t('button.updateChannel')}</Typography>
                </ButtonPrimary>
              </Box>
            </Box>
          </Texture>
        </Dialog>
      )}
    </div>
  );
}

ModalNotifyPlanChannel.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  channel: propTypes.object.isRequired,
  setOpen: propTypes.func.isRequired,
};

ModalNotifyPlanChannel.defaultProps = {};
