import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import { ButtonPrimary } from 'core/ui/Button';
import { Search } from '@material-ui/icons';
import { adminReportsModule } from 'modules/adminReports';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import useSelectors from 'modules/map/useSelectors';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import Toggle from 'core/ui/Toggle';
import ResultChannels from './ResultChannels';

const Overview = () => {
  const { t } = useTranslation();
  const { request } = useActions();
  const loading = useSelectors(adminReportsModule, 'loading');
  const [channels, setChannels] = useState([]);
  const [pagination, setPagination] = useState(null);
  const [query, setQuery] = useState();
  const [filterPlan, setFilterPLan] = useState('active');
  const [perPage, setPerPage] = useState(10);

  const getReport = (newPage = 1) => {
    request({
      action: Modules.adminReports.actions.getOverviewReport,
      data: {
        page: newPage,
        perPage,
        query,
        filterPlan,
      },
      options: {
        onSuccess: (resp, pag) => {
          setChannels(resp);
          setPagination(pag);
        },
      },
    });
  };

  useEffect(() => {
    getReport(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box pb={5} pt={5}>
      <Box>
        <Box display="flex" mb={3} alignItems="flex-end" justifyContent="flex-end" flexWrap="wrap">
          <Box flexGrow={1}>
            <TextFieldRounded
              value={query}
              onChange={(v) => setQuery(v)}
              placeholder={t('search.channel.placeholder.mobile')}
              bordered
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  getReport(1);
                }
              }}
            />
          </Box>
          <Box mx={2}>
            <Toggle
              options={[
                {
                  label: 'All',
                  value: 'all',
                  width: 120,
                },
                {
                  label: 'Active Plan',
                  value: 'active',
                  width: 140,
                },
                {
                  label: 'Inactive Plan',
                  value: 'inactive',
                  width: 140,
                },
              ]}
              fontSize="xSmall"
              value={filterPlan}
              onChange={(value) => setFilterPLan(value)}
            />
          </Box>
          <Box ml={3}>
            <ButtonPrimary disabled={loading} onClick={() => getReport(1)}>
              <Search style={{ color: 'white' }} fontSize="small" />
              {t('button.update')}&nbsp;
            </ButtonPrimary>
          </Box>
        </Box>

        <Box>
          {channels && (
            <ResultChannels
              channels={channels || []}
              pagination={pagination}
              search={getReport}
              setPerPage={setPerPage}
              perPage={perPage}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

Overview.propTypes = {};

Overview.defaultProps = {};

export default Overview;
