import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonPrimary } from 'core/ui/Button';
import { CARD_POINTE_FORM_URL } from 'core/env';
import Box from 'core/ui/Box';
import { secondary } from 'core/ui/Colors';
import Typography, { fontStyle } from 'core/ui/Typography';
import Toggle from 'core/ui/Toggle';
import MIcon from 'core/ui/MIcon';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import { PaymentMethodViewType } from '../Selector';

const dataInitialState = { message: '', expiry: '' };

const FormCardPointe = ({ userId, closeModal, setSelectedPaymentMethod, setView, embedView }) => {
  const { t } = useTranslation();
  const { request } = useActions();
  const [method, setMethod] = useState();
  const [accountType, setAccountType] = useState('savings');

  const [data, setData] = useState(dataInitialState);
  const [showErrors, setShowErrors] = useState(false);

  const addPaymentMethod = () => {
    setShowErrors(true);

    if (!data.message) return;

    const pmData = {
      userId,
      ownerableType: 'User',
      ownerableId: userId,
      cardpointeAccount: data.message,
      cardpointeExpiry: data.expiry,
      method,
      achType: method === 'ach' ? 'us_bank_account' : null,
      accountType,
    };

    if (embedView && setSelectedPaymentMethod) {
      setSelectedPaymentMethod(pmData);
      return;
    }

    request({
      action: Modules.ownerablePaymentMethods.actions.createCardPointePaymentMethod,
      data: pmData,
      options: {
        onSuccess: (data) => {
          if (setSelectedPaymentMethod) {
            setSelectedPaymentMethod(data);
          }

          if (setView) {
            setView(PaymentMethodViewType.list);
          }
          request({
            action: Modules.userPaymentMethods.actions.addPaymentMethod,
            data,
          });
          if (closeModal) {
            closeModal();
          }
        },
      },
    });
  };

  useEffect(() => {
    setData(dataInitialState);
    setShowErrors(false);
  }, [method]);

  return (
    <>
      <Box display="flex" justifyContent="center" alignItems="center" mb={2}>
        <Toggle
          options={[
            {
              label: (
                <Box display="flex" justifyContent="center" alignItems="center">
                  <MIcon icon="payment" styles={{ color: 'white' }} />
                  <Typography style={fontStyle.medium}>
                    &nbsp;{t('paymentMethod.method.card').toUpperCase()}
                  </Typography>
                </Box>
              ),
              value: 'card',
              width: 120,
            },
            {
              label: (
                <Box display="flex" justifyContent="center" alignItems="center">
                  <MIcon icon="account_balance" styles={{ color: 'white' }} />
                  <Typography style={fontStyle.medium}>
                    &nbsp;{t('paymentMethod.method.ach').toUpperCase()}
                  </Typography>
                </Box>
              ),
              value: 'ach',
              width: 140,
            },
          ]}
          fontSize="xSmall"
          value={method}
          onChange={(value) => setMethod(value)}
        />
      </Box>

      {method && (
        <div>
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            {method === 'ach' && (
              <>
                <Typography m={2} style={{ color: 'white' }}>
                  {
                    'Please enter your routing and account in the same field like <routing>/<account>.'
                  }
                </Typography>

                <Box mb={1} mt={2}>
                  <Toggle
                    options={[
                      {
                        label: t('paymentMethod.accountType.savings'),
                        value: 'savings',
                        width: 120,
                      },
                      {
                        label: t('paymentMethod.accountType.checkings'),
                        value: 'checking',
                        width: 120,
                      },
                    ]}
                    value={accountType}
                    onChange={(value) => setAccountType(value)}
                  />
                </Box>
              </>
            )}

            <CardPointeIframe method={method} onMessage={setData} />
          </Box>

          {showErrors && (
            <div className="tw-mb-2.5">
              {data.errorMessage && (
                <div className="tw-text-red-500">
                  Error: <span className="tw-text-white">{data.errorMessage}</span>
                </div>
              )}

              {data.errorCode && data.errorCode !== '0' && (
                <div className="tw-text-red-500">
                  Error Code: <span className="tw-text-white">{data.errorCode}</span>
                </div>
              )}
            </div>
          )}

          <Box px={10}>
            <ButtonPrimary fullWidth onClick={addPaymentMethod}>
              Save
            </ButtonPrimary>
          </Box>
        </div>
      )}
    </>
  );
};

const CardPointeIframe = ({ method, onMessage }) => {
  useEffect(() => {
    function handleEvent(event) {
      if (!event.origin.includes(CARD_POINTE_FORM_URL)) return;

      onMessage?.(JSON.parse(event.data));
    }

    window.addEventListener('message', handleEvent);

    return () => window.removeEventListener('message', handleEvent);
  }, [onMessage]);

  const cardParams =
    method === 'card'
      ? 'useexpiry=true&usecvv=true&invalidcreditcardevent=true&invalidcvvevent=true&invalidexpiryevent=true'
      : 'fullmobilekeyboard=true&invalidinputevent=true';

  return (
    <iframe
      title="CardPointe"
      src={`${CARD_POINTE_FORM_URL}/itoke/ajax-tokenizer.html?${cardParams}&autofocus=true&enhancedresponse=true&orientation=vertical&css=.error%7Bcolor:red;%7Dlabel%7Bcolor:white;%7Dinput%7Bborder-radius:5px;%20margin-bottom:5px;%20width:100%25;%20height:28px;%7Dselect%7Bborder-radius:5px;%20margin-bottom:5px;%20width:100%25;%20height:28px;%7D`}
      style={{
        height: method === 'ach' ? 80 : 230,
        width: '90%',
        backgroundColor: secondary,
      }}
      frameBorder="0"
      scrolling="no"
    />
  );
};

FormCardPointe.defaultProps = {};

export default FormCardPointe;
