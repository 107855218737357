import React, { useContext } from 'react';
import { Dialog } from '@material-ui/core';
import { LayoutContext } from 'pages/shared/Layout';
import { secondary } from 'core/ui/Colors';
import Form from './Form';

export default function ModalLogin({ callback }) {
  const layoutContext = useContext(LayoutContext);

  return (
    <Dialog
      open
      maxWidth="xs"
      fullWidth
      PaperProps={{ style: { backgroundColor: secondary } }}
      onClose={() => layoutContext?.setModalLogin({ open: false })}
    >
      <Form
        fromModal
        callback={() => (callback ? callback() : layoutContext.setModalLogin({ open: false }))}
      />
    </Dialog>
  );
}
