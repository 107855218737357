import React from 'react';
import Box from 'core/ui/Box';

import FormSection from 'components/Workout/Form/Section/FormSection';
import { Dialog } from '@material-ui/core';
import Texture from 'core/ui/Texture';

const ModalFormSection = ({
  modalSection,
  setModalSection,
  sections,
  channelId,
  partnerId,
  setSections,
  register,
  programs,
  custom,
  isVideoClass,
  partnerProgramming,
  isCompetition,
  editingRecurring,
  callbackSave,
}) => (
  <Dialog
    open={modalSection?.open}
    maxWidth="md"
    fullWidth
    scroll="body"
    onClose={() => setModalSection({ open: false })}
  >
    <Texture>
      <Box p={5}>
        <FormSection
          sections={sections}
          currentSection={modalSection?.currentSection}
          setSections={setSections}
          register={register}
          isCompetition={isCompetition}
          channelId={channelId}
          partnerId={partnerId}
          partnerPrograms={programs}
          isVideoClass={isVideoClass}
          partnerProgramming={partnerProgramming}
          showSaveButton
          editingRecurring={editingRecurring}
          closeModalSection={() => {
            setModalSection({ open: false });
          }}
          custom={custom}
          callbackSave={callbackSave}
        />
      </Box>
    </Texture>
  </Dialog>
);

export default ModalFormSection;

ModalFormSection.defaultProps = {};
