/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import Typography from 'core/ui/Typography';
import { labelLight, secondary } from 'core/ui/Colors';
import TagsChosen, { Tag } from 'core/ui/TagsChosen';
import { Add, Check } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { competitionsActions } from 'modules/competitions';
import { Dialog } from '@material-ui/core';
import { ButtonPrimary } from 'core/ui/Button';
import Texture from 'core/ui/Texture';
import { competitionsUsersModule } from 'modules/competitionsUsers';
import useSelectors from 'modules/map/useSelectors';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import notifications from 'modules/notifications';
import { dropdownWrapper } from './style';

export default function YourTags({ userId, buttonColor, competitionId, userTags, tags, setTags }) {
  const { t } = useTranslation();
  const { request } = useActions();
  const dispatch = useDispatch();
  const [opened, setOpened] = useState(false);
  const [currentTags, setCurrentTags] = useState([]);
  const loadingEditTeam = useSelectors(competitionsUsersModule, 'loading');

  useEffect(() => {
    if (userTags) {
      setCurrentTags([...userTags]);
    }
  }, [userTags]);

  const editTeam = (data, callback) => {
    request({
      action: Modules.competitionsUsers.actions.update,
      data: { ...data, regType: 'competitor' },
      options: {
        onSuccess: () => {
          callback();
        },
        onError: (error) => {
          dispatch(notifications.error(error));
        },
      },
    });
  };

  const removeTag = (tagName) => {
    if (loadingEditTeam) {
      return;
    }
    const newTags = currentTags.filter((x) => (x.name || x) != tagName);
    setCurrentTags(newTags);

    const dataEdit = {
      competitionId,
      userId,
      tagsOnly: true,
      tags:
        newTags &&
        newTags.map((x) => ({
          name: x.name ? x.name : x,
        })),
    };

    editTeam(dataEdit, () => {
      dispatch(competitionsActions.getCompetitionStart(competitionId));
      setOpened(false);
    });
  };

  const confirmTags = () => {
    const dataEdit = {
      competitionId,
      userId,
      tagsOnly: true,
      tags:
        currentTags &&
        currentTags.map((x) => ({
          name: x.name ? x.name : x,
        })),
    };
    editTeam(dataEdit, () => {
      dispatch(competitionsActions.getCompetitionStart(competitionId));
      setOpened(false);
    });
  };

  return (
    <Box>
      <Typography variant="body2" style={{ color: labelLight }}>
        {t('competition.yourTags')}
      </Typography>
      <Typography ml={1} variant="caption" color="secondary" mb={1}>
        {t('competition.registration.help.tags')}
      </Typography>

      <Dialog maxWidth="sm" fullWidth open={opened} onClose={() => setOpened(false)}>
        <Texture>
          <Box p={5}>
            <Typography align="center" variant="h5" mb={3} color="secondary">
              {t('competition.yourTags')}
            </Typography>
            <Box mb={2}>
              <TagsChosen
                placeholder={t('competition.registration.label.tags')}
                setValue={(values) => setCurrentTags([...values])}
                limit={30}
                lightColor={buttonColor}
                kind="competition"
                val={currentTags ? [...currentTags] : []}
                style={dropdownWrapper}
                radius={5}
                iconTag={
                  <Typography ml={1.5} variant="h6" style={{ color: 'white' }}>
                    #
                  </Typography>
                }
              />
            </Box>

            <ButtonPrimary
              fullWidth
              disabled={loadingEditTeam}
              style={{ backgroundColor: buttonColor }}
              onClick={() => confirmTags()}
            >
              {t('button.confirm')}
            </ButtonPrimary>
          </Box>
        </Texture>
      </Dialog>

      <Box display="flex" flexWrap="wrap">
        {userTags &&
          userTags.map((tag) => {
            const selected = tags && !!tags.find((x) => (x.name || x) == (tag.name || tag));
            return (
              <Box
                className="hover"
                onClick={() =>
                  selected
                    ? setTags((prev) => prev.filter((x) => (x.name || x) != tag.name || tag))
                    : setTags((prev) => (prev ? [...prev, tag.name || tag] : [tag.name || tag]))
                }
              >
                <Tag
                  label={tag.name || tag}
                  lightColor={buttonColor}
                  iconTag={
                    !selected ? (
                      <Typography ml={1.5} variant="h6" style={{ color: 'white' }}>
                        #
                      </Typography>
                    ) : (
                      <Typography variant="h6" style={{ color: 'white' }}>
                        <Check style={{ fontSize: 15, color: 'white' }} />
                      </Typography>
                    )
                  }
                  onDelete={() => !loadingEditTeam && removeTag(tag.name || tag)}
                />
              </Box>
            );
          })}

        <Box
          display="flex"
          alignItems="center"
          justyfyContent="center"
          px={3}
          mt={1}
          className="hover"
          style={{ backgroundColor: secondary, borderRadius: 5, height: 30 }}
          onClick={() => setOpened(true)}
        >
          <Add style={{ color: 'white', fontSize: 20 }} />

          <Typography ml={1} variant="caption" style={{ color: 'white' }}>
            {t('competition.addTags')}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

YourTags.defaultProps = {
  pay: true,
  open: false,
  addTeamManually: false,
  setOpen: null,
  coachTeam: false,
};
