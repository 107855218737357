import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import { Checkbox } from '@material-ui/core';
import Typography, { fontStyle } from 'core/ui/Typography';
import { timers, typeTimer } from 'core/utils/consts';
import TimeSelector from 'core/ui/TimeSelector';
import SelectBox from 'core/ui/SelectBox';
import Grid from 'core/ui/Grid';
import SelectBoxObject from 'core/ui/SelectBoxObject';

const FormSectionTimer = ({
  isVideoClass,
  timerData,
  setTimerData,
  disabled,
  bordered,
  isCompetition,
}) => {
  const { t } = useTranslation();

  const checkCustomTimer = (v) => {
    const arr = [];
    if (v) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < timerData.rounds; i++) {
        const tm = timerData.customIntervalTimers ? timerData.customIntervalTimers[i] : null;
        arr.push(tm || { work: null, rest: null });
      }
      setTimerData((prev) => ({ ...prev, customInterval: v, customIntervalTimers: arr }));
    } else {
      setTimerData((prev) => ({ ...prev, customInterval: v, customIntervalTimers: [] }));
    }
  };

  const changeCustomTimer = (index, prop, value) => {
    const arr = timerData.customIntervalTimers.map((item, i) => {
      const itTimer = { ...item };
      if (i === index) {
        itTimer[prop] = value;
      }
      return itTimer;
    });

    setTimerData((prev) => ({ ...prev, customIntervalTimers: arr }));
  };

  useEffect(() => {
    if (timerData.customInterval) {
      checkCustomTimer(timerData.customInterval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timerData.rounds]);

  useEffect(() => {
    if (timerData.unlimited) {
      setTimerData((prev) => ({ ...prev, count: 'UP', work: null }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timerData.unlimited]);

  useEffect(() => {
    if (timerData.type !== typeTimer.forTime) {
      setTimerData((prev) => ({ ...prev, unlimited: false }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timerData.type]);

  return (
    <>
      <Box mb={2}>
        <SelectBoxObject
          label={t('workout.section.label.timer').toUpperCase()}
          options={timers}
          value={timerData.type}
          disabled={disabled}
          setValue={(timer) => setTimerData((prev) => ({ ...prev, type: timer }))}
          bordered={bordered}
        />
      </Box>
      {timerData.type !== 'none' && (
        <>
          <Grid container spacing={3}>
            {(timerData.type === typeTimer.interval ||
              timerData.type === typeTimer.emom ||
              timerData.type === typeTimer.tabata) && (
              <Grid
                item
                xs={12}
                sm={12}
                md={timerData.customInterval ? 4 : 2}
                lg={timerData.customInterval ? 4 : 2}
              >
                <Box flexGrow="1">
                  <TextFieldRounded
                    type="number"
                    pattern="[0-9]*"
                    disabled={disabled}
                    placeholder={t('workout.section.label.rounds')}
                    label={t('workout.section.label.rounds').toUpperCase()}
                    value={`${timerData.rounds}`}
                    onChange={(v) => setTimerData((prev) => ({ ...prev, rounds: v }))}
                    bordered={bordered}
                  />
                </Box>
              </Grid>
            )}

            {timerData.type === typeTimer.forTime && (
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography style={fontStyle.label} align="center" variant="body1">
                    {t('workout.section.timer.unlimited').toUpperCase()}
                  </Typography>
                  <Box display="flex" alignItems="center" justifyContent="center">
                    <Checkbox
                      checked={!!timerData.unlimited || false}
                      disabled={disabled}
                      onChange={() =>
                        setTimerData((prev) => ({
                          ...prev,
                          unlimited: !prev.unlimited,
                        }))
                      }
                    />
                  </Box>
                </Box>
              </Grid>
            )}

            {!timerData.unlimited && (!timerData.customInterval || timerData.rounds === 1) && (
              <>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <Box flexGrow="1">
                    <TimeSelector
                      disabled={disabled}
                      time={timerData.work || {}}
                      setTime={(time) => setTimerData((prev) => ({ ...prev, work: time }))}
                      label={t('workout.section.label.workTime').toUpperCase()}
                      bordered={bordered}
                      hideHour={isCompetition}
                    />
                  </Box>
                </Grid>
                {(timerData.type === typeTimer.tabata || timerData.type === typeTimer.interval) && (
                  <Grid item xs={12} sm={6} md={3} lg={3}>
                    <Box flexGrow="1">
                      <TimeSelector
                        disabled={disabled || timerData.rounds <= 1}
                        time={timerData.rest || {}}
                        setTime={(time) => setTimerData((prev) => ({ ...prev, rest: time }))}
                        label={t('workout.section.label.restTime').toUpperCase()}
                        bordered={bordered}
                      />
                    </Box>
                  </Grid>
                )}
              </>
            )}

            {timerData.rounds && timerData.rounds > 1 && (
              <Grid item xs={12} sm={12} md={1} lg={1}>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography style={fontStyle.label} variant="body1">
                    {t('workout.section.timer.custom').toUpperCase()}
                  </Typography>
                  <Box display="flex" alignItems="center" justifyContent="center">
                    <Checkbox
                      disabled={disabled}
                      checked={!!timerData.customInterval || false}
                      onChange={() => checkCustomTimer(!timerData.customInterval)}
                    />
                  </Box>
                </Box>
              </Grid>
            )}

            {timerData.type !== 'none' && (
              <Grid
                item
                xs={12}
                sm={12}
                md={timerData.customInterval ? 4 : 3}
                lg={timerData.customInterval ? 4 : 3}
              >
                <Box flexGrow="1">
                  <SelectBox
                    label={t('workout.section.label.count').toUpperCase()}
                    options={['UP', 'DOWN']}
                    disabled={disabled || !!timerData.unlimited}
                    defaultValue="UP"
                    value={(timerData.count && timerData.count.toUpperCase()) || 'UP'}
                    setValue={(v) => {
                      setTimerData((prev) => ({ ...prev, count: v }));
                    }}
                    bordered={bordered}
                  />
                </Box>
              </Grid>
            )}
            {timerData.type !== 'none' && (
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography style={fontStyle.label} align="center" variant="body1">
                    {t('workout.section.timer.silenceTimer').toUpperCase()}
                  </Typography>
                  <Box display="flex" alignItems="center" justifyContent="center">
                    <Checkbox
                      checked={!!timerData.silenceTimer || false}
                      disabled={disabled}
                      onChange={() =>
                        setTimerData((prev) => ({
                          ...prev,
                          silenceTimer: !prev.silenceTimer,
                        }))
                      }
                    />
                  </Box>
                </Box>
              </Grid>
            )}
          </Grid>

          {isVideoClass && (
            <Box flexGrow="1" mt={3}>
              <TimeSelector
                time={timerData.timeStart || {}}
                setTime={(time) => setTimerData((prev) => ({ ...prev, timeStart: time }))}
                label={t('workout.section.label.timeStart').toUpperCase()}
                disabled={disabled}
                bordered={bordered}
                hideHour={isCompetition}
              />
            </Box>
          )}

          {timerData.customInterval && timerData.rounds > 1 && (
            <Box display="flex" mt={2} flexDirection="column">
              {timerData.customIntervalTimers &&
                timerData.customIntervalTimers.map((cTimer, index) => (
                  <Box display="flex" alignItems="center" key={index.toString()} mb={2}>
                    <Box alignItems="center" mr={3}>
                      <Typography color="primary" variant="body2">
                        {t('round')}&nbsp;{index + 1}
                      </Typography>
                    </Box>
                    <Box flexGrow="1">
                      <TimeSelector
                        time={cTimer.work || {}}
                        setTime={(time) => changeCustomTimer(index, 'work', time)}
                        label={t('workout.section.label.workTime').toUpperCase()}
                        bordered={bordered}
                        hideHour={isCompetition}
                      />
                    </Box>

                    {index < timerData.customIntervalTimers.length - 1 && (
                      <Box flexGrow="1" ml={2}>
                        <TimeSelector
                          disabled={disabled || timerData.rounds <= 1}
                          time={cTimer.rest || {}}
                          setTime={(time) => changeCustomTimer(index, 'rest', time)}
                          label={t('workout.section.label.restTime').toUpperCase()}
                          bordered={bordered}
                          hideHour={isCompetition}
                        />
                      </Box>
                    )}
                  </Box>
                ))}
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default FormSectionTimer;

FormSectionTimer.defaultProps = {
  isVideoClass: false,
  bordered: false,
};
