/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import Typography from 'core/ui/Typography';
import { ButtonPrimary } from 'core/ui/Button';
import { useSelector } from 'react-redux';
import LoaderSm from 'core/ui/LoaderSm';
import ReactPlayer from 'react-player';
import { sessionSelectors } from 'modules/session';
import Modules from 'modules';
import { usersContractsModule } from 'modules/usersContracts';
import useSelectors from 'modules/map/useSelectors';
import useActions from 'modules/map/useActions';

export default function ChannelVideoRequire({ channel, userId, setVideosAccepted, close }) {
  const { t } = useTranslation();
  const isCoach = useSelector((state) => sessionSelectors.getIsCoach(state));
  const loading = useSelectors(usersContractsModule, 'loading');
  const { request } = useActions();

  const putChannelWaiver = (accept) => {
    if (!userId) {
      return;
    }
    request({
      action: Modules.usersContracts.actions.acceptUsersContract,
      data: {
        userId,
        ownerableId: channel?.id,
        ownerableType: 'Channel',
        accept,
        kind: 'videos',
      },
      options: {
        onSuccess: () => {
          setVideosAccepted(accept);
          if (accept) {
            close();
          }
        },
      },
    });
  };

  useEffect(() => {
    request({
      action: Modules.usersContracts.actions.checkUsersContract,
      data: {
        userId,
        ownerableId: channel?.id,
        ownerableType: 'Channel',
        kind: 'videos',
      },
      options: {
        onSuccess: (data) => {
          setVideosAccepted(data?.accepted);
        },
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const buttonColor = channel?.customColors?.buttonColor;

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" p={10}>
        <LoaderSm loading width={40} />
      </Box>
    );
  }

  return (
    <Box>
      <Typography mb={3} align="center" color="secondary" variant="h3">
        {t('channel.video.accept.title')}
      </Typography>
      <Box my={3}>
        <Typography mb={3} align="center" color="textSecondary" variant="h6">
          {t('channel.videos.accept.description').replace('#{channel_name}', channel.name)}
        </Typography>
      </Box>
      <Box>
        <Box style={{ position: 'relative' }} className="container-aspect container-aspect-47">
          <Box className="item-aspect">
            <ReactPlayer
              controls
              width="100%"
              height="100%"
              onEnded={() => putChannelWaiver(1)}
              url={!isCoach ? channel.requiredVideoAthlete : channel.requiredVideoCoach}
            />
          </Box>
        </Box>
      </Box>
      <Box display="flex" p={5}>
        <Box mr={2} flexGrow="1">
          <ButtonPrimary buttonColor={buttonColor} fullWidth onClick={() => putChannelWaiver(0)}>
            {t('button.noThanks')}
          </ButtonPrimary>
        </Box>
      </Box>
    </Box>
  );
}
