/* eslint-disable no-use-before-define */
import React from 'react';
import PropTypes from 'prop-types';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import NoSsr from '@material-ui/core/NoSsr';
import CloseIcon from '@material-ui/icons/Close';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import styled from 'styled-components';
import { InputBase, Popper } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { movementsSelectors, movementsActions } from 'modules/movements';
import { VideoLibrary } from '@material-ui/icons';
import Box from '../Box';

const InputWrapper = styled('div')`
  width: 100%;
  background-color: #fff;
  padding: 4px;
  display: flex;
  flex-wrap: wrap;
  border-radius: 5px;
  min-height: 45px;
  & input {
    font-size: 12px;
    height: 30px;
    box-sizing: border-box;
    padding: 4px 6px;
    border-radius: 5px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
`;

const Tag = styled(({ label, onDelete, ...props }) => (
  <div {...props}>
    <span>{label}</span>
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      className="change-icon"
      style={{ background: '#404041', height: 30, width: 25 }}
    >
      <LocalOfferIcon style={{ color: 'white' }} onClick={onDelete} />
      <CloseIcon style={{ color: 'white', fontSize: 14 }} onClick={onDelete} />
    </Box>
  </div>
))`
  display: flex;
  align-items: center;
  margin: 4px;
  background-color: #bbbdbf;
  border-radius: 5px;
  box-sizing: content-box;
  outline: 0;
  overflow: hidden;
  padding-left: 5px;
  font-family: Roboto;
  font-weight: 500;
  font-size: 12px;

  &:focus {
    border-color: #40a9ff;
    background-color: #e6f7ff;
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 10px;
  }

  .change-icon > .MuiSvgIcon-root + .MuiSvgIcon-root,
  .change-icon:hover > .MuiSvgIcon-root {
    display: none;
  }
  .change-icon:hover > .MuiSvgIcon-root + .MuiSvgIcon-root {
    display: inherit;
  }

  & svg {
    font-size: 12px;
    cursor: pointer;
    padding: 4px;
  }
`;

const Listbox = styled('ul')`
  padding: 0;
  list-style: none;
  overflow: auto;
  max-height: 200px;
  max-width: 200px;
  background: #fff;
  z-index: 1000;

  & div {
    padding: 5px 12px;
    display: flex;
    font-size: 12px;
    font-family: Roboto;
    font-weight: 500;
    cursor: pointer;
    & span {
      flex-grow: 1;
    }
  }

  & div[aria-selected='true'] {
    background-color: #fafafa;
    color: #ec4242;
    & svg {
      color: #414042;
    }
  }

  & div[data-focus='true'] {
    color: #ec4242;
    cursor: pointer;

    & svg {
      color: #ec4242;
    }
  }
`;

const styleBoxList = {
  maxHeight: 250,
  backgroundColor: '#fff',
  padding: 5,
  borderRadius: '20px 20px 20px 20px',
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
  zIndex: 9999,
};

export default function MovementChosen({
  setValue,
  name,
  val,
  limit,
  placeholder,
  defaultValue,
  placement,
  ownerableId,
  ownerableType,
}) {
  const movements = useSelector((state) => movementsSelectors.getMovementsAutocomplete(state));
  const change = (evt, data) => {
    setValue(
      data.map((mov) => {
        let newMov = null;
        if (!mov?.id) {
          const movement = movements.find((x) => x?.name?.toLowerCase() === mov?.toLowerCase());
          newMov = movement ? { ...movement } : mov;
        } else {
          newMov = mov;
        }

        return newMov;
      }),
    );
  };
  const wRef = React.createRef();
  const dispatch = useDispatch();

  const searchMovement = (event) => {
    if (event) {
      dispatch(
        movementsActions.getMovementsAutocompleteStart({
          query: event.target.value,
          ownerableId,
          ownerableType,
        }),
      );
    }
  };

  const {
    getRootProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    value,
    focused,
    setAnchorEl,
    inputValue,
  } = useAutocomplete({
    defaultValue: [...defaultValue],
    value: val,
    multiple: true,
    // groupBy: (option) => option,
    freeSolo: true,
    options: [...movements],
    // value: defaultValue,
    onChange: change,
    getOptionLabel: (option) => option.name,
    onInputChange: searchMovement,
  });

  const input = getInputProps();

  const set = (option) => {
    const newOption = { ...option, embedVideo: option?.mediaFile?.url };

    setValue([...val, newOption]);
  };

  const handleBlur = () => {
    dispatch(movementsActions.clearMovementsAutocomplete());
    input.onBlur();
    input.onChange({ target: { value: '' } });
  };

  const sortedAsc = [...movements].sort((a, b) => {
    if (!a?.mediaFile?.url) {
      return 1;
    }

    if (!b?.mediaFile?.url) {
      return -1;
    }

    if (a?.mediaFile?.url === b?.mediaFile?.url) {
      return 0;
    }

    return a?.mediaFile?.url < b?.mediaFile?.url ? -1 : 1;
  });

  return (
    <NoSsr>
      <div>
        <div {...getRootProps()}>
          <InputWrapper ref={wRef} className={focused ? 'focused' : ''}>
            {value.map((option, index) => (
              <Tag label={option.name || option} {...getTagProps({ index })} />
            ))}
            <Box display="flex" flexGrow="1" ref={setAnchorEl} alignItems="center">
              <InputBase
                style={{ minWidth: 30, width: '100%' }}
                name={name}
                className={`${limit > 0 && value.length < limit ? '' : 'hide'} fontInput`}
                placeholder={value.length <= 0 ? placeholder : ''}
                {...getInputProps()}
                onBlur={handleBlur}
              />
            </Box>
          </InputWrapper>
        </div>
        {inputValue && movements.length > 0 ? (
          <Popper
            open
            anchorEl={input.ref.current}
            placement={placement}
            role={undefined}
            transition
            disablePortal
            style={{ zIndex: 9999 }}
          >
            <Box
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
              style={{ ...styleBoxList }}
            >
              <Listbox {...getListboxProps()}>
                {sortedAsc.map((option, index) => (
                  <Box
                    display="flex"
                    alignItems="center"
                    key={index.toString()}
                    onClick={() => set(option)}
                  >
                    <Box style={{ width: 30 }} mr={1}>
                      {option?.mediaFile?.url && <VideoLibrary style={{ fontSize: 14 }} />}
                    </Box>

                    <span>{option.name}</span>
                  </Box>
                ))}
              </Listbox>
            </Box>
          </Popper>
        ) : null}
      </div>
    </NoSsr>
  );
}

MovementChosen.propTypes = {
  setValue: PropTypes.func,
  limit: PropTypes.number,
  placeholder: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  error: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  defaultValue: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  val: PropTypes.array,
  tag: PropTypes.string,
  placement: PropTypes.string,
};

MovementChosen.defaultProps = {
  setValue: null,
  placeholder: '',
  error: {},
  defaultValue: [],
  limit: 0,
  val: [],
  tag: '',
  placement: 'bottom-start',
};
