import React, { memo, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { sessionSelectors } from 'modules/session';
import useSelectors from 'modules/map/useSelectors';
import { conversationsModule } from 'modules/conversations';
import useChannelStyles from 'core/useChannelStyles';
import Box from 'core/ui/Box';
import Scrollbars from 'react-custom-scrollbars';
import Divider from 'core/ui/Divider';
import LoaderSm from 'core/ui/LoaderSm';
import { colors } from '@material-ui/core';
import Button from 'core/ui/Button';
import Typography from 'core/ui/Typography';
import useSendMessages from './useSendMessages';
import useMessages from './useMessages';
import ChatMessageItem from './ChatMessageItem';

export default memo(({ setEditMessage, editMessage, action, setAction }) => {
  const { buttonColor } = useChannelStyles();
  const loadingMessages = useSelectors(conversationsModule, 'loadingMessages');
  const groupsChat = useSelectors(conversationsModule, 'groupsChat');
  const scrollRef = useRef();
  const { localMessages, loadMessages, pagination, setLocalMessages } = useMessages({
    groupsChat,
  });

  const { deleteMessage, setReaded } = useSendMessages();

  useEffect(() => {
    if (groupsChat?.id && groupsChat?.joined) {
      loadMessages(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupsChat?.id]);

  useEffect(() => {
    if (editMessage?.updatedItem?.id) {
      setLocalMessages((prev) =>
        prev.map((item) => {
          if (item.id === editMessage?.updatedItem?.id) {
            return editMessage?.updatedItem;
          }
          return item;
        }),
      );

      setEditMessage({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMessage?.updatedItem?.id]);

  useEffect(() => {
    if (editMessage?.addItem?.id) {
      setLocalMessages((prev) => [editMessage?.addItem, ...prev]);
      setEditMessage({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMessage?.addItem?.id]);

  const loadMoreMessage = async () => {
    loadMessages(pagination?.currentPage + 1);
  };

  const removeMessage = (item) => {
    deleteMessage({
      groupsChatId: groupsChat?.id,
      id: item?.id,
      callback: () => {
        setLocalMessages((prev) => prev?.filter((x) => x?.id !== item?.id));
      },
    });
  };

  const handleEditMessage = (item) => {
    setEditMessage({ open: true, item });
  };

  const scrollToTop = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop(scrollRef.current.getScrollHeight());
    }
  };

  useEffect(() => {
    if (pagination?.currentPage === 1) {
      setTimeout(() => {
        scrollToTop();
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localMessages, pagination]);

  return (
    <Scrollbars ref={scrollRef} style={{ flex: 1 }}>
      {pagination?.currentPage < pagination?.totalPages && (
        <Box display="flex" justifyContent="center" alignItems="center" m={2}>
          {loadingMessages && <LoaderSm center loading />}
          <Button onClick={() => loadMoreMessage()} className="linkHover">
            <Typography align="center" style={{ color: colors.grey[300] }}>
              Load More
            </Typography>
          </Button>
        </Box>
      )}
      <Box m={5} flex={1}>
        {[...localMessages]?.reverse()?.map((message, index) => (
          <Box key={message?.id}>
            <RenderItem
              item={message}
              action={action}
              setAction={setAction}
              buttonColor={buttonColor}
              actions={{
                removeMessage,
                editMessage: handleEditMessage,
                replyMessage: (item) => {
                  setAction({ type: 'reply', message: item });

                  setTimeout(() => {
                    scrollToTop();
                  }, 100);
                },
              }}
              setReaded={(itemId) => setReaded({ groupsChatId: groupsChat?.id, id: itemId })}
            />

            {localMessages?.length - 1 > index && <Divider my={1} />}
          </Box>
        ))}

        {loadingMessages && <LoaderSm center loading />}
      </Box>
    </Scrollbars>
  );
});

const RenderItem = ({ item, actions, setReaded, buttonColor, action, setAction }) => {
  const currentUser = useSelector((state) => sessionSelectors.getUser(state));

  const isFromUser = item.userId === currentUser?.id;

  useEffect(() => {
    if (item?.id && !item?.readed && !isFromUser) {
      setReaded(item?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  return (
    <ChatMessageItem
      isOwner={isFromUser}
      item={item}
      actions={actions}
      buttonColor={buttonColor}
      action={action}
      setAction={setAction}
    />
  );
};
