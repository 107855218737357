import React from 'react';

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 60 75"
    xmlSpace="preserve"
  >
    <path d="M48.4,34.8C48.4,34.8,48.4,34.8,48.4,34.8c0.6,0,1-0.5,1-1c-0.1-2.5-1.2-4.8-3.1-6.5s-4.3-2.6-6.8-2.4c-0.5,0-1,0.5-1,1  s0.5,1,1,1c2-0.1,3.9,0.6,5.3,1.9c1.5,1.3,2.3,3.2,2.4,5.1C47.5,34.4,47.9,34.8,48.4,34.8z" />
    <path d="M45.4,35C45.4,35,45.4,35,45.4,35c0.6,0,1-0.5,1-1c-0.2-3.5-3.1-6.2-6.7-6c-0.5,0-1,0.5-1,1s0.5,1,1,1  c2.4-0.1,4.5,1.7,4.6,4.1C44.4,34.6,44.8,35,45.4,35z" />
    <path d="M39.8,31c-0.5,0-1,0.5-1,1s0.5,1,1,1c0.7,0,1.3,0.5,1.4,1.2c0,0.5,0.5,1,1,1c0,0,0,0,0,0c0.5,0,1-0.5,1-1  C43.2,32.3,41.7,30.9,39.8,31z" />
    <path d="M8.5,14v32c0,4.4,3.6,8,8,8h12c4.4,0,8-3.6,8-8v-2h12c0.8,0,1.6-0.3,2.1-0.9c0.6-0.6,0.9-1.3,0.9-2.1c0-1.7-1.3-3-3-3h-12  V14c0-4.4-3.6-8-8-8h-12C12.1,6,8.5,9.6,8.5,14z M49.5,41c0,0.3-0.1,0.5-0.3,0.7C49,41.9,48.8,42,48.5,42h-13h-8.4l0,0h0h-2.6  c-0.5,0-1-0.5-1-1c0-0.3,0.1-0.5,0.3-0.7c0.2-0.2,0.4-0.3,0.7-0.3h2.6h0c0,0,0,0,0,0h8.4h13C49,40,49.5,40.5,49.5,41z M24.5,44h0.6  c-0.7,0.6-1.6,1-2.6,1c-2.2,0-4-1.8-4-4s1.8-4,4-4c1,0,1.9,0.4,2.6,1h-0.6c-0.8,0-1.6,0.3-2.1,0.9c-0.6,0.6-0.9,1.3-0.9,2.1  C21.5,42.7,22.9,44,24.5,44z M16.5,8h12c3.3,0,6,2.7,6,6v24h-6.8c-1.1-1.8-3-3-5.2-3c-3.3,0-6,2.7-6,6s2.7,6,6,6  c2.1,0,4.1-1.2,5.2-3h6.8v2c0,3.3-2.7,6-6,6h-12c-3.3,0-6-2.7-6-6V14C10.5,10.7,13.2,8,16.5,8z" />
    <path d="M14.5,15h2c0.5,0,1-0.4,1-1s-0.5-1-1-1h-2c-0.6,0-1,0.4-1,1S13.9,15,14.5,15z" />
    <path d="M21.5,15h2c0.5,0,1-0.4,1-1s-0.5-1-1-1h-2c-0.5,0-1,0.4-1,1S21,15,21.5,15z" />
    <path d="M28.5,15h2c0.5,0,1-0.4,1-1s-0.5-1-1-1h-2c-0.5,0-1,0.4-1,1S28,15,28.5,15z" />
    <path d="M14.5,19h2c0.5,0,1-0.5,1-1s-0.5-1-1-1h-2c-0.6,0-1,0.5-1,1S13.9,19,14.5,19z" />
    <path d="M21.5,19h2c0.5,0,1-0.5,1-1s-0.5-1-1-1h-2c-0.5,0-1,0.5-1,1S21,19,21.5,19z" />
    <path d="M28.5,19h2c0.5,0,1-0.5,1-1s-0.5-1-1-1h-2c-0.5,0-1,0.5-1,1S28,19,28.5,19z" />
    <path d="M14.5,23h2c0.5,0,1-0.5,1-1s-0.5-1-1-1h-2c-0.6,0-1,0.5-1,1S13.9,23,14.5,23z" />
    <path d="M21.5,23h2c0.5,0,1-0.5,1-1s-0.5-1-1-1h-2c-0.5,0-1,0.5-1,1S21,23,21.5,23z" />
    <path d="M28.5,23h2c0.5,0,1-0.5,1-1s-0.5-1-1-1h-2c-0.5,0-1,0.5-1,1S28,23,28.5,23z" />
    <path d="M14.5,27h2c0.5,0,1-0.5,1-1s-0.5-1-1-1h-2c-0.6,0-1,0.5-1,1S13.9,27,14.5,27z" />
    <path d="M21.5,27h2c0.5,0,1-0.5,1-1s-0.5-1-1-1h-2c-0.5,0-1,0.5-1,1S21,27,21.5,27z" />
    <path d="M28.5,27h2c0.5,0,1-0.5,1-1s-0.5-1-1-1h-2c-0.5,0-1,0.5-1,1S28,27,28.5,27z" />
  </svg>
);
