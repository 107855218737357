import { useEffect } from 'react';
import { useForm } from 'react-hook-form-v5';
import { repMaxTypes, typeMinMax } from 'core/utils/consts';
import { formatDateApi, formatDateToApi } from 'core/utils/formatters';
import useActions from 'modules/map/useActions';
import Modules from 'modules';

const useFormPrScore = ({ callback, selectedPr, itemEdit }) => {
  const { request } = useActions();

  const { register, handleSubmit, setValue, watch, reset, errors } = useForm();

  const onSubmit = (values) => {
    let scores = {};
    if (selectedPr?.scoreType === 'rep_max') {
      if (values.repMaxNumber === 'Custom') {
        scores.repCustomScheme = values?.repCustomScheme;
        scores.repCustomResults = values?.repCustomResults;
      } else {
        scores[values.repMaxNumber] = values[values.repMaxNumber];
        scores.repMaxType = values?.repMaxType;
      }
    } else {
      scores = { ...values };
    }

    scores.notes = values.notes;

    const data = {
      personalRecordMovementId: selectedPr?.id,
      date: values.date ? formatDateToApi(values.date) : null,
      scores: {
        ...scores,
        time: values.time ? values.time.value : null,
      },
    };

    if (itemEdit) {
      callback(data);
      return;
    }

    request({
      action: Modules.personalRecordMovements.actions.postUserPr,
      data,
      options: {
        onSuccess: () => {
          if (callback) {
            callback(values);
            // request({ action: Modules.personalRecordMovements.actions.getPrMovements });
          }
        },
      },
    });
  };

  useEffect(() => {
    register('date');
    if (selectedPr?.scoreType === 'rep_max') {
      register('repMaxNumber');

      register('repCustomScheme');
      register('repCustomResults');

      register('repMaxType');
      repMaxTypes.forEach((item) => {
        register(item);
      });
    }

    if (selectedPr?.scoreType === 'min_max') {
      register('minMax');
      register('minMaxType');
    }

    if (selectedPr?.scoreType === 'max_distance') {
      register('maxDistance');
      register('maxDistanceType');
    }

    if (selectedPr?.scoreType === 'weight') {
      register('weight');
      register('weightType');
    }

    if (selectedPr?.scoreType === 'time') {
      register('time');
    }

    if (selectedPr?.scoreType === 'unbroken') {
      register('unbroken');
    }
    register('notes');

    setTimeout(() => {
      if (selectedPr?.scoreType === 'rep_max') {
        setValue('repMaxNumber', 'repMax1');
      }
      if (selectedPr?.scoreType === 'min_max') {
        setValue('minMaxType', typeMinMax[0].id);
      }
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register, selectedPr]);

  useEffect(() => {
    if (itemEdit) {
      setTimeout(() => {
        setValue('date', formatDateApi(itemEdit?.date));
        Object.keys(itemEdit?.scores).forEach((x) => {
          setValue(x, itemEdit?.scores[x]);
          if (x === 'repCustomResults') {
            setValue('repMaxNumber', 'Custom');
          }
        });
      }, 500);
    }
  }, [itemEdit]);

  return {
    register,
    setValue,
    watch,
    reset,
    errors,
    onSubmit,
    handleSubmit,
  };
};

export default useFormPrScore;
