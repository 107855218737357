import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import { makeStyles, Slide } from '@material-ui/core';
import { Scrollbars } from 'react-custom-scrollbars';
import Form from 'components/Workout/Form';
import Box from 'core/ui/Box';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const ModalTemplate = ({ channelId, modalTemplate, close, callback }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const callbackSave = () => {
    callback();
  };
  return (
    <Dialog
      style={{ marginTop: 100 }}
      fullScreen
      open={modalTemplate.open}
      onClose={close}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar} color="secondary">
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            {t('channel.class.template')}
          </Typography>
          <IconButton edge="start" color="inherit" onClick={close} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Scrollbars allowFullScreen>
        <Box p={3}>
          <Form
            callbackSave={callbackSave}
            channelId={channelId}
            mode={modalTemplate.method}
            workoutId={modalTemplate.workoutId}
            type="template"
          />
        </Box>
      </Scrollbars>
    </Dialog>
  );
};

ModalTemplate.propTypes = {};

ModalTemplate.defaultProps = {
  close: () => {},
};

export default ModalTemplate;
