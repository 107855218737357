import React, { useEffect, useState } from 'react';
import Box from 'core/ui/Box';
import Typography, { fontStyle } from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import Paper from 'core/ui/Paper';
import Grid from 'core/ui/Grid';
import { ButtonCompetition } from 'core/ui/Button';
import { Report, Timer, VerifiedUser } from '@material-ui/icons';
import { primary, secondary, successColor } from 'core/ui/Colors';
import { workoutsActions, workoutsSelectors } from 'modules/workouts';
import { useDispatch, useSelector } from 'react-redux';
import LoaderSm from 'core/ui/LoaderSm';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import {
  convertSecondsToObject,
  convertSecondsToTimeString,
  convertToSeconds,
} from 'core/utils/helpers';
import { date, time } from 'core/utils/formatters';
import notifications from 'modules/notifications';
import TimeSelector from 'core/ui/TimeSelector';
import WorkoutScoresPoints from 'components/Competition/Show/Tabs/Results/Tables/WorkoutScoresPoints';
import VideoTeam from '../Watch/VideoTeam';
import ModalPenalty from './Penalties/ModalPenalty';
import WorkoutPenalty from './WorkoutPenalty';
import HeaderPlayer from '../Watch/HeaderPlayer';
import useWorkoutWatch from '../Watch/useWorkoutWatch';
import Popover from '../Watch/Flags/Popover';

const JudgeTeam = ({ workout, competition, leaderboard, refreshLeaderboard }) => {
  const { t } = useTranslation();
  const [modalPenalty, setModalPenalty] = useState({ open: false });
  const dispatch = useDispatch();
  const [startWorkoutTimer, setStartWorkoutTimer] = useState({});

  const workoutSectionUserData = useSelector((state) =>
    workoutsSelectors.getWorkoutSectionUserData(state),
  );
  const loadingWorkoutSection = useSelector((state) =>
    workoutsSelectors.getLoadingWorkoutSection(state),
  );
  const section = workout.sections[0];
  const userSection = workoutSectionUserData[section.id];
  const isVerified = userSection && userSection.verified;

  const player = useWorkoutWatch({ judge: true, workout });

  const styleIcon = {
    fontSize: 16,
    color: 'white',
  };

  const videoTeam = workout.recordingVideos.find(
    (x) => x.user.id === Number(leaderboard?.user?.id),
  );
  const penalties = (userSection && userSection.penalties) || [];

  const refreshSectionUser = () => {
    if (!leaderboard) {
      return;
    }
    dispatch(
      workoutsActions.getWorkoutSectionUserStart({
        workoutId: workout.id,
        userId: leaderboard.user.id,
      }),
    );
  };

  useEffect(() => {
    if (videoTeam && videoTeam.durationSeconds) {
      player.setTime((prev) => ({ ...prev, total: videoTeam.durationSeconds }));

      const startObject = convertSecondsToObject(
        convertSecondsToTimeString(videoTeam?.startCountdownSeconds),
      );

      setStartWorkoutTimer(startObject);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoTeam]);

  const onRemoveClick = (penalty) => {
    dispatch(
      workoutsActions.setWorkoutSectionUserPenaltiesStart({
        workoutId: workout.id,
        data: {
          penalties: penalties.filter((x) => x !== penalty),
          sectionId: section.id,
          userId: leaderboard.user.id,
          remove: true,
        },
        callback: () => {
          refreshSectionUser();
          refreshLeaderboard();
        },
      }),
    );
  };

  const viewFlagEvent = (flag) => {
    player.setPlaying(false);
    const seconds = convertToSeconds(flag.time);
    if (seconds <= player.durationSeconds) {
      let toSeek = Math.round(seconds - 10);
      toSeek = toSeek <= 0 ? 0 : toSeek;

      player.setTime((prev) => ({
        ...prev,
        complete: toSeek,
      }));
      player.setSeek(toSeek);
      if (player.setManualSeek) {
        player.setManualSeek(true);
      }
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    } else {
      dispatch(notifications.warning(t('flag.incorrectTime')));
    }
  };

  const onVerified = () => {
    dispatch(
      workoutsActions.setWorkoutSectionUserVerifiedStart({
        workoutId: workout.id,
        data: {
          verified: !isVerified,
          sectionId: section.id,
          userId: leaderboard.user.id,
        },
        callback: () => {
          refreshSectionUser();
          refreshLeaderboard();
        },
      }),
    );
  };

  const changeStartCountdownSeconds = () => {
    dispatch(
      workoutsActions.setWorkoutCountdownSecondsStart(
        {
          workoutId: workout.id,
          userId: videoTeam.user.id,
          startCountdownSeconds:
            startWorkoutTimer && startWorkoutTimer.value
              ? convertToSeconds(startWorkoutTimer.value)
              : 0,
        },
        () => {
          dispatch(workoutsActions.getWorkoutStart(workout.id));
          refreshLeaderboard();
        },
      ),
    );
  };

  const onSeek = (newTime) => {
    player.setTime((prev) => ({ ...prev, complete: newTime }));
  };

  useEffect(() => {
    refreshSectionUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refresh = () => {
    setModalPenalty({ open: false });
    refreshSectionUser();
    dispatch(workoutsActions.getWorkoutSectionLeaderboardStart(workout.id, 'first'));
  };

  if (!leaderboard) {
    return <></>;
  }

  return (
    <>
      {videoTeam && (
        <HeaderPlayer
          marks={[{ value: videoTeam?.startCountdownSeconds, label: 'Workout' }]}
          player={player}
          buttonColor={competition?.buttonColor}
          competition={competition}
          workout={workout}
          startCountdownSeconds={videoTeam?.startCountdownSeconds}
        />
      )}

      {modalPenalty.open && (
        <ModalPenalty
          open={modalPenalty.open}
          section={section}
          workout={workout}
          user={leaderboard.user}
          currentScores={leaderboard.revisedScores || leaderboard.scores}
          close={() => setModalPenalty({ open: false })}
          saveCallback={() => {
            refresh();
          }}
        />
      )}

      <Paper mt={1} className="paper-rounded opacity-80" p={3}>
        <Grid container>
          {videoTeam && (
            <Grid item xs={12} sm={12} md={6} lg={6} style={{ position: 'relative' }}>
              <Box>
                <Box style={{ position: 'absolute', right: 2, top: 10, zIndex: 999 }}>
                  <Popover
                    buttonColor={competition.buttonColor}
                    workoutId={workout.id}
                    userId={videoTeam?.user?.id}
                    sectionId={section.id}
                    callback={() => refresh()}
                  />
                </Box>
                <VideoTeam
                  seek={player.seek}
                  videoTeam={videoTeam || {}}
                  playing={player.playing}
                  setReady={() => player.setVideoReady(videoTeam?.user?.id)}
                  setSeek={(newTime) => onSeek(newTime)}
                  muted={player.muted}
                />
              </Box>
            </Grid>
          )}
          <Grid item xs={12} sm={12} md={videoTeam ? 6 : 12} lg={videoTeam ? 6 : 12} pl={3}>
            <Paper style={{ height: '100%' }} className="paper-rounded opacity-80" p={3}>
              <Box display="flex" style={{ height: '100%' }} flexDirection="column">
                <LoaderSm loading={loadingWorkoutSection} />
                <Box flexGrow={1}>
                  <Box display="flex" mb={3} alignItems="center">
                    <VerifiedUser
                      onClick={() => onVerified()}
                      style={{ color: isVerified ? successColor : secondary }}
                    />
                    {leaderboard.user && leaderboard.user.team && leaderboard.user.team.name && (
                      <Typography ml={1} variant="h5">
                        {leaderboard.user.team.name.toUpperCase()}
                      </Typography>
                    )}
                  </Box>

                  <Box mb={3}>
                    <Typography variant="h6">
                      {t('workout.judge.currentScore').toUpperCase()}
                    </Typography>

                    <WorkoutScoresPoints
                      sectionScores={leaderboard?.sectionScores}
                      scores={leaderboard?.scores}
                      positions={leaderboard?.positions || {}}
                      showVariation={workout.competitionUseRxScaledScores}
                      buttonColor={competition?.buttonColor}
                    />
                  </Box>
                  {leaderboard.revisedScores && (
                    <Box mb={3}>
                      <Typography variant="h6">
                        {t('workout.judge.revisedScore').toUpperCase()}
                      </Typography>
                      <WorkoutScoresPoints
                        sectionScores={leaderboard?.sectionScores}
                        scores={leaderboard.revisedScores}
                        positions={leaderboard?.positions || {}}
                        showVariation={workout.competitionUseRxScaledScores}
                        buttonColor={competition?.buttonColor}
                      />
                    </Box>
                  )}
                  <Box mb={3}>
                    <Typography variant="h6">
                      {t('workout.judge.penalties').toUpperCase()}
                    </Typography>
                    <Box mb={3}>
                      {userSection && penalties && (
                        <>
                          {penalties.map((p, index) => (
                            <Box key={index.toString()}>
                              <WorkoutPenalty
                                fontValue={fontStyle.large}
                                penalty={p}
                                onRemoveClick={onRemoveClick}
                              />
                            </Box>
                          ))}
                        </>
                      )}
                    </Box>
                  </Box>

                  <Paper mb={3} p={3}>
                    <Box display="flex" alignItems="flex-end">
                      <Box flexGrow={1} mr={3}>
                        <TimeSelector
                          time={startWorkoutTimer}
                          setTime={(newTime) => setStartWorkoutTimer(newTime)}
                          label={t('workout.competition.upload.startWorkoutCoutndownTime')}
                          milliseconds
                          bordered
                        />
                      </Box>

                      <Box>
                        <ButtonCompetition
                          onClick={() => changeStartCountdownSeconds()}
                          fullWidth
                          style={{ backgroundColor: successColor }}
                        >
                          <Timer style={styleIcon} />
                          &nbsp;
                          {t('button.save').toUpperCase()}
                        </ButtonCompetition>
                      </Box>
                    </Box>
                  </Paper>
                  {!!userSection && !!isVerified && (
                    <Box mb={3}>
                      <Typography variant="h6">
                        {t('workout.judge.verifiedBy').toUpperCase()}
                      </Typography>
                      <Box mb={3} display="flex" alignItems="center">
                        <Typography variant="body2">
                          {userSection.verifiedUser && userSection.verifiedUser.name}
                        </Typography>
                        <Typography ml={1} variant="body2">
                          {date(userSection.verifiedDate, competition?.channel?.dateFormatExtReact)}
                          &nbsp;&bull;&nbsp;
                          {time(userSection.verifiedDate)}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </Box>
                <Grid container spacing={5}>
                  <Grid item xs={12} md={isVerified ? 12 : 6}>
                    <ButtonCompetition
                      onClick={() => setModalPenalty({ open: true })}
                      fullWidth
                      style={{ backgroundColor: primary }}
                    >
                      <Report style={styleIcon} />
                      &nbsp;
                      {t('button.penalize').toUpperCase()}
                    </ButtonCompetition>
                  </Grid>

                  {!isVerified && (
                    <Grid item xs={12} md={6}>
                      <ButtonCompetition
                        onClick={() => onVerified()}
                        fullWidth
                        style={{ backgroundColor: successColor }}
                      >
                        <VerifiedUser style={styleIcon} />
                        &nbsp;
                        {t(isVerified ? 'button.verified' : 'button.verify').toUpperCase()}
                      </ButtonCompetition>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Paper>

      <Paper mt={1} className="paper-rounded opacity-80">
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography className="roboto-regular">
                    {t('competition.judge.flag.user')}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography className="roboto-regular">
                    {t('competition.judge.flag.flagType')}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography className="roboto-regular">
                    {t('competition.judge.flag.description')}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography className="roboto-regular">
                    {t('competition.judge.flag.timeStamp')}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography className="roboto-regular">
                    {t('competition.judge.flag.violationClip')}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userSection &&
                userSection.flagsData &&
                userSection.flagsData.map((item, index) => (
                  <TableRow key={index.toString()}>
                    <TableCell>
                      <Typography color="secondary">{item.userName}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography color="secondary">
                        {t(`workout.watch.flag.${item.type}`)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography color="secondary">{item.description}</Typography>
                    </TableCell>
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        <Typography color="secondary" mr={2}>
                          {item.time}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        <ButtonCompetition
                          style={{ backgroundColor: competition.buttonColor }}
                          onClick={() => viewFlagEvent(item)}
                        >
                          {t('button.watch')}
                        </ButtonCompetition>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export default JudgeTeam;
