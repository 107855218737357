/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import Box from 'core/ui/Box';
import Typography from 'core/ui/Typography';
import { Avatar, Dialog } from '@material-ui/core';
import { channelMembersActions, channelMembersSelectors } from 'modules/channelMembers';
import { useDispatch, useSelector } from 'react-redux';
import { QRCodeSVG } from 'qrcode.react';

import UserChannelKeys from 'components/Purchase/UserChannelKeys';
import Divider from 'core/ui/Divider';
import { useTranslation } from 'react-i18next';
import notifications from 'modules/notifications';
import Texture from 'core/ui/Texture';
import ChannelResumeInfo from 'components/Channel/Manage/Tabs/Members/Memberships/MemberInformation/ChannelResumeInfo';
import CheckinTime from './CheckinTime';

export default ({ channel, selectedUser, close }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const member = useSelector((state) => channelMembersSelectors.getChannelMember(state));
  const loading = useSelector((state) => channelMembersSelectors.getLoadingMember(state));

  const getMemberInfo = () => {
    if (channel && channel.id) {
      dispatch(
        channelMembersActions.getChannelMemberStart(channel.id, selectedUser?.id, {
          onError: () => {
            dispatch(notifications.error(t('checkIn.validation.member')));
            close();
          },
        }),
      );
    }
  };

  useEffect(() => {
    if (selectedUser?.id) {
      getMemberInfo();
    }

    return () => {
      dispatch(channelMembersActions.clearMember());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUser]);

  return (
    <Dialog open={selectedUser?.id} maxWidth="md" scroll="body" fullWidth onClose={close}>
      <Texture>
        <Box p={5}>
          <Box display="flex" flexWrap="wrap">
            <Box>
              <Avatar
                alt={selectedUser?.name || member?.name}
                src={selectedUser?.image?.image?.url || member?.image?.image?.url}
                style={{ width: 200, height: 200, borderRadius: 10 }}
              />
            </Box>
            <Box flexGrow={1} ml={5}>
              <Box display="flex" alignItems="center">
                <Box flexGrow={1}>
                  <Typography variant="h1" mt={5}>
                    {selectedUser?.name || member?.name}
                  </Typography>
                </Box>

                <Box display="flex" alignItems="center" justifyContent="flex-end" flexGrow={1}>
                  {selectedUser?.id && <QRCodeSVG value={`${selectedUser?.id}`} size={50} />}
                  <Typography variant="h1" align="center" ml={3}>
                    {selectedUser?.id}
                  </Typography>
                </Box>
              </Box>
              <Typography variant="body2" ml={2} color="textSecondary" mt={2}>
                {selectedUser?.country?.name || member?.country?.name}
              </Typography>

              <ChannelResumeInfo member={member} loading={loading} />
            </Box>
          </Box>

          <Divider m={3} my={5} />

          <Box>
            <CheckinTime closeModal={close} selectedUser={member} channelId={channel?.id} />
          </Box>

          <Box>
            <UserChannelKeys
              label={
                <Typography variant="h6" mb={3}>
                  Memberships
                </Typography>
              }
              keys={member?.channelKeys || []}
              workout={{ channel }}
            />
          </Box>
        </Box>
      </Texture>
    </Dialog>
  );
};
