import { useEffect } from 'react';
import { useForm } from 'react-hook-form-v5';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import useSelectors from 'modules/map/useSelectors';
import notifications from 'modules/notifications';
import { useDispatch } from 'react-redux';
import { formatDateApi, formatDateToApi } from 'core/utils/formatters';
import { adminChannelsModule } from 'modules/admin/channels';

export default ({ callback, channel }) => {
  const { request } = useActions();
  const loading = useSelectors(adminChannelsModule, 'loading');
  const { register, handleSubmit, setValue, watch, reset, errors } = useForm();
  const dispatch = useDispatch();

  const onSubmit = (values) => {
    const data = { allowCardpointe: values?.allowCardpointe };

    if (values?.renewAt) {
      data.renewAt = formatDateToApi(values?.renewAt);
    }
    request({
      action: Modules.adminChannels.actions.updateChannelInfo,
      data: { ...data, channelId: channel?.id },
      options: {
        onSuccess: () => {
          if (callback) {
            callback();
          }
        },
        onError: (errr) => {
          dispatch(notifications.error(errr));
        },
      },
    });
  };

  useEffect(() => {
    register('renewAt', { required: false });
    register('allowCardpointe', { required: false });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register]);

  useEffect(() => {
    if (channel && channel.id) {
      const bk = {
        allowCardpointe: channel?.allowCardpointe,
        renewAt:
          channel?.renewAt &&
          formatDateApi(channel?.renewAt, 'YYYY-MM-DD HH:mm:ss Z', channel?.timezone),
      };

      reset({
        renewAt: bk?.renewAt,
        allowCardpointe: bk.allowCardpointe,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel]);

  return {
    register,
    setValue,
    watch,
    reset,
    errors,
    onSubmit,
    handleSubmit,
    submitForm: handleSubmit(onSubmit),
    loading,
  };
};
