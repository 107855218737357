import React, { useState } from 'react';
import Box from 'core/ui/Box';
import BenchmarkList from 'components/Profile/Dashboard/Benchmark/List';
import { ButtonPrimary } from 'core/ui/Button';
import { useTranslation } from 'react-i18next';
import ModalFormBenchmark from './Form/Modal';
import ModalUsers from './ModalUsers';

export default () => {
  const [modal, setModal] = useState({ opened: false });
  const [modalUsers, setModalUsers] = useState({ opened: false });
  const { t } = useTranslation();
  const onSelectedBenchmark = (item) => {
    setModal({ opened: true, benchmark: item });
  };

  return (
    <Box py={3}>
      <ModalFormBenchmark modal={modal} close={() => setModal({ opened: false })} />
      {modalUsers.opened && modalUsers.benchmarkSectionId && (
        <ModalUsers
          benchmarkSectionId={modalUsers.benchmarkSectionId}
          close={() => setModalUsers({ opened: false })}
        />
      )}

      <Box flexGrow={1} display="flex" justifyContent="flex-end" mb={2}>
        <ButtonPrimary style={{ width: 150 }} onClick={() => setModal({ opened: true })}>
          {t('button.create')}
        </ButtonPrimary>
      </Box>
      <BenchmarkList
        selectedBenchmark={modal.benchmark}
        setSelectedBenchmark={onSelectedBenchmark}
        rowsPerPage={20}
        hideTitle
        onUsersButtonClick={(bId) => setModalUsers({ opened: true, benchmarkSectionId: bId })}
        onEditButtonClick={(benchmark) => setModal({ opened: true, benchmark })}
        selector
      />
    </Box>
  );
};
