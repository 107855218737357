/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Paper from 'core/ui/Paper';
import { useTranslation } from 'react-i18next';
import Typography, { fontStyle } from 'core/ui/Typography';
import SFDataTable from 'core/ui/DataTable';
import Box from 'core/ui/Box';
import { currency, date } from 'core/utils/formatters';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import LoaderSm from 'core/ui/LoaderSm';
import useSelectors from 'modules/map/useSelectors';
import { channelsPayoutsModule } from 'modules/channelsPayouts';
import { ButtonPrimarySm } from 'core/ui/Button';
import { Dialog } from '@material-ui/core';
import ResultPayments from '../Payments/ResultPayments';

export default function ResultCardpointeBatches({ rows, channel }) {
  const { t } = useTranslation();
  const { request } = useActions();
  const [payoutInfo, setPayoutInfo] = useState(null);
  const loading = useSelectors(channelsPayoutsModule, 'loading');

  const showPayout = (payoutRow) => {
    request({
      action: Modules.channelsPayouts.actions.show,
      data: {
        channelId: channel?.id,
        id: payoutRow?.id,
        cardpointe: '1',
      },
      options: {
        onSuccess: (data) => {
          setPayoutInfo(data);
        },
      },
    });
  };

  const columns = [
    {
      name: 'Batch ID',
      selector: 'batchId',
      sortable: true,
      cell: (row) => (
        <Typography style={fontStyle.medium} color="secondary">
          {row.batchId}
        </Typography>
      ),
    },
    {
      name: 'Batch Type',
      selector: 'batchType',
      sortable: true,
      cell: (row) => (
        <Typography style={fontStyle.medium} color="secondary">
          {row.batchType?.toUpperCase()}
        </Typography>
      ),
    },
    {
      name: 'Date',
      selector: 'payoutAt',
      cell: (row) => <Typography>{date(row?.createdAt, channel?.dateFormatReact)}</Typography>,
      sortable: true,
      center: true,
    },
    {
      name: 'Status',
      selector: 'status',
      left: true,
      sortable: true,
      cell: (row) => (
        <Box display="flex" flexDirection="column">
          <Typography>{row?.textStat}</Typography>
        </Box>
      ),
    },
    {
      name: 'Transactions',
      right: true,
      cell: (row) => <Typography>{row?.resume?.count}</Typography>,
    },
    {
      name: 'Gross',
      right: true,
      cell: (row) => <Typography>{currency(row?.resume?.grossTotal)}</Typography>,
    },

    {
      name: 'Fees',
      right: true,
      cell: (row) => <Typography>{currency(row?.resume?.gatewayFeeAmount)}</Typography>,
    },

    {
      name: 'Net',
      selector: 'amount',
      right: true,
      sortable: true,
      cell: (row) => <Typography>{currency(row?.resume?.netTotal)}</Typography>,
    },
    {
      name: 'Payments',
      selector: '',
      center: true,
      sortable: false,
      cell: (row) => (
        <ButtonPrimarySm
          onClick={() => {
            setPayoutInfo({ loading: true });
            showPayout(row);
          }}
        >
          {t('label.payments')}
        </ButtonPrimarySm>
      ),
    },
  ];

  return (
    <Paper style={{ width: '100%' }}>
      <Dialog
        open={payoutInfo?.id || payoutInfo?.loading}
        maxWidth="xl"
        fullWidth
        onClose={() => setPayoutInfo(null)}
      >
        <Box p={5} textAlign="center" mb={10}>
          <Typography component="h1" align="center" m={5}>
            {t('label.payments')}
          </Typography>

          <LoaderSm loading={loading} />
          <ResultPayments
            noHeader
            channel={channel}
            hideActions
            pagination={false}
            rows={payoutInfo?.payments || []}
          />
        </Box>
      </Dialog>
      <SFDataTable
        title={t('channel.reports.batches')}
        columns={columns}
        data={rows}
        pagination
        showExport
      />
    </Paper>
  );
}
