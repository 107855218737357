/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import Typography from 'core/ui/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { messagesActions, messagesSelectors } from 'modules/messages';
import { useTranslation } from 'react-i18next';
import Paper from 'core/ui/Paper';
import { Refresh } from '@material-ui/icons';
import Button from 'core/ui/Button';
import LoaderSm from 'core/ui/LoaderSm';
import Box from 'core/ui/Box';
import TableMessages from './Table';

const Messages = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const data = useSelector((state) => messagesSelectors.getMessages(state));
  const loading = useSelector((state) => messagesSelectors.getLoading(state));
  // const textServiceContext = useContext(TextServiceContext);

  useEffect(() => {
    dispatch(messagesActions.getMessagesStart());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refresh = () => {
    dispatch(messagesActions.getMessagesStart());
  };

  return (
    <Paper p={3} className="paper-rounded">
      <Box display="flex" alignItems="center">
        <Box display="flex" alignItems="center" flexGrow={1}>
          <Typography variant="h4">{t('admin.sentMessages')}</Typography>
          {loading && <LoaderSm loading />}
        </Box>
        <Button onClick={() => refresh()}>
          <Refresh />
        </Button>
      </Box>
      <TableMessages messages={data} />
    </Paper>
  );
};

Messages.propTypes = {};

Messages.defaultProps = {};

export default Messages;
