import Modules from 'modules';
import { ownerableMessagesModule } from 'modules/ownerableMessages';
import useActions from 'modules/map/useActions';
import useSelectors from 'modules/map/useSelectors';
import { usersMessagesModule } from 'modules/usersMessages';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { channelsActions } from 'modules/channels';

export default ({ channelId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { request } = useActions();
  const [modalView, setModalView] = useState({ open: false });
  const data = useSelectors(usersMessagesModule, 'data');
  const pagination = useSelectors(usersMessagesModule, 'pagination');
  const unreadCount = useSelectors(usersMessagesModule, 'unreadCount');
  const loading = useSelectors(usersMessagesModule, 'loading');
  const { messagesTypes } = useSelectors(ownerableMessagesModule);
  const [localSettings, setLocalSettings] = useState({});

  useEffect(() => {
    const newData = {};
    messagesTypes.forEach((item) => {
      newData[item.id] = !!item?.channelData?.block;
    });
    setLocalSettings(newData);
  }, [messagesTypes]);

  const getMessageTypes = () => {
    request({
      action: Modules.ownerableMessages.actions.getMessagesTypes,
      data: {
        channelId,
      },
    });
  };

  const toggleMessageType = (type, opt) => {
    const changeTypes = [{ id: type?.channelData?.id, messagesTypeId: type?.id, block: opt }];

    dispatch(
      channelsActions.putChannelStart({
        id: channelId,
        data: {
          usersMessagesTypesAttributes: changeTypes,
        },
        messageSuccess: t('message.success.save.channel'),
      }),
    );
  };
  const formatMessagesTypes = () => {
    const types = { email: [], push: [], sms: [] };
    messagesTypes
      .filter((x) => x.role === 'channel')
      .forEach((item) => {
        types[item?.kind].push({ ...item, groupType: null });
      });
    return types;
  };

  return {
    getMessageTypes,
    data,
    pagination,
    modalView,
    setModalView,
    unreadCount: unreadCount?.total,
    messagesTypes: formatMessagesTypes(),
    toggleMessageType,
    loading,
    localSettings,
    setLocalSettings,
  };
};
