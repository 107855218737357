/* eslint-disable react/prop-types */
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Button, IconButton } from '@material-ui/core';
import Typography from 'core/ui/Typography';
import Box from 'core/ui/Box';
import { useHistory } from 'react-router';
import Scrollbars from 'react-custom-scrollbars';
import { secondary } from 'core/ui/Colors';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CalculatorIcon from 'core/ui/MIcon/CalculatorIcon';
import ModalCalculator from 'components/Profile/Dashboard/PersonalRecord/ModalCalculator';
import { Edit } from '@material-ui/icons';
import { ShowContext } from '.';
import WorkoutHeats from './Tabs/Heats';

export default () => {
  const { t } = useTranslation();
  const showContext = useContext(ShowContext);
  const history = useHistory();

  const useStyles = makeStyles((theme) => ({
    boxTabs: {
      display: 'flex',
      height: 55,
      width: '100%',
      borderRadius: 5,
      alignItems: 'center',
    },
    wraperTabs: {
      background: secondary,
      height: 55,
      borderRadius: 5,
    },
    activeTab: {
      borderBottom: '0.5px dotted #fff',
    },
    styleTab: {
      padding: 3,
      marginLeft: 5,
      [theme.breakpoints.up('sm')]: {
        padding: 5,
        marginLeft: 11,
      },
    },
    styleTabFirst: {
      padding: 3,
      [theme.breakpoints.up('sm')]: {
        padding: 5,
        marginLeft: 3,
      },
    },
  }));
  const classes = useStyles();

  const buttonColor = showContext?.workout?.channel?.customColors?.buttonColor;
  const [modalCalculator, setModalCalculator] = useState(false);

  const ItemTab = ({ label, tab, onClick }) => (
    <Box className={classes.styleTab}>
      <Button
        size="small"
        onClick={
          onClick
            ? () => onClick()
            : () => {
                showContext.setTab('overview');
                history.push(`/workout/view/${showContext.workout.slug}/${tab}`);
              }
        }
      >
        <Typography
          className={showContext.tab === tab && classes.activeTab}
          style={{ color: 'white' }}
          variant="subtitle2"
        >
          {label}
        </Typography>
      </Button>
    </Box>
  );

  const showAttendance =
    showContext?.workout.isCoach ||
    (!showContext?.workout?.hideAttendance &&
      (showContext?.workout.registered ||
        showContext?.workout?.hasValidKeyInPersonChannel ||
        showContext?.workout?.hasValidKeyChannel ||
        showContext?.workout?.hasListChannel));

  return (
    <Box className={classes.wraperTabs} px={2}>
      {modalCalculator && (
        <ModalCalculator buttonColor={buttonColor} close={() => setModalCalculator(false)} />
      )}
      <Scrollbars
        autoHide
        renderTrackVertical={(props) => (
          <div {...props} style={{ display: 'none' }} className="track-horizontal" />
        )}
        style={{ width: '100%', height: 55 }}
      >
        <Box className={classes.boxTabs}>
          {showContext.tab === 'leaderboard' && (
            <Box>
              <IconButton
                onClick={() => {
                  showContext.setTab('overview');
                  history.push(`/workout/view/${showContext.workout.slug}/overview`);
                }}
              >
                <ArrowBackIosIcon style={{ color: 'white', fontSize: 14 }} />
              </IconButton>
            </Box>
          )}
          <Box
            className={showContext.tab === 'leaderboard' ? classes.styleTabFirst : classes.styleTab}
          >
            <Button
              size="small"
              onClick={() => {
                history.push(`/workout/view/${showContext.workout.slug}/overview`);
                showContext.setTab('overview');
              }}
            >
              <Typography
                className={
                  (showContext.tab === 'overview' || showContext.tab === 'leaderboard') &&
                  classes.activeTab
                }
                style={{ color: 'white' }}
                variant="subtitle2"
              >
                {t('workout.tab.overview')}
              </Typography>
            </Button>
          </Box>

          {showContext.workout.isCompetition && (
            <ItemTab
              label={t('workout.tab.competition')}
              tab="competition"
              onClick={() =>
                history.push(`/competition/view/${showContext.workout.competition.slug}`)
              }
            />
          )}
          {showAttendance && !showContext.workout.isCompetition && (
            <ItemTab label={t('workout.tab.attendance')} tab="attendance" />
          )}
          {!showContext.workout.isCompetition && (
            <>
              <Box ml={4}>
                <Button
                  size="small"
                  onClick={() =>
                    history.push(`/channel/view/${showContext.workout.channel.slug}/schedules`)
                  }
                >
                  <Typography style={{ color: 'white' }} variant="subtitle2">
                    {t('workout.tab.schedule')}
                  </Typography>
                </Button>
              </Box>

              <ItemTab label={t('workout.tab.reviews')} tab="reviews" />
            </>
          )}
          {(showContext.workout.isCoach ||
            (showContext.workout.channel &&
              ['admin', 'admin_coach', 'owner', 'coach_head', 'coach_owner'].find(
                (x) => x === showContext.workout.channel.accessType,
              ))) &&
            showContext.workout.active && (
              <Box className={classes.styleTab}>
                <Button
                  size="small"
                  onClick={() => history.push(`/workout/edit/${showContext.workout.id}`)}
                >
                  <Edit style={{ color: 'white' }} />
                </Button>
              </Box>
            )}

          <Box display="flex">
            <Button size="small" onClick={() => setModalCalculator(true)}>
              <CalculatorIcon color="white" size={24} />
            </Button>

            {showContext.workout?.useHeats &&
              (showContext.workout?.registered || showContext?.workout?.isCoach) &&
              showContext.workout?.workoutHeats?.length > 0 && (
                <Box>
                  <WorkoutHeats
                    open={showContext?.modalHeats}
                    setOpen={showContext?.setModalHeats}
                    buttonColor={buttonColor}
                    workout={showContext.workout}
                  />
                </Box>
              )}
          </Box>
        </Box>
      </Scrollbars>
    </Box>
  );
};
