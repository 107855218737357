import React, { useContext } from 'react';
import { ClickAwayListener, Drawer } from '@material-ui/core';
import clsx from 'clsx';
import SearchWorkoutFilters from 'core/ui/SearchChosen/Filters';
import ConfirmDialogGlobal from 'core/ui/ConfirmDialogGlobal';
import AppBar from './components/AppBar';
import SideBar from './components/SideBar';
import { LayoutContext } from '.';

const AppBarAndDrawerContent = ({ classes }) => {
  const { setDrawer, drawer, drawerFilters } = useContext(LayoutContext);

  return (
    <ClickAwayListener onClickAway={() => setDrawer(false)}>
      <div>
        <ConfirmDialogGlobal />
        <AppBar />
        <div className={classes.toolbar} />

        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: drawerFilters.open,
            [classes.drawerClose]: !drawerFilters.open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: drawerFilters.open,
              [classes.drawerClose]: !drawerFilters.open,
            }),
          }}
          anchor="left"
        >
          <SearchWorkoutFilters />
        </Drawer>

        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: drawer,
            [classes.drawerClose]: !drawer,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: drawer,
              [classes.drawerClose]: !drawer,
            }),
          }}
          anchor="right"
        >
          {drawer && <SideBar />}
        </Drawer>
      </div>
    </ClickAwayListener>
  );
};

export default AppBarAndDrawerContent;
