import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import Box from 'core/ui/Box';
import Texture from 'core/ui/Texture';
import Form from './Form';

const ModalFormPersonalRecord = ({ close, modalForm, callbackForm }) => (
  <Dialog open={modalForm.open} onClose={close} maxWidth="md" fullWidth scroll="body">
    <Texture>
      <Box p={5}>
        <Form
          callback={callbackForm}
          dataInvite={modalForm.dataInvite}
          duplicate={modalForm.duplicate}
          workoutId={modalForm.workoutId}
        />
      </Box>
    </Texture>
  </Dialog>
);

ModalFormPersonalRecord.propTypes = {
  close: PropTypes.func,
};

ModalFormPersonalRecord.defaultProps = {
  close: () => {},
};

export default ModalFormPersonalRecord;
