/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Typography, { fontStyle } from 'core/ui/Typography';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import Box from 'core/ui/Box';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Paper from 'core/ui/Paper';
import { Tooltip } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import { secondary } from 'core/ui/Colors';

const FormExpiryConfig = ({ currentPlan, setCurrentPlan }) => {
  const { t } = useTranslation();

  const setConfig = (field, value) => {
    setCurrentPlan((prev) => ({
      ...prev,
      expiryConfig: prev.expiryConfig
        ? { ...prev.expiryConfig, [field]: value }
        : { [field]: value },
    }));
  };

  const Option = ({ label, onClick, checked }) => (
    <Box className="link-hover" mr={2} onClick={() => onClick()} display="flex" alignItems="center">
      {!checked ? (
        <RadioButtonUncheckedIcon color="primary" />
      ) : (
        <CheckCircleOutlineIcon color="primary" />
      )}
      <Typography ml={2} variant="body2">
        {label}
      </Typography>
    </Box>
  );

  return (
    <Paper className="paper-rounded" p={3}>
      <Box display="flex" alignItems="center" flexWrap="wrap">
        <Box flexGrow={1}>
          <Typography mb={2} style={fontStyle.label} variant="body1">
            {t('channel.memberships.expiryIn').toUpperCase()}
          </Typography>
          <Box display="flex">
            <Option
              label={t('channel.memberships.custom.noExpiry')}
              onClick={() => setConfig('config', 'none')}
              checked={currentPlan?.expiryConfig?.config === 'none'}
            />
            <Option
              label={t('channel.memberships.custom.day')}
              onClick={() => setConfig('config', 'day')}
              checked={currentPlan?.expiryConfig?.config === 'day'}
            />
            <Option
              label={t('channel.memberships.custom.week')}
              onClick={() => setConfig('config', 'week')}
              checked={currentPlan?.expiryConfig?.config === 'week'}
            />
            <Option
              label={t('channel.memberships.custom.month')}
              onClick={() => setConfig('config', 'month')}
              checked={currentPlan?.expiryConfig?.config === 'month'}
            />
            <Option
              label={t('channel.memberships.custom.year')}
              onClick={() => setConfig('config', 'year')}
              checked={currentPlan?.expiryConfig?.config === 'year'}
            />
          </Box>
        </Box>

        {currentPlan?.expiryConfig?.config && currentPlan?.expiryConfig?.config !== 'none' && (
          <Box>
            <TextFieldRounded
              type="number"
              value={currentPlan?.expiryConfig?.number || ''}
              pattern="[1-9]*"
              placeholder={`${currentPlan?.expiryConfig?.config}s`.toUpperCase()}
              label={`${currentPlan?.expiryConfig?.config}s`.toUpperCase()}
              onChange={(v) => setConfig('number', v)}
              required
              bordered
            />
          </Box>
        )}
      </Box>

      {currentPlan?.cancelable &&
        currentPlan?.expiryConfig?.config &&
        currentPlan?.expiryConfig?.config !== 'none' && (
          <Box mt={3}>
            <Box display="flex" alignItems="center">
              <Typography style={fontStyle.label} mr={1} variant="body1">
                {t('channel.memberships.label.cancellationAmount').toUpperCase()}
              </Typography>
              <Tooltip
                title={`${t('channel.memberships.cancellationAmountInfo').replace(
                  '#{frequency}',
                  currentPlan?.customIncrement,
                )}`}
              >
                <Info style={{ fontSize: 24, color: secondary }} />
              </Tooltip>
            </Box>
            <TextFieldRounded
              type="number"
              value={currentPlan.cancellationAmount || ''}
              pattern="[0-9]*"
              placeholder={t('channel.memberships.placeholder.cancellationAmount')}
              // label={t('channel.memberships.label.cancellationAmount').toUpperCase()}
              onChange={(v) =>
                setCurrentPlan((prev) => ({ ...prev, cancellationAmount: v >= 0 ? v : 0 }))
              }
              currency
              bordered
            />
          </Box>
        )}
    </Paper>
  );
};

FormExpiryConfig.defaultProps = {
  currentPlan: {},
  setCurrentPlan: () => {},
};
FormExpiryConfig.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  currentPlan: PropTypes.object,
  setCurrentPlan: PropTypes.func,
};

export default FormExpiryConfig;
