import { useContext, useEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { roomActions, roomSelectors } from 'modules/room';
import { workoutsSelectors } from 'modules/workouts';
import { StreamContext } from 'pages/Stream';

import { sessionWorkoutActions } from 'modules/sessionWorkout';
import { workoutEvent } from 'core/utils/consts';
import useWindowSize from 'core/utils/hook/useWindowSize';
import { isSafari } from 'react-device-detect';

export default () => {
  const size = useWindowSize();
  const workout = useSelector((state) => workoutsSelectors.getWorkout(state));
  const [activeRecordings, setActiveRecordings] = useState([]);
  const playingRecordings = useSelector((state) => roomSelectors.getPlayingRecordings(state));
  const mutedRecordings = useSelector((state) => roomSelectors.getMutedRecordings(state));
  const [videosReady, setVideosReady] = useState([]);
  const [durationVideo, setDurationVideo] = useState(0);
  const [recordingChooseView, setRecordingChooseView] = useState({ open: true, view: null });
  const streamContext = useContext(StreamContext);
  const containerMainRef = useRef();
  const [startTimer, setStartTimer] = useState(false);

  const dispatch = useDispatch();

  const [time, setTime] = useState({ value: 0, complete: 0, jumped: 0, seek: 0, seekJump: 0 });

  useEffect(() => {
    if (workout.id) {
      dispatch(sessionWorkoutActions.getEventsStart(workout.id));
    }
  }, [dispatch, workout]);

  const loadingVideos = () => {
    if (workout.method === 'video') {
      return false;
    }

    return workout.recordingVideos.length !== videosReady.length;
  };

  const togglePlayRecordings = (value) => {
    dispatch(roomActions.setPlayRecordings(value));
  };

  const executeEvents = (events) => {
    if (!events) {
      return;
    }
    events.forEach((event) => {
      if (event.kind === workoutEvent.change_section) {
        streamContext.eventSideBar.setCurrentSection(event.data, false);
      }

      if (event.kind === workoutEvent.start_timer) {
        streamContext.eventSideBar.setCurrentSection(event.data, false);
        setStartTimer(true);
      }

      if (event.kind === workoutEvent.reset_timer && streamContext.eventSideBar.resetTimer) {
        streamContext.eventSideBar.resetTimer();
      }
    });
  };

  useEffect(() => {
    if (!playingRecordings) {
      return;
    }

    if (startTimer && streamContext.eventSideBar.startTimer) {
      streamContext.eventSideBar.startTimer();
      setStartTimer(false);
    }
  }, [streamContext.eventSideBar.startTimer, startTimer, playingRecordings]); //eslint-disable-line

  useEffect(() => {
    if (workout) {
      if (workout.recordingVideos.length === 1 || workout.live || isSafari) {
        setRecordingChooseView({ open: false });
      }
    }
  }, [workout]);

  const resetEvents = () => {
    if (streamContext.eventSideBar.currentSection) {
      streamContext.eventSideBar.resetTimer();
      streamContext.eventSideBar.setCurrentSection(null, false);
    }
  };

  const isFullScreen = (user) => !!activeRecordings.find((x) => x === user.id);

  const setRecordingFullScreen = (rec) => {
    let actives = [];
    if (isFullScreen(rec.user)) {
      actives = activeRecordings.filter((x) => x !== rec.user.id);
    } else {
      actives = [...activeRecordings, rec.user.id];
    }
    setActiveRecordings(actives);
  };

  const getSize = (rec, newSize, live = false) => {
    let width = newSize;
    let resWidth = 0;
    let resHeight = 0;
    if (rec) {
      resWidth = rec.resWidth;
      resHeight = rec.resHeight;
    }
    if (live) {
      resWidth = 640;
      resHeight = 480;
    }

    let height = Math.round((width / resWidth) * resHeight, 0);
    if (height > size.height - 200) {
      height = size.height - 200;
      width = (height / resHeight) * resWidth;
    }

    return {
      width,
      height,
    };
  };

  const showModalEnded = () => {
    streamContext.setOpenModalEnded((prev) => ({ ...prev, open: true }));
  };

  const setVideoReady = ({ url }) => {
    setVideosReady((prev) => [...prev.filter((x) => x !== url), url]);
  };

  const videos = () => {
    if (workout.method === 'video') {
      const item = {
        user: {
          id: workout?.coaches[0]?.id || workout?.user?.id,
          name: workout?.coaches[0]?.name || workout?.user?.name,
        },
        isCoach: true,
        video: true,
        vimeoUrl: workout.videoLink,
      };
      return [item, ...workout.recordingVideos];
    }
    return workout.recordingVideos;
  };

  return {
    size,
    workout,
    togglePlayRecordings,
    playingRecordings,
    executeEvents,
    resetEvents,
    time,
    setTime,
    containerMainRef,
    mutedRecordings,
    showModalEnded,
    recordingChooseView,
    setRecordingChooseView,
    loadingVideos,
    setVideosReady,
    setVideoReady,
    setRecordingFullScreen,
    getSize,
    videos: videos(),
    durationVideo,
    setDurationVideo,
  };
};
