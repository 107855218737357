import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from 'core/ui/Typography';
import PropTypes from 'prop-types';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { workoutsSelectors, workoutsActions } from 'modules/workouts';
import { DialogContent, Dialog, Chip } from '@material-ui/core';
import LoaderSm from 'core/ui/LoaderSm';
import FontIcon from 'core/ui/FontIcon';
import { ButtonPrimary } from 'core/ui/Button';
import Grid from 'core/ui/Grid';
import { dateExt, diffFromNow, time } from 'core/utils/formatters';
import LabelBreakLine from 'core/ui/LabelBreakLine';
import { useHistory } from 'react-router';
import Header from 'components/Workout/Show/Header';
import DialogTitle from 'core/ui/Dialog/DialogTitle';
import notifications from 'modules/notifications';
import TimerToStart from 'components/Workout/Grid/TimerToStart';

const useStyles = makeStyles({
  root: {
    backgroundColor: 'transparent',
  },
  btnClose: {
    position: 'absolute',
    right: 5,
    top: 0,
  },
});

export default function InfoEntry({ setOpenConfig, exit }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const workout = useSelector((state) => workoutsSelectors.getWorkout(state));
  const loading = useSelector((state) => workoutsSelectors.getLoading(state));
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);
  const history = useHistory();
  useEffect(() => {
    if (
      workout.id &&
      workout.registered &&
      workout.live &&
      ((workout.channel && workout.channel.planValid) || !workout.channel)
    ) {
      if (workout.registeredAs === 'inPerson') {
        dispatch(notifications.warning(t('workout.registeredInPerson')));
        setOpen(false);
        exit(false);
        return;
      }
      setOpen(false);
      setOpenConfig((prev) => ({
        ...prev,
        open: true,
      }));
    } else if (
      workout.registered &&
      workout.isCompetition &&
      workout.live &&
      workout.allowJoinWorkout
    ) {
      setOpen(false);
      setOpenConfig((prev) => ({
        ...prev,
        open: true,
      }));
    }

    if (workout.recorded && !workout.live) {
      if (workout.started) {
        setOpen(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, workout, dispatch]);

  const timeToStart = workout
    ? diffFromNow(workout.method !== 'live' ? workout.publishAt : workout.scheduledAt)
    : 0;

  const refresh = () => {
    dispatch(workoutsActions.getWorkoutStart(workout.id));
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogContent className={classes.root}>
        <DialogTitle onClose={() => history.push(`/workout/view/${workout.slug}`)}>
          <Typography mb={3} align="center" color="primary" variant="h2" component="span">
            {workout.name || (workout.firstType && workout.firstType.name)}
          </Typography>
        </DialogTitle>
        {!loading && (
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Box display="flex" justifyContent="center" flexDirection="column">
                <Box mt={2} mb={2}>
                  <Header workout={workout} />
                </Box>
                <Box my={3}>
                  <Typography variant="h6">{t('workout.info.entry')}</Typography>
                </Box>

                <>
                  <Box display="flex" justifyContent="center">
                    <FontIcon iconName="icon-live" size={20} />
                  </Box>
                  <Box display="flex" justifyContent="center">
                    <Typography ml={2} align="center" component="h6" variant="body1">
                      <span>
                        {dateExt(
                          workout.method === 'live' ? workout.scheduledAt : workout.publishAt,
                          t,
                        )}
                      </span>
                      <span>&nbsp;&bull;&nbsp;</span>
                      <span className="roboto-regular">
                        {time(workout.method === 'live' ? workout.scheduledAt : workout.publishAt)}
                      </span>
                    </Typography>
                  </Box>
                </>

                {timeToStart > 0 && timeToStart <= 600 && (
                  <Box display="flex" justifyContent="center" p={5}>
                    <TimerToStart workout={workout} />
                  </Box>
                )}

                {workout.recordingStatus === 'processing' && (
                  <Box my={5} display="flex" justifyContent="center">
                    <Chip
                      icon={<LoaderSm loading />}
                      color="primary"
                      label={t('workout.recording.processing')}
                    />
                  </Box>
                )}

                {workout.description && (
                  <Box>
                    <Typography align="center">{t('description')}</Typography>
                    <Typography align="center" className="roboto-regular">
                      <LabelBreakLine>{workout.description}</LabelBreakLine>
                    </Typography>
                  </Box>
                )}
                <Box mt={5} mx={5} display="flex">
                  <ButtonPrimary onClick={() => refresh()} style={{ width: '100%' }}>
                    {t('stream.config.refresh')}
                  </ButtonPrimary>
                  {workout.isCoach && !workout.recorded && (
                    <ButtonPrimary
                      onClick={() => history.push(`/workout/edit/${workout.id}`)}
                      style={{ width: '100%', marginLeft: 2 }}
                    >
                      {t('stream.config.edit')}
                    </ButtonPrimary>
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        )}

        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box display="flex" justifyContent="center">
              <LoaderSm width={100} loading={loading} />
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

InfoEntry.propTypes = {
  setOpenConfig: PropTypes.func,
};

InfoEntry.defaultProps = {
  setOpenConfig: () => {},
};
