/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import Box from 'core/ui/Box';
import Typography from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import { channelsSelectors } from 'modules/channels';
import { useDispatch, useSelector } from 'react-redux';
import Paper from 'core/ui/Paper';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { recurringsActions, recurringsSelectors } from 'modules/recurrings';
import notifications from 'modules/notifications';
import LoaderSm from 'core/ui/LoaderSm';
import { formatDateToApi } from 'core/utils/formatters';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { IconButton, Collapse } from '@material-ui/core';
import { sessionSelectors } from 'modules/session';
import { timeZoneName } from 'core/utils/formatters/date';
import { Refresh } from '@material-ui/icons';
import ModalEditRecurring from './ModalEditRecurring';
import ModalExtendRecurring from './ModalExtendRecurring';
import TableRecurrings from './TableRecurrings';
import ModalDaylightSavings from './ModalDaylightSavings';
import ModalStopRecurring from './ModalStopRecurring';
import MenuActions from './MenuActions';
import ModalBulkEditRecurring from './ModalBulkEditRecurring';

const RecurringManage = ({ setRefreshCalendar }) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  const isAuthenticated = useSelector((state) => sessionSelectors.getIsAuthenticated(state));
  const channel = useSelector((state) => channelsSelectors.getChannel(state));
  const recurrings = useSelector((state) => recurringsSelectors.getChannelRecurrings(state));
  const loadingChannelRecurrings = useSelector((state) =>
    recurringsSelectors.getLoadingChannelRecurrings(state),
  );
  const dispatch = useDispatch();
  const [modalDaylightSavings, setModalDaylightSavings] = useState({ open: false });
  const [extendModal, setModalExtend] = useState({ open: false });
  const [editModal, setModalEdit] = useState({ open: false });
  const [bulkEditModal, setBulkEditModal] = useState({ open: false });
  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    title: '',
    description: '',
    confirm: () => {},
    cancel: () => {},
  });

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(recurringsActions.getRecurringsByChannelStart(channel.id));
    }
  }, [dispatch, channel, isAuthenticated]);

  const [checkedAll, setCheckedAll] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);

  const toggleItem = (recId) => {
    if (checkedItems.find((x) => x === recId)) {
      setCheckedItems((prev) => prev.filter((x) => x !== recId));
    } else {
      setCheckedItems((prev) => [...prev, recId]);
    }
  };

  const toggleSelectAll = () => {
    if (checkedAll) {
      setCheckedItems([]);
      setCheckedAll(false);
    } else {
      setCheckedItems(recurrings?.map((x) => x.id));
      setCheckedAll(true);
    }
  };

  const refreshCalendar = () => {
    if (setRefreshCalendar) {
      setRefreshCalendar(true);
    }
  };

  const refreshRecurrings = () => {
    refreshCalendar();
    dispatch(recurringsActions.getRecurringsByChannelStart(channel.id));
  };

  const confirmStopRecurring = (disable, startAtRemoveWorkouts = null) => {
    setConfirmDialog((prevC) => ({ ...prevC, open: false }));

    dispatch(
      recurringsActions.stopRecurringStart(checkedItems, disable, startAtRemoveWorkouts, () => {
        dispatch(notifications.success(t('recurring.message.stop.success')));
        refreshCalendar();
        dispatch(recurringsActions.getRecurringsByChannelStart(channel.id));
      }),
    );
  };

  const stopRecurring = (disable = false) => {
    setConfirmDialog((prev) => ({
      ...prev,
      open: true,
      disable,
      title: t('recurring.confirm.title.stop'),
      cancel: () => setConfirmDialog((prevC) => ({ ...prevC, open: false })),
      confirm: ({ startAtRemoveWorkouts }) => confirmStopRecurring(disable, startAtRemoveWorkouts),
    }));
  };

  const extendRecurring = (recurring) => {
    setModalExtend((prev) => ({ ...prev, open: true, recurring }));
  };

  const editRecurring = (recurring) => {
    setModalEdit((prev) => ({ ...prev, open: true, recurring }));
  };

  const saveEditRecurring = (newRecurring) => {
    setModalExtend((prevC) => ({ ...prevC, open: false }));
    let startAt = null;
    if (newRecurring.startAt) {
      startAt = formatDateToApi(
        `${newRecurring.startAt.substr(0, 10)} ${newRecurring.startTimeAt.substr(11, 8)}`,
        timeZoneName(),
      );
    }

    dispatch(
      recurringsActions.extendRecurringStart(
        {
          ...newRecurring,
          startAt,
          stopRecurring: formatDateToApi(newRecurring.stopRecurring.substr(0, 10)),
        },
        () => {
          dispatch(notifications.success(t('recurring.message.extend.success')));
          refreshCalendar();
          dispatch(recurringsActions.getRecurringsByChannelStart(channel.id));
        },
      ),
    );
  };

  const callbackSaveEdit = () => {
    dispatch(notifications.success(t('recurring.message.edited.success')));
    refreshCalendar();
    dispatch(recurringsActions.getRecurringsByChannelStart(channel.id));
  };

  return (
    <>
      <ModalStopRecurring
        channel={channel}
        open={confirmDialog?.open}
        disable={confirmDialog.disable}
        confirm={confirmDialog.confirm}
        close={confirmDialog.cancel}
        recurringsIds={checkedItems}
      />

      <Paper className="paper-rounded">
        <Box p={3} display="flex" className="hover">
          <Box display="flex" alignItems="center" flexGrow={1} onClick={() => setOpen(!open)}>
            <AccessTimeIcon />
            <Typography ml={1} variant="h6">
              {t('channel.manageRecurring').toUpperCase()}
            </Typography>
            <LoaderSm loading={loadingChannelRecurrings} />
          </Box>

          <Box display="flex">
            <MenuActions
              actions={{ stopRecurring, setModalDaylightSavings, setBulkEditModal }}
              loading={loadingChannelRecurrings}
              buttonColor={channel?.customColors?.buttonColor}
              hasSelectedItems={checkedItems?.length > 0}
              itemsCount={checkedItems?.length}
            />

            <IconButton onClick={() => refreshRecurrings()}>
              <Refresh />
            </IconButton>

            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </Box>
        </Box>

        <Collapse in={open}>
          <Box>
            <TableRecurrings
              channel={channel}
              recurrings={recurrings}
              extendRecurring={extendRecurring}
              editRecurring={editRecurring}
              setRefreshCalendar={setRefreshCalendar}
              toggleItem={toggleItem}
              toggleSelectAll={toggleSelectAll}
              checkedAll={checkedAll}
              checkedItems={checkedItems}
            />
          </Box>
        </Collapse>
        {extendModal.recurring && (
          <ModalExtendRecurring
            open={extendModal.open}
            close={() => setModalExtend((prev) => ({ ...prev, open: false }))}
            recurring={extendModal.recurring}
            saveEditRecurring={saveEditRecurring}
            channel={channel}
          />
        )}
        <ModalDaylightSavings
          open={modalDaylightSavings?.open}
          close={() => setModalDaylightSavings({ open: false })}
          recurring={modalDaylightSavings.recurring}
          selectedRecurrings={checkedItems}
          channel={channel}
          callback={refreshRecurrings}
        />
        {editModal.recurring && (
          <ModalEditRecurring
            open={editModal.open}
            close={() => setModalEdit((prev) => ({ ...prev, open: false }))}
            recurring={editModal.recurring}
            saveEditRecurring={saveEditRecurring}
            callback={callbackSaveEdit}
          />
        )}
        {bulkEditModal.open && (
          <ModalBulkEditRecurring
            open={bulkEditModal.open}
            close={() => setBulkEditModal((prev) => ({ ...prev, open: false }))}
            selectedRecurrings={checkedItems}
            callback={callbackSaveEdit}
          />
        )}
      </Paper>
    </>
  );
};

RecurringManage.propTypes = {};

RecurringManage.defaultProps = {};

export default RecurringManage;
