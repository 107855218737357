import React from 'react';
import Box from 'core/ui/Box';
import { Skeleton } from '@material-ui/lab';
import Grid from 'core/ui/Grid';

const SkeletonMembershipReport = () => (
  <Box mt={5}>
    <Grid container spacing={3}>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <Skeleton variant="rect" animation="wave" style={{ height: 150, width: '100%' }} />
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <Skeleton variant="rect" animation="wave" style={{ height: 150, width: '100%' }} />
      </Grid>
    </Grid>

    <Box mt={3} display="flex">
      <Skeleton variant="rect" animation="wave" style={{ height: 200, width: '100%' }} />
    </Box>
    <Box mt={3} display="flex">
      <Skeleton variant="rect" animation="wave" style={{ height: 200, width: '100%' }} />
    </Box>
  </Box>
);

export default SkeletonMembershipReport;
