/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Typography, { fontStyle } from 'core/ui/Typography';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import Box from 'core/ui/Box';
import Grid from 'core/ui/Grid';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Paper from 'core/ui/Paper';

const FormLimituse = ({ currentPlan, setCurrentPlan }) => {
  const { t } = useTranslation();

  const Option = ({ label, onClick, checked }) => (
    <Box className="link-hover" mr={2} onClick={() => onClick()} display="flex" alignItems="center">
      {!checked ? (
        <RadioButtonUncheckedIcon color="primary" />
      ) : (
        <CheckCircleOutlineIcon color="primary" />
      )}
      <Typography ml={2} variant="body2">
        {label}
      </Typography>
    </Box>
  );

  useEffect(() => {
    if (currentPlan.customIncrement) {
      if (currentPlan.customIncrement === 'day' && currentPlan.limitUseIncrement === 'week') {
        setCurrentPlan((prev) => ({ ...prev, limitUseIncrement: 'day' }));
      }
      if (currentPlan.customIncrement === 'week' && currentPlan.limitUseIncrement === 'month') {
        setCurrentPlan((prev) => ({ ...prev, limitUseIncrement: 'week' }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPlan.customIncrement]);

  return (
    <Paper className="paper-rounded" p={3}>
      <Typography mb={2} style={fontStyle.label}>
        {t('channel.memberships.limitUse').toUpperCase()}
      </Typography>
      <Grid container spacing={5}>
        {currentPlan?.customIncrement !== 'limit0' && (
          <Grid item xs={12} md={6}>
            <Box>
              <Box display="flex" flexDirection="column">
                <Option
                  label={t('channel.memberships.custom.limit.renewFrequency')}
                  onClick={() =>
                    setCurrentPlan((prev) => ({ ...prev, limitUseIncrement: 'renew_frequency' }))
                  }
                  checked={currentPlan.limitUseIncrement === 'renew_frequency'}
                />

                <Option
                  label={t('channel.memberships.custom.limit.day')}
                  onClick={() => setCurrentPlan((prev) => ({ ...prev, limitUseIncrement: 'day' }))}
                  checked={currentPlan.limitUseIncrement === 'day'}
                />

                {currentPlan.customIncrement !== 'day' && (
                  <Option
                    label={t('channel.memberships.custom.limit.week')}
                    onClick={() =>
                      setCurrentPlan((prev) => ({ ...prev, limitUseIncrement: 'week' }))
                    }
                    checked={currentPlan.limitUseIncrement === 'week'}
                  />
                )}

                {currentPlan.customIncrement !== 'day' &&
                  currentPlan.customIncrement !== 'week' && (
                    <Option
                      label={t('channel.memberships.custom.limit.month')}
                      onClick={() =>
                        setCurrentPlan((prev) => ({ ...prev, limitUseIncrement: 'month' }))
                      }
                      checked={currentPlan.limitUseIncrement === 'month'}
                    />
                  )}
              </Box>
            </Box>
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <Box>
            {currentPlan.limitUseIncrement !== 'renew_frequency' && (
              <Box mb={3}>
                <TextFieldRounded
                  type="number"
                  value={currentPlan.limitUseIncrementNumber || ''}
                  pattern="[1-9]*"
                  placeholder={t(`channel.memberships.custom.renewEvery`).replaceAll(
                    '#{increment}',
                    currentPlan.limitUseIncrement,
                  )}
                  label={t(`channel.memberships.custom.renewEvery`)
                    .replaceAll('#{increment}', currentPlan.limitUseIncrement)
                    .toUpperCase()}
                  onChange={(v) =>
                    setCurrentPlan((prev) => ({ ...prev, limitUseIncrementNumber: v > 0 ? v : 0 }))
                  }
                  required
                  bordered
                />
              </Box>
            )}
            <Box>
              <TextFieldRounded
                type="number"
                value={currentPlan.limitUse || ''}
                pattern="[0-9]*"
                placeholder={t('channel.placeholder.membershipsCustomLimitUse')}
                label={t('channel.placeholder.membershipsCustomLimitUse').toUpperCase()}
                onChange={(v) => setCurrentPlan((prev) => ({ ...prev, limitUse: v > 0 ? v : 0 }))}
                required={!currentPlan.renewable}
                bordered
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

FormLimituse.defaultProps = {
  currentPlan: {},
  setCurrentPlan: () => {},
};

export default FormLimituse;
