/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import { Dialog } from '@material-ui/core';
import { ButtonPrimary } from 'core/ui/Button';
import Texture from 'core/ui/Texture';
import SelectBoxObject from 'core/ui/SelectBoxObject';

import TextAreaFieldRounded from 'core/ui/TextAreaFieldRounded';
import DatePickerRounded from 'core/ui/DatePickers/DatePickerRounded';
import useForm from './form';

const ModalFormList = ({ open, item, close, callback, mode, channel, coach }) => {
  const { t } = useTranslation();
  const { customPayments, setValue, watch, handleSubmit, onSubmit, errors, loading } = useForm({
    mode,
    item,
    callback,
    channel,
    coach,
  });

  return (
    <Dialog maxWidth="sm" scroll="body" fullWidth onClose={() => close()} open={open}>
      <Texture>
        <Box p={5}>
          <Box mb={3}>
            <SelectBoxObject
              label={t('channel.customPayment').toUpperCase()}
              options={customPayments}
              value={watch('channelsCustomPaymentId')}
              setValue={(v) => setValue('channelsCustomPaymentId', v)}
              error={errors.channelsCustomPaymentId}
              required
            />
          </Box>

          <Box mb={3}>
            <DatePickerRounded
              label={t('label.date').toUpperCase()}
              onChange={(v) => setValue('date', v)}
              value={watch('date')}
              disablePast={false}
              usFormat={channel?.useUsDateFormat}
              autoOk
              error={errors?.date}
              bordered
              required
            />
          </Box>

          <Box mb={3}>
            <TextAreaFieldRounded
              value={watch('description')}
              onChange={(v) => setValue('description', v)}
              placeholder={t('label.description')}
              error={errors.description}
              required
              label={t('label.description').toUpperCase()}
            />
          </Box>

          <Box mt={3} display="flex" justifyContent="flex-end">
            <ButtonPrimary disabled={loading} onClick={handleSubmit(onSubmit)}>
              {t('button.save')}
            </ButtonPrimary>
          </Box>
        </Box>
      </Texture>
    </Dialog>
  );
};

ModalFormList.defaultProps = {
  close: () => {},
  callback: () => {},
  open: false,
  mode: 'new',
};

ModalFormList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  close: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  callback: PropTypes.func,
  open: PropTypes.bool,
  mode: PropTypes.string,
};

export default ModalFormList;
