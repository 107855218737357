/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Typography, { fontStyle } from 'core/ui/Typography';
import SFDataTable from 'core/ui/DataTable';
import { Link } from '@material-ui/core';

export default function DataTableMembers({ channel, newMembers, addColums }) {
  const [perPage, setPerPage] = useState(5);
  let columns = [
    {
      name: 'ID',
      selector: 'id',
      sortable: false,
      width: '80px',
      cell: (row) => <Typography style={fontStyle.small}>{row?.id}</Typography>,
    },
    {
      name: 'Name',
      selector: (row) => row?.name,
      sortable: false,
      cell: (row) => (
        <Link
          className="hover"
          target="_blank"
          href={`/channel/manage/${channel?.id}/members/${row?.id}`}
        >
          <Typography style={fontStyle.small}>{row?.name || row?.email || row?.phone}</Typography>
        </Link>
      ),
    },
  ];

  if (addColums) {
    columns = [...columns, ...addColums];
  }

  return (
    <SFDataTable
      title={null}
      columns={columns}
      data={newMembers}
      pagination
      showExport={false}
      noHeader
      paginationPerPage={perPage}
      onChangeRowsPerPage={(newValue) => {
        if (setPerPage) {
          setPerPage(newValue);
        }
      }}
      paginationRowsPerPageOptions={[5, 10, 15, 20, 30, 50, 100]}
    />
  );
}
