import React, { createContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { renderRoutes } from 'react-router-config';
import { Hidden } from '@material-ui/core';
import { useLocation } from 'react-router';
import Box from 'core/ui/Box';
import { useSelector } from 'react-redux';
import { sessionSelectors } from 'modules/session';
import ModalChannelProfileSelector from 'components/Channel/ModalChannelProfileSelector';
import { HelpVideoManagerProvider } from 'contexts/HelpVideoManagerContext';
import AppBarMobile from './components/AppBarMobile';
import { HelpCollapse } from './components/Helper/HelpCollapse';
import LayoutContextProvider from './LayoutContextProvider';
import AppBarAndDrawerContent from './AppBarAndDrawerContent';

export const LayoutContext = createContext();
const drawerWidth = 250;
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  toolbar: {
    height: '72px',
    [theme.breakpoints.down('sm')]: {
      height: '45px',
    },
  },
  spaceTabBottom: {
    height: '0px',
    [theme.breakpoints.down('sm')]: {
      height: '72px',
    },
  },
  drawer: {
    justifyContent: 'center',
    flexShrink: 0,
    border: 'none',
    backgroundColor: '#404041',
  },
  drawerOpen: {
    width: drawerWidth,
    border: 'none',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 0,
    border: 'none',
  },
  content: {
    flexGrow: 1,
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '66px',
    },
  },
}));

function Layout({ route: { routes } }) {
  const isAuthenticated = useSelector((state) => sessionSelectors.getIsAuthenticated(state));
  const currentUser = useSelector((state) => sessionSelectors.getUser(state));
  const classes = useStyles();
  const location = useLocation();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <LayoutContextProvider>
        <HelpVideoManagerProvider>
          <AppBarAndDrawerContent classes={classes} />

          <Hidden smDown>
            <Box style={{ zIndex: 200, top: 72, position: 'sticky' }}>
              <HelpCollapse />
            </Box>
          </Hidden>

          <>{renderRoutes(routes)}</>

          <Hidden only={['md', 'lg', 'xl']}>
            <div className={classes.spaceTabBottom} />
            <Box style={{ zIndex: 200, bottom: 72, position: 'sticky' }}>
              <HelpCollapse />
            </Box>

            <AppBarMobile />
          </Hidden>

          {location?.pathname?.indexOf('/channel') === -1 &&
            location?.pathname?.indexOf('/calendar') === -1 &&
            location?.pathname?.indexOf('/competition') === -1 &&
            isAuthenticated &&
            !!currentUser?.name &&
            !currentUser?.channel?.id && <ModalChannelProfileSelector noRedirect />}
        </HelpVideoManagerProvider>
      </LayoutContextProvider>
    </div>
  );
}

export default Layout;
