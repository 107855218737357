/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import Box from 'core/ui/Box';
import Record from 'core/ui/RecordVideo/Record';
import FormData from './FormData';
import useForm from './form';
import PopUpValidation from './PopUpValidation';

export const CompetitionContext = createContext();

const Form = ({ mode }) => {
  const form = useForm({ mode });

  return (
    <CompetitionContext.Provider value={{ ...form }}>
      <Box>
        <Record
          setOpen={form.setModalPreviewVideo}
          open={form.modalPreviewVideo}
          setVideo={form.setVideo}
          duration={5 * 60000}
        />
      </Box>
      <PopUpValidation
        errors={form.errorsPopup}
        setOpen={form.setPopUpValidation}
        open={form.popUpValidation}
      />
      <Box mt={3}>
        <Box mb={5}>
          <FormData />
        </Box>
      </Box>
    </CompetitionContext.Provider>
  );
};

Form.propTypes = {
  mode: PropTypes.string,
};

Form.defaultProps = {
  mode: 'new',
};

export default Form;
