/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import Box from 'core/ui/Box';
import Modules from 'modules';
import useActions from 'modules/map/useActions';
import Typography, { fontStyle } from 'core/ui/Typography';
import { gymDate, time } from 'core/utils/formatters';
import Divider from 'core/ui/Divider';
import AvatarCoach from 'components/Profile/AvatarCoach';
import { AppBar, DialogContent, IconButton, Toolbar } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import Conversation from './Conversation';

const ContactConversation = ({ channel, contactForm, userId, close }) => {
  const { request } = useActions();

  const [item, setItem] = useState(null);

  const getContactForm = () => {
    request({
      action: Modules.contactForms.actions.getContactFormByUser,
      data: {
        channelId: channel?.id,
        userId,
      },
      options: {
        onSuccess: (data) => {
          setItem(data);
        },
      },
    });
  };

  useEffect(() => {
    if (userId && channel?.id && !contactForm) {
      getContactForm();
    }

    if (contactForm) {
      setItem(contactForm);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel?.id, userId, contactForm]);

  return (
    <>
      <AppBar className="tw-relative tw-bg-white">
        <Toolbar>
          <div className="tw-flex tw-w-full tw-flex-row tw-items-center tw-gap-2 tw-p-1">
            {item?.user && <AvatarCoach user={item?.user} />}
            <Box flexGrow={1}>
              <Typography className="tw-font-bold tw-text-gray-800">{item?.name}</Typography>
              <Typography className="tw-text-gray-800">{item?.phone}</Typography>
              <Typography className="tw-text-gray-800">{item?.email}</Typography>
            </Box>

            <Box>
              <Typography color="textSecondary" style={fontStyle.small}>
                {gymDate(item?.createdAt)}&nbsp;{time(item?.createdAt)}
              </Typography>
            </Box>
            <div className="">
              <IconButton onClick={close} className="tw-rounded-full tw-bg-white tw-p-1">
                <Close />
              </IconButton>
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <Divider />
      <DialogContent className="tw-bg-gray-100">
        <Conversation channel={channel} getContactForm={getContactForm} item={item} />
      </DialogContent>
    </>
  );
};

export default ContactConversation;
