import React from 'react';

import { useTranslation } from 'react-i18next';

import Box from 'core/ui/Box';
import Grid from 'core/ui/Grid';
import Typography from 'core/ui/Typography';
import TagsChosen from 'core/ui/TagsChosen';

// eslint-disable-next-line react/prop-types
export default ({ watch, setValue, errors }) => {
  const { t } = useTranslation();

  return (
    <>
      <Typography color="primary" mb={3} variant="h5">
        {t('profile.title.interests').toUpperCase()}
      </Typography>
      <Grid container>
        <Grid px={5} item xs={12} sm={12} md={12} lg={12}>
          <Box mb={4}>
            <Typography ml={2} mb={2} variant="body2">
              {t('profile.label.interests')}
            </Typography>
            <TagsChosen
              placeholder={t('profile.placeholder.interests')}
              setValue={(values) => setValue('interests', values)}
              error={errors}
              limit={6}
              kind="interest"
              val={watch('interests') ? [...watch('interests')] : []}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
