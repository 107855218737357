import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Typography from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { competitionsActions } from 'modules/competitions';
import { ButtonPrimary } from 'core/ui/Button';
import { useDispatch } from 'react-redux';
import LoaderSm from 'core/ui/LoaderSm';
import notifications from 'modules/notifications';
import Modules from 'modules';
import useActions from 'modules/map/useActions';
import { competitionsUsersModule } from 'modules/competitionsUsers';
import useSelectors from 'modules/map/useSelectors';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import Checkbox from 'core/ui/Checkbox';
import Radio from 'core/ui/Radio';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#f3f3f3',
  },
  form: {
    backgroundColor: '#ffffff',
    backgroundRepeat: 'repeat',
    padding: 10,
  },
}));

export default function ModalCategory({ competition, open, setOpen, object, regType }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { request } = useActions();
  const [teamData, setTeamData] = useState({
    company: object?.company,
    competitionsCategoryIds:
      object?.competitionsUsersCategories?.map((x) => x?.competitionsCategory?.id) || [],
  });
  const dispatch = useDispatch();
  const loadingRegistration = useSelectors(competitionsUsersModule, 'loading');

  const editTeam = () => {
    setOpen((prev) => ({ ...prev, open: false }));

    request({
      action: Modules.competitionsUsers.actions.update,
      data: {
        regType,
        competitionId: competition.id,
        userId: object?.user?.id,
        ...teamData,
      },
      options: {
        onSuccess: () => {
          dispatch(competitionsActions.getCompetitionStart(competition.id));
          dispatch(notifications.success(t(`competition.${regType}.edit.success`)));
        },
        onError: (error) => {
          dispatch(notifications.error(error));
        },
      },
    });
  };

  const isChecked = (item) => !!teamData?.competitionsCategoryIds.find((x) => x === item.id);

  const check = (item) => {
    if (isChecked(item)) {
      setTeamData((prev) => ({
        ...prev,
        competitionsCategoryIds: teamData?.competitionsCategoryIds.filter((x) => x !== item.id),
      }));
    } else {
      setTeamData((prev) => ({
        ...prev,
        competitionsCategoryIds:
          regType === 'vendor' ? [item?.id] : [...prev.competitionsCategoryIds, item?.id],
      }));
    }
  };

  return (
    <div>
      <Dialog
        PaperProps={{ style: { borderRadius: 5 } }}
        maxWidth="xs"
        fullWidth
        scroll="body"
        onClose={() => setOpen(false)}
        open={open}
      >
        <div className={classes.root}>
          <Box px={5} py={2}>
            <Box display="flex">
              <Box flexGrow={1}>
                <Typography mt={3} mb={3} align="center" color="primary" variant="h4">
                  {object.teamName}
                </Typography>
              </Box>
              <IconButton onClick={() => setOpen((prev) => ({ ...prev, open: false }))}>
                <Close />
              </IconButton>
            </Box>

            {regType === 'vendor' && (
              <Box mb={3}>
                <TextFieldRounded
                  name="name"
                  onChange={(v) =>
                    setTeamData((prev) => ({
                      ...prev,
                      company: v,
                    }))
                  }
                  value={teamData?.company || ''}
                  placeholder={t('competition.registration.label.company')}
                  label={t('competition.registration.label.company')}
                />
              </Box>
            )}

            <Box mb={5}>
              <Typography variant="body2" mb={2}>
                {t(
                  regType === 'vendor'
                    ? 'competition.label.vendorsCategories'
                    : 'competition.label.volunteersCategories',
                )}
              </Typography>
              {(regType === 'vendor'
                ? competition.vendorsCategories
                : competition.volunteersCategories
              ).map((item) => (
                <Box key={item.id} m={1}>
                  {regType === 'vendor' ? (
                    <Radio
                      checked={isChecked(item)}
                      onClick={() => check(item)}
                      label={item.name}
                      color={competition?.buttonColor}
                    />
                  ) : (
                    <Checkbox
                      checked={isChecked(item)}
                      onClick={() => check(item)}
                      label={item.name}
                      color={competition?.buttonColor}
                    />
                  )}
                </Box>
              ))}
            </Box>

            <ButtonPrimary
              fullWidth
              disabled={loadingRegistration}
              buttonColor={competition?.buttonColor}
              onClick={() => editTeam()}
            >
              {loadingRegistration && <LoaderSm loading width={15} />}
              {t('button.save')}
            </ButtonPrimary>
          </Box>
        </div>
      </Dialog>
    </div>
  );
}
