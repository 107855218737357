import React from 'react';
import Typography from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import { Link } from '@material-ui/core';
import { isIOS, isMobile } from 'react-device-detect';
import { AndroidOutlined, Apple } from '@material-ui/icons';
import { ButtonPrimary } from '../Button';
import Box from '../Box';

export default function DownloadApp() {
  const { t } = useTranslation();

  const googleAppUrl = 'https://play.google.com/store/apps/details?id=live.streamfitness.app.go';
  const appleAppUrl = 'https://apps.apple.com/us/app/stream-fitness/id1532265066';

  return (
    <Box display="flex" justifyContent="center">
      <ButtonPrimary>
        <Link
          underline="none"
          target="_blank"
          href={isMobile && isIOS ? appleAppUrl : googleAppUrl}
        >
          <Box display="flex" alignItems="center" justifyContent="center">
            {!isIOS ? (
              <AndroidOutlined fontSize="small" style={{ color: 'white' }} />
            ) : (
              <Apple fontSize="small" style={{ color: 'white' }} />
            )}
            <Typography align="center" ml={1} style={{ color: 'white' }} variant="body2">
              {t('button.downloadApp')}
            </Typography>
          </Box>
        </Link>
      </ButtonPrimary>
    </Box>
  );
}
