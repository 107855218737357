/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Grid from 'core/ui/Grid';
import Box from 'core/ui/Box';
import { searchSelectors } from 'modules/search';
import { Slide } from '@material-ui/core';
import Header from './Header';
import Featured from './Sections/Featured';
import Results from './Sections/Results';
import ViewMore from './ViewMore/index';
import './Sections/Item.css';
import { SearchContext } from './index';

export default ({ channelId, hideFilters, hideUpcoming, onClickCard, buttonColor }) => {
  const results = useSelector((state) => searchSelectors.getSearch(state));
  const workoutResults = useSelector((state) => searchSelectors.getWorkoutResults(state));
  const loading = useSelector((state) => searchSelectors.getLoading(state));
  const model = 'Workout';
  const [viewMore, setViewMore] = useState({
    open: false,
  });

  useEffect(() => {
    if (loading) {
      setViewMore({
        open: false,
      });
    }
  }, [loading]);

  return (
    <Box py={3}>
      <SearchContext.Provider value={{ setViewMore, viewMore }}>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Header channelId={channelId} hideFilters={hideFilters} model={model} />

            {viewMore.open && (
              <Slide direction="up" in>
                <Box mt={3} mb={3}>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <ViewMore
                        channelId={channelId}
                        onClickCard={onClickCard}
                        model={model}
                        viewMore={viewMore}
                        setViewMore={setViewMore}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Slide>
            )}

            {!viewMore.open && (
              <>
                {results || workoutResults ? (
                  <Box mt={3}>
                    <Results
                      channelId={channelId}
                      onClickCard={onClickCard}
                      model={model}
                      results={model === 'Workout' ? workoutResults : results}
                      loading={loading}
                      hideUpcoming={hideUpcoming}
                      buttonColor={buttonColor}
                    />
                  </Box>
                ) : (
                  <Box mt={3}>
                    <Featured
                      channelId={channelId}
                      hideUpcoming={hideUpcoming}
                      onClickCard={onClickCard}
                      model={model}
                      buttonColor={buttonColor}
                    />
                  </Box>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </SearchContext.Provider>
    </Box>
  );
};
