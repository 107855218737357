import React, { useEffect, useRef, useState } from 'react';
import Box from 'core/ui/Box';
import { Skeleton } from '@material-ui/lab';
import { ButtonCompetition } from 'core/ui/Button';
import { workoutsActions, workoutsSelectors } from 'modules/workouts';
import { useDispatch, useSelector } from 'react-redux';
import Paper from 'core/ui/Paper';
import Typography, { fontStyle } from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import Teams from './Teams';
import Gallery from './Gallery';
import HeaderPlayer from './HeaderPlayer';
import useGalleryWatch from './useGalleryWatch';

const CompetitionWatchGalleryView = ({ loading, workout, competition }) => {
  const [currentDivision, setCurrentDivision] = useState(null);
  const leaderboard = useSelector((state) => workoutsSelectors.getWorkoutSectionLeaderboard(state));
  const dispatch = useDispatch();
  const history = useHistory();
  const [view, setView] = useState('gallery');
  const { t } = useTranslation();
  const player = useGalleryWatch({ workout });
  const elementRef = useRef();
  const allowWatch = workout?.allowWatch && workout.hasRecordings;

  const hasDivisions = competition.divisions && competition.divisions.length > 0;

  useEffect(() => {
    if (workout && workout.id && allowWatch && (!hasDivisions || !!currentDivision)) {
      dispatch(
        workoutsActions.getWorkoutSectionLeaderboardStart(workout.id, 'first', {
          division_id: currentDivision,
        }),
      );
    }
    return () => {
      dispatch(workoutsActions.clearWorkoutLeaderboard());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, workout, allowWatch, currentDivision]);

  useEffect(() => {
    if (workout && workout.id && competition.id && !allowWatch) {
      history.push(`/competition/view/${competition.id}`);
    }
  }, [history, dispatch, workout, competition, allowWatch]);

  const onBackClick = () => {
    history.push(`/competition/view/${competition.id}`);
  };

  useEffect(() => {
    if (!currentDivision && competition.divisions && competition.divisions.length > 0) {
      setCurrentDivision(competition.divisions[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [competition]);

  useEffect(() => {
    if (workout && workout.recordingVideos && leaderboard) {
      const arr = leaderboard
        .filter((x) => !!workout.recordingVideos.find((y) => y.user.id === x.user.id))
        .slice(0, 4)
        .map((x) => x.user.id);
      player.setCheckedTeams(arr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workout, leaderboard]);

  useEffect(() => {
    if (!loading && elementRef && elementRef.current && elementRef.current.scrollIntoView) {
      elementRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [loading, player.checkedTeams]);

  if (!workout.id || !competition.id || loading) {
    return (
      <Box my={3}>
        <Skeleton variant="rect" animation="wave" style={{ height: 200, width: '100%' }} />
        <Skeleton
          variant="rect"
          animation="wave"
          style={{ height: 200, marginTop: 3, width: '100%' }}
        />
        <Skeleton
          variant="rect"
          animation="wave"
          style={{ height: 300, marginTop: 3, width: '100%' }}
        />
      </Box>
    );
  }
  return (
    <Box>
      <Paper className="paper-rounded opacity-80" p={3}>
        <Box ref={elementRef} display="flex" alignItems="center">
          <Box flexGrow={1} pl={5}>
            <Typography variant="h5" color="secondary">
              {competition.name}
            </Typography>
          </Box>
          <ButtonCompetition
            style={{ backgroundColor: competition.buttonColor }}
            onClick={() => onBackClick()}
          >
            {t('button.backToCompetition')}
          </ButtonCompetition>
        </Box>
      </Paper>
      <Box mt={1}>
        <HeaderPlayer
          competition={competition}
          player={player}
          buttonColor={competition?.buttonColor}
          workout={workout}
        />
      </Box>
      <Box my={1}>
        <Paper className="paper-rounded opacity-80" p={3}>
          {view === 'gallery' ? (
            <Box>
              {workout && workout.id && (
                <Gallery
                  player={player}
                  workout={workout}
                  leaderboard={leaderboard}
                  checkedTeams={player.checkedTeams}
                  buttonColor={competition.buttonColor}
                />
              )}

              <Box display="flex" justifyContent="center" mt={5}>
                <ButtonCompetition
                  style={{ backgroundColor: competition.buttonColor }}
                  onClick={() => {
                    player.reset();
                    setView('teams');
                  }}
                >
                  <Typography p={3} style={fontStyle.xLarge}>
                    {t('button.chooseTeams')}
                  </Typography>
                </ButtonCompetition>
              </Box>
            </Box>
          ) : (
            <Box mt={player.checkedTeams.length > 0 ? 5 : 0} mx={3}>
              {workout && workout.id && (
                <Teams
                  player={player}
                  competition={competition}
                  leaderboard={leaderboard}
                  currentDivision={currentDivision}
                  setCurrentDivision={setCurrentDivision}
                  workout={workout}
                />
              )}

              <Box display="flex" justifyContent="center" mt={5}>
                <ButtonCompetition
                  style={{ backgroundColor: competition.buttonColor }}
                  onClick={() => {
                    player.reset();
                    setView('gallery');
                  }}
                >
                  <Typography p={3} style={fontStyle.xLarge}>
                    {t('button.watchTeams')}
                  </Typography>
                </ButtonCompetition>
              </Box>
            </Box>
          )}
        </Paper>
      </Box>
    </Box>
  );
};

export default CompetitionWatchGalleryView;
