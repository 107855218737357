import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { InputBase } from '@material-ui/core';
import Box from 'core/ui/Box';
import { useDispatch, useSelector } from 'react-redux';
import { InputWrapper } from 'core/ui/ChosenComponents';
import { ButtonPrimary } from 'core/ui/Button';
import FontIcon from 'core/ui/FontIcon';
import LoaderSm from 'core/ui/LoaderSm';
import { useTranslation } from 'react-i18next';
import { searchActions, searchSelectors } from 'modules/search';
import Scrollbars from 'react-custom-scrollbars';
import Paper from 'core/ui/Paper';
import Typography from 'core/ui/Typography';
import { secondaryLight } from 'core/ui/Colors';
import ListUsers from './ListUsers';
import ModalAddMembers from '../Manage/Tabs/Members/AddMembers/Modal';

const SelectorChannelUser = ({ onSelectedUser, channelId, buttonColor, showCreateButton }) => {
  const dispatch = useDispatch();
  const dataUsers = useSelector((state) => searchSelectors.getChannelMembers(state));
  const loadingUsers = useSelector((state) => searchSelectors.getLoadingUsers(state));
  const { t } = useTranslation();
  const [modalAddMember, setModalAddMember] = useState(false);
  const [query, setQuery] = useState('');
  const [showInfo, setShowInfo] = useState(false);

  const search = (p) => {
    setShowInfo(true);
    dispatch(
      searchActions.getSearchChannelMembersStart({
        query,
        channelId,
        page: p,
      }),
    );
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      search(1);
    }
  };

  useEffect(() => {
    if (query?.length > 3) {
      search(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(
    () => () => {
      dispatch(searchActions.clearSearchUsers());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    if (!query) {
      dispatch(searchActions.clearSearchUsers());
    }
  }, [dispatch, query]);

  const callbackSaveMember = (user) => {
    onSelectedUser(user);
  };

  return (
    <>
      {modalAddMember && (
        <ModalAddMembers
          callbackSave={callbackSaveMember}
          channelId={channelId}
          close={() => setModalAddMember(false)}
          form
        />
      )}

      <Box className="paper-rounded" style={{ width: '100%', borderRadius: 30 }} pb={5}>
        <Box>
          <InputWrapper style={{ border: `1px solid ${secondaryLight}` }}>
            <Box display="flex" style={{ width: '100%' }}>
              <Box display="flex" flexGrow="1" flexWrap="wrap">
                <Box display="flex" flexGrow="1" alignItems="center">
                  <InputBase
                    autoFocus
                    value={query}
                    onKeyDown={(e) => handleKeyDown(e)}
                    onChange={(e) => setQuery(e.target.value)}
                    style={{
                      minWidth: 30,
                      width: '100%',
                      margin: '4px',
                    }}
                    placeholder={t('search.users.placeholder')}
                  />
                </Box>
              </Box>

              <Box>
                <ButtonPrimary
                  onClick={() => search(1)}
                  buttonColor={buttonColor}
                  style={{
                    height: '100%',
                    borderRadius: '5px',
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    padding: 10,
                  }}
                >
                  {!loadingUsers && <FontIcon iconName="icon-search" color="white" size={24} />}
                  <LoaderSm width={24} loading={loadingUsers} />
                </ButtonPrimary>
              </Box>
            </Box>
          </InputWrapper>
        </Box>
        {!loadingUsers && showInfo && (
          <Paper mt={2} p={3} className="paper-rounded">
            <Box display="flex" justifyContent="center" alignItems="center">
              <LoaderSm loading={loadingUsers} />
            </Box>

            <Box display="flex" justifyContent="center" alignItems="center">
              {query && dataUsers && dataUsers.length === 0 && (
                <Typography>
                  {t('channel.members.search.notFound').replace('#{text}', query)}
                </Typography>
              )}
            </Box>

            <Scrollbars autoHeight autoHeightMax={300}>
              <ListUsers onSelectedUser={onSelectedUser} users={dataUsers} />
            </Scrollbars>
          </Paper>
        )}
        {!loadingUsers && showCreateButton && (
          <Box p={5} display="flex" alignItems="center" justifyContent="center">
            {showInfo && (
              <Box mr={1}>
                <Typography>{t('channel.members.search.createNew')}</Typography>
              </Box>
            )}
            <ButtonPrimary buttonColor={buttonColor} onClick={() => setModalAddMember(true)}>
              {t('button.createNewUser')}
            </ButtonPrimary>
          </Box>
        )}
      </Box>
    </>
  );
};

SelectorChannelUser.propTypes = {
  onSelectedUser: PropTypes.func,
  showCreateButton: PropTypes.bool,
};

SelectorChannelUser.defaultProps = {
  onSelectedUser: () => {},
  showCreateButton: true,
};

export default SelectorChannelUser;
