import api from 'core/api';
import { call, put } from 'redux-saga/effects';

export const usersMessagesModule = 'usersMessages';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const actions = {
  getMessages: {
    module: usersMessagesModule,
    name: 'getMessages',
    api: (params) => api.get(`/users_messages`, { params }),
    *sagas(Creators, { params, options }) {
      try {
        const resp = yield call(actions.getMessages.api, params);
        yield put(Creators.getMessagesSuccess(resp.data, resp.pagination));
        if (options.onSuccess) {
          options.onSuccess(resp.data, resp.pagination);
        }
      } catch (error) {
        if (options.onError) {
          options.onError(error);
        }
        yield put(Creators.getMessagesError());
      }
    },
    params: {
      start: ['params'],
      error: [],
      success: ['data', 'pagination'],
    },
    state: loadingStates,
  },
  update: {
    module: usersMessagesModule,
    name: 'update',
    api: (data) => api.put(`/users_messages/${data.id}`, data),
    params: {
      start: ['params'],
      error: [],
      success: [],
    },
    state: loadingStates,
  },
  destroy: {
    module: usersMessagesModule,
    name: 'destroy',
    api: (data) => api.delete(`/users_messages/${data.id}`, data),
    params: {
      start: ['params'],
      error: [],
      success: [],
    },
    state: loadingStates,
  },
  getMessageUnredCount: {
    module: usersMessagesModule,
    name: 'getMessageUnredCount',
    api: (data) => api.get(`/users_messages/unread_count`, data),
    params: {
      start: ['params'],
      error: [],
      success: ['unreadCount'],
    },
    state: loadingStates,
  },
  markAllAsRead: {
    module: usersMessagesModule,
    name: 'markAllAsRead',
    api: (data) => api.get(`/users_messages/mark_all_as_read`, data),
    params: {
      start: ['params'],
      error: [],
      success: [''],
    },
    state: loadingStates,
  },
  setMessages: {
    module: usersMessagesModule,
    name: 'setMessages',
    params: {
      start: ['params'],
      success: ['data'],
    },
  },
};

export default {
  actions,
  state: {
    loading: false,

    data: [],
    pagination: null,
    unreadCount: 0,
  },
};
