import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import Typography, { fontStyle } from 'core/ui/Typography';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import TagsChosen from 'core/ui/TagsChosen';
import { CompetitionRegistrationContext } from 'contexts';
import Divider from 'core/ui/Divider';
import { secondary } from 'core/ui/Colors';
import AddAthletes from './Teams/AddAthletes';

export default function RegistrationIndividual() {
  const { t } = useTranslation();
  const registrationContext = useContext(CompetitionRegistrationContext);

  useEffect(() => {
    registrationContext.setInvites([{}]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registrationContext?.competition]);

  return (
    <Box>
      {registrationContext.competition.isCoach &&
        registrationContext.competition.affiliates &&
        registrationContext.competition.affiliates.length > 0 && (
          <Box my={3}>
            <SelectBoxObject
              value={registrationContext.registrationData.affiliateId || -1}
              setValue={(v) =>
                registrationContext.setRegistrationData((prev) => ({ ...prev, affiliateId: v }))
              }
              options={[
                { id: -1, gymName: 'No Affiliate' },
                ...registrationContext.competition.affiliates,
              ]}
              propLabel="gymName"
              propValue="id"
              label={t('competition.affiliateGym')}
              labelColor={registrationContext?.competition?.darkBackground ? 'white' : secondary}
            />
          </Box>
        )}

      <Box mb={3}>
        <Typography
          ml={2}
          style={{
            ...fontStyle.label,
            color: registrationContext?.competition?.darkBackground ? 'white' : secondary,
          }}
        >
          {t('competition.registration.label.tags')}
        </Typography>
        <Typography
          mb={1}
          ml={3}
          style={{
            ...fontStyle.xSmall,
            color: registrationContext?.competition?.darkBackground ? 'white' : secondary,
          }}
        >
          {t('competition.registration.help.tags')}
        </Typography>
        <TagsChosen
          placeholder={t('competition.registration.label.tags')}
          setValue={(values) =>
            registrationContext.setRegistrationData((prev) => ({ ...prev, tags: values }))
          }
          limit={6}
          kind="competition"
          val={
            registrationContext.registrationData.tags
              ? [...registrationContext.registrationData.tags]
              : []
          }
          iconTag={
            <Typography ml={1.5} variant="h6" style={{ color: 'white' }}>
              #
            </Typography>
          }
          labelColor={registrationContext?.competition?.darkBackground ? 'white' : secondary}
        />
      </Box>

      <Box mx={2} mt={10}>
        <AddAthletes
          competition={registrationContext?.competition}
          invites={registrationContext?.invites}
          setInvites={registrationContext?.setInvites}
          eventType="individual"
        />
      </Box>

      <Divider my={5} />
    </Box>
  );
}
