/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import { ButtonPrimary } from 'core/ui/Button';
import LoaderSm from 'core/ui/LoaderSm';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import useSelectors from 'modules/map/useSelectors';
import { useDispatch } from 'react-redux';
import notifications from 'modules/notifications';
import SelectFile from 'core/ui/SelectFile';
import { partnersModule } from 'modules/partners';

export default ({ close, callback, partner }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { request } = useActions();
  const loading = useSelectors(partnersModule, 'loading');
  const [data, setData] = useState(null);

  const confirmImport = () => {
    const formData = new FormData();
    formData.append('file', data);

    request({
      action: Modules.partners.actions.updateInventoryByFile,
      data: { partnerId: partner?.id, formData },
      options: {
        onError: (error) => {
          dispatch(notifications.error(error));
        },
        onSuccess: () => {
          dispatch(notifications.success(t('message.success.inventories.storeProducts')));
          if (callback) {
            callback();
          }
          close();
        },
      },
    });
  };

  return (
    <Box display="flex" alignItems="center" flexDirection="column" p={5}>
      <Box>
        <SelectFile setFile={setData} file={data} accept=".xlsx, .xls" />
        {/* <Typography mt={1} style={fontStyle.small} align="center">
          <Link href="/example_inventory.xlsx">Download Template</Link>
        </Typography> */}
      </Box>

      <Box mb={3} mt={5}>
        <ButtonPrimary disabled={loading} p={1} onClick={() => confirmImport()}>
          <LoaderSm loading={loading} width={25} />
          {t('button.confirm')}
        </ButtonPrimary>
      </Box>
    </Box>
  );
};
