/* eslint-disable no-underscore-dangle */
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form-v5';
import { useDispatch } from 'react-redux';
import notifications from 'modules/notifications';
import useSelectors from 'modules/map/useSelectors';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import { usersContractsModule } from 'modules/usersContracts';

export default ({ userId, document, callback, contractKey }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loading = useSelectors(usersContractsModule, 'loading');
  const { request } = useActions();
  const { register, handleSubmit, setValue, watch, errors, unregister, getValues } = useForm();
  const [contract, setContract] = useState();

  const [signaturesErrors, setSignatureErrors] = useState({});
  // eslint-disable-next-line no-array-constructor
  const itemsEls = useRef(new Array());

  const signatureSections = document?.sections?.filter((x) => x.kind === 'signature') || [];
  const validateSignatures = () => {
    if (itemsEls?.current?.length === 0) {
      return true;
    }

    let valid = true;
    const sigErrors = {};

    document.sections.forEach((section, idx) => {
      if (section.kind === 'signature') {
        const signatureRef = itemsEls.current[idx];
        if (signatureRef?.isEmpty() && section?.required) {
          sigErrors[`signature-${section?.id}`] =
            signatureRef?.isEmpty() && section?.required ? 'Signature is required' : null;
          valid = false;
        } else {
          delete sigErrors[`signature-${section?.id}`];
        }
      }
    });

    setSignatureErrors(sigErrors);

    return valid;
  };

  const onSubmit = (values) => {
    if (!validateSignatures()) {
      return;
    }

    const sections = [];
    Object.keys(values).forEach((sectionId) => {
      sections.push({
        documents_section_id: sectionId.replace('section-', ''),
        userId,
        value: values[sectionId],
      });
    });

    if (signatureSections?.length > 0) {
      document.sections.forEach((sec, index) => {
        if (sec.kind === 'signature') {
          const signatureRef = itemsEls.current[index];

          const signatureData = signatureRef?.toDataURL('base64');
          sections.push({
            documents_section_id: sec?.id,
            userId,
            value: signatureData,
          });
        }
      });
    }

    const data = {
      ...values,
      userId,
      key: contractKey,
      documentsSectionsUsersAttributes: sections,
      id: document?.id,
      channelId: document.channelId,
      accept: true,
    };

    request({
      action: Modules.usersContracts.actions.acceptByKey,
      data,
      options: {
        onSuccess: (resp) => {
          setContract(resp);
          if (callback) {
            callback(values.name);
          }
          dispatch(notifications.success(t('contract.accepted.message')));
        },
      },
    });
  };

  const checkRequired = (item) => {
    if (['text', 'radio', 'checkbox', 'dropdown'].find((x) => x === item?.kind)) {
      return item?.required;
    }

    return false;
  };

  const unregisterAll = () => {
    const keys = Object.keys(getValues());
    keys.forEach((x) => {
      unregister(x);
    });
  };

  useEffect(() => {
    if (document) {
      unregisterAll();

      document.sections
        .filter((x) => x.kind !== 'signature')
        .forEach((item) => {
          register(`section-${item?.id}`, { required: checkRequired(item) });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register, document]);

  return {
    watch,
    setValue,
    errors,
    submit: handleSubmit(onSubmit),
    loading,
    contract,
    itemsEls,
    signaturesErrors,
  };
};
