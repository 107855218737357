/* eslint-disable react/prop-types */
import React from 'react';
import Box from 'core/ui/Box';
import CoachesReport from 'components/Channel/Manage/Tabs/Reports/Coaches';
import { useSelector } from 'react-redux';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import { sessionSelectors } from 'modules/session';
import { channelsSelectors } from 'modules/channels';

const CoachRatePayroll = () => {
  const user = useSelector((state) => sessionSelectors.getUser(state));
  const channel = useSelector((state) => channelsSelectors.getChannel(state));

  const { request } = useActions();

  const getReport = (params = {}) => {
    if (!channel?.id) {
      return;
    }
    request({
      action: Modules.channelReports.actions.coachPayrollReport,
      data: {
        report: 'CoachesPayroll',
        channelId: channel?.id,
        coachId: user?.id,
        ...params,
      },
    });
  };

  return (
    <Box mt={2}>
      {channel?.id && <CoachesReport channel={channel} getReport={getReport} isCoachView />}
    </Box>
  );
};

CoachRatePayroll.defaultProps = {};

export default CoachRatePayroll;
