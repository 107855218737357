import React, { useEffect, useState } from 'react';
import Box from 'core/ui/Box';
import { channelReportsModule } from 'modules/channelReports';
import useSelectors from 'modules/map/useSelectors';
import { useDispatch, useSelector } from 'react-redux';
import { channelsSelectors } from 'modules/channels';
import { ButtonPrimary } from 'core/ui/Button';
import { useTranslation } from 'react-i18next';
import ChoosenChannelTags from 'core/ui/ChoosenChannelTags';
import Grid from 'core/ui/Grid';
import { Search } from '@material-ui/icons';
import DatePickerRounded from 'core/ui/DatePickers/DatePickerRounded';
import { formatDateToApi } from 'core/utils/formatters';
import Paper from 'core/ui/Paper';
import Toggle from 'core/ui/Toggle';
import useIsXs from 'core/utils/hook/useIsXs';
import Typography, { fontStyle } from 'core/ui/Typography';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import notifications from 'modules/notifications';
import TableMemberships from './TableMemberships';

// eslint-disable-next-line react/prop-types
export default function MembershipsDetails({ loading, getReport }) {
  const { t } = useTranslation();
  const channel = useSelector((state) => channelsSelectors.getChannel(state));
  const data = useSelectors(channelReportsModule, 'data');
  const isXs = useIsXs();
  const dispatch = useDispatch();
  const dateNow = new Date();

  const [filters, setFilters] = useState({
    startAt: `${dateNow.getMonth() + 1}/1/${dateNow.getFullYear()}`,
    endAt: `${dateNow.getMonth() + 1}/${dateNow.getDate()}/${dateNow.getFullYear()}`,
    renewable: true,
    filterDate: 'expiry_at',
    membersTags: [],
    membershipsTags: [],
  });

  const optionsDates = [
    {
      value: 'expiry_at',
      label: filters?.renewable ? t('label.date.byRenewalDate') : t('label.date.byExpiryDate'),
      width: isXs ? 80 : 150,
    },
    { value: 'start_at', label: t('label.byStartDate'), width: isXs ? 80 : 150 },
    { value: 'contract_end_date', label: t('label.byContractEndDate'), width: isXs ? 80 : 150 },
    { value: 'plan_expiry_at', label: t('label.byPlanExpiry'), width: isXs ? 80 : 150 },
  ];

  const [documents, setDocuments] = useState([]);
  const { request } = useActions();

  const getDocumentsData = () => {
    request({
      action: Modules.documents.actions.getDocuments,
      data: { ownerableId: channel?.id, ownerableType: 'Channel', kind: 'contract' },
      options: {
        onSuccess: setDocuments,
      },
    });
  };

  useEffect(() => {
    if (channel?.id) {
      getDocumentsData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel?.id]);

  const search = () => {
    const startAt = filters.startAt ? formatDateToApi(filters.startAt) : null;
    const endAt = filters.endAt ? formatDateToApi(filters.endAt) : null;

    if (startAt && endAt) {
      getReport({ ...filters, startAt, endAt });
    } else {
      dispatch(notifications.warning(t('report.validation.date')));
    }
  };

  return (
    <Box>
      <Paper p={3} className="tw-flex tw-flex-col tw-flex-wrap tw-gap-3">
        <Box display="flex" alignItems="flex-end" flexWrap="wrap" className="tw-gap-2 tw-pb-1">
          <Box>
            <Toggle
              label={t('label.renewable').toUpperCase()}
              options={[
                { value: true, label: t('label.renewable'), width: 100 },
                { value: false, label: t('label.noRenewable'), width: 100 },
              ]}
              value={filters?.renewable}
              onChange={(v) => {
                setFilters((prev) => ({ ...prev, renewable: v }));
              }}
            />
          </Box>
          <Box>
            <Toggle
              label={t('label.filterDate').toUpperCase()}
              options={optionsDates}
              value={filters?.filterDate}
              onChange={(v) => {
                setFilters((prev) => ({ ...prev, filterDate: v }));
              }}
            />
          </Box>
          <Box>
            <Typography style={fontStyle.label} mb={2}>
              {optionsDates?.find((x) => x.value === filters?.filterDate)?.label?.toUpperCase()}
            </Typography>
            <div className="tw-flex tw-flex-row tw-gap-2">
              <DatePickerRounded
                onChange={(v) => setFilters((prev) => ({ ...prev, startAt: v }))}
                value={filters.startAt}
                disablePast={false}
                usFormat={channel?.useUsDateFormat}
                autoOk
                bordered
              />
              <DatePickerRounded
                label=""
                onChange={(v) => setFilters((prev) => ({ ...prev, endAt: v }))}
                value={filters.endAt}
                disablePast={false}
                usFormat={channel?.useUsDateFormat}
                autoOk
                bordered
              />
            </div>
          </Box>
        </Box>
        <Box display="flex" alignItems="flex-end">
          <Grid container spacing={5}>
            <Grid item xs={12} md={4}>
              <SelectBoxObject
                label="MEMBERSHIPS"
                placeholder="All"
                emptyItem
                emptyValue="all"
                options={channel.membershipAllPlans || []}
                value={filters?.channelMembershipPlanId}
                setValue={(v) => setFilters((prev) => ({ ...prev, channelMembershipPlanId: v }))}
                bordered
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <SelectBoxObject
                label="CONTRACTS"
                placeholder="All"
                emptyItem
                emptyValue="all"
                options={documents || []}
                value={filters?.contractId}
                setValue={(v) => setFilters((prev) => ({ ...prev, contractId: v }))}
                bordered
              />
            </Grid>
          </Grid>
        </Box>
        <Box display="flex" alignItems="flex-end">
          <Box flexGrow={1}>
            <Grid container spacing={5}>
              <Grid item xs={12} md={4}>
                <ChoosenChannelTags
                  label={t('channel.label.memberTags').toUpperCase()}
                  placeholder={t('channel.tags')}
                  val={filters?.membersTags ? [...filters.membersTags] : []}
                  setValue={(v) =>
                    setFilters((prev) => ({ ...prev, membersTags: v?.map((x) => x?.name || x) }))
                  }
                  channelId={channel?.id}
                  bordered
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <ChoosenChannelTags
                  label={t('channel.label.membershipsTags').toUpperCase()}
                  placeholder={t('channel.tags')}
                  val={filters?.membershipsTags ? [...filters.membershipsTags] : []}
                  setValue={(v) =>
                    setFilters((prev) => ({
                      ...prev,
                      membershipsTags: v?.map((x) => x?.name || x),
                    }))
                  }
                  channelId={channel?.id}
                  bordered
                />
              </Grid>
            </Grid>
          </Box>
          <Box ml={3}>
            <ButtonPrimary disabled={loading} onClick={() => search()}>
              <Search style={{ color: 'white' }} fontSize="small" />
              {t('button.search')}&nbsp;
            </ButtonPrimary>
          </Box>
        </Box>
      </Paper>

      <Box mt={5}>
        <TableMemberships channel={channel} channelKeys={data.channelKeys} />
      </Box>
    </Box>
  );
}
