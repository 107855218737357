import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

import * as selectors from './selectors';
import * as sagas from './sagas';

/* ---------- Action Creators and Types ---------- */
const { Types, Creators } = createActions(
  {
    acceptContractError: ['error'],
    acceptContractStart: ['data'],
    acceptContractSuccess: [],

    rejectContractError: ['error'],
    rejectContractStart: ['data'],
    rejectContractSuccess: [],

    getContractError: ['error'],
    getContractStart: ['name'],
    getContractSuccess: ['contract'],
  },
  { prefix: 'contract/' },
);

/* ---------- Initial State ---------- */
const INITIAL_STATE = Immutable({
  data: {},
  error: null,
  loading: false,
});

/* ---------- Reducers ---------- */
const loadStartReducer = (state) => state.set('loading', true);

const acceptContractSuccessReducer = (state, action) =>
  state.merge({
    contract: action.contract,
    loading: false,
    error: null,
  });

const getContractSuccessReducer = (state, action) =>
  state.merge({
    data: action.contract,
    pagination: action.pagination,
    loading: false,
    error: null,
  });

const loadErrorReducer = (state, action) =>
  state.merge({
    loading: false,
    error: action.error,
  });

const loadSuccessReducer = (state, action) =>
  state.merge({
    loading: false,
    error: action.error,
  });

/* ---------- Main Reducer ---------- */
const reducer = createReducer(INITIAL_STATE, {
  [Types.ACCEPT_CONTRACT_START]: loadStartReducer,
  [Types.ACCEPT_CONTRACT_SUCCESS]: acceptContractSuccessReducer,
  [Types.ACCEPT_CONTRACT_ERROR]: loadErrorReducer,

  [Types.REJECT_CONTRACT_START]: loadStartReducer,
  [Types.REJECT_CONTRACT_SUCCESS]: loadSuccessReducer,
  [Types.REJECT_CONTRACT_ERROR]: loadErrorReducer,

  [Types.GET_CONTRACT_START]: loadStartReducer,
  [Types.GET_CONTRACT_SUCCESS]: getContractSuccessReducer,
  [Types.GET_CONTRACT_ERROR]: loadErrorReducer,
});

/* ---------- Exporting ---------- */
export const contractActions = Creators;
export const contractTypes = Types;
export const contractSelectors = selectors;
export const contractSagas = sagas;

export default reducer;
