/* eslint-disable react/prop-types */
import Box from 'core/ui/Box';
import React from 'react';

import { useTranslation } from 'react-i18next';
import ByPercentProductData from './ByPercentProductData';

export default function ByPercentProduct({ channel, byPercentProduct }) {
  const { t } = useTranslation();
  return (
    <Box>
      <ByPercentProductData
        title={t('channel.rateTemplate.kind.percent_by_product')}
        channel={channel}
        byPercentProduct={byPercentProduct}
      />
    </Box>
  );
}
