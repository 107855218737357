import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import Typography from 'core/ui/Typography';
import { ButtonPrimary } from 'core/ui/Button';
import { useSelector, useDispatch } from 'react-redux';
import notifications from 'modules/notifications';
import LoaderSm from 'core/ui/LoaderSm';
import SelectorPaymentMethod, { PaymentMethodViewType } from 'components/Account/Selector';
import { sessionSelectors } from 'modules/session';
import { channelCurrency } from 'core/utils/formatters';
import { Dialog } from '@material-ui/core';
import Texture from 'core/ui/Texture';

export default function ModalPayAndCreate({ open, close, channel, confirm, isClone, loading }) {
  const { t } = useTranslation();
  const currentUser = useSelector((state) => sessionSelectors.getUser(state));

  const [viewMethodPayment, setViewMethodPayment] = useState(PaymentMethodViewType.list);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);

  const dispatch = useDispatch();

  const validate = () => {
    if (
      !channel?.competitionActivePlan &&
      !channel?.defaultPaymentMethod &&
      !selectedPaymentMethod
    ) {
      dispatch(notifications.warning(t('modalpurchase.validation.selectAccount')));
      return false;
    }
    return true;
  };

  const save = () => {
    if (validate()) {
      confirm(!channel?.defaultPaymentMethod ? selectedPaymentMethod?.id : null);
    }
  };

  return (
    <Dialog
      PaperProps={{ style: { borderRadius: 5 } }}
      maxWidth="sm"
      fullWidth
      scroll="body"
      onClose={() => close}
      open={open}
    >
      <Texture>
        <Box p={3}>
          <Box>
            <Box>
              {!channel?.competitionActivePlan ? (
                <>
                  <Box mx={5}>
                    <Box display="flex" mt={2} justifyContent="center">
                      <Typography style={{ fontSize: 40 }}>{channelCurrency(100)}</Typography>
                    </Box>
                  </Box>
                  <Box m={5}>
                    {channel?.defaultPaymentMethod ? (
                      <Box my={3} display="flex" justifyContent="center" alignItems="center">
                        <Typography variant="h6" align="center" className="roboto-regular">
                          <span style={{ textTransform: 'capitalize' }}>
                            <b>{channel?.defaultPaymentMethod?.cardFlag}</b>
                          </span>{' '}
                          <Typography
                            className="roboto-regular"
                            variant="h6"
                            component="span"
                            color="textSecondary"
                          >
                            {t('userPaymentMethod.endingIn')}{' '}
                            {channel?.defaultPaymentMethod?.lastFour}
                          </Typography>
                        </Typography>
                      </Box>
                    ) : (
                      <SelectorPaymentMethod
                        selectedPaymentMethod={selectedPaymentMethod}
                        setSelectedPaymentMethod={setSelectedPaymentMethod}
                        view={viewMethodPayment}
                        setView={setViewMethodPayment}
                        cardpointe={false}
                        userId={currentUser.id}
                        userInfo={{
                          name: currentUser?.name,
                          phone: currentUser?.phone,
                          email: currentUser?.email,
                        }}
                      />
                    )}
                  </Box>

                  {viewMethodPayment !== PaymentMethodViewType.form && (
                    <Box display="flex" px={5} justifyContent="flex-end">
                      <ButtonPrimary
                        disabled={loading}
                        onClick={() => {
                          close();
                        }}
                        type="submit"
                        fullWidth
                        m={1}
                      >
                        <Box display="flex" alignItems="center">
                          <LoaderSm loading={loading} width={20} />
                          <Typography ml={1} component="span">
                            {t('button.cancel')}
                          </Typography>
                        </Box>
                      </ButtonPrimary>
                      <ButtonPrimary
                        disabled={loading}
                        onClick={() => save()}
                        type="submit"
                        fullWidth
                        m={1}
                      >
                        <Box display="flex" alignItems="center">
                          <LoaderSm loading={loading} width={20} />
                          <Typography ml={1} component="span">
                            {t(isClone ? 'button.createAndClone' : 'button.createAndPay')}
                          </Typography>
                        </Box>
                      </ButtonPrimary>
                    </Box>
                  )}
                </>
              ) : (
                <Box display="flex" px={5} justifyContent="flex-end">
                  <ButtonPrimary
                    disabled={loading}
                    onClick={() => {
                      close();
                    }}
                    type="submit"
                    fullWidth
                    m={1}
                  >
                    <Box display="flex" alignItems="center">
                      <LoaderSm loading={loading} width={20} />
                      <Typography ml={1} component="span">
                        {t('button.cancel')}
                      </Typography>
                    </Box>
                  </ButtonPrimary>
                  <ButtonPrimary
                    disabled={loading}
                    onClick={() => save()}
                    type="submit"
                    fullWidth
                    m={1}
                  >
                    <Box display="flex" alignItems="center">
                      <LoaderSm loading={loading} width={20} />
                      <Typography ml={1} component="span">
                        {t('button.confirm')}
                      </Typography>
                    </Box>
                  </ButtonPrimary>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Texture>
    </Dialog>
  );
}

ModalPayAndCreate.defaultProps = {
  setOpen: () => {},
};
