import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import { Dialog, FormControlLabel, Radio } from '@material-ui/core';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import Typography, { fontStyle } from 'core/ui/Typography';
import { ButtonPrimarySm } from 'core/ui/Button';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import notifications from 'modules/notifications';
import { useDispatch } from 'react-redux';
import Texture from 'core/ui/Texture';
import TableCoupons from './TableCoupons';

const ModalCoupons = ({
  channel,
  open,
  close,
  currentPlan,
  saveCoupon,
  changeCoupon,
  removeCoupon,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form, setForm] = useState({ open: false });
  const defaultCoupon = {
    kind: 'percentage',
    renewable: true,
  };
  const [coupon, setCoupon] = useState(defaultCoupon);

  const validate = () => {
    if (!coupon.code) {
      dispatch(notifications.warning(t('channel.membership.valid.code')));
      return false;
    }
    if (!coupon.amount || coupon.amount <= 0) {
      dispatch(notifications.warning(t('channel.membership.valid.amount')));
      return false;
    }
    if (coupon.kind === 'money' && Number(currentPlan.amount) < coupon.amount) {
      dispatch(notifications.warning(t('channel.membership.valid.amount')));
      return false;
    }

    return true;
  };

  const submitCoupon = () => {
    if (!validate()) {
      return false;
    }

    saveCoupon(currentPlan, { ...coupon, active: true });
    setCoupon(defaultCoupon);
    setForm({ open: false });
    return true;
  };

  const removeNew = (c) => {
    removeCoupon(currentPlan, c);
  };

  const change = (prop, c) => {
    changeCoupon(currentPlan, prop, c);
  };

  const edit = (c) => {
    setCoupon(c);

    setForm({ open, coupon: c });
  };

  return (
    <Dialog
      PaperProps={{ style: { borderRadius: 5, minWidth: 300 } }}
      maxWidth="md"
      scroll="body"
      onClose={() => close()}
      open={open}
    >
      <Texture>
        <Box p={5}>
          <Box>
            <Box>
              <Box flexGrow={1}>
                <Typography align="center" mb={2}>
                  {t('channel.membership.discount')}
                </Typography>
              </Box>
              <ButtonPrimarySm onClick={() => setForm({ open: true })}>
                <PlaylistAddIcon />
              </ButtonPrimarySm>
            </Box>

            <Dialog
              PaperProps={{ style: { borderRadius: 5, minWidth: 300 } }}
              maxWidth="sm"
              scroll="body"
              onClose={() => setForm({ open: false })}
              open={form?.open}
            >
              <Texture>
                <Box p={5}>
                  <Box display="flex">
                    <Box flexGrow="1">
                      <TextFieldRounded
                        value={coupon.code || ''}
                        onChange={(v) => setCoupon((prev) => ({ ...prev, code: v }))}
                        placeholder={t('channel.membership.placeholder.code')}
                        label={t('channel.membership.label.code').toUpperCase()}
                        required
                      />
                    </Box>
                    <Box mx={1} ml={4}>
                      <Typography style={fontStyle.label} variant="body1">
                        {t('channel.membership.label.type').toUpperCase()}
                      </Typography>
                      <Box display="flex">
                        <FormControlLabel
                          onClick={() => setCoupon((prev) => ({ ...prev, kind: 'percentage' }))}
                          control={
                            <Radio
                              color="primary"
                              size="small"
                              checked={coupon.kind === 'percentage'}
                            />
                          }
                          label="%"
                        />
                        <FormControlLabel
                          onClick={() => setCoupon((prev) => ({ ...prev, kind: 'money' }))}
                          control={
                            <Radio color="primary" size="small" checked={coupon.kind === 'money'} />
                          }
                          label={channel?.currency?.symbol}
                        />
                      </Box>
                    </Box>
                    <Box display="flex">
                      <TextFieldRounded
                        value={coupon.amount || ''}
                        onChange={(v) => setCoupon((prev) => ({ ...prev, amount: v }))}
                        type="number"
                        pattern="[0-9]*"
                        placeholder={t('channel.membership.placeholder.amount')}
                        label={t('channel.membership.coupon.label.amount').toUpperCase()}
                        required
                        currency={coupon.kind === 'money'}
                        currencySymbol={channel?.currency?.symbol}
                      />
                    </Box>
                  </Box>

                  <Box mt={1} display="flex" alignItems="flex-end">
                    <Box flexGrow="1">
                      <TextFieldRounded
                        value={coupon.limitUse || ''}
                        onChange={(v) => setCoupon((prev) => ({ ...prev, limitUse: v }))}
                        type="number"
                        pattern="[0-9]*"
                        placeholder={t('channel.membership.placeholder.limitUse')}
                        label={t('channel.membership.label.limitUse').toUpperCase()}
                      />
                    </Box>

                    <Box flexGrow="1" mx={1} ml={2}>
                      <Typography noWrap style={fontStyle.label} variant="body1">
                        {t('channel.membership.label.renewable').toUpperCase()}
                      </Typography>
                      <Box display="flex">
                        <FormControlLabel
                          onClick={() => setCoupon((prev) => ({ ...prev, renewable: true }))}
                          control={
                            <Radio
                              color="primary"
                              size="small"
                              checked={coupon.renewable === true}
                            />
                          }
                          label={t('button.yes')}
                        />
                        <FormControlLabel
                          onClick={() => setCoupon((prev) => ({ ...prev, renewable: false }))}
                          control={
                            <Radio
                              color="primary"
                              size="small"
                              checked={coupon.renewable === false}
                            />
                          }
                          label={t('button.no')}
                        />
                      </Box>
                    </Box>
                    <Box>
                      <ButtonPrimarySm onClick={() => submitCoupon()}>
                        {t('button.save')}
                      </ButtonPrimarySm>
                    </Box>
                  </Box>
                </Box>
              </Texture>
            </Dialog>

            <Box mb={1} mt={3}>
              <TableCoupons
                channel={channel}
                removeNew={removeNew}
                currentPlan={currentPlan}
                coupons={currentPlan.coupons}
                change={change}
                edit={edit}
              />
            </Box>
          </Box>
        </Box>
      </Texture>
    </Dialog>
  );
};

ModalCoupons.defaultProps = {
  currentPlan: {},
  close: () => {},
  open: false,
  saveCoupon: () => {},
  changeCoupon: () => {},
  removeCoupon: () => {},
};

ModalCoupons.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  currentPlan: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  close: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  saveCoupon: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  changeCoupon: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  removeCoupon: PropTypes.func,
  open: PropTypes.bool,
};

export default ModalCoupons;
