import React from 'react';
import PropTypes from 'prop-types';
import { ButtonPrimary } from 'core/ui/Button';
import LoaderSm from 'core/ui/LoaderSm';
import { useTranslation } from 'react-i18next';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import { Controller } from 'react-hook-form';
import Divider from 'core/ui/Divider';
import Typography, { fontStyle } from 'core/ui/Typography';
import { Avatar, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import useSelectors from 'modules/map/useSelectors';
import { redColor } from 'core/ui/Colors';
import TagsChosen from 'core/ui/TagsChosen';
import CKEditor, { defaultCkEditorText } from 'core/ui/CKEditor';
import { ContentState, convertFromHTML, convertToRaw } from 'draft-js';
import DatePickerRounded from 'core/ui/DatePickers/DatePickerRounded';
import TimePickerRounded from 'core/ui/DatePickers/TimePickerRounded';
import Box from 'core/ui/Box';
import { Delete } from '@material-ui/icons';
import SelImages from 'core/ui/SelImages';
import noImage from 'assets/img/no-image.jpg';
import { channelsEventsModule } from 'modules/channelsEvents';
import LocationMapView from 'core/ui/LocationMapView';
import useFormChannelsEvent from './useForm';

const FormChannelsEvents = ({ eventId, close, callbackSave, channel }) => {
  const { t } = useTranslation();
  const loadingForm = useSelectors(channelsEventsModule, 'loadingForm');
  const { submit, control, setValue, event, deleteImage, deleteEvent, watch } =
    useFormChannelsEvent({
      callbackSave,
      close,
      channel,
      eventId,
    });

  const defaultCkText = () => {
    if (!event?.text) {
      return defaultCkEditorText('');
    }
    if (!event?.textRaw && !!event?.text) {
      const blocksFromHTML = convertFromHTML(event.text);

      return convertToRaw(
        ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap),
      );
    }

    const newRaw = typeof event?.textRaw === 'string' ? JSON.parse(event?.textRaw) : event?.textRaw;

    return newRaw;
  };

  return (
    <>
      <DialogTitle>
        <Typography variant="h5">{t('label.eventDetails')}</Typography>
      </DialogTitle>
      <Divider />
      <DialogContent className="tw-bg-gray-100">
        <div className="tw-relative tw-mt-2 tw-flex tw-flex-col tw-gap-4">
          <div className="tw-grid tw-grid-cols-1 tw-gap-4 md:tw-grid-cols-6">
            <div className="tw-col-span-2">
              <Controller
                control={control}
                rules={{
                  required: false,
                }}
                render={({ field: { onChange, value } }) => (
                  <div className="tw-bg-white tw-p-2">
                    <div className="tw-aspect-video tw-overflow-hidden tw-bg-white tw-p-2">
                      <Box>
                        <img
                          className="boxImg"
                          draggable="false"
                          src={value?.url || noImage}
                          alt=""
                          style={{
                            // Without height undefined it won't work
                            height: undefined,
                            // figure out your image aspect ratio
                            aspectRatio: 16 / 9,
                            objectFit: 'cover',
                          }}
                        />
                      </Box>
                    </div>
                    <div className="tw-mt-2 tw-flex tw-flex-row tw-items-center tw-justify-center tw-gap-2">
                      <Box display="flex" justifyContent="center">
                        <SelImages setImage={(img) => onChange(img)} />
                      </Box>
                      <ButtonPrimary onClick={() => deleteImage()}>
                        <Delete fontSize="small" />
                      </ButtonPrimary>
                    </div>
                  </div>
                )}
                name="image"
              />
            </div>

            <div className="tw-col-span-4 tw-flex tw-flex-col tw-gap-4">
              <div>
                <Controller
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextFieldRounded
                      error={error}
                      placeholder={t('placeholder.name')}
                      label={t('label.name').toUpperCase()}
                      onChange={onChange}
                      value={value}
                      required
                    />
                  )}
                  name="name"
                />
              </div>

              <Box display="flex" flexWrap="wrap" className="tw-gap-2">
                <Controller
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <div>
                      <DatePickerRounded
                        type="datetime-local"
                        label={t('workout.label.scheduledAtDate').toUpperCase()}
                        onChange={onChange}
                        value={value}
                        usFormat={channel?.useUsDateFormat}
                        required
                        error={error}
                        disablePast={false}
                      />
                    </div>
                  )}
                  name="startAtDate"
                />
                <Controller
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <div>
                      <TimePickerRounded
                        name="scheduledAt"
                        type="datetime-local"
                        label={t('workout.label.scheduledAtTime').toUpperCase()}
                        onChange={onChange}
                        value={value}
                        error={error}
                        required
                      />
                    </div>
                  )}
                  name="startAtHour"
                />

                <Controller
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <div>
                      <DatePickerRounded
                        name="scheduledAt"
                        type="datetime-local"
                        label={t('workout.label.endAtDate').toUpperCase()}
                        onChange={onChange}
                        value={value}
                        usFormat={channel?.useUsDateFormat}
                        required
                        error={error}
                        disablePast={false}
                      />
                    </div>
                  )}
                  name="endAtDate"
                />
                <Controller
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <div>
                      <TimePickerRounded
                        name="scheduledAt"
                        type="datetime-local"
                        label={t('workout.label.endAtHour').toUpperCase()}
                        onChange={onChange}
                        value={value}
                        error={error}
                        required
                      />
                    </div>
                  )}
                  name="endAtHour"
                />
              </Box>

              <div className="tw-grid tw-grid-cols-1 tw-gap-2 md:tw-grid-cols-2">
                <div>
                  <Controller
                    control={control}
                    rules={{
                      required: false,
                    }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <TextFieldRounded
                        type="number"
                        pattern="[0-9]*"
                        value={value}
                        placeholder="0.00"
                        label={t('label.price').toUpperCase()}
                        onChange={onChange}
                        currency
                        error={error}
                        currencySymbol={channel?.currency?.symbol}
                      />
                    )}
                    name="price"
                  />
                </div>

                <div>
                  <Controller
                    control={control}
                    rules={{
                      required: false,
                    }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <TextFieldRounded
                        type="number"
                        pattern="[0-9]*"
                        value={value}
                        placeholder="No limit"
                        label={t('label.limitRegistrations').toUpperCase()}
                        onChange={onChange}
                        error={error}
                      />
                    )}
                    name="limitRegistrations"
                  />
                </div>
              </div>
            </div>
          </div>

          <div>
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <div>
                  {!eventId || (!!eventId && !!event?.id && Number(eventId) === event?.id) ? (
                    <CKEditor
                      error={error}
                      value={value}
                      onChange={(v) => {
                        onChange(v?.html);
                        setValue('textRaw', v?.raw);
                      }}
                      defaultValue={defaultCkText()}
                      allowUploads
                      pathUpload={`Channel/${channel?.id}/ChannelsEvents`}
                      editorStyle={{ height: 200 }}
                    />
                  ) : (
                    <div className="tw-m-4">
                      <LoaderSm center loading />
                    </div>
                  )}
                </div>
              )}
              name="text"
            />
          </div>
        </div>

        <div className="tw-mt-4 tw-grid tw-grid-cols-1 tw-gap-2 md:tw-grid-cols-2">
          <div>
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <TagsChosen
                  label={t('competition.registration.label.tags').toUpperCase()}
                  placeholder={t('competition.registration.label.tags')}
                  setValue={(values) => onChange(values)}
                  limit={6}
                  kind="channels_stages_user"
                  val={value || []}
                  iconTag={
                    <Typography ml={1.5} variant="h6" style={{ color: 'white' }}>
                      #
                    </Typography>
                  }
                />
              )}
              name="tags"
            />
          </div>

          <div>
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <div>
                  <Typography style={fontStyle.label} className="tw-mb-2">
                    {t('label.location').toUpperCase()}
                  </Typography>
                  <LocationMapView
                    setLocation={onChange}
                    location={value}
                    avatar={<Avatar src={watch('image')?.url} />}
                    styleWrapper={{ height: 45 }}
                  />
                </div>
              )}
              name="location"
            />
          </div>
        </div>
      </DialogContent>

      <Divider mb={3} />
      <DialogActions>
        <div className="tw-flex tw-w-full tw-flex-row">
          <div className="tw-grow">
            {!!event?.id && (
              <ButtonPrimary
                type="button"
                buttonColor={redColor}
                onClick={() => deleteEvent()}
                size="small"
                disabled={loadingForm}
                style={{ width: 100 }}
              >
                <LoaderSm loading={loadingForm} width={20} />
                {t('button.delete')}
              </ButtonPrimary>
            )}
          </div>
          <div className="tw-flex tw-flex-row tw-gap-2">
            <ButtonPrimary
              type="button"
              buttonColor={redColor}
              onClick={() => close()}
              size="small"
              disabled={loadingForm}
              style={{ width: 100 }}
            >
              {t('button.close')}
            </ButtonPrimary>
            <ButtonPrimary
              type="button"
              onClick={() => submit()}
              size="small"
              disabled={loadingForm}
              style={{ width: 100 }}
            >
              <LoaderSm loading={loadingForm} width={20} />
              {t('button.save')}
            </ButtonPrimary>
          </div>
        </div>
      </DialogActions>
    </>
  );
};

FormChannelsEvents.propTypes = {
  close: PropTypes.func.isRequired,
};

FormChannelsEvents.defaultProps = {};

export default FormChannelsEvents;
