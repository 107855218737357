import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import Paper from 'core/ui/Paper';
import Typography from 'core/ui/Typography';
import { ButtonPrimary } from 'core/ui/Button';
import { useSelector, useDispatch } from 'react-redux';
import { currency } from 'core/utils/formatters';
import DialogTitle from 'core/ui/Dialog/DialogTitle';
import LoaderSm from 'core/ui/LoaderSm';
import Texture from 'core/ui/Texture';
import { paymentsActions, paymentsSelectors } from 'modules/payments';
import { date, timeZoneName } from 'core/utils/formatters/date';
import { channelsActions } from 'modules/channels';
import { primary, redColor } from 'core/ui/Colors';
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import ChannelContracts from 'components/Channel/ChannelContracts';
import { sessionSelectors } from 'modules/session';
import TextAreaFieldRounded from 'core/ui/TextAreaFieldRounded';
import Divider from 'core/ui/Divider';

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function ModalPayment({
  allPlans,
  plan,
  channel,
  channelPlan,
  open,
  close,
  createPaymentMethod,
}) {
  const { t } = useTranslation();
  const userSession = useSelector((state) => sessionSelectors.getUser(state));
  const [accepted, setAccepted] = useState(false);
  const [cancelText, setCancelText] = useState('');

  const loadingPurchaseChannelPlan = useSelector((state) =>
    paymentsSelectors.getLoadingPurchaseChannelPlan(state),
  );
  const loadingPurchaseAdditional = useSelector((state) =>
    paymentsSelectors.getLoadingPurchaseAdditional(state),
  );
  const dispatch = useDispatch();

  const purchase = () => {
    dispatch(
      paymentsActions.purchaseChannelPlanStart(
        {
          planId: plan.id,
          channelId: channel.id,
          plans: allPlans.filter((x) => !!x.action),
          timezone: timeZoneName(),
          annualRenewal: channelPlan?.annualRenew,
          cancelText,
          cancel: channelPlan.planState === 'cancel',
        },
        () => {
          dispatch(channelsActions.getChannelStart(channel.id, 'edit'));
          close(false);
        },
      ),
    );
  };

  const calcPlanAmounts = () => {
    if (channel?.activePlans?.free?.active) {
      return [];
    }

    const plansAmounts = {};
    const activePlans = [];
    const annualPrice = channelPlan.annualRenew && (!channel.renewAt || channel.annualRenewal);

    if (channelPlan.plans.find((x) => x.plan === 'online')) {
      plansAmounts.online = annualPrice ? Number(plan.annualAmount) : Number(plan.amount);
      if (channel?.activePlans?.online?.active) {
        activePlans.push('online');
      }
    }
    if (channelPlan.plans.find((x) => x.plan === 'in_person')) {
      plansAmounts.in_person = annualPrice
        ? Number(plan.annualAmountInPerson)
        : Number(plan.amountInPerson);
      if (channel?.activePlans?.inPerson?.active) {
        activePlans.push('in_person');
      }
    }
    if (channelPlan.plans.find((x) => x.plan === 'member_app')) {
      plansAmounts.member_app = annualPrice
        ? Number(plan.annualAmountMemberApp)
        : Number(plan.amountMemberApp);
      if (channel?.activePlans?.memberApp?.active) {
        activePlans.push('member_app');
      }
    }
    if (channelPlan.plans.find((x) => x.plan === 'sifely')) {
      plansAmounts.sifely = annualPrice
        ? Number(plan.annualAmountSifely)
        : Number(plan.amountSifely);
      if (channel?.activePlans?.sifely?.active) {
        activePlans.push('sifely');
      }
    }

    if (channelPlan.plans.find((x) => x.plan === 'engage')) {
      if (channel?.engageFree) {
        plansAmounts.engage = 0;
      } else {
        plansAmounts.engage = annualPrice
          ? Number(plan.annualAmountEngage)
          : Number(plan.amountEngage);
      }

      if (channel?.activePlans?.engage?.active) {
        activePlans.push('engage');
      }
    }
    if (channelPlan.plans.find((x) => x.plan === 'competition')) {
      plansAmounts.competition = annualPrice
        ? Number(plan.annualAmountCompetition)
        : Number(plan.amountCompetition) / 3;
      if (channel?.activePlans?.competition?.active) {
        activePlans.push('competition');
      }
    }

    let planAmount = 0;
    let payChanges = false;
    Object.keys(plansAmounts).forEach((x) => {
      if (!activePlans.find((y) => y === x)) {
        payChanges = true;
        planAmount += plansAmounts[x];
      }
    });

    let newAmount = planAmount;
    if (channel.daysToRenewPlan > 0) {
      const daysToRenew = channel.daysToRenewPlan;
      newAmount = (planAmount / 30) * daysToRenew;
    }

    return {
      total: newAmount > planAmount ? planAmount : newAmount,
      amounts: plansAmounts,
      activePlans,
      payChanges,
    };
  };

  const planAmounts = calcPlanAmounts();

  return (
    <div style={{ minWidth: 300 }}>
      {!accepted && channelPlan.annualRenew && (
        <Box p={3}>
          <ChannelContracts
            channel={{ id: 2 }}
            userId={userSession.id}
            ownerableType="Channel"
            ownerableId={2}
            kind="annual_contract"
            documentId={plan.annualContractDocumentId}
            setAccepted={setAccepted}
          />
        </Box>
      )}

      <Dialog scroll="body" maxWidth="sm" fullWidth onClose={() => close(false)} open={open}>
        <Texture>
          <DialogTitle onClose={() => close(false)}>
            {t('channel.modalPayment.paymentInformation')}
          </DialogTitle>
          <DialogContent dividers>
            <Box mx={5}>
              <Paper className="paper-rounded" p={3}>
                <Box>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <Typography variant="subtitle1" component="span">
                            {t(`channel.plan.renewal`)}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="subtitle2" component="span">
                            {channelPlan?.annualRenew ? 'Annual' : 'Monthly'}
                          </Typography>
                        </TableCell>
                      </TableRow>

                      {channel?.activePlans?.free?.active ? (
                        <TableRow>
                          <TableCell colSpan={2}>
                            <Typography color="primary" variant="subtitle1" component="span">
                              FREE
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ) : (
                        <>
                          {allPlans?.map((item) => (
                            <TableRow key={item}>
                              <TableCell>
                                <Typography color="primary" variant="subtitle1" component="span">
                                  {t(`channel.plan.${item.plan}`)}
                                </Typography>
                              </TableCell>
                              <TableCell align="right">
                                {item.action !== 'cancel' ? (
                                  <>
                                    {!planAmounts.activePlans.find((x) => x === item.plan) ? (
                                      <div>
                                        {item?.plan === 'engage' && channel?.engageFree && (
                                          <Typography className="tw-text-xs tw-font-semibold tw-text-green-600">
                                            First {channelPlan.annualRenew ? 'year' : 'month'} FREE.
                                          </Typography>
                                        )}
                                        <Typography variant="subtitle2" component="span">
                                          {t('prefix')}
                                          {currency(planAmounts.amounts[item.plan])}
                                        </Typography>
                                      </div>
                                    ) : (
                                      <Typography>Active</Typography>
                                    )}
                                  </>
                                ) : (
                                  <Typography
                                    variant="subtitle2"
                                    component="span"
                                    style={{ color: redColor }}
                                  >
                                    Cancel
                                  </Typography>
                                )}
                              </TableCell>
                            </TableRow>
                          ))}
                        </>
                      )}
                    </TableBody>
                  </Table>
                </Box>

                {channelPlan.planState === 'change' && (
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    mt={3}
                    justifyContent="center"
                  >
                    <Typography>Renew</Typography>
                    <Typography color="primary" variant="subtitle1" component="span">
                      {date(channel.renewAt)}
                    </Typography>
                  </Box>
                )}
              </Paper>
            </Box>

            {channelPlan.planState === 'cancel' && (
              <Box m={5}>
                <Typography
                  variant="subtitle2"
                  mb={5}
                  mx={2}
                  component="span"
                  style={{ color: redColor }}
                >
                  Cancelling now will end your service on {date(channel.renewAt)}, and it will
                  remain active until then. If you no longer wish to process payments on StreamFit,
                  please delete all recurring schedules for active memberships.
                </Typography>

                <Divider my={2} />

                <TextAreaFieldRounded
                  label="Are you sure you want to cancel your plan?  If something is wrong, give us the opportunity to make it right! If you must leave, please give us a few words about why so we can be better in the future."
                  value={cancelText}
                  onChange={(v) => setCancelText(v)}
                />
              </Box>
            )}

            <Box m={3}>
              {channelPlan.planState !== 'cancel' && channel.defaultPaymentMethod && (
                <>
                  {planAmounts.payChanges && (
                    <Box my={3} display="flex" justifyContent="center" alignItems="center">
                      <Typography variant="h6" align="center" className="roboto-regular">
                        <span style={{ textTransform: 'capitalize' }}>
                          <b>{channel.defaultPaymentMethod.cardFlag}</b>
                        </span>{' '}
                        <Typography
                          className="roboto-regular"
                          variant="h6"
                          component="span"
                          color="textSecondary"
                        >
                          {t('userPaymentMethod.endingIn')} {channel.defaultPaymentMethod.lastFour}
                        </Typography>
                      </Typography>
                    </Box>
                  )}
                  <Box mt={3}>
                    {planAmounts.payChanges && (
                      <Typography align="center" variant="h5" style={{ color: primary }} mb={2}>
                        {t('prefix')}
                        {currency(planAmounts.total)}
                      </Typography>
                    )}
                  </Box>
                </>
              )}

              <ButtonPrimary
                disabled={(!accepted && channelPlan.annualRenew) || loadingPurchaseChannelPlan}
                onClick={() => purchase()}
                type="submit"
                fullWidth
              >
                <Box display="flex" alignItems="center">
                  <LoaderSm
                    loading={
                      !channelPlan.additional
                        ? loadingPurchaseChannelPlan
                        : loadingPurchaseAdditional
                    }
                    width={20}
                  />
                  {channelPlan.planState !== 'cancel' ? (
                    <Typography ml={1} component="span">
                      {planAmounts.payChanges ? t('purchase') : t('button.change')}
                    </Typography>
                  ) : (
                    <Typography ml={1} component="span">
                      {t('button.cancel')}
                    </Typography>
                  )}
                </Box>
              </ButtonPrimary>

              {!channel.defaultPaymentMethod && (
                <ButtonPrimary onClick={() => createPaymentMethod()} type="submit" fullWidth>
                  {t('button.addPaymentMethod')}
                </ButtonPrimary>
              )}
            </Box>
          </DialogContent>
        </Texture>
      </Dialog>
    </div>
  );
}

ModalPayment.defaultProps = {
  open: false,
};
