import api from 'core/api';

export const helpVideoMapModule = 'helpVideoMap';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const actions = {
  create: {
    module: helpVideoMapModule,
    name: 'create',
    api: ({ helpVideoId, body }) => api.post(`/help_videos/${helpVideoId}/maps`, body),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
};

export default {
  actions,
  state: {
    loading: false,
  },
};
