/* eslint-disable react/prop-types */
import React, { useRef } from 'react';
import Paper from 'core/ui/Paper';
import { ClickAwayListener, Popper, Slide } from '@material-ui/core';
import { bgDrawerColor } from 'core/ui/Colors';

const ShowMenu = ({ children, itemsMenu, opened, close, refC }) => {
  const refElement = useRef();

  return (
    <>
      <div ref={refElement}>{children}</div>

      <Popper
        open={!!opened}
        anchorEl={refElement?.current}
        role={undefined}
        placement="right-end"
        transition
        style={{ zIndex: 99999999 }}
        disablePortal={false}
        container={refC}
      >
        {({ TransitionProps }) => (
          <Slide {...TransitionProps} direction="right">
            <Paper
              style={{
                backgroundColor: bgDrawerColor,
                minWidth: 200,
                zIndex: 9999,
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
              }}
              p={3}
            >
              <ClickAwayListener onClickAway={() => close()}>
                <div>{itemsMenu}</div>
              </ClickAwayListener>
            </Paper>
          </Slide>
        )}
      </Popper>
    </>
  );
};

export default ShowMenu;
