import React from 'react';
import Grid from 'core/ui/Grid';
import { useSelector } from 'react-redux';
import { channelsSelectors } from 'modules/channels';
import ClassCard from 'components/Workout/Grid/Card';
import Paper from 'core/ui/Paper';

export default () => {
  const channel = useSelector((state) => channelsSelectors.getChannel(state));

  return (
    <Paper elevation={2} className="paper-rounded" p={3} mt={3}>
      <Grid item xs={12} sm={12} md={12} lg={12} mb={20}>
        <Grid container mt={5} spacing={5} className="search-item">
          <>
            {channel.upcomingPrivateClasses &&
              channel.upcomingPrivateClasses.map((workout) => (
                <Grid item xs={12} sm={6} md={3} lg={3} key={workout.id} className="item">
                  <ClassCard workout={workout} image={channel.image ? channel.image : {}} />
                </Grid>
              ))}
          </>
        </Grid>
      </Grid>
    </Paper>
  );
};
