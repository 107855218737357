import React from 'react';
import Box from 'core/ui/Box';
import SFDataTable from 'core/ui/DataTable';
import Typography from 'core/ui/Typography';

const TableProductsInventories = ({ data, loading }) => {
  const columns = [
    {
      name: 'Product',
      selector: (row) => row?.storeProduct?.name,
      sortable: true,
      cell: (row) => <Box>{row?.storeProduct?.name}</Box>,
    },
    {
      name: 'Option',
      selector: 'option',
      sortable: true,
      cell: (row) => <Box>{row?.option}</Box>,
    },
    {
      name: 'Qty',
      selector: 'qty',
      sortable: true,
      cell: (row) => (
        <Box
          className={
            row?.qty > 0
              ? 'tw-text-lg tw-font-bold tw-text-green-700'
              : 'tw-text-lg tw-font-bold tw-text-red-700'
          }
        >
          {row?.qty}
        </Box>
      ),
    },
  ];

  return (
    <Box>
      <SFDataTable
        noDataComponent={loading ? null : <Typography m={5}>No Products</Typography>}
        columns={columns}
        data={data?.inventories || []}
        noHeader
        pagination
        showExport
        exportData={(data?.inventories || []).map((x) => ({
          product: x?.storeProduct?.name,
          option: x?.option,
          qty: x?.qty,
        }))}
      />
    </Box>
  );
};

export default TableProductsInventories;
