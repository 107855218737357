import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import { Dialog } from '@material-ui/core';
import Button from 'core/ui/Button';
import Texture from 'core/ui/Texture';
import Typography, { fontStyle } from 'core/ui/Typography';
import useForm from './form';
import FormDocument from './FormDocument';

const ModalFormDocument = ({
  open,
  documentId,
  close,
  callback,
  mode,
  ownerableType,
  ownerableId,
}) => {
  const { t } = useTranslation();
  const form = useForm({
    mode,
    documentId,
    callback,
    ownerableId,
    ownerableType,
  });
  const completeForm = !!documentId || form?.sections?.length > 0;
  return (
    <Dialog
      maxWidth={completeForm ? 'lg' : 'md'}
      scroll="body"
      fullWidth
      onClose={() => close()}
      open={open}
    >
      <Texture>
        <Box p={5}>
          {form.watch('kind') ? (
            <FormDocument
              completeForm={completeForm}
              form={form}
              ownerableType={ownerableType}
              ownerableId={ownerableId}
            />
          ) : (
            <>
              <Typography variant="h3" align="center" mb={3}>
                {t('document.builder')}
              </Typography>

              <Box display="flex" justifyContent="center" mt={5}>
                <Box mr={1}>
                  <Button
                    style={{ height: 100, width: 200 }}
                    variant="outlined"
                    onClick={() => form.setValue('kind', 'waiver')}
                  >
                    <Typography style={fontStyle.xxLarge}>{t('document.kind.waiver')}</Typography>
                  </Button>
                </Box>
                <Box ml={1}>
                  <Button
                    variant="outlined"
                    style={{ height: 100, width: 200 }}
                    onClick={() => form.setValue('kind', 'contract')}
                  >
                    <Typography style={fontStyle.xxLarge}>{t('document.kind.contract')}</Typography>
                  </Button>
                </Box>
                <Box ml={1}>
                  <Button
                    variant="outlined"
                    style={{ height: 100, width: 200 }}
                    onClick={() => {
                      form.setValue('kind', 'questionnaire');
                      form.addSection({ kind: 'text' });
                    }}
                  >
                    <Typography style={fontStyle.xxLarge}>
                      {t('document.kind.questionnaire')}
                    </Typography>
                  </Button>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Texture>
    </Dialog>
  );
};

ModalFormDocument.defaultProps = {
  close: () => {},
  callback: () => {},
  open: false,
  mode: 'new',
};

ModalFormDocument.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  close: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  callback: PropTypes.func,
  open: PropTypes.bool,
  mode: PropTypes.string,
};

export default ModalFormDocument;
