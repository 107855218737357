import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import { FormControlLabel, Radio } from '@material-ui/core';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import Typography, { fontStyle } from 'core/ui/Typography';
import { ButtonPrimary } from 'core/ui/Button';
import useForm from './useForm';

const ModalFormList = ({ currency, item, callback, mode, ownerableId, ownerableType }) => {
  const { t } = useTranslation();

  const { setValue, watch, handleSubmit, onSubmit, errors, loading } = useForm({
    mode,
    item,
    callback,
    ownerableId,
    ownerableType,
  });
  return (
    <>
      <Box mb={3}>
        <TextFieldRounded
          value={watch('code')}
          onChange={(v) => setValue('code', v)}
          placeholder={t('form.label.code')}
          error={errors.code}
          label={t('form.label.code').toUpperCase()}
          required
        />
      </Box>

      <Box ml={2}>
        <Typography style={fontStyle.label} variant="body1">
          {t('channel.membership.label.type').toUpperCase()}
        </Typography>
        <Box display="flex">
          <FormControlLabel
            onClick={() => setValue('kind', 'percentage')}
            control={
              <Radio color="primary" size="small" checked={watch('kind') === 'percentage'} />
            }
            label="%"
          />
          <FormControlLabel
            onClick={() => setValue('kind', 'money')}
            control={<Radio color="primary" size="small" checked={watch('kind') === 'money'} />}
            label={currency?.symbol}
          />
        </Box>
      </Box>
      <Box mb={3}>
        <TextFieldRounded
          value={watch('amount') || ''}
          onChange={(v) => {
            setValue('amount', v);
            setValue('applyAllProducts', false);
          }}
          type="number"
          pattern="[0-9]*"
          error={errors.amount}
          placeholder={t('channel.membership.placeholder.amount')}
          label={t('channel.membership.label.amount').toUpperCase()}
          required
          currency={watch('kind') === 'money'}
          currencySymbol={currency?.symbol}
        />
      </Box>

      <Box mb={3}>
        <TextFieldRounded
          value={watch('limitUse') || ''}
          onChange={(v) => setValue('limitUse', v)}
          type="number"
          pattern="[0-9]*"
          placeholder={t('channel.membership.placeholder.limitUse')}
          label={t('channel.membership.label.limitUse').toUpperCase()}
        />
      </Box>

      <Box mb={3} px={1}>
        <Typography style={fontStyle.label} variant="body1">
          {t('form.product.label.active').toUpperCase()}
        </Typography>
        <Box display="flex">
          <FormControlLabel
            onClick={() => setValue('active', true)}
            control={<Radio color="primary" size="small" checked={!!watch('active')} />}
            label="Yes"
          />
          <FormControlLabel
            onClick={() => setValue('active', false)}
            control={<Radio color="primary" size="small" checked={!watch('active')} />}
            label="No"
          />
        </Box>
      </Box>
      {watch('kind') === 'percentage' && (
        <Box mb={3} px={1}>
          <Typography style={fontStyle.label} variant="body1">
            {t('form.product.label.applyAllProducts').toUpperCase()}
          </Typography>
          <Box display="flex">
            <FormControlLabel
              onClick={() => setValue('applyAllProducts', true)}
              control={<Radio color="primary" size="small" checked={!!watch('applyAllProducts')} />}
              label="Yes"
            />
            <FormControlLabel
              onClick={() => setValue('applyAllProducts', false)}
              control={<Radio color="primary" size="small" checked={!watch('applyAllProducts')} />}
              label="No"
            />
          </Box>
        </Box>
      )}

      <Box mt={3} display="flex" justifyContent="flex-end">
        <ButtonPrimary disabled={loading} onClick={handleSubmit(onSubmit)}>
          {t('button.save')}
        </ButtonPrimary>
      </Box>
    </>
  );
};

ModalFormList.defaultProps = {
  callback: () => {},
  item: null,
  mode: 'new',
};

ModalFormList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  callback: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.object,
  mode: PropTypes.string,
};

export default ModalFormList;
