import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from 'core/ui/Typography';
import Grid from 'core/ui/Grid';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import Box from 'core/ui/Box';
import { scoresTypes } from 'core/utils/consts';
import TextAreaFieldRounded from 'core/ui/TextAreaFieldRounded';
import { ButtonPrimary } from 'core/ui/Button';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { snakeToCamel } from 'core/utils/helpers';
import Radio from 'core/ui/Radio';
import { secondary } from 'core/ui/Colors';
import OptionScore from './OptionScore';

const FormSectionScores = ({
  scoreData,
  setScoreData,
  data,
  disabled,
  index,
  setSection,
  setOpenPR,
}) => {
  const { t } = useTranslation();

  const scoreDataCount = Object.keys(scoreData).filter(
    (x) =>
      x.indexOf('tieBreak') === -1 &&
      !!scoreData[x] &&
      scoresTypes.find((y) => snakeToCamel(y) === x),
  ).length;

  const liftComplext = !!scoreData?.liftComplex;

  const checkScores = () => {
    if (!scoreData.timeCapped && scoreData?.time) {
      if (scoreDataCount > 1) {
        setScoreData({ timeCapped: true });
      }
    }
  };

  useEffect(() => {
    checkScores();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scoreData]);

  useEffect(() => {
    if (scoreDataCount === 1 && scoreData?.timeCapped) {
      setScoreData({ timeCapped: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scoreData]);

  return (
    <>
      <Box display="flex" alignItems="center" className="gap-5">
        <Box flexGrow={1}>
          <TextFieldRounded
            value={scoreData?.name || (index ? `SCORE ${index + 1}` : 'SCORE')}
            onChange={(v) => setScoreData({ name: v })}
            bordered
          />
        </Box>

        <IconButton
          onClick={() => {
            setSection((prev) => ({
              ...prev,
              scores: prev.scores.filter((x) => x !== data),
            }));
          }}
        >
          <Close />
        </IconButton>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} mr={8} />
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={4} md={4} lg={4}>
              <OptionScore
                label={t('workout.section.label.score.time').toUpperCase()}
                value={scoreData.time}
                disabled={liftComplext || disabled}
                onChange={(v) => {
                  setScoreData({ time: v });
                }}
              />
            </Grid>
            <Grid item xs={6} sm={4} md={4} lg={4}>
              <OptionScore
                label={t('workout.section.label.score.rounds').toUpperCase()}
                value={scoreData.rounds}
                disabled={liftComplext || disabled}
                onChange={(v) => {
                  setScoreData({ rounds: v });
                }}
              />
            </Grid>
            <Grid item xs={6} sm={4} md={4} lg={4}>
              <OptionScore
                label={t('workout.section.label.score.round_reps').toUpperCase()}
                value={scoreData.roundReps}
                disabled={liftComplext || disabled}
                onChange={(v) => {
                  setScoreData({ roundReps: v });
                }}
              />
            </Grid>
            <Grid item xs={6} sm={4} md={4} lg={4}>
              <OptionScore
                label={`${t('workout.section.label.score.reps').toUpperCase()}`}
                value={scoreData.reps}
                disabled={liftComplext || disabled}
                onChange={(v) => {
                  setScoreData({ reps: v });
                }}
              />
            </Grid>
            <Grid item xs={6} sm={4} md={4} lg={4}>
              <OptionScore
                label={`${t('workout.section.label.score.weight').toUpperCase()} ${
                  scoreData?.personalRecord?.repMaxType
                    ? ` - ${t(scoreData?.personalRecord?.repMaxType)}`
                    : ''
                }`}
                value={scoreData.weight}
                disabled={liftComplext || disabled}
                onChange={(v) => {
                  setScoreData({ weight: v });
                }}
              />
            </Grid>
            <Grid item xs={6} sm={4} md={4} lg={4}>
              <OptionScore
                label={t('workout.section.label.score.calories').toUpperCase()}
                value={scoreData.calories}
                disabled={liftComplext || disabled}
                onChange={(v) => {
                  setScoreData({ calories: v });
                }}
              />
            </Grid>
            <Grid item xs={6} sm={4} md={4} lg={4}>
              <OptionScore
                label={t('workout.section.label.score.distance').toUpperCase()}
                value={scoreData.distance}
                disabled={liftComplext || disabled}
                onChange={(v) => {
                  setScoreData({ distance: v });
                }}
              />
            </Grid>
            <Grid item xs={6} sm={4} md={4} lg={4}>
              <OptionScore
                label={t('workout.section.label.score.completion').toUpperCase()}
                value={scoreData.completion}
                disabled={liftComplext || disabled}
                onChange={(v) => {
                  setScoreData({ completion: v });
                }}
              />
            </Grid>
            <Grid item xs={6} sm={4} md={4} lg={4}>
              <OptionScore
                label={t('workout.section.label.score.tieBreak').toUpperCase()}
                value={scoreData.tieBreak}
                disabled={liftComplext || disabled}
                onChange={(v) => {
                  setScoreData({ tieBreak: v });
                }}
              />

              {scoreData.tieBreak && (
                <Box pl={3}>
                  <SelectBoxObject
                    label={t('workout.section.tieBreakScore').toUpperCase()}
                    options={scoresTypes
                      .filter((x) => x !== 'tie_break' && x !== 'round_reps')
                      .map((x) => ({
                        id: x,
                        name: t(`workout.section.label.score.${x}`),
                      }))}
                    bordered
                    value={scoreData.tieBreakScore}
                    setValue={(v) => setScoreData({ tieBreakScore: v })}
                  />

                  <Box mt={1}>
                    <TextAreaFieldRounded
                      name="tieBreakDescription"
                      label={t('workout.label.tieBreakDescription')}
                      value={scoreData.tieBreakDescription}
                      onChange={(v) => setScoreData({ tieBreakDescription: v })}
                      bordered
                    />
                  </Box>
                </Box>
              )}
            </Grid>

            <Grid item xs={6} sm={12} md={12} lg={12}>
              <OptionScore
                label={t('workout.section.label.score.liftComplex').toUpperCase()}
                value={!!scoreData.liftComplex}
                disabled={scoreDataCount > 0 || disabled}
                onChange={(v) => {
                  setScoreData({ liftComplex: v ? {} : null });
                }}
              />

              {scoreData?.liftComplex && (
                <Box display="flex" className="gap-10" pl={8}>
                  <Box style={{ minWidth: 140 }} className="gap-5">
                    <Radio
                      checked={!data?.liftComplex?.custom}
                      label={t('workout.section.label.score.setAndReps')}
                      onClick={() =>
                        setScoreData({ liftComplex: { ...scoreData.liftComplex, custom: false } })
                      }
                      color={secondary}
                    />
                    <Radio
                      checked={!!data?.liftComplex?.custom}
                      label={t('workout.section.label.score.custom')}
                      onClick={() =>
                        setScoreData({ liftComplex: { ...scoreData.liftComplex, custom: true } })
                      }
                      color={secondary}
                    />
                  </Box>
                  <Box>
                    {!scoreData?.liftComplex?.custom && (
                      <Box display="flex" className="gap-10" alignItems="center">
                        <TextFieldRounded
                          placeholder={t('label.sets')}
                          value={scoreData?.liftComplex?.sets}
                          onChange={(v) => {
                            setScoreData({
                              liftComplex: { ...scoreData.liftComplex, sets: v },
                            });
                          }}
                          paperStyle={{ maxWidth: 70 }}
                          bordered
                          type="number"
                          pattern="[1-9]*"
                        />
                        x
                        <TextFieldRounded
                          placeholder={t('label.reps')}
                          value={scoreData?.liftComplex?.reps}
                          onChange={(v) => {
                            setScoreData({
                              liftComplex: { ...scoreData.liftComplex, reps: v },
                            });
                          }}
                          paperStyle={{ maxWidth: 70 }}
                          bordered
                          type="number"
                          pattern="[1-9]*"
                        />
                      </Box>
                    )}
                    {!!scoreData?.liftComplex?.custom && (
                      <Box display="flex" className="gap-10" alignItems="center">
                        <TextFieldRounded
                          placeholder={t('label.totalSets')}
                          value={scoreData?.liftComplex?.sets}
                          onChange={(v) => {
                            setScoreData({
                              liftComplex: { ...scoreData.liftComplex, sets: v },
                            });
                          }}
                          paperStyle={{ maxWidth: 100 }}
                          bordered
                          type="number"
                          pattern="[1-9]*"
                        />
                      </Box>
                    )}
                  </Box>
                </Box>
              )}
            </Grid>

            {scoreData && scoreData.time && scoreDataCount > 1 && (
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <OptionScore
                  label={
                    <Typography variant="body2">
                      {t('workout.section.label.option.timeCapped').toUpperCase()}
                    </Typography>
                  }
                  disabled={disabled}
                  value={scoreData.timeCapped}
                  onChange={(v) => {
                    setScoreData({ timeCapped: v });
                  }}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>

      {setOpenPR && (
        <Box my={1} display="flex" alignItems="center" justifyContent="center">
          <ButtonPrimary
            onClick={() => {
              setOpenPR({
                open: true,
                prScore: true,
                scoreIndex: index,
                liftComplext,
              });
            }}
          >
            {scoreData?.personalRecord?.movementName
              ? `${scoreData?.personalRecord?.movementName} - ${t(
                  scoreData?.personalRecord?.repMaxType,
                )}`
              : t('button.assignLift')}
          </ButtonPrimary>
        </Box>
      )}
    </>
  );
};

export default FormSectionScores;

FormSectionScores.defaultProps = {
  isVideoClass: false,
  bordered: false,
};
