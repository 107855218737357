import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import Typography from 'core/ui/Typography';
import { ButtonPrimary } from 'core/ui/Button';
import { CompetitionRegistrationContext } from 'contexts';
import SelectorPaymentMethod, { PaymentMethodViewType } from 'components/Account/Selector';
import LoaderSm from 'core/ui/LoaderSm';
import { sessionSelectors } from 'modules/session';
import { useSelector } from 'react-redux';
import { channelCurrency } from 'core/utils/formatters';
import Checkbox from 'core/ui/Checkbox';
import Paper from 'core/ui/Paper';
import { secondary } from 'core/ui/Colors';
import VendorRegistration from './VendorRegistration';
import RegistrationTeam from './Team';
import RegistrationCoupon from './Coupon';
import RegistrationIndividual from './Individual';
import DonationSection from './DonationSection';
import AffiliateRegistration from '../AffiliateRegistration';
import AddAthletes from './Teams/AddAthletes';

export default function RegistrationFinish() {
  const { t } = useTranslation();
  const context = useContext(CompetitionRegistrationContext);
  const currentUser = useSelector((state) => sessionSelectors.getUser(state));

  useEffect(() => {
    if (!context.registerAsCompetitor) {
      context.setInvites([{}]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context?.competition, context.registerAsCompetitor]);

  const { competition } = context;
  if (context?.registerTypes?.affiliate) {
    return (
      <AffiliateRegistration
        buttonColor={context?.competition?.buttonColor}
        close={context?.close}
      />
    );
  }

  return (
    <Box mx={5} mt={2}>
      <Typography
        align="center"
        variant="h5"
        mb={2}
        style={{ color: context?.competition?.darkBackground ? 'white' : secondary }}
      >
        {t('competition.label.completeRegistration')}
      </Typography>

      {context?.registerTypes?.vendor && (
        <Paper style={{ backgroundColor: context?.cardBgColor }} p={2} mb={2}>
          <Box mb={2}>
            <Typography
              variant="h5"
              style={{ color: context?.competition?.darkBackground ? 'white' : secondary }}
            >
              {t('competition.label.registration.vendor')}
            </Typography>
            <Typography
              mx={2}
              variant="body2"
              style={{ color: context?.competition?.darkBackground ? 'white' : secondary }}
            >
              {context?.registerTypes?.vendor?.name}
            </Typography>
          </Box>
          <VendorRegistration />
        </Paper>
      )}
      {context.registerAsCompetitor && context?.isTeam && (
        <Paper style={{ backgroundColor: context?.cardBgColor }} p={2} mb={2}>
          <Box mb={2}>
            <Typography
              variant="h5"
              style={{ color: context?.competition?.darkBackground ? 'white' : secondary }}
            >
              {t('competition.label.registration.team')}
            </Typography>
          </Box>
          <RegistrationTeam />
        </Paper>
      )}
      {context.registerAsCompetitor && !context?.isTeam && (
        <Paper style={{ backgroundColor: context?.cardBgColor }} p={2} mb={2}>
          <Box mb={2}>
            <Typography
              variant="h5"
              style={{ color: context?.competition?.darkBackground ? 'white' : secondary }}
            >
              {t('competition.label.registration.individual')}
            </Typography>
          </Box>
          <DivisionsInfo competition={competition} registerTypes={context?.registerTypes} />
          <RegistrationIndividual />
        </Paper>
      )}

      {context?.registerTypes?.volunteers?.length > 0 && (
        <Paper style={{ backgroundColor: context?.cardBgColor }} p={2} mb={2}>
          <Box mb={2}>
            <Typography
              variant="h5"
              style={{ color: context?.competition?.darkBackground ? 'white' : secondary }}
            >
              {t('competition.label.registration.volunteer')}
            </Typography>
            <Typography
              variant="body2"
              style={{ color: context?.competition?.darkBackground ? 'white' : secondary }}
            >
              {context?.registerTypes?.volunteers?.map((x) => x.name).join(',')}
            </Typography>
          </Box>
        </Paper>
      )}

      {!context?.registerAsCompetitor && (
        <Box my={2}>
          <AddAthletes
            competition={competition}
            invites={context?.invites}
            setInvites={context?.setInvites}
            hideCustomFields={!context?.registerTypes?.vendor}
            eventType="individual"
          />
        </Box>
      )}

      {competition?.isCoach && (
        <Box my={2} display="flex" justifyContent="center">
          <Checkbox
            label={t('competition.registration.skipPayment')}
            checked={context?.skipPayment}
            onClick={() => context.setSkipPayment(!context?.skipPayment)}
            labelColor={context?.competition?.darkBackground ? 'white' : secondary}
            color={context?.competition?.buttonColor}
          />
        </Box>
      )}

      {!context.skipPayment && !context?.isFree && (
        <>
          {!!context?.registerTypes?.regType && competition?.hasCoupons && (
            <Box mb={2}>
              <RegistrationCoupon
                competition={competition}
                coupon={context.coupon}
                setCoupon={context.setCoupon}
                eventType={context?.isTeam ? 'team' : 'individual'}
              />
            </Box>
          )}
          <Paper mb={2} px={2}>
            <SelectorPaymentMethod
              selectedPaymentMethod={context?.selectedPaymentMethod}
              setSelectedPaymentMethod={context?.setSelectedPaymentMethod}
              view={context?.viewMethodPayment}
              setView={context?.setViewMethodPayment}
              userId={currentUser.id}
              userInfo={{
                name: currentUser?.name,
                phone: currentUser?.phone,
                email: currentUser?.email,
              }}
              cardpointe={!!competition?.channel?.useCardpointe}
              onlyMethod={competition?.channel?.allowAchPayments ? null : 'card'}
            />
          </Paper>
        </>
      )}

      {!context?.skipPayment && !!context.competition?.donations && (
        <Box my={10}>
          <DonationSection
            competition={context.competition}
            value={context.registrationData.donationAmount}
            setValue={(v) =>
              context.setRegistrationData((prev) => ({ ...prev, donationAmount: v }))
            }
          />
        </Box>
      )}

      {context.resume?.total > 0 && (
        <Box mx={2} mt={3}>
          <Typography
            align="center"
            variant="h6"
            style={{ color: context?.competition?.darkBackground ? 'white' : secondary }}
          >
            {t('competition.registration.cost')}
          </Typography>
          <Typography
            align="center"
            style={{ color: context?.competition?.darkBackground ? 'white' : secondary }}
            variant="h2"
          >
            {channelCurrency(context.resume?.total, context?.competition?.currency)}
          </Typography>
        </Box>
      )}

      {context.viewMethodPayment !== PaymentMethodViewType.form && (
        <Box display="flex" px={5} justifyContent="flex-end">
          <ButtonPrimary
            disabled={context?.loadingRegistration}
            onClick={() => context?.confirmRegistration()}
            style={{ backgroundColor: competition?.buttonColor }}
            type="submit"
            fullWidth
            m={1}
          >
            <Box display="flex" alignItems="center">
              <LoaderSm loading={context?.loadingRegistration} width={20} />
              <Typography ml={1} component="span">
                {t('register')}
              </Typography>
            </Box>
          </ButtonPrimary>
        </Box>
      )}
    </Box>
  );
}

const DivisionsInfo = ({ competition, registerTypes }) => {
  const { t } = useTranslation();
  if (!competition?.hasDivisions) {
    return <></>;
  }

  return (
    <Box px={2} mb={2}>
      <Typography style={{ color: competition?.darkBackground ? 'white' : secondary }}>
        {t('competition.divisions')}
      </Typography>
      {registerTypes?.divisions?.map((reg) => (
        <Typography style={{ color: competition?.darkBackground ? 'white' : secondary }}>
          {reg?.name}
        </Typography>
      ))}
    </Box>
  );
};

RegistrationFinish.defaultProps = {
  open: false,
  close: null,
  callback: () => {},
};
