import React, { useEffect } from 'react';
import Button, { ButtonPrimary } from 'core/ui/Button';
import { Box, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Typography from 'core/ui/Typography';
import Texture from 'core/ui/Texture';
import useSelectors from 'modules/map/useSelectors';
import Modules from 'modules';
import useActions from 'modules/map/useActions';
import { useDispatch } from 'react-redux';
import notifications from 'modules/notifications';
import LoaderSm from 'core/ui/LoaderSm';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import { Controller, useForm } from 'react-hook-form';
import useConfirmation from 'core/useConfirmation';
import { channelKeysModule } from 'modules/channelKeys';

import { MembershipDiscountCode } from './MembershipDiscountCode';
import { Resume } from './Resume';

const ModalSwitchMembership = ({ showPlans, open, close, channel, channelKey, callbackSave }) => {
  const { t } = useTranslation();
  const loadingSwitch = useSelectors(channelKeysModule, 'loading');
  const { request } = useActions();
  const { confirmation } = useConfirmation();
  const dispatch = useDispatch();

  const { control, handleSubmit, reset, watch, setValue } = useForm();

  useEffect(() => {
    if (!open) {
      reset({});
    }
  }, [open, reset]);

  const confirmResume = (data) => {
    confirmation({
      description: t('mambership.switch.alert'),
      yesClick: () => {
        request({
          action: Modules.channelKeys.actions.confirmSwitchMembership,
          data: {
            channelId: channelKey?.channel?.id,
            channelKeyId: channelKey?.id,
            channelMembershipPlanId: data?.membershipPlanId,
            channelMembershipCouponId: data?.membershipCoupon?.id,
          },
          options: {
            onSuccess: () => {
              if (callbackSave) {
                callbackSave();
              }
              dispatch(notifications.success(t('membership.switch.success')));

              close();
            },
            onError: (ex) => {
              dispatch(notifications.error(ex));
            },
          },
        });
      },
    });
  };

  const membershipPlanId = watch('membershipPlanId');

  useEffect(() => {
    setValue('membershipCoupon', null);
  }, [membershipPlanId, setValue]);

  const truthPlansArray =
    showPlans ??
    channel?.membershipPlans?.filter((x) => x.active && x.id !== channelKey?.membershipPlan?.id) ??
    [];

  return (
    <Dialog open={open} onClose={close} maxWidth="sm" fullWidth>
      <Box>
        <Texture>
          <DialogContent>
            <Typography>{t('channel.plan.switchPlan')}</Typography>

            <Box m={3}>
              <LoaderSm loading={loadingSwitch} center />
            </Box>

            <div className="tw-mb-2 tw-flex tw-flex-col tw-gap-4">
              <Controller
                control={control}
                render={({ field: { onChange, value } }) => (
                  <div>
                    <SelectBoxObject
                      label={t('label.membership.chooseNewPlan')}
                      options={truthPlansArray}
                      propLabel="name"
                      propValue="id"
                      setValue={onChange}
                      value={value}
                    />
                  </div>
                )}
                name="membershipPlanId"
              />

              {membershipPlanId && (
                <Controller
                  control={control}
                  name="membershipCoupon"
                  render={({ field }) => (
                    <MembershipDiscountCode
                      channelId={channelKey?.channelId}
                      membershipPlanId={membershipPlanId}
                      coupon={field.value}
                      onCoupon={field.onChange}
                    />
                  )}
                />
              )}

              <Resume control={control} channel={channel} channelKey={channelKey} />
            </div>
          </DialogContent>
        </Texture>
        <DialogActions>
          <Button variant="outlined" onClick={close} color="secondary">
            {t('button.cancel')}
          </Button>
          <ButtonPrimary
            loading={loadingSwitch}
            disabled={loadingSwitch}
            onClick={() => handleSubmit(confirmResume)()}
            color="primary"
          >
            {t('button.confirm')}
          </ButtonPrimary>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default ModalSwitchMembership;
