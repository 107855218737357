import React, { createContext } from 'react';

import Image from 'assets/img/bg-site.jpeg'; // Import using relative path

import { makeStyles } from '@material-ui/core/styles';

import LanguageSelector from 'components/LanguageSelector';

import Box from 'core/ui/Box';
import CreateTypeAccount from './CreateTypeAccount';

import logoHeader from '../../../assets/img/logo-transparent.png';

export const Context = createContext();

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '300px',
    justifyContent: 'center',
  },
  logo: {
    width: 260,
  },
  form: {
    borderRadius: 10,
    backgroundColor: 'rgba(255, 255, 255, 0.60)',
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 30,
    paddingTop: 10,
    width: '100%',
    marginTop: theme.spacing(5),
  },
  paperContainer: {
    backgroundImage: `url(${Image})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    height: '100vh',
    backgroundAttachment: 'fixed',
    backgroundSize: 'cover',
    backgroundColor: '#d9d9d9',
    display: 'flex',
    justifyContent: 'center',
  },
}));

export default () => {
  const classes = useStyles();
  return (
    <div className={classes.paperContainer}>
      <LanguageSelector
        style={{
          position: 'absolute',
          top: 5,
          right: 50,
          border: 'none',
        }}
      />
      <div className={classes.paper}>
        <Box mb={5}>
          <img src={logoHeader} className={classes.logo} alt="Stream Fitness Logo" />
        </Box>
        <form className={classes.form}>
          <CreateTypeAccount />
        </form>
      </div>
    </div>
  );
};
