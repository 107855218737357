import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { Context } from 'components/Login/Form';
import TextFieldRounded from 'core/ui/TextFieldRounded';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 5,
  },
}));

export default function TextConfirm({ pin }) {
  const classes = useStyles();
  const formContext = useContext(Context);

  return (
    <Paper className={classes.root}>
      <TextFieldRounded
        name="code"
        error={!!formContext.errors.code}
        inputRef={formContext.register({ required: true })}
        autoComplete="one-time-code"
        placeholder="Enter the code"
        inputProps={{ maxLength: pin ? 4 : 5 }}
        style={{ maxWidth: 100 }}
        required
        type="password"
      />
    </Paper>
  );
}
