import React from 'react';
import PropTypes from 'prop-types';
import Typography from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import { DialogContent, Dialog } from '@material-ui/core';
import Texture from '../Texture';
import DownloadApp from './DownloadApp';

export default function ModalDownloadApp({ open, close }) {
  const { t } = useTranslation();

  return (
    <Dialog open={open} maxWidth="xs" fullWidth onClose={close}>
      <Texture>
        <DialogContent>
          <Typography mb={5} align="center" ml={3} color="primary" variant="h4">
            {t('stream.mobile.downloadApp')}
          </Typography>
          <DownloadApp />
        </DialogContent>
      </Texture>
    </Dialog>
  );
}

ModalDownloadApp.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};
