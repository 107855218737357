import api from 'core/api';

export const userHouseholdModule = 'userHousehold';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const actions = {
  toogleUserHousehold: {
    module: userHouseholdModule,
    name: 'toogleUserHousehold',
    api: (data) => api.post(`/users/toggle_user_household`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
};

export default {
  actions,
  state: {
    loading: false,
    data: [],
    child: null,
  },
};
