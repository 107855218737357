import React, { useEffect } from 'react';
import Box from 'core/ui/Box';
import { useSelector, useDispatch } from 'react-redux';
import LoaderSm from 'core/ui/LoaderSm';
import { channelListsActions, channelListsSelectors } from 'modules/channelLists';
import GridSkeleton from 'core/ui/GridSkeleton';
import Typography from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import { sessionSelectors } from 'modules/session';
import ListWorkouts from './ListWorkouts';

const ChannelLists = () => {
  const { t } = useTranslation();
  const user = useSelector((state) => sessionSelectors.getUser(state));
  const loading = useSelector((state) => channelListsSelectors.getLoading(state));
  const userChannelLists = useSelector((state) => channelListsSelectors.getUserChannelLists(state));
  const dispatch = useDispatch();

  useEffect(() => {
    if (user.id) {
      dispatch(channelListsActions.getUserChannelListsStart(user.id));
    }
  }, [dispatch, user]);

  return (
    <>
      {userChannelLists && userChannelLists.length > 0 ? (
        <>
          <Box display="flex" justifyContent="center" alignItems="center">
            <LoaderSm loading={loading} />
          </Box>

          <Box mt={2}>
            {loading ? (
              <Box>
                <GridSkeleton />
              </Box>
            ) : (
              <>
                {userChannelLists &&
                  userChannelLists
                    .filter((x) => x.workouts && x.workouts.length > 0)
                    .map((row) => <ListWorkouts key={row.name} item={row} />)}
              </>
            )}
          </Box>
        </>
      ) : (
        <>
          {!loading && (
            <Typography align="center" m={5} variant="h5">
              {t('channel.lists.notExists')}
            </Typography>
          )}
        </>
      )}
    </>
  );
};

export default ChannelLists;
