import { useEffect } from 'react';
import { useForm } from 'react-hook-form-v5';
import { formatDateToApi } from 'core/utils/formatters';
import { scoresTypes } from 'core/utils/consts';
import notifications from 'modules/notifications';
import { useTranslation } from 'react-i18next';
import humps from 'humps';
import useActions from 'modules/map/useActions';
import { useDispatch } from 'react-redux';
import Modules from 'modules';

export default ({ callback, selectedBenchmark }) => {
  const { request } = useActions();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { register, handleSubmit, setValue, watch, reset, errors } = useForm();

  const getTimeBenchmark = () => {
    if (selectedBenchmark && selectedBenchmark.timer) {
      return selectedBenchmark.timer.work;
    }
    return null;
  };

  const validateScores = (scores) => {
    let hasValue = false;
    Object.keys(scores).forEach((s) => {
      const scoreToValidate = s === 'roundRepsRounds' ? 'roundReps' : s;
      if (scores[s] && scoresTypes.find((x) => x === humps.decamelize(scoreToValidate))) {
        hasValue = true;
      }
    });

    if (!hasValue) {
      dispatch(notifications.warning(t('benchmark.section.validate.scores')));
    }
    return hasValue;
  };

  const onSubmit = (values) => {
    const hasTimeScore = Object.keys(selectedBenchmark.scores).find((x) => x === 'time');
    const timeScore = values.time ? values.time.value : getTimeBenchmark();

    const scores = {
      ...values,
    };

    if (hasTimeScore) {
      scores.time = timeScore;
    }

    if (!validateScores(scores)) {
      return;
    }

    const data = {
      benchmarkSectionId: selectedBenchmark.id,
      date: values.date ? formatDateToApi(values.date) : null,
      scores,
    };

    request({
      action: Modules.benchmarkSections.actions.postBenchmarkSectionUser,
      data,
      options: {
        onSuccess: () => {
          if (callback) {
            callback();
            request({ action: Modules.benchmarkSections.actions.getBenchmarkSections });
            request({
              action: Modules.benchmarkSections.actions.getBenchmarkSections,
              data: { benchmarkSectionId: selectedBenchmark.id },
            });
          }
        },
      },
    });
  };

  useEffect(() => {
    register('date');
    register('variation');

    const keys = Object.keys(selectedBenchmark.scores);
    keys.forEach((x) => {
      register(`${x}Notes`);
      if (x === 'roundReps') {
        register('roundRepsRounds');
        register('roundRepsReps');
      } else {
        register(x);
      }
      if (x === 'time') {
        register('timeCapped');
      }
    });

    keys.forEach((x) => {
      if (['weight', 'calories'].find((y) => y === x)) {
        let prop = '';
        if (x === 'weight') {
          prop = 'typeWeight';
        }
        if (x === 'distance') {
          prop = 'typeDistance';
        }
        register(prop);
      }
    });

    setTimeout(() => {
      setValue('variation', 'prescribed');
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register]);

  return {
    register,
    setValue,
    watch,
    reset,
    errors,
    onSubmit,
    handleSubmit,
  };
};
