import React, { useEffect } from 'react';
import Box from 'core/ui/Box';
import Paper from 'core/ui/Paper';
import useGalleryWatch from 'components/Competition/Workout/Watch/GalleryView/useGalleryWatch';
import HeaderPlayer from 'components/Competition/Workout/Watch/HeaderPlayer';
import { primary } from 'core/ui/Colors';
import Typography from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import { sessionSelectors } from 'modules/session';
import { useSelector } from 'react-redux';
import Gallery from './Gallery';

export default ({ workout, competition }) => {
  const { t } = useTranslation();
  const player = useGalleryWatch({ workout });
  const currentUser = useSelector((state) => sessionSelectors.getUser(state));

  const allRecordings = () => {
    if (workout?.invites?.length > 0) {
      const ownerRec = workout?.recordingVideos.find((x) => x.user.id === workout.user.id) || {};
      const recs = [
        {
          ...ownerRec,
          user: workout.user,
          processing:
            !!workout?.recordings?.find((x) => x.userId === currentUser.id) && !ownerRec?.vimeoUrl,
        },
      ];
      workout.invites.forEach((invite) => {
        const rec = workout?.recordingVideos.find((x) => x.user.id === invite.user.id) || {};
        recs.push({
          ...rec,
          user: invite.user,
          processing:
            !!workout?.recordings?.find((x) => x.userId === invite?.user?.id) && !rec?.vimeoUrl,
        });
      });

      return recs;
    }

    return workout?.recordingVideos;
  };

  const recordings = allRecordings();

  useEffect(() => {
    if (workout && recordings) {
      player.setCheckedTeams(recordings.map((x) => x.user.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workout]);

  return (
    <Box>
      <Paper p={3} mt={3} className="paper-rounded">
        <Typography variant="h5" color="primary">
          {t('workout.recordings')}
        </Typography>
        <Box mt={3}>
          <HeaderPlayer
            competition={competition}
            player={player}
            buttonColor={primary}
            workout={workout}
            hideLogo
          />
        </Box>
        <Box my={3}>
          <Paper className="paper-rounded opacity-80" p={3}>
            <Box>
              {workout && workout.id && (
                <Gallery
                  player={player}
                  workout={workout}
                  recordings={recordings}
                  buttonColor={primary}
                />
              )}
            </Box>
          </Paper>
        </Box>
      </Paper>
    </Box>
  );
};
