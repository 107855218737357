/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import { InputLabel } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { getBase64 } from 'core/utils/helpers';
import Typography from 'core/ui/Typography';
import Box from 'core/ui/Box';
import Button, { ButtonPrimarySm } from 'core/ui/Button';
import Card from 'core/ui/Card';
import { primary } from 'core/ui/Colors';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import { WorkoutContext } from '.';

const UploadAttachments = () => {
  const formContext = useContext(WorkoutContext);
  const { t } = useTranslation();

  const handleCapture = ({ target }) => {
    if (target.files.length) {
      for (let i = 0; i < target.files.length; i += 1) {
        const file = target.files[i];
        getBase64(file, (result) => {
          formContext.setAttachments((prev) => [
            ...(prev || []),
            {
              url: URL.createObjectURL(file),
              data: result,
              name: file.name.replace('.pdf', ''),
              type: file.type,
              extension: file.name.substring(file.name.lastIndexOf('.') + 1),
            },
          ]);
        });
      }
    }
  };

  const removeAttchment = (elem) => {
    if (elem.id) {
      formContext.setAttachments((prev) => {
        const newAtt = [...prev];
        return newAtt.map((file) => {
          if (file.id === elem.id) {
            return { ...file, destroy: true };
          }
          return { ...file };
        });
      });
    } else {
      formContext.setAttachments((prev) => prev.filter((x) => x !== elem));
    }
  };

  const changeAttchament = (prop, value, item) => {
    formContext.setAttachments((prev) =>
      prev.map((x) => {
        const newItem = { ...x };
        if (x === item) {
          newItem[prop] = value;
        }

        return newItem;
      }),
    );
  };

  const randomId = Math.floor(Math.random() * 101);

  return (
    <Box className="link-hover">
      <Box my={3}>
        <Card
          title="Attachments"
          action={
            <Box p={3}>
              <ButtonPrimarySm color="primary" fullWidth>
                <InputLabel htmlFor={`icon-button-file-${randomId}`}>
                  <Box display="flex" alignItems="center" px={2}>
                    <PictureAsPdfIcon style={{ color: 'white' }} />
                    <Typography ml={3} style={{ color: 'white' }} variant="subtitle2">
                      {t('button.attachments')}
                    </Typography>
                  </Box>
                </InputLabel>
              </ButtonPrimarySm>
            </Box>
          }
        >
          <Box p={3}>
            {formContext.attachments &&
              formContext.attachments
                .filter((x) => !x.destroy)
                .map((f, index) => (
                  <Box key={index.toString()} display="flex" alignItems="center">
                    <Box display="flex" alignItems="center" flexGrow={1}>
                      <PictureAsPdfIcon style={{ fontSize: 20, color: primary }} />
                      {/* <Typography ml={3}>{f.name}</Typography> */}
                      <Box flexGrow={1} ml={3}>
                        <TextFieldRounded
                          value={f.name || ''}
                          onChange={(v) => changeAttchament('name', v, f)}
                          placeholder={t('competition.division.label.name')}
                          bordered
                        />
                      </Box>
                    </Box>
                    <Button ml={3} onClick={() => removeAttchment(f)}>
                      {t('button.remove')}
                    </Button>
                  </Box>
                ))}
          </Box>
        </Card>
      </Box>

      <input
        color="primary"
        type="file"
        onChange={handleCapture}
        id={`icon-button-file-${randomId}`}
        style={{ display: 'none' }}
        accept=".pdf"
        onClick={(e) => {
          e.target.value = null;
        }}
      />
    </Box>
  );
};

export default UploadAttachments;
