import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form-v5';
import { useDispatch, useSelector } from 'react-redux';
import notifications from 'modules/notifications';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import useSelectors from 'modules/map/useSelectors';
import { channelsCoachesClocksModule } from 'modules/channelsCoachesClocks';
import { formatDateToApi } from 'core/utils/formatters';
import { timeZoneName } from 'core/utils/formatters/date';
import { channelsCoachesRatesModule } from 'modules/channelsCoachesRates';
import { channelsSelectors } from 'modules/channels';

export default ({ user, item, callback }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const channel = useSelector((state) => channelsSelectors.getChannel(state));
  const loading = useSelectors(channelsCoachesClocksModule, 'loading');
  const { request } = useActions();
  const { register, handleSubmit, setValue, watch, reset, errors } = useForm();
  const coachRates = useSelectors(channelsCoachesRatesModule, 'data');

  const getCoachRates = () => {
    request({
      action: Modules.channelsCoachesRates.actions.getChannelsCoachesRates,
      data: { channelId: channel?.id, userId: user?.id },
    });
  };

  useEffect(() => {
    if (user && user.id && channel) {
      getCoachRates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel, user]);

  const onSubmit = (values) => {
    const startAt = formatDateToApi(new Date(), timeZoneName());

    let data = {
      id: item?.id || null,
      timezone: timeZoneName(),
      userId: user.id,
    };

    if (!item?.id) {
      data = {
        ...data,
        startAt,
        description: values?.description,
        channelId: channel?.id,
      };
    } else {
      data = {
        ...data,
        endAt: formatDateToApi(values?.endAt, timeZoneName()),
      };
    }

    request({
      action: !item?.id
        ? Modules.channelsCoachesClocks.actions.postChannelsCoachesClocks
        : Modules.channelsCoachesClocks.actions.putChannelsCoachesClocks,
      data,
      options: {
        onSuccess: () => {
          if (callback) {
            callback(values.name);
          }
          dispatch(notifications.success(t('message.success.save.coachClock')));
          request({
            action: Modules.channelsCoachesClocks.actions.getChannelsCoachesClocks,
            data: { channelId: channel?.id, userId: user?.id },
          });
        },
      },
    });
  };

  useEffect(() => {
    register('description', { required: true });
    register('channelsCoachesRateId', { required: true });
    register('endAt', { required: false });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register]);

  useEffect(() => {
    if (item && item?.id) {
      reset({
        ...item,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, item?.id]);

  return {
    register,
    setValue,
    watch,
    reset,
    errors,
    onSubmit,
    handleSubmit,
    loading,
    coachRates,
  };
};
