/* eslint-disable react/prop-types */
import Box from 'core/ui/Box';
import React from 'react';

import { useTranslation } from 'react-i18next';
import CalendarsRegistrationsData from './CalendarsRegistrationsData';

export default function CalendarsRegistrations({ channel, calendarsRegistrations }) {
  const { t } = useTranslation();
  return (
    <Box>
      <CalendarsRegistrationsData
        title={t('payroll.calendarsRegistrations')}
        channel={channel}
        calendarsRegistrations={calendarsRegistrations}
      />
    </Box>
  );
}
