import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import Box from 'core/ui/Box';
import Texture from 'core/ui/Texture';
import Form from './Form';

const ModalFormPersonalRecord = ({ close, modal }) => (
  <Dialog open={modal.opened} onClose={close} maxWidth="sm" fullWidth scroll="body">
    <Texture>
      <Box p={5}>
        <Form callback={close} personalRecord={modal.personalRecord} />
      </Box>
    </Texture>
  </Dialog>
);

ModalFormPersonalRecord.propTypes = {
  close: PropTypes.func,
};

ModalFormPersonalRecord.defaultProps = {
  close: () => {},
};

export default ModalFormPersonalRecord;
