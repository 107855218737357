import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from 'core/ui/Box';
import FontIcon from 'core/ui/FontIcon';
import { Avatar, Collapse, Tooltip, colors } from '@material-ui/core';
import Typography from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import { bgDrawerColor, secondary } from 'core/ui/Colors';
import MIcon from 'core/ui/MIcon';
import { useHistory, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { sessionSelectors } from 'modules/session';
import LibraryIcon from 'core/ui/MIcon/LibraryIcon';
import EngageIcon from 'core/ui/MIcon/EngageIcon';
import Divider from 'core/ui/Divider';
import useChannelPermissions from '../useChannelPermissions';
import menuItems from './menuItems';
import ShowMenu from './ShowMenu';

const useStyles = makeStyles(() => ({
  root: {
    height: '100vh',
    backgroundColor: bgDrawerColor,
    zIndex: 9999,
    maxHeight: '100%',
    overflow: 'auto',
    marginBottom: 72,
  },
  divider: {
    height: 0.1,
    backgroundColor: 'rgba(255,255,255,0.3)',

    marginLeft: 60,
    marginRight: 14,
    marginBottom: 5,
  },
  btnOpenClose: {
    zIndex: 9999,
    backgroundColor: secondary,
    '&:hover': {
      backgroundColor: secondary,
    },
    padding: 1,
    top: 5,
  },
  btnOpenCloseAbsolute: {
    zIndex: 9999,
    backgroundColor: secondary,
    '&:hover': {
      backgroundColor: secondary,
    },
    padding: 1,
    top: 5,
    right: 5,
    position: 'absolute',
  },
  content: {
    zIndex: 1000,
  },
  itemMenu: {
    cursor: 'pointer',
    transition: 'opacity .1s ease-in-out',
    opacity: 0.6,
    height: 35,
    '&:hover': {
      opacity: 1,
    },
  },
  itemMenuShow: {
    opacity: 0.6,
    '&:hover': {
      cursor: 'pointer',
      opacity: 1,
    },
  },
  itemMenuActive: {
    opacity: 1,
  },
}));

const Menu = ({ mode, tab, channel }) => {
  const classes = useStyles();
  const history = useHistory();
  const currentUser = useSelector((state) => sessionSelectors.getUser(state));
  const [popperOpened, setPopperOpened] = useState({});
  const [openSubMenu, setOpenSubMenu] = useState(false);
  const { t } = useTranslation();
  const permissions = useChannelPermissions({ channel });
  const { param1 } = useParams();

  const navigateMenu = (newTab) => {
    setPopperOpened({});
    history.push(`/channel/manage/${channel?.id}/${newTab}`);
  };

  const iconSize = 25;

  if (!permissions.viewManage) {
    return <></>;
  }

  return (
    <div className={classes.root}>
      <Box display="flex" flexDirection="column" alignItems="center" my={5}>
        <Box display="flex" alignItems="flex-start" p={0} className="hover">
          <Avatar
            variant="rounded"
            src={channel?.image?.image?.size150?.url || channel?.image?.image?.url}
            style={{ height: 34, width: 34 }}
          />
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" className={classes.content} pb={5}>
        {mode === 'edit' && permissions.dashboard && (
          <ItemMenu
            onClick={() => navigateMenu('dashboard')}
            label={t('channel.tab.dashboard').toUpperCase()}
            iconMUI={<MIcon icon="dashboard" color="white" size={iconSize} />}
            divider
            active={tab === 'dashboard'}
          />
        )}
        {(mode === 'new' || permissions.formAccess) && (
          <ShowMenu
            opened={popperOpened?.settings}
            close={() => setPopperOpened({})}
            itemsMenu={
              <Box>
                <Box display="flex" className="gap-5" alignItems="center">
                  <MIcon icon="settings" color="white" size={iconSize} />{' '}
                  <Typography variant="h6" style={{ color: colors.grey[400] }}>
                    {t('channel.tab.settings').toUpperCase()}
                  </Typography>
                </Box>
                <Divider my={2} style={{ backgroundColor: colors.grey[500] }} />
                <Box display="flex" flexDirection="column" className="gap-10">
                  {menuItems.infoMenu?.map((m) => (
                    <ItemMenuShow
                      key={m}
                      onClick={() => navigateMenu(m)}
                      active={tab === m}
                      label={t(`channel.tab.${m}`)}
                      mlLabel={7}
                    />
                  ))}
                </Box>
              </Box>
            }
          >
            <ItemMenu
              onClick={() => setPopperOpened({ settings: !popperOpened?.settings })}
              label={t('channel.tab.settings').toUpperCase()}
              iconMUI={<MIcon icon="settings" color="white" size={iconSize} />}
              divider={false}
              active={tab === 'info'}
            />
          </ShowMenu>
        )}
        {mode === 'edit' && permissions.planAccess && (
          <ItemMenu
            onClick={() => navigateMenu('plans')}
            label={t('channel.tab.plan').toUpperCase()}
            iconMUI={<MIcon icon="layers" color="white" size={iconSize} />}
            divider
            active={tab === 'plans'}
          />
        )}
        {mode === 'edit' && permissions.usersAccess && (
          <Box my={1}>
            <ShowMenu
              opened={popperOpened?.users}
              close={() => setPopperOpened({})}
              itemsMenu={
                <Box>
                  <Box display="flex" className="gap-5" alignItems="center">
                    <MIcon icon="security" color="white" size={iconSize} />{' '}
                    <Typography variant="h6" style={{ color: colors.grey[400] }}>
                      {t('channel.tab.users').toUpperCase()}
                    </Typography>
                  </Box>
                  <Divider my={2} style={{ backgroundColor: colors.grey[500] }} />
                  <Box display="flex" flexDirection="column" className="gap-10">
                    {menuItems.usersMenu?.map((m) => (
                      <ItemMenuShow
                        key={m}
                        onClick={() => navigateMenu(m)}
                        active={tab === m}
                        label={t(`channel.tab.${m}`)}
                        mlLabel={7}
                      />
                    ))}
                  </Box>
                </Box>
              }
            >
              <ItemMenu
                onClick={() => setPopperOpened({ users: !popperOpened?.users })}
                label={t('channel.tab.users').toUpperCase()}
                iconMUI={<MIcon icon="security" color="white" size={iconSize} />}
                divider={false}
                active={menuItems.usersMenu.find((x) => x === tab)}
                my={0}
              />
            </ShowMenu>
          </Box>
        )}
        {mode === 'edit' && permissions.membershipsAccess && (
          <ItemMenu
            onClick={() => navigateMenu('memberships')}
            label={t('channel.tab.memberships').toUpperCase()}
            disabled={!channel?.planValid}
            iconMUI={<MIcon icon="card_membership" color="white" size={iconSize} />}
            divider
            active={tab === 'memberships'}
          />
        )}
        {mode === 'edit' && permissions.documentsAccess && (
          <ItemMenu
            onClick={() => navigateMenu('documents')}
            label={t('channel.tab.documents').toUpperCase()}
            disabled={!channel?.planValid}
            iconMUI={<MIcon icon="assignment" color="white" size={iconSize} />}
            divider
            active={tab === 'documents'}
          />
        )}
        {mode === 'edit' && permissions.scheduleAccess && (
          <>
            <ItemMenu
              onClick={() => navigateMenu('schedules')}
              label={t('channel.tab.schedule').toUpperCase()}
              disabled={!channel?.planValid}
              iconMUI={<MIcon icon="date_range" color="white" size={iconSize} />}
              divider
              active={tab === 'schedules'}
            />

            <Box mt={1}>
              <>
                <ShowMenu
                  opened={popperOpened?.library}
                  close={() => setPopperOpened({})}
                  itemsMenu={
                    <Box>
                      <Box display="flex" className="gap-5" alignItems="center">
                        <LibraryIcon color="white" size={iconSize} />
                        <Typography variant="h6" style={{ color: colors.grey[400] }}>
                          {t('channel.tab.library').toUpperCase()}
                        </Typography>
                      </Box>
                      <Divider my={2} style={{ backgroundColor: colors.grey[500] }} />
                      <Box display="flex" flexDirection="column" className="gap-10">
                        {menuItems.libraryMenus?.map((m) => (
                          <ItemMenuShow
                            key={m}
                            onClick={() => navigateMenu(m)}
                            active={tab === m}
                            label={t(`channel.tab.${m}`)}
                            mlLabel={7}
                          />
                        ))}
                      </Box>
                    </Box>
                  }
                >
                  <ItemMenu
                    onClick={() => setPopperOpened({ library: !popperOpened?.library })}
                    label={t('channel.tab.library').toUpperCase()}
                    iconMUI={<LibraryIcon color="white" size={iconSize} />}
                    disabled={!channel?.planValid}
                    divider={false}
                    active={menuItems.libraryMenus.find((x) => x === tab)}
                    my={0}
                  />
                </ShowMenu>
              </>
            </Box>
          </>
        )}

        {mode === 'edit' && (
          <>
            <ShowMenu
              opened={popperOpened?.programming}
              close={() => setPopperOpened({})}
              itemsMenu={
                <Box>
                  <Box display="flex" className="gap-5" alignItems="center">
                    <MIcon icon="fitness_center" color="white" size={iconSize} />
                    <Typography variant="h6" style={{ color: colors.grey[400] }}>
                      {t('channel.tab.programming').toUpperCase()}
                    </Typography>
                  </Box>
                  <Divider my={2} style={{ backgroundColor: colors.grey[500] }} />
                  <Box display="flex" flexDirection="column" className="gap-10">
                    <ItemMenuShow
                      onClick={() => navigateMenu('programmingPartners')}
                      active={tab === 'programmingPartners'}
                      label={t('channel.tab.programmingPartners')}
                      mlLabel={7}
                    />
                    <ItemMenuShow
                      onClick={() => navigateMenu('programmingCustoms')}
                      active={tab === 'programmingCustoms'}
                      label={t('channel.tab.programmingCustoms')}
                      mlLabel={7}
                    />
                  </Box>
                </Box>
              }
            >
              <ItemMenu
                onClick={() => setPopperOpened({ programming: !popperOpened?.programming })}
                label={t('channel.tab.programming').toUpperCase()}
                iconMUI={<MIcon icon="fitness_center" color="white" size={iconSize} />}
                divider={false}
                disabled={!channel?.planValid}
                my={1}
                mb={0}
                active={tab === 'programmingPartners' || tab === 'programmingCustoms'}
              />
            </ShowMenu>

            <ItemMenu
              onClick={() => navigateMenu('members')}
              label={t('channel.tab.members').toUpperCase()}
              iconMUI={<MIcon icon="group" color="white" size={iconSize} />}
              divider
              disabled={!channel?.planValid}
              active={tab === 'members' || tab === 'importMembers'}
            />

            {channel?.activePlans?.online?.active && (
              <ItemMenu
                onClick={() => navigateMenu('playlists')}
                label={t('channel.tab.playlists').toUpperCase()}
                iconMUI={<MIcon icon="chrome_reader_mode" color="white" size={iconSize} />}
                divider
                disabled={!channel?.planValid}
                active={tab === 'playlists'}
              />
            )}

            {permissions.reportsAccess && (
              <Box mt={1}>
                <ShowMenu
                  opened={popperOpened?.reports}
                  close={() => setPopperOpened({})}
                  itemsMenu={
                    <Box>
                      <Box display="flex" className="gap-5" alignItems="center">
                        <MIcon icon="insert_chart" color="white" size={iconSize} />
                        <Typography variant="h6" style={{ color: colors.grey[400] }}>
                          {t('channel.tab.reports').toUpperCase()}
                        </Typography>
                      </Box>
                      <Divider my={2} style={{ backgroundColor: colors.grey[500] }} />
                      <Box display="flex" flexDirection="column" className="gap-10">
                        {menuItems.reportsMenus(channel, currentUser)?.map((m) => (
                          <Box key={`report-${m?.value}`}>
                            <ItemMenuShow
                              onClick={() => {
                                setOpenSubMenu({
                                  [m?.value]: !openSubMenu[m?.value],
                                });
                              }}
                              active
                              label={m?.label}
                              mlLabel={7}
                            />
                            <Collapse in={openSubMenu[m?.value]}>
                              <Box
                                pl={1}
                                mt={1}
                                display="flex"
                                flexDirection="column"
                                className="gap-10"
                              >
                                {m.items?.map((subMenu) => (
                                  <ItemMenuShow
                                    key={subMenu?.value}
                                    onClick={() => navigateMenu(`reports/${subMenu?.value}`)}
                                    active={param1?.toLowerCase() === subMenu?.value.toLowerCase()}
                                    label={subMenu?.label}
                                    mlLabel={10}
                                  />
                                ))}
                              </Box>
                            </Collapse>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  }
                >
                  <ItemMenu
                    onClick={() => setPopperOpened({ reports: !popperOpened?.reports })}
                    label={t('channel.tab.reports').toUpperCase()}
                    iconMUI={<MIcon icon="insert_chart" color="white" size={iconSize} />}
                    divider={false}
                    disabled={!channel?.planValid}
                    my={0}
                    active={tab?.toLowerCase() === 'reports'}
                  />
                </ShowMenu>
              </Box>
            )}

            {channel?.activePlans?.engage?.active && (
              <ShowMenu
                opened={popperOpened?.engage}
                close={() => setPopperOpened({})}
                itemsMenu={
                  <Box>
                    <Box display="flex" className="gap-5" alignItems="center">
                      <EngageIcon icon="chat" color="white" size={20} />
                      <Typography variant="h6" style={{ color: colors.grey[400] }}>
                        {t('channel.tab.engage').toUpperCase()}
                      </Typography>
                    </Box>
                    <Divider my={2} style={{ backgroundColor: colors.grey[500] }} />
                    <Box display="flex" flexDirection="column" className="gap-10">
                      <ItemMenuShow
                        onClick={() => navigateMenu('automatedWorkflow')}
                        active={tab === 'automatedWorkflow'}
                        label={t('channel.tab.automatedWorkflow')}
                        mlLabel={7}
                      />
                      <ItemMenuShow
                        onClick={() => navigateMenu('stages')}
                        active={tab === 'stages'}
                        label={t('channel.tab.stages')}
                        mlLabel={7}
                      />
                      <ItemMenuShow
                        onClick={() => navigateMenu('contactForms')}
                        active={tab === 'contactForms'}
                        label={t('channel.tab.contactForms')}
                        mlLabel={7}
                      />
                    </Box>
                  </Box>
                }
              >
                <ItemMenu
                  onClick={() => setPopperOpened({ engage: !popperOpened?.engage })}
                  label={t('channel.tab.engage').toUpperCase()}
                  iconMUI={<EngageIcon icon="chat" color="white" size={iconSize} />}
                  divider={false}
                  disabled={!channel?.planValid}
                  active={tab === 'contactForms' || tab === 'automatedWorkflow'}
                  my={0}
                  mt={1}
                />
              </ShowMenu>
            )}

            <ShowMenu
              opened={popperOpened?.communication}
              close={() => setPopperOpened({})}
              itemsMenu={
                <Box>
                  <Box display="flex" className="gap-5" alignItems="center">
                    <MIcon icon="chat" color="white" size={iconSize} />{' '}
                    <Typography variant="h6" style={{ color: colors.grey[400] }}>
                      {t('channel.tab.communication').toUpperCase()}
                    </Typography>
                  </Box>
                  <Divider my={2} style={{ backgroundColor: colors.grey[500] }} />
                  <Box display="flex" flexDirection="column" className="gap-10">
                    <ItemMenuShow
                      onClick={() => navigateMenu('messages')}
                      active={tab === 'messages'}
                      label={t('channel.tab.messages')}
                      mlLabel={7}
                    />
                    {channel.planValid && (
                      <ItemMenuShow
                        onClick={() => navigateMenu('groupsChats')}
                        active={tab === 'groupsChats'}
                        label={t('channel.tab.groupsChats')}
                        mlLabel={7}
                      />
                    )}
                    <ItemMenuShow
                      onClick={() => navigateMenu('announcements')}
                      active={tab === 'announcements'}
                      label={t('channel.tab.announcements')}
                      mlLabel={7}
                    />
                    <ItemMenuShow
                      onClick={() => navigateMenu('automated')}
                      active={tab === 'automated'}
                      label={t('channel.tab.automated')}
                      mlLabel={7}
                    />
                    <ItemMenuShow
                      onClick={() => navigateMenu('messageSettings')}
                      active={tab === 'messageSettings'}
                      label={t('channel.tab.messageSettings')}
                      mlLabel={7}
                    />
                  </Box>
                </Box>
              }
            >
              <ItemMenu
                onClick={() => setPopperOpened({ communication: !popperOpened?.communication })}
                label={t('channel.tab.communication').toUpperCase()}
                iconMUI={<MIcon icon="chat" color="white" size={iconSize} />}
                divider={false}
                disabled={!channel?.planValid}
                active={tab === 'messages' || tab === 'announcements'}
                my={0}
                mt={1}
              />
            </ShowMenu>
          </>
        )}
        {mode === 'edit' && permissions.storeAccess && (
          <ItemMenu
            onClick={() => navigateMenu('store')}
            label={t('channel.tab.store').toUpperCase()}
            iconMUI={<MIcon icon="local_offer" color="white" size={iconSize} />}
            divider={false}
            disabled={!channel?.planValid}
            mt={1}
            active={tab === 'store'}
          />
        )}
      </Box>
    </div>
  );
};

const ItemMenu = ({
  label,
  color,
  icon,
  iconMUI,
  onClick,
  active,
  divider,
  disabled,
  my = 1,
  mlLabel,
  ...props
}) => {
  const classes = useStyles();

  return (
    <Box
      className={`${classes.itemMenu} ${active ? classes.itemMenuActive : ''}`}
      display="flex"
      onClick={disabled ? null : onClick}
      my={my}
      mx={3}
      {...props}
    >
      <Box mr={5}>
        <Tooltip
          style={{ zIndex: 1, opacity: disabled ? 0.2 : 1 }}
          title={disabled ? 'Please! Check your plan.' : label}
          placement="right-start"
        >
          <Box>{icon ? <FontIcon iconName={icon} color={color || 'white'} /> : iconMUI}</Box>
        </Tooltip>
      </Box>
    </Box>
  );
};

const ItemMenuShow = ({ label, color, icon, iconMUI, onClick, active, ...props }) => {
  const classes = useStyles();

  return (
    <Box
      className={`${classes.itemMenuShow} ${active ? classes.itemMenuActive : ''}`}
      display="flex"
      onClick={onClick}
      {...props}
    >
      <Box display="flex">
        <Typography style={{ fontSize: 16, color: 'white' }} noWrap>
          {label}
        </Typography>
      </Box>
    </Box>
  );
};

export default Menu;
