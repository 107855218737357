import React, { useState } from 'react';
import moment from 'moment';
import { Link, Paper, Tooltip } from '@material-ui/core';

import SFDataTable from 'core/ui/DataTable';
import Typography from 'core/ui/Typography';
import Checkbox from 'core/ui/Checkbox';
import { timeZoneName } from 'core/utils/formatters/date';
import AuditedChanges from './AuditedChanges';
import { AuditModal } from './AuditModal';

const userTimezone = timeZoneName();

const TableLogs = ({ loading, audits }) => {
  const [audit, setAudit] = useState(null);

  const [twentyFourHours, setTwentyFourHours] = useState(false);
  const [timezone, setTimezone] = useState(userTimezone);

  const formatDateTime = (momentObject) =>
    momentObject.tz(timezone).format(`DD MMM, yy • ${twentyFourHours ? 'HH:mm' : 'hh:mma'} z`);

  const columns = [
    {
      id: 'objectable',
      name: 'Object',
      selector: 'auditableId',
      sortable: true,
      maxWidth: '200px',
      cell: (row) => `${row.auditableType} ${row.auditableId}`,
    },
    {
      id: 'user',
      name: 'User',
      sortable: true,
      maxWidth: '200px',
      selector: (row) => row.user?.name ?? 'System',
      cell: (row) =>
        row.user ? (
          <Link
            href={`/channel/manage/${row.user.channelId}/members/${row.user.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {row.user.name}
          </Link>
        ) : (
          'System'
        ),
    },
    {
      id: 'changes',
      name: 'Changes',
      selector: 'auditedChanges',
      sortable: true,
      cell: (row) => (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div className="tw-cursor-pointer" onClick={() => setAudit(row)}>
          <AuditedChanges audit={row} />
        </div>
      ),
    },
    {
      id: 'createdAt',
      name: 'Date/Time',
      selector: 'createdAt',
      sortable: true,
      maxWidth: '200px',
      cell: (row) => (
        <Tooltip title={row.createdAt}>
          <span>{formatDateTime(moment(row.createdAt))}</span>
        </Tooltip>
      ),
    },
  ];

  return (
    <Paper>
      <div className="tw-flex tw-justify-end tw-gap-2 tw-p-2.5">
        <select
          value={timezone}
          onChange={(e) => setTimezone(e.target.value)}
          className="tw-rounded-md tw-border tw-border-solid tw-border-slate-300 tw-bg-white tw-px-3 tw-py-2 tw-text-sm tw-shadow-sm placeholder:tw-text-slate-400 invalid:tw-border-pink-500 invalid:tw-text-pink-600 focus:tw-border-sky-500 focus:tw-outline-none focus:tw-ring-1 focus:tw-ring-sky-500 focus:invalid:tw-border-pink-500 focus:invalid:tw-ring-pink-500 disabled:tw-border-slate-200 disabled:tw-bg-slate-50 disabled:tw-text-slate-500 disabled:tw-shadow-none"
        >
          <option value="UTC">UTC</option>
          <option value={userTimezone}>{userTimezone}</option>
        </select>

        <Checkbox
          label="24H"
          checked={twentyFourHours}
          onClick={() => setTwentyFourHours((oldTfh) => !oldTfh)}
        />
      </div>

      <SFDataTable
        noDataComponent={loading ? null : <Typography m={5}>No Logs</Typography>}
        columns={columns}
        data={audits}
        noHeader
        exportData={audits.map((row) => ({
          object: `${row.auditableType}-${row.auditableId}`,
          user: row.user?.name ?? 'System',
          auditedChanges: mountLogText(row),
          datetime: formatDateTime(moment(row.createdAt)),
        }))}
        showExport
        pagination
        showPaper={false}
      />

      <AuditModal audit={audit} onClose={() => setAudit(null)} />
    </Paper>
  );
};

const removeColumns = ['tokens', 'uid'];
const mountLogText = (log) => {
  let text = log?.action.toUpperCase();
  text += '\r';

  if (log.action === 'create' || log.action === 'destroy') {
    Object.keys(log?.auditedChanges)
      .filter((x) => !!log?.auditedChanges[x])
      .filter((x) => !removeColumns.find((y) => y === x))
      .forEach((itemColumn) => {
        const item = log?.auditedChanges[itemColumn];

        text += '\r';
        text += `Column: ${itemColumn?.toUpperCase()} `;
        text += `${JSON.stringify(item)}`;
        text += '\r';
      });
  }

  if (log.action === 'update') {
    Object.keys(log?.auditedChanges)
      .filter((x) => !removeColumns.find((y) => y === x))
      .forEach((itemColumn) => {
        const item = log?.auditedChanges[itemColumn];

        text += '\r';
        text += `Column: ${itemColumn?.toUpperCase()} `;
        text += `${JSON.stringify(item[0]) || 'NULL'}`;
        text += ' | ';
        text += `${JSON.stringify(item[1]) || 'NULL'}`;
        text += '\r';
      });
  }

  return text;
};

export default TableLogs;
