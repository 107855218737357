import React, { useEffect, useState } from 'react';
import Box from 'core/ui/Box';
import Paper from 'core/ui/Paper';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import useSelectors from 'modules/map/useSelectors';
import { channelAnnouncementsModule } from 'modules/channelAnnouncements';
import Typography from 'core/ui/Typography';
import { KeyboardArrowDown, KeyboardArrowRight } from '@material-ui/icons';
import { Collapse } from '@material-ui/core';
import { secondary, secondaryLight, secondaryXLight } from 'core/ui/Colors';
import { useTranslation } from 'react-i18next';
import LabelBreakLine from 'core/ui/LabelBreakLine';
import Divider from 'core/ui/Divider';

const ValidAnnouncements = ({ channelId, mt }) => {
  const { request } = useActions();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const data = useSelectors(channelAnnouncementsModule, 'data');

  const styleIcon = {
    color: secondary,
    fontSize: 30,
  };

  useEffect(() => {
    if (channelId) {
      request({
        action: Modules.channelAnnouncements.actions.getValidAnnouncements,
        data: { channelId },
      });
    }
  }, [request, channelId]);

  const boxAnnouncement = {
    borderLeft: `2px solid ${secondaryLight}`,
    paddingLeft: 8,
    marginBottom: 3,
  };

  const total = data && data.length;

  if (total <= 0) {
    return <></>;
  }

  return (
    <Box mt={mt}>
      <Paper elevation={2} className="paper-rounded" p={3}>
        <Box display="flex" className="link" alignItems="center" onClick={() => setOpen(!open)}>
          <Box flexGrow="1">
            <Typography ml={2} color="secondary" variant="h5">
              {t('channel.announcements').toUpperCase()}
            </Typography>
          </Box>

          <Box>
            {open === true ? (
              <KeyboardArrowDown style={{ ...styleIcon }} />
            ) : (
              <KeyboardArrowRight style={{ ...styleIcon }} />
            )}
          </Box>
        </Box>

        <Collapse in={open}>
          <Box px={3} pt={2}>
            {data &&
              data.map((announcement, index) => (
                <Box key={index.toString()}>
                  <Box>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: announcement.text,
                      }}
                    />
                  </Box>
                  {total - 1 > index && (
                    <Divider style={{ backgroundColor: secondaryXLight }} mx={5} mb={2} mt={2} />
                  )}
                </Box>
              ))}
          </Box>
        </Collapse>
      </Paper>
    </Box>
  );
};

export default ValidAnnouncements;

ValidAnnouncements.propTypes = {};

ValidAnnouncements.defaultProps = {};
