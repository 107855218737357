import { all, call, put } from 'redux-saga/effects';

import { getErrorMessage } from 'core/api/api-error';

import notifications from 'modules/notifications';

import * as api from './api';
import { favoritesActions as actions } from './index';

export function* getFavorites({ pagination }) {
  try {
    const response = yield call(api.getFavorites, { pagination });
    const favorites = response.data;
    yield put(actions.getFavoritesSuccess(favorites, response.pagination));
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.getFavoritesError(getErrorMessage(error))),
    ]);
  }
}

export function* getFavorite({ klass, klassId }) {
  try {
    const response = yield call(api.getFavorite, { klass, klassId });
    const favorite = response.data;
    yield put(actions.getFavoriteSuccess(favorite));
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.getFavoriteError(getErrorMessage(error))),
    ]);
  }
}

export function* deleteFavorite({ data }) {
  try {
    yield call(api.deleteFavorite, data);
    yield put(actions.deleteFavoriteSuccess());
    if (data.messageSuccess) {
      yield put(notifications.success(data.messageSuccess));
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.deleteFavoriteError(getErrorMessage(error))),
    ]);
  }
}

export function* postFavorite({ data }) {
  try {
    const response = yield call(api.postFavorite, data);
    const favorite = response.data;
    yield put(actions.postFavoriteSuccess(favorite));
    if (data.messageSuccess) {
      yield put(notifications.success(data.messageSuccess));
    }
    if (data.callback) {
      data.callback();
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.postFavoriteError(getErrorMessage(error))),
    ]);
  }
}
