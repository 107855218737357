import React, { useState, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography, { fontStyle } from 'core/ui/Typography';
import { Box, Popper, ClickAwayListener } from '@material-ui/core';
import { useHistory } from 'react-router';
import { time } from 'core/utils/formatters';
import { useTranslation } from 'react-i18next';
import { primary, redColor, secondary } from 'core/ui/Colors';
import { Check, ExpandMore } from '@material-ui/icons';
import Paper from 'core/ui/Paper';
import { dateExt, diffFromNow, timeWithoutTz } from 'core/utils/formatters/date';
import { ListContext } from 'components/Channel/Manage/Tabs/Lists/Workouts/ModalSearch';
import { ButtonPrimarySm } from 'core/ui/Button';
import AvatarCoach from 'components/Profile/AvatarCoach';
import AvatarCoaches from 'components/Profile/AvatarCoaches';
import FontIcon from 'core/ui/FontIcon';
import LiveIcon from 'core/ui/LiveIcon';
import { abbrevName } from 'core/utils/helpers';
import ClassDetail from './Details/ClassDetail';
import Header from '../Show/Header';
import TimerToStart from './TimerToStart';

const CardClass = ({ workout, clickable, buttonColor }) => {
  const useStyles = makeStyles({
    difficulty: {
      fontFamily: 'Roboto',
      fontSize: 14,
    },
    dayFont: {
      fontFamily: 'Roboto',
      fontWeight: 700,
      fontSize: 12,
    },
    timeFont: {
      fontFamily: 'Roboto',
      fontSize: 12,
    },
    boxTag: {
      background: buttonColor || primary,
      position: 'absolute',
      bottom: 0,
      left: 0,
      zIndex: 1,
    },
    boxStreaming: {
      background: buttonColor || primary,
      width: 70,
      borderRadius: 3,
      zIndex: 1,
    },
    boxTagRight: {
      background: buttonColor || primary,
      position: 'absolute',
      bottom: 0,
      right: 0,
      zIndex: 1,
    },
    boxContent: {
      backgroundColor: 'white',
      color: secondary,
      borderBottomLeftRadius: '5px',
      borderBottomRightRadius: '5px',
      padding: 5,
      marginTop: -1,
      zIndex: 2,
      minHeight: 80,
    },
    detailBoxButton: {
      backgroundColor: 'white',
      borderBottomRightRadius: 5,
      borderBottomLeftRadius: 5,
      marginTop: -3,
    },
    listAdded: {
      background: '#1ed760',
      position: 'absolute',
      borderTopRightRadius: '5px',
      borderBottomLeftRadius: '5px',
      top: 0,
      padding: 3,
      right: 0,
      zIndex: 1,
    },
    boxRating: {
      background: buttonColor,
      position: 'absolute',
      borderBottomRightRadius: '5px',
      borderBottomLeftRadius: '5px',
      top: 0,
      paddingLeft: 3,
      paddingRight: 3,
      right: 10,
      zIndex: 1,
    },
  });
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const [openDetail, setOpenDetail] = useState(false);
  const refDetail = useRef();

  const listContext = useContext(ListContext);

  const existsInList = () => {
    if (!listContext) {
      return false;
    }

    return !!listContext.list.workouts.find((x) => x.id === workout.id);
  };

  const openDetails = () => {
    setOpenDetail(!openDetail);
  };

  const workoutDatetime = workout?.method !== 'live' ? workout?.publishAt : workout?.scheduledAt;
  const timeToStart = workout ? diffFromNow(workoutDatetime) : 0;

  return (
    <Box style={{ minHeight: 220 }}>
      <div className={openDetail ? 'item-sel item-shadow' : 'item item-shadow'}>
        <Box
          display="flex"
          onClick={() => (clickable ? history.push(`/workout/view/${workout.slug}`) : null)}
          flexDirection="column"
        >
          <Box style={{ position: 'relative' }}>
            <Header workout={workout} />
            {workout.rating && (
              <Box
                className={classes.boxRating}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                pt={1}
                pb={0.5}
              >
                <FontIcon iconName="icon-star" color="white" size={9} />

                <Typography style={{ ...fontStyle.small, color: 'white' }}>
                  {Number(workout.rating.avg).toFixed(1)}
                </Typography>
              </Box>
            )}
            {existsInList() && (
              <Box className={classes.listAdded}>
                <Check style={{ fontSize: 20, color: 'white' }} />
              </Box>
            )}

            {!workout.isCompetition &&
              !workout.recorded &&
              workout.online &&
              workout.method === 'live' && (
                <Box className={classes.boxTag}>
                  <Typography mx={1} style={{ color: 'white', ...fontStyle.small }}>
                    {t('online').toUpperCase()}
                  </Typography>
                </Box>
              )}

            {!workout.isCompetition &&
              !workout.recorded &&
              workout.inPerson &&
              workout.method === 'live' && (
                <Box className={classes.boxTagRight}>
                  <Typography mx={1} style={{ color: 'white', ...fontStyle.small }}>
                    {t('in-person').toUpperCase()}
                  </Typography>
                </Box>
              )}

            {!workout.isCompetition && workout.hasRecordings && workout.recorded && (
              <Box className={classes.boxTag}>
                <Typography mx={1} style={{ color: 'white', ...fontStyle.small }}>
                  {t('on-demand').toUpperCase()}
                </Typography>
              </Box>
            )}

            {!workout.isCompetition &&
              !workout.recorded &&
              workout.hasRecordings &&
              workout.method === 'video' && (
                <Box className={classes.boxTag}>
                  <Typography mx={1} style={{ color: 'white', ...fontStyle.small }}>
                    {t('on-demand').toUpperCase()}
                  </Typography>
                </Box>
              )}
          </Box>
          <Box className={classes.boxContent}>
            <Box display="flex">
              {workout?.coaches?.length > 0 && (
                <>
                  {workout.coaches && workout.coaches.length > 1 ? (
                    <AvatarCoaches coaches={workout.coaches} buttonColor={buttonColor} />
                  ) : (
                    <AvatarCoach user={workout.coaches[0]} buttonColor={buttonColor} />
                  )}
                </>
              )}
              <Box flexGrow={1} pl={2} pt={1}>
                <Box display="flex" alignItems="center" style={{ position: 'relative' }}>
                  <Box
                    flexGrow={1}
                    style={{ maxWidth: 160, overflow: 'hidden', textOverflow: 'ellipsis' }}
                  >
                    <Typography
                      noWrap
                      variant="body2"
                      style={{ textOverflow: 'ellipsis' }}
                      component="h5"
                    >
                      {abbrevName(
                        workout.name || (workout.firstType && workout.firstType.name) || '',
                        25,
                      )}
                    </Typography>
                  </Box>
                  <Box style={{ position: 'absolute', right: 0, top: -3 }}>
                    {workout.live && workout.online && workout.started && <LiveIcon />}
                  </Box>
                </Box>

                <Box display="flex" flexDirection="row" flexGrow="1">
                  {timeToStart > 0 && timeToStart <= 3600 ? (
                    <TimerToStart workout={workout} />
                  ) : (
                    <>
                      <Typography className={classes.dayFont} variant="caption" component="span">
                        {dateExt(workoutDatetime, t, workout?.channel?.dateFormatExtReact)} &bull;
                      </Typography>
                      <Typography
                        className={classes.timeFont}
                        variant="caption"
                        component="span"
                        ml={1}
                      >
                        {timeWithoutTz(workoutDatetime)}
                      </Typography>

                      {!!workout.endA && !workout.online && (
                        <Typography className={classes.timeFont} variant="caption" component="span">
                          &nbsp;&bull;&nbsp;{time(workout.endAt)}
                        </Typography>
                      )}
                    </>
                  )}

                  {workout?.canceled && (
                    <Typography ml={1} variant="caption" style={{ color: redColor }}>
                      {t('workout.canceled')}
                    </Typography>
                  )}
                </Box>

                {Number(workout?.durationMinutes) > 0 && (
                  <Typography style={fontStyle.medium} className="roboto-regular">
                    {Math.round(workout.durationMinutes)}&nbsp;{t('workout.duration.mins')}
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
        </Box>

        <Box className={classes.detailBoxButton}>
          {clickable && (
            <Box className="showDetails" ref={refDetail}>
              <Box
                display="flex"
                onClick={() => openDetails()}
                justifyContent="center"
                alignItems="center"
              >
                <ExpandMore style={{ fontSize: 15, color: buttonColor }} />
                <Typography style={{ ...fontStyle.xSmall, color: buttonColor }}>
                  {t('button.classDetails')}
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
        <Popper
          open={openDetail}
          anchorEl={refDetail.current}
          placement="top-start"
          role={undefined}
          transition
          style={{ zIndex: 9999, width: 320 }}
        >
          <ClickAwayListener onClickAway={() => setOpenDetail(false)}>
            <Paper
              elevation={4}
              p={5}
              className="paper-rounded"
              style={{ backgroundColor: 'white' }}
            >
              <ClassDetail
                setOpenDetail={setOpenDetail}
                workoutId={workout.id}
                buttonColor={buttonColor}
              />
            </Paper>
          </ClickAwayListener>
        </Popper>
      </div>
      {listContext && !existsInList() && (
        <Box>
          <Box mt={2} display="flex" justifyContent="center">
            <ButtonPrimarySm>{t('button.addInList')}</ButtonPrimarySm>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default CardClass;

CardClass.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  workout: PropTypes.object,
  clickable: PropTypes.bool,
};

CardClass.defaultProps = {
  workout: {},
  clickable: true,
};
