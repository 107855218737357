/* eslint-disable react/prop-types */
import React from 'react';

import CheckIcon from '@material-ui/icons/Check';
import { secondary, successColor } from 'core/ui/Colors';
import CloseIcon from '@material-ui/icons/Close';
import Typography from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import { replaceAll } from 'core/utils/helpers';

const Renewable = ({ plan }) => {
  const { t } = useTranslation();
  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      {plan.renewable ? (
        <>
          <CheckIcon style={{ fontSize: 18, color: successColor }} />

          <Typography
            style={{ color: successColor }}
            align="center"
            variant="caption"
            component="h5"
          >
            {plan.customIncrement === 'limit0' ? (
              <>{t('channel.membership.desc.limit0')}</>
            ) : (
              <>
                {replaceAll(
                  t('channel.memberships.info.renewable'),
                  '#{increment}',
                  `${plan.customIncrementNumber} ${plan.customIncrement}`,
                )}
                {plan.customIncrementNumber > 1 ? 's' : ''}
              </>
            )}
          </Typography>
        </>
      ) : (
        <>
          <CloseIcon style={{ fontSize: 18, color: secondary }} />
          <Typography style={{ color: secondary }} align="center" variant="caption" component="h5">
            {t('channel.memberships.non-renewable')}
          </Typography>
        </>
      )}
    </Box>
  );
};

export default Renewable;
