import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from 'core/ui/Box';
import Texture from 'core/ui/Texture';

import Form from './Form';

const ModalFormUnavailableDate = ({ open, close, channelId, callback }) => (
  <Dialog open={open} onClose={close} maxWidth="xs" fullWidth scroll="body">
    <Texture>
      <Box p={5}>
        <Form callback={callback} channelId={channelId} />
      </Box>
    </Texture>
  </Dialog>
);

ModalFormUnavailableDate.defaultProps = {
  close: () => {},
  userId: null,
};

export default ModalFormUnavailableDate;
