import React from 'react';
import Typography, { fontStyle } from 'core/ui/Typography';
import { time } from 'core/utils/formatters';
import { useTranslation } from 'react-i18next';
import { timeWithoutTz, dateAdd, gymDate } from 'core/utils/formatters/date';
import AvatarCoach from 'components/Profile/AvatarCoach';
import AvatarCoaches from 'components/Profile/AvatarCoaches';
import LiveIcon from 'core/ui/LiveIcon';
import Box from 'core/ui/Box';
import Paper from 'core/ui/Paper';
import { ButtonPrimarySm } from 'core/ui/Button';
import { abbrevName } from 'core/utils/helpers';
import { redColor } from 'core/ui/Colors';

const PrivateItem = ({ workout, openModalRegistration, onViewClick, loading, declineInvite }) => {
  const { t } = useTranslation();
  const workoutDate = workout.method !== 'live' ? workout.publishAt : workout.scheduledAt;

  return (
    <Box
      style={{
        height: 100,
        position: 'relative',
      }}
    >
      <Paper elevation={1} p={3} style={{ border: '0.4px solid #e9e9e9' }}>
        <Box display="flex" flexDirection="column">
          <Box>
            <Box display="flex">
              {workout?.coaches?.length > 0 && (
                <>
                  {workout.coaches && workout.coaches.length > 1 ? (
                    <AvatarCoaches coaches={workout.coaches} />
                  ) : (
                    <AvatarCoach user={workout.coaches[0]} />
                  )}
                </>
              )}
              <Box flexGrow={1} pl={2} pt={1}>
                <Box display="flex">
                  <Box flexGrow={1} style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {!!workoutDate && (
                      <Box display="flex">
                        <Typography style={{ ...fontStyle.xSmall, color: 'gray' }}>
                          {gymDate(workoutDate)}&nbsp;-&nbsp;
                        </Typography>
                        <Typography style={{ ...fontStyle.xSmall, color: 'gray' }}>
                          {timeWithoutTz(workoutDate)}&nbsp;-&nbsp;
                        </Typography>
                        <Typography style={{ ...fontStyle.xSmall, color: 'gray' }}>
                          {time(dateAdd(workoutDate, workout.durationMinutes, 'minutes'))}
                        </Typography>
                      </Box>
                    )}
                    <Typography
                      noWrap
                      variant="body2"
                      style={{ textOverflow: 'ellipsis' }}
                      component="h5"
                    >
                      {abbrevName(
                        workout.name || (workout.firstType && workout.firstType.name) || '',
                        20,
                      )}
                    </Typography>
                  </Box>
                  <Box style={{ position: 'absolute', right: 0, top: -3 }}>
                    {workout.live && workout.online && workout.started && <LiveIcon />}
                  </Box>
                  <Box mt={10} display="flex">
                    <ButtonPrimarySm disabled={loading} onClick={() => onViewClick()}>
                      <Typography style={fontStyle.medium}>{t('button.view')}</Typography>
                    </ButtonPrimarySm>
                    <ButtonPrimarySm
                      disabled={loading}
                      style={{ marginLeft: 2 }}
                      onClick={() => openModalRegistration(workout)}
                    >
                      <Typography style={fontStyle.medium}> {t('button.accept')}</Typography>
                    </ButtonPrimarySm>
                    <ButtonPrimarySm
                      disabled={loading}
                      style={{ marginLeft: 2 }}
                      buttonColor={redColor}
                      onClick={() => declineInvite(workout.id)}
                    >
                      <Typography style={fontStyle.medium}> {t('button.decline')}</Typography>
                    </ButtonPrimarySm>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default PrivateItem;

PrivateItem.propTypes = {};

PrivateItem.defaultProps = {};
