import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import DatePickerRounded from 'core/ui/DatePickers/DatePickerRounded';
import { ButtonPrimary } from 'core/ui/Button';
import { GetApp, Search } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { channelCurrency, formatDateToApi } from 'core/utils/formatters';
import Grid from 'core/ui/Grid';
import Card from 'core/ui/Card';
import Typography from 'core/ui/Typography';
import { primary } from 'core/ui/Colors';
import { channelsSelectors } from 'modules/channels';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import { channelReportsModule } from 'modules/channelReports';
import useSelectors from 'modules/map/useSelectors';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import { storeCategoriesModule } from 'modules/storeCategories';
import { formatDate, timeZoneName } from 'core/utils/formatters/date';
import Toggle from 'core/ui/Toggle';
import ChoosenChannelTags from 'core/ui/ChoosenChannelTags';
import Paper from 'core/ui/Paper';
import SkeletonPayamentsReport from './Skeleton';
import ResultOrders from './ResultOrders';
import TotalByCategory from './TotalByCategory';

const StoreOrders = ({ loading, getReport }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const channel = useSelector((state) => channelsSelectors.getChannel(state));
  const { request } = useActions();

  const categories = useSelectors(storeCategoriesModule, 'data');
  const data = useSelectors(channelReportsModule, 'data');
  const dateNow = new Date();
  const month = dateNow.getMonth() + 1;

  const [filters, setFilters] = useState({
    startAt: `${month > 12 ? 12 : month}/1/${dateNow.getFullYear()}`,
    endAt: formatDate(new Date(), 'MM/DD/YYYY'),
    workoutId: '',
    paymentStatus: null,
    dateFilter: 'created_at',
    membersTags: [],
  });

  useEffect(() => {
    if (channel?.id) {
      request({
        action: Modules.storeCategories.actions.getStoreCategories,
        data: {
          ownerableType: 'Channel',
          ownerableId: channel?.id,
          showInactive: 0,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, channel]);

  const search = ({ exportReport = false } = {}) => {
    const startAt = filters.startAt ? formatDateToApi(filters.startAt, timeZoneName()) : null;
    const endAt = filters.endAt ? formatDateToApi(filters.endAt, timeZoneName()) : null;
    if (startAt && endAt)
      getReport({
        ...filters,
        startAt,
        endAt,
        categoryId: filters.categoryId || null,
        paymentStatus: filters?.paymentStatus,
        dateFilter: filters?.dateFilter,
        exportReport,
      });
  };

  useEffect(() => {
    search();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react/prop-types
  const CardSummary = ({ title, total, size }) => (
    <Grid item xs={6} sm={6} md={size} lg={size}>
      <Card btopcolor={primary} btopwidth={2} hidedivider="true" title={title}>
        <Box p={3} display="flex" flexDirection="column" alignItems="center">
          <Typography variant="h2" color="primary">
            {channelCurrency(total, channel?.currency)}
          </Typography>
        </Box>
      </Card>
    </Grid>
  );

  return (
    <Box mt={2}>
      <Box>
        <Paper p={3} my={3}>
          <Box
            display="flex"
            mb={3}
            alignItems="flex-end"
            justifyContent="flex-start"
            flexWrap="wrap"
          >
            <Box>
              <Toggle
                onChange={(v) => setFilters((prev) => ({ ...prev, dateFilter: v }))}
                options={[
                  { label: 'Created Date', value: 'created_at', width: 120 },
                  { label: 'Processed Date', value: 'confirmed_at', width: 120 },
                ]}
                value={filters?.dateFilter}
                color="white"
              />
            </Box>
            <Box ml={1}>
              <DatePickerRounded
                label={t('channel.reports.filter.start').toUpperCase()}
                onChange={(v) => setFilters((prev) => ({ ...prev, startAt: v }))}
                value={filters.startAt}
                disablePast={false}
                usFormat={channel?.useUsDateFormat}
                autoOk
                bordered
              />
            </Box>
            <Box ml={1}>
              <DatePickerRounded
                label={t('channel.reports.filter.end').toUpperCase()}
                onChange={(v) => setFilters((prev) => ({ ...prev, endAt: v }))}
                value={filters.endAt}
                disablePast={false}
                usFormat={channel?.useUsDateFormat}
                autoOk
                bordered
              />
            </Box>
            <Box ml={1}>
              <SelectBoxObject
                value={filters.categoryId || 0}
                options={categories.filter((x) => !!x.active)}
                propLabel="name"
                propValue="id"
                emptyItem
                emptyValue={0}
                setValue={(v) => setFilters((prev) => ({ ...prev, categoryId: v }))}
                placeholder={t('form.product.placeholder.category')}
                label={t('form.product.label.category').toUpperCase()}
                bordered
              />
            </Box>
            <Box ml={2}>
              <Toggle
                onChange={(v) => setFilters((prev) => ({ ...prev, paymentStatus: v }))}
                options={[
                  { label: 'All', value: null },
                  { label: t('payment.stripe.status.succeeded'), value: 'succeeded' },
                  { label: t('payment.stripe.status.refunded'), value: 'refunded' },
                  { label: t('payment.stripe.status.failed'), value: 'failed' },
                ]}
                value={filters?.paymentStatus}
                color="white"
                bordered
              />
            </Box>
            <Box ml={3} pt={5}>
              <ButtonPrimary disabled={loading} onClick={() => search()}>
                <Search style={{ color: 'white' }} fontSize="small" />
                {t('button.search')}&nbsp;
              </ButtonPrimary>
            </Box>
            <Box ml={3} pt={5}>
              <ButtonPrimary
                disabled={loading}
                onClick={() => search({ page: 1, exportReport: true })}
              >
                <GetApp style={{ color: 'white' }} fontSize="small" />
                {t('button.exportCsv')}&nbsp;
              </ButtonPrimary>
            </Box>
          </Box>

          <Box mt={2}>
            <Grid container spacing={5}>
              <Grid item xs={12} md={4}>
                <ChoosenChannelTags
                  label={t('channel.label.memberTags')}
                  placeholder={t('channel.tags')}
                  val={filters?.membersTags ? [...filters?.membersTags] : []}
                  setValue={(v) =>
                    setFilters((prev) => ({ ...prev, membersTags: v?.map((x) => x?.name || x) }))
                  }
                  channelId={channel?.id}
                  bordered
                />
              </Grid>
            </Grid>
          </Box>
        </Paper>

        {!data.orders || loading ? (
          <SkeletonPayamentsReport />
        ) : (
          <Box>
            <Grid container spacing={3} my={3}>
              <CardSummary size={3} total={data.total} title={t('channel.reports.summary.total')} />
            </Grid>
            <ResultOrders orders={data ? data.orders : []} channel={channel} />

            <Box m={2}>
              <TotalByCategory channel={channel} categories={data?.totalByCategory} />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

StoreOrders.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  loading: PropTypes.bool,
  getReport: PropTypes.func,
};

StoreOrders.defaultProps = {
  loading: false,
  getReport: () => {},
};

export default StoreOrders;
