import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

import * as selectors from './selectors';
import * as sagas from './sagas';

/* ---------- Action Creators and Types ---------- */
const { Types, Creators } = createActions(
  {
    getSearchError: ['error'],
    getSearchStart: ['data'],
    getSearchSuccess: ['data', 'pagination'],

    getUpcomingError: ['error'],
    getUpcomingStart: ['data'],
    getUpcomingSuccess: ['upcoming', 'pagination'],

    getCompetingError: ['error'],
    getCompetingStart: ['data'],
    getCompetingSuccess: ['competing', 'pagination'],

    getCurrentError: ['error'],
    getCurrentStart: ['data'],
    getCurrentSuccess: ['current', 'pagination'],

    getRecentsError: ['error'],
    getRecentsStart: ['data'],
    getRecentsSuccess: ['recents', 'pagination'],

    setQueryData: ['data'],
    setFilters: ['data'],

    clearResults: [],
    clearViewMoreResults: [],
  },
  { prefix: 'competitionsSearch/' },
);

/* ---------- Initial State ---------- */
const INITIAL_STATE = Immutable({
  data: [],
  queryData: [],
  pagination: {},

  loadingRecents: false,
  recents: [],
  paginationRecents: {},

  loadingUpcoming: false,
  upcoming: [],
  paginationUpcoming: {},

  loadingCurrent: false,

  current: [],
  paginationCurrent: {},

  filters: [],
  error: null,
  loading: false,

  loadingViewMore: false,
  viewMoreData: [],
  viewMorePagination: {},
});

/* ---------- Reducers ---------- */
const loadStartReducer = (state) => state.set('loading', true);
const loadRecentsStartReducer = (state) => state.set('loadingRecents', true);
const loadUpcomingStartReducer = (state) => state.set('loadingUpcoming', true);
const loadCurrentStartReducer = (state) => state.set('loadingCurrent', true);

const getSearchSuccessReducer = (state, action) =>
  state.merge({
    data: action.data,
    pagination: action.pagination,
    loading: false,
    error: null,
  });

const loadErrorReducer = (state, action) =>
  state.merge({
    loading: false,
    loadingRecents: false,
    loadingUpcoming: false,
    error: action.error,
  });

const clearViewMoreResultsReducer = (state) =>
  state.merge({
    viewMoreData: [],
    viewMorePagination: {},
  });

const setFiltersReducer = (state, action) =>
  state.merge({
    filters: action.data,
  });

const setQueryDataReducer = (state, action) =>
  state.merge({
    queryData: action.data,
  });

const getRecentsSuccessReducer = (state, action) =>
  state.merge({
    loadingRecents: false,
    recents: action.recents,
    paginationRecents: action.pagination,
  });

const getCurrentSuccessReducer = (state, action) =>
  state.merge({
    loadingCurrent: false,
    current: action.current,
    paginationCurrent: action.pagination,
  });

const getUpcomingSuccessReducer = (state, action) =>
  state.merge({
    loadingUpcoming: false,
    upcoming: action.upcoming,
    paginationUpcoming: action.pagination,
  });

const clearResultsReducer = (state) =>
  state.merge({
    data: [],
  });

/* ---------- Main Reducer ---------- */
const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_SEARCH_START]: loadStartReducer,
  [Types.GET_SEARCH_SUCCESS]: getSearchSuccessReducer,
  [Types.GET_SEARCH_ERROR]: loadErrorReducer,

  [Types.GET_UPCOMING_START]: loadStartReducer,
  [Types.GET_UPCOMING_SUCCESS]: getSearchSuccessReducer,
  [Types.GET_UPCOMING_ERROR]: loadErrorReducer,

  [Types.GET_RECENTS_START]: loadRecentsStartReducer,
  [Types.GET_RECENTS_SUCCESS]: getRecentsSuccessReducer,
  [Types.GET_RECENTS_ERROR]: loadErrorReducer,

  [Types.GET_CURRENT_START]: loadCurrentStartReducer,
  [Types.GET_CURRENT_SUCCESS]: getCurrentSuccessReducer,
  [Types.GET_CURRENT_ERROR]: loadErrorReducer,

  [Types.GET_UPCOMING_START]: loadUpcomingStartReducer,
  [Types.GET_UPCOMING_SUCCESS]: getUpcomingSuccessReducer,
  [Types.GET_UPCOMING_ERROR]: loadErrorReducer,

  [Types.SET_QUERY_DATA]: setQueryDataReducer,
  [Types.SET_FILTERS]: setFiltersReducer,

  [Types.CLEAR_RESULTS]: clearResultsReducer,
  [Types.CLEAR_VIEW_MORE_RESULTS]: clearViewMoreResultsReducer,
});

/* ---------- Exporting ---------- */
export const competitionsSearchActions = Creators;
export const competitionsSearchTypes = Types;
export const competitionsSearchSelectors = selectors;
export const competitionsSearchSagas = sagas;

export default reducer;
