import React, { useState } from 'react';
import Box from 'core/ui/Box';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import SelectBox from 'core/ui/SelectBox';
import Paper from 'core/ui/Paper';
import Typography from 'core/ui/Typography';
import { channelCurrency } from 'core/utils/formatters';
import ReactSimpleImageViewer from 'react-simple-image-viewer';
import { useTranslation } from 'react-i18next';

export default function RegistrationCustomFields({
  competition,
  customFields,
  setCustomFields,
  hidePrice,
}) {
  const { t } = useTranslation();
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const change = (field, value) => {
    const currentValues = customFields ? [...customFields] : [];
    if (!currentValues?.find((x) => x.field === field)) {
      setCustomFields([...currentValues, { field, value }]);
      return;
    }
    const fields = [...currentValues].map((x) => {
      const newX = { ...x };
      if (newX?.field === field) {
        newX.value = value;
      }
      return newX;
    });
    setCustomFields(fields);
  };

  return (
    <>
      {isViewerOpen?.open && (
        <ReactSimpleImageViewer
          src={[isViewerOpen?.url]}
          currentIndex={isViewerOpen?.currentIndex}
          onClose={() => setIsViewerOpen({ open: false })}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: 'rgba(0,0,0,0.9)',
          }}
          closeOnClickOutside
        />
      )}
      <Paper mb={2} p={2}>
        <Typography>{t('label.fillCustomFields')}</Typography>
      </Paper>
      {competition?.customFields &&
        competition?.customFields.map((row) => (
          <Paper mb={2} key={row?.name} p={2}>
            <Box display="flex" className="gap-10" alignItems="center">
              {!!row?.image?.image?.url && (
                <Box>
                  <Box
                    onClick={() => setIsViewerOpen({ open: true, url: row?.image?.image?.url })}
                    className="hover"
                  >
                    <img src={row?.image?.image?.url} alt="Custom Field" style={{ width: 80 }} />
                  </Box>
                </Box>
              )}
              <Box flexGrow={1}>
                {row?.kind === 'dropdown' ? (
                  <SelectBox
                    options={row?.options}
                    label={row?.name}
                    value={
                      customFields ? customFields?.find((x) => x.field === row?.id)?.value : ''
                    }
                    placeholder={row?.name}
                    setValue={(v) => {
                      change(row?.id, v);
                    }}
                    bordered
                    emptyItem={!row?.required}
                    required={row?.required}
                  />
                ) : (
                  <TextFieldRounded
                    label={row?.name}
                    value={
                      customFields ? customFields?.find((x) => x.field === row?.id)?.value : ''
                    }
                    placeholder={row?.name}
                    onChange={(v) => {
                      change(row?.id, v);
                    }}
                    bordered
                    required={row?.required}
                  />
                )}
              </Box>
              {!hidePrice && row?.cost && (
                <Typography variant="h4">
                  {channelCurrency(row?.cost, competition?.currency)}
                </Typography>
              )}
            </Box>
          </Paper>
        ))}
    </>
  );
}

RegistrationCustomFields.defaultProps = {
  competition: {},
};
