import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
// import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import Typography from '../Typography';
import LabelBreakLine from '../LabelBreakLine';
import LoaderSm from '../LoaderSm';
import Box from '../Box';

export default function ConfirmDialog({
  open,
  setOpen,
  confirm,
  cancel,
  labelConfirm,
  labelCancel,
  title,
  description,
  styleBody,
  styleTitle,
  loading,
  children,
  hideNoButton,
  showConfirm2,
  labelConfirm2,
  confirm2,
}) {
  const handleConfirm = () => {
    if (confirm) {
      confirm();
    }
    if (setOpen) {
      setOpen(false);
    }
  };

  const handleConfirm2 = () => {
    if (confirm2) {
      confirm2();
    }
    if (setOpen) {
      setOpen(false);
    }
  };

  const handleCancel = () => {
    if (cancel) {
      cancel();
    }
    if (setOpen) {
      setOpen(false);
    }
  };

  const isString = (myVar) => myVar instanceof String;

  return (
    <div>
      <Dialog
        style={{ zIndex: 99999 }}
        open={open}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Typography align="center" p={3} variant="h6" style={styleTitle}>
          {title}
        </Typography>
        <DialogContent>
          <DialogContentText style={styleBody} id="alert-dialog-description">
            <Box>
              {isString(description) ? <LabelBreakLine>{description}</LabelBreakLine> : description}
            </Box>

            {children}
          </DialogContentText>
          <Box display="flex" justifyContent="center" alignItems="center" m={3}>
            <LoaderSm loading={loading} />
          </Box>
        </DialogContent>
        <DialogActions>
          {!hideNoButton && (
            <Button disabled={loading} onClick={handleCancel} color="primary">
              {labelCancel}
            </Button>
          )}

          <Button disabled={loading} onClick={handleConfirm} color="primary" autoFocus>
            {labelConfirm}
          </Button>
          {showConfirm2 && (
            <Button disabled={loading} onClick={handleConfirm2} color="primary" autoFocus>
              {labelConfirm2}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}

ConfirmDialog.propTypes = {
  open: PropTypes.bool,
  loading: PropTypes.bool,
  setOpen: PropTypes.func,
  confirm: PropTypes.func,
  cancel: PropTypes.func,
  labelConfirm: PropTypes.string,
  labelCancel: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  styleBody: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  styleTitle: PropTypes.object,
};

ConfirmDialog.defaultProps = {
  open: false,
  setOpen: null,
  confirm: null,
  cancel: null,
  labelConfirm: 'Confirm',
  labelCancel: 'Cancel',
  title: '',
  description: '',
  styleBody: {},
  styleTitle: {},
  loading: false,
};
