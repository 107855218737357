import React, { useContext, useEffect, useState } from 'react';
import Box from 'core/ui/Box';
import { Avatar, Badge, Dialog, DialogContent, Hidden } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import MIcon from 'core/ui/MIcon';
import { secondaryXLight } from 'core/ui/Colors';
import { date } from 'core/utils/formatters';
import useSelectors from 'modules/map/useSelectors';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import { replaceAll } from 'core/utils/helpers';
import { channelCalendarsModule } from 'modules/channelCalendars';
import Grid from 'core/ui/Grid';
import Typography from 'core/ui/Typography';
import Divider from 'core/ui/Divider';
import { useTranslation } from 'react-i18next';
import LoaderSm from 'core/ui/LoaderSm';
import InstagramLink from 'components/Profile/InstagramLink';
import { AppContext } from 'contexts';
import { useHistory } from 'react-router';
import DialogTitle from 'core/ui/Dialog/DialogTitle';
import useIsXs from 'core/utils/hook/useIsXs';
import ItemScheduleClass from './ItemScheduleClass';

const CompDatePikcer = ({
  getCalendarDots,
  t,
  buttonColor,
  hasDot,
  currentDate,
  setCurrentDate,
  getWorkoutsGroup,
}) => (
  <Box>
    <Typography mb={2} variant="h5" color="secondary">
      {t('channel.calendar.selectDate')}
    </Typography>
    <DatePicker
      autoOk
      onMonthChange={(params) => {
        getCalendarDots(date(params, 'YYYY-MM-DD'));
      }}
      fullWidth
      format="yyyy-MM-dd"
      variant="static"
      renderDay={(day, selectedDate, isInCurrentMonth, dayComponent) => {
        if (hasDot(day)) {
          return (
            <Badge badgeContent={<MIcon icon="fiber_manual_record" color={buttonColor} size={8} />}>
              {dayComponent}
            </Badge>
          );
        }
        return <Box className="day-inative">{dayComponent}</Box>;
      }}
      openTo="date"
      value={date(currentDate)}
      onChange={(params) => {
        if (hasDot(params)) {
          setCurrentDate(date(params, 'YYYY-MM-DD'));
          getWorkoutsGroup({ newDate: date(params, 'YYYY-MM-DD') });
        }
      }}
    />
  </Box>
);

const ModalCalendarSchedule = ({ open, close, channel }) => {
  const appContext = useContext(AppContext);
  const { t } = useTranslation();
  const [currentDate, setCurrentDate] = useState(new Date());
  const { request } = useActions();
  const dots = useSelectors(channelCalendarsModule, 'dots');
  const calendarData = useSelectors(channelCalendarsModule, 'calendarData');
  const loadingWorkouts = useSelectors(channelCalendarsModule, 'loadingWorkouts');
  const history = useHistory();

  const isXs = useIsXs();

  const getCalendarDots = (month) => {
    request({
      action: Modules.channelCalendars.actions.getCalendarDots,
      data: { channelId: channel?.id, startAt: month, onlyMembershipClasses: true },
    });
  };
  useEffect(() => {
    if (open && currentDate) {
      getCalendarDots(currentDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDate, open]);

  const getWorkoutsGroup = ({ next, newDate, recursive }) => {
    request({
      action: Modules.channelCalendars.actions.getCalendarGroupWorkouts,
      data: {
        channelId: channel?.id,
        startAt: newDate,
        endAt: newDate,
        onlyMembershipClasses: true,
        next: next === true ? true : null,
        recursive: recursive === true ? true : null,
      },
    });
  };

  useEffect(() => {
    if (channel && channel.id) {
      getWorkoutsGroup({ next: true, newDate: currentDate, recursive: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel, request]);

  const hasDot = (dateParam) => {
    const newDate = date(dateParam, 'YYYY-MM-DD');
    const dayKey = replaceAll(newDate, '-', '/');
    if (dots && dots[dayKey]) {
      return true;
    }
    return false;
  };

  const workoutRegister = (w) => {
    appContext.setModalPurchase({
      open: true,
      workoutId: w?.id,
      confirmCallback: w?.useHeats ? w : null,
    });
  };

  const buttonColor = channel?.customColors?.buttonColor;
  const border = { border: `0.8px solid ${secondaryXLight}` };

  return (
    <Dialog
      maxWidth="md"
      scroll={isXs ? 'paper' : 'body'}
      fullScreen={isXs}
      fullWidth
      onClose={() => close()}
      open={open}
    >
      <DialogTitle className="tw-bg-zinc-100" onClose={close}>
        <div className="tw-flex tw-flex-row tw-items-start tw-text-lg">
          {t('button.channelCalendar')}
        </div>
      </DialogTitle>
      <DialogContent className="tw-bg-gray-50">
        <Box>
          <Grid container spacing={3} style={border}>
            <Grid item xs={12} md={7}>
              <Box
                display="flex"
                alignItems="flex-start"
                className="hover"
                onClick={() => history.push(`/channel/view/${channel?.id}`)}
              >
                <Avatar
                  variant="rounded"
                  src={channel?.image?.image?.url}
                  style={{ height: 100, width: 100 }}
                />

                <Box pl={2}>
                  <Box display="flex">
                    <Box flexGrow="1">
                      <Typography color="secondary" variant="h4" component="h4">
                        {channel.name}
                      </Typography>
                      <InstagramLink instagram={channel.instagram} />
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Divider my={3} />

              <Hidden only={['xs']}>
                <>
                  <Box display="flex" alignItems="center">
                    <Typography mb={2} variant="h5" color="secondary">
                      {t('channel.calendar.availableTimes')}
                    </Typography>
                    <LoaderSm loading={loadingWorkouts} />
                  </Box>
                  {Object.keys(calendarData?.dayWorkouts || {}).map((group) => (
                    <Box key={group?.title}>
                      {calendarData?.dayWorkouts[group]?.data?.map((item) => (
                        <ItemScheduleClass
                          key={item?.id}
                          workoutRegister={workoutRegister}
                          workout={item}
                          datetime={item.datetime}
                          buttonColor={buttonColor}
                        />
                      ))}
                    </Box>
                  ))}
                </>
              </Hidden>
            </Grid>

            <Grid
              item
              xs={12}
              md={5}
              pl={1}
              className="modal-calendar"
              style={{ borderLeft: `0.8px solid ${secondaryXLight}` }}
            >
              <CompDatePikcer
                getCalendarDots={getCalendarDots}
                t={t}
                buttonColor={buttonColor}
                hasDot={hasDot}
                currentDate={currentDate}
                setCurrentDate={setCurrentDate}
                getWorkoutsGroup={getWorkoutsGroup}
              />
            </Grid>
          </Grid>

          <Hidden only={['sm', 'md', 'lg', 'xl']}>
            <Box mt={3}>
              <Box display="flex" alignItems="center">
                <Typography mb={2} variant="h5" color="secondary">
                  {t('channel.calendar.availableTimes')}
                </Typography>
                <LoaderSm loading={loadingWorkouts} />
              </Box>
              {Object.keys(calendarData?.dayWorkouts || {}).map((group) => (
                <Box key={group?.title}>
                  {calendarData?.dayWorkouts[group]?.data?.map((item) => (
                    <ItemScheduleClass
                      workoutRegister={workoutRegister}
                      workout={item}
                      key={item?.id}
                      datetime={item.datetime}
                      buttonColor={buttonColor}
                    />
                  ))}
                </Box>
              ))}
            </Box>
          </Hidden>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

ModalCalendarSchedule.defaultProps = {
  close: () => {},
  callback: () => {},
  open: false,
  mode: 'new',
};

export default ModalCalendarSchedule;
