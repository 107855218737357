/* eslint-disable react/prop-types */
import React from 'react';
import Paper from 'core/ui/Paper';
import Typography from 'core/ui/Typography';
import Grid from 'core/ui/Grid';
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { channelCurrency } from 'core/utils/formatters';
import { primary, secondary } from 'core/ui/Colors';
import { Info } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { Tooltip as MUITooltip } from '@material-ui/core';
import TypographyIcon from 'core/ui/TypographyIcon';

export default function ResultArm({ channel, data }) {
  const { t } = useTranslation();
  const colorsChart = {
    totalRevenue: '#D28704',
    totalMembers: '#269FFC',
    arm: primary,
  };

  const currentMonthData = data?.armMonths && data?.armMonths[(data?.armMonths?.length || 0) - 1];

  return (
    <Paper p={3} px={5}>
      <TypographyIcon
        variant="h5"
        mb={3}
        icon={
          <MUITooltip title={t('arm.currentMonth.msg')}>
            <Info style={{ fontSize: 20, color: secondary }} />
          </MUITooltip>
        }
        text="ARM"
      />
      <Grid container spacing={3} mb={3}>
        <Grid item xs={12} md={3}>
          <Paper p={3}>
            <Typography variant="h5" mb={3}>
              Current Month
            </Typography>
            <Typography variant="h1">{currentMonthData?.month}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={3}>
          <Paper p={3}>
            <Typography variant="h5" mb={3}>
              Total Members
            </Typography>
            <Typography variant="h1">{currentMonthData?.totalMembers || 0}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={3}>
          <Paper p={3}>
            <Typography variant="h5" mb={3}>
              Total
            </Typography>
            <Typography variant="h1">
              {channelCurrency(currentMonthData?.totalRevenue, channel?.currency)}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={3}>
          <Paper p={3}>
            <Typography variant="h5" mb={3}>
              ARM
            </Typography>
            <Typography variant="h1">
              {channelCurrency(currentMonthData?.arm, channel?.currency)}
            </Typography>
          </Paper>
        </Grid>
      </Grid>

      <ResponsiveContainer width="100%" height={300}>
        <ComposedChart
          data={data?.armMonths}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis dataKey="month" scale="band" />

          <YAxis
            id="left"
            yAxisId="left"
            tickFormatter={(value) => channelCurrency(value, channel?.currency)}
          />
          <YAxis
            orientation="right"
            id="right"
            yAxisId="right"
            posi
            tickFormatter={(value) => value}
          />
          <YAxis
            orientation="right"
            id="right-arm"
            yAxisId="right-arm"
            posi
            tickFormatter={(value) => channelCurrency(value, channel?.currency)}
          />

          <Tooltip
            formatter={(value, name) =>
              name !== 'totalMembers' ? channelCurrency(value, channel?.currency) : value
            }
          />
          <Legend />

          <Bar dataKey="totalRevenue" yAxisId="left" barSize={20} fill={colorsChart.totalRevenue} />

          <Line
            type="monotone"
            yAxisId="right"
            dataKey="totalMembers"
            strokeWidth={5}
            stroke={colorsChart.totalMembers}
          />
          <Line
            type="monotone"
            yAxisId="right-arm"
            dataKey="arm"
            strokeWidth={5}
            stroke={colorsChart.arm}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </Paper>
  );
}
