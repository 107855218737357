/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import { Dialog } from '@material-ui/core';
import LoaderSm from 'core/ui/LoaderSm';
import useIsXs from 'core/utils/hook/useIsXs';
import DialogTitle from 'core/ui/Dialog/DialogTitle';
import ViewerContent from './ViewerContent';

const ModalDocumentViewer = ({
  contractKey,
  open,
  callback,
  close,
  document,
  userId,
  buttonColor,
  loadingContract,
  loaded = true,
}) => {
  const isXs = useIsXs();
  return (
    <Dialog
      open={!!open}
      onClose={close}
      maxWidth="lg"
      fullWidth
      scroll={isXs ? 'paper' : 'body'}
      fullScreen={isXs}
    >
      <DialogTitle className="tw-bg-zinc-100" onClose={close}>
        <div className="tw-flex tw-flex-row tw-items-start tw-text-lg">{document?.name}</div>
      </DialogTitle>
      <LoaderSm center loading={loadingContract} />
      {loaded && document?.id && (
        <ViewerContent
          contractKey={contractKey}
          callback={callback}
          document={document}
          userId={userId}
          buttonColor={buttonColor}
          padding={5}
        />
      )}
    </Dialog>
  );
};

export default ModalDocumentViewer;
