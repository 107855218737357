import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

import * as selectors from './selectors';
import * as sagas from './sagas';

/* ---------- Action Creators and Types ---------- */
const { Types, Creators } = createActions(
  {
    postMessageListsError: ['error'],
    postMessageListsStart: ['data', 'callback'],
    postMessageListsSuccess: ['list'],

    putMessageListsError: ['error'],
    putMessageListsStart: ['data', 'callback'],
    putMessageListsSuccess: ['list'],

    deleteContactListError: ['error'],
    deleteContactListStart: ['messageListId', 'contactId', 'callback'],
    deleteContactListSuccess: [''],

    getMessageListsError: ['error'],
    getMessageListsStart: [],
    getMessageListsSuccess: ['lists', 'pagination'],

    getMessageListContactsError: ['error'],
    getMessageListContactsStart: ['listId', 'query', 'page', 'perPage'],
    getMessageListContactsSuccess: ['contacts', 'pagination'],
  },
  { prefix: 'messageLists/' },
);

/* ---------- Initial State ---------- */
const INITIAL_STATE = Immutable({
  data: [],
  contacts: [],
  paginationContacts: {},
  loadingContacts: false,
  error: null,
  loading: false,
});

/* ---------- Reducers ---------- */
const loadStartReducer = (state) => state.set('loading', true);
const loadContactsStartReducer = (state) => state.set('loadingContacts', true);

const postMessageListsSuccessReducer = (state, action) =>
  state.merge({
    MessageLists: action.MessageLists,
    loading: false,
    error: null,
  });

const getMessageListContactsSuccessReducer = (state, action) =>
  state.merge({
    contacts: action.contacts,
    paginationContacts: action.pagination,
    loadingContacts: false,
    error: null,
  });

const getMessageListsSuccessReducer = (state, action) =>
  state.merge({
    data: action.lists,
    loading: false,
    error: null,
  });

const loadErrorReducer = (state, action) =>
  state.merge({
    loading: false,
    error: action.error,
  });

const loadContactsErrorReducer = (state, action) =>
  state.merge({
    loadingContacts: false,
    error: action.error,
  });

const deleteContatListSuccessReducer = (state) =>
  state.merge({
    loading: false,
  });

/* ---------- Main Reducer ---------- */
const reducer = createReducer(INITIAL_STATE, {
  [Types.POST_MESSAGE_LISTS_START]: loadStartReducer,
  [Types.POST_MESSAGE_LISTS_SUCCESS]: postMessageListsSuccessReducer,
  [Types.POST_MESSAGE_LISTS_ERROR]: loadErrorReducer,

  [Types.PUT_MESSAGE_LISTS_START]: loadStartReducer,
  [Types.PUT_MESSAGE_LISTS_SUCCESS]: postMessageListsSuccessReducer,
  [Types.PUT_MESSAGE_LISTS_ERROR]: loadErrorReducer,

  [Types.GET_MESSAGE_LISTS_START]: loadStartReducer,
  [Types.GET_MESSAGE_LISTS_SUCCESS]: getMessageListsSuccessReducer,
  [Types.GET_MESSAGE_LISTS_ERROR]: loadErrorReducer,

  [Types.GET_MESSAGE_LIST_CONTACTS_START]: loadContactsStartReducer,
  [Types.GET_MESSAGE_LIST_CONTACTS_SUCCESS]: getMessageListContactsSuccessReducer,
  [Types.GET_MESSAGE_LIST_CONTACTS_ERROR]: loadContactsErrorReducer,

  [Types.DELETE_CONTACT_LIST_START]: loadStartReducer,
  [Types.DELETE_CONTACT_LIST_SUCCESS]: deleteContatListSuccessReducer,
  [Types.DELETE_CONTACT_LIST_ERROR]: loadErrorReducer,
});

/* ---------- Exporting ---------- */
export const messageListsActions = Creators;
export const messageListsTypes = Types;
export const messageListsSelectors = selectors;
export const messageListsSagas = sagas;

export default reducer;
