import React, { useMemo } from 'react';

import CKEditor from 'core/ui/CKEditor';
import { ContentState, convertFromHTML, convertToRaw } from 'draft-js';

export const RichEditor = ({ form, ownerableType, ownerableId, isChannel }) => {
  let keys = ['{first_name}'];

  if (isChannel) {
    keys = [
      ...keys,
      '{channel_name}',
      '{channel_view_link}',
      '{channel_schedule_link}',
      '{channel_plans_link}',
    ];
  }

  const text = form.watch('text');
  const textRaw = form.watch('textRaw');

  const defaultValue = useMemo(
    () => {
      if (textRaw) {
        if (typeof textRaw === 'string') return JSON.parse(textRaw);

        return textRaw;
      }

      const blocksFromHTML = convertFromHTML(text || '');

      return convertToRaw(
        ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap),
      );
    },
    // We only need this to run one time. With this in mind, the unique manner to change this is unmounting and mounting again.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <CKEditor
      error={form.errors && form.errors.text}
      onChange={(v) => {
        form.setValue('text', v?.html);
        form.setValue('textRaw', v?.raw);
      }}
      defaultValue={defaultValue}
      bordered
      keywordOptions={keys}
      allowUploads
      pathUpload={`${ownerableType}/${ownerableId}/Messages`}
    />
  );
};
