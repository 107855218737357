import React, { useContext } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { useDispatch, useSelector } from 'react-redux';
import { workoutsSelectors } from 'modules/workouts';
import Texture from 'core/ui/Texture';
import Box from 'core/ui/Box';
import Typography from 'core/ui/Typography';
import Grid from 'core/ui/Grid';
import { ButtonPrimary } from 'core/ui/Button';
import { useTranslation } from 'react-i18next';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import notifications from 'modules/notifications';
import { ShowContext } from 'components/Workout/Show';

export default function ModalWaitlist({ open, userId, close, closePurchase, notified }) {
  const showContext = useContext(ShowContext);
  const { t } = useTranslation();
  const { request } = useActions();
  const dispatch = useDispatch();
  const workout = useSelector((state) => workoutsSelectors.getWorkout(state));

  const buttonColor = workout?.channel?.customColors?.buttonColor;

  const confirm = () => {
    request({
      action: Modules.newWorkouts.actions.registerWaitList,
      data: { workoutId: workout.id, userId },
      options: {
        onSuccess: () => {
          setTimeout(() => {
            if (showContext && showContext.setTab) {
              showContext.setTab('attendance');
            }
          }, 300);
          dispatch(notifications.success(t('workout.waitlist.success')));
          closePurchase();
          close(false);
        },
      },
    });
  };

  return (
    <div>
      <Dialog
        PaperProps={{ style: { borderRadius: 5, minWidth: 300 } }}
        maxWidth="sm"
        scroll="body"
        fullWidth
        onClose={() => close(false)}
        open={open}
      >
        <Texture>
          <Box p={5}>
            <>
              <Typography mb={10} align="center" variant="h4" color="primary">
                {t(!notified ? 'workout.register.error' : 'workout.register.errorNotified')}
              </Typography>
              {!notified && (
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <ButtonPrimary buttonColor={buttonColor} fullWidth onClick={() => confirm()}>
                      <Typography variant="h5" m={3}>
                        {t('button.confirm')}
                      </Typography>
                    </ButtonPrimary>
                  </Grid>

                  <Grid item xs={6}>
                    <ButtonPrimary buttonColor={buttonColor} fullWidth onClick={() => close()}>
                      <Typography variant="h5" m={3}>
                        {t('button.cancel')}
                      </Typography>
                    </ButtonPrimary>
                  </Grid>
                </Grid>
              )}
            </>
          </Box>
        </Texture>
      </Dialog>
    </div>
  );
}

ModalWaitlist.defaultProps = {
  open: false,
};
