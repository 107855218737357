import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from 'core/ui/Grid';
import Typography from 'core/ui/Typography';
import Paper from 'core/ui/Paper';
import Box from 'core/ui/Box';
import LoaderSm from 'core/ui/LoaderSm';
import EqpMovList from 'components/Workout/Show/Tabs/Overview/EqpMovList';
import ReactPlayer from 'react-player';
import CollapseText from 'core/ui/CollapseText';
import ClassInfoDetail from 'components/Workout/Show/Tabs/Overview/ClassInfoDetail';
import ItemSection from 'components/Workout/Show/Tabs/Overview/Sections/ItemSection';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import useSelectors from 'modules/map/useSelectors';
import { newWorkoutsModule } from 'modules/workouts/new';

export default ({ workoutId, compare, fromClassForm }) => {
  const { t } = useTranslation();
  const { request } = useActions();
  const loading = useSelectors(newWorkoutsModule, 'loading');
  const [indexOpened, setIndexOpened] = useState(null);
  const [workout, setWorkout] = useState({});

  useEffect(() => {
    request({
      action: Modules.newWorkouts.actions.getWorkout,
      data: { id: workoutId },
      options: {
        onSuccess: setWorkout,
      },
    });

    return () => {
      setWorkout({});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request, workoutId]);

  return (
    <Box p={3}>
      <Paper elevation={2} mb={3} className="paper-rounded" p={3}>
        {loading && (
          <Box display="flex" justifyContent="center">
            <LoaderSm loading={loading} />
          </Box>
        )}
        {!loading && (
          <Grid container>
            <Grid item xs={12} md={fromClassForm ? 12 : 6}>
              <Typography color="secondary" variant="subtitle2" component="h1">
                {t('description')}
              </Typography>
              <Typography
                className="roboto-regular"
                color="secondary"
                variant="caption"
                component="div"
                align="justify"
              >
                <CollapseText text={workout?.description} maxChar={120} />
              </Typography>
            </Grid>
            <Grid item xs={12} md={fromClassForm ? 12 : 6}>
              <ClassInfoDetail workout={workout} compare={compare} />
            </Grid>
          </Grid>
        )}
      </Paper>

      <Grid container spacing={3}>
        <Grid item xs={12} md={fromClassForm ? 12 : 6}>
          {workout &&
            workout?.sections &&
            workout?.sections.map((section, index) => (
              <Box mb={3} key={section?.id}>
                <ItemSection
                  selectTab={(idx, open) => setIndexOpened(open ? null : idx)}
                  hideSections={false}
                  index={index}
                  section={{ open: indexOpened === index, ...section }}
                  showLeaderboard={false}
                  isCoach={workout?.isCoach}
                  isCompetition
                  isRegistered={workout?.registered}
                />
              </Box>
            ))}
        </Grid>
        <Grid item xs={12} md={fromClassForm ? 12 : 6} mb={5}>
          {workout?.previewVideo && workout?.previewVideo.url && (
            <Paper elevation={2} mb={3} className="paper-rounded" p={3}>
              <Box className="container-aspect container-aspect-57">
                <Box className="item-aspect">
                  <ReactPlayer
                    playing={false}
                    url={workout?.previewVideo.url}
                    width="100%"
                    height="100%"
                    controls
                  />
                </Box>
              </Box>
            </Paper>
          )}
          <EqpMovList workout={workout} />
        </Grid>
      </Grid>
    </Box>
  );
};
