import api from 'core/api';

export const channelAnnouncementsModule = 'channelAnnouncements';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const actions = {
  getAnnouncements: {
    module: channelAnnouncementsModule,
    name: 'getAnnouncements',
    api: ({ channelId }) => api.get(`/channels/${channelId}/channel_announcements`),
    params: {
      start: ['params'],
      error: [''],
      success: ['data'],
    },
    state: loadingStates,
  },
  getValidAnnouncements: {
    module: channelAnnouncementsModule,
    name: 'getValidAnnouncements',
    api: ({ channelId }) => api.get(`/channels/${channelId}/channel_announcements/valid`),
    params: {
      start: ['params'],
      error: [''],
      success: ['data'],
    },
    state: loadingStates,
  },
  postAnnouncement: {
    module: channelAnnouncementsModule,
    name: 'postAnnouncement',
    api: (data) => api.post(`/channels/${data.channelId}/channel_announcements`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  putAnnouncement: {
    module: channelAnnouncementsModule,
    name: 'putAnnouncement',
    api: (data) => api.put(`/channels/${data.channelId}/channel_announcements/${data.id}`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  destroy: {
    module: channelAnnouncementsModule,
    name: 'destroy',
    api: (data) => api.delete(`/channels/${data.channelId}/channel_announcements/${data.id}`),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
};

export default {
  actions,
  state: {
    loading: false,
    data: [],
  },
};
