import React, { useState } from 'react';
import Typography from 'core/ui/Typography';
import { showScore } from 'core/utils/helpers';
import { secondary } from 'core/ui/Colors';
import { Collapse } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';

export default ({ workoutScores, score, fontColor, fontValue, hideName, sectionScore }) => {
  const { t } = useTranslation();

  const [liftOpened, setLiftOpened] = useState(false);

  const liftScores = Object.keys(workoutScores[score] || {}) || [];

  const lastIndex = () => {
    let retIndex = 0;
    liftScores.forEach((item, idx) => {
      const existsScore = workoutScores[score][liftScores[idx]];
      if (existsScore?.weight > 0) {
        retIndex = idx;
      }
    });

    return retIndex;
  };

  const lastScore = workoutScores[score][liftScores[lastIndex()]];

  return (
    <Box>
      {!!showScore(workoutScores[score], score) && (
        <Typography
          style={{ color: fontColor || secondary, ...fontValue }}
          color="secondary"
          className="roboto-regular"
        >
          {score.indexOf('tieBreak') > -1 && <b>Tie Break&nbsp;</b>}
          {!hideName && (
            <>
              {t(
                `workout.section.label.score.${
                  score.indexOf('tieBreak') > -1 ? `${score.replace('tieBreak', '')}` : score
                }`,
              )}
            </>
          )}
          {liftScores.length > 0 && (
            <>
              <Typography
                onClick={() => setLiftOpened(!liftOpened)}
                style={{ color: fontColor || secondary, ...fontValue }}
                color="secondary"
                className="roboto-regular hover"
              >
                {sectionScore?.liftComplex?.custom ? (
                  <>{liftScores?.map((lift) => workoutScores[score][lift]?.reps).join('-')}</>
                ) : (
                  <>
                    {sectionScore?.liftComplex?.sets}&nbsp;x&nbsp;{sectionScore?.liftComplex?.reps}
                  </>
                )}
                &nbsp;@&nbsp;{lastScore?.weight}
                {t(`weight.type.abbrev.${lastScore?.typeWeight || 'pounds'}`)}
              </Typography>
              <Box pl={4.5}>
                <Collapse in={liftOpened}>
                  {liftScores.reverse().map((lift) => (
                    <Box display="flex" key={lift} alignItems="center">
                      <Typography style={{ color: fontColor || secondary, fontSize: 10 }} mr={2}>
                        {workoutScores[score][lift]?.reps || Number(lift) + 1}x
                      </Typography>
                      <Typography style={{ color: fontColor || secondary, fontSize: 12 }}>
                        @&nbsp;&nbsp;{workoutScores[score][lift]?.weight}
                        {t(
                          `weight.type.abbrev.${
                            workoutScores[score][lift]?.typeWeight || 'pounds'
                          }`,
                        )}
                      </Typography>
                    </Box>
                  ))}
                </Collapse>
              </Box>
            </>
          )}
        </Typography>
      )}
    </Box>
  );
};
