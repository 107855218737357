import { useHistory } from 'react-router';

const schemes = [
  'streamfitness://',
  'https://move.streamfitness.live/',
  'https://staging.streamfitness.live/',
  'https://go.streamfitness.live/',
];

export default () => {
  const history = useHistory();

  const convertUrl = (url) => {
    let replacedUrl = url;

    schemes.forEach((item) => {
      replacedUrl = replacedUrl.replace(item, '');
    });

    const data = replacedUrl?.split('/');
    return {
      view: data[0],
      param1: data[1],
      param2: data[2],
      param3: data[3],
      param4: data[4],
    };
  };

  const openRoute = ({ url }) => {
    if (url) {
      const route = convertUrl(url);
      if (route.view === 'Stream') {
        history.push(`/workout/${route?.param1}/stream`);
        return;
      }

      if (route.view === 'WatchWorkout') {
        history.push(`/competition/${route.param1}/workout/${route.param2}/watch`);
        return;
      }

      if (route.view === 'WorkoutView' && route?.param2 === 'Leaderboard') {
        history.push(`/workout/view/${route?.param1}/overview/leaderboard/${route?.param3}`);
        return;
      }

      if (route.view === 'WorkoutView' && !route?.param2) {
        history.push(`/workout/view/${route?.param1}`);
        return;
      }

      if (route.view === 'ChannelView') {
        history.push(`/channel/view/${route.param1}`);
        return;
      }

      if (route.view === 'dashboard' && route?.param1 === 'personalRecord') {
        history.push(`/dashboard/personalRecord`);
        return;
      }

      if (route.view === 'Dashboard' && route?.param1 === 'Benchmark') {
        history.push(`/dashboard/benchmark`);
      }
    }
  };

  return {
    openRoute,
  };
};
