import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import { ButtonPrimary } from 'core/ui/Button';
import Typography, { fontStyle } from 'core/ui/Typography';
import Radio from 'core/ui/Radio';

const FormTag = ({ form }) => {
  const { t } = useTranslation();
  const { setValue, watch, handleSubmit, onSubmit, errors, loading } = form;

  return (
    <Box>
      <Typography variant="h5" align="center" mb={3}>
        {t('channel.tag')}
      </Typography>

      <Box mb={3}>
        <TextFieldRounded
          value={watch('name')}
          onChange={(v) => setValue('name', v)}
          placeholder={t('label.name')}
          error={errors.name}
          label={t('label.name').toUpperCase()}
          required
        />
      </Box>

      <Box mb={3}>
        <Typography mb={2} style={fontStyle.label} variant="body1">
          {t('label.active').toUpperCase()}
        </Typography>
        <Box display="flex">
          <Radio
            label={t('button.yes')}
            checked={!!watch('active')}
            onClick={() => setValue('active', true)}
          />
          <Box ml={1}>
            <Radio
              label={t('button.no')}
              checked={!watch('active')}
              onClick={() => {
                setValue('active', false);
              }}
            />
          </Box>
        </Box>
      </Box>

      <Box mt={3} display="flex" justifyContent="flex-end">
        <Box ml={3}>
          <ButtonPrimary disabled={loading} onClick={handleSubmit(onSubmit)}>
            {t('button.save')}
          </ButtonPrimary>
        </Box>
      </Box>
    </Box>
  );
};

export default FormTag;
