import React from 'react';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import Paper from 'core/ui/Paper';
import Typography from 'core/ui/Typography';
import ReactPlayer from 'react-player';
import { ButtonPrimary } from 'core/ui/Button';
import LoaderSm from 'core/ui/LoaderSm';
import { personalRecordMovementsModule } from 'modules/personalRecordMovements';
import useSelectors from 'modules/map/useSelectors';
import PrCalculator from './ModalPrCalculator/PrCalculator';

export const ColorsBarRepMax = {
  repMax1: '#4fb94a',
  repMax2: '#ff7c43',
  repMax3: '#ffa600',
  repMax4: '#003f5c',
};

const PersonalRecordInfo = ({ selectedPr, addNewPr }) => {
  const { t } = useTranslation();
  const loading = useSelectors(personalRecordMovementsModule, 'loading');

  return (
    <Box>
      <Paper className="paper-rounded" p={3}>
        <Typography variant="h4" color="secondary">
          {selectedPr.movement.name}
        </Typography>
        <Box my={3}>
          {selectedPr.videoLink && (
            <Box
              mb={3}
              className="container-aspect container-aspect-47"
              style={{ borderRadius: 10, overflow: 'hidden' }}
            >
              <Box className="item-aspect">
                <ReactPlayer controls width="100%" height="100%" url={selectedPr.videoLink} />
              </Box>
            </Box>
          )}

          {loading && (
            <Box display="flex" justifyContent="center" alignItems="center" m={5}>
              <LoaderSm loading={loading} />
            </Box>
          )}

          {selectedPr.bestPr && selectedPr.scoreType === 'rep_max' && (
            <PrCalculator selectedPr={selectedPr} />
          )}

          <ButtonPrimary onClick={() => addNewPr()} fullWidth>
            {t('button.addPr').toUpperCase()}
          </ButtonPrimary>
        </Box>
      </Paper>
    </Box>
  );
};

export default PersonalRecordInfo;
