import api from 'core/api';
import { primary } from 'core/ui/Colors';
import { call, put } from 'redux-saga/effects';

export const newAppModule = 'newApp';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const actions = {
  setPrimaryColor: {
    module: newAppModule,
    name: 'setPrimaryColor',
    params: {
      start: ['params'],
      error: [''],
      success: ['primaryColor'],
    },
  },

  getUnredCount: {
    module: newAppModule,
    name: 'getUnredCount',
    api: (data) => api.get('/users_messages/unread_count', data),
    *sagas(Creators, { params }) {
      try {
        const resp = yield call(actions.getUnredCount.api, params);
        yield put(
          Creators.getUnredCountSuccess(
            resp.data?.pushNotifications,
            resp.data?.groupsMessages,
            resp?.data?.workoutsInvites,
          ),
        );
      } catch (error) {
        yield put(Creators.getUnredCountError());
      }
    },
    params: {
      start: ['params'],
      error: [],
      success: ['notificationsUnreadCount', 'groupsChatsUnreadCount', 'workoutsInvitesCount'],
    },
    state: loadingStates,
  },

  setStatusBarHeight: {
    module: newAppModule,
    name: 'setStatusBarHeight',
    params: {
      start: ['params'],
      error: [''],
      success: ['statusBarHeight'],
    },
    state: loadingStates,
  },

  setChannelImageConfig: {
    module: newAppModule,
    name: 'setChannelImageConfig',
    params: {
      start: ['params'],
      error: [''],
      success: ['channelImageConfig'],
    },
    state: loadingStates,
  },

  setHeats: {
    module: newAppModule,
    name: 'setHeats',
    params: {
      start: ['params'],
      error: [''],
      success: ['hearts'],
    },
    state: loadingStates,
  },

  setHideContracts: {
    module: newAppModule,
    name: 'setHideContracts',
    params: {
      start: ['params'],
      error: [''],
      success: ['hideContracts'],
    },
    state: loadingStates,
  },

  setNotificationsUnreadCount: {
    module: newAppModule,
    name: 'setNotificationsUnreadCount',
    params: {
      start: ['params'],
      error: [''],
      success: ['notificationsUnreadCount'],
    },
    state: loadingStates,
  },

  setGroupsChatsUnreadCount: {
    module: newAppModule,
    name: 'setGroupsChatsUnreadCount',
    params: {
      start: ['params'],
      error: [''],
      success: ['groupsChatsUnreadCount'],
    },
    state: loadingStates,
  },
};

export default {
  actions,
  state: {
    primaryColor: primary,
  },
};
