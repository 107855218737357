/* eslint-disable eqeqeq */
import { conversationsModule } from 'modules/conversations';
import useSelectors from 'modules/map/useSelectors';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import { chatColors } from 'core/ui/Colors';
import { ButtonPrimary } from 'core/ui/Button';
import Typography from 'core/ui/Typography';
import { colors } from '@material-ui/core';
import { ChatContext } from 'contexts';
import { Close } from '@material-ui/icons';
import { persistData, retrieveData } from 'core/utils/session-storage';
import useSendMessages from './useSendMessages';
import ChatMessages from './ChatMessages';
import MessageInput from './MessageInput';
import Groups from './Groups';

const GroupsChat = ({ close }) => {
  const { t } = useTranslation();

  const { ownerableType, ownerableId } = useContext(ChatContext);
  const groupsChat = useSelectors(conversationsModule, 'groupsChat');
  const { getGroupsChat, joinGroup } = useSendMessages();

  const [editMessage, setEditMessage] = useState({ open: false });
  const [action, setAction] = useState({});

  const [openModalize, setOpenModalize] = useState(false);

  const cacheGroup = (gId) => {
    persistData(
      'CHAT_CACHE_DATA',
      JSON.stringify({
        ownerableType,
        ownerableId,
        groupsChatId: gId,
      }),
    );
  };

  const loadGroup = (gId) => {
    cacheGroup(gId);
    getGroupsChat({
      groupsChatId: gId,
    });
  };

  useEffect(() => {
    const cachedGroup = JSON.parse(retrieveData('CHAT_CACHE_DATA') || null);
    if (cachedGroup?.ownerableId == ownerableId && cachedGroup?.ownerableType == ownerableType) {
      loadGroup(cachedGroup?.groupsChatId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ownerableType, ownerableId]);

  return (
    <Box display="flex" style={{ height: '100%', flex: 1, backgroundColor: chatColors.background }}>
      <Groups close={close} />

      <Box
        flexGrow={1}
        display="flex"
        flexDirection="column"
        style={{ backgroundColor: chatColors.container }}
      >
        {groupsChat?.id && (
          <Box
            display="flex"
            style={{ backgroundColor: chatColors.background, height: 42 }}
            alignItems="center"
            px={2}
          >
            <Box flexGrow={1}>
              <Typography variant="h6" mt={1} style={{ color: colors.grey[300] }}>
                {groupsChat?.name}
              </Typography>
            </Box>

            <Box onClick={close} aria-label="close">
              <Close fontSize="small" style={{ color: colors.grey[300] }} />
            </Box>
          </Box>
        )}
        {groupsChat?.joined && (
          <>
            <ChatMessages
              setAction={setAction}
              editMessage={editMessage}
              setEditMessage={setEditMessage}
            />
            <MessageInput
              actions={{ setOpenModalize, openModalize }}
              editMessage={editMessage?.item}
              setEditMessage={setEditMessage}
              action={action}
              setAction={setAction}
            />
          </>
        )}

        {groupsChat?.id && !groupsChat.joined && (
          <Box
            p={10}
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Typography align="center" m={2} variant="h5" style={{ color: colors.grey[300] }}>
              Join to this group
            </Typography>
            <ButtonPrimary
              onClick={() =>
                joinGroup({
                  groupsChatId: groupsChat?.id,
                  callback: () => {
                    getGroupsChat({ groupsChatId: groupsChat?.id });
                  },
                })
              }
            >
              {t('button.join')}
            </ButtonPrimary>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default GroupsChat;
