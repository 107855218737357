import React, { useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import { Dialog, TableCell, withStyles } from '@material-ui/core';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useTranslation } from 'react-i18next';
import { secondary, secondaryLight } from 'core/ui/Colors';
import Typography from 'core/ui/Typography';
import Paper from 'core/ui/Paper';
import Checkbox from 'core/ui/Checkbox';
import { ButtonPrimary } from 'core/ui/Button';
import Texture from 'core/ui/Texture';
import Box from 'core/ui/Box';

export const Cell = withStyles(() => ({
  head: {
    backgroundColor: secondaryLight,
    color: secondary,
    fontSize: 12,
  },
  body: {
    fontSize: 11,
  },
}))(TableCell);

export default function ModalMemberships({ checkedItems, setCheckedItems, channel, close, open }) {
  const { t } = useTranslation();
  const [checkedAll, setCheckedAll] = useState(true);

  const toggleItem = (planId) => {
    if (checkedItems.find((x) => x === planId)) {
      setCheckedItems(checkedItems.filter((x) => x !== planId));
    } else {
      setCheckedItems([...checkedItems, planId]);
    }
  };

  const toggleSelectAll = () => {
    if (checkedAll) {
      setCheckedItems([]);
      setCheckedAll(false);
    } else {
      setCheckedItems(channel?.membershipAllPlans?.map((x) => x.id));
      setCheckedAll(true);
    }
  };

  return (
    <Dialog maxWidth="md" scroll="body" fullWidth onClose={() => close()} open={open}>
      <Texture>
        <Paper my={3} p={3}>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table" size="small">
              <TableHead>
                <TableRow>
                  <Cell>
                    <Typography className="hover" color="primary" onClick={() => toggleSelectAll()}>
                      {t(checkedAll ? 'search.button.deselectAll' : 'search.button.selectAll')}
                    </Typography>
                  </Cell>
                  <Cell>{t('channel.membership.plan.name')}</Cell>
                </TableRow>
              </TableHead>
              <TableBody>
                {channel?.membershipAllPlans?.map((plan, index) => (
                  <TableRow key={index.toString()}>
                    <Cell>
                      <Checkbox
                        checked={!!checkedItems.find((x) => x === plan?.id)}
                        onClick={() => toggleItem(plan?.id)}
                      />
                    </Cell>
                    <Cell>
                      <Typography>{plan.name}</Typography>
                    </Cell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box textAlign="end" mt={2}>
            <ButtonPrimary onClick={() => close()}>{t('button.close')}</ButtonPrimary>
          </Box>
        </Paper>
      </Texture>
    </Dialog>
  );
}
