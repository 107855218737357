export default {
  libraryMenus: [
    'movementLibrary',
    'history',
    'templates',
    'benchmarks',
    'tags',
    // 'importClasses',
    // 'libraryMedia',
  ],
  usersMenu: ['users', 'coachesCalendar', 'payRates', 'customPayments', 'timeClock'],
  infoMenu: ['info', 'integrations'],

  reportsMenus: (channel, currentUser) => {
    const reports = [
      {
        value: 'gymHealth',
        label: 'GYM HEALTH',
        items: [
          {
            value: 'Forecast',
            label: 'Forecast',
          },
          {
            value: 'MembersConversions',
            label: 'Conversion',
          },
          {
            value: 'MembersChurn',
            label: 'Churn',
          },
        ],
      },
      {
        value: 'finance',
        label: 'FINANCE',
        items: [
          {
            value: 'Payouts',
            label: 'Payouts',
          },
          {
            value: 'Coaches',
            label: 'Payroll',
          },
          {
            value: 'Payments',
            label: 'Transactions',
          },
          {
            value: 'StoreOrders',
            label: 'Store Orders',
          },
          {
            value: 'ActionReport',
            label: 'Action Report',
          },
        ],
      },
      {
        value: 'member',
        label: 'MEMBER',
        items: [
          {
            value: 'Memberships',
            label: 'Active Memberships',
          },
          {
            value: 'MembershipsDetails',
            label: 'Memberships Details',
          },
          {
            value: 'PausedMembers',
            label: 'Paused Members',
          },
          {
            value: 'AtRiskMembers',
            label: 'At Risk Members',
          },
          {
            value: 'Birthday',
            label: 'Birthdays',
          },
          {
            value: 'Anniversaries',
            label: 'Anniversaries',
          },
          {
            value: 'AttendanceReport',
            label: 'Attendance',
          },
        ],
      },
    ];

    const reportsMenu = {
      value: 'system',
      label: 'SYSTEM',
      items: [
        {
          value: 'ClassPerformance',
          label: 'Class Performance',
        },
        {
          value: 'ScheduledMemberships',
          label: 'Processing',
        },
        {
          value: 'ProductsInventories',
          label: 'Products Inventories',
        },
        {
          value: 'Waivers',
          label: 'Waivers',
        },
      ],
    };

    if (channel?.hasHybrid) {
      reportsMenu.items.push({
        value: 'HybridTracks',
        label: 'HybridAF',
      });
    }

    if (channel?.activePlans?.sifely?.active) {
      reportsMenu.items.push({
        value: 'SifelyLogs',
        label: 'Sifely Logs',
      });
    }

    reports.push(reportsMenu);

    reports[0].items.push({
      value: 'MembersGrowth',
      label: 'Growth',
    });

    return reports;
  },
};
