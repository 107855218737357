import React from 'react';
import PropTypes from 'prop-types';
import Box from 'core/ui/Box';
import { Dialog } from '@material-ui/core';
import Texture from 'core/ui/Texture';
import useForm from './form';
import FormTag from './FormTag';

const ModalFormTag = ({ open, tagId, close, callback, mode, channel }) => {
  const form = useForm({
    mode,
    tagId,
    callback,
    channel,
  });
  return (
    <Dialog maxWidth="sm" scroll="body" fullWidth onClose={() => close()} open={open}>
      <Texture>
        <Box p={5}>
          <FormTag form={form} channel={channel} />
        </Box>
      </Texture>
    </Dialog>
  );
};

ModalFormTag.defaultProps = {
  close: () => {},
  callback: () => {},
  open: false,
  mode: 'new',
};

ModalFormTag.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  close: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  callback: PropTypes.func,
  open: PropTypes.bool,
  mode: PropTypes.string,
};

export default ModalFormTag;
