import React, { useEffect } from 'react';
import { renderRoutes } from 'react-router-config';
import ScrollToTop from 'react-router-scroll-top';
import useChannelSettings from 'core/useChannelSettings';
import { channelsActions } from 'modules/channels';
import { retrieveData } from 'core/utils/session-storage';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { sessionSelectors } from 'modules/session';
import routes from './routes';

const AppStart = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => sessionSelectors.getUser(state));

  useChannelSettings();

  useEffect(() => {
    if (
      location?.pathname?.indexOf('/channel') === -1 &&
      location?.pathname?.indexOf('/embed') === -1 &&
      location?.pathname?.indexOf('/calendars') === -1
    ) {
      const data = retrieveData('MEMBER_CHANNEL');

      if (data) {
        const settings = JSON.parse(data);
        dispatch(channelsActions.setChannel(settings));
      }

      if (currentUser?.channel?.id) {
        dispatch(channelsActions.getChannelStart(currentUser?.channel?.id));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser?.channel?.id]);

  return <ScrollToTop>{renderRoutes(routes(currentUser?.role, currentUser?.manager))}</ScrollToTop>;
};

export default AppStart;
