import api from 'core/api';

export const storeOptionsModule = 'storeOptions';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const actions = {
  getStoreOptions: {
    module: storeOptionsModule,
    name: 'getStoreOptions',
    api: (params) => api.get(`/store/options`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: ['data'],
    },
    state: loadingStates,
  },
  postStoreOptions: {
    module: storeOptionsModule,
    name: 'postStoreOptions',
    api: (params) => api.post(`/store/options`, params),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  putStoreOptions: {
    module: storeOptionsModule,
    name: 'putStoreOptions',
    api: (data) => api.put(`/store/options/${data.id}`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
};

export default {
  actions,
  state: {
    loading: false,
    data: [],
  },
};
