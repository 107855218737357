import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

import * as selectors from './selectors';
import * as sagas from './sagas';

/* ---------- Action Creators and Types ---------- */
const { Types, Creators } = createActions(
  {
    postChannelMemberNotesError: ['error'],
    postChannelMemberNotesStart: ['data', 'callback'],
    postChannelMemberNotesSuccess: [],

    putChannelMemberNotesError: ['error'],
    putChannelMemberNotesStart: ['data', 'callback'],
    putChannelMemberNotesSuccess: [],

    getChannelMemberNotesError: ['error'],
    getChannelMemberNotesStart: ['channelId', 'memberId', 'callback'],
    getChannelMemberNotesSuccess: ['notes', 'pagination'],

    deleteChannelMemberNotesError: ['error'],
    deleteChannelMemberNotesStart: ['channelId', 'id', 'callback'],
    deleteChannelMemberNotesSuccess: [],
  },
  { prefix: 'channelLists/' },
);

/* ---------- Initial State ---------- */
const INITIAL_STATE = Immutable({
  data: [],
  error: null,
  loading: false,
});

/* ---------- Reducers ---------- */
const loadStartReducer = (state) => state.set('loading', true);

const postChannelMemberNotesSuccessReducer = (state) =>
  state.merge({
    loading: false,
    error: null,
  });

const deleteChannelMemberNotesSuccessReducer = (state) =>
  state.merge({
    loading: false,
    error: null,
  });

const getChannelMemberNotesSuccessReducer = (state, action) =>
  state.merge({
    data: action.notes,
    loading: false,
    error: null,
  });

const loadErrorReducer = (state, action) =>
  state.merge({
    loading: false,
    error: action.error,
  });

/* ---------- Main Reducer ---------- */
const reducer = createReducer(INITIAL_STATE, {
  [Types.POST_CHANNEL_MEMBER_NOTES_START]: loadStartReducer,
  [Types.POST_CHANNEL_MEMBER_NOTES_SUCCESS]: postChannelMemberNotesSuccessReducer,
  [Types.POST_CHANNEL_MEMBER_NOTES_ERROR]: loadErrorReducer,

  [Types.PUT_CHANNEL_MEMBER_NOTES_START]: loadStartReducer,
  [Types.PUT_CHANNEL_MEMBER_NOTES_SUCCESS]: postChannelMemberNotesSuccessReducer,
  [Types.PUT_CHANNEL_MEMBER_NOTES_ERROR]: loadErrorReducer,

  [Types.DELETE_CHANNEL_MEMBER_NOTES_START]: loadStartReducer,
  [Types.DELETE_CHANNEL_MEMBER_NOTES_SUCCESS]: deleteChannelMemberNotesSuccessReducer,
  [Types.DELETE_CHANNEL_MEMBER_NOTES_ERROR]: loadErrorReducer,

  [Types.GET_CHANNEL_MEMBER_NOTES_START]: loadStartReducer,
  [Types.GET_CHANNEL_MEMBER_NOTES_SUCCESS]: getChannelMemberNotesSuccessReducer,
  [Types.GET_CHANNEL_MEMBER_NOTES_ERROR]: loadErrorReducer,
});

/* ---------- Exporting ---------- */
export const channelMemberNotesActions = Creators;
export const channelMemberNotesTypes = Types;
export const channelMemberNotesSelectors = selectors;
export const channelMemberNotesSagas = sagas;

export default reducer;
