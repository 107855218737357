import React, { createContext } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from 'core/ui/Typography';

export const SearchCompetitionContext = createContext();

const ViewWeekDay = ({ weekDay, weeklyHours }) => {
  const { t } = useTranslation();

  const dayTimes = (weeklyHours && weeklyHours[weekDay?.id]) || [];

  const validDayTimes = dayTimes?.filter((x) => !x._destroy);

  return (
    <div className="tw-flex tw-flex-row tw-items-center tw-gap-2 tw-p-1">
      <div className="tw-w-16">
        <Typography className="tw-text-base tw-text-gray-500">
          {t(`abbrev2.${weekDay?.name}`).toUpperCase()}
        </Typography>
      </div>
      <div className="tw-flex tw-grow tw-flex-col tw-gap-1">
        {validDayTimes?.length <= 0 && (
          <div className="tw-text-base tw-text-gray-400">{t('label.unavailable')}</div>
        )}
        {validDayTimes.map((x) => (
          <div key={x.startTime} className="tw-flex tw-flex-row tw-items-center tw-gap-2">
            <div className="tw-font-normal tw-text-gray-700 ">{x?.startTime}</div>
            <div>-</div>
            <div className="tw-font-normal tw-text-gray-700">{x?.endTime}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ViewWeekDay;
