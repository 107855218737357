import React, { useEffect, useState } from 'react';
import Box from 'core/ui/Box';
import Typography from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import Paper from 'core/ui/Paper';
import { useSelector } from 'react-redux';
import { workoutsSelectors } from 'modules/workouts';
import { secondary, secondaryLight, successColor } from 'core/ui/Colors';
import { VerifiedUser } from '@material-ui/icons';
import SFDataTable from 'core/ui/DataTable';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import WorkoutScoresPoints from 'components/Competition/Show/Tabs/Results/Tables/WorkoutScoresPoints';
import BoxSearch from './BoxSearch';

const JudgeListTeams = ({
  competition,
  goToTeamJudge,
  currentDivision,
  setCurrentDivision,
  workout,
}) => {
  const { t } = useTranslation();
  const { fontColor } = competition;
  const [text, setText] = useState('');
  const leaderboard = useSelector((state) => workoutsSelectors.getWorkoutSectionLeaderboard(state));

  const dropdownWrapper = {
    padding: 5.3,
    backgroundColor: 'white',
    border: `0.5px solid ${secondaryLight}`,
    borderRadius: 5,
  };

  useEffect(() => {
    if (!currentDivision && competition.divisions && competition.divisions.length > 0) {
      setCurrentDivision(competition.divisions[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [competition]);

  const columns = [
    {
      name: (
        <Typography className="roboto-regular ">
          {t(`competition.label.eventType.${competition.eventType}`)}
        </Typography>
      ),
      sortable: true,
      cell: (row) => (
        <Typography onClick={() => goToTeamJudge(row)}>
          {row.user.team.name || row?.user.name}
        </Typography>
      ),
    },
    {
      name: <Typography className="roboto-regular">{t('workout.judge.heat')}</Typography>,
      selector: 'heat',
      sortable: true,
      center: true,
      cell: (row) => (
        <Typography onClick={() => goToTeamJudge(row)} style={{ color: fontColor }}>
          {row.heat}
        </Typography>
      ),
    },
    {
      name: <Typography className="roboto-regular">{t('workout.judge.place')}</Typography>,
      selector: 'position',
      sortable: true,
      center: true,
      cell: (row) => (
        <Typography onClick={() => goToTeamJudge(row)} style={{ color: fontColor }}>
          {row.position}
        </Typography>
      ),
    },
    {
      name: <Typography className="roboto-regular">{t('workout.judge.points')}</Typography>,
      selector: 'points',
      sortable: true,
      center: true,
      cell: (row) => (
        <Typography onClick={() => goToTeamJudge(row)} style={{ color: fontColor }}>
          {row.points}
        </Typography>
      ),
    },
    {
      name: <Typography className="roboto-regular">{t('workout.judge.flags')}</Typography>,
      selector: 'points',
      sortable: true,
      center: true,
      cell: (row) => (
        <Typography onClick={() => goToTeamJudge(row)} style={{ color: fontColor }}>
          {row.flags ? row.flags.length : 0}
        </Typography>
      ),
    },
    {
      name: <Typography className="roboto-regular">{t('workout.judge.score')}</Typography>,
      selector: 'points',
      sortable: true,
      center: true,
      cell: (row) => (
        <Box onClick={() => goToTeamJudge(row)}>
          <WorkoutScoresPoints scores={row.revisedScores || row.scores} />

          <WorkoutScoresPoints
            sectionScores={row?.sectionScores}
            scores={row.revisedScores || row.scores}
            positions={row?.positions || {}}
            showVariation={workout.competitionUseRxScaledScores}
            buttonColor={competition?.buttonColor}
          />
        </Box>
      ),
    },
    {
      name: <Typography className="roboto-regular">{t('workout.judge.verified')}</Typography>,
      selector: 'verified',
      sortable: true,
      center: true,
      cell: (row) => (
        <VerifiedUser
          onClick={() => goToTeamJudge(row)}
          style={{ color: row.verified ? successColor : secondary }}
        />
      ),
    },
  ];

  const filterTemas = (x) => {
    if (text) {
      if (x?.user?.name?.toUpperCase().indexOf(text?.toUpperCase()) > -1) {
        return true;
      }
      if (x?.user?.team?.name?.toUpperCase().indexOf(text?.toUpperCase()) > -1) {
        return true;
      }
      if (x.heat && x?.heat?.toUpperCase().indexOf(text?.toUpperCase()) > -1) {
        return true;
      }

      return false;
    }
    return true;
  };

  const divisions = competition.hasDivisions ? competition.divisions : [];

  return (
    <Paper className="paper-rounded opacity-80" p={3}>
      <Box>
        <Box display="flex" mb={5} flexWrap="wrap" alignItems="center">
          <Box flexGrow="1" display="flex">
            <Typography variant="h5" component="h4">
              {t(`competition.tab.${competition.eventType}s`).toUpperCase()}
            </Typography>
          </Box>
        </Box>
        <Box mb={3} display="flex">
          {competition && competition.workouts && competition.workouts.length > 0 && (
            <Box mr={3}>
              <Box style={dropdownWrapper}>
                <SelectBoxObject
                  options={competition.workouts}
                  value={workout.id}
                  setValue={(v) => {
                    window.location = `/competition/${competition.id}/workout/${v}/judge`;
                  }}
                  elevation={0}
                />
              </Box>
            </Box>
          )}
          {divisions && divisions.length > 0 && (
            <Box mr={3}>
              <Box style={dropdownWrapper}>
                <SelectBoxObject
                  options={divisions}
                  value={currentDivision}
                  setValue={(v) => setCurrentDivision(v)}
                  elevation={0}
                />
              </Box>
            </Box>
          )}
          <Box flexGrow={1}>
            <BoxSearch setText={setText} text={text} />
          </Box>
        </Box>
        <Paper className="paper-rounded hover" p={3}>
          <SFDataTable
            columns={columns}
            showPaper={false}
            data={leaderboard.filter((x) => filterTemas(x))}
            showExport={false}
            onRowClicked={(row) => goToTeamJudge(row)}
            pointerOnHover
            highlightOnHover
            striped
            noDataComponent={
              divisions && divisions.length > 0
                ? t('workout.watch.teams.notFoundDivision')
                : t('workout.watch.teams.notFound')
            }
            noHeader
            pagination={false}
          />
        </Paper>
      </Box>
    </Paper>
  );
};

export default JudgeListTeams;
