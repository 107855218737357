import api from 'core/api';

export const usersDocumentsModule = 'usersDocuments';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const actions = {
  postUsersDocuments: {
    module: usersDocumentsModule,
    name: 'postUsersDocuments',
    api: (data) => api.post(`/users_documents`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  putUsersDocuments: {
    module: usersDocumentsModule,
    name: 'putUsersDocuments',
    api: (data) => api.put(`/users_documents/${data?.id}`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  destroy: {
    module: usersDocumentsModule,
    name: 'destroy',
    api: (params) => api.delete(`/users_documents/${params?.id}`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  getUsersDocuments: {
    module: usersDocumentsModule,
    name: 'getUsersDocuments',
    api: (params) => api.get(`/users_documents`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: ['data'],
    },
    state: loadingStates,
  },
};

export default {
  actions,
  state: {
    data: [],
    pagination: null,
    error: null,
    loading: false,
  },
};
