import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import { ButtonPrimary } from 'core/ui/Button';
import Paper from 'core/ui/Paper';
import Checkbox from 'core/ui/Checkbox';
import useFormTaxes from './form';

const FormTax = ({ itemEdit, callback, mode }) => {
  const { t } = useTranslation();
  const { setValue, watch, handleSubmit, onSubmit, errors, loading } = useFormTaxes({
    mode,
    itemEdit,
    callback,
  });

  return (
    <>
      <Paper p={3}>
        <Box mb={3}>
          <TextFieldRounded
            value={watch('name') || ''}
            onChange={(v) => setValue('name', v)}
            placeholder={t('form.locations.label.name')}
            error={errors.name}
            label={t('form.locations.label.name').toUpperCase()}
            required
            bordered
          />
        </Box>
        <Box mb={3}>
          <Checkbox
            checked={watch('active')}
            onClick={() => setValue('active', !watch('active'))}
            label={t('label.active')}
          />
        </Box>
      </Paper>

      <Box mt={3} display="flex" justifyContent="flex-end">
        <ButtonPrimary disabled={loading} onClick={handleSubmit(onSubmit)}>
          {t('button.save')}
        </ButtonPrimary>
      </Box>
    </>
  );
};

FormTax.defaultProps = {
  callback: () => {},
  itemEdit: null,
  mode: 'new',
};

FormTax.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  callback: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  itemEdit: PropTypes.object,
  mode: PropTypes.string,
};

export default FormTax;
