import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { useTranslation } from 'react-i18next';
import Grid from 'core/ui/Grid';
import CardClass from 'components/Workout/Grid/Card';
import FontIcon from 'core/ui/FontIcon';
import { fontStyle } from 'core/ui/Typography';
import { primary } from 'core/ui/Colors';

export default function ListWorkouts({ item }) {
  const { t } = useTranslation();

  const [showWorkouts, setShowWorkouts] = useState([]);

  const countClasses = () => item.workouts && item.workouts.length;

  useEffect(() => {
    if (countClasses() <= 4) {
      setShowWorkouts(item.workouts);
    } else {
      setShowWorkouts(item.workouts.slice(0, 4));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const seeAll = () => {
    setShowWorkouts(item.workouts);
  };

  return (
    <>
      <Box mx={1} display="flex" alignItems="center">
        <Box flexGrow={1} display="flex">
          <Box>
            <Box display="flex">
              <Typography variant="h6" color="primary">
                {item.name}
              </Typography>
            </Box>
            <Typography ml={2} variant="caption" style={fontStyle.medium} color="secondary">
              {countClasses()}&nbsp;
              {t('channelList.classes')}
            </Typography>
          </Box>
        </Box>

        {countClasses() !== showWorkouts.length && (
          <Typography variant="body1" className="hover" onClick={() => seeAll()}>
            <>
              <Typography mr={1} component="span" variant="body1">
                {t('profile.seeAll').toUpperCase()}
              </Typography>
              <FontIcon iconName="icon-arrow-right" color={primary} size={10} />
            </>
          </Typography>
        )}
      </Box>
      <Box>
        <Box>
          <Grid container>
            {showWorkouts.map((workout, index) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
                key={index.toString()}
                className="search-item"
              >
                <Box m={2} key={index.toString()}>
                  <CardClass
                    key={workout.id}
                    workout={workout}
                    image={workout.channel && workout.channel.image ? workout.channel.image : {}}
                  />
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </>
  );
}

ListWorkouts.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.object.isRequired,
};
