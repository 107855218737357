/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import DatePickerRounded from 'core/ui/DatePickers/DatePickerRounded';
import { ButtonPrimary } from 'core/ui/Button';
import { GetApp, Search } from '@material-ui/icons';
import { formatDateToApi } from 'core/utils/formatters';
import { channelReportsModule } from 'modules/channelReports';
import useSelectors from 'modules/map/useSelectors';
import { formatDate, timeZoneName, usDateFormat } from 'core/utils/formatters/date';
import LoaderSm from 'core/ui/LoaderSm';
import Checkbox from 'core/ui/Checkbox';
import Typography from 'core/ui/Typography';
import notifications from 'modules/notifications';
import { useDispatch } from 'react-redux';
import { persistData, retrieveData } from 'core/utils/session-storage';
import SkeletonPayamentsReport from './Skeleton';
import ByClass from './ByClass';
import ByPercentMembership from './ByPercentMembership';
import ByHourly from './ByHourly';
import Total from './Total/Total';
import ByPercentProduct from './ByPercentProduct';
import ByCustomPayments from './ByCustomPayments';
import ByMembershipBonus from './ByMembershipBonus';
import CalendarsRegistrations from './CalendarsRegistrations';

const rateOptions = [
  'byClass',
  'byMembership',
  'byHourly',
  'ByPercentProduct',
  'byCustomPayments',
  'calendarsRegistrations',
  'byMembershipBonus',
];

const CoachesReport = ({ getReport, channel, isCoachView }) => {
  const { t } = useTranslation();
  const data = useSelectors(channelReportsModule, 'data');
  const loading = useSelectors(channelReportsModule, 'loading');
  const dateNow = new Date();
  const month = dateNow.getMonth() + 1;
  const dispatch = useDispatch();

  const [filters, setFilters] = useState({
    startAt: `${month > 12 ? 12 : month}/1/${dateNow.getFullYear()}`,
    endAt: formatDate(new Date(), channel?.dateFormatReact),
    workoutId: '',
    options: rateOptions,
  });

  const persistOptions = () => {
    persistData('PAYROLL_OPTIONS', JSON.stringify(filters?.options || []));
  };

  useEffect(() => {
    const storedOpts = retrieveData('PAYROLL_OPTIONS');

    if (storedOpts) {
      setFilters((prev) => ({ ...prev, options: JSON.parse(storedOpts) }));
    }
  }, []);

  const search = ({ exportReport = false } = {}) => {
    const startAt = filters.startAt ? formatDateToApi(filters.startAt, timeZoneName()) : null;
    const endAt = filters.endAt ? formatDateToApi(filters.endAt, timeZoneName()) : null;

    if (filters?.options.length === 0) {
      dispatch(notifications.warning('Select a rate type.'));
      return;
    }

    persistOptions();

    if (startAt && endAt)
      getReport({
        startAt,
        endAt,
        exportReport,
        exportFileName: 'coaches_payroll.xls',
        options: filters?.options,
      });
  };

  const checked = (item) => !!filters?.options.find((x) => x === item);

  const toggleType = (type) => {
    if (checked(type)) {
      setFilters((prev) => ({ ...prev, options: prev.options.filter((x) => x !== type) }));
    } else {
      setFilters((prev) => ({ ...prev, options: [...prev.options, type] }));
    }
  };

  return (
    <Box mt={2}>
      <Box>
        <Box display="flex" mb={3} alignItems="flex-end" justifyContent="center" flexWrap="wrap">
          <Box>
            <DatePickerRounded
              label={t('label.startDate').toUpperCase()}
              onChange={(v) => setFilters((prev) => ({ ...prev, startAt: v }))}
              value={filters.startAt}
              disablePast={false}
              autoOk
              usFormat={usDateFormat()}
              bordered={isCoachView}
            />
          </Box>
          <Box ml={1}>
            <DatePickerRounded
              label={t('label.endDate').toUpperCase()}
              onChange={(v) => setFilters((prev) => ({ ...prev, endAt: v }))}
              value={filters.endAt}
              disablePast={false}
              autoOk
              usFormat={usDateFormat()}
              bordered={isCoachView}
            />
          </Box>
          <LoaderSm loading={loading} />
          <Box ml={3}>
            <ButtonPrimary disabled={loading} onClick={() => search()}>
              <LoaderSm loading={loading} width={20} />
              <Search style={{ color: 'white' }} fontSize="small" />
              {t('button.search')}&nbsp;
            </ButtonPrimary>
          </Box>
          <Box ml={3} pt={5}>
            <ButtonPrimary
              disabled={loading}
              onClick={() => search({ page: 1, exportReport: true })}
            >
              <GetApp style={{ color: 'white' }} fontSize="small" />
              {t('button.exportCsv')}&nbsp;
            </ButtonPrimary>
          </Box>
        </Box>

        <Box mb={3} alignItems="center" justifyContent="center">
          <Typography>{t('channel.payroll.rateTypes')}</Typography>
          <Box display="flex" alignItems="center" justifyContent="center">
            {rateOptions.map((item) => (
              <Box key={item} mx={1}>
                <Checkbox
                  checked={checked(item)}
                  onClick={() => toggleType(item)}
                  label={t(`payroll.${item}`)}
                />
              </Box>
            ))}
          </Box>
        </Box>

        {!data && <SkeletonPayamentsReport />}

        {!!data && (
          <Box>
            {data?.byClass && (
              <ByClass byClass={data?.byClass} channel={channel} isCoachView={isCoachView} />
            )}

            {data?.byPercentMembership && (
              <Box mt={3}>
                <ByPercentMembership
                  byPercentMembership={data?.byPercentMembership}
                  channel={channel}
                />
              </Box>
            )}
            {data?.byHourly && (
              <Box mt={3}>
                <ByHourly byHourly={data?.byHourly} channel={channel} />
              </Box>
            )}

            {data?.byPercentProduct && (
              <Box mt={3}>
                <ByPercentProduct byPercentProduct={data?.byPercentProduct} channel={channel} />
              </Box>
            )}
            {data?.byCustomPayments && (
              <Box mt={3}>
                <ByCustomPayments byCustomPayments={data?.byCustomPayments} channel={channel} />
              </Box>
            )}
            {data?.calendarsRegistrations && (
              <Box mt={3}>
                <CalendarsRegistrations
                  calendarsRegistrations={data?.calendarsRegistrations}
                  channel={channel}
                />
              </Box>
            )}

            {data?.byMembershipBonus && (
              <Box mt={3}>
                <ByMembershipBonus byMembershipBonus={data?.byMembershipBonus} channel={channel} />
              </Box>
            )}
            {data && (
              <Box mt={3}>
                <Total data={data} channel={channel} refreshReport={search} />
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

CoachesReport.defaultProps = {
  getReport: () => {},
};

export default CoachesReport;
