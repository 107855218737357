import { all, call, put } from 'redux-saga/effects';

import { getErrorMessage } from 'core/api/api-error';

import notifications from 'modules/notifications';

import * as api from './api';
import { coachScheduleSessionsActions as actions } from './index';

export function* getUserScheduleSessions({ userId }) {
  try {
    const response = yield call(api.getUserScheduleSessions, userId);
    const channelLists = response.data;
    yield put(actions.getUserScheduleSessionsSuccess(channelLists));
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.getUserScheduleSessionsError(getErrorMessage(error))),
    ]);
  }
}

export function* getCoachScheduleSessions({ coachId }) {
  try {
    const response = yield call(api.getCoachScheduleSessions, coachId);
    const channelLists = response.data;
    yield put(actions.getCoachScheduleSessionsSuccess(channelLists));
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.getCoachScheduleSessionsError(getErrorMessage(error))),
    ]);
  }
}

export function* getScheduleSession({ sessionId }) {
  try {
    const response = yield call(api.getScheduleSession, sessionId);
    const channelLists = response.data;
    yield put(actions.getScheduleSessionSuccess(channelLists));
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.getScheduleSessionError(getErrorMessage(error))),
    ]);
  }
}
