import React, { useState } from 'react';
import Box from 'core/ui/Box';
import { Dialog, Slide } from '@material-ui/core';
import Texture from 'core/ui/Texture';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import Paper from 'core/ui/Paper';
import { useTranslation } from 'react-i18next';
import Typography from 'core/ui/Typography';
import Toggle from 'core/ui/Toggle';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import { repMaxTypes } from 'core/utils/consts';
import PrCalculatorList from './PrCalculatorList';
import BarbellPlatesCalculator from '../BarbellPlatesCalculator';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const ModalPrCalculator = ({ close, scores }) => {
  const [currentRepScore, setCurrentRepScore] = useState('repMax1');
  const [value, setValue] = useState(0);
  const [tab, setTab] = useState(0);
  const { t } = useTranslation();

  return (
    <Dialog maxWidth="sm" fullWidth open onClose={close} TransitionComponent={Transition}>
      <Texture style={{ minHeight: '100%' }}>
        <Box p={5}>
          <Typography align="center" variant="h5" color="primary" mb={3}>
            {t('personalRecord.calculator')}
          </Typography>

          <Box display="flex" justifyContent="center">
            <Toggle
              options={[
                { value: 0, label: t('calculator.type.weight'), width: 140 },
                { value: 1, label: t('calculator.type.barbell'), width: 140 },
              ]}
              onChange={(newTab) => setTab(newTab)}
              value={tab}
              color="white"
            />
          </Box>

          <Box mt={3}>
            {tab === 0 && (
              <>
                <Box mx={3}>
                  <Box mb={3}>
                    <SelectBoxObject
                      propValue="id"
                      propLabel="name"
                      label="&nbsp;"
                      options={[
                        ...repMaxTypes.map((x) => ({
                          id: x,
                          name: t(x),
                        })),
                        { id: 'weight', name: 'Weight' },
                      ]}
                      value={currentRepScore}
                      setValue={(v) => setCurrentRepScore(v)}
                    />
                  </Box>
                  {currentRepScore === 'weight' && (
                    <TextFieldRounded
                      type="number"
                      pattern="[0-9]*"
                      value={value || ''}
                      onChange={(v) => setValue(v)}
                      label={t('calculator.weight')}
                      placeholder={t('calculator.weight')}
                    />
                  )}
                </Box>
                <Paper className="paper-rounded" p={3} m={3}>
                  <PrCalculatorList
                    value={currentRepScore === 'weight' ? value : scores[currentRepScore]}
                  />
                </Paper>
              </>
            )}
            {tab === 1 && <BarbellPlatesCalculator />}
          </Box>
        </Box>
      </Texture>
    </Dialog>
  );
};

ModalPrCalculator.defaultProps = {
  close: () => {},
  callback: () => {},
};

export default ModalPrCalculator;
