import React from 'react';
import { AppBar, Dialog, DialogActions, Slide, Toolbar, makeStyles } from '@material-ui/core';
import Box from 'core/ui/Box';
import Texture from 'core/ui/Texture';
import Grid from 'core/ui/Grid';
import Button, { ButtonPrimary } from 'core/ui/Button';
import LoaderSm from 'core/ui/LoaderSm';
import { Send } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { secondaryBgModal, secondaryLight } from 'core/ui/Colors';

import Typography from 'core/ui/Typography';
import Scrollbars from 'react-custom-scrollbars';
import Divider from 'core/ui/Divider';
import UsersSelector from './UsersSelector';
import FormSend from './FormSend';
import useForm from './useForm';
import CompetitionsUsersSelector from './CompetitionsUsersSelector';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function ModalFormMessage({
  ownerableType,
  ownerableId,
  message,
  open,
  close,
  callback,
  automated,
  persistKey,
}) {
  const classes = useStyles();
  const textService = useForm({
    ownerableType,
    ownerableId,
    message,
    callback,
    automated,
    persistKey,
    close,
  });
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      onClose={() => textService.closeAndClearDraft()}
      style={{ marginTop: 100 }}
      fullScreen
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar} color="secondary">
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            {t('label.message')}
          </Typography>

          <Box display="flex" px={3} className="gap-10">
            <ButtonPrimary
              buttonColor={secondaryLight}
              onClick={() => {
                textService.closeAndClearDraft();
              }}
              color="primary"
            >
              Cancel
            </ButtonPrimary>
            <Divider orientation="vertical" m={2} style={{ backgroundColor: 'white' }} flexItem />
            <ButtonPrimary
              disabled={textService.loadingSend}
              onClick={() => textService.handleSubmit(textService.onSubmit)()}
            >
              {textService.loadingSend ? (
                <LoaderSm loading width={20} />
              ) : (
                <Send style={{ fontSize: 15 }} />
              )}
              &nbsp;{t(textService?.watch('scheduleSend') ? 'button.save' : 'button.send')}
            </ButtonPrimary>
          </Box>
        </Toolbar>
      </AppBar>

      <Scrollbars
        allowFullScreen
        hidden={false}
        autoHide={false}
        hideTracksWhenNotNeeded={false}
        universal
      >
        <Texture style={{ minHeight: '100%' }}>
          <Box px={5}>
            <Grid container spacing={5} p={5}>
              <Grid item xs={12} md={8}>
                <Dialog
                  open={textService.modalAlertPayment?.open}
                  onClose={() => textService.setModalAlertPayment({ open: false })}
                >
                  <Texture>
                    <Box p={5}>
                      <Typography>{t('channel.messages.overlimit')}</Typography>
                    </Box>
                  </Texture>
                  <DialogActions style={{ backgroundColor: secondaryBgModal }}>
                    {textService.loadingSend ? (
                      <LoaderSm loading />
                    ) : (
                      <Box display="flex" px={3}>
                        <Button onClick={() => textService.setModalAlertPayment({ open: false })}>
                          {t('button.cancel')}
                        </Button>
                        <Button
                          onClick={() =>
                            textService.handleSubmit(textService.onSubmitWithPayment)()
                          }
                        >
                          {t('button.confirm')}
                        </Button>
                      </Box>
                    )}
                  </DialogActions>
                </Dialog>
                <FormSend
                  callback={callback}
                  ownerableType={ownerableType}
                  ownerableId={ownerableId}
                  textService={textService}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                {ownerableType === 'Channel' && (
                  <UsersSelector channelId={ownerableId} textService={textService} />
                )}
                {ownerableType === 'Competition' && (
                  <CompetitionsUsersSelector textService={textService} />
                )}
              </Grid>
            </Grid>
          </Box>
        </Texture>
      </Scrollbars>
    </Dialog>
  );
}
