import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import { ButtonPrimary } from 'core/ui/Button';
import Paper from 'core/ui/Paper';
import TextAreaFieldRounded from 'core/ui/TextAreaFieldRounded';
import DatePickerRounded from 'core/ui/DatePickers/DatePickerRounded';
import LoaderSm from 'core/ui/LoaderSm';
import LimitRegistration from 'components/Workout/Form/ClassSettings/LimitRegistration';
import Checkbox from 'core/ui/Checkbox';
import Grid from 'core/ui/Grid';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import Typography from 'core/ui/Typography';
import CancellationPolicy from 'components/Workout/Form/ClassSettings/CancellationPolicy';
import CustomColors from 'components/Workout/Form/ClassSettings/CustomColors';
import SectionsSettings from 'components/Workout/Form/Section/SectionsSettings';
import WaitlistPolicy from 'components/Workout/Form/ClassSettings/WaitlistPolicy';
import { useSelector } from 'react-redux';
import { channelsSelectors } from 'modules/channels';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import InPersonFreeClass from 'components/Workout/Form/ClassSettings/InPersonFreeClass';
import Visibility from 'components/Workout/Form/ClassSettings/Visibility';
import useForm from './useForm';

const FormBulkEdit = ({ callbackSave, recurringsIds }) => {
  const { t } = useTranslation();
  const channel = useSelector((state) => channelsSelectors.getChannel(state));

  const { watch, setValue, errors, submitForm, loading, toggleField } = useForm({
    recurringsIds,
    callbackSave,
  });

  const optionsChange = [
    {
      label: t('workout.label.className'),
      field: 'name',
      registerFields: ['name'],
      type: 'text',
    },
    {
      label: t('channel.label.workoutsType'),
      field: 'channelsWorkoutsTypeId',
      registerFields: [['channelsWorkoutsTypeId', null]],
      component: (
        <Box>
          <Grid container spacing={5}>
            <Grid item xs={12} md={6}>
              <Paper p={3}>
                {channel?.workoutsTypes?.length > 0 && (
                  <SelectBoxObject
                    options={channel?.workoutsTypes?.filter((x) => !!x.active)}
                    label={t('channel.label.workoutsType').toUpperCase()}
                    value={watch('channelsWorkoutsTypeId')}
                    setValue={(v) => setValue('channelsWorkoutsTypeId', v)}
                    bordered
                  />
                )}
              </Paper>
            </Grid>
          </Grid>
        </Box>
      ),
    },
    {
      label: t('workout.label.visibility'),
      field: 'isPrivate',
      registerFields: [['isPrivate', false]],
      component: (
        <Box>
          <Grid container spacing={5}>
            <Grid item xs={12} md={6}>
              <Paper p={3}>
                <Visibility setValue={setValue} watch={watch} />
              </Paper>
            </Grid>
          </Grid>
        </Box>
      ),
    },
    {
      label: t('workout.label.description'),
      field: 'description',
      registerFields: ['description'],
      type: 'textarea',
    },
    {
      label: t('workout.label.limitRegistration'),
      field: 'limitRegistration',
      registerFields: [
        ['limitRegistration.hasInPersonLimit', false],
        'limitRegistration.numberInPerson',
        'limitRegistration.numberInPersonAfter',
        'limitRegistration.typeInPerson',
        'limitRegistration.typeInPersonAfter',
      ],
      component: (
        <Box>
          <Grid container spacing={5}>
            <Grid item xs={12} md={6}>
              <Paper p={3}>
                <Typography>{t('button.inPerson')}</Typography>
                <LimitRegistration
                  fieldNumber="limitRegistration.numberInPerson"
                  fieldRadio="limitRegistration.hasInPersonLimit"
                  fieldType="limitRegistration.typeInPerson"
                  watch={watch}
                  setValue={setValue}
                  bordered
                />
              </Paper>
            </Grid>
          </Grid>
        </Box>
      ),
    },
    {
      label: t('workout.label.maxUsers'),
      field: 'inPersonMaxUsers',
      registerFields: ['inPersonMaxUsers'],
      component: (
        <Box>
          <Grid container spacing={5}>
            <Grid item xs={12} md={6}>
              <Paper p={3}>
                <Typography mb={2}>{t('button.inPerson')}</Typography>
                <TextFieldRounded
                  type="number"
                  pattern="[0-9]*"
                  error={errors.inPersonMaxUsers}
                  placeholder={t('workout.label.maxUsers')}
                  label={t('workout.label.maxUsers').toUpperCase()}
                  value={watch('inPersonMaxUsers')}
                  onChange={(v) => setValue('inPersonMaxUsers', v)}
                  bordered
                />
              </Paper>
            </Grid>
          </Grid>
        </Box>
      ),
    },
    {
      label: t('workout.label.freeClass'),
      field: 'freeInPersonOption',
      registerFields: [['freeInPersonOption', 'no']],
      component: (
        <Box>
          <Grid container spacing={5}>
            <Grid item xs={12} md={6}>
              <Paper p={3}>
                <Typography mb={2}>{t('button.inPerson')}</Typography>

                <InPersonFreeClass watch={watch} setValue={setValue} />
              </Paper>
            </Grid>
          </Grid>
        </Box>
      ),
    },

    {
      label: t('workout.label.cancellationPolicy'),
      field: 'cancellationPolicy',
      registerFields: [
        ['cancellationPolicies.active', false],
        'cancellationPolicies.cancelClassNumber',
        'cancellationPolicies.cancelClassNumberType',
        'minRegisteredPeoplePolicy',

        'cancellationPolicies.cancelRegistrationLimitNumber',
        'cancellationPolicies.cancelRegistrationLimitNumberType',

        'cancellationPolicies.cancelRegistrationLimitLate',
        'cancellationPolicies.cancelRegistrationLimitLateAmount',
      ],
      component: (
        <Box>
          <Grid container spacing={5}>
            <Grid item xs={12} md={6}>
              <Paper p={3}>
                <CancellationPolicy watch={watch} setValue={setValue} bordered />
              </Paper>
            </Grid>
          </Grid>
        </Box>
      ),
    },
    {
      label: t('workout.label.calendarColor'),
      field: 'calendarColor',
      registerFields: ['calendarColor', 'useCalendarColor'],
      component: (
        <Box>
          <Grid container spacing={5}>
            <Grid item xs={12} md={6}>
              <Paper p={3}>
                <CustomColors watch={watch} setValue={setValue} bordered />
              </Paper>
            </Grid>
          </Grid>
        </Box>
      ),
    },
    {
      label: t('workout.settings.sections'),
      field: 'sectionsSettings',
      registerFields: [
        ['hideSections', false],
        ['allowPublicPostScores', false],
        ['hideOnCalendarNonMembers', false],
        ['hideSectionsConfig.active', false],
        'hideSectionsConfig.number',
        'hideSectionsConfig.timeType',
        'hideSectionsConfig.setTimeHour',
        'hideSectionsConfig.setTime',
      ],
      component: (
        <Box>
          <Grid container spacing={5}>
            <Grid item xs={12} md={6}>
              <Paper p={3}>
                <SectionsSettings watch={watch} setValue={setValue} bordered />
              </Paper>
            </Grid>
          </Grid>
        </Box>
      ),
    },
    {
      label: t('workout.label.waitlistPolicy'),
      field: 'waitlistPolicy',
      registerFields: [['waitlistPolicy', false], ['waitlistPolicyPercent']],
      component: (
        <Box>
          <Grid container spacing={5}>
            <Grid item xs={12} md={6}>
              <Paper p={3}>
                <WaitlistPolicy watch={watch} setValue={setValue} />
              </Paper>
            </Grid>
          </Grid>
        </Box>
      ),
    },
  ];

  return (
    <Box p={5}>
      <Paper p={3} m={1}>
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Typography variant="body" mb={2}>
            {t('workouts.label.initialDate')}
          </Typography>
          <Box mb={3}>
            <DatePickerRounded
              onChange={(v) => setValue('updateStartDate', v)}
              value={watch('updateStartDate')}
              usFormat={channel?.useUsDateFormat}
              disablePast={false}
              autoOk
              error={errors?.updateStartDate}
              bordered
            />
          </Box>
          <Box>
            <Checkbox
              label={t('recurring.bulkUpdate.templates')}
              onClick={() => setValue('updateTemplates', !watch('updateTemplates'))}
              checked={watch('updateTemplates')}
            />
          </Box>
        </Box>
      </Paper>

      <Typography variant="h3" m={3}>
        {t('workout.bulk.edit.fields')}
      </Typography>

      <Box mb={3}>
        {optionsChange?.map((item) => {
          const checked = watch('selectedFields')?.find((x) => x === item?.field);
          return (
            <Box key={item?.label} mb={2}>
              <Checkbox label={item?.label} onClick={() => toggleField(item)} checked={checked} />
              {checked && (
                <>
                  {item.type === 'text' && (
                    <TextFieldRounded
                      value={watch(item.field)}
                      label={item?.label}
                      onChange={(v) => setValue(item.field, v)}
                    />
                  )}
                  {item.type === 'textarea' && (
                    <TextAreaFieldRounded
                      value={watch(item.field)}
                      label={item?.label}
                      onChange={(v) => setValue(item.field, v)}
                    />
                  )}
                  <Box mt={2}>{item?.component}</Box>
                </>
              )}
            </Box>
          );
        })}
      </Box>

      <Box display="flex" justifyContent="flex-end" mt={3}>
        <ButtonPrimary loading={loading} onClick={() => submitForm()}>
          <LoaderSm loading={loading} />
          {t('button.save').toUpperCase()}
        </ButtonPrimary>
      </Box>
    </Box>
  );
};

FormBulkEdit.defaultProps = {
  close: () => {},
};

export default FormBulkEdit;
