import newApp from './app/new';
import channelTemplates from './channelTemplates';
import channelManage from './channelManage';
import channelAnnouncements from './channelAnnouncements';
import userHistory from './userHistory';
import userChildren from './userChildren';
import workoutsUsers from './workoutsUsers';
import workoutWaitlists from './workoutWaitlists';
import personalRecordMovements from './personalRecordMovements';
import benchmarkSections from './benchmarkSections';
import ownerableMessages from './ownerableMessages';
import newWorkouts from './workouts/new';
import channelPrograms from './channelPrograms';
import usersWorkouts from './usersWorkouts';
import newPayments from './payments/new';
import channelUserCredits from './channelUserCredits';
import channelReports from './channelReports';
import ownerablePaymentMethods from './ownerablePaymentMethods';
import mediaFilesAssociations from './mediaFilesAssociations';
import storeOptions from './storeOptions';
import storeCategories from './storeCategories';
import storeDiscountCodes from './storeDiscountCodes';
import storeProducts from './storeProducts';
import storeOrders from './storeOrders';
import partners from './partners';
import channelsContracts from './channelsContracts/index';
import partnersPrograms from './partnersPrograms';
import partnersProgramsChannels from './partnersProgramsChannels';
import newChannels from './channels/new';
import likes from './likes';
import comments from './comments';
import newChannelMembers from './channelMembers/new';
import usersImports from './usersImports';
import countries from './countries';
import userHousehold from './userHousehold';
import newRecurrings from './recurrings/new';
import channelsCoachesRates from './channelsCoachesRates';
import integrations from './integrations';
import channelUsers from './channelUsers';
import plans from './plans';
import adminReports from './adminReports';
import newCompetitions from './competitions/new';
import newWorkoutHeats from './workoutHeats/new';
import adminChannels from './admin/channels/index';
import workoutsInvites from './workoutsInvites';
import storeShopify from './storeShopify';
import channelsCoachesSchedules from './channelsCoachesSchedules';
import usersContracts from './usersContracts';
import storeTaxJar from './storeTaxJar';
import channelsRatesTemplates from './channelsRatesTemplates';
import channelsCoachesClocks from './channelsCoachesClocks';
import usersDocuments from './usersDocuments';
import usersAddresses from './usersAddresses';
import usersMessages from './usersMessages';
import channelsDenylists from './channelsDenylists';
import channelsPayouts from './channelsPayouts';
import newSearch from './search/new';
import workoutRecordings from './workoutRecordings';
import usersHistoryScores from './usersHistoryScores';
import documents from './documents';
import channelsTags from './channelsTags';
import channelCalendars from './channelCalendars';
import newMovements from './movements/new';
import groupsChats from './groupsChats';
import groupsChatsUsers from './groupsChatsUsers';
import channelsCustomPayments from './channelsCustomPayments';
import channelsCustomPaymentsUsers from './channelsCustomPaymentsUsers';
import channelWorkoutsPrograms from './channelWorkoutsPrograms';
import ownersPaymentsFees from './ownersPaymentsFees';
import userPaymentMethods from './userPaymentMethods';
import channelDashboard from './channelDashboard';
import competitionsUsers from './competitionsUsers';
import conversations from './conversations';
import competitionsCalendars from './competitionsCalendars';
import competitionsInvites from './competitionsInvites';
import channelsCoachesPayrollDeductions from './channelsCoachesPayrollDeductions';
import openAI from './openAI/index';
import automationTypes from './automationTypes';
import automationWorkflows from './automationWorkflows';
import automationWorkflowsTypes from './automationWorkflowsTypes';
import helpVideo from './helpVideo';
import helpVideoMap from './helpVideoMap';
import contactForms from './contactForms';
import channelsUsersImport from './channelsUsersImport';
import channelsStages from './channelsStages';
import channelsStagesUsers from './channelsStagesUsers';
import usersCalendars from './usersCalendars';
import usersCalendarsWeeklyHours from './usersCalendarsWeeklyHours/index';
import usersUnavailableTimes from './usersUnavailableTimes';
import usersCalendarsRegistrations from './usersCalendarsRegistrations';
import competitionsWaitlists from './competitionsWaitlists';
import channelKeys from './channelKeys';
import channelsEvents from './channelsEvents';
import channelsEventsRegistrations from './channelsEventsRegistrations';
import competitionsCustomScorings from './competitionsCustomScorings';

const Modules = {
  newApp,
  channelTemplates,
  channelManage,
  channelAnnouncements,
  userHistory,
  userChildren,
  workoutsUsers,
  personalRecordMovements,
  benchmarkSections,
  ownerableMessages,
  newWorkouts,
  workoutWaitlists,
  channelPrograms,
  usersWorkouts,
  newPayments,
  channelUserCredits,
  channelReports,
  ownerablePaymentMethods,
  mediaFilesAssociations,
  storeOptions,
  storeCategories,
  storeDiscountCodes,
  storeProducts,
  storeOrders,
  partners,
  channelsContracts,
  partnersPrograms,
  partnersProgramsChannels,
  newChannels,
  likes,
  comments,
  newChannelMembers,
  usersImports,
  countries,
  userHousehold,
  newRecurrings,
  channelsCoachesRates,
  integrations,
  channelUsers,
  plans,
  adminReports,
  newCompetitions,
  newWorkoutHeats,

  adminChannels,
  workoutsInvites,
  storeShopify,
  channelsCoachesSchedules,
  usersContracts,
  storeTaxJar,
  channelsRatesTemplates,
  channelsCoachesClocks,
  usersDocuments,
  usersAddresses,
  usersMessages,
  channelsDenylists,
  channelsPayouts,
  newSearch,
  workoutRecordings,
  usersHistoryScores,
  documents,
  channelsTags,
  channelCalendars,
  newMovements,
  groupsChats,
  groupsChatsUsers,
  channelsCustomPayments,
  channelsCustomPaymentsUsers,
  openAI,
  channelWorkoutsPrograms,
  ownersPaymentsFees,
  userPaymentMethods,
  channelDashboard,
  competitionsUsers,
  conversations,
  competitionsCalendars,
  competitionsInvites,
  channelsCoachesPayrollDeductions,
  automationTypes,
  automationWorkflows,
  helpVideo,
  helpVideoMap,
  automationWorkflowsTypes,
  contactForms,
  channelsUsersImport,
  channelsStages,
  channelsStagesUsers,
  usersCalendars,
  usersCalendarsWeeklyHours,
  usersUnavailableTimes,
  usersCalendarsRegistrations,
  competitionsWaitlists,
  channelKeys,
  channelsEvents,
  channelsEventsRegistrations,
  competitionsCustomScorings,
};

export default Modules;
