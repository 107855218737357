import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import Typography from 'core/ui/Typography';
import { useSelector, useDispatch } from 'react-redux';
import { workoutsActions } from 'modules/workouts';
import { timeZoneName } from 'core/utils/formatters/date';
import { ButtonPrimary } from 'core/ui/Button';
import LoaderSm from 'core/ui/LoaderSm';
import { useHistory } from 'react-router';
import ChannelContracts from 'components/Channel/ChannelContracts';
import { sessionSelectors } from 'modules/session';
import { Check } from '@material-ui/icons';
import Paper from 'core/ui/Paper';
import ButtonsRegister from './ButtonsRegister';
import ChannelVideoRequire from './ChannelVideoRequire';
import ModalWaitlist from './ModalWaitlist';
import UserChannelKeys from './UserChannelKeys';
import useRegisterStatus from './useRegisterStatus';

export default function Resume({ workout, close, confirmCallback }) {
  const { t } = useTranslation();
  const history = useHistory();
  const user = useSelector((state) => sessionSelectors.getUser(state));
  const [success, setSuccess] = useState();
  const [waiverAccepted, setWaiverAccepted] = useState();
  const [videosAccepted, setVideosAccepted] = useState();
  const [modalWaitList, setModalWaitList] = useState({ open: false, notified: false });

  const { getRegisterStatus, clearRegisterStatus, registerStatus, loading } = useRegisterStatus({
    workout,
  });

  useEffect(() => {
    if (user && user.id) {
      getRegisterStatus(user);
    } else {
      clearRegisterStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workout, user]);

  const dispatch = useDispatch();

  const hasKeyOrList =
    !!registerStatus?.hasOnlineKey || !!registerStatus?.hasInPersonKey || !!registerStatus?.hasList;

  const purchase = (type, workoutId) => {
    const confirm = () => {
      dispatch(
        workoutsActions.purchaseStart(
          {
            workoutId,
            timezone: timeZoneName(),
            type,
            // messageSuccess: t('purchase.workout.success'),
          },
          () => {
            dispatch(workoutsActions.getWorkoutStart(workout.id));
            setSuccess(true);
            if (confirmCallback) {
              confirmCallback();
            }
            // close();
          },
          (error) => {
            if (error && error.indexOf('The class limit has been reached') > -1) {
              setModalWaitList({ open: true, notified: false });
            }
            if (error && error.indexOf('someone already grabbed') > -1) {
              setModalWaitList({ open: true, notified: true });
            }
          },
        ),
      );
    };

    confirm();
  };

  if (!waiverAccepted && workout && workout.channel && workout.channel.documentWaiverId) {
    return (
      <ChannelContracts
        channel={workout?.channel}
        userId={user.id}
        ownerableType="Channel"
        ownerableId={workout.channel?.id}
        kind="waivers"
        setAccepted={setWaiverAccepted}
        documentId={workout.channel.documentWaiverId}
        alwaysOpen
      />
    );
  }

  if (
    !videosAccepted &&
    workout &&
    workout.channel &&
    (workout.channel.requiredVideoAthlete || workout.channel.requiredVideoCoach)
  ) {
    return (
      <ChannelVideoRequire
        close={() => close(false)}
        channel={workout.channel}
        userId={user.id}
        setVideosAccepted={setVideosAccepted}
      />
    );
  }

  if (loading) {
    return (
      <Box display="flex" justifyContent="center">
        <LoaderSm loading />
      </Box>
    );
  }

  if (success) {
    return (
      <div>
        <Paper p={2}>
          <div className="tw-flex tw-flex-col tw-items-center tw-justify-center">
            <Check className="tw-text-[50px] tw-text-green-400" />
            <Typography align="center" className="tw-text-lg tw-text-green-600" p={2}>
              <div>{t('purchase.workout.success')}</div>
            </Typography>
          </div>
        </Paper>

        <div className="tw-mt-4 tw-flex tw-flex-row tw-items-center tw-justify-end">
          <ButtonPrimary onClick={() => close()}>{t('button.close')}</ButtonPrimary>
        </div>
      </div>
    );
  }

  return (
    <>
      <ModalWaitlist
        close={() => setModalWaitList({ open: false })}
        open={modalWaitList.open}
        notified={modalWaitList.notified}
        closePurchase={close}
      />
      {hasKeyOrList ? (
        <>
          <Typography mb={3} align="center" color="primary" variant="h4">
            {t('workout.register.title')}
          </Typography>

          <Box px={10}>
            <Box display="flex" flexDirection="column">
              <ButtonsRegister
                purchase={purchase}
                workout={workout}
                registerStatus={registerStatus}
              />
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Typography align="center" variant="h3" m={8}>
            {t('workout.register.msg')}
          </Typography>

          <ButtonPrimary
            onClick={() => {
              close();
              history.push(`/channel/view/${workout?.channel?.id}/memberships`);
            }}
            buttonColor={workout?.channel?.customColors?.buttonColor}
            fullWidth
          >
            {t('button.memberships')}
          </ButtonPrimary>
        </>
      )}

      <Box>
        <UserChannelKeys keys={registerStatus?.channelKeys || []} workout={workout} />
      </Box>
    </>
  );
}

Resume.propTypes = {
  close: propTypes.func,
};

Resume.defaultProps = {
  close: null,
};
