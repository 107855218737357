import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import Card from 'core/ui/Card';
import { primary } from 'core/ui/Colors';
import { Tag } from 'core/ui/ChosenComponents';
import Paper from 'core/ui/Paper';
import Typography from 'core/ui/Typography';
import SelectorChannelUser from 'components/Channel/SelectorChannelUser';

export default ({ channelId, textService }) => {
  const { t } = useTranslation();

  const selectedUsers = textService.watch('customContacts') || [];
  const onSelect = (user) => {
    textService.setValue('customContacts', [...selectedUsers, user]);
  };

  const deleteContact = (u) => {
    textService.setValue(
      'customContacts',
      selectedUsers.filter((x) => x.id !== u.id),
    );
  };

  return (
    <Card
      btopcolor={primary}
      btopwidth={2}
      hidedivider="true"
      title={t('channel.message.customUsers')}
    >
      <Box
        px={3}
        py={1}
        pb={3}
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
      >
        <Box px={3} style={{ width: '100%' }}>
          <Paper p={3} elevation={4} className="paper-rounded" mb={2}>
            <Box display="flex" flexWrap="wrap">
              {selectedUsers && selectedUsers.length > 0 ? (
                <>
                  {selectedUsers.map((u) => (
                    <Tag label={u.name} key={u.id} onDelete={() => deleteContact(u)} />
                  ))}
                </>
              ) : (
                <Typography align="center">{t('channel.message.addCustomUsers')}</Typography>
              )}
            </Box>
          </Paper>
        </Box>

        <SelectorChannelUser onSelectedUser={(u) => onSelect(u)} channelId={channelId} />
      </Box>
    </Card>
  );
};
