import React, { useState } from 'react';
import { KeyboardArrowDown, KeyboardArrowRight } from '@material-ui/icons';
import Box from 'core/ui/Box';
import Typography from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import { Collapse } from '@material-ui/core';
import ClassHistory from 'components/Channel/Manage/Tabs/Library/ClassHistory';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import uuid from 'react-uuid';

const HistoryOptions = ({
  setSections,
  chooseSection,
  watch,
  setValue,
  channel,
  isCompetition,
}) => {
  const { t } = useTranslation();
  const { request } = useActions();
  const [opened, setOpened] = useState(false);

  const confirm = (workoutBase) => {
    if (chooseSection) {
      chooseSection(
        workoutBase?.sections?.map((x) => ({
          ...x,
          new: true,
          id: null,
          slug: null,
        })),
      );
      setOpened(false);
      return;
    }

    const movements = watch('movements') || [];
    const equipment = watch('movements') || [];

    if (isCompetition) {
      setSections(
        workoutBase?.sections?.map((x) => ({
          ...x,
          new: true,
          id: null,
        })),
      );
    } else {
      setSections((prev) => [
        ...prev,
        ...workoutBase?.sections?.map((x) => ({
          ...x,
          new: true,
          id: uuid(),
        })),
      ]);
    }

    if (workoutBase.movements && workoutBase.movements.length > 0) {
      const movs = workoutBase.movements.map((x) => ({ name: x }));
      setValue('movements', [...movements, ...movs]);
    }
    if (workoutBase.equipment && workoutBase.equipment.length > 0) {
      const eqps = workoutBase.equipment.map((x) => ({ name: x }));
      setValue('equipment', [...equipment, ...eqps]);
    }

    setOpened(false);
  };

  const onSelectWorkout = (workout) => {
    request({
      action: Modules.newWorkouts.actions.getWorkout,
      data: { id: workout?.id },
      options: {
        onSuccess: (w) => {
          confirm(w);
        },
      },
    });
  };

  return (
    <>
      <Box className="hover" display="flex" alignItems="center">
        <Box onClick={() => setOpened(!opened)} flexGrow="1" display="flex">
          <Box flexGrow={1}>
            <Typography color="primary" variant="h5">
              {t('workout.section.useWorkoutsHistory')}
            </Typography>
          </Box>
          {opened === true ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
        </Box>
      </Box>
      <Collapse in={opened}>
        <Box py={2}>
          {opened && <ClassHistory fromClassForm channel={channel} setWorkout={onSelectWorkout} />}
        </Box>
      </Collapse>
    </>
  );
};

export default HistoryOptions;
