/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import Paper from 'core/ui/Paper';
import Typography from 'core/ui/Typography';
import CollapseText from 'core/ui/CollapseText';
import { OpenWith } from '@material-ui/icons';
import { Collapse, Dialog, IconButton } from '@material-ui/core';
import { primary, secondary } from 'core/ui/Colors';
import SectionPersonalRecords from 'components/Workout/Show/Tabs/Overview/SectionPersonalRecords';

import SectionTimerInfo from 'components/Workout/Form/Section/SectionTimerInfo';
import { listScores } from 'core/utils/helpers';
import ViewSectionScoresTypes from 'components/Workout/Form/Section/ViewSectionScoresTypes';
import ExpandedSection from 'components/Workout/Show/Tabs/Overview/Sections/Expanded';
import { Transition } from 'react-transition-group';
import Grid from 'core/ui/Grid';
import TimerWorkout from 'components/Workout/Timer/index';
import { ButtonPrimary } from 'core/ui/Button';
import Leaderboard from '../Tabs/Overview/Leaderboard';

const WorkoutDetailSections = ({
  opened,
  setCurrentOpened,
  workout,
  sectionId,
  setModalScore,
  addPrScore,
  hideTimer,
}) => {
  const { t } = useTranslation();
  const section = workout.sections.find((x) => x.id === Number(sectionId));
  const [modelSection, setModalSection] = useState({ open: false });

  if (!section) {
    return <></>;
  }

  const expandSection = (sec) => {
    setModalSection({ open: !!sec, section: sec });
  };

  const showLeaderboard = !!listScores(section, t) && listScores(section, t) !== 'For Completion';

  return (
    <Box>
      {modelSection.section && (
        <Dialog
          style={{ marginTop: 80 }}
          fullScreen
          open={modelSection.open}
          onClose={() => setModalSection({ open: false })}
          TransitionComponent={Transition}
          PaperProps={{ style: { borderTop: `3px solid ${primary}`, borderRadius: 10 } }}
        >
          <ExpandedSection
            sections={workout.sections.map((sec, index) => ({ ...sec, index }))}
            hideSections={false}
            // onLeaderboardClick={() => onLeaderboardClick(modelSection.section)}
            selectTab={null}
            expandSection={expandSection}
            section={modelSection.section}
            showLeaderboard={false}
          />
        </Dialog>
      )}
      <Paper className="paper-rounded" p={3}>
        <Box display="flex">
          <Box flexGrow={1}>
            <Typography>{section.title}</Typography>

            <Box display="flex" flexWrap="wrap" flexDirection="column">
              <Grid container>
                <Grid item xs={12} md={6}>
                  <Typography color="secondary" variant="subtitle2" component="h1">
                    {t('description')}
                  </Typography>

                  <SectionPersonalRecords section={section} />
                  <CollapseText text={section.description} maxChar={300} />

                  {listScores(section, t) && (
                    <Box mx={3} my={1}>
                      <ViewSectionScoresTypes
                        scores={section?.scores}
                        scoresFontColor={secondary}
                      />
                    </Box>
                  )}

                  <SectionTimerInfo section={section} showTimer={false} />
                </Grid>
                {!hideTimer && section?.timerData?.type && section?.timerData?.type !== 'none' && (
                  <Grid item xs={12} md={6}>
                    <Box mt={5} display="flex" justifyContent="center">
                      <TimerWorkout section={section} preview colorLabel="white" />
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Box>
        </Box>
        <Box display="flex" mt={3}>
          <Box display="flex" flexGrow={1} mt={3} justifyContent="center">
            {showLeaderboard && (
              <Box mr={3}>
                <ButtonPrimary onClick={() => setCurrentOpened(opened ? null : section.id)}>
                  {t('button.leaderboard')}
                </ButtonPrimary>
              </Box>
            )}
            {showLeaderboard && (workout.isOwner || workout.registered) && (
              <Box display="flex">
                <Box mr={3}>
                  <ButtonPrimary onClick={() => addPrScore()}>
                    {t('button.addPr').toUpperCase()}
                  </ButtonPrimary>
                </Box>
                <ButtonPrimary
                  onClick={() => setModalScore({ open: true, sectionData: section, workout })}
                >
                  {t('button.enterScores')}
                </ButtonPrimary>
              </Box>
            )}
          </Box>
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <IconButton onClick={() => expandSection(section)}>
              <OpenWith style={{ fontSize: 24, color: secondary }} />
            </IconButton>
          </Box>
        </Box>
      </Paper>
      <Collapse in={opened}>
        <Box m={3}>
          {opened && <Leaderboard workout={workout} section={section} hideComments singleView />}
        </Box>
      </Collapse>
    </Box>
  );
};

export default WorkoutDetailSections;
