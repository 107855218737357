/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useState } from 'react';
import Box from 'core/ui/Box';
import { Checkbox } from '@material-ui/core';

import ImageMedia from 'components/Profile/ImageMedia';
import Typography from 'core/ui/Typography';
import InstagramLink from 'components/Profile/InstagramLink';
import Paper from 'core/ui/Paper';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import { WorkoutContext } from '.';

const WorkoutFormCoaches = () => {
  const formContext = useContext(WorkoutContext);
  const [channelCoaches, setChannelCoaches] = useState([]);

  const { request } = useActions();

  const checked = (id) => {
    const arrayFilters = formContext.watch('coaches') ? [...formContext.watch('coaches')] : [];
    return arrayFilters.filter((x) => x === id).length > 0;
  };

  const change = (id) => {
    let arrayFilters = formContext.watch('coaches') ? [...formContext.watch('coaches')] : [];

    if (checked(id)) {
      arrayFilters = arrayFilters.filter((x) => x !== id);
    } else {
      arrayFilters.push(id);
    }
    formContext.setValue('coaches', arrayFilters);
  };

  useEffect(() => {
    if (formContext.selectedChannel) {
      request({
        action: Modules.newChannels.actions.getChannelCoaches,
        data: {
          channelId: formContext.selectedChannel?.id,
        },
        options: {
          onSuccess: setChannelCoaches,
        },
      });
    }
  }, [formContext.selectedChannel]); //eslint-disable-line

  return (
    <Box display="flex" flexWrap="wrap">
      {channelCoaches
        .filter((x) => !!x?.user?.id && !!x?.user?.name)
        .filter((x) =>
          ['admin_coach', 'coach_head', 'coach_owner', 'coach'].find((y) => y === x.accessType),
        )
        .map((coach, index) => (
          <Box m={1} key={index.toString()}>
            <Paper
              py={2}
              pr={3}
              style={{ minWidth: 100 }}
              className="paper-rounded hover"
              onClick={() => change(coach.user.id)}
            >
              <Box display="flex">
                <Checkbox checked={checked(coach?.user?.id)} size="medium" color="primary" />

                <Box display="flex">
                  <Box style={{ position: 'relative' }}>
                    <ImageMedia
                      user={coach?.user}
                      image={coach?.user?.image ? coach?.user?.image : {}}
                      size={40}
                      showCountry={false}
                    />
                  </Box>

                  <Box ml={2} flexGrow="1">
                    <Typography color="primary" variant="caption" component="p">
                      {coach?.user.firstName}
                    </Typography>
                    <InstagramLink instagram={coach?.user.instagram} />
                  </Box>
                </Box>
              </Box>
            </Paper>
          </Box>
        ))}
    </Box>
  );
};

export default WorkoutFormCoaches;
