import React, { useEffect } from 'react';
import Typography from 'core/ui/Typography';
import { ownerableMessagesModule } from 'modules/ownerableMessages';
import { useTranslation } from 'react-i18next';
import Paper from 'core/ui/Paper';
import { Refresh } from '@material-ui/icons';
import Button from 'core/ui/Button';
import LoaderSm from 'core/ui/LoaderSm';
import Box from 'core/ui/Box';
import useSelectors from 'modules/map/useSelectors';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import useConfirmation from 'core/useConfirmation';
import TableMessages from './Table';
import TableMessageTemplates from './TableMessagesTemplates';

const Messages = ({ ownerableId, ownerableType, template, copyMessage, editMessage }) => {
  const { request } = useActions();
  const { confirmation } = useConfirmation();
  const { t } = useTranslation();
  const data = useSelectors(ownerableMessagesModule, 'data');
  const loading = useSelectors(ownerableMessagesModule, 'loading');

  useEffect(() => {
    if (ownerableId) {
      request({
        action: Modules.ownerableMessages.actions.getMessages,
        data: { ownerableId, ownerableType, template },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ownerableId, ownerableType]);

  const refresh = () => {
    request({
      action: Modules.ownerableMessages.actions.getMessages,
      data: { ownerableId, ownerableType, template },
    });
  };

  const removeMessage = (message) => {
    confirmation({
      description: t('message.confirm.delete'),
      yesClick: () => {
        request({
          action: Modules.ownerableMessages.actions.inactiveMessage,
          data: { ownerableId, ownerableType, messageId: message?.id },
          options: {
            onSuccess: () => {
              refresh();
            },
          },
        });
      },
    });
  };

  return (
    <Paper p={3} className="paper-rounded">
      <Box display="flex" alignItems="center">
        <Box display="flex" alignItems="center" flexGrow={1}>
          <Typography variant="h4">
            {t(template ? 'channel.automated.templates' : 'admin.sentMessages')}
          </Typography>
          {loading && <LoaderSm loading />}
        </Box>
        <Button onClick={() => refresh()}>
          <Refresh />
        </Button>
      </Box>
      {template ? (
        <TableMessageTemplates
          editMessage={editMessage}
          messages={data}
          copyMessage={copyMessage}
        />
      ) : (
        <TableMessages
          removeMessage={removeMessage}
          ownerableId={ownerableId}
          ownerableType={ownerableType}
          messages={data}
          copyMessage={copyMessage}
          editMessage={editMessage}
        />
      )}
    </Paper>
  );
};

export default Messages;
