/* eslint-disable react/prop-types */
import React from 'react';
import Paper from 'core/ui/Paper';
import Typography from 'core/ui/Typography';
import SFDataTable from 'core/ui/DataTable';
import { channelCurrency, date } from 'core/utils/formatters';
import Box from 'core/ui/Box';
import { secondaryBgLight } from 'core/ui/Colors';

export default function ByCustomPaymentsData({ title, channel, byCustomPayments, isCoachView }) {
  const columns = [
    {
      name: 'Coach',
      selector: 'name',
      sortable: true,
      cell: (row) => <Typography>{row?.user?.name}</Typography>,
    },
    {
      name: 'Total Amount',
      selector: 'total',
      sortable: true,
      cell: (row) => (
        <Typography noWrap>{channelCurrency(row?.total, channel?.currency)}</Typography>
      ),
    },
  ];
  const columnsClocks = [
    {
      name: 'Custom Payment',
      selector: (row) => row?.channelsCustomPayment?.name,
      sortable: true,
      cell: (row) => <Typography>{row?.payment?.channelsCustomPayment?.name}</Typography>,
    },
    {
      name: 'Description',
      selector: 'description',
      sortable: true,
      cell: (row) => <Typography>{row?.payment?.description}</Typography>,
    },
    {
      name: 'Date',
      selector: 'date',
      sortable: true,
      cell: (row) => <Typography>{date(row?.payment?.date)}</Typography>,
    },
    {
      name: 'Amount',
      selector: 'amount',
      sortable: true,
      cell: (row) => (
        <Typography noWrap>{channelCurrency(row?.payment?.amount, channel?.currency)}</Typography>
      ),
    },
  ];

  const ExpanableComponent = ({ data: dataExpanded }) => (
    <Box p={5}>
      <SFDataTable noHeader columns={columnsClocks} data={dataExpanded?.payments} />
    </Box>
  );

  return (
    <Paper
      style={{ width: '100%', border: isCoachView ? `solid 1px ${secondaryBgLight}` : 'none' }}
      py={2}
    >
      <SFDataTable
        title={
          <Box>
            <Typography variant="h5">{title}</Typography>
          </Box>
        }
        columns={columns}
        data={byCustomPayments?.coaches}
        expandableRows
        expandableRowsComponent={<ExpanableComponent />}
        showPaper={false}
      />
      <Box display="flex" justifyContent="flex-end">
        <Paper p={3} mt={3}>
          <Typography variant="h4">
            Total:&nbsp;{channelCurrency(byCustomPayments?.total, channel?.currency)}
          </Typography>
        </Paper>
      </Box>
    </Paper>
  );
}
