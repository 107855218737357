import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import Typography from 'core/ui/Typography';
import { channelUserCreditsModule } from 'modules/channelUserCredits';
import useActions from 'modules/map/useActions';
import useSelectors from 'modules/map/useSelectors';
import Modules from 'modules';
import LoaderSm from 'core/ui/LoaderSm';
import SFDataTable from 'core/ui/DataTable';
import { currency, dateExt } from 'core/utils/formatters';
import Paper from 'core/ui/Paper';
import { successColor } from 'core/ui/Colors';
import ModalFormMovement from './ModalFormMovement';

export default function MemberCredits({ channel, userId, openModalMovement, closeModalMovement }) {
  const { t } = useTranslation();
  const { request } = useActions();
  const loading = useSelectors(channelUserCreditsModule, 'loading');
  const userCredit = useSelectors(channelUserCreditsModule, 'userCredit');

  useEffect(() => {
    request({
      action: Modules.channelUserCredits.actions.getUserCredit,
      data: { channelId: channel?.id, userId },
    });
  }, [request, channel, userId]);

  const columns = [
    {
      name: t('description'),
      selector: 'description',
      sortable: true,
      cell: (movement) => <Typography>{movement.description}</Typography>,
    },
    {
      name: t('amount'),
      selector: 'amount',
      sortable: true,
      cell: (movement) => <Typography>{currency(movement.amount)}</Typography>,
    },
    {
      name: t('date'),
      selector: 'date',
      sortable: true,
      cell: (movement) => (
        <Typography>{dateExt(movement.createdAt, null, channel?.dateFormatExtReact)}</Typography>
      ),
    },
  ];

  return (
    <Box>
      <Box display="flex" alignItems="center" mb={3}>
        <Box mr={5}>
          <Typography variant="h6">{t('channel.member.credits')}</Typography>
        </Box>
        <LoaderSm loading={loading} />
      </Box>
      <ModalFormMovement
        channel={channel}
        userId={userId}
        open={openModalMovement}
        close={closeModalMovement}
      />

      <Paper py={2} className="paper-rounded">
        <Box display="flex" alignItems="center" mb={3} mx={3}>
          <Box flexGrow={1}>
            <Typography variant="h5" color="secondary">
              Total:&nbsp;
              <Typography variant="h5" component="span" style={{ color: successColor }}>
                {currency(userCredit.amount)}
              </Typography>
            </Typography>
          </Box>
          <Typography variant="h6" color="secondary" mb={3} ml={3}>
            Updated:&nbsp;
            <Typography variant="h6" component="span" color="primary">
              {dateExt(userCredit.updatedAt, null, channel?.dateFormatExtReact)}
            </Typography>
          </Typography>
        </Box>

        <SFDataTable
          noHead
          noHeader
          noTableHead
          showPaper={false}
          columns={columns}
          data={userCredit?.transactions}
          pagination
          showExport={false}
          paginationPerPage={5}
        />
      </Paper>
    </Box>
  );
}
MemberCredits.defaultProps = {};
