import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import TextTruncate from 'react-text-truncate';
import CardContent from '@material-ui/core/CardContent';
import Typography from 'core/ui/Typography';
import PropTypes from 'prop-types';
import ImageMedia from 'components/Channel/ImageMedia';
import Paper from 'core/ui/Paper';

const useStyles = makeStyles({
  root: {
    backgroundColor: 'transparent',
  },
  media: {
    paddingTop: '56.25%', // 16:9
    backgroundColor: '#bbbdbf',
  },
});

export default function CardChannel({ channel, paperContent }) {
  const classes = useStyles();
  return (
    <Card className={`${classes.root}`}>
      <ImageMedia image={channel.image ? channel.image : {}} />
      {paperContent ? (
        <Paper>
          <CardContent>
            <Typography gutterBottom color="primary" variant="h5" component="h2">
              {channel.name}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              <TextTruncate line={4} element="span" truncateText="…" text={channel.description} />
            </Typography>
          </CardContent>
        </Paper>
      ) : (
        <CardContent>
          <Typography gutterBottom color="primary" variant="h5" component="h2">
            {channel.name}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            <TextTruncate line={4} element="span" truncateText="…" text={channel.description} />
          </Typography>
        </CardContent>
      )}
    </Card>
  );
}

CardChannel.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  channel: PropTypes.object,
  paperContent: PropTypes.bool,
};

CardChannel.defaultProps = {
  channel: {},
  paperContent: false,
};
