import React from 'react';
import Box from 'core/ui/Box';
import { useParams } from 'react-router';
import StreamFitnessSplash from 'core/ui/StreamFitnessSplash';
import ModalRequiresAction from 'components/Profile/Dashboard/Account/Table/ModalRequiresAction';

const RequiresAction = () => {
  const { secret } = useParams();

  const callback = () => {};

  return (
    <Box>
      {secret ? (
        <ModalRequiresAction
          open
          clientSecret={secret}
          callback={() => callback()}
          close={() => callback()}
        />
      ) : (
        <StreamFitnessSplash />
      )}
    </Box>
  );
};

export default RequiresAction;
