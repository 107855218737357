/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Box from 'core/ui/Box';
import { useLocation, useParams } from 'react-router';
import { parse } from 'query-string';
import useSelectors from 'modules/map/useSelectors';
import { newChannelsModule } from 'modules/channels/new';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import LoaderSm from 'core/ui/LoaderSm';
import { sessionSelectors } from 'modules/session';
import { useSelector } from 'react-redux';
import ModalPayment from './ModalPayment';
import CardPlan from './CardPlan';
import ActivePlans from './ActivePlans';

const Plans = ({
  embed,
  hideCurrent,
  channel,
  membershipsActiveKeys,
  getMemberships,
  plansBlock,
  purchasedPlanCallback,
}) => {
  const buttonColor = channel?.customColors?.buttonColor;
  const [openModalJoin, setOpenModalJoin] = useState({});
  const { request } = useActions();
  const loadingPlan = useSelectors(newChannelsModule, 'loadingPlan');
  const { paramId, paramSecondary } = useParams();
  const currentUser = useSelector((state) => sessionSelectors.getUser(state));

  const location = useLocation();

  const openPlan = (planId, coupon) => {
    request({
      action: Modules.newChannels.actions.getMembershipPlan,
      data: planId,
      options: {
        onSuccess: (plan) => {
          if (plan?.allowPurchase) {
            setOpenModalJoin((prev) => ({
              ...prev,
              open: true,
              plan,
              couponCode: coupon,
              embed,
            }));
          } else {
            alert('You already have this plan.');
          }
        },
      },
    });
  };

  const embedGetPlan = (planId, coupon) => {
    const exists =
      membershipsActiveKeys &&
      membershipsActiveKeys.find(
        (x) =>
          x.membershipPlan?.renewable && x.membershipPlan && x.membershipPlan.id === Number(planId),
      );

    if (!exists) {
      openPlan(planId, coupon, true);
    }
  };

  useEffect(() => {
    if (paramId) {
      embedGetPlan(paramId, paramSecondary);
      return;
    }

    const parsed = parse(location.search);

    if (parsed && parsed.plan) {
      embedGetPlan(parsed.plan, parsed.coupon);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, channel?.id]);

  const plans = () => {
    if (!membershipsActiveKeys && !plansBlock) {
      return channel.membershipPlans ? channel.membershipPlans : [];
    }

    let arrayData = channel.membershipPlans ? [...channel.membershipPlans] : [];

    if (plansBlock) {
      arrayData = arrayData.filter(
        (x) =>
          !plansBlock.find((y) => y.channelMembershipPlan && y.channelMembershipPlan.id === x.id),
      );
    }

    if (membershipsActiveKeys) {
      arrayData = arrayData.filter(
        (x) =>
          !membershipsActiveKeys.find(
            (y) =>
              !x.hasLimit &&
              y.active &&
              y.paidAt &&
              y.membershipPlan &&
              y.membershipPlan.id === x.id,
          ),
      );
    }

    return arrayData;
  };

  const showPlans = plans()
    .filter((x) => !embed || !x.hideInEmbed)
    .filter(
      (x) => !openModalJoin?.embed || !openModalJoin?.plan?.id || x.id === openModalJoin?.plan?.id,
    );

  return (
    <Box>
      {!hideCurrent && !embed && (
        <div>
          <ActivePlans showPlans={showPlans} channel={channel} getMemberships={getMemberships} />
        </div>
      )}
      <LoaderSm loading={loadingPlan} center />
      <Box>
        {(!currentUser?.id || currentUser?.existsPin) &&
          openModalJoin.open &&
          openModalJoin.plan && (
            <ModalPayment
              // eslint-disable-next-line no-return-assign
              callback={() => {
                if (purchasedPlanCallback) purchasedPlanCallback();
              }}
              channel={channel}
              open={openModalJoin.open}
              membershipPlan={openModalJoin.plan}
              couponCode={openModalJoin.couponCode}
              close={() => setOpenModalJoin((prev) => ({ ...prev, open: false }))}
              buttonColor={buttonColor}
            />
          )}

        <div
          className={`tw-mt-2 tw-grid tw-grid-cols-1 tw-gap-3 md:tw-grid-cols-2 lg:tw-grid-cols-3 xl:tw-grid-cols-4 ${embed ? 'tw-p-2' : ''}`}
        >
          {showPlans.map((plan) => (
            <div key={plan?.id}>
              <CardPlan plan={plan} channel={channel} getPlan={openPlan} />
            </div>
          ))}
        </div>
      </Box>
    </Box>
  );
};

export default Plans;
