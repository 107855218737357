import { createSelector } from 'reselect';

const getState = (state) => state.channels;

export const getLoading = createSelector(getState, (state) => !!state.loading);
export const getLoadingChannel = createSelector(getState, (state) => !!state.loadingChannel);
export const getLoadingChannelUpcomingWorkouts = createSelector(
  getState,
  (state) => !!state.loadingChannelUpcomingWorkouts,
);
export const getLoadingKeys = createSelector(getState, (state) => !!state.loadingKeys);
export const getLoadingActiveKey = createSelector(getState, (state) => !!state.loadingActiveKey);
export const getLoadingCoaches = createSelector(getState, (state) => !!state.loadingCoaches);
export const getLoadingPurchase = createSelector(getState, (state) => !!state.loadingPurchase);
export const getLoadingCoach = createSelector(getState, (state) => !!state.loadingCoach);
export const getFavoriteLoading = createSelector(getState, (state) => !!state.favoriteLoading);
export const getFavoriteChannels = createSelector(getState, (state) => !!state.favoriteChannels);
export const getError = createSelector(getState, (state) => state.error);
export const getChannels = createSelector(getState, (state) => state.data);
export const getChannel = createSelector(getState, (state) => state.channel);

export const getChannelCalendarWorkouts = createSelector(
  getState,
  (state) => state.calendarWorkouts,
);
export const getLoadingValidationCard = createSelector(
  getState,
  (state) => state.loadingValidationCard,
);
export const getLoadingDiscountCode = createSelector(
  getState,
  (state) => state.loadingDiscountCode,
);
