import { createSelector } from 'reselect';

const getState = (state) => state.sessionWorkout;

export const getLoading = createSelector(getState, (state) => state.loading);
export const getLoadingRecording = createSelector(getState, (state) => state.loadingRecordings);
export const getRecording = createSelector(getState, (state) => state.recording);

export const getEvents = createSelector(getState, (state) => state.events);
export const getToken = createSelector(getState, (state) => state.token);
export const getAccess = createSelector(getState, (state) => state.access);
export const getError = createSelector(getState, (state) => state.error);
