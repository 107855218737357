import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import Typography, { fontStyle } from 'core/ui/Typography';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import CountryChosen from 'core/ui/CountryChosen';
import TagsChosen from 'core/ui/TagsChosen';
import { CompetitionRegistrationContext } from 'contexts';
import { secondary } from 'core/ui/Colors';
import AddAthletes from './Teams/AddAthletes';

export default function RegistrationTeam() {
  const { t } = useTranslation();
  const context = useContext(CompetitionRegistrationContext);

  useEffect(() => {
    if (!context?.registrationData?.countryId) {
      context.setRegistrationData((prev) => ({ ...prev, countryId: 16 }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context?.registrationData?.countryId]);

  useEffect(() => {
    context.setInvites([{}]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context?.competition]);

  return (
    <Box>
      <Box mb={3}>
        <TextFieldRounded
          name="name"
          onChange={(v) =>
            context.setRegistrationData((prev) => ({
              ...prev,
              teamName: v,
            }))
          }
          value={context.registrationData?.teamName || ''}
          placeholder={t('competition.registration.label.teamName')}
          label={t('competition.registration.label.teamName')}
          required
          labelColor={context?.competition?.darkBackground ? 'white' : secondary}
        />
      </Box>

      {context.competition.isCoach &&
        context.competition.affiliates &&
        context.competition.affiliates.length > 0 && (
          <Box my={3}>
            <SelectBoxObject
              value={context.registrationData?.affiliateId || -1}
              setValue={(v) => context.setRegistrationData((prev) => ({ ...prev, affiliateId: v }))}
              options={[{ id: -1, gymName: 'No Affiliate' }, ...context.competition.affiliates]}
              propLabel="gymName"
              propValue="id"
              label={t('competition.affiliateGym')}
              labelColor={context?.competition?.darkBackground ? 'white' : secondary}
            />
          </Box>
        )}

      <Box mb={3}>
        <CountryChosen
          setValue={(v) => context.setRegistrationData((prev) => ({ ...prev, countryId: v }))}
          defaultValue={context.registrationData?.countryId}
          label={t('competition.country')}
          labelColor={context?.competition?.darkBackground ? 'white' : secondary}
        />
      </Box>
      <Box mb={3}>
        <TextFieldRounded
          name="location"
          onChange={(v) =>
            context.setRegistrationData((prev) => ({
              ...prev,
              location: v,
            }))
          }
          value={context.registrationData?.location || ''}
          placeholder={t('competition.registration.label.location')}
          label={t('competition.registration.label.location')}
          labelColor={context?.competition?.darkBackground ? 'white' : secondary}
        />
      </Box>

      <Box mb={3}>
        <Typography
          ml={2}
          style={{
            ...fontStyle.label,
            color: context?.competition?.darkBackground ? 'white' : secondary,
          }}
        >
          {t('competition.registration.label.tags')}
        </Typography>
        <Typography
          mb={1}
          ml={3}
          style={{
            ...fontStyle.xSmall,
            color: context?.competition?.darkBackground ? 'white' : secondary,
          }}
        >
          {t('competition.registration.help.tags')}
        </Typography>
        <TagsChosen
          placeholder={t('competition.registration.label.tags')}
          setValue={(values) => context.setRegistrationData((prev) => ({ ...prev, tags: values }))}
          limit={6}
          kind="competition"
          val={context.registrationData?.tags ? [...context.registrationData?.tags] : []}
          iconTag={
            <Typography ml={1.5} variant="h6" style={{ color: 'white' }}>
              #
            </Typography>
          }
        />
      </Box>

      <AddAthletes
        competition={context?.competition}
        invites={context?.invites}
        setInvites={context?.setInvites}
        eventType="team"
      />
    </Box>
  );
}

RegistrationTeam.defaultProps = {
  pay: true,
  coachTeam: false,
};
