import { createSelector } from 'reselect';

const getState = (state) => state.workouts;

export const getLoading = createSelector(getState, (state) => state.loading);
export const getLoadingDetail = createSelector(getState, (state) => state.loadingDetail);
export const getLoadingWorkoutSection = createSelector(
  getState,
  (state) => state.loadingWorkoutSection,
);
export const getLoadingWorkoutUsers = createSelector(
  getState,
  (state) => state.loadingWorkoutUsers,
);
export const getLoadingWorkoutCoaches = createSelector(
  getState,
  (state) => state.loadingWorkoutCoaches,
);

export const getLoadingUpcoming = createSelector(getState, (state) => state.loadingUpcoming);
export const getLoadingUpcomingAttendings = createSelector(
  getState,
  (state) => state.loadingUpcomingAttendings,
);

export const getWorkout = createSelector(getState, (state) => state.workout);
export const getWorkoutSections = createSelector(getState, (state) => state.sections);
export const getWorkoutSectionLeaderboard = createSelector(getState, (state) => state.leaderboard);
export const getWorkoutUsers = createSelector(getState, (state) => state.workoutUsers);
export const getWorkoutCoaches = createSelector(getState, (state) => state.workoutCoaches);
export const getWorkoutSectionUserData = createSelector(getState, (state) => state.sectionUserData);

export const getLoadingRecents = createSelector(getState, (state) => state.loadingRecents);
export const getLoadingRecentsAttendings = createSelector(
  getState,
  (state) => state.loadingRecentsAttendings,
);
export const getLoadingCoachAccessed = createSelector(
  getState,
  (state) => state.loadingCoachAccessed,
);

export const getLoadingFavorites = createSelector(getState, (state) => state.loadingFavorites);
export const getLoadingAthlete = createSelector(getState, (state) => state.loadingAthlete);
export const getLoadingSectionLeaderboard = createSelector(
  getState,
  (state) => state.loadingSectionLeaderboard,
);

export const getUpcoming = createSelector(getState, (state) => state.upcomingData);
export const getUpcomingPagination = createSelector(getState, (state) => state.upcomingPagination);

export const getCoachAccessed = createSelector(getState, (state) => state.coachAccessed);

export const getUpcomingAttendings = createSelector(
  getState,
  (state) => state.upcomingAttendingsData,
);
export const getUpcomingAttendingsPagination = createSelector(
  getState,
  (state) => state.upcomingAttendingsPagination,
);

export const getRecents = createSelector(getState, (state) => state.recentsData);
export const getRecentsPagination = createSelector(getState, (state) => state.recentsPagination);
export const getRecentsAttendings = createSelector(
  getState,
  (state) => state.recentsAttendingsData,
);
export const getRecentsAttendingsPagination = createSelector(
  getState,
  (state) => state.recentsAttendingsPagination,
);
export const getDataTrackUser = createSelector(getState, (state) => state.dataTrackUser);

export const getFavorites = createSelector(getState, (state) => state.favoritesData);

export const getLoadingUpcomingRecordingInvites = createSelector(
  getState,
  (state) => state.loadingUpcomingRecordingInvites,
);
export const getLoadingWorkoutRecordingInvites = createSelector(
  getState,
  (state) => state.loadingWorkoutRecordingInvites,
);

export const getError = createSelector(getState, (state) => state.error);
export const getPurchaseSuccess = createSelector(getState, (state) => state.purchaseSuccess);
export const getLoadingPurchase = createSelector(getState, (state) => state.loadingPurchase);
export const getLoadingRefund = createSelector(getState, (state) => state.loadingRefund);
