import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  TableHead,
} from '@material-ui/core';
import Typography, { fontStyle } from 'core/ui/Typography';
import Box from 'core/ui/Box';
import FontIcon from 'core/ui/FontIcon';
import { ButtonPrimary } from 'core/ui/Button';
import { primary, redColor, secondary } from 'core/ui/Colors';
import { useDispatch, useSelector } from 'react-redux';
import { gymDate } from 'core/utils/formatters';
import notifications from 'modules/notifications';
import Divider from 'core/ui/Divider';
import { sessionActions } from 'modules/session';
import useConfirmation from 'core/useConfirmation';
import { appActions } from 'modules/app';
import Modules from 'modules';
import useActions from 'modules/map/useActions';
import useSelectors from 'modules/map/useSelectors';
import { newChannelMembersModule } from 'modules/channelMembers/new';
import ModalRenewMembership from 'components/Channel/Manage/Tabs/Members/Memberships/MemberInformation/MemberMemberships/ModalRenewMembership';
import { showAPIError } from 'core/utils/showAPIError';
import ModalSwitchMembership from 'components/Channel/Manage/Tabs/Members/Memberships/MemberInformation/MemberMemberships/ModalSwitchMembership';
import { channelsSelectors } from 'modules/channels';
import ModalPaymentKeyCancel from '../ModalPaymentKeyCancel';

const UserKeys = ({ keys }) => {
  const { t } = useTranslation();
  const channel = useSelector((state) => channelsSelectors.getChannel(state));
  const { request } = useActions();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [modalPaymentCancel, setModalPaymentCancel] = useState({ open: false });
  const loadingCancelMembership = useSelectors(newChannelMembersModule, 'loadingCancelMembership');
  const [modalSwitch, setModalSwitch] = useState({});

  const dispatch = useDispatch();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const { confirmation } = useConfirmation();

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const toggleKeysToRenew = (key) => {
    if (!key.membershipPlan.renewable) {
      dispatch(notifications.warning(t('channel.membership.message.no-renewable')));
      return;
    }

    confirmation({
      description: key?.previewCancelMsg,
      yesClick: () => {
        dispatch(appActions.setLoading(true));
        request({
          action: Modules.newChannelMembers.actions.cancelMembership,
          data: {
            channelId: key.channel.id,
            channelKeyId: key.id,
          },
          options: {
            onSuccess: () => {
              dispatch(sessionActions.authenticateStart());
            },
          },
        });
      },
    });
  };

  const cancelClick = (key) => {
    if (key.cancelable && key.cancellationAmount <= 0) {
      toggleKeysToRenew(key);
    } else {
      setModalPaymentCancel({
        open: true,
        key,
        callback: () => {
          setModalPaymentCancel({ open: false });
          dispatch(sessionActions.authenticateStart());
        },
      });
    }
  };

  const togglePostPay = (channelKeyId) => {
    request({
      action: Modules.channelKeys.actions.updatePostPayment,
      data: channelKeyId,
      options: {
        onSuccess: () => {
          dispatch(sessionActions.authenticateStart());
        },
        onError: showAPIError,
      },
    });
  };

  return (
    <Box mt={2}>
      <ModalPaymentKeyCancel
        open={modalPaymentCancel.open}
        close={() => setModalPaymentCancel({ open: false })}
        channelKey={modalPaymentCancel.key}
        callback={modalPaymentCancel.callback}
      />

      {modalSwitch?.open && (
        <ModalSwitchMembership
          channel={channel}
          channelKey={modalSwitch?.channelKey}
          open={modalSwitch?.open}
          close={() => setModalSwitch({})}
          callbackSave={() => {
            // getMemberships();
            dispatch(sessionActions.authenticateStart());
          }}
        />
      )}

      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">{t('table.column.userKeys.limit')}</TableCell>
              <TableCell>{t('table.column.userKeys.channel')}</TableCell>
              <TableCell align="center" />
              <TableCell align="center">{t('table.column.userKeys.status')}</TableCell>
              <TableCell align="center">{t('table.column.userKeys.actions')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? keys.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : keys
            ).map((itemKey) => (
              <TableRow
                key={itemKey.id}
                style={{ backgroundColor: itemKey.pauseAt ? '#FFFF66' : '' }}
              >
                <TableCell align="center">
                  <Typography>{itemKey?.pauseAt ? 'PAUSED' : itemKey.limitUse}</Typography>
                </TableCell>
                <TableCell align="center">
                  <FontIcon iconName="icon-live-tv" color={secondary} size={12} />
                  <Typography ml={1} color="secondary" style={{ ...fontStyle.small }}>
                    {itemKey?.channel?.name}
                    {itemKey.membershipPlan && (
                      <>
                        <span>&nbsp;-&nbsp;</span>
                        <span style={{ color: primary }}>{itemKey?.membershipPlan?.name}</span>
                      </>
                    )}
                  </Typography>
                </TableCell>
                <TableCell>
                  {itemKey.expiryAt && (
                    <Typography color="secondary" style={{ ...fontStyle.small }}>
                      {t(
                        itemKey.renewable
                          ? 'channel.memberships.renewIn'
                          : 'channel.memberships.expiryIn',
                      )}
                      &nbsp;
                      {gymDate(itemKey.expiryAt)}
                    </Typography>
                  )}
                  {itemKey.planExpiryAt && (
                    <>
                      <Divider my={1} />
                      <Typography color="primary" style={{ ...fontStyle.small }}>
                        {t('channel.memberships.planExpiresIn')}
                        &nbsp;
                        {gymDate(itemKey.planExpiryAt)}
                      </Typography>
                    </>
                  )}
                  {itemKey.removeActiveAt && (
                    <>
                      <Divider my={1} />
                      <Typography color="secondary" style={{ ...fontStyle.small }}>
                        {t('channel.memberships.removeMembershipAt')}: &nbsp;
                        {gymDate(itemKey.removeActiveAt)}
                      </Typography>
                    </>
                  )}
                </TableCell>
                <TableCell align="center">
                  {!itemKey.free && itemKey.membership && (
                    <div>
                      {!itemKey.notStarted ? (
                        <div>
                          {itemKey.renewable && !itemKey?.removeActiveAt ? (
                            <Typography
                              ml={1}
                              component="span"
                              color="secondary"
                              style={{ ...fontStyle.small }}
                            >
                              {t('channelkey.renewable')}
                            </Typography>
                          ) : (
                            <Typography
                              ml={1}
                              component="span"
                              color="secondary"
                              style={{ ...fontStyle.small }}
                            >
                              {t('channelkey.expiring')}
                            </Typography>
                          )}
                        </div>
                      ) : (
                        <Typography
                          ml={1}
                          component="span"
                          color="secondary"
                          style={{ ...fontStyle.small }}
                        >
                          {t('channel.label.plan.startDate')}&nbsp;
                          {gymDate(itemKey.startAt)}
                        </Typography>
                      )}

                      {itemKey?.offline && itemKey?.offlineType === 'post_payment' ? (
                        <strong className="tw-text-red-500">
                          {t('payment.offline.status.notPaid')}
                        </strong>
                      ) : (
                        itemKey.isPostPayment && (
                          <strong className="tw-text-green-500">
                            {t('payment.offline.status.paid')}
                          </strong>
                        )
                      )}
                    </div>
                  )}
                </TableCell>
                <TableCell>
                  <Box display="flex" alignItems="center" className="tw-gap-1.5">
                    {!!itemKey?.showRetry && (
                      <>
                        <ModalRenewMembership
                          channel={itemKey?.channel}
                          channelKey={itemKey}
                          callbackSave={() => {
                            dispatch(sessionActions.authenticateStart());
                          }}
                        />
                        &nbsp;
                      </>
                    )}
                    {!itemKey?.removeActiveAt &&
                      itemKey.cancelable &&
                      itemKey.renewable &&
                      itemKey.started && (
                        <ButtonPrimary
                          buttonColor={redColor}
                          disabled={loadingCancelMembership}
                          onClick={() => cancelClick(itemKey)}
                        >
                          <Typography>{t('button.cancel')}</Typography>
                        </ButtonPrimary>
                      )}

                    {itemKey?.kind === 'membership' &&
                      itemKey?.isPostPayment &&
                      itemKey?.offlineType !== 'post_payment' && (
                        <ButtonPrimary
                          buttonColor={redColor}
                          disabled={loadingCancelMembership}
                          onClick={() => togglePostPay(itemKey.id)}
                        >
                          <strong className="tw-text-xs">{t('button.removePostPay')}</strong>
                        </ButtonPrimary>
                      )}
                    {itemKey?.kind === 'membership' && itemKey?.allowSwitchPlan && (
                      <ButtonPrimary
                        buttonColor={redColor}
                        disabled={loadingCancelMembership}
                        onClick={() => setModalSwitch({ channelKey: itemKey, open: true })}
                      >
                        <strong className="tw-text-xs">
                          {t('button.membership.switchMembership')}
                        </strong>
                      </ButtonPrimary>
                    )}
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box mt={3} className="hide-pagination-controls">
        <TablePagination
          component="div"
          count={keys.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Box>
    </Box>
  );
};

UserKeys.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  keys: PropTypes.array,
};

UserKeys.defaultProps = {
  keys: [],
};

export default UserKeys;
