import React from 'react';
import Box from 'core/ui/Box';
import Typography from 'core/ui/Typography';
import { ButtonPrimary } from 'core/ui/Button';
import { Edit } from '@material-ui/icons';
import { redColor, successColor } from 'core/ui/Colors';
import { currency } from 'core/utils/formatters';
import { useTranslation } from 'react-i18next';

const ItemActionsProgram = ({ program, setModalForm, setModalChannels, setModalFormWorkout }) => {
  const { t } = useTranslation();

  const hasParents = program?.parentPrograms?.length > 0;
  const isChild = !!program?.parentProgram?.id;

  return (
    <Box>
      <Box display="flex" alignItems="center">
        <Box mx={1}>{!isChild && <Typography>${currency(program?.cost)}</Typography>}</Box>
        <Box mx={1}>
          <Typography
            style={{
              backgroundColor: program?.active ? successColor : redColor,
              borderRadius: 5,
              padding: 2,
              color: 'white',
              fontSize: 12,
            }}
          >
            {program?.active ? 'Active' : 'Inactive'}
          </Typography>
        </Box>
        {!isChild && (
          <Box mx={1}>
            <ButtonPrimary
              onClick={() => setModalChannels({ open: true, partnersProgram: program })}
            >
              {t('button.channels')}
            </ButtonPrimary>
          </Box>
        )}
        {!hasParents && (
          <Box mx={1}>
            <ButtonPrimary
              onClick={() => setModalFormWorkout({ open: true, partnersProgram: program })}
            >
              {t('button.addWorkout')}
            </ButtonPrimary>
          </Box>
        )}
        <Box mx={1}>
          <ButtonPrimary onClick={() => setModalForm({ open: true, program })}>
            <Edit />
          </ButtonPrimary>
        </Box>
      </Box>
    </Box>
  );
};

export default ItemActionsProgram;
