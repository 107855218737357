import React, { useEffect, useState } from 'react';
import Box from 'core/ui/Box';
import Paper from 'core/ui/Paper';
import Autocomplete from 'react-google-autocomplete';
import { GM_API_KEY } from 'core/env';
import { GoogleMap, OverlayView } from '@react-google-maps/api';
import { mapsStyles } from 'core/utils/consts';

const LocationMapView = ({ setLocation, location, avatar, styleWrapper = {} }) => {
  const [map, setMap] = useState();

  const userBounds = (item) => {
    const lat = Number(item?.lat);
    const lng = Number(item?.lng);
    const bounds = new window.google.maps.LatLngBounds(
      new window.google.maps.LatLng(lat, lng),
      new window.google.maps.LatLng(lat, lng),
    );

    return bounds;
  };

  const onLoad = React.useCallback((mp) => {
    const bounds = new window.google.maps.LatLng(userBounds({ lat: 59.938043, lng: 30.337157 }));
    mp.setCenter(bounds);
    setMap(mp);
  }, []);

  useEffect(() => {
    if (location && map) {
      // map.setCenter(location);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const onUnmount = React.useCallback(() => {
    setMap(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Box mb={2}>
        <Autocomplete
          inputMode="none"
          defaultValue={location?.name || ''}
          libraries={['places']}
          apiKey={GM_API_KEY}
          options={{ types: ['geocode'] }}
          onPlaceSelected={(place) => {
            if (!place?.name) {
              setLocation({
                name: place?.formatted_address,
                street: place?.address_components[0]?.short_name,
                city: place?.address_components[2]?.short_name,
                state: place?.address_components[3]?.short_name,
                country: place?.address_components[4]?.short_name,
                zip: place?.address_components[5]?.short_name,
                lat: place.geometry?.location.lat(),
                lng: place.geometry?.location.lng(),
              });
            }
          }}
          style={{
            border: 'none',
            height: 50,
            width: '100%',
            padding: 5,
            borderRadius: 5,
            ...(styleWrapper || {}),
          }}
        />
      </Box>

      {location?.name && (
        <Paper p={3} style={{ minHeight: 250 }}>
          <GoogleMap
            clickableIcons={false}
            zoom={12}
            center={location}
            onLoad={onLoad}
            options={{ styles: mapsStyles }}
            onUnmount={onUnmount}
            mapContainerStyle={{ width: '100%', height: 250, flex: 1 }}
          >
            <OverlayView bounds={userBounds(location)} mapPaneName={OverlayView.FLOAT_PANE}>
              {avatar}
            </OverlayView>
          </GoogleMap>
        </Paper>
      )}
    </Box>
  );
};

LocationMapView.defaultProps = {};

LocationMapView.propTypes = {};

export default LocationMapView;
