import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Popper, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import Typography, { fontStyle } from '../Typography';
import Box from '../Box';
import { secondaryLight } from '../Colors';
import Paper from '../Paper';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: 5,
    width: '100%',
  },
  select: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontSize: 12,
    paddingLeft: 5,
  },
  placeHolder: {
    color: '#C7C7CD',
  },
}));

export default function SelectBoxSearch({
  options,
  placeholder,
  value,
  label,
  error,
  setValue,
  disabled,
  required,
  mbl,
  bordered,
  ...props
}) {
  const classes = useStyles();

  const styleInput = error ? {} : { border: bordered ? `1px solid${secondaryLight}` : 'none' };

  return (
    <div>
      {label !== '' && (
        <Box ml={2} mb={mbl} display="flex">
          <Typography style={fontStyle.label} variant="body1">
            {label}
          </Typography>
          {required && (
            <Typography ml={1} color="error">
              *
            </Typography>
          )}
        </Box>
      )}
      <Paper
        className={`${error && value === 'undefined' ? 'input-paper-error' : ''} ${classes.root}`}
        style={styleInput}
        px={2}
      >
        <Autocomplete
          disablePortal
          placeholder={placeholder}
          disableClearable
          fullWidth
          PopperComponent={(pProps) => (
            <Popper {...pProps} style={{ zIndex: 3000, minWidth: 200, ...pProps?.style }} />
          )}
          disabled={disabled}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          value={value}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              required
              fullWidth
              autoFocus
              InputProps={{
                disableUnderline: true, // <== added this
                style: {
                  minWidth: 150,
                  marginLeft: 1,
                  flex: 1,
                  fontSize: 12,
                  paddingLeft: 5,
                },
                ...params?.InputProps,
              }}
            />
          )}
          options={options}
          {...props}
        />
      </Paper>
    </div>
  );
}

SelectBoxSearch.defaultProps = {
  label: '',
  options: [],
  mbl: 2,
  value: '',
  placeholder: '',
  error: null,
  setValue: null,
  required: false,
};
