import api from 'core/api';
import { retrieveAuthHeaders } from 'core/api/auth-headers';
import { BASE_URL } from 'core/env';
import { retrieveData } from 'core/utils/session-storage';
import { call, put } from 'redux-saga/effects';
import { saveAs } from 'file-saver';
import humps from 'humps';

export const newCompetitionsModule = 'newCompetitions';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};
const loadingPaymentsStates = {
  start: { loadingPayments: true },
  error: { loadingPayments: false },
  success: { loadingPayments: false },
};
const loadingCouponStates = {
  start: { loadingCoupon: true },
  error: { loadingCoupon: false },
  success: { loadingCoupon: false },
};
const loadingCloneStates = {
  start: { loadingClone: true },
  error: { loadingClone: false },
  success: { loadingClone: false },
};
const loadingResultsStates = {
  start: { loadingResults: true },
  error: { loadingResults: false },
  success: { loadingResults: false },
};
const loadingExportResultsStates = {
  start: { loadingExportResults: true },
  error: { loadingExportResults: false },
  success: { loadingExportResults: false },
};
const loadingDivisionsStates = {
  start: { loadingDivisions: true },
  error: { loadingDivisions: false },
  success: { loadingDivisions: false },
};
const loadingMyCompetitionsStates = {
  start: { loadingMyCompetitions: true },
  error: { loadingMyCompetitions: false },
  success: { loadingMyCompetitions: false },
};

const actions = {
  getMyCompetitions: {
    module: newCompetitionsModule,
    name: 'getMyCompetitions',
    api: () => api.get(`/competitions`),
    params: {
      start: ['params'],
      error: [''],
      success: [],
    },
    state: loadingMyCompetitionsStates,
  },
  getCompetitionResults: {
    module: newCompetitionsModule,
    name: 'getCompetitionResults',
    api: (params) => api.get(`/competitions/${params.competitionId}/results`, { params }),
    *sagas(Creators, { params, options }) {
      try {
        const resp = yield call(actions.getCompetitionResults.api, params);
        yield put(Creators.getCompetitionResultsSuccess(resp.pagination));
        if (options.onSuccess) {
          options.onSuccess(resp.data, resp.pagination);
        }
      } catch (error) {
        if (options.onError) {
          options.onError(error);
        }
        yield put(Creators.getCompetitionResultsError());
      }
    },
    params: {
      start: ['params'],
      error: [''],
      success: [],
    },
    state: loadingResultsStates,
    isTakeEvery: true,
  },

  exportCompetitionLeaderboard: {
    module: newCompetitionsModule,
    name: 'exportCompetitionLeaderboard',
    api: (data) => {
      // api.post(`/channel_reports/${data.channelId}/${data.report}`, data)
      const authHeaders = retrieveAuthHeaders(retrieveData)();
      return fetch(`${BASE_URL}/api/v1/competitions/${data.competitionId}/export_results`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          ...authHeaders,
        },
        body: JSON.stringify(humps.decamelizeKeys(data)),
      });
    },
    *sagas(Creators, { params, options }) {
      try {
        const resp = yield call(actions.exportCompetitionLeaderboard.api, params);

        resp
          .blob()
          .then((f) => saveAs(f, params?.exportFileName || `${params?.competitionId}.csv`));

        yield put(Creators.exportCompetitionLeaderboardSuccess());
        if (options.onSuccess) {
          options.onSuccess(resp);
        }
      } catch (error) {
        if (options.onError) {
          options.onError(error);
        }
        yield put(Creators.exportCompetitionLeaderboardError());
      }
    },
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingExportResultsStates,
  },

  cloneCompetition: {
    module: newCompetitionsModule,
    name: 'cloneCompetition',
    api: (data) => api.post(`/competitions/${data?.competitionId}/clone`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [],
    },
    state: loadingCloneStates,
  },
  getCompetitionDivisions: {
    module: newCompetitionsModule,
    name: 'getCompetitionDivisions',
    api: ({ competitionId }) => api.get(`/competitions/${competitionId}/divisions`),
    params: {
      start: ['params'],
      error: [''],
      success: [],
    },
    state: loadingDivisionsStates,
  },
  getCompetitionPayments: {
    module: newCompetitionsModule,
    name: 'getCompetitionPayments',
    api: (params) => api.get(`/competitions/${params?.competitionId}/payments`, { params }),

    *sagas(Creators, { params, options }) {
      try {
        const resp = yield call(actions.getCompetitionPayments.api, params);
        yield put(Creators.getCompetitionPaymentsSuccess());
        if (options.onSuccess) {
          options.onSuccess(resp.data, resp.pagination);
        }
      } catch (error) {
        if (options.onError) {
          options.onError(error);
        }
        yield put(Creators.getCompetitionPaymentsError());
      }
    },

    params: {
      start: ['params'],
      error: [''],
      success: [],
    },
    state: loadingPaymentsStates,
  },
  getRegistrationPrice: {
    module: newCompetitionsModule,
    name: 'getRegistrationPrice',
    api: (params) => api.post(`/competitions/${params?.competitionId}/resume_registration`, params),
    params: {
      start: ['params'],
      error: [''],
      success: [],
    },
    state: loadingStates,
  },

  connectStripeAccount: {
    module: newCompetitionsModule,
    name: 'connectStripeAccount',
    api: ({ competitionId, code }) =>
      api.get(`/competitions/${competitionId}/connect_stripe_account/${code}`),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  removeStripeAccount: {
    module: newCompetitionsModule,
    name: 'removeStripeAccount',
    api: ({ competitionId }) => api.delete(`/competitions/${competitionId}/remove_stripe_account`),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  getDiscount: {
    module: newCompetitionsModule,
    name: 'getDiscount',
    api: ({ competitionId, code }) => api.get(`/competitions/${competitionId}/coupon/${code}`),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingCouponStates,
  },
};

const newCompetitionsModuleObj = {
  actions,
  state: {
    loading: false,
    loadingCoupon: false,
    loadingResults: false,
    loadingDivisions: false,
    users: [],
    pagination: null,
  },
};

export default newCompetitionsModuleObj;
