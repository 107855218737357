import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from '@material-ui/core';
import { LayoutContext } from 'pages/shared/Layout';
import { workoutsActions, workoutsSelectors } from 'modules/workouts';
import { useHistory, useParams } from 'react-router';
import Show from 'components/Workout/Show/index';
import { sessionSelectors } from 'modules/session';
import Texture from 'core/ui/Texture';
import useDeepLinks, { AppScreens } from 'core/useDeepLinks';
import Background from 'core/ui/Background';

export default () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const layoutContext = useContext(LayoutContext);
  const purchaseSuccess = useSelector((state) => workoutsSelectors.getPurchaseSuccess(state));
  const loading = useSelector((state) => workoutsSelectors.getLoading(state));
  const workout = useSelector((state) => workoutsSelectors.getWorkout(state));
  const userSession = useSelector((state) => sessionSelectors.getUser(state));
  const { id } = useParams();
  const history = useHistory();
  const deep = useDeepLinks();
  const [tab, setTab] = useState('overview');

  useEffect(() => {
    deep.openInApp({ screen: AppScreens.Workout.View, param: id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(workoutsActions.getWorkoutStart(id));
    return () => {
      dispatch(workoutsActions.clearWorkout());
    };
  }, [dispatch, id, userSession]);

  useEffect(() => {
    if (!loading && workout && workout.id && !!workout.competition?.id) {
      history.push(`/competition/view/${workout.competition.id}?workoutId=${workout.id}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workout, loading]);

  useEffect(() => {
    if (userSession.id && purchaseSuccess === true) {
      dispatch(workoutsActions.getWorkoutStart(id));
      dispatch(workoutsActions.setPurchaseSuccess(false));
    }
  }, [dispatch, id, userSession, purchaseSuccess]);

  useEffect(() => {
    layoutContext.setTitleBar(t('title.workout').toUpperCase());
    layoutContext.setActiveMenu('');
  }, [layoutContext, t]);

  return (
    <Texture
      fullHeight
      heightBanner={layoutContext.heightBanner}
      heightHeader={layoutContext.heightHeader}
    >
      <Background
        imageUrl={
          tab === 'leaderboard'
            ? workout?.channel?.bgImage?.image?.url || workout?.channel?.image?.image?.url
            : null
        }
        heightBanner={layoutContext.heightHeader}
        heightHeader={layoutContext.heightHeader}
      >
        <Container maxWidth="xl">
          <Box pb={20}>
            <Show tab={tab} setTab={setTab} />
          </Box>
        </Container>
      </Background>
    </Texture>
  );
};
