/* eslint-disable react/prop-types */
import React from 'react';
import {
  BarChart,
  Bar,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from 'recharts';
import Typography from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import Paper from 'core/ui/Paper';
import { channelCurrency } from 'core/utils/formatters';

const BarMonths = ({ data, channel }) => {
  const { t } = useTranslation();

  const colorsBar = {
    lightGreen: '#0EB2A3',
    lightBlue: '#3A93D3',
  };

  return (
    <Paper my={5} p={3}>
      <Typography variant="h5" mb={3} align="center">
        {t('channel.reports.summary.projectionMembershipsPayments')}
      </Typography>

      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" style={{ fontSize: 10 }} />

          <YAxis
            tickFormatter={(value) => channelCurrency(value, channel?.currency)}
            yAxisId="left"
            orientation="left"
            stroke="#8884d8"
          />

          <Tooltip formatter={(value) => channelCurrency(value, channel?.currency)} />
          <Legend />

          <Bar yAxisId="left" stackId="a" dataKey="paid" fill={colorsBar.lightGreen} />
          <Bar yAxisId="left" stackId="a" dataKey="upcoming" fill={colorsBar.lightBlue} />
        </BarChart>
      </ResponsiveContainer>
    </Paper>
  );
};

export default BarMonths;
