import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import Box from 'core/ui/Box';
import Texture from 'core/ui/Texture';
import Form from './Form';

const ModalFormBenchmark = ({ close, callback, modal, channel }) => (
  <Dialog open={modal.opened} onClose={close} maxWidth="md" fullWidth scroll="body">
    <Texture>
      <Box p={5}>
        <Form callback={callback || close} channel={channel} benchmark={modal.benchmark} />
      </Box>
    </Texture>
  </Dialog>
);

ModalFormBenchmark.propTypes = {
  close: PropTypes.func,
};

ModalFormBenchmark.defaultProps = {
  close: () => {},
};

export default ModalFormBenchmark;
