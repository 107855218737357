import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import Paper from 'core/ui/Paper';
import Grid from 'core/ui/Grid';
import Typography from 'core/ui/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { plansModule } from 'modules/plans';
import { channelsActions, channelsSelectors } from 'modules/channels';
import { PlanManageContext } from 'contexts';
import { Skeleton } from '@material-ui/lab';
import OwnerablePaymentMethods from 'components/OwnerablePaymentMethods';
import Modules from 'modules';
import useActions from 'modules/map/useActions';
import useSelectors from 'modules/map/useSelectors';
import Toggle from 'core/ui/Toggle';
import { ButtonPrimary } from 'core/ui/Button';
import GymManagement from './GymManagement';
import useChannelPlan from './useChannelPlan';
import VirtualFitness from './VirtualFitness';
import MemberApp from './MemberApp';
import Competitions from './Competitions';
import CurrentPlanCosts from './CurrentPlanCosts';
import ModalPayment from '../ModalPayment';
import SifelyPlan from './Sifely';
import EngagePlan from './Engage';
import PaymentHistory from './PaymentHistory/index';

export default function Plans({ setTab }) {
  const { t } = useTranslation();
  const { request } = useActions();
  const dispatch = useDispatch();
  const channel = useSelector((state) => channelsSelectors.getChannel(state));

  const plan = useSelectors(plansModule, 'plan');

  useEffect(() => {
    request({
      action: Modules.plans.actions.getActivePlan,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const channelPlan = useChannelPlan({ plan: channel?.plan || plan, channel });
  const hasAnyPlan = !!channel.renewAt;

  if (!plan) {
    return (
      <>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Skeleton variant="rect" style={{ height: 150, width: '100%' }} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Skeleton variant="rect" style={{ height: 150, width: '100%' }} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Skeleton variant="rect" style={{ height: 150, width: '100%' }} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Skeleton variant="rect" style={{ height: 150, width: '100%' }} />
          </Grid>
        </Grid>
        <Box className="container-aspect container-aspect-37">
          <Box className="item-aspect">
            <Skeleton variant="rect" style={{ height: 300, width: '100%' }} />
          </Box>
        </Box>
      </>
    );
  }

  return (
    <Box p={5}>
      <PlanManageContext.Provider value={{ ...channelPlan }}>
        <Box my={3}>
          {!channel.defaultPaymentMethod && (
            <Typography align="center" variant="h4" m={3} color="primary">
              {t('channel.message.addAPaymentMethod')}
            </Typography>
          )}
          <OwnerablePaymentMethods
            ownerableType="Channel"
            ownerableId={channel?.id}
            onlyMethod="card"
            callbackSave={() => {
              dispatch(channelsActions.getChannelStart(channel.id, 'edit'));
            }}
          />
        </Box>
        <Paper elevation={2} mb={3} className="paper-rounded" p={5}>
          <Typography
            mb={3}
            align="center"
            variant="h4"
            className="roboto-regular"
            color="secondary"
          >
            {t('channel.plan.new')}
          </Typography>

          <Box display="flex" justifyContent="center" p={2}>
            <Box display="flex" flexGrow={1} justifyContent="center">
              <Toggle
                options={[
                  {
                    label: (
                      <Box>
                        <Typography>Monthly</Typography>
                      </Box>
                    ),
                    value: false,
                    width: 140,
                  },
                  {
                    label: (
                      <Box>
                        <Typography>Annual 5% OFF</Typography>
                      </Box>
                    ),
                    value: true,
                    width: 140,
                  },
                ]}
                value={channelPlan.annualRenew}
                onChange={(value) => channelPlan.setAnnualRenew(value)}
              />
            </Box>
            {channelPlan.planState && (
              <Box display="flex" justifyContent="center" p={2}>
                <ButtonPrimary style={{ height: 45 }} onClick={() => channelPlan.buyPlan()}>
                  {t(`button.plan.${channelPlan.planState}`)}
                </ButtonPrimary>
              </Box>
            )}
          </Box>

          <Grid container>
            <Grid item xs={12} md={6} lg={4}>
              <GymManagement plan={channel?.plan || plan} />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MemberApp plan={channel?.plan || plan} />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <EngagePlan plan={channel?.plan || plan} isAnnual={channelPlan.annualRenew} />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <SifelyPlan plan={channel?.plan || plan} />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <VirtualFitness plan={channel?.plan || plan} />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Competitions plan={channel?.plan || plan} />
            </Grid>
          </Grid>
        </Paper>

        <Box my={3}>
          <PaymentHistory channel={channel} />
        </Box>

        {hasAnyPlan && (
          <Paper elevation={2} mb={3} className="paper-rounded" p={5}>
            <Typography variant="h5" align="center" m={2}>
              {t('label.currentPlan')}
            </Typography>
            <CurrentPlanCosts
              channel={channel}
              renewPlan={channelPlan.renewPlan}
              loading={channelPlan.loadingPurchaseChannelPlan}
            />
          </Paper>
        )}

        {channel && channelPlan.openPayment?.open && (
          <ModalPayment
            channel={channel}
            channelPlan={channelPlan}
            setChannelPlan={channelPlan.setChannelPlan}
            open={channelPlan.openPayment?.open}
            allPlans={channelPlan.openPayment?.allPlans}
            close={() => channelPlan.setOpenPayment({ oepn: false })}
            createPaymentMethod={() => setTab('info')}
            plan={channel?.plan || plan}
          />
        )}
      </PlanManageContext.Provider>
    </Box>
  );
}
