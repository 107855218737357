import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import { ButtonCompetition, ButtonGreen } from 'core/ui/Button';
import { isMobile } from 'react-device-detect';
import ModalDownloadApp from 'core/ui/ModalDownloadApp';
import { FiberSmartRecord, Publish } from '@material-ui/icons';
import ModalUpload from 'components/Competition/Show/Tabs/Workouts/ModalUpload';
import { primary } from 'core/ui/Colors';
import { workoutInviteTypes } from 'core/utils/consts';
import { useHistory } from 'react-router';
import { useTheme } from '@material-ui/core';

export default ({ invite, workout }) => {
  const { t } = useTranslation();
  const [openModalDownloadApp, setOpenModalDownloadApp] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const { palette } = useTheme();
  const history = useHistory();

  const startInviteWorkout = () => {
    if (isMobile) {
      setOpenModalDownloadApp(true);
      return;
    }
    history.push(`/workout/${workout.id}/stream?inviteId=${invite.id}`);
  };

  const styleIcon = {
    fontSize: 16,
    color: 'white',
  };
  const styleButton = {
    backgroundColor: palette.primary.main,
    minWidth: 100,
  };

  return (
    <Box display="flex" flexGrow={1} flexWrap="wrap" justifyContent="flex-end">
      {isMobile && (
        <ModalDownloadApp
          open={openModalDownloadApp}
          close={() => setOpenModalDownloadApp(false)}
        />
      )}

      {openUpload && (
        <ModalUpload open={openUpload} buttonColor={primary} close={() => setOpenUpload(false)} />
      )}

      {invite.inviteType !== workoutInviteTypes.record_compare ? (
        <>
          {workout.registered && invite.live && (
            <ButtonGreen
              style={{ color: 'white', minWidth: 100 }}
              onClick={() => startInviteWorkout(invite)}
            >
              {t('button.joinClass')}
            </ButtonGreen>
          )}
        </>
      ) : (
        <Box display="flex">
          <Box ml={1}>
            {workout.live && (
              <ButtonCompetition style={styleButton} onClick={() => startInviteWorkout()}>
                <FiberSmartRecord style={styleIcon} />
                &nbsp;{t('button.recordWorkout').toUpperCase()}
              </ButtonCompetition>
            )}
          </Box>
          <Box ml={1}>
            {workout.live && (
              <ButtonCompetition style={styleButton} onClick={() => setOpenUpload(true)}>
                <Publish style={styleIcon} />
                &nbsp;{t('button.upload').toUpperCase()}
              </ButtonCompetition>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};
