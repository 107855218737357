/* eslint-disable camelcase */
import { all, call, put } from 'redux-saga/effects';

import { getErrorMessage } from 'core/api/api-error';

import notifications from 'modules/notifications';

import * as api from './api';
import { searchActions as actions } from './index';

export function* getSearch({ data }) {
  try {
    const response = yield call(api.search, data);
    if (data.models[0] === 'Workout') {
      yield put(actions.getSearchWorkoutSuccess(response.data));
    } else {
      yield put(actions.getSearchSuccess(response.data));
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.getSearchError(getErrorMessage(error))),
    ]);
  }
}

export function* getSearchUsers({ data, callback }) {
  try {
    const response = yield call(api.search, data);
    yield put(actions.getSearchUsersSuccess(response.data));
    if (callback) {
      callback(response.data);
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.getSearchUsersError(getErrorMessage(error))),
    ]);
  }
}

export function* getSearchChannelMembers({ data }) {
  try {
    const response = yield call(api.searchChannelMembers, data);
    yield put(actions.getSearchChannelMembersSuccess(response.data));
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.getSearchChannelMembersError(getErrorMessage(error))),
    ]);
  }
}

export function* getSearchAutocomplete({ data }) {
  try {
    const response = yield call(api.autocomplete, data);
    const search = response.data;
    yield put(actions.getSearchAutocompleteSuccess(search));
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.getSearchAutocompleteError(getErrorMessage(error))),
    ]);
  }
}

export function* getFeatured({ data }) {
  try {
    const { page, perPage, model, recents, channel_id, upcoming, registered } = data;
    const response = yield call(api.featured, {
      page,
      perPage,
      model,
      recents,
      channel_id,
      upcoming,
      registered,
    });
    yield put(actions.getFeaturedSuccess(response.data));
    if (data.callback) {
      data.callback(response.data);
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.getFeaturedError(getErrorMessage(error))),
    ]);
  }
}

export function* getFeaturedUpcoming({ data }) {
  try {
    const { page, perPage, model, recents, channel_id, upcoming, registered } = data;
    const response = yield call(api.featured, {
      page,
      perPage,
      model,
      recents,
      channel_id,
      upcoming,
      registered,
    });
    yield put(actions.getFeaturedUpcomingSuccess(response.data));
    if (data.callback) {
      data.callback(response.data);
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.getFeaturedUpcomingError(getErrorMessage(error))),
    ]);
  }
}

export function* getFeaturedRegistered({ data }) {
  try {
    const { page, perPage, model, recents, channel_id, upcoming, registered } = data;
    const response = yield call(api.featured, {
      page,
      perPage,
      model,
      recents,
      channel_id,
      upcoming,
      registered,
    });
    yield put(actions.getFeaturedRegisteredSuccess(response.data));
    if (data.callback) {
      data.callback(response.data);
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.getFeaturedRegisteredError(getErrorMessage(error))),
    ]);
  }
}

export function* getViewMore({ data }) {
  try {
    let response = {};
    if (data.type === 'featured') {
      response = yield call(api.featured, data);
    }
    if (data.type === 'results') {
      response = yield call(api.search, data);
    }
    yield put(actions.getViewMoreSuccess(response.data));
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.getViewMoreError(getErrorMessage(error))),
    ]);
  }
}
