import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { AnnouncementOutlined, Delete, Edit, FileCopy } from '@material-ui/icons';

import { primary, successColor } from 'core/ui/Colors';
import { dateExt } from 'core/utils/formatters';

export default function ItemRow({ channel, item, onEditClick, onDestroyItem }) {
  const [open, setOpen] = React.useState(false);

  const showPrevText = () => {
    const text = item.text || '';
    if (text.length > 50) {
      return `${item.text.substring(0, 50)}...`;
    }
    return text;
  };

  return (
    <TableRow className="hover" onClick={() => setOpen(!open)}>
      <TableCell align="center">
        <AnnouncementOutlined style={{ color: item.showing ? successColor : primary }} />
      </TableCell>

      <TableCell>{showPrevText()}</TableCell>

      <TableCell align="center">
        {dateExt(item.startAt, null, channel?.dateFormatExtReact)}
      </TableCell>

      <TableCell align="center">{dateExt(item.endAt, null, channel?.dateFormatExtReact)}</TableCell>

      <TableCell align="right">
        <IconButton onClick={() => onEditClick({ ...item, id: null })}>
          <FileCopy />
        </IconButton>

        <IconButton onClick={() => onEditClick(item)}>
          <Edit />
        </IconButton>

        <IconButton onClick={onDestroyItem}>
          <Delete />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

ItemRow.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.object.isRequired,
  onEditClick: PropTypes.func.isRequired,
};
