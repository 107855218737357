import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import { chatColors } from 'core/ui/Colors';
import Typography from 'core/ui/Typography';
import { MenuItem, MenuList, Popper, colors } from '@material-ui/core';
import MIcon from 'core/ui/MIcon';
import Paper from 'core/ui/Paper';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export default ({ groupsChat, item, loadGroup, handleJoinGroup }) => {
  const { t } = useTranslation();

  const isCurrentGroup = groupsChat?.id === item?.id;
  const labelColor = colors.grey[isCurrentGroup ? 100 : 500];

  const [open, setOpen] = useState(false);

  const anchorRef = useRef();

  return (
    <Box
      display="flex"
      alignItems="center"
      key={item?.id}
      px={4}
      py={1}
      mb={2}
      className="hover"
      style={isCurrentGroup ? { backgroundColor: chatColors.container } : {}}
    >
      <Box flexGrow={1} onClick={() => loadGroup(item?.id)} className="link-hover">
        <Typography
          style={{
            color: labelColor,
          }}
        >
          {item?.name}
        </Typography>
        <Box display="flex" alignItems="center">
          <MIcon icon="people" size={18} color={labelColor} />
          <Typography ml={1} style={{ color: labelColor }}>
            {item?.usersCount}
          </Typography>
        </Box>
      </Box>

      <Box>
        <Box ref={anchorRef} onClick={() => setOpen(!open)}>
          <ExpandMoreIcon style={{ color: labelColor }} />
        </Box>

        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          style={{ zIndex: 9999 }}
          disablePortal
        >
          <Box>
            <Paper style={{ backgroundColor: chatColors.input }}>
              <MenuList id="split-button-menu">
                <MenuItem
                  onClick={() => {
                    handleJoinGroup(item);
                    setOpen(false);
                  }}
                >
                  <Typography style={{ color: colors.grey[300] }}>
                    {t(item?.joined ? 'button.leave' : 'button.join')}
                  </Typography>
                </MenuItem>
              </MenuList>
            </Paper>
          </Box>
        </Popper>
      </Box>

      {!!item?.joined && !!item?.unreadCount && item?.unreadCount > 0 && (
        <Box>
          <Typography style={{ color: colors.grey[100] }}>({item?.unreadCount || 0})</Typography>
        </Box>
      )}
    </Box>
  );
};
