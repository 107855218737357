import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

import * as selectors from './selectors';
import * as sagas from './sagas';

/* ---------- Action Creators and Types ---------- */
const { Types, Creators } = createActions(
  {
    getCompetitionError: ['error'],
    getCompetitionStart: ['id'],
    getCompetitionSuccess: ['competition'],

    getCompetitionsError: ['error'],
    getCompetitionsStart: ['userId'],
    getCompetitionsSuccess: ['competitions'],

    cancelCompetitionError: ['error'],
    cancelCompetitionStart: ['data'],
    cancelCompetitionSuccess: ['competition'],

    paymentCompetitionError: ['error'],
    paymentCompetitionStart: ['data'],
    paymentCompetitionSuccess: ['competition'],

    postCompetitionError: ['error'],
    postCompetitionStart: ['data'],
    postCompetitionSuccess: ['competition'],

    putCompetitionError: ['error'],
    putCompetitionStart: ['data'],
    putCompetitionSuccess: ['competition'],

    clearCompetition: [],
    clearCompetitionResults: [],
  },
  { prefix: 'competitions/' },
);

/* ---------- Initial State ---------- */
const INITIAL_STATE = Immutable({
  data: [],
  loading: false,
  loadingEditTeam: false,
  loadingResults: false,
  loadingPayment: false,
  loadingRegistration: false,
  error: null,
  competition: {},
  competitionResults: [],
  competitionResultsPagination: {},
});

/* ---------- Reducers ---------- */
const loadStartReducer = (state) => state.set('loading', true);
const loadPaymentStartReducer = (state) => state.set('loadingPayment', true);

const getCompetitionsSuccessReducer = (state, action) =>
  state.merge({
    data: action.competitions,
    loading: false,
    error: null,
  });

const getCompetitionSuccessReducer = (state, action) =>
  state.merge({
    competition: action.competition,
    loading: false,
    error: null,
  });

const loadErrorReducer = (state, action) =>
  state.merge({
    loading: false,
    error: { ...action.error },
  });

const loadPaymentErrorReducer = (state, action) =>
  state.merge({
    loadingPayment: false,
    error: action.error,
  });

const postCompetitionSuccessReducer = (state, action) =>
  state.merge({
    loading: false,
    competition: action.competition,
  });

const cancelCompetitionSuccessReducer = (state, action) =>
  state.merge({
    loading: false,
    competition: action.competition,
  });

const paymentCompetitionSuccessReducer = (state, action) =>
  state.merge({
    loadingPayment: false,
    competition: action.competition,
  });

const putCompetitionSuccessReducer = (state, action) =>
  state.merge({
    loading: false,
    competition: action.competition,
  });

const clearCompetitionReducer = (state) =>
  state.merge({
    competition: {},
    loading: false,
    error: null,
  });

const clearCompetitionResultsReducer = (state) =>
  state.merge({
    competitionResults: [],
  });

/* ---------- Main Reducer ---------- */
const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_COMPETITION_START]: loadStartReducer,
  [Types.GET_COMPETITION_SUCCESS]: getCompetitionSuccessReducer,
  [Types.GET_COMPETITION_ERROR]: loadErrorReducer,

  [Types.GET_COMPETITIONS_START]: loadStartReducer,
  [Types.GET_COMPETITIONS_SUCCESS]: getCompetitionsSuccessReducer,
  [Types.GET_COMPETITIONS_ERROR]: loadErrorReducer,

  [Types.CANCEL_COMPETITION_START]: loadStartReducer,
  [Types.CANCEL_COMPETITION_SUCCESS]: cancelCompetitionSuccessReducer,
  [Types.CANCEL_COMPETITION_ERROR]: loadErrorReducer,

  [Types.PAYMENT_COMPETITION_START]: loadPaymentStartReducer,
  [Types.PAYMENT_COMPETITION_SUCCESS]: paymentCompetitionSuccessReducer,
  [Types.PAYMENT_COMPETITION_ERROR]: loadPaymentErrorReducer,

  [Types.POST_COMPETITION_START]: loadStartReducer,
  [Types.POST_COMPETITION_SUCCESS]: postCompetitionSuccessReducer,
  [Types.POST_COMPETITION_ERROR]: loadErrorReducer,

  [Types.PUT_COMPETITION_START]: loadStartReducer,
  [Types.PUT_COMPETITION_SUCCESS]: putCompetitionSuccessReducer,
  [Types.PUT_COMPETITION_ERROR]: loadErrorReducer,

  [Types.CLEAR_COMPETITION]: clearCompetitionReducer,
  [Types.CLEAR_COMPETITION_RESULTS]: clearCompetitionResultsReducer,
});

/* ---------- Exporting ---------- */
export const competitionsActions = Creators;
export const competitionsTypes = Types;
export const competitionsSelectors = selectors;
export const competitionsSagas = sagas;

export default reducer;
