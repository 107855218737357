import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from '@material-ui/core';
import { parse } from 'query-string';
import { LayoutContext } from 'pages/shared/Layout';
import { competitionsActions, competitionsSelectors } from 'modules/competitions';
import { useParams } from 'react-router';
import Show from 'components/Competition/Show';
import { sessionSelectors } from 'modules/session';
import Background from 'core/ui/Background';
import useDeepLinks, { AppScreens } from 'core/useDeepLinks';
import { isMobileSafari } from 'react-device-detect';
import { useLocation } from 'react-router-dom';
import { persistAuthHeaders } from 'core/api/auth-headers';
import { persistData } from 'core/utils/session-storage';
import BannerCompetition from 'components/Competition/Show/BannerCompetition';
import { channelsActions, channelsSelectors } from 'modules/channels';

const CompetitionShow = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const layoutContext = useContext(LayoutContext);
  const userSession = useSelector((state) => sessionSelectors.getUser(state));
  const competition = useSelector((state) => competitionsSelectors.getCompetition(state));
  const channel = useSelector((state) => channelsSelectors.getChannel(state));
  const { id, tab, embedTab } = useParams();
  const location = useLocation();

  const parsed = location && location.search ? parse(location?.search) : null;

  const workoutId = parsed?.workoutId;

  useEffect(() => {
    if (parsed && parsed.token) {
      persistAuthHeaders(persistData)(JSON.parse(parsed.token));
      setTimeout(() => {
        window.location = workoutId
          ? `/competition/view/${id}?workoutId=${workoutId}`
          : `/competition/view/${id}`;
      }, 300);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    dispatch(competitionsActions.getCompetitionStart(id));

    return () => {
      dispatch(competitionsActions.clearCompetition());
    };
  }, [dispatch, id, userSession?.id]);

  const deep = useDeepLinks();

  useEffect(() => {
    if (isMobileSafari) {
      deep.openInApp({ screen: AppScreens.Competition.View, param: id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (competition?.channel?.id && !channel?.id) {
      dispatch(channelsActions.getChannelStart(competition?.channel?.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel, competition]);

  useEffect(() => {
    if (layoutContext) {
      layoutContext.setTitleBar(t('title.competition').toUpperCase());
      layoutContext.setActiveMenu('');
    }
  }, [layoutContext, t]);

  return (
    <Box style={{ marginTop: -5 }}>
      <Background
        imageUrl={competition?.backgroundImage?.image?.url}
        heightBanner={0}
        heightHeader={layoutContext?.heightHeader}
        hexBgColor={competition?.backgroundColor}
      >
        <Container maxWidth="xl">
          <Box pt={3} />
          {competition?.banner && <BannerCompetition competition={competition} />}

          <Box pb={20}>
            <Show workoutId={workoutId} tab={tab} embedTab={embedTab} />
          </Box>
        </Container>
      </Background>
    </Box>
  );
};

export default CompetitionShow;
