import api from 'core/api';

export const storeCategoriesModule = 'storeCategories';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const actions = {
  getStoreCategories: {
    module: storeCategoriesModule,
    name: 'getStoreCategories',
    api: (params) => api.get(`/store/categories`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: ['data'],
    },
    state: loadingStates,
  },
  postStoreCategories: {
    module: storeCategoriesModule,
    name: 'postStoreCategories',
    api: (params) => api.post(`/store/categories`, params),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  putStoreCategories: {
    module: storeCategoriesModule,
    name: 'putStoreCategories',
    api: (data) => api.put(`/store/categories/${data.id}`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  putOrderCategory: {
    module: storeCategoriesModule,
    name: 'putOrderCategory',
    api: (data) => api.put(`/store/categories/${data.storeCategoryId}/change_order`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [],
    },
    state: loadingStates,
  },
};

export default {
  actions,
  state: {
    loading: false,
    data: [],
  },
};
