import React, { useContext, useEffect, useState } from 'react';
import Box from 'core/ui/Box';
import { Refresh } from '@material-ui/icons';
import { ButtonPrimary } from 'core/ui/Button';
import { WorkflowContext } from 'contexts';
import { useTranslation } from 'react-i18next';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import SFDataTable from 'core/ui/DataTable';
import Modules from 'modules';
import useActions from 'modules/map/useActions';
import { gymDate, time } from 'core/utils/formatters';
import { Chip, IconButton, Link, colors } from '@material-ui/core';
import Typography, { fontStyle } from 'core/ui/Typography';
import useSelectors from 'modules/map/useSelectors';
import { automationWorkflowsTypesModule } from 'modules/automationWorkflowsTypes';
import LoaderSm from 'core/ui/LoaderSm';
import MIcon from 'core/ui/MIcon';
import useConfirmation from 'core/useConfirmation';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import { useDebounce } from 'use-debounce';

const dateOptions = ['day_30', 'day_0', 'day_1', 'week_0', 'week_1', 'month_0', 'month_1'];
const statusOptions = ['executed', 'waiting'];

export const CardStatus = ({ status }) => {
  let bgColor = status === 'executed' ? colors.lightGreen[100] : colors.grey[100];
  if (status === 'failed') {
    // eslint-disable-next-line prefer-destructuring
    bgColor = colors.red[100];
  }
  return (
    <Box>
      <Chip
        color="primary"
        style={{
          backgroundColor: bgColor,
        }}
        size="small"
        label={
          <Typography
            style={{
              ...fontStyle.small,
              color: status === 'executed' ? colors.lightGreen[800] : colors.grey[800],
            }}
          >
            {status?.toUpperCase()}
          </Typography>
        }
      />
    </Box>
  );
};

export default function WorkflowExecutions() {
  const { t } = useTranslation();
  const { request } = useActions();
  const loading = useSelectors(automationWorkflowsTypesModule, 'loading');
  const workFlowContext = useContext(WorkflowContext);
  const { confirmation } = useConfirmation();
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState(null);
  const [perPage, setPerPage] = useState(10);
  const [queryText] = useDebounce(workFlowContext?.executionFilters?.queryText, 500);

  // eslint-disable-next-line no-underscore-dangle
  const workflowTypes = workFlowContext?.workflowTypes?.filter((x) => !x._destroy);

  const getExectionMembers = (newPage = 1) => {
    request({
      action: Modules.automationWorkflowsTypes.actions.getExecutionsUsers,
      data: {
        ownerableId: workFlowContext?.channelId,
        ownerableType: 'Channel',
        workflowId: workFlowContext?.workflow?.id,
        page: newPage,
        perPage,
        ...(workFlowContext?.executionFilters || {}),
      },
      options: {
        onSuccess: (respData, pag) => {
          setPagination(pag);
          setData(respData);
        },
      },
    });
  };

  const deleteUser = (item) => {
    confirmation({
      description: t('areYouSure'),
      yesClick: () => {
        request({
          action: Modules.automationWorkflowsTypes.actions.deleteExecutionsUser,
          data: {
            ownerableId: workFlowContext?.channelId,
            ownerableType: 'Channel',
            workflowId: workFlowContext?.workflow?.id,
            id: item?.id,
          },
          options: {
            onSuccess: () => {
              setData((prev) => prev.filter((x) => x.id !== item?.id));
            },
          },
        });
      },
    });
  };

  useEffect(() => {
    if (workFlowContext?.executionFilters) {
      getExectionMembers(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    perPage,
    queryText,
    workFlowContext?.executionFilters?.date,
    workFlowContext?.executionFilters?.workflowsTypeId,
    workFlowContext?.executionFilters?.status,
  ]);

  const columns = [
    {
      name: '',
      selector: '',
      sortable: true,
      cell: (row) => <Box>{row?.automationWorkflowsTypesExecutionId}</Box>,
    },
    {
      name: 'Member',
      selector: '',
      sortable: true,
      cell: (row) => (
        <Box>
          {row?.user?.name ? (
            <Link
              className="hover"
              target="_blank"
              href={`/channel/manage/${workFlowContext?.channelId}/members/${row?.user?.id}`}
            >
              <Box my={1}>
                <Typography>{row?.user?.name}</Typography>
              </Box>
            </Link>
          ) : (
            <Box my={1}>
              <Typography>{row?.user?.name || row?.name}</Typography>
              <Typography>{row?.user?.email || row?.email}</Typography>
              <Typography>{row?.user?.phone || row?.phone}</Typography>
            </Box>
          )}
        </Box>
      ),
    },
    {
      name: 'Action',
      selector: '',
      sortable: true,
      cell: (row) => (
        <Box display="flex" alignItems="center" className="gap-5">
          <Box
            style={{
              backgroundColor: colors.grey[300],
              borderRadius: 5,
            }}
            display="flex"
            alignItems="center"
            justifyContent="center"
            p={1}
          >
            <MIcon
              icon={row?.execution?.workflowsType?.automationType?.icon}
              color={colors.grey[500]}
            />
          </Box>
          <Typography noWrap>{row?.execution?.workflowsType?.name}</Typography>
        </Box>
      ),
    },
    {
      name: 'Status',
      selector: '',
      sortable: true,
      cell: (row) => <CardStatus status={row?.execution?.status} />,
    },
    {
      name: 'Executed On',
      selector: 'fileType',
      sortable: true,
      cell: (row) => (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <Typography className="roboto-medium" noWrap>
            {gymDate(row?.execution?.startAt)}
          </Typography>
          <Typography className="roboto-medium" style={fontStyle.small} noWrap>
            {time(row?.execution?.startAt)}
          </Typography>
        </Box>
      ),
    },
    {
      name: '',
      selector: '',
      sortable: true,
      cell: (row) => (
        <Box display="flex" justifyContent="center" alignItems="center" className="gap-5">
          <IconButton
            size="small"
            onClick={() => workFlowContext?.setCurrentType({ open: true, executionsTypeUser: row })}
          >
            <MIcon icon="info" />
          </IconButton>
          <IconButton size="small" onClick={() => deleteUser(row)}>
            <MIcon icon="delete" />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <Box p={5}>
      <Typography variant="h5" color="secondary" mb={2}>
        {t('label.executionLogs')}
      </Typography>
      <Box
        display="flex"
        className="gap-5"
        alignItems="flex-end"
        justifyContent="flex-end"
        flexWrap="wrap"
        mb={4}
      >
        <LoaderSm loading={loading} />
        <Box>
          <TextFieldRounded
            value={workFlowContext?.executionFilters?.queryText}
            onChange={(v) =>
              workFlowContext.setExecutionFilters((prev) => ({ ...prev, queryText: v }))
            }
            placeholder="Search by users names"
            style={{ height: 16, minWidth: 200 }}
          />
        </Box>
        <Box>
          <SelectBoxObject
            options={dateOptions.map((x) => ({ id: x, name: t(`label.dateOptions.${x}`) }))}
            style={{ minWidth: 200 }}
            placeholder="Date"
            emptyItem
            value={workFlowContext?.executionFilters?.date}
            setValue={(v) => workFlowContext.setExecutionFilters((prev) => ({ ...prev, date: v }))}
          />
        </Box>
        <Box>
          <SelectBoxObject
            options={statusOptions.map((x) => ({ id: x, name: t(`label.statusOptions.${x}`) }))}
            style={{ minWidth: 200 }}
            placeholder="All Status"
            emptyItem
            value={workFlowContext?.executionFilters?.status}
            setValue={(v) =>
              workFlowContext.setExecutionFilters((prev) => ({ ...prev, status: v }))
            }
          />
        </Box>
        <Box>
          <SelectBoxObject
            options={workflowTypes}
            style={{ minWidth: 200 }}
            placeholder="All Actions"
            emptyItem
            value={workFlowContext?.executionFilters?.workflowsTypeId}
            setValue={(v) =>
              workFlowContext.setExecutionFilters((prev) => ({ ...prev, workflowsTypeId: v }))
            }
          />
        </Box>

        <ButtonPrimary onClick={() => getExectionMembers(1)} disabled={loading}>
          <Refresh fontSize="small" />
        </ButtonPrimary>
      </Box>

      <Box>
        <SFDataTable
          columns={columns}
          data={data}
          pagination
          showExport={false}
          noHeader
          paginationPerPage={perPage}
          onChangePage={(p) => getExectionMembers(p)}
          paginationServer
          paginationDefaultPage={pagination?.currentPage}
          paginationTotalRows={pagination?.totalCount}
          onChangeRowsPerPage={(newValue) => {
            setPerPage(newValue);
          }}
        />
      </Box>
    </Box>
  );
}
