import React, { useState } from 'react';
import Box from 'core/ui/Box';
import { date } from 'core/utils/formatters';
import { dateAdd } from 'core/utils/formatters/date';
import Paper from 'core/ui/Paper';
import { secondary } from 'core/ui/Colors';
import Typography, { fontStyle } from 'core/ui/Typography';
import { Dialog } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { ButtonPrimarySm } from 'core/ui/Button';
import { useTranslation } from 'react-i18next';
import { CalendarToday } from '@material-ui/icons';
import moment from 'moment';
import LoaderSm from 'core/ui/LoaderSm';

export default function WorkoutsDateSwipe({
  partner,
  loading,
  currentDate,
  setCurrentDate,
  setModalCloneWeek,
}) {
  const [openDate, setOpenDate] = useState(false);
  const { t } = useTranslation();

  const formatWeek = (dateBase) => {
    const fromDate = moment(dateBase).startOf('week');
    const toDate = moment(dateBase).endOf('week');

    return `${date(fromDate, partner?.useUsDateFormat ? 'MMM DD' : 'DD MMM')} - ${date(
      toDate,
      partner?.useUsDateFormat ? 'MMM DD, yy' : 'DD MMM, yy',
    )}`;
  };

  const ButtomSwipe = ({ right, label, onClick }) => {
    const swipeStyle = { backgroundColor: secondary, color: 'white', width: 80 };
    if (right === true) {
      swipeStyle.borderTopRightRadius = 5;
      swipeStyle.borderBottomRightRadius = 5;
    } else {
      swipeStyle.borderTopLeftRadius = 5;
      swipeStyle.borderBottomLeftRadius = 5;
    }
    return (
      <Box onClick={onClick} p={3} style={swipeStyle} className="hover">
        <Typography align="center" style={fontStyle.small}>
          {label}
        </Typography>
      </Box>
    );
  };

  const onPrev = (cDate) => {
    setCurrentDate(cDate);
  };
  const onNext = (cDate) => {
    setCurrentDate(cDate);
  };

  return (
    <Box>
      <Dialog open={openDate} onClose={() => setOpenDate(false)}>
        <Box>
          <DatePicker
            autoOk
            format="yyyy-MM-dd"
            variant="static"
            openTo="date"
            value={date(currentDate)}
            onChange={(params) => {
              setCurrentDate(date(params, 'YYYY-MM-DD'));
              setOpenDate(false);
            }}
          />
        </Box>
      </Dialog>
      <Paper>
        <Box display="flex">
          <Box>
            <ButtomSwipe
              right={false}
              label="Previous"
              onClick={() => onPrev(dateAdd(currentDate, -1, 'week'))}
            />
          </Box>
          <Box flexGrow={1} display="flex" justifyContent="center" alignItems="center">
            <LoaderSm loading={loading} />
            <Typography
              className="hover"
              onClick={() => setOpenDate(true)}
              mr={2}
              color="secondary"
              size="medium"
            >
              <CalendarToday style={{ fontSize: 14 }} />
              &nbsp;
              {formatWeek(currentDate)}
            </Typography>
            <ButtonPrimarySm onClick={setModalCloneWeek}>{t('button.cloneWeek')}</ButtonPrimarySm>
          </Box>
          <Box>
            <ButtomSwipe
              right
              label="Next"
              onClick={() => onNext(dateAdd(currentDate, 1, 'week'))}
            />
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}

WorkoutsDateSwipe.propTypes = {};

WorkoutsDateSwipe.defaultProps = {};
