import React, { useEffect, useRef, useState } from 'react';
import { conversationsModule } from 'modules/conversations';
import useSelectors from 'modules/map/useSelectors';
import { sessionSelectors } from 'modules/session';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form-v5';
import Box from 'core/ui/Box';
import EmojiPicker from 'emoji-picker-react';

import MIcon from 'core/ui/MIcon';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import LoaderSm from 'core/ui/LoaderSm';
import { ClickAwayListener, Popover, colors } from '@material-ui/core';
import { chatColors } from 'core/ui/Colors';
import { InsertEmoticon } from '@material-ui/icons';
import Dropzone from 'react-dropzone';
import Typography from 'core/ui/Typography';
import uuid from 'react-uuid';
import notifications from 'modules/notifications';
import Paper from 'core/ui/Paper';
import LabelTextField from 'core/ui/LabelTextField';
import useSendMessages from './useSendMessages';
import BucketUpload from './BucketUpload';
import GifSelector from './GifSelector';

export default ({ editMessage, setEditMessage, setAction, action }) => {
  const { t } = useTranslation();
  const groupsChat = useSelectors(conversationsModule, 'groupsChat');
  const loadingCreate = useSelectors(conversationsModule, 'loadingCreate');
  const [openOptions, setOpenOptions] = useState(false);
  const currentUser = useSelector((state) => sessionSelectors.getUser(state));
  const btnRef = useRef();
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [openGifs, setOpenGifs] = useState(false);
  const { watch, handleSubmit, setValue, register } = useForm({ defaultValues: {} });
  const { create, updateMessage } = useSendMessages();

  useEffect(() => {
    register('text');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (editMessage?.text) {
      setValue('text', editMessage?.text);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMessage?.id]);

  const clearData = () => {
    setOpenGifs(false);
    setOpenOptions(false);
    setAction({});
    setValue('text', '');
    setFiles([]);
  };

  function onSubmit({ text }) {
    if (editMessage?.id) {
      updateMessage({
        id: editMessage?.id,
        groupsChatId: groupsChat?.id,
        text,
        callback: (data) => {
          setEditMessage({ open: false, updatedItem: data });

          clearData();
        },
      });
      return;
    }

    create({
      groupsChatId: groupsChat?.id,
      parentId: action?.message?.id,
      text,
      mediaFilesAttributes: files
        ?.filter((x) => x.complete)
        .map((x) => ({
          ...x,
          userId: currentUser?.id,
          streamMedia: true,
          provider: 'aws_s3',
        })),
      callback: (data) => {
        setEditMessage({ open: false, addItem: data });

        clearData();
      },
    });
  }

  const sendGif = (url) => {
    create({
      groupsChatId: groupsChat?.id,
      text: url,
      parentId: action?.message?.id,
      kind: 'gif',
      callback: (data) => {
        setEditMessage({ open: false, addItem: data });
        setOpenOptions(false);
        openGifs(false);
        setEditMessage({});
        setAction({});
        setValue('message', '');
      },
    });
  };

  useEffect(() => {
    if (!openOptions) {
      setOpenGifs(false);
    }
  }, [openOptions]);

  const onKeyPress = (e) => {
    if (e.charCode === 13 && e.shiftKey) {
      setValue(
        'text',
        `${watch('text') || ''}
      `,
      );
    } else if (e.charCode === 13) {
      e.preventDefault();
      handleSubmit(onSubmit)();
    }
  };

  const setCompleteFile = (completeFile) => {
    setFiles((prev) =>
      prev.map((item) => {
        if (item.uuid === completeFile.uuid) {
          return completeFile;
        }

        return item;
      }),
    );
  };

  const removeFile = (itemFile) => {
    setFiles((prev) => prev.filter((x) => x.uuid !== itemFile.uuid));
  };

  return (
    <Box
      style={{
        backgroundColor: chatColors.input,
        borderTopRightRadius: 10,
        borderTopLeftRadius: 5,
      }}
      p={2}
    >
      {action?.type === 'reply' && (
        <Paper style={{ backgroundColor: chatColors.message }} p={2}>
          <Box>
            <Box display="flex">
              <MIcon icon="reply" size={16} color={colors.grey[400]} />
              <Box display="flex" flexWrap="wrap" flexGrow={1} style={{ color: colors.grey[400] }}>
                <Typography>{t('message.reply')}</Typography>
              </Box>
              <Box onClick={() => setAction({})}>
                <MIcon icon="close" size={16} color={colors.grey[400]} />
              </Box>
            </Box>
            <Box m={2}>
              {action?.message?.kind === 'gif' ? (
                <Box>
                  <img
                    style={{ width: 30 }}
                    alt={action?.message?.user?.name}
                    src={action?.message?.text}
                  />
                </Box>
              ) : (
                <LabelTextField style={{ color: colors.grey[500] }} value={action?.message?.text} />
              )}
            </Box>
            {action?.message?.mediaFiles?.length > 0 && (
              <Box classes="flex flex-row">
                {action?.message?.mediaFiles?.map((media) => (
                  <Box key={media?.id}>
                    <img style={{ width: 16 }} src={media?.url} alt={media?.name} />
                  </Box>
                ))}
              </Box>
            )}

            {action?.message?.object && (
              <Typography style={{ color: colors.grey[300] }}>
                {action?.message?.object?.name}
              </Typography>
            )}
          </Box>
        </Paper>
      )}

      {openOptions && (
        <Box>
          <Dropzone
            onDrop={(acceptedFiles) =>
              acceptedFiles.forEach((itemFile) => {
                setFiles((prev) => [...prev, { file: itemFile, uuid: uuid() }]);
              })
            }
            accept={['.png', '.gif', '.jpeg', '.jpg']}
            onDropRejected={() => {
              dispatch(notifications.warning('Invalid file, please add images only.'));
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                className="hover"
                p={2}
                py={4}
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                <MIcon icon="image" color={colors.grey[300]} size={30} />
                <Typography style={{ color: colors.grey[400] }}>
                  Drag 'n' drop the image here, or click to select files
                </Typography>
              </Box>
            )}
          </Dropzone>
          <Box display="flex" alignItems="center" p={2} className="gap-5" mt={2}>
            {files?.map((itemFile) => (
              <BucketUpload
                key={itemFile?.uuid}
                itemFile={itemFile}
                setFile={(completeFile) => setCompleteFile(completeFile)}
                removeFile={removeFile}
              />
            ))}
          </Box>
        </Box>
      )}

      <Box display="flex" alignItems="center" className="gap-10">
        <Box>
          <Box
            style={{ backgroundColor: colors.grey[300], borderRadius: 50, width: 30, height: 30 }}
            onClick={() => setOpenOptions(!openOptions)}
            display="flex"
            alignItems="center"
            justifyContent="center"
            className="hover"
          >
            <MIcon
              icon={openOptions ? 'close' : 'add'}
              style={{ fontSize: 20, color: colors.grey[700] }}
            />
          </Box>
        </Box>
        <Box>
          <GifSelector onGifSelected={(url) => sendGif(url)} />
        </Box>
        <Box>
          <Popover
            open={isOpen}
            anchorEl={btnRef.current}
            onClose={() => setIsOpen(false)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Box style={{ height: 300, width: 300 }}>
              <EmojiPicker
                style={{ position: 'absolute' }}
                open={isOpen}
                onEmojiClick={(v) => {
                  setValue('text', `${watch('text') || ''}${v?.emoji}`);
                  // create({
                  //   groupsChatId: groupsChat?.id,
                  //   text: v?.emoji,
                  //   callback: (data) => {
                  //     setEditMessage({ open: false, addItem: data });
                  //   },
                  // });

                  setIsOpen(false);
                }}
                defaultSkinTone="#00e410"
              />
            </Box>
          </Popover>
          <ClickAwayListener onClickAway={() => setIsOpen(false)}>
            <Box>
              <Box
                style={{
                  backgroundColor: colors.grey[300],
                  borderRadius: 50,
                  width: 30,
                  height: 30,
                }}
                onClick={() => setIsOpen(true)}
                display="flex"
                alignItems="center"
                justifyContent="center"
                className="hover"
              >
                <InsertEmoticon
                  innerRef={btnRef}
                  style={{ fontSize: 20, color: colors.grey[700] }}
                />
              </Box>
            </Box>
          </ClickAwayListener>
        </Box>
        <Box flexGrow={1}>
          <Box display="flex" alignItems="center" style={{ position: 'relative' }}>
            <TextFieldRounded
              placeholder={t('messages.placeholder')}
              value={watch('text')}
              multiline
              style={{
                paddingLeft: 4,
                // backgroundColor: colors.grey[800],
                color: colors.grey[200],
              }}
              onChange={(v) => setValue('text', v)}
              paperStyle={{
                maxHeight: 200,
                minHeight: 60,
                overflow: 'scroll',
                backgroundColor: colors.grey[800],
              }}
              onKeyPress={onKeyPress}
            />
          </Box>
        </Box>

        <Box>
          <Box onClick={handleSubmit(onSubmit)} px={2} className="hover">
            {loadingCreate ? (
              <LoaderSm loading />
            ) : (
              <MIcon icon={editMessage?.id ? 'edit' : 'send'} size={24} color={colors.grey[100]} />
            )}
          </Box>
        </Box>
        {!!editMessage?.id && (
          <Box
            onClick={() => {
              setEditMessage({ open: false });
              setValue('text', '');
            }}
            className="hover"
          >
            <MIcon icon="close" size={26} color={colors.grey[100]} />
          </Box>
        )}
      </Box>
    </Box>
  );
};
