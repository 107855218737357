import api from 'core/api';
import { call, put } from 'redux-saga/effects';

export const usersCalendarsRegistrationsModule = 'usersCalendarsRegistrations';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const loadingRegistrationsStates = {
  start: { loadingRegistrations: true },
  error: { loadingRegistrations: false },
  success: { loadingRegistrations: false },
};

const actions = {
  getRegistrations: {
    module: usersCalendarsRegistrationsModule,
    name: 'getRegistrations',
    api: (params) =>
      api.post(
        `channels/${params?.channelId}/users_calendars/users_calendars_registrations/search`,
        params,
      ),
    *sagas(Creators, { params, options }) {
      try {
        const resp = yield call(actions.getRegistrations.api, params);
        yield put(Creators.getRegistrationsSuccess());
        if (options.onSuccess) {
          options.onSuccess(resp.data, resp.pagination);
        }
      } catch (error) {
        if (options.onError) {
          options.onError(error);
        }
        yield put(Creators.getRegistrationsError());
      }
    },
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingRegistrationsStates,
  },
  createRegistration: {
    module: usersCalendarsRegistrationsModule,
    name: 'createRegistration',
    api: (params) =>
      api.post(
        `channels/${params?.channelId}/users_calendars/${params?.usersCalendarId}/users_calendars_registrations`,
        params,
      ),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  deleteRegistration: {
    module: usersCalendarsRegistrationsModule,
    name: 'deleteRegistration',
    api: (params) =>
      api.delete(
        `channels/${params?.channelId}/users_calendars/${params?.usersCalendarId}/users_calendars_registrations/${params?.id}`,
        params,
      ),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  updateRegistration: {
    module: usersCalendarsRegistrationsModule,
    name: 'updateRegistration',
    api: (params) =>
      api.put(
        `channels/${params?.channelId}/users_calendars/${params?.usersCalendarId}/users_calendars_registrations/${params?.id}`,
        params,
      ),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
};

const usersCalendarsRegistrationsActionsAndStates = {
  actions,
  state: {
    error: null,
    loading: false,
  },
};

export default usersCalendarsRegistrationsActionsAndStates;
