import { makeStyles } from '@material-ui/core';
import { secondaryLight } from '../Colors';

const useStylesColorPicker = makeStyles(() => ({
  boxPreviewColor: {
    width: 33,
    height: 32,
    borderRadius: 5,
    border: `0.5px solid ${secondaryLight}`,
    display: 'block',
  },
  inputColor: {
    width: 90,
  },
}));

export default useStylesColorPicker;
