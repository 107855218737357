import React from 'react';
import Box from 'core/ui/Box';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import AvatarCoach from './AvatarCoach';

const useStyles = makeStyles({
  boxCoach: {
    marginLeft: -20,
    '&:hover': {
      cursor: 'pointer',
      zIndex: '999 !important',
    },
  },
  boxFirstCoach: {
    '&:hover': {
      cursor: 'pointer',
      zIndex: '999 !important',
    },
  },
});

const AvatarCoaches = ({ coaches, size, buttonColor }) => {
  const classes = useStyles();
  return (
    <Box display="flex">
      {coaches?.map((c, i) => (
        <Box
          key={i.toString()}
          className={i === 0 ? classes.boxFirstCoach : classes.boxCoach}
          style={{
            zIndex: coaches.length - i,
          }}
        >
          <AvatarCoach key={i.toString()} user={c} size={size} buttonColor={buttonColor} />
        </Box>
      ))}
    </Box>
  );
};

AvatarCoaches.propTypes = {
  size: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  coaches: PropTypes.array,
};

AvatarCoaches.defaultProps = {
  size: 35,
  coaches: [],
};

export default AvatarCoaches;
