import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import {
  TableContainer,
  TableHead,
  Table,
  TableCell,
  TableRow,
  TableBody,
  Paper,
  IconButton,
} from '@material-ui/core';
import Typography, { fontStyle } from 'core/ui/Typography';
import { Edit } from '@material-ui/icons';
import { channelCurrency } from 'core/utils/formatters';
import { ChannelContext } from '..';

const ListTaxes = ({ channel, onEditClick }) => {
  const { t } = useTranslation();
  const formContext = useContext(ChannelContext);
  const channelsTaxes = formContext.watch('channelsTaxes') || [];

  return (
    <Box mt={2}>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>{t('label.name')}</TableCell>
              <TableCell align="center">{t('label.amountType')}</TableCell>
              <TableCell align="center">{t('label.amount')}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {channelsTaxes &&
              channelsTaxes.map((row, index) => (
                <TableRow key={index.toString()}>
                  <TableCell>
                    <Box>
                      <Typography variant="body1" style={fontStyle.small} ml={1} color="secondary">
                        {row.name}
                      </Typography>
                    </Box>
                  </TableCell>

                  <TableCell align="center">
                    <Typography variant="body1" style={fontStyle.small} ml={1} color="secondary">
                      {row.amountType?.toUpperCase()}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body1" style={fontStyle.small} ml={1} color="secondary">
                      {row?.amountType === 'money'
                        ? channelCurrency(row.amount, channel?.currency)
                        : `${row.amount}%`}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <IconButton onClick={() => onEditClick(row)}>
                      <Edit />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      {channelsTaxes?.length === 0 && (
        <Typography align="center" m={3} variant="h6">
          {t('label.createTheTaxAndAssignInProductsAndMemberships')}
        </Typography>
      )}
    </Box>
  );
};

ListTaxes.propTypes = {
  onEditClick: PropTypes.func.isRequired,
};

export default ListTaxes;
