import api from 'core/api';

export const channelWorkoutsProgramsModule = 'channelWorkoutsPrograms';

const loadingViewStates = {
  start: { loadingView: true },
  error: { loadingView: false },
  success: { loadingView: false },
};

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const loadingCloneDayStates = {
  start: { loadingCloneDay: true },
  error: { loadingCloneDay: false },
  success: { loadingCloneDay: false },
};

const actions = {
  getProgramView: {
    module: channelWorkoutsProgramsModule,
    name: 'getProgramView',
    api: (params) => api.get(`/channels/${params.channelId}/program_view`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingViewStates,
  },

  saveSectionData: {
    module: channelWorkoutsProgramsModule,
    name: 'saveSectionData',
    api: (params) => api.post(`/channels/${params.channelId}/save_section`, params),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },

  saveOrderSections: {
    module: channelWorkoutsProgramsModule,
    name: 'saveOrderSections',
    api: (params) => api.post(`/channels/${params.channelId}/save_order_sections`, params),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },

  removeSection: {
    module: channelWorkoutsProgramsModule,
    name: 'removeSection',
    api: (params) => api.put(`/channels/${params.channelId}/remove_section`, params),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },

  moveSection: {
    module: channelWorkoutsProgramsModule,
    name: 'moveSection',
    api: (params) => api.put(`/channels/${params.channelId}/move_section`, params),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },

  cloneSection: {
    module: channelWorkoutsProgramsModule,
    name: 'cloneSection',
    api: (params) => api.post(`/channels/${params.channelId}/clone_section`, params),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },

  cloneDay: {
    module: channelWorkoutsProgramsModule,
    name: 'cloneDay',
    api: (params) => api.post(`/channels/${params.channelId}/clone_day`, params),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingCloneDayStates,
  },
};

export default {
  actions,
  state: {
    loading: false,
    loadingView: false,
  },
};
