import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from 'core/ui/Typography';
import Box from 'core/ui/Box';
import LoaderSm from 'core/ui/LoaderSm';
import SFDataTable from 'core/ui/DataTable';
import ImageMedia from 'components/Profile/ImageMedia';
import useSelectors from 'modules/map/useSelectors';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import Paper from 'core/ui/Paper';
import { InputWrapper } from 'core/ui/ChosenComponents';
import { ButtonPrimary } from 'core/ui/Button';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import LabelPhone from 'core/ui/InputPhone/LabelPhone';
import { competitionsUsersModule } from 'modules/competitionsUsers';
import { Business, Info } from '@material-ui/icons';
import MenuActions from './MenuActions';
import ModalCustomFields from '../Athletes/ModalCustomFields';

const VolunteersAndVendors = ({ competition, regType }) => {
  const { t } = useTranslation();

  const [users, setUsers] = useState([]);
  const [pagination, setPagination] = useState(null);
  const loading = useSelectors(competitionsUsersModule, 'loadingUsers');
  const [perPage, setPerPage] = useState(10);
  const [query, setQuery] = useState('');
  const { request } = useActions();

  const [currentInfo, setCurrentInfo] = useState();

  const search = (page = 1) => {
    request({
      action: Modules.competitionsUsers.actions.getCompetitionsUser,
      data: {
        competitionId: competition?.id,
        regType,
        page,
        perPage,
        query,
      },
      options: {
        onSuccess: (resp, pag) => {
          setUsers(resp);
          setPagination(pag);
        },
      },
    });
  };

  const exportUsers = () => {
    request({
      action: Modules.competitionsUsers.actions.exportUsers,
      data: {
        competitionId: competition?.id,
        exportFileName: `Competition-${regType}-${competition?.id}.csv`,
        regType,
      },
    });
  };

  useEffect(() => {
    if (competition && competition.id) {
      search(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [competition, perPage]);

  const columns = [
    {
      name: '',
      selector: 'name',
      cell: (member) => (
        <Box my={1}>
          <ImageMedia
            user={member}
            image={member?.user?.image || {}}
            size={50}
            heightCountry={15}
            sizeCountry="1em"
          />
        </Box>
      ),
      sortable: true,
      width: '60px',
    },
    {
      name: 'User',
      selector: 'name',
      cell: (member) => (
        <Box>
          <Typography>{member?.user?.name}</Typography>
          {member?.kind === 'vendor' && (
            <Box display="flex" alignItems="center" className="gap-5">
              <Business fontSize="small" />
              <Typography>{member?.company}</Typography>
            </Box>
          )}
          {competition.isTeam && <Typography color="primary">{member?.team}</Typography>}
        </Box>
      ),
      sortable: true,
      width: '250px',
    },
    {
      name: 'Category',
      selector: 'competitionsCategoryId',
      cell: (member) => (
        <Typography>
          {member?.competitionsUsersCategories?.map((x) => x?.competitionsCategory?.name).join(',')}
        </Typography>
      ),
      sortable: true,
    },
    {
      name: 'Email',
      selector: 'email',
      cell: (member) => <Typography>{member?.user?.email}</Typography>,
      sortable: true,
    },
    {
      name: 'Phone',
      selector: 'phone',
      cell: (member) => (
        <Typography>{member?.user?.phone && <LabelPhone value={member?.user?.phone} />}</Typography>
      ),
      sortable: true,
    },
  ];

  if (regType === 'vendor') {
    columns.push({
      name: 'Company',
      selector: 'Company',
      cell: (member) => <Typography mr={1}>{member?.company}</Typography>,
      sortable: true,
    });
  }

  columns.push({
    name: '',
    cell: (member) => (
      <MenuActions refresh={search} object={member} competition={competition} regType={regType} />
    ),
    sortable: true,
  });

  if (competition?.customFields) {
    columns.push({
      name: 'Info',
      cell: (member) => (
        <Box
          onClick={() => setCurrentInfo({ open: true, member, customFields: member?.customFields })}
        >
          <Info />
        </Box>
      ),
    });
  }

  return (
    <>
      <ModalCustomFields
        competition={competition}
        close={() => setCurrentInfo({})}
        refresh={search}
        open={currentInfo?.open}
        customFields={currentInfo?.customFields}
        member={currentInfo?.member}
        regType={regType}
      />
      <Paper className="paper-rounded opacity-80" p={3}>
        <Box mb={3}>
          <Box display="flex" alignItems="center">
            <InputWrapper>
              <Box display="flex" style={{ width: '100%' }}>
                <TextFieldRounded
                  value={query}
                  onChange={(value) => setQuery(value)}
                  style={{ minWidth: 30, width: '100%', margin: '4px' }}
                  placeholder={t('channel.members.search')}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      search();
                    }
                  }}
                />
              </Box>
            </InputWrapper>
            <Box display="flex" justifyContent="center">
              <LoaderSm loading={loading} />
            </Box>
            <Box ml={1}>
              <ButtonPrimary
                buttonColor={competition?.buttonColor}
                disabled={loading}
                onClick={() => search()}
              >
                {t('button.search')}
              </ButtonPrimary>
            </Box>
            <Box ml={1}>
              <ButtonPrimary
                buttonColor={competition?.buttonColor}
                disabled={loading}
                onClick={() => exportUsers()}
              >
                {t('button.export')}
              </ButtonPrimary>
            </Box>
          </Box>
        </Box>

        <SFDataTable
          // noHeader
          // noTableHead
          title=""
          paginationRowsPerPageOptions={[10, 25, 50]}
          showPaper={false}
          columns={columns}
          paginationPerPage={perPage}
          data={users}
          pagination
          noDataComponent=""
          onChangePage={(p) => search(p)}
          paginationServer
          paginationDefaultPage={pagination?.currentPage}
          paginationTotalRows={pagination?.totalCount}
          onChangeRowsPerPage={(newValue) => {
            setPerPage(newValue);
          }}
        />
      </Paper>
    </>
  );
};

VolunteersAndVendors.defaultProps = {};

export default VolunteersAndVendors;
