import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import Box from 'core/ui/Box';
import { workoutsSelectors } from 'modules/workouts';
import Rating from 'core/ui/Rating';
import { primary } from 'core/ui/Colors';
import SocialMediaButtons from 'core/ui/SocialMediaButtons';
import { workoutUrlSlug } from 'core/utils/helpers';
import Grid from 'core/ui/Grid';
import { Hidden } from '@material-ui/core';
import Header from './Header';
import GeneralInfo from './Tabs/Overview/GeneralInfo';

const useStyles = makeStyles((theme) => ({
  roundImage: {
    position: 'relative',
    borderRadius: 10,
    overflow: 'hidden',
  },
  colorTitle: {
    color: '#404041',
  },
  boxRating: {
    position: 'absolute',
    marginTop: '-40px',
    marginLeft: 40,
  },
  boxRatingScheduleClasses: {
    position: 'absolute',
    right: 20,
    top: 15,
    marginLeft: 40,
  },
  boxRegister: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  buttons: {
    color: '#fff',
    minWidth: 130,
  },
  boxButtonsHeader: {
    position: 'absolute',
    marginTop: '-45px',
    right: 30,
    cursor: 'pointer',
  },
}));

const ShowHeader = () => {
  const classes = useStyles();

  const loading = useSelector((state) => workoutsSelectors.getLoading(state));
  const workout = useSelector((state) => workoutsSelectors.getWorkout(state));

  const buttonColor = workout?.channel?.customColors?.buttonColor || primary;

  const isScheduleView = workout.coachScheduleSessionId && workout.recordingStatus !== 'done';

  return (
    <Grid container spacing={3}>
      {!isScheduleView && (
        <Grid item xs={12} md={6}>
          <Box className={classes.roundImage}>
            <Header workout={workout} />
            {!workout.isCompetition && workout.active && loading === false && (
              <Box display="flex" justifyContent="flex-start" pr={5}>
                <Box className={classes.boxRating}>
                  <Rating
                    readOnly
                    name="workout_ratings"
                    value={workout.rating ? workout.rating.avg : 0}
                    buttonColor={buttonColor}
                  />
                </Box>
                <Box display="flex" alignItems="center" className={classes.boxButtonsHeader}>
                  <Box ml={2}>
                    <SocialMediaButtons
                      url={workoutUrlSlug(workout)}
                      quote={workout.description}
                      size={20}
                      buttonColor={buttonColor}
                    />
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </Grid>
      )}

      {isScheduleView ? (
        <Grid item xs={12} md={isScheduleView ? 12 : 6} style={{ position: 'relative' }}>
          {isScheduleView && (
            <Box className={classes.boxRatingScheduleClasses} display="flex">
              <Rating
                readOnly
                name="workout_ratings"
                value={workout.rating ? workout.rating.avg : 0}
                buttonColor={buttonColor}
              />
            </Box>
          )}
          <GeneralInfo workout={workout} buttonColor={buttonColor} />
        </Grid>
      ) : (
        <Hidden only={['sm', 'xs']}>
          <Grid item xs={12} md={6} style={{ position: 'relative' }}>
            <GeneralInfo workout={workout} buttonColor={buttonColor} />
          </Grid>
        </Hidden>
      )}
    </Grid>
  );
};

export default ShowHeader;
