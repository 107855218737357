import { Dialog } from '@material-ui/core';
import Box from 'core/ui/Box';
import { ButtonPrimary } from 'core/ui/Button';
import Texture from 'core/ui/Texture';
import Typography from 'core/ui/Typography';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Add } from '@material-ui/icons';
import FormTax from './FormData';
import List from './List';

export default ({ channel }) => {
  const { t } = useTranslation();
  const [openModalForm, setOpenForm] = useState({ open: false });

  const onEditClick = (item) => {
    setOpenForm({ open: true, item });
  };

  return (
    <Box py={5}>
      <Box display="flex" alignItems="flex-start">
        <Box flexGrow={1}>
          <Typography>{t('channel.taxes')}</Typography>
        </Box>
        <Box style={{ minWidth: 110 }}>
          <ButtonPrimary onClick={() => setOpenForm({ open: true })}>
            <Box display="flex" alignItems="center">
              <Add fontSize="small" />
              &nbsp;
              {t('button.createTax')}
            </Box>
          </ButtonPrimary>
        </Box>
      </Box>

      <Dialog
        maxWidth="xs"
        fullWidth
        open={!!openModalForm?.open}
        onClose={() => setOpenForm({ open: false })}
      >
        <Texture>
          <Box p={5}>
            <FormTax
              channel={channel}
              itemEdit={openModalForm?.item}
              callback={() => {
                setOpenForm({ open: false });
              }}
            />
          </Box>
        </Texture>
      </Dialog>

      <Box mt={3}>
        <List onEditClick={onEditClick} channel={channel} />
      </Box>
    </Box>
  );
};
