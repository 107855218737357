import React, { useEffect, useState } from 'react';
import { InputLabel, makeStyles } from '@material-ui/core';
import Box from 'core/ui/Box';
import { Movie } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { primary, secondary } from 'core/ui/Colors';
import { parse } from 'query-string';
import { useLocation } from 'react-router-dom';
import ReactPlayer from 'react-player';
import { ButtonPrimary } from 'core/ui/Button';
import Uploading from './Uploading';

const UploadMobile = ({ bgColor, setUploadModal, compButtonColor, web, showPreview }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [upload, setUpload] = useState({});
  const [buttonColor, setButtonColor] = useState(compButtonColor);
  const useStyles = makeStyles(() => ({
    root: {
      backgroundColor: bgColor || secondary,
      minHeight: '120px',
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    const parsed = parse(location.search);
    if (parsed && parsed.buttonColor && !compButtonColor) {
      setButtonColor(parsed.buttonColor);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (compButtonColor) {
      setButtonColor(compButtonColor);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [compButtonColor]);

  useEffect(() => {
    if (setUploadModal) {
      setUploadModal((prev) => ({ ...prev, ...upload }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upload]);

  const handleCapture = ({ target }) => {
    if (target.files.length) {
      const file = target.files[0];
      setUpload((prev) => ({ ...prev, open: !showPreview, file }));
    }
  };

  const randomId = Math.floor(Math.random() * 101);

  return (
    <Box p={5} className={classes.root}>
      {!upload.file && (
        <div className="tw-mb-2 tw-flex tw-items-center tw-justify-center">
          <div
            style={{
              borderRadius: 5,
              backgroundColor: `#${(buttonColor || '').replace('#', '')}` || primary,
            }}
          >
            <InputLabel htmlFor={`icon-button-file-${randomId}`}>
              <div className="tw-flex tw-h-10 tw-flex-row tw-items-center tw-justify-center tw-gap-x-2 tw-overflow-hidden tw-bg-zinc-500 tw-p-2">
                <Movie size="large" style={{ color: web ? secondary : 'white' }} />
                <div className="tw-flex-1 tw-flex-nowrap tw-text-sm tw-font-semibold tw-text-white">
                  {upload.file ? `${t('workout.uploading')}` : t('workout.upload.selectVideo')}
                </div>
              </div>
            </InputLabel>
          </div>
        </div>
      )}

      {upload.open && (
        <Uploading
          buttonColor={buttonColor ? `#${buttonColor}` : primary}
          upload={upload}
          setUpload={setUpload}
          web={web}
        />
      )}

      <input
        color="primary"
        type="file"
        onChange={handleCapture}
        id={`icon-button-file-${randomId}`}
        style={{ display: 'none' }}
        multiple
      />

      {showPreview && upload?.file && !upload.open && (
        <div className="tw-flex tw-flex-col tw-items-center tw-justify-center">
          <div className="tw-my-4 tw-flex tw-items-center tw-justify-center">
            <Box className="tw-aspect-video tw-w-60">
              <ReactPlayer
                controls
                width="100%"
                height="100%"
                url={upload?.file && URL.createObjectURL(upload?.file)}
              />
            </Box>
          </div>
          <ButtonPrimary
            buttonColor={buttonColor}
            onClick={() => setUpload((prev) => ({ ...prev, open: true }))}
          >
            {t('button.startUpload')}
          </ButtonPrimary>
        </div>
      )}
    </Box>
  );
};

UploadMobile.propTypes = {};

export default UploadMobile;
