import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Modules from 'modules';
import useActions from 'modules/map/useActions';
import { showAPIError } from 'core/utils/showAPIError';
import { ButtonPrimary } from 'core/ui/Button';

export const DownloadReceiptButton = ({ payment }) => {
  const { t } = useTranslation();
  const { request } = useActions();

  const [loading, setLoading] = useState(false);

  const downloadReceipt = () => {
    setLoading(true);

    request({
      action: Modules.newPayments.actions.downloadReceipt,
      data: {
        paymentId: payment.id,
      },
      options: {
        onSuccess: () => setLoading(false),
        onError: (err) => {
          showAPIError(err);
          setLoading(false);
        },
      },
    });
  };

  return (
    <ButtonPrimary disabled={loading} onClick={downloadReceipt} className="tw-text-sm">
      {t('button.receipt')}
    </ButtonPrimary>
  );
};
