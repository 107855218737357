import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from 'core/ui/Typography';
import Box from 'core/ui/Box';
import { primary } from 'core/ui/Colors';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  root: {
    position: 'absolute',
    textAlign: 'center',
    right: 0,
    left: 0,
    zIndex: 9999,
  },
  boxTimer: {
    backgroundColor: primary,
    padding: 5,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    color: 'white',
    zIndex: 11,
    fontSize: 30,
  },
});

export default function CountDownCompetition() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.root}>
      <Box display="flex" justifyContent="center">
        <Typography className={classes.boxTimer}>
          {t('competition.equipmentVerification')}
        </Typography>
      </Box>
    </Box>
  );
}

CountDownCompetition.defaultProps = {
  exit: () => {},
};
