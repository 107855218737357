/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  TableHead,
  Table,
  TableCell,
  TableRow,
  TableBody,
  IconButton,
  withStyles,
} from '@material-ui/core';
import Box from 'core/ui/Box';
import LabelTextField from 'core/ui/LabelTextField';
import { ArrowUpward, Sort } from '@material-ui/icons';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { currency as currencyConvert } from 'core/utils/formatters';
import Checkbox from 'core/ui/Checkbox';
import { redColor, secondary, secondaryLight } from 'core/ui/Colors';
import Typography, { fontStyle } from 'core/ui/Typography';
import { dynamicSort, moveOrder } from 'core/utils/helpers';
import Paper from 'core/ui/Paper';
import useSelectors from 'modules/map/useSelectors';
import { storeOptionsModule } from 'modules/storeOptions';
import SelectBoxSearch from 'core/ui/SelectBox/SelectBoxSearch';
import { concat } from 'ramda';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import notifications from 'modules/notifications';
import { useDispatch } from 'react-redux';
import ButtonActions from './ButtonActions';
import ProductOption from './ProductOption';
import ModalReorderOptions from './ModalReorderOptions';

export const Cell = withStyles(() => ({
  head: {
    backgroundColor: secondaryLight,
    color: secondary,
    fontSize: 12,
  },
  body: {
    fontSize: 11,
  },
}))(TableCell);

const SelectOption = ({ t, storeOptions, options, setOptions, value, field }) => (
  <Box mr={1}>
    <SelectBoxSearch
      autoSelect={false}
      autoSave={false}
      multiple
      options={[...storeOptions.filter((x) => !!x.parent)].sort(
        (a, b) => -b?.parent?.name.localeCompare(a?.parent?.name),
      )}
      renderGroup={(params) => {
        const isChecked =
          storeOptions
            .find((x) => x.name === params.group)
            ?.parents?.filter((x) => !options?.find((y) => y.id === x.id)).length === 0;
        return (
          <li>
            <Box
              m={1}
              display="flex"
              alignItems="center"
              className="hover"
              p={1}
              onClick={() =>
                isChecked
                  ? setOptions((prev) => ({
                      ...prev,
                      [field]: prev[field]?.filter(
                        (x) =>
                          !storeOptions
                            .find((z) => z.name === params.group)
                            ?.parents?.find((y) => y.id === x.id),
                      ),
                    }))
                  : setOptions((prev) => ({
                      ...prev,
                      [field]: concat(
                        prev[field],
                        storeOptions
                          .find((x) => x.name === params.group)
                          ?.parents?.filter((x) => !options?.find((y) => y.id === x.id)),
                      ),
                    }))
              }
            >
              <Checkbox checked={isChecked} />
              <Typography style={{ color: '#546e7a' }}>{params.group}</Typography>
            </Box>
            <Box px={1}>{params.children}</Box>
          </li>
        );
      }}
      renderOption={(option, props) => (
        <Box display="flex" alignItems="center">
          <Checkbox checked={props.selected} />
          <Typography>{option?.name}</Typography>
        </Box>
      )}
      label={
        options?.length > 0
          ? options.find((x) => x?.parent?.name)?.parent?.name?.toUpperCase()
          : t(`form.inventory.${field}`).toUpperCase()
      }
      value={value || []}
      setValue={(v) => setOptions((prev) => ({ ...prev, [field]: v }))}
      getOptionLabel={(option) => option.name}
      groupBy={(option) => option.parent?.name}
      getOptionSelected={(option) => !!value.find((x) => x.id === option.id)}
      bordered
    />
  </Box>
);

const Inventory = ({
  currency,
  addSalesTax,
  salesTax,
  inventories,
  setInventories,
  isPartner,
  buttonColor,
  hasShopifyIntegration,
  shopifyInventory,
  customerAmounts,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [options, setOptions] = useState({ optionFirst: [], optionSecond: [], optionThird: [] });
  const [allSettings, setAllSettings] = useState({});
  const [openOrder, setOpenOrder] = useState(false);

  const storeOptions = useSelectors(storeOptionsModule, 'data');

  const lastOrder =
    inventories?.length > 0
      ? Math.max(...inventories?.map((x) => x.orderShow || inventories?.length))
      : 0;

  const addCustomOption = () => {
    setInventories((prev) => [...prev, { orderShow: lastOrder + 1 }]);
  };

  const changeInventory = (prop, value, item) => {
    if ((prop === 'qty' || prop === 'price') && Number(value) < 0) {
      return;
    }

    if (prop === 'orderShow') {
      setInventories(() =>
        moveOrder(value + 1, value, inventories).map((x, index) => {
          const newItem = { ...x };
          newItem[prop] = index;
          return newItem;
        }),
      );
    } else {
      setInventories((prev) =>
        prev.map((x) => {
          const newItem = { ...x };

          if (x === item) {
            newItem[prop] = value;
          }

          return newItem;
        }),
      );
    }
  };

  const changeInventoryAll = (prop, value) => {
    if ((prop === 'qty' || prop === 'price') && Number(value) < 0) {
      return;
    }

    setAllSettings((prev) => ({ ...prev, [prop]: value }));

    setInventories((prev) =>
      prev.map((x) => {
        const newItem = { ...x };

        newItem[prop] = value;

        return newItem;
      }),
    );
  };

  const removeNew = (row) => {
    setInventories((prev) => prev.filter((x) => x !== row));
  };

  const addNewOption = ({ first, second = null, third = null, order }) => {
    setInventories((prev) => [
      ...prev,
      {
        optionFirst: first,
        optionFirstId: first?.id,
        optionSecond: second,
        optionSecondId: second?.id,
        optionThird: third,
        optionThirdId: third?.id,
        orderShow: order,
      },
    ]);
  };

  const addOption = () => {
    if (options?.optionFirst?.length === 0) {
      dispatch(notifications.warning(t('addOptions.validation')));
      return;
    }

    let order = lastOrder;
    options.optionFirst.forEach((first) => {
      if (options?.optionSecond?.length > 0) {
        options.optionSecond.forEach((second) => {
          if (options?.optionThird?.length > 0) {
            options.optionThird.forEach((third) => {
              order += 1;
              addNewOption({ first, second, third, order });
            });
          } else {
            order += 1;
            addNewOption({ first, second, order });
          }
        });
      } else {
        order += 1;
        addNewOption({ first, order });
      }
    });

    setOptions({ optionFirst: [], optionSecond: [], optionThird: [] });
  };

  const remove = (row) => {
    setInventories((prev) =>
      prev.map((item) => {
        if (item === row) {
          // eslint-disable-next-line no-param-reassign
          item._destroy = true;
        }
        return item;
      }),
    );
  };

  const dropDownOptions = (option) => {
    let selectedParents = [];

    if (option === 'optionSecond') {
      selectedParents = [...options?.optionFirst?.map((x) => x.parentId)];
    }
    if (option === 'optionThird') {
      selectedParents = [
        ...options?.optionFirst?.map((x) => x.parentId),
        ...options?.optionSecond?.map((x) => x.parentId),
      ];
    }

    const opts = storeOptions
      ?.filter((z) => !selectedParents.find((x) => z.parentId === x))
      ?.filter(
        (x) =>
          options?.[option]?.length === 0 ||
          x.parentId === options?.[option][0]?.parentId ||
          x.id === options?.[option][0]?.parentId,
      );

    return opts;
  };

  const customerDesc = (row) => {
    if (customerAmounts?.type && customerAmounts?.amount) {
      return customerAmounts?.type === '%'
        ? (Number(row.price) / 100) * customerAmounts?.amount
        : customerAmounts?.amount;
    }
    return 0;
  };

  const calculateTotalItem = (row) => {
    const desc = customerDesc(row);

    if (!addSalesTax || !salesTax?.amount) {
      return currencyConvert(Number(row.price) - desc);
    }

    if (salesTax?.amountType === 'money') {
      return currencyConvert(Number(row.price) + Number(salesTax?.amount) - desc);
    }

    return currencyConvert(Number(row.price) + (Number(row.price) / 100) * salesTax?.amount - desc);
  };

  const inventoriesItems =
    inventories && inventories.filter((x) => !x._destroy).sort(dynamicSort('orderShow'));

  const showTotal = customerAmounts?.type || addSalesTax;

  return (
    <Paper p={3} mb={3}>
      <Box display="flex" alignItems="center">
        <Box flexGrow={1}>
          <Typography mb={3} variant="h5">
            {t('channel.store.product.inventory')}
          </Typography>
        </Box>
        <IconButton onClick={() => setOpenOrder(true)}>
          <Sort />
        </IconButton>
        <Box ml={2}>
          <ButtonActions
            buttonColor={buttonColor}
            addOption={addOption}
            addCustomOption={addCustomOption}
          />
        </Box>
      </Box>

      <ModalReorderOptions
        open={openOrder}
        close={() => setOpenOrder(false)}
        options={inventoriesItems}
        setOptions={setInventories}
      />

      <Typography color="primary" mb={2}>
        {t('inventory.combineOptions')}
      </Typography>
      <Box display="flex" alignItems="flex-end">
        <Box>
          <SelectOption
            options={options?.optionFirst || []}
            setOptions={setOptions}
            field="optionFirst"
            t={t}
            storeOptions={dropDownOptions('optionFirst')}
            value={options?.optionFirst}
          />
        </Box>
        <Box>
          <SelectOption
            options={options?.optionSecond || []}
            setOptions={setOptions}
            field="optionSecond"
            t={t}
            storeOptions={dropDownOptions('optionSecond')}
            value={options?.optionSecond}
          />
        </Box>
        <Box>
          <SelectOption
            options={options?.optionThird || []}
            setOptions={setOptions}
            field="optionThird"
            t={t}
            storeOptions={dropDownOptions('optionThird')}
            value={options?.optionThird}
          />
        </Box>
      </Box>

      <Box p={3} my={5}>
        {inventoriesItems?.length > 0 && (
          <Table size="small">
            <TableHead>
              <TableRow>
                <Cell />
                <Cell>{t('channel.product.inventory.option')}</Cell>
                {isPartner && <Cell>{t('channel.product.inventory.sku')}</Cell>}
                {hasShopifyIntegration && <Cell>{t('channel.product.inventory.variationId')}</Cell>}
                {!shopifyInventory && (
                  <Cell align="center">
                    <Box display="flex" flexDirection="column" alignItems="center">
                      <Typography align="center">
                        {t('channel.product.inventory.qtyUnlimited')}
                      </Typography>

                      <Checkbox
                        checked={allSettings.qtyUnlimited}
                        onClick={() =>
                          changeInventoryAll('qtyUnlimited', !allSettings.qtyUnlimited)
                        }
                        color={secondary}
                      />
                    </Box>
                  </Cell>
                )}
                <Cell align="right">
                  <Box display="flex" flexDirection="column" alignItems="flex-end">
                    {/* <Typography>{t('channel.product.inventory.qty')}</Typography> */}

                    {!shopifyInventory && (
                      <TextFieldRounded
                        value={allSettings.qty || ''}
                        onChange={(v) => changeInventoryAll('qty', v)}
                        placeholder={t('channel.product.inventory.qty')}
                        type="number"
                        pattern="[0-9]*"
                        style={{ textAlign: 'right' }}
                        bordered
                      />
                    )}
                  </Box>
                </Cell>
                <Cell align="right">
                  <Box display="flex" flexDirection="column" alignItems="flex-end">
                    <TextFieldRounded
                      value={allSettings.wholeSalePrice || ''}
                      onChange={(v) => changeInventoryAll('wholeSalePrice', v)}
                      placeholder={t('channel.product.inventory.wholeSalePrice')}
                      type="number"
                      pattern="[0-9]*"
                      style={{ textAlign: 'right' }}
                      bordered
                    />
                  </Box>
                </Cell>
                <Cell align="right">
                  <Box display="flex" flexDirection="column" alignItems="flex-end">
                    <TextFieldRounded
                      value={allSettings.price || ''}
                      onChange={(v) => changeInventoryAll('price', v)}
                      placeholder={t('channel.product.inventory.price')}
                      type="number"
                      pattern="[0-9]*"
                      style={{ textAlign: 'right' }}
                      bordered
                    />
                  </Box>
                </Cell>
                {showTotal && (
                  <Cell align="right">
                    {currency?.symbol}&nbsp;
                    {t('channel.product.inventory.total')}&nbsp;
                    {currency?.code}
                  </Cell>
                )}
                <Cell />
              </TableRow>
            </TableHead>
            <TableBody>
              {inventoriesItems.map((row, index) => (
                <TableRow key={index.toString()}>
                  <TableCell>
                    {index > 0 && (
                      <Typography
                        onClick={() => changeInventory('orderShow', index - 1, row)}
                        className="link-hover"
                        variant="caption"
                        align="center"
                      >
                        <ArrowUpward style={{ ...fontStyle.large, color: secondary }} />
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    {row?.optionFirst ? (
                      <ProductOption option={row} />
                    ) : (
                      <LabelTextField
                        value={row.kind || ''}
                        onChange={(v) => changeInventory('kind', v, row)}
                        placeholder={t('channel.product.inventory.placeholder.option')}
                      />
                    )}
                  </TableCell>
                  {isPartner && (
                    <TableCell>
                      <LabelTextField
                        value={row.sku || ''}
                        onChange={(v) => changeInventory('sku', v, row)}
                        placeholder={t('channel.product.inventory.placeholder.sku')}
                      />
                    </TableCell>
                  )}
                  {hasShopifyIntegration && (
                    <TableCell>
                      <LabelTextField
                        value={row.variationId || ''}
                        onChange={(v) => changeInventory('variationId', v, row)}
                        placeholder={t('channel.product.inventory.placeholder.variationId')}
                      />
                    </TableCell>
                  )}
                  {!shopifyInventory && (
                    <TableCell align="center">
                      <Box display="flex" justifyContent="center">
                        <Checkbox
                          checked={row.qtyUnlimited}
                          onClick={() => changeInventory('qtyUnlimited', !row.qtyUnlimited, row)}
                          color={secondary}
                        />
                      </Box>
                    </TableCell>
                  )}
                  <TableCell align="right">
                    {!shopifyInventory ? (
                      <>
                        {!row.qtyUnlimited ? (
                          <LabelTextField
                            value={row.qty || ''}
                            onChange={(v) => changeInventory('qty', v, row)}
                            placeholder={t('channel.product.inventory.qty')}
                            type="number"
                            pattern="[0-9]*"
                            style={{ textAlign: 'right' }}
                          />
                        ) : (
                          <Typography color="primary" style={fontStyle.small}>
                            {t('channel.product.inventory.qtyUnlimited')}
                          </Typography>
                        )}
                      </>
                    ) : (
                      <Typography color="primary">{row?.qty}</Typography>
                    )}
                  </TableCell>
                  <TableCell align="right">
                    <LabelTextField
                      value={row.wholeSalePrice || ''}
                      onChange={(v) => changeInventory('wholeSalePrice', v, row)}
                      placeholder={t('channel.product.inventory.wholeSalePrice')}
                      type="number"
                      pattern="[0-9]*"
                      style={{ textAlign: 'right' }}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <LabelTextField
                      value={row.price || ''}
                      onChange={(v) => changeInventory('price', v, row)}
                      placeholder={t('channel.product.inventory.price')}
                      type="number"
                      pattern="[0-9]*"
                      style={{ textAlign: 'right' }}
                    />
                  </TableCell>
                  {showTotal && (
                    <TableCell align="right">
                      {calculateTotalItem(row)}

                      <Box>
                        {addSalesTax && salesTax?.amount > 0 && (
                          <Typography style={fontStyle.xSmall}>
                            Sales Tax:&nbsp;{' '}
                            {salesTax?.amountType === 'money' ? currency?.symbol : '%'}{' '}
                            {currencyConvert(salesTax?.amount)}
                          </Typography>
                        )}
                        {customerAmounts?.type && (
                          <Typography style={{ ...fontStyle.xSmall, color: redColor }}>
                            Customer:&nbsp;{customerAmounts?.type}
                            {currencyConvert(customerAmounts?.amount)}
                          </Typography>
                        )}
                      </Box>
                    </TableCell>
                  )}
                  <TableCell>
                    <IconButton onClick={() => (!row.id ? removeNew(row) : remove(row))}>
                      <HighlightOffIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </Box>
    </Paper>
  );
};

Inventory.defaultProps = {};

Inventory.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  inventories: PropTypes.array.isRequired,
  setInventories: PropTypes.func.isRequired,
};

export default Inventory;
