import { SPOTIFY_AUTH_BASIC, SPOTIFY_CLIENT_ID } from 'core/env';

export const authEndpoint = 'https://accounts.spotify.com/authorize';

// Replace with your app's client ID, redirect URI and desired scopes
export const clientId = SPOTIFY_CLIENT_ID;
export const authBasic = SPOTIFY_AUTH_BASIC;
export const redirectUri = `https://${window.location.hostname}/profile/edit`;

export const scopes = [
  'user-top-read',
  'user-read-currently-playing',
  'user-read-playback-state',
  'user-library-read',
  'user-follow-read',
  'playlist-read-private',
  'streaming',
  'user-read-email',
  'user-read-private',
];
