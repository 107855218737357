import React, { useEffect, useState } from 'react';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import Typography from 'core/ui/Typography';
import { Add, Delete, Edit } from '@material-ui/icons';
import useSelectors from 'modules/map/useSelectors';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import { usersDocumentsModule } from 'modules/usersDocuments';
import SFDataTable from 'core/ui/DataTable';
import { IconButton, Link } from '@material-ui/core';
import Button, { ButtonPrimary } from 'core/ui/Button';
import LoaderSm from 'core/ui/LoaderSm';
import Paper from 'core/ui/Paper';
import ModalForm from './Form/Modal';

const MemberDocuments = ({ channel, user, buttonColor }) => {
  const { t } = useTranslation();
  const loading = useSelectors(usersDocumentsModule, 'loading');
  const data = useSelectors(usersDocumentsModule, 'data');
  const pagination = useSelectors(usersDocumentsModule, 'pagination');
  const { request } = useActions();
  const [filter] = useState({});
  const [perPage, setPerPage] = useState(10);

  const [modal, setModal] = useState({
    open: false,
  });

  const getDocuments = () => {
    request({
      action: Modules.usersDocuments.actions.getUsersDocuments,
      data: { ...filter, ownerableId: channel.id, ownerableType: 'Channel', userId: user?.id },
    });
  };

  useEffect(() => {
    if (channel?.id && user?.id) {
      getDocuments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel, user]);

  const openModal = () => {
    setModal((prev) => ({ ...prev, mode: 'new', open: true }));
  };

  const onEditClick = (item) => {
    setModal((prev) => ({ ...prev, item, mode: 'edit', open: true }));
  };

  const onRemoveClick = (item) => {
    request({
      action: Modules.usersDocuments.actions.destroy,
      data: { ownerableId: channel.id, ownerableType: 'Channel', id: item?.id },
      options: {
        onSuccess: () => {
          getDocuments();
        },
      },
    });
  };

  const reSendContract = (item) => {
    request({
      action: Modules.usersContracts.actions.resendContractEmail,
      data: {
        idRecord: item?.id,
        userId: user?.id,
        usersContractId: item?.ownerableId,
        usersContractType: item?.ownerableType,
      },
      options: {
        onSuccess: () => {
          getDocuments();
        },
      },
    });
  };

  const columns = [
    {
      name: 'Document',
      selector: '',
      sortable: true,
      cell: (row) => <Typography>{row?.name}</Typography>,
    },
    {
      name: 'File Type',
      selector: 'fileType',
      sortable: true,
      cell: (row) => <Typography>{row?.fileType}</Typography>,
    },
    {
      cell: (row) => (
        <Box display="flex" alignItems="center">
          {row?.ownerableType === 'UsersContract' && (
            <Box>
              <Button onClick={() => reSendContract(row)}>{t('button.resendEmail')}</Button>
            </Box>
          )}
          <Box>
            <Link target="_blank" href={row?.documentUrl} className="link-hover">
              <Button>{t('button.view')}</Button>
            </Link>
          </Box>
          <IconButton onClick={() => onEditClick(row)}>
            <Edit />
          </IconButton>

          <IconButton onClick={() => onRemoveClick(row)}>
            <Delete />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <Paper py={3} className="paper-rounded">
      {modal.open && (
        <ModalForm
          open={modal.open}
          item={modal.item}
          mode={modal.mode}
          buttonColor={buttonColor}
          channel={channel}
          user={user}
          close={() => setModal(() => ({ open: false }))}
          callback={() => setModal(() => ({ open: false }))}
        />
      )}

      <Box display="flex" alignItems="flex-end" mx={3} mb={3}>
        <Box flexGrow="1" display="flex">
          <Typography variant="h5">{t('channel.member.documents')}</Typography>
          <LoaderSm loading={loading} />
        </Box>
        <Box display="flex" alignItems="center">
          <ButtonPrimary onClick={() => openModal()}>
            <Box display="flex" alignItems="flex-end">
              <Add size="small" />
              <Box alignItems="center">
                <Typography component="span" mr={1}>
                  {t('button.addDocument')}
                </Typography>
              </Box>
            </Box>
          </ButtonPrimary>
        </Box>
      </Box>

      <SFDataTable
        columns={columns}
        data={data}
        pagination
        showExport={false}
        noHeader
        paginationPerPage={perPage}
        onChangePage={(p) => getDocuments(p)}
        paginationServer
        paginationDefaultPage={pagination?.currentPage}
        paginationTotalRows={pagination?.totalCount}
        onChangeRowsPerPage={(newValue) => {
          setPerPage(newValue);
        }}
      />
    </Paper>
  );
};

export default MemberDocuments;
