import Box from 'core/ui/Box';
import { primary, secondary } from 'core/ui/Colors';
import MIcon from 'core/ui/MIcon';
import Typography from 'core/ui/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';

const FormatSectionPrRow = ({
  data,
  movement,
  form,
  bestPr,
  removeRow,
  textColor,
  fontSize,
  expandView,
}) => {
  const { t } = useTranslation();

  const formatScore = (perc, scoreType) => {
    let score = null;

    if (bestPr && bestPr.scores[scoreType]) {
      score = bestPr.scores[scoreType];
    }

    if (score) {
      return `- ${((score / 100) * perc).toFixed(0)} ${bestPr.scores.repMaxType}`;
    }

    return null;
  };

  return (
    <Typography component="div" style={{ color: textColor || secondary, fontSize: fontSize || 12 }}>
      {data.layoutType === 'single' && (
        <>
          {`${data.repScheme} ${movement} - ${data.rows[0].percStart}% of ${t(
            `${data.rows[0].scoreType}`,
          )} `}

          {!expandView && !form && (
            <>
              {bestPr ? (
                <span style={{ color: primary, fontWeight: 'bold' }}>
                  &nbsp;{formatScore(data.rows[0].percStart, data.rows[0].scoreType)}
                </span>
              ) : (
                <span style={{ color: primary, fontWeight: 'bold' }}>
                  &nbsp;-&nbsp;No PR Recorded
                </span>
              )}
            </>
          )}
        </>
      )}
      {data.layoutType === 'range' && (
        <>
          <Typography
            component="p"
            style={{ fontSize }}
          >{`${movement} ${data.repScheme}`}</Typography>
          <Typography className="roboto-regular" component="div" style={{ fontSize }}>
            {`${data.rows[0].percStart}% - ${data.rows[0].percEnd}% of ${t(
              `${data.rows[0].scoreType}`,
            )}`}

            {!expandView && !form && (
              <>
                {bestPr ? (
                  <span style={{ color: primary, fontWeight: 'bold', fontSize }}>
                    &nbsp;{formatScore(data.rows[0].percStart, data.rows[0].scoreType)}
                    &nbsp;{formatScore(data.rows[0].percEnd, data.rows[0].scoreType)}
                  </span>
                ) : (
                  <span style={{ color: primary, fontWeight: 'bold', fontSize }}>
                    &nbsp;-&nbsp;No PR Recorded
                  </span>
                )}
              </>
            )}
          </Typography>
        </>
      )}
      {data.layoutType === 'custom' && (
        <>
          <Typography
            component="p"
            style={{ fontSize }}
          >{`${movement} ${data.repScheme}`}</Typography>
          <Box display="flex" flexDirection="column" style={{ fontSize }}>
            <Typography className="roboto-regular" component="div" style={{ fontSize }}>
              {data.rows &&
                data.rows.map((row, index) => (
                  <Box display="flex" alignItems="center" key={index.toString()}>
                    <Box display="flex" alignItems="center" flexGrow={1}>
                      {`${row.percStart}% of ${t(`${row.scoreType}`)}`}

                      {!expandView && !form && (
                        <>
                          {bestPr ? (
                            <span style={{ color: primary, fontWeight: 'bold', fontSize }}>
                              &nbsp;{formatScore(row.percStart, data.rows[0].scoreType)}
                            </span>
                          ) : (
                            <span style={{ color: primary, fontWeight: 'bold', fontSize }}>
                              &nbsp;-&nbsp;No PR Recorded
                            </span>
                          )}
                        </>
                      )}
                    </Box>

                    {removeRow && (
                      <Box className="hover">
                        <MIcon icon="remove_circle" onClick={() => removeRow(row)} />
                      </Box>
                    )}
                  </Box>
                ))}
            </Typography>
          </Box>
        </>
      )}
    </Typography>
  );
};

export default FormatSectionPrRow;
