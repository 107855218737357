import React from 'react';
import RemoveIcon from '@material-ui/icons/Remove';
import Box from 'core/ui/Box';
import Typography from 'core/ui/Typography';
import { secondaryXLight } from 'core/ui/Colors';
import { Button, ButtonGroup } from '@material-ui/core';
import { ButtonPrimary } from 'core/ui/Button';

export default ({ changeQty, qty }) => (
  <Box>
    <ButtonGroup size="medium" color="secondary" aria-label="medium secondary button group">
      <ButtonPrimary
        style={{ padding: 0 }}
        buttonColor={secondaryXLight}
        onClick={() => changeQty(-1, 'hours')}
      >
        <RemoveIcon /> Hour&nbsp;
      </ButtonPrimary>
      <ButtonPrimary
        style={{ padding: 0 }}
        buttonColor={secondaryXLight}
        onClick={() => changeQty(-1, 'minutes')}
      >
        <RemoveIcon /> Minute&nbsp;
      </ButtonPrimary>
      <Button>
        <Box flexGrow={1}>
          <Typography>{qty}</Typography>
        </Box>
      </Button>
    </ButtonGroup>
  </Box>
);
