/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { channelsSelectors } from 'modules/channels';
import Paper from 'core/ui/Paper';
import Typography from 'core/ui/Typography';
import Grid from 'core/ui/Grid';
import ExampleQR from './ExampleQR';
import SearchMembers from './SearchMembers';
import ModalUserInfo from './ModalUserInfo';

export default () => {
  const channel = useSelector((state) => channelsSelectors.getChannel(state));
  const { t } = useTranslation();
  const [query, setQuery] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);

  return (
    <Box>
      <Paper m={3} p={3} className="paper-rounded opacity-80">
        <Typography variant="h1" align="center" mb={3}>
          {t('label.selfCheckIn')}
        </Typography>

        <ModalUserInfo
          close={() => {
            setSelectedUser(null);
            setQuery('');
          }}
          selectedUser={selectedUser}
          channel={channel}
        />

        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <SearchMembers
              channelId={channel?.id}
              buttonColor={channel?.customColors?.buttonColor}
              onSelectedUser={(user) => setSelectedUser(user)}
              query={query}
              setQuery={setQuery}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper className="paper-rounded" p={5}>
              <ExampleQR />
            </Paper>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};
