import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import Typography, { fontStyle } from 'core/ui/Typography';

import Box from '../Box';
import WrapperInput from './WrapperInput';
import { secondaryLight } from '../Colors';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: 5,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontSize: 12,
    paddingLeft: 5,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export default function TextFieldRounded({
  label,
  name,
  inputRef,
  placeholder,
  error,
  onChange,
  style,
  disabled,
  value,
  type,
  onKeyPress,
  pattern,
  step,
  inputProps,
  paperStyle,
  required,
  currency,
  onBlur,
  bordered,
  currencySymbol,
  labelColor,
  ...props
}) {
  const classes = useStyles();
  const styleInput = error
    ? paperStyle
    : { ...paperStyle, border: bordered ? `1px solid${secondaryLight}` : 'none' };
  return (
    <div style={{ width: '100%' }}>
      {label !== '' && (
        <Box ml={2} mb={2} display="flex">
          <Typography
            style={{ color: labelColor || 'black', ...fontStyle.label }}
            component="div"
            variant="body1"
          >
            {label} {required && <span className="tw-text-red-500">*</span>}
          </Typography>
        </Box>
      )}

      <WrapperInput style={styleInput} error={error}>
        {currency && (
          <Typography className="roboto-regular" ml={1} mr={3} color="secondary" variant="body2">
            {currencySymbol || '$'}
          </Typography>
        )}
        {onChange === null && (
          <>
            {value === null ? (
              <InputBase
                disabled={disabled}
                name={name}
                className={classes.input}
                onBlur={onBlur}
                inputRef={inputRef}
                onKeyPress={onKeyPress}
                placeholder={placeholder}
                inputProps={{ style: { ...style }, autoComplete: 'new-password', ...inputProps }}
                type={type}
                pattern={pattern}
                {...props}
              />
            ) : (
              <InputBase
                disabled={disabled}
                name={name}
                className={classes.input}
                value={value || ''}
                onBlur={onBlur}
                inputRef={inputRef}
                onKeyPress={onKeyPress}
                placeholder={placeholder}
                inputProps={{ style: { ...style }, autoComplete: 'new-password', ...inputProps }}
                type={type}
                pattern={pattern}
                {...props}
              />
            )}
          </>
        )}
        {onChange !== null && (
          <InputBase
            name={name}
            disabled={disabled}
            className={classes.input}
            onKeyPress={onKeyPress}
            onChange={(e) => onChange(e.target.value)}
            placeholder={placeholder}
            value={value || ''}
            onBlur={onBlur}
            inputProps={{ style: { ...style }, step, autoComplete: 'new-password', ...inputProps }}
            type={type}
            pattern={pattern}
            {...props}
          />
        )}
      </WrapperInput>
    </div>
  );
}

TextFieldRounded.defaultProps = {
  name: null,
  error: null,
  onKeyPress: null,
  inputRef: null,
  placeholder: '',
  onChange: null,
  label: '',
  style: {},
  inputProps: {},
  paperStyle: {},
  value: null,
  type: 'text',
  disabled: false,
  pattern: '',
  step: '',
  required: false,
  currency: false,
  onBlur: () => {},
};
