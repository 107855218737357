import React from 'react';
import Box from 'core/ui/Box';
import { useSelector } from 'react-redux';
import { channelsSelectors } from 'modules/channels';
import Communication from 'components/Commmunication';

export default () => {
  const channel = useSelector((state) => channelsSelectors.getChannel(state));

  return (
    <Box p={3}>
      <Communication channel={channel} />
    </Box>
  );
};
