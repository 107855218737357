import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

import * as selectors from './selectors';
import * as sagas from './sagas';

/* ---------- Action Creators and Types ---------- */
const { Types, Creators } = createActions(
  {
    postWorkoutHeatError: ['error'],
    postWorkoutHeatStart: ['data', 'callback'],
    postWorkoutHeatSuccess: [''],

    getWorkoutHeatsError: ['error'],
    getWorkoutHeatsStart: ['workoutId'],
    getWorkoutHeatsSuccess: ['categories'],

    resetWorkoutHeatsError: ['error'],
    resetWorkoutHeatsStart: ['workoutId', 'callback'],
    resetWorkoutHeatsSuccess: [''],

    saveWorkoutHeatsUsersError: ['error'],
    saveWorkoutHeatsUsersStart: ['data', 'callback'],
    saveWorkoutHeatsUsersSuccess: ['categories'],
  },
  { prefix: 'workoutHeats/' },
);

/* ---------- Initial State ---------- */
const INITIAL_STATE = Immutable({
  data: [],
  error: null,
  loading: false,
});

/* ---------- Reducers ---------- */
const loadStartReducer = (state) => state.set('loading', true);

const postWorkoutHeatSuccessReducer = (state) =>
  state.merge({
    loading: false,
    error: null,
  });

const getWorkoutHeatSuccessReducer = (state, action) =>
  state.merge({
    data: action.categories,
    loading: false,
    error: null,
  });

const loadErrorReducer = (state, action) =>
  state.merge({
    loading: false,
    error: action.error,
  });

/* ---------- Main Reducer ---------- */
const reducer = createReducer(INITIAL_STATE, {
  [Types.POST_WORKOUT_HEAT_START]: loadStartReducer,
  [Types.POST_WORKOUT_HEAT_SUCCESS]: postWorkoutHeatSuccessReducer,
  [Types.POST_WORKOUT_HEAT_ERROR]: loadErrorReducer,

  [Types.GET_WORKOUT_HEATS_START]: loadStartReducer,
  [Types.GET_WORKOUT_HEATS_SUCCESS]: getWorkoutHeatSuccessReducer,
  [Types.GET_WORKOUT_HEATS_ERROR]: loadErrorReducer,

  [Types.RESET_WORKOUT_HEATS_START]: loadStartReducer,
  [Types.RESET_WORKOUT_HEATS_SUCCESS]: getWorkoutHeatSuccessReducer,
  [Types.RESET_WORKOUT_HEATS_ERROR]: loadErrorReducer,

  [Types.SAVE_WORKOUT_HEATS_USERS_START]: loadStartReducer,
  [Types.SAVE_WORKOUT_HEATS_USERS_SUCCESS]: postWorkoutHeatSuccessReducer,
  [Types.SAVE_WORKOUT_HEATS_USERS_ERROR]: loadErrorReducer,
});

/* ---------- Exporting ---------- */
export const workoutHeatsActions = Creators;
export const workoutHeatsTypes = Types;
export const workoutHeatsSelectors = selectors;
export const workoutHeatsSagas = sagas;

export default reducer;
