import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import Button, { ButtonPrimary } from 'core/ui/Button';
import { integrationTypes } from 'core/utils/consts';
import Typography from 'core/ui/Typography';
import { secondary, secondaryLight, secondaryXXLight } from 'core/ui/Colors';
import Grid from 'core/ui/Grid';
import Paper from 'core/ui/Paper';
import StripeConnectedAccount from 'components/Channel/Manage/Tabs/Plans/StripeConnectedAccount';
import useForm from './useForm';
import CardPointeBoardForm from './CardPointe/Form';

const ModalFormIntegrations = ({
  item,
  callback,
  mode,
  ownerableType,
  ownerableId,
  hasSifelyPlan,
  showCardpointe,
}) => {
  const { t } = useTranslation();

  const {
    setKind,
    kind,
    // setTestValues,
    setValue,
    watch,
    handleSubmit,
    onSubmit,
    errors,
    loading,
  } = useForm({
    mode,
    item,
    callback,
    ownerableType,
    ownerableId,
  });
  const defaultStyles = {
    border: `0.3px solid ${secondaryXXLight}`,
    height: 80,
    width: '100%',
  };

  const currentIntegration = integrationTypes.find((x) => x.id === kind);

  return (
    <>
      <Box mb={3}>
        {currentIntegration?.id ? (
          <Box display="flex" alignItems="center" py={1}>
            <img
              src={currentIntegration?.img}
              alt={currentIntegration.name}
              style={{ width: 26 }}
            />
            <Typography ml={1} style={{ color: secondary }}>
              {t(`integration.kind.${currentIntegration.id}`)}
            </Typography>
          </Box>
        ) : (
          <>
            <Typography align="center" m={3}>
              {t('select.integrations')}
            </Typography>
            <Grid container spacing={3}>
              {integrationTypes
                .filter((x) => x.id === 'shopify' || ownerableType !== 'Partner')
                .filter((x) => x.id !== 'sifely' || hasSifelyPlan)
                .filter((x) => x.id !== 'cardpointe' || showCardpointe)
                .map((integration) => (
                  <Grid item xs={6} key={integration?.id}>
                    <Button
                      style={
                        kind === integration.id
                          ? { ...defaultStyles, backgroundColor: secondary }
                          : { ...defaultStyles, backgroundColor: secondaryLight }
                      }
                      onClick={() => setKind(integration.id)}
                    >
                      <Box display="flex" alignItems="center" py={1}>
                        <img src={integration?.img} alt={integration.name} style={{ width: 26 }} />
                        <Typography
                          ml={1}
                          style={{ color: kind === integration.id ? 'white' : secondary }}
                        >
                          {t(`integration.kind.${integration.id}`)}
                        </Typography>
                      </Box>
                    </Button>
                  </Grid>
                ))}
            </Grid>
          </>
        )}
      </Box>

      {kind === 'zapier' && (
        <Paper p={5}>
          <Typography align="center" variant="h5">
            Save to create your api key
          </Typography>
        </Paper>
      )}

      {kind === 'hybrid' && (
        <>
          <Box mb={3}>
            <TextFieldRounded
              value={watch('externalId')}
              onChange={(v) => setValue('externalId', v)}
              placeholder={t('form.label.integration.hybrid.gymId')}
              error={errors.name}
              label={t('form.label.integration.hybrid.gymId').toUpperCase()}
              required
            />
          </Box>
          <Box mb={3}>
            <TextFieldRounded
              value={watch('token')}
              onChange={(v) => setValue('token', v)}
              placeholder={t('form.label.integration.hybrid.token')}
              error={errors.name}
              label={t('form.label.integration.hybrid.token').toUpperCase()}
              required
            />
          </Box>
        </>
      )}

      {kind === 'shopify' && (
        <>
          <Box mb={3}>
            <TextFieldRounded
              value={watch('payload.shop')}
              onChange={(v) => setValue('payload.shop', v)}
              placeholder={t('form.label.integration.shopify.shop')}
              error={errors.name}
              label={t('form.label.integration.shopify.shop').toUpperCase()}
              required
            />
          </Box>
          <Box mb={3}>
            <TextFieldRounded
              value={watch('payload.accessToken')}
              onChange={(v) => setValue('payload.accessToken', v)}
              placeholder={t('form.label.integration.shopify.accessToken')}
              error={errors.name}
              label={t('form.label.integration.shopify.accessToken').toUpperCase()}
              required
            />
          </Box>
        </>
      )}

      {kind === 'sifely' && (
        <>
          <Box mb={3}>
            <TextFieldRounded
              value={watch('payload.username')}
              onChange={(v) => setValue('payload.username', v)}
              placeholder={t('form.label.integration.sifely.username')}
              error={errors.name}
              label={t('form.label.integration.sifely.username').toUpperCase()}
              required
            />
          </Box>
          <Box mb={3}>
            <TextFieldRounded
              value={watch('payload.password')}
              onChange={(v) => setValue('payload.password', v)}
              placeholder={t('form.label.integration.sifely.password')}
              error={errors.name}
              label={t('form.label.integration.sifely.password').toUpperCase()}
              required
            />
          </Box>
          <Box mb={3}>
            <TextFieldRounded
              value={watch('payload.lockId')}
              onChange={(v) => setValue('payload.lockId', v)}
              placeholder={t('form.label.integration.sifely.lockId')}
              error={errors.name}
              label={t('form.label.integration.sifely.lockId').toUpperCase()}
              required
            />
          </Box>
        </>
      )}
      {kind === 'cardpointe' && (
        <>
          {/* <Button onClick={() => setTestValues()}>Set Fake Data</Button> */}
          <CardPointeBoardForm watch={watch} setValue={setValue} errors={errors} />
        </>
      )}
      {kind === 'stripe' && <StripeConnectedAccount />}
      {kind !== 'stripe' && (
        <Box mt={3} display="flex" justifyContent="flex-end">
          <ButtonPrimary disabled={loading} onClick={handleSubmit(onSubmit)}>
            {t('button.save')}
          </ButtonPrimary>
        </Box>
      )}
    </>
  );
};

ModalFormIntegrations.defaultProps = {
  callback: () => {},
  item: null,
  mode: 'new',
};

ModalFormIntegrations.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  callback: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.object,
  mode: PropTypes.string,
};

export default ModalFormIntegrations;
