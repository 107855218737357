import api from 'core/api';

export const usersUnavailableTimesModule = 'usersUnavailableTimes';

const loadingFormStates = {
  start: { loadingForm: true },
  error: { loadingForm: false },
  success: { loadingForm: false },
};

const actions = {
  getTimes: {
    module: usersUnavailableTimesModule,
    name: 'getTimes',
    api: (params) => api.get(`/channels/${params?.channelId}/users_unavailable_times`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingFormStates,
  },
  create: {
    module: usersUnavailableTimesModule,
    name: 'create',
    api: (data) => api.post(`/channels/${data?.channelId}/users_unavailable_times`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingFormStates,
  },
  update: {
    module: usersUnavailableTimesModule,
    name: 'update',
    api: (data) =>
      api.put(`/channels/${data?.channelId}/users_unavailable_times/${data?.id}`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingFormStates,
  },
  delete: {
    module: usersUnavailableTimesModule,
    name: 'delete',
    api: (data) => api.delete(`/channels/${data?.channelId}/users_unavailable_times/${data?.id}`),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingFormStates,
  },
};

const usersUnavailableTimesActionsAndStates = {
  actions,
  state: {
    data: [],
    error: null,
    loadingForm: false,
  },
};

export default usersUnavailableTimesActionsAndStates;
