import React, { useRef } from 'react';
import Box from 'core/ui/Box';
import { ButtonPrimary } from 'core/ui/Button';
import { useTranslation } from 'react-i18next';
import {
  ButtonGroup,
  Paper,
  MenuList,
  MenuItem,
  Popper,
  ClickAwayListener,
} from '@material-ui/core';
import { ArrowDropDown, OpenWith } from '@material-ui/icons';
import FontIcon from 'core/ui/FontIcon';
import BarChartIcon from '@material-ui/icons/BarChart';
import TimelineIcon from '@material-ui/icons/Timeline';

const MenuSection = ({ actions, section, showScoreButtons, showCompareButtons, buttonColor }) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const anchorRef = useRef();

  return (
    <Box>
      <Box>
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <Box>
            <ButtonGroup
              variant="contained"
              color="primary"
              ref={anchorRef}
              aria-label="split button"
            >
              <ButtonPrimary
                buttonColor={buttonColor}
                ref={anchorRef}
                onClick={() => setOpen(!open)}
              >
                Actions <ArrowDropDown />
              </ButtonPrimary>
            </ButtonGroup>
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              style={{ zIndex: 9999 }}
              disablePortal
            >
              <Box>
                <Paper>
                  <MenuList id="split-button-menu">
                    {showScoreButtons && (
                      <>
                        <MenuItem
                          onClick={() => {
                            actions.onAddScoresClick(section?.id);
                            setOpen(false);
                          }}
                        >
                          <FontIcon iconName="icon-dumbbell" size={16} />
                          &nbsp;&nbsp;
                          {t('button.results')}
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            actions.onLeaderboardClick(section);
                            setOpen(false);
                          }}
                        >
                          <BarChartIcon style={{ fontSize: 22 }} />
                          &nbsp;
                          {t('button.leaderboard')}
                        </MenuItem>
                        {showCompareButtons && (
                          <MenuItem
                            onClick={() => {
                              actions.setModalHistory({ open: true, section });
                              setOpen(false);
                            }}
                          >
                            <TimelineIcon style={{ fontSize: 22 }} />
                            &nbsp;
                            {t('button.compareHistory')}
                          </MenuItem>
                        )}
                      </>
                    )}
                    {actions?.expandSection && (
                      <MenuItem
                        onClick={() => {
                          actions.expandSection(section);
                          setOpen(false);
                        }}
                      >
                        <OpenWith style={{ fontSize: 20 }} />
                        &nbsp;
                        {t('button.expandView')}
                      </MenuItem>
                    )}
                  </MenuList>
                </Paper>
              </Box>
            </Popper>
          </Box>
        </ClickAwayListener>
      </Box>
    </Box>
  );
};

MenuSection.propTypes = {};

MenuSection.defaultProps = {};

export default MenuSection;
