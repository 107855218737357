import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import propTypes from 'prop-types';
import { workoutsSelectors, workoutsActions } from 'modules/workouts';
import Box from 'core/ui/Box';
import { sessionSelectors } from 'modules/session';
import WorkoutCompVideo from 'components/Stream/Views/Recording/WorkoutCompVideo';
import WorkoutUserLabelMultipleScores from '../Participants/WorkoutUserLabelMultipleScores';

export default function ViewScores({ section }) {
  const workout = useSelector((state) => workoutsSelectors.getWorkout(state));
  const user = useSelector((state) => sessionSelectors.getUser(state));
  const dispatch = useDispatch();

  const sectionUserData = useSelector((state) =>
    workoutsSelectors.getWorkoutSectionUserData(state),
  );

  useEffect(() => {
    if (workout.id) {
      dispatch(
        workoutsActions.getWorkoutSectionUserStart({
          workoutId: workout.id,
          userId: user.id,
        }),
      );
    }
  }, [workout, user, dispatch]);

  return (
    <Box>
      <Box my={5} display="flex" justifyContent="center">
        {workout.isCompetition && <WorkoutCompVideo />}
      </Box>

      <Box display="flex" flexWrap="wrap" justifyContent="center">
        <WorkoutUserLabelMultipleScores
          userScores={sectionUserData[section.id] && sectionUserData[section.id].scores}
        />
      </Box>
    </Box>
  );
}

ViewScores.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  section: propTypes.object,
};

ViewScores.defaultProps = {
  section: {},
};
