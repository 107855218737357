import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import { InputWrapper } from 'core/ui/ChosenComponents';
import { InputBase } from '@material-ui/core';

export default ({ setText, text, title }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Box display="flex" alignItems="center">
        <InputWrapper>
          <Box display="flex" style={{ width: '100%' }}>
            <Box display="flex" flexGrow="1" flexWrap="wrap">
              <InputBase
                style={{ minWidth: 30, width: '100%', margin: '4px' }}
                className="fontInput"
                value={text}
                onChange={(e) => setText(e.target.value)}
                placeholder={title || t('workout.search.teams')}
              />
            </Box>

            {/* <Box>
              <ButtonPrimary
                style={{
                  height: '100%',
                  borderRadius: '5px',
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  padding: 10,
                }}
              >
                <FontIcon iconName="icon-search" color="white" size={24} />
              </ButtonPrimary>
            </Box> */}
          </Box>
        </InputWrapper>
      </Box>
    </Box>
  );
};
