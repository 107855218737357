/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import { KeyboardArrowDown, KeyboardArrowRight, People, RemoveCircle } from '@material-ui/icons';
import { Chip, Collapse, Link } from '@material-ui/core';
import Paper from 'core/ui/Paper';
import { sessionSelectors } from 'modules/session';
import { useSelector } from 'react-redux';
import Typography from 'core/ui/Typography';
import { ButtonPrimary } from 'core/ui/Button';
import Divider from 'core/ui/Divider';
import { redColor } from 'core/ui/Colors';
import Form from './Form';

export default function AddAthletes({
  setInvites,
  invites,
  competition,
  eventType,
  hideCustomFields,
  removeUser,
  setAsCaptain,
  captainId,
  edit,
}) {
  const { t } = useTranslation();
  const user = useSelector((state) => sessionSelectors.getUser(state));
  const numbersInvites = () => {
    if (eventType === 'individual') {
      return 1;
    }

    return competition?.teamSize;
  };

  const individual = numbersInvites() === 1;

  const changeInvite = (item, prop, value) => {
    setInvites((prev) =>
      (prev || [{}]).map((p) => {
        if (item === p) {
          return {
            ...p,
            [prop]: value,
          };
        }
        return p;
      }),
    );
  };

  const changeInviteObj = (item, obj) => {
    setInvites((prev) =>
      (prev || [{}]).map((p) => {
        if (item === p) {
          return {
            ...p,
            ...obj,
          };
        }
        return p;
      }),
    );
  };

  const addNewRow = () => {
    if (invites?.length <= numbersInvites()) {
      setInvites((prev) => [...(prev || []), {}]);
    }
  };

  const captainInfo =
    invites?.length > 0
      ? invites.find((x, idx) => (captainId ? x.id === captainId : idx === 0))
      : {};

  return (
    <Box>
      <Box my={2}>
        {!edit && (
          <Box display="flex" justifyContent="flex-end">
            <Link
              onClick={() =>
                changeInviteObj(captainInfo, {
                  name: user?.name,
                  email: user?.email,
                  phone: user?.phone,
                })
              }
            >
              Use my information
            </Link>
          </Box>
        )}
        <ItemAthlete
          changeInvite={changeInvite}
          changeInviteObj={changeInviteObj}
          hideCustomFields={hideCustomFields}
          item={captainInfo || {}}
          competition={competition}
          captain
        />

        {invites &&
          invites
            .filter((x, idx) => (captainId ? x.id !== captainId : idx !== 0))
            .map((row, idx) => (
              <ItemAthlete
                key={idx.toString()}
                changeInvite={changeInvite}
                hideCustomFields={hideCustomFields}
                item={row}
                competition={competition}
                changeInviteObj={changeInviteObj}
                removeUser={removeUser}
                setAsCaptain={setAsCaptain}
              />
            ))}

        {!individual && invites?.length < numbersInvites() && (
          <Box
            display="flex"
            my={2}
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            className="gap-10"
          >
            <Chip
              size="small"
              icon={<People />}
              label={`${invites?.length}/${numbersInvites()}`}
              color="secondary"
            />
            <ButtonPrimary onClick={() => addNewRow()}>{t('button.addAthlete')}</ButtonPrimary>
          </Box>
        )}
      </Box>
    </Box>
  );
}

const ItemAthlete = ({
  competition,
  changeInvite,
  hideCustomFields,
  item,
  captain,
  removeUser,
  setAsCaptain,
}) => {
  const [opened, setOpened] = useState(true);
  const { t } = useTranslation();

  return (
    <Paper className="hover" p={2} mb={1}>
      <Box display="flex" alignItems="center" onClick={() => setOpened(!opened)}>
        <Box flexGrow={1}>
          {hideCustomFields && (
            <Typography>{t(captain ? 'label.captain' : 'label.athlete')}</Typography>
          )}
          <Typography color="textSecondary" ml={1}>
            {item?.name || item?.email || item?.phone}
          </Typography>
        </Box>

        {/* <ButtonPrimary onClick={() => setFakeData()}>setTestData</ButtonPrimary> */}

        <Box display="flex" alignItems="center" className="gap-10">
          {!competition?.started && !captain && item?.id && (
            <ButtonPrimary
              onClick={() => setAsCaptain(item?.id)}
              buttonColor={competition?.buttonColor}
            >
              {t('button.setAsCaptain')}
            </ButtonPrimary>
          )}

          <Box className="hover" onClick={() => removeUser(item)}>
            {!!removeUser && !captain && item.id !== item.captainId && (
              <RemoveCircle style={{ color: redColor }} />
            )}
          </Box>

          {!item.id && (
            <>{opened ? <KeyboardArrowDown style={{}} /> : <KeyboardArrowRight style={{}} />}</>
          )}
        </Box>
      </Box>

      {!item.id && (
        <Collapse in={opened}>
          <Box>
            <Divider my={3} />
            <Form
              changeInvite={changeInvite}
              competition={competition}
              hideCustomFields={item.id || hideCustomFields}
              row={item}
            />
          </Box>
        </Collapse>
      )}
    </Paper>
  );
};
