import React, { useState } from 'react';
import propTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import Box from 'core/ui/Box';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Typography from 'core/ui/Typography';
import { formatDateToApi } from 'core/utils/formatters';
import Toggle from 'core/ui/Toggle';
import { ButtonPrimary } from 'core/ui/Button';
import notifications from 'modules/notifications';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import useSelectors from 'modules/map/useSelectors';
import { newRecurringsModule } from 'modules/recurrings/new';
import LoaderSm from 'core/ui/LoaderSm';
import DatePickerRounded from 'core/ui/DatePickers/DatePickerRounded';
import Texture from 'core/ui/Texture';

const ModalDaylightSavings = ({ open, close, channel, selectedRecurrings, callback }) => {
  const { t } = useTranslation();
  const loading = useSelectors(newRecurringsModule, 'loading');
  const { request } = useActions();
  const dispatch = useDispatch();
  const [current, setCurrent] = useState({});

  const confirm = () => {
    if (!current?.hour || selectedRecurrings?.length <= 0) {
      return;
    }
    request({
      action: Modules.newRecurrings.actions.updateDaylightSavings,
      data: {
        recurringsIds: selectedRecurrings,
        channelId: channel?.id,
        hour: current?.hour,
        startAt: formatDateToApi(current?.startAt),
      },
      options: {
        onSuccess: () => {
          dispatch(notifications.success(t('recurring.message.daylightSavings.success')));
          if (callback) {
            callback();
          }
          close();
        },
      },
    });
  };

  return (
    <Dialog
      PaperProps={{ style: { borderRadius: 5 } }}
      maxWidth="sm"
      scroll="body"
      onClose={() => close()}
      open={open}
      fullWidth
    >
      <Texture>
        <Box p={10}>
          <Typography mb={5} variant="h2" align="center" color="primary">
            {t('recurring.daylightSavings')}
          </Typography>

          <Box display="flex" justifyContent="center" m={3}>
            <Toggle
              options={[
                { value: -1, label: '-1' },
                { value: 1, label: '+1' },
              ]}
              color="white"
              value={current?.hour}
              onChange={(v) => setCurrent((prev) => ({ ...prev, hour: v }))}
            />
          </Box>

          <Box display="flex" justifyContent="center" m={3}>
            <DatePickerRounded
              label={t('recurring.dayLightChange.date').toUpperCase()}
              onChange={(v) => setCurrent((prev) => ({ ...prev, startAt: v }))}
              value={current.startAt}
              disablePast={false}
              usFormat={channel?.useUsDateFormat}
              autoOk
            />
          </Box>

          <ButtonPrimary disabled={loading} fullWidth onClick={() => confirm()}>
            <LoaderSm loading={loading} />
            {t('button.confirm')}
          </ButtonPrimary>
        </Box>
      </Texture>
    </Dialog>
  );
};

ModalDaylightSavings.propTypes = {
  open: propTypes.bool,
  close: propTypes.func,
  callback: propTypes.func,
};

ModalDaylightSavings.defaultProps = {
  open: false,
  close: null,
  callback: null,
};

export default ModalDaylightSavings;
