import React from 'react';
/* eslint-disable react/prop-types */
import { IconButton } from '@material-ui/core';
import {
  DeleteForever,
  Edit,
  Info,
  KeyboardArrowDown,
  KeyboardArrowRight,
  Visibility,
  VisibilityOff,
} from '@material-ui/icons';
import Box from 'core/ui/Box';
import { labelLight } from 'core/ui/Colors';
import FontIcon from 'core/ui/FontIcon';
import Typography from 'core/ui/Typography';

const ActionsTeam = ({
  item,
  competition,
  setOpenTeamEdit,
  removeUser,
  withdrawUser,
  detail,
  openDetail,
  isCoach,
  currentUser,
  affiliateGym,
  setModalHeats,
  setTeamDetails,
}) => (
  <Box className="tw-flex tw-flex-1 tw-flex-row tw-items-center tw-gap-2">
    <Box onClick={() => openDetail()} className="tw-flex tw-grow tw-flex-row tw-items-center">
      {item.regType === 'team' ? (
        <Typography noWrap style={{ color: labelLight }}>
          {item.team.teamName}
        </Typography>
      ) : (
        <Box>
          <Typography noWrap style={{ color: labelLight }}>
            {item?.team?.userName}
          </Typography>
        </Box>
      )}
      {openDetail && (
        <IconButton style={{ marginRight: 3 }} onClick={() => openDetail()} className="hover">
          {!detail ? (
            <KeyboardArrowRight fontSize="small" />
          ) : (
            <KeyboardArrowDown fontSize="small" />
          )}
        </IconButton>
      )}
    </Box>

    {(isCoach || item?.team.id === currentUser?.id || affiliateGym(item)) && (
      <IconButton
        size="small"
        onClick={() =>
          setOpenTeamEdit({
            open: true,
            userId: item.team?.id,
          })
        }
        className="hover"
      >
        <Edit fontSize="small" />
      </IconButton>
    )}
    {isCoach && setTeamDetails && (
      <IconButton title="Scores" size="small" onClick={() => setTeamDetails(true)}>
        <Info fontSize="small" />
      </IconButton>
    )}
    {(isCoach || affiliateGym(item)) && (
      <IconButton size="small" onClick={() => removeUser(item.team.captainId)} className="hover">
        <DeleteForever fontSize="small" />
      </IconButton>
    )}
    {isCoach && (
      <IconButton size="small" onClick={() => withdrawUser(item.team.captainId)} className="hover">
        {item?.team?.withdraw ? (
          <Visibility fontSize="small" />
        ) : (
          <VisibilityOff fontSize="small" />
        )}
      </IconButton>
    )}
    {setModalHeats && competition?.heats && (
      <IconButton
        size="small"
        onClick={() => setModalHeats({ open: true, userId: item?.team?.id })}
      >
        <FontIcon iconName="icon-fire" color="secondary" size={18} />
      </IconButton>
    )}
  </Box>
);

export default ActionsTeam;
