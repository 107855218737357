import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import Typography, { fontStyle } from 'core/ui/Typography';
import MovimentChosen from 'core/ui/MovimentChosen';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from '@material-ui/core';

import ModalAddMedia from 'components/LibraryMedias/AddMedia/Modal';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import { ButtonPrimarySm } from 'core/ui/Button';
import { primary, secondary } from 'core/ui/Colors';

export default ({ movements, setMovements, hideTable, ownerableId, ownerableType }) => {
  const { t } = useTranslation();
  const [modal, setModal] = useState({
    open: false,
  });
  const setAlt = (value, index) => {
    const mov = movements ? [...movements] : [];
    mov[index] = {
      name: movements[index].name || movements[index],
      movement: value[0],
    };
    setMovements(mov);
  };

  const setVideo = (value, index) => {
    const mov = movements ? [...movements] : [];
    mov[index] = {
      id: mov[index].id,
      name: mov[index].name || mov[index],
      movement: mov[index].movement,
      embedVideo: value,
    };
    setMovements(mov);
  };

  return (
    <Box>
      {ownerableId && modal.open && (
        <ModalAddMedia
          open={modal.open}
          ownerableId={ownerableId}
          ownerableType={ownerableType}
          associableType="Movement"
          associableId={modal.movement?.id}
          title={modal.movement?.name}
          currentUrl={modal?.movement?.mediaFile?.url || modal?.movement?.embedVideo}
          close={() => setModal(() => ({ open: false }))}
          callback={(data) => {
            setVideo(data?.url, modal?.index);
            setModal(() => ({ open: false }));
          }}
        />
      )}
      <Box>
        <Typography ml={2} mb={1} style={fontStyle.label} variant="body1">
          {t('workout.label.movements').toUpperCase()}
        </Typography>

        <MovimentChosen
          placeholder={t('workout.placeholder.movements')}
          setValue={(values) => setMovements(values)}
          limit={1000}
          val={movements ? [...movements] : []}
          ownerableId={ownerableId}
          ownerableType={ownerableType}
        />
      </Box>
      {!hideTable && movements && (
        <Box mt={1}>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>{t('workout.label.movement').toUpperCase()}</TableCell>

                  <TableCell>{t('workout.label.alternative').toUpperCase()}</TableCell>
                  <TableCell>{t('workout.label.library').toUpperCase()}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {movements &&
                  movements.map((row, index) => (
                    <TableRow key={index.toString()}>
                      <TableCell component="th" scope="row">
                        {row.name || row}
                      </TableCell>

                      <TableCell>
                        <MovimentChosen
                          limit={1}
                          placement="right-start"
                          placeholder={t('workout.placeholder.alternativeMovement')}
                          setValue={(values) => setAlt(values, index)}
                          val={row.movement ? [`${row.movement.name || row.movement}`] : []}
                          ownerableId={ownerableId}
                          ownerableType={ownerableType}
                        />
                      </TableCell>
                      <TableCell>
                        <Box display="flex" alignItems="center">
                          {!!row.id && (
                            <ButtonPrimarySm
                              className="hover"
                              onClick={() => setModal({ open: true, movement: row, index })}
                              style={{
                                backgroundColor:
                                  !row.embedVideo && !row?.mediaFile?.url ? secondary : primary,
                              }}
                            >
                              <VideoLibraryIcon style={{ color: 'white' }} />
                            </ButtonPrimarySm>
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </Box>
  );
};
