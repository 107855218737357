import React, { useState } from 'react';
import Box from 'core/ui/Box';
import Paper from 'core/ui/Paper';
import Typography, { fontStyle } from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import Paginator from 'core/ui/Paginator';
import { Avatar, Dialog, IconButton, Link } from '@material-ui/core';
import { Delete, Notifications, OpenInBrowser } from '@material-ui/icons';
import Grid from 'core/ui/Grid';
import Texture from 'core/ui/Texture';
import MarkunreadIcon from '@material-ui/icons/Markunread';
import MarkunreadMailboxIcon from '@material-ui/icons/MarkunreadMailbox';
import { fromNow } from 'core/utils/formatters';
import { primary, secondary } from 'core/ui/Colors';
import SettingsIcon from '@material-ui/icons/Settings';
import LoaderSm from 'core/ui/LoaderSm';
import Divider from 'core/ui/Divider';
import Button, { ButtonPrimarySm } from 'core/ui/Button';
import { abbrevName } from 'core/utils/helpers';
import useUserMessages from './useUserMessages';
import useDeepLinks from './useDeepLinks';
import Settings from './Settings';

export default () => {
  const { t } = useTranslation();
  const {
    deleteMessage,
    viewMessage,
    data,
    pagination,
    modalView,
    setModalView,
    getMessages,
    messagesTypes,
    toggleMessageType,
    loading,
    markAllAsRead,
    localSettings,
    setLocalSettings,
  } = useUserMessages(true);

  const [view, setView] = useState('notifications');

  const { openRoute } = useDeepLinks();

  return (
    <Box>
      <Dialog
        maxWidth="sm"
        scroll="body"
        fullWidth
        onClose={() => setModalView({ open: false })}
        open={modalView?.open}
      >
        <Texture>
          <Box p={3}>
            <Typography variant="h5">{modalView?.message?.title}</Typography>
            <Paper p={3} my={3}>
              <div
                dangerouslySetInnerHTML={{ __html: modalView?.message?.body }}
                className="roboto-regular"
              />
            </Paper>
          </Box>
        </Texture>
      </Dialog>
      <Paper className="paper-rounded" mb={3} pb={3}>
        <Box mb={3} px={2} flexGrow="1" display="flex" alignItems="center">
          <Box flexGrow="1" display="flex" alignItems="center">
            <Typography ml={1} color="secondary" className="link" variant="h5">
              {t(
                view === 'notifications'
                  ? 'dashboard.tab.notifications'
                  : 'dashboard.tab.messageSettings',
              ).toUpperCase()}
            </Typography>
            <LoaderSm loading={loading} />
          </Box>
          {view === 'notifications' && (
            <Button onClick={() => markAllAsRead()}>
              <Typography>{t('button.markAllAsRead')}</Typography>
            </Button>
          )}
          <IconButton
            onClick={() => setView(view === 'notifications' ? 'settings' : 'notifications')}
          >
            {view !== 'notifications' ? <Notifications /> : <SettingsIcon />}
          </IconButton>
        </Box>

        {view === 'notifications' ? (
          <>
            {data?.map((message, index) => (
              <Box key={message?.id} className="message-hover">
                <Grid container spacing={5} px={2}>
                  <Grid item xs={12} md={8} onClick={() => viewMessage(message)}>
                    <Box display="flex">
                      {(message?.message?.userOrig?.image?.image?.url ||
                        message?.message?.channel?.image?.image?.url) && (
                        <Avatar
                          src={
                            message?.message?.userOrig?.image?.image?.url ||
                            message?.message?.channel?.image?.image?.url
                          }
                        />
                      )}
                      <Box ml={3}>
                        <Typography variant="body">{message?.title}</Typography>
                        <div
                          dangerouslySetInnerHTML={{ __html: abbrevName(message?.body, 150) }}
                          className="roboto-regular"
                        />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Box display="flex" flexDirection="column" alignItems="flex-end">
                      <Typography style={{ ...fontStyle.small }} className="roboto-regular">
                        {fromNow(message.createdAt)}
                      </Typography>
                      <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
                        {message?.message?.actionLink && (
                          <Box pb={2}>
                            <Link href={message?.message?.actionLink} target="_blank">
                              <ButtonPrimarySm px={5}>
                                {message?.message?.actionText || 'View'}
                              </ButtonPrimarySm>
                            </Link>
                          </Box>
                        )}
                        {message?.message?.pushData?.data?.url && (
                          <IconButton
                            onClick={() =>
                              openRoute({ url: message?.message?.pushData?.data?.url })
                            }
                          >
                            <OpenInBrowser style={{ color: secondary }} />
                          </IconButton>
                        )}
                        <IconButton onClick={() => viewMessage(message)}>
                          {message.visualized ? (
                            <MarkunreadMailboxIcon fontSize="small" />
                          ) : (
                            <MarkunreadIcon fontSize="small" style={{ color: primary }} />
                          )}
                        </IconButton>
                        <IconButton onClick={() => deleteMessage(message?.id)}>
                          <Delete fontSize="small" color="secondary" />
                        </IconButton>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                {data?.length - 1 > index && <Divider my={1} />}
              </Box>
            ))}
            <Box flexGrow={1} m={3} mt={5} display="flex" justifyContent="center">
              <Paginator pagination={pagination} changePage={(p) => getMessages(p)} />
            </Box>
          </>
        ) : (
          <Settings
            setLocalSettings={setLocalSettings}
            localSettings={localSettings}
            messagesTypes={messagesTypes}
            toggleType={toggleMessageType}
          />
        )}
      </Paper>
    </Box>
  );
};
