import React from 'react';
import Paper from 'core/ui/Paper';
import Typography from 'core/ui/Typography';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import ViewVideo from './ViewVideo';

export default function ItemMedia({ mediaFile, onSelect, isSelected }) {
  const { t } = useTranslation();
  const hasUrl = mediaFile?.url;
  return (
    <Paper className="paper-raounded" p={3}>
      <Box>
        <Typography mb={2} noWrap style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
          {mediaFile?.name || mediaFile?.url}
        </Typography>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          style={{ minHeight: 180 }}
        >
          <ViewVideo url={mediaFile?.url} />

          {onSelect && (
            <Box mt={3} display="flex" justifyContent="center" alignItems="center">
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                className="hover"
                onClick={() => onSelect(mediaFile)}
              >
                {isSelected ? (
                  <Typography style={{ fontSize: hasUrl ? 14 : 24, color: 'green' }}>
                    {t('button.selected')}
                  </Typography>
                ) : (
                  <Typography style={{ fontSize: hasUrl ? 14 : 24, color: '#ec4141' }}>
                    {t('button.select')}
                  </Typography>
                )}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Paper>
  );
}
