import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import Box from 'core/ui/Box';
import Rating from 'core/ui/Rating';
import { sessionSelectors } from 'modules/session';
import SocialMediaButtons from 'core/ui/SocialMediaButtons';
import { channelUrlSlug } from 'core/utils/helpers';
import Grid from 'core/ui/Grid';
import { Hidden } from '@material-ui/core';
import { channelsSelectors } from 'modules/channels';
import { primary } from 'core/ui/Colors';
import ImageMedia from '../ImageMedia';
import General from './General';

const useStyles = makeStyles((theme) => ({
  roundImage: {
    position: 'relative',
    borderRadius: 10,
    overflow: 'hidden',
  },
  colorTitle: {
    color: '#404041',
  },
  boxRating: {
    position: 'absolute',
    marginTop: '-40px',
    marginLeft: 40,
  },
  boxRegister: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  buttons: {
    color: '#fff',
    minWidth: 130,
  },
  boxButtonsHeader: {
    position: 'absolute',
    marginTop: '-45px',
    right: 30,
    cursor: 'pointer',
  },
}));

const ShowHeader = ({ buttonColor }) => {
  const classes = useStyles();

  const loading = useSelector((state) => channelsSelectors.getLoading(state));
  const channel = useSelector((state) => channelsSelectors.getChannel(state));

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <Box className={classes.roundImage}>
          <ImageMedia image={channel.image ? channel.image : {}} />
          {loading === false && (
            <Box display="flex" justifyContent="flex-start" pr={5}>
              <Box className={classes.boxRating}>
                <Rating
                  readOnly
                  name="channel_ratings"
                  value={channel.rating ? channel.rating.avg : 0}
                  buttonColor={buttonColor || primary}
                />
              </Box>
              <Box display="flex" alignItems="center" className={classes.boxButtonsHeader}>
                <Box ml={2}>
                  <SocialMediaButtons
                    url={channelUrlSlug(channel)}
                    quote={channel.description}
                    size={20}
                    buttonColor={buttonColor}
                  />
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Grid>
      <Hidden only={['sm', 'xs']}>
        <Grid item xs={12} md={6}>
          <General />
        </Grid>
      </Hidden>
    </Grid>
  );
};

export default ShowHeader;
