/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import Texture from 'core/ui/Texture';
import { Dialog } from '@material-ui/core';
import CountryChosen from 'core/ui/CountryChosen';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import StatesChosen from 'core/ui/StatesChosen';
import { ButtonPrimary } from 'core/ui/Button';
import useForm from './form';

const ModalFormAddress = ({ open, item, close, callback, userId, channelId, hideLocation }) => {
  const { t } = useTranslation();
  const { setValue, watch, handleSubmit, onSubmit, errors, loading } = useForm({
    item,
    callback,
    userId,
    channelId,
  });

  return (
    <Dialog maxWidth="xs" scroll="body" fullWidth onClose={() => close()} open={open}>
      <Texture>
        <Box p={5}>
          {!hideLocation && (
            <Box mb={3}>
              <TextFieldRounded
                value={watch('description')}
                onChange={(v) => setValue('description', v)}
                placeholder={t('label.locationName')}
                label={t('label.locationName').toUpperCase()}
                bordered
                error={errors?.description}
              />
            </Box>
          )}
          <Box mb={3}>
            <CountryChosen
              name="countryId"
              defaultValue={watch('countryId')}
              placeholder={t('form.address.label.country')}
              label={t('form.address.label.country').toUpperCase()}
              setValue={(v) => setValue('countryId', v)}
              required
              bordered
              error={errors?.countryId}
            />
          </Box>
          <Box mb={3}>
            <TextFieldRounded
              value={watch('street')}
              onChange={(v) => setValue('street', v)}
              placeholder={t('form.address.label.address')}
              label={t('form.address.label.address').toUpperCase()}
              bordered
              error={errors?.street}
            />
          </Box>
          <Box mb={3}>
            <TextFieldRounded
              value={watch('city')}
              onChange={(v) => setValue('city', v)}
              placeholder={t('form.address.label.city')}
              label={t('form.address.label.city').toUpperCase()}
              bordered
              error={errors?.city}
            />
          </Box>
          <Box mb={3}>
            <StatesChosen
              name="state"
              defaultValue={watch('state')}
              setValue={(v) => setValue('state', v)}
              countryId={watch('countryId')}
              placeholder={t('form.address.label.state')}
              label={t('form.address.label.state').toUpperCase()}
              bordered
              error={errors?.state}
            />
          </Box>
          <Box mb={3}>
            <TextFieldRounded
              value={watch('zip')}
              onChange={(v) => setValue('zip', v)}
              placeholder={t('form.address.label.zip')}
              label={t('form.address.label.zip').toUpperCase()}
              bordered
              required
              error={errors?.zip}
            />
          </Box>

          <Box mt={3} display="flex" justifyContent="flex-end">
            <ButtonPrimary disabled={loading} onClick={handleSubmit(onSubmit)}>
              {t('button.save')}
            </ButtonPrimary>
          </Box>
        </Box>
      </Texture>
    </Dialog>
  );
};

ModalFormAddress.defaultProps = {
  close: () => {},
  callback: () => {},
  open: false,
};

ModalFormAddress.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  close: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  callback: PropTypes.func,
  open: PropTypes.bool,
};

export default ModalFormAddress;
