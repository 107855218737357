import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import { Dialog } from '@material-ui/core';
import { ButtonPrimary } from 'core/ui/Button';
import Texture from 'core/ui/Texture';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import LoaderSm from 'core/ui/LoaderSm';

import useForm from './useForm';

const ModalAddMovement = ({ open, close, callback }) => {
  const { t } = useTranslation();

  const { watch, setValue, loading, handleSubmit, onSubmit } = useForm({ callback });

  return (
    <Dialog maxWidth="sm" fullWidth scroll="body" onClose={() => close()} open={open}>
      <Texture>
        <Box p={5}>
          <Box>
            <Box mb={3}>
              <TextFieldRounded
                label={t('label.name')}
                value={watch('name')}
                onChange={(v) => setValue('name', v)}
                placeholder={t('placeholder.name').toUpperCase()}
                bordered
              />
            </Box>
            <Box mt={3} display="flex" justifyContent="flex-end">
              <ButtonPrimary
                style={{ minWidth: 150 }}
                disabled={loading}
                onClick={() => handleSubmit(onSubmit)()}
              >
                <LoaderSm loading={loading} />
                {t('button.save')}
              </ButtonPrimary>
            </Box>
          </Box>
        </Box>
      </Texture>
    </Dialog>
  );
};

ModalAddMovement.defaultProps = {
  close: () => {},
  callback: () => {},
  open: false,
};

ModalAddMovement.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  close: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  callback: PropTypes.func,
  open: PropTypes.bool,
};

export default ModalAddMovement;
