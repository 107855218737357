import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form-v5';
import { useDispatch } from 'react-redux';
import notifications from 'modules/notifications';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import useSelectors from 'modules/map/useSelectors';
import { competitionsCalendarsModule } from 'modules/competitionsCalendars';
import { formatDateApi, formatDateToApi } from 'core/utils/formatters';
import { timeZoneName } from 'core/utils/formatters/date';
import useConfirmation from 'core/useConfirmation';

const useFormCalendarsCompetitionEvents = ({ competition, item, callback }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loading = useSelectors(competitionsCalendarsModule, 'loading');
  const { request } = useActions();
  const { confirmation } = useConfirmation();
  const { register, handleSubmit, setValue, watch, reset, errors } = useForm();

  const onSubmit = (values) => {
    const startAtStr = `${values?.startAtDate?.substr(0, 10)} ${values?.startAtHour?.substr(
      11,
      8,
    )}`;
    const timezone = timeZoneName();
    let endAtStr = null;
    if (values?.endAtDate) {
      endAtStr = `${values?.endAtDate?.substr(0, 10)} ${values?.endAtHour?.substr(
        11,
        8,
      )} ${timezone}`;
    }

    const startAt = formatDateToApi(startAtStr, timezone);
    const endAt = formatDateToApi(endAtStr, timezone);

    request({
      action: !item?.id
        ? Modules.competitionsCalendars.actions.postCompetitionsCalendars
        : Modules.competitionsCalendars.actions.putCompetitionsCalendars,
      data: {
        ...values,
        id: item?.id || null,
        startAt,
        endAt,
        competitionId: competition.id,
      },
      options: {
        onSuccess: () => {
          if (callback) {
            callback(values.name);
          }
          dispatch(notifications.success(t('message.success.save.competitionCalendarsEvent')));
        },
      },
    });
  };

  const removeEvent = () => {
    confirmation({
      description: t('areYouSure'),
      yesClick: () => {
        request({
          action: Modules.competitionsCalendars.actions.destroy,
          data: {
            id: item?.id,
            competitionId: competition.id,
          },
          options: {
            onSuccess: () => {
              if (callback) {
                callback();
              }
              dispatch(
                notifications.success(t('message.success.delete.competitionCalendarsEvent')),
              );
            },
          },
        });
      },
    });
  };

  useEffect(() => {
    register('name', { required: true });
    register('description', { required: true });

    register('startAtDate', { required: true });
    register('startAtHour', { required: true });
    register('endAtDate', { required: true });
    register('endAtHour', { required: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register]);

  useEffect(() => {
    if (item && item?.id) {
      reset({
        ...item,

        startAtDate: formatDateApi(item.startAt, 'YYYY-MM-DD HH:mm:ss Z', item.timezone),
        startAtHour: formatDateApi(item.startAt, 'YYYY-MM-DD HH:mm:ss Z', item.timezone),
        endAtDate: formatDateApi(item.endAt, 'YYYY-MM-DD HH:mm:ss Z', item.timezone),
        endAtHour: formatDateApi(item.endAt, 'YYYY-MM-DD HH:mm:ss Z', item.timezone),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, item?.id]);

  return {
    register,
    setValue,
    watch,
    reset,
    errors,
    onSubmit,
    handleSubmit,
    loading,
    removeEvent,
  };
};

export default useFormCalendarsCompetitionEvents;
