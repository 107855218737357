import React, { useCallback, useEffect, useState } from 'react';

import Modules from 'modules';
import useActions from 'modules/map/useActions';
import SFDataTable from 'core/ui/DataTable';
import { showAPIError } from 'core/utils/showAPIError';
import Typography from 'core/ui/Typography';
import { date } from 'core/utils/formatters';
import { channelsSelectors } from 'modules/channels';
import { useSelector } from 'react-redux';
import { StatusChip } from './StatusChip';
import { useStyles } from './styles';
import { ImportFromCsvButton } from './ImportFromCsvButton';

const columns = [
  {
    name: '#',
    selector: 'id',
    sortable: true,
    cell: (row) => <Typography>{row.id}</Typography>,
  },
  {
    name: 'Status',
    selector: 'status',
    sortable: false,
    cell: (row) => <StatusChip status={row.status} />,
  },
  {
    name: 'Created At',
    selector: 'createdAt',
    sortable: true,
    cell: (row) => <Typography title={row.createdAt}>{date(row.createdAt)}</Typography>,
  },
];

export const ImportMembers = () => {
  const classes = useStyles();
  const { request } = useActions();
  const channel = useSelector((state) => channelsSelectors.getChannel(state));

  const [channelsUsersImport, setChannelsUsersImport] = useState([]);

  const loadChannelsUsersImport = useCallback(() => {
    request({
      action: Modules.channelsUsersImport.actions.index,
      data: {
        channelId: channel?.id,
      },
      options: {
        onSuccess: setChannelsUsersImport,
        onError: showAPIError,
      },
    });
  }, [request]);

  useEffect(() => {
    if (!channelsUsersImport.find(({ status }) => status === 'enqueued' || status === 'processing'))
      return () => {};

    const interval = setInterval(() => {
      loadChannelsUsersImport();
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [loadChannelsUsersImport, channelsUsersImport]);

  useEffect(() => {
    loadChannelsUsersImport();
  }, [loadChannelsUsersImport]);

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <ImportFromCsvButton loadChannelsUsersImport={loadChannelsUsersImport} />
      </div>

      <SFDataTable columns={columns} data={channelsUsersImport} noHeader pagination />
    </div>
  );
};
