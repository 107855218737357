import React, { useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { useTranslation } from 'react-i18next';

import propTypes from 'prop-types';
import Box from 'core/ui/Box';
import Typography from 'core/ui/Typography';
import { useDispatch } from 'react-redux';
import { workoutsActions } from 'modules/workouts';
import FormScore from './FormScore';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#ececec',
  },
}));

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function ModalScore({ workout, user, open, setOpen, sectionData, callbackSave }) {
  const { t } = useTranslation();
  const classes = useStyles();

  const dispatch = useDispatch();

  useEffect(() => {
    if (open && workout && workout.id && workout.method !== 'custom') {
      dispatch(
        workoutsActions.getWorkoutSectionUserStart({
          workoutId: workout.id,
          userId: user.id,
        }),
      );
    }
  }, [open, workout, user, dispatch]);

  return (
    <div>
      <Dialog
        PaperProps={{ style: { borderRadius: 5 } }}
        maxWidth="sm"
        fullWidth
        scroll="body"
        onClose={() => setOpen(false)}
        open={open}
      >
        <Box px={5} pb={5} className={classes.root}>
          <DialogContent>
            <Typography color="primary" align="center" variant="h4">
              {t('workout.modal.section.score')}
            </Typography>

            <FormScore
              workout={workout}
              user={user}
              sectionData={sectionData}
              saveCallback={() => {
                if (callbackSave) {
                  callbackSave();
                }
                setOpen(false);
              }}
            />
          </DialogContent>
        </Box>
      </Dialog>
    </div>
  );
}

ModalScore.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  workout: propTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  user: propTypes.object.isRequired,
  open: propTypes.bool,
  setOpen: propTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  sectionData: propTypes.object,
};

ModalScore.defaultProps = {
  open: false,
  setOpen: null,
  sectionData: {},
};
