import React, { useEffect, useState } from 'react';
import Box from 'core/ui/Box';
import Paper from 'core/ui/Paper';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import Typography from 'core/ui/Typography';
import ModalPartnerProgramDates from './Workouts/Modal';
import ModalPartnerProgramCloneWeek from './Workouts/ModalCloneWeek';
import ModalChannels from './Channels/ModalChannels';
import ItemProgram from './ItemProgram';
import ItemActionsProgram from './ItemActionsProgram';

const PartnerProgramsList = ({ partner, currentProgram, setModalForm, getPrograms }) => {
  const [modalFormWorkout, setModalFormWorkout] = useState({ open: false });
  const [modalChannels, setModalChannels] = useState({ open: false });
  const [modalCloneWeek, setModalCloneWeek] = useState({ open: false });
  const [currentSubProgram, setCurrentSubProgram] = useState(null);

  const closeModal = () => {
    setModalFormWorkout({ open: false });
  };

  useEffect(() => {
    if (currentProgram?.parentPrograms?.length > 0) {
      setCurrentSubProgram(currentProgram?.parentPrograms[0]);
    } else {
      setCurrentSubProgram(currentProgram);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProgram?.id]);

  return (
    <Box mt={3}>
      <ModalPartnerProgramDates
        partner={partner}
        partnersProgram={modalFormWorkout?.partnersProgram}
        workoutId={modalFormWorkout?.workout?.id}
        clone={modalFormWorkout?.clone}
        close={() => closeModal()}
        open={modalFormWorkout.open}
        callbackSave={() => {
          getPrograms();
          closeModal();
        }}
      />
      {modalChannels?.open && (
        <ModalChannels
          partner={partner}
          open={modalChannels?.open}
          close={() => setModalChannels({ open: false })}
          partnersProgram={modalChannels?.partnersProgram}
          refreshPrograms={() => {
            getPrograms();
            setModalChannels({ open: false });
          }}
        />
      )}
      <ModalPartnerProgramCloneWeek
        open={modalCloneWeek?.open}
        close={() => setModalCloneWeek({ open: false })}
        dateClone={modalCloneWeek?.dateClone}
        partnersProgram={modalCloneWeek.partnersProgram}
        callback={() => {
          getPrograms();
          setModalCloneWeek({ open: false });
        }}
      />
      <Paper my={2} p={3}>
        <Box mb={1} display="flex">
          <Box flexGrow={1}>
            <Typography variant="h5">{currentProgram?.name}</Typography>
          </Box>
          <ItemActionsProgram
            program={currentProgram}
            setModalChannels={setModalChannels}
            setModalCloneWeek={setModalCloneWeek}
            setModalForm={setModalForm}
            setModalFormWorkout={setModalFormWorkout}
          />
        </Box>

        <Box p={2}>
          {currentProgram && currentProgram?.parentPrograms?.length > 0 && (
            <Box display="flex" alignItems="flex-end">
              <Box flexGrow={1} mr={3}>
                <SelectBoxObject
                  height={50}
                  label="Sub Program"
                  placeholder="Select the sub program"
                  value={currentSubProgram?.id}
                  setValue={(v) =>
                    setCurrentSubProgram(
                      currentProgram?.parentPrograms?.find((x) => x.id === Number(v)),
                    )
                  }
                  options={
                    currentProgram?.parentPrograms &&
                    currentProgram?.parentPrograms.map((x) => ({ id: x.id, name: x.name }))
                  }
                  bordered
                />
              </Box>
              <Box pb={3}>
                <ItemActionsProgram
                  program={currentSubProgram}
                  setModalChannels={setModalChannels}
                  setModalForm={setModalForm}
                  setModalFormWorkout={setModalFormWorkout}
                />
              </Box>
            </Box>
          )}

          {currentSubProgram && (
            <Box>
              <ItemProgram
                partner={partner}
                program={currentSubProgram}
                setModalChannels={setModalChannels}
                setModalCloneWeek={setModalCloneWeek}
                setModalForm={setModalForm}
                setModalFormWorkout={setModalFormWorkout}
              />
            </Box>
          )}
        </Box>
      </Paper>
    </Box>
  );
};

export default PartnerProgramsList;
