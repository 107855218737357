import { date } from 'core/utils/formatters';
import { formatWeekDays, formatWeekDaysToObj } from 'core/utils/helpers';
import Modules from 'modules';
import { channelsSelectors } from 'modules/channels';
import useActions from 'modules/map/useActions';
import notifications from 'modules/notifications';
import moment from 'moment';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

const useAvailabilityForm = ({ coachId } = {}) => {
  const { control } = useForm();
  const { t } = useTranslation();
  const channel = useSelector((state) => channelsSelectors.getChannel(state));
  const [currentCalendar, setCurrentCalendar] = useState();
  const [calendars, setCalendars] = useState([]);

  const defaultData = (time) => `${date(moment(), '01/MM/yyyy')} ${time}`;

  const [weeklyHours, setWeeklyHours] = useState({
    sunday: [],
    monday: [{ startTime: defaultData('09:00 am'), endTime: defaultData('10:00 am') }],
  });

  const dispatch = useDispatch();
  const { request } = useActions();

  const populateWeekDay = useCallback((calendarData) => {
    const obj = formatWeekDaysToObj(calendarData.usersCalendarsWeeklyHours, true);

    setWeeklyHours(obj);
  }, []);

  const saveCalendar = useCallback(() => {
    request({
      action: Modules.usersCalendars.actions.update,
      data: {
        channelId: channel?.id,
        id: currentCalendar?.id,
        usersCalendarsWeeklyHoursAttributes: formatWeekDays(weeklyHours),
        weekDays: currentCalendar?.weekDays,
      },
      options: {
        onSuccess: (data) => {
          populateWeekDay(data);
          dispatch(notifications.success(t('message.success.usersCalendars')));
        },
        onError: (error) => {
          dispatch(notifications.error(error));
        },
      },
    });
  }, [weeklyHours, request, channel, currentCalendar, dispatch, t, populateWeekDay]);

  const getCalendar = useCallback(
    (id) => {
      request({
        action: Modules.usersCalendars.actions.getCalendar,
        data: {
          id,
        },
        options: {
          onSuccess: (data) => {
            setCurrentCalendar(data);
            populateWeekDay(data);
          },
        },
      });
    },
    [request, populateWeekDay],
  );

  const getCalendars = useCallback(() => {
    request({
      action: Modules.usersCalendars.actions.getCalendars,
      data: {
        channelId: channel?.id,
        userId: coachId,
      },
      options: {
        onSuccess: setCalendars,
      },
    });
  }, [request, channel, coachId]);

  const deleteCalendar = useCallback(
    (id) => {
      request({
        action: Modules.usersCalendars.actions.delete,
        data: {
          channelId: channel?.id,
          id,
        },
        options: {
          onSuccess: () => {
            setCurrentCalendar(null);
            populateWeekDay({});
            getCalendars();
          },
        },
      });
    },
    [request, populateWeekDay, getCalendars, channel],
  );

  return {
    control,
    weeklyHours,
    setWeeklyHours,
    saveCalendar,

    currentCalendar,
    setCurrentCalendar,
    getCalendar,
    populateWeekDay,
    deleteCalendar,
    getCalendars,
    calendars,
  };
};

export default useAvailabilityForm;
