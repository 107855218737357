import api from 'core/api';

export const channelsRatesTemplatesModule = 'channelsRatesTemplates';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const actions = {
  getChannelsRatesTemplates: {
    module: channelsRatesTemplatesModule,
    name: 'getChannelsRatesTemplates',
    api: ({ channelId }) => api.get(`/channels/${channelId}/channels_rates_templates`),
    params: {
      start: ['params'],
      error: [''],
      success: ['data'],
    },
    state: loadingStates,
  },
  postChannelsRatesTemplates: {
    module: channelsRatesTemplatesModule,
    name: 'postChannelsRatesTemplates',
    api: (data) => api.post(`/channels/${data.channelId}/channels_rates_templates`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  putChannelsRatesTemplates: {
    module: channelsRatesTemplatesModule,
    name: 'putChannelsRatesTemplates',
    api: (data) => api.put(`/channels/${data.channelId}/channels_rates_templates/${data.id}`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
};

export default {
  actions,
  state: {
    loading: false,
    data: [],
    template: null,
  },
};
