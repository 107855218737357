import React from 'react';
import Box from 'core/ui/Box';
import Typography from 'core/ui/Typography';
import Paper from 'core/ui/Paper';
import Chronometer from 'components/Workout/Timer/Chronometer';
import PoweredLogo from 'components/Competition/Show/PoweredLogo';
import Grid from 'core/ui/Grid';
import { uCase } from 'core/utils/helpers';
import Player from '../Player';

export default ({ player, workout, competition, buttonColor, marks, startCountdownSeconds }) => {
  const heightBox = 120;

  const timeValue = () => {
    let { complete } = player.time;

    if (startCountdownSeconds > complete) {
      return 10;
    }

    if (startCountdownSeconds > 0) {
      complete -= startCountdownSeconds;
    }

    const value =
      complete >= 10 ? Number(Math.round(complete - 10, 0)) : Number(Math.round(10 - complete));

    return value > 0 ? value : 0;
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Box flexGrow={1}>
          <Paper style={{ height: heightBox }} className="paper-rounded opacity-80" pt={3} px={5}>
            <Typography variant="h5" component="h4">
              {uCase(workout.name)}
            </Typography>
            <Player
              time={player.time}
              playing={player.playing}
              setPlaying={player.setPlaying}
              setTime={player.setTime}
              setSeek={player.setSeek}
              muted={player.muted}
              checkedTeams={player.checkedTeams}
              ready={!!player.isReady}
              setMuted={player.setMuted}
              durationSeconds={player.durationSeconds}
              setManualSeek={player.setManualSeek}
              buttonColor={buttonColor}
              marks={marks}
            />
          </Paper>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box flexGrow={1}>
          <Paper style={{ height: heightBox }} className="paper-rounded opacity-80" pt={3} px={5}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Box>
                <Chronometer
                  timeValue={{
                    timeDisplay: timeValue(),
                  }}
                  fontSize={100}
                  spaceWidth={50}
                />
              </Box>

              <Box ml={5} style={{ width: '30%' }}>
                <PoweredLogo size={50} competition={competition} />
              </Box>
            </Box>
          </Paper>
        </Box>
      </Grid>
    </Grid>
  );
};
