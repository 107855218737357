/* eslint-disable react/prop-types */
import React from 'react';
import Box from 'core/ui/Box';

import { PRPercents } from 'core/utils/consts';
import Typography, { fontStyle } from 'core/ui/Typography';

import { secondaryXLight } from 'core/ui/Colors';

const PrCalculatorList = ({ value }) => {
  const list = () =>
    PRPercents.map((perc) => ({
      perc,
      value: value ? ((value / 100) * perc).toFixed(0) : 0,
    }));

  return (
    <Box className="grid grid-template-columns-4">
      {list().map((x, index) => (
        <Box
          style={{ border: `0.2px solid ${secondaryXLight}` }}
          display="flex"
          flexDirection="column"
          alignItems="center"
          key={index.toString()}
        >
          <Typography color="secondary" variant="h5">
            {x.value}
          </Typography>
          <Typography color="textSecondary" style={fontStyle.small} className="roboto-regular">
            {x.perc}%
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default PrCalculatorList;
