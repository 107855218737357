/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  Collapse,
  Table,
  TableRow,
  TableCell,
  styled,
  Link,
  makeStyles,
  Tooltip,
} from '@material-ui/core';
import { labelLight, onlineColor, secondary, secondaryLight } from 'core/ui/Colors';
import Box from 'core/ui/Box';
import FlagCountry from 'core/ui/FlagCountry';
import Typography, { fontStyle } from 'core/ui/Typography';
import { Info, KeyboardArrowDown, KeyboardArrowRight, VerifiedUser } from '@material-ui/icons';
import Divider from 'core/ui/Divider';
import InstagramLink from 'components/Profile/InstagramLink';
import ImageMediaUser from 'components/Profile/ImageMedia';
import { useTranslation } from 'react-i18next';
import FontIcon from 'core/ui/FontIcon';
import { ordinalNumber } from 'core/utils/formatters/date';
import { sessionSelectors } from 'modules/session';
import { useSelector } from 'react-redux';
import WorkoutScoresPoints from '../WorkoutScoresPoints';
import ActionsTeam from '../ActionsTeam';
import ActionsTeamWorkout from '../ActionsTeamWorkout';

const useStyles = makeStyles(() => ({
  buttonXs: {
    width: 27,
    height: 27,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const MobileTableResultsRow = ({
  competition,
  item,
  openModalScores,
  setOpenTeamEdit,
  removeUser,
  withdrawUser,
  compWorkouts,
  affiliate,
}) => {
  const currentUser = useSelector((state) => sessionSelectors.getUser(state));

  const classes = useStyles();
  const [currentOpen, setCurrentOpen] = useState(false);
  const { t } = useTranslation();
  const styleIcon = {
    color: secondary,
    fontSize: 20,
  };
  const { isCoach, isJudge } = competition;

  const cutLineStyle = () => ({
    borderBottom: item?.showCutline ? `3px solid ${competition?.buttonColor}` : 'none',
  });

  const mySelf = currentUser?.id === item?.team?.id;

  const showPointsRank = !competition?.hidePoints || isCoach || isJudge;

  const showVideo = (w) =>
    item?.workouts[w.id]?.leaderboard?.hasVideo &&
    (isCoach || isJudge || currentUser?.id === item?.team?.id);

  return (
    <Box style={{ ...cutLineStyle() }}>
      <Box
        px={2}
        display="flex"
        className="link"
        alignItems="center"
        onClick={() => setCurrentOpen(!currentOpen)}
      >
        <Box py={2} flexGrow="1" display="flex" alignItems="center" className="tw-gap-2">
          {showPointsRank && <Typography variant="h5">{affiliate ? '' : item.position}</Typography>}
          <Typography variant="h5" style={{ color: labelLight }}>
            {item?.team?.teamNumber}
          </Typography>
          <FlagCountry flag={item.team.country.flag} size={40} />

          <ActionsTeam
            item={item}
            competition={competition}
            setOpenTeamEdit={setOpenTeamEdit}
            removeUser={removeUser}
            affiliateGym={() => {}}
            withdrawUser={withdrawUser}
            isCoach={isCoach}
            currentUser={currentUser}
            openDetail={() => setCurrentOpen(!currentOpen)}
          />
        </Box>

        <Divider orientation="vertical" m={2} flexItem />

        <Box display="flex" alignItems="center" className="tw-w-20 tw-justify-end">
          {showPointsRank && (
            <Box>
              <Typography mr={2} color="secondary" variant="h5">
                {item.totalPoints}
              </Typography>
            </Box>
          )}
          {currentOpen ? (
            <KeyboardArrowDown style={{ ...styleIcon }} />
          ) : (
            <KeyboardArrowRight style={{ ...styleIcon }} />
          )}
        </Box>
      </Box>
      <Box>
        <Collapse in={currentOpen}>
          <Box>
            {!affiliate && item.team.affiliate && item.team.affiliate.gymName && (
              <Box pl={10} mb={2}>
                <Typography noWrap style={{ color: secondary }}>
                  {t('competition.affiliateGym')}:{' '}
                  {item.team.affiliate && item.team.affiliate.gymName}
                </Typography>
              </Box>
            )}
            <Box display="flex">
              <Box flexGrow={1}>
                <Table size="small">
                  {compWorkouts &&
                    compWorkouts.map((w) => {
                      const showScores =
                        w.showScores ||
                        isJudge ||
                        isCoach ||
                        (mySelf && !item.workouts[w.id]?.invalidCustomScores);
                      return (
                        <StyledTableRow key={w.id}>
                          <TableCell>
                            <Box display="flex" alignItems="center">
                              <Typography
                                style={{ ...fontStyle.medium }}
                                mr={2}
                                color="secondary"
                                className="roboto-regular"
                              >
                                {w.name}:
                              </Typography>
                              <Box display="flex" flexGrow={1}>
                                {showPointsRank && (
                                  <>
                                    {!!item.workouts[w.id]?.leaderboard &&
                                      !item.workouts[w.id]?.invalidCustomScores && (
                                        <Typography
                                          className="roboto-regular"
                                          color="secondary"
                                          mr={2}
                                          align="center"
                                          style={fontStyle.large}
                                        >
                                          <b>
                                            {ordinalNumber(
                                              item.workouts[w.id]?.leaderboard?.position,
                                            )}
                                          </b>
                                          &nbsp;&nbsp;
                                          {item.workouts[w.id]?.leaderboard?.points} points
                                        </Typography>
                                      )}

                                    {!!item.workouts[w.id]?.invalidCustomScores && (
                                      <div className="tw-flex tw-flex-row">
                                        <Tooltip
                                          title={t('competition.leaderboard.invalidCustomScorings')}
                                        >
                                          <Info />
                                        </Tooltip>
                                      </div>
                                    )}

                                    {item?.workouts[w.id]?.leaderboard?.verified && (
                                      <div
                                        style={{
                                          backgroundColor: onlineColor,
                                        }}
                                        className={classes.buttonXs}
                                      >
                                        <VerifiedUser style={{ color: 'white', fontSize: 15 }} />
                                      </div>
                                    )}
                                  </>
                                )}
                                {showVideo(w) && (
                                  <Link
                                    href={`/competition/${competition.id}/workout/${w.id}/watch/team/${item.team.id}`}
                                    target="_blank"
                                    underline="none"
                                    style={{
                                      marginLeft: 3,
                                      backgroundColor: secondaryLight,
                                    }}
                                    className={classes.buttonXs}
                                  >
                                    <FontIcon iconName="icon-classes" color={secondary} size={15} />
                                  </Link>
                                )}
                              </Box>
                              <Box display="flex" flexWrap="wrap" justifyContent="flex-end">
                                {showScores && (
                                  <Box display="flex" flexWrap="wrap">
                                    <WorkoutScoresPoints
                                      sectionScores={
                                        item.workouts[w.id]?.leaderboard?.sectionScores
                                      }
                                      scores={
                                        item.workouts[w.id]?.leaderboard?.revisedScores ||
                                        item.workouts[w.id]?.leaderboard?.scores
                                      }
                                      positions={item.workouts[w.id]?.leaderboard?.positions || {}}
                                      showVariation={w.competitionUseRxScaledScores}
                                      buttonColor={competition?.buttonColor}
                                    />
                                  </Box>
                                )}

                                {(isJudge || isCoach) && (
                                  <ActionsTeamWorkout
                                    item={item}
                                    openModalScores={openModalScores}
                                    w={w}
                                  />
                                )}
                              </Box>
                            </Box>
                          </TableCell>
                        </StyledTableRow>
                      );
                    })}
                </Table>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                // justifyContent="center"
                p={1}
              >
                {item.regType === 'team' ? (
                  <Box>
                    {item.team.country && <FlagCountry flag={item.team.country.flag} size={40} />}

                    {!!item.team.location && (
                      <Typography
                        color="secondary"
                        className="roboto-regular"
                        style={fontStyle.small}
                      >
                        {item.team.location}
                      </Typography>
                    )}
                    <Box display="flex" flexWrap="wrap" flexDirection="column">
                      {item.team.teamUsers &&
                        item.team.teamUsers.map((user, index) => (
                          <Box key={`team-${user?.id}`}>
                            <Typography color="secondary" className="tw-text-xs">
                              {user.name}
                            </Typography>
                            {item.team.teamUsers.length - 1 > index && <Divider my={1} />}
                          </Box>
                        ))}
                    </Box>
                  </Box>
                ) : (
                  <>
                    <ImageMediaUser
                      user={item.team}
                      image={item.team.image ? item.team.image : {}}
                      size={80}
                      heightCountry={15}
                      sizeCountry="1em"
                    />
                    {item.team.instagram && <InstagramLink instagram={item.team.instagram} />}
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </Collapse>
        <Divider />
      </Box>
    </Box>
  );
};

export default MobileTableResultsRow;
