import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Modal, Paper } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import ReactPlayer from 'react-player/vimeo';

import useActions from 'modules/map/useActions';
import { ButtonPrimary } from 'core/ui/Button';
import Modules from 'modules';
import useSelectors from 'modules/map/useSelectors';
import { helpVideoModule } from 'modules/helpVideo';
import Divider from 'core/ui/Divider';
import { withHealedCurrentPath } from 'hocs/withHealedCurrentPath';

import { AddNewHelpVideoButton } from './AddNewHelpVideoButton';
import { AddMapToHelpVideoButton } from './AddMapToHelpVideoButton';
import { EditHelpVideoButton } from './EditHelpVideoButton';
import { DestroyHelpVideoButton } from './DestroyHelpVideoButton';
import { useStyles } from './styles';

export const AdminArea = ({ currentHelpVideos, currentPath, getMappedHelpVideos }) => {
  const helpVideosToSelect = useSelectors(helpVideoModule, 'helpVideos');

  const { request } = useActions();

  const classes = useStyles();

  const [opened, setOpened] = useState(false);

  const alreadyAddedHelpVideoIds = useMemo(
    () => new Set(currentHelpVideos.map((helpVideo) => helpVideo.id)),
    [currentHelpVideos],
  );

  const getHelpVideosToSelect = useCallback(() => {
    request({ action: Modules.helpVideo.actions.index });
  }, [request]);

  const onClose = () => {
    getMappedHelpVideos();
    setOpened(false);
  };

  useEffect(() => {
    getHelpVideosToSelect();
  }, [getHelpVideosToSelect]);

  return (
    <Box className={classes.container}>
      {process.env.NODE_ENV === 'development' && <DebugHealedPath />}

      <ButtonPrimary onClick={() => setOpened(true)}>Add</ButtonPrimary>

      <Modal open={opened} onClose={onClose} className={classes.modal}>
        <Paper className={classes.paper}>
          <Box className={classes.header}>
            <AddNewHelpVideoButton getHelpVideosToSelect={getHelpVideosToSelect} />
          </Box>

          <Divider />

          <MUIDataTable
            title="System Help Videos"
            columns={[
              {
                name: 'title',
                label: 'Title',
                options: {
                  sortThirdClickReset: true,
                },
              },
              {
                name: 'vimeoUrl',
                label: 'Vimeo',
                options: {
                  customBodyRender: (item) => (
                    <div className={classes.tableVideoContainer}>
                      <ReactPlayer
                        url={item}
                        controls
                        light
                        width="100%"
                        height="100%"
                        style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}
                      />
                    </div>
                  ),
                  sort: false,
                  filter: false,
                },
              },
              {
                name: '',
                label: '',
                options: {
                  customBodyRender: (_, { rowIndex }) => {
                    const item = helpVideosToSelect[rowIndex];

                    return (
                      <div className={classes.tableOptionsContainer}>
                        <DestroyHelpVideoButton
                          helpVideo={item}
                          getHelpVideosToSelect={getHelpVideosToSelect}
                        />

                        <EditHelpVideoButton
                          helpVideo={item}
                          getHelpVideosToSelect={getHelpVideosToSelect}
                        />

                        <AddMapToHelpVideoButton
                          helpVideo={item}
                          alreadyAdded={alreadyAddedHelpVideoIds.has(item.id)}
                          currentPath={currentPath}
                          getMappedHelpVideos={getMappedHelpVideos}
                        />
                      </div>
                    );
                  },
                  filter: false,
                  sort: false,
                },
              },
            ]}
            data={helpVideosToSelect}
            options={{
              filter: false,
              viewColumns: false,
              print: false,
              download: false,
              selectableRows: false,
            }}
          />
        </Paper>
      </Modal>
    </Box>
  );
};

const DebugHealedPath = withHealedCurrentPath(
  ({ healedCurrentPath }) => healedCurrentPath ?? 'undefined',
);
