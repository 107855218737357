import React, { useState } from 'react';
import Box from 'core/ui/Box';
import Typography from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import { KeyboardArrowDown, KeyboardArrowRight, VerifiedUser } from '@material-ui/icons';
import { secondary, secondaryLight, successColor } from 'core/ui/Colors';
import { Collapse } from '@material-ui/core';
import Paper from 'core/ui/Paper';
import SFDataTable from 'core/ui/DataTable';
import { ButtonCompetition } from 'core/ui/Button';
import Checkbox from 'core/ui/Checkbox';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import WorkoutScoresPoints from 'components/Competition/Show/Tabs/Results/Tables/WorkoutScoresPoints';
import BoxSearch from '../../Judge/BoxSearch';

export default ({
  player,
  workout,
  competition,
  leaderboard,
  currentDivision,
  setCurrentDivision,
}) => {
  const { t } = useTranslation();
  const { buttonColor, fontColor } = competition;
  const [openTeams, setOpenTeams] = useState(true);
  const [text, setText] = useState('');

  const styleIcon = {
    color: secondary,
    fontSize: 20,
  };

  const dropdownWrapper = {
    padding: 5.3,
    backgroundColor: 'white',
    border: `0.5px solid ${secondaryLight}`,
    borderRadius: 5,
  };

  const isChecked = (id) => !!player.checkedTeams.find((x) => x === id);

  const toggleTeam = (id) => {
    // player.setPlaying(false);
    // player.setTime((prev) => ({ ...prev, complete: 0 }));

    if (isChecked(id)) {
      player.setCheckedTeams((prev) => prev.filter((x) => x !== id));
    } else {
      player.setCheckedTeams((prev) => (prev.length >= 4 ? prev : [...prev, id]));
    }
  };

  const columns = [
    {
      name: (
        <Typography className="roboto-regular ">
          {t(`competition.label.eventType.${competition.eventType}`)}
        </Typography>
      ),
      sortable: true,
      cell: (row) => <Typography>{row.user.team.name}</Typography>,
    },
    {
      name: <Typography className="roboto-regular">{t('workout.judge.heat')}</Typography>,
      selector: 'heat',
      sortable: true,
      center: true,
      cell: (row) => <Typography style={{ color: fontColor }}>{row.heat}</Typography>,
    },
    {
      name: <Typography className="roboto-regular">{t('workout.judge.place')}</Typography>,
      selector: 'position',
      sortable: true,
      center: true,
      cell: (row) => <Typography style={{ color: fontColor }}>{row.position}</Typography>,
    },
    {
      name: <Typography className="roboto-regular">{t('workout.judge.points')}</Typography>,
      selector: 'points',
      sortable: true,
      center: true,
      cell: (row) => <Typography style={{ color: fontColor }}>{row.points}</Typography>,
    },
    {
      name: <Typography className="roboto-regular">{t('workout.judge.score')}</Typography>,
      selector: 'points',
      sortable: true,
      center: true,
      cell: (row) => (
        <Box>
          <WorkoutScoresPoints scores={row.revisedScores || row.scores} fontColor={fontColor} />
        </Box>
      ),
    },
    {
      name: <Typography className="roboto-regular">{t('workout.judge.verified')}</Typography>,
      selector: 'verified',
      sortable: true,
      center: true,
      cell: (row) => <VerifiedUser style={{ color: row.verified ? successColor : secondary }} />,
    },
    {
      sortable: true,
      center: true,
      cell: (row) => (
        <Box p={2}>
          <ButtonCompetition style={{ padding: 3, backgroundColor: buttonColor }}>
            <Checkbox
              checked={isChecked(row.user.id)}
              label={t('button.watch')}
              size={18}
              onClick={() => toggleTeam(row.user.id)}
              color="secondary"
            />
          </ButtonCompetition>
        </Box>
      ),
    },
  ];

  const filterTemas = (x) => {
    if (text) {
      if (x.user.team.name.toUpperCase().indexOf(text.toUpperCase()) > -1) {
        return true;
      }
      if (x.heat && x.heat.toUpperCase().indexOf(text.toUpperCase()) > -1) {
        return true;
      }

      return false;
    }
    return true;
  };

  const divisions = competition.hasDivisions ? competition.divisions : [];

  return (
    <Box>
      <Box
        onClick={() => setOpenTeams(!openTeams)}
        display="flex"
        className="hover"
        mb={5}
        flexWrap="wrap"
        alignItems="center"
      >
        <Box display="flex">
          <Typography variant="h5" component="h4">
            {t(`competition.tab.${competition.eventType}s`).toUpperCase()}
          </Typography>
        </Box>
        {openTeams !== true ? (
          <KeyboardArrowDown style={styleIcon} />
        ) : (
          <KeyboardArrowRight style={styleIcon} />
        )}
      </Box>

      <Collapse in={openTeams}>
        <Box>
          <Box mb={3} display="flex">
            {divisions && divisions.length > 0 && (
              <Box mr={3}>
                <Box style={dropdownWrapper}>
                  <SelectBoxObject
                    options={divisions}
                    value={currentDivision}
                    setValue={(v) => setCurrentDivision(v)}
                    elevation={0}
                  />
                </Box>
              </Box>
            )}
            <Box flexGrow={1}>
              <BoxSearch text={text} setText={setText} title={t('workout.search.teamsHeat')} />
            </Box>
          </Box>

          <Paper className="paper-rounded hover" p={3}>
            <SFDataTable
              columns={columns}
              showPaper={false}
              paginationPerPage={5}
              noDataComponent={
                divisions && divisions.length > 0
                  ? t('workout.watch.teams.notFoundDivision')
                  : t('workout.watch.teams.notFound')
              }
              data={
                leaderboard &&
                leaderboard
                  .filter((x) => !!x.scores)
                  .filter(
                    (x) =>
                      workout.recordingVideos &&
                      !!workout.recordingVideos.find((r) => r.user.id === x.user.id),
                  )
                  .filter((x) => filterTemas(x))
              }
              showExport={false}
              pointerOnHover
              striped
              noHeader
              pagination
            />
          </Paper>
        </Box>
      </Collapse>
    </Box>
  );
};
