import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import Box from 'core/ui/Box';

import Texture from 'core/ui/Texture';
import Header from 'components/Profile/Edit/Header';
import { Add, KeyboardArrowDown, KeyboardArrowRight, Payment } from '@material-ui/icons';
import ManagePaymentMethods from 'components/Account/ManageList';

import FormAthlete from 'components/Profile/Edit/Athlete/Form';
import { ButtonPrimary } from 'core/ui/Button';
import LoaderSm from 'core/ui/LoaderSm';
import { channelMembersActions } from 'modules/channelMembers';
import { useDispatch, useSelector } from 'react-redux';
import { channelListsSelectors } from 'modules/channelLists';
import { useForm } from 'react-hook-form-v5';
import { useTranslation } from 'react-i18next';
import notifications from 'modules/notifications';
import Typography from 'core/ui/Typography';
import ModalAddPaymentMethod from 'components/Profile/Dashboard/Account/ModalAddPaymentMethod';
import Paper from 'core/ui/Paper';
import { secondary } from 'core/ui/Colors';
import { Collapse } from '@material-ui/core';
import { channelsSelectors } from 'modules/channels';

const ModalEditUser = ({ user, channelId, open, close }) => {
  const { t } = useTranslation();
  const { register, handleSubmit, errors, setValue, watch } = useForm();
  const dispatch = useDispatch();
  const loading = useSelector((state) => channelListsSelectors.getLoadingMember(state));
  const [openModalAddPaymentMethod, setOpenModalAddPaymentMethod] = useState(false);
  const [openPaymentMethods, setOpenPaymentMethods] = useState(true);
  const [image, setImage] = useState('');
  const channel = useSelector((state) => channelsSelectors.getChannel(state));

  useEffect(() => {
    setImage({
      ...user.image,
      url: user.image ? user.image.image.url : '',
      edit: false,
    });
  }, [user]);

  useEffect(() => {
    register({ name: 'interests' });
    register({ name: 'certifications' });
    register({ name: 'name' }, { required: true });
    register({ name: 'localGym' }, { required: false });
    register({ name: 'instagram' }, { required: false });
    register({ name: 'referredBy' }, { required: false });
    register({ name: 'gender' }, { required: false });
    register({ name: 'birthday' }, { required: false });

    register({ name: 'phone' }, { required: false });

    register({ name: 'emergencyName' });
    register({ name: 'emergencyEmail' });
    register({ name: 'emergencyPhone' });

    register({ name: 'defaultWeightImperial' });
    register({ name: 'defaultDistanceMiles' });

    register(
      { name: 'countryId' },
      {
        required: true,
        validate: (value) => Number(value) > 0,
      },
    );
  }, [register]);

  useEffect(() => {
    if (user) {
      setTimeout(() => {
        Object.keys(user).forEach((x) => {
          setValue(x, user[x]);
        });
      }, 100);
    }
  }, [user, setValue]);

  const onSubmit = (data) => {
    const formData = data;

    let instagram = '';
    if (formData.instagram && formData.instagram.indexOf('@') === -1) {
      instagram = `@${formData.instagram}`;
    } else if (formData.instagram) {
      instagram = formData.instagram;
    }
    if (!formData?.encryptedPin) {
      delete formData.encryptedPin;
    }

    dispatch(
      channelMembersActions.putUpdateMemberStart(
        {
          channelId,
          userId: user.id,
          ...formData,
          instagram,
          // eslint-disable-next-line no-nested-ternary
          photo: image ? (image.new === true ? image : null) : null,
        },
        () => {
          dispatch(notifications.success(t('channel.member.changed.success')));
          dispatch(channelMembersActions.getChannelMemberStart(channelId, user.id));
        },
      ),
    );
  };

  return (
    <Dialog open={open} onClose={close} maxWidth="md" fullWidth>
      <Texture>
        {channel?.id && (
          <ModalAddPaymentMethod
            open={openModalAddPaymentMethod}
            onClose={() => setOpenModalAddPaymentMethod(false)}
            setView={() => {}}
            userId={user.id}
            userInfo={{
              name: user?.name,
              email: user?.email,
              phone: user?.phone,
            }}
            cardpointe={channel?.useCardpointe}
          />
        )}

        <Typography align="center" variant="h4" mb={2} mt={2}>
          {t('modal.edit.member.title').replaceAll('#{user}', watch('name'))}
        </Typography>
        <Box px={5} flexGrow={1}>
          <Header
            errors={errors}
            loading={loading}
            setValue={setValue}
            setIsPhoneValid={() => {}}
            register={register}
            watch={watch}
            channelId={channelId}
            image={image}
            setImage={setImage}
            user={user}
            memberEdit
          />
          <Box mt={3}>
            <FormAthlete
              errors={errors}
              loading={loading}
              setValue={setValue}
              setIsPhoneValid={() => {}}
              register={register}
              watch={watch}
              memberEdit
              profile={user}
            />
          </Box>

          {!user.child && (
            <Paper p={5} my={3}>
              <Box mb={2} flexGrow="1" display="flex" alignItems="center">
                <Box
                  display="flex"
                  alignItems="center"
                  onClick={() => setOpenPaymentMethods(!openPaymentMethods)}
                >
                  {openPaymentMethods ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
                </Box>
                <Box
                  flexGrow="1"
                  display="flex"
                  alignItems="center"
                  onClick={() => setOpenPaymentMethods(!openPaymentMethods)}
                >
                  <Payment style={{ color: secondary, fontSize: 20 }} />
                  <Typography ml={1} color="secondary" className="link" variant="body2">
                    {t('user.paymentMethods').toUpperCase()}
                  </Typography>
                </Box>
                <Box
                  className="link-hover"
                  display="flex"
                  alignItems="center"
                  onClick={() => setOpenModalAddPaymentMethod(true)}
                >
                  <Add style={{ fontSize: 20 }} />
                </Box>
              </Box>
              <Collapse in={openPaymentMethods}>
                <ManagePaymentMethods userId={user.id} channelId={channelId} />
              </Collapse>
            </Paper>
          )}

          <Box mb={5} display="flex" justifyContent="flex-end">
            <ButtonPrimary
              type="button"
              onClick={() => handleSubmit(onSubmit)()}
              size="small"
              disabled={loading}
              style={{ height: 30, width: 80, borderRadius: 5 }}
            >
              <LoaderSm loading={loading} width={20} />
              {t('button.save')}
            </ButtonPrimary>
          </Box>
        </Box>
      </Texture>
    </Dialog>
  );
};

ModalEditUser.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.object,
  channelId: PropTypes.number.isRequired,
  open: PropTypes.bool,
  close: PropTypes.func,
};

ModalEditUser.defaultProps = {
  user: {},
  open: false,
  close: () => {},
};

export default ModalEditUser;
