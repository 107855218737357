import useConfirmation from 'core/useConfirmation';
import Modules from 'modules';
import useActions from 'modules/map/useActions';
import notifications from 'modules/notifications';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form-v5';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import uuid from 'react-uuid';

const useWorkflowForm = ({ channelId, workflowId, callback, isTemplate }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [workflowData, setWorkflowData] = useState({ status: 'draft' });
  const { request } = useActions();
  const [workflowTypes, setWorkflowTypes] = useState([]);
  const [modalAddUsers, setModalAddUsers] = useState(false);
  const { register, handleSubmit, setValue, getValues, watch, reset, errors } = useForm();
  const [workflow, setWorkflow] = useState(null);
  const [currentType, setCurrentType] = useState({ open: !workflowId, type: 'trigger' });
  const [executionFilters, setExecutionFilters] = useState({ date: 'day_30' });

  const { confirmation } = useConfirmation();

  const triggerKeywords =
    (workflowTypes && (workflowTypes[0]?.automationType?.keywords || [])) || [];

  const refreshWorkflow = useCallback(() => {
    request({
      action: Modules.automationWorkflows.actions.getWorkflow,
      data: {
        ownerableId: isTemplate ? 2 : channelId,
        ownerableType: 'Channel',
        id: workflowId,
      },
      options: {
        onSuccess: setWorkflow,
      },
    });
  }, [request, workflowId, channelId, isTemplate]);

  useEffect(() => {
    // AutomationWorkflowType
    if (workflowId) {
      refreshWorkflow(workflowId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelId, workflowId, refreshWorkflow]);

  useEffect(() => {
    // AutomationWorkflowType
    register('name', { required: true });
    register('filters', { required: false });
    register('form', { required: false });
    register('denials', { required: false });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register]);

  useEffect(() => {
    if (workflow?.id) {
      setWorkflowData({
        name: workflow?.name,
        status: workflow?.status,
        template: workflow?.template,
      });
      setWorkflowTypes(workflow?.automationWorkflowsTypes || []);
    }
  }, [workflow]);

  const resetFormType = () => {
    setValue('name', '');
    setValue('filters', null);
    setValue('form', null);
    setValue('denials', null);
  };

  function insert(array, val, ind) {
    return ind >= array.length
      ? array.concat(val)
      : array.reduce(
          (accumulator, currentValue, i) =>
            accumulator.concat(i === ind ? [val, currentValue] : currentValue),
          [],
        );
  }

  const submitType = (data) => {
    setWorkflowTypes((prev) => {
      const sortedItems = () =>
        insert(
          prev,
          {
            uuid: uuid(),
            ...data,
            filters: { data: data?.filters },
            automationTypeId: currentType?.automationType?.id,
            automationType: currentType?.automationType,
          },
          currentType?.index >= 0 ? (currentType?.index || 0) + 1 : prev.length,
        ).map((x, idx) => ({ ...x, execOrder: idx }));

      const items =
        !!currentType?.workflowType?.uuid || !!currentType?.workflowType?.id
          ? prev.map((item) => {
              if (
                currentType?.workflowType?.uuid
                  ? currentType?.workflowType?.uuid === item.uuid
                  : currentType?.workflowType.id === item.id
              ) {
                return {
                  ...item,
                  ...data,
                  filters: { data: data?.filters },
                  automationTypeId: currentType?.automationType?.id,
                  automationType: currentType?.automationType,
                };
              }

              return item;
            })
          : sortedItems();

      return items;
    });

    if (!workflowData?.name) {
      setWorkflowData((prev) => ({ ...prev, name: data?.name }));
    }

    resetFormType();
    setCurrentType((prev) => ({ ...prev, open: false }));
  };

  const onChangeFiltersValues = (index, data) => {
    const filters = getValues('filters') || [];
    const newFilters = filters.map((item, idx) => {
      if (index === idx) {
        return { ...item, ...data };
      }

      return item;
    });
    setValue('filters', newFilters);
  };

  const onChangeFormValues = (data) => {
    const form = getValues('form') || {};
    setValue('form', { ...form, ...data });
  };

  const removeFilter = (item) => {
    const filters = getValues('filters') || [];
    setValue(
      'filters',
      filters.filter((x) => x !== item),
    );
  };

  const saveWorkflow = () => {
    const data = {
      ...workflowData,
      automationWorkflowsTypesAttributes: workflowTypes?.map(({ denials, ...item }, idx) => ({
        ...item,
        denials_attributes: denials,
        execOrder: idx,
      })),
    };

    request({
      action: workflow?.id
        ? Modules.automationWorkflows.actions.putWorkflow
        : Modules.automationWorkflows.actions.postWorkflow,
      data: {
        ...data,
        ownerableId: channelId,
        ownerableType: 'Channel',
        id: workflow?.id,
      },
      options: {
        onSuccess: () => {
          dispatch(notifications.success(t('workflow.message.saved')));
          callback();
        },
        onError: (err) => {
          dispatch(notifications.error(err));
        },
      },
    });
  };

  const editWorkflowType = (workflowType) => {
    setValue('name', workflowType?.name);
    setValue('filters', workflowType.filters?.data);
    setValue('form', workflowType?.form);
    setValue('denials', workflowType?.denials);

    setCurrentType({
      open: true,
      automationType: workflowType?.automationType,
      type: workflowType?.automationType?.kind,
      workflowType,
    });
  };

  const deleteWorkflowType = (workflowType) => {
    confirmation({
      description: t('areYouSure'),
      yesClick: () => {
        if (workflowType?.id) {
          const setDeleteItem = (item) => {
            if (workflowType?.id && workflowType?.id === item?.id) {
              return { ...item, _destroy: true };
            }
            return item;
          };
          setWorkflowTypes((prev) => prev.map((x) => setDeleteItem(x)));
        } else {
          setWorkflowTypes((prev) => prev.filter((x) => x.uuid !== workflowType?.uuid));
        }

        setCurrentType((prev) => ({ ...prev, open: false }));
        resetFormType();
      },
    });
  };

  useEffect(() => {
    if (!currentType?.open) {
      resetFormType();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentType?.open]);

  const addWorkflowType = (type, index) => {
    resetFormType();
    setCurrentType({ open: true, type, index });
  };

  const workflowTypesWithoutDestroyed = useMemo(
    // eslint-disable-next-line no-underscore-dangle
    () => workflowTypes.filter((wt) => !wt._destroy),
    [workflowTypes],
  );

  return {
    workflowTypes,
    workflowTypesWithoutDestroyed,
    setWorkflowTypes,
    formType: {
      submit: handleSubmit(submitType),
      getValues,
      setValue,
      watch,
      reset,
      errors,
    },
    onChangeFiltersValues,
    removeFilter,
    currentType,
    setCurrentType,
    workflowData,
    setWorkflowData,
    onChangeFormValues,
    saveWorkflow,
    editWorkflowType,
    deleteWorkflowType,
    executionFilters,
    setExecutionFilters,
    workflow,
    channelId,
    resetFormType,
    refreshWorkflow,
    addWorkflowType,
    triggerKeywords,
    modalAddUsers,
    setModalAddUsers,
  };
};

export default useWorkflowForm;
