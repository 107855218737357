import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import { ButtonPrimary } from 'core/ui/Button';
import Paper from 'core/ui/Paper';
import Typography, { fontStyle } from 'core/ui/Typography';
import { FormControlLabel, Radio } from '@material-ui/core';
import useFormTaxes from './form';

const FormTax = ({ itemEdit, callback, mode, channel }) => {
  const { t } = useTranslation();
  const { setValue, watch, handleSubmit, onSubmit, errors, loading } = useFormTaxes({
    mode,
    itemEdit,
    callback,
  });

  return (
    <>
      <Paper p={3}>
        <Box mb={3}>
          <TextFieldRounded
            value={watch('name') || ''}
            onChange={(v) => setValue('name', v)}
            placeholder={t('form.locations.label.name')}
            error={errors.name}
            label={t('form.locations.label.name').toUpperCase()}
            required
            bordered
          />
        </Box>
        <Box ml={2}>
          <Typography style={fontStyle.label} variant="body1">
            {t('channel.membership.label.type').toUpperCase()}
          </Typography>
          <Box display="flex">
            <FormControlLabel
              onClick={() => setValue('amountType', 'percentage')}
              control={
                <Radio
                  color="primary"
                  size="small"
                  checked={watch('amountType') === 'percentage'}
                />
              }
              label="%"
            />
            <FormControlLabel
              onClick={() => setValue('amountType', 'money')}
              control={
                <Radio color="primary" size="small" checked={watch('amountType') === 'money'} />
              }
              label={channel?.currency?.symbol}
            />
          </Box>
        </Box>
        <Box mb={3}>
          <TextFieldRounded
            type="number"
            pattern="[0-9]*"
            value={watch('amount') || ''}
            onChange={(v) => setValue('amount', v)}
            placeholder="0.00"
            label={t('label.amount').toUpperCase()}
            required
            currency
            currencySymbol={
              watch('amountType') === 'money' ? channel?.currency?.symbol || '$' : '%'
            }
            bordered
          />
        </Box>
      </Paper>

      <Box mt={3} display="flex" justifyContent="flex-end">
        <ButtonPrimary disabled={loading} onClick={handleSubmit(onSubmit)}>
          {t('button.save')}
        </ButtonPrimary>
      </Box>
    </>
  );
};

FormTax.defaultProps = {
  callback: () => {},
  itemEdit: null,
  mode: 'new',
};

FormTax.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  callback: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  itemEdit: PropTypes.object,
  mode: PropTypes.string,
};

export default FormTax;
