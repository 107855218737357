import React, { useRef, useState } from 'react';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import { ButtonPrimary } from 'core/ui/Button';
import { channelsSelectors } from 'modules/channels';
import { useDispatch, useSelector } from 'react-redux';
import Schedules from 'components/Channel/Show/Tabs/Schedules/index';
import RecurringManage from 'components/Channel/Show/Tabs/Schedules/RecurringManage';
import {
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Popper,
} from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import Paper from 'core/ui/Paper';
import { recurringsActions } from 'modules/recurrings';
import Toggle from 'core/ui/Toggle';
import { primary } from 'core/ui/Colors';
import ModalClass from './ModalClass';
import ModalClone from './Clone/ModalClone';
import PartnersPrograms from './PartnersPrograms';
import ModalEventsForm from '../Events/EventsForm/Modal';

const ChannelSchedule = () => {
  const [modalClass, setModalClass] = useState({ open: false });
  const [modalClone, setModalClone] = useState({ open: false });
  const [modalEvent, setModalEvent] = useState({ open: false });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [refreshCalendar, setRefreshCalendar] = useState(false);
  const anchorRef = useRef();
  const channel = useSelector((state) => channelsSelectors.getChannel(state));
  const [isCoachView, setIsCoachView] = useState(false);

  const [modal, setModal] = useState({
    open: false,
  });

  const buttonColor = channel?.customColors?.buttonColor;
  const [calendarInfo, setCalendarInfo] = useState({ current: true });

  const callback = () => {
    setModalClass({ open: false });
    dispatch(recurringsActions.getRecurringsByChannelStart(channel.id));
    setRefreshCalendar(true);
  };
  return (
    <Box p={3}>
      <ModalClass
        channelId={channel.id}
        modalClass={modalClass}
        close={() => setModalClass({ open: false })}
        callback={callback}
      />
      <ModalClone
        calendarInfo={calendarInfo}
        close={() => setModalClone({ open: false })}
        modalClone={modalClone}
        channel={channel}
      />
      <ModalEventsForm
        channel={channel}
        eventId={modalEvent?.eventId}
        open={modalEvent?.open}
        close={() => setModalEvent({})}
        callbackSave={() => setRefreshCalendar(true)}
      />

      <PartnersPrograms
        refreshSchedule={() => setRefreshCalendar(true)}
        calendarInfo={calendarInfo}
        buttonColor={buttonColor}
        modal={modal}
        setModal={setModal}
      />

      <Box>
        <RecurringManage setRefreshCalendar={setRefreshCalendar} />
      </Box>

      <Box display="flex" mt={3} alignItems="center" flexWrap="wrap">
        <Box pb={3} mr={1}>
          <Toggle
            options={[
              { value: false, label: 'Schedule' },
              { value: true, label: 'Coach' },
            ]}
            onChange={(v) => setIsCoachView(v)}
            value={isCoachView}
            activeColor={buttonColor || primary}
            color="white"
          />
        </Box>
        <Box flexGrow={1} mx={5} display="flex" justifyContent="center" />
        <Box>
          <ButtonGroup
            variant="contained"
            style={{ backgroundColor: buttonColor }}
            ref={anchorRef}
            aria-label="split button"
          >
            <ButtonPrimary
              style={{ width: 120 }}
              buttonColor={buttonColor}
              onClick={() => setOpen(true)}
            >
              &nbsp;{t('button.actions')} <ArrowDropDown />
            </ButtonPrimary>
          </ButtonGroup>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            style={{ zIndex: 10 }}
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={() => setOpen(false)}>
                    <MenuList>
                      <MenuItem onClick={() => setModalClass({ open: true, type: 'customClass' })}>
                        {t('channel.class.createClass')}
                      </MenuItem>
                      <MenuItem onClick={() => setModalEvent({ open: true })}>
                        {t('channel.createEvent')}
                      </MenuItem>
                      {(!calendarInfo?.currentView ||
                        calendarInfo?.currentView === 'timeGridWeek') && (
                        <>
                          <MenuItem
                            onClick={() =>
                              setModal(() => ({
                                open: true,
                              }))
                            }
                          >
                            {t('channel.class.viewPartnerPrograms')}
                          </MenuItem>
                          <MenuItem
                            onClick={() => setModalClone((prev) => ({ ...prev, open: true }))}
                          >
                            {t('button.cloneWeek')}
                          </MenuItem>
                        </>
                      )}
                      <MenuItem
                        onClick={() => setModalClass({ open: true, type: 'classFromTemplate' })}
                      >
                        {t('channel.class.classFromTemplate')}
                      </MenuItem>
                      {/*
                      <MenuItem
                        onClick={() => setModalClass({ open: true, type: 'classFromHistory' })}
                      >
                        {t('channel.class.classFromHistory')}
                      </MenuItem>
                      <MenuItem
                        onClick={() => setModalClass({ open: true, type: 'classFromBenchmark' })}
                      >
                        {t('channel.class.classFromBenchmark')}
                      </MenuItem> */}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Box>
      </Box>
      <Box>
        <Schedules
          channel={channel}
          isCoach
          showQuickAdd={false}
          setCalendarInfo={setCalendarInfo}
          setModalEventForm={setModalEvent}
          refreshCalendar={refreshCalendar}
          setRefreshCalendar={setRefreshCalendar}
          isCoachView={isCoachView}
        />
      </Box>
    </Box>
  );
};

export default ChannelSchedule;
