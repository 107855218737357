import React from 'react';
import { Dialog } from '@material-ui/core';
import Content from './Content';

const ModalMediaFilesGallery = ({
  open,
  onSelected,
  close,
  ownerableType,
  ownerableId,

  associableType,
  associableId,
  section,
  selectedFiles,
}) => (
  <Dialog
    PaperProps={{ style: { borderRadius: 5 } }}
    maxWidth="lg"
    fullWidth
    scroll="body"
    onClose={close}
    open={open}
  >
    {open && (
      <Content
        onSelected={onSelected}
        ownerableType={ownerableType}
        ownerableId={ownerableId}
        associableType={associableType}
        associableId={associableId}
        section={section}
        selectedFiles={selectedFiles || []}
      />
    )}
  </Dialog>
);

export default ModalMediaFilesGallery;
