import { all, call, put } from 'redux-saga/effects';

import { getErrorMessage } from 'core/api/api-error';

import notifications from 'modules/notifications';

import * as api from './api';
import { workoutHeatsActions as actions } from './index';

export function* getWorkoutHeats({ workoutId }) {
  try {
    const response = yield call(api.getWorkoutHeats, workoutId);
    const workoutHeats = response.data;
    yield put(actions.getWorkoutHeatsSuccess(workoutHeats));
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.getWorkoutHeatsError(getErrorMessage(error))),
    ]);
  }
}

export function* resetWorkoutHeats({ workoutId, callback }) {
  try {
    const response = yield call(api.resetWorkoutHeats, workoutId);
    const workoutHeats = response.data;
    yield put(actions.resetWorkoutHeatsSuccess(workoutHeats));
    if (callback) {
      callback();
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.resetWorkoutHeatsError(getErrorMessage(error))),
    ]);
  }
}

export function* postWorkoutHeat({ data, callback }) {
  try {
    const response = yield call(api.postWorkoutHeat, data);
    const workoutHeats = response.data;
    yield put(actions.postWorkoutHeatSuccess(workoutHeats));
    if (callback) {
      callback();
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.postWorkoutHeatError(getErrorMessage(error))),
    ]);
  }
}

export function* saveWorkoutHeatsUsers({ data, callback }) {
  try {
    yield call(api.saveWorkoutHeatsUsers, data);
    yield put(actions.saveWorkoutHeatsUsersSuccess());
    if (callback) {
      callback();
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.saveWorkoutHeatsUsersError(getErrorMessage(error))),
    ]);
  }
}
