import React from 'react';
import propTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import Box from 'core/ui/Box';
import Form from 'components/Workout/Form';
import { useSelector } from 'react-redux';
import { channelsSelectors } from 'modules/channels';
import { useTranslation } from 'react-i18next';
import Typography from 'core/ui/Typography';

const ModalEditRecurring = ({ open, close, recurring, callback }) => {
  const channel = useSelector((state) => channelsSelectors.getChannel(state));
  const { t } = useTranslation();

  const callbackSave = () => {
    close();
    if (callback) {
      callback();
    }
  };

  return (
    <Dialog
      PaperProps={{ style: { borderRadius: 5 } }}
      maxWidth="lg"
      scroll="body"
      onClose={() => close()}
      open={open}
      fullWidth
    >
      <Box p={3}>
        <Typography mb={5} variant="h2" align="center" color="primary">
          {t('workout.form.editRecurring')}
        </Typography>
        <Form
          callbackSave={callbackSave}
          channelId={channel.id}
          workoutRecurringId={recurring.workoutId}
          mode="edit"
        />
      </Box>
    </Dialog>
  );
};

ModalEditRecurring.propTypes = {
  open: propTypes.bool,
  close: propTypes.func,
  callback: propTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  recurring: propTypes.object,
};

ModalEditRecurring.defaultProps = {
  open: false,
  close: null,
  callback: null,
  recurring: {},
};

export default ModalEditRecurring;
