/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Paper from 'core/ui/Paper';
import { useTranslation } from 'react-i18next';
import Typography, { fontStyle } from 'core/ui/Typography';
import SFDataTable from 'core/ui/DataTable';
import Box from 'core/ui/Box';
import { currency, date, timeWithoutTz } from 'core/utils/formatters';
import Toggle from 'core/ui/Toggle';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import LoaderSm from 'core/ui/LoaderSm';
import useSelectors from 'modules/map/useSelectors';
import { channelsPayoutsModule } from 'modules/channelsPayouts';
import { ButtonPrimarySm } from 'core/ui/Button';
import { Dialog } from '@material-ui/core';
import ResultPayments from '../Payments/ResultPayments';

export default function ResultPayouts({ rows, resume, channel, getReport }) {
  const { t } = useTranslation();
  const { request } = useActions();
  const [payoutInfo, setPayoutInfo] = useState(null);
  const loading = useSelectors(channelsPayoutsModule, 'loading');

  const changePayout = (payoutRow) => {
    request({
      action: Modules.channelsPayouts.actions.update,
      data: {
        channelId: channel?.id,
        id: payoutRow?.id,
        reconciled: !payoutRow?.reconciled,
      },
      options: {
        onSuccess: () => {
          getReport();
        },
      },
    });
  };

  const showPayout = (payoutRow) => {
    request({
      action: Modules.channelsPayouts.actions.show,
      data: {
        channelId: channel?.id,
        id: payoutRow?.id,
      },
      options: {
        onSuccess: (data) => {
          setPayoutInfo(data);
        },
      },
    });
  };

  const columns = [
    {
      name: 'Description',
      selector: 'description',
      sortable: true,
      cell: (row) => (
        <Typography style={fontStyle.medium} color="secondary">
          {row.description}
        </Typography>
      ),
    },
    {
      name: 'Date',
      selector: 'payoutAt',
      cell: (row) => (
        <Typography>
          {date(row?.payoutAt, channel?.dateFormatReact)}&nbsp;&bull;&nbsp;
          {timeWithoutTz(row.payoutAt)}
        </Typography>
      ),
      sortable: true,
      center: true,
    },
    {
      name: 'Status',
      selector: 'status',
      left: true,
      sortable: true,
      cell: (row) => (
        <Box display="flex" flexDirection="column">
          <Typography>{row?.status?.toUpperCase()}</Typography>
        </Box>
      ),
    },
    {
      name: 'Transactions',
      right: true,
      cell: (row) => <Typography>{row?.resume?.count}</Typography>,
    },
    {
      name: 'Gross',
      right: true,
      cell: (row) => <Typography>{currency(row?.resume?.grossTotal)}</Typography>,
    },

    {
      name: 'Non-StreamFit',
      right: true,
      cell: (row) => <Typography>{currency(row?.resume?.othersTotal)}</Typography>,
    },

    {
      name: 'Fees',
      right: true,
      cell: (row) => (
        <Typography className="tw-text-red-500">
          {currency(row?.resume?.gatewayFeeAmount)}
        </Typography>
      ),
    },
    {
      name: 'Refunds',
      right: true,
      cell: (row) => (
        <Typography className="tw-text-red-500">{currency(row?.resume?.refundedTotal)}</Typography>
      ),
    },
    {
      name: 'Partner',
      right: true,
      cell: (row) => (
        <Typography className="tw-text-red-500">{currency(row?.resume?.partnerTotal)}</Typography>
      ),
    },

    {
      name: 'Net',
      selector: 'amount',
      right: true,
      sortable: true,
      cell: (row) => <Typography>{currency(row?.amount)}</Typography>,
    },
    {
      name: 'Reconciled',
      selector: 'reconciled',
      right: true,
      sortable: true,
      cell: (row) => (
        <Toggle
          options={[
            { value: false, width: 50, label: 'No' },
            { value: true, width: 50, label: 'Yes' },
          ]}
          value={row?.reconciled}
          color="white"
          onChange={() => {
            changePayout(row);
          }}
        />
      ),
    },
    {
      name: 'Payments',
      selector: '',
      center: true,
      sortable: false,
      cell: (row) => (
        <ButtonPrimarySm
          onClick={() => {
            setPayoutInfo({ loading: true });
            showPayout(row);
          }}
        >
          {t('label.payments')}
        </ButtonPrimarySm>
      ),
    },
  ];

  return (
    <Paper style={{ width: '100%' }}>
      <Dialog
        open={payoutInfo?.id || payoutInfo?.loading}
        maxWidth="xl"
        fullWidth
        onClose={() => setPayoutInfo(null)}
      >
        <Box p={5} textAlign="center" mb={10}>
          <Typography component="h1" align="center" m={5}>
            {t('label.payments')}
          </Typography>

          <LoaderSm loading={loading} />
          <ResultPayments
            noHeader
            channel={channel}
            hideActions
            pagination={false}
            rows={payoutInfo?.payments || []}
          />
        </Box>
      </Dialog>

      <SFDataTable
        title={t('channel.reports.payouts')}
        columns={columns}
        data={rows}
        pagination
        showExport
      />
    </Paper>
  );
}
