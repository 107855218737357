/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Box from 'core/ui/Box';
import { ButtonPrimary } from 'core/ui/Button';
import LoaderSm from 'core/ui/LoaderSm';
import { useForm } from 'react-hook-form-v5';
import { useTranslation } from 'react-i18next';
import useSelectors from 'modules/map/useSelectors';
import { userChildrenModule } from 'modules/userChildren';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import { makeStyles } from '@material-ui/core';
import Image from 'components/Profile/Edit/Image';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import InputPhone from 'core/ui/InputPhone';
import ModalAddPaymentMethod from 'components/Profile/Dashboard/Account/ModalAddPaymentMethod';
import DatePickerRounded from 'core/ui/DatePickers/DatePickerRounded';
import { formatDateApi, formatDateToApi } from 'core/utils/formatters';
import notifications from 'modules/notifications';
import { useDispatch } from 'react-redux';
import EmergencyContactFields from 'components/Profile/Edit/EmergencyContactFields';

const useStyles = makeStyles(() => ({
  avatar: {
    height: 170,
    width: 170,
    borderRadius: 10,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    display: 'block',
    backgroundColor: '#bbbdbf',
  },
}));

const FormMembers = ({ user, close, child, callback }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const loading = useSelectors(userChildrenModule, 'loading');
  const { request } = useActions();
  const { register, handleSubmit, errors, setValue, watch, reset } = useForm();
  const [image, setImage] = useState({});

  const [openModalAddPaymentMethod, setOpenModalAddPaymentMethod] = useState(false);

  useEffect(() => {
    if (child) {
      setImage({
        ...child.image,
        url: child.image ? child.image.image.url : '',
        edit: false,
      });

      setTimeout(() => {
        reset({ ...child, birthday: formatDateApi(child.birthday, 'YYYY-MM-DD HH:mm:ss Z') });
      }, 300);
    } else if (user) {
      setTimeout(() => {
        reset({
          emergencyName: user?.emergencyName,
          emergencyEmail: user?.emergencyEmail,
          emergencyPhone: user?.emergencyPhone,
        });
      }, 300);
    }
  }, [child, reset, user]);

  useEffect(() => {
    register({ name: 'name' }, { required: true });
    register({ name: 'birthday' }, { required: true });

    if (child && child.convert) {
      register({ name: 'email' }, { required: true });
      register({ name: 'phone' }, { required: true });
    }

    register({ name: 'emergencyName' }, { required: true });
    register({ name: 'emergencyEmail' }, { required: true });
    register({ name: 'emergencyPhone' }, { required: true });
  }, [register, child]);

  const onSubmit = (data) => {
    const userData = {
      ...data,
      birthday: formatDateToApi(data.birthday),
      childId: child && child.id,
      photo: image ? (image.new === true ? image : null) : null,
    };

    request({
      action:
        child && child.id
          ? Modules.userChildren.actions.putChild
          : Modules.userChildren.actions.postChild,
      data: { userId: user?.id, ...userData },
      options: {
        onSuccess: () => {
          request({ action: Modules.userChildren.actions.getChildren, data: { userId: user?.id } });
          dispatch(notifications.success('Child has been saved.'));
          if (callback) {
            callback();
          }
          close();
        },
        onError: (err) => {
          dispatch(notifications.error(err));
        },
      },
    });
  };

  return (
    <Box>
      {child && child.convert && (
        <ModalAddPaymentMethod
          open={openModalAddPaymentMethod}
          setOpen={setOpenModalAddPaymentMethod}
          onClose={() => setOpenModalAddPaymentMethod(false)}
          userId={child.id}
          userInfo={{
            name: child?.name,
            email: child?.email,
            phone: child?.phone,
          }}
          cardpointe={child?.gymConfig?.useCardpointe}
        />
      )}
      <Box>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Box className={classes.avatar}>
            <Image image={image} setImage={setImage} />
          </Box>
        </Box>

        <Box mt={5}>
          <TextFieldRounded
            placeholder={t('profile.placeholder.childName')}
            label={t('profile.label.name').toUpperCase()}
            onChange={(v) => setValue('name', v)}
            value={watch('name') || ''}
            error={errors.name}
          />
        </Box>

        {child && child.convert && (
          <>
            <Box mt={3}>
              <InputPhone
                name="phone"
                error={errors.phone}
                label={t('profile.label.mobilePhone').toUpperCase()}
                setValue={(data) => {
                  setValue('phone', data.number);
                }}
                value={watch('phone') || ''}
                required
              />
            </Box>
            <Box mt={3}>
              <TextFieldRounded
                name="email"
                error={errors.email}
                placeholder={t('profile.placeholder.email')}
                label={t('profile.label.email').toUpperCase()}
                inputRef={register({ required: true })}
                required
              />
            </Box>
          </>
        )}

        <Box mt={3} display="flex" style={{ maxWidth: 200 }} alignItems="center">
          <DatePickerRounded
            label={t('profile.birthday')}
            placeholder={t('profile.birthday')}
            stylePaper={{ width: 160 }}
            value={watch('birthday') || ''}
            error={errors.birthday}
            onChange={(v) => setValue('birthday', v)}
            disablePast={false}
          />
        </Box>

        <EmergencyContactFields required setValue={setValue} watch={watch} errors={errors} />

        <Box mt={3} display="flex" justifyContent="flex-end">
          <ButtonPrimary
            type="button"
            onClick={() => handleSubmit(onSubmit)()}
            size="small"
            disabled={loading}
            style={{ height: 30, width: 80, borderRadius: 5 }}
          >
            <LoaderSm loading={loading} width={20} />
            {t('button.save')}
          </ButtonPrimary>
        </Box>
      </Box>
    </Box>
  );
};

FormMembers.propTypes = {
  close: PropTypes.func.isRequired,
};

FormMembers.defaultProps = {};

export default FormMembers;
