/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Box from 'core/ui/Box';
import Paper from 'core/ui/Paper';
import Typography from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import { channelCurrency, date } from 'core/utils/formatters';
import { useSelector } from 'react-redux';
import useSelectors from 'modules/map/useSelectors';
import { newChannelMembersModule } from 'modules/channelMembers/new';
import { sessionSelectors } from 'modules/session';
import LoaderSm from 'core/ui/LoaderSm';
import { ButtonPrimary } from 'core/ui/Button';
import ModalSwitchMembership from 'components/Channel/Manage/Tabs/Members/Memberships/MemberInformation/MemberMemberships/ModalSwitchMembership';

const ActivePlans = ({ showPlans, channel, getMemberships }) => {
  const { t } = useTranslation();
  const currentUser = useSelector((state) => sessionSelectors.getUser(state));
  const [modalSwitch, setModalSwitch] = useState({});

  const memberships = useSelectors(newChannelMembersModule, 'memberships');
  const loadingMemberships = useSelectors(newChannelMembersModule, 'loadingMemberships');

  useEffect(() => {
    if (channel?.id && currentUser?.id) {
      getMemberships();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel?.id, currentUser?.id]);

  return (
    <Box>
      <ModalSwitchMembership
        showPlans={showPlans}
        channel={channel}
        channelKey={modalSwitch?.channelKey}
        open={modalSwitch?.open}
        close={() => setModalSwitch({})}
        callbackSave={() => {
          getMemberships();
        }}
      />
      {memberships?.activeKeys &&
        memberships?.activeKeys.map((key) => (
          <Paper className="paper-rounded" p={3} my={3} key={memberships.id}>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell colSpan={2}>
                    <Typography color="secondary" align="center" variant="subtitle1" component="h5">
                      {key.membershipPlan ? key.membershipPlan.name : ''}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography color="primary" variant="subtitle1" component="span">
                      {t('channel.membership')}:
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography ml={1} variant="subtitle2" component="span">
                      {channelCurrency(key?.nextRenewAmounts?.nextCost, channel?.currency)}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography color="primary" variant="subtitle1" component="span">
                      {t(
                        key.renewable
                          ? 'channel.memberships.renewIn'
                          : 'channel.memberships.expiryIn',
                      )}
                      :
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography ml={1} variant="subtitle2" component="span">
                      {key.expiryAt ? date(key.expiryAt, channel?.dateFormatReact) : ''}
                    </Typography>
                  </TableCell>
                </TableRow>
                {key.planExpiryAt && (
                  <TableRow>
                    <TableCell>
                      <Typography color="primary" variant="subtitle1" component="span">
                        {t('channel.memberships.planExpiresIn')}:
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography ml={1} variant="subtitle2" component="span">
                        {date(key.planExpiryAt, channel?.dateFormatReact)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
                {key.limitUse && (
                  <TableRow>
                    <TableCell>
                      <Typography color="primary" variant="subtitle1" component="span">
                        {t('channel.plan.limitUse')}:
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography ml={1} variant="subtitle2" component="span">
                        {key.limitUse}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
                {key.allowSwitchPlan && (
                  <TableRow>
                    <TableCell>
                      <Typography color="primary" variant="subtitle1" component="span">
                        {t('channel.plan.switchPlan')}:
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <ButtonPrimary
                        onClick={() => setModalSwitch({ open: true, channelKey: key })}
                      >
                        {t('button.membership.switchMembership')}
                      </ButtonPrimary>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>

            <LoaderSm loading={loadingMemberships} center />
          </Paper>
        ))}
    </Box>
  );
};

export default ActivePlans;
