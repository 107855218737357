import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
} from '@material-ui/core';
import uuid from 'react-uuid';

import useActions from 'modules/map/useActions';
import { ButtonPrimary } from 'core/ui/Button';
import Modules from 'modules';
import useSelectors from 'modules/map/useSelectors';
import { channelsUsersImportModule } from 'modules/channelsUsersImport';
import { showAPIError } from 'core/utils/showAPIError';
import Typography, { fontStyle } from 'core/ui/Typography';
import { Bucket, S3Client, makeKey } from 'core/utils/S3Client';
import { useSelector } from 'react-redux';
import { channelsSelectors } from 'modules/channels';

export const ImportFromCsvButton = ({ loadChannelsUsersImport }) => {
  const { t } = useTranslation();
  const { request } = useActions();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const channel = useSelector((state) => channelsSelectors.getChannel(state));
  const [s3Loading, setS3Loading] = useState(false);
  const [file, setFile] = useState(null);

  const handleClose = () => setIsModalOpen(false);

  const handleFileChange = (e) => {
    if (!e.target.files?.[0]) return;

    setFile(e.target.files[0]);
  };

  const handleImport = (e) => {
    e.preventDefault();

    if (!file) return;

    setS3Loading(true);

    S3Client.upload({
      Bucket,
      Key: makeKey(`ChannelsUsersImport/${uuid()}-${file.name}`),
      Body: file,
      ContentType: file.type,
    })
      .promise()
      .then((data) => {
        request({
          action: Modules.channelsUsersImport.actions.create,
          data: { channelId: channel?.id, csvUrl: data.Location },
          options: {
            onSuccess: () => {
              handleClose();
              loadChannelsUsersImport();
            },
            onError: showAPIError,
          },
        });
      })
      .finally(() => setS3Loading(false));
  };

  return (
    <>
      <ButtonPrimary onClick={() => setIsModalOpen(true)}>
        {t('channel.manage.importMembers.import')}
      </ButtonPrimary>

      <Dialog
        open={isModalOpen}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: handleImport,
        }}
      >
        <DialogTitle>{t('channel.manage.importMembers.import')}</DialogTitle>

        <DialogContent>
          <input type="file" name="csv" accept=".csv" required onChange={handleFileChange} />

          <Typography mt={1} style={fontStyle.small} align="center">
            <Link href="/example_file_users.csv">Download Template</Link>
          </Typography>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>{t('button.cancel')}</Button>
          <SubmitButton s3Loading={s3Loading} />
        </DialogActions>
      </Dialog>
    </>
  );
};

const SubmitButton = ({ s3Loading }) => {
  const { t } = useTranslation();
  const importLoading = useSelectors(channelsUsersImportModule, 'loading');

  const isLoading = s3Loading || importLoading;

  return (
    <Button type="submit" disabled={isLoading}>
      {isLoading ? <CircularProgress /> : t('button.import')}
    </Button>
  );
};
