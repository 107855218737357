import React, { useState } from 'react';
import Box from 'core/ui/Box';
import Grid from 'core/ui/Grid';
import StoreCategories from 'components/Store/Categories';
import StoreDiscountCodes from 'components/Store/DiscountCodes';
import StoreProducts from 'components/Store/Products';
import StoreOptions from 'components/Store/Options';
import { StoreContext } from 'contexts';
import PartnersMarketPlace from 'components/Partners/Store/PartnersMarketPlace';
import Typography from 'core/ui/Typography';
import { Link } from '@material-ui/core';
import { useHistory } from 'react-router';

const ChannelStore = ({ channel }) => {
  const history = useHistory();
  const [refreshProducts, setRefreshProducts] = useState(false);

  return (
    <StoreContext.Provider
      value={{
        taxByCounty: false,
        channelsTaxes: channel?.channelsTaxes,
        currency: channel?.currency,
        hasShopify: channel?.hasShopify,
        refreshProducts,
        ownerableType: 'Channel',
      }}
    >
      <Box p={5}>
        {!channel.hasStripe && !channel?.hasCardpointe && (
          <Typography variant="h3" align="center" className="hover">
            Please add a gateway{' '}
            <Link onClick={() => history.push(`/channel/manage/${channel.id}/integrations`)}>
              integration
            </Link>
          </Typography>
        )}
        {(channel.streamFitness || channel.hasStripe || channel?.hasCardpointe) && (
          <>
            <Box mb={2}>
              <PartnersMarketPlace
                channelId={channel?.id}
                setRefreshProducts={setRefreshProducts}
                hasStripe={channel.hasStripe}
              />
            </Box>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Box>
                  <StoreCategories ownerableType="Channel" ownerableId={channel?.id} />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box mb={3}>
                  <StoreOptions
                    currency={channel?.currency}
                    ownerableType="Channel"
                    ownerableId={channel?.id}
                  />
                </Box>
                <Box>
                  <StoreDiscountCodes
                    currency={channel?.currency}
                    ownerableType="Channel"
                    ownerableId={channel?.id}
                  />
                </Box>
              </Grid>
            </Grid>
            <Box mt={3}>
              {!!channel?.id && (
                <StoreProducts
                  ownerableType="Channel"
                  ownerableId={channel?.id}
                  channelId={channel?.id}
                  currency={channel?.currency}
                  channelsTaxes={channel?.channelsTaxes}
                  hasShopifyIntegration={channel?.hasShopify}
                  refreshProducts={refreshProducts}
                />
              )}
            </Box>
          </>
        )}
      </Box>
    </StoreContext.Provider>
  );
};

export default ChannelStore;
