import React, { useContext } from 'react';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import Card from 'core/ui/Card';
import { ProfileContext } from 'pages/shared/Profile/Edit';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import { ButtonPrimarySm } from 'core/ui/Button';
import { useSelector } from 'react-redux';
import { usersSelectors } from 'modules/users';
import LoaderSm from 'core/ui/LoaderSm';
import StripeConnectedAccount from './StripeConnectedAccount';

const WorkoutPrice = () => {
  const { t } = useTranslation();
  const formContext = useContext(ProfileContext);
  const loadingUser = useSelector((state) => usersSelectors.getLoading(state));

  return (
    <Card
      btopcolor="#4fb94a"
      btopwidth={2}
      hidedivider="true"
      title={t('profile.oneToOne')}
      action={
        <Box p={3}>
          <ButtonPrimarySm onClick={() => formContext.submitForm()}>
            <LoaderSm width={15} loading={loadingUser} />
            {t('button.save')}
          </ButtonPrimarySm>
        </Box>
      }
    >
      <Box p={3}>
        <Box mb={3}>
          <StripeConnectedAccount />
        </Box>
        <Box mb={3}>
          <TextFieldRounded
            name="calendarLink"
            error={formContext.errors.calendarLink}
            placeholder={t('profile.placeholder.calendarLink')}
            label={t('profile.label.calendarLink').toUpperCase()}
            inputRef={formContext.register({ required: false })}
          />
        </Box>

        <Box mb={3}>
          <TextFieldRounded
            type="number"
            pattern="[0-9]*"
            name="amountHour"
            error={formContext.errors.amountHour}
            placeholder={t('profile.placeholder.amountHour')}
            label={t('profile.label.amountHour').toUpperCase()}
            inputRef={formContext.register({ required: false })}
            currency
          />
        </Box>

        <Box mb={3}>
          <TextFieldRounded
            type="number"
            pattern="[0-9]*"
            name="amountHalfHour"
            error={formContext.errors.amountHalfHour}
            placeholder={t('profile.placeholder.amountHalfHour')}
            label={t('profile.label.amountHalfHour').toUpperCase()}
            inputRef={formContext.register({ required: false })}
            currency
          />
        </Box>
      </Box>
    </Card>
  );
};

export default WorkoutPrice;
