import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Typography from 'core/ui/Typography';
import { Box, Link, Tooltip } from '@material-ui/core';
import { dateExt, time } from 'core/utils/formatters';
import { useTranslation } from 'react-i18next';
import { ButtonPrimary } from 'core/ui/Button';
import LabelBreakLine from 'core/ui/LabelBreakLine';
import { Edit, FitnessCenter } from '@material-ui/icons';
import Locations from 'components/Workout/Show/Tabs/Overview/Locations';
import { workoutsActions, workoutsSelectors } from 'modules/workouts';
import { useDispatch, useSelector } from 'react-redux';
import { Skeleton } from '@material-ui/lab';
import ConfirmDialog from 'core/ui/ConfirmDialog';
import CancelIcon from '@material-ui/icons/Cancel';
import { AppContext } from 'contexts';

const ClassDetail = ({
  setOpenDetail,
  workoutId,
  embed,
  confirmCancelClass,
  buttonColor,
  resume,
}) => {
  const { t } = useTranslation();
  const loadingDetail = useSelector((state) => workoutsSelectors.getLoadingDetail(state));
  const dispatch = useDispatch();
  const appContext = useContext(AppContext);
  const [workout, setWorkoutLocal] = useState({});
  const [dialogCancel, setDialogCancel] = useState({
    open: false,
  });

  useEffect(() => {
    if (workoutId) {
      dispatch(
        workoutsActions.getWorkoutDetailStart(workoutId, (w) => {
          setWorkoutLocal(w);
        }),
      );
    }
    return () => {
      dispatch(workoutsActions.clearWorkout());
    };
  }, [workoutId, dispatch]);

  const cancelClass = () => {
    setDialogCancel({
      open: true,
      callback: () => {
        if (confirmCancelClass) {
          confirmCancelClass();
        }
      },
    });
  };

  const openRegister = () => {
    appContext.setModalPurchase({
      open: true,
      workoutId: workout?.id,
      confirmCallback: () => {
        setOpenDetail(false);
      },
    });
  };

  if (loadingDetail) {
    return (
      <Box>
        <Skeleton variant="rect" style={{ width: '100%', height: 150 }} />
        <Skeleton variant="rect" style={{ marginTop: 5, width: '100%', height: 100 }} />
      </Box>
    );
  }

  return (
    <Box>
      <Box>
        <Box mt={1}>
          <Tooltip placement="right-end" title={<p>{workout.slug}</p>}>
            <Typography color="secondary" variant="h6" component="h5">
              {workout.name}
            </Typography>
          </Tooltip>
          <Typography color="secondary" className="tw-text-base tw-text-gray-500">
            <FitnessCenter style={{ fontSize: 14 }} />
            {workout.channelsWorkoutsType?.name}
          </Typography>

          <Box>
            <Typography className="roboto-regular" variant="caption" component="h5">
              <LabelBreakLine>{workout.description}</LabelBreakLine>
            </Typography>
          </Box>

          {!workout.isCompetition && (
            <>
              <Typography color="textSecondary" component="span" variant="body1">
                <span>
                  {dateExt(
                    workout.scheduledAt,
                    t,
                    workout?.channel?.useUserTimezone ? null : workout?.timezone,
                  )}
                </span>
                <span>&nbsp;&bull;&nbsp;</span>
                <span className="roboto-regular">
                  {time(
                    workout.scheduledAt,
                    workout?.channel?.useUserTimezone ? null : workout?.timezone,
                  )}
                </span>
              </Typography>

              {workout.filterDifficulty && (
                <Box display="flex" mt={1}>
                  <Typography>{t('workout.difficulty')}</Typography>
                  <Typography color="textSecondary" ml={3} style={{ textTransform: 'capitalize' }}>
                    {workout.filterDifficulty && workout.filterDifficulty.name}
                  </Typography>
                </Box>
              )}
              <Box display="flex" mt={1}>
                <Typography>{t('workout.label.access')}</Typography>
                {workout.online && workout.inPerson && (
                  <Typography color="textSecondary" ml={3} style={{ textTransform: 'capitalize' }}>
                    {t('workout.setting.online')},&nbsp;{t('workout.setting.inPerson')}
                  </Typography>
                )}
                {!workout.online && workout.inPerson && (
                  <Typography color="textSecondary" ml={3} style={{ textTransform: 'capitalize' }}>
                    {t('workout.setting.inPerson')}
                  </Typography>
                )}
                {workout.online && !workout.inPerson && (
                  <Typography color="textSecondary" ml={3} style={{ textTransform: 'capitalize' }}>
                    {t('workout.setting.online')}
                  </Typography>
                )}
              </Box>
              {!resume && workout.inPerson && (
                <Box mt={3}>
                  <Locations workout={workout} />
                </Box>
              )}
            </>
          )}
        </Box>
        {!resume && (
          <div className="tw-flex tw-flex-row tw-items-center">
            {!embed && workout.isCoach && (
              <Box m={1}>
                <Link
                  href={`/workout/edit/${workout.slug}`}
                  target="_blank"
                  style={{ textDecoration: 'none' }}
                >
                  <ButtonPrimary fullWidth buttonColor={buttonColor}>
                    <Edit />
                  </ButtonPrimary>
                </Link>
              </Box>
            )}
            {workout.active && workout.isCoach && (
              <Box m={1}>
                <ButtonPrimary
                  fullWidth
                  onClick={() => {
                    cancelClass();
                    setOpenDetail(true);
                  }}
                  buttonColor={buttonColor}
                >
                  <CancelIcon />
                </ButtonPrimary>

                <ConfirmDialog
                  open={dialogCancel.open}
                  setOpen={(v) => setDialogCancel((prev) => ({ ...prev, open: v }))}
                  description={t(
                    workout.started ? 'workout.confirm.delete' : 'workout.confirm.cancel',
                  )}
                  labelConfirm={t('confirm')}
                  labelCancel={t('cancel')}
                  confirm={dialogCancel.callback}
                />
              </Box>
            )}
            <Box m={1} flexGrow={1}>
              <Link
                href={`/workout/view/${workout.slug}`}
                target="blank"
                style={{ textDecoration: 'none' }}
              >
                <ButtonPrimary fullWidth buttonColor={buttonColor}>
                  {t('workout.detail.viewClass')}
                </ButtonPrimary>
              </Link>
            </Box>
            {!workout?.registered && (
              <Box m={1} flexGrow={1}>
                <ButtonPrimary fullWidth buttonColor={buttonColor} onClick={() => openRegister()}>
                  {t('button.register')}
                </ButtonPrimary>
              </Box>
            )}

            {workout.online && workout.registered && (
              <Box m={1} flexGrow={1}>
                <Link
                  href={`/workout/${workout.slug}/stream`}
                  target="blank"
                  style={{ textDecoration: 'none' }}
                >
                  <ButtonPrimary fullWidth buttonColor={buttonColor}>
                    {t('workout.joinClass')}
                  </ButtonPrimary>
                </Link>
              </Box>
            )}
          </div>
        )}
      </Box>
    </Box>
  );
};

export default ClassDetail;
ClassDetail.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  setOpenDetail: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  workoutId: PropTypes.number,
  embed: PropTypes.bool,
};

ClassDetail.defaultProps = {
  setOpenDetail: () => {},
  workoutId: null,
  embed: false,
};
