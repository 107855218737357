import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { roomActions, roomSelectors } from 'modules/room';
import Box from 'core/ui/Box';
import './Layout/index.css';
import { StreamContext } from 'pages/Stream';
import { TypesSignal } from 'core/openVidu/useOpenVidu';
import OvVideoComponent from '../Video/OvVideo';

const LiveUsersGallery = ({ style, showLiveIcon }) => {
  const participants = useSelector((state) => roomSelectors.getParticipants(state));
  const focusedUserId = useSelector((state) => roomSelectors.getFocusedUserId(state));
  const userRoom = useSelector((state) => roomSelectors.getUser(state));
  const streamContext = useContext(StreamContext);
  const dispatch = useDispatch();
  const setFocused = (userId) => {
    if (focusedUserId === userId) {
      dispatch(roomActions.setFocusedUserId(null));
      streamContext.eventSideBar.sendSignalUserChanged({
        type: TypesSignal.setFocusedUser,
        userId: userRoom.id,
        focusedUserId: null,
      });
    } else {
      dispatch(roomActions.setFocusedUserId(userId));
      streamContext.eventSideBar.sendSignalUserChanged({
        type: TypesSignal.setFocusedUser,
        userId: userRoom.id,
        focusedUserId: userId,
      });
    }
  };

  const styleBox = {
    ...style,
    borderRadius: 5,
    overflow: 'hidden',
  };

  if (userRoom && !userRoom.streamManager) {
    return <></>;
  }

  return (
    <>
      <Box
        className={`video-container ${focusedUserId === userRoom.id ? 'neon-wrapper' : ''}`}
        m={0.5}
        style={styleBox}
      >
        {userRoom && userRoom.streamManager && (
          <OvVideoComponent
            showOnline
            showClose={false}
            showControls
            showLiveIcon={showLiveIcon}
            showMove={false}
            focused={focusedUserId === userRoom.id}
            setFocused={setFocused}
            style={{
              width: '100%',
              height: '100%',
              backgroundColor: '#000',
              borderRadius: 5,
            }}
            user={userRoom}
            streamManager={userRoom.streamManager}
          />
        )}
      </Box>
      {participants &&
        participants.map((sub, i) => (
          <Box
            className={`video-container ${focusedUserId === sub.id ? 'neon-wrapper' : ''}`}
            m={0.5}
            key={i.toString()}
            style={styleBox}
          >
            <OvVideoComponent
              showOnline
              showClose={false}
              showControls
              showLiveIcon={showLiveIcon}
              focused={focusedUserId === sub.id}
              setFocused={setFocused}
              showMove={false}
              style={{
                width: '100%',
                height: '100%',
                backgroundColor: '#000',
                borderRadius: 5,
              }}
              user={sub}
              streamManager={sub.streamManager}
            />
          </Box>
        ))}
    </>
  );
};

LiveUsersGallery.propTypes = {};

LiveUsersGallery.defaultProps = {};

export default LiveUsersGallery;
