import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

import * as selectors from './selectors';
import * as sagas from './sagas';

/* ---------- Action Creators and Types ---------- */
const { Types, Creators } = createActions(
  {
    postChannelListsError: ['error'],
    postChannelListsStart: ['data'],
    postChannelListsSuccess: ['list'],

    putChannelListsError: ['error'],
    putChannelListsStart: ['data'],
    putChannelListsSuccess: ['list'],

    getChannelListsError: ['error'],
    getChannelListsStart: ['channelId', 'channelEdit'],
    getChannelListsSuccess: ['lists', 'pagination'],

    getUserChannelListsError: ['error'],
    getUserChannelListsStart: ['userId'],
    getUserChannelListsSuccess: ['lists'],

    postWorkoutChannelListError: ['error'],
    postWorkoutChannelListStart: ['channelId', 'listId', 'workoutId', 'callback'],
    postWorkoutChannelListSuccess: ['list'],

    deleteWorkoutChannelListError: ['error'],
    deleteWorkoutChannelListStart: ['channelId', 'listId', 'workoutId', 'callback'],
    deleteWorkoutChannelListSuccess: ['list'],
  },
  { prefix: 'channelLists/' },
);

/* ---------- Initial State ---------- */
const INITIAL_STATE = Immutable({
  data: [],
  userLists: [],
  error: null,
  loading: false,
});

/* ---------- Reducers ---------- */
const loadStartReducer = (state) => state.set('loading', true);

const postChannelListsSuccessReducer = (state, action) =>
  state.merge({
    ChannelLists: action.ChannelLists,
    loading: false,
    error: null,
  });

const getChannelListsSuccessReducer = (state, action) =>
  state.merge({
    data: action.lists,
    loading: false,
    error: null,
  });

const getUserChannelListsSuccessReducer = (state, action) =>
  state.merge({
    userLists: action.lists,
    loading: false,
    error: null,
  });

const postWorkoutChannelListSuccessReducer = (state) =>
  state.merge({
    loading: false,
    error: null,
  });

const deleteWorkoutChannelListSuccessReducer = (state) =>
  state.merge({
    loading: false,
    error: null,
  });

const loadErrorReducer = (state, action) =>
  state.merge({
    loading: false,
    error: action.error,
  });

/* ---------- Main Reducer ---------- */
const reducer = createReducer(INITIAL_STATE, {
  [Types.POST_CHANNEL_LISTS_START]: loadStartReducer,
  [Types.POST_CHANNEL_LISTS_SUCCESS]: postChannelListsSuccessReducer,
  [Types.POST_CHANNEL_LISTS_ERROR]: loadErrorReducer,

  [Types.PUT_CHANNEL_LISTS_START]: loadStartReducer,
  [Types.PUT_CHANNEL_LISTS_SUCCESS]: postChannelListsSuccessReducer,
  [Types.PUT_CHANNEL_LISTS_ERROR]: loadErrorReducer,

  [Types.GET_CHANNEL_LISTS_START]: loadStartReducer,
  [Types.GET_CHANNEL_LISTS_SUCCESS]: getChannelListsSuccessReducer,
  [Types.GET_CHANNEL_LISTS_ERROR]: loadErrorReducer,

  [Types.GET_USER_CHANNEL_LISTS_START]: loadStartReducer,
  [Types.GET_USER_CHANNEL_LISTS_SUCCESS]: getUserChannelListsSuccessReducer,
  [Types.GET_USER_CHANNEL_LISTS_ERROR]: loadErrorReducer,

  [Types.POST_WORKOUT_CHANNEL_LIST_START]: loadStartReducer,
  [Types.POST_WORKOUT_CHANNEL_LIST_SUCCESS]: postWorkoutChannelListSuccessReducer,
  [Types.POST_WORKOUT_CHANNEL_LIST_ERROR]: loadErrorReducer,

  [Types.DELETE_WORKOUT_CHANNEL_LIST_START]: loadStartReducer,
  [Types.DELETE_WORKOUT_CHANNEL_LIST_SUCCESS]: deleteWorkoutChannelListSuccessReducer,
  [Types.DELETE_WORKOUT_CHANNEL_LIST_ERROR]: loadErrorReducer,
});

/* ---------- Exporting ---------- */
export const channelListsActions = Creators;
export const channelListsTypes = Types;
export const channelListsSelectors = selectors;
export const channelListsSagas = sagas;

export default reducer;
