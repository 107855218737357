import React from 'react';
import { useSelector } from 'react-redux';
import { channelsSelectors } from 'modules/channels';
import Classes from 'components/Search/Classes';
import Box from 'core/ui/Box';
import Lists from './Lists';

export default () => {
  const channel = useSelector((state) => channelsSelectors.getChannel(state));
  return (
    <>
      <Classes
        buttonColor={channel?.customColors?.buttonColor}
        channelId={channel.id}
        model="Workout"
      />

      <Box mb={5}>
        <Lists />
      </Box>
    </>
  );
};
