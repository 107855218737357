/* eslint-disable react/prop-types */
import React from 'react';
import Paper from 'core/ui/Paper';
import Typography from 'core/ui/Typography';
import SFDataTable from 'core/ui/DataTable';
import Button from 'core/ui/Button';
import { successColor } from 'core/ui/Colors';
import Modules from 'modules';
import useActions from 'modules/map/useActions';
import { date } from 'core/utils/formatters';
import Box from 'core/ui/Box';

export default function ResultContracts({ channelContracts, channel, user, refresh }) {
  const { request } = useActions();

  const acceptContract = (accept, contract) => {
    const pp = {
      userId: user?.id,
      ownerableId: channel?.id,
      ownerableType: contract?.ownerableType,
      objectId: contract?.objectId,
      objectType: contract?.objectType,
      documentId: contract?.documentId,
      kind: contract?.kind,
      accept,
    };
    request({
      action: Modules.usersContracts.actions.acceptUsersContract,
      data: pp,
      options: {
        onSuccess: () => {
          refresh();
        },
      },
    });
  };

  const columns = [
    {
      name: 'Type',
      selector: 'kind',
      sortable: true,
      cell: (row) => (
        <Typography>
          {row.kind?.toUpperCase()}&nbsp;{row?.document?.name}
        </Typography>
      ),
    },
    {
      name: 'Plan',
      selector: 'objectName',
      sortable: true,
      cell: (row) => <Typography> {row?.objectName?.toUpperCase()}</Typography>,
    },
    {
      name: 'Sent At',
      selector: 'createdAt',
      sortable: true,
      cell: (row) => <Typography> {date(row?.createdAt, channel?.dateFormatReact)}</Typography>,
    },
    {
      name: 'Agreed To',
      selector: 'updatedAt',
      sortable: true,
      cell: (row) => (
        <Typography>{row.accepted && date(row?.updatedAt, channel?.dateFormatReact)}</Typography>
      ),
    },
    {
      name: 'Toggle',
      selector: 'accepted',
      sortable: true,
      cell: (row) => (
        <>
          {row.accepted ? (
            <Typography color="primary">Agreed</Typography>
          ) : (
            <Button
              style={{
                backgroundColor: successColor,
                color: 'white',
              }}
              onClick={() => acceptContract(!row.accepted, row)}
            >
              Mark as Agreed
            </Button>
          )}
        </>
      ),
    },
  ];

  return (
    <Paper style={{ width: '100%' }} className="paper-rounded" py={3}>
      <Box flexGrow="1" display="flex" mx={3} mb={3}>
        <Typography variant="h5">Waivers & Contracts</Typography>
      </Box>
      <SFDataTable
        noHead
        columns={columns}
        data={channelContracts}
        pagination
        showExport
        paginationPerPage={20}
      />
    </Paper>
  );
}
