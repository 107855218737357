import React, { useEffect, useState } from 'react';
import useLikes from 'core/useLikes';
import { useTranslation } from 'react-i18next';
import {
  Avatar,
  Dialog,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@material-ui/core';
import Box from '../Box';
import ShackIcon from '../FontIcon/ShackIcon';
import Typography, { fontStyle } from '../Typography';
import DialogTitle from '../Dialog/DialogTitle';
import LoaderSm from '../LoaderSm';

export default function ButtonLikeInfo({
  likeableId,
  likeableType,
  liked,
  callbackLike,
  countLikes,
  buttonColor,
}) {
  const { t } = useTranslation();
  const [modal, setModal] = useState();
  const { postLike, likes, getViewLikes, loading, setLikes } = useLikes();

  useEffect(() => {
    setLikes([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal]);

  const toogleLike = () => {
    postLike(
      {
        likeableId,
        likeableType,
      },
      () => {
        if (callbackLike) {
          callbackLike();
        }
      },
    );
  };

  const openModalGetLikes = () => {
    getViewLikes({ likeableId, likeableType });
    setModal(true);
  };

  return (
    <Box>
      <Box display="flex" alignItems="center" className="hover">
        <Typography onClick={() => toogleLike()}>
          <ShackIcon size={28} checked={liked} />
        </Typography>

        <Typography onClick={() => openModalGetLikes()}>
          {(!liked || countLikes === 1) && (
            <Typography ml={1} color="primary" style={{ ...fontStyle.medium, color: buttonColor }}>
              {countLikes}
            </Typography>
          )}

          {liked && countLikes > 1 && (
            <Typography ml={1} color="primary" style={{ ...fontStyle.medium, color: buttonColor }}>
              <>{t('likes.resume.info').replace('#{total}', countLikes - 1)}</>
            </Typography>
          )}
        </Typography>
      </Box>

      <Dialog open={modal} onClose={() => setModal(false)} maxWidth="sm" fullWidth>
        <Box mb={3}>
          <DialogTitle onClose={() => setModal(false)}>
            <Typography variant="h4">{t('view.likes')}</Typography>
          </DialogTitle>
          <Box display="flex" justifyContent="center" m={3}>
            {loading && <LoaderSm loading />}
          </Box>
          <List>
            {likes?.map((like) => (
              <React.Fragment key={like?.id}>
                <ListItem alignItems="flex-start">
                  <ListItemAvatar>
                    <Avatar alt="Remy Sharp" src={like?.user?.image?.image?.url} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography component="span" variant="h5" color="textPrimary">
                        {like?.user?.name}
                      </Typography>
                    }
                  />
                </ListItem>
                <Divider variant="inset" component="li" />
              </React.Fragment>
            ))}
          </List>
        </Box>
      </Dialog>
    </Box>
  );
}
