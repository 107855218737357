import React from 'react';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';

import useSelectors from 'modules/map/useSelectors';
import { newChannelMembersModule } from 'modules/channelMembers/new';

import Box from 'core/ui/Box';
import Typography, { fontStyle } from 'core/ui/Typography';
import { InputWrapper } from 'core/ui/ChosenComponents';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import SelectBox from 'core/ui/SelectBox';
import { ButtonPrimary } from 'core/ui/Button';
import LoaderSm from 'core/ui/LoaderSm';
import ChoosenChannelTags from 'core/ui/ChoosenChannelTags';

export const Form = ({ control, search, channel }) => {
  const loadingExport = useSelectors(newChannelMembersModule, 'loadingExport');
  const { t } = useTranslation();

  return (
    <Box mb={3}>
      <Box display="flex" alignItems="flex-end" className="tw-flex-wrap tw-gap-2">
        <Box flexGrow={1}>
          <Typography mb={1.5} style={fontStyle.label}>
            {t('label.search')}
          </Typography>

          <InputWrapper>
            <Box style={{ width: '100%' }}>
              <Controller
                control={control}
                name="query"
                render={({ field }) => (
                  <TextFieldRounded
                    value={field.value}
                    onChange={field.onChange}
                    style={{ minWidth: 30, width: '100%', margin: '4px' }}
                    placeholder={t('channel.members.search')}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        search(1);
                      }
                    }}
                  />
                )}
              />
            </Box>
          </InputWrapper>
        </Box>

        <div className="tw-grow">
          <Controller
            control={control}
            name="tags"
            render={({ field }) => (
              <ChoosenChannelTags
                label={t('channel.member.tags')}
                placeholder={t('channel.member.tags')}
                val={field.value || []}
                setValue={(v) => field.onChange(v)}
                channelId={channel?.id}
              />
            )}
          />
        </div>

        <Box>
          <Controller
            control={control}
            name="membershipId"
            render={({ field }) => (
              <SelectBoxObject
                label="Membership"
                placeholder="All"
                emptyItem
                emptyValue={null}
                options={channel.membershipAllPlans || []}
                value={field.value}
                setValue={field.onChange}
                style={{ height: 44 }}
              />
            )}
          />
        </Box>

        <Box>
          <Controller
            control={control}
            name="sortBy"
            render={({ field }) => (
              <SelectBox
                label={t('label.sortBy')}
                value={field.value}
                setValue={field.onChange}
                options={['name', 'joinedDate', 'card/ach', 'createdAt']}
                style={{ height: 44 }}
              />
            )}
          />
        </Box>

        <Box>
          <Controller
            control={control}
            name="sortType"
            render={({ field }) => (
              <SelectBox
                label=""
                value={field.value}
                setValue={field.onChange}
                options={['ASC', 'DESC']}
                style={{ height: 44 }}
              />
            )}
          />
        </Box>

        <Box>
          <ButtonPrimary style={{ height: 44 }} onClick={() => search(1)}>
            {t('button.search')}
          </ButtonPrimary>
        </Box>

        <Box>
          <ButtonPrimary
            style={{ height: 44 }}
            loading={loadingExport}
            onClick={() => search(1, 'export')}
          >
            <LoaderSm loading={loadingExport} />
            {t('button.export')}
          </ButtonPrimary>
        </Box>
      </Box>
    </Box>
  );
};
