import React, { useEffect, useState } from 'react';
import { Dialog, DialogActions } from '@material-ui/core';
import Texture from 'core/ui/Texture';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { reorder } from 'core/utils/helpers';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import Box from 'core/ui/Box';
import { ButtonPrimary } from 'core/ui/Button';
import { useTranslation } from 'react-i18next';
import { secondary, secondaryLight } from 'core/ui/Colors';
import Divider from 'core/ui/Divider';
import useSelectors from 'modules/map/useSelectors';
import { storeProductsModule } from 'modules/storeProducts';
import LoaderSm from 'core/ui/LoaderSm';
import Typography from 'core/ui/Typography';
import ImageProduct from './Image';

export default function ModalReorder({ ownerableId, ownerableType, open, close, refresh }) {
  const { request } = useActions();
  const { t } = useTranslation();
  const loadingReorder = useSelectors(storeProductsModule, 'loadingReorder');
  const loading = useSelectors(storeProductsModule, 'loading');
  const [items, setItems] = useState([]);

  const getProducts = (page = 1) => {
    request({
      action: Modules.storeProducts.actions.getStoreProducts,
      data: {
        ownerableId,
        ownerableType,
        page,
        perPage: 1000,
        showInactive: true,
        categories: [],
        onlyChannelProducts: true,
        orderView: true,
      },
      options: {
        onSuccess: (respData) => {
          setItems(respData);
        },
      },
    });
  };

  useEffect(() => {
    getProducts(1);
  }, []);

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newItems = reorder([...items], result.source.index, result.destination.index);

    setItems(newItems);
  };

  const confirmOrder = () => {
    request({
      action: Modules.storeProducts.actions.reorderProducts,
      data: {
        ownerableId,
        ownerableType,
        products: items.map((item, idx) => ({ id: item.id, orderShow: idx })),
      },
      options: {
        onSuccess: () => {
          refresh();
          close();
        },
      },
    });
  };

  return (
    <Dialog scroll="body" open={open} maxWidth="md" fullWidth onClose={close}>
      <Typography align="center" variant="h5" m={3}>
        {t('products.dragDropToOrder')}
      </Typography>
      <Box p={3} style={{ borderRadius: 10 }}>
        <Texture>
          <Box p={4}>
            <LoaderSm center loading={loading} />
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {items.map((item, index) => (
                      <Draggable key={item.id} draggableId={`${item.id}`} index={index}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <Box display="flex" alignItems="center">
                              <Box display="flex" alignItems="center" flex={1} flexGrow={1}>
                                <Box
                                  style={{ width: 30, height: 30, backgroundColor: secondaryLight }}
                                >
                                  <ImageProduct first images={item.images || []} size={32} />
                                </Box>

                                <Typography ml={2}>&nbsp;{item.name}</Typography>
                              </Box>

                              <Typography mr={1} style={{ color: secondary }}>
                                {index}&nbsp;
                              </Typography>
                            </Box>

                            {items?.length - 1 > index && <Divider my={2} />}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Box>
        </Texture>
      </Box>
      <DialogActions>
        <Box m={1} display="flex" justifyContent="flex-end" alignItems="center">
          <LoaderSm loading={loadingReorder} />
          <ButtonPrimary disabled={loadingReorder} onClick={() => confirmOrder()}>
            {t('button.confirm')}
          </ButtonPrimary>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
