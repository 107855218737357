import api from 'core/api';

export const channelsContractsModule = 'channelsContracts';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const actions = {
  accept: {
    module: channelsContractsModule,
    name: 'accept',
    api: (data) => api.put(`/channels/${data?.channelId}/channels_contracts`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  check: {
    module: channelsContractsModule,
    name: 'check',
    api: (data) => api.get(`/channels/${data?.channelId}/channels_contracts`, { params: data }),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
};

export default {
  actions,
  state: {
    data: [],
    error: null,
    loading: false,
  },
};
