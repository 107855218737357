/* eslint-disable no-nested-ternary */
import React from 'react';
import Typography, { fontStyle } from 'core/ui/Typography';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import { ButtonPrimary } from 'core/ui/Button';
import { currency, date } from 'core/utils/formatters';

const CurrentPlanCosts = ({ loading, channel, renewPlan }) => {
  const { t } = useTranslation();

  return (
    <Box display="flex">
      <Box flexGrow={1}>
        <Box display="flex" mb={5} alignItems="center">
          <Typography color="secondary" align="center" variant="h5" component="h5">
            {t(`channel.plan.renewal`)}
          </Typography>

          <Typography ml={3} color="primary" align="center" variant="h5" component="h5">
            {channel?.annualRenewal ? 'Annual' : 'Monthly'}
          </Typography>
        </Box>
        <Box mb={5}>
          {channel?.activePlans?.inPerson?.renew && (
            <Box display="flex" alignItems="center">
              <Typography color="secondary" align="center" variant="h5" component="h5">
                {t('channel.plan.in_person')}
              </Typography>
              {channel?.plan && (
                <Typography ml={3} color="primary" align="center" variant="h5" component="h5">
                  {t('prefix')}
                  {Number(
                    channel?.activePlans?.free?.active
                      ? 0
                      : channel.annualRenewal
                        ? channel?.plan?.annualAmountInPerson
                        : channel?.plan?.amountInPerson,
                  ).toFixed(2)}
                </Typography>
              )}
            </Box>
          )}
        </Box>
        <Box mb={5}>
          {channel?.activePlans?.online?.renew && (
            <Box display="flex" alignItems="center">
              <Typography color="secondary" align="center" variant="h5" component="h5">
                {t('channel.plan.online')}
              </Typography>
              {channel?.plan && (
                <Typography ml={3} color="primary" align="center" variant="h5" component="h5">
                  {t('prefix')}
                  {Number(
                    channel?.activePlans?.free?.active
                      ? 0
                      : channel.annualRenewal
                        ? channel?.plan?.annualAmount
                        : channel?.plan?.amount,
                  ).toFixed(2)}
                </Typography>
              )}
            </Box>
          )}
        </Box>
        <Box mb={5}>
          {channel?.activePlans?.memberApp?.renew && (
            <Box display="flex" alignItems="center">
              <Typography color="secondary" align="center" variant="h5" component="h5">
                {t('channel.plan.member_app')}
              </Typography>
              {channel?.plan && (
                <Typography ml={3} color="primary" align="center" variant="h5" component="h5">
                  {t('prefix')}
                  {Number(
                    channel?.activePlans?.free?.active
                      ? 0
                      : channel.annualRenewal
                        ? channel?.plan?.annualAmountMemberApp
                        : channel?.plan?.amountMemberApp,
                  ).toFixed(2)}
                </Typography>
              )}
            </Box>
          )}
        </Box>
        {channel?.activePlans?.sifely?.renew && (
          <Box mb={5}>
            <Box display="flex" alignItems="center">
              <Typography color="secondary" align="center" variant="h5" component="h5">
                {t('channel.plan.sifely')}
              </Typography>
              {channel?.plan && (
                <Typography ml={3} color="primary" align="center" variant="h5" component="h5">
                  {t('prefix')}
                  {Number(
                    channel?.activePlans?.free?.active
                      ? 0
                      : channel.annualRenewal
                        ? channel?.plan?.annualAmountSifely
                        : channel?.plan?.amountSifely,
                  ).toFixed(2)}
                </Typography>
              )}
            </Box>
          </Box>
        )}
        {channel?.activePlans?.engage?.renew && (
          <Box mb={5}>
            <Box display="flex" alignItems="center">
              <Typography color="secondary" align="center" variant="h5" component="h5">
                {t('channel.plan.engage')}
              </Typography>
              {channel?.plan && (
                <Typography ml={3} color="primary" align="center" variant="h5" component="h5">
                  {channel?.activePlans?.engage?.engageFree ? (
                    <span>
                      {channel?.engageFree && (
                        <Typography className="tw-font-semibold tw-text-green-600">
                          First {channel?.annualRenewal ? 'year' : 'month'} FREE.
                        </Typography>
                      )}
                    </span>
                  ) : (
                    <>
                      {t('prefix')}
                      {Number(
                        channel?.activePlans?.free?.active
                          ? 0
                          : channel.annualRenewal
                            ? channel?.plan?.annualAmountEngage
                            : channel?.plan?.amountEngage,
                      ).toFixed(2)}
                    </>
                  )}
                </Typography>
              )}
            </Box>
          </Box>
        )}
        {channel?.activePlans?.competition?.renew && (
          <Box mb={5}>
            <Box display="flex" alignItems="center">
              <Typography color="secondary" align="center" variant="h5" component="h5">
                {t('channel.plan.competition')}
              </Typography>
              {channel?.plan && (
                <Typography ml={3} color="primary" align="center" variant="h5" component="h5">
                  {t('prefix')}
                  {Number(
                    channel?.activePlans?.free?.active
                      ? 0
                      : channel.annualRenewal
                        ? channel?.plan?.annualAmountCompetition
                        : Number(channel?.plan?.amountCompetition) / 3,
                  ).toFixed(2)}
                </Typography>
              )}
            </Box>
            <Typography component="span" style={fontStyle.small}>
              {t('label.renew')}
            </Typography>
            <Typography component="span" ml={1} color="primary" style={fontStyle.small}>
              {date(channel?.activePlans?.competition?.renewAt, channel?.dateFormatReact)}
            </Typography>
          </Box>
        )}
      </Box>

      <Box>
        <Box display="flex" justifyContent="center" alignItems="center" m={1}>
          <Typography align="center" variant="h1" color="primary">
            {t('prefix')}
            {currency(channel.currentPlanCost)}
          </Typography>
        </Box>

        <Box display="flex" justifyContent="center" m={1}>
          <Typography style={fontStyle.xLarge} className="roboto-regular">
            {t(!channel.activePlans ? 'channel.plan.cancelAt' : 'channel.plan.renew')}
            &nbsp;{channel && date(channel.renewAt)}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center">
          {!!channel.showRenewButton && (
            <Box mx={1}>
              <ButtonPrimary disabled={loading} onClick={() => renewPlan()}>
                <Typography loading={loading} style={fontStyle.large} className="roboto-regular">
                  {t('channel.plan.renewNow')}
                </Typography>
              </ButtonPrimary>
            </Box>
          )}
        </Box>

        <Typography mt={3} align="center" variant="h1">
          {t('channel.totalPlan').toUpperCase()}
        </Typography>
      </Box>
    </Box>
  );
};

CurrentPlanCosts.defaultProps = {};

export default CurrentPlanCosts;
