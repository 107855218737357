/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Typography, { fontStyle } from 'core/ui/Typography';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import Box from 'core/ui/Box';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Paper from 'core/ui/Paper';

const FormMembershipBonus = ({ channel, currentPlan, setCurrentPlan }) => {
  const { t } = useTranslation();

  const setConfig = (field, value) => {
    setCurrentPlan((prev) => ({
      ...prev,
      membershipBonus: prev.membershipBonus
        ? { ...prev.membershipBonus, [field]: value }
        : { [field]: value },
    }));
  };

  const Option = ({ label, onClick, checked }) => (
    <Box className="link-hover" mr={2} onClick={() => onClick()} display="flex" alignItems="center">
      {!checked ? (
        <RadioButtonUncheckedIcon color="primary" />
      ) : (
        <CheckCircleOutlineIcon color="primary" />
      )}
      <Typography ml={2} variant="body2">
        {label}
      </Typography>
    </Box>
  );

  return (
    <Paper className="paper-rounded" p={3}>
      <Typography mb={2} style={fontStyle.label} variant="body1">
        {t('channel.memberships.membershipBonus').toUpperCase()}
      </Typography>
      <Box>
        <Box display="flex" alignItems="center">
          <Box>
            <Typography mb={2} style={fontStyle.label} variant="body1">
              {t('label.active').toUpperCase()}
            </Typography>
            <Box display="flex">
              <Option
                label={t('button.yes')}
                onClick={() => setConfig('active', true)}
                checked={currentPlan?.membershipBonus?.active}
              />
              <Option
                label={t('button.no')}
                onClick={() => setConfig('active', false)}
                checked={!currentPlan?.membershipBonus?.active}
              />
            </Box>
          </Box>
          {currentPlan?.membershipBonus?.active && (
            <Box>
              <Box display="flex" alignItems="center">
                <Box flexGrow={1}>
                  <Typography mb={2} style={fontStyle.label} variant="body1">
                    {t('channel.label.membershipBonus.amountType').toUpperCase()}
                  </Typography>
                  <Box display="flex">
                    <Option
                      label="%"
                      onClick={() => setConfig('amountType', 'percent')}
                      checked={currentPlan?.membershipBonus?.amountType === 'percent'}
                    />
                    <Option
                      label={channel?.currency?.symbol}
                      onClick={() => setConfig('amountType', 'money')}
                      checked={currentPlan?.membershipBonus?.amountType === 'money'}
                    />
                  </Box>
                </Box>
              </Box>

              <TextFieldRounded
                type="number"
                value={currentPlan?.membershipBonus?.amount || ''}
                pattern="[0-9]*"
                placeholder="0.00"
                label={t('channel.label.membershipBonus.amount').toUpperCase()}
                onChange={(v) => setConfig('amount', v)}
                required
                currency
                currencySymbol={
                  currentPlan?.membershipBonus?.amountType === 'money'
                    ? channel?.currency?.symbol
                    : '%'
                }
                bordered
              />
            </Box>
          )}
        </Box>
      </Box>
    </Paper>
  );
};

FormMembershipBonus.defaultProps = {
  currentPlan: {},
  setCurrentPlan: () => {},
};
FormMembershipBonus.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  currentPlan: PropTypes.object,
  setCurrentPlan: PropTypes.func,
};

export default FormMembershipBonus;
