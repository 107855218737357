import React, { useState } from 'react';

import Paper from 'core/ui/Paper';
import { useTranslation } from 'react-i18next';
import Typography from 'core/ui/Typography';
import SFDataTable from 'core/ui/DataTable';
import { Link } from '@material-ui/core';
import { date } from 'core/utils/formatters';
import { ButtonPrimary, ButtonPrimarySm } from 'core/ui/Button';
import Texture from 'core/ui/Texture';
import ViewWorkoutDetail from './ViewWorkoutDetail';

export default function ClassHistoryTable({
  channel,
  workouts,
  pagination,
  perPage,
  setPerPage,
  setWorkout,
  setModalClass,
  changePage,
  fromClassForm,
}) {
  const [expandedRowId, setExpandedRowId] = useState(null);
  const { t } = useTranslation();
  const columns = [
    {
      name: t('channel.history.name'),
      selector: 'name',
      sortable: true,
      cell: (row) => (
        <Link href={`/workout/view/${row.id}`} target="_blank" color="secondary">
          {row.name}
        </Link>
      ),
    },
    {
      name: t('channel.history.date'),
      selector: 'date',
      sortable: true,
      center: true,
      cell: (row) => (
        <Typography color="primary">
          <span>{date(row.date, channel?.dateFormatReact)}</span>
        </Typography>
      ),
    },
  ];

  if (setWorkout) {
    columns.push({
      name: '',
      cell: (row) => (
        <ButtonPrimarySm onClick={() => setWorkout(row)}>
          <Typography>{t('button.select')}</Typography>
        </ButtonPrimarySm>
      ),
    });
  }

  if (setModalClass) {
    columns.push({
      name: '',
      cell: (row) => (
        <ButtonPrimary
          onClick={() =>
            setModalClass({
              open: true,
              type: 'classFromHistory',
              workoutId: row?.id,
            })
          }
        >
          {t('button.clone')}
        </ButtonPrimary>
      ),
    });
  }

  const getRows = () => {
    const rowsWorkouts =
      workouts &&
      workouts.map((workout, index) => ({
        id: workout.id,
        name: workout.name,
        type: workout.method,
        date: workout.method === 'live' ? workout.scheduledAt : workout.publishAt,
        defaultExpanded: index === 0,
      }));

    return rowsWorkouts;
  };

  const ExpanableComponent = ({ data }) => (
    <Texture>
      <ViewWorkoutDetail workoutId={data.id} fromClassForm={fromClassForm} />
    </Texture>
  );

  const rows = getRows();

  return (
    <Paper style={{ width: '100%' }}>
      <SFDataTable
        title={t('channel.reports.workouts')}
        columns={columns}
        data={rows}
        noDataComponent={null}
        expandableRowExpanded={(row) => expandedRowId === row.id}
        onRowExpandToggled={(expanded, row) => {
          setExpandedRowId(expanded ? row.id : null);
        }}
        pagination
        showExport
        expandableRows
        expandableRowsComponent={<ExpanableComponent />}
        paginationPerPage={perPage}
        onChangePage={(p) => changePage(p)}
        paginationServer
        paginationDefaultPage={pagination?.currentPage}
        paginationTotalRows={pagination?.totalCount}
        onChangeRowsPerPage={(newValue) => {
          if (setPerPage) {
            setPerPage(newValue);
          }
        }}
        paginationRowsPerPageOptions={setPerPage ? [5, 10, 15, 20, 30, 50, 100] : [10]}
      />
    </Paper>
  );
}
