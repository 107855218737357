/* eslint-disable no-alert */
import React, { useEffect, useState } from 'react';
import Typography from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import { DialogContent, Dialog } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { usersActions } from 'modules/users';
import { sessionActions, sessionSelectors } from 'modules/session';
import useIsXs from 'core/utils/hook/useIsXs';
import { Security } from '@material-ui/icons';
import TextFieldRounded from '../TextFieldRounded';
import Box from '../Box';
import { ButtonPrimary } from '../Button';

export default function ModalUserPin() {
  const currentUser = useSelector((state) => sessionSelectors.getUser(state));
  const { t } = useTranslation();
  const [modal, setModal] = useState(false);
  const [pin, setPin] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentUser?.id && !currentUser?.existsPin && !!currentUser?.name) {
      setModal(true);
    } else {
      setModal(false);
    }
  }, [currentUser]);

  const savePin = () => {
    if (!pin || pin?.length !== 4) {
      alert('Pin is invalid, please add 4 digits.');

      return;
    }
    dispatch(
      usersActions.putUpdateUserStart(
        {
          id: currentUser?.id,
          user: {
            encryptedPin: pin,
          },
        },
        () => {
          dispatch(sessionActions.authenticateStart());
          setModal(false);
        },
      ),
    );
  };

  const isXs = useIsXs();

  return (
    <Dialog open={modal} maxWidth="xs" fullWidth fullScreen={isXs}>
      <DialogContent className="tw-bg-gray-50">
        <div>
          <Typography mb={5} align="center" ml={3} color="primary" variant="h4">
            {t('profile.label.pin')}
          </Typography>
          <div className="tw-m-2 tw-flex tw-items-center tw-justify-center">
            <Security className="tw-text-[50px]" />
          </div>
          <Typography mb={5} align="center" ml={3} color="secondary">
            {t('profile.validation.newAccountPin')}
          </Typography>
        </div>

        <Box my={2}>
          <TextFieldRounded
            name="code"
            autoComplete="one-time-code"
            placeholder="Enter the code"
            inputProps={{ maxLength: 4 }}
            style={{ maxWidth: 100 }}
            value={pin}
            onChange={(v) => setPin(v)}
            required
            bordered
          />
        </Box>

        <div className="tw-mt-4 tw-flex tw-flex-row tw-justify-end">
          <ButtonPrimary onClick={() => savePin()} color="primary">
            {t('button.save')}
          </ButtonPrimary>
        </div>
      </DialogContent>
    </Dialog>
  );
}
