import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { useSelector } from 'react-redux';
import { sessionSelectors } from 'modules/session';
import Texture from 'core/ui/Texture';
import Box from 'core/ui/Box';
import Typography from 'core/ui/Typography';
import Grid from 'core/ui/Grid';
import { ButtonPrimary } from 'core/ui/Button';
import { useTranslation } from 'react-i18next';
import Modules from 'modules';
import useActions from 'modules/map/useActions';
import LoaderSm from 'core/ui/LoaderSm';
import Form from 'components/Login/Form';
import Resume from './Resume';

import ChildrenRegister from './ChildrenRegister';

export default function ModalPurchase({ modalPurchase, close }) {
  const { t } = useTranslation();
  const { request } = useActions();
  const [registerType, setRegisterType] = useState('childrenSelect');
  const user = useSelector((state) => sessionSelectors.getUser(state));
  const isAuthenticated = useSelector((state) => sessionSelectors.getIsAuthenticated(state));

  const [loading, setLoading] = useState(false);
  const [workout, setWorkout] = useState();

  useEffect(() => {
    if (modalPurchase.open) {
      setLoading(true);
      request({
        action: Modules.newWorkouts.actions.getWorkout,
        data: { id: modalPurchase.workoutId },
        options: {
          onSuccess: (data) => {
            setWorkout(data);
            setLoading(false);
          },
          onError: () => {
            setWorkout(null);
            setLoading(false);
          },
        },
      });
    }

    return () => {
      setWorkout(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalPurchase.workoutId, modalPurchase.open]);

  useEffect(() => {
    if (user && user.id && workout && workout.id) {
      if (workout.registered && user.hasChildren) {
        setRegisterType('childrenSelect');
      } else {
        setRegisterType(user.hasChildren ? 'children' : 'normal');
      }
    }
    return () => {
      setRegisterType(null);
    };
  }, [user, workout, modalPurchase.open]);

  const buttonColor = workout?.channel?.customColors?.buttonColor;

  return (
    <div>
      <Dialog
        PaperProps={{ style: { borderRadius: 5, minWidth: 300 } }}
        maxWidth="sm"
        scroll="body"
        fullWidth
        onClose={() => close()}
        open={modalPurchase.open}
      >
        <Texture>
          <Box p={5}>
            {!isAuthenticated ? (
              <Form fromModal />
            ) : (
              <div>
                {loading || !workout ? (
                  <Box display="flex" justifyContent="center">
                    <LoaderSm loading />
                  </Box>
                ) : (
                  <div>
                    {user.hasChildren &&
                    (registerType === 'children' || registerType === 'childrenSelect') ? (
                      <Box>
                        {registerType === 'children' && (
                          <div>
                            <Typography mb={10} align="center" variant="h4" color="primary">
                              {t('workout.register.options')}
                            </Typography>
                            <Grid container spacing={1}>
                              {!workout.registered && (
                                <Grid item xs={6}>
                                  <ButtonPrimary
                                    buttonColor={buttonColor}
                                    fullWidth
                                    onClick={() => setRegisterType('normal')}
                                  >
                                    <Typography variant="h5" m={3}>
                                      {t('button.myself')}
                                    </Typography>
                                  </ButtonPrimary>
                                </Grid>
                              )}
                              <Grid item xs={6}>
                                <ButtonPrimary
                                  buttonColor={buttonColor}
                                  fullWidth
                                  onClick={() => setRegisterType('childrenSelect')}
                                >
                                  <Typography variant="h5" m={3}>
                                    {t('button.children')}
                                  </Typography>
                                </ButtonPrimary>
                              </Grid>
                            </Grid>
                          </div>
                        )}

                        {registerType === 'childrenSelect' && (
                          <ChildrenRegister workout={workout} close={() => close()} />
                        )}
                      </Box>
                    ) : (
                      <Resume
                        workout={workout}
                        close={() => close()}
                        confirmCallback={modalPurchase.confirmCallback}
                      />
                    )}
                  </div>
                )}
              </div>
            )}
          </Box>
        </Texture>
      </Dialog>
    </div>
  );
}

ModalPurchase.defaultProps = {
  modalPurchase: { open: false },
  close: () => {},
};
