import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from '@material-ui/core';
import { LayoutContext } from 'pages/shared/Layout';
import { useParams } from 'react-router';
import Judge from 'components/Competition/Workout/Judge/index';
import { workoutsActions, workoutsSelectors } from 'modules/workouts';
import { competitionsActions, competitionsSelectors } from 'modules/competitions';
import Background from 'core/ui/Background';
import Paper from 'core/ui/Paper';
import BannerCompetition from 'components/Competition/Show/BannerCompetition';

const WorkoutJudge = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const layoutContext = useContext(LayoutContext);
  const workout = useSelector((state) => workoutsSelectors.getWorkout(state));
  const loadingWorkout = useSelector((state) => workoutsSelectors.getLoading(state));

  const loadingCompetition = useSelector((state) => competitionsSelectors.getLoading(state));
  const competition = useSelector((state) => competitionsSelectors.getCompetition(state));
  const { workoutId, competitionId } = useParams();

  useEffect(() => {
    dispatch(workoutsActions.getWorkoutStart(workoutId));
    return () => {
      dispatch(workoutsActions.clearWorkout());
    };
  }, [dispatch, workoutId]);

  useEffect(() => {
    dispatch(competitionsActions.getCompetitionStart(competitionId));
    return () => {
      dispatch(competitionsActions.clearCompetition());
    };
  }, [dispatch, competitionId]);

  useEffect(() => {
    layoutContext.setTitleBar(t('title.judging').toUpperCase());
    layoutContext.setActiveMenu('');
  }, [layoutContext, t]);

  return (
    <Background
      hexBgColor={competition?.backgroundColor}
      imageUrl={competition?.backgroundImage?.image?.url}
      heightBanner={0}
      heightHeader={70}
    >
      <Container maxWidth="xl">
        <Box pt={5}>
          <Judge
            competition={competition}
            workout={workout}
            loading={loadingWorkout || loadingCompetition}
          />
        </Box>
        <Paper className="paper-rounded opacity-80" p={3}>
          <Box style={{ position: 'relative' }}>
            <BannerCompetition competition={competition} />
          </Box>
        </Paper>
      </Container>
    </Background>
  );
};

export default WorkoutJudge;
