import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form-v5';
import { useDispatch } from 'react-redux';
import notifications from 'modules/notifications';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import useSelectors from 'modules/map/useSelectors';
import { channelsCoachesClocksModule } from 'modules/channelsCoachesClocks';
import { formatDateApi, formatDateToApi } from 'core/utils/formatters';
import { timeZoneName } from 'core/utils/formatters/date';
import { channelsCoachesRatesModule } from 'modules/channelsCoachesRates';

export default ({ channel, item, callback }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loading = useSelectors(channelsCoachesClocksModule, 'loading');
  const coachRates = useSelectors(channelsCoachesRatesModule, 'data');
  const { request } = useActions();
  const { register, handleSubmit, setValue, watch, reset, errors } = useForm();

  const onSubmit = (values) => {
    const startAtStr = `${values?.startAtDate?.substr(0, 10)} ${values?.startAtHour?.substr(
      11,
      8,
    )}`;
    let endAtStr = null;
    if (values?.endAtDate) {
      endAtStr = `${values?.endAtDate?.substr(0, 10)} ${values?.endAtHour?.substr(11, 8)} ${
        values?.timezone
      }`;
    }

    const startAt = formatDateToApi(startAtStr, values?.timezone);
    const endAt = formatDateToApi(endAtStr, values?.timezone);

    request({
      action: !item?.id
        ? Modules.channelsCoachesClocks.actions.postChannelsCoachesClocks
        : Modules.channelsCoachesClocks.actions.putChannelsCoachesClocks,
      data: {
        ...values,
        id: item?.id || null,
        startAt,
        endAt,
        channelId: channel.id,
      },
      options: {
        onSuccess: () => {
          if (callback) {
            callback(values.name);
          }
          dispatch(notifications.success(t('message.success.save.coachClock')));
          request({
            action: Modules.channelsCoachesClocks.actions.getChannelsCoachesClocks,
            data: { channelId: channel.id },
          });
        },
      },
    });
  };

  const getCoachRates = () => {
    request({
      action: Modules.channelsCoachesRates.actions.getChannelsCoachesRates,
      data: { channelId: channel.id, userId: watch('userId') },
    });
  };

  useEffect(() => {
    if (channel && channel.id && watch('userId')) {
      getCoachRates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel, watch('userId')]);

  useEffect(() => {
    register('userId', { required: true });
    register('description', { required: true });
    register('timezone', { required: true });
    register('channelsCoachesRateId', { required: true });

    register('startAtDate', { required: true });
    register('startAtHour', { required: true });
    register('endAtDate', { required: false });
    register('endAtHour', { required: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register]);

  useEffect(() => {
    if (item && item?.id) {
      reset({
        ...item,

        startAtDate: formatDateApi(item.startAt, 'YYYY-MM-DD HH:mm:ss Z', item.timezone),
        startAtHour: formatDateApi(item.startAt, 'YYYY-MM-DD HH:mm:ss Z', item.timezone),
        endAtDate: formatDateApi(item.endAt, 'YYYY-MM-DD HH:mm:ss Z', item.timezone),
        endAtHour: formatDateApi(item.endAt, 'YYYY-MM-DD HH:mm:ss Z', item.timezone),
      });
    } else {
      setTimeout(() => {
        setValue('timezone', timeZoneName());
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, item?.id]);

  return {
    register,
    setValue,
    watch,
    reset,
    errors,
    onSubmit,
    handleSubmit,
    loading,
    coachRates,
  };
};
