/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Toggle from 'core/ui/Toggle';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import Typography, { fontStyle } from 'core/ui/Typography';
import MIcon from 'core/ui/MIcon';
import LoaderSm from 'core/ui/LoaderSm';
import notifications from 'modules/notifications';
import { useDispatch, useSelector } from 'react-redux';
import { channelsSelectors } from 'modules/channels';
import AchForm from './Ach';
import CardForm from './Card';

const FormPaymentMethod = ({
  setView,
  setSelectedPaymentMethod,
  userId,
  setupIntentId,
  closeModal,
  onlyMethod,
  ownerableId,
  ownerableType,
  bordered,
  showCanadaDebit,
  showMessageToAddStripePaymentMethod,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [method, setMethod] = useState(null);
  const [loading, setLoading] = useState(false);
  const [achType, setAchType] = useState(showCanadaDebit ? 'acss_debit' : 'us_bank_account');
  const { request } = useActions();
  const channel = useSelector((state) => channelsSelectors.getChannel(state));

  const [paymentMethod, setPaymentMethod] = useState();

  useEffect(() => {
    if (onlyMethod && (onlyMethod === 'card' || onlyMethod === 'ach')) {
      setMethod(onlyMethod);
    }
  }, [onlyMethod]);

  const getPaymentMethodBySetupIntent = () => {
    request({
      action: Modules.ownerablePaymentMethods.actions.getPaymentMethodBySetupIntent,
      data: {
        setupIntentId,
      },
      options: {
        onSuccess: (data) => {
          setMethod(data?.method);
          setAchType(data?.achType);
          setPaymentMethod(data);
        },
      },
    });
  };

  useEffect(() => {
    if (setupIntentId) {
      getPaymentMethodBySetupIntent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setupIntentId]);

  const createSetupIntent = (pmMethod) => {
    setLoading(true);
    if (!ownerableId) {
      request({
        action: Modules.userPaymentMethods.actions.createSetupIntent,
        data: {
          userId,
          method: pmMethod,
          achType,
          channelId: channel?.id,
        },
        options: {
          onSuccess: (data) => {
            setLoading(false);
            setPaymentMethod(data);
          },
          onError: (error) => {
            dispatch(notifications.error(error));
          },
        },
      });
    } else {
      request({
        action: Modules.ownerablePaymentMethods.actions.createSetupIntentChannel,
        data: { ownerableType, ownerableId, method: pmMethod, achType, channelId: channel?.id },
        options: {
          onSuccess: (data) => {
            setLoading(false);
            setPaymentMethod(data);
          },
        },
      });
    }
  };

  useEffect(() => {
    if (onlyMethod && (onlyMethod === 'card' || onlyMethod === 'ach')) {
      setMethod(onlyMethod);

      if (onlyMethod === 'card') {
        createSetupIntent('card');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onlyMethod]);

  useEffect(() => {
    if (!onlyMethod && !setupIntentId && method && (method === 'card' || !!achType)) {
      setPaymentMethod(null);
      createSetupIntent(method);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [method, achType]);

  return (
    <Box>
      {showMessageToAddStripePaymentMethod && (
        <Typography m={3} style={{ color: 'white' }}>
          {t('message.new.paymentMethodStripe')}
        </Typography>
      )}
      {!setupIntentId && (
        <>
          {!onlyMethod && (
            <Box display="flex" justifyContent="center" alignItems="center" mb={2}>
              <Toggle
                options={[
                  {
                    label: (
                      <Box display="flex" justifyContent="center" alignItems="center">
                        <MIcon icon="payment" styles={{ color: 'white' }} />
                        <Typography style={fontStyle.medium}>
                          &nbsp;{t('paymentMethod.method.card').toUpperCase()}
                        </Typography>
                      </Box>
                    ),
                    value: 'card',
                    width: 120,
                  },
                  {
                    label: (
                      <Box display="flex" justifyContent="center" alignItems="center">
                        <MIcon icon="account_balance" styles={{ color: 'white' }} />
                        <Typography style={fontStyle.medium}>
                          &nbsp;{t('paymentMethod.method.ach').toUpperCase()}
                        </Typography>
                      </Box>
                    ),
                    value: 'ach',
                    width: 140,
                  },
                ]}
                fontSize="xSmall"
                value={method}
                onChange={(value) => setMethod(value)}
              />
            </Box>
          )}

          {method === 'ach' && (
            <Box display="flex" justifyContent="center" alignItems="center" mb={2}>
              <Toggle
                options={[
                  { label: 'US DEBIT', value: 'us_bank_account', width: 120 },
                  {
                    label: 'CANADA DEBIT',
                    value: 'acss_debit',
                    width: 120,
                  },
                  {
                    label: 'SEPA DEBIT',
                    value: 'sepa_debit',
                    width: 120,
                  },
                ]}
                value={achType}
                onChange={(value) => setAchType(value)}
              />
            </Box>
          )}
        </>
      )}

      {loading && (
        <Box p={10}>
          <LoaderSm center loading />
        </Box>
      )}

      {paymentMethod?.id && paymentMethod?.stripeSetupIntentId && (
        <Box>
          {method === 'card' && (
            <CardForm
              setView={setView}
              setSelectedPaymentMethod={setSelectedPaymentMethod}
              paymentMethod={paymentMethod}
              closeModal={closeModal}
              bordered={bordered}
            />
          )}
          {method === 'ach' && (
            <AchForm
              setView={setView}
              setSelectedPaymentMethod={setSelectedPaymentMethod}
              paymentMethod={paymentMethod}
              closeModal={closeModal}
              bordered={bordered}
              showCanadaDebit={showCanadaDebit}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

export default FormPaymentMethod;
