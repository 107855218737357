import React from 'react';

import Paper from 'core/ui/Paper';
import Typography from 'core/ui/Typography';
import Box from 'core/ui/Box';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import { ButtonPrimary } from 'core/ui/Button';
import LoaderSm from 'core/ui/LoaderSm';
import { useTranslation } from 'react-i18next';

export default ({
  textCoupon,
  setTextCoupon,
  coupon,
  buttonColor,
  clearCoupon,
  applyCode,
  loadingDiscountCode,
}) => {
  const { t } = useTranslation();
  return (
    <Paper className="paper-rounded" p={3}>
      <Typography align="center">{t('channel.membership.discountCode')}</Typography>
      <Box display="flex" mt={2}>
        <TextFieldRounded
          placeholder={t('channel.membership.code.placeholder')}
          value={textCoupon}
          onChange={(v) => setTextCoupon(v)}
          disabled={!!coupon}
        />
        <ButtonPrimary
          onClick={() => (!coupon ? applyCode() : clearCoupon())}
          style={{ width: 100, height: 30 }}
          buttonColor={buttonColor}
        >
          {loadingDiscountCode ? (
            <LoaderSm loading width={25} />
          ) : (
            <>{t(!coupon ? 'button.apply' : 'button.clear')}</>
          )}
        </ButtonPrimary>
      </Box>
    </Paper>
  );
};
