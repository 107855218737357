/* eslint-disable react/prop-types */
import * as React from 'react';
import Paper from 'core/ui/Paper';
import { useTranslation } from 'react-i18next';
import Typography, { fontStyle } from 'core/ui/Typography';
import { channelCurrency } from 'core/utils/formatters';
import SFDataTable from 'core/ui/DataTable';
import Box from 'core/ui/Box';
import ActiveMemberships from './ActiveMemberships';

export default function ActiveMembershipsResume({ members, channel }) {
  const { t } = useTranslation();
  let countOneTime = 0;
  let totalOneTime = 0;
  let countRecurring = 0;
  let totalRecurring = 0;

  const resumeByPlans = () => {
    const plans = {};
    members
      .filter((m) => !!m.membershipPlan)
      .forEach((member) => {
        const key = member.membershipPlan.name;
        if (!plans[key]) {
          plans[key] = {
            id: member.membershipPlan.id,
            name: key,
            amount: 0,
            count: 0,
          };
        }
        if (!member?.membershipPlan?.renewable) {
          totalOneTime += Number(member.membershipChannelAmount);
          countOneTime += 1;
        } else {
          totalRecurring += Number(member.membershipChannelAmount);
          countRecurring += 1;
        }
        plans[key].amount += Number(member.membershipChannelAmount);
        plans[key].count += 1;
      });
    return plans;
  };

  const plans = resumeByPlans();

  const columns = [
    {
      name: t('activeMembershipsResume.table.plan'),
      selector: 'revenue',
      sortable: true,
      right: true,
      cell: (row) => <Typography style={fontStyle.medium}>{row.name}</Typography>,
    },
    {
      name: t('activeMembershipsResume.table.total'),
      selector: 'revenue',
      sortable: true,
      right: true,
      cell: (row) => (
        <Typography style={fontStyle.medium}>
          {channelCurrency(row.amount, channel?.currency)}
        </Typography>
      ),
    },
    {
      name: t('activeMembershipsResume.table.memberships'),
      selector: 'count',
      sortable: true,
      right: true,
      cell: (row) => <Typography style={fontStyle.medium}>{row.count}</Typography>,
    },
  ];

  const ExpanableComponent = ({ data: dataPlan }) => (
    <ActiveMemberships channel={channel} members={members} membershipPlanId={dataPlan?.id} />
  );

  return (
    <Paper style={{ width: '100%' }} pb={3}>
      <SFDataTable
        columns={columns}
        data={Object.keys(plans).map((x) => plans[x])}
        pagination
        showExport={false}
        paginationPerPage={30}
        noHeader
        expandableRows
        expandableRowsComponent={<ExpanableComponent />}
      />

      <Box display="flex" justifyContent="flex-end" m={3} mt={5}>
        <Paper p={3}>
          <Box display="flex">
            <Typography m={1} variant="h6" color="primary">
              {t('activeMembershipsResume.table.total')}:
            </Typography>
            <Typography m={1} variant="h6">
              {countRecurring} Renewing
            </Typography>
          </Box>
          <Typography m={1} variant="h6">
            {channelCurrency(totalRecurring, channel?.currency)}
          </Typography>
          <hr />
          <Box display="flex">
            <Typography m={1} variant="h6" color="primary">
              {t('activeMembershipsResume.table.total')}:
            </Typography>
            <Typography m={1} variant="h6">
              {countOneTime} One Time
            </Typography>
          </Box>
          <Typography m={1} variant="h6">
            {channelCurrency(totalOneTime, channel?.currency)}
          </Typography>
          <hr />
          <Typography m={1} variant="h6">
            {channelCurrency(totalOneTime + totalRecurring, channel?.currency)}
          </Typography>
        </Paper>
      </Box>
    </Paper>
  );
}
