import React from 'react';
import Box from 'core/ui/Box';
import useSelectors from 'modules/map/useSelectors';
import { adminChannelsModule } from 'modules/admin/channels';
import SFDataTable from 'core/ui/DataTable';
import Typography, { fontStyle } from 'core/ui/Typography';
import { currency, date } from 'core/utils/formatters';
import { redColor, warningColor } from 'core/ui/Colors';
import LoaderSm from 'core/ui/LoaderSm';
import { Autorenew } from '@material-ui/icons';
import useConfirmation from 'core/useConfirmation';
import { useDispatch } from 'react-redux';
import { ButtonPrimarySm } from 'core/ui/Button';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import notifications from 'modules/notifications';
import { newChannelMembersModule } from 'modules/channelMembers/new';

const TablePendingMemberships = ({ refresh, loading }) => {
  const loadingRenewMembership = useSelectors(newChannelMembersModule, 'loadingRenewMembership');
  const pendingMemberships = useSelectors(adminChannelsModule, 'pendingMemberships');
  const { confirmation } = useConfirmation();
  const dispatch = useDispatch();
  const { request } = useActions();

  const runMembership = (channelKey) => {
    confirmation({
      description: 'Are you sure?',
      yesClick: () => {
        request({
          action: Modules.newChannelMembers.actions.renewMembership,
          data: {
            channelId: channelKey?.channel?.id,
            channelKeyId: channelKey?.id,
          },
          options: {
            onSuccess: () => {
              refresh();
            },
            onError: (ex) => {
              dispatch(notifications.error(ex));
            },
          },
        });
      },
    });
  };
  const columns = [
    {
      name: 'KEY ID',
      selector: 'keyId',
      sortable: true,
      cell: (row) => <Box>{row.keyId}</Box>,
    },
    {
      name: 'Channel',
      selector: 'channelName',
      sortable: true,
      cell: (row) => <Box>{row.channelName}</Box>,
    },
    {
      name: 'Timezone',
      selector: 'timezone',
      sortable: true,
      cell: (row) => <Box>{row.timezone}</Box>,
    },
    {
      name: 'User Name',
      selector: 'userName',
      sortable: true,
      cell: (row) => <Box>{row.userName}</Box>,
    },
    {
      name: 'Expiry/Start Date',
      selector: 'expiryAt',
      sortable: true,
      cell: (row) => (
        <Box>
          {row.expiryAt}&nbsp;{row?.renew && <Autorenew style={{ fontSize: 10 }} />}
        </Box>
      ),
    },
    {
      name: 'Cost',
      selector: 'costNextRenew',
      sortable: true,
      cell: (row) => <Box>{currency(row?.costNextRenew)}</Box>,
    },
    {
      name: 'Job',
      selector: 'jobTimezoneStatus',
      sortable: true,
      cell: (row) => (
        <Box>
          <Typography style={fontStyle.xSmall}>
            {row?.jobTimezoneStatus === 'OK' ? (
              <span style={{ color: 'green' }}>Job has already run for this timezone</span>
            ) : (
              <span style={{ color: warningColor }}>Job has not yet run for this timezone</span>
            )}
          </Typography>
        </Box>
      ),
    },
    {
      name: 'Issue',
      selector: 'issueCheck',
      sortable: true,
      cell: (row) => (
        <Box>
          {row?.jobTimezoneStatus === 'OK' && !row?.error ? (
            <span style={{ color: redColor }}>
              Check this key
              <ButtonPrimarySm
                disabled={loadingRenewMembership}
                onClick={() => runMembership(row?.channelKey)}
              >
                Run Payment
              </ButtonPrimarySm>
            </span>
          ) : (
            row?.error
          )}
        </Box>
      ),
    },
  ];

  const formatElement = (element, renew = true) => ({
    channelKey: element?.channelKey,

    keyId: element?.channelKey?.id,
    timezone: element?.channelKey?.channel?.timezone,
    jobTimezoneStatus: element?.jobTimezoneStatus,
    userName: `${element?.channelKey?.user?.id} - ${element?.channelKey?.user?.name}`,
    channelName: `${element?.channelKey?.channel?.id} - ${element?.channelKey?.channel?.name}`,
    expiryAt: date(element?.channelKey?.expiryAt || element?.channelKey?.startAt),
    costNextRenew: element?.channelKey?.nextRenewAmounts?.nextCost,
    error: element?.error,
    renew,
    issueCheck: element?.jobTimezoneStatus === 'OK' && !element?.error ? 1 : 0,
  });

  const data = () => {
    const arr = [];
    if (pendingMemberships?.renew) {
      pendingMemberships.renew.forEach((element) => {
        arr.push(formatElement(element, true));
      });
    }
    if (pendingMemberships?.start) {
      pendingMemberships.start.forEach((element) => {
        arr.push(formatElement(element, false));
      });
    }
    return arr;
  };

  return (
    <Box>
      <LoaderSm center loading={loading || loadingRenewMembership} />
      <SFDataTable
        noDataComponent={loading ? null : <Typography m={5}>No Keys</Typography>}
        columns={columns}
        data={data()}
        noHeader
        pagination={false}
      />
    </Box>
  );
};

export default TablePendingMemberships;
