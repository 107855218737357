import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

import * as selectors from './selectors';
import * as sagas from './sagas';

/* ---------- Action Creators and Types ---------- */
const { Types, Creators } = createActions(
  {
    getEquipmentAutocompleteError: ['error'],
    getEquipmentAutocompleteStart: ['query'],
    getEquipmentAutocompleteSuccess: ['equipment'],

    clearEquipmentAutocomplete: [],
  },
  { prefix: 'equipment/' },
);

/* ---------- Initial State ---------- */
const INITIAL_STATE = Immutable({
  dataAutocomplete: [],
  error: null,
  loading: false,
});

/* ---------- Reducers ---------- */
const loadStartReducer = (state) => state.set('loading', true);

const getEquipmentAutocompleteSuccessReducer = (state, action) =>
  state.merge({
    dataAutocomplete: action.equipment,
    loading: false,
    error: null,
  });

const loadErrorReducer = (state, action) =>
  state.merge({
    loading: false,
    error: action.error,
  });

const clearEquipmentAutocompleteReducer = (state) =>
  state.merge({
    dataAutocomplete: [],
  });

/* ---------- Main Reducer ---------- */
const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_EQUIPMENT_AUTOCOMPLETE_START]: loadStartReducer,
  [Types.GET_EQUIPMENT_AUTOCOMPLETE_SUCCESS]: getEquipmentAutocompleteSuccessReducer,
  [Types.GET_EQUIPMENT_AUTOCOMPLETE_ERROR]: loadErrorReducer,

  [Types.CLEAR_EQUIPMENT_AUTOCOMPLETE]: clearEquipmentAutocompleteReducer,
});

/* ---------- Exporting ---------- */
export const equipmentActions = Creators;
export const equipmentTypes = Types;
export const equipmentSelectors = selectors;
export const equipmentSagas = sagas;

export default reducer;
