import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, TableCell, TableRow, TableBody, IconButton, Button } from '@material-ui/core';
import Box from 'core/ui/Box';
import { ButtonPrimary } from 'core/ui/Button';
import { Add, Delete, Sort } from '@material-ui/icons';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Typography from 'core/ui/Typography';
import Paper from 'core/ui/Paper';
import Checkbox from 'core/ui/Checkbox';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import SelImage from 'core/ui/SelImage';
import ModalReorderCustomFields from './ModalReorderCustomFields';

const CustomFields = ({ customFields, setCustomFields, currency }) => {
  const { t } = useTranslation();
  const [modalOrderCustomFields, setModalOrderCustomFields] = useState(false);

  const fields = (customFields || []).filter((x) => !x?._destroy);

  const addOption = () => {
    setCustomFields([...customFields, { required: false, showOrder: customFields?.length + 1 }]);
  };

  const change = (prop, value, item) => {
    setCustomFields(
      customFields.map((x) => {
        const newItem = { ...x };
        if (x === item) {
          newItem[prop] = value;
        }

        return newItem;
      }),
    );
  };

  const addOptionDropdown = (item) => {
    const opts = item.options || [];
    const newOptions = [...opts, ''];
    change('options', newOptions, item);
  };

  const changeOption = (v, item, idx) => {
    const opts = item.options || [];

    const newOptions = opts.map((opt, index) => {
      if (index === idx) {
        return v;
      }
      return opt;
    });

    change('options', newOptions, item);
  };

  const removeNew = (row) => {
    if (!row?.id) {
      setCustomFields(customFields.filter((x) => x !== row));
    } else {
      setCustomFields(customFields.map((x) => (x.id === row.id ? { ...x, _destroy: true } : x)));
    }
  };

  return (
    <Box pb={3} pt={2}>
      <Box display="flex" alignItems="center">
        <Box flexGrow="1">
          <Typography variant="h5" mb={3} color="primary">
            {t('competition.registrationCustomFields')}
          </Typography>
        </Box>
        <Box p={1}>
          <ButtonPrimary onClick={() => setModalOrderCustomFields(true)}>
            <Sort />
          </ButtonPrimary>
        </Box>
        <Box p={1}>
          <ButtonPrimary onClick={() => addOption()}>
            <Add />
          </ButtonPrimary>
        </Box>
      </Box>

      {modalOrderCustomFields && (
        <ModalReorderCustomFields
          open={modalOrderCustomFields}
          close={() => setModalOrderCustomFields(false)}
          customFields={fields}
          setCustomFields={(v) => setCustomFields(v)}
        />
      )}

      {fields?.length > 0 && (
        <Paper p={3}>
          <Table size="small">
            <TableBody>
              {fields &&
                fields.map((row, index) => (
                  <TableRow key={index.toString()}>
                    <TableCell align="center" style={{ position: 'relative', width: 80 }}>
                      {!row?.imageAttributes?._destroy && (row?.imageAttributes || row?.image) ? (
                        <Box>
                          <img
                            src={row?.imageAttributes?.url || row?.image?.image?.url}
                            alt="Custom Field"
                            style={{ width: 80 }}
                          />
                          <Button
                            fullWidth
                            onClick={() =>
                              change('imageAttributes', { id: row?.image?.id, _destroy: true }, row)
                            }
                          >
                            <Delete />
                          </Button>
                        </Box>
                      ) : (
                        <SelImage
                          label=" "
                          setImage={(file) => change('imageAttributes', file, row)}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      <TextFieldRounded
                        value={row.name || ''}
                        onChange={(v) => change('name', v, row)}
                        placeholder="Custom Field"
                        bordered
                      />
                    </TableCell>
                    <TableCell>
                      <TextFieldRounded
                        value={row.cost || ''}
                        onChange={(v) => change('cost', v, row)}
                        placeholder="Cost"
                        bordered
                        type="number"
                        pattern="[0-9]*"
                        currency
                        currencySymbol={currency?.symbol}
                      />
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        checked={row.required}
                        onClick={() => change('required', !row?.required, row)}
                        label="Required"
                      />
                    </TableCell>
                    <TableCell>
                      <Box display="flex" className="gap-10">
                        <Checkbox
                          checked={row.kind === 'dropdown'}
                          onClick={() => {
                            change('kind', row?.kind === 'dropdown' ? 'text' : 'dropdown', row);
                          }}
                          label="Multiple Choice"
                        />

                        {row?.kind === 'dropdown' && (
                          <ButtonPrimary
                            style={{ padding: 1 }}
                            onClick={() => addOptionDropdown(row)}
                          >
                            <Add fontSize="small" />
                          </ButtonPrimary>
                        )}
                      </Box>

                      {row?.kind === 'dropdown' && (
                        <Box mt={2}>
                          {row?.options?.map((opt, idx) => (
                            <TextFieldRounded
                              key={idx}
                              value={opt || ''}
                              onChange={(v) => changeOption(v, row, idx)}
                              placeholder="Option"
                              bordered
                            />
                          ))}
                        </Box>
                      )}
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => removeNew(row)}>
                        <HighlightOffIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Paper>
      )}
      {(customFields || [])?.length === 0 && (
        <Typography align="center" m={3}>
          {t('competition.help.addCustomFields')}
        </Typography>
      )}
    </Box>
  );
};

CustomFields.defaultProps = {};

CustomFields.propTypes = {};

export default CustomFields;
