import { useContext } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { StreamContext } from 'pages/Stream';
import { roomActions, roomSelectors } from 'modules/room';
import notifications from 'modules/notifications';
import { sessionSelectors } from 'modules/session';
import { workoutsSelectors, workoutsActions } from 'modules/workouts';
import { usersActions, usersSelectors } from 'modules/users';
import { playerRequest } from 'core/spotify/requests';
import { useTranslation } from 'react-i18next';
import { sessionWorkoutActions } from 'modules/sessionWorkout';
import { workoutEvent } from 'core/utils/consts';
import Modules from 'modules';
import useActions from 'modules/map/useActions';
import { TypesSignal } from './useOpenVidu';

function useEvents() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const streamContext = useContext(StreamContext);
  const { request } = useActions();
  const user = useSelector((state) => roomSelectors.getUser(state));
  const workout = useSelector((state) => workoutsSelectors.getWorkout(state));
  const workoutCoaches = useSelector((state) => workoutsSelectors.getWorkoutCoaches(state));
  const userSession = useSelector((state) => sessionSelectors.getUser(state));
  const participants = useSelector((state) => roomSelectors.getParticipants(state));
  const config = useSelector((state) => roomSelectors.getConfig(state));
  const session = useSelector((state) => roomSelectors.getSession(state));
  const spotifyData = useSelector((state) => usersSelectors.getSpotifyData(state));
  const spotifyPlayer = useSelector((state) => usersSelectors.getSpotifyPlayer(state));

  const startRecording = ({ callback = null }) => {
    dispatch(
      sessionWorkoutActions.startRecordingStart({
        workoutId: workout.id,
        workoutsInviteId: streamContext.inviteId,
        callback: (data) => {
          streamContext.eventSideBar.sendSignalUserChanged({
            type: TypesSignal.refreshStatusRecording,
            userId: user.id,
          });
          if (callback) {
            callback(data);
          }

          if (data.active) {
            dispatch(
              sessionWorkoutActions.postEventStart({
                workoutId: workout.id,
                kind: workoutEvent.start_recording,
              }),
            );
          }
        },
      }),
    );
  };

  const stopRecording = ({ callback = null }) => {
    dispatch(
      sessionWorkoutActions.stopRecordingStart({
        workoutId: workout.id,
        workoutsInviteId: streamContext.inviteId,
        callback: (data) => {
          if (data && data.id) {
            streamContext.eventSideBar.sendSignalUserChanged({
              type: TypesSignal.refreshStatusRecording,
              userId: user.id,
            });
          }
          if (callback) {
            callback(data);
          }
        },
      }),
    );
  };

  const isCoach = (id = null) => {
    if (!id) {
      return workout.isCoach;
    }

    if (workoutCoaches.find((x) => x.id === id)) {
      return true;
    }

    return false;
  };

  const camStatusChange = (sidebar = false) => {
    try {
      const value = !user.videoActive;

      if (sidebar === true && value) {
        streamContext.setControlPermission((prev) => ({ ...prev, changeVideo: true }));
      } else if (sidebar === true && !value) {
        streamContext.setControlPermission((prev) => ({ ...prev, changeVideo: false }));
      }

      dispatch(
        roomActions.setUser({
          ...user,
          videoActive: value,
        }),
      );
      streamContext.eventSideBar.camStatusChanged(user, value);
    } catch {
      notifications.error('Error to change microphone status.');
    }
  };

  const muteUmute = (value) => {
    dispatch(
      roomActions.setUser({
        ...user,
        audioActive: value,
      }),
    );
    streamContext.eventSideBar.micStatusChanged(user, value);
  };

  const micStatusChanged = (sidebar = false) => {
    try {
      const value = !user.audioActive;

      if (sidebar === true && value) {
        streamContext.setControlPermission((prev) => ({ ...prev, changeAudio: true }));
      } else if (sidebar === true && !value) {
        streamContext.setControlPermission((prev) => ({ ...prev, changeAudio: false }));
      }

      muteUmute(value);
      if (spotifyPlayer) {
        if (value === true) {
          dispatch(usersActions.setSpotifyPlayer({ volume: 10 }));
          playerRequest(spotifyData.accessToken, 'setVolume', { vol: 10 }, spotifyPlayer.id);
        } else {
          dispatch(usersActions.setSpotifyPlayer({ volume: spotifyPlayer.volumeUser || 100 }));
          playerRequest(
            spotifyData.accessToken,
            'setVolume',
            { vol: spotifyPlayer.volumeUser || 100 },
            spotifyPlayer.id,
          );
        }
      }
    } catch {
      notifications.error('Error to change microphone status.');
    }
  };

  const muteAll = () => {
    try {
      streamContext.eventSideBar.sendSignalUserChanged({ muteAll: true });
    } catch {
      notifications.error('Error to mute all microphone status.');
    }
  };

  const toggleMuteUsersOnEntry = () => {
    try {
      dispatch(
        roomActions.setConfig({
          ...config,
          muteUsersOnEntry: !config.muteUsersOnEntry,
        }),
      );
      if (config.muteUsersOnEntry) {
        streamContext.eventSideBar.sendSignalUserChanged({
          type: TypesSignal.muteUsersOnEntry,
          userId: userSession.id,
        });
      }
    } catch {
      notifications.error('Error to mute all microphone status.');
    }
  };

  const isOnline = (id) => {
    if (!workout.live) {
      return false;
    }
    return participants.filter((x) => x.id === id).length > 0 || userSession.id === id;
  };

  const sendMessage = (message, audio = null, toId = null) => {
    const msg = message.replace(/ +(?= )/g, '');
    if (audio != null || (msg !== '' && message !== ' ')) {
      dispatch(
        sessionWorkoutActions.postChatMessageStart({
          workoutId: workout.id,
          message: {
            toId,
            message: msg,
            audio,
          },
          callback: (url) => {
            const data = {
              message: msg,
              userId: userSession.id,
              audio: {
                url,
              },
              streamId: user.streamManager.stream.streamId,
              toId,
            };
            user.streamManager.stream.session.signal({
              data: JSON.stringify(data),
              type: 'chat',
            });
          },
        }),
      );
    }
  };

  const exit = () => {
    streamContext.eventSideBar.exit(!workout.isCoach);
  };

  const setOpenConfig = (b, showClose = false, device) => {
    streamContext.eventSideBar.setOpenConfig((prev) => ({
      ...prev,
      open: b,
      edit: true,
      showClose,
      device,
    }));
  };

  const changeDevice = (deviceId, kind) => {
    streamContext.eventSideBar.changeDevice(deviceId, kind);
  };

  const muteUser = (id) => {
    streamContext.eventSideBar.sendSignalUserChanged({ type: TypesSignal.muteUser, id });
  };

  const hideCamUser = (id) => {
    streamContext.eventSideBar.sendSignalUserChanged({ type: TypesSignal.hideCamUser, id });
  };

  const inactiveUser = (id) => {
    const usr = participants.find((x) => x.id === id);
    if (usr) {
      session.forceDisconnect(usr.streamManager.stream.connection);
    }
    dispatch(
      workoutsActions.putInactivateUserStart({
        workoutId: workout.id,
        userId: id,
        messageSuccess: t('workout.user.inactivate'),
      }),
    );
  };

  const checkInUser = (id) => {
    request({
      action: Modules.newWorkouts.actions.checkInUsers,
      data: {
        workoutId: workout.id,
        userId: id,
      },
      options: {
        onSuccess: () => {
          dispatch(workoutsActions.getWorkoutUsersStart(workout.id));
        },
        onError: (error) => {
          dispatch(notifications.error(error));
        },
      },
    });
  };

  return {
    startRecording,
    stopRecording,
    isOnline,
    camStatusChange,
    micStatusChanged,
    exit,
    sendMessage,
    setOpenConfig,
    changeDevice,
    muteAll,
    muteUser,
    muteUmute,
    hideCamUser,
    isCoach,
    inactiveUser,
    checkInUser,
    toggleMuteUsersOnEntry,
  };
}

export default useEvents;
