import React, { useRef } from 'react';
import Box from 'core/ui/Box';
import { ButtonPrimary } from 'core/ui/Button';
import { useTranslation } from 'react-i18next';
import { ButtonGroup, Paper, MenuList, MenuItem, Popper } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import notifications from 'modules/notifications';
import { primary, secondary } from 'core/ui/Colors';
import useConfirmation from 'core/useConfirmation';
import { competitionsActions } from 'modules/competitions';
import ModalCategory from './ModalCategory';

const MenuActions = ({ object, competition, refresh, regType }) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [category, setCategory] = React.useState(false);

  const { request } = useActions();
  const dispatch = useDispatch();
  const anchorRef = useRef();
  const { confirmation } = useConfirmation();

  const toggleCheckIn = () => {
    request({
      action: Modules.competitionsUsers.actions.update,
      data: {
        competitionId: competition?.id,
        userId: object?.user?.id,
        checkIn: !object?.checkIn,
        regType,
      },
      options: {
        onSuccess: () => {
          refresh();
          dispatch(notifications.success('Waiver has been sent.'));
        },
      },
    });
  };

  const confirmRemoveUser = () => {
    confirmation({
      description: t('areYouSure'),
      yesClick: () => {
        request({
          action: Modules.competitionsUsers.actions.delete,
          data: {
            regType,
            competitionId: competition.id,
            userId: object?.user?.id,
          },
          options: {
            onSuccess: () => {
              dispatch(competitionsActions.getCompetitionStart(competition.id));
            },
            onError: (error) => {
              dispatch(notifications.error(error));
            },
          },
        });
      },
    });
  };

  const reSendWaiverRequest = () => {
    request({
      action: Modules.usersContracts.actions.sendWaiverToUser,
      data: {
        ownerableId: competition?.id,
        ownerableType: 'Competition',
        documentId: competition?.documentWaiverId,
        userId: object?.user?.id,
        kind: 'waivers',
      },
      options: {
        onSuccess: () => {
          dispatch(notifications.success('Waiver has been sent.'));
        },
      },
    });
  };

  return (
    <Box>
      {category && (
        <ModalCategory
          open={category}
          setOpen={setCategory}
          object={object}
          competition={competition}
          regType={regType}
        />
      )}
      <Box>
        <ButtonGroup variant="contained" color="primary" ref={anchorRef} aria-label="split button">
          <ButtonPrimary
            buttonColor={object?.checkIn ? primary : secondary}
            ref={anchorRef}
            onClick={() => setOpen(!open)}
          >
            Actions <ArrowDropDown />
          </ButtonPrimary>
        </ButtonGroup>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          style={{ zIndex: 9999 }}
          disablePortal
        >
          <Box>
            <Paper>
              <MenuList id="split-button-menu">
                <MenuItem
                  onClick={() => {
                    setCategory(true);
                  }}
                >
                  {t('button.editCategory')}
                </MenuItem>
                {competition?.requireWaiver && object?.user?.waiver && (
                  <>
                    <MenuItem
                      onClick={() => {
                        reSendWaiverRequest();
                        setOpen(false);
                      }}
                    >
                      {t('button.resendWaiver')}
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        window.open(object?.user?.waiver?.documentUrl);
                        setOpen(false);
                      }}
                    >
                      {t('button.downloadWaiver')}
                    </MenuItem>
                  </>
                )}
                <MenuItem onClick={() => confirmRemoveUser()}>{t('button.remove')}</MenuItem>

                <MenuItem
                  onClick={() => {
                    toggleCheckIn();
                    setOpen(false);
                  }}
                >
                  {t(object?.checkIn ? 'button.checkOut' : 'button.checkIn')}
                </MenuItem>
              </MenuList>
            </Paper>
          </Box>
        </Popper>
      </Box>
    </Box>
  );
};

export default MenuActions;
