import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from 'core/ui/Typography';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import {
  DialogContent,
  Dialog,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import { ButtonPrimary } from 'core/ui/Button';
import Grid from 'core/ui/Grid';
import TextAreaFieldRounded from 'core/ui/TextAreaFieldRounded';
import { supportActions } from 'modules/support';
import { useDispatch, useSelector } from 'react-redux';
import Paper from 'core/ui/Paper';
import { LayoutContext } from 'pages/shared/Layout';
import DialogTitle from 'core/ui/Dialog/DialogTitle';
import { sessionSelectors } from 'modules/session';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import notifications from 'modules/notifications';
import SelImage from './SelImage';

const useStyles = makeStyles({
  root: {
    backgroundColor: '#f3f3f3',
  },
  btnClose: {
    position: 'absolute',
    right: 5,
    top: 0,
  },
});

export default function ModalSupport() {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const layoutContext = useContext(LayoutContext);
  const isAuthenticated = useSelector((state) => sessionSelectors.getIsAuthenticated(state));

  const [image, setImage] = useState({});
  const [kind, setKind] = useState('');
  const [data, setData] = useState({
    description: '',
    screen: '',
    steps: '',
  });

  const create = () => {
    if (!isAuthenticated) {
      if (!data.name) {
        dispatch(notifications.warning(t(`support.validation.name`)));
        return false;
      }
      if (!data.email) {
        dispatch(notifications.warning(t(`support.validation.name`)));
        return false;
      }
    }

    dispatch(
      supportActions.postSupportStart({
        kind,
        screenshot: image || null,
        data: { ...data },
        messageSuccess: t(`message.success.support.${kind}`),
      }),
    );
    layoutContext.setModalSupport({ open: false });
    return true;
  };

  const changeKind = (value) => {
    setKind(value);
    setImage({});
  };

  useEffect(() => {
    if (layoutContext && layoutContext.modalSupport && layoutContext.modalSupport.requestPR) {
      setKind('feature');
      setData((prev) => ({ ...prev, description: 'Request PR:' }));
    }
    if (
      layoutContext &&
      layoutContext.modalSupport &&
      layoutContext.modalSupport.requestBenchmark
    ) {
      setKind('feature');
      setData((prev) => ({ ...prev, description: 'Request Benchmark:' }));
    }
  }, [layoutContext]);

  const kindSupport = ['bug', 'feature', 'question'];

  return (
    <Dialog
      open
      maxWidth="xs"
      fullWidth
      onClose={() => layoutContext.setModalSupport({ open: false })}
    >
      <DialogTitle background onClose={() => layoutContext.setModalSupport({ open: false })} />
      <DialogContent className={classes.root}>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box display="flex" flexDirection="column">
              <Box display="flex" justifyContent="center" mb={5}>
                <FormControl component="fieldset">
                  <RadioGroup value={kind}>
                    {kindSupport.map((k) => (
                      <FormControlLabel
                        key={k}
                        onChange={(e) => changeKind(e.target.value)}
                        value={`${k}`}
                        control={<Radio size="small" color="primary" />}
                        label={
                          <Typography variant="body2">{t(`support.kind.label.${k}`)}</Typography>
                        }
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Box>
              {kind && !isAuthenticated && (
                <>
                  <Box mt={5}>
                    <TextFieldRounded
                      placeholder={t('support.bug.label.name')}
                      value={data.name}
                      onChange={(v) => setData((prev) => ({ ...prev, name: v }))}
                    />
                  </Box>
                  <Box mt={5}>
                    <TextFieldRounded
                      placeholder={t('support.bug.label.email')}
                      value={data.email}
                      onChange={(v) => setData((prev) => ({ ...prev, email: v }))}
                      rows={2}
                    />
                  </Box>
                </>
              )}
              {kind === 'bug' && (
                <>
                  <Box mb={4} mt={5}>
                    <TextAreaFieldRounded
                      placeholder={t('support.bug.placeholder.browserDevice')}
                      value={data.browser}
                      onChange={(v) => setData((prev) => ({ ...prev, browser: v }))}
                      rows={2}
                    />
                  </Box>
                  <Box mb={4}>
                    <TextAreaFieldRounded
                      placeholder={t('support.bug.placeholder.screen')}
                      value={data.screen}
                      onChange={(v) => setData((prev) => ({ ...prev, screen: v }))}
                      rows={2}
                    />
                  </Box>
                  <Box mb={4}>
                    <TextAreaFieldRounded
                      placeholder={t('support.bug.placeholder.steps')}
                      value={data.steps}
                      onChange={(v) => setData((prev) => ({ ...prev, steps: v }))}
                    />
                  </Box>
                  <Box mb={4}>
                    <Paper p={3} elevation={3} className="paper-rounded">
                      <Box mt={2} className="link" display="flex" justifyContent="center">
                        {image.url && <img src={image.url} height={100} alt={image.name} />}
                      </Box>
                      <Box mt={2} className="link" display="flex" justifyContent="center">
                        <SelImage setImage={setImage} />
                      </Box>
                    </Paper>
                  </Box>
                </>
              )}

              {kind === 'feature' && (
                <>
                  {!layoutContext.modalSupport.requestPR &&
                    !layoutContext.modalSupport.requestBenchmark && (
                      <Box mb={4} mt={5}>
                        <TextAreaFieldRounded
                          placeholder={t('support.feature.placeholder.screen')}
                          value={data.screen}
                          onChange={(v) => setData((prev) => ({ ...prev, screen: v }))}
                          rows={3}
                        />
                      </Box>
                    )}
                  <Box mb={4}>
                    <TextAreaFieldRounded
                      placeholder={t('support.feature.placeholder.description')}
                      value={data.description}
                      onChange={(v) => setData((prev) => ({ ...prev, description: v }))}
                    />
                  </Box>
                </>
              )}

              {kind === 'question' && (
                <Box mb={4} mt={5}>
                  <TextAreaFieldRounded
                    placeholder={t('support.question.placeholder.description')}
                    value={data.description}
                    onChange={(v) => setData((prev) => ({ ...prev, description: v }))}
                  />
                </Box>
              )}

              <Box mt={5} mx={5} display="flex">
                <ButtonPrimary onClick={() => create()} style={{ width: '100%' }}>
                  {t('button.send')}
                </ButtonPrimary>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
