import React, { useState } from 'react';
import Box from 'core/ui/Box';
import Typography from 'core/ui/Typography';
import Paper from 'core/ui/Paper';
import { KeyboardArrowDown, KeyboardArrowRight } from '@material-ui/icons';
import { secondary, secondaryLight } from 'core/ui/Colors';
import { Collapse } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ButtonPrimary } from 'core/ui/Button';
import { competitionsSelectors } from 'modules/competitions';
import { useSelector } from 'react-redux';
import ItemTeam from './ItemTeam';

const ListHeats = ({ workout, heats, heatsUsers, leaderboard, removeItem, dropable, editHeat }) => (
  <Box>
    {heats &&
      heats.map((heat) => {
        const heatUsers = heatsUsers.filter((y) => Number(y.heatId) === Number(heat.id));

        return (
          <ItemHeat
            workout={workout}
            removeItem={removeItem}
            leaderboard={leaderboard}
            heatUsers={heatUsers}
            editHeat={editHeat}
            heat={heat}
            key={heat?.id}
            dropable={dropable}
          />
        );
      })}
  </Box>
);

const ItemHeat = ({ heat, leaderboard, heatUsers, workout, removeItem, dropable, editHeat }) => {
  const [open, setOpen] = useState(dropable);
  const { t } = useTranslation();
  const competition = useSelector((state) => competitionsSelectors.getCompetition(state));

  const styleIcon = {
    fontSize: 40,
    color: secondary,
  };
  const totalUsers = heatUsers && heatUsers.length;
  const styleBox = {
    border: `0.5px solid ${secondaryLight}`,
  };
  const styleHeader = {
    backgroundColor: secondary,
  };

  return (
    <Paper elevation={2} mb={3} className="paper-rounded opacity-80" p={3}>
      <Box display="flex" className="link" alignItems="center">
        <Box flexGrow="1" onClick={() => setOpen(!open)}>
          <Typography ml={2} color="secondary" variant="h5">
            {heat?.name?.toUpperCase()}
          </Typography>
        </Box>
        <Box>
          <Typography>{heat?.startTime}</Typography>
        </Box>
        <Box mx={2} display="flex" alignItems="center" className="gap-5">
          <Typography ml={2} color="secondary" variant="h6">
            {t('workout.heat.lanes')}&nbsp;
            {totalUsers}/
          </Typography>

          <Typography color="secondary" variant="h6">
            {heat?.lanes}
          </Typography>
        </Box>

        {editHeat && (
          <Box display="flex" alignItems="center">
            <ButtonPrimary buttonColor={competition?.buttonColor} onClick={() => editHeat(heat)}>
              {t('button.edit')}
            </ButtonPrimary>
          </Box>
        )}

        <Box onClick={() => setOpen(!open)}>
          {open === true ? (
            <KeyboardArrowDown style={{ ...styleIcon }} />
          ) : (
            <KeyboardArrowRight style={{ ...styleIcon }} />
          )}
        </Box>
      </Box>
      <Collapse in={open}>
        <Box>
          <Box display="flex" px={2} py={2} alignItems="center" style={styleHeader}>
            <Box style={{ width: 50 }}>
              <Typography ml={1} variant="body2" style={{ color: 'white' }}>
                {t('competition.workout.lane').toUpperCase()}
              </Typography>
            </Box>
            <Box flexGrow={1}>
              <Typography variant="body2" style={{ color: 'white' }}>
                {t(
                  workout && workout.competition && workout.competition.isTeam
                    ? 'competition.tab.teams'
                    : 'competition.tab.competitors',
                ).toUpperCase()}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body2" style={{ color: 'white' }}>
                {t('competition.workout.points').toUpperCase()}
              </Typography>
            </Box>
          </Box>
          <Box style={styleBox}>
            {dropable ? (
              <div>
                {[...new Array(Number(heat?.lanes))].map((heatLane, idx) => {
                  const laneNumber = idx + 1;

                  const item = heatUsers?.find((x) => Number(x.laneNumber) === laneNumber);

                  const board =
                    item?.userId &&
                    leaderboard &&
                    leaderboard.find((x) => Number(x.user.id) === Number(item.userId));

                  return (
                    <div key={laneNumber} className="">
                      <ItemTeam
                        registration={item?.registration}
                        item={item?.registration}
                        leaderboard={board || {}}
                        laneNumber={laneNumber}
                        isDragDisabled={false}
                        heatId={heat.id}
                        removeItem={removeItem}
                        dropable={dropable}
                        index={idx}
                      />
                    </div>
                  );
                })}
              </div>
            ) : (
              <div>
                {heatUsers &&
                  heatUsers.map((item, index) => (
                    <Box key={`${item.userId}-${heat.id}`}>
                      {() => {
                        const board =
                          leaderboard &&
                          leaderboard.find((x) => Number(x.user.id) === Number(item.userId));

                        return (
                          <ItemTeam
                            item={item?.registration}
                            leaderboard={board || {}}
                            index={index}
                            laneNumber={item?.laneNumber}
                            isDragDisabled={false}
                            heatId={heat.id}
                            removeItem={removeItem}
                            dropable={false}
                          />
                        );
                      }}
                    </Box>
                  ))}
              </div>
            )}
          </Box>
        </Box>
      </Collapse>
    </Paper>
  );
};

export default ListHeats;
