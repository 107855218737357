import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import Typography, { fontStyle } from 'core/ui/Typography';
import Radio from 'core/ui/Radio';
import { Tooltip } from '@material-ui/core';
import { Info } from '@material-ui/icons';

const WaitlistPolicy = ({ watch, setValue, bordered }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Box display="flex" alignItems="center">
        <Typography style={fontStyle.label} mb={1} mr={1}>
          {t('workout.label.waitlistPolicy').toUpperCase()}
        </Typography>
        <Tooltip placement="left" title={t('workout.waitlistPolicy.info')}>
          <Info fontSize="small" />
        </Tooltip>
      </Box>

      <Box display="flex" alignItems="center">
        <Radio
          label={t('label.all')}
          checked={watch('waitlistPolicy') === 'all'}
          onClick={() => setValue('waitlistPolicy', 'all')}
        />
        <Radio
          style={{ marginLeft: 5 }}
          label={t('label.firstInLine')}
          checked={watch('waitlistPolicy') === 'first'}
          onClick={() => setValue('waitlistPolicy', 'first')}
        />
      </Box>
      {watch('waitlistPolicy') === 'first' && (
        <Box>
          <Box m={1} mt={2}>
            <Typography style={fontStyle.label} mb={1}>
              {t('workout.label.waitlistPolicyPercent').toUpperCase()}
            </Typography>
            <Box display="flex" alignItems="center">
              <Box>
                <TextFieldRounded
                  onChange={(v) => setValue('waitlistPolicyPercent', v)}
                  value={watch('waitlistPolicyPercent')}
                  type="number"
                  pattern="[0-9]*"
                  bordered={bordered}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default WaitlistPolicy;

WaitlistPolicy.defaultProps = {};
