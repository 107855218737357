import React, { useCallback, useEffect, useState } from 'react';
import { ButtonPrimary } from 'core/ui/Button';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import Typography from 'core/ui/Typography';
import {
  TableContainer,
  TableHead,
  Table,
  TableCell,
  TableRow,
  TableBody,
  Paper,
} from '@material-ui/core';
import { Add, Search } from '@material-ui/icons';
import LoaderSm from 'core/ui/LoaderSm';
import useSelectors from 'modules/map/useSelectors';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import { channelsCustomPaymentsUsersModule } from 'modules/channelsCustomPaymentsUsers';
import DatePickerRounded from 'core/ui/DatePickers/DatePickerRounded';
import { formatDate, formatDateToApi } from 'core/utils/formatters';
import ItemCustomPaymentUser from './ItemCustomPaymentUser';
import ModalForm from './Form/Modal';

const CoachCustomPayments = ({ channel, coach }) => {
  const { t } = useTranslation();
  const loading = useSelectors(channelsCustomPaymentsUsersModule, 'loading');
  const data = useSelectors(channelsCustomPaymentsUsersModule, 'data');
  const { request } = useActions();

  const [filters, setFilters] = useState({
    month: formatDate(new Date(), channel?.dateFormatReact),
  });

  const [modal, setModal] = useState({
    open: false,
  });

  const getData = useCallback(() => {
    if (channel && channel.id && coach?.id) {
      request({
        action: Modules.channelsCustomPaymentsUsers.actions.getChannelsCustomPaymentsUsers,
        data: { channelId: channel.id, userId: coach?.id, month: formatDateToApi(filters?.month) },
      });
    }
  }, [request, channel, coach, filters?.month]);

  useEffect(() => {
    getData();
  }, [getData]);

  const openModal = () => {
    setModal((prev) => ({ ...prev, mode: 'new', open: true }));
  };

  const onEditClick = (item) => {
    setModal((prev) => ({ ...prev, item, mode: 'edit', open: true }));
  };

  const onRemoveClick = (item) => {
    request({
      action: Modules.channelsCustomPaymentsUsers.actions.destroy,
      data: { channelId: channel.id, id: item?.id },
      options: {
        onSuccess: () => {
          getData();
        },
      },
    });
  };

  return (
    <Box>
      {modal.open && (
        <ModalForm
          open={modal.open}
          item={modal.item}
          mode={modal.mode}
          coach={coach}
          channel={channel}
          close={() => setModal(() => ({ open: false }))}
          callback={() => setModal(() => ({ open: false }))}
        />
      )}

      <Box display="flex" alignItems="flex-end">
        <Box mr={2}>
          <DatePickerRounded
            label="MONTH"
            onChange={(v) => setFilters((prev) => ({ ...prev, month: v }))}
            value={filters.month}
            disablePast={false}
            format="MMM yyyy"
            autoOk
            bordered
          />
        </Box>
        <Box>
          <ButtonPrimary onClick={() => getData()}>
            <Box display="flex" alignItems="flex-end">
              <Search size="small" />
            </Box>
          </ButtonPrimary>
        </Box>

        <Box flexGrow="1" display="flex" alignItems="center">
          <LoaderSm loading={loading} />
        </Box>
        <Box justifyContent="flex-end" alignItems="flex-end">
          <ButtonPrimary onClick={() => openModal()}>
            <Box display="flex" alignItems="flex-end">
              <Add size="small" />
              <Box alignItems="center">
                <Typography component="span" mr={1}>
                  {t('button.assignCustomPayment')}
                </Typography>
              </Box>
            </Box>
          </ButtonPrimary>
        </Box>
      </Box>
      <Box mt={2}>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>{t('label.name')}</TableCell>
                <TableCell>{t('label.description')}</TableCell>
                <TableCell>{t('label.amount')}</TableCell>
                <TableCell>{t('label.date')}</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data.map((row, idx) => (
                  <ItemCustomPaymentUser
                    channel={channel}
                    onEditClick={onEditClick}
                    onRemoveClick={onRemoveClick}
                    key={idx.toString()}
                    item={row}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default CoachCustomPayments;
