import { Drawer, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React, { useContext, useEffect, useState } from 'react';
import ManageChannel from 'components/Channel/Manage';
import Skeleton from 'components/Channel/Manage/Skeleton';
import Menu from 'components/Channel/Manage/Menu';
import { useHistory, useParams } from 'react-router';
import { channelsActions, channelsSelectors } from 'modules/channels';
import { useDispatch, useSelector } from 'react-redux';
import notifications from 'modules/notifications';
import { sessionSelectors } from 'modules/session';
import { LayoutContext } from 'pages/shared/Layout';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

export const closedDrawerWidth = 50;
export const openedDrawerWidth = 250;

const PageManageChannel = () => {
  const [drawer, setDrawer] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();
  const { id, defaultTag } = useParams();
  const user = useSelector((state) => sessionSelectors.getUser(state));
  const loadingChannel = useSelector((state) => channelsSelectors.getLoading(state));
  const channel = useSelector((state) => channelsSelectors.getChannel(state));
  const dispatch = useDispatch();

  const layoutContext = useContext(LayoutContext);
  const classes = useStyles();
  const [tab, setTab] = useState('dashboard');

  useEffect(() => {
    layoutContext.setTitleBar(t('title.account').toUpperCase());
    layoutContext.setActiveMenu('');
  }, [layoutContext, t]);

  useEffect(() => {
    if (defaultTag) {
      setTab(defaultTag);
    }
  }, [defaultTag]);

  useEffect(() => {
    if (loadingChannel || !channel.user || !user.id || id !== channel.id) {
      return;
    }
    if (!channel.allowEdit) {
      history.push(`/channel/view/${channel.slug}`);
      dispatch(notifications.error('Not authorized'));
    }
  }, [loadingChannel, channel, history, user, dispatch, id]);

  useEffect(() => {
    if (id) {
      dispatch(channelsActions.getChannelStart(id, 'edit'));
    }
  }, [dispatch, id]);

  const loading = !channel || !channel.id;
  // const loading = true;

  return (
    <>
      <Helmet>
        <style>{'body { background-color: #f3f3f3; }'}</style>
      </Helmet>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: drawer,
          [classes.drawerClose]: !drawer,
        })}
        classes={{
          paper: clsx(
            {
              [classes.drawerOpen]: drawer,
              [classes.drawerClose]: !drawer,
            },
            'tw-bg-gray-100',
          ),
        }}
        anchor="left"
      >
        {channel?.isCoach && (
          <Menu
            mode="edit"
            setTab={setTab}
            tab={tab}
            setDrawer={setDrawer}
            channel={channel}
            drawer={drawer}
          />
        )}
      </Drawer>
      <main
        className={clsx(
          classes.content,
          drawer ? classes.contentWithDrawerOpen : classes.contentWithDrawerClosed,
        )}
      >
        {!loading && channel?.isCoach && <ManageChannel mode="edit" setTab={setTab} tab={tab} />}
        {loading && <Skeleton />}
      </main>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  drawer: {
    justifyContent: 'center',
    flexShrink: 0,
    border: 'none',
    backgroundColor: '#404041',
  },
  drawerOpen: {
    marginTop: '72px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '45px',
    },
    width: openedDrawerWidth,
    border: 'none',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    marginTop: '72px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '45px',
    },
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: closedDrawerWidth,
    border: 'none',
  },
  content: {
    marginTop: '-72px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '-45px',
    },
    backgroundColor: '#f3f3f3',
    flexGrow: 1,
    paddingTop: 1,
    paddingLeft: 10,
    paddingRight: 10,
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '66px',
    },
  },
  contentWithDrawerClosed: {
    marginLeft: closedDrawerWidth,
  },
  contentWithDrawerOpen: {
    marginLeft: openedDrawerWidth,
  },
}));

export default PageManageChannel;
