import React from 'react';
import Box from 'core/ui/Box';
import useSelectors from 'modules/map/useSelectors';
import SFDataTable from 'core/ui/DataTable';
import Typography from 'core/ui/Typography';
import { currency, date } from 'core/utils/formatters';
import LoaderSm from 'core/ui/LoaderSm';
import { newChannelMembersModule } from 'modules/channelMembers/new';
import { Link } from '@material-ui/core';
import { useHistory } from 'react-router';

const TablePausedMembers = ({ channel, data, loading }) => {
  const loadingRenewMembership = useSelectors(newChannelMembersModule, 'loadingRenewMembership');

  const history = useHistory();

  const columns = [
    {
      name: 'KEY ID',
      selector: 'keyId',
      sortable: true,
      cell: (row) => <Box>{row.id}</Box>,
    },
    {
      name: 'User Name',
      selector: 'userName',
      sortable: true,
      cell: (row) => (
        <Box>
          <Link
            className="hover"
            onClick={() => history.push(`/channel/manage/${channel?.id}/members/${row?.user?.id}`)}
          >
            {row?.user?.name}
          </Link>
        </Box>
      ),
    },
    {
      name: 'Pause Date',
      selector: 'expiryAt',
      sortable: true,
      cell: (row) => (
        <Box>
          {(row.pauseAt || row?.pauseStartAt) &&
            date(row.pauseAt || row?.pauseStartAt, channel?.dateFormatReact)}
        </Box>
      ),
    },
    {
      name: 'End Pause Date',
      selector: 'pauseEndAt',
      sortable: true,
      cell: (row) => <Box>{row.pauseEndAt && date(row.pauseEndAt, channel?.dateFormatReact)}</Box>,
    },
    {
      name: 'Days Remaining',
      selector: 'expiryAt',
      sortable: true,
      cell: (row) => <Box>{row.pauseRemainingDays}</Box>,
    },
    {
      name: 'Next Cost',
      selector: 'costNextRenew',
      sortable: true,
      cell: (row) => (
        <Box>{Number(row.pauseRemainingDays) <= 0 && currency(row.nextRenewAmounts?.nextCost)}</Box>
      ),
    },
  ];

  return (
    <Box>
      <LoaderSm center loading={loading || loadingRenewMembership} />
      <SFDataTable
        noDataComponent={loading ? null : <Typography m={5}>No Keys</Typography>}
        columns={columns}
        data={data?.pausedMembers || []}
        noHeader
        pagination={false}
      />
    </Box>
  );
};

export default TablePausedMembers;
