/* eslint-disable react/prop-types */
import React from 'react';
import Paper from 'core/ui/Paper';
import Typography from 'core/ui/Typography';
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { primary } from 'core/ui/Colors';

export default function ResultLeg({ data }) {
  const colorsChart = {
    totalRevenue: '#D28704',
    totalMembers: '#269FFC',
    arm: primary,
  };
  return (
    <Paper p={3} px={5}>
      <Typography variant="h5" mb={3}>
        LEG
      </Typography>

      <ResponsiveContainer width="100%" height={300}>
        <ComposedChart
          data={data?.legMonths?.months}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis dataKey="month" scale="band" />

          <YAxis id="left" yAxisId="left" />

          <Tooltip />
          <Legend />

          <Bar dataKey="leg" yAxisId="left" barSize={20} fill={colorsChart.totalRevenue} />
        </ComposedChart>
      </ResponsiveContainer>
    </Paper>
  );
}
