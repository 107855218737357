/* eslint-disable react/prop-types */
import React, { useRef, useState } from 'react';
import Paper from 'core/ui/Paper';
import Box from 'core/ui/Box';
import {
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Popper,
} from '@material-ui/core';
import { ButtonPrimary } from 'core/ui/Button';
import { ArrowDropDown } from '@material-ui/icons';
import LoaderSm from 'core/ui/LoaderSm';
import { useTranslation } from 'react-i18next';
import Typography from 'core/ui/Typography';
import { useDispatch } from 'react-redux';
import notifications from 'modules/notifications';

export default ({ buttonColor, loading, actions, hasSelectedItems, itemsCount }) => {
  const { t } = useTranslation();
  const refElement = useRef();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const validateSelected = () => {
    if (!hasSelectedItems) {
      dispatch(notifications.warning('Select the recurrings'));
      return false;
    }

    return true;
  };

  return (
    <Box m={3} display="flex">
      <Box>
        <ButtonGroup ref={refElement} variant="contained" color="primary" aria-label="split button">
          <ButtonPrimary buttonColor={buttonColor} onClick={() => setOpen(true)}>
            <LoaderSm width={20} loading={loading} />
            <Typography noWrap>{hasSelectedItems ? `(${itemsCount})` : ''} Actions</Typography>
            <ArrowDropDown />
          </ButtonPrimary>
        </ButtonGroup>
        <Popper
          open={open}
          anchorEl={refElement?.current}
          role={undefined}
          transition
          style={{ zIndex: 10 }}
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={() => setOpen(false)}>
                  <MenuList id="split-button-menu">
                    <MenuItem
                      onClick={() => {
                        if (validateSelected()) {
                          actions.setBulkEditModal({ open: true });
                        }
                      }}
                    >
                      {t('button.bulkChange')}
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        if (validateSelected()) {
                          actions.stopRecurring(true);
                        }
                      }}
                    >
                      {t('button.remove')}
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        if (validateSelected()) {
                          actions.stopRecurring(false);
                        }
                      }}
                    >
                      {t('button.stop')}
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        if (validateSelected()) {
                          actions.setModalDaylightSavings({ open: true });
                        }
                      }}
                    >
                      {t('recurring.daylightSavings')}
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Box>
    </Box>
  );
};
