/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Typography, { fontStyle } from 'core/ui/Typography';
import Box from 'core/ui/Box';
import Checkbox from 'core/ui/Checkbox';
import Paper from 'core/ui/Paper';
import Grid from 'core/ui/Grid';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import { ChannelContext } from '../..';
import FormOptionYesNo from './FormOptionYesNo';

const FormSetting = ({ currentPlan, setCurrentPlan }) => {
  const { t } = useTranslation();
  const formContext = useContext(ChannelContext);

  return (
    <Paper className="paper-rounded" p={3}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <FormOptionYesNo
            t={t}
            currentPlan={currentPlan}
            setCurrentPlan={setCurrentPlan}
            label={t('channel.memberships.visible').toUpperCase()}
            prop="visible"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormOptionYesNo
            t={t}
            currentPlan={currentPlan}
            setCurrentPlan={setCurrentPlan}
            label={t('channel.memberships.renewable').toUpperCase()}
            prop="renewable"
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <FormOptionYesNo
            t={t}
            currentPlan={currentPlan}
            setCurrentPlan={setCurrentPlan}
            label={t('channel.memberships.cancelable').toUpperCase()}
            prop="cancelable"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormOptionYesNo
            t={t}
            currentPlan={currentPlan}
            setCurrentPlan={setCurrentPlan}
            label={t('channel.memberships.allowPostPayment').toUpperCase()}
            helpText={t('channel.memberships.allowPostPayment.help').toUpperCase()}
            prop="allowPostPayment"
          />

          {currentPlan?.allowPostPayment && (
            <div className="tw-mt-1 tw-flex tw-flex-col tw-gap-1">
              <TextFieldRounded
                type="number"
                pattern="[1-9]*"
                value={currentPlan.postPaymentValidDays || ''}
                placeholder="Number of days"
                label={t('channel.memberships.postPaymentValidDays').toUpperCase()}
                onChange={(v) =>
                  setCurrentPlan((prev) => ({ ...prev, postPaymentValidDays: v > 0 ? v : 0 }))
                }
                required
                bordered
              />

              <FormOptionYesNo
                t={t}
                currentPlan={currentPlan}
                setCurrentPlan={setCurrentPlan}
                label={t('channel.memberships.defaultValueForPostPayment').toUpperCase()}
                helpText={t('channel.memberships.defaultValueForPostPayment.help').toUpperCase()}
                prop="defaultValueForPostPayment"
              />
            </div>
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          <FormOptionYesNo
            t={t}
            currentPlan={currentPlan}
            setCurrentPlan={setCurrentPlan}
            label={t('channel.memberships.hideInEmbed').toUpperCase()}
            prop="hideInEmbed"
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <FormOptionYesNo
            t={t}
            currentPlan={currentPlan}
            setCurrentPlan={setCurrentPlan}
            label={t('channel.memberships.limitOneByUser').toUpperCase()}
            prop="limitOneByUser"
          />
        </Grid>

        {formContext.channel?.activePlans?.online?.active &&
          formContext.channel?.activePlans?.inPerson?.active && (
            <Grid item xs={12} md={8}>
              <Box>
                <Typography style={fontStyle.label} mb={2} variant="body1">
                  {t('channel.memberships.type').toUpperCase()}
                </Typography>

                <Box display="flex">
                  <Box mr={3}>
                    <Checkbox
                      color="primary"
                      label={t('button.online')}
                      checked={currentPlan.online}
                      onClick={() => setCurrentPlan((prev) => ({ ...prev, online: !prev.online }))}
                    />
                  </Box>

                  <Checkbox
                    color="primary"
                    label={t('button.inPerson')}
                    checked={currentPlan.inPerson}
                    onClick={() =>
                      setCurrentPlan((prev) => ({
                        ...prev,
                        inPerson: !prev.inPerson,
                      }))
                    }
                  />
                </Box>
              </Box>
            </Grid>
          )}

        {!!formContext?.channel?.hasHybrid && (
          <Grid item xs={12} md={4}>
            <Box>
              <Box display="flex">
                <Checkbox
                  color="primary"
                  label={t('channel.membership.hybridAccess')}
                  checked={currentPlan.hybridAccess}
                  onClick={() =>
                    setCurrentPlan((prev) => ({
                      ...prev,
                      hybridAccess: !prev.hybridAccess,
                    }))
                  }
                />
              </Box>
            </Box>
          </Grid>
        )}
        {!!formContext?.channel?.activePlans?.sifely?.active && (
          <Grid item xs={12} md={4}>
            <Box>
              <Box display="flex">
                <Checkbox
                  color="primary"
                  label={t('channel.sifelyAccess')}
                  checked={currentPlan.sifelyAccess}
                  onClick={() =>
                    setCurrentPlan((prev) => ({
                      ...prev,
                      sifelyAccess: !prev.sifelyAccess,
                    }))
                  }
                />
              </Box>
            </Box>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

FormSetting.defaultProps = {
  currentPlan: {},
  setCurrentPlan: () => {},
};

export default FormSetting;
