import api from 'core/api';

export const channels = ({ userId }) => api.get('/channels', { params: { user_id: userId } });
export const favorites = (userId) => api.get(`/channels/${userId}/favorites`);
export const channel = ({ id, view }) => api.get(`/channels/${id}`, { params: { view } });
export const channelCalendarWorkouts = ({ id, params }) =>
  api.get(`/channels/${id}/calendar_workouts`, { params });
export const coaches = (id) => api.get(`/channels/${id}/coaches`);
export const postChannel = (params) => api.post('/channels', params);
export const putChannel = ({ id, params }) => api.put(`/channels/${id}`, params);

export const removeUserFromKeys = ({ data }) =>
  api.post(`/channels/${data.channelId}/remove_user_from_keys`, data);

export const createNewKeys = (id) => api.post(`/channels/${id}/create_new_keys`);
export const assignKeysToUser = ({ data }) =>
  api.post(`/channels/${data.channelId}/assign_keys_to_user`, data);

export const toggleKeysToRenew = ({ data }) =>
  api.post(`/channels/${data.channelId}/toggle_keys_to_renew`, data);

export const activeKeyUser = ({ key }) => api.get(`/channels/active_key/${key}`);
export const cancelChannel = (id) => api.patch(`/channels/${id}/cancel`);
export const validateCardOwnerChannel = (channelId) =>
  api.patch(`/channels/${channelId}/validate_card_owner`);

export const applyMembershipDiscountCode = (data) =>
  api.post(`/channels/${data.channelId}/apply_membership_code`, data);

export const connectAccount = (channelId, code) =>
  api.get(`/channels/${channelId}/connect_stripe_account/${code}`);
export const removeAccount = (channelId) =>
  api.delete(`/channels/${channelId}/remove_stripe_account`);
