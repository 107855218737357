import api from 'core/api';

export const newWorkoutHeatsModule = 'newWorkoutHeats';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const actions = {
  getWorkoutHeats: {
    module: newWorkoutHeatsModule,
    name: 'getWorkoutHeats',
    api: (params) => api.get(`/workouts/${params?.workoutId}/heats`, params),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  createNewHeat: {
    module: newWorkoutHeatsModule,
    name: 'createNewHeat',
    api: (params) => api.post(`/workouts/${params?.workoutId}/new_heat`, params),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  cloneHeat: {
    module: newWorkoutHeatsModule,
    name: 'cloneHeat',
    api: (params) => api.post(`/workouts/${params?.workoutId}/clone_heat`, params),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  addUser: {
    module: newWorkoutHeatsModule,
    name: 'addUser',
    api: (params) => api.post(`/workouts/${params?.workoutId}/heat/register`, params),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  removeUser: {
    module: newWorkoutHeatsModule,
    name: 'removeUser',
    api: (params) =>
      api.delete(`/workouts/${params?.workoutId}/heat/${params?.heatId}/remove/${params?.userId}`),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  updateHeat: {
    module: newWorkoutHeatsModule,
    name: 'updateHeat',
    api: (params) => api.put(`/workout_heats/${params?.id}`, params),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
};

export default {
  actions,
  state: {
    loading: false,
    data: [],
  },
};
