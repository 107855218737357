import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Paper from 'core/ui/Paper';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import Typography from 'core/ui/Typography';
import { HintIcon } from './HintIcon';

export const Enroll = ({ channel, setCurrentPlan, currentPlan }) => {
  const { t } = useTranslation();

  const availableEnrollMemberships = useMemo(
    () =>
      channel.membershipAllPlans.filter(
        (plan) => !plan.channelMembershipPlanEnrollId && currentPlan?.id !== plan.id,
      ),
    [currentPlan?.id, channel.membershipAllPlans],
  );

  return (
    <Paper className="paper-rounded tw-mt-2 tw-flex tw-flex-col tw-gap-2 tw-p-3">
      <div className="tw-flex tw-flex-row tw-items-start tw-justify-between tw-gap-x-1">
        <Typography>{t('channel.membership.enroll')}</Typography>

        <HintIcon title={t(`channel.membership.enroll.hint`)} />
      </div>

      <SelectBoxObject
        label=""
        emptyItem
        placeholder={t('channel.membership.enroll.select')}
        emptyValue={null}
        value={currentPlan?.channelMembershipPlanEnrollId}
        setValue={(v) => setCurrentPlan((prev) => ({ ...prev, channelMembershipPlanEnrollId: v }))}
        options={availableEnrollMemberships}
      />
    </Paper>
  );
};
