import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from 'core/ui/Typography';
import LoaderSm from 'core/ui/LoaderSm';
import { DragDropContext } from 'react-beautiful-dnd';
import { workoutHeatsActions, workoutHeatsSelectors } from 'modules/workoutHeats';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonPrimary } from 'core/ui/Button';
import Box from 'core/ui/Box';
import notifications from 'modules/notifications';
import Scrollbars from 'react-custom-scrollbars';
import ConfirmDialog from 'core/ui/ConfirmDialog';
import useSelectors from 'modules/map/useSelectors';
import { competitionsUsersModule } from 'modules/competitionsUsers';
import ListHeats from './List';
import ListCompTeams from './ListCompTeams';

const ManageHeats = ({
  loadHeats,
  resetHeats,
  heatsUsers,
  setHeatsUsers,
  heats,
  competition,
  workout,
  leaderboard,
  buttonColor,
  editHeat,
  cloneHeat,
  createHeat,
  compUsers,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loadingUsers = useSelectors(competitionsUsersModule, 'loadingUsers');
  const loadingHeats = useSelector((state) => workoutHeatsSelectors.getLoading(state));
  const [confirmReset, setConfirmReset] = useState({ open: false });

  const dragItems =
    competition &&
    compUsers
      .filter((x) => !!x.teamCaptain)
      .filter((x) => !heatsUsers.find((y) => Number(y.userId) === Number(x?.user?.id)));

  const validateHeatLanes = (heatData) => {
    const heatId = heatData[1];
    const laneNumber = heatData[2];
    const heat = heats.find((x) => Number(x.id) === Number(heatId));
    const usersCount = heatsUsers.filter((x) => Number(x.heatId) === Number(heatId)).length;
    if (
      heatsUsers.find((x) => Number(x.heatId) === Number(heatId) && x.laneNumber === laneNumber)
    ) {
      dispatch(notifications.warning('Lane already has a user assigned'));
      return false;
    }
    if (Number(heat?.lanes) <= usersCount) {
      dispatch(notifications.warning('Heat is with the max of lanes.'));
      return false;
    }
    return true;
  };

  const onDragEnd = ({ source, destination, draggableId }) => {
    if (destination === undefined || destination === null) return null;
    if (source.droppableId === destination.droppableId && destination.index === source.index)
      return null;

    const heatDest = destination.droppableId.split('-');
    const heatDestId = heatDest[1];

    const heatOrig = source.droppableId.replace('HEAT-', '');
    const heatOrigId = heatOrig[1];

    const userId = draggableId.replace('TEAM-', '');

    if (heatDestId === heatOrigId) {
      const usersHeat = heatsUsers.filter((x) => Number(x.heatId) === Number(heatOrig));

      const newList = usersHeat.filter((_, idx) => idx !== source.index);
      if (newList.length > 0) {
        newList.splice(destination.index, 0, usersHeat[source.index]);
      }

      setHeatsUsers([
        ...heatsUsers.filter((x) => Number(x.heatId) !== Number(heatOrig)),
        ...newList,
      ]);
      return null;
    }

    if (!validateHeatLanes(heatDest)) {
      return null;
    }

    if (heatDest[0].indexOf('ALL') === -1) {
      const obj = compUsers?.find((x) => Number(x.user?.id) === Number(userId));

      setHeatsUsers((prev) => [
        ...prev.filter((x) => Number(x.userId) !== Number(userId)),
        {
          registration: obj,
          heatId: heatDestId,
          laneNumber: heatDest[2],
          userId,
        },
      ]);
    }
    return null;
  };

  const saveManage = () => {
    dispatch(
      workoutHeatsActions.saveWorkoutHeatsUsersStart(
        {
          workoutId: workout.id,
          heatsUsers,
        },
        () => {
          loadHeats();
          dispatch(notifications.success(t('workout.heats.save')));
        },
      ),
    );
  };

  const removeItem = ({ userId, heatId }) => {
    setHeatsUsers((prev) =>
      prev.filter(
        (x) => !(Number(x.heatId) === Number(heatId) && Number(x.userId) === Number(userId)),
      ),
    );
  };

  const resetAllHeats = () => {
    setConfirmReset({
      open: true,
      confirm: () => {
        resetHeats();
        setConfirmReset({ open: false });
      },
    });
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <ConfirmDialog
        open={confirmReset.open}
        confirm={() => confirmReset.confirm()}
        cancel={() => setConfirmReset({ open: false })}
        description={t('message.confirm.reset.heats')}
        labelConfirm={t('button.confirm')}
        labelCancel={t('button.cancel')}
      />
      <Box display="flex" mb={2} alignItems="center" className="gap-5">
        <Box flexGrow={1}>
          <Typography>
            {t('workout.heat.manage.text').replace(
              '{team_competitors}',
              competition.isTeam ? 'team' : 'competitor',
            )}
          </Typography>
        </Box>

        <Box display="flex" alignItems="center">
          <ButtonPrimary
            disabled={loadingHeats}
            buttonColor={competition?.buttonColor}
            onClick={() => cloneHeat()}
          >
            {t('button.clone')}
          </ButtonPrimary>
        </Box>

        <ButtonPrimary
          disabled={loadingHeats}
          buttonColor={buttonColor}
          onClick={() => resetAllHeats()}
        >
          {t('button.resetHeats')}
        </ButtonPrimary>
        <ButtonPrimary
          disabled={loadingHeats}
          buttonColor={buttonColor}
          onClick={() => createHeat()}
        >
          {t('button.createNewHeat')}
        </ButtonPrimary>
        <ButtonPrimary
          disabled={loadingHeats}
          buttonColor={buttonColor}
          onClick={() => saveManage()}
        >
          {t('button.save')}
        </ButtonPrimary>
      </Box>

      <div className="tw-grid tw-grid-cols-1 tw-gap-2 tw-pb-10 md:tw-grid-cols-2">
        <div>
          <Box flexGrow={1} p={3} className="paper-rounded opacity-80 tw-rounded-md">
            <div className="tw-flex tw-flex-row tw-items-center tw-gap-2">
              <Typography mb={3} variant="h5" color="secondary">
                {t(
                  workout.competition.isTeam
                    ? 'competition.tab.teams'
                    : 'competition.tab.competitors',
                ).toUpperCase()}
              </Typography>
              <LoaderSm loading={loadingUsers} />
            </div>

            <ListCompTeams workout={workout} leaderboard={leaderboard} compUsers={dragItems} />
          </Box>
        </div>
        <div>
          <Scrollbars className="tw-min-h-[500px] tw-rounded-md">
            {/* {leaderboard && leaderboard.length > 0 && ( */}
            <ListHeats
              removeItem={removeItem}
              workout={workout}
              heatsUsers={heatsUsers}
              heats={heats}
              leaderboard={leaderboard}
              compUsers={compUsers}
              editHeat={editHeat}
              cloneHeat={cloneHeat}
              dropable
            />
            {/* )} */}
          </Scrollbars>
        </div>
      </div>
    </DragDropContext>
  );
};

export default ManageHeats;
