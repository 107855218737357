import api from 'core/api';

export const workoutWaitlistsModule = 'workoutWaitlists';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const actions = {
  getWorkoutWaitlists: {
    module: workoutWaitlistsModule,
    name: 'getWorkoutWaitlists',
    api: ({ workoutId }) => api.get(`/workout_waitlists/${workoutId}`),
    params: {
      start: ['params'],
      error: [''],
      success: ['data'],
    },
    state: loadingStates,
  },
  removeFromWaitList: {
    module: workoutWaitlistsModule,
    name: 'removeFromWaitList',
    api: ({ workoutId, userId }) => api.delete(`/workout_waitlists/${workoutId}/remove/${userId}`),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
};

export default {
  actions,
  state: {
    loading: false,
    data: [],
  },
};
