import React from 'react';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core';
import Box from '../Box';
import Typography from '../Typography';
import { secondaryBgModal } from '../Colors';

const useStyles = makeStyles({
  root: {
    backgroundColor: secondaryBgModal,
  },
});

const DialogTitle = (props) => {
  const classes = useStyles();
  // eslint-disable-next-line react/prop-types
  const { children, onClose, ...other } = props;
  return (
    <MuiDialogTitle className={props.background ? classes.root : ''} disableTypography {...other}>
      <Box display="flex" alignItems="center" style={{ height: 20 }}>
        <Box flexGrow="1">
          <Typography align="center" color="secondary" variant="h5">
            {children}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          {onClose ? (
            <IconButton size="small" aria-label="close" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          ) : null}
        </Box>
      </Box>
    </MuiDialogTitle>
  );
};

export default DialogTitle;
