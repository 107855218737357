import React from 'react';
import Box from 'core/ui/Box';

import StagesKanban from './StagesKanban';
import ChannelsStageList from './Stages';

const ChannelStages = ({ channel }) => (
  <div>
    <Box mb={2}>
      <ChannelsStageList />
    </Box>

    <div style={{ flex: 1, height: 'calc(100vh - 265px)' }}>
      <StagesKanban channel={channel} />
    </div>
  </div>
);

export default ChannelStages;
