import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import Box from 'core/ui/Box';
import Texture from 'core/ui/Texture';
import Form from './Form';

const ModalFormEditChannelInfo = ({ close, callback, open, channel }) => (
  <Dialog open={open} onClose={close} maxWidth="xs" fullWidth scroll="body">
    <Texture>
      <Box p={5}>
        <Form callback={callback} channel={channel} />
      </Box>
    </Texture>
  </Dialog>
);

ModalFormEditChannelInfo.propTypes = {
  close: PropTypes.func,
};

ModalFormEditChannelInfo.defaultProps = {
  close: () => {},
};

export default ModalFormEditChannelInfo;
