import api from 'core/api';

export const contactFormsModule = 'contactForms';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const actions = {
  getContactForms: {
    module: contactFormsModule,
    name: 'getContactForms',
    api: (params) => api.get(`/contact_forms`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: ['data'],
    },
    state: loadingStates,
  },
  getContactFormByUser: {
    module: contactFormsModule,
    name: 'getContactFormByUser',
    api: (params) => api.get(`/contact_forms/by_user`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  create: {
    module: contactFormsModule,
    name: 'create',
    api: (params) => api.post(`/contact_forms`, params),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  createMessage: {
    module: contactFormsModule,
    name: 'createMessage',
    api: (params) => api.post(`/contact_forms/${params?.id}/create_message`, params),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  update: {
    module: contactFormsModule,
    name: 'update',
    api: (params) => api.put(`/contact_forms/${params?.id}`, params),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  destroy: {
    module: contactFormsModule,
    name: 'destroy',
    api: (params) => api.delete(`/contact_forms/${params.id}`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  generateCode: {
    module: contactFormsModule,
    name: 'generateCode',
    api: (params) => api.put(`/contact_forms/generate_code`, params),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
};

const contactFormsStateModule = {
  actions,
  state: {
    loading: false,
    data: [],
  },
};
export default contactFormsStateModule;
