import { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form-v5';
import { ChannelContext } from '..';

export default ({ itemEdit, callback }) => {
  const formContext = useContext(ChannelContext);
  const { register, handleSubmit, setValue, watch, reset, errors } = useForm();

  const onSubmit = (values) => {
    const items = formContext.workoutsTypes.filter((x) => x !== itemEdit);

    formContext.setWorkoutsTypes([...items, { id: itemEdit ? itemEdit.id : null, ...values }]);
    if (callback) {
      callback();
    }

    setTimeout(() => {
      reset({});
    }, 300);

    setTimeout(() => {
      formContext.submitForm();
    }, 500);
  };

  useEffect(() => {
    register('name', { required: true });
    register('active', { required: false });

    if (!itemEdit) {
      setValue('active', true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register]);

  useEffect(() => {
    setTimeout(() => {
      reset(itemEdit);
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemEdit]);

  return {
    register,
    setValue,
    watch,
    reset,
    errors,
    onSubmit,
    handleSubmit,
  };
};
