import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form-v5';

import { useDispatch, useSelector } from 'react-redux';
import { channelListsActions, channelListsSelectors } from 'modules/channelLists';
import { channelsSelectors } from 'modules/channels';
import notifications from 'modules/notifications';

export default ({ mode, item, callback }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const channel = useSelector((state) => channelsSelectors.getChannel(state));
  const loading = useSelector((state) => channelListsSelectors.getLoading(state));
  const { register, handleSubmit, setValue, watch, reset, errors } = useForm();

  const onSubmit = (values) => {
    if (mode === 'new') {
      dispatch(
        channelListsActions.postChannelListsStart({
          data: { ...values, channelId: channel.id },
          callback: () => {
            if (callback) {
              callback(values.name);
            }
            dispatch(channelListsActions.getChannelListsStart(channel.id, true));
            dispatch(notifications.success(t('message.success.save.channelLists')));
          },
        }),
      );
    } else {
      dispatch(
        channelListsActions.putChannelListsStart({
          data: { id: item.id, ...values, channelId: channel.id },
          callback: () => {
            if (callback) {
              callback(values.name);
            }
            dispatch(channelListsActions.getChannelListsStart(channel.id, true));
            dispatch(notifications.success(t('message.success.save.channelLists')));
          },
        }),
      );
    }
  };

  useEffect(() => {
    register('name', { required: t('form.list.validate.name') });
    register('order', { required: false });
    register('price', { required: false });
    register('active', { required: false });

    if (mode === 'new') {
      setValue('active', true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register]);

  useEffect(() => {
    setTimeout(() => {
      reset(item);
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    register,
    setValue,
    watch,
    reset,
    errors,
    onSubmit,
    handleSubmit,
    loading,
  };
};
