import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { languageSelectors } from 'modules/language';
import { countriesModule } from 'modules/countries';
import PhoneInput from 'react-phone-input-2';
import useSelectors from 'modules/map/useSelectors';
import Box from '../Box';

const useStyles = makeStyles(() => ({
  root: {
    padding: 7,
    display: 'flex',
    alignItems: 'center',
    borderRadius: 5,
  },
  input: {
    fontSize: 8,
    fontFamily: 'Roboto',
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export default function LabelPhone({ value }) {
  const classes = useStyles();

  const countries = useSelectors(countriesModule, 'countries');

  const phoneContries = countries ? countries.map((x) => x.flag) : [];

  const language = useSelector((state) => languageSelectors.getLanguage(state));
  const countriesFlags = [...phoneContries];

  return (
    <Box className="labelPhone">
      {countries.length > 0 && (
        <PhoneInput
          specialLabel=""
          country={language.defaultCountry.toLowerCase()}
          value={value}
          onlyCountries={countriesFlags.map((x) => x.toLowerCase()) || []}
          inputClass={classes.input}
          onChange={() => {}}
          disabled
        />
      )}
    </Box>
  );
}
