import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import Grid from 'core/ui/Grid';
import { makeStyles } from '@material-ui/core';
import { ButtonPrimary } from 'core/ui/Button';
import Typography from 'core/ui/Typography';
import LoaderSm from 'core/ui/LoaderSm';
import { useDispatch, useSelector } from 'react-redux';
import { filtersActions, filtersSelectors } from 'modules/filters';
import { WorkoutContext } from './index';
import ClassSettings from './ClassSettings/index';
import ClassOverview from './ClassOverview';
import Schedule from './Schedule';
import Coaches from './Coaches';

const useStyles = makeStyles((theme) => ({
  media: {
    paddingTop: '46.25%',
    backgroundColor: '#bbbdbf',
    maxHeight: 100,
    backgroundSize: '100% 100%',
  },
  boxSelImage: {
    position: 'absolute',
    marginTop: -30,
  },
  content: {
    position: 'relative',
    backgroundColor: '#f3f3f3',
    borderRadius: 10,
  },
  boxButtons: {
    margin: 0,
    top: 'auto',
    right: 30,
    bottom: 15,
    left: 'auto',
    position: 'fixed',
  },
  button: {
    boxShadow: '0px 3px 5px 0px rgba(0,0,0,0.4)',
    borderRadius: 5,
    height: 38,
    width: 110,
    margin: 2,
    [theme.breakpoints.down('sm')]: {
      bottom: '50px',
    },
  },
}));

export default () => {
  const { t } = useTranslation();
  const formContext = useContext(WorkoutContext);
  const classes = useStyles();
  const dispatch = useDispatch();
  const filters = useSelector((state) => filtersSelectors.getFilters(state));
  const workoutContext = useContext(WorkoutContext);

  useEffect(() => {
    dispatch(filtersActions.getFiltersStart());
  }, [dispatch]);

  useEffect(() => {
    const ar = [];
    filters
      .filter((x) => x.kind === 'age')
      .forEach((filter) => {
        ar.push({ kind: filter.kind, value: filter.id });
      });
    workoutContext.setFilters(ar);
  }, [filters]); //eslint-disable-line

  const mbSections = 2;
  const paddingSections = 2;

  return (
    <Box>
      {formContext.selectedChannel && (
        <>
          <Box mb={mbSections} px={paddingSections} className={classes.content}>
            <ClassSettings />
          </Box>
          <Box mb={mbSections} px={paddingSections} className={classes.content}>
            <Schedule />
          </Box>

          <Box mb={mbSections} px={paddingSections} className={classes.content}>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box mt={3}>
                  <Typography variant="h5" mb={2} color="primary">
                    {t(
                      formContext.competition
                        ? 'workout.competition.title.overview'
                        : 'workout.title.overview',
                    )}
                  </Typography>
                  <Grid container spacing={3} mb={5}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <ClassOverview />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
      {!formContext.competition && formContext.selectedChannel && (
        <Box mb={mbSections} px={paddingSections} className={classes.content}>
          <Grid container pb={5}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Box mt={3}>
                <Typography variant="h5" mb={2} color="primary">
                  {t('workout.title.coaches')}
                </Typography>
              </Box>

              <Box p={3}>
                <Coaches />
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}

      <Box px={5} display="flex" justifyContent="flex-end">
        <ButtonPrimary
          type="submit"
          size="small"
          mt={10}
          mb={5}
          className={classes.button}
          disabled={formContext.submiting}
        >
          <Box display="flex" alignItems="center">
            <LoaderSm loading={formContext.submiting} width={20} />
            <Typography ml={1} component="span">
              {t('button.saveClass')}
            </Typography>
          </Box>
        </ButtonPrimary>
      </Box>
    </Box>
  );
};
