import api from 'core/api';

export const usersAddressesModule = 'usersAddresses';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const loadingAddressStates = {
  start: { loadingAddress: true },
  error: { loadingAddress: false },
  success: { loadingAddress: false },
};

const actions = {
  getAdrresses: {
    module: usersAddressesModule,
    name: 'getAdrresses',
    api: (params) => api.get(`/users_addresses`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: ['data'],
    },
    state: loadingStates,
  },
  create: {
    module: usersAddressesModule,
    name: 'create',
    api: (data) => api.post(`/users_addresses`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  update: {
    module: usersAddressesModule,
    name: 'update',
    api: (data) => api.put(`/users_addresses/${data.id}`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  destroy: {
    module: usersAddressesModule,
    name: 'destroy',
    api: (params) => api.delete(`/users_addresses/${params?.id}`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  getAddress: {
    module: usersAddressesModule,
    name: 'getAddress',
    api: (params) => api.get('/users_address', { params }),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingAddressStates,
    isTakeEvery: true,
  },
};

const usersAddressesModuleObj = {
  actions,
  state: {
    loading: false,
    data: [],
    child: null,
  },
};

export default usersAddressesModuleObj;
