import { all, call, put } from 'redux-saga/effects';

import { getErrorMessage } from 'core/api/api-error';
import notifications from 'modules/notifications';

import { sessionActions } from 'modules/session';
import * as api from './api';
import { usersActions as actions } from './index';

export function* getWorkoutsInvites({ userId }) {
  try {
    const response = yield call(api.workoutInvites, userId);
    yield put(actions.getWorkoutsInvitesSuccess(response.data));
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.getWorkoutsInvitesError(getErrorMessage(error))),
    ]);
  }
}

export function* putUpdateUser({ data, callback }) {
  try {
    const userData = {
      ...data,
    };
    const response = yield call(api.updateUser, { id: data.id, user: userData });
    const user = response.data;
    if (user.id) {
      yield all([
        put(actions.putUpdateUserSuccess(user)),
        put(sessionActions.authenticateStart()),
        // put(push('/dashboard')),
      ]);
      if (callback) {
        callback(user);
      }
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.putUpdateUserError(getErrorMessage(error))),
    ]);
  }
}

export function* postSpotifyAccount({ data }) {
  try {
    const response = yield call(api.spotifyAccount, data);
    yield put(actions.postSpotifyAccountSuccess(response.data));
    if (data.messageSuccess) {
      yield put(notifications.success(data.messageSuccess));
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.postSpotifyAccountError(getErrorMessage(error))),
    ]);
  }
}

export function* delSpotifyAccount({ id }) {
  try {
    const response = yield call(api.deleteSpotifyAccount, id);
    yield put(actions.delSpotifyAccountSuccess(response.data));
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.delSpotifyAccountError(getErrorMessage(error))),
    ]);
  }
}

export function* connectStripeAccount({ userId, code, callback }) {
  try {
    const response = yield call(api.connectAccount, userId, code);
    yield put(actions.connectStripeAccountSuccess());
    if (callback) {
      callback(response.data);
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.connectStripeAccountError(getErrorMessage(error))),
    ]);
  }
}

export function* removeStripeAccount({ userId, callback }) {
  try {
    const response = yield call(api.removeAccount, userId);
    yield put(actions.removeStripeAccountSuccess());
    if (callback) {
      callback(response.data);
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.removeStripeAccountError(getErrorMessage(error))),
    ]);
  }
}
