import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import Texture from 'core/ui/Texture';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import { date, formatDateApi } from 'core/utils/formatters/date';
import { ButtonPrimary } from 'core/ui/Button';
import Typography from 'core/ui/Typography';
import Paper from 'core/ui/Paper';
import CloneContent from 'components/Workout/Form/CloneContent';
import DatePickerRounded from 'core/ui/DatePickers/DatePickerRounded';
import LoaderSm from 'core/ui/LoaderSm';
import Grid from 'core/ui/Grid';
import useForm from './PartnersPrograms/useFormConfirmWorkout';
import SectionsProgramming from './PartnersPrograms/FormSectionsProgramming';

const ModalCloneWorkoutsToWorkout = ({ close, workouts, channel, addEvent, open, programs }) => {
  const { t } = useTranslation();

  const {
    watch,
    setValue,
    submitForm,
    register,

    setCurrentSection,
    currentSection,
    sections,
    loading,

    setSections,
  } = useForm({
    workouts,
    channel,
    addEvent,
    close,
  });

  const [scheduleOptions, setScheduleOptions] = useState(null);

  useEffect(() => {
    setScheduleOptions([
      {
        scheduledAtDate: workouts[0]?.scheduledAt,
      },
    ]);
  }, [workouts]);

  const allPrograms = () => {
    let arrPrograms = [];

    if (programs) {
      programs.forEach((program) => {
        if (program?.parentPrograms?.length > 0) {
          arrPrograms = [...arrPrograms, ...program.parentPrograms];
        } else {
          arrPrograms.push(program);
        }
      });
    }

    return arrPrograms;
  };

  return (
    <Dialog open={open} onClose={close} maxWidth="lg" fullWidth scroll="body">
      <Texture>
        <Box p={5}>
          <Paper p={3} m={1}>
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
              <Typography align="center" variant="h5" mb={1}>
                {date(
                  workouts[0]?.scheduledAt,
                  channel?.useUsDateFormat ? 'ddd MM/DD' : 'ddd DD/MM',
                )}
              </Typography>

              <Box>
                <DatePickerRounded
                  onChange={(v) => setScheduleOptions([{ scheduledAtDate: v }])}
                  value={
                    scheduleOptions?.length > 0
                      ? formatDateApi(scheduleOptions[0]?.scheduledAtDate)
                      : null
                  }
                  disablePast={false}
                  usFormat={channel?.useUsDateFormat}
                  autoOk
                />
              </Box>
            </Box>
          </Paper>

          <Grid container spacing={5}>
            <Grid item xs={12} md={6}>
              {!!watch && (
                <SectionsProgramming
                  workout={workouts[0]}
                  watch={watch}
                  setValue={setValue}
                  setCurrentSection={setCurrentSection}
                  sections={sections}
                  channelId={channel?.id}
                  setSections={setSections}
                  currentSection={currentSection}
                  register={register}
                  programs={allPrograms()}
                />
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <Box mb={3}>
                <CloneContent
                  watch={watch}
                  setValue={setValue}
                  workout={workouts[0]}
                  channelId={channel?.id}
                  scheduleOptions={scheduleOptions}
                />
              </Box>
            </Grid>
          </Grid>

          <Box display="flex" justifyContent="flex-end" mt={3}>
            <ButtonPrimary loading={loading} onClick={() => submitForm()}>
              <LoaderSm loading={loading} />
              {t('button.save').toUpperCase()}
            </ButtonPrimary>
          </Box>
        </Box>
      </Texture>
    </Dialog>
  );
};

ModalCloneWorkoutsToWorkout.propTypes = {
  close: PropTypes.func,
};

ModalCloneWorkoutsToWorkout.defaultProps = {
  close: () => {},
};

export default ModalCloneWorkoutsToWorkout;
