import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import Box from 'core/ui/Box';
import Texture from 'core/ui/Texture';
import { useTranslation } from 'react-i18next';
import { ButtonPrimarySm } from 'core/ui/Button';
import { Typography } from '@material-ui/core';
import Form from './Form';

const ModalAddMembers = ({ channelId, close, callbackSave, form }) => {
  const { t } = useTranslation();
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    if (form) {
      setShowForm(true);
    }
  }, [form]);

  return (
    <Dialog open onClose={close} maxWidth={showForm ? 'md' : 'xs'} fullWidth scroll="body">
      <Texture>
        <Box
          p={5}
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
        >
          {showForm ? (
            <Box>
              <Form channelId={channelId} close={close} modalForm callbackSave={callbackSave} />
            </Box>
          ) : (
            <>
              <Link to="importMembers">
                <ButtonPrimarySm>Import Users From CSV</ButtonPrimarySm>
              </Link>

              <Box my={3}>
                <Typography variant="h3" color="primary">
                  {t('or')}
                </Typography>
              </Box>
              <Box>
                <ButtonPrimarySm p={1} onClick={() => setShowForm(true)}>
                  {t('button.addUserManually')}
                </ButtonPrimarySm>
              </Box>
            </>
          )}
        </Box>
      </Texture>
    </Dialog>
  );
};

ModalAddMembers.propTypes = {
  close: PropTypes.func,
};

ModalAddMembers.defaultProps = {
  close: () => {},
};

export default ModalAddMembers;
