import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import Typography from 'core/ui/Typography';

import { useSelector } from 'react-redux';

import { competitionsSelectors } from 'modules/competitions';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import { copyToClipBoard } from 'core/utils/helpers';
import { primary } from 'core/ui/Colors';
import { FileCopy } from '@material-ui/icons';

export default function Success() {
  const { t } = useTranslation();
  const competition = useSelector((state) => competitionsSelectors.getCompetition(state));

  return (
    <Box px={5}>
      <Typography variant="h4">{t('Share this link for competition registration')}</Typography>

      <Box display="flex" alignItems="center" mt={2}>
        <Box display="flex" flexGrow="1">
          <TextFieldRounded
            style={{ color: primary, fontSize: 18 }}
            disabled
            value={competition.shareUrl}
          />
        </Box>
        <Box display="flex" pt={2} ml={1}>
          <Box onClick={() => copyToClipBoard(competition.shareUrl)}>
            <FileCopy style={{ color: primary, fontSize: 18 }} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
