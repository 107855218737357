import React from 'react';
import PropTypes from 'prop-types';
import Box from 'core/ui/Box';
import { ButtonPrimary } from 'core/ui/Button';
import LoaderSm from 'core/ui/LoaderSm';
import { useTranslation } from 'react-i18next';
import Grid from 'core/ui/Grid';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import Checkbox from 'core/ui/Checkbox';
import useFormPlans from './useForm';

const FormPartner = ({ plan, close, callbackSave }) => {
  const { t } = useTranslation();
  const { loading, errors, setValue, watch, submit } = useFormPlans({
    plan,
    callbackSave,
    close,
  });

  return (
    <Box flexGrow={1}>
      <Grid container spacing={5}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <TextFieldRounded
            label={t('name').toUpperCase()}
            value={watch('name')}
            onChange={(v) => setValue('name', v)}
            error={errors?.name}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Checkbox
            checked={watch('active')}
            label={t('active')}
            onClick={() => setValue('active', !watch('active'))}
          />
        </Grid>
      </Grid>
      <Grid container spacing={5}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Box mb={4}>
            <TextFieldRounded
              placeholder="0,00"
              label={t('channel.plan.in_person').toUpperCase()}
              value={watch('amountInPerson')}
              onChange={(v) => setValue('amountInPerson', v)}
              error={errors?.amountInPerson}
              type="number"
              pattern="[1-9]*"
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Box mb={4}>
            <TextFieldRounded
              placeholder="0,00"
              label={t('channel.plan.online').toUpperCase()}
              value={watch('amount')}
              onChange={(v) => setValue('amount', v)}
              error={errors?.amount}
              type="number"
              pattern="[1-9]*"
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={5}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Box mb={4}>
            <TextFieldRounded
              placeholder="0,00"
              label={t('channel.plan.member_app').toUpperCase()}
              value={watch('amountMemberApp')}
              onChange={(v) => setValue('amountMemberApp', v)}
              error={errors?.amountMemberApp}
              type="number"
              pattern="[1-9]*"
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Box mb={4}>
            <TextFieldRounded
              placeholder="0,00"
              label={t('channel.plan.competition').toUpperCase()}
              value={watch('amountCompetition')}
              onChange={(v) => setValue('amountCompetition', v)}
              error={errors?.amountCompetition}
              type="number"
              pattern="[1-9]*"
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Box mb={4}>
            <TextFieldRounded
              placeholder="0,00"
              label={t('channel.plan.sifely').toUpperCase()}
              value={watch('amountSifely')}
              onChange={(v) => setValue('amountSifely', v)}
              error={errors?.amountSifely}
              type="number"
              pattern="[1-9]*"
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Box mb={4}>
            <TextFieldRounded
              placeholder="0,00"
              label={t('channel.plan.engage').toUpperCase()}
              value={watch('amountEngage')}
              onChange={(v) => setValue('amountEngage', v)}
              error={errors?.amountEngage}
              type="number"
              pattern="[1-9]*"
            />
          </Box>
        </Grid>
      </Grid>

      <Box mb={5} display="flex" justifyContent="flex-end">
        <ButtonPrimary
          type="button"
          onClick={() => submit()}
          size="small"
          disabled={loading}
          style={{ height: 30, width: 80, borderRadius: 5 }}
        >
          <LoaderSm loading={loading} width={20} />
          {t('button.save')}
        </ButtonPrimary>
      </Box>
    </Box>
  );
};

FormPartner.propTypes = {
  close: PropTypes.func.isRequired,
};

FormPartner.defaultProps = {};

export default FormPartner;
