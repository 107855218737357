import React, { useContext, useState } from 'react';
import { StreamContext } from 'pages/Stream';
import { useSelector } from 'react-redux';
import FontIcon from 'core/ui/FontIcon';
import Button from 'core/ui/Button';

import ConfirmDialog from 'core/ui/ConfirmDialog';
import { useTranslation } from 'react-i18next';
import { workoutsSelectors } from 'modules/workouts';
import { useHistory } from 'react-router';

export default ({ exit }) => {
  const streamContext = useContext(StreamContext);
  const [dialogCancel, setDialogCancel] = useState(false);
  const { t } = useTranslation();
  const workout = useSelector((state) => workoutsSelectors.getWorkout(state));

  const history = useHistory();

  const handleExit = () => {
    setDialogCancel({
      open: true,
      callbackYes: () => {
        if (workout.isCompetition) {
          streamContext.setOpenModalEnded((prev) => ({
            ...prev,
            open: true,
            edit: true,
            cancel: true,
          }));
        } else {
          workout.live ? exit(true) : history.push(`/workout/view/${workout.slug}`);
        }
      },
      callbackNo: () => {
        if (workout.isCompetition) {
          workout.live ? exit(true) : history.push(`/workout/view/${workout.slug}`);
        }
      },
    });
  };

  return (
    <>
      <ConfirmDialog
        open={dialogCancel.open}
        setOpen={(b) => setDialogCancel((prev) => ({ ...prev, open: b }))}
        title={t('workout.stream.exit.title')}
        description={t(
          workout.isCompetition
            ? 'workout.stream.competition.exit'
            : 'workout.stream.exit.description',
        )}
        labelConfirm={t(workout.isCompetition ? 'button.yes' : 'confirm')}
        labelCancel={t(workout.isCompetition ? 'button.no' : 'cancel')}
        confirm={() => dialogCancel.callbackYes()}
        cancel={() => dialogCancel.callbackNo()}
      />
      <Button onClick={() => handleExit()} mb={3}>
        <FontIcon iconName="icon-exit" size={20} />
      </Button>
    </>
  );
};
