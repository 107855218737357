import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogActions,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import Box from 'core/ui/Box';
import Texture from 'core/ui/Texture';
import { ButtonPrimary } from 'core/ui/Button';
import Paper from 'core/ui/Paper';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import useSelectors from 'modules/map/useSelectors';
import { newChannelsModule } from 'modules/channels/new';
import LoaderSm from 'core/ui/LoaderSm';
import DialogTitle from 'core/ui/Dialog/DialogTitle';
import Typography from 'core/ui/Typography';
import { date, dateExt, formatDateToApi, timeWithoutTz } from 'core/utils/formatters';
import DatePickerRounded from 'core/ui/DatePickers/DatePickerRounded';
import { redColor } from 'core/ui/Colors';
import { newWorkoutsModule } from 'modules/workouts/new';
import { Check } from '@material-ui/icons';

export default function ModalAddCoach({ close, onAddCoach = () => {}, workout, buttonColor }) {
  const { t } = useTranslation();
  const [channelCoaches, setChannelCoaches] = useState([]);
  const loadingCoaches = useSelectors(newChannelsModule, 'loadingCoaches');
  const loadingAddCoach = useSelectors(newWorkoutsModule, 'loadingAddCoach');
  const { request } = useActions();

  const [action, setAction] = useState({ view: 'coaches', coach: null });

  useEffect(() => {
    if (workout.channel) {
      request({
        action: Modules.newChannels.actions.getChannelCoaches,
        data: {
          channelId: workout.channel?.id,
          workoutId: workout.id,
        },
        options: {
          onSuccess: setChannelCoaches,
        },
      });
    }
  }, [request, workout]);

  const addCoach = (userId, recurring = false) => {
    request({
      action: Modules.newWorkouts.actions.addCoachWorkout,
      data: {
        workoutId: workout?.id,

        endDateApplyCoach: action?.endDateApplyCoach
          ? formatDateToApi(action.endDateApplyCoach)
          : null,
        recurring,
        userId: userId || action?.coach?.id,
      },
      options: {
        onSuccess: (data) => {
          if (recurring) {
            setAction({ view: 'success', workouts: data?.workouts });
          } else {
            close();
          }
          onAddCoach();
        },
      },
    });
  };

  return (
    <Dialog open maxWidth="md" fullWidth onClose={() => close()}>
      <DialogTitle onClose={close}>{t('workout.modal.addCoaches')}</DialogTitle>
      <Texture>
        <Box p={3}>
          <Paper p={3}>
            <LoaderSm loading={loadingCoaches} center />

            {action?.view === 'coaches' && (
              <TableContainer>
                <Table size="small">
                  <TableBody>
                    {channelCoaches &&
                      channelCoaches
                        .filter((x) =>
                          ['admin_coach', 'coach_head', 'coach_owner', 'coach'].find(
                            (y) => y === x.accessType,
                          ),
                        )
                        .filter((x) => !workout.coaches.find((y) => y.id === x.user.id))
                        .map((coach) => (
                          <TableRow key={coach.id}>
                            <TableCell>{coach.user.name}</TableCell>
                            <TableCell>
                              <div className="tw-flex tw-items-center">
                                <Tooltip title={t('tooltip.usersCalendar.feature')}>
                                  <div
                                    className={`tw-flex tw-w-20 tw-items-center tw-justify-center tw-rounded-md tw-p-1 tw-text-white ${coach.calendarAvailibility ? 'tw-bg-green-400 ' : 'tw-bg-red-400 '}`}
                                  >
                                    {t(
                                      coach.calendarAvailibility
                                        ? 'label.available'
                                        : 'label.unavailable',
                                    )}
                                  </div>
                                </Tooltip>
                              </div>
                            </TableCell>
                            <TableCell align="right">
                              <div className="tw-flex tw-flex-row tw-items-center tw-justify-end tw-gap-2">
                                <ButtonPrimary
                                  buttonColor={buttonColor}
                                  onClick={() => addCoach(coach.user.id)}
                                >
                                  {t('button.addToThisClass')}
                                </ButtonPrimary>
                                {workout?.recurringId && (
                                  <ButtonPrimary
                                    buttonColor={buttonColor}
                                    onClick={() =>
                                      setAction({
                                        view: 'recurring',
                                        coach: coach.user,
                                        applyToAll: true,
                                      })
                                    }
                                  >
                                    {t('button.addToRecurring')}
                                  </ButtonPrimary>
                                )}
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}

            {action?.view === 'recurring' && (
              <div className="tw-flex tw-flex-col tw-gap-2">
                <Typography variant="h5">{action?.coach?.name}</Typography>

                <Typography className="tw-text-gray-500">
                  {t('label.workout.addCoachToTimes')}
                </Typography>
                <Typography className="tw-text-gray-500">
                  {workout?.channelsWorkoutsType?.name}
                </Typography>
                <Typography className="tw-text-gray-500">
                  {timeWithoutTz(workout?.scheduledAt)}
                </Typography>

                <div>
                  <div className="tw-flex tw-flex-row tw-gap-2">
                    <div>
                      <DatePickerRounded
                        label={t('label.startDate').toUpperCase()}
                        onChange={null}
                        value={date(workout?.scheduledAt)}
                        disablePast={false}
                        disabled
                        usFormat={workout?.channel?.useUsDateFormat}
                        autoOk
                        bordered
                      />
                    </div>
                    <DatePickerRounded
                      label={t('label.endDate').toUpperCase()}
                      onChange={(v) => setAction((prev) => ({ ...prev, endDateApplyCoach: v }))}
                      value={action.endDateApplyCoach}
                      disablePast={false}
                      usFormat={workout?.channel?.useUsDateFormat}
                      autoOk
                      bordered
                    />
                  </div>
                </div>
              </div>
            )}

            {action?.view === 'success' && (
              <div className="tw-flex tw-flex-col tw-gap-2">
                <Typography variant="h5">{action?.coach?.name}</Typography>

                <div className="tw-flex tw-flex-col tw-items-center">
                  <Check style={{ fontSize: 30 }} className="tw-text-green-500" />
                  <Typography variant="h5" className="tw-text-green-500">
                    {t('label.workout.coachAddedInTheWorkouts')}
                  </Typography>
                  <Typography className="tw-text-gray-500">
                    {t('label.workout.coachAddedInTheWorkouts.text')}
                  </Typography>
                </div>

                <div className="tw-m-2 tw-flex tw-flex-col tw-gap-2">
                  {action?.workouts?.map((w) => (
                    <div key={w.id}>
                      <Link href={`/workout/view/${w?.id}`} target="_blank">
                        {w?.name}
                      </Link>
                      <Typography className="tw-ml-2 tw-text-xs" variant="caption" component="span">
                        {dateExt(w.scheduledAt, t, workout?.channel?.dateFormatExtReact)} &bull;
                      </Typography>
                      <Typography className="tw-ml-1 tw-text-xs" variant="caption" component="span">
                        {timeWithoutTz(w.scheduledAt)}
                      </Typography>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </Paper>
        </Box>
      </Texture>
      {action?.view === 'recurring' && (
        <DialogActions>
          <LoaderSm loading={loadingAddCoach} />
          <ButtonPrimary
            disabled={loadingAddCoach}
            buttonColor={redColor}
            onClick={() => setAction({ view: 'coaches' })}
          >
            {t('button.cancel')}
          </ButtonPrimary>
          <ButtonPrimary
            disabled={loadingAddCoach}
            onClick={() => addCoach(action?.coach.id, true)}
          >
            {t('button.confirm')}
          </ButtonPrimary>
        </DialogActions>
      )}
    </Dialog>
  );
}

ModalAddCoach.propTypes = {};

ModalAddCoach.defaultProps = {};
