import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import humps from 'humps';
import { BASE_API_URL_SOCKET } from 'core/env';
import { workoutsActions } from 'modules/workouts';
import { retrieveData } from 'core/utils/session-storage';
import { retrieveAuthHeaders } from 'core/api/auth-headers';

const actioncable = require('actioncable');

function useWorkoutUsersSocket({ workoutId }) {
  const dispatch = useDispatch();

  const received = (e) => {
    const users = humps.camelizeKeys(JSON.parse(e.data.users));
    if (e.data.type === 'new_user') {
      dispatch(workoutsActions.setWorkoutUsers(users));
    }
  };

  useEffect(() => {
    const logAuth = retrieveAuthHeaders(retrieveData)();
    const { client, uid } = logAuth;

    const authHeaders = {
      'access-token': logAuth['access-token'],
      client,
      uid,
    };
    const queryString = new URLSearchParams(authHeaders).toString();

    const cable = actioncable.createConsumer(`${BASE_API_URL_SOCKET}?${queryString}`);
    const channelCable = cable.subscriptions.create(
      {
        channel: 'WorkoutUsersChannel',
        workout_id: workoutId,
      },
      {
        connected: () => {
          console.log('connected!');
        },
        disconnected: () => {},
        received: (data) => {
          received(data);
        },
      },
    );

    return () => {
      channelCable.unsubscribe();
      cable.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workoutId]);

  return {};
}

export default useWorkoutUsersSocket;
