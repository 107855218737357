import React, { useContext, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from 'core/ui/Typography';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { roomSelectors } from 'modules/room';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import SignalWifi1BarIcon from '@material-ui/icons/SignalWifi1Bar';
import { StreamContext } from 'pages/Stream';

const useStyles = makeStyles({
  root: {
    position: 'absolute',
    textAlign: 'center',
    top: 10,
    left: 10,
    zIndex: 9999,
  },
  boxMessage: {
    background: 'rgba(0, 0, 4, 0.3)',
    borderRadius: 5,
  },
});

export default function ConnectionQuality() {
  const classes = useStyles();
  const connectionQuality = useSelector((state) => roomSelectors.getConnectionQuality(state));
  const userRoom = useSelector((state) => roomSelectors.getUser(state));
  const [camTurnOn, setCamTurnOn] = useState({
    userOff: false,
    autoOff: false,
  });
  const streamContext = useContext(StreamContext);

  const permissionRef = useRef(streamContext.controlPermission.changeVideo);
  const { t } = useTranslation();
  const tmTurnOffRef = useRef();
  const tmRef = useRef();

  const [viewMessage, setViewMessage] = useState({
    view: false,
    quality: 5,
  });

  useEffect(() => {
    permissionRef.current = streamContext.controlPermission.changeVideo;
  });

  useEffect(() => {
    if (connectionQuality.newValue) {
      clearTimeout(tmRef.current);
      tmRef.current = setTimeout(() => {
        setViewMessage((prev) => ({
          ...prev,
          view: false,
        }));
      }, 5000);
      setViewMessage({
        view: true,
        quality: connectionQuality.newValue,
      });
    }
  }, [connectionQuality]);

  useEffect(() => {
    clearTimeout(tmTurnOffRef.current);
    if (connectionQuality.newValue) {
      if (userRoom.videoActive && connectionQuality.newValue <= 1) {
        tmTurnOffRef.current = setTimeout(() => {
          // camStatusChange();
          setViewMessage((prev) => ({
            ...prev,
            view: true,
            // message: t('workout.stream.quality.turnOfCam'),
          }));
          setCamTurnOn({ userOff: false, autoOff: true });
        }, 6000);
      }

      if (!userRoom.videoActive && connectionQuality.newValue >= 2 && camTurnOn) {
        tmTurnOffRef.current = setTimeout(() => {
          if (permissionRef.current) {
            // camStatusChange();
            setViewMessage((prev) => ({ ...prev, view: false }));
            setCamTurnOn((prev) => ({ ...prev, autoOff: false }));
          }
        }, 6000);
      }
    }
  }, [connectionQuality]); //eslint-disable-line

  useEffect(() => {
    if (!userRoom.videoActive) {
      setCamTurnOn((prev) => ({ ...prev, userOff: true }));
    }
  }, [userRoom.videoActive]);

  return (
    <>
      {viewMessage.view === true && viewMessage.quality <= 2 && (
        <Box className={classes.root}>
          <SwitchTransition mode="out-in">
            <CSSTransition
              addEndListener={(node, done) => {
                node.addEventListener('transitionend', done, false);
              }}
              classNames="fade"
              key={viewMessage.view}
            >
              <>
                <Box px={3} py={1} className={classes.boxMessage}>
                  <Box display="flex" alignItems="center">
                    <SignalWifi1BarIcon style={{ color: 'white' }} />
                    <Typography ml={2} style={{ color: 'white' }}>
                      {viewMessage.message || t('workout.stream.quality.bad')}
                    </Typography>
                  </Box>
                </Box>
              </>
            </CSSTransition>
          </SwitchTransition>
        </Box>
      )}
    </>
  );
}

ConnectionQuality.propTypes = {};

ConnectionQuality.defaultProps = {};
