import React, { useRef, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography, { fontStyle } from 'core/ui/Typography';
import PropTypes from 'prop-types';
import Box from 'core/ui/Box';
import Rating from 'core/ui/Rating';
import { useTranslation } from 'react-i18next';
import ImageMediaUser from 'components/Profile/ImageMedia';
import { primary } from 'core/ui/Colors';
import useWindowSize from 'core/utils/hook/useWindowSize';
import InstagramLink from 'components/Profile/InstagramLink';

const useStyles = makeStyles({
  root: {
    backgroundColor: 'transparent',
    display: 'flex',
  },
  boxTag: {
    background: primary,
    position: 'absolute',
    bottom: 0,
    right: 0,
    borderBottomRightRadius: 10,
  },
});

export default function CardCoach({ coach, fullWidth }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const refBox = useRef();
  const sizeWindow = useWindowSize();
  const [size, setSize] = useState(120);

  useEffect(() => {
    if (fullWidth) {
      if (refBox.current) {
        setSize(refBox.current.clientWidth);
      }
    }
  }, [refBox, sizeWindow, fullWidth]);

  return (
    <Box className={classes.root} ref={refBox}>
      <Box>
        <Box style={{ position: 'relative' }}>
          <ImageMediaUser
            user={coach}
            image={coach.image ? coach.image : {}}
            size={size}
            heightCountry={34}
          />
          <Box className={classes.boxTag}>
            <Typography mx={1} style={{ color: 'white', ...fontStyle.small }}>
              {t('coach')}
            </Typography>
          </Box>
        </Box>

        <Box display="flex">
          <Box flexGrow="1">
            <Typography color="primary" variant="caption" component="p">
              {coach.firstName}
            </Typography>
            <InstagramLink instagram={coach.instagram} />
            <Rating
              readOnly
              value={coach.rating ? coach.rating.avg : 0}
              size="small"
              style={{ fontSize: 12 }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

CardCoach.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  coach: PropTypes.object,
  fullWidth: PropTypes.bool,
};

CardCoach.defaultProps = {
  coach: {},
  fullWidth: false,
};
