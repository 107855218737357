/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Box from 'core/ui/Box';
import FontIcon from 'core/ui/FontIcon';
import Typography from 'core/ui/Typography';
import { roomActions, roomSelectors } from 'modules/room';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import { ControlCamera } from '@material-ui/icons';
import { primary, onlineColor } from 'core/ui/Colors';
import useEvents from 'core/openVidu/useEvents';
import ImageMedia from 'components/Profile/ImageMedia';
import LiveIcon from 'core/ui/LiveIcon';
import CastIcon from '@material-ui/icons/Cast';
import { workoutsSelectors } from 'modules/workouts';

const OvVideoComponent = ({
  user,
  focused,
  setFocused,
  streamManager,
  style,
  showOnline,
  showControls,
  showMove,
  showClose,
  showLiveIcon,
}) => {
  const dispatch = useDispatch();
  const videoRef = useRef();
  const { isCoach, muteUser, hideCamUser } = useEvents();
  const config = useSelector((state) => roomSelectors.getConfig(state));
  const workout = useSelector((state) => workoutsSelectors.getWorkout(state));

  useEffect(() => {
    if (streamManager && videoRef.current) {
      streamManager.addVideoElement(videoRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (config.deviceSpeaker && videoRef.current && videoRef.current.setSinkId) {
      videoRef.current.setSinkId(config.deviceSpeaker.deviceId);
    }
  }, [config.deviceSpeaker]);

  const removeScreen = () => {
    dispatch(roomActions.removeParticipantScreen(user.id));
  };

  const boxOnline = {
    position: 'absolute',
    marginTop: -28.6,
    height: 25,
    bottom: 0,
    marginLeft: 0,
    background: '#181818',
    borderTopRightRadius: 10,
    borderBottomLeftRadius: 10,
  };

  const boxControlsOff = {
    position: 'absolute',
    marginLeft: 10,
    marginTop: 20,
    zIndex: 1000,
  };

  const boxClose = {
    position: 'absolute',
    zIndex: 1000,
  };

  const boxImage = {
    position: 'absolute',
    zIndex: 1000,
    top: 0,
    height: '100%',
    width: '100%',
  };

  const mute = () => {
    if (!isCoach()) {
      return;
    }
    muteUser(user.id);
  };

  const hideCam = () => {
    if (!isCoach()) {
      return;
    }
    hideCamUser(user.id);
  };

  return (
    <Box
      style={{
        width: style.width,
        height: style.height,
        backgroundColor: '#000',
        position: 'relative',
        borderRadius: 10,
      }}
    >
      {showLiveIcon && (
        <Box
          mr={1.5}
          style={{ position: 'absolute', top: 0, right: 0, zIndex: 1000 }}
          className="move"
        >
          <LiveIcon />
        </Box>
      )}
      {showControls && (
        <Box style={{ ...boxControlsOff }} pr={50}>
          {showMove && (
            <>
              <Box style={{ position: 'absolute', top: -15, cursor: 'move' }} className="move">
                <ControlCamera style={{ color: 'white', fontSize: 25 }} />
              </Box>
              <Box pt={10}>&nbsp;</Box>
            </>
          )}
        </Box>
      )}
      {showClose === true && (
        <Box display="flex" style={{ maxWidth: style.width }} justifyContent="flex-end">
          <Box style={{ ...boxClose }} pt={1.5} pr={1.5}>
            <Button onClick={() => removeScreen()}>
              <FontIcon iconName="icon-close" color="white" size={14} />
            </Button>
          </Box>
        </Box>
      )}

      {!user.videoActive && (
        <Box style={boxImage} display="flex" justifyContent="center" alignItems="center">
          <Box
            style={{
              position: 'absolute',
              border: '10px solid rgba(0, 0, 0, 0.5)',
              borderRadius: 5,
              height: 50,
              width: 50,
              zIndex: 1000,
            }}
          />
          <ImageMedia showCountry={false} user={user} image={user.image || {}} size={60} />
        </Box>
      )}

      <video
        style={{
          objectFit: 'cover',
          ...style,
          display: user.videoActive ? 'block' : 'none',
          backgroundColor: '#000',
        }}
        autoPlay
        ref={videoRef}
      />
      {showOnline === true && (
        <Box display="flex" alignItems="center">
          <Box style={{ ...boxOnline }} pb={1} px={2} display="flex" alignItems="center">
            <Box pl={1} py={1} pr={2}>
              <FontIcon iconName="icon-online" size={6} />
            </Box>
            <Box px={1}>
              <Typography
                className="opensans-bold"
                component="span"
                style={{ color: 'white', fontSize: 9 }}
              >
                {user.firstName}
              </Typography>
            </Box>
            {showControls && (
              <Box display="flex" alignItems="center" pt={0.5}>
                <Box m={1} className="link" onClick={() => hideCam()}>
                  <FontIcon
                    iconName={!user.videoActive ? 'icon-cam-off' : 'icon-cam-on'}
                    color={!user.videoActive ? primary : onlineColor}
                    size={10}
                  />
                </Box>
                <Box m={1} className="link" onClick={() => mute()}>
                  <FontIcon
                    iconName={!user.audioActive ? 'icon-microphone-off' : 'icon-microphone-on'}
                    color={!user.audioActive ? primary : onlineColor}
                    size={10}
                  />
                </Box>
                {workout.isCoach && workout.live && !workout.recorded && setFocused && (
                  <Box m={1} pt={0.3} className="link" onClick={() => setFocused(user.id)}>
                    <CastIcon style={{ color: !focused ? primary : onlineColor, fontSize: 11 }} />
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

OvVideoComponent.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  showControls: PropTypes.bool,
  showOnline: PropTypes.bool,
  showClose: PropTypes.bool,
  showMove: PropTypes.bool,
};

OvVideoComponent.defaultProps = {
  user: {},
  style: {},
  showControls: false,
  showOnline: false,
  showClose: false,
  showMove: false,
};

export default OvVideoComponent;
