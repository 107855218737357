/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { Box, Link } from '@material-ui/core';
import humps from 'humps';
import { useTranslation } from 'react-i18next';
import { ButtonPrimary } from 'core/ui/Button';
import Typography, { fontStyle } from 'core/ui/Typography';
import LoaderSm from 'core/ui/LoaderSm';
import Toggle from 'core/ui/Toggle';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import { BASE_API_URL_SOCKET } from 'core/env';
import Modules from 'modules';
import useActions from 'modules/map/useActions';
import { retrieveData } from 'core/utils/session-storage';
import { retrieveAuthHeaders } from 'core/api/auth-headers';
import { PaymentMethodViewType } from '../Selector';

const actioncable = require('actioncable');

function AchForm({ setView, setSelectedPaymentMethod, closeModal, bordered, paymentMethod }) {
  const stripe = useStripe();
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { request } = useActions();
  const [data, setData] = useState({
    zipCode: '',
    routingNumber: '',
    accountNumber: '',

    transitNumber: '',
    institutionNumber: '',

    accountType: 'checking',
    accountHolderType: 'individual',
  });

  const intervalRefresh = useRef();
  const intervalRefreshCount = useRef(0);

  const checkInterval = (paymentResult) => {
    intervalRefreshCount.current = 0;
    intervalRefresh.current = setInterval(() => {
      intervalRefreshCount.current += 1;
      if (intervalRefreshCount.current >= 8) {
        request({
          action: Modules.userPaymentMethods.actions.getPaymentMethodBySetupIntent,
          data: {
            // eslint-disable-next-line camelcase
            setupIntentId: paymentResult?.setupIntent?.id,
          },
          options: {
            onSuccess: (method) => {
              if (setSelectedPaymentMethod) {
                setSelectedPaymentMethod(method);
              }
              if (setView) {
                setView(PaymentMethodViewType.list);
              }
              request({
                action: Modules.userPaymentMethods.actions.addPaymentMethod,
                data: method,
              });
              if (closeModal) {
                closeModal();
              }
              // Must be there, the user can close the modal before the stripe request completes
              clearInterval(intervalRefresh.current);
            },
          },
        });

        // window.location.reload(false);
      }
    }, 1000);
  };

  useEffect(
    () => () => {
      clearInterval(intervalRefresh.current);
    },
    [],
  );

  useEffect(() => {
    let cable = null;
    let channelCable = null;

    const logAuth = retrieveAuthHeaders(retrieveData)();
    const { client, uid } = logAuth;

    const authHeaders = {
      'access-token': logAuth['access-token'],
      client,
      uid,
    };
    const queryString = new URLSearchParams(authHeaders).toString();

    cable = actioncable.createConsumer(`${BASE_API_URL_SOCKET}?${queryString}`);
    channelCable = cable.subscriptions.create(
      {
        channel: 'PaymentMethodsChannel',
        user_id: paymentMethod?.userId,
      },
      {
        connected: () => {
          console.log('connected!');
        },
        disconnected: () => {},
        received: (resp) => {
          setLoading(false);
          const method = humps.camelizeKeys(resp.data.method);
          if (setSelectedPaymentMethod) {
            setSelectedPaymentMethod(method);
          }
          if (setView) {
            setView(PaymentMethodViewType.list);
          }
          request({
            action: Modules.userPaymentMethods.actions.addPaymentMethod,
            data,
          });
          if (closeModal) {
            closeModal();
          }
        },
      },
    );

    return () => {
      if (channelCable) {
        channelCable.unsubscribe();
      }
      if (cable) {
        cable.disconnect();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethod?.userId]);

  useEffect(() => {
    if (paymentMethod?.user) {
      setData((prev) => ({
        ...prev,
        userName: paymentMethod?.user?.name,
        email: paymentMethod?.user?.email,
        phone: paymentMethod?.user?.phone,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethod?.user]);

  const usBanckAccount = async () =>
    stripe.confirmUsBankAccountSetup(paymentMethod?.clientSecret, {
      payment_method: {
        type: 'us_bank_account',
        us_bank_account: {
          routing_number: data.routingNumber,
          account_type: data.accountType,
          account_number: data.accountNumber,
          account_holder_type: 'individual',
        },
        billing_details: {
          name: data?.userName,
          phone: data?.phone,
          email: data?.email,
          address: {
            postal_code: data.zipCode,
          },
        },
      },
    });

  const canadaBankAccount = async () =>
    stripe.confirmAcssDebitSetup(paymentMethod?.clientSecret, {
      payment_method: {
        type: 'acss_debit',
        acss_debit: {
          account_number: data.accountNumber,
          institution_number: data?.institutionNumber,
          transit_number: data.transitNumber,
        },
        billing_details: {
          name: data?.userName,
          phone: data?.phone,
          email: data?.email,
          address: {
            postal_code: data.zipCode,
          },
        },
      },
    });

  const sepaBankAccount = async () =>
    stripe.confirmSepaDebitSetup(paymentMethod?.clientSecret, {
      payment_method: {
        type: 'sepa_debit',
        sepa_debit: {
          iban: data.iban,
        },
        billing_details: {
          name: data?.userName,
          phone: data?.phone,
          email: data?.email,
          address: {
            postal_code: data.zipCode,
          },
        },
      },
    });

  const handleSubmit = async (e) => {
    if (!data?.userName || !data?.phone || !data?.email) {
      setErrorMessage('Please add the user information.');
      return;
    }
    setLoading(true);
    try {
      e.preventDefault();

      let funcMethod = null;
      if (paymentMethod?.achType === 'us_bank_account') {
        funcMethod = usBanckAccount;
      } else if (paymentMethod?.achType === 'acss_debit') {
        funcMethod = canadaBankAccount;
      } else if (paymentMethod?.achType === 'sepa_debit') {
        funcMethod = sepaBankAccount;
      }

      const paymentResult = await funcMethod();
      if (paymentResult.error) {
        setLoading(false);
        setErrorMessage(paymentResult.error.message);
      } else {
        const respPayment = {
          ...((paymentResult?.setupIntent?.next_action &&
            paymentResult?.setupIntent?.next_action[
              paymentResult?.setupIntent?.next_action?.type
            ]) ||
            {}),
        };

        const nextStepUrl = respPayment.hosted_verification_url;
        if (nextStepUrl) {
          setData((prev) => ({ ...prev, stepVerificationUrl: nextStepUrl }));
          window.location = nextStepUrl;
        }

        checkInterval(paymentResult);
      }
    } catch (ex) {
      console.log('ex', ex);
      setLoading(false);
    }
  };

  const styleInput = {
    width: '100%',
  };

  if (data?.stepVerificationUrl) {
    return (
      <Box m={3} display="flex" justifyContent="center">
        <Link
          href={data?.stepVerificationUrl}
          target="blank"
          onClick={() => closeModal()}
          className="hover"
          style={fontStyle.xxLarge}
        >
          {t('paymentMethod.verifyAchAccount')}
        </Link>
      </Box>
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        {paymentMethod?.achType === 'us_bank_account' && (
          <>
            <Box mb={1} style={{ ...styleInput }}>
              <TextFieldRounded
                className="ph-stripe"
                style={{ height: 20 }}
                paperStyle={{ paddingLeft: 10 }}
                placeholder={t('label.userName')}
                value={data?.userName}
                onChange={(value) => setData((prev) => ({ ...prev, userName: value }))}
                bordered={bordered}
              />
            </Box>
            <Box mb={1} style={{ ...styleInput }}>
              <TextFieldRounded
                className="ph-stripe"
                style={{ height: 20, width: '100%' }}
                paperStyle={{ paddingLeft: 10 }}
                placeholder={t('label.email')}
                value={data?.email}
                onChange={(value) => setData((prev) => ({ ...prev, email: value }))}
                bordered={bordered}
              />
            </Box>
            <Box mb={1} style={{ ...styleInput }}>
              <TextFieldRounded
                className="ph-stripe"
                style={{ height: 20 }}
                paperStyle={{ paddingLeft: 10 }}
                placeholder={t('label.phone')}
                value={data?.phone}
                onChange={(value) => setData((prev) => ({ ...prev, phone: value }))}
                bordered={bordered}
              />
            </Box>
            <Box mb={1} style={{ ...styleInput }}>
              <TextFieldRounded
                className="ph-stripe"
                style={{ height: 20 }}
                paperStyle={{ paddingLeft: 10 }}
                placeholder={t('paymentMethod.routingNumber')}
                value={data?.routingNumber}
                onChange={(value) => setData((prev) => ({ ...prev, routingNumber: value }))}
                bordered={bordered}
              />
            </Box>
            <Box mb={1} style={{ ...styleInput }}>
              <TextFieldRounded
                className="ph-stripe"
                style={{ height: 20 }}
                paperStyle={{ paddingLeft: 10 }}
                placeholder={t('paymentMethod.accountNumber')}
                value={data?.accountNumber}
                onChange={(value) => setData((prev) => ({ ...prev, accountNumber: value }))}
                bordered={bordered}
              />
            </Box>
            <Box mb={1} style={{ ...styleInput }}>
              <TextFieldRounded
                className="ph-stripe"
                style={{ height: 20 }}
                paperStyle={{ paddingLeft: 10 }}
                placeholder={t('postalCode')}
                value={data?.zipCode}
                onChange={(value) => setData((prev) => ({ ...prev, zipCode: value }))}
                bordered={bordered}
              />
            </Box>
            <Box mb={1} mt={2}>
              <Toggle
                options={[
                  {
                    label: t('paymentMethod.accountType.savings'),
                    value: 'savings',
                    width: 120,
                  },
                  {
                    label: t('paymentMethod.accountType.checkings'),
                    value: 'checking',
                    width: 120,
                  },
                ]}
                value={data?.accountType}
                onChange={(value) => setData((prev) => ({ ...prev, accountType: value }))}
              />
            </Box>
          </>
        )}

        {paymentMethod?.achType === 'acss_debit' && (
          <>
            <Box mb={1} style={{ ...styleInput }}>
              <TextFieldRounded
                className="ph-stripe"
                style={{ height: 20 }}
                paperStyle={{ paddingLeft: 10 }}
                placeholder={t('label.userName')}
                value={data?.userName}
                onChange={(value) => setData((prev) => ({ ...prev, userName: value }))}
                bordered={bordered}
              />
            </Box>
            <Box mb={1} style={{ ...styleInput }}>
              <TextFieldRounded
                className="ph-stripe"
                style={{ height: 20, width: '100%' }}
                paperStyle={{ paddingLeft: 10 }}
                placeholder={t('label.email')}
                value={data?.email}
                onChange={(value) => setData((prev) => ({ ...prev, email: value }))}
                bordered={bordered}
              />
            </Box>
            <Box mb={1} style={{ ...styleInput }}>
              <TextFieldRounded
                className="ph-stripe"
                style={{ height: 20 }}
                paperStyle={{ paddingLeft: 10 }}
                placeholder={t('label.phone')}
                value={data?.phone}
                onChange={(value) => setData((prev) => ({ ...prev, phone: value }))}
                bordered={bordered}
              />
            </Box>
            <Box mb={1} style={{ ...styleInput }}>
              <TextFieldRounded
                className="ph-stripe"
                style={{ height: 20 }}
                paperStyle={{ paddingLeft: 10 }}
                placeholder={t('paymentMethod.institutionNumber')}
                value={data?.institutionNumber}
                onChange={(value) => setData((prev) => ({ ...prev, institutionNumber: value }))}
                bordered={bordered}
                inputProps={{ maxLength: 3 }}
              />
            </Box>
            <Box mb={1} style={{ ...styleInput }}>
              <TextFieldRounded
                className="ph-stripe"
                style={{ height: 20 }}
                paperStyle={{ paddingLeft: 10 }}
                placeholder={t('paymentMethod.accountNumber')}
                value={data?.accountNumber}
                onChange={(value) => setData((prev) => ({ ...prev, accountNumber: value }))}
                bordered={bordered}
              />
            </Box>
            <Box mb={1} style={{ ...styleInput }}>
              <TextFieldRounded
                className="ph-stripe"
                style={{ height: 20 }}
                paperStyle={{ paddingLeft: 10 }}
                placeholder={t('paymentMethod.transitNumber')}
                value={data?.transitNumber}
                onChange={(value) => setData((prev) => ({ ...prev, transitNumber: value }))}
                bordered={bordered}
              />
            </Box>
            <Box mb={1} style={{ ...styleInput }}>
              <TextFieldRounded
                className="ph-stripe"
                style={{ height: 20 }}
                paperStyle={{ paddingLeft: 10 }}
                placeholder={t('postalCode')}
                value={data?.zipCode}
                onChange={(value) => setData((prev) => ({ ...prev, zipCode: value }))}
                bordered={bordered}
              />
            </Box>
          </>
        )}

        {paymentMethod?.achType === 'sepa_debit' && (
          <>
            <Box mb={1} style={{ ...styleInput }}>
              <TextFieldRounded
                className="ph-stripe"
                style={{ height: 20 }}
                paperStyle={{ paddingLeft: 10 }}
                placeholder={t('label.userName')}
                value={data?.userName}
                onChange={(value) => setData((prev) => ({ ...prev, userName: value }))}
                bordered={bordered}
              />
            </Box>
            <Box mb={1} style={{ ...styleInput }}>
              <TextFieldRounded
                className="ph-stripe"
                style={{ height: 20, width: '100%' }}
                paperStyle={{ paddingLeft: 10 }}
                placeholder={t('label.email')}
                value={data?.email}
                onChange={(value) => setData((prev) => ({ ...prev, email: value }))}
                bordered={bordered}
              />
            </Box>
            <Box mb={1} style={{ ...styleInput }}>
              <TextFieldRounded
                className="ph-stripe"
                style={{ height: 20 }}
                paperStyle={{ paddingLeft: 10 }}
                placeholder={t('label.phone')}
                value={data?.phone}
                onChange={(value) => setData((prev) => ({ ...prev, phone: value }))}
                bordered={bordered}
              />
            </Box>
            <Box mb={1} style={{ ...styleInput }}>
              <TextFieldRounded
                className="ph-stripe"
                style={{ height: 20 }}
                paperStyle={{ paddingLeft: 10 }}
                placeholder={t('paymentMethod.iban')}
                value={data?.iban}
                onChange={(value) => setData((prev) => ({ ...prev, iban: value }))}
                bordered={bordered}
              />
            </Box>
          </>
        )}

        <Box>
          <Typography color="error" variant="caption">
            {errorMessage}
          </Typography>
        </Box>

        <ButtonPrimary
          onClick={handleSubmit}
          style={{ width: '100%', marginTop: 10 }}
          component="p"
          disabled={!stripe || loading}
        >
          <LoaderSm loading={loading} />
          {t('button.confirm')}
        </ButtonPrimary>
      </Box>
    </form>
  );
}

export default AchForm;
