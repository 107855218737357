import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import { TableCell, Tooltip, withStyles } from '@material-ui/core';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useTranslation } from 'react-i18next';
import { onlineColor, primary, redColor, secondary, secondaryLight } from 'core/ui/Colors';
import Typography, { fontStyle } from 'core/ui/Typography';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import InfoIcon from '@material-ui/icons/Info';
import EditIcon from '@material-ui/icons/Edit';
import { calculateServiceFees, valueTaxAmount } from 'core/utils/helpers';
import { ArrowUpward, Notifications } from '@material-ui/icons';
import { currency, date } from 'core/utils/formatters';
import { APP_BASE_URL } from 'core/env';
import Box from 'core/ui/Box';
import TicketIcon from 'core/ui/FontIcon/TicketIcon';
import ModalQrCodeDownload from 'core/ui/ModalQrCodeDownload';
import ExampleQr from 'assets/img/qr_code_example.png';
import DescPlanLimitUse from './DescPlanLimitUse';

export const Cell = withStyles(() => ({
  head: {
    backgroundColor: secondaryLight,
    color: secondary,
    fontSize: 11,
  },
  body: {
    fontSize: 11,
  },
}))(TableCell);

export default function TablePlans({
  channel,
  membershipPlans,
  editPlan,
  setModalCoupons,
  changeActive,
  changeVisible,
  changeOrderShow,
}) {
  const { t } = useTranslation();
  const [modalQr, setModalQr] = useState();
  const plans = [...membershipPlans].sort((a, b) => a.orderShow - b.orderShow);

  return (
    <>
      <ModalQrCodeDownload
        open={modalQr?.open}
        close={() => setModalQr({ opem: false })}
        text={modalQr?.text}
        title={modalQr?.title}
      />
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table" size="small">
          <TableHead>
            <TableRow>
              <Cell />
              <Cell>{t('channel.membership.plan.name')}</Cell>
              <Cell>{t('label.copyLink')}</Cell>
              <Cell />

              <Cell align="right">{t('channel.membership.plan.renewPeriod')}</Cell>
              <Cell align="right">{t('channel.membership.plan.price')}</Cell>
              <Cell align="center">{t('channel.membership.plan.limit')}</Cell>
              {editPlan && <Cell align="center">{t('channel.membership.plan.edit')}</Cell>}
              {changeActive && <Cell align="center">{t('channel.membership.plan.active')}</Cell>}
              {changeVisible && <Cell align="center">{t('channel.membership.plan.visible')}</Cell>}
              {changeOrderShow && (
                <Cell align="center">{t('channel.membership.plan.coupons')}</Cell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {plans.map((plan, index) => (
              <TableRow key={index.toString()}>
                <Cell>
                  {index > 0 && (
                    <Typography
                      onClick={() => changeOrderShow(plan, index)}
                      className="link-hover"
                      variant="caption"
                      align="center"
                    >
                      <ArrowUpward style={{ ...fontStyle.large, color: secondary }} />
                    </Typography>
                  )}
                </Cell>
                <Cell>
                  <Typography>{plan.name}</Typography>
                  <Typography style={fontStyle.small} color="primary">
                    {!!plan.expiryConfig?.config && plan.expiryConfig?.config !== 'none' ? (
                      <>
                        {t('channel.memberships.expiry')}&nbsp;{plan.expiryConfig?.number}&nbsp;
                        {plan.expiryConfig?.config}(s)&nbsp;
                        {t('channel.memberships.afterPurchase')}
                      </>
                    ) : (
                      <>{t('channel.memberships.noExpiry')}</>
                    )}
                  </Typography>
                </Cell>
                <Cell>
                  <Box display="flex" alignItems="center">
                    {plan?.id && (
                      <Box
                        ml={2}
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        className="hover"
                        onClick={() =>
                          setModalQr({
                            open: true,
                            text: `${APP_BASE_URL}/embed/memberships/${channel?.id}/${plan?.id}`,
                            title: plan.name,
                          })
                        }
                      >
                        <>
                          <img src={ExampleQr} alt="Qr Code" style={{ width: 24 }} />
                        </>
                      </Box>
                    )}
                  </Box>
                </Cell>
                <Cell align="center">
                  <Tooltip
                    title={`${plan.description ? plan.description : ''} - ${
                      plan.renewable ? 'Renewable' : 'Non-Renewable'
                    }`}
                  >
                    <InfoIcon />
                  </Tooltip>
                  {plan?.notifyUse && (
                    <Tooltip title={t('channel.memberships.notifyUse')}>
                      <Notifications />
                    </Tooltip>
                  )}
                </Cell>
                <Cell align="right">
                  {plan.customIncrement === 'custom' ? (
                    <>
                      {t(`channel.memberships.custom`)}&nbsp;
                      {date(plan?.startAt, channel?.dateFormatReact)} -{' '}
                      {date(plan?.endAt, channel?.dateFormatReact)}
                    </>
                  ) : (
                    <>
                      {t(`channel.memberships.custom.${plan.customIncrement}`)}&nbsp;-&nbsp;
                      {plan.customIncrementNumber}
                    </>
                  )}
                </Cell>
                <Cell align="right">
                  {plan.addSalesTax && plan.taxAmount > 0 && (
                    <>
                      {plan?.useIntroductoryAmount && plan.introductoryAmount !== plan.amount && (
                        <Typography style={fontStyle.xSmall}>
                          {t('channel.membership.plan.taxIntroductoryAmount')}:{' '}
                          {currency(plan.taxIntroductoryAmount || plan.taxAmount)}
                        </Typography>
                      )}
                      <Typography style={fontStyle.xSmall}>
                        {t('channel.membership.plan.taxAmount')}: {currency(plan.taxAmount)}
                      </Typography>
                    </>
                  )}
                  <Typography style={fontStyle.xSmall}>
                    {t('channel.membership.plan.amount')}: {currency(plan.amount)}
                  </Typography>
                  <Typography style={fontStyle.xSmall}>
                    {t('channel.membership.plan.cost')}:{' '}
                    {currency(
                      calculateServiceFees(plan, plan.amount) +
                        valueTaxAmount(
                          calculateServiceFees(plan, plan.amount || 0),
                          plan?.addSalesTax ? plan?.channelsTax || null : null,
                        ),
                    )}
                  </Typography>
                  {plan.useIntroductoryAmount && (
                    <>
                      <Typography style={fontStyle.xSmall}>
                        {t('channel.membership.plan.introductory')}:{' '}
                        {currency(plan.introductoryAmount || plan.amount)}
                      </Typography>
                      <Typography style={fontStyle.xSmall}>
                        {t('channel.membership.plan.introductoryCost')}:{' '}
                        {currency(
                          calculateServiceFees(plan, plan.introductoryAmount || plan.amount) +
                            valueTaxAmount(
                              calculateServiceFees(plan, plan.introductoryAmount || plan.amount),
                              plan?.addSalesTax ? plan?.channelsTax || null : null,
                            ),
                        )}
                      </Typography>
                    </>
                  )}
                </Cell>

                <Cell align="right">
                  {plan.limitUse > 0 && <DescPlanLimitUse plan={plan} font={fontStyle.small} />}

                  {plan.limitPurchases && (
                    <Box mt={1} display="flex" justifyContent="center" alignItems="center">
                      <TicketIcon />
                      <Typography noWrap style={fontStyle.medium} align="center">
                        {plan?.limitPurchaseUsage || 0}/{plan.limitPurchasesMax}
                      </Typography>
                    </Box>
                  )}
                </Cell>
                {editPlan && (
                  <Cell align="center">
                    <Typography
                      onClick={() => editPlan(plan, index)}
                      className="link-hover"
                      variant="caption"
                      align="center"
                    >
                      <EditIcon style={{ ...fontStyle.large, color: secondary }} />
                    </Typography>
                  </Cell>
                )}
                {changeActive && (
                  <Cell align="center">
                    <Typography
                      onClick={() => changeActive(plan)}
                      className="link-hover"
                      variant="caption"
                      align="center"
                    >
                      {!plan.active ? (
                        <RadioButtonUncheckedIcon style={{ ...fontStyle.large, color: redColor }} />
                      ) : (
                        <CheckCircleOutlineIcon
                          style={{ ...fontStyle.large, color: onlineColor }}
                        />
                      )}
                    </Typography>
                  </Cell>
                )}
                {changeVisible && (
                  <Cell align="center">
                    <Typography
                      onClick={() => changeVisible(plan)}
                      className="link-hover"
                      variant="caption"
                      align="center"
                    >
                      {!plan.visible ? (
                        <RadioButtonUncheckedIcon style={{ ...fontStyle.large, color: redColor }} />
                      ) : (
                        <CheckCircleOutlineIcon
                          style={{ ...fontStyle.large, color: onlineColor }}
                        />
                      )}
                    </Typography>
                  </Cell>
                )}
                {setModalCoupons && (
                  <Cell align="center">
                    <Typography
                      onClick={() =>
                        setModalCoupons((prev) => ({
                          ...prev,
                          open: true,
                          currentPlan: plan,
                        }))
                      }
                      className="link-hover"
                      variant="caption"
                      align="center"
                    >
                      <MoneyOffIcon style={{ ...fontStyle.large, color: primary }} />
                    </Typography>
                  </Cell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

TablePlans.defaultProps = {
  membershipPlans: [],
  setModalCoupons: null,
  editPlan: null,
  changeActive: null,
  changeVisible: null,
};

TablePlans.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  membershipPlans: PropTypes.array,
  setModalCoupons: PropTypes.func,
  editPlan: PropTypes.func,
  changeActive: PropTypes.func,
  changeVisible: PropTypes.func,
};
