import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import DatePickerRounded from 'core/ui/DatePickers/DatePickerRounded';
import { ButtonPrimary } from 'core/ui/Button';
import { Search } from '@material-ui/icons';
import { formatDate, formatDateToApi } from 'core/utils/formatters';
import { channelReportsModule } from 'modules/channelReports';
import useSelectors from 'modules/map/useSelectors';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import Divider from 'core/ui/Divider';
import Checkbox from 'core/ui/Checkbox';
import ResultUsers from './ResultUsers';
import SkeletonPayamentsReport from './Skeleton';
import BarMonths from './BarMonths';

const MembersGrow = ({ channel, loading, getReport }) => {
  const { t } = useTranslation();

  const data = useSelectors(channelReportsModule, 'data');
  const [filters, setFilters] = useState({
    startAt: formatDate(new Date(), channel?.dateFormatReact),
  });

  const search = () => {
    const startAt = filters.startAt ? formatDateToApi(filters.startAt) : null;
    if (startAt) getReport({ ...filters, startAt });
  };

  useEffect(() => {
    search();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box mt={2}>
      <Box>
        <Box display="flex" mb={3} alignItems="flex-end" justifyContent="center" flexWrap="wrap">
          <Box>
            <DatePickerRounded
              label="MONTH"
              onChange={(v) => setFilters((prev) => ({ ...prev, startAt: v }))}
              value={filters.startAt}
              disablePast={false}
              format="MMM yyyy"
              autoOk
            />
          </Box>
          <Box ml={3}>
            <SelectBoxObject
              label="MEMBERSHIPS"
              placeholder="All"
              emptyItem
              emptyValue={null}
              options={channel.membershipAllPlans || []}
              value={filters?.channelMembershipPlanId}
              setValue={(v) => setFilters((prev) => ({ ...prev, channelMembershipPlanId: v }))}
              bordered
            />
          </Box>
          <Box ml={3}>
            <Checkbox
              label="REMOVE DROP-INS"
              checked={filters?.removeDropIn}
              onClick={() => setFilters((prev) => ({ ...prev, removeDropIn: !prev?.removeDropIn }))}
            />
          </Box>
          <Box ml={3}>
            <ButtonPrimary disabled={loading} onClick={() => search()}>
              <Search style={{ color: 'white' }} fontSize="small" />
              {t('button.search')}&nbsp;
            </ButtonPrimary>
          </Box>
        </Box>

        {loading ? (
          <SkeletonPayamentsReport />
        ) : (
          <Box>
            <ResultUsers channel={channel} data={data || []} />
          </Box>
        )}

        <Divider m={3} mb={5} />
        <Box>
          <BarMonths channelId={channel?.id} />
        </Box>
      </Box>
    </Box>
  );
};

MembersGrow.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  loading: PropTypes.bool,
  getReport: PropTypes.func,
};

MembersGrow.defaultProps = {
  loading: false,
  getReport: () => {},
};

export default MembersGrow;
