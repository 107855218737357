import api from 'core/api';
import { call, put } from 'redux-saga/effects';

export const newRecurringsModule = 'newRecurrings';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const actions = {
  getRecurringWorkouts: {
    module: newRecurringsModule,
    name: 'getRecurringWorkouts',
    api: (params) => api.get(`/recurrings/${params.recurringId}/workouts`, { params }),
    *sagas(Creators, { params, options }) {
      try {
        const resp = yield call(actions.getRecurringWorkouts.api, params);
        yield put(Creators.getRecurringWorkoutsSuccess(resp.data, resp.pagination));
        if (options.onSuccess) {
          options.onSuccess(resp.data, resp.pagination);
        }
      } catch (error) {
        if (options.onError) {
          options.onError(error);
        }
        yield put(Creators.getRecurringWorkoutsError());
      }
    },
    params: {
      start: ['params'],
      error: [''],
      success: ['workouts', 'pagination'],
    },
    state: loadingStates,
  },
  updateDaylightSavings: {
    module: newRecurringsModule,
    name: 'updateDaylightSavings',
    api: (params) => api.post(`/recurrings/update_daylight_savings`, params),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  bulkUpdateWorkoutsByRecurrings: {
    module: newRecurringsModule,
    name: 'bulkUpdateWorkoutsByRecurrings',
    api: (params) => api.post(`/recurrings/bulk_update_workouts`, params),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
};

export default {
  actions,
  state: {
    loading: false,
    workouts: [],
    pagination: null,
  },
};
