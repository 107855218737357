/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useState } from 'react';
import Box from 'core/ui/Box';
import { Grow } from '@material-ui/core';
import Overview from './Overview';
import Leaderboard from './Leaderboard';
import { ShowContext } from '../../index';

export default ({ workout, loading }) => {
  const showContext = useContext(ShowContext);
  const [leaderboard, setLeaderboard] = useState({ open: false });

  useEffect(() => {
    if (showContext.tab === 'overview') {
      setLeaderboard({ open: false });
    }
  }, [showContext.tab]);

  const openLeaderboard = (section) => {
    showContext.setTab('leaderboard');
    setLeaderboard({ open: true, section });
  };

  return (
    <>
      <Grow direction="up" in={!leaderboard.open} mountOnEnter unmountOnExit>
        <div>
          {!leaderboard.open && (
            <Overview
              showInvites={showContext.showInvites}
              showStartJoinButton={showContext.showStartJoinButton}
              workout={workout}
              loading={loading}
              openLeaderboard={(section) => openLeaderboard(section)}
            />
          )}
        </div>
      </Grow>
      <Grow direction="up" in={leaderboard.open} mountOnEnter unmountOnExit>
        <Box>
          {leaderboard.open && <Leaderboard workout={workout} section={leaderboard.section} />}
        </Box>
      </Grow>
    </>
  );
};
