/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import Typography, { fontStyle } from 'core/ui/Typography';
import Checkbox from 'core/ui/Checkbox';
import { WorkoutContext } from '../index';

const Location = () => {
  const { t } = useTranslation();
  const formContext = useContext(WorkoutContext);

  const checkLocation = (field) => !!formContext.watch(field);

  const changeLocationList = (field) => {
    formContext.setValue(field, !formContext.watch(field));
  };

  return (
    <Box px={1}>
      <Typography style={fontStyle.label} variant="body1">
        {t('workout.label.location').toUpperCase()}
      </Typography>
      <Box display="flex" mt={2}>
        {formContext.selectedChannel?.activePlans?.online?.active && (
          <Checkbox
            label={t('workout.setting.online')}
            checked={checkLocation('online')}
            onClick={() => changeLocationList('online')}
            mr={2}
          />
        )}
        {formContext.selectedChannel?.activePlans?.inPerson?.active && (
          <Checkbox
            label={t('workout.setting.inPerson')}
            checked={checkLocation('inPerson')}
            onClick={() => changeLocationList('inPerson')}
          />
        )}
      </Box>
    </Box>
  );
};

export default Location;

Location.propTypes = {};

Location.defaultProps = {};
