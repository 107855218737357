import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import { Container } from '@material-ui/core';

import { LayoutContext } from 'pages/shared/Layout';
import Form from 'components/Workout/Form/index';
import { useLocation } from 'react-router';
import { parse } from 'query-string';

export default () => {
  const { t } = useTranslation();
  const location = useLocation();
  const layoutContext = useContext(LayoutContext);
  const params = parse(location.search);

  useEffect(() => {
    layoutContext.setTitleBar(
      t(!params.competitionId ? 'title.workout.new' : 'title.event.new').toUpperCase(),
    );
    layoutContext.setActiveMenu('new_workout');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layoutContext, t]);

  return (
    <div>
      <Container maxWidth="xl">
        <Box pt={5}>
          <Form
            mode="new"
            type="customClass"
            channelId={params.channelId}
            coachScheduleSessionId={params.coachScheduleSessionId}
          />
        </Box>
      </Container>
    </div>
  );
};
