/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import useSelectors from 'modules/map/useSelectors';
import { contactFormsModule } from 'modules/contactForms';
import Modules from 'modules';
import useActions from 'modules/map/useActions';
import Paper from 'core/ui/Paper';
import Typography, { fontStyle } from 'core/ui/Typography';
import { IconButton } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { gymDate, time } from 'core/utils/formatters';
import Divider from 'core/ui/Divider';
import useConfirmation from 'core/useConfirmation';
import { useForm } from 'react-hook-form';
import { FiltersFormContacts } from './FIltersContacts';
import ModalConversations from './ModalConversations';

const ContactForms = ({ channel }) => {
  const { t } = useTranslation();
  const { request } = useActions();
  const contactForms = useSelectors(contactFormsModule, 'data');
  const [modalConversations, setModalConversations] = useState({});
  const { confirmation } = useConfirmation();

  const { control, handleSubmit } = useForm();

  const getContactForms = (data) => {
    request({
      action: Modules.contactForms.actions.getContactForms,
      data: {
        channelId: channel?.id,
        ...data,
      },
    });
  };

  useEffect(() => {
    if (channel?.id) {
      getContactForms();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request, channel?.id]);

  const removeContactForm = (id) => {
    confirmation({
      description: t('areYouSure'),
      yesClick: () => {
        request({
          action: Modules.contactForms.actions.destroy,
          data: {
            channelId: channel?.id,
            id,
          },
          options: {
            onSuccess: () => getContactForms(),
          },
        });
      },
    });
  };

  return (
    <Box p={3}>
      <Box>
        <FiltersFormContacts
          setModalConversations={setModalConversations}
          control={control}
          search={() => handleSubmit(getContactForms)()}
        />
      </Box>
      <ModalConversations
        channel={channel}
        close={() => setModalConversations({ open: false })}
        userId={modalConversations?.userId}
        contactForm={modalConversations?.contactForm}
        open={modalConversations?.open}
      />
      <Box mt={2}>
        <Paper p={2}>
          <Box display="flex">
            <Box style={{ width: 200 }}>
              <Typography color="textSecondary" className="roboto-bold">
                {t('label.contact')}
              </Typography>
            </Box>
          </Box>
        </Paper>
        <Divider />
        {contactForms?.map((item) => (
          <Paper key={item?.id} p={3} mb={0.5}>
            <Box
              display="flex"
              className="link-hover gap-10"
              onClick={() => setModalConversations({ open: true, contactForm: item })}
            >
              <Box flexGrow={1}>
                <Typography>{item?.name}</Typography>
                <Typography>{item?.phone}</Typography>
                <Typography>{item?.email}</Typography>
              </Box>
              <Box>
                <Typography color="textSecondary" style={fontStyle.small}>
                  {gymDate(item?.createdAt)}&nbsp;{time(item?.createdAt)}
                </Typography>
              </Box>
              <Box>
                <IconButton onClick={() => removeContactForm(item?.id)}>
                  <Delete />
                </IconButton>
              </Box>
            </Box>
          </Paper>
        ))}
      </Box>
    </Box>
  );
};

export default ContactForms;
