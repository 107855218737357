import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from 'core/ui/Typography';
import Box from 'core/ui/Box';
import LoaderSm from 'core/ui/LoaderSm';
import SFDataTable from 'core/ui/DataTable';
import ImageMedia from 'components/Profile/ImageMedia';
import useSelectors from 'modules/map/useSelectors';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import { InputWrapper } from 'core/ui/ChosenComponents';
import { ButtonPrimary } from 'core/ui/Button';
import TextFieldRounded from 'core/ui/TextFieldRounded';

import { competitionsUsersModule } from 'modules/competitionsUsers';
import Checkbox from 'core/ui/Checkbox';
import { WorkoutContext } from '.';

const CompWorkoutVolunteers = () => {
  const { t } = useTranslation();
  const formContext = useContext(WorkoutContext);

  const [users, setUsers] = useState([]);
  const [pagination, setPagination] = useState(null);
  const loading = useSelectors(competitionsUsersModule, 'loadingUsers');
  const [perPage, setPerPage] = useState(10);
  const [query, setQuery] = useState('');
  const { request } = useActions();

  const competition = formContext?.selectedCompetition;

  const search = (page = 1) => {
    request({
      action: Modules.competitionsUsers.actions.getCompetitionsUser,
      data: {
        competitionId: competition?.id,
        regType: 'volunteer',
        page,
        perPage,
        query,
      },
      options: {
        onSuccess: (resp, pag) => {
          setUsers(resp);
          setPagination(pag);
        },
      },
    });
  };

  useEffect(() => {
    if (competition && competition.id) {
      search(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [competition, perPage]);

  const isChecked = (id) => {
    const items = formContext?.watch('competitionsUsersCategoryIds') || [];
    return !!items.find((x) => x === id);
  };

  const check = (id) => {
    const items = formContext?.watch('competitionsUsersCategoryIds') || [];
    if (isChecked(id)) {
      formContext.setValue(
        'competitionsUsersCategoryIds',
        items.filter((x) => x !== id),
      );
    } else {
      formContext.setValue('competitionsUsersCategoryIds', [...items, id]);
    }
  };

  const columns = [
    {
      name: '',
      selector: 'name',
      cell: (member) => (
        <Box my={1}>
          <ImageMedia
            user={member}
            image={member?.user?.image || {}}
            size={50}
            heightCountry={15}
            sizeCountry="1em"
          />
        </Box>
      ),
      width: '60px',
    },
    {
      name: 'User',
      selector: 'name',
      cell: (member) => (
        <Box>
          <Typography>{member?.user?.name}</Typography>
          {competition.isTeam && <Typography color="primary">{member?.team}</Typography>}
          {member.division && <Typography color="primary">{member?.division?.name}</Typography>}
        </Box>
      ),
    },
    {
      name: 'Category',
      cell: (member) => (
        <Typography>
          {member?.competitionsUsersCategories?.map((x) => (
            <Box key={x.id}>
              <Checkbox
                checked={isChecked(x.id)}
                onClick={() => check(x.id)}
                label={x?.competitionsCategory?.name}
                color={competition?.buttonColor}
              />
            </Box>
          ))}
        </Typography>
      ),
      sortable: true,
    },
  ];

  return (
    <Box py={2}>
      <Box display="flex" alignItems="center" pb={3} pt={2}>
        <Box flexGrow="1">
          <Typography variant="h5" mb={3} color="primary">
            {t('label.volunteerAssignment')}
          </Typography>
        </Box>
      </Box>

      <Box mb={3}>
        <Box display="flex" alignItems="center">
          <InputWrapper>
            <Box display="flex" style={{ width: '100%' }}>
              <TextFieldRounded
                value={query}
                onChange={(value) => setQuery(value)}
                style={{ minWidth: 30, width: '100%', margin: '4px' }}
                placeholder={t('channel.members.search')}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    search();
                  }
                }}
              />
            </Box>
          </InputWrapper>
          <Box display="flex" justifyContent="center">
            <LoaderSm loading={loading} />
          </Box>
          <Box ml={1}>
            <ButtonPrimary disabled={loading} onClick={() => search()}>
              {t('button.search')}
            </ButtonPrimary>
          </Box>
        </Box>
      </Box>

      <SFDataTable
        // noHeader
        // noTableHead
        title=""
        paginationRowsPerPageOptions={[10, 25, 50]}
        showPaper={false}
        columns={columns}
        paginationPerPage={perPage}
        data={users}
        pagination
        noDataComponent=""
        onChangePage={(p) => search(p)}
        paginationServer
        paginationDefaultPage={pagination?.currentPage}
        paginationTotalRows={pagination?.totalCount}
        onChangeRowsPerPage={(newValue) => {
          setPerPage(newValue);
        }}
      />
    </Box>
  );
};

CompWorkoutVolunteers.defaultProps = {};

export default CompWorkoutVolunteers;
