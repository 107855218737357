import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

import { LoginManager } from './LoginManager';

export const ByToken = () => {
  const { search } = useLocation();
  const { replace } = useHistory();

  const [token] = useState(() => new URLSearchParams(search).get('token'));

  useEffect(() => {
    if (!token) replace('/auth/login');
  }, [token, replace]);

  useEffect(() => {
    const queryParams = new URLSearchParams(search);

    if (queryParams.get('token') && token) {
      queryParams.delete('token');

      replace({ search: queryParams.toString() });
    }
  }, [search, token, replace]);

  if (!token) return null;

  return <LoginManager token={token} />;
};
