import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';

import SelectBoxObject from 'core/ui/SelectBoxObject';
import Toggle from 'core/ui/Toggle';
import TextFieldRounded from 'core/ui/TextFieldRounded';

import { ButtonPrimary } from 'core/ui/Button';
import { Search } from '@material-ui/icons';
import { storeCategoriesModule } from 'modules/storeCategories';
import { storeProductsModule } from 'modules/storeProducts';
import useSelectors from 'modules/map/useSelectors';
import useActions from 'modules/map/useActions';
import Modules from 'modules';

const FiltersProductsInventories = ({ getReport, loading, channel }) => {
  const { t } = useTranslation();
  const [filters, setFilters] = useState({ checkInventory: '0' });

  const storeCategories = useSelectors(storeCategoriesModule, 'data');
  const storeProducts = useSelectors(storeProductsModule, 'data');
  const { request } = useActions();

  useEffect(() => {
    if (channel?.id) {
      request({
        action: Modules.storeCategories.actions.getStoreCategories,
        data: { ownerableId: channel?.id, ownerableType: 'Channel' },
      });
    }
  }, [request, channel?.id]);

  useEffect(() => {
    if (channel?.id && filters?.storeCategoryId) {
      request({
        action: Modules.storeProducts.actions.getStoreProducts,
        data: {
          ownerableId: channel?.id,
          ownerableType: 'Channel',
          perPage: 200,
          categories: [filters?.storeCategoryId],
        },
      });
    }
  }, [request, channel?.id, filters?.storeCategoryId]);

  return (
    <Box>
      <div className="tw-mb-2 tw-flex tw-flex-row">
        <div className="tw-grid tw-grow tw-grid-cols-1 tw-gap-24 md:tw-grid-cols-4">
          <div className="tw-grow">
            <SelectBoxObject
              emptyItem
              emptyValue={null}
              label={t('label.category').toUpperCase()}
              placeholder="All categories"
              options={storeCategories}
              value={filters?.storeCategoryId}
              setValue={(v) => setFilters((prev) => ({ ...prev, storeCategoryId: v }))}
              bordered
            />
          </div>
          <div className="tw-grow">
            <SelectBoxObject
              emptyItem
              disabled={!filters?.storeCategoryId}
              emptyValue={null}
              label={t('label.product').toUpperCase()}
              placeholder="All products"
              options={storeProducts}
              value={filters?.storeProductId}
              setValue={(v) => setFilters((prev) => ({ ...prev, storeProductId: v }))}
              bordered
            />
          </div>
          <Box>
            <Toggle
              label={t('label.checkInventory').toUpperCase()}
              options={[
                { value: '0', label: t('button.no') },
                { value: '1', label: t('button.yes') },
              ]}
              value={filters?.checkInventory}
              onChange={(v) => {
                setFilters((prev) => ({ ...prev, checkInventory: v }));
              }}
            />
          </Box>

          {filters?.checkInventory === '1' && (
            <Box>
              <TextFieldRounded
                label={t(`label.report.inventoryMin`).toUpperCase()}
                placeholder="Qty"
                value={filters?.inventoryMin}
                onChange={(v) => {
                  setFilters((prev) => ({ ...prev, inventoryMin: v }));
                }}
                bordered
                type="number"
                pattern="[0-9]*"
              />
            </Box>
          )}
        </div>
        <Box ml={3}>
          <ButtonPrimary disabled={loading} onClick={() => getReport(filters)}>
            <Search style={{ color: 'white' }} fontSize="small" />
            {t('button.search')}&nbsp;
          </ButtonPrimary>
        </Box>
      </div>
    </Box>
  );
};

export default FiltersProductsInventories;
