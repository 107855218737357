import './index.css';

let oldMaxWidth = 0;
let oldMaxHeight = 0;
let oldVideoCount = 0;
let boxVideoWidth = 0;
let boxVideoHeight = 0;
let colsSize = 0;

export default function useLayoutGrid({ maxWidth, maxHeight, videoCount }) {
  const aspectRatio = 4 / 3;

  function calculateLayout() {
    let bestLayout = {
      area: 0,
      cols: 0,
      rows: 0,
      width: 0,
      height: 0,
    };

    // eslint-disable-next-line no-plusplus
    for (let cols = 1; cols <= videoCount; cols++) {
      const rows = Math.ceil(videoCount / cols);
      const hScale = maxWidth / (cols * aspectRatio);
      const vScale = maxHeight / rows;
      let width;
      let height;
      if (hScale <= vScale) {
        width = Math.floor(maxWidth / cols);
        height = Math.floor(width / aspectRatio);
      } else {
        height = Math.floor(maxHeight / rows);
        width = Math.floor(height * aspectRatio);
      }
      const area = width * height;
      if (area > bestLayout.area) {
        bestLayout = {
          area,
          width,
          height,
          rows,
          cols,
        };
      }
    }
    return bestLayout;
  }

  if (oldMaxHeight !== maxHeight || oldMaxWidth !== maxWidth || oldVideoCount !== videoCount) {
    oldMaxWidth = maxWidth;
    oldMaxHeight = maxHeight;
    oldVideoCount = videoCount;

    const { width, height, cols } = calculateLayout(maxWidth, maxHeight);
    boxVideoWidth = width;
    boxVideoHeight = height;
    colsSize = cols;
  }

  return { widthBox: boxVideoWidth, heightBox: boxVideoHeight, colsSize };
}
