/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import Box from 'core/ui/Box';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import useSelectors from 'modules/map/useSelectors';
import { usersContractsModule } from 'modules/usersContracts';
import ResultContracts from './ResultContracts';

const ChannelUserContracts = ({ channel, user }) => {
  const { request } = useActions();
  const channelContracts = useSelectors(usersContractsModule, 'channelContracts');

  const refresh = () => {
    request({
      action: Modules.usersContracts.actions.getChannelUserContracts,
      data: {
        userId: user?.id,
        ownerableId: channel?.id,
        ownerableType: 'Channel',
      },
    });
  };

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box mt={2}>
      <ResultContracts
        channelContracts={channelContracts}
        channel={channel}
        user={user}
        refresh={refresh}
      />
    </Box>
  );
};

export default ChannelUserContracts;
