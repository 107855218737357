import React, { createContext, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from 'core/ui/Box';
import { workoutsActions, workoutsSelectors } from 'modules/workouts';
import ModalRating from 'components/Rating/Workout/Modal';
import { parse } from 'query-string';
import { getRated } from 'modules/ratings/api';
import HelmetMetaData from 'core/ui/SocialMediaButtons/HelmetMetaData';
import { workoutUrlSlug } from 'core/utils/helpers';
import { useLocation, useParams } from 'react-router';
import Tabs from './Tabs';
import Content from './Content';
import ShowHeader from './ShowHeader';
import ButtonActions from './ButtonActions';
import SkeletonWorkout from './Skeleton';
import ModalRecordingInvite from './RecordingInvite/Modal';
import CustomWorkoutView from './Custom/index';

export const ShowContext = createContext();

export default ({ tab, setTab }) => {
  const [modalInvites, setModalInvites] = useState({ open: false });
  const loading = useSelector((state) => workoutsSelectors.getLoading(state));
  const workout = useSelector((state) => workoutsSelectors.getWorkout(state));
  const dispatch = useDispatch();
  const { defaultTab } = useParams();
  const [openRating, setOpenRating] = useState(false);
  const [modalHeats, setModalHeats] = useState(false);
  const location = useLocation();

  const modalRating = useCallback(async () => {
    if (workout.id && workout.method !== 'custom') {
      const rest = await getRated({ klass: 'Workout', klassId: workout.id });
      if (!rest.data.rate) {
        setOpenRating(true);
      }
    }
  }, [workout]);

  useEffect(() => {
    const parsed = parse(location.search);
    if (parsed && parsed.rating) {
      modalRating();
    }
  }, [location, modalRating, workout]);

  useEffect(() => {
    if (defaultTab) {
      setTab(defaultTab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultTab]);

  const callbackRating = () => {
    setOpenRating(false);
    dispatch(workoutsActions.getWorkoutStart(workout.id));
  };

  const showInvites = workout && workout.recorded && workout.invites && workout.invites.length > 0;

  const hasInviteOnline = () => {
    if (showInvites) {
      return !!workout.invites.find((x) => !!x.live);
    }
    return false;
  };

  const showStartJoinButton = (invite = false) => {
    if (hasInviteOnline() && !invite) {
      return false;
    }
    if (!workout.active || !workout.registered) {
      return false;
    }
    if (workout.recorded && !workout.started) {
      return false;
    }

    if (workout.inPerson && !workout.online) {
      return false;
    }
    if (workout.registeredAs === 'inPerson') {
      return false;
    }
    if (workout.channel && !workout.channel.planValid && !workout.recorded) {
      return false;
    }
    if (!workout.isCoach && workout.method !== 'live' && !workout.started) {
      return false;
    }

    if (workout.recorded && workout.hasRecordings) {
      if (workout.recordingStatus !== 'done') {
        return false;
      }
    } else if (workout.recorded && !workout.hasRecordings) {
      return false;
    }
    return true;
  };

  return (
    <Box pt={3} pb={5} style={{ height: '100%' }}>
      <ShowContext.Provider
        value={{
          tab,
          setTab,
          modalInvites,
          setModalInvites,
          loading,
          workout,
          showInvites,
          showStartJoinButton,
          hasInviteOnline,
          modalHeats,
          setModalHeats,
        }}
      >
        {modalInvites.open && (
          <ModalRecordingInvite
            workout={workout}
            open={modalInvites.open}
            close={() => setModalInvites({ open: false })}
          />
        )}
        {!workout.isCompetition && workout.registered && !workout.isCoach && openRating && (
          <ModalRating callback={callbackRating} setOpenRating={setOpenRating} />
        )}

        <HelmetMetaData
          url={workoutUrlSlug(workout)}
          title={workout.name}
          description={workout.description}
          image={workout.channel && workout.channel.image.image.url}
        />

        {!workout?.id ? (
          <SkeletonWorkout />
        ) : (
          <>
            {workout.method === 'custom' ? (
              <CustomWorkoutView workout={workout} />
            ) : (
              <>
                {tab !== 'leaderboard' && <ShowHeader />}

                <Box my={3} style={{ position: 'relative' }}>
                  <Tabs />
                  <ButtonActions />
                </Box>

                <Content />
              </>
            )}
          </>
        )}
      </ShowContext.Provider>
    </Box>
  );
};
