import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import Grid from 'core/ui/Grid';
import { Dialog, IconButton, Link, Tab, Tabs, Tooltip, makeStyles } from '@material-ui/core';
import { ButtonPrimary } from 'core/ui/Button';
import Typography, { fontStyle } from 'core/ui/Typography';
import LoaderSm from 'core/ui/LoaderSm';
import ConfirmDialog from 'core/ui/ConfirmDialog';
import { LayoutContext } from 'pages/shared/Layout';
import Paper from 'core/ui/Paper';
import { copyToClipBoard } from 'core/utils/helpers';
import { ArrowBack, FileCopy, Info } from '@material-ui/icons';
import { APP_BASE_URL } from 'core/env';
import { useHistory, useParams } from 'react-router';
import { integrationTypes } from 'core/utils/consts';
import Divider from 'core/ui/Divider';
import { date } from 'core/utils/formatters';
import DocumentsBuilder from 'components/DocumentsBuilder';
import Checkbox from 'core/ui/Checkbox';
import { CompetitionContext } from './index';
import CompetitionSettings from './CompetitionSettings';
import Judges from './Judges';
import CompetitionOverview from './Overview';
import CompetitionCustomization from './CompetitionSettings/Customization';
import UsersEarlyAccess from './UsersEarlyAccess';
import BannerAndLogo from './BannerAndLogo';
import StripeConnectedAccount from './StripeConnectedAccount';
import Coupons from './Coupons';
import MapsLocation from './MapsLocation';
import CustomFields from './CustomFields';
import Divisions from './Divisions';
import Categories from './Categories';
import StageLocations from './StageLocations';
import SearchPayments from './Payments/SearchPayments';
import CompetitionCommunication from './Communication';
import CompetitionInvites from './Invites';
import ModalPayAndCreate from '../Payment/ModalPayAndCreate';
import ModalPayment from '../Payment/Modal';
import CompetitionWaitlists from './Waitlists';
import WaiversUsers from './Waivers/Users';
import CompetitionHeats from './Heats';
import CompetitionMaterialDesign from './MaterialDesign';
import CompetitionsScoresManage from '../Show/Tabs/ScoresManage';
import CompetitionCustomScoringTable from './CustomScoringTable';

const useStyles = makeStyles((theme) => ({
  media: {
    paddingTop: '46.25%',
    backgroundColor: '#bbbdbf',
    maxHeight: 100,
    backgroundSize: '100% 100%',
  },
  boxSelImage: {
    position: 'absolute',
    marginTop: -30,
  },
  content: {
    position: 'relative',
    backgroundColor: '#fafafa',
    borderRadius: 5,
  },
  boxButtons: {
    margin: 0,
    top: 'auto',
    right: 30,
    bottom: 15,
    left: 'auto',
    position: 'fixed',
  },
  button: {
    boxShadow: '0px 3px 5px 0px rgba(0,0,0,0.4)',
    borderRadius: 5,
    height: 38,
    width: 110,
    margin: 2,
    [theme.breakpoints.down('sm')]: {
      bottom: '50px',
    },
  },
}));

const CompetitiionFormData = () => {
  const { t } = useTranslation();
  const layoutContext = useContext(LayoutContext);
  const formContext = useContext(CompetitionContext);
  const classes = useStyles();
  const { tab: defaultTab } = useParams('info');
  const [confirmCancel, setConfirmCancel] = useState({ open: false });
  const [tab, setTab] = useState(null);
  const history = useHistory();
  const [openStripe, setOpenStripe] = useState();

  useEffect(() => {
    if (defaultTab) {
      setTab(defaultTab);
    } else {
      setTab('info');
    }
  }, [defaultTab]);

  useEffect(() => {
    if (tab && formContext?.competition?.id) {
      window.history.replaceState(
        null,
        'StreamFit',
        `/competition/edit/${formContext?.competition?.id}/${tab}`,
      );
    }
  }, [tab, formContext?.competition]);

  const mbSections = 2;
  const paddingSections = 3;

  const embedLeaderboard = `<iframe width="100%" height="800px" src="${APP_BASE_URL}/embed/competition/${formContext?.competition?.id}/results" frameBorder="0" noresize="noresize"></iframe>`;
  const embedStore = `<iframe width="100%" height="800px" src="${APP_BASE_URL}/embed/competition/${formContext?.competition?.id}/store" frameBorder="0" noresize="noresize"></iframe>`;

  return (
    <Box>
      <ConfirmDialog
        open={confirmCancel.open}
        description={t('competition.confirm.cancel')}
        labelConfirm={t('button.yes')}
        labelCancel={t('button.no')}
        confirm={() => confirmCancel.callback()}
        cancel={() => setConfirmCancel((prev) => ({ ...prev, open: false }))}
      />

      <Paper square mb={2}>
        <Box display="flex" alignItems="center">
          {!!formContext?.competition.slug && (
            <>
              <IconButton
                size="small"
                className="tw-ml-1 tw-p-2"
                onClick={() => history.push(`/competition/view/${formContext?.competition.slug}`)}
              >
                <ArrowBack />
              </IconButton>
              <Divider orientation="vertical" mx={2} flexItem />
            </>
          )}
          <Tabs
            value={tab}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="disabled tabs example"
          >
            <Tab label={t('label.information')} value="info" onClick={() => setTab('info')} />
            <Tab
              label={t('label.customization')}
              disabled={!formContext?.competition?.id}
              onClick={() => setTab('customization')}
              value="customization"
            />
            <Tab
              label={t('label.categoriesAndAcesses')}
              disabled={!formContext?.competition?.id}
              onClick={() => setTab('categoriesAndAcesses')}
              value="categoriesAndAcesses"
            />
            <Tab
              label={t('label.communication')}
              disabled={!formContext?.competition?.id}
              onClick={() => setTab('communication')}
              value="communication"
            />
            <Tab
              label={t('label.waivers')}
              disabled={!formContext?.competition?.id}
              onClick={() => setTab('waivers')}
              value="waivers"
            />
            <Tab
              label={t('label.payments')}
              disabled={!formContext?.competition?.id}
              onClick={() => setTab('payments')}
              value="payments"
            />
            {formContext?.watch('invitesOnly') && (
              <Tab
                label={t('competition.invites')}
                disabled={!formContext?.competition?.id}
                onClick={() => setTab('invites')}
                value="invites"
              />
            )}
            {formContext?.watch('allowWaitlist') && (
              <Tab
                label={t('competition.waitlists')}
                disabled={!formContext?.competition?.id}
                onClick={() => setTab('waitlists')}
                value="waitlists"
              />
            )}

            <Tab
              label={t('comeptition.manageScores')}
              disabled={!formContext?.competition?.id}
              onClick={() => setTab('manageScores')}
              value="manageScores"
            />

            {formContext.watch('leaderboardOrder') === 'custom_scoring' && (
              <Tab
                label={t('competition.customScoring')}
                disabled={!formContext?.competition?.id}
                onClick={() => setTab('customScoring')}
                value="customScoring"
              />
            )}

            <Tab
              label={t('comeptition.socialCards')}
              disabled={!formContext?.competition?.id}
              onClick={() => setTab('socialCards')}
              value="socialCards"
            />

            {formContext?.watch('heats') && (
              <Tab
                label={t('workout.heats')}
                disabled={!formContext?.competition?.id}
                onClick={() => setTab('heats')}
                value="heats"
              />
            )}
          </Tabs>
        </Box>
      </Paper>

      {formContext?.openModalPayment?.open && formContext?.openModalPayment?.new && (
        <ModalPayAndCreate
          channel={formContext.selectedChannel}
          open={formContext?.openModalPayment?.open}
          confirm={formContext?.openModalPayment?.confirm}
          close={() => {
            formContext.setOpenModalPayment({ open: false });
            formContext.setSubmiting(false);
          }}
          loading={formContext?.loading}
        />
      )}

      {formContext?.openModalPayment?.open && !formContext?.openModalPayment?.new && (
        <ModalPayment
          open={formContext?.openModalPayment?.open}
          setOpen={(v) => formContext.setOpenModalPayment({ open: v })}
        />
      )}

      {(!tab || tab === 'info') && (
        <>
          <Box mb={4}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Box px={paddingSections} className={classes.content}>
                  <CompetitionOverview formContext={formContext} />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                {layoutContext?.isMapsLoaded && (
                  <Box px={paddingSections} className={classes.content}>
                    <MapsLocation formContext={formContext} />
                  </Box>
                )}
              </Grid>
            </Grid>
          </Box>

          <Box mb={mbSections} p={paddingSections} className={classes.content}>
            <CompetitionSettings />
          </Box>

          {formContext.watch('hasDivisions') && (
            <Box mb={mbSections} px={paddingSections} className={classes.content}>
              <Divisions
                setDivisions={formContext.setDivisions}
                divisions={formContext.divisions}
                competition={formContext.competition}
                eventType={formContext?.watch('eventType')}
                payPerDivision={formContext?.watch('payPerDivision')}
                invitesOnly={formContext?.watch('invitesOnly')}
                currency={formContext?.currency}
              />
            </Box>
          )}

          <Box mb={mbSections} px={paddingSections} className={classes.content}>
            <CustomFields
              customFields={formContext?.watch('customFields') || []}
              setCustomFields={(v) => formContext?.setValue('customFields', v)}
              currency={formContext?.currency}
            />
          </Box>

          <Box mb={mbSections} px={paddingSections} className={classes.content}>
            <Coupons coupons={formContext?.coupons} setCoupons={formContext?.setCoupons} />
          </Box>

          <Box mb={mbSections} px={paddingSections} className={classes.content} pb={2}>
            <Box display="flex" alignItems="center" pt={3} px={3}>
              <Box flexGrow="1">
                <Typography variant="h5" mb={3} color="primary">
                  {t('competition.embedTabs')}
                </Typography>
              </Box>
            </Box>
            {formContext?.competition?.id && (
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Paper p={1} px={5} className="paper-rounded">
                    <Box display="flex" mb={1}>
                      <Typography mr={1} variant="h6" style={fontStyle.label}>
                        {t('competition.embed.leaderboard')}
                      </Typography>
                      <IconButton size="small" onClick={() => copyToClipBoard(embedLeaderboard)}>
                        <FileCopy size="small" style={{ fontSize: 12 }} />
                      </IconButton>
                    </Box>
                    <Box className={classes.code}>
                      <code>{embedLeaderboard}</code>
                    </Box>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Paper p={1} px={5} className="paper-rounded">
                    <Box display="flex" mb={1}>
                      <Typography mr={1} variant="h6" style={fontStyle.label}>
                        {t('competition.embed.store')}
                      </Typography>
                      <IconButton size="small" onClick={() => copyToClipBoard(embedStore)}>
                        <FileCopy size="small" style={{ fontSize: 12 }} />
                      </IconButton>
                    </Box>
                    <Box className={classes.code}>
                      <code>{embedStore}</code>
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            )}
          </Box>
        </>
      )}
      {tab === 'customization' && (
        <>
          <Box mb={mbSections} px={paddingSections} className={classes.content}>
            <CompetitionCustomization setValue={formContext.setValue} watch={formContext.watch} />
          </Box>

          <Box mb={mbSections} px={paddingSections} className={classes.content}>
            <BannerAndLogo setValue={formContext.setValue} watch={formContext.watch} />
          </Box>
        </>
      )}
      {tab === 'categoriesAndAcesses' && (
        <>
          <Box mb={mbSections}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Box px={paddingSections} className={classes.content}>
                  <Categories
                    label={t('competition.label.vendorsCategories')}
                    categories={formContext?.watch('vendorsCategories') || []}
                    setCategories={(v) => formContext?.setValue('vendorsCategories', v)}
                    helpText={t('competition.help.addVendorsCategories')}
                    vendors
                    currency={formContext?.currency}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box px={paddingSections} className={classes.content}>
                  <Categories
                    label={t('competition.label.volunteersCategories')}
                    categories={formContext?.watch('volunteersCategories') || []}
                    setCategories={(v) => formContext?.setValue('volunteersCategories', v)}
                    helpText={t('competition.help.addVolunteersCategories')}
                    component={
                      <Box mb={3} display="flex" alignItems="center">
                        <Tooltip
                          color="secondary"
                          title="Checking this box will allow volunteers to fill out any custom fields you have created for registration purposes."
                        >
                          <Info />
                        </Tooltip>
                        <Checkbox
                          label="Use Custom Fields"
                          checked={formContext.watch('useVendorsCustomFields')}
                          onClick={() =>
                            formContext?.setValue(
                              'useVendorsCustomFields',
                              !formContext.watch('useVendorsCustomFields'),
                            )
                          }
                        />
                      </Box>
                    }
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box mb={mbSections}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Box px={paddingSections} className={classes.content}>
                  <Judges watch={formContext.watch} setValue={formContext.setValue} />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box px={paddingSections} className={classes.content}>
                  <UsersEarlyAccess watch={formContext.watch} setValue={formContext.setValue} />
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box mb={mbSections}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Box px={paddingSections} className={classes.content}>
                  <StageLocations
                    label={t('competition.label.stageLocations')}
                    stageLocations={formContext?.watch('stageLocations') || []}
                    setStageLocations={(v) => formContext?.setValue('stageLocations', v)}
                    helpText={t('competition.help.addStageLocations')}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
      {tab === 'communication' && <CompetitionCommunication classes={classes} />}

      {tab === 'waivers' && (
        <Box mb={mbSections} px={paddingSections} className={classes.content} p={2}>
          <DocumentsBuilder
            ownerableId={
              formContext.selectedChannel
                ? formContext.selectedChannel?.id
                : formContext?.competition?.id
            }
            ownerableType={formContext.selectedChannel?.id ? 'Channel' : 'Competition'}
            defaultDocumentWaiverId={formContext?.watch('documentWaiverId')}
            competitionId={formContext?.competition?.id}
          />

          <WaiversUsers />
        </Box>
      )}
      {tab === 'payments' && (
        <>
          <Box mb={mbSections} px={paddingSections} className={classes.content} p={2}>
            {formContext.competition.paidAt ? (
              <Typography>Competition Paid {date(formContext.competition.paidAt)}</Typography>
            ) : (
              <>
                <Box flexGrow="1" alignItems="center" mb={3}>
                  <Typography variant="h5">{t('competition.text.header')}</Typography>
                  <Typography variant="h5">{t('competition.subtext.header')}</Typography>
                </Box>
                <Box display="flex" justifyContent="center" mt={3}>
                  <ButtonPrimary
                    onClick={() => formContext.setOpenModalPayment({ open: true, new: false })}
                  >
                    {t('button.payCompetition')}
                  </ButtonPrimary>
                </Box>
              </>
            )}
          </Box>
          <Box mb={mbSections} px={paddingSections} className={classes.content} p={2}>
            <Box>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <Paper p={2}>
                    <Typography mb={2} variant="h5">
                      {t('competiton.paymentGateway')}
                    </Typography>
                    {formContext?.selectedChannel ? (
                      <Box mx={3} py={3}>
                        {formContext?.selectedChannel?.hasCardpointe ? (
                          <Typography variant="h6">
                            <img
                              alt=""
                              src={integrationTypes.find((x) => x.id === 'cardpointe').img}
                              style={{ width: 14, height: 14, borderRadius: 10, marginRight: 5 }}
                            />
                            Cardpointe from channel {formContext?.selectedChannel.name}
                          </Typography>
                        ) : (
                          <div>
                            {formContext?.selectedChannel?.hasStripe && (
                              <Typography variant="h6">
                                <img
                                  alt=""
                                  src={integrationTypes.find((x) => x.id === 'stripe').img}
                                  style={{
                                    width: 14,
                                    height: 14,
                                    borderRadius: 10,
                                    marginRight: 5,
                                  }}
                                />
                                Stripe from channel {formContext?.selectedChannel.name}
                              </Typography>
                            )}
                          </div>
                        )}

                        <Link onClick={() => setOpenStripe(true)} className="hover" color="primary">
                          {t('competition.connectAlternativeStripe')}
                        </Link>
                        <Dialog open={openStripe} onClose={() => setOpenStripe(false)}>
                          <StripeConnectedAccount competition={formContext.competition} />
                        </Dialog>
                      </Box>
                    ) : (
                      <StripeConnectedAccount competition={formContext.competition} />
                    )}
                  </Paper>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Divider m={2} />

          <Box px={paddingSections} py={3} className={classes.content}>
            <Box>
              <SearchPayments competition={formContext?.competition} />
            </Box>
          </Box>
        </>
      )}

      {tab === 'invites' && <CompetitionInvites classes={classes} />}
      {tab === 'waitlists' && <CompetitionWaitlists classes={classes} />}
      {tab === 'heats' && <CompetitionHeats classes={classes} />}
      {tab === 'customScoring' && <CompetitionCustomScoringTable classes={classes} />}
      {tab === 'manageScores' && (
        <CompetitionsScoresManage competition={formContext.competition} classes={classes} />
      )}
      {tab === 'socialCards' && <CompetitionMaterialDesign classes={classes} />}

      {!['heats', 'manageScores', 'customScoring'].find((x) => x === tab) && (
        <Box px={5} display="flex" justifyContent="flex-end">
          <Box className={classes.boxButtons}>
            <ButtonPrimary
              type="botton"
              size="small"
              onClick={() => formContext.submitForm()}
              mt={10}
              mb={5}
              className={classes.button}
              disabled={formContext.submiting}
            >
              <Box display="flex" alignItems="center">
                <LoaderSm loading={formContext.submiting} width={20} />
                <Typography ml={1} component="span">
                  {t('button.save')}
                </Typography>
              </Box>
            </ButtonPrimary>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default CompetitiionFormData;
