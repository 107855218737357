import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { InputBase } from '@material-ui/core';
import Box from 'core/ui/Box';
import { useDispatch, useSelector } from 'react-redux';
import { InputWrapper } from 'core/ui/ChosenComponents';
import { ButtonPrimary } from 'core/ui/Button';
import FontIcon from 'core/ui/FontIcon';
import LoaderSm from 'core/ui/LoaderSm';
import Texture from 'core/ui/Texture';
import { useTranslation } from 'react-i18next';
import { searchActions, searchSelectors } from 'modules/search';
import Pagination from '@material-ui/lab/Pagination';
import Scrollbars from 'react-custom-scrollbars';
import { secondaryLight } from 'core/ui/Colors';
import ListUsers from './ListUsers';

const SelectorContent = ({ data }) => {
  const { t } = useTranslation();
  return (
    <>
      <Box pt={5} px={5}>
        <InputWrapper
          style={{
            border: data.hideTexture ? `1px solid${secondaryLight}` : 'none',
          }}
        >
          <Box display="flex" style={{ width: '100%' }}>
            <Box display="flex" flexGrow="1" flexWrap="wrap">
              <Box display="flex" flexGrow="1" alignItems="center">
                <InputBase
                  autoFocus={data.autoFocus}
                  value={data.query}
                  onKeyDown={(e) => data.handleKeyDown(e)}
                  onChange={(e) => data.setQuery(e.target.value)}
                  style={{ minWidth: 30, width: '100%', margin: '4px' }}
                  placeholder={t('search.users.placeholder')}
                />
              </Box>
            </Box>

            <Box>
              <ButtonPrimary
                onClick={() => data.search(1)}
                style={{
                  height: '100%',
                  borderRadius: '5px',
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  padding: 10,
                }}
              >
                {!data.loadingUsers && <FontIcon iconName="icon-search" color="white" size={24} />}
                <LoaderSm width={24} loading={data.loadingUsers} />
              </ButtonPrimary>
            </Box>
          </Box>
        </InputWrapper>
      </Box>
      <Box>
        <Scrollbars autoHeight autoHeightMax={300}>
          <ListUsers onSelectedUser={data.onSelectedUser} users={data.dataUsers.data} />
        </Scrollbars>
        {data.dataUsers.pagination && data.dataUsers.pagination.totalPages > 1 && (
          <Box p={3} display="flex" justifyContent="center">
            <Pagination
              onChange={(e, page) => data.search(page)}
              count={data.dataUsers.pagination.totalPages}
              page={data.dataUsers.pagination.currentPage}
            />
          </Box>
        )}
      </Box>
    </>
  );
};
const SelectorUser = ({ onSelectedUser, hideTexture, autoFocus }) => {
  const dispatch = useDispatch();
  const [dataUsers, setDataUsers] = useState({});
  const loadingUsers = useSelector((state) => searchSelectors.getLoadingUsers(state));
  const [query, setQuery] = useState('');

  const search = (p) => {
    dispatch(
      searchActions.getSearchUsersStart(
        {
          query: [query],
          models: ['User'],
          page: p,
        },
        (data) => {
          setDataUsers(data?.user);
        },
      ),
    );
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      search(1);
    }
  };

  useEffect(
    () => () => {
      dispatch(searchActions.clearSearchUsers());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // useEffect(() => {
  //   if (!query) {
  //     dispatch(searchActions.clearSearchUsers());
  //   }
  // }, [dispatch, query]);

  return (
    <Box className="paper-rounded" style={{ width: '100%', borderRadius: 30 }}>
      {hideTexture ? (
        <SelectorContent
          data={{
            hideTexture,
            autoFocus,
            query,
            handleKeyDown,
            setQuery,
            loadingUsers,
            dataUsers,
            search,
            onSelectedUser,
          }}
        />
      ) : (
        <Texture style={{ borderRadius: 30 }}>
          <SelectorContent
            data={{
              hideTexture,
              autoFocus,
              query,
              handleKeyDown,
              setQuery,
              loadingUsers,
              dataUsers,
              search,
              onSelectedUser,
            }}
          />
        </Texture>
      )}
    </Box>
  );
};

SelectorUser.propTypes = {
  onSelectedUser: PropTypes.func,
  autoFocus: PropTypes.bool,
};

SelectorUser.defaultProps = {
  onSelectedUser: () => {},
  autoFocus: true,
};

export default SelectorUser;
