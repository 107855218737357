import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import DatePickerRounded from 'core/ui/DatePickers/DatePickerRounded';
import { ButtonPrimary } from 'core/ui/Button';
import { Search } from '@material-ui/icons';
import { formatDate, formatDateToApi } from 'core/utils/formatters';
import { sessionSelectors } from 'modules/session';
import { useSelector } from 'react-redux';
import useSelectors from 'modules/map/useSelectors';
import { channelReportsModule } from 'modules/channelReports';
import TablePendingMemberships from './Table';

const ScheduleMembership = ({ channel, getReport, loading }) => {
  const { t } = useTranslation();
  const isAdmin = useSelector((state) => sessionSelectors.getIsAdmin(state));

  const data = useSelectors(channelReportsModule, 'data');

  const [filters, setFilters] = useState({
    startAt: formatDate(new Date(), 'MM/DD/YYYY'),
    endAt: formatDate(new Date(), 'MM/DD/YYYY'),
  });

  const getPendingMemberships = ({ retryAll = false } = {}) => {
    getReport({
      startAt: formatDateToApi(filters?.startAt),
      endAt: formatDateToApi(filters?.endAt),
      retryAll,
    });
  };

  useEffect(() => {
    if (isAdmin) {
      getPendingMemberships();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdmin]);

  return (
    <Box>
      <Box display="flex" mb={3} alignItems="flex-end" justifyContent="center" flexWrap="wrap">
        <Box display="flex" mr={3}>
          <Box>
            <DatePickerRounded
              label={t('channel.reports.filter.start').toUpperCase()}
              onChange={(v) => setFilters((prev) => ({ ...prev, startAt: v }))}
              value={filters.startAt}
              disablePast={false}
              usFormat={channel?.useUsDateFormat}
              autoOk
              bordered
            />
          </Box>
          <Box ml={1}>
            <DatePickerRounded
              label={t('channel.reports.filter.end').toUpperCase()}
              onChange={(v) => setFilters((prev) => ({ ...prev, endAt: v }))}
              value={filters.endAt}
              disablePast={false}
              usFormat={channel?.useUsDateFormat}
              autoOk
              bordered
            />
          </Box>
        </Box>
        <Box ml={3}>
          <ButtonPrimary disabled={loading} onClick={() => getPendingMemberships()}>
            <Search style={{ color: 'white' }} fontSize="small" />
            {t('button.view')}&nbsp;
          </ButtonPrimary>
        </Box>
      </Box>

      <TablePendingMemberships
        data={data}
        getPendingMemberships={getPendingMemberships}
        loading={loading}
      />
    </Box>
  );
};

export default ScheduleMembership;
