/* eslint-disable react/prop-types */
import React from 'react';
import { InputLabel } from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import { getBase64 } from 'core/utils/helpers';
import { Publish } from '@material-ui/icons';
import Typography from 'core/ui/Typography';
import Box from 'core/ui/Box';
import { ButtonWhite } from 'core/ui/Button';
import { primary } from 'core/ui/Colors';

const SelectFile = ({ setFile, file }) => {
  const { t } = useTranslation();

  const handleCapture = ({ target }) => {
    if (target.files.length) {
      for (let i = 0; i < target.files.length; i += 1) {
        const f = target.files[i];
        getBase64(f, (result) => {
          setFile({
            url: URL.createObjectURL(f),
            base64: result,
            name: f.name,
            new: true,
          });
        });
      }
    }
  };

  const styleIcon = {
    color: '#404041',
  };

  const randomId = Math.floor(Math.random() * 101);

  return (
    <Box style={{ position: 'relative', zIndex: 2 }}>
      <>
        <ButtonWhite variant="contained" p={1}>
          <InputLabel style={{ color: primary }} htmlFor={`icon-button-file-${randomId}`}>
            <Box display="flex" alignItems="center">
              <Publish style={{ ...styleIcon, color: primary }} size="small" />
              <Typography ml={3} variant="subtitle2">
                {(file && file.name) || t('button.selectTheFile')}
              </Typography>
            </Box>
          </InputLabel>
        </ButtonWhite>
        <input
          color="primary"
          type="file"
          onChange={handleCapture}
          id={`icon-button-file-${randomId}`}
          style={{ display: 'none' }}
          accept=".csv"
          multiple
        />
      </>
    </Box>
  );
};

export default SelectFile;
