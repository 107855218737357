import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import { ButtonPrimary } from 'core/ui/Button';
import CKEditor, { defaultCkEditorText } from 'core/ui/CKEditor';
import Toggle from 'core/ui/Toggle';
import useChannelStyles from 'core/useChannelStyles';
import { Send } from '@material-ui/icons';
import useContactForm from './form';

const ContactForm = ({ channel, contactForm, item, callback, mode }) => {
  const { t } = useTranslation();
  const { buttonColor } = useChannelStyles();
  const { setValue, watch, handleSubmit, onSubmit, loading, errors } = useContactForm({
    mode,
    item,
    callback,
    channel,
    contactForm,
  });

  const defaultCkText = () => {
    if (!watch('text')) {
      return defaultCkEditorText('');
    }

    const newRaw =
      typeof watch('textRaw') === 'string' ? JSON.parse(watch('textRaw')) : watch('textRaw');

    return newRaw;
  };
  return (
    <Box p={5}>
      <div className="tw-flex tw-flex-row">
        <Box display="flex" className="gap-10 tw-grow">
          <Toggle
            options={['email', 'sms'].map((x) => ({
              value: x,
              label: t(`message.method.${x}`),
            }))}
            value={watch('method')}
            onChange={(v) => setValue('method', v)}
            buttonColor={buttonColor}
          />
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <ButtonPrimary disabled={loading} onClick={handleSubmit(onSubmit)}>
            <Send fontSize="small" />
            &nbsp;
            {t('button.send')}
          </ButtonPrimary>
        </Box>
      </div>

      <Box mb={3}>
        <CKEditor
          error={errors && errors.text}
          value={watch('text')}
          onChange={(v) => {
            setValue('text', v?.html);
            setValue('textRaw', v?.raw);
          }}
          defaultValue={defaultCkText()}
          bordered
          allowUploads
          pathUpload={`Channel/${channel?.id}/Messages`}
          editorStyle={{ height: 150 }}
        />
      </Box>
    </Box>
  );
};

export default ContactForm;
