import api from 'core/api';

export const channelsCustomPaymentsModule = 'channelsCustomPayments';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const actions = {
  postChannelsCustomPayments: {
    module: channelsCustomPaymentsModule,
    name: 'postChannelsCustomPayments',
    api: (data) => api.post(`/channels/${data?.channelId}/channels_custom_payments`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  putChannelsCustomPayments: {
    module: channelsCustomPaymentsModule,
    name: 'putChannelsCustomPayments',
    api: (data) =>
      api.put(`/channels/${data?.channelId}/channels_custom_payments/${data?.id}`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  destroy: {
    module: channelsCustomPaymentsModule,
    name: 'destroy',
    api: (data) => api.delete(`/channels/${data?.channelId}/channels_custom_payments/${data?.id}`),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  getChannelsCustomPayments: {
    module: channelsCustomPaymentsModule,
    name: 'getChannelsCustomPayments',
    api: (params) => api.get(`/channels/${params?.channelId}/channels_custom_payments`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: ['data'],
    },
    state: loadingStates,
  },
};

export default {
  actions,
  state: {
    data: [],
    error: null,
    loading: false,
  },
};
