import React, { useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import { Dialog, TableCell, withStyles } from '@material-ui/core';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useTranslation } from 'react-i18next';
import { secondary, secondaryLight } from 'core/ui/Colors';
import Typography from 'core/ui/Typography';
import Paper from 'core/ui/Paper';
import Checkbox from 'core/ui/Checkbox';
import useActions from 'modules/map/useActions';
import useSelectors from 'modules/map/useSelectors';
import { partnersProgramsChannelsModule } from 'modules/partnersProgramsChannels';
import Modules from 'modules';
import { ButtonPrimary } from 'core/ui/Button';
import Box from 'core/ui/Box';
import notifications from 'modules/notifications';
import { useDispatch } from 'react-redux';

export const Cell = withStyles(() => ({
  head: {
    backgroundColor: secondaryLight,
    color: secondary,
    fontSize: 12,
  },
  body: {
    fontSize: 11,
  },
}))(TableCell);

export default function ModalMembershipsBlocks({ program, channel, close, open }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { request } = useActions();
  const membershipBlocks = useSelectors(partnersProgramsChannelsModule, 'membershipBlocks');
  const [checkedAll, setCheckedAll] = useState(true);
  const [checkedItems, setCheckedItems] = useState([]);

  const toggleItem = (planId) => {
    if (checkedItems.find((x) => x === planId)) {
      setCheckedItems(checkedItems.filter((x) => x !== planId));
    } else {
      setCheckedItems([...checkedItems, planId]);
    }
  };

  useEffect(() => {
    if (open) {
      request({
        action: Modules.partnersProgramsChannels.actions.getMembershipPlansBlocks,
        data: {
          channelId: channel?.id,
          partnersProgramId: program?.id,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [program?.id, open]);

  useEffect(() => {
    setCheckedItems(membershipBlocks?.map((x) => x.id));
  }, [membershipBlocks]);

  const toggleSelectAll = () => {
    if (checkedAll) {
      setCheckedItems([]);
      setCheckedAll(false);
    } else {
      setCheckedItems(channel?.membershipAllPlans?.map((x) => x.id));
      setCheckedAll(true);
    }
  };

  const saveItems = () => {
    request({
      action: Modules.partnersProgramsChannels.actions.saveMembershipPlansBlocks,
      data: {
        channelId: channel?.id,
        partnersProgramId: program?.id,
        membershipPlans: checkedItems,
      },
      options: {
        onSuccess: () => {
          dispatch(notifications.success(t('channel.partenrs.programs.memberships')));
          close();
        },
      },
    });
  };

  return (
    <Dialog maxWidth="md" scroll="body" fullWidth onClose={() => close()} open={open}>
      <Paper my={3} p={3}>
        <Typography mb={3} variant="h5">
          {t('channel.memberships.access')}
        </Typography>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table" size="small">
            <TableHead>
              <TableRow>
                <Cell>
                  <Typography className="hover" color="primary" onClick={() => toggleSelectAll()}>
                    {t(!checkedAll ? 'search.button.deselectAll' : 'search.button.selectAll')}
                  </Typography>
                </Cell>
                <Cell>{t('channel.membership.plan.name')}</Cell>
              </TableRow>
            </TableHead>
            <TableBody>
              {channel?.membershipAllPlans?.map((plan, index) => (
                <TableRow key={index.toString()}>
                  <Cell>
                    <Checkbox
                      checked={!checkedItems.find((x) => x === plan?.id)}
                      onClick={() => toggleItem(plan?.id)}
                    />
                  </Cell>
                  <Cell>
                    <Typography>{plan.name}</Typography>
                  </Cell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Box m={3}>
          <ButtonPrimary onClick={() => saveItems()}>{t('button.save')}</ButtonPrimary>
        </Box>
      </Paper>
    </Dialog>
  );
}
