import React from 'react';

import Paper from 'core/ui/Paper';
import { Hidden, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import LoaderSm from 'core/ui/LoaderSm';
import CartItem from './CartItem';

export default function ShoppingCartItems({ buttonColor, shoppingCart, channel }) {
  return (
    <Paper className="paper-rounded" p={3}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <Hidden only={['xs', 'sm']}>
              <TableCell colSpan={2}>Product</TableCell>
              <TableCell align="center">Qty</TableCell>
              <TableCell align="center">Tax</TableCell>
              <TableCell align="center">Total</TableCell>
            </Hidden>
            <Hidden only={['md', 'lg', 'xl']}>
              <TableCell>Product</TableCell>
            </Hidden>
          </TableRow>
        </TableHead>
        <TableBody>
          {shoppingCart?.cartItems.map((item) => (
            <CartItem
              key={item?.option?.id}
              buttonColor={buttonColor}
              cartItem={item}
              shoppingCart={shoppingCart}
              channel={channel}
            />
          ))}
        </TableBody>
      </Table>
      <LoaderSm center loading={shoppingCart?.loadingPreview} />
    </Paper>
  );
}

ShoppingCartItems.defaultProps = {};
