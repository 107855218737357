import React, { useEffect } from 'react';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import Card from 'core/ui/Card';
import { primary } from 'core/ui/Colors';
import LoaderSm from 'core/ui/LoaderSm';
import Checkbox from 'core/ui/Checkbox';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import { ownerableMessagesModule } from 'modules/ownerableMessages';
import useSelectors from 'modules/map/useSelectors';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import { newChannelsModule } from 'modules/channels/new';
import ChoosenChannelTags from 'core/ui/ChoosenChannelTags';
import Paper from 'core/ui/Paper';
import CustomContacts from '../CustomContacts';

const UsersSelectors = ({ textService, channelId }) => {
  const { t } = useTranslation();
  const { request } = useActions();
  const membersTypes = useSelectors(ownerableMessagesModule, 'types');
  const loadingCount = useSelectors(ownerableMessagesModule, 'loadingCount');

  const channelCompetitions = useSelectors(newChannelsModule, 'channelCompetitions');

  useEffect(() => {
    if (channelId) {
      request({
        action: Modules.ownerableMessages.actions.getChannelMembersTypesCount,
        data: { channelId },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelId]);

  useEffect(() => {
    if (channelId) {
      request({
        action: Modules.newChannels.actions.getChannelCompetitions,
        data: { channelId },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelId]);

  const isTypeChecked = (type) => {
    const arr = textService.watch('membersTypes') ? [...textService.watch('membersTypes')] : [];
    if (arr.find((x) => x === type)) {
      return true;
    }

    return false;
  };

  const changeMemberType = (type) => {
    let arr = textService.watch('membersTypes') ? [...textService.watch('membersTypes')] : [];

    if (isTypeChecked(type)) {
      arr = arr.filter((x) => x !== type);
    } else {
      arr.push(type);
    }
    textService.setValue('membersTypes', arr);
  };

  return (
    <>
      <Box mb={3}>
        <Card
          btopcolor={primary}
          btopwidth={2}
          hidedivider="true"
          title={
            <Box display="flex" alignItems="center">
              {t('channel.label.statusType')}
              <LoaderSm loading={loadingCount} />
            </Box>
          }
        >
          <Box px={3} py={1} pb={3}>
            {Object.keys(membersTypes).map((x, i) => {
              const item = membersTypes[x];
              return (
                <Checkbox
                  key={i.toString()}
                  label={`${t(`channel.memberships.column.type.${item.name}`)} (${item.count})`}
                  checked={isTypeChecked(item.name)}
                  onClick={() => changeMemberType(item.name)}
                />
              );
            })}
          </Box>
        </Card>
      </Box>

      <Box my={3}>
        <Card
          btopcolor={primary}
          btopwidth={2}
          hidedivider="true"
          title={<>{t('channel.label.memberTags')}</>}
        >
          <Box px={3} py={1} pb={3}>
            <ChoosenChannelTags
              placeholder={t('channel.tags')}
              val={textService.watch('membersTags') ? [...textService.watch('membersTags')] : []}
              setValue={(v) => textService.setValue('membersTags', v)}
              channelId={channelId}
              bordered
            />
          </Box>
        </Card>
      </Box>
      <Box mb={3}>
        <Card
          btopcolor={primary}
          btopwidth={2}
          hidedivider="true"
          title={<>{t('channel.label.membershipsTags')}</>}
        >
          <Box px={3} py={1} pb={3}>
            <ChoosenChannelTags
              placeholder={t('channel.tags')}
              val={
                textService.watch('membershipsTags')
                  ? [...textService.watch('membershipsTags')]
                  : []
              }
              setValue={(v) => textService.setValue('membershipsTags', v)}
              channelId={channelId}
              bordered
            />
          </Box>
        </Card>
      </Box>

      <Box className="tw-flex tw-flex-col tw-gap-y-4">
        <Paper p={3}>
          <Checkbox
            label="Custom"
            checked={!!textService.watch('showCustom')}
            onClick={() => textService.setValue('showCustom', !textService.watch('showCustom'))}
          />

          {!!textService.watch('showCustom') && (
            <Box>
              <CustomContacts textService={textService} channelId={channelId} />
            </Box>
          )}
        </Paper>

        <Paper p={3}>
          <Checkbox
            label="Competitions"
            checked={!!textService.watch('showCompetitions')}
            onClick={() =>
              textService.setValue('showCompetitions', !textService.watch('showCompetitions'))
            }
          />

          {!!textService.watch('showCompetitions') && (
            <Box>
              <SelectBoxObject
                label="Competitions"
                options={channelCompetitions || []}
                value={textService.watch('competitionId')}
                setValue={(v) => textService.setValue('competitionId', v)}
                bordered
              />
            </Box>
          )}
        </Paper>
      </Box>
    </>
  );
};

export default UsersSelectors;
