/* eslint-disable react/prop-types */
import React, { useRef, useState } from 'react';
import Paper from 'core/ui/Paper';
import Box from 'core/ui/Box';
import {
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Popper,
} from '@material-ui/core';
import { ButtonPrimary } from 'core/ui/Button';
import { ArrowDropDown } from '@material-ui/icons';
import LoaderSm from 'core/ui/LoaderSm';
import { useTranslation } from 'react-i18next';
import Typography from 'core/ui/Typography';
import { useHistory } from 'react-router';
import { secondary } from 'core/ui/Colors';

const partnersTabs = ['information', 'store', 'library', 'programs', 'reports'];

export default ({ screen, partner, loading }) => {
  const { t } = useTranslation();
  const refElement = useRef();
  const [open, setOpen] = useState(false);

  const history = useHistory();

  const menuItems = partnersTabs.map((menu) => (
    <MenuItem
      onClick={() => {
        setOpen(false);
        history.push(`/partner/${partner?.id}/${menu}`);
      }}
    >
      {t(`partner.menu.${menu}`)}
    </MenuItem>
  ));

  if (menuItems.length === 0) {
    return <></>;
  }

  return (
    <Box>
      <ButtonGroup ref={refElement} variant="contained" color="primary" aria-label="split button">
        <ButtonPrimary style={{ width: 150 }} buttonColor={secondary} onClick={() => setOpen(true)}>
          <LoaderSm width={20} loading={loading} />
          <Typography noWrap>{screen ? t(`partner.menu.${screen}`) : 'Menu'}</Typography>
          <ArrowDropDown />
        </ButtonPrimary>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={refElement?.current}
        role={undefined}
        transition
        style={{ zIndex: 10 }}
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={() => setOpen(false)}>
                <MenuList id="split-button-menu">
                  {menuItems?.map((x, index) => (
                    <React.Fragment key={index}>{x}</React.Fragment>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};
