/* eslint-disable react/prop-types */
import * as React from 'react';
import Typography from 'core/ui/Typography';
import SFDataTable from 'core/ui/DataTable';
import Box from 'core/ui/Box';
import { Link, colors } from '@material-ui/core';
import { date } from 'core/utils/formatters';
import UserMembershipPlanResume from '../Membership/UserMembershipPlanResume';

export default function TableMemberships({ channelKeys, channel }) {
  const columns = [
    {
      name: 'Member',
      selector: 'userId',
      sortable: true,
      cell: (row) => (
        <Link
          href={`/channel/manage/${channel?.id}/members/${row?.user?.id}`}
          className="hover"
          target="_blank"
        >
          <Typography className="tw-text-xs">{row?.user?.name}</Typography>
        </Link>
      ),
    },
    {
      name: 'Joined',
      selector: 'userJoinedAt',
      sortable: true,
      cell: (row) => <Typography className="tw-text-xs">{row?.userJoinedAt}</Typography>,
    },
    {
      name: 'Membership',
      sortable: false,
      cell: (row) => (
        <Box>
          <UserMembershipPlanResume channelKey={row} />
        </Box>
      ),
    },
    {
      name: 'Start',
      selector: 'startAt',
      cell: (row) => (
        <Typography className="tw-text-xs">
          {row?.startAt && date(row?.startAt, channel?.dateFormatReact)}
        </Typography>
      ),
      sortable: true,
      center: true,
    },
    {
      name: 'Expires/Renew',
      selector: 'expiryAt',
      cell: (row) => (
        <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-1">
          <Typography className="tw-text-xs">
            {row?.expiryAt && date(row?.expiryAt, channel?.dateFormatReact)}
          </Typography>

          <div className="tw-flex tw-flex-row tw-items-center tw-gap-1">
            {!row.pauseAt && row.expired && (
              <div className="tw-flex tw-w-20 tw-items-center tw-justify-center tw-rounded-md  tw-bg-red-400 tw-text-xs tw-text-white">
                Expired
              </div>
            )}
            {!row.active && (
              <div className="tw-flex tw-w-20 tw-items-center tw-justify-center tw-rounded-md  tw-bg-red-400 tw-text-xs tw-text-white">
                Inactive
              </div>
            )}
          </div>
        </div>
      ),
      sortable: true,
      center: true,
    },
    {
      name: 'Plan Expires',
      selector: 'expires',
      cell: (row) => (
        <Typography className="tw-text-xs">
          {row.planExpiryAt ? date(row.planExpiryAt, channel?.dateFormatReact) : 'No Expiry'}
        </Typography>
      ),
      sortable: true,
      center: true,
    },
    {
      name: 'Contract',
      selector: 'expiryAt',
      cell: (row) => (
        <Typography>
          {row?.contractStartDate && (
            <div className="tw-flex tw-flex-col tw-items-center">
              <Typography color="secondary" className="tw-text-xs">
                {row?.membershipPlan?.documentContract?.name}
              </Typography>
              <Typography color="secondary" className="tw-text-xs tw-font-semibold">
                {date(row?.contractStartDate, channel?.dateFormatReact)}&nbsp;-&nbsp;
                {date(row?.contractEndDate, channel?.dateFormatReact)}
              </Typography>
            </div>
          )}
        </Typography>
      ),
      sortable: true,
      center: true,
    },
  ];

  return (
    <div>
      <SFDataTable
        conditionalRowStyles={conditionalRowStyles}
        noHeader
        columns={columns}
        data={channelKeys}
        pagination
        paginationDefaultPage={25}
        paginationPerPage={25}
        paginationRowsPerPageOptions={[10, 25, 50, 100]}
        showExport={false}
      />
    </div>
  );
}

const conditionalRowStyles = [
  {
    when: (row) => row.expired || !row?.active,
    style: {
      backgroundColor: colors.red[50],
      userSelect: 'none',
    },
  },
  {
    when: (row) => !!row.pauseAt,
    style: {
      backgroundColor: colors.yellow[50],
      userSelect: 'none',
    },
  },
];
