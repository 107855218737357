import React, { useEffect } from 'react';
import Box from 'core/ui/Box';
import { useSelector } from 'react-redux';
import { channelsSelectors } from 'modules/channels';
import Settings from 'components/Profile/Dashboard/Messages/Settings';
import useMessageSettings from './useMessageSettings';

export default () => {
  const channel = useSelector((state) => channelsSelectors.getChannel(state));

  const { getMessageTypes, messagesTypes, toggleMessageType, localSettings, setLocalSettings } =
    useMessageSettings({ channelId: channel?.id });

  useEffect(() => {
    if (channel?.id) {
      getMessageTypes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel]);

  return (
    <Box p={3}>
      <Settings
        localSettings={localSettings}
        messagesTypes={messagesTypes}
        setLocalSettings={setLocalSettings}
        channel={channel}
        toggleType={toggleMessageType}
      />
    </Box>
  );
};
