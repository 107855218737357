import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import InputBase from '@material-ui/core/InputBase';
import { MenuItem, Select, Paper } from '@material-ui/core';
import uuid from 'react-uuid';
import Typography, { fontStyle } from '../Typography';
import Box from '../Box';
import LoaderSm from '../LoaderSm';
import { secondary, secondaryChat, secondaryLight } from '../Colors';

const BootstrapInput = withStyles(() => ({
  input: {
    borderRadius: 5,
    position: 'relative',
    width: '100%',
    fontSize: 12,
    paddingLeft: 5,
    '&:focus': {
      borderRadius: 5,
      borderColor: '#80bdff',
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 5,
    width: '100%',
    paddingTop: 3,
    paddingBottom: 3,
  },
  select: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontSize: 12,
  },
  placeHolder: {
    color: '#C7C7CD',
  },
}));

export default function SelectBoxObject({
  options,
  propLabel,
  propValue,
  emptyItem,
  emptyValue,
  placeholder,
  value,
  disabled,
  label,
  error,
  setValue,
  required,
  elevation,
  bordered,
  defaultValue,
  loading,
  height,
  labelColor,
  style,
  multiple,
}) {
  const emptyRandom = uuid();
  const classes = useStyles();
  const styleInput = {
    // eslint-disable-next-line no-nested-ternary
    border: bordered && !error ? `1px solid ${secondaryLight}` : error ? '1px solid red' : 'none',
    ...style,
  };

  return (
    <>
      {label !== '' && (
        <Box mb={2} ml={2} display="flex">
          <Typography style={{ color: labelColor, ...fontStyle.label }} variant="body1">
            {label}
          </Typography>
          {required && (
            <Typography ml={1} color="error">
              *
            </Typography>
          )}
          {loading && <LoaderSm loading width={16} />}
        </Box>
      )}
      <Paper
        className={`${error ? 'input-paper-error' : ''} ${classes.root} opacity-80`}
        style={styleInput}
        elevation={elevation}
      >
        <Select
          className={`${classes.select} ${value === 'undefined' ? classes.placeHolder : ''}`}
          value={value || emptyRandom}
          disabled={disabled}
          onChange={(e) => setValue(e?.target?.value === emptyRandom ? null : e?.target?.value)}
          input={<BootstrapInput style={{ backgroundColor: 'transparent' }} />}
          multiple={multiple}
          style={{
            minWidth: 130,
            height,
            color: !value ? secondaryChat : secondary,
          }}
          defaultValue={defaultValue}
        >
          {emptyItem && (
            <MenuItem value={emptyValue || emptyRandom} style={{ height: 40, color: 'grey' }}>
              {placeholder}
            </MenuItem>
          )}
          {(options || []).map((x, index) => (
            <MenuItem key={index.toString()} value={`${x[propValue]}`}>
              {x[propLabel]}
            </MenuItem>
          ))}
        </Select>
      </Paper>
    </>
  );
}

SelectBoxObject.propTypes = {
  label: PropTypes.string,
  propValue: PropTypes.string,
  propLabel: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  emptyItem: PropTypes.bool,
  emptyValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  // eslint-disable-next-line react/forbid-prop-types
  error: PropTypes.object,
  setValue: PropTypes.func,
  required: PropTypes.bool,
  elevation: PropTypes.number,
};

SelectBoxObject.defaultProps = {
  label: '',
  propValue: 'id',
  propLabel: 'name',
  placeholder: '',
  options: [],
  value: '',
  emptyItem: false,
  error: null,
  setValue: null,
  required: false,
  emptyValue: 0,
  elevation: 3,
};
