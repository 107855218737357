import React from 'react';
import PropTypes from 'prop-types';
import Typography, { fontStyle } from 'core/ui/Typography';
import Box from 'core/ui/Box';
import ImageMediaUser from 'components/Profile/ImageMedia';

export default function CardUser({ user, onClick }) {
  return (
    <Box className="link-hover">
      <Box style={{ position: 'relative' }}>
        <Box display="flex" onClick={() => onClick()}>
          <ImageMediaUser
            size={40}
            sizeCountry="1em"
            heightCountry={15}
            user={user}
            image={user.image ? user.image : {}}
          />
          <Box ml={5}>
            <Typography color="secondary" variant="subtitle2">
              {user.name}
            </Typography>
            {user.localGym && (
              <Typography color="secondary" style={fontStyle.xSmall}>
                {user.localGym}
              </Typography>
            )}
            {user.state && (
              <Typography color="secondary" style={fontStyle.xSmall}>
                {user.state}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

CardUser.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.object,
  onClick: PropTypes.func,
};

CardUser.defaultProps = {
  user: {},
  onClick: () => {},
};
