import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import { Container } from '@material-ui/core';
import { LayoutContext } from 'pages/shared/Layout';
import OnboardingReport from 'components/Admin/Reports/OnboardingReport';
import Texture from 'core/ui/Texture';

const GymsOnboarding = () => {
  const { t } = useTranslation();

  const layoutContext = useContext(LayoutContext);
  useEffect(() => {
    layoutContext.setTitleBar(t('sidebar.gymOnboarding').toUpperCase());
    layoutContext.setActiveMenu('gymsOnboarding');
  }, [layoutContext, t]);

  return (
    <Texture fullHeight>
      <Container maxWidth="xl">
        <Box pb={4}>
          <OnboardingReport />
        </Box>
      </Container>
    </Texture>
  );
};

export default GymsOnboarding;
