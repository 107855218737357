import React, { useEffect } from 'react';
import propTypes from 'prop-types';
import Box from 'core/ui/Box';
import { Slider, withStyles } from '@material-ui/core';
import Button from 'core/ui/Button';
import { VolumeOff, VolumeUp } from '@material-ui/icons';
import PauseIcon from '@material-ui/icons/Pause';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import LoaderSm from 'core/ui/LoaderSm';
import { primary, secondary } from 'core/ui/Colors';
import { hhmmss } from 'core/utils/formatters';
import Typography from 'core/ui/Typography';

const PrettoSlider = withStyles({
  root: {
    color: primary,
    padding: 0,
    paddingBottom: 3,
  },
  thumb: {
    height: 15,
    width: 15,
    backgroundColor: '#fff',
    marginTop: -5,
    marginLeft: -5,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
    display: 'none',
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
    backgroundColor: secondary,
  },
  mark: {
    backgroundColor: secondary,
    height: 8,
    width: 2,
  },
  markLabel: {
    fontSize: 12,
    color: secondary,
    marginTop: -10,
  },
  markActive: {
    opacity: 1,
    backgroundColor: primary,
  },
})(Slider);

export default function Player({
  time,
  playing,
  setPlaying,
  setTime,
  setSeek,
  muted,
  setMuted,
  buttonColor,
  durationSeconds,
  ready,
  marks,
  setManualSeek,
}) {
  const toggleClass = () => {
    setPlaying(!playing);
  };

  const changeTime = (newValue) => {
    setPlaying(false);
    setTimeout(() => {
      setTime((prev) => ({
        ...prev,
        complete: newValue,
      }));
      setSeek(newValue);
      if (setManualSeek) {
        setManualSeek(true);
      }
    });
  };

  useEffect(() => {
    if (Math.ceil(time.complete) >= Math.round(time.total)) {
      setTimeout(() => {
        setPlaying(false);
      }, 800);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time.complete, time.total]);

  // const resetSlider = () => {
  //   setPlaying(false);
  //   changeTime(time.seek + 0.0001);
  // };

  const iconSize = 40;

  return (
    <Box py={1.5} style={{ posititon: 'relative' }}>
      <PrettoSlider
        min={0}
        disabled={!ready}
        style={{ width: '100%' }}
        max={Number(durationSeconds)}
        value={time.complete}
        onChangeCommitted={(e, newValue) => changeTime(newValue)}
        aria-labelledby="continuous-slider"
        step={1}
        marks={marks}
      />

      <>
        <Box flexGrow={1} mt={marks ? -1 : 1} display="flex" alignItems="center">
          <Button
            style={{ padding: 1, margin: 0.5 }}
            onClick={() => (!ready ? null : toggleClass())}
          >
            {!ready ? (
              <LoaderSm loading />
            ) : (
              <>
                {playing && <PauseIcon style={{ color: buttonColor, fontSize: iconSize }} />}
                {!playing && <PlayArrowIcon style={{ color: buttonColor, fontSize: iconSize }} />}
              </>
            )}
          </Button>
          <Box mx={3}>
            <Typography color="secondary" component="h1" variant="body2">
              {hhmmss(Math.round(time.complete))}
            </Typography>
          </Box>
          <Button style={{ padding: 1, margin: 0.5 }} onClick={() => setMuted(!muted)}>
            {!ready ? (
              <LoaderSm loading />
            ) : (
              <>
                {muted ? (
                  <VolumeOff style={{ color: buttonColor, fontSize: iconSize }} />
                ) : (
                  <VolumeUp style={{ color: buttonColor, fontSize: iconSize }} />
                )}
              </>
            )}
          </Button>
        </Box>
      </>
    </Box>
  );
}

Player.propTypes = {
  playing: propTypes.bool,
  muted: propTypes.bool,
  setMuted: propTypes.func,
  setPlaying: propTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  time: propTypes.object,
  setTime: propTypes.func,
};

Player.defaultProps = {
  playing: false,
  muted: false,
  setMuted: () => {},
  setPlaying: () => {},
  time: {},
  setTime: () => {},
};
