/* eslint-disable camelcase */
import React, { useContext, useEffect } from 'react';
import Modules from 'modules';
import { conversationsModule } from 'modules/conversations';
import useActions from 'modules/map/useActions';
import useSelectors from 'modules/map/useSelectors';
import { useSelector } from 'react-redux';
import Box from 'core/ui/Box';
import { chatColors } from 'core/ui/Colors';
import Typography from 'core/ui/Typography';
import { colors, makeStyles } from '@material-ui/core';
import Scrollbars from 'react-custom-scrollbars';
import Divider from 'core/ui/Divider';
import { retrieveAuthHeaders } from 'core/api/auth-headers';
import { persistData, retrieveData } from 'core/utils/session-storage';
import { BASE_API_URL_SOCKET } from 'core/env';
import { sessionSelectors } from 'modules/session';
import { ChatContext } from 'contexts';
import AvatarCoach from 'components/Profile/AvatarCoach';
import { ButtonPrimary } from 'core/ui/Button';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import GroupItem from './GroupItem';
import useSendMessages from './useSendMessages';

const actioncable = require('actioncable');

const useStyles = makeStyles((theme) => ({
  sidebar: {
    width: 250,
    [theme.breakpoints.down('sm')]: {
      width: 0,
    },
  },
}));

const ChatGroups = ({ close }) => {
  const { request } = useActions();
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const chatContext = useContext(ChatContext);
  const currentUser = useSelector((state) => sessionSelectors.getUser(state));
  const groupsChat = useSelectors(conversationsModule, 'groupsChat');
  const groupsChats = useSelectors(conversationsModule, 'groupsChats');
  const { getGroupsChat, getGroupsChats, joinGroup, unjoinGroup } = useSendMessages();

  useEffect(() => {
    getGroupsChats();

    return () => {
      request({ action: Modules.conversations.actions.clearGroupsChat, data: null });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateGroup = (data) => {
    if (data?.groups_chat_id) {
      request({
        action: Modules.conversations.actions.updateGroupChat,
        data: {
          groupsChatId: data?.groups_chat_id,
          ownerableId: chatContext?.ownerableId,
          ownerableType: chatContext?.ownerableType,
        },
        options: {
          onSuccess: (resp) => {
            request({
              action: Modules.conversations.actions.setGroupsChats,
              data: groupsChats?.map((item) => {
                if (item?.id === resp?.id) {
                  return resp;
                }

                return item;
              }),
            });
          },
        },
      });
    }
  };

  useEffect(() => {
    let channelCable = null;
    let cable = null;

    const logAuth = retrieveAuthHeaders(retrieveData)();
    const { client, uid } = logAuth;

    const authHeaders = {
      'access-token': logAuth['access-token'],
      client,
      uid,
    };
    const queryString = new URLSearchParams(authHeaders).toString();

    cable = actioncable.createConsumer(`${BASE_API_URL_SOCKET}?${queryString}`);
    channelCable = cable.subscriptions.create(
      {
        channel: 'ConversationsUpdateChannel',
        user_id: currentUser?.id,
      },
      {
        connected: () => {
          console.log('connected!');
        },
        disconnected: () => {},
        received: (data) => updateGroup(data),
      },
    );

    return () => {
      if (channelCable) {
        channelCable.unsubscribe();
      }
      if (cable) {
        cable.disconnect();
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupsChat]);

  const cacheGroup = (gId) => {
    persistData(
      'CHAT_CACHE_DATA',
      JSON.stringify({
        ownerableId: chatContext?.ownerableId,
        ownerableType: chatContext?.ownerableType,
        groupsChatId: gId,
      }),
    );
  };

  const loadGroup = (gId) => {
    cacheGroup(gId);
    getGroupsChat({
      groupsChatId: gId,
      options: {
        onSuccess: () => {
          request({
            action: Modules.conversations.actions.setGroupsChats,
            data: groupsChats?.map((item) => {
              if (item?.id === gId) {
                return { ...item, unreadCount: 0 };
              }

              return item;
            }),
          });
        },
      },
    });
  };

  const handleJoinGroup = (item) => {
    if (item?.joined) {
      unjoinGroup({
        groupsChatId: item?.id,
        callback: () => {
          getGroupsChats();
          loadGroup(item?.id);
        },
      });
      return;
    }
    joinGroup({
      groupsChatId: item?.id,
      callback: () => {
        getGroupsChats();
        loadGroup(item?.id);
      },
    });
  };

  return (
    <Box
      className={classes.sidebar}
      display="flex"
      flexDirection="column"
      style={{ backgroundColor: chatColors.background }}
    >
      <Box mt={3} pl={3}>
        <Typography style={{ color: colors.grey[200] }} variant="h6">
          {chatContext?.ownerable?.name}
        </Typography>
      </Box>
      <Divider mt={1} style={{ backgroundColor: colors.grey[800] }} />
      <Scrollbars style={{ flex: 1, paddingLeft: 2 }}>
        {groupsChats.map((item) => (
          <GroupItem
            key={item?.id}
            groupsChat={groupsChat}
            item={item}
            loadGroup={loadGroup}
            handleJoinGroup={handleJoinGroup}
          />
        ))}
      </Scrollbars>
      <Divider m={1} style={{ backgroundColor: colors.grey[800] }} />
      {chatContext?.ownerableId && chatContext?.ownerable?.isCoach && (
        <div>
          <div className="tw-flex tw-items-center tw-justify-center">
            <ButtonPrimary
              onClick={() => {
                history.push(`/competition/edit/${chatContext?.ownerableId}/communication`);
                close();
              }}
            >
              {t('button.communicationCenter').toUpperCase()}
            </ButtonPrimary>
          </div>
          <Divider m={1} style={{ backgroundColor: colors.grey[800] }} />
        </div>
      )}
      <Box display="flex" p={2} alignItems="center" justifyContent="flex-start" className="gap-5">
        <AvatarCoach user={currentUser} size={20} />
        <Typography style={{ color: colors.grey[400], fontSize: 12 }} variant="h6">
          {currentUser?.name}
        </Typography>
      </Box>
    </Box>
  );
};

export default ChatGroups;
