import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import Box from 'core/ui/Box';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import Typography, { fontStyle } from 'core/ui/Typography';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import Checkbox from 'core/ui/Checkbox';

const CancellationPolicy = ({ watch, setValue, bordered }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box>
      <Box>
        <Checkbox
          checked={!!watch('cancellationPolicies.active')}
          onClick={() =>
            setValue('cancellationPolicies.active', !watch('cancellationPolicies.active'))
          }
          label={
            <Typography color="textSecondary">
              {t('workout.label.setCancellationPolicy')}
            </Typography>
          }
          color="primary"
        />
      </Box>
      {watch('cancellationPolicies.active') && (
        <Box>
          <Box m={1} mt={2} className={classes.registrationsWithinContainer}>
            <Box>
              <Typography style={fontStyle.label} mb={1}>
                {t('workout.cancellationPolicies.cancelClassNumber').toUpperCase()}
              </Typography>
              <Box display="flex" alignItems="center">
                <Box>
                  <TextFieldRounded
                    onChange={(v) => setValue('cancellationPolicies.cancelClassNumber', v)}
                    value={watch('cancellationPolicies.cancelClassNumber')}
                    type="number"
                    pattern="[0-9]*"
                    bordered={bordered}
                  />
                </Box>

                <Box ml={1}>
                  <SelectBoxObject
                    emptyItem={false}
                    propValue="id"
                    propLabel="name"
                    options={['minutes', 'hours', 'days'].map((x) => ({
                      id: x,
                      name: t(`workout.limit.type.${x}`),
                    }))}
                    setValue={(v) => setValue('cancellationPolicies.cancelClassNumberType', v)}
                    value={watch('cancellationPolicies.cancelClassNumberType')}
                    bordered={bordered}
                  />
                </Box>
              </Box>
            </Box>

            <Box>
              <Typography className={classes.label} style={fontStyle.label} mb={1}>
                {t('workout.cancellationPolicies.minimumMembers')}
              </Typography>

              <Box display="flex" alignItems="center">
                <TextFieldRounded
                  onChange={(v) => setValue('minRegisteredPeoplePolicy', v)}
                  value={watch('minRegisteredPeoplePolicy')}
                  type="number"
                  pattern="^[1-9][0-9]*$"
                  bordered={bordered}
                  disabled={!watch('cancellationPolicies.cancelClassNumber')}
                />
              </Box>
            </Box>
          </Box>

          <Box m={1} mt={2}>
            <Typography style={fontStyle.label} mb={1}>
              {t('workout.cancellationPolicies.cancelRegistrationLimitNumber').toUpperCase()}
            </Typography>
            <Box display="flex" alignItems="center">
              <Box>
                <TextFieldRounded
                  onChange={(v) =>
                    setValue('cancellationPolicies.cancelRegistrationLimitNumber', v)
                  }
                  value={watch('cancellationPolicies.cancelRegistrationLimitNumber')}
                  type="number"
                  pattern="[0-9]*"
                  bordered={bordered}
                />
              </Box>

              <Box ml={1}>
                <SelectBoxObject
                  emptyItem={false}
                  propValue="id"
                  propLabel="name"
                  options={['minutes', 'hours', 'days'].map((x) => ({
                    id: x,
                    name: t(`workout.limit.type.${x}`),
                  }))}
                  setValue={(v) =>
                    setValue('cancellationPolicies.cancelRegistrationLimitNumberType', v)
                  }
                  value={watch('cancellationPolicies.cancelRegistrationLimitNumberType')}
                  bordered={bordered}
                />
              </Box>
            </Box>
          </Box>

          <Box>
            {watch('cancellationPolicies.cancelRegistrationLimitNumber') &&
              watch('cancellationPolicies.cancelRegistrationLimitNumberType') && (
                <>
                  <Box>
                    <Checkbox
                      checked={!!watch('cancellationPolicies.cancelRegistrationLimitLate')}
                      onClick={() => {
                        if (watch('cancellationPolicies.cancelRegistrationLimitLate')) {
                          setValue(
                            'cancellationPolicies.cancelRegistrationLimitLateAmount',
                            '0,00',
                          );
                        }
                        setValue(
                          'cancellationPolicies.cancelRegistrationLimitLate',
                          !watch('cancellationPolicies.cancelRegistrationLimitLate'),
                        );
                      }}
                      label={
                        <Typography color="textSecondary">
                          {t('workout.label.cancelRegistrationLimitLate')}
                        </Typography>
                      }
                      color="primary"
                    />
                  </Box>

                  {watch('cancellationPolicies.cancelRegistrationLimitLate') && (
                    <Box mt={2}>
                      <TextFieldRounded
                        type="number"
                        pattern="[1-9]*"
                        value={
                          watch('cancellationPolicies.cancelRegistrationLimitLateAmount') || ''
                        }
                        placeholder={t('workout.label.cancelRegistrationLimitLateAmount')}
                        onChange={(v) =>
                          setValue('cancellationPolicies.cancelRegistrationLimitLateAmount', v)
                        }
                        required
                        bordered
                        currency
                      />
                    </Box>
                  )}
                </>
              )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default CancellationPolicy;

const useStyles = makeStyles({
  label: {
    textTransform: 'uppercase',
  },
  registrationsWithinContainer: {
    display: 'flex',
    gap: 8,
  },
});
