import React from 'react';
import Box from 'core/ui/Box';
import Typography from 'core/ui/Typography';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';

export default ({ title, cancel }) => (
  <Box display="flex" alignItems="center">
    <Box flexGrow={1}>
      <Typography variant="h5">{title}</Typography>
    </Box>
    <IconButton onClick={cancel} size="small">
      <Close />
    </IconButton>
  </Box>
);
