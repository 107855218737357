import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import SearchChosen from 'core/ui/SearchChosen';
import { useSelector, useDispatch } from 'react-redux';
import { competitionsSearchActions, competitionsSearchSelectors } from 'modules/competitionsSearch';

const Header = ({ search }) => {
  const { t } = useTranslation();
  const queryData = useSelector((state) => competitionsSearchSelectors.getQueryData(state));
  const dispatch = useDispatch();

  const handleSearch = () => {
    search(1);
  };

  return (
    <Box py={3} pt={5} display="flex">
      <Box flexGrow="1">
        <SearchChosen
          limit={10}
          competitions
          placeholder={t('search.placeholder')}
          val={queryData}
          handleSearch={handleSearch}
          model="Competition"
          setValue={(v) => dispatch(competitionsSearchActions.setQueryData(v))}
        />
      </Box>
    </Box>
  );
};

export default Header;

Header.propTypes = {
  search: PropTypes.func,
};

Header.defaultProps = {
  search: () => {},
};
