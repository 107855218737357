import { useTranslation } from 'react-i18next';
import notifications from 'modules/notifications';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import useSelectors from 'modules/map/useSelectors';
import { date, formatDateApi } from 'core/utils/formatters';
import { channelsCoachesSchedulesModule } from 'modules/channelsCoachesSchedules';

const useCoachManage = ({ coach, channel, close }) => {
  const { t } = useTranslation();
  const { request } = useActions();
  const dispatch = useDispatch();
  const schedules = useSelectors(channelsCoachesSchedulesModule, 'data');
  const loadingSchedules = useSelectors(channelsCoachesSchedulesModule, 'loadingSchedules');
  const [modalConfirmSave, setModalConfirmSave] = useState([]);
  const [scheduleOptions, setScheduleOptions] = useState([]);
  const [tab, setTab] = useState(0);

  const getSchedules = () => {
    request({
      action: Modules.channelsCoachesSchedules.actions.getCoachesSchedules,
      data: {
        channelId: channel?.id,
        userId: coach?.id,
      },
    });
  };

  const populateSchedules = () => {
    const data = {};
    schedules.forEach((schedule) => {
      const key = `${schedule.startTime}-${schedule.endTime}`;
      if (!data[key]) {
        const baseDate = date(new Date(), 'DD-MM-YYYY');

        data[key] = {
          weekDays: [],
          startTime: formatDateApi(`${baseDate} ${schedule.startTime}`, 'YYYY-MM-DD HH:mm:ss a Z'),
          endTime: formatDateApi(`${baseDate} ${schedule.endTime}`, 'YYYY-MM-DD HH:mm:ss a Z'),
          calendarColor: schedule?.calendarColor,
          channelsWorkoutsTypeId: schedule?.channelsWorkoutsTypeId,
        };
      }

      data[key].weekDays.push({ day: schedule?.dayOfWeek, every: schedule?.every || 1 });
    });

    const newOptions = Object.keys(data).map((k) => ({ ...data[k] }));
    setScheduleOptions(newOptions);
  };

  useEffect(() => {
    if (channel?.id && coach?.id) {
      getSchedules();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel, coach]);

  useEffect(() => {
    if (schedules?.length > 0) {
      populateSchedules();
    } else {
      setScheduleOptions([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schedules]);

  const saveSchedules = () => {
    setModalConfirmSave({
      open: true,
      cancel: () => {
        setModalConfirmSave({ open: false });
      },
      save: ({ startAtUpdateWorkouts, updateWorkouts, removeOption }) => {
        setModalConfirmSave({ open: false });
        request({
          action: Modules.channelsCoachesSchedules.actions.save,
          data: {
            channelId: channel?.id,
            userId: coach?.id,
            scheduleOptions,
            startAtUpdateWorkouts,
            updateWorkouts,
            removeOption,
          },
          options: {
            onSuccess: () => {
              dispatch(notifications.success(t('channel.coach.schedules.saved')));

              if (close) {
                close();
              }
            },
            onError: (error) => {
              dispatch(notifications.error(error));
            },
          },
        });
      },
    });
  };

  return {
    tab,
    setTab,
    scheduleOptions,
    setScheduleOptions,
    saveSchedules,
    modalConfirmSave,
    setModalConfirmSave,
    loadingSchedules,
  };
};

export default useCoachManage;
