import React, { useEffect, useRef, useState } from 'react';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import { ButtonPrimary, ButtonPrimarySm } from 'core/ui/Button';
import Typography from 'core/ui/Typography';
import { primary, secondaryXLight } from 'core/ui/Colors';
import Grid from 'core/ui/Grid';
import TimerSection from 'components/Workout/Timer';
import { Close, KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons/';
import { ButtonGroup, Popover, Slider, styled } from '@material-ui/core';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import VerticalSplitIcon from '@material-ui/icons/VerticalSplit';
import Divider from 'core/ui/Divider';
import Paper from 'core/ui/Paper';
import Scrollbars from 'react-custom-scrollbars';
import Draggable from 'react-draggable';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import uuid from 'react-uuid';
import useNoSleep from 'use-no-sleep';
import ItemDetailSection from './ItemDetailSection';

const CustomSlider = styled(Slider)(() => ({
  color: 'white', // color of the slider between thumbs
  '& .MuiSlider-thumb': {
    backgroundColor: 'white', // color of thumbs
  },
  '& .MuiSlider-rail': {
    color: 'white', /// /color of the slider outside  teh area between thumbs
  },
}));

const SubView = ({ section, fontColor, buttonColor, closeView, activeTimer, setActiveTimer }) => {
  const [fontSize, setFontSize] = useState(20);
  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" className="link" alignItems="center">
        <Box flexGrow="1">
          <Typography ml={2} style={{ fontSize, color: 'white' }}>
            {section?.title?.toUpperCase()}
          </Typography>
        </Box>
        <Box ml={1} flexGrow={1} display="flex" justifyContent="flex-end" alignItems="center">
          <Box style={{ maxWidth: 200, borderRadius: 10 }} mb={1}>
            <Typography variant="body2" style={{ color: 'white' }}>
              {t('fontSize')}
            </Typography>
            <CustomSlider
              defaultValue={fontSize}
              aria-labelledby="continuous-slider"
              onChange={(e, value) => setFontSize(value)}
            />
          </Box>

          <Box
            className="hover"
            onClick={closeView}
            ml={3}
            my={2}
            display="flex"
            justifyContent="flex-end"
          >
            <Close style={{ color: primary, fontSize: 30 }} />
          </Box>
        </Box>
      </Box>

      <Box mb={2}>
        <ItemDetailSection
          section={section}
          fontColor={fontColor}
          buttonColor={buttonColor}
          expandView
          activeTimer={activeTimer}
          setActiveTimer={setActiveTimer}
          fontSize={fontSize}
        />
      </Box>
    </Box>
  );
};

const ExpandedSection = ({ sections, section, expandSection, buttonColor, fontColor }) => {
  const { t } = useTranslation();
  const [openPopOver, setOpenPopOver] = useState(false);
  const [timerSize, setTimerSize] = useState(2);
  const [views, setViews] = useState([section]);
  const btnRef = useRef();
  const [activeSectionTimer, setActiveSectionTimer] = useState(null);

  useNoSleep(true);

  const addView = (sec) => {
    setViews((prev) => [...prev, { ...sec, uuid: uuid() }]);
  };
  const columns = Math.round(12 / views?.length);

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (views?.length === 1) {
      const index = sections?.findIndex((x) => Number(x.id) === Number(views[0].id));
      setCurrentIndex(index);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [views]);

  useEffect(() => {
    if (views.length === 0) {
      expandSection(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [views]);

  const showNextButton = views?.length === 1 && currentIndex + 1 < sections?.length;
  const showPrevButton = views?.length === 1 && currentIndex > 0;

  return (
    <Box px={5} py={2} style={{ backgroundColor: 'black', flex: 1 }}>
      <Box display="flex" mb={2}>
        {showPrevButton && (
          <Box mr={1}>
            <ButtonPrimarySm onClick={() => setViews([sections[currentIndex - 1]])}>
              <KeyboardArrowLeft style={{ color: 'black', fontSize: 30 }} />
            </ButtonPrimarySm>
          </Box>
        )}
        <Box
          mx={2}
          ref={btnRef}
          flexGrow={1}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <ButtonPrimarySm onClick={() => setOpenPopOver(true)}>
            <VerticalSplitIcon />
            {t('button.split')}
          </ButtonPrimarySm>

          <Popover
            open={openPopOver}
            anchorEl={btnRef.current}
            onClose={() => setOpenPopOver(false)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Paper p={4}>
              <Typography>Select the section</Typography>
              <SelectBoxObject
                options={sections?.map((x) => ({ id: x.id, name: x?.title }))}
                setValue={(v) => {
                  addView(sections?.find((x) => x.id === Number(v)));
                  setOpenPopOver(false);
                }}
                bordered
              />
            </Paper>
          </Popover>
        </Box>
        {showNextButton && (
          <Box ml={1}>
            <ButtonPrimarySm
              onClick={() => {
                setViews([sections[currentIndex + 1]]);
              }}
            >
              <KeyboardArrowRight style={{ color: 'black', fontSize: 30 }} />
            </ButtonPrimarySm>
          </Box>
        )}
      </Box>

      <Divider />

      <Grid container spacing={3} style={{ flex: 1, height: '95%' }}>
        {views?.map((sec) => (
          <Grid item xs={12} md={columns} key={sec?.id} style={{ flex: 1 }}>
            <Scrollbars style={{ paddingTop: 3, flex: 1 }}>
              <SubView
                section={sec}
                buttonColor={buttonColor}
                fontColor={fontColor}
                closeView={() => setViews((prev) => prev.filter((x) => x.uuid !== sec?.uuid))}
                setActiveTimer={() =>
                  activeSectionTimer && sec?.id === activeSectionTimer?.id
                    ? setActiveSectionTimer(null)
                    : setActiveSectionTimer(sec)
                }
                activeTimer={sec?.id === activeSectionTimer?.id}
              />
            </Scrollbars>
          </Grid>
        ))}
      </Grid>

      {activeSectionTimer &&
        activeSectionTimer?.timerData?.type &&
        activeSectionTimer?.timerData?.type !== 'none' && (
          <Draggable defaultPosition={{ x: 0, y: 0 }} handle=".handle">
            <Box style={{ position: 'absolute', top: 130, right: 0 }}>
              <Box>
                <ButtonGroup
                  size="medium"
                  color="secondary"
                  aria-label="medium secondary button group"
                >
                  <ButtonPrimary
                    style={{ padding: 0 }}
                    buttonColor={secondaryXLight}
                    onClick={() => setTimerSize((prev) => prev - 0.5)}
                  >
                    <ZoomOutIcon fontSize="small" />
                  </ButtonPrimary>
                  <ButtonPrimary
                    style={{ padding: 0 }}
                    buttonColor={secondaryXLight}
                    onClick={() => setTimerSize((prev) => prev + 0.5)}
                  >
                    <ZoomInIcon fontSize="small" />
                  </ButtonPrimary>
                </ButtonGroup>
              </Box>
              <div className="handle">
                <TimerSection
                  section={activeSectionTimer}
                  colorLabel="white"
                  preview
                  zoomView={timerSize}
                />
              </div>
            </Box>
          </Draggable>
        )}
    </Box>
  );
};

export default ExpandedSection;

ExpandedSection.defaultProps = {
  section: {},
  index: 0,
  selectTab: null,
  hideSections: true,
  isRegistered: false,
};
