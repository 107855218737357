import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import DatePickerRounded from 'core/ui/DatePickers/DatePickerRounded';
import { ButtonPrimarySm } from 'core/ui/Button';
import { Search } from '@material-ui/icons';
import { formatDate, formatDateToApi } from 'core/utils/formatters';
import useSelectors from 'modules/map/useSelectors';
import Modules from 'modules';
import useActions from 'modules/map/useActions';
import { partnersModule } from 'modules/partners';
import ResultPayments from './ResultPayments';

const PaymentsReport = ({ partner }) => {
  const { t } = useTranslation();
  const { request } = useActions();
  const loading = useSelectors(partnersModule, 'loading');
  const payments = useSelectors(partnersModule, 'payments');
  const dateNow = new Date();
  const [filters, setFilters] = useState({
    startAt: `${dateNow.getMonth() + 1}/1/${dateNow.getFullYear()}`,
    endAt: formatDate(new Date(), 'MM/DD/YYYY'),
    workoutId: '',
  });

  const getReport = ({ startAt, endAt }) => {
    request({
      action: Modules.partners.actions.getPayments,
      data: {
        partnerId: partner?.id,
        startAt,
        endAt,
      },
    });
  };

  const search = () => {
    const startAt = filters.startAt ? formatDateToApi(filters.startAt) : null;
    const endAt = filters.endAt ? formatDateToApi(filters.endAt) : null;
    if (startAt && endAt) getReport({ startAt, endAt });
  };

  useEffect(() => {
    search();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partner?.id]);

  const rows = payments || [];

  return (
    <Box mt={2}>
      <Box>
        <Box display="flex" mb={3} alignItems="flex-end" justifyContent="center" flexWrap="wrap">
          <Box>
            <DatePickerRounded
              label={t('channel.reports.filter.start').toUpperCase()}
              onChange={(v) => setFilters((prev) => ({ ...prev, startAt: v }))}
              value={filters.startAt}
              disablePast={false}
              usFormat={partner?.useUsDateFormat}
              autoOk
            />
          </Box>
          <Box ml={1}>
            <DatePickerRounded
              label={t('channel.reports.filter.end').toUpperCase()}
              onChange={(v) => setFilters((prev) => ({ ...prev, endAt: v }))}
              value={filters.endAt}
              disablePast={false}
              usFormat={partner?.useUsDateFormat}
              autoOk
            />
          </Box>
          <Box ml={3}>
            <ButtonPrimarySm disabled={loading} onClick={() => search()}>
              <Search style={{ color: 'white' }} fontSize="small" />
              {t('button.search')}&nbsp;
            </ButtonPrimarySm>
          </Box>
        </Box>

        <ResultPayments partner={partner} rows={rows} getReport={() => search()} />
      </Box>
    </Box>
  );
};

PaymentsReport.defaultProps = {};

export default PaymentsReport;
