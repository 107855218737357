import { useEffect, useState } from 'react';
import { persistData, retrieveData, removeData } from 'core/utils/session-storage';

export default () => {
  const [opened, setOpened] = useState(false);
  const key = 'TUTORIAL_COMP_WORKOUT_WATCH';

  const show = () => {
    const showLocal = retrieveData(key);
    if (!showLocal) {
      setOpened(true);
    }
  };

  const reset = () => {
    removeData(key);
  };

  const setLog = () => {
    persistData(key, JSON.stringify({ showed: true, date: new Date() }));
  };

  useEffect(() => {
    show();
    // reset();
  }, []);

  return {
    opened,
    setOpened,
    setLog,
    reset,
  };
};
