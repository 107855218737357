import api from 'core/api';
import { call, put } from 'redux-saga/effects';
import { saveAs } from 'file-saver';
import { retrieveAuthHeaders } from 'core/api/auth-headers';
import { retrieveData } from 'core/utils/session-storage';
import { BASE_URL } from 'core/env';
import humps from 'humps';

export const competitionsUsersModule = 'competitionsUsers';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const loadingUsersStates = {
  start: { loadingUsers: true },
  error: { loadingUsers: false },
  success: { loadingUsers: false },
};

const actions = {
  getCompetitionsUser: {
    module: competitionsUsersModule,
    name: 'getCompetitionsUser',
    api: (params) => api.get(`/competitions/${params.competitionId}/users`, { params }),
    *sagas(Creators, { params, options }) {
      try {
        const resp = yield call(actions.getCompetitionsUser.api, params);
        yield put(Creators.getCompetitionsUserSuccess(resp.data, resp.pagination));
        if (options.onSuccess) {
          options.onSuccess(resp.data, resp.pagination);
        }
      } catch (error) {
        if (options.onError) {
          options.onError(error);
        }
        yield put(Creators.getCompetitionsUserError());
      }
    },
    params: {
      start: ['params'],
      error: [''],
      success: [],
    },
    state: loadingUsersStates,
  },
  show: {
    module: competitionsUsersModule,
    name: 'show',
    api: (params) =>
      api.get(`/competitions/${params?.competitionId}/competitions_users/show`, {
        params,
      }),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  exportUsers: {
    module: competitionsUsersModule,
    name: 'exportUsers',

    api: (params) => {
      const authHeaders = retrieveAuthHeaders(retrieveData)();
      return fetch(`${BASE_URL}/api/v1/competitions/${params.competitionId}/users/export`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          ...authHeaders,
        },
        body: JSON.stringify(humps.decamelizeKeys(params)),
      });
    },

    *sagas(Creators, { params, options }) {
      try {
        const resp = yield call(actions.exportUsers.api, params);

        resp.blob().then((f) => saveAs(f, params?.exportFileName || `${params?.channelId}.csv`));

        yield put(Creators.exportChannelMembersSuccess());
        if (options.onSuccess) {
          options.onSuccess(resp);
        }
      } catch (error) {
        if (options.onError) {
          options.onError(error);
        }
        yield put(Creators.exportChannelMembersError());
      }
    },
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  create: {
    module: competitionsUsersModule,
    name: 'create',
    api: (data) => api.post(`/competitions/${data?.competitionId}/competitions_users`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  update: {
    module: competitionsUsersModule,
    name: 'update',
    api: (data) => api.post(`/competitions/${data?.competitionId}/competitions_users/update`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  delete: {
    module: competitionsUsersModule,
    name: 'delete',
    api: (params) =>
      api.delete(`/competitions/${params?.competitionId}/competitions_users/delete`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  withdraw: {
    module: competitionsUsersModule,
    name: 'withdraw',
    api: (params) =>
      api.put(`/competitions/${params?.competitionId}/competitions_users/withdraw`, params),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  updateCaptain: {
    module: competitionsUsersModule,
    name: 'updateCaptain',
    api: (data) =>
      api.post(`/competitions/${data?.competitionId}/competitions_users/update_captain`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
};

const competitionsUsersModuleObj = {
  actions,
  state: {
    loading: false,

    pagination: null,
  },
};

export default competitionsUsersModuleObj;
