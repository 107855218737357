import { channelsSelectors } from 'modules/channels';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { sessionSelectors } from 'modules/session';

export default () => {
  const currentUser = useSelector((state) => sessionSelectors.getUser(state));
  const channel = useSelector((state) => channelsSelectors.getChannel(state));

  useEffect(() => {
    if (channel?.id) {
      if (channel?.dateFormatReact) {
        localStorage.setItem('GYM_DATE_FORMAT', channel?.dateFormatReact);
        localStorage.setItem('GYM_DATE_FORMAT_EXT', channel?.dateFormatExtReact);
      }
      localStorage.setItem('GYM_USE_USE_FORMAT', channel?.useUsDateFormat ? '1' : '0');
    } else if (currentUser?.id) {
      if (currentUser?.gymConfig?.dateFormatReact) {
        localStorage.setItem('GYM_DATE_FORMAT', currentUser?.gymConfig?.dateFormatReact);
        localStorage.setItem('GYM_DATE_FORMAT_EXT', currentUser?.gymConfig?.dateFormatExtReact);
      }
      localStorage.setItem(
        'GYM_USE_USE_FORMAT',
        currentUser?.gymConfig?.useUsDateFormat ? '1' : '0',
      );
    }
  }, [channel, currentUser]);
};
