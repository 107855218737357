/* eslint-disable react/prop-types */
import React from 'react';

import Box from 'core/ui/Box';
import Video from 'core/ui/Video';
import Scrollbars from 'react-custom-scrollbars';
import Typography, { fontStyle } from 'core/ui/Typography';
import { isSafari } from 'react-device-detect';
import { makeStyles } from '@material-ui/core';
import { roomSelectors } from 'modules/room';
import { useSelector } from 'react-redux';
import LiveUsers from '../SliderParticipants/LiveUsers';

const useStyles = makeStyles(() => ({
  boxName: {
    position: 'relative',
    marginTop: -23,
    marginLeft: 0,
    background: 'rgba(24,24,24,0.54)',
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
  },
}));

export default ({
  workout,
  setRecordingFullScreen,
  playingRecordings,
  setTime,
  videos,
  time,
  mutedRecordings,
  setVideoReady,
}) => {
  const classes = useStyles();
  const speakerVideo = videos.find((x) => !!x.isCoach);
  const userRoom = useSelector((state) => roomSelectors.getUser(state));
  const participants = useSelector((state) => roomSelectors.getParticipants(state));

  const usersLength = () => {
    let count = 0;
    if (workout && videos) {
      count += videos.length;
    }
    if (participants) {
      count += participants.length;
    }
    if (userRoom && userRoom.streamManager) {
      count += 1;
    }
    return count;
  };

  const countUsers = usersLength();

  if (!speakerVideo || (!workout.started && !workout.isCoach)) {
    return <></>;
  }

  return (
    <Box style={{ height: '100%' }}>
      {!isSafari && (
        <Scrollbars
          style={{ height: countUsers > 1 ? 150 : 0 }}
          renderTrackVertical={(props) => (
            <div {...props} style={{ display: 'none' }} className="track-vertical" />
          )}
          autoHide
        >
          <Box display="flex" flexWrap="nowrap">
            {workout.live && <LiveUsers showLiveIcon />}
            {videos
              .filter((x) => x !== speakerVideo)
              .filter((x) => !isSafari || x.isCoach)
              .map((rec, index) => (
                <Box
                  key={index.toString()}
                  mx={1}
                  style={{
                    height: 150,
                    minWidth: 200,
                    position: 'relative',
                    background: '#000',
                    borderRadius: 10,
                  }}
                >
                  <Box
                    style={{
                      position: 'absolute',
                      height: 150,
                      minWidth: 200,
                      display: 'block',
                      zIndex: 10,
                    }}
                    className="link"
                    onClick={() => setRecordingFullScreen(rec)}
                  />
                  <Video
                    playing={playingRecordings}
                    src={rec.vimeoUrl}
                    muted={mutedRecordings}
                    vimeo={!speakerVideo.video}
                    upload={!!speakerVideo.video}
                    full
                    showPlayButton={false}
                    seek={time.seek}
                    onReady={() => setVideoReady({ url: rec.vimeoUrl })}
                    borderRadius={10}
                  />
                  <Box className={classes.boxName}>
                    <Typography p={1} style={{ ...fontStyle.small, color: 'white' }}>
                      {rec.user.name}
                    </Typography>
                  </Box>
                </Box>
              ))}
          </Box>
        </Scrollbars>
      )}

      <Box
        mt={3}
        style={{
          height: '90%',
          position: 'relative',
          background: '#000',
          borderRadius: 10,
        }}
      >
        <Video
          playing={playingRecordings}
          muted={mutedRecordings}
          src={speakerVideo.vimeoUrl}
          vimeo={!speakerVideo.video}
          controls
          upload={!!speakerVideo.video}
          full
          showPlayButton={false}
          seek={time.seek}
          onReady={() => setVideoReady({ url: speakerVideo.vimeoUrl })}
          onSeek={(v) =>
            speakerVideo.isCoach && playingRecordings
              ? setTime((prev) => ({ ...prev, complete: v }))
              : null
          }
          borderRadius={10}
        />
      </Box>
    </Box>
  );
};
