/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Calendar from 'core/ui/Calendar';
import { date } from 'core/utils/formatters';
import { isMobile } from 'react-device-detect';
import useSelectors from 'modules/map/useSelectors';
import { usersCalendarsModule } from 'modules/usersCalendars';
import Backdrop from 'core/ui/Backdrop';
import { Button, ButtonGroup, Hidden } from '@material-ui/core';
import { NavigateBefore, NavigateNext } from '@material-ui/icons';
import { channelsActions, channelsSelectors } from 'modules/channels';
import { useDispatch, useSelector } from 'react-redux';
import AvatarCoach from 'components/Profile/AvatarCoach';
import MIcon from 'core/ui/MIcon';
import { formatWeekDaysToObj } from 'core/utils/helpers';
import Divider from 'core/ui/Divider';
import { dayWeekFilters } from 'core/utils/consts';
import { primary, secondary } from 'core/ui/Colors';
import { DatePicker } from '@material-ui/pickers';
import { useTranslation } from 'react-i18next';
import useAvailabilityForm from './useAvailabilityForm';
import useCalendarEvents from './Calendar/useCalendarEvents';
import { renderEventContent } from './Calendar/renderEventContent';
import ViewWeekDay from './Availability/ViewWeekDay';
import ModalFormRegistration from './Calendar/Form/ModalFormRegistration';
import ModalEventInfo from './Calendar/ModalEventInfo';

const CalendarShow = ({ calendarId }) => {
  const { t } = useTranslation();
  const channel = useSelector((state) => channelsSelectors.getChannel(state));
  const loadingEvents = useSelectors(usersCalendarsModule, 'loadingEvents');
  const [title, setTitle] = useState('');
  const { getCalendar, currentCalendar } = useAvailabilityForm({});
  const coachId = currentCalendar?.user?.id;
  const [currentDate, setCurrentDate] = useState(false);
  const dispatch = useDispatch();

  const {
    setValue,

    modalRegistration,
    setModalRegistration,
    getCalendarsEvents,
    modalEvent,
    setModalEvent,
    confirmRegistration,
    setCalendarDates,

    calendarRef,
    setCalendarRef,
    calendarDates,

    events,

    handleEventClick,
  } = useCalendarEvents({ usersCalendarId: calendarId, coachId, channel });

  useEffect(() => {
    if (calendarId) {
      getCalendar(calendarId);
    }
  }, [calendarId, getCalendar]);

  useEffect(() => {
    if ((!channel?.id || channel?.id != currentCalendar?.channelId) && currentCalendar?.channelId) {
      dispatch(channelsActions.getChannelStart(currentCalendar?.channelId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel, currentCalendar]);

  useEffect(() => {
    if (calendarId) {
      setValue('usersCalendarId', calendarId);
    }
  }, [calendarId, setValue]);

  const prev = () => {
    calendarRef.current.getApi().prev();
    setTitle(calendarRef.current.getApi().view.title);
  };

  const next = () => {
    calendarRef.current.getApi().next();
    setTitle(calendarRef.current.getApi().view.title);
  };

  const type = calendarDates?.currentView || 'timeGridWeek';

  const gridView = (newType) => {
    if (newType === 'timeGridProgram') {
      calendarRef.current?.getApi()?.changeView('timeGridWeek');
    } else {
      calendarRef.current?.getApi()?.changeView(newType);
    }
    setCalendarDates((prv) => ({ ...prv, currentView: newType }));
    setTitle(calendarRef.current?.getApi()?.view?.title);
  };

  const goToDate = (newDate) => {
    calendarRef.current?.getApi()?.gotoDate(newDate);
    setTitle(calendarRef.current?.getApi()?.view?.title);
    setCurrentDate(newDate);
  };

  return (
    <div className="tw-mb-20 tw-pt-4 md:tw-mb-2">
      <div className="tw-border tw-border-solid tw-border-gray-200 tw-bg-white">
        <div className="tw-flex tw-flex-row tw-items-center tw-rounded-t-md tw-border-0 tw-border-b tw-border-solid  tw-border-gray-300 tw-bg-[#404041] tw-p-1">
          <div>
            <ButtonGroup variant="contained" color="secondary">
              <Button onClick={() => prev()}>
                <NavigateBefore />
              </Button>
              <Button onClick={() => next()}>
                <NavigateNext />
              </Button>
            </ButtonGroup>
          </div>
          <div className="tw-flex tw-grow tw-items-center tw-justify-center">
            <div className="tw-text-xs tw-font-bold tw-text-white md:tw-text-lg">
              {title || calendarRef?.current?.getApi()?.view?.title}
            </div>
          </div>
          <div>
            <ButtonGroup variant="contained" color="secondary">
              <Button
                style={{ backgroundColor: type !== 'dayGridMonth' ? secondary : primary }}
                onClick={() => gridView('dayGridMonth')}
              >
                month
              </Button>
              <Button
                style={{ backgroundColor: type !== 'timeGridWeek' ? secondary : primary }}
                onClick={() => gridView('timeGridWeek')}
              >
                week
              </Button>
              <Button
                style={{ backgroundColor: type !== 'timeGridDay' ? secondary : primary }}
                onClick={() => gridView('timeGridDay')}
              >
                day
              </Button>
            </ButtonGroup>
          </div>
        </div>

        <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-4">
          <div
            className="tw-col-span-1 md:tw-col-span-1"
            style={{ borderRight: '1px solid #F5F5F5' }}
          >
            <div className="">
              <div className="tw-tw-justify-center tw-flex tw-flex-col tw-items-center tw-px-4 tw-pt-2">
                <div>
                  <AvatarCoach user={currentCalendar?.user} size={100} />
                </div>
                <div className="tw-mt-2 tw-text-lg tw-font-bold tw-text-gray-700">
                  {currentCalendar?.user?.name}
                </div>
                <div className="tw-font-bol tw-mt-2 tw-flex tw-flex-row tw-items-center tw-gap-x-2 tw-text-green-800">
                  <MIcon icon="fitness_center" />
                  {currentCalendar?.channelsWorkoutsType?.name}
                </div>
              </div>

              <Hidden only={['xs']}>
                <div className="tw-bg-red-200">
                  <DatePicker
                    autoOk
                    disableToolbar
                    // onMonthChange={(params) => {
                    //   goToDate(date(params, 'YYYY-MM-DD'));
                    // }}
                    onChange={(v) => goToDate(v)}
                    value={currentDate}
                    fullWidth
                    style={{ backgroundColor: 'red' }}
                    format="yyyy-MM-dd"
                    variant="static"
                    openTo="date"
                  />
                </div>

                <div className="tw-mb-2 tw-px-2">
                  <div className="tw-rounded-md tw-bg-gray-50">
                    <div className="tw-justify-center tw-rounded-md tw-bg-zinc-500 tw-p-1 tw-text-white">
                      {t('users.calendars.weeklyHours')}
                    </div>
                    {dayWeekFilters.map((weekDay, idx) => (
                      <div key={weekDay?.id}>
                        <ViewWeekDay
                          weekDay={weekDay}
                          weeklyHours={formatWeekDaysToObj(
                            currentCalendar?.usersCalendarsWeeklyHours || [],
                          )}
                        />
                        {dayWeekFilters.length - 1 > idx && <Divider />}
                      </div>
                    ))}
                  </div>
                </div>
              </Hidden>
            </div>
          </div>
          <div className="tw-col-span-1 tw-flex-1 md:tw-col-span-3">
            <div className="tw-h-full tw-flex-1 tw-bg-red-200 tw-font-normal tw-text-zinc-400">
              <Calendar
                calendarRef={setCalendarRef}
                eventClick={handleEventClick}
                events={events}
                initialView={!isMobile ? 'timeGridWeek' : 'timeGridDay'}
                dayHeaderFormat={(args) => {
                  if (calendarRef?.current?.getApi()?.view?.type === 'dayGridMonth') {
                    return date(args.date, channel?.useUsDateFormat ? 'ddd' : 'ddd');
                  }
                  return date(args.date, channel?.useUsDateFormat ? 'ddd M/DD' : 'ddd DD/M');
                }}
                datesSet={(dateInfo) => {
                  if (setCalendarDates) {
                    setCalendarDates((prv) => ({
                      ...prv,
                      start: dateInfo.start,
                      end: dateInfo.end,
                    }));
                  }
                }}
                eventContent={renderEventContent}
                rootClasses="coaches-calendar-show tw-h-full"
              />
            </div>
          </div>
          <ModalFormRegistration
            {...modalRegistration}
            close={() => setModalRegistration({})}
            channel={channel}
            callback={() => {
              getCalendarsEvents();
              setModalRegistration({});
            }}
            callbackError={() => {
              getCalendarsEvents();
            }}
            isCoach={currentCalendar?.isCoach}
          />
          <ModalEventInfo
            {...modalEvent}
            onConfirm={confirmRegistration}
            close={() => setModalEvent({})}
            channel={channel}
          />
        </div>
      </div>

      {loadingEvents && <Backdrop />}
    </div>
  );
};

export default CalendarShow;
