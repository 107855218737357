import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import { Dialog, FormControlLabel, Radio } from '@material-ui/core';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import Typography, { fontStyle } from 'core/ui/Typography';
import { ButtonPrimary } from 'core/ui/Button';
import Texture from 'core/ui/Texture';
import Grid from 'core/ui/Grid';
import TextAreaFieldRounded from 'core/ui/TextAreaFieldRounded';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import { channelsRatesTemplatesTypes } from 'core/utils/consts';
import DatePickerRounded from 'core/ui/DatePickers/DatePickerRounded';
import useForm from './form';

const ModalFormList = ({ open, item, close, callback, mode, channel }) => {
  const { t } = useTranslation();
  const { setValue, watch, handleSubmit, onSubmit, errors, loading } = useForm({
    mode,
    item,
    callback,
  });
  return (
    <Dialog maxWidth="sm" scroll="body" fullWidth onClose={() => close()} open={open}>
      <Texture>
        <Box p={5}>
          <Grid container spacing={5}>
            <Grid item xs={12} md={6}>
              <Box mb={3}>
                <TextFieldRounded
                  value={watch('name')}
                  onChange={(v) => setValue('name', v)}
                  placeholder={t('label.name')}
                  error={errors.name}
                  label={t('label.name').toUpperCase()}
                  required
                />
              </Box>
              <Box mb={3}>
                <TextAreaFieldRounded
                  value={watch('description')}
                  onChange={(v) => setValue('description', v)}
                  placeholder={t('label.description')}
                  error={errors.description}
                  label={t('label.description').toUpperCase()}
                />
              </Box>

              <Box>
                <Typography style={fontStyle.label} variant="body1">
                  {t('label.active').toUpperCase()}
                </Typography>
                <Box display="flex">
                  <FormControlLabel
                    onClick={() => setValue('active', true)}
                    control={<Radio color="primary" size="small" checked={!!watch('active')} />}
                    label="Yes"
                  />
                  <FormControlLabel
                    onClick={() => setValue('active', false)}
                    control={<Radio color="primary" size="small" checked={!watch('active')} />}
                    label="No"
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box mb={3}>
                <SelectBoxObject
                  label={t('channel.rateTemplate.label.kind').toUpperCase()}
                  options={Object.keys(channelsRatesTemplatesTypes).map((x) => ({
                    id: x,
                    name: t(`channel.rateTemplate.kind.${x}`),
                  }))}
                  value={watch('kind')}
                  setValue={(v) => setValue('kind', v)}
                  required
                  error={errors.kind}
                />
              </Box>

              <Box mb={3}>
                <TextFieldRounded
                  type="number"
                  value={watch('amount') || ''}
                  pattern="[0-9]*"
                  placeholder="0,00"
                  label={t('channel.rateTemplate.label.amount').toUpperCase()}
                  onChange={(v) => setValue('amount', v)}
                  required
                  currency
                  currencySymbol={
                    [
                      channelsRatesTemplatesTypes.percent_by_class,
                      channelsRatesTemplatesTypes.percent_by_membership,
                      channelsRatesTemplatesTypes.percent_by_product,
                    ].find((x) => x === watch('kind'))
                      ? '%'
                      : channel?.currency?.symbol
                  }
                  bordered
                  error={errors.amount}
                />
              </Box>

              <Box mb={3}>
                <DatePickerRounded
                  label={t('channel.rateTemplate.label.startDate').toUpperCase()}
                  onChange={(v) => setValue('startDate', v)}
                  value={watch('startDate')}
                  disablePast={false}
                  usFormat={channel?.useUsDateFormat}
                  autoOk
                  error={errors?.startDate}
                  bordered
                  required
                />
              </Box>
              <Box mb={3}>
                <DatePickerRounded
                  label={t('channel.rateTemplate.label.endDate').toUpperCase()}
                  onChange={(v) => setValue('endDate', v)}
                  value={watch('endDate')}
                  disablePast={false}
                  usFormat={channel?.useUsDateFormat}
                  autoOk
                  bordered
                />
              </Box>
            </Grid>
          </Grid>

          <Box mt={3} display="flex" justifyContent="flex-end">
            <ButtonPrimary disabled={loading} onClick={handleSubmit(onSubmit)}>
              {t('button.save')}
            </ButtonPrimary>
          </Box>
        </Box>
      </Texture>
    </Dialog>
  );
};

ModalFormList.defaultProps = {
  close: () => {},
  callback: () => {},
  open: false,
  mode: 'new',
};

ModalFormList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  close: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  callback: PropTypes.func,
  open: PropTypes.bool,
  mode: PropTypes.string,
};

export default ModalFormList;
