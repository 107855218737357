/* eslint-disable react/prop-types */
import React from 'react';

import Paper from 'core/ui/Paper';
import { useTranslation } from 'react-i18next';
import Typography from 'core/ui/Typography';
import SFDataTable from 'core/ui/DataTable';
import { date, timeWithoutTz, diffFromNow } from 'core/utils/formatters';
import { Link } from '@material-ui/core';

export default function ResultMembers({ channel, members }) {
  const { t } = useTranslation();
  const columns = [
    {
      name: 'ID',
      selector: 'id',
      cell: (row) => <Typography> {row.id}</Typography>,
      sortable: true,
      width: '80px',
    },
    {
      name: 'Name',
      selector: 'name',
      sortable: true,
      cell: (row) => (
        <div>
          <Link
            href={`/channel/manage/${channel?.id}/members/${row?.id}`}
            className="hover"
            target="_blank"
          >
            <Typography>{row.name}</Typography>
          </Link>
        </div>
      ),
    },
    {
      name: 'Status',
      selector: 'memberStatus',
      sortable: true,
      cell: (row) => <Typography>{row.memberStatus}</Typography>,
    },
    {
      name: 'Last Attend',
      selector: 'lastAttend',
      sortable: true,
      cell: (row) => <Typography> {row.lastAttend}</Typography>,
    },
    {
      name: 'Last Attend Date',
      selector: 'lastAttendDate',
      sortable: true,
      center: true,
      cell: (row) => <Typography> {row.lastAttendDate}</Typography>,
    },
    {
      name: 'Days Missing',
      sortable: true,
      selector: 'daysMissing',
      center: true,
      cell: (row) => <Typography> {row.daysMissing}</Typography>,
    },
  ];

  const getRows = () => {
    const rowsMembers =
      members &&
      members.map((member) => ({
        id: member.id,
        name: member.name,
        memberStatus: member.memberStatus,
        lastAttend: member?.lastWorkout?.name,
        lastAttendDate: `${date(
          member?.lastWorkout?.scheduledAt,
          channel?.dateFormatReact,
        )} ${timeWithoutTz(member.lastWorkout.scheduledAt)}`,
        daysMissing: Number(diffFromNow(member?.lastWorkout?.scheduledAt, 'days')) * -1,
      }));

    return rowsMembers;
  };

  const rows = getRows();

  return (
    <Paper style={{ width: '100%' }}>
      <SFDataTable
        title={t('channel.reports.membersAtRisk')}
        columns={columns}
        data={rows}
        pagination
        showExport
        paginationPerPage={20}
      />
    </Paper>
  );
}
