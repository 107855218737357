/* eslint-disable eqeqeq */
/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import Box from 'core/ui/Box';
import { ButtonPrimary } from 'core/ui/Button';
import LoaderSm from 'core/ui/LoaderSm';
import { useTranslation } from 'react-i18next';
import useSelectors from 'modules/map/useSelectors';
import { userChildrenModule } from 'modules/userChildren';
import { useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import notifications from 'modules/notifications';
import Modules from 'modules';
import useActions from 'modules/map/useActions';
import { getAllTimezones, timeZoneName } from 'core/utils/formatters/date';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import SelectBoxSearch from 'core/ui/SelectBox/SelectBoxSearch';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import Checkbox from 'core/ui/Checkbox';
import Typography from 'core/ui/Typography';
import { durationWorkout } from 'core/utils/consts';
import Grid from 'core/ui/Grid';
import Paper from 'core/ui/Paper';

const FormCalendar = ({ channel, item, callback }) => {
  const { t } = useTranslation();
  const loading = useSelectors(userChildrenModule, 'loading');
  const { control, handleSubmit, reset, watch } = useForm({
    defaultValues: { timezone: timeZoneName() },
  });
  const dispatch = useDispatch();
  const { request } = useActions();

  const onSubmit = (data) => {
    const formData = {
      ...data,
      channelId: channel?.id,

      usersCalendarsDurations: null,
      usersCalendarsDurationsAttributes: data?.usersCalendarsDurations,
    };

    request({
      action: item?.id
        ? Modules.usersCalendars.actions.update
        : Modules.usersCalendars.actions.create,
      data: formData,
      options: {
        onSuccess: (resp) => {
          dispatch(notifications.success(t('message.success.usersCalendars')));
          if (callback) {
            callback(resp);
          }
        },
        onError: (error) => {
          dispatch(notifications.error(error));
        },
      },
    });
  };

  useEffect(() => {
    if (item) {
      reset({ ...item });
    }
  }, [item, reset]);

  const changeDurations = (items, duration, prop, value) => {
    if (items?.find((x) => x.durationMinutes == duration)) {
      return items.map((dur) => {
        if (dur?.durationMinutes == duration) {
          return { ...dur, [prop]: value };
        }

        return dur;
      });
    }
    return [...items, { durationMinutes: duration, [prop]: value }];
  };

  return (
    <Box>
      <Grid container spacing={5}>
        <Grid item xs={12} md={6}>
          <Typography mb={3} variant="h5">
            {t('users.calendars.calendarInformation')}
          </Typography>
          <div className="tw-flex tw-flex-col tw-gap-4">
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <div>
                  <TextFieldRounded
                    label={t('label.name').toUpperCase()}
                    onChange={onChange}
                    value={value}
                    bordered
                  />
                </div>
              )}
              name="name"
            />

            <Controller
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <div>
                  <SelectBoxObject
                    label={t('label.classType').toUpperCase()}
                    options={channel?.workoutsTypes || []}
                    setValue={onChange}
                    value={value}
                    required
                  />
                </div>
              )}
              name="channelsWorkoutsTypeId"
            />
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <div>
                  <SelectBoxSearch
                    label={t('workout.label.timezone').toUpperCase()}
                    options={getAllTimezones()}
                    setValue={onChange}
                    value={value || timeZoneName()}
                    required
                  />
                </div>
              )}
              name="timezone"
            />
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <div>
                  <Checkbox
                    label={t('label.requireRegistrationsApproval')}
                    checked={!!value}
                    onClick={() => onChange(!value)}
                  />
                </div>
              )}
              name="requireRegistrationsApproval"
            />
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <div>
                  <Checkbox
                    label={t('label.showOnGymCalendar')}
                    checked={!!value}
                    onClick={() => onChange(!value)}
                  />
                </div>
              )}
              name="showOnGymCalendar"
            />

            {watch('customPrice') && (
              <Controller
                control={control}
                render={({ field: { onChange, value } }) => (
                  <div>
                    <TextFieldRounded
                      type="number"
                      pattern="[0-9]*"
                      value={value}
                      placeholder={t('channel.placeholder.membershipsAmount')}
                      label={t('label.price').toUpperCase()}
                      onChange={onChange}
                      currency
                      currencySymbol={channel?.currency?.symbol}
                    />
                  </div>
                )}
                name="price"
              />
            )}
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div>
            <Typography mb={3} variant="h5">
              {t('users.calendars.durationOptions')}
            </Typography>

            <Controller
              control={control}
              defaultValue={[]}
              render={({ field: { onChange, value } }) => (
                <div>
                  <div className="tw-mb-2 tw-flex tw-flex-col tw-flex-wrap tw-gap-1">
                    {durationWorkout.map((x) => {
                      const durationItem = value?.find((y) => y.durationMinutes == x.id);
                      return (
                        <Paper key={x.id} p={1}>
                          <div className="tw-flex tw-flex-row tw-gap-2">
                            <Checkbox
                              checked={!!durationItem?.active}
                              label={x.name}
                              onClick={() =>
                                onChange(
                                  changeDurations(value, x.id, 'active', !durationItem?.active),
                                )
                              }
                            />
                            {durationItem?.active && (
                              <Checkbox
                                checked={!!durationItem?.customPrice}
                                label={t('label.customPrice')}
                                onClick={() =>
                                  onChange(
                                    changeDurations(
                                      value,
                                      x.id,
                                      'customPrice',
                                      !durationItem?.customPrice,
                                    ),
                                  )
                                }
                              />
                            )}
                          </div>

                          {durationItem?.active && durationItem?.customPrice && (
                            <div className="tw-mt-2">
                              <TextFieldRounded
                                type="number"
                                pattern="[0-9]*"
                                value={durationItem?.price}
                                placeholder={t('channel.placeholder.membershipsAmount')}
                                label={t('label.price').toUpperCase()}
                                onChange={(v) => onChange(changeDurations(value, x.id, 'price', v))}
                                currency
                                currencySymbol={channel?.currency?.symbol}
                                bordered
                              />
                            </div>
                          )}
                        </Paper>
                      );
                    })}
                  </div>
                </div>
              )}
              name="usersCalendarsDurations"
            />
          </div>
        </Grid>
      </Grid>

      <Box>
        <Box mt={4} display="flex" justifyContent="flex-end">
          <ButtonPrimary
            type="button"
            onClick={() => handleSubmit(onSubmit)()}
            size="small"
            disabled={loading}
            style={{ height: 30, width: 80, borderRadius: 5 }}
          >
            <LoaderSm loading={loading} width={20} />
            {t('button.save')}
          </ButtonPrimary>
        </Box>
      </Box>
    </Box>
  );
};

FormCalendar.defaultProps = {};

export default FormCalendar;
