import { all, call, put } from 'redux-saga/effects';

import { getErrorMessage } from 'core/api/api-error';

import notifications from 'modules/notifications';

import * as api from './api';
import { filtersActions as actions } from './index';

// eslint-disable-next-line import/prefer-default-export
export function* getFilters() {
  try {
    const response = yield call(api.filters);
    const filters = response.data;
    yield put(actions.getFiltersSuccess(filters));
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.getFiltersError(getErrorMessage(error))),
    ]);
  }
}
