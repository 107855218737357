import React from 'react';
import Box from 'core/ui/Box';
import Integrations from 'components/Integrations';

const ChannelIntegrations = ({ channel }) => (
  <Box p={5}>
    <Integrations
      ownerableType="Channel"
      ownerableId={channel?.id}
      showCardpointe={channel?.countryId === 16}
      hasStripe={channel?.hasStripe}
      hasSifelyPlan={channel?.activePlans?.sifely?.active}
    />
  </Box>
);

export default ChannelIntegrations;
