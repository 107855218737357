/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  BarChart,
  Bar,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from 'recharts';
import Paper from 'core/ui/Paper';
import Typography from 'core/ui/Typography';
import ModalChannels from './ModalChannels';

const BarMonths = ({ title, chartData, barColor }) => {
  const [modalChannels, setModalChannels] = useState({ open: false });

  return (
    <>
      <ModalChannels
        channels={modalChannels?.channels || []}
        open={modalChannels?.open}
        month={modalChannels?.month}
        close={() => setModalChannels({ open: false })}
      />
      <Paper my={5} p={3}>
        {title && (
          <Typography align="center" m={3}>
            {title}
          </Typography>
        )}
        <ResponsiveContainer width="100%" height={300}>
          <BarChart
            data={chartData}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" style={{ fontSize: 10 }} />
            <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
            <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
            <Tooltip />
            <Legend />

            <Bar
              yAxisId="left"
              dataKey="newGymsCount"
              onClick={(e) =>
                setModalChannels({ open: true, channels: e?.channels, month: e.month })
              }
              fill={barColor || '#4fb94a'}
            />
            <Bar
              yAxisId="left"
              dataKey="lostGymsCount"
              onClick={(e) =>
                setModalChannels({ open: true, channels: e?.lostChannels, month: e.month })
              }
              fill="#ec4141"
            />
          </BarChart>
        </ResponsiveContainer>
      </Paper>
    </>
  );
};

export default BarMonths;
