/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from 'react';
import { ButtonPrimary } from 'core/ui/Button';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import Typography from 'core/ui/Typography';
import { useDispatch } from 'react-redux';
import { STRIPE_AUTH_URL } from 'core/env';
import { Link } from '@material-ui/core';
import ConfirmDialog from 'core/ui/ConfirmDialog';
import Card from 'core/ui/Card';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import { competitionsActions } from 'modules/competitions';
import { redColor } from 'core/ui/Colors';

const StripeConnectedAccount = ({ competition }) => {
  const { t } = useTranslation();
  const [confirmCancel, setConfirmCancel] = useState({ open: false });
  const dispatch = useDispatch();
  const { request } = useActions();

  const removeAccount = () => {
    const confirm = () => {
      setConfirmCancel({ open: false });
      if (competition?.id) {
        request({
          action: Modules.newCompetitions.actions.removeStripeAccount,
          data: {
            competitionId: competition?.id,
          },
          options: {
            onSuccess: () => {
              dispatch(competitionsActions.getCompetitionStart(competition?.id));
            },
          },
        });
      }
    };

    setConfirmCancel((prev) => ({
      ...prev,
      open: true,
      confirm,
    }));
  };

  const jsonStr = { ownerableType: 'Competition', ownerableId: competition?.id };
  const competitionString = btoa(JSON.stringify(jsonStr));

  return (
    <Box>
      <Card btopcolor="#4fb94a" btopwidth={2} hidedivider="true">
        <ConfirmDialog
          open={confirmCancel.open}
          description={t('channel.stripe.removeAccount.confirm')}
          labelConfirm={t('button.yes')}
          labelCancel={t('button.no')}
          confirm={() => confirmCancel.confirm()}
          cancel={() => setConfirmCancel((prev) => ({ ...prev, open: false }))}
        />
        <Box p={3}>
          {competition?.hasStripe ? (
            <Box>
              <ButtonPrimary buttonColor={redColor} fullWidth onClick={() => removeAccount()}>
                {t('channel.stripe.removeAccount')}
              </ButtonPrimary>
            </Box>
          ) : (
            <div>
              {competition?.id ? (
                <Link
                  href={`${STRIPE_AUTH_URL}&state=${competitionString}`}
                  className="hover"
                  style={{ textDecoration: 'none' }}
                >
                  <ButtonPrimary fullWidth>
                    <Typography ml={1} variant="h5" style={{ color: 'white' }}>
                      {t('button.stripe.connect')}
                    </Typography>
                  </ButtonPrimary>
                </Link>
              ) : (
                <Typography>{t('competition.form.stripe.message')}</Typography>
              )}
            </div>
          )}
        </Box>
      </Card>
    </Box>
  );
};

export default StripeConnectedAccount;
