import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { confirmDialogEmitter } from 'emitters';
import Typography from '../Typography';
import LabelBreakLine from '../LabelBreakLine';
import LoaderSm from '../LoaderSm';
import Box from '../Box';

export default function ConfirmDialogGlobal() {
  const [confirmDialog, setConfirmDialog] = useState({});

  useEffect(() => {
    confirmDialogEmitter.on('UPDATE_DIALOG_STATUS', (data) => {
      setConfirmDialog(data);
    });

    return () => {
      confirmDialogEmitter.off('UPDATE_DIALOG_STATUS', () => {
        setConfirmDialog({});
      });
    };
  }, []);

  const {
    title,
    description,
    styleBody,
    styleTitle,
    loading,
    children,
    hideNoButton,
    showConfirm2,
    labelConfirm2,
    confirm2,
  } = confirmDialog;

  const close = () => {
    setConfirmDialog({ open: false });
  };

  const confirm = () => {
    if (confirmDialog.confirm) {
      confirmDialog.confirm();
    }
    close();
  };

  const cancel = () => {
    if (confirmDialog?.cancel) {
      confirmDialog?.cancel(() => setConfirmDialog({ open: false }));
      return;
    }
    close();
  };

  const handleConfirm = () => {
    if (confirm) {
      confirm();
    }
    if (close) {
      close();
    }
  };

  const handleConfirm2 = () => {
    if (confirm2) {
      confirm2();
    }
    if (close) {
      close();
    }
  };

  const handleCancel = () => {
    if (cancel) {
      cancel();
    }
    if (close) {
      close();
    }
  };

  const isString = (myVar) => myVar instanceof String;

  return (
    <div>
      <Dialog
        style={{ zIndex: 99999 }}
        open={!!confirmDialog.open}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Typography align="center" p={3} variant="h6" style={styleTitle}>
          {title}
        </Typography>
        <DialogContent>
          <DialogContentText style={styleBody} id="alert-dialog-description">
            <Box>
              {isString(description) ? <LabelBreakLine>{description}</LabelBreakLine> : description}
            </Box>

            {children}
          </DialogContentText>
          <Box display="flex" justifyContent="center" alignItems="center" m={3}>
            <LoaderSm loading={loading} />
          </Box>
        </DialogContent>
        <DialogActions>
          {!hideNoButton && (
            <Button disabled={loading} onClick={handleCancel} color="primary">
              {confirmDialog.labelCancel || 'Cancel'}
            </Button>
          )}

          <Button disabled={loading} onClick={handleConfirm} color="primary" autoFocus>
            {confirmDialog.labelConfirm || 'Confirm'}
          </Button>
          {showConfirm2 && (
            <Button disabled={loading} onClick={handleConfirm2} color="primary" autoFocus>
              {labelConfirm2}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
