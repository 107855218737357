import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import { Container } from '@material-ui/core';
import { LayoutContext } from 'pages/shared/Layout';
import ChannelsOverview from 'components/Admin/Overview';
import Texture from 'core/ui/Texture';

export default () => {
  const { t } = useTranslation();

  const layoutContext = useContext(LayoutContext);
  useEffect(() => {
    layoutContext.setTitleBar(t('channels').toUpperCase());
    layoutContext.setActiveMenu('adminChannels');
  }, [layoutContext, t]);

  return (
    <Texture fullHeight>
      <Container maxWidth="xl">
        <Box>
          <ChannelsOverview />
        </Box>
      </Container>
    </Texture>
  );
};
