import React from 'react';
import PropTypes from 'prop-types';
import Box from 'core/ui/Box';
import { ButtonPrimary } from 'core/ui/Button';
import LoaderSm from 'core/ui/LoaderSm';
import { useTranslation } from 'react-i18next';
import Checkbox from 'core/ui/Checkbox';
import DatePickerRounded from 'core/ui/DatePickers/DatePickerRounded';
import AdminChannelSelector from 'components/Admin/ChannelSelector';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import useFormParterProgramChannel from './useForm';

const FormParterProgramChannel = ({
  partnersProgram,
  partnersProgramsChannel,
  close,
  callbackSave,
}) => {
  const { t } = useTranslation();
  const { loading, errors, setValue, watch, submit } = useFormParterProgramChannel({
    callbackSave,
    close,
    partnersProgram,
    partnersProgramsChannel,
  });

  return (
    <Box flexGrow={1}>
      <Box mb={3}>
        <AdminChannelSelector
          selectedChannel={watch('channel')}
          setSelectedChannel={(v) => setValue('channel', v)}
        />
      </Box>
      <Box mb={3}>
        <DatePickerRounded
          name="scheduledAt"
          disablePast={false}
          type="datetime-local"
          label={t('form.partner.channel.expires')}
          onChange={(v) => setValue('expiryAt', v)}
          value={watch('expiryAt')}
          required
          error={errors?.expiryAt}
        />
      </Box>
      <Checkbox
        checked={watch('renewable')}
        label={t('form.partner.channel.renewable')}
        onClick={() => setValue('renewable', !watch('renewable'))}
      />
      {partnersProgram?.allowAnnualCost && (
        <Box mt={3}>
          <Checkbox
            checked={watch('annualRenewal')}
            label={t('form.partner.program.annualCost')}
            onClick={() => setValue('annualRenewal', !watch('annualRenewal'))}
          />
        </Box>
      )}
      <Box my={3}>
        <TextFieldRounded
          type="number"
          value={watch('renewPrice')}
          pattern="[0-9]*"
          placeholder={t('form.partner.channel.placeholder.renewPrice')}
          label={t('form.partner.channel.renewPrice')}
          onChange={(v) => setValue('renewPrice', v >= 0 ? v : 0)}
          currency
          bordered
        />
      </Box>
      <Box mb={5} display="flex" justifyContent="flex-end">
        <ButtonPrimary type="button" onClick={() => submit()} size="small" disabled={loading}>
          <LoaderSm loading={loading} width={20} />
          {t('button.save')}
        </ButtonPrimary>
      </Box>
    </Box>
  );
};
FormParterProgramChannel.propTypes = {
  close: PropTypes.func.isRequired,
};
FormParterProgramChannel.defaultProps = {};

export default FormParterProgramChannel;
