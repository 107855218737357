import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@material-ui/core';
import Typography, { fontStyle } from 'core/ui/Typography';
import Box from 'core/ui/Box';
import { warningColor } from 'core/ui/Colors';
import { channelCurrency, date } from 'core/utils/formatters';
import { channelsSelectors } from 'modules/channels';
import { useSelector } from 'react-redux';

const UserMembershipPlanResume = ({ channelKey }) => {
  const { t } = useTranslation();
  const channel = useSelector((state) => channelsSelectors.getChannel(state));

  const showNextRenew =
    ((!channelKey?.started || !!channelKey?.renewable) && !channelKey?.pauseAt) ||
    (channelKey?.pauseAt && channelKey?.pauseRemainingDays > 0);

  return (
    <Box>
      <>
        <Tooltip
          placement="top"
          title={`Key Uniq ID: ${channelKey?.id}  -  Plan Uniq ID: ${channelKey?.membershipPlan?.id}`}
        >
          <Box>
            <Typography>{channelKey?.membershipPlan && channelKey?.membershipPlan.name}</Typography>
          </Box>
        </Tooltip>

        {showNextRenew && (
          <>
            <Typography style={fontStyle.small}>
              {t('channel.membership.plan.cost')}:{' '}
              {channelCurrency(channelKey?.membershipPlan.cost, channel?.currency)}
            </Typography>
            {channelKey?.membershipPlan.introductoryCost > 0 && (
              <Typography style={fontStyle.small}>
                {t('channel.membership.costNextRenew')}:{' '}
                {channelCurrency(channelKey?.nextRenewAmounts?.nextCost, channel?.currency)}
              </Typography>
            )}
          </>
        )}
        {channelKey?.membershipCoupon && channelKey?.membershipCoupon.code && (
          <Typography color="primary" style={{ ...fontStyle.xSmall }}>
            {t('channel.membership.member.coupon')}&nbsp;
            {channelKey?.membershipCoupon.code}
          </Typography>
        )}
        {channelKey?.membershipPlan.online && channelKey?.membershipPlan.inPerson && (
          <Typography color="primary" style={{ ...fontStyle.xSmall }}>
            {t('workout.setting.online')},&nbsp;{t('workout.setting.inPerson')}
          </Typography>
        )}
        {!channelKey?.membershipPlan.online && channelKey?.membershipPlan.inPerson && (
          <Typography color="primary" style={{ ...fontStyle.xSmall }}>
            {t('workout.setting.inPerson')}
          </Typography>
        )}
        {channelKey?.membershipPlan.online && !channelKey?.membershipPlan.inPerson && (
          <Typography color="primary" style={{ ...fontStyle.xSmall }}>
            {t('workout.setting.online')}
          </Typography>
        )}
        {channelKey?.notStarted && (
          <Typography color="secondary" style={{ ...fontStyle.small }}>
            {t('channel.label.plan.startDate')}&nbsp;
            {date(channelKey?.startAt, channel?.dateFormatReact)}
          </Typography>
        )}
        {channelKey?.channelKeyEnrollOrigin && (
          <Tooltip placement="top" title={`Enroll key ID: ${channelKey.channelKeyEnrollOrigin.id}`}>
            <Typography ml={3} style={{ ...fontStyle.small }}>
              {t('channel.label.plan.enrollCreatedBy', {
                name: channelKey.channelKeyEnrollOrigin.membershipPlan?.name,
              })}
            </Typography>
          </Tooltip>
        )}
        {!channelKey?.pauseAt && !!channelKey?.pauseStartAt && (
          <Typography style={{ ...fontStyle.medium, color: warningColor }}>
            {t('channel.label.plan.pauseAt')}&nbsp;
            {date(channelKey?.pauseStartAt, channel?.dateFormatReact)}
          </Typography>
        )}
      </>
    </Box>
  );
};

export default UserMembershipPlanResume;
