import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import languages from 'core/locale/languages';

import * as selectors from './selectors';
import * as sagas from './sagas';

/* ---------- Action Creators and Types ---------- */
const { Types, Creators } = createActions(
  {
    getLanguagesStart: [],
    getLanguagesSuccess: ['languages'],
    getLanguagesError: ['languages'],

    setLanguageStart: ['language'],
    setLanguageSuccess: ['language'],
    setLanguageError: [],
  },
  { prefix: 'LANGUAGE/' },
);

/* ---------- Initial State ---------- */
const INITIAL_STATE = Immutable({
  data: [],
  language: languages[0],
  error: false,
  loading: false,
});

/* ---------- Reducers ---------- */
const errorReducer = (state) => state.merge({ loading: false, error: false });
const loadingReducer = (state) => state.set('loading', true);

const setLanguage = (state, { language }) =>
  state.merge({
    language,
    error: false,
    loading: false,
  });

const getLanguagesSuccess = (state, action) =>
  state.merge({
    data: action.languages,
    loading: false,
    error: null,
  });

/* ---------- Main Reducer ---------- */
const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_LANGUAGE_START]: loadingReducer,
  [Types.SET_LANGUAGE_ERROR]: errorReducer,
  [Types.SET_LANGUAGE_SUCCESS]: setLanguage,

  [Types.GET_LANGUAGES_START]: loadingReducer,
  [Types.GET_LANGUAGES_ERROR]: errorReducer,
  [Types.GET_LANGUAGES_SUCCESS]: getLanguagesSuccess,
});

/* ---------- Exporting ---------- */
export const languageActions = Creators;
export const languageTypes = Types;
export const languageSelectors = selectors;
export const languageSagas = sagas;

export default reducer;
