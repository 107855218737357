import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import moment from 'moment';
import Box from 'core/ui/Box';
import { ButtonPrimary } from 'core/ui/Button';
import { Search } from '@material-ui/icons';
import { channelReportsModule } from 'modules/channelReports';
import useSelectors from 'modules/map/useSelectors';
import Typography from 'core/ui/Typography';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import LoaderSm from 'core/ui/LoaderSm';
import Grid from 'core/ui/Grid';
import ChoosenChannelTags from 'core/ui/ChoosenChannelTags';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import Checkbox from 'core/ui/Checkbox';
import NeoDatePickerRounded from 'core/ui/DatePickers/NeoDatePickerRounded';
import ResultUsers from './ResultUsers';
import SkeletonPayamentsReport from './Skeleton';
import BarMonths from './BarMonths';

const schema = yup.object({
  startAt: yup
    .string()
    // I'm almost certain that certain times and timezones will cause incorrect data in the way this is handled, but I haven't investigated it in depth
    .transform((value) => moment(value).format('YYYY-MM-DD'))
    .required(),
  endAt: yup
    .string()
    .transform((value) => moment(value).format('YYYY-MM-DD'))
    .required(),
  workoutsType: yup.string(),
  usersWithNoAttendances: yup.boolean(),
  onlyActiveMembers: yup.boolean(),
  membersTags: yup.array().of(yup.string().required()),
});

const Attendance = ({ channel, loading, getReport, genericCheckIn }) => {
  const { t } = useTranslation();
  const { request } = useActions();
  const loadingAttendance = useSelectors(channelReportsModule, 'loadingAttendance');
  const [attendanceData, setAttendanceData] = useState();
  const data = useSelectors(channelReportsModule, 'data');

  const { control, handleSubmit } = useForm({
    defaultValues: {
      startAt: moment().startOf('month').toDate(),
      endAt: moment().endOf('day').toDate(),
      workoutsType: '',
      usersWithNoAttendances: false,
      onlyActiveMembers: false,
      membersTags: [],
    },
    resolver: yupResolver(schema),
  });

  const attendanceResume = () => {
    request({
      action: Modules.channelReports.actions.attendanceResume,
      data: {
        channelId: channel.id,
      },
      options: {
        onSuccess: (resp) => {
          setAttendanceData(resp);
        },
      },
    });
  };

  useEffect(() => {
    if (channel?.id) {
      handleSubmit(getReport)();
      attendanceResume();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel]);

  return (
    <Box mt={2}>
      <form onSubmit={handleSubmit(getReport)}>
        <Box display="flex" flexDirection="column" gridGap={8}>
          <Box
            display="flex"
            alignItems="flex-end"
            justifyContent="flex-start"
            flexWrap="wrap"
            gridGap={8}
          >
            <Controller
              control={control}
              name="startAt"
              render={({ field }) => (
                <NeoDatePickerRounded
                  label={t('channel.reports.filter.start')}
                  value={field.value}
                  onChange={field.onChange}
                  disablePast={false}
                  usFormat={channel?.useUsDateFormat}
                  autoOk
                  labelClassName="tw-uppercase"
                />
              )}
            />

            <Controller
              control={control}
              name="endAt"
              render={({ field }) => (
                <NeoDatePickerRounded
                  label={t('channel.reports.filter.start')}
                  value={field.value}
                  onChange={field.onChange}
                  disablePast={false}
                  usFormat={channel?.useUsDateFormat}
                  autoOk
                  labelClassName="tw-uppercase"
                />
              )}
            />

            <Box>
              <Controller
                control={control}
                name="workoutsType"
                render={({ field }) => (
                  <SelectBoxObject
                    label="CLASS TYPE"
                    bordered
                    value={field.value}
                    setValue={field.onChange}
                    options={channel?.workoutsTypes}
                  />
                )}
              />
            </Box>

            <Box>
              <ButtonPrimary type="submit" disabled={loading}>
                <Search style={{ color: 'white' }} fontSize="small" />
                {t('button.search')}&nbsp;
              </ButtonPrimary>
            </Box>
          </Box>

          <div className="tw-flex tw-flex-col tw-items-start">
            <Controller
              control={control}
              name="usersWithNoAttendances"
              render={({ field }) => (
                <Checkbox
                  label={t('channel.reports.zeroAttendance')}
                  checked={field.value}
                  onClick={() => field.onChange(!field.value)}
                />
              )}
            />

            <Controller
              control={control}
              name="onlyActiveMembers"
              render={({ field }) => (
                <Checkbox
                  label={t('channel.reports.activeMembersOnly')}
                  checked={field.value}
                  onClick={() => field.onChange(!field.value)}
                />
              )}
            />
          </div>
        </Box>

        <Grid container spacing={5} mb={3} mt={3}>
          <Grid item xs={12} md={4}>
            <Controller
              control={control}
              name="membersTags"
              render={({ field }) => (
                <ChoosenChannelTags
                  label={t('channel.label.memberTags')}
                  placeholder={t('channel.tags')}
                  val={field.value}
                  setValue={(v) => field.onChange(v.map((tag) => tag?.name || tag))}
                  channelId={channel?.id}
                  bordered
                />
              )}
            />
          </Grid>
        </Grid>
      </form>

      {!data?.users || loading ? (
        <SkeletonPayamentsReport />
      ) : (
        <Box>
          <ResultUsers channel={channel} data={data?.users || []} genericCheckIn={genericCheckIn} />

          <Box mt={3}>
            <BarMonths chartData={data?.chart} />
          </Box>

          <Box mt={3}>
            <Typography align="center">{t('label.totalAttendance')}</Typography>
            <LoaderSm center loading={loadingAttendance} />
            <BarMonths chartData={attendanceData} />
          </Box>
        </Box>
      )}
    </Box>
  );
};

Attendance.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  loading: PropTypes.bool,
  getReport: PropTypes.func,
};

Attendance.defaultProps = {
  loading: false,
  getReport: () => {},
};

export default Attendance;
