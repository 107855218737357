import React from 'react';
import Box from 'core/ui/Box';
import Video from 'core/ui/Video';
import ReactPlayer from 'react-player';

const ViewVideo = ({ url }) => (
  <>
    {url && (
      <Box display="flex" justifyContent="center">
        {url.indexOf('streamfitness-workout-video') > -1 ? (
          <Video src={url} controls showPlayButton={false} />
        ) : (
          <Box className="container-aspect container-aspect-47">
            <Box className="item-aspect">
              <ReactPlayer playing={false} controls url={url} width="100%" height="100%" />
            </Box>
          </Box>
        )}
      </Box>
    )}
  </>
);

export default ViewVideo;
