import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form-v5';
import { useDispatch } from 'react-redux';
import notifications from 'modules/notifications';
import { useTranslation } from 'react-i18next';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import { formatDateToApi } from 'core/utils/formatters';
import { automatedMessageTypes } from 'core/utils/consts';
import { messageListsActions } from 'modules/messageLists';
import { messagesActions } from 'modules/messages';
import useSelectors from 'modules/map/useSelectors';
import { ownerableMessagesModule } from 'modules/ownerableMessages';
import { timeZoneName } from 'core/utils/formatters/date';

export default ({ channelId, automated = false }) => {
  const dispatch = useDispatch();
  const { request } = useActions();
  const { t } = useTranslation();
  const msgTypesData = useSelectors(ownerableMessagesModule, 'messagesTypes');
  const { register, handleSubmit, setValue, watch, setError, errors, reset } = useForm();

  const [currentMessageType, setCurrentMessageType] = useState(null);

  const formElementRef = useRef();
  const [attachments, setAttachments] = useState([]);

  const messagesTypes = msgTypesData.filter((x) => x.kind === 'email' && x.recurring);

  useEffect(() => {
    if (channelId) {
      request({
        action: Modules.ownerableMessages.actions.getMessagesTypes,
        data: {
          channelId,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelId]);

  useEffect(() => {
    if (messagesTypes && watch('messagesTypeId')) {
      setCurrentMessageType(messagesTypes?.find((x) => x.id === Number(watch('messagesTypeId'))));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [msgTypesData, watch('messagesTypeId')]);

  useEffect(() => {
    if (messagesTypes && !watch('messagesTypeId')) {
      setValue('messagesTypeId', messagesTypes[0]?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [msgTypesData, watch('messagesTypeId')]);

  useEffect(() => {
    register('text', { required: true });
    register('textRaw');
    register('subject', { required: false });
    register('method');
    register('messageImage');
    register('replyTo');
    register('videoLink');
    register('membersTypes');
    register('customContacts');
    register('membersTags');
    register('membershipsTags');
    register('showCustom');
    register('showCompetitions');
    register('checkedLists');
    register('copyMe');
    register('competitionId');
    register('actionText');
    register('actionLink');

    register('scheduleSend');
    register('scheduleSendAtHour');
    register('scheduleSendAtDate');

    if (!channelId) {
      register('twilioNumberId', { validate: (v) => watch('method') !== 'sms' || !!v });
      register('streamUSUsers');
      register('streamWorldUsers');
    }

    if (automated) {
      register('frequency');
      register('recurringStartDate');
      register('messagesTypeId');
      register('days');
      register('classes');
      register('recurringType');
    }

    setTimeout(() => {
      setValue('frequency', 'week');
      setValue('method', automated ? 'email' : 'sms');
      if (automated) {
        setValue('recurringType', 'custom');
      }
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelId]);

  const validate = (data) => {
    if (!data.method) {
      dispatch(notifications.warning(t('channel.message.validate.method')));
      return false;
    }

    if (!channelId && !data.replyTo && !data.streamWorldUsers && !data.streamUSUsers) {
      if (!data.checkedLists || (data.checkedLists && data.checkedLists.length === 0)) {
        dispatch(notifications.warning(t('admin.message.validate.lists')));
        return false;
      }
    }

    if (data.method === 'sms' && data.text.length > 1500) {
      dispatch(notifications.warning(t('admin.message.validate.lists')));
      return false;
    }

    if (data?.kind === automatedMessageTypes.registration_lapse) {
      if (!data.days || data.days <= 0) {
        dispatch(notifications.warning(t('channel.message.validate.days')));
        return false;
      }
    }

    if (data.showCompetitions && !data.competitionId) {
      dispatch(notifications.warning(t('channel.message.validate.competition')));
      return false;
    }
    if (data.kind === 'recurring' && data.frequency && !data.recurringStartDate) {
      setError('recurringStartDate');
      return false;
    }
    return true;
  };

  const onSubmit = (data) => {
    if (!validate(data)) {
      return;
    }

    let scheduleSendAt = null;
    if (data.scheduleSend && data.scheduleSendAtHour && data.scheduleSendAtDate) {
      const dateStr = `${data.scheduleSendAtDate?.substr(0, 10)} ${data.scheduleSendAtHour?.substr(
        11,
        8,
      )} ${timeZoneName()}`;

      scheduleSendAt = formatDateToApi(dateStr, timeZoneName());
    }

    const dataForm = {
      ...data,
      customContacts: data.customContacts && data.customContacts.map((u) => u.id),
      membersTags: data.membersTags && data.membersTags.map((u) => u.name || u),
      membershipsTags: data.membershipsTags && data.membershipsTags.map((u) => u.name || u),
      attachments,
      scheduleSendAt,
      channelId,
      isTemplate: !!automated,
      recurringStartDate: formatDateToApi(data.recurringStartDate),
    };

    if (!channelId) {
      dispatch(
        messagesActions.postMessagesStart(dataForm, () => {
          setValue('scheduleSend', false);
          setValue('scheduleSendAtHour', null);
          setValue('scheduleSendAtDate', null);

          setValue('text', '');
          setValue('replyTo', null);
          setValue('messageImage', null);

          dispatch(notifications.success(t('admin.message.success')));
          dispatch(messagesActions.getMessagesStart());
        }),
      );
      return;
    }

    request({
      action: Modules.ownerableMessages.actions.postMessage,
      data: dataForm,
      options: {
        onSuccess: () => {
          setValue('subject', '');
          setValue('text', '');
          setValue('replyTo', null);
          setValue('videoLink', null);
          setValue('membersTypes', []);
          setValue('messageImage', null);
          setValue('showCustom', false);
          setValue('showCompetitions', false);
          setValue('customContacts', null);
          setValue('membersTags', []);
          setValue('membershipsTahs', []);
          setValue('competitionId', null);
          setValue('frequency', null);
          setValue('recurringStartDate', null);
          setValue('kind', automated ? 'recurring' : 'normal');

          setValue('scheduleSend', false);
          setValue('scheduleSendAtHour', null);
          setValue('scheduleSendAtDate', null);

          setAttachments([]);
          dispatch(notifications.success(t('admin.message.success')));
          request({
            action: Modules.ownerableMessages.actions.getMessages,
            data: {
              channelId,
              template: !!automated,
            },
          });
        },
      },
    });
  };

  const removeContactList = (item) => {
    dispatch(
      messageListsActions.deleteContactListStart(item.messageListId, item.id, () => {
        dispatch(messageListsActions.getMessageListsStart());
      }),
    );
  };

  const copyMessage = (message) => {
    setValue('subject', message.subject);
    setValue('method', message.method);
    setValue('text', message.fullText);
    setValue('frequency', message.frequency);
    window.scrollTo({
      top: 50,
      behavior: 'smooth',
    });
  };

  const editMessage = (message) => {
    reset({ ...message });
  };

  return {
    onSubmit,
    setValue,
    watch,
    handleSubmit,
    errors,
    formElementRef,
    attachments,
    setAttachments,
    copyMessage,
    removeContactList,
    messagesTypes,
    currentMessageType,
    editMessage,
  };
};
