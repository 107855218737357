import React, { useEffect, useState } from 'react';
import { ButtonPrimary } from 'core/ui/Button';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import Typography, { fontStyle } from 'core/ui/Typography';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { onlineColor, primary, secondary } from 'core/ui/Colors';
import { IconButton } from '@material-ui/core';
import { Add, ArrowUpward, Edit } from '@material-ui/icons';
import { storeCategoriesModule } from 'modules/storeCategories';
import SFDataTable from 'core/ui/DataTable';
import Paper from 'core/ui/Paper';
import useActions from 'modules/map/useActions';
import useSelectors from 'modules/map/useSelectors';
import Modules from 'modules';
import ModalFormCategory from './Modal';

const StoreCategories = ({ ownerableType, ownerableId }) => {
  const { t } = useTranslation();
  const { request } = useActions();
  const storeCategories = useSelectors(storeCategoriesModule, 'data');

  const [modal, setModal] = useState({
    open: false,
  });

  const getStoreCategories = () => {
    request({
      action: Modules.storeCategories.actions.getStoreCategories,
      data: {
        ownerableType,
        ownerableId,
        showInactive: true,
      },
    });
  };

  useEffect(() => {
    if (ownerableId) {
      getStoreCategories();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request, ownerableId, ownerableType]);

  const openModal = () => {
    setModal((prev) => ({ ...prev, mode: 'new', open: true }));
  };

  const onEditClick = (item) => {
    setModal((prev) => ({ ...prev, item, mode: 'edit', open: true }));
  };

  const putOrderCategory = (storeCategoryId, order) => {
    let newOrder = order;
    if (newOrder < 0) {
      newOrder = 0;
    }
    request({
      action: Modules.storeCategories.actions.putOrderCategory,
      data: {
        ownerableId,
        ownerableType,
        storeCategoryId,
        newOrder,
      },
      options: {
        onSuccess: () => {
          getStoreCategories();
        },
      },
    });
  };

  const columns = [
    {
      name: '',
      selector: 'name',
      width: '40px',
      cell: (row, index) => (
        <>
          {onEditClick && index > 0 && (
            <Typography
              onClick={() =>
                putOrderCategory(
                  row?.id,
                  row?.orderShow < index - 1 ? row?.orderShow - 1 : index - 1,
                )
              }
              className="link-hover"
              variant="caption"
              align="center"
            >
              <ArrowUpward style={{ ...fontStyle.large, color: secondary }} />
            </Typography>
          )}
        </>
      ),
      sortable: true,
      center: true,
    },
    {
      name: 'Name',
      selector: 'name',
      cell: (row) => <Typography>{row.name}</Typography>,
      sortable: true,
    },
    {
      name: 'Active',
      selector: 'active',
      sortable: true,
      right: true,
      cell: (row) => (
        <>
          {!row.active ? (
            <RadioButtonUncheckedIcon style={{ ...fontStyle.medium, color: primary }} />
          ) : (
            <CheckCircleOutlineIcon style={{ ...fontStyle.medium, color: onlineColor }} />
          )}
        </>
      ),
    },
    {
      sortable: false,
      center: true,
      cell: (row) => (
        <IconButton onClick={() => onEditClick(row)}>
          <Edit />
        </IconButton>
      ),
    },
  ];

  return (
    <Box>
      {modal.open && (
        <ModalFormCategory
          open={modal.open}
          item={modal.item}
          mode={modal.mode}
          ownerableType={ownerableType}
          ownerableId={ownerableId}
          close={() => setModal((prev) => ({ ...prev, open: false, item: {} }))}
          callback={(nameList) =>
            setModal((prev) => ({ ...prev, open: false, item: {}, nameListExpand: nameList }))
          }
        />
      )}
      <Paper className="paper-rounded" p={3}>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <Typography variant="h4" color="primary" mr={1}>
              {t('channel.store.categories')}
            </Typography>
          </Box>
          <Box>
            <ButtonPrimary onClick={() => openModal()}>
              <Box display="flex" alignItems="flex-end">
                <Add size="small" />
                <Box alignItems="center">
                  <Typography component="span" mr={1}>
                    {t('channel.button.addCategory')}
                  </Typography>
                </Box>
              </Box>
            </ButtonPrimary>
          </Box>
        </Box>
        <Box mt={2}>
          <SFDataTable
            noHeader
            columns={columns}
            data={storeCategories}
            pagination
            showExport={false}
          />
        </Box>
      </Paper>
    </Box>
  );
};

export default StoreCategories;
