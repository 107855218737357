import { createSelector } from 'reselect';

const getState = (state) => state.competitionsSearch;

export const getLoading = createSelector(getState, (state) => !!state.loading);
export const getLoadingRecents = createSelector(getState, (state) => !!state.loadingRecents);
export const getLoadingUpcoming = createSelector(getState, (state) => !!state.loadingUpcoming);
export const getLoadingCompeting = createSelector(getState, (state) => !!state.loadingCompeting);
export const getLoadingHosting = createSelector(getState, (state) => !!state.loadingHosting);

export const getSearchData = createSelector(getState, (state) => state.data);
export const getSearchPagination = createSelector(getState, (state) => state.pagination);

export const getQueryData = createSelector(getState, (state) => state.queryData);

export const getRecents = createSelector(getState, (state) => state.recents);
export const getRecentsPagination = createSelector(getState, (state) => state.paginationRecents);

export const getUpcoming = createSelector(getState, (state) => state.upcoming);
export const getUpcomingPagination = createSelector(getState, (state) => state.paginationUpcoming);

export const getCurrent = createSelector(getState, (state) => state.current);
export const getCurrentPagination = createSelector(getState, (state) => state.paginationCurrent);
