import React from 'react';
import propTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import Box from 'core/ui/Box';
import Form from 'components/Workout/Form';
import { useDispatch, useSelector } from 'react-redux';
import { channelsActions, channelsSelectors } from 'modules/channels';
import Typography from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';

const WorkoutForm = ({ open, setOpen }) => {
  const channel = useSelector((state) => channelsSelectors.getChannel(state));
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const callbackSave = () => {
    setOpen(false);
    dispatch(channelsActions.getChannelStart(channel.id));
  };

  return (
    <Dialog
      PaperProps={{ style: { borderRadius: 5 } }}
      maxWidth="md"
      scroll="body"
      onClose={() => setOpen(false)}
      open={open}
    >
      <Box p={3}>
        <Typography mb={5} variant="h2" align="center" color="primary">
          {t('workout.form.quickAdd')}
        </Typography>
        <Typography my={3} mb={5} variant="body2" color="secondary">
          {t('workout.form.quickAdd.message')}
        </Typography>
        <Form callbackSave={callbackSave} channelId={channel.id} mode="quick-add" />
      </Box>
    </Dialog>
  );
};

WorkoutForm.propTypes = {
  open: propTypes.bool,
  setOpen: propTypes.func,
};

WorkoutForm.defaultProps = {
  open: false,
  setOpen: null,
};

export default WorkoutForm;
