import React, { useEffect } from 'react';
import Typography from 'core/ui/Typography';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import { DialogContent, Dialog, DialogActions, Button, Divider } from '@material-ui/core';
import { ButtonPrimary } from 'core/ui/Button';
import useSelectors from 'modules/map/useSelectors';
import { userChildrenModule } from 'modules/userChildren';
import { useSelector } from 'react-redux';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import ImageMedia from 'components/Profile/ImageMedia';
import { channelCurrency } from 'core/utils/formatters';
import { sessionSelectors } from 'modules/session';

const UserItem = ({ child, confirmCancel, isRegistered }) => {
  const { t } = useTranslation();

  return (
    <Box display="flex" alignItems="center" m={1}>
      <ImageMedia
        user={child}
        image={child.image ? child.image : {}}
        size={50}
        showCountry={false}
      />

      <Box flexGrow={1} ml={2}>
        <Typography component="span" variant="body2" color="textPrimary">
          {child.name}
        </Typography>
      </Box>
      {isRegistered && <ButtonPrimary onClick={confirmCancel}>{t('button.cancel')}</ButtonPrimary>}
    </Box>
  );
};

export default function ModalCancelRegister({ open, close, workout, confirmCancel }) {
  const { t } = useTranslation();
  const { request } = useActions();
  const user = useSelector((state) => sessionSelectors.getUser(state));

  const data = useSelectors(userChildrenModule, 'data');

  useEffect(() => {
    request({ action: Modules.userChildren.actions.getChildren, data: { userId: user?.id } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <Dialog open={open} maxWidth="xs" fullWidth onClose={close}>
      <DialogContent>
        <Box>
          <Box mb={3}>
            <Typography align="center" color="primary" variant="h4">
              {t(
                workout?.showLateCancellation
                  ? 'workout.confirm.lateCancellation'
                  : 'workout.confirm.unRegister',
                {
                  amount:
                    channelCurrency(
                      workout?.cancellationPolicies.cancelRegistrationLimitLateAmount,
                      workout?.channel?.currency,
                    ) || '0,00',
                },
              )}
            </Typography>
          </Box>

          <Box>
            <UserItem
              child={user}
              workout={workout}
              confirmCancel={() => confirmCancel()}
              isRegistered={workout?.registered}
            />
            <Divider variant="inset" />
          </Box>
          {data?.map((child) => (
            <Box key={child?.id}>
              <UserItem
                child={child}
                workout={workout}
                confirmCancel={() => confirmCancel(child?.id)}
                isRegistered={workout?.registeredChildren?.find((x) => x.id === child?.id)}
              />
              <Divider variant="inset" />
            </Box>
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary">
          {t('button.noThanks')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ModalCancelRegister.defaultProps = {};
