import React from 'react';
import PropTypes from 'prop-types';
import Box from 'core/ui/Box';
import { Dialog, Slide } from '@material-ui/core';
import Texture from 'core/ui/Texture';
import Form from './Form';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const ModalFormPrScore = ({ open, itemEdit, selectedPr, close, callback }) => (
  <Dialog maxWidth="sm" fullWidth open={open} onClose={close} TransitionComponent={Transition}>
    <Texture style={{ minHeight: '100%' }}>
      <Box p={5}>
        <Form callback={callback} itemEdit={itemEdit} selectedPr={selectedPr} />
      </Box>
    </Texture>
  </Dialog>
);

ModalFormPrScore.defaultProps = {
  close: () => {},
  callback: () => {},
};

ModalFormPrScore.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  close: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  callback: PropTypes.func,
};

export default ModalFormPrScore;
