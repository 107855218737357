import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import Typography from 'core/ui/Typography';
import Button, { ButtonPrimary } from 'core/ui/Button';
import ModalRegistration from './Registration/ModalRegistration';

const EventTab = ({ title, active, onClick }) => (
  <div>
    <Button onClick={onClick}>
      <Typography className={`tw-font-semibold ${active ? 'tw-text-white' : 'tw-text-gray-300'}`}>
        {title}
      </Typography>
    </Button>
    {active ? <div className="tw-h-1 tw-w-full tw-bg-white" /> : <div className="tw-h-1" />}
  </div>
);

const EventTabs = ({ event, channel, tab, setTab, refreshEvent }) => {
  const { t } = useTranslation();
  const [modalRegistration, setModalRegistration] = useState(false);

  return (
    <div className="-mt-2 tw-flex tw-h-10 tw-w-full tw-flex-1 tw-flex-row tw-items-center tw-bg-zinc-700 tw-pl-2">
      <div className="tw-flex tw-flex-1 tw-flex-row tw-items-center tw-gap-2">
        <EventTab onClick={() => setTab('info')} title={t('tab.info')} active={tab === 'info'} />
        {channel?.isCoach && (
          <EventTab
            onClick={() => setTab('registrations')}
            title={t('tab.registrations')}
            active={tab === 'registrations'}
          />
        )}
      </div>

      {modalRegistration && (
        <ModalRegistration
          open={modalRegistration}
          channel={channel}
          event={event}
          close={() => setModalRegistration(false)}
          callback={() => {
            if (refreshEvent) {
              refreshEvent();
            }
            setModalRegistration(false);
          }}
        />
      )}

      <div>
        <ButtonPrimary
          disabled={!channel?.isCoach && (event?.registered || !event?.allowRegistrations)}
          onClick={() => setModalRegistration(true)}
          style={{ borderRadius: 0 }}
          className="tw-h-10 tw-px-4"
        >
          {t(event?.registered ? 'button.registered' : 'button.register')}
        </ButtonPrimary>
      </div>
    </div>
  );
};

export default EventTabs;
