import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import { ToastContainer, toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { retrieveAuthHeaders } from 'core/api/auth-headers';
import { persistData, retrieveData } from 'core/utils/session-storage';
import StreamFitnessSplash from 'core/ui/StreamFitnessSplash';
import { matchPath } from 'react-router';
import { makeStyles, CircularProgress } from '@material-ui/core';
import useVerifyUserProfile from 'core/useVerifyUserProfile';
import Modules from 'modules';
import useActions from 'modules/map/useActions';
import Box from 'core/ui/Box';
import { last } from './core/utils/fp';
import { notificationsSelectors } from './modules/notifications';
import { appActions, appSelectors } from './modules/app';
import { sessionActions, sessionSelectors } from './modules/session';
import { languageActions } from './modules/language';

const useStyles = makeStyles((theme) => ({
  toask: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: '66px',
    },
  },
}));

const Main = ({ history, location, route: { routes } }) => {
  const isAuthenticated = useSelector((state) => sessionSelectors.getIsAuthenticated(state));
  const user = useSelector((state) => sessionSelectors.getUser(state));
  const loadingStart = useSelector((state) => sessionSelectors.getLoadingStart(state));
  const loading = useSelector((state) => sessionSelectors.getLoading(state));
  const loadingGlobal = useSelector((state) => appSelectors.getLoading(state));
  const [loadStart, setLoadStart] = useState(true);
  const classes = useStyles();
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const { request } = useActions();

  const pathName = location.pathname;

  useVerifyUserProfile();

  const getRoute = useCallback(
    (arrayRoutes) => {
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < arrayRoutes.length; index++) {
        const r = arrayRoutes[index];
        const match = matchPath(location.pathname, r);

        if (match) {
          return r;
        }

        if (r.routes) {
          return getRoute(r.routes);
        }
      }
      return null;
    },
    [location.pathname],
  );

  useEffect(() => {
    const route = getRoute(routes);
    dispatch(appActions.setCurrentRoute(route));
  }, [routes, dispatch, getRoute]);

  useEffect(() => {
    let currentPath = `${location.pathname}${location.search}`;
    if (currentPath === '/') currentPath = '/dashboard';
    if (currentPath.indexOf('/coach/active/') > -1) return;
    if (currentPath.indexOf('/auth/') > -1) return;

    persistData('loggedInRedirectPath', currentPath);
  }, [location]);

  const redirectToLogIn = useCallback(() => {
    let currentPath = `${location.pathname}${location.search}`;
    if (currentPath === '/') currentPath = '/dashboard';
    if (currentPath.indexOf('/coach/active/') > -1) currentPath = '/dashboard';

    persistData('loggedInRedirectPath', currentPath);
    history.push('/auth/login');
  }, [history, location]);

  const checkCurrentLanguage = useCallback(() => {
    const currentLanguage = retrieveData('currentLanguage');
    if (currentLanguage) {
      const language = JSON.parse(currentLanguage);
      dispatch(languageActions.setLanguageStart(language));
      i18n.changeLanguage(language.language);
    }
  }, [dispatch, i18n]);

  const checkAuthentication = useCallback(() => {
    if (
      !loadStart &&
      !loadingStart &&
      !loading &&
      !isAuthenticated &&
      !location.pathname.match('/auth') &&
      !location.pathname.match('/coach/active')
    ) {
      const route = getRoute(routes);
      if (!route || route.public !== true) {
        redirectToLogIn();
      }
    }
  }, [
    loading,
    loadingStart,
    loadStart,
    isAuthenticated,
    location,
    getRoute,
    routes,
    redirectToLogIn,
  ]);

  const checkUserInfo = useCallback(() => {
    if (
      !loadStart &&
      !loadingStart &&
      !loading &&
      isAuthenticated &&
      !pathName.match('/profile/edit') &&
      !pathName.match('/competition/view') &&
      pathName.indexOf('/embed/memberships/') === -1 &&
      pathName.indexOf('/channel/view/') === -1 &&
      pathName.indexOf('/calendar/') === -1 &&
      !user.name &&
      user.role
    ) {
      history.push('/profile/edit');
    }
  }, [loading, loadingStart, loadStart, isAuthenticated, pathName, user, history]);

  const loadCountries = useCallback(() => {
    request({
      action: Modules.countries.actions.getCountries,
    });
    request({
      action: Modules.countries.actions.getCurrencies,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isToRedirect =
    pathName.indexOf('/embed/memberships/') === -1 &&
    pathName.indexOf('/channel/view/') === -1 &&
    pathName.indexOf('/competition/view') === -1 &&
    pathName.indexOf('/channel/events/') === -1 &&
    pathName.indexOf('/calendar') === -1 &&
    pathName.indexOf('workout/upload');

  const checkUserRole = useCallback(() => {
    if (!isToRedirect) {
      return;
    }
    if (!loading && !loadStart && isAuthenticated) {
      if (user.new) {
        history.push('/signup/type-account');
      } else {
        if (pathName.includes('/auth/by_token')) return;

        const loggedInRedirectPath = retrieveData('loggedInRedirectPath');
        if (loggedInRedirectPath && !user.modal) {
          history.push(loggedInRedirectPath);
        } else if (user.name && !user.modal) {
          history.push('/dashboard');
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, loadStart, isAuthenticated, history, user]);

  useEffect(() => {
    loadCountries();
    checkCurrentLanguage();
  }, [checkCurrentLanguage, loadCountries]);

  useEffect(() => {
    checkAuthentication();
  }, [checkAuthentication]);

  useEffect(() => {
    checkUserInfo();
  }, [checkUserInfo]);

  useEffect(() => {
    checkUserRole();
  }, [checkUserRole]);

  const notifications = useSelector((state) => notificationsSelectors.getNotifications(state));

  const setNotification = useCallback(() => {
    const notification = last(notifications);

    if (notification) toast[notification.level](notification.message);
  }, [notifications]);

  useEffect(() => {
    setNotification();
  }, [setNotification, notifications]);

  useEffect(() => {
    const authHeaders = retrieveAuthHeaders(retrieveData)();
    if (authHeaders['access-token']) {
      dispatch(sessionActions.authenticateStart());
    } else {
      dispatch(sessionActions.setLoadingStart(false));
    }
  }, [dispatch]);

  useEffect(() => {
    setLoadStart(loadingStart);
  }, [loadingStart]);

  return (
    <>
      {/* <StreamFitnessLoader loading={loadingGlobal} /> */}
      {loadingGlobal && (
        <Box
          style={{
            zIndex: 9999,
            position: 'fixed',
            right: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(0,0,0,0.5)',
          }}
          className={classes.backdrop}
          open={loadingGlobal}
          color="primary"
          onClick={() => dispatch(appActions.setLoading(false))}
        >
          <CircularProgress color="primary" />
        </Box>
      )}
      {loadingStart ? <StreamFitnessSplash /> : <>{renderRoutes(routes)}</>}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        className={classes.toask}
        style={{ zIndex: 9999, marginTop: 60 }}
      />
      {/* NOT RENDER ROUTES FROM LAYOUT IF NOT IS AUTHENTICATED */}
    </>
  );
};

Main.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  location: PropTypes.shape({ pathname: PropTypes.string, search: PropTypes.string }).isRequired,
};

export default Main;
