import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import { Container } from '@material-ui/core';

import { LayoutContext } from 'pages/shared/Layout';
import Form from 'components/Competition/Form';

export default () => {
  const { t } = useTranslation();

  const layoutContext = useContext(LayoutContext);
  useEffect(() => {
    layoutContext.setTitleBar(t('title.competition.new').toUpperCase());
    layoutContext.setActiveMenu('new_competition');
  }, [layoutContext, t]);

  return (
    <div>
      <Container maxWidth="xl">
        <Box pt={5}>
          <Form />
        </Box>
      </Container>
    </div>
  );
};
