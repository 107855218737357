import api from 'core/api';

export const workoutsInvitesModule = 'workoutsInvites';

const loadingStates = {
  start: { loading: true, registerStatus: null },
  error: { loading: false },
  success: { loading: false },
};

const actions = {
  declineInvite: {
    module: workoutsInvitesModule,
    name: 'declineInvite',
    api: ({ workoutId }) => api.delete(`workouts/${workoutId}/workouts_invites`),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
};

export default {
  actions,
  state: {
    loading: false,
  },
};
