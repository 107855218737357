import api from 'core/api';

export const usersImportsModule = 'usersImports';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const actions = {
  importPersonalRecords: {
    module: usersImportsModule,
    name: 'importPersonalRecords',
    api: (data) => api.post(`/users_imports/import_personal_records`, data),
    params: {
      start: ['params'],
      error: [''],
      success: ['previewData'],
    },
    state: loadingStates,
  },
  confirmPersonalRecords: {
    module: usersImportsModule,
    name: 'confirmPersonalRecords',
    api: (data) => api.post(`/users_imports/confirm_personal_records`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  importBenchmarksWorkouts: {
    module: usersImportsModule,
    name: 'importBenchmarksWorkouts',
    api: (data) =>
      api.post(`/users_imports/import_benchmarks_workouts`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }),
    params: {
      start: ['params'],
      error: [''],
      success: ['previewData'],
    },
    state: loadingStates,
  },
  confirmBenchmarksWorkouts: {
    module: usersImportsModule,
    name: 'confirmBenchmarksWorkouts',
    api: (data) => api.post(`/users_imports/confirm_benchmarks_workouts`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  setPreviewData: {
    module: usersImportsModule,
    name: 'setPreviewData',
    params: {
      start: ['params'],
      success: ['previewData'],
    },
  },
};

export default {
  actions,
  state: {
    loading: false,
    data: [],
    previewData: [],
  },
};
