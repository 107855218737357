import { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form-v5';
import { useDispatch, useSelector } from 'react-redux';
import notifications from 'modules/notifications';
import { useTranslation } from 'react-i18next';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import { formatDateToApi } from 'core/utils/formatters';
import { automatedMessageTypes } from 'core/utils/consts';
import { messageListsActions } from 'modules/messageLists';
import { messagesActions, messagesSelectors } from 'modules/messages';
import useSelectors from 'modules/map/useSelectors';
import { ownerableMessagesModule } from 'modules/ownerableMessages';
import { formatDateApi, timeZoneName } from 'core/utils/formatters/date';
import throttle from 'lodash.throttle';
import { removeData } from 'core/utils/session-storage';
import useConfirmation from 'core/useConfirmation';
import { useDebounce } from 'use-debounce';

const useCommunicationForm = ({
  ownerableType,
  ownerableId,
  message,
  automated = false,
  callback,
  persistKey,
  close,
}) => {
  const dispatch = useDispatch();
  const { request } = useActions();
  const { t } = useTranslation();
  const { confirmation } = useConfirmation();
  const [modalAlertPayment, setModalAlertPayment] = useState({ open: false });
  const loadingNewSend = useSelectors(ownerableMessagesModule, 'loadingSend');
  const loadingSend = useSelector((state) => messagesSelectors.getLoadingSend(state));
  const msgTypesData = useSelectors(ownerableMessagesModule, 'messagesTypes');

  const { register, handleSubmit, setValue, watch, setError, errors, reset, getValues } = useForm({
    defaultValues: {
      frequency: 'week',
      method: 'email',
      firstVisitFrequency: 'day',
      recurringType: 'custom',
      ...(message || {}),
    },
  });

  const [messageText] = useDebounce(watch('text'), 500);
  const [messageSubject] = useDebounce(watch('subject'), 500);

  const throttledPersistence = useMemo(
    () =>
      throttle((msg) => {
        const object = getValues();
        if (!persistKey || (!object?.textRaw && !object?.text)) return;

        if (msg?.id && msg?.status === 'draft') {
          handleSubmit(autoSaveMessage)();
        } else {
          localStorage.setItem(persistKey, JSON.stringify(object));
        }
      }, 1000),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [persistKey],
  );

  useEffect(() => {
    throttledPersistence(message);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageText, messageSubject, throttledPersistence]);

  useEffect(() => {
    try {
      const storaged = localStorage.getItem(persistKey);
      if (!storaged) return;

      const draftMessage = JSON.parse(storaged);

      reset({ ...draftMessage, status: 'draft' });
    } catch {
      //
    }
  }, [reset, persistKey]);

  const [currentMessageType, setCurrentMessageType] = useState(null);

  const formElementRef = useRef();
  const [attachments, setAttachments] = useState([]);

  const messagesTypes = msgTypesData.filter((x) => x.kind === 'email' && x.recurring);

  useEffect(() => {
    if (ownerableType === 'Channel') {
      request({
        action: Modules.ownerableMessages.actions.getMessagesTypes,
        data: {
          channelId: ownerableId,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ownerableId, ownerableType]);

  useEffect(() => {
    if (messagesTypes && watch('messagesTypeId')) {
      setCurrentMessageType(messagesTypes?.find((x) => x.id === Number(watch('messagesTypeId'))));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [msgTypesData, watch('messagesTypeId')]);

  // useEffect(() => {
  //   if (messagesTypes && !watch('messagesTypeId')) {
  //     setValue('messagesTypeId', messagesTypes[0]?.id);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [msgTypesData, watch('messagesTypeId')]);

  useEffect(() => {
    register('text', { required: true });
    register('textRaw', { required: false });
    register('subject', { required: false });
    register('method');
    register('status');
    register('messageImage');
    register('replyTo');
    register('videoLink');
    register('membersTypes');
    register('customContacts');
    register('membersTags');
    register('membershipsTags');
    register('showCustom');
    register('showCompetitions');
    register('checkedLists');
    register('copyMe');
    register('competitionId');
    register('actionText');
    register('actionLink');

    register('scheduleSend');
    register('scheduleSendAtHour');
    register('scheduleSendAtDate');
    register('competitionsCategories');
    register('competitionsDivisions');
    register('competitionsUsers');
    register('competitionsWaitlist');

    if (ownerableType === 'Channel' && !ownerableId) {
      register('twilioNumberId', { validate: (v) => watch('method') !== 'sms' || !!v });
      register('streamUSUsers');
      register('streamWorldUsers');
    }

    if (automated) {
      register('frequency');
      register('recurringStartDate');
      register('messagesTypeId');
      register('days');
      register('classes');
      register('firstVisitFrequency');
      register('firstVisitNumber');
      register('recurringType');
    }

    if (message) {
      setTimeout(() => {
        if (message?.scheduleSendAt) {
          setValue('scheduleSend', true);

          setValue(
            'scheduleSendAtDate',
            formatDateApi(message?.scheduleSendAt, 'YYYY-MM-DD HH:mm:ss Z'),
          );
          setValue(
            'scheduleSendAtHour',
            formatDateApi(message?.scheduleSendAt, 'YYYY-MM-DD HH:mm:ss Z'),
          );
        }

        setValue('membersTypes', message?.recurringParams?.membersTypes || []);
        setValue('customContacts', message?.customContactsData || []);
        setValue('membersTags', message?.recurringParams?.membersTags || []);
        setValue('membershipsTags', message?.recurringParams?.membershipsTags || []);
        setValue('competitionsUsers', message?.competitionsUsers || []);
        setValue('competitionsCategories', message?.recurringParams?.competitionsCategories || []);
        setValue('competitionsDivisions', message?.recurringParams?.competitionsDivisions || []);
        setValue('competitionId', message?.recurringParams?.competitionId);

        setValue('showCustom', message?.recurringParams?.customContacts?.length > 0);
        setValue('showCompetitions', !!message?.recurringParams?.competitionId);
      }, 300);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ownerableId, ownerableType, message]);

  const validate = (data) => {
    if (!data.method) {
      dispatch(notifications.warning(t('channel.message.validate.method')));
      return false;
    }

    if (ownerableType === 'Channel' && !ownerableId) {
      if (!data.replyTo && !data.streamWorldUsers && !data.streamUSUsers) {
        if (!data.checkedLists || (data.checkedLists && data.checkedLists.length === 0)) {
          dispatch(notifications.warning(t('admin.message.validate.lists')));
          return false;
        }
      }
    }

    if (data.method === 'sms' && data.text.length > 1500) {
      dispatch(notifications.warning(t('admin.message.validate.lists')));
      return false;
    }

    if (data?.kind === automatedMessageTypes.registration_lapse) {
      if (!data.days || data.days <= 0) {
        dispatch(notifications.warning(t('channel.message.validate.days')));
        return false;
      }
    }

    if (data.showCompetitions && !data.competitionId) {
      dispatch(notifications.warning(t('channel.message.validate.competition')));
      return false;
    }
    if (data.kind === 'recurring' && data.frequency && !data.recurringStartDate) {
      setError('recurringStartDate');
      return false;
    }
    return true;
  };

  const clearFields = () => {
    setValue('subject', '');
    setValue('text', '');
    setValue('textRaw', '');
    setValue('replyTo', null);
    setValue('videoLink', null);
    setValue('membersTypes', []);
    setValue('messageImage', null);
    setValue('showCustom', false);
    setValue('showCompetitions', false);
    setValue('customContacts', null);
    setValue('membersTags', []);
    setValue('membershipsTahs', []);
    setValue('competitionId', null);
    setValue('frequency', null);
    setValue('recurringStartDate', null);
    setValue('kind', automated ? 'recurring' : 'normal');

    setValue('firstVisitFrequency', 'day');
    setValue('firstVisitNumber', '');

    setValue('scheduleSend', false);
    setValue('scheduleSendAtHour', null);
    setValue('scheduleSendAtDate', null);

    setAttachments([]);

    throttledPersistence?.cancel?.();
    localStorage.removeItem(persistKey);
  };

  const autoSaveMessage = (data) => {
    save(data, true);
  };

  const saveMessage = (data) => {
    save(data);
  };

  const save = (data, auto = false) => {
    let scheduleSendAt = null;
    if (data.scheduleSend && data.scheduleSendAtHour && data.scheduleSendAtDate) {
      const dateStr = `${data.scheduleSendAtDate?.substr(0, 10)} ${data.scheduleSendAtHour?.substr(
        11,
        8,
      )} ${timeZoneName()}`;

      scheduleSendAt = formatDateToApi(dateStr, timeZoneName());
    }

    const dataForm = {
      ...data,
      customContacts: data.customContacts && data.customContacts.map((u) => u.id),
      competitionsUsers: data.competitionsUsers && data.competitionsUsers.map((u) => u?.id || u),
      membersTags: data.membersTags && data.membersTags.map((u) => u.name || u),
      membershipsTags: data.membershipsTags && data.membershipsTags.map((u) => u.name || u),
      attachments,
      scheduleSendAt,
      ownerableId,
      ownerableType,
      isTemplate: !!automated,
      recurringStartDate: formatDateToApi(data.recurringStartDate),
      id: message?.id,
    };

    if (!ownerableId) {
      dispatch(
        messagesActions.postMessagesStart(dataForm, () => {
          if (!auto) {
            if (callback) {
              callback();
            }
            clearFields();

            dispatch(notifications.success(t('admin.message.success')));
            dispatch(messagesActions.getMessagesStart());
          }
        }),
      );
      return;
    }

    request({
      action: message?.id
        ? Modules.ownerableMessages.actions.updateMessage
        : Modules.ownerableMessages.actions.postMessage,
      data: dataForm,
      options: {
        onSuccess: () => {
          if (auto) {
            return;
          }

          if (callback) {
            callback();
          }

          clearFields();
          dispatch(notifications.success(t('admin.message.success')));
          request({
            action: Modules.ownerableMessages.actions.getMessages,
            data: {
              ownerableId,
              ownerableType,
              template: !!automated,
            },
          });
        },
        onError: (error) => {
          if (error.indexOf('Limit') > -1) {
            setModalAlertPayment({ open: true });
          } else {
            dispatch(notifications.error(error));
          }
        },
      },
    });
  };

  const onSubmit = (data) => {
    if (!validate(data)) {
      return;
    }

    saveMessage(data);
  };

  const onSubmitWithPayment = (data) => {
    if (!validate(data)) {
      return;
    }

    saveMessage({ ...data, pay: true });
  };

  const removeContactList = (item) => {
    dispatch(
      messageListsActions.deleteContactListStart(item.messageListId, item.id, () => {
        dispatch(messageListsActions.getMessageListsStart());
      }),
    );
  };

  const copyMessage = (m) => {
    setValue('subject', m.subject);
    setValue('method', m.method);
    setValue('text', m.fullText);
    setValue('frequency', m.frequency);

    window.scrollTo({
      top: 50,
      behavior: 'smooth',
    });
  };

  const closeAndClearDraft = () => {
    if (!message) {
      confirmation({
        description: t('description.cancel.message'),
        yesClick: () => {
          if (close) {
            close();
          }
          removeData(persistKey);
        },
      });
    } else {
      close();
    }
  };

  return {
    onSubmit,
    setValue,
    watch,
    handleSubmit,
    errors,
    formElementRef,
    attachments,
    setAttachments,
    copyMessage,
    removeContactList,
    messagesTypes,
    currentMessageType,
    loadingSend: loadingSend || loadingNewSend,
    automated,
    modalAlertPayment,
    setModalAlertPayment,
    onSubmitWithPayment,
    closeAndClearDraft,
  };
};
export default useCommunicationForm;
