import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form-v5';
import { useDispatch } from 'react-redux';
import notifications from 'modules/notifications';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import useSelectors from 'modules/map/useSelectors';
import { channelsCustomPaymentsModule } from 'modules/channelsCustomPayments';
import { formatDateApi, formatDateToApi } from 'core/utils/formatters';
import { channelsCustomPaymentsUsersModule } from 'modules/channelsCustomPaymentsUsers';

export default ({ channel, coach, item, callback }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loading = useSelectors(channelsCustomPaymentsUsersModule, 'loading');
  const customPayments = useSelectors(channelsCustomPaymentsModule, 'data');
  const { request } = useActions();
  const { register, handleSubmit, setValue, watch, reset, errors } = useForm();

  useEffect(() => {
    if (channel && channel.id) {
      request({
        action: Modules.channelsCustomPayments.actions.getChannelsCustomPayments,
        data: { channelId: channel.id },
      });
    }
  }, [request, channel]);

  const onSubmit = (values) => {
    const data = {
      ...values,
      channelsCustomPaymentId: values?.channelsCustomPaymentId,
      id: item?.id || null,
      channelId: channel.id,
      userId: coach?.id,
      date: values?.date && formatDateToApi(values?.date),
    };

    request({
      action: !item?.id
        ? Modules.channelsCustomPaymentsUsers.actions.postChannelsCustomPaymentsUsers
        : Modules.channelsCustomPaymentsUsers.actions.putChannelsCustomPaymentsUsers,
      data,
      options: {
        onSuccess: () => {
          if (callback) {
            callback(values.name);
          }
          dispatch(notifications.success(t('message.success.save.customPayments')));
          request({
            action: Modules.channelsCustomPaymentsUsers.actions.getChannelsCustomPaymentsUsers,
            data: { channelId: channel.id, userId: coach?.id },
          });
        },
      },
    });
  };

  useEffect(() => {
    register('description', { required: true });
    register('date', { required: true });
    register('channelsCustomPaymentId', { required: false });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register]);

  useEffect(() => {
    if (item && item?.id) {
      reset({
        ...item,
        date: formatDateApi(item?.date),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, item?.id]);

  return {
    customPayments,
    setValue,
    watch,
    handleSubmit,
    onSubmit,
    errors,
    loading,
  };
};
