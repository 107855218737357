import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import propTypes from 'prop-types';
import { workoutsActions } from 'modules/workouts';
import Box from 'core/ui/Box';
import { ButtonPrimary } from 'core/ui/Button';
import Typography from 'core/ui/Typography';
import FormScore from 'components/Stream/SideBar/components/Sections/FormScore';
import { primary } from 'core/ui/Colors';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#ececec',
  },
}));

export default function ModalEditScores({
  section,
  workout,
  open,
  close,
  user,
  refresh,
  buttonColor,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    if (workout && workout.id) {
      dispatch(
        workoutsActions.getWorkoutSectionUserStart({
          workoutId: workout.id,
          userId: user.id,
        }),
      );
    }
  }, [workout, user, dispatch]);

  const refsSection = useRef();

  const saveAllSections = () => {
    refsSection.current.saveSection();
  };

  return (
    <Dialog
      PaperProps={{ style: { borderRadius: 5, minWidth: 300 } }}
      maxWidth="sm"
      fullWidth
      scroll="body"
      onClose={() => close()}
      open={open}
    >
      <Box p={5} className={classes.root}>
        <Box display="flex" justifyContent="center" flexDirection="column">
          <Box>
            <Box mt={2}>
              <Typography align="center" color="secondary" variant="h5">
                {section?.title?.toUpperCase()}
              </Typography>
              <Box>
                <FormScore
                  ref={refsSection}
                  sectionData={section}
                  user={user}
                  showSaveButton={false}
                  workout={workout}
                  saveCallback={() => {
                    if (refresh) {
                      refresh();
                    }
                    close();
                  }}
                />
              </Box>
            </Box>
          </Box>

          <Box display="flex" mt={5} justifyContent="center">
            <ButtonPrimary
              fullWidth
              buttonColor={buttonColor || primary}
              onClick={() => saveAllSections()}
            >
              {t('button.saveScores')}
            </ButtonPrimary>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
}

ModalEditScores.propTypes = {
  open: propTypes.bool,
  close: propTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  workout: propTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  user: propTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  section: propTypes.object.isRequired,
};

ModalEditScores.defaultProps = {
  open: false,
  close: null,
};
