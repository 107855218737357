import api from 'core/api';
import { call, put } from 'redux-saga/effects';

export const workoutsUsersModule = 'workoutsUsers';

const loadingStates = {
  start: { loading: true, registerStatus: null },
  error: { loading: false },
  success: { loading: false },
};

const actions = {
  getRegisterStatus: {
    module: workoutsUsersModule,
    name: 'getRegisterStatus',
    api: ({ workoutId, userId }) =>
      api.get(`/workouts_users/${workoutId}/register_status/${userId}`),
    params: {
      start: ['params'],
      error: [''],
      success: ['registerStatus'],
    },
    state: loadingStates,
    isTakeEvery: true,
  },
  clearRegisterStatus: {
    module: workoutsUsersModule,
    name: 'clearRegisterStatus',
    params: {
      start: ['params'],
      error: [''],
    },
    state: { success: { registerStatus: null } },
  },

  getUserWorkouts: {
    module: workoutsUsersModule,
    name: 'getUserWorkouts',
    api: (params) =>
      api.get(
        `/workouts_users/${params.userId}/${
          params?.upcoming ? 'upcoming_attendings' : 'recents_attendings'
        }`,
        { params },
      ),
    *sagas(Creators, { params, options }) {
      try {
        const resp = yield call(actions.getUserWorkouts.api, params);
        yield put(Creators.getUserWorkoutsSuccess(resp.data, resp.pagination));
        if (options.onSuccess) {
          options.onSuccess(resp.data, resp.pagination);
        }
      } catch (error) {
        if (options.onError) {
          options.onError(error);
        }
        yield put(Creators.getUserWorkoutsError());
      }
    },
    params: {
      start: ['params'],
      error: [''],
      success: ['workouts', 'paginationWorkouts'],
    },
    state: loadingStates,
  },
};

export default {
  actions,
  state: {
    loading: false,
    registerStatus: null,

    workouts: [],
    paginationWorkouts: null,
  },
};
