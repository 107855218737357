/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Paper from 'core/ui/Paper';
import { useTranslation } from 'react-i18next';
import { date } from 'core/utils/formatters';
import Typography, { fontStyle } from 'core/ui/Typography';
import SFDataTable from 'core/ui/DataTable';
import MinimizeIcon from '@material-ui/icons/Minimize';
import AddIcon from '@material-ui/icons/Add';
import { primary, secondary, successColor } from 'core/ui/Colors';
import Box from 'core/ui/Box';
import { ButtonPrimarySm } from 'core/ui/Button';
import { integrationTypes } from 'core/utils/consts';
import ModalChannelPaymentFess from './FeesForm/Modal';
import ModalFormEditChannelInfo from './ChannelEditInfo/Modal';

const MinusIcon = () => <MinimizeIcon style={{ color: primary, fontSize: 10 }} />;
const PlusIcon = () => <AddIcon style={{ color: successColor, fontSize: 10 }} />;

export default function ResultChannels({ channels, search, pagination, perPage, setPerPage }) {
  const { t } = useTranslation();
  const [modalFees, setModalFees] = useState({ open: false });
  const [modalEdit, setModalEdit] = useState({ open: false });

  const columns = [
    {
      name: 'Channel',
      selector: 'channelName',
      sortable: false,
      cell: (row) => (
        <Typography style={fontStyle.small}>
          {row?.channel?.id} - {row.channel?.name}
        </Typography>
      ),
    },
    {
      name: 'Gateway',
      selector: 'gateway',
      sortable: false,
      cell: (row) => (
        <Box display="flex" alignItems="center" justifyContent="center">
          {!!row?.channel.useCardpointe && (
            <img
              alt=""
              src={integrationTypes.find((x) => x.id === 'cardpointe').img}
              style={{ width: 14, height: 14, borderRadius: 10, marginRight: 5 }}
            />
          )}
          {!row?.channel.useCardpointe && !!row?.channel.hasStripe && (
            <img
              alt=""
              src={integrationTypes.find((x) => x.id === 'stripe').img}
              style={{ width: 14, height: 14, borderRadius: 10, marginRight: 5 }}
            />
          )}
        </Box>
      ),
    },
    {
      name: 'Annual',
      selector: 'annualRenewal',
      cell: (row) => (
        <Typography style={fontStyle.small}>{row?.channel.annualRenewal ? 'YES' : 'NO'}</Typography>
      ),
      sortable: false,
      center: true,
    },
    {
      name: 'Renew',
      selector: 'renewAt',
      cell: (row) => <Typography style={fontStyle.small}>{date(row?.channel.renewAt)}</Typography>,
      sortable: false,
      center: true,
    },
    {
      name: (
        <>
          Plan&nbsp;
          <MinusIcon />
        </>
      ),
      selector: 'planAmount',
      sortable: false,
      cell: (row) => (
        <Typography style={fontStyle.small}>
          {t('prefix')}
          {row.planAmount}
        </Typography>
      ),
    },
    {
      name: (
        <>
          Memberships&nbsp;
          <PlusIcon />
        </>
      ),
      selector: 'memberships',
      sortable: false,
      cell: (row) => <Typography style={fontStyle.small}>{row.memberships}</Typography>,
    },
    {
      name: (
        <>
          Competitions&nbsp;
          <PlusIcon />
        </>
      ),
      selector: 'competitions',
      sortable: false,
      cell: (row) => (
        <Typography style={fontStyle.small}>
          {t('prefix')}
          {row.competitions}
        </Typography>
      ),
    },
    {
      name: 'Amount',
      selector: 'amount',
      sortable: false,
      right: true,
      cell: (row) => (
        <Typography style={{ ...fontStyle.small, color: row.amount < 0 ? 'red' : 'green' }}>
          {t('prefix')}
          {row.currentTotal}
        </Typography>
      ),
    },

    {
      name: '',
      selector: 'annualRenewal',
      width: '200px',
      cell: (row) => (
        <Box display="flex" alignItems="center" justifyContent="center">
          <Box>
            <ButtonPrimarySm
              buttonColor={row?.channel?.ownersPaymentsFee ? primary : secondary}
              onClick={() => setModalEdit({ open: true, channel: row?.channel })}
            >
              <Typography style={fontStyle.small}>Edit Info</Typography>
            </ButtonPrimarySm>
          </Box>
          <Box ml={1}>
            <ButtonPrimarySm
              buttonColor={row?.channel?.ownersPaymentsFee ? primary : secondary}
              onClick={() => setModalFees({ open: true, channel: row?.channel })}
            >
              <Typography style={fontStyle.small}>Cardpointe Fees</Typography>
            </ButtonPrimarySm>
          </Box>
        </Box>
      ),
      sortable: false,
      center: true,
    },
  ];

  return (
    <Paper style={{ width: '100%' }}>
      <ModalChannelPaymentFess
        open={modalFees?.open}
        channel={modalFees?.channel}
        close={() => setModalFees({ open: false })}
        callback={() => {
          search(1);
          setModalFees({ open: false });
        }}
      />
      <ModalFormEditChannelInfo
        open={modalEdit?.open}
        channel={modalEdit?.channel}
        close={() => setModalEdit({ open: false })}
        callback={() => {
          search(1);
          setModalEdit({ open: false });
        }}
      />
      <SFDataTable
        title={t('channel.reports.overview')}
        columns={columns}
        data={channels}
        pagination
        noHeader
        paginationPerPage={perPage}
        onChangePage={(p) => search(p)}
        paginationServer
        paginationDefaultPage={pagination?.currentPage}
        paginationTotalRows={pagination?.totalCount}
        onChangeRowsPerPage={(newValue) => {
          setPerPage(newValue);
        }}
      />
    </Paper>
  );
}
