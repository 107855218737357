import React from 'react';

import Paper from 'core/ui/Paper';
import Box from 'core/ui/Box';
import { ButtonPrimary } from 'core/ui/Button';
import { useTranslation } from 'react-i18next';
import { channelCurrency } from 'core/utils/formatters';
import { Check, Close } from '@material-ui/icons';
import { Chip, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import { redColor, successColor } from 'core/ui/Colors';
import Typography from 'core/ui/Typography';
import PurchaseResumeItem from './PurchaseResumeItem';

export default ({ shoppingCart, buttonColor }) => {
  const { t } = useTranslation();
  return (
    <Box m={2}>
      {shoppingCart?.purchaseResume?.payments.map((p, index) => (
        <Paper className="paper-rounded" p={3} key={index} m={2}>
          <Table>
            <TableBody>
              <>
                <TableRow>
                  <TableCell>
                    <Chip
                      style={{ backgroundColor: p?.payment?.error ? redColor : successColor }}
                      icon={
                        p?.payment?.error ? (
                          <Close style={{ color: 'white' }} />
                        ) : (
                          <Check style={{ color: 'white' }} />
                        )
                      }
                      label={
                        <Typography style={{ color: 'white' }}>
                          {p?.payment?.status?.toUpperCase()}
                        </Typography>
                      }
                    />
                  </TableCell>
                  <TableCell>{channelCurrency(p?.payment?.amount)}</TableCell>
                  <TableCell>{p?.payment?.error || t('channel.store.product.success')}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={3}>
                    <Table>
                      <TableBody>
                        {shoppingCart?.purchaseResume?.cartItems
                          .filter(
                            (x) => !!p?.items?.find((y) => Number(y) === Number(x?.option?.id)),
                          )
                          .map((item) => (
                            <PurchaseResumeItem
                              key={item?.option?.id}
                              buttonColor={buttonColor}
                              cartItem={item}
                              shoppingCart={shoppingCart}
                              previewOrder={shoppingCart?.purchaseResume?.previewOrder}
                            />
                          ))}
                      </TableBody>
                    </Table>
                  </TableCell>
                </TableRow>
              </>
            </TableBody>
          </Table>
        </Paper>
      ))}

      {shoppingCart?.purchaseResume?.payments?.find((x) => x.error) && (
        <Box display="flex" justifyContent="center" m={2}>
          <ButtonPrimary
            buttonColor={buttonColor}
            onClick={() => shoppingCart?.setPurchaseResume(null)}
          >
            {t('button.tryAgain')}
          </ButtonPrimary>
        </Box>
      )}
    </Box>
  );
};
