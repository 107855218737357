import React from 'react';
import Box from 'core/ui/Box';
import { Droppable } from 'react-beautiful-dnd';
import uuid from 'react-uuid';
import SFDataTable from 'core/ui/DataTable';
import ItemTeam from './ItemTeam';

const ListCompTeams = ({ leaderboard, compUsers, workout }) => {
  const totalLen = leaderboard && leaderboard.length;

  const columns = [
    {
      width: '100%',
      sortable: false,
      style: { padding: 0 },
      cell: (item, index) => {
        const board = leaderboard.find((x) => x.user.id === item.user.id);

        return (
          <div className="tw-flex-1">
            <ItemTeam
              item={item}
              workout={workout}
              totalLen={totalLen}
              index={index}
              leaderboard={board || {}}
              key={uuid()}
              dropable
              allTeams
            />
          </div>
        );
      },
    },
  ];

  return (
    <div className="tw-border tw-border-gray-200">
      <Droppable droppableId="TEAMS-ALL">
        {(provided) => (
          <Box ref={provided.innerRef}>
            <SFDataTable
              columns={columns}
              data={compUsers}
              pagination
              showPaper={false}
              noTableHead
              paginationPerPage={15}
              noHeader
              searchField={(row) => row?.displayName}
              showSearch
            />

            {/* {compUsers &&
              compUsers.map((item, index) => {
                const board = leaderboard.find((x) => x.user.id === item.user.id);
                return (
                  <ItemTeam
                    item={item}
                    workout={workout}
                    totalLen={totalLen}
                    index={index}
                    leaderboard={board || {}}
                    key={uuid()}
                    dropable
                    allTeams
                  />
                );
              })} */}

            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </div>
  );
};

export default ListCompTeams;
