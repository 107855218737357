import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

import * as selectors from './selectors';
import * as sagas from './sagas';

/* ---------- Action Creators and Types ---------- */
const { Types, Creators } = createActions(
  {
    getBannersError: ['error'],
    getBannersStart: [],
    getBannersSuccess: ['banners'],

    getBannersHeaderError: ['error'],
    getBannersHeaderStart: [],
    getBannersHeaderSuccess: ['banners'],
  },
  { prefix: 'banners/' },
);

/* ---------- Initial State ---------- */
const INITIAL_STATE = Immutable({
  data: [],
  dataHeader: [],
  error: null,
  loading: false,
});

/* ---------- Reducers ---------- */
const loadStartReducer = (state) => state.set('loading', true);

const getBannersSuccessReducer = (state, action) =>
  state.merge({
    data: action.banners,
    loading: false,
    error: null,
  });

const getBannersHeaderSuccessReducer = (state, action) =>
  state.merge({
    dataHeader: action.banners,
    loading: false,
    error: null,
  });

const loadErrorReducer = (state, action) =>
  state.merge({
    loading: false,
    error: action.error,
  });

/* ---------- Main Reducer ---------- */
const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_BANNERS_START]: loadStartReducer,
  [Types.GET_BANNERS_SUCCESS]: getBannersSuccessReducer,
  [Types.GET_BANNERS_ERROR]: loadErrorReducer,
  [Types.GET_BANNERS_HEADER_START]: loadStartReducer,
  [Types.GET_BANNERS_HEADER_SUCCESS]: getBannersHeaderSuccessReducer,
  [Types.GET_BANNERS_HEADER_ERROR]: loadErrorReducer,
});

/* ---------- Exporting ---------- */
export const bannersActions = Creators;
export const bannersTypes = Types;
export const bannersSelectors = selectors;
export const bannersSagas = sagas;

export default reducer;
