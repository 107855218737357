import api from 'core/api';

export const usersWorkoutsModule = 'usersWorkouts';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const loadingWorkoutsStates = {
  start: { loadingWorkouts: true },
  error: { loadingWorkouts: false },
  success: { loadingWorkouts: false },
};

const actions = {
  getCalendarDots: {
    module: usersWorkoutsModule,
    name: 'getCalendarDots',
    api: (data) => api.get(`/users/calendar/dots`, { params: data }),
    params: {
      start: ['params'],
      error: [''],
      success: ['dots'],
    },
    state: loadingStates,
  },
  getCalendarGroupWorkouts: {
    module: usersWorkoutsModule,
    name: 'getCalendarGroupWorkouts',
    api: (data) => api.get(`/users/calendar/workouts_by_day`, { params: data }),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingWorkoutsStates,
  },
};

const usersWorkoutsModuleObj = {
  actions,
  state: {
    loading: false,
    loadingWorkouts: false,
    data: [],
    dots: {},
  },
};

export default usersWorkoutsModuleObj;
