/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import noImage from 'assets/img/no-image-product.png';
import Box from 'core/ui/Box';
import Carousel from 'react-material-ui-carousel';
import ImageViewer from 'react-simple-image-viewer';

const ImageProduct = ({ first, images, size, indicators }) => {
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  return (
    <>
      {first || images?.length <= 1 ? (
        <Box onClick={() => setIsViewerOpen({ open: true, currentIndex: 0 })} className="hover">
          <img
            className="boxImg"
            draggable="false"
            src={images[0]?.image ? images[0]?.image.url : noImage}
            style={{ width: size }}
            alt=""
          />
        </Box>
      ) : (
        <Box>
          <Carousel autoPlay={false} indicators={indicators} navButtonsAlwaysInvisible>
            {images?.map((file, index) => (
              // eslint-disable-next-line jsx-a11y/no-static-element-interactions
              <div
                key={index.toString()}
                style={{ width: '100%' }}
                className="hover"
                onClick={() => setIsViewerOpen({ open: true, currentIndex: index })}
              >
                <img src={file.image ? file.image.url : noImage} alt="" style={{ width: size }} />
              </div>
            ))}
          </Carousel>
        </Box>
      )}

      {isViewerOpen?.open && (
        <ImageViewer
          src={images?.map((x) => x?.image?.url)}
          currentIndex={isViewerOpen?.currentIndex}
          onClose={() => setIsViewerOpen({ open: false })}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: 'rgba(0,0,0,0.9)',
          }}
          closeOnClickOutside
        />
      )}
    </>
  );
};

ImageProduct.defaultProps = {
  size: 170,
  image: {},
  indicators: true,
};

export default ImageProduct;
