import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import Typography from 'core/ui/Typography';
import Checkbox from 'core/ui/Checkbox';
import DatePickerRounded from 'core/ui/DatePickers/DatePickerRounded';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import CardPlan from 'components/Channel/Show/Tabs/Memberships/CardPlan';
import Paper from 'core/ui/Paper';
import { HintIcon } from 'components/Channel/Manage/Tabs/Memberships/HintIcon';

const FormNewChannelKey = ({ channel, setCurrentKey, currentKey, currentSelected }) => {
  const { t } = useTranslation();

  return (
    <>
      <Box mb={3}>
        <SelectBoxObject
          label={t('channel.label.plan')}
          options={
            channel &&
            channel.membershipAllPlans &&
            channel.membershipAllPlans
              .filter((x) => x.active === true)
              .map((x) => ({ id: x.id, name: x.name }))
          }
          propLabel="name"
          propValue="id"
          setValue={(v) =>
            setCurrentKey((prev) => ({
              ...prev,
              channelMembershipPlanId: v,
              channelMembershipCouponId: null,
              code: null,
              chargeGymAmount: null,
            }))
          }
          value={currentKey.channelMembershipPlanId || null}
        />
      </Box>
      {currentSelected && (
        <Box>
          <CardPlan channel={channel} plan={currentSelected} />
        </Box>
      )}
      {currentSelected && currentSelected.channelMembershipPlanEnroll && (
        <Paper mb={3} p={2} className="tw-flex tw-justify-between">
          <Typography>
            <b>{t('Enroll membership')}</b>:{' '}
            <span>{currentSelected.channelMembershipPlanEnroll.name}</span>
          </Typography>

          <HintIcon title={t('channel.membership.enroll.hint')} />
        </Paper>
      )}
      {currentSelected && currentSelected?.coupons && currentSelected.coupons.length > 0 && (
        <Box mb={3}>
          <SelectBoxObject
            label={t('channel.label.coupon')}
            placeholder={t('channel.key.placeholder.coupon')}
            emptyItem
            emptyValue={null}
            options={
              currentSelected && currentSelected.coupons.map((x) => ({ id: x.id, code: x.code }))
            }
            propLabel="code"
            propValue="id"
            setValue={(v) =>
              setCurrentKey((prev) => ({
                ...prev,
                channelMembershipCouponId: v,
                chargeGymAmount: null,
              }))
            }
            value={currentKey.channelMembershipCouponId || null}
          />
        </Box>
      )}
      {(!currentSelected?.renewable || currentSelected?.customIncrement === 'limit0') &&
        currentSelected?.limitUse > 0 && (
          <Box mb={3}>
            <TextFieldRounded
              type="number"
              value={currentKey.limitClass}
              pattern="[0-9]*"
              placeholder={t('channel.placeholder.limitClass')}
              label={`${t('channel.label.limitClass')}`}
              onChange={(v) => setCurrentKey((prev) => ({ ...prev, limitClass: v >= 0 ? v : 0 }))}
            />
          </Box>
        )}

      <Box mb={3} display="flex" alignItems="center">
        <Box display="flex">
          <Box mr={3}>
            <Checkbox
              label={t('channel.label.startNow')}
              checked={!currentKey.startAt}
              onClick={() => setCurrentKey((prev) => ({ ...prev, startAt: null }))}
            />
          </Box>
        </Box>
        <Typography mx={4} color="primary" variant="h4">
          {t('or')}
        </Typography>
        <Box ml={3}>
          <DatePickerRounded
            label={t('channel.label.plan.startDate')}
            placeholder="Today"
            usFormat={channel?.useUsDateFormat}
            value={currentKey.startAt}
            disablePast={false}
            onChange={(v) => setCurrentKey((prev) => ({ ...prev, startAt: v }))}
            bordered
          />
        </Box>
      </Box>
    </>
  );
};

export default FormNewChannelKey;
