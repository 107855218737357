import React, { useEffect, useState } from 'react';
import Box from 'core/ui/Box';
import { channelReportsModule } from 'modules/channelReports';
import useSelectors from 'modules/map/useSelectors';
import { useSelector } from 'react-redux';
import { channelsSelectors } from 'modules/channels';
import Modules from 'modules';
import useActions from 'modules/map/useActions';
import { exportCsv } from 'core/utils/helpers';
import { ButtonPrimary } from 'core/ui/Button';
import { useTranslation } from 'react-i18next';
import ChoosenChannelTags from 'core/ui/ChoosenChannelTags';
import Grid from 'core/ui/Grid';
import ActiveMembershipsResume from './ActiveMembershipsResume';
import SkeletonMembershipReport from './Skeleton';

// eslint-disable-next-line react/prop-types
export default function MembershipReport({ loading, getReport }) {
  const { t } = useTranslation();
  const channel = useSelector((state) => channelsSelectors.getChannel(state));
  const data = useSelectors(channelReportsModule, 'data');
  const [filters, setFilters] = useState({ membersTags: [], membershipsTags: [] });

  const { request } = useActions();
  useEffect(() => {
    getReport({ ...filters });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const exportReport = () => {
    request({
      action: Modules.channelReports.actions.exportChannelMemberships,
      data: {
        ...filters,
        channelId: channel?.id,
        export: true,
      },
      options: {
        onSuccess: (resp) => {
          exportCsv(resp?.data, `${channel?.name}_memberships`);
        },
      },
    });
  };

  if (!data.activeMembers || loading) {
    return <SkeletonMembershipReport />;
  }

  return (
    <Box>
      <Box display="flex" m={2} alignItems="flex-end">
        <Box flexGrow={1}>
          <Grid container spacing={5}>
            <Grid item xs={12} md={4}>
              <ChoosenChannelTags
                label={t('channel.label.memberTags')}
                placeholder={t('channel.tags')}
                val={filters?.membersTags ? [...filters?.membersTags] : []}
                setValue={(v) =>
                  setFilters((prev) => ({ ...prev, membersTags: v?.map((x) => x?.name || x) }))
                }
                channelId={channel?.id}
                bordered
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <ChoosenChannelTags
                label={t('channel.label.membershipsTags')}
                placeholder={t('channel.tags')}
                val={filters?.membershipsTags ? [...filters?.membershipsTags] : []}
                setValue={(v) =>
                  setFilters((prev) => ({ ...prev, membershipsTags: v?.map((x) => x?.name || x) }))
                }
                channelId={channel?.id}
                bordered
              />
            </Grid>
          </Grid>
        </Box>
        <Box ml={3}>
          <ButtonPrimary onClick={() => exportReport()}>{t('button.export')}</ButtonPrimary>
        </Box>
      </Box>

      <Box mt={5}>
        <ActiveMembershipsResume channel={channel} members={data.activeMembers} />
      </Box>
    </Box>
  );
}
