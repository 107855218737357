import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import Radio from 'core/ui/Radio';
import Typography, { fontStyle } from 'core/ui/Typography';

const PayCoachCost = ({ watch, setValue }) => {
  const { t } = useTranslation();

  return (
    <Box display="flex" alignItems="center" my={3} px={2}>
      <Box mr={2}>
        <Typography style={fontStyle.label} mb={1} noWrap>
          {t('workout.payCoach').toUpperCase()}
        </Typography>
        <Box display="flex" alignItems="center">
          <Radio
            checked={watch('payCoach') === true}
            label={t('button.yes')}
            onClick={() => setValue('payCoach', true)}
          />
          <Box ml={3}>
            <Radio
              checked={!watch('payCoach')}
              label={t('button.no')}
              onClick={() => setValue('payCoach', false)}
            />
          </Box>
        </Box>
      </Box>
      {watch('payCoach') && (
        <Box m={1} mt={2}>
          <Typography style={fontStyle.label} mb={1}>
            {t('workout.payCoachAmount').toUpperCase()}
          </Typography>
          <Box display="flex" alignItems="center">
            <Box>
              <TextFieldRounded
                onChange={(v) => setValue('payCoachAmount', v)}
                value={watch('payCoachAmount')}
                type="number"
                pattern="[0-9]*"
                currency
              />
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default PayCoachCost;

PayCoachCost.propTypes = {};

PayCoachCost.defaultProps = {};
