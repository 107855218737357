import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import DatePickerRounded from 'core/ui/DatePickers/DatePickerRounded';
import { ButtonPrimarySm } from 'core/ui/Button';
import { Search } from '@material-ui/icons';
import { formatDateToApi } from 'core/utils/formatters';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import useSelectors from 'modules/map/useSelectors';
import { adminReportsModule } from 'modules/adminReports';
import ResultPayouts from './ResultPayouts';

const PaymentsReport = () => {
  const { t } = useTranslation();
  const { request } = useActions();
  const loading = useSelectors(adminReportsModule, 'loading');
  const data = useSelectors(adminReportsModule, 'data');
  const dateNow = new Date();
  const [filters, setFilters] = useState({
    startAt: `${dateNow.getMonth() + 1}/1/${dateNow.getFullYear()}`,
    endAt: `${dateNow.getMonth() + 1}/${dateNow.getDate()}/${dateNow.getFullYear()}`,
    workoutId: '',
  });

  const getReport = (params = {}) => {
    request({
      action: Modules.adminReports.actions.getPayoutReport,
      data: params,
    });
  };

  const search = () => {
    const startAt = filters.startAt ? formatDateToApi(filters.startAt) : null;
    const endAt = filters.endAt ? formatDateToApi(filters.endAt) : null;
    if (startAt && endAt) getReport({ startAt, endAt });
  };

  const setPayoutTransfer = (transferId) => {
    request({
      action: Modules.adminReports.actions.setPayoutTransfer,
      data: { transferId },
      options: {
        onSuccess: () => {
          search();
        },
      },
    });
  };

  useEffect(() => {
    search();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box pb={5} pt={2}>
      <Box>
        <Box display="flex" mb={3} alignItems="flex-end" justifyContent="center" flexWrap="wrap">
          <Box m={1}>
            <DatePickerRounded
              label={t('channel.reports.filter.start').toUpperCase()}
              onChange={(v) => setFilters((prev) => ({ ...prev, startAt: v }))}
              value={filters.startAt}
              disablePast={false}
              autoOk
            />
          </Box>
          <Box m={1}>
            <DatePickerRounded
              label={t('channel.reports.filter.end').toUpperCase()}
              onChange={(v) => setFilters((prev) => ({ ...prev, endAt: v }))}
              value={filters.endAt}
              disablePast={false}
              autoOk
            />
          </Box>
          <Box m={1} ml={3}>
            <ButtonPrimarySm disabled={loading} onClick={() => search()}>
              <Search style={{ color: 'white' }} fontSize="small" />
              {t('button.search')}&nbsp;
            </ButtonPrimarySm>
          </Box>
        </Box>

        <Box>
          {data.transfers && (
            <ResultPayouts
              transfers={data ? data.transfers : []}
              setPayoutTransfer={setPayoutTransfer}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

PaymentsReport.propTypes = {};

PaymentsReport.defaultProps = {};

export default PaymentsReport;
