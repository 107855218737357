import { makeStyles } from '@material-ui/core';

const useStylesAthletes = makeStyles(() => ({
  boxChat: {},
  buttonLeft: {
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20,
    padding: 8,
    color: 'white',
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.9,
    },
  },
  buttonRight: {
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
    padding: 8,
    color: 'white',
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.9,
    },
  },
}));

export default useStylesAthletes;
