import React from 'react';
import Box from 'core/ui/Box';
import Typography, { fontStyle } from 'core/ui/Typography';
import { capitalizeFirstLetter, showScore } from 'core/utils/helpers';
import { ordinalNumber } from 'core/utils/formatters/date';
import { secondary } from 'core/ui/Colors';
import { Tooltip } from '@material-ui/core';
import { Note } from '@material-ui/icons';
import LabelBreakLine from 'core/ui/LabelBreakLine';
import { useTranslation } from 'react-i18next';
import LiftComplexScore from './LiftComplexScore';

export const ItemScore = ({
  sectionScore,
  workoutScores,
  score,
  fontColor,
  fontValue,
  showNotes,
  showFullNotes,
  hideName,
}) => {
  const { t } = useTranslation();

  if (!workoutScores) {
    return <></>;
  }

  return (
    <Box>
      <>
        {(() => {
          switch (score) {
            case 'roundReps':
              return (
                <Box>
                  <Typography
                    align="center"
                    style={{ color: fontColor || secondary, ...fontValue }}
                    color="secondary"
                    className="roboto-regular"
                  >
                    Round+Reps&nbsp;({workoutScores.roundRepsRounds}
                    &nbsp;+&nbsp;
                    {workoutScores.roundRepsReps})
                  </Typography>
                </Box>
              );
            case 'liftComplex':
              return (
                <LiftComplexScore
                  sectionScore={sectionScore}
                  fontColor={fontColor}
                  fontValue={fontValue}
                  hideName={hideName}
                  score={score}
                  workoutScores={workoutScores}
                />
              );

            default:
              return (
                <>
                  {!!showScore(workoutScores[score], score) && (
                    <Typography
                      style={{ color: fontColor || secondary, ...fontValue }}
                      color="secondary"
                      className="roboto-regular"
                    >
                      <b>{score.indexOf('tieBreak') > -1 && 'Tie Break '}</b>
                      {!hideName && (
                        <>
                          {t(
                            `workout.section.label.score.${
                              score.indexOf('tieBreak') > -1
                                ? `${score.replace('tieBreak', '')}`
                                : score
                            }`,
                          )}
                        </>
                      )}
                      ({showScore(workoutScores[score], score)}
                      {showScore(workoutScores[`type${capitalizeFirstLetter(score)}`])
                        ? ` ${showScore(workoutScores[`type${capitalizeFirstLetter(score)}`])}`
                        : ''}
                      )
                    </Typography>
                  )}
                </>
              );
          }
        })()}

        {showFullNotes && !!workoutScores[`${score}Notes`] && (
          <Typography style={{ ...fontValue, color: secondary, fontSize: 10 }}>
            {workoutScores[`${score}Notes`]}
          </Typography>
        )}

        {!showFullNotes && showNotes && workoutScores[`${score}Notes`] && (
          <Box ml={1} pt={1}>
            <Tooltip title={<LabelBreakLine>{workoutScores[`${score}Notes`]}</LabelBreakLine>}>
              <Note style={{ fontSize: 18 }} />
            </Tooltip>
          </Box>
        )}
      </>
    </Box>
  );
};

const WorkoutScores = ({
  position,
  workoutScores,
  sectionScore,
  fontColor,
  fontValue,
  fontScore,
  showNotes,
  showFullNotes,
}) => {
  const scores = Object.keys(workoutScores || {}).filter(
    (x) =>
      x !== 'timeCapped' &&
      x !== 'timeCapDescription' &&
      x !== 'personalRecord' &&
      x !== 'variationName' &&
      x !== 'variation' &&
      x !== 'isPrivate' &&
      x !== 'name' &&
      x !== 'completion' &&
      x !== 'judgeId' &&
      x !== 'judgeName' &&
      x.indexOf('Notes') === -1 &&
      x.indexOf('type') === -1,
  );

  const hasRoundReps = !!scores.find((x) => x.indexOf('roundReps') > -1);

  return (
    <Box
      display="flex"
      flexDirection={showFullNotes ? 'column' : 'row'}
      alignItems={showFullNotes ? 'flex-start' : 'center'}
      justifyContent={showFullNotes ? 'flex-start' : 'center'}
      flexWrap="wrap"
      className="gap-5"
    >
      {position && (
        <Typography align="center" style={fontScore}>
          {ordinalNumber(position)}
        </Typography>
      )}
      {scores
        .filter((x) => x.indexOf('roundReps') === -1)
        .filter((x) => x.indexOf('tieBreak') === -1)
        .map((score, index) => (
          <ItemScore
            key={index.toString()}
            workoutScores={workoutScores}
            score={score}
            fontColor={fontColor}
            fontValue={fontValue}
            showNotes={showNotes}
            showFullNotes={showFullNotes}
            sectionScore={sectionScore}
          />
        ))}
      {scores
        .filter((x) => x.indexOf('tieBreak') > -1)
        .map((score, index) => (
          <ItemScore
            key={index.toString()}
            workoutScores={workoutScores}
            score={score}
            fontColor={fontColor}
            fontValue={fontValue}
            showNotes={showNotes}
            showFullNotes={showFullNotes}
            sectionScore={sectionScore}
          />
        ))}
      {hasRoundReps && (
        <ItemScore
          workoutScores={workoutScores}
          score="roundReps"
          fontColor={fontColor}
          fontValue={fontValue}
          showNotes={showNotes}
          showFullNotes={showFullNotes}
          sectionScore={sectionScore}
        />
      )}
    </Box>
  );
};

WorkoutScores.defaultProps = {
  fontValue: fontStyle.medium,
  fontScore: fontStyle.medium,
};

export default WorkoutScores;
