/* eslint-disable jsx-a11y/label-has-associated-control */

import React from 'react';
import { useWatch } from 'react-hook-form';
import { CircularProgress } from '@material-ui/core';

import useSelectors from 'modules/map/useSelectors';
import { adminChannelsModule } from 'modules/admin/channels';

export const auditedModels = [
  'ChannelKey',
  'Channel',
  'ChannelMembershipPlan',
  'ChannelMembershipCoupon',
  'Workout',
  'WorkoutsUser',
  'PartnersProgramsChannel',
  'User',
  'Payment',
];

export const Form = ({ index, handleSearch, control, register }) => {
  const skipDate = useWatch({ control, name: `tabs.${index}.skipDate` });

  return (
    <form
      onSubmit={handleSearch}
      className="tw-mx-auto tw-my-2 tw-flex tw-w-full tw-max-w-5xl tw-flex-col tw-gap-1.5 tw-rounded-md tw-bg-white tw-p-4 tw-shadow"
    >
      <div className="tw-flex tw-gap-2">
        <label>
          <span className="block text-sm font-medium text-slate-700">Auditable Type</span>

          <select
            required
            className="tw-mt-1 tw-block tw-w-full tw-rounded-md tw-border tw-border-solid tw-border-slate-300 tw-bg-white tw-px-3 tw-py-2 tw-text-sm tw-shadow-sm placeholder:tw-text-slate-400 invalid:tw-border-pink-500 invalid:tw-text-pink-600 focus:tw-border-sky-500 focus:tw-outline-none focus:tw-ring-1 focus:tw-ring-sky-500 focus:invalid:tw-border-pink-500 focus:invalid:tw-ring-pink-500 disabled:tw-border-slate-200 disabled:tw-bg-slate-50 disabled:tw-text-slate-500 disabled:tw-shadow-none"
            {...register(`tabs.${index}.auditableType`)}
          >
            {auditedModels.map((model) => (
              <option key={model} value={model}>
                {model}
              </option>
            ))}
          </select>
        </label>

        <label>
          <span className="block text-sm font-medium text-slate-700">Auditable Id</span>

          <input
            required
            type="number"
            min={1}
            className="tw-mt-1 tw-block tw-w-full tw-rounded-md tw-border tw-border-solid tw-border-slate-300 tw-bg-white tw-px-3 tw-py-2 tw-text-sm tw-shadow-sm placeholder:tw-text-slate-400 invalid:tw-border-pink-500 invalid:tw-text-pink-600 focus:tw-border-sky-500 focus:tw-outline-none focus:tw-ring-1 focus:tw-ring-sky-500 focus:invalid:tw-border-pink-500 focus:invalid:tw-ring-pink-500 disabled:tw-border-slate-200 disabled:tw-bg-slate-50 disabled:tw-text-slate-500 disabled:tw-shadow-none"
            {...register(`tabs.${index}.auditableId`)}
          />
        </label>

        <label>
          <span className="block text-sm font-medium text-slate-700">User Id</span>

          <input
            type="number"
            min={1}
            className="tw-mt-1 tw-block tw-w-full tw-rounded-md tw-border tw-border-solid tw-border-slate-300 tw-bg-white tw-px-3 tw-py-2 tw-text-sm tw-shadow-sm placeholder:tw-text-slate-400 invalid:tw-border-pink-500 invalid:tw-text-pink-600 focus:tw-border-sky-500 focus:tw-outline-none focus:tw-ring-1 focus:tw-ring-sky-500 focus:invalid:tw-border-pink-500 focus:invalid:tw-ring-pink-500 disabled:tw-border-slate-200 disabled:tw-bg-slate-50 disabled:tw-text-slate-500 disabled:tw-shadow-none"
            {...register(`tabs.${index}.userId`)}
          />
        </label>
      </div>

      <div className="tw-flex tw-items-center tw-gap-2">
        <label>
          <span className="block text-sm font-medium text-slate-700">Start Date</span>

          <input
            type="date"
            disabled={skipDate}
            required={!skipDate}
            className="tw-mt-1 tw-block tw-w-full tw-rounded-md tw-border tw-border-solid tw-border-slate-300 tw-bg-white tw-px-3 tw-py-2 tw-text-sm tw-shadow-sm placeholder:tw-text-slate-400 invalid:tw-border-pink-500 invalid:tw-text-pink-600 focus:tw-border-sky-500 focus:tw-outline-none focus:tw-ring-1 focus:tw-ring-sky-500 focus:invalid:tw-border-pink-500 focus:invalid:tw-ring-pink-500 disabled:tw-border-slate-200 disabled:tw-bg-slate-50 disabled:tw-text-slate-500 disabled:tw-shadow-none"
            {...register(`tabs.${index}.startAt`)}
          />
        </label>

        <label>
          <span className="block text-sm font-medium text-slate-700">Start Date</span>

          <input
            type="date"
            disabled={skipDate}
            required={!skipDate}
            className="tw-mt-1 tw-block tw-w-full tw-rounded-md tw-border tw-border-solid tw-border-slate-300 tw-bg-white tw-px-3 tw-py-2 tw-text-sm tw-shadow-sm placeholder:tw-text-slate-400 invalid:tw-border-pink-500 invalid:tw-text-pink-600 focus:tw-border-sky-500 focus:tw-outline-none focus:tw-ring-1 focus:tw-ring-sky-500 focus:invalid:tw-border-pink-500 focus:invalid:tw-ring-pink-500 disabled:tw-border-slate-200 disabled:tw-bg-slate-50 disabled:tw-text-slate-500 disabled:tw-shadow-none"
            {...register(`tabs.${index}.endAt`)}
          />
        </label>

        <label className="tw-flex tw-items-center">
          <input
            type="checkbox"
            className="tw-size-3.5 tw-appearance-none tw-rounded-sm tw-border tw-border-solid tw-border-slate-300 tw-accent-pink-500 checked:tw-appearance-auto"
            {...register(`tabs.${index}.skipDate`)}
          />

          <span className="text-sm font-medium text-slate-700">Skip date</span>
        </label>

        <SubmitButton />
      </div>
    </form>
  );
};

const SubmitButton = () => {
  const loadingLogs = useSelectors(adminChannelsModule, 'loadingLogs');

  return (
    <button
      type="submit"
      disabled={loadingLogs}
      className="tw-ml-auto tw-h-10 tw-w-24 tw-cursor-pointer tw-self-end tw-rounded-md tw-border-0 tw-bg-sky-500 tw-px-5 tw-py-2.5 tw-text-[14px] tw-font-semibold tw-text-white hover:tw-bg-sky-700 disabled:tw-cursor-default"
    >
      {loadingLogs ? <CircularProgress size={14} color="inherit" /> : 'Search'}
    </button>
  );
};
