import api from 'core/api';

export const getChannelLists = (channelId, channelEdit) =>
  api.get(`channels/${channelId}/channel_lists?channel_edit=${channelEdit}`);
export const postChannelLists = (data) =>
  api.post(`channels/${data.channelId}/channel_lists`, data);
export const putChannelLists = (data) =>
  api.put(`channels/${data.channelId}/channel_lists/${data.id}`, data);

export const getUserChannelLists = (userId) => api.get(`/channel_lists/by_user/${userId}`);

export const postWorkoutChannelList = (data) =>
  api.post(`/channels/${data.channelId}/channel_lists/${data.listId}/add_workout`, data);
export const deleteWorkoutChannelList = (data) =>
  api.delete(`/channels/${data.channelId}/channel_lists/${data.listId}/${data.workoutId}`);
