/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import SectionHistoryScores from './SectionHistoryScores';
import SectionScoresChart from './SectionScoresChart';

const SectionScoresDetails = ({ workout, section, data, setModalScore, removeScore }) => {
  const [selectedScoreType, setSelectedScoreType] = useState(null);

  const [openedLeaderboard, setOpenedLeaderboard] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (data?.scoresTypes) {
      setSelectedScoreType(data?.scoresTypes[0]);
    }
  }, [data?.scoresTypes]);

  return (
    <Box>
      <Box mb={2}>
        <SelectBoxObject
          options={data?.scoresTypes?.map((x) => ({
            id: x,
            name: t(`workout.section.label.score.${x}`),
          }))}
          setValue={(v) => setSelectedScoreType(v)}
          value={selectedScoreType}
        />
      </Box>

      {selectedScoreType && (
        <SectionScoresChart
          data={data}
          scoreType={selectedScoreType}
          historyChart={data?.historyChart}
        />
      )}

      <Box mt={2}>
        <SectionHistoryScores
          scores={data?.history}
          isCustomWorkout={workout?.method === 'custom'}
          removeScore={removeScore}
          addScoreClick={() =>
            setModalScore({
              open: true,
              workout,
              sectionData: section,
            })
          }
          onLeaderboardClick={() => setOpenedLeaderboard(!openedLeaderboard)}
        />
      </Box>
    </Box>
  );
};

export default SectionScoresDetails;
