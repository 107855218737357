/* eslint-disable react/prop-types */
import React from 'react';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import ExampleQr from 'assets/img/qr_code_example.png';
import Typography from 'core/ui/Typography';

export default () => {
  const { t } = useTranslation();
  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
      <>
        <img src={ExampleQr} alt="Qr Code" style={{ width: 180 }} />

        <Typography variant="h1" align="center" m={5}>
          {t('label.scanQrCodeBelow')}
        </Typography>
      </>
    </Box>
  );
};
