import { makeStyles } from '@material-ui/core';
import { secondaryChat, secondaryLight } from 'core/ui/Colors';

const useStylesComments = makeStyles(() => ({
  boxChat: {
    height: '100%',
  },
  boxCoach: {
    width: 55,
    height: 15,
    textAlign: 'center',
    fontSize: 10,
    color: 'white',
    borderRadius: 10,
  },
  boxComments: {
    maxHeight: '100%',
    padding: 5,
  },
  boxInput: {
    backgroundColor: secondaryChat,
    height: 60,
    width: '100%',
    position: 'absolute',
    bottom: -1,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
  },
  boxInputNote: {
    backgroundColor: secondaryLight,
    height: 106,
    width: '100%',
    position: 'absolute',
    bottom: -1,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
  },
}));

export default useStylesComments;
