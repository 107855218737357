/* eslint-disable eqeqeq */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Box from 'core/ui/Box';
import Paper from 'core/ui/Paper';
import Typography, { fontStyle } from 'core/ui/Typography';
import Divider from 'core/ui/Divider';
import { useTranslation } from 'react-i18next';
import { ButtonPrimary } from 'core/ui/Button';
import { channelCurrency } from 'core/utils/formatters';
import { secondary, secondaryLight } from 'core/ui/Colors';
import { makeStyles } from '@material-ui/core';
import LabelBreakLine from 'core/ui/LabelBreakLine';
import TicketIcon from 'core/ui/FontIcon/TicketIcon';
import DescPlanLimitUse from 'components/Channel/Manage/Tabs/Memberships/DescPlanLimitUse';
import { APP_BASE_URL } from 'core/env';
import { QRCodeCanvas } from 'qrcode.react';
import ModalQrCodeDownload from 'core/ui/ModalQrCodeDownload';
import Renewable from './Renewable';
import ContractDuration from './ContractDuration';

const DetailCosts = ({
  channel,
  t,
  label,
  taxAmount,
  cost,
  originalCost,
  buttonColor,
  classes,
  introductory,
  plan,
}) => (
  <Paper className={classes.priceDetail} style={{ border: `1px solid ${buttonColor}` }}>
    <Box display="flex" alignItems="center">
      <Box flexGrow={1}>
        <Typography component="span" variant="h6" color="secondary">
          {label}
        </Typography>
      </Box>
      <Typography component="span" variant="h6" style={{ color: buttonColor }}>
        {channelCurrency(originalCost, channel?.currency)}
      </Typography>
    </Box>

    {plan.showFees && plan?.fees?.length > 0 && (
      <>
        <Divider my={1} style={{ backgroundColor: secondaryLight }} />
        {plan?.fees?.map((fee, index) => (
          <Box key={fee?.name}>
            <Box display="flex" alignItems="center">
              <Box flexGrow={1}>
                <Typography component="span" variant="body2" color="secondary">
                  {fee?.name}:&nbsp;
                </Typography>
              </Box>
              <Typography component="span" variant="body2" color="secondary">
                {channelCurrency(
                  introductory ? fee.feeIntroductoryAmount : fee.feeAmount,
                  channel?.currency,
                )}
              </Typography>
            </Box>
            {plan.fees.length - 1 > index && (
              <Divider my={1} style={{ backgroundColor: secondaryLight }} />
            )}
          </Box>
        ))}
      </>
    )}
    <Divider my={1} style={{ backgroundColor: secondaryLight }} />
    {taxAmount > 0 && (
      <>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <Typography component="span" variant="body2" color="secondary">
              {t('membership.tax')}:&nbsp;
            </Typography>
          </Box>
          <Typography component="span" variant="body2" color="secondary">
            {channelCurrency(taxAmount, channel?.currency)}
          </Typography>
        </Box>
        <Divider my={1} style={{ backgroundColor: secondaryLight }} />
      </>
    )}
    <Box display="flex" alignItems="center">
      <Box flexGrow={1}>
        <Typography component="span" variant="body2" color="secondary">
          Total
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center" alignItems="flex-end">
        <Typography component="span" variant="body2" color="secondary">
          {channelCurrency(cost, channel?.currency)}
        </Typography>
      </Box>
    </Box>
  </Paper>
);

const useStyles = makeStyles(() => ({
  priceDetail: {
    backgroundColor: 'white',
    paddingRight: 20,
    paddingLeft: 20,
    paddingTop: 10,
    paddingBottom: 10,
    margin: 10,
    borderRadius: 5,
  },
}));

const CardPlan = ({ plan, channel, modal, getPlan }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const buttonColor = channel?.customColors?.buttonColor;
  const [modalQr, setModalQr] = useState();

  return (
    <Paper
      mb={3}
      className="paper-rounded"
      pb={getPlan ? 0 : 3}
      style={{ overflow: 'hidden', position: 'relative' }}
    >
      <ModalQrCodeDownload
        open={modalQr?.open}
        close={() => setModalQr({ opem: false })}
        text={modalQr?.text}
        title={modalQr?.title}
      />

      {plan.name && (
        <Box my={3}>
          <Typography style={{ color: secondary }} align="center" variant="h4" component="h5">
            {plan.name}
          </Typography>
        </Box>
      )}

      {plan.useIntroductoryAmount && plan.renewable && plan.introductoryCost !== plan.cost ? (
        <Box>
          <DetailCosts
            label={t('channel.membership.plan.introductoryPrice')}
            taxAmount={plan.taxIntroductoryAmount}
            originalCost={plan.introductoryAmount}
            cost={plan.introductoryCost}
            buttonColor={buttonColor}
            channel={channel}
            t={t}
            classes={classes}
            introductory
            plan={plan}
          />

          <Divider m={1} />
          <DetailCosts
            label={t('channel.membership.plan.membershipPrice')}
            taxAmount={plan.taxAmount}
            originalCost={plan.amount}
            cost={plan.cost}
            buttonColor={buttonColor}
            channel={channel}
            t={t}
            classes={classes}
            plan={plan}
          />
        </Box>
      ) : (
        <Box>
          <DetailCosts
            label={t('channel.membership.plan.membershipPrice')}
            taxAmount={plan.taxAmount}
            cost={plan.cost}
            originalCost={plan.amount}
            buttonColor={buttonColor}
            channel={channel}
            t={t}
            classes={classes}
            plan={plan}
          />
        </Box>
      )}

      {!modal && plan.description && (
        <>
          <Box px={3}>
            <Typography color="secondary" align="center" variant="caption" component="h5">
              <LabelBreakLine>{plan.description}</LabelBreakLine>
            </Typography>
          </Box>
          <Divider m={1} />
        </>
      )}

      {plan.limitUse > 0 && <DescPlanLimitUse plan={plan} />}

      <Box mt={1} display="flex" flexGrow={1} justifyContent="center" alignItems="center">
        {plan.online && plan.inPerson && (
          <Typography style={{ color: buttonColor }} ml={3}>
            {t('workout.setting.online')},&nbsp;{t('workout.setting.inPerson')}
          </Typography>
        )}
        {!plan.online && plan.inPerson && (
          <Typography style={{ color: buttonColor }} ml={3}>
            {t('workout.setting.inPerson')}
          </Typography>
        )}
        {plan.online && !plan.inPerson && (
          <Typography style={{ color: buttonColor }} ml={3}>
            {t('workout.setting.online')}
          </Typography>
        )}
      </Box>
      <Divider m={1} />
      <Box mt={2} display="flex" flexGrow={1} justifyContent="center" alignItems="center">
        <Renewable plan={plan} />
      </Box>
      <ContractDuration plan={plan} />
      {!!plan.expiryConfig?.config &&
        plan.expiryConfig?.config !== 'none' &&
        plan.expiryConfig?.config !== 'limit0' && (
          <>
            <Divider m={1} />
            <Box mt={1} display="flex" flexGrow={1} justifyContent="center" alignItems="center">
              <Typography style={{ color: buttonColor }}>
                {t('channel.memberships.expiryIn')}&nbsp;{plan.expiryConfig?.number}
                &nbsp;
                {plan.expiryConfig?.config}
                {plan.expiryConfig?.number > 1 ? 's' : ''}&nbsp;
              </Typography>
            </Box>
          </>
        )}
      {plan.limitPurchases && (
        <>
          <Divider m={1} />
          <Box my={1} mb={2} display="flex" justifyContent="center" alignItems="center">
            <TicketIcon />
            <Typography noWrap style={fontStyle.xLarge} align="center">
              {plan?.limitPurchaseUsage || 0}/{plan.limitPurchasesMax}
            </Typography>
          </Box>
        </>
      )}

      <Box
        style={{ position: 'absolute', right: 5, bottom: getPlan ? 50 : 2 }}
        className="hover"
        onClick={() =>
          setModalQr({
            open: true,
            text: `${APP_BASE_URL}/embed/memberships/${channel?.id}/${plan?.id}`,
            title: plan.name,
          })
        }
      >
        <QRCodeCanvas
          style={{ width: 30, height: 30 }}
          value={`${APP_BASE_URL}/embed/memberships/${channel?.id}/${plan?.id}`}
        />
      </Box>

      {getPlan && (
        <Box mt={3} display="flex" flexGrow={1} alignItems="flex-end">
          <ButtonPrimary
            style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0, height: 50 }}
            onClick={() => {
              getPlan(plan?.id);
            }}
            buttonColor={buttonColor}
            fullWidth
          >
            {t('button.join').toUpperCase()}
          </ButtonPrimary>
        </Box>
      )}
    </Paper>
  );
};

export default CardPlan;
