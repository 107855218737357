import React, { useState } from 'react';
import Box from 'core/ui/Box';
import { primary, secondary } from 'core/ui/Colors';
import Paper from 'core/ui/Paper';
import Typography from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import Grid from 'core/ui/Grid';
import { useSelector } from 'react-redux';
import { Add, KeyboardArrowDown, KeyboardArrowRight } from '@material-ui/icons';
import ManagePaymentMethods from 'components/Account/ManageList';
import { Collapse } from '@material-ui/core';
import MemberHouseHold from 'components/Channel/Manage/Tabs/Members/Memberships/MemberInformation/MemberHouseHold';
import { paymentsSelectors } from 'modules/payments';
import { sessionSelectors } from 'modules/session';
import TablePayments from './Table/index';
import UserKeys from './UsersKeys/index';
import ModalAddPaymentMethod from './ModalAddPaymentMethod';
import Children from './Children';
import Favorite from './Favorite';
import ChannelLists from './ChannelLists';
import ModalFormChild from './Children/ModalForm';

const DashboardAccountShow = () => {
  const { t } = useTranslation();
  const payments = useSelector((state) => paymentsSelectors.getPayments(state));
  const user = useSelector((state) => sessionSelectors.getUser(state));
  const [openPayments, setOpenPayments] = useState(false);
  const [openFavorites, setOpenFavorites] = useState(false);
  const [openActiveKeys, setOpenActiveKeys] = useState(false);
  const [openChildren, setOpenChildren] = useState(false);
  const [openChannelLists, setOpenChannelLists] = useState(false);
  const [openPaymentMethods, setOpenPaymentMethods] = useState(false);
  const [openHouseHold, setOpenHouseHold] = useState({ open: false, collapse: false });
  const [openModalChildren, setOpenModalChildren] = useState({ open: false });

  const [openModalAddPaymentMethod, setOpenModalAddPaymentMethod] = useState(false);
  const styleIcon = {
    color: secondary,
    fontSize: 20,
  };

  const countPendingActionsPayments = payments?.filter(
    (payment) => payment?.status === 'requires_action' && !!payment?.nextActionSecret,
  )?.length;

  return (
    <Box>
      {user && user.id && (
        <ModalAddPaymentMethod
          open={openModalAddPaymentMethod}
          onClose={() => setOpenModalAddPaymentMethod(false)}
          userId={user.id}
          showCanadaDebit={user?.showCanadaDebit}
          setView={() => {}}
          userInfo={{
            name: user?.name,
            email: user?.email,
            phone: user?.phone,
          }}
          allowClose
          cardpointe={user?.gymConfig?.useCardpointe}
        />
      )}

      {openModalChildren.open && (
        <ModalFormChild
          close={() => setOpenModalChildren({ open: false })}
          child={openModalChildren.child}
        />
      )}

      <Grid container spacing={3} mb={3}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Paper className="paper-rounded" p={5}>
            <Box flexGrow="1" display="flex" alignItems="center">
              <Box
                display="flex"
                alignItems="center"
                onClick={() => setOpenPaymentMethods(!openPaymentMethods)}
              >
                {openPaymentMethods ? (
                  <KeyboardArrowDown style={{ ...styleIcon }} />
                ) : (
                  <KeyboardArrowRight style={{ ...styleIcon }} />
                )}
              </Box>
              <Box
                flexGrow="1"
                display="flex"
                alignItems="center"
                onClick={() => setOpenPaymentMethods(!openPaymentMethods)}
              >
                <Typography ml={1} color="secondary" className="link" variant="h5">
                  {t('user.paymentMethods').toUpperCase()}
                </Typography>
              </Box>
              <Box
                className="link-hover"
                display="flex"
                alignItems="center"
                onClick={() => setOpenModalAddPaymentMethod(true)}
              >
                <Add style={{ fontSize: 20 }} />
              </Box>
            </Box>
            <Collapse in={openPaymentMethods}>
              {user && user.id && <ManagePaymentMethods userId={user.id} />}
            </Collapse>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Paper className="paper-rounded" p={5}>
            <Box flexGrow="1" display="flex" alignItems="center">
              <Box
                display="flex"
                flexGrow="1"
                alignItems="center"
                onClick={() => setOpenActiveKeys(!openActiveKeys)}
                className="hover"
              >
                <Box display="flex" flexGrow="1" alignItems="center">
                  <Typography ml={1} color="secondary" className="link" variant="h5">
                    {t('user.activeKeys').toUpperCase()}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  {openActiveKeys ? (
                    <KeyboardArrowDown style={{ ...styleIcon }} />
                  ) : (
                    <KeyboardArrowRight style={{ ...styleIcon }} />
                  )}
                </Box>
              </Box>
            </Box>
            <Box>
              <Collapse in={openActiveKeys}>
                <UserKeys keys={user.channelKeys ? user.channelKeys : []} />
              </Collapse>
            </Box>
          </Paper>
        </Grid>
      </Grid>

      <Paper p={5} className="paper-rounded" mb={3}>
        <Box mb={3} flexGrow="1" display="flex" alignItems="center">
          <Box display="flex" alignItems="center" onClick={() => setOpenChildren(!openChildren)}>
            {openChildren ? (
              <KeyboardArrowDown style={{ ...styleIcon }} />
            ) : (
              <KeyboardArrowRight style={{ ...styleIcon }} />
            )}
          </Box>
          <Box
            flexGrow="1"
            display="flex"
            alignItems="center"
            onClick={() => setOpenChildren(!openChildren)}
          >
            <Typography ml={1} color="secondary" className="link" variant="h5">
              {t('user.children').toUpperCase()}
            </Typography>
          </Box>
          <Box
            className="link-hover"
            display="flex"
            alignItems="center"
            onClick={() => setOpenModalChildren({ open: true })}
          >
            <Add style={{ fontSize: 20 }} />
          </Box>
        </Box>
        <Collapse in={openChildren}>
          {user && user.id && (
            <Children
              setEditChild={(child) =>
                setOpenModalChildren({
                  child,
                  open: true,
                })
              }
            />
          )}
        </Collapse>
      </Paper>

      {!user?.hasUserHousehold && (
        <Paper p={5} className="paper-rounded" mb={3}>
          <Box mb={3} flexGrow="1" display="flex" alignItems="center">
            <Box display="flex" alignItems="center" onClick={() => setOpenChildren(!openChildren)}>
              {openChildren ? (
                <KeyboardArrowDown style={{ ...styleIcon }} />
              ) : (
                <KeyboardArrowRight style={{ ...styleIcon }} />
              )}
            </Box>
            <Box
              flexGrow="1"
              display="flex"
              alignItems="center"
              onClick={() => setOpenHouseHold((prev) => ({ ...prev, collapse: !prev.collapse }))}
            >
              <Typography ml={1} color="secondary" className="link" variant="h5">
                {t('channel.member.houseHold').toUpperCase()}
              </Typography>
            </Box>
            <Box
              className="link-hover"
              display="flex"
              alignItems="center"
              onClick={() => setOpenHouseHold((prev) => ({ ...prev, open: !prev.open }))}
            >
              <Add style={{ fontSize: 20 }} />
            </Box>
          </Box>
          <Collapse in={openHouseHold?.collapse}>
            {user && user.id && (
              <Box p={3} my={3}>
                <MemberHouseHold
                  member={user}
                  modalHouseHold={openHouseHold}
                  setModalHouseHold={(data) => setOpenHouseHold((prev) => ({ ...prev, ...data }))}
                  channelId={null}
                />
              </Box>
            )}
          </Collapse>
        </Paper>
      )}

      <Paper className="paper-rounded" p={5} mb={3}>
        <Box
          onClick={() => setOpenPayments(!openPayments)}
          mb={3}
          flexGrow="1"
          className="link-hover"
          display="flex"
          alignItems="center"
        >
          <Box flexGrow="1" display="flex" alignItems="center">
            <Typography ml={1} color="secondary" className="link" variant="h5">
              {t('table.payments').toUpperCase()}
              {countPendingActionsPayments > 0 && (
                <Typography component="span" ml={5} style={{ color: primary }}>
                  {t('payment.pendingAction')}&nbsp;
                  {countPendingActionsPayments}
                </Typography>
              )}
            </Typography>
          </Box>
          <Box>
            {openPayments ? (
              <KeyboardArrowDown style={{ ...styleIcon }} />
            ) : (
              <KeyboardArrowRight style={{ ...styleIcon }} />
            )}
          </Box>
        </Box>
        <Box>
          <Collapse in={openPayments}>
            <TablePayments />
          </Collapse>
        </Box>
      </Paper>

      <Paper className="paper-rounded" p={5} mb={3}>
        <Box
          onClick={() => setOpenFavorites(!openFavorites)}
          flexGrow="1"
          className="link-hover"
          display="flex"
          alignItems="center"
        >
          <Box flexGrow="1" display="flex" alignItems="center">
            <Typography mb={3} color="secondary" variant="h5">
              {t('title.favorites').toUpperCase()}
            </Typography>
          </Box>
          <Box>
            {openFavorites ? (
              <KeyboardArrowDown style={{ ...styleIcon }} />
            ) : (
              <KeyboardArrowRight style={{ ...styleIcon }} />
            )}
          </Box>
        </Box>
        <Box>
          <Collapse in={openFavorites}>
            <Favorite />
          </Collapse>
        </Box>
      </Paper>

      <Paper className="paper-rounded" p={5} mb={3}>
        <Box
          onClick={() => setOpenChannelLists(!openChannelLists)}
          flexGrow="1"
          className="link-hover"
          display="flex"
          alignItems="center"
        >
          <Box flexGrow="1" display="flex" alignItems="center">
            <Typography mb={3} color="secondary" variant="h5">
              {t('channel.lists').toUpperCase()}
            </Typography>
          </Box>
          <Box>
            {openChannelLists ? (
              <KeyboardArrowDown style={{ ...styleIcon }} />
            ) : (
              <KeyboardArrowRight style={{ ...styleIcon }} />
            )}
          </Box>
        </Box>
        <Box>
          <Collapse in={openChannelLists}>
            <ChannelLists />
          </Collapse>
        </Box>
      </Paper>
    </Box>
  );
};

export default DashboardAccountShow;
