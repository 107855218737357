import React, { useEffect, useRef, useState } from 'react';
import Box from 'core/ui/Box';
import Video from 'core/ui/Video';
import Typography, { fontStyle } from 'core/ui/Typography';
import LoaderSm from 'core/ui/LoaderSm';
import Button from 'core/ui/Button';
import { onlineColor } from 'core/ui/Colors';
import { Close, VerifiedUser } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core';

export default ({
  index,
  isFullScreen,
  boxVideoLarge,
  boxVideo,
  getSize,
  player,
  recording,
  setRecordingFullScreen,
}) => {
  const { palette } = useTheme();
  const { t } = useTranslation();
  const boxNameStyle = {
    position: 'relative',
    marginTop: -23,
    marginLeft: 0,
    background: 'rgba(24,24,24,0.54)',
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
  };
  const playerRef = useRef(player);
  const [currentSeek, setCurrentSeek] = useState(0);

  useEffect(() => {
    playerRef.current = player;
  });

  useEffect(() => {
    if (recording && recording.startCountdownSeconds) {
      if (player.time.complete > 0) {
        const perc = player.time.complete / player.time.totalMain;
        const newSeekItem = (recording.durationSeconds - recording.startCountdownSeconds) * perc;
        setCurrentSeek(newSeekItem + recording.startCountdownSeconds);
      } else {
        setCurrentSeek(recording.startCountdownSeconds);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recording]);

  useEffect(() => {
    if (recording && recording.startCountdownSeconds >= 0 && player.manualSeek) {
      let newSeek = player.seek + recording.startCountdownSeconds;
      if (newSeek < 0) {
        newSeek = 0;
      }
      setCurrentSeek(newSeek);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recording, player.seek, player.manualSeek]);

  const sizeBox = getSize(
    recording,
    isFullScreen(recording?.user?.id)
      ? boxVideoLarge && boxVideoLarge.clientWidth
      : boxVideo && boxVideo.clientWidth,
  );

  useEffect(
    () => () => {
      player.removeVideoReady(recording.user.id);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onWaiting = () => {
    player.setPlaying(false);
    player.setSeek(Number(playerRef.current.time.complete) + 0.001);
  };

  const onSeek = (seek) => {
    if (recording.id === player.time.mainId && seek > 0) {
      const newSeek = seek - recording.startCountdownSeconds || 0;

      player.setTime((prev) => ({ ...prev, complete: newSeek }));
    }
  };

  return (
    <Box
      id={index === 0 ? 'box-video-tutorial' : ''}
      style={{
        height: `${sizeBox.height}px`,
        width: `${sizeBox.width}px`,
        position: 'relative',
        background: '#000',
        borderRadius: 10,
      }}
      className={`box-rec ${isFullScreen(recording?.user?.id) ? 'active-rec' : 'inactive-rec'}`}
    >
      <Box style={{ position: 'absolute', right: 3, top: 3, zIndex: 999 }}>
        {recording.vimeoUrl && (
          <Box>
            <Button
              style={{
                backgroundColor: onlineColor,
                padding: 3,
                minWidth: 30,
                borderRadius: 10,
              }}
            >
              <VerifiedUser style={{ color: 'white', fontSize: 20 }} />
            </Button>
          </Box>
        )}
        {!!recording.processing && (
          <Box>
            <Button
              style={{
                backgroundColor: palette.primary.main,
                padding: 3,
                minWidth: 30,
                borderRadius: 10,
              }}
            >
              <LoaderSm loading width={16} />
              <Typography p={1} style={{ ...fontStyle.small, color: 'white' }}>
                {t('video.processing')}
              </Typography>
            </Button>
          </Box>
        )}
        {!recording.processing && !recording.vimeoUrl && (
          <Box>
            <Button
              style={{
                backgroundColor: palette.primary.main,
                padding: 3,
                minWidth: 30,
                borderRadius: 10,
              }}
            >
              <Close style={{ ...fontStyle.medium, color: 'white' }} />
              <Typography p={1} style={{ ...fontStyle.small, color: 'white' }}>
                {t('workout.notComplete')}
              </Typography>
            </Button>
          </Box>
        )}
      </Box>
      <Box
        style={{
          position: 'absolute',
          height: '100%',
          width: '100%',
          display: 'block',
          zIndex: 10,
        }}
        className="link"
        onClick={() => setRecordingFullScreen(recording?.user?.id)}
      />

      {!player.isReady && (
        <Box
          style={{
            position: 'absolute',
            height: '100%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <LoaderSm loading />
        </Box>
      )}
      <Video
        playing={player.playing && recording.durationSeconds > player.time.complete}
        muted={player.muted}
        src={recording.vimeoUrl}
        onReady={() => {
          player.setVideoReady(recording.user.id);
        }}
        vimeo
        full
        controls={false}
        idVideo={`${recording.user.id}`}
        showPlayButton={false}
        onSeek={(seconds) => onSeek(seconds)}
        seek={currentSeek}
        durationSeconds={recording.durationSeconds}
        togglePlayRecordings={player.setPlaying}
        borderRadius={10}
        onWaiting={onWaiting}
      />
      <Box style={boxNameStyle}>
        <Typography p={1} style={{ ...fontStyle.small, color: 'white' }}>
          {recording?.user?.name}
        </Typography>
      </Box>
    </Box>
  );
};
