/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import Box from 'core/ui/Box';
import TextAreaFieldRounded from 'core/ui/TextAreaFieldRounded';
import Paper from 'core/ui/Paper';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import useSelectors from 'modules/map/useSelectors';
import { benchmarkSectionsModule } from 'modules/benchmarkSections';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import Checkbox from 'core/ui/Checkbox';
import Typography, { fontStyle } from 'core/ui/Typography';

const FormInfo = ({ watch, setValue, errors }) => {
  const { t } = useTranslation();
  const categories = useSelectors(benchmarkSectionsModule, 'categories');
  const { request } = useActions();

  useEffect(() => {
    request({ action: Modules.benchmarkSections.actions.getBenchmarkSectionsCategories });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Paper className="paper-rounded" p={3}>
      <Box mb={3}>
        <TextFieldRounded
          placeholder={t('benchmark.label.name')}
          label={t('benchmark.label.name').toUpperCase()}
          value={watch('name') || ''}
          onChange={(v) => setValue('name', v)}
          error={errors.name}
          bordered
        />
      </Box>

      <Box mb={3}>
        <TextAreaFieldRounded
          placeholder={t('benchmark.label.description')}
          label={t('benchmark.label.description').toUpperCase()}
          value={watch('description') || ''}
          onChange={(v) => setValue('description', v)}
          error={errors.description}
          bordered
        />
      </Box>
      <Box mb={3} display="flex">
        <Box flexGrow={1}>
          <SelectBoxObject
            placeholder={t('benchmark.label.category')}
            label={t('benchmark.label.category').toUpperCase()}
            options={categories}
            error={errors.benchmarkSectionCategoryId}
            value={watch('benchmarkSectionCategoryId') || ''}
            setValue={(v) => setValue('benchmarkSectionCategoryId', v)}
            bordered
          />
        </Box>

        <Box
          flexGrow={1}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Typography style={fontStyle.label} mb={1} variant="body1">
            {t('benchmark.label.active').toUpperCase()}
          </Typography>
          <Box>
            <Checkbox
              checked={watch('active')}
              onClick={() => setValue('active', !watch('active'))}
            />
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

FormInfo.defaultProps = {
  currentPlan: {},
  setCurrentPlan: () => {},
};

export default FormInfo;
