import React, { useContext, useEffect } from 'react';
import { Grow } from '@material-ui/core';
import Box from 'core/ui/Box';
import Events from './Events';
import ViewEvent from './View';
import { ShowContext } from '../../index';

export default () => {
  const showContext = useContext(ShowContext);
  const { tab, setTab, setSelectedWorkout, selectedWorkout } = showContext;
  const setEventView = (workoutId) => {
    setTab('viewWorkout');
    setSelectedWorkout(workoutId);
  };

  useEffect(() => {
    const body = document.querySelector('#root');

    body.scrollIntoView(
      {
        behavior: 'smooth',
      },
      0,
    );
  }, [selectedWorkout]);

  return (
    <>
      <Box>{tab === 'workouts' && <Events setEventView={setEventView} />}</Box>

      <Grow direction="up" in={tab === 'viewWorkout'} mountOnEnter unmountOnExit>
        <Box>
          {tab === 'viewWorkout' && (
            <ViewEvent
              heats={showContext.competition.heats}
              workoutId={selectedWorkout}
              competition={showContext.competition}
            />
          )}
        </Box>
      </Grow>
    </>
  );
};
