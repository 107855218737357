import React from 'react';
import { Link } from '@material-ui/core';
import PropTypes from 'prop-types';
import Box from 'core/ui/Box';
import Typography from 'core/ui/Typography';

const URL = 'https://www.instagram.com';
const InstagramLink = ({ instagram, size, clickable }) => {
  const Render = () => (
    <Box display="flex" alignItems="center">
      {/* <Instagram className="instagram-link" style={{ fontSize: 14 }} /> */}
      <Typography
        style={{ fontSize: size }}
        className="instagram-link"
        variant="caption"
        component="div"
      >
        {instagram}
      </Typography>
    </Box>
  );
  return (
    <>
      {instagram && clickable && (
        <Link className="link-hover" href={`${URL}/${instagram.replace('@', '')}`} target="_blank">
          <Render />
        </Link>
      )}
      {instagram && !clickable && <Render />}
    </>
  );
};

InstagramLink.defaultProps = {
  instagram: null,
  size: 12,
  clickable: true,
};

InstagramLink.propTypes = {
  instagram: PropTypes.string,
  size: PropTypes.number,
  clickable: PropTypes.bool,
};

export default InstagramLink;
