import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import propTypes from 'prop-types';
import DialogTitle from 'core/ui/Dialog/DialogTitle';
import ChannelsCard from '.';
import MyChannels from './MyChannels';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#f3f3f3',
  },
}));

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function ModalChannels({ open, setOpen, select, sidebar, defaultTab }) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div>
      <Dialog
        maxWidth="lg"
        scroll="body"
        onClose={() => (select ? null : setOpen(false))}
        open={open}
        fullWidth
      >
        <div className={classes.root}>
          <DialogTitle onClose={() => setOpen(false)}>
            {t(!select ? 'myChannels' : 'modalChannels.selectChannel')}
          </DialogTitle>
          <DialogContent dividers>
            {!sidebar && <ChannelsCard select={select} />}
            {sidebar && <MyChannels select={select} defaultTab={defaultTab} />}
          </DialogContent>
        </div>
      </Dialog>
    </div>
  );
}

ModalChannels.propTypes = {
  select: propTypes.func,
  open: propTypes.bool,
  setOpen: propTypes.func,
  sidebar: propTypes.bool,
};

ModalChannels.defaultProps = {
  select: null,
  open: false,
  setOpen: null,
  sidebar: false,
};
