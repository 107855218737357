import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import { Dialog, IconButton } from '@material-ui/core';
import Typography from 'core/ui/Typography';
import { ButtonPrimary } from 'core/ui/Button';
import Texture from 'core/ui/Texture';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import Toggle from 'core/ui/Toggle';
import Paper from 'core/ui/Paper';
import LoaderSm from 'core/ui/LoaderSm';
import Grid from 'core/ui/Grid';
import { Close } from '@material-ui/icons';
import useFormMedia from './form';
import BucketUpload from './BucketUpload';
import ViewVideo from '../MediaFilesGallery/ViewVideo';

const ModalAddMedia = ({
  open,
  associableType,
  associableId,
  currentUrl,
  title,
  close,
  callback,

  ownerableId,
  ownerableType,
}) => {
  const { t } = useTranslation();
  const [option, setOption] = useState('link');

  const { confirmUploads, loading, uploads, setUploads } = useFormMedia({
    associableId,
    associableType,
    callback,

    ownerableId,
    ownerableType,
  });

  const setFile = (idx, data) => {
    setUploads((prev) =>
      prev.map((itemPrev, index) => {
        if (index === idx) {
          return { ...itemPrev, ...data };
        }

        return itemPrev;
      }),
    );
  };

  return (
    <Dialog maxWidth="md" fullWidth scroll="body" onClose={() => close()} open={open}>
      <Texture>
        <Box p={5}>
          <Box>
            <Typography align="center" variant="h3" mb={2}>
              {title}
            </Typography>

            {currentUrl && (
              <Paper p={1} m={1}>
                <ViewVideo url={currentUrl} />
              </Paper>
            )}

            <Box p={3}>
              <Box display="flex" justifyContent="center">
                <Toggle
                  options={[
                    { value: 'link', label: 'Link' },
                    { value: 'upload', label: 'Upload' },
                  ]}
                  value={option}
                  onChange={(v) => setOption(v)}
                />
              </Box>

              <Grid container spacing={4}>
                {uploads.map((file, idx) => (
                  <Grid item xs={12} md={6} key={idx.toString()}>
                    <Paper p={5} my={3} style={{ position: 'relative' }}>
                      {idx > 0 && (
                        <IconButton
                          style={{ position: 'absolute', right: 3, top: 3 }}
                          onClick={() => setUploads((prev) => prev.filter((x) => x !== file))}
                        >
                          <Close />
                        </IconButton>
                      )}
                      {associableType !== 'Movement' && (
                        <Box mb={3}>
                          <TextFieldRounded
                            label={t('channel.video.name.name')}
                            value={file?.name}
                            onChange={(v) => setFile(idx, { name: v })}
                            placeholder={t('channel.video.placeholder.name').toUpperCase()}
                            bordered
                          />
                        </Box>
                      )}
                      {option === 'link' && (
                        <Box>
                          <TextFieldRounded
                            label={t('channel.video.url')}
                            value={file?.url}
                            onChange={(v) => setFile(idx, { url: v })}
                            placeholder={t('workout.placeholder.video').toUpperCase()}
                            bordered
                          />
                        </Box>
                      )}
                      {option === 'upload' && (
                        <Box>
                          <BucketUpload
                            file={file}
                            setFile={(data) => setFile(idx, data)}
                            autoUpload
                          />
                        </Box>
                      )}

                      {file?.url && (
                        <Paper p={3} mt={3}>
                          <ViewVideo url={file?.url} />
                        </Paper>
                      )}
                    </Paper>
                  </Grid>
                ))}

                <Grid item xs={12} md={6}>
                  <Box display="flex" alignItems="center" justifyContent="center" p={5}>
                    <ButtonPrimary onClick={() => setUploads((prev) => [...prev, {}])}>
                      {t('buton.addVideos')}
                    </ButtonPrimary>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box mt={3} display="flex" justifyContent="flex-end">
              <ButtonPrimary
                style={{ minWidth: 150 }}
                disabled={loading}
                onClick={() => confirmUploads()}
              >
                <LoaderSm loading={loading} />
                {t('button.save')}
              </ButtonPrimary>
            </Box>
          </Box>
        </Box>
      </Texture>
    </Dialog>
  );
};

ModalAddMedia.defaultProps = {
  close: () => {},
  callback: () => {},
  open: false,
};

ModalAddMedia.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  close: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  callback: PropTypes.func,
  open: PropTypes.bool,
};

export default ModalAddMedia;
