/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import { ButtonPrimary } from 'core/ui/Button';
import Texture from 'core/ui/Texture';
import { Dialog, Link } from '@material-ui/core';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import Toggle from 'core/ui/Toggle';
import Paper from 'core/ui/Paper';
import DatePickerRounded from 'core/ui/DatePickers/DatePickerRounded';
import useForm from './form';
import S3Upload from '../S3Upload';

const ModalFormClock = ({ open, item, close, callback, user, channel, buttonColor }) => {
  const { t } = useTranslation();
  const { setValue, watch, handleSubmit, onSubmit, errors, loading } = useForm({
    item,
    callback,
    channel,
    user,
  });

  return (
    <Dialog maxWidth="sm" scroll="body" fullWidth onClose={() => close()} open={open}>
      <Texture>
        <Box p={5}>
          <Box mb={3}>
            <TextFieldRounded
              value={watch('name')}
              onChange={(v) => setValue('name', v)}
              placeholder={t('label.name')}
              error={errors.name}
              label={t('label.name').toUpperCase()}
            />
          </Box>
          <Box mb={3}>
            <DatePickerRounded
              value={watch('date')}
              onChange={(v) => setValue('date', v)}
              placeholder={t('label.date')}
              error={errors.date}
              label={t('label.date').toUpperCase()}
              usFormat={channel?.useUsDateFormat}
            />
          </Box>

          <Paper p={3} mb={3}>
            <Box m={3} display="flex" alignItems="center" justifyContent="center">
              <Toggle
                activeColor={buttonColor}
                options={[
                  { value: 'pdf', label: 'PDF' },
                  { value: 'image', label: 'Image' },
                  { value: 'doc', label: 'Doc' },
                ]}
                value={watch('fileType')}
                onChange={(v) => (item?.id ? null : setValue('fileType', v))}
              />
            </Box>

            {!item?.id && watch('fileType') && (
              <Box mt={3}>
                <S3Upload
                  userId={user?.id}
                  channelId={channel?.id}
                  buttonColor={buttonColor}
                  setUrl={(v) => setValue('s3Url', v)}
                />
              </Box>
            )}

            {item?.id && (
              <Box display="flex" justifyContent="center" m={3}>
                <Link target="_blank" href={item?.s3Url} className="link-hover">
                  <ButtonPrimary buttonColor={buttonColor} fontSize="large">
                    {t('button.view')}
                  </ButtonPrimary>
                </Link>
              </Box>
            )}
          </Paper>

          <Box mt={3} display="flex" justifyContent="flex-end">
            <ButtonPrimary
              buttonColor={buttonColor}
              disabled={loading || !watch('s3Url')}
              onClick={handleSubmit(onSubmit)}
            >
              {t('button.save')}
            </ButtonPrimary>
          </Box>
        </Box>
      </Texture>
    </Dialog>
  );
};

ModalFormClock.defaultProps = {
  close: () => {},
  callback: () => {},
  open: false,
};

ModalFormClock.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  close: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  callback: PropTypes.func,
  open: PropTypes.bool,
};

export default ModalFormClock;
