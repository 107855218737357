import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Typography from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import { mmss } from 'core/utils/formatters';

const TimerToStart = ({ workout, label }) => {
  const { t } = useTranslation();
  const [time, setTime] = useState(0);
  const intervalRef = useRef();
  const dateBase = workout.method !== 'live' ? workout.publishAt : workout.scheduledAt;
  const calculeDiff = () => {
    const scheduledAt = new Date(dateBase).getTime();
    const seconds = Math.round((scheduledAt - new Date().getTime()) / 1000);

    if (seconds <= 0) {
      setTime(0);
      clearInterval(intervalRef.current);
      intervalRef.current = null;
      return;
    }

    setTime(seconds);
  };

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      calculeDiff();
    }, 900);
    return () => {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {time > 0 && (
        <>
          <Typography variant="caption" color="secondary" component="span">
            {label || t('workout.startingIn')} &bull;
          </Typography>
          <Typography variant="caption" color="primary" component="span" ml={1}>
            {mmss(time)}
          </Typography>
        </>
      )}
    </>
  );
};

export default TimerToStart;

TimerToStart.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  workout: PropTypes.object,
};

TimerToStart.defaultProps = {
  workout: {},
};
