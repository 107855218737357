import React from 'react';
import Typography, { fontStyle } from 'core/ui/Typography';
import MaskedInput from 'react-text-mask';
import { TextField } from '@material-ui/core';
import Box from '../Box';
import WrapperInput from './WrapperInput';
import { secondaryLight } from '../Colors';

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[2-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

export default function TextFieldMaskedRounded({
  label,
  name,
  inputRef,
  placeholder,
  error,
  onChange,
  style,
  disabled,
  paperStyle,
  value,
  onKeyPress,
  inputProps,
  required,
  bordered,
  helperText,
  ...props
}) {
  const styleInput = error
    ? paperStyle
    : { ...paperStyle, border: bordered ? `1px solid${secondaryLight}` : 'none' };
  return (
    <div style={{ width: '100%' }} className="masked">
      {label !== '' && (
        <Box ml={2} mb={2} display="flex">
          <Typography style={fontStyle.label} variant="body1">
            {label}
          </Typography>
          {required && (
            <Typography ml={1} color="error">
              *
            </Typography>
          )}
        </Box>
      )}

      <WrapperInput style={styleInput} error={error} px={3}>
        <TextField
          style={{ width: '100%' }}
          name={name}
          disabled={disabled}
          placeholder={placeholder}
          InputProps={{
            inputComponent: TextMaskCustom,
            disableUnderline: true,
          }}
          helperText={helperText}
          variant="standard"
          value={value || ''}
          onChange={(e) => onChange(e.target.value)}
          {...props}
        />
      </WrapperInput>
    </div>
  );
}

TextFieldMaskedRounded.defaultProps = {
  name: null,
  error: null,
  onKeyPress: null,
  inputRef: null,
  placeholder: '',
  onChange: null,
  label: '',
  style: {},
  inputProps: {},
  value: null,
  disabled: false,
  required: false,
};
