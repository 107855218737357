/* eslint-disable eqeqeq */
import React, { createContext, useEffect, useMemo, useRef, useState } from 'react';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import Paper from 'core/ui/Paper';
import Typography from 'core/ui/Typography';
import { CalendarsContext } from 'contexts';
import { useDispatch, useSelector } from 'react-redux';
import { channelsActions, channelsSelectors } from 'modules/channels';
import Button, { ButtonPrimary } from 'core/ui/Button';
import MIcon from 'core/ui/MIcon';
import { ClickAwayListener, IconButton, Popper } from '@material-ui/core';
import { Add, CalendarToday, Close, QueryBuilder } from '@material-ui/icons';
import { APP_BASE_URL } from 'core/env';
import CopyText from 'core/ui/CopyText';
import calendarIcon from 'assets/img/integrations/google_calendar.png';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import useSelectors from 'modules/map/useSelectors';
import { usersCalendarsModule } from 'modules/usersCalendars';
import LoaderSm from 'core/ui/LoaderSm';
import { persistData } from 'core/utils/session-storage';
import WeeklyHours from './Availability/WeeklyHours';
import useAvailabilityForm from './useAvailabilityForm';
import UnavailableDates from './Availability/Unavailable/index';
import ModalFormCalendar from './Form/ModalForm';
import CalendarsCalendar from './Calendar';

export const SearchCompetitionContext = createContext();

const ManageCalendars = ({ calendarId, coachId, channelId }) => {
  const { t } = useTranslation();
  const channel = useSelector((state) => channelsSelectors.getChannel(state));
  const [modalForm, setModalForm] = useState({ open: false });
  const [popper, setPopper] = useState(false);

  const loadingExternalCalendar = useSelectors(usersCalendarsModule, 'loadingExternalCalendar');
  const [view, setView] = useState(calendarId ? 'times' : 'calendar');

  const popperRef = useRef();
  const dispatch = useDispatch();
  const { request } = useActions();

  const {
    control,
    weeklyHours,
    saveCalendar,
    setWeeklyHours,
    saveWeekDays,
    currentCalendar,
    setCurrentCalendar,
    populateWeekDay,
    deleteCalendar,
    getCalendars,
    calendars,
    getCalendar,
  } = useAvailabilityForm({ coachId });

  useEffect(() => {
    if (channelId) {
      dispatch(channelsActions.getChannelStart(channelId));
    }
  }, [channelId, dispatch]);

  useEffect(() => {
    if (channel?.id) {
      getCalendars();
    }
  }, [channel, getCalendars, coachId]);

  useEffect(() => {
    if (channel?.id && calendarId) {
      getCalendar(calendarId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel, getCalendars, coachId, calendarId]);

  const callbackSave = (data) => {
    setCurrentCalendar(data);
    populateWeekDay(data);
    setModalForm({});
    getCalendars();
  };

  const contextValues = useMemo(
    () => ({
      control,
      weeklyHours,
      saveCalendar,
      saveWeekDays,
      currentCalendar,
      setCurrentCalendar,
      setWeeklyHours,
    }),
    [
      control,
      weeklyHours,
      saveCalendar,
      saveWeekDays,
      currentCalendar,
      setWeeklyHours,
      setCurrentCalendar,
    ],
  );

  const openGoogleIntegration = () => {
    persistData(
      'EXTERNAL_CALENDAR_INTEGRATION',
      JSON.stringify({
        ownerableId: currentCalendar?.id,
        ownerableType: 'UsersCalendar',
      }),
    );

    request({
      action: Modules.usersCalendars.actions.connectExternal,
      data: {
        ownerableId: currentCalendar?.id,
        ownerableType: 'UsersCalendar',
      },
      options: {
        onSuccess: (data) => {
          window.location = data?.redirectUrl;
        },
      },
    });
  };

  const deleteCalendarIntegration = () => {
    request({
      action: Modules.usersCalendars.actions.removeExternalCalendar,
      data: {
        ownerableId: currentCalendar?.id,
        ownerableType: 'UsersCalendar',
      },
      options: {
        onSuccess: () => {
          getCalendar(currentCalendar?.id);
        },
        onError: () => {},
      },
    });
  };

  const calendarLink = `${APP_BASE_URL}/calendar/${currentCalendar?.slug}`;

  return (
    <Box mt={3} pb={2}>
      {channel?.isCoach && (
        <Paper p={1} mb={2}>
          <div className="tw-flex tw-flex-row tw-gap-x-2">
            <Button
              className={view === 'times' && 'tw-bg-gray-100'}
              onClick={() => setView('times')}
            >
              <QueryBuilder fontSize="small" />
              &nbsp;
              {t('button.times')}
            </Button>

            <Button
              className={view === 'calendar' && 'tw-bg-gray-100'}
              onClick={() => setView('calendar')}
            >
              <CalendarToday fontSize="small" />
              &nbsp;
              {t('button.calendar')}
            </Button>
          </div>
        </Paper>
      )}

      <div>
        {view === 'calendar' && (
          <Paper p={2} className=" tw-col-span-1 md:tw-col-span-3">
            <CalendarsCalendar isCoach={channel?.isCoach} channel={channel} coachId={coachId} />
          </Paper>
        )}
        {view === 'times' && (
          <div>
            {calendars?.length > 0 && (
              <Paper p={2} mb={2}>
                <div className="tw-flex tw-flex-row tw-items-center tw-gap-2">
                  <div className="tw-flex tw-flex-row">
                    <MIcon icon="date_range" color="black" />
                  </div>
                  <Button onClick={() => setPopper(!popper)} className="link tw-px-4">
                    <div className="tw-flex tw-flex-row tw-items-center">
                      <div className="tw-mr-2 tw-flex tw-grow tw-flex-col tw-items-start">
                        <div className="tw-flex tw-flex-row">
                          <Typography>{currentCalendar?.name || 'Select a calendar'}</Typography>
                        </div>
                        <Typography className="tw-text-xs tw-text-gray-400">
                          {currentCalendar?.timezone}
                        </Typography>
                      </div>
                      <div ref={popperRef}>
                        <MIcon icon="keyboard_arrow_down" color="black" size={20} />
                      </div>
                    </div>
                  </Button>
                  <div className="mr-2">
                    <Popper open={popper} anchorEl={popperRef.current}>
                      <Paper p={3}>
                        <ClickAwayListener onClickAway={() => setPopper(false)}>
                          <div className="tw-flex tw-flex-col tw-gap-2">
                            {calendars?.map((calendar) => (
                              <Button
                                key={calendar?.id}
                                className="tw-flex tw-flex-row tw-items-center tw-rounded-md tw-bg-gray-50 tw-p-1 hover:tw-cursor-pointer hover:tw-opacity-90"
                                onClick={() => {
                                  setCurrentCalendar(calendar);
                                  populateWeekDay(calendar);
                                  setPopper(false);
                                }}
                              >
                                <div className="tw-flex tw-grow tw-flex-row tw-items-start tw-gap-x-2 tw-p-1">
                                  <MIcon icon="date_range" color="black" size={20} />

                                  <Typography>
                                    {calendar?.name || `Calendar ${calendar?.id}`}
                                  </Typography>
                                </div>
                              </Button>
                            ))}
                          </div>
                        </ClickAwayListener>
                      </Paper>
                    </Popper>
                  </div>
                  {currentCalendar?.id && currentCalendar?.isCoach && (
                    <div>
                      <IconButton
                        size="small"
                        onClick={() => setModalForm({ open: true, item: currentCalendar })}
                      >
                        <MIcon icon="edit" color="black" size={20} />
                      </IconButton>
                      <IconButton onClick={() => deleteCalendar(currentCalendar?.id)}>
                        <MIcon icon="delete" color="black" size={20} />
                      </IconButton>
                    </div>
                  )}
                  <div className="tw-grow" />
                  <Button onClick={() => setModalForm({ open: true })}>
                    <MIcon icon="add" color="black" size={20} />
                    {t('button.createCalendar')}
                  </Button>
                </div>
              </Paper>
            )}

            <CalendarsContext.Provider value={contextValues}>
              <div className="tw-grid tw-grid-cols-1 tw-gap-2 md:tw-grid-cols-4">
                <div className="tw-col-span-1 md:tw-col-span-3">
                  <Paper p={3}>
                    {!currentCalendar?.id && (
                      <div className="tw-flex tw-flex-row tw-items-center">
                        <div className="tw-mb-2 tw-grow tw-text-xl tw-font-bold tw-text-gray-600">
                          {t('label.createYourCalendarAndAddAvailability')}
                        </div>
                        <Button onClick={() => setModalForm({ open: true })}>
                          <div className="tw-flex tw-flex-row tw-items-center tw-gap-x-1 tw-text-lg tw-text-gray-500">
                            <Add />
                            {t('button.createCalendar')}
                          </div>
                        </Button>
                      </div>
                    )}

                    {currentCalendar?.id && (
                      <div>
                        <div className="tw-mb-2">
                          <Typography variant="h5" className="tw-mb-1">
                            {t('label.shareYourCalendarLink')}
                          </Typography>
                          <div className="tw-flex tw-flex-row tw-items-center tw-gap-2">
                            <div className="tw-grow tw-rounded-md tw-border tw-border-solid tw-border-gray-300 tw-bg-gray-200 tw-p-2">
                              {calendarLink}
                            </div>
                            <CopyText text={calendarLink} fontSize={30} />
                          </div>
                        </div>
                        {currentCalendar?.showIntegrations && (
                          <div className="tw-mb-2">
                            <Typography className="tw-mb-1" variant="h5">
                              {t('label.integrations')}
                            </Typography>
                            {currentCalendar?.externalCalendar?.id ? (
                              <div className="tw-flex tw-flex-row tw-items-center tw-gap-2">
                                <Button className="tw-flex tw-flex-row tw-items-center tw-gap-2 tw-text-lg">
                                  <img src={calendarIcon} className="tw-h-6 tw-w-6" alt="" />
                                  &nbsp;{currentCalendar?.externalCalendar?.externalId}
                                </Button>

                                <IconButton onClick={() => deleteCalendarIntegration()}>
                                  <Close />
                                </IconButton>
                              </div>
                            ) : (
                              <div className="tw-flex tw-flex-row tw-items-center tw-gap-2">
                                <Button
                                  onClick={() => openGoogleIntegration()}
                                  className="tw-flex tw-flex-row tw-items-center tw-gap-2 tw-text-lg"
                                >
                                  <LoaderSm loading={loadingExternalCalendar} />
                                  <img src={calendarIcon} className="tw-h-6 tw-w-6" alt="" />
                                  &nbsp;{t('button.googleCalendar')}
                                </Button>
                              </div>
                            )}
                          </div>
                        )}
                        <div className="tw-mb-2">
                          <WeeklyHours />
                          <div className="tw-mt-4 tw-flex tw-justify-end">
                            <ButtonPrimary onClick={() => saveCalendar()}>
                              {t('button.save')}
                            </ButtonPrimary>
                          </div>
                        </div>
                      </div>
                    )}
                  </Paper>
                </div>
                <div className="tw-col-span-1 ">
                  <Paper p={3}>
                    <UnavailableDates channelId={channel?.id} />
                  </Paper>
                </div>
              </div>
            </CalendarsContext.Provider>
          </div>
        )}

        <ModalFormCalendar
          close={() => setModalForm({})}
          open={!!modalForm?.open}
          item={modalForm?.item}
          channel={channel}
          callback={(data) => callbackSave(data)}
        />
      </div>
    </Box>
  );
};

export default ManageCalendars;
