/* eslint-disable react/prop-types */
import React from 'react';
import Paper from 'core/ui/Paper';
import { useTranslation } from 'react-i18next';
import Typography from 'core/ui/Typography';
import SFDataTable from 'core/ui/DataTable';
import LabelPhone from 'core/ui/InputPhone/LabelPhone';
import { date } from 'core/utils/formatters';

export default function ResultUsers({ channel, users }) {
  const { t } = useTranslation();
  const columns = [
    {
      name: 'User Name',
      selector: 'userName',
      sortable: true,
      cell: (row) => <Typography>{row.userName}</Typography>,
    },
    {
      name: t('channel.memberships.column.phone'),
      selector: 'phone',
      sortable: true,
      width: '230px',
      cell: (member) => <>{member.phone && <LabelPhone value={member.phone} />}</>,
    },
    {
      name: t('channel.memberships.column.email'),
      selector: 'phone',
      sortable: true,
      width: '230px',
      cell: (member) => <Typography>{member.email}</Typography>,
    },
    {
      name: t('channel.memberships.column.birthday'),
      sortable: true,
      selector: 'dayMonth',
      cell: (row) => <Typography>{row?.dayMonth}</Typography>,
    },
    {
      name: 'Year',
      sortable: true,
      selector: 'year',
      cell: (row) => <Typography>{row?.year}</Typography>,
    },
  ];

  const rows =
    users &&
    users.map((ck) => ({
      id: ck.id,
      userName: ck.name,
      birthday: ck.birthday,
      email: ck.email,
      phone: ck.phone,
      dayMonth: date(ck.birthday, channel?.useUsDateFormat ? 'MM/DD' : 'DD/MM'),
      year: date(ck.birthday, 'YYYY'),
    }));

  return (
    <Paper style={{ width: '100%' }}>
      <SFDataTable
        title={t('channel.reports.birthday')}
        columns={columns}
        data={rows}
        pagination
        showExport
      />
    </Paper>
  );
}
