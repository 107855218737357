import React from 'react';
/* eslint-disable react/prop-types */
import { IconButton } from '@material-ui/core';
import { DeleteForever, Gavel, RemoveFromQueue, Score } from '@material-ui/icons';

const ActionsTeamWorkout = ({
  item,
  openModalScores,
  w,
  removeUserScore,
  removeUserVideo,
  setJudge,
}) => (
  <div className="tw-flex tw-flex-row tw-items-center tw-gap-2">
    <IconButton
      title="Scores"
      size="small"
      onClick={() =>
        openModalScores({
          workout: w,
          section: item.workouts[w.id].section,
          user: item.team,
        })
      }
    >
      <Score />
    </IconButton>
    {removeUserScore && !!item.workouts[w.id]?.leaderboard?.sectionUserId && (
      <IconButton
        title="Remove Scores"
        size="small"
        onClick={() =>
          removeUserScore(
            w.id,
            item.workouts[w.id]?.section?.id,
            item.workouts[w.id]?.leaderboard?.sectionUserId,
          )
        }
      >
        <DeleteForever />
      </IconButton>
    )}
    {removeUserVideo && item.workouts[w.id]?.leaderboard?.hasVideo && (
      <IconButton
        title="Remove Video"
        size="small"
        onClick={() => removeUserVideo(w?.id, item?.team?.id)}
      >
        <RemoveFromQueue />
      </IconButton>
    )}

    {setJudge && item.workouts[w.id]?.leaderboard && (
      <IconButton
        title="Remove Scores"
        size="small"
        onClick={() => setJudge({ workoutId: w.id, userId: item?.team?.id, open: true })}
      >
        <Gavel />
      </IconButton>
    )}
  </div>
);

export default ActionsTeamWorkout;
