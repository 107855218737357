import React, { useState } from 'react';
import Box from 'core/ui/Box';
import Typography, { fontStyle } from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import Grid from 'core/ui/Grid';
import BenchmarkOptions from 'components/Workout/Form/Section/BenchmarkOptions';
import Movements from 'components/Workout/Form/Movements';
import Equipments from 'components/Workout/Form/Equipments';
import Sections from 'components/Workout/Form/Section/Sections';
import Paper from 'core/ui/Paper';
import ModalFormSection from 'components/Workout/Form/Section/Modal';

const SectionsProgramming = ({
  workout,
  setValue,
  watch,
  sections,
  channelId,
  setSections,
  programs,
}) => {
  const { t } = useTranslation();
  const [modalSection, setModalSection] = useState();

  return (
    <>
      <ModalFormSection
        modalSection={modalSection}
        setModalSection={setModalSection}
        workout={workout}
        sections={sections}
        channelId={channelId}
        setSections={setSections}
        programs={programs}
      />

      <Grid container spacing={5}>
        <Grid item xs={12} sm={12} md={12}>
          <Box>
            <Paper className="paper-rounded" p={3} mb={3}>
              <BenchmarkOptions
                isCompetition={false}
                mode="new"
                setSections={setSections}
                setValue={setValue}
                sections={sections}
              />
            </Paper>
            <Sections
              setValue={setValue}
              sections={[...sections]}
              setSections={setSections}
              isScheduleSession={false}
              watch={watch}
              workout={workout}
              addNewSection={() => setModalSection({ open: true })}
              openEditSection={(sec) => setModalSection({ open: true, currentSection: sec })}
              partnerProgramming
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Box flexGrow="1" display="flex" mb={2} flexDirection="column">
            <Typography variant="h5" color="primary">
              {t('workout.stream.section.eqpMov')}
            </Typography>
            <Typography style={fontStyle.medium}>{t('input.tag.helper')}</Typography>
          </Box>

          <Movements
            setMovements={(values) => setValue('movements', values)}
            movements={watch('movements') || []}
            ownerableId={channelId}
            ownerableType="Channel"
          />

          <Box mt={3}>
            <Equipments
              setEquipment={(values) => setValue('equipment', values)}
              equipment={watch('equipment') || []}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default SectionsProgramming;

SectionsProgramming.defaultProps = {};
