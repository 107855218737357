import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from 'core/ui/Typography';
import Box from 'core/ui/Box';
import LoaderSm from 'core/ui/LoaderSm';
import SFDataTable from 'core/ui/DataTable';
import ImageMedia from 'components/Profile/ImageMedia';
import useSelectors from 'modules/map/useSelectors';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import Paper from 'core/ui/Paper';
import { InputWrapper } from 'core/ui/ChosenComponents';
import { ButtonPrimary, ButtonSecondary } from 'core/ui/Button';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import LabelPhone from 'core/ui/InputPhone/LabelPhone';
import { channelsEventsRegistrationsModule } from 'modules/channelsEventsRegistrations';
import { Dialog, DialogActions } from '@material-ui/core';
import DialogTitle from 'core/ui/Dialog/DialogTitle';

const ChannelsEventRegistrations = ({ event }) => {
  const { t } = useTranslation();

  const [modalRemove, setModalRemove] = useState({ open: false });
  const [users, setUsers] = useState([]);
  const [pagination, setPagination] = useState(null);
  const loading = useSelectors(channelsEventsRegistrationsModule, 'loadingUsers');
  const loadingForm = useSelectors(channelsEventsRegistrationsModule, 'loadingForm');
  const [perPage, setPerPage] = useState(10);
  const [query, setQuery] = useState('');
  const { request } = useActions();

  const search = (page = 1) => {
    request({
      action: Modules.channelsEventsRegistrations.actions.getRegistrations,
      data: {
        channelId: event?.channelId,
        channelsEventId: event?.id,
        page,
        perPage,
        query,
      },
      options: {
        onSuccess: (resp, pag) => {
          setUsers(resp);
          setPagination(pag);
        },
      },
    });
  };

  useEffect(() => {
    if (event && event.id) {
      search(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event, perPage]);

  const columns = [
    {
      name: '',
      selector: 'name',
      cell: (member) => (
        <Box my={1}>
          <ImageMedia
            user={member}
            image={member?.user?.image || {}}
            size={50}
            heightCountry={15}
            sizeCountry="1em"
          />
        </Box>
      ),
      sortable: true,
      width: '60px',
    },
    {
      name: 'User',
      selector: 'name',
      cell: (member) => (
        <Box>
          <Typography>{member?.user?.name}</Typography>
        </Box>
      ),
      sortable: true,
    },
    {
      name: 'Email',
      selector: 'email',
      cell: (member) => <Typography>{member?.user?.email}</Typography>,
      sortable: true,
    },
    {
      name: 'Phone',
      selector: 'phone',
      cell: (member) => (
        <Typography component="div">
          {member?.user?.phone && <LabelPhone value={member?.user?.phone} />}
        </Typography>
      ),
      sortable: true,
    },
    {
      cell: (member) => (
        <div>
          <ButtonPrimary onClick={() => setModalRemove({ open: true, reg: member })}>
            {t('button.remove')}
          </ButtonPrimary>
        </div>
      ),
      sortable: true,
    },
  ];

  const confirmRemove = (keepActive = false) => {
    request({
      action: Modules.channelsEventsRegistrations.actions.destroy,
      data: {
        channelId: event?.channelId,
        channelsEventId: event?.id,
        id: modalRemove?.reg?.id,
        keepActive,
      },
      options: {
        onSuccess: () => {
          setModalRemove({});
          search(pagination?.currentPage || 1);
        },
      },
    });
  };

  return (
    <Paper className="paper-rounded opacity-80" p={3}>
      <Box mb={3}>
        <Box display="flex" alignItems="center">
          <InputWrapper>
            <Box display="flex" style={{ width: '100%' }}>
              <TextFieldRounded
                value={query}
                onChange={(value) => setQuery(value)}
                style={{ minWidth: 30, width: '100%', margin: '4px' }}
                placeholder={t('channel.members.search')}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    search();
                  }
                }}
              />
            </Box>
          </InputWrapper>
          <Box display="flex" justifyContent="center">
            <LoaderSm loading={loading} />
          </Box>
          <Box ml={1}>
            <ButtonPrimary disabled={loading} onClick={() => search()}>
              {t('button.search')}
            </ButtonPrimary>
          </Box>
        </Box>
      </Box>

      <Dialog
        open={!!modalRemove?.open}
        onClose={() => setModalRemove({})}
        maxWidth="sm"
        fullWidth
        scroll="body"
      >
        <DialogTitle onClose={() => setModalRemove({})}>
          <Typography className="tw-font-semibold">
            {t('event.registration', { eventName: event?.name })}
          </Typography>
        </DialogTitle>
        <div className="text-lg text-gray-600 tw-bg-gray-100 tw-p-5">
          {t(
            modalRemove?.reg?.paid
              ? 'confirm.remove.paid.registration'
              : 'confirm.remove.registration',
          )}

          <LoaderSm loading={loadingForm} center />
        </div>
        <DialogActions>
          <ButtonSecondary disabled={loadingForm} onClick={() => setModalRemove({})}>
            {t('button.close')}
          </ButtonSecondary>
          {modalRemove?.reg?.paid ? (
            <>
              <ButtonPrimary disabled={loadingForm} onClick={() => confirmRemove()}>
                {t('button.removeAndRefund')}
              </ButtonPrimary>
              <ButtonPrimary disabled={loadingForm} onClick={() => confirmRemove(true)}>
                {t('button.refundAndKeepRegistration')}
              </ButtonPrimary>
            </>
          ) : (
            <ButtonPrimary onClick={() => confirmRemove()}>{t('button.remove')}</ButtonPrimary>
          )}
        </DialogActions>
      </Dialog>

      <SFDataTable
        // noHeader
        // noTableHead
        title=""
        paginationRowsPerPageOptions={[10, 25, 50]}
        showPaper={false}
        columns={columns}
        paginationPerPage={perPage}
        data={users}
        pagination
        noDataComponent=""
        onChangePage={(p) => search(p)}
        paginationServer
        paginationDefaultPage={pagination?.currentPage}
        paginationTotalRows={pagination?.totalCount}
        onChangeRowsPerPage={(newValue) => {
          setPerPage(newValue);
        }}
      />
    </Paper>
  );
};

ChannelsEventRegistrations.defaultProps = {};

export default ChannelsEventRegistrations;
