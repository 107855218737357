import React from 'react';
import Box from 'core/ui/Box';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import { cardpointeBabkTypes } from 'core/utils/consts';
import Checkbox from 'core/ui/Checkbox';
import { useTranslation } from 'react-i18next';

export default ({ watch, setValue, errors, rootKey, showCopy }) => {
  const { t } = useTranslation();
  const error = (field) => {
    const keys = rootKey.split('.');
    let er = errors;

    keys.forEach((item) => {
      er = er && er[item];
    });

    return er && er[field];
  };

  return (
    <>
      {showCopy && (
        <Box mb={2}>
          <Checkbox
            checked={watch(`sameDepositBank`)}
            onClick={() => setValue(`sameDepositBank`, !watch(`sameDepositBank`))}
            label={t('form.address.label.sameDepositBank')}
          />
        </Box>
      )}
      <Box mb={4}>
        <TextFieldRounded
          value={`${watch(`${rootKey}.bankName`) || ''}`}
          onChange={(v) => setValue(`${rootKey}.bankName`, v)}
          error={error('bankName')}
          placeholder="Bank Acct Num"
          label="Bank Acct Num"
          required
          bordered
        />
      </Box>

      <Box mb={4}>
        <TextFieldRounded
          value={`${watch(`${rootKey}.bankAcctNum`) || ''}`}
          onChange={(v) => setValue(`${rootKey}.bankAcctNum`, v)}
          error={error('bankAcctNum')}
          placeholder="Bank Acct Num"
          label="Bank Acct Num"
          required
          bordered
        />
      </Box>

      <Box mb={4}>
        <TextFieldRounded
          value={`${watch(`${rootKey}.bankRoutingNum`) || ''}`}
          onChange={(v) => setValue(`${rootKey}.bankRoutingNum`, v)}
          error={error('bankRoutingNum')}
          placeholder="Bank Routing Num"
          label="Bank Routing Num"
          required
          bordered
        />
      </Box>

      <Box>
        <SelectBoxObject
          options={cardpointeBabkTypes}
          propValue="code"
          label="The name you file taxes under"
          value={`${watch(`${rootKey}.bankAcctTypeCd`) || ''}`}
          setValue={(v) => setValue(`${rootKey}.bankAcctTypeCd`, v)}
          error={error('bankAcctTypeCd')}
          required
          bordered
        />
      </Box>
    </>
  );
};
