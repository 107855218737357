/* eslint-disable react/prop-types */
import React from 'react';
import {
  makeStyles,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
  withStyles,
} from '@material-ui/core';
import { secondary } from 'core/ui/Colors';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import Typography from 'core/ui/Typography';

import { ArrowDropDown } from '@material-ui/icons';
import TableResultsAffiliatesRow from './TableResultsAffiliatesRow';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },

  tableContainer: {},
  cell: {
    minWidth: '100px',
  },
  boxCelMin: {
    width: 60,
    display: 'flex',
    justifyContent: 'center',
  },
}));

const StickyTableCell = withStyles(() => ({
  head: {
    backgroundColor: secondary,
    color: 'white',
    left: 0,
    position: 'sticky',
    zIndex: 1090,
    borderRadius: 0,
  },
}))(TableCell);

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: secondary,
    color: 'white',
    borderRadius: 0,
  },
  body: {
    backgroundColor: 'white',
    fontSize: 14,
  },
}))(TableCell);

export default ({
  competition,
  competitionResults,
  openModalScores,
  setOpenTeamEdit,
  removeUser,
  compWorkouts,
  orderResult,
  lastOrder,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div>
      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader size="small" aria-label="sticky table">
          <TableHead>
            <TableRow>
              <StickyTableCell className={classes.head}>
                <Box display="flex">
                  <Box className={classes.boxCelMin}>{t('competition.workout.rank')}</Box>
                  <Box flexGrow="1">{t('competition.affiliateGym')}</Box>
                  <Box className={classes.boxCelMin} />
                  <Box
                    className={`${classes.boxCelMin} hover`}
                    onClick={() => orderResult('points', null)}
                  >
                    {t('competition.workout.points')}
                    {!lastOrder && <ArrowDropDown />}
                  </Box>
                </Box>
              </StickyTableCell>

              {compWorkouts.map((w) => (
                <StyledTableCell key={w.id} align="center">
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    className="hover"
                    onClick={() => orderResult('workout', w.id)}
                  >
                    <Typography>{w.name}</Typography>
                    {lastOrder === w.id && <ArrowDropDown />}
                  </Box>
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {competitionResults.map((item, index) => (
              <React.Fragment key={index.toString()}>
                <TableResultsAffiliatesRow
                  item={item}
                  indexRow={index}
                  competition={competition}
                  openModalScores={openModalScores}
                  position={item.position}
                  setOpenTeamEdit={setOpenTeamEdit}
                  removeUser={removeUser}
                  compWorkouts={compWorkouts}
                />
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
