import React from 'react';
import Box from 'core/ui/Box';
import FormAch from 'components/Account/Form/Ach';

const StripeConnect = () => (
  <Box>
    <FormAch userId={1} />
  </Box>
);

export default StripeConnect;
