import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Paper from 'core/ui/Paper';
import Typography, { fontStyle } from 'core/ui/Typography';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import { primary, secondaryLight } from 'core/ui/Colors';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { ButtonPrimarySm } from 'core/ui/Button';
import CollapseText from 'core/ui/CollapseText';
import { channelCurrency } from 'core/utils/formatters';
import { linkStoreItem, textStoreOptions } from 'core/utils/helpers';
import ItemQty from 'components/Channel/Show/Tabs/Store/ShoppingCart/ItemQty';
import { QRCodeCanvas } from 'qrcode.react';
import ModalQrCodeDownload from 'core/ui/ModalQrCodeDownload';
import ImageProduct from './Image';
import ProductOption from './Form/ProductOption';

const useStyles = makeStyles({
  cardProduct: {
    backgroundColor: '#fff',
    borderRadius: 10,
    minHeight: 315,
    overflow: 'hidden',
    position: 'relative',
  },
  boxImage: {
    overflow: 'hidden',
    height: 180,
  },
  preSaleTag: {
    backgroundColor: primary,
    position: 'absolute',
    right: 4,
    top: 4,
    padding: 2,
    borderRadius: 5,
  },
  boxOption: {
    padding: 3,
    height: 48,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 5,
    border: `0.3px solid ${secondaryLight}`,
  },
});

const CardProduct = ({
  currency,
  product,
  buyClick,
  shoppingCart,
  isCoach,
  buttonColor,
  showDescription,
  channelId,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [openOptions, setOpenOptions] = useState(false);
  const [selectOption, setSelectOption] = useState({});
  const anchorEl = useRef();
  const [modalQr, setModalQr] = useState();

  useEffect(() => {
    setSelectOption(
      product.inventories.find((x) => x.qty > 0 || x.qtyUnlimited) || product.inventories[0] || {},
    );
  }, [product]);

  const shoppingCartItem = shoppingCart?.item(product, selectOption);

  return (
    <Paper className={classes.cardProduct}>
      {modalQr?.open && (
        <ModalQrCodeDownload
          open={modalQr?.open}
          close={() => setModalQr({ opem: false })}
          text={modalQr?.text}
          title={modalQr?.title}
        />
      )}
      <Box px={3} pb={5}>
        <Box
          style={{ position: 'absolute', right: 5, top: 5 }}
          className="hover"
          onClick={() =>
            setModalQr({
              open: true,
              text: linkStoreItem(channelId, product?.id),
              title: product.name,
            })
          }
        >
          <QRCodeCanvas
            style={{ width: 30, height: 30 }}
            value={linkStoreItem(channelId, product?.id)}
          />
        </Box>
        <Box
          flexGrow={1}
          className={classes.boxImage}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <ImageProduct images={product.images || []} size={140} />
        </Box>

        {product?.preSaleData && (
          <Box className={classes.preSaleTag}>
            <Typography style={{ color: 'white', ...fontStyle.medium }}>PRE-SALE</Typography>
          </Box>
        )}

        <Box flexGrow={1} mt={2} style={{ height: showDescription ? 'auto' : 20 }}>
          <Typography color="secondary" variant="body2">
            {product.name}
          </Typography>
          {showDescription && (
            <Typography
              component="div"
              color="secondary"
              className="roboto-regular"
              style={fontStyle.medium}
            >
              <CollapseText text={product.description} maxChar={130} linkColor={buttonColor} />
            </Typography>
          )}
        </Box>
      </Box>

      <Box px={3} py={1} style={{ height: 120 }}>
        <Box mt={3}>
          <Box>
            <Box
              ref={anchorEl}
              className={classes.boxOption}
              onClick={() => setOpenOptions(true)}
              flexGrow={1}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              {product.inventories && product.inventories.length > 1 && (
                <Box mr={1}>
                  <IconButton style={{ padding: 3 }}>
                    <KeyboardArrowDownIcon style={{ fontSize: 18 }} />
                  </IconButton>
                </Box>
              )}
              {(selectOption?.optionFirst?.id ||
                selectOption.kind ||
                !selectOption.qtyUnlimited) && (
                <Box flexGrow={1}>
                  <Typography style={{ ...fontStyle.small, color: buttonColor }}>
                    {textStoreOptions(selectOption)}
                  </Typography>
                  {!selectOption.qtyUnlimited && (
                    <Box display="flex">
                      <Box flexGrow={1}>
                        <Typography style={fontStyle.small} color="secondary">
                          {selectOption.qty}&nbsp;{t('store.card.leftInStock')}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </Box>
              )}
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <Typography style={{ ...fontStyle.large, color: buttonColor }}>
                  {channelCurrency(selectOption.price, currency)}
                </Typography>
              </Box>
            </Box>

            {(isCoach || !product?.noMemberPurchase) && (
              <Box mt={2} display="flex" justifyContent="center">
                {shoppingCart?.existsInCart(product, selectOption) ? (
                  <Box>
                    <ItemQty
                      changeQty={(v) =>
                        shoppingCart?.addQtyItem(product, selectOption, shoppingCartItem, v)
                      }
                      qty={shoppingCartItem?.qty}
                    />
                  </Box>
                ) : (
                  <ButtonPrimarySm
                    style={{ height: 38, padding: 2 }}
                    onClick={() => buyClick(product, selectOption)}
                    buttonColor={buttonColor}
                    fullWidth
                    fontSize={10}
                  >
                    &nbsp;{t('button.addShoppingCart').toUpperCase()}&nbsp;
                  </ButtonPrimarySm>
                )}
              </Box>
            )}
          </Box>
        </Box>

        <Menu
          anchorEl={anchorEl.current}
          keepMounted
          open={openOptions}
          onClose={() => setOpenOptions(false)}
        >
          {product.inventories &&
            product.inventories
              .filter((x) => x.qty > 0 || x.qtyUnlimited)
              .map((stock, index) => (
                <MenuItem
                  key={index.toString()}
                  onClick={() => {
                    setSelectOption(stock);
                    setOpenOptions(false);
                  }}
                >
                  <Box flexGrow={1}>
                    <ProductOption option={stock} />
                    {!stock.qtyUnlimited && (
                      <Box display="flex">
                        <Box flexGrow={1}>
                          <Typography style={fontStyle.small} color="secondary">
                            {stock.qty}&nbsp;{t('store.card.leftInStock')}
                          </Typography>
                        </Box>
                      </Box>
                    )}
                  </Box>
                  <Box ml={5}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Typography style={{ ...fontStyle.large, color: buttonColor }}>
                        {channelCurrency(stock.price, currency)}
                      </Typography>
                    </Box>
                  </Box>
                </MenuItem>
              ))}
        </Menu>
      </Box>
    </Paper>
  );
};

export default CardProduct;

CardProduct.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  product: PropTypes.object,
  buyClick: PropTypes.func,
  isCoach: PropTypes.bool,
};

CardProduct.defaultProps = {
  product: {},
  buyClick: () => {},
  isCoach: false,
};
