import React from 'react';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import Paper from 'core/ui/Paper';
import Typography, { fontStyle } from 'core/ui/Typography';
import LoaderSm from 'core/ui/LoaderSm';
import { ButtonPrimary } from 'core/ui/Button';
import LabelBreakLine from 'core/ui/LabelBreakLine';
import Divider from 'core/ui/Divider';
import Timer from 'components/Workout/Timer';
import { Timer as TimerIcon } from '@material-ui/icons';
import { benchmarkSectionsModule } from 'modules/benchmarkSections';
import useSelectors from 'modules/map/useSelectors';
import BenchmarkUserScores from './BenchmarkUserScores';

const BenchmarkInfo = ({ selectedBenchmark, addNewBenchmark, formClass }) => {
  const { t } = useTranslation();
  const loading = useSelectors(benchmarkSectionsModule, 'loading');

  return (
    <Box>
      <Paper className="paper-rounded" p={3}>
        <Typography variant="h4" color="secondary">
          {selectedBenchmark.name}
        </Typography>
        <Typography variant="h6" color="secondary">
          {selectedBenchmark.category && selectedBenchmark.category.name}
        </Typography>
        <Typography my={3} className="roboto-regular" variant="body2">
          <LabelBreakLine>{selectedBenchmark.description}</LabelBreakLine>
        </Typography>

        {selectedBenchmark.timer && (
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Box display="flex" alignItems="center" mb={1}>
              <TimerIcon size="small" />
              <Typography mt={1} mr={1} variant="body2">
                {selectedBenchmark.timer &&
                  t(`timer.type.${selectedBenchmark.timer.type}`).toUpperCase()}
              </Typography>
              &bull;
              <Typography ml={1} mt={1} variant="body2">
                {t(
                  selectedBenchmark.timer.count.toUpperCase() === 'UP'
                    ? 'timer.countUp'
                    : 'timer.countdown',
                ).toUpperCase()}
              </Typography>
              <Typography ml={1} mt={1} variant="body2" color="primary">
                &nbsp;&bull;&nbsp;{selectedBenchmark.timer.work}
              </Typography>
            </Box>

            {!formClass && (
              <Timer
                section={{
                  title: '',
                  timerData: selectedBenchmark.timer,
                }}
                colorLabel="white"
                preview
              />
            )}
          </Box>
        )}

        {loading && (
          <Box display="flex" justifyContent="center" alignItems="center" m={5}>
            <LoaderSm loading={loading} />
          </Box>
        )}

        <Divider mx={5} my={5} />

        {!formClass && selectedBenchmark.bestBenchmark && (
          <Box display="flex" justifyContent="center" m={5}>
            <BenchmarkUserScores
              scores={selectedBenchmark.bestBenchmark?.scores}
              fontValue={fontStyle.xxLarge}
              fontScore={fontStyle.large}
            />
          </Box>
        )}

        {!formClass && (
          <ButtonPrimary onClick={() => addNewBenchmark()} fullWidth>
            {t('button.addScore').toUpperCase()}
          </ButtonPrimary>
        )}
      </Paper>
    </Box>
  );
};

export default BenchmarkInfo;
