import api from 'core/api';

export const channelTemplatesModule = 'channelTemplates';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const actions = {
  getTemplates: {
    module: channelTemplatesModule,
    name: 'getTemplates',
    api: ({ channelId }) => api.get(`/channels/${channelId}/templates`),
    params: {
      start: ['params'],
      error: [''],
      success: ['templates'],
    },
    state: loadingStates,
  },
};

export default {
  actions,
  state: {
    loading: false,
    templates: [],
  },
};
