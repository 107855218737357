import React, { useEffect, useRef, useState } from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import Paper from 'core/ui/Paper';
import Typography, { fontStyle } from 'core/ui/Typography';
import { ButtonPrimary } from 'core/ui/Button';
import { useSelector, useDispatch } from 'react-redux';
import DialogTitle from 'core/ui/Dialog/DialogTitle';
import LoaderSm from 'core/ui/LoaderSm';
import Texture from 'core/ui/Texture';
import SelectorPaymentMethod, { PaymentMethodViewType } from 'components/Account/Selector';
import { paymentsActions, paymentsSelectors } from 'modules/payments';
import { date, timeZoneName } from 'core/utils/formatters/date';
import { Check, NavigateBefore, Warning } from '@material-ui/icons';
import { primary } from 'core/ui/Colors';
import { IconButton, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import { channelsActions, channelsSelectors } from 'modules/channels';
import { sessionSelectors } from 'modules/session';
import useActions from 'modules/map/useActions';
import { userChildrenModule } from 'modules/userChildren';
import useSelectors from 'modules/map/useSelectors';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import Modules from 'modules';
import { channelCurrency } from 'core/utils/formatters';
import Form from 'components/Login/Form';
import QuickForm from 'components/Profile/QuickForm';
import ChannelContracts from 'components/Channel/ChannelContracts';
import TicketIcon from 'core/ui/FontIcon/TicketIcon';
import Divider from 'core/ui/Divider';
import ModalChannelProfileSelector from 'components/Channel/ModalChannelProfileSelector';
import useIsXs from 'core/utils/hook/useIsXs';
import Checkbox from 'core/ui/Checkbox';
import CardPlan from './CardPlan';
import ModalCalendarSchedule from './ModalCalendarSchedule';

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function ModalPayment({
  open,
  close,
  membershipPlan,
  callback,
  buttonColor,
  couponCode,
}) {
  const isAuthenticated = useSelector((state) => sessionSelectors.getIsAuthenticated(state));
  const currentUser = useSelector((state) => sessionSelectors.getUser(state));
  const requiresContract = !!membershipPlan?.documentContractId;
  const requiresQuestionnaire = !!membershipPlan?.documentQuestionnaireId;

  const [membershipContractOk, setMembershipContractOk] = useState(false);
  const [waiverOk, setWaiver] = useState(false);
  const [questContractOk, setQuestContractOk] = useState(false);

  const channel = membershipPlan?.channel;

  if (!isAuthenticated) {
    return (
      <Dialog open maxWidth="sm" fullWidth onClose={() => close()}>
        <Texture>
          <Box p={2}>
            <Form fromModal channelId={channel?.id} />
          </Box>
        </Texture>
      </Dialog>
    );
  }

  if (isAuthenticated && !currentUser?.name) {
    return (
      <Dialog open maxWidth="sm" fullWidth>
        <Texture>
          <Box px={4}>
            <QuickForm buttonColor={buttonColor} fastForm />
          </Box>
        </Texture>
      </Dialog>
    );
  }

  if (isAuthenticated && !currentUser?.channel?.id) {
    return <ModalChannelProfileSelector close={close} noRedirect />;
  }

  if (!!channel?.documentWaiverId && !waiverOk) {
    return (
      <ChannelContracts
        channel={channel}
        userId={currentUser.id}
        ownerableType="Channel"
        ownerableId={channel?.id}
        kind="waivers"
        setAccepted={setWaiver}
        documentId={channel.documentWaiverId}
        onClose={close}
      />
    );
  }

  if (!!requiresContract && !membershipContractOk) {
    return (
      <ChannelContracts
        channel={channel}
        userId={currentUser.id}
        ownerableType="Channel"
        ownerableId={channel?.id}
        kind="membership"
        objectId={membershipPlan?.id}
        objectType="ChannelMembershipPlan"
        setAccepted={setMembershipContractOk}
        documentId={membershipPlan.documentContract?.id}
        onClose={close}
      />
    );
  }

  if (!!requiresQuestionnaire && !questContractOk) {
    return (
      <ChannelContracts
        channel={channel}
        userId={currentUser.id}
        ownerableType="Channel"
        ownerableId={channel?.id}
        kind="questionnaire"
        objectId={membershipPlan?.id}
        objectType="ChannelMembershipPlan"
        setAccepted={setQuestContractOk}
        documentId={membershipPlan.documentQuestionnaire?.id}
        onClose={close}
      />
    );
  }

  return (
    <ModalContent
      close={close}
      open={open}
      channel={channel}
      membershipPlan={membershipPlan}
      callback={callback}
      couponCode={couponCode}
      buttonColor={buttonColor}
    />
  );
}

const ModalContent = ({
  close,
  membershipPlan,
  open,
  channel,
  callback,
  couponCode,
  buttonColor,
}) => {
  const loadingPurchaseChannelKey = useSelector((state) =>
    paymentsSelectors.getLoadingPurchaseChannelKey(state),
  );
  const [postPayment, setPostPayment] = useState(false);
  const currentUser = useSelector((state) => sessionSelectors.getUser(state));
  const { request } = useActions();
  const { t } = useTranslation();
  const isAuthenticated = useSelector((state) => sessionSelectors.getIsAuthenticated(state));
  const isXs = useIsXs();
  const [openModalCalendar, setOpenModalCalendar] = useState(false);
  const dataChildren = useSelectors(userChildrenModule, 'data');
  const dispatch = useDispatch();
  const [errors, setErrors] = useState([]);
  const [textCoupon, setTextCoupon] = useState('');
  const [childId, setChildId] = useState(currentUser.id);
  const [coupon, setCoupon] = useState(null);
  const [viewMethodPayment, setViewMethodPayment] = useState(PaymentMethodViewType.list);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const loadingDiscountCode = useSelector((state) =>
    channelsSelectors.getLoadingDiscountCode(state),
  );
  const [success, setSuccess] = useState(false);

  const purchasedRef = useRef();

  const isFreePlan = Number(membershipPlan.introductoryCost) === 0;

  useEffect(() => {
    request({ action: Modules.userChildren.actions.getChildren });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (membershipPlan.allowPostPayment) {
      setPostPayment(membershipPlan.defaultValueForPostPayment);
    } else {
      setPostPayment(false);
    }
  }, [membershipPlan.allowPostPayment, membershipPlan.defaultValueForPostPayment]);

  const validate = () => {
    const vErrors = [];
    if (!postPayment && !isFreePlan && !selectedPaymentMethod) {
      vErrors.push(t('channel.modalPayment.validation.selectAccount'));
    }

    if (vErrors.length > 0) {
      setErrors(vErrors);
      setTimeout(() => {
        setErrors([]);
      }, 5000);
      return false;
    }

    return true;
  };

  const purchase = () => {
    if (!validate()) {
      return;
    }

    dispatch(
      paymentsActions.purchaseChannelKeyStart({
        userPaymentMethodId: !postPayment && !isFreePlan ? selectedPaymentMethod.id : null,
        channelId: channel.id,
        membershipPlanId: membershipPlan.id,
        postPayment: membershipPlan.allowPostPayment && postPayment,
        childId: childId && childId !== currentUser.id ? childId : null,
        code: coupon ? coupon.code : null,
        timezone: timeZoneName(),
        callback: (resp) => {
          // dispatch(notifications.success(t('channel.membership.purchase.success')));
          if (callback) {
            callback(resp);
          }
          // resp?.firstMembership
          setSuccess(true);
        },
      }),
    );
  };

  useEffect(() => {
    if (
      purchasedRef.current !== 1 &&
      open &&
      membershipPlan?.id &&
      membershipPlan?.isFree &&
      isAuthenticated &&
      !!currentUser?.name
    ) {
      purchasedRef.current = 1;
      purchase();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, membershipPlan]);

  const applyCode = (code = null) => {
    if (code) {
      setTextCoupon(code);
    }
    const cCode = code || textCoupon;
    if (!cCode) {
      return;
    }
    dispatch(
      channelsActions.applyMembershipDiscountCodeStart({
        channelId: channel.id,
        membershipPlanId: membershipPlan.id,
        code: cCode,
        callback: (data) => {
          if (data) {
            setCoupon(data);
          } else {
            setCoupon(null);
          }
        },
      }),
    );
  };

  useEffect(() => {
    if (couponCode) {
      applyCode(couponCode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [couponCode]);

  const clearCoupon = () => {
    setTextCoupon('');
    setCoupon(null);
  };

  return (
    <Dialog
      scroll={isXs ? 'paper' : 'body'}
      fullScreen={isXs}
      fullWidth
      maxWidth="sm"
      onClose={() => close()}
      open={!!open}
    >
      <>
        <DialogTitle className="tw-bg-zinc-100" onClose={close}>
          <div className="tw-flex tw-flex-row tw-items-start tw-text-lg">
            {t('channel.modalPayment.paymentInformation')}
          </div>
        </DialogTitle>
        <DialogContent className="tw-bg-gray-50" dividers>
          {success ? (
            <div>
              <Paper p={2}>
                <Typography align="center" className="tw-text-lg tw-text-green-600" p={2}>
                  <div className="tw-flex tw-flex-row tw-items-center tw-justify-center">
                    <Check />
                    {t('membership.purchase.success')}
                  </div>
                </Typography>

                <div className="tw-mt-2 tw-flex tw-flex-row tw-items-center tw-justify-center">
                  <ButtonPrimary onClick={() => setOpenModalCalendar(true)}>
                    {t('button.channelCalendar')}
                  </ButtonPrimary>
                </div>

                {openModalCalendar && currentUser?.existsPin && (
                  <ModalCalendarSchedule
                    channel={channel}
                    open={openModalCalendar}
                    close={() => setOpenModalCalendar(false)}
                  />
                )}
              </Paper>
            </div>
          ) : (
            <>
              <Box mx={5}>
                {dataChildren && dataChildren.length > 0 && (
                  <Box mb={1}>
                    <SelectBoxObject
                      options={[{ id: currentUser.id, name: 'Myself' }, ...dataChildren]}
                      propLabel="name"
                      propValue="id"
                      placeholder={t('channel.membership.selectChildren')}
                      value={childId}
                      setValue={(v) => setChildId(v)}
                    />
                  </Box>
                )}

                <CardPlan plan={membershipPlan} channel={channel} modal />
                <Paper className="paper-rounded" mx={2} mt={2} p={3}>
                  {membershipPlan.renewAt && (
                    <Table size="small">
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <Typography color="textSecondary" variant="subtitle1" component="span">
                              {t(
                                membershipPlan.renewable
                                  ? 'channel.plan.autoRenewDate'
                                  : 'channel.memberships.expiresOn',
                              )}
                              :
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography ml={1} variant="subtitle2" component="span">
                              {date(membershipPlan.renewAt)}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  )}

                  {membershipPlan.limitPurchases && (
                    <>
                      <Box mt={1} display="flex" justifyContent="center" alignItems="center">
                        <TicketIcon />
                        <Typography style={fontStyle.large} noWrap align="center">
                          {membershipPlan?.limitPurchaseUsage || 0}/
                          {membershipPlan.limitPurchasesMax}
                        </Typography>
                      </Box>
                      <Divider m={1} />
                    </>
                  )}

                  <Box display="flex" mt={4} justifyContent="center">
                    <Typography
                      align="center"
                      variant="h2"
                      component="span"
                      style={{ color: buttonColor }}
                    >
                      {channelCurrency(
                        !coupon ? membershipPlan.introductoryCost : coupon.newIntroductoryCost,
                        channel?.currency,
                      )}
                    </Typography>
                  </Box>
                </Paper>
              </Box>

              {membershipPlan?.allowPostPayment && (
                <div className="tw-mt-4 tw-flex tw-justify-center">
                  <Checkbox
                    label={t('label.postPayment')}
                    checked={postPayment}
                    onClick={() => setPostPayment(!postPayment)}
                  />
                </div>
              )}

              {!isFreePlan && (
                <Box mx={5} mt={2}>
                  <Paper className="paper-rounded" mx={2} p={3}>
                    <Typography align="center">{t('channel.membership.discountCode')}</Typography>
                    <Box display="flex" mt={2}>
                      <TextFieldRounded
                        placeholder={t('channel.membership.code.placeholder')}
                        value={textCoupon}
                        onChange={(v) => setTextCoupon(v)}
                        disabled={!!coupon}
                      />
                      <ButtonPrimary
                        onClick={() => (!coupon ? applyCode() : clearCoupon())}
                        style={{ width: 100 }}
                        buttonColor={buttonColor}
                      >
                        {loadingDiscountCode ? (
                          <LoaderSm loading width={25} />
                        ) : (
                          <>{t(!coupon ? 'button.apply' : 'button.clear')}</>
                        )}
                      </ButtonPrimary>
                    </Box>
                    {coupon && (
                      <Box mt={3}>
                        <Typography variant="caption" align="center" color="textSecondary">
                          {t(
                            coupon.renewable
                              ? 'channel.membership.code.monthly'
                              : 'channel.membership.code.onetime',
                          )}
                        </Typography>
                      </Box>
                    )}
                  </Paper>
                </Box>
              )}

              {!postPayment && !isFreePlan && (
                <Box mx={5} my={2}>
                  {viewMethodPayment === PaymentMethodViewType.form && (
                    <Box display="flex" alignItems="center">
                      <Box>
                        <IconButton
                          onClick={() => setViewMethodPayment(PaymentMethodViewType.list)}
                        >
                          <NavigateBefore fontSize="large" />
                        </IconButton>
                      </Box>
                      <Box display="flex" flexGrow={1} justifyContent="center">
                        <Typography mt={3} mb={3} align="center" color="textSecondary" variant="h4">
                          {t('payment.method.save')}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                  <SelectorPaymentMethod
                    selectedPaymentMethod={selectedPaymentMethod}
                    setSelectedPaymentMethod={setSelectedPaymentMethod}
                    view={viewMethodPayment}
                    setView={setViewMethodPayment}
                    userId={currentUser.id}
                    onlyMethod={channel.allowAchPayments ? null : 'card'}
                    buttonColor={buttonColor}
                    userInfo={{
                      name: currentUser?.name,
                      phone: currentUser?.phone,
                      email: currentUser?.email,
                    }}
                    showCanadaDebit={currentUser?.showCanadaDebit}
                    cardpointe={channel?.useCardpointe}
                  />
                </Box>
              )}
              {errors.length > 0 && (
                <Box m={5} display="flex" flexDirection="column" justifyContent="center">
                  {errors.map((error) => (
                    <Typography key={error} color="textSecondary" variant="body2">
                      <Warning style={{ fontSize: 16, color: primary }} /> {error}
                    </Typography>
                  ))}
                </Box>
              )}

              {viewMethodPayment !== PaymentMethodViewType.form && (
                <Box display="flex" px={5} mt={5} justifyContent="flex-end">
                  <ButtonPrimary
                    disabled={loadingPurchaseChannelKey}
                    onClick={() => purchase()}
                    type="submit"
                    fullWidth
                    buttonColor={buttonColor}
                  >
                    <Box display="flex" alignItems="center">
                      <LoaderSm loading={loadingPurchaseChannelKey} width={20} />
                      <Typography ml={1} component="span">
                        {t('purchase')}
                      </Typography>
                    </Box>
                  </ButtonPrimary>
                </Box>
              )}
            </>
          )}
        </DialogContent>
      </>
    </Dialog>
  );
};

ModalPayment.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  membershipPlan: propTypes.object.isRequired,
  open: propTypes.bool,
  close: propTypes.func,
  callback: propTypes.func,
};

ModalPayment.defaultProps = {
  open: false,
  close: null,
  callback: () => {},
};
