import React, { useContext, useEffect, useState } from 'react';
import Box from 'core/ui/Box';
import useActions from 'modules/map/useActions';
import { useTranslation } from 'react-i18next';
import Modules from 'modules';
import { showAPIError } from 'core/utils/showAPIError';
import { ButtonPrimary } from 'core/ui/Button';
import useConfirmation from 'core/useConfirmation';
import { CompetitionContext } from '..';
import ModalFormTemplate from './ModalFormTemplate';
import ScoringTable from './ScoringTable';

const CompetitionCustomScoringTable = () => {
  const { t } = useTranslation();
  const formContext = useContext(CompetitionContext);
  const { confirmation } = useConfirmation();
  const [modalForm, setModalForm] = useState({ open: false, item: null });
  const [customScorings, setCustomScorings] = useState([]);
  const { request } = useActions();

  const getData = () => {
    request({
      action: Modules.competitionsCustomScorings.actions.getCustomScorings,
      data: {
        competitionId: formContext?.competition?.id,
      },
      options: {
        onSuccess: setCustomScorings,
        onError: showAPIError,
      },
    });
  };

  useEffect(() => {
    if (formContext?.competition?.id) {
      getData();
    }
  }, [formContext?.competition?.id]);

  const remove = (item) => {
    confirmation({
      description: t('areYouSure'),
      yesClick: () => {
        request({
          action: Modules.competitionsCustomScorings.actions.delete,
          data: {
            competitionId: formContext?.competition?.id,
            id: item?.id,
          },
          options: {
            onSuccess: getData,
            onError: showAPIError,
          },
        });
      },
    });
  };

  return (
    <Box>
      <div className="tw-mb-2">
        <ButtonPrimary onClick={() => setModalForm({ open: true })}>
          {t('button.createTemplate')}
        </ButtonPrimary>
      </div>

      <div className="tw-grid tw-grid-cols-1 tw-gap-2 md:tw-grid-cols-4 lg:tw-grid-cols-6">
        {customScorings?.map((item) => (
          <ScoringTable
            key={item?.id}
            remove={() => remove(item)}
            scoringTemplate={item}
            setModalForm={setModalForm}
          />
        ))}
      </div>

      {modalForm?.open && (
        <ModalFormTemplate
          open={modalForm?.open}
          close={() => setModalForm({})}
          callback={() => {
            getData();
            setModalForm({});
          }}
          item={modalForm?.item}
        />
      )}
    </Box>
  );
};

export default CompetitionCustomScoringTable;
