import api from 'core/api';

export const channelUsersModule = 'channelUsers';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const actions = {
  addUser: {
    module: channelUsersModule,
    name: 'addUser',
    api: (params) => api.post(`/channels/${params.channelId}/invite_coach`, params),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  changeCoachInfo: {
    module: channelUsersModule,
    name: 'changeCoachInfo',
    api: (params) => api.put(`/channels/${params?.channelId}/change_access_type`, params),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  removeUser: {
    module: channelUsersModule,
    name: 'removeUser',
    api: (params) => api.delete(`/channels/${params.channelId}/remove_coach/${params.userId}`),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },

  setChannelMemberApp: {
    module: channelUsersModule,
    name: 'setChannelMemberApp',
    api: ({ channelId }) => api.post('/users/set_channel_member_app', { channelId }),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
};

export default {
  actions,
  state: {
    loading: false,
    data: [],
  },
};
