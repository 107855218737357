import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import DatePickerRounded from 'core/ui/DatePickers/DatePickerRounded';
import { ButtonPrimary } from 'core/ui/Button';
import { GetApp, Search } from '@material-ui/icons';
import { formatDateToApi } from 'core/utils/formatters';
import { channelReportsModule } from 'modules/channelReports';
import useSelectors from 'modules/map/useSelectors';
import { timeZoneName } from 'core/utils/formatters/date';
import Paper from 'core/ui/Paper';
import { Tabs, Tab } from '@material-ui/core';
import ResultPayouts from './ResultPayouts';
import SkeletonPayamentsReport from './Skeleton';
import ResultCardpointeBatches from './ResultCardpointeBatches';
import CardResumes from '../Payments/CardResumes';

const Payouts = ({ loading, getReport, channel }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  const data = useSelectors(channelReportsModule, 'data');
  const dateNow = new Date();
  const [filters, setFilters] = useState({
    startAt: `${dateNow.getMonth() + 1}/1/${dateNow.getFullYear()}`,
    endAt: `${dateNow.getMonth() + 1}/${dateNow.getDate()}/${dateNow.getFullYear()}`,
  });

  const search = ({ exportReport = false } = {}) => {
    const startAt = filters.startAt ? formatDateToApi(filters.startAt, timeZoneName()) : null;
    const endAt = filters.endAt ? formatDateToApi(filters.endAt, timeZoneName()) : null;
    if (startAt && endAt)
      getReport({ startAt, endAt, exportReport, exportFileName: 'payouts.xls' });
  };

  useEffect(() => {
    search();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  return (
    <Box mt={2}>
      <Box>
        <Box display="flex" mb={3} alignItems="flex-end" justifyContent="center" flexWrap="wrap">
          <Box>
            <DatePickerRounded
              label={t('channel.reports.filter.start').toUpperCase()}
              onChange={(v) => setFilters((prev) => ({ ...prev, startAt: v }))}
              value={filters.startAt}
              disablePast={false}
              usFormat={channel?.useUsDateFormat}
              autoOk
            />
          </Box>
          <Box ml={1}>
            <DatePickerRounded
              label={t('channel.reports.filter.end').toUpperCase()}
              onChange={(v) => setFilters((prev) => ({ ...prev, endAt: v }))}
              value={filters.endAt}
              disablePast={false}
              usFormat={channel?.useUsDateFormat}
              autoOk
            />
          </Box>
          <Box ml={3}>
            <ButtonPrimary disabled={loading} onClick={() => search()}>
              <Search style={{ color: 'white' }} fontSize="small" />
              {t('button.search')}&nbsp;
            </ButtonPrimary>
          </Box>
          <Box ml={3} pt={5}>
            <ButtonPrimary
              disabled={loading}
              onClick={() => search({ page: 1, exportReport: true })}
            >
              <GetApp style={{ color: 'white' }} fontSize="small" />
              {t('button.exportCsv')}&nbsp;
            </ButtonPrimary>
          </Box>
        </Box>

        <Paper m={5} mx={20}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            {data?.showCardpointe && <Tab label="CardPointe" />}
            {data?.showStripe && <Tab label="Stripe" />}
          </Tabs>
        </Paper>

        {loading && <SkeletonPayamentsReport />}

        <Box mb={3}>
          <CardResumes data={data?.resume || {}} channel={channel} />
        </Box>

        {data?.showCardpointe && value === 0 && (
          <Box>
            <ResultCardpointeBatches
              rows={data?.cardpointsBatches || []}
              channel={channel}
              getReport={getReport}
            />
          </Box>
        )}
        {data?.showStripe && (value === 1 || (!data?.showCardpointe && value === 0)) && (
          <Box>
            <ResultPayouts rows={data?.payouts || []} channel={channel} getReport={getReport} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

Payouts.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  loading: PropTypes.bool,
  getReport: PropTypes.func,
};

Payouts.defaultProps = {
  loading: false,
  getReport: () => {},
};

export default Payouts;
