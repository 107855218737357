import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import EquipmentChosen from 'core/ui/EquipmentChosen';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from '@material-ui/core';
import Typography, { fontStyle } from 'core/ui/Typography';

const Equipment = ({ equipment, setEquipment, hideAlt }) => {
  const { t } = useTranslation();
  const setNewTag = (values) => {
    setEquipment(values);
  };

  const setAlt = (value, index) => {
    const equip = equipment ? [...equipment] : [];
    equip[index] = {
      name: equipment[index].name || equipment[index],
      equipment: value[0],
    };
    setEquipment(equip);
  };

  return (
    <Box justifyContent="center" display="flex" flexDirection="column">
      <Typography ml={2} mb={1} style={fontStyle.label} variant="body1">
        {t('workout.label.equipment').toUpperCase()}
      </Typography>
      <>
        <Box>
          <EquipmentChosen
            placeholder={t('workout.placeholder.equipment')}
            setValue={(values) => setNewTag(values)}
            limit={100}
            val={equipment ? [...equipment] : []}
          />
        </Box>
        {equipment && (
          <Box mt={1}>
            <TableContainer component={Paper}>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>{t('workout.label.equipment').toUpperCase()}</TableCell>
                    {!hideAlt && (
                      <TableCell>{t('workout.label.alternative').toUpperCase()}</TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {equipment &&
                    equipment.map((row, index) => (
                      <TableRow key={index.toString()}>
                        <TableCell component="th" scope="row">
                          {row.name || row}
                        </TableCell>
                        {!hideAlt && (
                          <TableCell>
                            <EquipmentChosen
                              limit={1}
                              placement="right-start"
                              placeholder={t('workout.placeholder.alternativeEquipment')}
                              setValue={(values) => setAlt(values, index)}
                              val={row.equipment ? [`${row.equipment.name || row.equipment}`] : []}
                            />
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </>
    </Box>
  );
};

export default Equipment;
