import React, { useContext, useState } from 'react';
import Box from 'core/ui/Box';

import { useTranslation } from 'react-i18next';
import { primary } from 'core/ui/Colors';
import { useSelector } from 'react-redux';
import { ButtonPrimary } from 'core/ui/Button';
import useEvents from 'core/openVidu/useEvents';
import { sessionWorkoutSelectors } from 'modules/sessionWorkout';
import FiberSmartRecordIcon from '@material-ui/icons/FiberSmartRecord';
import Typography, { fontStyle } from 'core/ui/Typography';
import LoaderSm from 'core/ui/LoaderSm';
import { Dialog, DialogContent } from '@material-ui/core';
import { StreamContext } from 'pages/Stream';
import DialogTitle from 'core/ui/Dialog/DialogTitle';
import { workoutsSelectors } from 'modules/workouts';

export default () => {
  const { t } = useTranslation();
  const streamContext = useContext(StreamContext);
  const workout = useSelector((state) => workoutsSelectors.getWorkout(state));
  const recording = useSelector((state) => sessionWorkoutSelectors.getRecording(state));
  const loadingRecording = useSelector((state) =>
    sessionWorkoutSelectors.getLoadingRecording(state),
  );
  const { startRecording, stopRecording } = useEvents();
  const [messageOk, setMessageOk] = useState('');

  const started = () => {
    const startAt = new Date(workout.classStartAt).getTime();
    if (startAt > Date.now()) {
      return false;
    }
    return true;
  };

  const startRec = () => {
    startRecording({
      callback: (data) => {
        if (data.id) {
          setMessageOk(t('workout.record.started'));
        } else {
          setMessageOk(t(started() ? 'workout.record.error' : 'workout.record.classNotStarted'));
        }
      },
    });
  };

  const stopRec = () => {
    stopRecording({
      callback: () => {
        setMessageOk(t('workout.record.stoped'));
      },
    });
  };

  return (
    <Dialog
      open
      maxWidth="xs"
      fullWidth
      onClose={() => streamContext.setModalRecording({ open: false })}
    >
      {!messageOk && (
        <DialogTitle onClose={() => streamContext.setModalRecording({ open: false })}>
          <Box display="flex" alignItems="center" justifyContent="center">
            <FiberSmartRecordIcon size="large" style={{ color: primary, fontSize: 28 }} />
            <Typography ml={3} component="h3" variant="h4">
              {t(
                recording.active ? 'workout.stop.recording' : 'workout.start.recording',
              ).toUpperCase()}
            </Typography>
          </Box>
        </DialogTitle>
      )}
      <DialogContent>
        {!messageOk ? (
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            style={{ height: '100%' }}
          >
            <Typography align="center" variant="body2">
              {t(recording.active ? 'workout.record.stop' : 'workout.record.start')}
            </Typography>
            <Box display="flex" p={5}>
              <>
                <ButtonPrimary
                  m={1}
                  style={{ ...fontStyle.large, padding: 10, width: '100%' }}
                  onClick={() => (recording.active ? stopRec() : startRec())}
                  disabled={loadingRecording}
                >
                  <LoaderSm loading={loadingRecording} />
                  {t('button.yes')}
                </ButtonPrimary>
                <ButtonPrimary
                  m={1}
                  disabled={loadingRecording}
                  style={{ ...fontStyle.large, padding: 10, width: '100%' }}
                  onClick={() => streamContext.setModalRecording({ open: false })}
                >
                  {t('button.no')}
                </ButtonPrimary>
              </>
            </Box>
          </Box>
        ) : (
          <Box p={5}>
            <Typography variant="h4">{t(messageOk)}</Typography>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};
