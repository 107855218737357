import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { useSelector } from 'react-redux';
import { languageSelectors } from 'modules/language';
import { countriesModule } from 'modules/countries';
import PhoneInput from 'react-phone-input-2';
import { startsWith } from 'ramda';
import useSelectors from 'modules/map/useSelectors';
import Box from '../Box';
import Typography, { fontStyle } from '../Typography';
import { secondaryLight } from '../Colors';

const useStyles = makeStyles(() => ({
  root: {
    padding: 7,
    display: 'flex',
    alignItems: 'center',
    borderRadius: 5,
  },
  input: {
    fontSize: 8,
    fontFamily: 'Roboto',
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export default function InputPhone({
  label,
  error,
  setValue,
  value,
  required,
  disabled,
  bordered,
  paperStyle,
}) {
  const classes = useStyles();
  const language = useSelector((state) => languageSelectors.getLanguage(state));

  const countries = useSelectors(countriesModule, 'countries');

  const phoneContries = countries ? countries.map((x) => x.flag) : [];
  const countriesFlags = [...phoneContries];
  const [valid, setValid] = useState(true);

  const styleInput = error
    ? paperStyle
    : { ...paperStyle, border: bordered ? `1px solid${secondaryLight}` : 'none' };

  const validValue = () => {
    if (value === 'undefined') {
      return false;
    }
    if (value === '') {
      return false;
    }

    if (!value) {
      return false;
    }

    if (error.type !== 'required') {
      return false;
    }

    return true;
  };

  const setNumber = (number, data) => {
    const isValid = startsWith(number, data.dialCode) || startsWith(data.dialCode, number);
    setValid(isValid);
    setValue({
      number,
      isValid,
      data,
    });
  };

  return (
    <>
      {label !== '' && (
        <Box ml={2} display="flex" mb={2}>
          <Typography style={fontStyle.label} variant="body1">
            {label}
          </Typography>
          {required && (
            <Typography ml={1} color="error">
              *
            </Typography>
          )}
        </Box>
      )}
      <Paper
        className={`${(error && !validValue()) || !valid ? 'input-paper-error' : ''} ${
          classes.root
        }`}
        style={styleInput}
      >
        {countries.length > 0 && (
          <PhoneInput
            specialLabel=""
            country={language.defaultCountry.toLowerCase()}
            value={value}
            onlyCountries={countriesFlags.map((x) => x.toLowerCase()) || []}
            inputClass={classes.input}
            onChange={(number, data) => setNumber(number, data)}
            disableCountryCode={disabled}
            disabled={disabled}
            inputStyle={{ minWidth: 200 }}
          />
        )}
      </Paper>
    </>
  );
}
