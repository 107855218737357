import React from 'react';
import PropTypes from 'prop-types';

import Box from 'core/ui/Box';
import { workoutsSelectors } from 'modules/workouts';
import ImageMedia from 'components/Channel/ImageMedia';
import ImageMediaUser from 'components/Profile/ImageMedia';
import Video from 'core/ui/Video';
import { useSelector } from 'react-redux';
import { secondary } from 'core/ui/Colors';

const HeaderWorkout = ({ workout, slide }) => {
  const loading = useSelector((state) => workoutsSelectors.getLoading(state));

  const show = () => {
    if (workout.previewVideo) {
      return 'preview';
    }
    if (workout.recordingPreview) {
      if (workout.recordingPreview.vimeoPreviewUrl) {
        return 'preview_recording';
      }
    }
    if (workout.channel && !workout.recordingPreview && !workout.previewVideo) {
      return 'image';
    }

    if (!workout.channel) {
      return 'coach_image';
    }
    return 'image';
  };

  return (
    <>
      {show() === 'coach_image' && (
        <Box style={{ position: 'relative' }} className="container-aspect container-aspect-37">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            className="item-aspect"
            style={{ backgroundColor: secondary }}
          >
            <ImageMediaUser
              user={workout.coaches && workout.coaches[0]}
              image={workout.coaches && workout.coaches.length > 0 ? workout.coaches[0].image : {}}
              size={100}
              heightCountry={34}
            />
          </Box>
        </Box>
      )}
      {show() === 'image' && (
        <ImageMedia image={workout.channel && workout.channel.image} loading={loading} />
      )}

      {show() === 'preview' && !loading && (
        <Box className="container-aspect container-aspect-37">
          <Box className="item-aspect">
            <Video src={workout.previewVideo && workout.previewVideo.url} full />
          </Box>
        </Box>
      )}

      {show() === 'preview_recording' && !loading && (
        <Box style={{ position: 'relative' }} className="container-aspect container-aspect-37">
          <Box className="item-aspect">
            {/* <Box style={{ position: 'absolute', top: 0, width: '100%', opacity: 0.5 }}>
              <ImageMedia image={workout.channel && workout.channel.image} loading={loading} />
            </Box> */}
            {slide ? (
              <img
                alt="StreamFitness - Slide Recording"
                src={workout.recordingPreview && workout.recordingPreview.slide.url}
                style={{ width: '100%', zIndex: 10 }}
              />
            ) : (
              <Video
                src={workout.recordingPreview && workout.recordingPreview.vimeoPreviewUrl}
                full
              />
            )}
          </Box>
        </Box>
      )}
    </>
  );
};

export default HeaderWorkout;

HeaderWorkout.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  workout: PropTypes.object,
  slide: PropTypes.bool,
};

HeaderWorkout.defaultProps = {
  workout: {},
  slide: false,
};
