import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form-v5';
import { useDispatch, useSelector } from 'react-redux';
import { channelsSelectors } from 'modules/channels';
import notifications from 'modules/notifications';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import useSelectors from 'modules/map/useSelectors';
import { channelsRatesTemplatesModule } from 'modules/channelsRatesTemplates';
import { formatDateApi, formatDateToApi } from 'core/utils/formatters';

export default ({ mode, item, callback }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const channel = useSelector((state) => channelsSelectors.getChannel(state));
  const loading = useSelectors(channelsRatesTemplatesModule, 'loading');
  const { request } = useActions();
  const { register, handleSubmit, setValue, watch, reset, errors } = useForm();

  const onSubmit = (values) => {
    request({
      action:
        mode === 'new'
          ? Modules.channelsRatesTemplates.actions.postChannelsRatesTemplates
          : Modules.channelsRatesTemplates.actions.putChannelsRatesTemplates,
      data: {
        ...values,
        id: item?.id || null,
        startDate: values?.startDate && formatDateToApi(values?.startDate),
        endDate: values?.endDate && formatDateToApi(values?.endDate),
        channelId: channel.id,
      },
      options: {
        onSuccess: () => {
          if (callback) {
            callback(values.name);
          }
          dispatch(notifications.success(t('message.success.save.rateTemplate')));
          request({
            action: Modules.channelsRatesTemplates.actions.getChannelsRatesTemplates,
            data: { channelId: channel.id },
          });
        },
      },
    });
  };

  useEffect(() => {
    register('name', { required: true });
    register('active', { required: false });
    register('kind', { required: true });
    register('amount', { required: true });
    register('description', { required: false });
    register('startDate', { required: true });
    register('endDate', { required: false });

    if (mode === 'new') {
      setValue('active', true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register]);

  useEffect(() => {
    if (item && item?.id) {
      reset({
        ...item,
        startDate: formatDateApi(item?.startDate),
        endDate: formatDateApi(item?.endDate),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, item?.id]);

  return {
    register,
    setValue,
    watch,
    reset,
    errors,
    onSubmit,
    handleSubmit,
    loading,
  };
};
