import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { DialogActions, DialogContent, Link } from '@material-ui/core';
import { ButtonPrimary } from 'core/ui/Button';
import { useTranslation } from 'react-i18next';
import Texture from 'core/ui/Texture';
import Paper from 'core/ui/Paper';
import { date, time } from 'core/utils/formatters';
import { redColor } from 'core/ui/Colors';

const ModalEventInfo = ({ open, event, close, onConfirm }) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={close} maxWidth="xs" fullWidth scroll="body">
      <Texture>
        <DialogContent>
          <div className="tw-mb-2">
            <Paper p={3}>
              <div>
                <div className="tw-text-lg tw-font-bold">{event?.user?.name}</div>
                <div>
                  {date(event?.startAt)}&nbsp;&nbsp;&nbsp;{time(event?.startAt)}
                </div>
                {event?.subType === 'workout' ? (
                  <div>
                    <Link href={`/workout/view/${event?.workout?.id}`} target="_blank">
                      {event?.workout?.name}
                    </Link>
                  </div>
                ) : (
                  <>
                    <div>{event?.usersCalendar?.channelsWorkoutsType?.name}</div>
                    <div>{event?.duration} min</div>
                  </>
                )}
              </div>
            </Paper>
          </div>
        </DialogContent>
      </Texture>
      <DialogActions>
        {event?.type === 'registration' && !event?.confirmed && onConfirm && (
          <div className="tw-mx-2">
            <ButtonPrimary
              onClick={() => {
                onConfirm(event, () => {
                  close();
                });
              }}
            >
              {t('button.confirmRegistration')}
            </ButtonPrimary>
          </div>
        )}
        <div className="tw-mx-2">
          <ButtonPrimary buttonColor={redColor} onClick={close}>
            {t('button.close')}
          </ButtonPrimary>
        </div>
      </DialogActions>
    </Dialog>
  );
};

ModalEventInfo.defaultProps = {
  close: () => {},
  userId: null,
};

export default ModalEventInfo;
