import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import PropTypes from 'prop-types';
import Typography, { fontStyle } from '../Typography';
import Box from '../Box';
import { secondaryLight } from '../Colors';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    overflowY: 'auto',
    borderRadius: 5,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontSize: 12,
    paddingLeft: 5,
    paddingTop: 15,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export default function TextAreaFieldRounded({
  label,
  name,
  error,
  placeholder,
  onChange,
  textAlign,
  rows,
  value,
  required,
  maxLength,
  bordered,
  resize,
  ...props
}) {
  const classes = useStyles();

  const styleInput = error ? {} : { border: bordered ? `1px solid${secondaryLight}` : 'none' };

  return (
    <>
      {label !== '' && (
        <Box ml={2} mb={2} display="flex">
          <Typography style={fontStyle.label} variant="body1">
            {label}
          </Typography>
          {required && (
            <Typography ml={1} color="error">
              *
            </Typography>
          )}
        </Box>
      )}

      <Paper className={`${error ? 'input-paper-error' : ''} ${classes.root}`} style={styleInput}>
        {onChange === null && (
          <InputBase
            multiline
            name={name}
            className={classes.input}
            placeholder={placeholder}
            autoComplete="off"
            rows={rows}
            inputProps={{ style: { textAlign }, autoComplete: 'new-password', maxLength }}
            {...props}
          />
        )}
        {onChange !== null && (
          <InputBase
            name={name}
            multiline
            className={classes.input}
            onChange={(e) => onChange(e.target.value)}
            value={value}
            placeholder={placeholder}
            autoComplete="off"
            rows={rows}
            inputProps={{
              style: { textAlign, resize },
              autoComplete: 'new-password',
              maxLength,
            }}
            {...props}
          />
        )}
      </Paper>
    </>
  );
}

TextAreaFieldRounded.propTypes = {
  name: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  error: PropTypes.object,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  textAlign: PropTypes.string,
  value: PropTypes.string,
  resize: PropTypes.string,
  rows: PropTypes.number,
  required: PropTypes.bool,
};

TextAreaFieldRounded.defaultProps = {
  name: null,
  error: null,
  placeholder: '',
  onChange: null,
  label: '',
  value: '',
  textAlign: 'left',
  resize: 'none',
  rows: 5,
  required: false,
};
