import React from 'react';
import Box from 'core/ui/Box';
import Typography from 'core/ui/Typography';

const LogoLeaderboard =
  'https://stream-fitness-app-uploads.s3.us-east-2.amazonaws.com/uploads/production/CompetitionBanner/LogoLeaderboard.png?v=2';
const LogoTimer =
  'https://stream-fitness-app-uploads.s3.us-east-2.amazonaws.com/uploads/production/CompetitionBanner/LogoTimer.png?v=2';

export default ({ row, size, leaderboard, competition }) => (
  <>
    {row ? (
      <Box display="flex" alignItems="center">
        <Typography align="center" variant="h6" color="secondary" className="roboto-regular" mr={2}>
          Powered By
        </Typography>
        <img
          src={
            leaderboard
              ? competition?.leaderboardLogo?.image?.url || LogoLeaderboard
              : competition?.timerLogo?.image?.url || LogoTimer
          }
          width={size || '100%'}
          alt="Powered by StreamFitness"
        />
      </Box>
    ) : (
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        <img
          src={
            leaderboard
              ? competition?.leaderboardLogo?.image?.url || LogoLeaderboard
              : competition?.timerLogo?.image?.url || LogoTimer
          }
          width={size || '100%'}
          alt="Powered by StreamFitness"
        />
        <Typography align="center" className="roboto-regular">
          Powered By
        </Typography>
      </Box>
    )}
  </>
);
