import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Edit } from '@material-ui/icons';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { fontStyle } from 'core/ui/Typography';
import { onlineColor, primary } from 'core/ui/Colors';
import { useTranslation } from 'react-i18next';
import { currency, date } from 'core/utils/formatters';

export default function ItemList({ channel, item, onEditClick }) {
  const { t } = useTranslation();
  return (
    <TableRow>
      <TableCell>{item.name}</TableCell>
      <TableCell>{t(`channel.rateTemplate.kind.${item.kind}`)}</TableCell>
      <TableCell>{currency(item?.amount)}</TableCell>
      <TableCell>{date(item?.startDate, channel?.dateFormatReact)}</TableCell>
      <TableCell>{item?.endDate && date(item?.endDate, channel?.dateFormatReact)}</TableCell>
      <TableCell align="center">
        {!item.active ? (
          <RadioButtonUncheckedIcon style={{ ...fontStyle.medium, color: primary }} />
        ) : (
          <CheckCircleOutlineIcon style={{ ...fontStyle.medium, color: onlineColor }} />
        )}
      </TableCell>
      <TableCell align="right">
        <IconButton onClick={() => onEditClick(item)}>
          <Edit />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

ItemList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.object.isRequired,
  onEditClick: PropTypes.func.isRequired,
};
