const Env = require('../env.json');

export const ENV = process.env.NODE_ENV;
export const { ENV_APP, BASE_URL, APP_BASE_URL, GOOGLE_RECAPTCHA_SITE_KEY } =
  Env[process.env.NODE_ENV];
export const BASE_URL_API = Env[process.env.NODE_ENV].BASE_API_URL;

export const { GA_TRACKING_ID } = Env[process.env.NODE_ENV];
export const { STRIPE_PK_KEY, STRIPE_AUTH_URL } = Env[process.env.NODE_ENV];

export const { SPOTIFY_CLIENT_ID } = Env[process.env.NODE_ENV];
export const { SPOTIFY_AUTH_BASIC } = Env[process.env.NODE_ENV];

export const {
  BASE_API_URL_SOCKET,
  GM_API_KEY,
  CARD_POINTE_FORM_URL,
  CONTACT_FORM_CSS,
  CONTACT_FORM_JS,
} = Env[process.env.NODE_ENV];
