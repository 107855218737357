import { useContext, useEffect, useRef, useState } from 'react';
import humps from 'humps';
import Modules from 'modules';
import useSelectors from 'modules/map/useSelectors';
import { conversationsModule } from 'modules/conversations';
import useActions from 'modules/map/useActions';
import { useSelector } from 'react-redux';
import { sessionSelectors } from 'modules/session';
import { retrieveAuthHeaders } from 'core/api/auth-headers';
import { retrieveData } from 'core/utils/session-storage';
import { BASE_API_URL_SOCKET } from 'core/env';
import { ChatContext } from 'contexts';

const actioncable = require('actioncable');

function useMessages({ groupsChat }) {
  const pagination = useSelectors(conversationsModule, 'pagination');
  const currentUser = useSelector((state) => sessionSelectors.getUser(state));
  const [localMessages, setLocalMessages] = useState([]);
  const { request } = useActions();
  const chatContext = useContext(ChatContext);

  const paginationRef = useRef();

  useEffect(() => {
    paginationRef.current = pagination;
  }, [pagination]);

  const loadMessages = (newPage = 1) => {
    if (newPage > 1 && paginationRef.current?.totalPages < newPage) {
      return;
    }

    if (newPage === 1) {
      request({
        action: Modules.conversations.actions.clearPagination,
        data: null,
      });
      setLocalMessages([]);
    }

    request({
      action: Modules.conversations.actions.getGroupMessages,
      data: {
        ownerableId: chatContext?.ownerableId,
        ownerableType: chatContext?.ownerableType,
        groupsChatId: groupsChat?.id,
        page: newPage,
        perPage: 20,
      },
      options: {
        onSuccess: (data) => {
          if (newPage === 1) {
            setLocalMessages(data);
          } else {
            setLocalMessages((prev) => [
              ...prev,
              ...data.filter((x) => !prev.find((y) => y.id === x.id)),
            ]);
          }
        },
      },
    });
  };

  const received = (data) => {
    const messageData = humps.camelizeKeys(data);
    if (messageData) {
      if (messageData?.message?.userId !== currentUser?.id) {
        if (messageData?.type === 'create') {
          setLocalMessages((prev) => [messageData?.message, ...prev]);
        }
        if (messageData?.type === 'update') {
          setLocalMessages((prev) =>
            prev.map((item) => {
              if (item.id === messageData?.message?.id) {
                return messageData?.message;
              }
              return item;
            }),
          );
        }
      }
    }
  };

  useEffect(() => {
    let channelCable = null;
    let cable = null;

    if (groupsChat?.id) {
      const logAuth = retrieveAuthHeaders(retrieveData)();
      const { client, uid } = logAuth;

      const authHeaders = {
        'access-token': logAuth['access-token'],
        client,
        uid,
      };
      const queryString = new URLSearchParams(authHeaders).toString();

      cable = actioncable.createConsumer(`${BASE_API_URL_SOCKET}?${queryString}`);
      channelCable = cable.subscriptions.create(
        {
          channel: 'ConversationsChannel',
          groups_chat_id: groupsChat?.id,
          web: true,
        },
        {
          connected: () => {
            console.log('connected!');
          },
          disconnected: () => {},
          received: (data) => received(data),
        },
      );
    }

    return () => {
      if (channelCable) {
        channelCable.unsubscribe();
      }
      if (cable) {
        cable.disconnect();
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupsChat]);

  return {
    localMessages,
    setLocalMessages,
    loadMessages,
    pagination,
  };
}

export default useMessages;
