import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import { secondaryBgLight } from 'core/ui/Colors';
import { DialogActions, DialogContent } from '@material-ui/core';
import TimePickerRounded from 'core/ui/DatePickers/TimePickerRounded';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import { ButtonPrimary } from 'core/ui/Button';
import { date, formatDateApi } from 'core/utils/formatters';
import { cutTime } from 'core/utils/formatters/date';
import { competitionsSelectors } from 'modules/competitions';
import { useSelector } from 'react-redux';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import Paper from 'core/ui/Paper';
import Typography from 'core/ui/Typography';
import Checkbox from 'core/ui/Checkbox';

const ModalEditHeat = ({ open, heat, close, changeHeat, createNewHeat }) => {
  const [data, setData] = useState({});
  const { t } = useTranslation();
  const { request } = useActions();
  const [users, setUsers] = useState([]);
  const competition = useSelector((state) => competitionsSelectors.getCompetition(state));

  const [heatsVolunteers, setHeatsVolunteers] = useState([]);

  const saveHeat = () => {
    const formData = {};
    if (data.startTime) {
      formData.startTime = cutTime(data.startTime);
    }
    if (data.name) {
      formData.name = data.name;
    }
    if (data.lanes) {
      formData.lanes = data.lanes;
    }
    formData.volunteerIds = heatsVolunteers;

    if (heat?.id) {
      changeHeat(heat?.id, formData);
    } else {
      createNewHeat(formData);
    }
  };

  useEffect(() => {
    const baseDate = date(new Date(), 'DD-MM-YYYY');

    setData((prev) => ({
      ...prev,
      lanes: heat?.lanes,
      name: heat?.name,
      startTime:
        heat?.startTime &&
        formatDateApi(`${baseDate} ${heat.startTime}`, 'YYYY-MM-DD HH:mm:ss a Z'),
    }));
    setHeatsVolunteers(heat?.volunteers?.map((x) => x.id) || []);
  }, [heat]);

  const search = (page = 1) => {
    request({
      action: Modules.competitionsUsers.actions.getCompetitionsUser,
      data: {
        competitionId: competition?.id,
        regType: 'volunteer',
        page,
        perPage: 10000,
      },
      options: {
        onSuccess: (resp) => {
          setUsers(resp);
        },
      },
    });
  };

  useEffect(() => {
    search();
  }, []);

  const isChecked = (userId) => !!heatsVolunteers?.find((x) => x === userId);

  const check = (userId) => {
    if (isChecked(userId)) {
      setHeatsVolunteers((prev) => prev.filter((x) => x !== userId));
    } else {
      setHeatsVolunteers((prev) => [...prev, userId]);
    }
  };

  return (
    <Dialog maxWidth="md" fullWidth open={!!open} onClose={close}>
      <DialogContent style={{ backgroundColor: secondaryBgLight }}>
        <Box mb={4}>
          <TextFieldRounded
            label="Heat Name"
            value={data?.name}
            onChange={(v) => setData((prev) => ({ ...prev, name: v }))}
          />
        </Box>

        <Box mb={4}>
          <TextFieldRounded
            label={t('workout.heat.lanes')}
            value={data?.lanes}
            onChange={(v) => setData((prev) => ({ ...prev, lanes: v }))}
          />
        </Box>

        <Box mb={3}>
          <TimePickerRounded
            name="scheduledAt"
            type="datetime-local"
            onChange={(v) => {
              setData((prev) => ({ ...prev, startTime: v }));
            }}
            value={data?.startTime}
            required
            autoOk={false}
          />
        </Box>

        {users?.map((item) => (
          <Paper p={2} key={item?.id} mt={1}>
            <Box display="flex" alignItems="center">
              <Box flexGrow={1}>
                <Typography>{item?.user?.name}</Typography>
                <Typography color="textSecondary" className="roboto-regular">
                  {item?.competitionsCategories?.map((x) => x.name).join(',')}
                </Typography>
              </Box>
              <Box>
                <Checkbox
                  checked={isChecked(item?.user?.id)}
                  onClick={() => check(item?.user?.id)}
                />
              </Box>
            </Box>
          </Paper>
        ))}
      </DialogContent>
      <DialogActions>
        <ButtonPrimary buttonColor={competition?.buttonColor} onClick={() => saveHeat()}>
          {t('button.save')}
        </ButtonPrimary>
      </DialogActions>
    </Dialog>
  );
};

export default ModalEditHeat;
