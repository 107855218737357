/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import { STRIPE_PK_KEY } from 'core/env';

import LoaderSm from 'core/ui/LoaderSm';
import Box from 'core/ui/Box';
import AchForm from './AchForm';

export default ({
  setView,
  setSelectedPaymentMethod,
  paymentMethod,
  closeModal,
  bordered,
  showCanadaDebit,
}) => {
  const stripePromise = useRef();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (paymentMethod?.id) {
      const func = async () => {
        setLoaded(false);
        stripePromise.current = await loadStripe(
          STRIPE_PK_KEY,
          paymentMethod?.stripeAcctId ? { stripeAccount: paymentMethod?.stripeAcctId } : {},
        );
        setLoaded(true);
      };

      func();
    }

    return () => {
      stripePromise.current = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethod?.id]);

  if (!loaded) {
    return (
      <Box p={3}>
        <LoaderSm center loading />
      </Box>
    );
  }

  return (
    <Elements stripe={stripePromise.current}>
      <AchForm
        setSelectedPaymentMethod={setSelectedPaymentMethod}
        setView={setView}
        paymentMethod={paymentMethod}
        closeModal={closeModal}
        bordered={bordered}
        showCanadaDebit={showCanadaDebit}
      />
    </Elements>
  );
};
