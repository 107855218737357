/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import Box from 'core/ui/Box';

import { Button, InputLabel } from '@material-ui/core';
import { Movie } from '@material-ui/icons';
import Typography from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import { WorkoutContext } from '..';

export default ({ accept }) => {
  const { t } = useTranslation();
  const formContext = useContext(WorkoutContext);

  const handleCapture = ({ target }) => {
    if (target.files.length) {
      const file = target.files[0];
      formContext.setUpload((prev) => ({ ...prev, file }));
    }
  };

  const randomId = Math.floor(Math.random() * 101);

  return (
    <Box mb={3}>
      <Typography mb={1} align="center">
        {t('workout.upload.video.formats')}
      </Typography>
      <Box display="flex" justifyContent="center" className="link-hover">
        <Button style={{ borderRadius: 5 }} color="primary" variant="contained" disabled={!accept}>
          <InputLabel htmlFor={`icon-button-file-${randomId}`}>
            <Box display="flex" alignItems="center" justifyContent="center">
              <Movie size="small" style={{ color: 'white' }} />
              <Typography ml={3} style={{ color: 'white' }} variant="subtitle2">
                {formContext.upload.file && formContext.upload.file.name
                  ? formContext.upload.file.name
                  : t('workout.upload.selectVideo')}
              </Typography>
            </Box>
          </InputLabel>
        </Button>
      </Box>

      <input
        color="primary"
        type="file"
        onChange={handleCapture}
        id={`icon-button-file-${randomId}`}
        style={{ display: 'none' }}
        accept=".mpeg,.mp4,.avi,.mov,.webm,.flv"
        multiple
      />
    </Box>
  );
};
