/* eslint-disable react/jsx-no-target-blank */
import React, { useContext, useEffect, useState } from 'react';
import { ButtonGreen, ButtonPrimary } from 'core/ui/Button';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import Typography from 'core/ui/Typography';
import { useDispatch } from 'react-redux';
import { usersActions } from 'modules/users';
import { STRIPE_AUTH_URL } from 'core/env';
import { Link } from '@material-ui/core';
import ConfirmDialog from 'core/ui/ConfirmDialog';
import { ProfileContext } from 'pages/shared/Profile/Edit';
import SelectBoxObject from 'core/ui/SelectBoxObject';

const StripeConnectedAccount = () => {
  const formContext = useContext(ProfileContext);
  const { t } = useTranslation();
  const [confirmCancel, setConfirmCancel] = useState({ open: false });
  const dispatch = useDispatch();

  const removeAccount = () => {
    const confirm = () => {
      setConfirmCancel({ open: false });
      if (formContext.profile.id) {
        dispatch(
          usersActions.removeStripeAccountStart(formContext.profile.id, () => {
            formContext.setValue('channelStripeId', null);
            dispatch(usersActions.authenticateStart(formContext.profile.id));
          }),
        );
      }
    };

    setConfirmCancel((prev) => ({
      ...prev,
      open: true,
      confirm,
    }));
  };

  const jsonStr = { ownerableType: 'Partner', ownerableId: formContext?.profile?.id };
  const userString = btoa(JSON.stringify(jsonStr));

  const channelsWithStripe = [];

  useEffect(() => {
    if (channelsWithStripe) {
      const channelOwner = channelsWithStripe.find((x) => x?.user?.id === formContext?.profile?.id);

      if (channelOwner) {
        formContext.setValue('channelStripeId', channelOwner.id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formContext?.profile]);

  return (
    <Box>
      <ConfirmDialog
        open={confirmCancel.open}
        description={t('channel.stripe.removeAccount.confirm')}
        labelConfirm={t('button.yes')}
        labelCancel={t('button.no')}
        confirm={() => confirmCancel.confirm()}
        cancel={() => setConfirmCancel((prev) => ({ ...prev, open: false }))}
      />
      <Box>
        <>
          {formContext.watch('channelStripeId') || formContext.profile.hasStripeAccount ? (
            <Box>
              <Typography mb={2} color="secondary">
                {t('profile.stripe')}
              </Typography>
              <ButtonPrimary fullWidth onClick={() => removeAccount()}>
                {t('channel.stripe.removeAccount')}
              </ButtonPrimary>
            </Box>
          ) : (
            <>
              <Box mb={3} mx={3}>
                <Typography mb={2} color="secondary">
                  {t('profile.stripe.desc')}
                </Typography>
                <Link
                  href={`${STRIPE_AUTH_URL}&state=${userString}`}
                  className="hover"
                  style={{ textDecoration: 'none' }}
                >
                  <ButtonGreen fullWidth>
                    <Typography ml={1} variant="h5" style={{ color: 'white' }}>
                      {t('button.stripe.connect')}
                    </Typography>
                  </ButtonGreen>
                </Link>
              </Box>

              {channelsWithStripe && channelsWithStripe.length > 0 && (
                <Box mx={2} mb={3}>
                  <SelectBoxObject
                    label={t('profile.stripe.channel')}
                    options={channelsWithStripe}
                    propLabel="name"
                    value={formContext.watch('channelStripeId')}
                    propValue="id"
                    setValue={(v) => formContext.setValue('channelStripeId', v)}
                  />
                </Box>
              )}
            </>
          )}

          {/* {!formContext.watch('channelStripeId') && formContext.profile.hasStripeAccount && (
            <Typography>{t('profile.stripe.required')}</Typography>
          )} */}
        </>
      </Box>
    </Box>
  );
};

export default StripeConnectedAccount;
