import React, { useEffect, useState } from 'react';
import { Dialog } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import RegistrationCustomFields from 'components/Competition/Registration/New/CustomFields';
import Texture from 'core/ui/Texture';
import { ButtonPrimary } from 'core/ui/Button';
import useActions from 'modules/map/useActions';
import { useDispatch } from 'react-redux';
import Modules from 'modules';
import notifications from 'modules/notifications';
import useSelectors from 'modules/map/useSelectors';
import { competitionsUsersModule } from 'modules/competitionsUsers';
import LoaderSm from 'core/ui/LoaderSm';

export default function ModalCustomFields({
  member,
  competition,
  open,
  customFields,
  close,
  refresh,
  regType,
}) {
  const { t } = useTranslation();
  const { request } = useActions();
  const loading = useSelectors(competitionsUsersModule, 'loading');
  const dispatch = useDispatch();
  const [editCustomFields, setEditCustomFields] = useState(null);

  const saveFields = () => {
    request({
      action: Modules.competitionsUsers.actions.update,
      data: {
        competitionId: competition?.id,
        userId: member?.user?.id,
        customFields: editCustomFields,
        kind: regType || 'competitor',
        regType: regType || 'competitor',
      },
      options: {
        onSuccess: () => {
          refresh();
          close();
          dispatch(notifications.success('Fields has been saved.'));
        },
      },
    });
  };

  useEffect(() => {
    setEditCustomFields(customFields);
  }, [customFields]);

  return (
    <Dialog maxWidth="md" fullWidth open={open} onClose={() => close()}>
      <Texture>
        <Box p={3}>
          <RegistrationCustomFields
            competition={competition}
            customFields={editCustomFields || []}
            hidePrice
            setCustomFields={(v) => setEditCustomFields(v)}
          />
          <Box mt={5} display="flex" justifyContent="flex-end">
            <LoaderSm loading={loading} />
            <ButtonPrimary disabled={loading} onClick={() => saveFields()}>
              {t('button.save')}
            </ButtonPrimary>
          </Box>
        </Box>
      </Texture>
    </Dialog>
  );
}
