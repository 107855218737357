import { makeStyles } from '@material-ui/core';

import { redColor } from 'core/ui/Colors';

export const useStyles = makeStyles({
  contentContainerStyle: {
    display: 'flex',
    overflowX: 'auto',
    padding: 16,
    gap: 24,
  },
  itemWrapper: {
    position: 'relative',
  },

  // Admin
  absoluteOptions: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 100,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  removeButton: {
    color: redColor,
  },
});
