import React, { useEffect, useState } from 'react';
import { ButtonPrimary } from 'core/ui/Button';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import useActions from 'modules/map/useActions';
import useSelectors from 'modules/map/useSelectors';
import { mediaFilesAssociationsModule } from 'modules/mediaFilesAssociations';
import Modules from 'modules';
import Grid from 'core/ui/Grid';
import { InputWrapper } from 'core/ui/ChosenComponents';
import { InputBase, TablePagination } from '@material-ui/core';
import TablePaginationActions from 'core/ui/TablePaginationActions';
import ConfirmDialog from 'core/ui/ConfirmDialog';
import LoaderSm from 'core/ui/LoaderSm';
import ItemMovement from './ItemMovement';
import ModalAddMedia from './AddMedia/Modal';
import ModalAddMovement from './AddMovement/Modal';

const LibraryMedias = ({ ownerableId, ownerableType }) => {
  const { t } = useTranslation();
  const { request } = useActions();
  const loading = useSelectors(mediaFilesAssociationsModule, 'loading');
  const movements = useSelectors(mediaFilesAssociationsModule, 'movements');
  const pagination = useSelectors(mediaFilesAssociationsModule, 'pagination');
  const [query, setQuery] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(24);

  const [modal, setModal] = useState({
    open: false,
  });
  const [modalAdd, setModalAdd] = useState({
    open: false,
  });

  const [confirmCopy, setConfirmCopy] = useState({ open: false });

  const search = (p) => {
    request({
      action: Modules.mediaFilesAssociations.actions.getMovements,
      data: {
        query,
        page: p,
        perPage: rowsPerPage,
        ownerableId,
        ownerableType,
      },
    });
  };

  const removeAssociation = (mov) => {
    request({
      action: Modules.mediaFilesAssociations.actions.removeAssociation,
      data: {
        associableType: 'Movement',
        associableId: mov?.id,
        ownerableId,
        ownerableType,
      },
      options: {
        onSuccess: () => {
          search(pagination?.currentPage || 1);
        },
      },
    });
  };

  useEffect(() => {
    if (ownerableId) {
      search(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request, ownerableId, ownerableType]);

  const addMedia = (movement) => {
    setModal((prev) => ({ ...prev, movement, open: true }));
  };

  const handleChangePage = (_event, newPage) => {
    search(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const copyStreamLibrary = () => {
    setConfirmCopy({
      open: true,
      confirm: () => {
        setConfirmCopy({ open: false });
        request({
          action: Modules.mediaFilesAssociations.actions.copyStreamMedia,
          data: {
            ownerableId,
            ownerableType,
          },
          options: {
            onSuccess: () => {
              search(pagination?.currentPage || 1);
            },
          },
        });
      },
      cancel: () => {
        setConfirmCopy({ open: false });
      },
    });
  };

  return (
    <Box p={5}>
      <ConfirmDialog
        open={confirmCopy.open}
        confirm={() => confirmCopy.confirm()}
        cancel={() => confirmCopy.cancel()}
        description={t('copy.movement.library')}
        labelConfirm={t('button.yes')}
        labelCancel={t('button.no')}
      />
      {modal.open && (
        <ModalAddMedia
          open={modal.open}
          ownerableId={ownerableId}
          ownerableType={ownerableType}
          associableType="Movement"
          associableId={modal.movement?.id}
          title={modal.movement?.name}
          currentUrl={modal?.movement?.mediaFile?.url || modal?.movement?.embedVideo}
          close={() => setModal(() => ({ open: false }))}
          callback={() => {
            setModal(() => ({ open: false }));
            search(pagination?.currentPage || 1);
          }}
        />
      )}

      <ModalAddMovement
        open={modalAdd?.open}
        callback={() => {
          setModalAdd({ open: false });
          search(1);
        }}
        close={() => setModalAdd({ open: false })}
      />

      <Box>
        <Box mb={3} display="flex">
          <Box flexGrow={1}>
            <ButtonPrimary disabled={loading} onClick={() => copyStreamLibrary()}>
              <LoaderSm width={25} loading={loading} />
              {t(loading ? 'channel.library.copy.loading' : 'button.useStreamLibrary')}
            </ButtonPrimary>
          </Box>
          <ButtonPrimary disabled={loading} onClick={() => setModalAdd({ open: true })}>
            {t('button.createMovement')}
          </ButtonPrimary>
        </Box>
        <Box display="flex" alignItems="center">
          <InputWrapper>
            <Box display="flex" style={{ width: '100%' }}>
              <InputBase
                value={query}
                onChange={(v) => setQuery(v.target.value)}
                style={{ minWidth: 30, width: '100%', margin: '4px' }}
                placeholder={t('movement.search')}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    search();
                  }
                }}
              />
            </Box>
          </InputWrapper>
          <Box ml={3}>
            <ButtonPrimary disabled={loading} onClick={() => search()}>
              {t('button.search')}
            </ButtonPrimary>
          </Box>
        </Box>
      </Box>

      <Grid container mt={2} spacing={2}>
        {movements &&
          movements.map((mov) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={mov.id}>
              <ItemMovement
                movement={mov}
                addMedia={addMedia}
                removeAssociation={removeAssociation}
              />
            </Grid>
          ))}
      </Grid>

      {pagination?.currentPage && pagination?.currentPage < pagination?.totalPages && (
        <Box>
          <TablePagination
            rowsPerPageOptions={[12, 24, 48, 112]}
            component="div"
            labelRowsPerPage="Per Page"
            count={pagination?.totalCount}
            rowsPerPage={rowsPerPage}
            page={pagination?.currentPage}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </Box>
      )}
    </Box>
  );
};

export default LibraryMedias;
