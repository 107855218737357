import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import Card from 'core/ui/Card';
import Paper from 'core/ui/Paper';
import SelectorChannelUser from 'components/Channel/SelectorChannelUser';
import CardUser from 'components/Channel/SelectorChannelUser/Card';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';

export default ({ title, channelId, member, setMember, buttonColor }) => {
  const { t } = useTranslation();

  const onSelect = (user) => {
    setMember(user);
  };

  return (
    <Card
      btopcolor={buttonColor}
      btopwidth={2}
      hidedivider="true"
      title={title || t('channel.store.sell.member')}
    >
      <Box
        px={3}
        py={1}
        pb={3}
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
      >
        {!member ? (
          <SelectorChannelUser
            buttonColor={buttonColor}
            onSelectedUser={(u) => onSelect(u)}
            channelId={channelId}
          />
        ) : (
          <Box px={3} style={{ width: '100%' }}>
            <Paper
              p={3}
              elevation={4}
              className="paper-rounded"
              mb={2}
              style={{ position: 'relative' }}
            >
              <Box display="flex" flexWrap="wrap">
                <IconButton
                  style={{ position: 'absolute', right: 0, top: 0 }}
                  onClick={() => setMember(null)}
                >
                  <Close />
                </IconButton>
                <CardUser user={member} />
              </Box>
            </Paper>
          </Box>
        )}
      </Box>
    </Card>
  );
};
