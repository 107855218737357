/* eslint-disable react/prop-types */
import AWS from 'aws-sdk';
import uuid from 'react-uuid';
import { ENV_APP } from 'core/env';
import { B_ID } from 'core/utils/consts';
import { mKY } from 'core/utils/helpers';

const B_K = mKY();

const bucket = new AWS.S3({
  accessKeyId: B_ID,
  secretAccessKey: B_K,
  region: 'us-east-2',
});

export default function useS3Upload({ filePrefix, path }) {
  const PATH_KEY = `uploads/${ENV_APP}/${path}/`;

  const uploadfile = (fileName, file) => {
    const params = {
      Bucket: 'stream-fitness-app-uploads',
      Key: PATH_KEY + fileName,
      Body: file,
      ContentType: file.type,
    };

    return bucket.upload(params).promise();
  };

  const startUploadFile = (file) => {
    const uniqueFileName = `streamfitness-${filePrefix || 'file'}-${uuid()}-${file.name}`;

    return uploadfile(uniqueFileName, file);
  };

  return { startUploadFile };
}
