import { all, call, put } from 'redux-saga/effects';

import { getErrorMessage } from 'core/api/api-error';

import notifications from 'modules/notifications';

import * as api from './api';
import { supportActions as actions } from './index';

export function* getSupport() {
  try {
    const response = yield call(api.getSupport);
    const support = response.data;
    yield put(actions.getSupportSuccess(support));
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.getSupportError(getErrorMessage(error))),
    ]);
  }
}

export function* postSupport({ data }) {
  try {
    const response = yield call(api.postSupport, data);
    const support = response.data;
    yield put(actions.postSupportSuccess(support));
    if (data.messageSuccess) {
      yield put(notifications.success(data.messageSuccess));
    }
    if (data.callback) {
      data.callback();
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.postSupportError(getErrorMessage(error))),
    ]);
  }
}
