import api from 'core/api';

export const storeOrdersModule = 'storeOrders';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const loadingPreviewOrderStates = {
  start: { loadingPreview: true },
  error: { loadingPreview: false },
  success: { loadingPreview: false },
};

const actions = {
  getLastOrderWithAddress: {
    module: storeOrdersModule,
    name: 'getLastOrderWithAddress',
    api: ({ userId }) => api.get(`/store/orders/last_order_user_with_address/${userId}`),
    params: {
      start: ['params'],
      error: [''],
      success: ['order'],
    },
    state: loadingStates,
  },
  getPreviewOrder: {
    module: storeOrdersModule,
    name: 'getPreviewOrder',
    api: (data) => api.post(`/store/orders/preview_order`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingPreviewOrderStates,
  },
};

export default {
  actions,
  state: {
    order: null,
    error: null,
    loading: false,
    loadingPreview: false,
  },
};
