import React, { useContext, useEffect } from 'react';
import { Container } from '@material-ui/core';
import Form from 'components/Workout/Form/index';
import { LayoutContext } from 'pages/shared/Layout';
import { useTranslation } from 'react-i18next';
import { sessionSelectors } from 'modules/session';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import { workoutsSelectors } from 'modules/workouts';
import Box from 'core/ui/Box';
import { parse } from 'query-string';

export default () => {
  const location = useLocation();
  const { id } = useParams();
  const layoutContext = useContext(LayoutContext);
  const { t } = useTranslation();
  const workout = useSelector((state) => workoutsSelectors.getWorkout(state));
  const loading = useSelector((state) => workoutsSelectors.getLoading(state));
  const user = useSelector((state) => sessionSelectors.getUser(state));
  const history = useHistory();
  const dispatch = useDispatch();
  const params = parse(location.search);

  useEffect(() => {
    if (!workout.id || loading) {
      return;
    }
    if (
      workout.isCoach === false &&
      workout.channel &&
      !['admin', 'owner', 'admin_coach', 'coach_head', 'coach_owner'].find(
        (x) => x === workout.channel.accessType,
      )
    ) {
      history.push(`/workout/view/${workout.slug}`);
      // dispatch(notifications.error('Not authorized'));
    }
  }, [workout, history, loading, user, dispatch]);

  useEffect(() => {
    layoutContext.setTitleBar(t('title.workout.edit').toUpperCase());
    layoutContext.setActiveMenu('');
  }, [layoutContext, t]);

  return (
    <div>
      <Container maxWidth="xl" style={{ position: 'relative' }}>
        <Box pt={5}>
          <Form mode="edit" type="customClass" channelId={params.channelId} workoutId={id} />
        </Box>
      </Container>
    </div>
  );
};
