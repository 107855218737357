/* eslint-disable react/prop-types */
import React, { useCallback, useState } from 'react';
import Typography from 'core/ui/Typography';
import Box from 'core/ui/Box';
import SFDataTable from 'core/ui/DataTable';
import { Skeleton } from '@material-ui/lab';
import { Dialog } from '@material-ui/core';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import { CalendarToday, Warning } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { ButtonPrimary, ButtonPrimarySm } from 'core/ui/Button';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import Checkbox from 'core/ui/Checkbox';
import PrScore from '../../PersonalRecord/PrScore';
import PersonalRecordList from '../../PersonalRecord/List';
import ModalFormPrScore from '../../PersonalRecord/Form/Modal';

const PreviewList = ({ previewData, loading }) => {
  const { t } = useTranslation();
  const [openPr, setOpenPr] = useState();
  const [openScorePr, setOpenScorePr] = useState({ open: false });
  const { request } = useActions();

  const [checkedItems, setCheckedItems] = useState([]);
  const [query, setQuery] = useState();

  const setSelectPr = (selectedPr) => {
    const newArray = previewData.map((x) => {
      const obj = { ...x };

      if (x === openPr.pr || checkedItems.find((y) => y === x)) {
        obj.personalRecordMovement = selectedPr;
        obj.scoreType = selectedPr?.scoreType;
      }

      return obj;
    });

    request({
      action: Modules.usersImports.actions.setPreviewData,
      data: newArray,
    });

    setOpenPr({ open: false });
    setQuery('');
    setCheckedItems([]);
  };

  const changeScores = (newData) => {
    const newArray = previewData.map((x) => {
      const obj = { ...x };

      if (x === openScorePr.item) {
        obj.scores = newData.scores;
        obj.data = newData.date;
      }

      return obj;
    });

    request({
      action: Modules.usersImports.actions.setPreviewData,
      data: newArray,
    });

    setOpenScorePr({ open: false });
  };

  const toggleItem = (item) => {
    if (checkedItems.find((x) => x === item)) {
      setCheckedItems((prev) => prev.filter((x) => x !== item));
    } else {
      setCheckedItems((prev) => [...prev, item]);
    }
  };

  const filter = useCallback(
    (pr) => {
      if (
        !query ||
        pr?.personalRecordMovement?.movement?.name?.toUpperCase().indexOf(query.toUpperCase()) > -1
      ) {
        return true;
      }

      return false;
    },
    [query],
  );

  const fixItems = previewData
    ?.filter((x) => !x?.personalRecordMovement?.id)
    ?.filter((x) => filter(x));

  const toggleAll = () => {
    if (checkedItems.length > 0) {
      setCheckedItems([]);
    } else {
      setCheckedItems(fixItems);
    }
  };

  const columns = (showFixButton) => [
    {
      sortable: false,
      cell: (pr) => (
        <>
          {!showFixButton && (
            <Checkbox checked={checkedItems.find((x) => x === pr)} onClick={() => toggleItem(pr)} />
          )}
          {pr?.personalRecordMovement?.id ? (
            <Box>
              <Typography>{pr?.personalRecordMovement?.movement?.name}</Typography>
            </Box>
          ) : (
            <Box display="flex" alignItems="center">
              <Warning color="primary" style={{ fontSize: 14 }} />
              <Typography variant="caption" mr={3} onClick={() => setOpenPr({ open: true, pr })}>
                {t('import.preview.cantFind')} {pr?.personalRecordMovement?.movement?.name}
              </Typography>
              <Typography
                className="link-hover"
                ml={2}
                color="primary"
                onClick={() => setOpenPr({ open: true, pr })}
              >
                {t('button.searchMovements')}
              </Typography>
            </Box>
          )}
        </>
      ),
    },
    {
      name: 'Name',
      selector: 'name',
      sortable: true,
      cell: (list) => <Typography ml={1}>{list.name}</Typography>,
    },
    {
      name: 'Date',
      selector: 'date',
      sortable: true,
      cell: (list) => (
        <Typography ml={1}>
          <CalendarToday style={{ fontSize: 12 }} /> {list.date}
        </Typography>
      ),
    },
    {
      name: '',
      selector: 'scores',
      sortable: true,
      cell: (pr) => (
        <Box>
          <PrScore scores={pr.scores} scoreType={pr.scoreType} />

          {showFixButton && (
            <Box display="flex" justifyContent="center" m={1}>
              <ButtonPrimarySm
                onClick={() =>
                  setOpenScorePr({
                    open: true,
                    item: pr,
                  })
                }
              >
                {t('button.fixScore')}
              </ButtonPrimarySm>
            </Box>
          )}
        </Box>
      ),
    },
  ];

  const numberToFix = fixItems?.length || 0;

  return (
    <Box p={5}>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={openPr?.open}
        onClose={() => setOpenPr({ open: false })}
      >
        <Box p={3}>
          <PersonalRecordList isSelector setSelectedPr={(pr) => setSelectPr(pr)} />
        </Box>
      </Dialog>
      {loading && (
        <Skeleton variant="rect" animation="wave" style={{ height: 200, width: '100%' }} />
      )}

      {numberToFix > 0 && (
        <Box mb={3}>
          <Typography mb={1} variant="h6" color="primary">
            {t('import.pr.fix').replace('#{number}', numberToFix)}
          </Typography>
          <Box mb={1} display="flex" alignItems="center">
            <Box>
              <ButtonPrimary onClick={() => toggleAll()}>{t('button.toggle')}</ButtonPrimary>
            </Box>
            <Box mx={1}>
              <ButtonPrimary
                disabled={checkedItems.length === 0}
                onClick={() => setOpenPr({ open: true })}
              >
                <Typography>{t('button.fixSelected')}</Typography>&nbsp;
                <Typography>
                  {checkedItems?.length > 0 ? `(${checkedItems?.length})` : ''}
                </Typography>
              </ButtonPrimary>
            </Box>
            <Box flexGrow={1}>
              <TextFieldRounded
                placeholder="Search"
                bordered
                value={query}
                onChange={(v) => {
                  setQuery(v);
                  setCheckedItems([]);
                }}
              />
            </Box>
          </Box>
          <SFDataTable
            columns={columns(false)}
            data={fixItems}
            pagination
            showPaper={false}
            noTableHead
            noHeader
          />
        </Box>
      )}

      <SFDataTable
        columns={columns(true)}
        data={previewData?.filter((x) => !!x?.personalRecordMovement?.id)}
        pagination
        showPaper={false}
        noTableHead
        noHeader
      />

      <ModalFormPrScore
        selectedPr={openScorePr?.item?.personalRecordMovement}
        itemEdit={openScorePr?.item}
        open={openScorePr?.open}
        close={() => setOpenScorePr({ open: false })}
        callback={changeScores}
      />
    </Box>
  );
};

PreviewList.propTypes = {};

PreviewList.defaultProps = {};

export default PreviewList;
