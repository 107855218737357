import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useTheme, TablePagination, IconButton } from '@material-ui/core';
import Typography, { fontStyle } from 'core/ui/Typography';
import Box from 'core/ui/Box';
import Divider from 'core/ui/Divider';
import { date } from 'core/utils/formatters';
import SFDataTable from 'core/ui/DataTable';
import ImageMedia from 'components/Profile/ImageMedia';
import LabelPhone from 'core/ui/InputPhone/LabelPhone';
import { ButtonPrimarySm } from 'core/ui/Button';
import { Delete } from '@material-ui/icons';
import TablePaginationActions from 'core/ui/TablePaginationActions';

const Pagination = memo(({ perPage, setPerPage, pagination, search }) => (
  <TablePagination
    component="div"
    rowsPerPage={perPage}
    onRowsPerPageChange={(event) => {
      setPerPage(parseInt(event.target.value, 10));
      search(1);
    }}
    labelRowsPerPage="Rows per page"
    count={pagination?.totalCount}
    page={(pagination?.currentPage || 1) - 1 || 0}
    onPageChange={(e, newPage) => {
      search(newPage + 1);
    }}
    ActionsComponent={TablePaginationActions}
  />
));

const TableMembers = ({
  setModalMemberInformation,
  members,
  pagination,
  search,
  setPerPage,
  perPage,
  changeLead,
  inactiveMember,
  channel,
}) => {
  const { palette } = useTheme();

  const { t } = useTranslation();

  const getHrefLink = (member) => `/channel/manage/${channel.id}/members/${member.id}`;

  const columns = [
    {
      id: 'avatar',
      cell: (member) => {
        const canOpen = canOpenProfile(member);

        return (
          <Link
            href={canOpen ? getHrefLink(member) : undefined}
            className="hover"
            onClick={(e) => {
              e.preventDefault();

              if (canOpen) setModalMemberInformation({ userId: member.id, open: true });
            }}
          >
            <Box my={1}>
              <ImageMedia
                user={member}
                image={member.image ? member.image : {}}
                size={50}
                heightCountry={15}
                sizeCountry="1em"
              />
            </Box>
          </Link>
        );
      },
      sortable: false,
      width: '60px',
    },
    {
      id: 'name',
      name: t('channel.memberships.column.user'),
      selector: 'name',
      cell: (member) => {
        const canOpen = canOpenProfile(member);

        return (
          <Link
            href={canOpen ? getHrefLink(member) : undefined}
            className="hover"
            onClick={(e) => {
              e.preventDefault();

              if (canOpen) setModalMemberInformation({ userId: member.id, open: true });
            }}
          >
            <Box>
              <Box>
                <Typography
                  mr={1}
                  className="tw-font-semibold"
                  color={canOpenProfile(member) ? 'primary' : 'secondary'}
                >
                  {member.name || member?.email}
                </Typography>
              </Box>
              {!!member.parentName && (
                <Typography color="secondary" mr={1} style={fontStyle.small}>
                  Parent: {member.parentName}
                </Typography>
              )}
            </Box>
          </Link>
        );
      },
      sortable: false,
      width: '250px',
    },
    {
      id: 'email',
      name: t('channel.memberships.column.email'),
      selector: 'email',
      sortable: false,
      width: '230px',
      cell: (member) => (
        <Typography color="secondary" mr={1} style={fontStyle.label}>
          {member.email}
        </Typography>
      ),
    },
    {
      id: 'phone',
      name: t('channel.memberships.column.phone'),
      selector: 'phone',
      sortable: false,
      width: '230px',
      cell: (member) => member.phone && <LabelPhone value={member.phone} />,
    },
    {
      id: 'channelKeys',
      name: t('channel.memberships.column.status'),
      selector: 'keys',
      sortable: false,
      cell: (member) => {
        const memberKeys = member.keys || [];
        const startPlan = memberKeys.find((x) => !!x.notStarted);
        const activeKeys = memberKeys.filter((x) => !x.expired).filter((x) => !x.notStarted);
        return (
          <Box>
            {startPlan && (
              <>
                <Typography color="secondary" style={{ ...fontStyle.small }}>
                  {startPlan.membershipPlan.name}
                </Typography>
                <Typography color="primary" style={{ ...fontStyle.small }}>
                  {t('channel.label.plan.startDate')}&nbsp;
                  {date(startPlan.startAt, channel?.dateFormatReact)}
                </Typography>
                <Divider />
              </>
            )}
            {member.keys &&
              activeKeys.map((key, i) => (
                <Box key={i.toString()}>
                  {!!key.membership && key.membershipPlan && (
                    <>
                      <Typography color="secondary" style={{ ...fontStyle.small }}>
                        {key.membershipPlan && key.membershipPlan.name}
                      </Typography>

                      {key.membershipPlan.online && key.membershipPlan.inPerson && (
                        <Typography color="primary" style={{ ...fontStyle.small }}>
                          {t('workout.setting.online')},&nbsp;
                          {t('workout.setting.inPerson')}
                        </Typography>
                      )}
                      {!key.membershipPlan.online && key.membershipPlan.inPerson && (
                        <Typography color="primary" style={{ ...fontStyle.small }}>
                          {t('workout.setting.inPerson')}
                        </Typography>
                      )}
                      {key.membershipPlan.online && !key.membershipPlan.inPerson && (
                        <Typography color="primary" style={{ ...fontStyle.small }}>
                          {t('workout.setting.online')}
                        </Typography>
                      )}
                      {!key.pauseAt && key.expiryAt && (
                        <Typography color="secondary" style={{ ...fontStyle.small, color: 'grey' }}>
                          {t(key.renewable ? 'channel.memberships.renewIn' : 'expires')}&nbsp;
                          {date(key.expiryAt, channel?.dateFormatReact)}
                        </Typography>
                      )}
                      {!!key.pauseAt && key.expiryAt && (
                        <Typography color="secondary" style={{ ...fontStyle.small, color: 'grey' }}>
                          {t('pausedAt')}&nbsp;
                          {date(key.pauseAt, channel?.dateFormatReact)}
                        </Typography>
                      )}
                      {key.planExpiryAt && (
                        <Typography color="primary" style={{ ...fontStyle.small }}>
                          {t('channel.memberships.planExpiresIn')}&nbsp;
                          {date(key.planExpiryAt, channel?.dateFormatReact)}
                        </Typography>
                      )}
                      {key?.limitUse && (
                        <Typography color="secondary" style={{ ...fontStyle.small, color: 'grey' }}>
                          {key?.limitUse}
                        </Typography>
                      )}
                    </>
                  )}
                  {!key.membership && (
                    <>
                      <Box>
                        <Typography color="secondary" style={{ ...fontStyle.small }}>
                          Channel Key
                        </Typography>
                      </Box>
                      <Typography color="primary" style={{ ...fontStyle.small }}>
                        {key.kind === 'online'
                          ? t('workout.setting.online')
                          : t('workout.setting.inPerson')}
                      </Typography>
                    </>
                  )}

                  {i < member.keys.length - 1 && <Divider />}
                </Box>
              ))}
          </Box>
        );
      },
    },
    {
      id: 'memberAnniversaryDate',
      name: t('channel.memberships.column.joinedDate'),
      selector: 'joinedDate',
      sortable: false,
      cell: (member) => <Typography>{member.memberAnniversaryDate}</Typography>,
    },
    {
      id: 'defaultPaymentInfo',
      name: t('channel.memberships.column.card'),
      selector: 'defaultPaymentInfo',
      sortable: false,
      cell: (member) => <Typography>{member.defaultPaymentInfo}</Typography>,
    },
    {
      id: 'memberTypes',
      sortable: false,
      cell: (member) => (
        <Box display="flex" alignItems="center" className="gap-5">
          {member.memberTypes.map((tp) => (
            <Box
              key={`${member?.id}-${tp}`}
              style={{
                backgroundColor: palette.primary.main,
                padding: 3,
                borderRadius: 10,
                color: 'white',
              }}
              className="hover"
            >
              <Typography style={fontStyle.small} noWrap className="tw-font-semibold">
                {t(`channel.memberships.column.type.${tp}`)}
              </Typography>
            </Box>
          ))}
        </Box>
      ),
    },
    {
      id: 'actions',
      name: '',
      selector: '',
      sortable: false,
      right: true,
      cell: (member) => (
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          {member?.memberTypes?.find((x) => x === 'lead') && (
            <ButtonPrimarySm onClick={() => changeLead(member)}>Uncked Lead</ButtonPrimarySm>
          )}
          &nbsp;
          <IconButton size="small" onClick={() => inactiveMember(member)}>
            <Delete className="tw-text-gray-700" />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <>
      {!!pagination?.currentPage && (
        <Pagination
          perPage={perPage}
          setPerPage={setPerPage}
          pagination={pagination}
          search={search}
        />
      )}

      <SFDataTable
        title=""
        noHeader
        showPaper={false}
        columns={columns}
        data={members}
        exportData={members.map((member) => ({
          userName: member.name,
          userPhone: member.phone,
          userEmail: member.email,
          DBO: member.birthday || '',
          joinedDate: member.memberAnniversaryDate || '',
        }))}
        showExport={false}
        noDataComponent=""
        // pagination
        // paginationRowsPerPageOptions={[10, 25, 50]}
        // paginationPerPage={perPage}
        // onChangePage={(p) => pagination?.currentPage !== p && search(p)}
        // paginationServer
        // paginationDefaultPage={pagination?.currentPage}
        // paginationTotalRows={pagination?.totalCount}
        // onChangeRowsPerPage={(newValue) => {
        //   // setPerPage(newValue);
        // }}
      />

      {pagination?.currentPage && (
        <div className="tw-mt-2">
          <Pagination
            perPage={perPage}
            setPerPage={setPerPage}
            pagination={pagination}
            search={search}
          />
        </div>
      )}
    </>
  );
};

const allowedMemberTypes = new Set([
  'user',
  'membership_inactive',
  'membership_active',
  'child',
  'lead',
  'customer',
]);

const canOpenProfile = (member) => member.memberTypes.some((y) => allowedMemberTypes.has(y));

TableMembers.defaultProps = {
  members: [],
  setModalMemberInformation: () => {},
  loading: false,
};

export default TableMembers;
