import React from 'react';
import propTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import Typography from 'core/ui/Typography';
import Texture from 'core/ui/Texture';
import FormBulkEdit from './BulkForm/Form';

const ModalBulkEditRecurring = ({ open, close, channel, selectedRecurrings, callback }) => {
  const { t } = useTranslation();

  const callbackSave = () => {
    close();
    if (callback) {
      callback();
    }
  };

  return (
    <Dialog
      PaperProps={{ style: { borderRadius: 5 } }}
      maxWidth="lg"
      scroll="body"
      onClose={() => close()}
      open={open}
      fullWidth
    >
      <Texture>
        <Box p={3}>
          <Typography mb={5} variant="h2" align="center" color="primary">
            {t('workout.form.bulkEdit')}
          </Typography>
          <FormBulkEdit
            channel={channel}
            callbackSave={callbackSave}
            recurringsIds={selectedRecurrings}
          />
        </Box>
      </Texture>
    </Dialog>
  );
};

ModalBulkEditRecurring.propTypes = {
  open: propTypes.bool,
  close: propTypes.func,
  callback: propTypes.func,
};

ModalBulkEditRecurring.defaultProps = {
  open: false,
  close: null,
  callback: null,
};

export default ModalBulkEditRecurring;
