import React from 'react';
import { Skeleton } from '@material-ui/lab';
import Box from '../Box';
import { primary } from '../Colors';

export default ({ loading, color, height }) => {
  const style = {
    position: 'relative',
    flex: 1,
    top: 0,
    zIndex: 9999,
  };
  const styleSkeleton = {
    background: color || primary,
    width: '100%',
  };
  return (
    <Box style={style}>
      {loading && (
        <Skeleton style={styleSkeleton} animation="pulse" height={height || 5} width="100%" />
      )}
    </Box>
  );
};
