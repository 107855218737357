import api from 'core/api';

export const channelCalendarsModule = 'channelCalendars';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const loadingWorkoutsStates = {
  start: { loadingWorkouts: true },
  error: { loadingWorkouts: false },
  success: { loadingWorkouts: false },
};

const actions = {
  getCalendar: {
    module: channelCalendarsModule,
    name: 'getCalendar',
    api: (data) => api.get(`/channels/${data.channelId}/calendar_workouts`, { params: data }),
    params: {
      start: ['params'],
      error: [''],
      success: ['data'],
    },
    state: loadingStates,
  },
  getCalendarDots: {
    module: channelCalendarsModule,
    name: 'getCalendarDots',
    api: (data) => api.get(`/channels/${data.channelId}/calendar_dots`, { params: data }),
    params: {
      start: ['params'],
      error: [''],
      success: ['dots'],
    },
    state: loadingStates,
  },
  getCalendarGroupWorkouts: {
    module: channelCalendarsModule,
    name: 'getCalendarGroupWorkouts',
    api: (data) => api.get(`/channels/${data.channelId}/calendar_group_workouts`, { params: data }),
    params: {
      start: ['params'],
      error: [''],
      success: ['calendarData'],
    },
    state: loadingWorkoutsStates,
  },
};

export default {
  actions,
  state: {
    loading: false,
    loadingWorkouts: false,
    calendarData: {},
    data: [],
    dots: {},
  },
};
