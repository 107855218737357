import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSelectors from 'modules/map/useSelectors';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import Paper from 'core/ui/Paper';
import Typography from 'core/ui/Typography';
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import Paginator from 'core/ui/Paginator';
import { newCompetitionsModule } from 'modules/competitions/new';
import LoaderSm from 'core/ui/LoaderSm';
import Divider from 'core/ui/Divider';
import ImageMedia from 'components/Profile/ImageMedia';
import LeaderboardFilters from '../Results/LeaderboardFilters';
import TeamWorkoutItem from './TeamWorkoutItem';

const CompetitionsScoresManage = ({ competition }) => {
  const { t } = useTranslation();
  const [pagination, setPagination] = useState(null);
  const loading = useSelectors(newCompetitionsModule, 'loadingResults');
  const [perPage] = useState(50);
  const [divisionId, setDivisionId] = useState();
  const [regType, setRegType] = useState();
  const [workoutId, setWorkoutId] = useState();
  const [results, setResults] = useState([]);
  const { request } = useActions();

  const getResults = (page = 1) => {
    request({
      action: Modules.newCompetitions.actions.getCompetitionResults,
      data: {
        competitionId: competition.id,
        page,
        per_page: perPage,
        workoutId,
        divisionId: competition.hasDivisions ? divisionId : null,
        regType,
      },
      options: {
        onSuccess: (data, pag) => {
          setResults(data);
          setPagination(pag);
        },
      },
    });
  };

  useEffect(() => {
    if (competition && competition.id && workoutId && (!competition?.hasDivision || !!divisionId)) {
      getResults(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [competition, perPage, workoutId, divisionId, regType]);

  useEffect(() => {
    if (competition?.id) {
      setRegType(competition?.eventType !== 'both' ? competition?.eventType : 'individual');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [competition?.id]);

  return (
    <div>
      <Paper className="paper-rounded opacity-80" p={3}>
        <LeaderboardFilters
          divisionId={divisionId}
          setDivisionId={setDivisionId}
          workoutId={workoutId}
          setWorkoutId={setWorkoutId}
          regType={regType}
          setRegType={setRegType}
        />
      </Paper>

      <div className="tw-mt-2 tw-rounded-md tw-bg-white/70 tw-p-2">
        <div className="tw-flex tw-flex-row ">
          <div className="tw-grow">
            <Typography className="tw-font-bold">{t('competition.workout.competitor')}</Typography>
          </div>
          <LoaderSm loading={loading} />
          {pagination && pagination.currentPage && (
            <Paginator
              pagination={{
                currentPage: pagination.currentPage,
                totalPages: pagination.totalPages,
              }}
              changePage={(p) => getResults(p)}
            />
          )}
        </div>
        <Divider m={2} />
        <Table>
          <TableBody>
            {results?.map((item) => (
              <TableRow key={item?.team?.id}>
                <TableCell className="tw-p-0">
                  <div className="tw-flex tw-flex-row tw-items-center tw-gap-2 tw-p-2">
                    <ImageMedia
                      user={item}
                      image={item?.team?.image || {}}
                      size={40}
                      heightCountry={15}
                      sizeCountry="1em"
                    />
                    <div>
                      <Typography noWrap className="tw-font-bold tw-text-gray-600">
                        {item?.team?.displayName}
                      </Typography>

                      <Typography noWrap className="tw-font-bold tw-text-gray-400">
                        {item?.team?.division?.name}
                      </Typography>
                    </div>
                  </div>
                </TableCell>

                <TeamWorkoutItem
                  competition={competition}
                  item={item}
                  workoutId={workoutId}
                  divisionId={divisionId}
                />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

CompetitionsScoresManage.defaultProps = {};

export default CompetitionsScoresManage;
