import { all, call, put } from 'redux-saga/effects';

import { getErrorMessage } from 'core/api/api-error';

import notifications from 'modules/notifications';

import * as api from './api';
import { equipmentActions as actions } from './index';

// eslint-disable-next-line import/prefer-default-export
export function* getEquipmentAutocomplete({ query }) {
  try {
    const response = yield call(api.equipment, query);
    const equipment = response.data;
    yield put(actions.getEquipmentAutocompleteSuccess(equipment));
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.getEquipmentAutocompleteError(getErrorMessage(error))),
    ]);
  }
}
