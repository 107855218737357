import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form-v5';
import { useDispatch } from 'react-redux';
import notifications from 'modules/notifications';
import { storeCategoriesModule } from 'modules/storeCategories';
import Modules from 'modules';
import useActions from 'modules/map/useActions';
import useSelectors from 'modules/map/useSelectors';
import { formatDateToApi } from 'core/utils/formatters';

export default ({ channelId, partnerId, storeProductId, callback }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { request } = useActions();
  const categories = useSelectors(storeCategoriesModule, 'data');
  const { register, handleSubmit, setValue, watch, reset, errors } = useForm();

  useEffect(() => {
    request({
      action: Modules.storeCategories.actions.getStoreCategories,
      data: {
        ownerableType: 'Channel',
        ownerableId: channelId,
        showInactive: 0,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, channelId]);

  const onSubmit = (values) => {
    if (values?.preSale && (!values?.preSaleStartDate || !values?.preSaleEndDate)) {
      dispatch(notifications.warning('Pre-Sale, Please add start and end date'));
      return;
    }
    const data = {
      ...values,
    };

    request({
      action: Modules.partners.actions.assignProductChannel,
      data: {
        ...data,

        preSaleStartDate: data?.preSaleStartDate ? formatDateToApi(data?.preSaleStartDate) : null,
        preSaleEndDate: data?.preSaleEndDate ? formatDateToApi(data?.preSaleEndDate) : null,
        channelId,
        partnerId,
        storeProductId,
      },
      options: {
        onSuccess: () => {
          dispatch(notifications.success(t('message.success.save.storeProducts')));
          if (callback) {
            callback(values.name);
          }
        },
      },
    });
  };

  useEffect(() => {
    register('storeCategoryId', { required: true });
    register('showInCompetitions', { required: false });
    register('preSale', { required: false });
    register('preSaleStartDate', { required: false });
    register('preSaleEndDate', { required: false });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register]);

  useEffect(() => {
    reset({});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeProductId]);

  return {
    register,
    setValue,
    watch,
    reset,
    errors,
    onSubmit,
    handleSubmit,

    categories,
  };
};
