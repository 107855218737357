import React from 'react';
import { useTranslation } from 'react-i18next';
import VideoRecorder from 'react-video-recorder';

import { Box, Dialog, DialogContent } from '@material-ui/core';
import DialogTitle from 'core/ui/Dialog/DialogTitle';
import { useDispatch } from 'react-redux';
import { appActions } from 'modules/app';
import Actions from './Actions';

export default ({ open, setOpen, setVideo, duration }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <Dialog open={open} maxWidth="xl" fullWidth>
      <DialogTitle onClose={() => setOpen(false)}>{t('workout.previewVideo')}</DialogTitle>
      <DialogContent style={{ minHeight: 400 }}>
        <Box className="container-aspect container-aspect-47">
          <Box className="item-aspect">
            <VideoRecorder
              timeLimit={duration || 60000}
              showReplayControls
              replayVideoAutoplayAndLoopOff
              renderDisconnectedView={() => <Box />}
              onRecordingComplete={(videoBlob) => {
                dispatch(appActions.setLoading(true));
                const reader = new FileReader();
                reader.readAsDataURL(videoBlob);

                const url = URL.createObjectURL(videoBlob);
                reader.onloadend = () => {
                  const base64data = reader.result;
                  dispatch(appActions.setLoading(false));
                  setVideo({
                    data: base64data,
                    type: videoBlob.type,
                    url,
                  });
                };
              }}
              renderActions={(props) => <Actions {...props} setOpen={setOpen} />}
            />
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
