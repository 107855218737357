/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography, { fontStyle } from 'core/ui/Typography';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import Box from 'core/ui/Box';
import Radio from 'core/ui/Radio';
import { calculateServiceFees, valueTaxAmount } from 'core/utils/helpers';
import Paper from 'core/ui/Paper';
import { currency } from 'core/utils/formatters';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import ServicesFees from './ServicesFees';

const FormPrices = ({ channel, currentPlan, setCurrentPlan }) => {
  const { t } = useTranslation();

  const salesTax =
    // eslint-disable-next-line eqeqeq
    channel?.channelsTaxes?.find((x) => x.id == currentPlan?.channelsTaxId) || null;

  return (
    <>
      <Paper className="paper-rounded" p={3} mb={1}>
        <Box display="flex" mb={3}>
          {currentPlan?.renewable && (
            <Box display="flex" flexDirection="column" alignItems="center" ml={3}>
              <Typography style={fontStyle.label} mx={5}>
                {t('channel.memberships.introductoryAmount').toUpperCase()}
              </Typography>
              <Box display="flex">
                <Box mr={3}>
                  <Radio
                    label={t('button.yes')}
                    checked={!!currentPlan.useIntroductoryAmount}
                    onClick={() =>
                      setCurrentPlan((prev) => ({ ...prev, useIntroductoryAmount: true }))
                    }
                  />
                </Box>
                <Radio
                  label={t('button.no')}
                  checked={!currentPlan.useIntroductoryAmount}
                  onClick={() =>
                    setCurrentPlan((prev) => ({
                      ...prev,
                      useIntroductoryAmount: false,
                      introductoryAmount: 0,
                    }))
                  }
                />
              </Box>
            </Box>
          )}
        </Box>
        <Box mb={3}>
          <TextFieldRounded
            type="number"
            pattern="[0-9]*"
            value={currentPlan.amount || ''}
            placeholder={t('channel.placeholder.membershipsAmount')}
            label={t('channel.label.membershipsAmount').toUpperCase()}
            onChange={(v) => setCurrentPlan((prev) => ({ ...prev, amount: v >= 0 ? v : 0 }))}
            required
            currency
            currencySymbol={channel?.currency?.symbol}
            bordered
          />
        </Box>
        <Box mb={3}>
          {currentPlan.useIntroductoryAmount && (
            <Box mt={3} mb={3}>
              <TextFieldRounded
                type="number"
                value={currentPlan.introductoryAmount || ''}
                pattern="[0-9]*"
                placeholder={t('channel.placeholder.membershipsIntroductoryAmount')}
                label={t('channel.label.membershipsIntroductoryAmount').toUpperCase()}
                onChange={(v) =>
                  setCurrentPlan((prev) => ({ ...prev, introductoryAmount: v >= 0 ? v : 0 }))
                }
                required
                currency
                currencySymbol={channel?.currency?.symbol}
                bordered
              />
            </Box>
          )}
        </Box>

        <Box display="flex">
          <Box>
            <Typography style={fontStyle.label} mb={2} variant="body1">
              {t('channel.product.label.addSalesTax').toUpperCase()}
            </Typography>
            <Box display="flex">
              <Radio
                onClick={() => setCurrentPlan((prev) => ({ ...prev, addSalesTax: true }))}
                checked={currentPlan.addSalesTax}
                label="Yes"
              />
              <Box ml={3}>
                <Radio
                  onClick={() => setCurrentPlan((prev) => ({ ...prev, addSalesTax: false }))}
                  checked={!currentPlan.addSalesTax}
                  label="No"
                />
              </Box>
            </Box>
          </Box>
          {currentPlan.addSalesTax && (
            <Box ml={3} flexGrow={1}>
              <SelectBoxObject
                options={channel?.channelsTaxes}
                value={currentPlan?.channelsTaxId}
                setValue={(v) => setCurrentPlan((prev) => ({ ...prev, channelsTaxId: v }))}
                label={t('channel.label.selectTheTax').toUpperCase()}
                bordered
              />
            </Box>
          )}
        </Box>
      </Paper>

      {channel?.useMembershipServiceFee && (
        <Paper className="paper-rounded" p={3}>
          <ServicesFees
            channel={channel}
            fees={currentPlan?.fees || []}
            setFees={(fees) => setCurrentPlan((prev) => ({ ...prev, fees }))}
          />
        </Paper>
      )}

      {!!currentPlan.amount && (
        <Paper className="paper-rounded" p={3} mt={1}>
          <Box>
            {currentPlan.useIntroductoryAmount && (
              <Typography mx={2} variant="body2">
                {t('channel.membership.plan.introductoryCost')}&nbsp;{channel?.currency?.symbol}
                {currency(
                  calculateServiceFees(currentPlan, currentPlan.introductoryAmount) +
                    valueTaxAmount(
                      calculateServiceFees(currentPlan, currentPlan.introductoryAmount || 0),
                      currentPlan?.addSalesTax ? salesTax : null,
                    ),
                )}
              </Typography>
            )}
            {currentPlan.amount > 0 && (
              <Typography mx={2} variant="body2">
                {t('channel.membership.plan.membershipPrice').replace(
                  '{period}',
                  t(`channel.memberships.${currentPlan.renewFrequency}`),
                )}
                &nbsp;{channel?.currency?.symbol}
                {currency(
                  calculateServiceFees(currentPlan, currentPlan.amount) +
                    valueTaxAmount(
                      calculateServiceFees(currentPlan, currentPlan.amount || 0),
                      currentPlan?.addSalesTax ? salesTax : null,
                    ),
                )}
              </Typography>
            )}
          </Box>
        </Paper>
      )}
    </>
  );
};

FormPrices.defaultProps = {
  currentPlan: {},
  setCurrentPlan: () => {},
};

export default FormPrices;
