import React from 'react';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import Typography from 'core/ui/Typography';
import { gymDate, time } from 'core/utils/formatters';
import Divider from 'core/ui/Divider';
import Grid from 'core/ui/Grid';
import MIcon from 'core/ui/MIcon';
import { Link, colors } from '@material-ui/core';
import { CardStatus } from '../../WorkflowExecutions';

const Header = ({ channelId, executionsTypesUser }) => {
  const { t } = useTranslation();

  const endAt =
    executionsTypesUser?.execution?.endAt || executionsTypesUser?.execution?.nextExecutionAt;

  return (
    <Box className="gap-10" display="flex" flexDirection="column">
      <Box display="flex" alignItems="center" className="gap-10">
        <Box
          style={{
            backgroundColor: colors.grey[300],
            borderRadius: 5,
          }}
          display="flex"
          alignItems="center"
          justifyContent="center"
          p={1}
        >
          <MIcon
            icon={executionsTypesUser?.execution?.workflowsType?.automationType?.icon}
            color={colors.grey[500]}
          />
        </Box>
        <Typography variant="h5" color="secondary">
          {executionsTypesUser?.execution?.workflowsType?.name}
        </Typography>
      </Box>
      <Divider />
      <Box display="flex" flexDirection="column">
        <Typography color="secondary">{t('label.status')}</Typography>
        <CardStatus status={executionsTypesUser?.execution?.status} />

        {executionsTypesUser?.execution?.status === 'failed' && (
          <Typography color="error">{executionsTypesUser?.execution?.error}</Typography>
        )}
      </Box>

      <Divider />

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Box className="gap-5">
            <Typography color="secondary">{t('label.executedOn')}</Typography>
            <Typography color="textSecondary">
              {gymDate(executionsTypesUser?.execution?.startAt)}&nbsp;
              {time(executionsTypesUser?.execution?.startAt)}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className="gap-5">
            <Typography color="secondary">{t('label.endAt')}</Typography>
            {endAt && (
              <Typography color="textSecondary">
                {gymDate(endAt)}&nbsp;
                {time(endAt)}
              </Typography>
            )}
          </Box>
        </Grid>
      </Grid>
      <Divider />
      <Box className="gap-5">
        <Typography color="secondary">{t('label.member')}</Typography>
        {executionsTypesUser?.user && (
          <Box>
            <Link
              target="_blank"
              href={`/channel/manage/${channelId}/members/${executionsTypesUser?.user?.id}`}
            >
              <Typography color="textSecondary">{executionsTypesUser?.user?.name}</Typography>
            </Link>
            <Typography color="textSecondary">{executionsTypesUser?.user?.email}</Typography>
            <Typography color="textSecondary">{executionsTypesUser?.user?.phone}</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Header;
