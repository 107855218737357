import React, { useContext, useEffect, useState } from 'react';
import Box from 'core/ui/Box';
import PropTypes from 'prop-types';
import Typography, { fontStyle } from 'core/ui/Typography';

import { useTranslation } from 'react-i18next';
import { typeTimer, boxTimerColor, workoutEvent } from 'core/utils/consts';
import { makeStyles } from '@material-ui/core';
import { Close, FiberSmartRecord } from '@material-ui/icons';
import { ButtonPrimarySm } from 'core/ui/Button';
import { useDispatch, useSelector } from 'react-redux';
import { workoutsActions, workoutsSelectors } from 'modules/workouts';
import { StreamContext } from 'pages/Stream';
import { ComponentsSideBar } from 'components/Stream/SideBar/components';
import { TypesSignal } from 'core/openVidu/useOpenVidu';
import { sessionSelectors } from 'modules/session';
import Draggable from 'react-draggable';
import { primary } from 'core/ui/Colors';
import { roomSelectors } from 'modules/room';
import { isMobile } from 'react-device-detect';
import { sessionWorkoutActions, sessionWorkoutSelectors } from 'modules/sessionWorkout';
import { hhmmss } from 'core/utils/formatters';
import useEvents from 'core/openVidu/useEvents';
import ConfirmDialog from 'core/ui/ConfirmDialog';
import notifications from 'modules/notifications';
import DialogConfirmTime from './DialogConfirmTime';
import Chronometer from './Chronometer';
import TrackRounds from './TrackRounds';
import useNewTimer from './useNewTimer';
import TimerActions from './TimerActions';
import './style.css';

const DraggableTimer = ({ children, live }) => (
  <Box style={{ zIndex: 50 }}>
    {isMobile || !live ? (
      <>{children}</>
    ) : (
      <Draggable defaultPosition={{ x: 0, y: 0 }}>{children}</Draggable>
    )}
  </Box>
);

DraggableTimer.propTypes = {
  children: PropTypes.node.isRequired,
  live: PropTypes.bool,
};

DraggableTimer.defaultProps = {
  live: false,
};

const SectionTimer = ({
  section,
  colorLabel,
  live,
  isCoach,
  preview,
  playingRecordings,
  playingCompetition,
  competitionView,
  zoomView,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const streamContext = useContext(StreamContext);
  const user = useSelector((state) => sessionSelectors.getUser(state));
  const workout = useSelector((state) => workoutsSelectors.getWorkout(state));
  const config = useSelector((state) => roomSelectors.getConfig(state));
  const { startRecording, stopRecording } = useEvents();
  const recording = useSelector((state) => sessionWorkoutSelectors.getRecording(state));
  const sectionUserData = useSelector((state) =>
    workoutsSelectors.getWorkoutSectionUserData(state),
  );

  const [timerStarted, setTimerStarted] = useState(false);

  const requireVerification = workout?.competition?.requireVerification;

  const {
    startTimer,
    doneSection,
    timeValue,
    resetTimer: reset,
    workToSeconds,
    restToSeconds,
    showTimer,
  } = useNewTimer({
    section,
    live,
    playingRecordings,
    playingCompetition,
    isCoach: workout.isCoach,
  });

  const [dialogConfirm, setDialogConfirmTime] = useState({
    open: false,
    callback: () => {},
    time: '00:00:00',
  });

  const [tracking, setTracking] = useState({
    active: false,
    currentRound: 1,
  });

  const classes = useStyles({ timeValue });
  const [dialogConfirmStartTimer, setDialogConfirmStartTimer] = useState({});

  const closeTimer = () => {
    if (streamContext && streamContext.eventSideBar) {
      streamContext.eventSideBar.setCurrentSection(null);
    }
  };

  const handleDoneSection = () => {
    if (streamContext.countDownCompetition.done) {
      return;
    }

    let userScores =
      sectionUserData[section.id] && sectionUserData[section.id].scores
        ? [...sectionUserData[section.id].scores]
        : [];

    const value =
      section.timerData.count.toUpperCase() === 'UP'
        ? timeValue.timeDisplay
        : workToSeconds - timeValue.timeDisplay;

    if (!workout.isCompetition) {
      setDialogConfirmTime({
        open: true,
        time: hhmmss(value),
        callback: (time) => {
          if (userScores.length > 0) {
            userScores = userScores.map((x, index) => {
              if (index === 0) {
                return { ...x, time: hhmmss(value), completion: true };
              }
              return x;
            });
          } else {
            userScores.push({ time, completion: true });
          }

          const data = {
            scores: userScores,
            sectionId: section.id,
          };
          dispatch(
            workoutsActions.setWorkoutSectionUserStart(
              {
                workoutId: workout.id,
                data,
              },
              () => {
                dispatch(notifications.success(t('message.success.save.score')));
              },
            ),
          );
          dispatch(
            sessionWorkoutActions.postEventStart({
              workoutId: workout.id,
              kind: workoutEvent.done_section,
              data,
            }),
          );
          doneSection(time);
          setDialogConfirmTime((prev) => ({ ...prev, open: false }));
          streamContext.openSideBar({ component: ComponentsSideBar.Sections, open: true });
        },
      });
    } else {
      if (userScores.length > 0) {
        userScores = userScores.map((x, index) => {
          if (index === 0) {
            return { ...x, time: hhmmss(value), completion: true };
          }
          return x;
        });
      } else {
        userScores.push({ time: hhmmss(value), completion: true });
      }

      const data = {
        scores: userScores,
        sectionId: section.id,
      };

      dispatch(
        workoutsActions.setWorkoutSectionUserStart(
          {
            workoutId: workout.id,
            data,
          },
          () => {
            dispatch(notifications.success(t('message.success.save.score')));
            dispatch(
              sessionWorkoutActions.postEventStart({
                workoutId: workout.id,
                kind: workoutEvent.done_section,
                data,
              }),
            );
            if (recording && recording.active) {
              stopRecording({ callback: () => {} });
            }

            streamContext.setOpenModalEnded((prev) => ({
              ...prev,
              edit: true,
              open: true,
              close: true,
            }));
            closeTimer();
          },
        ),
      );
    }
  };

  const coachResetTimer = () => {
    setTimerStarted(false);
    if (live) {
      dispatch(
        sessionWorkoutActions.postEventStart({
          workoutId: workout.id,
          kind: workoutEvent.reset_timer,
        }),
      );
    }
    reset(true);
  };

  const coachStartTimer = () => {
    if (timerStarted) {
      return false;
    }

    if (live) {
      if (!workout.isCompetition) {
        streamContext.eventSideBar.sendSignalUserChanged({
          type: TypesSignal.startTimer,
          userId: user.id,
        });
      }
      dispatch(
        sessionWorkoutActions.postEventStart({
          workoutId: workout.id,
          kind: workoutEvent.start_timer,
          data: section,
        }),
      );
    }
    setTimerStarted(true);
    startTimer();
    return true;
  };

  const competitionStartTimer = () => {
    const start = () => {
      startTimer();
      dispatch(
        sessionWorkoutActions.postEventStart({
          workoutId: workout.id,
          kind: workoutEvent.start_timer,
          data: section,
        }),
      );
    };

    if (requireVerification && recording && recording.active) {
      setDialogConfirmStartTimer((prev) => ({
        ...prev,
        open: true,
        description: t('competition.timer.start'),
        labelCancel: t('button.cancel'),
        labelConfirm: t('button.yes'),
        callback: () => {
          start();
          streamContext.setCountDownCompetition({ open: false });
        },
      }));
    } else {
      setDialogConfirmStartTimer((prev) => ({
        ...prev,
        open: true,
        description: !requireVerification
          ? t('competition.timer.start')
          : t('competition.record.requireVerification'),
        labelCancel: t(requireVerification ? 'button.notReady' : 'button.cancel'),
        labelConfirm: t(requireVerification ? 'button.ok' : 'button.yes'),
        callback: () => {
          startRecording({
            callback: () => {
              if (!requireVerification) {
                start();
              } else {
                streamContext.setCountDownCompetition({ open: true });
              }
            },
          });
        },
      }));
    }
  };

  const countDownValue = () => {
    const v = timeValue.timeDisplay;
    if (v === 0 && (timeValue.active === 'rest' || timeValue.active === 'countdown')) {
      return 'GO';
    }
    if (timeValue.active === 'countdown') {
      return v;
    }
    if (timeValue.active === 'work') {
      if (section?.timerData?.count?.toUpperCase() === 'UP' && workToSeconds - v <= 3) {
        return workToSeconds - v;
      }
      if (section?.timerData?.count?.toUpperCase() === 'DOWN' && v <= 3) {
        return v;
      }
    }
    if (timeValue.active === 'rest') {
      let value = '';
      if (section.timerData.count.toUpperCase() === 'UP' && restToSeconds - v <= 3) {
        value = restToSeconds - v;
      }
      if (section.timerData.count.toUpperCase() === 'DOWN' && v === 0) {
        return 'GO';
      }
      if (section.timerData.count.toUpperCase() === 'UP' && v === restToSeconds) {
        return 'GO';
      }
      if (section.timerData.count.toUpperCase() === 'DOWN' && v <= 3) {
        value = v;
      }
      return value;
    }
    return '';
  };

  useEffect(() => {
    if (
      workout.isCompetition &&
      timeValue.active === '' &&
      recording.active &&
      timeValue.ended &&
      !streamContext.countDownCompetition.done
    ) {
      handleDoneSection();
    }
  }, [timeValue]); //eslint-disable-line

  useEffect(() => {
    // if (timeValue.active) {
    setTimerStarted(false);
    // }
  }, [timeValue.active]);

  const showDoneButton =
    (workout.isCompetition && timeValue.active === 'work') ||
    (!competitionView && timeValue.active === 'work' && !timeValue.doneAt && !preview);

  return (
    <>
      <DialogConfirmTime
        open={dialogConfirm.open}
        setOpen={(v) => setDialogConfirmTime((prev) => ({ ...prev, open: v }))}
        time={dialogConfirm.time}
        confirm={dialogConfirm.callback}
      />

      <ConfirmDialog
        open={dialogConfirmStartTimer.open}
        setOpen={(b) => setDialogConfirmStartTimer((prev) => ({ ...prev, open: b }))}
        title={workout.isCompetition ? t('pleaseNote').toUpperCase() : null}
        styleTitle={{ fontSize: 26, color: primary, display: 'flex', justifyContent: 'center' }}
        description={dialogConfirmStartTimer.description}
        labelConfirm={dialogConfirmStartTimer.labelConfirm || t('button.yes')}
        labelCancel={dialogConfirmStartTimer.labelCancel || t('cancel')}
        styleBody={{ fontSize: 20, color: '#000' }}
        confirm={dialogConfirmStartTimer.callback}
      />

      {timeValue.active && !preview && countDownValue() !== '' && (
        <Box className={classes.boxCountDown}>
          <Typography style={{ ...fontStyle.countdown }}>{countDownValue()}</Typography>
        </Box>
      )}

      {showTimer && (
        <DraggableTimer live={live}>
          <Box className={classes.root} display="flex" flexDirection="column">
            {recording && recording.active && (
              <Box>
                <FiberSmartRecord
                  className={recording.active ? 'recording-effect' : ''}
                  style={{ fontSize: 30, color: recording.active ? primary : 'white' }}
                />
              </Box>
            )}
            {config.trackTimer && (
              <Box>
                <TrackRounds
                  workToSeconds={workToSeconds}
                  section={section}
                  tracking={tracking}
                  setTracking={setTracking}
                  timeValue={timeValue}
                />
              </Box>
            )}
            <Box>
              <Box display="flex" alignItems="stretch">
                <Box
                  px={5}
                  mr={1}
                  py={1}
                  flexDirection="column"
                  display="flex"
                  alignItems="center"
                  className={classes.boxTimer}
                >
                  <Typography variant="caption" style={{ ...fontStyle.medium }}>
                    {section?.title?.toUpperCase()}&nbsp;{t('timer').toUpperCase()}
                  </Typography>

                  {!preview && isCoach && (
                    <Box
                      style={{ position: 'absolute', left: 5, top: 0 }}
                      onClick={() => closeTimer()}
                    >
                      <Close style={{ fontSize: 14 * (zoomView || 1) }} />
                    </Box>
                  )}
                  <Box display="flex" flexDirection="column" alignItems="center">
                    {section?.timerData?.type !== typeTimer.amrap &&
                      section?.timerData?.type !== typeTimer.forTime &&
                      section?.timerData?.rounds > 1 && (
                        <Box>
                          <Typography
                            style={{
                              color: primary,
                              fontSize: 10 * (zoomView || 1),
                            }}
                            variant="caption"
                          >
                            {t('round')}&nbsp;{timeValue.round}/{section.timerData.rounds}
                          </Typography>
                        </Box>
                      )}
                  </Box>
                  <Typography
                    style={{ fontSize: 10 * (zoomView || 1) }}
                    variant="caption"
                    align="center"
                  >
                    {timeValue.active.toUpperCase() || ''}
                  </Typography>

                  <Chronometer timeValue={timeValue} zoomView={zoomView} />
                </Box>
                {showDoneButton && (
                  <ButtonPrimarySm
                    onClick={() => handleDoneSection()}
                    style={{ width: 120, zIndex: 9999 }}
                  >
                    {t('done').toUpperCase()}
                  </ButtonPrimarySm>
                )}
              </Box>

              <TimerActions
                workout={workout}
                colorLabel={colorLabel}
                timeValue={timeValue}
                start={() => (workout.isCompetition ? competitionStartTimer() : coachStartTimer())}
                reset={() => coachResetTimer()}
                isCoach={isCoach}
                timerStarted={timerStarted}
                requireVerification={requireVerification}
                recordingActive={recording && recording.active}
              />
            </Box>
          </Box>
        </DraggableTimer>
      )}
    </>
  );
};

const useStyles = makeStyles({
  root: {
    height: '100%',
    cursor: 'pointer',
  },
  boxTimer: ({ timeValue }) => ({
    background: boxTimerColor[timeValue.active || 'stop'].bg || '#eee',
    color: boxTimerColor[timeValue.active || 'stop'].font,
    borderRadius: 5,
    justifyContent: 'center',
    position: 'relative',
    height: 'auto',
    zIndex: 2,
    flex: 1,
    boxShadow: '0px 2px 10px 0px rgba(0,0,0,0.2)',
  }),
  boxDone: {
    background: '#4fb94a',
    borderRadius: 5,
    marginLeft: 3,
  },
  boxCountDown: {
    position: 'fixed',
    zIndex: 1000,
    left: `calc(50%)`,
    top: `calc(50% - 60px)`,
    color: '#fff',
  },
});

export default SectionTimer;

SectionTimer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  section: PropTypes.object,
  colorLabel: PropTypes.string,
  live: PropTypes.bool,
  isCoach: PropTypes.bool,
  preview: PropTypes.bool,
  playingRecordings: PropTypes.bool,
  playingCompetition: PropTypes.bool,
  competitionView: PropTypes.bool,
};

SectionTimer.defaultProps = {
  section: {},
  live: false,
  colorLabel: '#000',
  isCoach: true,
  preview: false,
  playingRecordings: false,
  playingCompetition: false,
  competitionView: false,
};
