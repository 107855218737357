import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';

import Typography, { fontStyle } from 'core/ui/Typography';

import Radio from 'core/ui/Radio';
import { WorkoutContext } from '../index';

export default () => {
  const { t } = useTranslation();
  const formContext = useContext(WorkoutContext);

  return (
    <Box>
      <Typography mb={2} style={fontStyle.label} variant="body1">
        {t('workout.label.useHeats').toUpperCase()}
      </Typography>

      <Box display="flex" alignItems="center">
        <Radio
          onClick={() => formContext.setValue('useHeats', false)}
          checked={!formContext.watch('useHeats')}
          label={t(`button.no`).toUpperCase()}
        />
        <Box ml={2}>
          <Radio
            onClick={() => formContext.setValue('useHeats', true)}
            checked={formContext.watch('useHeats')}
            label={t(`button.yes`).toUpperCase()}
          />
        </Box>
      </Box>
    </Box>
  );
};
