import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import Typography, { fontStyle } from 'core/ui/Typography';

import { KeyboardTimePicker } from '@material-ui/pickers';
import { dateTimeZone, formatDateToCurrentTimeZone } from 'core/utils/formatters/date';
import Box from '../Box';
import FontIcon from '../FontIcon';
import { secondaryLight } from '../Colors';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: 5,
    width: '130px',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontSize: 12,
    paddingLeft: 5,
    borderBottom: 'none',
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export default function TimePickerRounded({
  label,
  name,
  placeholder,
  error,
  onChange,
  value,
  required,
  stylePaper,
  bordered,
  autoOk,
}) {
  const [val, setVal] = useState(null);
  const [open, setOpen] = useState(false);

  const handleDateChange = (date) => {
    const d = dateTimeZone(date);
    setVal(date);
    onChange(d);
  };

  useEffect(() => {
    const v = value;
    if (v !== dateTimeZone(val)) {
      setVal(formatDateToCurrentTimeZone(value));
    }
  }, [value]); //eslint-disable-line

  const classes = useStyles();

  const styleInput = error
    ? stylePaper
    : { ...stylePaper, border: bordered ? `1px solid${secondaryLight}` : 'none' };

  return (
    <div style={{ width: '100%' }}>
      {label !== '' && (
        <Box ml={2} mb={2} display="flex">
          <Typography style={fontStyle.label} variant="body1">
            {label}
          </Typography>
          {required && (
            <Typography ml={1} color="error">
              *
            </Typography>
          )}
        </Box>
      )}
      <Paper className={`${error ? 'input-paper-error' : ''} ${classes.root}`} style={styleInput}>
        <KeyboardTimePicker
          variant="inline"
          open={open}
          keyboardIcon={<FontIcon iconName="icon-clock-ok" size={20} color="#757575" />}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          ampm
          name={name}
          value={val}
          onClick={() => setOpen(true)}
          placeholder={placeholder}
          inputVariant="standard"
          onChange={(v) => handleDateChange(v)}
          disablePast
          format="h:mma"
          className="inputDatePicker"
          InputProps={{ className: classes.input }}
          autoOk={autoOk}
        />
      </Paper>
    </div>
  );
}

TimePickerRounded.propTypes = {
  name: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  error: PropTypes.object,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  value: PropTypes.string,
  required: PropTypes.bool,
  autoOk: PropTypes.bool,
};

TimePickerRounded.defaultProps = {
  name: null,
  error: null,
  placeholder: '',
  onChange: null,
  label: '',
  value: null,
  required: false,
  autoOk: true,
};
