import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography, { fontStyle } from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import { secondary, onlineColor, secondaryXLight, redColor } from 'core/ui/Colors';
import { ButtonGreenSm, ButtonYellowSm } from 'core/ui/Button';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { IconButton, Chip } from '@material-ui/core';
import LoaderSm from 'core/ui/LoaderSm';
import { date, dateDayOfWeek, time } from 'core/utils/formatters';
import Checkbox from 'core/ui/Checkbox';
import WorkoutsRecurring from './WorkoutsRecurring';

const useStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
  divider: {
    background: secondaryXLight,
    color: secondary,
    borderWidth: 0.5,
    width: '100%',
    margin: 5,
  },
});

function RowTableRecurring({
  channel,
  rec,
  editRecurring,
  extendRecurring,
  setRefreshCalendar,
  checkedItems,
  toggleItem,
}) {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const { t } = useTranslation();
  const styleOpen = {
    outlineTop: `1px solid ${secondaryXLight}`,
    outlineLeft: `1px solid ${secondaryXLight}`,
    outlineRight: `1px solid ${secondaryXLight}`,
    outlineOffset: '-1px',
  };

  const activeRecurring = () => rec && rec.countLiveWorkouts > 0;

  const statusProcessing = () => rec.status === 'processing';

  return (
    <>
      <TableRow style={open ? styleOpen : {}} className={classes.root}>
        <TableCell>
          <Box display="flex">
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
            <Checkbox
              checked={!!checkedItems.find((x) => x === rec?.id)}
              onClick={() => toggleItem(rec?.id)}
            />
          </Box>
        </TableCell>

        <TableCell onClick={() => setOpen(!open)}>
          <Typography style={fontStyle.medium}>
            {rec.id}
            {rec?.workout?.name && ` - ${rec?.workout?.name}`}
          </Typography>
          {rec?.workout?.channelsWorkoutsType && (
            <Typography style={fontStyle.xSmall} color="textSecondary">
              {rec?.workout?.channelsWorkoutsType?.name}
            </Typography>
          )}
        </TableCell>
        <TableCell onClick={() => setOpen(!open)}>
          <Typography noWrap>
            {rec.frequency === 'daily' ? (
              <>Daily&nbsp;&bull;&nbsp;</>
            ) : (
              <>{dateDayOfWeek(rec?.workout?.scheduledAt)}&nbsp;&bull;&nbsp;</>
            )}
            <Typography component="span" color="primary">
              {time(rec?.workout?.scheduledAt, rec?.workout?.timezone)}
            </Typography>
          </Typography>
        </TableCell>
        <TableCell onClick={() => setOpen(!open)} align="center">
          {rec.frequency.toUpperCase()}
        </TableCell>
        <TableCell onClick={() => setOpen(!open)} align="center">
          {rec.autoRenew ? 'Yes' : 'No'}
        </TableCell>
        <TableCell onClick={() => setOpen(!open)} align="center">
          {rec.countLiveWorkouts}
        </TableCell>
        <TableCell align="center">
          <Typography
            onClick={() => (activeRecurring(rec) ? null : extendRecurring(rec))}
            className="link-hover"
            variant="caption"
          >
            {!!rec.inactiveDate || !activeRecurring() ? (
              <RadioButtonUncheckedIcon style={{ ...fontStyle.xLarge, color: redColor }} />
            ) : (
              <CheckCircleOutlineIcon style={{ ...fontStyle.xLarge, color: onlineColor }} />
            )}

            {!!rec.inactiveDate && (
              <Typography color="textSecondary" style={fontStyle.xSmall}>
                {t('inactiveDate')}&nbsp;
                {date(rec.inactiveDate, channel?.dateFormatReact)}
              </Typography>
            )}
          </Typography>
        </TableCell>
        <TableCell align="center">
          {!statusProcessing() && activeRecurring() && (
            <ButtonGreenSm onClick={() => editRecurring(rec)}>{t('button.edit')}</ButtonGreenSm>
          )}
        </TableCell>
        <TableCell align="center">
          {!statusProcessing() ? (
            <ButtonYellowSm onClick={() => extendRecurring(rec)}>
              {t('button.extend')}
            </ButtonYellowSm>
          ) : (
            <Chip
              icon={<LoaderSm loading />}
              color="primary"
              label={t('channel.recurring.processing')}
            />
          )}
        </TableCell>
      </TableRow>
      <TableRow style={open ? styleOpen : {}}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={11}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box mx={1} mt={3} mb={5}>
              <WorkoutsRecurring recurring={rec} setRefreshCalendar={setRefreshCalendar} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

RowTableRecurring.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  rec: PropTypes.object.isRequired,
  editRecurring: PropTypes.func.isRequired,
  extendRecurring: PropTypes.func.isRequired,
};

export default RowTableRecurring;
