import React, { memo, useEffect, useMemo, useState } from 'react';
import { Add, Close, ViewColumn, ViewColumnOutlined } from '@material-ui/icons';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import clsx from 'clsx';
import moment from 'moment';
import { IconButton, Paper, Tab, Tabs } from '@material-ui/core';

import Modules from 'modules';
import useActions from 'modules/map/useActions';
import { showAPIError } from 'core/utils/showAPIError';
import TableLogs from './Table';
import { Form } from './Form';

const tabValues = {
  auditableType: 'ChannelKey',
  auditableId: '1',
  userId: '',
  skipDate: false,
  startAt: moment().startOf('month').format('YYYY-MM-DD'),
  endAt: moment().format('YYYY-MM-DD'),
};

const AuditedLogsReport = () => {
  const { control, register, getValues } = useForm({
    defaultValues: {
      tabs: [tabValues],
    },
  });

  const { fields, append, remove } = useFieldArray({ control, name: 'tabs' });

  const [currentIndex, setCurrentIndex] = useState(0);
  const [splitScreen, setSplitScreen] = useState(false);

  const toShowIndexes = useMemo(() => {
    const indexes = new Set();

    indexes.add(currentIndex);
    if (splitScreen) indexes.add(currentIndex + 1);

    return indexes;
  }, [currentIndex, splitScreen]);

  useEffect(() => {
    if (splitScreen && getValues('tabs').length === 1) append(tabValues);
  }, [splitScreen, getValues, append]);

  return (
    <>
      <Paper className="tw-flex tw-border-0 tw-border-t tw-border-solid tw-border-t-neutral-200">
        <Tabs
          value={currentIndex}
          onChange={(_, newIndex) => setCurrentIndex(newIndex)}
          indicatorColor="primary"
          textColor="primary"
          className="tw-w-full"
        >
          {fields.map((field, index) => (
            <TabItem
              key={field.id}
              control={control}
              remove={remove}
              index={index}
              toShowIndexes={toShowIndexes}
            />
          ))}
        </Tabs>

        <IconButton onClick={() => setSplitScreen((sc) => !sc)}>
          {splitScreen ? <ViewColumn /> : <ViewColumnOutlined />}
        </IconButton>

        <IconButton onClick={() => append(tabValues)}>
          <Add />
        </IconButton>
      </Paper>

      <div className="tw-flex tw-gap-x-2">
        {fields.map((field, index) => (
          <div
            className={clsx(
              'tw-flex tw-flex-1 tw-flex-col',
              !toShowIndexes.has(index) && 'tw-hidden',
            )}
          >
            <Page
              key={field.id}
              control={control}
              index={index}
              register={register}
              getValues={getValues}
            />
          </div>
        ))}
      </div>
    </>
  );
};

const TabItem = ({ control, remove, index, toShowIndexes, selected, ...rest }) => {
  const [auditableType, auditableId] = useWatch({
    control,
    name: [`tabs.${index}.auditableType`, `tabs.${index}.auditableId`],
  });

  return (
    <div className="tw-group tw-relative">
      <Tab
        value={index}
        label={`${auditableType} ${auditableId}`}
        selected={selected || toShowIndexes.has(index)}
        {...rest}
      />

      <Close
        onClick={() => remove(index)}
        className="tw-absolute tw-right-1.5 tw-top-1.5 tw-rounded-full tw-p-0.5 tw-text-[18px] tw-opacity-0 tw-transition-all hover:tw-bg-neutral-100 group-hover:tw-opacity-100"
      />
    </div>
  );
};

const Page = memo(({ index, control, register, getValues }) => {
  const { request } = useActions();

  const [audits, setAudits] = useState([]);

  const handleSearch = (e) => {
    e.preventDefault();

    const data = getValues(`tabs.${index}`);

    request({
      action: Modules.adminChannels.actions.getAuditLogs,
      data,
      options: {
        onSuccess: (d) => setAudits(d.logs),
        onError: showAPIError,
      },
    });
  };

  return (
    <>
      <Form index={index} handleSearch={handleSearch} control={control} register={register} />

      <TableLogs audits={audits} />
    </>
  );
});

export default AuditedLogsReport;
