import React, { useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import { TableCell, withStyles } from '@material-ui/core';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useTranslation } from 'react-i18next';
import { secondary, secondaryLight } from 'core/ui/Colors';
import Typography from 'core/ui/Typography';
import Paper from 'core/ui/Paper';
import Checkbox from 'core/ui/Checkbox';

export const Cell = withStyles(() => ({
  head: {
    backgroundColor: secondaryLight,
    color: secondary,
    fontSize: 12,
  },
  body: {
    fontSize: 11,
  },
}))(TableCell);

export default function TableWorkoutsTypes({ channel, checkedItems, setCheckedTypes }) {
  const { t } = useTranslation();
  const [checkedAll, setCheckedAll] = useState(false);

  const wTypes = channel?.workoutsTypes?.filter((x) => !!x.active);

  const toggleItem = (planId) => {
    if (checkedItems.find((x) => x === planId)) {
      setCheckedTypes(checkedItems.filter((x) => x !== planId));
    } else {
      setCheckedTypes([...checkedItems, planId]);
    }
  };

  const toggleSelectAll = () => {
    if (checkedAll) {
      setCheckedTypes([]);
      setCheckedAll(false);
    } else {
      setCheckedTypes(wTypes?.map((x) => x.id));
      setCheckedAll(true);
    }
  };

  useEffect(() => {
    setCheckedAll(wTypes?.length === checkedItems?.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel, checkedItems]);

  return (
    <Paper p={3} className="paper-rounded">
      <Typography mb={3} variant="h5">
        {t('channel.workoutTypes.access')}
      </Typography>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table" size="small">
          <TableHead>
            <TableRow>
              <Cell>
                <Typography className="hover" color="primary" onClick={() => toggleSelectAll()}>
                  {t(checkedAll ? 'search.button.deselectAll' : 'search.button.selectAll')}
                </Typography>
              </Cell>
              <Cell>{t('channel.membership.plan.name')}</Cell>
            </TableRow>
          </TableHead>
          <TableBody>
            {wTypes?.map((plan, index) => (
              <TableRow key={index.toString()}>
                <Cell>
                  <Checkbox
                    checked={checkedItems.find((x) => x === plan?.id)}
                    onClick={() => toggleItem(plan?.id)}
                  />
                </Cell>
                <Cell>
                  <Typography>{plan.name}</Typography>
                </Cell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

TableWorkoutsTypes.defaultProps = {
  membershipPlans: [],
  setModalCoupons: null,
  editPlan: null,
  changeActive: null,
  changeVisible: null,
};
