import React from 'react';
import Paper from 'core/ui/Paper';
import { Controller } from 'react-hook-form';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import { useTranslation } from 'react-i18next';
import DatePickerRounded from 'core/ui/DatePickers/DatePickerRounded';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import { ButtonPrimary } from 'core/ui/Button';
import Toggle from 'core/ui/Toggle';

const CalendarsFilters = ({ control, channel, submit, watch }) => {
  const { t } = useTranslation();
  const staffs = [...(channel.coaches || []), ...(channel.admins || [])];

  return (
    <Paper p={3} mb={2}>
      <div className="tw-flex tw-flex-row tw-flex-wrap tw-items-end tw-gap-6">
        <div className="tw-grow">
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextFieldRounded
                label={t('channel.reports.filter.placeholder.userName').toUpperCase()}
                placeholder={t('channel.reports.filter.placeholder.userName')}
                onChange={(values) => onChange(values)}
                value={value}
                bordered
                style={{ height: 27 }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    submit();
                  }
                }}
              />
            )}
            name="userName"
          />
        </div>

        <div className="tw-grow">
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <div>
                <SelectBoxObject
                  label={t('label.coach').toUpperCase()}
                  options={staffs?.map((x) => ({
                    id: x.userId.toString(),
                    name: x?.user?.name,
                  }))}
                  setValue={(v) => {
                    onChange(v);
                  }}
                  value={value || []}
                  bordered
                  style={{ height: 45 }}
                  multiple
                />
              </div>
            )}
            name="coachIds"
          />
        </div>

        <div className="tw-grow">
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <div>
                <SelectBoxObject
                  label={t('label.classType').toUpperCase()}
                  options={channel?.workoutsTypes}
                  setValue={onChange}
                  value={value}
                  bordered
                  style={{ height: 45 }}
                  emptyItem
                  emptyValue={null}
                />
              </div>
            )}
            name="workoutsTypeId"
          />
        </div>

        <div>
          <Controller
            control={control}
            defaultValue="calendar"
            render={({ field: { onChange, value } }) => (
              <div>
                <Toggle
                  options={[
                    { label: 'Calendar', value: 'calendar' },
                    { label: 'Registrations', value: 'registrations' },
                  ]}
                  onChange={onChange}
                  value={value}
                />
              </div>
            )}
            name="viewType"
          />
        </div>
        {watch('viewType') === 'registrations' && (
          <div className="tw-flex tw-flex-row tw-gap-x-2">
            <div>
              <Controller
                control={control}
                render={({ field: { onChange, value } }) => (
                  <div>
                    <DatePickerRounded
                      label={t('label.scheduleStart').toUpperCase()}
                      onChange={onChange}
                      value={value}
                      disablePast={false}
                      usFormat={channel?.useUsDateFormat}
                      stylePaper={{ height: 45 }}
                      autoOk
                      bordered
                    />
                  </div>
                )}
                name="startDate"
              />
            </div>
            <div>
              <Controller
                control={control}
                render={({ field: { onChange, value } }) => (
                  <div>
                    <DatePickerRounded
                      label="&nbsp;"
                      onChange={onChange}
                      value={value}
                      disablePast={false}
                      usFormat={channel?.useUsDateFormat}
                      stylePaper={{ height: 45 }}
                      autoOk
                      bordered
                    />
                  </div>
                )}
                name="endDate"
              />
            </div>
          </div>
        )}
        <div>
          {/* <Button
            className={view === 'registrations' && 'tw-bg-gray-100'}
            onClick={() => setView('registrations')}
          >
            <AssignmentTurnedIn fontSize="small" />
            &nbsp;
            {t('button.registrations')}
          </Button> */}
        </div>
        <div>
          <ButtonPrimary style={{ height: 45 }} onClick={() => submit()}>
            <div>{t('button.search')}</div>
          </ButtonPrimary>
        </div>
      </div>
    </Paper>
  );
};

CalendarsFilters.propTypes = {};

CalendarsFilters.defaultProps = {
  close: () => {},
};

export default CalendarsFilters;
