import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';

import Box from 'core/ui/Box';
import Grid from 'core/ui/Grid';
import Typography from 'core/ui/Typography';

import TextAreaFieldRounded from 'core/ui/TextAreaFieldRounded';
import Skeleton from '@material-ui/lab/Skeleton';
import { ProfileContext } from 'pages/shared/Profile/Edit';
import TagsChosen from 'core/ui/TagsChosen';
import ProfileVideo from './ProfileVideo';
import WorkoutPrice from './WorkoutPrice';

export default () => {
  const { t } = useTranslation();
  const formContext = useContext(ProfileContext);

  return (
    <>
      <Typography color="primary" mb={3} variant="h5">
        {formContext.loading === false ? (
          <span>{t('profile.title.coach').toUpperCase()}</span>
        ) : (
          <Skeleton animation="wave" height={40} width={200} />
        )}
      </Typography>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} px={5}>
          <Box mb={4}>
            {formContext.loading === false ? (
              <TextAreaFieldRounded
                name="coachDescription"
                placeholder={t('profile.placeholder.coach.descriptionBackground')}
                label={t('profile.label.descriptionBackground').toUpperCase()}
                inputRef={formContext.register({ required: false })}
              />
            ) : (
              <Skeleton
                animation="wave"
                style={{ marginTop: 10 }}
                variant="rect"
                height={80}
                width="100%"
              />
            )}
          </Box>
        </Grid>
      </Grid>
      <Grid container>
        {formContext.profile.country && formContext.profile.country.allowStripeConnect ? (
          <>
            <Grid item xs={12} sm={6} md={6} lg={6} px={5}>
              <Box>
                <WorkoutPrice />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} px={5}>
              <Box>
                <ProfileVideo />
              </Box>
            </Grid>
          </>
        ) : (
          <Grid item xs={12} sm={6} md={6} lg={6} px={5}>
            <Box>
              <ProfileVideo />
            </Box>
          </Grid>
        )}
      </Grid>

      <Box mt={3}>
        <Typography color="primary" mb={3} variant="h5">
          {formContext.loading === false ? (
            <span>{t('profile.title.achievements').toUpperCase()}</span>
          ) : (
            <Skeleton animation="wave" height={40} width={200} />
          )}
        </Typography>
      </Box>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} px={5}>
          <Box mb={4}>
            {formContext.loading === false ? (
              <TagsChosen
                placeholder={t('profile.placeholder.certifications')}
                setValue={(values) => formContext.setValue('certifications', values)}
                error={formContext.errors}
                limit={6}
                kind="certificate"
                val={
                  formContext.watch('certifications')
                    ? [...formContext.watch('certifications')]
                    : []
                }
              />
            ) : (
              <Skeleton animation="wave" height={50} width="100%" />
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
