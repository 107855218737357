import { all, takeLatest } from 'redux-saga/effects';
import { languageSagas, languageTypes } from 'modules/language';

import { sessionSagas, sessionTypes } from 'modules/session';
import { usersSagas, usersTypes } from 'modules/users';
import { channelsSagas, channelsTypes } from 'modules/channels';
import { workoutsSagas, workoutsTypes } from 'modules/workouts';
import { sessionWorkoutSagas, sessionWorkoutTypes } from 'modules/sessionWorkout';
import { movementsSagas, movementsTypes } from 'modules/movements';
import { equipmentSagas, equipmentTypes } from 'modules/equipment';
import { tagsSagas, tagsTypes } from 'modules/tags';
import { filtersSagas, filtersTypes } from 'modules/filters';
import { searchSagas, searchTypes } from 'modules/search';
import { ratingsSagas, ratingsTypes } from 'modules/ratings';
import { favoritesSagas, favoritesTypes } from 'modules/favorites';
import { supportSagas, supportTypes } from 'modules/support';
import { contractSagas, contractTypes } from 'modules/contract';
import { competitionsSagas, competitionsTypes } from 'modules/competitions';
import { competitionsSearchSagas, competitionsSearchTypes } from 'modules/competitionsSearch';
import { paymentsSagas, paymentsTypes } from 'modules/payments';
import { bannersSagas, bannersTypes } from 'modules/banners';
import { advertisingsSagas, advertisingsTypes } from 'modules/advertisings';
import { channelListsSagas, channelListsTypes } from 'modules/channelLists';
import { recurringsSagas, recurringsTypes } from 'modules/recurrings';
import { channelMembersSagas, channelMembersTypes } from 'modules/channelMembers';
import { messageListsSagas, messageListsTypes } from 'modules/messageLists';
import { channelMemberNotesSagas, channelMemberNotesTypes } from 'modules/channelMemberNotes';
import { messagesSagas, messagesTypes } from 'modules/messages';
import {
  coachScheduleSessionsSagas,
  coachScheduleSessionsTypes,
} from 'modules/coachScheduleSessions';
import { workoutHeatsSagas, workoutHeatsTypes } from 'modules/workoutHeats';
import sagas from './modules/map/sagas';

export default function* rootSaga() {
  yield all([
    ...sagas,

    // ********** SESSION **********
    takeLatest(sessionTypes.SIGN_IN_START, sessionSagas.signIn),
    takeLatest(sessionTypes.AUTHENTICATE_START, sessionSagas.authenticate),
    takeLatest(sessionTypes.AUTHENTICATE_BY_JWT_START, sessionSagas.authenticateByJwt),
    takeLatest(sessionTypes.VALIDATE_PIN_START, sessionSagas.validatePin),
    takeLatest(sessionTypes.AUTH_LOG_OUT_START, sessionSagas.logOut),
    takeLatest(sessionTypes.CHANGE_USER_START, sessionSagas.changeUser),

    // ********** USERS **********
    takeLatest(usersTypes.PUT_UPDATE_USER_START, usersSagas.putUpdateUser),
    takeLatest(usersTypes.POST_SPOTIFY_ACCOUNT_START, usersSagas.postSpotifyAccount),
    takeLatest(usersTypes.DEL_SPOTIFY_ACCOUNT_START, usersSagas.delSpotifyAccount),
    takeLatest(usersTypes.GET_WORKOUTS_INVITES_START, usersSagas.getWorkoutsInvites),
    takeLatest(usersTypes.CONNECT_STRIPE_ACCOUNT_START, usersSagas.connectStripeAccount),
    takeLatest(usersTypes.REMOVE_STRIPE_ACCOUNT_START, usersSagas.removeStripeAccount),

    // ********** LANGUAGE **********
    takeLatest(languageTypes.SET_LANGUAGE_START, languageSagas.setLanguage),
    takeLatest(languageTypes.GET_LANGUAGES_START, languageSagas.getLanguages),

    // ********** CHANNELS **********
    takeLatest(channelsTypes.GET_CHANNELS_START, channelsSagas.getChannels),
    takeLatest(channelsTypes.GET_CHANNEL_START, channelsSagas.getChannel),
    takeLatest(
      channelsTypes.GET_CHANNEL_CALENDAR_WORKOUTS_START,
      channelsSagas.getChannelCalendarWorkouts,
    ),
    takeLatest(channelsTypes.POST_CHANNEL_START, channelsSagas.postChannel),
    takeLatest(channelsTypes.PUT_CHANNEL_START, channelsSagas.putChannel),
    takeLatest(channelsTypes.CANCEL_CHANNEL_START, channelsSagas.cancelChannel),
    takeLatest(channelsTypes.ASSIGN_KEYS_TO_USER_START, channelsSagas.assignKeysToUser),
    takeLatest(channelsTypes.REMOVE_USER_FROM_KEYS_START, channelsSagas.removeUserFromKeys),
    takeLatest(channelsTypes.TOGGLE_KEYS_TO_RENEW_START, channelsSagas.toggleKeysToRenew),
    takeLatest(channelsTypes.ACTIVE_KEY_START, channelsSagas.activeKey),
    takeLatest(
      channelsTypes.VALIDATE_CARD_OWNER_CHANNEL_START,
      channelsSagas.validateCardOwnerChannel,
    ),
    takeLatest(
      channelsTypes.APPLY_MEMBERSHIP_DISCOUNT_CODE_START,
      channelsSagas.applyMembershipDiscountCode,
    ),
    takeLatest(channelsTypes.CONNECT_STRIPE_ACCOUNT_START, channelsSagas.connectStripeAccount),
    takeLatest(channelsTypes.REMOVE_STRIPE_ACCOUNT_START, channelsSagas.removeStripeAccount),

    // ********** WORKOUTS **********
    takeLatest(workoutsTypes.GET_WORKOUT_START, workoutsSagas.getWorkout),
    takeLatest(workoutsTypes.GET_WORKOUT_DETAIL_START, workoutsSagas.getWorkoutDetail),
    takeLatest(workoutsTypes.POST_WORKOUT_START, workoutsSagas.postWorkout),
    takeLatest(workoutsTypes.PUT_WORKOUT_START, workoutsSagas.putWorkout),
    takeLatest(workoutsTypes.PUT_WORKOUT_SECTION_START, workoutsSagas.putWorkoutSection),
    takeLatest(workoutsTypes.GET_WORKOUT_SECTION_USER_START, workoutsSagas.getWorkoutSectionUser),
    takeLatest(workoutsTypes.SET_WORKOUT_SECTION_USER_START, workoutsSagas.setWorkoutSectionUser),
    takeLatest(
      workoutsTypes.SET_WORKOUT_COUNTDOWN_SECONDS_START,
      workoutsSagas.setWorkoutCountdownSeconds,
    ),
    takeLatest(
      workoutsTypes.SET_WORKOUT_SECTION_USER_PENALTIES_START,
      workoutsSagas.setWorkoutSectionUserPenalties,
    ),
    takeLatest(
      workoutsTypes.ADD_WORKOUT_SECTION_USER_FLAG_START,
      workoutsSagas.addWorkoutSectionUserFlag,
    ),
    takeLatest(
      workoutsTypes.SET_WORKOUT_SECTION_USER_VERIFIED_START,
      workoutsSagas.setWorkoutSectionUserVerified,
    ),
    takeLatest(workoutsTypes.PUT_TRACK_USER_START, workoutsSagas.putTrackUser),
    takeLatest(workoutsTypes.DELETE_WORKOUT_SECTION_START, workoutsSagas.deleteWorkoutSection),
    takeLatest(workoutsTypes.GET_WORKOUTS_START, workoutsSagas.getWorkouts),
    takeLatest(workoutsTypes.GET_UPCOMING_START, workoutsSagas.getUpcoming),
    takeLatest(workoutsTypes.GET_UPCOMING_ATTENDINGS_START, workoutsSagas.getUpcomingAttendings),
    takeLatest(workoutsTypes.GET_RECENTS_START, workoutsSagas.getRecents),
    takeLatest(workoutsTypes.GET_RECENTS_ATTENDINGS_START, workoutsSagas.getRecentsAttendings),
    takeLatest(workoutsTypes.GET_FAVORITES_START, workoutsSagas.getFavorites),
    takeLatest(workoutsTypes.PURCHASE_START, workoutsSagas.purchase),
    takeLatest(workoutsTypes.REFUND_START, workoutsSagas.refund),
    takeLatest(workoutsTypes.PUT_INACTIVATE_USER_START, workoutsSagas.putInactivateUser),
    takeLatest(workoutsTypes.GET_WORKOUT_USERS_START, workoutsSagas.getWorkoutUsers),
    takeLatest(workoutsTypes.GET_WORKOUT_COACHES_START, workoutsSagas.getWorkoutCoaches),
    takeLatest(workoutsTypes.GET_WORKOUT_SECTIONS_START, workoutsSagas.getWorkoutSections),
    takeLatest(
      workoutsTypes.GET_WORKOUT_SECTION_LEADERBOARD_START,
      workoutsSagas.getWorkoutSectionLeaderboard,
    ),
    takeLatest(workoutsTypes.GET_COACH_ACCESSED_START, workoutsSagas.getCoachAccessed),
    takeLatest(
      workoutsTypes.POST_WORKOUT_RECORDING_INVITES_START,
      workoutsSagas.postWorkoutRecordingInvites,
    ),

    // ********** SESSION WORKOUT **********
    takeLatest(sessionWorkoutTypes.CREATE_START, sessionWorkoutSagas.create),
    takeLatest(
      sessionWorkoutTypes.START_WORKOUT_COMPETITION_START,
      sessionWorkoutSagas.startWorkoutCompetition,
    ),
    takeLatest(
      sessionWorkoutTypes.RESET_WORKOUT_COMPETITION_START,
      sessionWorkoutSagas.resetWorkoutCompetition,
    ),
    takeLatest(sessionWorkoutTypes.GET_TOKEN_START, sessionWorkoutSagas.getToken),
    takeLatest(sessionWorkoutTypes.POST_CHAT_MESSAGE_START, sessionWorkoutSagas.postMessage),
    takeLatest(sessionWorkoutTypes.POST_ACCESS_START, sessionWorkoutSagas.postAccess),
    takeLatest(sessionWorkoutTypes.POST_EVENT_START, sessionWorkoutSagas.postEvent),
    takeLatest(sessionWorkoutTypes.GET_EVENTS_START, sessionWorkoutSagas.getEvents),

    takeLatest(
      sessionWorkoutTypes.GET_RECORDING_STATUS_START,
      sessionWorkoutSagas.getRecordingStatus,
    ),
    takeLatest(sessionWorkoutTypes.START_RECORDING_START, sessionWorkoutSagas.startRecording),
    takeLatest(sessionWorkoutTypes.STOP_RECORDING_START, sessionWorkoutSagas.stopRecording),
    takeLatest(
      sessionWorkoutTypes.POST_ACCESS_DISCONNECT_START,
      sessionWorkoutSagas.postAccessDisconnect,
    ),
    takeLatest(
      sessionWorkoutTypes.PROCESS_LOCAL_RECORDING_START,
      sessionWorkoutSagas.processLocalRecording,
    ),

    // ********** MOVEMENTS **********
    takeLatest(
      movementsTypes.GET_MOVEMENTS_AUTOCOMPLETE_START,
      movementsSagas.getMovementsAutocomplete,
    ),

    // ********** EQUIPMENT **********
    takeLatest(
      equipmentTypes.GET_EQUIPMENT_AUTOCOMPLETE_START,
      equipmentSagas.getEquipmentAutocomplete,
    ),

    // ********** TAGS **********
    takeLatest(tagsTypes.GET_TAGS_AUTOCOMPLETE_START, tagsSagas.getTagsAutocomplete),

    // ********** FILTERS **********
    takeLatest(filtersTypes.GET_FILTERS_START, filtersSagas.getFilters),

    // ********** SEARCH **********
    takeLatest(searchTypes.GET_SEARCH_START, searchSagas.getSearch),
    takeLatest(searchTypes.GET_SEARCH_USERS_START, searchSagas.getSearchUsers),
    takeLatest(searchTypes.GET_SEARCH_CHANNEL_MEMBERS_START, searchSagas.getSearchChannelMembers),
    takeLatest(searchTypes.GET_SEARCH_AUTOCOMPLETE_START, searchSagas.getSearchAutocomplete),
    takeLatest(searchTypes.GET_FEATURED_START, searchSagas.getFeatured),
    takeLatest(searchTypes.GET_FEATURED_UPCOMING_START, searchSagas.getFeaturedUpcoming),
    takeLatest(searchTypes.GET_FEATURED_REGISTERED_START, searchSagas.getFeaturedRegistered),
    takeLatest(searchTypes.GET_VIEW_MORE_START, searchSagas.getViewMore),

    // ********** RATINGS **********
    takeLatest(ratingsTypes.POST_RATING_START, ratingsSagas.postRating),
    takeLatest(ratingsTypes.GET_RATINGS_START, ratingsSagas.getRatings),

    // ********** FAVORITES **********
    takeLatest(favoritesTypes.POST_FAVORITE_START, favoritesSagas.postFavorite),
    takeLatest(favoritesTypes.GET_FAVORITES_START, favoritesSagas.getFavorites),
    takeLatest(favoritesTypes.DELETE_FAVORITE_START, favoritesSagas.deleteFavorite),
    takeLatest(favoritesTypes.GET_FAVORITE_START, favoritesSagas.getFavorite),

    // ********** SUPPORT **********
    takeLatest(supportTypes.POST_SUPPORT_START, supportSagas.postSupport),
    takeLatest(supportTypes.GET_SUPPORT_START, supportSagas.getSupport),

    // ********** CONTRACT - TERMS OF USE **********
    takeLatest(contractTypes.ACCEPT_CONTRACT_START, contractSagas.acceptContract),
    takeLatest(contractTypes.REJECT_CONTRACT_START, contractSagas.rejectContract),
    takeLatest(contractTypes.GET_CONTRACT_START, contractSagas.getContract),

    // ********** COMPETITIONS **********
    takeLatest(competitionsTypes.GET_COMPETITION_START, competitionsSagas.getCompetition),
    takeLatest(competitionsTypes.POST_COMPETITION_START, competitionsSagas.postCompetition),
    takeLatest(competitionsTypes.CANCEL_COMPETITION_START, competitionsSagas.cancelCompetition),
    takeLatest(competitionsTypes.PAYMENT_COMPETITION_START, competitionsSagas.paymentCompetition),

    takeLatest(competitionsTypes.PUT_COMPETITION_START, competitionsSagas.putCompetition),
    takeLatest(competitionsTypes.GET_COMPETITIONS_START, competitionsSagas.getCompetitions),

    // ********** COMPETITIONS SEARCH **********
    takeLatest(competitionsSearchTypes.GET_SEARCH_START, competitionsSearchSagas.getSearch),
    takeLatest(competitionsSearchTypes.GET_RECENTS_START, competitionsSearchSagas.getRecents),
    takeLatest(competitionsSearchTypes.GET_UPCOMING_START, competitionsSearchSagas.getUpcoming),
    takeLatest(competitionsSearchTypes.GET_CURRENT_START, competitionsSearchSagas.getCurrent),

    // ********** PAYMENTS **********
    takeLatest(paymentsTypes.GET_PAYMENTS_START, paymentsSagas.getPayments),
    takeLatest(paymentsTypes.RETRY_PAYMENT_START, paymentsSagas.retryPayment),
    takeLatest(paymentsTypes.PURCHASE_CHANNEL_PLAN_START, paymentsSagas.purchaseChannelPlan),
    takeLatest(paymentsTypes.PURCHASE_CHANNEL_KEY_START, paymentsSagas.purchaseChannelKey),
    takeLatest(paymentsTypes.PURCHASE_CHANNEL_LIST_START, paymentsSagas.purchaseChannelList),
    takeLatest(
      paymentsTypes.PURCHASE_COACH_SCHEDULE_SESSION_START,
      paymentsSagas.purchaseCoachScheduleSession,
    ),
    takeLatest(
      paymentsTypes.PURCHASE_CHANNEL_STORE_PRODUCT_START,
      paymentsSagas.purchaseChannelStoreProduct,
    ),
    takeLatest(
      paymentsTypes.PURCHASE_WORKOUT_TO_MEMBER_START,
      paymentsSagas.purchaseWorkoutToMember,
    ),

    // ********** BANNERS **********
    takeLatest(bannersTypes.GET_BANNERS_START, bannersSagas.getBanners),
    takeLatest(bannersTypes.GET_BANNERS_HEADER_START, bannersSagas.getBannersHeader),

    // ********** ADVERTISING **********
    takeLatest(advertisingsTypes.GET_ADVERTISINGS_START, advertisingsSagas.getAdvertisings),
    takeLatest(advertisingsTypes.POST_REGISTRATION_START, advertisingsSagas.postRegistration),
    takeLatest(advertisingsTypes.POST_CLICK_START, advertisingsSagas.postClick),

    // ********** CHANNEL LIST **********
    takeLatest(channelListsTypes.POST_CHANNEL_LISTS_START, channelListsSagas.postChannelLists),
    takeLatest(channelListsTypes.PUT_CHANNEL_LISTS_START, channelListsSagas.putChannelLists),
    takeLatest(channelListsTypes.GET_CHANNEL_LISTS_START, channelListsSagas.getChannelLists),
    takeLatest(
      channelListsTypes.GET_USER_CHANNEL_LISTS_START,
      channelListsSagas.getUserChannelLists,
    ),
    takeLatest(
      channelListsTypes.POST_WORKOUT_CHANNEL_LIST_START,
      channelListsSagas.postWorkoutChannelList,
    ),
    takeLatest(
      channelListsTypes.DELETE_WORKOUT_CHANNEL_LIST_START,
      channelListsSagas.deleteWorkoutChannelList,
    ),

    // ********** CHANNEL MEMBER NOTES **********
    takeLatest(
      channelMemberNotesTypes.POST_CHANNEL_MEMBER_NOTES_START,
      channelMemberNotesSagas.postChannelMemberNotes,
    ),
    takeLatest(
      channelMemberNotesTypes.PUT_CHANNEL_MEMBER_NOTES_START,
      channelMemberNotesSagas.putChannelMemberNotes,
    ),
    takeLatest(
      channelMemberNotesTypes.GET_CHANNEL_MEMBER_NOTES_START,
      channelMemberNotesSagas.getChannelMemberNotes,
    ),
    takeLatest(
      channelMemberNotesTypes.DELETE_CHANNEL_MEMBER_NOTES_START,
      channelMemberNotesSagas.deleteChannelMemberNotes,
    ),

    // ********** MESSAGE LIST **********
    takeLatest(messageListsTypes.POST_MESSAGE_LISTS_START, messageListsSagas.postMessageLists),
    takeLatest(messageListsTypes.PUT_MESSAGE_LISTS_START, messageListsSagas.putMessageLists),
    takeLatest(messageListsTypes.GET_MESSAGE_LISTS_START, messageListsSagas.getMessageLists),
    takeLatest(
      messageListsTypes.GET_MESSAGE_LIST_CONTACTS_START,
      messageListsSagas.getMessageListContacts,
    ),
    takeLatest(messageListsTypes.DELETE_CONTACT_LIST_START, messageListsSagas.deleteContactList),

    // ********** MESSAGES **********
    takeLatest(messagesTypes.POST_MESSAGES_START, messagesSagas.postMessages),
    takeLatest(messagesTypes.PUT_MESSAGES_START, messagesSagas.putMessages),
    takeLatest(messagesTypes.GET_MESSAGES_START, messagesSagas.getMessages),
    takeLatest(messagesTypes.GET_STREAM_USER_COUNT_START, messagesSagas.getStreamUserCount),
    takeLatest(messagesTypes.STOP_CONTACT_START, messagesSagas.stopContact),
    takeLatest(messagesTypes.GET_MESSAGES_REPLIES_START, messagesSagas.getMessagesReplies),
    takeLatest(messagesTypes.GET_MESSAGE_START, messagesSagas.getMessage),
    takeLatest(messagesTypes.GET_TWILIO_NUMBERS_START, messagesSagas.getTwilioNumbers),

    // ********** RECURRINGS **********
    takeLatest(
      recurringsTypes.GET_RECURRINGS_BY_CHANNEL_START,
      recurringsSagas.getRecurringsByChannel,
    ),
    takeLatest(recurringsTypes.STOP_RECURRING_START, recurringsSagas.stopRecurring),
    takeLatest(recurringsTypes.EXTEND_RECURRING_START, recurringsSagas.extendRecurring),

    // ********** CHANNEL MEMBERS **********
    takeLatest(
      channelMembersTypes.GET_CHANNEL_MEMBERS_START,
      channelMembersSagas.getChannelMembers,
    ),
    takeLatest(channelMembersTypes.GET_CHANNEL_MEMBER_START, channelMembersSagas.getChannelMember),
    takeLatest(
      channelMembersTypes.GET_AVAILABLE_CLASSES_START,
      channelMembersSagas.getAvailableClasses,
    ),
    takeLatest(
      channelMembersTypes.MEMBER_UNREGISTER_CLASS_START,
      channelMembersSagas.memberUnregisterClass,
    ),
    takeLatest(
      channelMembersTypes.MEMBER_REGISTER_CLASS_START,
      channelMembersSagas.memberRegisterClass,
    ),

    takeLatest(channelMembersTypes.PUT_UPDATE_MEMBER_START, channelMembersSagas.putUpdateMember),
    takeLatest(channelMembersTypes.POST_ADD_MEMBER_START, channelMembersSagas.postAddMember),

    // ********** COACH SCHEDULE SESSIONS **********
    takeLatest(
      coachScheduleSessionsTypes.GET_USER_SCHEDULE_SESSIONS_START,
      coachScheduleSessionsSagas.getUserScheduleSessions,
    ),
    takeLatest(
      coachScheduleSessionsTypes.GET_COACH_SCHEDULE_SESSIONS_START,
      coachScheduleSessionsSagas.getCoachScheduleSessions,
    ),
    takeLatest(
      coachScheduleSessionsTypes.GET_SCHEDULE_SESSION_START,
      coachScheduleSessionsSagas.getScheduleSession,
    ),

    // ********** WORKOUT HEATS **********
    takeLatest(workoutHeatsTypes.POST_WORKOUT_HEAT_START, workoutHeatsSagas.postWorkoutHeat),
    takeLatest(
      workoutHeatsTypes.SAVE_WORKOUT_HEATS_USERS_START,
      workoutHeatsSagas.saveWorkoutHeatsUsers,
    ),
    takeLatest(workoutHeatsTypes.GET_WORKOUT_HEATS_START, workoutHeatsSagas.getWorkoutHeats),
    takeLatest(workoutHeatsTypes.RESET_WORKOUT_HEATS_START, workoutHeatsSagas.resetWorkoutHeats),
  ]);
}
