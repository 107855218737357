import React from 'react';

import Box from '../Box';
import Typography from '../Typography';

const TypographyIcon = (props) => (
  <Typography
    className={props.className}
    color={props.color}
    variant={props.variant}
    component={props.component || 'div'}
    {...props}
  >
    <Box display="flex" justifyContent={props.align}>
      <Box alignItems="center" mr={2}>
        {props.icon}
      </Box>
      <Box alignItems="center">{props.text}</Box>
    </Box>
  </Typography>
);

export default TypographyIcon;
