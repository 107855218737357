/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Box from 'core/ui/Box';
import useSelectors from 'modules/map/useSelectors';
import { channelReportsModule } from 'modules/channelReports';
import Grid from 'core/ui/Grid';
import ChoosenChannelTags from 'core/ui/ChoosenChannelTags';
import { useTranslation } from 'react-i18next';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import { IconButton, Tooltip } from '@material-ui/core';
import { Info, Refresh } from '@material-ui/icons';
import { useDebounce } from 'use-debounce';
import SkeletonAtRiskMembersReport from './Skeleton';
import ResultMembers from './ResultMembers';

const AtRiskMembers = ({ channel, loading, getReport }) => {
  const { t } = useTranslation();
  const data = useSelectors(channelReportsModule, 'data');
  const [filters, setFilters] = useState({
    membersTags: [],
    membershipsTags: [],
    registeredInTheLastDays: 60,
    daysNotAttendance: 7,
    minAttendance: 3,
  });

  const [filtersBounce] = useDebounce(filters, 800);

  useEffect(() => {
    getReport(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersBounce]);

  return (
    <Box mt={2}>
      <Box flexGrow={1} mb={3}>
        <Grid container spacing={5}>
          <Grid item xs={12} md={4}>
            <TextFieldRounded
              label={
                <div className="tw-flex tw-flex-row tw-items-center">
                  {t(`label.registeredInTheLastDays`, {
                    days: filters?.registeredInTheLastDays,
                  }).toUpperCase()}
                  <Tooltip title={t('channel.atRiskReport.help.registeredInTheLastDays')}>
                    <Info fontSize="small" />
                  </Tooltip>
                </div>
              }
              placeholder="Days"
              value={filters?.registeredInTheLastDays}
              onChange={(v) => setFilters((prev) => ({ ...prev, registeredInTheLastDays: v }))}
              bordered
              type="number"
              pattern="[0-9]*"
            />

            {/* "channel.atRiskReport.help.registeredInTheLastDays": "The member will appear on this report if they have registered for a class at your gym in the last 60 days",
  "channel.atRiskReport.help.minDaysAttendance": "The member will appear on this report if they have NOT had a registration at your gym in the last 7 days",
  "channel.atRiskReport.help.attendanceMin */}
          </Grid>
          <Grid item xs={12} md={4}>
            <TextFieldRounded
              label={
                <div className="tw-flex tw-flex-row tw-items-center">
                  {t(`label.daysNotAttendance`, {
                    days: filters?.daysNotAttendance,
                  }).toUpperCase()}
                  <Tooltip title={t('channel.atRiskReport.help.minDaysAttendance')}>
                    <Info fontSize="small" />
                  </Tooltip>
                </div>
              }
              placeholder="Days"
              value={filters?.daysNotAttendance}
              onChange={(v) => setFilters((prev) => ({ ...prev, daysNotAttendance: v }))}
              bordered
              type="number"
              pattern="[0-9]*"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextFieldRounded
              label={
                <div className="tw-flex tw-flex-row tw-items-center">
                  {t(`label.minAttendance`, { classes: filters?.minAttendance }).toUpperCase()}
                  <Tooltip title={t('channel.atRiskReport.help.attendanceMin')}>
                    <Info fontSize="small" />
                  </Tooltip>
                </div>
              }
              placeholder="Days"
              value={filters?.minAttendance}
              onChange={(v) => setFilters((prev) => ({ ...prev, minAttendance: v }))}
              bordered
              type="number"
              pattern="[0-9]*"
            />
          </Grid>
        </Grid>
        <Grid container spacing={5}>
          <Grid item xs={12} md={4}>
            <ChoosenChannelTags
              label={
                <div className="tw-flex tw-flex-row tw-items-center">
                  {t(`channel.label.memberTags`).toUpperCase()}
                  <Tooltip title={t('channel.atRiskReport.help.memberTags')}>
                    <Info fontSize="small" />
                  </Tooltip>
                </div>
              }
              placeholder={t('channel.tags')}
              val={filters?.membersTags ? [...filters.membersTags] : []}
              setValue={(v) =>
                setFilters((prev) => ({ ...prev, membersTags: v?.map((x) => x?.name || x) }))
              }
              channelId={channel?.id}
              bordered
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <div className="tw-mt-8 tw-flex tw-items-end">
              <IconButton size="small" onClick={() => getReport(filters)}>
                <Refresh />
              </IconButton>
            </div>
          </Grid>
        </Grid>
      </Box>
      <Box>
        {!data.members || loading ? (
          <SkeletonAtRiskMembersReport />
        ) : (
          <Box>
            <ResultMembers channel={channel} members={data.members} />{' '}
          </Box>
        )}
      </Box>
    </Box>
  );
};

AtRiskMembers.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  loading: PropTypes.bool,
  getReport: PropTypes.func,
};

AtRiskMembers.defaultProps = {
  loading: false,
  getReport: () => {},
};

export default AtRiskMembers;
