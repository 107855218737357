import React from 'react';
import Box from 'core/ui/Box';

import useSelectors from 'modules/map/useSelectors';
import { channelReportsModule } from 'modules/channelReports';

import TableProductsInventories from './Table';
import FiltersProductsInventories from './Filters';

const ProductsInventories = ({ getReport, loading, channel }) => {
  const data = useSelectors(channelReportsModule, 'data');

  return (
    <Box>
      <FiltersProductsInventories getReport={getReport} loading={loading} channel={channel} />

      <TableProductsInventories
        channel={channel}
        data={data}
        refresh={getReport}
        loading={loading}
      />
    </Box>
  );
};

export default ProductsInventories;
