import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import DatePickerRounded from 'core/ui/DatePickers/DatePickerRounded';
import { ButtonPrimarySm } from 'core/ui/Button';
import { Search } from '@material-ui/icons';
import { formatDateToApi } from 'core/utils/formatters';
import { channelReportsModule } from 'modules/channelReports';
import useSelectors from 'modules/map/useSelectors';
import ResultUsers from './ResultUsers';
import SkeletonPayamentsReport from './Skeleton';

const MembersConversions = ({ channel, loading, getReport }) => {
  const { t } = useTranslation();

  const data = useSelectors(channelReportsModule, 'data');
  const dateNow = new Date();
  const [filters, setFilters] = useState({
    startAt: `${dateNow.getMonth() + 1}/1/${dateNow.getFullYear()}`,
    endAt: `${dateNow.getMonth() + 1}/${dateNow.getDate()}/${dateNow.getFullYear()}`,
  });

  const search = () => {
    const startAt = filters.startAt ? formatDateToApi(filters.startAt) : null;
    const endAt = filters.endAt ? formatDateToApi(filters.endAt) : null;
    if (startAt && endAt) getReport({ startAt, endAt });
  };

  useEffect(() => {
    search();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box mt={2}>
      <Box>
        <Box display="flex" mb={3} alignItems="flex-end" justifyContent="center" flexWrap="wrap">
          <Box>
            <DatePickerRounded
              label={t('channel.reports.filter.start').toUpperCase()}
              onChange={(v) => setFilters((prev) => ({ ...prev, startAt: v }))}
              value={filters.startAt}
              disablePast={false}
              usFormat={channel?.useUsDateFormat}
              autoOk
            />
          </Box>
          <Box ml={1}>
            <DatePickerRounded
              label={t('channel.reports.filter.end').toUpperCase()}
              onChange={(v) => setFilters((prev) => ({ ...prev, endAt: v }))}
              value={filters.endAt}
              disablePast={false}
              usFormat={channel?.useUsDateFormat}
              autoOk
            />
          </Box>
          <Box ml={3}>
            <ButtonPrimarySm disabled={loading} onClick={() => search()}>
              <Search style={{ color: 'white' }} fontSize="small" />
              {t('button.search')}&nbsp;
            </ButtonPrimarySm>
          </Box>
        </Box>

        {!data?.users ? (
          <SkeletonPayamentsReport />
        ) : (
          <Box>
            <ResultUsers channel={channel} data={data || []} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

MembersConversions.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  loading: PropTypes.bool,
  getReport: PropTypes.func,
};

MembersConversions.defaultProps = {
  loading: false,
  getReport: () => {},
};

export default MembersConversions;
