import React, { useEffect, useState } from 'react';
import { ButtonPrimary } from 'core/ui/Button';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import Typography from 'core/ui/Typography';

import {
  TableContainer,
  TableHead,
  Table,
  TableCell,
  TableRow,
  TableBody,
  Paper,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { channelsSelectors } from 'modules/channels';
import { Add } from '@material-ui/icons';
import LoaderSm from 'core/ui/LoaderSm';
import useSelectors from 'modules/map/useSelectors';
import { channelsCustomPaymentsModule } from 'modules/channelsCustomPayments';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import Divider from 'core/ui/Divider';
import ItemCustomPayment from './ItemCustomPayment';
import ModalForm from './Form/Modal';
import CoachCustomPayments from './CoachCustomPayments';

const CustomPayments = () => {
  const { t } = useTranslation();
  const channel = useSelector((state) => channelsSelectors.getChannel(state));
  const data = useSelectors(channelsCustomPaymentsModule, 'data');
  const loading = useSelectors(channelsCustomPaymentsModule, 'loading');
  const { request } = useActions();
  const [coachId, setCoachId] = useState(null);
  const [modal, setModal] = useState({
    open: false,
  });

  useEffect(() => {
    if (channel && channel.id) {
      request({
        action: Modules.channelsCustomPayments.actions.getChannelsCustomPayments,
        data: { channelId: channel.id },
      });
    }
  }, [request, channel]);

  const openModal = () => {
    setModal((prev) => ({ ...prev, mode: 'new', open: true }));
  };

  const onEditClick = (item) => {
    setModal((prev) => ({ ...prev, item, mode: 'edit', open: true }));
  };

  return (
    <Box p={5}>
      {modal.open && (
        <ModalForm
          open={modal.open}
          item={modal.item}
          mode={modal.mode}
          channel={channel}
          close={() => setModal(() => ({ open: false }))}
          callback={() => setModal(() => ({ open: false }))}
        />
      )}

      <Box display="flex" alignItems="center">
        <Box flexGrow="1" display="flex" alignItems="center">
          <LoaderSm loading={loading} />
        </Box>

        <Box>
          <ButtonPrimary onClick={() => openModal()}>
            <Box display="flex" alignItems="flex-end">
              <Add size="small" />
              <Box alignItems="center">
                <Typography component="span" mr={1}>
                  {t('button.createCustomPayment')}
                </Typography>
              </Box>
            </Box>
          </ButtonPrimary>
        </Box>
      </Box>
      <Box mt={2}>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>{t('label.name')}</TableCell>
                <TableCell>{t('label.amount')}</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data.map((row) => (
                  <ItemCustomPayment
                    onEditClick={onEditClick}
                    key={row.name}
                    item={row}
                    channel={channel}
                    expanded={modal.nameListExpand && modal.nameListExpand === row.name}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Divider my={5} />

      <Box flexGrow="1" display="flex" mb={2} px={1}>
        <LoaderSm loading={loading} />
        <Box flexGrow="1">
          <SelectBoxObject
            label={t('label.coach').toUpperCase()}
            options={[...channel.coaches?.map((x) => ({ id: x?.user?.id, name: x?.user?.name }))]}
            value={coachId}
            setValue={(v) => setCoachId(v)}
          />
        </Box>
      </Box>

      {coachId ? (
        <CoachCustomPayments
          channel={channel}
          // eslint-disable-next-line eqeqeq
          coach={channel.coaches.find((x) => x?.user.id == coachId)?.user}
        />
      ) : (
        <Typography variant="h3" align="center" m={5}>
          Select a coach to see/add custom payments
        </Typography>
      )}
    </Box>
  );
};

export default CustomPayments;
