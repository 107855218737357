import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form-v5';
import { useSelector, useDispatch } from 'react-redux';
import { channelsActions, channelsSelectors } from 'modules/channels';
import { useLocation } from 'react-router';
import notifications from 'modules/notifications';
import { parse } from 'query-string';
import { appActions } from 'modules/app';
import { useTranslation } from 'react-i18next';
import { timeZoneName } from 'core/utils/formatters/date';
import { sessionActions } from 'modules/session';
import useChannelPermissions from '../useChannelPermissions';

export default ({ mode, setTab }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const channel = useSelector((state) => channelsSelectors.getChannel(state));
  const permissions = useChannelPermissions({ channel });
  const { register, handleSubmit, errors, reset, control, setValue, getValues, watch } = useForm();
  const loadingSave = useSelector((state) => channelsSelectors.getLoading(state));
  const loading = useSelector((state) => channelsSelectors.getLoadingChannel(state));

  const location = useLocation();

  const [image, setImage] = useState({
    crop: false,
  });

  const [bgImage, setBgImage] = useState({});
  const [membershipPlans, setMembershipPlans] = useState([]);
  const [locations, setLocations] = useState([]);
  const [workoutsTypes, setWorkoutsTypes] = useState([]);

  const workoutsTypesRef = useRef();
  const membershipsRef = useRef();
  const locationsRef = useRef();

  useEffect(() => {
    membershipsRef.current = membershipPlans;
  }, [membershipPlans]);

  useEffect(() => {
    locationsRef.current = locations;
  }, [locations]);

  useEffect(() => {
    workoutsTypesRef.current = workoutsTypes;
  }, [workoutsTypes]);

  useEffect(() => {
    const parsed = parse(location.search);
    if (parsed && parsed.tab) {
      setTab(parsed.tab);
    }
  }, [setTab, location]);

  useEffect(() => {
    dispatch(appActions.setLoading(loading));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    register({ name: 'countryId' }, { required: true });
    register({ name: 'currencyId' }, { required: true });

    register({ name: 'timezone' }, { required: true });
    register({ name: 'useUserTimezone' }, { required: false });
    register({ name: 'state' }, { required: false });
    register({ name: 'languages' });
    register({ name: 'categories' }, { required: false });
    register({ name: 'customColors' }, { required: false });
    register({ name: 'hideToPublic' }, { required: false });
    register({ name: 'allowAchPayments' }, { required: false });
    register({ name: 'genericCheckIn' }, { required: false });
    register({ name: 'membershipPauseFeeAmount' }, { required: false });
    register({ name: 'membershipPauseFeeAmountActive' }, { required: false });
    register({ name: 'useTaxes' }, { required: false });
    register({ name: 'payCoachesRatesByClassType' }, { required: false });
    register({ name: 'hideAttendance' }, { required: false });
    register({ name: 'hideLeaderboardButton' }, { required: false });
    register({ name: 'hideDashboardCompetitions' }, { required: false });
    register({ name: 'appLogoPhoto' }, { required: false });
    register({ name: 'useUsDateFormat' }, { required: false });
    register({ name: 'showPaymentMethodAlert' }, { required: false });
    register({ name: 'showLeaderboardRank' }, { required: false });
    register({ name: 'useMembershipServiceFee' }, { required: false });
    register({ name: 'allowSelfCheckInWorkouts' }, { required: false });

    register({ name: 'paymentDiscountSettings.active' }, { required: false });
    register({ name: 'paymentDiscountSettings.achMembershipPerc' }, { required: false });
    register({ name: 'paymentDiscountSettings.achStorePerc' }, { required: false });
    register({ name: 'paymentDiscountSettings.cardMembershipPerc' }, { required: false });
    register({ name: 'paymentDiscountSettings.cardStorePerc' }, { required: false });
    register({ name: 'paymentDiscountSettings.offlineMembershipPerc' }, { required: false });
    register({ name: 'paymentDiscountSettings.offlineStorePerc' }, { required: false });

    register({ name: 'attendanceCreditsAmounts.athleteActive' }, { required: false });
    register({ name: 'attendanceCreditsAmounts.coachActive' }, { required: false });
    register({ name: 'attendanceCreditsAmounts.athleteAttendance' }, { required: false });
    register({ name: 'attendanceCreditsAmounts.athleteAmount' }, { required: false });
    register({ name: 'attendanceCreditsAmounts.athleteAmountType' }, { required: false });
    register({ name: 'attendanceCreditsAmounts.coachAttendance' }, { required: false });
    register({ name: 'attendanceCreditsAmounts.coachAmount' }, { required: false });

    register({ name: 'channelsTaxes' }, { required: false });
    register({ name: 'scoresVariations' }, { required: false });

    if (mode === 'new') {
      setTimeout(() => {
        setValue('useUsDateFormat', true);
        setValue('timezone', timeZoneName());
        setValue('payCoachesRatesByClassType', true);
        setValue('showLeaderboardRank', true);
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register]);

  const populateMembershipPlans = () => {
    setMembershipPlans(channel.membershipAllPlans ? channel.membershipAllPlans : []);
  };

  const populateLocations = () => {
    setLocations(channel.locations ? channel.locations : []);
  };

  const populateWorkoutsTypes = () => {
    setWorkoutsTypes(channel.workoutsTypes ? channel.workoutsTypes : []);
  };

  useEffect(() => {
    if (!loading && channel && mode === 'edit') {
      reset(channel);

      setTimeout(() => {
        populateMembershipPlans();
        populateLocations();
        populateWorkoutsTypes();

        if (channel?.membershipPauseFeeAmount > 0) {
          setValue('membershipPauseFeeAmountActive', true);
        }
        if (channel.appLogo && channel.appLogo.image.url && mode !== 'new') {
          setValue('appLogoPhoto', {
            ...channel.appLogo,
            url: channel.appLogo.image.url,
          });
        }
      }, 300);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel, reset, loading, setValue, mode]);

  useEffect(() => {
    if (channel.image && mode !== 'new') {
      setImage({
        ...channel.image,
        url: channel.image.image.url,
      });
    }
    if (channel.bgImage && mode !== 'new') {
      setBgImage({
        ...channel.bgImage,
        url: channel.bgImage.image.url,
      });
    }
  }, [channel, mode, setValue]);

  const onSubmit = (data) => {
    if (mode === 'new' && !image.url) {
      dispatch(notifications.warning(t('channel.validate.image')));
      return;
    }

    if (image.crop) {
      dispatch(notifications.warning(t('channel.validate.cropImage')));
      return;
    }

    let instagram = '';
    if (data.instagram && data.instagram.indexOf('@') === -1) {
      instagram = `@${data.instagram}`;
    } else if (data.instagram) {
      instagram = data.instagram;
    }

    const channelData = {
      ...data,
      instagram,
      channel_languages_attributes: data.languages
        ? data.languages.map((x) => ({ languageId: x.id }))
        : [],
      // eslint-disable-next-line no-nested-ternary
      photo: image ? (image.new === true ? image : null) : null,
      // eslint-disable-next-line no-nested-ternary
      bgPhoto: bgImage ? (bgImage.new === true ? bgImage : null) : null,
      appLogoPhoto: data?.appLogoPhoto?.new === true ? data?.appLogoPhoto : null,
      categories:
        data.categories &&
        data.categories.map((x) => ({
          name: x.name ? x.name : x,
        })),
      membershipPlansAttributes: membershipsRef.current
        .filter((x) => !!x.amount)
        .map((item) => {
          const newItem = { ...item };
          newItem.couponsAttributes = item.coupons;
          delete newItem.coupons;

          return newItem;
        }),
      channelLocationsAttributes: locationsRef.current.filter((x) => !!x.name),
      workoutsTypesAttributes: workoutsTypesRef.current.filter((x) => !!x.name),
      channelsTaxesAttributes: data?.channelsTaxes?.filter((x) => !!x.name),
      scoresVariationsAttributes: data?.scoresVariations?.filter((x) => !!x.name),
    };
    dispatch(appActions.setLoading(true));
    if (mode === 'new') {
      dispatch(
        channelsActions.postChannelStart({
          data: channelData,
          messageSuccess: t('message.success.save.channel'),
          callback: () => {
            dispatch(sessionActions.authenticateStart());
          },
        }),
      );
    } else {
      dispatch(
        channelsActions.putChannelStart({
          id: channel.id,
          data: channelData,
          messageSuccess: t('message.success.save.channel'),
        }),
      );
    }
  };

  const cancelChannel = () => {
    dispatch(
      channelsActions.cancelChannelStart({
        channelId: channel.id,
        messageSuccess: t('message.success.cancel.channel'),
      }),
    );
  };

  return {
    register,
    errors,
    control,
    setValue,
    getValues,
    watch,
    loading: loading || loadingSave,
    image,
    setImage,
    channel,
    membershipPlans,
    setMembershipPlans,
    locations,
    setLocations,
    submitForm: handleSubmit(onSubmit),
    permissions,
    cancelChannel,
    bgImage,
    setBgImage,
    workoutsTypes,
    setWorkoutsTypes,
  };
};
