/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from 'core/ui/Typography';
import PropTypes from 'prop-types';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import { DialogContent, Dialog } from '@material-ui/core';
import DatePickerRounded from 'core/ui/DatePickers/DatePickerRounded';
import { ButtonPrimary } from 'core/ui/Button';
import { formatDateToApi } from 'core/utils/formatters';

const useStyles = makeStyles({
  root: {
    backgroundColor: '#f3f3f3',
  },
  btnClose: {
    position: 'absolute',
    right: 5,
    top: 0,
  },
});

export default function ModalStopRecurring({ channel, open, close, disable, confirm }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const [date, setDate] = useState(null);

  return (
    <Dialog open={open} maxWidth="xs" fullWidth onClose={() => close()}>
      <DialogContent className={classes.root}>
        <Box>
          <Box>
            <Typography className="roboto-regular">
              {t('recurring.confirm.title.description')}
            </Typography>
            {!disable && (
              <Typography className="roboto-regular">
                {t('recurring.confirm.title.description_1')}
              </Typography>
            )}

            {disable && (
              <Box my={3} display="flex" justifyContent="center">
                <DatePickerRounded
                  type="datetime-local"
                  label={t('recurring.confirm.delete.workoutsStartDate').toUpperCase()}
                  onChange={(v) => setDate(v)}
                  usFormat={channel?.useUsDateFormat}
                  value={date}
                />
              </Box>
            )}
            <Typography mt={2} align="center">
              {t('recurring.confirm.title.description_2')}
            </Typography>
          </Box>

          <Box m={3}>
            <ButtonPrimary
              fullWidth
              onClick={() =>
                confirm({ startAtRemoveWorkouts: date ? formatDateToApi(date) : null })
              }
            >
              {t('button.confirm')}
            </ButtonPrimary>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

ModalStopRecurring.propTypes = {
  close: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

ModalStopRecurring.defaultProps = {};
