import React, { useEffect, useState } from 'react';
import Box from 'core/ui/Box';
import Grid from 'core/ui/Grid';
import { primary, twColors } from 'core/ui/Colors';
import Modules from 'modules';
import useActions from 'modules/map/useActions';
import { newChannelsModule } from 'modules/channels/new';
import useSelectors from 'modules/map/useSelectors';
import Toggle from 'core/ui/Toggle';
import { channelReportsModule } from 'modules/channelReports';
import Button from 'core/ui/Button';
import { useTranslation } from 'react-i18next';
import { GroupAdd, Refresh } from '@material-ui/icons';
import { useHistory } from 'react-router';
import { channelCurrency, date } from 'core/utils/formatters';
import Typography, { fontStyle } from 'core/ui/Typography';
import Divider from 'core/ui/Divider';
import { channelDashboardModule } from 'modules/channelDashboard';
import useChannelStyles from 'core/useChannelStyles';
import CardCompetition from 'components/Competition/Card';
import FontIcon from 'core/ui/FontIcon';
import StatisticCard from './StatisticCard';
import PaymentsReportContent from '../Reports/Payments/ReportContent';
import DataTableMembers from './DataTableMembers';

const ChannelDashboard = ({ channel }) => {
  const { request } = useActions();
  const data = useSelectors(channelReportsModule, 'data');
  const loading = useSelectors(channelReportsModule, 'loading');
  const { buttonColor } = useChannelStyles();
  const loadingResume = useSelectors(channelDashboardModule, 'loading');
  const loadingNewMembers = useSelectors(channelDashboardModule, 'loadingNewMembers');
  const loadingMonthBirthday = useSelectors(channelDashboardModule, 'loadingMonthBirthday');
  const loadingAnniversaries = useSelectors(channelDashboardModule, 'loadingAnniversaries');
  const channelCompetitions = useSelectors(newChannelsModule, 'channelCompetitions');
  const loadingCompetitions = useSelectors(newChannelsModule, 'loadingCompetitions');

  const [perPage, setPerPage] = useState(10);
  const [dashboardResume, setDashboardResume] = useState({});
  const [newMembers, setNewMembers] = useState([]);
  const [monthBirthday, setMonthBirthday] = useState([]);
  const [monthAnniversaries, setMonthAnniversaries] = useState([]);
  const history = useHistory();
  const { t } = useTranslation();

  const [filters, setFilters] = useState({
    workoutId: '',
    kind: 'all',
    paymentStatus: 'success',
    range: 'day',
  });

  const search = ({ page = 1 } = {}) => {
    request({
      action: Modules.channelReports.actions.channelReport,
      data: {
        channelId: channel.id,
        report: 'Payments',
        export: false,
        ...filters,

        page,
        perPage,
      },
    });
  };

  const getDashboardResume = () => {
    request({
      action: Modules.channelDashboard.actions.dashboardResume,
      data: {
        channelId: channel.id,
      },
      options: {
        onSuccess: (resp) => {
          setDashboardResume(resp);
        },
      },
    });
  };

  const getNewMembers = () => {
    request({
      action: Modules.channelDashboard.actions.newMembersMonth,
      data: {
        channelId: channel.id,
      },
      options: {
        onSuccess: (resp) => {
          setNewMembers(resp);
        },
      },
    });
  };

  const getMonthBirthday = () => {
    request({
      action: Modules.channelDashboard.actions.monthBirthday,
      data: {
        channelId: channel.id,
      },
      options: {
        onSuccess: (resp) => {
          setMonthBirthday(resp);
        },
      },
    });
  };

  const getMonthAnniversaries = () => {
    request({
      action: Modules.channelDashboard.actions.monthAnniversaries,
      data: {
        channelId: channel.id,
      },
      options: {
        onSuccess: (resp) => {
          setMonthAnniversaries(resp);
        },
      },
    });
  };

  const getCompetitions = () => {
    request({
      action: Modules.newChannels.actions.getChannelCompetitions,
      data: { channelId: channel.id },
    });
  };

  const refreshAll = () => {
    search();
    getMonthAnniversaries();
    getDashboardResume();
    getMonthBirthday();
    getNewMembers();
    getCompetitions();
  };

  useEffect(() => {
    if (channel?.id) {
      refreshAll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel]);

  useEffect(() => {
    if (channel && channel.id) {
      request({
        action: Modules.newChannels.actions.getChannelCompetitions,
        data: { channelId: channel.id },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel, perPage]);

  return (
    <Box p={5} style={{ position: 'relative' }}>
      <Box style={{ position: 'absolute', right: 0, top: -45, zIndex: 100 }}>
        <Button onClick={() => refreshAll()}>
          <Refresh fontSize="large" />
        </Button>
      </Box>

      <Grid container spacing={5} mb={5}>
        <Grid item xs={12} lg={4} xl={3}>
          <StatisticCard
            icon="people"
            label="Active Members"
            loading={loadingResume}
            amount={
              dashboardResume?.membersTypes?.find((x) => x.name === 'membership_active')?.count
            }
            iconColor="white"
            colorBgIcon={buttonColor}
            labelButton={t('button.viewMembers')}
            onClick={() => history.push(`/channel/manage/${channel.id}/members`)}
          />
        </Grid>
        <Grid item xs={12} lg={4} xl={3}>
          <StatisticCard
            icon="payments"
            loading={loadingResume}
            label={t('label.transactionsDay')}
            amount={channelCurrency(dashboardResume?.payments?.day?.income, channel?.currency)}
            iconColor="white"
            colorBgIcon={twColors.emerald[400]}
            bottomText={
              <Box display="flex" alignItems="center" justifyContent="flex-end">
                Net:&nbsp;
                <Typography color="primary">
                  {channelCurrency(dashboardResume?.payments?.day?.revenue, channel?.currency)}
                </Typography>
              </Box>
            }
          />
        </Grid>
        <Grid item xs={12} lg={4} xl={3}>
          <StatisticCard
            icon="payments"
            loading={loadingResume}
            label={t('label.transactionsWeek')}
            amount={channelCurrency(dashboardResume?.payments?.week?.income, channel?.currency)}
            iconColor="white"
            colorBgIcon={twColors.emerald[500]}
            bottomText={
              <Box display="flex" alignItems="center" justifyContent="flex-end">
                Net:&nbsp;
                <Typography color="primary">
                  {channelCurrency(dashboardResume?.payments?.week?.revenue, channel?.currency)}
                </Typography>
              </Box>
            }
          />
        </Grid>
        <Grid item xs={12} lg={4} xl={3}>
          <StatisticCard
            icon="payments"
            loading={loadingResume}
            label={t('label.transactionsMonth')}
            amount={channelCurrency(dashboardResume?.payments?.month?.income, channel?.currency)}
            iconColor="white"
            colorBgIcon={twColors.emerald[600]}
            bottomText={
              <Box display="flex" alignItems="center" justifyContent="flex-end">
                Net:&nbsp;
                <Typography color="primary">
                  {channelCurrency(dashboardResume?.payments?.month?.revenue, channel?.currency)}
                </Typography>
              </Box>
            }
          />
        </Grid>
      </Grid>

      <Grid container spacing={5} mb={3}>
        <Grid item xs={12} lg={4} xl={4}>
          <StatisticCard
            componentIcon={<GroupAdd style={{ color: 'white', fontSize: 20 }} />}
            label={t('label.newMembersMonth')}
            amount={newMembers?.length}
            colorBgIcon={twColors.cyan[600]}
            loading={loadingNewMembers}
            component={
              <Box mt={1}>
                <DataTableMembers channel={channel} newMembers={newMembers || []} />
              </Box>
            }
          />
        </Grid>
        <Grid item xs={12} lg={4} xl={4}>
          <StatisticCard
            icon="cake"
            label={t('label.birthdaysThisMonth')}
            amount={monthBirthday?.length}
            iconColor="white"
            colorBgIcon={twColors.cyan[500]}
            loading={loadingMonthBirthday}
            component={
              <Box mt={1}>
                <DataTableMembers
                  channel={channel}
                  newMembers={monthBirthday || []}
                  addColums={[
                    {
                      name: 'Date',
                      selector: 'id',
                      sortable: false,
                      width: '100px',
                      cell: (row) => (
                        <Typography style={fontStyle.small}>
                          {date(row?.birthday, channel?.dateFormatReact)}
                        </Typography>
                      ),
                    },
                  ]}
                />
              </Box>
            }
          />
        </Grid>
        <Grid item xs={12} lg={4} xl={4}>
          <StatisticCard
            icon="assignment-ind"
            label={t('label.gymAnniversaries')}
            amount={monthAnniversaries?.length}
            iconColor="white"
            loading={loadingAnniversaries}
            colorBgIcon={twColors.cyan[700]}
            component={
              <Box mt={1}>
                <DataTableMembers
                  channel={channel}
                  newMembers={monthAnniversaries || []}
                  addColums={[
                    {
                      name: 'Date',
                      selector: 'id',
                      sortable: false,
                      width: '100px',
                      cell: (row) => (
                        <Typography style={fontStyle.small}>
                          {date(row?.joinedDate, channel?.dateFormatReact)}
                        </Typography>
                      ),
                    },
                  ]}
                />
              </Box>
            }
          />
        </Grid>
      </Grid>

      <Divider my={2} />

      <Box mt={8}>
        <StatisticCard
          icon="payments"
          label={t('label.payments')}
          iconColor="white"
          colorBgIcon={primary}
          component={
            <Box>
              <Box mb={2} display="flex" justifyContent="center">
                <Box>
                  <Toggle
                    value={filters?.range}
                    onChange={(v) => setFilters((prev) => ({ ...prev, range: v }))}
                    options={[
                      { value: 'day', label: t('label.currentDay'), width: 100 },
                      { value: 'month', label: t('label.currentMonth'), width: 100 },
                    ]}
                  />
                </Box>
                <Box mx={1}>
                  <Toggle
                    value={filters?.paymentStatus}
                    onChange={(v) => setFilters((prev) => ({ ...prev, paymentStatus: v }))}
                    options={[
                      { value: 'success', label: t('label.success') },
                      { value: 'failed', label: t('label.failed') },
                      { value: 'refunded', label: t('label.refunded') },
                    ]}
                  />
                </Box>
                <Button onClick={() => search()}>
                  <Refresh />
                </Button>
              </Box>
              <PaymentsReportContent
                getReport={(page) => {
                  search({ page: page || 1 });
                }}
                data={data}
                channel={channel}
                rows={data?.payments || []}
                loading={loading}
                perPage={perPage}
                setPerPage={setPerPage}
              />
            </Box>
          }
        />
      </Box>

      <Divider my={2} />

      <Box mt={8}>
        <StatisticCard
          componentIcon={<FontIcon iconName="icon-kettlebell" color="white" size={20} />}
          label={t('label.hostedCompetitions')}
          amount={channelCompetitions?.length}
          loading={loadingCompetitions}
          colorBgIcon={twColors.cyan[700]}
          component={
            <div>
              <div className="tw-grid tw-grid-cols-1 tw-gap-2 tw-p-2 sm:tw-grid-cols-3 md:tw-grid-cols-4 xl:tw-grid-cols-5">
                {channelCompetitions &&
                  channelCompetitions.map((object, index) => (
                    <Box
                      style={{ minWidth: 230 }}
                      mr={2}
                      key={index.toString()}
                      className="search-item"
                    >
                      <CardCompetition key={object.id} competition={object} />
                    </Box>
                  ))}
              </div>
            </div>
          }
        />
      </Box>
    </Box>
  );
};

export default ChannelDashboard;
