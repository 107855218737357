import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import InputBase from '@material-ui/core/InputBase';
import { MenuItem, Select } from '@material-ui/core';
import Typography, { fontStyle } from '../Typography';
import Box from '../Box';
import { secondaryLight } from '../Colors';
import Paper from '../Paper';

const BootstrapInput = withStyles(() => ({
  input: {
    borderRadius: 5,
    position: 'relative',
    backgroundColor: '#fff',
    width: '100%',
    fontSize: 12,
    paddingLeft: 5,
    '&:focus': {
      backgroundColor: '#fff',
      borderRadius: 5,
      borderColor: '#80bdff',
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: 5,
    width: '100%',
  },
  select: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontSize: 12,
    paddingLeft: 5,
  },
  placeHolder: {
    color: '#C7C7CD',
  },
}));

export default function SelectBox({
  options,
  placeholder,
  value,
  label,
  error,
  setValue,
  disabled,
  required,
  mbl,
  bordered,
  style = {},
  emptyItem,
  emptyValue,
}) {
  const classes = useStyles();

  const styleInput = {
    // eslint-disable-next-line no-nested-ternary
    border: bordered && !error ? `1px solid ${secondaryLight}` : error ? '1px solid red' : 'none',
    ...style,
  };

  return (
    <div>
      {label !== '' && (
        <Box ml={2} mb={mbl} display="flex">
          <Typography style={fontStyle.label} variant="body1">
            {label}
          </Typography>
          {required && (
            <Typography ml={1} color="error">
              *
            </Typography>
          )}
        </Box>
      )}
      <Paper className={classes.root} style={styleInput}>
        <Select
          className={`${classes.select} ${value === 'undefined' ? classes.placeHolder : ''}`}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          disabled={disabled}
          input={<BootstrapInput />}
        >
          {(emptyItem || placeholder) && (
            <MenuItem value={emptyValue} style={{ height: 40, color: 'grey' }}>
              <em>{placeholder}</em>
            </MenuItem>
          )}
          {options.map((x) => (
            <MenuItem key={x} value={`${x}`}>
              {x}
            </MenuItem>
          ))}
        </Select>
      </Paper>
    </div>
  );
}

SelectBox.defaultProps = {
  label: '',
  options: [],
  mbl: 2,
  value: '',
  placeholder: '',
  error: null,
  setValue: null,
  required: false,
  emptyValue: null,
};
