import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import Grid from 'core/ui/Grid';
import Typography, { fontStyle } from 'core/ui/Typography';
import { successColor } from 'core/ui/Colors';
import { channelCurrency } from 'core/utils/formatters';
import Paper from 'core/ui/Paper';
import RemoveIcon from '@material-ui/icons/Remove';

const CardNetIncome = ({ channel, data }) => {
  const { t } = useTranslation();

  const CardTotals = ({ amount, label, positive, style = fontStyle.large }) => {
    if (amount === '0.00') {
      return <></>;
    }
    return (
      <Box display="flex" flexDirection="column">
        <Typography style={style}>{label}</Typography>
        <Typography style={{ ...style, color: positive ? successColor : 'red' }} color="secondary">
          {channelCurrency(amount, channel?.currency)}
        </Typography>
      </Box>
    );
  };

  return (
    <Paper className="paper-rounded" style={{ overflow: 'hidden' }}>
      <Typography variant="h5" p={3}>
        {t('label.netIncome')}
      </Typography>

      <Grid container spacing={3} p={3} style={{ height: 200 }}>
        <Grid item xs={4}>
          <CardTotals
            label={t('label.grossIncome')}
            positive
            amount={data.resumePaymentsAmounts?.income}
          />
        </Grid>

        <Grid item xs={4}>
          <Box display="flex" justifyContent="center">
            <RemoveIcon fontSize="large" />
          </Box>
        </Grid>
        <Grid item xs={4}>
          <CardTotals label={t('label.expenses')} amount={data.resumePaymentsAmounts?.expenses} />
        </Grid>
      </Grid>

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        style={{ backgroundColor: '#D4EDD2' }}
        p={5}
      >
        <Typography variant="h2" style={{ color: successColor }}>
          {channelCurrency(data.resumePaymentsAmounts?.revenue, channel?.currency)}
        </Typography>
      </Box>
    </Paper>
  );
};

export default CardNetIncome;
