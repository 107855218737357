import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import Box from 'core/ui/Box';
import Video from 'core/ui/Video';
import { roomSelectors } from 'modules/room';
import { workoutsSelectors } from 'modules/workouts';

import { sessionWorkoutActions } from 'modules/sessionWorkout';
import useWindowSize from 'core/utils/hook/useWindowSize';
import { sessionSelectors } from 'modules/session';
import { Chip } from '@material-ui/core';
import LoaderSm from 'core/ui/LoaderSm';
import { useTranslation } from 'react-i18next';

export default function WorkoutCompVideo() {
  const workout = useSelector((state) => workoutsSelectors.getWorkout(state));
  const userSession = useSelector((state) => sessionSelectors.getUser(state));
  const { t } = useTranslation();
  const mutedRecordings = useSelector((state) => roomSelectors.getMutedRecordings(state));

  const dispatch = useDispatch();

  const [time, setTime] = useState({ value: 0, complete: 0, jumped: 0, seek: 0, seekJump: 0 });

  useEffect(() => {
    if (workout.id) {
      dispatch(sessionWorkoutActions.getEventsStart(workout.id));
    }
  }, [dispatch, workout]);

  const size = useWindowSize();

  const getSize = (rec, newSize) => {
    let width = newSize;
    let height = Math.round((width / rec.resWidth) * rec.resHeight, 0);
    if (height > size.height - 200) {
      height = size.height - 200;
      width = (height / rec.resHeight) * rec.resWidth;
    }

    return {
      width,
      height,
    };
  };

  const getRecordingUser = () => workout.recordingVideos.find((x) => x.user.id === userSession.id);

  return (
    <>
      {getRecordingUser() ? (
        <Box style={{ height: '100%', width: 400 }} display="flex" flexDirection="column" px={3}>
          <Box className="wrapper-rec" m={1}>
            <Box
              className="box-rec active-rec-3"
              style={{
                height: `${getSize(getRecordingUser(), 400).height}px`,
                position: 'relative',
                background: '#000',
                borderRadius: 10,
              }}
            >
              <Video
                playing
                muted={mutedRecordings}
                src={getRecordingUser().vimeoUrl}
                vimeo
                full
                showPlayButton
                seek={time.seek}
                onSeek={(v) => setTime((prev) => ({ ...prev, complete: v }))}
                borderRadius={10}
              />
            </Box>
          </Box>
        </Box>
      ) : (
        <Box my={5} display="flex" justifyContent="center">
          {workout.competitionAccessed &&
            workout.recordingVideos.find((x) => x.user && x.user.id === userSession.id) && (
              <Chip
                icon={<LoaderSm loading />}
                color="primary"
                label={t('workout.competition.processing')}
              />
            )}
        </Box>
      )}
    </>
  );
}

WorkoutCompVideo.propTypes = {};

WorkoutCompVideo.defaultProps = {};
