import { all, call, put } from 'redux-saga/effects';

import { getErrorMessage } from 'core/api/api-error';

import notifications from 'modules/notifications';

import * as api from './api';
import { bannersActions as actions } from './index';

// eslint-disable-next-line import/prefer-default-export
export function* getBanners() {
  try {
    const response = yield call(api.banners);
    const banners = response.data;
    yield put(actions.getBannersSuccess(banners));
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.getBannersError(getErrorMessage(error))),
    ]);
  }
}

export function* getBannersHeader() {
  try {
    const response = yield call(api.bannersHeader);
    const banners = response.data;
    yield put(actions.getBannersHeaderSuccess(banners));
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.getBannersHeaderError(getErrorMessage(error))),
    ]);
  }
}
