import React, { useEffect, useState } from 'react';
import Box from 'core/ui/Box';
import { ButtonPrimary } from 'core/ui/Button';
import { Add } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import useSelectors from 'modules/map/useSelectors';
import { partnersProgramsModule } from 'modules/partnersPrograms';
import LoaderSm from 'core/ui/LoaderSm';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import ModalPartnerProgram from './Form/Modal';
import PartnerProgramsList from './List';

const PartnerPrograms = ({ partner }) => {
  const { t } = useTranslation();
  const { request } = useActions();
  const loading = useSelectors(partnersProgramsModule, 'loading');
  const data = useSelectors(partnersProgramsModule, 'data');
  const [currentProgram, setCurrentProgram] = useState(null);
  const [modalForm, setModalForm] = useState({ open: false });
  const closeModal = () => {
    setModalForm({ open: false });
  };

  const getPrograms = () => {
    request({
      action: Modules.partnersPrograms.actions.getPartnersPrograms,
      data: {
        partnerId: partner?.id,
      },
    });
  };

  useEffect(() => {
    if (partner?.id) {
      setCurrentProgram(null);
      getPrograms();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partner?.id]);

  useEffect(() => {
    if (data?.length > 0) {
      setCurrentProgram(
        currentProgram?.id ? data.find((x) => x.id === currentProgram?.id) : data[0],
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Box>
      <Box display="flex" justifyContent="flex-end">
        <Box flexGrow={1} mr={3}>
          <SelectBoxObject
            height={50}
            value={currentProgram?.id}
            setValue={(v) => setCurrentProgram(data?.find((x) => x.id === Number(v)))}
            options={data && data.map((x) => ({ id: x.id, name: x.name }))}
          />
        </Box>
        <ButtonPrimary onClick={() => setModalForm({ open: true })}>
          <Add /> {t('button.addProgram')}
        </ButtonPrimary>
      </Box>
      <LoaderSm loading={loading} center />
      {currentProgram && (
        <PartnerProgramsList
          partner={partner}
          currentProgram={currentProgram}
          setModalForm={setModalForm}
          getPrograms={getPrograms}
        />
      )}
      <ModalPartnerProgram
        partner={partner}
        close={() => closeModal()}
        program={modalForm.program}
        open={modalForm.open}
        programs={data}
        callbackSave={() => getPrograms()}
      />
    </Box>
  );
};

PartnerPrograms.defaultProps = {};

export default PartnerPrograms;
