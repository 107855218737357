import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import { ButtonPrimary } from 'core/ui/Button';
import DatePickerRounded from 'core/ui/DatePickers/DatePickerRounded';
import { formatDateToApi } from 'core/utils/formatters';
import Radio from 'core/ui/Radio';

export default function ModalConfirmSave({ channel, modalConfirmSave, setModalConfirmSave }) {
  const { t } = useTranslation();
  const [data, setData] = useState({
    startAtUpdateWorkouts: null,
    updateWorkouts: false,
    removeOption: null,
  });

  const handleCancel = () => {
    if (setModalConfirmSave) {
      setModalConfirmSave({ open: false });
    }
  };
  return (
    <div>
      <Dialog
        open={modalConfirmSave.open}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title">
          {t(
            data.updateWorkouts
              ? 'workout.save.confirm.recurringTemplate.startDate'
              : 'channel.coaches.schedules.update',
          )}
        </DialogTitle>
        <DialogContent>
          <Box display="flex" justifyContent="center">
            {data.updateWorkouts && (
              <Box>
                <Box display="flex" flexDirection="column" m={3}>
                  <Radio
                    onClick={() => setData((prev) => ({ ...prev, removeOption: 'remove_all' }))}
                    checked={data?.removeOption === 'remove_all'}
                    label={t('coaches.schedules.update.removeAll.yes')}
                  />
                  <Box mt={1}>
                    <Radio
                      onClick={() => setData((prev) => ({ ...prev, removeOption: 'remove_coach' }))}
                      checked={data?.removeOption === 'remove_coach'}
                      label={t('coaches.schedules.update.removeCoach')}
                    />
                  </Box>
                  <Box mt={1}>
                    <Radio
                      onClick={() => setData((prev) => ({ ...prev, removeOption: null }))}
                      checked={!data?.removeOption}
                      label={t('coaches.schedules.update.removeAll.no')}
                    />
                  </Box>
                </Box>
                <DatePickerRounded
                  label={t('channel.reports.filter.start').toUpperCase()}
                  onChange={(v) => setData((prev) => ({ ...prev, startAtUpdateWorkouts: v }))}
                  value={data.startAtUpdateWorkouts}
                  disablePast={false}
                  usFormat={channel?.useUsDateFormat}
                  autoOk
                  bordered
                />
              </Box>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          {data.updateWorkouts ? (
            <>
              <Box>
                <ButtonPrimary
                  onClick={() => {
                    modalConfirmSave.cancel();
                    setModalConfirmSave({ open: false });
                  }}
                  color="primary"
                >
                  {t('button.cancel')}
                </ButtonPrimary>
              </Box>
              <Box>
                <ButtonPrimary
                  onClick={() => {
                    const startAtUpdateWorkouts = data.startAtUpdateWorkouts
                      ? formatDateToApi(data.startAtUpdateWorkouts)
                      : null;
                    modalConfirmSave.save({ ...data, startAtUpdateWorkouts });
                  }}
                  color="primary"
                >
                  {t('button.confirm')}
                </ButtonPrimary>
              </Box>
            </>
          ) : (
            <>
              <Box>
                <ButtonPrimary
                  onClick={() => modalConfirmSave.save({ updateWorkouts: false })}
                  color="primary"
                >
                  {t('button.template')}
                </ButtonPrimary>
              </Box>
              <Box>
                <ButtonPrimary
                  onClick={() => setData((prev) => ({ ...prev, updateWorkouts: true }))}
                  color="primary"
                >
                  {t('button.templateAndClasses')}
                </ButtonPrimary>
              </Box>
            </>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
