import React, { useEffect } from 'react';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import Paper from 'core/ui/Paper';
import useNewTimer from 'components/Workout/Timer/useNewTimer';
import Chronometer from 'components/Workout/Timer/Chronometer';
import Typography from 'core/ui/Typography';
import { Close } from '@material-ui/icons';
import { typeTimer } from 'core/utils/consts';
import { primary } from 'core/ui/Colors';
import { Hidden, IconButton } from '@material-ui/core';
import PoweredLogo from '../../PoweredLogo';

export default ({ workout, competition, closeTimer, reset, start, setEventTimer }) => {
  const { t } = useTranslation();
  const section = workout.sections[0];

  const { startTimer, timeValue, resetTimer } = useNewTimer({
    section,
    live: false,
    playingRecordings: false,
    playingCompetition: false,
    isCoach: workout.isCoach,
  });

  useEffect(() => {
    if (start) {
      startTimer();
      setEventTimer((prev) => ({ ...prev, reset: false, start: true, stopped: false }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start]);

  useEffect(() => {
    if (reset) {
      resetTimer();
      setEventTimer((prev) => ({ ...prev, start: false, reset: true, stopped: true }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  return (
    <Paper className="paper-rounded opacity-80" p={3} px={8} style={{ position: 'relative' }}>
      <Box style={{ position: 'absolute', right: 3, top: 1 }} onClick={() => closeTimer()}>
        <IconButton>
          <Close style={{ fontSize: 30 }} />
        </IconButton>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Box flexGrow={1}>
          <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
            <Box display="flex" flexDirection="column" alignItems="center">
              {section.timerData.type !== typeTimer.amrap &&
                section.timerData.type !== typeTimer.forTime &&
                section.timerData.rounds > 1 && (
                  <Box>
                    <Typography
                      style={{
                        color: primary,
                        fontSize: 20,
                      }}
                      variant="caption"
                    >
                      {t('round')}&nbsp;{timeValue.round}/{section.timerData.rounds}
                    </Typography>
                  </Box>
                )}
            </Box>
            <Typography style={{ fontSize: 20 }} variant="caption" align="center">
              {timeValue.active.toUpperCase() || ''}
            </Typography>
            <Hidden only={['xs', 'sm']}>
              <Chronometer timeValue={timeValue} fontSize={150} spaceWidth={73} />
            </Hidden>
            <Hidden only={['md', 'lg', 'xl']}>
              <Chronometer timeValue={timeValue} fontSize={100} spaceWidth={48} />
            </Hidden>
          </Box>
        </Box>
        <Box display="flex" justifyContent="center" style={{ maxWidth: 200 }} mx={5}>
          <PoweredLogo competition={competition} />
        </Box>
      </Box>
    </Paper>
  );
};
