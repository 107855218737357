import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import Box from 'core/ui/Box';
import InstagramLink from 'components/Profile/InstagramLink';
import ImageMediaUser from 'components/Profile/ImageMedia';
import Typography from 'core/ui/Typography';
import { ButtonPrimary } from 'core/ui/Button';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { channelsActions, channelsSelectors } from 'modules/channels';
import { channelMembersActions } from 'modules/channelMembers';
import {
  ButtonGroup,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Popper,
  Grow,
  IconButton,
} from '@material-ui/core';
import { ArrowDropDown, Edit, EventAvailableOutlined } from '@material-ui/icons';
import LabelPhone from 'core/ui/InputPhone/LabelPhone';
import { date, formatDateToApi } from 'core/utils/formatters';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import useSelectors from 'modules/map/useSelectors';
import { usersAddressesModule } from 'modules/usersAddresses';
import LoaderSm from 'core/ui/LoaderSm';
import Divider from 'core/ui/Divider';
import notifications from 'modules/notifications';
import ChoosenChannelTags from 'core/ui/ChoosenChannelTags';
import Grid from 'core/ui/Grid';
import ModalClassesToRegister from './ModalRegisterClass/index';
import ModalEditUser from './ModalEditUser';
import ModalUserInfo from '../../../Plans/Details/ModalUserInfo';
import ModalEditJoinedDate from './ModalEditJoinedDate';
import ChannelResumeInfo from './ChannelResumeInfo';

const UserInfo = ({
  user,
  openModalAddMembership,
  setModalNote,
  setModalHouseHold,
  setModalChild,
  setOpenModalMovement,
  setModalAddPayment,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { request } = useActions();
  const channel = useSelector((state) => channelsSelectors.getChannel(state));
  const [modalClasses, setModalClasses] = useState({ open: false });
  const [modalEdit, setModalEdit] = useState(false);
  const [modalEditJoinedDate, setModalEditJoinedDate] = useState(false);
  const [modalKey, setModalKey] = useState(false);
  const [open, setOpen] = React.useState(false);
  const anchorRef = useRef();
  const usersAddress = useSelectors(usersAddressesModule, 'data');
  const loadingAddress = useSelectors(usersAddressesModule, 'loading');

  const [memberTags, setMemberTags] = useState();

  const getAddresses = () => {
    request({
      action: Modules.usersAddresses.actions.getAdrresses,
      data: { userId: user?.id, channelId: channel?.id },
    });
  };

  const saveTags = (v) => {
    setMemberTags(v);
    request({
      action: Modules.channelsTags.actions.saveMemberTags,
      data: { userId: user?.id, channelId: channel?.id, tags: v?.map((x) => x?.name || x) },
    });
  };

  useEffect(() => {
    if (channel?.id && user?.id) {
      getAddresses();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel?.id, user?.id]);

  useEffect(() => {
    if (user?.id) {
      setMemberTags(user?.channelTags || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id]);

  const assignToUser = (removeUserAt = null, options) => {
    setOpen(false);

    dispatch(
      channelsActions.assignKeysToUserStart({
        channelId: channel.id,
        keys: null,
        online: options?.online,
        inPerson: options?.inPerson,
        inPersonHybridAccess: options?.inPersonHybridAccess,
        inPersonSifelyAccess: options?.inPersonSifelyAccess,
        removeUserAt: removeUserAt ? formatDateToApi(removeUserAt) : null,
        user_id: user.id,
        callback: () => {
          request({
            action: Modules.newChannelMembers.actions.getMemberships,
            data: {
              channelId: channel?.id,
              userId: user.id,
            },
            options: {
              onError: (ex) => {
                dispatch(notifications.error(ex));
              },
            },
          });
          dispatch(notifications.success('Key has been added.'));
          setModalKey({ open: false });
        },
      }),
    );
  };

  const reSendWaiverRequest = () => {
    request({
      action: Modules.usersContracts.actions.sendWaiverToUser,
      data: {
        ownerableId: channel?.id,
        ownerableType: 'Channel',
        userId: user?.id,
        kind: 'waivers',
        documentId: channel?.documentWaiverId,
      },
      options: {
        onSuccess: () => {
          request({
            action: Modules.usersDocuments.actions.getUsersDocuments,
            data: { ownerableId: channel.id, ownerableType: 'Channel', userId: user?.id },
          });
          dispatch(notifications.success('Waiver has been sent.'));
        },
      },
    });
  };

  return (
    <Box>
      {modalClasses.open && (
        <ModalClassesToRegister
          user={user}
          channelId={channel.id}
          open={modalClasses.open}
          close={() => setModalClasses((prev) => ({ ...prev, open: false }))}
          callback={() =>
            dispatch(channelMembersActions.getChannelMemberStart(channel.id, user.id))
          }
          isCoach
        />
      )}

      {modalEdit.open && (
        <ModalEditUser
          user={user}
          channelId={channel.id}
          open={modalEdit.open}
          close={() => setModalEdit((prev) => ({ ...prev, open: false }))}
        />
      )}

      {modalKey?.open && user?.id && (
        <ModalUserInfo
          open={modalKey?.open}
          user={user}
          cancel={() => setModalKey({ open: false })}
          confirm={(u, removeDate, options) => assignToUser(removeDate, options)}
        />
      )}

      <Box display="flex" flexWrap="wrap">
        <Box>
          <ImageMediaUser
            size={180}
            sizeCountry="2em"
            heightCountry={25}
            user={user}
            image={user.image ? user.image : {}}
          />
          <InstagramLink instagram={user.instagram} clickable={false} />
        </Box>
        <Box pl={5} flexGrow={1}>
          <Box display="flex">
            <Box>
              <Typography mb={1} color="primary" variant="h3">
                {user.name}
              </Typography>
              {user.parentName && (
                <Typography mb={1} color="primary" variant="h3">
                  Parent: {user.parentName}
                </Typography>
              )}

              <Typography mb={1} color="secondary" variant="h6">
                {user.email}
              </Typography>
              {user.phone && (
                <Box>
                  <LabelPhone value={user.phone} />
                </Box>
              )}

              <Box display="flex" alignItems="center" mt={2}>
                <Typography className="roboto-regular">
                  {t('channel.member.joinedDate')}:&nbsp;
                </Typography>
                <EventAvailableOutlined />
                <Typography>
                  {user?.memberAnniversaryDate &&
                    date(user?.memberAnniversaryDate, channel?.dateFormatReact)}
                </Typography>
                <IconButton onClick={() => setModalEditJoinedDate(true)}>
                  <Edit />
                </IconButton>
                <ModalEditJoinedDate
                  open={modalEditJoinedDate}
                  close={() => setModalEditJoinedDate(false)}
                  user={user}
                  channel={channel}
                />
              </Box>

              {user?.referredBy && (
                <Box display="flex" alignItems="center" mt={2}>
                  <Typography className="roboto-regular">
                    {t('channel.member.referredBy')}:&nbsp;
                  </Typography>
                  <Typography>{user?.referredBy}</Typography>
                </Box>
              )}
              {(loadingAddress || usersAddress?.length > 0) && (
                <Box mt={2}>
                  <Typography variant="h6">{t('user.addresses')}</Typography>
                  <LoaderSm center loading={loadingAddress} />
                  {usersAddress?.map((add, index) => (
                    <Box key={add.id}>
                      <Box flexGrow={1}>
                        <Typography className="roboto-regular">{add?.street}</Typography>
                        <Typography className="roboto-regular">
                          {add?.country?.name}, {add?.city}, {add?.state}, {add?.zip}
                        </Typography>
                      </Box>
                      {usersAddress.length - 1 > index && <Divider m={1} />}
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
            <Box ml={5}>
              <Typography mb={1} color="primary" variant="h3">
                &nbsp;
              </Typography>
              <Typography variant="h6">{t('profile.title.emergencyContact')}</Typography>

              <Box>
                <Typography className="roboto-regular">{user?.emergencyName}</Typography>
                <Typography className="roboto-regular">{user?.emergencyEmail}</Typography>
                <Typography className="roboto-regular">{user?.emergencyPhone}</Typography>
              </Box>
            </Box>
          </Box>

          <Grid container spacing={5}>
            <Grid item xs={12} md={6}>
              <ChoosenChannelTags
                label={t('channel.member.tags')}
                placeholder={t('channel.member.tags')}
                val={memberTags ? [...memberTags] : []}
                setValue={(v) => saveTags(v)}
                channelId={channel?.id}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <ChannelResumeInfo member={user} />
            </Grid>
          </Grid>
        </Box>
        <Box m={3} display="flex">
          <Box>
            <ButtonGroup
              ref={anchorRef}
              variant="contained"
              color="primary"
              aria-label="split button"
            >
              <ButtonPrimary onClick={() => setOpen(true)}>
                <Typography noWrap>Actions</Typography> <ArrowDropDown />
              </ButtonPrimary>
            </ButtonGroup>
            <Popper
              open={open}
              anchorEl={anchorRef?.current}
              role={undefined}
              transition
              style={{ zIndex: 10 }}
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={() => setOpen(false)}>
                      <Box>
                        <MenuList>
                          <MenuItem
                            onClick={() => {
                              setOpen(false);
                              setModalEdit((prev) => ({ ...prev, open: true }));
                            }}
                          >
                            {t('channel.members.editUser')}
                          </MenuItem>
                          {user?.householdUsers?.length === 0 && !user?.hasUserHousehold && (
                            <MenuItem
                              onClick={() => {
                                setOpen(false);
                                setModalHouseHold((prev) => ({ ...prev, open: true }));
                              }}
                            >
                              {t('channel.members.createHouseHold')}
                            </MenuItem>
                          )}
                          <MenuItem
                            onClick={() => {
                              setOpen(false);
                              setModalKey({ open: true });
                            }}
                          >
                            {t('button.createKey')}
                          </MenuItem>
                          {!user?.child && (
                            <MenuItem
                              onClick={() => {
                                setOpen(false);
                                setModalChild({ open: true });
                              }}
                            >
                              {t('button.addChild')}
                            </MenuItem>
                          )}
                          <MenuItem
                            onClick={() => {
                              setOpen(false);
                              setModalNote((prev) => ({ ...prev, noteId: null, open: true }));
                            }}
                          >
                            {t('button.addNote')}
                          </MenuItem>
                          {user.memberTypes?.find((x) => x === 'membership_active') && (
                            <MenuItem
                              onClick={() => {
                                setOpen(false);
                                setModalClasses({ open: true });
                              }}
                            >
                              {t('channel.members.classRegistration')}
                            </MenuItem>
                          )}
                          <MenuItem
                            onClick={() => {
                              setOpen(false);
                              openModalAddMembership();
                            }}
                          >
                            {t('channel.tab.members.addMembership')}
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              setOpen(false);
                              setModalAddPayment({ open: true });
                            }}
                          >
                            {t('channel.tab.members.addPayments')}
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              setOpen(false);
                              setOpenModalMovement(true);
                            }}
                          >
                            {t('button.addCredit')}
                          </MenuItem>
                          {!!channel?.documentWaiverId && (
                            <MenuItem
                              onClick={() => {
                                setOpen(false);
                                reSendWaiverRequest();
                              }}
                            >
                              {t('button.resendWaiver')}
                            </MenuItem>
                          )}
                        </MenuList>
                      </Box>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

UserInfo.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.object.isRequired,
};

UserInfo.defaultProps = {};

export default UserInfo;
