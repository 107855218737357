import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Typography from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import { dateExt } from 'core/utils/formatters';
import { secondary, secondaryLight } from 'core/ui/Colors';
import { PaymentStatus } from 'core/utils/consts';
import { ButtonPrimary } from 'core/ui/Button';
import PaymentStatusColumn from 'components/Channel/Manage/Tabs/Reports/Payments/PaymentStatusColumn';
import { DownloadReceiptButton } from './DownloadReceiptButton';

const useStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
  divider: {
    background: secondaryLight,
    color: secondary,
    borderWidth: 0.5,
    width: '100%',
    margin: 5,
  },
});

function PaymentItem({ payment, openModalRetry, openAction }) {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const { t } = useTranslation();
  const styleOpen = {
    outline: '1px solid #eee',
    outlineOffset: '-1px',
  };

  const falied = [PaymentStatus.failed, PaymentStatus.requires_payment_method].find(
    (x) => x === payment.status,
  );
  const showProcessAgain = payment.allowRetry && falied;

  return (
    <>
      <TableRow
        style={open ? styleOpen : {}}
        onClick={() => setOpen(!open)}
        hover
        className={classes.root}
      >
        <TableCell>
          {payment?.id}
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          {!payment?.holdTransaction && (
            <>
              {payment?.userPaymentMethod ? (
                <>
                  {payment.userPaymentMethod.cardFlag?.toUpperCase()}&nbsp;
                  {payment.userPaymentMethod.lastFour}
                </>
              ) : (
                <Typography>Offline - {payment?.offlineType?.toUpperCase()}</Typography>
              )}
            </>
          )}
        </TableCell>
        <TableCell>{t(`payment.type.${payment.kind}`)}</TableCell>
        <TableCell>{dateExt(payment.createdAt)}</TableCell>
        <TableCell align="center">
          <PaymentStatusColumn row={payment} />
        </TableCell>
        <TableCell align="right">{payment.amount}</TableCell>
        <TableCell className="tw-flex tw-items-center tw-justify-center tw-gap-2">
          {showProcessAgain && (
            <ButtonPrimary style={{ width: 100 }} onClick={() => openModalRetry(payment)}>
              {t('button.retry')}
            </ButtonPrimary>
          )}

          {payment?.status === 'requires_action' && !!payment?.nextActionSecret && (
            <ButtonPrimary style={{ width: 100 }} onClick={() => openAction(payment)}>
              {t('button.confirmPayment')}
            </ButtonPrimary>
          )}

          {payment.allowReceipt && <DownloadReceiptButton payment={payment} />}
        </TableCell>
      </TableRow>

      <TableRow style={open ? styleOpen : {}}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box mx={1} mt={3} mb={5}>
              <Box mb={3} display="flex" flexDirection="column" alignItems="center">
                <Typography mb={3} variant="h5" color="primary">
                  {t('payments.summary')}
                </Typography>
                <Typography variant="h6">{payment.summary}</Typography>
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

PaymentItem.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  payment: PropTypes.object.isRequired,
};

export default PaymentItem;
