/* eslint-disable no-underscore-dangle */
import React from 'react';
import Box from 'core/ui/Box';
import Typography from 'core/ui/Typography';

const ProductOption = ({ option }) => (
  <Box>
    {option?.optionFirst ? (
      <>
        <Box display="flex" alignItems="flex-end">
          <Typography color="textSecondary">{option?.optionFirst?.parent?.name}:&nbsp;</Typography>
          <Typography>{option?.optionFirst?.name}</Typography>
        </Box>
        {option?.optionSecond && (
          <Box display="flex" alignItems="center">
            <Typography color="textSecondary">
              {option?.optionSecond?.parent?.name}:&nbsp;
            </Typography>
            <Typography>{option?.optionSecond?.name}</Typography>
          </Box>
        )}
        {option?.optionThird && (
          <Box display="flex" alignItems="center">
            <Typography color="textSecondary">
              {option?.optionThird?.parent?.name}:&nbsp;
            </Typography>
            <Typography>{option?.optionThird?.name}</Typography>
          </Box>
        )}
      </>
    ) : (
      <Typography>{option?.kind}</Typography>
    )}
  </Box>
);

ProductOption.defaultProps = {};

export default ProductOption;
