import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

import * as selectors from './selectors';
import * as sagas from './sagas';

/* ---------- Action Creators and Types ---------- */
const { Types, Creators } = createActions(
  {
    getFiltersError: ['error'],
    getFiltersStart: [],
    getFiltersSuccess: ['filters'],
  },
  { prefix: 'filters/' },
);

/* ---------- Initial State ---------- */
const INITIAL_STATE = Immutable({
  data: [],
  error: null,
  loading: false,
});

/* ---------- Reducers ---------- */
const loadStartReducer = (state) => state.set('loading', true);

const getFiltersSuccessReducer = (state, action) =>
  state.merge({
    data: action.filters,
    loading: false,
    error: null,
  });

const loadErrorReducer = (state, action) =>
  state.merge({
    loading: false,
    error: action.error,
  });

/* ---------- Main Reducer ---------- */
const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_FILTERS_START]: loadStartReducer,
  [Types.GET_FILTERS_SUCCESS]: getFiltersSuccessReducer,
  [Types.GET_FILTERS_ERROR]: loadErrorReducer,
});

/* ---------- Exporting ---------- */
export const filtersActions = Creators;
export const filtersTypes = Types;
export const filtersSelectors = selectors;
export const filtersSagas = sagas;

export default reducer;
