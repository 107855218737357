import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import { ButtonPrimary } from 'core/ui/Button';
import { useDispatch, useSelector } from 'react-redux';
import notifications from 'modules/notifications';
import Typography from 'core/ui/Typography';
import { workoutsActions, workoutsSelectors } from 'modules/workouts';
import LoaderSm from 'core/ui/LoaderSm';
import { getVideoTypeByLink } from 'core/utils/helpers';
import SelectorUser from 'components/Profile/SelectorUser';
import CardUser from 'components/Channel/SelectorChannelUser/Card';
import Paper from 'core/ui/Paper';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import Divider from 'core/ui/Divider';
import { workoutInviteTypes } from 'core/utils/consts';
import Grid from 'core/ui/Grid';
import { sessionActions } from 'modules/session';
import useCloneWorkout from './useCloneWorkout';

export default function ShareWorkoutScores({ workout, callback, close, isOwner, goToWorkout }) {
  const { t } = useTranslation();
  const { clone: cloneWorkout } = useCloneWorkout();
  const [clone, setClone] = useState(false);

  const loadingInvite = useSelector((state) =>
    workoutsSelectors.getLoadingWorkoutRecordingInvites(state),
  );
  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const [currentScreen, setCurrentScreen] = useState('normal');

  const hasOtherInvite =
    workout?.invites?.filter((x) => x.inviteType !== workoutInviteTypes.share_scores).length > 0;

  useEffect(() => {
    if (hasOtherInvite || !isOwner) {
      setCurrentScreen('confirmClone');
    } else {
      setCurrentScreen('normal');
    }
    setClone(!isOwner || hasOtherInvite);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workout, isOwner]);

  const validate = () => {
    if (users?.length === 0) {
      dispatch(notifications.warning(t('workout.custom.invite.selectUser')));
      return false;
    }

    return true;
  };

  const createInvite = () => {
    if (!validate()) {
      return;
    }

    if (!clone) {
      const dataInvite = {
        workoutId: workout?.id,
        invites: users.map((x) => ({ userId: x.id })),
        inviteType: workoutInviteTypes.share_scores,
      };
      dispatch(
        workoutsActions.postWorkoutRecordingInvitesStart(dataInvite, () => {
          if (callback) {
            callback();
          }
          goToWorkout(workout);
          dispatch(notifications.success(t('workout.invite.recording.success')));
        }),
      );
    } else {
      const dataInvite = {
        workoutId: workout?.id,
        users,
        inviteType: workoutInviteTypes.share_scores,
      };
      cloneWorkout({
        workout,
        dataInvite,
        callback: (workoutResp) => {
          dispatch(sessionActions.authenticateStart());
          callback(workoutResp);
          goToWorkout(workoutResp);
          close();
        },
      });
    }
  };

  const videoLink = workout?.videoLink ? getVideoTypeByLink(workout?.videoLink) : null;
  const showMessageApp = videoLink && ['youtube', 'vimeo'].find((x) => x === videoLink.type);

  return (
    <div>
      <Typography mb={3} variant="h5" align="center" color="primary">
        {t('button.shareWorkoutScores')}
      </Typography>

      {showMessageApp && (
        <Typography mb={5} mx={3} variant="h5" color="primary">
          {t('workout.videoLink.alertApp').toUpperCase()}
        </Typography>
      )}

      {currentScreen === 'confirmClone' && (
        <Box mb={5}>
          <Typography>{t('workout.invite.duplicate')}</Typography>

          <Grid container spacing={3} mt={3}>
            <Grid item xs={6} md={6}>
              <ButtonPrimary fullWidth onClick={() => close()}>
                {t('button.noThanks')}
              </ButtonPrimary>
            </Grid>
            <Grid item xs={6} md={6}>
              <ButtonPrimary fullWidth onClick={() => setCurrentScreen('normal')}>
                {t('button.yes')}
              </ButtonPrimary>
            </Grid>
          </Grid>
        </Box>
      )}

      {currentScreen === 'normal' && (
        <>
          <Box mt={3}>
            <Typography align="center" variant="h6" mb={3} color="secondary">
              {t('workout.custom.invite.users').toUpperCase()}
            </Typography>
            {users && users.length > 0 && (
              <Paper p={3} elevation={4} className="paper-rounded">
                {users &&
                  users.map((user, index) => (
                    <Box key={user?.id} style={{ position: 'relative' }}>
                      <Box display="flex" flexWrap="wrap">
                        <IconButton
                          style={{ position: 'absolute', right: 0, top: 0 }}
                          onClick={() => setUsers((prev) => prev.filter((y) => y !== user))}
                        >
                          <Close />
                        </IconButton>
                        <CardUser user={user} />
                      </Box>
                      {users.length - 1 > index && <Divider my={3} />}
                    </Box>
                  ))}
              </Paper>
            )}
          </Box>

          <Paper p={3} mt={3}>
            <Typography align="center" variant="h6" color="secondary">
              {t('select.user.message').toUpperCase()}
            </Typography>
            <SelectorUser onSelectedUser={(u) => setUsers((prev) => [...prev, u])} hideTexture />
          </Paper>

          <Box display="flex" mt={3} justifyContent="flex-end">
            <ButtonPrimary disabled={loadingInvite} onClick={() => createInvite()} fullWidth>
              <LoaderSm loading={loadingInvite} />
              {t('button.sendInvite')}
            </ButtonPrimary>
          </Box>
        </>
      )}
    </div>
  );
}

ShareWorkoutScores.propTypes = {};
