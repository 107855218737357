import { createSelector } from 'reselect';

const getState = (state) => state.language;

export const getLoading = createSelector(getState, (state) => state && !!state.loading);

export const getError = createSelector(getState, (state) => !state.loading && !!state.error);

export const getLanguages = createSelector(getState, (state) => state.data);

export const getLanguage = createSelector(getState, (state) => state.language || {});
