import React, { useState } from 'react';
import { ButtonPrimary } from 'core/ui/Button';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import { useForm } from 'react-hook-form-v5';

import Modules from 'modules';
import useActions from 'modules/map/useActions';
import useSelectors from 'modules/map/useSelectors';
import { helpVideoModule } from 'modules/helpVideo';

export const AddNewHelpVideoButton = ({ getHelpVideosToSelect }) => {
  const { request } = useActions();
  const { register, handleSubmit } = useForm({ defaultValues: { title: '', vimeoUrl: '' } });

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = (data) => {
    request({
      action: Modules.helpVideo.actions.create,
      data,
      options: {
        onSuccess: () => {
          handleClose();
          getHelpVideosToSelect();
        },
      },
    });
  };

  return (
    <>
      <ButtonPrimary onClick={() => setOpen(true)}>Add new Help Video</ButtonPrimary>

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: handleSubmit(onSubmit),
        }}
      >
        <DialogTitle>Create Help Video</DialogTitle>

        <DialogContent>
          <TextField
            inputRef={register}
            autoFocus
            required
            margin="dense"
            id="title"
            name="title"
            label="Title"
            type="text"
            fullWidth
            variant="standard"
          />

          <TextField
            inputRef={register}
            required
            margin="dense"
            id="vimeoUrl"
            name="vimeoUrl"
            label="Vimeo URL"
            type="text"
            fullWidth
            variant="standard"
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <CreateButton />
        </DialogActions>
      </Dialog>
    </>
  );
};

const CreateButton = () => {
  const loading = useSelectors(helpVideoModule, 'loading');

  return <Button type="submit">{loading ? <CircularProgress /> : 'Create'}</Button>;
};
