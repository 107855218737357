import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import Typography from 'core/ui/Typography';
import { useDispatch } from 'react-redux';
import { Dialog } from '@material-ui/core';
import Texture from 'core/ui/Texture';
import TextAreaFieldRounded from 'core/ui/TextAreaFieldRounded';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import { useForm } from 'react-hook-form-v5';
import { ButtonPrimary } from 'core/ui/Button';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import useSelectors from 'modules/map/useSelectors';
import { channelUserCreditsModule } from 'modules/channelUserCredits';
import notifications from 'modules/notifications';

export default function ModalFormMovement({ open, close, channel, userId }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { request } = useActions();
  const loading = useSelectors(channelUserCreditsModule, 'loading');
  const { setValue, watch, errors, register, handleSubmit } = useForm();

  useEffect(() => {
    register('description', { required: true });
    register('amount', { required: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const saveMovement = (data) => {
    request({
      action: Modules.channelUserCredits.actions.createTransaction,
      data: { ...data, channelId: channel?.id, userId },
      options: {
        onSuccess: () => {
          request({
            action: Modules.channelUserCredits.actions.getUserCredit,
            data: {
              channelId: channel?.id,
              userId,
            },
          });
          setValue('amount', '');
          setValue('description', '');
          close();
        },
        onError: (error) => {
          dispatch(notifications.error(error));
        },
      },
    });
  };

  return (
    <Box>
      <Dialog open={open} fullWidth maxWidth="xs" onClose={() => close()}>
        <Texture>
          <Box p={5}>
            <Typography mb={5} variant="h4" align="center">
              {t('credit.label.memberCredit')}
            </Typography>
            <Box mb={3}>
              <TextAreaFieldRounded
                label={t('description')}
                value={watch('description') || ''}
                onChange={(v) => setValue('description', v)}
                error={errors.description}
                required
              />
            </Box>
            <Box mb={3}>
              <TextFieldRounded
                type="number"
                pattern="[0-9]*"
                error={errors.amount}
                value={watch('amount') || ''}
                onChange={(v) => setValue('amount', v)}
                placeholder={t('credit.placeholder.amount')}
                label={t('credit.label.amount').toUpperCase()}
                required
                currency
                bordered
                currencySymbol={channel?.currency?.symbol}
              />
            </Box>
            <ButtonPrimary
              fullWidth
              disabled={loading}
              onClick={() => handleSubmit(saveMovement)()}
            >
              {t('button.save')}
            </ButtonPrimary>
          </Box>
        </Texture>
      </Dialog>
    </Box>
  );
}
ModalFormMovement.defaultProps = {};
