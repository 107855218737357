import React, { forwardRef } from 'react';
import styled from 'styled-components';
// eslint-disable-next-line import/no-extraneous-dependencies
import { spacing } from '@material-ui/system';
import { Typography as MuiTypography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

const TypographySpacing = styled(MuiTypography)(spacing);

export const fontStyle = {
  xSmall: {
    fontSize: 9,
  },
  small: {
    fontSize: 10,
  },
  medium: {
    fontSize: 12,
  },
  large: {
    fontSize: 14,
  },
  xLarge: {
    fontSize: 16,
  },
  xxLarge: {
    fontSize: 24,
  },
  variation: {
    fontSize: 22,
  },
  largeExpanded: {
    fontSize: 21,
  },
  countdown: {
    fontSize: 80,
  },
  input: {
    fontSize: 12,
  },
  label: {
    fontSize: 12,
    fontWeight: 600,
  },
};

const Typography = forwardRef(({ loading, ...rest }, ref) =>
  loading ? <Skeleton animation="wave" width={100} /> : <TypographySpacing ref={ref} {...rest} />,
);

export default Typography;
