import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import { durationWorkout } from 'core/utils/consts';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import TimePickerRounded from 'core/ui/DatePickers/TimePickerRounded';
import DatePickerRounded from 'core/ui/DatePickers/DatePickerRounded';
import { FileCopy, RemoveCircleOutline } from '@material-ui/icons';
import { getAllTimezones, timeZoneName } from 'core/utils/formatters/date';
import Divider from 'core/ui/Divider';
import Radio from 'core/ui/Radio';
import Typography, { fontStyle } from 'core/ui/Typography';
import SelectBoxSearch from 'core/ui/SelectBox/SelectBoxSearch';
import { WorkoutContext } from '../index';
import WeekDaysCheck from './WeekDaysCheck';

const ScheduleOption = ({ index, removeScheduleOption }) => {
  const { t } = useTranslation();
  const formContext = useContext(WorkoutContext);

  const setScheduleOption = (key, value) => {
    const arr = [...formContext.scheduleOptions];

    arr[index][key] = value;
    formContext.setScheduleOptions(arr);
  };

  const cloneOption = (option) => {
    const arr = [...formContext.scheduleOptions, { ...option }];

    formContext.setScheduleOptions(arr);
  };

  return (
    <Box>
      <Box display="flex" flexWrap="wrap" alignItems="flex-end">
        <Box display="flex" mt={1} flexWrap="wrap" alignItems="center">
          {formContext.watch('method') !== 'video' && (
            <>
              {!formContext.workoutRecurringId && (
                <Box m={1}>
                  <DatePickerRounded
                    name="scheduledAt"
                    type="datetime-local"
                    label={t('workout.label.scheduledAtDate').toUpperCase()}
                    onChange={(v) => setScheduleOption('scheduledAtDate', v)}
                    value={formContext.scheduleOptions[index].scheduledAtDate}
                    usFormat={formContext?.selectedChannel?.useUsDateFormat}
                    required
                    disablePast={false}
                  />
                </Box>
              )}
              <Box m={1}>
                <TimePickerRounded
                  name="scheduledAt"
                  type="datetime-local"
                  label={t('workout.label.scheduledAtTime').toUpperCase()}
                  onChange={(v) => setScheduleOption('scheduledAtHour', v)}
                  value={formContext.scheduleOptions[index].scheduledAtHour}
                  required
                />
              </Box>
            </>
          )}

          {formContext.watch('inPerson') && !formContext.watch('online') ? (
            <Box m={1}>
              <TimePickerRounded
                name="scheduledAt"
                type="datetime-local"
                label={t('workout.label.endAtHour').toUpperCase()}
                onChange={(v) => setScheduleOption('endAtHour', v)}
                value={formContext.scheduleOptions[index].endAtHour}
                required
              />
            </Box>
          ) : (
            <Box m={formContext.watch('method') !== 'video' ? 1 : 0} ml={1}>
              <SelectBoxObject
                propValue="id"
                propLabel="name"
                label={t('workout.label.duration').toUpperCase()}
                options={durationWorkout}
                setValue={(v) => setScheduleOption('durationMinutes', v)}
                value={`${formContext.scheduleOptions[index].durationMinutes}`}
                required
              />
            </Box>
          )}

          {formContext.watch('method') !== 'video' && (
            <Box m={1}>
              <SelectBoxSearch
                label={t('workout.label.timezone').toUpperCase()}
                options={getAllTimezones()}
                setValue={(v) => setScheduleOption('timezone', v)}
                value={formContext.scheduleOptions[index].timezone || timeZoneName()}
                required
              />
            </Box>
          )}
        </Box>
        {!formContext.isScheduleSession && formContext.mode !== 'edit' && (
          <>
            <Box m={2} mt={2}>
              <Typography style={fontStyle.label} variant="body1">
                {t('workout.repeat').toUpperCase()}
              </Typography>
              <Box display="flex" mt={1}>
                <Box>
                  <Radio
                    label={t('workout.label.no')}
                    checked={!formContext.scheduleOptions[index].repeat}
                    onClick={() => setScheduleOption('repeat', null)}
                  />
                </Box>
                <Box ml={2}>
                  <Radio
                    label={t('button.yes')}
                    checked={formContext.scheduleOptions[index].repeat === 'weekly'}
                    onClick={() => setScheduleOption('repeat', 'weekly')}
                  />
                </Box>
              </Box>
            </Box>
            {formContext.scheduleOptions[index].repeat && (
              <>
                <Box pb={1}>
                  <WeekDaysCheck
                    setWeekDays={(v) => setScheduleOption('weekDays', v)}
                    weekDays={formContext.scheduleOptions[index].weekDays || []}
                  />
                </Box>
                <Box ml={2} mt={2}>
                  <DatePickerRounded
                    name="stopRecurring"
                    type="datetime-local"
                    label={t('workout.label.repeatLimitDate').toUpperCase()}
                    onChange={(v) => setScheduleOption('stopRecurring', v)}
                    value={formContext.scheduleOptions[index].stopRecurring}
                  />
                </Box>
              </>
            )}
          </>
        )}
        {index > 0 && (
          <Box onClick={() => removeScheduleOption(index)} ml={2} mt={8} className="link">
            <RemoveCircleOutline color="primary" />
          </Box>
        )}

        <Box ml={1} onClick={() => cloneOption(formContext.scheduleOptions[index])}>
          <FileCopy />
        </Box>
      </Box>
      <Divider my={2} mx={5} />
    </Box>
  );
};

export default ScheduleOption;

ScheduleOption.propTypes = {
  index: PropTypes.number,
  removeScheduleOption: PropTypes.func,
};

ScheduleOption.defaultProps = {
  index: 0,
  removeScheduleOption: () => {},
};
