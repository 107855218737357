import api from 'core/api';

export const integrationsModule = 'integrations';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const loadingSignatureStates = {
  start: { loadingSignature: true },
  error: { loadingSignature: false },
  success: { loadingSignature: false },
};

const actions = {
  getIntegrations: {
    module: integrationsModule,
    name: 'getIntegrations',
    api: (params) => api.get(`/integrations`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: ['data'],
    },
    state: loadingStates,
  },
  create: {
    module: integrationsModule,
    name: 'create',
    api: (data) => api.post(`/integrations`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  update: {
    module: integrationsModule,
    name: 'update',
    api: (data) => api.put(`/integrations/${data?.id}`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  cardpointeStep: {
    module: integrationsModule,
    name: 'cardpointeStep',
    api: (data) => api.put(`/integrations/${data?.id}/cardpointe_step`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingSignatureStates,
  },
  destroy: {
    module: integrationsModule,
    name: 'destroy',
    api: (params) => api.delete(`/integrations/${params?.id}`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
};

export default {
  actions,
  state: {
    data: [],
    loading: false,
    loadingSignature: false,
  },
};
