import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { channelsSelectors } from 'modules/channels';
import { formatDateApi, formatDateToApi, currency } from 'core/utils/formatters';
import { timeZoneName } from 'core/utils/formatters/date';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import useSelectors from 'modules/map/useSelectors';
import { newChannelMembersModule } from 'modules/channelMembers/new';

const useFormKey = ({ channelKey, user, changeMemberKey, mode }) => {
  const channel = useSelector((state) => channelsSelectors.getChannel(state));
  const loading = useSelectors(newChannelMembersModule, 'loading');
  const loadingMemberships = useSelectors(newChannelMembersModule, 'loadingMemberships');
  const { request } = useActions();

  const [currentSelected, setCurrentSelected] = useState({});
  const [currentKey, setCurrentKey] = useState({});

  const [currentAmounts, setCurrentAmounts] = useState({});

  const convDate = (value) => {
    if (!value) {
      return null;
    }
    return formatDateApi(value, 'YYYY-MM-DD', timeZoneName());
  };

  const getNextCharge = () => {
    const dateField = currentKey.startAt ? formatDateToApi(currentKey.startAt) : null;
    request({
      action: Modules.newChannelMembers.actions.getNextMembershipCharge,
      data: {
        ...currentKey,
        channelId: channel?.id,
        userId: user?.id,
        startAt: channelKey?.id
          ? formatDateToApi(channelKey?.notStarted ? channelKey?.startAt : channelKey?.expiryAt)
          : dateField,
      },
      options: {
        onSuccess: (data) => {
          setCurrentAmounts(data);
        },
      },
    });
  };

  useEffect(() => {
    if (channelKey) {
      setCurrentKey({
        ...channelKey,

        id: channelKey.id,
        channelMembershipPlanId: channelKey.membershipPlan && channelKey.membershipPlan.id,
        active: channelKey.active,
        renewable: channelKey.renewable,
        startAt: channelKey.startAt,
        pauseAt: channelKey.pauseAt,
        chargeGymAmount: currency(channelKey.chargeGymAmount),
        showGymAmount: channelKey.chargeGymAmount > 0,
        limitClass: channelKey.limitClass,
        paused: !!channelKey.pauseAt,
        channelMembershipCouponId: channelKey.channelMembershipCouponId,

        removeUserAt: convDate(channelKey.removeUserAt),
        removeActiveAt: convDate(channelKey.removeActiveAt),
        removeRenewableAt: convDate(channelKey.removeRenewableAt),
        pauseStartAt: convDate(channelKey.pauseStartAt),
        pauseEndAt: convDate(channelKey.pauseEndAt),
        planExpiryAt: channelKey.planExpiryAt && convDate(channelKey.planExpiryAt),

        contractStartDate: channelKey.contractStartDate && convDate(channelKey.contractStartDate),
        contractEndDate: channelKey.contractEndDate && convDate(channelKey.contractEndDate),

        expiryAt: channelKey.expiryAt && convDate(channelKey.expiryAt),

        inPersonHybridAccess: channelKey.inPersonHybridAccess,

        pauseSchedule: channelKey?.pauseStartAt || channelKey?.pauseEndAt,

        isPostPayment: channelKey.isPostPayment,
      });
    }
  }, [channelKey]);

  useEffect(() => {
    if (mode === 'new') {
      let currentPlan = null;
      if (currentKey.channelMembershipPlanId && channel.membershipAllPlans) {
        currentPlan = channel.membershipAllPlans.find(
          (x) => x.id === Number(currentKey.channelMembershipPlanId),
        );
      }
      setCurrentSelected(currentPlan);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentKey, mode]);

  useEffect(() => {
    if (mode === 'edit') {
      let currentPlan = null;
      if (channelKey.membershipPlan && channelKey.membershipPlan.id && channel.membershipAllPlans) {
        currentPlan = channel.membershipAllPlans.find(
          (x) => x.id === Number(channelKey.membershipPlan && channelKey.membershipPlan.id),
        );
      }
      setCurrentSelected(currentPlan);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentKey, mode]);

  const saveChannelKey = () => {
    const params = {
      ...currentKey,
      startAt: currentKey.startAt ? formatDateToApi(currentKey.startAt) : null,
      removeActiveAt: currentKey.removeActiveAt ? formatDateToApi(currentKey.removeActiveAt) : null,
      removeRenewableAt: currentKey.removeRenewableAt
        ? formatDateToApi(currentKey.removeRenewableAt)
        : null,
      pauseStartAt: currentKey.pauseStartAt ? formatDateToApi(currentKey.pauseStartAt) : null,
      pauseEndAt: currentKey.pauseEndAt ? formatDateToApi(currentKey.pauseEndAt) : null,
      removeUserAt: currentKey.removeUserAt ? formatDateToApi(currentKey.removeUserAt) : null,
      expiryAt: currentKey.expiryAt ? formatDateToApi(currentKey.expiryAt) : null,
      planExpiryAt: currentKey.planExpiryAt ? formatDateToApi(currentKey.planExpiryAt) : null,
      contractStartDate: currentKey.contractStartDate
        ? formatDateToApi(currentKey.contractStartDate)
        : null,
      contractEndDate: currentKey.contractEndDate
        ? formatDateToApi(currentKey.contractEndDate)
        : null,
      userId: user.id,
    };

    if (params.removeRenewableAt) {
      params.renewable = channelKey?.renewable;
    }
    if (params.removeActiveAt) {
      params.active = channelKey?.active;
    }
    if (params.pauseStartAt) {
      params.pauseAt = channelKey?.pauseAt;
    }

    changeMemberKey(params);
  };

  useEffect(() => {
    if (currentKey.channelMembershipPlanId) {
      getNextCharge();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentKey?.startAt,
    currentKey?.offline,
    currentKey?.channelMembershipPlanId,
    currentKey?.channelMembershipCouponId,
    currentKey?.chargeGymAmount,
  ]);

  useEffect(() => {
    if (currentSelected?.contractDates?.contractStartDate && !currentKey?.contractEndDate) {
      setCurrentKey((prev) => ({
        ...prev,
        contractStartDate: convDate(currentSelected?.contractDates?.contractStartDate),
        contractEndDate: convDate(currentSelected?.contractDates?.contractEndDate),
      }));
    }
  }, [currentSelected, currentKey]);

  return {
    channel,
    setCurrentKey,
    currentSelected,
    currentKey,
    currentAmounts,
    loading,
    loadingMemberships,
    saveChannelKey,
  };
};

export default useFormKey;
