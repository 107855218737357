import api from 'core/api';

export const channelsUsersImportModule = 'channelsUsersImport';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const actions = {
  index: {
    module: channelsUsersImportModule,
    name: 'index',
    api: (params) => api.get('channels_users_imports', { params }),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  create: {
    module: channelsUsersImportModule,
    name: 'create',
    api: (body) => api.post('channels_users_imports', body),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
};

export default {
  actions,
  state: {
    loading: false,
  },
};
