import React, { useEffect, useState } from 'react';
import { ButtonPrimary } from 'core/ui/Button';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import Typography, { fontStyle } from 'core/ui/Typography';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { onlineColor, redColor } from 'core/ui/Colors';
import { IconButton } from '@material-ui/core';
import { Add, Edit } from '@material-ui/icons';
import SFDataTable from 'core/ui/DataTable';
import Paper from 'core/ui/Paper';
import Modules from 'modules';
import useActions from 'modules/map/useActions';
import useSelectors from 'modules/map/useSelectors';
import { storeDiscountCodesModule } from 'modules/storeDiscountCodes';
import ModalFormCodes from './Modal';

const StoreDiscountCodes = ({ currency, ownerableId, ownerableType }) => {
  const { t } = useTranslation();
  const { request } = useActions();
  const data = useSelectors(storeDiscountCodesModule, 'data');
  const [modal, setModal] = useState({
    open: false,
  });

  useEffect(() => {
    if (ownerableId) {
      request({
        action: Modules.storeDiscountCodes.actions.getStoreDiscountCodes,
        data: {
          ownerableId,
          ownerableType,
        },
      });
    }
  }, [request, ownerableId, ownerableType]);

  const openModal = () => {
    setModal((prev) => ({ ...prev, mode: 'new', open: true }));
  };

  const onEditClick = (item) => {
    setModal((prev) => ({ ...prev, item, mode: 'edit', open: true }));
  };

  const columns = [
    {
      name: 'Code',
      selector: 'code',
      cell: (row) => <Typography> {row.code}</Typography>,
      sortable: true,
    },
    {
      name: 'Amount',
      selector: 'amount',
      cell: (row) => (
        <Typography textWrap="noWrap">
          {row.kind === 'money' ? currency?.symbol : '%'}
          {row.amount}
        </Typography>
      ),
      sortable: true,
      right: true,
    },
    {
      name: 'Limit',
      selector: 'limitUse',
      cell: (row) => <Typography>{row.limitUse}</Typography>,
      sortable: true,
      right: true,
    },
    {
      name: 'Apply All',
      selector: 'applyAllProducts',
      sortable: true,
      center: true,
      cell: (row) => (
        <>
          {!row.applyAllProducts ? (
            <RadioButtonUncheckedIcon style={{ ...fontStyle.medium, color: redColor }} />
          ) : (
            <CheckCircleOutlineIcon style={{ ...fontStyle.medium, color: onlineColor }} />
          )}
        </>
      ),
    },
    {
      name: 'Active',
      selector: 'active',
      sortable: true,
      right: true,
      cell: (row) => (
        <>
          {!row.active ? (
            <RadioButtonUncheckedIcon style={{ ...fontStyle.medium, color: redColor }} />
          ) : (
            <CheckCircleOutlineIcon style={{ ...fontStyle.medium, color: onlineColor }} />
          )}
        </>
      ),
    },

    {
      sortable: false,
      center: true,
      cell: (row) => (
        <IconButton onClick={() => onEditClick(row)}>
          <Edit />
        </IconButton>
      ),
    },
  ];

  return (
    <Box>
      {modal.open && (
        <ModalFormCodes
          open={modal.open}
          currency={currency}
          item={modal.item}
          mode={modal.mode}
          ownerableId={ownerableId}
          ownerableType={ownerableType}
          close={() => setModal((prev) => ({ ...prev, open: false, item: {} }))}
          callback={(nameList) =>
            setModal((prev) => ({ ...prev, open: false, item: {}, nameListExpand: nameList }))
          }
        />
      )}
      <Paper className="paper-rounded" p={3}>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <Typography variant="h4" color="primary" mr={1}>
              {t('channel.store.discountCodes')}
            </Typography>
          </Box>
          <Box>
            <ButtonPrimary onClick={() => openModal()}>
              <Box display="flex" alignItems="flex-end">
                <Add size="small" />
                <Box alignItems="center">
                  <Typography component="span" mr={1}>
                    {t('channel.button.addDiscountCode')}
                  </Typography>
                </Box>
              </Box>
            </ButtonPrimary>
          </Box>
        </Box>
        <Box mt={2}>
          <SFDataTable noHeader columns={columns} data={data} pagination showExport={false} />
        </Box>
      </Paper>
    </Box>
  );
};

export default StoreDiscountCodes;
