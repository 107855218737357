import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import SelectBox from 'core/ui/SelectBox';
import LimitRegistration from './LimitRegistration';

export default ({ watch, setValue }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Box display="flex" flexWrap="wrap">
        <Box mr={1} my={1} flexGrow="1">
          {watch('method') !== 'video' && (
            <SelectBox
              mbl={2.5}
              placeholder={t('workout.label.maxUsers')}
              label={t('workout.label.maxUsers').toUpperCase()}
              value={watch('maxUsers')}
              setValue={(v) => setValue('maxUsers', v)}
              options={[...new Array(20)].map((x, i) => `${i + 1}`)}
            />
          )}
        </Box>
      </Box>
      <LimitRegistration
        fieldNumber="limitRegistration.number"
        fieldRadio="limitRegistration.hasLimit"
        fieldType="limitRegistration.type"
        watch={watch}
        setValue={setValue}
      />
    </Box>
  );
};
