import React from 'react';
import Box from 'core/ui/Box';
import Grid from 'core/ui/Grid';
import TextAreaFieldRounded from 'core/ui/TextAreaFieldRounded';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import Typography from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import SelectBox from 'core/ui/SelectBox';
import { getAllTimezones, timeZoneName } from 'core/utils/formatters/date';
import TimePickerRounded from 'core/ui/DatePickers/TimePickerRounded';
import DatePickerRounded from 'core/ui/DatePickers/DatePickerRounded';
import CurrencyChosen from 'core/ui/CurrencyChosen';
import InfoChannel from './ChannelSelect';

const CompetitionOverview = ({ formContext }) => {
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="h5" pt={3} mb={3} color="primary">
        {t('competition.title.overview')}
      </Typography>
      <Grid container spacing={5}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box m={1}>
            <Box mb={4}>
              <TextFieldRounded
                name="name"
                error={formContext.errors.name}
                placeholder={t('competition.label.name')}
                label={t('competition.label.name').toUpperCase()}
                inputRef={formContext.register({
                  required: t('competitions.validation.name'),
                })}
                required
              />
            </Box>
            <Box mb={4} mt={5}>
              <TextAreaFieldRounded
                name="description"
                error={formContext.errors.description}
                placeholder={t('competition.placeholder.description')}
                label={t('description').toUpperCase()}
                inputRef={formContext.register({
                  required: t('competition.validation.description'),
                })}
                rows={10}
                required
              />
            </Box>
            <InfoChannel />
            <Box mt={2}>
              <CurrencyChosen
                label={t('channel.label.currency').toUpperCase()}
                setValue={(v, currObj) => {
                  formContext.setValue('currencyId', v);
                  formContext.setCurrency(currObj);
                }}
                defaultValue={
                  formContext?.selectedChannel?.currency?.id || formContext?.watch('currencyId')
                }
                disabled={!!formContext?.selectedChannel}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box display="flex" flexWrap="wrap" alignItems="center">
            <Box m={1}>
              <DatePickerRounded
                name="startAtDate"
                type="datetime-local"
                error={formContext.errors.startAtDate}
                usFormat={formContext?.selectedChannel?.useUsDateFormat}
                label={t('competition.label.startAtDate').toUpperCase()}
                onChange={(v) => formContext.setValue('startAtDate', v)}
                value={formContext.watch('startAtDate')}
                disablePast={false}
                required
              />
            </Box>
            <Box m={1}>
              <TimePickerRounded
                name="startAtTime"
                type="datetime-local"
                error={formContext.errors.startAtTime}
                label={t('competition.label.startAtTime').toUpperCase()}
                onChange={(v) => formContext.setValue('startAtTime', v)}
                value={formContext.watch('startAtTime')}
                required
              />
            </Box>
            <Box m={1}>
              <DatePickerRounded
                name="endAtDate"
                type="datetime-local"
                error={formContext.errors.endAtDate}
                usFormat={formContext?.selectedChannel?.useUsDateFormat}
                label={t('competition.label.endAtDate').toUpperCase()}
                onChange={(v) => formContext.setValue('endAtDate', v)}
                value={formContext.watch('endAtDate')}
                disablePast={false}
                required
              />
            </Box>
            <Box m={1}>
              <TimePickerRounded
                name="endAtTime"
                type="datetime-local"
                error={formContext.errors.endAtTime}
                label={t('competition.label.endAtTime').toUpperCase()}
                onChange={(v) => formContext.setValue('endAtTime', v)}
                value={formContext.watch('endAtTime')}
                required
              />
            </Box>
            <Box m={1}>
              <SelectBox
                propValue="id"
                propLabel="name"
                error={formContext.errors.timezone}
                label={t('competition.label.timezone').toUpperCase()}
                options={getAllTimezones()}
                setValue={(v) => formContext.setValue('timezone', v)}
                value={formContext.watch('timezone') || timeZoneName()}
                required
              />
            </Box>

            <Box m={1}>
              <DatePickerRounded
                name="registrationCloseDate"
                type="datetime-local"
                error={formContext.errors.registrationCloseDate}
                usFormat={formContext?.selectedChannel?.useUsDateFormat}
                label={t('competition.label.registrationCloseDate').toUpperCase()}
                onChange={(v) => formContext.setValue('registrationCloseDate', v)}
                value={formContext.watch('registrationCloseDate')}
                disablePast={false}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

CompetitionOverview.defaultProps = {};

CompetitionOverview.propTypes = {};

export default CompetitionOverview;
