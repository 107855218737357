import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import { makeStyles, Slide } from '@material-ui/core';
import { Scrollbars } from 'react-custom-scrollbars';
import Box from 'core/ui/Box';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import Texture from 'core/ui/Texture';
import { date, dateAdd } from 'core/utils/formatters';
import useSelectors from 'modules/map/useSelectors';
import { partnersProgramsChannelsModule } from 'modules/partnersProgramsChannels';
import { ButtonPrimary } from 'core/ui/Button';
import LoaderSm from 'core/ui/LoaderSm';
import Checkbox from 'core/ui/Checkbox';
import Paper from 'core/ui/Paper';
import { fontStyle } from 'core/ui/Typography';
import TypographyIcon from 'core/ui/TypographyIcon';
import MIcon from 'core/ui/MIcon';
import Divider from 'core/ui/Divider';
import Backdrop from 'core/ui/Backdrop';
import ProgramView from './ProgramView';
import ModalMembershipsBlocks from '../../Programming/PartnersPrograms/ModalMembershipsBlocks';
import CalendarContextProvider from './CalendarContextProvider';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const ModalCalendar = ({ open, channel, programs, close, callback, calendarInfo }) => {
  const { t } = useTranslation();
  const { request } = useActions();
  const classes = useStyles();
  const loading = useSelectors(partnersProgramsChannelsModule, 'loading');
  const workouts = useSelectors(partnersProgramsChannelsModule, 'workouts');
  const [modalProgram, setModalProgram] = useState({ open: false });
  const [selectedPrograms, setSelectedPrograms] = useState([]);

  const titleModal = () =>
    ` ${date(calendarInfo?.start, channel?.dateFormatReact)} - ${date(
      dateAdd(calendarInfo?.end, -1, 'days'),
      channel?.dateFormatReact,
    )}`;

  const buttonColor = channel?.customColors?.buttonColor;

  const getWorkouts = () => {
    request({
      action: Modules.partnersProgramsChannels.actions.getPartnersProgramsChannelWorkouts,
      data: {
        channelId: channel?.id,
        partnersProgramIds: selectedPrograms,
        startAt: calendarInfo?.start,
        endAt: calendarInfo?.end,
      },
    });
  };

  useEffect(() => {
    if (selectedPrograms?.length > 0) {
      getWorkouts();
    } else {
      request({
        action: Modules.partnersProgramsChannels.actions.clearWorkouts,
        data: [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPrograms]);

  const callbackSave = () => {
    callback();
  };

  const closeModal = () => {
    close();
  };

  const toggleProgram = (program, root) => {
    if (root && program.parentPrograms?.length > 0) {
      const isChecked = !!selectedPrograms.find((x) => x === program.parentPrograms[0]?.id);

      if (isChecked) {
        setSelectedPrograms((prev) =>
          prev.filter((x) => !program.parentPrograms.find((y) => y.id === x)),
        );
      } else {
        setSelectedPrograms((prev) => [...prev, ...program.parentPrograms.map((x) => x.id)]);
      }
    } else if (selectedPrograms.find((x) => x === program?.id)) {
      setSelectedPrograms((prev) => prev.filter((x) => x !== program?.id));
    } else {
      setSelectedPrograms((prev) => [...prev, program?.id]);
    }
  };

  const isSelectedAll = (program) => {
    let ret = true;

    if (program.parentPrograms?.length === 0) {
      return !!selectedPrograms.find((x) => x === program?.id);
    }

    program.parentPrograms.forEach((p) => {
      if (!selectedPrograms.find((x) => x === p?.id)) {
        ret = false;
      }
    });
    return ret;
  };

  return (
    <Dialog
      style={{ marginTop: 100 }}
      fullScreen
      open={open}
      onClose={closeModal}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar} color="secondary">
        <Toolbar>
          <TypographyIcon
            className={classes.title}
            text={`Partners Programs ${titleModal()}`}
            icon={<MIcon icon="fitness_center" color="white" size={20} />}
          />
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => callbackSave()}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <CalendarContextProvider
        channel={channel}
        programs={programs}
        callback={callback}
        close={close}
      >
        {loading && <Backdrop loading={loading} />}
        <div className="tw-flex-1">
          <ModalMembershipsBlocks
            open={modalProgram?.open}
            channel={channel}
            program={modalProgram.program}
            close={() => setModalProgram({ open: false })}
          />

          <Scrollbars allowFullScreen>
            <Texture style={{ minHeight: '100%' }}>
              <Box p={5}>
                <Box display="flex" alignItems="flex-start" mb={2}>
                  {programs?.map((program) => (
                    <Paper p={2} m={1} key={program?.id} className="hover">
                      <Checkbox
                        label={program.name}
                        checked={isSelectedAll(program)}
                        onClick={() => toggleProgram(program, true)}
                      />
                      {program?.parentPrograms?.map((sub, idx) => (
                        <Box key={sub?.id}>
                          {idx === 0 && <Divider m={1} ml={7} />}
                          <Box mx={1} className="hover">
                            <Checkbox
                              label={
                                <Typography color="secondary" style={fontStyle.medium}>
                                  {sub.name}
                                </Typography>
                              }
                              checked={selectedPrograms?.find((x) => x === sub?.id)}
                              onClick={() => toggleProgram(sub, false)}
                            />
                          </Box>
                          {program.parentPrograms.length - 1 > idx && <Divider m={1} ml={7} />}
                        </Box>
                      ))}
                    </Paper>
                  ))}
                </Box>
                <ProgramView
                  channel={channel}
                  calendarInfo={calendarInfo}
                  workouts={workouts}
                  buttonColor={buttonColor}
                  setModalProgram={setModalProgram}
                />
              </Box>
              {loading && (
                <Box display="flex" justifyContent="center" mb={3}>
                  <LoaderSm loading />
                </Box>
              )}

              <Box display="flex" justifyContent="flex-end" p={5}>
                <ButtonPrimary onClick={() => callbackSave()}>{t('button.close')}</ButtonPrimary>
              </Box>
            </Texture>
          </Scrollbars>
        </div>
      </CalendarContextProvider>
    </Dialog>
  );
};

ModalCalendar.propTypes = {};

ModalCalendar.defaultProps = {
  close: () => {},
};

export default ModalCalendar;
