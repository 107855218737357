import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import Texture from 'core/ui/Texture';
import Typography from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import FormList from './FormList';

const ModalCreateList = ({ close, list }) => {
  const { t } = useTranslation();
  return (
    <Dialog open onClose={close} maxWidth="sm" fullWidth scroll="body">
      <Texture>
        <Box py={3}>
          <Typography align="center" variant="h4" color="primary">
            {t('admin.messageLists')}
          </Typography>
          <FormList close={close} list={list} />
        </Box>
      </Texture>
    </Dialog>
  );
};

ModalCreateList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  list: PropTypes.object,
  close: PropTypes.func,
};

ModalCreateList.defaultProps = {
  close: () => {},
  list: {},
};

export default ModalCreateList;
