import React from 'react';
import { secondary } from '../Colors';

const LibraryIcon = ({ size, color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    id="Layer_1"
    width={size}
    height={size}
    x="0px"
    y="0px"
    viewBox="0 0 546 558"
    xmlSpace="preserve"
  >
    <g>
      <path
        d="M523,485.5v42.4c0,2.3-1.8,4.1-4.1,4.1H23.1c-2.3,0-4.1-1.8-4.1-4.1v-42.4c0-2.3,1.8-4.1,4.1-4.1h495.8   C521.2,481.5,523,483.3,523,485.5z M192.5,38.7c0-2.8-1.1-5.6-3.1-7.6s-4.7-3.1-7.6-3.1h-76.7c-5.9,0-10.7,4.8-10.7,10.7v46.4   l98.1,0L192.5,38.7z M94.4,446.4c0,5.9,4.8,10.7,10.7,10.7h76.7c2.8,0,5.6-1.1,7.6-3.1c2-2,3.1-4.7,3.1-7.6v-46.4H94.4L94.4,446.4z    M94.4,373.8h98.1V111.3H94.4V373.8z M320.1,38.7c0-5.9-4.8-10.7-10.7-10.7h-76.7c-5.9,0-10.7,4.8-10.7,10.7v46.4l98.1,0   L320.1,38.7z M221.9,446.4c0,2.8,1.1,5.6,3.1,7.6s4.7,3.1,7.6,3.1h76.7c2.8,0,5.6-1.1,7.6-3.1c2-2,3.1-4.7,3.1-7.6v-46.4h-98.1   L221.9,446.4z M221.9,373.8h98.1V111.4h-98.1V373.8z M447.6,117.5c0-5.9-4.8-10.7-10.7-10.7h-76.7c-2.8,0-5.6,1.1-7.6,3.1   c-2,2-3.1,4.7-3.1,7.6v46.4l98.1,0L447.6,117.5z M349.5,446.4c0,2.8,1.1,5.6,3.1,7.6c2,2,4.7,3.1,7.6,3.1h76.7   c5.9,0,10.7-4.8,10.7-10.7v-46.4h-98.1L349.5,446.4z M349.5,373.8h98.1V190.1h-98.1V373.8z"
        fill={color}
      />
    </g>
  </svg>
);

export default LibraryIcon;

LibraryIcon.defaultProps = {
  icon: null,
  color: secondary,
  size: 20,
  styles: {},
};
