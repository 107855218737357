import api from 'core/api';

export const ownersPaymentsFeesModule = 'ownersPaymentsFees';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};
const loadingStatusStates = {
  start: { loadingStatus: true },
  error: { loadingStatus: false },
  success: { loadingStatus: false },
};

const actions = {
  save: {
    module: ownersPaymentsFeesModule,
    name: 'save',
    api: (data) => api.post(`/admin/owners_payments_fees`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  checkCardpointeStatus: {
    module: ownersPaymentsFeesModule,
    name: 'checkCardpointeStatus',
    api: (params) => api.get(`/admin/owners_payments_fees/check_cardpointe_status`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStatusStates,
  },
};

export default {
  actions,
  state: {
    loading: false,
    data: [],
    setupIntent: null,
  },
};
