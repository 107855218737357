import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import Paper from 'core/ui/Paper';
import Typography from 'core/ui/Typography';
import { ButtonPrimary } from 'core/ui/Button';
import { useSelector, useDispatch } from 'react-redux';
import DialogTitle from 'core/ui/Dialog/DialogTitle';
import LoaderSm from 'core/ui/LoaderSm';
import Texture from 'core/ui/Texture';
import SelectorPaymentMethod, { PaymentMethodViewType } from 'components/Account/Selector';
import { timeZoneName } from 'core/utils/formatters/date';
import { NavigateBefore, Warning } from '@material-ui/icons';
import {
  DialogContent,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import { sessionSelectors } from 'modules/session';
import notifications from 'modules/notifications';
import { primary } from 'core/ui/Colors';
import Checkbox from 'core/ui/Checkbox';
import Radio from 'core/ui/Radio';
import Modules from 'modules';
import useActions from 'modules/map/useActions';
import useSelectors from 'modules/map/useSelectors';
import { storeDiscountCodesModule } from 'modules/storeDiscountCodes';
import { channelCurrency } from 'core/utils/formatters';
import Form from 'components/Login/Form';
import QuickForm from 'components/Profile/QuickForm';
import Divider from 'core/ui/Divider';
import Grid from 'core/ui/Grid';
import { newPaymentsModule } from 'modules/payments/new';
import DeliveryAddress from '../DeliveryAddress';
import MemberSell from '../MemberSell';
import ShoppingCartItems from './CartItems';
import CouponCode from './CouponCode';
import PurchaseResume from './PurchaseResume';

export default function ModalStoreCheckout({
  channel,
  isCoach,
  open,
  close,
  callback,
  buttonColor,
  shoppingCart,
}) {
  const { t } = useTranslation();
  const { request } = useActions();
  const loadingStorePurchase = useSelectors(newPaymentsModule, 'loadingStorePurchase');
  const isAuthenticated = useSelector((state) => sessionSelectors.getIsAuthenticated(state));
  const currentUser = useSelector((state) => sessionSelectors.getUser(state));
  const loadingDiscountCode = useSelectors(storeDiscountCodesModule, 'loading');
  const {
    address,
    setAddress,
    coupon,
    setCoupon,
    offlinePayment,
    setOfflinePayment,
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    previewOrder,
  } = shoppingCart;
  const [holdTransaction, setHoldTransaction] = useState(false);
  const dispatch = useDispatch();
  const [textCoupon, setTextCoupon] = useState('');
  const [errors, setErrors] = useState([]);
  const [member, setMember] = useState(null);

  const [viewMethodPayment, setViewMethodPayment] = useState(PaymentMethodViewType.list);

  useEffect(() => {
    request({
      action: Modules.newChannels.actions.memberHasActiveMembership,
      data: {
        channelId: channel?.id,
        userId: member?.id || currentUser?.id,
      },
      options: {
        onSuccess: (resp) => {
          setHoldTransaction(
            resp?.active && !!shoppingCart?.cartItems.find((x) => x?.product?.holdTransaction),
          );
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCoach, member, currentUser]);

  const validate = () => {
    const vErrors = [];
    if (!holdTransaction && !offlinePayment.isOffline && !selectedPaymentMethod) {
      vErrors.push(t('channel.modalPayment.validation.selectAccount'));
    }

    if (!previewOrder.preSale && address.delivery && !address.countryId) {
      vErrors.push(t('channel.store.validation.selectTheCountry'));
    }

    if (!previewOrder.preSale && address.delivery && !address.street) {
      vErrors.push(t('channel.store.validation.street'));
    }

    if (!previewOrder.preSale && address.delivery && !address.state) {
      vErrors.push(t('channel.store.validation.state'));
    }

    if (isCoach && !member) {
      vErrors.push(t('channel.store.validation.member'));
    }

    if (vErrors.length > 0) {
      setErrors(vErrors);
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (isCoach) {
      setSelectedPaymentMethod(null);
    }
  }, [member, isCoach, setSelectedPaymentMethod]);

  const purchase = () => {
    if (!validate()) {
      return;
    }

    request({
      action: Modules.newPayments.actions.storePurchase,
      data: {
        userPaymentMethodId: selectedPaymentMethod?.id,
        memberId: member && member.id,
        timezone: timeZoneName(),
        ...shoppingCart.buildOrderParams({ isCoach }),
      },
      options: {
        onSuccess: (resp) => {
          if (resp.payments) {
            shoppingCart.successPurchase(resp);
          }

          if (callback) {
            callback();
          }
        },
        onError: (error) => {
          dispatch(notifications.error(error));
        },
      },
    });
  };

  const applyCode = () => {
    if (!textCoupon) {
      return;
    }

    const storeProductId = shoppingCart?.cartItems[0]?.product?.id;
    const ownerableType = shoppingCart?.cartItems[0]?.product?.ownerableType;
    const ownerableId = shoppingCart?.cartItems[0]?.product?.ownerableId;

    request({
      action: Modules.storeDiscountCodes.actions.getDiscount,
      data: {
        storeProductId,
        ownerableId,
        ownerableType,
        code: textCoupon,
      },
      options: {
        onSuccess: (data) => {
          if (data) {
            setCoupon({
              code: textCoupon,
              newPrice: data.newPrice,
            });
          } else {
            setCoupon(null);
          }
        },
        onError: (error) => {
          dispatch(notifications.error(error));
        },
      },
    });
  };

  const clearCoupon = () => {
    setTextCoupon('');
    setCoupon(null);
  };

  useEffect(() => {
    if (offlinePayment.isOffline) {
      setViewMethodPayment(PaymentMethodViewType.list);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!offlinePayment.isOffline]);

  useEffect(() => {
    if (!offlinePayment.isOffline && member && member.guest) {
      setViewMethodPayment(PaymentMethodViewType.form);
    }
  }, [member, offlinePayment.isOffline]);

  useEffect(() => {
    if (
      shoppingCart?.cartItems?.find(
        (item) => item?.product?.ownerableType === 'Partner' || item?.delivery === true,
      )
    ) {
      setAddress((prev) => ({ ...prev, delivery: true }));
    } else {
      setAddress((prev) => ({ ...prev, delivery: false }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shoppingCart?.cartItems]);

  const isFromPartner = shoppingCart?.cartItems?.find(
    (product) => product?.ownerableType === 'Partner',
  );

  const showDeliveryAddress =
    !previewOrder.preSale && (!isCoach || (member && member.id)) && address?.delivery;

  if (!isAuthenticated) {
    return (
      <Dialog open={open} maxWidth="sm" fullWidth>
        <Texture>
          <Box p={10}>
            <Form fromModal channelId={channel?.id} />
          </Box>
        </Texture>
      </Dialog>
    );
  }

  if (isAuthenticated && !currentUser?.name) {
    return (
      <Dialog open={open} maxWidth="sm" fullWidth>
        <Texture>
          <Box p={10}>
            <QuickForm buttonColor={buttonColor} />
          </Box>
        </Texture>
      </Dialog>
    );
  }

  const StoreOption = ({ checked, label, onClick, options }) => {
    const labelId = 'item';
    return (
      <ListItem role={undefined} dense button onClick={onClick}>
        <ListItemText id={labelId} primary={label} />
        <ListItemSecondaryAction>
          {options || (
            <Checkbox
              checked={checked}
              tabIndex={-1}
              onClick={onClick}
              color={buttonColor}
              disableRipple
              inputProps={{ 'aria-labelledby': labelId }}
            />
          )}
        </ListItemSecondaryAction>
      </ListItem>
    );
  };

  const ItemRow = ({ value, label, noBorder = false }) => {
    const style = noBorder ? { border: 'none' } : {};
    return (
      <TableRow>
        <TableCell style={style}>
          <Typography variant="h6" align="center" color="secondary">
            {label}
          </Typography>
        </TableCell>
        <TableCell style={style}>
          <Typography variant="h6" align="center" style={{ color: buttonColor }}>
            {shoppingCart?.loadingPreview ? <LoaderSm loading center /> : <>{value}</>}
          </Typography>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Dialog scroll="body" fullWidth maxWidth="md" onClose={() => close()} open={open}>
      <Texture>
        <DialogTitle onClose={() => close()}>
          {t('channel.modalPayment.paymentInformation')}
        </DialogTitle>
        <DialogContent dividers>
          {shoppingCart?.purchaseResume?.showSuccess ? (
            <PurchaseResume shoppingCart={shoppingCart} close={close} />
          ) : (
            <>
              {isCoach && (
                <Paper className="paper-rounded" mb={2}>
                  <List>
                    {isCoach && channel.guestUser && (
                      <>
                        <StoreOption
                          label={t('store.guestPurchase')}
                          onClick={() => setMember(member ? null : channel.guestUser)}
                          checked={member?.guest}
                        />
                        <Divider m={1} />
                      </>
                    )}

                    {!isFromPartner && isCoach && (
                      <>
                        {(!selectedPaymentMethod || (member && !member.guest)) && (
                          <StoreOption
                            label={t('store.offlinePurchase')}
                            onClick={() =>
                              setOfflinePayment((prev) => ({
                                ...prev,
                                isOffline: !prev.isOffline,
                              }))
                            }
                            checked={offlinePayment.isOffline}
                          />
                        )}
                        {offlinePayment.isOffline && (
                          <>
                            <Divider m={1} />
                            <StoreOption
                              label={t(
                                'channel.placeholder.offlinePayments.offlineType',
                              ).toUpperCase()}
                              onClick={() =>
                                setOfflinePayment((prev) => ({
                                  ...prev,
                                  isOffline: !prev.isOffline,
                                }))
                              }
                              options={
                                <Box display="flex">
                                  <Radio
                                    checked={offlinePayment.type === 'cash'}
                                    label={t('channel.placeholder.offlinePayments.cash')}
                                    onClick={() =>
                                      setOfflinePayment((prev) => ({ ...prev, type: 'cash' }))
                                    }
                                    color={buttonColor}
                                  />
                                  <Box ml={3}>
                                    <Radio
                                      checked={offlinePayment.type === 'check'}
                                      label={t('channel.placeholder.offlinePayments.check')}
                                      onClick={() =>
                                        setOfflinePayment((prev) => ({ ...prev, type: 'check' }))
                                      }
                                      color={buttonColor}
                                    />
                                  </Box>
                                </Box>
                              }
                            />
                          </>
                        )}
                      </>
                    )}
                  </List>
                </Paper>
              )}
              <Box mb={2}>
                <ShoppingCartItems
                  buttonColor={buttonColor}
                  shoppingCart={shoppingCart}
                  channel={channel}
                />
              </Box>

              <Grid container spacing={2} mb={2}>
                {isCoach && channel.guestUser && (!member || (member && !member.guest)) && (
                  <Grid item xs={12} md={6}>
                    <MemberSell
                      buttonColor={buttonColor}
                      setMember={setMember}
                      member={member}
                      channelId={channel.id}
                    />

                    {isCoach && showDeliveryAddress && (
                      <Box mt={2}>
                        <CouponCode
                          textCoupon={textCoupon}
                          setTextCoupon={setTextCoupon}
                          coupon={coupon}
                          buttonColor={buttonColor}
                          clearCoupon={clearCoupon}
                          applyCode={applyCode}
                          loadingDiscountCode={loadingDiscountCode}
                        />
                      </Box>
                    )}
                  </Grid>
                )}
                {showDeliveryAddress && (
                  <Grid item xs={12} md={6}>
                    <DeliveryAddress
                      currency={channel?.currency}
                      userId={isCoach ? member && member.id : currentUser.id}
                      address={address}
                      setAddress={setAddress}
                      buttonColor={buttonColor}
                      channelId={channel?.id}
                    />
                  </Grid>
                )}
                {!(isCoach && showDeliveryAddress) && (
                  <Grid item xs={12} md={6}>
                    <CouponCode
                      textCoupon={textCoupon}
                      setTextCoupon={setTextCoupon}
                      coupon={coupon}
                      buttonColor={buttonColor}
                      clearCoupon={clearCoupon}
                      applyCode={applyCode}
                      loadingDiscountCode={loadingDiscountCode}
                    />
                  </Grid>
                )}
              </Grid>

              {!holdTransaction && (!isCoach || !!member) && !offlinePayment.isOffline && (
                <Paper p={3} mb={2}>
                  {viewMethodPayment === PaymentMethodViewType.form && (
                    <Box display="flex" alignItems="center">
                      {(!member || !member.guest) && (
                        <Box>
                          <IconButton
                            onClick={() => setViewMethodPayment(PaymentMethodViewType.list)}
                          >
                            <NavigateBefore fontSize="large" />
                          </IconButton>
                        </Box>
                      )}
                      <Box display="flex" flexGrow={1} justifyContent="center">
                        <Typography
                          mt={3}
                          mb={3}
                          align="center"
                          style={{ color: buttonColor }}
                          variant="h4"
                        >
                          {t(
                            member && member.guest
                              ? 'payment.method.guest.new'
                              : 'payment.method.save',
                          )}
                        </Typography>
                      </Box>
                    </Box>
                  )}

                  {previewOrder && (
                    <SelectorPaymentMethod
                      selectedPaymentMethod={selectedPaymentMethod}
                      setSelectedPaymentMethod={setSelectedPaymentMethod}
                      view={viewMethodPayment}
                      setView={setViewMethodPayment}
                      onlyMethod={channel?.allowAchPayments ? null : 'card'}
                      userId={isCoach ? member.id : currentUser.id}
                      guest={member && member.guest}
                      userInfo={{
                        name: isCoach ? member?.name : currentUser?.name,
                        phone: isCoach ? member?.phone : currentUser?.phone,
                        email: isCoach ? member?.email : currentUser?.email,
                      }}
                      bordered
                      cardpointe={previewOrder?.fromPartner ? false : channel.useCardpointe}
                      showMessageToAddStripePaymentMethod={channel.useCardpointe}
                    />
                  )}
                </Paper>
              )}

              {errors.length > 0 && (
                <Paper className="paper-rounded" p={3} mb={2}>
                  <Box display="flex" flexDirection="column" justifyContent="center">
                    {errors.map((error, index) => (
                      <Typography
                        key={index.toString()}
                        style={{ color: buttonColor }}
                        variant="body2"
                        mb={1}
                      >
                        <Warning style={{ fontSize: 16, color: primary }} /> {error}
                      </Typography>
                    ))}
                  </Box>
                </Paper>
              )}

              {viewMethodPayment !== PaymentMethodViewType.form && (
                <Box mb={2}>
                  <Paper className="paper-rounded" mx={15} p={3} mb={3}>
                    <Table>
                      <TableBody>
                        <ItemRow
                          value={channelCurrency(
                            shoppingCart?.previewOrder?.totalProducts,
                            channel?.currency,
                          )}
                          label={t('store.summary.subTotal')}
                        />
                        <ItemRow
                          value={channelCurrency(
                            shoppingCart?.previewOrder?.totalSalesTax,
                            channel?.currency,
                          )}
                          label={t('store.summary.tax')}
                        />
                        {!!shoppingCart?.cartItems?.find((x) => x.delivery) && (
                          <ItemRow
                            value={channelCurrency(
                              shoppingCart?.previewOrder?.totalDelivery,
                              channel?.currency,
                            )}
                            label={t('store.summary.delivery')}
                          />
                        )}
                        {shoppingCart?.previewOrder?.totalDiscount > 0 && (
                          <ItemRow
                            value={`-${channelCurrency(
                              shoppingCart?.previewOrder?.totalDiscount,
                              channel?.currency,
                            )}`}
                            label={t('store.summary.discount')}
                          />
                        )}
                        <ItemRow
                          value={channelCurrency(
                            shoppingCart?.previewOrder?.total,
                            channel?.currency,
                          )}
                          label={t('store.summary.total')}
                          noBorder
                        />
                      </TableBody>
                    </Table>

                    {/* <div >
                      TEMP VALUES
                      <Typography>
                        Gym:&nbsp;
                        {channelCurrency(
                          shoppingCart?.previewOrder?.totalChannel,
                          channel?.currency,
                        )}
                      </Typography>
                      <Typography>
                        Partner:&nbsp;
                        {channelCurrency(
                          shoppingCart?.previewOrder?.totalPartner,
                          channel?.currency,
                        )}
                      </Typography>
                      <Typography>
                        Stream:&nbsp;
                        {channelCurrency(
                          shoppingCart?.previewOrder?.totalStream,
                          channel?.currency,
                        )}
                      </Typography>
                      <Typography>
                        Consumer Discount:&nbsp;
                        {channelCurrency(
                          shoppingCart?.previewOrder?.totalCustomer,
                          channel?.currency,
                        )}
                      </Typography>
                      <Typography>
                        Discount:&nbsp;
                        {channelCurrency(
                          shoppingCart?.previewOrder?.totalDiscount,
                          channel?.currency,
                        )}
                      </Typography>
                      <Typography>
                        Delivery:&nbsp;
                        {channelCurrency(
                          shoppingCart?.previewOrder?.totalDelivery,
                          channel?.currency,
                        )}
                      </Typography>
                      <Typography>
                        Sales Tax:&nbsp;
                        {channelCurrency(
                          shoppingCart?.previewOrder?.totalSalesTax,
                          channel?.currency,
                        )}
                      </Typography>
                    </div> */}
                  </Paper>

                  <ButtonPrimary
                    disabled={!shoppingCart?.validItems || loadingStorePurchase}
                    onClick={() => purchase()}
                    type="submit"
                    fullWidth
                    buttonColor={buttonColor}
                  >
                    <Box display="flex" alignItems="center">
                      <LoaderSm loading={loadingStorePurchase} width={20} />
                      <Typography ml={1} component="span">
                        {t('purchase')}
                      </Typography>
                    </Box>
                  </ButtonPrimary>
                </Box>
              )}
            </>
          )}
        </DialogContent>
      </Texture>
    </Dialog>
  );
}

ModalStoreCheckout.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  channel: propTypes.object.isRequired,
  open: propTypes.bool,
  close: propTypes.func,
  callback: propTypes.func,
};

ModalStoreCheckout.defaultProps = {
  open: false,
  close: null,
  callback: () => {},
};
