import { createSelector } from 'reselect';
import Modules from '../index';

const selectors = {};

Object.keys(Modules).forEach((module) => {
  const getState = (state) => state[module];

  selectors[module] = { state: createSelector(getState, (state) => state) };
});

export default selectors;
