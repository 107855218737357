import { createSelector } from 'reselect';

const getState = (state) => state.room;

export const getLoading = createSelector(getState, (state) => state.loading);
export const getUser = createSelector(getState, (state) => state.user);
export const getSession = createSelector(getState, (state) => state.session);
export const getParticipants = createSelector(getState, (state) => state.participants);

export const getPlayingRecordings = createSelector(getState, (state) => state.playingRecordings);
export const getMutedRecordings = createSelector(getState, (state) => state.mutedRecordings);
export const getSeekVideo = createSelector(getState, (state) => state.seekVideo);

export const getParticipantFullScreen = createSelector(
  getState,
  (state) => state.participantFullScreen,
);
export const getMessages = createSelector(getState, (state) => state.messages);

export const getMessagesText = createSelector(getState, (state) => {
  const currentUser = state.user;
  const mgs = state.messages.filter(
    (m) => m.from.id === currentUser.id || !m.to || !m.to.id || m.to.id === currentUser.id,
  );
  return mgs.filter((x) => !x.audio.url);
});

export const getMessagesAudio = createSelector(getState, (state) => {
  const currentUser = state.user;
  const mgs = state.messages.filter(
    (m) => m.from.id === currentUser.id || !m.to || !m.to.id || m.to.id === currentUser.id,
  );
  return mgs.filter((x) => x.audio !== null && x.audio.url);
});

export const getConfig = createSelector(getState, (state) => state.config);
export const getConnectionQuality = createSelector(getState, (state) => state.connectionQuality);
export const getError = createSelector(getState, (state) => state.error);

export const getSignalStream = createSelector(getState, (state) => state.signalStream);
export const getFocusedUserId = createSelector(getState, (state) => state.focusedUserId);
