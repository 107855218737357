/* eslint-disable no-underscore-dangle */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form-v5';
import { useDispatch } from 'react-redux';
import notifications from 'modules/notifications';
import useSelectors from 'modules/map/useSelectors';
import { documentsModule } from 'modules/documents';
import useActions from 'modules/map/useActions';
import Modules from 'modules';

export default ({ mode, documentId, callback, ownerableId, ownerableType }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [sections, setSections] = useState([]);
  const loading = useSelectors(documentsModule, 'loading');
  const document = useSelectors(documentsModule, 'document');
  const { request } = useActions();
  const { register, handleSubmit, setValue, watch, reset, errors } = useForm({
    defaultValues: { pdf: false },
  });

  useEffect(() => {
    if (documentId) {
      request({
        action: Modules.documents.actions.getDocument,
        data: {
          ownerableId,
          ownerableType,
          id: documentId,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentId]);

  const onSubmit = (values) => {
    const data = {
      ...values,
      sectionsAttributes: getSectionsAttributesToSubmit(sections),
      id: document?.id,
      ownerableId,
      ownerableType,
    };

    request({
      action: mode === 'new' ? Modules.documents.actions.create : Modules.documents.actions.update,
      data,
      options: {
        onSuccess: () => {
          if (callback) {
            callback(values.name);
          }
          dispatch(notifications.success(t('message.success.save.document')));
        },
        onError: (error) => {
          dispatch(notifications.error(error));
        },
      },
    });
  };

  useEffect(() => {
    register('name', { required: t('form.validate.name') });
    register('description');
    register('kind');
    register('pdf');

    register('createCustom');

    if (mode === 'new') {
      setValue('active', true);
      setValue('default', false);
    }

    if (ownerableType === 'Competition') {
      setValue('kind', 'waiver');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register, ownerableType]);

  useEffect(() => {
    if (documentId && document) {
      reset(document);

      setSections(document?.sections || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentId, document]);

  const addSection = (params = {}) => {
    setSections((prev) => [...prev, params]);
  };

  const changeSection = (section, field, value) => {
    setSections((prev) =>
      prev.map((item) => {
        if (item === section) {
          const newItem = { ...item };
          newItem[field] = value;
          return newItem;
        }
        return item;
      }),
    );
  };

  const removeSection = (s) => {
    if (s?.id) {
      changeSection(s, '_destroy', true);
    } else {
      setSections((prev) => prev.filter((x) => x !== s));
    }
  };

  const canEditSections = !watch('pdf');

  return {
    sections,
    setSections,
    register,
    setValue,
    watch,
    reset,
    errors,
    onSubmit,
    handleSubmit,
    loading,
    addSection,
    changeSection,
    removeSection,
    document,
    canEditSections,
  };
};

const getSectionsAttributesToSubmit = (sections) => {
  let i = -1;

  return sections.map((section) => {
    if (section._destroy) return section;

    i += 1;

    return { ...section, showOrder: i };
  });
};
