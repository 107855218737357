import api from 'core/api';
import { call, put } from 'redux-saga/effects';

export const competitionsInvitesModule = 'competitionsInvites';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const actions = {
  saveInvites: {
    module: competitionsInvitesModule,
    name: 'saveInvites',
    api: (data) => api.post(`/competitions/${data?.competitionId}/competitions_invites`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  deleteInvite: {
    module: competitionsInvitesModule,
    name: 'deleteInvite',
    api: (data) =>
      api.delete(`/competitions/${data?.competitionId}/competitions_invites/${data?.id}`),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  getCompetitionsInvites: {
    module: competitionsInvitesModule,
    name: 'getCompetitionsInvites',
    api: (params) =>
      api.get(`/competitions/${params?.competitionId}/competitions_invites`, { params }),
    *sagas(Creators, { params, options }) {
      try {
        const resp = yield call(actions.getCompetitionsInvites.api, params);
        yield put(Creators.getCompetitionsInvitesSuccess());
        if (options.onSuccess) {
          options.onSuccess(resp.data, resp.pagination);
        }
      } catch (error) {
        if (options.onError) {
          options.onError(error);
        }
        yield put(Creators.getCompetitionsInvitesError());
      }
    },
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
};

export default {
  actions,
  state: {
    error: null,
    loading: false,
  },
};
