import React, { useState } from 'react';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import { ButtonPrimary } from 'core/ui/Button';
import ModalFormMessage from 'components/Commmunication/Form/Modal';
import Messages from 'components/Commmunication/Messages';
import Typography from 'core/ui/Typography';

const messagePersistKey = 'message-persist-communitation-competition';

const CompetitionMessages = ({ competition }) => {
  const { t } = useTranslation();
  const [modalMessage, setModalMessage] = useState({ open: false });
  const editMessage = (message) => {
    setModalMessage({ open: true, message });
  };

  const copyMessage = (message) => {
    setModalMessage({ open: true, message: { ...message, id: null } });
  };

  return (
    <Box p={3}>
      <Box display="flex" justifyContent="flex-end" m={3}>
        <Box flexGrow="1" display="flex" alignItems="center">
          <Typography variant="h5" color="primary">
            {t('label.messages')}
          </Typography>
        </Box>
        <ButtonPrimary onClick={() => setModalMessage({ open: true })}>
          {t('button.sendNewMessage')}
        </ButtonPrimary>
      </Box>

      <Box my={3}>
        {modalMessage?.open && (
          <ModalFormMessage
            open={modalMessage?.open}
            close={() => setModalMessage({ open: false })}
            ownerableId={competition?.id}
            ownerableType="Competition"
            message={modalMessage?.message}
            persistKey={messagePersistKey}
            callback={() => {
              setModalMessage({ open: false });
            }}
          />
        )}
        <Messages
          ownerableId={competition?.id}
          ownerableType="Competition"
          copyMessage={copyMessage}
          editMessage={editMessage}
        />
      </Box>
    </Box>
  );
};

export default CompetitionMessages;
