import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  container: {
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  header: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
});
