import React, { useEffect, useState } from 'react';
import { ButtonPrimary } from 'core/ui/Button';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import useActions from 'modules/map/useActions';
import useSelectors from 'modules/map/useSelectors';
import { mediaFilesAssociationsModule } from 'modules/mediaFilesAssociations';
import Modules from 'modules';
import Grid from 'core/ui/Grid';
import { InputWrapper } from 'core/ui/ChosenComponents';
import { InputBase, Tab, Tabs } from '@material-ui/core';
import Paper from 'core/ui/Paper';
import MIcon from 'core/ui/MIcon';
import Paginator from 'core/ui/Paginator';
import ModalAddMedia from '../AddMedia/Modal';
import ItemMedia from './ItemMedia';

const MediaFilesGallery = ({
  onSelected,
  ownerableType,
  ownerableId,
  associableType,
  associableId,
  section,
  selectedFiles,
}) => {
  const { t } = useTranslation();

  const { request } = useActions();
  const mediaFiles = useSelectors(mediaFilesAssociationsModule, 'mediaFiles');
  const loading = useSelectors(mediaFilesAssociationsModule, 'loading');
  const paginationMediaFiles = useSelectors(mediaFilesAssociationsModule, 'paginationMediaFiles');
  const [query, setQuery] = useState('');
  const [tab, setTab] = useState('uploads');
  const [modal, setModal] = useState({
    open: false,
  });

  const [movementsData, setMovementsData] = useState({ pagination: null, data: [] });

  const searchMovements = (p) => {
    request({
      action: Modules.newMovements.actions.getMovementsVideos,
      data: {
        query,
        page: p,
        perPage: 8,
        ownerableId,
        ownerableType,
      },
      options: {
        onSuccess: (data, pagination) => {
          setMovementsData({ pagination, data });
        },
      },
    });
  };

  const search = (p, select = false) => {
    if (tab === 'movements') {
      searchMovements(p);
      return;
    }

    request({
      action: Modules.mediaFilesAssociations.actions.getMediaFiles,
      data: {
        query,
        page: p,
        perPage: 8,
        ownerableId,
        ownerableType,
      },
      options: {
        onSuccess: (data) => {
          if (select) {
            onSelected(data[0]);
          }
        },
      },
    });
  };

  useEffect(() => {
    if (ownerableId) {
      search(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request, ownerableId, tab]);

  const addMedia = () => {
    setModal((prev) => ({ ...prev, open: true }));
  };
  return (
    <Box p={5}>
      {modal.open && (
        <ModalAddMedia
          open={modal.open}
          section={section}
          ownerableId={ownerableId}
          ownerableType={ownerableType}
          associableType={associableType}
          associableId={associableId}
          title="Upload or use a link"
          close={() => setModal(() => ({ open: false }))}
          callback={() => {
            search(paginationMediaFiles?.currentPage || 1, true);
            setModal(() => ({ open: false }));
          }}
        />
      )}

      <Box>
        <Box display="flex" alignItems="center">
          <InputWrapper>
            <Box display="flex" style={{ width: '100%' }}>
              <InputBase
                value={query}
                onChange={(v) => setQuery(v.target.value)}
                style={{ minWidth: 30, width: '100%', margin: '4px' }}
                placeholder={t('gallery.search')}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    search();
                  }
                }}
              />
            </Box>
          </InputWrapper>
          <Box ml={3}>
            <ButtonPrimary disabled={loading} onClick={() => search()}>
              {t('button.search')}
            </ButtonPrimary>
          </Box>
        </Box>
      </Box>

      <Paper m={5} mx={20}>
        <Tabs
          value={tab}
          onChange={(e, v) => setTab(v)}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="Uploads" value="uploads" />
          <Tab label="Movements" value="movements" />
        </Tabs>
      </Paper>

      {tab === 'uploads' && (
        <>
          <Paper mt={2} p={5}>
            <Box display="flex" justifyContent="center">
              <ButtonPrimary onClick={() => addMedia()}>
                <MIcon icon="add" color="white" />
                {t('button.upload')}
              </ButtonPrimary>
            </Box>
          </Paper>

          <Grid container mt={2} spacing={2}>
            {mediaFiles &&
              mediaFiles.map((mov) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={mov.id}>
                  <ItemMedia
                    mediaFile={mov}
                    onSelect={onSelected}
                    isSelected={!!selectedFiles?.find((x) => x.id === mov.id)}
                  />
                </Grid>
              ))}
          </Grid>

          <Box mt={2} display="flex" justifyContent="flex-end">
            <Paginator pagination={paginationMediaFiles} changePage={search} />
          </Box>
        </>
      )}

      {tab === 'movements' && (
        <>
          <Grid container mt={2} spacing={2}>
            {movementsData?.data &&
              movementsData.data.map((mov) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={mov.id}>
                  <ItemMedia
                    mediaFile={mov}
                    onSelect={onSelected}
                    isSelected={!!selectedFiles?.find((x) => x.id === mov.id)}
                  />
                </Grid>
              ))}
          </Grid>

          <Box mt={2} display="flex" justifyContent="flex-end">
            <Paginator pagination={movementsData?.pagination || {}} changePage={search} />
          </Box>
        </>
      )}
    </Box>
  );
};

export default MediaFilesGallery;
