import React from 'react';
import { Tooltip } from '@material-ui/core';
import Box from 'core/ui/Box';
import Typography, { fontStyle } from 'core/ui/Typography';
import humps from 'humps';
import { showScore } from 'core/utils/helpers';
import LabelBreakLine from 'core/ui/LabelBreakLine';
import { Note } from '@material-ui/icons';

const PrScore = ({ scores, scoreType }) => {
  const repMaxDesc = () => {
    let desc = '';
    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].forEach((x) => {
      if (scores[`repMax${x}`]) {
        desc = `Rep Max ${x}`;
      }
    });
    return desc;
  };
  return (
    <Box>
      <Box display="flex">
        {scoreType === 'rep_max' ? (
          <Box>
            {scores?.repCustomScheme ? (
              <Box display="flex" className="gap-5" flex={1}>
                <Typography
                  style={fontStyle.medium}
                  align="center"
                  color="secondary "
                  className="roboto-regular"
                >
                  {scores.repCustomScheme}
                </Typography>
                <Typography
                  className="roboto-regular"
                  style={fontStyle.medium}
                  align="center"
                  color="primary"
                >
                  {scores.repCustomResults}
                </Typography>
              </Box>
            ) : (
              <Box display="flex" className="gap-5" flex={1}>
                <Typography
                  className="roboto-regular"
                  style={fontStyle.medium}
                  align="center"
                  color="secondary"
                >
                  {repMaxDesc()}
                </Typography>
                <Typography
                  className="roboto-regular"
                  style={fontStyle.medium}
                  align="center"
                  color="secondary"
                >
                  {scores.repMax1 ||
                    scores.repMax2 ||
                    scores.repMax3 ||
                    scores.repMax4 ||
                    scores.repMax5 ||
                    scores.repMax6 ||
                    scores.repMax7 ||
                    scores.repMax8 ||
                    scores.repMax9 ||
                    scores.repMax10}
                  <Typography
                    className="roboto-regular"
                    style={fontStyle.medium}
                    align="center"
                    color="secondary"
                  >
                    {scores.repMaxType}
                  </Typography>
                </Typography>
              </Box>
            )}
          </Box>
        ) : (
          <Box display="flex" flex={1}>
            <Typography style={fontStyle.medium} color="secondary">
              {showScore(scores[humps.camelize(scoreType)], scoreType)}
              &nbsp;
            </Typography>

            {scores[humps.camelize(`${scoreType}Type`)] && (
              <Typography style={fontStyle.medium} color="secondary">
                {scores[humps.camelize(`${scoreType}Type`)]
                  ? `${scores[humps.camelize(`${scoreType}Type`)].toUpperCase()}`
                  : ''}
              </Typography>
            )}
          </Box>
        )}

        {scores.notes && (
          <Box ml={1} pt={1}>
            <Tooltip title={<LabelBreakLine>{scores.notes}</LabelBreakLine>}>
              <Note style={{ fontSize: 18 }} />
            </Tooltip>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default PrScore;
