import React from 'react';
import Box from 'core/ui/Box';
import useSelectors from 'modules/map/useSelectors';
import { channelsCoachesClocksModule } from 'modules/channelsCoachesClocks';
import LoaderSm from 'core/ui/LoaderSm';
import CoachCustomPayments from 'components/Channel/Manage/Tabs/Coaches/CustomPayments/CoachCustomPayments';
import { channelsSelectors } from 'modules/channels';
import { useSelector } from 'react-redux';

const CoachProfileCustomPayments = ({ user }) => {
  const loading = useSelectors(channelsCoachesClocksModule, 'loading');
  const channel = useSelector((state) => channelsSelectors.getChannel(state));

  return (
    <Box p={5}>
      <Box display="flex" alignItems="flex-end" mb={3}>
        <Box flexGrow={1}>
          <LoaderSm loading={loading} />
        </Box>
      </Box>

      {channel?.id && <CoachCustomPayments channel={channel} coach={user} />}
    </Box>
  );
};

export default CoachProfileCustomPayments;
