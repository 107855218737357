import React, { useState } from 'react';
import { Box, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Typography from 'core/ui/Typography';
import { channelCurrency, date } from 'core/utils/formatters';
import Texture from 'core/ui/Texture';
import Button, { ButtonPrimary } from 'core/ui/Button';
import { newChannelMembersModule } from 'modules/channelMembers/new';
import useSelectors from 'modules/map/useSelectors';
import Modules from 'modules';
import useActions from 'modules/map/useActions';
import { useDispatch } from 'react-redux';
import notifications from 'modules/notifications';
import LoaderSm from 'core/ui/LoaderSm';

const ModalRenewMembership = ({ channel, channelKey, callbackSave }) => {
  const { t } = useTranslation();
  const [modalRenew, setModalRenew] = useState({ open: false });
  const loadingRenewMembership = useSelectors(newChannelMembersModule, 'loadingRenewMembership');
  const { request } = useActions();
  const dispatch = useDispatch();

  const renewMembership = () => {
    setModalRenew({
      open: true,
      channelKey,
      confirm: (maxPayments = null) => {
        request({
          action: Modules.newChannelMembers.actions.renewMembership,
          data: {
            channelId: channelKey?.channel?.id,
            channelKeyId: channelKey?.id,
            maxPayments,
          },
          options: {
            onSuccess: () => {
              setModalRenew({ open: false });
              if (callbackSave) {
                callbackSave();
              }
            },
            onError: (ex) => {
              dispatch(notifications.error(ex));
            },
          },
        });
      },
    });
  };

  return (
    <>
      <ButtonPrimary
        style={{ marginRight: 5 }}
        disabled={loadingRenewMembership}
        onClick={() => renewMembership()}
      >
        <Typography>{t('channel.membership.retry')}</Typography>
      </ButtonPrimary>
      <Dialog open={modalRenew?.open}>
        <Box>
          <Texture>
            <DialogContent style={{ minHeight: 130 }}>
              <Typography>
                {t('channel.membership.retry.confirm')
                  .replace(
                    '#{date}',
                    date(
                      modalRenew?.channelKey?.expiryAt || modalRenew?.channelKey?.startAt,
                      channel?.dateFormatReact,
                    ),
                  )
                  .replace(
                    '#{next_cost}',
                    channelCurrency(
                      modalRenew?.channelKey?.nextRenewAmounts?.nextCost,
                      channel?.currency,
                    ),
                  )}
              </Typography>

              <Box m={3}>
                <LoaderSm loading={loadingRenewMembership} center />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setModalRenew({ open: false })} color="primary">
                {t('button.cancel')}
              </Button>
              {modalRenew?.channelKey?.nextRenewAmounts?.items?.length <= 1 ? (
                <Button
                  disabled={loadingRenewMembership}
                  onClick={() => modalRenew?.confirm()}
                  color="primary"
                  autoFocus
                >
                  {t('button.confirm')}
                </Button>
              ) : (
                <>
                  <Button
                    disabled={loadingRenewMembership}
                    onClick={() => modalRenew?.confirm()}
                    color="primary"
                    autoFocus
                  >
                    {t('button.runAllTransactions')}
                  </Button>
                  <Button
                    disabeld={loadingRenewMembership}
                    onClick={() => modalRenew?.confirm(1)}
                    color="primary"
                    autoFocus
                  >
                    {t('button.runOneAtTime')}
                  </Button>
                </>
              )}
            </DialogActions>
          </Texture>
        </Box>
      </Dialog>
    </>
  );
};

export default ModalRenewMembership;
