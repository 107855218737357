/* eslint-disable react/prop-types */
import React, { useState } from 'react';

import Typography, { fontStyle } from 'core/ui/Typography';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import Paper from 'core/ui/Paper';
import RoomIcon from '@material-ui/icons/Room';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  withStyles,
  TableCell,
  Collapse,
} from '@material-ui/core';
import { secondary } from 'core/ui/Colors';
import { KeyboardArrowDown, KeyboardArrowRight } from '@material-ui/icons';

const styleIcon = {
  color: secondary,
  fontSize: 20,
};
export default ({ workout }) => {
  const { t } = useTranslation();
  // const showContext = useContext(ShowContext);
  const [open, setOpen] = useState(true);
  return (
    <Box mb={3}>
      <Paper elevation={2} className="paper-rounded" p={3}>
        <Box display="flex" alignItems="center" className="link" onClick={() => setOpen(!open)}>
          <Box flexGrow="1" display="flex" alignItems="center">
            <RoomIcon style={{ color: secondary, fontSize: 20 }} />
            <Typography ml={2} color="secondary" variant="h5">
              {t('workout.view.channel.inPersonLocations').toUpperCase()}
            </Typography>
          </Box>

          <Box>
            {open === true ? (
              <KeyboardArrowDown style={{ ...styleIcon }} />
            ) : (
              <KeyboardArrowRight style={{ ...styleIcon }} />
            )}
          </Box>
        </Box>
        <Collapse in={open}>
          <Box mt={5}>
            <TableContainer component={Paper}>
              <Table size="small" aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell />
                    <StyledTableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {workout.channelLocations &&
                    workout.channelLocations.map((location) => (
                      <StyledTableRow key={location.id}>
                        <StyledTableCell component="th" scope="row">
                          {location.name}
                        </StyledTableCell>
                        <StyledTableCell>
                          <Box>
                            <Box display="flex" flexDirection=" column">
                              <Typography style={fontStyle.small} ml={1} color="textSecondary">
                                {location.street1},{location.street2}
                              </Typography>
                              <Typography style={fontStyle.small} ml={1} color="textSecondary">
                                {location.city}/{location.state}&nbsp;-&nbsp;{location.zip}
                              </Typography>
                            </Box>
                          </Box>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Collapse>
      </Paper>
    </Box>
  );
};

const StyledTableCell = withStyles(() => ({
  head: {
    // backgroundColor: theme.palette.common.black,
    color: '#4fb94a',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);
