import { LayoutContext } from 'pages/shared/Layout';

const { useContext } = require('react');

const usePullRefresh = () => {
  const appContext = useContext(LayoutContext);

  const setRefresh = () => {
    appContext.setRefreshScreen(true);

    setTimeout(() => {
      appContext.setRefreshScreen(false);
    }, 1000);
  };

  return {
    setRefresh,
    refresh: appContext.refreshScreen,
  };
};

export default usePullRefresh;
