/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';

import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Typography from 'core/ui/Typography';
import { primary } from 'core/ui/Colors';
import { WorkoutContext } from '../index';

const CompDivisions = () => {
  const { t } = useTranslation();
  const formContext = useContext(WorkoutContext);

  const isChecked = (div) => {
    const arr = formContext.watch('competitionDivisions')
      ? [...formContext.watch('competitionDivisions')]
      : [];
    return !!arr.find((x) => x === div.id);
  };

  const change = (div) => {
    let arr = formContext.watch('competitionDivisions')
      ? [...formContext.watch('competitionDivisions')]
      : [];
    if (isChecked(div)) {
      arr = arr.filter((x) => x !== div.id);
    } else {
      arr.push(div.id);
    }
    formContext.setValue('competitionDivisions', arr);
  };

  const OptionDivision = ({ label, checked, onClick }) => (
    <Box onClick={() => onClick()} display="flex" className="link-hover" alignItems="center">
      {checked ? <CheckBoxIcon style={{ color: primary }} /> : <CheckBoxOutlineBlankIcon />}
      <Typography ml={1} mr={2} variant="caption" color="textSecondary">
        {label}
      </Typography>
    </Box>
  );

  return (
    <Box px={2} py={3}>
      <Typography variant="h5" mb={2} color="primary">
        {t('workout.label.competition.divisions')}
      </Typography>
      <Box display="flex" mt={2}>
        {formContext.selectedCompetition &&
          formContext.selectedCompetition.divisions &&
          formContext.selectedCompetition.divisions.map((div, index) => (
            <Box key={index.toString()}>
              <OptionDivision
                label={div.name}
                checked={isChecked(div)}
                onClick={() => change(div)}
              />
            </Box>
          ))}
      </Box>
    </Box>
  );
};

export default CompDivisions;

CompDivisions.propTypes = {};

CompDivisions.defaultProps = {};
