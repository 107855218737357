import api from 'core/api';
import { call, put } from 'redux-saga/effects';

export const commentsModule = 'comments';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const actions = {
  getComments: {
    module: commentsModule,
    name: 'getComments',
    api: (params) => api.get(`/comments`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: ['comments', 'pagination'],
    },
    *sagas(Creators, { params, options }) {
      try {
        const resp = yield call(actions.getComments.api, params);
        yield put(Creators.getCommentsSuccess(resp.data, resp.pagination));
        if (options.onSuccess) {
          options.onSuccess(resp.data, resp.pagination);
        }
      } catch (error) {
        if (options.onError) {
          options.onError(error);
        }
        yield put(Creators.getCommentsError());
      }
    },
    state: loadingStates,
  },
  postComment: {
    module: commentsModule,
    name: 'postComment',
    api: (data) => api.post(`/comments`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  deleteComment: {
    module: commentsModule,
    name: 'deleteComment',
    api: (id) => api.post(`/comments/${id}`),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  clearComments: {
    module: commentsModule,
    name: 'clearComments',
    params: {
      start: ['params'],
      error: [''],
      success: ['comments'],
    },
    state: loadingStates,
  },
};

export default {
  actions,
  state: {
    comments: [],
    pagination: null,
  },
};
