import React, { useState, useEffect, useMemo } from 'react';
import { useJsApiLoader } from '@react-google-maps/api';
import { GM_API_KEY } from 'core/env';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { searchActions } from 'modules/search';
import ModalAddPr from 'components/Profile/Dashboard/PersonalRecord/ModalAddPr';
import ModalSupport from 'components/Support/Modal';
import ModalLogin from 'components/Login/Modal';
import ModalChat from 'components/GroupsChat/ModalChat';
import { LayoutContext } from '.';

const drawerWidth = 250;

const LayoutContextProvider = ({ children }) => {
  const [titleBar, setTitleBar] = useState('');
  const [modalPrScore, setModalPrScore] = useState('');
  const [refreshScreen, setRefreshScreen] = useState(false);
  const [drawer, setDrawer] = useState(false);
  const [drawerFilters, setDrawerFilters] = useState({
    open: false,
    execSearch: false,
  });
  const [activeMenu, setActiveMenu] = useState('');
  const [modalSupport, setModalSupport] = useState({ open: false });
  const [modalLogin, setModalLogin] = useState({ open: false });
  const [modalChat, setModalChat] = useState({ open: false });
  const [heightHeaderWrapper, setHeightHeaderWrapper] = useState(0);

  const history = useHistory();
  const dispatch = useDispatch();
  const libraries = ['geometry', 'places'];

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GM_API_KEY,
    libraries,
    language: 'en',
  });

  useEffect(() => {
    setDrawer(false);
    setDrawerFilters((prev) => ({ ...prev, open: false }));
    dispatch(searchActions.clearSearchResults());
  }, [history.location, dispatch]);

  const states = useMemo(
    () => ({
      activeMenu,
      setActiveMenu,
      drawer,
      setDrawer,
      titleBar,
      drawerWidth,
      setTitleBar,
      modalSupport,
      setModalSupport,
      setModalLogin,
      modalLogin,
      drawerFilters,
      setDrawerFilters,
      setHeightHeaderWrapper,
      heightBanner: heightHeaderWrapper,
      heightHeader: 72,
      refreshScreen,
      setRefreshScreen,
      addPrScore: () => setModalPrScore({ open: true }),
      isMapsLoaded: isLoaded,
      modalChat,
      setModalChat,
    }),
    [
      activeMenu,
      drawer,
      titleBar,
      modalSupport,
      modalLogin,
      drawerFilters,
      heightHeaderWrapper,
      refreshScreen,
      isLoaded,
      modalChat,
    ],
  );

  return (
    <LayoutContext.Provider value={states}>
      {children}
      {modalPrScore.open && <ModalAddPr open close={() => setModalPrScore({ open: false })} />}
      {modalSupport.open && <ModalSupport />}
      {modalLogin.open && <ModalLogin />}
      {modalChat.open && (
        <ModalChat
          modalChat={modalChat}
          open={modalChat.open}
          close={() => setModalChat({ open: false })}
        />
      )}
    </LayoutContext.Provider>
  );
};

export default LayoutContextProvider;
