import { createTheme, alpha } from '@material-ui/core/styles';

import { blue, green, grey, red } from '@material-ui/core/colors';
import typography from './typography';
import overrides from './overrides';
import breakpoints from './breakpoints';
import props from './props';
import shadows from './shadows';

export default (color) =>
  createTheme({
    spacing: 4,
    breakpoints,
    overrides,
    props,
    typography,
    shadows,
    body: {
      background: '#ecf0f5',
    },
    mq: {
      background: '#00a65a',
    },
    header: {
      color: grey[500],
      background: '#FFFFFF',
      search: {
        color: grey[800],
      },
      indicator: {
        background: red[600],
      },
    },
    palette: {
      background: {
        default: alpha('#00a65a', 0),
      },
      primary: {
        main: color || '#4fb94a',
        contrastText: '#FFF',
        light: color || '#4fb94a',
      },
      danger: {
        main: 'Red',
        contrastText: '#FFF',
      },
      secondary: {
        main: '#404041',
        contrastText: '#FFF',
      },
      white: {
        main: '#fff',
        contrastText: '#FFF',
      },
      mq: {
        card: {
          colorGreen: '#00a65a',
          colorRed: '#f51d00',
          colorLight: '#d2d6de',
          colorDark: '#333333',
          background: '#fff',
        },
        background: '#00a65a',
      },
    },
    sidebar: {
      color: grey[200],
      background: '#00a65a',
      header: {
        color: grey[200],
        background: '#232f3e',
        brand: {
          color: blue[500],
        },
      },
      footer: {
        color: grey[200],
        background: '#232f3e',
        online: {
          background: green[500],
        },
      },
    },
  });
