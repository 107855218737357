import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router';
import { persistData } from 'core/utils/session-storage';
import { useDispatch, useSelector } from 'react-redux';
import { sessionActions, sessionSelectors } from 'modules/session';
import StreamFitnessSplash from 'core/ui/StreamFitnessSplash';
import { parse } from 'query-string';

const CalendarConnect = () => {
  const isAuthenticated = useSelector((state) => sessionSelectors.getIsAuthenticated(state));
  const location = useLocation();
  const { auth } = useParams();
  const parsed = parse(location.search);

  const dispatch = useDispatch();

  const authString = () => auth || parsed?.token;

  const decodeToken = () => {
    const data = atob(authString());

    const decoded = JSON.parse(data);

    Object.keys(decoded?.token || {}).forEach((item) => {
      persistData(item, decoded.token[item]);
    });

    if (!decoded?.usersCalendarId) {
      // eslint-disable-next-line no-alert
      alert('Error, Calendar ID is blank.');
      return;
    }

    persistData(
      'EXTERNAL_CALENDAR_INTEGRATION',
      JSON.stringify({
        ownerableId: decoded?.usersCalendarId,
        ownerableType: 'UsersCalendar',
      }),
    );
    dispatch(sessionActions.authenticateStart());
  };

  useEffect(() => {
    if (auth || parsed?.token) {
      decodeToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, parsed?.token]);

  useEffect(() => {
    if (isAuthenticated) {
      const data = atob(authString());

      const decoded = JSON.parse(data);

      setTimeout(() => {
        window.location = decoded?.redirectUrl;
      }, 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return <StreamFitnessSplash />;
};

export default CalendarConnect;
