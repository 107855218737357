import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import Grid from 'core/ui/Grid';

import Typography from 'core/ui/Typography';
import TextAreaFieldRounded from 'core/ui/TextAreaFieldRounded';

import { WorkoutContext } from './index';

export default () => {
  const { t } = useTranslation();
  const formContext = useContext(WorkoutContext);

  return (
    <Grid container pb={5}>
      <Grid item xs={12} sm={12} md={12} lg={12} px={2}>
        <Box mt={3}>
          <Typography variant="h5" mb={2} color="primary">
            {t('workout.recordingInstructions')}
          </Typography>
        </Box>

        <Box m={1}>
          <TextAreaFieldRounded
            name="compInstructions"
            type="number"
            error={formContext.errors.compInstructions}
            placeholder={t('workout.placeholder.compInstructions')}
            rows={10}
            inputRef={formContext.register}
          />
        </Box>
      </Grid>
    </Grid>
  );
};
