import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import Texture from 'core/ui/Texture';
import Box from 'core/ui/Box';
import Paper from 'core/ui/Paper';
import LoaderSm from 'core/ui/LoaderSm';
import { Elements, useStripe } from '@stripe/react-stripe-js';
import { STRIPE_PK_KEY } from 'core/env';
import { loadStripe } from '@stripe/stripe-js';
import { ButtonPrimary } from 'core/ui/Button';
import Modules from 'modules';
import useActions from 'modules/map/useActions';
import useSelectors from 'modules/map/useSelectors';
import { newPaymentsModule } from 'modules/payments/new';
import Typography from 'core/ui/Typography';
import { PaymentStatus } from 'core/utils/consts';
import { channelCurrency } from 'core/utils/formatters';
import Divider from 'core/ui/Divider';

const ModalRequiresAction = (props) => {
  const payment = useSelectors(newPaymentsModule, 'payment');
  const loadingPayment = useSelectors(newPaymentsModule, 'loading');
  const { request } = useActions();

  const getPayment = () => {
    request({
      action: Modules.newPayments.actions.getPaymentBySecret,
      data: { secret: props.clientSecret },
      options: {},
    });
  };

  useEffect(() => {
    getPayment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <LoaderSm center width={50} loading={loadingPayment} />
      {payment?.id && (
        <LoadStripePaymentAction getPayment={getPayment} payment={payment} {...props} />
      )}
    </>
  );
};

const LoadStripePaymentAction = (props) => {
  const stripePromise = useRef(
    loadStripe(
      STRIPE_PK_KEY,
      props?.payment?.stripeAcctId ? { stripeAccount: props?.payment?.stripeAcctId } : {},
    ),
  );
  return (
    <Elements stripe={stripePromise.current}>
      <ContentModalRequiresAction {...props} />
    </Elements>
  );
};

function ContentModalRequiresAction({ open, getPayment, payment, close, clientSecret, callback }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const stripe = useStripe();

  const confirm = () => {
    setLoading(true);
    stripe.confirmCardPayment(clientSecret).then(() => {
      setTimeout(() => {
        getPayment();
        callback();
        setLoading(false);
      }, 3000);
    });
  };

  const failed = [PaymentStatus.failed, PaymentStatus.requires_payment_method].find(
    (x) => x === payment?.status,
  );

  return (
    <Dialog maxWidth="sm" scroll="body" onClose={() => close()} open={open} fullWidth>
      <Texture>
        <Box p={5}>
          <Paper p={10} className="paper-rounded">
            {payment?.id && (
              <>
                <Typography color="success" variant="h1" align="center">
                  {payment?.ownerable?.channel?.name || payment?.ownerable?.name || 'Payment'}
                </Typography>
                <Divider m={5} />
                <Box textAlign="center">
                  {failed && (
                    <Box display="flex" justifyContent="center" alignItems="center">
                      <Box display="flex" flexDirection="column" alignItems="center">
                        <Typography color="primary" variant="h5">
                          {t(`payment.stripe.status.failed`)}
                        </Typography>
                        <Typography variant="caption">{payment?.error}</Typography>
                      </Box>
                    </Box>
                  )}

                  {payment?.status === 'succeeded' && (
                    <Typography color="primary" variant="h1">
                      {t('competition.payment.success')}
                    </Typography>
                  )}
                </Box>

                <Box textAlign="center" my={10}>
                  <Typography variant="h1">
                    {channelCurrency(payment?.amount, payment?.currency)}
                  </Typography>
                </Box>

                {!!stripe && payment?.status !== 'succeeded' && (
                  <ButtonPrimary
                    fullWidth
                    disabled={loading}
                    onClick={() => {
                      confirm();
                    }}
                  >
                    <LoaderSm loading={loading} />
                    {t('button.confirmPayment')}
                  </ButtonPrimary>
                )}
              </>
            )}
          </Paper>
        </Box>
      </Texture>
    </Dialog>
  );
}

export default ModalRequiresAction;
