import api from 'core/api';

export const storeTaxJarModule = 'storeTaxJar';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const actions = {
  getStoreTaxJarCategories: {
    module: storeTaxJarModule,
    name: 'getStoreTaxJarCategories',
    api: (params) => api.get(`/store/tax_jar/categories`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: ['data'],
    },
    state: loadingStates,
  },
};

export default {
  actions,
  state: {
    loading: false,
    loadingPull: false,
    data: [],
  },
};
