import React from 'react';
import Box from 'core/ui/Box';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import { useTranslation } from 'react-i18next';
import Typography from 'core/ui/Typography';
import Paper from 'core/ui/Paper';

export default function DonationSection({ competition, value, setValue }) {
  const { t } = useTranslation();
  return (
    <Paper className="paper-rounded" p={5}>
      <Typography color="primary">{t('donation')}</Typography>
      <Typography className="roboto-regular" mb={1} ml={1}>
        {competition?.donationsDescription}
      </Typography>
      <Box mb={3}>
        <TextFieldRounded
          type="number"
          pattern="[0-9]*"
          value={value || 0}
          placeholder={t('competition.placeholder.donation')}
          onChange={(v) => (v >= 0 ? setValue(v) : null)}
          required
          currency
          bordered
        />
      </Box>
    </Paper>
  );
}

DonationSection.defaultProps = {
  competition: {},
};
