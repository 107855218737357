import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import Box from 'core/ui/Box';
import Texture from 'core/ui/Texture';
import SFDataTable from 'core/ui/DataTable';
import useSelectors from 'modules/map/useSelectors';
import Modules from 'modules';
import useActions from 'modules/map/useActions';
import LoaderSm from 'core/ui/LoaderSm';
import Typography from 'core/ui/Typography';
import { personalRecordMovementsModule } from 'modules/personalRecordMovements';

const ModalUsers = ({ close, personalRecordId }) => {
  const users = useSelectors(personalRecordMovementsModule, 'users');
  const loadingUsers = useSelectors(personalRecordMovementsModule, 'loadingUsers');
  const { request } = useActions();
  const columns = [
    {
      name: 'Name',
      selector: 'name',
      sortable: true,
      cell: (row) => <Box>{row.name}</Box>,
    },
  ];

  useEffect(() => {
    request({
      action: Modules.personalRecordMovements.actions.getPrMovementUsers,
      data: { id: personalRecordId },
    });
  }, [request, personalRecordId]);

  return (
    <Dialog open onClose={close} maxWidth="md" fullWidth scroll="body">
      <Texture>
        <Box p={5}>
          <Box m={5} display="flex" justifyContent="center">
            <LoaderSm loading={loadingUsers} />
          </Box>
          <SFDataTable
            noDataComponent={loadingUsers ? null : <Typography m={5}>No Users</Typography>}
            columns={columns}
            data={users}
            noHeader
            pagination
            showExport
          />
        </Box>
      </Texture>
    </Dialog>
  );
};

ModalUsers.propTypes = {
  close: PropTypes.func,
};

ModalUsers.defaultProps = {
  close: () => {},
};

export default ModalUsers;
