/* eslint-disable react/prop-types */
import React from 'react';

import Paper from 'core/ui/Paper';
import { useTranslation } from 'react-i18next';
import Typography from 'core/ui/Typography';
import SFDataTable from 'core/ui/DataTable';
import { Link } from '@material-ui/core';
import { date, timeWithoutTz } from 'core/utils/formatters';

export default function ResultWorkouts({ channel, workouts }) {
  const { t } = useTranslation();
  const columns = [
    {
      name: 'ID',
      selector: 'id',
      cell: (row) => <Typography> {row.id}</Typography>,
      sortable: true,
      width: '80px',
    },
    {
      name: 'Name',
      selector: 'name',
      sortable: true,
      cell: (row) => (
        <Link href={`/workout/view/${row.id}`} target="_blank">
          {row.name}
        </Link>
      ),
    },
    {
      name: 'Type',
      selector: 'methodClass',
      sortable: true,
      cell: (row) => <Typography> {row.methodClass}</Typography>,
    },
    {
      name: 'Coach',
      sortable: true,
      selector: 'coachName',
      cell: (row) => <Typography>{row.coachName}</Typography>,
    },
    {
      name: 'Date',
      selector: 'scheduledAt',
      sortable: true,
      center: true,
      cell: (row) => (
        <Typography>
          <span>{date(row.scheduledAt, channel?.dateFormatReact)}</span>
          <span>&nbsp;&bull;&nbsp;</span>
          <span className="roboto-regular">{timeWithoutTz(row.scheduledAt)}</span>
        </Typography>
      ),
    },
    {
      name: 'In-Person',
      sortable: true,
      selector: 'countInPersonUsers',
      cell: (row) => <Typography>{row.countInPersonUsers}</Typography>,
    },
    {
      name: 'Online',
      sortable: true,
      selector: 'countOnlineUsers',
      cell: (row) => <Typography>{row.countOnlineUsers}</Typography>,
    },
    {
      name: 'Check-ins',
      selector: 'countCheckIn',
      sortable: true,
      cell: (row) => <Typography>{row.countCheckIn}</Typography>,
    },
    {
      name: 'On-Demand',
      selector: 'countOnDemandUsers',
      sortable: true,
      center: true,
      cell: (row) => <Typography>{row.countOnDemandUsers}</Typography>,
    },
    {
      name: 'Total',
      selector: 'countUsers',
      sortable: true,
      cell: (row) => <Typography>{row.countUsers}</Typography>,
    },
    {
      name: 'Views',
      selector: 'views',
      sortable: true,
      cell: (row) => <Typography>{row.views}</Typography>,
    },
  ];

  const getRows = () => {
    const rowsWorkouts =
      workouts &&
      workouts.map((workout) => ({
        id: workout.id,
        name: workout.name,
        methodClass: workout.methodClass,
        scheduledAt: workout.scheduledAt,
        coachId: workout.user.id,
        coachName: workout.user.name,
        countUsers: workout.countUsers,
        countOnlineUsers: workout.countOnlineUsers,
        countOnDemandUsers: workout.countOnDemandUsers,
        countCheckIn: workout.countCheckIn,
        countInPersonUsers: Number(workout.countUsers) - Number(workout.countOnlineUsers),
        views: workout.impressionsCount,
      }));

    return rowsWorkouts;
  };

  const getGroupRows = (rows) => {
    const group = {};
    rows.forEach((x) => {
      if (!group[x.name]) {
        group[x.name] = {
          name: x.name,
          countUsers: 0,
          countInPersonUsers: 0,
          countOnlineUsers: 0,
          countCheckIn: 0,
          countOnDemandUsers: 0,
          views: 0,
          count: 0,
        };
      }

      group[x.name].countUsers += x.countUsers;
      group[x.name].countInPersonUsers += x.countInPersonUsers;
      group[x.name].countOnlineUsers += x.countOnlineUsers;
      group[x.name].countOnDemandUsers += x.countOnDemandUsers;
      group[x.name].countCheckIn += x.countCheckIn;
      group[x.name].views += x.views;
      group[x.name].count += 1;
    });

    const newArray = [];
    Object.keys(group).forEach((k) => {
      newArray.push(group[k]);
    });

    return newArray;
  };

  const columnsGroup = [
    {
      name: 'Name',
      selector: 'name',
      cell: (row) => <Typography>{row.name}</Typography>,
      sortable: true,
    },
    {
      name: 'Classes',
      selector: 'count',
      cell: (row) => <Typography color="primary">{row.count}</Typography>,
      sortable: true,
      center: true,
    },
    {
      name: 'In-Person',
      selector: 'countInPersonUsers',
      sortable: true,
      center: true,
      cell: (row) => <Typography>{row.countInPersonUsers}</Typography>,
    },
    {
      name: 'Online',
      selector: 'countOnlineUsers',
      sortable: true,
      center: true,
      cell: (row) => <Typography>{row.countOnlineUsers}</Typography>,
    },
    {
      name: 'Check-ins',
      selector: 'countCheckIn',
      sortable: true,
      center: true,
      cell: (row) => <Typography>{row.countCheckIn}</Typography>,
    },
    {
      name: 'On-Demand',
      selector: 'countOnDemandUsers',
      sortable: true,
      center: true,
      cell: (row) => <Typography>{row.countOnDemandUsers}</Typography>,
    },
    {
      name: 'Total',
      selector: 'countUsers',
      sortable: true,
      center: true,
      cell: (row) => <Typography>{row.countUsers}</Typography>,
    },
    {
      name: 'Views',
      selector: 'views',
      sortable: true,
      center: true,
      cell: (row) => <Typography>{row.views}</Typography>,
    },
  ];
  const rows = getRows();
  const rowsGroup = getGroupRows(rows);

  return (
    <>
      <Paper style={{ width: '100%' }}>
        <SFDataTable
          title={t('channel.reports.workouts')}
          columns={columns}
          data={rows}
          pagination
          showExport
          paginationPerPage={20}
        />
      </Paper>
      <Paper mt={10} style={{ width: '100%' }}>
        <SFDataTable
          title={t('channel.reports.summaryByName')}
          columns={columnsGroup}
          data={rowsGroup}
          pagination
          showExport
          paginationPerPage={10}
        />
      </Paper>
    </>
  );
}
