import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import Typography from 'core/ui/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { sessionSelectors } from 'modules/session';
import CountryChosen from 'core/ui/CountryChosen';
import { ButtonPrimary } from 'core/ui/Button';
import SelectorPaymentMethod, { PaymentMethodViewType } from 'components/Account/Selector';
import { useForm } from 'react-hook-form-v5';
import { competitionsActions, competitionsSelectors } from 'modules/competitions';
import notifications from 'modules/notifications';
import { channelCurrency } from 'core/utils/formatters';
import { competitionsUsersModule } from 'modules/competitionsUsers';
import useSelectors from 'modules/map/useSelectors';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import DonationSection from './New/DonationSection';

export default function AffiliateRegistration({ buttonColor, close }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const competition = useSelector((state) => competitionsSelectors.getCompetition(state));
  const { setValue, watch, errors, register, handleSubmit } = useForm();
  const loadingRegistration = useSelectors(competitionsUsersModule, 'loading');
  const userSession = useSelector((state) => sessionSelectors.getUser(state));
  const [viewMethodPayment, setViewMethodPayment] = useState(PaymentMethodViewType.list);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const { request } = useActions();

  const totalAmount = () => {
    let price = Number(competition.costAffiliate);
    if (Number(watch('donationAmount') || 0) > 0) {
      price += Number(watch('donationAmount'));
    }

    return price;
  };

  const totalPrice = totalAmount();

  useEffect(() => {
    register('gymName', { required: true });
    register('countryId', { required: true });
    register('donationAmount', { required: false });

    setTimeout(() => {
      setValue('countryId', 16);
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submit = (data) => {
    if (!selectedPaymentMethod && totalPrice > 0) {
      dispatch(notifications.warning(t('modalpurchase.validation.selectAccount')));
      return;
    }

    request({
      action: Modules.competitionsUsers.actions.create,
      data: {
        competitionId: competition.id,
        affiliate: true,
        ...data,
        userPaymentMethodId: selectedPaymentMethod ? selectedPaymentMethod.id : null,
      },
      options: {
        onSuccess: () => {
          close();
          dispatch(competitionsActions.getCompetitionStart(competition.id));
          dispatch(notifications.success(t('competition.registration.success')));
        },
        onError: (error) => {
          dispatch(notifications.error(error));
        },
      },
    });
  };

  return (
    <Box px={5}>
      <Typography mb={10} align="center" variant="h4">
        {t('competition.affiliateRegistration')}
      </Typography>
      <Box mb={3}>
        <TextFieldRounded
          name="name"
          onChange={(v) => setValue('gymName', v)}
          value={watch('gymName') || ''}
          placeholder={t('competition.registration.label.gymName')}
          label={t('competition.registration.label.gymName')}
          error={errors.gymName}
          required
        />
      </Box>

      <Box mb={3}>
        <CountryChosen
          setValue={(v) => setValue('countryId', v)}
          defaultValue={watch('countryId')}
          label={t('competition.registration.label.gymCountry')}
          error={errors.gymCountry}
        />
      </Box>

      {!!competition.donations && (
        <Box>
          <DonationSection
            competition={competition}
            value={watch('donationAmount')}
            setValue={(v) => setValue('donationAmount', v)}
          />
        </Box>
      )}

      {totalPrice > 0 && (
        <>
          <Box>
            <SelectorPaymentMethod
              selectedPaymentMethod={selectedPaymentMethod}
              setSelectedPaymentMethod={setSelectedPaymentMethod}
              view={viewMethodPayment}
              setView={setViewMethodPayment}
              userId={userSession.id}
              userInfo={{
                name: userSession?.name,
                phone: userSession?.phone,
                email: userSession?.email,
              }}
              cardpointe={userSession?.gymConfig?.useCardpointe}
              onlyMethod={competition?.channel?.allowAchPayments ? null : 'card'}
            />
          </Box>

          <Box mx={2} my={3}>
            <Typography align="center" variant="h6">
              {t('competition.registration.cost')}
            </Typography>
            <Typography align="center" color="primary" variant="h2">
              {channelCurrency(totalPrice, competition?.channel?.currency)}
            </Typography>
          </Box>
        </>
      )}

      <Box>
        <ButtonPrimary
          style={{ backgroundColor: buttonColor }}
          disabled={loadingRegistration}
          fullWidth
          onClick={() => handleSubmit(submit)()}
        >
          {t('button.register')}
        </ButtonPrimary>
      </Box>
    </Box>
  );
}
