import React from 'react';
import Paper from 'core/ui/Paper';
import { GoogleMap, OverlayView } from '@react-google-maps/api';
import { mapsStyles } from 'core/utils/consts';
import { Dialog } from '@material-ui/core';
import DialogTitle from './Dialog/DialogTitle';

const ModalViewMap = ({ avatar, open, close, location }) => (
  <Dialog open={open} onClose={close} maxWidth="md" fullWidth>
    <DialogTitle onClose={close}>{location?.name}</DialogTitle>
    <Paper>
      <GoogleMap
        clickableIcons={false}
        zoom={12}
        center={location}
        options={{
          styles: mapsStyles.light,
        }}
        mapContainerStyle={{ width: '100%', height: 350, flex: 1 }}
      >
        <OverlayView
          bounds={
            new window.google.maps.LatLngBounds(
              new window.google.maps.LatLng(location?.lat, location?.lng),
              new window.google.maps.LatLng(location?.lat, location?.lng),
            )
          }
          mapPaneName={OverlayView.FLOAT_PANE}
        >
          {avatar}
        </OverlayView>
      </GoogleMap>
    </Paper>
  </Dialog>
);

ModalViewMap.defaultProps = {};

ModalViewMap.propTypes = {};

export default ModalViewMap;
