import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import DatePickerRounded from 'core/ui/DatePickers/DatePickerRounded';
import { ButtonPrimary } from 'core/ui/Button';
import { GetApp, Search } from '@material-ui/icons';
import { formatDate, formatDateToApi } from 'core/utils/formatters';
import { channelReportsModule } from 'modules/channelReports';
import useSelectors from 'modules/map/useSelectors';
import { timeZoneName } from 'core/utils/formatters/date';
import Paper from 'core/ui/Paper';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import Modules from 'modules';
import { newChannelsModule } from 'modules/channels/new';
import useActions from 'modules/map/useActions';
import Grid from 'core/ui/Grid';
import ChoosenChannelTags from 'core/ui/ChoosenChannelTags';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import PaymentsReportContent from './ReportContent';
import BarMonths from './BarMonths';

const PaymentsReport = ({ channel, loading, getReport }) => {
  const { t } = useTranslation();
  const data = useSelectors(channelReportsModule, 'data');
  const dateNow = new Date();
  const month = dateNow.getMonth() + 1;
  const { request } = useActions();
  const [perPage, setPerPage] = useState(10);
  const [filters, setFilters] = useState({
    startAt: formatDate(`${month > 12 ? 12 : month}/1/${dateNow.getFullYear()}`, 'MM/DD/YYYY'),
    endAt: formatDate(new Date(), 'MM/DD/YYYY'),
    workoutId: '',
    kind: 'all',
    channelMembershipPlanId: 'all',
    competitionId: 'all',
    membersTags: [],
    membershipsTags: [],
  });

  const search = ({ page = 1, exportReport = false } = {}) => {
    const startAt = filters.startAt ? formatDateToApi(filters.startAt, timeZoneName()) : null;

    const endAt = filters.endAt ? formatDateToApi(filters.endAt, timeZoneName()) : null;
    if (startAt && endAt)
      getReport({
        ...filters,
        channelMembershipPlanId:
          filters?.channelMembershipPlanId !== 'all' ? filters?.channelMembershipPlanId : null,
        competitionId: filters?.competitionId !== 'all' ? filters?.competitionId : null,
        startAt,
        endAt,
        page,
        perPage,
        exportReport,
      });
  };

  useEffect(() => {
    if (channel?.id) {
      search();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel, perPage]);

  const channelCompetitions = useSelectors(newChannelsModule, 'channelCompetitions');

  useEffect(() => {
    if (channel && channel.id) {
      request({
        action: Modules.newChannels.actions.getChannelCompetitions,
        data: { channelId: channel.id },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel]);

  return (
    <Box mt={2}>
      <Box>
        <Paper p={5} mb={3}>
          <Box display="flex" alignItems="center" flexWrap="wrap">
            <Box display="flex" mr={3}>
              <Box>
                <DatePickerRounded
                  label={t('channel.reports.filter.start').toUpperCase()}
                  onChange={(v) => setFilters((prev) => ({ ...prev, startAt: v }))}
                  value={filters.startAt}
                  disablePast={false}
                  usFormat={channel?.useUsDateFormat}
                  autoOk
                  bordered
                />
              </Box>
              <Box ml={1}>
                <DatePickerRounded
                  label={t('channel.reports.filter.end').toUpperCase()}
                  onChange={(v) => setFilters((prev) => ({ ...prev, endAt: v }))}
                  value={filters.endAt}
                  disablePast={false}
                  usFormat={channel?.useUsDateFormat}
                  autoOk
                  bordered
                />
              </Box>
              <Box ml={1}>
                <TextFieldRounded
                  label={t('label.userName').toUpperCase()}
                  value={filters?.userName}
                  onChange={(v) => setFilters((prev) => ({ ...prev, userName: v }))}
                  bordered
                />
              </Box>
              {filters.kind === 'store' && (
                <Box ml={1}>
                  <TextFieldRounded
                    label={t('label.productName').toUpperCase()}
                    value={filters?.productName}
                    onChange={(v) => setFilters((prev) => ({ ...prev, productName: v }))}
                    bordered
                  />
                </Box>
              )}
            </Box>

            <Box display="flex" alignItems="flex-end" mt={1} style={{ height: 55 }}>
              <Box>
                <SelectBoxObject
                  label="TYPES"
                  options={[
                    'all',
                    'membership',
                    'store',
                    'competition',
                    'users_calendars_registration',
                    'channels_events_registration',
                  ].map((x) => ({
                    id: x,
                    name: t(`label.${x}`),
                  }))}
                  value={filters?.kind}
                  setValue={(v) => setFilters((prev) => ({ ...prev, kind: v }))}
                  bordered
                />
              </Box>
              {filters.kind === 'competition' && (
                <Box mx={3}>
                  <SelectBoxObject
                    label="COMPETITIONS"
                    placeholder="All"
                    emptyItem
                    emptyValue="all"
                    options={channelCompetitions || []}
                    value={filters?.competitionId}
                    setValue={(v) => setFilters((prev) => ({ ...prev, competitionId: v }))}
                    bordered
                  />
                </Box>
              )}
              {filters.kind === 'membership' && (
                <Box mx={3}>
                  <SelectBoxObject
                    label="MEMBERSHIPS"
                    placeholder="All"
                    emptyItem
                    emptyValue="all"
                    options={channel.membershipAllPlans || []}
                    value={filters?.channelMembershipPlanId}
                    setValue={(v) =>
                      setFilters((prev) => ({ ...prev, channelMembershipPlanId: v }))
                    }
                    bordered
                  />
                </Box>
              )}
            </Box>
            <Box ml={3} pt={5}>
              <ButtonPrimary disabled={loading} onClick={() => search()}>
                <Search style={{ color: 'white' }} fontSize="small" />
                {t('button.search')}&nbsp;
              </ButtonPrimary>
            </Box>
            <Box ml={3} pt={5}>
              <ButtonPrimary
                disabled={loading}
                onClick={() => search({ page: 1, exportReport: true })}
              >
                <GetApp style={{ color: 'white' }} fontSize="small" />
                {t('button.exportCsv')}&nbsp;
              </ButtonPrimary>
            </Box>
          </Box>
          <Box mt={3}>
            <Grid container spacing={5}>
              <Grid item xs={12} md={4}>
                <ChoosenChannelTags
                  label={t('channel.label.memberTags').toUpperCase()}
                  placeholder={t('channel.tags')}
                  val={filters?.membersTags ? [...filters?.membersTags] : []}
                  setValue={(v) =>
                    setFilters((prev) => ({ ...prev, membersTags: v?.map((x) => x?.name || x) }))
                  }
                  channelId={channel?.id}
                  bordered
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <ChoosenChannelTags
                  label={t('channel.label.membershipsTags').toUpperCase()}
                  placeholder={t('channel.tags')}
                  val={filters?.membershipsTags ? [...filters?.membershipsTags] : []}
                  setValue={(v) =>
                    setFilters((prev) => ({
                      ...prev,
                      membershipsTags: v?.map((x) => x?.name || x),
                    }))
                  }
                  channelId={channel?.id}
                  bordered
                />
              </Grid>
            </Grid>
          </Box>
        </Paper>

        <PaymentsReportContent
          getReport={(page) => {
            search({ page: page || 1 });
          }}
          data={data}
          channel={channel}
          rows={data?.payments || []}
          loading={loading}
          perPage={perPage}
          setPerPage={setPerPage}
        />

        <Box mt={3}>
          <BarMonths channelId={channel?.id} />
        </Box>
      </Box>
    </Box>
  );
};

PaymentsReport.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  loading: PropTypes.bool,
  getReport: PropTypes.func,
};

PaymentsReport.defaultProps = {
  loading: false,
  getReport: () => {},
};

export default PaymentsReport;
