/* eslint-disable react/prop-types */
import React from 'react';

import { useTranslation } from 'react-i18next';

import TextFieldRounded from 'core/ui/TextFieldRounded';
import TextAreaFieldRounded from 'core/ui/TextAreaFieldRounded';
import Box from 'core/ui/Box';
import Grid from 'core/ui/Grid';
import Typography from 'core/ui/Typography';
import CountryChosen from 'core/ui/CountryChosen';
import InputPhone from 'core/ui/InputPhone';
import Radio from 'core/ui/Radio';
import Interests from './Interests';

export default ({
  errors,
  setValue,
  watch,
  register,
  setIsPhoneValid,
  memberEdit,
  memberAdd,
  quickForm,
  profile,
  fastForm,
}) => {
  const { t } = useTranslation();

  if (fastForm) {
    return (
      <>
        <Box mb={4} px={5}>
          <InputPhone
            name="phone"
            error={errors.phone}
            label={t('profile.label.mobilePhone').toUpperCase()}
            setValue={(data) => {
              setValue('phone', data.number);
              setIsPhoneValid(data.isValid);
            }}
            value={watch('phone') || ''}
            required
          />
        </Box>
        <Box mb={4} px={5}>
          <TextFieldRounded
            name="encryptedPin"
            error={errors.encryptedPin}
            placeholder={profile?.existsPin ? 'PIN ****' : t('profile.placeholder.pin')}
            label={t('profile.label.pin').toUpperCase()}
            inputRef={register({ required: !memberAdd && !memberEdit && !profile?.existsPin })}
            required={!memberAdd && !memberEdit && !profile?.existsPin}
            inputProps={{ maxLength: 4 }}
          />
        </Box>
      </>
    );
  }

  return (
    <>
      <Typography color="primary" mb={3} variant="h5">
        <span>{t('profile.title.athlete').toUpperCase()}</span>
      </Typography>

      <Grid container>
        <Grid item xs={12} sm={12} md={quickForm ? 12 : 6} lg={quickForm ? 12 : 6} px={5}>
          <Box mb={4}>
            <InputPhone
              name="phone"
              error={errors.phone}
              label={t('profile.label.mobilePhone').toUpperCase()}
              setValue={(data) => {
                setValue('phone', data.number);
                setIsPhoneValid(data.isValid);
              }}
              value={watch('phone') || ''}
              required
            />
          </Box>
          <Box mb={4}>
            <TextFieldRounded
              name="email"
              error={errors.email}
              placeholder={t('profile.placeholder.email')}
              label={t('profile.label.email').toUpperCase()}
              inputRef={register({ required: true })}
              required
            />
          </Box>

          <Box mb={4}>
            <TextFieldRounded
              name="encryptedPin"
              error={errors.encryptedPin}
              placeholder={profile?.existsPin ? 'PIN ****' : t('profile.placeholder.pin')}
              label={t('profile.label.pin').toUpperCase()}
              inputRef={register({ required: !memberAdd && !memberEdit && !profile?.existsPin })}
              required={!memberAdd && !memberEdit && !profile?.existsPin}
              inputProps={{ maxLength: 4 }}
            />
          </Box>

          <Box mb={4}>
            <CountryChosen
              name="countryId"
              defaultValue={`${watch('countryId')}`}
              placeholder={t('profile.placeholder.country')}
              error={errors.countryId}
              label={t('profile.label.country').toUpperCase()}
              setValue={(v) => setValue('countryId', v)}
              required
            />
          </Box>
          <Box mb={4}>
            <TextFieldRounded
              name="state"
              placeholder={t('profile.placeholder.cityStateProvidence')}
              label={t('profile.label.cityStateProvidence').toUpperCase()}
              inputRef={register({ required: false })}
            />
          </Box>

          <Box mb={3}>
            <Typography>{t('profile.placeholder.weightDefault')}</Typography>
            <Radio
              label="Imperial System"
              checked={!!watch('defaultWeightImperial')}
              onClick={() => setValue('defaultWeightImperial', true)}
            />
            <Radio
              label="Metric"
              checked={!watch('defaultWeightImperial')}
              onClick={() => setValue('defaultWeightImperial', false)}
            />
          </Box>
          <Box>
            <Typography>{t('profile.placeholder.defaultDistanceMiles')}</Typography>
            <Radio
              label="Miles"
              checked={!!watch('defaultDistanceMiles')}
              onClick={() => setValue('defaultDistanceMiles', true)}
            />
            <Radio
              label="Kilometers"
              checked={!watch('defaultDistanceMiles')}
              onClick={() => setValue('defaultDistanceMiles', false)}
            />
          </Box>
        </Grid>
        {!quickForm && (
          <Grid item xs={12} sm={12} md={6} lg={6} px={5}>
            <Box mb={4}>
              <TextAreaFieldRounded
                name="description"
                placeholder={t('profile.placeholder.athlete.descriptionBackground')}
                label={t('profile.label.descriptionBackground').toUpperCase()}
                inputRef={register({ required: false })}
              />
            </Box>
            <Box mb={4}>
              <TextAreaFieldRounded
                name="injuryReport"
                placeholder={t('profile.placeholder.athlete.injuryReport')}
                label={t('profile.label.athlete.injuryReport').toUpperCase()}
                inputRef={register({ required: false })}
              />
            </Box>
          </Grid>
        )}
      </Grid>

      {!quickForm && !memberAdd && !memberEdit && (
        <Box mt={3}>
          <Interests watch={watch} errors={errors} setValue={setValue} />
        </Box>
      )}
    </>
  );
};
