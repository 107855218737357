import React from 'react';
import propTypes from 'prop-types';
import { Box, IconButton } from '@material-ui/core';
import Typography from 'core/ui/Typography';
import { Close } from '@material-ui/icons';
import Paper from 'core/ui/Paper';
import Content from './Content';

export default function ViewMore({ channelId, viewMore, setViewMore, onClickCard }) {
  const bgModal = {
    background: '#fff',
    borderRadius: 10,
  };

  return (
    <Paper className="paper-rounded" p={5}>
      <Box mb={3} display="flex" alignItems="center">
        <Box flexGrow="1" display="flex" alignItems="center">
          <Box alignItems="center" display="flex" p={3} style={{ ...bgModal }}>
            <Typography ml={1} color="secondary" className="link" variant="h5">
              {viewMore.title.toUpperCase()}
            </Typography>
          </Box>
        </Box>
        <Box style={{ ...bgModal }} display="flex" justifyContent="flex-end" alignItems="center">
          <IconButton
            aria-label="close"
            onClick={() => setViewMore((prev) => ({ ...prev, open: false }))}
          >
            <Close />
          </IconButton>
        </Box>
      </Box>

      <Content viewMore={viewMore} channelId={channelId} onClickCard={onClickCard} />
    </Paper>
  );
}

ViewMore.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  viewMore: propTypes.object,
  setViewMore: propTypes.func,
  channelId: propTypes.number,
  onClickCard: propTypes.func,
};

ViewMore.defaultProps = {
  viewMore: {},
  setViewMore: null,
  channelId: null,
  onClickCard: null,
};
