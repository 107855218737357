import React from 'react';
import { Dialog, Slide } from '@material-ui/core';

import GroupsChat from '.';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const ModalChat = ({ modalChat, open, close }) => (
  <Dialog
    style={{ marginTop: 100 }}
    fullScreen
    open={open}
    onClose={close}
    TransitionComponent={Transition}
  >
    {/* <AppBar className={classes.appBar} color="secondary">
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={close} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar> */}

    <GroupsChat {...modalChat} close={close} />
  </Dialog>
);

ModalChat.defaultProps = {
  userId: null,
  open: false,
  close: () => {},
};

export default ModalChat;
