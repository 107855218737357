import React, { useEffect, useState } from 'react';
import Box from 'core/ui/Box';
import { useSelector } from 'react-redux';
import { sessionSelectors } from 'modules/session';
import Typography from 'core/ui/Typography';
import Divider from 'core/ui/Divider';
import Paper from 'core/ui/Paper';
import { partnersModule } from 'modules/partners';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import useSelectors from 'modules/map/useSelectors';
import { ButtonPrimary } from 'core/ui/Button';
import { Add, Edit } from '@material-ui/icons';
import { redColor, successColor } from 'core/ui/Colors';
import ModalFormPartner from './Form/Modal';

const PartnerList = ({ setPartner }) => {
  const [modalPartner, setModalPartner] = useState({ open: false });
  const user = useSelector((state) => sessionSelectors.getUser(state));
  const isAdminOrSupport = useSelector((state) => sessionSelectors.getIsAdminOrManager(state));

  const { request } = useActions();
  const partners = useSelectors(partnersModule, 'data');

  const getPartners = () => {
    request({
      action: Modules.partners.actions.getPartners,
    });
  };

  useEffect(() => {
    if (isAdminOrSupport) {
      getPartners();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <Box>
      <Box m={1} display="flex" justifyContent="flex-end">
        <ButtonPrimary onClick={() => setModalPartner({ open: true })}>
          <Add />
        </ButtonPrimary>
      </Box>
      <Paper p={3}>
        {partners?.map((p, index) => (
          <Box key={p?.id}>
            <Box display="flex" alignItems="center">
              <Box m={1} style={{ width: 65 }}>
                {p?.image?.image?.url && (
                  <img src={p?.image?.image?.url} alt="Partner" style={{ width: 60 }} />
                )}
              </Box>
              <Box flexGrow={1}>
                <Typography>{p.companyName}</Typography>
              </Box>
              <Box mx={2}>
                <Typography
                  style={{
                    backgroundColor: p.hasStripe ? successColor : redColor,
                    borderRadius: 5,
                    padding: 2,
                    color: 'white',
                    fontSize: 12,
                  }}
                >
                  {p.hasStripe ? 'Stripe connected' : 'Stripe not connected'}
                </Typography>
              </Box>
              <ButtonPrimary onClick={() => setPartner(p)}>Open</ButtonPrimary>
              <Box mx={1}>
                <ButtonPrimary onClick={() => setModalPartner({ open: true, partner: p })}>
                  <Edit />
                </ButtonPrimary>
              </Box>
            </Box>
            {partners?.length - 1 > index && <Divider my={2} />}
          </Box>
        ))}
      </Paper>
      <ModalFormPartner
        partner={modalPartner?.partner}
        open={modalPartner?.open}
        close={() => setModalPartner({ open: false })}
        callbackSave={() => {
          setModalPartner({ open: false });
          getPartners();
        }}
      />
    </Box>
  );
};

export default PartnerList;
