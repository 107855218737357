import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { workoutHeatsActions, workoutHeatsSelectors } from 'modules/workoutHeats';
import { workoutsActions, workoutsSelectors } from 'modules/workouts';
import { competitionsSelectors } from 'modules/competitions';
import Modules from 'modules';
import useActions from 'modules/map/useActions';
import notifications from 'modules/notifications';
import { useTranslation } from 'react-i18next';

const useHeats = ({ workout, hasDivisions, divisionId, loadLeaderboard = true }) => {
  const { t } = useTranslation();
  const { request } = useActions();
  const loadingHeats = useSelector((state) => workoutHeatsSelectors.getLoading(state));
  const heats = useSelector((state) => workoutHeatsSelectors.getWorkoutHeats(state));
  const competition = useSelector((state) => competitionsSelectors.getCompetition(state));
  const dispatch = useDispatch();
  const [modalHeatAction, setModalHeatAction] = useState();
  const loadingLeaderboard = useSelector((state) =>
    workoutsSelectors.getLoadingSectionLeaderboard(state),
  );
  const leaderboard = useSelector((state) => workoutsSelectors.getWorkoutSectionLeaderboard(state));
  const [heatsUsers, setHeatsUsers] = useState([]);

  useEffect(() => {
    if (heats) {
      const heatUsers = [];
      heats.forEach((x) => {
        const l = x.workoutHeatUsers.map((y) => ({
          id: y?.id,
          heatId: x.id,
          userId: y?.user?.id,
          laneNumber: y.laneNumber,
          registration: y?.registration,
        }));
        heatUsers.push(...l);
      });
      setHeatsUsers(heatUsers);
    }
  }, [heats]);

  const loadWorkoutLeaderboard = () => {
    if (workout && workout.id && workout.sections && workout.sections[0]) {
      dispatch(
        workoutsActions.getWorkoutSectionLeaderboardStart(workout.id, workout.sections[0].id, {
          divisionId,
        }),
      );
    }
  };

  const loadHeats = () => {
    if (workout && workout.id) {
      dispatch(workoutHeatsActions.getWorkoutHeatsStart(workout.id));
    }
  };

  const resetHeats = () => {
    if (workout && workout.id) {
      dispatch(
        workoutHeatsActions.resetWorkoutHeatsStart(workout.id, () => {
          loadHeats();
        }),
      );
    }
  };

  const createNewHeat = (data) => {
    if (workout && workout.id) {
      request({
        action: Modules.newWorkoutHeats.actions.createNewHeat,
        data: {
          workoutId: workout?.id,

          ...data,
        },
        options: {
          onSuccess: () => {
            setModalHeatAction({});
            dispatch(notifications.success(t('workout.heats.save')));
            loadHeats();
          },
        },
      });
    }
  };

  const changeHeat = (heatId, data = {}) => {
    request({
      action: Modules.newWorkoutHeats.actions.updateHeat,
      data: {
        workoutId: workout?.id,
        id: heatId,
        ...data,
      },
      options: {
        onSuccess: () => {
          setModalHeatAction({});
          dispatch(notifications.success(t('workout.heats.save')));
          loadHeats();
        },
      },
    });
  };

  useEffect(() => {
    if (loadLeaderboard && (!hasDivisions || !!divisionId)) {
      loadWorkoutLeaderboard();
    }
    loadHeats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workout?.id, divisionId, hasDivisions, loadLeaderboard]);

  const hasHeats = heats && heats.length > 0;

  return {
    heats,
    hasHeats,
    leaderboard,
    loadingLeaderboard,
    loadingHeats,
    loadHeats,
    resetHeats,
    heatsUsers,
    setHeatsUsers,
    competition,
    changeHeat,
    modalHeatAction,
    setModalHeatAction,
    createNewHeat,
  };
};

export default useHeats;
