/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import {
  InputLabel,
  ButtonGroup,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  useTheme,
} from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import { ChannelContext } from 'components/Channel/Manage/index';
import { getBase64 } from 'core/utils/helpers';
import { ArrowDropDownCircleOutlined, PhotoCamera, OpenWith, Publish } from '@material-ui/icons';
import Typography from 'core/ui/Typography';
import Box from 'core/ui/Box';
import { ButtonWhite } from 'core/ui/Button';

const SelImage = ({ widthElement }) => {
  const { palette } = useTheme();
  const formContext = useContext(ChannelContext);
  const { t } = useTranslation();
  const anchorRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleCapture = ({ target }) => {
    if (target.files.length) {
      for (let i = 0; i < target.files.length; i += 1) {
        const file = target.files[i];
        getBase64(file, (result) => {
          formContext.setImage({
            url: URL.createObjectURL(file),
            image: result,
            name: file.name,
            position: { x: 0, y: 1, width: widthElement },
            new: true,
            edit: true,
          });
          formContext.setTab('info');
        });
      }
    }
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const reposition = () => {
    formContext.setImage((prev) => ({
      ...prev,
      new: false,
      edit: true,
    }));
  };

  const styleIcon = {
    color: '#404041',
  };

  const randomId = Math.floor(Math.random() * 101);

  return (
    <Box style={{ position: 'relative', zIndex: 2 }}>
      {formContext.image.url && (
        <>
          <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button">
            <ButtonWhite p={1} onClick={handleToggle}>
              <PhotoCamera style={{ fontSize: 16 }} />{' '}
              <Typography component="span" mx={1}>
                {t('channel.editCoverPhoto')}
              </Typography>
            </ButtonWhite>
            <ButtonWhite
              p={1}
              color="secondary"
              size="small"
              aria-controls={open ? 'split-button-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={handleToggle}
            >
              <ArrowDropDownCircleOutlined style={{ fontSize: 16 }} />
            </ButtonWhite>
          </ButtonGroup>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            style={{ paddingTop: 2 }}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList>
                      <MenuItem onClick={() => reposition()}>
                        <Box display="flex" alignItems="center">
                          <OpenWith style={{ ...styleIcon }} size="small" />
                          <Typography ml={3} color="secondary" variant="subtitle2">
                            {t('reposition')}
                          </Typography>
                        </Box>
                      </MenuItem>
                      <MenuItem>
                        <InputLabel htmlFor={`icon-button-file-${randomId}`}>
                          <Box display="flex" alignItems="center">
                            <Publish style={{ ...styleIcon }} size="small" />
                            <Typography ml={3} color="secondary" variant="subtitle2">
                              {t('uploadImage')}
                            </Typography>
                          </Box>
                        </InputLabel>
                        <input
                          color="primary"
                          type="file"
                          onChange={handleCapture}
                          id={`icon-button-file-${randomId}`}
                          style={{ display: 'none' }}
                          accept=".png,.jpg,.jpeg"
                          multiple
                        />
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </>
      )}

      {!formContext.image.image && (
        <>
          <ButtonWhite variant="contained" p={1} onClick={handleToggle}>
            <InputLabel
              style={{ color: palette.primary.main }}
              htmlFor={`icon-button-file-${randomId}`}
            >
              <Box display="flex" alignItems="center">
                <Publish style={{ ...styleIcon, color: palette.primary.main }} size="small" />
                <Typography ml={3} variant="subtitle2">
                  {t('uploadImage')}
                </Typography>
              </Box>
            </InputLabel>
          </ButtonWhite>
          <input
            color="primary"
            type="file"
            onChange={handleCapture}
            id={`icon-button-file-${randomId}`}
            style={{ display: 'none' }}
            accept=".png,.jpg,.jpeg"
            multiple
          />
        </>
      )}
    </Box>
  );
};

export default SelImage;
