import React from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import Typography, { fontStyle } from 'core/ui/Typography';
import { ButtonSecondary } from 'core/ui/Button';
import CheckIcon from '@material-ui/icons/Check';
import LoaderSm from 'core/ui/LoaderSm';

export default function ButtonsRegister({ purchase, loading, workout }) {
  const { t } = useTranslation();

  const showInPerson =
    workout.isCoach || workout.hasListChannel || workout.hasValidKeyInPersonChannel;

  const showOnline = workout.isCoach || workout.hasListChannel || workout.hasValidKeyChannel;

  return (
    <Box display="flex" justifyContent="flex-end">
      {showInPerson && workout.inPerson && (
        <Box flexGrow={1} mr={1}>
          <ButtonSecondary
            disabled={loading}
            onClick={() => purchase('inPerson', workout.id)}
            type="submit"
            fullWidth
          >
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
              <Box display="flex" alignItems="center">
                <LoaderSm loading={loading} width={20} />
                <Typography component="span" variant="h6">
                  {t('button.inPerson')}
                </Typography>
              </Box>

              <Box display="flex" mt={-1} alignItems="center">
                <CheckIcon style={{ color: 'white', fontSize: 14 }} />
                <Typography style={{ ...fontStyle.xSmall, color: 'white' }}>
                  {t(workout.hasListChannel ? 'channelList' : 'membership')}
                </Typography>
              </Box>
            </Box>
          </ButtonSecondary>
        </Box>
      )}

      {showOnline && workout.online && (
        <Box flexGrow={1} ml={1}>
          <ButtonSecondary
            disabled={loading}
            onClick={() => purchase('online', workout.id)}
            type="submit"
            fullWidth
          >
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
              <Box display="flex" alignItems="center">
                <LoaderSm loading={loading} width={20} />
                <Typography component="span" variant="h6">
                  {t('button.online')}
                </Typography>
              </Box>

              <Box display="flex" mt={-1} alignItems="center">
                <CheckIcon style={{ color: 'white', fontSize: 14 }} />
                <Typography style={{ ...fontStyle.xSmall, color: 'white' }}>
                  {t(workout.hasListChannel ? 'channelList' : 'membership')}
                </Typography>
              </Box>
            </Box>
          </ButtonSecondary>
        </Box>
      )}
    </Box>
  );
}

ButtonsRegister.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  workout: propTypes.object.isRequired,
  purchase: propTypes.func.isRequired,
  loading: propTypes.bool,
};

ButtonsRegister.defaultProps = {
  loading: false,
};
