import React from 'react';
import { secondary } from '../Colors';

const LeaderboardIcon = ({ size, color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    role="img"
    width={size}
    height={size}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 700 700"
  >
    <path
      d="m434.85 526.06h25.457v-118.79h127.27c9.375 0 16.973 7.5977 16.973 16.969v101.82h25.453c4.6875 0 8.4844 3.7969 8.4844 8.4844v16.969c0 4.6875-3.7969 8.4844-8.4844 8.4844h-560c-4.6875 0-8.4844-3.7969-8.4844-8.4844v-16.969c0-4.6875 3.7969-8.4844 8.4844-8.4844h25.453v-144.25c0-9.3711 7.5977-16.969 16.973-16.969h127.27v161.21h25.457v-220.61c0-9.3711 7.5977-16.969 16.969-16.969h135.76c9.3711 0 16.969 7.5977 16.969 16.969zm-124.3-329.18c-16.633 8.7422-33.852-3.9336-30.703-22.305l7.5352-43.93-31.914-31.109c-13.457-13.117-6.7188-33.41 11.727-36.09l44.105-6.4102 19.727-39.969c8.3164-16.848 29.699-16.715 37.945 0l19.727 39.969 44.105 6.4102c18.598 2.6992 25.074 23.078 11.727 36.09l-31.914 31.109 7.5352 43.93c3.1758 18.52-14.203 30.977-30.703 22.305l-39.449-20.742z"
      fill={color}
    />
  </svg>
);

export default LeaderboardIcon;

LeaderboardIcon.defaultProps = {
  icon: null,
  color: secondary,
  size: 20,
  styles: {},
};
