import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { roomActions, roomSelectors } from 'modules/room';
import Box from 'core/ui/Box';
import OvVideoComponent from 'components/Stream/Video/OvVideo';
import { StreamContext } from 'pages/Stream';
import { TypesSignal } from 'core/openVidu/useOpenVidu';

export default function LiveUsers({ showLiveIcon }) {
  const user = useSelector((state) => roomSelectors.getUser(state));
  const participants = useSelector((state) => roomSelectors.getParticipants(state));
  const focusedUserId = useSelector((state) => roomSelectors.getFocusedUserId(state));
  const streamContext = useContext(StreamContext);
  const dispatch = useDispatch();
  const setFocused = (userId) => {
    if (focusedUserId === userId) {
      dispatch(roomActions.setFocusedUserId(null));
      streamContext.eventSideBar.sendSignalUserChanged({
        type: TypesSignal.setFocusedUser,
        userId: user.id,
        focusedUserId: null,
      });
    } else {
      dispatch(roomActions.setFocusedUserId(userId));
      streamContext.eventSideBar.sendSignalUserChanged({
        type: TypesSignal.setFocusedUser,
        userId: user.id,
        focusedUserId: userId,
      });
    }
  };

  const sizeParticipant = {
    width: 200,
    height: 150,
  };

  const setParticipantScreen = (participant, screen = false) => {
    if (focusedUserId) {
      return;
    }
    dispatch(roomActions.setParticipantFullScreen(participant.id, screen));
  };

  return (
    <>
      {user && user.streamManager && (
        <Box
          mx={1}
          className={`link ${focusedUserId === user.id ? 'neon-wrapper' : ''}`}
          onClick={() => setParticipantScreen(user)}
        >
          <OvVideoComponent
            showControls
            showOnline
            showLiveIcon={showLiveIcon}
            showMove={false}
            showClose={false}
            focused={focusedUserId === user.id}
            setFocused={setFocused}
            style={{
              ...sizeParticipant,
              borderRadius: 10,
              boxShadow: '0 2px 2px rgba(0,0,0,0.10), 0 1px 1px rgba(0,0,0,0.10)',
            }}
            user={user}
            streamManager={user.streamManager}
          />
        </Box>
      )}
      {participants &&
        participants.map((p, index) => (
          <Box
            mx={1}
            key={index.toString()}
            className={`link ${focusedUserId === p.id ? 'neon-wrapper' : ''}`}
            onClick={() => setParticipantScreen(p)}
          >
            <OvVideoComponent
              showControls
              showOnline
              showMove={false}
              focused={focusedUserId === p.id}
              setFocused={setFocused}
              showLiveIcon={showLiveIcon}
              showClose={false}
              style={{ ...sizeParticipant, borderRadius: 10 }}
              user={p}
              streamManager={p.streamManager}
            />
          </Box>
        ))}
    </>
  );
}
