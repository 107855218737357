/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Button, IconButton } from '@material-ui/core';
import Typography from 'core/ui/Typography';
import Box from 'core/ui/Box';
import Scrollbars from 'react-custom-scrollbars';
import { primary, secondary } from 'core/ui/Colors';
import LoaderLine from 'core/ui/LoaderSm/LoaderLine';
import { PlayArrow, RotateLeft } from '@material-ui/icons';
import { ButtonPrimary } from 'core/ui/Button';
import { LayoutContext } from 'pages/shared/Layout';
import { useSelector } from 'react-redux';
import { sessionSelectors } from 'modules/session';
import { ShowContext } from './index';
import CoachMenuActions from './Tabs/Actions/CoachMenuActions';

const CompetitionTabs = ({ actionsVisible }) => {
  const { t } = useTranslation();
  const currentUser = useSelector((state) => sessionSelectors.getUser(state));
  const layoutContext = useContext(LayoutContext);
  const showContext = useContext(ShowContext);

  const useStyles = makeStyles((theme) => ({
    boxTabs: {
      display: 'flex',
      height: 55,
      borderRadius: 5,
      alignItems: 'center',
    },
    wraperTabs: {
      background: secondary,
      height: 55,
      borderRadius: 5,
    },
    activeTab: {
      borderBottom: '0.5px dotted #fff',
    },
    styleTab: {
      padding: 3,
      marginLeft: 5,
      [theme.breakpoints.up('sm')]: {
        padding: 5,
        marginLeft: 11,
      },
    },
  }));
  const classes = useStyles();

  const ItemTab = ({ label, tab, onClick }) => (
    <Box className={classes.styleTab}>
      <Button size="small" onClick={() => (onClick ? onClick() : showContext.setTab(tab))}>
        <Typography
          className={showContext.tab === tab && classes.activeTab}
          style={{ color: 'white' }}
          variant="subtitle2"
          noWrap
        >
          {label}
        </Typography>
      </Button>
    </Box>
  );

  return (
    <Box className={classes.wraperTabs} px={2}>
      <Box display="flex" alignItems="center">
        <Box flexGrow={1}>
          <Scrollbars
            autoHide
            renderTrackVertical={(props) => (
              <div {...props} style={{ display: 'none' }} className="track-horizontal" />
            )}
            style={{ width: '100%', height: 55 }}
          >
            <Box className={classes.boxTabs}>
              <Box className={classes.styleTab}>
                <Button size="small" onClick={() => showContext.setTab('workouts')}>
                  <Typography
                    className={
                      (showContext.tab === 'workouts' || showContext.tab === 'viewWorkout') &&
                      classes.activeTab
                    }
                    style={{ color: 'white' }}
                    variant="subtitle2"
                  >
                    {t('competition.tab.home')}
                  </Typography>
                </Button>
              </Box>

              {(!showContext.competition.hideLeaderboard || showContext.competition.isCoach) && (
                <ItemTab label={t('competition.tab.leaderboard')} tab="results" />
              )}
              {showContext.competition.isCoach && (
                <ItemTab label={t('competition.tab.scores')} tab="scores" />
              )}

              {showContext?.competition?.showMessagesBoard && (
                <ItemTab
                  label={t('competition.tab.messageBoard')}
                  tab="chat"
                  onClick={() =>
                    layoutContext?.setModalChat({
                      open: true,
                      ownerableType: 'Competition',
                      ownerableId: showContext?.competition?.id,
                      ownerable: showContext?.competition,
                    })
                  }
                />
              )}
              {showContext.competition.showStore && (
                <ItemTab label={t('competition.tab.store')} tab="store" />
              )}
              {showContext.competition.isCoach && (
                <>
                  <ItemTab label={t('competition.tab.athletes')} tab="athletes" />
                  <ItemTab label={t('competition.tab.vendors')} tab="vendor" />
                  <ItemTab label={t('competition.tab.volunteers')} tab="volunteer" />
                </>
              )}
            </Box>
          </Scrollbars>
          {showContext.tab === 'viewWorkout' && showContext.eventTimer.visible && (
            <Box
              display="flex"
              alignItems="center"
              style={{
                position: 'absolute',
                right: 3,
                top: 2,
                zIndex: 10,
                height: 50,
              }}
            >
              {showContext.eventTimer.stopped !== false && (
                <Box>
                  <IconButton
                    onClick={() => showContext.setEventTimer((prev) => ({ ...prev, start: true }))}
                  >
                    <PlayArrow style={{ color: showContext.buttonColor, fontSize: 50 }} />
                  </IconButton>
                </Box>
              )}
              <Box ml={1}>
                <IconButton
                  onClick={() => showContext.setEventTimer((prev) => ({ ...prev, reset: true }))}
                >
                  <RotateLeft style={{ color: showContext.buttonColor, fontSize: 40 }} />
                </IconButton>
              </Box>
            </Box>
          )}
        </Box>
        {actionsVisible && showContext?.competition?.isCoach && (
          <Box ml={2}>
            <CoachMenuActions />
          </Box>
        )}
        {actionsVisible && !showContext?.competition?.isCoach && (
          <Box ml={2}>
            {!showContext?.competition.registeredAsAffiliate &&
              !showContext?.competition.isCoach &&
              !showContext?.competition.registered &&
              showContext?.competition.allowRegister && (
                <ButtonPrimary
                  onClick={() =>
                    showContext?.setOpenModalRegistration((prev) => ({
                      ...prev,
                      open: true,
                      pay: true,
                    }))
                  }
                  buttonColor={showContext?.competition?.buttonColor || primary}
                  pl={3}
                  pr={3}
                  spacing={2}
                  style={{ letterSpacing: 2 }}
                >
                  {t('button.register').toUpperCase()}
                </ButtonPrimary>
              )}
          </Box>
        )}
        {actionsVisible &&
          !showContext.competition?.isCoach &&
          showContext.competition.registered && (
            <Box ml={2}>
              <ButtonPrimary
                onClick={() =>
                  showContext?.setOpenTeamEdit({ open: true, userId: currentUser?.id })
                }
                buttonColor={showContext?.competition?.buttonColor || primary}
                pl={3}
                pr={3}
                spacing={2}
                style={{ letterSpacing: 2 }}
              >
                {t('button.myRegistration').toUpperCase()}
              </ButtonPrimary>
            </Box>
          )}
      </Box>

      <Box px={2} mt={-1.01}>
        {showContext.competition && showContext.competition.id && (
          <LoaderLine loading={showContext.loading} color={showContext.buttonColor} />
        )}
      </Box>
    </Box>
  );
};

export default CompetitionTabs;
