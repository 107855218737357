import api from 'core/api';

export const channelsPayoutsModule = 'channelsPayouts';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const actions = {
  show: {
    module: channelsPayoutsModule,
    name: 'show',
    api: (data) =>
      api.get(`/channels/${data?.channelId}/channels_payouts/${data?.id}`, {
        params: { cardpointe: data?.cardpointe },
      }),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  update: {
    module: channelsPayoutsModule,
    name: 'update',
    api: (data) => api.put(`/channels/${data?.channelId}/channels_payouts/${data?.id}`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
};

export default {
  actions,
  state: {
    data: [],
    loading: false,
  },
};
