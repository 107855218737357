import React, { useMemo, useState } from 'react';
import { PartersProgramsWorkoutsContext } from 'contexts';
import ModalCloneWorkoutsToWorkout from '../ModalCloneWorkoutsToWorkout';

const ModalCalendarContextProvider = ({ programs, channel, children, callback, close }) => {
  const [selectedWorkouts, setSelectedWorkouts] = useState(null);
  const [modalProgram, setModalProgram] = useState({ open: false });
  const [selectedPrograms, setSelectedPrograms] = useState([]);

  const callbackSave = () => {
    callback();
  };

  const closeModal = () => {
    close();
  };

  const toggleProgram = (program, root) => {
    if (root && program.parentPrograms?.length > 0) {
      const isChecked = !!selectedPrograms.find((x) => x === program.parentPrograms[0]?.id);

      if (isChecked) {
        setSelectedPrograms((prev) =>
          prev.filter((x) => !program.parentPrograms.find((y) => y.id === x)),
        );
      } else {
        setSelectedPrograms((prev) => [...prev, ...program.parentPrograms.map((x) => x.id)]);
      }
    } else if (selectedPrograms.find((x) => x === program?.id)) {
      setSelectedPrograms((prev) => prev.filter((x) => x !== program?.id));
    } else {
      setSelectedPrograms((prev) => [...prev, program?.id]);
    }
  };

  const isSelectedAll = (program) => {
    let ret = true;

    if (program.parentPrograms?.length === 0) {
      return !!selectedPrograms.find((x) => x === program?.id);
    }

    program.parentPrograms.forEach((p) => {
      if (!selectedPrograms.find((x) => x === p?.id)) {
        ret = false;
      }
    });
    return ret;
  };

  const stateContext = useMemo(
    () => ({
      isSelectedAll,
      toggleProgram,
      selectedWorkouts,
      setSelectedWorkouts,
      modalProgram,
      setModalProgram,
      callbackSave,
      closeModal,
    }),
    [modalProgram],
  );

  return (
    <PartersProgramsWorkoutsContext.Provider value={stateContext}>
      {children}
      {selectedWorkouts?.length > 0 && (
        <ModalCloneWorkoutsToWorkout
          workouts={selectedWorkouts}
          close={() => setSelectedWorkouts([])}
          open={selectedWorkouts?.length}
          programs={programs}
          channel={channel}
        />
      )}
    </PartersProgramsWorkoutsContext.Provider>
  );
};

ModalCalendarContextProvider.propTypes = {};

ModalCalendarContextProvider.defaultProps = {
  close: () => {},
};

export default ModalCalendarContextProvider;
