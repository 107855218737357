import React, { useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import Box from 'core/ui/Box';
import Typography, { fontStyle } from 'core/ui/Typography';
import TimeSelector from 'core/ui/TimeSelector';
import { hasScore } from 'core/utils/helpers';
import { typeWeight, typeDistance } from 'core/utils/consts';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import Checkbox from 'core/ui/Checkbox';
import { makeStyles } from '@material-ui/core';
import { secondaryLight } from 'core/ui/Colors';
import TextAreaFieldRounded from 'core/ui/TextAreaFieldRounded';
import { useSelector } from 'react-redux';
import { sessionSelectors } from 'modules/session';

const useStyles = makeStyles(() => ({
  boxScore: {
    border: `0.5px solid ${secondaryLight}`,
    borderRadius: 10,
    padding: 10,
    margin: 1,
  },
}));

const ScoreFields = ({
  workout,
  scoresTypes,
  sectionData,
  changeCurrentScores,
  currentEditScores,
  timeCapped,
  prefix,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const currentUser = useSelector((state) => sessionSelectors.getUser(state));

  const defaultWeightType = typeWeight.find(
    (x) => x.id === (currentUser?.defaultWeightImperial ? 'pounds' : 'kilograms'),
  );
  const defaultDistanceType = typeDistance.find(
    (x) => x.id === (currentUser?.defaultDistanceMiles ? 'miles' : 'kilometers'),
  );

  const liftComplexScore = useCallback(
    (idx) =>
      currentEditScores[`${prefix}liftComplex`]
        ? currentEditScores[`${prefix}liftComplex`][idx] || {}
        : {},
    [currentEditScores, prefix],
  );

  return (
    <div>
      {sectionData.scores && (
        <>
          {hasScore(scoresTypes, 'time') && (
            <Box>
              {hasScore(scoresTypes, 'timeCapped') && (
                <Box mb={3}>
                  <Checkbox
                    color="secondary"
                    label={t('workout.section.label.score.timeCapped')}
                    onClick={() =>
                      changeCurrentScores({
                        timeCapped: !timeCapped,
                      })
                    }
                    checked={currentEditScores && timeCapped}
                  />
                </Box>
              )}
              {!timeCapped && (
                <Box className={classes.boxScore}>
                  <TimeSelector
                    time={currentEditScores[`${prefix}time`]}
                    setTime={(time) => changeCurrentScores({ [`${prefix}time`]: time })}
                    label={t('workout.section.label.score.time')}
                  />

                  {!workout.isCompetition && (
                    <Box mt={1}>
                      <TextAreaFieldRounded
                        onChange={(v) => changeCurrentScores({ [`${prefix}timeNotes`]: v })}
                        value={currentEditScores[`${prefix}timeNotes`] || ''}
                        label={t('workout.section.label.notes')}
                        placeholder={t('workout.section.label.notes')}
                      />
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          )}
          {hasScore(scoresTypes, 'completion') && (
            <Box mb={3}>
              <Checkbox
                color="secondary"
                label={t('workout.section.label.score.completion')}
                onClick={() =>
                  changeCurrentScores({
                    completion: currentEditScores?.completion === '0' ? '1' : '0',
                  })
                }
                checked={currentEditScores?.completion === '1'}
              />
            </Box>
          )}

          {(!hasScore(scoresTypes, 'timeCapped') || !!timeCapped) && (
            <>
              {hasScore(scoresTypes, 'liftComplex') && (
                <Box className={classes.boxScore}>
                  {[...new Array(Number(scoresTypes?.liftComplex?.sets))].map((_i, idx) => (
                    <Box>
                      <Typography mb={1} style={{ ...fontStyle.label }} variant="body1">
                        {`${t('workout.section.label.score.liftComplex')} #${idx + 1}`}
                      </Typography>
                      <Box display="flex" alignItems="flex-start" className="gap-10">
                        {scoresTypes?.liftComplex?.custom && (
                          <TextFieldRounded
                            onChange={(v) =>
                              changeCurrentScores({
                                [`${prefix}liftComplex`]: {
                                  ...(currentEditScores[`${prefix}liftComplex`] || {}),
                                  [idx]: { ...liftComplexScore(idx), [`${prefix}reps`]: v },
                                },
                              })
                            }
                            value={liftComplexScore(idx)[`${prefix}reps`] || ''}
                            placeholder={t('workout.section.label.score.reps')}
                          />
                        )}
                        <TextFieldRounded
                          onChange={(v) =>
                            changeCurrentScores({
                              [`${prefix}liftComplex`]: {
                                ...(currentEditScores[`${prefix}liftComplex`] || {}),
                                [idx]: { ...liftComplexScore(idx), [`${prefix}weight`]: v },
                              },

                              [`${prefix}typeWeight`]:
                                liftComplexScore(idx)[`${prefix}typeWeight`] ||
                                defaultWeightType.id,
                            })
                          }
                          value={liftComplexScore(idx)[`${prefix}weight`] || ''}
                          placeholder={t('workout.section.label.score.weight')}
                        />
                        <Box>
                          <SelectBoxObject
                            propValue="id"
                            propLabel="id"
                            options={typeWeight}
                            value={
                              liftComplexScore(idx)[`${prefix}typeWeight`] || defaultWeightType.id
                            }
                            setValue={(v) =>
                              changeCurrentScores({
                                [`${prefix}liftComplex`]: {
                                  ...(currentEditScores[`${prefix}liftComplex`] || {}),
                                  [idx]: { ...liftComplexScore(idx), [`${prefix}typeWeight`]: v },
                                },
                              })
                            }
                          />
                        </Box>
                      </Box>
                    </Box>
                  ))}

                  {!workout.isCompetition && (
                    <Box mt={1}>
                      <TextAreaFieldRounded
                        onChange={(v) => changeCurrentScores({ [`${prefix}roundNotes`]: v })}
                        value={currentEditScores[`${prefix}roundNotes`] || ''}
                        label={t('workout.section.label.notes')}
                        placeholder={t('workout.section.label.notes')}
                      />
                    </Box>
                  )}
                </Box>
              )}
              {hasScore(scoresTypes, 'rounds') && (
                <Box className={classes.boxScore}>
                  <TextFieldRounded
                    onChange={(v) => changeCurrentScores({ [`${prefix}rounds`]: v })}
                    value={currentEditScores[`${prefix}rounds`] || ''}
                    label={t('workout.section.label.score.rounds')}
                  />
                  {!workout.isCompetition && (
                    <Box mt={1}>
                      <TextAreaFieldRounded
                        onChange={(v) => changeCurrentScores({ [`${prefix}roundNotes`]: v })}
                        value={currentEditScores[`${prefix}roundNotes`] || ''}
                        label={t('workout.section.label.notes')}
                        placeholder={t('workout.section.label.notes')}
                      />
                    </Box>
                  )}
                </Box>
              )}
              {hasScore(scoresTypes, 'roundReps') && (
                <Box className={classes.boxScore}>
                  <Box display="flex" alignItems="flex-end">
                    <TextFieldRounded
                      onChange={(v) => changeCurrentScores({ [`${prefix}roundRepsRounds`]: v })}
                      value={currentEditScores[`${prefix}roundRepsRounds`] || ''}
                      label={t('workout.section.label.score.rounds')}
                    />
                    <Typography mx={1} mb={2}>
                      +
                    </Typography>
                    <TextFieldRounded
                      onChange={(v) => changeCurrentScores({ [`${prefix}roundRepsReps`]: v })}
                      value={currentEditScores[`${prefix}roundRepsReps`] || ''}
                      label={t('workout.section.label.score.reps')}
                    />
                  </Box>
                  {!workout.isCompetition && (
                    <Box mt={1}>
                      <TextAreaFieldRounded
                        onChange={(v) => changeCurrentScores({ [`${prefix}roundRepsNotes`]: v })}
                        value={currentEditScores[`${prefix}roundRepsNotes`] || ''}
                        label={t('workout.section.label.notes')}
                        placeholder={t('workout.section.label.notes')}
                      />
                    </Box>
                  )}
                </Box>
              )}
              {hasScore(scoresTypes, 'reps') && (
                <Box className={classes.boxScore}>
                  <TextFieldRounded
                    onChange={(v) => changeCurrentScores({ [`${prefix}reps`]: v })}
                    value={currentEditScores[`${prefix}reps`] || ''}
                    label={t('workout.section.label.score.reps')}
                  />

                  {!workout.isCompetition && (
                    <Box mt={1}>
                      <TextAreaFieldRounded
                        onChange={(v) => changeCurrentScores({ [`${prefix}repsNotes`]: v })}
                        value={currentEditScores[`${prefix}repsNotes`] || ''}
                        label={t('workout.section.label.notes')}
                        placeholder={t('workout.section.label.notes')}
                      />
                    </Box>
                  )}
                </Box>
              )}
              {hasScore(scoresTypes, 'weight') && (
                <Box className={classes.boxScore}>
                  <Box display="flex">
                    <TextFieldRounded
                      onChange={(v) =>
                        changeCurrentScores({
                          [`${prefix}weight`]: v,
                          [`${prefix}typeWeight`]:
                            currentEditScores[`${prefix}typeWeight`] || defaultWeightType?.id,
                        })
                      }
                      value={currentEditScores[`${prefix}weight`] || ''}
                      label={t('workout.section.label.score.weight')}
                    />
                    <Box ml={1}>
                      <SelectBoxObject
                        propValue="id"
                        propLabel="id"
                        label="&nbsp;"
                        options={typeWeight}
                        value={currentEditScores[`${prefix}typeWeight`] || defaultWeightType.id}
                        setValue={(v) => changeCurrentScores({ [`${prefix}typeWeight`]: v })}
                      />
                    </Box>
                  </Box>
                  {!workout.isCompetition && (
                    <Box mt={1}>
                      <TextAreaFieldRounded
                        onChange={(v) => changeCurrentScores({ [`${prefix}weightNotes`]: v })}
                        value={currentEditScores[`${prefix}weightNotes`] || ''}
                        label={t('workout.section.label.notes')}
                        placeholder={t('workout.section.label.notes')}
                      />
                    </Box>
                  )}
                </Box>
              )}
              {hasScore(scoresTypes, 'calories') && (
                <Box className={classes.boxScore}>
                  <TextFieldRounded
                    onChange={(v) => changeCurrentScores({ [`${prefix}calories`]: v })}
                    value={currentEditScores[`${prefix}calories`] || ''}
                    label={t('workout.section.label.score.calories')}
                  />
                  {!workout.isCompetition && (
                    <Box mt={1}>
                      <TextAreaFieldRounded
                        onChange={(v) => changeCurrentScores({ [`${prefix}caloriesNotes`]: v })}
                        value={currentEditScores[`${prefix}caloriesNotes`] || ''}
                        label={t('workout.section.label.notes')}
                        placeholder={t('workout.section.label.notes')}
                      />
                    </Box>
                  )}
                </Box>
              )}
              {hasScore(scoresTypes, 'distance') && (
                <Box className={classes.boxScore}>
                  <Box display="flex">
                    <TextFieldRounded
                      onChange={(v) =>
                        changeCurrentScores({
                          [`${prefix}distance`]: v,
                          [`${prefix}typeDistance`]:
                            currentEditScores[`${prefix}typeDistance`] || defaultDistanceType.id,
                        })
                      }
                      value={currentEditScores[`${prefix}distance`] || ''}
                      label={t('workout.section.label.score.distance')}
                    />
                    <Box ml={1}>
                      <SelectBoxObject
                        propValue="id"
                        propLabel="id"
                        label="&nbsp;"
                        options={typeDistance}
                        value={currentEditScores[`${prefix}typeDistance`] || defaultDistanceType.id}
                        setValue={(v) => changeCurrentScores({ [`${prefix}typeDistance`]: v })}
                      />
                    </Box>
                  </Box>
                  {!workout.isCompetition && (
                    <Box mt={1}>
                      <TextAreaFieldRounded
                        onChange={(v) => changeCurrentScores({ [`${prefix}distanceNotes`]: v })}
                        value={currentEditScores[`${prefix}distanceNotes`] || ''}
                        label={t('workout.section.label.notes')}
                        placeholder={t('workout.section.label.notes')}
                      />
                    </Box>
                  )}
                </Box>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

ScoreFields.defaultProps = {
  scoresTypes: [],
  prefix: '',
};

export default ScoreFields;
