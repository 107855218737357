import React, { useState } from 'react';
import Box from 'core/ui/Box';
import FormSection from 'components/Workout/Form/Section/FormSection';
import { Container, Dialog } from '@material-ui/core';
import Texture from 'core/ui/Texture';
import Paper from 'core/ui/Paper';
import Typography from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import { dateExt, time } from 'core/utils/formatters';
import BenchmarkOptions from 'components/Workout/Form/Section/BenchmarkOptions';
import { convertTimerToForm } from 'core/utils/helpers';
import HistoryOptions from 'components/Workout/Form/Section/HistoryOptions';

const ModalSection = ({ modalSection, setModalSection, channel, callbackSave }) => {
  const { t } = useTranslation();
  const [modal, setModal] = useState(false);
  return (
    <Dialog
      open={modalSection?.open}
      maxWidth="lg"
      fullWidth
      scroll="body"
      onClose={() => setModalSection({ open: false })}
    >
      <Texture>
        <Container>
          {modal && (
            <Dialog fullWidth maxWidth="sm" open={modal}>
              <Box p={3}>
                {modal?.sections?.map((item) => (
                  <Box
                    key={item?.id}
                    className="link-hover"
                    p={2}
                    onClick={() => {
                      setModalSection((prev) => ({
                        ...prev,
                        currentSection: {
                          section: item,
                          timerData: convertTimerToForm(item.timerData),
                        },
                      }));
                      setModal(false);
                    }}
                  >
                    <Typography>{item?.title}</Typography>
                  </Box>
                ))}
                <Typography>{t('workout.noSections')}</Typography>
              </Box>
            </Dialog>
          )}{' '}
          <Box p={3} py={5}>
            <Paper className="paper-rounded" p={3} mb={3}>
              <BenchmarkOptions
                isCompetition={false}
                mode="new"
                saveSection={(sec) => {
                  setModalSection((prev) => ({
                    ...prev,
                    currentSection: { section: sec, timerData: convertTimerToForm(sec.timerData) },
                  }));
                }}
                setValue={() => {}}
                sections={[]}
              />
            </Paper>

            <Paper className="paper-rounded" p={3} mb={3}>
              <HistoryOptions
                setCurrentSection={(sec) => setModalSection({ open: true, currentSection: sec })}
                chooseSection={(sections) => setModal({ open: true, sections })}
                sections={[]}
                channel={channel}
              />
            </Paper>

            <FormSection
              sections={[]}
              currentSection={modalSection?.currentSection}
              setSections={() => {}}
              channelId={channel?.id}
              showSaveButton
              closeModalSection={() => {
                setModalSection({ open: false });
              }}
              callbackSave={callbackSave}
              programmingClassesContent={
                <Paper p={3}>
                  <Typography variant="h5" mb={3}>
                    {t('label.classes')}
                  </Typography>
                  {modalSection?.workouts?.map((w) => (
                    <Box key={w.id} display="flex" alignItems="center">
                      <Typography>
                        {w?.id}&nbsp;-&nbsp;{w?.name}&nbsp;-&nbsp;
                      </Typography>
                      <Typography>
                        <Typography color="primary" component="span" variant="body1">
                          <span>
                            {dateExt(w.datetime, t, channel?.useUserTimezone ? null : w?.timezone)}
                          </span>
                          <span>&nbsp;&bull;&nbsp;</span>
                          <span className="roboto-regular">
                            {time(w.datetime, channel?.useUserTimezone ? null : w?.timezone)}
                          </span>
                        </Typography>
                      </Typography>
                    </Box>
                  ))}
                </Paper>
              }
            />
          </Box>
        </Container>
      </Texture>
    </Dialog>
  );
};

export default ModalSection;

ModalSection.defaultProps = {};
