import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { DialogActions, DialogContent, Link } from '@material-ui/core';
import { ButtonPrimary } from 'core/ui/Button';
import { useTranslation } from 'react-i18next';
import Texture from 'core/ui/Texture';
import { redColor } from 'core/ui/Colors';
import { useDispatch } from 'react-redux';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import notifications from 'modules/notifications';
import EventInfo from 'components/ChannelsEvents/EventInfo';

const ModalEventInfo = ({ open, channel, eventId, close, setModalEventForm }) => {
  const { t } = useTranslation();
  const [event, setEvent] = useState(null);
  const { request } = useActions();
  const dispatch = useDispatch();

  const getEvent = () => {
    request({
      action: Modules.channelsEvents.actions.show,
      data: {
        channelId: channel?.id,
        id: eventId,
      },
      options: {
        onSuccess: (data) => {
          setEvent(data);
        },
        onError: (error) => {
          dispatch(notifications.error(error));
        },
      },
    });
  };

  useEffect(() => {
    if (eventId) {
      getEvent();
    }
  }, [eventId]);

  return (
    <Dialog open={open} onClose={close} maxWidth="md" fullWidth scroll="body">
      <Texture>
        <DialogContent>
          <EventInfo event={event} />
        </DialogContent>
      </Texture>
      <DialogActions>
        <div className="tw-flex tw-flex-row tw-items-center tw-gap-2">
          {channel?.isCoach && setModalEventForm && (
            <div className="tw-flex tw-flex-row tw-gap-2">
              <ButtonPrimary
                onClick={() => {
                  close();
                  setModalEventForm({ open: true, eventId: event?.id });
                }}
              >
                {t('button.edit')}
              </ButtonPrimary>
            </div>
          )}

          <div>
            <Link
              className="link"
              href={`/channel/events/${channel?.id}/view/${event?.id}`}
              target="_blank"
            >
              <ButtonPrimary>{t('button.view')}</ButtonPrimary>
            </Link>
          </div>
          <div>
            <ButtonPrimary buttonColor={redColor} onClick={close}>
              {t('button.close')}
            </ButtonPrimary>
          </div>
        </div>
      </DialogActions>
    </Dialog>
  );
};

ModalEventInfo.defaultProps = {
  close: () => {},
  userId: null,
};

export default ModalEventInfo;
