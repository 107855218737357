/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import NoSsr from '@material-ui/core/NoSsr';
import CloseIcon from '@material-ui/icons/Close';

import styled from 'styled-components';
import { languageSelectors, languageActions } from 'modules/language';
import { useSelector, useDispatch } from 'react-redux';
import { InputBase } from '@material-ui/core';
import Box from '../Box';

const InputWrapper = styled('div')`
  width: 100%;
  background-color: #fff;
  padding: 4px;
  display: flex;
  flex-wrap: wrap;
  border-radius: 5px;

  & input {
    font-size: 12px;
    height: 30px;
    box-sizing: border-box;
    padding: 4px 6px;
    border-radius: 5px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
`;

const Tag = styled(({ label, onDelete, ...props }) => (
  <div {...props}>
    <span>{label}</span>
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      className="change-icon"
      style={{ background: '#404041', height: 30, width: 25 }}
    >
      <CloseIcon style={{ color: 'white', fontSize: 14 }} onClick={onDelete} />
    </Box>
  </div>
))`
  display: flex;
  align-items: center;
  margin: 4px;
  background-color: #bbbdbf;
  border-radius: 5px;
  box-sizing: content-box;
  outline: 0;
  overflow: hidden;
  padding-left: 5px;
  font-family: Roboto;
  font-weight: 500;
  font-size: 12px;

  &:focus {
    border-color: #40a9ff;
    background-color: #e6f7ff;
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 10px;
  }

  & svg {
    font-size: 12px;
    cursor: pointer;
    padding: 4px;
  }
`;

const Listbox = styled('ul')`
  padding: 0;
  list-style: none;
  overflow: auto;
  max-height: 200px;
  max-width: 200px;
  background: #fff;
  z-index: 1000;

  & li {
    padding: 5px 12px;
    display: flex;
    font-size: 12px;
    font-family: Roboto;
    font-weight: 500;

    & span {
      flex-grow: 1;
    }
  }

  & li[aria-selected='true'] {
    background-color: #fafafa;
    color: #ec4242;
    & svg {
      color: #414042;
    }
  }

  & li[data-focus='true'] {
    color: #ec4242;
    cursor: pointer;

    & svg {
      color: #ec4242;
    }
  }
`;

const styleBoxList = {
  maxHeight: 250,
  position: 'absolute',
  backgroundColor: '#fff',
  padding: 10,
  borderRadius: '20px 20px 20px 20px',
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
  zIndex: 2000,
};

export default function LanguagesChosen({ setValue, error, placeholder, defaultValue }) {
  const languages = useSelector((state) => languageSelectors.getLanguages(state));
  const dispatch = useDispatch();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    dispatch(languageActions.getLanguagesStart());
  }, [dispatch]);

  useEffect(() => {
    if (defaultValue) {
      setValue([...defaultValue]);
    }
  }, [defaultValue]);

  const change = (evt, data) => {
    setValue(data);
  };

  const [left, setLeft] = useState(0);
  const wRef = React.createRef();

  const {
    getRootProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl,
    anchorEl,
  } = useAutocomplete({
    defaultValue: [...defaultValue],
    multiple: true,
    // groupBy: (option) => option.group,
    freeSolo: false,
    options,
    onChange: change,
    getOptionLabel: (option) => option.name,
  });
  const { innerWidth: width } = window;
  const widthElement = 250;

  useEffect(() => {
    setOptions(languages.filter((x) => value.filter((y) => y.id === x.id).length === 0));
  }, [languages, value]);

  useEffect(() => {
    let mLeft = anchorEl ? anchorEl.offsetLeft : 0;
    const overflow = mLeft + widthElement > width;
    const widthWrapper = wRef.current ? wRef.current.clientWidth : 0;
    const offL = wRef.current ? wRef.current.offsetLeft : 0;
    if (overflow) {
      mLeft = offL + widthWrapper - widthElement;
    }
    setLeft(mLeft);
  }, [anchorEl, value, width, wRef]);

  useEffect(() => {
    setTimeout(() => {
      setValue([...value]);
    }, 500);
  }, [error, setValue, value]);

  return (
    <NoSsr>
      <div>
        <div
          style={{ borderRadius: 5 }}
          className={`${error && value.length === 0 ? 'input-paper-error' : ''}`}
          {...getRootProps()}
        >
          <InputWrapper ref={wRef} className={focused ? 'focused' : ''}>
            {value.map((option, index) => (
              <Tag label={option.name || option} {...getTagProps({ index })} />
            ))}
            <Box display="flex" flexGrow="1" ref={setAnchorEl} alignItems="center">
              <InputBase
                style={{ minWidth: 30, width: '100%' }}
                className="fontInput"
                placeholder={value.length <= 0 ? placeholder : ''}
                {...getInputProps()}
              />
            </Box>
          </InputWrapper>
        </div>
        {groupedOptions.length > 0 ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ ...styleBoxList, left }}
          >
            <Listbox style={{ minWidth: 150 }} {...getListboxProps()}>
              {groupedOptions.map((option, index) => (
                <li {...getOptionProps({ option, index })}>
                  <span>&nbsp;{option.name}</span>
                </li>
              ))}
            </Listbox>
          </Box>
        ) : null}
      </div>
    </NoSsr>
  );
}

LanguagesChosen.propTypes = {
  setValue: PropTypes.func,
  placeholder: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  defaultValue: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  error: PropTypes.object,
};

LanguagesChosen.defaultProps = {
  setValue: null,
  error: null,
  placeholder: '',
  defaultValue: [],
};
