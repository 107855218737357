import React, { useState } from 'react';
import Typography from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import { ButtonPrimary } from 'core/ui/Button';
import { usersActions } from 'modules/users';
import { useDispatch, useSelector } from 'react-redux';
import { sessionSelectors } from 'modules/session';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { contractActions } from 'modules/contract';
import { primary } from 'core/ui/Colors';
import { useHistory } from 'react-router';

export default () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state) => sessionSelectors.getUser(state));
  const history = useHistory();
  const [acceptContract, setAcceptContract] = useState(false);

  const saveUser = (role) => {
    dispatch(
      usersActions.putUpdateUserStart(
        {
          id: user.id,
          user: { role },
        },
        () => {
          history.push('/profile/edit');
        },
      ),
    );
  };

  const acceptContractTerms = () => {
    if (acceptContract) {
      dispatch(contractActions.rejectContractStart({ contractName: 'terms_of_use' }));
    } else {
      dispatch(contractActions.acceptContractStart({ contractName: 'terms_of_use' }));
    }
    setAcceptContract(!acceptContract);
  };

  return (
    <Box>
      <Typography variant="h4" color="primary" mt={5} mb={5} align="center">
        {t('login.createAccount')}
      </Typography>

      <>
        <Box>
          <Typography variant="caption" component="h6" align="center" mb={1}>
            {t('login.selectTypeAccount')}
          </Typography>

          <Box pl={5} pr={5} pb={1} pt={10}>
            <ButtonPrimary
              onClick={() => saveUser('athlete')}
              fullWidth
              disabled={!acceptContract}
              variant="contained"
            >
              {t('account.type.athlete').toUpperCase()}
            </ButtonPrimary>
          </Box>
          <Box pl={5} pr={5} pt={1} pb={5}>
            <ButtonPrimary
              disabled={!acceptContract}
              onClick={() => saveUser('coach')}
              fullWidth
              variant="contained"
            >
              {t('account.type.coach').toUpperCase()}
            </ButtonPrimary>
          </Box>
        </Box>

        <Box display="flex" justifyContent="center">
          <FormControlLabel
            value={acceptContract}
            control={<Checkbox color="primary" />}
            label={
              <Typography>
                {t('message.contract.i_agree_to')}&nbsp;
                <a
                  href="https://streamfit.com/terms-of-use/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link-hover"
                  style={{ color: primary }}
                >
                  {t('message.contract.terms_of_use')}
                </a>
              </Typography>
            }
            onChange={() => acceptContractTerms()}
            labelPlacement="end"
          />
        </Box>
      </>
    </Box>
  );
};
