import React, { useState } from 'react';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import { ButtonPrimary } from 'core/ui/Button';
import Messages from '../Messages';
import ModalFormMessage from '../Form/Modal';
import EngageBanner from './EngageBanner';

export default ({ channel }) => {
  const { t } = useTranslation();
  const [modalMessage, setModalMessage] = useState({ open: false });

  return (
    <Box p={3}>
      <Box>
        <EngageBanner />
      </Box>

      <Box display="flex" justifyContent="flex-end" m={3}>
        <ButtonPrimary onClick={() => setModalMessage({ open: true })}>
          {t('button.sendNewMessage')}
        </ButtonPrimary>
      </Box>

      {modalMessage?.open && (
        <ModalFormMessage
          open={modalMessage?.open}
          close={() => setModalMessage({ open: false })}
          ownerableId={channel?.id}
          ownerableType="Channel"
          message={modalMessage?.message}
          callback={() => setModalMessage({ open: false })}
          automated
        />
      )}

      <Box my={3}>
        <Messages ownerableId={channel?.id} ownerableType="Channel" template />
      </Box>
    </Box>
  );
};
