import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import InputPhone from 'core/ui/InputPhone';
import Divider from 'core/ui/Divider';
import RegistrationCustomFields from '../CustomFields';
import ChildFields from '../ChildFields';

export default function Form({ row, competition, changeInvite, hideCustomFields }) {
  const { t } = useTranslation();

  return (
    <>
      <Box>
        <Box mb={3}>
          <TextFieldRounded
            label="Name"
            style={{ fontSize: 14, minWidth: 150, fontFamily: 'Roboto' }}
            name="name"
            value={row.name || ''}
            onChange={(n) => changeInvite(row, 'name', n)}
            placeholder={t('placeholder.name').toUpperCase()}
            bordered
          />
        </Box>
        <Box mb={3}>
          <TextFieldRounded
            label="Email"
            style={{ fontSize: 14, minWidth: 200, fontFamily: 'Roboto' }}
            name="email"
            value={row.email || ''}
            onChange={(n) => changeInvite(row, 'email', n)}
            placeholder={t('placeholder.email').toUpperCase()}
            bordered
          />
        </Box>
        <Box mb={3}>
          <InputPhone
            label="Phone"
            value={row.phone}
            name="phone"
            setValue={(n) => changeInvite(row, 'phone', n?.number)}
            placeholder="(55) 555-5555"
            bordered
          />
        </Box>
      </Box>
      <Box>
        {!hideCustomFields && !competition?.online && (
          <ChildFields
            child={row?.child}
            setChild={(v) => changeInvite(row, 'child', v)}
            childName={row?.childName}
            setChildName={(v) => changeInvite(row, 'childName', v)}
          />
        )}

        {!hideCustomFields && competition?.customFields?.length > 0 && (
          <>
            <Divider m={4} />
            <RegistrationCustomFields
              competition={competition}
              customFields={row.customFields || []}
              setCustomFields={(v) => changeInvite(row, 'customFields', v)}
            />
          </>
        )}
      </Box>
    </>
  );
}
