import React, { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import { EditorState, convertToRaw, convertFromRaw, Modifier } from 'draft-js';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Paper from '../Paper';
import Typography, { fontStyle } from '../Typography';
import Box from '../Box';
import SelectBox from '../SelectBox';
import useS3Upload from './useS3Upload';

export const defaultCkEditorText = (text) => ({
  entityMap: {},
  blocks: [
    {
      key: '637gr',
      text,
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
  ],
});

const KeyworkdsOptions = ({ onChange, editorState, options }) => {
  const addKey = (v) => {
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      v,
      editorState.getCurrentInlineStyle(),
    );
    onChange(EditorState.push(editorState, contentState, 'insert-characters'));
  };

  return (
    <SelectBox
      placeholder="Keyshorts"
      setValue={(v) => addKey(v)}
      options={options || []}
      bordered
      emptyItem
      value={0}
      emptyValue={0}
      style={{ minWidth: 100 }}
    />
  );
};

function CKEditor({
  error,
  label,
  required,
  defaultValue,
  onChange,
  editorStyle,
  keywordOptions,
  allowUploads,
  pathUpload,
  filesPrefix,
}) {
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

  const { startUploadFile } = useS3Upload({ filePrefix: filesPrefix, path: pathUpload });

  const toolbar = {
    list: { inDropdown: true },
    inline: {
      inDropdown: false,
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
      options: ['bold', 'italic', 'underline'],
    },
    fontSize: {},
  };

  if (allowUploads) {
    toolbar.image = {
      uploadEnabled: true,
      previewImage: true,
      uploadCallback: (file) =>
        new Promise((resolve, reject) => {
          startUploadFile(file)
            .then((data) => {
              if (data.Location) {
                const url = data.Location;

                resolve({ data: { link: url }, link: { url } });
              } else {
                alert('Missing Location');
                reject(new Error('Missing Location'));
              }
            })
            .catch((err) => {
              alert(err);

              reject(err);
            });
        }),
      alt: { present: true, mandatory: false },
    };
  }

  useEffect(() => {
    if (defaultValue) {
      const two = { ...defaultValue };

      const blocks = two?.blocks?.map((x) => ({ ...x }));

      const raw = convertFromRaw({
        blocks: [...blocks],
        entityMap: two?.entityMap ? { ...two?.entityMap } : {},
      });
      setEditorState(EditorState.createWithContent(raw));
    }
  }, []);

  return (
    <>
      {label !== '' && (
        <Box ml={2} mb={2} display="flex">
          <Typography style={fontStyle.label} variant="body1">
            {label}
          </Typography>
          {required && (
            <Typography ml={1} color="error">
              *
            </Typography>
          )}
        </Box>
      )}

      <Paper className={`roboto-regular ${error ? 'input-paper-error' : ''}`}>
        <Editor
          editorState={editorState}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          onEditorStateChange={(state) => {
            setEditorState(state);
            const raw = convertToRaw(state?.getCurrentContent());
            const html = draftToHtml(raw);
            onChange({
              html,
              raw: JSON.stringify(raw),
            });
          }}
          editorStyle={{
            resize: 'both',
            height: 300,
            padding: 3,
            paddingLeft: 5,
            borderRadius: 5,
            ...(editorStyle || {}),
          }}
          // mention={{
          //   separator: ' ',
          //   trigger: '$',
          //   suggestions: [{ text: 'First Name', value: '{first_name}' }],
          // }}
          toolbarCustomButtons={
            keywordOptions ? [<KeyworkdsOptions options={keywordOptions} />] : null
          }
          toolbar={toolbar}
        />
      </Paper>
    </>
  );
}

export default CKEditor;
