/* eslint-disable react/prop-types */
import React from 'react';
import Paper from 'core/ui/Paper';
import { channelCurrency } from 'core/utils/formatters';
import Typography from 'core/ui/Typography';
import Box from 'core/ui/Box';
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import ProductOption from 'components/Store/Products/Form/ProductOption';

export default function StoreOrderItems({ items, currency }) {
  return (
    <>
      {items?.length > 0 && (
        <Paper m={3} p={3}>
          <Typography color="primary" align="center" m={1}>
            Items
          </Typography>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Table size="small">
              <TableBody>
                {items &&
                  items.map((storeItem, idx) => (
                    <TableRow key={idx.toString()} display="flex">
                      <TableCell>
                        {storeItem.storeProductInventory?.storeProduct?.name}
                        <ProductOption option={storeItem.storeProductInventory} />
                      </TableCell>

                      <TableCell>
                        <Typography>{storeItem.qty}</Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography noWrap>{channelCurrency(storeItem.total, currency)}</Typography>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Box>
        </Paper>
      )}
    </>
  );
}
