/* eslint-disable react/prop-types */
import React from 'react';

import Box from 'core/ui/Box';
import Video from 'core/ui/Video';
import Typography, { fontStyle } from 'core/ui/Typography';
import { isSafari } from 'react-device-detect';
import { makeStyles } from '@material-ui/core';
import useWindowSize from 'core/utils/hook/useWindowSize';
import { drawerStreamWidth } from 'pages/Stream';
import { useSelector } from 'react-redux';
import { roomSelectors } from 'modules/room';
import useLayoutGrid from '../Layout/useLayoutGrid';
import LiveUsersGallery from '../LiveUsersGallery';

const useStyles = makeStyles(() => ({
  boxName: {
    position: 'relative',
    marginTop: -23,
    marginLeft: 0,
    background: 'rgba(24,24,24,0.54)',
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
  },
}));

export default ({
  openDrawer,
  getSize,
  playingRecordings,
  setTime,
  time,
  videos,
  mutedRecordings,
  setVideoReady,
}) => {
  const classes = useStyles();
  const participants = useSelector((state) => roomSelectors.getParticipants(state));

  const videoCount = videos ? videos.length + participants.length + 1 : 1;

  const size = useWindowSize();
  const { heightBox, colsSize, widthBox } = useLayoutGrid({
    maxHeight: size.height - 150,
    maxWidth: size.width - 100 - (openDrawer ? drawerStreamWidth + 100 : 100),
    videoCount,
  });

  const styleBox = {
    width: widthBox,
    height: heightBox,
    borderRadius: 5,
    overflow: 'hidden',
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        className="gallery"
        style={{ maxWidth: `calc(${widthBox} * ${colsSize})` }}
      >
        <LiveUsersGallery
          showLiveIcon
          style={{
            ...styleBox,
            height: `${getSize(null, widthBox, true).height}px`,
          }}
        />
        {videos
          .filter((x) => !isSafari || x.isCoach)
          .map((rec, index) => (
            <Box
              key={index.toString()}
              style={{
                ...styleBox,
              }}
            >
              <Box
                flex={1}
                m={0.5}
                className="video-container"
                style={{ height: heightBox, width: '100%', borderRadius: 5 }}
              >
                <Video
                  playing={playingRecordings}
                  src={rec.vimeoUrl}
                  muted={mutedRecordings}
                  vimeo={!rec.video}
                  upload={!!rec.video}
                  full
                  height={100}
                  showPlayButton={false}
                  seek={time.seek}
                  onReady={() => setVideoReady({ url: rec.vimeoUrl })}
                  onSeek={(v) =>
                    rec.isCoach && playingRecordings
                      ? setTime((prev) => ({ ...prev, complete: v }))
                      : null
                  }
                  borderRadius={10}
                />
                <Box className={classes.boxName}>
                  <Typography p={1} style={{ ...fontStyle.small, color: 'white' }}>
                    {rec.user.name}
                  </Typography>
                </Box>
              </Box>
            </Box>
          ))}
      </Box>
    </Box>
  );
};
