/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import Paper from 'core/ui/Paper';
import Typography from 'core/ui/Typography';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import { IconButton } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import useComments from 'modules/comments/hook';
import Scrollbars from 'react-custom-scrollbars';
import LoaderSm from 'core/ui/LoaderSm';
import ItemComment from './Item';
import useStylesComments from './styles';

export default ({
  typeId,
  type,
  kind,
  title,
  hideTitle,
  paperClass,
  fontColor,
  placeholder,
  buttonColor,
  hideLikes,
}) => {
  const classes = useStylesComments();
  const { t } = useTranslation();
  const [message, setMessage] = useState('');
  const scrollRef = useRef();

  const scrollToTop = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop(scrollRef.current.getScrollHeight());
    }
  };

  const { loading, addComment, localComments, getComments } = useComments({
    type,
    typeId,
    kind,
  });

  useEffect(() => {
    if (type && typeId) {
      getComments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeId, type, kind]);

  useEffect(() => {
    setTimeout(() => {
      scrollToTop();
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localComments]);

  const sendComment = () => {
    if (!message) {
      return;
    }
    addComment(message);
    setTimeout(() => {
      setMessage('');
    }, 300);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      sendComment();
      return;
    }

    if (event.keyCode === 13 && event.shiftKey) {
      if (event.type === 'keypress') {
        setMessage((prev) => `${prev}\n`);
      }
      event.preventDefault();
    }
  };

  return (
    <Paper
      className={`paper-rounded ${paperClass}`}
      style={{ height: '100%', minHeight: 300, flex: 1, position: 'relative' }}
      pb={23}
    >
      {!hideTitle && (
        <Typography pt={2} pl={3} mb={3} color="secondary" variant="h4">
          {title || t('comments')}
        </Typography>
      )}

      <Scrollbars
        ref={scrollRef}
        renderTrackHorizontal={(props) => (
          <div {...props} style={{ display: 'none' }} className="track-horizontal" />
        )}
        autoHide
        autoHeightMin={300}
        style={{
          borderRadius: 15,
        }}
      >
        <Box display="flex" flexDirection="column" justifyContent="flex-end" flex={1}>
          <Box display="flex" flexGrow={1} flexDirection="column" className={classes.boxChat}>
            <Box className={classes.boxComments}>
              {localComments &&
                localComments.map((comment) => (
                  <Box key={comment.id}>
                    <ItemComment
                      comment={comment}
                      fontColor={fontColor}
                      buttonColor={buttonColor}
                      hideLikes={hideLikes}
                    />
                  </Box>
                ))}
            </Box>
          </Box>
        </Box>
      </Scrollbars>

      <Box px={3} display="flex" alignItems="center" className={classes.boxInput}>
        <Box flexGrow={1}>
          <TextFieldRounded
            onChange={(v) => setMessage(v)}
            value={message}
            placeholder={placeholder || t('placeholder.addComment')}
            onKeyPress={handleKeyPress}
          />
        </Box>
        <Box pl={3}>
          <IconButton onClick={() => sendComment()}>
            {loading ? <LoaderSm loading /> : <SendIcon style={{ color: 'white', fontSize: 30 }} />}
          </IconButton>
        </Box>
      </Box>
    </Paper>
  );
};
