import React from 'react';

import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Box from 'core/ui/Box';
import Typography from 'core/ui/Typography';
import { secondaryXLight } from 'core/ui/Colors';
import { Button, ButtonGroup } from '@material-ui/core';
import { ButtonPrimary } from 'core/ui/Button';

export default ({ changeQty, qty, hideNext }) => (
  <Box>
    <ButtonGroup size="medium" color="secondary" aria-label="medium secondary button group">
      <ButtonPrimary
        style={{ padding: 0 }}
        buttonColor={secondaryXLight}
        onClick={() => changeQty(-1)}
      >
        <RemoveIcon />
      </ButtonPrimary>
      <Button>
        <Box flexGrow={1}>
          <Typography>{qty}</Typography>
        </Box>
      </Button>
      {!hideNext && (
        <ButtonPrimary
          style={{ padding: 0 }}
          buttonColor={secondaryXLight}
          onClick={() => changeQty(1)}
        >
          <AddIcon />
        </ButtonPrimary>
      )}
    </ButtonGroup>
  </Box>
);
