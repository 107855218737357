const deCurrency = (value) => {
  if (!value) {
    return 0;
  }
  const isNegative = value.indexOf('-') > -1 && value.indexOf('+') === -1;

  return (
    [
      isNegative ? '-' : '',
      ...value
        .replace('$', '')
        .replace(/\+/g, '')
        .replace(/-/g, '')
        .replace(/,/g, '')
        .replace(/\./g, '')
        .split(''),
    ].join('') / 100
  );
};

const currency = (number) => {
  if (number === undefined || number === null) {
    return '';
  }
  const parsedNumber = Number.isNaN(parseFloat(number)) ? 0 : number;

  const value = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(parsedNumber);

  return value.replace(/[$]/g, '');
};

const currencyEdit = (number) => currency(number).replace(/,/g, '');

const channelCurrency = (number, chCurrency) => {
  if (chCurrency) {
    return `${chCurrency?.symbol}${currency(number)} ${chCurrency?.code}`;
  }
  return currency(number);
};

export default currency;

export { deCurrency, currencyEdit, channelCurrency };
