import React, { useContext, useRef } from 'react';
import Box from 'core/ui/Box';
import { ButtonPrimary } from 'core/ui/Button';
import { useTranslation } from 'react-i18next';

import { ButtonGroup, Paper, MenuList, MenuItem, Popper } from '@material-ui/core';
import { ArrowDropDown, Edit, Favorite, PersonAdd, Send } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { sessionSelectors } from 'modules/session';
import CalculatorIcon from 'core/ui/MIcon/CalculatorIcon';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { LayoutContext } from '../../../../pages/shared/Layout';

const MenuCustomWorkout = ({ actions, workout }) => {
  const { t } = useTranslation();
  const layoutContext = useContext(LayoutContext);
  const isAuthenticated = useSelector((state) => sessionSelectors.getIsAuthenticated(state));
  const currentUser = useSelector((state) => sessionSelectors.getUser(state));
  const [open, setOpen] = React.useState(false);
  const anchorRef = useRef();

  const openLogin = () => {
    layoutContext.setModalLogin({ open: true });
  };

  const isOwner = currentUser.id === workout?.ownerId;

  return (
    <Box>
      <Box>
        <ButtonGroup variant="contained" color="primary" ref={anchorRef} aria-label="split button">
          <ButtonPrimary ref={anchorRef} onClick={() => setOpen(!open)}>
            Actions <ArrowDropDown />
          </ButtonPrimary>
        </ButtonGroup>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          style={{ zIndex: 9999 }}
          disablePortal
        >
          <Box>
            <Paper>
              <MenuList id="split-button-menu">
                {workout.method === 'custom' && !workout.isOwner && !workout.registered && (
                  <MenuItem
                    onClick={() => {
                      if (isAuthenticated) {
                        actions.register(workout);
                      } else {
                        openLogin();
                      }
                      setOpen(false);
                    }}
                  >
                    <PersonAdd style={{ fontSize: 14 }} />
                    &nbsp;
                    {t('button.addWorkout')}
                  </MenuItem>
                )}
                <MenuItem
                  onClick={() => {
                    actions.viewWorkout(workout);
                    setOpen(false);
                  }}
                >
                  <VisibilityIcon style={{ fontSize: 14 }} />
                  &nbsp;
                  {t('button.view')}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    actions.calculator(workout);
                    setOpen(false);
                  }}
                >
                  <CalculatorIcon size={16} />
                  &nbsp;
                  {t('button.calculator')}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    if (isAuthenticated) {
                      actions.toggleFavorite(workout);
                    } else {
                      openLogin();
                    }
                    setOpen(false);
                  }}
                >
                  <Favorite style={{ fontSize: 14 }} />
                  &nbsp;
                  {t(workout?.favorited ? 'button.unfavorite' : 'button.favorite')}
                </MenuItem>
                {/* <MenuItem
                      onClick={() => {
                        actions.share(workout);
                        setOpen(false);
                      }}
                    >
                      <Share style={{ fontSize: 14 }} />
                      &nbsp;
                      {t('button.share')}
                    </MenuItem> */}

                {(workout?.method !== 'custom' || isOwner) && (
                  <MenuItem
                    onClick={() => {
                      if (isAuthenticated) {
                        actions.invite(workout);
                      } else {
                        openLogin();
                      }
                      setOpen(false);
                    }}
                  >
                    <Send style={{ fontSize: 14 }} />
                    &nbsp;
                    {t('button.invite')}
                  </MenuItem>
                )}

                {isAuthenticated && isOwner && (
                  <MenuItem
                    onClick={() => {
                      actions.openForm(workout?.id);
                      setOpen(false);
                    }}
                  >
                    <Edit style={{ fontSize: 14 }} />
                    &nbsp;
                    {t('button.edit')}
                  </MenuItem>
                )}
              </MenuList>
            </Paper>
          </Box>
        </Popper>
      </Box>
    </Box>
  );
};

MenuCustomWorkout.propTypes = {};

MenuCustomWorkout.defaultProps = {};

export default MenuCustomWorkout;
