import React from 'react';
import Box from 'core/ui/Box';
import Scrollbars from 'react-custom-scrollbars';
import { Droppable } from 'react-beautiful-dnd';
import KanbanColumn from './KanbanColumn';

const KanbanColumns = ({ channelStages }) => (
  <Box
    className="tw-flex tw-flex-1 tw-grow tw-flex-row tw-gap-3"
    style={{ height: 'calc(100% - 70px)' }}
  >
    {channelStages.map((stage) => (
      <Box className="tw-min-w-60 tw-rounded-t-lg" key={stage.id.toString()}>
        <Scrollbars allowFullScreen className="tw-flex-1 tw-grow tw-basis-0">
          <Droppable droppableId={`stage-${stage.id}`}>
            {(provided) => (
              <div {...provided.droppableProps} style={{ minHeight: 200 }} ref={provided.innerRef}>
                <KanbanColumn stage={stage} />
              </div>
            )}
          </Droppable>
        </Scrollbars>
      </Box>
    ))}
  </Box>
);

KanbanColumns.propTypes = {};

export default KanbanColumns;
