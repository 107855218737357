/* eslint-disable react/prop-types */
import React from 'react';
import Paper from 'core/ui/Paper';
import { useTranslation } from 'react-i18next';
import { date, timeWithoutTz } from 'core/utils/formatters';
import Typography from 'core/ui/Typography';
import SFDataTable from 'core/ui/DataTable';

export default function ResultTracks({ channel, tracks }) {
  const { t } = useTranslation();
  const columns = [
    {
      name: 'User Name',
      selector: 'userName',
      sortable: true,
      cell: (row) => <Typography>{row.userName}</Typography>,
    },
    {
      name: 'Checkin',
      selector: 'checkin',
      sortable: true,
      cell: (row) => <Typography>{row.checkin ? 'Yes' : 'No'}</Typography>,
    },
    {
      name: 'Note',
      selector: 'checkin_note',
      cell: (row) => <Typography>{row.checkinNote}</Typography>,
      sortable: true,
      center: true,
    },
    {
      name: 'Date',
      selector: 'datetime',
      cell: (row) => (
        <Typography>
          {' '}
          {date(row?.datetime, channel?.dateFormatReact)}&nbsp;&bull;&nbsp;
          {timeWithoutTz(row.datetime)}
        </Typography>
      ),
      sortable: true,
      center: true,
    },
  ];

  const rows =
    tracks &&
    tracks.map((key) => ({
      id: key.id,
      userName: key.user && key.user.name,
      checkin: key.checkin,
      checkinNote: key.checkinNote,
      datetime: key.datetime,
    }));

  return (
    <Paper style={{ width: '100%' }}>
      <SFDataTable
        title={t('channel.reports.hybridTracks')}
        columns={columns}
        data={rows || []}
        pagination
        showExport
      />
    </Paper>
  );
}
