import React from 'react';
import PropTypes from 'prop-types';
import Box from 'core/ui/Box';
import { makeStyles, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import CardUser from './Card';

const ListUsers = ({ users, onSelectedUser }) => {
  const useStyles = makeStyles((theme) => ({
    boxCoaches: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
  }));

  const classes = useStyles();

  return (
    <Box>
      {users && (
        <Box className={classes.boxCoaches} flexWrap="wrap">
          <Table aria-label="collapsible table" size="small">
            <TableBody>
              {users.map((user, index) => (
                <TableRow key={index.toString()}>
                  <TableCell>
                    <CardUser user={user} onClick={() => onSelectedUser(user)} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      )}
    </Box>
  );
};

ListUsers.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  users: PropTypes.array,
  onSelectedUser: PropTypes.func,
};

ListUsers.defaultProps = {
  users: [],
  onSelectedUser: () => {},
};

export default ListUsers;
