import { useCallback, useEffect, useState } from 'react';
import humps from 'humps';
import { BASE_API_URL_SOCKET } from 'core/env';
import useLikes from 'core/useLikes';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import useSelectors from 'modules/map/useSelectors';
import { retrieveData } from 'core/utils/session-storage';
import { retrieveAuthHeaders } from 'core/api/auth-headers';
import { commentsModule } from '.';

const actioncable = require('actioncable');

function useComments({ typeId, type, kind }) {
  const { request } = useActions();
  const [localComments, setLocalComments] = useState([]);
  const loading = useSelectors(commentsModule, 'loading');

  const { postLike } = useLikes();

  const received = (e) => {
    const comment = humps.camelizeKeys(e.data.comment);
    if (e.data.type === 'new_comment') {
      if (localComments.find((x) => x.id === comment.id)) {
        return;
      }
      setLocalComments((prev) => [...prev, comment]);
    }

    if (e.data.type === 'new_like') {
      setLocalComments((prev) =>
        prev.map((cm) => {
          if (Number(cm.id) === Number(comment.id)) {
            return comment;
          }
          return cm;
        }),
      );
    }
  };

  useEffect(() => {
    const logAuth = retrieveAuthHeaders(retrieveData)();
    const { client, uid } = logAuth;

    const authHeaders = {
      'access-token': logAuth['access-token'],
      client,
      uid,
    };
    const queryString = new URLSearchParams(authHeaders).toString();

    const cable = actioncable.createConsumer(`${BASE_API_URL_SOCKET}?${queryString}`);
    const channelCable = cable.subscriptions.create(
      {
        channel: 'CommentsChannel',
        type,
        type_id: typeId,
        kind,
      },
      {
        connected: () => {
          console.log('connected!');
        },
        disconnected: () => {},
        received: (data) => {
          received(data);
        },
      },
    );

    return () => {
      channelCable.unsubscribe();
      cable.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeId, type]);

  useEffect(
    () => () => {
      request({
        action: Modules.comments.actions.clearComments,
      });
    },
    [request, typeId, type],
  );

  const addComment = (text) => {
    request({
      action: Modules.comments.actions.postComment,
      data: {
        ownerableType: type,
        ownerableId: typeId,
        kind,
        text,
      },
    });
  };

  const getComments = () => {
    request({
      action: Modules.comments.actions.getComments,
      data: {
        ownerableType: type,
        ownerableId: typeId,
        kind,
      },
      options: {
        onSuccess: (data) => {
          setLocalComments(data);
        },
      },
    });
  };

  const likeComment = (id) => {
    postLike({ likeableId: id, likeableType: 'Comment', kind });
  };

  const removeComment = useCallback(
    (id) => {
      request({
        action: Modules.comments.actions.deleteComment,
        data: id,
      });
    },
    [request],
  );

  return {
    addComment,
    getComments,
    removeComment,
    likeComment,
    localComments,
    setLocalComments,
    loading,
  };
}

export default useComments;
