import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Edit } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { ButtonPrimary, ButtonPrimarySm } from 'core/ui/Button';
import Grid from 'core/ui/Grid';
import CardClass from 'components/Workout/Grid/Card';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { fontStyle } from 'core/ui/Typography';
import { onlineColor, primary } from 'core/ui/Colors';
import { channelCurrency } from 'core/utils/formatters';

export default function ItemList({
  item,
  onEditClick,
  onAddWorkoutClick,
  onRemoveWorkoutClick,
  expanded,
  channel,
}) {
  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    setOpen(!!expanded);
  }, [expanded]);
  const { t } = useTranslation();

  return (
    <>
      <TableRow className="hover" onClick={() => setOpen(!open)}>
        <TableCell style={{ width: 50 }}>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{item.name}</TableCell>
        <TableCell align="right">
          {item.price > 0 ? channelCurrency(Number(item.price), channel?.currency) : null}
        </TableCell>
        <TableCell align="center">
          {!item.active ? (
            <RadioButtonUncheckedIcon style={{ ...fontStyle.medium, color: primary }} />
          ) : (
            <CheckCircleOutlineIcon style={{ ...fontStyle.medium, color: onlineColor }} />
          )}
        </TableCell>
        <TableCell align="center">{item.numberOfUsers}</TableCell>
        <TableCell align="right">
          <IconButton onClick={() => onEditClick(item)}>
            <Edit />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box m={5}>
              <Box m={1} display="flex" alignItems="center">
                <Box flexGrow={1}>
                  <Typography variant="h6" color="primary" gutterBottom component="div">
                    {t('channel.list.class')}
                  </Typography>
                </Box>

                {item.canAddClasses && (
                  <ButtonPrimary onClick={() => onAddWorkoutClick(item)}>
                    <Typography>{t('channel.lists.addClasses')}</Typography>
                  </ButtonPrimary>
                )}
              </Box>
              <Box>
                <Box>
                  <Grid container>
                    {item.workouts &&
                      item.workouts.map((workout, index) => (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={4}
                          lg={3}
                          key={index.toString()}
                          className="search-item"
                        >
                          <Box m={2} key={index.toString()}>
                            <CardClass
                              key={workout.id}
                              workout={workout}
                              clickable={false}
                              image={
                                workout.channel && workout.channel.image
                                  ? workout.channel.image
                                  : {}
                              }
                            />
                            <Box mt={2} display="flex" justifyContent="center">
                              <ButtonPrimarySm onClick={() => onRemoveWorkoutClick(workout)}>
                                {t('button.remove')}
                              </ButtonPrimarySm>
                            </Box>
                          </Box>
                        </Grid>
                      ))}
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

ItemList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.object.isRequired,
  onEditClick: PropTypes.func.isRequired,
  onAddWorkoutClick: PropTypes.func.isRequired,
  onRemoveWorkoutClick: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
};
