import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import Typography from 'core/ui/Typography';
import { Dialog, IconButton, Link } from '@material-ui/core';
import Texture from 'core/ui/Texture';
import { Add, RemoveCircle } from '@material-ui/icons';
import SelectorChannelUser from 'components/Channel/SelectorChannelUser';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import { channelMembersActions } from 'modules/channelMembers';
import { useDispatch } from 'react-redux';
import { usersActions } from 'modules/users';
import SelectorUser from 'components/Profile/SelectorUser/index';
import SFDataTable from 'core/ui/DataTable';
import Button from 'core/ui/Button';
import Paper from 'core/ui/Paper';
import { useHistory } from 'react-router';

export default function MemberHouseHold({ member, channelId, modalHouseHold, setModalHouseHold }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { request } = useActions();
  const history = useHistory();
  const toogleUserHousehold = (userId) => {
    setModalHouseHold({ open: false });
    request({
      action: Modules.userHousehold.actions.toogleUserHousehold,
      data: {
        channelId,
        memberId: member?.id,
        userId,
      },
      options: {
        onSuccess: () => {
          if (channelId) {
            dispatch(channelMembersActions.getChannelMemberStart(channelId, member.id));
          } else {
            dispatch(usersActions.authenticateStart());
          }
        },
      },
    });
  };

  const columns = [
    {
      name: t('table.column.name'),
      selector: 'name',
      sortable: true,
      cell: (row) => (
        <Box>
          <Link
            className="hover"
            onClick={() => history.push(`/channel/manage/${channelId}/members/${row?.id}`)}
          >
            {row?.name}
          </Link>
        </Box>
      ),
    },
    {
      name: t('child'),
      selector: 'name',
      sortable: true,
      cell: (row) => (
        <Box>
          <Typography>{row.child ? t('button.yes') : t('button.no')}</Typography>
        </Box>
      ),
    },
    {
      name: t('table.column.defaultPaymentInfo'),
      selector: 'name',
      sortable: true,
      cell: () => (
        <Box>
          <Typography>{member?.defaultPaymentInfo}</Typography>
        </Box>
      ),
    },
    {
      name: t('table.column.remove'),
      cell: (row) => (
        <Box>
          {member?.id !== row?.id && !row.child && (
            <IconButton
              onClick={() => {
                toogleUserHousehold(row.id);
              }}
            >
              <RemoveCircle style={{ fontSize: 15 }} />
            </IconButton>
          )}
        </Box>
      ),
    },
  ];

  return (
    <Box>
      {member?.householdUsers?.length > 0 && (
        <>
          {channelId && (
            <Box display="flex" alignItems="center" mb={1.5}>
              <Box mr={5}>
                <Typography variant="h6">{t('channel.member.houseHold')}</Typography>
              </Box>
              <Box>
                <Button onClick={() => setModalHouseHold({ open: true })}>
                  <Add fontSize="small" />
                </Button>
              </Box>
            </Box>
          )}

          <Paper className="paper-rounded" py={2}>
            <SFDataTable
              noHeader
              title=""
              showPaper={false}
              columns={columns}
              paginationPerPage={20}
              data={member?.householdUsers}
              pagination
              noDataComponent=""
            />
          </Paper>
        </>
      )}
      <Dialog
        open={modalHouseHold.open}
        fullWidth
        maxWidth="xs"
        onClose={() => setModalHouseHold(() => ({ open: false }))}
      >
        <Texture>
          <Box p={5}>
            <Typography mb={5} variant="h4" align="center">
              {t('channel.member.adduserToHousehold')}
            </Typography>
            <Box mb={3}>
              {channelId ? (
                <SelectorChannelUser
                  channelId={channelId}
                  onSelectedUser={(u) => toogleUserHousehold(u?.id)}
                />
              ) : (
                <SelectorUser
                  channelId={channelId}
                  onSelectedUser={(u) => toogleUserHousehold(u?.id)}
                />
              )}
            </Box>
          </Box>
        </Texture>
      </Dialog>
    </Box>
  );
}

MemberHouseHold.defaultProps = {};
