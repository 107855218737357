import api from 'core/api';

export const getChannelMemberNotes = (channelId, memberId) =>
  api.get(`channels/${channelId}/member/${memberId}/notes`);
export const postChannelMemberNotes = (data) =>
  api.post(`channels/${data.channelId}/channel_member_notes`, data);
export const putChannelMemberNotes = (data) =>
  api.put(`channels/${data.channelId}/channel_member_notes/${data.id}`, data);
export const deleteChannelMemberNotes = (channelId, id) =>
  api.delete(`channels/${channelId}/channel_member_notes/${id}`);
