import React, { useEffect, useState } from 'react';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import { ButtonPrimary } from 'core/ui/Button';
import { channelsSelectors } from 'modules/channels';
import { useSelector } from 'react-redux';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import useSelectors from 'modules/map/useSelectors';
import { channelTemplatesModule } from 'modules/channelTemplates';
import MIcon from 'core/ui/MIcon';
import LoaderSm from 'core/ui/LoaderSm';
import TemplatesTable from './Table';
import ModalTemplate from './ModalTemplate';

export default () => {
  const { t } = useTranslation();
  const { request } = useActions();
  const loading = useSelectors(channelTemplatesModule, 'loading');
  const templates = useSelectors(channelTemplatesModule, 'templates');
  const channel = useSelector((state) => channelsSelectors.getChannel(state));
  const [modalTemplate, setModalTemplate] = useState({ open: false, method: 'new' });

  const getTemplates = () => {
    request({
      action: Modules.channelTemplates.actions.getTemplates,
      data: { channelId: channel.id },
    });
  };

  useEffect(() => {
    getTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const callback = () => {
    setModalTemplate({ open: false });
    getTemplates();
  };

  return (
    <Box p={5}>
      <Box display="flex" mb={2} alignItems="center">
        <Box flexGrow="1" alignItems="center" />
        <Box>
          <ButtonPrimary onClick={() => setModalTemplate({ open: true, method: 'new' })}>
            <MIcon icon="add" size={15} color="white" />
            &nbsp;{t('button.template')}
          </ButtonPrimary>
        </Box>
      </Box>

      <ModalTemplate
        channelId={channel.id}
        modalTemplate={modalTemplate}
        close={() => setModalTemplate({ open: false })}
        callback={callback}
      />
      <Box display="flex" justifyContent="center">
        <LoaderSm loading={loading} />
      </Box>

      <TemplatesTable templates={templates} setModalTemplate={setModalTemplate} />
    </Box>
  );
};
