import React from 'react';
import Box from 'core/ui/Box';
import { ButtonPrimary } from 'core/ui/Button';
import Paper from 'core/ui/Paper';
import Typography from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import LoaderSm from 'core/ui/LoaderSm';
import { useHistory } from 'react-router';
import ListHeats from './List';
import useHeats from './useHeats';

const WorkoutHeats = ({
  workout,
  isCoach,
  buttonColor,

  loading,
  divisionId,
  hasDivisions,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { heats, loadingHeats, heatsUsers } = useHeats({
    workout,
    divisionId,
    hasDivisions,
    loadLeaderboard: false,
  });

  return (
    <Paper elevation={2} mb={3} className="paper-rounded opacity-80" p={3}>
      <Box display="flex" alignItems="center">
        <Box flexGrow={1}>
          <Typography mb={3} variant="h5" color="secondary">
            {t('workout.heats').toUpperCase()}
          </Typography>
          <LoaderSm loading={loadingHeats} />
        </Box>
        {isCoach && (
          <ButtonPrimary
            buttonColor={buttonColor}
            onClick={() =>
              history.push(
                `/competition/edit/${workout.competitionId}/heats?workoutId=${workout?.id}`,
              )
            }
          >
            {t('button.editHeats').toUpperCase()}
          </ButtonPrimary>
        )}
      </Box>
      {loading && (
        <Box display="flex" justifyContent="center">
          <LoaderSm loading={loading} />
        </Box>
      )}
      {!loadingHeats && (
        <Box mt={3}>
          <ListHeats workout={workout} heats={heats} heatsUsers={heatsUsers} dropable={false} />
        </Box>
      )}
    </Paper>
  );
};

export default WorkoutHeats;
