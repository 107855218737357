import { Dialog } from '@material-ui/core';
import Box from 'core/ui/Box';
import { ButtonPrimary } from 'core/ui/Button';
import Texture from 'core/ui/Texture';
import Typography from 'core/ui/Typography';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Add } from '@material-ui/icons';
import FormVariation from './FormData';
import List from './List';

export default ({ channel }) => {
  const { t } = useTranslation();
  const [openModalForm, setOpenForm] = useState(null);
  const [itemEdit, setItemEdit] = useState(null);

  const onEditClick = (item) => {
    setOpenForm(true);
    setItemEdit(item);
  };

  return (
    <Box py={5}>
      <Box display="flex" alignItems="flex-start">
        <Box flexGrow={1}>
          <Typography>{t('channel.scoreVariations')}</Typography>
        </Box>
        <Box style={{ minWidth: 110 }}>
          <ButtonPrimary onClick={() => setOpenForm(true)}>
            <Box display="flex" alignItems="center">
              <Add fontSize="small" />
              &nbsp;
              {t('button.createVariation')}
            </Box>
          </ButtonPrimary>
        </Box>
      </Box>

      <Dialog maxWidth="xs" fullWidth open={!!openModalForm} onClose={() => setOpenForm(false)}>
        <Texture>
          <Box p={5}>
            <FormVariation
              channel={channel}
              itemEdit={itemEdit}
              callback={() => {
                setItemEdit(null);
                setOpenForm(false);
              }}
            />
          </Box>
        </Texture>
      </Dialog>

      <Box mt={3}>
        <List onEditClick={onEditClick} />
      </Box>
    </Box>
  );
};
