import React, { createContext, useEffect } from 'react';

import Grid from 'core/ui/Grid';
import propTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { channelsSelectors, channelsActions } from 'modules/channels';
import Skeleton from '@material-ui/lab/Skeleton';
import { useHistory } from 'react-router';
import CardChannel from './Card';
import CardButtonAdd from './CardButtonAdd';

export const Context = createContext();

const Channels = ({ select }) => {
  const channels = useSelector((state) => channelsSelectors.getChannels(state));
  const loading = useSelector((state) => channelsSelectors.getLoading(state));
  const dispatch = useDispatch();

  const history = useHistory();
  useEffect(() => {
    dispatch(channelsActions.getChannelsStart());
  }, [dispatch]);

  return (
    <>
      {loading && <Skeleton style={{ height: 200 }} animation="wave" variant="rect" />}
      {!loading && (
        <Grid container spacing={2}>
          {channels.map((channel, index) => (
            <Grid
              onClick={() =>
                select ? select(channel) : history.push(`/channel/view/${channel.slug}`)
              }
              item
              xs={12}
              sm={12}
              md={4}
              lg={4}
              key={index.toString()}
            >
              <CardChannel channel={channel} />
            </Grid>
          ))}
          {!select && (
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <CardButtonAdd />
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};

export default Channels;

Channels.propTypes = {
  select: propTypes.func,
};

Channels.defaultProps = {
  select: null,
};
