import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import { useSelector } from 'react-redux';

import useActions from 'modules/map/useActions';
import Modules from 'modules';
import { getUser } from 'modules/session/selectors';
import { showAPIError } from 'core/utils/showAPIError';
import ModalRetryPayment from 'components/Profile/Dashboard/Account/Table/ModalRetryPayment';

export const RetryPayment = ({ paymentId }) => {
  const { request } = useActions();
  const currentUser = useSelector(getUser);

  const [payment, setPayment] = useState(null);

  useEffect(() => {
    request({
      action: Modules.newPayments.actions.show,
      data: paymentId,
      options: {
        onSuccess: setPayment,
        onError: showAPIError,
      },
    });
  }, [request, paymentId]);

  return payment ? (
    <ModalRetryPayment
      payment={payment}
      userId={currentUser.id}
      open
      showPlanName
      showPaymentMethodsSelector
    />
  ) : (
    <div className="tw-w-scren tw-flex tw-h-screen tw-items-center tw-justify-center">
      <CircularProgress className="tw-mx-auto" />
    </div>
  );
};
