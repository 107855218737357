import React from 'react';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import Typography from 'core/ui/Typography';
import Divider from 'core/ui/Divider';
import { ordinalNumber } from 'core/utils/formatters/date';
import { IconButton } from '@material-ui/core';
import { Delete, Edit, FileCopy } from '@material-ui/icons';

const ScoringTable = ({ scoringTemplate, remove, setModalForm }) => {
  const { t } = useTranslation();

  return (
    <Box className="tw-flex tw-flex-col tw-gap-2 tw-rounded-md tw-bg-white tw-pb-2">
      <div className="tw-relative tw-flex tw-flex-col tw-gap-2 tw-rounded-t-md tw-bg-zinc-600 tw-p-2">
        <div className="tw-flex tw-grow tw-flex-col tw-gap-2">
          {scoringTemplate?.divisions?.length > 0 && (
            <div className="tw-flex tw-flex-row tw-gap-2">
              <Typography className="tw-text-white">{t(`label.division`)}</Typography>
              <Typography className="tw-font-bold tw-text-white">
                {scoringTemplate?.divisions?.map((x) => x.name)?.join(', ')}
              </Typography>
            </div>
          )}
          {scoringTemplate?.workouts?.length > 0 && (
            <div className="tw-flex tw-flex-row tw-gap-2">
              <Typography className="tw-text-white">{t(`competition.workout`)}</Typography>
              <Typography className="tw-font-bold tw-text-white">
                {scoringTemplate?.workouts?.map((x) => x.name)?.join(', ')}
              </Typography>
            </div>
          )}
          {!(scoringTemplate?.divisions?.length > 0 || scoringTemplate?.workouts?.length > 0) && (
            <div className="tw-flex tw-flex-row tw-gap-2">
              <Typography className="tw-font-bold tw-text-white">
                {t(`competition.customScoring.allEventsAllDivisions`)}
              </Typography>
            </div>
          )}
        </div>

        <div className="tw-flex tw-flex-row tw-items-center tw-justify-end">
          <IconButton
            size="small"
            onClick={() => setModalForm({ open: true, item: scoringTemplate })}
          >
            <Edit fontSize="small" htmlColor="white" />
          </IconButton>
          <Divider orientation="vertical" mx={1} flexItem />

          <IconButton size="small" onClick={() => remove(scoringTemplate)}>
            <Delete fontSize="small" htmlColor="white" />
          </IconButton>
          <Divider orientation="vertical" mx={1} flexItem />

          <IconButton
            size="small"
            onClick={() =>
              setModalForm({ open: true, item: { ...scoringTemplate, clone: true, id: null } })
            }
          >
            <FileCopy fontSize="small" htmlColor="white" />
          </IconButton>
        </div>
      </div>

      {/* <Divider /> */}

      <div>
        {[...new Array(Number(scoringTemplate?.positions || 0))].map((item, index) => (
          <div key={index}>
            <div className="tw-gray-100 tw-flex tw-flex-row tw-items-center tw-gap-2 tw-px-2">
              <div className="tw-flex tw-w-10 tw-items-center tw-justify-center">
                <Typography className="tw-font-bold">{ordinalNumber(index + 1)}</Typography>
              </div>
              <Divider orientation="vertical" mx={2} flexItem />
              <div className="tw-flex tw-grow tw-flex-row tw-items-end tw-justify-end">
                <Typography>{scoringTemplate?.scorings[index]}</Typography>
              </div>
            </div>

            {Number(scoringTemplate?.positions) - 1 > index && <Divider my={2} />}
          </div>
        ))}
      </div>
    </Box>
  );
};

export default ScoringTable;
