/* eslint-disable react/prop-types */
import React from 'react';
import { successColor } from 'core/ui/Colors';
import Typography from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import { replaceAll } from 'core/utils/helpers';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';

const ContractDuration = ({ plan }) => {
  const { t } = useTranslation();
  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      {plan.hasContract && (
        <>
          <AssignmentTurnedInIcon style={{ fontSize: 18, color: successColor }} />
          <Typography
            style={{ color: successColor }}
            align="center"
            variant="caption"
            component="h5"
          >
            {replaceAll(
              t('channel.memberships.info.contractDuration'),
              '#{duration}',
              `${plan.contractTimeNumber} ${plan.contractTimeType}`,
            )}
            {plan.contractTimeNumber > 1 ? 's' : ''}
          </Typography>
        </>
      )}
    </Box>
  );
};

export default ContractDuration;
