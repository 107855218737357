import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Box from 'core/ui/Box';
import Typography, { fontStyle } from 'core/ui/Typography';
import { Container, Hidden } from '@material-ui/core';
import CollapseText from 'core/ui/CollapseText';
import { sessionSelectors } from 'modules/session';
import ImageMedia from '../ImageMedia';
import InstagramLink from '../InstagramLink';

const useStyles = makeStyles((theme) => ({
  rootHeader: {
    backgroundColor: 'white',
  },

  boxRoot: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  spacingBox: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: 4,
    },
  },
  boxAvatar: {
    height: 170,
    width: 170,
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      top: 33,
      marginRight: 15,
    },
  },
  avatar: {
    height: 170,
    width: 170,
    borderRadius: 10,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    display: 'block',
    position: 'relative',
    backgroundColor: '#bbbdbf',
  },
  personalInformationSpan: {
    fontSize: 12,
    color: '#404041',
    width: 120,
  },
  personalInformationValues: {
    fontSize: 12,
  },
  buttonWidth: {
    width: 150,
  },
}));

export default () => {
  const user = useSelector((state) => sessionSelectors.getUser(state));
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.rootHeader}>
      <Container maxWidth="xl">
        <Box display="flex" p={3}>
          <Box>
            <Hidden only={['md', 'lg', 'xl']}>
              <Box mt={2}>
                <InstagramLink size={15} instagram={user.instagram} />
              </Box>
            </Hidden>
            <Box mt={2} className={classes.avatar}>
              <Box style={{ position: 'absolute', top: 0, width: 170, height: 170 }}>
                <ImageMedia image={user.image ? user.image : {}} user={user} />
              </Box>
            </Box>
          </Box>
          <Box flexGrow={1} pl={5}>
            <Typography color="primary" mt={3} mb={2} variant="h3">
              {user.name}
            </Typography>
            <Box display="flex">
              <Hidden only={['xs', 'sm']}>
                <Box flexGrow={1} mr={5}>
                  <Box display="flex">
                    <Typography color="secondary" component="span" variant="h6">
                      {t('dashboard.birthday')}:
                    </Typography>

                    <Typography color="secondary" ml={1} component="span" variant="h6">
                      {user.age}
                    </Typography>
                  </Box>
                  <Box display="flex" mt={2}>
                    <Typography color="secondary" component="span" variant="h6">
                      {t('profile.localGym')}:
                    </Typography>

                    <Typography color="secondary" ml={1} component="span" variant="h6">
                      {user.localGym}
                    </Typography>
                  </Box>
                  <Box mt={1}>
                    <InstagramLink size={18} instagram={user.instagram} />
                  </Box>
                </Box>
              </Hidden>
              <Box flexGrow={1}>
                <Typography mx={1} component="div" style={fontStyle.medium}>
                  <CollapseText text={user.description} maxChar={250} />
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </div>
  );
};
