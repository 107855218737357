/* eslint-disable no-nested-ternary */
import React from 'react';
import Box from 'core/ui/Box';
import { ButtonPrimary } from 'core/ui/Button';
import LoaderSm from 'core/ui/LoaderSm';
import { useTranslation } from 'react-i18next';
import useSelectors from 'modules/map/useSelectors';
import DatePickerRounded from 'core/ui/DatePickers/DatePickerRounded';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { sessionSelectors } from 'modules/session';
import TextAreaFieldRounded from 'core/ui/TextAreaFieldRounded';
import Typography from 'core/ui/Typography';
import Checkbox from 'core/ui/Checkbox';
import TimePickerRounded from 'core/ui/DatePickers/TimePickerRounded';
import notifications from 'modules/notifications';
import Modules from 'modules';
import useActions from 'modules/map/useActions';
import { date, formatDateToApi } from 'core/utils/formatters';
import SelectBoxSearch from 'core/ui/SelectBox/SelectBoxSearch';
import { getAllTimezones, timeZoneName } from 'core/utils/formatters/date';
import { usersUnavailableTimesModule } from 'modules/usersUnavailableTimes';

const FormUnavailableDates = ({ item, callback, channelId }) => {
  const { t } = useTranslation();
  const currentUser = useSelector((state) => sessionSelectors.getUser(state));
  const loading = useSelectors(usersUnavailableTimesModule, 'loading');
  const { control, handleSubmit, watch } = useForm({ defaultValues: { timezone: timeZoneName() } });
  const dispatch = useDispatch();
  const { request } = useActions();

  const onSubmit = (data) => {
    const formData = {
      ...data,
      channelId,
      id: item?.id,
      startTime: data?.startTime && date(data?.startTime, 'hh:mm a'),
      endTime: data?.endTime && date(data?.endTime, 'hh:mm a'),
      date: formatDateToApi(data?.date),
      timezone: data?.timezone || timeZoneName(),
    };

    request({
      action: item?.id
        ? Modules.usersUnavailableTimes.actions.update
        : Modules.usersUnavailableTimes.actions.create,
      data: formData,
      options: {
        onSuccess: () => {
          dispatch(notifications.success(t('message.success.usersUnavailableTimes')));
          if (callback) {
            callback(data);
          }
        },
        onError: (error) => {
          dispatch(notifications.error(error));
        },
      },
    });
  };

  return (
    <Box>
      <div className="tw-flex tw-flex-col tw-gap-4">
        <Typography align="center">{t('label.unavailable')}</Typography>
        <div className="tw-flex tw-flex-row tw-items-end tw-gap-4">
          <Controller
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <div>
                <DatePickerRounded
                  label={t('label.date').toUpperCase()}
                  onChange={onChange}
                  value={value}
                  disablePast={false}
                  usFormat={currentUser?.gymConfig?.useUsDateFormat}
                  autoOk
                  error={error}
                  bordered
                />
              </div>
            )}
            name="date"
          />
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <div>
                <Checkbox
                  label={t('label.allDay')}
                  checked={value}
                  onClick={() => onChange(!value)}
                />
              </div>
            )}
            name="allDay"
          />
        </div>

        <div>
          {!watch('allDay') && (
            <div className="tw-flex tw-flex-row tw-items-center tw-gap-2">
              <Controller
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <div>
                    <TimePickerRounded
                      onChange={onChange}
                      value={value}
                      error={error}
                      disablePast={false}
                      bordered
                    />
                  </div>
                )}
                name="startTime"
              />
              -
              <Controller
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <div>
                    <TimePickerRounded
                      onChange={onChange}
                      value={value}
                      error={error}
                      disablePast={false}
                      autoOk
                      bordered
                    />
                  </div>
                )}
                name="endTime"
              />
            </div>
          )}
        </div>

        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <div>
              <SelectBoxSearch
                label={t('workout.label.timezone').toUpperCase()}
                options={getAllTimezones()}
                setValue={onChange}
                value={value || timeZoneName()}
                required
              />
            </div>
          )}
          name="timezone"
        />
        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <div>
              <TextAreaFieldRounded
                label={t('label.description').toUpperCase()}
                onChange={onChange}
                value={value}
                disablePast={false}
                stylePaper={{ height: 100 }}
                autoOk
                bordered
              />
            </div>
          )}
          name="description"
        />
      </div>

      <Box>
        <Box mt={3} display="flex" justifyContent="flex-end">
          <ButtonPrimary
            type="button"
            onClick={() => handleSubmit(onSubmit)()}
            size="small"
            disabled={loading}
            style={{ height: 30, width: 80, borderRadius: 5 }}
          >
            <LoaderSm loading={loading} width={20} />
            {t('button.save')}
          </ButtonPrimary>
        </Box>
      </Box>
    </Box>
  );
};

FormUnavailableDates.defaultProps = {};

export default FormUnavailableDates;
