import React, { useContext, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from 'core/ui/Grid';
import { Card, makeStyles } from '@material-ui/core';
import Typography from 'core/ui/Typography';
import noImage from 'assets/img/no-image.jpg';
import Box from 'core/ui/Box';
import imgGrid from 'assets/img/bg-grid.png';
import { Check, OpenWith, ZoomIn, ZoomOut } from '@material-ui/icons';
import Draggable from 'react-draggable';
import { isNumber } from 'core/utils/fp';
import { ButtonWhite } from 'core/ui/Button';
import { ChannelContext } from './index';
import SelImage from './SelImage';

const Image = () => {
  const formContext = useContext(ChannelContext);
  const { t } = useTranslation();
  const [sizeMedia, setSizeMedia] = useState({ width: 0 });

  const refMedia = useRef(null);

  const useStyles = makeStyles({
    boxSelImage: {
      position: 'absolute',
      marginTop: -40,
      height: 100,
    },
    boxInfoDrag: {
      backgroundColor: 'rgba(0,0,0,0.4)',
      color: 'white',
      position: 'absolute',
      marginTop: -90,
      padding: 5,
      borderRadius: 10,
    },
    gridEdit: {
      backgroundImage: `url(${imgGrid})`,
      margin: 0,
      padding: 0,
      cursor: 'move',
    },
    colorTitle: {
      color: '#404041',
    },
    btnZoom: {
      backgroundColor: 'rgba(0,0,0,0.4)',
      borderRadius: 10,
      padding: 1,
    },
    boxZoom: {
      backgroundColor: 'rgba(0,0,0,0.4)',
      color: 'white',
      position: 'absolute',
      marginTop: -130,
      padding: 5,
      borderRadius: 10,
    },
  });
  const classes = useStyles();

  useEffect(() => {
    if (refMedia.current) {
      setSizeMedia({
        height: refMedia.current.clientHeight,
        width: refMedia.current.clientWidth,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSizeMedia, refMedia.current && refMedia.current.clientHeight]);

  const handleDrag = (e, ui) => {
    formContext.setImage((prev) => ({
      ...prev,
      position: {
        ...prev.position,
        width: sizeMedia.width,
        x: 0,
        y: ui.y,
      },
    }));
  };

  const confirmPosition = () => {
    formContext.setImage((prev) => ({
      ...prev,
      new: true,
      edit: false,
    }));
  };

  useEffect(() => {
    if (formContext.image.position && sizeMedia.width > 0) {
      const y = formContext.image.position.y !== 0 ? formContext.image.position.y : 1;
      const newY = (y / formContext.image.position.width) * sizeMedia.width;

      formContext.setImage((prev) => ({
        ...prev,
        position: {
          ...prev.position,
          width: sizeMedia.width,
          x: 0,
          y: newY,
        },
      }));
    }
  }, [sizeMedia.width, formContext.image.image]); // eslint-disable-line

  const zoom = (n) => {
    formContext.setImage((prev) => ({
      ...prev,
      position: {
        ...prev.position,
        zoom: (prev.position.zoom || 1) + n,
      },
    }));
  };

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Card>
          {formContext.image && formContext.image.edit && (
            <Box className={`${classes.gridEdit}`}>
              <Box style={{ paddingTop: '37.25%', position: 'relative' }}>
                <Box className="boxGridMedia" ref={refMedia}>
                  <Draggable
                    axis="y"
                    onDrag={handleDrag}
                    position={{
                      x: 0,
                      y: isNumber(formContext.image.position.y) ? formContext.image.position.y : 0,
                    }}
                  >
                    <Box>
                      <img
                        className="boxImg"
                        draggable="false"
                        src={formContext.image ? formContext.image.url : noImage}
                        alt=""
                        style={{
                          transform: `scale(${formContext.image.position.zoom || 1})`,
                        }}
                      />
                    </Box>
                  </Draggable>
                </Box>
              </Box>
            </Box>
          )}

          {formContext.image && formContext.image.edit && (
            <Box display="flex" justifyContent="center">
              <Box className={classes.boxZoom} display="flex">
                <Box
                  className={classes.btnZoom}
                  display="flex"
                  mr={1}
                  alignItems="center"
                  onClick={() => zoom(0.1)}
                >
                  <ZoomIn style={{ color: 'white', fontSize: 22 }} />
                </Box>
                <Box
                  className={classes.btnZoom}
                  display="flex"
                  mr={1}
                  alignItems="center"
                  onClick={() => zoom(-0.1)}
                >
                  <ZoomOut style={{ color: 'white', fontSize: 22 }} />
                </Box>
              </Box>
              <Box className={classes.boxInfoDrag} display="flex" alignItems="center">
                <OpenWith size="small" />
                <Typography ml={1} variant="h6">
                  {t('channel.info.dragToReposition')}
                </Typography>
              </Box>
            </Box>
          )}

          {formContext.image && !formContext.image.edit && (
            <Box className={`${classes.grid}`}>
              <Box style={{ paddingTop: '37.25%', position: 'relative' }}>
                <Box className="boxGridMedia" ref={refMedia}>
                  <Draggable
                    axis="y"
                    disabled
                    position={{
                      x: 0,
                      y: formContext.image.position ? formContext.image.position.y : 0,
                    }}
                  >
                    <Box>
                      <img
                        className="boxImg"
                        draggable="false"
                        src={formContext.image ? formContext.image.url : noImage}
                        alt=""
                        style={{
                          transform: `scale(${
                            formContext.image.position ? formContext.image.position.zoom || 1 : 1
                          })`,
                        }}
                      />
                    </Box>
                  </Draggable>
                </Box>
              </Box>
            </Box>
          )}

          <Box display="flex" justifyContent="center">
            <Box className={classes.boxSelImage}>
              {formContext.image && !formContext.image.edit && (
                <SelImage widthElement={sizeMedia.width} />
              )}
              {formContext.image && formContext.image.edit && (
                <ButtonWhite p={1} variant="contained" onClick={() => confirmPosition()}>
                  <Box display="flex" alignItems="center">
                    <Check style={{ fontSize: 16 }} />
                    <Typography component="span" mx={1}>
                      {t('confirmCrop').toUpperCase()}
                    </Typography>
                  </Box>
                </ButtonWhite>
              )}
            </Box>
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Image;
