/* eslint-disable consistent-return */
import React from 'react';
import { CompetitionRegistrationContext } from 'contexts';
import { hexToRgb } from '@material-ui/core';
import { secondary } from 'core/ui/Colors';
import Typography from 'core/ui/Typography';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import { ButtonPrimary } from 'core/ui/Button';
import RegistrationTypeSelector from './RegistrationTypeSelector';
import useCompRegistration, { registrationSteps } from './useCompRegistration';
import RegistrationFinish from './RegistrationFinish';

const Registration = ({ close }) => {
  const registration = useCompRegistration({ close });
  const { t } = useTranslation();
  const optionBgColor = () => {
    const c = registration?.competition?.backgroundColor || secondary;
    const rgb = hexToRgb(c).replace('rgb(', '').replace(')', '');
    const splitRgb = rgb.split(',');

    const darker = (x) => x - x * 0.1;
    const light = (x) => x * 1.8;

    if (registration?.competition?.darkBackground) {
      return `rgb(${light(splitRgb[0])}, ${light(splitRgb[1])}, ${light(splitRgb[2])})`;
    }

    return `rgb(${darker(splitRgb[0])}, ${darker(splitRgb[1])}, ${darker(splitRgb[2])})`;
  };

  return (
    <CompetitionRegistrationContext.Provider
      value={{ ...registration, close, cardBgColor: optionBgColor() }}
    >
      {registration.step === registrationSteps.chooseType && <RegistrationTypeSelector />}
      {registration.step === registrationSteps.finish && <RegistrationFinish />}
      {registration.step === registrationSteps.success && (
        <Box p={4}>
          <Typography
            align="center"
            variant="h5"
            color="primary"
            mb={4}
            style={{ color: registration.competition?.darkBackground ? 'white' : secondary }}
          >
            {t('label.competition.success')}
          </Typography>
          <Typography
            align="center"
            variant="h6"
            style={{ color: registration.competition?.darkBackground ? 'white' : secondary }}
          >
            {t('label.competition.success.msg')}
          </Typography>

          <Box display="flex" justifyContent="center" mt={3}>
            <ButtonPrimary onClick={close}>{t('button.close')}</ButtonPrimary>
          </Box>
        </Box>
      )}
    </CompetitionRegistrationContext.Provider>
  );
};

export default Registration;
