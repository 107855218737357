/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import Typography from 'core/ui/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { messagesActions, messagesSelectors } from 'modules/messages';
import { useTranslation } from 'react-i18next';
import Paper from 'core/ui/Paper';
import { Refresh } from '@material-ui/icons';
import Button from 'core/ui/Button';
import LoaderSm from 'core/ui/LoaderSm';
import Box from 'core/ui/Box';
import { messageListsActions } from 'modules/messageLists';
import TableMessagesReplies from './Table';

const MessagesReplies = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const data = useSelector((state) => messagesSelectors.getMessagesReplies(state));
  const loading = useSelector((state) => messagesSelectors.getLoadingReplies(state));

  useEffect(() => {
    dispatch(messagesActions.getMessagesRepliesStart());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refresh = () => {
    dispatch(messagesActions.getMessagesRepliesStart());
  };

  const stopContact = (contact) => {
    dispatch(
      messagesActions.stopContactStart(contact.phone, () => {
        dispatch(messageListsActions.getMessageListsStart());
        dispatch(messagesActions.getMessagesStart());
        dispatch(messagesActions.getMessagesRepliesStart());
      }),
    );
  };

  return (
    <Paper p={3} className="paper-rounded">
      <Box display="flex" alignItems="center">
        <Box display="flex" alignItems="center" flexGrow={1}>
          <Typography variant="h4">{t('admin.messagesReplies')}</Typography>
          {loading && <LoaderSm loading />}
        </Box>
        <Button onClick={() => refresh()}>
          <Refresh />
        </Button>
      </Box>
      <TableMessagesReplies messages={data} stopContact={stopContact} />
    </Paper>
  );
};

MessagesReplies.propTypes = {};

MessagesReplies.defaultProps = {};

export default MessagesReplies;
