import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography, { fontStyle } from 'core/ui/Typography';
import { useHistory } from 'react-router';
import { time } from 'core/utils/formatters';
import { useTranslation } from 'react-i18next';
import { primary, redColor } from 'core/ui/Colors';
import { timeWithoutTz, dateAdd } from 'core/utils/formatters/date';
import AvatarCoach from 'components/Profile/AvatarCoach';
import AvatarCoaches from 'components/Profile/AvatarCoaches';
import LiveIcon from 'core/ui/LiveIcon';
import Box from 'core/ui/Box';
import Paper from 'core/ui/Paper';
import { ButtonPrimarySm } from 'core/ui/Button';
import { channelsSelectors } from 'modules/channels';
import { useSelector } from 'react-redux';

const useStyles = makeStyles({
  difficulty: {
    fontFamily: 'Roboto',
    fontSize: 14,
  },
  timeFont: {
    fontFamily: 'Roboto',
  },
  boxTag: {
    background: primary,
    position: 'absolute',
    top: 8,
    right: 8,
    zIndex: 10,
  },
});

const ScheduleItemClass = ({ workout, clickable, datetime }) => {
  const classes = useStyles();
  const history = useHistory();
  const channel = useSelector((state) => channelsSelectors.getChannel(state));

  const { t } = useTranslation();
  const workoutDate =
    datetime || (workout.method !== 'live' ? workout.publishAt : workout.scheduledAt);

  return (
    <Box
      style={{
        height: 100,
        position: 'relative',
      }}
    >
      <Paper elevation={1} p={3} style={{ border: '0.4px solid #e9e9e9' }}>
        <Box
          display="flex"
          onClick={() => (clickable ? history.push(`/workout/view/${workout.slug}`) : null)}
          flexDirection="column"
        >
          <Box className={classes.boxContent}>
            <Box display="flex">
              {workout?.coaches?.length > 0 && (
                <>
                  {workout.coaches && workout.coaches.length > 1 ? (
                    <AvatarCoaches coaches={workout.coaches} />
                  ) : (
                    <AvatarCoach user={workout.coaches[0]} />
                  )}
                </>
              )}
              <Box flexGrow={1} pl={2} pt={1}>
                <Box display="flex">
                  <Box flexGrow={1} style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {!!workoutDate && (
                      <Box display="flex">
                        <Typography style={{ ...fontStyle.xSmall, color: 'gray' }}>
                          {timeWithoutTz(
                            workoutDate,
                            channel?.useUserTimezone ? null : workout?.timezone,
                          )}
                          &nbsp;-&nbsp;
                        </Typography>

                        {workout.online ? (
                          <Typography style={{ ...fontStyle.xSmall, color: 'gray' }}>
                            {time(
                              dateAdd(workoutDate, workout.durationMinutes, 'minutes'),
                              channel?.useUserTimezone ? null : workout?.timezone,
                            )}
                          </Typography>
                        ) : (
                          <Typography style={{ ...fontStyle.xSmall, color: 'gray' }}>
                            {time(
                              workout.endAt,
                              channel?.useUserTimezone ? null : workout?.timezone,
                            )}
                          </Typography>
                        )}
                      </Box>
                    )}
                    {workout?.canceled && (
                      <Typography variant="caption" style={{ color: redColor }}>
                        {t('workout.canceled')}
                      </Typography>
                    )}
                    <Typography
                      noWrap
                      variant="body2"
                      style={{ textOverflow: 'ellipsis' }}
                      component="h5"
                    >
                      {(
                        workout.name ||
                        (workout.firstType && workout.firstType.name) ||
                        ''
                      ).substring(0, 30)}
                    </Typography>
                  </Box>
                  {workout?.live && workout?.online && workout?.started && (
                    <Box style={{ position: 'absolute', right: 5, top: 3 }}>
                      <LiveIcon />
                    </Box>
                  )}
                  <Box mt={10}>
                    <ButtonPrimarySm>
                      <Typography style={fontStyle.medium}>{t('button.view')}</Typography>
                    </ButtonPrimarySm>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default ScheduleItemClass;

ScheduleItemClass.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  workout: PropTypes.object,
  clickable: PropTypes.bool,
};

ScheduleItemClass.defaultProps = {
  workout: {},
  clickable: true,
};
