import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { currency, date } from 'core/utils/formatters';
import { ButtonPrimary, ButtonSecondary } from 'core/ui/Button';
import { useTranslation } from 'react-i18next';
import { Check, Close, CreditCard, Lock, Payment } from '@material-ui/icons';
import Box from 'core/ui/Box';
import Typography, { fontStyle } from 'core/ui/Typography';
import {
  Card,
  CardContent,
  CardHeader,
  Dialog,
  List,
  Table,
  TableCell,
  TableRow,
} from '@material-ui/core';
import Paper from 'core/ui/Paper';
import { primary, redColor, successColor } from 'core/ui/Colors';

export default function ItemPartnerProgram({
  channel,
  item,
  onPurchaseClick,
  onCancelClick,
  loadingCancel,
  setModalProgram,
}) {
  const { t } = useTranslation();
  const [modalSubPrograms, setModalSubPrograms] = useState();

  return (
    <Card variant="outlined">
      <Dialog
        maxWidth="sm"
        fullWidth
        open={modalSubPrograms?.open}
        onClose={() => setModalSubPrograms({ open: false })}
      >
        <Paper p={3}>
          <Typography color="primary" variant="caption">
            {t('partners.programs.sub')}
          </Typography>
          <Table>
            {modalSubPrograms?.program?.parentPrograms?.map((sub) => (
              <TableRow key={sub.id}>
                <TableCell>
                  <Typography>{sub?.name}</Typography>
                </TableCell>
                {modalSubPrograms?.program?.channelPlan &&
                  !modalSubPrograms?.program?.channelPlan?.expired && (
                    <TableCell>
                      <ButtonSecondary
                        p={0}
                        px={1}
                        onClick={() => setModalProgram({ open: true, program: sub })}
                      >
                        <Lock style={{ fontSize: 10 }} />
                        &nbsp;
                        <Typography style={{ fontSize: 10 }}>{t('channel.memberships')}</Typography>
                      </ButtonSecondary>
                    </TableCell>
                  )}
              </TableRow>
            ))}
          </Table>
        </Paper>
      </Dialog>
      <CardHeader
        title={
          <Box display="flex" alignItems="center" justifyContent="center">
            {item?.channelPlan && <Check style={{ color: successColor }} />}
            <Typography variant="h5">{item?.name}</Typography>
          </Box>
        }
      />
      <CardContent>
        <Box px={1} textAlign="center">
          <Typography variant="h3" component="h2" gutterBottom>
            {t('prefix')}
            {currency(item.cost)}
            <Typography variant="h6" color="textSecondary" component="span">
              /month
            </Typography>
          </Typography>

          {item?.allowAnnualCost && (
            <Typography variant="h3" component="h2" gutterBottom>
              {t('prefix')}
              {currency(item.annualCost)}
              <Typography variant="h6" color="textSecondary" component="span">
                /year
              </Typography>
            </Typography>
          )}

          <Typography color="textSecondary" variant="subtitle1" component="p">
            {item?.channelPlan && !item?.channelPlan?.expired ? (
              <span>
                {item?.channelPlan?.renewable ? (
                  <Typography>
                    Renew &nbsp;
                    <CreditCard fontSize="small" /> {item?.channelPlan?.lastFour}
                  </Typography>
                ) : (
                  <Typography>Expires</Typography>
                )}
              </span>
            ) : (
              <span>&nbsp;</span>
            )}
          </Typography>
          <Typography color="textSecondary" variant="subtitle1" component="p">
            {item?.channelPlan && !item?.channelPlan?.expired ? (
              <span>{date(item?.channelPlan?.expiryAt, channel?.dateFormatReact)}</span>
            ) : (
              <span>&nbsp;</span>
            )}
          </Typography>

          <Typography color="textSecondary" variant="subtitle1" component="p" paragraph>
            &nbsp;
          </Typography>
        </Box>

        <Box display="flex" textAlign="center" justifyContent="center">
          {item?.channelPlan && !!item?.channelPlan?.renewable && !item?.channelPlan?.expired ? (
            <>
              {!item.hasParents && (
                <ButtonSecondary onClick={() => setModalProgram({ open: true, program: item })}>
                  <Lock style={fontStyle.medium} />
                  &nbsp;
                  <Typography style={fontStyle.medium}>{t('channel.memberships')}</Typography>
                </ButtonSecondary>
              )}
              &nbsp;
              {item?.channelPlan?.renewable && (
                <ButtonPrimary
                  buttonColor={redColor}
                  onClick={() => onCancelClick(item)}
                  loading={loadingCancel}
                >
                  <Close style={fontStyle.medium} />
                  &nbsp;
                  <Typography style={fontStyle.medium}>{t('button.cancel')}</Typography>
                </ButtonPrimary>
              )}
            </>
          ) : (
            <ButtonPrimary buttonColor={primary} onClick={() => onPurchaseClick(item)}>
              <Payment style={{ color: 'white', ...fontStyle.medium }} />
              &nbsp;
              <Typography style={{ color: 'white', ...fontStyle.medium }}>
                {t('button.purchase')}
              </Typography>
            </ButtonPrimary>
          )}

          {item.hasParents && (
            <>
              &nbsp;
              <ButtonSecondary onClick={() => setModalSubPrograms({ open: true, program: item })}>
                <List style={fontStyle.medium} />
                &nbsp;
                <Typography style={fontStyle.medium}>{t('channel.subPrograms')}</Typography>
              </ButtonSecondary>
            </>
          )}
        </Box>
      </CardContent>
    </Card>
  );
}

ItemPartnerProgram.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.object.isRequired,
  onPurchaseClick: PropTypes.func.isRequired,
};
