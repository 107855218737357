/* eslint-disable no-return-assign */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { channelsActions, channelsSelectors } from 'modules/channels';
import { useParams } from 'react-router';
import { Skeleton } from '@material-ui/lab';
import Box from 'core/ui/Box';
import Memberships from 'components/Channel/Show/Tabs/Memberships';
import Texture from 'core/ui/Texture';

const ChannelMemberships = () => {
  const { channelId } = useParams();
  const dispatch = useDispatch();
  const channel = useSelector((state) => channelsSelectors.getChannel(state));
  const loading = useSelector((state) => channelsSelectors.getLoadingChannel(state));

  useEffect(() => {
    if (channelId) {
      dispatch(channelsActions.getChannelStart(channelId));
    }
  }, [dispatch, channelId]);

  return (
    <Texture fullHeight>
      <Box>
        {!loading && channel && channel.id && (
          <Memberships embed channel={channel} isCoach={false} />
        )}

        {loading && (
          <div className="tw-grid tw-grid-cols-1 tw-gap-2 lg:tw-grid-cols-4">
            {[...Array(9)].map((v) => (
              <div key={v}>
                <Skeleton variant="rect" width="100%" height="200px" />
              </div>
            ))}
          </div>
        )}
      </Box>
    </Texture>
  );
};

ChannelMemberships.propTypes = {};

export default ChannelMemberships;
