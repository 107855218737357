/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Box from 'core/ui/Box';
import ModalDocumentViewer from 'components/DocumentsBuilder/ModalDocumentViewer';
import useUsersContracts from 'core/useUsersContracts';

export default function ChannelContracts({
  ownerableType,
  ownerableId,
  objectId,
  objectType,
  userId,
  kind,
  buttonColor,
  setAccepted,
  documentId,
  alwaysOpen,
  onClose,
}) {
  const [loaded, setLoaded] = useState(false);
  const { check, contract, setContract, loadingContract } = useUsersContracts({
    userId,
    ownerableId,
    ownerableType,
    objectId,
    objectType,
    documentId,
  });

  useEffect(() => {
    if (userId && documentId) {
      check(kind, setLoaded, (resp) => setAccepted(resp?.accepted));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, ownerableId, kind]);

  useEffect(() => {
    if (contract?.accepted && setAccepted) {
      setAccepted(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contract?.id, contract?.accepted]);

  return (
    <Box>
      <ModalDocumentViewer
        contractKey={contract?.key}
        document={contract?.document}
        userId={userId}
        loaded={loaded}
        open={contract?.key && (alwaysOpen || contract != null)}
        close={() => {
          if (onClose) {
            onClose();
          }
          if (!alwaysOpen) {
            setContract(null);
          }
        }}
        loadingContract={loadingContract}
        callback={() => {
          check(
            kind,
            () => {},
            (data) => {
              setAccepted(data?.accepted);
            },
          );
        }}
        buttonColor={buttonColor}
      />
    </Box>
  );
}
