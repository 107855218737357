import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form-v5';
import Modules from 'modules';
import useActions from 'modules/map/useActions';
import { useDispatch } from 'react-redux';
import notifications from 'modules/notifications';
import useSelectors from 'modules/map/useSelectors';
import useConfirmation from 'core/useConfirmation';
import { integrationsModule } from 'modules/integrations';
import { IntegrationPayload } from '.';

export const defaultTest = {
  merchant: {
    dbaName: 'The Card Stream Test 04',
    legalBusinessName: 'The Card Stream Test 02',
    taxFilingName: 'The Card Stream Test 01 LLC',
    taxFilingMethod: 'EIN',
    demographic: {
      businessAddress: {
        countryCd: 'US',
        address1: '123 Fake Street',
        address2: 'Suite 300',
        city: 'Keystone',
        stateCd: 'CA',
        zip: '80435',
      },
      mailingAddress: {
        countryCd: 'US',
        address1: '123 Fake Street',
        address2: 'Suite 300',
        city: 'Keystone',
        stateCd: 'AZ',
        zip: '80435',
      },
    },
    ownership: {
      owner: {
        ownerAddress: {
          countryCd: 'US',
          address1: '1 Imaginary Lane',
          address2: 'Apt 221B',
          city: 'Dillon',
          stateCd: 'GA',
          zip: '80435',
        },
        ownerName: 'Forename Surname',
        ownerEmail: 'developer+001@streamfit.com',
        ownerPhone: '5551234568',
        ownerTitle: 'OWNER',
      },
      driversLicenseNumber: '123456789',
      driversLicenseStateCd: 'FM',
    },
    bankDetail: {
      depositBank: {
        bankAcctNum: '923-456-8789',
        bankRoutingNum: '987654321',
        bankAcctTypeCd: 'BIZ',
        bankName: 'Deposit Bank',
      },
      withdrawalBank: {
        bankAcctNum: '123456789',
        bankRoutingNum: '987654321',
        bankAcctTypeCd: 'BIZ',
        bankName: 'Withdrawal Bank',
      },
    },
  },
};

export default ({ item, callback, ownerableId, ownerableType }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { confirmation } = useConfirmation();
  const { request } = useActions();
  const loading = useSelectors(integrationsModule, 'loading');
  const [kind, setKind] = useState();
  const { register, handleSubmit, setValue, watch, reset, errors } = useForm();

  const getIntegrations = () => {
    request({
      action: Modules.integrations.actions.getIntegrations,
      data: {
        ownerableId,
        ownerableType,
      },
    });
  };

  const onSubmit = (values) => {
    const data = {
      ...values,
      // eslint-disable-next-line no-nested-ternary
      ownerableId,
      ownerableType,
    };

    request({
      action: item?.id ? Modules.integrations.actions.update : Modules.integrations.actions.create,
      data: {
        id: item?.id,
        ownerableId,
        ownerableType,
        kind,
        ...data,
      },
      options: {
        onSuccess: (resp) => {
          getIntegrations();
          dispatch(notifications.success(t('message.success.save.integrations')));

          if (data.kind === 'zapier') {
            confirmation({
              description: <IntegrationPayload integration={resp} />,
              labelConfirm: 'Ok',
              hideNoButton: true,
            });
          }

          if (callback) {
            callback(values.name);
          }
        },
        onError: (err) => {
          dispatch(notifications.error(err));
        },
      },
    });
  };

  const businessAddress =
    watch('payload.merchant.demographic.businessAddress.address1') +
    watch('payload.merchant.demographic.businessAddress.address2') +
    watch('payload.merchant.demographic.businessAddress.stateCd') +
    watch('payload.merchant.demographic.businessAddress.city') +
    watch('payload.merchant.demographic.businessAddress.zip');
  const bankInfo =
    watch('payload.merchant.bankDetail.depositBank.bankAcctNum') +
    watch('payload.merchant.bankDetail.depositBank.bankRoutingNum') +
    watch('payload.merchant.bankDetail.depositBank.bankAcctTypeCd') +
    watch('payload.merchant.bankDetail.depositBank.bankName');

  const mailingAddressCopy = watch(
    'payload.merchant.demographic.mailingAddress.sameBusinessAddress',
  );
  const ownerAddressCopy = watch(
    'payload.merchant.ownership.owner.ownerAddress.sameBusinessAddress',
  );
  const bankCopy = watch('sameDepositBank');

  useEffect(() => {
    if (kind === 'hybrid') {
      register('externalId', { required: false });
      register('token', { required: false });
    }
    if (kind === 'shopify') {
      register('payload.shop', { required: false });
      register('payload.accessToken', { required: false });
    }
    if (kind === 'sifely') {
      register('payload.username', { required: false });
      register('payload.password', { required: false });
      register('payload.lockId', { required: false });
    }

    if (kind === 'cardpointe') {
      register('payload.merchant.dbaName', { required: true });
      register('payload.merchant.legalBusinessName', { required: true });
      register('payload.merchant.taxFilingName', { required: true });
      register('payload.merchant.taxFilingMethod', { required: true });

      register('payload.merchant.demographic.businessAddress.countryCd', { required: false });
      register('payload.merchant.demographic.businessAddress.address1', { required: true });
      register('payload.merchant.demographic.businessAddress.address2', { required: false });
      register('payload.merchant.demographic.businessAddress.city', { required: true });
      register('payload.merchant.demographic.businessAddress.stateCd', { required: false });
      register('payload.merchant.demographic.businessAddress.zip', { required: true });

      register('payload.merchant.demographic.mailingAddress.sameBusinessAddress', {
        required: false,
      });
      register('payload.merchant.demographic.mailingAddress.countryCd', { required: false });
      register('payload.merchant.demographic.mailingAddress.address1', { required: true });
      register('payload.merchant.demographic.mailingAddress.address2', { required: false });
      register('payload.merchant.demographic.mailingAddress.city', { required: true });
      register('payload.merchant.demographic.mailingAddress.stateCd', { required: false });
      register('payload.merchant.demographic.mailingAddress.zip', { required: true });

      register('payload.merchant.ownership.owner.ownerAddress.sameBusinessAddress', {
        required: false,
      });
      register('payload.merchant.ownership.owner.ownerAddress.countryCd', { required: false });
      register('payload.merchant.ownership.owner.ownerAddress.address1', { required: true });
      register('payload.merchant.ownership.owner.ownerAddress.address2', { required: false });
      register('payload.merchant.ownership.owner.ownerAddress.city', { required: true });
      register('payload.merchant.ownership.owner.ownerAddress.stateCd', { required: false });
      register('payload.merchant.ownership.owner.ownerAddress.zip', { required: true });

      register('payload.merchant.ownership.owner.ownerName', { required: true });
      register('payload.merchant.ownership.owner.ownerEmail', { required: true });
      register('payload.merchant.ownership.owner.ownerPhone', { required: true });
      register('payload.merchant.ownership.owner.ownerTitle', { required: true });

      register('payload.merchant.ownership.driversLicenseNumber', { required: true });
      register('payload.merchant.ownership.driversLicenseStateCd', { required: true });

      register('payload.merchant.bankDetail.depositBank.bankAcctNum', { required: true });
      register('payload.merchant.bankDetail.depositBank.bankRoutingNum', { required: true });
      register('payload.merchant.bankDetail.depositBank.bankAcctTypeCd', { required: true });
      register('payload.merchant.bankDetail.depositBank.bankName', { required: true });

      register('payload.merchant.bankDetail.withdrawalBank.bankAcctNum', { required: true });
      register('payload.merchant.bankDetail.withdrawalBank.bankRoutingNum', { required: true });
      register('payload.merchant.bankDetail.withdrawalBank.bankAcctTypeCd', { required: true });
      register('payload.merchant.bankDetail.withdrawalBank.bankName', { required: true });
    }

    register('sameDepositBank', { required: false });

    if (ownerableType === 'Partner') {
      setValue('kind', 'shopify');
    }

    setValue('payload.merchant.demographic.businessAddress.countryCd', 'US');
    setValue('payload.merchant.demographic.mailingAddress.countryCd', 'US');
    setValue('payload.merchant.ownership.owner.ownerAddress.countryCd', 'US');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register, ownerableType, kind]);

  useEffect(() => {
    if (item?.id) {
      setTimeout(() => {
        reset(item);
        setKind(item?.kind);
      }, 300);
    }
    // const payload = { payload: { ...defaultTest } };
    // reset({ ...payload });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item?.id]);

  console.log(' errors', errors);

  const copyAddress = useCallback(
    (key) => {
      const keyFrom = 'payload.merchant.demographic.businessAddress';
      setValue(`${key}.countryId`, watch(`${keyFrom}.countryId`));
      setValue(`${key}.address1`, watch(`${keyFrom}.address1`));
      setValue(`${key}.address2`, watch(`${keyFrom}.address2`));
      setValue(`${key}.city`, watch(`${keyFrom}.city`));
      setValue(`${key}.stateCd`, watch(`${keyFrom}.stateCd`));
      setValue(`${key}.zip`, watch(`${keyFrom}.zip`));
    },
    [setValue, watch],
  );

  useEffect(() => {
    if (mailingAddressCopy) {
      copyAddress('payload.merchant.demographic.mailingAddress');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mailingAddressCopy, businessAddress]);

  useEffect(() => {
    if (ownerAddressCopy) {
      copyAddress('payload.merchant.ownership.owner.ownerAddress');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ownerAddressCopy, businessAddress]);

  useEffect(() => {
    if (bankCopy) {
      setValue(
        `payload.merchant.bankDetail.withdrawalBank.bankAcctNum`,
        watch(`payload.merchant.bankDetail.depositBank.bankAcctNum`),
      );
      setValue(
        `payload.merchant.bankDetail.withdrawalBank.bankRoutingNum`,
        watch(`payload.merchant.bankDetail.depositBank.bankRoutingNum`),
      );
      setValue(
        `payload.merchant.bankDetail.withdrawalBank.bankAcctTypeCd`,
        watch(`payload.merchant.bankDetail.depositBank.bankAcctTypeCd`),
      );
      setValue(
        `payload.merchant.bankDetail.withdrawalBank.bankName`,
        watch(`payload.merchant.bankDetail.depositBank.bankName`),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankCopy, bankInfo]);

  const setTestValues = () => {
    setTimeout(() => {
      reset({ payload: { merchant: defaultTest.merchant } });
      setKind('cardpointe');
      // const payload = { payload: { ...defaultTest } };
      // reset({ ...payload });
    }, 300);
  };

  return {
    register,
    setValue,
    watch,
    reset,
    errors,
    onSubmit,
    handleSubmit,
    loading,
    setKind,
    kind,
    setTestValues,
  };
};
