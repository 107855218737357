import api from 'core/api';

export const storeDiscountCodesModule = 'storeDiscountCodes';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const actions = {
  getDiscount: {
    module: storeDiscountCodesModule,
    name: 'getDiscount',
    api: (params) => api.get(`/store/discount_codes/apply_code`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: ['newPrice'],
    },
    state: loadingStates,
  },
  getStoreDiscountCodes: {
    module: storeDiscountCodesModule,
    name: 'getStoreDiscountCodes',
    api: (params) => api.get(`/store/discount_codes`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: ['data'],
    },
    state: loadingStates,
  },
  postStoreDiscountCodes: {
    module: storeDiscountCodesModule,
    name: 'postStoreDiscountCodes',
    api: (params) => api.post(`/store/discount_codes`, params),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  putStoreDiscountCodes: {
    module: storeDiscountCodesModule,
    name: 'putStoreDiscountCodes',
    api: (data) => api.put(`/store/discount_codes/${data.id}`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  assignCouponToAllProducts: {
    module: storeDiscountCodesModule,
    name: 'assignCouponToAllProducts',
    api: (data) => api.put(`/store/discount_codes/${data.id}/assign_coupon_to_all_products`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
};

export default {
  actions,
  state: {
    data: [],
    newPrice: 0,
    error: null,
    loading: false,
  },
};
