import React, { useContext, useEffect, useState } from 'react';
import Box from 'core/ui/Box';
import Typography from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import { ButtonPrimary } from 'core/ui/Button';
import { useHistory } from 'react-router';
import Paper from 'core/ui/Paper';
import { Dialog } from '@material-ui/core';
import Scrollbars from 'react-custom-scrollbars';
import { date } from 'core/utils/formatters';
import { Today } from '@material-ui/icons';
import ModalCalendarsEvent from 'components/Competition/CalendarsEvents/Modal';
import Modules from 'modules';
import useActions from 'modules/map/useActions';
import { ShowContext } from '../../index';
import CardEvent from './CardEvent';
import CalendarEvents from './CalendarEvents';

const CompetitionWorkoutsEvents = ({ setEventView }) => {
  const showContext = useContext(ShowContext);
  const { t } = useTranslation();
  const { request } = useActions();
  const history = useHistory();
  const [customEvents, setCustomEvents] = useState(false);
  const [modalFormCustom, setModalFormCustom] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);

  const onViewClick = (workout) => {
    if (workout?.extendedProps?.custom) {
      // eslint-disable-next-line eqeqeq
      setModalFormCustom({ open: true, item: customEvents?.find((x) => x.id == workout?.id) });

      return;
    }
    if (!showContext.competition.isCoach && workout.hideSectionsEveryone && !workout.started) {
      setOpenMessage(true);
      return;
    }
    setEventView(workout.id);
  };

  const getCustomEvents = () => {
    request({
      action: Modules.competitionsCalendars.actions.getCompetitionsCalendars,
      data: {
        competitionId: showContext?.competition?.id,
      },
      options: {
        onSuccess: (data) => {
          setCustomEvents(data);
        },
      },
    });
  };
  useEffect(() => {
    if (showContext?.competition?.id) {
      getCustomEvents();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showContext?.competition]);

  const { buttonColor } = showContext;

  return (
    <Box>
      <Dialog open={openMessage} onClose={() => setOpenMessage(false)}>
        <Box p={10}>
          <Typography>{t('message.event.view')}</Typography>
        </Box>
      </Dialog>
      <Box>
        <Paper p={2} mb={2} className="opacity-80">
          <Box display="flex" flexWrap="wrap" alignItems="center">
            <Box flexGrow="1" display="flex">
              <Typography variant="h5" component="h4">
                {t('competition.tab.events').toUpperCase()}
              </Typography>
            </Box>
            <ModalCalendarsEvent
              open={modalFormCustom?.open}
              competition={showContext.competition}
              item={modalFormCustom?.item}
              callback={() => {
                getCustomEvents();
                setModalFormCustom({ open: false });
              }}
              close={() => setModalFormCustom({ open: false })}
              isCoach={showContext.competition.isCoach}
            />
            {showContext.competition.isCoach && (
              <Box display="flex" className="gap-10">
                <ButtonPrimary
                  buttonColor={buttonColor}
                  onClick={() =>
                    history.push(`/workout/new?competitionId=${showContext.competition.id}`)
                  }
                >
                  {t('button.createEvent')}
                </ButtonPrimary>
              </Box>
            )}
          </Box>

          {showContext.competition.workouts?.length === 0 && (
            <Typography m={3} align="center">
              {t('competition.events.notCreated')}
            </Typography>
          )}
        </Paper>
        <Scrollbars
          autoHide
          renderTrackVertical={(props) => (
            <div {...props} style={{ display: 'none' }} className="track-horizontal" />
          )}
          style={{ width: '100%', borderRadius: 5, marginBottom: 8 }}
          autoHeight
          autoHeightMax={500}
        >
          <Box display="flex" className="gap-5">
            {showContext.competition.workouts &&
              showContext.competition.workouts.map((item) => {
                const day = date(item?.startAt, 'D');

                return (
                  <React.Fragment key={item?.id}>
                    <Box display="flex" flexDirection="column">
                      <Paper p={1} px={2} mb={1} className="opacity-80">
                        <Box display="flex" alignItems="center" ml={-1} justifyContent="center">
                          <Today />
                          <Box display="flex" ml={1} flexDirection="column" alignItems="center">
                            <Typography component="div" variant="body2">
                              {date(item?.startAt, 'MMM')}
                            </Typography>
                            <Typography
                              component="div"
                              mt={-1}
                              variant="body2"
                              color="textSecondary"
                            >
                              {day}
                            </Typography>
                          </Box>
                        </Box>
                      </Paper>

                      <Box display="flex" flexGrow={1}>
                        <CardEvent item={item} onViewClick={onViewClick} />
                      </Box>
                    </Box>
                  </React.Fragment>
                );
              })}
          </Box>
        </Scrollbars>

        <Box>
          <CalendarEvents
            setModalFormCustom={setModalFormCustom}
            customEvents={customEvents}
            competition={showContext.competition}
            onViewClick={onViewClick}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default CompetitionWorkoutsEvents;
