import React, { useState, useEffect, useRef } from 'react';
import Box from 'core/ui/Box';
import Draggable from 'react-draggable';
import PropTypes from 'prop-types';
import FlagCountry from 'core/ui/FlagCountry';
import Typography from 'core/ui/Typography';
import { secondary } from 'core/ui/Colors';

const ImageMedia = ({ user, image, size, showCountry, heightCountry, sizeCountry }) => {
  const [sizeMedia, setSizeMedia] = useState({ width: 0 });
  const [position, setPosition] = useState(image?.position ? image.position : {});

  const refMedia = useRef(null);

  useEffect(() => {
    setPosition(image?.position);
  }, [image?.position]);

  useEffect(() => {
    if (refMedia.current) {
      setSizeMedia({
        height: refMedia.current.clientHeight,
        width: refMedia.current.clientWidth,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSizeMedia, refMedia.current && refMedia.current.clientHeight]);

  useEffect(() => {
    if (image?.position && sizeMedia.width > 0) {
      setPosition((prev) => ({
        ...prev,
        width: sizeMedia.width,
        x: 0,
        y: ((prev.y !== 0 ? prev.y : 1) / prev.width) * sizeMedia.width,
      }));
    }
  }, [size, sizeMedia, image?.position]);

  const styleMedia = {
    height: size,
    width: size,
    borderRadius: 10,
    backgroundColor: secondary,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const boxFlag = {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: 44,
    height: heightCountry,
    overflow: 'hidden',
    borderBottomLeftRadius: 10,
  };

  const urlImage = image && image.image && (image?.image?.size350?.url || image.image.url);

  return (
    <Box style={{ ...styleMedia, position: 'relative' }}>
      {urlImage && (
        <Box className="boxGridMedia" ref={refMedia} style={{ borderRadius: 10 }}>
          <Draggable
            axis="y"
            disabled
            position={{
              x: 0,
              y: position ? position.y : 0,
            }}
          >
            <Box>
              {urlImage && (
                <img
                  className="boxImg"
                  draggable="false"
                  style={{
                    transform: `scale(${position && position.zoom !== 0 ? position.zoom : 1})`,
                  }}
                  src={urlImage}
                  alt=""
                />
              )}
            </Box>
          </Draggable>
        </Box>
      )}
      {showCountry && user.country && (
        <Box style={{ ...boxFlag }}>
          <FlagCountry flag={user.country.flag} size={sizeCountry} />
        </Box>
      )}
      {!urlImage && (
        <Typography
          align="center"
          style={{ fontSize: size * 0.5, color: 'white' }}
          mb={size * 0.02}
        >
          {user.initials}
        </Typography>
      )}
    </Box>
  );
};

ImageMedia.propTypes = {
  size: PropTypes.number,
  heightCountry: PropTypes.number,
  sizeCountry: PropTypes.string,
  showCountry: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  image: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.object,
};

ImageMedia.defaultProps = {
  size: 170,
  heightCountry: 35,
  sizeCountry: '3em',
  showCountry: true,
  image: {},
  user: {},
};

export default ImageMedia;
