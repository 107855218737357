import React from 'react';
import { useTranslation } from 'react-i18next';
import PermDeviceInformationInfo from '@material-ui/icons/PermDeviceInformation';

import { useHelpVideoManager } from 'hooks/useHelpVideoManager';
import { ItemMenu } from '../ItemMenu';

export const HelperToggleMenuItem = (props) => {
  const { t } = useTranslation();

  const { visible, toggleVisible } = useHelpVideoManager();

  return (
    <ItemMenu
      onClick={toggleVisible}
      CustomIcon={<PermDeviceInformationInfo fontSize="large" htmlColor="white" />}
      active={visible}
      label={t('appbar.menu.help')}
      {...props}
    />
  );
};
