import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import { Dialog, IconButton } from '@material-ui/core';
import Button, { ButtonPrimary } from 'core/ui/Button';
import Texture from 'core/ui/Texture';
import Typography from 'core/ui/Typography';
import SelectorChannelUser from 'components/Channel/SelectorChannelUser';
import Grid from 'core/ui/Grid';
import Paper from 'core/ui/Paper';
import Divider from 'core/ui/Divider';
import { Block, Delete, People } from '@material-ui/icons';
import Scrollbars from 'react-custom-scrollbars';
import SelectorUser from 'components/Profile/SelectorUser';

const ModalUsers = ({ open, item, close, addUserGroup, removeUserGroup, updateUserGroup }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      PaperProps={{ style: { borderRadius: 5, minWidth: 300 } }}
      maxWidth="md"
      scroll="body"
      fullWidth
      onClose={() => close()}
      open={open}
    >
      <Texture>
        <Box p={3}>
          <Typography variant="h5" align="center">
            {t('label.groupsChats.users')}
          </Typography>
          <Grid container spacing={5} p={5}>
            <Grid item xs={12} md={6}>
              <Box style={{ minHeight: 400 }}>
                {item?.ownerableType === 'Channel' ? (
                  <SelectorChannelUser
                    channelId={item?.ownerableId}
                    onSelectedUser={(u) => addUserGroup(u)}
                  />
                ) : (
                  <SelectorUser onSelectedUser={(u) => addUserGroup(u)} />
                )}
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box display="flex" alignItems="center" mb={5}>
                <People />
                <Typography ml={2}>
                  {item?.users?.length}
                  &nbsp; Users
                </Typography>
              </Box>
              <Paper p={3} mt={2}>
                <Scrollbars autoHeight autoHeightMax={400}>
                  {item?.groupsChatsUsers?.map((groupUser, idx) => (
                    <Paper key={groupUser.id}>
                      <Box display="flex" px={3} algnItems="center">
                        <Box flexGrow={1}>
                          <Typography>{groupUser?.user?.name}</Typography>
                        </Box>
                        <Button
                          style={{ marginRight: 5 }}
                          onClick={() => updateUserGroup(groupUser?.user, !groupUser?.blocked)}
                        >
                          <Block />
                          {groupUser?.blocked ? 'Unblock' : 'Block'}
                        </Button>
                        <IconButton onClick={() => removeUserGroup(groupUser?.user)}>
                          <Delete />
                        </IconButton>
                      </Box>

                      {item?.users?.length - 1 > idx && <Divider m={3} />}
                    </Paper>
                  ))}
                </Scrollbars>
              </Paper>
            </Grid>
          </Grid>

          <Box display="flex" justifyContent="flex-end">
            <ButtonPrimary onClick={() => close()}>{t('button.close')}</ButtonPrimary>
          </Box>
        </Box>
      </Texture>
    </Dialog>
  );
};

ModalUsers.defaultProps = {
  close: () => {},
  item: null,
  open: false,
};

ModalUsers.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  close: PropTypes.func,
  open: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.object,
};

export default ModalUsers;
