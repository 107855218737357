import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Typography from 'core/ui/Typography';
import { Box } from '@material-ui/core';
import { useHistory } from 'react-router';
import ImageMedia from 'components/Channel/ImageMedia';
import { primary } from 'core/ui/Colors';
import InstagramLink from 'components/Profile/InstagramLink';

const useStyles = makeStyles({
  root: {
    maxWidth: 250,
    minWidth: 200,
    backgroundColor: 'transparent',
  },
  media: {
    paddingTop: '56.25%', // 16:9
    backgroundColor: '#bbbdbf',
  },
  difficulty: {
    fontFamily: 'Roboto',
    fontSize: 14,
  },
  dayFont: {
    fontFamily: 'Roboto',
    fontWeight: 700,
  },
  timeFont: {
    fontFamily: 'Roboto',
  },
  boxTag: {
    background: primary,
    position: 'absolute',
    bottom: 0,
    left: 0,
  },
  boxContent: {
    padding: 5,
    minHeight: 60,
  },
});

export default (props) => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <div className="item item-shadow" style={{ backgroundColor: 'rgba(245, 245, 245, 0.5)' }}>
      <Box onClick={() => history.push(`/channel/view/${props.channel.slug}`)}>
        <Box style={{ position: 'relative' }}>
          <ImageMedia image={props.image ? props.image : {}} />
        </Box>
        <Box display="flex" className={classes.boxContent}>
          <Box pl={2} pt={1}>
            <Typography color="primary" style={{ textOverflow: 'ellipsis' }} variant="h6" noWrap>
              {props.channel.name}
            </Typography>

            <InstagramLink instagram={props.channel.instagram} />
          </Box>
        </Box>
      </Box>
    </div>
  );
};
