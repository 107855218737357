import React from 'react';
import clsx from 'clsx';
import { Chip, CircularProgress, colors, makeStyles } from '@material-ui/core';

export const StatusChip = ({ status }) => {
  const classes = useStyles();

  return (
    <Chip
      label={status}
      icon={status === 'processing' && <CircularProgress size={14} className={classes.loader} />}
      className={clsx(
        classes.chip,
        (status === 'waiting' || status) === 'enqueued' && classes.waitingOrEnqueued,
        status === 'processing' && classes.processing,
        status === 'success' && classes.success,
        status === 'error' && classes.error,
      )}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  chip: {
    textTransform: 'capitalize',
  },
  waitingOrEnqueued: {
    background: theme.palette.grey[300],
    color: theme.palette.getContrastText(theme.palette.grey[300]),
  },
  processing: {
    background: colors.blue[400],
    color: colors.common.white,
  },
  success: {
    background: theme.palette.success.light,
    color: theme.palette.primary.contrastText,
  },
  error: {
    background: theme.palette.error.dark,
    color: theme.palette.error.contrastText,
  },
  loader: {
    color: 'white',
  },
}));
