import React from 'react';
import PropTypes from 'prop-types';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { useTheme } from '@material-ui/core';
import Typography from '../Typography';
import Box from '../Box';
import { secondaryLight } from '../Colors';

function Checkbox({ onClick, checked, size, label, color, disabled, style, labelColor, ...props }) {
  const { palette } = useTheme();

  const rColor = color || palette.primary.main;
  return (
    <Box
      className="link-hover"
      onClick={() => (disabled ? null : onClick())}
      display="flex"
      alignItems="center"
      {...props}
    >
      {!checked ? (
        <CheckBoxOutlineBlankIcon
          style={{ fontSize: size, color: disabled ? secondaryLight : rColor, ...style }}
        />
      ) : (
        <CheckBoxIcon
          style={{ fontSize: size, color: disabled ? secondaryLight : rColor, ...style }}
        />
      )}
      {label && (
        <Typography ml={2} variant="body2" component="div" style={{ color: labelColor }}>
          {label}
        </Typography>
      )}
    </Box>
  );
}

Checkbox.propTypes = {
  checked: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  onClick: PropTypes.func,
  size: PropTypes.number,
  color: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
};

Checkbox.defaultProps = {
  checked: false,
  color: null,
  size: 25,
  onClick: () => {},
  style: {},
};

export default Checkbox;
