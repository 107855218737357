import { createContext, useContext, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form-v5';
import { useSelector, useDispatch } from 'react-redux';
import { sessionActions, sessionSelectors } from 'modules/session';
import { usersActions } from 'modules/users';
import notifications from 'modules/notifications';
import { parse } from 'query-string';
import { useHistory } from 'react-router';
import { validateFullname } from 'core/utils/helpers';
import { LayoutContext } from '../Layout';

// SPOTIFY CALLBACK AUTORIZATION
const hash = parse(window.location.search);
if (hash.code && window.opener) {
  window.opener.spotifyCallback(hash.code);
}

export const ProfileContext = createContext();

const useProfileForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const profile = useSelector((state) => sessionSelectors.getUser(state));
  const [loadingSk, setLoadingSk] = useState(true);
  const { register, handleSubmit, errors, control, setValue, watch, setError } = useForm({
    defaultValues: {
      defaultWeightImperial: true,
      defaultDistanceMiles: true,
    },
  });
  const [image, setImage] = useState('');
  const [isPhoneValid, setIsPhoneValid] = useState(true);

  const layoutContext = useContext(LayoutContext);
  useEffect(() => {
    layoutContext.setTitleBar(t('title.account').toUpperCase());
    layoutContext.setActiveMenu('profile');
  }, [layoutContext, t]);

  useEffect(() => {
    register({ name: 'interests' });
    register({ name: 'certifications' });
    register({ name: 'channels' });
    register({ name: 'name' }, { required: true });
    register({ name: 'localGym' }, { required: false });
    register({ name: 'instagram' }, { required: false });
    register({ name: 'referredBy' }, { required: false });
    register({ name: 'gender' }, { required: false });
    register({ name: 'birthday' }, { required: true });
    register({ name: 'profileVideoLink' }, { required: false });
    register({ name: 'video' }, { required: false });
    register({ name: 'channelStripeId' }, { required: false });

    register({ name: 'emergencyName' });
    register({ name: 'emergencyEmail' });
    register({ name: 'emergencyPhone' });

    register({ name: 'defaultWeightImperial' });
    register({ name: 'defaultDistanceMiles' });

    register(
      { name: 'countryId' },
      {
        required: true,
        validate: (value) => Number(value) > 0,
      },
    );
    register({ name: 'phone' }, { required: true });
  }, [register]);

  const [validation, setValidation] = useState({
    errors: [],
    open: false,
  });

  useEffect(() => {
    if (errors) {
      const keys = Object.keys(errors);
      if (keys.length > 0) {
        const err = [];
        keys.forEach((k) => {
          err.push(t(`profile.validation.${k}`));
        });
        setValidation({
          open: true,
          errors: err,
        });
      }
    }
  }, [errors, t]);

  useEffect(() => {
    if (profile) {
      setTimeout(() => {
        setValue('video', profile.profileVideo);
        Object.keys(profile).forEach((x) => {
          setValue(x, profile[x]);
        });

        setValue('channels', profile.userChannels);
        setLoadingSk(false);
      }, 100);
    }
  }, [profile, setValue]);

  useEffect(() => {
    setImage({
      ...profile.image,
      url: profile.image ? profile.image.image.url : '',
      edit: false,
    });
  }, [profile]);

  const onSubmit = (data) => {
    if (!isPhoneValid) {
      dispatch(notifications.warning(t('profile.validate.phoneNumberInvalid')));
      return;
    }

    if (!validateFullname(data.name)) {
      dispatch(notifications.warning(t('validation.invalidFullName')));
      setError('name', true);
      return;
    }

    let instagram = '';
    if (data.instagram && data.instagram.indexOf('@') === -1) {
      instagram = `@${data.instagram}`;
    } else if (data.instagram) {
      instagram = data.instagram;
    }

    let videoData = data.video && data.video.data ? data.video.data.split('base64,')[1] : null;
    if (data.video && data.video.data) {
      if (data.video.data.indexOf('webm') > -1) {
        videoData = `data:video/webm;base64,${videoData}`;
      } else {
        videoData = `data:video/mov;base64,${videoData}`;
      }
    }

    let { calendarLink } = data;
    if (calendarLink && calendarLink.indexOf('http') === -1) {
      calendarLink = `https://${calendarLink}`;
    }

    const formData = data;
    if (!formData?.encryptedPin) {
      delete formData.encryptedPin;
    }

    dispatch(
      usersActions.putUpdateUserStart(
        {
          id: profile.id,
          user: {
            ...formData,
            videoAttributes: {
              video: videoData,
            },
            instagram,
            calendarLink,
            // eslint-disable-next-line no-nested-ternary
            photo: image ? (image.new === true ? image : null) : null,
            interests: formData?.interests?.map((x) => ({
              name: x.name ? x.name : x,
            })),
            certifications: data.certifications.map((x) => ({
              name: x.name ? x.name : x,
            })),
            channelsAttributes: data.channels ? data.channels.map((x) => x.id) : [],
          },
        },
        () => {
          dispatch(sessionActions.authenticateStart());
          dispatch(notifications.success(t('message.success.save.profile')));
          history.push('/dashboard');
        },
      ),
    );
  };

  return {
    validation,
    setValidation,
    handleSubmit,
    onSubmit,

    register,
    errors,
    loading: loadingSk,
    loadingSk,
    control,
    setValue,
    image,
    setImage,
    watch,
    setIsPhoneValid,
    submitForm: handleSubmit(onSubmit),
  };
};

export default useProfileForm;
