import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { roomActions, roomSelectors } from 'modules/room';
import Box from 'core/ui/Box';
import { makeStyles } from '@material-ui/core';
import { isMobileSafari } from 'react-device-detect';
import { workoutsSelectors } from 'modules/workouts';
import SpeakerVideo from '../Video/SpeakerVideo';
import SliderParticipants from './SliderParticipants';

const useStyles = makeStyles((theme) => ({
  boxSpeaker: {
    marginBottom: 30,
    height: '90%',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 85,
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: 100,
      marginRight: 100,
    },
  },
}));

export default function SpeakerView() {
  const classes = useStyles();
  const participants = useSelector((state) => roomSelectors.getParticipants(state));
  const workout = useSelector((state) => workoutsSelectors.getWorkout(state));
  const focusedUserId = useSelector((state) => roomSelectors.getFocusedUserId(state));

  const participantFullScreen = useSelector((state) =>
    roomSelectors.getParticipantFullScreen(state),
  );
  const user = useSelector((state) => roomSelectors.getUser(state));
  const dispatch = useDispatch();

  useEffect(() => {
    if (!participantFullScreen || (participantFullScreen && !participantFullScreen.id)) {
      dispatch(
        roomActions.setParticipantFullScreen(
          participants.length > 0 ? participants[0].id : user.id,
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participants, user]);

  useEffect(() => {
    if (participants && participantFullScreen && participantFullScreen.id) {
      if (participantFullScreen.id === user.id) {
        dispatch(roomActions.setParticipantFullScreen(user.id));
        return;
      }

      if (participants.find((x) => x.id === participantFullScreen.id)) {
        dispatch(
          roomActions.setParticipantFullScreen(
            participants.find((x) => x.id === participantFullScreen.id).id,
          ),
        );
      }
    }

    if (participants && participants.length === 0 && user) {
      dispatch(roomActions.setParticipantFullScreen(user.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, participants]);

  const fullParticipant = () => {
    if (participantFullScreen && participantFullScreen.id) {
      return participantFullScreen;
    }

    if (participants && participants.length > 0) {
      return participants[0];
    }

    return user;
  };

  useEffect(() => {
    if (focusedUserId) {
      dispatch(roomActions.setParticipantFullScreen(focusedUserId));
    } else {
      dispatch(roomActions.setParticipantFullScreen(fullParticipant().id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusedUserId]);

  return (
    <Box style={{ height: '100%', width: '100%' }} mt={5} display="flex" flexDirection="column">
      {!workout.isCompetition && (
        <Box display="flex" justifyContent="center" alignItems="center">
          <SliderParticipants />
        </Box>
      )}
      <Box
        display="flex"
        alignItems="flex-start"
        mt={isMobileSafari ? 12 : 1}
        px={10}
        className={classes.boxSpeaker}
      >
        {!workout.isCompetition ? (
          <SpeakerVideo style={{ borderRadius: 10 }} user={fullParticipant()} full />
        ) : (
          <SpeakerVideo style={{ borderRadius: 10 }} user={user} full />
        )}
      </Box>
    </Box>
  );
}

SpeakerView.propTypes = {};

SpeakerView.defaultProps = {};
