import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import Radio from 'core/ui/Radio';
import Typography, { fontStyle } from 'core/ui/Typography';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import { Tooltip } from '@material-ui/core';
import { Info } from '@material-ui/icons';

const LimitRegistration = ({ fieldNumber, fieldType, fieldRadio, watch, setValue, bordered }) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (!watch(fieldType)) {
      setValue(fieldType, 'minutes');
    }
    if (!watch(`${fieldType}After`)) {
      setValue(`${fieldType}After`, 'minutes');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box display="flex" alignItems="center" my={3} px={2}>
      <Box mr={2}>
        <Typography style={fontStyle.label} mb={1} noWrap>
          {t('workout.label.limitRegistration').toUpperCase()}
        </Typography>
        <Box display="flex" alignItems="center">
          <Radio
            checked={watch(fieldRadio) === true}
            label={t('button.yes')}
            onClick={() => setValue(fieldRadio, true)}
          />
          <Box ml={3}>
            <Radio
              checked={!watch(fieldRadio)}
              label={t('button.no')}
              onClick={() => setValue(fieldRadio, false)}
            />
          </Box>
        </Box>
      </Box>
      <Box>
        {watch(fieldRadio) && (
          <Box m={1} mt={2}>
            <Typography style={fontStyle.label} mb={1}>
              {t('workout.limit.before.number').toUpperCase()}{' '}
              <Tooltip placement="left" title={t('workout.limitRegistration.beforeInfo')}>
                <Info fontSize="small" />
              </Tooltip>
            </Typography>
            <Box display="flex" alignItems="center">
              <Box>
                <TextFieldRounded
                  onChange={(v) => setValue(fieldNumber, v)}
                  value={watch(fieldNumber)}
                  type="number"
                  pattern="[0-9]*"
                  bordered={bordered}
                />
              </Box>

              <Box ml={1}>
                <SelectBoxObject
                  emptyItem={false}
                  propValue="id"
                  propLabel="name"
                  options={['minutes', 'hours', 'days'].map((x) => ({
                    id: x,
                    name: t(`workout.limit.type.${x}`),
                  }))}
                  setValue={(v) => setValue(fieldType, v)}
                  value={watch(fieldType)}
                  bordered={bordered}
                />
              </Box>
            </Box>
          </Box>
        )}
        {watch(fieldRadio) && (
          <Box m={1} mt={2}>
            <Typography style={fontStyle.label} mb={1}>
              {t('workout.limit.after.number').toUpperCase()}
              <Tooltip placement="left" title={t('workout.limitRegistration.afterInfo')}>
                <Info fontSize="small" />
              </Tooltip>
            </Typography>
            <Box display="flex" alignItems="center">
              <Box>
                <TextFieldRounded
                  onChange={(v) => setValue(`${fieldNumber}After`, v)}
                  value={watch(`${fieldNumber}After`)}
                  type="number"
                  pattern="[0-9]*"
                  bordered={bordered}
                />
              </Box>

              <Box ml={1}>
                <SelectBoxObject
                  emptyItem={false}
                  propValue="id"
                  propLabel="name"
                  options={['minutes', 'hours', 'days'].map((x) => ({
                    id: x,
                    name: t(`workout.limit.type.${x}`),
                  }))}
                  setValue={(v) => setValue(`${fieldType}After`, v)}
                  value={watch(`${fieldType}After`)}
                  bordered={bordered}
                />
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default LimitRegistration;

LimitRegistration.propTypes = {};

LimitRegistration.defaultProps = {};
