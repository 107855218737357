import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import { FormControlLabel, Radio } from '@material-ui/core';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import Typography, { fontStyle } from 'core/ui/Typography';
import { ButtonPrimary } from 'core/ui/Button';
import useForm from './useForm';

const ModalFormList = ({ item, callback, mode, ownerableType, ownerableId }) => {
  const { t } = useTranslation();

  const { setValue, watch, handleSubmit, onSubmit, errors, loading } = useForm({
    mode,
    item,
    callback,
    ownerableType,
    ownerableId,
  });
  return (
    <>
      <Box mb={3}>
        <TextFieldRounded
          value={watch('name')}
          onChange={(v) => setValue('name', v)}
          placeholder={t('form.product.label.name')}
          error={errors.name}
          label={t('form.product.label.name').toUpperCase()}
          required
        />
      </Box>
      <Box mb={3} px={1}>
        <Typography style={fontStyle.label} variant="body1">
          {t('form.product.label.active').toUpperCase()}
        </Typography>
        <Box display="flex">
          <FormControlLabel
            onClick={() => setValue('active', true)}
            control={<Radio color="primary" size="small" checked={!!watch('active')} />}
            label="Yes"
          />
          <FormControlLabel
            onClick={() => setValue('active', false)}
            control={<Radio color="primary" size="small" checked={!watch('active')} />}
            label="No"
          />
        </Box>
      </Box>

      <Box mt={3} display="flex" justifyContent="flex-end">
        <ButtonPrimary disabled={loading} onClick={handleSubmit(onSubmit)}>
          {t('button.save')}
        </ButtonPrimary>
      </Box>
    </>
  );
};

ModalFormList.defaultProps = {
  callback: () => {},
  item: null,
  mode: 'new',
};

ModalFormList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  callback: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.object,
  mode: PropTypes.string,
};

export default ModalFormList;
