import React from 'react';
import PropTypes from 'prop-types';
import Box from 'core/ui/Box';
import { Dialog, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import Typography from 'core/ui/Typography';
import Form from 'components/Account/Form';
import { secondary } from 'core/ui/Colors';

const ModalAddChannelPaymentMethod = ({
  textHeader,
  setOpen,
  open,
  allowClose,
  ownerableId,
  userId,
  ownerableType,
  onClose,
  onRefresh,
  onlyMethod,
}) => (
  <Dialog open={open} maxWidth="xs" fullWidth onClose={() => (allowClose ? setOpen(false) : null)}>
    <Box style={{ backgroundColor: secondary }}>
      {onClose && (
        <Box display="flex" justifyContent="flex-end">
          <IconButton onClick={() => onClose()}>
            <Close style={{ color: 'white' }} />
          </IconButton>
        </Box>
      )}
      <Box m={5}>
        {textHeader && <Typography mb={2}>{textHeader}</Typography>}
        <Form
          setView={() => {}}
          setSelectedPaymentMethod={() => onRefresh()}
          ownerableId={ownerableId}
          ownerableType={ownerableType}
          userId={userId}
          closeModal={onClose}
          onlyMethod={onlyMethod}
        />
      </Box>
    </Box>
  </Dialog>
);

ModalAddChannelPaymentMethod.propTypes = {
  onClose: PropTypes.func,
  setOpen: PropTypes.func,
  allowClose: PropTypes.bool,
  open: PropTypes.bool,
  textHeader: PropTypes.string,
};

ModalAddChannelPaymentMethod.defaultProps = {
  setOpen: () => {},
  onClose: () => {},
  open: true,
  allowClose: true,
  textHeader: null,
};

export default ModalAddChannelPaymentMethod;
