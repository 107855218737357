import { useEffect } from 'react';
import { useForm } from 'react-hook-form-v5';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import notifications from 'modules/notifications';
import { useDispatch } from 'react-redux';
import useSelectors from 'modules/map/useSelectors';
import { formatDateToApi } from 'core/utils/formatters';
import { timeZoneName } from 'core/utils/formatters/date';
import { newRecurringsModule } from 'modules/recurrings/new';

export default ({ recurringsIds, callbackSave }) => {
  const { request } = useActions();
  const dispatch = useDispatch();
  const loading = useSelectors(newRecurringsModule, 'loading');
  const { register, unregister, handleSubmit, setValue, watch, reset, errors } = useForm();

  const validate = () => {
    const errorsValidation = [];

    if (errorsValidation.length > 0) {
      dispatch(notifications.warning(errorsValidation.join(' ')));
      return false;
    }
    return true;
  };

  const onSubmit = (values) => {
    if (!validate()) {
      return;
    }

    const updateStartDate = values?.updateStartDate
      ? formatDateToApi(values?.updateStartDate, timeZoneName())
      : null;

    const data = {
      ...values,
      recurringsIds,
      updateStartDate,
    };

    request({
      action: Modules.newRecurrings.actions.bulkUpdateWorkoutsByRecurrings,
      data,
      options: {
        onSuccess: (workoutResp) => {
          if (callbackSave) {
            callbackSave(workoutResp);
          }
        },
      },
    });
  };

  useEffect(() => {
    register('updateTemplates');
    register('updateStartDate', { required: true });
    register('selectedFields');

    setTimeout(() => {
      setValue('updateTemplates', true);
    }, 200);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register]);

  const toggleField = (item) => {
    const { field } = item;

    const fields = watch('selectedFields') || [];
    if (fields.find((x) => x === field)) {
      setValue(
        'selectedFields',
        fields.filter((x) => x !== field),
      );

      item.registerFields.forEach((x) => {
        const fName = Array.isArray(x) ? x[0] : x;
        unregister(fName);
      });
    } else {
      item.registerFields.forEach((x) => {
        const fName = Array.isArray(x) ? x[0] : x;
        register(fName);

        if (Array.isArray(x)) {
          setTimeout(() => {
            setValue(fName, x[1]);
          }, 100);
        }
      });

      setValue('selectedFields', [...fields, field]);
    }
  };

  return {
    register,
    setValue,
    watch,
    reset,
    errors,
    onSubmit,
    handleSubmit,
    submitForm: handleSubmit(onSubmit),
    loading,
    toggleField,
  };
};
