import React, { useEffect, useState } from 'react';
import { ButtonPrimary } from 'core/ui/Button';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import Typography from 'core/ui/Typography';
import { Paper, IconButton, Collapse } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { channelsSelectors } from 'modules/channels';
import { Add, Edit, KeyboardArrowDown, KeyboardArrowRight } from '@material-ui/icons';
import LoaderSm from 'core/ui/LoaderSm';
import useSelectors from 'modules/map/useSelectors';
import { channelsStagesModule } from 'modules/channelsStages';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Divider from 'core/ui/Divider';
import { reorder } from 'core/utils/helpers';
import ModalForm from './Form/Modal';

const ChannelsStageList = () => {
  const { t } = useTranslation();
  const channel = useSelector((state) => channelsSelectors.getChannel(state));
  const data = useSelectors(channelsStagesModule, 'data');
  const loading = useSelectors(channelsStagesModule, 'loading');
  const { request } = useActions();

  const [collapse, setCollapse] = useState(false);
  const [modal, setModal] = useState({
    open: false,
  });

  useEffect(() => {
    if (channel && channel.id) {
      request({
        action: Modules.channelsStages.actions.getChannelsStages,
        data: { channelId: channel.id },
      });
    }
  }, [request, channel]);

  const openModal = () => {
    setModal((prev) => ({ ...prev, mode: 'new', open: true }));
  };

  const onEditClick = (item) => {
    setModal((prev) => ({ ...prev, item, mode: 'edit', open: true }));
  };

  const setItems = (items) => {
    request({
      action: Modules.channelsStages.actions.saveStagesOrder,
      data: {
        channelId: channel?.id,
        items: items?.map((x) => x.id),
      },
    });
    request({
      action: Modules.channelsStages.actions.setStagesData,
      data: items,
    });
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newItems = reorder([...data], result.source.index, result.destination.index);

    setItems(newItems?.map((x, idx) => ({ ...x, showOrder: idx })));
  };

  return (
    <Box>
      <Paper className="tw-rounded-md">
        <Box className="tw-flex tw-cursor-pointer tw-flex-row tw-items-center tw-gap-4 tw-p-4">
          <Box flexGrow={1} onClick={() => setCollapse(!collapse)}>
            <Typography variant="h5">{t('label.channel.stages')}</Typography>
          </Box>
          <LoaderSm loading={loading} />
          <Box>
            <ButtonPrimary onClick={() => openModal()}>
              <Box display="flex" alignItems="flex-end">
                <Add size="small" />
                <Box alignItems="center">
                  <Typography component="span" mr={1}>
                    {t('button.createStage')}
                  </Typography>
                </Box>
              </Box>
            </ButtonPrimary>
          </Box>
          <Box onClick={() => setCollapse(!collapse)}>
            {collapse ? (
              <KeyboardArrowDown fontSize="large" color="secondary" />
            ) : (
              <KeyboardArrowRight fontSize="large" color="secondary" />
            )}
          </Box>
        </Box>

        <Collapse in={collapse}>
          <div className="tw-mt-1 tw-pb-2">
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {data.map((item, index) => (
                      <Draggable key={item.id} draggableId={`${item.id}`} index={index}>
                        {(draggableProvided, draggableSnapshot) => (
                          <div
                            ref={draggableProvided.innerRef}
                            {...draggableProvided.draggableProps}
                            {...draggableProvided.dragHandleProps}
                          >
                            <Box display="flex" alignItems="center">
                              <Box display="flex" alignItems="center" flex={1} flexGrow={1}>
                                <Typography ml={2}>&nbsp;{item.name}</Typography>
                              </Box>

                              <IconButton onClick={() => onEditClick(item)}>
                                <Edit />
                              </IconButton>
                            </Box>

                            {data.length - 1 > index && <Divider my={2} />}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </Collapse>
      </Paper>
      {modal.open && (
        <ModalForm
          open={modal.open}
          item={modal.item}
          mode={modal.mode}
          channel={channel}
          close={() => setModal(() => ({ open: false }))}
          callback={() => setModal(() => ({ open: false }))}
        />
      )}
    </Box>
  );
};

export default ChannelsStageList;
