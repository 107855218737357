import { useState, createContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { sessionActions } from 'modules/session';
import { useForm } from 'react-hook-form-v5';
import notifications from 'modules/notifications';
import { channelsActions } from 'modules/channels';
import { validateFullname } from 'core/utils/helpers';

export const Context = createContext();

const useFormLogin = ({ callback, fromModal, channelId }) => {
  const { t } = useTranslation();
  const [step, setStep] = useState('submit');
  const [dataForm, setDataForm] = useState({});
  const { register, handleSubmit, errors, setValue, watch, setError } = useForm();
  const recaptchaRef = useRef();
  const input = watch('input');

  const dispatch = useDispatch();

  useEffect(() => {
    register('name');
    register('input');
    register('phone');
    register('email');
    register('isNewUser');
    register('confirmCaptcha');

    setValue('input', 'email');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register]);

  const validate = (data) => {
    if (step === 'submit') {
      if (data.input === 'phone' && !data.phone) {
        setError('phone', true);
        return false;
      }
      if (data.input === 'email' && !data.email) {
        setError('email', true);
        return false;
      }
    }

    if (step === 'newUser') {
      if (!data.phone) {
        setError('phone', true);
        return false;
      }
      if (!data.name) {
        setError('name', true);

        return false;
      }
      if (!validateFullname(data.name)) {
        dispatch(notifications.warning(t('validation.invalidFullName')));
        setError('name', true);
        return false;
      }
      if (!data.email) {
        setError('email', true);
        return false;
      }
    }

    return true;
  };

  const onSubmit = (data) => {
    if (!validate(data)) {
      return;
    }

    if (step === 'submit' || step === 'newUser') {
      if (step === 'newUser' && !recaptchaRef?.current?.getValue()) {
        dispatch(notifications.warning(t('login.validation.captcha')));
        return;
      }

      const captcha = recaptchaRef?.current?.getValue();
      if (data.input === 'phone' && data.phone && !data.phone.isValid) {
        dispatch(notifications.warning(t('phoneNumberInvalid')));
        return;
      }

      setDataForm(data);

      dispatch(
        sessionActions.signInStart(
          {
            channelId,
            name: data?.name,
            phone: data.phone ? data.phone.number : null,
            email: data.email,
            provider: data.input,
            bundleId: 'web',
            captcha,
            notSendEmail: true,
          },
          (resp) => {
            if (resp?.logged) {
              dispatch(
                sessionActions.authenticateStart((user) => {
                  if (user?.channel) {
                    dispatch(channelsActions.getChannelStart(user?.channel?.id));
                  }
                  if (callback) {
                    callback();
                  }
                }),
              );
            } else if (!resp?.new) {
              setDataForm((prev) => ({
                ...prev,
                id: resp?.id,
                pin: resp?.existsPin,
                existsEmail: resp?.existsEmail,
                isNewUser: resp?.new,
              }));
              setStep('confirm');
            } else {
              setStep('newUser');
              setValue('input', data?.input === 'phone' ? 'email' : 'phone');
            }
          },
        ),
      );

      if (recaptchaRef.current) {
        recaptchaRef.current.reset();
      }
    }

    if (step === 'confirm') {
      if (!data?.code || data?.code === '_____') {
        setError('code', true);
        return;
      }

      dispatch(
        sessionActions.validatePinStart({
          onboardingId: dataForm?.id,
          skipPin: dataForm?.skipPin,
          fromModal,
          code: data.code.replace('-', ''),
          callback,
        }),
      );
    }
  };

  const tryWithEmail = () => {
    setStep('submit');
    setValue('input', 'email');
  };

  const reSendCode = (skipPin = false) => {
    setDataForm((prev) => ({ ...prev, skipPin: true, pin: false }));

    dispatch(
      sessionActions.signInStart({
        name: dataForm.name,
        phone: dataForm.phone ? dataForm.phone.number : null,
        email: dataForm.email,
        provider: input,
        skipPin,
        sendEmail: true,
        captcha: recaptchaRef?.current?.getValue(),
      }),
    );
    dispatch(notifications.success(t('login.codeResend')));
    setValue('code', '');
  };

  return {
    tryWithEmail,
    reSendCode,
    onSubmit,
    register,
    handleSubmit,
    errors,
    setValue,
    watch,
    input,
    step,
    setStep,
    dataForm,
    recaptchaRef,
    setDataForm,
  };
};

export default useFormLogin;
