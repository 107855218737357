/* eslint-disable react/prop-types */
import React from 'react';
import { secondaryLight } from 'core/ui/Colors';
import Box from 'core/ui/Box';
import MobileTableResultsRow from './MobileTableResultsRow';

const MobileTableResults = ({
  competition,
  competitionResults,
  openModalScores,
  setOpenTeamEdit,
  removeUser,
  compWorkouts,
  withdrawUser,
}) => {
  const style = {
    borderLeft: `0.5px solid ${secondaryLight}`,
    borderRight: `0.5px solid ${secondaryLight}`,
    borderTop: `0.5px solid ${secondaryLight}`,
  };

  return (
    <Box mt={3} style={style}>
      {competitionResults.map((item) => (
        <Box key={item?.team?.id}>
          <MobileTableResultsRow
            competition={competition}
            item={item}
            openModalScores={openModalScores}
            setOpenTeamEdit={setOpenTeamEdit}
            removeUser={removeUser}
            withdrawUser={withdrawUser}
            compWorkouts={compWorkouts}
          />
        </Box>
      ))}
    </Box>
  );
};

export default MobileTableResults;
