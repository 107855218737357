import React from 'react';
import Box from 'core/ui/Box';
import Typography from 'core/ui/Typography';

export default () => (
  <Box p={5}>
    <Typography align="center" variant="h1" color="primary" m={10}>
      COMING SOON
    </Typography>
  </Box>
);
