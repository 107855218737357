/* eslint-disable react/no-danger */
import React from 'react';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import Typography, { fontStyle } from 'core/ui/Typography';
import { gymDate, time } from 'core/utils/formatters';
import ContactForm from './Form/ContactForm';

const Conversation = ({ getContactForm, channel, item }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Box className="gap-5" display="flex" flexDirection="column">
        {item?.text && (
          <div>
            <Box p={2}>
              <Typography variant="h6" mt={2}>
                {t('label.message')}
              </Typography>

              <p>{item?.text}</p>
            </Box>
          </div>
        )}
        {item?.messages?.map((itemMessage) => (
          <div key={itemMessage?.id}>
            <div className="tw-rounded-md tw-bg-white tw-p-2 tw-shadow-sm">
              <Box display="flex" className="gap-10" alignItems="center">
                <Box flexGrow={1}>
                  {!itemMessage?.isReply ? (
                    <>
                      <Typography>{channel?.name}</Typography>
                      <Typography>{itemMessage?.objectable?.contactEmail}</Typography>
                    </>
                  ) : (
                    <>
                      <Typography>{item?.name}</Typography>
                      <Typography>{item?.email}</Typography>
                      <Typography>{item?.phone}</Typography>
                    </>
                  )}
                </Box>
                <Box>
                  <Typography color="textSecondary" style={fontStyle.small}>
                    {gymDate(itemMessage?.createdAt)}&nbsp;{time(itemMessage?.createdAt)}
                  </Typography>
                </Box>
              </Box>

              <Box p={2}>
                <Typography variant="h6" mt={2}>
                  {t('label.message')}
                </Typography>

                <div dangerouslySetInnerHTML={{ __html: itemMessage?.contact?.body }} />
              </Box>
            </div>
          </div>
        ))}
        <Box display="flex" justifyContent="center" mt={2}>
          <ContactForm channel={channel} contactForm={item} callback={() => getContactForm()} />
        </Box>
      </Box>
    </Box>
  );
};

export default Conversation;
