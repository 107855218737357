import React from 'react';
import Box from 'core/ui/Box';
import Typography from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import { Timer as TimerIcon } from '@material-ui/icons';
import LabelInfo from 'core/ui/LabelInfo';
import { typeTimer } from 'core/utils/consts';
import { primary, secondary } from 'core/ui/Colors';
import Timer from '../../Timer';

const SectionTimerInfo = ({
  section,
  buttonColor,
  fontSize,
  showTimerIcon,
  showTimer,
  fontColor,
}) => {
  const { t } = useTranslation();

  if (!section?.timerData) {
    return <></>;
  }

  return (
    <Box>
      <Box>
        {section?.timerData?.type !== 'none' && (
          <>
            {section.timerData.type === typeTimer.forTime && section.timerData.unlimited ? (
              <Box display="flex" alignItems="center">
                <Typography style={{ fontSize, color: fontColor }} variant="body1">
                  {t('timer.type.forTime').toUpperCase()}
                </Typography>
                <Typography color="primary" ml={1} style={{ fontSize, fontColor }} variant="body1">
                  {t('workout.section.timer.unlimited')}
                </Typography>
              </Box>
            ) : (
              <>
                <Box display="flex" alignItems="center">
                  {showTimerIcon && <TimerIcon size="small" style={{ color: fontColor }} />}
                  <Typography mt={1} mr={1} variant="body2" style={{ fontSize, color: fontColor }}>
                    {section.timerData && t(`timer.type.${section.timerData.type}`).toUpperCase()}
                  </Typography>
                  &bull;
                  <Typography ml={1} mt={1} variant="body2" style={{ fontSize, color: fontColor }}>
                    {t(
                      section.timerData.count.toUpperCase() === 'UP'
                        ? 'timer.countUp'
                        : 'timer.countdown',
                    ).toUpperCase()}
                  </Typography>
                </Box>
                <Box display="flex" flexWrap="wrap">
                  {section.timerData.type !== typeTimer.amrap &&
                    section.timerData.type !== typeTimer.forTime && (
                      <Box m={1}>
                        <LabelInfo
                          label={t('workout.section.label.rounds')}
                          value={`${section.timerData.rounds}`}
                          labelBackgroud={buttonColor}
                          fontSize={fontSize * 0.8}
                        />
                      </Box>
                    )}

                  {!section.timerData.customInterval ? (
                    <>
                      {section.timerData.work && (
                        <Box m={1}>
                          <LabelInfo
                            label={t('workout.section.label.workTime')}
                            value={section.timerData.work}
                            labelBackgroud={buttonColor}
                            fontSize={fontSize * 0.8}
                          />
                        </Box>
                      )}
                      {section.timerData.rest && (
                        <Box m={1}>
                          <LabelInfo
                            label={t('workout.section.label.restTime')}
                            value={`${section.timerData.rest}`}
                            labelBackgroud={buttonColor}
                            fontSize={fontSize * 0.8}
                          />
                        </Box>
                      )}
                    </>
                  ) : (
                    <>
                      {section.timerData.customIntervalTimers.map((ct, i) => (
                        <Box key={i.toString()}>
                          <Typography
                            color="primary"
                            align="center"
                            variant="body2"
                            style={{ fontSize, fontColor }}
                          >
                            {t('round')}&nbsp;{i + 1}
                          </Typography>
                          <Box m={1}>
                            <LabelInfo
                              label={t('workout.section.label.workTime')}
                              value={ct.work}
                              labelBackgroud={buttonColor}
                              fontSize={fontSize * 0.8}
                            />
                          </Box>

                          {ct.rest && (
                            <Box m={1}>
                              <LabelInfo
                                label={t('workout.section.label.restTime')}
                                value={`${ct.rest}`}
                                labelBackgroud={buttonColor}
                                fontSize={fontSize * 0.8}
                              />
                            </Box>
                          )}
                        </Box>
                      ))}
                    </>
                  )}
                </Box>
                {showTimer && (
                  <Box mt={5} display="flex" justifyContent="center">
                    <Timer section={section} preview colorLabel="white" />
                  </Box>
                )}
              </>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

SectionTimerInfo.defaultProps = {
  buttonColor: primary,
  fontColor: secondary,
  fontSize: 16,
  showTimerIcon: true,
  showTimer: true,
};

export default SectionTimerInfo;
