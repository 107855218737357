import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import { ButtonPrimary } from 'core/ui/Button';
import { useDispatch, useSelector } from 'react-redux';
import notifications from 'modules/notifications';
import Typography from 'core/ui/Typography';
import { workoutsActions, workoutsSelectors } from 'modules/workouts';
import DatePickerRounded from 'core/ui/DatePickers/DatePickerRounded';
import TimePickerRounded from 'core/ui/DatePickers/TimePickerRounded';
import { timeZoneName, formatDateToApi } from 'core/utils/formatters/date';
import LoaderSm from 'core/ui/LoaderSm';
import { getVideoTypeByLink } from 'core/utils/helpers';
import SelectorUser from 'components/Profile/SelectorUser';
import CardUser from 'components/Channel/SelectorChannelUser/Card';
import Paper from 'core/ui/Paper';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { workoutInviteTypes } from 'core/utils/consts';
import Grid from 'core/ui/Grid';
import { sessionActions, sessionSelectors } from 'modules/session';
import LiveInvitePlans from './LiveInvitePlans';
import useCloneWorkout from './useCloneWorkout';

export default function CustomLiveInvite({ workout, callback, close, isOwner, goToWorkout }) {
  const { t } = useTranslation();
  const currentUser = useSelector((state) => sessionSelectors.getUser(state));
  const { clone: cloneWorkout } = useCloneWorkout();
  const loadingInvite = useSelector((state) =>
    workoutsSelectors.getLoadingWorkoutRecordingInvites(state),
  );
  const dispatch = useDispatch();
  const [data, setData] = useState({ screen: '' });
  const [clone, setClone] = useState(false);

  const setUser = (user) => {
    setData((prev) => ({ ...prev, user }));
  };

  const setScreen = () => {
    if (!currentUser.hasLiveMembership && currentUser.allowCreateLive) {
      setData((prev) => ({ ...prev, screen: 'firstAccess' }));
    } else if (!currentUser.hasLiveMembership && !currentUser.allowCreateLive) {
      setData((prev) => ({ ...prev, screen: 'purchasePlan' }));
    } else {
      setData((prev) => ({ ...prev, screen: 'createInvite' }));
    }
  };

  useEffect(() => {
    if (workout?.invites?.length > 0) {
      setData({ screen: 'confirmClone' });
    } else {
      setScreen();
    }
    setClone(!isOwner || workout?.invites?.length > 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workout, isOwner, currentUser]);

  const validate = () => {
    if (!data.scheduledAtDate || !data.scheduledAtHour) {
      dispatch(notifications.warning(t('workout.validation.scheduleAt')));
      return false;
    }
    if (!data.user) {
      dispatch(notifications.warning(t('workout.custom.invite.selectUser')));
      return false;
    }

    return true;
  };

  const refreshUser = () => {
    dispatch(sessionActions.authenticateStart());
  };

  const createInvite = () => {
    if (!validate()) {
      return;
    }
    const timezone = timeZoneName();
    const scheduledAt = formatDateToApi(
      `${data.scheduledAtDate.substr(0, 10)} ${data.scheduledAtHour.substr(11, 8)}`,
      timezone,
    );

    const dataInvite = {
      workoutId: workout?.id,
      scheduledAt,
      mainUserId: currentUser?.id,
      user: data?.user,
      invites: [{ userId: data?.user?.id }],
      inviteType: workoutInviteTypes.live_one_to_one,
    };
    if (!clone) {
      dispatch(
        workoutsActions.postWorkoutRecordingInvitesStart(dataInvite, () => {
          if (callback) {
            callback();
          }
          refreshUser();
          dispatch(notifications.success(t('workout.invite.recording.success')));
          goToWorkout(workout);
        }),
      );
    } else {
      cloneWorkout({
        workout: { ...workout, name: `${workout?.name} - ${data?.user?.name}` },
        dataInvite,
        callback: (workoutResp) => {
          dispatch(sessionActions.authenticateStart(currentUser?.id));
          callback(workoutResp);
          goToWorkout(workoutResp);
          close();
        },
      });
    }
  };

  const videoLink = workout?.videoLink ? getVideoTypeByLink(workout?.videoLink) : null;
  const showMessageApp = videoLink && ['youtube', 'vimeo'].find((x) => x === videoLink.type);

  return (
    <div>
      <Typography mb={3} variant="h5" align="center" color="primary">
        {t('button.workoutInviteLive')}
      </Typography>
      {data.screen === 'confirmClone' && (
        <Box mb={5}>
          <Typography>{t('workout.invite.liveOneToOne.confirmClone')}</Typography>

          <Grid container spacing={3} mt={3}>
            <Grid item xs={6} md={6}>
              <ButtonPrimary fullWidth onClick={() => close()}>
                {t('button.noThanks')}
              </ButtonPrimary>
            </Grid>
            <Grid item xs={6} md={6}>
              <ButtonPrimary fullWidth onClick={() => setScreen()}>
                {t('button.yes')}
              </ButtonPrimary>
            </Grid>
          </Grid>
        </Box>
      )}
      {data.screen === 'firstAccess' && (
        <Box mb={5}>
          <Typography>{t('workout.invite.liveOneToOne.membership')}</Typography>

          <Grid container spacing={3} mt={3}>
            <Grid item xs={6} md={6}>
              <ButtonPrimary fullWidth onClick={() => close()}>
                {t('button.cancel')}
              </ButtonPrimary>
            </Grid>
            <Grid item xs={6} md={6}>
              <ButtonPrimary
                fullWidth
                onClick={() => setData((prev) => ({ ...prev, screen: 'createInvite' }))}
              >
                {t('button.soundsGood')}
              </ButtonPrimary>
            </Grid>
          </Grid>
        </Box>
      )}

      {data.screen === 'createInvite' && (
        <>
          {showMessageApp && (
            <Typography mb={5} mx={3} variant="h5" color="primary">
              {t('workout.videoLink.alertApp').toUpperCase()}
            </Typography>
          )}
          <Box display="flex" justifyContent="center" alignItems="center">
            <Box m={1}>
              <DatePickerRounded
                name="scheduledAt"
                type="datetime-local"
                label={t('workout.label.scheduledAtDate').toUpperCase()}
                onChange={(v) => setData((prev) => ({ ...prev, scheduledAtDate: v }))}
                value={data.scheduledAtDate}
                required
              />
            </Box>

            <Box m={1}>
              <TimePickerRounded
                name="scheduledAt"
                type="datetime-local"
                label={t('workout.label.scheduledAtTime').toUpperCase()}
                onChange={(v) => setData((prev) => ({ ...prev, scheduledAtHour: v }))}
                value={data.scheduledAtHour}
                required
              />
            </Box>
          </Box>
          {data?.user ? (
            <Box mt={5}>
              <Paper p={3} elevation={4} className="paper-rounded" style={{ position: 'relative' }}>
                <Box display="flex" flexWrap="wrap">
                  <IconButton
                    style={{ position: 'absolute', right: 0, top: 0 }}
                    onClick={() => setUser(null)}
                  >
                    <Close />
                  </IconButton>
                  <CardUser user={data?.user} />
                </Box>
              </Paper>
            </Box>
          ) : (
            <Paper p={3} mt={5} justifyContent="center">
              <Typography align="center" variant="h6" color="secondary">
                {t('select.user.message').toUpperCase()}
              </Typography>
              <SelectorUser onSelectedUser={(u) => setUser(u)} hideTexture />
            </Paper>
          )}
          <Box display="flex" mt={5} justifyContent="flex-end">
            <ButtonPrimary disabled={loadingInvite} onClick={() => createInvite()} fullWidth>
              <LoaderSm loading={loadingInvite} />
              {t('button.sendInvite')}
            </ButtonPrimary>
          </Box>
        </>
      )}
      {data.screen === 'purchasePlan' && (
        <>
          <Typography align="center" variant="h6" mb={3} color="secondary">
            {t('live.oneToOne.purchase.text').toUpperCase()}
          </Typography>

          <LiveInvitePlans purchasedPlanCallback={() => refreshUser()} />
        </>
      )}
    </div>
  );
}

CustomLiveInvite.propTypes = {};
