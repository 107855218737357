import React, { useState } from 'react';
import Box from 'core/ui/Box';
import { Container } from '@material-ui/core';
import Header from 'components/Profile/Dashboard/Header';
import { DashboardContext } from 'contexts';
import { parseDate } from 'core/utils/formatters';
import Tabs from './Tabs';
import MyCalendar from './MyCalendar';
import PersonalRecord from './PersonalRecord';
import Benchmark from './Benchmark';
import Workouts from './Workouts';
import Account from './ManageAccount';
import PrivateSessions from './MyCalendar/PrivateSessions/index';
import Coaches from './Coaches';
import Notifications from './Messages';

export default ({ defaultTab }) => {
  const [tab, setTab] = useState(defaultTab || 'myCalendar');

  const today = parseDate(new Date()).format('YYYY-MM-DD');
  const [currentDate, setCurrentDate] = useState(today);

  return (
    <>
      <Header />
      <DashboardContext.Provider value={{ tab, setTab, currentDate, setCurrentDate }}>
        <Container maxWidth="xl">
          <Box my={3}>
            <Tabs setTab={setTab} tab={tab} />
          </Box>
          <Box pb={10}>
            {tab === 'myCalendar' && <MyCalendar />}
            {tab === 'invites' && <PrivateSessions />}
            {tab === 'personalRecord' && <PersonalRecord />}
            {tab === 'benchmark' && <Benchmark />}
            {tab === 'workouts' && <Workouts />}
            {tab === 'coaches' && <Coaches />}
            {tab === 'manageAccount' && <Account />}
            {tab === 'notifications' && <Notifications />}
          </Box>
        </Container>
      </DashboardContext.Provider>
    </>
  );
};
