import useActions from 'modules/map/useActions';
import Modules from 'modules';
import { useDispatch, useSelector } from 'react-redux';
import useSelectors from 'modules/map/useSelectors';
import { newWorkoutsModule } from 'modules/workouts/new';
import { formatDateToApi } from 'core/utils/formatters';
import moment from 'moment';
import { sessionSelectors } from 'modules/session';
import { workoutInviteTypes } from 'core/utils/consts';
import notifications from 'modules/notifications';

export default () => {
  const { request } = useActions();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => sessionSelectors.getUser(state));
  const loading = useSelectors(newWorkoutsModule, 'loading');

  const createWorkoutRequest = ({ data, callback }) => {
    request({
      action: Modules.newWorkouts.actions.createWorkout,
      data,
      options: {
        onSuccess: (workoutResp) => {
          if (callback) {
            callback(workoutResp);
          }
        },
      },
    });
  };

  const cloneFromModal = ({ workout, newParams, callback }) => {
    setTimeout(() => {
      const data = {
        id: null,
        durationMinutes: 60,
        coachesAttributes: [currentUser?.id],
        sectionsAttributes: workout?.sections?.map((s) => ({
          ...s,
          id: null,
        })),
        movementsAttributes:
          workout.movements &&
          workout.movements.map((x) => ({
            name: x?.movement?.name,
            movement: x.movementAlt ? x.movementAlt.name || x.movementAlt : null,
            embedVideo: x.embedVideo,
          })),
        equipmentAttributes:
          workout.equipment &&
          workout.equipment.map((x) => ({
            name: x.name ? x.name : x,
            equipment: x.equipment ? x.equipment.name || x.equipment : null,
          })),
        ...newParams,
      };

      request({
        action: Modules.newWorkouts.actions.cloneFromModal,
        data,
        options: {
          onSuccess: (workoutResp) => {
            if (callback) {
              callback(workoutResp);
            }
          },
          onError: (msg) => {
            dispatch(notifications.error(msg));
          },
        },
      });
    }, 500);
  };

  const clone = ({ workout, dataInvite, callback }) => {
    const invites = [];
    if (dataInvite && dataInvite.inviteType === workoutInviteTypes.live_one_to_one) {
      invites.push({
        userId: dataInvite?.user?.id,
        scheduledAt: dataInvite?.scheduledAt,
        mainUserId: dataInvite?.mainUserId,
        invited: false,
        inviteType: dataInvite.inviteType,
      });
    }

    if (
      dataInvite &&
      dataInvite.users &&
      dataInvite.inviteType !== workoutInviteTypes.live_one_to_one
    ) {
      dataInvite.users.forEach((user) => {
        invites.push({
          userId: user?.id,
          invited: false,
          scheduledAt: dataInvite?.scheduledAt,
          mainUserId: dataInvite?.mainUserId,
          inviteType: dataInvite.inviteType,
        });
      });
    }

    setTimeout(() => {
      const data = {
        name: workout?.name,
        id: null,
        method: 'custom',
        durationMinutes: 60,
        coachesAttributes: [currentUser?.id],
        invitesAttributes: invites,
        sectionsAttributes: workout?.sections?.map((s) => ({
          ...s,
          id: null,
          mediaFilesAssociationsAttributes: s?.mediaFilesAssociations?.map((ass) => ({
            mediaFileId: ass.mediaFile?.id,
            mediaFile: ass.mediaFile,
            kind: ass.kind,
          })),
        })),
      };

      data.scheduledAt = dataInvite?.scheduledAt || formatDateToApi(moment());

      createWorkoutRequest(data, callback);
    }, 500);
  };

  return {
    clone,
    loading,
    cloneFromModal,
  };
};
