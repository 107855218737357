import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import UserIcon from '@material-ui/icons/Person';
import Slide from '@material-ui/core/Slide';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import Texture from 'core/ui/Texture';
import Scrollbars from 'react-custom-scrollbars';
import { channelMembersActions, channelMembersSelectors } from 'modules/channelMembers';
import { useDispatch, useSelector } from 'react-redux';
import { channelsSelectors } from 'modules/channels';
import ConfirmDialog from 'core/ui/ConfirmDialog';
import notifications from 'modules/notifications';
import Children from 'components/Profile/Dashboard/Account/Children';
import ModalAddChild from 'components/Profile/Dashboard/Account/Children/ModalForm';
import Paper from 'core/ui/Paper';
import { Tooltip } from '@material-ui/core';
import Grid from 'core/ui/Grid';
import { primary } from 'core/ui/Colors';

import UserInfo from './UserInfo';
import SkeletonMemberInfo from './Skeleton';
import { MembersContext } from '..';
import MemberNotes from './MemberNotes';
import MemberCredits from './MemberCredits';
import MemberHouseHold from './MemberHouseHold';
import MemberWorkouts from './MemberWorkouts';
import MemberPayments from './MemberPayments';
import MemberMemberships from './MemberMemberships';
import MemberDocuments from './MemberDocuments';
import ChannelUserContracts from './Contracts';
import ModalAddPayment from './FormPayment/Modal';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const ModalMembershipInformation = ({ userId, open, close }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [modalAddPayment, setModalAddPayment] = useState({ open: false });

  const { setModalFormKey } = useContext(MembersContext);
  const [modalNote, setModalNote] = useState({ open: false });
  const [modalHouseHold, setModalHouseHold] = useState({ open: false });
  const dispatch = useDispatch();
  const channel = useSelector((state) => channelsSelectors.getChannel(state));
  const member = useSelector((state) => channelMembersSelectors.getChannelMember(state));
  const loading = useSelector((state) => channelMembersSelectors.getLoadingMember(state));
  const loadingChannelKeys = useSelector((state) => channelMembersSelectors.getLoading(state));
  const [modalChild, setModalChild] = React.useState({ open: false });
  const loadingMember = loading || loadingChannelKeys;
  const [confirmCancel, setConfirmCancel] = useState({ open: false });
  const [openModalMovement, setOpenModalMovement] = useState(false);

  const buttonColor = channel?.customColors?.buttonColor || primary;

  const getMemberInfo = () => {
    if (channel && channel.id) {
      dispatch(channelMembersActions.getChannelMemberStart(channel.id, userId));
    }
  };

  useEffect(() => {
    if (userId && channel?.id) {
      getMemberInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, userId, channel?.id]);

  const openModalAddMembership = () => {
    setModalFormKey((prev) => ({
      ...prev,
      open: true,
      channelKey: {},
      mode: 'new',
      user: member,
    }));

    if (!member.hasDefaultPaymentMethod) {
      dispatch(notifications.warning(t('channel.key.add.message.noCard')));
    }
    if (member.hasPendingAch) {
      dispatch(notifications.warning(t('channel.key.add.message.pendingAch')));
    }
  };

  return (
    <Dialog
      style={{ marginTop: 100 }}
      fullScreen
      TransitionComponent={Transition}
      open={!!open}
      onClose={close}
    >
      <ConfirmDialog
        open={confirmCancel.open}
        confirm={() => confirmCancel.confirm()}
        cancel={() => setConfirmCancel({ open: false })}
        description={confirmCancel.description}
        labelConfirm={t('button.confirm')}
        labelCancel={t('button.cancel')}
      />

      {modalAddPayment.open && (
        <ModalAddPayment
          member={member}
          channel={channel}
          open={modalAddPayment.open}
          channelKey={modalAddPayment.channelKey}
          close={() => setModalAddPayment((prev) => ({ ...prev, open: false }))}
          callback={() =>
            dispatch(channelMembersActions.getChannelMemberStart(channel.id, member.id))
          }
        />
      )}
      <AppBar className={classes.appBar} color="secondary">
        <Toolbar>
          <UserIcon />
          <Tooltip
            placement="right-end"
            title={
              <p>
                {member?.id} &nbsp;-&nbsp;
                {member?.name}
              </p>
            }
          >
            <Typography variant="h6" className={classes.title}>
              {t('channel.tab.members.manage')}&nbsp;
              {member?.name}
            </Typography>
          </Tooltip>
          <IconButton edge="start" color="inherit" onClick={close} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Scrollbars
        allowFullScreen
        hidden={false}
        autoHide={false}
        hideTracksWhenNotNeeded={false}
        universal
      >
        <Texture style={{ minHeight: '100%' }}>
          <Box px={5} py={3}>
            {!member || loadingMember ? (
              <Box>
                <SkeletonMemberInfo />
              </Box>
            ) : (
              <>
                <UserInfo
                  user={member}
                  openModalAddMembership={openModalAddMembership}
                  setModalNote={setModalNote}
                  setModalHouseHold={setModalHouseHold}
                  setModalChild={setModalChild}
                  setOpenModalMovement={setOpenModalMovement}
                  getMemberInfo={getMemberInfo}
                  setModalAddPayment={setModalAddPayment}
                />

                <Box mb={3}>
                  <MemberNotes
                    channelId={channel.id}
                    memberId={member.id}
                    modalNote={modalNote}
                    setModalNote={setModalNote}
                  />
                </Box>
                {member?.householdUser?.id && (
                  <Paper p={3} mb={3}>
                    <Typography variant="h5" mb={3}>
                      {t('channel.member.houseHoldPrimary')}
                    </Typography>
                    <Typography color="textSecondary">{member?.householdUser?.name}</Typography>
                    <Typography color="textSecondary">
                      Default Payment Info: {member?.householdUser?.defaultPaymentInfo}
                    </Typography>
                  </Paper>
                )}
                <Grid container spacing={4} mb={3}>
                  <Grid item xs={12} md={6}>
                    <MemberCredits
                      channel={channel}
                      userId={member.id}
                      openModalMovement={openModalMovement}
                      closeModalMovement={() => setOpenModalMovement(false)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {!member?.child && (
                      <MemberHouseHold
                        member={member}
                        modalHouseHold={modalHouseHold}
                        setModalHouseHold={setModalHouseHold}
                        channelId={channel?.id}
                      />
                    )}
                  </Grid>
                </Grid>

                {!member.hasDefaultPaymentMethod && (
                  <Typography color="primary" m={3} mx={5} variant="h5" align="center">
                    {t('channel.key.add.message.noCard')}
                  </Typography>
                )}
                {member.hasPendingAch && (
                  <Typography color="primary" m={3} mx={5} variant="h5" align="center">
                    {t('channel.key.add.message.pendingAch')}
                  </Typography>
                )}

                {member && (
                  <Box mb={3}>
                    <MemberMemberships
                      setModalFormKey={setModalFormKey}
                      channel={channel}
                      member={member}
                      setModalAddPayment={setModalAddPayment}
                    />
                  </Box>
                )}

                {member && (
                  <Box mb={3}>
                    <MemberPayments channel={channel} user={member} />
                  </Box>
                )}

                <Box mb={3}>
                  <MemberDocuments channel={channel} user={member} buttonColor={buttonColor} />
                </Box>

                <Box mb={3}>
                  <ChannelUserContracts channel={channel} user={member} buttonColor={buttonColor} />
                </Box>

                {modalChild.open && (
                  <ModalAddChild
                    user={member}
                    close={() => setModalChild(false)}
                    open
                    child={modalChild.child}
                    callback={() => getMemberInfo()}
                  />
                )}

                {!!member.hasChildren && (
                  <Box mb={3}>
                    <Children
                      userId={member.id}
                      setEditChild={(child) => setModalChild({ child, open: true })}
                    />
                  </Box>
                )}
                <Box mb={3}>
                  <MemberWorkouts channel={channel} user={member} limit={6} upcoming />
                </Box>
              </>
            )}
          </Box>
        </Texture>
      </Scrollbars>
    </Dialog>
  );
};

ModalMembershipInformation.defaultProps = {
  userId: null,
  open: false,
  close: () => {},
};

export default ModalMembershipInformation;
