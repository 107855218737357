import React, { useContext } from 'react';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import Typography, { fontStyle } from 'core/ui/Typography';
import { useSelector } from 'react-redux';
import { channelsSelectors } from 'modules/channels';

import GymGymManagementImg from 'assets/img/plan_gym_management.png';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { successColor } from 'core/ui/Colors';
import { PlanManageContext } from 'contexts';
import { currency } from 'core/utils/formatters';
import Checkbox from 'core/ui/Checkbox';
import { styleBoxPlanImage, styleBoxPlanInfo } from './styles';

const GymManagement = ({ plan }) => {
  const { t } = useTranslation();
  const channel = useSelector((state) => channelsSelectors.getChannel(state));
  const planContext = useContext(PlanManageContext);

  const calcTotalInPerson = () => {
    const planAmount = planContext?.annualRenew
      ? plan.annualAmountInPerson
      : Number(plan.amountInPerson);

    return currency(channel?.activePlans?.free?.active ? 0 : planAmount);
  };

  const ItemPlan = ({ label }) => (
    <Box display="flex" alignItems="center">
      <CheckCircleOutlineIcon style={{ color: successColor, fontSize: 14 }} />
      <Typography ml={2} style={fontStyle.large} className="roboto-regular">
        {label}
      </Typography>
    </Box>
  );

  return (
    <Box p={5} style={{ width: '100%' }} justifyContent="center" alignItems="center">
      <Box display="flex" justifyContent="center" style={styleBoxPlanImage}>
        <img src={GymGymManagementImg} alt="Profile" />
      </Box>

      <Typography my={2} align="center" variant="h5">
        {t('channel.plan.in_person')}
      </Typography>

      <Box style={styleBoxPlanInfo}>
        <Box style={{ width: 200 }}>
          <ItemPlan label={t('channel.plan.new.webAccess')} />
          <ItemPlan label={t('channel.plan.new.communityApp')} />
          <ItemPlan label={t('channel.plan.new.unlimitedMembers')} />
          <ItemPlan label={t('channel.plan.new.unlimitedMemberships')} />
          <ItemPlan label={t('channel.plan.new.unlimitedCoaches')} />
          <ItemPlan label={t('channel.plan.new.eCommerceStore')} />
          <ItemPlan label={t('channel.plan.new.noAdditionalFees')} />
        </Box>
      </Box>

      <Box my={1} display="flex" alignItems="center" justifyContent="center">
        <Box my={1} display="flex" justifyContent="center">
          <Checkbox
            checked={planContext?.plans.find((x) => x.plan === 'in_person')}
            onClick={() =>
              planContext?.togglePlan('in_person', !channel?.activePlans?.inPerson?.active)
            }
            size={40}
            disabled={
              channel.showRenewButton ||
              (channel?.activePlans?.inPerson?.active && !channel?.activePlans?.inPerson?.renew)
            }
          />
        </Box>

        <Box display="flex" justifyContent="center" alignItems="flex-end">
          <Typography component="span" variant="h2" color="secondary">
            {t('prefix')}
            {calcTotalInPerson()}
          </Typography>
          <Typography
            component="span"
            variant="h2"
            pb={1}
            color="secondary"
            style={{ ...fontStyle.medium }}
          >
            /{t('channel.plan.new.month').toLowerCase()}
          </Typography>
        </Box>
      </Box>
      {channel?.activePlans?.inPerson?.active && !channel?.activePlans?.inPerson?.renew && (
        <Typography align="center" style={fontStyle.xSmall} color="textSecondary">
          {t('channel.plan.message.cancel')}
        </Typography>
      )}
    </Box>
  );
};

export default GymManagement;
