/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import useSelectors from 'modules/map/useSelectors';
import { contactFormsModule } from 'modules/contactForms';
import Modules from 'modules';
import useActions from 'modules/map/useActions';
import Paper from 'core/ui/Paper';
import Typography, { fontStyle } from 'core/ui/Typography';
import { ButtonPrimary } from 'core/ui/Button';
import useConfirmation from 'core/useConfirmation';
import CopyText from 'core/ui/CopyText';
import notifications from 'modules/notifications';
import { useDispatch } from 'react-redux';
import { Chip, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { CONTACT_FORM_CSS, CONTACT_FORM_JS } from 'core/env';

const positions = ['bottom-left', 'bottom-right'];

const ContactFormGenerateCode = ({ channel }) => {
  const { t } = useTranslation();
  const { request } = useActions();
  const loading = useSelectors(contactFormsModule, 'loading');
  const { confirmation } = useConfirmation();
  const dispatch = useDispatch();
  const [code, setCode] = useState(null);

  const [position, setPosition] = useState('bottom-left');

  useEffect(() => {
    if (channel?.contactFormPk) {
      setCode(channel?.contactFormPk);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request, channel?.contactFormPk]);

  const generateCode = () => {
    confirmation({
      description: t('areYouSure'),
      yesClick: () => {
        request({
          action: Modules.contactForms.actions.generateCode,
          data: {
            channelId: channel?.id,
          },
          options: {
            onSuccess: (resp) => {
              setCode(resp?.code);
            },
            onError: (err) => {
              dispatch(notifications.error(err));
            },
          },
        });
      },
    });
  };

  const embedCode = `
<link
  name="contact_form_css"
  rel="stylesheet"
  href="${CONTACT_FORM_CSS}"
/>

<div id="contact-form" style="position: absolute;" data-channel-pk="${code}" data-position="${position}"></div>

<script
  name="contact_form_js"
  src="${CONTACT_FORM_JS}"
  type="text/javascript"
></script>
  `;

  return (
    <Paper p={3}>
      <Typography mb={2} style={fontStyle.label}>
        {t('label.contactFormCode')}
      </Typography>

      {code && (
        <div className="tw-flex-col tw-gap-2">
          <div className="tw-flex tw-gap-2">
            <CopyText text={embedCode} fontSize={30} />

            <Box
              flexGrow={1}
              style={{ backgroundColor: '#f1f1f1', padding: 5, margin: 2, borderRadius: 5 }}
            >
              <code>{embedCode}</code>
            </Box>
          </div>

          <RadioGroup
            value={position}
            onChange={(e) => setPosition(e.target.value)}
            className="tw-flex tw-flex-row"
          >
            {positions.map((pos) => (
              <FormControlLabel key={pos} control={<Radio />} value={pos} label={pos} />
            ))}
          </RadioGroup>
        </div>
      )}

      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" m={2}>
        {code && (
          <Box m={1}>
            <Chip label={code} />
          </Box>
        )}
        <ButtonPrimary loading={loading} disabled={loading} onClick={() => generateCode()}>
          {t(!code ? 'button.generateCode' : 'button.regenerateCode')}
        </ButtonPrimary>
      </Box>
    </Paper>
  );
};

export default ContactFormGenerateCode;
