import React, { useEffect, useRef, useState } from 'react';
import Axios from 'axios';
import Box from 'core/ui/Box';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import LoaderSm from 'core/ui/LoaderSm';
import { ClickAwayListener, Popover, colors } from '@material-ui/core';
import { Gif } from '@material-ui/icons';
import Paper from 'core/ui/Paper';
import { chatColors } from 'core/ui/Colors';

const GIPHY_API_KEY = 'bD2eygUcaOOKQVo0mMIp9OjUVe32TfY0';

export default ({ onGifSelected }) => {
  const [query, setQuery] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState([]);
  const btnRef = useRef();
  const [openGifs, setOpenGifs] = useState(false);

  const searchGifs = () => {
    setLoading(true);
    const url = `https://api.giphy.com/v1/gifs/${query ? 'search' : 'trending'}`;
    Axios.get(url, {
      params: {
        offset: 25,
        api_key: GIPHY_API_KEY,
        q: query,
        limit: 200,
      },
    }).then((resp) => {
      setLoading(false);
      setData(resp?.data?.data || []);
    });
  };

  useEffect(() => {
    searchGifs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return (
    <ClickAwayListener onClickAway={() => setOpenGifs()}>
      <Box>
        <Box
          style={{
            backgroundColor: colors.grey[300],
            borderRadius: 50,
            width: 30,
            height: 30,
          }}
          onClick={() => setOpenGifs(true)}
          display="flex"
          alignItems="center"
          justifyContent="center"
          className="hover"
        >
          <Gif innerRef={btnRef} style={{ fontSize: 20, color: colors.grey[700] }} />
        </Box>

        <Popover
          open={openGifs}
          anchorEl={btnRef.current}
          onClose={() => setOpenGifs(false)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          PaperProps={{ style: { backgroundColor: chatColors.container } }}
        >
          <Paper
            p={2}
            style={{
              height: 300,
              width: 300,
              backgroundColor: chatColors.container,
              overflow: 'scroll',
            }}
          >
            <Box mb={2}>
              <TextFieldRounded value={query} onChange={(v) => setQuery(v)} placeholder="Search" />
            </Box>

            <Box>
              <LoaderSm loading={loading} center />
            </Box>
            <Box display="flex" flexWrap="wrap" className="gap-5">
              {data?.map((item, index) => (
                <Box
                  key={index}
                  className="hover"
                  onClick={() => {
                    onGifSelected(item?.images?.downsized?.url);
                    setOpenGifs(false);
                  }}
                >
                  <img
                    src={item?.images?.downsized?.url}
                    alt={item?.name}
                    style={{ wdith: 80, height: 80 }}
                  />
                </Box>
              ))}
            </Box>
          </Paper>
        </Popover>
      </Box>
    </ClickAwayListener>
  );
};
