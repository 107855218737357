import React from 'react';
import Box from 'core/ui/Box';

import LoaderSm from 'core/ui/LoaderSm';
import Paper from 'core/ui/Paper';
import usePrivateSessions from './usePrivateSessions';
import PrivateInvites from './PrivateInvites';
import ScheduleSessions from './ScheduleSessions';

const PrivateSessions = () => {
  const {
    workoutsInvites,
    loadingInvites,

    loadingSchedule,
    coachSessions,
    userSessions,

    isCoach,
  } = usePrivateSessions();

  return (
    <Paper p={5} className="paper-rounded">
      {loadingInvites && (
        <Box display="flex" justifyContent="center" mb={3} alignItems="center">
          <LoaderSm loading={loadingInvites} />
        </Box>
      )}

      <PrivateInvites workoutsInvites={workoutsInvites} loading={loadingInvites} />

      {(coachSessions?.length > 0 || userSessions?.length > 0) && (
        <ScheduleSessions
          coachSessions={coachSessions}
          userSessions={userSessions}
          isCoach={isCoach}
          loading={loadingSchedule}
        />
      )}
    </Paper>
  );
};

export default PrivateSessions;
