import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import Texture from 'core/ui/Texture';
import { ButtonPrimary } from 'core/ui/Button';
import { Publish } from '@material-ui/icons';
import Import from './Import';

const ModalImportInventory = ({ partner, callback }) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const close = () => {
    setOpen(false);
  };

  return (
    <Box display="flex" justifyContent="flex-end" mx={1}>
      <ButtonPrimary onClick={() => setOpen(true)}>
        <Publish />
        {t('button.importInventoryByFile')}
      </ButtonPrimary>
      <Dialog maxWidth="xs" scroll="body" fullWidth onClose={() => close()} open={open}>
        <Texture style={{ minHeight: '100%' }}>
          <Box>
            <Import close={close} partner={partner} callback={callback} />
          </Box>
        </Texture>
      </Dialog>
    </Box>
  );
};

ModalImportInventory.propTypes = {};

ModalImportInventory.defaultProps = {};

export default ModalImportInventory;
