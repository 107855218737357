/* eslint-disable import/prefer-default-export */
import { all, call, put } from 'redux-saga/effects';

import { getErrorMessage } from 'core/api/api-error';

import notifications from 'modules/notifications';

import * as api from './api';
import { recurringsActions as actions } from './index';

export function* getRecurringsByChannel({ channelId }) {
  try {
    const response = yield call(api.recurringsByChannel, channelId);
    const { data } = response;
    yield put(actions.getRecurringsByChannelSuccess(data));
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.getRecurringsByChannelError(getErrorMessage(error))),
    ]);
  }
}

export function* stopRecurring({ recurringsIds, disable, startAtRemoveWorkouts, callback }) {
  try {
    yield call(api.stopRecurring, recurringsIds, disable, startAtRemoveWorkouts);
    yield put(actions.stopRecurringSuccess());
    if (callback) {
      callback();
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.stopRecurringError(getErrorMessage(error))),
    ]);
  }
}

export function* extendRecurring({ data, callback }) {
  try {
    yield call(api.extendRecurring, data);
    yield put(actions.extendRecurringSuccess());
    if (callback) {
      callback();
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.extendRecurringError(getErrorMessage(error))),
    ]);
  }
}
