import React from 'react';
import Box from 'core/ui/Box';
import ListPlans from './List';

const Plans = () => (
  <Box px={5}>
    <ListPlans />
  </Box>
);

export default Plans;
