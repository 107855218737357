import React, { useEffect, useState } from 'react';
import { IconButton, Link } from '@material-ui/core';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import Paper from 'core/ui/Paper';
import Typography, { fontStyle } from 'core/ui/Typography';
import SFDataTable from 'core/ui/DataTable';
import LoaderSm from 'core/ui/LoaderSm';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import useSelectors from 'modules/map/useSelectors';
import { userChildrenModule } from 'modules/userChildren';
import ImageMedia from 'components/Profile/ImageMedia';
import Divider from 'core/ui/Divider';
import ConfirmDialog from 'core/ui/ConfirmDialog';
import { Close, Edit } from '@material-ui/icons';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import { gymDate } from 'core/utils/formatters/date';

const Children = ({ userId, setEditChild, setChildren }) => {
  const { t } = useTranslation();
  const { request } = useActions();
  const [text, setText] = useState('');
  const data = useSelectors(userChildrenModule, 'data');
  const loading = useSelectors(userChildrenModule, 'loading');
  const [confirmRemove, setConfirmRemove] = useState({ open: false });

  useEffect(() => {
    request({ action: Modules.userChildren.actions.getChildren, data: { userId } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removeChild = (child) => {
    setConfirmRemove({
      open: true,
      confirm: () => {
        request({
          action: Modules.userChildren.actions.removeChild,
          data: { userId, childId: child.id },
          options: {
            onSuccess: () => {
              request({ action: Modules.userChildren.actions.getChildren, data: { userId } });
            },
          },
        });
        setConfirmRemove({ open: false });
      },
    });
  };

  const columns = [
    {
      cell: (row) => (
        <Box display="flex" className="hover" py={1} style={{ minWidth: 250 }}>
          <Box mr={3}>
            <ImageMedia
              user={row}
              image={row.image ? row.image : {}}
              size={50}
              showCountry={false}
            />
          </Box>
          <Box flexGrow={1}>
            <Typography style={fontStyle.medium} noWrap>
              {row.name}
            </Typography>
            <Typography style={fontStyle.small}>Age: {row.age}</Typography>
            {!setChildren && (
              <Link onClick={() => setEditChild({ ...row, convert: true })}>
                <Typography style={fontStyle.small} noWrap>
                  {t('account.children.convert')}
                </Typography>
              </Link>
            )}
          </Box>
        </Box>
      ),
    },

    {
      cell: (row) => (
        <Box py={1} pl={3}>
          {row.keys &&
            row.keys.map((key, i) => (
              <Box key={i.toString()}>
                {!!key.membership && key.membershipPlan && (
                  <>
                    <Typography color="secondary" style={{ ...fontStyle.small }}>
                      {key.channel && key.channel.name}
                    </Typography>
                    <Typography color="secondary" style={{ ...fontStyle.small }}>
                      {key.membershipPlan && key.membershipPlan.name}
                    </Typography>

                    {key.membershipPlan.online && key.membershipPlan.inPerson && (
                      <Typography color="primary" style={{ ...fontStyle.small }}>
                        {t('workout.setting.online')},&nbsp;
                        {t('workout.setting.inPerson')}
                      </Typography>
                    )}
                    {!key.membershipPlan.online && key.membershipPlan.inPerson && (
                      <Typography color="primary" style={{ ...fontStyle.small }}>
                        {t('workout.setting.inPerson')}
                      </Typography>
                    )}
                    {key.membershipPlan.online && !key.membershipPlan.inPerson && (
                      <Typography color="primary" style={{ ...fontStyle.small }}>
                        {t('workout.setting.online')}
                      </Typography>
                    )}
                    {key.expiryA && (
                      <Typography color="secondary" style={{ ...fontStyle.small, color: 'grey' }}>
                        {t('expires')}&nbsp;
                        {gymDate(key.expiryAt)}
                      </Typography>
                    )}
                    {key.limitUse && (
                      <Typography color="secondary" style={{ ...fontStyle.small, color: 'grey' }}>
                        {key.limitUse}
                      </Typography>
                    )}
                  </>
                )}
                {!key.membership && (
                  <>
                    <Box>
                      <Typography color="secondary" style={{ ...fontStyle.small }}>
                        Channel Key
                      </Typography>
                    </Box>
                    <Typography color="primary" style={{ ...fontStyle.small }}>
                      {key.kind === 'online'
                        ? t('workout.setting.online')
                        : t('workout.setting.inPerson')}
                    </Typography>
                  </>
                )}

                {i < row.keys.length - 1 && <Divider />}
              </Box>
            ))}
        </Box>
      ),
    },
    {
      center: true,
      cell: (row) => (
        <Box display="flex">
          {setChildren ? (
            <IconButton onClick={() => setChildren(row)}>
              <ArrowForwardIosIcon />
            </IconButton>
          ) : (
            <>
              <IconButton onClick={() => setEditChild(row)}>
                <Edit />
              </IconButton>
              <IconButton onClick={() => removeChild(row)}>
                <Close />
              </IconButton>
            </>
          )}
        </Box>
      ),
    },
  ];

  const rows = data.filter((x) => !text || x.name.toUpperCase().indexOf(text.toUpperCase()) > -1);

  return (
    <Box>
      <Paper className="paper-rounded" mt={1} pb={2} pt={3}>
        <ConfirmDialog
          open={confirmRemove.open}
          confirm={() => confirmRemove.confirm()}
          cancel={() => setConfirmRemove({ open: false })}
          description={t('button.remove.child.confirm')}
          labelConfirm={t('button.confirm')}
          labelCancel={t('button.cancel')}
        />
        <Box mx={3} mb={2}>
          <Typography mb={2} variant="h5">
            {t('channel.member.children')}
          </Typography>
          <Box display="flex" alignItems="center">
            <Box display="flex" style={{ width: '100%' }}>
              <TextFieldRounded
                value={text}
                onChange={(v) => setText(v)}
                placeholder={t('dashboard.child.search')}
                bordered
              />
            </Box>
          </Box>
        </Box>

        <Box display="flex" alignItems="center" justifyContent="center">
          <LoaderSm loading={loading} />
        </Box>
        <Box>
          <SFDataTable
            noHeader
            noTableHead
            title=""
            showPaper={false}
            columns={columns}
            paginationPerPage={20}
            data={rows}
            pagination
            noDataComponent=""
          />
        </Box>
      </Paper>
    </Box>
  );
};

export default Children;
