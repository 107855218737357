import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { APP_BASE_URL } from 'core/env.js';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';
import { FileCopy, Share, Link as LinkIcon } from '@material-ui/icons';
import { ClickAwayListener, Popper, Tooltip, Zoom } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { copyToClipBoard } from 'core/utils/helpers';
import Typography from '../Typography';
import Box from '../Box';
import { primary } from '../Colors';
import Paper from '../Paper';
import TextFieldRounded from '../TextFieldRounded';

export default function SocialMediaButtons({ url, quote, hashtag, placement, size, buttonColor }) {
  const [open, setOpen] = useState(false);
  const [openUrl, setOpenUrl] = useState(false);
  const [text, setText] = useState('');
  const anchorRef = useRef();
  const { t } = useTranslation();

  return (
    <Box display="flex" alignItems="center" style={{ borderRadius: 10 }}>
      <Box
        onClick={() => setOpen(!open)}
        px={2}
        pt={1.5}
        pb={0.5}
        style={{ background: buttonColor || primary, zIndex: 2, borderRadius: 5 }}
        className="link-hover"
      >
        <Share style={{ fontSize: size, color: 'white' }} />
      </Box>

      <Box
        className={open ? 'open-social-media' : 'close-social-media'}
        pl={8}
        display="flex"
        alignItems="center"
        ml={-9}
        style={{
          background: '#fff',
          borderRadius: 5,
          height: 33,
          zIndex: 0,
          width: 0,
          overflow: 'hidden',
        }}
      >
        <Zoom in={open} style={{ transitionDelay: open ? '210ms' : '0ms' }}>
          <Box display="flex" alignItems="center" pt={1}>
            <Tooltip title={t('social.share.tooltip.link')}>
              <Box
                className="link-hover"
                ref={anchorRef}
                mx={1}
                mt={0.3}
                ml={3}
                onClick={() => setOpenUrl(!openUrl)}
              >
                <LinkIcon style={{ fontSize: 30, color: '#f6bc92' }} />
              </Box>
            </Tooltip>
            <Box display="flex" pt={1}>
              <Tooltip title={t('social.share.tooltip.facebook')}>
                <FacebookShareButton url={url || APP_BASE_URL} quote={quote} hashtag={hashtag}>
                  <FacebookIcon
                    round
                    size={38}
                    bgStyle={{ fill: '#fff' }}
                    iconFillColor="#82aae9"
                  />
                </FacebookShareButton>
              </Tooltip>
              <Tooltip title={t('social.share.tooltip.twitter')}>
                <TwitterShareButton url={url || APP_BASE_URL} quote={quote} hashtag={hashtag}>
                  <TwitterIcon round size={38} bgStyle={{ fill: '#fff' }} iconFillColor="#86cafa" />
                </TwitterShareButton>
              </Tooltip>
              <Tooltip title={t('social.share.tooltip.whatsapp')}>
                <WhatsappShareButton url={url || APP_BASE_URL} quote={quote} hashtag={hashtag}>
                  <WhatsappIcon
                    round
                    size={30}
                    bgStyle={{ fill: '#fff' }}
                    iconFillColor="#0dbf42"
                  />
                </WhatsappShareButton>
              </Tooltip>
            </Box>
          </Box>
        </Zoom>
      </Box>
      <Popper
        placement={placement}
        open={openUrl}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: 9 }}
      >
        <ClickAwayListener onClickAway={() => setOpenUrl(false)}>
          <Box>
            <Paper
              p={3}
              elevation="3"
              className="paper-rounded"
              style={{
                backgroundColor: '#f3f3f3',
              }}
            >
              <Box display="flex" alignItems="flex-end">
                <Box
                  flexGrow="1"
                  style={{
                    borderRadius: 5,
                  }}
                  p={1}
                >
                  <Typography align="center" mb={1} variant="h6" color="secondary">
                    {t('social.share.link')}
                  </Typography>
                  <TextFieldRounded value={url} />
                </Box>
                <Box ml={1} pb={1} onClick={() => copyToClipBoard(url, setText)}>
                  <FileCopy style={{ color: buttonColor || primary, fontSize: 18 }} />
                </Box>
              </Box>
              <Typography color="secondary" style={{ fontSize: 12 }} align="center" component="p">
                {text}
              </Typography>
            </Paper>
          </Box>
        </ClickAwayListener>
      </Popper>
    </Box>
  );
}

SocialMediaButtons.defaultProps = {
  url: '',
  hashtag: '',
  quote: '',
  placement: 'top',
  size: 26,
};

SocialMediaButtons.propTypes = {
  url: PropTypes.string,
  hashtag: PropTypes.string,
  quote: PropTypes.string,
  placement: PropTypes.string,
  size: PropTypes.number,
};
