import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import { Dialog } from '@material-ui/core';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import { ButtonPrimary } from 'core/ui/Button';
import Texture from 'core/ui/Texture';
import ColorPicker from 'core/ui/ColorPicker';
import useForm from './form';

const ModalFormList = ({ open, item, close, callback, mode }) => {
  const { t } = useTranslation();
  const { setValue, watch, handleSubmit, onSubmit, errors, loading } = useForm({
    mode,
    item,
    callback,
  });
  return (
    <Dialog maxWidth="xs" scroll="body" fullWidth onClose={() => close()} open={open}>
      <Texture>
        <Box p={5}>
          <Box mb={3}>
            <TextFieldRounded
              value={watch('name')}
              onChange={(v) => setValue('name', v)}
              placeholder={t('label.name')}
              error={errors.name}
              label={t('label.name').toUpperCase()}
              required
            />
          </Box>
          <Box mb={3}>
            <ColorPicker
              label={t('label.color')}
              color={watch('color')}
              setColor={(v) => {
                setValue('color', v);
              }}
              bordered
            />
          </Box>

          <Box mt={3} display="flex" justifyContent="flex-end">
            <ButtonPrimary disabled={loading} onClick={handleSubmit(onSubmit)}>
              {t('button.save')}
            </ButtonPrimary>
          </Box>
        </Box>
      </Texture>
    </Dialog>
  );
};

ModalFormList.defaultProps = {
  close: () => {},
  callback: () => {},
  open: false,
  mode: 'new',
};

ModalFormList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  close: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  callback: PropTypes.func,
  open: PropTypes.bool,
  mode: PropTypes.string,
};

export default ModalFormList;
