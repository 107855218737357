import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form-v5';
import { useDispatch } from 'react-redux';
import notifications from 'modules/notifications';
import { contactFormsModule } from 'modules/contactForms';
import useSelectors from 'modules/map/useSelectors';
import useActions from 'modules/map/useActions';
import Modules from 'modules';

const useContactForm = ({ contactForm, callback, channel }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { request } = useActions();
  const loading = useSelectors(contactFormsModule, 'loading');
  const { register, handleSubmit, setValue, watch, reset, errors } = useForm({
    defaultValues: { method: 'email' },
  });

  const onSubmit = (values) => {
    request({
      action: Modules.contactForms.actions.createMessage,
      data: {
        ...values,
        subject: `Contact ${channel?.name}`,
        channelId: channel?.id,
        id: contactForm?.id,
      },
      options: {
        onSuccess: () => {
          if (callback) {
            callback();
          }
          request({
            action: Modules.contactForms.actions.getContactForms,
            data: {
              channelId: channel?.id,
            },
          });
          setValue('text', '');
          setValue('textRaw', '');
          dispatch(notifications.success(t('message.success.save.contactForms')));
        },
        onError: (error) => {
          dispatch(notifications.error(error));
        },
      },
    });
  };

  useEffect(() => {
    register('text', { required: false });
    register('textRaw', { required: false });
    register('method', { required: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register]);

  return {
    register,
    setValue,
    watch,
    reset,
    errors,
    onSubmit,
    handleSubmit,
    loading,
  };
};

export default useContactForm;
