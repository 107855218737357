import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import TimePickerRounded from 'core/ui/DatePickers/TimePickerRounded';
import DatePickerRounded from 'core/ui/DatePickers/DatePickerRounded';
import { getAllTimezones, timeZoneName } from 'core/utils/formatters/date';
import SelectBox from 'core/ui/SelectBox';
import Typography from 'core/ui/Typography';
import { WorkoutContext } from '../index';

const PublishDate = () => {
  const { t } = useTranslation();
  const formContext = useContext(WorkoutContext);

  return (
    <Box>
      {formContext.watch('method') !== 'video' && (
        <Typography ml={3} mt={5}>
          {t('workout.publishDate.note')}
        </Typography>
      )}
      <Box display="flex" mt={1} flexWrap="wrap" alignItems="center">
        <>
          <Box m={1}>
            <DatePickerRounded
              name="publishAtDate"
              type="datetime-local"
              label={t('workout.label.publishAtDate').toUpperCase()}
              onChange={(v) => formContext.setValue('publishAtDate', v)}
              value={formContext.watch('publishAtDate')}
              usFormat={formContext?.selectedChannel?.useUsDateFormat}
              error={formContext.errors.publishAtDate}
              required
            />
          </Box>
          <Box m={1}>
            <TimePickerRounded
              name="publishAtTime"
              type="datetime-local"
              label={t('workout.label.publishAtTime').toUpperCase()}
              onChange={(v) => formContext.setValue('publishAtTime', v)}
              value={formContext.watch('publishAtTime')}
              error={formContext.errors.publishAtTime}
              usFormat={formContext?.selectedChannel?.useUsDateFormat}
              required
            />
          </Box>
        </>

        <Box m={1}>
          <SelectBox
            propValue="id"
            propLabel="name"
            label={t('workout.label.timezone').toUpperCase()}
            options={getAllTimezones()}
            setValue={(v) => formContext.setValue('publishTimezone', v)}
            value={formContext.watch('publishTimezone') || timeZoneName()}
            error={formContext.errors.publishTimezone}
            required
          />
        </Box>
      </Box>
    </Box>
  );
};

export default PublishDate;

PublishDate.propTypes = {};

PublishDate.defaultProps = {};
