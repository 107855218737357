import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import Texture from 'core/ui/Texture';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import { formatDateApi } from 'core/utils/formatters/date';
import Typography from 'core/ui/Typography';
import Paper from 'core/ui/Paper';
import CloneContent from 'components/Workout/Form/CloneContent';
import DatePickerRounded from 'core/ui/DatePickers/DatePickerRounded';
import { useForm } from 'react-hook-form-v5';
import { ButtonPrimary } from 'core/ui/Button';
import { channelWorkoutsProgramsModule } from 'modules/channelWorkoutsPrograms';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import useSelectors from 'modules/map/useSelectors';
import LoaderSm from 'core/ui/LoaderSm';
import Divider from 'core/ui/Divider';

const ModalCloneDay = ({ channel, close, callback, open, day, section }) => {
  const { t } = useTranslation();
  const { watch, setValue, register, handleSubmit } = useForm();

  const loadingCloneDay = useSelectors(channelWorkoutsProgramsModule, 'loadingCloneDay');
  const { request } = useActions();

  useEffect(() => {
    register('relatedWorkouts');
  }, [register]);

  const cloneDayProgramming = (data) => {
    request({
      action: Modules.channelWorkoutsPrograms.actions.cloneDay,
      data: {
        channelId: channel?.id,
        day,
        sectionId: section?.id,
        relatedWorkouts: data?.relatedWorkouts,
      },
      options: {
        onSuccess: () => {
          callback();
        },
      },
    });
  };

  const [scheduleOptions, setScheduleOptions] = useState({});

  return (
    <Dialog open={open} onClose={close} maxWidth="sm" fullWidth scroll="body">
      <Texture>
        <Box p={5}>
          <Paper p={3} m={1}>
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
              <Typography align="center" variant="h5" mb={1}>
                {day || section?.title}
              </Typography>

              <Divider m={3} />
              <Typography align="center" variant="h5" mb={1}>
                {t('program.view.selectDayToClone')}
              </Typography>

              <Box>
                <DatePickerRounded
                  onChange={(v) => setScheduleOptions([{ scheduledAtDate: v }])}
                  value={
                    scheduleOptions?.length > 0
                      ? formatDateApi(scheduleOptions[0]?.scheduledAtDate)
                      : null
                  }
                  disablePast={false}
                  usFormat={channel?.useUsDateFormat}
                  autoOk
                  bordered
                />
              </Box>
            </Box>
          </Paper>

          <Box my={3}>
            <CloneContent
              watch={watch}
              setValue={setValue}
              // workout={workouts[0]}
              hideText
              channelId={channel?.id}
              scheduleOptions={scheduleOptions}
            />
          </Box>

          <Box display="flex" justifyContent="flex-end" mt={3}>
            <ButtonPrimary loading={loadingCloneDay} onClick={handleSubmit(cloneDayProgramming)}>
              <LoaderSm loading={loadingCloneDay} />
              {t('button.save').toUpperCase()}
            </ButtonPrimary>
          </Box>
        </Box>
      </Texture>
    </Dialog>
  );
};

ModalCloneDay.propTypes = {
  close: PropTypes.func,
};

ModalCloneDay.defaultProps = {
  close: () => {},
};

export default ModalCloneDay;
