/* eslint-disable react/prop-types */
import React from 'react';
import Typography from 'core/ui/Typography';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import Grid from 'core/ui/Grid';
import { Hidden } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { sessionSelectors } from 'modules/session';
import useChannelPermissions from 'components/Channel/useChannelPermissions';
import Comments from 'components/Comments/index';
import ValidAnnouncements from 'components/Channel/Manage/Tabs/Announcements/ShowValid';
import { primary } from 'core/ui/Colors';
import { gymDate, time } from 'core/utils/formatters';
import Paper from 'core/ui/Paper';
import GeneralInfo from './GeneralInfo';
import Locations from './Locations';
import EqpMovList from './EqpMovList';
import Invites from './Invites/index';
import Sections from './Sections';

export default ({ showInvites, workout, loading, openLeaderboard, showStartJoinButton }) => {
  const { t } = useTranslation();
  const isAuthenticated = useSelector((state) => sessionSelectors.getIsAuthenticated(state));
  const { commentsAccess } = useChannelPermissions({ channel: workout.channel });
  const buttonColor = workout?.channel?.customColors?.buttonColor || primary;

  const hasKey =
    workout.hasValidKeyInPersonChannel || workout.hasValidKeyChannel || workout.hasListChannel;

  const isToHideSections = () => {
    if (workout.isCoach) {
      return false;
    }
    return (
      !!workout.hideSectionsEveryone ||
      (!workout.competition
        ? !hasKey && workout.hideSections && !workout.registered
        : workout.hideSections && !workout.started)
    );
  };

  const hideSections = isToHideSections();

  return (
    <Box style={{ height: '100%' }}>
      {workout && workout.channel && (
        <Box mb={3}>
          <ValidAnnouncements channelId={workout.channel.id} />
        </Box>
      )}

      <Hidden only={['md', 'lg', 'xl']}>
        <Box my={1} mb={3}>
          <GeneralInfo workout={workout} loading={loading} />
        </Box>
      </Hidden>
      {!loading && (
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6}>
            {workout.inPerson && <Locations workout={workout} />}
            {!!workout?.pictureDay?.image?.url && (
              <Paper mb={5} p={3}>
                <img
                  className="boxImg"
                  draggable="false"
                  src={workout?.pictureDay?.image?.size350?.url || workout?.pictureDay?.image?.url}
                  style={{ width: '100%', height: 300 }}
                  alt=""
                />
              </Paper>
            )}

            {!hasKey && hideSections && (
              <Box mb={5}>
                <Typography variant="h5" color="primary">
                  {t('workout.hideSectionsMessage')}
                </Typography>
              </Box>
            )}
            {!!workout.hideSectionsEveryone && (
              <Box mb={5}>
                <Typography variant="h5" color="primary">
                  {t('workout.hideSectionsEveryone')
                    .replace('{time}', time(workout.hideSectionsBeforeAt))
                    .replace('{date}', gymDate(workout.hideSectionsBeforeAt))}
                </Typography>
              </Box>
            )}

            <Sections workout={workout} openLeaderboard={openLeaderboard} />

            <EqpMovList workout={workout} fontColor={buttonColor} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            {showInvites && (
              <Invites
                buttonColor={buttonColor}
                workout={workout}
                showStartJoinButton={showStartJoinButton}
              />
            )}

            {workout &&
              workout.id &&
              (hasKey || commentsAccess || workout.registered) &&
              isAuthenticated && (
                <Comments
                  typeId={workout.id}
                  kind="workout"
                  type="Workout"
                  title={t('competition.messageBoard')}
                  fontColor={buttonColor}
                  buttonColor={buttonColor}
                />
              )}
          </Grid>
        </Grid>
      )}
    </Box>
  );
};
