import GymsChart from 'components/Admin/Reports/GymsChart';
import GymsOnboarding from 'pages/Admin/Reports/GymsOnboarding';

export default [
  {
    path: '/gymMetrics',
    exact: true,
    name: 'Gym Metrics',
    component: GymsChart,
  },
  {
    path: '/gymsOnboarding',
    exact: true,
    name: 'Gym Onboarding',
    component: GymsOnboarding,
  },
];
