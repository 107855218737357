/* eslint-disable react/no-danger */
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from '@material-ui/core';

import { LayoutContext } from 'pages/shared/Layout';
import { contractActions, contractSelectors } from 'modules/contract';
import { useDispatch, useSelector } from 'react-redux';

export default () => {
  const { t } = useTranslation();

  const layoutContext = useContext(LayoutContext);
  const dispatch = useDispatch();
  const contract = useSelector((state) => contractSelectors.getContract(state));
  useEffect(() => {
    layoutContext.setTitleBar(t('message.contract.terms_of_use').toUpperCase());
  }, [layoutContext, t]);

  useEffect(() => {
    dispatch(contractActions.getContractStart('terms_of_use'));
  }, [dispatch]);

  return (
    <div>
      <Container maxWidth="xl">
        <div className="contract">
          <div dangerouslySetInnerHTML={{ __html: contract.content }} />
        </div>
      </Container>
    </div>
  );
};
