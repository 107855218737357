/* eslint-disable eqeqeq */
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import Grid from 'core/ui/Grid';
import Typography, { fontStyle } from 'core/ui/Typography';
import Checkbox from 'core/ui/Checkbox';
import Paper from 'core/ui/Paper';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import { Tooltip } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import SelectBox from 'core/ui/SelectBox';
import TextAreaFieldRounded from 'core/ui/TextAreaFieldRounded';
import { CompetitionContext } from '../index';

const SelectOptions = ({ options, setValue, helpText, hide, value, label, yesOrNo }) => {
  if (hide) {
    return null;
  }

  let val = !yesOrNo ? value : null;
  if (value === false) {
    val = 'no';
  }
  if (value === true) {
    val = 'yes';
  }
  return (
    <Box mb={2} display="flex" alignItems="center" className="gap-10">
      <Box flexGrow={1}>
        <SelectBoxObject
          label={label}
          setValue={(v) => (!yesOrNo ? setValue(v) : setValue(v === 'yes'))}
          value={val}
          options={options}
          bordered
          placeholder="Select Option"
          style={{ borderRadius: 1 }}
          emptyItem
        />
      </Box>

      <Box style={{ width: 80 }} display="flex" justifyContent="center">
        {!!helpText && (
          <Tooltip title={helpText}>
            <Box pt={8}>
              <Info fontSize="medium" />
            </Box>
          </Tooltip>
        )}
      </Box>
    </Box>
  );
};

const yesNoOptions = [
  { id: 'no', name: 'NO' },
  { id: 'yes', name: 'YES' },
];

const CompetitionSettings = () => {
  const { t } = useTranslation();
  const formContext = useContext(CompetitionContext);

  return (
    <>
      <Box>
        <Typography variant="h5" mb={2} color="primary">
          {t('competition.title.settings')}
        </Typography>
      </Box>
      <Grid container pb={3} mt={2} spacing={5}>
        <Grid item xs={12} md={4}>
          <Paper p={3}>
            <Typography mb={2} variant="h5">
              {t('label.generalInfo').toUpperCase()}
            </Typography>

            <Box mb={2}>
              <Typography ml={2} mb={1} style={fontStyle.label} variant="body1">
                {t('competition.label.location').toUpperCase()}
              </Typography>
              <Box display="flex">
                <Checkbox
                  disabled={formContext.competition.started}
                  label={
                    <Typography style={fontStyle.small} variant="caption" color="textSecondary">
                      {t(`competition.label.location.online`).toUpperCase()}
                    </Typography>
                  }
                  onClick={() => formContext.setValue('online', !formContext.watch('online'))}
                  checked={formContext.watch('online')}
                />
                <Box ml={3}>
                  <Checkbox
                    disabled={formContext.competition.started}
                    label={
                      <Typography style={fontStyle.small} variant="caption" color="textSecondary">
                        {t(`competition.label.location.inPerson`).toUpperCase()}
                      </Typography>
                    }
                    onClick={() => formContext.setValue('inPerson', !formContext.watch('inPerson'))}
                    checked={formContext.watch('inPerson')}
                  />
                </Box>
              </Box>
            </Box>

            <SelectOptions
              disabled={formContext.competition.started}
              options={[
                {
                  id: 'individual',
                  name: t(`competition.label.eventType.individual`).toUpperCase(),
                },
                {
                  id: 'team',
                  name: t(`competition.label.eventType.team`).toUpperCase(),
                },
                {
                  id: 'both',
                  name: t(`competition.label.eventType.both`).toUpperCase(),
                },
              ]}
              label={t('competition.label.eventType').toUpperCase()}
              setValue={(v) => formContext.setValue('eventType', v)}
              value={formContext.watch('eventType')}
            />
            <SelectOptions
              yesOrNo
              options={yesNoOptions}
              label={t('competition.label.fullTeamRegistration').toUpperCase()}
              setValue={(v) => formContext.setValue('fullTeamRegistration', v)}
              value={formContext.watch('fullTeamRegistration')}
              helpText={t('competition.help.fullTeamRegistration')}
              hide={
                !formContext?.watch('eventType') === 'team' &&
                !formContext?.watch('eventType') === 'both'
              }
            />

            <SelectOptions
              yesOrNo
              options={yesNoOptions}
              label={t('workout.heats').toUpperCase()}
              value={formContext.watch('heats')}
              setValue={(v) => formContext.setValue('heats', v)}
            />
            <SelectOptions
              yesOrNo
              options={yesNoOptions}
              label={t('competition.label.invitesOnly').toUpperCase()}
              value={formContext.watch('invitesOnly')}
              setValue={(v) => formContext.setValue('invitesOnly', v)}
              helpText={t('competition.help.invitesOnly')}
            />
            <SelectOptions
              yesOrNo
              options={[
                { id: 'no', name: t(`competition.label.type.public`).toUpperCase() },
                { id: 'yes', name: t(`competition.label.type.private`).toUpperCase() },
              ]}
              label={t('competition.label.visibility').toUpperCase()}
              value={formContext.watch('isPrivate')}
              setValue={(v) => formContext.setValue('isPrivate', v)}
              helpText={t('competition.help.isPrivate')}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper p={3}>
            <Typography variant="h5" mb={2}>
              {t('label.scoringOptions').toUpperCase()}
            </Typography>

            <SelectOptions
              options={[
                { id: 'lowest', name: t(`competition.label.lowest`).toUpperCase() },
                { id: 'highest', name: t(`competition.label.highest`).toUpperCase() },
                { id: 'custom_scoring', name: t(`competition.customScoring`).toUpperCase() },
              ]}
              label={t('competition.label.leaderboardOrder').toUpperCase()}
              value={formContext.watch('leaderboardOrder')}
              setValue={(v) => formContext.setValue('leaderboardOrder', v)}
            />

            <SelectOptions
              yesOrNo
              options={yesNoOptions}
              label={t('competition.label.hideLeaderboard').toUpperCase()}
              value={formContext.watch('hideLeaderboard')}
              setValue={(v) => formContext.setValue('hideLeaderboard', v)}
              helpText={t('competition.help.hideLeaderboard')}
            />
            <SelectOptions
              yesOrNo
              options={yesNoOptions}
              label={t('competition.label.hidePoints').toUpperCase()}
              value={formContext.watch('hidePoints')}
              setValue={(v) => formContext.setValue('hidePoints', v)}
              helpText={t('competition.help.hidePoints')}
            />
            <SelectOptions
              yesOrNo
              options={yesNoOptions}
              label={t('competition.label.hasDivisions').toUpperCase()}
              value={formContext.watch('hasDivisions')}
              setValue={(v) => formContext.setValue('hasDivisions', v)}
            />
            <SelectOptions
              yesOrNo
              options={yesNoOptions}
              label={t('competition.label.multiDivisionRegistrations').toUpperCase()}
              value={formContext.watch('multiDivisionRegistrations')}
              setValue={(v) => formContext.setValue('multiDivisionRegistrations', v)}
              helpText={t('competition.help.multiDivisionRegistrations')}
              hide={!formContext?.watch('hasDivisions')}
            />
            <SelectOptions
              yesOrNo
              options={yesNoOptions}
              label={t('competition.label.payPerDivision').toUpperCase()}
              value={formContext.watch('payPerDivision')}
              setValue={(v) => formContext.setValue('payPerDivision', v)}
              helpText={t('competition.help.payPerDivision')}
              hide={!formContext?.watch('hasDivisions')}
            />
            <SelectOptions
              yesOrNo
              options={yesNoOptions}
              label={t('competition.label.allowWaitlist').toUpperCase()}
              value={formContext.watch('allowWaitlist')}
              setValue={(v) => formContext.setValue('allowWaitlist', v)}
              helpText={t('competition.help.allowWaitlist')}
            />
            <SelectOptions
              options={[
                {
                  id: 'after_event',
                  name: t(`competition.label.watchOption.afterEvent`).toUpperCase(),
                },
                {
                  id: 'immediately',
                  name: t(`competition.label.watchOption.immediately`).toUpperCase(),
                },
                { id: 'none', name: t(`competition.label.watchOption.none`).toUpperCase() },
              ]}
              label={t('competition.label.watchOption').toUpperCase()}
              value={formContext.watch('watchOption')}
              setValue={(v) => formContext.setValue('watchOption', v)}
              helpText={t('competition.help.watchOption')}
              hide={!formContext?.watch('online')}
            />

            <SelectOptions
              yesOrNo
              options={yesNoOptions}
              label={t('competition.label.requireVerification').toUpperCase()}
              value={formContext.watch('requireVerification')}
              setValue={(v) => formContext.setValue('requireVerification', v)}
              helpText={t('competition.help.requireVerification')}
              hide={!formContext?.watch('online')}
            />
            <SelectOptions
              yesOrNo
              options={yesNoOptions}
              label={t('competition.label.rankByVideo').toUpperCase()}
              value={formContext.watch('rankByVideo')}
              setValue={(v) => formContext.setValue('rankByVideo', v)}
              helpText={t('competition.help.rankByVideo')}
              hide={!formContext?.watch('online')}
            />
            <SelectOptions
              yesOrNo
              options={yesNoOptions}
              label={t('competition.label.hideJudge').toUpperCase()}
              value={formContext.watch('hideJudge')}
              setValue={(v) => formContext.setValue('hideJudge', v)}
              helpText={t('competition.help.hideJudge')}
              hide={!formContext?.watch('online')}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper p={3}>
            <Typography variant="h5" mb={2}>
              {t('label.pricingOptions').toUpperCase()}
            </Typography>
            <Box mb={2} display="flex" alignItems="center" className="gap-10">
              <Box flexGrow={1}>
                <TextFieldRounded
                  name="limitRegistrations"
                  type="number"
                  pattern="[0-9]*"
                  error={formContext.errors.limitRegistrations}
                  inputRef={formContext.register()}
                  placeholder={t('competition.label.limitRegistrations')}
                  label={t('competition.label.limitRegistrations').toUpperCase()}
                  bordered
                />
              </Box>

              <Box style={{ width: 80 }} display="flex" justifyContent="center">
                <Tooltip title={t('competition.help.limitRegistrations')}>
                  <Box pt={8}>
                    <Info fontSize="medium" />
                  </Box>
                </Tooltip>
              </Box>
            </Box>
            <SelectOptions
              yesOrNo
              options={yesNoOptions}
              label={t('competition.label.useTax').toUpperCase()}
              value={formContext.watch('useTax')}
              setValue={(v) => formContext.setValue('useTax', v)}
            />

            {formContext.watch('useTax') && (
              <Box mr={22} mt={2}>
                {formContext?.selectedChannel?.id ? (
                  <SelectBoxObject
                    emptyItem
                    emptyValue={null}
                    options={formContext?.selectedChannel?.channelsTaxes}
                    value={formContext?.watch('channelsTaxId')}
                    setValue={(v) => formContext.setValue('channelsTaxId', v)}
                    label={t('competition.label.selectTheTax').toUpperCase()}
                    bordered
                  />
                ) : (
                  <Box>
                    <TextFieldRounded
                      name="taxPercent"
                      type="number"
                      pattern="[0-9]*"
                      error={formContext.errors.taxPercent}
                      placeholder={t('competition.label.taxPercent')}
                      label={t('competition.label.selectTheTax').toUpperCase()}
                      inputRef={formContext.register({
                        required: false,
                      })}
                      currency
                      currencySymbol={formContext?.currency?.symbol}
                      bordered
                    />
                  </Box>
                )}
              </Box>
            )}

            {(formContext?.watch('eventType') === 'individual' ||
              formContext?.watch('eventType') === 'both') && (
              <Box mr={22} mt={2}>
                <TextFieldRounded
                  name="cost"
                  type="number"
                  pattern="[0-9]*"
                  error={formContext.errors.cost}
                  placeholder={t('competition.label.cost')}
                  label={t('competition.label.cost').toUpperCase()}
                  inputRef={formContext.register({ required: t('competition.validation.cost') })}
                  required
                  currency
                  currencySymbol={formContext?.currency?.symbol}
                  bordered
                  // disabled={formContext.competition.started}
                />
              </Box>
            )}

            {(formContext.watch('eventType') === 'team' ||
              formContext?.watch('eventType') === 'both') && (
              <Box display="flex" mr={22} mt={2}>
                <Box flexGrow={1}>
                  <TextFieldRounded
                    name="costTeam"
                    type="number"
                    pattern="[0-9]*"
                    error={formContext.errors.cost}
                    placeholder={t('competition.label.cost')}
                    label={t('competition.label.costTeam').toUpperCase()}
                    inputRef={formContext.register({
                      required: t('competition.validation.costTeam'),
                    })}
                    required
                    currency
                    currencySymbol={formContext?.currency?.symbol}
                    bordered
                    // disabled={formContext.competition.started}
                  />
                </Box>
                <Box ml={2}>
                  <SelectBox
                    error={formContext.errors.teamSize}
                    label={t('competition.label.teamSize').toUpperCase()}
                    options={['2', '3', '4', '5', '6']}
                    setValue={(v) => formContext.setValue('teamSize', v)}
                    value={formContext.watch('teamSize') ? `${formContext.watch('teamSize')}` : '2'}
                    required
                    bordered
                  />
                </Box>
              </Box>
            )}

            <Box mt={2}>
              <SelectOptions
                yesOrNo
                options={yesNoOptions}
                label={t('competition.label.donations').toUpperCase()}
                value={formContext.watch('donations')}
                setValue={(v) => formContext.setValue('donations', v)}
              />
            </Box>

            {!!formContext.watch('donations') && (
              <Box mr={22}>
                <TextAreaFieldRounded
                  error={formContext.errors.costAffiliate}
                  placeholder={t('competition.label.donationsDescription')}
                  label={t('competition.label.donationsDescription').toUpperCase()}
                  value={formContext.watch('donationsDescription')}
                  onChange={(v) => formContext.setValue('donationsDescription', v)}
                  bordered
                />
              </Box>
            )}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default CompetitionSettings;
