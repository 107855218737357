import { defaultCkEditorText } from 'core/ui/CKEditor';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form-v5';
import uuid from 'react-uuid';

export default ({ item, callback }) => {
  const { register, handleSubmit, setValue, watch, errors } = useForm({
    defaultValues: item,
  });

  const formElementRef = useRef();
  const [attachments, setAttachments] = useState([]);

  useEffect(() => {
    register('subject', { required: true });
    register('text', { required: true });
    register('textRaw');
    register('videoLink');
    register('actionText');
    register('actionLink');
    register('objectableType');
    register('objectableId');
    register('objectable');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (item) {
      Object.keys(item).forEach((x) => {
        setValue(x, item[x]);
      });

      if (!item?.textRaw && !!item?.text) {
        setValue('textRaw', defaultCkEditorText(item?.text));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  const clearFields = () => {
    setValue('subject', '');
    setValue('text', '');
    setValue('textRaw', null);
    setValue('videoLink', null);
    setValue('actionText', null);
    setValue('actionLink', null);

    setAttachments([]);
  };

  const saveMessage = (data) => {
    const dataForm = {
      ...data,
      uuid: item?.id || item?.uuid || uuid(),
      customContacts: data.customContacts && data.customContacts.map((u) => u.id),
      membersTags: data.membersTags && data.membersTags.map((u) => u.name || u),
      membershipsTags: data.membershipsTags && data.membershipsTags.map((u) => u.name || u),
      attachments,
      method: 'email',
      isTemplate: true,
      id: item?.id,
    };

    clearFields();
    callback(dataForm);
  };

  const onSubmit = (data) => {
    saveMessage(data);
  };

  return {
    onSubmit,
    setValue,
    watch,
    handleSubmit,
    errors,
    formElementRef,
    attachments,
    setAttachments,
  };
};
