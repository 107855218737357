import React, { useEffect, useState } from 'react';
import Typography from 'core/ui/Typography';
import Box from 'core/ui/Box';
import useSelectors from 'modules/map/useSelectors';
import { newCompetitionsModule } from 'modules/competitions/new';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import Paper from 'core/ui/Paper';
import { Dialog } from '@material-ui/core';
import FlagCountry from 'core/ui/FlagCountry';
import Divider from 'core/ui/Divider';
import Texture from 'core/ui/Texture';
import LoaderSm from 'core/ui/LoaderSm';
import { labelLight } from 'core/ui/Colors';
import { time } from 'core/utils/formatters';

const ModalTableTeamHeats = ({ open, competition, userId, close }) => {
  const loading = useSelectors(newCompetitionsModule, 'loading');

  const { request } = useActions();

  const [currentInfo, setCurrentInfo] = useState();

  const loadInfo = () => {
    request({
      action: Modules.competitionsUsers.actions.show,
      data: {
        competitionId: competition?.id,
        userId,
      },
      options: {
        onSuccess: (data) => {
          setCurrentInfo(data);
        },
      },
    });
  };

  useEffect(() => {
    if (userId && competition?.id) {
      loadInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, competition]);

  return (
    <Dialog open={open} fullWidth maxWidth="md" onClose={() => close()}>
      <Texture>
        <Box p={3}>
          <Paper className="paper-rounded" p={3} mb={3}>
            <LoaderSm center loading={loading} />
            <Box mb={3} display="flex">
              {currentInfo?.user && currentInfo?.user.country && currentInfo?.user.country.flag && (
                <Box mr={3}>
                  <FlagCountry flag={currentInfo?.user?.country?.flag || 'US'} size={40} />
                </Box>
              )}
              <Box>
                <Box mb={3} display="flex">
                  <Typography variant="h5" mr={3} style={{ color: labelLight }}>
                    {currentInfo?.teamNumber}
                  </Typography>
                  <Typography variant="h5">{currentInfo?.team}</Typography>
                </Box>
                <Box mb={3}>{currentInfo?.userName}</Box>
              </Box>
            </Box>
          </Paper>
          <Divider m={3} />

          <Paper className="paper-rounded" p={3} mb={3}>
            {currentInfo?.workoutsHeats?.map((itemW) => (
              <Paper p={2} mb={2} key={itemW?.workoutId}>
                <Typography>
                  Workout: <span style={{ color: labelLight }}>{itemW?.workoutName}</span>
                </Typography>
                <Typography>
                  Heat: <span style={{ color: labelLight }}>{itemW?.heat?.name}</span>
                </Typography>
                <Typography>
                  Lane: <span style={{ color: labelLight }}>{itemW?.userLane}</span>
                </Typography>

                {itemW?.heat?.startTime ? (
                  <Box flexGrow={1} display="flex" alignItems="center">
                    <Typography>
                      Time:&nbsp;
                      <span style={{ color: labelLight }}>{itemW?.heat?.startTime}</span>
                    </Typography>
                  </Box>
                ) : (
                  <Typography>
                    Time:&nbsp;
                    <span style={{ color: labelLight }}>{time(itemW.workoutStartAt)}</span>
                  </Typography>
                )}
              </Paper>
            ))}
          </Paper>
        </Box>
      </Texture>
    </Dialog>
  );
};

ModalTableTeamHeats.defaultProps = {};

export default ModalTableTeamHeats;
