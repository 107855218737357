/* eslint-disable react/prop-types */
import React, { useMemo, useState } from 'react';
import Paper from 'core/ui/Paper';
import { useTranslation } from 'react-i18next';
import Typography from 'core/ui/Typography';
import SFDataTable from 'core/ui/DataTable';
import { Dialog, Link } from '@material-ui/core';
import { date, time } from 'core/utils/formatters';
import Box from 'core/ui/Box';
import Texture from 'core/ui/Texture';
import Button from 'core/ui/Button';
import ViewHeadlineIcon from '@material-ui/icons/ViewHeadline';
import { OpenInNew } from '@material-ui/icons';
import TextFieldRounded from 'core/ui/TextFieldRounded';

export default function ResultUsers({ channel, data, genericCheckIn }) {
  const { t } = useTranslation();
  const [modal, setModal] = useState({ open: false, workouts: [] });

  const [minimumCheckIns, setMinimumCheckIns] = useState('0');

  const healedData = useMemo(
    () =>
      data.map((usersCheckIn) => ({
        ...usersCheckIn,
        userName: usersCheckIn.user.name,
        totalCheckIns:
          usersCheckIn.checkins + usersCheckIn.genericCheckins + usersCheckIn.importedCheckins,
      })),
    [data],
  );

  const filteredData = useMemo(
    () =>
      healedData.filter((usersCheckIn) => usersCheckIn.totalCheckIns >= Number(minimumCheckIns)),
    [healedData, minimumCheckIns],
  );

  const columns = [
    {
      name: 'User Name',
      selector: 'userName',
      sortable: true,
      cell: (row) => <Typography>{row?.userName}</Typography>,
    },
  ];

  if (genericCheckIn) {
    columns.push({
      name: 'Generic Check-ins',
      selector: 'checkins',
      sortable: true,
      cell: (row) => <Typography>{row.genericCheckins}</Typography>,
    });
    columns.push({
      name: 'Import Check-ins',
      selector: 'checkins',
      sortable: true,
      cell: (row) => <Typography>{row.importedCheckins}</Typography>,
    });
    columns.push({
      name: 'Class Check-ins',
      selector: 'checkins',
      sortable: true,
      cell: (row) => (
        <Button
          onClick={() =>
            setModal({
              open: true,
              workouts: row?.checkinsClasses || [],
            })
          }
        >
          <ViewHeadlineIcon />
          <Typography>&nbsp;{row.checkins}</Typography>
        </Button>
      ),
    });
    columns.push({
      name: 'Total Check-ins',
      selector: 'totalCheckIns',
      sortable: true,
      cell: (row) => <Typography>{row?.totalCheckIns}</Typography>,
    });
  } else {
    columns.push({
      name: 'Check-ins',
      selector: 'checkins',
      sortable: true,
      cell: (row) => (
        <Button
          onClick={() =>
            setModal({
              open: true,
              workouts: row?.checkinsClasses || [],
            })
          }
        >
          <ViewHeadlineIcon />
          <Typography>&nbsp;{row.checkins}</Typography>
        </Button>
      ),
    });
    columns.push({
      name: 'No Show',
      selector: 'noShow',
      sortable: true,
      cell: (row) => (
        <Button
          onClick={() =>
            setModal({
              open: true,
              workouts: row?.noShowClasses || [],
            })
          }
        >
          <ViewHeadlineIcon />
          <Typography>&nbsp;{row.noShow}</Typography>
        </Button>
      ),
    });
    columns.push({
      name: 'Cancel',
      selector: 'cancel',
      sortable: true,
      cell: (row) => (
        <Button
          onClick={() =>
            setModal({
              open: true,
              workouts: row?.cancelClasses || [],
            })
          }
        >
          <ViewHeadlineIcon />
          <Typography>&nbsp;{row.cancel}</Typography>
        </Button>
      ),
    });
    columns.push({
      name: 'Late Cancel',
      selector: 'lateCancel',
      sortable: true,
      cell: (row) => (
        <Button
          onClick={() =>
            setModal({
              open: true,
              workouts: row?.lateCancelClasses || [],
            })
          }
        >
          <ViewHeadlineIcon />
          <Typography>&nbsp;{row.lateCancel}</Typography>
        </Button>
      ),
    });
    columns.push({
      name: 'Import Check-ins',
      selector: 'checkins',
      sortable: true,
      cell: (row) => <Typography>{row.importedCheckins}</Typography>,
    });
    columns.push({
      name: 'Total Check-ins',
      selector: 'totalCheckIns',
      sortable: true,
      cell: (row) => <Typography>{row?.totalCheckIns}</Typography>,
    });
  }

  const exportRows = filteredData.map((ck) => ({
    id: ck?.user?.name,
    userName: ck?.user?.name,
    checkins: ck?.checkins,
    noShow: ck?.noShow,
    genericCheckins: ck?.genericCheckins,
    importedCheckins: ck?.importedCheckins,
    totalCheckIns: ck.totalCheckIns,
  }));

  return (
    <Paper style={{ width: '100%' }}>
      <Dialog open={modal?.open} fullWidth maxWidth="sm" onClose={() => setModal({})}>
        <Texture>
          <Box p={3}>
            {modal?.workouts?.map((x) => (
              <Paper key={x?.id} p={3} mb={1}>
                <Box display="flex">
                  <Box flexGrow={1}>
                    <Typography>
                      {x?.id} - {x?.name}
                    </Typography>
                    <Typography>{x?.classTypeName}</Typography>

                    <Typography>
                      {date(x?.datetime, channel?.dateFormatReact)}&nbsp;&bull;&nbsp;
                      {time(x.datetime)}
                    </Typography>
                  </Box>
                  <Link target="_blank" href={`/workout/view/${x.id}`}>
                    <OpenInNew style={{ fontSize: 30 }} />
                  </Link>
                </Box>
              </Paper>
            ))}
          </Box>
        </Texture>
      </Dialog>

      <div className="tw-ml-auto tw-w-40 tw-p-2">
        <TextFieldRounded
          label={t('channel.reports.minimumCheckins').toUpperCase()}
          type="number"
          inputProps={{ min: 0 }}
          value={minimumCheckIns}
          onChange={setMinimumCheckIns}
        />
      </div>

      <SFDataTable
        title={t('channel.reports.attendanceReport')}
        searchField={(row) => row?.user?.name}
        showSearch
        columns={columns}
        data={filteredData}
        pagination
        showExport
        exportData={exportRows}
      />
    </Paper>
  );
}
