import React, { useEffect } from 'react';
import Driver from 'driver.js';
import 'driver.js/dist/driver.min.css';

export default function DriverTutorial({ targetId, title, description, onDeselected }) {
  useEffect(() => {
    const driver = new Driver({
      onDeselected,
      opacity: 0.5,
      stageBackground: 'transparent',
    });
    setTimeout(() => {
      driver.highlight({
        element: targetId,
        popover: {
          title,
          description,
          position: 'bottom',
          offset: 20,
        },
      });
    }, 1000);
    return () => {
      driver.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <></>;
}

DriverTutorial.propTypes = {};

DriverTutorial.defaultProps = {};
