import React, { useContext } from 'react';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import Typography, { fontStyle } from 'core/ui/Typography';
import { useSelector } from 'react-redux';
import { channelsSelectors } from 'modules/channels';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { successColor } from 'core/ui/Colors';
import { PlanManageContext } from 'contexts';
import { currency } from 'core/utils/formatters';
import Checkbox from 'core/ui/Checkbox';
import EngageImage from 'assets/img/engage-icon.png';
import { styleBoxPlanImage, styleBoxPlanInfo } from './styles';

const ItemPlan = ({ label }) => (
  <Box display="flex" alignItems="center">
    <CheckCircleOutlineIcon style={{ color: successColor, fontSize: 14 }} />
    <Typography ml={2} style={fontStyle.large} className="roboto-regular">
      {label}
    </Typography>
  </Box>
);

const EngagePlan = ({ plan, isAnnual }) => {
  const { t } = useTranslation();
  const channel = useSelector((state) => channelsSelectors.getChannel(state));
  const planContext = useContext(PlanManageContext);

  const totalPlanOption = () => {
    const planAmount = planContext?.annualRenew
      ? plan.annualAmountEngage
      : Number(plan.amountEngage);

    return currency(Number(channel?.activePlans?.free?.active ? 0 : planAmount));
  };

  return (
    <Box p={5}>
      <Box display="flex" style={styleBoxPlanImage} justifyContent="center">
        <img src={EngageImage} alt="Profile" />
      </Box>

      <Typography my={2} align="center" variant="h5">
        {t('channel.plan.engage')}
      </Typography>

      <Box style={styleBoxPlanInfo}>
        <Box style={{ width: 200 }}>
          <ItemPlan label={t('channel.plan.engage.buildAutomationWorkflows')} />
          <ItemPlan label={t('channel.plan.engage.captureLeadsFromYourWebsite')} />
          <ItemPlan label={t('channel.plan.engage.automateConversationsWithTriggersAndActions')} />
          <ItemPlan label={t('channel.plan.engage.replyToMessagesOnYourStreamFitApp')} />
          <ItemPlan label={t('channel.plan.engage.unlimitedWorkflows')} />
          <ItemPlan label={t('channel.plan.engage.unlimitedTextMessages')} />
          <ItemPlan label={t('channel.plan.engage.unlimitedEmails')} />
        </Box>
      </Box>

      <Box my={1} display="flex" alignItems="center" justifyContent="center">
        <Box my={1} display="flex" justifyContent="center">
          <Checkbox
            checked={planContext?.plans.find((x) => x.plan === 'engage')}
            onClick={() => planContext?.togglePlan('engage', !channel?.activePlans?.engage?.active)}
            size={40}
            disabled={
              channel.showRenewButton ||
              (channel?.activePlans?.inPerson?.active && !channel?.activePlans?.inPerson?.renew)
            }
          />
        </Box>

        <Box>
          {channel?.engageFree && (
            <Typography className="tw-text-xs tw-font-semibold tw-text-green-600">
              First {isAnnual ? 'year' : 'month'} FREE.
            </Typography>
          )}
          <Box display="flex" justifyContent="center" alignItems="flex-end">
            <Typography component="span" variant="h2" color="secondary">
              {t('prefix')}
              {totalPlanOption()}
            </Typography>
            <Typography
              component="span"
              variant="h2"
              pb={1}
              color="secondary"
              style={{ ...fontStyle.medium }}
            >
              /{t('channel.plan.new.month').toLowerCase()}
            </Typography>
          </Box>
        </Box>
      </Box>
      {channel?.activePlans?.engage?.active && !channel?.activePlans?.engage?.renew && (
        <Typography align="center" style={fontStyle.xSmall} color="textSecondary">
          {t('channel.plan.message.cancel')}
        </Typography>
      )}
    </Box>
  );
};

export default EngagePlan;
