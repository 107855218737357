import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

// eslint-disable-next-line react/prop-types
export default ({ children, dark, fullHeight, heightBanner, heightHeader, ...props }) => {
  let stringHeight = 'calc(100vh - ';
  stringHeight += heightBanner ? `${heightBanner}px - ` : '0px - ';
  stringHeight += heightHeader ? `${heightHeader}px` : '0px';
  stringHeight += `)`;

  const useStyles = makeStyles(() => ({
    root: {
      backgroundColor: dark ? '#2E2E2E' : '#f3f3f3',
      minHeight: fullHeight ? stringHeight : 'auto',
    },
  }));
  const classes = useStyles();
  return (
    <div className={classes.root} {...props}>
      {children}
    </div>
  );
};
