/* eslint-disable eqeqeq */
import React, { useEffect, useState, useImperativeHandle, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import propTypes from 'prop-types';
import { workoutsSelectors, workoutsActions } from 'modules/workouts';
import Box from 'core/ui/Box';
import { ButtonPrimary } from 'core/ui/Button';
import Typography, { fontStyle } from 'core/ui/Typography';
import { typeWeight, typeDistance, defaultScoresVariations } from 'core/utils/consts';
import Radio from 'core/ui/Radio';
import { primary, secondary } from 'core/ui/Colors';
import DatePickerRounded from 'core/ui/DatePickers/DatePickerRounded';
import moment from 'moment';
import { formatDateToApi } from 'core/utils/formatters';
import notifications from 'modules/notifications';
import { hasScore } from 'core/utils/helpers';
import { Dialog } from '@material-ui/core';
import Texture from 'core/ui/Texture';
import { channelsSelectors } from 'modules/channels';
import BenchmarkUserScores from 'components/Profile/Dashboard/Benchmark/BenchmarkUserScores';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import ScoreFields from './ScoreFields';
import './fireworks.css';

const FormScore = forwardRef(
  (
    { workout, sectionData, saveCallback, showSaveButton, user, buttonColor, saveFormPenalty },
    ref,
  ) => {
    const { t } = useTranslation();
    const [data, setData] = useState({});
    const [currentScore, setCurrentScore] = useState(1);
    const [sectionUserScores, setSectionUserScores] = useState([]);
    const dispatch = useDispatch();
    const channel = useSelector((state) => channelsSelectors.getChannel(state));
    const workoutSectionUserData = useSelector((state) =>
      workoutsSelectors.getWorkoutSectionUserData(state),
    );
    const [showPrModal, setShowPrModal] = useState({ open: false });

    const isCustomWorkout = !workout.isCompetition && workout?.method === 'custom';
    const isMultipleScores = sectionData?.scores?.length > 1;
    const currentIndexScore = currentScore - 1;

    const variationsOptions =
      channel?.scoresVariations?.length > 0
        ? [...defaultScoresVariations, ...channel?.scoresVariations]
        : defaultScoresVariations;

    const setForTime = (time) => {
      if (!time) {
        return {};
      }
      const workTimer = time.split(':');
      return {
        hours: workTimer && workTimer[0],
        minutes: workTimer && workTimer[1],
        seconds: workTimer && workTimer[2],
        value: time,
      };
    };

    const changeCurrentScores = (values) => {
      const currentItems = [...sectionUserScores];
      currentItems[currentIndexScore] = { ...sectionUserScores[currentIndexScore], ...values };
      setSectionUserScores(currentItems);
    };

    useEffect(() => {
      if (
        !isCustomWorkout &&
        workoutSectionUserData[sectionData.id] &&
        workoutSectionUserData[sectionData.id].scores
      ) {
        setSectionUserScores(
          workoutSectionUserData[sectionData.id].scores.map((item) => ({
            ...item,
            time: setForTime(item.time ? item.time : null),
            tieBreaktime: setForTime(item.tieBreaktime ? item.tieBreaktime : null),
          })),
        );

        setData({
          isPrivate: workoutSectionUserData[sectionData.id].isPrivate,
        });
      } else {
        const scoreNumber = sectionData?.scores?.length;

        setSectionUserScores([...new Array(scoreNumber)].map(() => ({})));

        setData({ isPrivate: false });
      }
    }, [isCustomWorkout, workoutSectionUserData, sectionData, currentScore]);

    const totalTimeSection = () => {
      if (sectionUserScores.timeCapped) {
        return (sectionData.timerData && sectionData.timerData.work) || '00:00:00';
      }
      return null;
    };

    const saveSectionUser = () => {
      let error = false;
      if (!workout?.isCompetition || !!workout?.competitionUseRxScaledScores) {
        if (!sectionUserScores[currentIndexScore]?.variation) {
          error = true;
        }
      }

      if (error) {
        dispatch(notifications.warning('Add score variation.'));
        return;
      }

      const scoresData = sectionUserScores.map((itemScore) => {
        const newItemScore = { ...itemScore };
        if (itemScore.distance && !itemScore.typeDistance) {
          newItemScore.typeDistance = typeDistance[0].id;
        }
        if (itemScore.weight && !itemScore.typeWeight) {
          newItemScore.typeWeight = typeWeight[0].id;
        }
        const time =
          newItemScore.time && itemScore.time.value && !itemScore.timeCapped
            ? itemScore.time.value
            : totalTimeSection();

        const tieBreaktime = newItemScore?.tieBreaktime?.value
          ? newItemScore?.tieBreaktime?.value
          : null;

        return {
          ...newItemScore,
          time,
          tieBreaktime,
        };
      });

      if (saveFormPenalty) {
        if (isMultipleScores && currentScore < sectionData?.scores?.length) {
          setCurrentScore((prev) => prev + 1);
          return;
        }
        saveFormPenalty(scoresData);

        return;
      }

      const callback = () => {
        if (isMultipleScores && currentScore < sectionData?.scores?.length) {
          setCurrentScore((prev) => prev + 1);
          return;
        }
        saveCallback();
      };

      dispatch(
        workoutsActions.setWorkoutSectionUserStart(
          {
            workoutId: workout.id,
            data: {
              createdAt: formatDateToApi(data.createdAt || moment()),
              scores: scoresData,
              sectionId: sectionData.id,
              userId: user.id,
            },
          },
          (resp) => {
            if (resp?.showPrModalData?.length > 0) {
              setShowPrModal({
                open: true,
                data: resp?.showPrModalData,
                onClose: () => {
                  setShowPrModal({ open: false });
                  if (callback) {
                    callback();
                  }
                },
              });
            } else if (callback) {
              callback();
            }
            dispatch(notifications.success(t('message.success.save.score')));
          },
        ),
      );
    };

    useImperativeHandle(ref, () => ({
      saveSection() {
        saveSectionUser();
      },
    }));

    const currentEditScores = sectionUserScores[currentIndexScore] || {};

    return (
      <>
        <Dialog
          open={showPrModal?.open}
          onClose={() => {
            showPrModal.onClose();
          }}
        >
          <Texture>
            <Box p={10}>
              <div className="pyro">
                <div className="before" />
                <div className="after" />
              </div>

              {showPrModal?.data
                ?.filter((x) => x.benchmark)
                .map((item, index) => (
                  <Box key={index} mb={3} display="flex" flexDirection="column" alignItems="center">
                    <Typography variant="h4">
                      {t('new.benchmark.added').replace(
                        '#{benchmark}',
                        sectionData?.benchmarkSection?.name,
                      )}
                    </Typography>

                    <BenchmarkUserScores
                      scores={item?.data?.scores}
                      fontValue={fontStyle.large}
                      fontScore={fontStyle.medium}
                    />
                  </Box>
                ))}

              {showPrModal?.data
                ?.filter((x) => x.pr)
                .map((item, index) => (
                  <Box key={index} mb={3}>
                    <Typography variant="h4">
                      {t('new.pr.added').replace(
                        '#{pr_name}',
                        sectionData?.scores[currentIndexScore]?.personalRecord?.movementName,
                      )}
                    </Typography>
                  </Box>
                ))}
            </Box>
          </Texture>
        </Dialog>
        {isCustomWorkout && (
          <Box>
            <DatePickerRounded
              autoOk
              disablePast={false}
              label={t('form.personalRecord.date')}
              placeholder={t('form.personalRecord.date')}
              value={data.createdAt}
              onChange={(v) => setData((prev) => ({ ...prev, createdAt: v }))}
            />
          </Box>
        )}

        <Typography my={3} align="center">
          {sectionData?.scores[currentIndexScore]?.name || `Score: ${currentScore}`}
          {sectionData?.scores[currentIndexScore]?.personalRecord?.movementName
            ? ` - ${sectionData?.scores[currentIndexScore]?.personalRecord?.movementName}`
            : null}
        </Typography>

        {!isCustomWorkout && !workout.isCompetition && (
          <Box display="flex" justifyContent="center" my={3}>
            <Radio
              color={secondary}
              label={t('workout.section.label.public')}
              onClick={() => changeCurrentScores({ isPrivate: false })}
              checked={!currentEditScores?.isPrivate}
            />
            <Box ml={3}>
              <Radio
                color={secondary}
                label={t('workout.section.label.private')}
                onClick={() => changeCurrentScores({ isPrivate: true })}
                checked={!!currentEditScores?.isPrivate}
              />
            </Box>
          </Box>
        )}
        <Box display="flex" alignItems="center">
          <Box display="flex" justifyContent="center" flexDirection="column" flexGrow={1}>
            {sectionData.scores && (
              <>
                <ScoreFields
                  workout={workout}
                  sectionData={sectionData}
                  scoresTypes={sectionData.scores[currentIndexScore]}
                  currentEditScores={currentEditScores}
                  timeCapped={currentEditScores.timeCapped}
                  changeCurrentScores={changeCurrentScores}
                />

                {hasScore(sectionData.scores[currentIndexScore], 'tieBreak') && (
                  <Box mt={3}>
                    <Typography>Tie Break</Typography>
                    <Typography className="roboto-regular">
                      {sectionData.scores[currentIndexScore]?.tieBreakDescription}
                    </Typography>

                    <ScoreFields
                      workout={workout}
                      sectionData={sectionData}
                      scoresTypes={{ [sectionData.scores[currentIndexScore].tieBreakScore]: true }}
                      currentEditScores={currentEditScores}
                      changeCurrentScores={changeCurrentScores}
                      prefix="tieBreak"
                    />
                  </Box>
                )}

                {(!workout.isCompetition || !!workout.competitionUseRxScaledScores) && (
                  <Box my={3} className="gap-5">
                    <SelectBoxObject
                      propValue="id"
                      propLabel="name"
                      label={t('channel.scoreDivision')}
                      options={variationsOptions}
                      value={currentEditScores.variation}
                      setValue={(v) =>
                        changeCurrentScores({
                          variation: v,
                          variationName: variationsOptions?.find((x) => x.id == v)?.name,
                        })
                      }
                    />
                  </Box>
                )}
              </>
            )}

            {showSaveButton && (
              <Box display="flex" mt={5} justifyContent="center">
                <ButtonPrimary
                  fullWidth
                  buttonColor={buttonColor || primary}
                  onClick={() => saveSectionUser()}
                >
                  {t('confirm')}
                </ButtonPrimary>
              </Box>
            )}
          </Box>
        </Box>
      </>
    );
  },
);

FormScore.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  sectionData: propTypes.object,
  saveCallback: propTypes.func,
  saveFormPenalty: propTypes.func,
  showSaveButton: propTypes.bool,
};

FormScore.defaultProps = {
  sectionData: {},
  saveCallback: () => {},
  showSaveButton: true,
  saveFormPenalty: null,
};

export default FormScore;
