/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import { Dialog, IconButton, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import { ButtonPrimary, ButtonPrimarySm } from 'core/ui/Button';
import Texture from 'core/ui/Texture';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import { channelsRatesTemplatesTypes } from 'core/utils/consts';
import Paper from 'core/ui/Paper';
import Typography from 'core/ui/Typography';
import MemberSell from 'components/Channel/Show/Tabs/Store/MemberSell';
import Grid from 'core/ui/Grid';
import { Delete } from '@material-ui/icons';
import useForm from './form';
import ModalMemberships from './MemberMemberships';
import WorkoutsTypes from './WorkoutsTypes';

const ModalFormList = ({ open, item, close, callback, mode, channel, coach }) => {
  const { t } = useTranslation();
  const [membershipsMember, setMembershipsMember] = useState({ open: false, membershipPlans: [] });
  const {
    templates,
    member,
    setMember,
    setValue,
    watch,
    handleSubmit,
    onSubmit,
    errors,
    addUser,
    members,
    loading,
    deleteMember,
    changeMembershipPlans,
  } = useForm({
    mode,
    item,
    callback,
    channel,
    coach,
  });

  const currentKind = templates?.find((x) => x.id === Number(watch('channelsRatesTemplateId')));

  return (
    <Dialog maxWidth="md" scroll="body" fullWidth onClose={() => close()} open={open}>
      <ModalMemberships
        open={membershipsMember?.open}
        channel={channel}
        close={() => setMembershipsMember({ open: false })}
        checkedItems={
          members?.find((x) => x.user?.id === membershipsMember?.member?.user?.id)
            ?.membershipPlanIds || []
        }
        setCheckedItems={(items) => {
          changeMembershipPlans(membershipsMember?.member, items);
        }}
      />

      <Texture>
        <Box p={5}>
          <Grid container spacing={5}>
            <Grid
              item
              xs={12}
              md={currentKind?.kind === channelsRatesTemplatesTypes.percent_by_membership ? 6 : 12}
            >
              <Box mb={3}>
                <SelectBoxObject
                  label={t('channel.rateTemplate').toUpperCase()}
                  options={templates?.map((x) => ({
                    ...x,
                    name: `${x.name} - ${t(`channel.rateTemplate.kind.${x.kind}`)}`,
                  }))}
                  value={watch('channelsRatesTemplateId')}
                  setValue={(v) => setValue('channelsRatesTemplateId', v)}
                  error={errors.channelsRatesTemplateId}
                  required
                />
              </Box>

              {(currentKind?.kind === channelsRatesTemplatesTypes.percent_by_class ||
                currentKind?.kind === channelsRatesTemplatesTypes.price_by_class) &&
                channel?.workoutsTypes?.length > 0 && (
                  <WorkoutsTypes
                    checkedItems={watch('channelsWorkoutsTypeIds') || []}
                    setCheckedItems={(v) => setValue('channelsWorkoutsTypeIds', v)}
                    channel={channel}
                  />
                )}

              {currentKind?.kind === channelsRatesTemplatesTypes.percent_by_membership && (
                <>
                  <MemberSell
                    title={t('channel.members')}
                    setMember={setMember}
                    member={member}
                    channelId={channel.id}
                  />
                  <Box m={2}>
                    <ButtonPrimary fullWidth onClick={() => addUser()}>
                      {t('button.addMember')}
                    </ButtonPrimary>
                  </Box>
                </>
              )}
            </Grid>
            {currentKind?.kind === channelsRatesTemplatesTypes.percent_by_membership && (
              <Grid item xs={12} md={6}>
                <>
                  <Typography mb={2}>{t('channel.rate.coach.members').toUpperCase()}</Typography>
                  <Paper p={3}>
                    <Box>
                      {members?.length === 0 && (
                        <Typography align="center">No members added.</Typography>
                      )}
                      <Table>
                        <TableBody>
                          {members
                            ?.filter((x) => !x._destroy)
                            .map((itemChild, index) => (
                              <TableRow key={index}>
                                <TableCell>
                                  <Typography noWrap>{itemChild?.user?.name}</Typography>
                                </TableCell>
                                <TableCell style={{ minWidth: 100 }}>
                                  <ButtonPrimarySm
                                    onClick={() =>
                                      setMembershipsMember({ open: true, member: itemChild })
                                    }
                                  >
                                    {t('button.memberships')}
                                  </ButtonPrimarySm>
                                </TableCell>
                                <TableCell>
                                  <IconButton onClick={() => deleteMember(itemChild)}>
                                    <Delete />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </Box>
                  </Paper>
                </>
              </Grid>
            )}
          </Grid>

          <Box mt={3} display="flex" justifyContent="flex-end">
            <ButtonPrimary disabled={loading} onClick={handleSubmit(onSubmit)}>
              {t('button.save')}
            </ButtonPrimary>
          </Box>
        </Box>
      </Texture>
    </Dialog>
  );
};

ModalFormList.defaultProps = {
  close: () => {},
  callback: () => {},
  open: false,
  mode: 'new',
};

ModalFormList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  close: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  callback: PropTypes.func,
  open: PropTypes.bool,
  mode: PropTypes.string,
};

export default ModalFormList;
