import React, { useContext, lazy, Suspense } from 'react';
import Box from 'core/ui/Box';
import { StreamContext } from 'pages/Stream';
import Scrollbars from 'react-custom-scrollbars';
import LoaderSm from 'core/ui/LoaderSm';
import Leaderboard from './Leaderboard';
import ModalRecording from './ModalRecording';

const Chat = lazy(() => import('./Chat/index'));
const Participants = lazy(() => import('./Participants/index'));
const Settings = lazy(() => import('./Settings'));
const Sections = lazy(() => import('./Sections'));

export const ComponentsSideBar = {
  Participants: 'Participants',
  Chat: 'Chat',
  Sections: 'Sections',
  Leaderboard: 'Leaderboard',
  Settings: 'Settings',
};

export default () => {
  const streamContext = useContext(StreamContext);
  return (
    <>
      {streamContext.modalRecording.open && <ModalRecording />}
      <Box
        className={streamContext.openDrawer === false ? 'hide' : ''}
        style={{ width: '100%', height: '100%', backgroundColor: '#373738' }}
      >
        <>
          <Suspense
            fallback={
              <Box
                display="flex"
                style={{ height: '100%' }}
                alignItems="center"
                justifyContent="center"
              >
                <LoaderSm loading width={30} />
              </Box>
            }
          >
            {streamContext.componentSideBar === ComponentsSideBar.Participants && (
              <Scrollbars>
                <Participants />
              </Scrollbars>
            )}
            {streamContext.componentSideBar === ComponentsSideBar.Chat && <Chat />}
            {streamContext.componentSideBar === ComponentsSideBar.Sections && (
              <Scrollbars>
                <Sections />
              </Scrollbars>
            )}
            {streamContext.componentSideBar === ComponentsSideBar.Leaderboard && (
              <Scrollbars>
                <Leaderboard openDrawer={streamContext.openDrawer} />
              </Scrollbars>
            )}
          </Suspense>
          <Box
            style={{
              display: streamContext.componentSideBar === ComponentsSideBar.Settings ? '' : 'none',
            }}
          >
            <Settings />
          </Box>
        </>
      </Box>
    </>
  );
};
