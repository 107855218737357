import React, { useEffect, useState } from 'react';
import { ButtonPrimary } from 'core/ui/Button';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import Typography from 'core/ui/Typography';

import {
  TableContainer,
  TableHead,
  Table,
  TableCell,
  TableRow,
  TableBody,
  Paper,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import LoaderSm from 'core/ui/LoaderSm';
import useSelectors from 'modules/map/useSelectors';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import { documentsModule } from 'modules/documents';
import { channelsActions } from 'modules/channels';
import { useDispatch } from 'react-redux';
import { showAPIError } from 'core/utils/showAPIError';
import Toggle from 'core/ui/Toggle';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import { competitionsActions } from 'modules/competitions';
import ItemDocument from './ItemDocument';
import ModalForm from './Form/Modal';
import ModalDocumentViewer from './ModalDocumentViewer';

const DocumentsBuilder = ({
  ownerableId,
  ownerableType,
  defaultDocumentWaiverId,
  competitionId,
}) => {
  const { t } = useTranslation();
  const [modalPreview, setModalPreview] = useState({ open: false });
  const dispatch = useDispatch();
  const [filter, setFilter] = useState({ text: '', kind: null });
  const loading = useSelectors(documentsModule, 'loading');
  const [data, setData] = useState([]);
  const { request } = useActions();

  const [modal, setModal] = useState({
    open: false,
  });

  const getData = () => {
    request({
      action: Modules.documents.actions.getDocuments,
      data: { ownerableId, ownerableType, ...filter },
      options: {
        onSuccess: setData,
      },
    });
  };

  useEffect(() => {
    if (ownerableId && ownerableType) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request, ownerableId, filter]);

  const onRemoveClick = (id) => {
    if (ownerableId && ownerableType) {
      request({
        action: Modules.documents.actions.destroy,
        data: { ownerableId, ownerableType, id },
        options: {
          onSuccess: () => {
            getData();
          },
          onError: showAPIError,
        },
      });
    }
  };

  const updatePrimaryWaiver = (docId) => {
    if (ownerableType === 'Channel' && !competitionId) {
      dispatch(
        channelsActions.putChannelStart({
          id: ownerableId,
          data: {
            id: ownerableId,
            documentWaiverId: docId,
          },
          messageSuccess: t('message.success.save.channel'),
        }),
      );
    }
    if (ownerableType === 'Competition' || competitionId) {
      dispatch(
        competitionsActions.putCompetitionStart({
          data: {
            id: competitionId,
            documentWaiverId: docId,
          },
          messageSuccess: t('message.success.save.competition'),
        }),
      );
    }
  };

  const openModal = () => {
    setModal((prev) => ({ ...prev, mode: 'new', open: true }));
  };

  const onEditClick = (item) => {
    setModal((prev) => ({ ...prev, item, mode: 'edit', open: true }));
  };

  return (
    <Box p={5}>
      {modal.open && (
        <ModalForm
          open={modal.open}
          documentId={modal.item?.id}
          mode={modal.mode}
          ownerableId={ownerableId}
          ownerableType={ownerableType}
          close={() => setModal(() => ({ open: false }))}
          callback={() => {
            setModal(() => ({ open: false }));
            getData();
          }}
        />
      )}

      <Box display="flex" alignItems="center">
        {ownerableType === 'Channel' && (
          <Box mr={3}>
            <Toggle
              options={[
                {
                  label: 'All',
                  value: null,
                  width: 160,
                },
                {
                  label: t(`document.kind.waiver`),
                  value: 'waiver',
                  width: 160,
                },
                {
                  label: t(`document.kind.questionnaire`),
                  value: 'questionnaire',
                  width: 160,
                },
                {
                  label: t(`document.kind.contract`),
                  value: 'contract',
                  width: 160,
                },
              ]}
              value={filter?.kind}
              color="white"
              onChange={(value) => setFilter((prev) => ({ ...prev, kind: value }))}
              fontSize="large"
            />
          </Box>
        )}
        <Box flexGrow={1} mr={3}>
          <TextFieldRounded
            style={{ height: 32 }}
            placeholder={t('label.search.document')}
            value={filter?.text}
            onChange={(v) => setFilter((prev) => ({ ...prev, text: v }))}
          />
        </Box>
        <LoaderSm loading={loading} />
        <Box>
          <ButtonPrimary onClick={() => openModal()}>
            <Box display="flex" alignItems="flex-end">
              <Add size="small" />
              <Box alignItems="center">
                <Typography component="span" mr={1}>
                  {t('button.addDocument')}
                </Typography>
              </Box>
            </Box>
          </ButtonPrimary>
        </Box>
      </Box>
      <Box mt={2}>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>{t('label.name')}</TableCell>
                <TableCell>{t('label.kind')}</TableCell>
                <TableCell align="center" />
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data.map((row) => (
                  <ItemDocument
                    onEditClick={onEditClick}
                    onRemoveClick={onRemoveClick}
                    updatePrimaryWaiver={updatePrimaryWaiver}
                    key={row.name}
                    item={row}
                    defaultDocumentWaiverId={defaultDocumentWaiverId}
                    expanded={modal.nameListExpand && modal.nameListExpand === row.name}
                    preview={(s) => setModalPreview({ open: true, document: s })}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <ModalDocumentViewer
        document={modalPreview.document}
        open={modalPreview.open}
        close={() => setModalPreview({ open: false })}
      />
    </Box>
  );
};

export default DocumentsBuilder;
