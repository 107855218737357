import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { debounce, IconButton } from '@material-ui/core';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import SignatureCanvas from 'react-signature-canvas';

import Typography, { fontStyle } from 'core/ui/Typography';

const MAX_SIGNATURE_WIDTH = 550;

const borderWidth = 1;
const borderOffset = borderWidth * 2;

export const SignatureSection = ({ itemsEls, section, signaturesErrors, index }) => {
  const wrapperRef = useRef();

  const { t } = useTranslation();

  const [width, setWidth] = useState(0);

  const canvasWidth = width - borderOffset;
  const canvasHeight = Math.trunc(canvasWidth * 0.7);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(
      debounce((entries) => {
        if (entries.length < 1) return;

        setWidth((prevWidth) => {
          const newWidth = Math.min(
            MAX_SIGNATURE_WIDTH + borderOffset,
            entries[0].contentRect.width,
          );

          // If the width varies, the canvas is cleared. So we only allow it to increase, so the user can rotate the phone and sign.
          return newWidth > prevWidth ? newWidth : prevWidth;
        });
      }, 75),
    );

    resizeObserver.observe(wrapperRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <div ref={wrapperRef}>
      <Typography className="tw-mb-0.5">{t('document.section.kind.signature')}</Typography>

      <div className="tw-text-center">
        <div
          className="tw-relative tw-inline-block tw-rounded tw-border-solid tw-border-neutral-400"
          style={{ borderWidth }}
        >
          <SignatureCanvas
            penColor="black"
            ref={(ref) => {
              itemsEls.current[index] = ref;
            }}
            canvasProps={{
              width: canvasWidth,
              height: canvasHeight,
            }}
            clearOnResize={false}
          />

          <IconButton
            onClick={() => itemsEls.current[index].clear()}
            className="tw-absolute tw-right-0 tw-top-0"
          >
            <RotateLeftIcon />
          </IconButton>
        </div>
      </div>

      {signaturesErrors[`signature-${section?.id}`] && (
        <Typography m={1} style={fontStyle.xSmall} color="error">
          {t('document.section.kind.signature')} is required
        </Typography>
      )}
    </div>
  );
};
