import React, { useEffect, useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from 'core/ui/Typography';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { workoutsActions, workoutsSelectors } from 'modules/workouts';
import { getTimeNow, hhmmss } from 'core/utils/formatters/date';
import { primary } from 'core/ui/Colors';
import notifications from 'modules/notifications';

const useStyles = makeStyles({
  root: {
    position: 'absolute',
    textAlign: 'center',
    right: 0,
    left: 0,
  },
  boxTimer: {
    backgroundColor: primary,
    padding: 2,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    width: 190,
    color: 'white',
    zIndex: 11,
    fontSize: 10,
  },
});

export default function TimerRemaining({ exit }) {
  const classes = useStyles();
  const workout = useSelector((state) => workoutsSelectors.getWorkout(state));
  const workoutCoachAccessed = useSelector((state) => workoutsSelectors.getCoachAccessed(state));

  const T_WAIT_COACH = 25 * 60;
  const T_SHOW_TIMER = 10 * 60;

  const [time, setTime] = useState({ value: 0 });
  const [timeWaitCoach, setTimeWaitCoach] = useState({ value: 0 });
  const intervalRemaining = useRef();
  const intervalCoachAcccess = useRef();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(workoutsActions.getCoachAccessedStart(workout.id));

    return () => {
      clearInterval(intervalCoachAcccess.current);
      clearInterval(intervalRemaining.current);
    };
  }, [dispatch, workout]);

  const endWorkout = useCallback(() => {
    dispatch(notifications.warning(t('workout.stream.ended')));
    clearInterval(intervalCoachAcccess.current);
    clearInterval(intervalRemaining.current);
    exit(true);
  }, [dispatch, t]); //eslint-disable-line

  const incrementInterval = useCallback(() => {
    const now = getTimeNow();
    const endAt = new Date(workout.classEndAt).getTime();
    const dif = endAt - now;
    if (dif <= 0) {
      endWorkout();
      return;
    }
    setTime({
      endAt,
      value: Math.round(dif / 1000),
    });

    if (workoutCoachAccessed === false && workout.method === 'live') {
      const scheduledAt = new Date(workout.datetime || workout.scheduledAt).getTime();
      const difSchedule = Math.round((now - scheduledAt) / 1000);

      setTimeWaitCoach({
        value: T_WAIT_COACH - difSchedule,
      });
    }
  }, [workout, T_WAIT_COACH, endWorkout, workoutCoachAccessed]);

  useEffect(() => {
    if (workout.classEndAt) {
      const now = getTimeNow();
      const endAt = new Date(workout.classEndAt).getTime();
      const dif = endAt - now;
      if (dif > 0) {
        setTime({
          endAt,
          value: Math.round(dif / 1000),
        });
        intervalRemaining.current = setInterval(() => {
          incrementInterval();
        }, 1000);
      }
    }
    return () => {
      clearInterval(intervalRemaining.current);
    };
  }, [workout]); //eslint-disable-line

  const validateCoachAccess = useCallback(() => {
    clearInterval(intervalCoachAcccess.current);
    intervalCoachAcccess.current = setInterval(() => {
      if (workout.id) {
        dispatch(workoutsActions.getCoachAccessedStart(workout.id));
      }
    }, 2000);

    if (workoutCoachAccessed === true) {
      clearInterval(intervalCoachAcccess.current);
      setTimeWaitCoach({ value: 0 });
    }
  }, [dispatch, workout, workoutCoachAccessed]);

  useEffect(() => {
    if (workout.id && !workout.isCoach && workout.method === 'live') {
      validateCoachAccess();
    }
  }, [workout, validateCoachAccess, workoutCoachAccessed, dispatch]);

  useEffect(() => {
    if (!workout.isCoach && timeWaitCoach.value < 0 && workoutCoachAccessed === false) {
      clearInterval(intervalCoachAcccess.current);
      clearInterval(intervalRemaining.current);
      exit(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeWaitCoach.value, workout, workoutCoachAccessed]);

  return (
    <Box className={classes.root}>
      <Box display="flex" justifyContent="center">
        {workout.classEndAt && workout.live && time.value <= T_SHOW_TIMER && (
          <Typography className={classes.boxTimer}>
            {t('workout.stream.timeRemaining')} {hhmmss(time.value)}
          </Typography>
        )}
        {workout.method === 'live' &&
          workout.live &&
          !workout.isCoach &&
          !workoutCoachAccessed &&
          timeWaitCoach.value >= 0 &&
          timeWaitCoach.value <= T_SHOW_TIMER && (
            <Typography className={classes.boxTimer}>
              {t('workout.stream.timeWaitCoachAccess')} {hhmmss(timeWaitCoach.value)}
            </Typography>
          )}
      </Box>
    </Box>
  );
}

TimerRemaining.propTypes = {
  exit: PropTypes.func,
};

TimerRemaining.defaultProps = {
  exit: () => {},
};
