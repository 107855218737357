import React from 'react';
import Box from 'core/ui/Box';
import Typography, { fontStyle } from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';

export default ({ plan, font }) => {
  const { t } = useTranslation();
  return (
    <Box display="flex" justifyContent="center">
      <Typography style={font || fontStyle.medium} noWrap>
        {t('channel.membership.limitUse')}:&nbsp;
      </Typography>
      <Typography style={font || fontStyle.medium} noWrap>
        {Number(plan?.limitUse) > 0 ? plan?.limitUse : ''}{' '}
        {Number(plan?.limitUse) > 1 ? 'classes' : 'class'}
      </Typography>
      {plan.customIncrement !== 'limit0' && (
        <>
          <Typography style={font || fontStyle.medium} noWrap mx={1}>
            &bull;
          </Typography>
          <Typography style={font || fontStyle.medium} noWrap>
            {plan?.limitUseIncrementNumber > 0 && plan?.limitUseIncrement !== 'renew_frequency'
              ? plan?.limitUseIncrementNumber
              : null}
            &nbsp;&nbsp;
            {t(`channel.memberships.custom.${plan?.limitUseIncrement}`)}
          </Typography>
        </>
      )}
    </Box>
  );
};
