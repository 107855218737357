/* eslint-disable react/prop-types */
import React from 'react';
import Typography from 'core/ui/Typography';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import { time, dateExt } from 'core/utils/formatters';
import ScheduleIcon from '@material-ui/icons/Schedule';
import LiveIcon from 'core/ui/LiveIcon';

export default ({ workout }) => {
  const { t } = useTranslation();
  const buttonColor = workout?.channel?.customColors?.buttonColor;
  return (
    <Box mt={2}>
      <Box display="flex" alignItems="center">
        {workout.live && workout.online && workout.started && (
          <Box mr={2}>
            <LiveIcon buttonColor={buttonColor} />
          </Box>
        )}
        <ScheduleIcon style={{ fontSize: 18 }} />
        <Typography color="textSecondary" ml={1} component="span" variant="body1">
          <span>
            {dateExt(
              workout.method !== 'live' ? workout.publishAt : workout.scheduledAt,
              t,
              workout?.channel?.useUserTimezone ? null : workout?.timezone,
            )}
          </span>
          <span>&nbsp;&bull;&nbsp;</span>
          <span className="roboto-regular">
            {time(
              workout.method !== 'live' ? workout.publishAt : workout.scheduledAt,
              workout?.channel?.useUserTimezone ? null : workout?.timezone,
            )}
          </span>
          <span>&nbsp;&bull;&nbsp;</span>

          {workout.online ? (
            <span className="roboto-regular">
              {Math.round(workout.durationMinutes)} {t('workout.duration.mins')}
            </span>
          ) : (
            <span className="roboto-regular">
              {time(workout.endAt, workout?.channel?.useUserTimezone ? null : workout?.timezone)}
            </span>
          )}
        </Typography>
      </Box>
    </Box>
  );
};
