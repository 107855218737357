/* eslint-disable react/prop-types */
import React, { useContext, useEffect } from 'react';

import Box from 'core/ui/Box';
import './style.css';
import { Fade } from '@material-ui/core';
import ReactGA from 'react-ga';
import { LayoutContext } from 'pages/shared/Layout';
import useBanners from '.';

const BannerHeader = ({ show, page }) => {
  const { current, lastBanner, bannersHeader, heightHeaderWrapper } = useBanners({ page });
  const layoutContext = useContext(LayoutContext);

  useEffect(() => {
    layoutContext.setHeightHeaderWrapper(heightHeaderWrapper);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (bannersHeader.length <= 0) {
    return <></>;
  }
  return (
    <Box
      className={show ? '' : 'hide'}
      style={{ height: heightHeaderWrapper - 2, position: 'relative' }}
    >
      {lastBanner && lastBanner.image && lastBanner.image.image && (
        <Fade style={{ position: 'absolute' }} in={!!lastBanner.url} timeout={3000}>
          <a
            href={lastBanner.url}
            // eslint-disable-next-line react/jsx-no-target-blank
            target="_blank"
            rel="noreferrer"
          >
            {lastBanner.image && lastBanner.image.image.url && (
              <img src={lastBanner.image.image.url} alt="Banner" style={{ width: '100%' }} />
            )}
          </a>
        </Fade>
      )}

      {current.image && current.image.image && (
        <Fade style={{ position: 'absolute' }} in key={current.image.image.url} timeout={3000}>
          <ReactGA.OutboundLink
            eventLabel={`${current.banner.company} - ${current.order} - ${current.alt}`}
            to={current.url}
            target="_blank"
          >
            {current.image.image.url && (
              <img src={current.image.image.url} alt="Banner" style={{ width: '100%' }} />
            )}
          </ReactGA.OutboundLink>
        </Fade>
      )}
    </Box>
  );
};

export default BannerHeader;
