import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Edit } from '@material-ui/icons';

export default function ItemTag({ item, onEditClick }) {
  return (
    <TableRow>
      <TableCell>{item.name}</TableCell>
      <TableCell align="center">{item.active ? 'YES' : 'NO'}</TableCell>
      <TableCell align="right">
        <IconButton onClick={() => onEditClick(item)}>
          <Edit />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

ItemTag.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.object.isRequired,
  onEditClick: PropTypes.func.isRequired,
};
