import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

import * as selectors from './selectors';
import * as sagas from './sagas';

/* ---------- Action Creators and Types ---------- */
const { Types, Creators } = createActions(
  {
    getScheduleSessionError: ['error'],
    getScheduleSessionStart: ['sessionId'],
    getScheduleSessionSuccess: ['session'],

    getUserScheduleSessionsError: ['error'],
    getUserScheduleSessionsStart: ['userId'],
    getUserScheduleSessionsSuccess: ['sessions'],

    getCoachScheduleSessionsError: ['error'],
    getCoachScheduleSessionsStart: ['coachId'],
    getCoachScheduleSessionsSuccess: ['sessions'],

    clearSession: [],
  },
  { prefix: 'coachScheduleSessions/' },
);

/* ---------- Initial State ---------- */
const INITIAL_STATE = Immutable({
  data: [],
  session: null,
  dataCoach: [],
  error: null,
  loading: false,
  loadingSession: false,
});

/* ---------- Reducers ---------- */
const loadStartReducer = (state) => state.set('loading', true);

const loadScheduleSessionStart = (state) =>
  state.merge({
    session: null,
    loadingSession: true,
    error: null,
  });

const getUserScheduledSessionsSuccessReducer = (state, action) =>
  state.merge({
    data: action.sessions,
    loading: false,
    error: null,
  });

const getSessionSuccessReducer = (state, action) =>
  state.merge({
    session: action.session,
    loadingSession: false,
    error: null,
  });

const getCoachScheduledSessionsSuccessReducer = (state, action) =>
  state.merge({
    dataCoach: action.sessions,
    loading: false,
    error: null,
  });

const loadErrorReducer = (state, action) =>
  state.merge({
    loading: false,
    error: action.error,
  });

const loadSessionErrorReducer = (state, action) =>
  state.merge({
    loadingSession: false,
    error: action.error,
  });

const clearSessionReducer = (state) =>
  state.merge({
    session: null,
  });

/* ---------- Main Reducer ---------- */
const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_USER_SCHEDULE_SESSIONS_START]: loadStartReducer,
  [Types.GET_USER_SCHEDULE_SESSIONS_SUCCESS]: getUserScheduledSessionsSuccessReducer,
  [Types.GET_USER_SCHEDULE_SESSIONS_ERROR]: loadErrorReducer,

  [Types.GET_COACH_SCHEDULE_SESSIONS_START]: loadStartReducer,
  [Types.GET_COACH_SCHEDULE_SESSIONS_SUCCESS]: getCoachScheduledSessionsSuccessReducer,
  [Types.GET_COACH_SCHEDULE_SESSIONS_ERROR]: loadErrorReducer,

  [Types.GET_SCHEDULE_SESSION_START]: loadScheduleSessionStart,
  [Types.GET_SCHEDULE_SESSION_SUCCESS]: getSessionSuccessReducer,
  [Types.GET_SCHEDULE_SESSION_ERROR]: loadSessionErrorReducer,

  [Types.CLEAR_SESSION]: clearSessionReducer,
});

/* ---------- Exporting ---------- */
export const coachScheduleSessionsActions = Creators;
export const coachScheduleSessionsTypes = Types;
export const coachScheduleSessionsSelectors = selectors;
export const coachScheduleSessionsSagas = sagas;

export default reducer;
