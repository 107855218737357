import React, { useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import InputBase from '@material-ui/core/InputBase';
import { MenuItem, Select, Paper } from '@material-ui/core';
import { countriesModule } from 'modules/countries';
import Modules from 'modules';
import useActions from 'modules/map/useActions';
import useSelectors from 'modules/map/useSelectors';
import Typography, { fontStyle } from '../Typography';
import Box from '../Box';
import { secondaryLight } from '../Colors';

const BootstrapInput = withStyles(() => ({
  input: {
    borderRadius: 5,
    position: 'relative',
    backgroundColor: '#fff',
    width: '100%',
    fontSize: 12,
    paddingLeft: 5,
    '&:focus': {
      backgroundColor: '#fff',
      borderRadius: 5,
      borderColor: '#80bdff',
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: 5,
    width: '100%',
  },
  select: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontSize: 12,
    paddingLeft: 5,
  },
  placeHolder: {
    color: '#C7C7CD',
  },
}));

export default function StateChosen({
  countryId,
  placeholder,
  defaultValue,
  label,
  error,
  setValue,
  required,
  bordered,
  paperStyle,
}) {
  const classes = useStyles();
  const states = useSelectors(countriesModule, 'states');
  const { request } = useActions();
  const styleInput = error
    ? paperStyle
    : { ...paperStyle, border: bordered ? `1px solid${secondaryLight}` : 'none' };
  useEffect(() => {
    if (countryId) {
      request({
        action: Modules.countries.actions.getStates,
        data: { countryId },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryId]);

  return (
    <div>
      {label !== '' && (
        <Box ml={2} mb={2} display="flex">
          <Typography style={fontStyle.label} variant="body1">
            {label}
          </Typography>
          {required && (
            <Typography ml={1} color="error">
              *
            </Typography>
          )}
        </Box>
      )}
      <Paper style={styleInput} className={`${error ? 'input-paper-error' : ''} ${classes.root}`}>
        <Select
          displayEmpty
          className={`${classes.select} ${defaultValue === 'undefined' ? classes.placeHolder : ''}`}
          value={`${defaultValue}`}
          onChange={(e) => setValue(e.target.value)}
          input={<BootstrapInput />}
        >
          {placeholder && (
            <MenuItem disabled value={undefined}>
              <em>{placeholder}</em>
            </MenuItem>
          )}
          {states.map((x) => (
            <MenuItem key={x} value={x}>
              {x}
            </MenuItem>
          ))}
        </Select>
      </Paper>
    </div>
  );
}

StateChosen.propTypes = {
  label: PropTypes.string,
  countryId: PropTypes.number,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  error: PropTypes.object,
  setValue: PropTypes.func,
};

StateChosen.defaultProps = {
  label: '',
  countryId: null,
  defaultValue: '',
  placeholder: '',
  error: null,
  setValue: null,
};
