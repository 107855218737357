import api from 'core/api';

export const groupsChatsUsersModule = 'groupsChatsUsers';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const actions = {
  create: {
    module: groupsChatsUsersModule,
    name: 'create',
    api: (params) =>
      api.post(`/chat/groups_chats/${params?.groupsChatId}/groups_chats_users`, params),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  update: {
    module: groupsChatsUsersModule,
    name: 'update',
    api: (params) =>
      api.put(
        `/chat/groups_chats/${params?.groupsChatId}/groups_chats_users/${params?.userId}`,
        params,
      ),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  destroy: {
    module: groupsChatsUsersModule,
    name: 'destroy',
    api: (params) =>
      api.delete(
        `/chat/groups_chats/${params?.groupsChatId}/groups_chats_users/${params?.userId}`,
        { params },
      ),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
};

export default {
  actions,
  state: {
    loading: false,
    data: [],
  },
};
