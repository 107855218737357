import React from 'react';
import Box from 'core/ui/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from 'core/ui/Paper';
import { useSelector } from 'react-redux';
import { sessionSelectors } from 'modules/session';
import { Container } from '@material-ui/core';
import AdminPaymentsReport from './Payments';
import PayoutsReport from './Payouts';
import AdminGymsChart from './GymsChart';
import PendingMemberships from './PendingMemberships';
import AuditedLogsReport from './AuditedLogs';
import Forecast from './Forecast';
import OnboardingReport from './OnboardingReport';

export default function Reports() {
  const isAdmin = useSelector((state) => sessionSelectors.getIsAdmin(state));
  const [value, setValue] = React.useState(null);

  if (!isAdmin) {
    return (
      <Box pt={5}>
        <AuditedLogsReport />
      </Box>
    );
  }

  return (
    <Box>
      <Paper>
        <Tabs
          value={value}
          onChange={(_, newValue) => setValue(newValue)}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="Gyms Metrics" />
          <Tab label="Forecast" />
          <Tab label="Pending Memberships" />
          <Tab label="Payments" />
          <Tab label="Payouts" />
          <Tab label="Onboarding" />
          <Tab label="Logs" />
        </Tabs>
      </Paper>

      {value === 6 && <AuditedLogsReport />}

      <div className="tw-mt-5">
        <Container maxWidth="xl">
          {value === 0 && <AdminGymsChart />}
          {value === 1 && <Forecast />}
          {value === 2 && <PendingMemberships />}
          {value === 3 && <AdminPaymentsReport />}
          {value === 4 && <PayoutsReport />}
          {value === 5 && <OnboardingReport />}
        </Container>
      </div>
    </Box>
  );
}
