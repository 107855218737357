import React, { useState } from 'react';
import Box from 'core/ui/Box';
import { useTranslation } from 'react-i18next';
import ConfirmDialog from 'core/ui/ConfirmDialog';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import SectionScoresDetails from '../Workouts/SectionScoresDetails';

const PersonalRecordHistory = ({ data, getHistoryScore }) => {
  const { t } = useTranslation();
  const { request } = useActions();
  const [confirmDelete, setConfirmDelete] = useState({ open: false });

  const removePr = (item) => {
    setConfirmDelete({
      open: true,
      confirm: () => {
        request({
          action: Modules.personalRecordMovements.actions.deletePrHistory,
          data: { personalRecordUserId: item?.id },
          options: {
            onSuccess: () => {
              request({ action: Modules.personalRecordMovements.actions.getPrMovements });
              getHistoryScore();
            },
          },
        });

        setConfirmDelete({ open: false });
      },
    });
  };

  return (
    <Box mt={3}>
      <ConfirmDialog
        open={confirmDelete.open}
        confirm={() => confirmDelete.confirm()}
        cancel={() => setConfirmDelete({ open: false })}
        description={t('pr.user.confirmDelete')}
        labelConfirm={t('button.confirm')}
        labelCancel={t('button.cancel')}
      />
      <SectionScoresDetails data={data} removeScore={removePr} />
    </Box>
  );
};

export default PersonalRecordHistory;
