/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import notifications from 'modules/notifications';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { formatDateApi, formatDateToApi } from 'core/utils/formatters';
import useConfirmation from 'core/useConfirmation';

const useFormChannelsEvent = ({ eventId, close, callbackSave, channel }) => {
  const { t } = useTranslation();
  const { confirmation } = useConfirmation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
    control,
  } = useForm();
  const [event, setEvent] = useState(null);
  const { request } = useActions();
  const dispatch = useDispatch();

  const getEvent = () => {
    request({
      action: Modules.channelsEvents.actions.show,
      data: {
        channelId: channel?.id,
        id: eventId,
      },
      options: {
        onSuccess: (data) => {
          setEvent(data);
        },
        onError: (error) => {
          dispatch(notifications.error(error));
        },
      },
    });
  };

  const deleteEvent = () => {
    confirmation({
      description: t('areYouSure'),
      yesClick: () => {
        request({
          action: Modules.channelsEvents.actions.delete,
          data: {
            channelId: channel?.id,
            id: eventId,
          },
          options: {
            onSuccess: () => {
              callbackSave();
              close();
            },
            onError: (error) => {
              dispatch(notifications.error(error));
            },
          },
        });
      },
    });
  };

  useEffect(() => {
    if (eventId) {
      getEvent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId]);

  useEffect(() => {
    register('textRaw', { required: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteImage = (prop) => {
    if (watch(prop)?.id) {
      setValue('image', { id: watch(prop)?.id, _destroy: true });
    } else {
      setValue('image', null);
    }
  };

  useEffect(() => {
    if (event?.id) {
      reset({
        ...event,
        startAtDate: formatDateApi(event.startAt, 'YYYY-MM-DD HH:mm:ss Z', channel.timezone),
        startAtHour: formatDateApi(event.startAt, 'YYYY-MM-DD HH:mm:ss Z', channel.timezone),
        endAtDate: formatDateApi(event.endAt, 'YYYY-MM-DD HH:mm:ss Z', channel.timezone),
        endAtHour: formatDateApi(event.endAt, 'YYYY-MM-DD HH:mm:ss Z', channel.timezone),
        image: {
          id: event?.image?.id,
          url: event?.image?.image?.url,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event]);

  const onSubmit = (data) => {
    const startAt = formatDateToApi(
      `${data?.startAtDate?.substr(0, 10)} ${data?.startAtHour?.substr(
        11,
        8,
      )} ${channel?.timezone}`,
      channel?.timezone,
    );
    const endAt = formatDateToApi(
      `${data?.endAtDate?.substr(0, 10)} ${data?.endAtHour?.substr(11, 8)} ${channel?.timezone}`,
      channel?.timezone,
    );

    const eventData = {
      ...data,
      id: event?.id,
      image: null,
      imageAttributes: data?.image?.url ? data?.image : null,
      channelId: channel?.id,
      startAt,
      endAt,
      tags:
        data?.tags &&
        data?.tags.map((x) => ({
          name: x.name ? x.name : x,
        })),
    };

    request({
      action: event?.id
        ? Modules.channelsEvents.actions.update
        : Modules.channelsEvents.actions.create,
      data: {
        ...eventData,
      },
      options: {
        onSuccess: () => {
          dispatch(notifications.success(t('channel.events.success')));
          if (callbackSave) {
            callbackSave();
            close();
            return;
          }
          close();
        },
        onError: (error) => {
          dispatch(notifications.error(error));
        },
      },
    });
  };

  return {
    event,
    watch,
    setValue,
    submit: handleSubmit(onSubmit),
    errors,
    register,
    control,
    getEvent,
    deleteImage,
    deleteEvent,
  };
};

useFormChannelsEvent.propTypes = {
  close: PropTypes.func.isRequired,
};

useFormChannelsEvent.defaultProps = {};

export default useFormChannelsEvent;
