import React, { memo, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Collapse, CircularProgress, ClickAwayListener } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import { withHealedCurrentPath } from 'hocs/withHealedCurrentPath';
import Modules from 'modules';
import useActions from 'modules/map/useActions';
import { sessionSelectors } from 'modules/session';
import { useHelpVideoManager } from 'hooks/useHelpVideoManager';
import Divider from 'core/ui/Divider';
import useSelectors from 'modules/map/useSelectors';
import { helpVideoModule } from 'modules/helpVideo';
import Typography from 'core/ui/Typography';
import { useStyles } from './styles';

import { AdminArea } from '../AdminArea';
import { ListAdmin } from './List/ListAdmin';
import { ListUser } from './List/ListUser';

const HelpCollapseBase = memo(({ healedCurrentPath }) => {
  const { request } = useActions();

  const isAdmin = useSelector(sessionSelectors.getIsAdmin);

  const classes = useStyles();

  const [helpVideos, setHelpVideos] = useState([]);

  const getMappedHelpVideos = useCallback(() => {
    request({
      action: Modules.helpVideo.actions.indexByPath,
      data: {
        path: healedCurrentPath,
      },
      options: {
        onSuccess: setHelpVideos,
      },
    });
  }, [request, healedCurrentPath]);

  useEffect(() => {
    getMappedHelpVideos();
  }, [getMappedHelpVideos]);

  return (
    <CollapseContainer>
      <Box className={classes.container}>
        <Divider />

        {/* eslint-disable-next-line no-nested-ternary */}
        {helpVideos.length >= 1 ? (
          isAdmin ? (
            <ListAdmin
              helpVideos={helpVideos}
              setHelpVideos={setHelpVideos}
              getMappedHelpVideos={getMappedHelpVideos}
              healedCurrentPath={healedCurrentPath}
            />
          ) : (
            <ListUser helpVideos={helpVideos} />
          )
        ) : (
          <ListEmptyComponent />
        )}

        <Typography variant="h5" className="tw-text-white" m={2} ml={4}>
          VIDEOS ARE SPECIFIC TO THE PAGE YOU ARE VIEWING
        </Typography>

        {isAdmin && (
          <>
            <Divider mb={2} />

            <AdminArea
              getMappedHelpVideos={getMappedHelpVideos}
              currentHelpVideos={helpVideos}
              currentPath={healedCurrentPath}
            />
          </>
        )}
      </Box>
    </CollapseContainer>
  );
});

const CollapseContainer = ({ children }) => {
  const location = useLocation();
  const { visible, setVisible } = useHelpVideoManager();

  const isInChannelManage = location.pathname.includes('/channel/manage');

  // eslint-disable-next-line no-nested-ternary
  const paddingLeft = isInChannelManage ? 50 : 0;

  return (
    <Collapse in={visible} mountOnEnter unmountOnExit style={{ paddingLeft }}>
      <ClickAwayListener onClickAway={() => setVisible(false)}>{children}</ClickAwayListener>
    </Collapse>
  );
};

const ListEmptyComponent = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const loading = useSelectors(helpVideoModule, 'loading');

  return (
    <Box className={classes.listEmptyComponent}>
      {loading ? (
        <CircularProgress className={classes.listEmptyComponentLoader} />
      ) : (
        <Typography className={classes.listEmptyComponentText}>
          {t('helparea.emptylist')}
        </Typography>
      )}
    </Box>
  );
};

export const HelpCollapse = withHealedCurrentPath(HelpCollapseBase);
