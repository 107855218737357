import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import session from 'modules/session';
import language from 'modules/language';
import users from 'modules/users';
import channels from 'modules/channels';
import workouts from 'modules/workouts';
import app from 'modules/app';
import room from 'modules/room';
import sessionWorkout from 'modules/sessionWorkout';
import movements from 'modules/movements';
import equipment from 'modules/equipment';
import tags from 'modules/tags';
import filters from 'modules/filters';
import search from 'modules/search';
import ratings from 'modules/ratings';
import favorites from 'modules/favorites';
import support from 'modules/support';
import contract from 'modules/contract';
import competitions from 'modules/competitions';
import competitionsSearch from 'modules/competitionsSearch';
import payments from 'modules/payments';
import banners from 'modules/banners';
import advertisings from 'modules/advertisings';
import channelLists from 'modules/channelLists';
import messageLists from 'modules/messageLists';
import messages from 'modules/messages';
import recurrings from 'modules/recurrings';
import channelMembers from 'modules/channelMembers';
import channelMemberNotes from 'modules/channelMemberNotes';
import coachScheduleSessions from 'modules/coachScheduleSessions';
import workoutHeats from 'modules/workoutHeats';
import { reducer as notifications } from 'modules/notifications';
import reducers from './modules/map/reducers';

const rootReducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    session,
    notifications,
    language,
    users,
    channels,
    workouts,
    app,
    room,
    sessionWorkout,
    movements,
    equipment,
    filters,
    tags,
    search,
    ratings,
    favorites,
    support,
    contract,
    competitions,
    competitionsSearch,
    payments,
    banners,
    advertisings,
    channelLists,
    recurrings,
    channelMembers,
    messageLists,
    messages,
    channelMemberNotes,
    coachScheduleSessions,
    workoutHeats,
    ...reducers,
  });

export default rootReducers;
