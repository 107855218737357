import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router';

import { appSelectors } from 'modules/app';

export const withHealedCurrentPath = (WrappedComponent) => (props) => {
  const healedCurrentPath = useHealedCurrentPath();

  if (!healedCurrentPath) return null;

  return <WrappedComponent {...props} healedCurrentPath={healedCurrentPath} />;
};

/*
SENSIBLE ROUTES
Not all are listed here.

/embed/competition/:id/:embedTab
/channel/manage/:id/:defaultTag
/gym/:tab
/dashboard/:tab

/channel/view/:id/:defaultTab
/channel/view/:id/:defaultTab/:paramId

/partner/:partnerId/:screen

/channel/manage/:id
/channel/manage/:id/:defaultTag
/channel/manage/:id/:defaultTag/:param1
*/

// --- //

/*
HEALED ROUTES

# This routes will be healed because there are screens in UI, not tabs.
/channel/manage/:id
/channel/manage/:id/:defaultTag
/channel/manage/:id/:defaultTag/:param1
*/

const useHealedCurrentPath = () => {
  const currentRoutePath = useSelector((state) => appSelectors.getCurrentRoute(state)?.path);
  const { pathname } = useLocation();

  return useMemo(() => {
    const match = matchPath(pathname, {
      path: currentRoutePath,
      exact: true,
    });

    if (!match) return currentRoutePath;

    // These logic are EXTREMELY fragile:
    switch (match.path) {
      case '/channel/manage/:id':
        return '/channel/manage/:id/dashboard';

      case '/channel/manage/:id/:defaultTag':
        return match.path.replace(':defaultTag', match.params.defaultTag);

      case '/channel/manage/:id/:defaultTag/:param1':
        return match.path
          .replace(':defaultTag', match.params.defaultTag)
          .replace(':param1', match.params.param1);

      default:
        break;
    }

    return currentRoutePath;
  }, [currentRoutePath, pathname]);
};
