import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    gap: 4,
  },
  row: {
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    gap: 2,
  },
});
