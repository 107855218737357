import api from 'core/api';

export const openAIModule = 'openAI';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const actions = {
  generateWorkout: {
    module: openAIModule,
    name: 'generateWorkout',
    api: (params) => api.get(`/open_ai/generate_workout`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
};

export default {
  actions,
  state: {
    loading: false,
  },
};
