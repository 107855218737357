import api from 'core/api';

export const ownerablePaymentMethodsModule = 'ownerablePaymentMethods';

const loadingStates = {
  start: { loading: true },
  error: { loading: false },
  success: { loading: false },
};

const actions = {
  getOwnerablePaymentMethods: {
    module: ownerablePaymentMethodsModule,
    name: 'getOwnerablePaymentMethods',
    api: (params) => api.get(`/ownerable/payment_methods`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: ['data'],
    },
    state: loadingStates,
  },
  getPaymentMethodBySetupIntent: {
    module: ownerablePaymentMethodsModule,
    name: 'getPaymentMethodBySetupIntent',
    api: (params) => api.get(`/user_payment_methods/by_setup_intent`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
  createSetupIntentChannel: {
    module: ownerablePaymentMethodsModule,
    name: 'createSetupIntentChannel',
    api: (params) => api.get(`/ownerable/payment_methods/create_setup_intent`, { params }),
    params: {
      start: ['params'],
      error: [''],
      success: ['setupIntent'],
    },
    state: loadingStates,
  },
  setInactive: {
    module: ownerablePaymentMethodsModule,
    name: 'setInactive',
    api: (params) => api.put(`/ownerable/payment_methods/${params.id}/inactive`, params),
    params: {
      start: ['params'],
      error: [''],
      success: ['data'],
    },
    state: loadingStates,
  },
  setDefault: {
    module: ownerablePaymentMethodsModule,
    name: 'setDefault',
    api: (data) => api.put(`/ownerable/payment_methods/${data.id}/set_default`, data),
    params: {
      start: ['params'],
      error: [''],
      success: ['data'],
    },
    state: loadingStates,
  },
  createCardPointePaymentMethod: {
    module: ownerablePaymentMethodsModule,
    name: 'createCardPointePaymentMethod',
    api: (data) => api.post(`/ownerable/payment_methods`, data),
    params: {
      start: ['params'],
      error: [''],
      success: [''],
    },
    state: loadingStates,
  },
};

export default {
  actions,
  state: {
    loading: false,
    data: [],
    setupIntent: null,
  },
};
