/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import {
  BarChart,
  Bar,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from 'recharts';
import Typography from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import useSelectors from 'modules/map/useSelectors';
import { channelReportsModule } from 'modules/channelReports';
import Paper from 'core/ui/Paper';
import LoaderSm from 'core/ui/LoaderSm';

const BarMonths = ({ channelId }) => {
  const { t } = useTranslation();
  const membersConversionsChart = useSelectors(channelReportsModule, 'membersConversionsChart');
  const loadingChart = useSelectors(channelReportsModule, 'loadingChart');
  const { request } = useActions();

  const getReportChart = (params = {}) => {
    request({
      action: Modules.channelReports.actions.membersConversionsChart,
      data: {
        channelId,
        ...params,
      },
    });
  };

  useEffect(() => {
    if (channelId) {
      getReportChart();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelId]);

  const colorsChart = {
    newLeads: '#D28704',
    newMemberships: '#269FFC',
    newFirstVisists: '#04BC84',
  };

  return (
    <Paper p={3}>
      <Typography variant="h5" mb={3} align="center">
        {t('label.last12Months')} <LoaderSm loading={loadingChart} />
      </Typography>

      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={membersConversionsChart}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" style={{ fontSize: 10 }} />
          <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
          <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
          <Tooltip />
          <Legend />
          {Object.keys(colorsChart).map((item) => (
            <Bar yAxisId="left" dataKey={item} fill={colorsChart[item]} />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </Paper>
  );
};

export default BarMonths;
