import { createSelector } from 'reselect';

const getState = (state) => state.channelMembers;

export const getLoading = createSelector(getState, (state) => !!state.loading);
export const getLoadingWorkouts = createSelector(getState, (state) => !!state.loadingWorkouts);
export const getLoadingMember = createSelector(getState, (state) => !!state.loadingMember);
export const getLoadingUploadMembers = createSelector(
  getState,
  (state) => !!state.loadingUploadMembers,
);
export const getChannelMembers = createSelector(getState, (state) => state.data);
export const getChannelMembersPagination = createSelector(
  getState,
  (state) => state.membersPagination,
);
export const getChannelMember = createSelector(getState, (state) => state.member);
export const getChannelMemberWorkouts = createSelector(getState, (state) => state.dataWorkouts);
