/* eslint-disable react/prop-types */
import React from 'react';
import ImageMediaUser from 'components/Profile/ImageMedia';
import Box from 'core/ui/Box';
import Typography, { fontStyle } from 'core/ui/Typography';
import Paper from 'core/ui/Paper';
import InstagramLink from 'components/Profile/InstagramLink';
import CollapseText from 'core/ui/CollapseText';
import WorkoutScoresPoints from 'components/Competition/Show/Tabs/Results/Tables/WorkoutScoresPoints';
import { useTranslation } from 'react-i18next';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import { ButtonPrimary } from 'core/ui/Button';
import useConfirmation from 'core/useConfirmation';
import { useSelector } from 'react-redux';
import { channelsSelectors } from 'modules/channels';
import Comments from '../../../../../Comments';

export default ({
  leaderboard,
  workoutId,
  sectionId,
  showScores,
  hideComments,
  isCustomClass,
  buttonColor,
  onRemoveScore,
  allowRemove,
}) => {
  const { t } = useTranslation();
  const { request } = useActions();
  const { confirmation } = useConfirmation();
  const channel = useSelector((state) => channelsSelectors.getChannel(state));
  const removeScore = (scoreId) => {
    confirmation({
      description: t('user.remove.score'),
      yesClick: () => {
        request({
          action: Modules.newWorkouts.actions.deleteUserScore,
          data: { workoutId, workoutSectionUserId: scoreId, sectionId },
          options: {
            onSuccess: () => {
              onRemoveScore();
            },
          },
        });
      },
    });
  };

  return (
    <Box>
      <Paper elevation={2} className="paper-rounded" p={3}>
        <Box display="flex">
          <Box>
            <InstagramLink instagram={leaderboard.user.instagran} />
            <ImageMediaUser
              user={leaderboard.user}
              image={leaderboard.user.image ? leaderboard.user.image : {}}
              size={170}
            />
          </Box>
          <Box flexGrow={1} pl={3}>
            <Typography variant="h4" color="secondary">
              {leaderboard.user.name}
            </Typography>

            <Typography component="div" color="secondary" style={fontStyle.medium}>
              <CollapseText
                text={leaderboard.user.description}
                maxChar={120}
                linkColor={buttonColor}
              />
            </Typography>
          </Box>
        </Box>
      </Paper>

      <Paper elevation={2} mt={3} className="paper-rounded" p={3}>
        <Box display="flex">
          <Box flexGrow={1}>
            <Typography>{t('workout.scores')}</Typography>
          </Box>
          {allowRemove && (
            <ButtonPrimary onClick={() => removeScore(leaderboard.sectionUserId)}>
              {t('button.removeScore')}
            </ButtonPrimary>
          )}
        </Box>
        <Box display="flex">
          <Box>
            {showScores && leaderboard.scores && (
              <Box mt={1}>
                <WorkoutScoresPoints
                  scores={leaderboard.scores}
                  sectionScores={leaderboard.sectionScores}
                  positions={channel?.showLeaderboardRank ? leaderboard?.positions : null}
                  showVariation
                  showNotes
                  fontValue={fontStyle.large}
                  fontScore={fontStyle.large}
                  showFullNotes
                  buttonColor={buttonColor}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Paper>

      {!isCustomClass && !hideComments && (
        <Box mt={3} style={{ height: 400 }}>
          <Comments
            title="Message User"
            typeId={leaderboard.sectionUserId}
            kind="workout_section_user"
            type="WorkoutSectionUser"
            buttonColor={buttonColor}
            fontColor={buttonColor}
          />
        </Box>
      )}
    </Box>
  );
};
