import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import { ButtonPrimary } from 'core/ui/Button';
import { Search } from '@material-ui/icons';
import { channelReportsModule } from 'modules/channelReports';
import useSelectors from 'modules/map/useSelectors';
import Divider from 'core/ui/Divider';
import LoaderSm from 'core/ui/LoaderSm';
import DatePickerRounded from 'core/ui/DatePickers/DatePickerRounded';
import { formatDate, formatDateToApi } from 'core/utils/formatters';
import Checkbox from 'core/ui/Checkbox';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import ResultArm from './ResultArm';
import ResultLeg from './ResultLeg';
import ResultLtv from './ResultLtv';

const MembersGrowth = ({ channel, loading, getReport }) => {
  const { t } = useTranslation();
  const data = useSelectors(channelReportsModule, 'data');
  const [filters, setFilters] = useState({
    startAt: formatDate(new Date(), 'MM/DD/YYYY'),
  });
  const search = () => {
    const startAt = filters.startAt ? formatDateToApi(filters.startAt) : null;
    if (startAt) getReport({ ...filters, startAt });
  };

  useEffect(() => {
    search();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box mt={2}>
      <Box>
        <Box display="flex" mb={3} alignItems="flex-end" justifyContent="center" flexWrap="wrap">
          <Box display="flex" mb={3} alignItems="flex-end" justifyContent="center" flexWrap="wrap">
            <Box>
              <DatePickerRounded
                label="MONTH"
                onChange={(v) => setFilters((prev) => ({ ...prev, startAt: v }))}
                value={filters.startAt}
                disablePast={false}
                format="MMM yyyy"
                autoOk
              />
            </Box>
            <Box ml={3}>
              <SelectBoxObject
                label="MEMBERSHIPS"
                placeholder="All"
                emptyItem
                emptyValue={null}
                options={channel.membershipAllPlans || []}
                value={filters?.channelMembershipPlanId}
                setValue={(v) => setFilters((prev) => ({ ...prev, channelMembershipPlanId: v }))}
                bordered
              />
            </Box>
            <Box ml={3}>
              <Checkbox
                label="REMOVE DROP-INS"
                checked={filters?.removeDropIn}
                onClick={() =>
                  setFilters((prev) => ({ ...prev, removeDropIn: !prev?.removeDropIn }))
                }
              />
            </Box>
            <Box ml={3}>
              <ButtonPrimary disabled={loading} onClick={() => search()}>
                <Search style={{ color: 'white' }} fontSize="small" />
                {t('button.search')}&nbsp;
              </ButtonPrimary>
            </Box>
          </Box>
        </Box>

        {loading && <LoaderSm loading center />}
        <Divider m={3} />
        <ResultArm channel={channel} data={data} />
        <Divider m={3} />
        <ResultLeg channel={channel} data={data} />
        <Divider m={3} />
        <ResultLtv channel={channel} data={data} />
      </Box>
    </Box>
  );
};

MembersGrowth.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  loading: PropTypes.bool,
  getReport: PropTypes.func,
};

MembersGrowth.defaultProps = {
  loading: false,
  getReport: () => {},
};

export default MembersGrowth;
