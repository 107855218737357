import React, { useEffect, useRef, useState } from 'react';

import { useSelector } from 'react-redux';
import { roomSelectors } from 'modules/room';
import Box from 'core/ui/Box';
import useWindowSize from 'core/utils/hook/useWindowSize';
import Scrollbars from 'react-custom-scrollbars/lib/Scrollbars';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { IconButton } from '@material-ui/core';
import { isSafari } from 'react-device-detect';
import LiveUsers from './LiveUsers';

export default function SliderParticipants() {
  const participants = useSelector((state) => roomSelectors.getParticipants(state));
  const sizeWindow = useWindowSize();
  const scrollRef = useRef();
  const sizeParticipant = {
    width: 200,
    height: 160,
  };

  const [isArrowVisible, setIsArrowVisible] = useState(false);

  const scroll = (direction) => {
    const scrollData = scrollRef.current.getValues();

    let left = scrollData.scrollLeft + direction * sizeParticipant.width;

    if (left >= scrollData.scrollWidth) {
      left = scrollData.scrollWidth;
    }

    if (left < 0) {
      left = 0;
    }
    scrollRef.current.view.scroll({
      left,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const scrollData = scrollRef.current.getValues();
    if (scrollData.clientWidth === scrollData.scrollWidth) {
      setIsArrowVisible(false);
    } else {
      setIsArrowVisible(true);
    }
  }, [sizeWindow.width, participants]);

  return (
    <Box display="flex" mt={isSafari ? 10 : 5} alignItems="center" style={{ width: '100%' }}>
      {isArrowVisible && (
        <Box className="link" onClick={() => scroll(-1)}>
          <IconButton>
            <ArrowBackIosIcon />
          </IconButton>
        </Box>
      )}
      <Box flexGrow="1" display="flex" justifyContent="center">
        <Scrollbars
          renderTrackHorizontal={(props) => (
            <div {...props} style={{ display: 'none' }} className="track-horizontal" />
          )}
          renderTrackVertical={(props) => (
            <div {...props} style={{ display: 'none' }} className="track-vertical" />
          )}
          ref={scrollRef}
          style={{ width: '100%', height: 160 }}
        >
          <Box
            flexGrow="1"
            display="flex"
            justifyContent={isArrowVisible ? 'flex-start' : 'center'}
            flexWrap="nowrap"
          >
            <LiveUsers />
          </Box>
        </Scrollbars>
      </Box>

      {isArrowVisible && (
        <Box className="link" onClick={() => scroll(1)}>
          <IconButton>
            <ArrowForwardIosIcon />
          </IconButton>
        </Box>
      )}
    </Box>
  );
}
