import React, { useCallback, useEffect } from 'react';
import Typography from 'core/ui/Typography';
import Paper from 'core/ui/Paper';
import { Controller, useForm } from 'react-hook-form';
import TagsChosen from 'core/ui/TagsChosen';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import { useTranslation } from 'react-i18next';
import DatePickerRounded from 'core/ui/DatePickers/DatePickerRounded';
import Checkbox from 'core/ui/Checkbox';
import { Delete } from '@material-ui/icons';
import { debounce } from '@material-ui/core';
import { kanbanUpdateColumnEmitter } from 'emitters';
import { formatDateToApi } from 'core/utils/formatters';

const StagesKanbanFilters = ({ channelStages, channel }) => {
  const { t } = useTranslation();
  const { control, handleSubmit, watch } = useForm();
  const staffs = [...(channel.coaches || []), ...(channel.admins || [])];

  const submit = useCallback(
    (data) => {
      // # params[:q][:created_at_gteq] = Date.current
      // # params[:q][:created_at_lteq] = Date.current
      // # params[:q][:staffs_id_eq] = 1
      // # params[:q][:tags_name_in] = ['mmc']

      const formatParams = {
        archived: data?.archived ? '1' : '0',
        q: {
          created_at_gteq: data?.startDate && formatDateToApi(data?.startDate),
          created_at_lteq: data?.endDate && formatDateToApi(data?.endDate),
          staffs_id_eq: data?.staffs,
          tags_name_in: data?.tags?.map((x) => x.name || x),
        },
      };

      channelStages.forEach((item) => {
        kanbanUpdateColumnEmitter.emit(`CHANNEL_STAGE_UPDATE_${item?.id}`, formatParams);
      });
    },
    [channelStages],
  );

  useEffect(() => {
    // TypeScript users
    // const subscription = watch(() => handleSubmit(onSubmit)())
    const subscription = watch(debounce(handleSubmit(submit), 200));
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch, submit]);

  return (
    <Paper p={3} mb={2}>
      <div className="tw-flex tw-flex-row tw-flex-wrap tw-items-center tw-gap-6">
        <div className="tw-grow">
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <TagsChosen
                label={t('competition.registration.label.tags').toUpperCase()}
                placeholder={t('competition.registration.label.tags')}
                setValue={(values) => onChange(values)}
                limit={6}
                kind="channels_stages_user"
                val={value || []}
                iconTag={
                  <Typography ml={1.5} variant="h6" style={{ color: 'white' }}>
                    #
                  </Typography>
                }
                bordered
              />
            )}
            name="tags"
          />
        </div>

        <div className="tw-grow">
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <div>
                <SelectBoxObject
                  label={t('label.assignedTo').toUpperCase()}
                  options={staffs?.map((x) => ({ id: x.userId, name: x?.user?.name }))}
                  setValue={onChange}
                  value={value}
                  bordered
                  style={{ height: 45 }}
                  emptyItem
                  emptyValue={null}
                />
              </div>
            )}
            name="staffs"
          />
        </div>
        <div className="tw-flex tw-flex-row tw-gap-x-2">
          <div>
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <div>
                  <DatePickerRounded
                    label={t('label.created').toUpperCase()}
                    onChange={onChange}
                    value={value}
                    disablePast={false}
                    usFormat={channel?.useUsDateFormat}
                    stylePaper={{ height: 45 }}
                    autoOk
                    bordered
                  />
                </div>
              )}
              name="startDate"
            />
          </div>
          <div>
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <div>
                  <DatePickerRounded
                    label="&nbsp;"
                    onChange={onChange}
                    value={value}
                    disablePast={false}
                    usFormat={channel?.useUsDateFormat}
                    stylePaper={{ height: 45 }}
                    autoOk
                    bordered
                  />
                </div>
              )}
              name="endDate"
            />
          </div>
        </div>
        <div>
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <div>
                <Checkbox
                  label={
                    <div className="tw-flex tw-flex-row tw-items-center tw-gap-2">
                      <Delete fontSize="small" />
                      <Typography>{t('label.archived').toUpperCase()}</Typography>
                    </div>
                  }
                  onClick={() => onChange(!value)}
                  checked={!!value}
                />
              </div>
            )}
            name="archived"
          />
        </div>
      </div>
    </Paper>
  );
};

StagesKanbanFilters.propTypes = {};

StagesKanbanFilters.defaultProps = {
  close: () => {},
};

export default StagesKanbanFilters;
