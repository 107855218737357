import React from 'react';
import { useTheme } from '@material-ui/core';
import Box from '../Box';
import { secondaryLight } from '../Colors';
import Typography, { fontStyle } from '../Typography';
import { Wrapper } from './style';

const WrapperOption = ({
  active,
  activeColor,
  color,
  width,
  first,
  last,
  fontSize,
  value,
  option,
  onClick,
}) => {
  const style = {
    backgroundColor: active ? activeColor : color,
    borderTopLeftRadius: first ? '5px' : '0px',
    borderTopRightRadius: last ? '5px' : '0px',
    borderBottomLeftRadius: first ? '5px' : '0px',
    borderBottomRightRadius: last ? '5px' : '0px',
    borderTop: `0.5px solid ${secondaryLight}`,
    borderBottom: `0.5px solid ${secondaryLight}`,
    borderRight: last ? `0.5px solid ${secondaryLight}` : 'none',
    borderLeft: first ? `0.5px solid ${secondaryLight}` : 'none',
    padding: '8px',
    width: width ? `${width}px` : '80px',
    paddingLeft: '8px',
    paddingRight: '8px',
  };

  return (
    <Box
      style={style}
      display="flex"
      justifyContent="center"
      alignItems="center"
      className="hover"
      onClick={onClick}
    >
      <Typography
        align="center"
        className="tw-font-semibold"
        style={{
          color: value === option.value ? 'white' : activeColor,
          ...fontStyle[fontSize],
        }}
      >
        {option.label}
      </Typography>
    </Box>
  );
};

const Toggle = ({
  fontSize,
  value,
  options,
  onChange,
  buttonColor,
  color,
  label,
  required,
  ...props
}) => {
  const { palette } = useTheme();
  return (
    <>
      {label && (
        <Box ml={2} mb={2.5} display="flex">
          <Typography style={fontStyle.label} variant="body1">
            {label}
          </Typography>
          {required && (
            <Typography ml={1} color="error">
              *
            </Typography>
          )}
        </Box>
      )}
      <Box style={Wrapper} {...props}>
        {options.map((option, index) => (
          <WrapperOption
            key={index.toString()}
            active={value === option.value}
            activeColor={buttonColor || palette.primary.main}
            color={color}
            fontSize={fontSize}
            value={value}
            width={option.width}
            option={option}
            first={index === 0}
            last={index === options.length - 1}
            onClick={() => onChange(option.value)}
          />
        ))}
      </Box>
    </>
  );
};

Toggle.defaultProps = {
  value: '',
  fontSize: 'small',
  options: [],
  onChange: () => {},
};

export default Toggle;
