import api from 'core/api';

export const getMessage = (messageId) => api.get(`/admin/messages/${messageId}`);
export const stopContact = (phone) => api.get(`/admin/messages/stop_contact/${phone}`);
export const getMessagesReplies = () => api.get(`/admin/messages/replies`);
export const getMessageContacts = (messageId, query, page, perPage) =>
  api.get(`/admin/messages/${messageId}/contacts/?query=${query}&page=${page}&per_page=${perPage}`);
export const getMessages = () => api.get(`/admin/messages`);
export const getTwilioNumbers = () => api.get(`/twilio/numbers`);
export const postMessages = (data) => api.post('/admin/messages', data);
export const putMessages = (data) => api.put(`/admin/messages/${data.id}`, data);
export const getStreamUserCount = () => api.get(`/admin/messages/stream_users_count`);
