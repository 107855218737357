import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from 'core/ui/Box';
import { Dialog, FormControlLabel, Radio } from '@material-ui/core';
import TextFieldRounded from 'core/ui/TextFieldRounded';
import Typography, { fontStyle } from 'core/ui/Typography';
import { ButtonPrimary } from 'core/ui/Button';
import Texture from 'core/ui/Texture';
import useForm from './form';
import TableMembershipsBlocks from './TableMembershipsBlocks';

const ModalFormList = ({ open, programId, close, callback, mode, channel }) => {
  const { t } = useTranslation();
  const { setValue, watch, handleSubmit, onSubmit, errors, loading } = useForm({
    mode,
    programId,
    callback,
  });
  return (
    <Dialog maxWidth="md" scroll="body" fullWidth onClose={() => close()} open={open}>
      <Texture>
        <Box p={5}>
          <Box>
            <Box>
              <Box mb={3}>
                <TextFieldRounded
                  value={watch('name')}
                  onChange={(v) => setValue('name', v)}
                  placeholder={t('form.program.label.name')}
                  error={errors.name}
                  label={t('form.program.label.name').toUpperCase()}
                  required
                />
              </Box>
              <Box display="flex">
                <Box mb={1} ml={2}>
                  <Typography style={fontStyle.label} variant="body1">
                    {t('form.program.label.active').toUpperCase()}
                  </Typography>
                  <Box display="flex">
                    <FormControlLabel
                      onClick={() => setValue('active', true)}
                      control={<Radio color="primary" size="small" checked={!!watch('active')} />}
                      label="Yes"
                    />
                    <FormControlLabel
                      onClick={() => setValue('active', false)}
                      control={<Radio color="primary" size="small" checked={!watch('active')} />}
                      label="No"
                    />
                  </Box>
                </Box>
                <Box mb={1} ml={2}>
                  <Typography style={fontStyle.label} variant="body1">
                    {t('form.program.label.default').toUpperCase()}
                  </Typography>
                  <Box display="flex">
                    <FormControlLabel
                      onClick={() => setValue('default', true)}
                      control={<Radio color="primary" size="small" checked={!!watch('default')} />}
                      label="Yes"
                    />
                    <FormControlLabel
                      onClick={() => setValue('default', false)}
                      control={<Radio color="primary" size="small" checked={!watch('default')} />}
                      label="No"
                    />
                  </Box>
                </Box>
              </Box>
            </Box>

            {!loading && (
              <TableMembershipsBlocks channel={channel} setValue={setValue} watch={watch} />
            )}

            <Box mt={3} display="flex" justifyContent="flex-end">
              <ButtonPrimary disabled={loading} onClick={handleSubmit(onSubmit)}>
                {t('button.save')}
              </ButtonPrimary>
            </Box>
          </Box>
        </Box>
      </Texture>
    </Dialog>
  );
};

ModalFormList.defaultProps = {
  close: () => {},
  callback: () => {},
  open: false,
  mode: 'new',
};

ModalFormList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  close: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  callback: PropTypes.func,
  open: PropTypes.bool,
  mode: PropTypes.string,
};

export default ModalFormList;
