import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import useIsXs from 'core/utils/hook/useIsXs';
import Form from './Form';

const ModalEventsForm = ({ open, eventId, channel, close, callbackSave }) => {
  const isXs = useIsXs();

  return (
    <Dialog open={open} onClose={close} maxWidth="md" fullWidth fullScreen={isXs}>
      <Form eventId={eventId} close={close} channel={channel} callbackSave={callbackSave} />
    </Dialog>
  );
};

ModalEventsForm.propTypes = {
  close: PropTypes.func,
};

ModalEventsForm.defaultProps = {
  close: () => {},
};

export default ModalEventsForm;
