import React from 'react';
import { Chip } from '@material-ui/core';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';

export const HintTable = () => (
  <div className="tw-flex tw-flex-col tw-gap-y-2 md:tw-flex-row md:*:before:tw-block md:[&>:not(:first-child)]:before:tw-content-['\a0\a0•\a0\a0']">
    <div className="tw-flex tw-items-center tw-gap-1">
      <Chip
        label={<CalendarTodayOutlinedIcon className="tw-my-auto tw-block" />}
        className="tw-bg-neutral-500 tw-text-white"
        size="small"
      />
      To be sent
    </div>

    <div className="tw-flex tw-items-center tw-gap-1">
      <Chip
        label={<MailOutlineIcon className="tw-my-auto tw-block" />}
        className="tw-bg-red-500 tw-text-white"
        size="small"
      />
      Sent
    </div>

    <div className="tw-flex tw-items-center tw-gap-1">
      <Chip
        label={<CheckCircleOutlineIcon className="tw-my-auto tw-block" />}
        className="tw-bg-green-500 tw-text-white"
        size="small"
      />
      Completed
    </div>
  </div>
);
