import React, { useContext, useEffect } from 'react';
import { Container } from '@material-ui/core';
import Form from 'components/Competition/Form';
import { LayoutContext } from 'pages/shared/Layout';
import { useTranslation } from 'react-i18next';
import { sessionSelectors } from 'modules/session';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import notifications from 'modules/notifications';
import { competitionsSelectors } from 'modules/competitions';
import Box from 'core/ui/Box';

export default () => {
  const layoutContext = useContext(LayoutContext);
  const { t } = useTranslation();
  const competition = useSelector((state) => competitionsSelectors.getCompetition(state));
  const loading = useSelector((state) => competitionsSelectors.getLoading(state));
  const user = useSelector((state) => sessionSelectors.getUser(state));
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!competition.id || loading) {
      return;
    }
    if (competition.isCoach === false) {
      history.push(`/competition/view/${competition.slug}`);
      dispatch(notifications.error('Not authorized'));
    }
  }, [competition, history, loading, user, dispatch]);

  useEffect(() => {
    layoutContext.setTitleBar(t('title.competition.edit').toUpperCase());
    layoutContext.setActiveMenu('');
  }, [layoutContext, t]);

  return (
    <div>
      <Container maxWidth="xl" style={{ position: 'relative' }}>
        <Box pt={5}>
          <Form mode="edit" />
        </Box>
      </Container>
    </div>
  );
};
