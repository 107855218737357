import React, { useState } from 'react';
import Box from 'core/ui/Box';
import Typography from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import { Collapse, Dialog } from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowRight } from '@material-ui/icons';
import { secondary } from 'core/ui/Colors';
import Paper from 'core/ui/Paper';
import { listScores } from 'core/utils/helpers';
import ViewVideo from 'components/LibraryMedias/MediaFilesGallery/ViewVideo';
import LabelBreakLine from 'core/ui/LabelBreakLine';
import HistoryWorkoutScoresDetails from 'components/Profile/Dashboard/Workouts/HistoryWorkoutScoresDetails';
import Texture from 'core/ui/Texture';
import ItemDetailSection from './ItemDetailSection';
import MenuSection from './MenuSection';

const styleIcon = {
  color: secondary,
  fontSize: 26,
};
const ItemSection = ({
  workout,
  section,
  index,
  selectTab,
  hideSections,
  onAddScoresClick,
  addPrScore,
  onLeaderboardClick,
  expandSection,
  isCompetition,
  showLeaderboard,
  paperClass,
  buttonColor,
  fontColor,
  isCoach,
}) => {
  const { t } = useTranslation();
  const [modalHistory, setModalHistory] = useState({ open: false });
  const [collapseCoachNotes, setCollapseCoachNotes] = useState(false);
  const [collapseAthleteNotes, setCollapseAthleteNotes] = useState(false);

  const sectionVideos = section?.mediaFilesAssociations
    ?.filter((x) => x.kind === 'section' && !!x.mediaFile)
    .map((x) => x.mediaFile);
  const coachNotesVideos = section?.mediaFilesAssociations
    ?.filter((x) => x.kind === 'coach_notes' && !!x.mediaFile)
    .map((x) => x.mediaFile);
  const athleteNotesVideos = section?.mediaFilesAssociations
    ?.filter((x) => x.kind === 'athlete_notes' && !!x.mediaFile)
    .map((x) => x.mediaFile);

  const showCompareButtons =
    !isCompetition &&
    !!listScores(section, t) &&
    showLeaderboard &&
    listScores(section, t) !== 'For Completion';
  const showScoreButtons = !isCompetition && !!listScores(section, t) && showLeaderboard;

  const showExpandButton = !!expandSection && !hideSections;

  return (
    <>
      {modalHistory?.open && (
        <Dialog
          maxWidth="md"
          fullWidth
          scroll="body"
          open={modalHistory?.open}
          onClose={() => setModalHistory({ open: false })}
        >
          <Texture>
            <Box>
              <HistoryWorkoutScoresDetails
                workout={workout}
                section={section}
                actions={{ setModalScore: onAddScoresClick }}
              />
            </Box>
          </Texture>
        </Dialog>
      )}
      <Box key={index.toString()}>
        <Paper elevation={2} className={`paper-rounded ${paperClass}`} p={3}>
          <Box display="flex" className="link" alignItems="center">
            <Box flexGrow="1" onClick={() => (selectTab ? selectTab(index, section.open) : null)}>
              <Typography ml={2} color="secondary" variant="h5">
                {section?.title?.toUpperCase()}
              </Typography>
            </Box>

            {(showScoreButtons || showExpandButton) && (
              <Box display="flex" mx={3}>
                <Box>
                  <MenuSection
                    section={section}
                    showScoreButtons={showScoreButtons}
                    showCompareButtons={showCompareButtons}
                    actions={{
                      onLeaderboardClick,
                      addPrScore,
                      onAddScoresClick,
                      expandSection: !!expandSection && !hideSections ? expandSection : null,
                      setModalHistory,
                    }}
                    buttonColor={buttonColor}
                  />
                </Box>
              </Box>
            )}

            {!hideSections && selectTab && (
              <Box onClick={() => (selectTab ? selectTab(index, section.open) : null)}>
                {section.open === true ? (
                  <KeyboardArrowDown style={{ ...styleIcon }} />
                ) : (
                  <KeyboardArrowRight style={{ ...styleIcon }} />
                )}
              </Box>
            )}
          </Box>

          {!hideSections && (
            <Box mt={3}>
              <Collapse in={section.open}>
                <Box px={2} pb={5}>
                  {sectionVideos?.length > 0 && (
                    <Box my={3}>
                      {sectionVideos.map((x) => (
                        <Box key={x.id} mb={1}>
                          <ViewVideo url={x?.url} />
                        </Box>
                      ))}
                    </Box>
                  )}
                  <ItemDetailSection
                    section={section}
                    fontColor={fontColor}
                    buttonColor={buttonColor}
                  />

                  {isCoach && (!!section?.coachNotes || coachNotesVideos?.length > 0) && (
                    <NotesSection
                      opened={collapseCoachNotes}
                      toggle={() => setCollapseCoachNotes(!collapseCoachNotes)}
                      videos={coachNotesVideos}
                      label={t(
                        isCompetition ? 'workout.label.judgeNotes' : 'workout.label.coachNotes',
                      )}
                      showCollapse={!hideSections && selectTab}
                      notes={section?.coachNotes}
                    />
                  )}

                  {(!!section?.athleteNotes || athleteNotesVideos?.length > 0) && (
                    <NotesSection
                      opened={collapseAthleteNotes}
                      toggle={() => setCollapseAthleteNotes(!collapseAthleteNotes)}
                      videos={athleteNotesVideos}
                      label={t('workout.section.label.athleteNotes')}
                      showCollapse={!hideSections && selectTab}
                      notes={section?.athleteNotes}
                    />
                  )}
                </Box>
              </Collapse>
            </Box>
          )}
        </Paper>
      </Box>
    </>
  );
};

export default ItemSection;

ItemSection.defaultProps = {
  section: {},
  index: 0,
  selectTab: null,
  hideSections: true,
  isRegistered: false,
};

const NotesSection = ({ videos, toggle, opened, label, showCollapse, notes }) => (
  <Paper m={3} p={3}>
    <Box display="flex" className="link" alignItems="center">
      <Box flexGrow="1" onClick={() => toggle()}>
        <Typography className="roboto-regular" style={{ fontSize: 14 }}>
          <b>{label}</b>
        </Typography>
      </Box>

      {showCollapse && (
        <Box onClick={() => toggle()}>
          {opened === true ? (
            <KeyboardArrowDown style={{ ...styleIcon }} />
          ) : (
            <KeyboardArrowRight style={{ ...styleIcon }} />
          )}
        </Box>
      )}
    </Box>
    <Collapse in={opened}>
      <Box>
        {!!notes && (
          <Typography className="roboto-regular" style={{ fontSize: 14 }}>
            <LabelBreakLine>{notes}</LabelBreakLine>
          </Typography>
        )}
        {videos?.length > 0 && (
          <Box my={3}>
            {videos.map((x) => (
              <Box key={x.id} mb={1}>
                <ViewVideo url={x?.url} />
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </Collapse>
  </Paper>
);
