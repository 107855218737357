import { all, call, put } from 'redux-saga/effects';

import { clearAuthHeaders, persistAuthHeaders } from 'core/api/auth-headers';
import { persistData, removeData } from 'core/utils/session-storage';
import { getErrorMessage } from 'core/api/api-error';

import notifications from 'modules/notifications';

import * as api from './api';
import { sessionActions as actions } from './index';

export function* authenticate({ callbackSucess, callbackError }) {
  try {
    const response = yield call(api.validateToken);
    yield put(actions.authenticateSuccess(response.data));
    if (callbackSucess) {
      callbackSucess(response.data);
    }
  } catch (error) {
    if (callbackError) {
      callbackError();
    }
    yield put(actions.authenticateError(getErrorMessage(error)));
  }
}

export function* authenticateByJwt({ token, callbackSuccess, callbackError }) {
  try {
    const response = yield call(api.validateJwtToken, { token });
    yield put(actions.authenticateByJwtSuccess(response.data));
    if (callbackSuccess) {
      callbackSuccess(response.data);
    }
  } catch (error) {
    if (callbackError) {
      callbackError();
    }
    yield put(actions.authenticateByJwtError(getErrorMessage(error)));
  }
}

export function* changeUser({ userId, callback }) {
  try {
    const response = yield call(api.changeUser, userId);

    if (response.headers['access-token']) {
      persistAuthHeaders(persistData)(response.headers);
    }

    yield put(actions.changeUserSuccess(response.data));
    if (callback) {
      callback(response?.data);
    }
  } catch (error) {
    yield put(actions.changeUserError(getErrorMessage(error)));
  }
}

export function* signIn({ user, callback }) {
  try {
    const resp = yield call(api.signIn, user);
    yield put(actions.signInSuccess(resp?.data));
    if (callback) {
      callback(resp?.data);
    }
  } catch (error) {
    yield put(notifications.error(getErrorMessage(error)));
    yield put(actions.signInError(getErrorMessage(error)));
  }
}

export function* validatePin({ credentials }) {
  try {
    const response = yield call(api.validatePin, credentials);
    const user = response.data;
    if (user.id) {
      // User is logged in
      yield put(actions.validatePinSuccess({ ...user, modal: !!credentials.fromModal }));
      if (credentials.callback) {
        credentials.callback();
      }
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.validatePinError(getErrorMessage(error))),
    ]);
  }
}

export function* logOut({ callback }) {
  try {
    yield call(api.authLogOut);
    yield put(actions.authLogOutSuccess());
    yield call(clearAuthHeaders(removeData));
    if (callback) {
      callback();
    }
  } catch (error) {
    yield all([
      put(notifications.error(getErrorMessage(error))),
      put(actions.authLogOutError(getErrorMessage(error))),
    ]);
  }
}
