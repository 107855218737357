import React from 'react';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { useTheme } from '@material-ui/core';
import Typography from '../Typography';
import Box from '../Box';

function Radio({ onClick, checked, size, label, color, style, ...props }) {
  const { palette } = useTheme();

  const rColor = color || palette.primary.main;

  return (
    <Box
      className="link-hover"
      onClick={() => onClick()}
      display="flex"
      alignItems="center"
      {...props}
    >
      {!checked ? (
        <RadioButtonUncheckedIcon style={{ fontSize: size, color: rColor, ...style }} />
      ) : (
        <CheckCircleOutlineIcon style={{ fontSize: size, color: rColor, ...style }} />
      )}
      {label && (
        <Typography ml={2} variant="body2" component="div">
          {label}
        </Typography>
      )}
    </Box>
  );
}

Radio.defaultProps = {
  checked: false,
  color: null,
  size: 25,
  onClick: () => {},
  label: null,
  style: {},
};

export default Radio;
