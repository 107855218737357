import React from 'react';
import PropTypes from 'prop-types';
import Box from 'core/ui/Box';
import { Dialog, Slide } from '@material-ui/core';
import Texture from 'core/ui/Texture';
import Form from './Form';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const ModalFormCategory = ({
  open,
  item,
  close,
  callback,
  mode,
  ownerableType,
  ownerableId,
  hasSifelyPlan,
  showCardpointe,
}) => (
  <Dialog
    fullWidth
    maxWidth="md"
    scroll="body"
    open={open}
    onClose={close}
    TransitionComponent={Transition}
  >
    <Texture style={{ minHeight: '100%' }}>
      <Box p={5}>
        <Form
          mode={mode}
          callback={callback}
          item={item}
          ownerableId={ownerableId}
          ownerableType={ownerableType}
          hasSifelyPlan={hasSifelyPlan}
          showCardpointe={showCardpointe}
        />
      </Box>
    </Texture>
  </Dialog>
);

ModalFormCategory.defaultProps = {
  close: () => {},
  callback: () => {},
  item: null,
  open: false,
  mode: 'new',
};

ModalFormCategory.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  close: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  callback: PropTypes.func,
  open: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.object,
  mode: PropTypes.string,
};

export default ModalFormCategory;
