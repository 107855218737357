import React from 'react';
import { useTranslation } from 'react-i18next';
import SelectBoxObject from 'core/ui/SelectBoxObject';
import { channelAccessTypes } from 'core/utils/consts';
import Box from 'core/ui/Box';
import ColorPicker from 'core/ui/ColorPicker';
import Paper from 'core/ui/Paper';
import Toggle from 'core/ui/Toggle';
import Typography, { fontStyle } from 'core/ui/Typography';

export default function CoachMenuTypes({
  coach,
  accessType,
  calendarColor,
  manageContactForm,
  onChangeType,
}) {
  const { t } = useTranslation();
  const [currentAccess, setCurrentAccess] = React.useState(accessType);
  const [color, setColor] = React.useState(calendarColor);
  const [currentManageContact, setCurrentManageContact] = React.useState(manageContactForm);

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Paper p={3} className="paper-rounded">
        <Box display="flex" alignItems="center" className="gap-5">
          <Box flexGrow={1}>
            <SelectBoxObject
              options={channelAccessTypes}
              label={t('accessType')}
              propLabel="label"
              propValue="value"
              setValue={(v) => {
                onChangeType(coach.id, { accessType: v });
                setCurrentAccess(v);
              }}
              value={currentAccess}
              bordered
            />
          </Box>
          <Box ml={2}>
            <ColorPicker
              label={t('coach.calendarColor')}
              color={color}
              setColor={(v) => {
                setColor(v);
                onChangeType(coach.id, { calendarColor: v });
              }}
              bordered
            />
          </Box>

          <Box>
            <Typography style={fontStyle.label}>{t('label.manageContactForm')}</Typography>
            <Toggle
              options={[
                { value: false, label: t('button.no') },
                { value: true, label: t('button.yes') },
              ]}
              value={!!currentManageContact}
              onChange={() => {
                setCurrentManageContact(!currentManageContact);
                onChangeType(coach.id, { manageContactForm: !currentManageContact });
              }}
            />
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}

CoachMenuTypes.defaultProps = {
  coach: {},
  remove: null,
  onChangeType: null,
};
