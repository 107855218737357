/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import Box from 'core/ui/Box';
import { ButtonPrimary } from 'core/ui/Button';
import LoaderSm from 'core/ui/LoaderSm';
import { useTranslation } from 'react-i18next';
import useSelectors from 'modules/map/useSelectors';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import notifications from 'modules/notifications';
import Modules from 'modules';
import useActions from 'modules/map/useActions';
import { timeZoneName } from 'core/utils/formatters/date';
import Texture from 'core/ui/Texture';
import { DialogActions, IconButton } from '@material-ui/core';
import Paper from 'core/ui/Paper';
import SelectorPaymentMethod, { PaymentMethodViewType } from 'components/Account/Selector';
import { sessionSelectors } from 'modules/session';
import useChannelStyles from 'core/useChannelStyles';
import Typography from 'core/ui/Typography';
import { channelCurrency } from 'core/utils/formatters';
import SelectorChannelUser from 'components/Channel/SelectorChannelUser';
import { Close } from '@material-ui/icons';
import { channelsEventsRegistrationsModule } from 'modules/channelsEventsRegistrations';
import EventInfo from '../EventInfo';

const FormRegistration = ({ event, channel, callback, callbackError }) => {
  const { t } = useTranslation();
  const currentUser = useSelector((state) => sessionSelectors.getUser(state));
  const { buttonColor } = useChannelStyles();

  const [member, setMember] = useState(null);

  const loadingForm = useSelectors(channelsEventsRegistrationsModule, 'loadingForm');
  const [viewMethodPayment, setViewMethodPayment] = useState(PaymentMethodViewType.list);
  const { control, handleSubmit } = useForm({
    defaultValues: { timezone: timeZoneName() },
  });
  const dispatch = useDispatch();
  const { request } = useActions();

  const onSubmit = (data) => {
    const formData = {
      userPaymentMethodId: data?.userPaymentMethod?.id,
      channelId: channel?.id,
      userId: member?.id || currentUser?.id,
      channelsEventId: event?.id,
    };

    request({
      action: Modules.channelsEventsRegistrations.actions.create,
      data: formData,
      options: {
        onSuccess: () => {
          dispatch(notifications.success(t('message.success.channelsEventsRegistrations')));
          if (callback) {
            callback();
          }
        },
        onError: (error) => {
          dispatch(notifications.error(error));
          if (callbackError) {
            callbackError(error);
          }
        },
      },
    });
  };

  return (
    <>
      <Texture>
        <div className="tw-flex tw-flex-col tw-gap-4 tw-p-4">
          {channel?.isCoach && !member?.id && (
            <div>
              <div className="tw-mb-1 tw-pl-2">{t('channel.selectAMember')}</div>
              <SelectorChannelUser
                buttonColor={buttonColor}
                onSelectedUser={(u) => setMember(u)}
                channelId={channel?.id}
                showCreateButton={false}
              />
            </div>
          )}
          {channel?.isCoach && !!member?.id && (
            <Paper p={3}>
              <div className="tw-flex tw-flex-row tw-items-center tw-gap-2">
                <div className="tw-grow">
                  <div>{member?.name}</div>
                  <div>{member?.email}</div>
                  <div>{member?.phone}</div>
                </div>
                <IconButton size="small" onClick={() => setMember(null)}>
                  <Close />
                </IconButton>
              </div>
            </Paper>
          )}

          <EventInfo channel={channel} event={event} />

          {event?.chargeRegistration && (
            <div>
              <Controller
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <div>
                    <SelectorPaymentMethod
                      selectedPaymentMethod={value}
                      setSelectedPaymentMethod={onChange}
                      view={viewMethodPayment}
                      setView={setViewMethodPayment}
                      userId={member?.id || currentUser.id}
                      onlyMethod={channel.allowAchPayments ? null : 'card'}
                      buttonColor={buttonColor}
                      userInfo={{
                        name: currentUser?.name,
                        phone: currentUser?.phone,
                        email: currentUser?.email,
                      }}
                      cardpointe={channel?.useCardpointe}
                    />

                    {error && (
                      <div>
                        <Typography className="tw-m-1 tw-text-xs tw-text-red-400">
                          {t('channel.modalPayment.validation.selectAccount')}
                        </Typography>
                      </div>
                    )}
                  </div>
                )}
                name="userPaymentMethod"
              />

              <div className="tw-m-2">
                <Typography variant="h5" align="center">
                  {channelCurrency(event?.price, channel?.currency)}
                </Typography>
              </div>
            </div>
          )}
        </div>
      </Texture>

      <DialogActions>
        <Box display="flex" justifyContent="flex-end">
          <ButtonPrimary
            type="button"
            onClick={() => handleSubmit(onSubmit)()}
            size="small"
            disabled={loadingForm}
            style={{ height: 30, width: 80, borderRadius: 5 }}
          >
            <LoaderSm loading={loadingForm} width={20} />
            {t('button.confirm')}
          </ButtonPrimary>
        </Box>
      </DialogActions>
    </>
  );
};

FormRegistration.defaultProps = {};

export default FormRegistration;
